<template>
    <v-dialog
            v-model="transferDialog"
            transition="slide-x-transition"
            persistent
            :max-width="dlgWidth + 'px'"
            max-height="600px"
            width="90%"
            height="90%"
            lazy
    >
        <v-card style="overflow-y: hidden" v-if="transferDialog">

            <v-toolbar
                    card
                    color="primary"
                    dark
            >
                <v-btn @click="transferDialog = false" small fab color="white" class="py-0 my-0" flat>
                    <v-icon>arrow_back</v-icon>
                </v-btn>

                <v-toolbar-title>Transfer Task</v-toolbar-title>
            </v-toolbar>
            <v-layout align-space-between justify-center row fill-height>
                <!--Loading-->
                <v-flex v-if="showLoading">
                    <div class="text-xs-center ma5 pa-5">
                        <v-progress-circular
                                :size="40"
                                :width="5"
                                color="primary"
                                indeterminate
                        ></v-progress-circular>
                    </div>
                </v-flex>

                <v-flex v-if="!showLoading && transferHistory.length != 0" grow>
                    <div class="subheading font-weight-light text-xs-center text--primary pl-2 py-1 pb-0"
                         color="primary">History
                    </div>
                    <v-divider></v-divider>
                    <div class="scroll" style="overflow-y: auto; max-height: 450px">
                        <v-list two-line>
                            <template v-for="(item, index) in transferHistory">
                                <v-list-tile
                                        :key="item.date"
                                        avatar
                                        ripple
                                        @click="() => {}"
                                >
                                    <v-list-tile-content>
                                        <v-list-tile-sub-title class="caption text-xs-right font-weight-bold">
                                            {{ getLongDate(item.date) }}
                                        </v-list-tile-sub-title>
                                        <v-list-tile-sub-title class="text--primary">
                                            By: {{ item.by }}
                                        </v-list-tile-sub-title>
                                        <v-list-tile-sub-title>
                                            From: {{ item.from }}
                                        </v-list-tile-sub-title>
                                        <v-list-tile-sub-title>
                                            To: {{ item.to }}
                                        </v-list-tile-sub-title>
                                    </v-list-tile-content>
                                </v-list-tile>
                                <v-divider
                                        v-if="index + 1 < transferHistory.length"
                                        :key="index"
                                ></v-divider>
                            </template>
                        </v-list>
                    </div>


                </v-flex>

                <v-flex shrink>
                    <v-divider vertical></v-divider>
                </v-flex>


                <v-flex v-show="!showLoading" grow>

                    <div class="subheading font-weight-light text-xs-center text--primary pl-2 py-1 pb-0"
                         color="primary">Transfer To?
                    </div>
                    <v-divider></v-divider>

                    <div id="searchBox">
                        <v-text-field
                                label="Search Users"
                                solo
                                clearable
                                prepend-icon="search"
                                color="primary"
                                flat
                                class="ml-3"
                                :hide-details="true"
                                v-model="userSearch"
                        ></v-text-field>

                        <v-divider></v-divider>
                    </div>

                    <div class="scroll" style="overflow-y: auto; max-height: 450px">
                        <v-list one-line dense>

                            <template v-for="(item, index) in displayUsers">
                                <v-list-tile
                                        :key="item.date"
                                        avatar
                                        ripple
                                        @click="() => {}"
                                >

                                    <v-list-tile-action class="px-1" style="min-width: auto">
                                        <v-icon
                                                :color="typeof item.outOfOffice == 'undefined' ? 'primary' : (intToBool(item.outOfOffice) ? 'secondary' : 'primary')"
                                                class="pa-0 ma-0"
                                        >
                                            person
                                        </v-icon>
                                    </v-list-tile-action>


                                    <v-list-tile-title>
                                        {{ item.userFullName }}
                                    </v-list-tile-title>

                                    <v-list-tile-action class="px-1" style="min-width: auto">
                                        <v-tooltip top>
                                            <v-btn @click="transferTask(item)" class="pa-0 ma-0" flat icon color="red"
                                                   slot="activator">
                                                <v-icon>forward</v-icon>
                                            </v-btn>
                                            <span>Transfer Task to {{ item.userFullName }}</span>
                                        </v-tooltip>
                                    </v-list-tile-action>

                                </v-list-tile>

                                <v-divider
                                        v-if="index + 1 < displayUsers.length"
                                        :key="index"
                                ></v-divider>
                            </template>

                        </v-list>
                    </div>


                </v-flex>

            </v-layout>
        </v-card>
    </v-dialog>
</template>

<script>

import {eventBus} from '../../main';
import {codeBaseMixin} from '../../codeBaseMixin';

export default {
    mixins: [codeBaseMixin],
    data() {
        return {
            transferDialog: false,
            taskID: 0,
            userInfo: {},
            showLoading: true,
            authorizedUsers: [],
            transferHistory: [],
            dlgWidth: 400,
            searchBoxHeight: 0,
            userSearch: '',
        }
    },
    computed: {
        displayUsers() {
            let arrUsers = [];

            if (this.userSearch == '' || this.userSearch == null) {
                return this.authorizedUsers;
            } else {

                this.authorizedUsers.forEach((user) => {
                    if (user.userFullName.toLowerCase().includes(this.userSearch.toLowerCase(), 0)) {
                        arrUsers.push(user)
                    }
                });
                return arrUsers;
            }
        }
    },
    created() {
        eventBus.$on('transferTask', this.transferTaskEvent)
    },
    beforeDestroy() {
        eventBus.$on('transferTask', this.transferTaskEvent)
    },
    mounted() {
        // this.searchBoxHeight = document.getElementById('searchBox').offsetHeight;
    },
    methods: {
        transferTaskEvent(transferObj) {
            this.transferDialog = true;
            this.initTransfer(transferObj)
        },

        initTransfer(transferObj) {
            this.taskID = transferObj.taskID;
            this.userInfo = transferObj.userInfo;
            this.authorizedUsers = [];
            this.transferHistory = [];
            this.showLoading = true;
            this.getTransferHistory();
        },

        transferTask(userToObj) {
            eventBus.$emit('taskTransferStart', this.taskID);
            this.fetch({
                method: 'GET',
                url: `/taskSync/transferTask/${this.userInfo.entityID}/${this.userInfo.userID}/${this.taskID}/${userToObj.userID}`
            })
                    .then((data) => {
                        this.transferDialog = false;
                    })
                    .catch((e) => {
                        this.standardError(e)
                    })

            // let sRequ = this.serverAddress + '/taskSync/transferTask/' + this.userInfo.entityID + '/' + this.taskID + '/' + this.userInfo.userID + '/' + userToObj.userID;
            // let req = new Request(sRequ, {method: 'PATCH', mode: 'cors'});
            // fetch(req)
            //     .then((response) => {
            //         if (response.ok == true) {
            //             return response.text();
            //         } else {
            //             throw new Error('Bad Stuff');
            //         }
            //     })
            //     .then((data) => {
            //             if (data == 'true') {
            //                 this.transferDialog = false;
            //                 // eventBus.$emit('taskTransferDone', {taskID: this.taskID, transferedTo: userToObj})
            //             }
            //     })
            //     .catch((err) => {
            //         console.log('ERROR: ' + err.message);
            //     });
        },

        getTransferHistory() {

            this.fetch({
                method: 'GET',
                url: `/tasks/taskTransferHistory/${this.userInfo.entityID}/${this.userInfo.userID}/${this.taskID}`
            })
                    .then((data) => {
                        this.transferHistory = data;
                        this.dlgWidth = this.transferHistory.length == 0 ? 400 : 800;
                        this.getAuthorizedUsers();
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e)
                        this.getAuthorizedUsers();
                        this.dlgWidth = 400;
                    })
        },

        getAuthorizedUsers() {
            this.fetch({
                method: 'GET',
                url: `/tasks/taskTransferAuthorizedUsersTransfer/${this.userInfo.entityID}/${this.taskID}`
            })
                    .then((data) => {
                        try {
                            this.authorizedUsers = data;
                            this.showLoading = false;
                        } catch (e) {
                            console.log(e);
                        }
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e)
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                    })
        }
    }
}
</script>

<style scoped>

</style>
