<template>
  <app-input-dlg
      :show-dialog="value"
      :show-input-box="false"
      :body-padding="false"
      :width="600"
  >
    <template slot="title">
      <div class="primary">
        <v-layout row align-center>
          <v-flex shrink class="body-1 white--text pa-2" style="white-space: nowrap">
            Yeastar Recordings
          </v-flex>
          <v-flex xs12 class="px-2 ">
            <v-text-field
                hide-details
                single-line
                dark
                prepend-icon="search"
                label="Search"
                class="pa-0 ma-0"
                color="white"
                v-model="searchStr"
            />
          </v-flex>
          <v-flex shrink class="px-2">
            <v-btn
                small
                icon
                flat
                class="pa-0 ma-0"
                color="white"
                @click="$emit('input', false)"
            >
              <v-icon small>close</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </div>
    </template>
    <template slot="body">
      <v-divider></v-divider>
      <div v-if="loadingRecordings && recordings.length <= 0" style="width: 100%" class="text-xs-center">
        <v-progress-circular
            :size="40"
            :width="5"
            color="primary"
            indeterminate
        />
      </div>
      <div v-if="recordings.length" style="height: 400px; overflow-y: auto" class="c-d-flex fill-height of-y">
        <div class="c-flex-grow-1 of-y">
          <template v-for="rec in filteredCalls">
            <yeastar-p-series-recording
                :user-info="userInfo"
                :rec="rec"
                :selected-rec="selectedRec"
                @selectRecording="selectedRec = $event"
                :show-to="false"
            />
          </template>
<!--          <div class="pt-1 text-xs-center" v-if="loadMore">-->
<!--            <v-btn-->
<!--                small-->
<!--                class="pa-0 mx-2"-->
<!--                outline-->
<!--                color="secondary"-->
<!--                @click="() => getCalls('incoming')"-->
<!--                :disabled="loadingRecordings"-->
<!--                :loading="loadingRecordings"-->
<!--            >Load More-->
<!--            </v-btn>-->
<!--          </div>-->
        </div>
      </div>
<!--      <div v-else style="max-height: 400px; overflow-y: auto">-->
<!--        <template v-for="rec in filteredCalls">-->
<!--          <recording-->
<!--              :ext="selectedExt"-->
<!--              :user-info="userInfo"-->
<!--              :rec="rec"-->
<!--              :selected-rec-i-d="selectedRec"-->
<!--              @selectRecording="selectedRec = $event"-->
<!--          />-->
<!--        </template>-->
<!--        <div class="pt-1 text-xs-center" v-if="loadMore">-->
<!--          <v-btn-->
<!--              small-->
<!--              class="pa-0 mx-2"-->
<!--              outline-->
<!--              color="secondary"-->
<!--              @click="() => getCalls()"-->
<!--              :disabled="loadingRecordings"-->
<!--              :loading="loadingRecordings"-->
<!--          >Load More-->
<!--          </v-btn>-->
<!--        </div>-->
<!--      </div>-->
    </template>
    <template slot="actions">
      <div style="width: 100%" class="text-xs-right">
        <v-btn
            :disabled="selectedRec == null"
            :loading="linkingToTask"
            small
            color="secondary"
            flat
            @click="linkRecordingToTask"
        >
          <v-icon left>link</v-icon>
          Link To Task
        </v-btn>
      </div>
    </template>
  </app-input-dlg>
</template>

<script>
import InputDlg from "../../../../components/General/InputDlg";
import {codeBaseMixin} from "../../../../codeBaseMixin";
import YeastarPSeriesRecording from "./YeastarPSeriesRecording.vue";
import {eventBus} from "../../../../main";

export default {
  mixins: [codeBaseMixin],
  components: {YeastarPSeriesRecording, appInputDlg: InputDlg},
  props: {
    userInfo: Object,
    taskID: Number,
    task: Object,
    stepID: Number,
    value: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      recordings: [],
      loadingRecordings: false,
      selectedRec: null,
      linkingToTask: false,
      searchStr: null,
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        if (val) {
          eventBus.$emit('AudioPlay')
          this.searchStr = null;
          this.selectedRec = null;
          this.recordings = [];
          this.recordingsOutgoing = [];
          this.getCalls();
        }
      }
    }
  },
  
  methods: {
    // "ID": "2024110413344961B9E",
// "Time": "04/11/2024 13:34:49",
// "Call From": "Andisha<2>",
// "Call To": "0860665544",
// "Call Duration": "19",
// "Ring Duration": "2",
// "Talk Duration": "17",
// "Status": "ANSWERED",
// "Reason": "Andisha<2> hangup",
// "Source Trunk": null,
// "Destination Trunk": "FirstNet-PortaOne",
// "Communication Type": "Outbound",
// "DID": null,
// "DOD": "0310015503",
// "Caller IP Address": "102.218.55.117:57922",
// "PIN Code": null,
// "Recording File":
    
    linkRecordingToTask() {
      this.linkingToTask = true;
      this.fetch({
        method: 'POST', url: `/yeastarPSeries/linkRecordingToTask`, body: JSON.stringify({
          taskID: this.taskID,
          stepID: this.stepID,
          newFilename: `${this.selectedRecording['Recording File']}`,
          recording: this.selectedRecording['Recording File'],
        })
      })
          .then((data) => {
            this.task.structure.documents.push(data)
            this.linkingToTask = false;
            this.$emit('addedDocument', false)
            this.$emit('input', false)
          })
          .catch((e) => {
            // eslint-disable-next-line
            console.log(e)
            this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
            this.linkingToTask = false;
          })
    },
    
    extChanged() {
      this.selectedRec = null;
      this.recordings = [];
      this.recordingsOutgoing = [];
      this.getCalls()
    },
    
    getCalls(type) {
      this.$nextTick(() => {
        this.loadingRecordings = true;

        this.fetch({
          method: 'GET',
          url: `/yeastarPSeries/recordingsList`
        })
            .then((data) => {
              this.recordings = data.map(row => ({
                ...row,
                Time: new Date(row.Time)
              })).reverse();
              this.loadingRecordings = false;
            })
            .catch((e) => {
              // eslint-disable-next-line
              console.log(e)
              this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
              this.loadingRecordings = false;
            })
      })
    },
    
    
  },
  
  computed: {
    filteredCalls() {
      return this.recordings.filter(v => this.searchStr ? JSON.stringify(v).toLowerCase().includes(this.searchStr.toLowerCase()) : true)
    },
    selectedRecording() {
      try {
        return this.recordings.find(obj => obj['Recording File'] === this.selectedRec);
      } catch (e) {
        // eslint-disable-next-line
        return null
      }
    },
  },
  
}
</script>

<style scoped>

</style>
