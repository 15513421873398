<template>
    <v-layout column fill-height class="scrollable">
        <v-flex shrink class="pa-2">
            <v-text-field
                    label="Search Rules"
                    solo
                    v-model="filterString"
                    color="primary"
                    hide-details
                    style="width: 100%;"
                    class="ma-0 pa-0"
                    prepend-inner-icon="search"
                    lazy
                    clearable
            ></v-text-field>
        </v-flex>
        <v-flex shrink class="pa-2">
            <v-layout row align-center>
                <v-flex xs12>
                    <v-switch
                            v-model="previewRules"
                            label="Rules Preview"
                            hide-details
                            color="secondary"
                            class="pa-0 ma-0"
                    ></v-switch>
                </v-flex>
                <v-flex shrink v-if="previewRules">
                    <v-btn
                            small
                            flat
                            color="secondary"
                            class="pa-0 ma-0"
                            @click="$emit('clearPreviewValues')"
                    >
                        <v-icon left>clear</v-icon>
                        Clear Values
                    </v-btn>
                </v-flex>

            </v-layout>
        </v-flex>

        <v-flex shrink><v-divider></v-divider></v-flex>
        <v-flex xs12 class="scrollable pb-2" id="RulesContainer">
            <div v-for="rule in filteredRules" :key="rule.id">
                <app-rule-bar-rule
                        :editRule="rule"
                        :selectLists="selectLists"
                        :fields="fields"
                        :steps="steps"
                        :userInfo="userInfo"
                        @editCalled="$emit('editCalled', rule)"
                        @addCalled="$emit('addCalled', rule)"
                        @ruleDrop="$emit('ruleDrop', $event)"
                        @ruleRemoved="$emit('ruleRemoved', $event)"
                />
            </div>
        </v-flex>

        <app-input-dlg
                :show-dialog="showLoadingRules"
                title="Loading"
                :cancel-button="true"
                :show-input-box="false"
                @dismiss="showLoadingRules = false"
                :body-padding="false"
        >
            <template slot="body">
                <div class="pa-2 body-2">
                    Please Wait Building {{rules.length}} Rule/s
                </div>
                <div style="width: 100%" class="text-xs-center pa-3">
                    <v-progress-circular
                            :size="40"
                            :width="5"
                            color="primary"
                            indeterminate
                    ></v-progress-circular>
                </div>
            </template>
        </app-input-dlg>

    </v-layout>
</template>

<script>
    import RuleBarRule from "./RuleBarRule";
    import {codeBaseMixin} from "../../../../../codeBaseMixin";
    import InputDlg from "../../../../../components/General/InputDlg";

    export default {
        mixins: [codeBaseMixin],
        components: {
            appInputDlg: InputDlg,
            appRuleBarRule: RuleBarRule
        },
        props: {
            userInfo: Object,
            steps: Array,
            fields: Array,
            rules: Array,
            ruleRef: Object,
            selectLists: Array,
            value: String,
            previewRulesValue: Boolean,

        },
        data() {
            return {
                showLoadingRules: false
            }
        },
        computed: {
            filteredRules() {
                if (this.filterString != null && this.filterString != '') {
                    let tIDS = this.filterStringRef.reduce((list, item) => {
                        if (item.searchStr.toLowerCase().includes(this.filterString.toLowerCase())) {
                            list.push(item.id);
                        }
                        return list;
                    }, []);

                    return this.rules.filter(obj => tIDS.includes(obj.id))
                        .sort((a,b) => a['id'] < b['id'] ? -1 : 1);
                } else {
                    return this.rules.sort((a,b) => a['id'] < b['id'] ? -1 : 1);
                }
            },
            filterString: {
                get() {
                    return this.value
                },
                set(newValue) {
                    this.$emit('input', newValue)
                }
            },
            previewRules: {
                get() {
                    return this.previewRulesValue
                },
                set(newValue) {
                    if (newValue == true) {
                        this.showLoadingRules = true;
                        setTimeout(() => {

                            this.$emit('inputPreview', newValue)
                            this.$nextTick(() => {
                                this.showLoadingRules = false;
                            })
                        }, 500)

                    } else {
                        this.showLoadingRules = false;
                        this.$emit('inputPreview', newValue)
                    }


                }
            },
            filterStringRef() {
                let objectFields = {};
                this.fields.forEach(field => {
                    objectFields[field.id] = field.description
                });
                let objectSteps = {};
                this.steps.forEach(step => {
                    objectSteps[step.id] = step.description
                });

                return this.rules.reduce((list, rule) => {

                    let str = [];
                    str.push(rule.description);

                    //ShowFields
                    if (Array.isArray(rule.showFields)) {
                        rule.showFields.forEach(showField => {
                            str.push(objectFields[showField])
                        })
                    }
                    //ConditionFields
                    if (Array.isArray(rule.conditions)) {
                        rule.conditions.forEach(condition => {
                            str.push(objectFields[condition.fk_field])
                        })
                    }
                    //Show Steps
                    if (Array.isArray(rule.showSteps)) {
                        rule.showSteps.forEach(step => {
                            str.push(objectSteps[step.fk_field])
                        })
                    }

                    list.push({
                        id: rule.id,
                        searchStr: str.join(' ')
                    });

                    return list
                }, [])
            }

        }
    }
</script>

<style scoped>

</style>
