<template>
    <v-layout column fill-height class="scrollable">
<!--Loading-->
        <v-flex xs12 class="fill-height" v-if="loadingData">
            <v-layout column fill-height justify-center align-center>
                <v-progress-circular
                    :size="40"
                    :width="5"
                    color="primary"
                    indeterminate
                ></v-progress-circular>
            </v-layout>
        </v-flex>
<!--View Detail-->
        <template v-else>
<!--            <v-flex shrink>-->
<!--                <v-btn @click="loadData" small>LoadData</v-btn>-->
<!--                <v-btn @click="downloadCSV">downloadCSV</v-btn>-->
<!--            </v-flex>-->
            <v-flex shrink>
                <v-layout row align-center>
                    <v-flex shrink>
                        <v-menu offset-y v-model="columnMenu" :close-on-content-click="false">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    small
                                    color="secondary"
                                    class="px-2 py-0"
                                    v-on="on"
                                >
                                    Select Columns
                                </v-btn>
                            </template>
                            <v-card width="300" class="white scrollable">
                                <div style="max-height: 500px" class="scrollable" v-if="columnMenu">
                                    <multi-select-list
                                        :show-action-buttons="false"
                                        :show-check-boxes="true"
                                        :multiple="true"
                                        headline-key="name"
                                        item-key="prop"
                                        :return-object="false"
                                        v-model="selectedColumns"
                                        :show-filter="true"
                                        :items="columns"
                                        :dark="true"
                                        filter-label="Select Columns"
                                    />
                                </div>
                                <div class="pa-1 text-xs-center">
                                    <v-btn
                                        small
                                        color="secondary"
                                        class="px-2 py-0"
                                        @click="columnMenu = !columnMenu"
                                    >
                                        done
                                    </v-btn>
                                </div>
                            </v-card>
                        </v-menu>

                    </v-flex>

                    <v-flex shrink class="pl-2">
                        <v-switch
                            v-model="showHiddenFields"
                            label="Include Hidden Fields"
                            color="secondary"
                            class="pa-0 ma-0"
                            dense
                            hide-details
                            style="white-space: nowrap"
                        ></v-switch>
                    </v-flex>

                    <v-flex xs12></v-flex>

                    <v-flex>
                        <v-btn
                            small
                            color="secondary"
                            class="px-2 py-0"
                            @click="downloadCSV"
                            :loading="downloadingData"
                            :disabled="downloadingData"
                        >
                            <v-icon small left>cloud_download</v-icon>
                            Export Data
                        </v-btn>
                    </v-flex>


                </v-layout>
            </v-flex>

            <v-divider></v-divider>

            <v-flex xs12 class="scrollable" v-if="viewCols != null">
                <v-grid
                    :theme="$store.state.darkTheme ? 'darkCompact' : 'compact'"
                    :source="rows"
                    :columns="viewCols"
                    :autoSizeColumn="{
                            mode: 'autoSizeOnTextOverlap',
                        }"
                    :resize="true"
                    :export="false"
                    @beforeCellFocus="beforeEdit"
                    ref="dataGrid"
                ></v-grid>
            </v-flex>
        </template>
    </v-layout>
</template>

<script>
import {codeBaseMixin} from "@/codeBaseMixin";
import {mapGetters} from 'vuex';
import {getDateTimeSpaceSeperated} from "@/codeFunctions";

import VGrid from "@revolist/vue-datagrid";
import MultiSelectList from "@/components/Multi Select List/MultiSelectList";

export default {
    mixins:[codeBaseMixin],
    components: {
        MultiSelectList,
        VGrid,
    },
    props: {
        selectedDataset: Object,
    },
    data() {
        return {
            loadingData: false,
            columns: [],
            rows: [],
            lastLoadIndex: 0,
            showHiddenFields: false,
            selectedColumns: [],
            columnMenu: false,
            downloadingData: false
        }
    },
    watch: {
        selectedDataset: {
            immediate: true,
            handler() {
                this.loadData()
            }
        }
    },
    methods: {
        beforeEdit(event) {
            event.preventDefault();
        },

        resetSelectedCols() {
            this.showHiddenFields = false;
            this.selectedColumns = this.columns.map(col => {return col.prop});
        },

        downloadCSV() {
            this.downloadingData = true;
            this.$newReqDownloadFile(
                    'GET',
                    `dsmData/fullDatasetData/download/${this.userInfo.entityID}/${this.selectedDataset.id}`,
                    null,
                    `${this.selectedDataset.description} ${getDateTimeSpaceSeperated(new Date().toISOString())}.xlsx`,
            )
                    .catch(e => {
                        console.log(e)
                        this.$snack.networkError();
                    })
                    .finally(() => {
                        this.downloadingData = false;
                    });
        },

        loadData() {
            ++this.lastLoadIndex;
            let iLoadIndex = this.lastLoadIndex;
            this.loadingData = true;
            this.fetch({method: 'GET', url: `/dsmData/fullDatasetData/${this.userInfo.entityID}/${this.selectedDataset.id}`})
                .then((data) => {
                    if (iLoadIndex === this.lastLoadIndex) {
                        this.columns = data.columns;
                        this.rows = data.rows;
                        this.loadingData = false;
                        this.resetSelectedCols();
                    }
                })
                .catch((e) => {
                    if (iLoadIndex === this.lastLoadIndex) {
                        // eslint-disable-next-line
                        console.log(e)
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                        this.loadingData = false;
                    }
                })
        }
    },
    computed: {
        ...mapGetters('userInfo', ['userInfo']),

        viewCols() {
            if (this.columns != null) {
                return this.columns.reduce((list,col) => {
                    if ((!this.showHiddenFields && col.visible === 0) || (!this.selectedColumns.includes(col.prop)))
                        return list;

                    return list.concat({
                        name: col.name,
                        prop: col.prop,
                        autoSize: [1, 2, 3, 4, 5].includes(col.type) || col.prop === 'tl_Folder',
                        sortable: [1, 2, 3, 4, 5, 99].includes(col.type),
                        filter: [1, 2, 3, 4, 5, 99].includes(col.type),
                        editor: false,
                        pin: col.prop === 'tl_Folder' ? 'colPinStart' : null
                    })


                }, [])
            } else {
                return null
            }
        }
    },

}
</script>

<style scoped>

</style>