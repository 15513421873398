<template>
    <div style="width: 100%" class="px-1 pb-1">
        <v-card class="pa-1" flat>
            <!--Input Area-->
                <template>
                <!--{{highlightedIndex}}-->
                <!--{{filteredTasks.length}}-->

                <v-menu
                        offset-y
                        v-model="searchMenuShown"
                        full-width
                        :close-on-content-click="false"
                        style="overflow-y: hidden;"
                >
                    <v-text-field
                            prepend-icon="search"
                            label="Search"
                            class="pa-0 ma-0"
                            hide-details
                            slot="activator"
                            single-line
                            @keydown.down="moveCursorDown()"
                            @keydown.up="moveCursorUp()"
                            @keydown.enter="runMenuCallbackMethod()"
                            v-model="refString"
                            ref="refArea"
                            @input="searchMenuShown = true"
                    >
                        <template slot="append-outer" v-if="filters.length > 0">
                            <v-btn small flat color="red" icon class="pa-0 ma-0" @click="filters = []; $refs.refArea.focus(); searchMenuShown = false"><v-icon>close</v-icon></v-btn>
                        </template>
                    </v-text-field>

                    <v-card style="overflow-y: hidden; max-width: 500px; overflow-y: auto; max-height: 450px" v-if="searchMenuShown">

                        <template v-for="(item) in menuItemsWithIndex">
                            <div
                                    :key="item.id"
                            >
                                <task-filter-item
                                        :item="item"
                                        :click="runMenuCallbackMethod"
                                        :highlighted-index="highlightedIndex"
                                        @highlight="highlightedIndex = $event"
                                />
                            </div>
                        </template>

                        <template v-if="displayLimit < menuItems.length - 1">

                            <v-divider></v-divider>
                            <div style="width: 100%;" class="text-xs-center pa-2">
                                <v-btn small block flat color="secondary" @click="displayLimit = displayLimit + displayLimitDefault" class="py-0 ma-0">Load More Items</v-btn>
                            </div>
                        </template>








                    </v-card>
                </v-menu>
            </template>

            <!--Chips Area-->
            <v-layout row wrap>
                <v-flex shrink v-for="filter in filters" class="pt-1 pr-2">

                    <v-layout row align-center class="white--text">
                        <v-flex shrink class="caption pl-1 primary"
                                style="border-bottom-left-radius: 3px; border-top-left-radius: 3px;">
                            <v-icon v-if="filter.type == 'f'" small style="margin-bottom: -1px" color="white">folder</v-icon>
                            <v-icon v-if="filter.type == 'fg'" small style="margin-bottom: -1px" color="white">folder_shared</v-icon>
                            <v-icon v-if="filter.type == 't'" small  color="white">edit</v-icon>
                            <img v-if="filter.type == 'w'" src="/workflows.svg" alt="" width="13" style="margin-bottom: -2px">
                            <img v-if="filter.type == 'u'" src="/users.svg" alt="" width="13" style="margin-bottom: -2px">
                            <img v-if="filter.type == 's'" src="/sub_users.svg" alt="" width="13" style="margin-bottom: -2px">
                        </v-flex>
                        <v-flex shrink class="caption pl-1 primary">
                            {{displayString(filter.string)}}&nbsp;
                        </v-flex>
                        <v-flex shrink class="px-1 close-chip-icon"
                                style="border-bottom-right-radius: 3px; border-top-right-radius: 3px;"
                                @click="removeFilter(filter)"
                        >
                            <div
                                    class="caption font-weight-bold"
                            >
                                &times;
                            </div>
                        </v-flex>
                    </v-layout>

                </v-flex>
            </v-layout>
            <!--Menus-->
            <!--<v-btn @click="test1">-->
            <!--test-->
            <!--</v-btn>-->
        </v-card>
    </div>
</template>

<script>
    import {codeBaseMixin} from "../../../codeBaseMixin";

    import TaskFilterItem from './TaskFilterItem'

    export default {
        components: {TaskFilterItem},
        mixins: [codeBaseMixin],
        props: {
            preTasks: Array,
            value: Array,
            folderGroups: Array,
            setPostTasks: Function,
            localData: Object,
        },
        data() {
            return {
                filters: [],
                maxMenuOptions: 0,
                highlightedIndex: -1,
                searchMenuShown: false,
                mainMenu: [
                    {
                        title: 'Type To Search',
                        iconType: 'icon',
                        icon: 'search',
                        callback: () => {
                            this.refString = '';
                        }
                    },
                    {
                        title: 'Workflows',
                        iconType: 'svg',
                        icon: '../../../workflows.svg',
                        callback: () => {
                            this.refString = 'w:';
                        }
                    },
                    {
                        title: 'Owners',
                        iconType: 'svg',
                        icon: '../../../users.svg',
                        callback: () => {
                            this.refString = 'u:';
                        }
                    },
                    {
                        title: 'Sub Owners',
                        iconType: 'svg',
                        icon: '../../../sub_users.svg',
                        callback: () => {
                            this.refString = 's:';
                        }
                    },
                    {
                        title: 'Folder Groups',
                        iconType: 'icon',
                        icon: 'folder_shared',
                        callback: () => {
                            this.refString = 'fg:';
                        }
                    },
                    {
                        title: 'Folders',
                        iconType: 'icon',
                        icon: 'folder',
                        callback: () => {
                            this.refString = 'f:';
                        }
                    },
                ],
                refString: '',
                resetCalc: 0,
                displayLimitDefault: 50,
                displayLimit: 0
            }
        },
        watch: {

            "filters": {
                immediate: true,
                deep: true,
                handler(val) {
                    this.setPostTasks(this.filteredTasks.filterAll);
                    this.searchMenuShown = false;


                    this.$emit('filtersUpdated', val)
                }
            },

            searchMenuShown() {
                this.highlightedIndex = -1;
            },

            preTasks() {
                this.setPostTasks(this.filteredTasks.filterAll);
            },

            menuItems: {
                immediate: true,
                handler() {
                    this.displayLimit = this.displayLimitDefault
                }
            }
        },
        mounted() {
            this.setPostTasks(this.filteredTasks.all);
        },
        methods: {
            displayString(string) {
                if (string.length > 30) {
                    return string.split('').reduce((word, char, index) => {
                        if ([27,28,29].includes(index)) {
                            word.push('.')
                        } else {
                            if (index <= 26) {
                                word.push(char)
                            }
                        }
                        return word
                    }, []).join('')
                } else {
                    return string
                }
            },

            removeFilter(filter) {


                this.filters = this.filters.reduce((filters, obj) => {
                    if (obj.type == filter.type && `'${obj['string']}'` == `'${filter['string']}'`){
                        return filters
                    } else {
                        filters = filters.concat([obj])
                        return filters
                    }

                }, [])
            },

            runMenuCallbackMethod(highlightedIndex) {
                if (
                    (typeof highlightedIndex == 'undefined' && this.highlightedIndex >= 0 && this.highlightedIndex <= this.maxMenuOptions && this.searchMenuShown == true)
                    ||
                    (typeof highlightedIndex != 'undefined' && highlightedIndex >= 0 && highlightedIndex <= this.maxMenuOptions && this.searchMenuShown == true)
                ) {
                    let item = this.menuItems[typeof highlightedIndex != 'undefined' ? highlightedIndex : this.highlightedIndex];
                    if (typeof item.callback != 'undefined') {
                        item.callback();
                    }
                    this.$refs.refArea.focus();
                } else {
                    this.searchMenuShown = true;
                }
            },

            moveCursorUp() {
                this.highlightedIndex = --this.highlightedIndex;
                if (this.highlightedIndex <= 0) {this.highlightedIndex = 0;}
                this.searchMenuShown = true;
            },

            moveCursorDown() {
                this.highlightedIndex = ++this.highlightedIndex;
                if (this.highlightedIndex > this.maxMenuOptions) {this.highlightedIndex = 0;}
                this.searchMenuShown = true;
            },

            setMenuItems(max, index) {
                this.maxMenuOptions = max;
                this.highlightedIndex = index;
            },

            getTaskCountsForFolders() {
                let counts = {};

                this.filteredTasks.exFolders.forEach(task => {

                    let refVar = task.folderName != null && task.folderName != '' ? task.folderName : 'Task Folder'

                    if (typeof counts[refVar] == 'undefined') {
                        counts[refVar] = 0
                    }

                    ++counts[refVar]
                });

                return counts
            },
            getTaskCountsForFolderGroups() {
                let counts = {};

                this.filteredTasks.exFolderGroups.forEach(task => {

                    let refVar = task.folderGroupNames != null && task.folderGroupNames != '' ? task.folderGroupNames : 'Task Folder'

                    if (typeof counts[refVar] == 'undefined') {
                        counts[refVar] = 0
                    }

                    ++counts[refVar]
                });

                return counts
            },
            getTaskCountsForUsers() {
                let counts = {};

                this.filteredTasks.exUsers.forEach(task => {

                    let refVar = `${task.ownerName} ${task.ownerSurname}`;

                    if (typeof counts[refVar] == 'undefined') {
                        counts[refVar] = 0
                    }

                    ++counts[refVar]
                });

                return counts
            },

            getTaskCountsForSubUsers() {
                let counts = {};

                this.filteredTasks.exSubUsers.forEach(task => {

                    let refVar = `${task.subOwnerName} ${task.subOwnerSurname}`;

                    if (typeof counts[refVar] == 'undefined') {
                        counts[refVar] = 0
                    }

                    ++counts[refVar]
                });

                return counts
            },

            getTaskCountsForWorkflows() {
                let counts = {};

                this.filteredTasks.exWorkflows.forEach(task => {

                    let refVar = task.workflowName;

                    if (typeof counts[refVar] == 'undefined') {
                        counts[refVar] = 0
                    }

                    ++counts[refVar]
                });

                return counts
            }
        },
        computed: {
            filteredTasks() {
                if (this.filters.length <= 0) {
                    return {
                        exWorkflows: this.preTasks,
                        exUsers: this.preTasks,
                        exSubUsers: this.preTasks,
                        exFolderGroups: this.preTasks,
                        exFolders: this.preTasks,
                        all: this.preTasks,
                        filterAll: this.preTasks
                    }
                } else {

                    let tasks = {
                        exWorkflows: [],
                        exUsers: [],
                        exSubUsers: [],
                        exFolderGroups: [],
                        exFolders: [],
                        all: [],
                        filterAll: []
                    };

                    let textFilters = this.filters.filter(obj => obj.type == 't').map(obj => {
                        return obj.string
                    });
                    let workflowFilters = this.filters.filter(obj => obj.type == 'w').map(obj => {
                        return obj.string
                    });
                    let userFilters = this.filters.filter(obj => obj.type == 'u').map(obj => {
                        return obj.string
                    });
                    let subUserFilters = this.filters.filter(obj => obj.type == 's').map(obj => {
                        return obj.string
                    });
                    let folderGroupFilters = this.filters.filter(obj => obj.type == 'fg').map(obj => {
                        return obj.string
                    });
                    let folderFilters = this.filters.filter(obj => obj.type == 'f').map(obj => {
                        return obj.string
                    });


                    this.preTasks.forEach(task => {
                        //Text Filter - AND
                        let bText = true;
                        let searchStr = `#${task.taskID} ${task.searchStr} ${task.title} ${task.ownerName} ${task.ownerSurname} ${task.folderName} ${task.folderGroupNames} ${task.workflowName} ${task.statusText}`;
                        textFilters.forEach(filter => {
                            if (!searchStr.toLowerCase().includes(filter.toLowerCase().trim())) {
                                bText = false;
                            }
                        });

                        //Workflow Filter - OR
                        let bWorkflow = workflowFilters.length == 0 || workflowFilters.includes(task.workflowName);

                        //Users Filter - OR
                        let bUser = userFilters.length == 0 || userFilters.includes(`${task.ownerName} ${task.ownerSurname}`);

                        //Sub Users Filter - OR
                        let bSubUser = subUserFilters.length == 0 || subUserFilters.includes(`${task.subOwnerName} ${task.subOwnerSurname}`);

                        //Folder Group Filter - OR
                        let bFolderGroup = folderGroupFilters.length == 0 || folderGroupFilters.includes(task.folderGroupNames);

                        //Folder Filter - OR
                        let bFolder = folderFilters.length == 0 || folderFilters.includes(task.folderName);

                        //Push Task Into Arrays
                        if (bText) {
                            tasks.all.push(task)
                        }
                        if (bText && bUser && bFolderGroup && bFolder && bSubUser) {
                            tasks.exWorkflows.push(task)
                        }
                        if (bText && bWorkflow && bFolderGroup && bFolder && bSubUser) {
                            tasks.exUsers.push(task)
                        }
                        if (bText && bWorkflow && bFolderGroup && bFolder && bUser) {
                            tasks.exSubUsers.push(task)
                        }
                        if (bText && bWorkflow && bUser && bFolder && bSubUser) {
                            tasks.exFolderGroups.push(task)
                        }
                        if (bText && bWorkflow && bUser && bFolderGroup && bSubUser) {
                            tasks.exFolders.push(task)
                        }
                        if (bText && bWorkflow && bUser && bFolderGroup && bFolder && bSubUser) {
                            tasks.filterAll.push(task)
                        }
                    });

                    return tasks
                }

            },

            menuItemsWithIndex() {
                return this.menuItems.reduce((arr, item, index) => {
                    if (index <= this.displayLimit) {
                        item.id = index;
                        arr.push(item)
                    }
                    return arr
                }, []);
            },

            menuItems() {
                if (this.refString == null || this.refString == '') {
                    this.setMenuItems(this.mainMenu.length -1, 0);
                    return this.mainMenu;
                } else {
                    switch (this.refString.substring(0,2).toLowerCase()) {
                        case 'w:' : {
                            this.setMenuItems(this.workflowMenuItems.length, -1);
                            return this.workflowMenuItems
                        }
                        case 'u:' : {
                            this.setMenuItems(this.userMenuItems.length, -1);
                            return this.userMenuItems;
                        }
                        case 's:' : {
                            this.setMenuItems(this.subUserMenuItems.length, -1);
                            return this.subUserMenuItems;
                        }
                        case 'f:' : {

                            this.setMenuItems(this.folderMenuItems.length, -1);
                            return this.folderMenuItems;

                        }
                        default : {
                            if (this.refString.substring(0,3).toLowerCase() == 'fg:') {
                                this.setMenuItems(this.folderGroupMenuItems.length, -1);
                                return this.folderGroupMenuItems;
                            } else {
                                this.setMenuItems(1,0);
                                return [{
                                    title: 'Enter To Search',
                                    icon: 'keyboard_return',
                                    callback: () => {
                                        if (this.refString != null && this.refString != '') {
                                          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                                            this.filters.push({
                                                type: 't',
                                                string: this.refString
                                            });
                                          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                                            this.refString = '';
                                        }
                                    }
                                }]
                            }
                        }
                    }
                }
            },

            workflowMenuItems() {
                try {
                    let searchString = this.refString.substring(2, this.refString.length);
                    let filteredWorkflows = this.filters.reduce((filteredWorkflows, filter) => {
                        if (filter.type == 'w') {
                            return filteredWorkflows.concat(filter.string)
                        } else {
                            return filteredWorkflows
                        }
                    }, []);
                    let tItems = this.localData.workflows.reduce((workflows, workflow) => {
                        if (workflow.access) {
                            if (!filteredWorkflows.includes(workflow.description) && !workflows.includes(workflow.description)) {
                                if (!this.blankString(searchString)) {
                                    if (workflow.description.toLowerCase().includes(searchString.toLowerCase())) {
                                        workflows = workflows.concat(workflow.description)
                                    }
                                } else {
                                    workflows = workflows.concat(workflow.description)
                                }
                            }
                        }
                        return workflows
                    }, []);
                    let counts = this.getTaskCountsForWorkflows();
                    return tItems.sort((a,b) => a < b ? -1 : 1).map(item => {
                        return {
                            title: item,
                            count: counts[item],//this.filteredTasks.exWorkflows.filter(obj => obj.workflowName.toLowerCase() == item.toLowerCase()).length,
                            iconType: 'svg',
                            icon: '../../../workflows.svg',
                            callback: () => {
                              // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                                this.filters.push({
                                    type: 'w',
                                    string: item
                                });
                              // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                                this.refString = '';
                            }
                        }
                    }).map(obj => {if (typeof obj.count == 'undefined') {obj.count = 0;} return obj}).sort((a,b) => (a['count'] > 0) > (b['count'] > 0) ? -1 : 1);
                } catch (e) {
                    // eslint-disable-next-line
                    console.log(e)
                    return [];
                }
            },

            userMenuItems() {
                try {
                    let searchString = this.refString.substring(2, this.refString.length);
                    let filteredUsers = this.filters.reduce((filteredUsers, filter) => {
                        if (filter.type == 'u') {
                            return filteredUsers.concat(filter.string)
                        } else {
                            return filteredUsers
                        }
                    }, []);

                    let tItems = this.localData.users.reduce((users, user) => {
                        if (!filteredUsers.includes(`${user.name} ${user.surname}`) && !users.includes(`${user.name} ${user.surname}`)) {
                            if (!this.blankString(searchString)) {
                                if (`${user.name} ${user.surname}`.toLowerCase().includes(searchString.toLowerCase())) {
                                    users = users.concat(`${user.name} ${user.surname}`)
                                }
                            } else {
                                users = users.concat(`${user.name} ${user.surname}`)
                            }
                        }
                        return users
                    }, []);

                    let counts = this.getTaskCountsForUsers();

                    return tItems.sort((a,b) => a < b ? -1 : 1).map(item => {
                        return {
                            title: item,
                            count: typeof counts[item] != 'undefined' ? counts[item] : 0,//this.filteredTasks.exUsers.filter(obj => `${obj.ownerName} ${obj.ownerSurname}`.toLowerCase() == item.toLowerCase()).length,
                            iconType: 'svg',
                            icon: '../../../users.svg',
                            callback: () => {
                              // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                                this.filters.push({
                                    type: 'u',
                                    string: item
                                });
                              // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                                this.refString = '';
                            }
                        }
                    }).map(obj => {if (typeof obj.count == 'undefined') {obj.count = 0;} return obj}).sort((a,b) => (a['count'] > 0) > (b['count'] > 0) ? -1 : 1);
                } catch (e) {
                    // eslint-disable-next-line
                    console.log(e)
                    return [];
                }
            },

            folderGroupMenuItems() {
                try {
                    let searchString = this.refString.substring(3, this.refString.length);

                    let filteredFolderGroups = this.filters.reduce((filteredFolderGroups, filter) => {
                        if (filter.type == 'fg') {
                            return filteredFolderGroups.concat(filter.string)
                        } else {
                            return filteredFolderGroups
                        }
                    }, []);

                    let tItems = this.localData.folderGroups.reduce((folderGroups, folderGroup) => {
                        if (folderGroup.access) {
                            if (!filteredFolderGroups.includes(folderGroup.description) && !folderGroups.includes(folderGroup.description != null ? folderGroup.description : 'Task Folder')) {
                                if (searchString != '' && searchString != '') {
                                    if ((folderGroup.description != null ? folderGroup.description : 'Task Folder').toLowerCase().includes(searchString.toLowerCase())) {
                                        folderGroups.push(folderGroup.description != null ? folderGroup.description : 'Task Folder')
                                    }
                                } else {
                                    folderGroups = folderGroups.concat(folderGroup.description != null ? folderGroup.description : 'Task Folder')
                                }
                            }
                        }
                        return folderGroups
                    }, []);
                    let counts = this.getTaskCountsForFolderGroups();
                    return tItems.sort((a,b) => a < b ? -1 : 1).map(item => {
                        return {
                            title: item,
                            count: counts[item], //this.filteredTasks.exFolderGroups.filter(obj => typeof obj.folderGroupNames != 'undefined' && (obj.folderGroupNames == null ? 'Task Folder' : obj.folderGroupNames).toLowerCase() == item.toLowerCase()).length,
                            icon: 'folder_shared',
                            callback: () => {
                              // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                                this.filters.push({
                                    type: 'fg',
                                    string: item
                                });
                              // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                                this.refString = '';
                            }
                        }
                    }).map(obj => {if (typeof obj.count == 'undefined') {obj.count = 0;} return obj}).sort((a,b) => (a['count'] > 0) > (b['count'] > 0) ? -1 : 1);
                } catch (e) {
                    // eslint-disable-next-line
                    console.log(e)
                    return [];
                }
            },

            folderMenuItems() {

                try {
                    let searchString = this.refString.substring(2, this.refString.length);
                    let filteredFolders = this.filters.reduce((filteredFolders, filter) => {
                        if (filter.type == 'f') {
                            return filteredFolders.concat(filter.string)
                        } else {
                            return filteredFolders
                        }
                    }, []);
                    let tItems = this.localData.folders.reduce((folders, folder) => {
                        if (folder.access) {
                            if (!filteredFolders.includes(folder.description) && !folders.includes(folder.description != null ? folder.description : 'Task Folder')) {
                                if (searchString != null && searchString != '') {
                                    if (((folder.description != null ? folder.description : 'Task Folder')).toLowerCase().includes(searchString.toLowerCase())) {
                                        folders = folders.concat(folder.description != null ? folder.description : 'Task Folder')
                                    }
                                } else {
                                    folders = folders.concat(folder.description != null ? folder.description : 'Task Folder')
                                }
                            }
                        }
                        return folders
                    }, []);
                    let counts = this.getTaskCountsForFolders();
                    let tReturn = tItems.sort((a,b) => a < b ? -1 : 1).map(item => {
                        return {
                            title: item,
                            count: counts[item],
                            icon: 'folder',
                            callback: () => {
                              // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                                this.filters.push({
                                    type: 'f',
                                    string: item
                                });
                              // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                                this.refString = '';
                            }
                        }
                    }).map(obj => {if (typeof obj.count == 'undefined') {obj.count = 0;} return obj}).sort((a,b) => (a['count'] > 0) > (b['count'] > 0) ? -1 : 1);
                    return tReturn
                } catch (e) {
                    // eslint-disable-next-line
                    console.log(e)
                    return [];
                }
            },

            subUserMenuItems() {
                try {
                    let searchString = this.refString.substring(2, this.refString.length);
                    let filteredUsers = this.filters.reduce((filteredUsers, filter) => {
                        if (filter.type == 's') {
                            return filteredUsers.concat(filter.string)
                        } else {
                            return filteredUsers
                        }
                    }, []);

                    let tItems = this.localData.users.reduce((users, user) => {
                        if (!filteredUsers.includes(`${user.name} ${user.surname}`) && !users.includes(`${user.name} ${user.surname}`)) {
                            if (!this.blankString(searchString)) {
                                if (`${user.name} ${user.surname}`.toLowerCase().includes(searchString.toLowerCase())) {
                                    users = users.concat(`${user.name} ${user.surname}`)
                                }
                            } else {
                                users = users.concat(`${user.name} ${user.surname}`)
                            }
                        }
                        return users
                    }, []);

                    let counts = this.getTaskCountsForSubUsers();

                    return tItems.sort((a,b) => a < b ? -1 : 1).map(item => {
                        return {
                            title: item,
                            count: typeof counts[item] != 'undefined' ? counts[item] : 0,//this.filteredTasks.exUsers.filter(obj => `${obj.ownerName} ${obj.ownerSurname}`.toLowerCase() == item.toLowerCase()).length,
                            iconType: 'svg',
                            icon: '../../../sub_users.svg',
                            callback: () => {
                              // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                                this.filters.push({
                                    type: 's',
                                    string: item
                                });
                              // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                                this.refString = '';
                            }
                        }
                    }).map(obj => {if (typeof obj.count == 'undefined') {obj.count = 0;} return obj}).sort((a,b) => (a['count'] > 0) > (b['count'] > 0) ? -1 : 1);
                } catch (e) {
                    // eslint-disable-next-line
                    console.log(e)
                    return [];
                }
            },
        }
    }
</script>

<style scoped>
    .scroller {
        max-height: 450px;
    }

    .close-chip-icon {
        cursor: pointer;
        background: var(--v-primary-base);
        color: white;
    }

    .close-chip-icon:hover {
        background: var(--v-primary-lighten1);
    }
</style>
