<template>
    <v-layout row>
        <v-flex class="pl-2">
            <v-text-field
                    clearable
                    color="primary"
                    flat
                    hide-details
                    class="pa-0 ma-0"
                    single-line
                    :label="value.fieldDescription"
                    type="number"
                    pattern="^\d*(\.\d{0,2})?$"
                    :readonly="readOnly"
                    :value="value.value"
                    @change="val => value.value = val"
                    @keydown.enter="$emit('forceSave')"
            >
            </v-text-field>
        </v-flex>
    </v-layout>
</template>

<script>
    export default {
        props: {
            value: Object,
            readOnly: Boolean
        },
        watch: {
            "value.value": {
                handler(val) {
                    if (val) {
                        // Check For Decimal Point
                        if (val.indexOf('.') <= -1) {
                            // No Decimal
                            this.value.value = `${val}.00`
                        } else {
                            // Decimal Point
                            try {
                                this.value.value = parseFloat(val).toFixed(2)
                            } catch (e) {
                                console.log(e)
                            }
                        }

                    }
                }
            }
        }
    }
</script>

<style scoped>

</style>
