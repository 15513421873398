<template>
    <v-card class="fill-height scrollable">
        <v-layout column fill-height class="scrollable" style="overflow-x: hidden; background: var(--page-back)" >
            <v-flex shrink>
                <v-tabs
                        v-model="view"
                        slider-color="secondary"
                        color="primary"
                        grow
                >
                    <v-tab class="white--text">
                        Inbox
                    </v-tab>
                    <v-tab class="white--text">
                        Bin
                    </v-tab>
                </v-tabs>
            </v-flex>
            <v-flex shrink class="px-2 pb-1 pt-2">

                <v-autocomplete
                        :items="mailboxes"
                        color="primary"
                        solo
                        v-model="selectedMailboxRelay"
                        hide-details
                        class="pa-0 ma-0"
                        single-line
                        dense
                        @input="filterStr = null"
                >
                    <template slot="selection" slot-scope="data">
                        <v-avatar size="30" color="primary" class="white--text mr-2">
                            <v-icon color="white darken-1">{{ data.item.initials ? 'inbox' : 'all_inbox' }}</v-icon>
                        </v-avatar>
                        {{ data.item.description }}
                    </template>
                    <template slot="item" slot-scope="data">
                        <v-avatar size="30" color="primary" class="white--text mr-2">
                            <v-icon color="white darken-1">{{ data.item.initials ? 'inbox' : 'all_inbox' }}</v-icon>
                        </v-avatar>
                        {{ data.item.description }}
                    </template>
                </v-autocomplete>

            </v-flex>
            <v-flex class="px-2 pt-1 pb-2">
                <v-layout row>
                    <v-flex xs12>
                        <v-text-field
                                color="primary"
                                solo
                                v-model="filterStr"
                                hide-details
                                class="pa-0 ma-0"
                                single-line
                                dense
                                prepend-inner-icon="search"
                                label="Search Mails"
                                @keydown.enter="$emit('newSearch', filterStr)"
                                clearable
                                @click:clear="$emit('newSearch', null)"
                        >
                        </v-text-field>
                        <div class="primary--text caption text-xs-center pt-1" v-if="filterStr !== searchVal">
                            Press Enter to search
                        </div>
                    </v-flex>
                    <v-flex shrink>
                        <v-menu open-on-hover offset-y>
                            <template slot="activator">
                                <v-btn
                                        class="ml-2 mr-0"
                                        icon
                                        color="blue"
                                        flat
                                >
                                    <v-icon>info</v-icon>
                                </v-btn>
                            </template>
                            <v-card class="pa-2">
                                <div class="subheading">
                                    Retention Policy
                                </div>
                                <div class="pa-2">
                                    Mails are removed after 30 days
                                    <br>
                                    Deleted mails are removed after 7 days
                                </div>
                            </v-card>
                        </v-menu>
                    </v-flex>
                    <v-flex shrink>
                        <v-tooltip
                                top
                                :disabled="!(Array.isArray(selectedMailIDs) && selectedMailIDs.length)"
                        >
                            <template v-slot:activator="{on}">
                                <v-btn
                                        :disabled="!(Array.isArray(selectedMailIDs) && selectedMailIDs.length)"
                                        icon
                                        color="secondary"
                                        class="mx-0"
                                        flat
                                        @click="flagDelete(deletedView ? 0 : 1)"
                                        v-on="on"
                                >
                                    <v-icon>{{ deletedView ? 'inbox' : 'delete' }}</v-icon>
                                </v-btn>
                            </template>
                            {{ deletedView ? 'Restore' : 'Archive to Bin' }}
                        </v-tooltip>
                    </v-flex>
                </v-layout>
            </v-flex>

            <v-divider></v-divider>


            <v-flex
                    xs12
                    class="scrollable"
                    style="overflow-x: hidden; background: var(--page-back)"
                    ref="mailsBar"
                    @scroll="mailBarScrolled"
            >


                <div
                        v-for="mail in orderedVault"
                        :key="mail.id"
                        style="overflow-x: hidden"
                        :draggable="selectedMailIDs.includes(mail.id)"
                        @drag="$event.preventDefault()"
                        @dragstart="setLinkMails($event)"
                >
                    <mailbox-mail-bar-mail
                            :mail="mail"
                            :selectedMailIDs="selectedMailIDs"
                            :loadingMailIDs="loadingMailIDs"
                            @getMail="$emit('getMail', $event)"
                            :show-avatar="showAvatar"
                            :mailbox-ref="mailboxRef"
                    />
                </div>
                <div class="pa-2 text-xs-center" v-if="loadingMore">
                    <v-progress-circular
                            :size="30"
                            :width="3"
                            color="grey"
                            indeterminate
                    />
                </div>
            </v-flex>
        </v-layout>
    </v-card>

</template>

<script>
import {mapGetters} from 'vuex'
import MailboxMailBarMail from "@/Mail/Mailboxes/MailboxMailBarMail";
import {arrPushMerge, arrSplice, objectifyArr} from "@/codeFunctions";

export default {
    components: {MailboxMailBarMail},
    props: {
        selectedMailbox: Object,
        mailboxes: Array,

        loadingMore: Boolean,
        deletedView: Boolean,
        mailVault: Array,
        selectedMailIDs: Array,
        loadingMailIDs: Array,
        searchVal: String,
        getSelectedMails: Function,
        showAvatar: Boolean
    },
    data() {
        return {
            filterStr: null
        }
    },
    watch: {},
    methods: {
        setLinkMails(event) {
            event.dataTransfer.setData('text', JSON.stringify({
                event: 'smtpMailboxMailLink',
                data: null
            }));
        },

        mailBarScrolled() {
            let oScrollData = {scrollTop:  this.$refs.mailsBar.scrollTop, scrollHeight:  this.$refs.mailsBar.scrollHeight, clientHeight:  this.$refs.mailsBar.clientHeight}
            this.$emit('bumpScroll', oScrollData)
        },

        flagDelete(value) {
            this.$emit('resetMailView')
            this.$emit('clearMailView')

            this.$nextTick(() => {
                arrPushMerge(this.loadingMailIDs, this.selectedMailIDs);

                let reqBody = this.mailVault.filter(obj => this.selectedMailIDs.includes(obj.id)).map(obj => {return {id: obj.id, mailbox: obj.fk_mailbox}})

                this.$newReq('POST', `smtp/flagDeleteMailbox/${this.userInfo.entityID}/${value}`, reqBody)
                        .then((data) => {
                            arrSplice(this.mailVault, mail => this.loadingMailIDs.includes(mail.id));
                            arrSplice(this.selectedMailIDs, () => true);
                            arrSplice(this.loadingMailIDs, () => true);
                            this.mailBarScrolled();
                        })
                        .catch((e) => {
                            // eslint-disable-next-line
                            console.log(e);
                            this.$snack.networkError();
                            arrSplice(this.loadingMailIDs, () => true);
                            this.loadingMore = false;
                        })
            })


        },
    },
    computed: {
        ...mapGetters('userInfo', ['userInfo']),
        selectedMailboxRelay: {
            get() {
                return this.selectedMailbox
            },
            set(val) {
                this.$emit('selectMailbox', val)
            }
        },

        orderedVault() {
            return [...this.mailVault].sort((a,b) => b.id - a.id)
        },

        mailboxRef() {
            return objectifyArr(this.mailboxes, 'id')
        },

        view: {
            get() {
                return this.deletedView ? 1 : 0
            },
            set(val) {
                this.$emit('deletedView', val !== 0)
            }
        }
    },
}
</script>

<style scoped>

</style>