<template>
    <v-dialog v-model="showDialog" width="400px" persistent>
        <v-card flat>
            <v-card-title class="pa-1 pl-2">
                <span class="subheading font-weight-light primary--text">{{ title }}</span>
                <v-spacer></v-spacer>
                <v-btn
                        v-if="cancelButton"
                        small
                        icon
                        flat
                        color="primary"
                        class="pa-0 ma-0"
                        @click="$emit('dismiss', true)"
                >
                    <v-icon small>close</v-icon>
                </v-btn>
            </v-card-title>
            <v-divider></v-divider>

            <v-card-text>
                <template v-if="body != null && body != ''">
                    <span class="body-1">{{ body }}</span>
                </template>
                <template>
                    <v-form class="px3" ref="password" v-model="validPassword">
                        <v-text-field
                                v-if="showDialog"
                                :append-icon="showPassword ? 'visibility' : 'visibility_off'"
                                :rules="[rules.required, rules.min, rules.upper, rules.lower, rules.special]"
                                :type="showPassword ? 'text' : 'password'"
                                @click:append="showPassword = !showPassword"
                                label=""
                                single-line
                                v-model="inputValue"
                                color="primary"
                                style="width: 100%"
                                class="ma-0 pa-0"
                        ></v-text-field>
                    </v-form>
                </template>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions v-if="buttons != null && buttons.length > 0">
                <v-spacer></v-spacer>
                <template v-for="(button, i) in buttons">
                    <v-btn
                            :key="'InputButton-' + title + '-' + i + '-' + button"
                            flat
                            color="secondary"
                            dark
                            @click="inputValue != null && inputValue != '' && validPassword ? callBacks[i](inputValue) : showSnack('Error', 'Invalid Password', 'Close', 'red')"
                    >
                        {{ button }}
                    </v-btn>
                </template>
            </v-card-actions>

        </v-card>
    </v-dialog>
</template>

<script>
    import {codeBaseMixin} from "../../codeBaseMixin";

    export default {
        mixins: [codeBaseMixin],
        props: {
            showDialog: Boolean,
            title: String,
            body: String,
            cancelButton: {
                type: Boolean,
                value: true
            },
            showInputBox: {
                type: Boolean,
                value: false
            },
            buttons: Array,
            callBacks: Array,
            defaultInput: {
                type: String,
                default: null
            },
        },
        data() {
            return {
                validPassword: null,
                inputValue: "",
                showPassword: false,
                rules: {
                    required: value => !!value || 'Required.',
                    min: v => v.length >= 6 || 'Min 6 characters',
                    lower: v => v != null && (/[a-z]/.test(v)) || 'At Least 1 Lower Case Character Is Required',
                    upper: v => v != null && (/[A-Z]/.test(v)) || 'At Least 1 Upper Case Character Is Required',
                    special: v => v != null && (/[$&+,:;=?@#|'<>.^*()%!-]/.test(v)) || 'At Least 1 Special Character Is Required',
                }
            }
        },
        watch: {
            showDialog(newVal) {
                if (!newVal) {
                    this.inputValue = "";
                } else {
                    this.inputValue = this.defaultInput;
                }
            }
        }
    }
</script>

<style scoped>

</style>
