<template>
    <div>
        {{ node.description }}
    </div>
</template>

<script>
export default {
    components: {},
    props: {
        node: {type: Object, required: true},
    },
    data() {
        return {
        }
    },
    watch: {},
    methods: {},
}
</script>

<style scoped>

</style>