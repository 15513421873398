<template>
    <v-treeview open-all :transition="true" :hoverable="true" :items="treeSort" item-text="" class="subheading" color="white">
        <template
                slot="prepend"
                slot-scope="{ item, open, leaf }"
        >
            <div
                    @click="selectLabel(item.id)"


                    style="width: 100%; height: 100%; cursor: pointer;"
                    @dragover="allowDrop"
                    @drop="drop($event, item)"
            >
                <v-icon left v-if="!item.color">{{ item.icon }}</v-icon>
                <v-icon left v-else :color="item.color.backgroundColor">{{ item.icon }}</v-icon>
                <span v-if="selectedLabel.id == item.id" class="font-weight-medium primary--text">{{ item.displayName }}</span>
                <span v-else >{{ item.displayName }}</span>
            </div>
        </template>
    </v-treeview>

</template>

<script>
    import { eventBus } from '../../../main';

    export default {
        data() {
            return {
                dragData: {}
            }
        },
        props: {
            labels: Array,
            selectedLabel: Object
        },
        methods: {
            selectLabel(labelID) {
                this.selectedLabel.id = labelID;
                this.$emit('labelClicked');
            },
            allowDrop: function (ev) {
                ev.preventDefault();
            },

            drop: function (ev, item) {
                ev.preventDefault();
                eventBus.$emit('moveGmailMessageLabel', {messageID: this.dragData.gmailID, toLabelID: item.id})
            },
            gmailMessageDrag(value) {this.dragData = value;}
        },
        computed: {
            treeSort() {
                let labelsRedone = [];

                this.labels.sort((a,b) => a['name'] < b['name'] ? -1 : 1);

                //Loop Through Labels
                this.labels.forEach((labelObject, i) => {
                    let arrNames = labelObject.name.split("/");
                    labelObject.count = arrNames.length;
                    labelObject.ogName = arrNames.join('/');
                    labelObject.name = arrNames[arrNames.length - 1];

                    if ((labelObject.type == 'user' || labelObject.count >= 2 || labelObject.name == 'INBOX' || labelObject.name == 'SENT' || labelObject.name == 'SPAM' || labelObject.name == 'TRASH' || labelObject.name == 'DRAFT' || labelObject.name == 'STARRED' )) {

                        switch (labelObject.name) {
                            case 'INBOX':
                                labelObject.icon = 'inbox';
                                labelObject.color = {};
                                labelObject.color.backgroundColor = '#D32F2F';
                                break;
                            case 'SENT':
                                labelObject.icon = 'send';
                                labelObject.color = {};
                                labelObject.color.backgroundColor = '#D32F2F';
                                break;
                            case 'SPAM':
                                labelObject.icon = 'report';
                                break;
                            case 'TRASH':
                                labelObject.icon = 'delete';
                                break;
                            case 'STARRED':
                                labelObject.icon = 'star';
                                break;
                            case 'DRAFT':
                                labelObject.icon = 'drafts';
                                break;

                            default:
                                labelObject.icon = 'label'
                        }

                        labelObject.displayName = labelObject.name.toLowerCase()
                            .split(' ')
                            .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
                            .join(' ');

                        labelsRedone.push(labelObject);
                    }
                });

                let i;
                for (i = labelsRedone.length -1; i >= 0; i--) {

                    if (labelsRedone[i].count > 1) {
                        let match = false;
                        let iPreIndex = 1;
                        let bSplice = true

                        while (match == false && (i-iPreIndex) >= 0) {
                            if (labelsRedone[i-iPreIndex].count < labelsRedone[i].count && labelsRedone[i].ogName.includes(labelsRedone[i-iPreIndex].name)) {
                                match = true;
                                if (labelsRedone[i-iPreIndex].hasOwnProperty("children") == false) {
                                    labelsRedone[i-iPreIndex].children = []
                                }
                                labelsRedone[i-iPreIndex].children.push(labelsRedone[i]);
                            } else {
                                iPreIndex++
                            }

                            if (i - iPreIndex <= -1) {
                                labelsRedone[i].displayName = labelsRedone[i].ogName;
                                match = true;// labelsRedone[i-iPreIndex].children.push(labelsRedone[i]);
                                bSplice = false;
                            }
                        }

                        if (bSplice) {
                            labelsRedone.splice(i, 1);
                        }
                    }
                }

                function sortChildren(obj) {
                    obj.children.sort((a,b) => a.name > b.name ? 1 : -1)
                    for (const label of obj.children) {
                        if (label.children)
                            sortChildren(label)
                    }
                }

                for (const label of labelsRedone) {
                    if (label.children)
                        sortChildren(label)
                }




                labelsRedone.sort((a,b) => {

                    if (a.type === b.type)
                        a['name'] < b['name'] ? -1 : 1

                    return a['type'] < b['type'] ? -1 : 1
                });

                return(labelsRedone);
            },
        },
        created() {
            eventBus.$on('gmailMessageDrag', this.gmailMessageDrag);
        },
        beforeDestroy() {
            eventBus.$off('gmailMessageDrag', this.gmailMessageDrag);
        },
    }
</script>

<style scoped>

    .hover {
        border: 1px solid rgba(0,0,0,0);
    }
    .hover:hover {
        border-radius: 2px;
        border: 1px solid var(--v-primary-base);
    }


</style>
