<template>
    <div>
        <template v-if="true">
            <v-card :style="{background: $store.state.darkTheme ? 'var(--primary-20p)' : 'var(--v-primary-base)'}">
              
                <v-layout row align-center class="pl-1 pt-1">
                
<!--Filter Options-->
                  <v-flex shrink class="px-1">
                    <v-menu
                        offset-y
                        open-on-hover
                    >
                      <template #activator="{on}">
                        <v-btn
                            v-on="on"
                            slot="activator"
                            icon
                            flat
                            small
                            :color="isFiltered ? 'amber' : 'white'"
                            class="pa-0 ma-0"
                            @click="isFiltered ? clearFilters() : null"
                        >
                          <v-icon>{{ isFiltered ? 'filter_alt_off' : 'filter_alt' }}</v-icon>
                        </v-btn>
                      </template>
                      
                      <v-card class="page-back">
                        <v-list :dense="true" class="py-0 my-0">
                          
                          
                          <v-list-tile
                              v-for="item in [
                          {key: 'showNotes', title: 'Show Notes', icon: 'note'},
                          {key: 'showMails', title: 'Show Mails', icon: 'mail'},
                          {key: 'showDocuments', title: 'Show Documents', icon: 'attachment'},
                          {key: 'showEvents', title: 'Show Events', icon: 'event'},
                          {key: 'showChat', title: 'Show Chat'},
                        ]"
                              :key="item.key"
                              @click="setSingleFilter(item.key)"
                              class="pl-0"
                          >
                            <v-list-tile-avatar class="pl-0 ml-0">
                              <v-btn
                                  @click.stop.prevent="filterOptions[item.key] = !filterOptions[item.key]"
                                  icon
                                  flat
                                  class="mx-0"
                                  :color="filterOptions[item.key] ? 'primary' : 'grey'"
                              >
                                <v-icon>{{
                                    !filterOptions[item.key] ? 'check_box_outline_blank' : 'check_box'
                                  }}
                                </v-icon>
                              </v-btn>
                            </v-list-tile-avatar>
                            
                            <v-list-tile-avatar class="pl-0 ml-0">
                              <v-icon v-if="item.icon">{{ item.icon }}</v-icon>
                              <img v-else src="../../../../../../public/chat_grey.svg" class="nav-item"
                                   style="width: 24px"/>
                            </v-list-tile-avatar>
                            
                            <v-list-tile-content>
                              <v-list-tile-title>{{ item.title }}</v-list-tile-title>
                            </v-list-tile-content>
                          </v-list-tile>
                        </v-list>
                      </v-card>
                    
                    </v-menu>
                  </v-flex>
                
                
                
<!--&lt;!&ndash;Show Notes&ndash;&gt;-->
<!--                    <v-flex shrink class="pr-1">-->
<!--                        <v-tooltip top>-->
<!--                            <v-btn-->
<!--                                    slot="activator"-->
<!--                                    icon-->
<!--                                    flat-->
<!--                                    small-->
<!--                                    :color="filterOptions.showNotes ? 'white' : 'amber'"-->
<!--                                    class="pa-0 ma-0"-->
<!--                                    @click="filterOptions.showNotes = !filterOptions.showNotes"-->
<!--                            >-->
<!--                                <v-icon>note</v-icon>-->
<!--                            </v-btn>-->
<!--                            <span>{{filterOptions.showNotes ? 'Hide Notes' : 'Show Notes'}}</span>-->
<!--                        </v-tooltip>-->
<!--                    </v-flex>-->
<!--&lt;!&ndash;Show Mails&ndash;&gt;-->
<!--                    <v-flex shrink class="pr-1">-->
<!--                        <v-tooltip top>-->
<!--                            <v-btn-->
<!--                                    slot="activator"-->
<!--                                    icon-->
<!--                                    flat-->
<!--                                    small-->
<!--                                    :color="filterOptions.showMails ? 'white' : 'amber'"-->
<!--                                    class="pa-0 ma-0"-->
<!--                                    @click="filterOptions.showMails = !filterOptions.showMails"-->
<!--                            >-->
<!--                                <v-icon>mail</v-icon>-->
<!--                            </v-btn>-->
<!--                            <span>{{filterOptions.showMails ? 'Hide Mails' : 'Show Mails'}}</span>-->
<!--                        </v-tooltip>-->
<!--                    </v-flex>-->
<!--&lt;!&ndash;Show Documents&ndash;&gt;-->
<!--                    <v-flex shrink class="pr-1">-->
<!--                        <v-tooltip top>-->
<!--                            <v-btn-->
<!--                                    slot="activator"-->
<!--                                    icon-->
<!--                                    flat-->
<!--                                    small-->
<!--                                    :color="filterOptions.showDocuments ? 'white' : 'amber'"-->
<!--                                    class="pa-0 ma-0"-->
<!--                                    @click="filterOptions.showDocuments = !filterOptions.showDocuments"-->
<!--                            >-->
<!--                                <v-icon>attachment</v-icon>-->
<!--                            </v-btn>-->
<!--                            <span>{{filterOptions.showDocuments ? 'Hide Documents' : 'Show Documents'}}</span>-->
<!--                        </v-tooltip>-->
<!--                    </v-flex>-->
<!--&lt;!&ndash;Show Events&ndash;&gt;-->
<!--                    <v-flex shrink class="pr-1">-->
<!--                        <v-tooltip top>-->
<!--                            <v-btn-->
<!--                                    slot="activator"-->
<!--                                    icon-->
<!--                                    flat-->
<!--                                    small-->
<!--                                    :color="filterOptions.showEvents ? 'white' : 'amber'"-->
<!--                                    class="pa-0 ma-0"-->
<!--                                    @click="filterOptions.showEvents = !filterOptions.showEvents"-->
<!--                            >-->
<!--                                <v-icon>event</v-icon>-->
<!--                            </v-btn>-->
<!--                            <span>{{filterOptions.showEvents ? 'Hide Events' : 'Show Events'}}</span>-->
<!--                        </v-tooltip>-->
<!--                    </v-flex>-->
<!--                  -->
<!--&lt;!&ndash;Show Chat&ndash;&gt;-->
<!--                  <v-flex shrink class="pr-1" v-if="contactsEnabled">-->
<!--                    <v-tooltip top>-->
<!--                      <v-btn-->
<!--                          slot="activator"-->
<!--                          icon-->
<!--                          flat-->
<!--                          small-->
<!--                          :color="filterOptions.showChat ? 'white' : 'amber'"-->
<!--                          class="pa-0 ma-0"-->
<!--                          @click="filterOptions.showChat = !filterOptions.showChat"-->
<!--                      >-->
<!--                        <img src="../../../../../../public/chat_white.svg" class="nav-item" style="width: 24px">-->
<!--                      </v-btn>-->
<!--                      <span>{{filterOptions.showChat ? 'Hide Chat Messages' : 'Show Chat Messages'}}</span>-->
<!--                    </v-tooltip>-->
<!--                  </v-flex>-->
                  
                  


                    <v-flex xs12 class="pr-2 pl-1">
                        <v-layout row align-center>
                            <v-flex xs12>
                                <v-text-field
                                    prepend-inner-icon="search"
                                    label="Search"
                                    class="pa-0 ma-0"
                                    hide-details
                                    slot="activator"
                                    single-line
                                    v-model="filterOptions.filterStr"
                                    ref="refArea"
                                    color="white"
                                    dark
                                >
                                </v-text-field>
                            </v-flex>
                            <v-flex class="px-1" v-if="drawTimeOut != null">
                                <v-progress-circular
                                    :size="20"
                                    :width="3"
                                    color="white"
                                    indeterminate
                                />
                            </v-flex>
                        </v-layout>
                    </v-flex>

                    <v-spacer></v-spacer>

<!--User Filter-->
                    <v-flex shrink class="pr-1">
                        <v-tooltip top>
                            <v-btn
                                    slot="activator"
                                    icon
                                    flat
                                    small
                                    :color="!filterOptions.userOnly ? 'white' : 'amber'"
                                    class="pa-0 ma-0"
                                    @click="filterOptions.userOnly = !filterOptions.userOnly"
                            >
                                <v-icon>
                                    {{filterOptions.userOnly ? 'person' : 'people'}}
                                </v-icon>
                            </v-btn>
                            <span>{{filterOptions.userOnly ? 'Me Only' : 'All Users'}}</span>
                        </v-tooltip>
                    </v-flex>
<!--Order-->
                    <v-flex shrink class="pr-1">
                        <v-tooltip top>
                            <v-btn
                                    slot="activator"
                                    icon
                                    flat
                                    small
                                    color="white"
                                    class="pa-0 ma-0"
                                    @click="filterOptions.orderAscending = !filterOptions.orderAscending"
                            >
                                <v-icon>
                                    {{filterOptions.orderAscending ? 'arrow_upward' : 'arrow_downward'}}
                                </v-icon>
                            </v-btn>
                            <span>{{filterOptions.orderAscending ? 'Order Ascending' : 'Order Descending'}}</span>
                        </v-tooltip>
                    </v-flex>
<!--Task/Step Filter-->
                    <v-flex shrink class="pr-1">
                        <v-tooltip top>
                            <v-btn
                                    slot="activator"
                                    icon
                                    flat
                                    small
                                    :color="!filterOptions.stepOnly ? 'white' : 'amber'"
                                    class="pa-0 ma-0"
                                    @click="filterOptions.stepOnly = !filterOptions.stepOnly"
                            >
                                <v-icon>
                                    {{filterOptions.stepOnly ? 'view_list' : 'assignment_turned_in'}}
                                </v-icon>
                            </v-btn>
                            <span>{{filterOptions.stepOnly ? 'Step Attachments' : 'Task Attachments'}}</span>
                        </v-tooltip>
                    </v-flex>

                </v-layout>

                <div class="px-1 pt-1">


                </div>
            </v-card>
        </template>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    
    export default {
        props: {
            filterOptions: Object,
            drawTimeOut: {
                required: true,
                validator: v => true
            }
        },
        data() {
            return {
                val: 'HELLO'
            }
        },
      watch: {
          "filterOptions": {
            immediate: true,
            handler(val) {
              if (typeof val?.showChat === "boolean")
                this.$set(this.filterOptions, "showChat", true)
            }
          },
      },
      
      methods: {
        
        setSingleFilter(key) {
          this.filterOptions.showMails = false;
          this.filterOptions.showNotes = false;
          this.filterOptions.showDocuments = false;
          this.filterOptions.showEvents = false;
          this.filterOptions.showChat = false;
          this.filterOptions[key] = true
        },
        
        clearFilters() {
          this.filterOptions.showMails = true;
          this.filterOptions.showNotes = true;
          this.filterOptions.showDocuments = true;
          this.filterOptions.showEvents = true;
          this.filterOptions.showChat = true;
        }
      },
      computed: {
        ...mapGetters("userInfo", ["userInfo", "contactsEnabled"]),
        isFiltered() {
          return !this.filterOptions.showMails || !this.filterOptions.showNotes || !this.filterOptions.showDocuments || !this.filterOptions.showEvents || !this.filterOptions.showChat
        }
      },
    }
</script>

<style scoped>

</style>
