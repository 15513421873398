<template>
    <v-layout row wrap @click="!readOnly ? runEditProtocol() : ''" class="pt-2">
        <v-flex xs12 style="overflow-y: auto; max-height: 500px; cursor: pointer">
            <div v-if="fieldValue != null && fieldValue != ''" v-html="fieldValue">

            </div>
            <div v-else style="border: 1px dashed lightgrey; border-radius: 3px" class="text-xs-center pa-1">
                <span>Click to Edit</span>
            </div>
        </v-flex>
        <v-flex xs12 v-if="fieldValue != null && fieldValue != ''">
            <v-divider class="grey lighten-1"></v-divider>
        </v-flex>
    </v-layout>
</template>

<script>

    // let tinyEditor = require('@tinymce/tinymce-vue').default;

    export default {
        components: {
            // appEditor: tinyEditor
        },
        props: {
            field: Object,
            readOnly: {
                default: false,
                type: Boolean
            },
            editNoteFn: Function
        },

        methods: {
            updateValue(newVal) {
                this.fieldValue = newVal;

            },

            editDone() {
                this.fieldValue = this.modelText;
                this.showEdit = false;
                this.$emit('input');
            },

            runEditProtocol() {
                let editValue = {valueTree: true, value: this.fieldValue, description: 'Edit Note'}
                this.editNoteFn(editValue)
                    .then(() => {
                        this.fieldValue = editValue.value;
                        this.$emit('input')
                    })
                    .catch((e) => {
                        console.log(e)
                    })
            },
        },

        computed: {
            fieldValue: {
                // getter
                get: function () {
                    if (this.field.value != null && this.field.value != '') {
                        return this.field.value.replace(/~#~/g,'"')
                    } else {
                        return ''
                    }
                },
                // setter
                set: function (newValue) {
                    this.field.value = newValue.split('"').join('~#~')
                }
            },
        },

        watch: {
            showEdit(shown) {
                if (shown) {
                    this.modelText = this.fieldValue;
                }
            }
        },

        data() {
            return {
                showEdit: false,
                tinyInitSettings: {
                    // plugins: 'print, autoresize, table',
                    plugins: 'print, table, imagetools, image, paste',
                    elementpath: false,

                    force_br_newlines : true,
                    force_p_newlines : false,
                    forced_root_block : '', // Needed for 3.x
                    menubar:false,
                    formats: {
                        // Changes the default format for the bold button to produce a span with style with font-width: bold
                        paragraph: { inline: 'span', styles: { 'font-weight': 'bold', 'background-color': 'orange' } }
                    },
                    toolbar: 'fontsizeselect bold italic forecolor backcolor',
                    removed_menuitems: 'newdocument',
                    visual_table_class: 'overide',
                    max_height: 500,
                    height: '100%',
                    resize: false,
                    browser_spellcheck: true,
                    // convert_urls : false,
                    relative_urls : false,
                    remove_script_host : false,
                    content_css : "/tinycontent.css",
                    paste_data_images: true,
                    paste_preprocess: function(plugin, args) {
                        args.content  = args.content.replace('<img', `<img style='max-width: 100%;' `)
                    }
                },
                modelText: ""
            }
        },
    }
</script>

<style scoped>

</style>
