<template>
    <v-text-field
            :clearable="!readOnly"
            color="primary"
            flat
            v-model="field.value"
            hide-details
            class="pa-0 ma-0"
            single-line
            :label="field.description"
            type="number"
            :readonly="readOnly"
            @input="$emit('input')"
    >
    </v-text-field>
</template>

<script>
    export default {
        props: {
            field: Object,
            readOnly: {
                default: false,
                type: Boolean
            }
        }
    }
</script>

<style scoped>

</style>
