<template>
    <div class="px-2 pt-2 pb-0">
        <div :class="topicClass">
            <div :class="headingClass">
                Step Description:
            </div>
            <div :class="itemClass">
                <v-text-field
                        label="Step Description"
                        single-line
                        color="primary"
                        append-outer-icon="save"
                        hide-details
                        style="width: 100%"
                        class="pa-0 ma-0"
                        ref="stepDescription"
                        v-model="localLookup.description"
                />
            </div>
        </div>
        <v-layout row>
            <v-flex xs6>
<!--Visibility-->
                <div :class="topicClass" style="overflow-y: hidden">
                    <div :class="headingClass">
                        Visible:
                    </div>
                    <!--:label="visible ? 'Shown' : 'Hidden'"-->
                    <div :class="itemClass">
                        <v-switch
                                v-model="visible"
                                hide-details
                                color="secondary"
                                class="pa-0 ma-0"
                        />
                    </div>
                </div>
            </v-flex>
            <v-flex xs6>
<!--Hide When Empty-->
                <div :class="topicClass" style="overflow-y: hidden">
                    <div :class="headingClass">
                        Hidden When Empty:
                    </div>

                    <div :class="itemClass">
                        <v-switch
                                v-model="hideIfEmpty"
                                hide-details
                                color="secondary"
                                class="pa-0 ma-0"
                        />
                    </div>
                </div>
            </v-flex>
        </v-layout>
<!--Step Shown To-->
        <div :class="topicClass">
            <div :class="headingClass">
                Step Shown To:
            </div>
            <div :class="itemClass">
                <app-workflow-manager-group-selector
                        :user-groups="userGroups"
                        btn-label="Shown to All"
                        ac-label="Shown to All"
                        v-model="securityShownTo"
                />
            </div>
        </div>
<!--Step Editable By-->
        <div :class="topicClass">
            <div :class="headingClass">
                Step Editable By:
            </div>
            <div :class="itemClass">
                <app-workflow-manager-group-selector
                        :user-groups="userGroups"
                        btn-label="Editable by All"
                        ac-label="Editable by All"
                        v-model="securityEditableBy"
                />
            </div>
        </div>


        <!--Step Alias-->
        <div :class="topicClass">
            <div :class="headingClass">
                Step Alias:
            </div>
            <div :class="itemClass">
                <workflow-field-selector
                        v-model="localLookup.stepConfig.alias"
                        :steps="steps"
                        :fields="fields"
                        :field-type-filter="[1,2,3,5]"
                        @input="$forceUpdate()"
                />
            </div>
        </div>

        <!--Collapse Step Name-->
        <div :class="topicClass">
            <div :class="headingClass">
                Collapse Step Name:
            </div>
            <div :class="itemClass" class="c-d-flex c-justify-space-around">
                <v-btn
                        color="secondary"
                        small
                        :outline="localLookup.stepConfig.collapseNameOnceComplete !== null"
                        @click="localLookup.stepConfig.collapseNameOnceComplete = null"
                >
                    Dont Collapse
                </v-btn>

                <v-btn
                        color="secondary"
                        small
                        :outline="localLookup.stepConfig.collapseNameOnceComplete !== 1"
                        @click="localLookup.stepConfig.collapseNameOnceComplete = 1"
                >
                    Required Completed
                </v-btn>

                <v-btn
                        color="secondary"
                        small
                        :outline="localLookup.stepConfig.collapseNameOnceComplete !== 2"
                        @click="localLookup.stepConfig.collapseNameOnceComplete = 2"
                >
                    All Completed
                </v-btn>
            </div>
        </div>


        <v-divider></v-divider>
        <div class="text-xs-right">
            <v-btn
                    small
                    color="secondary"
                    @click="doneRun"
                    :disabled="savingChanges || localLookup.description == null || localLookup.description == ''"
                    :loading="savingChanges"
            >
                Save
            </v-btn>
        </div>


<!--        &lt;!&ndash;Step Editable By&ndash;&gt;-->
<!--        <div :class="topicClass">-->
<!--            <div :class="headingClass">-->
<!--                Step Editable By:-->
<!--            </div>-->
<!--            <div :class="itemClass">-->
<!--                <app-workflow-manager-group-selector-->
<!--                        :user-groups="userGroups"-->
<!--                        btn-label="Editable by All"-->
<!--                        ac-label="Editable by All"-->
<!--                        v-model="securityEditableBy"-->
<!--                />-->
<!--            </div>-->
<!--        </div>-->
<!--        <v-divider></v-divider>-->
<!--        <div class="text-xs-right">-->
<!--            <v-btn-->
<!--                    small-->
<!--                    color="secondary"-->
<!--                    @click="doneRun"-->
<!--                    :disabled="savingChanges || localLookup.description == null || localLookup.description == ''"-->
<!--                    :loading="savingChanges"-->
<!--            >-->
<!--                Save-->
<!--            </v-btn>-->
<!--        </div>-->
    </div>
</template>

<script>
    import {codeBaseMixin} from "../../../../../../codeBaseMixin";
    import WorkflowManagerGroupSelector from './WorkflowManagerGroupSelector'
    import WorkflowFieldSelector
        from "@/Admin/WorkflowEditor/Components/WorkflowManager/Workflow/Components/WorkflowFieldSelector";

    export default {
        mixins: [codeBaseMixin],
        components: {
            WorkflowFieldSelector,
            appWorkflowManagerGroupSelector: WorkflowManagerGroupSelector
        },
        props: {
            value: Object,
            userInfo: Object,
            userGroups: Array,
            addStep: {
                type: Boolean,
                default: false
            },
            workflowId: Number,
            steps: Array,
            fields: Array,
        },
        data() {
            return {
                headingClass: 'caption greyType--text font-weight-bold',
                topicClass: 'pa-1',
                itemClass: 'pl-2',
                localLookup: null,
                savingChanges: false
            }
        },
        // mounted() {
        //     setTimeout(() => {this.$refs.stepDescription.focus();}, 200)
        // },
        watch: {
            value: {
                immediate: true,
                handler(val) {
                    if (val != null) {
                        this.localLookup = JSON.parse(JSON.stringify(val));
                        this.localLookup.id = typeof val.id != 'undefined' ? val.id : null;
                        this.localLookup.description = typeof val.description != 'undefined' ? val.description : null;
                        this.localLookup.hidden = typeof val.hidden != 'undefined' ? val.hidden : 0;
                        this.localLookup.securityLookup = typeof val.securityLookup != 'undefined' ? val.securityLookup : null;
                        this.localLookup.hideIfEmpty = typeof val.hideIfEmpty != 'undefined' ? val.hideIfEmpty : 0;
                        this.localLookup.recycleStep = typeof val.recycleStep != 'undefined' ? val.recycleStep : 0;
                        this.localLookup.stepConfig = typeof val.stepConfig != 'undefined' && val.stepConfig !== null ? val.stepConfig : {alias:null}
                        if (typeof this.localLookup.stepConfig.collapseNameOnceComplete == 'undefined') {
                            this.$set(this.localLookup.stepConfig, "collapseNameOnceComplete", null)
                        }

                            // this.localLookup.stepConfig.collapseNameOnceComplete = null
                    } else {
                        this.localLookup = {
                            id: null,
                            description: null,
                            hidden: 0,
                            securityLookup: null,
                            hideIfEmpty: 0,
                            recycleStep: 0,
                            stepConfig: {
                                alias:null,
                                collapseNameOnceComplete: null
                            }
                        }
                    }

                }


            }
        },

        methods: {
            doneRun() {
                // this.$emit('input', this.localLookup)
                // this.updateStepMetaValues();

                if (this.addStep == false) {
                    this.updateStepMetaValues();
                } else  {
                    this.addNewStep();
                }
            },
            updateStepMetaValues() {
                let newVal = {};
                newVal.description = this.localLookup.description;
                newVal.hidden = this.localLookup.hidden;
                newVal.securityLookup = this.localLookup.securityLookup;
                newVal.hideIfEmpty = this.localLookup.hideIfEmpty;
                newVal.recycleStep = this.localLookup.recycleStep;
                newVal.stepConfig = this.localLookup.stepConfig;


                this.savingChanges = true;

                this.fetch({
                    method: 'PATCH',
                    url: `/workflowManager/step/meta/${this.userInfo.entityID}/${this.localLookup.id}`,
                    body: JSON.stringify(newVal)
                })
                    .then(() => {
                        this.savingChanges = false;
                        this.$emit('input', newVal);
                        this.showSnack('Info', 'Changes Saved', 'Close', 'primary')
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e);
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
                        this.savingChanges = false;
                    })
            },

            addNewStep() {

                let newVal = {};
                newVal.description = this.localLookup.description;
                newVal.hidden = this.localLookup.hidden;
                newVal.securityLookup = this.localLookup.securityLookup;
                newVal.hideIfEmpty = this.localLookup.hideIfEmpty;
                newVal.recycleStep = this.localLookup.recycleStep;

                this.savingChanges = true;

                this.fetch({
                    method: 'POST',
                    url: `/workflowManager/step/meta/${this.userInfo.entityID}/${this.workflowId}`,
                    body: JSON.stringify(newVal)
                })
                    .then((data) => {
                        this.savingChanges = false;
                        this.$emit('input', data);
                        this.showSnack('Info', 'Step Added', 'Close', 'primary')
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e);
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
                        this.savingChanges = false;
                    })
            },
        },

        computed: {
            visible: {
                get: function () {
                    return !this.intToBool(this.localLookup.hidden)
                },
                set: function (newValue) {
                    this.localLookup.hidden = !this.boolToInt(newValue);
                }
            },
            hideIfEmpty: {
                get: function () {
                    return this.intToBool(this.localLookup.hideIfEmpty)
                },
                set: function (newValue) {
                    this.localLookup.hideIfEmpty = this.boolToInt(newValue);
                }
            },
            recycleStep: {
                get: function () {
                    return this.intToBool(this.localLookup.recycleStep)
                },
                set: function (newValue) {
                    this.localLookup.recycleStep = this.boolToInt(newValue);
                }
            },
            securityShownTo: {
                // getter
                get: function () {
                    return this.localLookup.securityLookup != null && typeof this.localLookup.securityLookup.shownTo != "undefined" ? this.localLookup.securityLookup.shownTo : []
                },
                set: function (newValue) {
                    if (this.localLookup.securityLookup == null) {this.localLookup.securityLookup = {}}
                    this.localLookup.securityLookup.shownTo = newValue;
                    this.localLookup.securityLookup = this.localLookup.securityLookup != null ? JSON.parse(JSON.stringify(this.localLookup.securityLookup)) : null;
                }
            },
            securityEditableBy: {
                get: function () {
                    return this.localLookup.securityLookup != null && typeof this.localLookup.securityLookup.editableBy != "undefined" ? this.localLookup.securityLookup.editableBy : []
                },
                set: function (newValue) {
                    if (this.localLookup.securityLookup == null) {this.localLookup.securityLookup = {}}
                    this.localLookup.securityLookup.editableBy = newValue;
                    this.localLookup.securityLookup = this.localLookup.securityLookup != null ? JSON.parse(JSON.stringify(this.localLookup.securityLookup)) : null;
                }
            },
        }
    }
</script>

<style scoped>

</style>
