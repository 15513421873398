<template>
    <v-card class="fill-height" style="overflow-y: auto;">
        <v-layout column fill-height class="scrollable" style="overflow-x: hidden">
            <v-flex shrink>
                <v-card-title class="white--text pa-0 ma-0 title-color">
                    <div class="pa-2">Select a Dataset</div>
                </v-card-title>
            </v-flex>
            <v-flex shrink class="px-2 py-2">
                <app-add-data-set
                        :user-info="userInfo"
                        :user-groups="userGroups"
                        :folder-groups="folderGroups"
                        @selectDataset="$emit('input', $event)"
                        @post="$emit('post', $event)"
                >
                    <v-btn
                            small
                            outline
                            class="ma-0"
                            color="secondary"
                            block
                            slot="activator"
                    >
                        <v-icon left>add</v-icon>
                        Add DataSet
                    </v-btn>
                </app-add-data-set>

            </v-flex>
            <v-flex shrink class="px-2">
                <v-text-field
                        label="Filter Datasets"
                        solo
                        clearable
                        prepend-icon="search"
                        color="primary"
                        flat
                        :hide-details="true"
                        v-model="search"
                        class="pa-0 ma-0"
                        ref="myField"
                />
            </v-flex>
            <v-flex shrink>
                <v-divider></v-divider>
            </v-flex>
            <v-flex xs12 class="scrollable" style="overflow-x: hidden">
                <div
                        v-for="dataSet in filteredSortedDataSets"
                        :key="`shownDataSet${dataSet.id}`"
                        style="overflow-x: hidden"
                >
                    <app-data-set-select-member
                            :user-info="userInfo"
                            :data-set="dataSet"
                            :selected-data-set="value"
                            :group-string="dataSetGroupNames[dataSet.id]"
                            :user-groups="userGroups"
                            :folder-groups="folderGroups"
                            @selectDataset="$emit('input', $event)"
                            @patch="$emit('patch', $event)"
                    />
                </div>

            </v-flex>

        </v-layout>
    </v-card>

</template>

<script>
    import {codeBaseMixin} from "../../../codeBaseMixin";
    import DataSetSelectMember from "./DataSetSelectMember";
    import AddDataSet from "./AddDataSet";

    export default {
        components: {
            appAddDataSet: AddDataSet,
            appDataSetSelectMember: DataSetSelectMember
        },
        mixins: [codeBaseMixin],
        props: {
            userInfo: Object,
            value: Object,
            dataSets: Array,
            selectDataSet: Object,
            filterString: String,
            userGroups: Array,
            folderGroups: Array,
        },
        computed: {
            search: {
                get() {
                    return this.filterString
                },
                set(value) {
                    this.$emit('searchUpdated', value)
                }
            },

            dataSetGroupNames() {
                try {
                    return this.dataSets.reduce((dSGroups, dataSet) => {
                        let tLookup = dataSet.securityLookup != null ? dataSet.securityLookup.map(obj => {return obj.fk_user_group}) : [];
                        dSGroups[dataSet.id] = this.userGroups
                            .filter(group => tLookup.includes(group.id)).sort((a,b) => a['description'] < b['description'] ? -1 : 1)
                            .sort((a,b) => a['description'] < b['description'] ? -1 : 1)
                            .map(obj => {return obj.description})
                            .join(', ');
                        return dSGroups;
                    }, {})
                } catch (e) {
                    // eslint-disable-next-line
                    console.log(e)
                    return null
                }
            },

            filteredSortedDataSets() {
                return this.blankString(this.filterString) ?
                    this.dataSets.filter(() => true)
                        .sort((a,b) => a['description'] < b['description'] ? -1 : 1)
                    : this.dataSets.filter((dataSet) => !this.blankString(dataSet.description) && dataSet.description.toLowerCase().includes(this.filterString.toLowerCase()))
                        .sort((a,b) => a['description'] < b['description'] ? -1 : 1)
            }
        },


    }
</script>

<style scoped>

</style>
