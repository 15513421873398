<template>
    <div>
        <v-menu offset-y :close-on-content-click="false" v-model="selectingGroups" max-width="480">
            <template v-slot:activator="{ on }">
                <div v-show="displayGroupsTruncated == null" v-on="on">
                    <v-btn small flat color="secondary" class="ma-0">{{BtnLabel}}</v-btn>
                </div>
                <v-card ripple hover flat v-show="displayGroupsTruncated != null" class="primary white--text" v-on="on">
                    <v-layout row align-center style="cursor: pointer" v-if="displayGroupsTruncated != null">
                        <v-flex xs12 class="pa-1 pl-2 text-truncate" style="overflow-x: hidden;">
                            {{displayGroupsTruncated.displayItems.join(', ')}}
                        </v-flex>
                        <v-flex shrink>
                            <v-tooltip top v-if="displayGroupsTruncated.tooltipItems.length > 0">
                                <v-btn
                                        small
                                        flat
                                        icon
                                        color="white"
                                        class="pa-0 ma-0"
                                        slot="activator"
                                >
                                    <div class="caption">
                                        {{`+${displayGroupsTruncated.tooltipItems.length}`}}
                                    </div>
                                </v-btn>
                                <span>{{displayGroupsTruncated.tooltipItems.join(', ')}}</span>
                            </v-tooltip>
                        </v-flex>
                        <v-flex shrink class="pr-1">
                            <v-tooltip top>
                                <v-btn
                                        small
                                        flat
                                        icon
                                        color="white"
                                        class="pa-0 ma-0"
                                        slot="activator"
                                >
                                    <v-icon small>edit</v-icon>
                                </v-btn>
                                <span>Edit Groups</span>
                            </v-tooltip>
                        </v-flex>
                    </v-layout>
                </v-card>
            </template>
            <v-card width="480">
                <v-layout row align-center class="pa-1" style="overflow-x: hidden; overflow-y: auto">
                    <v-flex xs12 style="overflow-x: hidden; overflow-y: auto">
                        <div class="pa-1">
                            <div class="caption greyType--text font-weight-bold">
                                {{lblLabel}}
                            </div>
                            <div class="pl-2" style="overflow-x: hidden; overflow-y: auto">
                                <v-autocomplete
                                        dense
                                        v-model="selectedGroups"
                                        :items="sortedGroups"
                                        :label="AcLabel"
                                        hide-details
                                        single-line
                                        color="primary"
                                        item-text="description"
                                        item-value="id"
                                        small-chips
                                        deletable-chips
                                        class="py-1 ma-0"
                                        clearable
                                        ref="groupSelector"
                                        style="max-width: 400px"
                                        :multiple="multiple"
                                ></v-autocomplete>
                            </div>
                        </div>
                    </v-flex>
                    <v-flex shrink class="px-2">
                        <v-tooltip top>
                            <v-btn
                                    class="pa-0 ma-0"
                                    color="secondary"
                                    slot="activator"
                                    small
                                    @click="doneRun"
                                    icon
                                    flat
                            >
                                <v-icon>save</v-icon>
                            </v-btn>
                            <span>Save Changes</span>
                            </v-tooltip>
                    </v-flex>
                </v-layout>
            </v-card>
        </v-menu>
    </div>
</template>

<script>
    import {codeBaseMixin} from "../../../../../../codeBaseMixin";
    import InputDlg from "../../../../../../components/General/InputDlg";

    export default {
        mixins: [codeBaseMixin],
        props: {
            value: [Array, Number],
            userGroups: Array,
            AcLabel: String,
            BtnLabel: String,
            lblLabel: {
                type: String,
                default: 'Select User Groups:'
            },
            multiple: {
                default: true,
                type: Boolean
            }
        },
        data() {
            return {
                selectingGroups: false,
                selectedGroups: null,
            }
        },
        watch: {
            value: {
                immediate: true,
                handler(val) {
                    this.resetLocalValues(val)
                }
            },
            selectingGroups(shown) {
                if (shown) {
                    setTimeout(() => {this.$refs.groupSelector.focus();}, 500);
                }
            }
        },
        methods: {
            doneRun() {
                console.log('DONE RUN', this.selectedGroups)
                this.selectingGroups = false;
                this.$emit('input', this.selectedGroups || null)
            },

            resetLocalValues(val) {
                this.selectedGroups = null;
                this.selectingGroups = false;
                if (val != null && val != '' && ((Array.isArray(val) && val.length > 0) || (typeof val == 'number'))) {
                    console.log('SET HERE')
                    this.selectedGroups = val;
                }
            },
        },
        computed: {

            sortedGroups() {
                return this.userGroups.filter(obj => true).sort((a,b) => a['description'] < b['description'] ? -1 : 1).map(obj => {
                    let val = {};
                    Object.assign(val, obj);
                    if (val.description.length >= 45) {
                        val.description = val.description.substr(0, 45) + '...'
                    }
                    return val
                });
            },

            displayGroups() {
                if (this.value == null) {
                    return null

                } else {
                    return this.sortedGroups.filter(obj => (Array.isArray(this.value) ? this.value : [this.value]).includes(obj.id))
                }
            },

            displayGroupsTruncated() {
                if (this.displayGroups == null || !this.displayGroups.length) {
                    return null;
                } else {
                    return this.displayGroups.reduce((returnObject, group, index, valueGroups) => {
                        if (index <= 2) {
                            returnObject.displayItems.push(group.description)
                        } else {
                            returnObject.tooltipItems.push(group.description)
                        }

                        if (index + 1 == valueGroups.length && (returnObject.displayItems.length + returnObject.tooltipItems.length) <= 0) {
                            returnObject = null;
                        }
                        return returnObject;
                    }, {
                        displayItems: [],
                        tooltipItems: []
                    })
                }
            },
        }
    }
</script>

<style scoped>

</style>
