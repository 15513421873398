<template>
    <div style="width: 300px">
        <div class="text-xs-center" style="width: 100%" v-if="loading">
            <v-progress-circular

                    :size="40"
                    :width="5"
                    color="primary"
                    indeterminate
            />
        </div>
        <template v-else>
            <document-container
                    v-if="!mailField"
                    :user-info="userInfo"
                    :document="docData"
            />
            <general-mail-container
                    v-else
                    :mail="mailData"
                    :user-info="userInfo"
                    :dashboard="false"
                    :task-band="false"
                    :fieldValue="false"
                    :dense="true"
                    :disableSelfPreview="true"
                    :external-eml-view="true"
                    @viewMail="$emit('viewMail', $event)"
            />
        </template>
    </div>

</template>

<script>
    import {codeBaseMixin} from "../../../codeBaseMixin";
    import DocumentContainer from "../../../Tasks/TaskView V2/components/Documents/DocumentContainer";
    import GeneralMailContainer from "../../../components/General/generalMailContainer";

    export default {
        components: {GeneralMailContainer, DocumentContainer},
        mixins: [codeBaseMixin],
        props: {
            file: String,
            userInfo: Object
        },
        mounted() {
            if (this.mailField) {
                this.getMailMeta();
            } else {
                this.getDocMeta();
            }
        },
        data() {
            return {
                loading: true,
                mailData: null,
                docData: null,
                con: console
            }
        },
        methods: {
            getMailMeta() {
                this.loading = true;
                this.fetch({method: 'GET', url:`/wfr/report/preview/mail/${this.userInfo.entityID}/${this.fileID}`})
                    .then((data) => {


                        this.mailData = data;
                        this.loading = false;
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e);
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                        this.loading = false;
                    })
            },
            getDocMeta() {
                this.loading = true;
                this.fetch({method: 'GET', url:`/wfr/report/preview/doc/${this.userInfo.entityID}/${this.fileID}`})
                    .then((data) => {
                        this.docData = data;
                        this.loading = false;
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e);
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                        this.loading = false;
                    })
            }

        },
        computed: {
            mailField() {
                return this.file.split('-')[0] == 1
            },
            fileID() {
                return this.file.split('-')[1];
            }
        }
    }
</script>

<style scoped>

</style>
