<template>
    <v-layout row>
        <v-flex class="pl-2">

            <template v-if="value.value != null && value.value != ''">
                <template v-if="value.valueType == 2">
                  <app-document-container
                      v-for="doc in folderDocs.filter(obj => obj.documentDBID == value.value)" :key="doc.documentDBID"
                      :document="doc"
                      :user-info="userInfo"
                      :fieldValue="!readOnly"
                      @uploadDone="documentUploaded"
                      :dense="true"
                      :add-doc-to-task-btn="addDocToTaskBtn"
                      @addDocToTask="$emit('addDocToTask', $event)"
                  >
                    <template v-slot:uploadSection>
                      <v-tooltip left>
                        <v-btn
                            :disabled="readOnly"
                            @click="addRepoDocs()"
                            class="pa-0 ma-0"
                            flat
                            icon
                            color="secondary"
                            slot="activator"
                        >
                          <v-icon>note_add</v-icon>
                        </v-btn>
                        <span>Add Document From Store</span>
                      </v-tooltip>
                    </template>
                  </app-document-container>
                </template>

                <template v-if="value.valueType == 1">
                  <app-general-mail-container
                      v-for="mail in computedMails"
                      :key="mail.mailID"
                      :mail="mail"
                      :user-info="userInfo"
                      :dashboard="false"
                      :task-band="false"
                      :fieldValue="!readOnly"
                      @uploadDone="documentUploaded"
                      :dense="true"
                  />
                </template>
            </template>
            <template v-else>
                <v-layout row align-center>
                    <v-flex xs12>
                        <span class="body-1 greyType--text">Upload File</span>
                    </v-flex>
                    <v-flex shrink>

                        <v-tooltip top>
                            <v-btn
                                    @click="showUpload.show = true"
                                    flat
                                    icon
                                    color="primary"
                                    class="pa-0 ma-0"
                                    slot="activator"
                                    :readonly="readOnly"
                                    :disabled="readOnly"
                            >
                                <v-icon>cloud_upload</v-icon>
                            </v-btn>

                            <span>Upload Document</span>
                            <app-document-upload-dlg
                                    :dialog="showUpload"
                                    :user-info="userInfo"
                                    :field-value="true"
                                    @documentsUploaded="documentUploaded"
                            />
                            <!--@documentsSaved="updateBar"-->
                        </v-tooltip>


                    </v-flex>
                    <v-flex shrink>
                        <v-tooltip left>
                            <v-btn
                                :disabled="readOnly"
                                @click="addRepoDocs()"
                                class="pa-0 ma-0"
                                flat
                                icon
                                color="secondary"
                                slot="activator"
                            >
                                <v-icon>note_add</v-icon>
                            </v-btn>
                            <span>Add Document From Store</span>
                        </v-tooltip>
                    </v-flex>
                </v-layout>
                <v-divider class="grey "></v-divider>
            </template>




        </v-flex>
    </v-layout>
</template>

<script>

    import DocumentContainer from '../../../Tasks/TaskView V2/components/Documents/DocumentContainer';
    import GeneralMailContainer from '../../../components/General/generalMailContainer'
    import DocumentUploadDlg from '../../../Tasks/TaskView/components/Documents/DocumentUploadDlg'
    import mime from "mime-types";
    import {mapGetters} from "vuex";

    export default {
        components: {
            appDocumentContainer: DocumentContainer,
            appGeneralMailContainer: GeneralMailContainer,
            appDocumentUploadDlg: DocumentUploadDlg
        },
        props: {
            value: Object,
            userInfo: Object,
            readOnly: Boolean,
            folderMails: Array,
            folderDocs: Array,
            addDocToTaskBtn: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                showUpload: {show: false}
            }
        },
        methods: {
            documentUploaded(document) {
                this.value.valueType = 2;
                this.folderDocs.push(document);
                this.value.value = document.documentDBID;
                this.$emit('forceSave')
            },
            addRepoDocs() {
                function getFilename(alias, filename, ext) {
                    if (alias) {
                        if (alias.split('.').pop().toLowerCase() === ext.toLowerCase()) {
                            return alias
                        } else {
                            return `${alias}.${ext}`
                        }
                    } else {
                        return filename
                    }
                }

                this.$getRepoDocs({
                    multiple: false,
                    allowUserDocs: true
                })
                        .then((doc) => {

                            this.value.valueType = 2;
                            this.folderDocs.push(doc.document);
                            this.value.value = doc.document.documentDBID;
                            this.$emit('forceSave')

                            // console.log({doc})
                            // docs.map((att) => {
                            //     return {
                            //         type: "attachment",
                            //         contentType: mime.lookup(att.extention),
                            //         contentDisposition: "attachment",
                            //         filename: getFilename(att.document.alias, att.document.fileName, att.document.extention),
                            //         size: null,
                            //         source: 'task',
                            //         taskDocumentID: att.documentID
                            //     }
                            // }).forEach(att => {
                            //     this.attachments.push(att)
                            // })
                            //
                            // this.checkTaskAttachmentSize();
                        })
                        .catch(e => {
                            if (e) {
                                console.log(e)
                                this.$snack.error('An Error Has Occurred')
                            } else {
                                console.log('User Canceled')
                            }
                        })
            },
        },
        computed: {
            ...mapGetters("userInfo", ["softBlock"]),
            computedMails() {
                if (this.value.value != null && this.value.value != '' && this.value.valueType == 1) {
                    return this.folderMails.filter(obj => obj.mailDBID == this.value.value).map(obj => {
                        try {
                            obj.attachments = JSON.parse(obj.attachments);
                        } catch (e) {
                            // eslint-disable-next-line
                            console.log(e)
                        }
                        return obj;
                    });
                } else {
                    return null
                }
            }
        }
    }
</script>

<style scoped>

</style>
