<template>
    <v-layout row wrap class="pt-2">
<!--Title-->
        <v-flex xs12>
            <v-layout row align-center>
                <v-flex xs12>
                    <span class="body-1 font-weight-light">Add New Field</span>
                </v-flex>
                <v-flex shrink class="pb-1">
                    <v-btn
                            flat
                            icon
                            color="primary"
                            small
                            @click="addFieldDlg = true"
                            dark
                            class="pa-0 ma-0"
                    >
                        <v-icon>add</v-icon>
                    </v-btn>
                </v-flex>
            </v-layout>
        </v-flex>

<!--Add Field Dialog-->
        <v-dialog
                v-model="addFieldDlg"
                persistent
                width="500"
        >
            <v-card>
                <v-card-title class="pa-1 pl-2">
                    <span class="subheading font-weight-light primary--text">Add Field</span>

                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
<!--Description-->
                            <v-text-field
                                    label="Description"
                                    single-line
                                    v-model="addField.description"
                                    color="primary"
                                    hide-details
                                    style="width: 100%"
                                    class="ma-0 pa-0"
                            ></v-text-field>
<!--Type-->
                            <v-select
                                    v-model="addField.type"
                                    :items="fieldTypes"
                                    label="Select A Type"
                                    hide-details
                                    single-line
                                    color="primary"
                                    item-text="description"
                                    item-value="value"
                            ></v-select>
<!--List Lookup Type-->
                            <v-select
                                    @input="stopChange"
                                    v-if="addField.type == 5"
                                    v-model="addField.lookupType"
                                    :items="lookupTypes"
                                    label="List Type"
                                    hide-details
                                    single-line
                                    color="primary"
                                    item-text="description"
                                    item-value="value"
                                    :disabled="allowChange"
                            ></v-select>
<!--Custom List-->
                        <template v-if="addField.type == 5 && addField.lookupType == 1">
                            <div class="pa-1">
                                <div class="pt-2">
                                    <span class="body-2 greyType--text">Add Items To Create A List</span>
                                </div>
                                <template v-if="Array.isArray(addField.lookup)">
                                    <template v-for="(item, index) in addField.lookup">
                                        <v-layout
                                                row
                                                :key="'CustomListItem' + index"
                                                align-center
                                        >
                                            <v-flex xs12>
                                                <span class="body-1 font-weight-light">{{ item }}</span>
                                            </v-flex>
                                            <v-flex shrink>
                                                <v-btn
                                                        icon
                                                        flat
                                                        small
                                                        color="red"
                                                        @click="addField.lookup.splice(index, 1)"
                                                        class="pa-0 ma-0"
                                                >
                                                    <v-icon>
                                                        close
                                                    </v-icon>
                                                </v-btn>
                                            </v-flex>
                                        </v-layout>
                                    </template>
                                </template>

                                <v-text-field
                                        label="Add New List Item"
                                        single-line
                                        v-model="customListAddDescription"
                                        color="primary"
                                        append-outer-icon="add"
                                        hide-details
                                        style="width: 100%"
                                        @click:append-outer="addItemToCustomList"
                                        @keydown.enter="addItemToCustomList"
                                        class="ma-0 pa-0 pt-2"
                                ></v-text-field>
                            </div>
                        </template>
<!--Select List-->
                        <template v-if="addField.type == 5 && addField.lookupType == 2">
                            <v-layout row align-center class="pt-2">
                                <v-flex xs12>
                                    <span class="body-2">{{ addField.lookup != null && addField.lookup != '' && typeof JSON.parse(addField.lookup).description != 'undefined' ? JSON.parse(addField.lookup).description : 'Please Select a List'}}</span>
                                </v-flex>
                                <v-flex shrink>
                                    <v-btn
                                            small
                                            icon
                                            flat
                                            color="secondary"
                                            @click="showSelectList = true"
                                            class="pa-0 ma-0"
                                    >
                                        <v-icon>list</v-icon>
                                    </v-btn>

                                </v-flex>

                            </v-layout>
                        </template>

                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                            color="secondary"
                            flat
                            @click="closeAndReset"
                            :disabled="addingField"
                    >
                        Cancel
                    </v-btn>
                    <v-btn
                            v-if="showAdd"
                            color="secondary"
                            flat
                            @click="addFieldSetField"
                            :disabled="addingField"
                            :loading="addingField"
                    >
                        Add Field
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <app-select-list-dlg
                :user-info="userInfo"
                :show-dialog="showSelectList"
                @dismiss="showSelectList = false"
                @selected="selectListSelected"
        />
    </v-layout>

</template>

<script>
    import { codeBaseMixin } from "../../../codeBaseMixin";
    import SelectListDlg from '../../../components/General/SelectListDlg'

    export default {
        mixins: [codeBaseMixin],
        components: {
            appSelectListDlg: SelectListDlg
        },
        props: {
            userInfo: Object,
            selectedSet: Object,
        },
        data() {
            return {
                addField: {
                    description: '',
                    type: null,
                    lookup: '',
                    lookupType: null
                },
                fieldTypes: [
                    {description: 'Word Field', value: 1},
                    {description: 'Date Field', value: 2},
                    {description: 'Number Field', value: 3},
                    {description: 'Currency Field', value: 4},
                    {description: 'List Field', value: 5},
                    {description: 'Mail/Document Field', value: 6}
                ],
                lookupTypes: [
                    {description: 'Custom List', value: 1},
                    {description: 'Choose Select List', value: 2},
                ],
                addFieldDlg: false,
                customListAddDescription: '',
                showSelectList: false,
                allowChange: false,
                listSelected: false,
                addingField: false,
            }
        },
        methods: {
            addFieldSetField() {
                this.addingField = true;

                let jSend = this.addField;
                jSend.fieldSet = this.selectedSet.id;
                if (jSend.type != 5) {
                    jSend.lookup = null;
                    jSend.lookupType = null;
                }
                if (jSend.lookupType == 1) {
                    jSend.lookup = JSON.stringify(jSend.lookup)
                    jSend.lookup.split("'").join("/'")
                }

                this.fetch({method: 'POST', url: '/fieldsets/admin/addFieldSetField/' + this.userInfo.entityID, body: JSON.stringify(jSend)})
                    .then((data) => {
                        this.$emit('fieldadded', data);
                        this.addingField = false;
                        this.closeAndReset();
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e)
                    })
            },
            stopChange() {

                if (this.addField.lookupType != null && this.addField.lookupType != 0) {
                    this.allowChange = true;
                }
            },
            closeAndReset() {
                this.addField = {
                    description: '',
                    type: null,
                    lookup: '',
                    lookupType: null
                };
                this.allowChange = false;
                this.addFieldDlg = false;
            },
            addItemToCustomList() {
                if (this.customListAddDescription != null && this.customListAddDescription != '') {

                    if (!Array.isArray(this.addField.lookup)) {
                        this.addField.lookup = []
                    }
                    this.addField.lookup.push(this.customListAddDescription);
                    this.customListAddDescription = '';

                } else  {
                    this.showSnack('Error', 'List Item Can Not Be Blank', 'Close', 'red')
                }
            },
            selectListSelected(data) {
                this.addField.lookup = JSON.stringify(data);
                this.listSelected = true;
            },
        },
        computed: {
            showAdd() {
                if (this.addField.type != 5) {
                    if (this.addField.description == null || this.addField.description == '') {return false}
                    if (this.addField.type == null || this.addField.type == 0) {return false}
                    return true
                } else {
                    if (this.addField.description == null || this.addField.description == '') {return false}
                    if (this.addField.lookupType == 1) {
                        // Custm List
                        return Array.isArray(this.addField.lookup) && this.addField.lookup.length > 0
                    } else {
                        return this.listSelected
                    }
                }
            }
        }

    }
</script>

<style scoped>

</style>
