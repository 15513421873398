import { render, staticRenderFns } from "./WorkflowStepConfigure.vue?vue&type=template&id=230be600&scoped=true"
import script from "./WorkflowStepConfigure.vue?vue&type=script&lang=js"
export * from "./WorkflowStepConfigure.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "230be600",
  null
  
)

export default component.exports