<template>
    <v-layout row fill-height class="scrollable" style="overflow-x: hidden; background: var(--page-back)">
        <v-flex xs4 class="scrollable" style="overflow-x: hidden">
            <smtp-mail-bar
                    :user-info="userInfo"
                    :deleted-view="deletedView"
                    :local-data="localData"
                    :mail-config="mailConfig"
                    :loading-more="loadingMore"
                    :mail-vault="mailVault"
                    @setDeleted="deletedView = $event"
                    :selectedMailIDs="selectedMailIDs"
                    :loadingMailIDs="loadingMailIDs"
                    @getMail="getMail($event)"
                    @bumpScroll="bump($event)"
                    @resetMailView="resetMailView"
                    :search-val="searchVal"
                    @newSearch="search($event)"
            />
        </v-flex>
        <v-flex xs8 class="scrollable pl-0 py-1 pr-1" fill-height>
            <!--            <smtp-mail-view-->
            <!--                    :selectedMailIDs="selectedMailIDs"-->
            <!--                    :loading-view-mail="loadingViewMail"-->
            <!--                    :mail="mailViewMail"-->
            <!--                    :html="mailViewHtml"-->
            <!--                    :user-info="userInfo"-->
            <!--                    :getTaskHeader="getTaskHeader"-->
            <!--                    @setQT="$emit('setQT', $event)"-->
            <!--            />-->

            <v-card class="fill-height scrollable pa-1" v-if="mailViewMail" style="background: var(--page-back)">
                <e-m-l-viewer :filename="mailViewMail.filename" :smtp-cache="true" ref="emlView">
                    <template slot="metaTop">
                        <v-tooltip top v-if="typeof userInfo.createTask != 'undefined' && intToBool(userInfo.createTask)">
                            <v-btn
                                    icon
                                    dark
                                    small
                                    color="secondary"
                                    @click="$emit('createTaskSMTP', getSelectedMails())"
                                    slot="activator"
                                    class="pt-0 mx-1 mt-1"
                            >
                                <v-icon small>add</v-icon>
                            </v-btn>
                            <span>Create Task</span>
                        </v-tooltip>
                        <!--Tasks-->
                        <template v-if="typeof mailViewMail.tasks != 'undefined' && mailViewMail.tasks.length > 0">
                            <template v-for="task in mailViewMail.tasks">
                                <v-menu open-on-hover lazy offset-y nudge-left="150px" :key="task">
                                    <v-chip
                                            slot="activator"
                                            small
                                            label
                                            color="secondary"
                                            text-color="white"
                                    >
                                        <v-icon left small>assignment_turned_in</v-icon>
                                        Task #{{ task }}
                                    </v-chip>
                                    <div style="width: 400px; background: var(--card-back); cursor: pointer">
                                        <task-loading-container
                                                v-if="loadedTasks[task].loadingTask"
                                                :task-id="task"
                                        />
                                        <task-container
                                                v-else
                                                :userInfo="userInfo"
                                                :task="loadedTasks[task].task"
                                                :taskBar="false"
                                                :showNav="false"
                                                @taskSelected="taskSelected(loadedTasks[task].task)"
                                        />
                                    </div>
                                </v-menu>
                            </template>
                        </template>


                        <template v-if="typeof userInfo.createTask != 'undefined' && intToBool(userInfo.createTask)">
                            <v-tooltip
                                    top
                                    v-for="(qt, i) in [...userInfo.quickTasks].sort((a, b) => a.config.order - b.config.order)"
                                    :key="i"
                            >
                                <v-btn
                                        icon
                                        dark
                                        outline
                                        small
                                        color="secondary"
                                        @click="$emit('createTaskSMTP', {mails: getSelectedMails(), qt: qt.config})"
                                        slot="activator"
                                        class="pt-0 mx-1 mt-1 elevation-5"
                                >
                                    <v-icon small>{{ qt.config.icon }}</v-icon>
                                </v-btn>
                                <span>Create {{qt.config.description}} Task</span>
                            </v-tooltip>
                        </template>
                    </template>
                </e-m-l-viewer>
            </v-card>
        </v-flex>
    </v-layout>
</template>

<script>
import {codeBaseMixin} from "../../codeBaseMixin";
import SmtpMailBar from "./Components/smtpMailBar";
import {arrSplice, arrPushMerge, arrPopulated} from "../../codeFunctions";
import SmtpMailView from "./Components/smtpMailView";
import {feed} from '../../feedSocket'
import {eventBus} from "../../main";
import EMLViewer from "@/Mail/EML Viewer/EMLViewer";
import TaskContainer from "../../components/TaskContainer/TaskContainer";
import TaskLoadingContainer from "../../components/TaskContainer/TaskLoadingContainer";

export default {
    components: {SmtpMailView, SmtpMailBar, EMLViewer, TaskLoadingContainer,
        TaskContainer},
    mixins: [codeBaseMixin],
    props: {
        showLinkSmtp: Boolean,
        mailConfig: Object,
        getTaskHeader: Function,
        localData: Object,
        userInfo: Object
    },
    data() {
        return {
            loadingMore: false,
            minID: null,
            pageSize: 20,
            deletedView: false,
            mailVault: [],
            selectedMailIDs: [],
            loadingMailIDs: [],
            searchVal: null,

            lastMailIDLoad: null,
            loadingViewMail: false,
            mailViewMail: null,
            mailViewHtml: null,
            loadedTasks: []

        }
    },
    created() {
        eventBus.$on('refreshSmtp', this.refreshSmtp)
        feed.on('SmtpMailLink', this.SmtpMailLink)
        feed.on('SmtpMailNew', this.SmtpMailNew)
    },
    beforeDestroy() {
        eventBus.$off('refreshSmtp', this.refreshSmtp)
        feed.off('SmtpMailLink', this.SmtpMailLink)
        feed.off('SmtpMailNew', this.SmtpMailNew)
    },
    watch: {

        selectedMailIDs: {
            immediate: true,
            deep: true,
            handler(val) {
                this.$nextTick(() => {
                    this.$emit('selectedSmtpMails', this.getSelectedMails())
                })
            }
        },


        deletedView: {
            immediate: true,
            handler(val) {
                this.searchVal = null;
                this.mailVault = [];
                this.loadingMailIDs = [];
                this.selectedMailIDs = [];
                this.minID = 0;
                this.loadMoreMails();
                this.resetMailView();
            }
        },

        "mailViewMail.tasks" : {
            immediate: true,
            deep: true,
            handler(val) {
                if (arrPopulated(val)) {
                    val.forEach(taskID => {
                        if (typeof this.loadedTasks[taskID] == 'undefined') {
                            this.$set(this.loadedTasks, taskID, {loadingTask: true, task: null});
                            this.getTaskHeader(taskID)
                                    .then((taskHeader) => {
                                        this.$set(this.loadedTasks, taskID, {loadingTask: false, task: taskHeader});
                                    })
                                    .catch((e) => {
                                        console.log(e)
                                    })
                        }
                    })
                }
            }
        },
    },
    methods: {

        refreshSmtp() {
            this.searchVal = null;
            this.mailVault = [];
            this.loadingMailIDs = [];
            this.selectedMailIDs = [];
            this.minID = 0;
            this.loadMoreMails();
            this.resetMailView();
        },
        SmtpMailLink(linkData) {
            this.mailVault.forEach(vaultMail => {
                if (linkData.mails.includes(vaultMail.id)) {
                    if (!vaultMail.tasks.includes(linkData.task)) {
                        vaultMail.tasks.push(linkData.task);
                    }
                }
            })
        },
        SmtpMailNew(mail) {
            if (!this.mailVault.map(vMail => {
                return vMail.id
            }).includes(mail.id)) {
                this.mailVault.splice(0, 0, mail)
            }
        },

        taskSelected(task) {
            eventBus.$emit('gotoTask', task)
        },

        getSelectedMails() {
            return this.mailVault.filter(mail => this.selectedMailIDs.includes(mail.id))
        },

        search(str) {
            this.mailVault = [];
            this.loadingMailIDs = [];
            this.selectedMailIDs = [];
            this.minID = 0;
            this.searchVal = str;
            this.loadMoreMails();
        },
        loadMoreMails() {
            this.loadingMore = true;
            this.fetch({method: 'POST', url:`/smtp/loadMails/${this.userInfo.entityID}/${this.userInfo.userID}/${this.pageSize}/${this.minIDViewMails}/${this.boolToInt(this.deletedView)}`, body: {filterStr: this.searchVal}})
                    .then((data) => {
                        this.minID = data.minID;
                        this.addMailToVault(data.mails);
                        this.loadingMore = false;
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e)
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                        this.loadingMore = false;
                    })
        },
        addMailToVault(mails) {
            mails.forEach(mail => {
                let bAdded = false;
                this.mailVault.forEach(vaultMail => {
                    if (vaultMail.id === mail.id) {
                        Object.assign(vaultMail, mail);
                        bAdded = true;
                    }
                });
                if (!bAdded) {
                    this.mailVault.push(mail)
                }
            })
        },
        getMail(mail) {
            this.resetMailView();
            this.lastMailIDLoad = mail.mailID;
            this.mailViewMail = mail;
            this.$newReq('get', `smtp/markAsRead/${this.userInfo.entityID}/${mail.mailID}`)
                    .catch((e) => {
                        console.log(e)
                        this.$snack.networkError()
                    })
            this.mailVault.forEach(vaultMail => {
                if (mail.mailID === vaultMail.mailID) {
                    mail.readFlag = 1
                }
            })
        },
        bump(data) {
            let pxPerCon = data.scrollHeight / this.viewMails.length;
            let lastConNo = (data.scrollTop + data.clientHeight) / pxPerCon;
            let cRem = this.viewMails.length - lastConNo;
            if (!this.loadingMore && cRem <= 5 && this.minIDViewMails > this.minID) {
                this.loadingMore = true;
                this.loadMoreMails()
            }
        },
        resetMailView() {
            this.loadingViewMail = false;
            this.mailViewMail = null;
            this.mailViewHtml = null
        }
    },

    computed: {
        viewMails() {
            return this.mailVault.filter(() => true).sort((a,b) => a['id'] < b['id'] ? -1 : 1);
        },

        minIDViewMails() {
            return this.viewMails.reduce((id, mail) => {
                return id === null || mail.id < id ? mail.id : id
            }, null)
        }
    }
}
</script>

<style scoped>

</style>
