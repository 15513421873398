<template>
    <div>
        <div class="">
            <v-tooltip bottom v-if="supportInfo != null">
                <v-btn
                        icon
                        flat
                        class="pa-0 ma-0"
                        @click="showSupport = true"
                        slot="activator"
                >
                    <v-icon>help_outline</v-icon>
                </v-btn>
                <span>Support Contacts</span>
            </v-tooltip>
        </div>


        <app-input-dlg
                v-if="showSupport"
                :show-dialog="showSupport"
                title="Support Contacts"
                :cancel-button="true"
                :show-input-box="false"
                @dismiss="showSupport = false"
                :body-padding="false"
                :width="600"
        >
            <template slot="body">
                <div style="max-height: 70vh" class="scrollable px-2 py-1 pt-2 page-back">
                    <template v-for="(topic, i) in supportInfo">
                        <div :key="i" class="white mb-2" style="border-radius: 3px">
                            <v-card flat class="">
                                <div class="px-2 py-1 subheading primary white--text">
                                    {{topic.title}}
                                </div>
                                <div v-if="typeof topic.description != 'undefined' && !blankString(topic.description)">
                                    <div class="px-2 py-0 caption" v-html="topic.description.replace(/\n/g, '<br>')"></div>
                                </div>
                                <v-divider></v-divider>
                                <div v-if="typeof topic.contacts != 'undefined' && arrPopulated(topic.contacts)">
                                    <v-layout row wrap>
                                        <template v-for="(contact, i) in topic.contacts">
                                            <v-flex :xs6="topic.contacts.length >= 1" :xs12="topic.contacts.length === 1" class="pa-1" :key="i">
                                                <v-card hover>
                                                    <div class="body-2 px-1">
                                                        {{contact.name}}
                                                    </div>
                                                    <v-divider></v-divider>
                                                    <div
                                                            class="caption px-1"
                                                            v-for="(key, i) in Object.keys(contact.attrs)"
                                                            :key="i"
                                                    >
                                                        <v-layout row align-center>
                                                            <v-flex xs4>
                                                                {{key}}
                                                            </v-flex>
                                                            <v-flex xs8>
                                                                {{contact.attrs[key]}}
                                                            </v-flex>
                                                        </v-layout>
                                                    </div>
                                                </v-card>
                                            </v-flex>
                                        </template>
                                    </v-layout>
                                </div>
                            </v-card>
                        </div>
                    </template>
                </div>
            </template>
            <template slot="actions">

            </template>
        </app-input-dlg>

    </div>
</template>

<script>
    import {mapGetters} from 'vuex'
    import InputDlg from "./InputDlg";
    import {blankString,arrPopulated} from "../../codeFunctions";

    export default {
        components: {appInputDlg: InputDlg},
        data() {
            return {
                showSupport: false,
            }
        },
        methods: {
            blankString: blankString,
            arrPopulated: arrPopulated
        },
        computed: {
            ...mapGetters('userInfo', ['userInfo']),
            supportInfo() {
                try {
                    return JSON.parse(this.userInfo.entityInfo.supportInfo)
                } catch (e) {
                    return null
                }
            }
        }
    }
</script>

<style scoped>
</style>
