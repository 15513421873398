<template>
    <div>
        <v-layout row style="overflow-x: hidden; overflow-y: auto">
            <v-flex xs12 style="overflow-x: hidden; overflow-y: auto; cursor: pointer">
<!--TaskID / Status / Owner / Folder-->
                <v-layout row align-center style="overflow-x: hidden; overflow-y: auto">
                    <v-flex shrink class="px-1">

                        <v-layout row slot="activator" align-center>
<!--Task Status-->
                            <v-flex shrink>
                                <div class="placeholder-icon-small placeholder-content"></div>
                            </v-flex>
<!--Task No-->
                            <v-flex shrink class="pl-1">
                                <span class="font-weight-black caption red--text text--darken-2" style="float: right">#{{ taskId }}</span>
                            </v-flex>

                        </v-layout>
                    </v-flex>
                    <v-flex xs12>
<!--Owner-->
                        <div class="text-truncate pt-1">
                            <v-icon class="greyType--text text--lighten-1 py-0 pr-1" small>person</v-icon>
                            <div class="caption clear-text placeholder-content rounded-border" style="float: right; width: calc(100% - 20px)">-</div>
                        </div>
<!--Folder-->
                        <div class="text-truncate pt-1">
                            <v-icon class="greyType--text text--lighten-1 py-0 pr-1" small>folder</v-icon>
                            <div class="caption clear-text placeholder-content rounded-border" style="float: right; width: calc(100% - 20px)">-</div>
                        </div>
                    </v-flex>
                </v-layout>
<!--Title-->
                <div class="text-truncate pt-1">
                    <v-icon class="greyType--text text--lighten-1 py-0 pr-1" small>assignment_turned_in</v-icon>
                    <div class="caption font-weight-bold clear-text placeholder-content rounded-border" style="float: right; width: calc(100% - 20px)">-</div>
                </div>
<!--Task Status-->
                <div class="text-truncate pt-1">
                    <v-icon class="greyType--text text--lighten-1 py-0 pr-1" small>mode_comment</v-icon>
                    <div class="caption clear-text placeholder-content rounded-border" style="float: right; width: calc(100% - 20px)">-</div>
                </div>
<!--Folder Groups / Task Age-->
                <div class="text-truncate pt-1">
                    <v-icon class="greyType--text text--lighten-1 py-0 pr-1" small>folder_shared</v-icon>
                    <div class="caption clear-text placeholder-content rounded-border" style="float: right; width: calc(100% - 20px)">-</div>
                </div>
            </v-flex>
            <v-flex shrink class="px-1">
                <v-layout column justify-space-around align-center class="fill-height pa-0 ma-0">
                    <v-flex shrink>
                        <div class="placeholder-btn placeholder-content"></div>
                    </v-flex>
                    <v-flex shrink>
                        <div class="placeholder-btn placeholder-content"></div>
                    </v-flex>
                    <v-flex shrink>
                        <div class="placeholder-btn placeholder-content"></div>
                    </v-flex>
                </v-layout>
            </v-flex>
        </v-layout>

        <v-progress-linear
                color="grey"
                class="pa-0 ma-0"
                height="3"
                :background-opacity="0.5"
                :indeterminate="true"
                background-color="grey"
        ></v-progress-linear>
    </div>
</template>

<script>
    import {codeBaseMixin} from "../../codeBaseMixin";

    export default {
        mixins: [codeBaseMixin],
        props: {
            taskId: Number
        },
    }
</script>

<style scoped>
    .smallBtn {
        width: 25px;
        height: 25px;
    }
</style>
