<template>
    <v-layout row align-center>
<!--User-->
        <v-flex shrink>
            <v-menu open-on-hover bottom offset-y class="pl-1">
                <v-btn
                        slot="activator"
                        icon
                        flat
                        class="pa-0 ma-0"
                        color="primary"
                        depressed
                        small
                >
                    <v-icon>{{ value.userOption == 0 ? 'person' : 'people'}}</v-icon>
                </v-btn>
              <v-card class="page-back">
                <v-list dense>
                  <v-subheader class="primary--text">Filter By User</v-subheader>
                  <v-divider></v-divider>
                  
                  <v-list-tile @click="value.userOption = 0" class="py-0 my-0">
                    <v-icon color="#primary" left>person</v-icon>
                    <v-list-tile-title>Me</v-list-tile-title>
                    <v-icon color="#388E3C" v-if="value.userOption == 0" right>done</v-icon>
                  </v-list-tile>
                  
                  <v-list-tile @click="value.userOption = 1" class="py-0 my-0">
                    <v-icon color="#primary" left>people</v-icon>
                    <v-list-tile-title>All Users</v-list-tile-title>
                    <v-icon color="#388E3C" v-if="value.userOption == 1" right>done</v-icon>
                  </v-list-tile>
                </v-list>
              </v-card>
            </v-menu>
        </v-flex>
<!--State-->
        <v-flex shrink v-show="stateFilter">
            <v-menu open-on-hover bottom offset-y class="pl-1">
                <v-btn
                        slot="activator"
                        icon
                        flat
                        class="pa-0 ma-0"
                        color="primary"
                        depressed
                        small
                >
                    <v-icon v-if="value.stateOption == 0">edit</v-icon>
                    <v-icon v-if="value.stateOption == 1">alarm</v-icon>
                    <v-icon v-if="value.stateOption == 2">spellcheck</v-icon>
                    <v-icon v-if="value.stateOption == 3">done_all</v-icon>
                    <v-icon v-if="value.stateOption == 4">play_arrow</v-icon>
                </v-btn>
              <v-card class="page-back">
                <v-list dense>
                  <v-subheader class="primary--text">Filter By Task State</v-subheader>
                  <v-divider></v-divider>
                  <v-list-tile @click="value.stateOption = 4" class="py-0 my-0">
                    <v-icon color="#primary" left>play_arrow</v-icon>
                    <v-list-tile-title>All Tasks</v-list-tile-title>
                    <v-icon color="#388E3C" v-if="value.stateOption == 4" right>done</v-icon>
                  </v-list-tile>
                  
                  <v-list-tile @click="value.stateOption = 0" class="py-0 my-0">
                    <v-icon color="#primary" left>edit</v-icon>
                    <v-list-tile-title>Open Tasks</v-list-tile-title>
                    <v-icon color="#388E3C" v-if="value.stateOption == 0" right>done</v-icon>
                  </v-list-tile>
                  
                  <v-list-tile @click="value.stateOption = 1" class="py-0 my-0">
                    <v-icon color="#primary" left>alarm</v-icon>
                    <v-list-tile-title>Snoozed Tasks</v-list-tile-title>
                    <v-icon color="#388E3C" v-if="value.stateOption == 1" right>done</v-icon>
                  </v-list-tile>
                  
                  <v-list-tile @click=" value.stateOption = 2" class="py-0 my-0">
                    <v-icon color="#primary" left>spellcheck</v-icon>
                    <v-list-tile-title>Open & Snoozed Tasks</v-list-tile-title>
                    <v-icon color="#388E3C" v-if="value.stateOption == 2" right>done</v-icon>
                  </v-list-tile>
                  
                  <v-list-tile @click="value.stateOption = 3" class="py-0 my-0">
                    <v-icon color="#primary" left>done_all</v-icon>
                    <v-list-tile-title>Closed Tasks</v-list-tile-title>
                    <v-icon color="#388E3C" v-if="value.stateOption == 3" right>done</v-icon>
                  </v-list-tile>
                </v-list>
              </v-card>
            </v-menu>
        </v-flex>
<!--Starred-->
        <v-flex shrink>
            <v-menu open-on-hover bottom offset-y class="pl-1">
                <v-btn
                        slot="activator"
                        icon
                        flat
                        class="pa-0 ma-0"
                        color="primary"
                        depressed
                        small
                >
                    <v-icon>{{ value.starOption == 0 ? 'star_border' : 'star'}}</v-icon>
                </v-btn>
              <v-card>
                <v-list dense class="page-back">
                  <v-subheader class="primary--text">Filter By Star</v-subheader>
                  <v-divider></v-divider>
                  <v-list-tile @click="value.starOption = 0" class="py-0 my-0">
                    <v-icon color="#primary" left>star_border</v-icon>
                    <v-list-tile-title>All Tasks</v-list-tile-title>
                    <v-icon color="#388E3C" v-if="value.starOption == 0" right>done</v-icon>
                  </v-list-tile>
                  
                  <v-list-tile @click="value.starOption = 1" class="py-0 my-0">
                    <v-icon color="#primary" left>star</v-icon>
                    <v-list-tile-title>Starred Tasks</v-list-tile-title>
                    <v-icon color="#388E3C" v-if="value.starOption == 1" right>done</v-icon>
                  </v-list-tile>
                </v-list>
              </v-card>
            </v-menu>
        </v-flex>
<!--Group By-->
        <v-flex shrink>
            <v-menu open-on-hover bottom offset-y class="pl-1">
                <v-btn
                        slot="activator"
                        icon
                        flat
                        class="pa-0 ma-0"
                        color="primary"
                        depressed
                        small
                >
                    <v-icon v-if="value.groupOption == 0">folder</v-icon>
                    <v-icon v-if="value.groupOption == 1">person</v-icon>
                    <v-icon v-if="value.groupOption == 6">people</v-icon>
                    <v-icon v-if="value.groupOption == 3">check_box</v-icon>
                    <v-icon v-if="value.groupOption == 2">ballot</v-icon>
                    <v-icon v-if="value.groupOption == 5">folder_shared</v-icon>
                    <v-icon v-if="value.groupOption == 4">close</v-icon>
                </v-btn>
              
              <v-card class="page-back">
                <v-list dense>
                  <v-subheader class="primary--text">Group By</v-subheader>
                  <v-divider></v-divider>
                  <v-list-tile @click="value.groupOption = 0" class="py-0 my-0">
                    <v-icon color="#primary" left>folder</v-icon>
                    <v-list-tile-title>Folder</v-list-tile-title>
                    <v-icon color="#388E3C" v-if="value.groupOption == 0" right>done</v-icon>
                  </v-list-tile>
                  
                  <v-list-tile @click="value.groupOption = 5" class="py-0 my-0">
                    <v-icon color="#primary" left>folder_shared</v-icon>
                    <v-list-tile-title>Folder Groups</v-list-tile-title>
                    <v-icon color="#388E3C" v-if="value.groupOption == 5" right>done</v-icon>
                  </v-list-tile>
                  
                  <v-list-tile @click="value.groupOption = 1" class="py-0 my-0">
                    <v-icon color="#primary" left>person</v-icon>
                    <v-list-tile-title>Owner</v-list-tile-title>
                    <v-icon color="#388E3C" v-if="value.groupOption == 1" right>done</v-icon>
                  </v-list-tile>
                  
                  <v-list-tile @click="value.groupOption = 6" class="py-0 my-0">
                    <v-icon color="#primary" left>people</v-icon>
                    <v-list-tile-title>Sub Owner</v-list-tile-title>
                    <v-icon color="#388E3C" v-if="value.groupOption == 6" right>done</v-icon>
                  </v-list-tile>
                  
                  <v-list-tile @click="value.groupOption = 2" class="py-0 my-0">
                    <v-icon color="#primary" left>ballot</v-icon>
                    <v-list-tile-title>Workflow</v-list-tile-title>
                    <v-icon color="#388E3C" v-if="value.groupOption == 2" right>done</v-icon>
                  </v-list-tile>
                  
                  <!--                    <v-list-tile @click="value.groupOption = 4" class="py-0 my-0">-->
                  <!--                        <v-icon color="#primary" left>close</v-icon>-->
                  <!--                        <v-list-tile-title>None</v-list-tile-title>-->
                  <!--                        <v-icon color="#388E3C" v-if="value.groupOption == 4" right>done</v-icon>-->
                  <!--                    </v-list-tile>-->
                </v-list>
              </v-card>
            </v-menu>
        </v-flex>
<!--Order By-->
        <v-flex shrink>
            <v-menu open-on-hover bottom offset-y class="pl-1">
                <v-btn
                        slot="activator"
                        icon
                        flat
                        class="pa-0 ma-0"
                        color="primary"
                        depressed
                        small
                >
                    <v-icon>swap_vert</v-icon>
                </v-btn>
              <v-card class="page-back">
                <v-list dense>
                  <v-subheader class="primary--text">Order By</v-subheader>
                  <v-divider></v-divider>
                  <v-list-tile @click="value.orderOption = 0" class="py-0 my-0">
                    <v-icon color="#primary" left>arrow_upward</v-icon>
                    <v-list-tile-title>Ascending</v-list-tile-title>
                    <v-icon color="#388E3C" v-if="value.orderOption == 0" right>done</v-icon>
                  </v-list-tile>
                  <v-list-tile @click="value.orderOption = 1">
                    <v-icon color="#primary" left>arrow_downward</v-icon>
                    <v-list-tile-title>Descending</v-list-tile-title>
                    <v-icon color="#388E3C" v-if="value.orderOption == 1" right>done</v-icon>
                  </v-list-tile>
                </v-list>
              </v-card>
            </v-menu>
        </v-flex>
<!--Expand Drop All-->
<!--        <v-flex shrink>-->
<!--            <v-tooltip v-if="value.groupOption != 4" top class="pl-1">-->
<!--                <v-btn-->
<!--                        @click="$emit('drop', !allDropped)"-->
<!--                        slot="activator"-->
<!--                        icon-->
<!--                        flat-->
<!--                        class="pa-0 ma-0"-->
<!--                        color="primary"-->
<!--                        depressed-->
<!--                        small-->
<!--                >-->
<!--                    <v-icon>{{ !allDropped ? 'keyboard_arrow_down' : 'keyboard_arrow_up'}}</v-icon>-->
<!--                </v-btn>-->
<!--                <span>{{ !allDropped ? 'Expand All Groups' : 'Collapse All Groups'}}</span>-->
<!--            </v-tooltip>-->
<!--        </v-flex>-->
    </v-layout>
</template>

<script>
    export default {
        props:{
            value: Object,
            allDropped: Boolean,
            stateFilter: {
                type: Boolean,
                default: true
            }
        }
    }
</script>

<style scoped>

</style>
