<template>
    <div class="scrollable" >

        <div
                @click="dialog = true"
        >
            <div @click="reload()">
                <slot></slot>
            </div>
        </div>

        <v-dialog
                v-model="dialog"
                fullscreen
        >
            <v-card v-if="dialog" style="height: 100%; overflow-y: hidden">
                <v-layout column fill-height>
                    <v-flex shrink class="primary">
                        <v-layout row align-center>
                            <v-flex xs12>
                                <v-layout row align-center>
                                    <v-flex xs12 class="font-weight-regular white--text pa-2 pl-3 title">
                                        {{localFilename || filename}}
                                    </v-flex>
                                </v-layout>
                                <v-spacer></v-spacer>
                            </v-flex>
                            <v-flex shrink class="pr-2">
                              <v-btn
                                icon
                                flat
                                small
                                color="white"
                                class="pa-0 ma-0"
                                v-if="downloadSrc"
                                @click="() => downloadSrc()"
                              >
                                <v-icon>file_download</v-icon>
                              </v-btn>

                                <a v-else target="_blank" :href="src || localSrc" :download="filename || localFilename">
                                    <v-btn
                                            icon
                                            flat
                                            small
                                            color="white"
                                            class="pa-0 ma-0"
                                    >
                                        <v-icon>file_download</v-icon>
                                    </v-btn>
                                </a>
                            </v-flex>
                            <v-flex shrink class="pr-2">
                                <v-btn
                                        icon
                                        flat
                                        small
                                        color="white"
                                        class="pa-0 ma-0"
                                        @click="dialog = !dialog"
                                >
                                    <v-icon>close</v-icon>
                                </v-btn>
                            </v-flex>
                        </v-layout>
                    </v-flex>
                    <v-flex xs12 class="grey">
                       <div class="fill-height" ref="pdfView">

                       </div>
                    </v-flex>
                </v-layout>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>

import pdf from 'pdfobject'

export default {
    props: {
        src: {
            type: [String, Object],
            default: null
        },
        filename: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            dialog: false,
            localSrc: null,
            localFilename: null,
            embeddedElement: null,
            downloadSrc: null
        }
    },

    methods: {
        localInit(src, title, downloadSrc = null) {

            this.downloadSrc = downloadSrc
            this.dialog = true;
            this.localSrc = src;
            this.localFilename = title
            this.$nextTick(() => {
                this.reload();
            })

        },

        reload() {
            try {
                this.embeddedElement = pdf.embed(this.localSrc || this.src, this.$refs.pdfView)
                this.$nextTick(() => {
                    // console.log(this.$refs.pdfView)
                })
            } catch(e) {
                console.log(e)
            }
        }
    }
}
</script>

<style scoped>

</style>