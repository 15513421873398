<template>
    <v-dialog
            v-model="showDialog"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
            persistent
    >
        <v-card flat height="100vh">
            <v-layout column fill-height >
                <v-flex shrink class="title-color">
<!--Card Title-->
                    <v-card-title class="py-2 pr-2">
                        <v-icon
                            left
                            color="white"
                        >
                            markunread_mailbox
                        </v-icon>
                        <span class="title font-weight-light white--text">Group Mailbox Manager</span>
                        <v-spacer></v-spacer>
                        <v-btn flat icon color="white" class="pa-0 ma-0" @click="showDialog = false">
                            <v-icon>close</v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-divider class="title-color darken-1"></v-divider>
                </v-flex>
<!--Loading-->
                <template v-if="loading">
                    <v-layout column fill-height align-center justify-center>
                        <v-flex shrink class="text-xs-center">
                            <v-progress-circular
                                    :size="40"
                                    :width="5"
                                    color="primary"
                                    indeterminate
                            ></v-progress-circular>
                        </v-flex>
                    </v-layout>
                </template>
                <template v-else>

                    <v-layout row fill-height class="scrollable">
<!--Select Mailbox-->
                        <v-flex xs4 class="scrollable" fill-height>

                            <multi-select-list
                                    :items="mailboxesDetail"
                                    item-key="id"
                                    headline-key="displayText"
                                    :return-object="false"
                                    :multiple="false"
                                    :show-check-boxes="true"
                                    :show-action-buttons="true"
                                    :loading="loadingMailbox"
                                    :showFilter="true"
                                    v-model="selectedMailboxID"
                                    filter-label="Filter Mailboxes"
                                    :action-buttons="[{icon: 'edit', color: 'primary', cb: renameMailbox, hoverItem: true}, {icon: 'toggle_off', color: 'red', cb: disableEnableMailbox, hoverItem: true}]"
                            >
<!--Create Mailbox-->
                                <template slot="header">
                                    <div class="subheading white--text pa-2 title-color">
                                        Select Mailbox
                                    </div>
                                    <div class="py-1 px-2">
                                        <v-btn
                                                small
                                                outline
                                                color="secondary"
                                                class="px-2 py-0 ma-0"
                                                block
                                                slot="activator"
                                                @click="createMailboxObj = {id:null, description:'Mailbox Name', enabled:true, fkUsers:[], fkGroups:[]}"
                                        >
                                            Add New Mailbox
                                        </v-btn>
                                    </div>
                                    <input-dlg
                                            v-if="renameMailboxObject"
                                            :show-dialog="!!renameMailboxObject"
                                            title="Rename Mailbox"
                                            :body="`Please provide a new name for: ${renameMailboxObject.description}`"
                                            :default-input="renameMailboxObject.description"
                                            :cancel-button="true"
                                            :show-input-box="true"
                                            @dismiss="renameMailboxObject = null"
                                            :buttons="['Rename']"
                                            :call-backs="[renameMailboxRunEnd]"
                                    >
                                    </input-dlg>
                                    <input-dlg
                                            v-if="!!createMailboxObj"
                                            :show-dialog="!!createMailboxObj"
                                            title="Create Mailbox"
                                            :body="`Please give the new Mailbox a name:`"
                                            :cancel-button="true"
                                            :show-input-box="true"
                                            @dismiss="createMailboxObj = null"
                                            :buttons="['Create']"
                                            :call-backs="[createMailbox]"
                                    >
                                    </input-dlg>
                                </template>
                            </multi-select-list>
                        </v-flex>
                        <v-flex shrink fill-height>
                            <v-divider vertical class="title-color darken-1"></v-divider>
                        </v-flex>

                        <template v-if="selectedMailboxID && selectedMailbox">

                            <v-flex xs8 class="scrollable" fill-height>

                                <v-layout column fill-height class="scrollable">
                                    <v-flex shrink class="title-color">

                                        <v-hover>
                                            <v-flex
                                                    xs12
                                                    slot-scope="{ hover }"
                                            >
                                                <template>
                                                    <v-layout row align-center ripple style="cursor: pointer">
                                                        <v-flex shrink class="py-2 px-1">
                                                            <span class="subheading white--text pa-2 title-color">Email Address: {{ mailboxAddress }}</span>
                                                            <input type="text" :value="mailboxAddress" id="mailboxEmail" class="bord" style="width: 3px; height: 3px; opacity: 0"/>
                                                        </v-flex>
                                                        <v-flex shrink class="pl-1">
                                                            <v-tooltip right v-if="hover">
                                                                <v-icon @click="copyLink" slot="activator" style="cursor: pointer" class="text--lighten-1 primary--text">file_copy</v-icon>
                                                                <span>Copy Email Address</span>
                                                            </v-tooltip>
                                                        </v-flex>
                                                    </v-layout>
                                                </template>
                                            </v-flex>
                                        </v-hover>
                                    </v-flex>
                                    <v-flex shrink><v-divider class="title-color darken-1"></v-divider></v-flex>
                                    <v-flex xs12 class="scrollable">


                                        <v-layout row fill-height class="scrollable">
                                            <v-flex xs6 class="scrollable" fill-height>

                                                <multi-select-list
                                                        :items="displayGroups"
                                                        item-key="id"
                                                        headline-key="description"
                                                        :return-object="false"
                                                        :multiple="true"
                                                        :show-check-boxes="true"
                                                        :show-action-buttons="true"
                                                        :showFilter="true"
                                                        v-model="modelGroups"
                                                        @input="updateMembership()"
                                                        filter-label="Filter User Groups"
                                                        :show-select-all="false"
                                                        :cutom-sort-fn="groupSort"
                                                >
                                                    <template slot="header">
                                                        <div class="subheading white--text pa-2 title-color">
                                                            User Groups with Access
                                                        </div>
                                                    </template>
                                                </multi-select-list>
                                            </v-flex>
                                            <v-flex shrink fill-height>
                                                <v-divider vertical class="title-color darken-1"></v-divider>
                                            </v-flex>
                                            <v-flex xs6 class="scrollable" fill-height>

                                                <multi-select-list
                                                        :items="displayUsers"
                                                        item-key="id"
                                                        headline-key="description"
                                                        :return-object="false"
                                                        :multiple="true"
                                                        :show-check-boxes="true"
                                                        :show-action-buttons="true"
                                                        :showFilter="true"
                                                        v-model="modelUsers"
                                                        @input="updateMembership()"
                                                        filter-label="Filter Users"
                                                        :show-select-all="false"
                                                        :cutom-sort-fn="userSort"
                                                >
                                                    <template slot="header">
                                                        <div class="subheading white--text pa-2 title-color">
                                                            Users with Access
                                                        </div>
                                                    </template>
                                                </multi-select-list>
                                            </v-flex>
                                        </v-layout>


                                    </v-flex>
                                </v-layout>


                            </v-flex>


                        </template>

                    </v-layout>
                </template>

            </v-layout>
        </v-card>
    </v-dialog>
</template>

<script>

import {mapGetters} from 'vuex';
import MultiSelectList from '@/components/Multi Select List/MultiSelectList';
import InputDlg from '@/components/General/InputDlg';

export default {
    components: {
        MultiSelectList,
        InputDlg
    },
    props: {
        value: Boolean
    },
    data() {
        return {
            createMailboxObj: null,
            loading: true,
            mailboxes: [],
            selectedMailboxID: null,
            loadingMailbox: null,
            renameMailboxObject: null,
            modelGroups: [],
            modelUsers: []
        }
    },
    watch: {
        value: {
            immediate: true,
            handler(val) {
                if (val)
                    this.loadMailboxes()
            }
        },
        selectedMailbox(val) {
            if (val) {
                this.modelGroups = val.fkGroups
                this.modelUsers = val.fkUsers
            } else {
                this.modelGroups = val.fkGroups
            }
        }
    },
    methods: {

        groupSort(a,b) {

            if (this.modelGroups.includes(a.id) && this.modelGroups.includes(b.id))
                return a.description > b.description ? 1 : -1;

            if (this.modelGroups.includes(a.id))
                return -1

            if (this.modelGroups.includes(b.id))
                return 1

            return a.description > b.description ? 1 : -1;
        },

        userSort(a,b) {
            if (this.modelUsers.includes(a.id) && this.modelUsers.includes(b.id))
                return a.description > b.description ? 1 : -1;

            if (this.modelUsers.includes(a.id))
                return -1

            if (this.modelUsers.includes(b.id))
                return 1

            return a.description > b.description ? 1 : -1;
        },

        copyLink() {
            /* Get the text field */
            var copyText = document.getElementById("mailboxEmail");

            /* Select the text field */
            copyText.select();

            /* Copy the text inside the text field */
            document.execCommand("copy");

            /* Alert the copied text */
            this.$snack.info('Address Copied')
        },

        async updateMembership() {

            let mailbox = this.selectedMailbox;
            this.loadingMailbox = mailbox.id
            try {
                let updatedMailbox = await this.$newReq('patch', `smtpMailbox/mailboxes/${this.userInfo.entityID}/${this.userInfo.userID}`, {
                    ...mailbox,
                    fkGroups: this.modelGroups,
                    fkUsers: this.modelUsers,
                })
                this.searchReplace(obj => obj.id === updatedMailbox.id, this.mailboxes, updatedMailbox);
                this.selectedMailboxID = updatedMailbox.id;
            } catch (e) {
                this.$snack.networkError();
            } finally {
                this.loadingMailbox = null
            }

        },

        async createMailbox(val) {
            if (!val) {
                this.$snack.info('A Mailbox Description is Required');
            } else {
                let mailbox = this.createMailboxObj;
                this.createMailboxObj = null;
                this.loadingMailbox = mailbox.id
                try {
                    let updatedMailbox = await this.$newReq('patch', `smtpMailbox/mailboxes/${this.userInfo.entityID}/${this.userInfo.userID}`, {...mailbox, description: val})
                    this.searchReplace(obj => obj.id === updatedMailbox.id, this.mailboxes, updatedMailbox);
                    this.selectedMailboxID = updatedMailbox.id;
                } catch (e) {
                    this.$snack.networkError();
                } finally {
                    this.loadingMailbox = null
                }
            }
        },
        searchReplace(comparison, arr, val) {
            let valAdded = false;
            arr.forEach((arrVal, i) => {
                if (!valAdded && comparison(arrVal)) {
                    arr.splice(i, 1, val)
                    valAdded = true
                }
            })
            if (!valAdded)
                arr.push(val)
        },

        async disableEnableMailbox(val) {
            this.loadingMailbox = val.id
            try {
                let updatedMailbox = await this.$newReq('patch', `smtpMailbox/mailboxes/${this.userInfo.entityID}/${this.userInfo.userID}`, {...val, enabled: !val.enabled})
                this.searchReplace(obj => obj.id === updatedMailbox.id, this.mailboxes, updatedMailbox)
            } catch (e) {
                this.$snack.networkError();
            } finally {
                this.loadingMailbox = null
            }
        },

        async renameMailboxRunEnd(val) {
            if (!val) {
                this.$snack.info('A Mailbox Description Is Required');
            } else {
                let mailbox = this.renameMailboxObject;
                this.renameMailboxObject = null;
                this.loadingMailbox = mailbox.id
                try {
                    let updatedMailbox = await this.$newReq('patch', `smtpMailbox/mailboxes/${this.userInfo.entityID}/${this.userInfo.userID}`, {...mailbox, description: val})
                    this.searchReplace(obj => obj.id === updatedMailbox.id, this.mailboxes, updatedMailbox)
                } catch (e) {
                    this.$snack.networkError();
                } finally {
                    this.loadingMailbox = null
                }
            }
        },

        renameMailbox(val) {
            this.renameMailboxObject = val
        },

        async loadMailboxes() {
            try {
                this.loading = true;
                this.mailboxes = await this.$newReq('get', `smtpMailbox/mailboxes/${this.userInfo.entityID}/${this.userInfo.userID}`)
            } catch (e) {
                this.mailboxes = [];
                this.$snack.networkError();
            } finally {
                this.loading = false;
            }
        }
    },
    computed: {
        ...mapGetters('userInfo', ['userInfo']),
        ...mapGetters(['localData']),
        displayGroups() {
            return this.localData.userGroups.map(obj => { return {id: obj.id, description: obj.description}})
        },
        displayUsers() {
            return this.localData.users
                    .filter(obj => !obj.disabled)
                    .map(obj => {
                        return {
                            id: obj.userID,
                            description: `${obj.name} ${obj.surname}`
                        }
                    })
        },
        showDialog: {
            get() {
                return this.value
            },
            set(newVal) {
                this.$emit('input', newVal)
            }
        },
        mailboxesDetail() {
            return this.mailboxes.map(obj => {
                return {...obj, displayText: `${obj.description}${!obj.enabled ? ' (Disabled)' : ''}`}
            })
        },
        selectedMailbox() {
            try {
                return this.mailboxes.filter(mailbox => mailbox.id === this.selectedMailboxID).pop()
            } catch (e) {
                return []
            }
        },
        mailboxAddress() {
            try {
                return `${this.selectedMailbox.emailAddress}@mail.tasklogix.co.za`
            } catch (e) {
                return ''
            }
        }
    }
}
</script>

<style scoped>

</style>
