<template>
    <v-layout row>
        <v-flex xs12 class="text-xs-center" v-if="showLoading">
            <v-progress-circular
                    :size="28"
                    :width="3"
                    color="secondary"
                    indeterminate
            ></v-progress-circular>
        </v-flex>
        <template v-else>
            <v-flex xs12 v-if="!blockInput">
                <v-layout row align-center>
                    <v-flex xs12>
                        <v-text-field
                                clearable
                                color="primary"
                                flat
                                v-model="value.value"
                                hide-details
                                class="pa-0 ma-0"
                                single-line
                                :label="value.description"
                                type="number"
                                :disabled="!intToBool(value.editable) || !stepEditable"
                                @input="bindData = false"
                        >
                        </v-text-field>
                    </v-flex>
                </v-layout>
            </v-flex>
            <v-flex xs12 v-else class="py-1">
                <v-layout row align-center>
                    <v-flex shrink>
                        <v-icon class="px-2 amber--text">warning</v-icon>
                    </v-flex>
                    <v-flex>
                        <span class="body-2 greyType--text">{{ fieldSetSelectField.description + ' Required' }}</span>
                    </v-flex>
                </v-layout>
            </v-flex>
        </template>
    </v-layout>
</template>

<script>
    import {codeBaseMixin} from "../../../../../../codeBaseMixin";
    import {DatasetBinder} from "@/Tasks/TaskView V2/components/TaskViewDetail/datasetBinder";

    export default {
        mixins: [codeBaseMixin],
        props: {
            value: Object,
            selectFields: Array,
            fieldSets: Array,
            stepEditable: Boolean,
            linkedFieldInfo: Object,
            binder: DatasetBinder
        },

        methods: {
            updateValue(newVal) {
                this.value.value = newVal
            }
        },

        data() {
            return {
                bindData: true,
                loading: false
            }
        },

        computed: {

            fieldSetSelectField() {
                try {
                    return this.binder.datasetSelectFieldsRef[this.lookupFieldID]
                } catch (e) {
                    console.log(e)
                    return null
                }
            },

            blockInput() {
                return !!(this.value.linkLookup
                        && this.value.linkLookup.fieldSetSelectField
                        && !this.fieldSetSelectField.value);
            },

            lookupFieldID() {
                return this.value.linkLookup && this.value.linkLookup.fieldSetSelectField ? this.value.linkLookup.fieldSetSelectField : null
            },

            lookupFieldMemberID() {
                if (this.lookupFieldID)
                    return this.fieldSetSelectField
                            ? this.fieldSetSelectField.value
                            : null
                else return null
            },


            showLoading() {
                if (this.lookupFieldID && this.lookupFieldMemberID) {
                    return this.binder.loadingDatasets.includes(Number(this.lookupFieldMemberID))
                } else {
                    return false
                }
            },

        }
    }
</script>

<style scoped>

</style>
