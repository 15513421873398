<template>
    <div>
        <div class="text-xs-center" v-if="value.value == null">
            <v-btn
                    :disabled="value.editable === 0 || !stepEditable || messageTemplate === false || userInfo.bulkSmsEnabled === 0"
                    color="primary"
                    small
                    @click="sendSmsRun()"
            >
                <v-icon left>textsms</v-icon>
                Send Sms
            </v-btn>
        </div>

        <template v-else>
            <div class="pt-1">
                <app-s-m-s
                        :value="value.value"
                        :local-data="localData"
                        :show-send="value.editable === 1 && stepEditable && messageTemplate !== false && userInfo.bulkSmsEnabled === 1"
                        @sendAgain="sendSmsRun()"
                />
            </div>
        </template>



        <app-send-sms
                :title="value.description"
                v-if="showSendSms"
                :user-info="userInfo"
                :fields="fields"
                :steps="steps"
                :datasets="datasets"
                :show-send-sms="showSendSms"
                :template="messageTemplate"
                :binder="binder"
                @dismiss="showSendSms = false"
                @input="saveRun($event)"
        />
    </div>
</template>

<script>
    import SendSms from "./SendSms";
    import SMS from './SMS.vue';
    import {DatasetBinder} from "@/Tasks/TaskView V2/components/TaskViewDetail/datasetBinder";
    export default {
        components: {
            appSendSms: SendSms,
            appSMS: SMS
        },
        props: {
            value: Object,
            stepEditable: Boolean,
            fields: Array,
            steps: Array,
            datasets: Array,
            userInfo: Object,
            localData: Object,
            binder: DatasetBinder
        },
        data() {
            return {
                showSendSms: false
            }
        },
        methods: {
            saveRun(val) {
                this.value.value = val;
                this.$emit('saveFields')
            },

            sendSmsRun() {
                this.showSendSms = true;
            }
        },
        computed: {
            messageTemplate() {
                try {
                    return JSON.parse(this.value.lookup)
                } catch (e) {
                    // eslint-disable-next-line
                    console.log(e)
                    return false
                }
            }
        }
    }
</script>

<style scoped>

</style>
