<template>
  <div class="c-d-flex c-flex-column fill-height c-d-flex pa-3">
    <v-card style="width: 100%; display: inline-block">
      <v-card-title class="text-h6 title-color white--text">
        {{ selectedField.description }}
      </v-card-title>
      
      <v-card-text>
        
        <v-text-field
            label="Field Description"
            color="primary"
            hide-details
            style="width: 100%"
            v-model="editField.description"
            :disabled="savingChanges"
            :autofocus="true"
        />
        
        <v-autocomplete
            class="pt-4"
            label="Edit Type"
            :items="editTypes"
            item-value="id"
            item-text="description"
            color="primary"
            hide-details
            style="width: 100%"
            v-model="editField.editType"
            :disabled="savingChanges"
        />
        
        <v-autocomplete
            class="pt-4"
            label="Field Type"
            :readonly="!!selectedField.id"
            :items="fieldTypes"
            item-value="id"
            item-text="description"
            color="primary"
            hide-details
            style="width: 100%"
            v-model="editField.type"
            @change="updateLookup"
            :disabled="savingChanges"
        />
        
        <template v-if="editField.type === 2">
          <v-switch
              class="pt-2"
              label="Date Only"
              v-model="editField.lookup.dateOnly"
              color="primary"
              hide-details
              :disabled="savingChanges"
          />
        </template>
        <template v-else-if="editField.type === 3">
          <v-switch
              class="pt-2"
              label="Currency Field"
              v-model="editField.lookup.currency"
              color="primary"
              hide-details
              :disabled="savingChanges"
          />
        </template>
        <template v-else-if="editField.type === 4">
          <v-autocomplete
              class="pt-4"
              label="Select List"
              :items="[...selectLists].sort((a,b) => a.description > b.description ? 1 : -1)"
              item-value="id"
              item-text="description"
              color="primary"
              hide-details
              style="width: 100%"
              v-model="editField.lookup.selectListId"
              :disabled="savingChanges"
          />
        </template>
        <template v-else-if="editField.type === 5">
          <v-autocomplete
              class="pt-4"
              label="Limit Document Type"
              :items="limitToTypes"
              item-value="id"
              item-text="description"
              color="primary"
              hide-details
              style="width: 100%"
              v-model="editField.lookup.limitToType"
              :disabled="savingChanges"
          />
        </template>
        
        
        
        <v-switch
            class="pt-2"
            label="Visible"
            v-model="editField.visible"
            color="primary"
            hide-details
            :disabled="savingChanges"
        />
        
      </v-card-text>
      
      
      <v-card-actions>
        <v-spacer/>
        <v-btn
            flat
            @click="resetField"
            :disabled="savingChanges"
        >Cancel</v-btn>
        <v-btn
            @click="saveChanges"
            color="secondary"
            :disabled="savingChanges"
            :loading="savingChanges"
        >Save Changes</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>

import {arrSplice} from "@/codeFunctions";

const blankField = {
  id: null,
  description: null,
  visible: 1,
  editType: 1,
  type: 1,
  lookup: null,
  order: null
}

export default {
  props: {
    selectedField: {
      type: Object,
      default: () => []
    },
    selectLists: Array
    
  },
  data() {
    return {
      fieldTypes: [
        {id: 1, description: 'Word Field', defaultLookup: {}},
        {
          id: 2,
          description: 'Date Field',
          defaultLookup: {
            dateOnly: true
          }
        },
        {
          id: 3,
          description: 'Number Field',
          defaultLookup: {
            currency: false
          }
        },
        {
          id: 4,
          description: 'List Field',
          defaultLookup: {
            selectListId: null
          }
        },
        {
          id: 5,
          description: 'Document Field',
          defaultLookup: {
            limitToType: null
          }
        },
      ],
      
      limitToTypes: [
        {id: 1, description: 'Any'},
        {id: 2, description: 'PDF Only'},
        {id: 3, description: 'Image Only'},
      ],
      
      editTypes: [
        {id: 1, description: 'Open'},
        {id: 2, description: 'Contact Only'},
        {id: 3, description: 'Tasklogix Only'},
      ],
      
      editField: blankField,
      
      savingChanges: false
    }
  },
  watch: {
    selectedField: {
      immediate: true,
      handler(val) {
        this.resetField()
      }
    }
  },
  methods: {
    updateLookup(val) {
      const selectedLookup = this.fieldTypes.find(fieldType => fieldType.id === val).defaultLookup || {}
      const newLookup = JSON.parse(JSON.stringify(selectedLookup))
      this.editField.lookup = Object.assign(newLookup, this.editField.lookup)
    },
    
    resetField() {
      this.editField = Object.assign(JSON.parse(JSON.stringify(blankField)), JSON.parse(JSON.stringify(this.selectedField)))
    },
    async saveChanges() {
      
      if (!this.editField.description)
        return this.$snack.error('Description is required')
      
      if (!this.editField.type)
        return this.$snack.error('Field Type is required')
      
      if (!this.editField.type === 4 && !this.editField.lookup.selectListId)
        return this.$snack.error('Select list is required')
      
      try {
        this.savingChanges = true
        const savedValue = await this.$newReq('POST', `contacts/setup/customField`, this.editField)
        
        if (this.editField.id)
          this.$emit('field-edited', savedValue)
        else
          this.$emit('field-added', savedValue)
        
      } catch (e) {
        console.log(e)
        this.$snack.networkError()
      } finally {
        this.savingChanges = false
      }
      
    },
  
  },
  computed: {
    visibleSwitch: {
      get() {
        return !!this.editField.visible
      },
      set(val) {
        this.editField.visible = val ? 1 : 0
      }
    }
  },
}
</script>



<style scoped>
  .selected-tile-background {
    background-color: var(--v-primary-base);
    border: 1px solid fuchsia;
    color: red;
    
  }
</style>