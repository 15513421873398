<template>
    <div
            style="overflow-x: hidden; cursor: pointer; position: relative"
            class="mail"
            :class="selectedMails ? 'selectedMessage' : (mail.readFlag === 1 ? 'read' : 'unread')"
    >
        <div ref="content">
            <v-layout row align-space-between class="hide-parent">
                <v-flex shrink class="pl-1" fill-height>
                    <v-layout column fill-height justify-center>
                        <v-flex shrink>
                            <v-btn
                                    icon
                                    flat
                                    small
                                    :color="selectedMails ? 'secondary' : 'grey'"
                                    class="pa-0 ma-0"
                                    @click="selectMail"
                                    :disabled="loadingMail"
                                    :loading="loadingMail"
                            >
                                <v-icon>{{ selectedMails ? 'check_box' : 'check_box_outline_blank' }}</v-icon>
                            </v-btn>
                        </v-flex>
                        <v-flex shrink>
                            <v-btn
                                    v-if="typeof mail.tasks != 'undefined' && mail.tasks.length > 0"
                                    icon
                                    small
                                    flat
                                    color="primary"
                                    class="pa-0 ma-0"
                                    @click="selectedMails = !selectedMails"
                            >
                                <v-icon>link</v-icon>
                            </v-btn>
                        </v-flex>
                    </v-layout>
                </v-flex>

                <v-flex fill-height xs12 @click="selectMeOnly" class="pl-1 pr-2 py-2" style="overflow-x: hidden;" >


                            <v-layout row style="overflow-x: hidden;">
                                <v-flex
                                        xs12 style="overflow-x: hidden;"
                                        class="text-truncate subheading"
                                >
                                    {{mailFrom}}
                                </v-flex>
                                <v-flex shrink
                                        style="white-space: nowrap"
                                        class="caption font-weight-bold greyType--text"
                                >
                                    {{mailDate}}
                                </v-flex>
                            </v-layout>

                            <v-layout row align-center style="overflow-x: hidden;">
                                <v-flex xs12 style="overflow-x: hidden;">
                                    <div
                                            v-if="mailSubject != null"
                                            class="text-truncate body-1"
                                    >
                                        {{mailSubject}}
                                    </div>
                                </v-flex>
<!--                                <v-flex shrink class="hide-child pl-1" v-if="selectedMails" style="position: absolute; left: 0; bottom: 0">-->
<!--                                    <v-btn-->
<!--                                            icon-->
<!--                                            flat-->
<!--                                            small-->
<!--                                            :color="mail.deletedFlag === 1 ? 'green' : 'red'"-->
<!--                                            class="pa-0 ma-0"-->
<!--                                            @click="mail.deletedFlag === 1 ? $emit('restoreMails') : $emit('removeMails')"-->
<!--                                            :disabled="loadingMail"-->
<!--                                    >-->
<!--                                        <v-icon>{{mail.deletedFlag === 1 ? 'restore_from_trash' : 'delete'}}</v-icon>-->
<!--                                    </v-btn>-->
<!--                                </v-flex>-->
                            </v-layout>



                </v-flex>

            </v-layout>
        </div>
        <v-divider></v-divider>
    </div>
</template>

<script>
    import {blankString, arrSplice, getShortDate} from "../../../codeFunctions";

    export default {
        props: {
            mailConfig: Object,
            localData: Object,
            userInfo: Object,
            mail: Object,
            selectedMailIDs: Array,
            loadingMailIDs: Array,
        },

        data() {
            return {
                snippetLines: 2
            }
        },

        methods: {

            selectMail() {
                this.selectedMails = !this.selectedMails;
                if (this.selectedMails) {
                    this.$emit('getMail', this.mail)
                }
            },

            selectMeOnly() {
                arrSplice(this.selectedMailIDs, () => true);
                this.$nextTick(() => {
                    this.selectedMails = true;
                });

                this.$emit('getMail', this.mail)
            }
        },

        computed: {
            loadingMail: {
                get() {
                    return this.loadingMailIDs.includes(this.mail.id)
                },
                set(val) {
                    if (val) {
                        this.loadingMailIDs.push(this.mail.id)
                    } else {
                        arrSplice(this.loadingMailIDs, obj => obj.id !== this.mail.id);
                    }
                }
            },
            selectedMails: {
                get() {
                    return this.selectedMailIDs.includes(this.mail.id)
                },
                set(val) {
                    if (val) {
                        this.selectedMailIDs.push(this.mail.id)
                    } else {
                        arrSplice(this.selectedMailIDs, obj => obj === this.mail.id);
                    }
                }
            },
            mailDate() {
                return getShortDate(this.mail.mailDate);
            },
            mailFrom() {

                let sFrom = this.mail.from;

                if (!blankString(sFrom) && sFrom.includes('<')) {
                    sFrom = sFrom.split('<').shift().trim();
                } else {
                    sFrom = blankString(sFrom) ? 'No Address Provided' : sFrom.replace(/<|>/g,"\\'").trim();
                }

                return `${sFrom}`;
            },
            mailSubject() {
                return blankString(this.mail.subject) ? null : this.mail.subject.trim();
            },

            mailSnippet() {
                return blankString(this.mail.snippet)
                    ? null
                    : this.mail.snippet
                        .split('\n')
                        .reduce((list, str) => {
                            if (list.length < this.snippetLines) {
                                if (!blankString(str) && str.replace(/( )+/g,"") !== '') {
                                    list.push(str.trim())
                                }
                            }
                            return list
                        }, [])

            }

        }
    }
</script>

<style scoped>

.read {
  border-left: 4px solid rgba(0,0,0,0);
  background: var(--hover-item-background);
}

.unread {
  border-left: 4px solid var(--v-primary-base);
}

.mail:hover {
  background-color: var(--hover-item-background);
}

.selectedMessage {
  border-left: 4px solid var(--v-secondary-base);
  background: var(--selected-item);
}

</style>
