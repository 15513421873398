<template>
    <div class="fill-height" ref="boxHeight">
<!--        <vuetable ref="vuetable"-->
<!--                  :fields="headings"-->
<!--                  :api-mode="false"-->
<!--                  :data="dataPrev"-->
<!--        ></vuetable>-->
        <vue-virtual-table
                :config="tableConfig"
                :data="previewData.reportData"
                :height="viewHeight - 12"
                :minWidth="tableConfig.length * 150"
                language="en"
                class="test145"
                :enableExport="false"
                :bordered="true"
                :itemHeight="32"
        >
                <template v-for="slotName in scopedValues" slot-scope="scope" :slot="slotName.val">
                    <template v-if="slotName.type === 'id'">

                            <app-report-preview-task
                                    v-if="scope.row[slotName.val] != null"
                                    :taskID="scope.row[slotName.val]"
                                    :user-info="userInfo"
                                    :get-task-header="getTaskHeader"
                                    @showReports="showPreviewTaskClicked($event)"
                                    :last-task="lastTask"
                            />

                    </template>
                    <template v-if="slotName.type === 'doc'">
                        <div style="width: 150px" class="text-xs-center">
                            <v-menu
                                    open-on-hover
                                    :lazy="true"
                                    class="py-0"
                                    right
                                    offset-x
                                    :disabled="scope.row[slotName.val] == null"
                            >
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                            small
                                            flat
                                            icon
                                            color="secondary"
                                            class="ma-0 pa-0"
                                            style="width: 20px; height: 20px"
                                            v-on="on"
                                            :disabled="scope.row[slotName.val] == null"
                                    >
                                        <v-icon small>attachment</v-icon>
                                    </v-btn>
                                </template>


                                <app-report-preview-mail-doc
                                        v-if="scope.row[slotName.val] != null"
                                        :file="scope.row[slotName.val]"
                                        :user-info="userInfo"
                                        @showPreview="$emit('showPreview', $event)"
                                        @viewMail="mailPreview($event)"
                                />

                            </v-menu>
                        </div>
                    </template>
                </template>
        </vue-virtual-table>

        <v-dialog
                fullscreen
                :value="!!mailFile"
                @input="$event === false ? mailFile = null : null"
        >
            <v-card v-if="!!mailFile" class="scrollable" style="height: 100vh">

                <v-layout column fill-height class="scrollable">
                    <v-flex shrink class="primary">
                        <v-layout row align-center>
                            <v-flex xs12>
                                <v-layout row align-center>
                                    <v-flex xs12 class="font-weight-regular white--text pa-2 title">
                                        Mail View
                                    </v-flex>
                                </v-layout>

                                <v-spacer></v-spacer>


                            </v-flex>
                            <v-flex shrink class="pa-2">
                                <v-btn
                                        icon
                                        flat
                                        color="white"
                                        class="pa-0 ma-0"
                                        @click="mailFile = null"
                                >
                                    <v-icon>close</v-icon>
                                </v-btn>
                            </v-flex>
                        </v-layout>
                    </v-flex>
                    <v-flex xs12 class="scrollable pa-2" v-if="!!mailFile">
                        <e-m-l-viewer :filename="mailFile" :load-from-filename="true" ref="emlView"/>
                    </v-flex>
                </v-layout>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import VueVirtualTable from 'vue-virtual-table';
    import ReportPreviewMailDoc from './ReportPreviewMailDoc';
    import ReportPreviewTask from "./ReportPreviewTask";
    import EMLViewer from "@/Mail/EML Viewer/EMLViewer";
    export default {
        components: {
            EMLViewer,
            appReportPreviewTask: ReportPreviewTask,
            VueVirtualTable,
            appReportPreviewMailDoc: ReportPreviewMailDoc
        },
        props: {
            previewData: Object,
            userInfo: Object,
            getTaskHeader: Function,
        },
        mounted() {
            this.viewHeight = this.$refs.boxHeight.clientHeight
        },
        data() {
            return {
                viewHeight: 0,
                lastTask: [],
                mailFile: null
            }
        },
        watch: {
            previewData: {
                immediate: true,
                deep: true,
                handler() {
                    this.lastTask = [];
                }
            }
        },
        methods: {
            mailPreview(data) {
                this.mailFile = data;
            },

            showPreviewTaskClicked(taskID) {
                this.lastTask.push(taskID);
                this.$emit('showReports', false)
            }
        },
        computed: {
            tableConfig() {
                try {
                    return Object.keys(this.previewData.reportData[0])
                        .map((obj) => {
                            if (!this.previewData.documentColumns.includes(obj) && !this.previewData.idColumns.includes(obj)) {
                                return {
                                    prop:obj,
                                    name:obj,
                                    searchable: true,
                                    sortable: true,
                                    alignItems: 'Left',
                                    width: 500
                                }
                            } else {
                                // console.log(obj)
                                return {
                                    width: 500,
                                    prop: "_action",
                                    name: obj,
                                    actionName: obj,
                                    searchable: this.previewData.idColumns.includes(obj),
                                    sortable: this.previewData.idColumns.includes(obj)
                                }
                            }

                        })
                } catch (e) {
                    // eslint-disable-next-line
                    console.log(e);
                    return []
                }
            },

            scopedValues() {
                try {

                    let tReturn = this.previewData.documentColumns.map((obj) => {
                        return {type: 'doc', val: obj
                        }
                    }).concat(
                        this.previewData.idColumns.map((obj) => {return {type: 'id', val: obj}})
                    );

                    return tReturn


                } catch (e) {
                    // eslint-disable-next-line
                    console.log(e)
                    return[];
                }
            }
        }

    }
</script>

<style scoped>

</style>
