<template>
    <div
            class="hover-item"
            style="cursor: pointer" @click="$emit('selectRecording', rec.id)"
            :style="{
              display: !(toNumber == ext || rec.fromNumber == ext) ? 'none' : null,
              background: selectedRecID === rec.id ? 'var(--selected-item)' : null
            }"
    >
        <v-layout row style="overflow-x: hidden" class="px-2" align-center>
            <v-flex shrink class="pr-2">
                <simple-audio
                        :src="previewAddress"
                />
            </v-flex>
            <v-flex v-if="showFrom" xs7 style="overflow-x: hidden" class="py-1">
                <div class="body-1 text-truncate" style="overflow-x: hidden">
                    {{rec.fromDescription}}
                </div>
                <div style="overflow-x: hidden" class="caption greyType--text ">
                    {{rec.fromNumber}}
                </div>
            </v-flex>
            <v-flex v-if="showTo" xs7 style="overflow-x: hidden" class="py-1">
                <div class="body-1 text-truncate" style="overflow-x: hidden">
                    {{toDescription}}
                </div>
                <div style="overflow-x: hidden" class="caption greyType--text ">
                    {{toNumber}}
                </div>
            </v-flex>

            <v-flex xs2 class="caption font-weight-bold greyType--text" style="white-space: nowrap">
                {{recordingDate}} <br>{{recordingTime}}
            </v-flex>
        </v-layout>
        <v-divider></v-divider>
    </div>
</template>

<script>
    import {codeBaseMixin} from "../../../../codeBaseMixin";
    import SimpleAudio from "../../../../components/General/SimpleAudio";
    import {token} from "@/tokenManSite";

    export default {
        mixins: [codeBaseMixin],
        components: {
            SimpleAudio,
        },
        props: {
            ext: [Number, String],
            userInfo: Object,
            rec: Object,
            selectedRecID: Number,
          showFrom: {default: true, type: Boolean},
          showTo: {default: true, type: Boolean}
        },
        data() {
            return {
              
            }
        },
        methods: {

        },
        computed: {
            previewAddress() {
                return `${this.serverAddress}/3cx/recording/${this.userInfo['3CXServerIndex']}/${this.rec.id}/${token()}`
            },

            recordingTime() {
                return this.getSqlTime(this.rec.date)
            },
            recordingDate() {
                return this.getShortDateLongYear(this.rec.date)
            },
            toDescription() {
                try {
                  const toStr = this.rec.participants[this.rec.participants.length - 1]
                  if (toStr.includes('(')) {
                    let tDesc = this.rec.participants[this.rec.participants.length - 1];
                    tDesc = tDesc.substring(0, tDesc.indexOf('(')).trim();
                    return tDesc;
                  } else {
                    return toStr;
                  }
                } catch (e) {
                    // eslint-disable-next-line
                    console.log(e)
                    return this.rec.participants[this.rec.participants.length - 1]
                }
            },
            toNumber() {
                try {
                    let tNo = this.rec.participants[this.rec.participants.length - 1];
                    
                    if (tNo.includes('(')) {
                      tNo = tNo.substring(tNo.indexOf('(') + 1, tNo.indexOf(')'));
                      return tNo;
                    } else {
                      return tNo
                    }
                } catch (e) {
                    // eslint-disable-next-line
                    console.log(e)
                    return this.rec.participants[this.rec.participants.length - 1]
                }
            }
        }
    }
</script>

<style scoped>

</style>
