<template>
    <div>
        <div v-for="i in numPages" :key="'PDFView' + i" class="px-2" style="max-width: 21.5cm; margin: auto">
            <pdf
                    v-show="loadedPages.includes(i)"
                    :src="src"
                    :page="i"
                    style="margin: auto"
                    @loaded="loadedPages.push(i)"
            ></pdf>
            <div style="height: 20px"></div>
        </div>
    </div>
</template>

<script>

import pdf from 'vue-pdf'

export default {
    components: {pdf},
    props: {
        url: String
    },
    data() {
        return {
            src: null,
            numPages: undefined,
            showViewer: false,
            loadedPages: [],
        }
    },

    beforeDestroy() {
        console.log('DESTROYED')
    },

    mounted() {
        console.log('MOUNTED')

        this.$nextTick(() => {
            this.src = null;
            this.numPages = undefined;
            this.loadedPages = [];

            let loadingTask = pdf.createLoadingTask(this.url);
            this.src = loadingTask;
            this.src.promise.then(pdf => {
                this.numPages = pdf.numPages;
            });
        })

    },
    computed: {

    },
}
</script>

<style scoped>

</style>