<template>

    <div>
        <v-dialog :disabled="disabled" width="700" v-model="menu" style="width: 100%">
<!--Activator-->
            <div slot="activator">
                <slot name="activator">

                </slot>
                <v-text-field
                        v-if="!hasActivatorSlot"
                        append-outer-icon="calendar_today"
                        label="Select A Date"
                        clearable
                        color="primary"
                        flat
                        :value="dateModel"
                        hide-details
                        class="pl-2 ma-0"
                        single-line
                >
                </v-text-field>
            </div>

            <v-card v-if="menu">
<!--Title-->
                <div class="c-d-flex c-align-center py-1 primary">
                    <v-icon color="white" class="ml-2">event</v-icon>
                    <div class="c-flex-grow-1 pa-1 subheading pl-2 white--text">
                        {{displayDate}}
                    </div>
                </div>

                <div class="c-d-flex">
<!--Date Picker-->
                    <div style="width: 50%">
                        <div>
                            <v-date-picker
                                    no-title
                                    id="calendarDiv"
                                    v-model="dateModel"
                                    show-current
                                    color="primary"
                                    full-width
                                    flat
                                    style="height: 100%; border: none; box-shadow: none; background: deeppink"
                                    event-color="amber"
                                    
                            ></v-date-picker>
                        </div>

                    </div>

                    <div style="width: 50%" >

                        <div class="c-d-flex c-flex-column" v-if="!dateOnly">
                            <div class="c-d-flex align-center pa-1">

                                <v-btn
                                        icon
                                        flat
                                        class="pa-0 ma-0"
                                        @click="selectedDate = subHours(selectedDate, 1)"
                                >
                                    <v-icon>chevron_left</v-icon>
                                </v-btn>

                                <div class="font-weight-bold pa-2 text-xs-center c-flex-grow-1">
                                    {{displayTime}}
                                </div>

                                <v-btn
                                        icon
                                        flat
                                        class="pa-0 ma-0"
                                        @click="selectedDate = addHours(selectedDate, 1)"
                                >
                                    <v-icon>chevron_right</v-icon>
                                </v-btn>
                            </div>

                            <div class="c-d-flex align-center mr-2">
                                <div class="c-flex-equal c-flex-grow-1 c-d-flex c-flex-column c-align-center">
                                    <div class="greyType--text caption pa-2">Hour</div>
                                    <div class="c-d-flex c-flex-wrap align-center justify-center">
                                        <v-btn
                                                :key="n"
                                                v-for="n in 12"
                                                icon
                                                style="width: 32px; height: 32px"
                                                class="mx-1 mt-0 mb-1"
                                                :flat="((selectedHour % 12) || 12) !== n"
                                                :color="((selectedHour % 12) || 12) === n ? 'primary' : null"
                                                @click="selectedHour = (selectedHour === 12 && n === 12 ? 0 : ((selectedHour % 12) === n ? (selectedHour < 12 ? n+12 : n) : n))"
                                        >
                                            <span class="caption font-weight-medium">{{n}}</span>
                                        </v-btn>
                                    </div>

                                </div>

                                <div class="c-flex-equal c-flex-grow-1 c-d-flex c-flex-column c-align-center">
                                    <div class="greyType--text caption pa-2">Minute</div>
                                    <div class="c-d-flex c-flex-wrap align-center justify-center">
                                        <v-btn
                                                :key="n"
                                                v-for="n in 12"
                                                icon
                                                class="mx-1 mt-0 mb-1"
                                                style="width: 32px; height: 32px"
                                                :flat="selectedMinute !== (n - 1) * 5"
                                                :color="selectedMinute === (n - 1) * 5 ? 'primary' : null"
                                                @click="selectedMinute = (n - 1) * 5"
                                        >
                                           <span class="caption font-weight-medium">{{`${(n-1)*5}`.padStart(2, '0')}}</span>
                                        </v-btn>
                                    </div>
                                </div>

                                <div class="pl-2 c-flex-equal c-d-flex c-flex-column c-justify-space-between">
                                    <v-btn
                                            style="width: 32px; height: 32px"
                                            icon
                                            class="ma-1"
                                            :flat="selectedHour >= 12"
                                            :color="selectedHour < 12 ? 'primary' : null"
                                            @click="selectedHour = selectedHour < 12 ? selectedHour + 12 : selectedHour - 12"
                                    >
                                        <span class="caption font-weight-medium">AM</span>
                                    </v-btn>
                                    <v-btn
                                            small
                                            icon
                                            class="ma-1"
                                            :flat="selectedHour < 12"
                                            :color="selectedHour >= 12 ? 'primary' : null"
                                            @click="selectedHour = selectedHour < 12 ? selectedHour + 12 : selectedHour - 12"
                                    >
                                        <span class="caption font-weight-medium">PM</span>
                                    </v-btn>
                                </div>


                            </div>
                        </div>

                        <template v-if="dateOnly">
                            <div class="font-weight-bold pa-2 text-xs-center" style="margin-top: 4px">
                                Quick Select
                            </div>

                            <div class="pt-2">
                                <div v-for="(type, i) in quickPickOptions" :key="i" class="c-d-flex c-align-center">

                                    <div class="caption greyType--text" style="width: 30%">
                                        {{type.description}}
                                    </div>

                                    <div class="c-flex-grow-1 c-flex-equal text-xs-center pa-1" v-for="(option, n) in type.options" :key="`option${n}`">
                                        <v-tooltip top>
                                            <v-btn
                                                    small
                                                    flat
                                                    icon
                                                    class="pa-0 ma-0"
                                                    color="secondary"
                                                    slot="activator"
                                                    @click="selectedDate = option.value"
                                            >
                                                {{option.amount}}
                                            </v-btn>
                                            {{option.displayStr}}
                                        </v-tooltip>
                                    </div>

                                </div>
                            </div>
                        </template>
                    </div>
                </div>
                <template v-if="!dateOnly">
                    <div class="font-weight-bold text-xs-center">
                        Quick Select
                    </div>

                    <div class="c-d-flex c-flex-wrap">
                        <div v-for="(type, i) in quickPickOptions" :key="i" class="c-d-flex c-align-center px-5" style="width: 50%">

                            <div class="caption greyType--text" style="width: 30%">
                                {{type.description}}
                            </div>

                            <div class="c-flex-grow-1 c-flex-equal text-xs-center pa-1" v-for="(option, n) in type.options" :key="`option${n}`">
                                <v-tooltip top>
                                    <v-btn
                                            small
                                            flat
                                            icon
                                            class="pa-0 ma-0"
                                            color="surface-accent"
                                            slot="activator"
                                            @click="selectedDate = option.value"
                                    >
                                        {{option.amount}}
                                    </v-btn>
                                    {{option.displayStr}}
                                </v-tooltip>
                            </div>

                        </div>
                    </div>
                </template>

                <v-divider></v-divider>
                <div class="c-d-flex c-justify-space-between" style="background: var(--page-back)">
                    <v-btn
                            small
                            color="secondary"
                            @click="menu = false"
                            outline
                    >
                        Cancel
                    </v-btn>

                    <v-btn
                            small
                            color="secondary"
                            @click="selectDate()"
                    >
                        Done
                    </v-btn>
                </div>
            </v-card>
        </v-dialog>
    </div>

</template>

<script>

import {
    addDays,
    addHours, addMonths, addWeeks,
    format,
    getDayOfYear,
    getHours,
    getMinutes, getYear,
    parse,
    setDayOfYear,
    setHours,
    setMinutes, setYear,
    startOfDay, subHours
} from "date-fns";
import VueScrollTo from "vue-scrollto";

export default {
    props: {
        value: {String, Object},
        modelString: {
            type: Boolean,
            value: false
        },
        dateOnly: {
            type: Boolean,
            value: false
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            menu: false,
            selectedDate: new Date(),
            timeMenu: false,
            format: format,
            subHours: subHours,
            addHours: addHours,
        }
    },
    watch: {
        menu: {
            immediate: true,
            handler(shown) {
                if (shown) {

                    if (this.value) {
                        this.selectedDate = parse(this.value)
                    } else {
                        this.selectedDate = new Date();
                    }

                    if (this.dateOnly) {
                        this.selectedDate = startOfDay(this.selectedDate)
                    }
                }
            }
        },

        timeMenu(val) {

            if (val) {
                setTimeout(() => {
                    VueScrollTo.scrollTo(`#hour${this.selectedHour}`, 1, { container: '#hourDiv', x: false, y: true});
                    setTimeout(() => {
                        VueScrollTo.scrollTo(`#minute${this.selectedMinute}`, 1, { container: '#minuteDiv', x: false, y: true});
                    }, 50)

                }, 250)
            }


        }
    },
    methods: {
        selectDate() {
            this.$emit('input', this.inputValue)
            this.menu = false;
        },

        getAddedDate(start, type, val) {
            switch (type) {
                case 'hour': return addHours(start, val);
                case 'day': return addDays(start, val);
                case 'week': return addWeeks(start, val);
                case 'month': return addMonths(start, val);
            }
        }
    },
    computed: {

        inputValue() {
            if (this.modelString) {
                return format(this.selectedDate, `YYYY-MM-DD${!this.dateOnly ? ' HH:mm' : ''}`)
            } else {
                if (this.dateOnly) {
                    return startOfDay(this.selectedDate)
                } else {
                    return this.selectedDate
                }
            }
        },

        hasActivatorSlot () {
            return !!this.$slots['activator']
        },

        displayDate() {
            return format(this.selectedDate, `Do MMM YYYY${!this.dateOnly ? ' hh:mm A' : ''}`)
        },

        displayTime() {
            return format(this.selectedDate, `hh:mm A`)
        },

        dateModel: {
            get() {
                return format(this.selectedDate, 'YYYY-MM-DD')
            },
            set(val) {
                this.selectedDate = setYear(this.selectedDate, getYear(parse(val)))
                this.selectedDate = setDayOfYear(this.selectedDate, getDayOfYear(parse(val)))
            }
        },

        selectedHour: {
            get() {
                return getHours(this.selectedDate)
            },
            set(val) {
                this.selectedDate = setHours(this.selectedDate, val)
            }
        },

        selectedMinute: {
            get() {
                return Math.ceil(getMinutes(this.selectedDate)/5)*5;
            },
            set(val) {
                this.selectedDate = setMinutes(this.selectedDate, val)
            }
        },

        quickPickOptions() {
            let options = [
                {description: 'Hours', keyValue: 'hour', options: [1,3,6,9,12]},
                {description: 'Days', keyValue: 'day', options: [1,2,3,4,5]},
                {description: 'Weeks', keyValue: 'week', options: [1,2,3,5,6]},
                {description: 'Months', keyValue: 'month', options: [1,2,3,6,12]},
            ].map((type) => {
                return {
                    ...type,
                    options: type.options.map((amm) => {
                        let dateVal = this.getAddedDate(this.dateOnly ? startOfDay(this.selectedDate) : this.selectedDate, type.keyValue, amm)
                        return {
                            amount: amm,
                            value: dateVal,
                            displayStr: format(dateVal, `Do MMM YYYY${!this.dateOnly ? ' hh:mm A' : ''}`)
                        }
                    })
                }
            })
            if (this.dateOnly)
                options.splice(0,1)
            return options
        }





    }
}
</script>

<style scoped>

</style>
