<template>
  <div ref="editor" class="fill-height">
    <!--        <v-btn @click="test">test</v-btn>-->
    <div style="display: none">
      {{ passVal }}
    </div>
    <template v-if="!hideEditor" class="primary">
      <editor
          ref="intenalEditor"
          api-key="rmmqi59eb2ord4m4pb14jprluc3p5yalfjevkjjl1ue9ey7c"
          :tinymceScriptSrc="'/tinymce/tinymce.min.js'"
          :init="editorConfig"
          :value="passVal"
          :style="{height: editorHeight + 'px'}"
          :heignt="editorHeight"
          class="b1"
      />
    </template>
  </div>
</template>

<script>

import Editor from '@tinymce/tinymce-vue'
import axios from "axios";
import {codeBaseMixin} from "@/codeBaseMixin";
import {mapGetters} from 'vuex'
import {newReq} from "@/axiosRequest";

const extraHtml = `
body {
    font-family: "Arial";
    font-size: 48px;
}`

export default {
  mixins: [codeBaseMixin],
  components: {
    'editor': Editor
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    extraConfig: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      uploadProgress: 0,
      hideEditor: true,
      showEditorTimeout: null,
      resizeTimeout: null,
      editorHeight: 50,
      loadingEditor: true,
      editor: null
    }
  },
  
  mounted() {
    // Register an event listener when the Vue component is ready
    if (!((typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1))) {
      window.addEventListener('resize', this.onResize);
    }
    this.$nextTick(() => {
      this.onResize();
    })
  },
  
  beforeDestroy() {
    // Unregister the event listener before destroying this Vue instance
    window.removeEventListener('resize', this.onResize)
    
    this.editor.off("blur", this.updateModel)
  },
  watch: {
    editor(val) {
      if (val) {
        this.editor.on("blur", this.updateModel)
      }
    },
    value(newVal, oldVal) {
      // console.log(newVal)
      if (oldVal !== newVal && this.editor)
        this.editor.setContent(newVal)
    }
  },
  methods: {
    
    test() {
    
    },
    
    removeRedundantDivs(html) {
      const regex = /^(<div>\s*)+(.+?)(<\/div>\s*)+$/gsm;
      return html.replace(regex, (match, openingDiv, content, closingDiv) => {
        return content.trim();
      });
    },
    
    updateModel() {
      try {
        this.passVal = this.removeRedundantDivs(this.editor.getContent() || '')
      } catch (e) {
        console.log(e)
      }
    },
    
    onResize() {
      
      // if (this.editor) {
      //     this.updateModel()
      // }
      //
      //
      // this.hideEditor = true;
      //
      if (this.resizeTimeout !== null) {
        clearTimeout(this.resizeTimeout)
      }
      
      this.resizeTimeout = setTimeout(() => {
        this.hideEditor = false
        try {
          this.editorHeight = this.$refs.editor.clientHeight;
          
          if (this.$refs.intenalEditor) {
            this.$refs.intenalEditor.$el.style.height = this.editorHeight + 'px'
            const editorDiv = this.$refs.editor.querySelector(".tox-tinymce")
            editorDiv.style.height = this.editorHeight + 'px'
          }
        } catch (e) {
          console.log(e)
        }
      }, 250)
    },
  },
  computed: {
    ...mapGetters('userInfo', ['userInfo']),
    passVal: {
      get() {
        return this.value || ' '
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    baseConfig() {
      let vm = this
      
      const extended = {}
      
      if (vm.$store.state.darkTheme) {
        extended.skin = "oxide-dark"
        extended.content_css = "dark"
      }
      
      return {
        ...extended,
        extended_valid_elements: "style,link[href|rel]",
        custom_elements: "style,link,~link",
        valid_children: '+body[style]',
        relative_urls: false,
        remove_script_host: false,
        forced_root_block: false,
        // height: this.editorHeight,
        menubar: true,
        browser_spellcheck: true,
        contextmenu: false,
        fontsize_formats: "6pt 7pt 8pt 9pt 10pt 11pt 12pt 14pt 16pt 18pt 24pt 36pt 48pt",
        paste_filter_drop: false,
        plugins: ['print preview paste importcss searchreplace autolink code visualblocks visualchars fullscreen image link media table charmap hr nonbreaking toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap emoticons'],
        toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview print | insertfile image media link',
        font_formats: "Andale Mono=andale mono,times; Arial Black=arial black; Arial Narrow='Arial Narrow'; Arial=arial,helvetica,sans-serif; Book Antiqua=book antiqua,palatino; CODE39=code39_XL; Calibri=calibri; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats; avant garde",
        content_style: `
                    @import url('/code39_XL.woff');

                    @font-face {
                        font-family: "Arial Narrow";
                        src: url('/Arial Narrow.woff') format('woff');
                    }

                    @font-face {
                        font-family: arial narrow";
                        src: url('/Arial Narrow.woff') format('woff');
                    }

                    @font-face {
                        font-family: "Calibri";
                        src: url("/Calibri.woff") format('woff');
                    }`,
        setup: function (editor) {
          vm.editor = editor
          editor.on('init', function (e) {
            vm.loadingEditor = false;
            
            let fontSize = '12pt'
            let fontName = 'Tahoma'
            
            // let fontColor = "#000000"
            // if (vm.userInfo && vm.userInfo.entityInfo && vm.userInfo.entityInfo.editorFontSize)
            //   fontSize = +vm.userInfo.entityInfo.editorFontSize + 'pt'

            // if (vm.userInfo && vm.userInfo.entityInfo && vm.userInfo.entityInfo.editorFontName)
            //   fontName = vm.userInfo.entityInfo.editorFontName

            // if (vm.userInfo && vm.userInfo.entityInfo && vm.userInfo.entityInfo.editorFontColor)
            //   fontColor = vm.userInfo.entityInfo.editorFontColor
            
            this.getDoc().body.style.fontSize = fontSize;
            this.getDoc().body.style.fontFamily = fontName;
            // this.getDoc().body.style.color = fontColor;
          });
          
          editor.on('focus', function (event) {
            vm.$emit('focus', event)
          });
          
          editor.on('keydown', function (event) {
            if (event.keyCode === 9) { // tab pressed
              if (event.shiftKey) {
                editor.execCommand('Outdent');
              } else {
                editor.execCommand('Indent');
              }
              
              event.preventDefault();
              return false;
            }
          });
        }
      }
    },
    editorConfig() {
      let oConfig = {...this.extraConfig};
      Object.assign(oConfig, this.baseConfig);
      return oConfig;
    }
  }
}
</script>

<style scoped>

</style>