<template>
    <div>
        <!--Signatures-->
        <template v-if="usedSignatures.length > 0">
            <v-divider></v-divider>
            <v-layout row align-center class="hover-item" @click="signaturesDropped = !signaturesDropped">
                <v-flex row class="pa-2 body-2 greyType--text">
                    Signatures
                </v-flex>
                <v-flex shrink class="pr-2">
                    <v-btn
                            icon
                            flat
                            small
                            color="grey"
                            class="pa-0 ma-0"
                    >
                        <v-icon>{{signaturesDropped ? 'arrow_drop_up' : 'arrow_drop_down'}}</v-icon>
                    </v-btn>
                </v-flex>
            </v-layout>
            <v-divider></v-divider>
            <div class="px-2 pb-1 pt-2">
                <div class="primary--text caption">Send Signature Invitation Using:</div>
                <div>
                    <v-autocomplete
                            :items="fromAddresses"
                            item-text="address"
                            v-model="selectedSMTPAddress"
                            return-object
                            color="primary"
                            flat
                            hide-details
                            class="pa-0 ma-0"
                            dense
                            single-line
                    >
                        <template slot="prepend-inner">
                            <div class="pr-2">
                                <v-icon v-if="selectedSMTPAddress == null || typeof selectedSMTPAddress.type == 'undefined'">mail</v-icon>
                                <template v-else>
                                    <img v-if="selectedSMTPAddress.type === 'gmail'" src="../../../../../../../../public/logo_gmail_32px.svg" class="nav-item" height="22px">
                                    <img v-if="selectedSMTPAddress.type === 'office'" src="../../../../../../../../public/outlook_32x1.svg" class="nav-item"  height="32px">
                                    <img v-if="selectedSMTPAddress.type === 'smtp'" src="../../../../../../../../public/smtp.svg" class="nav-item"  height="22px">
                                </template>
                            </div>

                        </template>
                        <template slot="item" slot-scope="address">
                            <v-layout row align-center class="hide-parent">
                                <v-flex shrink class="pr-2">
                                    <img v-if="address.item.type == 'gmail'" src="../../../../../../../../public/logo_gmail_32px.svg" class="nav-item" height="18px">
                                    <img v-if="address.item.type == 'office'" src="../../../../../../../../public/outlook_32x1.svg" class="nav-item"  height="22px">
                                    <img v-if="address.item.type == 'smtp'" src="../../../../../../../../public/smtp.svg" class="nav-item"  height="18px">
                                </v-flex>
                                <v-flex xs12 class="body-1">
                                    {{address.item.address}}
                                </v-flex>

                                <v-flex shrink v-if="defaultFrom.address == address.item.address && defaultFrom.type == address.item.type">
                                    <v-tooltip top>
                                        <v-btn small icon flat color="amber" slot="activator" class="pa-0 ma-0">
                                            <v-icon>
                                                star
                                            </v-icon>
                                        </v-btn>
                                        <span>Default Send Address</span>
                                    </v-tooltip>
                                </v-flex>

                            </v-layout>
                        </template>
                    </v-autocomplete>
                </div>
            </div>
            <v-divider></v-divider>
            <div v-if="signaturesDropped" class="">
                <div v-for="(sig, i) in usedSignatures" class="pa-1">
                    <v-card class="hover-item pb-1" hover style="cursor: pointer">
                        <div class="px-2 py-1 primary white--text">
                            {{sig.description == null || sig.description === "" ? `Signature ${i+1}` : sig.description}}
                        </div>
                        <div class="pt-1 pl-2 caption greyType--text">
                            Email Address:
                        </div>
                        <!--Email Input Field-->
                        <template v-if="sig.emailType === 0">
                            <v-layout
                                    row
                                    align-center
                            >
                                <v-flex shrink class="px-1">
                                    <v-tooltip right v-if="sig.emailError == null">
                                        <v-btn
                                                icon
                                                flat
                                                small
                                                color="green"
                                                class="pa-0 ma-0"
                                                slot="activator"
                                        >
                                            <v-icon>done</v-icon>
                                        </v-btn>
                                        <span>Valid Email Address</span>
                                    </v-tooltip>
                                    <v-tooltip right v-else>
                                        <v-btn
                                                icon
                                                flat
                                                small
                                                color="red"
                                                class="pa-0 ma-0"
                                                slot="activator"
                                        >
                                            <v-icon>error</v-icon>
                                        </v-btn>
                                        <span>{{sig.emailError}}</span>
                                    </v-tooltip>
                                </v-flex>

                                <v-flex xs12 class="pb-1">
                                    <v-text-field
                                            color="primary"
                                            hide-details
                                            class="pa-0 ma-0 pr-2"
                                            single-line
                                            label="Click to Edit"
                                            v-model="sig.emailFieldValue"
                                            @input="validateEmailInput(sig)"
                                    >
                                    </v-text-field>
                                </v-flex>
                            </v-layout>
                            <div v-if="sig.emailError != null" class="text-xs-center red--text caption pt-1 font-weight-bold ">{{sig.emailError}}</div>
                        </template>
                        <!--Other Email Types-->
                        <v-layout
                                v-else
                                row
                                align-center
                        >
                            <v-flex shrink class="px-1">
                                <v-tooltip right v-if="sig.emailError == null">
                                    <v-btn
                                            icon
                                            flat
                                            small
                                            color="green"
                                            class="pa-0 ma-0"
                                            slot="activator"
                                    >
                                        <v-icon>done</v-icon>
                                    </v-btn>
                                    <span>Valid Email Address</span>
                                </v-tooltip>
                                <v-tooltip right v-else>
                                    <v-btn
                                            icon
                                            flat
                                            small
                                            color="red"
                                            class="pa-0 ma-0"
                                            slot="activator"
                                    >
                                        <v-icon>error</v-icon>
                                    </v-btn>
                                    <span>{{sig.emailError}}</span>
                                </v-tooltip>
                            </v-flex>

                            <v-flex
                                    xs12
                                    class="py-1"
                                    :class="sig.emailError == null ? 'primary--text' : 'red--text'"
                            >
                                {{sig.emailError == null ? sig.emailFieldValue : sig.emailError}}
                            </v-flex>
                        </v-layout>

                        <div class="pt-0 pl-2 caption greyType--text">
                            OTP Number:
                        </div>
                        <!--OTP Input Field-->
                        <template v-if="sig.OTPType === 0">
                            <v-layout
                                    row
                                    align-center
                            >
                                <v-flex shrink class="px-1">
                                    <v-tooltip right v-if="sig.OTPError == null">
                                        <v-btn
                                                icon
                                                flat
                                                small
                                                color="green"
                                                class="pa-0 ma-0"
                                                slot="activator"
                                        >
                                            <v-icon>done</v-icon>
                                        </v-btn>
                                        <span>Valid OTP Number</span>
                                    </v-tooltip>
                                    <v-tooltip right v-else>
                                        <v-btn
                                                icon
                                                flat
                                                small
                                                color="red"
                                                class="pa-0 ma-0"
                                                slot="activator"
                                        >
                                            <v-icon>error</v-icon>
                                        </v-btn>
                                        <span>{{sig.OTPError}}</span>
                                    </v-tooltip>
                                </v-flex>

                                <v-flex xs12>
                                    <v-text-field
                                            color="primary"
                                            hide-details
                                            class="pa-0 ma-0 pr-2"
                                            single-line
                                            label="Click to Edit"
                                            v-model="sig.OTPFieldValue"
                                            @input="validateOTPInput(sig)"
                                    >
                                    </v-text-field>
                                </v-flex>
                            </v-layout>
                            <div v-if="sig.OTPError != null" class="text-xs-center red--text caption py-1 font-weight-bold">{{sig.OTPError}}</div>
                        </template>
                        <!--Other OTP Types-->
                        <v-layout
                                v-else
                                row
                                align-center
                        >
                            <v-flex shrink class="px-1">
                                <v-tooltip right v-if="sig.OTPError == null">
                                    <v-btn
                                            icon
                                            flat
                                            small
                                            color="green"
                                            class="pa-0 ma-0"
                                            slot="activator"
                                    >
                                        <v-icon>done</v-icon>
                                    </v-btn>
                                    <span>Valid Cellphone Address</span>
                                </v-tooltip>
                                <v-tooltip right v-else>
                                    <v-btn
                                            icon
                                            flat
                                            small
                                            color="red"
                                            class="pa-0 ma-0"
                                            slot="activator"
                                    >
                                        <v-icon>error</v-icon>
                                    </v-btn>
                                    <span>{{sig.OTPError}}</span>
                                </v-tooltip>
                            </v-flex>

                            <v-flex
                                    xs12
                                    class="py-1"
                                    :class="sig.OTPError == null ? 'primary--text' : 'red--text'"
                            >
                                {{sig.OTPError == null ? sig.OTPFieldValue : sig.OTPError}}
                            </v-flex>
                        </v-layout>
                    </v-card>
                </div>
            </div>
        </template>

        <!--Input Fields-->
        <template v-if="fields.inputFields.length > 0">
            <v-divider></v-divider>
            <v-layout row align-center class="hover-item" @click="userInputFieldsDropped = !userInputFieldsDropped">
                <v-flex row class="pa-2 body-2 greyType--text">
                    Input Fields
                </v-flex>
                <v-flex shrink class="pr-2">
                    <v-btn
                            icon
                            flat
                            small
                            color="grey"
                            class="pa-0 ma-0"
                    >
                        <v-icon>{{userInputFieldsDropped ? 'arrow_drop_up' : 'arrow_drop_down'}}</v-icon>
                    </v-btn>
                </v-flex>
            </v-layout>
            <v-divider></v-divider>
            <div v-if="userInputFieldsDropped" class="">
                <div v-for="field in fields.inputFields" class="pa-1">
                    <v-card class="px-1 hover-item" hover style="cursor: pointer">
                        <v-layout
                                row
                                align-center
                        >
                            <v-flex shrink class="px-1">
                                <v-tooltip right v-if="field.field.value != null && field.field.value !== ''">
                                    <v-btn
                                            icon
                                            flat
                                            small
                                            color="green"
                                            class="pa-0 ma-0"
                                            slot="activator"
                                    >
                                        <v-icon>done</v-icon>
                                    </v-btn>
                                    <span>Field is Complete</span>
                                </v-tooltip>
                                <v-tooltip right v-else>
                                    <v-btn
                                            icon
                                            flat
                                            small
                                            color="red"
                                            class="pa-0 ma-0"
                                            slot="activator"
                                    >
                                        <v-icon>error</v-icon>
                                    </v-btn>
                                    <span>Field is Required</span>
                                </v-tooltip>
                            </v-flex>


                            <v-flex xs12 class="pl-2 caption">
                                <div
                                        class="font-weight-bold pr-2"
                                        :class="field.field.value != null && field.field.value !== '' ? 'primary--text' : 'red--text'"
                                >
                                    {{field.field.description}}
                                </div>
                                <div class="pr-0 pb-1">
                                    <v-textarea
                                            color="primary"
                                            :hide-details="true"
                                            class="pa-0 ma-0"
                                            single-line
                                            auto-grow
                                            :rows="1"
                                            label="Click to Edit"
                                            v-model="field.field.value"
                                    >

                                    </v-textarea>
                                </div>
                            </v-flex>
                        </v-layout>
                    </v-card>
                </div>
            </div>
        </template>

        <!--Workflow Fields-->
        <template v-if="fields.workflowFields.length > 0">
            <v-divider></v-divider>
            <v-layout row align-center class="hover-item" @click="workflowFieldsDropped = !workflowFieldsDropped">
                <v-flex row class="pa-2 body-2 greyType--text">
                    Workflow Fields
                </v-flex>
                <v-flex shrink class="pr-2">
                    <v-btn
                            icon
                            flat
                            small
                            color="grey"
                            class="pa-0 ma-0"
                    >
                        <v-icon>{{workflowFieldsDropped ? 'arrow_drop_up' : 'arrow_drop_down'}}</v-icon>
                    </v-btn>
                </v-flex>
            </v-layout>
            <v-divider></v-divider>
            <div v-if="workflowFieldsDropped" class="">
                <div v-for="field in fields.workflowFields" class="pa-1">
                    <v-card class="px-1 hover-item" hover style="cursor: pointer">
                        <v-layout
                                row
                                align-center
                        >
                            <v-flex shrink class="px-1">
                                <template v-if="field.field.value != null && field.field.value !== ''">
                                    <v-tooltip right>
                                        <v-btn
                                                icon
                                                flat
                                                small
                                                color="green"
                                                class="pa-0 ma-0"
                                                slot="activator"
                                        >
                                            <v-icon>done</v-icon>
                                        </v-btn>
                                        <span>Field is Complete</span>
                                    </v-tooltip>
                                </template>
                                <template v-else>
                                    <v-tooltip v-if="field.field.required === 1" right>
                                        <v-btn
                                                icon
                                                flat
                                                small
                                                color="red"
                                                class="pa-0 ma-0"
                                                slot="activator"
                                        >
                                            <v-icon>error</v-icon>
                                        </v-btn>
                                        <span>Field is Required</span>
                                    </v-tooltip>
                                    <v-tooltip v-else right>
                                        <v-btn
                                                icon
                                                flat
                                                small
                                                color="orange"
                                                class="pa-0 ma-0"
                                                slot="activator"
                                        >
                                            <v-icon>warning</v-icon>
                                        </v-btn>
                                        <span>Field is Blank</span>
                                    </v-tooltip>
                                </template>
                            </v-flex>
                            <v-flex xs12 class="px-2 caption">
                                <div class="font-weight-bold" :class="field.field.value != null && field.field.value !== '' ? 'primary--text' : (field.field.required === 1 ? 'red--text' : 'orange--text')">{{field.field.description}}</div>
                                <div class="font-weight-regular greyType--text">{{stepsObj[field.field.stepID].description}}</div>
                            </v-flex>
                        </v-layout>
                    </v-card>
                </div>
            </div>
        </template>

        <!--Dataset Fields-->
        <template v-if="fields.datasetFields.length > 0">
            <v-divider></v-divider>
            <v-layout row align-center class="hover-item" @click="datasetFieldsDropped = !datasetFieldsDropped">
                <v-flex row class="pa-2 body-2 greyType--text">
                    Dataset Fields
                </v-flex>
                <v-flex shrink class="pr-2">
                    <v-btn
                            icon
                            flat
                            small
                            color="grey"
                            class="pa-0 ma-0"
                    >
                        <v-icon>{{datasetFieldsDropped ? 'arrow_drop_up' : 'arrow_drop_down'}}</v-icon>
                    </v-btn>
                </v-flex>
            </v-layout>
            <v-divider></v-divider>
            <div v-if="datasetFieldsDropped" class="">
                <div v-for="field in fields.datasetFields" class="pa-1">
                    <v-card class="px-1 hover-item" hover style="cursor: pointer">
                        <v-layout
                                row
                                align-center
                        >
                            <v-flex shrink class="px-1">
                                <template v-if="field.field != null && field.field.value != null && field.field.value !== ''">
                                    <v-tooltip right>
                                        <v-btn
                                                icon
                                                flat
                                                small
                                                color="green"
                                                class="pa-0 ma-0"
                                                slot="activator"
                                        >
                                            <v-icon>done</v-icon>
                                        </v-btn>
                                        <span>Field is Complete</span>
                                    </v-tooltip>
                                </template>
                                <template v-else>
                                    <v-tooltip v-if="field.field == null || field.field.required === 1" right>
                                        <v-btn
                                                icon
                                                flat
                                                small
                                                color="red"
                                                class="pa-0 ma-0"
                                                slot="activator"
                                        >
                                            <v-icon>error</v-icon>
                                        </v-btn>
                                        <span>{{field.field == null ? 'No Dataset Selected' : 'Field is Required'}}</span>
                                    </v-tooltip>
                                    <v-tooltip v-else right>
                                        <v-btn
                                                icon
                                                flat
                                                small
                                                color="orange"
                                                class="pa-0 ma-0"
                                                slot="activator"
                                        >
                                            <v-icon>warning</v-icon>
                                        </v-btn>
                                        <span>Field is Blank</span>
                                    </v-tooltip>
                                </template>
                            </v-flex>


                            <v-flex xs12 class="px-2 caption">
                                <div
                                        class="font-weight-bold"
                                        :class="field.field != null && field.field.value != null && field.field.value !== '' ? 'primary--text' : (field.field == null || field.field.required === 1 ? 'red--text' : 'orange--text')"
                                >
                                    <template v-if="field.field != null">{{field.field.fieldDescription}}</template>
                                    <template v-else>Not Selected</template>
                                </div>
                                <div class="font-weight-regular greyType--text">{{field.dsSelectField.description}}</div>
                            </v-flex>
                        </v-layout>
                    </v-card>
                </div>
            </div>
        </template>

    </div>
</template>

<script>
    import {blankString, validateEmail, validateCellNo, arrPopulated} from "../../../../../../../codeFunctions";

    export default {
        props: {
            fields: Object,
            signatures: Array,
            stepsObj: Object,
            flatWorkflowFields: Object,
            flatDatasetSelectFields: Object,
            flatDatasetFieldsByMemberID: Object,
            flatSignatures: Object,
            userInfo: Object,
            mailConfig: Object,
        },
        data() {
            return {
                workflowFieldsDropped: true,
                datasetFieldsDropped: true,
                userInputFieldsDropped: true,
                signaturesDropped: true,
                usedSignatures: [],
                selectedSMTPAddress: null
            }
        },
        watch: {
            usedSignatures: {
                deep:true,
                immediate:true,
                handler(val) {
                    this.$emit('usedSignaturesUpdate', val)
                }
            },

            "fields.signatures": {
                immediate: true,
                handler(val) {
                    this.usedSignatures = [];
                    let addedSignatures = [];

                    if (Array.isArray(this.usedSignatures)) {
                        val.forEach(sigField => {
                            if (!addedSignatures.includes(sigField) && !((this.usedSignatures.map(obj => obj.id)).includes(sigField.field.signature))) {
                                // "id":"eae2dae1-070a-4be3-9188-e6cff596c8ed",
                                // "description":"Signature Input 2",
                                // "message":"Input Heading 2",
                                // "disclaimer":"Input Disclaimer 2",
                                // "emailType":1,
                                // "emailValue":299,
                                // "OTPType":1,
                                // "OTPValue":300

                                let tSig = {};
                                Object.assign(tSig, this.flatSignatures[sigField.fieldID]);

                                // {id: 0, description: 'User Input'},
                                // {id: 1, description: 'Workflow Field'},
                                // {id: 2, description: 'Dataset Field'},
                                switch (tSig.emailType) {
                                    case 0: {
                                        tSig.emailValue = null;
                                        tSig.emailError = `Enter Email Address`;
                                        tSig.emailFieldValue = null;
                                        break;
                                    }
                                    case 1: {
                                        tSig.emailFieldValue = this.flatWorkflowFields[tSig.emailValue].value;
                                        if (blankString(tSig.emailFieldValue)) {
                                            // No Value
                                            tSig.emailError = `${this.flatWorkflowFields[tSig.emailValue].description} - Blank`
                                        } else {
                                            // Validate Value That Is There
                                            if (validateEmail(tSig.emailFieldValue)) {
                                                tSig.emailError = null
                                            } else {
                                                tSig.emailError = `${this.flatWorkflowFields[tSig.emailValue].description} - Invalid Email Address`
                                            }
                                        }
                                        break;
                                    }
                                    case 2: {
                                        let fieldMemberID = this.flatDatasetSelectFields[tSig.emailValue.fieldSetSelectField];
                                        if (blankString(fieldMemberID.value)) {
                                            tSig.emailFieldValue = null;
                                            tSig.emailError = `${fieldMemberID.description} - Not Selected`
                                        } else {
                                            let dsField = this.flatDatasetFieldsByMemberID[fieldMemberID.value].fields[tSig.emailValue.field];
                                            if (blankString(dsField.value)) {
                                                tSig.emailFieldValue = null;
                                                tSig.emailError = `${this.flatDatasetFieldsByMemberID[fieldMemberID.value].fieldSetName} : ${dsField.fieldDescription} - Blank`;
                                            } else {
                                                tSig.emailFieldValue = dsField.value;
                                                if (validateEmail(dsField.value)) {
                                                    tSig.emailError = null
                                                } else {
                                                    tSig.emailError = `${this.flatDatasetFieldsByMemberID[fieldMemberID.value].fieldSetName} : ${dsField.fieldDescription} - Invalid Email Address`
                                                }
                                            }
                                        }
                                        break;
                                    }
                                }

                                switch (tSig.OTPType) {
                                    case 0: {
                                        tSig.OTPValue = null;
                                        tSig.OTPError = `Enter Cellphone Number`;
                                        tSig.OTPFieldValue = null;
                                        break;
                                    }
                                    case 1: {
                                        tSig.OTPFieldValue = this.flatWorkflowFields[tSig.OTPValue].value;

                                        if (blankString(tSig.OTPFieldValue)) {
                                            // No Value
                                            tSig.OTPError = `${this.flatWorkflowFields[tSig.OTPValue].description} - Blank`
                                        } else {
                                            // Validate Value That Is There
                                            if (validateCellNo(tSig.OTPFieldValue)) {
                                                tSig.OTPError = null
                                            } else {
                                                tSig.OTPError = `${this.flatWorkflowFields[tSig.OTPValue].description} - Invalid Number`
                                            }
                                        }
                                        break;
                                    }
                                    case 2: {
                                        let fieldMemberID = this.flatDatasetSelectFields[tSig.OTPValue.fieldSetSelectField];
                                        if (blankString(fieldMemberID.value)) {
                                            tSig.OTPFieldValue = null;
                                            tSig.OTPError = `${fieldMemberID.description} - Not Selected`
                                        } else {
                                            let dsField = this.flatDatasetFieldsByMemberID[fieldMemberID.value].fields[tSig.OTPValue.field];
                                            if (blankString(dsField.value)) {
                                                tSig.OTPFieldValue = null;
                                                tSig.OTPError = `${this.flatDatasetFieldsByMemberID[fieldMemberID.value].fieldSetName} : ${dsField.description} - Blank`;
                                            } else {
                                                tSig.OTPFieldValue = dsField.value;
                                                if (validateCellNo(dsField.value)) {
                                                    tSig.OTPError = null
                                                } else {
                                                    tSig.OTPError = `${this.flatDatasetFieldsByMemberID[fieldMemberID.value].fieldSetName} : ${dsField.fieldDescription} - Invalid Number`
                                                }
                                            }
                                        }
                                        break;
                                    }
                                }

                                addedSignatures.push(tSig.id)
                                this.usedSignatures.push(tSig)
                            }
                        })
                    }
                }
            },

            selectedSMTPAddress: {
                deep:true,
                immediate: true,
                handler(val) {
                    if (val != null) {
                        this.$emit('setSendMethod', {address: val.address, type: val.type === 'gmail' ? 0 : (val.type === 'office' ? 1 : 2), server: typeof val.server !== 'undefined' ? val.server : 0})
                    }
                }
            }
        },
        mounted() {
            try {
                this.sendSigMethod = this.availableSources[0].id;

                if (!blankString(this.userInfo.defaultSendAsAddress)) {
                    try {
                        let oDefault = JSON.parse(this.userInfo.defaultSendAsAddress)
                        this.fromAddresses.forEach(add => {
                            if (add.type === oDefault.type && add.address === oDefault.address) {
                                this.selectedSMTPAddress = add
                            }
                        });
                    } catch (e) {
                        // eslint-disable-next-line
                        console.log(e)
                    }
                }
                if (this.selectedSMTPAddress == null && arrPopulated(this.fromAddresses)) {
                    this.selectedSMTPAddress = this.fromAddresses[0]
                }

            } catch (e) {
                // eslint-disable-next-line
                console.log(e)
            }
        },
        methods: {



            validateEmailInput(sig) {
                if (blankString(sig.emailFieldValue)) {
                    sig.emailError = 'Enter Email Address'
                } else {
                    if (validateEmail(sig.emailFieldValue)) {
                        sig.emailError = null
                    } else {
                        sig.emailError = 'Invalid Email Address'
                    }
                }
            },
            validateOTPInput(sig) {
                if (blankString(sig.OTPFieldValue)) {
                    sig.OTPError = 'Enter OTP Number'
                } else {
                    if (validateCellNo(sig.OTPFieldValue)) {
                        sig.OTPError = null
                    } else {
                        sig.OTPError = 'Invalid OTP Number'
                    }
                }
            }
        },
        computed: {
            sendSigMethod: {
                get() {
                    return this.sendSignatureMethod
                },
                set(val) {
                    this.$emit('setSendSignatureMethod', val)
                }
            },
            availableSources() {
                let tMethods = [];
                if (this.userInfo.gmailEnabled === 1) {
                    tMethods.push({id: 0, description: 'GMail'})
                }
                if (this.userInfo.office365Enabled === 1) {
                    tMethods.push({id: 1, description: 'Office 365'})
                }
                if (this.userInfo.smtpAddressEnabled === 1) {
                    tMethods.push({id: 3, description: 'SMTP'})
                }
                return tMethods
            },
            fromAddresses() {
                let tAddresses = [];
                try {
                    tAddresses = tAddresses.concat(this.mailConfig.gmail.addresses.map(address => {
                        address.type = 'gmail';
                        return address
                    }))
                } catch (e) {}
                try {
                    tAddresses = tAddresses.concat(this.mailConfig.office.addresses.map(address => {
                        address.type = 'office';
                        return address
                    }))
                } catch (e) {
                  // some Error
                }
                try {
                    tAddresses = tAddresses.concat(this.mailConfig.smtp.addresses.map(address => {
                        address.type = 'smtp';
                        return address
                    }))
                } catch (e) {}
                return tAddresses;
            },
            defaultFrom() {
                try {
                    if (this.userInfo.defaultSendAsAddress == null) {
                        throw 'No Default Address Set'
                    }
                    return JSON.parse(this.userInfo.defaultSendAsAddress)
                } catch (e) {
                    // eslint-disable-next-line
                    console.log(e);
                    return {
                        address: 'null',
                        type: 'null'
                    }
                }
            },
        }
    }
</script>

<style scoped>

</style>
