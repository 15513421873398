<template>
    <div class="pa-1">
        <div class="px-1">
            <v-text-field
                    label="Search Fields"
                    single-line
                    clearable
                    prepend-icon="search"
                    color="primary"
                    flat
                    :hide-details="true"
                    v-model="search"
                    class="pa-0 ma-0"
                    ref="myField"
            ></v-text-field>
        </div>
        <div class="scrollable pt-1" :style="`max-height: ${maxHeight}px`">
            <template v-for="step in displaySteps">
                <div :key="`step:${step.id}`">
                    <div class="pa-1 caption primary--text font-weight-bold">
                        {{step.description}}
                    </div>
                    <div class="pl-3">
                        <template v-for="field in filteredFieldsMerge.filter(obj => obj.workflowField.fk_step == step.id)">
                            <v-layout row align-center class="hoverItem">
                                <v-flex shrink class="pl-2">
                                    <v-icon
                                            small
                                            color="primary"
                                            cursor="pointer"
                                            class="pa-0 ma-0"
                                            @click="selectedField(field)"
                                    >
                                        {{field.value != null ? 'check_box' : 'check_box_outline_blank'}}
                                    </v-icon>
                                </v-flex>
                                <v-flex class="caption pa-1">
                                    {{field.workflowField.description}}
                                </v-flex>
                                <v-flex shrink class="pr-2" v-if="field.value != null">
                                    <v-tooltip top>
                                        <v-icon
                                                slot="activator"
                                                small
                                                color="secondary"
                                                cursor="pointer"
                                                class="pa-0 ma-0"
                                                @click="selectedValue(field.value)"
                                        >
                                            {{field.value.readOnly ? 'check_box' : 'check_box_outline_blank'}}
                                        </v-icon>
                                        <span>Read Only</span>
                                    </v-tooltip>
                                </v-flex>
                            </v-layout>
                        </template>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>

   import {codeBaseMixin} from "../../../../../../../../codeBaseMixin";


   export default {
       mixins: [codeBaseMixin],
       components: {

       },
       props: {
           value: Array,
           steps: Array,
           fields: Array,
           maxHeight: {
               type: Number,
               default: 400
           }
       },
       data() {
           return {
               search: '',
               dropedWorkSpaces: [],
               dropedWorkflowGroups: [],
           }
       },
       methods: {
           selectedField(field) {
               if (field.value == null) {
                   let newValue = {
                       id: field.workflowField.id,
                       readOnly: true
                   };
                   this.$emit('input', this.value != null ? this.value.concat(newValue) : [newValue])
               } else {
                   let newValue = this.value.filter(obj => obj.id != field.workflowField.id);
                   this.$emit('input', newValue);
               }
           },
           selectedValue(value) {
               this.$emit('input', this.value.map(obj => {
                   if (obj.id == value.id) {
                       obj.readOnly = !obj.readOnly
                   }
                   return obj
               }));
           }
       },
       computed: {
           filteredFields() {
               if (this.search != null && this.search != '') {
                   return this.fields.filter(obj => obj.description.toLowerCase().includes(this.search.toLowerCase())).sort((a,b) => a['field_order'] < b['field_order'] ? -1 : 1);
               } else {
                   return this.fields.filter(obj => true).sort((a,b) => a['field_order'] < b['field_order'] ? -1 : 1);
               }
           },

           displaySteps() {
               let shownFieldSteps = this.filteredFields.map(obj => {return obj.fk_step})
               return this.steps.filter(step => shownFieldSteps.includes(step.id)).sort((a,b) => a['field_order'] < b['field_order'] ? -1 : 1);
           },

           filteredFieldsMerge() {
               let valueIDs = this.value != null && this.value.length > 0 ? this.value.map(obj => {return obj.id}) : [];

               return this.filteredFields.map(obj => {
                   return {
                       workflowField: obj,
                       value: !valueIDs.includes(obj.id) ? null
                           : this.value.filter(value => value.id == obj.id).pop()
                   }
               });
           }
       }
   }
</script>

<style scoped>

    .hoverItem:hover {
        cursor: pointer;
        border-radius: 3px;
        background: #EEEEEE;
    }
</style>
