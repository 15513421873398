<template>
    <div
            @dragover="$event.preventDefault()"
            @drop="drop($event)"
            :class="getClass(task.ownerID, userInfo.userID, task.taskID)"
            style="overflow-x: hidden; cursor: pointer"
            :style="task.taskID == selectedTaskID ? `background: linear-gradient(90deg, #EEEEEE ${task.status_percentage != null ? task.status_percentage : 0}%, #F5F5F5 ${task.status_percentage != null ? task.status_percentage : 0}%); cursor: pointer;` : ''"
            
    >
        <!--Start Content-->
        <v-layout row style="overflow-x: hidden" class="px-1">
            <v-flex xs12 style="overflow-x: hidden" @click="taskSelected(task.taskID)">

                <v-layout row align-center style="overflow-x: hidden">
                    <v-flex shrink>
                        <v-layout column justify-space-around>
                            <v-flex shrink>
                                <v-layout row align-center>
                                    <v-flex shrink>
                                        <v-tooltip top>
                                            <v-icon v-if="showIcons" small class="pa-0 ma-0 pr-1" slot="activator" color="primary">
                                                {{task.state == 0 ? 'edit' : (task.state == 1 ? 'done_all' : 'alarm')}}
                                            </v-icon>
                                            
                                            <span v-if="task.state == 0">Open - {{ task.status_percentage != null ? task.status_percentage : 0 }}%</span>
                                            <span v-else-if="task.state == 1">Closed - {{ task.status_percentage != null ? task.status_percentage : 0 }}%</span>
                                            <span v-else-if="task.state == 2">Snoozed until {{ getLongDate(task.snoozeDate) }} - {{ task.status_percentage != null ? task.status_percentage : 0 }}%</span>
                                        </v-tooltip>
                                    </v-flex>
                                    <v-flex shrink>
                                        <span class="font-weight-medium caption red--text text--darken-2">#{{ task.taskID }}</span>
                                    </v-flex>
                                </v-layout>
                            </v-flex>
                        </v-layout>

                    </v-flex>
                    <v-flex xs12 style="overflow-x: hidden" class="pl-2">
                        <!--<v-layout row align-center>-->
                        <!--<v-flex shrink>-->
                        <!---->
                        <!--</v-flex>-->
                        <!--</v-layout>-->
                        <div>
                            <v-layout row align-center>
                                <v-flex shrink>
                                    <v-icon v-if="showIcons" class="greyType--text text--lighten-1 pr-1" small>person</v-icon>
                                    <div v-else class="small-icon"></div>
                                </v-flex>
                                <v-flex shrink v-if="task.ownerState == 2">
                                    <v-icon v-if="showIcons" class="greyType--text text--lighten-1 pr-1" small>alarm</v-icon>
                                    <div v-else class="small-icon"></div>
                                </v-flex>
                                <v-flex shrink><span class="text--primary caption">{{ task.ownerName + ' ' + task.ownerSurname }}</span>
                                </v-flex>
                            </v-layout>
                        </div>
                        <div style="overflow-x: hidden">
                            <v-layout row align-center>
                                <v-flex shrink>
                                    <v-icon v-if="showIcons" class="greyType--text text--lighten-1 pr-1" small>folder</v-icon>
                                    <div v-else class="small-icon"></div>
                                </v-flex>
                                <v-flex shrink style="overflow-x: hidden" class="text-truncate"><span class="caption">{{ task.folderID != null ? task.folderName : 'Task Folder' }}</span>
                                </v-flex>
                            </v-layout>
                        </div>
                    </v-flex>
                </v-layout>

                <!--Task Title-->
                <v-layout row align-center>
                        <v-flex shrink>
                            <v-icon v-if="showIcons" class="greyType--text text--lighten-1 pr-1" small>assignment_turned_in</v-icon>
                            <div v-else class="small-icon"></div>
                        </v-flex>

                        <v-flex shrink style="overflow-x: hidden" class="text-truncate"><span class="caption font-weight-bold">{{ !blankString(task.title) ? task.title : 'No Title' }}</span></v-flex>
                    </v-layout>

                <!--Step / Status-->
                <v-layout row align-center>
                    <v-flex shrink>
                        <v-icon v-if="showIcons" class="greyType--text text--lighten-1 pr-1" small>low_priority</v-icon>
                        <div v-else class="small-icon"></div>
                    </v-flex>
                    <v-flex shrink style="overflow-x: hidden" class="text-truncate"><span class="caption">{{ task.curStepName }}</span>
                    </v-flex>
                </v-layout>

                <!--Status Text-->
                <v-tooltip top>
                    <v-layout row align-center slot="activator" style="overflow-x: hidden">
                        <v-flex shrink>
                            <v-icon v-if="showIcons" class="text--lighten-1 pr-1 greyType--text text--lighten-1" small>mode_comment</v-icon>
                            <div v-else class="small-icon"></div>
                        </v-flex>
                        <v-flex xs12 style="overflow-x: hidden" class="text-truncate">
                            <div
                                    v-if="typeof task.workflowStatusValue != 'undefined' && task.workflowStatusValue != null"
                                    class="caption text-truncate"
                                    style="overflow-x: hidden"
                            >
                                {{task.workflowStatusType == 0 ? task.workflowStatusDescription : task.workflowStatusText}}
                            </div>
                            <div
                                    v-else
                                    class="caption text-truncate"
                                    style="overflow-x: hidden"
                            >
                                No Status Set
                            </div>
                        </v-flex>
                        <v-flex
                                v-if="typeof task.workflowStatusValue != 'undefined' && task.workflowStatusValue != null"
                                shrink
                                class="px-1"
                        >
                            <span class="caption greyType--text" style="overflow-x: hidden; white-space: pre">{{`${getDaysOld(task.workflowStatusStartDate == null ? task.workflowStatusCreated : task.workflowStatusStartDate)} ${getDaysOld(task.workflowStatusStartDate == null ? task.workflowStatusCreated : task.workflowStatusStartDate) != 1 ? `Days` : `Day`}`}}</span>
                        </v-flex>
                    </v-layout>
                    <span>{{typeof task.workflowStatusValue != 'undefined' && task.workflowStatusValue != null ? `${task.workflowStatusName} ${task.workflowStatusSurname} - (${getLongDate(task.workflowStatusCreated)})` : 'No Status Set'}}</span>
                </v-tooltip>
                <slot name="statusoverdue"></slot>

                <v-layout row justify-space-between>
                    <!--Folder Group-->
                    <v-flex xs12>
                        <v-layout row align-center>
                            <v-flex shrink>
                                <v-icon v-if="showIcons" class="greyType--text text--lighten-1 pr-1" small>folder_shared</v-icon>
                                <div v-else class="small-icon"></div>
                            </v-flex>
                            <v-flex shrink style="overflow-x: hidden" class="text-truncate">
                                <span class="caption greyType--text font-weight-light">{{ task.folderID != null ? (folderGroupNames == null ? task.folderGroupNames : folderGroupNames) : 'Task Folder' }}</span>
                            </v-flex>
                        </v-layout>
                    </v-flex>
                    <!--task date-->
                    <v-flex shrink class="pr-1">
                        <v-tooltip right>
                            <div
                                    slot="activator"
                                    class="caption font-weight-light"
                                    style="white-space: nowrap"
                            >
                                {{ `${getDaysOld(task.created)} ${getDaysOld(task.created) != 1 ? `Days` : `Day`}` }}
                            </div>
                            <span>{{ 'Task Created: ' + getLongDate(task.created) }}</span>
                        </v-tooltip>
                    </v-flex>
                </v-layout>
            </v-flex>
            <v-flex shrink>
                <template v-if="(typeof task.workflowAccess == 'undefined') || (typeof task.workflowAccess != 'undefined' && task.workflowAccess > 0 && (task.folderAccess > 0 || (!(task.folderAccess) && typeof task.sharedUsers != 'undefined' && task.sharedUsers != null && JSON.parse(task.sharedUsers).includes(userInfo.userID))))">
                    <v-layout column justify-space-around align-center class="fill-height">
                        <v-flex shrink v-if="linkTaskMail" class="pa-1">
                            <v-tooltip top>
                                <v-btn icon small class="pa-0 ma-0" slot="activator" color="secondary" @click="gotoTask();">
                                    <v-icon v-if="showIcons">keyboard_arrow_right</v-icon>
                                    <div v-else class="medium-icon"></div>
                                </v-btn>
                                <span>Navigate To Task</span>
                            </v-tooltip>
                        </v-flex>
                        <v-flex shrink>
                            <v-tooltip top>
                                <v-btn
                                        @click="task.starredID != null ? starTask(task.taskID, false) : starTask(task.taskID, true)"
                                        slot="activator"
                                        small
                                        :color="task.starredID != null ? '#FBC02D' : '#D6D6D6'"
                                        class="pa-0 ma-0"
                                        flat
                                        icon
                                >
                                    <v-icon v-if="showIcons">{{ task.starredID != null ? 'star' : 'star_border' }}</v-icon>
                                    <div v-else class="medium-icon"></div>
                                </v-btn>
                                <span>{{ task.starredID != null ? 'Unstar Task' : 'Star Task' }}</span>
                            </v-tooltip>
                        </v-flex>
                        <v-flex shrink>
                            <v-tooltip top v-if="intToBool(userInfo.allowTransfer)">
                                <v-btn
                                        @click="transferTask(task.taskID)"
                                        slot="activator"
                                        small
                                        color="red"
                                        class="pa-0 ma-0"
                                        flat
                                        icon
                                >
                                    <v-icon v-if="showIcons">forward</v-icon>
                                    <div v-else class="medium-icon"></div>
                                </v-btn>
                                <span>Transfer Task</span>
                            </v-tooltip>
                        </v-flex>
                        <v-flex shrink>
                            <v-tooltip top>
                                <div slot="activator">
                                    <app-add-date-time
                                            :title="`Snooze Task ${task.taskID}`"
                                            :disabled="task.state == 2 || loadingSnooze"
                                            @inputString="snoozeTask($event)"
                                    >
                                        <v-btn
                                                @click="task.state == 2 ? unSnoozeTask(task) : ''"
                                                slot="activator"
                                                small
                                                flat
                                                icon
                                                color="indigo"
                                                class="pa-0 ma-0"
                                                :disabled="loadingSnooze"
                                                :loading="loadingSnooze"
                                        >
                                            <v-icon v-if="showIcons">{{  task.state == 2 ? 'alarm_off' : 'alarm' }}</v-icon>
                                            <div v-else class="medium-icon"></div>
                                        </v-btn>
                                    </app-add-date-time>
                                </div>
                                <span>{{ task.state == 2 ? 'Wake Task' : 'Snooze Task' }}</span>
                            </v-tooltip>
                        </v-flex>
                    </v-layout>
                </template>
                <v-tooltip top v-else>
                    <v-icon v-if="showIcons"
                            slot="activator"
                            color="grey"
                    >
                        security
                    </v-icon>

                    <span>Access To Task Is Denied</span>
                </v-tooltip>
            </v-flex>
        </v-layout>
        <!--End Content-->
        <div v-if="linkTaskMail && selectedTaskID == task.taskID && curView != 'app-gmail'" class="pr-1 pt-1 pb-0 text-xs-right" style="width: 100%">
            <v-divider></v-divider>
            <v-btn small flat color="secondary" @click="linkToTask()" :disabled="linking" :loading="linking">
                <v-icon v-if="showIcons" left>link</v-icon>
                Link To Task
            </v-btn>
        </div>
        <v-divider></v-divider>
        <app-task-bar-container-drop-step-select-dlg
                v-if="showCopy"
                :user-info="userInfo"
                :workflow-i-d="task.workflowID"
                :show-dialog="showCopy"
                @dismiss="showCopy = false"
                @input="copyToTaskStep($event)"
        />
    </div>
</template>

<script>

    import { eventBus } from '../../main';
    import { codeBaseMixin } from '../../codeBaseMixin';
    import TaskBarContainerDropStepSelectDlg from './TaskBarContainerDropStepSelectDlg';
    import AddDateTime from "./AddDateTime";

    export default {
        name: "TaskBarContainer",
        mixins: [codeBaseMixin],
        components: {
            appAddDateTime: AddDateTime,
            appTaskBarContainerDropStepSelectDlg: TaskBarContainerDropStepSelectDlg,
        },
        props: {
            userInfo: Object,
            task: Object,
            selectedTaskID: Number,
            dashboard: {
                default: false,
                type: Boolean
            },
            createTask: {
                default: false,
                type: Boolean
            },
            createTaskMail: {
                default: false,
                type: Boolean
            },
            linkTaskMail: {
                default: false,
                type: Boolean
            },
            calcFolderGroupNames: Boolean,
            folderGroups: Array,
            linkPane: {
                default: false,
                type: Boolean
            },
            linkMailFn: Function,
            curView: String,
            checkSecurity: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                dragData: null,
                showCopy: false,
                droppedTasks: false,
                linking: false,
                showIcons: true,
                loadingSnooze: false
            }
        },
        beforeDestroy() {
            // destroy the vue listeners, etc
            eventBus.$off('mailDrag', this.eventBusMailDrag);
            eventBus.$off('taskDocumentDrag', this.eventBusTaskDocumentDrag);
            eventBus.$off('taskSnoozedDone', this.eventBusTaskSnoozedDone);
            eventBus.$off('taskTransferDone', this.eventBusTaskTransferDone);
            eventBus.$off('updateTaskMeta', this.eventBusUpdateTaskMeta);
        },
        methods: {
            linkToTask() {
                this.linking = true;
                this.linkMailFn(this.task)
                    .then(() => {
                        this.linking = false;
                    })
                    .catch((e) => {
                        console.log(e)
                        this.linking = false;
                    })
            },
            gotoTask() {

                this.$emit('taskNav')

                eventBus.$emit('gotoTask', (this.task));
            },
            copyToTaskStep(stepID) {



                // let jBody = this.drag
                //
                //
                this.fetch({
                    method: 'POST',
                    url: `/tasks/copyMessage/${this.userInfo.entityID}/${this.userInfo.userID}/${this.task.taskID}/${stepID}/${this.dragData.type == 'mail' ? 1 : 3}/${this.dragData.type == 'mail' ? this.dragData.data.mailDBID : this.dragData.data.documentDBID}`,
                })
                    .then((data) => {
                        this.showSnack('Info', 'Copy Complete', 'Close', 'primary')
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e)
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                    })
            },
            drop: function (ev) {
                try {
                    if (['mail','document'].includes(this.dragData.type)) {
                        this.showCopy = true;
                    } else {
                        this.showSnack('Error', 'Only A Mail Or A Document Can Be Copied Here', 'Close', 'red')
                    }
                } catch (e) {
                    // Not Meant To
                }
            },
            getClass(ownerID, userID, taskID) {
                if (this.selectedTaskID != null && taskID != this.selectedTaskID) {
                    return ownerID == userID ? 'myTask taskHeader white-back' : 'otherTask taskHeader white-back'
                } else {
                    return ownerID == userID ? 'myTask' : 'otherTask'
                }
            },
            getDaysOld(date) {
                let dNow = new Date();
                let tValue = this.getDaysDifference(dNow, date);
                return tValue
            },
            taskSelected(task) {

                if (this.linkTaskMail == true) {
                    this.$emit('taskSelectedMailLink', (this.task));
                    this.$emit('taskSelected', this.task)
                } else {

                    if (this.dashboard == true) {
                        if (
                            (typeof this.task.workflowAccess == 'undefined')
                            || (
                                typeof this.task.workflowAccess != 'undefined'
                                && this.task.workflowAccess > 0
                                && (this.task.folderAccess > 0
                                || (
                                    !(this.task.folderAccess)
                                        && typeof this.task.sharedUsers != 'undefined'
                                        && this.task.sharedUsers != null &&
                                        JSON.parse(this.task.sharedUsers).includes(this.userInfo.userID)))
                            )
                        ) {
                            this.gotoTask();
                        } else {
                            this.showSnack('Error', 'Access Denied', 'Close', 'red')
                        }
                    }

                    if (this.createTask == true && !this.createTaskMail && !this.linkTaskMail) {
                        eventBus.$emit('gotoTask', (this.task));
                    }

                    if (this.createTaskMail == true) {
                        this.$emit('taskSelectedMail');
                    }

                    if (this.dashboard == false && this.createTask == false && this.createTaskMail == false && this.linkTaskMail == false) {
                        eventBus.$emit('taskSelected', task)
                    }

                }
            },
            unSnoozeTask(task) {
                this.loadingSnooze = true;

                this.fetch({method: 'DELETE', url:`/tasks/snoozeTask/${this.userInfo.entityID}/${this.userInfo.userID}/${task.taskID}`})
                    .then((data) => {
                        this.task.state = this.task.preSnoozeState;
                        this.task.snoozeDate = null;
                        this.task.snoozeID = null;
                        this.loadingSnooze = false;
                        eventBus.$emit('taskWoke', task.taskID)
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e)
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                        this.loadingSnooze = false;
                    });
            },
            transferTask(taskID) {
                eventBus.$emit('transferTask',{taskID: taskID, userInfo: this.userInfo})
            },
            snoozeTask(snoozeDate) {
                this.loadingSnooze = true;

                this.fetch({method: 'PATCH', url:`/tasks/snoozeTask/${this.userInfo.entityID}/${this.userInfo.userID}/${this.task.taskID}`, body: JSON.stringify({date: snoozeDate})})
                    .then((data) => {
                        this.loadingSnooze = false;
                        eventBus.$emit('taskSnoozedDone', {
                            taskID: this.task.taskID,
                            snoozeID: data[0].snoozedID,
                            snoozedDate: snoozeDate
                        });
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e)
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                        this.loadingSnooze = false;
                    })

            },
            starTask(taskID, value) {

                eventBus.$emit('starTask', {taskID: taskID, value: value});

                this.task.starredID = value ? 1 : null;

                this.fetch({method: value ? 'PATCH' : 'DELETE', url:`/tasks/starTask/${this.userInfo.entityID}/${this.userInfo.userID}/${taskID}`})
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e)
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
                        this.showLogin = true
                    });
            },
            eventBusMailDrag(mail) {
                this.dragData = {
                    type: 'mail',
                    data: mail
                }
            },
            eventBusTaskDocumentDrag(document) {
                this.dragData = {
                    type: 'document',
                    data: document
                }
            },
            eventBusTaskSnoozedDone(transferObj) {
                if (transferObj.taskID == this.task.taskID) {
                    this.task.snoozeID = transferObj.snoozeID;
                    this.task.snoozeDate = transferObj.snoozedDate;
                    this.task.state = 2;
                }
            },
            eventBusTaskTransferDone(transferObj) {
                if (transferObj.taskID == this.task.taskID) {
                    this.task.ownerName = transferObj.transferedTo.userName;
                    this.task.ownerSurname = transferObj.transferedTo.userSurname;
                    this.task.ownerID = transferObj.transferedTo.userID
                }
            },
            eventBusUpdateTaskMeta(meta) {
                if (meta.taskID == this.task.taskID) {
                    this.task.curStepID = meta.curStepID;
                    this.task.curStepName = meta.curStepName;
                    this.task.preSnoozeState = meta.preSnoozeState;
                    this.task.state = meta.state;
                    if (typeof meta.status_percentage != 'undefined') {
                        this.task.status_percentage = meta.status_percentage
                    }
                }
            }
        },
        created() {
            eventBus.$on('mailDrag', this.eventBusMailDrag);
            eventBus.$on('taskDocumentDrag', this.eventBusTaskDocumentDrag);
            eventBus.$on('taskSnoozedDone', this.eventBusTaskSnoozedDone);
            eventBus.$on('taskTransferDone', this.eventBusTaskTransferDone);
            eventBus.$on('updateTaskMeta', this.eventBusUpdateTaskMeta);
        },
        computed: {
            folderGroupNames() {
                return !this.calcFolderGroupNames ? null : (
                    this.task.folderGroups == null ? null : (
                        this.folderGroups.filter(obj => JSON.parse(this.task.folderGroups).includes(obj.id)).map(mObj => {return mObj.description}).join(', ')
                    )
                )
            }
        },
        mounted() {
            setTimeout(() => {
                this.showIcons = true;
            }, 200)
        }
    }
</script>

<style scoped>

    .taskHeader:hover {
        cursor: pointer;
        background: #f9f9f9;
    }

    .groupByGroup{
        background: #f9f9f9;
    }

    .otherTask {
        border-left: 5px solid var(--v-primary-base);
    }

    .myTask {
        border-left: 5px solid var(--v-secondary-base);
    }

    .white-back {
        background: white;
    }

    .small-icon {
        width: 16px;
        height: 16px;
    }

    .medium-icon {
        width: 16px;
        height: 16px;
    }

</style>
