<template>
    <v-card
            class="pa-1"
            @dragstart="drag($event)"
            :draggable="!noDrag && !inEdit"
            flat
            :style="dense ? 'border: 1px solid lightgrey' : ''"
            @contextmenu="show"
    >

        <v-layout row wrap>
            <v-flex xs12>
                <v-layout align-center justify-space-between row wrap class="pb-1">
<!--User Circle-->
                    <v-flex shrink class="font-weight-medium text--small">
                        {{ document.linkUser === -2 ? 'Assist User' : document.linkUser === 0 ? 'System' : document.linkName + ' ' + document.linkSurname }}
                    </v-flex>
                    <v-flex shrink style="font-size: 0.8em" class="font-weight-regular">
                        {{ getLongDate(document.created) }}
                    </v-flex>
                </v-layout>
            </v-flex>
<!--Document Detail-->
            <v-flex xs12 class="caption pt-1">
                <v-layout align-center justify-start row class="pb-1" >
                    <v-hover>
                        <v-flex shrink class="text-xs-center" slot-scope="{ hover }">
                            <v-layout row>
                                <v-flex shrink v-show="hover && hasAccess && canView" class="pl-1 pr-1">

                                    <doc-view
                                            :url="`${serverAddress}/upload/download/${userInfo.entityID}/${document.documentID}/${documentDownloadName}`"
                                    >
                                        <v-avatar :color="hover ? 'primary' : 'secondary'" size="26">
                                            <v-icon small class="white--text" style="cursor: pointer">
                                                visibility
                                            </v-icon>
                                        </v-avatar>
                                    </doc-view>
                                </v-flex>
                                <v-flex shrink>
                                    <v-tooltip top>
                                        <v-avatar v-if="hasAccess" slot="activator" :color="hover ? 'primary' : 'secondary'" size="26" @click="runPreviewAttachment(true)">
                                            <v-icon small class="white--text" style="cursor: pointer">
                                                {{ hover ? 'cloud_download' : 'attach_file'}}
                                            </v-icon>
                                        </v-avatar>
                                        <v-avatar v-else slot="activator" color="secondary" size="26">
                                            <v-icon small class="white--text" style="cursor: pointer">
                                                attach_file
                                            </v-icon>
                                        </v-avatar>
                                        <span>{{hasAccess ? 'Download File' : 'This Document Has Been Restricted'}}</span>
                                    </v-tooltip>
                                </v-flex>
                            </v-layout>
                        </v-flex>
                    </v-hover>

                    <template v-if="!inEdit">
<!--Document Name-->
                        <v-flex shrink v-if="document.extention == 'wav'">
                            <simple-audio :src="`${serverAddress}/upload/download/${userInfo.entityID}/${document.documentID}/${documentDownloadName}`"/>
                        </v-flex>
                        <v-flex xs12 class="pl-1 text-truncate" >
                            {{ document.alias == null ? document.fileName : document.alias }}
                        </v-flex>
<!--No Access Icon-->
                        <v-flex shrink v-if="typeof document.securityLookup != 'undefined' && document.securityLookup != null && JSON.parse(document.securityLookup).length > 0">
                            <v-tooltip top>
                                <v-icon
                                        :color="hasAccess ? 'primary' : 'red'"
                                        class="pa-0 ma-0"
                                        style="cursor: pointer"
                                        slot="activator"
                                >
                                    security
                                </v-icon>
                                <span>{{hasAccess ? 'Document Has Security Applied' : 'This Document Has Been Restricted'}}</span>
                            </v-tooltip>
                        </v-flex>
<!--Edit Button-->
                        <v-flex shrink>
                            <v-tooltip v-if="!fieldValue && !mailTemplate && !noteDoc" left>
                                <v-btn v-if="hasAccess" @click="switchToEditMode" slot="activator" flat icon color="secondary" class="ma-0 pa-0" small>
                                    <v-icon>edit</v-icon>
                                </v-btn>
                                <span>Edit File Name</span>
                            </v-tooltip>

                            <v-tooltip v-if="fieldValue" top>
                                <v-btn
                                        @click="showUpload.show = true"
                                        flat
                                        icon
                                        color="primary"
                                        class="pa-0 ma-0"
                                        slot="activator"
                                >
                                    <v-icon>cloud_upload</v-icon>
                                </v-btn>

                                <span>Upload New Document</span>
                                <app-document-upload-dlg
                                        :dialog="showUpload"
                                        :user-info="userInfo"
                                        :field-value="true"
                                        @documentsUploaded="uploadFinished"
                                />
                                <!--@documentsSaved="updateBar"-->
                            </v-tooltip>

                            <v-tooltip v-if="mailTemplate" top>
                                <v-btn
                                        @click="$emit('removeFromTemplate', true)"
                                        flat
                                        icon
                                        color="red"
                                        class="pa-0 ma-0"
                                        slot="activator"
                                >
                                    <v-icon>clear</v-icon>
                                </v-btn>

                                <span>Remove Document</span>
                            </v-tooltip>
                        </v-flex>
                    </template>
                    <template v-if="inEdit">
                        <v-flex xs10 class="pl-2">
                            <v-text-field
                                    label="File Name"
                                    single-line
                                    color="primary"
                                    clearable
                                    v-model="newDocumentAlias"
                                    @keydown.enter="newDocumentAlias != null || newDocumentAlias.length >= 3 ? updateAlias : showSnack('Error', 'Name Must Be At Least 3 Characters Long', 'Close', 'red')"
                                    hide-details
                                    class="ma-0 pa-0"
                                    ref="renameEdit"
                                    @input="sanitzeText($event)"
                                    @click.stop="() => {console.log('RAN')}"
                            ></v-text-field>
                        </v-flex>
                        <v-flex shrink>
                            <v-tooltip left>
                                <v-btn :disabled="newDocumentAlias == null || newDocumentAlias.length < 3" @click="updateAlias" slot="activator" flat icon color="primary" class="ma-0 pa-0">
                                    <v-icon>save</v-icon>
                                </v-btn>
                                <span>Save Changes</span>
                            </v-tooltip>
                        </v-flex>
                    </template>
                </v-layout>
            </v-flex>
        </v-layout>

        <v-menu
                v-model="showMenu"
                :position-x="x"
                :position-y="y"
                absolute
                offset-y
                v-if="!addingToRepo"
        >
            <v-card>
                <div class="centered-row px-2 hover-item" @click="addToRepo()">
                    <v-icon>contact_page</v-icon>
                    <span class="py-1 px-1">Add To User Store</span>
                </div>
                <div class="centered-row px-2 hover-item" @click="showMenu = false">
                    <v-icon>close</v-icon>
                    <span class="py-1 px-1">Cancel</span>
                </div>
            </v-card>
        </v-menu>
    </v-card>

</template>

<script>

    import { codeBaseMixin } from "../../../../codeBaseMixin";
    import DocumentUploadDlg from './DocumentUploadDlg'
    import {eventBus} from "../../../../main";
    import SimpleAudio from "../../../../components/General/SimpleAudio";
    import DocView from "../../../../DocView/DocView";
    import {addDocToUserRepo} from "@/Document Repository/docRepoMan";

    export default {
        mixins: [codeBaseMixin],
        components: {DocView, SimpleAudio, appDocumentUploadDlg: DocumentUploadDlg},
        props: {
            document: Object,
            userInfo: Object,
            fieldValue: {
                value: false,
                type: Boolean
            },
            mailTemplate: {
                value: false,
                type: Boolean
            },
            noDrag: {
                type: Boolean,
                default: false
            },
            srcTaskAttachment: {
                type: Boolean,
                default: false
            },
            dense: {
                type: Boolean,
                default: false
            },
            noteDoc: {
                default: false,
                type: Boolean
            },
        },
        data() {
            return {
                inEdit: false,
                newDocumentAlias: '',
                showUpload: {show: false},
                showMenu: false,
                x: 0,
                y: 0,
                addingToRepo: false
            }
        },
        methods: {

            addToRepo() {
                this.showMenu = false
                this.addingToRepo = true;
                addDocToUserRepo(this.document.documentDBID)
                    .then(() => {
                        this.$snack.info('Document Added')
                    })
                    .catch(e => {
                        console.log(e)
                        this.$snack.networkError()
                    })
                    .finally(() => {
                        this.addingToRepo = false;
                    })
            },

            show (e) {
                e.preventDefault()
                this.showMenu = false
                this.x = e.clientX
                this.y = e.clientY
                this.$nextTick(() => {
                    this.showMenu = true
                })
            },

            runPreviewAttachment(download) {
                let downloadAddress = '';
                downloadAddress = `${this.serverAddress}/upload/download/${this.userInfo.entityID}/${this.document.documentID}/${this.documentDownloadName}`;
                let win = window.open(downloadAddress, '_blank');
            },

            sanitzeText(text) {
                if (text != null && text != '') {
                    this.newDocumentAlias = this.newDocumentAlias.replace(/[/\\?%*:|"<>,]/g, '-');
                }
            },

            switchToEditMode() {
                this.inEdit = true;
                this.newDocumentAlias = this.document.alias != null && this.document.alias != '' ? this.document.alias : this.document.fileName;
                this.$nextTick(() => {
                    this.$refs.renameEdit.focus();
                })
            },

            drag: function (event) {
                event.dataTransfer.setData("text", JSON.stringify({dragType: 'document', data: this.document.documentDBID}));
                if (!this.srcTaskAttachment) {
                    eventBus.$emit('taskDocumentDrag', this.document);
                } else {
                    eventBus.$emit('SRCtaskDocumentDrag', this.document);
                }
            },

            uploadFinished(data) {
                this.$emit('uploadDone', data)
            },
            updateAlias() {
                this.document.alias = this.newDocumentAlias;
                this.inEdit = false;
                this.newDocumentAlias = '';

                this.fetch({
                    url: '/tasks/updateAlias/' + this.userInfo.entityID + '/' + this.document.documentDBID,
                    method: 'PATCH',
                    body: JSON.stringify({alias: this.document.alias})
                })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e)
                    })

            }
        },
        computed: {
            documentDownloadName() {
                if (this.document.alias == null) {
                    return this.document.fileName
                } else {
                    return this.document.alias.replace(/\.pdf$/g, '') + '.' + this.document.extention
                }
            },
            hasAccess() {
                if (typeof this.document.securityLookup != 'undefined' && this.document.securityLookup != null){
                    let groupsWithOutAccess = []
                    try {
                        groupsWithOutAccess = JSON.parse(this.document.securityLookup)
                        if (typeof groupsWithOutAccess == 'number') {
                            groupsWithOutAccess = [groupsWithOutAccess]
                        }

                    } catch (e) {
                        return true
                    }
                    return this.userInfo.userGroups.filter(element => groupsWithOutAccess.includes(element)).length <= 0
                } else {
                    return true
                }
            },
            canView() {
                try {
                    return ['pdf'].includes(this.document.extention.toLowerCase());
                } catch(e) {
                    return false
                }
            }
        }

    }
</script>

<style scoped>

</style>
