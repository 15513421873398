<template>
    <div>
        <v-dialog
                v-if="showDialog"
                v-model="showDialog"
                fullscreen
                hide-overlay
                transition="dialog-bottom-transition"
                persistent
        >
            <v-card flat height="100vh" v-if="showDialog">

                <v-layout column fill-height >
<!--Card Title-->
                    <v-flex shrink primary>
                        <v-card-title class="py-2 pr-2 ">

                            <v-icon class="white--text">description</v-icon>
                            <span class="title font-weight-light white--text pl-2">Document View</span>
                            <v-spacer></v-spacer>
                            <v-btn flat icon color="white" class="pa-0 ma-0" @click="$emit('dismiss')">
                                <v-icon>close</v-icon>
                            </v-btn>
                        </v-card-title>
                    </v-flex>
                    <v-flex xs12 class="scrollable">
                        <v-layout row fill-height class="scrollable">
<!--Fields-->
                            <v-flex xs3 fill-height class="scrollable pa-1">
                                <v-card class="fill-height">
                                    <v-layout column fill-height class="scrollable">
<!--Title-->
                                        <v-flex shrink class="primary px-2 py-1 white--text">
                                            Document Fields
                                        </v-flex>
<!--Fields-->
                                        <v-flex xs12 fill-height class="scrollable">
                                            <document-field-bar
                                                    :signatures="config.signatures"
                                                    :fields="structure.fields"
                                                    :steps-obj="flatSteps"
                                                    :flatWorkflowFields="flatWorkflowFields"
                                                    :flatDatasetSelectFields="flatDatasetSelectFields"
                                                    :flatDatasetFieldsByMemberID="flatDatasetFieldsByMemberID"
                                                    :flatSignatures="flatSignatures"
                                                    @usedSignaturesUpdate="usedSignatures = $event"
                                                    :user-info="userInfo"
                                                    @setSendSignatureMethod="sendSignatureMethod = $event"
                                                    :send-signature-method="sendSignatureMethod"
                                                    :mail-config="mailConfig"
                                                    @setSendMethod="setSendMethod($event)"
                                            />
                                        </v-flex>
                                    </v-layout>
                                </v-card>
                            </v-flex>

                            <v-flex xs9 fill-height class="scrollable pr-1 py-1">
                                <v-card class="fill-height">
                                    <v-layout column fill-height class="scrollable">
                                        <v-flex shrink class="primary px-2 py-1 white--text">
                                            Document Preview
                                        </v-flex>
                                        <v-flex xs12 fill-height class="scrollable">


                                            <document-preview
                                                    :structure="structure"
                                                    :user-info="userInfo"
                                                    :flatWorkflowFields="flatWorkflowFields"
                                                    :flatDatasetSelectFields="flatDatasetSelectFields"
                                                    :flatDatasetFieldsByMemberID="flatDatasetFieldsByMemberID"
                                                    :flatSignatures="flatSignatures"
                                                    :usedSignatures="usedSignatures"
                                                    :task="task"
                                                    :step="step"
                                                    :field="value"
                                                    @input="$emit('docAdded', $event)"
                                                    :send-signature-method="sendSignatureMethod"
                                                    :send-signature-address="sendSignatureAddress"
                                                    :send-signature-server="sendSignatureServer"
                                                    @setFlowTemplate="$emit('setFlowTemplate', $event)"
                                            ></document-preview>
                                        </v-flex>
                                    </v-layout>
                                </v-card>
                            </v-flex>
                        </v-layout>
                    </v-flex>
                </v-layout>
            </v-card>
        </v-dialog>
    </div>

</template>

<script>
    import {codeBaseMixin} from "../../../../../../../codeBaseMixin";
    import {objectifyArr, blankString, getUUID, getLongDate} from "../../../../../../../codeFunctions";
    import DocumentFieldBar from "./DocumentFieldBar";
    import DocumentPreview from "./DocumentPreview";
    import SignFlowPreviewPages
        from "@/Tasks/TaskView V2/components/TaskViewDetail/Components/FieldTypeComponents/Document Template/SignFlowPreviewPages";
    import InputDlg from "@/components/General/InputDlg";

    export default {
        components: {InputDlg, SignFlowPreviewPages, DocumentPreview, DocumentFieldBar},
        mixins: [codeBaseMixin],
        props: {
            value: Object,
            config: Object,
            showDialog: Boolean,
            userInfo: Object,
            fields: Array,
            steps: Array,
            fieldSets: Array,
            task: Object,
            step: Object,
            mailConfig: Object
        },
        data() {
            return {
                structure: {
                    fields: null,
                    parts: [],
                },
                usedSignatures: [],
                sendSignatureMethod: null,
                sendSignatureAddress: null,
                sendSignatureServer: null,
                showInfo: false
            }
        },
        watch: {
            showDialog: {
                immediate: true,
                handler(val) {
                    if (val) {
                        this.createTemplate()
                    }
                }
            }
        },
        methods: {



            setSendMethod(val) {
                this.sendSignatureMethod = val.type;
                this.sendSignatureAddress = val.address;
                this.sendSignatureServer = typeof val.server !== 'undefined' && val.server !== null ? val.server : 0
            },

            checkSectionRules(rules) {
                try {
                    return eval(this.generateRuleEval(rules))
                } catch (e) {
                    // eslint-disable-next-line
                    console.log(e)
                    return false
                }
            },

            generateRuleEval(rules) {
                return rules
                    .map((obj, i) => {

                        let tFunction = ``;

                        // Add And And Or If Neccesary
                        if (i > 0) {
                            if (typeof obj.function == 'string') {
                                tFunction = `${obj.function === 'AND' ? `&&` : `||`}`
                            } else {
                                tFunction = `&&`
                            }
                        }

                        if (typeof obj.function == 'string' && obj.children.length > 0) {
                            return `${tFunction}(${this.generateRuleEval(obj.children)})`
                        } else {
                            if (typeof obj.function == 'object') {
                                return `${tFunction}${this.getFieldFunction(obj.function)}`
                            } else {
                                return ''
                            }
                        }
                    })
                    .join('')
            },

            getFieldFunction(func) {
                let tFieldValue = this.getFieldValue(func.field)
                let tValue = null
                switch (func.field.valueType) {
                    case 2: {
                        tValue = `'${`${func.value}`.replace(/'/g,"\\'")}'`
                        break;
                    }
                    case 3: {
                        try {
                            tValue = new Date(func.value).getTime();
                        } catch (e) {
                            // eslint-disable-next-line
                            console.log(e)
                            return 'null'
                        }
                        break;
                    }
                    case 6: {
                        tValue = `'${`${func.value}`.replace(/'/g,"\\'")}'`
                        break;
                    }
                    default: {
                        tValue = func.value;
                        break;
                    }
                }

                // Operations
                // 0: 'Equal'
                // 1: 'Not Equal'
                // 2: 'Smaller'
                // 3: 'Greater'
                // 4: 'Not Blank'
                // 5: 'Is Blank'

                switch (func.operation) {
                    case 0: {
                        // 'Equal'
                        return `${tFieldValue}==${tValue}`
                    }
                    case 1: {
                        // 'Not Equal'
                        return `${tFieldValue}!=${tValue}`
                    }
                    case 2: {
                        // 'Smaller'
                        return `${tFieldValue}<${tValue}`
                    }
                    case 3: {
                        // 'Greater'
                        return `${tFieldValue}>${tValue}`
                    }
                    case 4: {
                        // 'Not Blank'
                        return `${tFieldValue}==null?false:(${tFieldValue}==""?false:true)`
                    }
                    case 5: {
                        // 'Is Blank'
                        return `${tFieldValue}==null?true:(${tFieldValue}==""?true:false)`
                    }
                    default: {
                        return 'false'
                    }
                }



            },

            getFieldValue(field) {
                // dsSelect: 297
                // fieldType: "df"
                // id: 6
                // valueType: 6
                //
                // fieldType: "wf"
                // id: 291
                // valueType: 2
                //
                // fieldType: "wf"
                // id: 291
                // valueType: 2

                let val = null;

                switch (field.fieldType) {
                    case "wf": {
                        val = this.flatWorkflowFields[field.id].value;
                        break
                    }
                    case "df": {
                        try {
                            val = `${this.flatDatasetFieldsByMemberID[this.flatDatasetSelectFields[field.dsSelect].value].fields[field.id].value}`
                        } catch (e) {
                            // eslint-disable-next-line
                            val = null
                        }
                        break;
                    }
                }

                if (!blankString(val)) {
                    // Value Types
                    // 1: Number
                    // 2: Text
                    // 3: Date
                    // 6: Select

                    switch (field.valueType) {
                        case 2: {
                            return `'${`${val}`.replace(/'/g,"\\'")}'`
                        }
                        case 3: {
                            try {
                                return new Date(val).getTime();
                            } catch (e) {
                                // eslint-disable-next-line
                                console.log(e)
                                return 'null'
                            }
                        }
                        case 6: {
                            return `'${`${val}`.replace(/'/g,"\\'")}'`
                        }
                        default: {
                            return val
                        }
                    }
                } else {
                    return 'null'
                }
            },

            createTemplate() {
                this.sendSignatureMethod = null;
                // Filter Out Sections Based On Rules
                this.structure.parts = this.config.parts.reduce((list, part) => {
                    let sections = part.sections.reduce((sectionList, section) => {
                        if (section.rules.length <= 0 || this.checkSectionRules(section.rules)) {
                            sectionList.push(section)
                        }
                        return sectionList
                    }, []);
                    if (sections.length > 0) {
                        list.push({
                            type: part.type,
                            sections: sections
                        })
                    }
                    return list
                }, []);

                let dDate = getLongDate(new Date())
                let taskCreatedDate = getLongDate(new Date(this.task.created))
                // Get A List of All Fields Listed In The Sections
                this.structure.fields = this.structure.parts
                    .reduce((fieldList, part) => {

                        part.sections.forEach(section => {
                            let sectionFields = this.extractFieldsFromHtml(section.html);
                            section.html = sectionFields.html;

                            section.html = section.html.replace(/{{date}}/gi, dDate)
                            section.html = section.html.replace(/{{created}}/gi, taskCreatedDate)
                            section.html = section.html.replace(/{{title}}/gi, this.task.title)
                            section.html = section.html.replace(/{{user}}/gi, `${this.task.ownerName} ${this.task.ownerSurname}`)
                            section.html = section.html.replace(/{{taskId}}/gi, this.task.taskID)
                            section.html = section.html.replace(/{{folder}}/gi, this.task.folderName)
                            section.html = section.html.replace(/{{subUser}}/gi, `${this.task.subOwnerName} ${this.task.subOwnerSurname}`)

                            fieldList.workflowFields = fieldList.workflowFields.concat(sectionFields.workflowFields);
                            fieldList.datasetFields = fieldList.datasetFields.concat(sectionFields.datasetFields);
                            fieldList.inputFields = fieldList.inputFields.concat(sectionFields.inputFields);
                            fieldList.signatureInputFields = fieldList.signatureInputFields.concat(sectionFields.signatureInputFields);
                            fieldList.signatures = fieldList.signatures
                                    .concat(sectionFields.signatures)
                        });

                        return fieldList
                    }, {
                        workflowFields: [],
                        datasetFields: [],
                        inputFields: [],
                        signatureInputFields: [],
                        signatures: []
                    });
            },

            extractFieldsFromHtml(html) {
                const re = /(<span[^>]+id="F-.+?<\/span>)|(<div[^>]+id="F-.+?<\/div>)/g;
                let s = html.replace(/\n/g,"").replace(/\r/g,"");

                let arrfields = s.match(re);

                if (Array.isArray(arrfields)) {
                    arrfields = arrfields.map(fieldStr => {
                        let uuid = getUUID();
                        s = s.replace(fieldStr, `{{${uuid}}}`);

                        let fieldRe = /id="F-.+?(?=")/g
                        let arrIDStr = fieldStr.match(fieldRe);
                        let fieldType = arrIDStr[0].replace(/id="F-/g, '').split('-V-').shift();
                        let fieldID = arrIDStr[0].replace(/id="F-/g, '').split('-V-').pop();

                        switch (fieldType) {
                            case 'W': {
                                fieldID = parseInt(fieldID)
                                break;
                            }
                            case 'D': {
                                // WorkflowField:DsID:dsFieldID
                                fieldID = fieldID.split(':');
                                fieldID = {
                                    dsSelectID: fieldID[0],
                                    dsID: fieldID[1],
                                    dsFieldID: fieldID[2],
                                };
                                break;
                            }
                            case 'N': {
                                let tSplit = fieldID.split('-');
                                fieldID = {
                                    description: tSplit.pop(),
                                    sigID: tSplit.join('-')
                                };
                                break;
                            }
                        }

                        return {
                            fieldID: fieldID,
                            fieldType: fieldType,
                            fieldStr: fieldStr,
                            uuid: uuid
                        }
                    });

                    return arrfields.reduce((fieldObj, obj) => {
                        switch (obj.fieldType) {
                            case 'W': {
                                if (typeof this.flatWorkflowFields[obj.fieldID] != 'undefined') {
                                    obj.field = this.flatWorkflowFields[obj.fieldID];
                                    fieldObj.workflowFields.push(obj);
                                }
                                break;
                            }
                            case 'D': {
                                try {
                                    obj.dsSelectField = this.flatDatasetSelectFields[obj.fieldID.dsSelectID];
                                } catch (e) {
                                    // eslint-disable-next-line
                                    obj.dsSelectField = null
                                }

                                try {
                                    obj.field = this.flatDatasetFieldsByMemberID[this.flatDatasetSelectFields[obj.fieldID.dsSelectID].value].fields[obj.fieldID.dsFieldID];
                                } catch (e) {
                                    // eslint-disable-next-line
                                    obj.field = null
                                }
                                fieldObj.datasetFields.push(obj)
                                break;
                            }
                            case 'I': {
                                obj.field = {
                                    description: obj.fieldID,
                                    value: null
                                };
                                fieldObj.inputFields.push(obj);
                                break;
                            }
                            case 'N': {
                                obj.field = {
                                    signature: obj.fieldID.sigID,
                                    description: obj.fieldID.description,
                                    signatureDescription: this.flatSignatures[obj.fieldID.sigID].description,
                                    value: null
                                };
                                fieldObj.signatureInputFields.push(obj);
                                break;
                            }
                            case 'S': {
                                obj.field = {
                                    signature: obj.fieldID,
                                    description: this.flatSignatures[obj.fieldID].description,
                                };

                                // description: obj.fieldID.description,
                                fieldObj.signatures.push(obj);
                                break;
                            }
                            default: {
                                obj.field = null;
                            }
                        }

                        return fieldObj;
                    }, {
                        html: s,
                        workflowFields: [],
                        datasetFields: [],
                        inputFields: [],
                        signatureInputFields: [],
                        signatures: [],
                    }) ;
                } else {
                    return {
                        html: html,
                        workflowFields: [],
                        datasetFields: [],
                        inputFields: [],
                        signatureInputFields: [],
                        signatures: [],
                    }
                }

            },
        },
        computed: {
            combinedHTML() {
                return this.structure.parts.map(part => {
                    return part.sections.map(section => {return section.html}).join('<br>')
                }).join('<br>')

            },

            showGenerateBtn() {
                return this.value.value === null
            },

            flatWorkflowFields() {
                return objectifyArr(this.fields, `fieldID`)
            },

            flatDatasetSelectFields() {
                return objectifyArr(this.fields.filter(obj => obj.fieldType === 9), `fieldID`)
            },

            flatDatasetFieldsByMemberID() {
                return objectifyArr(this.fieldSets.map(obj => {
                    let oObject = {}
                    Object.assign(oObject, obj)

                    try {
                        oObject.fields = JSON.parse(oObject.fields)
                    } catch (e) {
                        // eslint-disable-next-line
                        console.log(e)
                        oObject.fields = []
                    }
                    oObject.fields = objectifyArr(oObject.fields, `fieldID`);

                    return oObject
                }), `memberID`)
            },

            flatSignatures() {
                return objectifyArr(this.config.signatures, `id`)
            },

            flatSteps() {
                return objectifyArr(this.steps, `stepID`)
            }

        },
    }
</script>

<style scoped>

</style>
