<template>
    <v-layout row>
        <v-flex class="pl-2">
            <start-date-picker
                    v-model="value.value"
                    :disabled="readOnly"
                    :date-only="!!value.lookupType"
                    :model-string="true"
                    @input="$emit('forceSave')"
            >
<!--                <v-text-field-->
<!--                        slot="activator"-->
<!--                        append-outer-icon="calendar_today"-->
<!--                        label="Select A Date"-->
<!--                        clearable-->
<!--                        color="primary"-->
<!--                        flat-->
<!--                        -->
<!--                        hide-details-->
<!--                        class="pa-0 ma-0"-->
<!--                        single-line-->
<!--                        :disabled="!intToBool(value.editable) || !stepEditable"-->
<!--                        readonly-->
<!--                        @click:clear="value.value = null"-->
<!--                >-->
<!--                </v-text-field>-->

                <v-text-field
                        slot="activator"
                        append-outer-icon="calendar_today"
                        label="Select A Date"
                        color="primary"
                        flat
                        :value="value.value == null ? '' : (!!value.lookupType ? getLongDateOnly(value.value) : getLongDate(value.value))"
                        hide-details
                        class="pa-0 ma-0"
                        single-line
                        readonly
                >
                </v-text-field>

            </start-date-picker>


<!--            <v-menu style="width: 100%" v-model="menu" :close-on-content-click="false" :disabled="readOnly">-->


<!--                <v-card class="text-xs-right">-->
<!--&lt;!&ndash;Date&ndash;&gt;-->
<!--                    <v-date-picker-->
<!--                            v-if="selectDate"-->
<!--                            id="calendarDiv"-->
<!--                            v-model="date"-->
<!--                            show-current-->
<!--                            color="primary"-->
<!--                            @input="selectDate = false"-->
<!--                            full-width-->
<!--                    ></v-date-picker>-->
<!--&lt;!&ndash;TIme&ndash;&gt;-->
<!--                    <v-time-picker-->
<!--                            v-if="!selectDate"-->
<!--                            v-model="time"-->
<!--                            type="month"-->
<!--                            color="primary"-->
<!--                            full-width-->
<!--                    ></v-time-picker>-->
<!--&lt;!&ndash;Done Button&ndash;&gt;-->
<!--                    <v-btn-->
<!--                            v-if="!selectDate"-->
<!--                            color="primary"-->
<!--                            flat-->
<!--                            @click="menu = false"-->
<!--                    >-->
<!--                        Done-->
<!--                    </v-btn>-->
<!--                </v-card>-->
<!--            </v-menu>-->
        </v-flex>
    </v-layout>
</template>

<script>
    import {codeBaseMixin} from "../../../codeBaseMixin";
    import StartDatePicker from "@/components/General/Calendar/StartDatePicker/StartDatePicker";

    export default {
        components: {StartDatePicker},
        mixins: [ codeBaseMixin ],
        props: {
            value: Object,
            readOnly: Boolean
        },
        data() {
            return {
                menu: false,
                selectDate: true,
            }
        },
        watch: {
            menu() {
                this.selectDate = true;
            }
        },
        computed: {
            date: {
                // getter
                get: function () {

                    if (this.value.value == null) {
                        let sString = this.getSqlDate(new Date()) + ' ' + this.getFormattedTime(new Date()) + ':00' ;
                        return sString
                    } else {

                        return this.value.value
                    }
                },
                // setter
                set: function (newValue) {
                    this.value.value = newValue
                }
            },

            time: {
                // getter
                get: function () {

                    if (this.value.value == null) {
                        return '00:00'
                    } else {
                        return this.getFormattedTime(this.value.value);
                    }
                },
                // setter
                set: function (newValue) {
                    this.value.value = this.date + ' ' + newValue + ':00';
                }
            },


        }
    }
</script>

<style scoped>

</style>
