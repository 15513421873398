import { render, staticRenderFns } from "./FieldType22.vue?vue&type=template&id=95e1e3ba&scoped=true"
import script from "./FieldType22.vue?vue&type=script&lang=js"
export * from "./FieldType22.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "95e1e3ba",
  null
  
)

export default component.exports