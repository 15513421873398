<template>
    <div>
        <template>
            <div v-show="lookup == null" @click="configuringField = true">
                <v-btn small flat color="secondary" class="ma-0">Configure Mail Template</v-btn>
            </div>
            <v-card ripple hover flat v-show="lookup != null" class="primary white--text"
                    @click="configuringField = true">
                <v-layout row align-center style="cursor: pointer" v-if="lookup != null">
                    <v-flex xs12 class="pa-1 pl-2 text-truncate" style="overflow-x: hidden;">
                        Mail Template Configuration
                    </v-flex>
                    <v-flex shrink class="pr-1">
                        <v-tooltip top>
                            <v-btn
                                    small
                                    flat
                                    icon
                                    color="white"
                                    class="pa-0 ma-0"
                                    slot="activator"
                            >
                                <v-icon small>edit</v-icon>
                            </v-btn>
                            <span>Edit Mail Template</span>
                        </v-tooltip>
                    </v-flex>
                </v-layout>
            </v-card>
        </template>
        <app-input-dlg
                :show-dialog="configuringField"
                title="Configure Mail"
                :cancel-button="true"
                :show-input-box="false"
                @dismiss="configuringField = false"
                :body-padding="false"
                :width="500"
        >
            <template slot="body">
                <div class="pa-1">

<!--Allow Edit-->
<!--                    <div class="pa-1">-->
<!--                        <div class="caption grey&#45;&#45;text font-weight-bold">-->
<!--                            Allow Edit:-->
<!--                        </div>-->
<!--                        <div class="pl-2">-->
<!--                            <v-switch-->
<!--                                v-model="allowEdit"-->
<!--                                label="Mail Template Editable"-->
<!--                                hide-details-->
<!--                                color="secondary"-->
<!--                                class="pa-0 ma-0"-->
<!--                            ></v-switch>-->
<!--                        </div>-->
<!--                    </div>-->

                    <!--Subject-->
                    <div class="pa-1">
                        <div class="caption greyType--text font-weight-bold">
                            Fixed Subject:
                        </div>
                        <div class="pl-2">
                            <div v-if="localSubject == null && !(lookup && lookup.subject)">
                                <v-btn small flat color="secondary" class="ma-0" @click="showSetSubject = true">
                                    Set Subject
                                </v-btn>
                            </div>
                            <v-card ripple hover flat v-else class="primary white--text">
                                <v-layout row align-center style="cursor: pointer" v-if="localSubject != null">

                                    <v-flex xs12 class="pa-1 pl-2 text-truncate" style="overflow-x: hidden;" @click="showSetSubject = true">
                                        {{lookup.subject}}
                                    </v-flex>
                                    <v-flex shrink class="pr-1">
                                        <v-tooltip top>
                                            <v-btn
                                                    small
                                                    flat
                                                    icon
                                                    color="white"
                                                    class="pa-0 ma-0"
                                                    slot="activator"
                                                    @click="localSubject = null"
                                            >
                                                <v-icon small>close</v-icon>
                                            </v-btn>
                                            <span>Remove Fixed Subject</span>
                                        </v-tooltip>
                                    </v-flex>
                                    <v-flex shrink class="pr-1" @click="showSetSubject = true">
                                        <v-tooltip top>
                                            <v-btn
                                                    small
                                                    flat
                                                    icon
                                                    color="white"
                                                    class="pa-0 ma-0"
                                                    slot="activator"
                                            >
                                                <v-icon small>edit</v-icon>
                                            </v-btn>
                                            <span>Change Subject</span>
                                        </v-tooltip>
                                    </v-flex>
                                </v-layout>
                            </v-card>
                            <app-input-dlg
                                    :show-dialog="showSetSubject"
                                    title="Set Subject"
                                    body="Please provide a Fixed Subject:"
                                    :cancel-button="true"
                                    :show-input-box="true"
                                    :buttons="['Set Subject']"
                                    :default-input="lookup.subject"
                                    :call-backs="[ setNewSubject ]"
                                    @dismiss="showSetSubject = false"
                            />
                        </div>
                    </div>
                    <!--Forced Signature-->
                    <div class="pa-1">
                        <div class="caption greyType--text font-weight-bold">
                            Force Signature:
                        </div>
                        <div class="pl-2">
                            <v-autocomplete
                                    dense
                                    v-model="localSignature"
                                    :items="signatures"
                                    label="User Defined"
                                    hide-details
                                    single-line
                                    color="primary"
                                    item-text="description"
                                    item-value="id"
                                    class="pa-0 ma-0"
                                    clearable
                            ></v-autocomplete>
                        </div>
                    </div>
                    <!--Default To Address-->
                    <div class="pa-1">
                        <div class="caption greyType--text font-weight-bold">
                            Default to Address Type:
                        </div>
                        <div class="pl-2">
                            <v-autocomplete
                                    dense
                                    v-model="localDefaultToAddressLookup"
                                    :items="defaultToAddressLookupValues"
                                    label="Default to Address"
                                    hide-details
                                    single-line
                                    color="primary"
                                    item-text="description"
                                    item-value="id"
                                    class="pa-0 ma-0"
                            ></v-autocomplete>
                        </div>
                    </div>
                    <!--Workflow Field-->
                    <div class="pa-1" v-if="localDefaultToAddressLookup == 3 || localDefaultToAddressLookup == 6">
                        <div class="caption greyType--text font-weight-bold">
                            Select Workflow Field:
                        </div>
                        <div class="pl-2">
                            <v-autocomplete
                                    dense
                                    v-model="localDefaultToAddress"
                                    :items="fieldType1s"
                                    label="Default to Address"
                                    hide-details
                                    single-line
                                    color="primary"
                                    item-text="name"
                                    item-value="id"
                                    class="pa-0 ma-0"
                            >
                                <template v-slot:item="data">
                                    <template v-if="typeof data.item !== 'object'">
                                        <v-list-tile-content v-text="data.item" class="font-weight-bold"></v-list-tile-content>
                                    </template>
                                    <template v-else>

                                        <v-layout row align-center>
                                            <v-flex shrink>
                                                <v-icon
                                                        cursor="pointer"
                                                        color="primary"
                                                        small
                                                        left
                                                >
                                                    {{data.item.id == localDefaultToAddress ? 'check_box' : 'check_box_outline_blank'}}
                                                </v-icon>
                                            </v-flex>
                                            <v-flex xs12>{{data.item.name}}</v-flex>
                                        </v-layout>
                                    </template>
                                </template>
                            </v-autocomplete>
                        </div>
                    </div>
                    <!--Dataset Field-->
                    <div class="pa-1" v-if="localDefaultToAddressLookup == 2 || localDefaultToAddressLookup == 5">
                        <div class="caption greyType--text font-weight-bold">
                            Select Dataset Field:
                        </div>
                        <div class="pl-2">
                            <app-field-set-field-selector
                                    v-model="localDefaultToAddress"
                                    :fields="fields"
                                    :field-sets="fieldSets"
                                    :field-type-filter="[1]"
                                    :include-auto-populate="false"
                            />
                        </div>
                    </div>
                    <!--Specific Address-->
                    <div class="pa-1" v-if="localDefaultToAddressLookup == 1 || localDefaultToAddressLookup == 4">
                        <div class="caption greyType--text font-weight-bold">
                            Set Email Address:
                        </div>
                        <div class="pl-2">
                            <div v-if="displayGroupsTruncated == null">
                                <v-btn small flat color="secondary" class="ma-0" @click="showSetSpecificAddress = true">
                                    Set Email Addresses
                                </v-btn>
                            </div>
                            <v-card ripple hover flat v-else class="primary white--text" @click="showSetSpecificAddress = true">
                                <v-layout row align-center style="cursor: pointer" v-if="displayGroupsTruncated != null">
                                    <v-flex xs12 class="pa-1 pl-2 text-truncate" style="overflow-x: hidden;">
                                        {{displayGroupsTruncated.displayItems.join(', ')}}
                                    </v-flex>
                                    <v-flex shrink>
                                        <v-tooltip top v-if="displayGroupsTruncated.tooltipItems.length > 0">
                                            <v-btn
                                                    small
                                                    flat
                                                    icon
                                                    color="white"
                                                    class="pa-0 ma-0"
                                                    slot="activator"
                                            >
                                                <div class="caption">
                                                    {{`+${displayGroupsTruncated.tooltipItems.length}`}}
                                                </div>
                                            </v-btn>
                                            <span>{{displayGroupsTruncated.tooltipItems.join(', ')}}</span>
                                        </v-tooltip>
                                    </v-flex>
                                    <v-flex shrink class="pr-1">
                                        <v-tooltip top>
                                            <v-btn
                                                    small
                                                    flat
                                                    icon
                                                    color="white"
                                                    class="pa-0 ma-0"
                                                    slot="activator"
                                            >
                                                <v-icon small>edit</v-icon>
                                            </v-btn>
                                            <span>Edit Addresses</span>
                                        </v-tooltip>
                                    </v-flex>
                                </v-layout>
                            </v-card>
                            <app-input-dlg
                                    :show-dialog="showSetSpecificAddress"
                                    title="Set Email Addresses"
                                    body="Please provide valid email addresses separated by a semicolon or comma."
                                    :cancel-button="true"
                                    :show-input-box="true"
                                    :buttons="['Done']"
                                    :default-input="localDefaultToAddress"
                                    :call-backs="[ setNewEmail ]"
                                    @dismiss="showSetSpecificAddress = false"
                            />

                        </div>
                    </div>
                    <!--Configure Body-->
                    <div class="pa-1">
                        <div class="caption greyType--text font-weight-bold">
                            Configure Mail:
                        </div>
                        <div class="pl-2">
                            <v-btn
                                    small
                                    flat
                                    color="secondary"
                                    class="ma-0"
                                    @click="configureTemplate"
                            >
                                Configure Mail
                            </v-btn>
                        </div>
                    </div>
                    <v-divider></v-divider>
                    <div class="text-xs-right">
                        <v-btn
                                small
                                color="secondary"
                                :disabled="saveObject == null"
                                @click="doneRun"
                        >
                            Save
                        </v-btn>
                    </div>
                </div>
            </template>
        </app-input-dlg>
    </div>
</template>

<script>
    import InputDlg from "../../../../../../../components/General/InputDlg";
    import {codeBaseMixin} from "../../../../../../../codeBaseMixin";
    import FieldSetFieldSelector from "../FieldSetFieldSelector";
    import {boolToInt} from "@/codeFunctions";
    // import WorkflowSelect from './FieldType10Components/WorkflowSelect';
    // import FieldsSelect from "./FieldType10Components/FieldsSelect";
    // import FolderDlg from '../../../../../../../Folders/FolderDlg';
    // import WorkflowManagerGroupSelector from '../WorkflowManagerGroupSelector'

    export default {
        mixins: [codeBaseMixin],
        components: {
            appFieldSetFieldSelector: FieldSetFieldSelector,
            // appWorkflowManagerGroupSelector: WorkflowManagerGroupSelector,
            // appFolderDlg: FolderDlg,
            // appFieldsSelect: FieldsSelect,
            appInputDlg: InputDlg,
            // appWorkflowSelect: WorkflowSelect
        },
        props: {
            userInfo: Object,
            value: String,
            fields: Array,
            field: Object,
            steps: Array,
            fieldSets: Array,
            signatures: Array,
            showTemplateEditorFn: Function,
            localData: Object,
            showSync: Boolean
        },
        data() {
            return {
                configuringField: false,
                lookup: {
                    defaultToAddressLookup: 0,
                    defaultToAddress: null,
                    body: null,
                    subject: null,
                },
                showSetSubject: false,
                defaultToAddressLookupValues: [
                    {id: 0, description: 'User Input'},
                    {id: 1, description: 'Specific Address'},
                    {id: 4, description: 'Specific Address - Remove Enabled'},
                    {id: 2, description: 'Dataset Field'},
                    {id: 5, description: 'Dataset Field - Remove Enabled'},
                    {id: 3, description: 'Workflow Field'},
                    {id: 6, description: 'Workflow Field - Remove Enabled'},
                ],
                showSetSpecificAddress: false
            }
        },
        watch: {
            value: {
                immediate: true,
                handler(val) {
                    this.lookup = this.isJson(val != null ? val.replace(/\\"/g,'"') : null, (val) => {
                        return val != null ? val : {
                            defaultToAddressLookup: 0,
                            defaultToAddress: null,
                            body: null,
                            subject: null,
                        }
                    }, {
                        defaultToAddressLookup: 0,
                        defaultToAddress: null,
                        body: null,
                        subject: null,
                    });
                }
            },

        },
        methods: {
            configureTemplate() {
                this.showTemplateEditorFn({
                    allFields: this.fields,
                    allSteps: this.steps,
                    fieldLookup: this.lookup,
                    fieldSets: this.fieldSets,
                })
                    .catch((e) => {
                        console.log(e)
                    })
            },
            setNewEmail(value) {
                if (
                    this.isString(value, (addressStr) => {
                            this.localDefaultToAddress = addressStr;
                            this.showSetSpecificAddress = false;
                            return addressStr;
                        },
                        null,
                        this.validateEmail(value.split(/[;,]+/))) == null
                ) {
                    this.showSnack('Error', 'Invalid Email Address\\Addresses Specified', 'Close', 'red')
                }
            },
            setNewSubject(value) {
                if (this.isString(value, (subject) => {
                    this.localSubject = subject;
                    this.showSetSubject = false;
                    return subject
                }) == null) {
                    this.showSnack('Error', 'Subject can not be blank', 'Close', 'red')
                }
            },
            doneRun() {
                this.$emit('input', JSON.stringify(this.saveObject))
                this.configuringField = false;
            }
        },
        computed: {

            allowEdit: {
                get() {
                    try {return this.boolToInt(this.lookup.allowEdit)} catch (e) {return false}
                },
                set(newVal) {
                    if (this.lookup == null) {this.lookup = {}}
                    this.$set(this.lookup, `allowEdit`, this.boolToInt(newVal));
                }
            },

            localSubject: {
                get() {
                    try {
                        return this.lookup && this.lookup.subject ? this.lookup.subject : '';
                    } catch (e) {
                        return null;
                    }
                },
                set(newVal) {
                    if (!this.lookup) {
                        this.lookup = {}
                    }

                    if (newVal != null) {
                        this.lookup.subject = newVal;
                    } else {
                        delete this.lookup.subject
                    }
                }
            },
            localSignature: {
                get() {
                    try {return this.lookup.forcedSignature} catch (e) {return null}
                },
                set(newVal) {
                    if (this.lookup == null) {this.lookup = {}}
                    if (newVal != null) {
                        this.lookup.forcedSignature = newVal;
                    } else {
                        if (typeof this.lookup.forcedSignature != 'undefined') {
                            this.lookup.forcedSignature = null;
                            this.$nextTick(() => {
                                delete this.lookup.forcedSignature
                            })
                        }
                    }
                }
            },
            localDefaultToAddressLookup: {
                get() {
                    try {return this.lookup.defaultToAddressLookup} catch (e) {return 0}
                },
                set(newVal) {
                    if (this.lookup == null) {this.lookup = {}}
                    if (newVal != null) {
                        this.lookup.defaultToAddressLookup = newVal;
                    } else {
                        this.lookup.defaultToAddressLookup = 0;
                    }
                }
            },
            localDefaultToAddress: {
                get() {
                    try {
                        switch (this.localDefaultToAddressLookup) {
                            case 3: {
                                return !isNaN(this.lookup.defaultToAddress) ? this.lookup.defaultToAddress : null
                            }
                            case 6: {
                                return !isNaN(this.lookup.defaultToAddress) ? this.lookup.defaultToAddress : null
                            }
                            case 2: {
                                return typeof this.lookup.defaultToAddress == "object" ? this.lookup.defaultToAddress : null
                            }
                            case 5: {
                                return typeof this.lookup.defaultToAddress == "object" ? this.lookup.defaultToAddress : null
                            }
                            case 1: {
                                return typeof this.lookup.defaultToAddress == "string" ? this.lookup.defaultToAddress : null
                            }
                            case 4: {
                                return typeof this.lookup.defaultToAddress == "string" ? this.lookup.defaultToAddress : null
                            }
                            default: {return null}
                        }
                    } catch (e) {return null}
                },
                set(newVal) {

                    if (this.lookup == null) {this.lookup = {}}
                    this.lookup.defaultToAddress = newVal;
                }
            },
            fieldType1s() {
                let fields = this.fields.filter(obj => obj.fieldType == 1).sort((a,b) => a['field_order'] < b['field_order'] ? -1 : 1);

                let fieldSteps = fields.map(obj => {return obj.fk_step})

                let steps = this.steps.filter(obj => fieldSteps.includes(obj.id)).sort((a,b) => a['field_order'] < b['field_order'] ? -1 : 1);
                return steps.reduce((returnList, step) => {
                    returnList.push({
                        header: step.description
                    });
                    returnList.push({ divider: true })
                    returnList = returnList.concat(fields.filter(obj => obj.fk_step == step.id).map(obj => {return {
                        name: obj.description,
                        id: obj.id
                    }}));
                    returnList.push({ divider: true })
                    return returnList
                }, []);
            },
            displayGroupsTruncated() {
                if (this.localDefaultToAddress == null) {
                    return null;
                } else {
                    let addresses = this.localDefaultToAddress.split(/[;,]+/);

                    return addresses.reduce((returnObject, address, index, addresses) => {
                        if (index <= 2) {
                            returnObject.displayItems.push(address)
                        } else {
                            returnObject.tooltipItems.push(address)
                        }

                        if (index + 1 == addresses.length && (returnObject.displayItems.length + returnObject.tooltipItems.length) <= 0) {
                            returnObject = null;
                        }
                        return returnObject;
                    }, {
                        displayItems: [],
                        tooltipItems: []
                    })
                }
            },
            saveObject() {
                if (this.lookup == null) {return null}
                if (typeof this.lookup.defaultToAddressLookup == 'undefined' || this.lookup.defaultToAddressLookup == null) {return null}

                switch (this.lookup.defaultToAddressLookup) {
                    case 0: {return this.lookup}
                    case 1: {return typeof this.lookup.defaultToAddress == 'string' && this.lookup.defaultToAddress != '' ? this.lookup : null}
                    case 4: {return typeof this.lookup.defaultToAddress == 'string' && this.lookup.defaultToAddress != '' ? this.lookup : null}
                    case 2: {return typeof this.lookup.defaultToAddress == 'object' ? this.lookup : null}
                    case 5: {return typeof this.lookup.defaultToAddress == 'object' ? this.lookup : null}
                    case 3: {return this.lookup}
                    case 6: {return this.lookup}
                    default: {return null}
                }
            }
        }
    }
</script>

<style scoped>

</style>
