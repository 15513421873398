export default [
  {
    title: "People",
    emojis: [
      {key: 'smile', val: '😄'},
      {key: 'smiley', val: '😃'},
      {key: 'grinning', val: '😀'},
      {key: 'blush', val: '😊'},
      {key: 'wink', val: '😉'},
      {key: 'heart_eyes', val: '😍'},
      {key: 'kissing_heart', val: '😘'},
      {key: 'kissing_closed_eyes', val: '😚'},
      {key: 'kissing', val: '😗'},
      {key: 'kissing_smiling_eyes', val: '😙'},
      {key: 'stuck_out_tongue_winking_eye', val: '😜'},
      {key: 'stuck_out_tongue_closed_eyes', val: '😝'},
      {key: 'stuck_out_tongue', val: '😛'},
      {key: 'flushed', val: '😳'},
      {key: 'grin', val: '😁'},
      {key: 'pensive', val: '😔'},
      {key: 'relieved', val: '😌'},
      {key: 'unamused', val: '😒'},
      {key: 'disappointed', val: '😞'},
      {key: 'persevere', val: '😣'},
      {key: 'cry', val: '😢'},
      {key: 'joy', val: '😂'},
      {key: 'sob', val: '😭'},
      {key: 'sleepy', val: '😪'},
      {key: 'disappointed_relieved', val: '😥'},
      {key: 'cold_sweat', val: '😰'},
      {key: 'sweat_smile', val: '😅'},
      {key: 'sweat', val: '😓'},
      {key: 'weary', val: '😩'},
      {key: 'tired_face', val: '😫'},
      {key: 'fearful', val: '😨'},
      {key: 'scream', val: '😱'},
      {key: 'angry', val: '😠'},
      {key: 'rage', val: '😡'},
      {key: 'triumph', val: '😤'},
      {key: 'confounded', val: '😖'},
      {key: 'laughing', val: '😆'},
      {key: 'yum', val: '😋'},
      {key: 'mask', val: '😷'},
      {key: 'sunglasses', val: '😎'},
      {key: 'sleeping', val: '😴'},
      {key: 'dizzy_face', val: '😵'},
      {key: 'astonished', val: '😲'},
      {key: 'worried', val: '😟'},
      {key: 'frowning', val: '😦'},
      {key: 'anguished', val: '😧'},
      {key: 'imp', val: '👿'},
      {key: 'open_mouth', val: '😮'},
      {key: 'grimacing', val: '😬'},
      {key: 'neutral_face', val: '😐'},
      {key: 'confused', val: '😕'},
      {key: 'hushed', val: '😯'},
      {key: 'smirk', val: '😏'},
      {key: 'expressionless', val: '😑'},
      {key: 'man_with_gua_pi_mao', val: '👲'},
      {key: 'man_with_turban', val: '👳'},
      {key: 'cop', val: '👮'},
      {key: 'construction_worker', val: '👷'},
      {key: 'guardsman', val: '💂'},
      {key: 'baby', val: '👶'},
      {key: 'boy', val: '👦'},
      {key: 'girl', val: '👧'},
      {key: 'man', val: '👨'},
      {key: 'woman', val: '👩'},
      {key: 'older_man', val: '👴'},
      {key: 'older_woman', val: '👵'},
      {key: 'person_with_blond_hair', val: '👱'},
      {key: 'angel', val: '👼'},
      {key: 'princess', val: '👸'},
      {key: 'smiley_cat', val: '😺'},
      {key: 'smile_cat', val: '😸'},
      {key: 'heart_eyes_cat', val: '😻'},
      {key: 'kissing_cat', val: '😽'},
      {key: 'smirk_cat', val: '😼'},
      {key: 'scream_cat', val: '🙀'},
      {key: 'crying_cat_face', val: '😿'},
      {key: 'joy_cat', val: '😹'},
      {key: 'pouting_cat', val: '😾'},
      {key: 'japanese_ogre', val: '👹'},
      {key: 'japanese_goblin', val: '👺'},
      {key: 'see_no_evil', val: '🙈'},
      {key: 'hear_no_evil', val: '🙉'},
      {key: 'speak_no_evil', val: '🙊'},
      {key: 'skull', val: '💀'},
      {key: 'alien', val: '👽'},
      {key: 'hankey', val: '💩'},
      {key: 'fire', val: '🔥'},
      {key: 'sparkles', val: '✨'},
      {key: 'star2', val: '🌟'},
      {key: 'dizzy', val: '💫'},
      {key: 'boom', val: '💥'},
      {key: 'anger', val: '💢'},
      {key: 'sweat_drops', val: '💦'},
      {key: 'droplet', val: '💧'},
      {key: 'zzz', val: '💤'},
      {key: 'dash', val: '💨'},
      {key: 'ear', val: '👂'},
      {key: 'eyes', val: '👀'},
      {key: 'nose', val: '👃'},
      {key: 'tongue', val: '👅'},
      {key: 'lips', val: '👄'},
      {key: 'thumbs_up', val: '👍'},
      {key: '-1', val: '👎'},
      {key: 'ok_hand', val: '👌'},
      {key: 'facepunch', val: '👊'},
      {key: 'fist', val: '✊'},
      {key: 'wave', val: '👋'},
      {key: 'hand', val: '✋'},
      {key: 'open_hands', val: '👐'},
      {key: 'point_up_2', val: '👆'},
      {key: 'point_down', val: '👇'},
      {key: 'point_right', val: '👉'},
      {key: 'point_left', val: '👈'},
      {key: 'raised_hands', val: '🙌'},
      {key: 'pray', val: '🙏'},
      {key: 'clap', val: '👏'},
      {key: 'muscle', val: '💪'},
      {key: 'walking', val: '🚶'},
      {key: 'runner', val: '🏃'},
      {key: 'dancer', val: '💃'},
      {key: 'couple', val: '👫'},
      {key: 'family', val: '👪'},
      {key: 'couplekiss', val: '💏'},
      {key: 'couple_with_heart', val: '💑'},
      {key: 'dancers', val: '👯'},
      {key: 'ok_woman', val: '🙆'},
      {key: 'no_good', val: '🙅'},
      {key: 'information_desk_person', val: '💁'},
      {key: 'raising_hand', val: '🙋'},
      {key: 'massage', val: '💆'},
      {key: 'haircut', val: '💇'},
      {key: 'nail_care', val: '💅'},
      {key: 'bride_with_veil', val: '👰'},
      {key: 'person_with_pouting_face', val: '🙎'},
      {key: 'person_frowning', val: '🙍'},
      {key: 'bow', val: '🙇'},
      {key: 'tophat', val: '🎩'},
      {key: 'crown', val: '👑'},
      {key: 'womans_hat', val: '👒'},
      {key: 'athletic_shoe', val: '👟'},
      {key: 'mans_shoe', val: '👞'},
      {key: 'sandal', val: '👡'},
      {key: 'high_heel', val: '👠'},
      {key: 'boot', val: '👢'},
      {key: 'shirt', val: '👕'},
      {key: 'necktie', val: '👔'},
      {key: 'womans_clothes', val: '👚'},
      {key: 'dress', val: '👗'},
      {key: 'running_shirt_with_sash', val: '🎽'},
      {key: 'jeans', val: '👖'},
      {key: 'kimono', val: '👘'},
      {key: 'bikini', val: '👙'},
      {key: 'briefcase', val: '💼'},
      {key: 'handbag', val: '👜'},
      {key: 'pouch', val: '👝'},
      {key: 'purse', val: '👛'},
      {key: 'eyeglasses', val: '👓'},
      {key: 'ribbon', val: '🎀'},
      {key: 'closed_umbrella', val: '🌂'},
      {key: 'lipstick', val: '💄'},
      {key: 'yellow_heart', val: '💛'},
      {key: 'blue_heart', val: '💙'},
      {key: 'purple_heart', val: '💜'},
      {key: 'green_heart', val: '💚'},
      {key: 'broken_heart', val: '💔'},
      {key: 'heartpulse', val: '💗'},
      {key: 'heartbeat', val: '💓'},
      {key: 'two_hearts', val: '💕'},
      {key: 'sparkling_heart', val: '💖'},
      {key: 'revolving_hearts', val: '💞'},
      {key: 'cupid', val: '💘'},
      {key: 'love_letter', val: '💌'},
      {key: 'kiss', val: '💋'},
      {key: 'ring', val: '💍'},
      {key: 'gem', val: '💎'},
      {key: 'bust_in_silhouette', val: '👤'},
      {key: 'speech_balloon', val: '💬'},
      {key: 'footprints', val: '👣'},
    ]
  },
  {
    title: "Nature",
    emojis: [
      {key: 'dog', val: '🐶'},
      {key: 'wolf', val: '🐺'},
      {key: 'cat', val: '🐱'},
      {key: 'mouse', val: '🐭'},
      {key: 'hamster', val: '🐹'},
      {key: 'rabbit', val: '🐰'},
      {key: 'frog', val: '🐸'},
      {key: 'tiger', val: '🐯'},
      {key: 'koala', val: '🐨'},
      {key: 'bear', val: '🐻'},
      {key: 'pig', val: '🐷'},
      {key: 'pig_nose', val: '🐽'},
      {key: 'cow', val: '🐮'},
      {key: 'boar', val: '🐗'},
      {key: 'monkey_face', val: '🐵'},
      {key: 'monkey', val: '🐒'},
      {key: 'horse', val: '🐴'},
      {key: 'sheep', val: '🐑'},
      {key: 'elephant', val: '🐘'},
      {key: 'panda_face', val: '🐼'},
      {key: 'penguin', val: '🐧'},
      {key: 'bird', val: '🐦'},
      {key: 'baby_chick', val: '🐤'},
      {key: 'hatched_chick', val: '🐥'},
      {key: 'hatching_chick', val: '🐣'},
      {key: 'chicken', val: '🐔'},
      {key: 'snake', val: '🐍'},
      {key: 'turtle', val: '🐢'},
      {key: 'bug', val: '🐛'},
      {key: 'bee', val: '🐝'},
      {key: 'ant', val: '🐜'},
      {key: 'beetle', val: '🐞'},
      {key: 'snail', val: '🐌'},
      {key: 'octopus', val: '🐙'},
      {key: 'shell', val: '🐚'},
      {key: 'tropical_fish', val: '🐠'},
      {key: 'fish', val: '🐟'},
      {key: 'dolphin', val: '🐬'},
      {key: 'whale', val: '🐳'},
      {key: 'racehorse', val: '🐎'},
      {key: 'dragon_face', val: '🐲'},
      {key: 'blowfish', val: '🐡'},
      {key: 'camel', val: '🐫'},
      {key: 'poodle', val: '🐩'},
      {key: 'feet', val: '🐾'},
      {key: 'bouquet', val: '💐'},
      {key: 'cherry_blossom', val: '🌸'},
      {key: 'tulip', val: '🌷'},
      {key: 'four_leaf_clover', val: '🍀'},
      {key: 'rose', val: '🌹'},
      {key: 'sunflower', val: '🌻'},
      {key: 'hibiscus', val: '🌺'},
      {key: 'maple_leaf', val: '🍁'},
      {key: 'leaves', val: '🍃'},
      {key: 'fallen_leaf', val: '🍂'},
      {key: 'herb', val: '🌿'},
      {key: 'ear_of_rice', val: '🌾'},
      {key: 'mushroom', val: '🍄'},
      {key: 'cactus', val: '🌵'},
      {key: 'palm_tree', val: '🌴'},
      {key: 'chestnut', val: '🌰'},
      {key: 'seedling', val: '🌱'},
      {key: 'blossom', val: '🌼'},
      {key: 'new_moon', val: '🌑'},
      {key: 'first_quarter_moon', val: '🌓'},
      {key: 'moon', val: '🌔'},
      {key: 'full_moon', val: '🌕'},
      {key: 'first_quarter_moon_with_face', val: '🌛'},
      {key: 'crescent_moon', val: '🌙'},
      {key: 'earth_asia', val: '🌏'},
      {key: 'volcano', val: '🌋'},
      {key: 'milky_way', val: '🌌'},
      {key: 'stars', val: '🌠'},
      {key: 'partly_sunny', val: '⛅'},
      {key: 'snowman', val: '⛄'},
      {key: 'cyclone', val: '🌀'},
      {key: 'foggy', val: '🌁'},
      {key: 'rainbow', val: '🌈'},
      {key: 'ocean', val: '🌊'},
    ]
  },
  {
    title: "Objects",
    emojis: [
      {key: 'bamboo', val: '🎍'},
      {key: 'gift_heart', val: '💝'},
      {key: 'dolls', val: '🎎'},
      {key: 'school_satchel', val: '🎒'},
      {key: 'mortar_board', val: '🎓'},
      {key: 'flags', val: '🎏'},
      {key: 'fireworks', val: '🎆'},
      {key: 'sparkler', val: '🎇'},
      {key: 'wind_chime', val: '🎐'},
      {key: 'rice_scene', val: '🎑'},
      {key: 'jack_o_lantern', val: '🎃'},
      {key: 'ghost', val: '👻'},
      {key: 'santa', val: '🎅'},
      {key: 'christmas_tree', val: '🎄'},
      {key: 'gift', val: '🎁'},
      {key: 'tanabata_tree', val: '🎋'},
      {key: 'tada', val: '🎉'},
      {key: 'confetti_ball', val: '🎊'},
      {key: 'balloon', val: '🎈'},
      {key: 'crossed_flags', val: '🎌'},
      {key: 'crystal_ball', val: '🔮'},
      {key: 'movie_camera', val: '🎥'},
      {key: 'camera', val: '📷'},
      {key: 'video_camera', val: '📹'},
      {key: 'vhs', val: '📼'},
      {key: 'cd', val: '💿'},
      {key: 'dvd', val: '📀'},
      {key: 'minidisc', val: '💽'},
      {key: 'floppy_disk', val: '💾'},
      {key: 'computer', val: '💻'},
      {key: 'iphone', val: '📱'},
      {key: 'telephone_receiver', val: '📞'},
      {key: 'pager', val: '📟'},
      {key: 'fax', val: '📠'},
      {key: 'satellite', val: '📡'},
      {key: 'tv', val: '📺'},
      {key: 'radio', val: '📻'},
      {key: 'loud_sound', val: '🔊'},
      {key: 'bell', val: '🔔'},
      {key: 'loudspeaker', val: '📢'},
      {key: 'mega', val: '📣'},
      {key: 'hourglass_flowing_sand', val: '⏳'},
      {key: 'hourglass', val: '⌛'},
      {key: 'alarm_clock', val: '⏰'},
      {key: 'watch', val: '⌚'},
      {key: 'unlock', val: '🔓'},
      {key: 'lock', val: '🔒'},
      {key: 'lock_with_ink_pen', val: '🔏'},
      {key: 'closed_lock_with_key', val: '🔐'},
      {key: 'key', val: '🔑'},
      {key: 'mag_right', val: '🔎'},
      {key: 'bulb', val: '💡'},
      {key: 'flashlight', val: '🔦'},
      {key: 'electric_plug', val: '🔌'},
      {key: 'battery', val: '🔋'},
      {key: 'mag', val: '🔍'},
      {key: 'bath', val: '🛀'},
      {key: 'toilet', val: '🚽'},
      {key: 'wrench', val: '🔧'},
      {key: 'nut_and_bolt', val: '🔩'},
      {key: 'hammer', val: '🔨'},
      {key: 'door', val: '🚪'},
      {key: 'smoking', val: '🚬'},
      {key: 'bomb', val: '💣'},
      {key: 'gun', val: '🔫'},
      {key: 'hocho', val: '🔪'},
      {key: 'pill', val: '💊'},
      {key: 'syringe', val: '💉'},
      {key: 'moneybag', val: '💰'},
      {key: 'yen', val: '💴'},
      {key: 'dollar', val: '💵'},
      {key: 'credit_card', val: '💳'},
      {key: 'money_with_wings', val: '💸'},
      {key: 'calling', val: '📲'},
      {key: 'e-', val: '📧'},
      {key: 'inbox_tray', val: '📥'},
      {key: 'outbox_tray', val: '📤'},
      {key: 'envelope_with_arrow', val: '📩'},
      {key: 'incoming_envelope', val: '📨'},
      {key: 'mailbox', val: '📫'},
      {key: 'mailbox_closed', val: '📪'},
      {key: 'postbox', val: '📮'},
      {key: 'package', val: '📦'},
      {key: 'memo', val: '📝'},
      {key: 'page_facing_up', val: '📄'},
      {key: 'page_with_curl', val: '📃'},
      {key: 'bookmark_tabs', val: '📑'},
      {key: 'bar_chart', val: '📊'},
      {key: 'chart_with_upwards_trend', val: '📈'},
      {key: 'chart_with_downwards_trend', val: '📉'},
      {key: 'scroll', val: '📜'},
      {key: 'clipboard', val: '📋'},
      {key: 'date', val: '📅'},
      {key: 'calendar', val: '📆'},
      {key: 'card_index', val: '📇'},
      {key: 'file_folder', val: '📁'},
      {key: 'open_file_folder', val: '📂'},
      {key: 'pushpin', val: '📌'},
      {key: 'paperclip', val: '📎'},
      {key: 'straight_ruler', val: '📏'},
      {key: 'triangular_ruler', val: '📐'},
      {key: 'closed_book', val: '📕'},
      {key: 'green_book', val: '📗'},
      {key: 'blue_book', val: '📘'},
      {key: 'orange_book', val: '📙'},
      {key: 'notebook', val: '📓'},
      {key: 'notebook_with_decorative_cover', val: '📔'},
      {key: 'ledger', val: '📒'},
      {key: 'books', val: '📚'},
      {key: 'book', val: '📖'},
      {key: 'bookmark', val: '🔖'},
      {key: 'name_badge', val: '📛'},
      {key: 'newspaper', val: '📰'},
      {key: 'art', val: '🎨'},
      {key: 'clapper', val: '🎬'},
      {key: 'microphone', val: '🎤'},
      {key: 'headphones', val: '🎧'},
      {key: 'musical_score', val: '🎼'},
      {key: 'musical_note', val: '🎵'},
      {key: 'notes', val: '🎶'},
      {key: 'musical_keyboard', val: '🎹'},
      {key: 'violin', val: '🎻'},
      {key: 'trumpet', val: '🎺'},
      {key: 'saxophone', val: '🎷'},
      {key: 'guitar', val: '🎸'},
      {key: 'space_invader', val: '👾'},
      {key: 'video_game', val: '🎮'},
      {key: 'black_joker', val: '🃏'},
      {key: 'flower_playing_cards', val: '🎴'},
      {key: 'mahjong', val: '🀄'},
      {key: 'game_die', val: '🎲'},
      {key: 'dart', val: '🎯'},
      {key: 'football', val: '🏈'},
      {key: 'basketball', val: '🏀'},
      {key: 'soccer', val: '⚽'},
      {key: 'baseball', val: '⚾'},
      {key: 'tennis', val: '🎾'},
      {key: '8ball', val: '🎱'},
      {key: 'bowling', val: '🎳'},
      {key: 'golf', val: '⛳'},
      {key: 'checkered_flag', val: '🏁'},
      {key: 'trophy', val: '🏆'},
      {key: 'ski', val: '🎿'},
      {key: 'snowboarder', val: '🏂'},
      {key: 'swimmer', val: '🏊'},
      {key: 'surfer', val: '🏄'},
      {key: 'fishing_pole_and_fish', val: '🎣'},
      {key: 'tea', val: '🍵'},
      {key: 'sake', val: '🍶'},
      {key: 'beer', val: '🍺'},
      {key: 'beers', val: '🍻'},
      {key: 'cocktail', val: '🍸'},
      {key: 'tropical_drink', val: '🍹'},
      {key: 'wine_glass', val: '🍷'},
      {key: 'fork_and_knife', val: '🍴'},
      {key: 'pizza', val: '🍕'},
      {key: 'hamburger', val: '🍔'},
      {key: 'fries', val: '🍟'},
      {key: 'poultry_leg', val: '🍗'},
      {key: 'meat_on_bone', val: '🍖'},
      {key: 'spaghetti', val: '🍝'},
      {key: 'curry', val: '🍛'},
      {key: 'fried_shrimp', val: '🍤'},
      {key: 'bento', val: '🍱'},
      {key: 'sushi', val: '🍣'},
      {key: 'fish_cake', val: '🍥'},
      {key: 'rice_ball', val: '🍙'},
      {key: 'rice_cracker', val: '🍘'},
      {key: 'rice', val: '🍚'},
      {key: 'ramen', val: '🍜'},
      {key: 'stew', val: '🍲'},
      {key: 'oden', val: '🍢'},
      {key: 'dango', val: '🍡'},
      {key: 'egg', val: '🍳'},
      {key: 'bread', val: '🍞'},
      {key: 'doughnut', val: '🍩'},
      {key: 'custard', val: '🍮'},
      {key: 'icecream', val: '🍦'},
      {key: 'ice_cream', val: '🍨'},
      {key: 'shaved_ice', val: '🍧'},
      {key: 'birthday', val: '🎂'},
      {key: 'cake', val: '🍰'},
      {key: 'cookie', val: '🍪'},
      {key: 'chocolate_bar', val: '🍫'},
      {key: 'candy', val: '🍬'},
      {key: 'lollipop', val: '🍭'},
      {key: 'honey_pot', val: '🍯'},
      {key: 'apple', val: '🍎'},
      {key: 'green_apple', val: '🍏'},
      {key: 'tangerine', val: '🍊'},
      {key: 'cherries', val: '🍒'},
      {key: 'grapes', val: '🍇'},
      {key: 'watermelon', val: '🍉'},
      {key: 'strawberry', val: '🍓'},
      {key: 'peach', val: '🍑'},
      {key: 'melon', val: '🍈'},
      {key: 'banana', val: '🍌'},
      {key: 'pineapple', val: '🍍'},
      {key: 'sweet_potato', val: '🍠'},
      {key: 'eggplant', val: '🍆'},
      {key: 'tomato', val: '🍅'},
      {key: 'corn', val: '🌽'},
    ]
  },
  {
    title: "Places",
    emojis: [
      {key: 'house', val: '🏠'},
      {key: 'house_with_garden', val: '🏡'},
      {key: 'school', val: '🏫'},
      {key: 'office', val: '🏢'},
      {key: 'post_office', val: '🏣'},
      {key: 'hospital', val: '🏥'},
      {key: 'bank', val: '🏦'},
      {key: 'convenience_store', val: '🏪'},
      {key: 'love_hotel', val: '🏩'},
      {key: 'hotel', val: '🏨'},
      {key: 'wedding', val: '💒'},
      {key: 'church', val: '⛪'},
      {key: 'department_store', val: '🏬'},
      {key: 'city_sunrise', val: '🌇'},
      {key: 'city_sunset', val: '🌆'},
      {key: 'japanese_castle', val: '🏯'},
      {key: 'european_castle', val: '🏰'},
      {key: 'tent', val: '⛺'},
      {key: 'factory', val: '🏭'},
      {key: 'tokyo_tower', val: '🗼'},
      {key: 'japan', val: '🗾'},
      {key: 'mount_fuji', val: '🗻'},
      {key: 'sunrise_over_mountains', val: '🌄'},
      {key: 'sunrise', val: '🌅'},
      {key: 'night_with_stars', val: '🌃'},
      {key: 'statue_of_liberty', val: '🗽'},
      {key: 'bridge_at_night', val: '🌉'},
      {key: 'carousel_horse', val: '🎠'},
      {key: 'ferris_wheel', val: '🎡'},
      {key: 'fountain', val: '⛲'},
      {key: 'roller_coaster', val: '🎢'},
      {key: 'ship', val: '🚢'},
      {key: 'boat', val: '⛵'},
      {key: 'speedboat', val: '🚤'},
      {key: 'rocket', val: '🚀'},
      {key: 'seat', val: '💺'},
      {key: 'station', val: '🚉'},
      {key: 'bullettrain_side', val: '🚄'},
      {key: 'bullettrain_front', val: '🚅'},
      {key: 'metro', val: '🚇'},
      {key: 'railway_car', val: '🚃'},
      {key: 'bus', val: '🚌'},
      {key: 'blue_car', val: '🚙'},
      {key: 'car', val: '🚗'},
      {key: 'taxi', val: '🚕'},
      {key: 'truck', val: '🚚'},
      {key: 'rotating_light', val: '🚨'},
      {key: 'police_car', val: '🚓'},
      {key: 'fire_engine', val: '🚒'},
      {key: 'ambulance', val: '🚑'},
      {key: 'bike', val: '🚲'},
      {key: 'barber', val: '💈'},
      {key: 'busstop', val: '🚏'},
      {key: 'ticket', val: '🎫'},
      {key: 'traffic_light', val: '🚥'},
      {key: 'construction', val: '🚧'},
      {key: 'beginner', val: '🔰'},
      {key: 'fuelpump', val: '⛽'},
      {key: 'izakaya_lantern', val: '🏮'},
      {key: 'slot_machine', val: '🎰'},
      {key: 'moyai', val: '🗿'},
      {key: 'circus_tent', val: '🎪'},
      {key: 'performing_arts', val: '🎭'},
      {key: 'round_pushpin', val: '📍'},
      {key: 'triangular_flag_on_post', val: '🚩'},
    ]
  },
  {
    title: "Symbols",
    emojis: [
      {key: 'keycap_ten', val: '🔟'},
      {key: '1234', val: '🔢'},
      {key: 'symbols', val: '🔣'},
      {key: 'capital_abcd', val: '🔠'},
      {key: 'abcd', val: '🔡'},
      {key: 'abc', val: '🔤'},
      {key: 'arrow_up_small', val: '🔼'},
      {key: 'arrow_down_small', val: '🔽'},
      {key: 'rewind', val: '⏪'},
      {key: 'fast_forward', val: '⏩'},
      {key: 'arrow_double_up', val: '⏫'},
      {key: 'arrow_double_down', val: '⏬'},
      {key: 'ok', val: '🆗'},
      {key: 'new', val: '🆕'},
      {key: 'up', val: '🆙'},
      {key: 'cool', val: '🆒'},
      {key: 'free', val: '🆓'},
      {key: 'ng', val: '🆖'},
      {key: 'signal_strength', val: '📶'},
      {key: 'cinema', val: '🎦'},
      {key: 'koko', val: '🈁'},
      {key: 'u6307', val: '🈯'},
      {key: 'u7a7a', val: '🈳'},
      {key: 'u6e80', val: '🈵'},
      {key: 'u5408', val: '🈴'},
      {key: 'u7981', val: '🈲'},
      {key: 'ideograph_advantage', val: '🉐'},
      {key: 'u5272', val: '🈹'},
      {key: 'u55b6', val: '🈺'},
      {key: 'u6709', val: '🈶'},
      {key: 'u7121', val: '🈚'},
      {key: 'restroom', val: '🚻'},
      {key: 'mens', val: '🚹'},
      {key: 'womens', val: '🚺'},
      {key: 'baby_symbol', val: '🚼'},
      {key: 'wc', val: '🚾'},
      {key: 'no_smoking', val: '🚭'},
      {key: 'u7533', val: '🈸'},
      {key: 'accept', val: '🉑'},
      {key: 'cl', val: '🆑'},
      {key: 'sos', val: '🆘'},
      {key: 'id', val: '🆔'},
      {key: 'no_entry_sign', val: '🚫'},
      {key: 'underage', val: '🔞'},
      {key: 'no_entry', val: '⛔'},
      {key: 'negative_squared_cross_mark', val: '❎'},
      {key: 'white_check_mark', val: '✅'},
      {key: 'heart_decoration', val: '💟'},
      {key: 'vs', val: '🆚'},
      {key: 'vibration_mode', val: '📳'},
      {key: 'mobile_phone_off', val: '📴'},
      {key: 'ab', val: '🆎'},
      {key: 'diamond_shape_with_a_dot_inside', val: '💠'},
      {key: 'ophiuchus', val: '⛎'},
      {key: 'six_pointed_star', val: '🔯'},
      {key: 'atm', val: '🏧'},
      {key: 'chart', val: '💹'},
      {key: 'heavy_dollar_sign', val: '💲'},
      {key: 'currency_exchange', val: '💱'},
      {key: 'x', val: '❌'},
      {key: 'exclamation', val: '❗'},
      {key: 'question', val: '❓'},
      {key: 'grey_exclamation', val: '❕'},
      {key: 'grey_question', val: '❔'},
      {key: 'o', val: '⭕'},
      {key: 'top', val: '🔝'},
      {key: 'end', val: '🔚'},
      {key: 'back', val: '🔙'},
      {key: 'on', val: '🔛'},
      {key: 'soon', val: '🔜'},
      {key: 'arrows_clockwise', val: '🔃'},
      {key: 'clock12', val: '🕛'},
      {key: 'clock1', val: '🕐'},
      {key: 'clock2', val: '🕑'},
      {key: 'clock3', val: '🕒'},
      {key: 'clock4', val: '🕓'},
      {key: 'clock5', val: '🕔'},
      {key: 'clock6', val: '🕕'},
      {key: 'clock7', val: '🕖'},
      {key: 'clock8', val: '🕗'},
      {key: 'clock9', val: '🕘'},
      {key: 'clock10', val: '🕙'},
      {key: 'clock11', val: '🕚'},
      {key: 'heavy_plus_sign', val: '➕'},
      {key: 'heavy_minus_sign', val: '➖'},
      {key: 'heavy_division_sign', val: '➗'},
      {key: 'white_flower', val: '💮'},
      {key: '100', val: '💯'},
      {key: 'radio_button', val: '🔘'},
      {key: 'link', val: '🔗'},
      {key: 'curly_loop', val: '➰'},
      {key: 'trident', val: '🔱'},
      {key: 'small_red_triangle', val: '🔺'},
      {key: 'black_square_button', val: '🔲'},
      {key: 'white_square_button', val: '🔳'},
      {key: 'red_circle', val: '🔴'},
      {key: 'large_blue_circle', val: '🔵'},
      {key: 'small_red_triangle_down', val: '🔻'},
      {key: 'white_large_square', val: '⬜'},
      {key: 'black_large_square', val: '⬛'},
      {key: 'large_orange_diamond', val: '🔶'},
      {key: 'large_blue_diamond', val: '🔷'},
      {key: 'small_orange_diamond', val: '🔸'},
      {key: 'small_blue_diamond', val: '🔹'},
    ]
  },
]