const EventEmitter = require('events');
const {differenceInCalendarMonths} = require("date-fns");
const differenceInMinutes = require("date-fns/difference_in_minutes");
let token = null;

let emitter = new EventEmitter();


function addRenewEvent(exp) {
    let fireTime = differenceInMinutes(exp, new Date());
    if (fireTime <= 5) {
        emitter.emit('refreshToken', null);
    } else {
        setTimeout(() => {
            emitter.emit('refreshToken', null);
        }, ((fireTime - 5) * 60) * 1000)
    }
}

module.exports = {
    initialize(tokenObj) {
        token = tokenObj;
        addRenewEvent(token.exp);
    },
    renewToken(tokenObj) {
        token = {token: tokenObj.token, exp: new Date(tokenObj.exp)};
        addRenewEvent(tokenObj.exp)
    },
    token() {
        return token?.token || null
    },
    valid() {
        return token === null ? false : token.exp >= new Date().getTime();
    },
    userKey() {
        return null
    },
    events: emitter
};
