<template>
    <div
            class="c-flex-grow-1 c-flex-equal c-d-flex c-flex-column c-bord-r c-day"
            style="min-width: 300px"
    >
        <!--Header-->
        <slot name="header"></slot>

        <!--Body-->
        <div class="of-y c-flex-grow-1">
            <slot></slot>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {

    props: {
    },
    data() {
        return {

        }
    },
    watch: {},
    methods: {},
    computed: {
        ...mapGetters('userInfo', ['userInfo'])
    },
}
</script>

<style scoped>

</style>