<template>
    <div>
        <div class="pa-2 text-xs-center" v-if="initSettings || smtpSettings == null" @click="initSmtpSettings()">
            <v-progress-circular
                    :size="40"
                    :width="5"
                    color="primary"
                    indeterminate
            />
        </div>
        <div v-else>
            <div :class="topicClass">
                <div :class="headingClass">
                    SMTP Receive Address:
                </div>
                <div :class="itemClass" class="hide-parent body-2 greyType--text pt-1" @click="copyMailLink()" style="cursor: pointer;">
                    <v-layout row align-center class="pl-2">
                        <v-flex shrink style="white-space: nowrap">
                            <span class="underlineHover">{{`${smtpSettings.entitySmtpKey}-${smtpSettings.smtpKey}@mail.tasklogix.co.za`}}</span>
                            <input type="text" :value="`${smtpSettings.entitySmtpKey}-${smtpSettings.smtpKey}@mail.tasklogix.co.za`" id="mailKey" class="bord" style="width: 3px; height: 3px; opacity: 0"/>
                        </v-flex>
                        <v-flex class="pl-2 hide-child">
                            <v-icon color="greyType--text" small>file_copy</v-icon>
                        </v-flex>
                    </v-layout>
                </div>
            </div>
            <div :class="topicClass" >
                <div :class="headingClass">
                    Authorized Receive Addresses:
                </div>
                <div :class="itemClass" class="pt-1">
                    <div @click="showAuthAddresses = true" class="hover-item body-1 px-2 py-1" style="border: 1px solid grey; border-radius: 3px" :class="blankString(smtpSettings.smtpAuthorizedAddresses) ? 'red--text caption text-xs-center font-weight-bold' : 'greyType--text'">
                        {{blankString(smtpSettings.smtpAuthorizedAddresses) ? '*Required Click To Edit' : smtpSettings.smtpAuthorizedAddresses.split(';').map(obj => {return obj.trim()}).join(', ')}}
                    </div>
                </div>
            </div>
            <div :class="topicClass" class="pb-2">
                <div :class="headingClass">
                    Manage Servers:
                </div>
                <div :class="itemClass" class="pt-2">
                    <user-smtp-servers-manage
                            :user-info="userInfo"
                            :user="userInfo.userID"
                            :smtp-server-string="smtpSettings.smtpServers"
                            @updateServers="serverUpdateDone($event)"
                    />
                </div>
            </div>
        </div>
        <app-input-dlg
                :show-dialog="showAuthAddresses"
                title="Authorized Receive Addresses"
                :cancel-button="true"
                :show-input-box="false"
                @dismiss="showAuthAddresses = false"
                :body-padding="false"
        >
            <template slot="body">

                <div class="pt-2">
                    <div class="pl-2 greyType--text font-weight-bold caption">Add Email Address:</div>
                    <v-layout row align-center class="px-2 pb-1">
                        <v-flex xs12>
                            <v-text-field
                                    single-line
                                    hide-details
                                    class="pa-0 ma-0"
                                    label="Add Email Address:"
                                    v-model="addAuthAddress"
                                    color="primary"
                                    style="width: 100%"
                            />
                        </v-flex>
                        <v-flex class="pl-2">
                            <v-btn
                                    icon
                                    flat
                                    small
                                    color="green"
                                    class="pa-0 ma-0"
                                    @click="authAddressList.push(addAuthAddress); addAuthAddress = null"
                                    :disabled="addAuthAddress == null || email(addAuthAddress) !== true"
                            >
                                <v-icon>add</v-icon>
                            </v-btn>
                        </v-flex>
                    </v-layout>
                    <div class="pb-1 caption font-weight-bold red--text text-xs-center" v-if="addAuthAddress !== null  && email(addAuthAddress) !== true">
                        Invalid Email Address
                    </div>
                    <v-divider></v-divider>
                    <div class="scrollable" style="max-height: 300px">
                        <div v-for="(addr, i) in authAddressList" :key="i" class="hover-item" style="cursor: pointer">
                            <v-layout row align-center class="px-2">
                                <v-flex xs12 class="body-1 py-2">
                                    {{addr}}
                                </v-flex>
                                <v-flex shrink>
                                    <v-btn
                                            icon
                                            flat
                                            small
                                            color="red"
                                            class="pa-0 ma-0"
                                            @click="authAddressList.splice(i,1)"
                                    >
                                        <v-icon>delete</v-icon>
                                    </v-btn>
                                </v-flex>
                            </v-layout>
                            <v-divider></v-divider>
                        </div>
                    </div>
                </div>

            </template>
            <template slot="actions">
                <v-btn @click="smtpSettings.smtpAuthorizedAddresses = authAddressList.map(obj => {return obj.trim()}).join(';'); showAuthAddresses = false; updateAuthAddresses()"
                       :disabled="authAddressList.length === 0" small color="secondary">Done
                </v-btn>
            </template>
        </app-input-dlg>
    </div>
</template>

<script>
    import {codeBaseMixin} from "../../../codeBaseMixin";
    import InputDlg from "../../../components/General/InputDlg";
    import UserSmtpServersManage from "../../../Admin/UserManager/userSmtpServersManage";
    import {eventBus} from "../../../main";

    export default {
        components: {UserSmtpServersManage, appInputDlg: InputDlg},
        mixins: [codeBaseMixin],
        props: {
            userInfo: Object
        },
        data() {
            return {
                smtpSettings: null,
                initSettings: false,
                email: value => {
                    if(value.length > 0) {
                        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                        return pattern.test(value) || 'Invalid Email Address';
                    }
                },
                showAuthAddresses: false,
                authAddressList: [],
                addAuthAddress: null,
            }
        },
        watch: {
            showAuthAddresses(val) {
                if (val) {
                    this.authAddressList = this.blankString(this.smtpSettings.smtpAuthorizedAddresses) ? [] : this.smtpSettings.smtpAuthorizedAddresses.split(';').map(obj => {return obj.trim()})
                }
            },
        },
        mounted() {
            this.initSmtpSettings();
        },
        methods: {
            serverUpdateDone(data) {
                this.smtpSettings.smtpServers = data;
                this.updateMailConfig()
            },

            updateMailConfig() {
                eventBus.$emit('updateSmtpMailConfig')
            },

            initSmtpSettings() {
                this.initSettings = true;
                this.fetch({method: 'GET', url:`/smtp/getUserManage/${this.userInfo.entityID}/${this.userInfo.userID}`})
                    .then((data) => {
                        this.smtpSettings = data;
                        this.initSettings = false;
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e);
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                        this.initSettings = false;
                    })
            },

            updateAuthAddresses() {
                this.initSettings = true;
                this.fetch({method: 'POST', url:`/smtp/udateAuthAddressList/${this.userInfo.entityID}/${this.userInfo.userID}`, body: {addressList: this.smtpSettings.smtpAuthorizedAddresses}})
                    .then(() => {
                        this.initSettings = false;
                        this.showSnack('Info', 'Settings Updated', 'Close', 'primary')
                        this.$emit('changesSaved');
                        eventBus.$emit('updateSMTPMailConfig');
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e);
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                        this.initSettings = false;
                    })
            },

            copyMailLink() {
                /* Get the text field */
                var copyText = document.getElementById("mailKey");

                /* Select the text field */
                copyText.select();

                /* Copy the text inside the text field */
                document.execCommand("copy");

                /* Alert the copied text */
                this.showSnack('Info', 'SMTP Receive Address Copied', 'Close', 'primary')
            },
        }
    }
</script>

<style scoped>
    .underlineHover:hover {
        text-decoration:underline;
    }
</style>
