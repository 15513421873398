<template>
    <v-layout row fill-height class="scrollable">
        <v-flex xs12 class="text-xs-center pa-2" v-if="loading">
            <v-progress-circular
                    :size="40"
                    :width="5"
                    color="primary"
                    indeterminate
            ></v-progress-circular>
        </v-flex>
        <template v-else>
            <v-flex xs3 class="fill-height" v-if="workflowViewOption === 1">
                <app-rules-bar
                        :user-info="userInfo"
                        :steps="steps"
                        :fields="fields"
                        :rules="rules"
                        :ruleRef="ruleRef"
                        :select-lists="selectLists"
                        @editCalled="editRun($event)"
                        @addCalled="addRuleFieldsRun($event)"
                        @ruleDrop="ruleDropRule($event)"
                        @ruleRemoved="removeRule($event)"
                        v-model="ruleFilterString"
                        @inputPreview="rulesPreview = $event"
                        :preview-rules-value="rulesPreview"
                        @clearPreviewValues="resetPreviewValues(true)"
                />
            </v-flex>

            <v-flex :xs9="workflowViewOption === 1" :xs12="workflowViewOption === 0" class="fill-height">
                <v-layout column class="scrollable" fill-height style="overflow-x: auto">
                    <v-flex grow style="overflow-x: auto; overflow-y: hidden">
                        <draggable v-model="orderedSteps"
                                   group="Quick Steps"
                                   @start="drag=true"
                                   @end="drag=false"
                                   ghostClass="ghost"
                                   :class="viewSwitch ? 'mainCon' : ''"
                        >
                            <template slot="header">
                                <div class="py-1 px-2 subConHeader">
                                    <v-tooltip right>
                                        <v-btn
                                                small
                                                icon
                                                flat
                                                class="pa-0 ma-0"
                                                color="secondary"
                                                @click="viewSwitch = !viewSwitch"
                                                slot="activator"
                                                style="display: flex"
                                        >
                                            <v-icon>
                                                {{viewSwitch ? 'chevron_right' : 'keyboard_arrow_down'}}
                                            </v-icon>
                                        </v-btn>
                                        <span>Toggle Steps View</span>
                                    </v-tooltip>
                                </div>
                            </template>
                            <template v-for="step in orderedSteps">
                                <div class="px-1 subConHeader" :class="viewSwitch ? 'py-2' : 'py-1'" :key="`WorkflowManagerStep${step.id}`" :id="`WorkflowManagerStepShort${step.id}`">
                                    <v-card
                                            :hover="true"
                                            ripple
                                            :class="step.hidden == 0 ? (step.hideIfEmpty == 1 ? 'blue darken-3' : 'title-color')  : 'grey'"
                                            outline
                                            @click="navigate(step.id)"
                                    >
                                        <v-layout row align-center>
                                            <v-flex shrink class="pl-1">
                                                <v-icon
                                                        small
                                                        class="pa-0 ma-0"
                                                        @click="!selectedSteps.includes(step.id) ? selectedSteps.push(step.id) : selectedSteps = selectedSteps.filter(id => id != step.id)"
                                                        color="white"
                                                        @contextmenu="show"
                                                >
                                                    {{selectedSteps.includes(step.id) > 0 ? 'check_box' : 'check_box_outline_blank'}}
                                                </v-icon>
                                            </v-flex>
                                            <v-flex shrink>
                                                <div class="pr-2 pl-1">
                                                    <span class="caption font-weight-regular white--text" style="white-space: pre">{{step.description}}</span>
                                                </div>
                                            </v-flex>
                                        </v-layout>
                                    </v-card>
                                </div>
                            </template>
                            <template slot="footer">
                                <div class="px-2 subConHeader" :class="viewSwitch ? 'py-2' : 'py-1'">
                                    <app-add-step-to-workflow
                                            :user-info="userInfo"
                                            :user-groups="userGroups"
                                            :workflow-id="selectedWorkflow.id"
                                            :steps="steps"
                                            :fields="fields"
                                            @newStepID="navToNewStep($event)"
                                    />
                                </div>
                            </template>
                        </draggable>
                    </v-flex>
                    <v-flex xs12 fill-height style="overflow-y: auto; overflow-x: auto;" id="StepsContainer" class="mainCon px-2">
                        <template v-for="step in filteredSteps">
                            <div
                                    class="px-1 pt-2 subCon"
                                    :key="`WorkflowManagerStep${step.id}`"
                                    :id="`WorkflowManagerStep${step.id}`"
                                    v-show="!rulesPreview || (rulesPreview && !rulePreviewHiddenItems.steps.includes(step.id))"
                            >
                                <app-workflow-step
                                        :user-info="userInfo"
                                        :step="step"
                                        :steps="steps"
                                        :fields="fields"
                                        :rules="rules"
                                        :ruleRef="ruleRef"
                                        :fieldSets="fieldSets"
                                        :userGroups="userGroups"
                                        :selectLists="selectLists"
                                        @order="changeStepOrder = true"
                                        @selectField="selectedField = $event"
                                        @ruleDrop="ruleDropField($event)"
                                        :selected-field="selectedField"
                                        :split-task-workflows="splitTaskWorkflows"
                                        :folder-groups="folderGroups"
                                        :signatures="signatures"
                                        :show-template-editor-fn="showTemplateEditorFn"
                                        :show-select-fields="addRuleFieldsRunning"
                                        :selected-fields="selectedFields"
                                        @addToSelection="addToSelection($event)"
                                        @removeFromSelection="removeFromSelection($event)"
                                        :scroll-to-rule-fn="scrollToRuleID"
                                        @editRuleFromID="editRunID($event)"
                                        :preview-rules="rulesPreview"
                                        :preview-values="rulePreviewFieldValues"
                                        :preview-edit-fields="rulePreviewEditFields"
                                        @setPreviewValue="setPreviewValue($event)"
                                        :hiddenFields="rulePreviewHiddenItems.fields"
                                        @copyStep="copyStepRun(step)"
                                        :recycleFields="recycleFields"
                                        :local-data="localData"
                                        :show-sync="showSync"
                                        :get-task-header="getTaskHeader"
                                />
                            </div>
                        </template>
                    </v-flex>
                </v-layout>
            </v-flex>
<!--Select Fields Snack-->
            <v-snackbar
                    top
                    center
                    :value="50"
                    v-model="addRuleFieldsRunning"
                    :timeout="0"
            >
                <span
                        class="subheading font-weight-bold pr-2 primary--text"
                >
                            Select Fields
                </span>

                <span class="body-1">{{ `(${selectedFields.length} Field${selectedFields.length >= 2 ? 's' : ''} Selected)` }}</span>

                <v-btn
                        flat
                        @click="addRuleFieldsRunning = false"
                        class="secondary--text ma-0 mx-3"
                >
                    Cancel
                </v-btn>

                <v-btn
                        flat
                        @click="addMultipleToRule()"
                        class="secondary--text ma-0"
                >
                    Add To Rule
                </v-btn>
            </v-snackbar>
<!--Rule Dialogs And Menus-->
            <v-menu
                    v-model="showMenu"
                    :position-x="x"
                    :position-y="y"
                    absolute
                    offset-y
            >
              <v-card class="page-back">
                <v-list style="cursor: pointer">
                  <v-list-tile @click="selectedSteps = orderedSteps.map(step => {return step.id})">
                    <v-list-tile-title>Select All</v-list-tile-title>
                  </v-list-tile>
                  <v-list-tile @click="selectedSteps = []">
                    <v-list-tile-title>Select None</v-list-tile-title>
                  </v-list-tile>
                </v-list>
              </v-card>
            </v-menu>
            <app-input-dlg
                    v-if="dropRuleFieldData != null"
                    :show-dialog="showDropRuleFieldDlg"
                    :title="dropRuleFieldData.dragData.description"
                    :cancel-button="true"
                    :show-input-box="false"
                    @dismiss="showDropRuleFieldDlg = false"
                    :body-padding="false"
                    :width="500"
            >
                <template slot="body">
                    <div class="pl-2 pr-0 pt-2 pb-0">
                        <div :class="topicClass">
                            <div :class="headingClass">
                                Create a New Rule:
                            </div>
                            <div :class="itemClass">
                                <v-btn small flat block color="secondary" @click="newRuleRunStart">Create New Rule</v-btn>
                            </div>
                            <div :class="headingClass" class="pt-2">
                                Add '{{dropRuleFieldData.dragData.description}}' to Rule:
                            </div>
                            <div>
                                <v-divider></v-divider>
                            </div>
                            <div :class="itemClass">
                                <v-list dense style="max-height: 300px; overflow-y: auto">
                                    <v-list-tile
                                            v-for="rule in dropFieldRuleList"
                                            :key="rule.id"
                                            @click="editRuleRunStart(rule.id)"
                                            ripple
                                    >
                                        <span class="caption">{{rule.description}}</span>
                                    </v-list-tile>
                                </v-list>
                            </div>
                        </div>
                    </div>
                </template>
            </app-input-dlg>
            <app-edit-rule-dialog
                    :show="showEditRule"
                    :select-lists="selectLists"
                    :add-fields="editRuleFields"
                    :add-steps="editRuleSteps"
                    :edit-rule="editRule"
                    :fields="fields"
                    :steps="steps"
                    @dismiss="showEditRule = false"
                    :user-info="userInfo"
                    @ruleUpdated="replaceRule($event)"
            />
            <app-add-rule-dialog
                    :show="showAddRule"
                    :select-lists="selectLists"
                    :add-fields="editRuleFields"
                    :add-steps="editRuleSteps"
                    :fields="fields"
                    @dismiss="showAddRule = false"
                    :user-info="userInfo"
                    :selected-workflow-i-d="selectedWorkflow.id"
                    @ruleAdded="addRule($event)"
            />
            <app-input-dlg
                    v-if="showCopyStepConfirm"
                    :show-dialog="showCopyStepConfirm"
                    title="Confirm Copy"
                    :body="`Copy Step '${copyStep.description}'. This function will copy the step and all fields along with security. Please remember to configure rules as they are not copied`"
                    :cancel-button="true"
                    :show-input-box="false"
                    :buttons="[]"
                    @dismiss="showCopyStepConfirm = false"
            >
                <template slot="actions">
                    <div class="text-xs-right">
                        <v-btn flat color="secondary" @click="copyStepConfirm" :disabled="copyingStep" :loading="copyingStep">Confirm</v-btn>
                    </div>
                </template>
            </app-input-dlg>
        </template>
    </v-layout>
</template>

<script>
    import {codeBaseMixin} from "../../../../../codeBaseMixin";
    import WorkflowStep from "./Components/WorkflowStep";
    import InputDlg from "../../../../../components/General/InputDlg";
    import draggable from 'vuedraggable';
    import VueScrollTo from 'vue-scrollto';
    import AddStepToWorkflow from "./Components/AddStepToWorkflow";
    import AddRuleDialog from "../Rules/AddRuleDialog";
    import EditRuleDialog from "../Rules/EditRuleDialog";
    import RulesBar from "../Rules/RulesBar";

    export default {
        mixins: [codeBaseMixin],
        components: {
            appRulesBar: RulesBar,
            appEditRuleDialog: EditRuleDialog,
            appAddRuleDialog: AddRuleDialog,
            appInputDlg: InputDlg,
            appAddStepToWorkflow: AddStepToWorkflow,
            appWorkflowStep: WorkflowStep,
            draggable
        },
        props: {
            userInfo: Object,
            selectedWorkflow: Object,
            folderGroups: Array,
            showTemplateEditorFn: Function,
            workflowViewOption: Number,
            localData: Object,
            showSync: Boolean,
            getTaskHeader: Function,
        },
        data() {
            return {
                loading: false,
                steps: [],
                fields: [],
                rules: [],
                fieldSets: [],
                userGroups: [],
                selectLists: [],
                selectedField: null,
                changeStepOrder: false,
                selectedSteps: [],
                splitTaskWorkflows: [],
                signatures: [],
                viewSwitch: true,
                bReset: false,
                showMenu: false,
                x: 0,
                y: 0,
                showDropRuleFieldDlg: false,
                dropRuleFieldData: null,
                headingClass: 'caption greyType--text font-weight-bold',
                topicClass: 'pa-1',
                itemClass: 'pl-2',
                showEditRule: false,
                showAddRule: false,
                editRule: null,
                editRuleFields: [],
                editRuleSteps: [],
                selectedFields: [],
                addRuleFieldsRunning: false,
                ruleFilterString: null,
                rulesPreview: false,
                rulePreviewFieldValues: {},
                showCopyStepConfirm: false,
                copyStep: null,
                copyingStep: false,
            }
        },
        watch: {
            rulesPreview() {
                this.resetPreviewValues(true)
            },
            selectedWorkflow: {
                immediate: true,
                handler(val) {
                    this.addRuleFieldsRunning = false;
                    this.ruleFilterString = null;
                    this.rulesPreview = false;
                    this.rulePreviewFieldValues = {};
                    if (val != null && typeof val.id != 'undefined') {
                        this.reset(val.id);
                    }
                }
            },
            workflowViewOption() {
                this.addRuleFieldsRunning = false;
                this.ruleFilterString = null;
                this.rulesPreview = false;
                this.rulePreviewFieldValues = {};
            },
            fields: {
                immediate: true,
                handler(val) {
                    this.resetPreviewValues(true)
                }
            },
        },
        methods: {
            copyStepConfirm() {
                this.copyingStep = true;
                this.fetch({method: 'GET', url:`/workflowManager/workflow/copyStep/${this.userInfo.entityID}/${this.copyStep.id}`})
                    .then((data) => {
                        this.steps = this.steps.concat(data.steps);
                        this.fields = this.fields.concat(data.fields);
                        this.navToNewStep(data.steps.pop().id);
                        this.copyingStep = false;
                        this.showCopyStepConfirm = false;
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e);
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                        this.copyingStep = false;
                    })

            },

            copyStepRun(step) {
                this.copyStep = step;
                this.showCopyStepConfirm = true;
            },
            resetPreviewValues(clearValues) {
                if (clearValues) {
                    this.rulePreviewFieldValues = {};
                }
                this.fields.forEach(field => {
                    if (typeof this.rulePreviewFieldValues[field.id] == 'undefined') {
                        this.$set(this.rulePreviewFieldValues, `${field.id}`, null)
                    }
                })
            },
            scrollToRuleID(ruleID) {
                this.ruleFilterString = null;
                this.$emit('workflowViewOption', 1)
                this.$nextTick(() => {
                    setTimeout(() => {
                        VueScrollTo.scrollTo(`#WorkflowManagerRule${ruleID}`, 500, { container: '#RulesContainer', x: false, y: true});
                    }, 500)
                })
            },
            removeRule(rule) {
                this.rules.forEach((existingRule, n) => {
                    if (existingRule.id == rule.id) {
                        this.rules.splice(n,1);
                        this.showSnack('Info', 'Rule Removed', 'Close', 'primary')
                    }
                })
            },
            addMultipleToRule() {
                this.editRuleFields = [];
                this.editRuleSteps = [];
                //LoopThrough Fields And Add 1 by 1 to create a new Array
                this.selectedFields.forEach(field => {this.editRuleFields.push(field)})
                this.selectedFields =  [];
                this.addRuleFieldsRunning =  false;
                this.showEditRule = true
            },
            addToSelection(field) {
                this.selectedFields.push(field)
            },
            removeFromSelection(field) {
                this.selectedFields.forEach((selectedField, n) => {
                    if (selectedField.id == field.id) {
                        this.selectedFields.splice(n,1)
                    }
                })
            },
            addRuleFieldsRun(rule) {
                this.editRule = rule
                this.addRuleFieldsRunning = true;
                this.selectedFields = [];
            },
            editRun(rule) {
                this.editRule = rule;
                this.editRuleFields = [];
                this.editRuleSteps = [];
                this.showEditRule = true
            },
            editRunID(ruleID) {
                this.editRule = this.rules.filter(obj => obj.id == ruleID).pop();
                this.editRuleFields = [];
                this.editRuleSteps = [];
                this.showEditRule = true
            },
            replaceRule(rule) {
                this.rules = this.rules.reduce((list, oldRule) => {
                    if (oldRule.id == rule.id) {
                        list.push(rule);
                    } else {
                        list.push(oldRule);
                    }
                    return list
                }, [])
                this.resetPreviewValues(false);
            },
            addRule(rule) {
                this.ruleFilterString = null;
                this.scrollToRuleID(rule.ruleID);
                this.rules.push(rule);
                this.rules = this.rules.filter(obj => true);
                this.resetPreviewValues(false);
            },
            show (e) {
                e.preventDefault();
                this.showMenu = false;
                this.x = e.clientX;
                this.y = e.clientY;
                this.$nextTick(() => {
                    this.showMenu = true
                })
            },
            navToNewStep(stepID) {
                this.selectedSteps.push(stepID);
                setTimeout(() => {this.navigate(stepID)},500)
            },
            navigate(stepID) {
                VueScrollTo.scrollTo(`#WorkflowManagerStep${stepID}`, 500, { container: '#StepsContainer', x: true, y: true});
            },
            reset(workflowID) {
                this.steps = [];
                this.fields = [];
                this.rules = [];
                this.fieldSets = [];
                this.userGroups = [];
                this.selectLists = [];
                this.selectedField = null;
                this.selectedSteps = [];
                this.splitTaskWorkflows = [];
                this.signatures = [];
                this.loading = true;
                let urlSend = `/workflowManager/workflow/${this.userInfo.entityID}/${workflowID}`;

                this.fetch({method: 'GET', url: urlSend})
                    .then((data) => {

                        this.steps = data.steps;
                        this.fields = data.fields;
                        this.rules = data.rules;
                        this.fieldSets = data.fieldSets;
                        this.userGroups = data.userGroups;
                        this.selectLists = data.selectLists;
                        this.splitTaskWorkflows = data.workflows;
                        this.signatures = data.signatures;
                        if (this.steps.length <= 20)
                            this.selectAllSteps(true);
                        this.loading = false
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e);
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                    })

            },
            selectAllSteps(selectAll) {
                if (selectAll) {
                    this.selectedSteps = this.steps.map(obj => {return obj.id});
                } else {
                    this.selectedSteps = [];
                }
            },
            saveStepOrderChange() {
                this.fetch({method: 'PATCH', url:`/workflowManager/workflow/order/${this.userInfo.entityID}`, body: JSON.stringify({
                        newOrder: this.steps.map(obj => {
                            return {id: obj.id, field_order: obj.field_order}
                        })
                    })})
                    .then((data) => {

                        this.showSnack('Info', 'Order Updated', 'Close', 'primary')
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e);
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                    })
            },
            ruleDropRule(ruleData) {

                this.editRuleFields = [];
                this.editRuleSteps = [];
                this.editRule = this.rules.filter(obj => obj.id == ruleData.dropRule.id).pop();

                switch (ruleData.type) {
                    case 'field': {
                        this.editRuleFields = [ruleData.dragData];
                        break;
                    }
                    case 'step': {
                        this.editRuleSteps = [ruleData.dragData];
                        break;
                    }
                }
                this.showEditRule = true
            },
            ruleDropField(ruleData) {
                this.dropRuleFieldData = ruleData;
                this.editRuleFields = [];
                this.editRuleSteps = [];
                this.showDropRuleFieldDlg = false;

                switch (ruleData.type) {
                    case 'field': {
                        if (this.dropFieldRuleList.length <= 0) {
                            //New Rule
                            this.editRuleFields = [ruleData.dropField, ruleData.dragData];
                            this.showAddRule = true;
                        } else {
                            // Exising Rules
                            this.showDropRuleFieldDlg = true;
                        }
                        break;
                    }
                    case 'step': {
                        if (this.dropFieldRuleList.length <= 0) {
                            //New Rule
                            this.editRuleFields = [ruleData.dropField];
                            this.editRuleSteps = [ruleData.dragData];
                            this.showAddRule = true;
                        } else {
                            // Exising Rules
                            this.showDropRuleFieldDlg = true;
                        }
                        break;
                    }
                    case 'rule': {
                        this.editRuleFields = [this.dropRuleFieldData.dropField];
                        this.editRule = this.rules.filter(obj => obj.id == this.dropRuleFieldData.dragData.id).pop();
                        this.showEditRule = true;
                        break;
                    }
                }
            },
            newRuleRunStart() {
                this.editRuleFields = [];
                this.editRuleSteps = [];
                this.showDropRuleFieldDlg = false;
                switch (this.dropRuleFieldData.type) {
                    case 'field': {
                        this.editRuleFields = [this.dropRuleFieldData.dropField, this.dropRuleFieldData.dragData];
                        break;
                    }
                    case 'step' || '': {
                        this.editRuleFields = [this.dropRuleFieldData.dropField];
                        this.editRuleSteps = [this.dropRuleFieldData.dragData];
                        break;
                    }
                }
                this.showAddRule = true;
            },
            editRuleRunStart(ruleID) {
                this.showDropRuleFieldDlg = false;
                this.editRuleFields = [];
                this.editRuleSteps = [];

                switch (this.dropRuleFieldData.type) {
                    case 'field': {
                        this.editRuleFields = [this.dropRuleFieldData.dragData];
                        this.editRule = this.rules.filter(obj => obj.id == ruleID).pop();
                        break;
                    }
                    case 'step' : {
                        this.editRuleSteps = [this.dropRuleFieldData.dragData];
                        this.editRule = this.rules.filter(obj => obj.id == ruleID).pop();
                        break;
                    }
                }

                this.showEditRule = true
            },
            setPreviewValue(value) {
                this.rulePreviewFieldValues[value.field] = value.value;
            },
        },
        computed: {
            recycleFields() {
                try {
                    return this.fields.filter(obj => obj.visible == "0");
                } catch (e) {
                    // eslint-disable-next-line
                    console.log(e)
                    return null;
                }
            },
            filteredSteps() {

                if (this.recycleFields.length >= 1) {
                    return this.orderedSteps.filter(step => this.selectedSteps.includes(step.id)).concat([{id: -1, description: 'Recycle Bin'}])
                } else {
                    return this.orderedSteps.filter(step => this.selectedSteps.includes(step.id))
                }


            },
            orderedSteps: {
                get: function () {
                    try {
                        return this.steps.filter(() => true).sort((a,b) => a['field_order'] < b['field_order'] ? -1 : 1);
                    } catch (e) {
                        // eslint-disable-next-line
                        console.log(e);
                        return [];
                    }
                },
                set: function (newValue) {
                    if (newValue.length <= 0) {
                        return []
                    } else {
                        this.steps = newValue.map((item, order) => {
                            item.field_order = order;
                            return item
                        });
                        this.saveStepOrderChange();
                    }
                }
            },
// Rules
            rulePreviewEditFields() {
                try {
                    return this.rules.reduce((list, item) => {
                        if (Array.isArray(item.conditions)) {
                            list = list.concat(item.conditions.map(obj => {return obj.fk_field}))
                        }
                        return list
                    }, []);
                } catch (e) {
                    // eslint-disable-next-line
                    console.log(e)
                    return [];
                }
            },
            ruleRef() {
                return this.rules.reduce((ruleDisplay, curRule) => {

                    if (curRule.showSteps != null) {
                        curRule.showSteps.forEach(step => {
                            if (typeof ruleDisplay.steps[step] == "undefined") {
                                ruleDisplay.steps[step] = []
                            }
                            ruleDisplay.steps[step].push({id: curRule.id, description: curRule.description})
                        });
                    }
                    if (curRule.showFields != null) {
                        curRule.showFields.forEach(field => {
                            if (typeof ruleDisplay.fields[field] == "undefined") {ruleDisplay.fields[field] = {shownInRules: [], conditionInRules: []}}
                            ruleDisplay.fields[field].shownInRules.push({
                                id: curRule.id,
                                description: curRule.description
                            })
                        });
                    }
                    if (curRule.conditions != null) {
                        curRule.conditions.forEach(field => {
                            if (typeof ruleDisplay.fields[field.fk_field] == "undefined") {
                                ruleDisplay.fields[field.fk_field] = {shownInRules : [], conditionInRules: []}
                            }
                            ruleDisplay.fields[field.fk_field].conditionInRules.push({id: curRule.id, description: curRule.description})
                        });
                    }

                    return ruleDisplay
                }, {fields: {}, steps: {}})
            },
            dropFieldRuleList() {
                try {
                    let tList = [];
                    if (typeof this.ruleRef.fields[this.dropRuleFieldData.dropField.id].shownInRules != 'undefined' && this.ruleRef.fields[this.dropRuleFieldData.dropField.id].shownInRules != null) {
                        tList = tList.concat(this.ruleRef.fields[this.dropRuleFieldData.dropField.id].shownInRules)
                    }
                    if (typeof this.ruleRef.fields[this.dropRuleFieldData.dropField.id].conditionInRules != 'undefined' && this.ruleRef.fields[this.dropRuleFieldData.dropField.id].conditionInRules != null) {
                        tList = tList.concat(this.ruleRef.fields[this.dropRuleFieldData.dropField.id].conditionInRules)
                    }

                    tList = tList.map(obj => {return obj.id})

                    return this.rules.reduce((rules, rule) => {
                        if (tList.includes(rule.id)) {
                            rules.push({
                                id: rule.id,
                                description: rule.description != null && rule.description != '' ? rule.description : `Rule ${rule.id}`
                            })
                        }
                        return rules
                    }, [])
                        .sort((a,b) => a['description'] < b['description'] ? -1 : 1);
                } catch (e) {
                    // eslint-disable-next-line
                    return [];
                }
            },
            allRuleShowFields() {
                try {
                    return this.rules.reduce((list, item) => {
                        if (Array.isArray(item.showFields)) {
                            list = list.concat(item.showFields)
                        }
                        return list
                    }, []).reduce((list, item) => {
                        if (!list.includes(item)) {
                            list.push(item)
                        }
                        return list
                    }, []);
                } catch (e) {
                    // eslint-disable-next-line
                    console.log(e)
                    return [];
                }
            },
            allRuleShowSteps() {
                try {
                    return this.rules.reduce((list, item) => {
                        if (Array.isArray(item.showSteps)) {
                            list = list.concat(item.showSteps)
                        }
                        return list
                    }, []).reduce((list, item) => {
                        if (!list.includes(item)) {
                            list.push(item)
                        }
                        return list
                    }, []);
                } catch (e) {
                    // eslint-disable-next-line
                    console.log(e)
                    return [];
                }
            },
            rulePreviewHiddenItems() {
                let hiddenFields = this.allRuleShowFields.filter(() => true);
                let hiddenSteps = this.allRuleShowSteps.filter(() => true);

                this.rules.forEach((rule, index) => {
                    let validated = (rule.conditions || []).reduce((result, condition) => {
                        // 0: "=", 1: "Not Equal", 2: "Not Blank", 3: "Bigger", 4: "Smaller", 5: "After", 6: "Before", 7: "Is Blank"
                        switch (condition.operationID) {
                            case 0: {
                                if (
                                    this.blankString(this.rulePreviewFieldValues[condition.fk_field])
                                    || this.rulePreviewFieldValues[condition.fk_field].toLowerCase() != condition.value.toLowerCase()
                                ) {result = false;}
                                break;
                            }
                            case 1: {
                                if (
                                    !this.blankString(this.rulePreviewFieldValues[condition.fk_field])
                                    && this.rulePreviewFieldValues[condition.fk_field].toLowerCase() == condition.value.toLowerCase()
                                ) {result = false;}
                                break;
                            }

                            case 2: {
                                if (this.rulePreviewFieldValues[condition.fk_field] == null ? true : this.rulePreviewFieldValues[condition.fk_field] === "") {
                                    result = false;
                                }
                                break;
                            }

                            case 3: {
                                if (
                                    this.blankString(this.rulePreviewFieldValues[condition.fk_field])
                                    || parseFloat(!this.blankString(this.rulePreviewFieldValues[condition.fk_field]) ? this.rulePreviewFieldValues[condition.fk_field] : 0) < parseFloat(condition.value)
                                ) {
                                    result = false;
                                }
                                break;
                            }

                            case 4: {
                                if (
                                    this.blankString(this.rulePreviewFieldValues[condition.fk_field])
                                    || parseFloat(!this.blankString(this.rulePreviewFieldValues[condition.fk_field]) ? this.rulePreviewFieldValues[condition.fk_field] : 0) > parseFloat(condition.value)
                                ) {
                                    result = false;
                                }
                                break;
                            }

                            case 5: {
                                try {
                                    if (
                                        this.blankString(this.rulePreviewFieldValues[condition.fk_field])
                                        || new Date(this.rulePreviewFieldValues[condition.fk_field]) <= new Date(condition.value)
                                    ) {result = false;}
                                } catch (e) {
                                    //
                                }
                                break;
                            }
                            case 6: {
                                try {
                                    if (
                                        this.blankString(this.rulePreviewFieldValues[condition.fk_field])
                                        || new Date(this.rulePreviewFieldValues[condition.fk_field]) >= new Date(condition.value)
                                    ) {result = false;}
                                } catch (e) {
                                    //
                                }
                                break;
                            }

                            case 7: {
                                if (
                                    !this.blankString(this.rulePreviewFieldValues[condition.fk_field])
                                ) {
                                    result = false;
                                }
                                break;
                            }
                        }

                        return result
                    }, true);

                    console.log("validated", validated, rule)


                    if (validated && Array.isArray(rule.showFields)) {
                        hiddenFields = hiddenFields.filter(field => !rule.showFields.includes(field))
                    }

                    if (validated && Array.isArray(rule.showSteps)) {
                        hiddenSteps = hiddenSteps.filter(step => !rule.showSteps.includes(step))
                    }

                });

                let addHiddenStepsByEmptyFields = this.orderedSteps.reduce((list, step) => {
                    if (step.hideIfEmpty == 1 && this.fields.filter(field => field.fk_step == step.id && field.visible == 1 && !hiddenFields.includes(field.id)).length <= 0) {
                        list.push(step.id)
                    }
                    return list
                }, []);

                return {fields: hiddenFields, steps: hiddenSteps.concat(addHiddenStepsByEmptyFields)}
            }
        }

    }
</script>

<style scoped>

    .ghost {
        opacity: 0.5;
        background: #EEEEEE;
    }

    .mainCon {
        display: flex;
    }
    .subCon {
        float: left;
        min-width: 250px;
        max-width: 250px;
    }
    .subConHeader {
        float: left;
        cursor: pointer;
    }

</style>
