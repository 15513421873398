<template>
    <div>
        <v-menu offset-y offset-x v-if="!addOnly">
            <template v-slot:activator="{ on }">
                <v-btn
                        icon
                        small
                        outline
                        color="secondary"
                        v-on="on"
                        class="pa-0 ma-0"
                        style="width: 20px; height: 20px"
                >
                    <v-icon small>add</v-icon>
                </v-btn>
            </template>
            <v-list dense>
                <v-list-tile @click="$emit('input', 'AND')">
                    <v-list-tile-action><v-icon color="primary">arrow_forward</v-icon></v-list-tile-action>
                    <v-list-tile-title class="font-weight-bold">And</v-list-tile-title>
                </v-list-tile>
                <v-list-tile @click="$emit('input', 'OR')">
                    <v-list-tile-action><v-icon color="primary">subdirectory_arrow_right</v-icon></v-list-tile-action>
                    <v-list-tile-title class="font-weight-bold">Or</v-list-tile-title>
                </v-list-tile>
                <v-list-tile @click="$emit('input', 'condition')" v-if="showCondition">
                    <v-list-tile-action><v-icon color="primary">check_box</v-icon></v-list-tile-action>
                    <v-list-tile-title class="font-weight-bold">condition</v-list-tile-title>
                </v-list-tile>
            </v-list>
        </v-menu>
        <v-btn
                v-else
                icon
                small
                outline
                color="secondary"
                class="pa-0 ma-0"
                style="width: 20px; height: 20px"
                @click="$emit('input', 'AND')"
        >
            <v-icon small>add</v-icon>
        </v-btn>
    </div>
</template>

<script>
    export default {
        props: {
            addOnly: {
                type: Boolean,
                default: false
            },
            showCondition: {
                type: Boolean,
                default: true
            }
        }
    }
</script>

<style scoped>

</style>
