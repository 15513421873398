<template>
    <v-card
            class="pl-0"
            :class="mailClass"
            :flat="(!fieldValue && !taskValue) || dense"
            :style="dense ? 'border: 1px solid lightgrey' : ''"
            style="overflow-x: hidden"
            :hover="dashboard"
    >
<!--Mail Container-->
        <div
                @dragstart="drag('mail', mail)"
                :draggable="hasAccess && !noDrag ? true : false"
                style="overflow-x: hidden"
        >
            <v-layout
                    column
                    style="overflow-x: hidden"
                    class="pt-1"
            >
                <template v-if="dashboard">
                    <v-flex shrink class="px-2">
                        <span class="caption font-weight-bold">Autolink Mail</span>
                    </v-flex>
                </template>
                <v-flex shrink class=" pl-1 pr-2">
                    <v-layout align-center justify-start row class="pb-1">
<!--Mail Icon-->
                        <v-flex shrink class="text-xs-center">
                            <v-tooltip top>
                                <v-avatar
                                        ripple
                                        @click="hasAccess ? showPreview(true) : showPreview(false)" slot="activator"
                                        :color="intToBool(mail.autoLinkMail) ? 'blue' : 'primary'"
                                        size="26"
                                        style="cursor: pointer"
                                >
                                    <template v-if="typeof mail.sent != 'undefined' && mail.sent == 1">
                                        <v-icon small dark>keyboard_arrow_right</v-icon>
                                    </template>
                                    <template v-else>
                                        <v-icon v-if="intToBool(mail.autoLinkMail)" small dark>link</v-icon>
                                        <v-icon v-else small dark>mail</v-icon>
                                    </template>
                                </v-avatar>
                                <span>{{hasAccess ? 'Show Mail' : 'This Mail Has Been Restricted'}}</span>
                            </v-tooltip>
                        </v-flex>
<!--From String-->
                        <v-flex xs12 class="pl-1 overflow-x-hidden">

                            <v-layout row>
                                <v-flex xs12 class="overflow-x-hidden">
                                    <div class="font-weight-medium text--small">
                                        {{`${mailFrom}`}}
                                    </div>
                                    <div class="caption font-weight-light text-truncate">{{ mailTo }}</div>
                                </v-flex>
                                <v-flex shrink style="font-size: 0.8em; white-space: nowrap" class="font-weight-regular text-xs-right pr-1">
                                    {{ getShortDate(mail.mailDate) }}
                                    <br>
                                    {{ getShortTime(mail.mailDate) }}
                                </v-flex>
                            </v-layout>
                        </v-flex>
<!--Notification Icon-->
                        <v-flex shrink
                                v-if="(taskBand || taskMail) && task.ownerID == userInfo.userID && intToBool(mail.autoLinkNotification)"
                        >
                            <v-tooltip top>
                                <v-btn
                                        @click="removeAutolinkNotification()"
                                        class="ma-0 pa-0"
                                        flat
                                        icon
                                        color="secondary"
                                        slot="activator"
                                        :loading="responsePending"
                                        :disabled="responsePending"
                                >
                                    <v-icon>announcement</v-icon>
                                </v-btn>
                                <span>Remove Notification</span>
                            </v-tooltip>
                        </v-flex>
<!--Archive Mail-->
                        <v-flex shrink v-if="taskBand">
                            <v-tooltip top>
                                <v-btn @click="archiveMail()" class="ma-0 pa-0" flat icon
                                       :color="mail.archived == 0 ? 'red' : 'primary'" slot="activator">
                                    <v-icon>{{ mail.archived == 0 ? 'archive' : 'unarchive' }}</v-icon>
                                </v-btn>
                                <span>{{ mail.archived == 0 ? 'Archive Mail' : 'Restore Mail' }}</span>
                            </v-tooltip>
                        </v-flex>
<!--Upload Icon-->
                        <v-flex shrink v-if="fieldValue">
                            <v-tooltip top>
                                <v-btn
                                        @click="showUpload.show = true"
                                        flat
                                        icon
                                        color="primary"
                                        class="pa-0 ma-0"
                                        slot="activator"
                                >
                                    <v-icon>cloud_upload</v-icon>
                                </v-btn>

                                <span>Upload New Document</span>
                                <app-document-upload-dlg
                                        :dialog="showUpload"
                                        :user-info="userInfo"
                                        :field-value="true"
                                        @documentsUploaded="uploadFinished"
                                />
                            </v-tooltip>
                        </v-flex>
<!--Security-->
                        <v-flex shrink v-if="typeof mail.securityLookup != 'undefined' && mail.securityLookup != null && mail.securityLookup !== ''">
                            <v-tooltip top>
                                <v-icon
                                        :color="hasAccess ? 'primary' : 'red'"
                                        class="pa-0 ma-0"
                                        style="cursor: pointer"
                                        slot="activator"
                                >
                                    security
                                </v-icon>
                                <span>{{hasAccess ? 'Mail Has Security Applied' : 'This Mail Has Been Restricted'}}</span>
                            </v-tooltip>
                        </v-flex>
                    </v-layout>
                </v-flex>
            </v-layout>
        </div>



        <v-layout row align-center style="overflow-x: hidden; overflow-y: auto">
            <v-flex xs12 style="overflow-x: hidden; overflow-y: auto" class="text-truncate pt-1" >
<!--Subject-->
                <div class="px-2" v-if="mail.subject != '' && mail.subject != null" style="min-width: 0">
                    <div class="caption font-weight-medium text-truncate" style="min-width: 0"> {{ mail.subject }}</div>
                </div>
<!--Snippet-->
                <div class="px-2 text-truncate" v-if="mail.snippet != '' && mail.snippet != null && !taskValue" style="overflow-x: hidden">
                    <div class="caption font-weight-light text-truncate" style="min-width: 0"> {{ mail.snippet }}</div>
                </div>
<!--Attachments-->
                <v-layout row wrap align-center justify-space-between v-if="!taskValue && hasAccess">
                    <v-flex xs12 v-if="mail.attachments.length > 0">
                        <v-layout class="px-2 pt-1 pb-1" row align-center wrap>
                            <v-flex xs9>
                                <v-icon small class="primary--text">attach_file</v-icon>
                                <span class="caption font-weight-medium greyType--text pl-1">Attachments</span>
                            </v-flex>

                            <template>
                                <template v-for="(attachment, i) in mailAttachments">
                                    <v-flex
                                            xs12
                                            :key="i"
                                            class="taskBar"
                                    >
                                        <div
                                                @dragstart="dragAtt($event,attachment)"
                                                draggable="true"
                                        >
                                            <general-mail-container-attachment
                                                    :attachment="attachment"
                                                    ref="attachment"
                                                    @downloadAttachment="downloadAttachment(attachment, i)"
                                                    @previewAttachment="previewAttachment(attachment, i)"
                                            />
                                        </div>
                                    </v-flex>
                                </template>
                                <v-tooltip top style="width: 100%" v-if="!showEmbeddedAttachments && embeddedAttachmentLength > 0">
                                    <v-flex slot="activator" xs12 class="pl-2" style="cursor: pointer"  @click="showEmbeddedAttachments = true">
                                        <v-layout row>
                                            <v-flex xs12>
                                                <span class="caption text-truncate font-weight-bold secondary--text">{{ `+${embeddedAttachmentLength} `}}More</span>
                                            </v-flex>
                                            <v-flex shrink>
                                                <v-icon small color="secondary" class="ma-0">keyboard_arrow_down</v-icon>
                                            </v-flex>
                                        </v-layout>
                                    </v-flex>
                                    <span>Show Embedded Attachments</span>
                                </v-tooltip>
                            </template>
                        </v-layout>
                    </v-flex>
                </v-layout>
            </v-flex>
            <v-flex shrink v-if="dashboard" class="pa-1">
                <v-btn
                        flat
                        icon
                        class="pa-0 ma-0"
                        @click="clearNotification"
                        color="secondary"
                        small
                        :loading="responsePending"
                        :disabled="responsePending || readOnlyDash"
                >
                    <v-icon small>close</v-icon>
                </v-btn>
            </v-flex>
        </v-layout>

        <template v-if="taskBand && mail.stepID !== 0">
                <v-layout row align-center justify-end style="cursor: pointer" class="pr-2">
                    <v-flex class="pl-2" shrink v-if="mail.stepID !== -1">
                        <template  v-for="step in task.structure.steps">
                            <template v-if="step.stepID == mail.stepID">
                                <span class="caption greyType--text font-weight-medium">{{ step.description }}</span>
                            </template>
                        </template>
                    </v-flex>
                </v-layout>
        </template>

        <template v-if="taskMail && hasAccess && typeof mail.stepID !== 'undefined' && mail.stepID !== -1">

            <v-layout row justify-end v-if="intToBool(userInfo.gmailEnabled) || intToBool(userInfo.office365Enabled) || intToBool(userInfo.smtpAddressEnabled)">

                <!--<v-flex shrink class="text-xs-right pa-1">-->
                    <!--<v-tooltip top>-->
                        <!--<v-btn-->
                                <!--@click="taskMailReplyCompose(1)"-->
                                <!--slot="activator"-->
                                <!--flat-->
                                <!--icon-->
                                <!--color="primary"-->
                                <!--class="ma-0 pa-0"-->
                                <!--small-->
                        <!--&gt;-->
                            <!--<v-icon>reply</v-icon>-->
                        <!--</v-btn>-->
                        <!--<span>Reply To Mail Compose</span>-->
                    <!--</v-tooltip>-->
                <!--</v-flex>-->
              
              <v-flex shrink class="text-xs-right pa-1" v-if="hasAccess">
                <v-tooltip top>
                  <v-btn
                      :disabled="softBlock"
                      @click="downloadMail"
                      slot="activator"
                      flat
                      icon
                      color="secondary"
                      class="ma-0 pa-0"
                      small
                      :loading="downloadingMail"
                  >
                    <v-icon>download</v-icon>
                  </v-btn>
                  <span>Download Mail</span>
                </v-tooltip>
              </v-flex>
              <v-flex shrink class="text-xs-right pa-1">
                <v-tooltip top>
                  <v-btn
                      :disabled="softBlock"
                      @click="taskMailReplyCompose(1)"
                      slot="activator"
                      flat
                      icon
                      color="secondary"
                      class="ma-0 pa-0"
                      small
                      :loading="downloadingMessageHTML"
                  >
                    <v-icon>reply</v-icon>
                  </v-btn>
                  <span>Reply To Mail</span>
                </v-tooltip>
              </v-flex>
                <v-flex shrink class="text-xs-right pa-1">
                    <v-tooltip top>
                        <v-btn
                            :disabled="softBlock"
                                @click="taskMailReplyCompose(2)"
                                slot="activator"
                                flat
                                icon
                                color="secondary"
                                class="ma-0 pa-0"
                                small
                                :loading="downloadingMessageHTML"
                        >
                            <v-icon>reply_all</v-icon>
                        </v-btn>
                        <span>Reply To All</span>
                    </v-tooltip>
                </v-flex>
                <v-flex shrink class="text-xs-right pa-1">
                    <v-tooltip top>
                        <v-btn
                            :disabled="softBlock"
                                @click="taskMailReplyCompose(3)"
                                slot="activator"
                                flat
                                icon
                                color="secondary"
                                class="ma-0 pa-0"
                                small
                                :loading="downloadingMessageHTML"
                        >
                            <v-icon>forward</v-icon>
                        </v-btn>
                        <span>Forward Mail</span>
                    </v-tooltip>
                </v-flex>
            </v-layout>
        </template>

<!--EML Preview-->
        <v-dialog
                v-model="emlView"
                fullscreen
        >
            <v-card v-if="emlView" class="scrollable" style="height: 100vh">

                <v-layout column fill-height class="scrollable">
                    <v-flex shrink class="primary">
                        <v-layout row align-center>
                            <v-flex xs12>
                                <v-layout row align-center>
                                    <v-flex xs12 class="font-weight-regular white--text pa-2 title">
                                        Mail View
                                    </v-flex>
                                </v-layout>

                                <v-spacer></v-spacer>


                            </v-flex>
                            <v-flex shrink class="pa-2">
                                <v-btn
                                        icon
                                        flat
                                        color="white"
                                        class="pa-0 ma-0"
                                        @click="emlView = !emlView"
                                >
                                    <v-icon>close</v-icon>
                                </v-btn>
                            </v-flex>
                        </v-layout>
                    </v-flex>
                    <v-flex xs12 class="scrollable pa-2" v-if="emlView">
                        <e-m-l-viewer :filename="mail.mailFile" :load-from-filename="!emlAttachmentPreview" ref="emlView"/>
                    </v-flex>
                </v-layout>
            </v-card>
        </v-dialog>

<!--PDF Preview-->
        <p-d-f ref="pdfViewer"></p-d-f>


<!--        <v-layout row align-center v-if="typeof mail.stepID != 'undefined' && mail.stepID != null && mail.stepID != 0 && (intToBool(userInfo.gmailEnabled) || intToBool(userInfo.office365Enabled) || intToBool(userInfo.smtpAddressEnabled)) && typeof mail.stepID !== 'undefined' && mail.stepID !== -1">-->
<!--            <v-flex shrink class="text-xs-right pr-1 px-0">-->
<!--                <v-tooltip bottom>-->
<!--                    <v-btn-->
<!--                            @click="taskMailReplyCompose(1)"-->
<!--                            slot="activator"-->
<!--                            flat-->
<!--                            icon-->
<!--                            color="white"-->
<!--                            class="ma-0 pa-0"-->
<!--                            :loading="downloadingMessageHTML"-->
<!--                    >-->
<!--                        <v-icon>reply</v-icon>-->
<!--                    </v-btn>-->
<!--                    <span>Reply To Mail</span>-->
<!--                </v-tooltip>-->
<!--            </v-flex>-->
<!--            <v-flex shrink class="text-xs-right pr-1 px-0">-->
<!--                <v-tooltip bottom>-->
<!--                    <v-btn-->
<!--                            @click="taskMailReplyCompose(2)"-->
<!--                            slot="activator"-->
<!--                            flat-->
<!--                            icon-->
<!--                            color="white"-->
<!--                            class="ma-0 pa-0"-->
<!--                            :loading="downloadingMessageHTML"-->
<!--                    >-->
<!--                        <v-icon>reply_all</v-icon>-->
<!--                    </v-btn>-->
<!--                    <span>Reply To All</span>-->
<!--                </v-tooltip>-->
<!--            </v-flex>-->
<!--            <v-flex shrink class="text-xs-right pr-1 px-0">-->
<!--                <v-tooltip bottom>-->
<!--                    <v-btn-->
<!--                            @click="taskMailReplyCompose(3)"-->
<!--                            slot="activator"-->
<!--                            flat-->
<!--                            icon-->
<!--                            color="white"-->
<!--                            class="ma-0 pa-0"-->
<!--                            :loading="downloadingMessageHTML"-->
<!--                    >-->
<!--                        <v-icon>forward</v-icon>-->
<!--                    </v-btn>-->
<!--                    <span>Forward Mail</span>-->
<!--                </v-tooltip>-->
<!--            </v-flex>-->
<!--        </v-layout>-->


    </v-card>
</template>

<script>
    import { codeBaseMixin } from "../../codeBaseMixin";
    import { eventBus } from "../../main";
    import DocumentUploadDlg from '../../Tasks/TaskView/components/Documents/DocumentUploadDlg';
    import EMLViewer from "@/Mail/EML Viewer/EMLViewer";
    import GeneralMailContainerAttachment from "@/components/General/generalMailContainerAttachment";
    import PDF from "@/components/General/PDF/PDF";
    import {parseMail} from "@/codeFunctions";
    import {mapGetters} from "vuex";

    export default {
        mixins: [codeBaseMixin],
        components: {
            PDF,
            GeneralMailContainerAttachment,
            EMLViewer,
            appDocumentUploadDlg: DocumentUploadDlg,
        },
        props: {
            mailConfig: Object,
            userInfo: Object,
            mail: Object,
            task: Object,
            dashboard: {
                default: true,
                type: Boolean
            },
            taskBand: {
                default: false,
                type: Boolean
            },
            taskMail: {
                default: false,
                type: Boolean
            },
            fieldValue: {
                default: false,
                type: Boolean
            },
            taskValue: {
                default: false,
                type: Boolean
            },
            archiveMailFn: Function,
            noDrag: {
                type: Boolean,
                default: false
            },
            srcTaskAttachment: {
                type: Boolean,
                default: false
            },
            dense: {
                type: Boolean,
                default: false
            },
            disableSelfPreview: {
                type: Boolean,
                default: false
            },
            newMailFn: Function,
            readOnlyDash: {
                type: Boolean,
                default: false
            },
            externalEmlView: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                downloadingMessageHTML: false,
                showUpload: {show: false},
                showEmbeddedAttachments: false,
                composeMailReplyType: 1,
                messageBody: null,
                responsePending: false,
                disableReply: {
                    type: Boolean,
                    default: false
                },
                emlView: false,
                emlAttachmentPreview: false,
                downloadingMail: false
            }
        },
        watch: {
            emlView(val) {
                if (!val)
                    this.emlAttachmentPreview = false
            },
            showEmbeddedAttachments() {
                this.$emit('sizeChange')
            },
        },
        created() {
            eventBus.$on('taskMailLinked', this.taskMailLinked)
        },
        beforeDestroy() {
            eventBus.$off('taskMailLinked', this.taskMailLinked)
        },
        methods: {
          
          downloadMail() {
            this.downloadingMail = true
            this.$newReqDownloadFile(
                'post',
                `eml/download/${this.userInfo.entityID}`,
                {
                  filename: this.mail.mailFile,
                  route: this.smtpCache ? 'smtp' : (this.smtpMailboxCache ? 'mailbox' : (this.cache ? 'cache' : 'store'))
                },
                this.mail.mailFile,
            )
                .catch(e => {
                  // eslint-disable-next-line
                  console.log(e)
                  this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                })
                .finally(() => {
                  this.downloadingMail = false
                })
          },

            taskMailLinked(mail) {
                if (mail.mailID == this.mail.mailID) {
                    this.$forceUpdate();
                }
            },

            dragAtt(event, attachment) {
                event.dataTransfer.setData('text', JSON.stringify({
                    type: 'mailAttachment',
                    mail: {
                        mailID: this.mail.mailDBID,
                        mailFile: this.mail.mailFile
                    },
                    attachment: attachment
                }));
            },

            downloadAttachment(attachment, index) {
                let attComponent = this.$refs.attachment[index];
                attComponent.progress = 1;
                this.$newReqDownloadFile(
                        'post',
                        `eml/createMail/download/${this.userInfo.entityID}`,
                        {
                            ...attachment,
                            filename: attachment.attName,
                            source: 'mail',
                            srcMailPath: this.mail.mailFile,
                            srcMailLocation: 'store'
                        },
                        attachment.attName || 'attachment',
                        'auto',
                        true,
                        false,
                        (p) => {attComponent.progress = Math.floor((p.loaded / p.total) * 100)}

                )
                        .catch(() => {this.$snack.networkError()})
                        .finally(() => {attComponent.progress = 0;})
            },

            previewAttachment(attachment, index) {

                function getBlobText(blob) {
                    return new Promise((resolve, reject) => {
                        let reader = new FileReader();

                        reader.readAsText(blob, 'ISO-8859-1');

                        reader.onload = function() {
                            resolve(reader.result);
                        };

                        reader.onerror = function() {
                            reject(reader.error);
                        };
                    })
                }

                let downloadType = attachment.attMimeType === "application/pdf" ? 'url' : 'data';

                let attComponent = this.$refs.attachment[index];
                attComponent.progress = 1;
                this.$newReqDownloadFile(
                        'post',
                        `eml/createMail/download/${this.userInfo.entityID}`,
                        {
                            ...attachment,
                            filename: attachment.attName,
                            source: 'mail',
                            srcMailPath: this.mail.mailFile,
                            srcMailLocation: 'store'
                        },
                        attachment.attName || 'attachment',
                        downloadType,
                        true,
                        false,
                        (p) => {attComponent.progress = Math.floor((p.loaded / p.total) * 100)}

                )
                        .then(async (data) => {
                            if (downloadType === 'url') {
                                let viewer = this.$refs.pdfViewer;
                                viewer.localInit(data, attachment.attName || attachment.filename);
                            }
                            if (downloadType === 'data') {
                                attComponent.indeterminate = true;
                                attComponent.progress = 0;
                                parseMail(await getBlobText(data))
                                        .then(parsed => {
                                            this.emlAttachmentPreview = true;
                                            this.emlView = true;
                                            this.$nextTick(() => {
                                                this.$refs.emlView.inception(parsed)
                                            })
                                        })
                                        .catch(err => {
                                            console.log(err)
                                        })
                                        .finally(() => {
                                            attComponent.indeterminate = false;
                                            attComponent.progress = 0;
                                        })
                            }
                        })
                        .catch(() => {this.$snack.networkError()})
                        .finally(() => {attComponent.progress = 0;})
            },

            runPreviewAttachment(mailDownloadPath, attName, download) {
                let downloadAddress = '';
                downloadAddress = this.baseServerAddress + 'mailstore/' + this.userInfo.entityID + '/' + mailDownloadPath + '/' + attName;
                let win = window.open(downloadAddress, '_blank');
            },

            taskMailReplyCompose(composeType) {
                eventBus.$emit('newCompose', {composeType: composeType, srcMailFilename: this.mail.mailFile})
            },

            showPreview(show) {
                if (show)
                    if (!this.externalEmlView) {
                        this.emlView = true;
                        console.log("Not External")
                    } else {
                        console.log("External")
                        this.$emit('viewMail', this.mail.mailFile)
                    }
                else
                    this.$snack.error('Access Denied')
            },

            uploadFinished(data) {
                this.$emit('uploadDone', data)
            },

            drag: function (dragType, data) {

                if (!this.srcTaskAttachment) {
                    eventBus.$emit(dragType + 'Drag', data);
                } else {
                    eventBus.$emit('SRC' + dragType + 'Drag', data);
                }
            },

            removeAutolinkNotification() {
                this.responsePending = true;
                this.fetch({url:'/notifications/removeAutolinkNotification/' + this.userInfo.entityID + '/' + this.mail.messageID + '/' + this.userInfo.userID, method: 'PATCH'})
                    .then(() => {
                        this.responsePending = false;
                        this.mail.autoLinkNotification = 0;
                        eventBus.$emit('removeAutolinkNotification', this.mail.mailDBID)
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e);
                        this.responsePending = false;
                    });
                this.$forceUpdate();
            },

            archiveMail() {
                this.archiveMailFn(this.mail);
                this.$forceUpdate()
            },

            showMail() {
                eventBus.$emit('previewMail', this.mail)
            },

            clearNotification() {
                this.responsePending = true;
                this.fetch({url:'/notifications/removeAutolinkNotification/' + this.userInfo.entityID + '/' + this.mail.messageID + '/' + this.userInfo.userID, method: 'PATCH'})
                    .then(() => {
                        this.responsePending = false;
                        this.$emit('removed')
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e)
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                        this.responsePending = false;
                    });
            },
            setAttachments() {
                try {
                    this.mail.attachments = JSON.parse(this.mail.attachments);
                } catch (e) {
                    this.mail.attachments = [];
                }
            }
        },
        computed: {
            ...mapGetters("userInfo", ["softBlock"]),
            mailAttachments() {
                if (this.showEmbeddedAttachments) {
                    return this.mail.attachments
                } else {
                    if (typeof this.mail.attachments == 'object') {
                        return this.mail.attachments.filter(obj => !this.embeddedMimeTypes.includes(obj.attMimeType) && !(obj.attName || '').includes('.blob'))
                    } else {
                        try {
                            this.setAttachments();
                            return this.mail.attachments.filter(obj => !this.embeddedMimeTypes.includes(obj.attMimeType) && !(obj.attName || '').includes('.blob'))
                        } catch (e) {
                            console.log(e)
                            return []
                        }
                    }
                }
            },


            mailPath() {
                return this.mail.fk_gmail_group_mailbox_folder == 0
                    ? this.mail.fk_import_user + '-' + this.mail.mailID
                    : `${this.mail?.fk_gmail_group_mailbox_folder}-${this.mail?.mailID}`;
            },
            mailClass() {
                if (this.dashboard) {
                    return 'dashboardMail'
                }
                if (this.taskBand) {
                    return 'dashboardMail taskBar'
                }
                if (this.taskMail) {
                    return ''
                }
                return ''
            },
            hasAccess() {
                if (typeof this.mail.securityLookup != 'undefined' && this.mail.securityLookup != null){
                    let groupsWithAccess = [];
                    try {
                        groupsWithAccess = JSON.parse(this.mail.securityLookup);
                        if (typeof groupsWithAccess == 'number') {
                            groupsWithAccess = [groupsWithAccess]
                        }
                    } catch (e) {
                        return true
                    }
                    return this.userInfo.userGroups.filter(element => groupsWithAccess.includes(element)).length <= 0
                } else {
                    return true
                }
            },
            embeddedAttachmentLength() {
                try {
                    return this.mail.attachments.filter(obj => this.embeddedMimeTypes.includes(obj.attMimeType) || (obj.attName || '').includes('.blob')).length
                } catch (e) {
                    // eslint-disable-next-line
                    console.log(e)
                    return 0
                }

            },

            mailFrom() {
                try {
                    return (this.mail.from.split('<').filter(str => str !== '').shift() || '').trim().replace(/>/g,"")
                } catch (e) {
                    return null;
                }
            },

            mailTo() {
                try {
                    return (this.mail.to.split('<').filter(str => str !== '').shift() || '').trim().replace(/>/g,"")
                } catch (e) {
                    console.log(e)
                    return null;
                }
            }
        },
    }
</script>

<style scoped>

    .dashboardMail {
        border-left: 5px solid var(--v-tOrange-base);
    }

    .taskBar:hover {
        cursor: pointer;
        background: var(--hover-item-background);
    }

</style>
