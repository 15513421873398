<template>
    <v-layout row fill-height style="overflow-y: auto; background: var(--page-back)">
        <v-flex xs12 fill-height v-if="workflowViewOption == 0 || workflowViewOption == 1" style="overflow-y: auto">
            <app-workflow
                    :user-info="userInfo"
                    :selected-workflow="selectedWorkflow"
                    :select-lists="selectLists"
                    :folder-groups="folderGroups"
                    :show-template-editor-fn="showTemplateEditorFn"
                    :workflow-view-option="workflowViewOption"
                    @workflowViewOption="$emit('workflowViewOption', $event)"
                    :local-data="localData"
                    :show-sync="showSync"
                    :get-task-header="getTaskHeader"
            >
            </app-workflow>
        </v-flex>
        <v-flex xs12 fill-height v-if="workflowViewOption == 2" style="overflow-y: auto" class="pr-1 pl-1 py-1">
            <app-security
                    :user-info="userInfo"
                    :selected-workflow="selectedWorkflow"
                    :workflow-view-option="workflowViewOption"
            />
        </v-flex>
        <v-flex xs12 fill-height v-if="workflowViewOption == 3" style="overflow-y: auto" class="pr-1 pl-1 py-1">
            <app-manage
                    :user-info="userInfo"
                    :selected-workflow="selectedWorkflow"
                    :workflow-view-option="workflowViewOption"
                    :local-data="localData"
            />
        </v-flex>
    </v-layout>
</template>

<script>

    import {codeBaseMixin} from "../../../../codeBaseMixin";

    import Workflow from './Workflow/Workflow';
    import Security from "./Security/Security";
    import Manage from "./Manage/Manage";

    export default {
        components: {
            appManage: Manage,
            appWorkflow: Workflow,
            appSecurity: Security
        },

        props: {
            userInfo: Object,
            workflowViewOption: Number,
            selectedWorkflow: Object,
            selectLists: Array,
            folderGroups: Array,
            showTemplateEditorFn: Function,
            localData: Object,
            showSync: Boolean,
            getTaskHeader: Function,
        }
    }
</script>

<style scoped>

</style>
