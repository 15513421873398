<template>
    <div>
        <draggable
                :list="reportGroupings"
                group="groupingFields"
                @start="drag=true"
                @end="drag=false"
                @change="log"
                ghostClass="ghost"
                v-bind="{handle:'.handle'}"
        >
            <div v-for="grouping in reportGroupings" class="hover-item cursor">
                <v-layout row align-center class="hide-parent">
                    <v-flex shrink class="pl-2">
                        <v-btn
                                small
                                icon
                                flat
                                class="pa-0 ma-0 handle"
                                color="grey"
                                style="cursor: move"
                        >
                            <v-icon small>drag_indicator</v-icon>
                        </v-btn>
                    </v-flex>
<!--Field Order Icon-->
                    <v-flex shrink class="pl-1">
                        <v-btn
                                small
                                icon
                                flat
                                class="pa-0 ma-0"
                                color="blue"
                        >
                            <span class="subheading font-weight-bold">{{grouping.index + 1 }}</span>
                        </v-btn>
                    </v-flex>
<!--Field Type Icon-->
                    <v-flex shrink class="pl-1">
                        <v-btn
                                small
                                icon
                                flat
                                class="pa-0 ma-0"
                                color="primary"
                        >
                            <v-icon>
                                {{['wd','dd','sd'].includes(grouping.field.fieldType) ? 'event_available' : (['wu','du','su'].includes(grouping.field.fieldType) ? 'person' : 'check_box')}}
                            </v-icon>
                        </v-btn>
                    </v-flex>
                    <v-flex xs12 class="pl-3 py-1 pr-2  caption">
<!--Main Description-->
                        <div class="font-weight-bold ">{{grouping.field.mainDescription}}<span class="caption font-weight-bold greyType--text">{{['wd','dd','sd'].includes(grouping.field.fieldType) ? ' (Date)' : (['wu','du','su'].includes(grouping.field.fieldType) ? ' (User)' : '')}}</span></div>
<!--Sub Description-->
                        <div class="greyType--text">{{grouping.field.subDescription}}</div>
                    </v-flex>
<!--Edit Alias-->
                    <v-flex shrink class="pr-2 hide-child">
                        <v-tooltip top>
                            <v-btn
                                    small
                                    icon
                                    flat
                                    class="pa-0 ma-0"
                                    color="primary"
                                    slot="activator"
                                    @click="editAlias(grouping.field)"
                            >
                                <v-icon>edit</v-icon>
                            </v-btn>
                            <span>Edit Field Description</span>
                        </v-tooltip>
                    </v-flex>
<!--Create Aggregate-->
                    <v-flex shrink class="pr-2 hide-child" v-if="grouping.field.aggregate == null && grouping.field.allowAggregate">
                        <v-tooltip top>
                            <v-btn
                                    small
                                    icon
                                    flat
                                    class="pa-0 ma-0"
                                    color="secondary"
                                    slot="activator"
                                    @click="addAggregateRunStart(grouping.field)"
                            >
                                <v-icon>functions</v-icon>
                            </v-btn>
                            <span>Add an Aggregate based on this Field</span>
                        </v-tooltip>
                    </v-flex>
<!--Remove-->
                    <v-flex shrink class="pr-2 hide-child">
                        <v-tooltip top>
                            <v-btn
                                    small
                                    icon
                                    flat
                                    class="pa-0 ma-0"
                                    color="red"
                                    slot="activator"
                                    @click="$emit('remove', grouping.field)"
                            >
                                <v-icon>delete</v-icon>
                            </v-btn>
                            <span>Remove Field from Report</span>
                        </v-tooltip>
                    </v-flex>
                </v-layout>
                <v-divider></v-divider>
            </div>
        </draggable>

        <app-input-dlg
                v-if="showEditAlias"
                :show-dialog="showEditAlias"
                title="Set Field Alias"
                body="Please provide an alias for the report field:"
                :cancel-button="true"
                :show-input-box="true"
                :buttons="['Update']"
                :default-input="editAliasField.mainDescription"
                :call-backs="[ editAliasDone ]"
                @dismiss="showEditAlias = false"
        />
        <app-input-dlg
                :show-dialog="showAddAggregate"
                title="Add Aggregate"
                :cancel-button="true"
                :show-input-box="false"
                @dismiss="showAddAggregate = false"
                :body-padding="false"
        >
            <template slot="body">
                <div class="pa-2" v-if="showAddAggregate">
                    <div :class="headingClass">
                        Create an Aggregate for Field {{addAggregateField.mainDescription}}:
                    </div>
                    <div :class="itemClass">
                        <v-autocomplete
                                :items="aggregates"
                                v-model="selectedEditAggregate"
                                label="Select Aggregate"
                                single-line
                                color="primary"
                                hide-details
                                style="width: 100%"
                                class="ma-0 pa-0"
                                dense
                        ></v-autocomplete>
                    </div>
                </div>
            </template>
            <template slot="actions">
                <v-btn small color="secondary" class="ma-0" @click="addAggregate" :disabled="selectedEditAggregate == null"><v-icon left>add</v-icon>Add Aggregate</v-btn>
            </template>
        </app-input-dlg>
    </div>
</template>

<script>
    import {codeBaseMixin} from "../../../codeBaseMixin";
    import InputDlg from "../../../components/General/InputDlg";
    import draggable from "vuedraggable";

    export default {
        components: {appInputDlg: InputDlg, draggable,},
        mixins: [codeBaseMixin],
        props: {
            selectedReport: Object,
            userInfo: Object,
            steps: Array,
            fields: Array,
            flatFields: Object,
            manageFields: Array,
            dataSets: Array,
            allDataSetFields: Array,
            allWorkflowStatuses: Array,
            selectedFields: Array,
            groupingOrder: Array,
        },
        data() {
            return {
                editAliasField: null,
                showEditAlias: false,
                aggregates: ["SUM", "MAX", "MIN", "COUNT", "AVG", "VARIANCE"],
                availableAggregates: null,
                addAggregateField: null,
                selectedEditAggregate: null,
                showAddAggregate: false
            }
        },
        methods: {
            log: function(evt) {
                if (typeof evt.moved != 'undefined') {
                    let tArr = this.reportGroupings.filter(() => true).map(obj => {return JSON.parse(JSON.stringify(obj))});
                    this.$emit('newGroupingOrder', tArr.map(obj => {return obj.obj}))
                }
            },
            editAlias(field) {
                this.editAliasField = field;
                this.showEditAlias = true;
            },
            editAliasDone(newAlias) {
                if (!this.blankString(newAlias)) {
                    this.showEditAlias = false;
                    this.$emit('editAlias', {alias: newAlias, field: this.editAliasField})
                } else {
                    this.showSnack('Error', 'Alias Not Set', 'Close', 'red');
                    this.showEditAlias = false;
                }
            },
            addAggregateRunStart(field) {
                this.addAggregateField = field;
                let existingFieldAggregates = this.selectedFields.filter(obj => obj.id == field.id && obj.fieldType == field.fieldType && obj.dsSelect == field.dsSelect && obj.aggregate != null).map(obj => {return obj.aggregate})
                this.availableAggregates = this.aggregates.filter(obj => !existingFieldAggregates.includes(obj));
                this.selectedEditAggregate = null;
                this.showAddAggregate = true
            },
            addAggregate() {

                let tField = {
                    id: this.addAggregateField.id,
                    fieldType: this.addAggregateField.fieldType,
                    aggregate: this.selectedEditAggregate,
                    dsSelect: this.addAggregateField.dsSelect,
                    alias: null
                };

                this.$emit('add', tField);
                this.showAddAggregate = false;
            }
        },
        computed: {
            reportGroupings() {
                return this.groupingOrder.map((obj, i) => {
                    let tValue = {
                        index: i,
                        obj: obj,
                    };

                    tValue.field = this.manageFields.reduce((returnItem, item) => {
                        if (
                            item.aggregate == null
                            && item.fieldType == obj.fieldType
                            && item.id == obj.id
                            && (typeof item.dsSelect != 'undefined' ? item.dsSelect == obj.dsSelect : true)
                        ) {
                            returnItem = item
                        }
                        return returnItem
                    }, {mainDescription: '-', subDescription: '-'})
                    return tValue;
                })
            }
        }
    }
</script>

<style scoped>

</style>
