<template>
    <v-treeview
            :items="folders"
            item-text="displayName"
            item-key="id"
            item-children="children"
            hoverable
            dense
            :load-children="fnLoadSubFolders"
            :transition="true"
            style="cursor: pointer"
            color="secondary"
    >
        <template slot="prepend" slot-scope="{ item, open, leaf }">
            <v-icon
                    @dragover="$event.preventDefault();"
                    @drop="$emit('droppedLabel', item)"
                    @click="$emit('labelSelected', item)"
                    :class="item.id == selectedLabel.id ? 'primary--text' : ''"
            >
                {{ item.icon }}
            </v-icon>
        </template>

        <template slot="label" slot-scope="{ item, open, leaf }">
            <!--@dragover="allowDrop"-->
            <!--@drop="drop($event, item)"-->
            <div
                    style="width: 100%;"
                    @dragover="$event.preventDefault();"
                    @drop="$emit('droppedLabel', item)"
                    @click="$emit('labelSelected', item)"
            >
                <span
                        :class="item.id == selectedLabel.id ? 'font-weight-medium secondary--text' : ''">
                    {{ item.displayName }}
                </span>
            </div>

        </template>
    </v-treeview>
</template>

<script>
    export default {
        props: {
            userInfo: Object,
            inboxId: String,
            folders: {
                type: Array,
                default: () => {
                    return []
                }
            },
            fnLoadSubFolders: Function,
            selectedLabel: {
                type: Object,
                default: () => {
                    return {
                        id: null
                    }
                }
            },
        },
        data() {
            return {
                treeValue: null
            }
        },
    }
</script>

<style scoped>

</style>
