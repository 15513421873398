import {arrSplice, objectifyArr} from "@/codeFunctions";
import {newReq} from "@/axiosRequest";
import {format, getDayOfYear, getYear} from "date-fns";
import {waitForEvent} from "@/feedSocket";
import {networkError} from "@/snack";
import {CalendarEvent} from "./calendarClass"

function upsertItem(state, key, item) {
    let bAdded = false
    if (!Array.isArray(state[key]))
        state[key] = []

    for (const sItem of state[key]) {
        if (sItem.id === item.id) {
            Object.assign(sItem, item)
            bAdded = true;
            return true
        }
    }
    if (!bAdded)
        state[key].push(item)
    return true
}

let curDate = new Date();

setInterval(() => {
    curDate = new Date()
}, 20000)


export default {
    namespaced: true,
    state() {
        return {
            groups: [],
            templates: [],
            events: [],
        }
    },
    mutations: {
        upsertToGroup(state, payload) {
            if (Array.isArray(payload)) {
                for (const item of payload) {
                    upsertItem(state, 'groups', item)
                }
            } else {
                upsertItem(state, 'groups', payload)
            }
        },

        upsertObject(state, payload) {
            for (const arrKey of Object.keys(payload)) {
                if (typeof state[arrKey] != 'undefined') {
                    let stateVar = state[arrKey]
                    let upsertArr = payload[arrKey]

                    let addItems = upsertArr.filter(val => typeof val === 'object')
                    let spliceItems = upsertArr.filter(val => typeof val !== 'object')

                    // Add Items To The State
                    for (const item of addItems)
                        upsertItem(state, arrKey, item)

                    // Splice Items From Arr
                    arrSplice(stateVar, obj => spliceItems.includes(obj.id))
                }
            }
        }
    },
    actions: {
        upsertToGroup(context, payload) {
            context.commit('upsertToGroup', payload)
        },

        upsert(context, payload) {
            context.commit('upsertObject', payload)
        }
    },
    getters: {
        CalendarGroupClass(state, getters, rootState, rootGetters) {
            return class {
                constructor(obj) {
                    Object.assign(this, obj);
                    this.id = obj.id || null;
                    this.description = obj.description || null;
                    this.subscribers = obj.subscribers || [];
                    this.editors = obj.editors || [];
                    this.allUsersEditors = obj.allUsersEditors || false;
                    this.allUsersSubscribed = obj.allUsersSubscribed || false;
                    this.onlyEditorsCanPublish = obj.onlyEditorsCanPublish || false;
                    this.groups = obj.groups || [];
                    this.users = obj.users || [];
                    this.loading = false;

                }
                get usersRef() {
                    return this.users.map(userID => {
                        return rootGetters.usersRef[userID]
                    })
                }
                get editorsRef() {
                    return this.editors.map(userID => {
                        return rootGetters.usersRef[userID]
                    })
                }
                get subscribersRef() {
                    return this.subscribers.map(userID => {
                        return rootGetters.usersRef[userID]
                    })
                }
                get groupsRef() {
                    return this.groups.map(groupID => {
                        return rootGetters.userGroupsRef[groupID]
                    })
                }
                get hasAccess() {
                    let userID = rootGetters["userInfo/userInfo"].userID;

                    if (this.users.includes(userID)) // Check Shared User
                        return true
                    if (this.editors.includes(userID)) // Check Editor
                        return true

                    for (const userGroup of this.groupsRef) {// Check Shared User Group
                        if (userGroup.users.includes(userID))
                            return true
                    }
                    return false
                }

                get canPublish() {
                    let hasAccess = this.hasAccess;
                    if (!hasAccess)
                        return false
                    if (hasAccess && !this.onlyEditorsCanPublish)
                        return true

                    let userID = rootGetters["userInfo/userInfo"].userID;
                    return this.editors.includes(userID) && this.onlyEditorsCanPublish
                }

                get groupInitials() {
                    return this.description.split(' ').map(str => {return str.substr(0,1)}).join('')
                }
                get subscribed() {
                    return this.subscribers.includes(rootGetters["userInfo/userInfo"].userID)
                }

                async updateSubscription() {
                    try {
                        this.loading = true;
                        let updateID = await newReq('PATCH', `cal/groups/subscription/${rootGetters['userInfo/userInfo'].entityID}/${this.id}/${rootGetters['userInfo/userInfo'].userID}`)
                        let payload = await waitForEvent(updateID)
                        console.log('PAYLOAD', payload)
                    } catch (e) {
                        console.log(e)
                        networkError()
                    } finally {
                        this.loading = false
                    }
                }

                update() {
                    return newReq('PATCH', `cal/groups/${rootGetters['userInfo/userInfo'].entityID}`, this)
                }
            }
        },

        calendarEvents(state, getters) {

            // for (let k = 0; k < state.events.length; ++k) {
            //     if (!(state.events[k] instanceof CalendarEvent)) {
            //         state.events[k] = new CalendarEvent(state.events[k])
            //     } else {
            //     }
            // }

            return state.events.map(event => new CalendarEvent(event))
        },

        calendarEventsRef(_, getters) {
            const res = objectifyArr(getters.calendarEvents, "id")
            return res
        },
        calendarEventsWithAccess(state, getters) {
            return getters.calendarEvents.filter(obj => obj.hasAccess)
        },
        calendarGroups(state, getters) {
            return state.groups.map((calendarGroup) => {return new getters.CalendarGroupClass(calendarGroup)})
        },
        calendarGroupsRef(state, getters) {
            return objectifyArr(getters.calendarGroups, 'id')
        },
        calendarGroupsWithAccess(state, getters, rootState, rootGetters) {
            return getters.calendarGroups.filter(group => group.hasAccess)
        },

        calendarGroupsCanPublish(state, getters, rootState, rootGetters) {
            return getters.calendarGroups.filter(group => group.canPublish)
        },

        calendarTemplates(state) {
            return state.templates.sort((a, b) => a.title > b.title ? 1 : -1)
        },

        calendarEventsWithPendingNotifications(state, getters) {
            const res = getters.calendarEvents
            console.time("Filter")
            const filteredRes = res.filter(event => event.start < curDate && event.pendingNotification)
            console.timeEnd("Filter")
            return filteredRes
        }
    }
}
