import { render, staticRenderFns } from "./MailNotification.vue?vue&type=template&id=bae51b14&scoped=true"
import script from "./MailNotification.vue?vue&type=script&lang=js"
export * from "./MailNotification.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bae51b14",
  null
  
)

export default component.exports