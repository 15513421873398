<template>
    <v-layout column fill-height style="overflow-y: auto; overflow-x: hidden" class="">


        <v-flex xs12 style="overflow-y: auto; overflow-x: hidden" v-if="loading" class="text-xs-center pa-2">
            <v-progress-circular
                    :size="40"
                    :width="5"
                    color="primary"
                    indeterminate
            />
        </v-flex>
        <template v-else>
            <v-flex xs12 style="overflow-y: auto">
                <v-layout row style="overflow-y: auto" fill-height>
                    <v-flex xs6 style="overflow-y: auto" fill-height>
                        <v-layout column style="overflow-y: auto" fill-height>
                            <v-flex shrink class="body-2 primary--text pa-1 pt-2">
                                Statuses
                                <v-divider class="mt-2"></v-divider>
                            </v-flex>
                            <v-flex shrink class="pr-2 pl-1 pt-0">
                                <!--Add New Status-->
                                <v-btn
                                        block
                                        color="secondary"
                                        outline
                                        small
                                        @click="showAddStatus = true"
                                        :disabled="addingStatus"
                                        :loading="addingStatus"
                                >
                                    <v-icon left>add</v-icon>
                                    Add New Status
                                </v-btn>
                                <app-input-dlg
                                        :show-dialog="showAddStatus"
                                        title="Add New Workflow Status"
                                        body="Please provide a Description for the New Status:"
                                        :cancel-button="true"
                                        :show-input-box="true"
                                        :buttons="['Add Status']"
                                        :call-backs="[ newStatus ]"
                                        @dismiss="showAddStatus = false"
                                />
                            </v-flex>
                            <v-flex xs12 style="overflow-y: auto" fill-height class="pa-1 pr-2">
                                <v-list dense>
                                    <draggable v-model="statusFieldsOrder" group="people" @start="drag=true" @end="drag=false">
                                        <v-list-tile
                                                v-for="item in statusFieldsOrder"
                                                shrink
                                                :key="item.statusID"
                                                @click="selectedStatus = item"
                                                class="hide-parent"
                                                :class="selectedStatus != null && selectedStatus.statusID == item.statusID ? 'selected-item' : ''"
                                        >
                                            <v-list-tile-action>
                                                <v-tooltip top>
                                                    <v-btn
                                                            icon
                                                            small
                                                            flat
                                                            color="primary"
                                                            class="pa-0 ma-0"
                                                            slot="activator"
                                                            @click="updateVisibility(item)"
                                                    >
                                                        <v-icon>{{ item.hidden == 1 ? 'check_box_outline_blank' : 'check_box'}}</v-icon>
                                                    </v-btn>
                                                    <span>{{ item.hidden == 1 ? 'Mark As Visible' : 'Mark as Hidden'}}</span>
                                                </v-tooltip>
                                            </v-list-tile-action>
                                            <v-list-tile-content>
                                                <v-list-tile-title :class="selectedStatus != null && selectedStatus.statusID == item.statusID ? 'font-weight-bold' : ''">
                                                    {{item.description}}
                                                </v-list-tile-title>
                                            </v-list-tile-content>
                                            <v-list-tile-action class="text-xs-right px-1">
                                                <v-tooltip top class="hide-child">
                                                    <v-btn
                                                            icon
                                                            small
                                                            flat
                                                            color="primary"
                                                            class="pa-0 ma-0"
                                                            slot="activator"
                                                            @click="selectedStatus = item; showRenameStatus = true"
                                                    >
                                                        <v-icon>edit</v-icon>
                                                    </v-btn>
                                                    <span>Rename Status</span>
                                                </v-tooltip>
                                            </v-list-tile-action>
                                            <v-list-tile-action class="text-xs-right px-1">
                                                <v-tooltip top>
                                                    <v-btn
                                                            icon
                                                            small
                                                            flat
                                                            :color="item.authGroups.length > 0 ? 'primary' : 'grey'"
                                                            class="pa-0 ma-0"
                                                            slot="activator"
                                                            @click="setStatusSecurity(item)"
                                                    >
                                                        <v-icon>security</v-icon>
                                                    </v-btn>
                                                    <span>{{item.authGroups.length > 0 ? 'Status is limited' : 'Open to all users'}}</span>
                                                </v-tooltip>
                                            </v-list-tile-action>
                                            <v-list-tile-action class="text-xs-right px-1">
                                                <v-tooltip top>
                                                    <v-btn
                                                            icon
                                                            small
                                                            flat
                                                            :color="intToBool(item.default) ? 'secondary' : 'grey'"
                                                            class="pa-0 ma-0"
                                                            slot="activator"
                                                            @click="setAsDefaultStatus(item)"
                                                    >
                                                        <v-icon>{{intToBool(item.default) ? 'done_all' : 'done'}}</v-icon>
                                                    </v-btn>
                                                    <span>Set As Default Status</span>
                                                </v-tooltip>
                                            </v-list-tile-action>
                                        </v-list-tile>
                                    </draggable>
                                </v-list>
                            </v-flex>
                        </v-layout>
                    </v-flex>
                    <app-input-dlg
                            :show-dialog="showRenameStatus"
                            title="Rename Status"
                            body="Type a New Description:"
                            :cancel-button="true"
                            :show-input-box="true"
                            :buttons="['Rename']"
                            :call-backs="[ updateStatusName ]"
                            @dismiss="showRenameStatus = false"
                            :default-input="selectedStatus != null ? selectedStatus.description : ''"
                    />
                    <v-flex xs6 style="overflow-y: auto" fill-height>
                        <v-layout column style="overflow-y: auto" fill-height>
                            <v-flex shrink class="body-2 primary--text pa-1 pt-2">
                                Configure Status
                                <v-divider class="mt-2"></v-divider>
                            </v-flex>
                            <v-flex xs12 style="overflow-y: auto" fill-height class="pa-1 pr-2 caption text-xs-center greyType--text" v-if="selectedStatus == null">
                                *Please select a status to configure.
                            </v-flex>
                            <v-flex xs12 style="overflow-y: auto" fill-height class="pa-1 pr-2" v-else>
                                <app-configure-status
                                        :selected-status="selectedStatus"
                                        :user-info="userInfo"
                                        :update-visibility-fn="updateVisibility"
                                        :user-groups="userGroups"
                                />
                            </v-flex>
                        </v-layout>
                    </v-flex>
                </v-layout>
            </v-flex>
        </template>

        <app-input-dlg
                :show-dialog="showSetStatusSecurity"
                title="Select Authorized User Groups"
                :cancel-button="true"
                :show-input-box="false"
                @dismiss="showSetStatusSecurity = false"
                :body-padding="false"
                ref="statusSecurityInput"
        >
            <template slot="body">
                <div v-if="showSetStatusSecurity">
                    <multi-select-list
                            ref="setSecurityList"
                            :items="localData.userGroups"
                            item-key="id"
                            headline-key="description"
                            :return-object="false"
                            :multiple="true"
                            :show-check-boxes="true"
                            :show-action-buttons="false"
                            :showFilter="true"
                            v-model="selectedUserGroups"
                            :dark="false"
                            filter-label="Filter User Groups"
                            :height="400"
                    ></multi-select-list>
                </div>
            </template>
            <template slot="actions">
                <v-btn
                        small
                        class="px-2 py-0"
                        color="secondary"
                        @click="saveStatusSecurity"
                        :disabled="updatingStatusSecurity"
                        :loading="updatingStatusSecurity"
                >
                    Save Changes
                </v-btn>
            </template>
        </app-input-dlg>
    </v-layout>
</template>

<script>

    import {codeBaseMixin} from "../../../../../../codeBaseMixin";
    import InputDlg from "../../../../../../components/General/InputDlg";

    import draggable from 'vuedraggable';
    import ConfigureStatus from "./ConfigureStatus";
    import MultiSelectList from "../../../../../../components/Multi Select List/MultiSelectList";

    export default {
        components: {
            MultiSelectList,
            appConfigureStatus: ConfigureStatus,
            appInputDlg: InputDlg,
            draggable
        },
        mixins: [codeBaseMixin],
        props: {
            userInfo: Object,
            workflowData: Object,
            localData: Object
        },
        data() {
            return {
                loading: false,
                showAddStatus: false,
                addingStatus: false,

                statusFields: [],
                userGroups: [],

                selectedStatus: null,

                showRenameStatus: false,
                showSetStatusSecurity: false,
                selectedUserGroups: null,
                updatingStatusSecurity: false
            }
        },
        mounted() {
            this.initStatusDetail();
        },
        methods: {
            setStatusSecurity(status) {
                // TODO Set Security Here
                // setSecurityList
                this.$refs.statusSecurityInput.refVar(status);
                this.selectedUserGroups = status.authGroups;
                this.showSetStatusSecurity = true;
            },

            saveStatusSecurity() {

                let status = this.$refs.statusSecurityInput.refVar();
                this.updatingStatusSecurity = true;

                this.fetch({method: 'PATCH', url:`/workflowEditor/status/updateStatusSecurity/${this.userInfo.entityID}/${status.statusID}`, body: this.selectedUserGroups})
                    .then(() => {
                        status.authGroups = this.selectedUserGroups;
                        this.showSetStatusSecurity = false;
                        this.updatingStatusSecurity = false;
                        this.showSnack('Info', 'Changes Saved', 'Close', 'primary')
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e)
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                        this.updatingStatusSecurity = false;
                    })
            },

            setAsDefaultStatus(item) {
                // Check Fore An Old Status
                this.selectedStatus = item;
                let tOldStatusID = this.statusFields.reduce((curDefaultStatus, status) => {
                    if (this.intToBool(status.default)) {
                        curDefaultStatus = status.statusID
                    }
                    return curDefaultStatus
                }, 0);

                let tNewStatusID = this.intToBool(item.default) ? 0 : item.statusID;

                this.fetch({method: 'PATCH', url:`/workflowEditor/status/updateDefault/${this.userInfo.entityID}/${tNewStatusID}/${tOldStatusID}`})
                    .then(() => {
                        this.statusFields.forEach(status => {
                            if (status.statusID === tNewStatusID) {
                                status.default = 1
                            } else {
                                status.default = 0
                            }
                        });
                        this.showSnack('Info', 'Changes Saved', 'Close', 'primary')
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e)
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                        status.hidden = this.boolToInt(!this.intToBool(status.hidden));
                    })





            },

            updateStatusName(description) {
                this.selectedStatus.description = description;
                this.showRenameStatus = false;
                if (description != null && description != '') {
                    this.fetch({method: 'PATCH', url:`/workflowEditor/status/updateDescription/${this.userInfo.entityID}/${this.selectedStatus.statusID}/${this.workflowData.id}`, body: JSON.stringify({description: description})})
                        .then((data) => {
                            this.showSnack('Info', 'Changes Saved', 'Close', 'primary')
                        })
                        .catch((e) => {
                            // eslint-disable-next-line
                            console.log(e)
                            this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
                        })
                } else {
                    this.showSnack('Error', 'A description is required', 'Close', 'red')
                }
            },

            updateVisibility(status) {
                status.hidden = this.boolToInt(!this.intToBool(status.hidden));
                this.fetch({method: 'PATCH', url:`/workflowEditor/status/updateVisibility/${this.userInfo.entityID}/${status.statusID}/${status.hidden}/${this.workflowData.id}`})
                    .then((data) => {
                        this.showSnack('Info', 'Changes Saved', 'Close', 'primary')
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e)
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                        status.hidden = this.boolToInt(!this.intToBool(status.hidden));
                    })
            },

            newStatus(description) {
                if (description != null && description != '') {
                    this.showAddStatus = false;
                    this.addingStatus = true;
                    this.fetch({method: 'POST', url:`/workflowEditor/status/add/${this.userInfo.entityID}/${this.workflowData.id}`, body: JSON.stringify({description : description})})
                        .then((data) => {
                            this.statusFields.push(data);
                            this.addingStatus = false;
                            this.selectedStatus = data;
                            this.showSnack('Info', 'Status Added', 'Close', 'primary')
                        })
                        .catch((e) => {
                            // eslint-disable-next-line
                            console.log(e);
                            this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
                            this.addingStatus = false;
                        })
                } else {
                    this.showSnack('Error', 'A status description is required', 'Close', 'red')
                }
            },

            initStatusDetail() {
                this.loading = true;
                this.fetch({method: 'GET', url:`/workflowEditor/status/info/${this.userInfo.entityID}/${this.workflowData.id}`})
                    .then((data) => {
                        this.statusFields = data.statuses;
                        this.userGroups = data.groups;
                        this.loading = false;
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e);
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
                        this.loading = false;
                    })
            },

            fireOrderSave() {

                let newOrder = this.statusFields.map((obj) => {
                    return {
                        id: obj.statusID,
                        order: obj.order
                    }
                });

                this.fetch({method: 'PATCH', url:`/workflowEditor/status/saveOrder/${this.userInfo.entityID}`, body: JSON.stringify({list: newOrder})})
                    .then((data) => {

                        this.showSnack('Info', 'Order Saved', 'Close', 'primary')
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e)
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                    })

            }
        },

        computed: {
            statusFieldsOrder: {
                get: function () {
                    return this.statusFields
                },
                set: function (newValue) {
                    if (newValue.length <= 0) {
                        return []
                    } else {
                        this.statusFields = newValue.map((item, order) => {
                            item.order = order;
                            return item
                        });
                        this.fireOrderSave();
                    }
                }
            },
        }
    }
</script>

<style scoped>

</style>
