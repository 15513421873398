<template>
    <v-tooltip top>
        <v-btn
                small
                icon
                flat
                color="secondary"
                slot="activator"
                class="pa-0 ma-0"
                @click="showAddWorkflowGroup = true"
        >
            <v-icon>add</v-icon>
        </v-btn>
        <span>Add New Workflow Group</span>
        <app-input-dlg
                :show-dialog="showAddWorkflowGroup"
                title="Add Workflow Group"
                :body="'Please provide a Description for the New Workflow Group that you would like to add to: ' + workSpace.displayName"
                :cancel-button="true"
                :show-input-box="true"
                :buttons="['Add Workflow Group']"
                :call-backs="[ addWorkflowGroup ]"
                @dismiss="showAddWorkflowGroup = false"
        />
    </v-tooltip>
</template>

<script>

    import InputDlg from '../../../../../components/General/InputDlg'

    export default {
        components: {
            appInputDlg: InputDlg
        },
        props: {
            workSpace: Object
        },
        data() {
            return {
                showAddWorkflowGroup: false
            }
        },
        methods: {
            addWorkflowGroup(groupDescription) {

                if (groupDescription != null && groupDescription != '') {

                    this.$emit('addwfgroup', {workspaceID: this.workSpace.id, description: groupDescription})
                    this.showAddWorkflowGroup = false;
                } else {
                    this.showSnack('Error', 'A Workflow Group Description Is Required', 'Close', 'red')
                }
            }
        }

    }
</script>

<style scoped>

</style>
