<template>
    <v-layout column fill-height class="scrollable">

        <v-flex shrink class="pa-2">
            <v-text-field
                    label="Search Fields"
                    single-line
                    flat
                    color="primary"
                    prepend-inner-icon="search"
                    hide-details
                    style="width: 100%"
                    class="pa-0 ma-0"
                    v-model="search"
                    clearable
            />
        </v-flex>
        <v-flex xs12 class="scrollable">
            <v-treeview
                    :items="fieldOptions"
                    item-children="items"
                    item-key="ref"
                    item-text="description"
                    class="reg-tree"
                    :open-on-click="true"
                    :hoverable="true"
                    :search="search"
                    ref="fieldSelect"
            >
                <template v-slot:label="{ item }">

                    <div
                            class="fill-height"
                            style="width: 100%;"
                            :draggable="typeof item.dropType === 'string' ? ['workflowField', 'datasetField', 'userInput', 'signature', 'signatureInput'].includes(item.dropType) : false"
                            @dragstart="setTransfer($event, item)"
                    >
                        {{item.description}}
                    </div>
                </template>
            </v-treeview>
        </v-flex>
    </v-layout>

</template>

<script>
    import {arrPopulated, blankString} from "../../../../../codeFunctions";

    export default {
        props: {
            userInfo: Object,
            datasets: Array,
            fields: Array,
            steps: Array,
            fieldTypes: Array,
            signatures: Array,
            showInputFields: Boolean,
        },
        data() {
            return {
                search: null,
            }
        },
        watch: {
            search(val) {
                if (blankString(val)) {
                    this.$refs.fieldSelect.updateAll(false);
                } else {
                    this.$refs.fieldSelect.updateAll(true);
                }
            }
        },
        methods: {
            setTransfer(event, field) {
                if (this.showInputFields) {
                    this.setTransferEditor(event, field)
                } else {
                    this.setTransferRules(event, field)
                }
            },

            setTransferRules(event, field) {
                switch (field.dropType) {
                    case 'workflowField': {
                        let oObj = {
                            item: {
                                description: field.description,
                                fk_step: field.fk_step,
                                id: field.id,
                                systemFieldType: field.fieldType,
                            },
                            type: "wf",
                        };
                        event.dataTransfer.setData('text', JSON.stringify(oObj));
                        break;
                    }
                    case 'datasetField': {
                        let oObj = {
                            item: {
                                description: field.description,
                                fk_field_set: field.fk_field_set,
                                id: field.id,
                                systemFieldType: field.type,
                                datasetSelectFieldID: field.datasetSelectFieldID
                            },
                            type: "df",
                        };
                        event.dataTransfer.setData('text', JSON.stringify(oObj));
                        break;
                    }
                }
            },

            setTransferEditor(event, field) {
                let tString = ``;
                switch (field.dropType) {
                    case 'workflowField': {
                        tString = `<span id="F-W-V-${field.id}" class="fr-deletable" style="background: #F44336; border-radius: 4px;  color: white; text-align: center; padding: 1px 4px; font-size: 11px; outline: none">${field.description}</span>&nbsp;`
                        break;
                    }
                    case 'datasetField': {
                        tString = `<span id="F-D-V-${field.datasetSelectFieldID}:${field.fk_field_set}:${field.id}" class="fr-deletable" style="background: #2196F3; border-radius: 4px;  color: white; text-align: center; padding: 1px 4px; font-size: 11px; outline: none">${field.datasetSelectDescription} - ${field.description}</span>&nbsp;`
                        break;
                    }
                    case 'userInput' : {
                        // #4CAF50
                        tString = `{{USERINPUT}}`;
                        break;
                    }
                    case 'signature' : {
                        tString = ` <div id="F-S-V-${field.id}" class="fr-deletable" style="display: inline-block;background: #EF6C00; border-radius: 4px; color: white; text-align: center; padding: 20px 15px; font-size: 11px; outline: none;" > ${field.description} </div>&nbsp;`
                        break
                    }
                    case 'signatureInput' : {
                        this.$emit('setSigInput', {id: field.id, description: field.sigDescription});
                        tString = `{{SIGNATUREINPUT}}`;
                        break
                    }
                }
                event.dataTransfer.setData('text', tString);
            }

        },



        computed: {
            signatureFields() {
                if (arrPopulated(this.signatures)) {
                    return this.signatures.map((sig, i) => {
                        return {
                            type: 'signatureHeading',
                            id: sig.id,
                            ref: `sigHeading:${sig.id}`,
                            description: sig.description || `Signature ${i + 1}`,
                            items: [
                                {
                                    type: 'signature',
                                    id: sig.id,
                                    ref: `sig:${sig.id}`,
                                    description: `${sig.description || `Signature ${i + 1}`} - Signature Stamp`,
                                    dropType: `signature`
                                },
                                {
                                    type: 'signatureInput',
                                    id: sig.id,
                                    ref: `sigInput:${sig.id}`,
                                    description: `${sig.description || `Signature ${i + 1}`} Input Field`,
                                    sigDescription: sig.description || `Signature ${i + 1}`,
                                    dropType: `signatureInput`
                                },
                            ]

                        }
                    })
                } else {
                    return null
                }
            },
            workflowStepFields() {
                let arrAddFields = this.fields
                    .filter(
                        obj =>
                            (arrPopulated(this.fieldTypes) ? this.fieldTypes.includes(obj.fieldType) : true)
                            && (obj.visible === 1)
                    );
                let arrAddFieldsSteps = arrAddFields.map(obj => {return obj.fk_step});
                let arrSteps = this.steps.filter(obj => arrAddFieldsSteps.includes(obj.id) && obj.hidden === 0);
                return arrSteps.map(step => {
                    return {
                        type: 'step',
                        id: step.id,
                        ref: `step:${step.id}`,
                        description: step.description,
                        items: arrAddFields
                            .filter(obj => obj.fk_step === step.id)
                            .map(obj => {
                                let objField = {
                                    ref: `workflowField:${obj.id}`,
                                    dropType: `workflowField`
                                };
                                Object.assign(objField, obj);
                                return objField
                            })
                            .sort((a,b) => a['field_order'] < b['field_order'] ? -1 : 1)

                    }
                })
            },
            datasetSelectFields() {
                let arrDatasetSelectFields = this.fields
                    .filter(obj => obj.fieldType === 9 && obj.visible === 1)
                    .map(obj => {
                        return {
                            type: 'datasetSelect',
                            id: obj.id,
                            ref: `datasetSelect:${obj.id}`,
                            description: obj.description,
                            dataset: parseInt(obj.config.dataset),
                            items: []
                        }
                    });

                let objRefDataSets = this.datasets.reduce((obj, item) => {
                    obj[item.id] = item.fields.filter(
                        obj =>
                            (arrPopulated(this.fieldTypes) ? this.fieldTypes.includes(obj.type) : true)
                            && (obj.visible === 1)
                    );
                    return obj
                }, {});

                return arrDatasetSelectFields.reduce((list, item) => {
                    if (arrPopulated(objRefDataSets[item.dataset])) {
                        item.items = objRefDataSets[item.dataset]
                            .map(obj => {
                                let objField = {
                                    ref: `DatasetSelectField:${item.id}-${item.dataset}-${obj.id}`,
                                    dropType: `datasetField`,
                                    datasetSelectFieldID: item.id,
                                    datasetSelectDescription: item.description
                                };
                                Object.assign(objField, obj);
                                return objField
                            });
                        list.push(item)
                    }
                    return list
                }, []);
            },
            fieldOptions() {
                let arrOptions = [];
                if (this.showInputFields) {
                    arrOptions.push({
                        id: 0,
                        ref: `title:0`,
                        description: `User Input`,
                        items: [{
                            ref: `userInputField`,
                            dropType: `userInput`,
                            description: 'User Input Field'
                        }]
                    })
                }
                if (arrPopulated(this.workflowStepFields)) {
                    arrOptions.push({
                        id: 1,
                        ref: `title:1`,
                        description: `Workflow Fields`,
                        items: this.workflowStepFields
                    })
                }
                if (arrPopulated(this.datasetSelectFields)) {
                    arrOptions.push({
                        id: 2,
                        ref: `title:2`,
                        description: `Dataset Select Fields`,
                        items: this.datasetSelectFields
                    })
                }
                if (arrPopulated(this.signatureFields)) {
                    arrOptions.push({
                        id: 3,
                        ref: `title:3`,
                        description: `Signatures`,
                        items: this.signatureFields
                    })
                }


                return arrOptions;
            }
        }
    }
</script>

<style scoped>

</style>
