<template>
    <div class="pl-2 hover-item">
        <v-layout row align-center v-if="!inEdit" style="cursor: pointer">
            <v-flex xs12>
                {{conditionField.description}}
            </v-flex>
            <template v-if="![2, 7].includes(value.operationID)">
                <v-flex xs3 class="primary--text font-weight-bold text-xs-center">
                    {{operationDescriptions[value.operationID]}}
                </v-flex>
                <v-flex xs12 class="text-xs-right">
                    {{`"${value.value}"`}}
                </v-flex>
            </template>
            <template v-else>
                <v-flex xs3 class="primary--text font-weight-bold text-xs-right">
                    {{value.operationID === 2 ? 'Not Blank' : 'Is Blank'}}
                </v-flex>
            </template>

            <v-flex shrink class="pl-1">
                <v-tooltip top>
                    <v-btn
                            small
                            icon
                            flat
                            class="pa-0 ma-0"
                            color="red"
                            slot="activator"
                            @click="removeCondition"
                            :disabled="disableRemove"
                    >
                        <v-icon small>delete</v-icon>
                    </v-btn>
                    <span>Remove Condition</span>
                </v-tooltip>
            </v-flex>
            <v-flex shrink class="px-1">
                <v-tooltip top>
                    <v-btn
                            small
                            icon
                            flat
                            class="pa-0 ma-0"
                            color="primary"
                            slot="activator"
                            @click="inEdit = true"
                    >
                        <v-icon small>edit</v-icon>
                    </v-btn>
                    <span>Edit Condition</span>
                </v-tooltip>
            </v-flex>
        </v-layout>

        <div class="px-2" :class="!valid ? 'red lighten-5' : 'green lighten-5'" v-if="inEdit">
            <v-layout row align-center>
                <v-flex xs12 class="caption">
                    {{conditionField.description}}
                </v-flex>
                <v-flex shrink class="pl-2">
                    <v-select
                            :items="availableOperations"
                            item-text="description"
                            item-value="id"
                            v-model="value.operationID"
                            single-line
                            color="primary"
                            hide-details
                            style="width: 100%"
                            class="ma-0 pa-0"
                            dense
                    ></v-select>
                </v-flex>
                <v-flex xs12 class="pl-2" v-if="![2,7].includes(value.operationID)">
                    <template v-if="[1].includes(conditionField.fieldType)">
                        <v-text-field
                                label="Value"
                                single-line
                                v-model="value.value"
                                color="primary"
                                hide-details
                                style="width: 100%"
                                class="ma-0 pa-0"
                        ></v-text-field>
                    </template>
                    <template v-if="[2].includes(conditionField.fieldType)">
                        <app-date-picker-with-max
                                v-model="value.value"
                                :date-only="true"
                        />
                    </template>
                    <template v-if="[3,4].includes(conditionField.fieldType)">
                        <v-text-field
                                label="Value"
                                type="number"
                                single-line
                                v-model="value.value"
                                color="primary"
                                hide-details
                                style="width: 100%"
                                class="ma-0 pa-0"
                        ></v-text-field>
                    </template>
                    <template v-if="[5].includes(conditionField.fieldType)">
                        <v-autocomplete
                                :items="selectListOptions"
                                item-text="description"
                                item-value="id"
                                v-model="value.value"
                                single-line
                                color="primary"
                                hide-details
                                style="width: 100%"
                                class="ma-0 pa-0"
                                dense
                        ></v-autocomplete>
                    </template>
                </v-flex>
                <v-flex shrink class="pl-2">
                    <v-tooltip top>
                        <v-btn
                                small
                                icon
                                flat
                                class="pa-0 ma-0"
                                color="secondary"
                                slot="activator"
                                @click="updateCondition"
                                :disabled="!valid"
                                :loading="savingChanges"
                        >
                            <v-icon>save</v-icon>
                        </v-btn>
                        <span>Save Changes</span>
                    </v-tooltip>
                </v-flex>
            </v-layout>
        </div>
    </div>

</template>

<script>
    import {codeBaseMixin} from "../../../../../codeBaseMixin";
    import DatePickerWithMax from "../../../../../components/General/DatePickerWithMax";

    export default {
        components: {
            appDatePickerWithMax: DatePickerWithMax
        },
        mixins: [codeBaseMixin],
        props: {
            selectLists: Array,
            fields: Array,
            rule: Object,
            mixins: [codeBaseMixin],
            userInfo: Object,
            disableRemove: Boolean
        },
        watch: {
            rule: {
                immediate: true,
                handler(val) {
                    this.inEdit = false;
                    this.value = JSON.parse(JSON.stringify(val))
                }
            }
        },
        data() {
            return {
                inEdit: false,
                operationDescriptions: {
                    0: "=",
                    1: "Not Equal",
                    2: "Not Blank",
                    7: "Is Blank",
                    3: "Bigger",
                    4: "Smaller",
                    5: "After",
                    6: "Before",
                },
                value: {
                    operationID: 0,
                    fk_field: 0,
                    value: ''
                },
                savingChanges: false,
            }
        },
        methods: {
            updateCondition() {
                this.savingChanges = true;
                this.fetch({method: 'POST', url:`/workflowManager/rules/condition/${this.userInfo.entityID}`, body: JSON.stringify(this.value)})
                    .then((data) => {
                        this.savingChanges = false;
                        this.inEdit = false;
                        this.$emit('input', this.value);
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e);
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                        this.savingChanges = false;
                    })
            },
            removeCondition() {
                this.savingChanges = true;
                this.fetch({method: 'DELETE', url:`/workflowManager/rules/condition/${this.userInfo.entityID}/${this.value.id}`})
                    .then((data) => {
                        this.savingChanges = false;
                        this.$emit('remove', this.value);
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e);
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
                        this.savingChanges = false;
                    })
            }
        },
        computed: {
            valid() {
                return this.value.operationID == 2 || (this.value.operationID != 2 && this.value.value != null && this.value.value != '');
            },
            conditionField() {
                try {
                    return this.fields.filter(obj => obj.id == this.value.fk_field).pop();
                } catch (e) {
                    // eslint-disable-next-line
                    console.log(e);
                    return null;
                }
            },
            selectListOptions() {
                try {
                    if (this.conditionField.lookup_type == 1) {
                        return JSON.parse(this.conditionField.lookup.replace(/\\"/g,'"'))
                    } else {
                        return this.selectLists.filter(obj => obj.id == parseInt(JSON.parse(this.conditionField.lookup.replace(/\\"/g,'"')).id)).pop().members.map(obj => {return obj.value});
                    }
                } catch (e) {
                    // eslint-disable-next-line
                    console.log(e);
                    return [];
                }

                // try {
                //     return this.selectLists.filter(obj => obj.id == parseInt(JSON.parse(this.conditionField.lookup.replace(/\\"/g,'"')).id)).pop().members.map(obj => {return obj.value});
                // } catch (e) {
                //     // eslint-disable-next-line
                //     console.log(e);
                //     return [];
                // }
            },
            availableOperations() {
                switch (this.conditionField.fieldType) {
                    case 1: {
                        return [
                            {id: 0, description: "="},
                            {id: 1, description: "<>"},
                            {id: 2, description: "Not Blank"},
                            {id: 7, description: "Is Blank"},
                        ]
                    }
                    case 2: {
                        return [
                            {id: 0, description: "="},
                            {id: 1, description: "Not Equal"},
                            {id: 2, description: "Not Blank"},
                            {id: 7, description: "Is Blank"},
                            {id: 5, description: "After"},
                            {id: 6, description: "Before"},
                        ]
                    }
                    case 3: {
                        return [
                            {id: 0, description: "="},
                            {id: 1, description: "Not Equal"},
                            {id: 2, description: "Not Blank"},
                            {id: 7, description: "Is Blank"},
                            {id: 3, description: "Bigger"},
                            {id: 4, description: "Smaller"},
                        ]
                    }
                    case 4: {
                        return [
                            {id: 0, description: "="},
                            {id: 1, description: "Not Equal"},
                            {id: 2, description: "Not Blank"},
                            {id: 7, description: "Is Blank"},
                            {id: 3, description: "Bigger"},
                            {id: 4, description: "Smaller"},
                        ]
                    }
                    case 5: {
                        return [
                            {id: 0, description: "="},
                            {id: 1, description: "Not Equal"},
                            {id: 2, description: "Not Blank"},
                            {id: 7, description: "Is Blank"},
                        ]
                    }
                    default: {
                        return [
                            {id: 2, description: "Not Blank"},
                            {id: 7, description: "Is Blank"},
                        ]
                    }
                }
            },
        }
    }
</script>

<style scoped>

</style>
