<template>
    <div class="scrollable" >

        <div
                @click="dialog = true"
        >
            <div @click="reload()">
                <slot></slot>
            </div>
        </div>

        <v-dialog
                v-model="dialog"
                fullscreen
        >
<!--            <template v-slot:activator="{ on }">-->
<!--                -->

<!--            </template>-->

            <v-card v-if="dialog" class="scrollable" style="height: 100vh">

                <v-layout column fill-height class="scrollable">

<!--                    <v-card-title class="py-2 pr-2 primary">-->
<!--                        <span class="title font-weight-regular white&#45;&#45;text">{{note.authorFullName}}</span>-->
<!--                        <v-spacer></v-spacer>-->
<!--                        <span class="body font-weight-light white&#45;&#45;text pr-2">{{ getLongDate(note.created) }}</span>-->
<!--                        <v-btn flat icon color="white" class="pa-0 ma-0" @click="printNote">-->
<!--                            <v-icon>print</v-icon>-->
<!--                        </v-btn>-->
<!--                        <div v-show="false" ref="printLink"></div>-->

<!--                        <v-btn flat icon color="white" class="pa-0 ma-0" @click="noteContentView = false">-->
<!--                            <v-icon>close</v-icon>-->
<!--                        </v-btn>-->
<!--                    </v-card-title>-->
<!--                    <v-divider></v-divider>-->

                    <v-flex shrink class="primary">
                        <v-layout row align-center>
                            <v-flex xs12>
                                <v-layout row align-center>
                                    <v-flex xs12 class="font-weight-regular white--text pa-2 title">
                                        {{docTitle}}
                                    </v-flex>
                                    <v-flex shrink>
                                        <div class="pr-2">
                                            <v-btn
                                                @click="zoom = zoom + 20"
                                                flat
                                                icon
                                                color="white"
                                                class="pa-0 ma-0"
                                                :disabled="zoom >= 300"
                                            >
                                                <v-icon>zoom_in</v-icon>
                                            </v-btn>
                                        </div>
                                    </v-flex>
                                    <v-flex shrink>
                                        <div class="pr-2">
                                            <v-btn
                                                @click="zoom = zoom - 20"
                                                :disabled="zoom <= 20"
                                                flat
                                                icon
                                                color="white"
                                                class="pa-0 ma-0"
                                            >
                                                <v-icon>zoom_out</v-icon>
                                            </v-btn>
                                        </div>
                                    </v-flex>
                                    <v-flex shrink v-if="loadedPages.length > 0 && showViewer">
                                         <div class="pr-2">
                                             <a :href="url">
                                                 <v-btn
                                                     icon
                                                     flat
                                                     color="white"
                                                     class="pa-0 ma-0"
                                                     @click="dialog = false"
                                                 >
                                                     <v-icon>cloud_download</v-icon>
                                                 </v-btn>
                                             </a>
                                         </div>
                                    </v-flex>
                                </v-layout>

                                <v-spacer></v-spacer>


                            </v-flex>
                            <v-flex shrink class="pa-2">
                                <v-btn
                                        icon
                                        flat
                                        color="white"
                                        class="pa-0 ma-0"
                                        @click="dialog = !dialog"
                                >
                                    <v-icon>close</v-icon>
                                </v-btn>
                            </v-flex>
                        </v-layout>
                    </v-flex>
                    <v-flex xs12 class="scrollable grey" ref="scrollBox" @mousedown="mouseDownHandler" style="cursor: grab">
                        <template v-show="loadedPages.length > 0 && showViewer">
                            <template v-for="i in numPages">
                                <pdf
                                        v-show="loadedPages.includes(i)"
                                        :key="i"
                                        :src="src"
                                        :page="i"
                                        :style="`width: ${zoom}%`"
                                        style="margin: auto"
                                        @loaded="loadedPages.push(i)"
                                ></pdf>
                                <div style="height: 20px"></div>
                            </template>
                        </template>
                    </v-flex>
                </v-layout>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import pdf from 'vue-pdf'

    export default {
        components: {
            pdf
        },
        data() {
            return {
                src: null,
                numPages: undefined,
                showViewer: false,
                loadedPages: [],
                dialog: false,
                zoom: 75,
                pos: { top: 0, left: 0, x: 0, y: 0 },
            }
        },
        props: {
            url: String
        },
        watch: {
            // dialog(val) {
            //     if (!val) {
            //         this.src = null;
            //         this.numPages = undefined;
            //         this.showViewer = false;
            //         this.loadedPages = [];
            //     }
            // }
        },
        methods: {

            mouseDownHandler(e) {
                try {
                    this.$refs.scrollBox.style.cursor = 'grabbing';
                    this.$refs.scrollBox.style.userSelect = 'none';
                } catch(e) {
                    console.log(e)
                }

                this.pos = {
                    left: this.$refs.scrollBox.scrollLeft,
                    top: this.$refs.scrollBox.scrollTop,
                    // Get the current mouse position
                    x: e.clientX,
                    y: e.clientY,
                };

                document.addEventListener('mousemove', this.mouseMoveHandler);
                document.addEventListener('mouseup', this.mouseUpHandler);
            },

            mouseUpHandler() {
                this.$refs.scrollBox.style.cursor = 'grab';
                this.$refs.scrollBox.style.removeProperty('user-select');

                document.removeEventListener('mousemove', this.mouseMoveHandler);
                document.removeEventListener('mouseup', this.mouseUpHandler);
            },

            mouseMoveHandler(e) {
                // How far the mouse has been moved
                const dx = e.clientX - this.pos.x;
                const dy = e.clientY - this.pos.y;

                // Scroll the element
                this.$refs.scrollBox.scrollTop = this.pos.top - dy;
                this.$refs.scrollBox.scrollLeft = this.pos.left - dx;
            },

            reload() {
                this.src = null;
                this.numPages = undefined;
                this.loadedPages = [];
                this.showViewer = true;
                var loadingTask = pdf.createLoadingTask(this.url);
                this.src = loadingTask;
                this.src.promise.then(pdf => {
                    this.numPages = pdf.numPages;
                });
                this.$nextTick(() => {
                    this.showViewer = true
                })
            },
        },
        computed: {
            docTitle() {
                try {
                    return decodeURIComponent(this.url).split('/').pop()
                } catch (e) {
                    return null
                }
            }
        }
    }
</script>

<style scoped>

</style>
