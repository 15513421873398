<template>
    <div class="pa-1">
        <v-checkbox
                :indeterminate="field.value == null"
                v-model="field.value"
                :label="field.description"
                color="primary"
                @change="$emit('input')"
                hide-details
                class="pa-0 ma-0"
                :readonly="readOnly"
        ></v-checkbox>
    </div>
</template>

<script>
    export default {
        props: {
            field: Object,
            readOnly: {
                default: false,
                type: Boolean
            }
        }
    }
</script>

<style scoped>

</style>
