<template>
  <div class="fill-height of-y">
    
    <div v-if="loading" class="fill-height c-d-flex c-align-center c-justify-center">
      <v-progress-circular
          :size="40"
          :width="5"
          color="primary"
          indeterminate
      ></v-progress-circular>
    </div>
    
    <div v-else class="fill-height c-d-flex of-y">
      
      <div class="fill-height of-y" style="width: 50%" ref="scrollList">
        
        <div style="position: sticky; top: 0; z-index: 9" class="primary">
          
          <div class="px-3 pb-2 c-d-flex c-align-center white--text">
            Linked Folders
          </div>
          <div>
            <v-divider></v-divider>
          </div>
        </div>
        
        <v-list
            class="py-0"
        >
          <v-list-tile
              v-for="folder in [...linkedFolders].sort(nameSort)"
              :key="folder.id"
              color="primary"
              @click="() => {}"
          >
            <v-list-tile-avatar>
              <v-icon color="amber darken-2">folder</v-icon>
            </v-list-tile-avatar>
            <v-list-tile-content>
              <v-list-tile-title>{{folder.description}}</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
        </v-list>
      </div>
      
      <v-divider :vertical="true"/>
      
      <div class="fill-height of-y" style="width: 50%" ref="scrollList">
        
        <div style="position: sticky; top: 0; z-index: 9" class="primary">
          
          <div class="px-3 pb-2 c-d-flex c-align-center white--text">
            Contact History
          </div>
          <div>
            <v-divider></v-divider>
          </div>
        </div>
        
        <div class="py-1 px-3">
          <v-card v-for="edit in history" class="my-4" hover flat>
            <v-card-text class="py-2 px-2">
              <div class="caption font-weight-bold c-d-flex c-align-center c-justify-space-between">
                <div style="white-space: nowrap">{{edit.displayDate}}</div>
                <div style="white-space: nowrap">{{edit.displayTime}}</div>
              </div>
              <div v-html="edit.displayVal" class="pt-1">
              
              </div>
<!--              {{ edit.displayVal }}-->
            </v-card-text>
          </v-card>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import AppContactsCustomFields from "@/contacts/AppContactsCustomFields.vue"
import draggable from "vuedraggable";
import {mapGetters} from "vuex";
import {arrSplice, getLongDate, getTime, nameSort} from "@/codeFunctions";
import AppContactModify from "@/contacts/AppContactModify.vue";
import format from "date-fns/format";

export default {
  components: {
  },
  props: {
    contact: {
      type: [Object, null],
      default: null
    }
  },
  data() {
    return {
      loading: false,
      history: [],
      linkedFolders: []
    }
  },
  watch: {
    contact: {
      immediate: true,
      handler(val) {
        this.init()
      }
    }
  },
  methods: {
    nameSort,
    async init() {
      this.history = [];
      this.linkedFolders = [];
      if (this.contact)
        
        try {
          this.loading = true
          const {linkedFolders, history} = await this.$newReq('GET', `contacts/information/${this.contact.id}`)
          
          for (const historyVal of history) {
            historyVal.date = new Date(historyVal.date)
            historyVal.displayDate = getLongDate(historyVal.date)
            historyVal.displayTime = getTime(historyVal.date)
            
            const lines = historyVal.val.match(/(\n\t)[^\n]*/gim)
            
            historyVal.displayVal = historyVal.val
            if (lines)
              for (const line of lines) {
                historyVal.displayVal = historyVal.displayVal.replace(line, `<div style="padding-left: 10px; padding-top: 4px">${line.replace(/\n|\t/gi, '')}</div>`)
              }
          }
          
          this.linkedFolders = linkedFolders;
          this.history = history.sort((a,b) => a.date > b.date ? -1 : 1);
        } catch (e) {
          console.log(e)
          this.$snack.networkError()
        } finally {
          this.loading = false
        }
    },
  },
  computed: {
    ...mapGetters('userInfo', ['userInfo']),
    
  },
}
</script>

<style scoped>
.tab {
  display: inline-block;
  width: 40px;
}
</style>