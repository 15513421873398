import { render, staticRenderFns } from "./TaskFilterBandToolbar.vue?vue&type=template&id=1b7c8ffe&scoped=true"
import script from "./TaskFilterBandToolbar.vue?vue&type=script&lang=js"
export * from "./TaskFilterBandToolbar.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b7c8ffe",
  null
  
)

export default component.exports