<template>
    <div class="pt-1">

        <div class="primary c-d-flex align-center" :class="fieldDropped ? null : 'elevation-2'">
            <div class="body-1 white--text font-weight-regular px-2 py-1 c-flex-grow-1">
                {{fieldTitle}}
            </div>
            <v-tooltip top v-if="showBody || showDocs">
                <template v-slot:activator="{on}">
                    <v-btn
                            icon
                            small
                            class="pa-0 mx-2 my-0"
                            flat
                            color="white"
                            @click="fieldDropped = !fieldDropped"
                            v-on="on"
                    >
                        <v-icon>
                            {{fieldDropped ? 'expand_less' : 'expand_more'}}
                        </v-icon>
                    </v-btn>
                </template>
                {{fieldDropped ? 'Show Less' : 'Show More'}}
            </v-tooltip>
        </div>

        <div v-if="fieldDropped">
            <div class="px-2 pt-0 pb-1">
                <div v-if="showBody" v-html="fieldBody"/>
            </div>


            <div class="c-d-flex c-flex-wrap px-1" v-if="viewDocs.length">
                <div
                        v-for="(doc, i) in viewDocs"
                        :key="i"
                        class="pr-2 pb-1"
                        @click="() => {$newDownload(doc.document)}"
                >
                    <div
                            :draggable="true"
                            @drag="$event.preventDefault()"
                            @dragstart="(event) => event.dataTransfer.setData('text', JSON.stringify({type: 'InfoDoc', doc: doc}))"
                    >
                        <dense-chip
                                color="primary"
                                :label="doc.description"
                                icon="attach_file"
                        />
                    </div>

<!--                    <v-card hover class="ma-1 primary">-->
<!--                        <div class="c-d-flex align-center hover-item" @click="$newDownload(doc.document)">-->
<!--                            <v-icon class="mx-2" color="white">file_present</v-icon>-->
<!--                            <div class="c-flex-grow-1 pa-2 white&#45;&#45;text">{{doc.description}}</div>-->
<!--                        </div>-->
<!--                    </v-card>-->
                </div>

            </div>



        </div>
        <v-divider></v-divider>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {parseHtml} from "@/codeFunctions";
import DenseChip from "@/components/General/DenseChip";

export default {
    components: {DenseChip},
    props: {
        lookup: String,
        fieldTitle: String,
    },
    data() {
        return {
            documents: null,
            showBody: true,
            showDocs: true,
            defaultCollapsed: false,
            fieldBody: null,
            fieldDropped: true
        }
    },
    watch: {
        lookup: {
            immediate: true,
            handler(val) {
                function getRef(val) {
                    try {
                        return JSON.parse(val)
                    } catch (e) {
                        return JSON.parse(val.replace(/\\"/g, '"'))
                    }
                }
                let ref = getRef(this.lookup)
                this.documents = (typeof ref === 'undefined' || ref === null || typeof ref.documents === 'undefined' || ref.documents === null) ? [] : ref.documents;
                this.showBody = (typeof ref === 'undefined' || ref === null || typeof ref.showBody === 'undefined' || ref.showBody === null) ? true : ref.showBody;
                this.showDocs = (typeof ref === 'undefined' || ref === null || typeof ref.showDocs === 'undefined' || ref.showDocs === null) ? true : ref.showDocs;
                this.defaultCollapsed = (typeof ref === 'undefined' || ref === null || typeof ref.defaultCollapsed === 'undefined' || ref.defaultCollapsed === null) ? false : ref.defaultCollapsed;
                this.fieldBody = (typeof ref === 'undefined' || ref === null || typeof ref.fieldBody === 'undefined' || ref.fieldBody === null) ? null : parseHtml(ref.fieldBody);
                this.fieldDropped = !this.defaultCollapsed;
            }
        }
    },
    methods: {},
    computed: {
        ...mapGetters('userInfo', ['userInfo']),
        ...mapGetters('repo', ['repoDocs']),

        viewDocs() {
            return this.repoDocs.filter(doc => this.documents.includes(doc.id))
        },
    },
}
</script>

<style scoped>

</style>