<template>
    <v-layout row align-center wrap>

        <v-flex xs12 class="text-xs-center" v-if="showLoading">
            <v-progress-circular
                    :size="28"
                    :width="3"
                    color="secondary"
                    indeterminate
            ></v-progress-circular>
        </v-flex>

        <template v-else>
            <v-flex xs12 v-if="!blockInput">
                <v-layout row align-center>
                    <v-flex xs12>
                        <v-text-field
                                clearable
                                color="primary"
                                flat
                                hide-details
                                class="pa-0 ma-0"
                                single-line
                                :label="value.description"
                                :disabled="!intToBool(value.editable) || !stepEditable"
                                @input="bindData = false"
                                :value="value.value"
                                @change="val => value.value = val">
                        </v-text-field>
                    </v-flex>
                    <v-flex shrink v-if="loadingMap">
                        <v-progress-circular
                                :size="20"
                                :width="2"
                                color="primary"
                                indeterminate
                        />
                    </v-flex>
                </v-layout>
            </v-flex>
            <v-flex xs12 v-else class="py-1">
                <v-layout row align-center>
                    <v-flex shrink>
                        <v-icon class="px-2 amber--text">warning</v-icon>
                    </v-flex>
                    <v-flex>
                        <span class="body-2 greyType--text">{{ fieldSetSelectField.description + ' Required' }}</span>
                    </v-flex>
                </v-layout>
            </v-flex>
        </template>


    </v-layout>
</template>

<script>

    import {codeBaseMixin} from "../../../../../../codeBaseMixin";
    import {mapGetters} from 'vuex';
    import {blankString} from "../../../../../../codeFunctions";
    import {DatasetBinder} from "@/Tasks/TaskView V2/components/TaskViewDetail/datasetBinder";

    export default {
        mixins:[codeBaseMixin],
        props: {
            value: Object,
            selectFields: Array,
            fieldSets: Array,
            stepEditable: Boolean,
            linkedFieldInfo: Object,
            binder: DatasetBinder
        },

        data() {
            return {
                bindData: true,
                curReqID: -1,
                loadingMap: false,
                loading: false
            }
        },

        watch: {
            "value.value": {
                handler(val) {
                    if (this.fieldMap != null && !blankString(val)) {
                        this.getfieldMapData(val, ++this.curReqID)
                    }
                }
            }
        },

        methods: {
            getfieldMapData(val, reqIndex) {
                this.loadingMap = true;
                this.fetch({method: 'PATCH', url:`/tasks/fieldMap/getValues/${this.userInfo.entityID}`, body: {val: val, map: this.fieldMap}})
                    .then((data) => {
                        if (this.curReqID === reqIndex) {
                            this.loadingMap = false;
                            this.$emit('setMap', {map:this.fieldMap, values: data.values, srcTask: data.srcTaskID})
                        }
                    })
                    .catch((e) => {
                        if (this.curReqID === reqIndex) {
                            this.loadingMap = false;
                            this.$emit('clearMap', this.fieldMap)
                        }
                    })
            },

            updateValue(newVal) {
                this.value.value = newVal
            }
        },

        computed: {

            fieldMap() {
                if (this.fieldMaps != null && typeof this.fieldMaps[this.value.fieldID] != 'undefined') {
                    return this.fieldMaps[this.value.fieldID]
                } else {
                    return null
                }
            },

            ...mapGetters('userInfo', ['fieldMaps', 'userInfo']),

            fieldSetSelectField() {
                try {
                    return this.binder.datasetSelectFieldsRef[this.lookupFieldID]
                } catch (e) {
                    console.log(e)
                    return null
                }
            },

            blockInput() {
                return !!(this.value.linkLookup
                        && this.value.linkLookup.fieldSetSelectField
                        && !this.fieldSetSelectField.value);
            },

            lookupFieldID() {
                return this.value.linkLookup && this.value.linkLookup.fieldSetSelectField ? this.value.linkLookup.fieldSetSelectField : null
            },

            lookupFieldMemberID() {
                if (this.lookupFieldID)
                    return this.fieldSetSelectField
                            ? this.fieldSetSelectField.value
                            : null
                else return null
            },


            showLoading() {
                if (this.lookupFieldID && this.lookupFieldMemberID) {
                    return this.binder.loadingDatasets.includes(Number(this.lookupFieldMemberID))
                } else {
                    return false
                }
            },

        }

    }
</script>

<style scoped>

</style>
