<template>
    <v-layout row align-center>

        <v-flex xs12 class="text-xs-center" v-if="showLoading">
            <v-progress-circular
                    :size="28"
                    :width="3"
                    color="secondary"
                    indeterminate
            ></v-progress-circular>
        </v-flex>

        <template v-else>
            <template v-if="!blockInput">
                <v-flex xs12 class="pt-1">
                    <v-autocomplete
                            v-if="!addingFieldSet"
                            :items="availableDatasets"
                            :clearable="value.config.createOnly && value.editable && stepEditable"
                            color="primary"
                            flat
                            v-model="fieldValue"
                            hide-details
                            class="pa-0 ma-0"
                            single-line
                            :label="value.description"
                            item-text="datasetValueNameSafe"
                            item-value="memberID"
                            dense
                            :disabled="!intToBool(value.editable) || !stepEditable"
                            :readonly="value.config.createOnly"
                    >
                        <template v-slot:item="{item}">
                            <dense-chip
                                    color="primary"
                                    icon-color="yellow darken-2"
                                    icon="description"
                                    :label="item.datasetName"
                                    :showClose="false"
                                    :max-chars="200"
                            />
                            {{item.datasetValueName ? `&nbsp;&nbsp;${item.datasetValueName}` : ''}}
                        </template>
                        <template v-slot:selection="{item}">
                            <dense-chip
                                    color="primary"
                                    icon="description"
                                    icon-color="yellow darken-2"
                                    :label="item.datasetName"
                                    :showClose="false"
                                    :max-chars="200"
                            />
                            {{item.datasetValueName ? `&nbsp;&nbsp;${item.datasetValueName}` : ''}}
                        </template>
                    </v-autocomplete>
                </v-flex>

                <v-flex shrink v-if="allowAdd">
                    <v-tooltip top>
                        <v-btn
                                icon
                                flat
                                small
                                class="pa-0 ma-0"
                                color="secondary"
                                slot="activator"
                                @click="addFieldSet"
                                :disabled="addingFieldSet || !intToBool(value.editable) || !stepEditable"
                                :loading="addingFieldSet"
                        >
                            <v-icon>add</v-icon>
                        </v-btn>
                        <span>{{'Add New: ' + binder.datasetRuleRef[value.config.dataset].description }}</span>
                    </v-tooltip>
                </v-flex>
            </template>


<!--No Folder Selected-->
            <v-flex xs12 v-else class="py-1">
                <v-layout row align-center>
                    <v-flex shrink>
                        <v-icon class="px-2 amber--text">warning</v-icon>
                    </v-flex>
                    <v-flex>
                        <span class="body-2 greyType--text">{{ folderSelectFieldDescription + ' Required' }}</span>
                    </v-flex>
                </v-layout>
            </v-flex>
        </template>
    </v-layout>
</template>

<script>

    import {codeBaseMixin} from "../../../../../../codeBaseMixin";
    import {DatasetBinder} from "@/Tasks/TaskView V2/components/TaskViewDetail/datasetBinder";
    import DenseChip from "@/components/General/DenseChip";

    export default {
        components: {DenseChip},
        mixins:[codeBaseMixin],
        props: {
            value: Object,
            task: Object,
            userInfo: Object,
            stepEditable: Boolean,
            binder: DatasetBinder
        },

        data() {
            return {
                addingFieldSet: false,
                added: false,
                forceRecalcOfItems: 0
            }
        },

        watch: {
            "value.value": {
                handler() {
                    if (this.value.value) {
                        this.binder.loadDataset(this.value.value)
                    }
                }
            }
        },

        methods: {
            addFieldSet() {
                const folderID = this.value.config.folder ? this.binder.folderSelectFieldFolderRef[this.value.config.folder] : this.task.folderID
                this.addingFieldSet = true;
                this.$newReq(
                        'POST',
                        `dsLink/addDatasetToFolderWorkflow/${this.userInfo.entityID}/${this.userInfo.userID}/${folderID}/${this.value.config.dataset}`)
                    .then((data) => {
                        this.binder.addDataset(data)

                        this.value.value = data.memberID;
                        this.value.bindRef = data.folderID;

                        this.addingFieldSet = false;
                        this.added = true;
                        this.binder.clearLinkedFields(this.value);
                        ++this.forceRecalcOfItems;
                        this.$emit('saveFields');
                        this.$nextTick(() => {
                            this.$forceUpdate();
                        })
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e);
                        this.addingFieldSet = false;
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                    })
            }
        },

        mounted() {
            // if ((this.value.value == null || this.value.value == '') && !this.intToBool(this.fieldRules.allowMultiple) && this.fieldSets.length > 0) {
            //     this.value.value = this.fieldSets[0].memberID;
            // }
            // console.log(this.binder.loadingFolders, this.binder.folderSelectFieldFolderRef, this.value.config.folder, this.binder.folderSelectFieldFolderRef[this.value.config.folder])
            // (binder.loadingFolders.includes(binder.folderSelectFieldFolderRef[this.value.config.folder])
        },

        computed: {
            allowAdd() {
                if (this.value.config.createOnly)
                    // Check if there is a value
                    if (this.value.value) {
                        const selectedFolder = this.value.config && this.value.config.folder
                                ? this.binder.folderSelectFieldFolderRef[this.value.config.folder]
                                : this.task.folderID
                        // Check If The Ref Matches Up
                        if (Number(this.value.bindRef) !== Number(selectedFolder))
                            return true
                    }
                return !this.value.config.selectOnly && !this.added
            },

            blockInput() {
                if (this.value.config && this.value.config.folder) {
                    try {
                        return !this.binder.folderSelectFields.filter(folderField => folderField.fieldID === this.value.config.folder).shift().value
                    } catch (e) {
                        return false
                    }
                } else
                    return false
            },

            folderSelectFieldDescription() {
                try {
                    return this.binder.folderSelectFields.filter(folderField => folderField.fieldID === this.value.config.folder).shift().description
                } catch (e) {
                    return 'Folder Select'
                }
            },

            availableDatasets() {
                if (this.forceRecalcOfItems > -1) {
                    const folderDatasets = this.binder.folderDatasets[
                            this.value.config && this.value.config.folder
                                    ? this.binder.folderSelectFieldFolderRef[this.value.config.folder]
                                    : this.task.folderID
                            ] || null
                    if (!folderDatasets) {
                        // No Datasets For That Folder
                        return []
                    }
                    else {
                        // Return all datasets of compatible type
                        return folderDatasets[this.value.config.dataset || '0'] || []
                    }
                }
                return null
            },


            showLoading() {
                if (this.value.config.folder)
                    return this.binder.loadingFolders.includes(this.binder.folderSelectFieldFolderRef[this.value.config.folder])
                else
                    return this.binder.loadingFolders.includes(this.task.folderID)

            },

            fieldValue: {
                // getter
                get: function () {
                    try {
                        return parseInt(this.value.value)
                    } catch (e) {
                        // eslint-disable-next-line
                        console.log(e);
                        return this.value.value
                    }
                },
                // setter
                set: function (newValue) {
                    this.value.value = newValue
                    this.value.bindRef = this.value.config && this.value.config.folder
                            ? this.binder.folderSelectFieldFolderRef[this.value.config.folder]
                            : this.task.folderID
                }
            },


            // fieldRules() {
            //     let tRules = null;
            //     this.task.structure.fieldSetRules.forEach(rule => {
            //         if (rule.id == this.value.config.dataset) {
            //             tRules = rule
            //         }
            //     });
            //     return tRules
            // },
            showAdd() {
                return false
                // if (JSON.parse(this.fieldRules.securityGroups).filter(element => this.userInfo.userGroups.includes(element)).length > 0) {
                //     if (!this.intToBool(this.fieldRules.allowMultiple)) {
                //         // CHeck First
                //         return this.fieldSets.filter(field => field.fieldSetID == this.fieldRules.id).length <= 0
                //     } else {
                //         return true
                //     }
                // } else {
                //     return false
                // }
            }
        }

    }
</script>

<style scoped>

</style>
