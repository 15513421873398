<template>
    <v-card class="hide-parent" :flat="editMode">
        <v-layout row align-center>
            <template v-if="editMode">

                <template v-if="editFieldDisplay == null">
<!--Field Not Selected-->
                    <div
                            class="pa-1"
                            style="width: 100%"
                            @dragover="$event.preventDefault()"
                            @drop="dropOperator($event)"
                    >
                        <v-flex xs12 style="border-radius: 3px; border: 1px dashed lightgray" class="caption greyType--text text-xs-center pa-1">
                            Drop Field here to Start
                        </v-flex>
                    </div>
                </template>
                <template v-else>
<!--Field Selected-->
                    <v-flex xs12 class="text-truncate py-1" style="overflow-x: hidden">
                        <div
                                style="border-radius: 3px; border: 1px solid lightgray; width: 100%"
                                class="px-2 py-1"
                                @dragover="$event.preventDefault()"
                                @drop="dropOperator($event)"
                        >
                            <div class="caption font-weight-bold">
                                {{editFieldDisplay.mainDescription}}
                            </div>
                            <div class="caption font-weight-regular greyType--text">
                                {{editFieldDisplay.subDescription}}
                            </div>
                        </div>
                    </v-flex>
<!--Operation-->
                    <v-flex xs12 class="px-2">
                        <v-autocomplete
                                color="primary"
                                label="Operation"
                                :items="availableOperations"
                                item-text="operation"
                                item-value="id"
                                flat
                                v-model="editOperation"
                                hide-details
                                class="pa-0 ma-0"
                                dense
                                single-line
                                @input="editValue = null"
                        >
                        </v-autocomplete>
                    </v-flex>
<!--Value-->
                    <v-flex xs12 class="pr-2">
                        <div v-if="editOperation == null" class="caption greyType--text text-xs-center">
                            *Please select an Operation
                        </div>
<!--Other Value Type-->
                        <div v-if="editOperation == 4 || editOperation == 5" class="caption greyType--text text-xs-center">
                            *No Value To Select
                        </div>
<!--All Other Value Types-->
                        <template v-if="editOperation != null && editOperation != 4 && editOperation != 5">
<!--Number Value-->
                            <div v-if="editField.valueType == 1" class="caption greyType--text text-xs-center">
                                <v-text-field
                                        label="Value"
                                        single-line
                                        v-model="editValue"
                                        color="primary"
                                        style="width: 100%"
                                        class="ma-0 pa-0"
                                        type="number"
                                        hide-details
                                ></v-text-field>
                            </div>
<!--Text Value-->
                            <div v-if="editField.valueType == 2" class="caption greyType--text text-xs-center">
                                <v-text-field
                                        label="Value"
                                        single-line
                                        v-model="editValue"
                                        color="primary"
                                        style="width: 100%"
                                        class="ma-0 pa-0"
                                        type="text"
                                        hide-details
                                ></v-text-field>
                            </div>
<!--Date Value-->
                            <div v-if="editField.valueType == 3" class="caption greyType--text text-xs-center">
                                <date-picker-with-max
                                        v-model="editValue"
                                >
                                    <v-text-field
                                            label="Value"
                                            single-line
                                            v-model="editValue"
                                            color="primary"
                                            style="width: 100%"
                                            class="ma-0 pa-0"
                                            type="text"
                                            hide-details
                                            slot="activator"
                                    ></v-text-field>
                                </date-picker-with-max>
                            </div>
<!--Select List Value-->
                            <div v-if="editField.valueType == 6" class="caption greyType--text text-xs-center">
                                <v-autocomplete
                                        color="primary"
                                        label="value"
                                        :items="selectListTypeLookup"
                                        flat
                                        v-model="editValue"
                                        hide-details
                                        class="pa-0 ma-0"
                                        dense
                                        single-line
                                >
                                </v-autocomplete>
                            </div>
                        </template>
                    </v-flex>
                </template>
<!--Cancel Changes-->
                <v-flex class="pr-2" v-if="value != null">
                    <v-tooltip top>
                        <v-btn
                                small
                                icon
                                color="red"
                                class="pa-0 ma-0"
                                flat
                                slot="activator"
                                @click="editMode = false"
                        >
                            <v-icon>close</v-icon>
                        </v-btn>
                        <span>Cancel</span>
                    </v-tooltip>
                </v-flex>
<!--Remove Condition-->
                <v-flex class="pr-2" >
                    <v-tooltip top>
                        <v-btn
                                small
                                icon
                                color="red"
                                class="pa-0 ma-0"
                                flat
                                slot="activator"
                                @click="$emit('spliceMe')"
                        >
                            <v-icon>delete</v-icon>
                        </v-btn>
                        <span>Remove Condition</span>
                    </v-tooltip>
                </v-flex>
<!--Update Changes-->
                <v-flex class="pr-2" >
                    <v-tooltip top>
                        <v-btn
                                small
                                icon
                                color="green"
                                class="pa-0 ma-0"
                                flat
                                slot="activator"
                                :disabled="!allowUpdate"
                                @click="$emit('input', {field: editField, operation: editOperation, value: editValue}); editMode = false"
                        >
                            <v-icon>done</v-icon>
                        </v-btn>
                        <span>{{!allowUpdate ? 'Parameters Are Missing' : 'Done'}}</span>
                    </v-tooltip>
                </v-flex>
            </template>
            <template v-if="!editMode && value != null && value.field != null && typeof value.field.id != 'undefined' && value.field.id != null">
<!--Field Selected-->
                <v-flex
                        xs6
                        class="text-truncate py-1"
                        style="overflow-x: hidden"
                >
                    <div class="px-2 py-1">
                        <v-tooltip top>
                            <div class=" font-weight-bold primary--text" slot="activator">
                                {{valueFieldDisplay.mainDescription}}
                            </div>
                            <span>{{valueFieldDisplay.subDescription}}</span>
                        </v-tooltip>
                    </div>
                </v-flex>
                <v-flex
                        :shrink="![4,5].includes(value.operation)"
                        :xs6="[4,5].includes(value.operation)"
                        class="primary--text body-2 pr-2"
                        :class="[4,5].includes(value.operation) ? 'text-xs-right' : ''"
                >
                    {{valueOperationDisplay}}
                </v-flex>
                <v-flex
                        xs6
                        v-if="![4,5].includes(value.operation)"
                        class="primary--text body-2 text-xs-right pr-2"
                >
                    {{value.value}}
                </v-flex>
                <v-flex shrink class="hide-child pr-1">
                    <v-tooltip top>
                        <v-btn
                                small
                                icon
                                color="red"
                                class="pa-0 ma-0"
                                flat
                                slot="activator"
                                @click="$emit('spliceMe')"
                        >
                            <v-icon>delete</v-icon>
                        </v-btn>
                        <span>Remove Condition</span>
                    </v-tooltip>
                </v-flex>
                <v-flex shrink class="hide-child pr-1">
                    <v-tooltip top>
                        <v-btn
                                small
                                icon
                                color="secondary"
                                class="pa-0 ma-0"
                                flat
                                slot="activator"
                                @click="editMode = true"
                        >
                            <v-icon>edit</v-icon>
                        </v-btn>
                        <span>Edit Condition</span>
                    </v-tooltip>
                </v-flex>
            </template>
        </v-layout>
    </v-card>

</template>

<script>
    import DatePickerWithMax from "../../../../../../../../components/General/DatePickerWithMax";
    export default {
        components: {DatePickerWithMax},
        props: {
            initialStartupEditMode: {
                type: Boolean,
                default: false
            },
            value: Object,
            flatFields: Object,
            selectLists: Array,
        },
        data() {
            return {
                editMode: false,
                editField: {
                    id: null,
                    fieldType: null,
                    valueType: null,
                    dsSelect: null
                },
                editOperation: null,
                editValue: null,
                operationIDs: [
                    {id: 0, operation: '=', description: 'Equal'},
                    {id: 1, operation: '<>', description: 'Not Equal'},
                    {id: 2, operation: '<', description: 'Smaller'},
                    {id: 3, operation: '>', description: 'Greater'},
                    {id: 4, operation: 'Not Blank', description: 'Not Blank'},
                    {id: 5, operation: 'Is Blank', description: 'Is Blank'},
                ],
                queryValueTypes: {
                    1: 'Number',
                    2: 'Text',
                    3: 'Date',
                    4: 'Other',
                    6: 'List Field'
                }
            }
        },
        methods: {
            dropOperator(event) {
                let data = JSON.parse(event.dataTransfer.getData("text"));
                let field = {};
                field.id = data.item.id;
                field.fieldType = data.type;
                field.dsSelect = data.item.datasetSelectFieldID;
                field.valueType = ['wd', 'dd', 'sd'].includes(data.type) ? 3
                    : (
                        ['wu', 'du', 'su'].includes(data.type) ? 5
                            : this.getQueryFieldProperty(data.type, data.item.id, 'queryFieldType')
                    );
                this.editField = field;
                this.editOperation = null;
                this.editValue = null;
            },

            getQueryFieldProperty(fieldType, id, property) {
                if (id != null) {
// Workflow Fields
                    if (['wf'].includes(fieldType)) {
                        return this.flatFields.workflowFields[id][property];
                    }
// Dataset Fields
                    if (['df'].includes(fieldType)) {
                        return this.flatFields.dataSetFields[id][property];
                    }
                } else {
                    return null
                }

            }
        },
        mounted() {
            if (this.initialStartupEditMode) {
                this.editMode = true;
            }
        },
        watch: {
            editMode: {
                immediate: true,
                handler(edit) {
                    if (edit) {
                        if (this.value != null && typeof this.value.field != 'undefined' && this.value.field != null) {
                            this.editField = this.value.field;
                            this.editOperation = this.value.operation;
                            this.editValue = this.value.value;
                        }
                    } else {
                        this.editField = {
                                id: null,
                                fieldType: null,
                                valueType: null,
                                dsSelect: null
                        };
                        this.editOperation = null;
                        this.editValue = null;
                    }
                }
            }
        },

        computed: {
            allowUpdate() {
                if (this.editField == null || typeof this.editField.id == 'undefined' || this.editField.id == null) {
                    return false
                }
                if (this.editOperation == null) {
                    return false
                }
                if (!(this.editOperation == 4 || this.editOperation == 5) && this.editValue == null) {
                    return  false
                }
                return true
            },
            selectListTypeLookup() {
                try {
                    let tParse = this.getQueryFieldProperty(this.editField.fieldType, this.editField.id, 'lookup');
                    let lookupString = typeof tParse == 'string' ? JSON.parse(tParse.replace(/\\"/g,`"`)) : tParse;

                    if (Array.isArray(lookupString)) {
                        return lookupString
                    } else {
                        return this.selectLists
                            .filter(obj => obj.id == lookupString.id)
                            .map(obj => {return obj.members.map(itm => {return itm.value})})
                            .pop()
                    }
                } catch (e) {
                    // eslint-disable-next-line
                    console.log(e)
                    return null
                }
            },
            editFieldDisplay() {
                if (this.editField.id != null) {
                    let tField = {
                        mainDescription: '-',
                        subDescription: '-',
                    };
// Workflow Fields
                    if (['wf'].includes(this.editField.fieldType)) {
                        tField.mainDescription = this.flatFields.workflowFields[this.editField.id].description;
                        tField.subDescription = this.flatFields.steps[this.flatFields.workflowFields[this.editField.id].fk_step].description;
                    }
// Dataset Fields
                    if (['df'].includes(this.editField.fieldType)) {
                        tField.mainDescription = this.flatFields.dataSetFields[this.editField.id].description;
                        tField.subDescription = this.flatFields.dataSets[this.editField.dsSelect].fieldDescription + ' - ' + this.flatFields.dataSets[this.editField.dsSelect].dataSetDescription;
                    }

                    return tField
                } else {
                    return null
                }
            },
            availableOperations() {
                if (this.editField.id != null) {
                    switch (this.editField.valueType) {
                        case 1: {
                            return [
                                {id: 0, operation: '=', description: 'Equal'},
                                {id: 1, operation: '<>', description: 'Not Equal'},
                                {id: 2, operation: '<', description: 'Smaller'},
                                {id: 3, operation: '>', description: 'Greater'},
                                {id: 4, operation: 'Not Blank', description: 'Not Blank'},
                                {id: 5, operation: 'Is Blank', description: 'Is Blank'},
                            ]
                        }
                        case 3: {
                            return [
                                {id: 0, operation: '=', description: 'Equal'},
                                {id: 1, operation: '<>', description: 'Not Equal'},
                                {id: 2, operation: '<', description: 'Smaller'},
                                {id: 3, operation: '>', description: 'Greater'},
                                {id: 4, operation: 'Not Blank', description: 'Not Blank'},
                                {id: 5, operation: 'Is Blank', description: 'Is Blank'},
                            ]
                        }
                        case 4: {
                            return [
                                {id: 4, operation: 'Not Blank', description: 'Not Blank'},
                                {id: 5, operation: 'Is Blank', description: 'Is Blank'},
                            ]
                        }
                        default : {
                            return [
                                {id: 0, operation: '=', description: 'Equal'},
                                {id: 1, operation: '<>', description: 'Not Equal'},
                                {id: 4, operation: 'Not Blank', description: 'Not Blank'},
                                {id: 5, operation: 'Is Blank', description: 'Is Blank'},
                            ]
                        }
                    }
                } else {
                    return null
                }
            },
            valueOperationDisplay() {
                return this.operationIDs
                    .filter(obj => obj.id == this.value.operation)
                    .map(obj => {
                        return obj.operation
                    })
                .pop()
            },
            valueFieldDisplay() {
                if (this.value.field.id != null) {
                    let tField = {
                        mainDescription: '-',
                        subDescription: '-',
                    };
// Workflow Fields
                    if (['wf'].includes(this.value.field.fieldType)) {
                        tField.mainDescription = this.flatFields.workflowFields[this.value.field.id].description;
                        tField.subDescription = this.flatFields.steps[this.flatFields.workflowFields[this.value.field.id].fk_step].description;
                    }
// Dataset Fields
                    if (['df'].includes(this.value.field.fieldType)) {
                        tField.mainDescription = this.flatFields.dataSetFields[this.value.field.id].description;
                        tField.subDescription = this.flatFields.dataSets[this.value.field.dsSelect].fieldDescription + ' - ' + this.flatFields.dataSets[this.value.field.dsSelect].dataSetDescription;
                    }

                    return tField
                } else {
                    return null
                }
            },
        }
    }
</script>

<style scoped>

</style>
