<template>
    <v-layout row>

        <v-flex xs12 class="text-xs-center" v-if="showLoading">
            <v-progress-circular
                    :size="28"
                    :width="3"
                    color="secondary"
                    indeterminate
            ></v-progress-circular>
        </v-flex>

        <template v-else>
            <v-flex v-if="!blockInput">
                <v-layout row align-center>

                    <v-flex xs12>
                        <start-date-picker
                                v-model="value.value"
                                :disabled="!intToBool(value.editable) || !stepEditable"
                                :date-only="!!value.lookup_type"
                                :model-string="true"
                        >
                            <v-text-field
                                    slot="activator"
                                    append-outer-icon="calendar_today"
                                    label="Select A Date"
                                    clearable
                                    color="primary"
                                    flat
                                    :value="value.value == null ? '' : (!!value.lookup_type ? getLongDateOnly(value.value) : getLongDate(value.value))"
                                    hide-details
                                    class="pa-0 ma-0"
                                    single-line
                                    :disabled="!intToBool(value.editable) || !stepEditable"
                                    readonly
                                    @click:clear="value.value = null"
                            >
                            </v-text-field>
                        </start-date-picker>
                    </v-flex>
                </v-layout>
            </v-flex>
            <v-flex xs12 v-else class="py-1">
                <v-layout row align-center>
                    <v-flex shrink>
                        <v-icon class="px-2 amber--text">warning</v-icon>
                    </v-flex>
                    <v-flex>
                        <span class="body-2 greyType--text">{{ fieldSetSelectField.description + ' Required' }}</span>
                    </v-flex>
                </v-layout>
            </v-flex>
        </template>

    </v-layout>
</template>

<script>
    import {codeBaseMixin} from "../../../../../../codeBaseMixin";
    import StartDatePicker from "@/components/General/Calendar/StartDatePicker/StartDatePicker";
    import {DatasetBinder} from "@/Tasks/TaskView V2/components/TaskViewDetail/datasetBinder";


    export default {
        components: {StartDatePicker},
        mixins: [ codeBaseMixin ],
        props: {
            value: Object,
            selectFields: Array,
            fieldSets: Array,
            stepEditable: Boolean,
            linkedFieldInfo: Object,
            binder: DatasetBinder
        },
        data() {
            return {
                menu: false,
                selectDate: true,
                bindData: true,
                loading: false
            }
        },
        methods: {
            updateValue(newVal) {
                this.value.value = newVal
            },
            dateInput() {
                this.selectDate = false;
                this.bindData = false
            }
        },
        watch: {
            menu() {
                this.selectDate = true;
            },
            blockInput: {
                immediate: true,
                handler(val) {
                    if (!val && !this.value.value && this.value.lookup == 1) {
                        this.value.value = this.getSqlDate(new Date()) + ' ' + this.getFormattedTime(new Date()) + ':00';
                        this.value.bindRef = this.lookupFieldMemberID
                    }
                }
            }
        },
        computed: {
            date: {
                // getter
                get: function () {

                    if (this.value.value == null) {
                        let sString = this.getSqlDate(new Date()) + ' ' + this.getFormattedTime(new Date()) + ':00' ;
                        return sString
                    } else {
                        return this.value.value
                    }
                },
                // setter
                set: function (newValue) {
                    this.value.value = newValue
                }
            },

            time: {
                // getter
                get: function () {

                    if (this.value.value == null) {
                        return '00:00'
                    } else {
                        return this.getFormattedTime(this.value.value);
                    }
                },
                // setter
                set: function (newValue) {
                    this.value.value = this.getSqlDate(this.date) + ' ' + newValue + ':00';
                }
            },

            fieldSetSelectField() {
                try {
                    return this.binder.datasetSelectFieldsRef[this.lookupFieldID]
                } catch (e) {
                    console.log(e)
                    return null
                }
            },

            blockInput() {
                return !!(this.value.linkLookup
                        && this.value.linkLookup.fieldSetSelectField
                        && !this.fieldSetSelectField.value);
            },

            lookupFieldID() {
                return this.value.linkLookup && this.value.linkLookup.fieldSetSelectField ? this.value.linkLookup.fieldSetSelectField : null
            },

            lookupFieldMemberID() {
                if (this.lookupFieldID)
                    return this.fieldSetSelectField
                            ? this.fieldSetSelectField.value
                            : null
                else return null
            },


            showLoading() {
                if (this.lookupFieldID && this.lookupFieldMemberID) {
                    return this.binder.loadingDatasets.includes(Number(this.lookupFieldMemberID))
                } else {
                    return false
                }
            },
        }
    }
</script>

<style scoped>

</style>
