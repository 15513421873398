<template>
    <v-layout row align-center>
        <v-flex xs12 class="body-1 py-1 font-weight-bold text--primary">
            {{value.value}}
        </v-flex>
        <v-flex shrink>
            <v-tooltip top>
                <template v-slot:activator="{on}">
                    <v-icon
                          :color="!(binderField && binderField.status === 'Success') ? 'amber' : null"
                          v-on="on"
                          @click="binderField ? evalBinder.runField(binderField, true) : null"
                    >
                        {{binderField && binderField.status === 'Success' ? 'functions' : 'warning'}}
                    </v-icon>
                </template>
                {{binderField && binderField.status === 'Success' ? 'Evaluation Success' : "Evaluation Failed"}}
            </v-tooltip>
        </v-flex>




<!--        <v-flex xs12 class="text-xs-center" v-if="showLoading">-->
<!--            <v-progress-circular-->
<!--                    :size="28"-->
<!--                    :width="3"-->
<!--                    color="secondary"-->
<!--                    indeterminate-->
<!--            ></v-progress-circular>-->
<!--        </v-flex>-->

<!--        <template v-else>-->
<!--            <v-flex xs12 v-if="!blockInput">-->
<!--                <v-layout row align-center>-->
<!--                    <v-flex xs12>-->
<!--                        <v-text-field-->
<!--                                clearable-->
<!--                                color="primary"-->
<!--                                flat-->
<!--                                hide-details-->
<!--                                class="pa-0 ma-0"-->
<!--                                single-line-->
<!--                                :label="value.description"-->
<!--                                :disabled="!intToBool(value.editable) || !stepEditable"-->
<!--                                @input="bindData = false"-->
<!--                                :value="value.value"-->
<!--                                @change="val => value.value = val">-->
<!--                        </v-text-field>-->
<!--                    </v-flex>-->
<!--                    <v-flex shrink v-if="loadingMap">-->
<!--                        <v-progress-circular-->
<!--                                :size="20"-->
<!--                                :width="2"-->
<!--                                color="primary"-->
<!--                                indeterminate-->
<!--                        />-->
<!--                    </v-flex>-->
<!--                </v-layout>-->
<!--            </v-flex>-->
<!--            <v-flex xs12 v-else class="py-1">-->
<!--                <v-layout row align-center>-->
<!--                    <v-flex shrink>-->
<!--                        <v-icon class="px-2 amber&#45;&#45;text">warning</v-icon>-->
<!--                    </v-flex>-->
<!--                    <v-flex>-->
<!--                        <span class="body-2 greyType&#45;&#45;text">{{ fieldSetSelectField.description + ' Required' }}</span>-->
<!--                    </v-flex>-->
<!--                </v-layout>-->
<!--            </v-flex>-->
<!--        </template>-->


    </v-layout>
</template>

<script>

    import {codeBaseMixin} from "../../../../../../codeBaseMixin";
    import {mapGetters} from 'vuex';
    import {blankString} from "../../../../../../codeFunctions";
    import {DatasetBinder} from "@/Tasks/TaskView V2/components/TaskViewDetail/datasetBinder";
    import {EvalBinder} from "@/Tasks/TaskView V2/components/TaskViewDetail/evalsBinder";

    export default {
        mixins:[codeBaseMixin],
        props: {
            value: Object,
            selectFields: Array,
            fieldSets: Array,
            stepEditable: Boolean,
            linkedFieldInfo: Object,
            binder: DatasetBinder,
            evalBinder: EvalBinder
        },

        data() {
            return {
                bindData: true,
                curReqID: -1,
                loadingMap: false,
                loading: false
            }
        },

        watch: {

        },

        methods: {



        },

        computed: {
            binderField() {
                const field = this.evalBinder.binderFields.find(binderRef => binderRef.field.fieldID === this.value.fieldID)
                return field
            }
        }

    }
</script>

<style scoped>

</style>
