<template>
    <div>
        <div @click="!disabled ? showView = true : null">
            <slot name="activator"></slot>
        </div>
        <v-dialog v-model="showView" persistent width="800px">
            <v-card flat>
                <v-card-title class="pa-1 pl-2">
                    <span class="subheading font-weight-light primary--text">Status View</span>
                    <v-spacer></v-spacer>


                    <v-btn
                            small
                            icon
                            flat
                            color="primary"
                            class="pa-0 ma-0"
                            @click="exportReport"
                            :loading="exportingReport"
                            :disabled="exportingReport"
                    >
                        <v-icon small>cloud_download</v-icon>
                    </v-btn>


                    <v-btn
                            small
                            icon
                            flat
                            color="primary"
                            class="pa-0 ma-0"
                            @click="showView = false"
                    >
                        <v-icon small>close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-divider></v-divider>

                <v-card-text class="pa-2 ma-0">
                    <template v-if="loadingData">
                        <div class="pa-2 text-xs-center">
                            <v-progress-circular
                                    :size="40"
                                    :width="5"
                                    color="primary"
                                    indeterminate
                            />
                        </div>
                    </template>
                    <template v-else>
<!--Current Status-->
                        <div class="greyType--text body-2">Current Task Status:</div>
                        <v-card flat class="pa-1">
                        <v-layout row align-center v-if="currentStatus != null">
<!--Status Circle-->
                            <v-flex shrink class="pl-1">
                                <v-tooltip top>
                                    <v-btn
                                            icon
                                            :color="curStatusColour"
                                            class="pa-0 ma-0"
                                            slot="activator"
                                            small
                                            dark
                                    >
                                        <v-icon small>chat_bubble</v-icon>
                                    </v-btn>
                                    <span>{{ curStatusColour == 'green' ? 'Task Status is Normal' : (curStatusColour == 'red' ? 'Task Status is Overdue' : 'Task Status is Extended')}}</span>
                                </v-tooltip>
                            </v-flex>
<!--Status User-->
                            <v-flex shrink class="pl-2">
                                <v-layout row align-center>
                                    <v-flex shrink>
                                        <v-avatar
                                                :color="currentStatus.fk_user == userInfo.userID ? 'secondary' : 'primary'"
                                                slot="activator"
                                                :size="26"
                                        >
                                            <span class="white--text caption">{{ getInitials(currentStatus.createdName + ' ' + currentStatus.createdSurname) }}</span>
                                        </v-avatar>
                                    </v-flex>
                                    <v-flex shrink class="caption greyType--text pl-2">
                                        <div style="white-space: pre">{{currentStatus.createdName}}</div>
                                        <div style="white-space: pre; margin-top: -4px">{{currentStatus.createdSurname}}</div>
                                    </v-flex>
                                </v-layout>
                            </v-flex>
                            <!--<v-flex shrink>-->
                                <!--{{curTime}} - {{ `'${curStatusColour}'` }}-->
                            <!--</v-flex>-->
                            <v-flex xs12 class="pl-2 body-2">
                                {{ curStatusDisplayName }}
                            </v-flex>

                            <v-flex shrink class="pl-2 pr-1">
                                <div style="white-space: pre;" class="greyType--text caption">{{ `${statusAge} Old` }}</div>
                                <div v-if="overdueAge != null" style="white-space: pre;" class="greyType--text caption">{{ `${overdueAge}` }}</div>
                            </v-flex>

                            <v-flex shrink class="py-1" v-if="showExtend">
                                <v-tooltip top>
                                    <v-btn
                                            icon
                                            color="secondary"
                                            class="pa-0 ma-0"
                                            slot="activator"
                                            small
                                            @click="showExtendDlg = true"
                                            flat
                                    >
                                        <v-icon >fast_forward</v-icon>
                                    </v-btn>
                                    <span>Extend Task Status</span>
                                </v-tooltip>

                                <app-input-dlg
                                        :show-dialog="showExtendDlg"
                                        title="Extend Status"
                                        body=""
                                        :cancel-button="true"
                                        :show-input-box="false"
                                        :buttons="[]"
                                        @dismiss="showExtendDlg = false"
                                >
                                    <template slot="body">
<!--Minutes-->
                                        <v-layout row align-center class="pl-2">
                                            <v-flex xs12 class="body-2 primary--text">
                                                Minutes:
                                            </v-flex>
                                            <template v-for="value in [10, 20, 30]">
                                                <v-flex :key="'min'+value" shrink :class="btnPaddingClass">
                                                    <v-btn
                                                            color="secondary"
                                                            flat
                                                            class="ma-0"
                                                            icon
                                                            :disabled="availableExtendValues != null && availableExtendValues.minutes < value"
                                                            @click="extendStatus('mm', value)"
                                                    >
                                                        +{{value}}
                                                    </v-btn>
                                                </v-flex>
                                            </template>
                                        </v-layout>
<!--Hours-->
                                        <v-layout row align-center class="pl-2">
                                            <v-flex xs12 class="body-2 primary--text">
                                                Hours:
                                            </v-flex>
                                            <template v-for="value in [1, 3, 6, 12]">
                                                <v-flex :key="'hour'+value" shrink :class="btnPaddingClass">
                                                    <v-btn
                                                            color="secondary"
                                                            flat
                                                            class="ma-0"
                                                            icon
                                                            :disabled="availableExtendValues != null && availableExtendValues.hours < value"
                                                            @click="extendStatus('h', value)"
                                                    >
                                                        +{{value}}
                                                    </v-btn>
                                                </v-flex>
                                            </template>
                                        </v-layout>
<!--Days-->
                                        <v-layout row align-center class="pl-2">
                                            <v-flex xs12 class="body-2 primary--text">
                                                Days:
                                            </v-flex>
                                            <template v-for="value in [1, 2, 3, 4, 5, 6, 7]">
                                                <v-flex :key="'day'+value" shrink :class="btnPaddingClass">
                                                    <v-btn
                                                            color="secondary"
                                                            flat
                                                            class="ma-0"
                                                            icon
                                                            :disabled="availableExtendValues != null && availableExtendValues.days < value"
                                                            @click="extendStatus('d', value)"
                                                    >
                                                        +{{value}}
                                                    </v-btn>
                                                </v-flex>
                                            </template>
                                        </v-layout>
<!--Months-->
                                        <v-layout row align-center class="pl-2">
                                            <v-flex xs12 class="body-2 primary--text">
                                                Months:
                                            </v-flex>
                                            <template v-for="value in [1, 2, 3, 4, 6, 12]">
                                                <v-flex :key="'months'+value" shrink :class="btnPaddingClass">
                                                    <v-btn
                                                            color="secondary"
                                                            flat
                                                            class="ma-0"
                                                            icon
                                                            :disabled="availableExtendValues != null && availableExtendValues.months < value"
                                                            @click="extendStatus('m', value)"
                                                    >
                                                        +{{value}}
                                                    </v-btn>
                                                </v-flex>
                                            </template>
                                        </v-layout>
<!--Other Options-->
                                        <v-layout row align-center class="pl-2">
                                            <v-flex xs12 class="body-2 primary--text">
                                                Other:
                                            </v-flex>
<!--                                            <v-flex shrink :class="btnPaddingClass">-->
<!--                                                <v-btn-->
<!--                                                        color="secondary"-->
<!--                                                        flat-->
<!--                                                        class="ma-0"-->
<!--                                                        @click="extendStatus('max')"-->
<!--                                                >-->
<!--                                                    Max-->
<!--                                                </v-btn>-->
<!--                                            </v-flex>-->
                                            <v-flex shrink :class="btnPaddingClass">
                                                <v-btn
                                                        color="secondary"
                                                        flat
                                                        class="ma-0"
                                                        @click="showSelectCustomDate = true"
                                                >
                                                    Select Date
                                                </v-btn>
                                            </v-flex>


                                            <app-input-dlg
                                                    :show-dialog="showSelectCustomDate"
                                                    title="Select Custom Date"
                                                    :cancel-button="true"
                                                    :show-input-box="false"
                                                    :buttons="[]"
                                                    @dismiss="showSelectCustomDate = false"
                                            >
                                                <template slot="body">
                                                   <date-picker
                                                           v-if="showSelectCustomDate"
                                                           v-model="selectedCustomDate"
                                                           :max="maxExtendDate"
                                                           :min="lastStatusDate"
                                                   />
                                                    <div class="text-xs-center red--text font-weight-bold caption" v-if="showSelectCustomDate && selectedDateError != null && selectedDateError != true">{{selectedDateError}}</div>
                                                </template>
                                                <template slot="actions">
                                                    <v-btn
                                                            color="secondary"
                                                            flat
                                                            :disabled="selectedDateError != null"
                                                            @click="extendWithCustom"
                                                    >
                                                        <v-icon left>save</v-icon>
                                                        Done
                                                    </v-btn>
                                                </template>
                                            </app-input-dlg>


                                        </v-layout>
                                    </template>
                                </app-input-dlg>
                            </v-flex>
                        </v-layout>
                        </v-card>
<!--Set A New Status-->
                        <div class="greyType--text body-2 pt-1">Set Task Status:</div>

                        <template v-if="ownerOnlyStatus ? this.task.ownerID === this.userInfo.userID : true">
                            <v-combobox
                                    dense
                                    label="Set Task Status"
                                    hide-details
                                    single-line
                                    color="primary"
                                    class="pa-0 ma-0 pl-3"
                                    :items="availableStatusList"
                                    item-text="description"
                                    prepend-icon="add"
                                    v-model="selectedNewStatus"
                                    ref="addStatusInput"
                                    @keyup.enter="setNewStatus"
                                    :loading="savingStatus"
                                    :disabled="savingStatus"
                            >
                                <template slot="no-data">
                                    <div class="caption font-weight-bold pa-2" :class="allowTextStatus ? 'primary--text' : 'red--text'">
                                        {{allowTextStatus ? '* Enter or click save to update status' : 'No Status Available'}}
                                    </div>
                                </template>
                                <template slot="append-outer">
                                    <div style="margin-top: -5px">
                                        <v-tooltip top>
                                            <v-btn
                                                    icon
                                                    flat
                                                    color="primary"
                                                    class="pa-0 ma-0"
                                                    slot="activator"
                                                    @click="setNewStatus"
                                                    :loading="savingStatus"
                                                    :disabled="savingStatus"
                                            >
                                                <v-icon>save</v-icon>
                                            </v-btn>
                                            <span>Save Status</span>
                                        </v-tooltip>
                                    </div>
                                </template>
                            </v-combobox>
                        </template>
                        <div v-else class="pa-2 caption grey--text text--darken-1 font-weight-bold">
                            Only the task owner can edit the status
                        </div>
<!--Status History-->
                        <template v-if="values.length > 0">
                            <div class="greyType--text body-2 pt-1">Status History:</div>
                            <div style="max-height: 400px; overflow-y: auto">
                                <template v-for="value in valuesMapped">
                                    <div class="pa-1">
                                        <v-card class="pa-1 px-2" flat style="border: 1px solid lightgrey">

                                            <v-layout row align-center>
                                                <v-flex shrink>
                                                    <v-icon :color="value.values.length > 0 ? 'blue' : 'green'" left>chat_bubble</v-icon>
                                                </v-flex>
                                                <v-flex xs12 class="subheading" >
                                                    <v-layout row align-center justify-space-between>
                                                        <v-flex shrink class="body-1 greyType--text">
                                                            {{value.createdName + ' ' + value.createdSurname}}
                                                        </v-flex>
                                                        <v-flex shrink class="caption greyType--text">
                                                            {{getLongDate(value.created)}}
                                                        </v-flex>
                                                        <v-flex shrink class="caption greyType--text">
                                                            <v-tooltip top>
                                                                <template slot="activator">
                                                                    <div style="cursor: pointer">
                                                                        {{value.age}}
                                                                    </div>
                                                                </template>
                                                                Status Duration
                                                            </v-tooltip>
                                                        </v-flex>
                                                    </v-layout>
                                                    <div>
                                                        {{ value.displayName }}
                                                    </div>
                                                </v-flex>
                                            </v-layout>

                                            <div v-if="value.values.length > 0" class="pt-0">
                                                <v-divider></v-divider>
                                                <div class="pt-1">
                                                    <template v-for="extension in value.values">
                                                        <v-layout class="pl-3 pr-3 caption" row align-center>
                                                            <v-flex shrink>
                                                                <v-icon small left color="secondary">person</v-icon>
                                                            </v-flex>
                                                            <v-flex xs3 class="font-weight-bold">
                                                                {{extension.createdName + ' ' + extension.createdSurname}}
                                                            </v-flex>
                                                            <v-flex xs4>
                                                                {{getLongDate(extension.created)}}
                                                            </v-flex>
                                                            <v-flex xs2>
                                                                {{`Age: ${extension.age}`}}
                                                            </v-flex>
                                                            <v-flex xs2 class="text-xs-right">
                                                                {{ `Added ${extension.extended}` }}
                                                            </v-flex>

                                                        </v-layout>
                                                    </template>
                                                </div>
                                            </div>
                                        </v-card>
                                    </div>
                                </template>
                            </div>

                        </template>
                    </template>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import {codeBaseMixin} from "../../../../../../codeBaseMixin";
    import InputDlg from "../../../../../../components/General/InputDlg";
    import DatePicker from "../../../../../../components/General/DatePickerWithMax";
    import {getDateTimeSpaceSeperated} from "@/codeFunctions";
    import {format} from 'date-fns'

    export default {
        components: {
            DatePicker,
            appInputDlg: InputDlg
        },
        mixins: [codeBaseMixin],
        props: {
            value: {
                type: Boolean,
                default: false,
            },
            userInfo: Object,
            task: Object,
            curTime: {
                type: Date,
                default: null
            },
            disabled: {type: Boolean, default: false}
        },

        watch: {
            value: {
                immediate: true,
                handler(val) {
                    this.showView = val;
                }
            },
            showView(shown) {
                if (!shown) {
                    this.$emit('input', false)
                } else {
                    this.loadingData = true;
                    this.fetch({method: 'GET', url:`/tasks/status/${this.userInfo.entityID}/${this.task.taskID}`})
                        .then((data) => {
                            this.allowTextStatus = data.allowTextStatus;
                            this.ownerOnlyStatus = data.ownerOnlyStatus;
                            this.statusList = data.statusList;
                            this.values = data.values;
                            this.loadingData = false;
                        })
                        .catch((e) => {
                            // eslint-disable-next-line
                            console.log(e);
                            this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
                            this.loadingData = false;
                        })
                }
            }
        },
        created() {
            this.curDate = new Date();
            this.curDateInterval = setInterval(() => {
                this.curDate = new Date();
            }, 60000)
        },
        beforeDestroy() {
            clearInterval(this.curDateInterval)
        },

        data() {
            return {
                showView: false,
                loadingData: false,
                allowTextStatus: null,
                statusList: [],
                values: [],
                selectedNewStatus: null,
                savingStatus: false,
                availableExtensions: [],
                showExtendDlg: false,
                btnPaddingClass: 'pt-1',
                showSelectCustomDate: false,
                selectedCustomDate: null,
                curDate: null,
                curDateInterval: null,
                ownerOnlyStatus: false,
                exportingReport: false
            }
        },

        methods: {

            exportReport() {
                this.exportingReport = true;
                this.$newReqDownloadFile(
                        'POST',
                        `tasks/status/export/task/statuses`,
                        {
                            valuesMapped: this.valuesMapped,
                            filename: `${this.task.title} Statuses ${format(new Date(), 'YYYY-MM-DD HH:mm')}`
                        },
                        `${this.task.title} Statuses ${format(new Date(), 'YYYY-MM-DD HH:mm')}.xlsx`,
                )
                        .catch(e => {
                            console.log(e)
                            this.$snack.networkError();
                        })
                        .finally(() => {
                            this.exportingReport = false;
                        });
            },


            extendWithCustom() {
                this.showSelectCustomDate = false;
                this.$nextTick(() => {
                    this.extendStatus('custom', this.selectedCustomDate)
                })
            },

            extendStatus(prefix, value) {
                this.savingStatus = true;
                this.showExtendDlg = false;
                let tNewDate = null;
                switch (prefix) {
                    case 'mm' : {
                        let tDate = this.curDate;
                        tDate = this.dateAdd(tDate, 'minute', value);
                        tNewDate = this.getSqlDate(tDate) + ' ' + this.getFormattedTime(tDate) + ':00';
                        break;
                    }
                    case 'h' : {
                        let tDate = this.curDate;
                        tDate = this.dateAdd(tDate, 'hour', value);
                        tNewDate = this.getSqlDate(tDate) + ' ' + this.getFormattedTime(tDate) + ':00';
                        break;
                    }
                    case 'd' : {
                        let tDate = this.curDate;
                        tDate = this.dateAdd(tDate, 'day', value);
                        tNewDate = this.getSqlDate(tDate) + ' ' + this.getFormattedTime(tDate) + ':00';
                        break;
                    }
                    case 'm' : {
                        let tDate = this.curDate;
                        tDate = this.dateAdd(tDate, 'month', value);
                        tNewDate = this.getSqlDate(tDate) + ' ' + this.getFormattedTime(tDate) + ':00';
                        break;
                    }
                    case 'max' : {
                        tNewDate = this.getSqlDate(this.maxExtendDate) + ' ' + this.getFormattedTime(this.maxExtendDate) + ':00';
                        break;
                    }
                    case 'custom' : {
                        tNewDate = value;
                        break;
                    }
                }

                let sendObject = {
                    extendDate: tNewDate,
                    srcValue: this.currentStatus.id,
                    fk_workflow_status: this.currentStatus.fk_workflow_status,
                    fk_user: this.currentStatus.fk_user,
                    statusStartDate: this.currentStatus.statusStartDate == null ? this.currentStatus.created : this.currentStatus.statusStartDate,
                    fk_task: this.task.taskID,
                    hiddenUsers: this.currentStatus.hiddenUsers,
                };

                sendObject.statusStartDate = this.getSqlDate(sendObject.statusStartDate) + ' ' + this.getFormattedTime(sendObject.statusStartDate) + ':00';

                this.fetch({method: 'post', url:`/tasks/status/extend/${this.userInfo.entityID}/${this.task.taskID}`, body: JSON.stringify(sendObject)})
                    .then((data) => {
                        this.values = this.values.concat(data.value);
                        this.task.workflowStatusUser = data.value.fk_user;
                        this.task.workflowStatusValue = data.value.id;
                        this.task.workflowStatus = data.value.fk_workflow_status;
                        this.task.workflowStatusCreated = data.value.created;
                        this.task.workflowStatusDescription = data.value.statusDescription;
                        this.task.workflowStatusName = data.value.createdName;
                        this.task.workflowStatusSurname = data.value.createdSurname;
                        this.task.workflowStatusTTE = data.value.tte;
                        this.task.workflowStatusType = data.value.statusType;
                        this.task.workflowStatusText = data.value.statusText;
                        this.task.workflowStatusExtension = data.value.extension;
                        this.task.workflowStatusStartDate = data.value.statusStartDate;

                        this.selectedNewStatus = null;
                        this.$refs.addStatusInput.$data.lazySearch = null;
                        this.$refs.addStatusInput.$data.lazyValue = null;
                        this.$refs.addStatusInput.$data.initialValue = null;
                        this.$refs.addStatusInput.$data.isMenuActive = false;

                        this.showSnack('Info', 'Status Updated', 'Close', 'primary')
                        this.savingStatus = false;
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e)
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                        this.savingStatus = false;
                    })
            },

            setStatus(type, value) {
                switch (type) {
                    case 'text': {
                        if (value != null && value != '') {
                            this.savingStatus = true;
                            this.fetch({method: 'POST', url:`/tasks/status/text/${this.userInfo.entityID}/${this.userInfo.userID}/${this.task.taskID}`, body: JSON.stringify({text: value})})
                                .then((data) => {
                                    this.values = this.values.concat(data);
                                    this.task.workflowStatusUser = data.fk_user;
                                    this.task.workflowStatusValue = data.id;
                                    this.task.workflowStatus = data.fk_workflow_status;
                                    this.task.workflowStatusCreated = data.created;
                                    this.task.workflowStatusDescription = null;
                                    this.task.workflowStatusName = data.createdName;
                                    this.task.workflowStatusSurname = data.createdSurname;
                                    this.task.workflowStatusTTE = data.tte;
                                    this.task.workflowStatusType = data.statusType;
                                    this.task.workflowStatusText = data.statusText;
                                    this.task.workflowStatusExtension = data.extension;
                                    this.task.workflowStatusStartDate = data.statusStartDate;

                                    this.selectedNewStatus = null;
                                    this.$refs.addStatusInput.$data.lazySearch = null;
                                    this.$refs.addStatusInput.$data.lazyValue = null;
                                    this.$refs.addStatusInput.$data.initialValue = null;
                                    this.$refs.addStatusInput.$data.isMenuActive = false;
                                    this.savingStatus = false;

                                    this.showSnack('Info', 'Status Saved', 'Close', 'primary')
                                    this.$emit('input', false)
                                    this.showView = false;
                                })
                                .catch((e) => {
                                    // eslint-disable-next-line
                                    console.log(e);
                                    this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
                                    this.savingStatus = false;
                                })
                        } else {
                            this.showSnack('Info', 'A status description is required', 'Close', 'primary');
                        }
                        break;
                    }
                    case 'value': {
                        this.savingStatus = true;
                        this.fetch({method: 'POST', url:`/tasks/status/value/${this.userInfo.entityID}/${this.userInfo.userID}/${this.task.taskID}/${this.selectedNewStatus.id}`})
                            .then((data) => {
                                this.values = this.values.concat(data);
                                this.task.workflowStatusUser = data.fk_user;
                                this.task.workflowStatusValue = data.id;
                                this.task.workflowStatus = data.fk_workflow_status;
                                this.task.workflowStatusCreated = data.created;
                                this.task.workflowStatusDescription = data.statusDescription;
                                this.task.workflowStatusName = data.createdName;
                                this.task.workflowStatusSurname = data.createdSurname;
                                this.task.workflowStatusTTE = data.tte;
                                this.task.workflowStatusType = data.statusType;
                                this.task.workflowStatusText = data.statusText;
                                this.task.workflowStatusExtension = data.extension;
                                this.task.workflowStatusStartDate = data.statusStartDate;

                                this.selectedNewStatus = null;
                                this.$refs.addStatusInput.$data.lazySearch = null;
                                this.$refs.addStatusInput.$data.lazyValue = null;
                                this.$refs.addStatusInput.$data.initialValue = null;
                                this.$refs.addStatusInput.$data.isMenuActive = false;
                                this.savingStatus = false;

                                this.showSnack('Info', 'Status Saved', 'Close', 'primary')
                                this.$emit('input', false);
                                this.showView = false;
                            })
                            .catch((e) => {
                                // eslint-disable-next-line
                                console.log(e);
                                this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
                                this.savingStatus = false;
                            });
                        break;
                    }
                }
            },

            setNewStatus() {
                let isText = (this.selectedNewStatus == null && !this.blankString(this.$refs.addStatusInput.$data.lazySearch)) || (this.selectedNewStatus != null && typeof this.selectedNewStatus == "string" && !this.blankString(this.selectedNewStatus));
                let isObject = this.selectedNewStatus != null && typeof this.selectedNewStatus == 'object' && typeof this.selectedNewStatus.id != 'undefined';

                if (
                    (!this.intToBool(this.allowTextStatus) && isText) || (!isText && !isObject)
                ) {
                    this.selectedNewStatus = null;
                    this.$refs.addStatusInput.$data.lazySearch = null;
                    this.$refs.addStatusInput.$data.lazyValue = null;
                    this.$refs.addStatusInput.$data.initialValue = null;
                } else {
                    if (this.selectedNewStatus != null || !this.blankString(this.$refs.addStatusInput.$data.lazySearch)) {
                        this.setStatus(
                            this.selectedNewStatus == null || typeof this.selectedNewStatus.id == "undefined" ? 'text' : 'value',
                            this.selectedNewStatus != null ? this.selectedNewStatus : this.$refs.addStatusInput.$data.lazySearch);
                    } else {
                        this.showSnack('Error', 'Status description is required', 'Close', 'red');
                    }
                    this.$refs.addStatusInput.$data.isMenuActive = false;
                    this.$refs.addStatusInput.$data.lazySearch = null;
                    this.$refs.addStatusInput.$data.lazyValue = null;
                    this.$refs.addStatusInput.$data.initialValue = null;
                }
            },

            updateAvailableExtensions(extensions) {
                this.availableExtensions = extensions;
            }
        },

        computed: {

            valuesMapped() {
                return this.values
                    .map(obj => {
                        obj.displayName = obj.statusType == 1 ? obj.statusText :
                            this.statusList
                                .reduce((description, status) => {
                                    if (status.id == obj.fk_workflow_status) {
                                        description = status.description;
                                    }
                                    return description;
                                }, null);
                        return obj;
                    })
                    .sort((a,b) => new Date(a['created']) < new Date(b['created']) ? -1 : 1)
                    .reduce((newValues, value, i, oldValues) => {
                        let compareDate = null;

                        if ((i + 1) == oldValues.length) {compareDate = new Date()}
                        else {compareDate = new Date(oldValues[i + 1].created)}

                        if (value.statusType == 1 || (value.statusType == 0 && value.extension == 0)) {
                            newValues.push({
                                displayName: value.displayName,
                                createdName: value.createdName,
                                createdSurname: value.createdSurname,
                                tte: value.tte,
                                created: value.created,
                                age: this.getDateTimedifferanceText(compareDate, new Date(value.created)),
                                values: []
                            })
                        } else {
                            newValues[newValues.length - 1].values.push({
                                createdName: value.createdName,
                                createdSurname: value.createdSurname,
                                created: value.created,
                                age: this.getDateTimedifferanceText(compareDate, new Date(value.created)),
                                extended: this.getDateTimedifferanceText(new Date(value.tte), new Date(oldValues[i - 1].tte))
                            });

                            newValues[newValues.length - 1].age = this.getDateTimedifferanceText(compareDate, new Date(newValues[newValues.length - 1].created))
                        }
                        return newValues;
                    }, [])
                    .sort((a,b) => new Date(a['created']) < new Date(b['created']) ? 1 : -1)
            },





            lastStatusDate() {
                if (this.currentStatus == null) {
                    return new Date();
                } else {
                   return new Date(this.currentStatus.created);
                }
            },
            selectedDateError() {
                if (this.selectedCustomDate != null) {
                    if (this.maxExtendDate == null) {
                        return null
                    } else {
                        let tDate = new Date(this.selectedCustomDate);
                        if (tDate <= this.maxExtendDate) {
                            return null
                        } else {
                            return `Please Select a Date Before: ${this.getLongDate(this.maxExtendDate)}`
                        }
                    }
                } else {
                    return true
                }
            },

            availableExtendValues() {
                if (this.maxExtendDate != null) {
                    return this.getDateTimedifferance(this.maxExtendDate, this.curDate);
                } else {
                    return null
                }
            },

            maxExtendDate() {

                if (this.maxExtendVal == 0) {
                    return this.maxExtendInterval == 0 ? null : this.maxExtendInterval
                }

                if (this.maxExtendInterval == 0) {
                    return this.maxExtendVal == 0 ? null : this.maxExtendVal;
                }
                return this.maxExtendVal < this.maxExtendInterval ? this.maxExtendVal : this.maxExtendInterval;
            },

            maxExtendVal() {
                let start = new Date(this.currentStatus.statusStartDate == null ? this.currentStatus.created : this.currentStatus.statusStartDate);
                let interval = this.availableExtensions.map((obj) => {

                    let iTotal = parseInt(obj.months) + parseInt(obj.days) + parseInt(obj.hours) + parseInt(obj.minutes);
                    if (iTotal == 0) {
                        return 0
                    } else {
                        return this.addDateTimeToDate(start, parseInt(obj.months), parseInt(obj.days), parseInt(obj.hours), parseInt(obj.minutes));
                    }
                }).sort((a,b) => ((a < b) && a != 0) || (b == 0) ? -1 : 1).pop();
                return interval;
            },

            maxExtendInterval() {
                let interval = this.availableExtensions.map((obj) => {
                    let iTotal = parseInt(obj.monthsMax) + parseInt(obj.daysMax) + parseInt(obj.hoursMax) + parseInt(obj.minutesMax);
                    if (iTotal == 0) {
                        return 0
                    } else {
                        return this.addDateTimeToDate(this.curDate, parseInt(obj.monthsMax), parseInt(obj.daysMax), parseInt(obj.hoursMax), parseInt(obj.minutesMax));
                    }
                }).sort((a,b) => ((a < b) && a != 0) || (b == 0) ? -1 : 1).pop();
                return interval;
            },

            showExtend() {
                if (this.currentStatus == null || this.currentStatus.tte == null || this.currentStatus.statusType == 1 || this.curStatusTemplate == null || this.curStatusTemplate.enableExtension == 0) {
                    return false;
                } else {

                    // Check If Overdue Yet

                    let tte = new Date(this.currentStatus.tte);

                    tte = this.dateAdd(tte, 'hour', -12);

                    if (tte < this.curTime) {
                                          // Escalated Status

                        // Now Check if user can escalate

                        try {

                            let extensions = JSON.parse(this.curStatusTemplate.extensions);

                            // Create a list of all ids where user falls into

                            let allExtensionIDs = extensions.reduce((list, extension) => {
                                let extensionGroups = JSON.parse(extension.userGroups);
                                if (this.userInfo.userGroups.filter(obj => extensionGroups.includes(obj)).length >= 1) {
                                    list = list.concat(extension);
                                }
                                return list
                            }, []);

                            if (allExtensionIDs.length > 0) {
                                this.updateAvailableExtensions(allExtensionIDs);
                                return true
                            } else {
                                this.updateAvailableExtensions([]);
                                return false
                            }
                        } catch (e) {
                            // eslint-disable-next-line
                            console.log(e);
                            return false
                        }
                    } else {
                        // Not Escalated Yet
                        return false;
                    }

                }
            },
            overdueAge() {
                if (this.currentStatus == null || this.currentStatus.tte == null) {
                    return null;
                } else {
                    let start = new Date(this.currentStatus.tte);

                    let diff = this.getMinutesDifference(this.curTime, start);

                    let appendPrefix = ' Overdue';

                    if (diff <= -1) {
                        diff = diff * -1;
                        appendPrefix = ' Remaining'
                    }

                    if (diff < 60) {
                        return `${diff} Minute${diff >= 2 ? 's' : ''}${appendPrefix}`;
                    } else {
                        diff = Math.trunc(diff / 60);
                        if (diff < 24) {
                            return `${diff} Hour${diff >= 2 ? 's' : ''}${appendPrefix}`;
                        } else {
                            diff = Math.trunc(diff / 24);
                            return `${diff} Day${diff >= 2 ? 's' : ''}${appendPrefix}`;
                        }
                    }
                }
            },
            statusAge() {
                if (this.currentStatus == null) {
                    return null;
                } else {
                    let start = new Date(this.currentStatus.statusStartDate == null ? this.currentStatus.created : this.currentStatus.statusStartDate);

                    let diff = this.getMinutesDifference(this.curTime, start);

                    if (diff < 60) {
                        return `${diff} Minute${diff >= 2 ? 's' : ''}`;
                    } else {
                        diff = Math.trunc(diff / 60);
                        if (diff < 24) {
                            return `${diff} Hour${diff >= 2 ? 's' : ''}`;
                        } else {
                            diff = Math.trunc(diff / 24);
                            return `${diff} Day${diff >= 2 ? 's' : ''}`;
                        }
                    }
                }
            },
            curStatusTemplate() {
                if (this.currentStatus == null) {
                    return null;
                } else {
                    if (this.currentStatus.statusType == 1) {
                        return this.currentStatus.statusText;
                    } else {
                        return this.statusList.reduce((obj, status) => {
                            if (status.id == this.currentStatus.fk_workflow_status) {
                                obj = status;
                            }
                            return obj;
                        }, null)
                    }
                }
            },
            curStatusDisplayName() {
                if (this.curStatusTemplate == null) {
                    return null;
                } else {
                    return typeof this.curStatusTemplate.description != 'undefined' ? this.curStatusTemplate.description : this.curStatusTemplate;
                }
            },
            curStatusColour() {
                if (this.currentStatus == null) {
                    return null;
                } else {
                    if (this.currentStatus.tte == null) {
                        return 'green';
                    } else {
                        let tte = new Date(this.currentStatus.tte);
                        if (tte < this.curTime) {
                            return 'red';
                        } else {
                            return this.currentStatus.extension == 1 ? 'blue' : 'green';
                        }
                    }
                }
            },
            currentStatus: {
                // getter
                get: function () {
                    if (this.values.length <= 0) {
                        return null
                    } else {

                        if (this.task.workflowStatusValue == null || this.task.workflowStatusValue == 0) {
                            return null
                        } else {
                            return this.values.reduce((returnVal, value) => {
                                if (value.id == this.task.workflowStatusValue) {
                                    returnVal = value
                                }
                                return returnVal
                            }, null)
                        }
                    }
                },
                // setter
                set: function (newValue) {

                }
            },
            availableStatusList() {
                return (
                    this.currentStatus != null
                        ? this.statusList.filter(obj => obj.id != this.currentStatus.fk_workflow_status && obj.hidden !== 1).sort((a,b) => a['order'] < b['order'] ? -1 : 1)
                        : this.statusList.filter(obj => obj.hidden !== 1).sort((a,b) => a['order'] < b['order'] ? -1 : 1)
                ).reduce((statusList, status) => {
                    if (status.authGroups == null) {
                        statusList.push(status)
                    } else {
                        try {
                            let oStatusAuth = JSON.parse(status.authGroups);

                            if (oStatusAuth.length <= 0) {
                                statusList.push(status)
                            } else {
                                if (this.userInfo.userGroups.filter(ug => oStatusAuth.includes(ug)).length >= 1) {
                                    statusList.push(status)
                                }
                            }
                        } catch (e) {
                            statusList.push(status)
                        }
                    }

                    return statusList
                }, [])
            }
        }
    }
</script>

<style scoped>

</style>
