<template>
    <v-layout row align-center class="py-1" :class="fieldClass" style="border-radius: 3px">
<!--Required Field-->
        <v-flex shrink class="pl-2">
            <v-tooltip top :disabled="field.distinct != 1">
                <span
                    :style="{color: field.distinct == 1 ? 'var(--v-tRed-base)' : 'rgba(0,0,0,0)'}"
                        slot="activator"
                        class="pa-0 ma-0 body-1"
                    style="cursor: pointer"
                >
                    #
                </span>
                <span>Unique Field</span>
            </v-tooltip>
        </v-flex>

        <v-flex shrink class="pl-1 pr-2">
            <v-tooltip top :disabled="field.required != 1">
                <span
                      :style="{color: field.required == 1 ? 'var(--v-tRed-base)' : 'rgba(0,0,0,0)'}"
                        slot="activator"
                        class="pa-0 ma-0 body-1"
                      style="cursor: pointer"
                >
                    *
                </span>
                <span v-if="field.required == 1">Required Field</span>
            </v-tooltip>
        </v-flex>

<!--Field Description-->
        <v-flex xs5>

            <v-layout row wrap>
                <v-flex xs12>
                    <span class="body-1">{{ field.fieldDescription }}</span>
                </v-flex>
                <v-flex xs12 v-if="field.saveStatus == 1">
                    <span class="caption"> {{ field.saveMessage }}</span>
                </v-flex>

            </v-layout>


        </v-flex>
<!--Read Only-->
        <v-flex shrink class="pr-2" v-if="readOnly || readOnlyField">
            <v-tooltip top>
                <v-icon class="greyType--text" slot="activator" style="cursor: pointer">security</v-icon>
                <span>Read Only Field</span>
            </v-tooltip>
        </v-flex>
<!--Secure Field-->
        <v-flex shrink class="pr-2" v-if="!readOnly && !readOnlyField && secureField">
            <v-tooltip top>
                <v-icon class="primary--text" slot="activator" style="cursor: pointer">security</v-icon>
                <span>Secure Field</span>
            </v-tooltip>
        </v-flex>
<!--Last Edited By-->
        <v-flex shrink>

            <!--:class=""-->

          <div style="width: 27px; height: 27px">
            <v-icon v-if="valueChanged" slot="activator" color="secondary">
              edit
            </v-icon>
            <v-tooltip v-else-if="field.valueID" top>
              <template #activator="{on}">
                <div
                    v-if="$store.state.darkTheme"
                    v-on="on"
                    class="c-d-flex c-align-center c-justify-center fill-height"
                    style="width: 27px; height: 27px; border-radius: 50%; cursor: pointer; position: relative;"
                    :style="{ background: editByColor }"
                >
                                    <span
                                        
                                        class="caption font-weight-bold"
                                        style="line-height: 0"
                                        @click="showHistory = true"
                                    >
                                      {{ editedByInitial }}
                                    </span>
                  
                  <!--                          <div style="width: 100%; height: 100%; position: absolute; opacity: 5%;border-radius: 50%;" :style="{background: editByColor}" ></div>-->
                </div>
                <div
                    v-else
                    v-on="on"
                    class="c-d-flex c-align-center c-justify-center fill-height"
                    style="width: 27px; height: 27px; border-radius: 50%; cursor: pointer; position: relative;"
                    :style="{ border: `2px solid ${editByColor}` }"
                >
                                    <span
                                        :style="{ color: editByColor }"
                                        class="caption font-weight-bold"
                                        style="line-height: 0"
                                        @click="showHistory = true"
                                    >
                                      {{ editedByInitial }}
                                    </span>
                  
                  <!--                          <div style="width: 100%; height: 100%; position: absolute; opacity: 5%;border-radius: 50%;" :style="{background: editByColor}" ></div>-->
                </div>
              </template>
              <span> {{ editedByName + ' - ' + getLongDate(field.lastEdited) }}</span>
            </v-tooltip>
            
            <v-tooltip v-else top>
              <v-avatar
                  style="opacity: 10%"
                  class="greyType"
                  slot="activator"
                  :size="25"
              >
              </v-avatar>
              <span>Nothing Saved</span>
            </v-tooltip>
          </div>
          
            <template v-if="valueChanged">
                <v-tooltip top>
                    
                    <span>Changes Made Click On Save to Apply Changes</span>
                </v-tooltip>
            </template>
          
          <app-field-history-viewer
              :user-info="userInfo"
              v-model="showHistory"
              :field="field"
          />



        </v-flex>
<!--Field Value-->
        <v-flex xs7 class="pr-2">
            <app-field-type1
                    v-if="field.type === 1"
                    v-model="field"
                    :readOnly="readOnly || readOnlyField"
                    @forceSave="$emit('forceSave')"
            />
            <app-field-type2
                    v-if="field.type === 2"
                    v-model="field"
                    :readOnly="readOnly || readOnlyField"
                    @forceSave="$emit('forceSave')"
            />
            <app-field-type3
                    v-if="field.type === 3"
                    v-model="field"
                    @forceSave="$emit('forceSave')"
                    :readOnly="readOnly || readOnlyField"
            />
            <app-field-type4
                    v-if="field.type === 4"
                    v-model="field"
                    :readOnly="readOnly || readOnlyField"
                    @forceSave="$emit('forceSave')"
            />
            <app-field-type5
                    v-if="field.type === 5"
                    v-model="field"
                    :readOnly="readOnly || readOnlyField"
                    @forceSave="$emit('forceSave')"
            />
            <app-field-type6
                    v-if="field.type === 6"
                    v-model="field"
                    @forceSave="$emit('forceSave')"
                    :user-info="userInfo"
                    :readOnly="readOnly || readOnlyField"
                    :folderDocs="folderDocs"
                    :folderMails="folderMails"
                    :add-doc-to-task-btn="addDocToTaskBtn"
                    @addDocToTask="$emit('addDocToTask', $event)"
            />
            <app-field-type13
                    v-if="field.type == 13"
                    v-model="field"
                    @forceSave="$emit('forceSave')"
                    :readOnly="readOnly || readOnlyField"
            />
        </v-flex>
    </v-layout>

</template>

<script>
    import {codeBaseMixin} from "../../codeBaseMixin";
    import FieldType1 from './FieldSetFieldTypeComponents/FieldType1';
    import FieldType2 from './FieldSetFieldTypeComponents/FieldType2';
    import FieldType3 from './FieldSetFieldTypeComponents/FieldType3';
    import FieldType4 from './FieldSetFieldTypeComponents/FieldType4';
    import FieldType5 from './FieldSetFieldTypeComponents/FieldType5';
    import FieldType6 from './FieldSetFieldTypeComponents/FieldType6';
    import FieldType13 from './FieldSetFieldTypeComponents/FieldType13';
    import FieldHistoryViewer from './FieldHistory/FieldHistoryViewer';
    import {getInitials} from "@/codeFunctions";
    import {getColor} from "random-material-color/dist/randomColor";
    export default {
        mixins: [codeBaseMixin],
        components: {
            appFieldType1: FieldType1,
            appFieldType2: FieldType2,
            appFieldType3: FieldType3,
            appFieldType4: FieldType4,
            appFieldType5: FieldType5,
            appFieldType6: FieldType6,
            appFieldType13: FieldType13,
            appFieldHistoryViewer: FieldHistoryViewer,
        },
        props: {
            field: Object,
            userInfo: Object,
            readOnly: {
                type: Boolean,
                default: false,
            },
            folderMails: Array,
            folderDocs: Array,
            secureField: Boolean,
            readOnlyField: Boolean,
            addDocToTaskBtn: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                showHistory: false
            }
        },
        computed: {
          
          editedByName() {
            if (!this.field.valueID) return ''
            if (this.field.valueContactName) return this.field.valueContactName.trim()
            return !this.field.valueUserID ? 'System' : (this.field.valueUserName + ' ' + this.field.valueUserSurname).trim()
          },
          
          editedByInitial() {
            return this.editedByName ? getInitials(this.editedByName) : null
          },
          
          editByColor() {
            return getColor({ shades: ['600', '700', '800'], text: this.editedByName})
          },
          
            valueChanged() {
                if (this.field.value != null && this.field.value != '') {
                    return this.field.value != this.field.oldValue
                } else {
                    return false
                }
            },

            fieldClass() {
                let tClass = '';

                if (this.field.saveStatus == 1) {tClass = 'red20'}
                if (this.field.saveStatus == 2) {tClass = 'primary10'}

                return tClass
            },

            distinctClass() {
                if (this.field.distinct == 1) {
                    return 'red--text'
                } else {
                    switch (this.field.saveStatus) {
                        case 0: {return 'white--text'}
                        case 1: {return 'red--text text--lighten-5'}
                        case 2: {return 'green--text text--lighten-5'}
                        default: {return 'white--text'}
                    }
                }
            },
            requiredClass() {
                if (this.field.required == 1) {
                    return 'red--text'
                } else {
                    switch (this.field.saveStatus) {
                        case 0: {return 'white--text'}
                        case 1: {return 'red--text text--lighten-5'}
                        case 2: {return 'green--text text--lighten-5'}
                        default: {return 'white--text'}
                    }
                }
            }
        }
    }
</script>

<style scoped>
    .editedField {
        background: #efefef;
    }

    .uniqueField {
        /*border-left: 3px solid var(--v-primary-base);*/
    }
</style>
