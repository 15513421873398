<template>
    <v-card
            class="pl-0"
            flat
    >
        <div>
            <v-layout column>
                <v-flex shrink class="px-1">
                    <v-layout align-center justify-start row>
                        <v-flex shrink class="text-xs-center">
                            <v-tooltip top>
                                <v-avatar
                                          slot="activator" color="secondary"
                                          size="26" style="cursor: pointer"
                                          @click="$emit('openDraft', draft)"
                                >
                                    <template>
                                        <v-icon small dark>note</v-icon>
                                    </template>
                                </v-avatar>
                                <span>Open Draft</span>
                            </v-tooltip>
                        </v-flex>

                        <v-flex xs12 class="pl-1">
                            <v-layout class="py-0 my-0" row wrap>
                                <v-flex xs12 class="font-weight-medium text--small">
                                    Draft Note
                                </v-flex>
                                <v-flex xs12 style="font-size: 0.8em" class="font-weight-regular">
                                    {{ getLongDate(draft.created) }}
                                </v-flex>
                            </v-layout>
                        </v-flex>
                        <v-flex shrink>
                            <v-layout row justify-end>
                                <v-flex shrink class="text-xs-right pa-1">
                                    <v-tooltip top>
                                        <v-btn
                                                @click="deleteDraftFN(draft.draftID)"
                                                slot="activator"
                                                flat
                                                icon
                                                color="secondary"
                                                class="ma-0 pa-0"
                                                small
                                                :disabled="removingDraft"
                                                :loading="removingDraft"
                                        >
                                            <v-icon>delete</v-icon>
                                        </v-btn>
                                        <span>Delete Draft</span>
                                    </v-tooltip>
                                </v-flex>
                                <v-flex shrink class="text-xs-right pa-1">
                                    <v-tooltip top>
                                        <v-btn
                                                @click="$emit('openDraft', draft)"
                                                slot="activator"
                                                flat
                                                icon
                                                color="secondary"
                                                class="ma-0 pa-0"
                                                small
                                        >
                                            <v-icon>edit</v-icon>
                                        </v-btn>
                                        <span>Open Draft</span>
                                    </v-tooltip>
                                </v-flex>
                            </v-layout>
                        </v-flex>
                    </v-layout>
                </v-flex>
            </v-layout>
        </div>
    </v-card>
</template>

<script>
    import {codeBaseMixin} from "../../../../codeBaseMixin";
    import {eventBus} from "../../../../main";

    export default {
        mixins: [codeBaseMixin],
        props: {
            selectedStep: Object,
            userInfo: Object,
            task: Object,
            draft: Object,
            deleteDraftFN: Function
        },
        data() {
            return {
                removingDraft: false
            }
        },
        methods: {

        }
    }
</script>

<style scoped>

</style>
