<template>
  <div class="fill-height of-y c-d-flex">
    
    <div class="fill-height of-y" style="width: 30%; position: relative; background: var(--page-back)" >
      
      <div style="position: sticky; top: 0; z-index: 1; background: var(--page-back)">
        
        <div class="px-2 pt-2 pb-1 c-d-flex c-align-center">
          <v-autocomplete
              solo
              prepend-inner-icon="forum"
              placeholder="All Channels"
              v-model="selectedChannelId"
              :items="sortedChannels"
              item-text="internalName"
              item-value="id"
              item-avatar="chat_bubble"
              :clearable="true"
              hide-details
          >
            <template #item="{item, props}">
              <v-list-tile-avatar>
                <v-icon :color="item.color || undefined">{{ item.icon || 'chat_bubble' }}</v-icon>
              </v-list-tile-avatar>
              <v-list-tile-content>
                <v-list-tile-title>{{ item.internalName }}</v-list-tile-title>
              </v-list-tile-content>
            </template>
          </v-autocomplete>
          
          
          <!--          <v-btn-->
          <!--              icon-->
          <!--              color="secondary"-->
          <!--              class="ml-2 mr-0"-->
          <!--              :elevation="5"-->
          <!--              @click:clear="loadMore(true)"-->
          <!--              :disabled="loading"-->
          <!--              :loading="loading"-->
          <!--          >-->
          <!--            <v-icon>search</v-icon>-->
          <!--          </v-btn>-->
        
        
        </div>
        
        <div class="px-2 pt-1 c-d-flex c-align-center">
          <v-text-field
              solo
              prepend-inner-icon="search"
              placeholder="Search Channels"
              v-model="chatSearchStr"
              :clearable="true"
              hide-details
          ></v-text-field>
        </div>
        
        <div class="px-2">
          <v-btn block :class="$store.state.chat.archiveView ? 'amber darken-2' : null" @click="$store.state.chat.archiveView = !$store.state.chat.archiveView">
            <div style="text-transform: none !important; width: 100%; height: 100%;" :style="{color: $store.state.chat.archiveView ? '#000' : 'unset'}" class="c-d-flex c-align-center">
              <v-icon left>{{ $store.state.chat.archiveView ? 'unarchive' : 'archive' }}</v-icon>
              <div class="c-flex-grow-1 text-xs-left">
                {{ $store.state.chat.archiveView ? 'Active' : 'Archived' }} Chats
              </div>
              <div class="caption">
                ({{chatsCount}})
              </div>
            </div>
          </v-btn>
        </div>
        
        <div>
          <v-divider></v-divider>
        </div>
      </div>
      
      <v-list
          class="py-0 px-0 mx-0"
          :two-line="!selectedChannelId || selectedChannelId === -1"
      >
        
        
        <v-list-tile
            v-for="chat in chatsPostArchiveFilter"
            :key="chat.chatKey"
            color="primary"
            :style="selectedChatId === chat.chatKey ? 'background: #00695C20' : null"
            :value="selectedChatId === chat.chatKey"
            @click="selectedChatId = selectedChatId === chat.chatKey ? null : chat.chatKey"
        >
          <template v-if="chat.type === 1">
            <v-list-tile-action class="pr-3" style="min-width: unset !important;">
              <v-icon :color="selectedChatId === chat.chatKey ? 'primary' : null">chat_bubble</v-icon>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title>{{chat.contactName}}</v-list-tile-title>
              <v-list-tile-sub-title class="py-0 my-0" v-if="chat.fkMessageId">
                <strong>{{ chat.sentByStr }}</strong>: {{chat.message || 'Sent an attachment'}}
              </v-list-tile-sub-title>
              <v-list-tile-sub-title v-if="!selectedChannelId" class="caption">
                {{ chat.chanelInternalName }}
              </v-list-tile-sub-title>
            </v-list-tile-content>
          </template>
          <template v-else>
            <v-list-tile-action class="pr-3" style="min-width: unset !important;">
              <v-icon :color="selectedChatId === chat.chatKey ? 'primary' : null">speaker_notes</v-icon>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title>{{ chat.taskTitle }}</v-list-tile-title>
              <v-list-tile-sub-title class="py-0 my-0" v-if="chat.fkMessageId">
                <strong>{{ chat.sentByStr }}</strong>: {{chat.message || 'Sent an attachment'}}
              </v-list-tile-sub-title>
              <v-list-tile-sub-title v-if="!selectedChannelId || selectedChannelId === -1" class="caption" >
                #{{ chat.fkRefTaskId }}
              </v-list-tile-sub-title>
            </v-list-tile-content>
          </template>
          
          <v-list-tile-action class=" c-d-flex c-flex-column c-justify-space-around c-align-end" style="min-width: unset !important;">
            <transition
                enter-active-class="scale-in-center"
                leave-active-class="scale-out-center"
                mode="out-in"
            >
              <div
                  v-if="chat.unread"
                  :key="chat.unread"
                  class="caption py-1 px-1 text-center white--text"
                  style="min-width: 20px; display: inline-block; line-height: 1; background: var(--v-secondary-base); border-radius: 10px"
              >
                <span style="padding-right: 3px; padding-left: 3px">{{ chat.unread }}</span>
              </div>
            </transition>
            <span class="caption">{{ chat.dateTitleShort }}</span>
          </v-list-tile-action>
        </v-list-tile>
      </v-list>
      
      <div style="position: sticky; bottom: 0; right: 0; width: 100%; z-index: 1" class="pa-2 text-xs-right">
        
        <v-tooltip top>
          <template #activator="{on}">
            <app-browse-contacts v-if="userInfo && userInfo.userID" ref="contactView" :multi-select="false" :registered-only="true" :ignore-management="true">
              <template #title>Select Contact</template>
              <template #activator>
                <v-btn
                    icon
                    large
                    color="secondary"
                    class="elevation-4"
                    v-on="on"
                >
                  <v-icon>add</v-icon>
                </v-btn>
              
              </template>
              
              <template #actions="{selectedContacts, hideDialog}">
                <div class=" text-xs-right">
                  <v-btn flat class="mx-1" small @click="() => hideDialog()">
                    Cancel
                  </v-btn>
                  
                  <v-btn :loading="loadingAvailableChats" class="mx-1 mr-2" color="secondary" small :disabled="!selectedContacts.length || loadingAvailableChats" @click="() => getAvailableChats(selectedContacts[0].id, hideDialog)">
                    Select Contact
                  </v-btn>
                </div>
              </template>
            </app-browse-contacts>
          </template>
          <span>Start Chat</span>
        </v-tooltip>
      </div>
    </div>
    
    <div class="fill-height of-y c-flex-grow-1 pa-2" style="width: 70%">
      
      <transition
          mode="out-in"
          enter-active-class="fade-in"
          leave-active-class="fade-out"
      >
        <v-card
            v-if="selectedChat"
            :key="selectedChat.chatKey"
            class="fill-height of-y"
        >
          <app-chat-messages :chat="selectedChat" ref="messageList">
            <template #header="{contact, lastSeen, startingUp}">
              <div class="c-d-flex c-align-center py-0 my-0 pl-3" v-if="selectedChat.fkRefContactId" style="background: var(--chat-bubble-right-background)">
                <v-icon color="white">person</v-icon>
                <div class="c-flex-grow-1 white--text px-2 subheading py-2">
                  {{contact.fullName}}
                  <div class="caption" v-if="contact.lastSeen || lastSeen">
                    Last Seen: {{formatLastSeen(lastSeen || contact.lastSeen)}}
                  </div>
                </div>
                
                
                
                <v-menu>
                  <template #activator="{on}">
                    <v-btn
                        icon
                        flat
                        color="white"
                        v-on="on"
                        :disabled="menuLoading"
                        :loading="menuLoading"
                    >
                      <v-icon>more_vert</v-icon>
                    </v-btn>
                  </template>
                  
                  <v-card>
                    <v-list>
                      
                      <v-list-tile :disabled="startingUp" @click="showBusinessCard = true">
                        <v-list-tile-avatar>
                          <v-icon>contact_phone</v-icon>
                        </v-list-tile-avatar>
                        <v-list-tile-content>
                          <v-list-tile>Contact Information</v-list-tile>
                        </v-list-tile-content>
                      </v-list-tile>
                      
                      <v-list-tile :disabled="startingUp" @click="showMoreContactInfo = true">
                        <v-list-tile-avatar>
                          <v-icon>info</v-icon>
                        </v-list-tile-avatar>
                        <v-list-tile-content>
                          <v-list-tile>More Information</v-list-tile>
                        </v-list-tile-content>
                      </v-list-tile>
                      
                      <v-list-tile @click="showSelectFolder = true">
                        <v-list-tile-avatar>
                          <v-icon>folder</v-icon>
                        </v-list-tile-avatar>
                        <v-list-tile-content>
                          <v-list-tile>Link to folder</v-list-tile>
                        </v-list-tile-content>
                      </v-list-tile>
                      
                      <v-list-tile @click="archiveChat">
                        <v-list-tile-avatar>
                          <v-icon>{{selectedChat.isArchived ? 'unarchive' : 'archive'}}</v-icon>
                        </v-list-tile-avatar>
                        <v-list-tile-content>
                          <v-list-tile>{{selectedChat.isArchived ? 'Restore Chat' : 'Archive Chat'}}</v-list-tile>
                        </v-list-tile-content>
                      </v-list-tile>
                    </v-list>
                  </v-card>
                  
                </v-menu>
                
                <app-contact-information-dlg
                    v-if="!startingUp && !menuLoading && showMoreContactInfo"
                    :shown.sync="showMoreContactInfo"
                    :contact="contact"
                />
                
                <app-contact-business-card-dlg v-if="showBusinessCard" :contact="contact" :shown.sync="showBusinessCard"></app-contact-business-card-dlg>
              </div>
              
              <div class="primary c-d-flex c-align-center py-0 my-0 pl-3" v-else>
                <v-icon color="white">speaker_notes</v-icon>
                <div class="c-flex-grow-1 white--text px-2 subheading py-2 text-truncate">
                  {{selectedChat.taskTitle}}
                  <div class="caption">
                    #{{ selectedChat.fkRefTaskId }}
                  </div>
                </div>
                
                
                
                <v-menu>
                  <template #activator="{on}">
                    <v-btn
                        icon
                        flat
                        color="white"
                        v-on="on"
                        :disabled="menuLoading"
                        :loading="menuLoading"
                    >
                      <v-icon>more_vert</v-icon>
                    </v-btn>
                  </template>
                  
                  <v-card>
                    <v-list class="py-0 my-0">
                      <v-list-tile @click="archiveChat">
                        <v-list-tile-avatar>
                          <v-icon>{{selectedChat.isArchived ? 'unarchive' : 'archive'}}</v-icon>
                        </v-list-tile-avatar>
                        <v-list-tile-content>
                          <v-list-tile>{{selectedChat.isArchived ? 'Restore Chat' : 'Archive Chat'}}</v-list-tile>
                        </v-list-tile-content>
                      </v-list-tile>
                    </v-list>
                    <task-header-with-loader :task-id="selectedChat.fkRefTaskId" @taskSelected="taskSelected"/>
                  </v-card>
                
                </v-menu>
              </div>
              
              <div v-if="selectedChat.archived" class="amber darken-2 py-1 px-3 text-xs-center caption" style="color: #000">
                {{ archiveDisplayString }}
              </div>
              
            </template>
          </app-chat-messages>
        </v-card>
      </transition>
    
    
    
    </div>
    
    
    
    <app-input-dlg
        :show-dialog="showSelectChat"
        title="Select Channel"
        :cancel-button="true"
        :show-input-box="false"
        @dismiss="showSelectChat = false"
        default-input=""
    >
      
      <template #body>
        <v-autocomplete
            solo
            prepend-inner-icon="forum"
            placeholder="All Channels"
            v-model="selectedStartChat"
            :items="availableChats"
            item-text="chanelInternalName"
            item-value="chatKey"
            :return-object="true"
            item-avatar="chat_bubble"
            :clearable="true"
            hide-details
        >
          <template #item="{item, props}">
            <v-list-tile-content>
              <v-list-tile-title>{{ item.chanelInternalName }}</v-list-tile-title>
            </v-list-tile-content>
          </template>
        </v-autocomplete>
      </template>
      
      <template #actions>
        <div class=" text-xs-right">
          <v-btn flat class="mx-1" small @click="showSelectChat = false">
            Cancel
          </v-btn>
          
          <v-btn class="mx-1 mr-2" color="secondary" small @click="() => startChat()">
            Select Channel
          </v-btn>
        </div>
      </template>
      
    </app-input-dlg>
    
    
    <folder-dlg
        v-if="showSelectFolder"
        :show-dialog="showSelectFolder"
        @dismiss="showSelectFolder = false"
        :get-task-header="getTaskHeader"
        :select-dlg="true"
        :user-info="userInfo"
        :folders="foldersSelect"
        dlg-type="allFoldersSelect"
        @appendFolderData="ev => foldersSelect = ev"
        v-model="selectedLinkFolder"
        :local-data="localData"
        :show-sync="showSync"
        @input="linkContactToFolder"
    />
    
    
<!--    :filter-groups=""-->
    
    
  </div>
</template>

<script>

import {Chat, formatLastSeen, loadChannelChats, loadStartupData} from "@/chat/chatFunctions";
import {mapGetters} from "vuex";
import {arrSplice} from "@/codeFunctions";
import AppChatMessages from "@/chat/AppChatMessages.vue";
import format from "date-fns/format";
import appInputDlg from "@/components/General/InputDlg.vue";
import AppBrowseContacts from "@/contacts/AppBrowseContacts.vue";
import AppCreateLinkContact from "@/contacts/AppCreateLinkContact.vue";
import FolderDlg from "@/Folders/FolderDlg.vue";
import AppContactInformationDlg from "@/contacts/AppContactInformationDlg.vue";
import TaskHeaderWithLoader from "@/components/General/TaskHeaderWithLoader.vue";
import {eventBus} from "@/main";
import {rtcmRelay} from '@/chat/chatFunctions'
import AppContactBusinessCardDlg from "@/contacts/AppContactBusinessCardDlg.vue";

export default {
  components: {
    AppContactBusinessCardDlg,
    TaskHeaderWithLoader,
    AppContactInformationDlg,
    FolderDlg,
    AppCreateLinkContact, AppBrowseContacts,
    appInputDlg,
    AppChatMessages
    
  },
  props: {
    curView: String,
    localData: Object,
    showSync: Boolean,
    taskList: Array,
    getTaskHeader: Function
  },
  data() {
    return {
      console,
      chatSearchStr: null,
      selectedChannelId: null,
      selectedChatId: null,
      showSelectChat: false,
      availableChats: [],
      loadingAvailableChats: false,
      selectedStartChat: null,
      showSelectFolder: false,
      showMoreContactInfo: false,
      showBusinessCard: false,
      foldersSelect: {
        folderGroups: [],
        folders: []
      },
      selectedLinkFolder: null,
      menuLoading: false
    }
  },
  mounted() {
    loadStartupData()
    rtcmRelay.on('taskClosed', this.taskClosedEvent)
  },
  beforeDestroy() {
    rtcmRelay.off('taskClosed', this.taskClosedEvent)
  },
  watch: {
    selectedChannelId: {
      immediate: true,
      handler(val) {
        // if (val)
        //   this.loadChannelChats(val)
      }
    }
  
  },
  methods: {
    formatLastSeen,
    format,
    test() {
      console.log(this.$store.state)
    },
    
    taskClosedEvent({chatKey}) {
      if (this.selectedChatId === chatKey)
        this.selectedChatId = null
      this.$store.state.chat.chats.splice(this.$store.state.chat.chats.findIndex(v => v.chatKey === chatKey))
    },
    
    async archiveChat() {
      try {
        this.menuLoading = true
        const newLinks = await this.$newReq('POST', `chat/archiveChat`, {
          chatKey: this.selectedChat.chatKey,
          status: !this.selectedChat.isArchived
        })
        console.log(newLinks)
        // this.$snack.info(`Contact Linked`)
      } catch (e) {
        console.log(e)
        this.$snack.networkError()
      } finally {
        this.menuLoading = false
      }
    },
    
    async linkContactToFolder(folder) {
      const chat = this.selectedChat
      try {
        this.menuLoading = true
        const newLinks = await this.$newReq('POST', `contacts/linkMultiple`, {
          folderId: folder.id,
          contactIds: [chat.fkRefContactId]
        })
        console.log(newLinks)
        this.$snack.info(`Contact Linked`)
      } catch (e) {
        console.log(e)
        this.$snack.networkError()
      } finally {
        this.menuLoading = false
      }
    },
    
    
    
    
    
    async startChat() {
      const newChat = this.selectedStartChat
      
      const existingChat = this.$store.getters["chat/chatsMapByChatKey"].get(newChat.chatKey)
      if (!existingChat)
        this.$store.state.chat.chats.push(newChat)
      this.selectedChatId = newChat.chatKey
      this.showSelectChat = false
    },
    
    async getAvailableChats(contactId, closeDlg) {
      try {
        this.loadingAvailableChats = true
        const chats = (await this.$newReq('POST', 'chat/startNewChat', {contactId})).map(v => new Chat(v))
        closeDlg()
        this.availableChats = chats
        
        if (this.availableChats.length === 1) {
          this.selectedStartChat = this.availableChats[0]
          this.startChat()
        } else {
          this.showSelectChat = true
          this.selectedStartChat = null
        }
        
        
      } catch (e) {
        console.log(e)
        this.$snack.networkError();
      } finally {
        this.loadingAvailableChats = false
      }
    },
    
    taskSelected(task) {
      eventBus.$emit('gotoTask', task)
    },
    
    // async init() {
    //   try {
    //     this.startingUp = true
    //     const data = await this.$newReq('GET', `contacts/setup/startup`)
    //     this.selectLists = data.selectLists
    //     this.customFields = data.customFields
    //     console.log({data})
    //   } catch (e) {
    //     console.log(e)
    //     this.$snack.networkError()
    //   } finally {
    //     this.startingUp = false
    //   }
    // }
  },
  computed: {
    ...mapGetters('chat', ["channels", "chatsByChannelMap", "chatsMap", "channelChats", "sortedChats", "chatsMapByChatKey"]),
    ...mapGetters('userInfo', ["userInfo"]),
    sortedChannels() {
      return [...this.channels]
          .sort((a, b) => {
            if (a.lastSent && b.lastSent)
              return a.lastSent > b.lastSent ? -1 : 1
            else if (!a.lastSent && b.lastSent)
              return 1
            else if (!b.lastSent && b.lastSent)
              return -1
            else
              return a.internalName > b.internalName ? 1 : -1
          })
          .concat({
            id: -1,
            internalName: "Task Chats",
            icon: "speaker_notes"
          })
    },
    
    chatsPostSort() {
      return [...this.filteredChats]
          .sort((a,b) => {
            // if (a.type !== b.type)
            //   return a.type - b.type
            if (a.sentAsDate.getTime() === b.sentAsDate.getTime())
              return (a.taskTitle || a.channelRef.internalName) > (b.taskTitle || b.channelRef.internalName) ? -1 : 1
            return a.sentAsDate > b.sentAsDate ? -1 : 1
          })
    },
    
    chatsPostArchiveFilter() {
      return [...this.chatsPostSort].filter(v => v.isArchived === this.$store.state.chat.archiveView)
    },
    
    chatsCount() {
      return this.chatsPostSort
          .filter(v => this.$store.state.chat.archiveView !== v.isArchived)
          .length
    },
    
    archiveDisplayString() {
      
      if (!this.selectedChat.archived)
        return null
      
      return `Archived on: ${format(new Date(this.selectedChat.archivedOn), 'YYYY-MM-DD HH:mm:ss')} By: ${this.selectedChat.archivedByStr}`
    },
    
    filteredChats() {
      // TODO Add For All Channels
      const vm = this
      function filterFn(val) {
        return !vm.chatSearchStr || JSON.stringify(val).toLowerCase().includes(vm.chatSearchStr.toLowerCase())
      }
      
      if (!this.selectedChannelId)
        return this.sortedChats.filter(filterFn)
      else if (this.selectedChannelId === -1)
        return this.sortedChats.filter(chat => chat.type === 2).filter(filterFn)
      else {
        return this.sortedChats.filter(chat => chat.fkRefChannelId === this.selectedChannelId).filter(filterFn)
      }
      
    },
    
    
    chatSelectRelay: {
      get() {
        return this.selectedChatId ? [this.selectedChatId] : []
      },
      set(val) {
        console.log(val)
      }
    },
    selectedChat() {
      return this.chatsMapByChatKey.get(this.selectedChatId)
    },
    selectedChannel() {
      return this.chatsMap.get(this.selectedChatId)
    }
  },
}
</script>

<style scoped>

</style>