<template>
    <app-input-dlg
            :show-dialog="showManager"
            :cancel-button="true"
            :show-input-box="false"
            @dismiss="$emit('dismiss')"
            :body-padding="false"
            :width="500"
    >
        <template slot="title">
            <v-card-title class="primary pa-0 ma-0">
                <v-layout row align-center>
                    <v-flex shrink class="px-2">
                        <v-icon color="white">emoji_people</v-icon>
                    </v-flex>
                    <v-flex xs12 class="pa-2 subheading white--text">
                        Out Of Office
                    </v-flex>
                    <v-flex shrink class="px-2">
                        <v-btn
                                small
                                icon
                                flat
                                color="white"
                                class="pa-0 ma-0"
                                @click="$emit('dismiss', true)"
                        >
                            <v-icon small>close</v-icon>
                        </v-btn>
                    </v-flex>
                </v-layout>
            </v-card-title>
        </template>
        <template slot="body">
            <div>
                <div class="pa-3 text-xs-center" v-if="loadingUsers">
                    <v-progress-circular
                            :size="40"
                            :width="5"
                            color="primary"
                            indeterminate
                    />
                </div>
                <template v-else>
                    <v-divider></v-divider>
                    <div class="py-0 px-2">
                        <v-text-field
                                label="Filter Users"
                                solo
                                clearable
                                prepend-icon="search"
                                color="primary"
                                flat
                                :hide-details="true"
                                v-model="filterString"
                                class="pa-0 ma-0"
                                ref="filterUsersBox"
                        ></v-text-field>
                    </div>
                    <div class="pt-0 scrollable" style="max-height: 500px">
                        <div
                                v-for="user in filteredUsers"
                                :key="`filteredUsersOOOManager${user.id}`"
                                class="hover-item"
                                style="cursor: pointer"
                                @click="updateUser(user)"
                        >
                            <v-divider></v-divider>
                            <v-layout row align-center>
                                <v-flex shrink class="pl-2">
                                    <v-progress-circular
                                            v-if="updatingUsers.includes(user.id)"
                                            :size="18"
                                            :width="3"
                                            color="primary"
                                            indeterminate
                                    />
                                    <v-icon v-else class="pa-0 ma-0" :class="intToBool(user.outOfOffice) ? 'secondary--text' : 'primary--text'">person</v-icon>
                                </v-flex>
                                <v-flex class="px-2 caption py-2" :class="intToBool(user.outOfOffice) ? 'greyType--text' : ''">
                                    {{user.description}}
                                </v-flex>
                                <v-flex shrink class="pr-2">
                                    <v-btn
                                            small
                                            icon
                                            flat
                                            :color="intToBool(user.outOfOffice) ? 'grey' : 'primary'"
                                            class="pa-0 ma-0"
                                    >
                                        <v-icon>{{intToBool(user.outOfOffice) ? 'check_box' : 'check_box_outline_blank'}}</v-icon>
                                    </v-btn>
                                </v-flex>
                            </v-layout>
                        </div>
                    </div>
                </template>
            </div>
        </template>
    </app-input-dlg>
</template>

<script>
    import InputDlg from "../../components/General/InputDlg";
    import {codeBaseMixin} from "../../codeBaseMixin";
    export default {
        mixins: [codeBaseMixin],
        components: {
            appInputDlg : InputDlg
        },
        props: {
            userInfo: Object,
            showManager: Boolean,
        },
        data() {
            return {
                loadingUsers: false,
                filterString: null,
                users: [],
                updatingUsers: []
            }
        },
        watch: {
            showManager: {
                immediate: true,
                handler(val) {
                    if (val) {
                        this.filterString = null;
                        this.startOutOfOfficeManager();
                    }
                }
            }
        },
        methods: {
            updateUser(user) {
                this.updatingUsers.push(user.id);
                this.fetch({method: 'GET', url:`/localUsers/OOO/setUser/${this.userInfo.entityID}/${user.id}/${this.boolToInt(!this.intToBool(user.outOfOffice))}`})
                    .then(() => {
                        user.outOfOffice = this.boolToInt(!this.intToBool(user.outOfOffice));
                        this.updatingUsers = this.updatingUsers.filter(obj => obj != user.id);
                        this.showSnack('Info', 'Changes Saved', 'Close', 'primary')
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e);
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
                        this.updatingUsers = this.updatingUsers.filter(obj => obj != user.id);
                    })
            },

            startOutOfOfficeManager() {
                this.users = [];
                this.loadingUsers = true;
                this.fetch({method: 'GET', url:`/localUsers/OOO/startup/${this.userInfo.entityID}/${this.userInfo.userID}`})
                    .then((data) => {
                        this.users = data;
                        this.loadingUsers = false;
                        this.$nextTick(() => {
                            this.$refs.filterUsersBox.focus();
                        })
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e);
                        this.loadingUsers = false;
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                        this.$emit('dismiss')
                    })
            }
        },
        computed: {
            filteredUsers() {
                return this.users.filter(obj => this.blankString(this.filterString) || (!this.blankString(this.filterString) && obj.description.toLowerCase().includes(this.filterString.toLowerCase())))
                .sort((a,b) => a['description'] < b['description'] ? -1 : 1);
            }
        }
    }
</script>

<style scoped>

</style>
