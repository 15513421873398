<template>
    <div
            style="overflow-y: auto; overflow-x: hidden"
    >
        <div class="treeNode" style="overflow-y: auto; overflow-x: hidden">
<!--Drop Icon-->
            <div>
                <template v-if="this.node.type === 0">
                    <template v-if="showDrop">
                        <v-btn
                                small
                                icon
                                flat
                                color="primary"
                                class="pa-0 ma-0"
                                @click="$emit('expandFolder', {node: node, drop: !dropped})"
                                style="width: 24px; height: 24px"
                        >
                            <v-icon>{{ dropped ? 'expand_less' : 'expand_more' }}</v-icon>
                        </v-btn>
                    </template>
                    <div style="width: 24px" v-else/>
                </template>
                <div style="width: 24px" v-else/>
            </div>
<!--Node Body-->
            <div
                    class="treeNode nodeContent hide-parent"
                    :class="selected ? 'selected' : ''"
                    style="width: 100%; overflow-y: auto; overflow-x: hidden"

                    @click="node.type === 0 ?  $emit('expandFolder', {node: node, drop: !dropped}) : (managerOptions ? $emit('select', node) : null)"

                    :draggable="node.type <= 1"
                    @drag="$event.preventDefault()"
                    @dragstart="setDataTransfer($event)"
                    @dragover="node.type === 0 ? $event.preventDefault() : null"
                    @drop="droppedEvent($event)"
            >
<!--Icon-->
                <v-icon
                        :color="iconColor"
                        style="margin: 0 3px 0 3px"
                        v-if="!loadingNode"
                        @click="!managerOptions && node.type !== 0 ? $emit('select', node.key) : null"
                >
                    {{icon}}
                </v-icon>
                <div v-else style="padding: 0 5px 0 5px; height: 24px; overflow-y: hidden; overflow-x: hidden">
                    <v-progress-circular

                            slot="activator"
                            :size="22"
                            :width="2"
                            indeterminate
                            color="primary"
                    />
                </div>

<!--Item-->
                <div style="width: 100%; overflow-y: auto; overflow-x: hidden" @click="!managerOptions && node.type !== 0 ? $emit('select', node.key) : null">
                    <template v-if="node.type === 0">
                        <repo-tree-folder :node="node"/>
                    </template>
                    <template v-if="node.type === 1">
                        <repo-tree-doc :node="node" :selected="selected"/>
                    </template>
                </div>
<!--Buttons-->
                <div class="hide-child" v-if="managerOptions && node.key !== 'rf--1'">
                    <v-btn
                            small
                            icon
                            flat
                            color="grey"
                            class="pa-0 ma-0"
                            @click.stop="showMenuFn(node, $event)"
                            style="width: 24px; height: 24px"
                    >
                        <v-icon small>tune</v-icon>
                    </v-btn>
                </div>
            </div>

        </div>
        <div v-if="node.type === 0 && dropped" style="margin-left: 12px">
            <div v-for="(childNode, i) in sortedChildren" :key="childNode.key + i">
                <node-component
                        :node="childNode"
                        :drop-keys="dropKeys"
                        @expandFolder="$emit('expandFolder', $event)"
                        @select="$emit('select', $event)"
                        :selected-node-key="selectedNodeKey"
                        :show-menu-fn="showMenuFn"
                        @updateDocument="$emit('updateDocument', $event)"
                        @updateFolder="$emit('updateFolder', $event)"
                        :get-parents-fn="getParentsFn"
                        :selected-keys="selectedKeys"
                        :manager-options="managerOptions"
                />
            </div>
        </div>
    </div>

</template>

<script>

import RepoTreeFolder from "@/components/General/Repo Tree/RepoTreeFolder";
import RepoTreeDoc from "@/components/General/Repo Tree/RepoTreeDoc";
import {mapGetters} from "vuex";

export default {
    name: 'nodeComponent',
    components: {RepoTreeDoc, RepoTreeFolder},
    props: {
        node: {type: Object, required: true},
        dropKeys: [Array, Boolean],
        selectedNodeKey: String,
        showMenuFn: Function,
        getParentsFn: Function,
        selectedKeys: Array,
        managerOptions: Boolean
    },
    data() {
        return {
            loadingNode: false
        }
    },
    watch: {},
    methods: {
        droppedEvent(dropEvent) {
            try {
                let dropData = dropEvent.dataTransfer.getData('text');
                dropData = JSON.parse(dropData);

                if (dropData.type && dropData.type === 'node') {
                    let dropNode = dropData.data

                    if (dropNode.type === 0) {
                        // Folder

                        // Check That The Drop Node Is Not Going To Create A circular Ref
                        let parents = this.getParentsFn(this.node.parent)

                        if (parents.includes(`rf-${dropNode.id}`) || dropNode.id === this.node.id) {
                            this.$snack.error('Invalid Folder')
                        } else {
                            this.loadingNode = true;
                            this.$newReq('patch', `docRepo/InsertUpdateFolder/${this.userInfo.entityID}`, {
                                id: dropNode.id,
                                description: dropNode.description,
                                parent: this.node.id
                            })
                                    .then(folder => {
                                        this.$snack.info('Changes Saved')
                                        this.$emit('updateFolder', folder)
                                    })
                                    .catch(e => {
                                        console.log(e);
                                        this.$snack.networkError()
                                    })
                                    .finally(() => {this.loadingNode = false})
                        }
                    } else {
                        // Document
                        this.loadingNode = true;
                        this.$newReq('patch', `docRepo/UpdateDocument/${this.userInfo.entityID}/${this.userInfo.userID}`, {
                            id: dropNode.id,
                            description: dropNode.description,
                            users: dropNode.users,
                            groups: dropNode.groups,
                            deleteFlag: dropNode.deleteFlag,
                            parent: this.node.id
                        })
                                .then(doc => {
                                    this.$snack.info('Changes Saved')
                                    this.$emit('updateDocument', doc)
                                })
                                .catch(e => {
                                    console.log(e);
                                    this.$snack.networkError()
                                })
                                .finally(() => {this.loadingNode = false})
                    }
                }
            } catch (e) {
                console.log(e);
            }
        },

        setDataTransfer(event) {
            event.dataTransfer.setData('text', `${JSON.stringify({type: 'node', data: this.node})}`);
        },
    },
    computed: {
        ...mapGetters('userInfo', ['userInfo']),

        sortedChildren() {
            return [...this.node.children].sort((a,b) => a.type === b.type ? (a.description > b.description ? 1 : -1) : (a.type > b.type ? 1 : -1))
        },



        icon() {
            switch (this.node.type) {
                case 0: return this.node.id !== -1 ? 'folder' : 'delete'
                case 1: return !this.node.deleteFlag ? 'description' : 'restore_page'
                default: return  'contact_page'
            }
        },

        iconColor() {
            switch (this.node.type) {
                case 0: return this.node.id !== -1 ? 'yellow darken-2' : 'secondary'
                default: return this.selected ? 'primary' : 'grey'
            }
        },


        showDrop() {
            return this.node.type === 0 && this.node.children.length
        },

        dropped() {
            return this.node.type === 0 && (this.dropKeys === true || this.dropKeys.includes(this.node.key))
        },

        selected() {
            if (this.managerOptions) {
                return this.node.key === this.selectedNodeKey
            } else {
                return this.selectedKeys.includes(this.node.key)
            }

        }
    },
}
</script>

<style scoped>
    .treeNode {
        display: flex;
        align-items: center;
        justify-content: left;
    }

    .nodeContent:hover {
        background: var(--hover-item-background);
    }

    .nodeContent {
        cursor: pointer;
        border-radius: 3px;
        margin: 1px 0 1px 0;
        padding-right: 3px;
    }

    /*.nodeContent.selected:hover {*/
    /*    background: #d0d0d0;*/
    /*}*/
    .nodeContent.selected {
        background: var(--selected-item);
    }
</style>