<template>

    <div @click="$openCalendarEvent(event)">

            <div class=" pa-1 hover-item" :style="`border-left: 5px solid ${borderColors[(groupCalColor || event.color) || 'blue']}`">
                <div class="c-d-flex align-center">
                    <div>
                        <v-avatar
                                ripple
                                :color="(groupCalColor || event.color) || 'blue'"
                                size="26"
                                style="cursor: pointer"
                        >
                            <v-icon small dark>{{ event.icon || 'event' }}</v-icon>
                        </v-avatar>
                    </div>

                    <div class="c-flex-grow-1 ml-1">
                        <div class="font-weight-medium text--small">
                            {{ event.ownerRef.fullName }}
                        </div>
                        <div class="caption font-weight-light text-truncate">
                            Event Date: {{ event.startLongStr }}
                        </div>
                    </div>

                    <v-tooltip top v-if="event.done">
                        <v-icon
                                class="mr-2"
                                :color="groupCalColor || event.color"
                                slot="activator"
                        >
                            done_all
                        </v-icon>
                        {{event.doneRef.user.fullName}} - {{event.doneRef.dateStr}}
                    </v-tooltip>

                    <div style="font-size: 0.8em; white-space: nowrap" class="font-weight-regular text-xs-right mr-1">
                        {{ event.shortDate }}
                        <br>
                        {{ event.shortTime }}
                    </div>
                </div>

                <div class="c-d-flex c-align-center">

                    <div class="mt-1 caption font-weight-medium text-truncate c-flex-grow-1" style="min-width: 0">
                        {{ event.title }}
                    </div>

                    <div v-if="showRemoveSubscription && event.subscribed && !event.subscribed.removed" class="">
                        <v-btn
                                flat
                                icon
                                class="pa-0 ma-0"
                                @click.stop="removeNotification()"
                                color="secondary"
                                small
                                :loading="loading"
                                :disabled="loading"
                        >
                            <v-icon small>close</v-icon>
                        </v-btn>
                    </div>
                </div>
            </div>
    </div>
</template>

<script>

import {mapGetters} from "vuex";
import {colors} from "@/codeFunctions";
import {CalendarEvent} from "@/Store/calendar/calendarClass";

export default {
    props: {
        eventID: Object,
        showRemoveSubscription: {
            default: false,
            type: Boolean
        },
        addLeftBorder: {
            default: false,
            type: Boolean
        },
        calEvents2: Object
    },
    computed: {
        // ...mapGetters('cal', ['calendarEvents', "calendarEventsRef"]),

        event() {
            try {
                const res = this.eventID instanceof CalendarEvent ? this.eventID : new CalendarEvent(this.eventID)
                return res
            } catch (e) {
                console.log(e)
                return this.eventID
            }
        },

        // event() {
        //     try {
        //
        //
        //         // console.time("arr")
        //         // const arr = this.calendarEvents
        //         // console.log(arr.length)
        //         // console.timeEnd("arr")
        //         const res = this.calendarEventsRef[this.eventID.id]
        //
        //         return res
        //     } catch (e) {
        //         console.log(e)
        //         return this.eventID
        //     }
        // },
        groupCalColor() {
            return this.event.fkCalGroup ? this.event.groupCalendarRef.color : null
        }
    },
    data() {
        return {
            removingNotification: false,
            borderColors: colors,
            loading: false
        }
    },
    methods: {
        removeNotification() {
            this.loading = true;


            this.event.updateSubscription(true, (success, e) => {
                if (e) {
                    console.log(e)
                    this.$snack.networkError()
                }
                this.loading = false
            })


        }
    },
}
</script>

<style scoped>

</style>