<template>
    <div>
        <calendar-event-full-detail
                :event="event"
                v-if="showEvent"
                v-model="showEvent"
                @eventUpdated="event = calendarEvents.filter(obj => obj.id === event.id).pop()"
                :edit-mode="openInEdit"
                @removed="event = null; showEvent = false; showEvent = null"
        />
        <calendar-item-create ref="createEvent" @input="onCreateCB ? onCreateCB($event) : null"/>
    </div>
</template>

<script>

import CalendarEventFullDetail from "@/components/General/Calendar/CalendarEventFullDetail";
import {calEventOpenEvents} from "@/Calendar View/Event Manager/EventManager";
import {mapGetters} from "vuex";
import CalendarItemCreate from "@/components/General/Calendar/CalendarItemCreate";


export default {
    components: {CalendarItemCreate, CalendarEventFullDetail},
    data() {
        return {
            console: console,
            event: null,
            showEvent: false,
            onCreateCB: null,
            openInEdit: false
        }
    },
    created() {
        calEventOpenEvents.on('openEvent', this.openEvent)
        calEventOpenEvents.on('openEventEdit', this.openEventEdit)
        calEventOpenEvents.on('createEvent', this.createEvent)
        calEventOpenEvents.on('calEventRemoved', this.eventRemoved)
    },
    beforeDestroy() {
        calEventOpenEvents.off('openEvent', this.openEvent)
        calEventOpenEvents.off('createEvent', this.createEvent)
        calEventOpenEvents.off('calEventRemoved', this.eventRemoved)
        calEventOpenEvents.off('openEventEdit', this.openEventEdit)
    },
    methods: {

        eventRemoved(eventID) {
            if (eventID === this.event.id) {
                this.showEvent = null
                this.event = null
            }
        },

        openEventEdit(event) {
            this.event = event;
            this.openInEdit = true;
            this.showEvent = true
        },

        openEvent(event) {
            this.event = event;
            this.openInEdit = false;
            this.showEvent = true
        },
        createEvent({eventConfig, callBack}) {
            this.onCreateCB = callBack || null;
            this.$refs.createEvent.init(eventConfig || {start: new Date()})
        }
    },
    computed: {
        ...mapGetters('cal', ['calendarEvents'])
    }
}
</script>

<style scoped>

</style>