<template>
  
  
  <div class="" :draggable="allowAttachmentDrag ? false : (selectedMessageIds.size ? isSelected : !!selectedMessageIdsSize)" @dragstart="dragStart">
<!--    Draggable {{allowAttachmentDrag ? false : (selectedMessageIds.size ? isSelected : true)}} -&#45;&#45; {{allowAttachmentDrag}}-->
<!--    {{selectedMessageIds.size ? isSelected : true}} I channged this-->
    <div v-if="source.dateTitle" class="pb-2 pt-4 c-d-flex c-justify-center text-caption">
      <div class="pa-2 px-4 elevation-3" style="border-radius: 8px; background: var(--card-back-accent)">
        {{ source.dateTitleStr }}
      </div>
    </div>
    
    <div :class="{
      'bubble-selected': isSelected
    }"
         class="c-d-flex c-align-center py-2"
         @click="!!selectedMessageIdsSize ? handleMessageSelect(source) : null"
         :style="{cursor: selectedMessageIdsSize ? 'pointer' : null}"
    >
      
      <transition
          mode="out-in"
          enter-active-class="scale-in-center"
          leave-active-class="scale-out-center"
      >
        <div class="px-3" v-if="selectedMessageIds.size" :key="isSelected ? 'true' : 'false'">
          <v-icon
              color="secondary"
              medium
              @click.stop="handleMessageSelect(source)"
              class="hover-button"
          >
            {{isSelected ? 'check_box' : 'check_box_outline_blank'}}
          </v-icon>
        </div>
      </transition>
      
      <div class="c-flex-grow-1 c-d-flex bubble-padding" style="position: relative">
        
        <div @contextmenu.stop.prevent="(ev) => handleContextMenu(ev, source)" ref="dragBubble" class="bubble-card elevation-3">
          
          <div class="px-3 pt-2">
            <strong>{{ source.authorName }}</strong>
          </div>
          
          <div class="px-3 pt-1" :class="{'display-1': emojiOnly}" v-if="source.message">{{ source.message }}</div>
          
          
          <div v-if="Array.isArray(source.attachments) && source.attachments.length">
            
            <template v-for="att of source.attachments">
              <template v-if="att.type === 'audio'">
                <div class="px-1 py-1 theme--light c-d-flex c-justify-center">
                  <div
                      :draggable="allowAttachmentDrag"
                      @dragstart="(event) => callDragStartHandler(event, att, 'chatAttachmentAudio')"
                  >
                    <app-voice-note-player
                        :show-download="true"
                        :url="`${serverAddress}/download/document/${att.documentId}/${att.filename}/${token()}`"
                        :show-delete="false"
                    />
                  </div>
                  
                </div>
                
              </template>
              <template v-else-if="att.type === 'image'">
                <div
                    :draggable="allowAttachmentDrag"
                    @dragstart="(event) => callDragStartHandler(event, att, 'chatAttachmentImage')"
                    class="px-2 py-1" style="position: relative"
                    @click="eventBus.$emit('imagePreview', {title: source.authorName, subtitle: `${getLongDate(source.createdAt)} ${getTime(source.createdAt)}`, documentId: att.documentId, filename: att.filename})"
                >
                  
                  <div
                      :style="{
                            width: !att.width ? null : (att.width > 300 || att.height > 300 ? (att.width > att.height ? 300 : 300 * (att.width / att.height)) : att.width) + 'px',
                            height: !att.height ? null : (att.width > 300 || att.height > 300 ? (att.width > att.height ? (300 / (att.width / att.height)) : 300) : att.height) + 'px',
                          }"
                      style="position: relative"
                  >
                    <img
                        :draggable="false"
                        :src="`${serverAddress}/download/imagePreview/300/300/${att.documentId}/${att.filename}/${token()}`"
                        :alt="att.filename"
                        style="max-width: 100%; max-height: 300px; border-radius: 10px"
                    />
                    
                    <a :href="`${serverAddress}/download/document/${att.documentId}/${att.filename}/${token()}`" target="_blank" v-if="att.height >= 52 && att.width >= 52">
                      <v-btn
                          class="ma-2"
                          style="position: absolute; right: 0; top: 0; background: var(--card-back)"
                          icon
                          depressed
                      >
                        <v-icon>download</v-icon>
                      </v-btn>
                    </a>
                  </div>
                </div>
              </template>
              <template v-else-if="att.type === 'file' || att.type === 'pdf'">
                <div
                    :draggable="allowAttachmentDrag"
                    @dragstart="(event) => callDragStartHandler(event, att, 'chatAttachmentDocument')"
                >
                  <v-card class="ma-1" style="border-radius: 10px !important;" flat>
                    <app-general-file-viewer
                        :document-id="att.documentId"
                        :filename="att.filename"
                        :card-props="{flat: true}"
                    />
                  </v-card>
                </div>
              </template>
              <template v-else-if="att.type === 'informationRequest'">
                <div class="px-2 py-1">
                  <app-chat-message-information-request-preview
                      key="InformationRequest"
                      :request="att.request"
                  />
                </div>
              </template>
            </template>
            
          </div>
          
          <div class="c-d-flex c-align-end c-justify-space-between display--text pb-1 px-3">
            
            <div v-if="source.linkedTaskIds.length">
              <v-tooltip top>
                  <template #activator="{on}">
                      <v-icon v-on="on" small class="bubble-task-icon" @click="ev => handleViewTasks(source, ev)">assignment_turned_in</v-icon>
                  </template>
                  Click to view linked tasks
              </v-tooltip>
            </div>
            
            <div style="white-space: nowrap" class="c-ml-auto">
              {{ showFullDate ? source.timeDateStr : source.timeStr }}
            </div>
          </div>
          <div class="bubble-corner"></div>
        </div>
      </div>
    </div>
  </div>
  
  
  
  

</template>

<script>
import {ChatMessage} from "@/chat/chatFunctions";
import {mapGetters} from "vuex";
const tokenMan = require('@/tokenManSite')
import {serverAddress} from "@/axiosRequest";
import AppVoiceNotePlayer from "@/Tasks/TaskView V2/components/Chat Message/AppVoiceNotePlayer.vue";
import AppGeneralFileViewer from "@/components/General/AppGeneralFileViewer.vue";
import AppChatMessageInformationRequestPreview from "@/chat/AppChatMessageInformationRequestPreview.vue";
import {eventBus} from "@/main";
import {getLongDate, getTime} from "@/codeFunctions";

export default {
  components: {AppChatMessageInformationRequestPreview, AppGeneralFileViewer, AppVoiceNotePlayer},
  props: {
    source: {
      type: ChatMessage,
    },
    handleContextMenu: Function,
    selectedMessageIds: Set,
    handleMessageSelect: Function,
    selectedMessageIdsSize: Number,
    handleDragStart: Function,
    handleViewTasks: Function,
    showFullDate: {type: Boolean, default: false},
    allowAttachmentDrag: {type: Boolean, default: false},
    chatAttachmentDragHandler: Function,
    showLinkedTasks: {type: Boolean, default: false}
  },
  data() {
    return {
      console,
      serverAddress,
    }
  },
  methods: {
    getLongDate,
    getTime,
    callDragStartHandler(event, att, type) {
      console.log("Att drag")
      
      if (this.allowAttachmentDrag) {
        // event.preventDefault();
        event.stopPropagation();
        this.chatAttachmentDragHandler(event, {
          type,
          payload: att
        })
      }
      
    },
    token: tokenMan.token,
    dragStart(event) {
      
      if (this.selectedMessageIdsSize) {
        console.log('Message Drag')
        if (!this.isSelected)
          this.handleMessageSelect(this.source)
        if (this.selectedMessageIdsSize === 1)
          event.dataTransfer.setDragImage(this.$refs.dragBubble, 0, 0);
        this.handleDragStart(event)
      }
    }
  },
  computed: {
    eventBus() {
      return eventBus
    },
    
    isSelected() {
      return this.selectedMessageIdsSize && this.selectedMessageIds.has(this.source.id)
    },
    
    emojiOnly() {
      if (!this.source?.message)
        return false
      const emojiRegex = /[\u{1F600}-\u{1F64F}]|[\u{1F300}-\u{1F5FF}]|[\u{1F680}-\u{1F6FF}]|[\u{1F700}-\u{1F77F}]|[\u{1F780}-\u{1F7FF}]|[\u{1F800}-\u{1F8FF}]|[\u{1F900}-\u{1F9FF}]|[\u{1FA00}-\u{1FA6F}]|[\u{1FA70}-\u{1FAFF}]|[\u{2600}-\u{26FF}]|[\u{2700}-\u{27BF}]|[\u{FE0F}]|[\u{1F004}]|[\u{1F0CF}]/gu;
      const characters = [...this.source?.message || ''];
      const emojis = characters.filter(char => char.match(emojiRegex));
      return characters.length === emojis.length && emojis.length <= 3;
    }
  },
}




</script>



<style scoped>

</style>