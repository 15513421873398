<template>
    <v-layout row fill-height style="overflow-y: auto">
<!--Loading-->
        <v-flex xs12 class="pa-2 text-xs-center" v-if="loadingRules">
            <v-progress-circular
                    :size="40"
                    :width="5"
                    color="primary"
                    indeterminate
            />
        </v-flex>

        <template v-if="!loadingRules">
<!--Select Action-->
            <v-flex xs5 fill-height>
                <v-layout column fill-height style="overflow-y: auto">
<!--Loading-->
                    <v-flex shrink v-if="addingAction">
                        <v-progress-linear class="pa-0 ma-0" height="3px" color="primary" indeterminate></v-progress-linear>
                    </v-flex>
<!--Title-->
                    <v-flex shrink class="pa-2">
                        <span class="primary--text body-2">Dataset Actions</span>
                    </v-flex>
                    <v-divider/>
<!--Add New-->
                    <v-flex shrink class="pa-2">
                        <v-btn
                                small
                                outline
                                class="ma-0"
                                color="secondary"
                                block
                                @click="showAddAction = true"
                        >
                            <v-icon left>add</v-icon>
                            Add New Action
                        </v-btn>
                        <app-input-dlg
                                :show-dialog="showAddAction"
                                title="Add New Dataset Action"
                                body="Dataset Action Description:"
                                :cancel-button="true"
                                :show-input-box="true"
                                :buttons="['Add Action']"
                                :call-backs="[ addNewAction ]"
                                @dismiss="showAddAction = false"
                        />
                    </v-flex>
                    <v-divider/>
<!--Select Field Sets-->
                    <v-flex xs12 style="overflow-y: auto">
                        <div
                                v-for="action in actions.filter(() => true).sort((a,b) => a['description'] < b['description'] ? -1 : 1)"
                                :key="action.id"
                                @click="selectedAction = action"
                                :class="selectedAction != null && selectedAction.id == action.id ? 'selected-item' : 'hover-item'"
                        >
                            <v-layout row align-center class="hide-parent">
                                <v-flex shrink>
                                    <v-tooltip top>
                                        <v-btn
                                                slot="activator"
                                                icon
                                                small
                                                :color="intToBool(action.enabled) ? 'primary' : 'disabled'"
                                                flat
                                                @click="enableDisableAction(action)"
                                                class="ma-0"
                                        >
                                            <v-icon small>{{ intToBool(action.enabled) ? 'check_box' : 'check_box_outline_blank'}}</v-icon>
                                        </v-btn>
                                        <span>Enable/Disable Action</span>
                                    </v-tooltip>
                                </v-flex>
                                <v-flex
                                        xs12
                                        class="pa-2 caption"
                                        :class="selectedAction != null && selectedAction.id == action.id
                                            ? 'font-weight-bold'
                                            : (!intToBool(action.enabled) ? 'greyType--text' : '')"
                                >
                                    {{`${action.description}${!intToBool(action.enabled) ? ' (Disabled)' : ''}`}}
                                </v-flex>


                                <v-flex shrink class="hide-child pr-2">
                                    <v-tooltip top>
                                        <v-btn
                                                slot="activator"
                                                icon
                                                small
                                                color="primary"
                                                flat
                                                @click="cloneDSAction(action.id)"
                                                class="ma-0"
                                        >
                                            <v-icon small>content_copy</v-icon>
                                        </v-btn>
                                        <span>Clone Action</span>
                                    </v-tooltip>
                                </v-flex>


                                <v-flex shrink class="hide-child pr-2">
                                    <v-tooltip top>
                                        <v-btn
                                                slot="activator"
                                                icon
                                                small
                                                color="primary"
                                                flat
                                                @click="showRenameDlg = true"
                                                class="ma-0"
                                        >
                                            <v-icon small>edit</v-icon>
                                        </v-btn>
                                        <span>Rename Action</span>
                                    </v-tooltip>
                                </v-flex>
                                <v-flex shrink class="hide-child pr-2">
                                    <v-tooltip top>
                                        <v-btn
                                                slot="activator"
                                                icon
                                                small
                                                color="red"
                                                flat
                                                @click="startRemoveAction(action)"
                                                class="ma-0"
                                        >
                                            <v-icon small>delete</v-icon>
                                        </v-btn>
                                        <span>Delete Action</span>
                                    </v-tooltip>
                                </v-flex>
                            </v-layout>
                            <v-divider/>
                        </div>
                    </v-flex>
                </v-layout>
            </v-flex>
            <v-flex shrink><v-divider vertical/></v-flex>

            <v-flex xs7 fill-height style="overflow-y: auto" v-if="selectedAction != null">
                <v-layout column fill-height style="overflow-y: auto" >
                    <!--Title-->
                    <v-flex shrink class="pa-2">
                        <span class="primary--text body-2">Configure Action</span>
                    </v-flex>
                    <v-divider/>
                    <v-flex fill-height xs12 style="overflow-y: auto">
                        <app-field-set-admin-rules-detail-actions-view
                                :user-info="userInfo"
                                :action="selectedAction"
                                :workflows="workflows"
                                :fields="fieldSetFields"
                                :users="allUsers"
                                @post="saveActionChanges"
                        />
                    </v-flex>
                </v-layout>
            </v-flex>

        </template>

        <app-input-dlg
                :show-dialog="showRenameDlg"
                title="Rename Action"
                body="Please provide a New Description:"
                :cancel-button="true"
                :show-input-box="true"
                :buttons="['Rename']"
                :call-backs="[ renameAction ]"
                @dismiss="showRenameDlg = false"
                :default-input="selectedAction != null ? selectedAction.description : ''"
        />
        <app-input-dlg
                :show-dialog="showRemoveAction"
                title="Remove Action"
                :body="`Are you sure you would like to remove the action '${removeActionProperty == null ? '': removeActionProperty.description}'`"
                :cancel-button="true"
                :show-input-box="false"
                :buttons="['Remove']"
                :call-backs="[ removeAction ]"
                @dismiss="showRemoveAction = false"
        />
    </v-layout>
</template>

<script>

    import {codeBaseMixin} from "../../../codeBaseMixin";
    import InputDlg from "../../../components/General/InputDlg";
    import FieldSetAdminRulesDetailActionsView from "./FieldSetAdminRulesDetailActionsView";

    export default {
        name: "FieldSetAdminRulesDetail",
        components: {
            appFieldSetAdminRulesDetailActionsView: FieldSetAdminRulesDetailActionsView,
            appInputDlg: InputDlg
        },
        mixins: [
            codeBaseMixin
        ],
        props: {
            userInfo: Object,
            selectedSet: Object,
        },
        data() {
            return {
                loadingRules: false,
                addingAction: false,
                enablingAction: false,
                renamingAction: false,
                removingAction: false,
                savingActionChanges: false,
                actions: [],
                fieldSetFields: [],
                selectedAction: null,
                showRenameDlg: false,
                workflows: [],
                allUsers: [],
                removeActionProperty: null,
                showRemoveAction: false,
                showAddAction: false
            }
        },

        mounted() {
            this.initStartup();
        },
        watch: {
            selectedSet(newValue) {
                if (newValue != null) {this.initStartup()}
            }
        },
        methods: {
            cloneDSAction(actionID) {
                this.$snack.info('Please Wait');
                this.$newReq('PUT', `fieldSetActions/clone/${this.userInfo.entityID}/${actionID}`)
                    .then((data) => {
                        this.actions.push(data);
                        this.selectedAction = data;
                        this.$snack.info('Success');
                    })
                    .catch(e => {
                        console.log(e)
                        this.$snack.networkError();
                    })
            },

            enableDisableAction(action) {
                this.enablingAction = true;
                this.fetch({method: 'PATCH', url:`/fieldSetActions/actionState/${this.userInfo.entityID}/${action.id}/${this.boolToInt(!this.intToBool(action.enabled))}`})
                    .then(() => {
                        action.enabled = this.boolToInt(!this.intToBool(action.enabled));
                        this.enablingAction = false;
                        this.showSnack('Info', 'Changes Saved', 'Close', 'primary');
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e);
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                        this.enablingAction = false;
                    })
            },

            startRemoveAction(action) {
                this.removeActionProperty = action;
                this.showRemoveAction = true;
            },
            addNewAction(addActionDescription) {
                if (!this.blankString(addActionDescription)){
                    this.showAddAction = false;
                    this.addingAction = true;
                    this.fetch({method: 'POST', url:`/fieldSetActions/newAction/${this.userInfo.entityID}/${this.selectedSet.id}`, body: JSON.stringify({description: addActionDescription})})
                        .then((data) => {
                            this.actions.push(data);
                            this.selectedAction = data;
                            this.addingAction = false;
                        })
                        .catch((e) => {
                            // eslint-disable-next-line
                            console.log(e);
                            this.addingAction = false;
                            this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                        })
                } else {
                    this.showSnack('Error', 'A Description is Required', 'Close', 'red')
                }
            },
            initStartup() {
                this.loadingRules = true;
                this.fetch({method: 'GET', url:`/fieldSetActions/startupScreenData/${this.userInfo.entityID}/${this.selectedSet.id}`})
                    .then((data) => {
                        this.actions = data.actions;
                        this.fieldSetFields = data.fieldSetFields;
                        this.workflows = data.workflows;
                        this.loadingRules = false;
                        this.allUsers = data.allUsers;
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e);
                        this.loadingRules = false;
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                    })
            },
            removeAction() {
                this.removingAction = true;
                this.showRemoveAction = false;
                this.selectedAction = null;
                this.actions = this.actions.filter(obj => obj.id != this.removeActionProperty.id);
                this.fetch({
                    method: 'GET',
                    url: `/fieldSetActions/removeAction/${this.userInfo.entityID}/${this.removeActionProperty.id}`
                })
                    .then(() => {
                        this.showSnack('Info', 'Action Removed', 'Close', 'primary');
                        this.removingAction = false;
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e);
                        this.removingAction = false;
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                    })
            },
            renameAction(description) {
               if (description != null && description != '') {
                   this.showRenameDlg = false;
                   this.renamingAction = true;
                   this.selectedAction.description = description
                   this.fetch({method: 'PATCH', url: `/fieldSetActions/renameAction/${this.userInfo.entityID}/${this.selectedAction.id}`, body: JSON.stringify({description: description})})
                       .then(() => {
                           this.renamingAction = false;
                           this.showSnack('Info', 'Changes Saved', 'Close', 'primary');
                       })
                       .catch((e) => {
                           // eslint-disable-next-line
                           console.log(e);
                           this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
                           this.renamingAction = false;
                       })
               } else {
                   this.showSnack('Error', 'Description Can Not Be Blank', 'Close', 'red')
               }
            },

            saveActionChanges() {
                this.showRenameDlg = false;
                this.savingActionChanges = true;
                this.fetch({
                    method: 'PATCH',
                    url: `/fieldSetActions/saveFieldSetAction/${this.userInfo.entityID}/${this.selectedAction.id}/${this.selectedSet.id}/${this.userInfo.userID}`,
                    body: JSON.stringify({object: this.selectedAction.object})
                })
                    .then(() => {
                        this.showSnack('Info', 'Changes Saved', 'Close', 'primary');
                        this.savingActionChanges = false;
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        if (e == 500) {
                            this.showSnack('Error', 'Please Check Rule Configuration (Failed To Save)', 'Close', 'red')
                        } else {
                            this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                        }


                        this.savingActionChanges = false;
                    })
            },
        },
        computed: {
            disableClicks() {
                return this.enablingAction || this.addingAction || this.renamingAction || this.removingAction || this.savingActionChanges
            }
        }
    }
</script>

<style scoped>

</style>
