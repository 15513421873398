<template>
    <div
            @click="click(item.id)"
            :class="item.id === highlightedIndex ? 'selected-item' : null"
            @mouseover="$emit('highlight', item.id)"
            @mouseleave="highlightedIndex === index ? $emit('highlight', -1) : ''"
    >
        <v-layout row class="pr-1" align-center style="cursor: pointer">
            <v-flex shrink v-if="typeof item.icon != 'undefined'" class="pl-1">
                <template
                        v-if="typeof item.iconType != 'undefined' && item.iconType == 'svg'">
                    <img :src="item.icon" alt="" height="24">
                </template>
                <template v-else>
                    <v-icon color="primary">{{item.icon}}</v-icon>
                </template>
            </v-flex>
            <v-flex xs12 class="pl-1 py-2 text-truncate" style="overflow-y: hidden">
                {{ item.title }}
            </v-flex>
            <v-flex shrink v-if="typeof item.count != 'undefined' && item.count != 0" class="pl-1 greyType--text">
                ({{ item.count }})
            </v-flex>
        </v-layout>
    </div>
</template>

<script>
    export default {
        props: {
            index: { // index of current item
                type: Number
            },
            item: {
                type: Object,
                default () {
                    return {}
                }
            },
            click: Function,
            highlightedIndex: Number
        }
    }
</script>

<style scoped>

</style>
