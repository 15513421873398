<template>
    <v-card class="centered-row py-2 mb-2" dark>
        <div class="pl-2">
            <v-progress-circular
                    class="py-0 my-0"
                    :value="download.progress"
                    color="secondary"
                    :size="22"
                    :width="3"
                    :rotate="270"
            />
        </div>
        <div class="white--text px-2" style="width: 100%">
            {{download.filename}}
        </div>
        <div class="pr-2">
            <v-tooltip left>
                <v-btn
                        small
                        icon
                        flat
                        color="error"
                        class="pa-0 ma-0"
                        slot="activator"
                        @click="download.cancelToken.cancel()"
                >
                    <v-icon>close</v-icon>
                </v-btn>
                Cancel
            </v-tooltip>
        </div>
    </v-card>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
    components: {},
    props: {
        download: Object
    },
    data() {
        return {
        }
    },
    watch: {},
    methods: {},
    computed: {
        ...mapGetters('userInfo', ['userInfo'])
    },
}
</script>

<style scoped>

</style>