<template>
    <v-layout column fill-height class="scrollable card-back">
<!--Loading-->
        <v-flex xs12 fill-height v-if="loadingDetail">
            <v-layout fill-height align-center justify-center>
                <v-flex shrink>
                    <v-progress-circular
                            :size="40"
                            :width="3"
                            color="primary"
                            indeterminate
                    ></v-progress-circular>
                </v-flex>
            </v-layout>
        </v-flex>
<!--Body-->
        <template v-else>
            <v-flex shrink >
                <div class="centered-row title-color px-1">
                    <v-icon v-if="!savingChanges" color="white">
                        {{!refDoc.deleteFlag ? 'description' : 'restore_page'}}
                    </v-icon>
                    <div style="width: 24px; height: 24px; overflow: hidden; margin-left: 4px" v-else>
                        <v-progress-circular
                                :size="20"
                                :width="2"
                                color="white"
                                indeterminate
                        ></v-progress-circular>
                    </div>
                    <div class="body white--text centered-row" style="width: 100%" v-if="!inEdit">

                        <div class="pa-2 px-2">
                            {{refDoc.description}}
                        </div>

                        <v-tooltip top>
                            <v-btn
                                    slot="activator"
                                    small
                                    icon
                                    flat
                                    color="white"
                                    class="pa-0 ma-0"
                                    @click="inEdit = !inEdit"
                            >
                                <v-icon small>edit</v-icon>
                            </v-btn>
                            Edit Document Description
                        </v-tooltip>


                    </div>
                    <div v-else style="width: 100%" class="centered-row">
                        <div style="min-width: 300px; width: 50%" class="px-2 pb-1">
                            <v-text-field
                                    label="Folder Description"
                                    single-line
                                    color="white"
                                    dark
                                    flat
                                    :hide-details="true"
                                    class="pa-0 ma-0"
                                    v-model="editDoc.description"
                                    :disabled="savingChanges"
                            ></v-text-field>
                        </div>

                        <div class="pr-2">
                            <v-tooltip top>
                                <v-btn
                                        slot="activator"
                                        small
                                        icon
                                        flat
                                        color="white"
                                        class="pa-0 ma-0"
                                        @click="updateDocument"
                                        :disabled="savingChanges || !editDoc.description"
                                >
                                    <v-icon>save</v-icon>
                                </v-btn>
                                Save Changes
                            </v-tooltip>
                        </div>

                        <div class="pr-2">
                            <v-tooltip top>
                                <v-btn
                                        slot="activator"
                                        small
                                        icon
                                        flat
                                        color="white"
                                        class="pa-0 ma-0"
                                        @click="inEdit = !inEdit"
                                        :disabled="savingChanges"
                                >
                                    <v-icon>close</v-icon>
                                </v-btn>
                                Cancel
                            </v-tooltip>
                        </div>

                    </div>

                    <v-tooltip top>
                        <v-btn
                                small
                                :color="refDoc.deleteFlag ? 'white' : 'red'"
                                class="pa-0 ma-0"
                                flat
                                icon
                                slot="activator"
                                @click="$emit(refDoc.deleteFlag ? 'restore' : 'delete', refDoc)"
                        >
                            <v-icon>{{refDoc.deleteFlag ? 'restore_from_trash' : 'delete'}}</v-icon>
<!--                            <v-icon>auto_delete</v-icon>-->
                        </v-btn>
                        {{refDoc.deleteFlag ? 'Restore Document' : 'Delete Document'}}
                    </v-tooltip>

                    <v-tooltip top v-if="refDoc.deleteHistory">
                        <v-btn
                                small
                                color="white"
                                class="pa-0 ma-0 ml-2"
                                flat
                                icon
                                slot="activator"
                                @click="showDeleteHistory = true"
                        >
                            <v-icon>delete_sweep</v-icon>
                        </v-btn>
                        Delete History
                    </v-tooltip>

                    <input-dlg
                            :show-dialog="showDeleteHistory"
                            title="Delete History"
                            :cancel-button="true"
                            :show-input-box="false"
                            @dismiss="showDeleteHistory = false"
                            :body-padding="false"
                            :width="600"
                    >
                        <template slot="body">
                            <div style="max-height: 600px" class="scrollable">
                                <div v-for="(line, i) in refDoc.deleteHistory" :key="i">
                                    <div class="centered-row px-2 py-1 hover-item">
                                        <v-icon left>
                                            {{line.state ? 'delete' : 'restore_from_trash'}}
                                        </v-icon>
                                        <div style="white-space: nowrap">{{format(line.date, 'YYYY-MM-DD HH:mm')}}</div>
                                        <div style="width: 100%" class="pl-2">{{refUsers[line.userID].name + refUsers[line.userID].surname}}</div>
                                        <div>{{line.state ? 'Deleted' : 'Restored'}}</div>
                                    </div>
                                    <v-divider></v-divider>
                                </div>
                            </div>
                        </template>
                    </input-dlg>



                    <div class="caption pa-1 px-2 white--text" style="white-space: nowrap">{{createdDate}}</div>
                </div>
            </v-flex>
            <v-divider style="background: var(--v-primary-darken4)"></v-divider>
            <v-flex xs12 class="scrollable">
                <v-layout row fill-height class="scrollable">
<!--Revisions-->
                    <v-flex xs4 class="fill-height scrollable ">
                        <v-layout column fill-height class="scrollable">
                            <v-flex shrink class="title-color body pa-1 px-2 white--text">Revisions</v-flex>
                            <v-flex xs12 class="scrollable">
                                <v-layout column fill-height class="scrollable">
                                    <v-flex shrink class="px-1">

                                        <div class="body-2 primary--text py-1">Add Revision</div>

                                        <div class="upload-btn-wrapper" style="width: 100%" v-if="revisionUploadDoc === null">
                                            <div
                                                    @dragover="$event.preventDefault()"
                                                    @drop="$event.preventDefault(); dropFiles($event);"
                                                    class="py-1 text-xs-center greyType--text hover-item"
                                                    style="border: 1px dashed lightgrey; border-radius: 3px; cursor: pointer"
                                            >
                                                Click to Select or Drop File here.
                                            </div>
                                            <input
                                                    ref="manualUpload"
                                                    type="file"
                                                    name="myfile"
                                                    :accept="userInfo.entityInfo.mimeTypeList"
                                                    @input="selectFile"
                                            />
                                        </div>
                                        <div v-else class="pb-1">
                                            <div class="centered-row pl-2" style="border: 1px solid lightgrey; border-radius: 3px;">
                                                    <v-progress-circular
                                                            :size="20"
                                                            :width="2"
                                                            color="grey"
                                                            :value="revisionUploadDocProgress"
                                                    ></v-progress-circular>
                                                <div style="width: 100%" class="px-2 py-1 greyType--text">
                                                    {{ revisionUploadDoc.name }}
                                                </div>
                                            </div>
                                        </div>
                                    </v-flex>
                                    <v-divider></v-divider>
                                    <v-flex xs12 class="scrollable pl-1 no-x">
                                        <v-layout column fill-height class="scrollable no-x">
                                            <v-flex shrink class="body-2 primary--text py-1">
                                                All Revisions
                                            </v-flex>
                                            <v-flex xs12 class="scrollable no-x">
                                                <div v-for="rev in displayRevisions" :key="rev.id" class="pb-1 no-x">

                                                    <div class="centered-row no-x">
                                                        <div class="secondary--text title px-2">{{rev.revision}}</div>
                                                        <div class="no-x pa-1">
                                                            <v-card class="px-1">
                                                                <document-container
                                                                        :document="rev.viewDoc"
                                                                        :user-info="userInfo"
                                                                />
                                                            </v-card>
                                                        </div>
                                                    </div>
                                                </div>
                                            </v-flex>
                                        </v-layout>
                                    </v-flex>
                                </v-layout>
                            </v-flex>
                        </v-layout>
                    </v-flex>
                    <v-divider vertical style="background: var(--v-primary-darken4)"></v-divider>
                    <v-flex xs4 class="fill-height scrollable">
                        <v-layout column fill-height class="scrollable">
                            <v-flex shrink class="title-color body pa-1 px-2 white--text">Users With Access</v-flex>
                            <v-flex xs12 class="scrollable">
                                <multi-select-list
                                        :items="displayUsers"
                                        item-key="id"
                                        headline-key="description"
                                        :return-object="false"
                                        :multiple="true"
                                        :show-check-boxes="true"
                                        :show-action-buttons="false"
                                        :showFilter="true"
                                        v-model="editDoc.users"
                                        filter-label="Filter Users"
                                        @input="updateDocument"
                                >
                                </multi-select-list>
                            </v-flex>
                        </v-layout>
                    </v-flex>
                    <v-divider vertical style="background: var(--v-primary-darken4)"></v-divider>
                    <v-flex xs4 class="fill-height scrollable">
                        <v-layout column fill-height class="scrollable">
                            <v-flex shrink class="title-color body pa-1 px-2 white--text">Groups With Access</v-flex>
                            <v-flex xs12 class="scrollable">
                                <multi-select-list
                                        :items="displayGroups"
                                        item-key="id"
                                        headline-key="description"
                                        :return-object="false"
                                        :multiple="true"
                                        :show-check-boxes="true"
                                        :show-action-buttons="false"
                                        :showFilter="true"
                                        v-model="editDoc.groups"
                                        filter-label="Filter Groups"
                                        @input="updateDocument"
                                >
                                </multi-select-list>
                            </v-flex>
                        </v-layout>
                    </v-flex>
                </v-layout>
            </v-flex>
        </template>
    </v-layout>
</template>

<script>
import {mapGetters} from 'vuex'
import format from "date-fns/format";
import DocumentContainer from "@/Tasks/TaskView V2/components/Documents/DocumentContainer";
import {objectifyArr} from "@/codeFunctions";
import MultiSelectList from "@/components/Multi Select List/MultiSelectList";
import InputDlg from "@/components/General/InputDlg";

export default {
    components: {InputDlg, MultiSelectList, DocumentContainer},
    props: {
        selectedDocID: Number,
        docs: Array
    },
    data() {
        return {
            loadingDetail: false,
            created: null,
            revisions: null,
            editDoc: null,
            inEdit: false,
            savingChanges: false,

            revisionUploadDoc: null,
            revisionUploadDocProgress: 0,

            showDeleteHistory: false
        }
    },
    watch: {
        selectedDocID: {
            immediate: true,
            handler(val) {
                if (val) {
                    this.loadDocumentDetail();
                    this.editDoc = {
                        id: this.refDoc.id,
                        description: this.refDoc.description,
                        users: this.refDoc.users,
                        groups: this.refDoc.groups,
                        deleteFlag: this.refDoc.deleteFlag,
                        parent: this.refDoc.parent ? this.refDoc.parent : null,
                    }
                }
            }
        }
    },
    methods: {

        format: format,

        uploadRevision() {
            let vm = this;
            function updateProgress(obj) {
                vm.revisionUploadDocProgress = Math.floor((obj.loaded / obj.total) * 100)
            }
            this.addDocLoading = true;
            this.$newReqUploadMultiple(
                    'patch',
                    `docRepo/AddRevision/${this.userInfo.entityID}/${this.userInfo.userID}/${this.selectedDocID}`,
                    [ {key: 'file', val: this.revisionUploadDoc}],
                    true,
                    updateProgress,
                    false,
            )
                    .then(doc => {
                        this.$snack.info('Revision Added')
                        this.$emit('updateDocument', doc);
                        this.loadDocumentDetail();
                    })
                    .catch(e => {
                        console.log(e);
                        this.$snack.networkError()
                    })
                    .finally(() => {
                        this.addDocLoading = false;
                        this.revisionUploadDocProgress = 0;
                        this.revisionUploadDoc = null;
                    })
        },

        selectFile() {
            if (this.$refs.manualUpload.files.length) {
                this.revisionUploadDoc = this.$refs.manualUpload.files[0];
                this.uploadRevision();
            }
        },

        dropFiles(e) {
            this.showUpload = true;
            this.$nextTick(() => {
                if (e.dataTransfer.files.length) {
                    this.revisionUploadDoc = e.dataTransfer.files[0];
                    this.uploadRevision();
                }
            })

        },

        updateDocument() {
            this.savingChanges = true;
            this.$newReq('patch', `docRepo/UpdateDocument/${this.userInfo.entityID}/${this.userInfo.userID}`, this.editDoc)
                    .then(doc => {
                        this.$emit('updateDocument', doc);
                        this.inEdit = false;
                    })
                    .catch(e => {
                        console.log(e);
                        this.$snack.networkError()
                    })
                    .finally(() => {this.savingChanges = false})
        },

        loadDocumentDetail() {
            this.loadingDetail = true;
            this.$newReq('get', `docRepo/RevDetailAndHistory/${this.userInfo.entityID}/${this.selectedDocID}/1`)
                    .then((data) => {
                        this.created = data.detail.created;
                        this.revisions = data.revisions;
                    })
                    .catch(e => {
                        console.log(e);
                        this.$snack.networkError()
                    })
                    .finally(() => {this.loadingDetail = false})
        }
    },
    computed: {
        ...mapGetters('userInfo', ['userInfo']),
        ...mapGetters(['localData']),

        displayUsers() {
            return this.localData.users
                    .reduce((list, user) => {
                        if (!user.disabled)
                            return list.concat({id: user.userID, description: `${user.name} ${user.surname}`.trim()})
                        return list
                    }, [])
                    .sort((a,b) => a.description > b.description ? 1 : 0)
        },

        displayGroups() {
            return [...this.localData.userGroups]
                    .sort((a,b) => a.description > b.description ? 1 : 0)
        },

        refDoc() {
            return this.docs.filter(doc => doc.id === this.selectedDocID).pop()
        },

        createdDate() {
            try {
                return format(this.created, 'Do MMMM YYYY  h:mm A')
            } catch (e) {
                return null
            }
        },

        refUsers() {
            return objectifyArr(this.localData.users, 'userID')
        },

        displayRevisions() {
            return this.revisions
                    .map((doc) => {
                        return {
                            ...doc,
                            viewDoc: {
                                ...doc.document,
                                messageID: null,
                                documentDBID: doc.document.id,
                                linkUser: doc.document.fk_import_user,
                                linkName: this.refUsers[doc.document.fk_import_user].name,
                                linkSurname: this.refUsers[doc.document.fk_import_user].surname,
                                linkDate: doc.document.created,
                                stepID: null
                            }
                        }
                    })
                    .sort((a,b) => b.revision - a.revision)
        }
    },
}
</script>

<style scoped>

.upload-btn-wrapper input[type=file] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
}
.upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
}

</style>