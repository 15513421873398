<template>
    <div style="margin: 3px 0 2px 0; overflow-y: hidden; overflow-x: hidden" :class="selected ? 'selected' : ''">
        <div style="line-height: 1">
            {{node.description}}
        </div>
<!--        <div class="caption text-truncate" style="overflow-y: auto; overflow-x: hidden; line-height: 1">-->
<!--            {{documentDisplayName}}-->
<!--        </div>-->
    </div>
</template>

<script>

export default {
    components: {},
    props: {
        node: {type: Object, required: true},
        selected: Boolean
    },
    data() {
        return {

        }
    },
    watch: {},
    methods: {},
    computed: {
        documentDisplayName() {
            return this.node.document.alias && this.node.document.alias !== '' ? this.node.document.alias : this.node.document.fileName
        }
    },
}
</script>

<style scoped>
    .selected {
        /*color: var(--v-primary-base);*/
    }
    .nodeItems {
        display: flex;
        align-items: center;
        justify-content: left;
    }
</style>