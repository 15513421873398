<template>
  <div>
    <div @click="shownRelay = !shownRelay">
      <slot name="activator"></slot>
    </div>
    
    <v-card v-if="cardOnly" class="scrollable c-d-flex c-flex-column fill-height of-y" style="">
      <div class="c-flex-grow-1 c-d-flex of-y">
        
        <div class="fill-height of-y" style="width: 50%" @scroll="listScrolled" ref="scrollList">
          
          <div style="position: sticky; top: 0; z-index: 9; background: var(--card-back)">
            
            <slot name="header" :init="init" :selectContact="selectContact"></slot>
            
            <div class="pa-2 c-d-flex c-align-center">
              <v-text-field
                  solo
                  prepend-inner-icon="search"
                  placeholder="Search Contacts"
                  v-model="searchStr"
                  :clearable="true"
                  hide-details
                  @keydown.enter="loadMore(true)"
                  @click:clear="loadMore(true, true)"
              ></v-text-field>
              
              
              <v-btn
                  icon
                  color="secondary"
                  class="ml-2 mr-0"
                  :elevation="5"
                  @click="() => loadMore(true)"
                  :disabled="loading"
                  :loading="loading"
              >
                <v-icon>search</v-icon>
              </v-btn>
            
            
            </div>
            
            <slot name="belowSearch" :init="init" :selectContact="selectContact"></slot>
            
            
            <div>
              <v-divider></v-divider>
            </div>
          </div>
          
          <v-list
              class="py-0"
          >
            <v-list-tile
                v-for="contact in contacts"
                :key="contacts.id"
                color="primary"
                :style="selectedRelay.includes(contact.id) ? 'background: #00695C20' : null"
                :value="selectedRelay.includes(contact.id)"
                @click="selectedRelay = contact.id"
            >
              <v-list-tile-avatar>
                <v-icon :color="selectedRelay.includes(contact.id) ? 'primary' : ($store.getters.usersWithLinkedContactsSet.has(contact.id) ? 'amber darken-2' : (contact.registered ? 'green' : null)) ">{{ $store.getters.usersWithLinkedContactsSet.has(contact.id) ? 'lightbulb' : (contact.registered ? '3p' : 'person') }}</v-icon>
              </v-list-tile-avatar>
              <v-list-tile-content>
                
                <v-list-tile-title>{{contact.fullName}}</v-list-tile-title>
                <v-list-tile-sub-title class="c-d-flex align-center justify-space-between">
                  <span>{{ contact.mobile }}</span>
                  <span>{{ (contact.email || []).join(', ') }}</span>
                </v-list-tile-sub-title>
              </v-list-tile-content>
              
              <v-list-tile-action>
                <div class="c-d-flex c-align-center">
                  <v-btn
                      icon
                      small
                      color="blue"
                      flat
                      @click.stop="infoContact = contact"
                  >
                    <v-icon>info</v-icon>
                  </v-btn>
                  
                  <v-btn
                      v-if="folderFilter && allowRemoveContact"
                      class="ml-2"
                      icon
                      small
                      color="error"
                      flat
                      @click.stop="unlinkContact(contact)"
                  >
                    <v-icon>delete</v-icon>
                  </v-btn>
                </div>
              
              </v-list-tile-action>
            </v-list-tile>
            
            <v-list-tile
                color="primary"
                v-if="loading"
            >
              <v-list-tile-content class="c-d-flex c-align-center c-justify-center">
                <v-progress-circular
                    :size="30"
                    :width="3"
                    color="grey"
                    indeterminate
                />
              </v-list-tile-content>
            </v-list-tile>
          </v-list>
        </div>
        
        <div class="fill-height of-y" style="width: 50%">
          <div v-for="(contact, i) in selectedContacts" :key="contact.id">
            <div class="py-2 c-d-flex c-align-center px-2">
              <v-icon :size="28" color="primary">{{ $store.getters.usersWithLinkedContactsSet.has(contact.id) ? 'lightbulb' : (contact.registered ? '3p' : 'person') }}</v-icon>
              <div class="c-flex-grow-1 subheading px-2">
                <div style="line-height: 1" class="pb-1">{{ contact.fullName }}</div>
                <div class="caption" style="line-height: 1">{{ contact.mobile }}</div>
                <div class="caption" style="line-height: 1">{{ (contact.email || []).join(', ') }}</div>
              </div>
              <v-btn
                  v-if="selectedContacts.length > 1"
                  icon
                  flat
                  color="primary"
                  class="ma-0"
                  @click="droppedContacts.includes(contact.id) ? arrSplice(droppedContacts, val => val === contact.id) : droppedContacts.push(contact.id)"
              >
                <v-icon>{{!droppedContacts.includes(contact.id) ? 'expand_more' : 'expand_less'}}</v-icon>
              </v-btn>
            </div>
            
            <app-contact-modify
                v-if="droppedContacts.includes(contact.id)"
                :custom-field-ref="customFieldRef"
                :value="contact"
                class="px-3"
                @input="updateContact($event)"
            ></app-contact-modify>
            
            <v-divider></v-divider>
          </div>
        </div>
      </div>
    </v-card>
    
    <v-dialog
        v-else
        v-model="shownRelay"
        :width="1000"
        max-width="90%"
        persistent
        
    >
      
      <v-card v-if="shownRelay" class="scrollable c-d-flex c-flex-column" style="height: 800px; max-height: 80vh">
        
        <div class="c-flex-grow-0 px-3 title-color white--text dark c-d-flex c-align-center">
          <div>
            <slot name="titleIcon">
              <v-icon color="white" left>contact_mail</v-icon>
            </slot>
          </div>
          <div class="c-flex-grow-1 py-2 subheading">
            <slot name="title">Browse Contacts</slot>
          </div>
          <div>
            <slot name="toolbarEnd" :init="init" :selectContact="selectContact"></slot>
          </div>
          <div>
            <v-btn
                color="white"
                icon
                flat
                @click="shownRelay = false"
                class="mx-0"
            >
              <v-icon>close</v-icon>
            </v-btn>
          </div>
        </div>
        
        <div class="c-flex-grow-1 c-d-flex of-y">
          
          <div class="fill-height of-y" style="width: 50%" @scroll="listScrolled" ref="scrollList">
            
            <div style="position: sticky; top: 0; z-index: 9; background: var(--card-back)" >
              
              <slot name="header" :init="init" :selectContact="selectContact"></slot>
              
              <div class="pa-2 c-d-flex c-align-center">
                <v-text-field
                    solo
                    prepend-inner-icon="search"
                    placeholder="Search Contacts"
                    v-model="searchStr"
                    :clearable="true"
                    hide-details
                    @keydown.enter="loadMore(true)"
                    @click:clear="loadMore(true, true)"
                ></v-text-field>
                
                
                <v-btn
                    icon
                    color="secondary"
                    class="ml-2 mr-0"
                    :elevation="5"
                    @click="loadMore(true)"
                    :disabled="loading"
                    :loading="loading"
                >
                  <v-icon>search</v-icon>
                </v-btn>
                
                
              </div>
              
              <slot name="belowSearch" :init="init" :selectContact="selectContact"></slot>
              
              <div>
                <v-divider></v-divider>
              </div>
            </div>
            
            <v-list
                class="py-0"
            >
              <v-list-tile
                  v-for="contact in contacts"
                  :key="contacts.id"
                  :style="{background: selectedRelay.includes(contact.id) ? 'var(--selected-item)' : null}"
                  :value="selectedRelay.includes(contact.id)"
                  @click="selectedRelay = contact.id"
              >
                <v-list-tile-avatar>
                  <v-icon :color="selectedRelay.includes(contact.id) ? 'primary' :  ($store.getters.usersWithLinkedContactsSet.has(contact.id) ? 'amber darken-2' : (contact.registered ? 'green' : null)) ">{{ $store.getters.usersWithLinkedContactsSet.has(contact.id) ? 'lightbulb' : (contact.registered ? '3p' : 'person') }}</v-icon>
                </v-list-tile-avatar>
                <v-list-tile-content>
                  
                  <v-list-tile-title style="color: var(--on-surface)">{{contact.fullName}}</v-list-tile-title>
                  <v-list-tile-sub-title class="c-d-flex align-center justify-space-between">
                    <span>{{ contact.mobile }}</span>
                    <span>{{ (contact.email || []).join(', ') }}</span>
                  </v-list-tile-sub-title>
                </v-list-tile-content>
                
                <v-list-tile-action>
                  <div class="c-d-flex c-align-center">
                    <v-btn
                        icon
                        small
                        color="blue"
                        flat
                        @click.stop="infoContact = contact"
                    >
                      <v-icon>info</v-icon>
                    </v-btn>
                    
                    <v-btn
                        v-if="folderFilter && allowRemoveContact"
                        class="ml-2"
                        icon
                        small
                        color="error"
                        flat
                        @click.stop="unlinkContact(contact)"
                    >
                      <v-icon>delete</v-icon>
                    </v-btn>
                  </div>
                  
                </v-list-tile-action>
              </v-list-tile>
              
              <v-list-tile
                  color="primary"
                  v-if="loading"
              >
                <v-list-tile-content class="c-d-flex c-align-center c-justify-center">
                  <v-progress-circular
                      :size="30"
                      :width="3"
                      color="grey"
                      indeterminate
                  />
                </v-list-tile-content>
              </v-list-tile>
            </v-list>
          </div>
          
          <div class="fill-height of-y" style="width: 50%">
            <div v-for="(contact, i) in selectedContacts" :key="contact.id">
              <div class="py-2 c-d-flex c-align-center px-2">
                <v-icon :size="28" color="primary">{{ $store.getters.usersWithLinkedContactsSet.has(contact.id) ? 'lightbulb' : (contact.registered ? '3p' : 'person') }}</v-icon>
                <div class="c-flex-grow-1 subheading px-2">
                  <div style="line-height: 1" class="pb-1">{{ contact.fullName }}</div>
                  <div class="caption" style="line-height: 1">{{ contact.mobile }}</div>
                  <div class="caption" style="line-height: 1">{{ (contact.email || []).join(', ') }}</div>
                </div>
                <v-btn
                    v-if="selectedContacts.length > 1"
                    icon
                    flat
                    color="primary"
                    class="ma-0"
                    @click="droppedContacts.includes(contact.id) ? arrSplice(droppedContacts, val => val === contact.id) : droppedContacts.push(contact.id)"
                >
                  <v-icon>{{!droppedContacts.includes(contact.id) ? 'expand_more' : 'expand_less'}}</v-icon>
                </v-btn>
                <v-tooltip left>
                  <template #activator="{on}">
                    <v-btn
                        icon
                        color="error"
                        flat
                        v-on="on"
                        v-if="!contact.registered && $store.getters['userInfo/contactsManager']"
                        :loading="removingContact"
                        :disabled="removingContact"
                        @click="removeContact(contact.id)"
                    >
                      <v-icon>delete</v-icon>
                    </v-btn>
                  </template>
                  Delete Contact
                </v-tooltip>
                
              </div>
              
              <app-contact-modify
                  v-if="droppedContacts.includes(contact.id)"
                  :custom-field-ref="customFieldRef"
                  :value="contact"
                  class="px-3"
                  @input="updateContact($event)"
              ></app-contact-modify>
              
              <v-divider></v-divider>
            </div>
          </div>
        </div>
        
        
        
        <div class="c-flex-shrink-1">
          <v-divider/>
          <slot name="actions" :selectedContacts="selectedContacts" :hideDialog="hideDialog"></slot>
        </div>
      </v-card>
      
    </v-dialog>
    
    <app-contact-information-dlg
        :shown="!!infoContact"
        @update:shown="infoContact = null"
        :contact="infoContact"
    />
  </div>

</template>

<script>
import AppContactsCustomFields from "@/contacts/AppContactsCustomFields.vue"
import draggable from "vuedraggable";
import {mapGetters} from "vuex";
import {arrSplice} from "@/codeFunctions";
import AppContactModify from "@/contacts/AppContactModify.vue";
import AppContactInformationDlg from "@/contacts/AppContactInformationDlg.vue";
import props from "vuetify/lib/components/VCalendar/util/props";

export default {
  components: {
    AppContactInformationDlg,
    AppContactModify,
    draggable,
    AppContactsCustomFields
  },
  props: {
    shown: {
      type: Boolean,
      default: false
    },
    multiSelect: {
      type: Boolean,
      default: false
    },
    value: {
      type: [Array, Number, null],
      default: null
    },
    cardOnly: {
      type: Boolean,
      default: false
    },
    folderFilter: {
      type: Number,
      default: null
    },
    allowRemoveContact: {
      type: Boolean,
      default: false
    },
    registeredOnly: {
      type: Boolean,
      default: false
    },
    ignoreManagement: {type: Boolean, default: false}
  },
  data() {
    return {
      arrSplice,
      selfShown: false,
      loading: false,
      selectLists: [],
      customFields: new Map(),
      curPage: 0,
      searchStr: null,
      contacts: [],
      internalSelected: null,
      droppedContacts: [],
      customFieldRef: new Map(),
      infoContact: null,
      contactsCount: null,
      searchOptionsVal: null,
      removingContact: false,
    }
  },
  watch: {
    folderFilter: {
      handler(val) {
        if (val)
          this.init()
      }
    },
    cardOnly: {
      immediate: true,
      handler(val) {
        if (val)
          this.init()
      }
    },
    shownRelay: {
      immediate: true,
      handler(val) {
        this.internalSelected = this.value
        if (val)
          this.init()
      }
    },
    selectedRelay: {
      immediate: true,
      handler(val) {
        if (val.length === 1) {
          this.droppedContacts = [val[0]]
        } else {
          this.droppedContacts = []
        }
      }
    },
  },
  methods: {
    
    async removeContact(contactId) {
      try {
        this.removingContact = true
        await this.$newReq('POST', `contacts/deleteContact`, {contactId})
        this.internalSelected = this.internalSelected.filter((contact) => contact !== contactId)
        this.contacts = this.contacts.filter((contact) => contact.id !== contactId)
      } catch (e) {
        console.log(e)
        this.$snack.networkError()
      } finally {
        this.removingContact = false
      }
    },
    
    updateContact(contact) {
      const existingContact = this.contacts.find(val => val.id === contact.id)
      if (existingContact)
        Object.assign(existingContact, contact)
      else {
        this.contacts.push(contact)
        
        if (!Array.isArray(this.internalSelected))
          this.internalSelected = []
        
        if (!this.internalSelected.includes(contact.id))
          if (!this.multiSelect)
            this.internalSelected = [contact.id]
          else {
            this.internalSelected.push(contact.id)
            this.droppedContacts.push(contact.id)
          }
      }
    },
    
    selectContact(contact) {
      this.updateContact(contact)
      // this.selectedRelay = [contact.id]
    },
    
    listScrolled(ev) {
      if (!this.loading && ev.srcElement.scrollTop + ev.srcElement.clientHeight >= ev.srcElement.scrollHeight) {
        this.loadMore();
      }
    },
    
    async init() {
      this.contacts = [];
      
      try {
        this.loading = true
        const {customFields} = await this.$newReq('GET', `contacts/browse/startup`)
        this.customFieldRef = new Map(customFields.map(val => [val.id, val]))
        
        await this.loadMore()
      } catch (e) {
        console.log(e)
        this.$snack.networkError()
      } finally {
        this.loading = false
      }
    },
    
    async loadMore(clear = false, erase = false) {
      try {
        
        if (clear) {
          this.contacts = []
          this.internalSelected = null
        }
        
        const searchOptions = {
          userId: this.userInfo.userID,
          searchStr: this.searchStr,
          folderFilter: this.folderFilter,
          registeredOnly: this.registeredOnly
        }
        
        const searchOptionsString = Object.values(searchOptions).join('')
        
        if (searchOptionsString === this.searchOptionsVal && this.contacts.length >= this.contactsCount)
          return this.loading = false
        
        this.loading = true
        
        if (erase) {
          this.searchStr = null
        }
        
        this.searchOptionsVal = searchOptionsString
        const {contacts: receivedContacts, contactsCount} = await this.$newReq('POST', `contacts/browse/search`, {
          ...searchOptions,
          skip: this.contacts.length || null,
          limit: this.cardOnly ? 200 : null,
          ignoreManagement: this.ignoreManagement
        })
        this.contacts.push(...receivedContacts)
        this.contactsCount = contactsCount
      } catch (e) {
        console.log(e)
        this.$snack.networkError()
      } finally {
        this.loading = false
      }
    },
    
    async unlinkContact(contact) {
      try {
        this.loading = true
        
        const contactRemoved = await this.$newReq('POST', `contacts/unlink`, {
          folderId: this.folderFilter,
          contactId: contact.id
        })
        this.internalSelected = []
        arrSplice(this.contacts, listContact => listContact.id === contact.id)
      } catch (e) {
        console.log(e)
        this.$snack.networkError()
      } finally {
        this.loading = false
      }
    },
    hideDialog() {
      this.shownRelay = false
    }
  },
  computed: {
    ...mapGetters('userInfo', ['userInfo']),
    shownRelay: {
      get() {
        return this.shown || this.selfShown
      },
      set(val) {
        this.selfShown = val
        this.$emit("update:shown", val)
      }
    },
    
    selectedContacts() {
      return this.contacts.filter(contact => this.selectedRelay.includes(contact.id))
    },
    
    selectedRelay: {
      get() {
        return this.internalSelected || []
      },
      set(val) {
        
        if (!Array.isArray(this.internalSelected))
          this.internalSelected = []
        
        if (this.internalSelected.includes(val)) {
          
          if (this.multiSelect)
            arrSplice(this.internalSelected || [], spliceVal => spliceVal === val)
          else
            this.internalSelected = []
          
          
        } else {
          
          if (this.multiSelect)
            this.internalSelected.push(val)
          else
            this.internalSelected = [val]
          
        }
        
        
        
      }
    }
  },
}
</script>

<style scoped>

</style>