<template>
<!--@dragend="handleDragEnd"-->
    <div
            class="pr-2 pl-1 pt-2 pb-0"
    >
        <v-card
                hover
                style="cursor: pointer"
                :draggable="true"
                @drag="$event.preventDefault()"
                @dragstart="setDataTransfer($event)"
                @dragover="$event.preventDefault()"
                @drop="droppedEvent($event)"
                :id="`WorkflowManagerRule${editRule.id}`"
        >
            <div class="title-color body-1 white--text px-2">
                <v-layout row align-center class="hide-parent">
                    <v-flex xs12 class="py-1">
                        {{editRule.description != null && editRule.description != '' ? editRule.description : `Rule ${editRule.id}`}}
                    </v-flex>
                    <v-flex shrink class="hide-child pl-2">
                        <v-tooltip top>
                            <v-btn
                                    small
                                    icon
                                    flat
                                    class="pa-0 ma-0"
                                    color="white"
                                    slot="activator"
                                    @click="deleteRule()"
                                    :loading="removingRule"
                            >
                                <v-icon small>delete</v-icon>
                            </v-btn>
                            <span>Remove Rule</span>
                        </v-tooltip>
                    </v-flex>
                    <v-flex shrink class="hide-child pl-2">
                        <v-tooltip top>
                            <v-btn
                                    small
                                    icon
                                    flat
                                    class="pa-0 ma-0"
                                    color="white"
                                    slot="activator"
                                    @click="$emit('addCalled')"
                            >
                                <v-icon small>add_to_photos</v-icon>
                            </v-btn>
                            <span>Add Multiple Fields to Rule</span>
                        </v-tooltip>
                    </v-flex>
                    <v-flex shrink class="hide-child pl-2">
                        <v-tooltip top>
                            <v-btn
                                    small
                                    icon
                                    flat
                                    class="pa-0 ma-0"
                                    color="white"
                                    slot="activator"
                                    @click="$emit('editCalled')"
                            >
                                <v-icon small>edit</v-icon>
                            </v-btn>
                            <span>Edit Rule</span>
                        </v-tooltip>
                    </v-flex>
                </v-layout>
            </div>
            <div :class="topicClass" class="pa-1">
<!--Rule Conditions-->
                <template v-if="editRule.conditions.length > 0">
                    <div :class="headingClass" class="">
                        Rule Conditions:
                    </div>
                    <div :class="itemClass">
                        <div class="caption" v-for="(field, n) in conditionFields" :key="n">
                            <v-layout row align-center>
                                <v-flex xs6>
                                    {{field.description}}
                                </v-flex>
                                <template v-if="!['Not Blank', 'Is Blank'].includes(field.operation)">
                                    <v-flex xs3 class="primary--text font-weight-bold text-xs-center">
                                        {{field.operation}}
                                    </v-flex>
                                    <v-flex xs6 class="text-xs-right">
                                        {{`"${field.value}"`}}
                                    </v-flex>
                                </template>
                                <template v-else>
                                    <v-flex xs6 class="primary--text font-weight-bold text-xs-right">
                                        {{field.operation}}
                                    </v-flex>
                                </template>
                            </v-layout>
                        </div>
                    </div>
                </template>
<!--Show Fields-->
                <template v-if="showFields.length > 0">
                    <div :class="headingClass" class="pt-2">
                        Show Fields:
                    </div>
                    <div :class="itemClass">
                        <div class="caption" v-for="(field, n) in showFields" :key="n">
                            {{field.description}}
                        </div>
                    </div>
                </template>
<!--Show Steps-->
                <template v-if="showSteps.length > 0">
                    <div :class="headingClass" class="pt-2">
                        Show Steps:
                    </div>
                    <div :class="itemClass">
                        <div class="caption hover-item pl-2" v-for="(step, n) in showSteps" :key="n" style="cursor: pointer">
                            {{step.description}}
                        </div>
                    </div>
                </template>
            </div>
        </v-card>
    </div>


</template>

<script>
    import {codeBaseMixin} from "../../../../../codeBaseMixin";

    export default {
        mixins: [codeBaseMixin],
        props: {
            editRule: Object,
            selectLists: Array,
            fields: Array,
            steps: Array,
            userInfo: Object,
        },
        data() {
            return {
                headingClass: 'caption greyType--text font-weight-bold',
                topicClass: 'pa-1',
                itemClass: 'pl-2',

                operationDescriptions: {
                    0: "=",
                    1: "Not Equal",
                    2: "Not Blank",
                    3: "Bigger",
                    4: "Smaller",
                    5: "After",
                    6: "Before",
                    7: "Is Blank"
                },
                removingRule: false
            }
        },
        methods: {
            deleteRule() {
                this.removingRule = true;
                this.fetch({method: 'DELETE', url:`/workflowManager/rules/${this.userInfo.entityID}/${this.editRule.id}`})
                    .then(() => {
                        this.removingRule = false;
                        this.$emit('ruleRemoved', this.editRule)
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e);
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
                        this.removingRule = false;
                    })
            },

            setDataTransfer(event) {
                event.dataTransfer.setData('text', `${JSON.stringify({type: 'rule', data: this.editRule})}`);
            },

            droppedEvent(dropEvent) {
                try {
                    let dropData = dropEvent.dataTransfer.getData('text');
                    dropData = JSON.parse(dropData);

                    if (typeof dropData.type != 'undefined' && typeof dropData.data != undefined) {
                        this.$emit('ruleDrop', {type: dropData.type, dragData: dropData.data, dropRule: this.editRule});
                    }
                } catch (e) {
                    console.log(e);
                }
            },
        },
        computed: {
            conditionFields() {
                try {
                    return this.editRule.conditions.reduce((list, field) => {
                        list.push({
                            description: this.fields.reduce((description, workflowField) => {
                                if (field.fk_field == workflowField.id) {
                                    description = workflowField.description
                                }
                                return description
                            }, ''),
                            operation: this.operationDescriptions[field.operationID],
                            value: field.value
                        });
                        return list

                    }, []).sort((a,b) => a['description'] < b['description'] ? -1 : 1);
                } catch (e) {
                    // eslint-disable-next-line
                    console.log(e);
                    return null;
                }
            },

            showFields() {
                try {
                    return this.fields.reduce((list, field) => {
                        if (this.editRule.showFields.includes(field.id)) {
                            list.push({id: field.id, description: field.description})
                        }
                        return list
                    }, [])
                } catch (e) {
                    // eslint-disable-next-line
                    return [];
                }
            },

            showSteps() {
                try {
                    return this.steps.reduce((list, step) => {
                        if (this.editRule.showSteps.includes(step.id)) {
                            list.push({id: step.id, description: step.description})
                        }
                        return list
                    }, [])
                } catch (e) {
                    // eslint-disable-next-line
                    return [];
                }
            }
        }
    }
</script>

<style scoped>

</style>
