<template>
    <v-layout row wrap>
        <v-flex xs12 class="greyType--text body-1">
            {{label}}
        </v-flex>
        <v-flex xs3 class="pr-2">
            <v-text-field
                    label="Months"
                    hide-details
                    mask="##"
                    v-model="months"
                    @keyup="dotValueChanged($event)"
                    @click="dotValueChanged($event)"
                    @change="emitNewValue()"
                    ref="monthsInput"
                    type="number"
            >
            </v-text-field>
        </v-flex>
        <v-flex xs3 class="pr-2">
            <v-text-field
                    hide-details
                    mask="##"
                    v-model="days"
                    @keyup="dotValueChanged($event)"
                    @click="dotValueChanged($event)"
                    @change="emitNewValue()"
                    label="Days"
                    type="number"
            >
            </v-text-field>
        </v-flex>
        <v-flex xs3 class="pr-2">
            <v-text-field
                    hide-details
                    mask="##"
                    v-model="hours"
                    @keyup="dotValueChanged($event)"
                    @click="dotValueChanged($event)"
                    @change="emitNewValue()"
                    label="Hours"
                    type="number"
            >
            </v-text-field>
        </v-flex>
        <v-flex xs3 class="pr-2">
            <v-text-field
                    hide-details
                    mask="##"
                    v-model="minutes"
                    @keyup="dotValueChanged($event)"
                    @click="dotValueChanged($event)"
                    @change="emitNewValue()"
                    label="Minutes"
                    type="number"
            >
            </v-text-field>
        </v-flex>
    </v-layout>
</template>

<script>
    export default {
        data() {
            return {
                Months: null,
                Days: null,
                Hours: null,
                Minutes: null,
            }
        },

        props: {
            label: {
                type:String,
                default: null
            },
            value: {
                type: Object,
                default: () => {
                    return {
                        months: 0,
                        days: 0,
                        hours: 0,
                        minutes: 0
                    }
                }
            }
        },

        watch: {
            value: {
                immediate: true,
                handler(val) {
                    this.Months = val.months;
                    this.Days = val.days;
                    this.Hours = val.hours;
                    this.Minutes = val.minutes;
                }
            }
        },

        methods: {
            emitNewValue() {
                this.$emit('input', {
                    months: this.Months,
                    days: this.Days,
                    hours: this.Hours,
                    minutes: this.Minutes,
                })
            },

            dotValueChanged(event) {
                setTimeout(() => {
                    if (event.target.value == '00') {

                        event.srcElement.select();
                    }
                }, 50)
            }
        },

        computed: {
            months: {
                // getter
                get: function () {
                    if (this.Months != null && this.Months != '') {
                        if (`${this.Months.length}`.length == 1) {
                            return `0${this.Months}`;
                        } else {
                            return `${this.Months}`;
                        }
                    } else {
                        return `00`;
                    }
                },
                // setter
                set: function (newValue) {
                    this.Months = newValue == null || newValue == '' ? 0 : parseInt(newValue) ;
                }
            },
            days: {
                // getter
                get: function () {
                    if (this.Days != null && this.Days != '') {
                        if (`${this.Days.length}`.length == 1) {
                            return `0${this.Days}`;
                        } else {
                            return `${this.Days}`;
                        }
                    } else {
                        return `00`;
                    }
                },
                // setter
                set: function (newValue) {
                    this.Days = newValue == null || newValue == '' ? 0 : parseInt(newValue) ;
                }
            },
            hours: {
                // getter
                get: function () {
                    if (this.Hours != null && this.Hours != '') {
                        if (`${this.Hours.length}`.length == 1) {
                            return `0${this.Hours}`;
                        } else {
                            return `${this.Hours}`;
                        }
                    } else {
                        return `00`;
                    }
                },
                // setter
                set: function (newValue) {
                    this.Hours = newValue == null || newValue == '' ? 0 : parseInt(newValue) ;
                }
            },
            minutes: {
                // getter
                get: function () {
                    if (this.Minutes != null && this.Minutes != '') {
                        if (`${this.Minutes.length}`.length == 1) {
                            return `0${this.Minutes}`;
                        } else {
                            return `${this.Minutes}`;
                        }
                    } else {
                        return `00`;
                    }
                },
                // setter
                set: function (newValue) {
                    this.Minutes = newValue == null || newValue == '' ? 0 : parseInt(newValue) ;
                }
            },
        }
    }
</script>

<style scoped>

</style>
