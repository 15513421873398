module.exports = [
  {
    "name": "10k",
    "tagStr": "10000 10K alphabet character digit display font letter number pixel pixels resolution symbol text type video",
    "description": "10k"
  },
  {
    "name": "10mp",
    "tagStr": "10 camera digits font image letters megapixel megapixels mp numbers pixel pixels quality resolution symbol text type",
    "description": "10mp"
  },
  {
    "name": "11mp",
    "tagStr": "11 camera digits font image letters megapixel megapixels mp numbers pixel pixels quality resolution symbol text type",
    "description": "11mp"
  },
  {
    "name": "12mp",
    "tagStr": "12 camera digits font image letters megapixel megapixels mp numbers pixel pixels quality resolution symbol text type",
    "description": "12mp"
  },
  {
    "name": "13mp",
    "tagStr": "13 camera digits font image letters megapixel megapixels mp numbers pixel pixels quality resolution symbol text type",
    "description": "13mp"
  },
  {
    "name": "14mp",
    "tagStr": "14 camera digits font image letters megapixel megapixels mp numbers pixel pixels quality resolution symbol text type",
    "description": "14mp"
  },
  {
    "name": "15mp",
    "tagStr": "15 camera digits font image letters megapixel megapixels mp numbers pixel pixels quality resolution symbol text type",
    "description": "15mp"
  },
  {
    "name": "16mp",
    "tagStr": "16 camera digits font image letters megapixel megapixels mp numbers pixel pixels quality resolution symbol text type",
    "description": "16mp"
  },
  {
    "name": "17mp",
    "tagStr": "17 camera digits font image letters megapixel megapixels mp numbers pixel pixels quality resolution symbol text type",
    "description": "17mp"
  },
  {
    "name": "18mp",
    "tagStr": "18 camera digits font image letters megapixel megapixels mp numbers pixel pixels quality resolution symbol text type",
    "description": "18mp"
  },
  {
    "name": "19mp",
    "tagStr": "19 camera digits font image letters megapixel megapixels mp numbers pixel pixels quality resolution symbol text type",
    "description": "19mp"
  },
  {
    "name": "1k",
    "tagStr": "1000 1K alphabet character digit display font letter number pixel pixels resolution symbol text type video",
    "description": "1k"
  },
  {
    "name": "1k_plus",
    "tagStr": "+ 1000 1K alphabet character digit display font letter number pixel pixels plus resolution symbol text type video",
    "description": "1k Plus"
  },
  {
    "name": "1x_mobiledata",
    "tagStr": "1x alphabet cellular character digit font letter mobile mobiledata network number phone signal speed symbol text type wifi",
    "description": "1x Mobiledata"
  },
  {
    "name": "20mp",
    "tagStr": "20 camera digits font image letters megapixel megapixels mp numbers pixel pixels quality resolution symbol text type",
    "description": "20mp"
  },
  {
    "name": "21mp",
    "tagStr": "21 camera digits font image letters megapixel megapixels mp numbers pixel pixels quality resolution symbol text type",
    "description": "21mp"
  },
  {
    "name": "22mp",
    "tagStr": "22 camera digits font image letters megapixel megapixels mp numbers pixel pixels quality resolution symbol text type",
    "description": "22mp"
  },
  {
    "name": "23mp",
    "tagStr": "23 camera digits font image letters megapixel megapixels mp numbers pixel pixels quality resolution symbol text type",
    "description": "23mp"
  },
  {
    "name": "24mp",
    "tagStr": "24 camera digits font image letters megapixel megapixels mp numbers pixel pixels quality resolution symbol text type",
    "description": "24mp"
  },
  {
    "name": "2k",
    "tagStr": "2000 2K alphabet character digit display font letter number pixel pixels resolution symbol text type video",
    "description": "2k"
  },
  {
    "name": "2k_plus",
    "tagStr": "+ 2k alphabet character digit font letter number plus symbol text type",
    "description": "2k Plus"
  },
  {
    "name": "2mp",
    "tagStr": "2 camera digit font image letters megapixel megapixels mp number pixel pixels quality resolution symbol text type",
    "description": "2mp"
  },
  {
    "name": "30fps",
    "tagStr": "30fps alphabet camera character digit font fps frames letter number symbol text type video",
    "description": "30fps"
  },
  {
    "name": "30fps_select",
    "tagStr": "30 camera digits fps frame frequency image numbers per rate second seconds select video",
    "description": "30fps Select"
  },
  {
    "name": "360",
    "tagStr": "360 arrow av camera direction rotate rotation vr",
    "description": "360"
  },
  {
    "name": "3d_rotation",
    "tagStr": "3 3d D alphabet arrow arrows av camera character digit font letter number rotation symbol text type vr",
    "description": "3d Rotation"
  },
  {
    "name": "3g_mobiledata",
    "tagStr": "3g alphabet cellular character digit font letter mobile mobiledata network number phone signal speed symbol text type wifi",
    "description": "3g Mobiledata"
  },
  {
    "name": "3k",
    "tagStr": "3000 3K alphabet character digit display font letter number pixel pixels resolution symbol text type video",
    "description": "3k"
  },
  {
    "name": "3k_plus",
    "tagStr": "+ 3000 3K alphabet character digit display font letter number pixel pixels plus resolution symbol text type video",
    "description": "3k Plus"
  },
  {
    "name": "3mp",
    "tagStr": "3 camera digit font image letters megapixel megapixels mp number pixel pixels quality resolution symbol text type",
    "description": "3mp"
  },
  {
    "name": "3p",
    "tagStr": "3 3p account avatar bubble chat comment communicate face human message party people person profile speech user",
    "description": "3p"
  },
  {
    "name": "4g_mobiledata",
    "tagStr": "4g alphabet cellular character digit font letter mobile mobiledata network number phone signal speed symbol text type wifi",
    "description": "4g Mobiledata"
  },
  {
    "name": "4g_plus_mobiledata",
    "tagStr": "4g alphabet cellular character digit font letter mobile mobiledata network number phone plus signal speed symbol text type wifi",
    "description": "4g Plus Mobiledata"
  },
  {
    "name": "4k",
    "tagStr": "4000 4K alphabet character digit display font letter number pixel pixels resolution symbol text type video",
    "description": "4k"
  },
  {
    "name": "4k_plus",
    "tagStr": "+ 4000 4K alphabet character digit display font letter number pixel pixels plus resolution symbol text type video",
    "description": "4k Plus"
  },
  {
    "name": "4mp",
    "tagStr": "4 camera digit font image letters megapixel megapixels mp number pixel pixels quality resolution symbol text type",
    "description": "4mp"
  },
  {
    "name": "5g",
    "tagStr": "5g alphabet cellular character data digit font letter mobile network number phone signal speed symbol text type wifi",
    "description": "5g"
  },
  {
    "name": "5k",
    "tagStr": "5000 5K alphabet character digit display font letter number pixel pixels resolution symbol text type video",
    "description": "5k"
  },
  {
    "name": "5k_plus",
    "tagStr": "+ 5000 5K alphabet character digit display font letter number pixel pixels plus resolution symbol text type video",
    "description": "5k Plus"
  },
  {
    "name": "5mp",
    "tagStr": "5 camera digit font image letters megapixel megapixels mp number pixel pixels quality resolution symbol text type",
    "description": "5mp"
  },
  {
    "name": "60fps",
    "tagStr": "60fps camera digit fps frames number symbol video",
    "description": "60fps"
  },
  {
    "name": "60fps_select",
    "tagStr": "60 camera digits fps frame frequency numbers per rate second seconds select video",
    "description": "60fps Select"
  },
  {
    "name": "6_ft_apart",
    "tagStr": "6 apart body covid distance feet ft human people person social",
    "description": "6 Ft Apart"
  },
  {
    "name": "6k",
    "tagStr": "6000 6K alphabet character digit display font letter number pixel pixels resolution symbol text type video",
    "description": "6k"
  },
  {
    "name": "6k_plus",
    "tagStr": "+ 6000 6K alphabet character digit display font letter number pixel pixels plus resolution symbol text type video",
    "description": "6k Plus"
  },
  {
    "name": "6mp",
    "tagStr": "6 camera digit font image letters megapixel megapixels mp number pixel pixels quality resolution symbol text type",
    "description": "6mp"
  },
  {
    "name": "7k",
    "tagStr": "7000 7K alphabet character digit display font letter number pixel pixels resolution symbol text type video",
    "description": "7k"
  },
  {
    "name": "7k_plus",
    "tagStr": "+ 7000 7K alphabet character digit display font letter number pixel pixels plus resolution symbol text type video",
    "description": "7k Plus"
  },
  {
    "name": "7mp",
    "tagStr": "7 camera digit font image letters megapixel megapixels mp number pixel pixels quality resolution symbol text type",
    "description": "7mp"
  },
  {
    "name": "8k",
    "tagStr": "8000 8K alphabet character digit display font letter number pixel pixels resolution symbol text type video",
    "description": "8k"
  },
  {
    "name": "8k_plus",
    "tagStr": "+ 7000 8K alphabet character digit display font letter number pixel pixels plus resolution symbol text type video",
    "description": "8k Plus"
  },
  {
    "name": "8mp",
    "tagStr": "8 camera digit font image letters megapixel megapixels mp number pixel pixels quality resolution symbol text type",
    "description": "8mp"
  },
  {
    "name": "9k",
    "tagStr": "9000 9K alphabet character digit display font letter number pixel pixels resolution symbol text type video",
    "description": "9k"
  },
  {
    "name": "9k_plus",
    "tagStr": "+ 9000 9K alphabet character digit display font letter number pixel pixels plus resolution symbol text type video",
    "description": "9k Plus"
  },
  {
    "name": "9mp",
    "tagStr": "9 camera digit font image letters megapixel megapixels mp number pixel pixels quality resolution symbol text type",
    "description": "9mp"
  },
  {
    "name": "ac_unit",
    "tagStr": "ac air cold conditioner flake snow temperature unit weather winter",
    "description": "Ac Unit"
  },
  {
    "name": "access_alarm",
    "tagStr": "",
    "description": "Access Alarm"
  },
  {
    "name": "access_alarms",
    "tagStr": "",
    "description": "Access Alarms"
  },
  {
    "name": "access_time",
    "tagStr": "",
    "description": "Access Time"
  },
  {
    "name": "access_time_filled",
    "tagStr": "",
    "description": "Access Time Filled"
  },
  {
    "name": "accessibility",
    "tagStr": "accessibility accessible body handicap help human people person",
    "description": "Accessibility"
  },
  {
    "name": "accessibility_new",
    "tagStr": "accessibility accessible body handicap help human people person",
    "description": "Accessibility New"
  },
  {
    "name": "accessible",
    "tagStr": "accessibility accessible body handicap help human people person wheelchair",
    "description": "Accessible"
  },
  {
    "name": "accessible_forward",
    "tagStr": "accessibility accessible body forward handicap help human people person wheelchair",
    "description": "Accessible Forward"
  },
  {
    "name": "account_balance",
    "tagStr": "account balance bank bill card cash coin commerce credit currency dollars finance money online pay payment",
    "description": "Account Balance"
  },
  {
    "name": "account_balance_wallet",
    "tagStr": "account balance bank bill card cash coin commerce credit currency dollars finance money online pay payment wallet",
    "description": "Account Balance Wallet"
  },
  {
    "name": "account_box",
    "tagStr": "account avatar box face human people person profile square thumbnail user",
    "description": "Account Box"
  },
  {
    "name": "account_circle",
    "tagStr": "account avatar circle face human people person profile thumbnail user",
    "description": "Account Circle"
  },
  {
    "name": "account_tree",
    "tagStr": "account analytics chart connect data diagram flow graph infographic measure metrics process square statistics structure tracking tree",
    "description": "Account Tree"
  },
  {
    "name": "ad_units",
    "tagStr": "Android OS ad banner cell device hardware iOS mobile notification notifications phone tablet top units",
    "description": "Ad Units"
  },
  {
    "name": "adb",
    "tagStr": "adb android bridge debug",
    "description": "Adb"
  },
  {
    "name": "add",
    "tagStr": "+ add create new new symbol plus symbol",
    "description": "Add"
  },
  {
    "name": "add_a_photo",
    "tagStr": "+ a photo add camera lens new photography picture plus symbol",
    "description": "Add A Photo"
  },
  {
    "name": "add_alarm",
    "tagStr": "",
    "description": "Add Alarm"
  },
  {
    "name": "add_alert",
    "tagStr": "+ active add alarm alert bell chime new notifications notify plus reminder ring sound symbol",
    "description": "Add Alert"
  },
  {
    "name": "add_box",
    "tagStr": "add box new square plus symbol",
    "description": "Add Box"
  },
  {
    "name": "add_business",
    "tagStr": "+ add business market plus retail shop shopping store storefront",
    "description": "Add Business"
  },
  {
    "name": "add_chart",
    "tagStr": "+ add analytics bar bars chart data diagram graph infographic measure metrics new plus statistics symbol tracking",
    "description": "Add Chart"
  },
  {
    "name": "add_circle",
    "tagStr": "+ add circle create new plus",
    "description": "Add Circle"
  },
  {
    "name": "add_circle_outline",
    "tagStr": "+ add circle create new outline plus",
    "description": "Add Circle Outline"
  },
  {
    "name": "add_comment",
    "tagStr": "+ add bubble chat comment communicate feedback message new plus speech symbol",
    "description": "Add Comment"
  },
  {
    "name": "add_ic_call",
    "tagStr": "+ add call cell contact device hardware ic mobile new phone plus symbol telephone",
    "description": "Add Ic Call"
  },
  {
    "name": "add_link",
    "tagStr": "add attach clip link new plus symbol",
    "description": "Add Link"
  },
  {
    "name": "add_location",
    "tagStr": "+ add destination direction location maps new pin place plus stop symbol",
    "description": "Add Location"
  },
  {
    "name": "add_location_alt",
    "tagStr": "+ add alt destination direction location maps new pin place plus stop symbol",
    "description": "Add Location Alt"
  },
  {
    "name": "add_moderator",
    "tagStr": "+ add certified moderator new plus privacy private protect protection security shield symbol verified",
    "description": "Add Moderator"
  },
  {
    "name": "add_photo_alternate",
    "tagStr": "+ add alternate image landscape mountain mountains new photo photography picture plus symbol",
    "description": "Add Photo Alternate"
  },
  {
    "name": "add_reaction",
    "tagStr": "",
    "description": "Add Reaction"
  },
  {
    "name": "add_road",
    "tagStr": "+ add destination direction highway maps new plus road stop street symbol traffic",
    "description": "Add Road"
  },
  {
    "name": "add_shopping_cart",
    "tagStr": "add card cart cash checkout coin commerce credit currency dollars money online pay payment plus shopping",
    "description": "Add Shopping Cart"
  },
  {
    "name": "add_task",
    "tagStr": "+ add approve check circle completed increase mark ok plus select task tick yes",
    "description": "Add Task"
  },
  {
    "name": "add_to_drive",
    "tagStr": "+ add app backup cloud drive files folders gdrive google plus storage to",
    "description": "Add To Drive"
  },
  {
    "name": "add_to_home_screen",
    "tagStr": "Android OS add to arrow cell device hardware home iOS mobile phone screen tablet up",
    "description": "Add To Home Screen"
  },
  {
    "name": "add_to_photos",
    "tagStr": "add collection image landscape mountain mountains photo photography photos picture plus to",
    "description": "Add To Photos"
  },
  {
    "name": "add_to_queue",
    "tagStr": "+ Android OS add chrome desktop device display hardware iOS mac monitor new plus queue screen symbol to web window",
    "description": "Add To Queue"
  },
  {
    "name": "addchart",
    "tagStr": "add analytics bar bars chart data diagram graph infographic measure metrics plus statistics tracking",
    "description": "Addchart"
  },
  {
    "name": "adjust",
    "tagStr": "adjust alter center circle circles control dot edit filter fix image mix move setting slider sort switch target tune",
    "description": "Adjust"
  },
  {
    "name": "admin_panel_settings",
    "tagStr": "account admin avatar certified face human panel people person privacy private profile protect protection security settings shield user verified",
    "description": "Admin Panel Settings"
  },
  {
    "name": "adobe",
    "tagStr": "",
    "description": "Adobe"
  },
  {
    "name": "ads_click",
    "tagStr": "",
    "description": "Ads Click"
  },
  {
    "name": "agriculture",
    "tagStr": "agriculture automobile car cars cultivation farm harvest maps tractor transport travel truck vehicle",
    "description": "Agriculture"
  },
  {
    "name": "air",
    "tagStr": "air blowing breeze flow wave weather wind",
    "description": "Air"
  },
  {
    "name": "airline_seat_flat",
    "tagStr": "airline body business class first flat human people person rest seat sleep travel",
    "description": "Airline Seat Flat"
  },
  {
    "name": "airline_seat_flat_angled",
    "tagStr": "airline angled body business class first flat human people person rest seat sleep travel",
    "description": "Airline Seat Flat Angled"
  },
  {
    "name": "airline_seat_individual_suite",
    "tagStr": "airline body business class first human individual people person rest seat sleep suite travel",
    "description": "Airline Seat Individual Suite"
  },
  {
    "name": "airline_seat_legroom_extra",
    "tagStr": "airline body extra feet human leg legroom people person seat sitting space travel",
    "description": "Airline Seat Legroom Extra"
  },
  {
    "name": "airline_seat_legroom_normal",
    "tagStr": "airline body feet human leg legroom normal people person seat sitting space travel",
    "description": "Airline Seat Legroom Normal"
  },
  {
    "name": "airline_seat_legroom_reduced",
    "tagStr": "airline body feet human leg legroom people person reduced seat sitting space travel",
    "description": "Airline Seat Legroom Reduced"
  },
  {
    "name": "airline_seat_recline_extra",
    "tagStr": "airline body extra feet human leg legroom people person seat sitting space travel",
    "description": "Airline Seat Recline Extra"
  },
  {
    "name": "airline_seat_recline_normal",
    "tagStr": "airline body extra feet human leg legroom normal people person recline seat sitting space travel",
    "description": "Airline Seat Recline Normal"
  },
  {
    "name": "airline_stops",
    "tagStr": "",
    "description": "Airline Stops"
  },
  {
    "name": "airlines",
    "tagStr": "",
    "description": "Airlines"
  },
  {
    "name": "airplane_ticket",
    "tagStr": "airplane airport boarding flight fly maps pass ticket transportation travel",
    "description": "Airplane Ticket"
  },
  {
    "name": "airplanemode_active",
    "tagStr": "active airplane airplanemode flight mode on signal",
    "description": "Airplanemode Active"
  },
  {
    "name": "airplanemode_inactive",
    "tagStr": "airplane airplanemode airport disabled enabled flight fly inactive maps mode off offline on slash transportation travel",
    "description": "Airplanemode Inactive"
  },
  {
    "name": "airplay",
    "tagStr": "airplay apple arrow cast connect control desktop device display monitor screen signal tv",
    "description": "Airplay"
  },
  {
    "name": "airport_shuttle",
    "tagStr": "airport automobile car cars commercial delivery direction maps mini public shuttle transport transportation travel truck van vehicle",
    "description": "Airport Shuttle"
  },
  {
    "name": "alarm",
    "tagStr": "alarm alart bell clock countdown date notification schedule time",
    "description": "Alarm"
  },
  {
    "name": "alarm_add",
    "tagStr": "+ add alarm alart bell clock countdown date new notification plus schedule symbol time",
    "description": "Alarm Add"
  },
  {
    "name": "alarm_off",
    "tagStr": "alarm alart bell clock disabled duration enabled notification off on slash time timer watch",
    "description": "Alarm Off"
  },
  {
    "name": "alarm_on",
    "tagStr": "alarm alart bell clock disabled duration enabled notification off on slash time timer watch",
    "description": "Alarm On"
  },
  {
    "name": "album",
    "tagStr": "album artist audio bvb cd computer data disk file music record sound storage track vinyl",
    "description": "Album"
  },
  {
    "name": "align_horizontal_center",
    "tagStr": "align alignment center format horizontal layout lines paragraph rule rules style text",
    "description": "Align Horizontal Center"
  },
  {
    "name": "align_horizontal_left",
    "tagStr": "align alignment format horizontal layout left lines paragraph rule rules style text",
    "description": "Align Horizontal Left"
  },
  {
    "name": "align_horizontal_right",
    "tagStr": "align alignment format horizontal layout lines paragraph right rule rules style text",
    "description": "Align Horizontal Right"
  },
  {
    "name": "align_vertical_bottom",
    "tagStr": "align alignment bottom format layout lines paragraph rule rules style text vertical",
    "description": "Align Vertical Bottom"
  },
  {
    "name": "align_vertical_center",
    "tagStr": "align alignment center format layout lines paragraph rule rules style text vertical",
    "description": "Align Vertical Center"
  },
  {
    "name": "align_vertical_top",
    "tagStr": "align alignment format layout lines paragraph rule rules style text top vertical",
    "description": "Align Vertical Top"
  },
  {
    "name": "all_inbox",
    "tagStr": "Inbox all delivered delivery email mail message send",
    "description": "All Inbox"
  },
  {
    "name": "all_inclusive",
    "tagStr": "all endless forever inclusive infinity loop mobius neverending strip sustainability sustainable",
    "description": "All Inclusive"
  },
  {
    "name": "all_out",
    "tagStr": "all circle out shape",
    "description": "All Out"
  },
  {
    "name": "alt_route",
    "tagStr": "alt alternate alternative arrows direction maps navigation options other route routes split symbol",
    "description": "Alt Route"
  },
  {
    "name": "alternate_email",
    "tagStr": "@ address alternate contact email tag",
    "description": "Alternate Email"
  },
  {
    "name": "analytics",
    "tagStr": "analytics assessment bar chart data diagram graph infographic measure metrics statistics tracking",
    "description": "Analytics"
  },
  {
    "name": "anchor",
    "tagStr": "anchor google logo",
    "description": "Anchor"
  },
  {
    "name": "android",
    "tagStr": "android character logo mascot toy",
    "description": "Android"
  },
  {
    "name": "animation",
    "tagStr": "animation circles motion movie moving video",
    "description": "Animation"
  },
  {
    "name": "announcement",
    "tagStr": "! alert announcement attention bubble caution chat comment communicate danger error exclamation feedback important mark message notification speech symbol warning",
    "description": "Announcement"
  },
  {
    "name": "aod",
    "tagStr": "Android OS always aod device display hardware homescreen iOS mobile on phone tablet",
    "description": "Aod"
  },
  {
    "name": "apartment",
    "tagStr": "accommodation apartment architecture building city company estate flat home house office places real residence residential shelter units workplace",
    "description": "Apartment"
  },
  {
    "name": "api",
    "tagStr": "api developer development enterprise software",
    "description": "Api"
  },
  {
    "name": "app_blocking",
    "tagStr": "Android OS app applications cancel cell device hardware iOS mobile phone stop tablet",
    "description": "App Blocking"
  },
  {
    "name": "app_registration",
    "tagStr": "app apps edit pencil register registration",
    "description": "App Registration"
  },
  {
    "name": "app_settings_alt",
    "tagStr": "Android OS app applications cell device gear hardware iOS mobile phone setting settings tablet",
    "description": "App Settings Alt"
  },
  {
    "name": "apple",
    "tagStr": "",
    "description": "Apple"
  },
  {
    "name": "approval",
    "tagStr": "apply approval approvals approve certificate certification disapproval drive file impression ink mark postage stamp",
    "description": "Approval"
  },
  {
    "name": "apps",
    "tagStr": "all applications apps circles collection dots grid squares",
    "description": "Apps"
  },
  {
    "name": "apps_outage",
    "tagStr": "",
    "description": "Apps Outage"
  },
  {
    "name": "architecture",
    "tagStr": "architecture art compass design draw drawing engineering geometric tool",
    "description": "Architecture"
  },
  {
    "name": "archive",
    "tagStr": "archive inbox mail store",
    "description": "Archive"
  },
  {
    "name": "area_chart",
    "tagStr": "",
    "description": "Area Chart"
  },
  {
    "name": "arrow_back",
    "tagStr": "app application arrow back components direction interface left navigation previous screen site ui ux web website",
    "description": "Arrow Back"
  },
  {
    "name": "arrow_back_ios",
    "tagStr": "app application arrow back chevron components direction interface ios left navigation previous screen site ui ux web website",
    "description": "Arrow Back Ios"
  },
  {
    "name": "arrow_back_ios_new",
    "tagStr": "app application arrow back chevron components direction interface ios left navigation new previous screen site ui ux web website",
    "description": "Arrow Back Ios New"
  },
  {
    "name": "arrow_circle_down",
    "tagStr": "arrow circle direction down navigation",
    "description": "Arrow Circle Down"
  },
  {
    "name": "arrow_circle_left",
    "tagStr": "",
    "description": "Arrow Circle Left"
  },
  {
    "name": "arrow_circle_right",
    "tagStr": "",
    "description": "Arrow Circle Right"
  },
  {
    "name": "arrow_circle_up",
    "tagStr": "arrow circle direction navigation up",
    "description": "Arrow Circle Up"
  },
  {
    "name": "arrow_downward",
    "tagStr": "app application arrow components direction down downward interface navigation screen site ui ux web website",
    "description": "Arrow Downward"
  },
  {
    "name": "arrow_drop_down",
    "tagStr": "app application arrow components direction down drop interface navigation screen site ui ux web website",
    "description": "Arrow Drop Down"
  },
  {
    "name": "arrow_drop_down_circle",
    "tagStr": "app application arrow circle components direction down drop interface navigation screen site ui ux web website",
    "description": "Arrow Drop Down Circle"
  },
  {
    "name": "arrow_drop_up",
    "tagStr": "app application arrow components direction drop interface navigation screen site ui up ux web website",
    "description": "Arrow Drop Up"
  },
  {
    "name": "arrow_forward",
    "tagStr": "app application arrow arrows components direction forward interface navigation right screen site ui ux web website",
    "description": "Arrow Forward"
  },
  {
    "name": "arrow_forward_ios",
    "tagStr": "app application arrow chevron components direction forward interface ios navigation next right screen site ui ux web website",
    "description": "Arrow Forward Ios"
  },
  {
    "name": "arrow_left",
    "tagStr": "app application arrow back backstack backward components direction interface left navigation previous screen site ui ux web website",
    "description": "Arrow Left"
  },
  {
    "name": "arrow_right",
    "tagStr": "app application arrow components continue direction forward interface navigation right screen site ui ux web website",
    "description": "Arrow Right"
  },
  {
    "name": "arrow_right_alt",
    "tagStr": "alt arrow arrows direction east navigation pointing right",
    "description": "Arrow Right Alt"
  },
  {
    "name": "arrow_upward",
    "tagStr": "app application arrow components direction interface navigation screen site ui up upward ux web website",
    "description": "Arrow Upward"
  },
  {
    "name": "art_track",
    "tagStr": "album art artist audio image music photo photography picture sound track tracks",
    "description": "Art Track"
  },
  {
    "name": "article",
    "tagStr": "article clarify doc document file news page paper text writing",
    "description": "Article"
  },
  {
    "name": "aspect_ratio",
    "tagStr": "aspect expand image ratio resize scale size square",
    "description": "Aspect Ratio"
  },
  {
    "name": "assessment",
    "tagStr": "analytics assessment bar chart data diagram graph infographic measure metrics statistics tracking",
    "description": "Assessment"
  },
  {
    "name": "assignment",
    "tagStr": "assignment clipboard doc document text writing",
    "description": "Assignment"
  },
  {
    "name": "assignment_ind",
    "tagStr": "account assignment clipboard doc document face ind people person profile user",
    "description": "Assignment Ind"
  },
  {
    "name": "assignment_late",
    "tagStr": "! alert assignment attention caution clipboard danger doc document error exclamation important late mark notification symbol warning",
    "description": "Assignment Late"
  },
  {
    "name": "assignment_return",
    "tagStr": "arrow assignment back clipboard doc document left retun",
    "description": "Assignment Return"
  },
  {
    "name": "assignment_returned",
    "tagStr": "arrow assignment clipboard doc document down returned",
    "description": "Assignment Returned"
  },
  {
    "name": "assignment_turned_in",
    "tagStr": "approve assignment check clipboard complete doc document done in mark ok select tick turn validate verified yes",
    "description": "Assignment Turned In"
  },
  {
    "name": "assistant",
    "tagStr": "assistant bubble chat comment communicate feedback message recommendation speech star suggestion twinkle",
    "description": "Assistant"
  },
  {
    "name": "assistant_direction",
    "tagStr": "assistant destination direction location maps navigate navigation pin place right stop",
    "description": "Assistant Direction"
  },
  {
    "name": "assistant_photo",
    "tagStr": "assistant flag photo recommendation smart star suggestion",
    "description": "Assistant Photo"
  },
  {
    "name": "atm",
    "tagStr": "alphabet atm automated bill card cart cash character coin commerce credit currency dollars font letter machine money online pay payment shopping symbol teller text type",
    "description": "Atm"
  },
  {
    "name": "attach_email",
    "tagStr": "attach attachment clip compose email envelop letter link mail message send",
    "description": "Attach Email"
  },
  {
    "name": "attach_file",
    "tagStr": "add attach clip file link mail media",
    "description": "Attach File"
  },
  {
    "name": "attach_money",
    "tagStr": "attach bill card cash coin commerce cost credit currency dollars finance money online pay payment symbol",
    "description": "Attach Money"
  },
  {
    "name": "attachment",
    "tagStr": "attach attachment clip compose file image link",
    "description": "Attachment"
  },
  {
    "name": "attractions",
    "tagStr": "amusement attractions entertainment ferris fun maps park places wheel",
    "description": "Attractions"
  },
  {
    "name": "attribution",
    "tagStr": "attribute attribution body copyright copywriter human people person",
    "description": "Attribution"
  },
  {
    "name": "audiotrack",
    "tagStr": "audio audiotrack key music note sound track",
    "description": "Audiotrack"
  },
  {
    "name": "auto_awesome",
    "tagStr": "auto awesome edit editing enhance filter image photography photos setting stars",
    "description": "Auto Awesome"
  },
  {
    "name": "auto_awesome_mosaic",
    "tagStr": "auto awesome edit editing filter grid image layout mosaic photographs photography photos pictures setting",
    "description": "Auto Awesome Mosaic"
  },
  {
    "name": "auto_awesome_motion",
    "tagStr": "animation auto awesome edit editing filter image live motion photographs photography photos pictures setting video",
    "description": "Auto Awesome Motion"
  },
  {
    "name": "auto_delete",
    "tagStr": "auto bin can clock date delete garbage remove schedule time trash",
    "description": "Auto Delete"
  },
  {
    "name": "auto_fix_high",
    "tagStr": "auto edit erase fix high magic modify star stars wand",
    "description": "Auto Fix High"
  },
  {
    "name": "auto_fix_normal",
    "tagStr": "auto edit erase fix magic modify normal star stars wand",
    "description": "Auto Fix Normal"
  },
  {
    "name": "auto_fix_off",
    "tagStr": "auto disabled edit enabled erase fix magic modify off on slash star stars wand",
    "description": "Auto Fix Off"
  },
  {
    "name": "auto_graph",
    "tagStr": "analytics auto chart data diagram graph infographic line measure metrics stars statistics tracking",
    "description": "Auto Graph"
  },
  {
    "name": "auto_stories",
    "tagStr": "audiobook auto book page reading stories story",
    "description": "Auto Stories"
  },
  {
    "name": "autofps_select",
    "tagStr": "A alphabet auto character font fps frame frequency letter per rate second seconds select symbol text type",
    "description": "Autofps Select"
  },
  {
    "name": "autorenew",
    "tagStr": "around arrow arrows autorenew cache cached direction inprogress load loading refresh navigation renew rotate turn",
    "description": "Autorenew"
  },
  {
    "name": "av_timer",
    "tagStr": "av clock countdown duration minutes seconds time timer watch",
    "description": "Av Timer"
  },
  {
    "name": "baby_changing_station",
    "tagStr": "babies baby bathroom body changing child children father human infant kids mother newborn people person station toddler wc young",
    "description": "Baby Changing Station"
  },
  {
    "name": "back_hand",
    "tagStr": "",
    "description": "Back Hand"
  },
  {
    "name": "backpack",
    "tagStr": "back backpack bag book bookbag knapsack pack storage travel",
    "description": "Backpack"
  },
  {
    "name": "backspace",
    "tagStr": "arrow back backspace cancel clear correct delete erase remove",
    "description": "Backspace"
  },
  {
    "name": "backup",
    "tagStr": "arrow backup cloud data drive files folders storage up upload",
    "description": "Backup"
  },
  {
    "name": "backup_table",
    "tagStr": "backup drive files folders format layout stack storage table",
    "description": "Backup Table"
  },
  {
    "name": "badge",
    "tagStr": "account avatar badge card certified employee face human identification name people person profile security user work",
    "description": "Badge"
  },
  {
    "name": "bakery_dining",
    "tagStr": "bakery bread breakfast brunch croissant dining food",
    "description": "Bakery Dining"
  },
  {
    "name": "balcony",
    "tagStr": "architecture balcony doors estate home house maps out outside place real residence residential stay terrace window",
    "description": "Balcony"
  },
  {
    "name": "ballot",
    "tagStr": "ballot bulllet election list point poll vote",
    "description": "Ballot"
  },
  {
    "name": "bar_chart",
    "tagStr": "analytics bar chart data diagram graph infographic measure metrics statistics tracking",
    "description": "Bar Chart"
  },
  {
    "name": "batch_prediction",
    "tagStr": "batch bulb idea light prediction",
    "description": "Batch Prediction"
  },
  {
    "name": "bathroom",
    "tagStr": "bath bathroom closet home house place plumbing room shower sprinkler wash water wc",
    "description": "Bathroom"
  },
  {
    "name": "bathtub",
    "tagStr": "bath bathing bathroom bathtub home hotel human person shower travel tub",
    "description": "Bathtub"
  },
  {
    "name": "battery_alert",
    "tagStr": "! alert attention battery caution cell charge danger error exclamation important mark mobile notification power symbol warning",
    "description": "Battery Alert"
  },
  {
    "name": "battery_charging_full",
    "tagStr": "battery bolt cell charge charging full lightening mobile power thunderbolt",
    "description": "Battery Charging Full"
  },
  {
    "name": "battery_full",
    "tagStr": "battery cell charge full mobile power",
    "description": "Battery Full"
  },
  {
    "name": "battery_saver",
    "tagStr": "+ add battery charge charging new plus power saver symbol",
    "description": "Battery Saver"
  },
  {
    "name": "battery_std",
    "tagStr": "battery cell charge mobile plus power standard std",
    "description": "Battery Std"
  },
  {
    "name": "battery_unknown",
    "tagStr": "? assistance battery cell charge help info information mobile power punctuation question mark support symbol unknown",
    "description": "Battery Unknown"
  },
  {
    "name": "beach_access",
    "tagStr": "access beach places summer sunny umbrella",
    "description": "Beach Access"
  },
  {
    "name": "bed",
    "tagStr": "bed bedroom double full furniture home hotel house king night pillows queen rest room size sleep",
    "description": "Bed"
  },
  {
    "name": "bedroom_baby",
    "tagStr": "babies baby bedroom child children home horse house infant kid newborn rocking room toddler young",
    "description": "Bedroom Baby"
  },
  {
    "name": "bedroom_child",
    "tagStr": "bed bedroom child children furniture home hotel house kid night pillows rest room size sleep twin young",
    "description": "Bedroom Child"
  },
  {
    "name": "bedroom_parent",
    "tagStr": "bed bedroom double full furniture home hotel house king night parent pillows queen rest room sizem master sleep",
    "description": "Bedroom Parent"
  },
  {
    "name": "bedtime",
    "tagStr": "",
    "description": "Bedtime"
  },
  {
    "name": "beenhere",
    "tagStr": "approve archive beenhere bookmark check complete done favorite label library mark ok read reading remember ribbon save select tag tick validate verified yes",
    "description": "Beenhere"
  },
  {
    "name": "bento",
    "tagStr": "bento box dinner food lunch meal restaurant takeout",
    "description": "Bento"
  },
  {
    "name": "bike_scooter",
    "tagStr": "automobile bike car cars maps scooter transportation vehicle vespa",
    "description": "Bike Scooter"
  },
  {
    "name": "biotech",
    "tagStr": "biotech chemistry laboratory microscope research science technology",
    "description": "Biotech"
  },
  {
    "name": "blender",
    "tagStr": "appliance blender cooking electric juicer kitchen machine vitamix",
    "description": "Blender"
  },
  {
    "name": "block",
    "tagStr": "avoid block cancel close entry exit no prohibited quit remove stop",
    "description": "Block"
  },
  {
    "name": "bloodtype",
    "tagStr": "blood bloodtype donate droplet emergency hospital medicine negative positive type water",
    "description": "Bloodtype"
  },
  {
    "name": "bluetooth",
    "tagStr": "bluetooth cast connect connection device paring streaming symbol wireless",
    "description": "Bluetooth"
  },
  {
    "name": "bluetooth_audio",
    "tagStr": "audio bluetooth connect connection device music signal sound symbol",
    "description": "Bluetooth Audio"
  },
  {
    "name": "bluetooth_connected",
    "tagStr": "bluetooth cast connect connection device paring streaming symbol wireless",
    "description": "Bluetooth Connected"
  },
  {
    "name": "bluetooth_disabled",
    "tagStr": "bluetooth cast connect connection device disabled enabled off offline on paring slash streaming symbol wireless",
    "description": "Bluetooth Disabled"
  },
  {
    "name": "bluetooth_drive",
    "tagStr": "automobile bluetooth car cars cast connect connection device drive maps paring streaming symbol transportation travel vehicle wireless",
    "description": "Bluetooth Drive"
  },
  {
    "name": "bluetooth_searching",
    "tagStr": "bluetooth connection device paring search searching symbol",
    "description": "Bluetooth Searching"
  },
  {
    "name": "blur_circular",
    "tagStr": "blur circle circular dots edit editing effect enhance filter",
    "description": "Blur Circular"
  },
  {
    "name": "blur_linear",
    "tagStr": "blur dots edit editing effect enhance filter linear",
    "description": "Blur Linear"
  },
  {
    "name": "blur_off",
    "tagStr": "blur disabled dots edit editing effect enabled enhance off on slash",
    "description": "Blur Off"
  },
  {
    "name": "blur_on",
    "tagStr": "blur disabled dots edit editing effect enabled enhance filter off on slash",
    "description": "Blur On"
  },
  {
    "name": "bolt",
    "tagStr": "bolt electric energy fast flash lightning power thunderbolt",
    "description": "Bolt"
  },
  {
    "name": "book",
    "tagStr": "book bookmark favorite label library read reading remember ribbon save tag",
    "description": "Book"
  },
  {
    "name": "book_online",
    "tagStr": "Android OS admission appointment book cell device event hardware iOS mobile online pass phone reservation tablet ticket",
    "description": "Book Online"
  },
  {
    "name": "bookmark",
    "tagStr": "archive bookmark favorite follow label library read reading remember ribbon save tag",
    "description": "Bookmark"
  },
  {
    "name": "bookmark_add",
    "tagStr": "+ add bookmark favorite plus remember ribbon save symbol",
    "description": "Bookmark Add"
  },
  {
    "name": "bookmark_added",
    "tagStr": "added approve bookmark check complete done favorite mark ok remember save select tick validate verified yes",
    "description": "Bookmark Added"
  },
  {
    "name": "bookmark_border",
    "tagStr": "archive bookmark border favorite label library read reading remember ribbon save tag",
    "description": "Bookmark Border"
  },
  {
    "name": "bookmark_remove",
    "tagStr": "bookmark delete favorite minus remember remove ribbon save subtract",
    "description": "Bookmark Remove"
  },
  {
    "name": "bookmarks",
    "tagStr": "bookmark bookmarks favorite label layers library multiple read reading remember ribbon save stack tag",
    "description": "Bookmarks"
  },
  {
    "name": "border_all",
    "tagStr": "all border doc edit editing editor sheet spreadsheet stroke text type writing",
    "description": "Border All"
  },
  {
    "name": "border_bottom",
    "tagStr": "border bottom doc edit editing editor sheet spreadsheet stroke text type writing",
    "description": "Border Bottom"
  },
  {
    "name": "border_clear",
    "tagStr": "border clear doc edit editing editor sheet spreadsheet stroke text type writing",
    "description": "Border Clear"
  },
  {
    "name": "border_color",
    "tagStr": "all border doc edit editing editor pen pencil sheet spreadsheet stroke text type writing",
    "description": "Border Color"
  },
  {
    "name": "border_horizontal",
    "tagStr": "border doc edit editing editor horizontal sheet spreadsheet stroke text type writing",
    "description": "Border Horizontal"
  },
  {
    "name": "border_inner",
    "tagStr": "border doc edit editing editor inner sheet spreadsheet stroke text type writing",
    "description": "Border Inner"
  },
  {
    "name": "border_left",
    "tagStr": "border doc edit editing editor left sheet spreadsheet stroke text type writing",
    "description": "Border Left"
  },
  {
    "name": "border_outer",
    "tagStr": "border doc edit editing editor outer sheet spreadsheet stroke text type writing",
    "description": "Border Outer"
  },
  {
    "name": "border_right",
    "tagStr": "border doc edit editing editor right sheet spreadsheet stroke text type writing",
    "description": "Border Right"
  },
  {
    "name": "border_style",
    "tagStr": "border color doc edit editing editor sheet spreadsheet stroke style text type writing",
    "description": "Border Style"
  },
  {
    "name": "border_top",
    "tagStr": "border doc edit editing editor sheet spreadsheet stroke text top type writing",
    "description": "Border Top"
  },
  {
    "name": "border_vertical",
    "tagStr": "border doc edit editing editor sheet spreadsheet stroke text type vertical writing",
    "description": "Border Vertical"
  },
  {
    "name": "branding_watermark",
    "tagStr": "branding components copyright design emblem format identity interface layout logo screen site stamp ui ux watermark web website window",
    "description": "Branding Watermark"
  },
  {
    "name": "breakfast_dining",
    "tagStr": "bakery bread breakfast butter dining food toast",
    "description": "Breakfast Dining"
  },
  {
    "name": "brightness_1",
    "tagStr": "1 brightness circle control cresent level moon screen",
    "description": "Brightness 1"
  },
  {
    "name": "brightness_2",
    "tagStr": "2 brightness circle control cresent level moon screen",
    "description": "Brightness 2"
  },
  {
    "name": "brightness_3",
    "tagStr": "3 brightness circle control cresent level moon screen",
    "description": "Brightness 3"
  },
  {
    "name": "brightness_4",
    "tagStr": "4 brightness circle control cresent level moon screen sun",
    "description": "Brightness 4"
  },
  {
    "name": "brightness_5",
    "tagStr": "5 brightness circle control cresent level moon screen sun",
    "description": "Brightness 5"
  },
  {
    "name": "brightness_6",
    "tagStr": "6 brightness circle control cresent level moon screen sun",
    "description": "Brightness 6"
  },
  {
    "name": "brightness_7",
    "tagStr": "7 brightness circle control cresent level moon screen sun",
    "description": "Brightness 7"
  },
  {
    "name": "brightness_auto",
    "tagStr": "A auto brightness control display level mobile monitor phone screen sun",
    "description": "Brightness Auto"
  },
  {
    "name": "brightness_high",
    "tagStr": "auto brightness control high mobile monitor phone sun",
    "description": "Brightness High"
  },
  {
    "name": "brightness_low",
    "tagStr": "auto brightness control low mobile monitor phone sun",
    "description": "Brightness Low"
  },
  {
    "name": "brightness_medium",
    "tagStr": "auto brightness control medium mobile monitor phone sun",
    "description": "Brightness Medium"
  },
  {
    "name": "broken_image",
    "tagStr": "broken corrupt error image landscape mountain mountains photo photography picture torn",
    "description": "Broken Image"
  },
  {
    "name": "browser_not_supported",
    "tagStr": "browser disabled enabled internet not off on page screen site slash supported web website www",
    "description": "Browser Not Supported"
  },
  {
    "name": "browser_updated",
    "tagStr": "",
    "description": "Browser Updated"
  },
  {
    "name": "brunch_dining",
    "tagStr": "breakfast brunch champaign dining drink food lunch meal",
    "description": "Brunch Dining"
  },
  {
    "name": "brush",
    "tagStr": "art brush design draw edit editing paint painting tool",
    "description": "Brush"
  },
  {
    "name": "bubble_chart",
    "tagStr": "analytics bar bars bubble chart data diagram graph infographic measure metrics statistics tracking",
    "description": "Bubble Chart"
  },
  {
    "name": "bug_report",
    "tagStr": "animal bug fix insect issue problem report testing virus warning",
    "description": "Bug Report"
  },
  {
    "name": "build",
    "tagStr": "adjust build fix repair tool wrench",
    "description": "Build"
  },
  {
    "name": "build_circle",
    "tagStr": "adjust build circle fix repair tool wrench",
    "description": "Build Circle"
  },
  {
    "name": "bungalow",
    "tagStr": "architecture bungalow cottage estate home house maps place real residence residential stay traveling",
    "description": "Bungalow"
  },
  {
    "name": "burst_mode",
    "tagStr": "burst image landscape mode mountain mountains multiple photo photography picture",
    "description": "Burst Mode"
  },
  {
    "name": "bus_alert",
    "tagStr": "! alert attention automobile bus car cars caution danger error exclamation important maps mark notification symbol transportation vehicle warning",
    "description": "Bus Alert"
  },
  {
    "name": "business",
    "tagStr": "apartment architecture building business company estate home place real residence residential shelter",
    "description": "Business"
  },
  {
    "name": "business_center",
    "tagStr": "bag baggage briefcase business case center places purse suitcase work",
    "description": "Business Center"
  },
  {
    "name": "cabin",
    "tagStr": "architecture cabin camping cottage estate home house log maps place real residence residential stay traveling wood",
    "description": "Cabin"
  },
  {
    "name": "cable",
    "tagStr": "cable connect connection device electronics usb wire",
    "description": "Cable"
  },
  {
    "name": "cached",
    "tagStr": "around arrows cache cached inprogress load loading refresh renew rotate",
    "description": "Cached"
  },
  {
    "name": "cake",
    "tagStr": "baked birthday cake candles celebration dessert food frosting party pastries pastry social sweet",
    "description": "Cake"
  },
  {
    "name": "calculate",
    "tagStr": "+ - = calculate count finance calculator math",
    "description": "Calculate"
  },
  {
    "name": "calendar_today",
    "tagStr": "calendar date day event month schedule today",
    "description": "Calendar Today"
  },
  {
    "name": "calendar_view_day",
    "tagStr": "calendar date day event format grid layout month schedule today view week",
    "description": "Calendar View Day"
  },
  {
    "name": "calendar_view_month",
    "tagStr": "calendar date day event format grid layout month schedule today view",
    "description": "Calendar View Month"
  },
  {
    "name": "calendar_view_week",
    "tagStr": "calendar date day event format grid layout month schedule today view week",
    "description": "Calendar View Week"
  },
  {
    "name": "call",
    "tagStr": "call cell contact device hardware mobile phone telephone",
    "description": "Call"
  },
  {
    "name": "call_end",
    "tagStr": "call cell contact device end hardware mobile phone telephone",
    "description": "Call End"
  },
  {
    "name": "call_made",
    "tagStr": "arrow call device made mobile",
    "description": "Call Made"
  },
  {
    "name": "call_merge",
    "tagStr": "arrow call device merge mobile",
    "description": "Call Merge"
  },
  {
    "name": "call_missed",
    "tagStr": "arrow call device missed mobile",
    "description": "Call Missed"
  },
  {
    "name": "call_missed_outgoing",
    "tagStr": "arrow call device missed mobile outgoing",
    "description": "Call Missed Outgoing"
  },
  {
    "name": "call_received",
    "tagStr": "arrow call device mobile received",
    "description": "Call Received"
  },
  {
    "name": "call_split",
    "tagStr": "arrow call device mobile split",
    "description": "Call Split"
  },
  {
    "name": "call_to_action",
    "tagStr": "action alert bar call components cta design info information interface layout message notification screen site to ui ux web website window",
    "description": "Call To Action"
  },
  {
    "name": "camera",
    "tagStr": "album aperture camera lens photo photography picture record screenshot shutter",
    "description": "Camera"
  },
  {
    "name": "camera_alt",
    "tagStr": "alt camera image photo photography picture",
    "description": "Camera Alt"
  },
  {
    "name": "camera_enhance",
    "tagStr": "camera enhance important lens photo photography picture quality special star",
    "description": "Camera Enhance"
  },
  {
    "name": "camera_front",
    "tagStr": "body camera front human lens mobile person phone photography portrait selfie",
    "description": "Camera Front"
  },
  {
    "name": "camera_indoor",
    "tagStr": "architecture building camera estate film filming home house image indoor inside motion nest picture place real residence residential shelter video videography",
    "description": "Camera Indoor"
  },
  {
    "name": "camera_outdoor",
    "tagStr": "architecture building camera estate film filming home house image motion nest outdoor outside picture place real residence residential shelter video videography",
    "description": "Camera Outdoor"
  },
  {
    "name": "camera_rear",
    "tagStr": "camera front lens mobile phone photo photography picture portrait rear selfie",
    "description": "Camera Rear"
  },
  {
    "name": "camera_roll",
    "tagStr": "camera film image library photo photography roll",
    "description": "Camera Roll"
  },
  {
    "name": "cameraswitch",
    "tagStr": "arrows camera cameraswitch flip rotate swap switch view",
    "description": "Cameraswitch"
  },
  {
    "name": "campaign",
    "tagStr": "alert announcement campaign loud megaphone microphone notification speaker",
    "description": "Campaign"
  },
  {
    "name": "cancel",
    "tagStr": "cancel circle close exit stop x",
    "description": "Cancel"
  },
  {
    "name": "cancel_presentation",
    "tagStr": "cancel close device exit no present presentation quit remove screen slide stop website window x",
    "description": "Cancel Presentation"
  },
  {
    "name": "cancel_schedule_send",
    "tagStr": "cancel email mail no quit remove schedule send share stop x",
    "description": "Cancel Schedule Send"
  },
  {
    "name": "car_rental",
    "tagStr": "automobile car cars key maps rental transportation vehicle",
    "description": "Car Rental"
  },
  {
    "name": "car_repair",
    "tagStr": "automobile car cars maps repair transportation vehicle",
    "description": "Car Repair"
  },
  {
    "name": "card_giftcard",
    "tagStr": "account balance bill card cart cash certificate coin commerce credit currency dollars gift giftcard money online pay payment present shopping",
    "description": "Card Giftcard"
  },
  {
    "name": "card_membership",
    "tagStr": "bill bookmark card cash certificate coin commerce cost credit currency dollars inance loyalty membership money online pay payment shopping subscription",
    "description": "Card Membership"
  },
  {
    "name": "card_travel",
    "tagStr": "bill card cash coin commerce cost credit currency dollars inance membership miles money online pay payment travel trip",
    "description": "Card Travel"
  },
  {
    "name": "carpenter",
    "tagStr": "building carpenter construction cutting handyman repair saw tool",
    "description": "Carpenter"
  },
  {
    "name": "cases",
    "tagStr": "bag baggage briefcase business case cases purse suitcase",
    "description": "Cases"
  },
  {
    "name": "casino",
    "tagStr": "casino dice dots entertainment gamble gambling game games luck places",
    "description": "Casino"
  },
  {
    "name": "cast",
    "tagStr": "Android OS airplay cast chrome connect desktop device display hardware iOS mac monitor screen screencast streaming television tv web window wireless",
    "description": "Cast"
  },
  {
    "name": "cast_connected",
    "tagStr": "Android OS airplay cast chrome connect desktop device display hardware iOS mac monitor screen screencast streaming television tv web window wireless",
    "description": "Cast Connected"
  },
  {
    "name": "cast_for_education",
    "tagStr": "Android OS airplay cast chrome connect desktop device display education for hardware iOS learning lessons teaching mac monitor screen screencast streaming television tv web window wireless",
    "description": "Cast For Education"
  },
  {
    "name": "castle",
    "tagStr": "",
    "description": "Castle"
  },
  {
    "name": "catching_pokemon",
    "tagStr": "catching go pokemon pokestop travel",
    "description": "Catching Pokemon"
  },
  {
    "name": "category",
    "tagStr": "categories category circle collection items product sort square triangle",
    "description": "Category"
  },
  {
    "name": "celebration",
    "tagStr": "activity birthday celebration event fun party",
    "description": "Celebration"
  },
  {
    "name": "cell_wifi",
    "tagStr": "cell connection data internet mobile network phone service signal wifi wireless",
    "description": "Cell Wifi"
  },
  {
    "name": "center_focus_strong",
    "tagStr": "camera center focus image lens photo photography strong zoom",
    "description": "Center Focus Strong"
  },
  {
    "name": "center_focus_weak",
    "tagStr": "camera center focus image lens photo photography weak zoom",
    "description": "Center Focus Weak"
  },
  {
    "name": "chair",
    "tagStr": "chair comfort couch decoration furniture home house living lounging loveseat room seat seating sofa",
    "description": "Chair"
  },
  {
    "name": "chair_alt",
    "tagStr": "cahir furniture home house kitchen lounging seating table",
    "description": "Chair Alt"
  },
  {
    "name": "chalet",
    "tagStr": "architecture chalet cottage estate home house maps place real residence residential stay traveling",
    "description": "Chalet"
  },
  {
    "name": "change_circle",
    "tagStr": "around arrows change circle direction navigation rotate",
    "description": "Change Circle"
  },
  {
    "name": "change_history",
    "tagStr": "change history shape triangle",
    "description": "Change History"
  },
  {
    "name": "charging_station",
    "tagStr": "Android OS battery bolt cell charging device electric hardware iOS lightning mobile phone station tablet thunderbolt",
    "description": "Charging Station"
  },
  {
    "name": "chat",
    "tagStr": "bubble chat comment communicate feedback message speech",
    "description": "Chat"
  },
  {
    "name": "chat_bubble",
    "tagStr": "bubble chat comment communicate feedback message speech",
    "description": "Chat Bubble"
  },
  {
    "name": "chat_bubble_outline",
    "tagStr": "bubble chat comment communicate feedback message outline speech",
    "description": "Chat Bubble Outline"
  },
  {
    "name": "check",
    "tagStr": "check complete confirm correct done enter mark ok okay purchased select success tick yes",
    "description": "Check"
  },
  {
    "name": "check_box",
    "tagStr": "approved box button check component control form mark ok select selected selection tick toggle ui yes",
    "description": "Check Box"
  },
  {
    "name": "check_box_outline_blank",
    "tagStr": "blank box button check component control deselected empty form outline select selection square tick toggle ui",
    "description": "Check Box Outline Blank"
  },
  {
    "name": "check_circle",
    "tagStr": "approve check circle complete done download mark ok select success tick upload validate verified yes",
    "description": "Check Circle"
  },
  {
    "name": "check_circle_outline",
    "tagStr": "approve check circle complete done finished mark ok outline select tick validate verified yes",
    "description": "Check Circle Outline"
  },
  {
    "name": "checklist",
    "tagStr": "",
    "description": "Checklist"
  },
  {
    "name": "checklist_rtl",
    "tagStr": "",
    "description": "Checklist Rtl"
  },
  {
    "name": "checkroom",
    "tagStr": "checkroom closet clothes coat check hanger",
    "description": "Checkroom"
  },
  {
    "name": "chevron_left",
    "tagStr": "arrow arrows back chevron direction left triangle",
    "description": "Chevron Left"
  },
  {
    "name": "chevron_right",
    "tagStr": "arrow arrows chevron direction forward right triangle",
    "description": "Chevron Right"
  },
  {
    "name": "child_care",
    "tagStr": "babies baby care child children face infant kids newborn toddler young",
    "description": "Child Care"
  },
  {
    "name": "child_friendly",
    "tagStr": "baby care carriage child children friendly infant kid newborn stroller toddler young",
    "description": "Child Friendly"
  },
  {
    "name": "chrome_reader_mode",
    "tagStr": "chrome mode read reader text",
    "description": "Chrome Reader Mode"
  },
  {
    "name": "church",
    "tagStr": "",
    "description": "Church"
  },
  {
    "name": "circle",
    "tagStr": "button circle full geometry moon radio",
    "description": "Circle"
  },
  {
    "name": "circle_notifications",
    "tagStr": "active alarm alert bell chime circle notifications notify reminder ring sound",
    "description": "Circle Notifications"
  },
  {
    "name": "class",
    "tagStr": "archive book bookmark class favorite label library read reading remember ribbon save tag",
    "description": "Class"
  },
  {
    "name": "clean_hands",
    "tagStr": "bacteria clean disinfect germs gesture hand hands sanitize sanitizer",
    "description": "Clean Hands"
  },
  {
    "name": "cleaning_services",
    "tagStr": "clean cleaning dust services sweep",
    "description": "Cleaning Services"
  },
  {
    "name": "clear",
    "tagStr": "back cancel clear correct delete erase exit x",
    "description": "Clear"
  },
  {
    "name": "clear_all",
    "tagStr": "all clear doc document format lines list",
    "description": "Clear All"
  },
  {
    "name": "close",
    "tagStr": "cancel close exit stop x",
    "description": "Close"
  },
  {
    "name": "close_fullscreen",
    "tagStr": "action arrow arrows close collapse direction full fullscreen minimize screen",
    "description": "Close Fullscreen"
  },
  {
    "name": "closed_caption",
    "tagStr": "accessible alphabet caption cc character closed decoder font language letter media movies subtitle subtitles symbol text tv type",
    "description": "Closed Caption"
  },
  {
    "name": "closed_caption_disabled",
    "tagStr": "accessible alphabet caption cc character closed decoder disabled enabled font language letter media movies off on slash subtitle subtitles symbol text tv type",
    "description": "Closed Caption Disabled"
  },
  {
    "name": "closed_caption_off",
    "tagStr": "accessible alphabet caption cc character closed decoder font language letter media movies off outline subtitle subtitles symbol text tv type",
    "description": "Closed Caption Off"
  },
  {
    "name": "cloud",
    "tagStr": "cloud connection internet network sky upload",
    "description": "Cloud"
  },
  {
    "name": "cloud_circle",
    "tagStr": "app application backup circle cloud connection drive files folders internet network sky storage upload",
    "description": "Cloud Circle"
  },
  {
    "name": "cloud_done",
    "tagStr": "app application approve backup check cloud complete connection done drive files folders internet mark network ok select sky storage tick upload validate verified yes",
    "description": "Cloud Done"
  },
  {
    "name": "cloud_download",
    "tagStr": "app application arrow backup cloud connection down download drive files folders internet network sky storage upload",
    "description": "Cloud Download"
  },
  {
    "name": "cloud_off",
    "tagStr": "app application backup cloud connection disabled drive enabled files folders internet network off offline on sky slash storage upload",
    "description": "Cloud Off"
  },
  {
    "name": "cloud_queue",
    "tagStr": "cloud connection internet network queue sky upload",
    "description": "Cloud Queue"
  },
  {
    "name": "cloud_upload",
    "tagStr": "app application arrow backup cloud connection download drive files folders internet network sky storage up upload",
    "description": "Cloud Upload"
  },
  {
    "name": "co2",
    "tagStr": "",
    "description": "Co2"
  },
  {
    "name": "code",
    "tagStr": "brackets code css develop developer engineer engineering html platform",
    "description": "Code"
  },
  {
    "name": "code_off",
    "tagStr": "brackets code css develop developer disabled enabled engineer engineering html off on platform slash",
    "description": "Code Off"
  },
  {
    "name": "coffee",
    "tagStr": "beverage coffee cup drink mug plate set tea",
    "description": "Coffee"
  },
  {
    "name": "coffee_maker",
    "tagStr": "appliances beverage coffee cup drink machine maker mug",
    "description": "Coffee Maker"
  },
  {
    "name": "collections",
    "tagStr": "album collections gallery image landscape library mountain mountains photo photography picture stack",
    "description": "Collections"
  },
  {
    "name": "collections_bookmark",
    "tagStr": "album archive bookmark collections favorite gallery label library read reading remember ribbon save stack tag",
    "description": "Collections Bookmark"
  },
  {
    "name": "color_lens",
    "tagStr": "art color lens paint pallet",
    "description": "Color Lens"
  },
  {
    "name": "colorize",
    "tagStr": "color colorize dropper extract eye picker tool",
    "description": "Colorize"
  },
  {
    "name": "comment",
    "tagStr": "bubble chat comment communicate feedback message outline speech",
    "description": "Comment"
  },
  {
    "name": "comment_bank",
    "tagStr": "archive bank bookmark bubble cchat comment communicate favorite label library message remember ribbon save speech tag",
    "description": "Comment Bank"
  },
  {
    "name": "comments_disabled",
    "tagStr": "",
    "description": "Comments Disabled"
  },
  {
    "name": "commute",
    "tagStr": "automobile car commute direction maps public train transportation trip vehicle",
    "description": "Commute"
  },
  {
    "name": "compare",
    "tagStr": "adjust adjustment compare edit editing edits enhance fix image images photo photography photos scan settings",
    "description": "Compare"
  },
  {
    "name": "compare_arrows",
    "tagStr": "arrow arrows collide compare direction left pressure push right together",
    "description": "Compare Arrows"
  },
  {
    "name": "compass_calibration",
    "tagStr": "calibration compass connection internet location maps network refresh service signal wifi wireless",
    "description": "Compass Calibration"
  },
  {
    "name": "compost",
    "tagStr": "",
    "description": "Compost"
  },
  {
    "name": "compress",
    "tagStr": "arrow arrows collide compress pressure push together",
    "description": "Compress"
  },
  {
    "name": "computer",
    "tagStr": "Android OS chrome computer desktop device hardware iOS mac monitor web window",
    "description": "Computer"
  },
  {
    "name": "confirmation_number",
    "tagStr": "admission confirmation entertainment event number ticket",
    "description": "Confirmation Number"
  },
  {
    "name": "connect_without_contact",
    "tagStr": "communicating connect contact distance people signal social socialize without",
    "description": "Connect Without Contact"
  },
  {
    "name": "connected_tv",
    "tagStr": "Android OS airplay chrome connect connected desktop device display hardware iOS mac monitor screen screencast streaming television tv web window wireless",
    "description": "Connected Tv"
  },
  {
    "name": "connecting_airports",
    "tagStr": "",
    "description": "Connecting Airports"
  },
  {
    "name": "construction",
    "tagStr": "build carpenter construction equipment fix hammer improvement industrial industry repair tools wrench",
    "description": "Construction"
  },
  {
    "name": "contact_mail",
    "tagStr": "account address avatar communicate contact email face human info information mail message people person profile user",
    "description": "Contact Mail"
  },
  {
    "name": "contact_page",
    "tagStr": "account avatar contact data doc document drive face file folder folders human page people person profile sheet slide storage user writing",
    "description": "Contact Page"
  },
  {
    "name": "contact_phone",
    "tagStr": "account avatar call communicate contact face human info information message mobile people person phone profile user",
    "description": "Contact Phone"
  },
  {
    "name": "contact_support",
    "tagStr": "? bubble chat comment communicate contact help info information mark message punctuation question speech support symbol vquestion mark",
    "description": "Contact Support"
  },
  {
    "name": "contactless",
    "tagStr": "bluetooth cash connect connection connectivity contact contactless credit device finance pay payment signal transaction wifi wireless",
    "description": "Contactless"
  },
  {
    "name": "contacts",
    "tagStr": "account avatar call cell contacts face human info information mobile people person phone profile user",
    "description": "Contacts"
  },
  {
    "name": "content_copy",
    "tagStr": "content copy cut doc document duplicate file multiple past",
    "description": "Content Copy"
  },
  {
    "name": "content_cut",
    "tagStr": "content copy cut doc document file past scissors trim",
    "description": "Content Cut"
  },
  {
    "name": "content_paste",
    "tagStr": "clipboard content copy cut doc document file multiple past",
    "description": "Content Paste"
  },
  {
    "name": "content_paste_go",
    "tagStr": "",
    "description": "Content Paste Go"
  },
  {
    "name": "content_paste_off",
    "tagStr": "clipboard content disabled doc document enabled file off on paste slash",
    "description": "Content Paste Off"
  },
  {
    "name": "content_paste_search",
    "tagStr": "",
    "description": "Content Paste Search"
  },
  {
    "name": "control_camera",
    "tagStr": "adjust arrow arrows camera center control direction left move right",
    "description": "Control Camera"
  },
  {
    "name": "control_point",
    "tagStr": "+ add circle control plus point",
    "description": "Control Point"
  },
  {
    "name": "control_point_duplicate",
    "tagStr": "+ add circle control duplicate multiple new plus point symbol",
    "description": "Control Point Duplicate"
  },
  {
    "name": "cookie",
    "tagStr": "",
    "description": "Cookie"
  },
  {
    "name": "copy_all",
    "tagStr": "all content copy cut doc document file multiple page paper past",
    "description": "Copy All"
  },
  {
    "name": "copyright",
    "tagStr": "alphabet c character circle copyright emblem font legal letter owner symbol text",
    "description": "Copyright"
  },
  {
    "name": "coronavirus",
    "tagStr": "19 bacteria coronavirus covid disease germs illness sick social",
    "description": "Coronavirus"
  },
  {
    "name": "corporate_fare",
    "tagStr": "architecture building business corporate estate fare organization place real residence residential shelter",
    "description": "Corporate Fare"
  },
  {
    "name": "cottage",
    "tagStr": "architecture beach cottage estate home house lake lodge maps place real residence residential stay traveling",
    "description": "Cottage"
  },
  {
    "name": "countertops",
    "tagStr": "counter countertops home house kitchen sink table tops",
    "description": "Countertops"
  },
  {
    "name": "create",
    "tagStr": "compose create edit editing input new pen pencil write writing",
    "description": "Create"
  },
  {
    "name": "create_new_folder",
    "tagStr": "+ add create data doc document drive file folder new plus sheet slide storage symbol",
    "description": "Create New Folder"
  },
  {
    "name": "credit_card",
    "tagStr": "bill card cash coin commerce cost credit currency dollars finance money online pay payment price shopping symbol",
    "description": "Credit Card"
  },
  {
    "name": "credit_card_off",
    "tagStr": "card charge commerce cost credit disabled enabled finance money off online pay payment slash",
    "description": "Credit Card Off"
  },
  {
    "name": "credit_score",
    "tagStr": "approve bill card cash check coin commerce complete cost credit currency dollars done finance loan mark money ok online pay payment score select symbol tick validate verified yes",
    "description": "Credit Score"
  },
  {
    "name": "crib",
    "tagStr": "babies baby bassinet bed child children cradle crib infant kid newborn sleeping toddler",
    "description": "Crib"
  },
  {
    "name": "crop",
    "tagStr": "adjust adjustments area crop edit editing frame image images photo photos rectangle settings size square",
    "description": "Crop"
  },
  {
    "name": "crop_16_9",
    "tagStr": "16 9 adjust adjustments area by crop edit editing frame image images photo photos rectangle settings size square",
    "description": "Crop 16 9"
  },
  {
    "name": "crop_3_2",
    "tagStr": "2 3 adjust adjustments area by crop edit editing frame image images photo photos rectangle settings size square",
    "description": "Crop 3 2"
  },
  {
    "name": "crop_5_4",
    "tagStr": "4 5 adjust adjustments area by crop edit editing settings frame image images photo photos rectangle size square",
    "description": "Crop 5 4"
  },
  {
    "name": "crop_7_5",
    "tagStr": "5 7 adjust adjustments area by crop editing frame image images photo photos rectangle settings size square",
    "description": "Crop 7 5"
  },
  {
    "name": "crop_din",
    "tagStr": "adjust adjustments area crop din edit editing frame image images photo photos rectangle settings size square",
    "description": "Crop Din"
  },
  {
    "name": "crop_free",
    "tagStr": "adjust adjustments crop edit editing focus frame free image photo photos settings size zoom",
    "description": "Crop Free"
  },
  {
    "name": "crop_landscape",
    "tagStr": "adjust adjustments area crop edit editing frame image images landscape photo photos settings size",
    "description": "Crop Landscape"
  },
  {
    "name": "crop_original",
    "tagStr": "adjust adjustments area crop edit editing frame image images original photo photos picture settings size",
    "description": "Crop Original"
  },
  {
    "name": "crop_portrait",
    "tagStr": "adjust adjustments area crop edit editing frame image images photo photos portrait rectangle settings size square",
    "description": "Crop Portrait"
  },
  {
    "name": "crop_rotate",
    "tagStr": "adjust adjustments area arrow arrows crop edit editing frame image images photo photos rotate settings size turn",
    "description": "Crop Rotate"
  },
  {
    "name": "crop_square",
    "tagStr": "adjust adjustments area crop edit editing frame image images photo photos rectangle settings size square",
    "description": "Crop Square"
  },
  {
    "name": "cruelty_free",
    "tagStr": "",
    "description": "Cruelty Free"
  },
  {
    "name": "dangerous",
    "tagStr": "broken danger dangerous fix no sign stop update warning wrong x",
    "description": "Dangerous"
  },
  {
    "name": "dark_mode",
    "tagStr": "app application dark device interface mode moon night silent theme ui ux website",
    "description": "Dark Mode"
  },
  {
    "name": "dashboard",
    "tagStr": "cards dashboard format layout rectangle shapes square web website",
    "description": "Dashboard"
  },
  {
    "name": "dashboard_customize",
    "tagStr": "cards customize dashboard format layout rectangle shapes square web website",
    "description": "Dashboard Customize"
  },
  {
    "name": "data_array",
    "tagStr": "",
    "description": "Data Array"
  },
  {
    "name": "data_exploration",
    "tagStr": "",
    "description": "Data Exploration"
  },
  {
    "name": "data_object",
    "tagStr": "",
    "description": "Data Object"
  },
  {
    "name": "data_saver_off",
    "tagStr": "analytics bar bars chart data diagram donut graph infographic measure metrics off on ring saver statistics tracking",
    "description": "Data Saver Off"
  },
  {
    "name": "data_saver_on",
    "tagStr": "+ add analytics chart data diagram graph infographic measure metrics new on plus ring saver statistics symbol tracking",
    "description": "Data Saver On"
  },
  {
    "name": "data_usage",
    "tagStr": "analytics chart data diagram graph infographic measure metrics statistics tracking usage",
    "description": "Data Usage"
  },
  {
    "name": "date_range",
    "tagStr": "calendar date day event month range remember reminder schedule time today week",
    "description": "Date Range"
  },
  {
    "name": "deck",
    "tagStr": "chairs deck home house outdoors outside patio social terrace umbrella yard",
    "description": "Deck"
  },
  {
    "name": "dehaze",
    "tagStr": "adjust dehaze edit editing enhance haze image lines photo photography remove",
    "description": "Dehaze"
  },
  {
    "name": "delete",
    "tagStr": "bin can delete garbage remove trash",
    "description": "Delete"
  },
  {
    "name": "delete_forever",
    "tagStr": "bin can cancel delete exit forever garbage remove trash x",
    "description": "Delete Forever"
  },
  {
    "name": "delete_outline",
    "tagStr": "bin can delete garbage outline remove trash",
    "description": "Delete Outline"
  },
  {
    "name": "delete_sweep",
    "tagStr": "bin can delete garbage remove sweep trash",
    "description": "Delete Sweep"
  },
  {
    "name": "delivery_dining",
    "tagStr": "delivery dining food meal restaurant scooter takeout transportation vehicle vespa",
    "description": "Delivery Dining"
  },
  {
    "name": "departure_board",
    "tagStr": "automobile board bus car cars clock departure maps public schedule time transportation travel vehicle",
    "description": "Departure Board"
  },
  {
    "name": "description",
    "tagStr": "article data description doc document drive file folder folders notes page paper sheet slide text writing",
    "description": "Description"
  },
  {
    "name": "design_services",
    "tagStr": "",
    "description": "Design Services"
  },
  {
    "name": "desktop_access_disabled",
    "tagStr": "Android OS access chrome desktop device disabled display enabled hardware iOS mac monitor off offline on screen slash web window",
    "description": "Desktop Access Disabled"
  },
  {
    "name": "desktop_mac",
    "tagStr": "Android OS chrome desktop device display hardware iOS mac monitor screen web window",
    "description": "Desktop Mac"
  },
  {
    "name": "desktop_windows",
    "tagStr": "Android OS chrome desktop device display hardware iOS mac monitor screen television tv web window windows",
    "description": "Desktop Windows"
  },
  {
    "name": "details",
    "tagStr": "details edit editing enhance image photo photography sharpen triangle",
    "description": "Details"
  },
  {
    "name": "developer_board",
    "tagStr": "board chip computer developer development hardware microchip processor",
    "description": "Developer Board"
  },
  {
    "name": "developer_board_off",
    "tagStr": "board chip computer developer development disabled enabled hardware microchip off on processor slash",
    "description": "Developer Board Off"
  },
  {
    "name": "developer_mode",
    "tagStr": "Android OS bracket cell code developer development device engineer hardware iOS mobile mode phone tablet",
    "description": "Developer Mode"
  },
  {
    "name": "device_hub",
    "tagStr": "Android OS circle computer desktop device hardware hub iOS laptop mobile monitor phone square tablet triangle watch wearable web",
    "description": "Device Hub"
  },
  {
    "name": "device_thermostat",
    "tagStr": "celsius device fahrenheit meter temp temperature thermometer thermostat",
    "description": "Device Thermostat"
  },
  {
    "name": "device_unknown",
    "tagStr": "? Android OS assistance cell device hardware help iOS info information mobile phone punctuation question mark support symbol tablet unknown",
    "description": "Device Unknown"
  },
  {
    "name": "devices",
    "tagStr": "Android OS computer desktop device hardware iOS laptop mobile monitor phone tablet watch wearable web",
    "description": "Devices"
  },
  {
    "name": "devices_other",
    "tagStr": "Android OS ar cell chrome desktop device gadget hardware iOS ipad mac mobile monitor other phone tablet vr watch wearables window",
    "description": "Devices Other"
  },
  {
    "name": "dialer_sip",
    "tagStr": "alphabet call cell character contact device dialer font hardware initiation internet letter mobile over phone protocol routing session sip symbol telephone text type voice",
    "description": "Dialer Sip"
  },
  {
    "name": "dialpad",
    "tagStr": "buttons call contact device dial dialpad dots mobile numbers pad phone",
    "description": "Dialpad"
  },
  {
    "name": "dining",
    "tagStr": "cafe cafeteria cutlery diner dining eat eating fork room spoon",
    "description": "Dining"
  },
  {
    "name": "dinner_dining",
    "tagStr": "breakfast dining dinner food fork lunch meal restaurant spaghetti utensils",
    "description": "Dinner Dining"
  },
  {
    "name": "directions",
    "tagStr": "arrow directions maps right route sign traffic",
    "description": "Directions"
  },
  {
    "name": "directions_bike",
    "tagStr": "bicycle bike direction directions human maps person public route transportation",
    "description": "Directions Bike"
  },
  {
    "name": "directions_boat",
    "tagStr": "automobile boat car cars direction directions ferry maps public transportation vehicle",
    "description": "Directions Boat"
  },
  {
    "name": "directions_boat_filled",
    "tagStr": "automobile boat car cars direction directions ferry filled maps public transportation vehicle",
    "description": "Directions Boat Filled"
  },
  {
    "name": "directions_bus",
    "tagStr": "automobile bus car cars directions maps public transportation vehicle",
    "description": "Directions Bus"
  },
  {
    "name": "directions_bus_filled",
    "tagStr": "automobile bus car cars direction directions filled maps public transportation vehicle",
    "description": "Directions Bus Filled"
  },
  {
    "name": "directions_car",
    "tagStr": "automobile car cars direction directions maps public transportation vehicle",
    "description": "Directions Car"
  },
  {
    "name": "directions_car_filled",
    "tagStr": "automobile car cars direction directions filled maps public transportation vehicle",
    "description": "Directions Car Filled"
  },
  {
    "name": "directions_off",
    "tagStr": "arrow directions disabled enabled maps off on right route sign slash traffic",
    "description": "Directions Off"
  },
  {
    "name": "directions_railway",
    "tagStr": "automobile car cars direction directions maps public railway train transportation vehicle",
    "description": "Directions Railway"
  },
  {
    "name": "directions_railway_filled",
    "tagStr": "automobile car cars direction directions filled maps public railway train transportation vehicle",
    "description": "Directions Railway Filled"
  },
  {
    "name": "directions_run",
    "tagStr": "body directions human jogging maps people person route run running walk",
    "description": "Directions Run"
  },
  {
    "name": "directions_subway",
    "tagStr": "automobile car cars direction directions maps public rail subway train transportation vehicle",
    "description": "Directions Subway"
  },
  {
    "name": "directions_subway_filled",
    "tagStr": "automobile car cars direction directions filled maps public rail subway train transportation vehicle",
    "description": "Directions Subway Filled"
  },
  {
    "name": "directions_transit",
    "tagStr": "automobile car cars direction directions maps public rail subway train transit transportation vehicle",
    "description": "Directions Transit"
  },
  {
    "name": "directions_transit_filled",
    "tagStr": "automobile car cars direction directions filled maps public rail subway train transit transportation vehicle",
    "description": "Directions Transit Filled"
  },
  {
    "name": "directions_walk",
    "tagStr": "body direction directions human jogging maps people person route run walk",
    "description": "Directions Walk"
  },
  {
    "name": "dirty_lens",
    "tagStr": "camera dirty lens photo photography picture splat",
    "description": "Dirty Lens"
  },
  {
    "name": "disabled_by_default",
    "tagStr": "box by cancel close default disabled exit no quit remove square stop x",
    "description": "Disabled By Default"
  },
  {
    "name": "disabled_visible",
    "tagStr": "",
    "description": "Disabled Visible"
  },
  {
    "name": "disc_full",
    "tagStr": "! alert attention caution cd danger disc error exclamation full important mark music notification storage symbol warning",
    "description": "Disc Full"
  },
  {
    "name": "discord",
    "tagStr": "",
    "description": "Discord"
  },
  {
    "name": "dns",
    "tagStr": "address bars dns domain information ip list lookup name server system",
    "description": "Dns"
  },
  {
    "name": "do_disturb",
    "tagStr": "cancel close denied deny disturb do remove silence stop",
    "description": "Do Disturb"
  },
  {
    "name": "do_disturb_alt",
    "tagStr": "cancel close denied deny disturb do remove silence stop",
    "description": "Do Disturb Alt"
  },
  {
    "name": "do_disturb_off",
    "tagStr": "cancel close denied deny disabled disturb do enabled off on remove silence slash stop",
    "description": "Do Disturb Off"
  },
  {
    "name": "do_disturb_on",
    "tagStr": "cancel close denied deny disabled disturb do enabled off on remove silence slash stop",
    "description": "Do Disturb On"
  },
  {
    "name": "do_not_disturb",
    "tagStr": "cancel close denied deny disturb do remove silence stop",
    "description": "Do Not Disturb"
  },
  {
    "name": "do_not_disturb_alt",
    "tagStr": "cancel close denied deny disturb do remove silence stop",
    "description": "Do Not Disturb Alt"
  },
  {
    "name": "do_not_disturb_off",
    "tagStr": "cancel close denied deny disabled disturb do enabled off on remove silence slash stop",
    "description": "Do Not Disturb Off"
  },
  {
    "name": "do_not_disturb_on",
    "tagStr": "cancel close denied deny disabled disturb do enabled off on remove silence slash stop",
    "description": "Do Not Disturb On"
  },
  {
    "name": "do_not_disturb_on_total_silence",
    "tagStr": "busy disturb do mute no not on total quiet silence",
    "description": "Do Not Disturb On Total Silence"
  },
  {
    "name": "do_not_step",
    "tagStr": "boot disabled do enabled feet foot not off on shoe slash sneaker step",
    "description": "Do Not Step"
  },
  {
    "name": "do_not_touch",
    "tagStr": "disabled do enabled fingers gesture hand not off on slash touch",
    "description": "Do Not Touch"
  },
  {
    "name": "dock",
    "tagStr": "Android OS cell charging connector device dock hardware iOS mobile phone power station tablet",
    "description": "Dock"
  },
  {
    "name": "document_scanner",
    "tagStr": "",
    "description": "Document Scanner"
  },
  {
    "name": "domain",
    "tagStr": "apartment architecture building business domain estate home place real residence residential shelter web www",
    "description": "Domain"
  },
  {
    "name": "domain_disabled",
    "tagStr": "apartment architecture building business company disabled domain enabled estate home internet maps off office offline on place real residence residential slash web website",
    "description": "Domain Disabled"
  },
  {
    "name": "domain_verification",
    "tagStr": "app application desktop approve check complete design domain done interface internet layout mark ok screen select site tick ui ux validate verification verified web website window www yes",
    "description": "Domain Verification"
  },
  {
    "name": "done",
    "tagStr": "approve check complete done mark ok select tick validate verified yes",
    "description": "Done"
  },
  {
    "name": "done_all",
    "tagStr": "all approve check complete done layers mark multiple ok select stack tick validate verified yes",
    "description": "Done All"
  },
  {
    "name": "done_outline",
    "tagStr": "all approve check complete done mark ok outline select tick validate verified yes",
    "description": "Done Outline"
  },
  {
    "name": "donut_large",
    "tagStr": "analytics chart data diagram donut graph infographic inprogress large measure metrics pie statistics tracking",
    "description": "Donut Large"
  },
  {
    "name": "donut_small",
    "tagStr": "analytics chart data diagram donut graph infographic inprogress measure metrics pie small statistics tracking",
    "description": "Donut Small"
  },
  {
    "name": "door_back",
    "tagStr": "back closed door doorway entrance exit home house way",
    "description": "Door Back"
  },
  {
    "name": "door_front",
    "tagStr": "closed door doorway entrance exit front home house way",
    "description": "Door Front"
  },
  {
    "name": "door_sliding",
    "tagStr": "auto automatic door doorway double entrance exit glass home house sliding two",
    "description": "Door Sliding"
  },
  {
    "name": "doorbell",
    "tagStr": "alarm bell door doorbell home house ringing",
    "description": "Doorbell"
  },
  {
    "name": "double_arrow",
    "tagStr": "arrow arrows direction double multiple navigation right",
    "description": "Double Arrow"
  },
  {
    "name": "downhill_skiing",
    "tagStr": "athlete athletic body downhill entertainment exercise hobby human people person ski social skiing snow sports travel winter",
    "description": "Downhill Skiing"
  },
  {
    "name": "download",
    "tagStr": "arrow down download downloads drive install upload",
    "description": "Download"
  },
  {
    "name": "download_done",
    "tagStr": "arrow arrows check done down download downloads drive install installed ok tick upload",
    "description": "Download Done"
  },
  {
    "name": "download_for_offline",
    "tagStr": "arrow circle down download for offline install upload",
    "description": "Download For Offline"
  },
  {
    "name": "downloading",
    "tagStr": "arrow circle down download downloading downloads install pending progress upload",
    "description": "Downloading"
  },
  {
    "name": "drafts",
    "tagStr": "document draft drafts email file letter mail message read",
    "description": "Drafts"
  },
  {
    "name": "drag_handle",
    "tagStr": "app application ui components design drag handle interface layout menu move screen site ui ux web website window",
    "description": "Drag Handle"
  },
  {
    "name": "drag_indicator",
    "tagStr": "app application circles components design dots drag drop indicator interface layout mobile monitor move phone screen shape shift site tablet ui ux web website window",
    "description": "Drag Indicator"
  },
  {
    "name": "draw",
    "tagStr": "",
    "description": "Draw"
  },
  {
    "name": "drive_eta",
    "tagStr": "automobile car cars destination direction drive estimate eta maps public transportation travel trip vehicle",
    "description": "Drive Eta"
  },
  {
    "name": "drive_file_move",
    "tagStr": "arrow arrows data direction doc document drive file folder folders move right sheet side slide storage",
    "description": "Drive File Move"
  },
  {
    "name": "drive_file_move_rtl",
    "tagStr": "drive file move",
    "description": "Drive File Move Rtl"
  },
  {
    "name": "drive_file_rename_outline",
    "tagStr": "compose create draft drive edit editing file input pen pencil rename write writing",
    "description": "Drive File Rename Outline"
  },
  {
    "name": "drive_folder_upload",
    "tagStr": "arrow data doc document drive file folder sheet slide storage up upload",
    "description": "Drive Folder Upload"
  },
  {
    "name": "dry",
    "tagStr": "air bathroom dry dryer fingers gesture hand wc",
    "description": "Dry"
  },
  {
    "name": "dry_cleaning",
    "tagStr": "cleaning dry hanger hotel laundry places service towel",
    "description": "Dry Cleaning"
  },
  {
    "name": "duo",
    "tagStr": "call chat conference device duo video",
    "description": "Duo"
  },
  {
    "name": "dvr",
    "tagStr": "Android OS audio chrome computer desktop device display dvr electronic hardware iOS list mac monitor record recorder screen tv video web window",
    "description": "Dvr"
  },
  {
    "name": "dynamic_feed",
    "tagStr": "dynamic feed layer multiple post",
    "description": "Dynamic Feed"
  },
  {
    "name": "dynamic_form",
    "tagStr": "bolt code dynamic electric fast form lightning lists questionnaire thunderbolt",
    "description": "Dynamic Form"
  },
  {
    "name": "e_mobiledata",
    "tagStr": "alphabet data e font letter mobile mobiledata text type",
    "description": "E Mobiledata"
  },
  {
    "name": "earbuds",
    "tagStr": "accessory audio earbuds earphone headphone listen music sound",
    "description": "Earbuds"
  },
  {
    "name": "earbuds_battery",
    "tagStr": "accessory audio battery charging earbuds earphone headphone listen music sound",
    "description": "Earbuds Battery"
  },
  {
    "name": "east",
    "tagStr": "arrow directional east maps navigation right",
    "description": "East"
  },
  {
    "name": "edgesensor_high",
    "tagStr": "Android OS cell device edge hardware high iOS mobile move phone sensitivity sensor tablet vibrate",
    "description": "Edgesensor High"
  },
  {
    "name": "edgesensor_low",
    "tagStr": "Android cell device edge hardware iOS low mobile move phone sensitivity sensor tablet vibrate",
    "description": "Edgesensor Low"
  },
  {
    "name": "edit",
    "tagStr": "compose create edit editing input new pen pencil write writing",
    "description": "Edit"
  },
  {
    "name": "edit_attributes",
    "tagStr": "approve attribution check complete done edit mark ok select tick validate verified yes",
    "description": "Edit Attributes"
  },
  {
    "name": "edit_calendar",
    "tagStr": "",
    "description": "Edit Calendar"
  },
  {
    "name": "edit_location",
    "tagStr": "destination direction edit location maps pen pencil pin place stop",
    "description": "Edit Location"
  },
  {
    "name": "edit_location_alt",
    "tagStr": "alt edit location pen pencil pin",
    "description": "Edit Location Alt"
  },
  {
    "name": "edit_note",
    "tagStr": "",
    "description": "Edit Note"
  },
  {
    "name": "edit_notifications",
    "tagStr": "active alarm alert bell chime compose create draft edit editing input new notifications notify pen pencil reminder ring sound write writing",
    "description": "Edit Notifications"
  },
  {
    "name": "edit_off",
    "tagStr": "compose create disabled draft edit editing enabled input new off offline on pen pencil slash write writing",
    "description": "Edit Off"
  },
  {
    "name": "edit_road",
    "tagStr": "destination direction edit highway maps pen pencil road street traffic",
    "description": "Edit Road"
  },
  {
    "name": "egg",
    "tagStr": "",
    "description": "Egg"
  },
  {
    "name": "egg_alt",
    "tagStr": "",
    "description": "Egg Alt"
  },
  {
    "name": "eject",
    "tagStr": "disc drive dvd eject remove triangle usb",
    "description": "Eject"
  },
  {
    "name": "elderly",
    "tagStr": "body cane elderly human old people person senior",
    "description": "Elderly"
  },
  {
    "name": "electric_bike",
    "tagStr": "automobile bike car cars maps scooter transportation vehicle vespa",
    "description": "Electric Bike"
  },
  {
    "name": "electric_car",
    "tagStr": "automobile car cars electric electricity maps transportation vehicle",
    "description": "Electric Car"
  },
  {
    "name": "electric_moped",
    "tagStr": "automobile bike car cars maps scooter transportation vehicle vespa",
    "description": "Electric Moped"
  },
  {
    "name": "electric_rickshaw",
    "tagStr": "automobile car cars india maps transportation vehicle",
    "description": "Electric Rickshaw"
  },
  {
    "name": "electric_scooter",
    "tagStr": "automobile bike car cars maps scooter transportation vehicle vespa",
    "description": "Electric Scooter"
  },
  {
    "name": "electrical_services",
    "tagStr": "charge cord electric electrical plug power services wire",
    "description": "Electrical Services"
  },
  {
    "name": "elevator",
    "tagStr": "body down elevator human people person up",
    "description": "Elevator"
  },
  {
    "name": "email",
    "tagStr": "email envelop letter mail message send",
    "description": "Email"
  },
  {
    "name": "emergency",
    "tagStr": "",
    "description": "Emergency"
  },
  {
    "name": "emoji_emotions",
    "tagStr": "emoji emotions expressions face feelings glad happiness happy like mood person pleased smile smiling social survey",
    "description": "Emoji Emotions"
  },
  {
    "name": "emoji_events",
    "tagStr": "achievement award chalice champion cup emoji events first prize reward sport trophy winner",
    "description": "Emoji Events"
  },
  {
    "name": "emoji_food_beverage",
    "tagStr": "beverage coffee cup drink emoji mug plate set tea",
    "description": "Emoji Food Beverage"
  },
  {
    "name": "emoji_nature",
    "tagStr": "animal bee bug daisy emoji flower insect ladybug nature petals spring summer",
    "description": "Emoji Nature"
  },
  {
    "name": "emoji_objects",
    "tagStr": "bulb creative emoji idea light objects solution thinking",
    "description": "Emoji Objects"
  },
  {
    "name": "emoji_people",
    "tagStr": "arm body emoji greeting human people person social waving",
    "description": "Emoji People"
  },
  {
    "name": "emoji_symbols",
    "tagStr": "ampersand character emoji hieroglyph music note percent sign symbols",
    "description": "Emoji Symbols"
  },
  {
    "name": "emoji_transportation",
    "tagStr": "architecture automobile building car cars direction emoji estate maps place public real residence residential shelter transportation travel vehicle",
    "description": "Emoji Transportation"
  },
  {
    "name": "engineering",
    "tagStr": "body cogs cogwheel construction engineering fixing gears hat helmet human maintenance people person setting worker",
    "description": "Engineering"
  },
  {
    "name": "enhanced_encryption",
    "tagStr": "+ add encryption enhanced lock locked new password plus privacy private protection safety secure security symbol",
    "description": "Enhanced Encryption"
  },
  {
    "name": "equalizer",
    "tagStr": "adjustment analytics chart data equalizer graph measure metrics music noise sound static statistics tracking volume",
    "description": "Equalizer"
  },
  {
    "name": "error",
    "tagStr": "! alert attention caution circle danger error exclamation important mark notification symbol warning",
    "description": "Error"
  },
  {
    "name": "error_outline",
    "tagStr": "! alert attention caution circle danger error exclamation important mark notification outline symbol warning",
    "description": "Error Outline"
  },
  {
    "name": "escalator",
    "tagStr": "down escalator staircase up",
    "description": "Escalator"
  },
  {
    "name": "escalator_warning",
    "tagStr": "body child escalator human kid parent people person warning",
    "description": "Escalator Warning"
  },
  {
    "name": "euro",
    "tagStr": "bill card cash coin commerce cost credit currency dollars euro euros finance money online pay payment price shopping symbol",
    "description": "Euro"
  },
  {
    "name": "euro_symbol",
    "tagStr": "bill card cash coin commerce cost credit currency dollars euro finance money online pay payment symbol",
    "description": "Euro Symbol"
  },
  {
    "name": "ev_station",
    "tagStr": "automobile car cars charging electric electricity ev maps places station transportation vehicle",
    "description": "Ev Station"
  },
  {
    "name": "event",
    "tagStr": "calendar date day event mark month range remember reminder today week",
    "description": "Event"
  },
  {
    "name": "event_available",
    "tagStr": "approve available calendar check complete date done event mark ok schedule select tick time validate verified yes",
    "description": "Event Available"
  },
  {
    "name": "event_busy",
    "tagStr": "busy calendar cancel close date event exit no remove schedule stop time unavailable x",
    "description": "Event Busy"
  },
  {
    "name": "event_note",
    "tagStr": "calendar date event note schedule text time writing",
    "description": "Event Note"
  },
  {
    "name": "event_seat",
    "tagStr": "assign assigned chair event furniture reservation row seat section sit",
    "description": "Event Seat"
  },
  {
    "name": "exit_to_app",
    "tagStr": "app application arrow components design exit export interface layout leave mobile monitor move phone screen site tablet to ui ux web website window",
    "description": "Exit To App"
  },
  {
    "name": "expand",
    "tagStr": "arrow arrows compress enlarge expand grow move push together",
    "description": "Expand"
  },
  {
    "name": "expand_circle_down",
    "tagStr": "",
    "description": "Expand Circle Down"
  },
  {
    "name": "expand_less",
    "tagStr": "arrow arrows chevron collapse direction expand expandable less list up",
    "description": "Expand Less"
  },
  {
    "name": "expand_more",
    "tagStr": "arrow arrows chevron collapse direction down expand expandable list more",
    "description": "Expand More"
  },
  {
    "name": "explicit",
    "tagStr": "adult alphabet character content e explicit font language letter media movies music parent rating supervision symbol text type",
    "description": "Explicit"
  },
  {
    "name": "explore",
    "tagStr": "compass destination direction east explore location maps needle north south travel west",
    "description": "Explore"
  },
  {
    "name": "explore_off",
    "tagStr": "compass destination direction disabled east enabled explore location maps needle north off on slash south travel west",
    "description": "Explore Off"
  },
  {
    "name": "exposure",
    "tagStr": "add brightness contrast edit editing effect exposure image minus photo photography picture plus settings subtract",
    "description": "Exposure"
  },
  {
    "name": "exposure_neg_1",
    "tagStr": "1 brightness contrast digit edit editing effect exposure image neg negative number photo photography settings symbol",
    "description": "Exposure Neg 1"
  },
  {
    "name": "exposure_neg_2",
    "tagStr": "2 brightness contrast digit edit editing effect exposure image neg negative number photo photography settings symbol",
    "description": "Exposure Neg 2"
  },
  {
    "name": "exposure_plus_1",
    "tagStr": "1 add brightness contrast digit edit editing effect exposure image number photo photography plus settings symbol",
    "description": "Exposure Plus 1"
  },
  {
    "name": "exposure_plus_2",
    "tagStr": "2 add brightness contrast digit edit editing effect exposure image number photo photography plus settings symbol",
    "description": "Exposure Plus 2"
  },
  {
    "name": "exposure_zero",
    "tagStr": "0 brightness contrast digit edit editing effect exposure image number photo photography settings symbol zero",
    "description": "Exposure Zero"
  },
  {
    "name": "extension",
    "tagStr": "app extended extension game jigsaw plugin add puzzle shape",
    "description": "Extension"
  },
  {
    "name": "extension_off",
    "tagStr": "disabled enabled extended extension jigsaw off on piece puzzle shape slash",
    "description": "Extension Off"
  },
  {
    "name": "face",
    "tagStr": "account emoji eyes face human lock log login logout people person profile recognition security social thumbnail unlock user",
    "description": "Face"
  },
  {
    "name": "face_retouching_natural",
    "tagStr": "edit editing effect emoji emotion face faces image natural photo photography retouch retouching settings star tag",
    "description": "Face Retouching Natural"
  },
  {
    "name": "face_retouching_off",
    "tagStr": "disabled edit editing effect emoji emotion enabled face faces image natural off on photo photography retouch retouching settings slash tag",
    "description": "Face Retouching Off"
  },
  {
    "name": "fact_check",
    "tagStr": "approve check complete done fact list mark ok select tick validate verified yes",
    "description": "Fact Check"
  },
  {
    "name": "family_restroom",
    "tagStr": "bathroom child children family father kids mother parents restroom wc",
    "description": "Family Restroom"
  },
  {
    "name": "fast_forward",
    "tagStr": "control fast ff forward media music play speed time tv video",
    "description": "Fast Forward"
  },
  {
    "name": "fast_rewind",
    "tagStr": "back control fast media music play rewind speed time tv video",
    "description": "Fast Rewind"
  },
  {
    "name": "fastfood",
    "tagStr": "drink fastfood food hamburger maps meal places",
    "description": "Fastfood"
  },
  {
    "name": "favorite",
    "tagStr": "appreciate favorite heart like love remember save shape",
    "description": "Favorite"
  },
  {
    "name": "favorite_border",
    "tagStr": "border favorite heart like love outline remember save shape",
    "description": "Favorite Border"
  },
  {
    "name": "featured_play_list",
    "tagStr": "collection featured highlighted list music play playlist recommended",
    "description": "Featured Play List"
  },
  {
    "name": "featured_video",
    "tagStr": "advertised advertisment featured highlighted recommended video watch",
    "description": "Featured Video"
  },
  {
    "name": "feed",
    "tagStr": "article feed headline information news newspaper paper public social timeline",
    "description": "Feed"
  },
  {
    "name": "feedback",
    "tagStr": "! alert announcement attention bubble caution chat comment communicate danger error exclamation feedback important mark message notification speech symbol warning",
    "description": "Feedback"
  },
  {
    "name": "female",
    "tagStr": "female gender girl lady social symbol woman women",
    "description": "Female"
  },
  {
    "name": "fence",
    "tagStr": "backyard barrier boundaries boundary fence home house protection yard",
    "description": "Fence"
  },
  {
    "name": "festival",
    "tagStr": "circus event festival local maps places tent tour travel",
    "description": "Festival"
  },
  {
    "name": "fiber_dvr",
    "tagStr": "alphabet character digital dvr electronics fiber font letter network record recorder symbol text tv type video",
    "description": "Fiber Dvr"
  },
  {
    "name": "fiber_manual_record",
    "tagStr": "circle dot fiber manual play record watch",
    "description": "Fiber Manual Record"
  },
  {
    "name": "fiber_new",
    "tagStr": "alphabet character fiber font letter network new symbol text type",
    "description": "Fiber New"
  },
  {
    "name": "fiber_pin",
    "tagStr": "alphabet character fiber font letter network pin symbol text type",
    "description": "Fiber Pin"
  },
  {
    "name": "fiber_smart_record",
    "tagStr": "circle dot fiber play record smart watch",
    "description": "Fiber Smart Record"
  },
  {
    "name": "file_copy",
    "tagStr": "content copy cut doc document duplicate file multiple past",
    "description": "File Copy"
  },
  {
    "name": "file_download",
    "tagStr": "arrow arrows down download downloads drive export file install upload",
    "description": "File Download"
  },
  {
    "name": "file_download_done",
    "tagStr": "arrow arrows check done down download downloads drive file install installed tick upload",
    "description": "File Download Done"
  },
  {
    "name": "file_download_off",
    "tagStr": "arrow disabled down download drive enabled export file install off on save slash upload",
    "description": "File Download Off"
  },
  {
    "name": "file_present",
    "tagStr": "clip data doc document drive file folder folders note paper present reminder sheet slide storage writing",
    "description": "File Present"
  },
  {
    "name": "file_upload",
    "tagStr": "arrow arrows download drive export file up upload",
    "description": "File Upload"
  },
  {
    "name": "filter",
    "tagStr": "edit editing effect filter image landscape mountain mountains photo photography picture settings",
    "description": "Filter"
  },
  {
    "name": "filter_1",
    "tagStr": "1 digit edit editing effect filter image images multiple number photography picture pictures settings stack symbol",
    "description": "Filter 1"
  },
  {
    "name": "filter_2",
    "tagStr": "2 digit edit editing effect filter image images multiple number photography picture pictures settings stack symbol",
    "description": "Filter 2"
  },
  {
    "name": "filter_3",
    "tagStr": "3 digit edit editing effect filter image images multiple number photography picture pictures settings stack symbol",
    "description": "Filter 3"
  },
  {
    "name": "filter_4",
    "tagStr": "4 digit edit editing effect filter image images multiple number photography picture pictures settings stack symbol",
    "description": "Filter 4"
  },
  {
    "name": "filter_5",
    "tagStr": "5 digit edit editing effect filter image images multiple number photography picture pictures settings stack symbol",
    "description": "Filter 5"
  },
  {
    "name": "filter_6",
    "tagStr": "6 digit edit editing effect filter image images multiple number photography picture pictures settings stack symbol",
    "description": "Filter 6"
  },
  {
    "name": "filter_7",
    "tagStr": "7 digit edit editing effect filter image images multiple number photography picture pictures settings stack symbol",
    "description": "Filter 7"
  },
  {
    "name": "filter_8",
    "tagStr": "8 digit edit editing effect filter image images multiple number photography picture pictures settings stack symbol",
    "description": "Filter 8"
  },
  {
    "name": "filter_9",
    "tagStr": "9 digit edit editing effect filter image images multiple number photography picture pictures settings stack symbol",
    "description": "Filter 9"
  },
  {
    "name": "filter_9_plus",
    "tagStr": "+ 9 digit edit editing effect filter image images multiple number photography picture pictures plus settings stack symbol",
    "description": "Filter 9 Plus"
  },
  {
    "name": "filter_alt",
    "tagStr": "alt edit filter funnel options refine sift",
    "description": "Filter Alt"
  },
  {
    "name": "filter_b_and_w",
    "tagStr": "and b black contrast edit editing effect filter grayscale image images photography picture pictures settings w white",
    "description": "Filter B And W"
  },
  {
    "name": "filter_center_focus",
    "tagStr": "camera center dot edit filter focus image photo photography picture",
    "description": "Filter Center Focus"
  },
  {
    "name": "filter_drama",
    "tagStr": "cloud drama edit editing effect filter image photo photography picture sky camera",
    "description": "Filter Drama"
  },
  {
    "name": "filter_frames",
    "tagStr": "boarders border camera center edit editing effect filter filters focus frame frames image options photo photography picture",
    "description": "Filter Frames"
  },
  {
    "name": "filter_hdr",
    "tagStr": "camera edit editing effect filter hdr image mountain mountains photo photography picture",
    "description": "Filter Hdr"
  },
  {
    "name": "filter_list",
    "tagStr": "filter lines list organize sort",
    "description": "Filter List"
  },
  {
    "name": "filter_none",
    "tagStr": "filter multiple none stack",
    "description": "Filter None"
  },
  {
    "name": "filter_tilt_shift",
    "tagStr": "blur center edit editing effect filter focus image images photography picture pictures shift tilt",
    "description": "Filter Tilt Shift"
  },
  {
    "name": "filter_vintage",
    "tagStr": "edit editing effect filter flower image images photography picture pictures vintage",
    "description": "Filter Vintage"
  },
  {
    "name": "find_in_page",
    "tagStr": "data doc document drive file find folder folders glass in look magnify magnifying page paper search see sheet slide writing",
    "description": "Find In Page"
  },
  {
    "name": "find_replace",
    "tagStr": "around arrows find glass inprogress load loading refresh look magnify magnifying renew replace rotate search see",
    "description": "Find Replace"
  },
  {
    "name": "fingerprint",
    "tagStr": "finger fingerprint id identification identity print reader thumbprint verification",
    "description": "Fingerprint"
  },
  {
    "name": "fire_extinguisher",
    "tagStr": "emergency extinguisher fire water",
    "description": "Fire Extinguisher"
  },
  {
    "name": "fireplace",
    "tagStr": "chimney fire fireplace flame home house living pit place room warm winter",
    "description": "Fireplace"
  },
  {
    "name": "first_page",
    "tagStr": "arrow back chevron first left page rewind",
    "description": "First Page"
  },
  {
    "name": "fit_screen",
    "tagStr": "enlarge fit format layout reduce scale screen size",
    "description": "Fit Screen"
  },
  {
    "name": "fitbit",
    "tagStr": "",
    "description": "Fitbit"
  },
  {
    "name": "fitness_center",
    "tagStr": "athlete center dumbbell exercise fitness gym hobby places sport weights workout",
    "description": "Fitness Center"
  },
  {
    "name": "flag",
    "tagStr": "country flag goal mark nation report start",
    "description": "Flag"
  },
  {
    "name": "flaky",
    "tagStr": "approve check close complete contrast done exit flaky mark no ok options select stop tick verified x yes",
    "description": "Flaky"
  },
  {
    "name": "flare",
    "tagStr": "bright edit editing effect flare image images light photography picture pictures sparkle sun",
    "description": "Flare"
  },
  {
    "name": "flash_auto",
    "tagStr": "a auto bolt camera electric fast flash lightning thunderbolt",
    "description": "Flash Auto"
  },
  {
    "name": "flash_off",
    "tagStr": "bolt camera disabled electric enabled fast flash lightning off on slash thunderbolt",
    "description": "Flash Off"
  },
  {
    "name": "flash_on",
    "tagStr": "bolt camera disabled electric enabled fast flash lightning off on slash thunderbolt",
    "description": "Flash On"
  },
  {
    "name": "flashlight_off",
    "tagStr": "disabled enabled flash flashlight light off on slash",
    "description": "Flashlight Off"
  },
  {
    "name": "flashlight_on",
    "tagStr": "disabled enabled flash flashlight light off on slash",
    "description": "Flashlight On"
  },
  {
    "name": "flatware",
    "tagStr": "cafe cafeteria cutlery diner dining eat eating fork room spoon",
    "description": "Flatware"
  },
  {
    "name": "flight",
    "tagStr": "air airplane airport flight plane transportation travel trip",
    "description": "Flight"
  },
  {
    "name": "flight_class",
    "tagStr": "",
    "description": "Flight Class"
  },
  {
    "name": "flight_land",
    "tagStr": "airport arrival arriving flight fly land landing plane transportation travel",
    "description": "Flight Land"
  },
  {
    "name": "flight_takeoff",
    "tagStr": "airport departed departing flight fly landing plane takeoff transportation travel",
    "description": "Flight Takeoff"
  },
  {
    "name": "flip",
    "tagStr": "edit editing flip image orientation scan scanning",
    "description": "Flip"
  },
  {
    "name": "flip_camera_android",
    "tagStr": "android camera center edit editing flip image mobile orientation rotate turn",
    "description": "Flip Camera Android"
  },
  {
    "name": "flip_camera_ios",
    "tagStr": "android camera edit editing flip image ios mobile orientation rotate turn",
    "description": "Flip Camera Ios"
  },
  {
    "name": "flip_to_back",
    "tagStr": "arrange arrangement back flip format front layout move order sort to",
    "description": "Flip To Back"
  },
  {
    "name": "flip_to_front",
    "tagStr": "arrange arrangement back flip format front layout move order sort to",
    "description": "Flip To Front"
  },
  {
    "name": "flourescent",
    "tagStr": "bright flourescent lamp light lightbulb",
    "description": "Flourescent"
  },
  {
    "name": "flutter_dash",
    "tagStr": "bird dash flutter",
    "description": "Flutter Dash"
  },
  {
    "name": "fmd_bad",
    "tagStr": "! alert attention bad caution danger destination direction error exclamation fmd important location maps mark notification pin place symbol warning",
    "description": "Fmd Bad"
  },
  {
    "name": "fmd_good",
    "tagStr": "destination direction fmd good location maps pin place stop",
    "description": "Fmd Good"
  },
  {
    "name": "folder",
    "tagStr": "data doc document drive file folder folders sheet slide storage",
    "description": "Folder"
  },
  {
    "name": "folder_open",
    "tagStr": "data doc document drive file folder folders open sheet slide storage",
    "description": "Folder Open"
  },
  {
    "name": "folder_shared",
    "tagStr": "account collaboration data doc document drive face file folder human people person profile share shared sheet slide storage team user",
    "description": "Folder Shared"
  },
  {
    "name": "folder_special",
    "tagStr": "bookmark data doc document drive favorite file folder highlight important marked save saved shape sheet slide special star storage",
    "description": "Folder Special"
  },
  {
    "name": "follow_the_signs",
    "tagStr": "arrow body directional follow human people person right signs social the",
    "description": "Follow The Signs"
  },
  {
    "name": "font_download",
    "tagStr": "A alphabet character download font letter square symbol text type",
    "description": "Font Download"
  },
  {
    "name": "font_download_off",
    "tagStr": "alphabet character disabled download enabled font letter off on slash square symbol text type",
    "description": "Font Download Off"
  },
  {
    "name": "food_bank",
    "tagStr": "architecture bank building charity eat estate food fork house knife meal place real residence residential shelter utensils",
    "description": "Food Bank"
  },
  {
    "name": "forest",
    "tagStr": "",
    "description": "Forest"
  },
  {
    "name": "format_align_center",
    "tagStr": "align alignment center doc edit editing editor format sheet spreadsheet text type writing",
    "description": "Format Align Center"
  },
  {
    "name": "format_align_justify",
    "tagStr": "align alignment doc edit editing editor format justify sheet spreadsheet text type writing",
    "description": "Format Align Justify"
  },
  {
    "name": "format_align_left",
    "tagStr": "align alignment doc edit editing editor format left sheet spreadsheet text type writing",
    "description": "Format Align Left"
  },
  {
    "name": "format_align_right",
    "tagStr": "align alignment doc edit editing editor format right sheet spreadsheet text type writing",
    "description": "Format Align Right"
  },
  {
    "name": "format_bold",
    "tagStr": "B alphabet bold character doc edit editing editor font format letter sheet spreadsheet styles symbol text type writing",
    "description": "Format Bold"
  },
  {
    "name": "format_clear",
    "tagStr": "T alphabet character clear disabled doc edit editing editor enabled font format letter off on sheet slash spreadsheet style symbol text type writing",
    "description": "Format Clear"
  },
  {
    "name": "format_color_fill",
    "tagStr": "bucket color doc edit editing editor fill format paint sheet spreadsheet style text type writing",
    "description": "Format Color Fill"
  },
  {
    "name": "format_color_reset",
    "tagStr": "clear color disabled doc droplet edit editing editor enabled fill format off on paint reset sheet slash spreadsheet style text type water writing",
    "description": "Format Color Reset"
  },
  {
    "name": "format_color_text",
    "tagStr": "color doc edit editing editor fill format paint sheet spreadsheet style text type writing",
    "description": "Format Color Text"
  },
  {
    "name": "format_indent_decrease",
    "tagStr": "align alignment decrease doc edit editing editor format indent indentation paragraph sheet spreadsheet text type writing",
    "description": "Format Indent Decrease"
  },
  {
    "name": "format_indent_increase",
    "tagStr": "align alignment doc edit editing editor format increase indent indentation paragraph sheet spreadsheet text type writing",
    "description": "Format Indent Increase"
  },
  {
    "name": "format_italic",
    "tagStr": "alphabet character doc edit editing editor font format italic letter sheet spreadsheet style symbol text type writing",
    "description": "Format Italic"
  },
  {
    "name": "format_line_spacing",
    "tagStr": "align alignment doc edit editing editor format line sheet spacing spreadsheet text type writing",
    "description": "Format Line Spacing"
  },
  {
    "name": "format_list_bulleted",
    "tagStr": "align alignment bulleted doc edit editing editor format list notes sheet spreadsheet text type writing",
    "description": "Format List Bulleted"
  },
  {
    "name": "format_list_numbered",
    "tagStr": "align alignment digit doc edit editing editor format list notes number numbered sheet spreadsheet symbol text type writing",
    "description": "Format List Numbered"
  },
  {
    "name": "format_list_numbered_rtl",
    "tagStr": "align alignment digit doc edit editing editor format list notes number numbered rtl sheet spreadsheet symbol text type writing",
    "description": "Format List Numbered Rtl"
  },
  {
    "name": "format_paint",
    "tagStr": "brush color doc edit editing editor fill format paint roller sheet spreadsheet style text type writing",
    "description": "Format Paint"
  },
  {
    "name": "format_quote",
    "tagStr": "doc edit editing editor format quotation quote sheet spreadsheet text type writing",
    "description": "Format Quote"
  },
  {
    "name": "format_shapes",
    "tagStr": "alphabet character color doc edit editing editor fill font format letter paint shapes sheet spreadsheet style symbol text type writing",
    "description": "Format Shapes"
  },
  {
    "name": "format_size",
    "tagStr": "alphabet character color doc edit editing editor fill font format letter paint sheet size spreadsheet style symbol text type writing",
    "description": "Format Size"
  },
  {
    "name": "format_strikethrough",
    "tagStr": "alphabet character doc edit editing editor font format letter sheet spreadsheet strikethrough style symbol text type writing",
    "description": "Format Strikethrough"
  },
  {
    "name": "format_textdirection_l_to_r",
    "tagStr": "align alignment doc edit editing editor format ltr sheet spreadsheet text textdirection type writing",
    "description": "Format Textdirection L To R"
  },
  {
    "name": "format_textdirection_r_to_l",
    "tagStr": "align alignment doc edit editing editor format rtl sheet spreadsheet text textdirection type writing",
    "description": "Format Textdirection R To L"
  },
  {
    "name": "format_underlined",
    "tagStr": "alphabet character doc edit editing editor font format letter line sheet spreadsheet style symbol text type under underlined writing",
    "description": "Format Underlined"
  },
  {
    "name": "fort",
    "tagStr": "",
    "description": "Fort"
  },
  {
    "name": "forum",
    "tagStr": "bubble chat comment communicate community conversation feedback forum hub message speech",
    "description": "Forum"
  },
  {
    "name": "forward",
    "tagStr": "arrow forward mail message playback right sent",
    "description": "Forward"
  },
  {
    "name": "forward_10",
    "tagStr": "10 arrow control controls digit fast forward music number play seconds symbol video",
    "description": "Forward 10"
  },
  {
    "name": "forward_30",
    "tagStr": "30 arrow control controls digit fast forward music number seconds symbol video",
    "description": "Forward 30"
  },
  {
    "name": "forward_5",
    "tagStr": "10 5 arrow control controls digit fast forward music number seconds symbol video",
    "description": "Forward 5"
  },
  {
    "name": "forward_to_inbox",
    "tagStr": "arrow email envelop forward inbox letter mail message send to",
    "description": "Forward To Inbox"
  },
  {
    "name": "foundation",
    "tagStr": "architecture base basis building construction estate foundation home house real residential",
    "description": "Foundation"
  },
  {
    "name": "free_breakfast",
    "tagStr": "beverage breakfast cafe coffee cup drink free mug tea",
    "description": "Free Breakfast"
  },
  {
    "name": "free_cancellation",
    "tagStr": "",
    "description": "Free Cancellation"
  },
  {
    "name": "front_hand",
    "tagStr": "",
    "description": "Front Hand"
  },
  {
    "name": "fullscreen",
    "tagStr": "adjust app application components full fullscreen interface screen site size ui ux view web website",
    "description": "Fullscreen"
  },
  {
    "name": "fullscreen_exit",
    "tagStr": "adjust app application components exit full fullscreen interface screen site size ui ux view web website",
    "description": "Fullscreen Exit"
  },
  {
    "name": "functions",
    "tagStr": "average calculate count doc edit editing editor functions math sheet spreadsheet style sum text type writing",
    "description": "Functions"
  },
  {
    "name": "g_mobiledata",
    "tagStr": "alphabet character data font g letter mobile network service symbol text type",
    "description": "G Mobiledata"
  },
  {
    "name": "g_translate",
    "tagStr": "emblem g google language logo mark speaking speech translate translator words",
    "description": "G Translate"
  },
  {
    "name": "gamepad",
    "tagStr": "buttons console controller device game gamepad gaming playstation video",
    "description": "Gamepad"
  },
  {
    "name": "games",
    "tagStr": "adjust arrow arrows control controller direction games gaming left move right",
    "description": "Games"
  },
  {
    "name": "garage",
    "tagStr": "automobile automotive car cars direction garage maps transportation travel vehicle",
    "description": "Garage"
  },
  {
    "name": "gavel",
    "tagStr": "agreement contract court document gavel government judge law mallet official police rule rules terms",
    "description": "Gavel"
  },
  {
    "name": "generating_tokens",
    "tagStr": "",
    "description": "Generating Tokens"
  },
  {
    "name": "gesture",
    "tagStr": "drawing finger gesture gestures hand motion",
    "description": "Gesture"
  },
  {
    "name": "get_app",
    "tagStr": "app arrow arrows down download downloads export get install play upload",
    "description": "Get App"
  },
  {
    "name": "gif",
    "tagStr": "alphabet animated animation bitmap character font format gif graphics interchange letter symbol text type",
    "description": "Gif"
  },
  {
    "name": "gif_box",
    "tagStr": "",
    "description": "Gif Box"
  },
  {
    "name": "gite",
    "tagStr": "architecture estate gite home hostel house maps place real residence residential stay traveling",
    "description": "Gite"
  },
  {
    "name": "golf_course",
    "tagStr": "athlete athletic ball club course entertainment flag golf golfer golfing hobby hole places putt sports",
    "description": "Golf Course"
  },
  {
    "name": "gpp_bad",
    "tagStr": "bad cancel certified close error exit gpp no privacy private protect protection remove security shield sim stop verified x",
    "description": "Gpp Bad"
  },
  {
    "name": "gpp_good",
    "tagStr": "certified check good gpp ok pass security shield sim tick",
    "description": "Gpp Good"
  },
  {
    "name": "gpp_maybe",
    "tagStr": "! alert attention caution certified danger error exclamation gpp important mark maybe notification privacy private protect protection security shield sim symbol verified warning",
    "description": "Gpp Maybe"
  },
  {
    "name": "gps_fixed",
    "tagStr": "destination direction fixed gps location maps pin place pointer stop tracking",
    "description": "Gps Fixed"
  },
  {
    "name": "gps_not_fixed",
    "tagStr": "destination direction disabled enabled gps location maps not fixed off on online place pointer slash tracking",
    "description": "Gps Not Fixed"
  },
  {
    "name": "gps_off",
    "tagStr": "destination direction disabled enabled gps location maps not fixed off offline on place pointer slash tracking",
    "description": "Gps Off"
  },
  {
    "name": "grade",
    "tagStr": "achievement grade important marked rate reward saved shape special star",
    "description": "Grade"
  },
  {
    "name": "gradient",
    "tagStr": "color edit editing effect filter gradient image images photography picture pictures",
    "description": "Gradient"
  },
  {
    "name": "grading",
    "tagStr": "approve check complete document done feedback grade grading mark ok review reviewed select tick validate verified writing yes",
    "description": "Grading"
  },
  {
    "name": "grain",
    "tagStr": "dots edit editing effect filter grain image images photography picture pictures",
    "description": "Grain"
  },
  {
    "name": "graphic_eq",
    "tagStr": "audio eq equalizer graphic music recording sound voice",
    "description": "Graphic Eq"
  },
  {
    "name": "grass",
    "tagStr": "backyard fodder grass ground home lawn plant turf yard",
    "description": "Grass"
  },
  {
    "name": "grid_3x3",
    "tagStr": "3 grid layout line space",
    "description": "Grid 3x3"
  },
  {
    "name": "grid_4x4",
    "tagStr": "4 by grid layout lines space",
    "description": "Grid 4x4"
  },
  {
    "name": "grid_goldenratio",
    "tagStr": "golden goldenratio grid layout lines ratio space",
    "description": "Grid Goldenratio"
  },
  {
    "name": "grid_off",
    "tagStr": "collage disabled enabled grid image layout off on slash view",
    "description": "Grid Off"
  },
  {
    "name": "grid_on",
    "tagStr": "collage disabled enabled grid image layout off on slash view",
    "description": "Grid On"
  },
  {
    "name": "grid_view",
    "tagStr": "app application square blocks components dashboard design grid interface layout screen site tiles ui ux view web website window",
    "description": "Grid View"
  },
  {
    "name": "group",
    "tagStr": "accounts committee face family friends group humans network people persons profiles social team users",
    "description": "Group"
  },
  {
    "name": "group_add",
    "tagStr": "accounts add committee face family friends group humans increase more network people persons plus profiles social team users",
    "description": "Group Add"
  },
  {
    "name": "group_off",
    "tagStr": "",
    "description": "Group Off"
  },
  {
    "name": "group_remove",
    "tagStr": "",
    "description": "Group Remove"
  },
  {
    "name": "group_work",
    "tagStr": "alliance collaboration group partnership team teamwork together work",
    "description": "Group Work"
  },
  {
    "name": "groups",
    "tagStr": "body club collaboration crowd gathering groups human meeting people person social teams",
    "description": "Groups"
  },
  {
    "name": "h_mobiledata",
    "tagStr": "alphabet character data font h letter mobile network service symbol text type",
    "description": "H Mobiledata"
  },
  {
    "name": "h_plus_mobiledata",
    "tagStr": "+ alphabet character data font h letter mobile network plus service symbol text type",
    "description": "H Plus Mobiledata"
  },
  {
    "name": "hail",
    "tagStr": "body hail human people person pick public stop taxi transportation",
    "description": "Hail"
  },
  {
    "name": "handyman",
    "tagStr": "build construction fix hammer handyman repair screw screwdriver tools",
    "description": "Handyman"
  },
  {
    "name": "hardware",
    "tagStr": "break construction hammer hardware nail repair tool",
    "description": "Hardware"
  },
  {
    "name": "hd",
    "tagStr": "alphabet character definition display font hd high letter movie movies quality resolution screen symbol text tv type video",
    "description": "Hd"
  },
  {
    "name": "hdr_auto",
    "tagStr": "A alphabet auto camera character circle dynamic font hdr high letter photo range symbol text type",
    "description": "Hdr Auto"
  },
  {
    "name": "hdr_auto_select",
    "tagStr": "+ A alphabet auto camera character circle dynamic font hdr high letter photo range select symbol text type",
    "description": "Hdr Auto Select"
  },
  {
    "name": "hdr_enhanced_select",
    "tagStr": "add alphabet character dynamic enhance font hdr high letter plus range select symbol text type",
    "description": "Hdr Enhanced Select"
  },
  {
    "name": "hdr_off",
    "tagStr": "alphabet character disabled dynamic enabled enhance font hdr high letter off on range select slash symbol text type",
    "description": "Hdr Off"
  },
  {
    "name": "hdr_off_select",
    "tagStr": "alphabet camera character circle disabled dynamic enabled font hdr high letter off on photo range select slash symbol text type",
    "description": "Hdr Off Select"
  },
  {
    "name": "hdr_on",
    "tagStr": "add alphabet character dynamic enhance font hdr high letter on plus range select symbol text type",
    "description": "Hdr On"
  },
  {
    "name": "hdr_on_select",
    "tagStr": "+ alphabet camera character circle dynamic font hdr high letter on photo range select symbol text type",
    "description": "Hdr On Select"
  },
  {
    "name": "hdr_plus",
    "tagStr": "+ add alphabet character circle dynamic enhance font hdr high letter plus range select symbol text type",
    "description": "Hdr Plus"
  },
  {
    "name": "hdr_strong",
    "tagStr": "circles dots dynamic enhance hdr high range strong",
    "description": "Hdr Strong"
  },
  {
    "name": "hdr_weak",
    "tagStr": "circles dots dynamic enhance hdr high range weak",
    "description": "Hdr Weak"
  },
  {
    "name": "headphones",
    "tagStr": "accessory audio device ear earphone headphones headset listen music sound",
    "description": "Headphones"
  },
  {
    "name": "headphones_battery",
    "tagStr": "accessory audio battery charging device ear earphone headphones headset listen music sound",
    "description": "Headphones Battery"
  },
  {
    "name": "headset",
    "tagStr": "accessory audio device ear earbuds earphone headphones headset listen music sound",
    "description": "Headset"
  },
  {
    "name": "headset_mic",
    "tagStr": "accessory audio chat device ear earphone headphones headset listen mic music sound talk",
    "description": "Headset Mic"
  },
  {
    "name": "headset_off",
    "tagStr": "accessory audio chat device disabled ear earphone enabled headphones headset listen mic music off on slash sound talk",
    "description": "Headset Off"
  },
  {
    "name": "healing",
    "tagStr": "bandage edit editing emergency fix healing hospital image medicine",
    "description": "Healing"
  },
  {
    "name": "health_and_safety",
    "tagStr": "+ add and certified health plus privacy private protect protection safety security shield symbol verified",
    "description": "Health And Safety"
  },
  {
    "name": "hearing",
    "tagStr": "accessibility accessible aid ear handicap hearing help impaired listen sound volume",
    "description": "Hearing"
  },
  {
    "name": "hearing_disabled",
    "tagStr": "accessibility accessible aid disabled ear enabled handicap hearing help impaired listen off on slash sound volume",
    "description": "Hearing Disabled"
  },
  {
    "name": "heart_broken",
    "tagStr": "",
    "description": "Heart Broken"
  },
  {
    "name": "height",
    "tagStr": "arrow color doc down edit editing editor fill format height paint sheet spreadsheet style text type up writing",
    "description": "Height"
  },
  {
    "name": "help",
    "tagStr": "? assistance circle help info information punctuation question mark shape support symbol",
    "description": "Help"
  },
  {
    "name": "help_center",
    "tagStr": "? assistance center help info information punctuation question mark support symbol",
    "description": "Help Center"
  },
  {
    "name": "help_outline",
    "tagStr": "? assistance circle help info information outline punctuation question mark shape support symbol",
    "description": "Help Outline"
  },
  {
    "name": "hevc",
    "tagStr": "alphabet character coding efficiency font hevc high letter symbol text type video",
    "description": "Hevc"
  },
  {
    "name": "hide_image",
    "tagStr": "disabled enabled hide image landscape mountain mountains off on photo photography picture slash",
    "description": "Hide Image"
  },
  {
    "name": "hide_source",
    "tagStr": "circle disabled enabled hide off offline on shape slash source",
    "description": "Hide Source"
  },
  {
    "name": "high_quality",
    "tagStr": "alphabet character definition display font high hq letter movie movies quality resolution screen symbol text tv type",
    "description": "High Quality"
  },
  {
    "name": "highlight",
    "tagStr": "color doc edit editing editor emphasize fill flash format highlight light paint sheet spreadsheet style text type writing",
    "description": "Highlight"
  },
  {
    "name": "highlight_alt",
    "tagStr": "alt arrow box click cursor draw focus highlight pointer select selection target",
    "description": "Highlight Alt"
  },
  {
    "name": "highlight_off",
    "tagStr": "cancel close exit highlight no off quit remove stop x",
    "description": "Highlight Off"
  },
  {
    "name": "hiking",
    "tagStr": "backpacking bag climbing duffle hiking mountain social sports stick trail travel walking",
    "description": "Hiking"
  },
  {
    "name": "history",
    "tagStr": "arrow back backwards clock date history refresh renew reverse rotate schedule time turn",
    "description": "History"
  },
  {
    "name": "history_edu",
    "tagStr": "document edu education feather history letter paper pen quill school story tools write writing",
    "description": "History Edu"
  },
  {
    "name": "history_toggle_off",
    "tagStr": "clock date history off schedule time toggle",
    "description": "History Toggle Off"
  },
  {
    "name": "hive",
    "tagStr": "",
    "description": "Hive"
  },
  {
    "name": "holiday_village",
    "tagStr": "architecture beach camping cottage estate holiday home house lake lodge maps place real residence residential stay traveling vacation village",
    "description": "Holiday Village"
  },
  {
    "name": "home",
    "tagStr": "address app application--house architecture building components design estate home homepage house interface layout place real residence residential screen shelter site structure ui unit ux web website window",
    "description": "Home"
  },
  {
    "name": "home_max",
    "tagStr": "device gadget hardware home internet iot max nest smart things",
    "description": "Home Max"
  },
  {
    "name": "home_mini",
    "tagStr": "Internet device gadget hardware home iot mini nest smart things",
    "description": "Home Mini"
  },
  {
    "name": "home_repair_service",
    "tagStr": "box equipment fix home kit mechanic repair repairing service tool toolbox tools workshop",
    "description": "Home Repair Service"
  },
  {
    "name": "home_work",
    "tagStr": "architecture building estate home place real residence residential shelter work",
    "description": "Home Work"
  },
  {
    "name": "horizontal_distribute",
    "tagStr": "alignment distribute format horizontal layout lines paragraph rule rules style text",
    "description": "Horizontal Distribute"
  },
  {
    "name": "horizontal_rule",
    "tagStr": "gmail horizontal line novitas rule",
    "description": "Horizontal Rule"
  },
  {
    "name": "horizontal_split",
    "tagStr": "bars format horizontal layout lines split stacked",
    "description": "Horizontal Split"
  },
  {
    "name": "hot_tub",
    "tagStr": "bath bathing bathroom bathtub hot hotel human jacuzzi person shower spa steam travel tub water",
    "description": "Hot Tub"
  },
  {
    "name": "hotel",
    "tagStr": "body hotel human people person sleep stay travel trip",
    "description": "Hotel"
  },
  {
    "name": "hotel_class",
    "tagStr": "",
    "description": "Hotel Class"
  },
  {
    "name": "hourglass_bottom",
    "tagStr": "bottom countdown half hourglass loading minute minutes time wait waiting",
    "description": "Hourglass Bottom"
  },
  {
    "name": "hourglass_disabled",
    "tagStr": "clock countdown disabled empty enabled hourglass loading minute minutes off on slash time wait waiting",
    "description": "Hourglass Disabled"
  },
  {
    "name": "hourglass_empty",
    "tagStr": "countdown empty hourglass loading minutes time wait waiting",
    "description": "Hourglass Empty"
  },
  {
    "name": "hourglass_full",
    "tagStr": "countdown full hourglass loading minutes time wait waiting",
    "description": "Hourglass Full"
  },
  {
    "name": "hourglass_top",
    "tagStr": "countdown half hourglass loading minute minutes time top wait waiting",
    "description": "Hourglass Top"
  },
  {
    "name": "house",
    "tagStr": "architecture building estate family home homepage house place places real residence residential shelter",
    "description": "House"
  },
  {
    "name": "house_siding",
    "tagStr": "architecture building construction estate exterior facade home house real residential siding",
    "description": "House Siding"
  },
  {
    "name": "houseboat",
    "tagStr": "architecture beach boat estate floating home house houseboat maps place real residence residential sea stay traveling vacation",
    "description": "Houseboat"
  },
  {
    "name": "how_to_reg",
    "tagStr": "approve ballot check complete done election how mark ok poll register registration select tick to reg validate verified vote yes",
    "description": "How To Reg"
  },
  {
    "name": "how_to_vote",
    "tagStr": "ballot election how poll to vote",
    "description": "How To Vote"
  },
  {
    "name": "http",
    "tagStr": "alphabet character font http letter symbol text transfer type url website",
    "description": "Http"
  },
  {
    "name": "https",
    "tagStr": "https lock locked password privacy private protection safety secure security",
    "description": "Https"
  },
  {
    "name": "hub",
    "tagStr": "",
    "description": "Hub"
  },
  {
    "name": "hvac",
    "tagStr": "air conditioning heating hvac ventilation",
    "description": "Hvac"
  },
  {
    "name": "ice_skating",
    "tagStr": "athlete athletic entertainment exercise hobby ice shoe skates skating social sports travel",
    "description": "Ice Skating"
  },
  {
    "name": "icecream",
    "tagStr": "cream dessert food ice icecream snack",
    "description": "Icecream"
  },
  {
    "name": "image",
    "tagStr": "frame image landscape mountain mountains photo photography picture",
    "description": "Image"
  },
  {
    "name": "image_aspect_ratio",
    "tagStr": "aspect image photo photography picture ratio rectangle square",
    "description": "Image Aspect Ratio"
  },
  {
    "name": "image_not_supported",
    "tagStr": "disabled enabled image landscape mountain mountains not off on photo photography picture slash supported",
    "description": "Image Not Supported"
  },
  {
    "name": "image_search",
    "tagStr": "find glass image landscape look magnify magnifying mountain mountains photo photography picture search see",
    "description": "Image Search"
  },
  {
    "name": "imagesearch_roller",
    "tagStr": "art image imagesearch paint roller search",
    "description": "Imagesearch Roller"
  },
  {
    "name": "import_contacts",
    "tagStr": "address book contacts import info information open",
    "description": "Import Contacts"
  },
  {
    "name": "import_export",
    "tagStr": "arrow arrows direction down explort import up",
    "description": "Import Export"
  },
  {
    "name": "important_devices",
    "tagStr": "Android OS desktop devices hardware iOS important mobile monitor phone star tablet web",
    "description": "Important Devices"
  },
  {
    "name": "inbox",
    "tagStr": "archive email inbox incoming mail message",
    "description": "Inbox"
  },
  {
    "name": "incomplete_circle",
    "tagStr": "",
    "description": "Incomplete Circle"
  },
  {
    "name": "indeterminate_check_box",
    "tagStr": "app application box button check components control design form indeterminate interface screen select selected selection site square toggle ui undetermined ux web website",
    "description": "Indeterminate Check Box"
  },
  {
    "name": "info",
    "tagStr": "alert announcment assistance details help i info information service support",
    "description": "Info"
  },
  {
    "name": "input",
    "tagStr": "arrow box download input login move right",
    "description": "Input"
  },
  {
    "name": "insert_chart",
    "tagStr": "analytics bar bars chart data diagram graph infographic insert measure metrics statistics tracking",
    "description": "Insert Chart"
  },
  {
    "name": "insert_chart_outlined",
    "tagStr": "analytics bar bars chart data diagram graph infographic insert measure metrics outlined statistics tracking",
    "description": "Insert Chart Outlined"
  },
  {
    "name": "insert_comment",
    "tagStr": "add bubble chat comment feedback insert message",
    "description": "Insert Comment"
  },
  {
    "name": "insert_drive_file",
    "tagStr": "doc drive file format insert sheet slide",
    "description": "Insert Drive File"
  },
  {
    "name": "insert_emoticon",
    "tagStr": "account emoji emoticon face happy human insert people person profile sentiment smile user",
    "description": "Insert Emoticon"
  },
  {
    "name": "insert_invitation",
    "tagStr": "calendar date day event insert invitation mark month range remember reminder today week",
    "description": "Insert Invitation"
  },
  {
    "name": "insert_link",
    "tagStr": "add attach clip file insert link mail media",
    "description": "Insert Link"
  },
  {
    "name": "insert_page_break",
    "tagStr": "",
    "description": "Insert Page Break"
  },
  {
    "name": "insert_photo",
    "tagStr": "image insert landscape mountain mountains photo photography picture",
    "description": "Insert Photo"
  },
  {
    "name": "insights",
    "tagStr": "analytics bar bars chart data diagram graph infographic insights measure metrics stars statistics tracking",
    "description": "Insights"
  },
  {
    "name": "integration_instructions",
    "tagStr": "brackets clipboard code css develop developer doc document engineer engineering clipboard html instructions integration platform",
    "description": "Integration Instructions"
  },
  {
    "name": "interests",
    "tagStr": "",
    "description": "Interests"
  },
  {
    "name": "interpreter_mode",
    "tagStr": "",
    "description": "Interpreter Mode"
  },
  {
    "name": "inventory",
    "tagStr": "buy check clipboard commerce e-commerce inventory list purchase shop store",
    "description": "Inventory"
  },
  {
    "name": "inventory_2",
    "tagStr": "archive box file inventory organize packages product stock storage supply",
    "description": "Inventory 2"
  },
  {
    "name": "invert_colors",
    "tagStr": "colors drop droplet edit editing hue invert inverted palette tone water",
    "description": "Invert Colors"
  },
  {
    "name": "invert_colors_off",
    "tagStr": "colors disabled drop droplet enabled hue invert inverted off offline on opacity palette slash tone water",
    "description": "Invert Colors Off"
  },
  {
    "name": "ios_share",
    "tagStr": "arrow arrows button direction internet ios link send share sharing social up web website",
    "description": "Ios Share"
  },
  {
    "name": "iron",
    "tagStr": "appliance clothes electric iron ironing machine object",
    "description": "Iron"
  },
  {
    "name": "iso",
    "tagStr": "add edit editing effect image iso minus photography picture plus sensor shutter speed subtract",
    "description": "Iso"
  },
  {
    "name": "kayaking",
    "tagStr": "athlete athletic body canoe entertainment exercise hobby human kayak kayaking lake paddle paddling people person rafting river row social sports summer travel water",
    "description": "Kayaking"
  },
  {
    "name": "kebab_dining",
    "tagStr": "",
    "description": "Kebab Dining"
  },
  {
    "name": "keyboard",
    "tagStr": "computer device hardware input keyboard keypad letter office text type",
    "description": "Keyboard"
  },
  {
    "name": "keyboard_alt",
    "tagStr": "alt computer device hardware input keyboard keypad letter office text type",
    "description": "Keyboard Alt"
  },
  {
    "name": "keyboard_arrow_down",
    "tagStr": "arrow arrows down keyboard",
    "description": "Keyboard Arrow Down"
  },
  {
    "name": "keyboard_arrow_left",
    "tagStr": "arrow arrows keyboard left",
    "description": "Keyboard Arrow Left"
  },
  {
    "name": "keyboard_arrow_right",
    "tagStr": "arrow arrows keyboard right",
    "description": "Keyboard Arrow Right"
  },
  {
    "name": "keyboard_arrow_up",
    "tagStr": "arrow arrows keyboard up",
    "description": "Keyboard Arrow Up"
  },
  {
    "name": "keyboard_backspace",
    "tagStr": "arrow back backspace keyboard left",
    "description": "Keyboard Backspace"
  },
  {
    "name": "keyboard_capslock",
    "tagStr": "arrow capslock keyboard up",
    "description": "Keyboard Capslock"
  },
  {
    "name": "keyboard_double_arrow_down",
    "tagStr": "",
    "description": "Keyboard Double Arrow Down"
  },
  {
    "name": "keyboard_double_arrow_left",
    "tagStr": "",
    "description": "Keyboard Double Arrow Left"
  },
  {
    "name": "keyboard_double_arrow_right",
    "tagStr": "",
    "description": "Keyboard Double Arrow Right"
  },
  {
    "name": "keyboard_double_arrow_up",
    "tagStr": "",
    "description": "Keyboard Double Arrow Up"
  },
  {
    "name": "keyboard_hide",
    "tagStr": "arrow computer device down hardware hide input keyboard keypad text",
    "description": "Keyboard Hide"
  },
  {
    "name": "keyboard_return",
    "tagStr": "arrow back keyboard left return",
    "description": "Keyboard Return"
  },
  {
    "name": "keyboard_tab",
    "tagStr": "arrow keyboard next right tab",
    "description": "Keyboard Tab"
  },
  {
    "name": "keyboard_voice",
    "tagStr": "keyboard mic microphone noise record recorder speaker voice",
    "description": "Keyboard Voice"
  },
  {
    "name": "king_bed",
    "tagStr": "bed bedroom double furniture home hotel house king night pillows queen rest room sleep",
    "description": "King Bed"
  },
  {
    "name": "kitchen",
    "tagStr": "appliance cold food fridge home house ice kitchen places refrigerator storage",
    "description": "Kitchen"
  },
  {
    "name": "kitesurfing",
    "tagStr": "athlete athletic beach body entertainment exercise hobby human kitesurfing people person social sports surf travel water",
    "description": "Kitesurfing"
  },
  {
    "name": "label",
    "tagStr": "favorite indent label library mail remember save stamp sticker tag",
    "description": "Label"
  },
  {
    "name": "label_important",
    "tagStr": "favorite important indent label library mail remember save stamp sticker tag wing",
    "description": "Label Important"
  },
  {
    "name": "label_off",
    "tagStr": "disabled enabled favorite indent label library mail off on remember save slash stamp sticker tag wing",
    "description": "Label Off"
  },
  {
    "name": "landscape",
    "tagStr": "image landscape mountain mountains nature photo photography picture",
    "description": "Landscape"
  },
  {
    "name": "language",
    "tagStr": "globe internet language planet website world www",
    "description": "Language"
  },
  {
    "name": "laptop",
    "tagStr": "Android OS chrome computer connect desktop device display hardware iOS laptop link mac monitor smart tv web windows",
    "description": "Laptop"
  },
  {
    "name": "laptop_chromebook",
    "tagStr": "Android OS chrome chromebook device display hardware iOS laptop mac chromebook monitor screen web window",
    "description": "Laptop Chromebook"
  },
  {
    "name": "laptop_mac",
    "tagStr": "Android OS chrome device display hardware iOS laptop mac monitor screen web window",
    "description": "Laptop Mac"
  },
  {
    "name": "laptop_windows",
    "tagStr": "Android OS chrome device display hardware iOS laptop mac monitor screen web window windows",
    "description": "Laptop Windows"
  },
  {
    "name": "last_page",
    "tagStr": "app application arrow chevron components end forward interface last page right screen site ui ux web website",
    "description": "Last Page"
  },
  {
    "name": "launch",
    "tagStr": "app application arrow box components interface launch new open screen site ui ux web website window",
    "description": "Launch"
  },
  {
    "name": "layers",
    "tagStr": "arrange disabled enabled interaction layers maps off on overlay pages slash stack",
    "description": "Layers"
  },
  {
    "name": "layers_clear",
    "tagStr": "arrange clear delete disabled enabled interaction layers maps off on overlay pages slash",
    "description": "Layers Clear"
  },
  {
    "name": "leaderboard",
    "tagStr": "analytics bar bars chart data diagram graph infographic leaderboard measure metrics statistics tracking",
    "description": "Leaderboard"
  },
  {
    "name": "leak_add",
    "tagStr": "add connection data leak link network service signals synce wireless",
    "description": "Leak Add"
  },
  {
    "name": "leak_remove",
    "tagStr": "connection data disabled enabled leak link network off offline on remove service signals slash synce wireless",
    "description": "Leak Remove"
  },
  {
    "name": "legend_toggle",
    "tagStr": "analytics chart data diagram graph infographic legend measure metrics monitoring stackdriver statistics toggle tracking",
    "description": "Legend Toggle"
  },
  {
    "name": "lens",
    "tagStr": "circle full geometry lens moon",
    "description": "Lens"
  },
  {
    "name": "lens_blur",
    "tagStr": "blur camera dim dot effect foggy fuzzy image lens photo soften",
    "description": "Lens Blur"
  },
  {
    "name": "library_add",
    "tagStr": "+ add collection layers library multiple music new plus save stacked symbol video",
    "description": "Library Add"
  },
  {
    "name": "library_add_check",
    "tagStr": "add approve check collection complete done layers library mark multiple music ok select stacked tick validate verified video yes",
    "description": "Library Add Check"
  },
  {
    "name": "library_books",
    "tagStr": "add album audio book books collection library read reading",
    "description": "Library Books"
  },
  {
    "name": "library_music",
    "tagStr": "add album audio collection library music song sounds",
    "description": "Library Music"
  },
  {
    "name": "light",
    "tagStr": "bulb ceiling hanging inside interior lamp light lighting pendent room",
    "description": "Light"
  },
  {
    "name": "light_mode",
    "tagStr": "bright brightness day device light lighting mode mornng sky sun sunny",
    "description": "Light Mode"
  },
  {
    "name": "lightbulb",
    "tagStr": "alert announcement idea info information learning light lightbulb mode on",
    "description": "Lightbulb"
  },
  {
    "name": "line_axis",
    "tagStr": "",
    "description": "Line Axis"
  },
  {
    "name": "line_style",
    "tagStr": "dash dotted line rule spacing style",
    "description": "Line Style"
  },
  {
    "name": "line_weight",
    "tagStr": "height line size spacing style thickness weight",
    "description": "Line Weight"
  },
  {
    "name": "linear_scale",
    "tagStr": "app application components design interface layout linear measure menu scale screen site slider ui ux web website window",
    "description": "Linear Scale"
  },
  {
    "name": "link",
    "tagStr": "chain clip connection external hyperlink link linked links multimedia unlisted url",
    "description": "Link"
  },
  {
    "name": "link_off",
    "tagStr": "attached chain clip connection disabled enabled link linked links multimedia off on slash unlink url",
    "description": "Link Off"
  },
  {
    "name": "linked_camera",
    "tagStr": "camera connect connection lens linked network photo photography picture signal signals sync wireless",
    "description": "Linked Camera"
  },
  {
    "name": "liquor",
    "tagStr": "alcohol bar bottle club cocktail drink food liquor party store wine",
    "description": "Liquor"
  },
  {
    "name": "list",
    "tagStr": "file format index list menu options",
    "description": "List"
  },
  {
    "name": "list_alt",
    "tagStr": "alt box contained format lines list order reorder stacked title",
    "description": "List Alt"
  },
  {
    "name": "live_help",
    "tagStr": "? assistance bubble chat comment communicate help info information live message punctuation question mark speech support symbol",
    "description": "Live Help"
  },
  {
    "name": "live_tv",
    "tagStr": "Android OS antennas hardware chrome desktop device iOS live mac monitor movie play stream television tv web window",
    "description": "Live Tv"
  },
  {
    "name": "living",
    "tagStr": "chair comfort couch decoration furniture home house living lounging loveseat room seat seating sofa",
    "description": "Living"
  },
  {
    "name": "local_activity",
    "tagStr": "activity event local star things ticket",
    "description": "Local Activity"
  },
  {
    "name": "local_airport",
    "tagStr": "air airplane airport flight plane transportation travel trip",
    "description": "Local Airport"
  },
  {
    "name": "local_atm",
    "tagStr": "atm bill card cart cash coin commerce credit currency dollars local money online pay payment shopping symbol",
    "description": "Local Atm"
  },
  {
    "name": "local_bar",
    "tagStr": "alcohol bar bottle club cocktail drink food liquor local wine",
    "description": "Local Bar"
  },
  {
    "name": "local_cafe",
    "tagStr": "bottle cafe coffee cup drink food restaurant tea",
    "description": "Local Cafe"
  },
  {
    "name": "local_car_wash",
    "tagStr": "automobile car cars local maps transportation travel vehicle wash",
    "description": "Local Car Wash"
  },
  {
    "name": "local_convenience_store",
    "tagStr": "-- 24 bill building business card cash coin commerce company convenience credit currency dollars local maps market money new online pay payment plus shop shopping store storefront symbol",
    "description": "Local Convenience Store"
  },
  {
    "name": "local_dining",
    "tagStr": "dining eat food fork knife local meal restaurant spoon",
    "description": "Local Dining"
  },
  {
    "name": "local_drink",
    "tagStr": "cup drink drop droplet liquid local park water",
    "description": "Local Drink"
  },
  {
    "name": "local_fire_department",
    "tagStr": "911 department fire firefighter flame hot",
    "description": "Local Fire Department"
  },
  {
    "name": "local_florist",
    "tagStr": "florist flower local shop",
    "description": "Local Florist"
  },
  {
    "name": "local_gas_station",
    "tagStr": "auto car gas local oil station vehicle",
    "description": "Local Gas Station"
  },
  {
    "name": "local_grocery_store",
    "tagStr": "grocery market shop store",
    "description": "Local Grocery Store"
  },
  {
    "name": "local_hospital",
    "tagStr": "911 aid cross emergency first hospital local medicine",
    "description": "Local Hospital"
  },
  {
    "name": "local_hotel",
    "tagStr": "",
    "description": "Local Hotel"
  },
  {
    "name": "local_laundry_service",
    "tagStr": "cleaning clothing dry dryer hotel laundry local service washer",
    "description": "Local Laundry Service"
  },
  {
    "name": "local_library",
    "tagStr": "book community learning library local read",
    "description": "Local Library"
  },
  {
    "name": "local_mall",
    "tagStr": "bag bill building business buy card cart cash coin commerce credit currency dollars handbag local mall money online pay payment shop shopping store storefront",
    "description": "Local Mall"
  },
  {
    "name": "local_movies",
    "tagStr": "",
    "description": "Local Movies"
  },
  {
    "name": "local_offer",
    "tagStr": "deal discount offer price shop shopping store tag",
    "description": "Local Offer"
  },
  {
    "name": "local_parking",
    "tagStr": "alphabet auto car character font garage letter local park parking symbol text type vehicle",
    "description": "Local Parking"
  },
  {
    "name": "local_pharmacy",
    "tagStr": "911 aid cross emergency first hospital local medicine pharmacy places",
    "description": "Local Pharmacy"
  },
  {
    "name": "local_phone",
    "tagStr": "booth call communication phone telecommunication",
    "description": "Local Phone"
  },
  {
    "name": "local_pizza",
    "tagStr": "drink fastfood food local meal pizza",
    "description": "Local Pizza"
  },
  {
    "name": "local_play",
    "tagStr": "",
    "description": "Local Play"
  },
  {
    "name": "local_police",
    "tagStr": "911 badge law local officer police protect protection security shield",
    "description": "Local Police"
  },
  {
    "name": "local_post_office",
    "tagStr": "delivery email envelop letter local mail message office package parcel post postal send stamp",
    "description": "Local Post Office"
  },
  {
    "name": "local_printshop",
    "tagStr": "draft fax ink local machine office paper print printer printshop send",
    "description": "Local Printshop"
  },
  {
    "name": "local_see",
    "tagStr": "camera lens local photo photography picture see",
    "description": "Local See"
  },
  {
    "name": "local_shipping",
    "tagStr": "automobile car cars delivery letter local mail maps office package parcel post postal send shipping shopping stamp transportation truck vehicle",
    "description": "Local Shipping"
  },
  {
    "name": "local_taxi",
    "tagStr": "automobile cab call car cars direction local lyft maps public taxi transportation uber vehicle yellow",
    "description": "Local Taxi"
  },
  {
    "name": "location_city",
    "tagStr": "apartments architecture buildings business city estate home landscape location place real residence residential shelter town urban",
    "description": "Location City"
  },
  {
    "name": "location_disabled",
    "tagStr": "destination direction disabled enabled location maps off on pin place pointer slash stop tracking",
    "description": "Location Disabled"
  },
  {
    "name": "location_off",
    "tagStr": "destination direction disabled enabled location maps off on pin place slash stop",
    "description": "Location Off"
  },
  {
    "name": "location_on",
    "tagStr": "destination direction disabled enabled location maps off on pin place slash stop",
    "description": "Location On"
  },
  {
    "name": "location_searching",
    "tagStr": "destination direction location maps pin place pointer searching stop tracking",
    "description": "Location Searching"
  },
  {
    "name": "lock",
    "tagStr": "lock locked password privacy private protection safety secure security",
    "description": "Lock"
  },
  {
    "name": "lock_clock",
    "tagStr": "clock date lock locked password privacy private protection safety schedule secure security time",
    "description": "Lock Clock"
  },
  {
    "name": "lock_open",
    "tagStr": "lock open password privacy private protection safety secure security unlocked",
    "description": "Lock Open"
  },
  {
    "name": "login",
    "tagStr": "access app application arrow components design enter in interface left log login screen sign site ui ux web website",
    "description": "Login"
  },
  {
    "name": "logout",
    "tagStr": "app application arrow components design exit interface leave log login logout right screen site ui ux web website",
    "description": "Logout"
  },
  {
    "name": "looks",
    "tagStr": "circle half looks rainbow",
    "description": "Looks"
  },
  {
    "name": "looks_3",
    "tagStr": "3 digit looks numbers square symbol",
    "description": "Looks 3"
  },
  {
    "name": "looks_4",
    "tagStr": "4 digit looks numbers square symbol",
    "description": "Looks 4"
  },
  {
    "name": "looks_5",
    "tagStr": "5 digit looks numbers square symbol",
    "description": "Looks 5"
  },
  {
    "name": "looks_6",
    "tagStr": "6 digit looks numbers square symbol",
    "description": "Looks 6"
  },
  {
    "name": "looks_one",
    "tagStr": "1 digit looks numbers square symbol",
    "description": "Looks One"
  },
  {
    "name": "looks_two",
    "tagStr": "2 digit looks numbers square symbol",
    "description": "Looks Two"
  },
  {
    "name": "loop",
    "tagStr": "around arrow arrows direction inprogress load loading refresh loop music navigation renew rotate turn",
    "description": "Loop"
  },
  {
    "name": "loupe",
    "tagStr": "+ add details focus glass loupe magnifying new plus symbol",
    "description": "Loupe"
  },
  {
    "name": "low_priority",
    "tagStr": "arrange arrow backward bottom list low move order priority",
    "description": "Low Priority"
  },
  {
    "name": "loyalty",
    "tagStr": "card credit loyalty membership miles points program subscription heart tag travel trip",
    "description": "Loyalty"
  },
  {
    "name": "lte_mobiledata",
    "tagStr": "alphabet character data font internet letter lte mobile network speed symbol text type wifi wireless",
    "description": "Lte Mobiledata"
  },
  {
    "name": "lte_plus_mobiledata",
    "tagStr": "+ alphabet character data font internet letter lte mobile network plus speed symbol text type wifi wireless",
    "description": "Lte Plus Mobiledata"
  },
  {
    "name": "luggage",
    "tagStr": "airport bag baggage carry flight hotel luggage on suitcase travel trip",
    "description": "Luggage"
  },
  {
    "name": "lunch_dining",
    "tagStr": "breakfast dining dinner drink fastfood food hamburger lunch meal",
    "description": "Lunch Dining"
  },
  {
    "name": "mail",
    "tagStr": "email envelop envelope inbox letter mail message send",
    "description": "Mail"
  },
  {
    "name": "mail_outline",
    "tagStr": "email envelop letter mail message outline send",
    "description": "Mail Outline"
  },
  {
    "name": "male",
    "tagStr": "boy gender male man social symbol",
    "description": "Male"
  },
  {
    "name": "man",
    "tagStr": "",
    "description": "Man"
  },
  {
    "name": "manage_accounts",
    "tagStr": "accounts change details service-human face gear manage options people person profile settings user",
    "description": "Manage Accounts"
  },
  {
    "name": "manage_search",
    "tagStr": "glass history magnifying manage search text",
    "description": "Manage Search"
  },
  {
    "name": "map",
    "tagStr": "destination direction location map maps pin place route stop travel",
    "description": "Map"
  },
  {
    "name": "maps_home_work",
    "tagStr": "building home house maps office work",
    "description": "Maps Home Work"
  },
  {
    "name": "maps_ugc",
    "tagStr": "+ add bubble comment communicate feedback maps message new plus speech symbol ugc",
    "description": "Maps Ugc"
  },
  {
    "name": "margin",
    "tagStr": "design layout margin padding size square",
    "description": "Margin"
  },
  {
    "name": "mark_as_unread",
    "tagStr": "as envelop letter mail mark post postal read receive send unread",
    "description": "Mark As Unread"
  },
  {
    "name": "mark_chat_read",
    "tagStr": "approve bubble chat check comment communicate complete done mark message ok read select sent speech tick verified yes",
    "description": "Mark Chat Read"
  },
  {
    "name": "mark_chat_unread",
    "tagStr": "bubble chat circle comment communicate mark message notification speech unread",
    "description": "Mark Chat Unread"
  },
  {
    "name": "mark_email_read",
    "tagStr": "approve check complete done email envelop letter mail mark message note ok read select send sent tick yes",
    "description": "Mark Email Read"
  },
  {
    "name": "mark_email_unread",
    "tagStr": "check circle email envelop letter mail mark message note notification send unread",
    "description": "Mark Email Unread"
  },
  {
    "name": "markunread",
    "tagStr": "email envelop letter mail markunread message send unread",
    "description": "Markunread"
  },
  {
    "name": "markunread_mailbox",
    "tagStr": "deliver envelop letter mail mailbox markunread post postal postbox receive send unread",
    "description": "Markunread Mailbox"
  },
  {
    "name": "masks",
    "tagStr": "air cover covid face hospital masks medical pollution protection respirator sick social",
    "description": "Masks"
  },
  {
    "name": "maximize",
    "tagStr": "app application components design interface line maximize screen shape site ui ux web website",
    "description": "Maximize"
  },
  {
    "name": "media_bluetooth_off",
    "tagStr": "bluetooth connect connection connectivity device disabled enabled media music note off offline on paring signal slash symbol wireless",
    "description": "Media Bluetooth Off"
  },
  {
    "name": "media_bluetooth_on",
    "tagStr": "bluetooth connect connection connectivity device disabled enabled media music note off on online paring signal slash symbol wireless",
    "description": "Media Bluetooth On"
  },
  {
    "name": "mediation",
    "tagStr": "alternative compromise mediation negotiation party structure",
    "description": "Mediation"
  },
  {
    "name": "medical_services",
    "tagStr": "aid bag briefcase emergency first kit medical medicine services",
    "description": "Medical Services"
  },
  {
    "name": "medication",
    "tagStr": "doctor drug emergency hospital medication medicine pharmacy pills prescription",
    "description": "Medication"
  },
  {
    "name": "medication_liquid",
    "tagStr": "",
    "description": "Medication Liquid"
  },
  {
    "name": "meeting_room",
    "tagStr": "building door doorway entrance home house interior meeting office open places room",
    "description": "Meeting Room"
  },
  {
    "name": "memory",
    "tagStr": "card chip digital memory micro processor sd storage",
    "description": "Memory"
  },
  {
    "name": "menu",
    "tagStr": "app application components hamburger interface line lines menu screen site ui ux web website",
    "description": "Menu"
  },
  {
    "name": "menu_book",
    "tagStr": "book dining food meal menu restaurant",
    "description": "Menu Book"
  },
  {
    "name": "menu_open",
    "tagStr": "app application arrow components hamburger interface left line lines menu open screen site ui ux web website",
    "description": "Menu Open"
  },
  {
    "name": "merge_type",
    "tagStr": "arrow combine direction format merge text type",
    "description": "Merge Type"
  },
  {
    "name": "message",
    "tagStr": "bubble chat comment communicate feedback message speech",
    "description": "Message"
  },
  {
    "name": "mic",
    "tagStr": "hear hearing mic microphone noise record search sound speech voice",
    "description": "Mic"
  },
  {
    "name": "mic_external_off",
    "tagStr": "audio disabled enabled external mic microphone off on slash sound voice",
    "description": "Mic External Off"
  },
  {
    "name": "mic_external_on",
    "tagStr": "audio disabled enabled external mic microphone off on slash sound voice",
    "description": "Mic External On"
  },
  {
    "name": "mic_none",
    "tagStr": "hear hearing mic microphone noise none record sound voice",
    "description": "Mic None"
  },
  {
    "name": "mic_off",
    "tagStr": "audio disabled enabled hear hearing mic microphone noise off on record recording slash sound voice",
    "description": "Mic Off"
  },
  {
    "name": "microwave",
    "tagStr": "appliance cooking electric heat home house kitchen machine microwave",
    "description": "Microwave"
  },
  {
    "name": "military_tech",
    "tagStr": "army award badge honor medal merit military order privilege prize rank reward ribbon soldier star status tech trophy win winner",
    "description": "Military Tech"
  },
  {
    "name": "minimize",
    "tagStr": "app application components design interface line minimize screen shape site ui ux web website",
    "description": "Minimize"
  },
  {
    "name": "miscellaneous_services",
    "tagStr": "",
    "description": "Miscellaneous Services"
  },
  {
    "name": "missed_video_call",
    "tagStr": "arrow call camera film filming hardware image missed motion picture record video videography",
    "description": "Missed Video Call"
  },
  {
    "name": "mms",
    "tagStr": "bubble chat comment communicate feedback image landscape message mms mountain mountains multimedia photo photography picture speech",
    "description": "Mms"
  },
  {
    "name": "mobile_friendly",
    "tagStr": "Android OS approve cell check complete device done friendly hardware iOS mark mobile ok phone select tablet tick validate verified yes",
    "description": "Mobile Friendly"
  },
  {
    "name": "mobile_off",
    "tagStr": "Android OS cell device disabled enabled hardware iOS mobile off on phone silence slash tablet",
    "description": "Mobile Off"
  },
  {
    "name": "mobile_screen_share",
    "tagStr": "Android OS cast cell device hardware iOS mirror mobile monitor phone screen screencast share stream streaming tablet tv wireless",
    "description": "Mobile Screen Share"
  },
  {
    "name": "mobiledata_off",
    "tagStr": "arrow data disabled down enabled internet mobile network off on slash speed up wifi wireless",
    "description": "Mobiledata Off"
  },
  {
    "name": "mode",
    "tagStr": "compose create draft draw edit mode pen pencil write",
    "description": "Mode"
  },
  {
    "name": "mode_comment",
    "tagStr": "bubble chat comment communicate feedback message mode comment speech",
    "description": "Mode Comment"
  },
  {
    "name": "mode_edit",
    "tagStr": "compose create draft draw edit mode pen pencil write",
    "description": "Mode Edit"
  },
  {
    "name": "mode_edit_outline",
    "tagStr": "compose create draft draw edit mode outline pen pencil write",
    "description": "Mode Edit Outline"
  },
  {
    "name": "mode_night",
    "tagStr": "dark disturb mode moon night sleep weather",
    "description": "Mode Night"
  },
  {
    "name": "mode_of_travel",
    "tagStr": "",
    "description": "Mode Of Travel"
  },
  {
    "name": "mode_standby",
    "tagStr": "disturb mode power sleep standby target",
    "description": "Mode Standby"
  },
  {
    "name": "model_training",
    "tagStr": "arrow bulb idea inprogress light load loading model refresh renew restore reverse rotate training",
    "description": "Model Training"
  },
  {
    "name": "monetization_on",
    "tagStr": "bill card cash circle coin commerce cost credit currency dollars finance monetization money on online pay payment shopping symbol",
    "description": "Monetization On"
  },
  {
    "name": "money",
    "tagStr": "100 bill card cash coin commerce cost credit currency digit dollars finance money number online pay payment price shopping symbol",
    "description": "Money"
  },
  {
    "name": "money_off",
    "tagStr": "bill card cart cash coin commerce credit currency disabled dollars enabled money off on online pay payment shopping slash symbol",
    "description": "Money Off"
  },
  {
    "name": "money_off_csred",
    "tagStr": "bill card cart cash coin commerce credit csred currency disabled dollars enabled money off on online pay payment shopping slash symbol",
    "description": "Money Off Csred"
  },
  {
    "name": "monitor",
    "tagStr": "Android OS chrome device display hardware iOS mac monitor screen web window",
    "description": "Monitor"
  },
  {
    "name": "monitor_heart",
    "tagStr": "",
    "description": "Monitor Heart"
  },
  {
    "name": "monitor_weight",
    "tagStr": "body device diet health monitor scale smart weight",
    "description": "Monitor Weight"
  },
  {
    "name": "monochrome_photos",
    "tagStr": "black camera image monochrome photo photography photos picture white",
    "description": "Monochrome Photos"
  },
  {
    "name": "mood",
    "tagStr": "emoji emotions expressions face feelings glad happiness happy like mood person pleased smile smiling social survey",
    "description": "Mood"
  },
  {
    "name": "mood_bad",
    "tagStr": "bad disappointment dislike emoji emotions expressions face feelings mood person rating social survey unhappiness unhappy unpleased unsmile unsmiling",
    "description": "Mood Bad"
  },
  {
    "name": "moped",
    "tagStr": "automobile car cars direction maps moped motorized public scooter transportation vehicle vespa",
    "description": "Moped"
  },
  {
    "name": "more",
    "tagStr": "3 archive bookmark dots etc favorite indent label more remember save stamp sticker tab tag three",
    "description": "More"
  },
  {
    "name": "more_horiz",
    "tagStr": "3 app application components dots etc horiz horizontal interface ios more screen site three ui ux web website",
    "description": "More Horiz"
  },
  {
    "name": "more_time",
    "tagStr": "+ add clock date more new plus schedule symbol time",
    "description": "More Time"
  },
  {
    "name": "more_vert",
    "tagStr": "3 android app application components dots etc interface more screen site three ui ux vert vertical web website",
    "description": "More Vert"
  },
  {
    "name": "mosque",
    "tagStr": "",
    "description": "Mosque"
  },
  {
    "name": "motion_photos_auto",
    "tagStr": "A alphabet animation auto automatic character circle font gif letter live motion photos symbol text type video",
    "description": "Motion Photos Auto"
  },
  {
    "name": "motion_photos_off",
    "tagStr": "animation circle disabled enabled motion off on photos slash video",
    "description": "Motion Photos Off"
  },
  {
    "name": "motion_photos_on",
    "tagStr": "animation circle disabled enabled motion off on photos play slash video",
    "description": "Motion Photos On"
  },
  {
    "name": "motion_photos_pause",
    "tagStr": "",
    "description": "Motion Photos Pause"
  },
  {
    "name": "motion_photos_paused",
    "tagStr": "animation circle motion pause paused photos video",
    "description": "Motion Photos Paused"
  },
  {
    "name": "mouse",
    "tagStr": "click computer cursor device hardware mouse wireless",
    "description": "Mouse"
  },
  {
    "name": "move_to_inbox",
    "tagStr": "archive arrow down email envelop inbox incoming letter mail message move to send",
    "description": "Move To Inbox"
  },
  {
    "name": "movie",
    "tagStr": "cinema film media movie slate video",
    "description": "Movie"
  },
  {
    "name": "movie_creation",
    "tagStr": "clapperboard creation film movie movies slate video",
    "description": "Movie Creation"
  },
  {
    "name": "movie_filter",
    "tagStr": "clapperboard creation film filter movie movies slate stars video",
    "description": "Movie Filter"
  },
  {
    "name": "moving",
    "tagStr": "arrow direction moving navigation travel up",
    "description": "Moving"
  },
  {
    "name": "mp",
    "tagStr": "alphabet character font image letter megapixel mp photo photography pixels quality resolution symbol text type",
    "description": "Mp"
  },
  {
    "name": "multiline_chart",
    "tagStr": "analytics bar bars chart data diagram graph infographic line measure metrics multiple statistics tracking",
    "description": "Multiline Chart"
  },
  {
    "name": "multiple_stop",
    "tagStr": "arrows directions dots left maps multiple navigation right stop",
    "description": "Multiple Stop"
  },
  {
    "name": "museum",
    "tagStr": "architecture attraction building estate event exhibition explore local museum palces real see shop store tour",
    "description": "Museum"
  },
  {
    "name": "music_note",
    "tagStr": "audio audiotrack key music note sound track",
    "description": "Music Note"
  },
  {
    "name": "music_off",
    "tagStr": "audio audiotrack disabled enabled key music note off on slash sound track",
    "description": "Music Off"
  },
  {
    "name": "music_video",
    "tagStr": "band music mv recording screen tv video watch",
    "description": "Music Video"
  },
  {
    "name": "my_location",
    "tagStr": "destination direction location maps navigation pin place point stop",
    "description": "My Location"
  },
  {
    "name": "nat",
    "tagStr": "communication nat",
    "description": "Nat"
  },
  {
    "name": "nature",
    "tagStr": "forest nature outdoor outside park tree wilderness",
    "description": "Nature"
  },
  {
    "name": "nature_people",
    "tagStr": "activity body forest human nature outdoor outside park people person tree wilderness",
    "description": "Nature People"
  },
  {
    "name": "navigate_before",
    "tagStr": "arrow arrows before direction left navigate",
    "description": "Navigate Before"
  },
  {
    "name": "navigate_next",
    "tagStr": "arrow arrows direction navigate next right",
    "description": "Navigate Next"
  },
  {
    "name": "navigation",
    "tagStr": "destination direction location maps navigation pin place point stop",
    "description": "Navigation"
  },
  {
    "name": "near_me",
    "tagStr": "destination direction location maps me navigation near pin place point stop",
    "description": "Near Me"
  },
  {
    "name": "near_me_disabled",
    "tagStr": "destination direction disabled enabled location maps me navigation near off on pin place point slash",
    "description": "Near Me Disabled"
  },
  {
    "name": "nearby_error",
    "tagStr": "! alert attention caution danger error exclamation important mark nearby notification symbol warning",
    "description": "Nearby Error"
  },
  {
    "name": "nearby_off",
    "tagStr": "disabled enabled nearby off on slash",
    "description": "Nearby Off"
  },
  {
    "name": "network_cell",
    "tagStr": "cell cellular data internet mobile network phone speed wifi wireless",
    "description": "Network Cell"
  },
  {
    "name": "network_check",
    "tagStr": "check connect connection internet meter network signal speed tick wifi wireless",
    "description": "Network Check"
  },
  {
    "name": "network_locked",
    "tagStr": "alert available cellular connection data error internet lock locked mobile network not privacy private protection restricted safety secure security service signal warning wifi wireless",
    "description": "Network Locked"
  },
  {
    "name": "network_wifi",
    "tagStr": "cell cellular data internet mobile network phone speed wifi wireless",
    "description": "Network Wifi"
  },
  {
    "name": "new_label",
    "tagStr": "",
    "description": "New Label"
  },
  {
    "name": "new_releases",
    "tagStr": "! alert attention burst caution danger error exclamation important mark new notification release releases star symbol warning",
    "description": "New Releases"
  },
  {
    "name": "next_plan",
    "tagStr": "arrow circle next plan right",
    "description": "Next Plan"
  },
  {
    "name": "next_week",
    "tagStr": "arrow bag baggage briefcase business case next suitcase week",
    "description": "Next Week"
  },
  {
    "name": "nfc",
    "tagStr": "communication data field mobile near nfc wireless",
    "description": "Nfc"
  },
  {
    "name": "night_shelter",
    "tagStr": "architecture bed building estate homeless house night place real shelter sleep",
    "description": "Night Shelter"
  },
  {
    "name": "nightlife",
    "tagStr": "alcohol bar bottle club cocktail dance drink food glass liquor music nightlife note wine",
    "description": "Nightlife"
  },
  {
    "name": "nightlight",
    "tagStr": "dark disturb mode moon night nightlight sleep weather",
    "description": "Nightlight"
  },
  {
    "name": "nightlight_round",
    "tagStr": "dark half light mode moon night nightlight round",
    "description": "Nightlight Round"
  },
  {
    "name": "nights_stay",
    "tagStr": "cloud crescent dark mode moon nights phases silence silent sky stay time weather",
    "description": "Nights Stay"
  },
  {
    "name": "no_accounts",
    "tagStr": "account accounts avatar disabled enabled face human no off offline on people person profile slash thumbnail unavailable unidentifiable unknown user",
    "description": "No Accounts"
  },
  {
    "name": "no_backpack",
    "tagStr": "accessory backpack bag bookbag knapsack no pack travel",
    "description": "No Backpack"
  },
  {
    "name": "no_cell",
    "tagStr": "Android OS cell device disabled enabled hardware iOS mobile no off on phone slash tablet",
    "description": "No Cell"
  },
  {
    "name": "no_drinks",
    "tagStr": "alcohol beverage bottle cocktail drink drinks food liquor no wine",
    "description": "No Drinks"
  },
  {
    "name": "no_encryption",
    "tagStr": "disabled enabled encryption lock no off on password safety security slash",
    "description": "No Encryption"
  },
  {
    "name": "no_encryption_gmailerrorred",
    "tagStr": "disabled enabled encryption error gmail lock locked no off on slash",
    "description": "No Encryption Gmailerrorred"
  },
  {
    "name": "no_flash",
    "tagStr": "bolt camera disabled enabled flash image lightning no off on photo photography picture slash thunderbolt",
    "description": "No Flash"
  },
  {
    "name": "no_food",
    "tagStr": "disabled drink enabled fastfood food hamburger meal no off on slash",
    "description": "No Food"
  },
  {
    "name": "no_luggage",
    "tagStr": "bag baggage carry disabled enabled luggage no off on slash suitcase travel",
    "description": "No Luggage"
  },
  {
    "name": "no_meals",
    "tagStr": "dining disabled eat enabled food fork knife meal meals no off on restaurant slash spoon utensils",
    "description": "No Meals"
  },
  {
    "name": "no_meeting_room",
    "tagStr": "building disabled door doorway enabled entrance home house interior meeting no off office on open places room slash",
    "description": "No Meeting Room"
  },
  {
    "name": "no_photography",
    "tagStr": "camera disabled enabled image no off on photo photography picture slash",
    "description": "No Photography"
  },
  {
    "name": "no_sim",
    "tagStr": "camera card device eject insert memory no phone sim storage",
    "description": "No Sim"
  },
  {
    "name": "no_stroller",
    "tagStr": "baby care carriage child children disabled enabled infant kid newborn no off on parents slash stroller toddler young",
    "description": "No Stroller"
  },
  {
    "name": "no_transfer",
    "tagStr": "automobile bus car cars direction disabled enabled maps no off on public slash transfer transportation vehicle",
    "description": "No Transfer"
  },
  {
    "name": "nordic_walking",
    "tagStr": "athlete athletic body entertainment exercise hiking hobby human nordic people person social sports travel walker walking",
    "description": "Nordic Walking"
  },
  {
    "name": "north",
    "tagStr": "arrow directional maps navigation north up",
    "description": "North"
  },
  {
    "name": "north_east",
    "tagStr": "arrow east maps navigation noth right up",
    "description": "North East"
  },
  {
    "name": "north_west",
    "tagStr": "arrow directional left maps navigation north up west",
    "description": "North West"
  },
  {
    "name": "not_accessible",
    "tagStr": "accessibility accessible body handicap help human not person wheelchair",
    "description": "Not Accessible"
  },
  {
    "name": "not_interested",
    "tagStr": "cancel close dislike exit interested no not off quit remove stop x",
    "description": "Not Interested"
  },
  {
    "name": "not_listed_location",
    "tagStr": "? assistance destination direction help info information listed location maps not pin place punctuation question mark stop support symbol",
    "description": "Not Listed Location"
  },
  {
    "name": "not_started",
    "tagStr": "circle media not pause play started video",
    "description": "Not Started"
  },
  {
    "name": "note",
    "tagStr": "bookmark message note paper",
    "description": "Note"
  },
  {
    "name": "note_add",
    "tagStr": "+ -doc add data document drive file folder folders new note page paper plus sheet slide symbol writing",
    "description": "Note Add"
  },
  {
    "name": "note_alt",
    "tagStr": "alt clipboard document file memo note page paper writing",
    "description": "Note Alt"
  },
  {
    "name": "notes",
    "tagStr": "comment doc document note notes text write writing",
    "description": "Notes"
  },
  {
    "name": "notification_add",
    "tagStr": "+ active add alarm alert bell chime notification notifications notify plus reminder ring sound symbol",
    "description": "Notification Add"
  },
  {
    "name": "notification_important",
    "tagStr": "! active alarm alert attention bell caution chime danger error exclamation important mark notification notifications notify reminder ring sound symbol warning",
    "description": "Notification Important"
  },
  {
    "name": "notifications",
    "tagStr": "active alarm alert bell chime notifications notify reminder ring sound",
    "description": "Notifications"
  },
  {
    "name": "notifications_active",
    "tagStr": "active alarm alert bell chime notifications notify reminder ring ringing sound",
    "description": "Notifications Active"
  },
  {
    "name": "notifications_none",
    "tagStr": "alarm alert bell none notifications notify reminder sound",
    "description": "Notifications None"
  },
  {
    "name": "notifications_off",
    "tagStr": "active alarm alert bell chime disabled enabled notifications notify off offline on reminder ring slash sound",
    "description": "Notifications Off"
  },
  {
    "name": "notifications_paused",
    "tagStr": "active alarm alert bell chime ignore notifications notify paused quiet reminder ring --- pause sleep snooze sound z zzz",
    "description": "Notifications Paused"
  },
  {
    "name": "numbers",
    "tagStr": "",
    "description": "Numbers"
  },
  {
    "name": "offline_bolt",
    "tagStr": "bolt circle electric fast lightning offline thunderbolt",
    "description": "Offline Bolt"
  },
  {
    "name": "offline_pin",
    "tagStr": "approve check checkmark circle complete done mark offline ok pin select tick validate verified yes",
    "description": "Offline Pin"
  },
  {
    "name": "offline_share",
    "tagStr": "Android OS arrow cell connect device direction hardware iOS link mobile multiple offline phone right share tablet",
    "description": "Offline Share"
  },
  {
    "name": "ondemand_video",
    "tagStr": "Android OS chrome demand desktop device hardware iOS mac monitor ondemand play television tv video web window",
    "description": "Ondemand Video"
  },
  {
    "name": "online_prediction",
    "tagStr": "bulb connection idea light network online prediction signal wireless",
    "description": "Online Prediction"
  },
  {
    "name": "opacity",
    "tagStr": "color drop droplet hue invert inverted opacity palette tone water",
    "description": "Opacity"
  },
  {
    "name": "open_in_browser",
    "tagStr": "arrow browser in open site up web website window",
    "description": "Open In Browser"
  },
  {
    "name": "open_in_full",
    "tagStr": "action arrow arrows expand full grow in move open",
    "description": "Open In Full"
  },
  {
    "name": "open_in_new",
    "tagStr": "app application arrow box components in interface new open screen site ui ux web website window",
    "description": "Open In New"
  },
  {
    "name": "open_in_new_off",
    "tagStr": "arrow box disabled enabled export in new off on open slash window",
    "description": "Open In New Off"
  },
  {
    "name": "open_with",
    "tagStr": "arrow arrows direction expand move open pan with",
    "description": "Open With"
  },
  {
    "name": "other_houses",
    "tagStr": "architecture cottage estate home house houses maps other place real residence residential stay traveling",
    "description": "Other Houses"
  },
  {
    "name": "outbound",
    "tagStr": "",
    "description": "Outbound"
  },
  {
    "name": "outbox",
    "tagStr": "box mail outbox send sent",
    "description": "Outbox"
  },
  {
    "name": "outdoor_grill",
    "tagStr": "barbecue bbq charcoal cooking grill home house outdoor outside",
    "description": "Outdoor Grill"
  },
  {
    "name": "outlet",
    "tagStr": "connect connecter electricity outlet plug power",
    "description": "Outlet"
  },
  {
    "name": "outlined_flag",
    "tagStr": "country flag goal mark nation outlined report start",
    "description": "Outlined Flag"
  },
  {
    "name": "padding",
    "tagStr": "design layout margin padding size square",
    "description": "Padding"
  },
  {
    "name": "pages",
    "tagStr": "article gplus pages paper post star",
    "description": "Pages"
  },
  {
    "name": "pageview",
    "tagStr": "doc document find glass magnifying page paper search view",
    "description": "Pageview"
  },
  {
    "name": "paid",
    "tagStr": "circle currency money paid payment transaction",
    "description": "Paid"
  },
  {
    "name": "palette",
    "tagStr": "art color colors filters paint palette",
    "description": "Palette"
  },
  {
    "name": "pan_tool",
    "tagStr": "fingers gesture hand hands human move pan scan stop tool",
    "description": "Pan Tool"
  },
  {
    "name": "panorama",
    "tagStr": "angle image mountain mountains panorama photo photography picture view wide",
    "description": "Panorama"
  },
  {
    "name": "panorama_fish_eye",
    "tagStr": "angle circle eye fish image panorama photo photography picture wide",
    "description": "Panorama Fish Eye"
  },
  {
    "name": "panorama_horizontal",
    "tagStr": "angle horizontal image panorama photo photography picture wide",
    "description": "Panorama Horizontal"
  },
  {
    "name": "panorama_horizontal_select",
    "tagStr": "angle horizontal image panorama photo photography picture select wide",
    "description": "Panorama Horizontal Select"
  },
  {
    "name": "panorama_photosphere",
    "tagStr": "angle horizontal image panorama photo photography photosphere picture wide",
    "description": "Panorama Photosphere"
  },
  {
    "name": "panorama_photosphere_select",
    "tagStr": "angle horizontal image panorama photo photography photosphere picture select wide",
    "description": "Panorama Photosphere Select"
  },
  {
    "name": "panorama_vertical",
    "tagStr": "angle image panorama photo photography picture vertical wide",
    "description": "Panorama Vertical"
  },
  {
    "name": "panorama_vertical_select",
    "tagStr": "angle image panorama photo photography picture select vertical wide",
    "description": "Panorama Vertical Select"
  },
  {
    "name": "panorama_wide_angle",
    "tagStr": "angle image panorama photo photography picture wide",
    "description": "Panorama Wide Angle"
  },
  {
    "name": "panorama_wide_angle_select",
    "tagStr": "angle image panorama photo photography picture select wide",
    "description": "Panorama Wide Angle Select"
  },
  {
    "name": "paragliding",
    "tagStr": "athlete athletic body entertainment exercise fly gliding hobby human parachute paragliding people person sky skydiving social sports travel",
    "description": "Paragliding"
  },
  {
    "name": "park",
    "tagStr": "attraction fresh local nature outside park plant tree",
    "description": "Park"
  },
  {
    "name": "party_mode",
    "tagStr": "camera lens mode party photo photography picture",
    "description": "Party Mode"
  },
  {
    "name": "password",
    "tagStr": "key login password pin security star unlock",
    "description": "Password"
  },
  {
    "name": "pattern",
    "tagStr": "key login password pattern pin security star unlock",
    "description": "Pattern"
  },
  {
    "name": "pause",
    "tagStr": "control controls media music pause player video",
    "description": "Pause"
  },
  {
    "name": "pause_circle",
    "tagStr": "",
    "description": "Pause Circle"
  },
  {
    "name": "pause_circle_filled",
    "tagStr": "circle control controls filled media music pause video",
    "description": "Pause Circle Filled"
  },
  {
    "name": "pause_circle_outline",
    "tagStr": "circle control controls media music outline pause video",
    "description": "Pause Circle Outline"
  },
  {
    "name": "pause_presentation",
    "tagStr": "app application desktop device pause present presentation screen share site slides web website window www",
    "description": "Pause Presentation"
  },
  {
    "name": "payment",
    "tagStr": "bill card cash coin commerce cost credit currency dollars finance money online pay payment price shopping symbol",
    "description": "Payment"
  },
  {
    "name": "payments",
    "tagStr": "bill card cash coin commerce cost credit currency dollars finance layer money multiple online pay payment payments price shopping symbol",
    "description": "Payments"
  },
  {
    "name": "paypal",
    "tagStr": "",
    "description": "Paypal"
  },
  {
    "name": "pedal_bike",
    "tagStr": "automobile bicycle bike car cars maps pedal public transportation vehicle",
    "description": "Pedal Bike"
  },
  {
    "name": "pending",
    "tagStr": "circle dots loading pending progress wait waiting",
    "description": "Pending"
  },
  {
    "name": "pending_actions",
    "tagStr": "actions clipboard clock date doc document pending remember schedule time",
    "description": "Pending Actions"
  },
  {
    "name": "people",
    "tagStr": "accounts committee community face family friends group humans network people persons profiles social team users",
    "description": "People"
  },
  {
    "name": "people_alt",
    "tagStr": "accounts committee face family friends humans network people persons profiles social team users",
    "description": "People Alt"
  },
  {
    "name": "people_outline",
    "tagStr": "accounts committee face family friends humans network outline people persons profiles social team users",
    "description": "People Outline"
  },
  {
    "name": "perm_camera_mic",
    "tagStr": "camera image microphone min perm photo photography picture speaker",
    "description": "Perm Camera Mic"
  },
  {
    "name": "perm_contact_calendar",
    "tagStr": "account calendar contact date face human information people perm person profile schedule time user",
    "description": "Perm Contact Calendar"
  },
  {
    "name": "perm_data_setting",
    "tagStr": "data gear info information perm settings",
    "description": "Perm Data Setting"
  },
  {
    "name": "perm_device_information",
    "tagStr": "Android OS alert announcement device hardware i iOS info information mobile perm phone tablet",
    "description": "Perm Device Information"
  },
  {
    "name": "perm_identity",
    "tagStr": "account avatar face human identity people perm person profile thumbnail user",
    "description": "Perm Identity"
  },
  {
    "name": "perm_media",
    "tagStr": "collection data doc document file folder folders image landscape media mountain mountains perm photo photography picture storage",
    "description": "Perm Media"
  },
  {
    "name": "perm_phone_msg",
    "tagStr": "bubble call cell chat comment communicate contact device message msg perm phone recording speech telephone voice",
    "description": "Perm Phone Msg"
  },
  {
    "name": "perm_scan_wifi",
    "tagStr": "alert announcement connection info information internet network perm scan service signal wifi wireless",
    "description": "Perm Scan Wifi"
  },
  {
    "name": "person",
    "tagStr": "account face human people person profile user",
    "description": "Person"
  },
  {
    "name": "person_add",
    "tagStr": "+ account add avatar face friend human new people person plus profile symbol user",
    "description": "Person Add"
  },
  {
    "name": "person_add_alt",
    "tagStr": "+ account add face human people person plus profile user",
    "description": "Person Add Alt"
  },
  {
    "name": "person_add_alt_1",
    "tagStr": "",
    "description": "Person Add Alt 1"
  },
  {
    "name": "person_add_disabled",
    "tagStr": "+ account add disabled enabled face human new off offline on people person plus profile slash symbol user",
    "description": "Person Add Disabled"
  },
  {
    "name": "person_off",
    "tagStr": "account avatar disabled enabled face human off on people person profile slash user",
    "description": "Person Off"
  },
  {
    "name": "person_outline",
    "tagStr": "account face human outline people person profile user",
    "description": "Person Outline"
  },
  {
    "name": "person_pin",
    "tagStr": "account avatar destination direction face human location maps people person pin place profile stop user",
    "description": "Person Pin"
  },
  {
    "name": "person_pin_circle",
    "tagStr": "account circle destination direction face human location maps people person pin place profile stop user",
    "description": "Person Pin Circle"
  },
  {
    "name": "person_remove",
    "tagStr": "account avatar delete face human minus people person profile remove unfriend user",
    "description": "Person Remove"
  },
  {
    "name": "person_remove_alt_1",
    "tagStr": "",
    "description": "Person Remove Alt 1"
  },
  {
    "name": "person_search",
    "tagStr": "account avatar face find glass human look magnify magnifying people person profile search user",
    "description": "Person Search"
  },
  {
    "name": "personal_injury",
    "tagStr": "",
    "description": "Personal Injury"
  },
  {
    "name": "personal_video",
    "tagStr": "Android OS cam chrome desktop device hardware iOS mac monitor personal television tv video web window",
    "description": "Personal Video"
  },
  {
    "name": "pest_control",
    "tagStr": "bug control exterminator insects pest",
    "description": "Pest Control"
  },
  {
    "name": "pest_control_rodent",
    "tagStr": "control exterminator mice pest rodent",
    "description": "Pest Control Rodent"
  },
  {
    "name": "pets",
    "tagStr": "animal cat dog hand paw pet",
    "description": "Pets"
  },
  {
    "name": "phone",
    "tagStr": "call cell contact device hardware mobile phone telephone",
    "description": "Phone"
  },
  {
    "name": "phone_android",
    "tagStr": "OS android cell device hardware iOS mobile phone tablet",
    "description": "Phone Android"
  },
  {
    "name": "phone_bluetooth_speaker",
    "tagStr": "bluetooth call cell connect connection connectivity contact device hardware mobile phone signal speaker symbol telephone wireless",
    "description": "Phone Bluetooth Speaker"
  },
  {
    "name": "phone_callback",
    "tagStr": "arrow call callback cell contact device down hardware mobile phone telephone",
    "description": "Phone Callback"
  },
  {
    "name": "phone_disabled",
    "tagStr": "call cell contact device disabled enabled hardware mobile off offline on phone slash telephone",
    "description": "Phone Disabled"
  },
  {
    "name": "phone_enabled",
    "tagStr": "call cell contact device enabled hardware mobile phone telephone",
    "description": "Phone Enabled"
  },
  {
    "name": "phone_forwarded",
    "tagStr": "arrow call cell contact device direction forwarded hardware mobile phone right telephone",
    "description": "Phone Forwarded"
  },
  {
    "name": "phone_in_talk",
    "tagStr": "call cell connection contact data device hardware in mobile network phone scan service signal sound speaker talk telephone waves wireless",
    "description": "Phone In Talk"
  },
  {
    "name": "phone_iphone",
    "tagStr": "Android OS cell device hardware iOS iphone mobile phone tablet",
    "description": "Phone Iphone"
  },
  {
    "name": "phone_locked",
    "tagStr": "call cell contact device hardware lock locked mobile password phone privacy private protection safety secure security telephone",
    "description": "Phone Locked"
  },
  {
    "name": "phone_missed",
    "tagStr": "arrow call cell contact device hardware missed mobile phone telephone",
    "description": "Phone Missed"
  },
  {
    "name": "phone_paused",
    "tagStr": "call cell contact device hardware mobile pause paused phone telephone",
    "description": "Phone Paused"
  },
  {
    "name": "phonelink",
    "tagStr": "Android OS chrome computer connect desktop device hardware iOS link mac mobile phone phonelink sync tablet web windows",
    "description": "Phonelink"
  },
  {
    "name": "phonelink_erase",
    "tagStr": "Android OS cancel cell close connection device erase exit hardware iOS mobile no phone phonelink remove stop tablet x",
    "description": "Phonelink Erase"
  },
  {
    "name": "phonelink_lock",
    "tagStr": "Android OS cell connection device erase hardware iOS lock locked mobile password phone phonelink privacy private protection safety secure security tablet",
    "description": "Phonelink Lock"
  },
  {
    "name": "phonelink_off",
    "tagStr": "Android OS chrome computer connect desktop device disabled enabled hardware iOS link mac mobile off on phone phonelink slash sync tablet web windows",
    "description": "Phonelink Off"
  },
  {
    "name": "phonelink_ring",
    "tagStr": "Android OS cell connection data device hardware iOS mobile network phone phonelink ring service signal tablet wireless",
    "description": "Phonelink Ring"
  },
  {
    "name": "phonelink_setup",
    "tagStr": "Android OS call chat device hardware iOS info mobile phone phonelink settings setup tablet text",
    "description": "Phonelink Setup"
  },
  {
    "name": "photo",
    "tagStr": "image mountain mountains photo photography picture",
    "description": "Photo"
  },
  {
    "name": "photo_album",
    "tagStr": "album archive bookmark image label library mountain mountains photo photography picture ribbon save tag",
    "description": "Photo Album"
  },
  {
    "name": "photo_camera",
    "tagStr": "camera image photo photography picture",
    "description": "Photo Camera"
  },
  {
    "name": "photo_camera_back",
    "tagStr": "back camera image landscape mountain mountains photo photography picture rear",
    "description": "Photo Camera Back"
  },
  {
    "name": "photo_camera_front",
    "tagStr": "account camera face front human image people person photo photography picture portrait profile user",
    "description": "Photo Camera Front"
  },
  {
    "name": "photo_filter",
    "tagStr": "filter filters image photo photography picture star stars",
    "description": "Photo Filter"
  },
  {
    "name": "photo_library",
    "tagStr": "album image library mountain mountains photo photography picture",
    "description": "Photo Library"
  },
  {
    "name": "photo_size_select_actual",
    "tagStr": "actual image mountain mountains photo photography picture select size",
    "description": "Photo Size Select Actual"
  },
  {
    "name": "photo_size_select_large",
    "tagStr": "adjust album edit editing image large library mountain mountains photo photography picture select size",
    "description": "Photo Size Select Large"
  },
  {
    "name": "photo_size_select_small",
    "tagStr": "adjust album edit editing image large library mountain mountains photo photography picture select size small",
    "description": "Photo Size Select Small"
  },
  {
    "name": "piano",
    "tagStr": "instrument keyboard keys music musical piano social",
    "description": "Piano"
  },
  {
    "name": "piano_off",
    "tagStr": "disabled enabled instrument keyboard keys music musical off on piano slash social",
    "description": "Piano Off"
  },
  {
    "name": "picture_as_pdf",
    "tagStr": "alphabet as character document file font image letter multiple pdf photo photography picture symbol text type",
    "description": "Picture As Pdf"
  },
  {
    "name": "picture_in_picture",
    "tagStr": "crop cropped overlap photo picture position shape",
    "description": "Picture In Picture"
  },
  {
    "name": "picture_in_picture_alt",
    "tagStr": "crop cropped overlap photo picture position shape",
    "description": "Picture In Picture Alt"
  },
  {
    "name": "pie_chart",
    "tagStr": "analytics bar bars chart data diagram graph infographic measure metrics pie statistics tracking",
    "description": "Pie Chart"
  },
  {
    "name": "pie_chart_outline",
    "tagStr": "analytics bar bars chart data diagram graph infographic measure metrics outline pie statistics tracking",
    "description": "Pie Chart Outline"
  },
  {
    "name": "pin",
    "tagStr": "1 2 3 digit key login logout number password pattern pin security star symbol unlock",
    "description": "Pin"
  },
  {
    "name": "pin_drop",
    "tagStr": "destination direction drop location maps navigation pin place stop",
    "description": "Pin Drop"
  },
  {
    "name": "pin_end",
    "tagStr": "",
    "description": "Pin End"
  },
  {
    "name": "pin_invoke",
    "tagStr": "",
    "description": "Pin Invoke"
  },
  {
    "name": "pivot_table_chart",
    "tagStr": "analytics arrow arrows bar bars chart data diagram direction drive edit editing graph grid infographic measure metrics pivot rotate sheet statistics table tracking",
    "description": "Pivot Table Chart"
  },
  {
    "name": "pix",
    "tagStr": "",
    "description": "Pix"
  },
  {
    "name": "place",
    "tagStr": "destination direction location maps navigation pin place point stop",
    "description": "Place"
  },
  {
    "name": "plagiarism",
    "tagStr": "doc document find glass look magnifying page paper plagiarism search see",
    "description": "Plagiarism"
  },
  {
    "name": "play_arrow",
    "tagStr": "arrow control controls media music play player start video",
    "description": "Play Arrow"
  },
  {
    "name": "play_circle",
    "tagStr": "",
    "description": "Play Circle"
  },
  {
    "name": "play_circle_filled",
    "tagStr": "arrow circle control controls media music play video",
    "description": "Play Circle Filled"
  },
  {
    "name": "play_circle_outline",
    "tagStr": "arrow circle control controls media music outline play video",
    "description": "Play Circle Outline"
  },
  {
    "name": "play_disabled",
    "tagStr": "control controls disabled enabled media music off on play slash video",
    "description": "Play Disabled"
  },
  {
    "name": "play_for_work",
    "tagStr": "arrow circle down google half play work",
    "description": "Play For Work"
  },
  {
    "name": "play_lesson",
    "tagStr": "audio book bookmark digital ebook lesson multimedia play play lesson read reading ribbon",
    "description": "Play Lesson"
  },
  {
    "name": "playlist_add",
    "tagStr": "+ add collection list music new playlist plus symbol",
    "description": "Playlist Add"
  },
  {
    "name": "playlist_add_check",
    "tagStr": "add approve check collection complete done list mark music ok playlist select tick validate verified yes",
    "description": "Playlist Add Check"
  },
  {
    "name": "playlist_add_check_circle",
    "tagStr": "",
    "description": "Playlist Add Check Circle"
  },
  {
    "name": "playlist_add_circle",
    "tagStr": "",
    "description": "Playlist Add Circle"
  },
  {
    "name": "playlist_play",
    "tagStr": "arow collection list music play playlist",
    "description": "Playlist Play"
  },
  {
    "name": "plumbing",
    "tagStr": "build construction fix handyman plumbing repair tools wrench",
    "description": "Plumbing"
  },
  {
    "name": "plus_one",
    "tagStr": "1 add digit increase number one plus symbol",
    "description": "Plus One"
  },
  {
    "name": "podcasts",
    "tagStr": "broadcast casting network podcasts signal transmitting wireless",
    "description": "Podcasts"
  },
  {
    "name": "point_of_sale",
    "tagStr": "checkout cost machine merchant money of pay payment point pos retail sale system transaction",
    "description": "Point Of Sale"
  },
  {
    "name": "policy",
    "tagStr": "certified find glass legal look magnify magnifying policy privacy private protect protection search security see shield verified",
    "description": "Policy"
  },
  {
    "name": "poll",
    "tagStr": "analytics bar bars chart data diagram graph infographic measure metrics poll statistics survey tracking vote",
    "description": "Poll"
  },
  {
    "name": "polymer",
    "tagStr": "emblem logo mark polymer",
    "description": "Polymer"
  },
  {
    "name": "pool",
    "tagStr": "athlete athletic beach body entertainment exercise hobby human ocean people person places pool sea sports swim swimming water",
    "description": "Pool"
  },
  {
    "name": "portable_wifi_off",
    "tagStr": "connection data disabled enabled internet network off offline on portable service signal slash wifi wireless",
    "description": "Portable Wifi Off"
  },
  {
    "name": "portrait",
    "tagStr": "account face human people person photo picture portrait profile user",
    "description": "Portrait"
  },
  {
    "name": "post_add",
    "tagStr": "+ add data doc document drive file folder folders page paper plus post sheet slide text writing",
    "description": "Post Add"
  },
  {
    "name": "power",
    "tagStr": "charge cord electric electrical outlet plug power",
    "description": "Power"
  },
  {
    "name": "power_input",
    "tagStr": "input lines power supply",
    "description": "Power Input"
  },
  {
    "name": "power_off",
    "tagStr": "charge cord disabled electric electrical enabled off on outlet plug power slash",
    "description": "Power Off"
  },
  {
    "name": "power_settings_new",
    "tagStr": "info information off on power save settings shutdown",
    "description": "Power Settings New"
  },
  {
    "name": "precision_manufacturing",
    "tagStr": "arm automatic chain conveyor crane factory industry machinery manufacturing mechanical precision production repairing robot supply warehouse",
    "description": "Precision Manufacturing"
  },
  {
    "name": "pregnant_woman",
    "tagStr": "baby birth body female human lady maternity mom mother people person pregnant women",
    "description": "Pregnant Woman"
  },
  {
    "name": "present_to_all",
    "tagStr": "all arrow present presentation screen share site slides to web website",
    "description": "Present To All"
  },
  {
    "name": "preview",
    "tagStr": "design eye layout preview reveal screen see show site view web website window www",
    "description": "Preview"
  },
  {
    "name": "price_change",
    "tagStr": "arrows bill card cash change coin commerce cost credit currency dollars down finance money online pay payment price shopping symbol up",
    "description": "Price Change"
  },
  {
    "name": "price_check",
    "tagStr": "approve bill card cash check coin commerce complete cost credit currency dollars done finance mark money ok online pay payment price select shopping symbol tick validate verified yes",
    "description": "Price Check"
  },
  {
    "name": "print",
    "tagStr": "draft fax ink machine office paper print printer send",
    "description": "Print"
  },
  {
    "name": "print_disabled",
    "tagStr": "disabled enabled off on paper print printer slash",
    "description": "Print Disabled"
  },
  {
    "name": "priority_high",
    "tagStr": "! alert attention caution danger error exclamation high important mark notification priority symbol warning",
    "description": "Priority High"
  },
  {
    "name": "privacy_tip",
    "tagStr": "alert announcment assistance certified details help i info information privacy private protect protection security service shield support tip verified",
    "description": "Privacy Tip"
  },
  {
    "name": "private_connectivity",
    "tagStr": "",
    "description": "Private Connectivity"
  },
  {
    "name": "production_quantity_limits",
    "tagStr": "! alert attention bill card cart cash caution coin commerce credit currency danger dollars error exclamation important limits mark money notification online pay payment production quantity shopping symbol warning",
    "description": "Production Quantity Limits"
  },
  {
    "name": "psychology",
    "tagStr": "behavior body brain cognitive function gear head human intellectual mental mind people person preferences psychiatric psychology science settings social therapy thinking thoughts",
    "description": "Psychology"
  },
  {
    "name": "public",
    "tagStr": "earth global globe map network planet public social space web world",
    "description": "Public"
  },
  {
    "name": "public_off",
    "tagStr": "disabled earth enabled global globe map network off on planet public slash social space web world",
    "description": "Public Off"
  },
  {
    "name": "publish",
    "tagStr": "arrow cloud file import publish up upload",
    "description": "Publish"
  },
  {
    "name": "published_with_changes",
    "tagStr": "approve arrow arrows changes check complete done inprogress load loading mark ok published refresh renew replace rotate select tick validate verified with yes",
    "description": "Published With Changes"
  },
  {
    "name": "punch_clock",
    "tagStr": "",
    "description": "Punch Clock"
  },
  {
    "name": "push_pin",
    "tagStr": "location marker pin place push remember save",
    "description": "Push Pin"
  },
  {
    "name": "qr_code",
    "tagStr": "barcode camera code media product qr quick response smartphone url urls",
    "description": "Qr Code"
  },
  {
    "name": "qr_code_2",
    "tagStr": "barcode camera code media product qr quick response smartphone url urls",
    "description": "Qr Code 2"
  },
  {
    "name": "qr_code_scanner",
    "tagStr": "barcode camera code media product qr quick response scanner smartphone url urls",
    "description": "Qr Code Scanner"
  },
  {
    "name": "query_builder",
    "tagStr": "builder clock date query schedule time",
    "description": "Query Builder"
  },
  {
    "name": "query_stats",
    "tagStr": "",
    "description": "Query Stats"
  },
  {
    "name": "question_answer",
    "tagStr": "answer bubble chat comment communicate conversation feedback message question speech talk",
    "description": "Question Answer"
  },
  {
    "name": "queue",
    "tagStr": "add collection layers list multiple music playlist queue stack stream video",
    "description": "Queue"
  },
  {
    "name": "queue_music",
    "tagStr": "collection list music playlist queue",
    "description": "Queue Music"
  },
  {
    "name": "queue_play_next",
    "tagStr": "+ add arrow desktop device display hardware monitor new next play plus queue screen steam symbol tv",
    "description": "Queue Play Next"
  },
  {
    "name": "quickreply",
    "tagStr": "bolt bubble chat comment communicate fast lightning message quick quickreply reply speech thunderbolt",
    "description": "Quickreply"
  },
  {
    "name": "quiz",
    "tagStr": "? assistance faq help info information punctuation question mark quiz support symbol test",
    "description": "Quiz"
  },
  {
    "name": "quora",
    "tagStr": "",
    "description": "Quora"
  },
  {
    "name": "r_mobiledata",
    "tagStr": "alphabet character data font letter mobile r symbol text type",
    "description": "R Mobiledata"
  },
  {
    "name": "radar",
    "tagStr": "detect military near network position radar scan",
    "description": "Radar"
  },
  {
    "name": "radio",
    "tagStr": "antenna audio device frequency hardware listen media music player radio signal tune",
    "description": "Radio"
  },
  {
    "name": "radio_button_checked",
    "tagStr": "app application bullet button checked circle components design form interface off on point radio record screen select selected site toggle ui ux web website",
    "description": "Radio Button Checked"
  },
  {
    "name": "radio_button_unchecked",
    "tagStr": "bullet button circle deselected form off on point radio record select toggle unchecked",
    "description": "Radio Button Unchecked"
  },
  {
    "name": "railway_alert",
    "tagStr": "! alert attention automobile bike car cars caution danger direction error exclamation important maps mark notification public railway scooter subway symbol train transportation vehicle vespa warning",
    "description": "Railway Alert"
  },
  {
    "name": "ramen_dining",
    "tagStr": "breakfast dining dinner drink fastfood food lunch meal noodles ramen restaurant",
    "description": "Ramen Dining"
  },
  {
    "name": "rate_review",
    "tagStr": "comment feedback pen pencil rate review stars write",
    "description": "Rate Review"
  },
  {
    "name": "raw_off",
    "tagStr": "alphabet character disabled enabled font image letter off on original photo photography raw slash symbol text type",
    "description": "Raw Off"
  },
  {
    "name": "raw_on",
    "tagStr": "alphabet character disabled enabled font image letter off on original photo photography raw slash symbol text type",
    "description": "Raw On"
  },
  {
    "name": "read_more",
    "tagStr": "arrow more read text",
    "description": "Read More"
  },
  {
    "name": "real_estate_agent",
    "tagStr": "",
    "description": "Real Estate Agent"
  },
  {
    "name": "receipt",
    "tagStr": "bill credit invoice paper payment receipt sale",
    "description": "Receipt"
  },
  {
    "name": "receipt_long",
    "tagStr": "bill check document list long paper paperwork receipt record store transaction",
    "description": "Receipt Long"
  },
  {
    "name": "recent_actors",
    "tagStr": "account actors avatar card cards carousel face human layers list people person profile recent thumbnail user",
    "description": "Recent Actors"
  },
  {
    "name": "recommend",
    "tagStr": "approved circle confirm favorite gesture hand like reaction recommend social support thumbs up well",
    "description": "Recommend"
  },
  {
    "name": "record_voice_over",
    "tagStr": "account face human over people person profile record recording speak speaking speech transcript user voice",
    "description": "Record Voice Over"
  },
  {
    "name": "recycling",
    "tagStr": "",
    "description": "Recycling"
  },
  {
    "name": "reddit",
    "tagStr": "",
    "description": "Reddit"
  },
  {
    "name": "redeem",
    "tagStr": "bill card cart cash certificate coin commerce credit currency dollars gift giftcard money online pay payment present redeem shopping",
    "description": "Redeem"
  },
  {
    "name": "redo",
    "tagStr": "arrow backward forward next redo repeat rotate undo",
    "description": "Redo"
  },
  {
    "name": "reduce_capacity",
    "tagStr": "arrow body capacity covid decrease down human people person reduce social",
    "description": "Reduce Capacity"
  },
  {
    "name": "refresh",
    "tagStr": "around arrow arrows direction inprogress load loading refresh navigation refresh renew right rotate turn",
    "description": "Refresh"
  },
  {
    "name": "remember_me",
    "tagStr": "Android OS avatar device hardware human iOS identity me mobile people person phone profile remember tablet user",
    "description": "Remember Me"
  },
  {
    "name": "remove",
    "tagStr": "can delete minus negative remove substract trash",
    "description": "Remove"
  },
  {
    "name": "remove_circle",
    "tagStr": "block can circle delete minus negative remove substract trash",
    "description": "Remove Circle"
  },
  {
    "name": "remove_circle_outline",
    "tagStr": "block can circle delete minus negative outline remove substract trash",
    "description": "Remove Circle Outline"
  },
  {
    "name": "remove_done",
    "tagStr": "approve check complete disabled done enabled finished mark multiple off ok on remove select slash tick yes",
    "description": "Remove Done"
  },
  {
    "name": "remove_from_queue",
    "tagStr": "desktop device display from hardware monitor queue remove screen steam",
    "description": "Remove From Queue"
  },
  {
    "name": "remove_moderator",
    "tagStr": "certified disabled enabled moderator off on privacy private protect protection remove security shield slash verified",
    "description": "Remove Moderator"
  },
  {
    "name": "remove_red_eye",
    "tagStr": "eye iris look looking preview red remove see sight vision",
    "description": "Remove Red Eye"
  },
  {
    "name": "remove_shopping_cart",
    "tagStr": "card cart cash checkout coin commerce credit currency disabled dollars enabled off on online pay payment remove shopping slash tick",
    "description": "Remove Shopping Cart"
  },
  {
    "name": "reorder",
    "tagStr": "format lines list order reorder stacked",
    "description": "Reorder"
  },
  {
    "name": "repeat",
    "tagStr": "arrow arrows control controls media music repeat video",
    "description": "Repeat"
  },
  {
    "name": "repeat_on",
    "tagStr": "arrow arrows control controls media music on repeat video",
    "description": "Repeat On"
  },
  {
    "name": "repeat_one",
    "tagStr": "1 arrow arrows control controls digit media music number one repeat symbol video",
    "description": "Repeat One"
  },
  {
    "name": "repeat_one_on",
    "tagStr": "arrow arrows control controls digit media music number on one repeat symbol video",
    "description": "Repeat One On"
  },
  {
    "name": "replay",
    "tagStr": "arrow arrows control controls music refresh renew repeat replay video",
    "description": "Replay"
  },
  {
    "name": "replay_10",
    "tagStr": "10 arrow arrows control controls digit music number refresh renew repeat replay symbol ten video",
    "description": "Replay 10"
  },
  {
    "name": "replay_30",
    "tagStr": "30 arrow arrows control controls digit music number refresh renew repeat replay symbol thirty video",
    "description": "Replay 30"
  },
  {
    "name": "replay_5",
    "tagStr": "5 arrow arrows control controls digit five music number refresh renew repeat replay symbol video",
    "description": "Replay 5"
  },
  {
    "name": "replay_circle_filled",
    "tagStr": "arrow arrows circle control controls filled music refresh renew repeat replay video",
    "description": "Replay Circle Filled"
  },
  {
    "name": "reply",
    "tagStr": "arrow backward left mail message reply send share",
    "description": "Reply"
  },
  {
    "name": "reply_all",
    "tagStr": "all arrow backward group left mail message multiple reply send share",
    "description": "Reply All"
  },
  {
    "name": "report",
    "tagStr": "! alert attention caution danger error exclamation important mark notification octagon report symbol warning",
    "description": "Report"
  },
  {
    "name": "report_gmailerrorred",
    "tagStr": "! alert attention caution danger error exclamation gmail gmailerrorred important mark notification octagon report symbol warning",
    "description": "Report Gmailerrorred"
  },
  {
    "name": "report_off",
    "tagStr": "! alert attention caution danger disabled enabled error exclamation important mark notification octagon off offline on report slash symbol warning",
    "description": "Report Off"
  },
  {
    "name": "report_problem",
    "tagStr": "! alert attention caution danger error exclamation feedback important mark notification problem report symbol triangle warning",
    "description": "Report Problem"
  },
  {
    "name": "request_page",
    "tagStr": "",
    "description": "Request Page"
  },
  {
    "name": "request_quote",
    "tagStr": "bill card cash coin commerce cost credit currency dollars finance money online pay payment price quote request shopping symbol",
    "description": "Request Quote"
  },
  {
    "name": "reset_tv",
    "tagStr": "arrow device hardware monitor reset television tv",
    "description": "Reset Tv"
  },
  {
    "name": "restart_alt",
    "tagStr": "alt around arrow inprogress load loading refresh reboot renew repeat reset restart",
    "description": "Restart Alt"
  },
  {
    "name": "restaurant",
    "tagStr": "breakfast dining dinner eat food fork knife local lunch meal places restaurant spoon utensils",
    "description": "Restaurant"
  },
  {
    "name": "restaurant_menu",
    "tagStr": "book dining eat food fork knife local meal menu restaurant spoon",
    "description": "Restaurant Menu"
  },
  {
    "name": "restore",
    "tagStr": "arrow back backwards clock date history refresh renew restore reverse rotate schedule time turn",
    "description": "Restore"
  },
  {
    "name": "restore_from_trash",
    "tagStr": "arrow back backwards clock date history refresh renew restore reverse rotate schedule time turn",
    "description": "Restore From Trash"
  },
  {
    "name": "restore_page",
    "tagStr": "arrow data doc file page paper refresh restore rotate sheet storage",
    "description": "Restore Page"
  },
  {
    "name": "reviews",
    "tagStr": "bubble chat comment communicate feedback message rate rating recommendation reviews speech",
    "description": "Reviews"
  },
  {
    "name": "rice_bowl",
    "tagStr": "bowl dinner food lunch meal restaurant rice",
    "description": "Rice Bowl"
  },
  {
    "name": "ring_volume",
    "tagStr": "call calling cell contact device hardware incoming mobile phone ring ringer sound telephone volume",
    "description": "Ring Volume"
  },
  {
    "name": "roofing",
    "tagStr": "architecture building chimney construction estate home house real residence residential roof roofing service shelter",
    "description": "Roofing"
  },
  {
    "name": "room",
    "tagStr": "destination direction location maps pin place room stop",
    "description": "Room"
  },
  {
    "name": "room_preferences",
    "tagStr": "building door doorway entrance gear home house interior office open preferences room settings",
    "description": "Room Preferences"
  },
  {
    "name": "room_service",
    "tagStr": "alert bell delivery hotel notify room service",
    "description": "Room Service"
  },
  {
    "name": "rotate_90_degrees_ccw",
    "tagStr": "90 arrow arrows ccw degrees direction edit editing image photo rotate turn",
    "description": "Rotate 90 Degrees Ccw"
  },
  {
    "name": "rotate_90_degrees_cw",
    "tagStr": "",
    "description": "Rotate 90 Degrees Cw"
  },
  {
    "name": "rotate_left",
    "tagStr": "around arrow direction inprogress left load loading refresh renew rotate turn",
    "description": "Rotate Left"
  },
  {
    "name": "rotate_right",
    "tagStr": "around arrow direction inprogress load loading refresh renew right rotate turn",
    "description": "Rotate Right"
  },
  {
    "name": "rounded_corner",
    "tagStr": "adjust corner edit rounded shape square transform",
    "description": "Rounded Corner"
  },
  {
    "name": "route",
    "tagStr": "",
    "description": "Route"
  },
  {
    "name": "router",
    "tagStr": "box cable connection hardware internet network router signal wifi",
    "description": "Router"
  },
  {
    "name": "rowing",
    "tagStr": "activity boat body canoe human people person row rowing sport water",
    "description": "Rowing"
  },
  {
    "name": "rss_feed",
    "tagStr": "application blog connection data feed internet network rss service signal website wifi wireless",
    "description": "Rss Feed"
  },
  {
    "name": "rsvp",
    "tagStr": "alphabet character font invitation invite letter plaît respond rsvp répondez sil symbol text type vous",
    "description": "Rsvp"
  },
  {
    "name": "rtt",
    "tagStr": "call real rrt text time",
    "description": "Rtt"
  },
  {
    "name": "rule",
    "tagStr": "approve check complete done incomplete line mark missing no ok rule select tick validate verified wrong x yes",
    "description": "Rule"
  },
  {
    "name": "rule_folder",
    "tagStr": "approve cancel check close complete data doc document done drive exit file folder mark no ok remove rule select sheet slide storage tick validate verified x yes",
    "description": "Rule Folder"
  },
  {
    "name": "run_circle",
    "tagStr": "body circle exercise human people person run running",
    "description": "Run Circle"
  },
  {
    "name": "running_with_errors",
    "tagStr": "! alert attention caution danger duration error errors exclamation important mark notification process processing running symbol time warning with",
    "description": "Running With Errors"
  },
  {
    "name": "rv_hookup",
    "tagStr": "arrow attach automobile automotive back car cars connect direction hookup left maps public right rv trailer transportation travel truck van vehicle",
    "description": "Rv Hookup"
  },
  {
    "name": "safety_divider",
    "tagStr": "apart distance divider safety separate social space",
    "description": "Safety Divider"
  },
  {
    "name": "sailing",
    "tagStr": "boat entertainment fishing hobby ocean sailboat sailing sea social sports travel water",
    "description": "Sailing"
  },
  {
    "name": "sanitizer",
    "tagStr": "bacteria bottle clean covid disinfect germs pump sanitizer",
    "description": "Sanitizer"
  },
  {
    "name": "satellite",
    "tagStr": "bluetooth connect connection connectivity data device image internet landscape location maps mountain mountains network photo photography picture satellite scan service signal symbol wireless-- wifi",
    "description": "Satellite"
  },
  {
    "name": "save",
    "tagStr": "data disk document drive file floppy multimedia save storage",
    "description": "Save"
  },
  {
    "name": "save_alt",
    "tagStr": "alt arrow disk document down file floppy multimedia save",
    "description": "Save Alt"
  },
  {
    "name": "saved_search",
    "tagStr": "find glass important look magnify magnifying marked saved search see star",
    "description": "Saved Search"
  },
  {
    "name": "savings",
    "tagStr": "bank bill card cash coin commerce cost credit currency dollars finance money online pay payment pig piggy savings symbol",
    "description": "Savings"
  },
  {
    "name": "scanner",
    "tagStr": "copy device hardware machine scan scanner",
    "description": "Scanner"
  },
  {
    "name": "scatter_plot",
    "tagStr": "analytics bar bars chart circles data diagram dot graph infographic measure metrics plot scatter statistics tracking",
    "description": "Scatter Plot"
  },
  {
    "name": "schedule",
    "tagStr": "clock date schedule time",
    "description": "Schedule"
  },
  {
    "name": "schedule_send",
    "tagStr": "calendar clock date email letter mail remember schedule send share time",
    "description": "Schedule Send"
  },
  {
    "name": "schema",
    "tagStr": "",
    "description": "Schema"
  },
  {
    "name": "school",
    "tagStr": "academy achievement cap class college education graduation hat knowledge learning school university",
    "description": "School"
  },
  {
    "name": "science",
    "tagStr": "beaker chemical chemistry experiment flask glass laboratory research science tube",
    "description": "Science"
  },
  {
    "name": "score",
    "tagStr": "2k alphabet analytics bar bars character chart data diagram digit font graph infographic letter measure metrics number score statistics symbol text tracking type",
    "description": "Score"
  },
  {
    "name": "screen_lock_landscape",
    "tagStr": "Android OS device hardware iOS landscape lock mobile phone rotate screen tablet",
    "description": "Screen Lock Landscape"
  },
  {
    "name": "screen_lock_portrait",
    "tagStr": "Android OS device hardware iOS lock mobile phone portrait rotate screen tablet",
    "description": "Screen Lock Portrait"
  },
  {
    "name": "screen_lock_rotation",
    "tagStr": "Android OS arrow device hardware iOS lock mobile phone rotate rotation screen tablet turn",
    "description": "Screen Lock Rotation"
  },
  {
    "name": "screen_rotation",
    "tagStr": "Android OS arrow device hardware iOS mobile phone rotate rotation screen tablet turn",
    "description": "Screen Rotation"
  },
  {
    "name": "screen_search_desktop",
    "tagStr": "Android OS arrow desktop device hardware iOS lock monitor rotate screen web",
    "description": "Screen Search Desktop"
  },
  {
    "name": "screen_share",
    "tagStr": "Android OS arrow cast chrome device display hardware iOS laptop mac mirror monitor screen share steam streaming web window",
    "description": "Screen Share"
  },
  {
    "name": "screenshot",
    "tagStr": "Android OS cell crop device hardware iOS mobile phone screen screenshot tablet",
    "description": "Screenshot"
  },
  {
    "name": "sd",
    "tagStr": "alphabet camera card character data device digital drive flash font image letter memory photo sd secure symbol text type",
    "description": "Sd"
  },
  {
    "name": "sd_card",
    "tagStr": "camera card digital memory photos sd secure storage",
    "description": "Sd Card"
  },
  {
    "name": "sd_card_alert",
    "tagStr": "! alert attention camera card caution danger digital error exclamation important mark memory notification photos sd secure storage symbol warning",
    "description": "Sd Card Alert"
  },
  {
    "name": "sd_storage",
    "tagStr": "camera card data digital memory sd secure storage",
    "description": "Sd Storage"
  },
  {
    "name": "search",
    "tagStr": "filter find glass look magnify magnifying search see",
    "description": "Search"
  },
  {
    "name": "search_off",
    "tagStr": "cancel close disabled enabled find glass look magnify magnifying off on search see slash stop x",
    "description": "Search Off"
  },
  {
    "name": "security",
    "tagStr": "certified privacy private protect protection security shield verified",
    "description": "Security"
  },
  {
    "name": "security_update",
    "tagStr": "Android OS arrow device down download hardware iOS mobile phone security tablet update",
    "description": "Security Update"
  },
  {
    "name": "security_update_good",
    "tagStr": "Android OS checkmark device good hardware iOS mobile ok phone security tablet tick update",
    "description": "Security Update Good"
  },
  {
    "name": "security_update_warning",
    "tagStr": "! Android OS alert attention caution danger device download error exclamation hardware iOS important mark mobile notification phone security symbol tablet update warning",
    "description": "Security Update Warning"
  },
  {
    "name": "segment",
    "tagStr": "alignment fonts format lines list paragraph part piece rule rules segment style text",
    "description": "Segment"
  },
  {
    "name": "select_all",
    "tagStr": "all select selection square tool",
    "description": "Select All"
  },
  {
    "name": "self_improvement",
    "tagStr": "body calm care chi human improvement meditate meditation people person relax self sitting wellbeing yoga zen",
    "description": "Self Improvement"
  },
  {
    "name": "sell",
    "tagStr": "bill card cart cash coin commerce credit currency dollars money online pay payment price sell shopping tag",
    "description": "Sell"
  },
  {
    "name": "send",
    "tagStr": "email mail message paper plane reply right send share",
    "description": "Send"
  },
  {
    "name": "send_and_archive",
    "tagStr": "archive arrow down download email letter mail save send share",
    "description": "Send And Archive"
  },
  {
    "name": "send_to_mobile",
    "tagStr": "Android OS arrow device export forward hardware iOS mobile phone right send share tablet to",
    "description": "Send To Mobile"
  },
  {
    "name": "sensor_door",
    "tagStr": "",
    "description": "Sensor Door"
  },
  {
    "name": "sensor_window",
    "tagStr": "",
    "description": "Sensor Window"
  },
  {
    "name": "sensors",
    "tagStr": "connection network scan sensors signal wireless",
    "description": "Sensors"
  },
  {
    "name": "sensors_off",
    "tagStr": "connection disabled enabled network off on scan sensors signal slash wireless",
    "description": "Sensors Off"
  },
  {
    "name": "sentiment_dissatisfied",
    "tagStr": "angry disappointed dislike dissatisfied emotions expressions face feelings frown mood person sad sentiment survey unhappy unsatisfied upset",
    "description": "Sentiment Dissatisfied"
  },
  {
    "name": "sentiment_neutral",
    "tagStr": "emotionless emotions expressions face feelings fine indifference mood neutral okay person sentiment survey",
    "description": "Sentiment Neutral"
  },
  {
    "name": "sentiment_satisfied",
    "tagStr": "emotions expressions face feelings glad happiness happy like mood person pleased satisfied sentiment smile smiling survey",
    "description": "Sentiment Satisfied"
  },
  {
    "name": "sentiment_satisfied_alt",
    "tagStr": "account alt emoji face happy human people person profile satisfied sentiment smile user",
    "description": "Sentiment Satisfied Alt"
  },
  {
    "name": "sentiment_very_dissatisfied",
    "tagStr": "angry disappointed dislike dissatisfied emotions expressions face feelings mood person sad sentiment sorrow survey unhappy unsatisfied upset very",
    "description": "Sentiment Very Dissatisfied"
  },
  {
    "name": "sentiment_very_satisfied",
    "tagStr": "emotions expressions face feelings glad happiness happy like mood person pleased satisfied sentiment smile smiling survey very",
    "description": "Sentiment Very Satisfied"
  },
  {
    "name": "set_meal",
    "tagStr": "chopsticks dinner fish food lunch meal restaurant set teishoku",
    "description": "Set Meal"
  },
  {
    "name": "settings",
    "tagStr": "application change details gear info information options personal service settings",
    "description": "Settings"
  },
  {
    "name": "settings_accessibility",
    "tagStr": "accessibility body details human information people person personal preferences profile settings user",
    "description": "Settings Accessibility"
  },
  {
    "name": "settings_applications",
    "tagStr": "application change details gear info information options personal service settings",
    "description": "Settings Applications"
  },
  {
    "name": "settings_backup_restore",
    "tagStr": "arrow back backup backwards refresh restore reverse rotate settings",
    "description": "Settings Backup Restore"
  },
  {
    "name": "settings_bluetooth",
    "tagStr": "bluetooth connect connection connectivity device settings signal symbol",
    "description": "Settings Bluetooth"
  },
  {
    "name": "settings_brightness",
    "tagStr": "brightness dark filter light mode setting settings",
    "description": "Settings Brightness"
  },
  {
    "name": "settings_cell",
    "tagStr": "Android OS cell device hardware iOS mobile phone settings tablet",
    "description": "Settings Cell"
  },
  {
    "name": "settings_ethernet",
    "tagStr": "arrows computer connect connection connectivity dots ethernet internet network settings wifi",
    "description": "Settings Ethernet"
  },
  {
    "name": "settings_input_antenna",
    "tagStr": "airplay antenna arrows cast computer connect connection connectivity dots input internet network screencast settings stream wifi wireless",
    "description": "Settings Input Antenna"
  },
  {
    "name": "settings_input_component",
    "tagStr": "audio av cable cables component connect connection connectivity input internet plug points settings video wifi",
    "description": "Settings Input Component"
  },
  {
    "name": "settings_input_composite",
    "tagStr": "component composite connection connectivity input plug points settings",
    "description": "Settings Input Composite"
  },
  {
    "name": "settings_input_hdmi",
    "tagStr": "cable connection connectivity definition hdmi high input plug plugin points settings video wire",
    "description": "Settings Input Hdmi"
  },
  {
    "name": "settings_input_svideo",
    "tagStr": "cable connection connectivity definition input plug plugin points settings standard svideo video",
    "description": "Settings Input Svideo"
  },
  {
    "name": "settings_overscan",
    "tagStr": "arrows expand image photo picture scan settings",
    "description": "Settings Overscan"
  },
  {
    "name": "settings_phone",
    "tagStr": "call cell contact device hardware mobile phone settings telephone",
    "description": "Settings Phone"
  },
  {
    "name": "settings_power",
    "tagStr": "info information off on power save settings shutdown",
    "description": "Settings Power"
  },
  {
    "name": "settings_remote",
    "tagStr": "bluetooth connection connectivity device remote settings signal wifi wireless",
    "description": "Settings Remote"
  },
  {
    "name": "settings_suggest",
    "tagStr": "change details gear options recommendation service settings suggest suggestion system",
    "description": "Settings Suggest"
  },
  {
    "name": "settings_system_daydream",
    "tagStr": "backup cloud daydream drive settings storage system",
    "description": "Settings System Daydream"
  },
  {
    "name": "settings_voice",
    "tagStr": "mic microphone record recorder settings speaker voice",
    "description": "Settings Voice"
  },
  {
    "name": "share",
    "tagStr": "android connect contect link media multimedia multiple network options send share shared sharing social",
    "description": "Share"
  },
  {
    "name": "share_location",
    "tagStr": "destination direction gps location maps pin place share stop tracking",
    "description": "Share Location"
  },
  {
    "name": "shield",
    "tagStr": "certified privacy private protect protection secure security shield verified",
    "description": "Shield"
  },
  {
    "name": "shield_moon",
    "tagStr": "",
    "description": "Shield Moon"
  },
  {
    "name": "shop",
    "tagStr": "bag bill buy card cart cash coin commerce credit currency dollars google money online pay payment play shop shopping store",
    "description": "Shop"
  },
  {
    "name": "shop_2",
    "tagStr": "",
    "description": "Shop 2"
  },
  {
    "name": "shop_two",
    "tagStr": "add arrow buy cart google play purchase shop shopping two",
    "description": "Shop Two"
  },
  {
    "name": "shopify",
    "tagStr": "",
    "description": "Shopify"
  },
  {
    "name": "shopping_bag",
    "tagStr": "bag bill business buy card cart cash coin commerce credit currency dollars money online pay payment shop shopping store storefront",
    "description": "Shopping Bag"
  },
  {
    "name": "shopping_basket",
    "tagStr": "add basket bill buy card cart cash checkout coin commerce credit currency dollars money online pay payment shopping",
    "description": "Shopping Basket"
  },
  {
    "name": "shopping_cart",
    "tagStr": "add bill buy card cart cash checkout coin commerce credit currency dollars money online pay payment shopping",
    "description": "Shopping Cart"
  },
  {
    "name": "short_text",
    "tagStr": "brief comment doc document note short text write writing",
    "description": "Short Text"
  },
  {
    "name": "shortcut",
    "tagStr": "arrow direction forward right shortcut",
    "description": "Shortcut"
  },
  {
    "name": "show_chart",
    "tagStr": "analytics bar bars chart data diagram graph infographic line measure metrics presentation show chart statistics tracking",
    "description": "Show Chart"
  },
  {
    "name": "shower",
    "tagStr": "bath bathroom closet home house place plumbing room shower sprinkler wash water wc",
    "description": "Shower"
  },
  {
    "name": "shuffle",
    "tagStr": "arrow arrows control controls music random shuffle video",
    "description": "Shuffle"
  },
  {
    "name": "shuffle_on",
    "tagStr": "arrow arrows control controls music on random shuffle video",
    "description": "Shuffle On"
  },
  {
    "name": "shutter_speed",
    "tagStr": "aperture camera duration image lens photo photography photos picture setting shutter speed stop time timer watch",
    "description": "Shutter Speed"
  },
  {
    "name": "sick",
    "tagStr": "covid discomfort emotions expressions face feelings fever flu ill mood pain person sick survey upset",
    "description": "Sick"
  },
  {
    "name": "signal_cellular_0_bar",
    "tagStr": "0 bar cell cellular data internet mobile network phone signal speed wifi wireless",
    "description": "Signal Cellular 0 Bar"
  },
  {
    "name": "signal_cellular_4_bar",
    "tagStr": "4 bar cell cellular data internet mobile network phone signal speed wifi wireless",
    "description": "Signal Cellular 4 Bar"
  },
  {
    "name": "signal_cellular_alt",
    "tagStr": "alt analytics bar cell cellular chart data diagram graph infographic internet measure metrics mobile network phone signal statistics tracking wifi wireless",
    "description": "Signal Cellular Alt"
  },
  {
    "name": "signal_cellular_connected_no_internet_0_bar",
    "tagStr": "! 0 alert attention bar caution cell cellular connected danger data error exclamation important internet mark mobile network no notification phone signal symbol warning wifi wireless",
    "description": "Signal Cellular Connected No Internet 0 Bar"
  },
  {
    "name": "signal_cellular_connected_no_internet_4_bar",
    "tagStr": "! 4 alert attention bar caution cell cellular connected danger data error exclamation important internet mark mobile network no notification phone signal symbol warning wifi wireless",
    "description": "Signal Cellular Connected No Internet 4 Bar"
  },
  {
    "name": "signal_cellular_no_sim",
    "tagStr": "camera card cellular chip device disabled enabled memory no off offline on phone signal sim slash storage",
    "description": "Signal Cellular No Sim"
  },
  {
    "name": "signal_cellular_nodata",
    "tagStr": "cell cellular data internet mobile network no nodata offline phone quit signal wifi wireless x",
    "description": "Signal Cellular Nodata"
  },
  {
    "name": "signal_cellular_null",
    "tagStr": "cell cellular data internet mobile network null phone signal wifi wireless",
    "description": "Signal Cellular Null"
  },
  {
    "name": "signal_cellular_off",
    "tagStr": "cell cellular data disabled enabled internet mobile network off offline on phone signal slash wifi wireless",
    "description": "Signal Cellular Off"
  },
  {
    "name": "signal_wifi_0_bar",
    "tagStr": "0 bar cell cellular data internet mobile network phone signal wifi wireless",
    "description": "Signal Wifi 0 Bar"
  },
  {
    "name": "signal_wifi_4_bar",
    "tagStr": "4 bar cell cellular data internet mobile network phone signal wifi wireless",
    "description": "Signal Wifi 4 Bar"
  },
  {
    "name": "signal_wifi_4_bar_lock",
    "tagStr": "4 bar cell cellular data internet lock locked mobile network password phone privacy private protection safety secure security signal wifi wireless",
    "description": "Signal Wifi 4 Bar Lock"
  },
  {
    "name": "signal_wifi_bad",
    "tagStr": "bad bar cancel cell cellular close data exit internet mobile network no phone quit remove signal stop wifi wireless x",
    "description": "Signal Wifi Bad"
  },
  {
    "name": "signal_wifi_connected_no_internet_4",
    "tagStr": "4 cell cellular connected data internet mobile network no offline phone signal wifi wireless x",
    "description": "Signal Wifi Connected No Internet 4"
  },
  {
    "name": "signal_wifi_off",
    "tagStr": "cell cellular data disabled enabled internet mobile network off on phone signal slash speed wifi wireless",
    "description": "Signal Wifi Off"
  },
  {
    "name": "signal_wifi_statusbar_4_bar",
    "tagStr": "4 bar cell cellular data internet mobile network phone signal speed statusbar wifi wireless",
    "description": "Signal Wifi Statusbar 4 Bar"
  },
  {
    "name": "signal_wifi_statusbar_connected_no_internet_4",
    "tagStr": "! 4 alert attention caution cell cellular connected danger data error exclamation important internet mark mobile network no notification phone signal speed statusbar symbol warning wifi wireless",
    "description": "Signal Wifi Statusbar Connected No Internet 4"
  },
  {
    "name": "signal_wifi_statusbar_null",
    "tagStr": "cell cellular data internet mobile network null phone signal speed statusbar wifi wireless",
    "description": "Signal Wifi Statusbar Null"
  },
  {
    "name": "sim_card",
    "tagStr": "camera card chip device memory phone sim storage",
    "description": "Sim Card"
  },
  {
    "name": "sim_card_alert",
    "tagStr": "! alert attention camera card caution danger digital error exclamation important mark memory notification photos sd secure storage symbol warning",
    "description": "Sim Card Alert"
  },
  {
    "name": "sim_card_download",
    "tagStr": "arrow camera card chip device down download memory phone sim storage",
    "description": "Sim Card Download"
  },
  {
    "name": "single_bed",
    "tagStr": "bed bedroom double furniture home hotel house king night pillows queen rest room single sleep twin",
    "description": "Single Bed"
  },
  {
    "name": "sip",
    "tagStr": "alphabet call character dialer font initiation internet letter over phone protocol routing session sip symbol text type voice",
    "description": "Sip"
  },
  {
    "name": "skateboarding",
    "tagStr": "athlete athletic body entertainment exercise hobby human people person skate skateboarder skateboarding social sports",
    "description": "Skateboarding"
  },
  {
    "name": "skip_next",
    "tagStr": "arrow control controls forward music next play previous skip transport video",
    "description": "Skip Next"
  },
  {
    "name": "skip_previous",
    "tagStr": "arrow backward control controls music next play previous skip transport video",
    "description": "Skip Previous"
  },
  {
    "name": "sledding",
    "tagStr": "athlete athletic body entertainment exercise hobby human people person sled sledding sledge snow social sports travel winter",
    "description": "Sledding"
  },
  {
    "name": "slideshow",
    "tagStr": "movie photos play slideshow square video view",
    "description": "Slideshow"
  },
  {
    "name": "slow_motion_video",
    "tagStr": "arrow control controls motion music play slow speed video",
    "description": "Slow Motion Video"
  },
  {
    "name": "smart_button",
    "tagStr": "action auto button components composer function interface site smart special stars ui ux web website",
    "description": "Smart Button"
  },
  {
    "name": "smart_display",
    "tagStr": "airplay cast chrome connect device display play screen screencast smart stream television tv video wireless",
    "description": "Smart Display"
  },
  {
    "name": "smart_screen",
    "tagStr": "Android OS airplay cast cell connect device hardware iOS mobile phone screen screencast smart stream tablet video",
    "description": "Smart Screen"
  },
  {
    "name": "smart_toy",
    "tagStr": "games robot smart toy",
    "description": "Smart Toy"
  },
  {
    "name": "smartphone",
    "tagStr": "Android OS call cell chat device hardware iOS mobile phone smartphone tablet text",
    "description": "Smartphone"
  },
  {
    "name": "smoke_free",
    "tagStr": "cigarette disabled enabled free never no off on places prohibited slash smoke smoking tobacco warning zone",
    "description": "Smoke Free"
  },
  {
    "name": "smoking_rooms",
    "tagStr": "allowed cigarette places rooms smoke smoking tobacco zone",
    "description": "Smoking Rooms"
  },
  {
    "name": "sms",
    "tagStr": "3 bubble chat communication conversation dots message more service sms speech three",
    "description": "Sms"
  },
  {
    "name": "sms_failed",
    "tagStr": "! alert attention bubbles caution chat communication conversation danger error exclamation failed important mark message notification service sms speech symbol warning",
    "description": "Sms Failed"
  },
  {
    "name": "snapchat",
    "tagStr": "",
    "description": "Snapchat"
  },
  {
    "name": "snippet_folder",
    "tagStr": "data doc document drive file folder sheet slide snippet storage",
    "description": "Snippet Folder"
  },
  {
    "name": "snooze",
    "tagStr": "alarm bell clock duration notification snooze time timer watch z",
    "description": "Snooze"
  },
  {
    "name": "snowboarding",
    "tagStr": "athlete athletic body entertainment exercise hobby human people person snow snowboarding social sports travel winter",
    "description": "Snowboarding"
  },
  {
    "name": "snowmobile",
    "tagStr": "automobile car direction skimobile snow snowmobile social sports transportation travel vehicle winter",
    "description": "Snowmobile"
  },
  {
    "name": "snowshoeing",
    "tagStr": "body human people person snow snowshoe snowshoeing sports travel winter",
    "description": "Snowshoeing"
  },
  {
    "name": "soap",
    "tagStr": "bathroom clean fingers gesture hand soap wash wc",
    "description": "Soap"
  },
  {
    "name": "social_distance",
    "tagStr": "6 apart body distance ft human people person social space",
    "description": "Social Distance"
  },
  {
    "name": "sort",
    "tagStr": "filter find lines list organize sort",
    "description": "Sort"
  },
  {
    "name": "sort_by_alpha",
    "tagStr": "alphabet alphabetize az by alpha character font letter list order organize sort symbol text type",
    "description": "Sort By Alpha"
  },
  {
    "name": "soup_kitchen",
    "tagStr": "",
    "description": "Soup Kitchen"
  },
  {
    "name": "source",
    "tagStr": "code composer content creation data doc document file folder mode source storage view",
    "description": "Source"
  },
  {
    "name": "south",
    "tagStr": "arrow directional down maps navigation south",
    "description": "South"
  },
  {
    "name": "south_america",
    "tagStr": "",
    "description": "South America"
  },
  {
    "name": "south_east",
    "tagStr": "arrow directional down east maps navigation right south",
    "description": "South East"
  },
  {
    "name": "south_west",
    "tagStr": "arrow directional down left maps navigation south west",
    "description": "South West"
  },
  {
    "name": "spa",
    "tagStr": "aromatherapy flower healthcare leaf massage meditation nature petals places relax spa wellbeing wellness",
    "description": "Spa"
  },
  {
    "name": "space_bar",
    "tagStr": "bar keyboard line space",
    "description": "Space Bar"
  },
  {
    "name": "space_dashboard",
    "tagStr": "",
    "description": "Space Dashboard"
  },
  {
    "name": "speaker",
    "tagStr": "box electronic loud music sound speaker stereo system video",
    "description": "Speaker"
  },
  {
    "name": "speaker_group",
    "tagStr": "box electronic group loud multiple music sound speaker stereo system video",
    "description": "Speaker Group"
  },
  {
    "name": "speaker_notes",
    "tagStr": "bubble chat comment communicate format list message notes speaker speech text",
    "description": "Speaker Notes"
  },
  {
    "name": "speaker_notes_off",
    "tagStr": "bubble chat comment communicate disabled enabled format list message notes off on slash speaker speech text",
    "description": "Speaker Notes Off"
  },
  {
    "name": "speaker_phone",
    "tagStr": "Android OS cell device hardware iOS mobile phone sound speaker tablet volume",
    "description": "Speaker Phone"
  },
  {
    "name": "speed",
    "tagStr": "arrow control controls fast gauge meter motion music slow speed speedometer velocity video",
    "description": "Speed"
  },
  {
    "name": "spellcheck",
    "tagStr": "a alphabet approve character check font letter mark ok processor select spell spellcheck symbol text tick type word write yes",
    "description": "Spellcheck"
  },
  {
    "name": "splitscreen",
    "tagStr": "grid layout multitasking screen split splitscreen two",
    "description": "Splitscreen"
  },
  {
    "name": "spoke",
    "tagStr": "",
    "description": "Spoke"
  },
  {
    "name": "sports",
    "tagStr": "athlete athletic ball basketball blowing coach entertainment exercise game hobby instrument live referee soccer social sound sports trophy warning whistle",
    "description": "Sports"
  },
  {
    "name": "sports_bar",
    "tagStr": "alcohol bar beer drink liquor pint places pub sports",
    "description": "Sports Bar"
  },
  {
    "name": "sports_baseball",
    "tagStr": "athlete athletic ball baseball entertainment exercise game hobby social sports",
    "description": "Sports Baseball"
  },
  {
    "name": "sports_basketball",
    "tagStr": "athlete athletic ball basketball entertainment exercise game hobby social sports",
    "description": "Sports Basketball"
  },
  {
    "name": "sports_cricket",
    "tagStr": "athlete athletic ball bat cricket entertainment exercise game hobby social sports",
    "description": "Sports Cricket"
  },
  {
    "name": "sports_esports",
    "tagStr": "controller entertainment esports game gamepad gaming hobby online social sports video",
    "description": "Sports Esports"
  },
  {
    "name": "sports_football",
    "tagStr": "athlete athletic ball entertainment exercise football game hobby social sports",
    "description": "Sports Football"
  },
  {
    "name": "sports_golf",
    "tagStr": "athlete athletic ball club entertainment exercise game golf golfer golfing hobby social sports",
    "description": "Sports Golf"
  },
  {
    "name": "sports_handball",
    "tagStr": "athlete athletic ball body entertainment exercise game handball hobby human people person social sports",
    "description": "Sports Handball"
  },
  {
    "name": "sports_hockey",
    "tagStr": "athlete athletic entertainment exercise game hobby hockey social sports sticks",
    "description": "Sports Hockey"
  },
  {
    "name": "sports_kabaddi",
    "tagStr": "athlete athletic body combat entertainment exercise fighting game hobby human kabaddi people person social sports wrestle wrestling",
    "description": "Sports Kabaddi"
  },
  {
    "name": "sports_mma",
    "tagStr": "arts athlete athletic boxing combat entertainment exercise fighting game glove hobby martial mixed mma social sports",
    "description": "Sports Mma"
  },
  {
    "name": "sports_motorsports",
    "tagStr": "athlete athletic automobile bike drive driving entertainment helmet hobby motorcycle motorsports protect social sports vehicle",
    "description": "Sports Motorsports"
  },
  {
    "name": "sports_rugby",
    "tagStr": "athlete athletic ball entertainment exercise game hobby rugby social sports",
    "description": "Sports Rugby"
  },
  {
    "name": "sports_score",
    "tagStr": "destination flag goal score sports",
    "description": "Sports Score"
  },
  {
    "name": "sports_soccer",
    "tagStr": "athlete athletic ball entertainment exercise football game hobby soccer social sports",
    "description": "Sports Soccer"
  },
  {
    "name": "sports_tennis",
    "tagStr": "athlete athletic ball bat entertainment exercise game hobby racket social sports tennis",
    "description": "Sports Tennis"
  },
  {
    "name": "sports_volleyball",
    "tagStr": "athlete athletic ball entertainment exercise game hobby social sports volleyball",
    "description": "Sports Volleyball"
  },
  {
    "name": "square_foot",
    "tagStr": "construction feet foot inches length measurement ruler school set square tools",
    "description": "Square Foot"
  },
  {
    "name": "stacked_bar_chart",
    "tagStr": "analytics bar chart-chart data diagram graph infographic measure metrics stacked statistics tracking",
    "description": "Stacked Bar Chart"
  },
  {
    "name": "stacked_line_chart",
    "tagStr": "analytics chart data diagram graph infographic line measure metrics stacked statistics tracking",
    "description": "Stacked Line Chart"
  },
  {
    "name": "stairs",
    "tagStr": "down staircase stairs up",
    "description": "Stairs"
  },
  {
    "name": "star",
    "tagStr": "best bookmark favorite highlight ranking rate rating save star toggle",
    "description": "Star"
  },
  {
    "name": "star_border",
    "tagStr": "best bookmark border favorite highlight outline ranking rate rating save star toggle",
    "description": "Star Border"
  },
  {
    "name": "star_border_purple500",
    "tagStr": "500 best bookmark border favorite highlight outline purple ranking rate rating save star toggle",
    "description": "Star Border Purple500"
  },
  {
    "name": "star_half",
    "tagStr": "0.5 1/2 achievement bookmark favorite half highlight important marked ranking rate rating rating rank reward save saved shape special star toggle",
    "description": "Star Half"
  },
  {
    "name": "star_outline",
    "tagStr": "bookmark favorite half highlight ranking rate rating save star toggle",
    "description": "Star Outline"
  },
  {
    "name": "star_purple500",
    "tagStr": "500 best bookmark favorite highlight purple ranking rate rating save star toggle",
    "description": "Star Purple500"
  },
  {
    "name": "star_rate",
    "tagStr": "achievement bookmark favorite highlight important marked ranking rate rating rank reward save saved shape special star",
    "description": "Star Rate"
  },
  {
    "name": "stars",
    "tagStr": "achievement bookmark circle favorite highlight important marked ranking rate rating rank reward save saved shape special star",
    "description": "Stars"
  },
  {
    "name": "stay_current_landscape",
    "tagStr": "Android OS current device hardware iOS landscape mobile phone stay tablet",
    "description": "Stay Current Landscape"
  },
  {
    "name": "stay_current_portrait",
    "tagStr": "Android OS current device hardware iOS mobile phone portrait stay tablet",
    "description": "Stay Current Portrait"
  },
  {
    "name": "stay_primary_landscape",
    "tagStr": "Android OS current device hardware iOS landscape mobile phone primary stay tablet",
    "description": "Stay Primary Landscape"
  },
  {
    "name": "stay_primary_portrait",
    "tagStr": "Android OS current device hardware iOS mobile phone portrait primary stay tablet",
    "description": "Stay Primary Portrait"
  },
  {
    "name": "sticky_note_2",
    "tagStr": "2 bookmark mark message note paper sticky text writing",
    "description": "Sticky Note 2"
  },
  {
    "name": "stop",
    "tagStr": "control controls music pause play player square stop video",
    "description": "Stop"
  },
  {
    "name": "stop_circle",
    "tagStr": "",
    "description": "Stop Circle"
  },
  {
    "name": "stop_screen_share",
    "tagStr": "Android OS arrow cast chrome device disabled display enabled hardware iOS laptop mac mirror monitor off offline on screen share slash steam stop streaming web window",
    "description": "Stop Screen Share"
  },
  {
    "name": "storage",
    "tagStr": "computer data drive memory storage",
    "description": "Storage"
  },
  {
    "name": "store",
    "tagStr": "bill building business card cash coin commerce company credit currency dollars market money online pay payment shop shopping store storefront",
    "description": "Store"
  },
  {
    "name": "store_mall_directory",
    "tagStr": "directory mall store",
    "description": "Store Mall Directory"
  },
  {
    "name": "storefront",
    "tagStr": "business buy cafe commerce front market places restaurant retail sell shop shopping store storefront",
    "description": "Storefront"
  },
  {
    "name": "storm",
    "tagStr": "forecast hurricane storm temperature twister weather wind",
    "description": "Storm"
  },
  {
    "name": "straighten",
    "tagStr": "length measure measurement ruler size straighten",
    "description": "Straighten"
  },
  {
    "name": "stream",
    "tagStr": "cast connected feed live network signal stream wireless",
    "description": "Stream"
  },
  {
    "name": "streetview",
    "tagStr": "maps street streetview view",
    "description": "Streetview"
  },
  {
    "name": "strikethrough_s",
    "tagStr": "alphabet character cross doc edit editing editor font letter out s sheet spreadsheet strikethrough styles symbol text type writing",
    "description": "Strikethrough S"
  },
  {
    "name": "stroller",
    "tagStr": "baby care carriage child children infant kid newborn stroller toddler young",
    "description": "Stroller"
  },
  {
    "name": "style",
    "tagStr": "booklet cards filters options style tags",
    "description": "Style"
  },
  {
    "name": "subdirectory_arrow_left",
    "tagStr": "arrow directory down left navigation sub subdirectory",
    "description": "Subdirectory Arrow Left"
  },
  {
    "name": "subdirectory_arrow_right",
    "tagStr": "arrow directory down navigation right sub subdirectory",
    "description": "Subdirectory Arrow Right"
  },
  {
    "name": "subject",
    "tagStr": "alignment doc document email full justify list note subject text writing",
    "description": "Subject"
  },
  {
    "name": "subscript",
    "tagStr": "2 doc edit editing editor gmail novitas sheet spreadsheet style subscript symbol text writing x",
    "description": "Subscript"
  },
  {
    "name": "subscriptions",
    "tagStr": "enroll list media order play signup subs subscribe subscriptions",
    "description": "Subscriptions"
  },
  {
    "name": "subtitles",
    "tagStr": "accessible caption cc character closed decoder language media movies subtitle subtitles tv",
    "description": "Subtitles"
  },
  {
    "name": "subtitles_off",
    "tagStr": "accessibility accessible caption cc closed disabled enabled language off on slash subtitle subtitles translate video",
    "description": "Subtitles Off"
  },
  {
    "name": "subway",
    "tagStr": "automobile bike car cars maps rail scooter subway train transportation travel tunnel underground vehicle vespa",
    "description": "Subway"
  },
  {
    "name": "summarize",
    "tagStr": "doc document list menu note report summary",
    "description": "Summarize"
  },
  {
    "name": "superscript",
    "tagStr": "2 doc edit editing editor gmail novitas sheet spreadsheet style superscript symbol text writing x",
    "description": "Superscript"
  },
  {
    "name": "supervised_user_circle",
    "tagStr": "account avatar circle control face human parental parents people person profile supervised supervisor user",
    "description": "Supervised User Circle"
  },
  {
    "name": "supervisor_account",
    "tagStr": "account avatar control face human parental parental control parents people person profile supervised supervisor user",
    "description": "Supervisor Account"
  },
  {
    "name": "support",
    "tagStr": "assist buoy help life lifebuoy rescue safe safety support",
    "description": "Support"
  },
  {
    "name": "support_agent",
    "tagStr": "agent care customer face headphone person representative service support",
    "description": "Support Agent"
  },
  {
    "name": "surfing",
    "tagStr": "athlete athletic beach body entertainment exercise hobby human people person sea social sports sports summer surfing water",
    "description": "Surfing"
  },
  {
    "name": "surround_sound",
    "tagStr": "circle signal sound speaker surround system volumn wireless",
    "description": "Surround Sound"
  },
  {
    "name": "swap_calls",
    "tagStr": "arrow arrows calls device direction mobile share swap",
    "description": "Swap Calls"
  },
  {
    "name": "swap_horiz",
    "tagStr": "arrow arrows back forward horizontal swap",
    "description": "Swap Horiz"
  },
  {
    "name": "swap_horizontal_circle",
    "tagStr": "arrow arrows back circle forward horizontal swap",
    "description": "Swap Horizontal Circle"
  },
  {
    "name": "swap_vert",
    "tagStr": "arrow arrows direction down navigation swap up vert vertical",
    "description": "Swap Vert"
  },
  {
    "name": "swap_vertical_circle",
    "tagStr": "arrow arrows circle down swap up vertical",
    "description": "Swap Vertical Circle"
  },
  {
    "name": "swipe",
    "tagStr": "arrow arrows fingers gesture hand hands swipe touch",
    "description": "Swipe"
  },
  {
    "name": "switch_access_shortcut",
    "tagStr": "",
    "description": "Switch Access Shortcut"
  },
  {
    "name": "switch_access_shortcut_add",
    "tagStr": "",
    "description": "Switch Access Shortcut Add"
  },
  {
    "name": "switch_account",
    "tagStr": "account choices face human multiple options people person profile social switch user",
    "description": "Switch Account"
  },
  {
    "name": "switch_camera",
    "tagStr": "arrow arrows camera photo photography picture switch",
    "description": "Switch Camera"
  },
  {
    "name": "switch_left",
    "tagStr": "arrows directional left navigation switch toggle",
    "description": "Switch Left"
  },
  {
    "name": "switch_right",
    "tagStr": "arrows directional navigation right switch toggle",
    "description": "Switch Right"
  },
  {
    "name": "switch_video",
    "tagStr": "arrow arrows camera photography switch video videos",
    "description": "Switch Video"
  },
  {
    "name": "synagogue",
    "tagStr": "",
    "description": "Synagogue"
  },
  {
    "name": "sync",
    "tagStr": "360 around arrow arrows direction inprogress load loading refresh renew rotate sync turn",
    "description": "Sync"
  },
  {
    "name": "sync_alt",
    "tagStr": "alt arrow arrows horizontal internet sync technology up update wifi",
    "description": "Sync Alt"
  },
  {
    "name": "sync_disabled",
    "tagStr": "360 around arrow arrows direction disabled enabled inprogress load loading refresh off on renew rotate slash sync turn",
    "description": "Sync Disabled"
  },
  {
    "name": "sync_problem",
    "tagStr": "! 360 alert around arrow arrows attention caution danger direction error exclamation important inprogress load loading refresh mark notification problem renew rotate symbol sync turn warning",
    "description": "Sync Problem"
  },
  {
    "name": "system_security_update",
    "tagStr": "Android OS arrow cell device down hardware iOS mobile phone security system tablet update",
    "description": "System Security Update"
  },
  {
    "name": "system_security_update_good",
    "tagStr": "Android OS approve cell check complete device done good hardware iOS mark mobile ok phone security select system tablet tick update validate verified yes",
    "description": "System Security Update Good"
  },
  {
    "name": "system_security_update_warning",
    "tagStr": "! Android OS alert attention caution cell danger device error exclamation hardware iOS important mark mobile notification phone security symbol system tablet update warning",
    "description": "System Security Update Warning"
  },
  {
    "name": "system_update",
    "tagStr": "Android OS arrow arrows cell device direction down download hardware iOS install mobile phone system tablet update",
    "description": "System Update"
  },
  {
    "name": "system_update_alt",
    "tagStr": "arrow down download export system update",
    "description": "System Update Alt"
  },
  {
    "name": "tab",
    "tagStr": "browser computer document documents folder internet tab tabs web website window windows",
    "description": "Tab"
  },
  {
    "name": "tab_unselected",
    "tagStr": "browser computer document documents folder internet tab tabs unselected web website window windows",
    "description": "Tab Unselected"
  },
  {
    "name": "table_bar",
    "tagStr": "",
    "description": "Table Bar"
  },
  {
    "name": "table_chart",
    "tagStr": "analytics bar bars chart data diagram graph infographic grid measure metrics statistics table tracking",
    "description": "Table Chart"
  },
  {
    "name": "table_restaurant",
    "tagStr": "",
    "description": "Table Restaurant"
  },
  {
    "name": "table_rows",
    "tagStr": "grid layout lines rows stacked table",
    "description": "Table Rows"
  },
  {
    "name": "table_view",
    "tagStr": "format grid group layout multiple table view",
    "description": "Table View"
  },
  {
    "name": "tablet",
    "tagStr": "Android OS device hardware iOS ipad mobile tablet web",
    "description": "Tablet"
  },
  {
    "name": "tablet_android",
    "tagStr": "OS android device hardware iOS ipad mobile tablet web",
    "description": "Tablet Android"
  },
  {
    "name": "tablet_mac",
    "tagStr": "Android OS device hardware iOS ipad mobile tablet mac web",
    "description": "Tablet Mac"
  },
  {
    "name": "tag",
    "tagStr": "hash hashtag key media number pound social tag trend",
    "description": "Tag"
  },
  {
    "name": "tag_faces",
    "tagStr": "emoji emotion faces happy satisfied smile tag",
    "description": "Tag Faces"
  },
  {
    "name": "takeout_dining",
    "tagStr": "box container delivery dining food meal restaurant takeout",
    "description": "Takeout Dining"
  },
  {
    "name": "tap_and_play",
    "tagStr": "Android OS wifi cell connection device hardware iOS internet mobile network phone play signal tablet tap to wireless",
    "description": "Tap And Play"
  },
  {
    "name": "tapas",
    "tagStr": "appetizer brunch dinner food lunch restaurant snack tapas",
    "description": "Tapas"
  },
  {
    "name": "task",
    "tagStr": "approve check complete data doc document done drive file folder folders mark ok page paper select sheet slide task tick validate verified writing yes",
    "description": "Task"
  },
  {
    "name": "task_alt",
    "tagStr": "approve check circle complete done mark ok select task tick validate verified yes",
    "description": "Task Alt"
  },
  {
    "name": "taxi_alert",
    "tagStr": "! alert attention automobile cab car cars caution danger direction error exclamation important lyft maps mark notification public symbol taxi transportation uber vehicle warning yellow",
    "description": "Taxi Alert"
  },
  {
    "name": "telegram",
    "tagStr": "",
    "description": "Telegram"
  },
  {
    "name": "temple_buddhist",
    "tagStr": "",
    "description": "Temple Buddhist"
  },
  {
    "name": "temple_hindu",
    "tagStr": "",
    "description": "Temple Hindu"
  },
  {
    "name": "terrain",
    "tagStr": "geography landscape mountain terrain",
    "description": "Terrain"
  },
  {
    "name": "text_fields",
    "tagStr": "T add alphabet character field fields font input letter symbol text type",
    "description": "Text Fields"
  },
  {
    "name": "text_format",
    "tagStr": "alphabet character font format letter square A style symbol text type",
    "description": "Text Format"
  },
  {
    "name": "text_rotate_up",
    "tagStr": "A alphabet arrow character field font letter move rotate symbol text type up",
    "description": "Text Rotate Up"
  },
  {
    "name": "text_rotate_vertical",
    "tagStr": "A alphabet arrow character down field font letter move rotate symbol text type vertical",
    "description": "Text Rotate Vertical"
  },
  {
    "name": "text_rotation_angledown",
    "tagStr": "A alphabet angledown arrow character field font letter move rotate symbol text type",
    "description": "Text Rotation Angledown"
  },
  {
    "name": "text_rotation_angleup",
    "tagStr": "A alphabet angleup arrow character field font letter move rotate symbol text type",
    "description": "Text Rotation Angleup"
  },
  {
    "name": "text_rotation_down",
    "tagStr": "A alphabet arrow character dow field font letter move rotate symbol text type",
    "description": "Text Rotation Down"
  },
  {
    "name": "text_rotation_none",
    "tagStr": "A alphabet arrow character field font letter move none rotate symbol text type",
    "description": "Text Rotation None"
  },
  {
    "name": "text_snippet",
    "tagStr": "data doc document file note notes snippet storage text writing",
    "description": "Text Snippet"
  },
  {
    "name": "textsms",
    "tagStr": "bubble chat comment communicate dots feedback message speech textsms",
    "description": "Textsms"
  },
  {
    "name": "texture",
    "tagStr": "diagonal lines pattern stripes texture",
    "description": "Texture"
  },
  {
    "name": "theater_comedy",
    "tagStr": "broadway comedy event movie musical places show standup theater tour watch",
    "description": "Theater Comedy"
  },
  {
    "name": "theaters",
    "tagStr": "film movie movies show showtimes theater theaters watch",
    "description": "Theaters"
  },
  {
    "name": "thermostat",
    "tagStr": "forecast temperature thermostat weather",
    "description": "Thermostat"
  },
  {
    "name": "thermostat_auto",
    "tagStr": "A auto celsius fahrenheit meter temp temperature thermometer thermostat",
    "description": "Thermostat Auto"
  },
  {
    "name": "thumb_down",
    "tagStr": "ate dislike down downvote favorite fingers gesture hand hands like rank ranking rating thumb",
    "description": "Thumb Down"
  },
  {
    "name": "thumb_down_alt",
    "tagStr": "bad decline disapprove dislike down feedback hate negative no reject social thumb veto vote",
    "description": "Thumb Down Alt"
  },
  {
    "name": "thumb_down_off_alt",
    "tagStr": "bad decline disapprove dislike down feedback hate negative no reject sad social thumb veto vote",
    "description": "Thumb Down Off Alt"
  },
  {
    "name": "thumb_up",
    "tagStr": "favorite fingers gesture hand hands like rank ranking rate rating thumb up upvote",
    "description": "Thumb Up"
  },
  {
    "name": "thumb_up_alt",
    "tagStr": "agreed approved confirm correct favorite feedback good happy like okay positive satisfaction social thumb up vote yes",
    "description": "Thumb Up Alt"
  },
  {
    "name": "thumb_up_off_alt",
    "tagStr": "agreed approved confirm correct favorite feedback good happy like okay positive satisfaction social thumb up vote yes",
    "description": "Thumb Up Off Alt"
  },
  {
    "name": "thumbs_up_down",
    "tagStr": "dislike down favorite fingers gesture hands like rate rating thumbs up",
    "description": "Thumbs Up Down"
  },
  {
    "name": "tiktok",
    "tagStr": "",
    "description": "Tiktok"
  },
  {
    "name": "time_to_leave",
    "tagStr": "automobile car cars destination direction drive estimate eta maps public transportation travel trip vehicle",
    "description": "Time To Leave"
  },
  {
    "name": "timelapse",
    "tagStr": "duration motion photo time timelapse timer video",
    "description": "Timelapse"
  },
  {
    "name": "timeline",
    "tagStr": "data history line movement point points timeline tracking trending zigzag",
    "description": "Timeline"
  },
  {
    "name": "timer",
    "tagStr": "alarm alart bell clock disabled duration enabled notification off on slash stop time timer watch",
    "description": "Timer"
  },
  {
    "name": "timer_10",
    "tagStr": "10 digits duration number numbers seconds time timer",
    "description": "Timer 10"
  },
  {
    "name": "timer_10_select",
    "tagStr": "10 alphabet camera character digit font letter number seconds select symbol text timer type",
    "description": "Timer 10 Select"
  },
  {
    "name": "timer_3",
    "tagStr": "3 digits duration number numbers seconds time timer",
    "description": "Timer 3"
  },
  {
    "name": "timer_3_select",
    "tagStr": "3 alphabet camera character digit font letter number seconds select symbol text timer type",
    "description": "Timer 3 Select"
  },
  {
    "name": "timer_off",
    "tagStr": "alarm alart bell clock disabled duration enabled notification off on slash stop time timer watch",
    "description": "Timer Off"
  },
  {
    "name": "tips_and_updates",
    "tagStr": "",
    "description": "Tips And Updates"
  },
  {
    "name": "title",
    "tagStr": "T alphabet character font header letter subject symbol text title type",
    "description": "Title"
  },
  {
    "name": "toc",
    "tagStr": "content format lines list order reorder stacked table title titles toc",
    "description": "Toc"
  },
  {
    "name": "today",
    "tagStr": "calendar date day event mark month remember reminder schedule time today",
    "description": "Today"
  },
  {
    "name": "toggle_off",
    "tagStr": "active app application components configuration control design disable inable inactive interface off on selection settings site slider switch toggle ui ux web website",
    "description": "Toggle Off"
  },
  {
    "name": "toggle_on",
    "tagStr": "active app application components configuration control design disable inable inactive interface off on selection settings site slider switch toggle ui ux web website",
    "description": "Toggle On"
  },
  {
    "name": "token",
    "tagStr": "",
    "description": "Token"
  },
  {
    "name": "toll",
    "tagStr": "bill booth car card cash coin commerce credit currency dollars highway money online pay payment ticket toll",
    "description": "Toll"
  },
  {
    "name": "tonality",
    "tagStr": "circle edit editing filter image photography picture tonality",
    "description": "Tonality"
  },
  {
    "name": "topic",
    "tagStr": "data doc document drive file folder sheet slide storage topic",
    "description": "Topic"
  },
  {
    "name": "touch_app",
    "tagStr": "app command fingers gesture hand press tap touch",
    "description": "Touch App"
  },
  {
    "name": "tour",
    "tagStr": "destination flag places tour travel visit",
    "description": "Tour"
  },
  {
    "name": "toys",
    "tagStr": "car games kids toys windmill",
    "description": "Toys"
  },
  {
    "name": "track_changes",
    "tagStr": "bullseye changes circle evolve lines movement rotate shift target track",
    "description": "Track Changes"
  },
  {
    "name": "traffic",
    "tagStr": "direction light maps signal street traffic",
    "description": "Traffic"
  },
  {
    "name": "train",
    "tagStr": "automobile car cars direction maps public rail subway train transportation vehicle",
    "description": "Train"
  },
  {
    "name": "tram",
    "tagStr": "automobile car cars direction maps public rail subway train tram transportation vehicle",
    "description": "Tram"
  },
  {
    "name": "transfer_within_a_station",
    "tagStr": "a arrow arrows body direction human left maps people person public right route station stop transfer transportation vehicle walk within",
    "description": "Transfer Within A Station"
  },
  {
    "name": "transform",
    "tagStr": "adjust crop edit editing image photo picture transform",
    "description": "Transform"
  },
  {
    "name": "transgender",
    "tagStr": "female gender lgbt male neutrual social transgender",
    "description": "Transgender"
  },
  {
    "name": "transit_enterexit",
    "tagStr": "arrow direction enterexit maps navigation route transit transportation",
    "description": "Transit Enterexit"
  },
  {
    "name": "translate",
    "tagStr": "language speaking speech translate translator words",
    "description": "Translate"
  },
  {
    "name": "travel_explore",
    "tagStr": "earth explore find glass global globe look magnify magnifying map network planet search see social space travel web world",
    "description": "Travel Explore"
  },
  {
    "name": "trending_down",
    "tagStr": "analytics arrow data diagram down graph infographic measure metrics movement rate rating statistics tracking trending",
    "description": "Trending Down"
  },
  {
    "name": "trending_flat",
    "tagStr": "arrow change data flat metric movement rate right track tracking trending",
    "description": "Trending Flat"
  },
  {
    "name": "trending_up",
    "tagStr": "analytics arrow data diagram graph infographic measure metrics movement rate rating statistics tracking trending up",
    "description": "Trending Up"
  },
  {
    "name": "trip_origin",
    "tagStr": "circle departure origin trip",
    "description": "Trip Origin"
  },
  {
    "name": "try",
    "tagStr": "bookmark bubble chat comment communicate favorite feedback highlight important marked message save saved shape special speech star try",
    "description": "Try"
  },
  {
    "name": "tty",
    "tagStr": "call cell contact deaf device hardware impaired mobile phone speech talk telephone text tty",
    "description": "Tty"
  },
  {
    "name": "tune",
    "tagStr": "adjust edit editing options setting settings tune",
    "description": "Tune"
  },
  {
    "name": "tungsten",
    "tagStr": "electricity indoor lamp light lightbulb setting tungsten",
    "description": "Tungsten"
  },
  {
    "name": "turned_in",
    "tagStr": "archive bookmark favorite in label library read reading remember ribbon save tag turned",
    "description": "Turned In"
  },
  {
    "name": "turned_in_not",
    "tagStr": "archive bookmark favorite in label library not read reading remember ribbon save tag turned",
    "description": "Turned In Not"
  },
  {
    "name": "tv",
    "tagStr": "device display linear living room monitor screen screencast stream television tv video wireless",
    "description": "Tv"
  },
  {
    "name": "tv_off",
    "tagStr": "Android OS chrome desktop device disabled enabled hardware iOS mac monitor off on slash television tv web window",
    "description": "Tv Off"
  },
  {
    "name": "two_wheeler",
    "tagStr": "automobile bike car cars direction maps motorcycle public scooter sport transportation travel twom wheeler vehicle",
    "description": "Two Wheeler"
  },
  {
    "name": "umbrella",
    "tagStr": "beach protection rain sun sunny umbrella",
    "description": "Umbrella"
  },
  {
    "name": "unarchive",
    "tagStr": "archive arrow inbox mail store unarchive undo up",
    "description": "Unarchive"
  },
  {
    "name": "undo",
    "tagStr": "arrow backward mail previous redo repeat rotate undo",
    "description": "Undo"
  },
  {
    "name": "unfold_less",
    "tagStr": "arrow arrows chevron collapse direction expand expandable inward less list navigation unfold up",
    "description": "Unfold Less"
  },
  {
    "name": "unfold_more",
    "tagStr": "arrow arrows chevron collapse direction down expand expandable list more navigation unfold",
    "description": "Unfold More"
  },
  {
    "name": "unpublished",
    "tagStr": "approve check circle complete disabled done enabled mark off ok on select slash tick unpublished validate verified yes",
    "description": "Unpublished"
  },
  {
    "name": "unsubscribe",
    "tagStr": "cancel close email envelop esubscribe letter mail message newsletter off remove send unsubscribe",
    "description": "Unsubscribe"
  },
  {
    "name": "upcoming",
    "tagStr": "alarm calendar mail message notification upcoming",
    "description": "Upcoming"
  },
  {
    "name": "update",
    "tagStr": "arrow back backwards clock forward history load refresh reverse schedule time update",
    "description": "Update"
  },
  {
    "name": "update_disabled",
    "tagStr": "arrow back backwards clock date disabled enabled forward history load off on refresh reverse rotate schedule slash time update",
    "description": "Update Disabled"
  },
  {
    "name": "upgrade",
    "tagStr": "arrow export instal line replace up update upgrade",
    "description": "Upgrade"
  },
  {
    "name": "upload",
    "tagStr": "arrow arrows download drive up upload",
    "description": "Upload"
  },
  {
    "name": "upload_file",
    "tagStr": "arrow data doc document download drive file folder folders page paper sheet slide up upload writing",
    "description": "Upload File"
  },
  {
    "name": "usb",
    "tagStr": "cable connection device usb wire",
    "description": "Usb"
  },
  {
    "name": "usb_off",
    "tagStr": "cable connection device off usb wire",
    "description": "Usb Off"
  },
  {
    "name": "vaccines",
    "tagStr": "",
    "description": "Vaccines"
  },
  {
    "name": "verified",
    "tagStr": "approve badge burst check complete done mark ok select star tick validate verified yes",
    "description": "Verified"
  },
  {
    "name": "verified_user",
    "tagStr": "approve certified check complete done mark ok privacy private protect protection security select shield tick user validate verified yes",
    "description": "Verified User"
  },
  {
    "name": "vertical_align_bottom",
    "tagStr": "align alignment arrow bottom doc down edit editing editor sheet spreadsheet text type vertical writing",
    "description": "Vertical Align Bottom"
  },
  {
    "name": "vertical_align_center",
    "tagStr": "align alignment arrow center doc down edit editing editor sheet spreadsheet text type up vertical writing",
    "description": "Vertical Align Center"
  },
  {
    "name": "vertical_align_top",
    "tagStr": "align alignment arrow doc edit editing editor sheet spreadsheet text top type up vertical writing",
    "description": "Vertical Align Top"
  },
  {
    "name": "vertical_distribute",
    "tagStr": "alignment distribute format layout lines paragraph rule rules style text vertical",
    "description": "Vertical Distribute"
  },
  {
    "name": "vertical_split",
    "tagStr": "design format grid layout paragraph split text vertical website writing",
    "description": "Vertical Split"
  },
  {
    "name": "vibration",
    "tagStr": "Android OS alert cell device hardware iOS mobile mode motion notification phone silence silent tablet vibrate vibration",
    "description": "Vibration"
  },
  {
    "name": "video_call",
    "tagStr": "+ add call camera chat conference film filming hardware image motion new picture plus symbol video videography",
    "description": "Video Call"
  },
  {
    "name": "video_camera_back",
    "tagStr": "back camera image landscape mountain mountains photo photography picture rear video",
    "description": "Video Camera Back"
  },
  {
    "name": "video_camera_front",
    "tagStr": "account camera face front human image people person photo photography picture profile user video",
    "description": "Video Camera Front"
  },
  {
    "name": "video_label",
    "tagStr": "label screen video window",
    "description": "Video Label"
  },
  {
    "name": "video_library",
    "tagStr": "arrow collection library play video",
    "description": "Video Library"
  },
  {
    "name": "video_settings",
    "tagStr": "change details gear info information options play screen service setting settings video window",
    "description": "Video Settings"
  },
  {
    "name": "video_stable",
    "tagStr": "film filming recording setting stability stable taping video",
    "description": "Video Stable"
  },
  {
    "name": "videocam",
    "tagStr": "cam camera conference film filming hardware image motion picture video videography",
    "description": "Videocam"
  },
  {
    "name": "videocam_off",
    "tagStr": "cam camera conference disabled enabled film filming hardware image motion off offline on picture slash video videography",
    "description": "Videocam Off"
  },
  {
    "name": "videogame_asset",
    "tagStr": "asset console controller device game gamepad gaming playstation video",
    "description": "Videogame Asset"
  },
  {
    "name": "videogame_asset_off",
    "tagStr": "asset console controller device disabled enabled game gamepad gaming off on playstation slash video videogame",
    "description": "Videogame Asset Off"
  },
  {
    "name": "view_agenda",
    "tagStr": "agenda cards design format grid layout stacked view website",
    "description": "View Agenda"
  },
  {
    "name": "view_array",
    "tagStr": "array design format grid layout view website",
    "description": "View Array"
  },
  {
    "name": "view_carousel",
    "tagStr": "cards carousel design format grid layout view website",
    "description": "View Carousel"
  },
  {
    "name": "view_column",
    "tagStr": "column design format grid layout vertical view website",
    "description": "View Column"
  },
  {
    "name": "view_comfy",
    "tagStr": "comfy grid layout pattern squares view",
    "description": "View Comfy"
  },
  {
    "name": "view_compact",
    "tagStr": "compact grid layout pattern squares view",
    "description": "View Compact"
  },
  {
    "name": "view_day",
    "tagStr": "cards carousel day design format grid layout view website",
    "description": "View Day"
  },
  {
    "name": "view_headline",
    "tagStr": "design format grid headline layout paragraph text view website",
    "description": "View Headline"
  },
  {
    "name": "view_in_ar",
    "tagStr": "3d ar augmented cube daydream headset in reality square view vr",
    "description": "View In Ar"
  },
  {
    "name": "view_list",
    "tagStr": "design format grid layout lines list stacked view website",
    "description": "View List"
  },
  {
    "name": "view_module",
    "tagStr": "design format grid layout module square squares stacked view website",
    "description": "View Module"
  },
  {
    "name": "view_quilt",
    "tagStr": "design format grid layout quilt square squares stacked view website",
    "description": "View Quilt"
  },
  {
    "name": "view_sidebar",
    "tagStr": "design format grid layout sidebar view web",
    "description": "View Sidebar"
  },
  {
    "name": "view_stream",
    "tagStr": "design format grid layout lines list stacked stream view website",
    "description": "View Stream"
  },
  {
    "name": "view_week",
    "tagStr": "bars columns design format grid layout view website week",
    "description": "View Week"
  },
  {
    "name": "vignette",
    "tagStr": "border edit editing filter gradient image photo photography setting vignette",
    "description": "Vignette"
  },
  {
    "name": "villa",
    "tagStr": "architecture beach estate home house maps place real residence residential traveling vacation stay villa",
    "description": "Villa"
  },
  {
    "name": "visibility",
    "tagStr": "eye on reveal see show view visability",
    "description": "Visibility"
  },
  {
    "name": "visibility_off",
    "tagStr": "disabled enabled eye off on reveal see show slash view visability",
    "description": "Visibility Off"
  },
  {
    "name": "voice_chat",
    "tagStr": "bubble cam camera chat comment communicate facetime feedback message speech video voice",
    "description": "Voice Chat"
  },
  {
    "name": "voice_over_off",
    "tagStr": "account disabled enabled face human off on over people person profile recording slash speak speaking speech transcript user voice",
    "description": "Voice Over Off"
  },
  {
    "name": "voicemail",
    "tagStr": "call device message missed mobile phone recording voice voicemail",
    "description": "Voicemail"
  },
  {
    "name": "volume_down",
    "tagStr": "audio av control down music quieter shh soft sound speaker tv volume",
    "description": "Volume Down"
  },
  {
    "name": "volume_mute",
    "tagStr": "audio control music mute sound speaker tv volume",
    "description": "Volume Mute"
  },
  {
    "name": "volume_off",
    "tagStr": "audio av control disabled enabled low music mute off on slash sound speaker tv volume",
    "description": "Volume Off"
  },
  {
    "name": "volume_up",
    "tagStr": "audio control music sound speaker tv up volume",
    "description": "Volume Up"
  },
  {
    "name": "volunteer_activism",
    "tagStr": "activism donation fingers gesture giving hand hands heart love sharing volunteer",
    "description": "Volunteer Activism"
  },
  {
    "name": "vpn_key",
    "tagStr": "code key lock network passcode password unlock vpn",
    "description": "Vpn Key"
  },
  {
    "name": "vpn_lock",
    "tagStr": "earth globe lock locked network password privacy private protection safety secure security virtual vpn world",
    "description": "Vpn Lock"
  },
  {
    "name": "vrpano",
    "tagStr": "angle image landscape mountain mountains panorama photo photography picture view vrpano wide",
    "description": "Vrpano"
  },
  {
    "name": "wallpaper",
    "tagStr": "background image landscape photo photography picture wallpaper",
    "description": "Wallpaper"
  },
  {
    "name": "warning",
    "tagStr": "! alert attention caution danger error exclamation important mark notification symbol triangle warning",
    "description": "Warning"
  },
  {
    "name": "warning_amber",
    "tagStr": "! alert amber attention caution danger error exclamation important mark notification symbol triangle warning",
    "description": "Warning Amber"
  },
  {
    "name": "wash",
    "tagStr": "bathroom clean fingers gesture hand wash wc",
    "description": "Wash"
  },
  {
    "name": "watch",
    "tagStr": "Android OS ar clock gadget iOS time vr watch wearables web wristwatch",
    "description": "Watch"
  },
  {
    "name": "watch_later",
    "tagStr": "clock date later schedule time watch",
    "description": "Watch Later"
  },
  {
    "name": "water",
    "tagStr": "aqua beach lake ocean river water waves weather",
    "description": "Water"
  },
  {
    "name": "water_damage",
    "tagStr": "architecture building damage drop droplet estate house leak plumbing real residence residential shelter water",
    "description": "Water Damage"
  },
  {
    "name": "water_drop",
    "tagStr": "",
    "description": "Water Drop"
  },
  {
    "name": "waterfall_chart",
    "tagStr": "analytics bar chart data diagram graph infographic measure metrics statistics tracking waterfall",
    "description": "Waterfall Chart"
  },
  {
    "name": "waves",
    "tagStr": "beach lake ocean pool river sea swim water wave waves",
    "description": "Waves"
  },
  {
    "name": "waving_hand",
    "tagStr": "",
    "description": "Waving Hand"
  },
  {
    "name": "wb_auto",
    "tagStr": "A W alphabet auto automatic balance character edit editing font image letter photo photography symbol text type white wp",
    "description": "Wb Auto"
  },
  {
    "name": "wb_cloudy",
    "tagStr": "balance cloud cloudy edit editing white wp",
    "description": "Wb Cloudy"
  },
  {
    "name": "wb_incandescent",
    "tagStr": "balance bright edit editing incandescent light lighting setting settings white wp",
    "description": "Wb Incandescent"
  },
  {
    "name": "wb_iridescent",
    "tagStr": "balance bright edit editing iridescent light lighting setting settings white wp",
    "description": "Wb Iridescent"
  },
  {
    "name": "wb_shade",
    "tagStr": "balance house light lighting shade wb white",
    "description": "Wb Shade"
  },
  {
    "name": "wb_sunny",
    "tagStr": "balance bright light lighting sun sunny wb white",
    "description": "Wb Sunny"
  },
  {
    "name": "wb_twilight",
    "tagStr": "",
    "description": "Wb Twilight"
  },
  {
    "name": "wc",
    "tagStr": "bathroom closet female male man restroom room wash water wc women",
    "description": "Wc"
  },
  {
    "name": "web",
    "tagStr": "browser internet page screen site web website www",
    "description": "Web"
  },
  {
    "name": "web_asset",
    "tagStr": "-website app application desktop asset browser design download image interface internet layout screen site ui ux video web website window www",
    "description": "Web Asset"
  },
  {
    "name": "web_asset_off",
    "tagStr": "asset browser disabled enabled internet off on page screen slash web webpage website windows www",
    "description": "Web Asset Off"
  },
  {
    "name": "wechat",
    "tagStr": "",
    "description": "Wechat"
  },
  {
    "name": "weekend",
    "tagStr": "chair couch furniture home living lounge relax room weekend",
    "description": "Weekend"
  },
  {
    "name": "west",
    "tagStr": "arrow directional left maps navigation west",
    "description": "West"
  },
  {
    "name": "whatsapp",
    "tagStr": "",
    "description": "Whatsapp"
  },
  {
    "name": "whatshot",
    "tagStr": "arrow circle direction fire frames hot round whatshot",
    "description": "Whatshot"
  },
  {
    "name": "wheelchair_pickup",
    "tagStr": "accessibility accessible body handicap help human person pickup wheelchair",
    "description": "Wheelchair Pickup"
  },
  {
    "name": "where_to_vote",
    "tagStr": "approve ballot check complete destination direction done location maps mark ok pin place poll select stop tick to validate election verified vote where yes",
    "description": "Where To Vote"
  },
  {
    "name": "widgets",
    "tagStr": "app box menu setting squares ui widgets",
    "description": "Widgets"
  },
  {
    "name": "wifi",
    "tagStr": "connection data internet network scan service signal wifi wireless",
    "description": "Wifi"
  },
  {
    "name": "wifi_calling",
    "tagStr": "call calling cell connect connection connectivity contact device hardware mobile phone signal telephone wifi wireless",
    "description": "Wifi Calling"
  },
  {
    "name": "wifi_calling_3",
    "tagStr": "3 calling cell cellular data internet mobile network phone speed wifi wireless",
    "description": "Wifi Calling 3"
  },
  {
    "name": "wifi_lock",
    "tagStr": "cellular connection data internet lock locked mobile network password privacy private protection safety secure security service signal wifi wireless",
    "description": "Wifi Lock"
  },
  {
    "name": "wifi_off",
    "tagStr": "connection data disabled enabled internet network off offline on scan service signal slash wifi wireless",
    "description": "Wifi Off"
  },
  {
    "name": "wifi_protected_setup",
    "tagStr": "around arrow arrows protected rotate setup wifi",
    "description": "Wifi Protected Setup"
  },
  {
    "name": "wifi_tethering",
    "tagStr": "cell cellular connection data internet mobile network phone scan service signal speed tethering wifi wireless",
    "description": "Wifi Tethering"
  },
  {
    "name": "wifi_tethering_error_rounded",
    "tagStr": "! alert attention caution cell cellular connection danger data error exclamation important internet mark mobile network notification phone rounded scan service signal speed symbol tethering warning wifi wireless",
    "description": "Wifi Tethering Error Rounded"
  },
  {
    "name": "wifi_tethering_off",
    "tagStr": "cell cellular connection data disabled enabled internet mobile network off offline on phone scan service signal slash speed tethering wifi wireless",
    "description": "Wifi Tethering Off"
  },
  {
    "name": "window",
    "tagStr": "close glass grid home house interior layout outside window",
    "description": "Window"
  },
  {
    "name": "wine_bar",
    "tagStr": "alcohol bar cocktail cup drink glass liquor wine",
    "description": "Wine Bar"
  },
  {
    "name": "woman",
    "tagStr": "",
    "description": "Woman"
  },
  {
    "name": "woo_commerce",
    "tagStr": "",
    "description": "Woo Commerce"
  },
  {
    "name": "wordpress",
    "tagStr": "",
    "description": "Wordpress"
  },
  {
    "name": "work",
    "tagStr": "-briefcase bag baggage business case job suitcase work",
    "description": "Work"
  },
  {
    "name": "work_off",
    "tagStr": "bag baggage briefcase business case disabled enabled job off on slash suitcase work",
    "description": "Work Off"
  },
  {
    "name": "work_outline",
    "tagStr": "bag baggage briefcase business case job suitcase work",
    "description": "Work Outline"
  },
  {
    "name": "workspace_premium",
    "tagStr": "",
    "description": "Workspace Premium"
  },
  {
    "name": "workspaces",
    "tagStr": "",
    "description": "Workspaces"
  },
  {
    "name": "wrap_text",
    "tagStr": "arrow writing doc edit editing editor sheet spreadsheet text type wrap write writing",
    "description": "Wrap Text"
  },
  {
    "name": "wrong_location",
    "tagStr": "cancel close destination direction exit location maps no pin place quit remove stop wrong x",
    "description": "Wrong Location"
  },
  {
    "name": "wysiwyg",
    "tagStr": "composer mode screen site software system text view visibility web website window wysiwyg",
    "description": "Wysiwyg"
  },
  {
    "name": "yard",
    "tagStr": "backyard flower garden home house nature pettle plants yard",
    "description": "Yard"
  },
  {
    "name": "youtube_searched_for",
    "tagStr": "arrow back backwards find glass history inprogress load loading look magnify magnifying refresh renew restore reverse rotate search see youtube",
    "description": "Youtube Searched For"
  },
  {
    "name": "zoom_in",
    "tagStr": "big bigger find glass grow in look magnify magnifying plus scale search see size zoom",
    "description": "Zoom In"
  },
  {
    "name": "zoom_out",
    "tagStr": "find glass look magnify magnifying minus negative out scale search see size small smaller zoom",
    "description": "Zoom Out"
  },
  {
    "name": "zoom_out_map",
    "tagStr": "arrow arrows destination location maps move out place stop zoom",
    "description": "Zoom Out Map"
  }
]