<template>
    <v-dialog
            v-model="valuePassOver"
            fullscreen
            persistent
    >
        <v-card class="scrollable" style="height: 100vh; overflow-y: auto; overflow-x: hidden; background: var(--page-back)">
            <v-layout column fill-height class="scrollable">
<!--Title-->
                <v-flex shrink class="title-color">
                    <v-layout row align-center>
                        <v-flex xs12>
                            <v-layout row align-center>
                                <v-flex shrink>
                                    <v-icon class="pl-2" color="white">source</v-icon>
                                </v-flex>
                                <v-flex xs12 class="font-weight-regular white--text px-2 py-1 title">
                                    Document Store Manager
                                </v-flex>
                            </v-layout>
                        </v-flex>
                        <v-flex shrink class="px-2">
                            <v-btn
                                    icon
                                    flat
                                    color="white"
                                    class="pa-0 ma-0"
                                    @click="valuePassOver = !valuePassOver"
                            >
                                <v-icon>close</v-icon>
                            </v-btn>
                        </v-flex>
                    </v-layout>
                </v-flex>
<!--Loading-->
                <v-flex xs12 fill-height v-if="loadingRepo">
                    <v-layout fill-height align-center justify-center>
                        <v-flex shrink>
                            <v-progress-circular
                                    :size="40"
                                    :width="3"
                                    color="primary"
                                    indeterminate
                            ></v-progress-circular>
                        </v-flex>
                    </v-layout>
                </v-flex>
<!--Body-->
                <v-flex xs12 fill-height class="scrollable" v-else>
<!--Select And Manage Repo Items-->
                    <v-layout row fill-height class="scrollable">
                        <v-flex xs4 fill-height class="py-2 pl-2 pr-1" style="overflow-y: auto; overflow-x: hidden">
                            <v-card class="fill-height card-back" style="overflow-y: auto; overflow-x: hidden">
                                <repo-tree
                                        ref="repoTree"
                                        :tree="tree"
                                        :docs="docs"
                                        :folders="folders"
                                        @searchStr="searchStr = $event"
                                        :search-str="searchStr"
                                        :get-parents-fn="getParents"
                                        @updateFolder="updateFolder($event)"
                                        @deleteFolder="deleteFolder($event)"
                                        @deleteDoc="deleteDoc($event)"
                                        @addDocument="addDocument($event)"
                                        @updateDocument="updateDocument($event)"
                                        v-model="selectedDocID"
                                />
                            </v-card>
                        </v-flex>

                        <v-flex xs8 fill-height class="py-2 pl-1 pr-2" style="overflow-y: auto; overflow-x: hidden" v-if="selectedDocID">
                            <v-card class="fill-height white" style="overflow-y: auto; overflow-x: hidden">
                                <document-repository-manager-detail
                                        :selectedDocID="selectedDocID"
                                        :docs="docs"
                                        @updateDocument="updateDocument($event)"
                                        @restore="$refs.repoTree.restoreItem($event)"
                                        @delete="$refs.repoTree.removeItem($event)"
                                />
                            </v-card>
                        </v-flex>
                    </v-layout>
                </v-flex>
            </v-layout>
        </v-card>
    </v-dialog>
</template>

<script>
import {mapGetters} from 'vuex'
import RepoTree from "@/components/General/Repo Tree/RepoTree";
import {arrSplice, objectifyArr} from "@/codeFunctions";
import DocumentRepositoryManagerDetail from "@/Admin/Document Repository Manager/DocumentRepositoryManagerDetail";
import {getRepoTree} from "@/Document Repository/docRepoMan";

export default {
    components: {DocumentRepositoryManagerDetail, RepoTree},
    props: {
        value: Boolean
    },
    data() {
        return {
            loadingRepo: true,
            folders: null,
            docs: null,
            tree: null,
            searchStr: null,
            selectedDocID: null
        }
    },
    watch: {
        value: {
            immediate: true,
            handler(val) {
                if (val)
                    this.refreshRepoData()
            }
        },

        searchStr() {
            this.refreshTree();
        }
    },
    methods: {
        getParents(folderID, arrParents = [], refFolders = null) {
            try {
                if (!folderID || folderID === 0)
                    return arrParents
                refFolders = refFolders || objectifyArr(this.folders, 'id')
                if (refFolders[folderID]) {
                    arrParents.push(refFolders[folderID].key)
                    if (refFolders[folderID].parent)
                        return this.getParents(refFolders[folderID].parent, arrParents, refFolders)
                    return arrParents
                }
                return arrParents
            } catch (e) {
                console.log(e)
                return arrParents
            }
        },

        dropToDoc(docID) {
            try {
                let doc = this.docs.filter(doc => doc.id === docID).pop()
                // Gen All Parent Keys
                if (doc.parent) {
                    let parentKeys = this.getParents(doc.parent)
                    parentKeys.forEach(key => {
                        if (!this.$refs.repoTree.dropKeys.includes(key))
                            this.$refs.repoTree.dropKeys.push(key)
                    })
                }
            } catch (e) {
                console.log('Drop Error', e)
            }
        },

        dropToFolder(folderID) {
            try {
                let folder = this.folders.filter(folder => folder.id === folderID).pop()
                // Gen All Parent Keys
                if (folder.parent) {
                    let parentKeys = this.getParents(folder.parent)
                    parentKeys.forEach(key => {
                        if (!this.$refs.repoTree.dropKeys.includes(key))
                            this.$refs.repoTree.dropKeys.push(key)
                    })
                }
            } catch (e) {
                console.log('Drop Error', e)
            }
        },

        addDocument(doc) {
            this.docs.push(doc)
            this.refreshTree()
            this.dropToDoc(doc.id)
        },

        deleteDoc(docID) {
            arrSplice(this.docs, (doc) => doc.id === docID)
            this.refreshTree()
        },

        deleteFolder(folderID) {
            arrSplice(this.folders, (folder) => folder.id === folderID)
            this.refreshTree()
        },

        updateDocument(editedDoc) {
            for (let doc of this.docs) {
                if (doc.id === editedDoc.id) {
                    Object.assign(doc, editedDoc);
                    this.refreshTree()
                    this.dropToDoc(editedDoc.id)
                    return;
                }
            }
            this.docs.push(editedDoc)
            this.refreshTree()
            this.dropToDoc(editedDoc.id)
        },

        updateFolder(editedFolder) {
            for (let folder of this.folders) {
                if (folder.id === editedFolder.id) {
                    Object.assign(folder, editedFolder);
                    this.refreshTree()
                    this.dropToFolder(editedFolder.id)
                    return;
                }
            }
            this.folders.push(editedFolder)
            this.refreshTree()
            this.dropToFolder(editedFolder.id)
        },

        refreshRepoData() {
            this.loadingRepo = true;
            this.$newReq('get', `docRepo/ManagerStartup/${this.userInfo.entityID}`)
                .then(data => {
                    this.folders = data.folders;
                    this.docs = data.docs;
                    this.refreshTree()
                })
                .catch((e) => {
                    console.log(e)
                    this.$snack.networkError()
                })
                .finally(() => this.loadingRepo = false)
        },
        refreshTree() {
            this.tree = getRepoTree(this.folders, this.docs, false, this.searchStr).children
        },
    },
    computed: {
        ...mapGetters('userInfo', ['userInfo']),
        valuePassOver: {
            get() {
                return this.value
            },
            set(val) {
                this.$emit('input', val)
            }
        }
    },
}
</script>

<style scoped>

</style>