<template>
    <v-layout column class="scrollable">
                <v-flex shrink class="scrollable pa-1">
<!--Description-->
                    <div :class="topicClass">
                        <div :class="headingClass">Field Description:</div>
                        <div :class="itemClass">
                            <v-text-field
                                    label="Field Description"
                                    single-line
                                    color="primary"
                                    hide-details
                                    style="width: 100%"
                                    class="pa-0 ma-0"
                                    ref="stepDescription"
                                    v-model="field.description"
                            />
                        </div>
                    </div>
<!--Visibility And Required-->
                    <v-layout row>
<!--Visibility-->
                        <v-flex xs3 >
                            <div :class="topicClass" style="overflow-y: hidden">
                                <div :class="headingClass">
                                    Visible:
                                </div>
                                <div :class="itemClass">
                                    <v-switch
                                            v-model="visible"
                                            hide-details
                                            color="secondary"
                                            class="pa-0 ma-0"
                                    />
                                </div>
                            </div>
                        </v-flex>
<!--Auto Expand Group-->
                        <v-flex xs5 v-if="field.type === 16 && showFieldDrop" >
                            <div :class="topicClass" style="overflow-y: hidden">
                                <div :class="headingClass">
                                    Expanded By Default:
                                </div>
                                <div :class="itemClass">
                                    <v-switch
                                            v-model="lookup"
                                            hide-details
                                            color="secondary"
                                            class="pa-0 ma-0"
                                    />
                                </div>
                            </div>
                        </v-flex>
<!--Required-->
                        <v-flex xs3 v-if="field.type !== 16">
                            <div :class="topicClass" style="overflow-y: hidden">
                                <div :class="headingClass">
                                    Required:
                                </div>

                                <div :class="itemClass">
                                    <v-switch
                                            v-model="required"
                                            hide-details
                                            color="secondary"
                                            class="pa-0 ma-0"
                                    />
                                </div>
                            </div>
                        </v-flex>
<!--Required-->
                        <v-flex xs3 v-if="field.type !== 16">
                            <div :class="topicClass" style="overflow-y: hidden">
                                <div :class="headingClass">
                                    Distinct:
                                </div>

                                <div :class="itemClass">
                                    <v-switch
                                            v-model="distinct"
                                            hide-details
                                            color="secondary"
                                            class="pa-0 ma-0"
                                    />
                                </div>
                            </div>
                        </v-flex>
<!--Use In Name-->
                        <v-flex xs3 v-if="field.type !== 16">
                            <div :class="topicClass" style="overflow-y: hidden">
                                <div :class="headingClass">
                                    Use In Name:
                                </div>

                                <div :class="itemClass">
                                    <v-switch
                                            v-model="useInName"
                                            hide-details
                                            color="secondary"
                                            class="pa-0 ma-0"
                                    />
                                </div>
                            </div>
                        </v-flex>
                    </v-layout>
<!--Field Type-->
                    <div :class="topicClass">
                        <div :class="headingClass">Field Type:</div>
                        <div :class="itemClass">
                            <v-autocomplete
                                    dense
                                    v-model="field.type"
                                    :items="fieldTypes"
                                    label="Field Type"
                                    hide-details
                                    single-line
                                    color="primary"
                                    item-text="description"
                                    item-value="id"
                                    class="pa-0 ma-0"
                                    :disabled="!createField"
                            />
                        </div>
                    </div>
<!--Field Type 2-->
                    <div :class="topicClass" v-if="[2].includes(field.type)">
                        <field-type2-configure
                                :user-info="userInfo"
                                v-model="fullLookup"
                                :show-default-value-switch="false"
                        />
                    </div>
<!--Field Type 5-->
                    <div :class="topicClass" v-if="[5].includes(field.type)">
                        <div :class="headingClass">
                            List Options:
                        </div>
                        <div :class="itemClass">
                            <app-field-type6-configure
                                    :user-info="userInfo"
                                    :select-lists="selectLists"
                                    v-model="fullLookup"
                            />
                        </div>
                    </div>
<!--Shown To-->
                    <div :class="topicClass" v-if="field.type !== 16">
                        <div :class="headingClass">Field Shown To:</div>
                        <div :class="itemClass">
                            <app-workflow-manager-group-selector
                                    :user-groups="userGroups"
                                    btn-label="Shown to All"
                                    ac-label="Shown to All"
                                    v-model="securityShownTo"
                            />
                        </div>
                    </div>
<!--Editable By-->
                    <div :class="topicClass" v-if="field.type !== 16">
                        <div :class="headingClass">Field Editable By:</div>
                        <div :class="itemClass">
                            <app-workflow-manager-group-selector
                                    :user-groups="userGroups"
                                    btn-label="Editable by All"
                                    ac-label="Editable by All"
                                    v-model="securityEditableBy"
                            />
                        </div>
                    </div>
<!--Group Field-->
                    <div :class="topicClass" v-if="field.type === 16 && showFieldDrop">
                        <div :class="headingClass">Grouped Fields:</div>
                        <div :class="itemClass">

                            <dataset-group-manage
                                    :value="value"
                                    :user-info="userInfo"
                                    :save-fields="saveFields"
                                    @addedFieldToGroup="$emit('addedFieldToGroup', $event)"
                                    :all-fields="allFields"
                                    @addedFieldRemovedFromGroup="$emit('addedFieldRemovedFromGroup', $event)"
                            />
                        </div>
                    </div>


                </v-flex>
                <v-flex shrink>
                    <div class="text-xs-center caption font-weight-bold red--text" v-if="saveMessage != null">
                        {{saveMessage}}
                    </div>
                    <v-divider/>
                </v-flex>
                <v-flex shrink class="pa-2 text-xs-right">
                    <v-btn
                            small
                            color="secondary"
                            :disabled="saveMessage != null || savingChanges"
                            :loading="savingChanges"
                            class="ma-0"
                            @click="saveChanges"
                    >
                        Save Changes
                    </v-btn>
                </v-flex>
            </v-layout>
</template>

<script>
    import {codeBaseMixin} from "../../../codeBaseMixin";
    import WorkflowManagerGroupSelector
        from "../../WorkflowEditor/Components/WorkflowManager/Workflow/Components/WorkflowManagerGroupSelector";
    import FieldType6Configure
        from "../../WorkflowEditor/Components/WorkflowManager/Workflow/Components/FieldTypeConfigureFields/FieldType6Configure";
    import DatasetGroupManage from "./DatasetGroupManage";
    import FieldType2Configure
        from "@/Admin/WorkflowEditor/Components/WorkflowManager/Workflow/Components/FieldTypeConfigureFields/FieldType2Configure";

    export default {
        components: {
            FieldType2Configure,
            DatasetGroupManage,
            appFieldType6Configure: FieldType6Configure,
            appWorkflowManagerGroupSelector: WorkflowManagerGroupSelector
        },
        mixins: [codeBaseMixin],
        props: {
            userInfo: Object,
            value: Object,
            userGroups: Array,
            selectLists: Array,
            saveFields: Array,
            createField: {
                type: Boolean,
                default: false,
            },
            allFields: Array,
        },
        data() {
            return {
                field: null,
                fieldTypes: [
                    {id: 1, description: 'Word Field'},
                    {id: 2, description: 'Date Field'},
                    {id: 3, description: 'Number Field'},
                    {id: 4, description: 'Currency Field'},
                    {id: 5, description: 'List Field'},
                    {id: 6, description: 'Mail/Document Field'},
                    {id: 13, description: "Note Field"},
                    {id: 16, description: "Field Group"},
                ],
            }
        },
        watch: {
            value: {
                immediate: true,
                handler(newVal) {
                    if (!this.createField) {
                        this.field = JSON.parse(JSON.stringify(newVal));
                    } else {
                        if (this.field == null) {
                            this.field = {
                                id: -1,
                                description: null,
                                visible: 1,
                                type: 1,
                                distinct: 0,
                                useInName: 0,
                                required: 0,
                                lookupType: null,
                                lookup: null,
                                securityLookup: null,
                                fk_field_set: newVal.fk_field_set
                            }
                        }
                    }
                }
            }
        },
        methods: {
            saveChanges() {
                let tField = JSON.parse(JSON.stringify(this.field));
                this.$emit('startSave', this.field.id);
                this.fetch({
                    method: 'PATCH', url: `/datasetmanager/datasetfields/${this.userInfo.entityID}`, body: JSON.stringify({
                        id: tField.id != -1 ? tField.id : null,
                        fk_field_set: tField.fk_field_set,
                        description: tField.description,
                        visible: tField.visible,
                        type: tField.type,
                        distinct: tField.distinct,
                        useInName: tField.useInName,
                        required: tField.required,
                        lookupType: tField.lookupType,
                        lookup: tField.lookup,
                        securityLookup: tField.securityLookup
                    })
                })
                    .then((data) => {
                        this.$emit('patch', data);
                        this.showSnack('Info', 'Changes Saved', 'Close', 'primary')
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e);
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
                        this.$emit('saveFailed', tField.id);
                    })

            },


        },
        computed: {

            showFieldDrop() {
                return this.field.type === 16 && typeof this.value.id != 'undefined' && this.value.id != null && this.value.id !== 0
            },

            visible: {
                get: function () {
                    return this.intToBool(this.field.visible)
                },
                set: function (newValue) {
                    this.field.visible = this.boolToInt(newValue)
                }
            },
            required: {
                get: function () {
                    return this.intToBool(this.field.required)
                },
                set: function (newValue) {
                    this.field.required = this.boolToInt(newValue)
                }
            },
            useInName: {
                get: function () {
                    return this.intToBool(this.field.useInName)
                },
                set: function (newValue) {
                    this.field.useInName = this.boolToInt(newValue)
                }
            },
            distinct: {
                get: function () {
                    return this.intToBool(this.field.distinct)
                },
                set: function (newValue) {
                    this.field.distinct = this.boolToInt(newValue)
                }
            },

            saveMessage() {
                if (this.blankString(this.field.description)) {
                    return 'Field Description can not be blank'
                } else {
                    if (this.field.type == null || this.field.type == 0) {
                        return 'Please Select a Field Type'
                    } else {
                        return null
                    }
                }
            },


            lookup: {
                get: function () {
                    return this.field.lookup ? JSON.parse(this.field.lookup) : false
                },
                set: function (newValue) {
                    this.field.lookup = newValue ? 1 : 0
                }
            },


            fullLookup: {
                get: function () {
                    return {
                        lookup_type: this.field.lookupType,
                        lookup: this.field.lookup
                    }
                },
                set: function (newValue) {
                    this.field.lookupType = newValue != null && typeof newValue.lookup_type != "undefined" ? newValue.lookup_type : null;
                    this.field.lookup = newValue != null && typeof newValue.lookup != "undefined" ? newValue.lookup : null;
                }
            },
            securityShownTo: {
                get: function () {
                    return this.field.securityLookup != null && typeof this.field.securityLookup.shownTo != "undefined" ? this.field.securityLookup.shownTo : []
                },
                set: function (newValue) {
                    if (this.field.securityLookup == null) {this.field.securityLookup = {}}
                    this.field.securityLookup.shownTo = newValue;
                    this.field.securityLookup = this.field.securityLookup != null ? JSON.parse(JSON.stringify(this.field.securityLookup)) : null;
                }
            },
            securityEditableBy: {
                get: function () {
                    return this.field.securityLookup != null && typeof this.field.securityLookup.editableBy != "undefined" ? this.field.securityLookup.editableBy : []
                },
                set: function (newValue) {
                    if (this.field.securityLookup == null) {this.field.securityLookup = {}}
                    this.field.securityLookup.editableBy = newValue;
                    this.field.securityLookup = this.field.securityLookup != null ? JSON.parse(JSON.stringify(this.field.securityLookup)) : null;
                }
            },
            savingChanges() {
                return this.saveFields.includes(this.field.id)
            }
        }
    }
</script>

<style scoped>

</style>
