<template>
  <v-dialog :fullscreen="true" persistent v-model="shown">
    <v-card class="c-d-flex c-flex-column of-y" style="height: 100vh">
      
      <div class="c-d-flex c-align-center px-2">
        
        <div class="pa-1">
          <v-btn
              icon
              @click="shown = false"
          ><v-icon>arrow_back</v-icon></v-btn>
        </div>
        
        <div class="c-flex-grow-1 px-1">
          <div class="c-d-flex title">{{title}}</div>
          <div class="c-d-flex subheading" v-if="subtitle">{{subtitle}}</div>
        </div>
        
        <div class="pa-1">
          <a :href="`${serverAddress}/download/document/${documentId}/${filename}/${token()}`" target="_blank">
            <v-btn
                icon
            >
              <v-icon>download</v-icon>
            </v-btn>
          </a>
        </div>
      </div>
      
      
      <v-divider></v-divider>
      <div class="c-flex-grow-1 fill-height c-d-flex c-align-center c-justify-center">
        
        <v-progress-circular
            v-if="loadingImage"
            :size="40"
            :width="5"
            color="primary"
            :indeterminate="!downloadProgress"
            :value="downloadProgress"
        ></v-progress-circular>
        
        <img v-else style="max-height: 100%; max-width: 100%" :src="imgUrl" alt="Image">
        
      </div>
    </v-card>
    
  </v-dialog>
  
  
</template>

<script>
import {eventBus} from "../../main";
import {serverAddress} from "@/axiosRequest";
import {token} from "@/tokenManSite";
import axios from "axios";

export default {
  data() {
    return {
      shown: false,
      title: null,
      subtitle: null,
      documentId: null,
      filename: null,
      loadingImage: null,
      downloadProgress: null,
      imgUrl: null,
      serverAddress
    }
  },
  
  created() {
    eventBus.$on('imagePreview', this.showImage)
  },
  beforeDestroy() {
    eventBus.$off('imagePreview', this.showImage)
  },
  
  methods: {
    token,
    showImage({title, subtitle, documentId, filename}) {
      this.imgUrl = null;
      this.title = title || null;
      this.subtitle = subtitle || null;
      this.documentId = documentId || null;
      this.filename = filename || null;
      this.loadingImage = true
      this.downloadProgress = 0;
      this.loadImage()
      this.shown = true
    },
    
    
    async loadImage() {
      try {
        this.loadingImage = true
        const response = await axios.get(`${serverAddress}/download/document/${this.documentId}/${this.filename}/${token()}`, {
          responseType: 'blob',
          onDownloadProgress: (progressEvent) => {
            this.downloadProgress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          },
        });
        const imageBlob = response.data;
        this.imgUrl = URL.createObjectURL(imageBlob);
      } catch (e) {
        console.log(e)
      } finally {
        this.loadingImage = false
      }
    }
  },
  
  
  
}
//.image-container {
//  max-width: 100%;
//  max-height: 100%;
//  overflow: hidden;
//  border: 1px solid #ccc;
//}
//
//.image-container img {
//
//  max-height: 100%;
//  border: 3px solid fuchsia;
//}
</script>

<style scoped>

</style>