<template>
    <v-layout row align-center>
        <v-flex shrink>
            <div style="width: 10px; height: 2px" class="primary">

            </div>
        </v-flex>
        <v-flex shrink class="primary--text body-2 text-xs-center" style="width: 40px">
            <template v-if="!firstItem">
                {{wrapper.function}}
            </template>
            <template v-else>
                <div style="width: 100%; height: 2px" class="primary">

                </div>
            </template>
        </v-flex>
        <v-flex xs12 class="pr-1">
            <div v-for="(wrapperChild, index) in wrapper.children">
<!--                {{wrapperChild}}-->
                <v-layout row align-space-around>
                    <v-flex shrink v-if="wrapper.children.length > 0">
<!--                        <v-layout column fill-height>-->
<!--                            <v-flex xs12 class="bord">col</v-flex>-->
<!--                        </v-layout>-->
                        <div style="width: 2px; height: 50%" :class="index > 0 ? 'primary' : ''">

                        </div>
                        <div style="width: 2px; height: 50%" :class="index < (wrapper.children.length - 1) ? 'primary' : ''">

                        </div>
                    </v-flex>
                    <v-flex xs12 v-if="wrapperChild.type == 'C'" class="py-1">
                        <document-rule-condition
                                @spliceMe="removeConditionChild(index)"
                                :flat-fields="flatFields"
                                v-model="wrapperChild.function"
                                :select-lists="selectLists"
                        />
                    </v-flex>
                    <v-flex xs12 v-else class="py-1">
                        <wrapper
                                @spliceMe="removeConditionChild(index)"
                                :wrapper="wrapperChild"
                                :flatFields="flatFields"
                                :selectLists="selectLists"
                                :first-item="index == 0"
                        >
                        </wrapper>
                    </v-flex>
                    <v-flex shrink v-if="wrapper.children.length > 0" >
                        <!--                        <v-layout column fill-height>-->
                        <!--                            <v-flex xs12 class="bord">col</v-flex>-->
                        <!--                        </v-layout>-->
                        <div
                                style="width: 8px; height: 100%; border-right: 2px solid var(--v-primary-base);  "
                                :style="(index >= (wrapper.children.length - 1) ? 'border-bottom: 2px solid var(--v-primary-base);' : '') + (index == 0 ? 'border-top: 2px solid var(--v-primary-base);' : '')"
                        >


                        </div>
                    </v-flex>
                </v-layout>
            </div>

            <div v-if="wrapper.children.length <= 0 || addCondition">
                <document-rule-condition
                        :flat-fields="flatFields"
                        v-model="saveField"
                        :initial-startup-edit-mode="true"
                        :select-lists="selectLists"
                        @input="addChild($event)"
                        @spliceMe="addCondition ? addCondition = false : $emit('spliceMe')"
                />
            </div>
        </v-flex>
        <v-flex shrink class="pr-2" v-if="wrapper.children.length >= 1">
            <document-rule-add-btn
                    @input="addWrapper($event)"
                    :show-condition="wrapper.children.filter(obj => obj.type == 'W').length >= 1 ? false : true"
            />
        </v-flex>

    </v-layout>
</template>

<script>
    import DocumentRuleAddBtn from "./DocumentRuleAddBtn";
    import DocumentRuleCondition from "./DocumentRuleCondition";
    export default {
        name: "wrapper",
        components: {
            DocumentRuleCondition,
            DocumentRuleAddBtn
        },
        props: {
            wrapper: Object,
            flatFields: Object,
            selectLists: Array,
            firstItem: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                saveField: null,
                addCondition: false
            }
        },
        methods: {
            removeConditionChild(index) {
                this.wrapper.children.splice(index, 1);
                if (this.wrapper.children.length <= 0) {
                    this.$emit('spliceMe')
                }
            },
            addWrapper(type) {
                if (type == 'condition') {
                    this.saveField = null;
                    this.addCondition = true;
                } else {
                    // Create A Wrapper For The Existing Fields And Add The Existing Forlds To That Wrapper
                    let tFields = this.wrapper.children.filter(obj => obj.type != 'W');
                    this.wrapper.children = this.wrapper.children.filter(obj => obj.type == 'W');
                    if (tFields.length >= 1) {
                        this.wrapper.children.push({
                            type: 'W',
                            function: 'AND',
                            children: tFields
                        });
                    }
                    this.wrapper.children.push({
                        type: 'W',
                        function: type,
                        children: []
                    })
                }


            },
            addChild(field) {
                this.wrapper.children.push(
                    {
                        type: 'C',
                        function: field
                    }
                );
                this.addCondition = false;
            }
        }
    }
</script>

<style scoped>

</style>
