<template>
  
  <div class="fill-height c-d-flex">
    <template v-if="startupLoading">
      <div class="c-d-flex c-align-center c-justify-center c-flex-grow-1 fill-height">
        <v-progress-circular
            :size="40"
            :width="5"
            color="primary"
            indeterminate
        ></v-progress-circular>
      </div>
    </template>
    <template v-else>
      <div style="width: 50%" class="fill-height of-y">
        <div style="position: sticky; top: 0; z-index: 9" class="card-back">
          <div class="pa-2">
            <v-text-field
                class="page-back"
                solo
                hide-details
                prepend-inner-icon="search"
                placeholder="Search Channels"
                v-model="searchStr"
                :clearable="true"
            ></v-text-field>
          </div>
          
          <div class="px-2 pb-2">
            <v-btn
                block
                outline
                color="secondary"
                @click="showAddRule = true"
            >
              <v-icon left>add</v-icon>
              Add Rule
            </v-btn>
          </div>
          
          
          <div>
            <v-divider></v-divider>
          </div>
        </div>
        <v-list>
          
          <v-list-tile
              v-for="rule in filteredRules"
              :key="rule.id"
              color="primary"
              :value="selectedRule === rule.id"
              @click="selectedRule === rule.id ? selectedRule = null : selectedRule = rule.id"
              active-class="someActive"
          >
            <v-list-tile-avatar><v-icon>gavel</v-icon></v-list-tile-avatar>
            <v-list-tile-content>
              <v-list-tile-title style="color: var(--on-surface)">{{ rule.description }}</v-list-tile-title>
              <v-list-tile-sub-title>{{rule.linkHash}}</v-list-tile-sub-title>
            </v-list-tile-content>
          </v-list-tile>
        
        
        </v-list>
      </div>
      <div style="width: 50%" class=" fill-height of-y">
        <app-contacts-link-rules-configure
            v-if="showAddRule"
            :apps="apps"
            @cancelClicked="showAddRule = false"
            @input="upsertRule"
        />
        
        <template v-else-if="selectedRule">
          <app-contacts-link-rules-configure
              :apps="apps"
              :edit-rule="selectedRuleObject"
              @input="upsertRule"
              @deleted="ruleDeleted"
          />
          <app-contacts-link-rule-info :apps="apps" :link-rule="selectedRuleObject"/>
        </template>
      </div>
    </template>
  </div>

</template>

<script>

import {arrSplice} from "@/codeFunctions";
import AppContactsCustomFieldConfig from "@/contacts/AppContactsCustomFieldConfig.vue";
import draggable from 'vuedraggable';
import appInputDlg from "@/components/General/InputDlg.vue";
import AppContactsCustomFields from "@/contacts/AppContactsCustomFields.vue";
import AppContactsChannelConfig from "@/contacts/AppContactsChannelConfig.vue";
import AppContactsLinkRulesConfigure from "@/contacts/Link Rules/AppContactsLinkRulesConfigure.vue";
import AppContactsLinkRuleInfo from "@/contacts/Link Rules/AppContactsLinkRuleInfo.vue";

const blankField = {
  id: null,
  description: null,
  visible: 1,
  type: 0,
  lookup: null,
  order: null
}

export default {
  components: {
    AppContactsLinkRuleInfo,
    AppContactsLinkRulesConfigure,
    AppContactsChannelConfig, AppContactsCustomFields, appInputDlg, AppContactsCustomFieldConfig, draggable},
  props: {
    selectLists: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      startupLoading: false,
      showAddRule: null,
      loadingAddChannel: false,
      selectedRule: null,
      searchStr: null,
      apps: [],
      rules: []
    }
  },
  watch: {
    showAddRule(val) {
      if (val) {
        this.selectedRule = null
      }
    },
    selectedRule(val) {
      if (val) {
        this.showAddRule = false
      }
    }
  },
  
  mounted() {
    this.init();
  },
  
  methods: {
    
    ruleDeleted(ruleId) {
      this.selectedRule = null;
      this.rules = this.rules.filter(v => v.id !== ruleId)
    },
    
    upsertRule(updatedRule) {
      const existingRule = this.rules.find(v => v.id === updatedRule.id)
      if (!existingRule) {
        this.rules.push(updatedRule)
        this.selectedRule = updatedRule.id
      } else {
        Object.assign(existingRule, updatedRule)
      }
      this.showAddRule = false
    },
    
    async init() {
      try {
        this.startupLoading = true
        const {rules, apps} = await this.$newReq('GET', `appLinkRules/setup`)
        this.rules = rules
        this.apps = apps
      } catch (e) {
        console.log(e)
        this.$snack.networkError();
      } finally {
        this.startupLoading = false
      }
    },
    
    async addNewChannel(internalName) {
      
      if (!internalName)
        return this.$snack.error('Internal name is required');
      
      if (this.channels.find(exCha => exCha.internalName.toLowerCase() === internalName.toLowerCase()))
        return this.$snack.error('Duplicate Channel Name');
      
      try {
        this.loadingAddChannel = true
        const newChannel = await this.$newReq('POST', `channels/setup/create`, {internalName, disabled: true})
        this.$snack.info('Channel added')
        this.showAddRule = false;
        this.channels.push(newChannel)
        this.selectedRule = newChannel.id
      } catch (e) {
        console.log(e)
        this.$snack.networkError();
      } finally {
        this.loadingAddChannel = false
      }
    },
    
    
    
  },
  computed: {
    selectedRuleObject() {
      return this.rules.find(val => val.id === this.selectedRule)
    },
    
    sortedRules() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.rules.sort((a,b) => a.description > b.description ? 1 : -1)
    },
    
    filteredRules() {
      return this.sortedRules.filter(v => !this.searchStr || v.description.toLowerCase().includes(this.searchStr.toLowerCase()))
    },
  },
}
</script>


<style>

.someActive {
  //background: fuchsia !important;
  background: #00695C20;
}

.selected-tile-background {
  background-color: var(--v-primary-base);
  color: red;
}


</style>