<template>
  <div :key="typeof item.groupObject != 'undefined' ? item.title : item.taskID">
    <template v-if="typeof item.groupObject != 'undefined'">
      <div style="height: 60px">
        
        <div :class="{'dropped': item.dropped}" class="c-d-flex c-align-center task-group" @click="toggleGrouping(item)" style="cursor: pointer; height: 59px">
          
          <div class="px-2 pl-3">
            <img v-if="filterSettings.groupOption == 1" src="../../../../public/users.svg" alt="" width="24">
            <img v-if="filterSettings.groupOption == 6" src="../../../../public/sub_users.svg" alt=""
                 width="24">
            <img v-if="filterSettings.groupOption == 2" src="../../../../public/workflows.svg" alt=""
                 width="24">
            <v-icon v-if="filterSettings.groupOption == 5" class="yellow--text text--darken-2">
              folder_shared
            </v-icon>
            <v-icon v-if="filterSettings.groupOption == 0" class="yellow--text text--darken-2">folder
            </v-icon>
          </div>
          
          <div class="c-flex-grow-1 text-truncate body-2">
            <div class="pb-1">{{ !item.title || item.title === 'null' || item.title === 'null null' ? '-' : item.title }}</div>
<!--            <v-flex xs12 style="overflow-x: hidden" class="text-truncate body-1 pl-1"-->
<!--                    :class="`${item.dropped ? 'white&#45;&#45;text' : ''} ${!item.title || item.title === 'null' || item.title === 'null null' ? ' clear-text placeholder-content rounded-border pr-2 ml-1' : ''}`">-->
<!--              {{ !item.title || item.title === 'null' || item.title === 'null null' ? '-' : item.title }}-->
<!--            </v-flex>-->
            <div class="caption ">
              {{item.count}} Task{{item.count > 1 ? 's' : ''}}
            </div>
            
          </div>
          
          <div class="px-2">
            <v-icon>{{ item.dropped ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}</v-icon>
          </div>
          
        </div>
        
        
<!--        <v-layout-->
<!--            @click="toggleGrouping(item)"-->
<!--            row align-center class="groupHover py-1" :class="item.dropped ? 'primary' : ''">-->
<!--          <v-flex class="pl-1" shrink>-->
<!--          -->
<!--          </v-flex>-->
<!--          <v-flex xs12 style="overflow-x: hidden" class="text-truncate body-1 pl-1"-->
<!--                  :class="`${item.dropped ? 'white&#45;&#45;text' : ''} ${!item.title || item.title === 'null' || item.title === 'null null' ? ' clear-text placeholder-content rounded-border pr-2 ml-1' : ''}`">-->
<!--            {{ !item.title || item.title === 'null' || item.title === 'null null' ? '-' : item.title }}-->
<!--          </v-flex>-->
<!--          <v-flex shrink class="body-1 pl-1" :class="item.dropped ? 'white&#45;&#45;text' : ''">-->
<!--            {{ `(${item.count})` }}-->
<!--          </v-flex>-->
<!--          <v-flex shrink class="px-1">-->
<!--            <v-btn class="pa-0 ma-0" small flat icon :color="item.dropped ? 'white' : 'primary'">-->
<!--              <v-icon>{{ item.dropped ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}</v-icon>-->
<!--            </v-btn>-->
<!--          </v-flex>-->
<!--        </v-layout>-->
        <v-divider></v-divider>
      </div>
    </template>
    <template v-else>
      
      <div style="min-height: 101px">
        <task-container
            :userInfo="userInfo"
            :task="item"
            :taskBar="true"
            :showNav="['mail', 'app-gmail', 'smtp', 'mailboxes', 'chat'].includes(curView)"
            :selected="selectedTaskID === item.taskID"
            :style="{
              background: selectedTaskID === item.taskID ? 'var(--selected-item)' : null
            }"
            :allow-drop="true"
            @taskSelected="taskSelected"
            @taskBarDrop="taskBarDrop($event, item)"
            @smtpMailLink="smtpMailLink"
            @smtpMailboxMailLink="smtpMailboxMailLink"
            @chatMessagesLink="chatMessagesLink"
        >
          <div
              slot="right"
              v-if="((['mailboxes','smtp'].includes(curView) && showLinkSmtp) || ['mail', 'app-gmail'].includes(curView)) && selectedTaskID === item.taskID"
              @click="linkMailToTask()"
              class="pr-1"
          >
            <v-tooltip top>
              <v-btn
                  small
                  color="primary"
                  icon
                  class="pa-0 ma-0"
                  slot="activator"
                  :disabled="linkingMail"
                  :loading="linkingMail"
              >
                <v-icon small>link</v-icon>
              </v-btn>
              Link to Task
            </v-tooltip>
          </div>
        
        </task-container>
      </div>
    </template>
  </div>
</template>


<script>

import props from "vuetify/lib/components/VCalendar/util/props";
import TaskContainer from "@/components/TaskContainer/TaskContainer.vue";
import {mapGetters} from "vuex";

export default {
  components: {TaskContainer},
  props: {
    source: {
      type: Object,
    },
    filterSettings: Object,
    droppedGroupings: Array,
    selectedTaskID: Number,
    curView: String,
    toggleGrouping: Function,
    taskSelected: Function,
    taskBarDrop: Function,
    smtpMailLink: Function,
    smtpMailboxMailLink: Function,
    chatMessagesLink: Function,
    linkingMail: Boolean,
    showLinkSmtp: Boolean,
    linkMailToTask: Function
  },
  computed: {
    ...mapGetters('userInfo', ["userInfo"]),
    item() {
      return this.source
    }
  },
}
</script>


<style scoped>


</style>