<template>
    <v-layout row wrap v-show="selectedStep.stepID != 0">
        <div @click="addNewNote">
            <slot></slot>
        </div>

<!--Draft Notes-->
<!--        <template v-for="(draft, i) in task.structure.draftNotes">-->
<!--            <template v-if="draft.stepID == selectedStep.stepID">-->
<!--                <v-flex xs12 :key="i + 'notedraft'" class="pa-2">-->
<!--                    <app-task-draft-note-->
<!--                            :selected-step="selectedStep"-->
<!--                            :user-info="userInfo"-->
<!--                            :task="task"-->
<!--                            :draft="draft"-->
<!--                            @openDraft="createNoteFromDraft($event)"-->
<!--                            :delete-draft-f-n="deleteDraft"-->
<!--                    />-->
<!--                    <v-divider></v-divider>-->
<!--                </v-flex>-->
<!--            </template>-->
<!--        </template>-->
<!--&lt;!&ndash;Note Containers&ndash;&gt;-->
<!--        <template v-for="(note, i) in task.structure.notes">-->
<!--            <template v-if="note.stepID == selectedStep.stepID">-->
<!--                <v-flex xs12 :key="i + 'note'" class="pa-2">-->
<!--                    <app-note-view-container-->
<!--                            :userInfo="userInfo"-->
<!--                            :task="task"-->
<!--                            :note="note"-->
<!--                            @reply="replyToNote($event)"-->
<!--                    />-->
<!--                    <v-divider></v-divider>-->
<!--                </v-flex>-->
<!--            </template>-->
<!--        </template>-->



        <app-compose-note
                :noteReplyID="0"
                :setNoteContent="setNoteContent"
                :userInfo="userInfo"
                :replyUsers="replyUsers"
                :selectedStep="selectedStep"
                :task="task"
                :dialog="showAddNote"
                @dismiss="showAddNote = false"
                :draft="createDraft"
                @deleteDraft="deleteDraft($event)"
                @setDraft="createDraft = $event"

        />
    </v-layout>


</template>

<script>

    import { eventBus } from '../../../../main';
    import ComposeNote from '../Notes/ComposeNote';
    import TaskDraftNote from "./TaskDraftNote";
    import {codeBaseMixin} from "../../../../codeBaseMixin";


    export default {
        mixins: [codeBaseMixin],
        data() {
            return {
                removeCheck: false,
                showAddNote: false,
                replyUsers: [],
                createDraft: {
                    draftID: 0,
                    checklistItems: null,
                    noteBody: '',
                    created: null,
                    userID: null,
                    selectedUsers: null,
                    taskID: null,
                    stepID: null
                },
                noteReplyID: 0,
                setNoteContent: '',
                noteReplyUser: null,
            }
        },
        components: {
            appComposeNote: ComposeNote,
        },
        props: {
            selectedStep: Object,
            userInfo: Object,
            task: Object,
        },
        watch: {
            showAddNote(shown) {
                if (!shown) {
                    this.replyUsers = [];
                }
            }
        },
        created() {
            eventBus.$on('CreateNoteWithHtml', this.CreateNoteWithHtml);

            eventBus.$on('createNoteFromDraft', this.createNoteFromDraft)
            eventBus.$on('replyToNote', this.replyToNote)
        },

        beforeDestroy() {
            eventBus.$off('createNoteFromDraft', this.createNoteFromDraft)
            eventBus.$off('replyToNote', this.replyToNote)
            eventBus.$off('CreateNoteWithHtml', this.CreateNoteWithHtml);
        },


        methods: {
            CreateNoteWithHtml(data) {
                this.setNoteContent = data;
                this.showAddNote = true;
            },

            deleteDraft(draftID) {
                //Delete Draft Here
                this.fetch({method: 'DELETE', url:`/taskCache/note/removeDraftNote/${this.userInfo.entityID}/${draftID}`})
                    .then((data) => {
                        this.task.structure.draftNotes.forEach((draft, i) => {
                            if (draft.draftID === draftID) {
                                this.task.structure.draftNotes.splice(i,1);
                            }
                        })
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e)
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
                    })
            },


            addNewNote() {
                this.resetDraft();
                this.setNoteContent = null;
                this.showAddNote = true;
            },

            resetDraft() {
                this.createDraft = {
                    draftID: 0,
                    checklistItems: null,
                    noteBody: '',
                    created: null,
                    userID: null,
                    selectedUsers: null,
                    taskID: null,
                    stepID: null
                };
                this.setNoteContent = null;
            },

            createNoteFromDraft(draft) {
                this.createDraft = draft;
                this.showAddNote = true;
            },

            replyToNote(data) {
                this.replyUsers = data.reply;
                this.setNoteContent = null;
                this.showAddNote = true;
            }
        }
    }
</script>

<style scoped>

</style>
