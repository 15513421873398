<template>
    <v-tooltip top>
        <v-btn
                small
                icon
                flat
                color="secondary"
                slot="activator"
                class="pa-0 ma-0"
                @click="showAddWorkflow = true"
        >
            <v-icon>add</v-icon>
        </v-btn>
        <span>Add New Workflow</span>
        <app-input-dlg
                :show-dialog="showAddWorkflow"
                title="Add Workflow"
                :body="'Please provide a Description for the New Workflow that you would like to add to: ' + workflowGroup.displayName"
                :cancel-button="true"
                :show-input-box="true"
                :buttons="['Add Workflow']"
                :call-backs="[ addWorkflow ]"
                @dismiss="showAddWorkflow = false"
        />
    </v-tooltip>
</template>

<script>

    import InputDlg from '../../../../../components/General/InputDlg'

    export default {
        components: {
            appInputDlg: InputDlg
        },
        props: {
            workflowGroup: Object
        },
        data() {
            return {
                showAddWorkflow: false
            }
        },
        methods: {
            addWorkflow(WFDescription) {
                if (WFDescription != null && WFDescription != '') {
                    this.$emit('addwf', {workflowGroupID: this.workflowGroup.id, description: WFDescription});
                    this.showAddWorkflow = false;
                } else {
                    this.showSnack('Error', 'A Workflow Description Is Required', 'Close', 'red')
                }
            }
        }

    }
</script>

<style scoped>

</style>
