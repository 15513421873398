<template>
  
  <div>
    <div class="py-2 font-weight-bold caption px-2">
      New Form Request:
    </div>
    
    <div class="px-3">
      <app-browse-contacts
          v-if="$store.state.localData && userInfo.userID"
          :value="[request.targetId]"
          :multi-select="false"
          :folder-filter="filterTargetFolder"
      >
        <template #activator>
          <v-text-field
              :disabled="loading"
              prepend-icon="3p"
              hide-details
              label="Target:"
              placeholder="Any Contact, Click to select"
              readonly
              :value="targetNameStr"
              style="cursor: pointer !important;"
              clearable
              @click:clear.stop.prevent="request.targetId = null"
          ></v-text-field>
        </template>
        
        <template #actions="{selectedContacts, hideDialog}">
          
          <v-card-actions class="c-d-flex c-justify-end">
            <v-btn
                @click="() => hideDialog()"
                flat
            >
              Cancel
            </v-btn>
            
            <v-btn
                :disabled="!Array.isArray(selectedContacts) || !selectedContacts.length"
                color="secondary"
                @click="updateTargetContact(selectedContacts, hideDialog)"
            >
              Select Contact
            </v-btn>
          </v-card-actions>
        
        </template>
      
      </app-browse-contacts>
      
      <v-textarea
          prepend-icon="question_mark"
          hide-details
          label="Prompt:"
          style="cursor: pointer"
          :rows="1"
          :auto-grow="true"
          :disabled="loading"
          v-model="request.prompt"
      ></v-textarea>
      
      
      <div
           class="v-input v-text-field v-text-field--placeholder v-input--hide-details v-input--is-readonly theme--light"
           style="width: 100%">
        <div class="v-input__prepend-outer">
          <v-icon>edit_square</v-icon>
        </div>
        
        <div class="v-input__control" style="width: 100%">
          <div class="v-input__slot">
            <div class="v-text-field__slot">
              <label
                  aria-hidden="true"
                  class="v-label v-label--active theme--light"
                  style="left: 0px; right: auto; position: absolute;"
              >
                Fields:
              </label>
              <div class="py-1 " style="width: 100%;">
                
                <draggable
                    :list="request.fields"
                    :group="'fields'"
                    ghostClass="ghost"
                    v-bind="{handle:'.handle'}"
                >
                  <!--                <div v-for="field in stepFields">-->
                  <!--                    {{field.description}}-->
                  <!--                </div>-->
                  <v-card v-for="field in request.fields" :key="field.fieldID" color="primary" dark style="width: 100%" class="my-1">
                    
                    <div class="c-d-flex c-align-center" style="width: 100%; overflow-x: hidden" >
                      
                      <div>
                        <v-btn small icon flat class="handle" style="cursor: grab">
                          <v-icon>drag_indicator</v-icon>
                        </v-btn>
                      
                      </div>
                      
                      <div class="c-flex-grow-1 py-1" >
                        <div class="body-2 font-weight-bold">{{ field.description }}</div>
                        <div class="caption font-weight-bold primary--text text--lighten-4">{{fieldTypeRef[field.fieldType]}}</div>
                        <div class="caption primary--text text--lighten-4" v-if="field.value">{{field.value}}</div>
                      </div>
                      
                      <div>
                        <v-btn small icon flat @click="removeField(field.fieldID)">
                          <v-icon>close</v-icon>
                        </v-btn>
                      </div>
                    </div>
                  </v-card>
                </draggable>
                <div v-if="!loading" class="pa-2 body-2 caption text-sm-center" style="border-radius: 6px; border: 2px dashed grey" @dragover="$event.preventDefault()" @drop="dropFn">
                  Drop field here to add
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <div class="pt-2 text-xs-right">
      <v-btn
          :disabled="loading"
          flat
          small
          @click="$emit('cancel')"
      >
        Cancel
      </v-btn>
      
      <v-btn
          :disabled="!request.fields.length || loading"
          :loading="loading"
          small
          color="secondary"
          @click="$emit('send')"
      >
        Send
      </v-btn>
    </div>
    
  </div>

</template>

<script>

import {InformationRequestTemplate, InformationRequestField} from "@/Tasks/TaskView V2/components/Chat Message/AppComboComposeMessage.vue"

import draggable from "vuedraggable";
import AppBrowseContacts from "@/contacts/AppBrowseContacts.vue";
import {mapGetters} from "vuex";

export default {
  components: {
    AppBrowseContacts,
    draggable,
  },
  props: {
    editRequest: {
      type: Object,
      required: true
    },
    filterTargetFolder: Number,
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      console,
      request: new InformationRequestTemplate({}),
      fieldTypeRef: {
        1: 'Word Field',
        2: 'Date Field',
        3: 'Number Field',
        4: 'Currency Field',
        5: 'List Field',
        6: 'Mail/Document Field',
      },
      loadingTargetContactInformation: false,
      targetContactInformation: null
    }
  },
  watch: {
    editRequest: {
      immediate: true,
      deep: true,
      handler(v) {
        this.request = new InformationRequestTemplate(v)
      }
    },
    
    "request.targetId": {
      immediate: true,
      handler(val) {
        this.targetContactInformation = null;
        if (val)
          this.getTargetContactInformation()
      }
    }
  },
  methods: {
    
    async getTargetContactInformation() {
      try {
        this.loadingTargetContactInformation = true
        const contactInfo = await this.$newReq('GET', `contacts/contactInfo/${this.request.targetId}`)
        this.$set(this, "targetContactInformation", contactInfo)
      } catch (e) {
        console.log(e)
        this.$snack.networkError()
      } finally {
        this.loadingTargetContactInformation = false
      }
      
    },
    
    updateTargetContact(selectedContacts, hideDialog) {
      this.request.targetId = selectedContacts.shift().id
      hideDialog()
    },
    
    removeField(fieldId) {
      this.request.fields = this.request.fields.filter(v => v.fieldID !== fieldId)
    },
    
    dropFn(e) {
      const dropData = JSON.parse(e.dataTransfer.getData("text") || '{}')
      
      // Task Document
       if (dropData.type === 'workflowField') {
          this.request.fields.push(dropData.payload)
          this.request.fields = this.request.fields.filter((v, i , a) => a.findIndex(av => av.fieldID === v.fieldID) === i)
      }
    }
  },
  computed: {
    ...mapGetters('userInfo', ['userInfo']),
    targetNameStr() {
      if (this.loadingTargetContactInformation)
        return 'LOADING...'
      return this.targetContactInformation?.fullName || null
    }
  
  
  },
}
</script>



<style scoped>

</style>