<template>
    <div>
        <v-menu offset-y full-width :close-on-content-click="false" v-model="configuringField" >
            <template v-slot:activator="{ on }">
                <div v-show="lookupDisplay == null" v-on="on">
                    <v-btn small flat color="secondary" class="ma-0">Configure List</v-btn>
                </div>
                <v-card ripple hover flat v-show="lookupDisplay != null" class="primary white--text" v-on="on">
                    <v-layout row align-center style="cursor: pointer" v-if="lookupDisplay != null">
                        <v-flex xs12 class="pa-1 pl-2">
                            {{lookupDisplay}}
                        </v-flex>
                        <v-flex shrink class="pr-1">
                            <v-tooltip top>
                                <v-btn
                                        small
                                        flat
                                        icon
                                        color="white"
                                        class="pa-0 ma-0"
                                        slot="activator"
                                >
                                    <v-icon small>edit</v-icon>
                                </v-btn>
                                <span>Edit List Options</span>
                            </v-tooltip>
                        </v-flex>
                    </v-layout>
                </v-card>
            </template>


            <v-card class="pa-1">
                <div class="pa-1">
                    <div class="caption greyType--text font-weight-bold">
                        List Type:
                    </div>
                    <div class="pl-2">
                        <v-autocomplete
                                dense
                                v-model="lookup_type"
                                :items="lookup_typeRefValues"
                                label="List Type"
                                hide-details
                                single-line
                                color="primary"
                                item-text="description"
                                item-value="id"
                                class="pa-0 ma-0"
                                @input="resetLookup"
                        ></v-autocomplete>
                    </div>
                </div>
<!--Custom List-->
                <div class="pa-1" v-if="lookup_type == 1">
                    <div class="caption greyType--text font-weight-bold">
                        <v-layout row align-center>
                            <v-flex xs12>
                                List Items:
                            </v-flex>
                            <v-flex shrink>
                                <v-tooltip top>
                                    <v-btn
                                            icon
                                            flat
                                            class="pa-0 ma-0"
                                            color="secondary"
                                            slot="activator"
                                            small
                                            @click="showAddCustomListItem = true"
                                    >
                                        <v-icon>add</v-icon>
                                    </v-btn>
                                    <span>Add Item to List</span>
                                </v-tooltip>
                            </v-flex>
                        </v-layout>
                    </div>
                    <div class="px-4" style="max-height: 200px; overflow-y: auto">
                        <template v-for="(item, index) in customListItems">
                            <v-layout row align-center>
                                <v-flex xs12 class="caption">
                                    {{item}}
                                </v-flex>
                                <v-flex shrink>
                                    <v-tooltip top>
                                        <v-btn
                                                icon
                                                flat
                                                class="pa-0 ma-0"
                                                color="red"
                                                slot="activator"
                                                small
                                                @click="customListItems = customListItems.filter((obj, i) => i != index)"
                                        >
                                            <v-icon small>delete</v-icon>
                                        </v-btn>
                                        <span>Delete Item from List</span>
                                    </v-tooltip>
                                </v-flex>
                            </v-layout>
                        </template>
                    </div>
                    <div>
                        <v-divider></v-divider>
                    </div>
                    <div class=" pt-2 text-xs-right">
                        <v-tooltip top>
                            <v-btn
                                    class="pa-0 ma-0"
                                    color="secondary"
                                    slot="activator"
                                    small
                                    @click="doneRun"
                                    :disabled="customListItems != null && customListItems.length <= 0"
                            >
                                done
                            </v-btn>
                            <span>Done</span>
                        </v-tooltip>
                    </div>
                    <app-input-dlg
                            :show-dialog="showAddCustomListItem"
                            title="Add New List Item"
                            body="Item Description:"
                            :cancel-button="true"
                            :show-input-box="true"
                            :buttons="['Add']"
                            :call-backs="[ addCustomListItem ]"
                            @dismiss="showAddCustomListItem = false"
                    />
                </div>
<!--Select List-->
                <div class="pa-1" v-if="lookup_type == 2">

                    <div class="caption greyType--text font-weight-bold">
                         Configure List:
                    </div>
                    <div class="pl-2">
                        <div class="text-xs-center" v-if="localLookupDisplay == null">
                            <v-btn small flat color="secondary" @click="showSelectListManager = true" class="ma-0">Select List</v-btn>
                        </div>
                        <v-card v-else class="primary white--text" @click="showSelectListManager = true">
                            <v-layout row align-center style="cursor: pointer">
                                <v-flex xs12 class="pa-1 pl-2">
                                    {{localLookupDisplay}}
                                </v-flex>
                                <v-flex shrink class="pr-1">
                                    <v-tooltip top>
                                        <v-btn
                                                small
                                                flat
                                                icon
                                                color="white"
                                                class="pa-0 ma-0"
                                                slot="activator"
                                        >
                                            <v-icon small>edit</v-icon>
                                        </v-btn>
                                        <span>Change List</span>
                                    </v-tooltip>
                                </v-flex>
                            </v-layout>
                        </v-card>
                    </div>
                    <app-select-list-manager
                            :user-info="userInfo"
                            :select-lists="selectLists"
                            :showManager="showSelectListManager"
                            title="Select List"
                            @dismiss="showSelectListManager = false"
                            v-model="lookup"
                    />

                    <div class="pt-2 text-xs-right">
                        <v-tooltip top>
                            <v-btn
                                    class="pa-0 ma-0"
                                    color="secondary"
                                    slot="activator"
                                    small
                                    @click="doneRun"
                                    :disabled="lookup == null"
                            >
                                done
                            </v-btn>
                            <span>Done</span>
                        </v-tooltip>
                    </div>
                </div>

            </v-card>
        </v-menu>
    </div>
</template>

<script>
    import InputDlg from "../../../../../../../components/General/InputDlg";
    import {codeBaseMixin} from "../../../../../../../codeBaseMixin";
    import SelectListManager from "../../../../../../../components/General/SelectListManager";

    export default {
        mixins: [codeBaseMixin],
        components: {
            appSelectListManager: SelectListManager,
            appInputDlg: InputDlg
        },
        props: {
            value: {
                type: Object,
                default: () => {
                    return {
                        lookup_type: null,
                        lookup: null
                    }
                }
            },
            userInfo: Object,
            selectLists: Array,
        },
        data() {
            return {
                configuringField: false,
                lookup_type: null,
                lookup_typeRefValues: [
                    {description: 'Select List', id: 2},
                    {description: 'Custom List', id: 1},
                ],
                lookup: null,
                showAddCustomListItem: false,
                showSelectListManager: false
            }
        },
        watch: {
            value: {
                immediate: true,
                handler(val) {
                    this.resetLocalValues(val);
                }
            }
        },
        methods: {
            doneRun() {


                let tLookup = JSON.stringify(this.lookup);


                this.$emit('input', {
                    lookup_type: this.lookup_type,
                    lookup: tLookup
                })
            },

            addCustomListItem(item) {
                if (item == null || item == '') {
                    this.showSnack('Error', 'List Item Can Not Be Blank', 'Close', 'red');
                    return null;
                }

                if (this.lookup != null && this.lookup.map(obj => {return obj.toLowerCase()}).includes(item.toLowerCase())) {
                    this.showSnack('Error', 'Item Already Exists', 'Close', 'red');
                    return null;
                }

                if (item.includes('"')) {
                    this.showSnack('Error', 'Item Can Not Contain A Quotation', 'Close', 'red');
                    return null;
                }

                if (!Array.isArray(this.lookup)) {
                    this.lookup = []
                }

                this.lookup.push(item);
                this.showSnack('Info', 'Item Added', 'Close', 'primary');
                this.showAddCustomListItem = false;
            },
            resetLookup() {
                this.lookup = null;
            },
            resetLocalValues(val) {
                this.configuringField = false;
                this.lookup_type = val != null && typeof val.lookup_type != "undefined" ? val.lookup_type : 2;
                this.lookup =
                    val != null && typeof val.lookup != "undefined"
                        ? JSON.parse(val.lookup.replace(/\\"/g,'"'))
                        : null;
            },
        },
        computed: {
            customListItems: {
                get() {
                    return this.lookup != null ? this.lookup.sort((a,b) => a < b ? -1 : 1) : [];
                },
                set(newValue) {
                    this.lookup = newValue;
                }
            },
            lookupDisplay() {
                if (this.valueLookup_type != null) {
                    try {
                        if (this.valueLookup_type == 1) {
                            return 'Custom List';
                        }
                        if (this.valueLookup_type == 2) {
                            return this.selectLists.filter(obj => obj.id == this.valueLookup.id).map(obj => {return obj.description}).pop();
                        }
                    } catch (e) {
                        // eslint-disable-next-line
                        console.log(e);
                        return null;
                    }
                }

                return null;
            },
            valueLookup_type() {
                return this.value != null && typeof this.value.lookup_type != "undefined" ? this.value.lookup_type : null;
            },
            valueLookup() {
                try {
                    return this.value != null && typeof this.value.lookup != "undefined" ? JSON.parse(this.value.lookup.replace(/\\"/g,'"')) : null;
                } catch (e) {
                    // eslint-disable-next-line
                    console.log(e);
                    return null;
                }
            },
            localLookupDisplay() {
                try {
                    return this.lookup != null ? this.selectLists.filter(obj => obj.id == this.lookup.id).map(obj => {
                        return obj.description
                    }).pop() : null;
                } catch (e) {
                    // eslint-disable-next-line
                    console.log(e);
                    return null;
                }
            },

        }
    }
</script>

<style scoped>

</style>
