<template>
    <div >
        <app-input-dlg
                :show-dialog="showSnap"
                :title="title"
                :cancel-button="true"
                :show-input-box="false"
                @dismiss="$emit('dismiss')"
                :body-padding="false"
                :width="640"
        >
            <template slot="title">
                <div v-show="false">Title Text</div>
            </template>
            <template slot="body">
                <div v-if="showSnap" style="height: 480px">
                    <video ref="webcam" autoplay playsinline width="640" height="480"></video>
                    <canvas ref="canvas" class="d-none"></canvas>

                    <div
                            class="text-xs-center pa-2"
                            style="position: absolute; bottom: 0; width: 100%"
                    >
                        <v-btn
                                @click="snap"
                                color="secondary"
                                icon
                                :loading="prepingCam"
                                :disabled="prepingCam"
                        >
                            <v-icon>photo_camera</v-icon>
                        </v-btn>
                    </div>
                    <div
                            class="text-xs-right pa-2"
                            style="position: absolute; top: 0; width: 100%"
                    >
                        <v-btn
                                small
                                icon
                                flat
                                color="primary"
                                class="pa-0 ma-0"
                                @click="$emit('dismiss', true)"
                        >
                            <v-icon small>close</v-icon>
                        </v-btn>
                    </div>
                </div>
            </template>
        </app-input-dlg>
    </div>
</template>



<script>
    import InputDlg from "../../../../../../../components/General/InputDlg";

    import Webcam from 'webcam-easy';

    export default {
        name: "SnapPhoto.vue",
        components: {
            appInputDlg: InputDlg
        },
        props: {
            title: String,
            value: Object,
            showSnap: Boolean
        },
        data() {
            return {
                webcamElement: null,
                canvasElement: null,
                webcam: null,
                prepingCam: true
            }
        },

        watch: {
            showSnap: {
                immediate: true,
                handler(shown) {
                    this.prepingCam = true;
                    if (shown) {
                        this.$nextTick(() => {
                            this.webcamElement = this.$refs.webcam;
                            this.canvasElement = this.$refs.canvas;
                            this.webcam = new Webcam(this.webcamElement, 'user', this.canvasElement);
                            this.webcam.start()
                                .then(result =>{
                                    this.prepingCam = false;
                                })
                                .catch(err => {
                                    console.log(err);
                                    this.showSnack('Error', 'Cant Start Camera', 'Close', 'red')
                                    this.$emit('dismiss');
                                });
                        })
                    }
                }
            }
        },

        methods: {
            snap() {
                let picture = this.webcam.snap();
                this.$emit('input', picture);
                this.webcam.stop();
                this.$emit('dismiss');
            }
        }
    }
</script>

<style scoped>

</style>
