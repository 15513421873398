<template>
    <app-input-dlg
            :show-dialog="showSendSms"
            :title="title"
            :cancel-button="!sendingSms"
            :show-input-box="false"
            @dismiss="$emit('dismiss')"
            :body-padding="false"
            :width="500"
    >
        <template slot="body">
<!--SMS To-->
            <div class="px-2 pt-2">
<!--0 - User Input-->
                <template v-if="template.toType === 0">
                    <v-text-field
                            dense
                            v-model="smsTo"
                            label="Enter Cellphone Number"
                            hide-details
                            single-line
                            color="primary"
                            class="pa-0 ma-0"
                            prepend-icon="phone"
                            :mask="smsTo == null || smsTo.length <= 10 ? '### ### #####' : '## ## ### ####'"
                    ></v-text-field>
                </template>
<!--1 - Workflow Field-->
                <template v-if="template.toType === 1">
                    <v-text-field
                            :readonly="true"
                            dense
                            v-model="workflowFieldTo.description"
                            label="Enter Cellphone Number"
                            hide-details
                            single-line
                            color="orange"
                            class="pa-0 ma-0"
                            prepend-icon="phone"
                            :mask="workflowFieldTo.description == null || workflowFieldTo.description.length <= 10 ? '### ### #####' : '## ## ### ####'"
                            v-if="workflowFieldTo.valid"
                    ></v-text-field>
                    <div v-else>
                        <v-layout row align-center>
                            <v-flex shrink>
                                <v-icon class="red--text" left>phone</v-icon>
                            </v-flex>
                            <v-flex xs12 class="body-2 red--text">
                                {{`${workflowFieldTo.description} - Invalid Cellphone Number`}}
                            </v-flex>
                        </v-layout>
                    </div>
                </template>
<!--2 - DatasetField-->
                <template v-if="template.toType === 2">
                    <v-text-field
                            :readonly="true"
                            dense
                            v-model="datasetFieldTo.description"
                            label="Enter Cellphone Number"
                            hide-details
                            single-line
                            color="orange"
                            class="pa-0 ma-0"
                            prepend-icon="phone"
                            :mask="datasetFieldTo.description == null || datasetFieldTo.description.length <= 10 ? '### ### #####' : '## ## ### ####'"
                            v-if="datasetFieldTo.valid"
                    ></v-text-field>
                    <div v-else>
                        <v-layout row align-center>
                            <v-flex shrink>
                                <v-icon class="red--text" left>phone</v-icon>
                            </v-flex>
                            <v-flex xs12 class="body-2 red--text">
                                {{`${datasetFieldTo.description} - Invalid Cellphone Number`}}
                            </v-flex>
                        </v-layout>
                    </div>
                </template>
<!--3 - Specific Number-->
                <template v-if="template.toType === 3">
                    <v-text-field
                            dense
                            v-model="template.toSpecificNumber"
                            label="Enter Cellphone Number"
                            hide-details
                            single-line
                            color="primary"
                            class="pa-0 ma-0"
                            prepend-icon="phone"
                            :readonly="true"
                            :mask="template.toSpecificNumber == null || template.toSpecificNumber.length <= 10 ? '### ### #####' : '## ## ### ####'"
                    ></v-text-field>
                </template>
            </div>
<!--SMS Body-->

<!--Template-->
            <div class="pa-1 pl-2 scrollable" v-if="template.type === 0" >

                <div style="max-height: 300px" class="scrollable">
<!--User Input Fields-->
                    <template v-if="userInputFields.length > 0">
                        <div
                                v-for="field in userInputFields"
                                :key="`input${field.index}`"
                                class="py-1"
                        >
                            <v-text-field
                                    dense
                                    v-model="field.value"
                                    :label="field.description"
                                    hide-details
                                    single-line
                                    color="primary"
                                    class="pa-0 ma-0"
                                    prepend-icon="edit"
                            ></v-text-field>
                        </div>
                    </template>
<!--Workflow Fields-->
                    <template v-if="workflowFields.length > 0">
                        <div
                                v-for="field in workflowFields"
                                :key="`workflow${field.index}`"
                        >
                            <v-layout row align-center>
                                <v-flex shrink class="pt-0">
                                    <v-icon
                                            :class="field.status === 0 ? 'greyType--text' : (field.status === 1 ? 'amber--text' : 'red--text')"
                                    >{{field.status === 0 ? 'done' : (field.status === 1 ? 'warning' : 'error')}}</v-icon>
                                </v-flex>
                                <v-flex
                                        class="body-1 pl-2 pt-0"
                                        :class="field.status === 0 ? 'greyType--text' : (field.status === 1 ? 'amber--text' : 'red--text')"
                                >
                                    <div class="py-2">{{field.status === 0 ? field.description : (field.status === 1 ? `${field.description} - Not Required` : `${field.description} - Required Field`)}}</div>
                                    <v-divider color="darkGrey"></v-divider>
                                </v-flex>
                            </v-layout>
                        </div>
                    </template>
<!--Dataset Fields-->
                    <template v-if="datasetFields.length > 0">
                        <div
                                v-for="field in datasetFields"
                                :key="`dataset${field.index}`"
                        >
                            <v-layout row align-center>
                                <v-flex shrink class="pt-0">
                                    <v-icon
                                            :class="field.status === 0 ? 'greyType--text' : (field.status === 1 ? 'amber--text' : 'red--text')"
                                    >{{field.status === 0 ? 'done' : (field.status === 1 ? 'warning' : 'error')}}</v-icon>
                                </v-flex>
                                <v-flex
                                        class="body-1 pl-2 pt-0"
                                        :class="field.status === 0 ? 'greyType--text' : (field.status === 1 ? 'amber--text' : 'red--text')"
                                >
                                    <div class="py-2">{{field.status === 0 ? field.description : (field.status === 1 ? `${field.description} - Not Required` : `${field.description} - Required Field`)}}</div>
                                    <v-divider color="darkGrey"></v-divider>
                                </v-flex>
                            </v-layout>
                        </div>
                    </template>
                </div>
<!--Message Body-->
                <v-textarea
                        dense
                        v-model="messageBody"
                        label="SMS Body"
                        hide-details
                        single-line
                        color="primary"
                        class="pa-0 ma-0 pt-1"
                        prepend-icon="textsms"
                        :readonly="true"
                ></v-textarea>
            </div>

<!--User Input-->
            <div class="px-2" v-if="template.type === 1">
                <v-textarea
                        dense
                        v-model="messageBody"
                        label="SMS Body"
                        hide-details
                        single-line
                        color="primary"
                        class="pa-0 ma-0"
                        prepend-icon="textsms"
                ></v-textarea>
            </div>

            <div class="caption px-2 text-xs-right" :class="messageParts.valid ? 'greyType--text' : 'red--text font-weight-bold'">
                {{`Characters: ${messageParts.chars}, Message Parts: ${messageParts.parts}${messageParts.valid ? '' : `, Max Parts: ${template.maxMessageParts}`}`}}
            </div>
        </template>
        <template slot="actions">
            <div class="text-xs-right">
                <v-btn
                        small
                        color="secondary"
                        @click="sendRun"
                        :disabled="blockSend || sendingSms"
                        :loading="sendingSms"
                        class="ma-0"
                >
                    <v-icon left>send</v-icon>
                    Send SMS
                </v-btn>
            </div>
        </template>
    </app-input-dlg>
</template>

<script>
    import InputDlg from "../../../../../../../components/General/InputDlg";
    import {codeBaseMixin} from "../../../../../../../codeBaseMixin";
    import {DatasetBinder} from "@/Tasks/TaskView V2/components/TaskViewDetail/datasetBinder";
    import {objectifyArr} from "@/codeFunctions";
    export default {
        mixins: [codeBaseMixin],
        components: {
            appInputDlg: InputDlg
        },
        props: {
            title: String,
            showSendSms: Boolean,
            template: Object,
            userInfo: Object,
            datasets: Array,
            fields: Array,
            steps: Array,
            binder: DatasetBinder
        },
        data() {
            return {
                smsTo: null,
                messageBody: '',
                userInputFields: [],
                workflowFields: [],
                datasetFields: [],
                sendingSms: false
            }
        },
        watch: {
            showSendSms: {
                immediate: true,
                handler(val) {
                    if (val) {

                        // User Input Fields
                        this.userInputFields = this.replaceFields.userInput.reduce((list, item, i) => {
                            list.push({
                                index: i,
                                description: item,
                                value: null
                            });
                            return list
                        }, []);

                        // Workflow Fields
                        let tWorkflowFields = this.fields.reduce((obj, item) => {
                            obj[item.fieldID] = item;
                            return obj
                        }, {});
                        this.workflowFields = this.replaceFields.workflow.reduce((list, item, i) => {

                            list.push({
                                index: i,
                                description: tWorkflowFields[item].description,
                                value: tWorkflowFields[item].value,
                                status: tWorkflowFields[item].value !== null ? 0 : (tWorkflowFields[item].required === 0 ? 1 : 2),//0 - Good, 1 - Warning, 2 - FAIL
                                id: tWorkflowFields[item].fieldID
                            });

                            return list;
                        }, [])

                        // Dataset Fields
                        let tDatasets = Object.keys(this.binder.datasets).reduce((obj, memberId) => {
                            obj[memberId] = objectifyArr(this.binder.datasets[memberId].fields, "datasetFieldID")
                            return obj
                        }, {})


                        // let tDatasets = this.datasets.reduce((obj, item) => {
                        //
                        //     try {
                        //         let tFields = JSON.parse(item.fields);
                        //         tFields = tFields.reduce((fieldObj, item) => {
                        //             fieldObj[item.fieldID] = item;
                        //             return fieldObj;
                        //         }, {});
                        //         obj[item.memberID] = tFields;
                        //         return obj
                        //     } catch (e) {
                        //         // eslint-disable-next-line
                        //         console.log(e)
                        //         return obj
                        //     }
                        // }, {});

                        this.datasetFields = this.replaceFields.dataset.reduce((list, item, i) => {
                            console.log(item)

                            if (tWorkflowFields[item.dsSelectID].value == null || tWorkflowFields[item.dsSelectID].value === '') {
                                list.push({
                                    index: i,
                                    description: `${tWorkflowFields[item.dsSelectID].description} - Not Selected`,
                                    value: null,
                                    status: 2//0 - Good, 1 - Warning, 2 - FAIL
                                });
                            } else {
                                //Ds Selected Now Check Field
                                // console.log('DS FIELD', tDatasets[tWorkflowFields[item.dsSelectID].value][item.dsFieldID])

                                list.push({
                                    index: i,
                                    description: tDatasets[tWorkflowFields[item.dsSelectID].value][item.dsFieldID].fieldDescription,
                                    value: tDatasets[tWorkflowFields[item.dsSelectID].value][item.dsFieldID].value,
                                    status: tDatasets[tWorkflowFields[item.dsSelectID].value][item.dsFieldID].value !== null ? 0 : (tDatasets[tWorkflowFields[item.dsSelectID].value][item.dsFieldID].required === 0 ? 1 : 2),//0 - Good, 1 - Warning, 2 - FAIL
                                    dsSelectID: item.dsSelectID,
                                    dsFieldID: item.dsFieldID
                                });
                            }
                            return list;
                        }, [])

                        this.updateMessageBody();
                    }
                }
            },

            userInputFields: {
                deep: true,
                handler() {this.updateMessageBody()}
            },
            workflowFields: {
                deep: true,
                handler() {this.updateMessageBody()}
            },
            datasetFields: {
                deep: true,
                handler() {this.updateMessageBody()}
            },
        },

        methods: {
            sendRun() {
                this.sendingSms = true;
                let to = null;

                switch (this.template.toType) {
                    case 0: {
                        to = this.smsTo;
                        break;
                    }
                    case 1: {
                        to = this.workflowFieldTo.description;
                        break;
                    }
                    case 2: {
                        to = this.datasetFieldTo.description;
                        break;
                    }
                    default: {
                        to = this.template.toSpecificNumber
                    }
                }

                this.fetch({method: 'POST', url:`/tasks/SMS/sendSms/${this.userInfo.entityID}/${this.userInfo.userID}`, body: JSON.stringify({to: to, body: this.messageBody})})
                    .then((data) => {
                        this.sendingSms = false;
                        if (data.status) {
                            this.showSnack('Info', 'SMS Sent', 'Close', 'primary');

                            let tVal = {
                                sentBy: this.userInfo.userID,
                                date: data.message,
                                to: to,
                                message: this.messageBody
                            };

                            this.$emit('input', JSON.stringify(tVal));
                            this.$emit('dismiss');
                        } else {
                            this.showSnack('Error', 'SMS Send Failed: ' + data.message, 'Close', 'red')
                        }
                    })
                    .catch((e) => {
                        this.sendingSms = false;
                        this.showSnack('Error', 'Error Sending SMS', 'Close', 'red')
                    })
            },

            updateMessageBody() {
                
                if(this.template.type === 0) {
                    let tVal = this.htmlToText(this.templateLookup, true);

                    tVal = tVal.afterTags;

                    // Replace In Values

                    // User Input
                    this.userInputFields.forEach((field, i) => {
                        tVal = tVal
                            .split(`{{${i}-${field.description}}}`)
                            .join(this.coalesce(field.value))
                    });

                    // Workflow
                    this.workflowFields.forEach(field => {
                        tVal = tVal
                            .split(`{{W-${field.id}}}`)
                            .join(this.coalesce(field.value))
                    });

                    // Dataset
                    this.datasetFields.forEach(field => {
                        tVal = tVal
                            .split(`{{D-${field.dsSelectID}-${field.dsFieldID}}}`)
                            .join(this.coalesce(field.value))
                    });

                    //Finally
                    this.messageBody = tVal;
                }
                

            },

            coalesce(value, col='') {
                return typeof value == 'undefined' || value == null ? col : value;
            }
        },

        computed: {
            messageParts() {
                return {
                    chars: this.messageBody.length,
                    parts: Math.floor(this.messageBody.length / 160) + 1,
                    valid: this.template.maxMessageParts >= (Math.floor(this.messageBody.length / 160) + 1)
                }
            },
            blockSend() {
                // 0-UserInput; 1-WorkflowField; 2-DatasetField; 3-SpecificNumber

                let tFail = false;

                if (this.blankString(this.messageBody)) {
                    tFail = true;

                }

                if (!this.messageParts.valid) {
                    tFail = true;
                }

                switch (this.template.toType) {
                    case 0: {
                        if (this.blankString(this.smsTo) || ![10, 11].includes(this.smsTo.length) || isNaN(this.smsTo)) {
                            tFail = true;
                        }
                        break;
                    }
                    case 1: {
                        if (!this.workflowFieldTo.valid) {
                            tFail = true;
                        }
                        break;
                    }
                    case 2: {
                        if (!this.datasetFieldTo.valid) {
                            tFail = true;
                        }
                        break;
                    }
                }

                // Check User Input
                this.userInputFields.forEach(field => {
                    if (this.blankString(field.value)) {
                        tFail = true;
                    }
                });

                // Check Workflow Fields
                this.workflowFields.forEach(field => {
                    if (field.status === 2) {
                        tFail = true;
                    }
                });

                // Check Dataset Fields
                this.datasetFields.forEach(field => {
                    if (field.status === 2) {
                        tFail = true;
                    }
                });

                return tFail;
            },
            replaceFields() {
                let tVal = this.htmlToText(this.templateLookup, true)
                return tVal.fields;
            },
            templateLookup() {
                return this.template.templateLookup.replace(/\\"/g,`"`)
            },
            datasetFieldTo() {

                let ds = this.fields
                    .filter(obj => obj.fieldID === this.template.toDatasetField.fieldSetSelectField)
                    .map(obj => {return obj.value})
                    .pop();

                if (typeof ds == 'undefined' || ds == null ) {
                    return {
                        valid: false,
                        description: this.fields
                            .filter(obj => obj.fieldID === this.template.toDatasetField.fieldSetSelectField)
                            .map(obj => {return `${obj.description} - Not Selected`})
                            .pop()
                    }
                } else {
                    let dsFields = this.datasets
                        .filter(obj => obj.memberID == ds)
                        .map(obj => {return JSON.parse(obj.fields)})
                        .pop();

                    if (typeof ds == 'undefined' || !Array.isArray(dsFields) || dsFields.length === 0) {
                        return {
                            valid: false,
                            description: 'Dataset Field Not Found'
                        }
                    } else {
                        let dsField = dsFields.filter(obj => obj.fieldID === this.template.toDatasetField.field).pop();
                        if (typeof dsField == 'undefined' || dsField == null) {
                            return {
                                valid: false,
                                description: 'Dataset Field Not Found'
                            }
                        } else {
                            if (dsField.value !== null && [10,11].includes(dsField.value.length) && !isNaN(dsField.value)) {
                                return {
                                    valid: true,
                                    description: dsField.value
                                }
                            } else {
                                return {
                                    valid: false,
                                    description: dsField.description
                                }
                            }
                        }
                    }
                }
            },
            workflowFieldTo() {
                return this.fields
                    .filter(obj => obj.fieldID === this.template.toWorkflowFieldID)
                    .map(obj => {
                        if (obj.value !== null && [10,11].includes(obj.value.length) && !isNaN(obj.value)) {
                            return {
                                valid: true,
                                description: obj.value
                            }
                        } else {
                            return {
                                valid: false,
                                description: obj.description
                            }
                        }

                    })
                    .pop();
            }
        }
    }
</script>

<style scoped>

</style>
