<template>
    <v-card class="fill-height">
        <v-layout column fill-height>
            <v-flex xs12 fill-height class="scrollable" style="overflow-x: auto">
                <div class="text-xs-center pa-2" v-if="loadingReportPreview">
                    <v-progress-circular
                            :size="40"
                            :width="5"
                            color="primary"
                            indeterminate
                    />
                </div>
                <report-preview
                        v-if="!loadingReportPreview && previewData != null && typeof previewData.reportData != 'undefined' && previewData.reportData.length > 0"
                        :preview-data="previewData"
                        :user-info="userInfo"
                        @showPreview="$emit('showPreview', $event)"
                        :get-task-header="getTaskHeader"
                        @showReports="$emit('showReports', $event)"
                ></report-preview>
            </v-flex>
        </v-layout>
    </v-card>
</template>

<script>
    import ReportPreview from "../Report Design/ReportPreview";
    import {codeBaseMixin} from "../../../codeBaseMixin";
    export default {
        mixins: [codeBaseMixin],
        components: {ReportPreview},
        props: {
            loadingReportPreview: Boolean,
            previewData: Object,
            userInfo: Object,
            selectedReport: Object,
            getTaskHeader: Function,
        },
        watch: {
            selectedReport: {
                immediate: true,
                handler(val) {

                    if (val != null) {

                        this.$emit('refresh')
                    }
                }
            }
        }
    }
</script>

<style scoped>

</style>
