<template>
  <div class="fill-height" ref="taskView">
    
    <v-layout column fill-height class="scrollable">
      <!--Task Header-->
      <v-flex shrink v-if="!resetTaskHeader" style="" class="px-2 pb-1 pt-2">
        
        <app-task-view-header
            :task="task"
            @starTask="starTask"
            :userInfo="userInfo"
            :cur-time="curTime"
            :cur-time-minute="curTimeMinute"
            :local-data="localData"
            :show-sync="showSync"
            :get-task-header="getTaskHeader"
            @addDocToTask="copyToTaskStepRunStart($event)"
            @refreshTask="$emit('refreshTask')"
        />
      </v-flex>
      
      <!--Steps-->
      <v-flex shrink class="px-1 pb-1">
        <app-task-view-step-headers
            v-if="!showLoadingDetail"
            :task="task"
            :userInfo="userInfo"
            :selectedStep="selectedStep"
            @stepChanged="showSourceDetail = false"
            @sourceSelected="showSourceDetail = true"
            :folder-groups="folderGroups"
            :hidden-steps="hiddenSteps"
            :get-task-header="getTaskHeader"
        />
      </v-flex>
      
      <v-flex xs12 class="scrollable">
        
        <v-layout row fill-height class="scrollable">
          <!--Step Detail-->
          <v-flex xs6 class="fill-height scrollable">
            <div v-if="showLoadingDetail" class="text-xs-center">
              <v-progress-circular
                  :size="40"
                  :width="5"
                  color="primary"
                  indeterminate
              ></v-progress-circular>
            </div>
            <v-layout column fill-height class="scrollable" v-else>
              <!--Detail View-->
              <v-flex
                  xs12
                  class="fill-height scrollable"
                  v-if="!showSourceDetail && task.structure.steps.map((obj) => { return {editable: obj.editable, shown: obj.shown, stepID: obj.stepID} }).filter(obj => obj.shown == 1).map((obj) => {return obj.stepID}).includes(selectedStep.stepID)"
              >
                <app-task-view-detail
                    :selectedStep="selectedStep"
                    :task="task"
                    :userInfo="userInfo"
                    v-if="selectedStep.stepID != null && showLoadingDetail == false && selectedStep.stepID != 0"
                    :folder-groups="folderGroups"
                    :users="users"
                    :selectLists="selectLists"
                    :edit-note-fn="openFieldNoteEditor"
                    :mail-config="mailConfig"
                    @hiddenSteps="hiddenSteps = $event"
                    :hidden-steps="hiddenSteps"
                    @updateTask="$emit('updateTask', $event)"
                    @showSavePrompt="$emit('savePrompt', $event)"
                    @showSaveMessage="removeSaveMessage($event)"
                    :show-save-message="showSaveMessage"
                    :local-data="localData"
                    :show-sync="showSync"
                    :get-task-header="getTaskHeader"
                    @selectedStep="selectedStep = $event"
                >
                </app-task-view-detail>
              </v-flex>
              
              <v-flex xs12 style="overflow-y: auto; background: var(--page-back)"
                      v-if="showSourceDetail">
                <app-source-task-detail-view
                    :user-info="userInfo"
                    :split-task="task"
                    :mail-config="mailConfig"
                    :local-data="localData"
                />
              </v-flex>
            </v-layout>
          </v-flex>
          <!--Attachment Detail-->
          <v-flex xs6 class="fill-height scrollable" v-if="!showLoadingDetail">
            <combo-bar
                :task="task"
                :selectedStep="selectedStep"
                :mail-config="mailConfig"
                @selectStep="$event !== -1 ? selectedStep = task.structure.steps.filter(step => step.stepID === $event).pop() : selectedStep = {stepID: -1}"
                :chat-attachment-drag-handler="chatAttachmentDragHandler"
            />
          </v-flex>
        </v-layout>
      
      </v-flex>
    
    </v-layout>
    
    <field-note-editor-dialog
        v-if="typeof userInfo.userID != 'undefined'"
        v-model="editorHtml"
        :shown="showNoteFieldEditor"
        @dismiss="showNoteFieldEditor = false; updateNoteFieldData = $event"
        :user-info="userInfo"
        :field-name="noteFieldDescription"
    />
    
    <task-bar-container-drop-step-select-dlg
        v-if="showCopyDoc"
        :user-info="userInfo"
        :workflow-i-d="task.workflowID"
        :show-dialog="showCopyDoc"
        @dismiss="showCopyDoc = false"
        @input="copyToTaskStep($event)"
    />
  
  </div>


</template>

<script>

import TaskViewHeader from './components/TaskViewHeader';
import TaskViewStepHeaders from './components/TaskViewStepHeaders';
import TaskViewDetail from './components/TaskViewDetail/TaskViewDetail';
import {eventBus} from '../../main';
import {codeBaseMixin} from "../../codeBaseMixin";
import SourceTaskDetailView from './components/SourceTaskDetail/SourceTaskDetailView'
import FieldNoteEditorDialog
  from "./components/TaskViewDetail/Components/FieldTypeComponents/FieldNoteEditor/FieldNoteEditorDialog";
import ComboBar from './components/Combo Bar/ComboBar'
import TaskBarContainerDropStepSelectDlg from "@/components/General/TaskBarContainerDropStepSelectDlg";

export default {
  mixins: [codeBaseMixin],
  components: {
    TaskBarContainerDropStepSelectDlg,
    ComboBar,
    FieldNoteEditorDialog,
    appTaskViewHeader: TaskViewHeader,
    appTaskViewDetail: TaskViewDetail,
    appTaskViewStepHeaders: TaskViewStepHeaders,
    appSourceTaskDetailView: SourceTaskDetailView
    
  },
  props: {
    mailConfig: Object,
    task: Object,
    userInfo: Object,
    folderGroups: Array,
    users: Array,
    selectLists: {
      type: Array,
      default: () => {
        return []
      }
    },
    getTaskHeader: Function,
    showMailBand: Boolean,
    curView: String,
    curTime: {
      type: Date,
      default: null
    },
    curTimeMinute: {
      type: Date,
      default: null
    },
    savePrompt: Boolean,
    localData: Object,
    showSync: Boolean
  },
  data() {
    return {
      clickPosition: {x: null, y: null},
      showSaveMessage: false,
      showSourceDetail: false,
      showLoadingDetail: true,
      selectedStep: {
        stepID: 0,
        description: ''
      },
      headerHeight: 0,
      maxHeight: 0,
      dragData: {
        type: '',
        data: {}
      },
      showNoteFieldEditor: false,
      editorHtml: "",
      updateNoteFieldData: false,
      noteFieldDescription: "",
      hiddenSteps: [],
      resetTaskHeader: false,
      showCopyDoc: false,
      copyDoc: null
    }
  },
  created() {
    eventBus.$on('taskLoadingStructure', this.taskLoadingStructureEvent);
    eventBus.$on('mailDrag', this.mailDragEvent);
    eventBus.$on('attachmentDrag', this.attachmentDragEvent);
  },
  beforeDestroy() {
    eventBus.$off('taskLoadingStructure', this.taskLoadingStructureEvent);
    eventBus.$off('mailDrag', this.mailDragEvent);
    eventBus.$off('attachmentDrag', this.attachmentDragEvent);
  },
  watch: {
    hiddenSteps: {
      deep: true,
      immediate: true,
      handler(val) {
        this.$emit('hiddenStepsUpdated', val)
      }
    },
    
    task(newvalue) {
      this.showshowSourceDetail = false;
      this.hiddenSteps = [];
      
      this.resetTaskHeader = true;
      this.$nextTick(() => {
        this.resetTaskHeader = false
      })
      
    },
    
    taskId: {
      immediate: true,
      handler(val) {
        this.$store.state.chat.comboBarShowCompose = false
      }
    },
  },
  methods: {
    
    clickOutside(ev) {
      console.log('OUTSIDE', ev)
      
    },
    
    
    chatAttachmentDragHandler(event, payload) {
      event.dataTransfer.setData('text', `${JSON.stringify(payload)}`);
    },
    
    taskLoadingStructureEvent(value) {
      this.showSourceDetail = false;
      this.showLoadingDetail = value;
      if (value == false) {
        this.selectedStep.stepID = this.task.curStepID;
        this.selectedStep.description = this.task.structure.steps.reduce((description, step) => {
          if (description == null && step.stepID === this.task.curStepID) {
            description = step.description;
          }
          return description
        }, null);
        eventBus.$emit('newStructureLoaded', null);
      }
    },
    mailDragEvent(mail) {
      this.dragData = {type: 'mail', data: mail}
    },
    attachmentDragEvent(attachment) {
      this.dragData = {type: 'attachment', data: attachment}
    },
    
    copyToTaskStepRunStart(doc) {
      this.copyDoc = doc;
      this.showCopyDoc = true
    },
    
    copyToTaskStep(stepID) {
      // let jBody = this.drag
      //
      //
      this.fetch({
        method: 'POST',
        url: `/tasks/copyMessage/${this.userInfo.entityID}/${this.userInfo.userID}/${this.task.taskID}/${stepID}/3/${this.copyDoc.documentDBID}`,
      })
          .then((data) => {
            this.showSnack('Info', 'Copy Complete', 'Close', 'primary')
            this.$emit('refreshTask')
          })
          .catch((e) => {
            // eslint-disable-next-line
            console.log(e)
            this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
          })
    },
    
    removeSaveMessage(navigate) {
      this.showSaveMessage = false;
      // this.savePrompt = false;
      this.$emit('savePrompt', false)
      
      if (navigate) {
        this.$nextTick(() => {
          document.elementFromPoint(this.clickPosition.x, this.clickPosition.y).click();
        })
      }
    },
    
    savePromptClicked(event) {
      // this.savePrompt = false;
      this.$emit('savePrompt', false)
      this.clickPosition.x = event.x;
      this.clickPosition.y = event.y;
      this.showSaveMessage = true;
    },
    
    openFieldNoteEditor(data) {
      
      return new Promise((resolve, reject) => {
        
        // if (typeof data.valueTree == 'undefined') {
        this.editorHtml = data.value != null && data.value != '' ? data.value : "";
        
        
        this.noteFieldDescription = data.description;
        this.showNoteFieldEditor = true;
        
        var refreshId = setInterval(() => {
          if (!this.showNoteFieldEditor) {
            
            if (this.updateNoteFieldData) {
              data.value = this.editorHtml
              resolve('Updated');
              clearInterval(refreshId);
            } else {
              reject('Dismissed');
              clearInterval(refreshId);
            }
            
          }
        }, 100);
      })
    },
    
    starTask(data) {
      this.$emit('starTask', data)
    },
    linkMailToStep(mail) {
      
      mail.stepID = this.selectedStep.stepID;
      eventBus.$emit('taskMailLinked', mail);
      
      this.fetch({
        url: '/tasks/linkMailToStep/' + this.userInfo.entityID + '/' + this.userInfo.userID + '/' + mail.messageID + '/' + this.selectedStep.stepID,
        method: 'PATCH'
      })
          .catch((e) => {
            // eslint-disable-next-line
            console.log(e)
          })
    },
    linkMailAttachmentToStep(mail, attachment) {
      // mail.stepID = this.selectedStep.stepID;
      // eventBus.$emit('taskMailLinked', mail);
      
      let mailPath = mail.fk_import_user != 0 ? mail.fk_import_user + '-' + mail.mailID : mail.fk_gmail_group_mailbox_folder + '-' + mail.mailID
      this.fetch({
        url: '/tasks/linkMailAttachmentToStep/' + this.userInfo.entityID + '/' + this.userInfo.userID + '/' + mailPath + '/' + this.selectedStep.stepID + '/' + this.task.taskID,
        method: 'PATCH',
        body: JSON.stringify(attachment)
      })
          .then((data) => {
            this.task.structure.documents.push(data);
            eventBus.$emit('mailAttchmentLinkedToStep', true)
          })
          .catch((e) => {
            // eslint-disable-next-line
            console.log(e)
          })
    },
    allowDrop: function (ev) {
      ev.preventDefault();
    },
    drop: function (ev) {
      ev.preventDefault();
      
      switch (this.dragData.type) {
        case '': {
          
          break;
        }
        case 'mail': {
          this.linkMailToStep(this.dragData.data);
          break
        }
        case 'attachment': {
          this.linkMailAttachmentToStep(this.dragData.data.mail, this.dragData.data.attachment)
          break
        }
      }
    },
  },
  computed: {
    taskId() {
      return this.task?.taskID || null
    }
  },
  
}
</script>

<style scoped>
.overlay {
  position: fixed; /* Sit on top of the page content */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.0); /* Black background with opacity */
  z-index: 4; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
  display: block;
}
</style>
