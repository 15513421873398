<template>
    <div
            class="hover-item"
            @dragover="allowDrop ? $event.preventDefault() : null"
            @drop="allowDrop ? drop($event) : null"
            @contextmenu="show"
    >
        <v-layout row style="overflow-x: hidden; overflow-y: auto">
            <v-flex xs12 style="overflow-x: hidden; overflow-y: auto; cursor: pointer" @click="taskSelected()">
<!--TaskID / Status / Owner / Folder-->
                <v-layout row align-center style="overflow-x: hidden; overflow-y: auto">
                    <v-flex shrink class="px-1">
                        <v-tooltip right>
                            <v-layout row slot="activator" align-center>
<!--Task Status-->
                                <v-flex shrink>
                                    <!--icon--><v-icon small class="pa-0 ma-0" color="primary">{{taskStatusIcon}}</v-icon>
                                </v-flex>
<!--Task No-->
                                <v-flex shrink class="pl-1">
                                    <span class="font-weight-black caption" style="float: right; color: var(--task-number-color)">#{{ task.taskID }}</span>
                                </v-flex>

                            </v-layout>

                            <span>{{taskStatusText}}</span>
                        </v-tooltip>
                        <div class="text-xs-center caption greyType--text" style="white-space: nowrap">{{getShortDate(task.created)}}</div>
                    </v-flex>
                    <v-flex xs12>
<!--Owner-->
                        <div class="py-0 my-0 text-truncate pt-1">
                            <v-icon style="color: var(--on-surface-grey)" class="pr-1 py-0" small>person</v-icon>
                            <template v-if="!loading">
                                <span class="text--primary caption text-truncate">{{ task.ownerName + ' ' + task.ownerSurname}}</span>
                                <template v-if="typeof task.subOwnerID != 'undefined' && task.subOwnerID != null && task.subOwnerID !== task.ownerID">
                                    <v-icon style="color: var(--on-surface-grey)" class="pr-1 pl-2 py-0" small>people</v-icon>
                                    <span class="text--primary caption text-truncate " v-if="!loading">{{ task.subOwnerName + ' ' + task.subOwnerSurname}}</span>
                                </template>
                            </template>
                            <div v-else class="caption clear-text" :class="loading ? 'placeholder-content rounded-border' : ''" style="float: right; width: calc(100% - 20px)">hello</div>
                        </div>
<!--Folder-->
                        <div class="text-truncate pt-1">
                            <!--icon--><v-icon style="color: var(--on-surface-grey)" class="py-0 pr-1" small>folder</v-icon>
                            <span class="caption text-truncate" v-if="!loading">{{task.folderName}}</span>
                            <div v-else class="caption clear-text" :class="loading ? 'placeholder-content rounded-border' : ''" style="float: right; width: calc(100% - 20px)">hello</div>
                        </div>
                    </v-flex>
                </v-layout>
<!--Title-->
                <div class="text-truncate pl-1 pt-1" style="overflow-x: hidden; overflow-y: auto">
                    <!--icon--><v-icon style="color: var(--on-surface-grey)" class="pr-1" small>assignment_turned_in</v-icon>
                    <span class="caption font-weight-bold">{{ titleString }}</span>
                </div>
<!--Task Status-->
                <div class="text-truncate pl-1 pt-1" style="overflow-x: hidden; overflow-y: auto">
                    <v-layout row align-center style="overflow-x: hidden;">
                        <v-flex shrink>
                            <!--icon--><v-icon style="color: var(--on-surface-grey)" class="pr-1" small>mode_comment</v-icon>
                        </v-flex>
                        <v-flex xs12 style="overflow-x: hidden" class="text-truncate">
                            <span
                                    v-if="typeof task.workflowStatusValue != 'undefined' && task.workflowStatusValue != null"
                                    class="caption text-truncate"
                                    style="overflow-x: hidden"
                            >
                                {{task.workflowStatusType == 0 ? task.workflowStatusDescription : task.workflowStatusText}}
                            </span>
                            <span
                                    v-else
                                    class="caption text-truncate"
                                    style="overflow-x: hidden"
                            >
                                No Status Set
                            </span>
                        </v-flex>
                        <v-flex
                                v-if="typeof task.workflowStatusValue != 'undefined' && task.workflowStatusValue != null && task.taskState === 0"
                                grow
                                class="px-1"
                                style="overflow-y: hidden; over"
                        >
                            <span class="caption greyType--text" style="overflow-x: hidden; white-space: pre">{{`${getDaysOld(task.workflowStatusStartDate == null ? task.workflowStatusCreated : task.workflowStatusStartDate)} ${getDaysOld(task.workflowStatusStartDate == null ? task.workflowStatusCreated : task.workflowStatusStartDate) != 1 ? `Days` : `Day`}`}}</span>
                        </v-flex>
                    </v-layout>
                </div>
<!--Folder Groups / Task Age-->
                <v-layout row class="pl-1" align-center>
<!--Folder Groups-->
                    <v-flex xs12 class="text-truncate">
                        <!--icon--><v-icon style="color: var(--on-surface-grey)" class="pr-1" small>folder_shared</v-icon>
                        <span class="caption" v-if="!loading">{{ task.folderGroupNames }}</span>
                        <div v-else class="caption clear-text" :class="loading ? 'placeholder-content rounded-border' : ''" style="float: right; width: calc(100% - 20px)">hello</div>
                    </v-flex>
<!--Task Age-->
                    <v-flex shrink class="px-1" v-if="task.taskState == 0">
                        <v-tooltip right>
                        <span
                                slot="activator"
                                class="caption font-weight-light text-truncate"
                                style="white-space: nowrap"
                        >
                            {{ `${getDaysOld(task.created)} ${getDaysOld(task.created) != 1 ? `Days` : `Day`}` }}
                        </span>
                            <span>{{ 'Task Created: ' + getLongDate(task.created) }}</span>
                        </v-tooltip>
                    </v-flex>
                </v-layout>
            </v-flex>
            <v-flex shrink class="px-1">
                <template v-if="task.access && showNav">
                    <v-layout column justify-space-around align-center class="fill-height pa-0 ma-0">
<!--Navigate To Task-->
                        <v-flex shrink v-if="showNav">
                            <v-tooltip left>
                                <v-btn
                                        icon
                                        small
                                        flat
                                        class="pa-0 ma-0 smallBtn"
                                        slot="activator"
                                        color="secondary"
                                        @click="gotoTask();"
                                >
                                    <v-icon>keyboard_arrow_right</v-icon>
                                </v-btn>
                                <span>Navigate To Task</span>
                            </v-tooltip>
                        </v-flex>

                    </v-layout>
                </template>
                <template v-if="!task.access">
                    <v-layout column justify-space-around align-center class="fill-height pa-0 ma-0">
<!--Access Icon-->
                        <v-flex shrink v-if="!loading">
                            <v-tooltip right>
                                <v-btn
                                        icon
                                        small
                                        flat
                                        class="pa-0 ma-0 smallBtn"
                                        color="grey"
                                        slot="activator"
                                >
                                    <v-icon>security</v-icon>
                                </v-btn>
                                <span>Access To Task is Denied</span>
                            </v-tooltip>
                        </v-flex>
                        <template v-else>
                            <v-flex shrink>
                                <div class="placeholder-btn placeholder-content"></div>
                            </v-flex>
                            <v-flex shrink>
                                <div class="placeholder-btn placeholder-content"></div>
                            </v-flex>
                            <v-flex shrink>
                                <div class="placeholder-btn placeholder-content"></div>
                            </v-flex>
                        </template>
                    </v-layout>
                </template>
            </v-flex>
            <v-flex shrink class="px-1">
                <v-layout column justify-space-around align-center class="fill-height pa-0 ma-0">
                    <v-flex shrink>
                        <slot name="right"></slot>
                    </v-flex>
                </v-layout>
            </v-flex>
        </v-layout>
        <div>
            <slot name="lower"></slot>
        </div>

        <div v-if="showEscalation">
            <v-layout row align-center>
<!--                v-if="typeof escalations[item.taskID] != 'undefined'"-->
                <v-flex xs12 class="caption font-weight-bold red--text px-2" v-if="diffString != null">
                    {{`${diffString} Overdue`}}
                </v-flex>
                <v-flex shrink class="pr-2 pb-1" @click="loadingRemove = true">
                    <v-btn
                        small
                        flat
                        color="secondary"
                        class="pa-0 ma-0 px-2"
                        :disabled="loadingRemove"
                        :loading="loadingRemove"
                        @click="$emit('removeEscalationRunStart', cancelRemove)"
                    >
                        Hide Escalation
                    </v-btn>
                </v-flex>
            </v-layout>
        </div>

        <v-progress-linear
                :color="task.ownerID === userInfo.userID ? 'secondary' : 'primary'"
                class="pa-0 ma-0"
                height="2"
                :value="100"
                :background-opacity="0.5"
                :background-color="task.ownerID === userInfo.userID ? 'secondary' : 'primary'"
        ></v-progress-linear>

        <v-menu
                v-model="showMenu"
                :position-x="x"
                :position-y="y"
                absolute
                offset-y
                :close-on-content-click="false"
                v-if="!softBlock && showMenu && task.access"
        >
            <v-card class="pa-1" v-if="showMenu && task.access">
                <v-layout row align-center class="pa-0 ma-0">
<!--Star Task-->
                    <v-flex shrink class="px-1">
                        <v-tooltip top>
                            <v-btn
                                    slot="activator"
                                    small
                                    :color="task.starred ? 'amber' : 'grey'"
                                    class="pa-0 ma-0 smallBtn"
                                    flat
                                    icon
                                    :loading="starLoading"
                                    :disabled="starLoading"
                                    @click="starTask()"
                            >
                                <v-icon>{{ task.starred ? 'star' : 'star_border' }}</v-icon>
                            </v-btn>
                            <span>{{ task.starred ? 'Unstar Task' : 'Star Task' }}</span>
                        </v-tooltip>
                    </v-flex>
<!--Transfer Task-->
                    <v-flex shrink class="px-1">
                        <v-tooltip top v-if="userInfo.allowTransfer == 1">
                            <v-btn
                                    @click="transferTask(task.taskID)"
                                    slot="activator"
                                    small
                                    color="red"
                                    class="pa-0 ma-0 smallBtn"
                                    flat
                                    icon
                                    :loading="transferLoading"
                                    :disabled="transferLoading"
                            >
                                <v-icon>forward</v-icon>
                            </v-btn>
                            <span>Transfer Task</span>
                        </v-tooltip>
                    </v-flex>
<!--Snooze Task-->
                    <v-flex shrink class="px-1">
                        <v-tooltip top>
                            <v-btn
                                    slot="activator"
                                    small
                                    flat
                                    icon
                                    color="blue darken-2"
                                    class="pa-0 ma-0"
                                    @click="$createCalendarEvent({start: new Date(), fkTask: task.taskID})"
                            >
                                <v-icon>event</v-icon>
                            </v-btn>
                            <span>Create Calendar Event</span>
                        </v-tooltip>
                    </v-flex>
<!--Cancel-->
                    <v-flex shrink class="px-1">
                        <v-tooltip top>
                            <v-btn
                                    @click="showMenu = !showMenu"
                                    slot="activator"
                                    small
                                    color="secondary"
                                    class="pa-0 ma-0 smallBtn"
                                    flat
                                    icon
                            >
                                <v-icon>close</v-icon>
                            </v-btn>
                            <span>Cancel</span>
                        </v-tooltip>
                    </v-flex>
                </v-layout>
            </v-card>
        </v-menu>
    </div>
</template>

<script>
    import {eventBus} from "../../main";
    import {codeBaseMixin} from "../../codeBaseMixin";
    import {mapGetters} from "vuex";

    export default {
        mixins: [codeBaseMixin],
        props: {
            userInfo: Object,
            task: Object,
            taskBar: {
                default: false,
                type: Boolean
            },
            showNav: {
                default: false,
                type: Boolean
            },
            selected: {
                type: Boolean,
                default: false
            },
            allowDrop: {
                type: Boolean,
                default: false
            },
            showEscalation: {
                type: Boolean,
                default: false
            },
            curMS: Number,
            escalationValue: Number
        },
        data() {
            return {
                starLoading: false,
                transferLoading: false,
                snoozedLoading: false,
                dragData: null,
                showMenu: false,
                x: 0,
                y: 0,
                loadingRemove: false
            }
        },
        created() {
            eventBus.$on('taskTransferStart', this.listenToTransfer);
            eventBus.$on('mailDrag', this.eventBusMailDrag);
            eventBus.$on('taskDocumentDrag', this.eventBusTaskDocumentDrag);
        },
        beforeDestroy() {
            eventBus.$off('mailDrag', this.eventBusMailDrag);
            eventBus.$off('taskDocumentDrag', this.eventBusTaskDocumentDrag);
            eventBus.$off('taskTransferStart', this.listenToTransfer);
        },
        watch: {
            "task.ownerID": {
                handler() {
                    this.transferLoading = false;
                    this.showMenu = false;
                }
            },
            "task.snoozeID": {
                handler() {
                    this.snoozedLoading = false;
                    this.showMenu = false;
                }
            },

            "task": {
                handler() {
                    this.starLoading = false;
                    this.transferLoading = false;
                    this.snoozedLoading = false;
                    this.showMenu = false;
                }
            }
        },
        methods: {

            cancelRemove() {
                this.loadingRemove = false
            },

            show (e) {
                e.preventDefault();
                this.showMenu = false;
                this.x = e.clientX;
                this.y = e.clientY;
                this.$nextTick(() => {
                    if (this.task.access) {
                        this.showMenu = true
                    } else {
                        this.showSnack('Error', 'Access Is Denied', 'Close', 'red')
                    }

                })
            },

            eventBusMailDrag(mail) {
                this.dragData = {
                    type: 'mail',
                    data: mail
                }
            },
            eventBusTaskDocumentDrag(document) {
                this.dragData = {
                    type: 'document',
                    data: document
                }
            },

            drop: function (ev) {

                try {
                    let dropData = JSON.parse(ev.dataTransfer.getData("text"));
                    if (dropData?.type === 'chatMessagesLink') {
                      this.$emit('chatMessagesLink', {messageIds: dropData.data, task: this.task})
                    } else if (['smtpMailboxMailLink', 'smtpMailLink'].includes(dropData.event)) {
                        this.$emit(dropData.event)
                    } else {
                        throw 'no Match'
                    }

                } catch (e) {
                    if (['mail','document'].includes(this.dragData.type)) {
                        this.$emit('taskBarDrop', this.dragData)
                    } else {
                        this.showSnack('Error', 'Only A Mail Or A Document Can Be Copied Here', 'Close', 'red')
                    }
                }
            },

            taskSelected() {

                if (this.task.access) {
                    this.$emit('taskSelected', this.task)
                } else {
                    this.showSnack('Error', 'Access Is Denied', 'Close', 'red')
                }
            },

            listenToTransfer(taskID) {
                if (taskID === this.task.taskID) {
                    this.transferLoading = true;
                }
            },

            gotoTask() {
                this.$emit('taskNav');
                eventBus.$emit('gotoTask', (this.task));
            },

            starTask() {
                this.starLoading = true;
                this.fetch({method: 'GET', url:`/taskSync/starredTask/${this.userInfo.entityID}/${this.userInfo.userID}/${this.task.taskID}/${this.boolToInt(!this.task.starred)}`})
                    .then(() => {
                        this.task.starred = !this.task.starred;
                        this.starLoading = false;
                        this.showMenu = false;
                    })
                    .catch((e) => {
                        this.standardError(e);
                        this.starLoading = false
                        this.showMenu = false;
                    })

            },

            transferTask(taskID) {
                eventBus.$emit('transferTask',{taskID: taskID, userInfo: this.userInfo})
            },


            snoozeTask(snoozeDate) {
                this.snoozedLoading = true;
                this.fetch({method: 'PATCH', url:`/tasks/sn/snoozeTask/${this.userInfo.entityID}/${this.userInfo.userID}/${this.task.taskID}`, body: JSON.stringify({date: snoozeDate})})
                    .then((data) => {
                        eventBus.$emit('newSnoozedTaskObj', data)
                    })
                    .catch((e) => {
                        console.log(e);
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
                        this.snoozedLoading = false;
                    })
            },

            unSnoozeTask(snoozeID) {
                this.snoozedLoading = true;
                this.fetch({method: 'PATCH', url:`/tasks/sn/unSnoozeTask/${this.userInfo.entityID}/${this.userInfo.userID}/${this.task.taskID}/${snoozeID}`})
                    .then(() => {
                        eventBus.$emit('removeSnoozedTaskObj', this.task.taskID)
                    })
                    .catch((e) => {
                        console.log(e);
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                        this.snoozedLoading = false;
                    })
            },
        },
        computed: {
            ...mapGetters("userInfo", ["softBlock"]),

            diffString() {
                if (this.showEscalation && this.escalationValue) {
                    let msOverdue = this.curMS - this.escalationValue

                    // Minutes
                    let tVal = (msOverdue / 1000) / 60;
                    if (tVal < 60) {
                        return `${Math.round(tVal)} Minute${Math.round(tVal)>1?'s':''}`;
                    } else {
                        // Hours
                        tVal = tVal / 60;
                        if (tVal < 24) {
                            return `${Math.round(tVal)} Hour${Math.round(tVal)>1?'s':''}`;
                        } else {
                            // Days
                            tVal = tVal / 24;
                            if (tVal < 30.4) {
                                return `${Math.round(tVal)} Day${Math.round(tVal)>1?'s':''}`;
                            } else {
                                // Months
                                tVal = tVal / 30.4;
                                return `${Math.round(tVal)} Month${Math.round(tVal)>1?'s':''}`;
                            }
                        }
                    }
                } else {
                    return null
                }
            },


            loading() {
                return this.task.ownerName == null
            },

            taskStatusIcon() {
                if (this.task != null && typeof this.task.snoozed != 'undefined' && this.task.snoozed) {
                    return 'alarm'
                } else {
                    if (this.task !== null) {
                        return this.task.taskState === 1 ? 'done_all' : 'edit';
                    } else {
                        return  'done_all'
                    }

                }
            },

            taskStatusText() {
                if (this.task.snoozed) {
                    return `Snoozed ${this.task.status_percentage != null ? this.task.status_percentage : '0'}%`
                } else {
                    return `${this.task.taskState === 1 ? 'Closed' : 'Open'} ${this.task.status_percentage != null ? this.task.status_percentage : '0'}%`;
                }
            },

            titleString() {
                return !this.blankString(this.task.title) ? this.task.title : 'No Title Set'
            }

        },
    }
</script>

<style scoped>
    .smallBtn {
        width: 25px;
        height: 25px;
    }
</style>
