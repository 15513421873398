import { render, staticRenderFns } from "./generalMailContainer.vue?vue&type=template&id=9ddc8fb6&scoped=true"
import script from "./generalMailContainer.vue?vue&type=script&lang=js"
export * from "./generalMailContainer.vue?vue&type=script&lang=js"
import style0 from "./generalMailContainer.vue?vue&type=style&index=0&id=9ddc8fb6&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9ddc8fb6",
  null
  
)

export default component.exports