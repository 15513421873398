<template>
  <div>

    <div v-if="displayDoc != null">
      <v-layout row align-center class="pt-1">
        <v-flex xs12>
          <app-document-container
            :document="displayDoc"
            :user-info="userInfo"
            :srcTaskAttachment="false"
            :dense="true"
          />
        </v-flex>
        <v-flex shrink class="pl-2">
          <v-tooltip top>
            <v-btn
              color="primary"
              icon
              fab
              slot="activator"
              @click="generateStartup()"
              :disabled="!intToBool(value.editable) || !stepEditable"
            >
              <v-icon>history_edu</v-icon>
            </v-btn>
            <span>Generate Document</span>
          </v-tooltip>
        </v-flex>
      </v-layout>
    </div>

    <div v-else class="text-xs-center">
      <v-btn
        color="primary"
        small
        @click="generateStartup"
        :disabled="!intToBool(value.editable) || !stepEditable"
      >
        <v-icon left>history_edu</v-icon>
        Generate Document
      </v-btn>
    </div>


    <input-dlg
      v-if="showGenerate"
      :show-dialog="showGenerate"
      title="Generate Document"
      :cancel-button="true"
      :show-input-box="false"
      @dismiss="showGenerate = false"
      :body-padding="false"
      :width="650"
    >
      <template slot="body">
        <div class="py-1" style="overflow-x: hidden">
          <template v-if="startingUp">
            <div class="py-4 c-d-flex c-justify-center">
              <v-progress-circular
                :size="40"
                :width="5"
                color="primary"
                indeterminate
              />
            </div>
          </template>

          <template v-else>
            <div style="max-height: 600px; position: relative" class="of-y">

              <div class="caption text-xs-center" style="z-index: 9; position: sticky; top: 0; background: var(--page-back)" >
                <div class="py-1 text-xs-center">
                  *NB please remember to save changes before generating a new word template
                </div>

                <div style="height: 1px; background: var(--card-back-accent);"/>
              </div>

              <div
                class="c-d-flex c-align-center px-1"
                v-for="field in sortedFields"
                :key="field.fieldId"
                style="border-bottom: 1px solid var(--card-back-accent)"
              >
                <div class="px-1">
                  <v-icon v-if="field.status === -1" color="primary">keyboard</v-icon>
                  <v-icon v-if="field.status === 0" color="error">remove_circle</v-icon>
                  <v-icon v-if="field.status === 1" color="amber">warning</v-icon>
                  <v-icon v-if="field.status === 2" color="green">check</v-icon>
                </div>
                <div class="c-flex-grow-1 caption px-1 py-2" style="line-height: 1">
                  <div class=" font-weight-bold">
                    {{field.promt || field.fieldName}}
                  </div>
                  <div v-if="field.error" class="pt-1 grey--text text--darken-2">
                    {{field.error}}
                  </div>
                  <div v-if="field.warning" class="pt-1 grey--text text--darken-2">
                    {{field.warning}}
                  </div>

                  <div v-if="field.type === 'input'" class="pt-1">
                    <v-text-field
                      hide-details
                      class="pa-0 ma-0"
                      v-model="field.val"
                      :disabled="generating"
                    />
                  </div>
                </div>
              </div>


              <div style="position: sticky; bottom: 0" class="c-d-flex justify-end">
                <v-btn
                  color="primary"
                  @click="validateGenerate()"
                  :disabled="generating"
                  :loading="generating"
                >
                  Generate
                </v-btn>
              </div>
            </div>
          </template>




        </div>
      </template>
      <!--        <template slot="actions">-->
      <!--          <v-btn-->
      <!--            class="pa-0 ma-0 px-2"-->
      <!--            color="secondary"-->
      <!--            @click="testSmtpSettings()"-->
      <!--            :loading="testingSmtp"-->
      <!--            :disabled="testingSmtp || !testReady"-->
      <!--            small-->
      <!--            outline-->
      <!--          >-->
      <!--            <v-icon left>check_box</v-icon>-->
      <!--            Test SMTP Server Settings-->
      <!--          </v-btn>-->
      <!--          <v-btn-->
      <!--            class="pa-0 ma-0 ml-2 px-2"-->
      <!--            color="secondary"-->
      <!--            @click="updateServerVal"-->
      <!--            :loading="testingSmtp || modifyingServer"-->
      <!--            :disabled="testingSmtp || !testReady || modifyingServer"-->
      <!--            small-->
      <!--          >-->
      <!--            <v-icon left>save</v-icon>-->
      <!--            Done-->
      <!--          </v-btn>-->
      <!--        </template>-->
    </input-dlg>


  </div>
</template>

<script>

import {codeBaseMixin} from "../../../../../../codeBaseMixin";
import {mapGetters} from 'vuex';
import {blankString} from "../../../../../../codeFunctions";
import {DatasetBinder} from "@/Tasks/TaskView V2/components/TaskViewDetail/datasetBinder";
import {EvalBinder} from "@/Tasks/TaskView V2/components/TaskViewDetail/evalsBinder";
import InputDlg from "@/components/General/InputDlg.vue";
import appInputDlg from "@/components/General/InputDlg.vue";
import appDocumentContainer from "@/Tasks/TaskView V2/components/Documents/DocumentContainer.vue";

export default {
  components: {appDocumentContainer, appInputDlg, InputDlg},
  mixins: [codeBaseMixin],
  props: {
    value: Object,
    stepEditable: Boolean,
    task: Object
  },

  data() {
    return {
      bindData: true,
      curReqID: -1,
      loadingMap: false,
      loading: false,
      showGenerate: false,
      startingUp: false,
      fields: [],
      generating: false
    }
  },

  watch: {},

  methods: {
    async generateStartup() {
      try {
        this.showGenerate = true
        this.startingUp = true
        this.fields = await this.$newReq('GET', `mswt/generate/startup/${this.task.taskID}/${this.value.fieldID}`)
      } catch (e) {
        console.log(e)
        this.$snack.networkError()
      } finally {
        this.startingUp = false
      }
    },

    async validateGenerate() {
      try {
        this.generating = true

        for (const field of this.sortedFields) {
          if (field.error)
            return this.$snack.error(`${field.promt || field.fieldName} - ${field.error}`)

          if (field.required && !field.val)
            return this.$snack.error(`${field.promt || field.fieldName} - Required field`)
        }


        const document = await this.$newReq('POST', `mswt/generate/${this.task.taskID}/${this.value.fieldID}`, this.fields)

        this.task.structure.documents.push(document);
        this.value.value = document.documentDBID
        this.$emit('forceSave', true)
        this.showGenerate = false
      } catch (e) {
        console.log(e)
        this.$snack.networkError()
      } finally {
        this.generating = false
      }
    }
  },

  computed: {
    ...mapGetters('userInfo', ['userInfo']),
    binderField() {
      const field = this.evalBinder.binderFields.find(binderRef => binderRef.field.fieldID === this.value.fieldID)
      return field
    },
    sortedFields() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.fields.sort((a,b) => a.status - b.status)
    },
    displayDoc() {
      try {
        return this.task.structure.documents.find(obj => obj.documentDBID === (this.value && this.value.value ? +this.value.value : null) )
      } catch (e) {
        return null
      }
    },
  }

}
</script>

<style scoped>

</style>
