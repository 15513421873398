<template>
    <div>
        <v-layout row align-center justify-start class="pt-1">
<!--Create Task-->
            <v-flex shrink v-if="!softBlock && typeof userInfo.createTask != 'undefined' && intToBool(userInfo.createTask)" class="pl-1">
                <v-tooltip right slot="activator" >
                    <v-btn
                            @click="showCreateTask = true"
                            slot="activator"
                            icon
                            class="pa-0 ma-0"
                            color="secondary"
                            flat
                            small
                    >
                        <v-icon>add</v-icon>
                    </v-btn>

                    <span>New Task</span>

                    <app-new-task-dlg
                            :show-dialog="showCreateTask"
                            :user-info="userInfo"
                            @dismiss="showCreateTask = false"
                            :folder-groups="folderGroups"
                            :local-data="localData"
                            :show-sync="showSync"
                            :get-task-header="getTaskHeader"
                            @clearQuick="quickTask = null"
                            :quick-task="quickTask"
                    />
                </v-tooltip>
            </v-flex>
<!--User-->
            <v-flex shrink>
                <v-menu open-on-hover bottom offset-y class="pl-1">
                    <v-btn
                            slot="activator"
                            icon
                            flat
                            class="pa-0 ma-0"
                            color="primary"
                            depressed
                            small
                    >
                        <v-icon>{{ filterSettings.userOption == 0 ? 'account_circle' : (filterSettings.userOption == 1 ? 'groups' : (filterSettings.userOption == 2 ? 'person' : 'supervisor_account'))}}</v-icon>
                    </v-btn>
                  <v-card class="page-back">
                    <v-list dense>
                      <v-subheader class="primary--text">Filter By User</v-subheader>
                      
                      <v-divider></v-divider>
                      
                      <v-list-tile @click="filterSettings.userOption = 0; updateFilterSetings();" class="py-0 my-0">
                        <v-icon color="#primary" left>account_circle</v-icon>
                        <v-list-tile-title>My Tasks</v-list-tile-title>
                        <v-icon color="#388E3C" v-if="filterSettings.userOption == 0" right>done</v-icon>
                      </v-list-tile>
                      
                      <v-list-tile @click="filterSettings.userOption = 2; updateFilterSetings();" class="py-0 my-0">
                        <v-icon color="#primary" left>person</v-icon>
                        <v-list-tile-title>Me as Owner</v-list-tile-title>
                        <v-icon color="#388E3C" v-if="filterSettings.userOption == 2" right>done</v-icon>
                      </v-list-tile>
                      
                      <v-list-tile @click="filterSettings.userOption = 3; updateFilterSetings();" class="py-0 my-0">
                        <v-icon color="#primary" left>supervisor_account</v-icon>
                        <v-list-tile-title>Me as Sub-owner</v-list-tile-title>
                        <v-icon color="#388E3C" v-if="filterSettings.userOption == 3" right>done</v-icon>
                      </v-list-tile>
                      
                      <v-list-tile @click="filterSettings.userOption = 1; updateFilterSetings();" class="py-0 my-0">
                        <v-icon color="#primary" left>groups</v-icon>
                        <v-list-tile-title>All Users</v-list-tile-title>
                        <v-icon color="#388E3C" v-if="filterSettings.userOption == 1" right>done</v-icon>
                      </v-list-tile>
                    </v-list>
                  </v-card>
                </v-menu>
            </v-flex>
<!--State-->
            <v-flex shrink>
                <v-menu open-on-hover bottom offset-y class="pl-1">
                        <v-btn
                                slot="activator"
                                icon
                                flat
                                class="pa-0 ma-0"
                                color="primary"
                                depressed
                                small
                        >
                            <v-icon v-if="filterSettings.stateOption == 0">edit</v-icon>
                            <v-icon v-if="filterSettings.stateOption == 1">alarm</v-icon>
                            <v-icon v-if="filterSettings.stateOption == 2">spellcheck</v-icon>
                            <v-icon v-if="filterSettings.stateOption == 3">done_all</v-icon>
                        </v-btn>
                  <v-card class="page-back">
                    <v-list dense>
                      <v-subheader class="primary--text">Filter By Task State</v-subheader>
                      <v-divider></v-divider>
                      <v-list-tile @click="setStateFilter(0)" class="py-0 my-0">
                        <v-icon color="#primary" left>edit</v-icon>
                        <v-list-tile-title>Open Tasks</v-list-tile-title>
                        <v-icon color="#388E3C" v-if="filterSettings.stateOption == 0" right>done</v-icon>
                      </v-list-tile>
                      
                      <v-list-tile @click="setStateFilter(1)" class="py-0 my-0">
                        <v-icon color="#primary" left>alarm</v-icon>
                        <v-list-tile-title>Snoozed Tasks</v-list-tile-title>
                        <v-icon color="#388E3C" v-if="filterSettings.stateOption == 1" right>done</v-icon>
                      </v-list-tile>
                      
                      <v-list-tile @click=" setStateFilter(2)" class="py-0 my-0">
                        <v-icon color="#primary" left>spellcheck</v-icon>
                        <v-list-tile-title>Open & Snoozed Tasks</v-list-tile-title>
                        <v-icon color="#388E3C" v-if="filterSettings.stateOption == 2" right>done</v-icon>
                      </v-list-tile>
                      
                      <v-list-tile @click="setStateFilter(3)" class="py-0 my-0">
                        <v-icon color="#primary" left>done_all</v-icon>
                        <v-list-tile-title>Closed Tasks</v-list-tile-title>
                        <v-icon color="#388E3C" v-if="filterSettings.stateOption == 3" right>done</v-icon>
                      </v-list-tile>
                    </v-list>
                  </v-card>
                </v-menu>
            </v-flex>
<!--Starred-->
            <v-flex shrink>
                <v-menu open-on-hover bottom offset-y class="pl-1">
                        <v-btn
                                slot="activator"
                                icon
                                flat
                                class="pa-0 ma-0"
                                color="primary"
                                depressed
                                small
                        >
                            <v-icon>{{ filterSettings.starOption == 0 ? 'star_border' : 'star'}}</v-icon>
                        </v-btn>
                  <v-card class="page-back">
                    <v-list dense class="page-back">
                      <v-subheader class="primary--text">Filter By Star</v-subheader>
                      <v-divider></v-divider>
                      <v-list-tile @click="filterSettings.starOption = 0; updateFilterSetings();" class="py-0 my-0">
                        <v-icon color="#primary" left>star_border</v-icon>
                        <v-list-tile-title>All Tasks</v-list-tile-title>
                        <v-icon color="#388E3C" v-if="filterSettings.starOption == 0" right>done</v-icon>
                      </v-list-tile>
                      
                      <v-list-tile @click="filterSettings.starOption = 1; updateFilterSetings();" class="py-0 my-0">
                        <v-icon color="#primary" left>star</v-icon>
                        <v-list-tile-title>Starred Tasks</v-list-tile-title>
                        <v-icon color="#388E3C" v-if="filterSettings.starOption == 1" right>done</v-icon>
                      </v-list-tile>
                    </v-list>
                  </v-card>
                </v-menu>
            </v-flex>
<!--Group By-->
            <v-flex shrink>
                <v-menu open-on-hover bottom offset-y class="pl-1">
                        <v-btn
                                slot="activator"
                                icon
                                flat
                                class="pa-0 ma-0"
                                color="primary"
                                depressed
                                small
                        >
                            <v-icon v-if="filterSettings.groupOption == 0">folder</v-icon>
                            <v-icon v-if="filterSettings.groupOption == 1">person</v-icon>
                            <v-icon v-if="filterSettings.groupOption == 3">check_box</v-icon>
                            <v-icon v-if="filterSettings.groupOption == 2">ballot</v-icon>
                            <v-icon v-if="filterSettings.groupOption == 5">folder_shared</v-icon>
                            <v-icon v-if="filterSettings.groupOption == 6">people</v-icon>
                            <v-icon v-if="filterSettings.groupOption == 4">close</v-icon>
                        </v-btn>
                  
                  <v-card class="page-back">
                    <v-list dense>
                      <v-subheader class="primary--text">Group By</v-subheader>
                      <v-divider></v-divider>
                      <v-list-tile @click="filterSettings.groupOption = 0; updateFilterSetings(); $emit('dropAll')"
                                   class="py-0 my-0">
                        <v-icon color="#primary" left>folder</v-icon>
                        <v-list-tile-title>Folder</v-list-tile-title>
                        <v-icon color="#388E3C" v-if="filterSettings.groupOption == 0" right>done</v-icon>
                      </v-list-tile>
                      
                      <v-list-tile @click="filterSettings.groupOption = 5; updateFilterSetings(); $emit('dropAll')"
                                   class="py-0 my-0">
                        <v-icon color="#primary" left>folder_shared</v-icon>
                        <v-list-tile-title>Folder Groups</v-list-tile-title>
                        <v-icon color="#388E3C" v-if="filterSettings.groupOption == 5" right>done</v-icon>
                      </v-list-tile>
                      
                      <v-list-tile @click="filterSettings.groupOption = 1; updateFilterSetings(); $emit('dropAll')"
                                   class="py-0 my-0">
                        <v-icon color="#primary" left>person</v-icon>
                        <v-list-tile-title>Owner</v-list-tile-title>
                        <v-icon color="#388E3C" v-if="filterSettings.groupOption == 1" right>done</v-icon>
                      </v-list-tile>
                      
                      <v-list-tile @click="filterSettings.groupOption = 6; updateFilterSetings(); $emit('dropAll')"
                                   class="py-0 my-0">
                        <v-icon color="#primary" left>people</v-icon>
                        <v-list-tile-title>Sub Owner</v-list-tile-title>
                        <v-icon color="#388E3C" v-if="filterSettings.groupOption == 6" right>done</v-icon>
                      </v-list-tile>
                      
                      <!--<v-list-tile @click="filterSettings.groupOption = 3; updateFilterSetings(); $emit('dropAll')" class="py-0 my-0">-->
                      <!--<v-icon color="#primary" left>check_box</v-icon>-->
                      <!--<v-list-tile-title>Task State</v-list-tile-title>-->
                      <!--<v-icon color="#388E3C" v-if="filterSettings.groupOption == 3" right>done</v-icon>-->
                      <!--</v-list-tile>-->
                      
                      <v-list-tile @click="filterSettings.groupOption = 2; updateFilterSetings(); $emit('dropAll')"
                                   class="py-0 my-0">
                        <v-icon color="#primary" left>ballot</v-icon>
                        <v-list-tile-title>Workflow</v-list-tile-title>
                        <v-icon color="#388E3C" v-if="filterSettings.groupOption == 2" right>done</v-icon>
                      </v-list-tile>
                      
                      <v-list-tile @click="filterSettings.groupOption = 4; updateFilterSetings(); $emit('dropAll')"
                                   class="py-0 my-0">
                        <v-icon color="#primary" left>close</v-icon>
                        <v-list-tile-title>None</v-list-tile-title>
                        <v-icon color="#388E3C" v-if="filterSettings.groupOption == 4" right>done</v-icon>
                      </v-list-tile>
                    </v-list>
                  </v-card>
                </v-menu>
            </v-flex>
<!--Order By-->
            <v-flex shrink>
                <v-menu open-on-hover bottom offset-y class="pl-1">
                        <v-btn
                                slot="activator"
                                icon
                                flat
                                class="pa-0 ma-0"
                                color="primary"
                                depressed
                                small
                        >
                            <v-icon>swap_vert</v-icon>
                        </v-btn>
                  <v-card class="page-back">
                    <v-list dense>
                      <v-subheader class="primary--text">Order By</v-subheader>
                      <v-divider></v-divider>
                      <v-list-tile @click="filterSettings.orderOption = 0; updateFilterSetings()" class="py-0 my-0">
                        <v-icon color="#primary" left>arrow_upward</v-icon>
                        <v-list-tile-title>Ascending</v-list-tile-title>
                        <v-icon color="#388E3C" v-if="filterSettings.orderOption == 0" right>done</v-icon>
                      </v-list-tile>
                      <v-list-tile @click="filterSettings.orderOption = 1; updateFilterSetings()">
                        <v-icon color="#primary" left>arrow_downward</v-icon>
                        <v-list-tile-title>Descending</v-list-tile-title>
                        <v-icon color="#388E3C" v-if="filterSettings.orderOption == 1" right>done</v-icon>
                      </v-list-tile>
                    </v-list>
                  </v-card>
                </v-menu>
            </v-flex>
<!--Expand Drop All-->
            <v-flex shrink>
                <v-tooltip v-if="filterSettings.groupOption != 4" top class="pl-1">
                    <v-btn
                            @click="dropExpandAll()"
                            slot="activator"
                            icon
                            flat
                            class="pa-0 ma-0"
                            color="primary"
                            depressed
                            small
                    >
                        <v-icon>{{ droppedItems.length == 0 ? 'keyboard_arrow_down' : 'keyboard_arrow_up'}}</v-icon>
                    </v-btn>
                    <span>{{ droppedItems.length == 0 ? 'Expand All Groups' : 'Collapse All Groups'}}</span>
                </v-tooltip>
            </v-flex>
<!--Refresh-->
            <v-flex xs12 class="text-xs-right">
                <v-tooltip top class="pl-1">
                    <v-btn
                            @click="$emit('refresh')"
                            slot="activator"
                            icon
                            flat
                            class="pa-0 ma-0"
                            color="primary"
                            depressed
                            small
                            :loading="refreshLoading"
                            :disabled="refreshLoading"
                    >
                        <v-icon>refresh</v-icon>
                    </v-btn>
                    <span>Refresh Task List</span>
                </v-tooltip>
            </v-flex>
        </v-layout>
    </div>
</template>

<script>
    import { eventBus } from '../../../main';
    import {codeBaseMixin} from "../../../codeBaseMixin";
    import NewTaskDlg from "../../NewTask/NewTaskDlg";
    import {mapGetters} from "vuex";

    export default {
        components: {appNewTaskDlg : NewTaskDlg},
        mixins: [codeBaseMixin],
        props: {
            filterSettings: Object,
            userInfo: Object,
            droppedItems: Array,
            filter: String,
            folderGroups: Array,
            localData: Object,
            showSync: Boolean,
            getTaskHeader: Function,
            refreshLoading: Boolean
        },
        data() {
            return {
                showCreateTask: false,
                quickTask: null
            }
        },
        created() {
            eventBus.$on('quickTask', this.showQuickTask)

            if (this.filterSettings.stateOption === 3) {
                this.setStateFilter(2)
            }


        },
        beforeDestroy() {
            eventBus.$off('quickTask', this.showQuickTask)
        },
        methods: {

            showQuickTask(val) {
                this.quickTask = val;
                this.showCreateTask = true;
            },

            setStateFilter(newValue) {
                this.filterSettings.stateOption = newValue;
                this.updateFilterSetings();
            },

            updateFilterSetings() {
                eventBus.$emit('updateFilterSettings', this.filterSettings);
                this.$emit('scrollToTop');
                this.fetch({method: 'PATCH', url: '/localUsers/update/userSortPreference/' + this.userInfo.entityID + '/' + this.userInfo.userID, body: JSON.stringify(this.filterSettings)})
                    .then((data) => {
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e)
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                    })

            },
            dropExpandAll() {
                this.$emit('drop')
            },
        },
        computed: {
            ...mapGetters("userInfo", ["softBlock"])
        }
    }
</script>

<style scoped>

</style>
