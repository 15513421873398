<template>
    <v-card  class="scrollable fill-height">
        <v-layout row fill-height class="scrollable">
            <template v-if="loadingInfo">
                <v-flex xs12 class="pa-3 text-xs-center">
                    <v-progress-circular
                            :size="40"
                            :width="5"
                            color="primary"
                            indeterminate
                    />
                </v-flex>
            </template>
            <template v-else>
<!--Manage Bar-->
                <v-flex xs4 class="scrollable">
                    <v-layout column fill-height class="scrollable">
                        <v-flex shrink class="body-1 title-color white--text pa-2">
                            Workflow Options
                        </v-flex>
                        <v-flex xs12 class="scrollable">
                            <app-manage-bar
                                    :selectedWorkflow="selectedWorkflow"
                                    :userInfo="userInfo"
                                    :workflow-info="workflowInfo"
                            />
                        </v-flex>
                    </v-layout>
                </v-flex>
              <v-flex shrink><v-divider class="title-color darken-1" vertical></v-divider></v-flex>
                <!--Manage Status-->
                <v-flex xs8 class="scrollable">
                    <v-layout column fill-height class="scrollable">
                        <v-flex shrink class="body-1 title-color white--text pa-2">
                            Status Options
                        </v-flex>
                        <v-flex xs12 class="scrollable">
                            <app-manage-detail
                                    :selected-workflow="selectedWorkflow"
                                    :user-info="userInfo"
                                    :workflow-info="workflowInfo"
                                    :local-data="localData"
                            />
                        </v-flex>
                    </v-layout>
                </v-flex>
            </template>
        </v-layout>
    </v-card>

</template>

<script>
    import {codeBaseMixin} from "../../../../../codeBaseMixin";
    import ManageBar from './ManageBar';
    import ManageDetail from './ManageDetail'

    export default {
        mixins: [codeBaseMixin],
        components: {
            appManageBar: ManageBar,
            appManageDetail: ManageDetail
        },
        props: {
            userInfo: Object,
            selectedWorkflow: Object,
            workflowViewOption: Number,
            localData: Object
        },
        data() {
            return {
                loadingInfo: false,
                workflowInfo: null
            }
        },
        watch: {
            selectedWorkflow: {
                immediate: true,
                handler() {
                    this.resetManage();
                }
            }
        },
        methods: {
            resetManage() {
                this.loadingInfo = true;
                this.fetch({method: 'GET', url: '/workflowEditor/manageInfo/' + this.userInfo.entityID + '/' + this.selectedWorkflow.id})
                    .then((data) => {
                        this.workflowInfo = data;
                        this.loadingInfo = false;
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e);
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                        this.loadingInfo = false;
                    })
            },
        }
    }
</script>

<style scoped>

</style>
