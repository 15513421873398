<!--Base Version 2.0.2-->
<template>
  <v-app :dark="$store.state.darkTheme">
    <app-nav-bar v-if="showNav" v-show="!inPrint"></app-nav-bar>
    <v-content style="max-height: 100vh" v-show="!inPrint">
      <login
          @succesfullLogin="succesfullLogin($event)"
          v-if="!showNav"
      />
      <dashboard v-else/>
      <doc-repo-downloads style="z-index: 999"/>
    </v-content>
    <create-mail-print @print="inPrint = $event"/>
  </v-app>
</template>

<script>
import {eventBus} from './main';
import Navbar from './components/General/NavBar.vue'
import {codeBaseMixin} from './codeBaseMixin';
import Login from "./views/Login";
import Dashboard from "./views/Dashboard";
import DocRepoDownloads from "@/Document Repository/docRepoDownloads";
import CreateMailPrint from "@/Mail/Create Mail/CreateMailPrint";
import {mapActions, mapGetters} from "vuex";
import {initEmojiDb} from "@/components/General/EmojiPicker/frequent"
import {useCssVar, useDark} from "@vueuse/core";
import {dbGlobalMethods} from "@/localDBFunction";

let tokenMan = require('./tokenManSite');


export default {
  name: 'App',
  mixins: [codeBaseMixin],
  components: {
    CreateMailPrint,
    DocRepoDownloads,
    Dashboard,
    Login,
    appNavBar: Navbar
  },
  data() {
    return {
      console,
      testURL: `https://app.tasklogix.co.za/upload/download/3/356e762b1211ecc76af4bb7eb1bd1cfc/POLICY%20WORDING.pdf`,
      showNav: false,
      inPrint: false,
      
      colors: [
        {
          colorRef: useCssVar('--page-back', document.documentElement),
          light: '#f9f9f9',
          dark: '#212121'
        },
        {colorRef: useCssVar('--autofill-background-color', document.documentElement), light: '#fff', dark: '#2c2c2c'},
        {colorRef: useCssVar('--autofill-text-color', document.documentElement), light: '#000', dark: '#fff'},
        
        {
          colorRef: useCssVar('--card-back', document.documentElement),
          light: '#fff',
          dark: '#2c2c2c'
        },
        
        {
          colorRef: useCssVar('--card-back-accent', document.documentElement),
          light: '#d3d3d3',
          dark: '#3b3b3b'
        },
        
        {colorRef: useCssVar('--hover-item-background', document.documentElement), light: '#f9f9f9', dark: 'rgba(125,125,125,0.1)'},
        {
          colorRef: useCssVar('--selected-item', document.documentElement),
          light: 'rgba(125,125,125,0.1)',
          dark: 'rgba(255, 87, 34,0.2)'
        },
        {
          colorRef: useCssVar('--scroll-back', document.documentElement),
          light: 'rgba(125,125,125,0.1)',
          dark: '#2b2b2b'
        },
        {
          colorRef: useCssVar('--scroll-thumb', document.documentElement),
          light: 'rgba(125,125,125,0.2)',
          dark: 'rgba(125,125,125,0.2)'
        },
        {
          colorRef: useCssVar('--field-color-required', document.documentElement),
          light: 'rgba(244, 67, 54, 1)',
          dark: 'rgba(244, 67, 54, 1)',
        },
        {
          colorRef: useCssVar('--field-color-warning', document.documentElement),
          light: '#FF6F00',
          dark: 'rgba(255, 193, 7, 1)',
        },
        {
          colorRef: useCssVar('--field-color-success', document.documentElement),
          light: 'rgba(0,0,0, 1)',
          dark: 'rgba(255,255,255, 1)',
        },
        {
          colorRef: useCssVar('--task-number-color', document.documentElement),
          light: '#af1c1c',
          dark: '#fb2828',
        },
        
        {
          colorRef: useCssVar('--on-surface-grey', document.documentElement),
          light: '#8f8f8f',
          dark: 'rgba(255,255,255,35%)',
        },
        {
          colorRef: useCssVar('--on-surface', document.documentElement),
          light: 'rgba(0,0,0, 1)',
          dark: 'rgba(255,255,255, 1)',
        },
        {
          colorRef: useCssVar('--on-surface', document.documentElement),
          light: '#8f8f8f',
          dark: 'rgba(255,255,255, 1)',
        },
        {
          colorRef: useCssVar('--chat-bubble-right-background', document.documentElement),
          light: '#00695C',
          dark: '#23413e',
        },
        
      ]
    }
  },
  watch: {
    darkTheme: {
      immediate: true,
      handler(darkTheme) {
        console.log('DARK', darkTheme)
        if (darkTheme) {
          // Dark
          this.$vuetify.theme.primary = '#009688'
          this.$vuetify.theme["title-color"] = '#23413e'
          this.$vuetify.theme.secondary = '#FF5722'
          this.$vuetify.theme.greyType = '#a5a5a5'
          this.$vuetify.theme.appBar = '#2d2d2d'
          this.$vuetify.theme['surface-accent'] = '#4DB6AC'
        } else {
          // Light
          this.$vuetify.theme.primary = '#00695C'
          this.$vuetify.theme["title-color"] = '#00695C'
          this.$vuetify.theme.secondary = '#D84315'
          this.$vuetify.theme.greyType = '#757575'
          this.$vuetify.theme.appBar = '#f9f9f9'
          this.$vuetify.theme['surface-accent'] = '#2d2d2d'
        }
        
        for (const val of this.colors) {
          val.colorRef = darkTheme ? val.dark : val.light
        }
      }
    }
  },
  created() {
    
    // dbGlobalMethods.initialize()
    //     .then(() => {
    //       dbGlobalMethods.getConfigValue('theme')
    //           .then(theme => {
    //             console.log('THEME', theme)
    //           })
    //           .catch(e => {
    //             console.log('THEME', e)
    //           })
    //     })
    //     .catch(e => console.log(e))
    
    
    eventBus.$on('showHideNav', this.showHideNav);
    tokenMan.events.on('refreshToken', this.renewToken);
    window.addEventListener('focus', () => {
      this.$store.state.chat.windowFocused = true
    });
    window.addEventListener('blur', () => {
      this.$store.state.chat.windowFocused = false
    });
    // initEmojiDb().catch(e => {console.log(e)})
    
    
    
    
    
    
  },
  beforeDestroy() {
    eventBus.$off('showHideNav', this.showHideNav);
    tokenMan.events.off('refreshToken', this.renewToken)
  },
  methods: {
    
    showHideNav(value) {
      this.showNav = value;
      this.setLoggedIn(value)
    },
    
    ...mapActions(['setLoggedIn']),
    
    renewToken(retryCount = 0) {
      if (retryCount < 24) {
        this.fetch({
          method: 'PATCH',
          url: `/login`,
          body: JSON.stringify({
            token: tokenMan.token()
          })
        })
            .then((data) => {
              tokenMan.renewToken(data);
            })
            .catch((e) => {
              // eslint-disable-next-line
              console.log(e)
              this.showSnack('Error', `Token Renew Failed Attempt ${retryCount + 1}`, 'Close', 'red');
              setTimeout(() => {
                this.renewToken(++retryCount)
              }, retryCount * 1000)
            })
      } else {
        
        
        this.showNav = false;
      }
    },
    succesfullLogin(loginInfo) {
      if (loginInfo.gmailTokenCode != '') {
        
        const jBody = {
          code: loginInfo.gmailTokenCode,
          userKey: loginInfo.apiKey
        };
        
        this.fetch({method: 'POST', url: `/gmail/auth/newToken`, body: JSON.stringify(jBody)})
            .then((data) => {
              if (data.status == "Success") {
                eventBus.$emit('gmailAuthorized');
              }
            })
            .catch((e) => {
              // eslint-disable-next-line
              console.log(e)
              this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
            })
      }
      
      if (loginInfo.officeTokenCode != '') {
        const jBody = {
          code: loginInfo.officeTokenCode,
          userKey: loginInfo.apiKey
        };
        
        this.fetch({method: 'GET', url: `/office/auth/newToken`, body: JSON.stringify(jBody)})
            .then((data) => {
              if (data.status == "Success") {
                eventBus.$emit('officeAuthorized');
              }
            })
            .catch((e) => {
              // eslint-disable-next-line
              console.log(e)
              this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
            })
      }
    }
  },
  computed: {
    store() {
      return store
    },
    darkTheme() {
      return this.$store.state.darkTheme
    }
  }
}
</script>

<style>


</style>
