<template>
  
  <div class="fill-height c-d-flex">
    <div style="width: 50%" class="fill-height of-y">
      <div style="position: sticky; top: 0; z-index: 9" class="card-back">
        <div class="pa-2">
          <v-text-field
              solo
              hide-details
              prepend-inner-icon="search"
              placeholder="Search Fields"
              v-model="searchStr"
              :clearable="true"
              class="page-back"
          ></v-text-field>
        </div>
        
        <div class="px-2 pb-2">
          <v-btn
              block
              outline
              color="secondary"
              @click="newField"
          >
            <v-icon left>add</v-icon>
            Add Field
          </v-btn>
        </div>
        
        
        
        <div>
          <v-divider></v-divider>
        </div>
      </div>
      <v-list>
        
        <draggable v-model="editFieldRelay"
                   group="Quick Steps"
                   ghostClass="ghost"
        >
          <v-list-tile
              v-for="field in editFieldRelay"
              :key="field.id"
              color="primary"
              :style="selectedField === field.id ? 'background: #00695C20' : null"
              :value="selectedField === field.id"
              @click="selectedField === field.id ? selectedField = null : selectedField = field.id"
          >
            <v-list-tile-content>
              <v-list-tile-title>{{ field.description }}</v-list-tile-title>
              <v-list-tile-sub-title></v-list-tile-sub-title>
            </v-list-tile-content>
          </v-list-tile>
        </draggable>
        
       
      </v-list>
    </div>
    
    <div style="width: 50%" class="fill-height of-y" >
      <app-contacts-custom-field-config
          :selected-field="selectedFieldObject"
          :select-lists="selectLists"
          v-if="selectedFieldObject"
          @field-added="customFieldAdded"
          @field-edited="customFieldEdited"
      />
    </div>
  </div>

</template>

<script>

import {arrSplice} from "@/codeFunctions";
import AppContactsCustomFieldConfig from "@/contacts/AppContactsCustomFieldConfig.vue";
import draggable from 'vuedraggable';

const blankField = {
  id: null,
  description: null,
  visible: 1,
  type: 0,
  lookup: null,
  order: null
}

export default {
  components: {AppContactsCustomFieldConfig, draggable},
  props: {
    selectLists: {
      type: Array,
      default: () => []
    },
    customFields: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      selectedField: null,
      editFields: [],
      searchStr: null
    }
  },
  watch: {
    customFields: {
      immediate: true,
      handler(val) {
        this.editFields = JSON.parse(JSON.stringify(this.customFields))
      }
    }
    
  },
  methods: {
    
    customFieldEdited(field) {
      this.selectedField = null
      arrSplice(this.customFields, val => val.id === field.id)
      this.customFields.push(field)
      this.selectedField = field.id
    },
    
    customFieldAdded(field) {
      this.selectedField = null
      arrSplice(this.customFields, val => {
        console.log(val)
        return !val.id
      })
      this.customFields.push(field)
      this.selectedField = field.id
    },
    
    
    async checkChanges() {
      return true
    },
    
    async newField() {
      
      await this.checkChanges();
      
      this.editFields = this.editFields.filter(val => val.id !== null)
      
      this.editFields.push({
        ...JSON.parse(JSON.stringify(blankField)),
        order: this.editFields.length + 1,
        description: 'New Field'
      })
      this.selectedField = null
    },
    
    async saveOrder() {
      
      try {
        this.savingChanges = true
        await this.$newReq('POST', `contacts/setup/customField/order`, this.editFields.map(val => ({id: val.id, order: val.order, desc: val.description})))
        
        this.editFields.forEach(editField => {
          const customField = this.customFields.find(field => field.id === editField.id)
          if (customField)
            customField.order = editField.order
        })
        
        this.$snack.info('Changes saved')
        
      } catch (e) {
        console.log(e)
        this.$snack.networkError()
      } finally {
        this.savingChanges = false
      }
      
    },
  },
  computed: {
    
    selectedFieldObject() {
      return this.editFields.find(val => val.id === this.selectedField)
    },
    
    editFieldRelay: {
      get() {
        return [...this.editFields]
            .sort((a,b) => a.order - b.order)
            .filter(val => this.searchStr ? (val.description || '').toLowerCase().includes((this.searchStr || '').toLowerCase()) : true)
      },
      set(val) {
        val.forEach((field, index) => {
          field.order = index
        })
        this.saveOrder();
      }
    }
    
    
    
  },
}
</script>



<style scoped>
  .selected-tile-background {
    background-color: var(--v-primary-base);
    border: 1px solid fuchsia;
    color: red;
    
  }
  .ghost {
    opacity: 0.5;
    background: #EEEEEE;
  }
</style>