<template>
    <div class="pa-2 pl-3 hover-item">
        <v-layout row align-center >
            <v-flex xs12>
                <v-layout row wrap align-center>
                    <v-flex shrink class="caption font-weight-bold secondary--text pr-2">{{`${fieldDescription} =`}}</v-flex>
                    <template v-if="editFields.includes(actionField.field)">
                        <v-flex

                                shrink
                                v-for="(action, ai) in fieldActions"
                                :key="`{actionSection:${ai}`"
                                @dragover="$event.preventDefault()"
                                @drop="dropOperator($event, ai)"
                                class="pr-1"
                        >
                            <v-btn v-if="action.type == 'placeholder'" icon small class="pa-0 ma-0 teal lighten-5"></v-btn>
                            <v-chip v-if="action.type == 'operator'" @input="removeItem(ai)" close small class="pa-0 ma-0 teal lighten-3">{{action.operation}}</v-chip>
                            <v-chip v-if="action.type == 'd'" @input="removeItem(ai)" close small class="pa-0 ma-0 teal lighten-3">{{ action.value + ' Days' }}</v-chip>
                            <v-chip v-if="action.type == 'w'" @input="removeItem(ai)" close small class="pa-0 ma-0 teal lighten-3">{{ action.value + ' Weeks' }}</v-chip>
                            <v-chip v-if="action.type == 'm'" @input="removeItem(ai)" close small class="pa-0 ma-0 teal lighten-3">{{ action.value + ' Months' }}</v-chip>
                            <v-chip v-if="action.type == 'y'" @input="removeItem(ai)" close small class="pa-0 ma-0 teal lighten-3">{{ action.value + ' Years' }}</v-chip>
                            <v-chip v-if="action.type == 'h'" @input="removeItem(ai)" close small class="pa-0 ma-0 teal lighten-3">{{ action.value + ' Hours' }}</v-chip>
                            <v-chip v-if="action.type == 'mi'" @input="removeItem(ai)" close small class="pa-0 ma-0 teal lighten-3">{{ action.value + ' Minutes' }}</v-chip>
                            <v-chip v-if="action.type == 's'" @input="removeItem(ai)" close small class="pa-0 ma-0 teal lighten-3">{{ action.value + ' Seconds' }}</v-chip>
                            <v-chip v-if="action.type == 'now'" @input="removeItem(ai)" close small class="pa-0 ma-0 teal lighten-3">NOW</v-chip>
                            <v-chip v-if="action.type == 'value'" @input="removeItem(ai)" close small class="pa-0 ma-0 teal lighten-3">{{ `'${action.value}'` }}</v-chip>
                            <v-chip v-if="action.type == 'equal'" @input="removeItem(ai)" close small class="pa-0 ma-0 teal lighten-3">{{ `=` }}</v-chip>
                            <v-chip v-if="action.type == 'field'" @input="removeItem(ai)" close small class="pa-0 ma-0 teal lighten-3">{{ flatFields[action.value] }}</v-chip>
                        </v-flex>
                    </template>

                    <template v-else>
                        <v-flex

                                shrink
                                v-for="(action, ai) in fieldActions"
                                :key="`{actionSection:${ai}`"
                                class="pr-1"
                                v-show="action.type != 'placeholder'"
                        >
                            <span class="primary--text caption font-weight-regular" v-if="action.type == 'operator'">{{action.operation}}</span>
                            <span class="primary--text caption font-weight-regular" v-if="action.type == 'd'" >{{ action.value + ' Days' }}</span>
                            <span class="primary--text caption font-weight-regular" v-if="action.type == 'w'" >{{ action.value + ' Weeks' }}</span>
                            <span class="primary--text caption font-weight-regular" v-if="action.type == 'm'" >{{ action.value + ' Months' }}</span>
                            <span class="primary--text caption font-weight-regular" v-if="action.type == 'y'" >{{ action.value + ' Years' }}</span>
                            <span class="primary--text caption font-weight-regular" v-if="action.type == 'h'" >{{ action.value + ' Hours' }}</span>
                            <span class="primary--text caption font-weight-regular" v-if="action.type == 'mi'" >{{ action.value + ' Minutes' }}</span>
                            <span class="primary--text caption font-weight-regular" v-if="action.type == 's'" >{{ action.value + ' Seconds' }}</span>
                            <span class="primary--text caption font-weight-regular" v-if="action.type == 'now'" >NOW</span>
                            <span class="primary--text caption font-weight-regular" v-if="action.type == 'value'" >{{ `'${action.value}'` }}</span>
                            <span class="primary--text caption font-weight-regular" v-if="action.type == 'equal'" >{{ `=` }}</span>
                            <span class="primary--text caption font-weight-regular" v-if="action.type == 'field'" >{{ flatFields[action.value] }}</span>
                        </v-flex>
                    </template>
                </v-layout>
            </v-flex>

            <v-flex shrink class="pr-2">
                <v-tooltip top>
                    <v-btn
                            small
                            icon
                            flat
                            color="red"
                            class="pa-0 ma-0"
                            @click="$emit('removeField', actionField.field)"
                            slot="activator"
                    >
                        <v-icon small>delete</v-icon>
                    </v-btn>
                    <span>Remove Action Field</span>
                </v-tooltip>
            </v-flex>

            <v-flex shrink class="pr-2" v-if="!editFields.includes(actionField.field)">
                <v-tooltip top>
                    <v-btn
                            small
                            icon
                            flat
                            color="primary"
                            class="pa-0 ma-0"
                            @click="$emit('editField', actionField.field)"
                            slot="activator"
                    >
                        <v-icon small>edit</v-icon>
                    </v-btn>
                    <span>Edit Action Field</span>
                </v-tooltip>
            </v-flex>
        </v-layout>


<!--Add Value-->
        <app-input-dlg
                :show-dialog="showAddVal"
                :title="addVal.title"
                :body="addVal.body"
                :cancel-button="true"
                :show-input-box="true"
                :buttons="['Add']"
                :call-backs="[ addValue ]"
                @dismiss="showAddVal = false"
                default-input="0"
        />
<!--Add Field-->
        <v-dialog v-model="showAddField" width="400px" persistent>
            <v-card flat>
                <v-card-title class="pa-1 pl-2">
                    <span class="subheading font-weight-light primary--text">Add Field</span>
                    <v-spacer></v-spacer>
                    <v-btn
                            small
                            icon
                            flat
                            color="primary"
                            class="pa-0 ma-0"
                            @click="showAddField = false"
                    >
                        <v-icon small>close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <span class="body-1">Please Select a Field to Add</span>
                    <v-autocomplete
                            color="primary"
                            label="Select Field"
                            :items="fields"
                            item-text="description"
                            return-object
                            flat
                            v-model="selectedAddField"
                            hide-details
                            class="pa-0 ma-0 pt-2"
                            dense
                            single-line
                    >
                    </v-autocomplete>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                            flat
                            color="secondary"
                            dark
                            :disabled="selectedAddField == null"
                            @click="addField"
                    >
                        select Field
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import {codeBaseMixin} from "../../../codeBaseMixin";
    import InputDlg from "../../../components/General/InputDlg";

    export default {
        components: {
            appInputDlg: InputDlg
        },
        mixins: [codeBaseMixin],
        props: {
            actionField: Object,
            fields: Array,
            userInfo: Object,
            editFields: Array,
        },
        data() {
            return {
                addVal: {
                    operation: '',
                    title: '',
                    body: '',
                    position: null
                },
                showAddVal: false,
                showAddField: false,
                selectedAddField: null
            }
        },
        methods: {
            removeItem(actionIndex) {
                let tArr = this.fieldActions.filter(() => true);

                tArr.splice(actionIndex,1);

                this.fieldActions = tArr;
            },
            addValue(value) {
                if (this.addVal.operator != `value`) {
                    let reg = /^-?\d+\.?\d*$/;

                    if (value != null && value != '' && reg.test(value)) {
                        this.showAddVal = false;
                        let tArr = this.fieldActions.filter(() => true);
                        tArr.splice(this.addVal.position,1,{
                            type: this.addVal.operator,
                            value: value
                        });
                        this.fieldActions = tArr;

                    } else {
                        this.showSnack('Error', 'Invalid Value Specified', 'Close', 'red')
                    }
                } else {
                    if (value != null && value != '') {
                        let tArr = this.fieldActions.filter(() => true);
                        tArr.splice(this.addVal.position,1,{
                            type: this.addVal.operator,
                            value: value
                        });
                        this.fieldActions = tArr;

                        this.showAddVal = false;
                        this.addVal = {
                                operation: '',
                                title: '',
                                body: '',
                                position: {}
                            }
                    } else {
                        this.showSnack('Error', 'Invalid Value Specified', 'Close', 'red')
                    }
                }

            },
            addField() {

                let tArr = this.fieldActions.filter(() => true);
                tArr.splice(
                    this.addVal.position,
                    1,
                    {
                        type: `field`,
                        value: this.selectedAddField.id,
                        fieldType: this.selectedAddField.type
                    });
                this.fieldActions = tArr;

                this.showAddField = false;
                this.selectedAddField = null;
            },
            dropOperator(event, actionIndex) {
                let data = JSON.parse(event.dataTransfer.getData("text"));
                if ([`d`,`w`,`m`,`y`,`h`,`mi`,`s`,`value`,`field`].includes(data.operation)) {
                    switch (data.operation) {
                        case 'd': {
                            this.addVal.title = 'Add Days Value';
                            this.addVal.body = 'Please Specify The Amount Of Days You Would Like To Add';
                            break;
                        }
                        case 'w': {
                            this.addVal.title = 'Add Weeks Value';
                            this.addVal.body = 'Please Specify The Amount Of Weeks You Would Like To Add';
                            break;
                        }
                        case 'm': {
                            this.addVal.title = 'Add Months Value';
                            this.addVal.body = 'Please Specify The Amount Of Months You Would Like To Add';
                            break;
                        }
                        case 'y': {
                            this.addVal.title = 'Add Years Value';
                            this.addVal.body = 'Please Specify The Amount Of Years You Would Like To Add';
                            break;
                        }
                        case 'h': {
                            this.addVal.title = 'Add Hours Value';
                            this.addVal.body = 'Please Specify The Amount Of Hours You Would Like To Add';
                            break;
                        }
                        case 'mi': {
                            this.addVal.title = 'Add Minutes Value';
                            this.addVal.body = 'Please Specify The Amount Of Minutes You Would Like To Add';
                            break;
                        }
                        case 's': {
                            this.addVal.title = 'Add Seconds Value';
                            this.addVal.body = 'Please Specify The Amount Of Seconds You Would Like To Add';
                            break;
                        }
                        case 'value': {
                            this.addVal.title = 'Add Value';
                            this.addVal.body = 'Please Specify The Value You Would Like To Add';
                            break;
                        }
                    }

                    this.addVal.operator = data.operation;
                    this.addVal.position = actionIndex;

                    if (data.operation != 'field') {
                        this.showAddVal = true;
                    } else {
                        this.showAddField = true;
                    }

                } else {
                    let tArr = this.fieldActions.filter(() => true);

                    tArr.splice(actionIndex,1,data);

                    this.fieldActions = tArr;
                }
            },
        },
        computed: {
            flatFields() {
                return this.fields.reduce((obj, field) => {
                    obj[field.id] = field.description;
                    return obj
                }, {})
            },
            fieldActions: {
                get() {
                    return [{type: 'placeholder'}].concat(this.actionField.actions.reduce((list, item) => {
                        list.push(item);
                        list.push({type: 'placeholder'});
                        return list
                    }, []))
                },
                set(newValue) {
                    this.$emit('updateActions', newValue.filter(obj => obj.type != 'placeholder'))
                }
            },

            fieldDescription() {
                try {
                    return this.flatFields[this.actionField.field];
                } catch (e) {
                    // eslint-disable-next-line
                    console.log(e);
                    return 'No Field Description';
                }
            }
        }
    }
</script>

<style scoped>

</style>
