<template>
    <v-card
        style="overflow-x: hidden; cursor: pointer; overflow-y: hidden"
        class="hover-item"
        :elevation="3"
    >
        <v-layout row align-center>
            <v-flex class="pl-2">
                <v-btn
                    :loading="loading"
                    icon
                    flat
                    small
                    color="primary"
                    class="pa-0 ma-0"
                >
                    <v-icon>{{share.type === 'user' ? 'person' : 'people'}}</v-icon>
                </v-btn>
            </v-flex>
            <v-flex xs12 class="text-truncate py-2 px-2">
                <div class="text-truncate">
                    {{share.description}}
                </div>
            </v-flex>
            <v-flex shrink class="pr-2">
                <v-tooltip top>
                    <v-btn
                        slot="activator"
                        icon
                        flat
                        small
                        color="red"
                        class="pa-0 ma-0"
                        @click="$emit('remove')"
                        :disabled="loading"
                    >
                        <v-icon>delete</v-icon>
                    </v-btn>
                    Remove Share
                </v-tooltip>
            </v-flex>
        </v-layout>
    </v-card>
</template>

<script>
export default {
    props: {
        share: Object,
    },
    data() {
        return {
            loading: false
        }
    },
}
</script>

<style scoped>

</style>