<template>
    <v-layout row>
        <v-flex xs12 class="text-xs-center px-2 pb-2" v-if="loadingSourceTask">
            <v-progress-circular
                    :size="40"
                    :width="5"
                    color="primary"
                    indeterminate
            ></v-progress-circular>
        </v-flex>
        <v-flex v-else xs12>
            <div
                    style="width: 100%; overflow-y: auto;"
                    class="px-2 pb-2"
            >
                <v-card>
                    <!--Step Title-->
                    <v-card-title class="py-0 pr-2 primary">

                        <v-layout row align-center>
                            <v-flex xs12 class=py-2>
                                <v-layout row align-center>
                                    <v-flex shrink>
                                        <v-icon class="white--text pr-2">
                                            assignment_turned_in
                                        </v-icon>
                                    </v-flex>
                                    <v-flex xs12>
                                        <span class="title font-weight-light white--text">{{ `Task ${splitTask.fk_source_task}` }}</span>
                                    </v-flex>
                                </v-layout>
                            </v-flex>
                            <v-flex shrink v-if="saveFields.length > 0">
                                <v-tooltip top>
                                    <v-btn
                                            ripple
                                            class="pa-0 ma-0"
                                            color="white"
                                            icon
                                            flat
                                            slot="activator"
                                            style="cursor: pointer"
                                            :loading="savingValues"
                                            :disabled="savingValues"
                                            @click="saveChanges"
                                    >
                                        <v-icon>save</v-icon>
                                    </v-btn>
                                    <span>Save Changes</span>
                                </v-tooltip>
                            </v-flex>
                        </v-layout>
                    </v-card-title>

<!--Fields-->

                    <template v-for="field in sourceFields">
                        <app-task-field
                                v-if="field.ruleShow"
                                :field="field"
                                :user-info="userInfo"
                                :task="{taskID: splitTask.fk_source_task, structure: sourceTask}"
                                :selected-step="{description: '', stepID: field.stepID}"
                                :no-drag="true"
                                :srcTaskAttachment="true"
                                :mail-config="mailConfig"
                                :binder="binder"
                                :local-data="localData"
                        />
                        <!--<span class="caption">{{ field.description + ' - ' + field.editable }}</span>-->
                        <!--<v-divider></v-divider>-->
                    </template>

                </v-card>
            </div>
        </v-flex>
    </v-layout>
</template>

<script>

    import {eventBus} from "../../../../main";
    import {codeBaseMixin} from "../../../../codeBaseMixin";
    import TaskField from '../TaskViewDetail/Components/TaskField'
    import {DatasetBinder} from "@/Tasks/TaskView V2/components/TaskViewDetail/datasetBinder";

    export default {
        mixins: [codeBaseMixin],
        components: {
            appTaskField: TaskField
        },
        props: {
            userInfo: Object,
            splitTask: Object,
            mailConfig: Object,
            localData: Object,
        },
        mounted() {
            this.loadSourceTask()
        },
        created() {
            eventBus.$on('mailTemplateSaveSent', this.mailTemplateSaveSent)
        },
        beforeDestroy() {
            eventBus.$off('mailTemplateSaveSent', this.mailTemplateSaveSent)
        },
        data() {
            return {
                loadingSourceTask: false,
                sourceTask: null,
                savingValues: false,
                binder: null,
            }
        },
        methods: {

            mailTemplateSaveSent() {
                if (this.saveFields.length > 0) {
                    this.saveChanges();
                }
            },

            loadSourceTask() {
                this.sourceTask = null;
                this.loadingSourceTask = true;
                this.fetch({method: 'GET', url: `/workflows/getWorkflowTemplateSrcTask/${this.userInfo.entityID}/${this.userInfo.userID}/${this.splitTask.fk_source_task}`})
                    .then((data) => {
                        this.sourceTask = data;
                        this.binder = new DatasetBinder(this.localData, {structure: this.sourceTask}, this);
                        this.loadingSourceTask = false;
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e)
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                        this.loadingSourceTask = false;
                    })
            },
            saveChanges() {

                let lastStep = null;

                let loopFields = this.sourceTask.fields.map(fieldObj => {
                    return {
                        completed: fieldObj.value != null && fieldObj.value != '',
                        required: fieldObj.required,
                        stepID: fieldObj.stepID,
                        ruleShow: fieldObj.ruleShow,
                        fieldOrder: fieldObj.field_order,
                        stepOrder: fieldObj.stepOrder
                    }
                })
                    .filter(obj => obj.required && obj.ruleShow)
                    .sort((a, b) => a['fieldOrder'] < b['fieldOrder'] ? -1 : 1)
                    .sort((a, b) => a['stepOrder'] < b['stepOrder'] ? -1 : 1);


                loopFields.forEach((field, i) => {
                    if (lastStep == null) {
                        if (field.completed == false) {
                            lastStep = field.stepID
                        }
                    }
                });

                if (lastStep == null) {
                    loopFields = this.sourceTask.fields.map(fieldObj => {
                        return {
                            completed: fieldObj.value != null && fieldObj.value != '',
                            required: fieldObj.required,
                            stepID: fieldObj.stepID,
                            ruleShow: fieldObj.ruleShow,
                            fieldOrder: fieldObj.field_order,
                            stepOrder: fieldObj.stepOrder
                        }
                    }).sort((a, b) => a['fieldOrder'] < b['fieldOrder'] ? -1 : 1).sort((a, b) => a['stepOrder'] < b['stepOrder'] ? -1 : 1);
                    lastStep = loopFields[loopFields.length - 1].stepID;
                }

                this.savingValues = true;


                this.fetch({
                    method: 'POST',
                    url: `/tasks/saveFields/${this.userInfo.entityID}/${this.userInfo.userID}/${this.splitTask.fk_source_task}/${lastStep}/${(this.sourceTask.status_percentage != null ? this.sourceTask.status_percentage : 0)}`,
                    body: JSON.stringify({fields: this.saveFields, map: null})
                })
                    .then((data) => {

                        this.sourceTask.fields.forEach((taskField, i) => {
                            taskField.saveStatus = 0;
                            taskField.saveStatusText = null;
                        });

                        data.savedFields.forEach(saveValue => {

                            if (saveValue.fieldType == 7 && saveValue.value_type == 1) {
                                this.sourceTask.mails.forEach(mail => {
                                    if (mail.mailDBID == saveValue.value) {
                                        mail.securityLookup = saveValue.lookup
                                    }
                                })
                            }

                            if (saveValue.fieldType == 7 && saveValue.value_type == 2) {
                                this.sourceTask.documents.forEach(document => {
                                    if (document.documentDBID == saveValue.value) {
                                        document.securityLookup = saveValue.lookup
                                    }
                                })
                            }

                            this.sourceTask.fields.forEach((taskField, i) => {
                                if (saveValue.fieldID == taskField.fieldID) {
                                    this.sourceTask.fields.splice(i, 1, saveValue)
                                }
                            })
                        });

                        eventBus.$emit('ChangesSaved');

                        let tMeta = data.meta;
                        tMeta.status_percentage = this.sourceTask.status_percentage;
                        eventBus.$emit('updateTaskMeta', tMeta);

                        this.savingValues = false;
                        this.showSnack('Info', 'Changes Saved', 'Close', 'primary')

                        // Auto Switch
                        // this.selectedStep.stepID = data.meta.curStepID;
                        // this.selectedStep.description = data.meta.curStepName;
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e);
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                        this.savingValues = false
                    })
            },
            recalcTaskPercentage() {
                let iTotal = 0;
                let iComplete = 0;

                this.sourceTask.fields.forEach(field => {
                    if (field.ruleShow == true) {
                        iTotal = ++iTotal;
                        if (field.value != null && field.value != '') {
                            iComplete = ++iComplete
                        }
                    }
                });
                this.sourceTask.status_percentage = Math.round((iComplete / iTotal) * 100)
            },
        },
        computed: {
            allShowFieldIDs() {
                let allshow = [];
                if (this.sourceTask != null) {
                    this.sourceTask.rules.forEach(rule => {
                        if (rule.showFields != null && rule.showFields != '') {
                            try {
                                allshow = allshow.concat(JSON.parse(rule.showFields))
                            } catch (e) {
                                // eslint-disable-next-line
                                console.log(e)
                            }
                        }
                    });
                }
                return allshow;
            },
            appliedRulesOnFields() {
                if (this.sourceTask != null) {
                    // Build A List Of All Show Fields
                    this.sourceTask.fields.forEach(field => {
                        field.ruleShow = !this.allShowFieldIDs.includes(field.fieldID)
                    });

                    // Loop Through All Rules
                    this.sourceTask.rules.forEach(rule => {
                        if (rule.conditions != null && rule.conditions != '') {

                            let bRuleMet = true;

// Loop Through All Conditions
                            JSON.parse(rule.conditions).forEach(condition => {
                                // Get The Referenced Value
                                let rValue = null;
                                this.sourceTask.fields.forEach((field, i) => {
                                    if (field.fieldID == condition.fk_field) {
                                        rValue = field.value;
                                    }
                                });

                                // Use The Operation ID to Determine bRuleMet
                                switch (condition.operationID) {
                                    case 0: {
                                        if (rValue != condition.value) {
                                            bRuleMet = false
                                        }
                                        break;
                                    }
                                    case 1: {
                                        if (rValue == condition.value) {
                                            bRuleMet = false
                                        }
                                        break;
                                    }
                                    case 2: {
                                        if (rValue == null || rValue == '') {
                                            bRuleMet = false
                                        }
                                        break;
                                    }
                                    case 5: {
                                        if (rValue != null && rValue != '') {
                                            try {
                                                if (new Date(rValue) < new Date(condition.value)) {
                                                    bRuleMet = false
                                                }
                                            } catch (e) {
                                                // eslint-disable-next-line
                                                console.log(e)
                                                bRuleMet = false
                                            }
                                        } else {
                                            bRuleMet = false
                                        }
                                        break;
                                    }
                                    case 6: {
                                        if (rValue != null && rValue != '') {
                                            try {
                                                if (new Date(rValue) < new Date(condition.value)) {
                                                    bRuleMet = false
                                                }
                                            } catch (e) {
                                                // eslint-disable-next-line
                                                console.log(e)
                                                bRuleMet = false
                                            }
                                        } else {
                                            bRuleMet = false
                                        }
                                        break;
                                    }
                                    case 3: {
                                        if (rValue != null && rValue != '') {
                                            try {
                                                if (parseFloat(rValue) < parseFloat(condition.value)) {
                                                    bRuleMet = false
                                                }
                                            } catch (e) {
                                                // eslint-disable-next-line
                                                console.log(e);
                                                bRuleMet = false
                                            }
                                        } else {
                                            bRuleMet = false
                                        }
                                        break;
                                    }
                                    case 4: {
                                        if (rValue != null && rValue != '') {
                                            try {
                                                if (parseFloat(rValue) > parseFloat(condition.value)) {
                                                    bRuleMet = false
                                                }
                                            } catch (e) {
                                                // eslint-disable-next-line
                                                console.log(e);
                                                bRuleMet = false
                                            }
                                        } else {
                                            bRuleMet = false
                                        }
                                        break;
                                    }
                                }
                            });

                            // Check If The Rule Was Met and update to show the appropriate fields
                            if (bRuleMet) {
                                if (rule.showFields != null && rule.showFields != '')
                                    this.sourceTask.fields.forEach((field) => {
                                        if (JSON.parse(rule.showFields).includes(field.fieldID)) {
                                            field.ruleShow = true;
                                        }
                                    });
                            }
                        }
                    });

                    this.recalcTaskPercentage();

                    return this.sourceTask.fields;
                } else {
                    return []
                }
            },
            sourceFields() {
                if (this.sourceTask != null) {

                    let availableFields = JSON.parse(this.splitTask.structure.sourceTaskFields);
                    let avalableIDs = availableFields.map(obj => {
                        return obj.id
                    });
                    let readOnlyIDs = availableFields.filter(obj => obj.readOnly).map(obj => {return obj.id});
                    let fields = this.appliedRulesOnFields.filter(obj => avalableIDs.includes(obj.fieldID)).map(field => {
                        let tField = field;
                        tField.editable = this.boolToInt(!readOnlyIDs.includes(tField.fieldID))
                        if ([6,7].includes(tField.fieldType)) {
                            tField.editable = 0
                        }
                        return tField
                    });
                    // this.appliedRulesOnFields.forEach(field => {
                    //      if (avalableIDs.inc == this.selectedStep.stepID && field.shown) {
                    //         fields.push(field)
                    //      }
                    // });

                    fields.sort((a, b) => a['field_order'] < b['field_order'] ? -1 : 1);
                    fields.sort((a, b) => a['stepOrder'] < b['stepOrder'] ? -1 : 1);

                    return fields
                } else {
                    return []
                }
            },
            saveFields() {

                let fields = [];
                if (this.sourceFields.length > 0) {
                    this.sourceFields.forEach(field => {
                        let bAddSaveField = false;
                        // Start Field Check


                        if (field.value != null && field.value != '') {
                            if (field.type != 7) {
                                bAddSaveField = field.value != field.oldValue
                            }
                        }

                        if (field.fieldType == 12 && field.value == 0 && field.oldValue != 0) {
                            bAddSaveField = true
                        }

                        // End Field Check
                        if (bAddSaveField) {fields.push(field)}
                    })
                }

                let tPassFields = [];


                fields.forEach(field => {
                    switch (field.fieldType) {
                        case 7: {
                            tPassFields.push({
                                fieldID: field.fieldID,
                                valueID: field.valueID,
                                value: field.value,
                                value_type: field.value_type,
                                linkLookup: field.linkLookup,
                                fieldType: field.fieldType,
                                lookup: field.lookup
                            });
                            break;
                        }
                        case 9: {
                            tPassFields.unshift({
                                fieldID: field.fieldID,
                                valueID: field.valueID,
                                value: field.value,
                                value_type: field.value_type,
                                linkLookup: field.linkLookup,
                                fieldType: field.fieldType,
                                lookup: field.lookup
                            });
                            break;
                        }
                        case 11: {
                            tPassFields.push({
                                fieldID: field.fieldID,
                                valueID: field.valueID,
                                value: field.value,
                                value_type: field.value_type,
                                linkLookup: field.linkLookup,
                                fieldType: field.fieldType,
                                lookup: field.lookup
                            });
                            break;
                        }
                        case 12: {
                            tPassFields.push({
                                fieldID: field.fieldID,
                                valueID: field.valueID,
                                value: field.value,
                                value_type: field.value_type,
                                linkLookup: field.linkLookup,
                                fieldType: field.fieldType,
                                lookup: field.lookup
                            });
                            break;
                        }
                        default: {
                            tPassFields.push({
                                fieldID: field.fieldID,
                                valueID: field.valueID,
                                value: field.value,
                                value_type: field.value_type,
                                linkLookup: field.linkLookup,
                                fieldType: field.fieldType,
                                lookup: null
                            });
                            break;
                        }
                    }
                });

                return tPassFields
            },

        }
    }
</script>

<style scoped>

</style>
