

const EventEmitter      = require('events');
let userInfo            = null;
let dbEmitter           = new EventEmitter();
let db                  = null;
let dbGlobal                  = null;

function addEventHandlers() {
    db.onerror = function(event) {
        dbEmitter.emit('dbError', event.target.errorCode);
    };
}


let folderExample = [
    {
        "id": 5,
        "description": "Georgi",
        "folderGroups": "[1]",
        "lastEdited": null,
        "searchStr": "Employees [\"10001\", \"Facello\", \"1953-09-02\", \"Georgi\", \"M\", \"1986-06-26\"] "
    }
]

let dbMethods = {

    resetDB() {
        return new Promise ((resolve, reject) => {
            var transaction = db.transaction(["config"], "readwrite");

            transaction.oncomplete = function(event) {
                // Start
                var clearTrans = db.transaction(["folders"], "readwrite");
                clearTrans.oncomplete = function(event) {resolve(true)};
                clearTrans.onerror = function(event) {reject(event.target.error)};
                let folderStore = clearTrans.objectStore("folders");
                folderStore.clear();
                // End
            };
            transaction.onerror = function(event) {
                reject(event.target.error)
            };

            let objectStore = transaction.objectStore("config");
            objectStore.put({id: 1, val: null});
        })
    },

    initialize(usrInf) {
        userInfo = usrInf;
        let request = window.indexedDB.open(`TL-${userInfo.entityID}`, 1);
        request.onerror = function (event) {
            console.log('onerror', event);
            dbEmitter.emit('dbReady', false)
        };
        request.onsuccess = function (event) {
            // Do something with request.result!
            db = event.target.result;
            addEventHandlers();
            dbEmitter.emit('dbReady', true);
        };
        request.onupgradeneeded = function(event) {
            db = event.target.result;
            db.createObjectStore("folders", { keyPath: "id" });
            db.createObjectStore("config", { keyPath: "id" });
        };
    },


    addConfigValue(id, val) {
        return new Promise ((resolve, reject) => {
            let transaction = db.transaction(["config"], "readwrite");
            transaction.oncomplete = function() {resolve(true)};
            transaction.onerror = function(event) {reject(event.target.error)};
            let objectStore = transaction.objectStore("config");
            objectStore.put({id: id, val: val});
        })
    },

    getConfigValue(id) {
        return new Promise ((resolve, reject) => {
            let transaction = db.transaction(["config"], "readonly");
            let objectStore = transaction.objectStore("config");
            let request = objectStore.get(id);
            request.onerror = function(event) {
                reject(event.target.error)
            };
            request.onsuccess = function() {
                resolve(request.result)
            };
        })
    },

    getFolders() {
        return new Promise ((resolve, reject) => {
            let transaction = db.transaction(["folders"], "readonly");
            let objectStore = transaction.objectStore("folders");
            let request = objectStore.getAll();
            request.onerror = function(event) {
                reject(event.target.error)
            };
            request.onsuccess = function() {
                resolve(request.result)
            };
        })
    },

    addFolders(folders) {
        return new Promise ((resolve, reject) => {

            if (folders.length > 0) {
                let transaction = db.transaction(["folders"], "readwrite");
                transaction.oncomplete = function(event) {
                    resolve(true)
                };
                transaction.onerror = function(event) {
                    reject(event.target.error)
                };

                let objectStore = transaction.objectStore("folders");

                folders.forEach(folder => {
                    objectStore.put(folder);
                })
            } else {
                resolve(true)
            }
        })
    },


};

let dbGlobalMethods = {

    initialize() {
        return new Promise((resolve, reject) => {
            let request = window.indexedDB.open(`TL-config`, 1);
            request.onerror = function (event) {
                reject(event)
            };
            request.onsuccess = function (event) {
                // Do something with request.result!
                dbGlobal = event.target.result
                resolve(dbGlobal)
            };
            request.onupgradeneeded = function(event) {
                dbGlobal = event.target.result
                dbGlobal.createObjectStore("config", { keyPath: "id" });
            };
        })
    },


    addConfigValue(id, val) {
        return new Promise ((resolve, reject) => {
            let transaction = dbGlobal.transaction(["config"], "readwrite");
            transaction.oncomplete = function() {resolve(true)};
            transaction.onerror = function(event) {reject(event.target.error)};
            let objectStore = transaction.objectStore("config");
            objectStore.put({id: id, val: val});
        })
    },

    getConfigValue(id) {
        return new Promise ((resolve, reject) => {
            let transaction = dbGlobal.transaction(["config"], "readonly");
            let objectStore = transaction.objectStore("config");
            let request = objectStore.get(id);
            request.onerror = function(event) {
                reject(event.target.error)
            };
            request.onsuccess = function() {
                resolve(request.result)
            };
        })
    },
}


export {
    dbMethods,
  dbGlobalMethods,
    dbEmitter
}
