<template>
        <div style="background: rgba(0,0,0,0.5)" class="scrollable fill-height">
            <v-card class="fill-height scrollable">
                <v-layout row fill-height class="scrollable">
                    <v-flex xs9 class="scrollable fill-height text-xs-center">
                        <v-layout row fill-height justify-space-around class="scrollable grey lighten-3">
                            <v-flex shrink class="fill-height overflow-x-hidden overflow-y-hidden">
                                <editor
                                        style="width: 21cm"
                                        class="white"
                                        :extra-config="editorConfig"
                                        v-model="editHtml"
                                        @input="$emit('setHtml', editHtml.replace(/\n/g, ''))"
                                />
                            </v-flex>
                        </v-layout>

                    </v-flex>

                    <v-flex xs3 class="scrollable fill-height">

                        <v-layout column fill-height class="scrollable">
                            <v-flex shrink class="white--text primary py-2 px-3 body-1">
                                Add Fields To Section (drop to add field)
                            </v-flex>
                            <v-flex xs12 class="scrollable">
                                <field-drop-selector
                                        :datasets="datasets"
                                        :steps="steps"
                                        :fields="fields"
                                        :signatures="signatures"
                                        :show-input-fields="true"
                                        :field-types="[1,2,3,4,5,13,22]"
                                        @setSigInput="signatureRefField = $event"
                                />
                            </v-flex>
                        </v-layout>
                    </v-flex>
                </v-layout>
<!--                <div-->
<!--                        style="position: absolute; bottom: 0; right: 0"-->
<!--                        class="pa-2"-->
<!--                >-->
<!--                    <v-btn-->
<!--                            icon-->
<!--                            large-->
<!--                            color="secondary"-->
<!--                            @click="$emit('setHtml', editHtml.replace(/\n/g, ''))"-->
<!--                    >-->
<!--                        <v-icon>done</v-icon>-->
<!--                    </v-btn>-->
<!--                </div>-->
            </v-card>
            <app-input-dlg
                    :show-dialog="showUserInput"
                    title="User Input"
                    :body="`Please provide a description for the ${signatureRefField !== null ? 'Signature Input' : 'User Input'} field`"
                    :cancel-button="true"
                    :show-input-box="true"
                    :buttons="['Add Field']"
                    :call-backs="[ setUserInputData ]"
                    @dismiss="showUserInput = false"
            />
        </div>
</template>

<script>
    import {codeBaseMixin} from "../../../../../../../../codeBaseMixin";
    import {arrSplice,blankString} from "../../../../../../../../codeFunctions";
    import FieldDropSelector from "../../../FieldDropSelector";
    import InputDlg from "../../../../../../../../components/General/InputDlg";
    import Editor from "@/Editor/Editor";

    export default {
        components: {
            Editor,
            appInputDlg: InputDlg,
            FieldDropSelector
        },
        mixins: [codeBaseMixin],
        props: {
            userInfo: Object,
            datasets: Array,
            fields: Array,
            steps: Array,
            selectLists: Array,
            signatures: Array,
            title: String,
            html: String,
            attachments: Array
        },
        data() {
            return {
                editHtml: null,
                editor: null,
                showUserInput: false,
                userInputFieldName: null,
                signatureRefField: null
            }
        },
        mounted() {
            this.editHtml = this.html || '<br>';
        },
        watch: {
            showUserInput(val) {
                if (val) {
                    this.userInputFieldName = null
                }
            },
        },
        methods: {
            setUserInputData(fieldName) {
                if (blankString(fieldName)) {
                    this.showSnack('Error', 'Description can not be blank', 'Close', 'red')
                } else {
                    this.userInputFieldName = fieldName;
                    this.showUserInput = false;
                }
            },
            imageRemoved($img) {
                this.$nextTick(() => {
                    arrSplice(this.attachments, obj => obj.type !== 'embeddedImageUpload' ? true : obj.id !== $img[0].dataset.id)
                })
            },
            imageInserted($img, response) {
                $img[0].style.maxWidth = "100%";
                $img[0].style.width = "auto";
                let res = JSON.parse(response);
                this.attachments.push({
                    type: 'embeddedImageUpload',
                    filename: res.filename,
                    id: res.id,
                    link: res.link,
                    size: res.size
                })
            },
            waitForAddInput() {
                return new Promise ((resolve, reject) => {
                    this.showUserInput = true;
                    let tInterval = setInterval(() => {
                        if (!this.showUserInput) {
                            clearInterval(tInterval);
                            if (blankString(this.userInputFieldName)) {
                                reject('User Not Complete')
                            } else {
                                resolve(this.userInputFieldName)
                            }
                        }
                    }, 500)
                })
            },
            editorDrop(dropEvent, editor, vm) {
                let dropData = dropEvent.dataTransfer.getData('Text');

                if (['{{USERINPUT}}', '{{SIGNATUREINPUT}}'].includes(dropData)) {
                    if (dropData === '{{USERINPUT}}') {
                        vm.signatureRefField = null;
                        vm.waitForAddInput()
                            .then((fieldName) => {
                                let tag = `<span id="F-I-V-${fieldName.replace(/"/g,"")}" class="fr-deletable" style="background: #4CAF50; border-radius: 4px;  color: white; text-align: center; padding: 1px 4px; font-size: 11px; outline: none">${fieldName}</span>&nbsp;`
                                editor.insertContent(tag)
                            })
                            .catch((e) => {
                                console.log(e)
                            })
                    } else {
                        vm.waitForAddInput()
                            .then((fieldName) => {
                                let tag = `<span id="F-N-V-${vm.signatureRefField.id}-${fieldName}" class="fr-deletable" style="background: #EF6C00; border-radius: 4px;  color: white; text-align: center; padding: 1px 4px; font-size: 11px; outline: none">${vm.signatureRefField.description} - ${fieldName}</span>`
                                editor.insertContent(tag)
                                vm.signatureRefField = null;
                            })
                            .catch((e) => {
                                vm.signatureRefField = null;
                                console.log(e)
                            })
                    }
                } else {
                    editor.insertContent(dropData)
                }
            },
        },
        computed: {
            editorConfig() {
                // testDrop(event, editor) {
                //     console.log('DROP EVENT', event)
                //
                //     editor.insertContent('<span style="background: fuchsia; display: inline-block; cursor: pointer" :draggable="true">Test123456</span>')
                // },
                // paste_data_images: true,
                // images_upload_handler: this.uploadImage,

                // },
                // uploadImage(blobInfo, success) {
                //     ++this.imageCount
                //     let h = new Headers();
                //     let fd = new FormData();
                //     let myFile = blobInfo.blob();
                //     fd.append('attachmentFile', myFile);
                //
                //     axios.request({
                //         method: "post",
                //         url: `${this.serverAddress}/upload/noteAttachment/${this.userInfo.entityID}`,
                //         data: fd,
                //         onUploadProgress: (p) => {
                //             this.uploadProgress = (p.loaded / p.total) * 100
                //         },
                //     })
                //         .then(data => {
                //             success(data.data.link);
                //             --this.imageCount;
                //         })
                //         .catch(() => {
                //             this.showSnack('Error', 'Image Upload Error', 'Close', 'red');
                //             success('.blank');
                //             --this.imageCount;
                //         })
                // },
                let vm = this
                return {
                    paste_data_images: true,
                    remove_linebreaks : false,
                    init_instance_callback: function(editor) {
                        editor.focus();
                        editor.on('drop', function(e) {
                            vm.editorDrop(e, editor, vm)
                        })
                    },
                }
            },

        }
    }
</script>

<style scoped>

</style>
