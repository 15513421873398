<template>
    <v-dialog
            v-model="showDialog"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
            persistent
    >
        <v-card flat height="100vh">
<!--            {{localData.folders.length}} - {{selectedFolderID}}-->
            <v-layout column fill-height >
                <v-flex shrink class="primary">
<!--Card Title-->
                    <v-card-title class="py-2 pr-2">
<!--<img src="../../public/folders.svg" class="nav-item pr-2" width="32px">-->
                        <img src="../../public/icons/folders.svg" style="width: 32px" class="pr-2"/>
                        <span class="title font-weight-light white--text">Folder Manager</span>
                        <v-spacer></v-spacer>
                        <v-btn flat icon color="white" class="pa-0 ma-0" @click="$emit('dismiss', false)">
                            <v-icon>close</v-icon>
                        </v-btn>
                    </v-card-title>

                </v-flex>


                <v-flex xs12 style="overflow-y: auto">
                    <v-layout row fill-height>
<!--Folder Select-->
                        <v-flex xs4 class="pa-1" style="overflow-y: auto" v-if="showSelect" v-show="!hideSelectFolder || dlgType == 'allFoldersSelect'">
                            <v-card class="fill-height" style="overflow-y: auto;" v-if="!loadingTaskOnlyAccess">
                                <app-folder-select-component
                                        v-model="selectedFolderID"
                                        @refresh="refresh = true"
                                        :user-info="userInfo"
                                        :filter-groups="filterGroups"
                                        :show-all-folder-choice="dlgType == 'allFoldersSelect' || dlgType == 'allFolders' ? true : false"
                                        :all-folders-view="allFoldersView"
                                        :local-data="localData"
                                        :show-sync="showSync"
                                        :foldersWithSecurity="foldersAfterTaskOnlyAccess"
                                        :selected-folder="selectedFolder"
                                        :remove-contact-lists="removeContactLists"
                                        :contact-lists-only="contactListsOnly"
                                />
                            </v-card>
                            <div
                                    v-else
                                    class="pa-2 text-xs-center"
                            >
                                <v-progress-circular
                                        :size="40"
                                        :width="5"
                                        color="primary"
                                        indeterminate
                                />
                            </div>

                        </v-flex>
<!--Folder View-->
                        <v-flex xs12 style="overflow-y: auto" v-if="selectedFolderID >= 0" class="pa-1">
<!--                            @folderRenamed="updateCheck"-->
<!--                            @securityUpdated="updateFolderSecurity($event)"-->
<!--                            @folderGroupAdded="folderGroupAdded($event)"-->



                            <v-card class="fill-height scrollable">

                                <v-layout column fill-height class="scrollable">
                                    <v-flex shrink class="primary">
                                        <!--Select Workflow And Tabs-->
                                        <v-layout row align-center>
                                            <!--Show Hide Workflow-->
                                            <v-flex xs12 class="px-2">
                                                <v-layout row align-center>
                                                    <!--Selected Workflow Tabs-->
                                                    <v-flex xs12 v-if="selectedFolder != null && typeof selectedFolder.id != 'undefined'">
                                                        <v-tabs
                                                                v-model="selectedFolderViewOption"
                                                                color="primary"
                                                                slider-color="secondary"
                                                                grow
                                                                show-arrows
                                                                class="smallTab pt-1"
                                                        >
                                                            <v-tab
                                                                    key="fieldSets"
                                                                    class="white--text"
                                                                    v-show="selectedFolder && selectedFolder.id !== 0"
                                                            >
                                                                <div class="pa-2">Datasets</div>
                                                            </v-tab>
                                                            <v-tab
                                                                    key="Tasks"
                                                                    class="white--text"
                                                                    v-show="selectedFolder.access || typeof selectedFolder.access == 'undefined' || selectedFolder.taskOnlyAccess"
                                                            >
                                                                <div class="pa-2">Tasks</div>
                                                            </v-tab>
                                                            <v-tab
                                                                    key="Groups"
                                                                    class="white--text"
                                                                    v-if="!softBlock && userInfo.folderManager && selectedFolder.id !== 0"
                                                            >
                                                                <div class="pa-2">Groups</div>
                                                            </v-tab>
                                                          <v-tab
                                                              key="Contacts"
                                                              class="white--text"
                                                              v-if="!softBlock && selectedFolder.id !== 0 && userInfo.entityInfo &&  userInfo.entityInfo.contactsEnabled"
                                                          >
                                                            <div class="pa-2">Contacts</div>
                                                          </v-tab>
                                                        </v-tabs>

                                                    </v-flex>
                                                </v-layout>
                                                <v-divider></v-divider>
                                            </v-flex>
                                        </v-layout>
                                    </v-flex>
                                    <v-flex xs12 class="scrollable">
                                        <app-folder-view
                                            :allow-remove-contact="allowRemoveContact"
                                            :show-add-contact="showAddContact"
                                                v-if="!refresh"
                                                :user-info="userInfo"
                                                :folder="selectedFolder"
                                                :show-dialog="showDialog"
                                                @dismiss="$emit('dismiss', false)"
                                                @hideParent="$emit('dismiss', true)"
                                                @changesMade="changesMade = $event"
                                                :model="selectedFolderViewOption"
                                                :get-task-header="getTaskHeader"
                                                :add-doc-to-task-btn="addDocToTaskBtn"
                                                :local-data="localData"
                                                @addDocToTask="$emit('addDocToTask', $event)"
                                        >
                                            <template>
                                                <v-tooltip left v-if="typeof selectedFolder.id != 'undefined' && selectedFolderID >= 0 && dlgType == 'allFoldersSelect' && selectedFolder.access">
                                                    <v-btn
                                                            color="secondary"
                                                            dark
                                                            fab
                                                            @click="folderSelected"
                                                            slot="activator"
                                                    >
                                                        <v-icon>check</v-icon>
                                                    </v-btn>
                                                    <span>Select Folder</span>
                                                </v-tooltip>
                                            </template>

<!--                                            <template slot="extra">-->
<!--                                                <v-tooltip left v-if="typeof selectedFolder.id != 'undefined' && selectedFolderID >= 0 && dlgType == 'allFoldersSelect' && selectedFolder.access">-->
<!--                                                    <v-btn-->
<!--                                                            color="secondary"-->
<!--                                                            dark-->
<!--                                                            fab-->
<!--                                                            @click="folderSelected"-->
<!--                                                            slot="activator"-->
<!--                                                    >-->
<!--                                                        <v-icon>check</v-icon>-->
<!--                                                    </v-btn>-->
<!--                                                    <span>Select Folder</span>-->
<!--                                                </v-tooltip>-->
<!--                                            </template>-->

                                        </app-folder-view>
                                    </v-flex>
                                </v-layout>
                            </v-card>
                        </v-flex>
                    </v-layout>
                </v-flex>
            </v-layout>

        </v-card>


    </v-dialog>
</template>

<script>
    import { eventBus} from "../main";
    import { codeBaseMixin } from "../codeBaseMixin";
    import FolderSelectComponent from './components/FolderSelectComponent'
    import FolderView from './components/FolderView'
    import {mapGetters} from "vuex";

    export default {
        name: "FolderDlg",
        mixins: [ codeBaseMixin ],
        components: {
            appFolderSelectComponent: FolderSelectComponent,
            appFolderView: FolderView
        },
        props: {
            showDialog: Boolean,
            userInfo: Object,
            selectDlg: {
                type: Boolean,
                value: false
            },
            dlgType: {
                type: String,
                value: 'allFolders'
            },
            value: {
                type: Object,
                value: {}
            },
            filterGroups: {
                type: Array,
                default: null
            },
            localData: Object,
            showSync: Boolean,
            getTaskHeader: Function,
            addDocToTaskBtn: {
                type: Boolean,
                default: false
            },
          showAddContact: {
              type: Boolean,
              default: true
          },
          allowRemoveContact: {
            type: Boolean,
            default: false
          },
          removeContactLists: {
            type: Boolean,
            default: false
          },
          contactListsOnly: {
            type: Boolean,
            default: false
          },
        },

        data() {
            return {
                hideSelectFolder: false,
                initRan: false,
                foldersLoaded: false,
                showSelect: true,
                allFoldersView: false,
                changesMade: false,
                selectedFolderViewOption: 0,
                taskOnlyFolders: [],
                loadingTaskOnlyAccess: true,
                selectedFolderID: -1,
                refresh: false,
                console: console
            }
        },
        methods: {
            // folderGroupAdded(newGroup) {
            //     this.folders.folderGroups.push({id:newGroup.id,name:newGroup.description,dropped:false})
            // },

            // updateFolderSecurity(folderData) {
            //     this.folders.folders.forEach(folder => {
            //         if (folderData.folder.id == folder.id) {
            //             folder.folderGroups = folderData.folderGroups
            //         }
            //     })
            // },

            folderSelected() {
                this.$emit('input', this.selectedFolder);
                this.$emit('dismiss', false);
            },

            // updateCheck(folder) {
            //     this.folders.folders.forEach(lFolder => {
            //         if (lFolder.id == folder.id) {
            //             lFolder.name = folder.name
            //         }
            //     })
            // },

            initAppAllFolders() {

                if (typeof this.userInfo.entityID == 'undefined') {
                    setTimeout(() => {this.initAppAllFolders()}, 500)
                } else {
                    this.getFolderTaskOnlyAccess();
                }
            },

            // getFolders() {
            //     console.log('Get Folders')
            //     this.allFoldersView = false;
            //     this.foldersLoaded = false;
            //     let h = new Headers();
            //     h.append('Content-Type', 'application/json');
            //
            //     let url = this.serverAddress + '/folders/getFoldersForUser/' + this.userInfo.entityID + '/' + this.userInfo.userID;
            //
            //     let req = new Request(url, {method: 'GET', headers: h, mode: 'cors'});
            //     fetch(req)
            //         .then((response) => {
            //             if (response.ok == true) {return response.text();
            //             } else {
            //                 throw new Error('Bad Stuff');
            //             }
            //         })
            //         .then((jsonData) => {
            //             try {
            //                 let jData = JSON.parse(jsonData);
            //                 jData.folderGroups.forEach((folderGroup) => {folderGroup.dropped = false});
            //                 if (this.value != null && typeof this.value.id != 'undefined') {
            //                     jData.folders.forEach(folder => {
            //                         folder.selected = folder.id == this.value.id;
            //                     })
            //                 }
            //                 this.$emit('appendFolderData', jData);
            //                 this.foldersLoaded = true;
            //                 if (this.value != null && typeof this.value.id != 'undefined') {
            //                     this.selectedFolder = this.value
            //                 }
            //
            //             } catch (e) {
            //                 // eslint-disable-next-line
            //                 console.log(e)
            //             }
            //         })
            //         .catch((err) => {
            //             console.log('ERROR: ' + err.message);
            //         });
            // },

            getFolderTaskOnlyAccess() {
                this.loadingTaskOnlyAccess = true;
                this.fetch({method: 'GET', url: `/folders/taskOnlyAccess/${this.userInfo.entityID}/${this.userInfo.userID}`})
                .then(data => {
                    this.taskOnlyFolders = data;
                    this.loadingTaskOnlyAccess = false
                })
                .catch(err => {
                    console.log(err)
                })
            },

            // getAllFolders() {
            //     console.log('Get All Folders')
            //     this.allFoldersView = true;
            //     this.foldersLoaded = false;
            //     let h = new Headers();
            //     h.append('Content-Type', 'application/json');
            //
            //     let url = this.serverAddress + '/folders/getAllFoldersForUser/' + this.userInfo.entityID + '/' + this.userInfo.userID;
            //
            //     let req = new Request(url, {method: 'GET', headers: h, mode: 'cors'});
            //     fetch(req)
            //         .then((response) => {
            //             if (response.ok == true) {return response.text();
            //             } else {
            //                 throw new Error('Bad Stuff');
            //             }
            //         })
            //         .then((jsonData) => {
            //             try {
            //                 let jData = JSON.parse(jsonData);
            //                 jData.folderGroups.forEach((folderGroup) => {folderGroup.dropped = false});
            //                 if (this.value != null && typeof this.value.id != 'undefined') {
            //                     jData.folders.forEach(folder => {
            //                         folder.selected = folder.id == this.value.id;
            //                     })
            //                 }
            //                 this.$emit('appendFolderData', jData);
            //                 this.foldersLoaded = true;
            //                 if (this.value != null && typeof this.value.id != 'undefined') {
            //                     this.selectedFolder = this.value
            //                 }
            //             } catch (e) {
            //                 // eslint-disable-next-line
            //                 console.log(e)
            //             }
            //
            //
            //         })
            //         .catch((err) => {
            //             console.log('ERROR: ' + err.message);
            //         });
            // }
        },
        watch: {
            refresh(val) {
                if (val) {
                    console.log('Hello')
                    this.$nextTick(() => {this.refresh = false})
                }

            },

            showDialog: {
                immediate: true,
                handler(newValue) {
                    if (newValue) {
                        this.hideSelectFolder = false
                    }
                    if (!this.initRan && newValue == true) {
                        this.initRan = true;
                        switch (this.dlgType) {
                            case 'allFoldersSelect': {
                                this.initAppAllFolders();
                                break;
                            }
                            case 'allFolders': {
                                this.initAppAllFolders();
                                break;
                            }
                            case 'selection': {
                                break;
                            }
                            case 'folderView': {
                                this.selectedFolderID = this.value.id;
                                this.showSelect = false;
                                break;
                            }
                        }
                    }
                }
            }
        },
        computed: {
            ...mapGetters("userInfo", ["softBlock"]),

            selectedFolder() {
                if (this.selectedFolderID >= 0) {
                    return this.foldersAfterTaskOnlyAccess.filter(obj => obj.id == this.selectedFolderID).pop()
                } else {
                    return {}
                }
            },

            foldersAfterFilterGroups() {
                if (Array.isArray(this.filterGroups) && this.filterGroups.length >= 1) {
                    return this.localData.folders.filter(folder =>
                        Array.isArray(folder.folderGroups)
                        && folder.folderGroups.length >= 1
                        && this.filterGroups.filter(group => folder.folderGroups.includes(group)).length >= 1
                    )
                } else {
                    return this.localData.folders
                }

            },

            foldersAfterTaskOnlyAccess() {
                return this.foldersAfterFilterGroups.filter(obj => obj.id > 0).map((folder) => {
                    if (!folder.access) {
                        folder.taskOnlyAccess = this.taskOnlyFolders.includes(folder.id);
                    }
                    return folder;
                }).concat(!this.filterGroups || !this.filterGroups.length ? {
                    access: false,
                    taskOnlyAccess: true,
                    groupString: "Tasklogix Task Folder",
                    id: 0,
                    description: "Task Folder",
                    folderGroups: [],
                    searchStr: "task folder taskfolder tf"
                }: [])
            }
        }
    }
</script>

<style scoped>
    .selectedFieldSet {
        background: #efefef;
    }

</style>
