<template>
    <v-layout column>
        <v-flex shrink class="pa-2 text-xs-center" v-if="taskLoading">
            <v-card :flat="hideCard">
                <task-loading-container
                    :task-id="taskID"
                />
            </v-card>
        </v-flex>
        <v-flex shrink v-else class="py-1">
            <v-card :flat="hideCard">
                <task-container
                        :task="taskHeader"
                        :user-info="userInfo"
                        :taskBar="false"
                        @taskSelected="taskSelected(taskHeader)"
                        :allow-drop="allowDrop"
                        @taskBarDrop="passDropEvent($event, taskHeader)"
                />
            </v-card>

        </v-flex>
    </v-layout>
</template>

<script>
    import {codeBaseMixin} from "../../../../../../../codeBaseMixin";
    import TaskLoadingContainer from "../../../../../../../components/TaskContainer/TaskLoadingContainer";
    import TaskContainer from "../../../../../../../components/TaskContainer/TaskContainer";
    import {eventBus} from "../../../../../../../main";

    export default {
        components: {TaskContainer, TaskLoadingContainer},
        mixins: [codeBaseMixin],
        props: {
            taskID: Number,
            userInfo: Object,
            hideCard: {
                type: Boolean,
                default: false
            },
            folderGroups: Array,
            getTaskHeader: Function,
            allowDrop: {
                type: Boolean,
                default: false
            }

        },
        data() {
            return {
                taskHeader: null,
                taskLoading: false
            }
        },
        mounted() {
            this.updateTask();
        },
        methods: {
            passDropEvent(dropData, task) {
                this.$emit('taskBarDrop', {dropData: dropData, task: task})
            },

            taskSelected(task) {
                eventBus.$emit('gotoTask', task)
            },

            updateTask() {
                this.taskLoading = true;
                this.getTaskHeader(this.taskID)
                    .then(task => {
                        this.taskHeader = task;
                        this.taskLoading = false;
                    })
                    .catch(err => {console.log(err)})
            }
        },

    }
</script>

<style scoped>

</style>
