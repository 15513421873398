<template>
  <div style="height: 48px; border-radius: 27px; background-color: var(--card-back)" class="c-d-flex c-align-center">
    <v-tooltip top>
      <template #activator="{ on }">
        <v-btn
            class="ml-2"
            icon
            variant="text"
            v-on="on"
            @click="togglePlay"
        >
          <v-icon>{{isPlaying ? 'pause' : 'play_arrow'}}</v-icon>
        </v-btn>
      </template>
      Play / Pause recording
    </v-tooltip>
    
    <div class="px-2 pr-4 d-flex justify-end text-caption" style="color: var(--on-surface)">{{ currentTime }} / {{ totalTime }}</div>
    
    <canvas ref="waveformCanvas" width="160" height="44"></canvas>
    
    <v-tooltip top v-if="showDelete">
      <template #activator="{ on }">
        <v-btn
            icon
            variant="text"
            v-on="on"
            @click="$emit('delete')"
            class="mr-2"
        >
          <v-icon>delete_outline</v-icon>
        </v-btn>
      </template>
      Discard voice message
    </v-tooltip>
    
    <v-tooltip top v-else-if="showDownload">
      <template #activator="{ on }">
        <a :href="url" target="_blank">
          <v-btn
              icon
              variant="text"
              v-on="on"
              @click="$emit('delete')"
              class="mr-2"
          >
            <v-icon>download</v-icon>
          </v-btn>
        </a>
      </template>
      Download
    </v-tooltip>
    
    <template v-else>
      <div class="pr-4"></div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    url: {
      type: String,
      required: true,
    },
    showDelete: {
      type: Boolean,
      default: false
    },
    showDownload: {
      type: Boolean,
      default: false
    }
  },
  
  data() {
    return {
      isPlaying: false,
      currentTime: '00:00',
      totalTime: '00:00',
      waveformCanvas: null,
      audio: null,
      audioContext: null,
      analyser: null,
      dataArray: null,
      bufferLength: null,
      animationFrameId: null,
      mediaElementSource: null,
      barCount: 20
    }
  },
  created() {
    this.audio = new Audio(this.url);
    this.audio.crossOrigin = "anonymous";
  },
  mounted() {
    if (this.url && this.audio) {
      this.checkDuration();
      this.audio.addEventListener('timeupdate', this.onTimeUpdate);
      this.audio.addEventListener('loadedmetadata', this.onLoadedMetadata);
      this.audio.addEventListener('ended', this.onEnded);
      this.drawPlaceholders()
    }
  },
  beforeDestroy() {
    if (this.audio) {
      this.audio.pause();
      this.audio.removeEventListener('timeupdate', this.onTimeUpdate);
      this.audio.removeEventListener('loadedmetadata', this.onLoadedMetadata);
      this.audio.removeEventListener('ended', this.onEnded);
    }
    if (this.animationFrameId) {
      cancelAnimationFrame(this.animationFrameId);
    }
    if (this.audioContext) {
      this.audioContext.close();
    }
  },
  methods: {
    formatTime(time) {
      const minutes = Math.floor(time / 60);
      const seconds = Math.floor(time % 60);
      return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    },
    
    onTimeUpdate() {
      this.currentTime = this.formatTime(this.audio.currentTime);
    },
    
    onLoadedMetadata() {
      if (this.audio.duration !== Infinity) {
        this.totalTime = this.formatTime(this.audio.duration);
      }
    },
    
    onEnded() {
      this.isPlaying = false;
      cancelAnimationFrame(this.animationFrameId); // Stop the animation
    },
    
    togglePlay() {
      if (this.audio) {
        if (this.isPlaying) {
          this.audio.pause();
          cancelAnimationFrame(this.animationFrameId);
        } else {
          this.audio.play();
          this.animateWaveform();
        }
        this.isPlaying = !this.isPlaying;
      }
    },
    
    animateWaveform() {
      if (!this.audioContext) {
        this.audioContext = new (window.AudioContext || window.webkitAudioContext)();
      }
      if (!this.analyser) {
        this.analyser = this.audioContext.createAnalyser();
        this.analyser.fftSize = 256;
        this.bufferLength = this.analyser.frequencyBinCount;
        this.dataArray = new Uint8Array(this.bufferLength);
      }
      
      if (!this.mediaElementSource) {
        this.mediaElementSource = this.audioContext.createMediaElementSource(this.audio);
        this.mediaElementSource.connect(this.analyser);
        this.analyser.connect(this.audioContext.destination);
      }
      
      const canvas = this.$refs.waveformCanvas;
      const canvasContext = canvas.getContext('2d');
      
      const vm = this
      
      const draw = () => {
        vm.animationFrameId = requestAnimationFrame(draw);
        
        vm.analyser.getByteFrequencyData(vm.dataArray);
        
        canvasContext.clearRect(0, 0, canvas.width, canvas.height);
        
        const barWidth = canvas.width / (vm.barCount * 2 - 1);
        let barHeight;
        let x = 0;
        
        for (let i = 0; i < vm.barCount; i++) {
          const value = vm.dataArray[i];
          barHeight = Math.log(value) * 2; // Adjust this factor to control sensitivity
          
          if (barHeight > canvas.height / 2) barHeight = canvas.height / 2; // Cap the height
          
          const radius = barWidth / 2;
          const yTop = canvas.height / 2 - barHeight;
          const yBottom = canvas.height / 2 + barHeight;
          
          // Top bar with rounded top
          canvasContext.fillStyle = '#00695C';
          canvasContext.beginPath();
          canvasContext.moveTo(x, yTop + radius);
          canvasContext.arc(x + radius, yTop + radius, radius, Math.PI, Math.PI * 2);
          canvasContext.rect(x, yTop + radius, barWidth, barHeight - radius);
          canvasContext.fill();
          
          // Bottom bar with rounded bottom
          canvasContext.beginPath();
          canvasContext.moveTo(x, yBottom - radius);
          canvasContext.arc(x + radius, yBottom - radius, radius, 0, Math.PI);
          canvasContext.rect(x, canvas.height / 2, barWidth, barHeight - radius);
          canvasContext.fill();
          
          x += barWidth + barWidth; // Space between bars
        }
      };
      
      draw();
    },
    
    drawPlaceholders() {
      const canvas = this.$refs.waveformCanvas;
      const canvasContext = canvas.getContext('2d');
      const barWidth = canvas.width / (this.barCount * 2 - 1);
      const placeholderHeight = canvas.height / 4; // Set a height for placeholder bars
      let x = 0;
      
      for (let i = 0; i < this.barCount; i++) {
        const radius = barWidth / 2;
        const yTop = canvas.height / 2 - placeholderHeight;
        const yBottom = canvas.height / 2 + placeholderHeight;
        canvasContext.fillStyle = '#00695C'; // Lighter color for placeholder
        canvasContext.beginPath();
        canvasContext.moveTo(x, yTop + radius);
        canvasContext.arc(x + radius, yTop + radius, radius, Math.PI, Math.PI * 2);
        canvasContext.rect(x, yTop + radius, barWidth, placeholderHeight - radius);
        canvasContext.fill();
        canvasContext.beginPath();
        canvasContext.moveTo(x, yBottom - radius);
        canvasContext.arc(x + radius, yBottom - radius, radius, 0, Math.PI);
        canvasContext.rect(x, canvas.height / 2, barWidth, placeholderHeight - radius);
        canvasContext.fill();
        x += barWidth + barWidth; // Space between bars
      }
    },
    
    
    async checkDuration() {
      try {
        const response = await fetch(this.url);
        const arrayBuffer = await response.arrayBuffer();
        const audioContext = new (window.AudioContext || window.webkitAudioContext)();
        const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
        this.totalTime = this.formatTime(audioBuffer.duration);
      } catch (error) {
        console.error('Error decoding audio data:', error);
      }
    },
  },
}
</script>


<style scoped>

</style>