<template>
    <div class="pt-2 pb-2">
        <v-card>
            <v-text-field
                    label="Search Mail"
                    solo
                    clearable
                    :append-icon="searchMenu ? 'arrow_drop_up' : 'arrow_drop_down'"
                    prepend-icon="search"
                    color="primary"
                    flat
                    class="ml-3"
                    :hide-details="true"
                    @click:append="searchMenu = !searchMenu"
                    v-model="searchOptions.searchText"
                    @keyup.enter="fullSearch(false)"
            ></v-text-field>
        </v-card>
        <v-menu :full-width="true" v-model="searchMenu" left :close-on-content-click="false">
            <div slot="activator" style="width: 100%;"></div>
            <v-card >
                <v-card-title primary-title class="pb-0 mb-0">

                    <v-list style="width: 100%">
                        <v-list-tile>
                            <v-text-field
                                    label="To:"
                                    color="primary"
                                    clearable
                                    v-model="searchOptions.to"
                            ></v-text-field>
                        </v-list-tile>
                        <v-list-tile>
                            <v-text-field
                                    label="From:"
                                    color="primary"
                                    clearable
                                    v-model="searchOptions.from"
                            ></v-text-field>
                        </v-list-tile>
                        <v-list-tile>
                            <v-text-field
                                    label="Subject:"
                                    color="primary"
                                    clearable
                                    v-model="searchOptions.subject"
                            ></v-text-field>
                        </v-list-tile>
                        <v-list-tile>
                            <v-text-field
                                    label="Body Contains:"
                                    color="primary"
                                    clearable
                                    v-model="searchOptions.hasWords"
                            ></v-text-field>
                        </v-list-tile>

                        <v-list-tile>
                            <v-checkbox
                                    label="Has Attachments"
                                    v-model="searchOptions.hasAttachment"
                            ></v-checkbox>
                            <v-spacer/>
                        </v-list-tile>
                    </v-list>

                </v-card-title>

                <v-card-actions class="pt-0 mt-0">
                    <v-btn @click="clearFilter" flat color="primary">
                        <v-icon color="primary" left>clear</v-icon>
                        <span>Clear</span>
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn @click="fullSearch(true)" flat color="primary">
                        <v-icon color="primary" left>search</v-icon>
                        <span>Search</span>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-menu>
    </div>
</template>

<script>
    export default {
        props: {
            searchOptions: Object
        },
        data() {
            return {
                searchMenu: false,
            }
        },
        methods: {

            simpleSearch() {
                if (this.searchOptions.searchText != '') {
                    this.$emit('simpleSearch');
                    this.searchMenu = false
                }
            },

            clearFilter() {
                this.searchOptions.searchText = "";
                this.searchOptions.from = "";
                this.searchOptions.to = "";
                this.searchOptions.subject = "";
                this.searchOptions.hasWords = "";
                this.searchOptions.noWords = "";
                this.searchOptions.hasAttachment = false;
                this.searchOptions.starred = false;
            },
            fullSearch(extended) {
                this.searchMenu = false;

                let qString = "";

                if (extended) {
                    if (this.searchOptions.to != '' && this.searchOptions.to != null) {
                        qString = qString + 'to:' + this.searchOptions.to + ' '
                    }
                    if (this.searchOptions.from != ''  && this.searchOptions.from != null) {
                        qString = qString + 'from:' + this.searchOptions.from + ' '
                    }
                    if (this.searchOptions.subject != ''  && this.searchOptions.subject != null) {
                        qString = qString + 'subject:' + this.searchOptions.subject + ' '
                    }
                    if (this.searchOptions.hasWords != ''  && this.searchOptions.hasWords != null) {
                        qString = qString + this.searchOptions.hasWords + ' '
                    }
                    if (this.searchOptions.noWords != ''  && this.searchOptions.noWords != null) {
                        qString = qString + '-' + this.searchOptions.noWords.split(" ").join(" -") + ' '
                    }
                    if (this.searchOptions.hasAttachment) {
                        qString = qString + ' has:attachment'
                    }
                    if (this.searchOptions.starred) {
                        qString = qString + ' is:starred'
                    }
                } else {
                    qString = this.searchOptions.searchText;
                    this.searchOptions.hasWords = this.searchOptions.searchText;
                }

                this.searchOptions.searchText = qString
                this.$emit('runSearch')
            }
        }
    }
</script>

<style scoped>

</style>
