import { render, staticRenderFns } from "./FieldsSelect.vue?vue&type=template&id=84c7f602&scoped=true"
import script from "./FieldsSelect.vue?vue&type=script&lang=js"
export * from "./FieldsSelect.vue?vue&type=script&lang=js"
import style0 from "./FieldsSelect.vue?vue&type=style&index=0&id=84c7f602&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "84c7f602",
  null
  
)

export default component.exports