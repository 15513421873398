<template>
    <div id="printArea"  ref="printArea" :class="printing ? 'print-view print-content' : 'fill-height scrollable'">


        <v-layout row fill-height justify-center align-center v-if="loadingMessage">
            <v-flex class="text-xs-center">
                <v-progress-circular
                        :size="40"
                        :width="5"
                        color="primary"
                        indeterminate
                ></v-progress-circular>
            </v-flex>
        </v-layout>

        <template v-else-if="printing">
            <div>
                <v-layout row align-center>
                    <v-flex shrink class="pa-1" >
                        <v-icon>mail_outline</v-icon>
                    </v-flex>
                    <v-flex xs12 class="px-1">
                        <!--Subject-->
                        <v-layout row align-top v-if="messageData.subject">
                            <v-flex xs12 class="subheading">{{ messageData.subject }}</v-flex>
                            <v-flex shrink class="caption pl-2" style="white-space: nowrap">{{dateStr}}</v-flex>
                        </v-layout>
                        <!--From-->
                        <v-layout row align-top>
                            <v-flex xs12 class="body-1">
                                <div style="display: inline" v-html="fromStr"></div>
                            </v-flex>
                            <v-flex v-if="!messageData.subject" shrink class="caption pl-2" style="white-space: nowrap">{{dateStr}}</v-flex>
                        </v-layout>
                        <!--To-->
                        <div class="caption" v-if="messageData.to">
                            <div style="display: inline">To: </div><div style="display: inline" v-html="toStr"></div>
                            <div v-if="messageData.to.length > 3 && !fullTo" style="display: inline; cursor: pointer" class="showMore" @click="fullTo = true">
                                +{{ messageData.to.length - 3 }} More
                            </div>
                        </div>
                        <!--Cc-->
                        <div class="caption" v-if="messageData.cc">
                            <div style="display: inline">Cc: </div><div style="display: inline" v-html="ccStr"></div>
                            <div v-if="messageData.cc.length > 3 && !fullCc" style="display: inline; cursor: pointer" class="showMore" @click="fullCc = true">
                                +{{ messageData.cc.length - 3 }} More
                            </div>
                        </div>
                        <!--BCc-->
                        <div class="caption" v-if="messageData.bcc">
                            <div style="display: inline">Bcc: </div><div style="display: inline" v-html="bccStr"></div>
                            <div v-if="messageData.bcc.length > 3 && !fullBcc" style="display: inline; cursor: pointer" class="showMore" @click="fullBcc = true">
                                +{{ messageData.bcc.length - 3 }} More
                            </div>
                        </div>
                    </v-flex>
                </v-layout>
            </div>
            <v-divider></v-divider>
            <div v-html="printHtml">
            </div>
        </template>

        <template v-else>
            <v-layout column :class="printing ? 'print-view' : 'fill-height scrollable'">
                <v-flex shrink>
<!--Metta Top Slot-->
                    <div v-if="!printing">
                        <slot name="metaTop"></slot>
                    </div>
                    <v-layout row align-center>
                        <v-flex shrink class="pa-1" >
                            <v-icon>mail_outline</v-icon>
                        </v-flex>
                        <v-flex xs12 class="px-1">
<!--Subject-->
                            <v-layout row align-top v-if="messageData.subject">
                                <v-flex xs12 class="subheading">{{ messageData.subject }}</v-flex>
                                <v-flex shrink class="caption pl-2" style="white-space: nowrap">{{dateStr}}</v-flex>
                                <v-flex shrink class="pl-1" style="margin-top: -4px" v-if="!printing">
                                    <v-tooltip left>
                                        <v-btn
                                                small
                                                @click="callPrintMail()"
                                                style="width: 24px; height: 24px"
                                                icon
                                                flat
                                                color="grey"
                                                slot="activator"
                                                class="pa-0 ma-0"
                                        >
                                            <v-icon small>print</v-icon>
                                        </v-btn>
                                        Print Mail
                                    </v-tooltip>
                                </v-flex>
<!--                                <v-flex shrink class="pl-1" style="margin-top: -4px" v-if="!printing">-->
<!--                                    <v-tooltip left>-->
<!--                                        <v-btn-->
<!--                                                small-->
<!--                                                @click="downloadMessage()"-->
<!--                                                style="width: 24px; height: 24px"-->
<!--                                                icon-->
<!--                                                flat-->
<!--                                                color="grey"-->
<!--                                                slot="activator"-->
<!--                                                class="pa-0 ma-0"-->
<!--                                        >-->
<!--                                            <v-icon small>download</v-icon>-->
<!--                                        </v-btn>-->
<!--                                        Download Mail-->
<!--                                    </v-tooltip>-->
<!--                                </v-flex>-->
                            </v-layout>
<!--From-->
                            <v-layout row align-top>
                                <v-flex xs12 class="body-1">
                                    <div style="display: inline" v-html="fromStr"></div>
                                </v-flex>
                                <v-flex v-if="!messageData.subject" shrink class="caption pl-2" style="white-space: nowrap">{{dateStr}}</v-flex>
                            </v-layout>
<!--To-->
                            <div class="caption" v-if="messageData.to">
                                <div style="display: inline">To: </div><div style="display: inline" v-html="toStr"></div>
                                <div v-if="messageData.to.length > 3 && !fullTo" style="display: inline; cursor: pointer" class="showMore" @click="fullTo = true">
                                   +{{ messageData.to.length - 3 }} More
                                </div>
                            </div>
<!--Cc-->
                            <div class="caption" v-if="messageData.cc">
                                <div style="display: inline">Cc: </div><div style="display: inline" v-html="ccStr"></div>
                                <div v-if="messageData.cc.length > 3 && !fullCc" style="display: inline; cursor: pointer" class="showMore" @click="fullCc = true">
                                    +{{ messageData.cc.length - 3 }} More
                                </div>
                            </div>
<!--BCc-->
                            <div class="caption" v-if="messageData.bcc">
                                <div style="display: inline">Bcc: </div><div style="display: inline" v-html="bccStr"></div>
                                <div v-if="messageData.bcc.length > 3 && !fullBcc" style="display: inline; cursor: pointer" class="showMore" @click="fullBcc = true">
                                    +{{ messageData.bcc.length - 3 }} More
                                </div>
                            </div>
                        </v-flex>
                    </v-layout>
<!--Metta Bottom Slot-->
                    <div>
                        <slot name="metaBottom"></slot>
                    </div>
                </v-flex>
                <v-flex shrink class="pt-1">
                    <v-divider></v-divider>
                </v-flex>
                <v-flex :grow="printing" ref="parentBox" style="overflow-y: visible; background: white">
                    <iframe
                            srcdoc="<h1></h1>"
                            ref="viewFrame"
                            id="viewFrame"
                            style="width: 100%;"
                            frameborder="0"
                            class="fill-height"
                    >
                    </iframe>
                </v-flex>
                <v-flex shrink class="py-1" style="max-height: 20vh" v-if="!printing">
                    <p-d-f ref="pdfViewer"></p-d-f>

                    <template v-for="(att, i) in attList">
                        <e-m-l-attachment
                                ref="att"
                                :key="i"
                                :filename="att.fileName || att.generatedFileName || 'attachment'"
                                @input="downloadAtt(att, i)"
                        />
                    </template>
                    <v-chip
                            v-if="!showEmbedded && attList.length !== messageData.attachments.length"
                            style="cursor: pointer"
                            small
                            label
                            outline
                            color="primary"
                            @click="showEmbedded = true"
                    >
                        +{{messageData.attachments.length - attList.length}} More
                    </v-chip>
                </v-flex>
            </v-layout>




            <v-dialog
                    v-model="dialog"
                    fullscreen
            >
                <v-card v-if="dialog" class="scrollable" style="height: 100vh">

                    <v-layout column fill-height class="scrollable">
                        <v-flex shrink class="primary">
                            <v-layout row align-center>
                                <v-flex xs12>
                                    <v-layout row align-center>
                                        <v-flex xs12 class="font-weight-regular white--text pa-2 title">
                                            Mail View
                                        </v-flex>
                                    </v-layout>

                                    <v-spacer></v-spacer>


                                </v-flex>
                                <v-flex shrink class="pa-2">
                                    <v-btn
                                            icon
                                            flat
                                            color="white"
                                            class="pa-0 ma-0"
                                            @click="dialog = !dialog"
                                    >
                                        <v-icon>close</v-icon>
                                    </v-btn>
                                </v-flex>
                            </v-layout>
                        </v-flex>
                        <v-flex xs12 class="scrollable pa-2" v-if="dialog">
                            <e-m-l-viewer
                                    ref="child"
                            />
                        </v-flex>
                    </v-layout>
                </v-card>
            </v-dialog>

        </template>

    </div>
</template>

<script>

import {codeBaseMixin} from "@/codeBaseMixin";
import {mapGetters} from 'vuex';
import {format, parse} from 'date-fns';
import PDF from "@/components/General/PDF/PDF";
import EMLAttachment from "@/Mail/EML Viewer/EMLAttachment";
import {parseMail} from "@/codeFunctions";
import {printMail} from "@/Mail/Create Mail/createMailManager";

export default {
    name: 'EMLViewer',
    components: {EMLAttachment, PDF},
    mixins: [codeBaseMixin],
    props: {
        filename: {
            type: String,
            default: null
        },
        smtpMailboxCache: {
            type: Boolean,
            default: false
        },
        smtpCache: {
            type: Boolean,
            default: false
        },
        cache: {
            type: Boolean,
            default: false
        },
        loadFromFilename: {
            type: Boolean,
            default: true
        },
        printing: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            loadingMessage: true,
            messageData: null,
            vFrameRef: null,
            fullTo: false,
            fullCc: false,
            fullBcc: false,
            showEmbedded: false,
            dialog: false,
            fileSrc: false,
            printHtml: null
        }
    },
    created() {
        console.log('EML VIEW Created')
    },
    beforeDestroy() {
        console.log('EML VIEW Destroyed')
    },
    watch: {
        filename: {
            immediate: true,
            handler(val) {
                this.fullTo = false;
                this.fullCc = false;
                this.fullBcc = false;
                this.showEmbedded = false;
                this.messageData = null;
                if (val && this.loadFromFilename)
                    this.reloadMessage()
            }
        }
    },
    methods: {

        callPrintMail() {
            printMail(this.messageData)
        },

        printMailRun() {
            return new Promise((resolve, reject) => {
                this.printHtml = this.messageData.html || this.messageData.textAsHtml
                setTimeout(() => {
                    window.print();
                    this.printHtml = null
                    resolve(true)
                }, 500)
            })
        },

        async downloadAtt(att, index) {

            function getBlobText(blob) {
                return new Promise((resolve, reject) => {
                    let reader = new FileReader();

                    reader.readAsText(blob, 'ISO-8859-1');

                    reader.onload = function() {
                        resolve(reader.result);
                    };

                    reader.onerror = function() {
                        reject(reader.error);
                    };
                })
            }

            if (!att.content) {
                this.$refs.att[index].loading = true;
                let downloadType = att.contentType === "application/pdf" ? 'url' : (att.contentType === 'message/rfc822' ? 'data' : 'auto');
                this.$newReqDownloadFile(
                        'post',
                        `eml/att/${this.userInfo.entityID}`,
                        {
                            att: att,
                            mailFilename: this.filename,
                            route: this.smtpCache ? 'smtp' : (this.smtpMailboxCache ? 'mailbox' : (this.cache ? 'cache' : 'store'))
                        },
                        att.fileName || 'attachemnt',
                        downloadType
                )
                        .then(async data => {
                            if (downloadType === 'url') {
                                let viewer = this.$refs.pdfViewer;
                                viewer.localInit(data, att.fileName || 'attachemnt.pdf');
                                this.$refs.att[index].loading = false;
                            }
                            if (downloadType === 'data') {
                                try {

                                    let parsed = await parseMail(await getBlobText(data))
                                    this.dialog = true;

                                    this.$nextTick(() => {
                                        this.$refs.child.inception(parsed)
                                    })

                                    this.$refs.att[index].loading = false;
                                } catch (e) {
                                    // eslint-disable-next-line
                                    console.log(e)
                                    this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                                    this.$refs.att[index].loading = false;
                                    this.dialog = false;
                                }
                            }
                        })
                        .catch(e => {
                            // eslint-disable-next-line
                            console.log(e)
                            this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                            this.$refs.att[index].loading = false;
                        })
                        .finally(() => {
                            if (!['url','data'].includes(downloadType))
                                this.$refs.att[index].loading = false;
                        })

            } else {
                this.$refs.att[index].loading = true;

                switch (att.contentType.toLowerCase()) {
                    case 'application/pdf': {
                        let blob = new Blob([att.content.buffer], {type: att.contentType})
                        const url = window.URL.createObjectURL(blob);
                        let viewer = this.$refs.pdfViewer;
                        viewer.localInit(url, att.fileName || 'attachemnt.pdf');
                        this.$refs.att[index].loading = false;
                        break;
                    }
                    case 'message/rfc822': {
                        let blob = new Blob([att.content.buffer])
                        parseMail(await getBlobText(blob))
                                .then(parsed => {
                                    this.dialog = true;
                                    this.$nextTick(() => {
                                        this.$refs.child.inception(parsed)
                                    })
                                })
                                .catch(err => {
                                    console.log(err)
                                })
                                .finally(() => {
                                    this.$refs.att[index].loading = false;
                                })
                        break;
                    }
                    default: {
                        let blob = new Blob([att.content.buffer])
                        const url = window.URL.createObjectURL(blob);
                        const link = document.createElement('a');
                        link.href = url;
                        link.target = "_blank"
                        if (att.fileName !== null)
                            link.setAttribute('download', att.fileName || 'attachment'); //or any other extension
                        document.body.appendChild(link);
                        link.click();
                        this.$refs.att[index].loading = false;
                    }
                }
            }
        },

        inception(messageData) {
            this.fileSrc = true
            this.messageData = messageData
            this.loadingMessage = false
            this.resetBodyView()
        },


        iframeReset(frame) {



            function addStyle(html) {
                const styleData = `
                    body { font-family: sans-serif; zoom: 90%;} body::-webkit-scrollbar-track { background-color: #ededed; }
                    body::-webkit-scrollbar { width: 8px; height: 8px; background-color: #F5F5F5; }
                    body::-webkit-scrollbar-thumb { background-color: #00897B; }`

                if (html.indexOf('<head>') === -1) {
                    return `<html>
<head>
<meta charset="UTF-8"/>
<style>${styleData}</style>
</head>
<body>
${html}
</body>
</html>`
                } else {
                    return html.replace(/<head>/g,`<head><meta charset="UTF-8"/><style>${styleData}</style>`)
                }
            }

            try {
                // this.$refs.viewFrame.srcdoc = addStyle(this.messageData.html || this.messageData.textAsHtml);
                // document.getElementById(frame).srcdoc = this.printing ? '<h1>PRINTING</h1>' : addStyle(this.messageData.html || this.messageData.textAsHtml);
                this.$refs.viewFrame.srcdoc = addStyle(this.messageData.html || this.messageData.textAsHtml);
                // this.printHtml = this.messageData.html || this.messageData.textAsHtml TODO HERE
            } catch (e) {
                // Not An Error Here
            } finally {
                this.$nextTick(() => {
                    this.$emit('ready')
                })
            }
        },

        resetBodyView() {
            this.$nextTick(() => {
                this.iframeReset('viewFrame')
            })
        },

        reloadMessage() {
            this.messageData = null;
            this.loadingMessage = true;

            this.fetch({method: 'POST', url:`/eml/${this.userInfo.entityID}`, body: {filename: this.filename, route: this.smtpCache ? 'smtp' : (this.smtpMailboxCache ? 'mailbox' : (this.cache ? 'cache' : 'store')), full: false}})
                    .then((data) => {
                        this.messageData = data
                        this.loadingMessage = false
                        this.resetBodyView()
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e)
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                        this.loadingMessage = false;
                    });
        },
        // downloadMessage() {
        //     this.messageData = null;
        //     this.loadingMessage = true;
        //
        //     this.$newReqDownloadFile(
        //             'POST',
        //             `/eml/${this.userInfo.entityID}`,
        //             'Test.eml',
        //             'test.eml',
        //
        //     )
        //
        //     this.fetch({method: 'POST', url:`/eml/${this.userInfo.entityID}`, body: {filename: this.filename, route: this.smtpCache ? 'smtp' : (this.smtpMailboxCache ? 'mailbox' : (this.cache ? 'cache' : 'store')), full: false}})
        //             .then((data) => {
        //                 this.messageData = data
        //                 this.loadingMessage = false
        //                 this.resetBodyView()
        //             })
        //             .catch((e) => {
        //                 // eslint-disable-next-line
        //                 console.log(e)
        //                 this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
        //                 this.loadingMessage = false;
        //             });
        // }
    },
    computed: {
        ...mapGetters('userInfo', ['userInfo']),

        attList() {
            // return [...this.messageData.attachments].sort((a,b) => a-b)
          
            return this.messageData.attachments
                .filter(att => {
                  if (this.showEmbedded)
                    return true
                  
                  if ((att.attName || att.fileName || '').includes('.blob'))
                    return false
                  
                  return att.contentDisposition === "attachment"
                })
                .sort((a,b) => a-b)
        },

        dateStr() {
            return format(parse(this.messageData.date), 'D MMM YYYY h:mm A')
        },
        fromStr() {
            return this.messageData.from ? this.messageData.from
                    .map(val => {
                        return val.name
                                ? `<strong>${val.name}</strong> (${val.address})`
                                : `<strong>${val.address}</strong>`
                    })
                    .join(', ')
                    : 'No Address Specified'
        },
        toStr() {
            return this.messageData.to
                    .map(val => {
                        return val.name
                                ? `<strong>${val.name}</strong> (${val.address})`
                                : `<strong>${val.address}</strong>`
                    })
                    .filter((obj, i) => this.fullTo ? true : i <= 2)
                    .join(', ')
        },
        ccStr() {
            return this.messageData.cc
                    .map(val => {
                        return val.name
                                ? `<strong>${val.name}</strong> (${val.address})`
                                : `<strong>${val.address}</strong>`
                    })
                    .filter((obj, i) => this.fullCc ? true : i <= 2)
                    .join(', ')
        },
        bccStr() {
            return this.messageData.bcc
                    .map(val => {
                        return val.name
                                ? `<strong>${val.name}</strong> (${val.address})`
                                : `<strong>${val.address}</strong>`
                    })
                    .filter((obj, i) => this.fullBcc ? true : i <= 2)
                    .join(', ')
        },
    }
}
</script>

<style scoped>
    .showMore:hover{
        color: var(--v-primary-base);
        font-weight: bold;

    }

    .print-view {
        min-height: 100vh;
        min-width: 100vw;
        background: white;
    }

    /*@media print {*/
    /*    .print-view {*/
    /*        width: auto;*/
    /*        height: auto;*/
    /*        overflow: visible !important;*/
    /*    }*/
    /*}*/

    /** { overflow: visible !important; }*/

</style>