<template>
    <div class="py-1 px-1">
            <v-card
                    @click="$emit('input', event)"
                    hover
                    :class="(groupCalColor || event.color) || 'blue'"
                    class="darken-2 white--text c-d-flex c-align-center"
                    :width="290"
                    ripple
                    style="position: relative"
            >

                <div class="py-1 px-1" style="line-height: 1; width: 100%">
                    <div class="c-d-flex c-align-center">
                        <div>
                            <v-icon
                                    class="ma-1 pr-1"
                                    white--text
                                    color="white"
                            >
                                {{ event.icon || 'event' }}
                            </v-icon>
                        </div>

                        <div class="c-flex-grow-1">
                            {{ event.title }}
                            <div class="caption" style="line-height: 1; padding-top: 3px">
                                {{ event.shortTime }}{{ event.durationStr ? ` - ${event.durationStr}` : '' }}
                            </div>
                            <div class="caption text--lighten-4" style="line-height: 1; padding-top: 3px" :class="`${groupCalColor || event.color}--text` || 'blue--text'" v-if="event.fkFolder || event.fkTask">
                                <template v-if="event.fkTask">
                                    #{{ event.fkTask }}
                                    <!--                        {{ taskRef ? ` - ${taskRef.title}` : 'Loading Title...' }}-->
                                </template>
                                <template v-if="event.fkFolder">
                                    {{ event.folderRef.description }}
                                </template>
                            </div>
                        </div>

                        <v-tooltip top v-if="event.done">
                            <v-icon
                                    class="mb-1 ml-1 pa-1 green elevation-5"
                                    color="white"
                                    slot="activator"
                                    style="border-radius: 50%"
                            >
                                done_all
                            </v-icon>
                            {{event.doneRef.user.fullName}} - {{event.doneRef.dateStr}}
                        </v-tooltip>


                    </div>

                        <div v-if="event.fkOwner !== userInfo.userID" style="max-width: 100%">
                            <dense-chip
                                    :label="event.ownerRef.fullName"
                                    icon="person"
                                    :color="event.color || 'blue'"
                                    :max-chars="35"
                            />
                        </div>
                        <div v-if="event.fkCalGroup" :class="event.fkOwner !== userInfo.userID ? 'pt-1' : null">
                            <dense-chip :label="event.groupCalendarRef.description" :color="event.groupCalendarRef.color || 'blue'" icon="groups" :max-chars="35"/>
                        </div>
                </div>
            </v-card>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import DenseChip from "@/components/General/DenseChip";

export default {
    components: {DenseChip},
    props: {
        event: Object,
        task: Object,
    },
    data() {
        return {
            taskRef: null,
        }
    },
    watch: {
        // task: {
        //     immediate: true,
        //     handler(val) {
        //         if (!val && this.event.fkTask && !this.taskRef) {
        //             this.getTaskHeader(this.event.fkTask)
        //                     .then(task => {
        //                         this.taskRef = task
        //                     })
        //                     .catch(e => {console.log(e)})
        //         }
        //     }
        // }
    },
    methods: {
    },
    computed: {
        ...mapGetters(['getTaskHeader']),
        ...mapGetters('userInfo', ['userInfo']),
        hasTask() {
            return !!this.event.fkTask
        },
        groupCalColor() {
            return this.event.fkCalGroup ? this.event.groupCalendarRef.color : null
        }
    },
}
</script>

<style scoped>

</style>