<template>
    <div>

        <v-btn small flat color="secondary" class="ma-0" @click="configuringField = !configuringField">Configure Sms Field</v-btn>

        <app-input-dlg
                :show-dialog="configuringField"
                title="Configure Sms Field"
                :cancel-button="true"
                :show-input-box="false"
                @dismiss="configuringField = false"
                :body-padding="false"
                :width="500"
        >
            <template slot="body">
                <div class="pa-1" style="max-width: 800px">
<!--Message To-->
                    <div class="pa-1">
                        <div class="caption greyType--text font-weight-bold">
                            Message To Type:
                        </div>
                        <div class="pl-2">
                            <v-autocomplete
                                    dense
                                    v-model="localLookup.toType"
                                    :items="[{id: 0, description: 'User Input'}, {id: 1, description: 'Workflow Field'}, {id: 2, description: 'Dataset Field'}, {id: 3, description: 'Specific Number'}]"
                                    label="Message To Type"
                                    hide-details
                                    single-line
                                    color="primary"
                                    item-text="description"
                                    item-value="id"
                                    class="pa-0 ma-0"
                            ></v-autocomplete>
                        </div>
                    </div>
<!--Message To Type 1 Workflow Field-->
                    <div class="pa-1" v-if="localLookup.toType === 1">
                        <div class="caption greyType--text font-weight-bold">
                            Select Workflow Field:
                        </div>
                        <div class="pl-2">
                            <v-autocomplete
                                    dense
                                    v-model="localLookup.toWorkflowFieldID"
                                    :items="workflowFieldType1s"
                                    label="Select Workflow Field"
                                    hide-details
                                    single-line
                                    color="primary"
                                    item-text="name"
                                    item-value="id"
                                    class="pa-0 ma-0"
                            >
                                <template v-slot:item="data">
                                    <template v-if="typeof data.item !== 'object'">
                                        <v-list-tile-content v-text="data.item" class="font-weight-bold"></v-list-tile-content>
                                    </template>
                                    <template v-else>

                                        <v-layout row align-center>
                                            <v-flex shrink>
                                                <v-icon
                                                        cursor="pointer"
                                                        color="primary"
                                                        small
                                                        left
                                                >
                                                    {{data.item.id === localLookup.toWorkflowFieldID ? 'check_box' : 'check_box_outline_blank'}}
                                                </v-icon>
                                            </v-flex>
                                            <v-flex xs12>{{data.item.name}}</v-flex>
                                        </v-layout>
                                    </template>
                                </template>
                            </v-autocomplete>
                        </div>
                    </div>
<!--Message To Type 2 Dataset Field-->
                    <div class="pa-1" v-if="localLookup.toType === 2">
                    <div class="caption greyType--text font-weight-bold">
                        Select Dataset Field:
                    </div>
                    <div class="pl-2">
                        <app-field-set-field-selector
                                v-model="localLookup.toDatasetField"
                                :fields="fields"
                                :field-sets="fieldSets"
                                :field-type-filter="[1]"
                                :include-auto-populate="false"
                        />
                    </div>
                </div>
<!--Message To Type 3 Specific Number-->
                    <div class="pa-1" v-if="localLookup.toType === 3">
                        <div class="caption greyType--text font-weight-bold">
                            Specific Number:
                        </div>
                        <div class="pl-2">
                            <v-text-field
                                    dense
                                    v-model="localLookup.toSpecificNumber"
                                    label="Enter Cellphone Number"
                                    hide-details
                                    single-line
                                    color="primary"
                                    class="pa-0 ma-0"
                                    :mask="localLookup.toSpecificNumber == null || localLookup.toSpecificNumber.length <= 10 ? '### ### #####' : '## ## ### ####'"
                            ></v-text-field>
                        </div>
                    </div>
<!--Max Message Parts-->
                    <div class="pa-1">
                        <div class="caption greyType--text font-weight-bold">
                            Max Message Parts:
                        </div>
                        <div class="pl-2">
                            <v-text-field
                                    dense
                                    v-model="localLookup.maxMessageParts"
                                    label="Max Message Parts"
                                    hide-details
                                    single-line
                                    color="primary"
                                    class="pa-0 ma-0"
                                    type="number"
                            ></v-text-field>
                        </div>
                    </div>
<!--Message Type-->
                    <div class="pa-1">
                        <div class="caption greyType--text font-weight-bold">
                            Message Type:
                        </div>
                        <div class="pl-2">
                            <v-autocomplete
                                    dense
                                    v-model="localLookup.type"
                                    :items="[{id: 0, description: 'Template'}, {id: 1, description: 'User Input'}]"
                                    label="Message Type"
                                    hide-details
                                    single-line
                                    color="primary"
                                    item-text="description"
                                    item-value="id"
                                    class="pa-0 ma-0"
                            ></v-autocomplete>
                        </div>
                    </div>

<!--Configure SMS Template-->
                    <div class="pa-1" v-if="localLookup.type === 0">
                        <div class="caption greyType--text font-weight-bold">
                            Configure Template:
                        </div>
                        <div class="px-2 py-1">
                            <v-btn small outline block color="secondary" class="ma-0" @click="showConfigureTemplate = !showConfigureTemplate">Configure SMS Template</v-btn>
                        </div>
                    </div>

                    <v-divider></v-divider>
                    <div class="text-xs-right pa-0">
                        <v-btn
                                color="secondary"
                                @click="doneRun()"
                                small
                                flat
                                class="pa-0"
                        >Done</v-btn>
                    </div>

                    <sms-template-editor
                            v-model="localLookup.templateLookup"
                            :user-info="userInfo"
                            :shown="showConfigureTemplate"
                            :steps="steps"
                            :field-sets="fieldSets"
                            :fields="fields"
                            @dismiss="showConfigureTemplate = false"
                    />
                </div>
            </template>
        </app-input-dlg>


    </div>
</template>

<script>
    import InputDlg from "../../../../../../../../components/General/InputDlg";
    import {codeBaseMixin} from "../../../../../../../../codeBaseMixin";
    import WorkflowSelect from '../FieldType10Components/WorkflowSelect';
    import FieldsSelect from "../FieldType10Components/FieldsSelect";
    import FolderDlg from '../../../../../../../../Folders/FolderDlg';
    import WorkflowManagerGroupSelector from '../../WorkflowManagerGroupSelector'
    import FieldSetFieldSelector from "../../FieldSetFieldSelector";
    import SmsTemplateEditor from "./SmsTemplateEditor";

    export default {
        mixins: [codeBaseMixin],
        components: {
            SmsTemplateEditor,
            appInputDlg: InputDlg,
            appFieldSetFieldSelector: FieldSetFieldSelector
            // appWorkflowManagerGroupSelector: WorkflowManagerGroupSelector,
            // appFolderDlg: FolderDlg,
            // appFieldsSelect: FieldsSelect,
            // appInputDlg: InputDlg,
            // appWorkflowSelect: WorkflowSelect
        },
        props: {
            userInfo: Object,
            value: String,
            localData: Object,
            steps: Array,
            fieldSets: Array,
            fields: Array,
        },
        data() {
            return {
                showConfigureTemplate: false,
                configuringField: false,
                localLookup: {
                    type: 1, // 0-Template; 1-UserInput
                    maxMessageParts: 3, // Default Is 3
                    toType: 0, // 0-UserInput; 1-WorkflowField; 2-DatasetField; 3-SpecificNumber
                    templateLookup: "",
                    toWorkflowFieldID: null,
                    toDatasetField: null,
                    toSpecificNumber: null
                },
            }
        },
        mounted() {
            this.fillLookup();
        },
        watch: {
            value: {
                immediate: true,
                handler(val) {
                    //this.fillLookup();
                }
            },
        },
        methods: {

            doneRun() {
                this.$emit('input', JSON.stringify(this.localLookup).replace(/"/g,'\\"'));
                this.configuringField = false;
            },

            fillLookup() {
                if (!this.blankString(this.value)) {
                    this.localLookup = JSON.parse(this.value.replace(/\\"/g,'"'));
                }
            }

        },
        computed: {

            workflowFieldType1s() {
                let fields = this.fields.filter(obj => obj.fieldType == 1).sort((a,b) => a['field_order'] < b['field_order'] ? -1 : 1);

                let fieldSteps = fields.map(obj => {return obj.fk_step})

                let steps = this.steps.filter(obj => fieldSteps.includes(obj.id)).sort((a,b) => a['field_order'] < b['field_order'] ? -1 : 1);
                return steps.reduce((returnList, step) => {
                    returnList.push({
                        header: step.description
                    });
                    returnList.push({ divider: true })
                    returnList = returnList.concat(fields.filter(obj => obj.fk_step == step.id).map(obj => {return {
                        name: obj.description,
                        id: obj.id
                    }}));
                    returnList.push({ divider: true })
                    return returnList
                }, []);
            },
        }
    }
</script>

<style scoped>

</style>
