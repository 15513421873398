<template>
  
  <div class="pt-1 input-box">
    <div class="v-label v-label--active master-title" :class="$store.state.darkTheme ? 'theme--dark' : 'theme--light'" style="font-size: 10.5px">Email Addresses</div>
    <v-form ref="addForm" v-model="validEmail" @submit.prevent="() => {}">
      <div class="c-d-flex align-center">
        <v-text-field
            class="mt-0 pt-0"
            single-line
            style="width: 100%"
            placeholder="Add Email Address"
            v-model="addStr"
            hide-details
            :rules="[validationRules.email]"
            ref="field"
            @keydown.enter="addAddress"
            @blur="!$event.target.value ? $refs.addForm.resetValidation() : () => {}"
        ></v-text-field>
        <v-btn
            icon
            color="primary"
            :disabled="!validEmail"
            small
            @click="addAddress"
            class=" mr-0"
        >
          <v-icon>add</v-icon>
        </v-btn>
      </div>
    </v-form>
    
    <v-list
       :dense="true"
       v-if="Array.isArray(value) && value.length"
    >
      <v-list-tile
          v-for="address in (value || [])"
          @click="() => {}"
          class="py-0 my-0"
      >
        <v-list-tile-avatar :tile="true" :size="24"><v-icon>email</v-icon></v-list-tile-avatar>
        <v-list-tile-content>
          <v-list-tile-title>{{address}}</v-list-tile-title>
        </v-list-tile-content>
        <v-list-tile-action>
          <v-btn @click.stop="() => removeAddress(address)" small flat icon color="error">
            <v-icon>close</v-icon>
          </v-btn>
        </v-list-tile-action>
        
        <v-list-tile-action>
          
          <v-tooltip left>
            <template #activator="{on}">
              <v-btn v-on="on" @click.stop="copyStuff(address)" small flat icon color="primary">
                <v-icon>content_copy</v-icon>
              </v-btn>
            </template>
            Copy
          </v-tooltip>
          
          
        </v-list-tile-action>
      </v-list-tile>
    </v-list>
    
  </div>

</template>

<script>
import {copyText, validationRules} from "@/codeFunctions";

export default {
  props: {
    value: Array,
  },
  data() {
    return {
      addStr: null,
      validationRules,
      validEmail: false
    }
  },
  methods: {
    copyText,
    copyStuff(str) {
      this.copyText(document, str);
      this.$snack.info(str + ' Copied')
    },
    removeAddress(str) {
      this.$emit('input', (this.value || []).filter(val => val !== str))
    },
    addAddress() {
      const validation = validationRules.email(this.addStr)
      
      if (validation !== true)
        return this.$snack.error(validation)
      
        this.$emit('input', (this.value || []).concat(this.addStr))
        this.addStr = null;
        this.$refs.field.focus()
        this.$refs.addForm.resetValidation()
    }
  },
  
}
</script>

<style scoped>
.input-box:hover .master-title,
.input-box:focus-within .master-title {
  //font-weight: bold;
  color: var(--v-primary-base);
}
</style>