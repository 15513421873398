<template>
    <div>
        <template v-if="showLoader">
            <v-list-tile class="text-xs-center">
                <v-spacer></v-spacer>
                <v-progress-circular
                        :size="40"
                        :width="5"
                        color="primary"
                        indeterminate
                ></v-progress-circular>
                <v-spacer></v-spacer>
            </v-list-tile>
        </template>
        <template v-if="messages.length != 0 && !showLoader" >
            <template v-for="(message, index) in messages">

                <v-card
                        :key="message.gmailID"
                        flat
                        @click="$emit('messageSelected', message)"
                        @drag="drag(message)"
                        draggable="true"
                        style="background: var(--page-back)"
                >
                    <v-layout
                            row
                            align-center
                            style="overflow-x: hidden; cursor: pointer"
                            :class="messageClassFn(message)"
                            @click="$emit('selectMessage', message);"
                            class="py-1"
                    >
                        <v-flex shrink>
                            <!--Message Star-->
                            <div>
                                <v-tooltip top>
                                    <v-icon
                                            @click="message.labelIds.includes('STARRED') ? unstarFn(message) : starFn(message.gmailID)"
                                            class="px-1"
                                            style="cursor: pointer"
                                            :color="message.labelIds.includes('STARRED') ? 'amber' : '#D6D6D6'"
                                            slot="activator"

                                    >
                                        <!--:class="message.starred ? 'red&#45;&#45;text' : (typeof selectedMessage != 'undefined' && selectedMessage != null && selectedMessage.id == message.id ? 'white&#45;&#45;text' : 'grey&#45;&#45;text text&#45;&#45;lighten-2')"-->
                                        {{ message.labelIds.includes('STARRED') ? 'star' : 'star_border'}}
                                    </v-icon>
                                    <span>{{ message.starred ? 'Unstar Message' : 'Star Message' }}</span>
                                </v-tooltip>
                            </div>
                            <!--Linked To Task-->
                            <div v-if="message.tasks && Array.isArray(message.tasks) && message.tasks.length > 0">
                                <v-tooltip bottom>
                                    <v-icon
                                            color="primary"
                                            slot="activator"
                                            class="px-1"
                                    >
                                        assignment_turned_in
                                    </v-icon>
                                    <span>Mail linked to Tasks</span>
                                </v-tooltip>
                            </div>
                        </v-flex>

                        <v-flex xs12 style="overflow-x: hidden">
                            <v-layout column>
                                <v-flex shrink style="overflow-x: hidden">
                                    <v-layout row align-center>
                                        <v-flex
                                            xs12
                                            class="subheading px-1 text-truncate"
                                            style="white-space: pre; overflow-x: hidden" v-if="Array.isArray(message.from) && message.from.length >= 1"
                                            :style="message.labelIds.includes('UNREAD') ? 'font-weight: 500' : null"
                                        >
                                            {{ message.from.map(obj => {return obj.name || obj.address}).join(', ') }}
                                        </v-flex>
                                        <v-flex shrink class="pr-1">
                                    <span style="white-space: nowrap" class="greyType--text caption">
                                        {{ message.displayDate }}
                                    </span>
                                        </v-flex>
                                    </v-layout>
                                </v-flex>
                                <v-flex shrink style="overflow-x: hidden">
                                    <v-layout row style="overflow-x: hidden" align-center>
                                        <v-flex xs12 style="overflow-x: hidden">
                                            <div style="overflow-x: hidden" class="text-truncate" v-if="message.subject != null && message.subject != ''">
                                                <span class="body-1" style="white-space: pre;">{{ message.subject }}</span>
                                            </div>
                                            <div style="overflow-x: hidden" class="text-truncate">
                                                <span class="caption greyType--text" style="white-space: pre;">{{ message.snippet }}</span>
                                            </div>
                                        </v-flex>
                                        <v-flex shrink>

                                                <v-icon
                                                        color="red"
                                                        style="cursor: pointer"
                                                        class="pa-1"
                                                        @click="archiveFn(message)"
                                                        v-if="message.labelIds.includes('INBOX')"
                                                >
                                                    archive
                                                </v-icon>
                                        </v-flex>
                                    </v-layout>
                                </v-flex>
                            </v-layout>
                        </v-flex>
                    </v-layout>
                    <v-progress-linear
                            v-if="message.showLoading"
                            indeterminate
                            height="3"
                            class="pa-0 ma-0"
                    ></v-progress-linear>
                    <v-divider></v-divider>
                </v-card>
            </template>
            <div v-if="nextPage !== undefined && !!nextPage.pageToken" class="text-xs-center">
                <v-btn
                        :loading="moreLoading"
                        :disabled="moreLoading"
                        @click="$emit('loadNextPage')"
                        color="secondary"
                        flat
                >
                    Load More
                </v-btn>
            </div>
        </template>

    </div>
</template>

<script>
    import { eventBus } from '../../../main';

    export default {
        props: {
            messages: Array,
            updateHTMLfn: Function,
            nextPage: Object,
            starFn: Function,
            unstarFn: Function,
            archiveFn: Function,
            selectedMessage: Object
        },
        data() {
            return {
                showLoader: false,
                moreLoading: false
            }
        },
        created() {
            eventBus.$on('showMessageLoader', this.showMessageLoader)
            eventBus.$on('showMoreLoader', this.showMoreLoader)
            eventBus.$on('mailLinkedToTask', this.mailLinkedToTaskLocal)
        },
        beforeDestroy() {
            eventBus.$off('showMessageLoader', this.showMessageLoader)
            eventBus.$off('showMoreLoader', this.showMoreLoader)
            eventBus.$off('mailLinkedToTask', this.mailLinkedToTaskLocal)
        },
        methods: {

            showMessageLoader(value) {this.showLoader = value;},
            showMoreLoader(value) {this.moreLoading = value;},
            mailLinkedToTaskLocal(data) {this.$forceUpdate();},

            drag: function (message) {

                eventBus.$emit('gmailMessageDrag', message);
            },
            messageClassFn(message) {
                let sClass = message.labelIds.includes('UNREAD') ? 'unread' : 'read';
                if (this.selectedMessage != null) {
                    if (message.gmailID === this.selectedMessage.gmailID) {
                        sClass = 'messageSelected';
                    }
                }
                return sClass
            }
        }
    }
</script>

<style scoped>

    .messageSelected {
        border-left: 4px solid var(--v-secondary-base);
        background: var(--selected-item);
    }

    .read {
        border-left: 4px solid rgba(0,0,0,0);
        background: var(--hover-item-background);
    }

    .unread {
        border-left: 4px solid var(--v-primary-base);
    }

</style>
