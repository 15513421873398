<template>
    <v-layout row fill-height class="scrollable" style="overflow-x: hidden; background: var(--page-back)">
        <v-flex xs4 class="scrollable pa-1" style="overflow-x: hidden">
            <v-card class="scrollable fill-height">
                <mailbox-mail-bar
                        :selectedMailbox="selectedMailbox"
                        :loading-mail-i-ds="loadingMailIDs"
                        :selected-mail-i-ds="selectedMailIDs"
                        :mailboxes="mailboxes"
                        @selectMailbox="selectedMailbox = $event"
                        :loading-more="loadingMore"
                        :mail-vault="mailVault"
                        :deleted-view="archived"
                        :search-val="searchVal"
                        @bumpScroll="bump($event)"
                        @newSearch="search($event)"
                        @getMail="getMail($event)"
                        @resetMailView="resetMailView"
                        @deletedView="switchDeletedView"
                        @clearMailView="resetMailView"
                        :show-avatar="selectedMailbox.id === 0"
                />
            </v-card>
        </v-flex>
        <v-flex xs8 class="scrollable pl-0 py-1 pr-1" fill-height v-if="mailViewMail !== null">
            <v-card class="fill-height scrollable pa-1" v-if="mailViewMail !== null" style="background: var(--page-back)">
                <e-m-l-viewer :filename="mailViewMail.filename" :smtp-mailbox-cache="true" ref="emlView">
                    <template slot="metaTop">
                        <v-tooltip top v-if="typeof userInfo.createTask != 'undefined' && userInfo.createTask">
                            <v-btn
                                    icon
                                    dark
                                    small
                                    color="secondary"
                                    @click="$emit('createTaskSMTPMailbox', getSelectedMails())"
                                    slot="activator"
                                    class="pt-0 mx-1 mt-1"
                            >
                                <v-icon small>add</v-icon>
                            </v-btn>
                            <span>Create Task</span>
                        </v-tooltip>
<!--Tasks-->
                        <template v-if="typeof mailViewMail.tasks != 'undefined' && mailViewMail.tasks.length > 0">
                            <template v-for="task in mailViewMail.tasks">
                                <v-menu open-on-hover lazy offset-y nudge-left="150px" :key="task">
                                    <v-chip
                                            slot="activator"
                                            small
                                            label
                                            color="secondary"
                                            text-color="white"
                                    >
                                        <v-icon left small>assignment_turned_in</v-icon>
                                        Task #{{ task }}
                                    </v-chip>
                                    <div style="width: 400px; background: var(--card-back); cursor: pointer">
                                        <task-loading-container
                                                v-if="loadedTasks[task].loadingTask"
                                                :task-id="task"
                                        />
                                        <task-container
                                                v-else
                                                :userInfo="userInfo"
                                                :task="loadedTasks[task].task"
                                                :taskBar="false"
                                                :showNav="false"
                                                @taskSelected="taskSelected(loadedTasks[task].task)"
                                        />
                                    </div>
                                </v-menu>
                            </template>
                        </template>

                        <template v-if="typeof userInfo.createTask != 'undefined' && userInfo.createTask">
                            <v-tooltip
                                    top
                                    v-for="(qt, i) in [...userInfo.quickTasks].sort((a, b) => a.config.order - b.config.order)"
                                    :key="i"
                            >
                                <v-btn
                                        icon
                                        dark
                                        outline
                                        small
                                        color="secondary"
                                        @click="$emit('createTaskSMTPMailbox', {mails: getSelectedMails(), qt: qt.config})"
                                        slot="activator"
                                        class="pt-0 mx-1 mt-1 elevation-5"
                                >
                                    <v-icon small>{{ qt.config.icon }}</v-icon>
                                </v-btn>
                                <span>Create {{qt.config.description}} Task</span>
                            </v-tooltip>
                        </template>
                    </template>
                </e-m-l-viewer>
            </v-card>
        </v-flex>
    </v-layout>
</template>

<script>
import {mapGetters} from 'vuex'
import MailboxMailBar from "@/Mail/Mailboxes/MailboxMailBar";
import {arrPopulated, arrSplice, getInitials} from "@/codeFunctions";
import EMLViewer from "@/Mail/EML Viewer/EMLViewer";
import {feed} from "@/feedSocket";
import TaskLoadingContainer from "@/components/TaskContainer/TaskLoadingContainer";
import TaskContainer from "@/components/TaskContainer/TaskContainer";
import {eventBus} from "@/main";

export default {
    components: {TaskContainer, TaskLoadingContainer, EMLViewer, MailboxMailBar},
    props: {
        getTaskHeader: Function,
    },
    data() {
        return {
            selectedMailbox: null,
            searchVal: null,
            pageSize: 30,
            archived: false,
            mailVault: [],
            loadingMore: false,
            selectedMailIDs: [],
            loadingMailIDs: [],
            mailViewMail: null,
            loadedTasks: []
        }
    },
    created() {
        this.selectedMailbox = this.mailboxes[0];

        feed.on('SmtpMailBoxLink', (linkData) => {
            this.mailVault.forEach(vaultMail => {
                if (vaultMail.id === linkData.mail) {
                    if (!vaultMail.tasks.includes(linkData.task)) {
                        vaultMail.tasks.push(linkData.task);
                    }
                }
            })
        })

        feed.on('SmtpMailBoxDeleteFlag', (data) => {
            // Check That its Applicable to the view
            if (this.selectedMailbox.id === 0 || this.selectedMailbox.id === data.mailData.fk_mailbox) {
                if (!!data.mailData.deletedFlag === this.archived) {
                    // Different So Add The Mail To The Vault
                    if (!this.mailVault.map(vMail => {return vMail.id}).includes(data.mail).length) {
                        this.mailVault.splice(0,0, data.mailData)
                    }
                } else {
                    // Remove From The Valut
                    if (!!this.mailViewMail && this.mailViewMail.id === data.mail) {
                        this.resetMailView();
                    }
                    arrSplice(this.mailVault, mail => mail.id === data.mail);
                    if (this.selectedMailIDs.includes(data.mail)) {
                        arrSplice(this.selectedMailIDs, mailID => mailID === data.mail);
                        this.$snack.info(`Selected Mail Was ${data.val ? 'Restored' : 'Deleted'} By Another User`)
                    }
                }
            }
        })

        feed.on('SmtpMailBoxNew', (mail) => {
            if (!this.mailVault.map(vMail => {return vMail.id}).includes(mail.id)) {
                this.mailVault.splice(0,0, mail)
            }
        })
    },
    watch: {

        selectedMailIDs: {
            immediate: true,
            deep: true,
            handler(val) {
                this.$nextTick(() => {
                    this.$emit('selectedSmtpMailboxMails', this.getSelectedMails())
                })
            }
        },

        selectedMailbox: {
            immediate: true,
            handler(val) {
                if (val) {
                    this.minID = null;
                    this.searchVal = null;
                    this.archived = false
                    this.mailVault = [];
                    this.resetMailView();
                    this.resetMailSelections();
                    this.loadMoreMails()
                }
            }
        },

        "mailViewMail.tasks" : {
            immediate: true,
            deep: true,
            handler(val) {
                if (arrPopulated(val)) {
                    val.forEach(taskID => {
                        if (typeof this.loadedTasks[taskID] == 'undefined') {
                            this.$set(this.loadedTasks, taskID, {loadingTask: true, task: null});
                            this.getTaskHeader(taskID)
                                    .then((taskHeader) => {
                                        this.$set(this.loadedTasks, taskID, {loadingTask: false, task: taskHeader});
                                    })
                                    .catch((e) => {
                                        console.log(e)
                                    })
                        }
                    })
                }
            }
        },

    },
    methods: {

        taskSelected(task) {
            eventBus.$emit('gotoTask', task)
        },

        getSelectedMails() {
            return this.mailVault.filter(mail => this.selectedMailIDs.includes(mail.id))
        },

        resetMailSelections() {
            arrSplice(this.selectedMailIDs, () => true);
            arrSplice(this.loadingMailIDs, () => true);
        },

        switchDeletedView(val) {
            this.minID = null;
            this.searchVal = null;
            this.archived = val
            this.mailVault = [];
            this.loadMoreMails();
            this.resetMailView();
            this.resetMailSelections();
        },

        resetMailView() {
            this.loadingViewMail = false;
            this.mailViewMail = null;
        },

        getMail(mail) {
            this.resetMailView();
            this.lastMailIDLoad = mail.mailID;
            this.mailViewMail = mail;
            this.$newReq('get', `smtp/markAsReadMailbox/${this.userInfo.entityID}/${mail.mailID}`)
                    .catch((e) => {
                        console.log(e)
                        this.$snack.networkError()
                    })
            this.mailVault.forEach(vaultMail => {
                if (mail.mailID === vaultMail.mailID) {
                    mail.readFlag = 1
                }
            })
        },

        bump(data) {
            let pxPerCon = data.scrollHeight / this.viewMails.length;
            let lastConNo = (data.scrollTop + data.clientHeight) / pxPerCon;
            let cRem = this.viewMails.length - lastConNo;
            if (!this.loadingMore && cRem <= 5 && this.minIDViewMails > this.minID) {
                this.loadingMore = true;
                this.loadMoreMails()
            }
        },

        loadMoreMails() {
            this.loadingMore = true;
            this.$newReq(
                    'post',
                    `smtp/loadMailsMailbox/${this.userInfo.entityID}/${this.userInfo.userID}/${this.selectedMailbox.id}/${this.pageSize}/${this.minIDViewMails}/${this.archived ? 1 : 0}`,
                    {filterStr: this.searchVal}
            )
                    .then((data) => {
                        this.minID = data.minID;
                        this.addMailToVault(data.mails);
                        this.loadingMore = false;
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e)
                        this.$snack.networkError()
                        this.loadingMore = false;
                    })
        },

        addMailToVault(mails) {
            mails.forEach(mail => {
                let bAdded = false;
                this.mailVault.forEach(vaultMail => {
                    if (vaultMail.id === mail.id) {
                        Object.assign(vaultMail, mail);
                        bAdded = true;
                    }
                });
                if (!bAdded) {
                    this.mailVault.push(mail)
                }
            })
        },

        // TODO RESUME HERE
        search(str) {
            this.mailVault = [];
            this.loadingMailIDs = [];
            this.selectedMailIDs = [];
            this.minID = 0;
            this.searchVal = str;
            this.loadMoreMails();
        },

    },
    computed: {
        ...mapGetters('userInfo', ['userInfo']),
        mailboxes() {
            return [{
                id: 0,
                description: 'Combined Mail'
            }].concat(
                    [...this.userInfo.mailboxes]
                            .map(obj => {
                                return {
                                    ...obj,
                                    initials: getInitials(obj.description),
                                }
                            })
                            .sort((a,b) => a.description > b.description ? 1 : -1)

            )
        },

        viewMails() {
            return this.mailVault.filter(() => true).sort((a,b) => a['id'] < b['id'] ? -1 : 1);
        },

        minIDViewMails() {
            return this.viewMails.reduce((id, mail) => {
                return id === null || mail.id < id ? mail.id : id
            }, null)
        }
    },
}
</script>

<style scoped>

</style>