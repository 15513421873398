<template>
    <v-layout row align-center>

        <template v-if="loadingUsers">
            <v-flex>
                <v-btn
                        icon
                        color="primary"
                        flat
                        class="pa-0 ma-0"
                        :loading="true"
                ></v-btn>
            </v-flex>
        </template>

        <template v-if="!loadingUsers && allSharedUsers.length > 0">

            <!--Make Some Circles-->
<!--            <template v-for="(shareUser, i) in allSharedUsers">-->
<!--                <v-flex shrink class="pr-1" v-if="i <= 3">-->
<!--                    <v-tooltip top>-->
<!--                        <v-btn-->
<!--                                icon-->
<!--                                color="primary"-->
<!--                                class="pa-0 ma-0"-->
<!--                                slot="activator"-->
<!--                                small-->
<!--                                @click="showShareDlg = true"-->
<!--                        >-->
<!--                            <span class="caption">{{ getInitials(shareUser.name) }}</span>-->
<!--                        </v-btn>-->
<!--                        <span>{{ shareUser.name }}</span>-->
<!--                    </v-tooltip>-->
<!--                </v-flex>-->
<!--            </template>-->

<!--            &lt;!&ndash;Add Users if less than 4&ndash;&gt;-->
<!--            <v-flex shrink class="pr-1" v-if="allSharedUsers.length <= 4">-->
<!--                <v-tooltip top>-->
<!--                    <v-btn-->
<!--                            icon-->
<!--                            color="primary"-->
<!--                            class="pa-0 ma-0"-->
<!--                            slot="activator"-->
<!--                            small-->
<!--                            flat-->
<!--                            @click="showShareDlg = true"-->
<!--                    >-->
<!--                        <v-icon>person_add</v-icon>-->
<!--                    </v-btn>-->
<!--                    <span>Share With More Users</span>-->
<!--                </v-tooltip>-->
<!--            </v-flex>-->

<!--            <v-flex shrink class="pr-1" v-else>-->
<!--                <v-tooltip top>-->
<!--                    <v-btn-->
<!--                            icon-->
<!--                            color="primary"-->
<!--                            class="pa-0 ma-0"-->
<!--                            slot="activator"-->
<!--                            small-->
<!--                            flat-->
<!--                            @click="showShareDlg = true"-->
<!--                    >-->
<!--                        {{ `+${allSharedUsers.length - 4}` }}-->
<!--                    </v-btn>-->
<!--                    <span>{{ `+${allSharedUsers.length - 4} Others` }}</span>-->
<!--                </v-tooltip>-->
<!--            </v-flex>-->

            <v-flex shrink class="pr-1">
                <v-tooltip top>
                    <v-btn
                        :disabled="softBlock"
                        icon
                        color="primary"
                        class="pa-0 ma-0"
                        slot="activator"
                        small
                        flat
                        @click="showShareDlg = true"
                    >
                        <v-icon>share</v-icon>
                        <div
                            style="position: absolute; left: 0px; top: -5px"
                            class="caption secondary--text font-weight-black"
                        >
                            {{allSharedUsers.length}}
                        </div>
                    </v-btn>
                    <span>Task Share</span>
                </v-tooltip>
            </v-flex>
        </template>

        <v-dialog v-model="showShareDlg" persistent fullscreen v-if="showShareDlg">
            <v-card flat class="fill-height" style="background: var(--page-back)">
            <v-layout column fill-height class="scrollable">
                <v-flex shrink>
                    <v-card-title class="pa-2 pl-3 primary">
                        <span class="subheading font-weight-light white--text">Task Access</span>
                        <v-spacer></v-spacer>
                        <v-btn
                                small
                                icon
                                flat
                                color="white"
                                class="pa-0 ma-0"
                                @click="showShareDlg = false"
                        >
                            <v-icon>close</v-icon>
                        </v-btn>
                    </v-card-title>
                </v-flex>
                <v-divider></v-divider>
                <v-flex xs12 class="scrollable">
                    <template>

                        <v-layout row align-center fill-height class="scrollable">
                            <v-flex xs3 class="scrollable" fill-height>
                                <v-layout column fill-height class="scrollable">
                                    <v-flex shrink class="primary white--text body-1 pa-2">
                                        Users With Access
                                    </v-flex>
                                    <v-flex xs12 class="scrollable">
                                        <div v-for="user in allSharedUsers" :key="`SharedWith:${user.id}`" class="pa-1">
                                            <v-card hover class="pa-2">
                                                <v-layout row align-center>
                                                    <v-flex shrink class="pr-1">
                                                        <v-btn
                                                                icon
                                                                color="primary"
                                                                class="pa-0 ma-0"
                                                                slot="activator"
                                                                small
                                                        >
                                                            {{ getInitials(user.name) }}
                                                        </v-btn>
                                                    </v-flex>
                                                    <v-flex xs12 class=" pl-1 body-1">
                                                        {{ user.id == task.ownerID ? `${user.name} (Owner)` : user.name }}
                                                    </v-flex>
                                                    <v-flex shrink class="pl-1" v-if="taskShareUsers.includes(user.id) && user.id != task.ownerID && !accessUsers.map(obj => {return obj.id}).includes(user.id) && shareAdmin">
                                                        <v-tooltip top>
                                                            <v-btn
                                                                    icon
                                                                    color="red"
                                                                    class="pa-0 ma-0"
                                                                    slot="activator"
                                                                    small
                                                                    flat
                                                                    :loading="removeUserID == user.id"
                                                                    :disabled="removeUserID == user.id"
                                                                    @click="removeShare(user)"
                                                            >
                                                                <v-icon>
                                                                    close
                                                                </v-icon>
                                                            </v-btn>
                                                            <span>Remove User</span>
                                                        </v-tooltip>
                                                    </v-flex>
                                                </v-layout>
                                            </v-card>
                                        </div>
                                    </v-flex>
                                </v-layout>
                            </v-flex>
                            <v-divider vertical></v-divider>
                            <v-flex xs9 class="scrollable" fill-height>
                                <v-layout column fill-height class="scrollable">
                                    <v-flex shrink class="primary white--text body-1">


                                        <v-layout row align-center>
                                            <v-flex xs12 class="pa-2">
                                                Add Additional Users
                                            </v-flex>
                                            <v-flex shrink>
                                                <div style="width: 300px">
                                                    <v-text-field
                                                            label="Filter Users"
                                                            single-line
                                                            dark
                                                            color="white"
                                                            hide-details
                                                            style="width: 100%"
                                                            class="pa-0 ma-0"
                                                            prepend-inner-icon="search"
                                                            v-model="usersFilterString"
                                                    />
                                                </div>
                                            </v-flex>
                                        </v-layout>
                                    </v-flex>
                                    <v-flex xs12 class="scrollable">
                                        <v-layout row align-center wrap>
                                            <template v-for="(user, index) in availableUsersFiltered">
                                                <v-flex
                                                        :key="`SharedTitle:${user.id}`"
                                                        xs12
                                                        class="pb-1"
                                                        :class="index > 0 ? 'pt-2' : ''"
                                                        v-if="index === 0 ||
                                                                ((user.name != null && user.name !== '' ? user.name.substring(0,1).toUpperCase() : '-')
                                                                    !==
                                                                    (availableUsersFiltered[index -1].name != null && availableUsersFiltered[index -1].name !== '' ? availableUsersFiltered[index -1].name.substring(0,1).toUpperCase() : '-'))
"
                                                >
                                                    <div class="px-2 py-0 white--text headline primary lighten-2">
                                                        {{user.name != null && user.name !== '' ? user.name.substring(0,1).toUpperCase() : '-'}}
                                                    </div>
                                                </v-flex>
                                                <v-flex xs3 class="pa-1" :key="`SharedAdd:${user.id}`">
                                                    <v-card hover class="pa-2" @click="addShare(user.id)">
                                                        <v-layout row align-center>
                                                            <v-flex shrink class="pr-1">
                                                                <v-btn
                                                                        icon
                                                                        color="secondary"
                                                                        class="pa-0 ma-0"
                                                                        slot="activator"
                                                                        small
                                                                        :loading="addingUser.includes(user.id)"
                                                                        :disabled="addingUser.includes(user.id)"
                                                                >
                                                                    {{ getInitials(user.name) }}
                                                                </v-btn>
                                                            </v-flex>
                                                            <v-flex xs12 class=" pl-1 body-1">
                                                                {{ user.id == task.ownerID ? `${user.name} (Owner)` : user.name }}
                                                            </v-flex>
                                                        </v-layout>
                                                    </v-card>
                                                </v-flex>
                                            </template>
                                        </v-layout>
                                    </v-flex>
                                </v-layout>
                            </v-flex>
                        </v-layout>
                    </template>
                </v-flex>
            </v-layout>
            </v-card>
        </v-dialog>


    </v-layout>


</template>

<script>
    import {codeBaseMixin} from "../../../codeBaseMixin";
    import {blankString} from "../../../codeFunctions";
    import {mapGetters} from "vuex";

    export default {
        mixins: [codeBaseMixin],
        props: {
            userInfo: Object,
            task: Object
        },
        data() {
            return {
                workflowUsers: [],
                accessUsers: [],
                loadingUsers: false,

                showShareDlg: false,

                addShareUser: null,
                removeUserID: null,
                addingUser: [],
                usersFilterString: null
            }
        },
        mounted() {
            this.loadUsers();
        },
        watch: {
            task(newValue) {
                this.loadUsers()
            },
            showShareDlg() {
                this.usersFilterString = null
            }
        },

        methods: {

            addShare(addShareUserID) {
                this.addingUser.push(addShareUserID);
                this.fetch({method: 'GET', url:`/tasks/addTaskShare/${this.userInfo.entityID}/${this.task.taskID}/${addShareUserID}`})
                    .then(() => {
                        // this.task.sharedUsers = JSON.stringify(this.taskShareUsers.concat([this.addShareUser.id]));
                        this.showSnack('Info', 'User Added', 'Close', 'primary');
                        this.addingUser = this.addingUser.filter(obj => obj !== addShareUserID);
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e);
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
                        this.addingUser = this.addingUser.filter(obj => obj !== addShareUserID);
                    })
            },

            removeShare(user) {
                this.removeUserID = user.id;
                this.fetch({method: 'GET', url:`/tasks/removeTaskShare/${this.userInfo.entityID}/${this.task.taskID}/${user.id}`})
                    .then(() => {
                        this.task.sharedUsers = JSON.stringify(this.taskShareUsers.filter(obj => obj != user.id));
                        this.showSnack('Info', 'User Removed', 'Close', 'primary');
                        this.removeUserID = 0;
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e);
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
                        this.removeUserID = 0;
                    })
            },

            loadUsers() {
                this.loadingUsers = true;
                this.users = [];
                this.fetch({method: 'GET', url:`/tasks/getTaskShareInfo/${this.userInfo.entityID}/${this.task.taskID}`})
                    .then((data) => {
                        this.workflowUsers = data.workflowUsers;
                        this.accessUsers = data.accessUsers;
                        this.loadingUsers = false;

                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e);
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
                        this.loadingUsers = false;
                    })
            }
        },

        computed: {
            ...mapGetters("userInfo", ["softBlock"]),

            availableUsersFiltered() {
                return blankString(this.usersFilterString)
                    ? this.availableUsers
                    : this.availableUsers
                        .filter(obj => blankString(obj.name) ? false : obj.name.toLowerCase().includes(this.usersFilterString.toLowerCase()));
            },

            allSharedUsers() {
                let tUsers = [];
                tUsers.push({id: this.task.ownerID, name: this.task.ownerName + ' ' + this.task.ownerSurname});
                tUsers = tUsers.concat(this.accessUsers.filter(obj => obj.id != this.task.ownerID).sort((a,b) => a['name'] < b['name'] ? -1 : 1));
                return this.taskShareUsers.length > 0 ? tUsers.concat(this.taskShareUsers.map(obj => {let tUserNames = this.workflowUsers.filter(fobj => fobj.id == obj);if (tUserNames.length > 0) {return tUserNames[0]} else {return null}}).filter(obj => obj != null).filter(obj => !tUsers.map(uObj => {return uObj.id}).includes(obj.id)).sort((a,b) => a['name'] < b['name'] ? -1 : 1)) : tUsers;
            },

            taskShareUsers() {
                return this.task.sharedUsers != null && this.task.sharedUsers != '' && this.task.sharedUsers != '[]' ? (typeof this.task.sharedUsers == 'string' ? JSON.parse(this.task.sharedUsers) : this.task.sharedUsers) : []
            },

            availableUsers() {
                return this.workflowUsers.filter(obj => !this.allSharedUsers.map(mObj => {return mObj.id}).includes(obj.id)).sort((a,b) => a['name'] < b['name'] ? -1 : 1)
            },

            taskShareAdmins() {
                return this.task.shareAdminGroups != null && this.task.shareAdminGroups != '' && this.task.shareAdminGroups != '[]' ? (typeof this.task.shareAdminGroups == 'string' ? JSON.parse(this.task.shareAdminGroups) : this.task.shareAdminGroups) : []
            },

            shareAdmin() {
                if (this.intToBool(this.task.allowOwnerShare) && this.task.ownerID == this.userInfo.userID) {
                    return true
                } else {
                    return this.taskShareAdmins.filter(obj => this.userInfo.userGroups.includes(obj)).length > 0
                }
            }
        }
    }
</script>

<style scoped>

</style>
