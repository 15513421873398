import format from 'date-fns/format';
import isValid from 'date-fns/is_valid'
import differenceInMinutes from 'date-fns/difference_in_minutes';
import differenceInHours from 'date-fns/difference_in_hours';
import differenceInDays from 'date-fns/difference_in_days';
import differenceInMonths from 'date-fns/difference_in_months';
import parse from 'date-fns/parse';
import {eventBus} from "./main";
import addMonthsFns from 'date-fns/add_months';
import addDaysFns from 'date-fns/add_days';
import addHoursFns from 'date-fns/add_hours';
import addMinutesFns from 'date-fns/add_minutes';
import {mapGetters} from 'vuex'
import {liveAPI, serverAddress} from "@/axiosRequest";

let tokenMan = require('./tokenManSite')



export const codeBaseMixin = {

    data() {
        return {
            embeddedMimeTypes: ['application/png', 'image/jpeg', 'image/png'],
            viewableExtentions: ['xls', 'xlsx', 'doc', 'docx', 'gdoc', 'gsheet', 'gslides', 'pdf', 'txt', 'ppt', 'pptx'],
            topicClass: 'pa-1',
            headingClass: 'caption greyType--text font-weight-bold',
            itemClass: 'pl-2',
            originAddress: null,
            couchServerPath: null,
            pouchUser: null
        }




    },

    created() {
        this.originAddress = serverAddress
    },

    methods: {
        htmlToText(html, includeReplaceValues = false) {
            let fields = {
                userInput: [],
                workflow: [],
                dataset: [],
            };

            // Step 1 Strip out Fields

            //User input
            //`<div contenteditable="false" style="display: inline; border-radius: 3px" class="pa-1 white--text caption green" id="f-U-V-${description.split('"').join('')}">${description.split('"').join('')}</div>`
            let tUsrSplit = html.split('<div contenteditable="false" style="display: inline; border-radius: 3px" class="pa-1 white--text caption green" id="f-U')

            tUsrSplit = tUsrSplit.map(part => {
                // Check if Valid Tag
                if (!this.blankString(part) && part.length > 3 && part.substr(0,3) === '-V-') {
                    let fieldName = part.substring(3, part.indexOf('"'));
                    let postTag = part.substring(part.indexOf('</div>') + 6, part.length);

                    fields.userInput.push(fieldName);

                    if (includeReplaceValues) {
                        postTag = `{{${fields.userInput.length - 1}-${fieldName}}}` + postTag;
                    }

                    return postTag
                } else {
                    return part
                }
            });

            // Workflow Fields
            //`<div contenteditable="false" style="display: inline; border-radius: 3px" class="pa-1 white--text caption red" id="f-W-V-${item.id}">${item.description}</div>`
            let tWkfSplit = tUsrSplit.join('').split(`<div contenteditable="false" style="display: inline; border-radius: 3px" class="pa-1 white--text caption red" id="f-W`)
            tWkfSplit = tWkfSplit.map(part => {
                // Check if Valid Tag
                if (!this.blankString(part) && part.length > 3 && part.substr(0,3) === '-V-') {
                    let fieldName = part.substring(3, part.indexOf('"'));
                    let postTag = part.substring(part.indexOf('</div>') + 6, part.length);
                    fields.workflow.push(parseInt(fieldName));
                    if (includeReplaceValues) {
                        postTag = `{{W-${fieldName}}}` + postTag;
                    }
                    return postTag
                } else {
                    return part
                }
            })

            // Dataset Fields
            //`<div contenteditable="false" style="display: inline; border-radius: 3px" class="pa-1 white--text caption blue" id="f-D-V-1-5">Some Description</div>`
            let tDSSplit = tWkfSplit.join('').split(`<div contenteditable="false" style="display: inline; border-radius: 3px" class="pa-1 white--text caption blue" id="f-D`);
            tDSSplit = tDSSplit.map(part => {
                // Check if Valid Tag
                if (!this.blankString(part) && part.length > 3 && part.substr(0,3) === '-V-') {
                    let fieldName = part.substring(3, part.indexOf('"'));

                    let postTag = part.substring(part.indexOf('</div>') + 6, part.length);

                    fields.dataset.push({
                        dsSelectID: parseInt(fieldName.substring(0, fieldName.indexOf('-'))),
                        dsFieldID: parseInt(fieldName.substring(fieldName.indexOf('-') + 1, fieldName.length))
                    });

                    if (includeReplaceValues) {
                        postTag = `{{D-${fieldName}}}` + postTag;
                    }
                    return postTag
                } else {
                    return part
                }
            })

            let tBeforeTagReplace = tDSSplit.join('');

            //Split Out Tags
            let tAfterTags = tDSSplit.join('').split('<div>').join('\n').split('</div>').join('').split('<br>').join(`\n`).split('&nbsp;').join(' ');

            return {
                fields: fields,
                withTags: tBeforeTagReplace,
                afterTags: tAfterTags
            }
        },

        getOrAddElementFromArray(arr=[],key='',value=null,addElement) {
            try {
                let tItem = arr.reduce((obj, item) => {
                    if (obj == null) {
                        if (typeof item[key] != 'undefined' && item[key] === value) {
                            obj = item
                        }
                    }
                    return obj
                }, null);

                if (tItem == null) {
                    arr.push(addElement)
                    return addElement
                } else {
                    return tItem
                }
            } catch (e) {
                // eslint-disable-next-line
                return null
            }
        },
        waitForAddToAnArray(arr, itemKey, value) {
            return new Promise ((resolve, reject) => {
                try {
                    if (arr.map(obj => {return obj[itemKey]}).includes(value)) {
                        resolve(true)
                    } else {
                        setTimeout(() => {

                            return this.waitForAddToAnArray(arr, itemKey, value)
                        }, 100)
                    }
                } catch (e) {
                    // eslint-disable-next-line
                    console.log(e);
                    reject(e);
                }
            })
        },
        preventDefault: function (ev) {
            ev.preventDefault();
        },
        validDate(date) {
            return isValid(date);
        },
        boolToInt(boolVal) {
            if (boolVal) {
                return 1
            } else {
                return 0
            }
        },
        intToBool(intVal) {
            if (intVal == 1) {
                return true
            } else {
                return false
            }
        },
        getLongDate(date) {
            return format(date, 'Do MMMM YYYY  h:mm A')
        },
        getLongDateOnly(date) {
            return format(date, 'Do MMMM YYYY')
        },
        getShortDate(date) {
            return format(date, "D MMM YY")
        },
        getShortDateTime(date) {
            return format(date, "D MMM YYYY h:mm A")
        },
        getShortTime(date) {
            return format(date, "h:mm A")
        },
        getShortDateLongYear(date) {
            return format(date, "D MMM YYYY")
        },
        getSqlDate(date) {
            return format(date, 'YYYY-MM-DD')
        },
        dateAdd(date, interval, units) {
            if (!(date instanceof Date))
                return undefined;
            var ret = new Date(date); //don't change original date
            var checkRollover = function () {
                if (ret.getDate() != date.getDate()) ret.setDate(0);
            };
            switch (String(interval).toLowerCase()) {
                case 'year'   :
                    ret.setFullYear(ret.getFullYear() + units);
                    checkRollover();
                    break;
                case 'quarter':
                    ret.setMonth(ret.getMonth() + 3 * units);
                    checkRollover();
                    break;
                case 'month'  :
                    ret.setMonth(ret.getMonth() + units);
                    checkRollover();
                    break;
                case 'week'   :
                    ret.setDate(ret.getDate() + 7 * units);
                    break;
                case 'day'    :
                    ret.setDate(ret.getDate() + units);
                    break;
                case 'hour'   :
                    ret.setTime(ret.getTime() + units * 3600000);
                    break;
                case 'minute' :
                    ret.setTime(ret.getTime() + units * 60000);
                    break;
                case 'second' :
                    ret.setTime(ret.getTime() + units * 1000);
                    break;
                default       :
                    ret = undefined;
                    break;
            }
            return ret;
        },
        addDateTimeToDate(date, months, days, hours, minutes) {
           return addMonthsFns(addDaysFns(addHoursFns(addMinutesFns(date, minutes), hours), days), months)
        },
        getDateTimedifferance(date1, date2) {
            return {
                months: differenceInMonths(date1, date2),
                days: differenceInDays(date1, date2),
                hours: differenceInHours(date1, date2),
                minutes: differenceInMinutes(date1, date2),
            }
        },
        getDateTimedifferanceText(date1, date2) {
            let values = {
                months: differenceInMonths(date1, date2),
                days: differenceInDays(date1, date2),
                hours: differenceInHours(date1, date2),
                minutes: differenceInMinutes(date1, date2),
            }
            return values.months > 0 ? `${values.months} Months` : (values.days > 0 ? `${values.days} Days` : (values.hours > 0 ? `${values.hours} hours` : `${values.minutes} minutes`));
        },
        getEscalationColor(task, curTime) {
            if (task.workflowStatusValue == null) {
                return 'primary';
            } else {
                if (task.workflowStatusTTE == null) {
                    return 'green';
                } else {
                    let tte = new Date(task.workflowStatusTTE);

                    if (tte < curTime) {
                        return 'red';
                    } else {
                        return task.workflowStatusExtension == 1 ? 'blue' : 'green';
                    }
                }
            }
        },
        getSqlTimestampDate(date) {
            return format(date, 'YYYY-MM-DD HH:mm:ss')
        },
        getFormattedTime(date) {
            return format(date, 'HH:mm')
        },
        getSqlTime(date) {
            return format(date, 'HH:mm')
        },
        getDateFromTimestamp(timestamp) {
            return parse(timestamp)
        },
        getMinutesDifference(date1, date2) {
            return differenceInMinutes(date1, date2)
        },
        getDaysDifference(date1, date2) {
            return differenceInDays(date1, date2)
        },
        getInitials(string) {
            try {
                let arrWords = string.split(' ');
                let arrReturn = [];

                arrReturn.push(arrWords[0].substring(0,1).toUpperCase());

                arrReturn.push(arrWords[arrWords.length - 1].substring(0,1).toUpperCase());

                // arrWords.forEach((word) => {
                //     arrReturn.push(word.substring(0,1).toUpperCase())
                // });

                return arrReturn.join('');
            } catch (e) {
                console.log(e);
                return '';
            }
        },
        notBlank(value) {
            if (value != null && value != '') {
                return true
            } else {
                return false
            }
        },
        fetch(config, addAuth = true) {
            return new Promise((resolve, reject) => {

                if (addAuth ? tokenMan.valid() : true) {
                    let h = new Headers();
                    h.append('Content-Type', 'application/json');

                    if (addAuth) {
                        h.append('tl-token', tokenMan.token());
                        h.append('tl-user-id', this.userInfoSimple.userID);
                        h.append('tl-entity-id', this.userInfoSimple.entityID);
                    }

                    if (typeof config.body == 'object' && config.body != null) {
                        config.body = JSON.stringify(config.body)
                    }

                    let req = null
                    if (typeof config.body != 'undefined') {
                        req = new Request(this.serverAddress + config.url, {method: config.method, body: config.body, headers: h, mode: 'cors'});
                    } else {
                        req = new Request(this.serverAddress + config.url, {method: config.method, headers: h, mode: 'cors'});
                    }
                    let iStatus = 0;
                    fetch(req)
                        .then(async (response)=> {
                            if (response.ok == true) {
                                return response.text();
                            } else {
                                if (response.status != 399) {
                                    reject(await response.text())
                                } else {
                                    iStatus = response.status;
                                    return response.text()
                                }
                            }})
                        .then((jsonData) => {
                            if (iStatus != 399) {
                                try {
                                    resolve(JSON.parse(jsonData));
                                } catch (e) {
                                    // eslint-disable-next-line
                                    resolve(jsonData);
                                }
                            } else {
                                try {
                                    reject({
                                        status: iStatus,
                                        data: JSON.parse(jsonData)
                                    })
                                } catch (e) {
                                    reject({
                                        status: iStatus,
                                        data: jsonData
                                    })
                                }
                            }
                        })
                        .catch((err) => {
                            reject(err)
                        });
                } else {
                    reject('Token Has Expired')
                }

            });
        },
        showSnack(title, message, buttonText, titleColor) {
            eventBus.$emit('messageBox', {
                title: title,
                message: message,
                buttonText: buttonText,
                titleColor: titleColor
            })
        },
        isJson(stringValue, callback, defaultValue, ...conditionals) {
            try {
                if (conditionals.includes(false)) {return typeof defaultValue == 'undefined' ? null : defaultValue = null}
                let tObj = JSON.parse(stringValue);
                return typeof callback == 'undefined' ? tObj : callback(tObj);
            } catch (e) {
                return typeof defaultValue == 'undefined' ? null : defaultValue = null;
            }
        },
        isString(stringValue, callback, defaultValue, ...conditionals) {
            try {
                if (conditionals.includes(false)) {return typeof defaultValue == 'undefined' ? null : defaultValue = null}

                if (typeof stringValue == "string" && stringValue != null && stringValue != '') {
                    return typeof callback == 'undefined' ? stringValue : callback(stringValue);
                } else {
                    return typeof defaultValue == 'undefined' ? null : defaultValue = null
                }
            } catch (e) {
                return typeof defaultValue == 'undefined' ? null : defaultValue = null;
            }
        },
        isNotNull(value, callback, defaultValue, ...conditionals) {
            try {
                if (conditionals.includes(false) || value == null || typeof value == 'undefined') {return typeof defaultValue == 'undefined' ? null : defaultValue = null}
                return typeof callback == 'undefined' ? value : callback(value);
            } catch (e) {
                return typeof defaultValue == 'undefined' ? null : defaultValue = null;
            }
        },
        blankString(value) {
            return typeof value === "undefined" || value == null || value === '';
        },
        validateEmail(email) {
            // eslint-disable-next-line no-useless-escape
            var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

            if (typeof email == 'string') {
                return re.test(String(email).toLowerCase());
            } else {
                let bPass = true;
                email.forEach(address => {
                    if (re.test(String(address).toLowerCase()) == false) {
                        bPass = false;
                    }
                });
                return bPass
            }

        },
        log(item) {

        },
        encodeHtmlForJson(htmlString) {
            try {
                return htmlString.replace(/\\/g,"\\\\").replace(/"/g,"\\\"").replace(/\n/g, `\\\\n`).replace(/\r/g, `\\\\r`).replace(/\t/g, `\\\\t`);
            } catch (e) {
                return ""
            }
        },
        mapObject(object, key, callback) {
            try {
                let tObject = JSON.stringify(object);
                tObject = JSON.parse(tObject);
                tObject[key] = callback(tObject[key]);
                return tObject
            } catch (e) {
                // eslint-disable-next-line
                console.log(e);
                return null
            }
        },
        standardError(e) {
            console.log(e)
            this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
        },
        extractAddressesfromString(addressString) {
                try {

                    return addressString.split(',').join(';').split(';').map(obj => {return obj.trim()}).reduce((accumulator, address) => {

                        if (address.trim() != null && address.trim() != '') {
                            let tAddress = address.trim().split('<');
                            if (tAddress.length > 1) {
                                // Name And Surname
                                return accumulator.concat([{
                                    name: tAddress[0].trim().replace(/"/g,''),
                                    email: tAddress[1].replace(/>/g, '').trim()
                                }])
                            } else {
                                // Just An Address
                                return accumulator.concat([{
                                    name: '',
                                    email: tAddress[0].trim()
                                }])
                            }
                        } else {
                            return accumulator
                        }
                    }, []).map(address => {
                        address.display = address.name != null && address.name.trim() != "" ? `${address.name} <${address.email}>` : address.email;
                        return address;
                    }).sort((a,b) => a['display'] < b['display'] ? -1 : 1);
                } catch (e) {
                    // eslint-disable-next-line
                    console.log(e);
                    return [];
                }

        },
        getDaysOld(date) {
            let dNow = new Date();
            let tValue = this.getDaysDifference(dNow, date);
            return tValue
        },
        uuidv4() {
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
                var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
                return v.toString(16);
            });
        },
        treeify(list, idAttr, parentAttr, childrenAttr) {
            // idAttr = 'taskID';
            // parentAttr = 'fk_source_task';
            // childrenAttr = 'children';
            var treeList = [];
            var lookup = {};
            list.forEach(function (obj) {
                lookup[obj[idAttr]] = obj;
                obj[childrenAttr] = [];
            });

            list.forEach(function (obj) {



                if (obj[parentAttr] != null && obj[parentAttr] != '' && obj[parentAttr] != 0) {
                    try {
                        lookup[obj[parentAttr]][childrenAttr].push(obj);
                    } catch (e) {
                        treeList.push(obj);
                    }

                } else {
                    treeList.push(obj);
                }
            });
            return treeList;
        }
    },

    computed: {

        ...mapGetters('userInfo', ['userInfoSimple']),

        serverAddress() {
            return serverAddress
        },
        baseServerAddress() {
            return `${this.originAddress}/`
        },
        autoUpdate() {
            return process.env.NODE_ENV !== "development"
        },
    }
};
