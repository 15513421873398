<template>
   <div>
       <div
               @click="showUpload = true"
               @dragover="preventDefault($event)"
               @drop="$event.preventDefault(); dropFiles($event); dragOverAttachments = false"
               @dragenter="dragOverAttachments = true"
               @dragleave="dragOverAttachments = false"
       >
           <slot name="activator">

           </slot>
       </div>


       <app-input-dlg
               v-if="showUpload"
               :show-dialog="showUpload"
               title="Upload Documents"
               :cancel-button="true"
               :show-input-box="false"
               @dismiss="dialog.show = false"
               :body-padding="false"
       >
           <template slot="body">

               <div class="px-2 pb-2 scrollable" style="max-height: 300px">
                   <div class="upload-btn-wrapper pt-2" style="width: 100%" v-if="fieldValue ? validFileCount <= 0 : true">
                       <div
                               @dragover="preventDefault($event)"
                               @drop="$event.preventDefault(); dropFiles($event); dragOverAttachments = false"
                               @dragenter="dragOverAttachments = true"
                               @dragleave="dragOverAttachments = false"
                               class="pa-3 caption text-xs-center greyType--text hover-item"
                               style="border: 1px dashed lightgrey; border-radius: 3px; cursor: pointer"
                       >
                           Click to Select or Drop Files here.
                       </div>
                       <input ref="manualUpload" type="file" name="myfile" :multiple="!single" :accept="userInfo.entityInfo.mimeTypeList" @input="manualUploadRun()"/>
                   </div>

                   <div >
                       <div v-for="file in files" class="pt-2" style="overflow-y: visible; overflow-x: hidden">
                           <document-upload-item
                                   :title="file.title"
                                   :state="file.state"
                                   :sub-title="file.subTitle"
                                   :upload-status="file.uploadStatus"
                                   @remove="removeFile(file)"
                                   @retry="retryFile(file)"
                           ></document-upload-item>
                       </div>
                   </div>
               </div>
           </template>
           <template slot="actions">
                <v-btn
                        color="secondary"
                        :disabled="!allowDone || savingDocuments"
                        :loading="savingDocuments"
                        small
                        @click="save"
                >
                    Add Files
                </v-btn>
           </template>
       </app-input-dlg>
   </div>
</template>

<script>
    import { codeBaseMixin } from "../../../../codeBaseMixin";
    import InputDlg from "../../../../components/General/InputDlg";
    import axios from "axios";
    import DocumentUploadItem from "./DocumentUploadItem";

    export default {
        mixins: [codeBaseMixin],
        components: {
            DocumentUploadItem,
            appInputDlg: InputDlg,
        },
        props: {
            dialog: Object,
            userInfo: Object,
            task: Object,
            selectedStep: Object,
            fieldValue: {
                type: Boolean,
                value: false
            },
            single: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                showUpload: false,
                myFiles: [],
                myFileCount: 0,
                savingDocuments: false,
                dragOverAttachments: false,

                files: [],
                nextIndex: 0,
                allowDone: false,

                validFileCount: 0,
            }
        },

        watch: {
            files: {
                immediate: true,
                deep: true,
                handler(files) {
                    let tFileCount = files.filter(file => file.state == 1).length;
                    let tPendingCount = files.filter(file => file.state == 0).length;
                    this.validFileCount = tFileCount + tPendingCount;
                    this.allowDone = tFileCount >= 1 && tPendingCount <= 0;
                }
            },
            dialog: {
                immediate: true,
                deep: true,
                handler(dlg) {
                    if (typeof dlg != 'undefined' && typeof dlg.show != 'undefined') {
                        if (!dlg.show) {
                            this.files = [];
                            this.myFiles = [];
                        }
                        this.showUpload = dlg.show
                    }
                }
            }
        },

        methods: {
            getIndex() {
                this.nextIndex = this.nextIndex + 1;
                return this.nextIndex;
            },

            manualUploadRun() {
                for (let i = 0; i < this.$refs.manualUpload.files.length; i++) {
                    if (this.fieldValue ? i <= 0 : true) {
                        let file = this.$refs.manualUpload.files[i];
                        this.processFile(file)
                    }
                }

                this.$refs.manualUpload.value = "";
            },

            processFile(file) {

                function checkFileType(mimeList, file) {
                    let list = mimeList
                            .split(',')
                            .map(str => {try {return str.trim().toLowerCase()} catch (e) {return ''}})
                    let typeList = list
                            .filter(str => str && str.substr(0,1) !== '.')
                    let extList = list
                            .filter(str => str && str.substr(0,1) === '.')
                            .map(str => {
                                try {
                                    return str.substring(1)
                                } catch (e) {
                                    return ''
                                }
                            })
                    let ext = file.name ? file.name.split('.').pop().toLowerCase() : ''
                    let type = file.type ? file.type.toLowerCase().trim() : ''
                    return typeList.includes(type) || extList.includes(ext)
                }

                if (((file.size / 1024) / 1024) < 50) {
                    if (checkFileType(this.userInfo.entityInfo.mimeTypeList, file)) {
                        this.uploadFile(file);
                    } else {
                        this.files.push({
                            index: this.getIndex(),
                            title: file.name,
                            subTitle: 'File upload canceled - invalid file type',
                            state: 3
                        });
                    }
                } else {
                    this.files.push({
                        index: this.getIndex(),
                        title: file.name,
                        subTitle: `File upload canceled - file to large (${Math.round(((file.size / 1024) / 1024) * 10) / 10} Mb)`,
                        state: 3
                    });
                }
            },

            dropFiles(e) {
                this.showUpload = true;
                this.$nextTick(() => {
                    for (let i = 0; i < e.dataTransfer.files.length; i++) {
                        if (this.fieldValue ? i <= 0 : true) {
                            let file = e.dataTransfer.files[i];
                            this.processFile(file);
                        }
                    }
                })

            },

            uploadFile(file) {
                let h = new Headers();
                let fd = new FormData();

                let myFile = file;

                fd.append('document', myFile);

                let uploadFile = {
                    index: this.getIndex(),
                    title: file.name,
                    subTitle: `Uploading File (${Math.round(((file.size / 1024) / 1024) * 10) / 10} Mb)`,
                    state: 0,
                    uploadStatus: 0,
                    file: file
                };

                this.files.push(uploadFile);
                axios.request( {
                    method: "post",
                    url: `${this.serverAddress}/upload/taskDocument/`,
                    data: fd,
                    onUploadProgress: (p) => {
                        uploadFile.uploadStatus = (p.loaded / p.total) * 100
                    },
                }).then (data => {
                    uploadFile.state = 1;
                    uploadFile.subTitle = 'Upload Success';
                    uploadFile.id = data.data.id;
                    this.myFiles.push(data.data);
                })
                .catch(err => {
                    console.log(err);
                    uploadFile.state = 2;
                    uploadFile.subTitle = 'Network error please retry';
                    uploadFile.retryFile = myFile
                })
            },

            save() {
                if (!this.fieldValue) {
                    this.saveDocuments()
                } else {
                    this.saveFieldDocuments()
                }
            },

            saveFieldDocuments() {

                //this.savingDocuments = true;
                this.fetch(
                    {
                        url: '/fieldsets/saveDocument/' + this.userInfo.entityID + '/' + this.userInfo.userID,
                        method: 'POST',
                        body: JSON.stringify(this.myFiles[0])
                    }
                )
                    .then(data => {
                        this.savingDocuments = false;
                        this.dialog.show = false;
                        this.$emit('documentsUploaded', data)
                    })
                    .catch(err => {
                        this.savingDocuments = false;
                        console.log(err)
                    })

            },
            //
            //
            saveDocuments() {
                this.savingDocuments = true;
                this.fetch(
                    {
                        url: '/tasks/saveDocuments/' + this.userInfo.entityID + '/' + this.userInfo.userID + '/' + this.task.taskID + '/' + this.selectedStep.stepID,
                        method: 'POST',
                        body: JSON.stringify(this.myFiles)
                    }
                )
                    .then(data => {
                        data.forEach(document => {
                            this.task.structure.documents.push(document)
                        });
                        this.savingDocuments = false;
                        this.dialog.show = false;
                        this.$emit('documentsSaved', true)
                    })
                    .catch(err => {
                        this.savingDocuments = false;
                        console.log(err)
                    })
            },

            removeFile(file) {

                this.files = this.files.filter(obj => obj.index !== file.index);
                if (typeof file.id != 'undefined') {
                    this.myFiles = this.myFiles.filter(obj => obj.id !== file.id);
                }
            },

            retryFile(file) {
                this.files = this.files.filter(obj => obj.index !== file.index);
                this.uploadFile(file.retryFile)
            }
            //
            // cancelUpload() {
            //     this.clearFiles();
            //     this.dialog.show = false;
            // },
            //
            // handleProcessFile(error, data) {

            //     this.myFiles.push(data.serverId);
            // },
            //
            // removeFile(error, file) {
            //
            //     let i = -1;
            //     this.myFiles.forEach((arrfile) => {
            //         i++;
            //         if (arrfile == file.serverId) this.myFiles.splice(i,1);
            //     });
            //     this.myFileCount--;
            // },
            //
            // clearFiles() {
            //     this.myFiles = [];
            //     this.myFileCount = 0;
            // }
        },
    }
</script>

<style scoped>
    .upload-btn-wrapper input[type=file] {
        font-size: 100px;
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
    }
    .upload-btn-wrapper {
        position: relative;
        overflow: hidden;
        display: inline-block;
    }
</style>
