const EventEmitter      = require('events');
let emitter             = new EventEmitter();

function snack(title, message, buttonText, titleColor) {
    emitter.emit('showSnack', {
        title: title,
        message: message,
        buttonText: buttonText,
        titleColor: titleColor
    })
}

function info(message) {
    emitter.emit('showSnack', {
        title: 'Info',
        message: message,
        buttonText: 'Close',
        titleColor: 'primary'
    })
}

function error(message) {
    emitter.emit('showSnack', {
        title: 'Error',
        message: message,
        buttonText: 'Close',
        titleColor: 'red'
    })
}

function networkError() {
    emitter.emit('showSnack', {
        title: 'Error',
        message: 'Network error. Please try again.',
        buttonText: 'Close',
        titleColor: 'red'
    })
}

export {
    emitter,
    snack,
    info,
    error,
    networkError
}
