<template>
    <v-layout column fill-height>
        <v-flex shrink class="px-2 pb-2">
            <v-card :style="{background: $store.state.darkTheme ? 'var(--primary-20p)' : 'var(--v-primary-base)'}">
                <v-card-title class="py-1  pl-2 pr-1">
                    <v-layout row align-center>
                        <v-hover>
                            <v-flex
                                    xs12
                                    slot-scope="{ hover }"
                            >
                                <template class="">
                                    <v-layout row align-center ripple style="cursor: pointer">
                                        <v-flex shrink class="py-1">
                                            <span class="subheading font-weight-regular white--text">{{ selectedStep.description }}</span>
                                            <input type="text" :value="`TL-Link: ${userInfo.entityID}:${task.taskID}:${selectedStep.stepID};`" id="linkInput" class="bord" style="width: 3px; height: 3px; opacity: 0"/>
                                        </v-flex>
                                        <v-flex shrink class="pl-1">
                                            <v-tooltip right v-if="hover">
                                                <v-icon @click="copyLink" slot="activator" style="cursor: pointer" class="text--lighten-1 primary--text">file_copy</v-icon>
                                                <span>Copy Task Mail Link</span>
                                            </v-tooltip>
                                        </v-flex>
                                    </v-layout>
                                </template>
                            </v-flex>
                        </v-hover>

<!--Note-->
                            <v-flex  v-if="!softBlock" shrink class="pr-2">
                                <add-note
                                    :user-info="userInfo"
                                    :selected-step="selectedStep"
                                    :task="task"
                                >
                                    <v-tooltip bottom>
                                        <v-btn
                                            slot="activator"
                                            icon
                                            flat
                                            small
                                            color="white"
                                            class="pa-0 ma-0"
                                        >
                                            <v-icon>note</v-icon>
                                            <div
                                                style="position: absolute; left: -5px; top: -5px"
                                            >
                                                <v-icon
                                                    class="teal--text text--lighten-3"
                                                    small
                                                >add_circle</v-icon>
                                            </div>
                                        </v-btn>
                                        <span>Add Note</span>
                                    </v-tooltip>
                                </add-note>
                            </v-flex>
<!--Mail-->
                            <v-flex v-if="!softBlock" shrink class="pr-2">
                                <add-mail
                                    :user-info="userInfo"
                                    :selected-step="selectedStep"
                                    :mail-config="mailConfig"
                                    :task="task"
                                >
                                    <v-tooltip bottom>
                                        <v-btn
                                            slot="activator"
                                            icon
                                            flat
                                            small
                                            color="white"
                                            class="pa-0 ma-0"
                                        >
                                            <v-icon>mail</v-icon>
                                            <div
                                                style="position: absolute; left: -5px; top: -5px"
                                            >
                                                <v-icon
                                                    class="teal--text text--lighten-3"
                                                    small
                                                >add_circle</v-icon>
                                            </div>
                                        </v-btn>
                                        <span>Add Mail</span>
                                    </v-tooltip>
                                </add-mail>
                            </v-flex>

                            <add-document
                                v-if="!softBlock"
                                :user-info="userInfo"
                                :task="task"
                                :selected-step="selectedStep"
                                ref="docs"
                            ></add-document>


<!--Documents-->
                            <v-flex v-if="!softBlock" shrink class="pr-2">
                                <v-tooltip bottom>
                                    <v-btn
                                        slot="activator"
                                        icon
                                        flat
                                        small
                                        color="white"
                                        class="pa-0 ma-0"
                                        @click="$refs.docs.showUpload.show = true"
                                    >
                                        <v-icon>attachment</v-icon>
                                        <div
                                            style="position: absolute; left: -5px; top: -5px"
                                        >
                                            <v-icon
                                                class="teal--text text--lighten-3"
                                                small
                                            >add_circle</v-icon>
                                        </div>
                                    </v-btn>
                                    <span>Add Document</span>
                                </v-tooltip>
                            </v-flex>
<!--3CX-->
                        <v-flex  shrink class="pr-2" v-if="!softBlock && ((userInfo['3CXServerIndex'] > -1) || (userInfo.yeastarExtension && (userInfo.entityInfo.yeastarSSeriesEnabled || userInfo.entityInfo.yeastarPSeriesEnabled)))">
                            <v-tooltip bottom>
                                <v-btn
                                        slot="activator"
                                        icon
                                        flat
                                        small
                                        color="white"
                                        class="pa-0 ma-0"
                                        @click="$refs.docs.showVoip = true"
                                >
                                    <v-icon>phone</v-icon>
                                    <div
                                            style="position: absolute; left: -5px; top: -5px"
                                    >
                                        <v-icon
                                                class="teal--text text--lighten-3"
                                                small
                                        >add_circle</v-icon>
                                    </div>
                                </v-btn>
                                <span>Link Recording</span>
                            </v-tooltip>
                        </v-flex>
<!--Lifestyle Voip-->
                        <v-flex shrink class="pr-2" v-if="!softBlock && userInfo.entityID === 48 && userInfo['link-10']">
                            <v-tooltip bottom>
                                <v-btn
                                        slot="activator"
                                        icon
                                        flat
                                        small
                                        color="white"
                                        class="pa-0 ma-0"
                                        @click="showLifestyleVoip = true"
                                >
                                    <v-icon>phone</v-icon>
                                    <div
                                            style="position: absolute; left: -5px; top: -5px"
                                    >
                                        <v-icon
                                                class="teal--text text--lighten-3"
                                                small
                                        >add_circle</v-icon>
                                    </div>
                                </v-btn>
                                <span>Link Voip Recording</span>
                            </v-tooltip>
                            <lifestyle-voip
                                    v-if="showLifestyleVoip"
                                    :shown.sync="showLifestyleVoip"
                                    :selected-task="task"
                            ></lifestyle-voip>
                        </v-flex>

<!--Doc Repo-->
                        <v-flex v-if="!softBlock" shrink class="pr-2">
                            <v-tooltip bottom>
                                <v-btn
                                        slot="activator"
                                        icon
                                        flat
                                        small
                                        color="white"
                                        class="pa-0 ma-0"
                                        @click="addRepoDocs()"
                                        :disabled="addingRepoDocs"
                                        :loading="addingRepoDocs"

                                >
                                    <v-icon>note_add</v-icon>
                                    <div
                                            style="position: absolute; left: -5px; top: -5px"
                                    >
                                        <v-icon
                                                class="teal--text text--lighten-3"
                                                small
                                        >add_circle</v-icon>
                                    </div>
                                </v-btn>
                                <span>Add Document From Store</span>
                            </v-tooltip>
                        </v-flex>

<!--Event-->
                        <v-flex v-if="!softBlock" shrink class="pr-2">
                            <v-tooltip bottom>
                                <v-btn
                                        slot="activator"
                                        icon
                                        flat
                                        small
                                        color="white"
                                        class="pa-0 ma-0"
                                        @click="$createCalendarEvent({fkTask: task.taskID, start: new Date()}, calendarEventCreated)"
                                >
                                    <v-icon>event</v-icon>
                                    <div
                                            style="position: absolute; left: -5px; top: -5px"
                                    >
                                        <v-icon
                                                class="teal--text text--lighten-3"
                                                small
                                        >add_circle</v-icon>
                                    </div>
                                </v-btn>
                                <span>Add Calendar Event</span>
                            </v-tooltip>
                        </v-flex>
                      
<!--chat-->
                      <v-flex  v-if="!softBlock && contactsEnabled" shrink class="pr-2">
                        <v-tooltip bottom>
                          <template #activator="{on}">
                            <v-btn
                                icon
                                flat
                                small
                                color="white"
                                class="pa-0 ma-0"
                                v-on="on"
                                @click.stop="$store.state.chat.comboBarShowCompose = !$store.state.chat.comboBarShowCompose"
                            >
<!--                              <v-icon>chat_bubble</v-icon>-->
                              
                              <img src="../../../../../public/chat_white.svg" class="nav-item" style="width: 24px">
                              
                              <div
                                  style="position: absolute; left: -5px; top: -5px"
                              >
                                <v-icon
                                    class="teal--text text--lighten-3"
                                    small
                                >add_circle</v-icon>
                              </div>
                            </v-btn>
                          </template>
                          <span>Add Chat Message</span>
                        </v-tooltip>
                      </v-flex>



                        <v-divider v-if="!softBlock" vertical color="white"></v-divider>

                        <v-flex v-if="!softBlock" shrink class="pl-1">
                            <v-tooltip bottom>
                                <v-btn

                                        ripple
                                        class="pa-0 ma-0"
                                        color="white"
                                        icon
                                        flat
                                        small
                                        slot="activator"
                                        style="cursor: pointer"
                                        :loading="savingValues"
                                        :disabled="savingValues || saveFields.length <= 0"
                                        @click="saveChanges"
                                >
                                    <v-icon>save</v-icon>
                                </v-btn>
                                <span>Save Changes</span>
                            </v-tooltip>
                        </v-flex>
                    </v-layout>
                </v-card-title>
            </v-card>
        </v-flex>
        <v-flex xs12 class="scrollable px-2 pb-2">
            <v-card>
<!--Step Fields-->
                <template v-if="this.selectedStep.stepID != 0">

                    <template v-for="field in stepFields">
                        <app-task-field
                                ref="field"
                                :key="field.fieldID + '-WorkflowField'"
                                v-if="field.ruleShow"
                                :field="field"
                                :user-info="userInfo"
                                :task="task"
                                :selected-step="selectedStep"
                                :folder-groups="folderGroups"
                                :users="users"
                                :selectLists="selectLists"
                                :edit-note-fn="editNoteFn"
                                :mail-config="mailConfig"
                                :hidden-steps="hiddenSteps"
                                :local-data="localData"
                                :show-sync="showSync"
                                :get-task-header="getTaskHeader"
                                @forceSave="saveChanges"
                                @setMap="setMap($event)"
                                @clearMap="clearMap($event)"
                                @selectedStep="$emit('selectedStep', $event)"
                                :binder="binder"
                                :eval-binder="evalBinder"
                        />
                    </template>
                </template>
            </v-card>

            <app-input-dlg
                    v-if="false"
                    :show-dialog="showSaveMessage"
                    title="Changes Made"
                    :cancel-button="true"
                    :show-input-box="false"
                    @dismiss="$emit('showSaveMessage', null)"
                    :body-padding="false"
            >
                <template slot="body">
                    <div class="pa-2">
                        {{`Changes were made to '${task.title}'. Would you like to save these changes?`}}
                    </div>
                </template>
                <template slot="actions">
                    <v-btn
                            small
                            class="my-0"
                            color="secondary"
                            @click="$emit('showSaveMessage', true)"
                            :disabled="savingValues"
                    >
                        <v-icon left>close</v-icon>
                        Cancel
                    </v-btn>
                    <v-btn
                            small
                            class="my-0"
                            color="secondary"
                            @click="saveChanges"
                            :disabled="savingValues"
                            :loading="savingValues"
                    >
                        <v-icon left>save</v-icon>
                        Save changes
                    </v-btn>
                </template>
            </app-input-dlg>
        </v-flex>
    </v-layout>
</template>

<script>
    import { codeBaseMixin } from '../../../../codeBaseMixin';
    import { eventBus } from '../../../../main';
    import TaskField from './Components/TaskField'
    import InputDlg from "../../../../components/General/InputDlg";
    import {objectifyArr} from "../../../../codeFunctions";
    import AddNote from "../Notes/AddNote";
    import AddMail from "../Mails/AddMail";
    import AddDocument from "../Documents/AddDocument";
    import {DatasetBinder} from "./datasetBinder"
    import LifestyleVoip from "@/Tasks/TaskView V2/components/Lifestyle Voip/LifestyleVoip";
    import {EvalBinder} from "@/Tasks/TaskView V2/components/TaskViewDetail/evalsBinder";
    import {mapGetters} from "vuex";

    export default {
        mixins: [codeBaseMixin],
        components: {
            LifestyleVoip,
            AddMail,
            AddNote,
            AddDocument,
            appInputDlg: InputDlg,
            appTaskField: TaskField
        },
        props: {
            task: Object,
            userInfo: Object,
            selectedStep: Object,
            folderGroups: Array,
            users: Array,
            selectLists: {
                type: Array,
                default: () => {
                    return []
                }
            },
            editNoteFn: Function,
            mailConfig: {
                required: true,
                type: Object
            },
            hiddenSteps: Array,
            showSaveMessage: {
                type: Boolean,
                default: false
            },
            localData: Object,
            showSync: Boolean,
            getTaskHeader: Function,
        },
        data() {
            return {
                viewStep: 0,
                savingValues: false,
                mapUpdate: null,
                addingRepoDocs: false,
                binder: new DatasetBinder(this.localData, this.task, this),
                showLifestyleVoip: false,
                evalBinder: null
            }
        },
        created() {
            eventBus.$on('newStructureLoaded', () => this.calcStep());
            eventBus.$on('mailTemplateSaveSent', this.mailTemplateSaveSent)
            this.evalBinder = new EvalBinder(this.localData, this.task, this, this.binder)
        },
        beforeDestroy() {
            eventBus.$off('newStructureLoaded', () => this.calcStep());
            eventBus.$off('mailTemplateSaveSent', this.mailTemplateSaveSent)
            window.removeEventListener('beforeunload', this.stopClose);
        },
        methods: {

            mailTemplateSaveSent() {
                if (this.saveFields.length > 0) {
                    this.saveChanges();
                }
            },

            calendarEventCreated(event) {
                try {
                    this.task.structure.events.push(event)
                } catch (e) {
                    this.task.structure.events = [event]
                }
            },

            addRepoDocs() {
                this.$getRepoDocs({
                    multiple: true,
                    allowUserDocs: true
                })
                        .then((docs) => {
                            this.addingRepoDocs = true;
                            this.$newReq(
                                    'post',
                                    `docRepo/addDocsToTask/${this.userInfo.entityID}/${this.userInfo.userID}/${this.task.taskID}/${this.selectedStep.stepID}`,
                                    {docs: docs.map(obj => {return obj.documentDBID})}
                            )
                            .then((addedDocs) => {
                                addedDocs.forEach(document => {
                                    this.task.structure.documents.push(document)
                                });
                                this.$snack.info('Documents Added Successfully')
                            })
                            .catch(e => {
                                console.log(e)
                                this.$snack.networkError()
                            })
                            .finally(() => {
                                this.addingRepoDocs = false;
                            })
                        })
                        .catch(e => {
                            if (e) {
                                console.log(e)
                                this.$snack.error('An Error Has Occurred')
                            } else {
                                console.log('User Canceled')
                            }
                        })
            },

            setMap(config) {

                // Clear All Values
                this.clearMap(config.map);



                // Set New Values
                let newValues = config.values.reduce((obj, item) => {
                    obj[`${item.id}`] = item.value;
                    return obj
                }, {})

                let valMap = config.map.fieldMap.reduce((obj, item) => {
                    obj[`${item.dest}`] = typeof newValues[`${item.src}`] != 'undefined' ? newValues[`${item.src}`] : null;
                    return obj
                }, {});

                this.task.structure.fields.forEach(field => {
                    if (typeof valMap[`${field.fieldID}`] != 'undefined') {
                        field.value = valMap[`${field.fieldID}`];
                    }
                });

                // Set The updateMap
                this.mapUpdate = {
                    taskID: config.srcTask,
                    values: config.map.updateSrcValues
                };
            },

            clearMap(map) {
                let mapFieldIDs = map.fieldMap.map(obj => {return obj.dest});
                this.task.structure.fields.forEach(field => {
                    if (mapFieldIDs.includes(field.fieldID)) {
                        field.value = null;
                    }
                });
                this.mapUpdate = null;
            },

            calcStep() {
                this.viewStep = this.task.curStepID
                let tStep = this.getLastStep();

                // if (tStep != null && tStep != null)
            },

            recalcStepCount() {
                let tCounts = [];
                this.task.structure.fields.forEach(field => {
                    if (!tCounts.map(obj => {return obj.step}).includes(field.stepID)) {
                        tCounts.push({
                            step: field.stepID,
                            count: 0
                        })
                    }

                    if (field.ruleShow) {
                        tCounts.forEach(step => {
                            if (step.step == field.stepID) {
                                step.count = step.count + 1
                            }
                        })
                    }
                });
                eventBus.$emit('stepCountReran', tCounts)

                const doneSteps = this.task.structure.fields.reduce((obj, field) => {

                    if (!obj[field.stepID])
                        obj[field.stepID] = {
                            required: true,
                            all: true
                        };

                    if (field.value === null && field.ruleShow && field.fieldType !== 20) {
                        if (field.required)
                            obj[field.stepID] = {required: false, all: false}
                        else
                            obj[field.stepID].all = false
                    }

                    return obj
                }, {});

                eventBus.$emit('doneStepsReran', doneSteps)
            },

            copyLink() {
                /* Get the text field */
                var copyText = document.getElementById("linkInput");

                /* Select the text field */
                copyText.select();

                /* Copy the text inside the text field */
                document.execCommand("copy");

                /* Alert the copied text */
                this.showSnack('Info', 'Link Copied', 'Close', 'primary')
            },

            recalcTaskPercentage() {
                let iTotal = 0;
                let iComplete = 0;

                this.task.structure.fields.forEach(field => {
                    if (field.ruleShow == true) {
                        iTotal = ++iTotal;
                        if (field.value != null && field.value != '') {
                            iComplete = ++iComplete
                        }
                    }
                });
                this.task.status_percentage = Math.round((iComplete / iTotal) * 100)
            },

            getLastStep() {

                // build an array of visible steps
                let allViewSteps = this.task.structure.steps.filter(step => !this.hiddenSteps.includes(step.stepID)).sort((a,b) => a['order'] < b['order'] ? -1 : 1);

                // Get Max Order
                if (this.workflowRef.lastStep !== null) {
                    let maxOrder = (this.task.structure.steps.filter(step => step.stepID === this.workflowRef.lastStep).pop() || [...allViewSteps].pop()).order
                    allViewSteps = allViewSteps.filter(step => step.order <= maxOrder)
                }

                let allViewStepIDs = allViewSteps.map(obj => {return obj.stepID});
                // Build A List of Required Fields
                let requiredFields = this.task.structure.fields.filter(obj => obj.ruleShow && allViewStepIDs.includes(obj.stepID) && (obj.required == 1))

                if (requiredFields.length <= 0) {
                    //Build A list of all Steps with their Fields in
                    return allViewSteps.reduce((step, curStep, index, allSteps) => {
                        if (step == null) {
                            let stepComplete = this.task.structure.fields.filter(obj => obj.ruleShow && obj.stepID == curStep.stepID)
                                .sort((a,b) => a['field_order'] < b['field_order'] ? -1 : 1)
                                .filter(obj => this.blankString(obj.value)).length <= 0;

                            if (stepComplete) {
                                if (allSteps.length -1 == index) {
                                    // Set To Last Step
                                    step = curStep
                                }
                            } else {
                                // Set To Current Step
                                step = curStep
                            }
                        }
                        return step
                    }, null);
                } else {
                    // Check Required fields
                    return allViewSteps.reduce((step, curStep, index, allSteps) => {
                        if (step == null) {
                            let stepComplete = this.task.structure.fields.filter(obj => obj.ruleShow && obj.stepID == curStep.stepID && (obj.required == 1))
                                .sort((a,b) => a['field_order'] < b['field_order'] ? -1 : 1)
                                .filter(obj => this.blankString(obj.value)).length <= 0;

                            if (stepComplete) {
                                if (allSteps.length -1 == index) {
                                    // Set To Last Stepfield
                                    step = curStep
                                }
                            } else {
                                // Set To Current Step
                                step = curStep
                            }
                        }
                        return step
                    }, null);
                }
            },

            saveChanges() {

                let lastStep = this.getLastStep();
                lastStep = lastStep.stepID;
                this.savingValues = true;
                this.fetch({
                    method: 'POST',
                    url: '/tasks/saveFields/' + this.userInfo.entityID + '/' + this.userInfo.userID + '/' + this.task.taskID + '/' + lastStep + '/' + (this.task.status_percentage != null ? this.task.status_percentage : 0),
                    body: {fields: this.saveFields, map: this.mapUpdate}
                })
                    .then((data) => {
                        this.mapUpdate = null;
                        this.task.state = data.meta.state;
                        this.task.structure.fields.forEach((taskField, i) => {
                            taskField.saveStatus = 0;
                            taskField.saveStatusText = null;
                        });
                        data.savedFields.forEach(saveValue => {
                            if (saveValue.fieldType == 7 && saveValue.value_type == 1) {
                                this.task.structure.mails.forEach(mail => {
                                    if (mail.mailDBID == saveValue.value) {
                                        mail.securityLookup = saveValue.lookup;
                                    }
                                })
                            }
                            if (saveValue.fieldType == 7 && saveValue.value_type == 2) {
                                this.task.structure.documents.forEach(document => {
                                    if (document.documentDBID == saveValue.value) {
                                        document.securityLookup = saveValue.lookup;
                                    }
                                })
                            }
                            this.task.structure.fields.forEach((taskField, i) => {
                                if (saveValue.fieldID == taskField.fieldID) {
                                    Object.assign(this.task.structure.fields[i], saveValue)
                                }
                            })
                        });
                        eventBus.$emit('ChangesSaved');
                        this.savingValues = false;
                        this.showSnack('Info', 'Changes Saved', 'Close', 'primary')
                        this.$emit('showSaveMessage', false)
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e);
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                        this.savingValues = false
                    })
            },
            
            stopClose(e) {
                e.preventDefault();
            }
        },
        watch: {
            saveFields: {
                immediate: true,
                handler(val) {
                    this.$emit('showSavePrompt', Array.isArray(val) && val.filter((v) => !!v.value).length >= 1);
                    if (Array.isArray(val) && val.filter((v) => !!v.value).length >= 1) {
                        console.log('Here')
                        window.addEventListener('beforeunload', this.stopClose);
                    } else {
                        window.removeEventListener('beforeunload', this.stopClose);
                    }
                }
            }
        },
        
        computed: {
            ...mapGetters("userInfo", ["softBlock", "contactsEnabled"]),

            workflowRef() {
                try {
                    return this.localData.workflows.filter(wf => wf.id === this.task.workflowID).pop();
                } catch(e) {
                    return null
                }

            },
            stepObj() {
                try {
                    return this.task.structure.steps.filter(step => step.stepID === this.selectedStep.stepID).pop()
                } catch(e) {
                    return null
                }
            },
            allShowRuleIDs() {
                let allshow = this.task.structure.rules.reduce((obj, rule) => {
                    if (!this.blankString(rule.showFields)) {
                        obj.fields = obj.fields.concat(JSON.parse(rule.showFields))
                    }
                    if (!this.blankString(rule.showSteps)) {
                        obj.steps = obj.steps.concat(JSON.parse(rule.showSteps))
                    }
                    return obj
                }, {fields: [], steps: []});
                return allshow;
            },
            refFields() {
                let values = this.task.structure.fields
                    .reduce((obj, field) => {
                        obj[field.fieldID] = field
                        return obj;
                    }, [])
                return values
            },
            refRules() {
                let arrValues = this.task.structure.rules.reduce((arr, rule) => {
                    let refRule = {id: rule.id}
                    try {refRule.showSteps = JSON.parse(rule.showSteps)} catch (e) {refRule.showSteps = null}
                    try {
                        refRule.showFields = JSON.parse(rule.showFields)
                        refRule.showFieldsRaw = this.showFields.filter(field => refRule.showFields.includes(field.fieldID))
                    } catch (e) {refRule.showFields = null}
                    try {
                        refRule.conditions = JSON.parse(rule.conditions)
                            .map(condition => {
                                condition.refField = this.refFields[condition.fk_field]
                                return condition
                            })
                    } catch (e) {refRule.conditions = null}
                    arr.push(refRule);
                    return arr
                }, [])
                return arrValues;
            },
            showFields() {
                return this.task.structure.fields.filter(field => this.allShowRuleIDs.fields.includes(field.fieldID))
            },
            appliedRulesOnFields() {

                // Build A List Of All Show Fields


                this.task.structure.fields.forEach(field => {
                    field.ruleShow = true;
                });
                this.showFields.forEach(field => {
                    field.ruleShow = false;
                });


                let hiddenSteps = this.allShowRuleIDs.steps;
                // Loop Through All Rules
                this.refRules.forEach(rule => {
                    if (rule.conditions != null && rule.conditions != '') {

                        let bRuleMet = true;

// Loop Through All Conditions

                        rule.conditions.forEach(condition => {

                            // Get The Referenced Value
                            let rValue = null;
                            try {
                                rValue = condition.refField.value;
                            } catch(e) {
                                rValue = null
                            }

                            // Use The Operation ID to Determine bRuleMet
                            switch (condition.operationID) {
                                case 0: {
                                    if (rValue != condition.value) {
                                        bRuleMet = false
                                    }
                                    break;
                                }
                                case 1: {
                                    if (rValue == condition.value) {
                                        bRuleMet = false
                                    }
                                    break;
                                }
                                case 2: {
                                    if (rValue == null || rValue == '') {
                                        bRuleMet = false
                                    }
                                    break;
                                }
                                case 5: {
                                    if (rValue != null && rValue != '') {
                                        try {
                                            if (new Date(rValue) < new Date(condition.value)) {
                                                bRuleMet = false
                                            }
                                        } catch (e) {
                                            // eslint-disable-next-line
                                            console.log(e)
                                            bRuleMet = false
                                        }
                                    } else {
                                        bRuleMet = false
                                    }
                                    break;
                                }
                                case 6: {
                                    if (rValue != null && rValue != '') {
                                        try {
                                            if (new Date(rValue) < new Date(condition.value)) {
                                                bRuleMet = false
                                            }
                                        } catch (e) {
                                            // eslint-disable-next-line
                                            console.log(e)
                                            bRuleMet = false
                                        }
                                    } else {
                                        bRuleMet = false
                                    }
                                    break;
                                }
                                case 3: {
                                    if (rValue != null && rValue != '') {
                                        try {
                                            if (parseFloat(rValue) < parseFloat(condition.value)) {
                                                bRuleMet = false
                                            }
                                        } catch (e) {
                                            // eslint-disable-next-line
                                            console.log(e);
                                            bRuleMet = false
                                        }
                                    } else {
                                        bRuleMet = false
                                    }
                                    break;
                                }
                                case 4: {
                                    if (rValue != null && rValue != '') {
                                        try {
                                            if (parseFloat(rValue) > parseFloat(condition.value)) {
                                                bRuleMet = false
                                            }
                                        } catch (e) {
                                            // eslint-disable-next-line
                                            console.log(e);
                                            bRuleMet = false
                                        }
                                    } else {
                                        bRuleMet = false
                                    }
                                    break;
                                }
                                case 7: {
                                    if (rValue !== null && rValue !== '') {
                                        bRuleMet = false
                                    }
                                    break;
                                }
                            }
                        });

                        // Check If The Rule Was Met and update to show the appropriate fields

                        if (bRuleMet) {
                            if (rule.showSteps != null) {
                                hiddenSteps = hiddenSteps.filter(obj => !rule.showSteps.includes(obj))
                            }
                            if (rule.showFields != null)
                                rule.showFieldsRaw.forEach(field => {
                                    field.ruleShow = true
                                })
                        }
                    }
                });

                this.$emit('hiddenSteps', hiddenSteps)
                this.recalcTaskPercentage();
                this.recalcStepCount();

                return this.task.structure.fields;
            },
            stepFields() {
                if (this.selectedStep.stepID != 0) {
                    let fields = [];
                    this.appliedRulesOnFields.forEach(field => {
                        if (field.stepID == this.selectedStep.stepID && field.shown) {
                            fields.push(field)
                        }
                    });

                    fields.sort((a,b) => a['field_order'] < b['field_order'] ? -1 : 1);
                    return fields
                } else {
                    return []
                }
            },
            saveFields() {
                if (this.evalBinder)
                    this.evalBinder.runForFields()
                let fields = [];
                if (this.appliedRulesOnFields.length > 0) {
                    this.appliedRulesOnFields.forEach(field => {
                        let bAddSaveField = false;
                        // Start Field Check


                        if (field.value || field.allowNull) {
                            if (field.type != 7) {
                                bAddSaveField = field.value != field.oldValue
                            }
                        }

                        if (field.fieldType == 12 && field.oldValue != null && field.value != field.oldValue) {
                            bAddSaveField = true
                        }

                        // End Field Check
                        if (bAddSaveField) {fields.push(field)}
                    })
                }

                let tPassFields = [];

                fields.forEach(field => {
                    switch (field.fieldType) {
                        case 1: {
                            tPassFields.push({
                                fieldID: field.fieldID,
                                valueID: field.valueID,
                                value: field.value,
                                value_type: field.value_type,
                                linkLookup: field.linkLookup,
                                fieldType: field.fieldType,
                                lookup: field.lookup,
                                stepID: field.stepID,
                                bindRef: field.bindRef
                            });
                            break;
                        }
                        case 7: {
                            tPassFields.push({
                                fieldID: field.fieldID,
                                valueID: field.valueID,
                                value: field.value,
                                value_type: field.value_type,
                                linkLookup: field.linkLookup,
                                fieldType: field.fieldType,
                                lookup: field.lookup,
                                bindRef: field.bindRef
                            });
                            break;
                        }
                        case 9: {
                            tPassFields.unshift({
                                fieldID: field.fieldID,
                                valueID: field.valueID,
                                value: field.value,
                                value_type: field.value_type,
                                linkLookup: field.linkLookup,
                                fieldType: field.fieldType,
                                lookup: field.lookup,
                                bindRef: field.bindRef
                            });
                            break;
                        }
                        case 11: {
                            tPassFields.push({
                                fieldID: field.fieldID,
                                valueID: field.valueID,
                                value: field.value,
                                value_type: field.value_type,
                                linkLookup: field.linkLookup,
                                fieldType: field.fieldType,
                                lookup: field.lookup,
                                bindRef: field.bindRef
                            });
                            break;
                        }
                        case 12: {
                            tPassFields.push({
                                fieldID: field.fieldID,
                                valueID: field.valueID,
                                value: field.value,
                                value_type: field.value_type,
                                linkLookup: field.linkLookup,
                                fieldType: field.fieldType,
                                lookup: field.lookup,
                                bindRef: field.bindRef
                            });
                            break;
                        }
                        case 22: {
                            tPassFields.push({
                                fieldID: field.fieldID,
                                valueID: field.valueID,
                                value: field.value,
                                value_type: field.value_type,
                                linkLookup: field.linkLookup,
                                fieldType: field.fieldType,
                                lookup: field.lookup,
                                bindRef: field.bindRef
                            });
                            break;
                        }
                        default: {
                            tPassFields.push({
                                fieldID: field.fieldID,
                                valueID: field.valueID,
                                value: field.value,
                                value_type: field.value_type,
                                linkLookup: field.linkLookup,
                                fieldType: field.fieldType,
                                lookup: null,
                                stepID: field.stepID,
                                bindRef: field.bindRef
                            });
                            break;
                        }
                    }
                });

                return tPassFields
            },
        },
    }
</script>

<style scoped>
</style>
