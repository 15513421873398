<template>
    <div>
        <div>
            <v-text-field
                    label="Search Workflows"
                    single-line
                    clearable
                    prepend-icon="search"
                    color="primary"
                    flat
                    :hide-details="true"
                    v-model="search"
                    class="pa-0 ma-0"
                    ref="myField"
            ></v-text-field>
        </div>
        <div class="scrollable" :style="`max-height: ${maxHeight}px`">
            <template v-for="workspace in filteredDisplayItems">
                <div :key="`workspace${workspace.id}`">
                    <v-layout row align-center class="hover-item" @click="dropedWorkSpaces = !dropedWorkSpaces.includes(workspace.id) ? dropedWorkSpaces.concat(workspace.id) : dropedWorkSpaces.filter(obj => obj != workspace.id)">
                        <v-flex shrink class="px-1">
                            <v-icon
                                    color="primary"
                                    cursor="pointer"
                                    class="pa-0 ma-0"
                            >
                                {{dropedWorkSpaces.includes(workspace.id) ? 'arrow_drop_up' : 'arrow_drop_down'}}
                            </v-icon>
                        </v-flex>
                        <v-flex xs12 class="pa-1 caption">
                            {{workspace.description}}
                        </v-flex>
                    </v-layout>
                    <div class="pl-3" v-if="!(search == null || search == '') || dropedWorkSpaces.includes(workspace.id)">
                        <template v-for="workflowGroup in workspace.workflowGroups">
                            <div :key="`workflowGroup${workflowGroup.id}`">
                                <v-layout row align-center class="hover-item" @click="dropedWorkflowGroups = !dropedWorkflowGroups.includes(workflowGroup.id) ? dropedWorkflowGroups.concat(workflowGroup.id) : dropedWorkflowGroups.filter(obj => obj != workflowGroup.id)">
                                    <v-flex shrink class="px-1">
                                        <v-icon
                                                color="primary"
                                                cursor="pointer"
                                                class="pa-0 ma-0"
                                        >
                                            {{dropedWorkflowGroups.includes(workflowGroup.id) ? 'arrow_drop_up' : 'arrow_drop_down'}}
                                        </v-icon>
                                    </v-flex>
                                    <v-flex xs12 class="pa-1 caption">
                                        {{workflowGroup.description}}
                                    </v-flex>
                                </v-layout>
                                <div class="pl-3" v-if="!(search == null || search == '') || dropedWorkflowGroups.includes(workflowGroup.id)">
                                    <template v-for="workflow in workflowGroup.workflows">
                                        <div :key="`workflow${workflow.id}`">
                                            <v-layout row align-center class="hover-item" @click="selectWorkflow(workflow)">
                                                <v-flex shrink class="px-1">
                                                    <v-icon
                                                            small
                                                            color="primary"
                                                            cursor="pointer"
                                                            class="pa-0 ma-0"
                                                    >
                                                        {{valueID == workflow.id ? 'check_box' : 'check_box_outline_blank'}}
                                                    </v-icon>
                                                </v-flex>
                                                <v-flex xs12 class="pa-1 caption">
                                                    {{workflow.description}}
                                                </v-flex>
                                            </v-layout>
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>

   import {codeBaseMixin} from "../../../../../../../../codeBaseMixin";


   export default {
       mixins: [codeBaseMixin],
       components: {

       },
       props: {
           value: Object,
           workflows: Array,
           maxHeight: {
               type: Number,
               default: 400
           }
       },
       data() {
           return {
               search: '',
               dropedWorkSpaces: [],
               dropedWorkflowGroups: [],
           }
       },
       methods: {
           selectWorkflow(workflow) {
               this.$emit('input', workflow)
           }
       },
       computed: {
           valueID() {
               return this.value != null && typeof this.value.id != 'undefined' && this.value.id != null ? this.value.id : -1
           },

           allWorkflows() {
               return this.workflows.reduce((wfList, workspace) => {
                   try {
                       workspace.workflowGroups.forEach(workflowGroup => {
                           try {
                               wfList = wfList.concat(workflowGroup.workflows.map(workflow => {
                                   return {
                                       wfID: workflow.id,
                                       wgID: workflowGroup.id,
                                       wsID: workspace.id,
                                       wfDescription: workflow.description,
                                       wgDescription: workflowGroup.description,
                                       wsDescription: workspace.description,
                                   }
                               }))
                           } catch (e) {
                               // eslint-disable-next-line
                               console.log(e)
                           }
                       })
                   } catch (e) {
                       // eslint-disable-next-line
                       console.log(e)
                   }
                   return wfList
               }, [])
           },

           filteredWorkflows() {
               if (this.search == null || this.search == '') {
                   return null
               } else {
                   return this.allWorkflows.filter(obj => obj.wfDescription.toLowerCase().includes(this.search.toLowerCase()))
               }
           },

           filteredDisplayItems() {
               if (this.filteredWorkflows == null) {
                   return this.workflows
                       .map(obj => {
                           obj.workflowGroups =
                               obj.workflowGroups.map(workflowGroup => {
                                    workflowGroup.workflows = workflowGroup.workflows.filter(() => true).sort((a,b) => a['description'] < b['description'] ? -1 : 1);
                                    return workflowGroup
                                })
                               .sort((a,b) => a['description'] < b['description'] ? -1 : 1);
                           return obj
                       })
                       .sort((a,b) => a['description'] < b['description'] ? -1 : 1);
               } else {
                   let displayGroups = this.filteredWorkflows.reduce((returnObj, obj) => {
                       if (!returnObj.workspaces.includes(obj.wsID)) {returnObj.workspaces.push(obj.wsID)}
                       if (!returnObj.workflowGroups.includes(obj.wgID)) {returnObj.workflowGroups.push(obj.wgID);}
                       returnObj.workflows.push(obj.wfID);
                       return returnObj;
                   }, {
                       workspaces: [],
                       workflowGroups: [],
                       workflows: [],
                   });
                   let tWorkspaces = this.workflows.filter(obj => displayGroups.workspaces.includes(obj.id)).sort((a,b) => a['description'] < b['description'] ? -1 : 1);
                   tWorkspaces.forEach(workspace => {
                       workspace.workflowGroups = workspace.workflowGroups.filter(wfGroup => displayGroups.workflowGroups.includes(wfGroup.id)).sort((a,b) => a['description'] < b['description'] ? -1 : 1);
                       workspace.workflowGroups.forEach(workflowGroup => {
                           workflowGroup.workflows = workflowGroup.workflows.filter(wf => displayGroups.workflows.includes(wf.id)).sort((a,b) => a['description'] < b['description'] ? -1 : 1);
                       })
                   });
                   return tWorkspaces;
               }
           }
       }
   }
</script>

<style scoped>

    .hoverItem:hover {
        cursor: pointer;
        border-radius: 3px;
        background: #EEEEEE;
    }
</style>
