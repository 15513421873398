<template>
    <v-card flat hover :style="`border-left: 5px solid ${$vuetify.theme[notification.color] || 'var(--v-tBlue-base)'};`" @click="console.log($vuetify.theme)">
<!--        {{notification}}-->
            <v-layout row align-center justify-space-between style="white-space: nowrap" class="pb-1" >
                <v-flex class="py-1 pr-1 caption font-weight-bold text-truncate" style="overflow-y: hidden">
                    {{notification.title}}
                </v-flex>
                <v-flex style="font-size: 0.8em" shrink class="font-weight-regular pr-1">
                    {{ getLongDate(notification.created) }}
                </v-flex>
            </v-layout>


        <v-layout row align-center justify-space-between style="white-space: nowrap" class="pb-1" v-if="notification.stepDescription != null">
            <v-flex xs12  class="pl-1 caption font-weight-bold text-truncate" style="overflow-y: hidden">
                {{notification.stepDescription || ' '}}
            </v-flex>
            <v-flex shrink class="px-1 caption font-weight-bold">
                {{notification.fieldDescription || ' '}}
            </v-flex>
        </v-layout>

        <v-layout row align-center>
            <v-flex shrink class="px-2" v-if="notification.icon != null">
                <v-icon :color="(notification.color || 'Blue').toLowerCase()">
                    {{notification.icon}}
                </v-icon>
            </v-flex>
            <v-flex shrink class="pr-2 py-1">
                <div class="caption font-weight-regular" v-html="notification.body"></div>
            </v-flex>
        </v-layout>

        <v-layout row align-center class="px-1">
            <v-flex xs12 class="px-1 caption font-weight-bold">
                <template v-if="notification.fromUserName != null && notification.fromUserSurnname != null">
                    {{notification.fromUserName + ' ' + notification.fromUserSurnname}}
                </template>
            </v-flex>
            <v-flex shrink class="py-1">
                <v-btn
                        flat
                        icon
                        class="pa-0 ma-0"
                        @click="removeTaskNotification"
                        color="secondary"
                        small
                        :loading="responsePending"
                        :disabled="responsePending || readOnlyDash"
                >
                    <v-icon small>close</v-icon>
                </v-btn>
            </v-flex>
        </v-layout>
    </v-card>
</template>

<script>

    import {codeBaseMixin} from "../../../codeBaseMixin";

    export default {
        mixins: [ codeBaseMixin ],
        props: {
            userInfo: Object,
            task: Object,
            notification: Object,
            readOnlyDash: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                console,
                responsePending: false
            }
        },
        methods: {
            removeTaskNotification() {
                this.responsePending = true;
                this.fetch({method: 'PATCH', url:`/notifications/removeTaskNotification/${this.userInfo.entityID}/${this.notification.id}`})
                    .then(() => {
                        this.responsePending = false;
                        this.$emit('removed')
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e)
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                        this.responsePending = false;
                    });
            }
        }
    }
</script>

<style scoped>
    .blueBorder {border-left: 5px solid blue!important}
</style>
