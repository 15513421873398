<template>
    <v-card class="fill-height scrollable">
        <v-layout column fill-height class="scrollable">
            <v-flex xs12 class="scrollable">
                <v-layout row fill-height class="scrollable">
                    <v-flex xs9 class="scrollable fill-height grey lighten-3">
                        <div class="pa-2">
                            <div class="pa-2">
                                <document-rule-add-btn
                                        @input="addWrapper($event)"
                                        :add-only="rules.length <= 0"
                                        :show-condition="false"
                                />
                            </div>

                            <div v-for="(wrapper, index) in rules" class="pl-2" :key="index">
                                <document-rule-wrapper
                                        :wrapper="wrapper"
                                        :flat-fields="flatFields"
                                        :select-lists="selectLists"
                                        :first-item="index == 0"
                                        @spliceMe="removeConditionChild(index)"
                                />
                            </div>

                        </div>
                    </v-flex>
                    <v-flex xs3 class="scrollable fill-height">
                        <field-drop-selector
                                :datasets="datasets"
                                :steps="steps"
                                :fields="fields"
                                :show-input-fields="false"
                                :field-types="[]"
                        />
                    </v-flex>
                </v-layout>
            </v-flex>
        </v-layout>
    </v-card>
</template>

<script>
    import DocumentRuleAddBtn from './DocumentRuleAddBtn';
    import DocumentRuleWrapper from './DocumentRuleWrapper';
    import {objectifyArr} from "../../../../../../../../codeFunctions";
    import FieldDropSelector from "../../../FieldDropSelector";


    export default {
        components: {
            FieldDropSelector,
            // DocumentContainer,
            DocumentRuleAddBtn,
            DocumentRuleWrapper
        },
        props: {
            showRules: Boolean,
            userInfo: Object,
            datasets: Array,
            fields: Array,
            steps: Array,
            selectLists: Array,
            title: String,
            rules: Array,
        },
        methods: {
            test() {
                var valid = eval(`(10 < 5) || (1 == 1 && 1 < 2)`);
            },

            addWrapper(type) {
                this.rules.push({
                    type: 'W',
                    function: type,
                    children: []
                })
            },

            removeConditionChild(index) {
                this.rules.splice(index, 1);
            },

            queryTypeFromFieldType(fieldType) {
                switch (fieldType) {
                    case 1 : {return 2} // - Word Field
                    case 2 : {return 3} // - Date Field
                    case 3 : {return 1} // - Number Field
                    case 4 : {return 1} // - Currency Field
                    case 5 : {return 6} // - List Field
                    case 6 : {return 4} // - Mail/Document Field
                    case 7 : {return 4} // - Restricted Mail/Document
                    case 8 : {return 4} // - MailTemplate
                    case 9 : {return 4} // - Field Set Select Field
                    case 10 : {return 4} // - Split Task Field
                    case 11 : {return 4} // - Check box list Field
                    case 12 : {return 4} // - Close Task Field
                    case 13 : {return 4} // - Information Field
                    default : {return 4}
                }
            },
        },
        computed: {
            flatFields() {
                return {
                    workflowFields: this.workflowFields,
                    dataSetFields: this.dataSetFields,
                    dataSets: this.dataSets,
                    steps: this.flatSteps
                }
            },
            workflowFields() {
                return this.fields.reduce((obj, field) => {
                    if (field.visible === 1) {
                        let oField = {
                            systemFieldType : field.fieldType,
                            queryFieldType : this.queryTypeFromFieldType(field.fieldType)
                        };
                        Object.assign(oField, field);
                        obj[oField.id] = oField;
                    }
                    return obj
                }, {})
            },
            dataSetFields() {
                return this.datasets
                    .reduce((list, item) => {
                        return list.concat(item.fields)
                    }, [])
                    .reduce((obj, field) => {
                        if (field.visible === 1) {
                            let oField = {
                                systemFieldType : field.type,
                                queryFieldType : this.queryTypeFromFieldType(field.type)
                            };
                            Object.assign(oField, field);
                            obj[oField.id] = oField;
                        }
                        return obj
                    }, {})
            },
            dataSets() {
                let oDataSets = objectifyArr(this.datasets, `id`);
                return this.fields.reduce((obj, field) => {
                    if (field.visible === 1 && field.fieldType === 9) {
                        let oField = {
                            fieldID: field.id,
                            dataSetDescription: oDataSets[parseInt(field.lookup)],
                            fk_dataset: parseInt(field.lookup),
                            fk_step: field.fk_step,
                            fieldDescription: field.description
                        };
                        obj[oField.fieldID] = oField;
                    }
                    return obj
                }, {})
            },
            flatSteps() {
                return this.steps.reduce((obj, field) => {

                        let oField = {
                            id: field.id,
                            description: field.description
                        };
                        obj[oField.id] = oField;
                    return obj
                }, {})
            }
        }
    }
</script>

<style scoped>

</style>
