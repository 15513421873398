<template>
    <div>
        <div class="c-d-flex justify-space-around pt-1" v-if="!value.value">
            <v-btn
                    small
                    class="ma-0"
                    color="primary"
                    @click="showSelectFolder = true"
                    v-if="!lookup.createOnly"
            >
                <v-icon left>folder</v-icon>
                Select Folder
            </v-btn>

            <v-btn
                    small
                    class="ma-0"
                    color="primary"
                    v-if="lookup.allowCreate"
                    @click="showFolderCreate = true"
            >
                <v-icon left>add</v-icon>
                Create New Folder
            </v-btn>
        </div>

        <div class="pt-1" v-else>
            <v-card class="px-2 py-1 primary white--text c-d-flex c-align-center" hover>
                <div class="c-flex-grow-1">
                    <div class="body-2 c-d-flex c-align-center"><v-icon color="yellow darken-2" class="mr-2">folder</v-icon>{{valRef.description}}</div>
                    <div v-if="valRef.groupString" class="caption">{{valRef.groupString}}</div>
                </div>
                <div v-if="!(lookup.disableAfterSave && value.oldValue) && !lookup.createOnly">
                    <v-tooltip top>
                        <template v-slot:activator="{on}">
                            <v-btn
                                    small
                                    icon
                                    flat
                                    color="white"
                                    @click="showSelectFolder = true"
                                    v-on="on"
                                    :disabled="!value.editable || !stepEditable"
                            >
                                <v-icon>edit</v-icon>
                            </v-btn>
                        </template>
                        Select Another Folder
                    </v-tooltip>
                </div>
                <div v-if="lookup.allowCreate && !(lookup.disableAfterSave && value.oldValue)">
                    <v-tooltip top>
                        <template v-slot:activator="{on}">
                            <v-btn
                                    small
                                    icon
                                    flat
                                    color="white"
                                    v-on="on"
                                    @click="showFolderCreate = true"
                                    :disabled="!value.editable || !stepEditable"
                            >
                                <v-icon>add</v-icon>
                            </v-btn>
                        </template>
                        Create New Folder
                    </v-tooltip>
                </div>
            </v-card>
        </div>

        <folder-dlg
                v-if="showSelectFolder"
                :show-dialog="showSelectFolder"
                @dismiss="showSelectFolder = false"
                :get-task-header="getTaskHeader"
                :select-dlg="true"
                :folders="foldersSelect"
                :user-info="userInfo"
                dlg-type="allFoldersSelect"
                v-model="valRef"
                :filter-groups="lookup.folderGroupLimit && lookup.folderGroupLimit.length ? lookup.folderGroupLimit : null"
                :local-data="localData"
                :show-sync="false"
        />
<!--        @appendFolderData="appendFolderData"-->

        <input-dlg
                v-if="showFolderCreate"
                :show-dialog="showFolderCreate"
                :cancel-button="true"
                :show-input-box="false"
                @dismiss="showFolderCreate = false"
                :body-padding="false"
                title="Create New Folder"
        >
            <template slot="body">
                <div class="pt-1">
                    <v-form ref="createFolderForm" :disabled="creatingFolder">

                    </v-form>
                    <div :class="topicClass" class="px-1">
                        <div :class="headingClass">
                            Folder Name:
                        </div>
                        <div :class="itemClass">
                            <v-text-field
                                    label="Folder Name"
                                    single-line
                                    color="primary"
                                    hide-details
                                    style="width: 100%"
                                    class="pa-0 ma-0"
                                    v-model="folderCreateDescription"
                                    :rules="[validationRules.required]"
                            />
                        </div>
                    </div>
                    <div :class="topicClass" class="px-1">
                        <div :class="headingClass">
                            Select Folder Groups:
                        </div>
                    </div>
                    <div style="overflow-y: auto">
                        <multi-select-list
                                :show-action-buttons="false"
                                :show-check-boxes="true"
                                :multiple="true"
                                headline-key="description"
                                item-key="id"
                                :return-object="false"
                                v-model="folderCreateFolderGroups"
                                :show-filter="true"
                                :items="folderCreateFolderGroupsOptions"
                                :height="300"
                                filter-label="Filter Folder Groups"
                        />
                    </div>
                </div>
            </template>
            <template slot="actions">
                <div class="text-xs-right">
                    <v-btn
                            small
                            color="secondary"
                            :disabled="creatingFolder"
                            @click="showFolderCreate = false"
                            outline
                    >
                        Cancel
                    </v-btn>
                    <v-btn
                            small
                            color="secondary"
                            :disabled="!(Array.isArray(folderCreateFolderGroups) && folderCreateFolderGroups.length && folderCreateDescription) || creatingFolder"
                            :loading="creatingFolder"
                            @click="createFolder"
                    >
                        Create Folder
                    </v-btn>
                </div>
            </template>
        </input-dlg>

    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import FolderDlg from "@/Folders/FolderDlg";
import InputDlg from "@/components/General/InputDlg";
import {validationRules} from '@/codeFunctions'
import MultiSelectList from "@/components/Multi Select List/MultiSelectList";
import {DatasetBinder} from "@/Tasks/TaskView V2/components/TaskViewDetail/datasetBinder";
export default {
    components: {MultiSelectList, InputDlg, FolderDlg},
    props: {
        value: Object,
        showDialog: Boolean,
        fields: Array,
        steps: Array,
        fieldSets: Array,
        task: Object,
        step: Object,
        stepEditable: Boolean,
        getTaskHeader: Function,
        localData: Object,
        binder: DatasetBinder
    },
    data() {
        return {
            validationRules: validationRules,
            showSelectFolder: false,
            foldersSelect: {
                folderGroups: [],
                folders: []
            },
            showFolderCreate: false,
            folderCreateDescription: null,
            folderCreateFolderGroups: [],
            topicClass: 'pa-1',
            headingClass: 'caption greyType--text font-weight-bold',
            itemClass: 'pl-2',
            creatingFolder: false,
        }
    },
    watch: {
        showFolderCreate(val) {
            if (val) {
                this.folderCreateFolderGroups = [];
                this.folderCreateDescription = null;
            }
        },
        "value.value": {
            immediate: true,
            handler(val) {
                if (!val && this.lookup.autoSelectCurrentFolder) {
                    if (this.lookup.folderGroupLimit) {
                        // Check That The Current Folder Matches the folder Groups Limit
                        const taskFolder = (this.localData.folders.filter(folder => folder.id == this.task.folderID) || [{folderGroups: []}]).pop()
                        if (this.lookup.folderGroupLimit.filter(limitGroup => taskFolder.folderGroups.includes(limitGroup)).length)
                            this.value.value = this.task.folderID
                    } else this.value.value = this.task.folderID;
                }

                if (this.value.value !== null && val !== null) {
                    this.binder.loadFolderDatasets(this.value.value, this.value.fieldID)
                }
            }
        }
    },
    methods: {
        createFolder() {

            function waitForFolder(folderID, localData) {
                return new Promise((resolve) => {
                    const folder = (localData.folders.filter(folder => folder.id == folderID) || [null]).pop()
                    if (folder) {
                        resolve(folder)
                    } else {
                        setTimeout(() => {
                            resolve(waitForFolder(folderID, localData))
                        }, 300)
                    }
                })
            }

            this.creatingFolder = true;
            this.$newReq('POST', `folders/createFolder/${this.userInfo.entityID}/${this.userInfo.userID}`, {description: this.folderCreateDescription, folderGroups: this.folderCreateFolderGroups})
                    .then(async ({folderID}) => {
                        const createdFolder = await waitForFolder(folderID, this.localData);
                        this.value.value = createdFolder.id
                        this.showFolderCreate = false;
                        this.binder.folderDatasets[folderID] = {};
                        this.$emit('forceSave');
                    })
                    .catch(e => {
                        console.log(e)
                        this.$snack.networkError()
                    })
                    .finally(() => {
                        this.creatingFolder = false;
                    })
        },

    },
    computed: {
        ...mapGetters('userInfo', ['userInfo']),
        lookup() {
            return JSON.parse(this.value.lookup)
        },
        valRef: {
            get() {
                return this.value.value ? (this.localData.folders.filter(folder => folder.id == this.value.value) || [null]).pop() : null
            },
            set(val) {
                this.value.value = val.id
            }
        },
        folderCreateFolderGroupsOptions() {
            let groups = this.localData.folderGroups;

            if (this.lookup.folderGroupLimit && this.lookup.folderGroupLimit.length)
                groups = groups.filter(group => this.lookup.folderGroupLimit.includes(group.id))

            if (this.lookup.onlyGroupsWithAccess)
                groups = groups.filter(group => group.access)

            return groups
        }
    },
}
</script>

<style scoped>

</style>