import {blankString, objectifyArr} from "../../codeFunctions";

export default {
    namespaced: true,
    state() {
        return {
            userInfo: null,
            fieldMaps: null,
            mailConfig: null,
        }
    },
    mutations: {
        setMailConfig(state, payload) {
            state.mailConfig = payload
        },
        setUserInfo(state, payload) {
            state.userInfo = payload;
            if (typeof state.userInfo.entityInfo.fieldMaps != 'undefined' && !blankString(state.userInfo.entityInfo.fieldMaps)) {
                try {
                    state.fieldMaps = objectifyArr(JSON.parse(state.userInfo.entityInfo.fieldMaps), 'destField');
                } catch (e) {
                    state.fieldMaps = null;
                    // eslint-disable-next-line
                    console.log(e);
                }
            }
        },
        setUserInfoProperty(state, payload) {
            try {
                state.userInfo[payload.name] = payload.value;
            } catch (e) {
                // eslint-disable-next-line
                console.log(e)
            }
        },
    },
    actions: {
        setUserInfo(context, payload) {context.commit('setUserInfo', payload)},
        setUserInfoProperty(context, payload) {context.commit('setUserInfoProperty', payload)},
        setMailConfig(context, payload) {context.commit('setMailConfig', payload)},
    },
    getters: {

        folderManager(state) {
            return !!state.userInfo?.folderManager
        },
        contactsManager(state) {
            return !!state.userInfo?.contactsManager
        },

        softBlock(state) {
            return (state.userInfo && state.userInfo.entityInfo && !!state.userInfo.entityInfo.softBlock)
        },
        mailConfig(state) {
            return state.mailConfig
        },
        userInfo(state) {
            return state.userInfo;
        },
        masterAdmin(state) {

            if (!state.userInfo)
                return false

            if (state.userInfo.tasklogixAdmin)
                return true

            return !!((state.userInfo?.username || '').match(/^tlx@/))
        },
        contactsEnabled(state) {
            return !state.userInfo.contactsDisabled && state.userInfo.entityInfo.contactsEnabled == "1"
        },
        userInfoSimple(state) {
            try {
                return {userID: state.userInfo.userID, entity: state.userInfo.entityID};
            } catch(e) {
                return {userID: null, entity: null};
            }
        },
        fieldMaps(state) {
            return state.fieldMaps;
        },
    }
}
