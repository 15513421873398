<template>
  
  <div class="">
    
    <v-text-field
        :label="field.description"
        hide-details
        @click="!loadingDocumentInfo && field.editType !== 3 ? $refs.manualUpload.click() : null"
        readonly
        :value="displayString"
        @dragover.prevent="$event.preventDefault()"
        @drop="$event.preventDefault(); fileDropped($event);"
        :disabled="loadingDocumentInfo"
        append-outer-icon="attach_file"
    >
      <template #prepend-inner v-if="loadingDocumentInfo">
        <v-progress-circular
            :size="18"
            :width="2"
            color="secondary"
            indeterminate
            class="mx-2"
        ></v-progress-circular>
      </template>
      
      
      <template v-if="documentInfo" #append>
        
        <v-tooltip top v-if="documentInfo.secured">
          <template v-slot:activator="{on}">
            <v-btn
                small
                icon
                flat
                color="indigo"
                class="pa-0 ma-0"
                v-on="on"
                @click.stop="showSecurityData = true"
            >
              <v-icon>gpp_maybe</v-icon>
            </v-btn>
          </template>
          PDF is secured (Click for more info)
        </v-tooltip>
        
        <v-tooltip top v-if="!restrictedUsers.includes(userInfo.userID)">
          <template v-slot:activator="{on}">
            <v-btn
                small
                icon
                flat
                color="primary"
                class="pa-0 ma-0"
                v-on="on"
                @click.stop="$newDownload(documentInfo)"
            >
              <v-icon>cloud_download</v-icon>
            </v-btn>
          </template>
          Download / View File
        </v-tooltip>
        
        
      </template>
      
    </v-text-field>
<!--    style="width: 100%; height: 100%; left:0; top:0; position: absolute; opacity: 0"-->
    <input
        ref="manualUpload"
        type="file"
        name="myfile"
        :multiple="false"
        :accept="limitedTypes"
        @input="fileSelected"
    />
    
    
    <input-dlg
        :show-dialog="showSecurityData"
        v-if="showSecurityData"
        title="Security Info"
        :show-input-box="false"
        @dismiss="showSecurityData = false"
        :cancel-button="true"
        :body-padding="false"
        :width="300"
    >
      <div slot="body">
        <div class="c-d-flex align-center">
          <div class="px-2 c-flex-grow-1">
            <div class="px-2 py-1 c-d-flex align-center"><v-icon left>person</v-icon><b>{{ usersRef[documentInfo.secured.userID].name + ' ' + usersRef[documentInfo.secured.userID].surname }}</b></div>
            <div class="px-2 py-1 c-d-flex align-center"><v-icon left>event</v-icon>{{ format(new Date(documentInfo.secured.date), 'YYYY-MM-DD HH:mm')}}</div>
            <v-hover>
              <v-tooltip left slot-scope="{hover}">
                <template v-slot:activator="{on}">
                  <div class="hover-item px-2 py-1 c-d-flex align-center" @click="copyPassword" style="cursor: pointer" v-on="on">
                    <v-icon left>password</v-icon>{{documentInfo.secured.password}}<v-icon right v-if="hover">content_copy</v-icon>
                  </div>
                </template>
                Copy Password To Clipboard
              </v-tooltip>
            </v-hover>
            <input type="text" :value="documentInfo.secured.password" :id="'documentPassword' + documentInfo.secured.password" style="width: 3px; height: 3px; opacity: 0; position: fixed"/>
          </div>
        </div>
      </div>
      <div slot="actions">
        <v-btn
            small
            color="secondary"
            class="pa-0 ma-0"
            @click="showSecurityData = false"
        >
          done
        </v-btn>
      </div>
    </input-dlg>
    
  </div>
  
  
  
  
</template>

<script>
import {mapGetters} from "vuex";
import InputDlg from "@/components/General/InputDlg.vue";
import format from "date-fns/format";
import {copyText} from "@/codeFunctions";

export default {
  components: {InputDlg},
  props: {
    field: Object,
    value: [String, Number, File]
  },
  data() {
    return {
      format,
      console,
      uploadFile: null,
      documentInfo: null,
      loadingDocumentInfo: false,
      showSecurityData: false
    }
  },
  
  watch: {
    value: {
      immediate: true,
      handler(val) {
        if (typeof val == 'string' || typeof val == 'number') {
          this.uploadFile = null;
          this.documentInfo = null;
          this.loadingDocumentInfo = false;
          this.showSecurityData = false;
          this.init()
        }
      }
    }
  },
  
  methods: {
    
    copyPassword() {
      // let copyText = document.getElementById("documentPassword" + this.securityInfo.password);
      // copyText.select();
      // document.execCommand("copy");
      // this.$snack.info('Password Copied')
      
      copyText(document, this.documentInfo.secured.password)
      
    },
    
    async init() {
      try {
        this.loadingDocumentInfo = true
        this.documentInfo = await this.$newReq('GET', `contacts/documentInfo/${this.value}`)
        
        console.log('DOCINFO', this.documentInfo)
      } catch (e) {
        console.log(e)
        this.$snack.networkError();
      } finally {
        this.loadingDocumentInfo = false
      }
    },
    
    
    processFileList(files) {
      function checkFileType(mimeList, file) {
        let list = mimeList
            .split(',')
            .map(str => {try {return str.trim().toLowerCase()} catch (e) {return ''}})
        let typeList = list
            .filter(str => str && str.substr(0,1) !== '.')
        let extList = list
            .filter(str => str && str.substr(0,1) === '.')
            .map(str => {
              try {
                return str.substring(1)
              } catch (e) {
                return ''
              }
            })
        let ext = file.name ? file.name.split('.').pop().toLowerCase() : ''
        let type = file.type ? file.type.toLowerCase().trim() : ''
        return typeList.includes(type) || extList.includes(ext)
      }
      
      if (files.length > 1)
        return this.$snack.error('Only one file allowed');
      
      for (let i = 0; i < files.length; i++) {
        let file = files[i];
        if (((file.size / 1024) / 1024) < 50) {
          
          if (!checkFileType(this.limitedTypes, file)) {
            this.$refs.manualUpload.value = null;
            return this.$snack.error('File Upload Canceled - Invalid File Type');
          }
          
          this.uploadFile = file;
          this.$emit('input', file)
          
        } else {
          this.$refs.manualUpload.value = null;
          this.$snack.error('File Upload Canceled - File To Large');
        }
      }
    },
    fileSelected(ev) {
      this.processFileList(ev.target.files)
    },
    fileDropped(ev) {
      this.processFileList(ev.dataTransfer.files)
    }
  },
  computed: {
    ...mapGetters('userInfo', ['userInfo']),
    ...mapGetters(['usersRef']),
    
    restrictedUsers() {
      if (this.documentInfo && this.documentInfo.restrictedUsers && Array.isArray(this.documentInfo.restrictedUsers))
        return this.documentInfo.restrictedUsers
      else if (this.documentInfo && this.documentInfo.restrictedUsers && typeof this.documentInfo.restrictedUsers == 'string')
        return JSON.parse(this.documentInfo.restrictedUsers)
      else
        return []
    },
    
    limitedTypes() {
      if (!this.field.lookup || !this.field.lookup.limitToType || this.field.lookup.limitToType === 1)
        return this.userInfo.entityInfo.mimeTypeList
      else if (this.field.lookup.limitToType === 2)
        return 'application/pdf'
      else if (this.field.lookup.limitToType === 3)
        return 'image/apng,image/avif,image/gif,image/jpeg,image/png,image/svg+xml,image/webp,image/bmp,image/x-icon,image/tiff,image/heif,image/heic'
    },
    
    displayString() {
      if (this.loadingDocumentInfo)
        return 'Loading...'
      
      if (this.uploadFile && this.uploadFile.name)
        return this.uploadFile.name
      
      if (this.documentInfo)
        return this.documentInfo.alias || this.documentInfo.fileName
      
      return null
    }
  },

}
</script>



<style scoped>
input[type="file"] {
  display: none;
}
</style>