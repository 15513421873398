<template>

        <!--Node Body-->
        <div
                style="width: 100%; overflow-y: auto; overflow-x: hidden"
                class="nodeContent centered-row hide-parent" :class="selected ? 'selected' : ''"
        >
<!--Icon-->
            <v-icon
                    :color="selected ? 'primary' : 'grey'"
                    style="margin: 0 3px 0 3px"
                    @click="$emit('select', node.key)"
            >
                contact_page
            </v-icon>
<!--Item-->
            <div style="width: 100%; overflow-y: auto; overflow-x: hidden" @click="$emit('select', node.key)">
                <div style="margin: 3px 0 2px 0; overflow-y: auto; overflow-x: hidden" :class="selected ? 'selected' : ''">
                    <div style="line-height: 1" class="text-truncate">
                        {{node.description}}
                    </div>
                    <div class="caption text-truncate" style="overflow-y: auto; overflow-x: hidden; line-height: 1">
                        {{documentDisplayDate}}
                    </div>
                </div>
            </div>

            <!--Buttons-->
            <div class="hide-child">

                <v-tooltip top>
                    <v-btn
                            small
                            icon
                            flat
                            color="error"
                            class="pa-0 ma-0"
                            style="width: 24px; height: 24px"
                            slot="activator"
                            @click="$emit('deleteUserDoc', node)"
                    >
                        <v-icon>delete</v-icon>
                    </v-btn>
                    Remove From User Store
                </v-tooltip>


            </div>
        </div>
</template>

<script>
import format from "date-fns/format";

export default {
    props: {
        node: Object,
        selectedKeys: Array
    },
    computed: {
        selected() {
            return this.selectedKeys.includes(this.node.key)
        },
        documentDisplayDate() {
            return format(this.node.added, 'Do MMMM YYYY  h:mm A')
        }
    }
}
</script>

<style scoped>

/*.nodeContent:hover {*/
/*    background: #e8e8e8;*/
/*}*/

.nodeContent {
    cursor: pointer;
    border-radius: 3px;
    margin: 1px 0 1px 0;
    padding-right: 3px;
}

/*.nodeContent.selected:hover {*/
/*    background: #d0d0d0;*/
/*}*/
.selected {
    background: #ececec;
}
</style>