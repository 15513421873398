<template>
    <v-layout column fill-height class="scrollable" style="overflow-x: hidden">
        <v-flex shrink>
            <v-card-title class="white--text pa-0 ma-0 primary">
                <v-layout column>
                    <v-flex shrink>
                        <v-layout row align-center>
                            <v-flex xs12 class="pa-2">
                                Select a Folder
                            </v-flex>
                            <v-flex shrink v-if="userInfo.showAllFolders === 1">
                                <v-tooltip top>
                                    <!--                            @change="allFoldersView ? $emit('allfolders', true) : $emit('allfolders', false)"-->
                                    <v-switch

                                            label=""
                                            color="secondary"
                                            hide-details
                                            class="pa-0 ma-0"
                                            v-model="allFoldersView"
                                            slot="activator"
                                    ></v-switch>
                                    Show All Folders
                                </v-tooltip>
                            </v-flex>
                        </v-layout>
                    </v-flex>
<!--                    <v-flex shrink >-->
<!--                        <v-progress-linear-->
<!--                                :indeterminate="showSync"-->
<!--                                height="3"-->
<!--                                class="pa-0 ma-0"-->
<!--                                color="secondary"-->
<!--                        ></v-progress-linear>-->
<!--                    </v-flex>-->
                </v-layout>
                <div></div>

            </v-card-title>
        </v-flex>
        <v-flex xs12 class="scrollable">
            <v-layout style="overflow-y: auto;" column fill-height>

                <v-flex shrink >
<!--                    <div class="caption greyType--text text-xs-center" style="width: 100%">-->
<!--                        *Search Starts After 4 Characters or more-->
<!--                    </div>-->
                    <v-layout row align-center>
                        <v-flex shrink>
                            <v-tooltip right slot="activator" v-if="!softBlock && typeof userInfo.createFolder != 'undefined' && intToBool(userInfo.createFolder)">
                                <v-btn
                                        @click="showAddFolder = true"
                                        slot="activator"
                                        icon
                                        class="pa-0 ma-0"
                                        color="secondary"
                                        flat
                                >
                                    <v-icon>add</v-icon>
                                </v-btn>
                                <span>Create a New Folder</span>
                            </v-tooltip>
                        </v-flex>
<!--                        <v-flex shrink class="pr-2">-->
<!--                            <v-tooltip right slot="activator" v-if="typeof userInfo.createFolder != 'undefined' && intToBool(userInfo.createFolder)">-->
<!--                                <v-btn-->
<!--                                        slot="activator"-->
<!--                                        icon-->
<!--                                        class="pa-0 ma-0"-->
<!--                                        color="primary"-->
<!--                                        flat-->
<!--                                        @click="$emit('refreshFolders')"-->
<!--                                >-->
<!--                                    <v-icon>refresh</v-icon>-->
<!--                                </v-btn>-->
<!--                                <span>Refresh Folders</span>-->
<!--                            </v-tooltip>-->
<!--                        </v-flex>-->
                        <v-flex xs12>
                            <v-text-field
                                    single-line
                                    label="Search Folders"
                                    clearable
                                    prepend-icon="search"
                                    color="primary"
                                    flat
                                    v-model="searchRelay"
                                    hide-details
                                    class="pa-0 ma-0 pr-2 pt-1"
                                    ref="myField"
                                    @click:clear="resetSelectList"
                            >
                                <template slot="append-outer">
                                    <div class="pt-1" v-if="searchRelayTimeout">
                                        <v-progress-circular
                                                :size="18"
                                                :width="2"
                                                color="primary"
                                                indeterminate
                                        ></v-progress-circular>
                                    </div>
                                </template>
                            </v-text-field>
                        </v-flex>
                    </v-layout>
                </v-flex>
                <v-flex shrink class="pa-2">
                    <v-autocomplete
                            :items="displayGroups"
                            clearable
                            color="primary"
                            flat
                            v-model="selectedFolderGroups"
                            hide-details
                            class="pa-0 ma-0"
                            single-line
                            item-text="description"
                            item-value="id"
                            dense
                            multiple
                            small-chips
                            deletable-chips
                            chips
                            label="Select Folder Groups"
                            prepend-icon="filter_list"
                    >
                    </v-autocomplete>
                </v-flex>
                <v-flex shrink  class="pl-2">
                    <v-divider></v-divider>
                </v-flex>
                <v-flex ref="folderBar" xs12 class="scroll" style="overflow-y: auto; overflow-x: hidden" id="folderList">
                    <RecycleScroller
                            :items="selectedFoldersAfterFolderGroups"
                            :item-size="42"
                            key-field="id"
                            style="height: 100%"
                    >
                        <div
                                slot-scope="folder"
                                :key="folder.item.id"
                                :id="`folder${folder.item.id}`"
                                @click="selectFolder(folder.item)"
                                style="cursor: pointer; overflow-x: hidden; height: 100%"
                                @contextmenu="show($event, folder.item.id)"
                        >
                            <v-layout
                                    fill-height
                                    row
                                    align-center
                                    class="py-1 hover-item"
                                    :style="{background: value == folder.item.id ? 'var(--selected-item)' : null}"
                                    @dragover="$event.preventDefault()"
                                    @drop="dropped($event, folder.item)"
                            >
                                <v-flex shrink class="pl-2">

                                    <v-btn small flat icon color="primary" class="pa-0 ma-0">
                                        <v-icon :class="value == folder.item.id ? 'amber--text' : 'greyType--text'">
                                          {{folder.item.contactList ? 'contacts' : (value == folder.item.id ? 'folder' : 'folder_open')}}
                                        </v-icon>
                                    </v-btn>
                                </v-flex>
                                <v-flex xs12>
                                    <v-layout row align-center>
                                        <v-flex xs12>
                                            <v-layout column justify-center class="pl-2">
                                                <v-flex shrink :class="value == folder.item.id ? 'body-2' : 'body-1'" style="overflow-x: hidden" class="text-truncate pb-1">
                                                    {{folder.item.description}}
                                                </v-flex>

                                                <v-flex
                                                        v-if="folder.item.groupString != null && folder.item.groupString != ''"
                                                        shrink
                                                        class="caption greyType--text text-truncate"
                                                        style="overflow-x: hidden"
                                                >
                                                    {{ folder.item.groupString }}
                                                </v-flex>
                                                <v-flex shrink v-else class="caption red--text font-weight-bold">
                                                    (No Groups Assigned)
                                                </v-flex>

                                            </v-layout>
                                        </v-flex>
                                        <v-flex shrink v-if="!folder.item.access" class="pr-2">
                                            <v-tooltip top>
                                                <v-icon class="greyType--text" slot="activator">security</v-icon>
                                                <span>Folder Access Denied</span>
                                            </v-tooltip>
                                        </v-flex>
                                    </v-layout>
                                </v-flex>
                            </v-layout>
                            <v-divider></v-divider>
                        </div>
                    </RecycleScroller>
                </v-flex>


                <app-input-dlg
                        v-if="dropFolder != null && dragTask != null && showConfirmMoveTask"
                        :show-dialog="showConfirmMoveTask"
                        title="Please Confirm Move Task"
                        :cancel-button="true"
                        :show-input-box="false"
                        :buttons="['Confirm']"
                        :body="`Move Task ${dragTask} to the Folder ${dropFolder.description}`"
                        :call-backs="[ moveTaskConfirmed ]"
                        @dismiss="showConfirmMoveTask = false"
                />

                <app-input-dlg
                        v-if="dropFolder != null && dragFieldSet != null"
                        :show-dialog="showConfirmMove"
                        title="Confirm Move"
                        :cancel-button="true"
                        :show-input-box="false"
                        @dismiss="showConfirmMove = false"
                        :body-padding="false"
                        :width="500"
                >
                    <template slot="body">
                        <div class="pa-2">
                            {{`Move Dataset "${dragFieldSet.fieldSetName}${dragFieldSet.fieldSetValueName != null && dragFieldSet.fieldSetValueName != "" ? ` - (${dragFieldSet.fieldSetValueName})` : ``}" From "${dragFieldSet.folderName}" To the Folder "${dropFolder.description}"`}}
                            <br>
                            Would you like to move the dataset only or all tasks as well
                        </div>
                        <v-divider></v-divider>
                        <div class="text-xs-left pa-1 px-2">
                            <v-layout row align-center>
                                <v-flex xs12 class="caption greyType--text">{{`Dataset and Tasks only where "${dragFieldSet.fieldSetName}" was used`}}</v-flex>
                                <v-flex shrink>
                                    <v-btn small color="secondary" class="py-0 ma-0" @click="moveConfirmedTasksWhereUsed">Move</v-btn>
                                </v-flex>
                            </v-layout>
                        </div>
                        <div class="text-xs-left pa-1 px-2">
                            <v-layout row align-center>
                                <v-flex xs12 class="caption greyType--text">Dataset and all Tasks</v-flex>
                                <v-flex shrink>
                                    <v-btn small color="secondary" class="py-0 ma-0" @click="moveConfirmedTasks">Move</v-btn>
                                </v-flex>
                            </v-layout>
                        </div>
                        <div class="text-xs-left pa-1 px-2">
                            <v-layout row align-center>
                                <v-flex xs12 class="caption greyType--text">Dataset only</v-flex>
                                <v-flex shrink>
                                    <v-btn small color="secondary" class="py-0 ma-0" @click="moveConfirmed">Move</v-btn>
                                </v-flex>
                            </v-layout>
                        </div>
                    </template>
                </app-input-dlg>



            </v-layout>
        </v-flex>
        <app-input-dlg
                v-if="showAddFolder"
                :show-dialog="showAddFolder"
                title="Add Folder"
                :cancel-button="true"
                :show-input-box="false"
                @dismiss="showAddFolder = false"
                :body-padding="false"
        >
            <template slot="body">
                <div class="pa-2">
                    <div :class="topicClass">
                        <div :class="headingClass">
                            New Folder Description:
                        </div>
                        <div :class="itemClass">
                            <v-text-field
                                    label="Folder Description"
                                    single-line
                                    v-model="addFolderDescription"
                                    color="primary"
                                    hide-details
                                    class="ma-0 pa-0"
                                    ref="addFolderInput"
                            ></v-text-field>
                        </div>
                    </div>
                    <div :class="topicClass">
                        <div :class="headingClass">
                            Folder Groups:
                        </div>
                        <div :class="itemClass">
                            <v-autocomplete
                                    dense
                                    v-model="addFolderFolderGroups"
                                    :items="allDisplayGroups"
                                    label="Select Folder Groups"
                                    hide-details
                                    single-line
                                    color="primary"
                                    item-text="description"
                                    item-value="id"
                                    multiple
                                    small-chips
                                    deletable-chips
                                    class="pa-0 ma-0"
                            ></v-autocomplete>
                        </div>
                    </div>
                </div>
<!--                                        <template v-if=" Array.isArray(similarFolders) && similarFolders.length > 0 && allDisplayGroups.length > 0">-->
<!--                                            <div class="pb-2 px-2">-->
<!--                                                <div :class="topicClass">-->
<!--                                                    <div :class="headingClass">-->
<!--                                                        Similar Folders <sup>{{`*${topFolders} Similar Folder/s Found${topFolders >= 10 ? ' Only 10 displayed' : ''}`}}</sup>-->
<!--                                                    </div>-->
<!--                                                </div>-->
<!--                                            </div>-->
<!--                                            <v-divider></v-divider>-->
<!--                                            <div style="overflow-y: auto; max-height: 250px">-->
<!--                                                <div-->
<!--                                                        v-for="(folder, index) in similarFolders"-->
<!--                                                        :key="`similarFolder${folder.id}`"-->
<!--                                                        @click="selectFolder(folder); showAddFolder = false"-->
<!--                                                        style="cursor: pointer"-->

<!--                                                >-->
<!--                                                    <v-layout-->
<!--                                                            v-if="index <= 9"-->
<!--                                                            fill-height-->
<!--                                                            row-->
<!--                                                            align-center-->
<!--                                                            class="py-1"-->
<!--                                                            :class="folder.selected ? 'grey lighten-3' : 'hover-item'"-->
<!--                                                            @dragover="$event.preventDefault()"-->
<!--                                                            @drop="dropped($event, folder)"-->
<!--                                                    >-->
<!--                                                        <v-flex shrink class="pl-2">-->

<!--                                                            <v-btn small flat icon color="primary" class="pa-0 ma-0">-->
<!--                                                                <v-icon :class="folder.selected ? 'amber&#45;&#45;text' : 'grey&#45;&#45;text'">-->
<!--                                                                    {{ folder.selected ? 'folder' : 'folder_open' }}-->
<!--                                                                </v-icon>-->
<!--                                                            </v-btn>-->
<!--                                                        </v-flex>-->
<!--                                                        <v-flex xs12>-->
<!--                                                            <v-layout row align-center>-->
<!--                                                                <v-flex xs12>-->
<!--                                                                    <v-layout column justify-center class="pl-2">-->
<!--                                                                        <v-flex shrink :class="folder.selected ? 'primary&#45;&#45;text body-2' : 'body-1'">-->
<!--                                                                            {{folder.name}}-->
<!--                                                                        </v-flex>-->

<!--                                                                        <v-flex-->
<!--                                                                                v-if="folder.groupNamesString != null"-->
<!--                                                                                shrink-->
<!--                                                                                class="caption grey&#45;&#45;text"-->
<!--                                                                        >-->
<!--                                                                            {{ folder.groupNamesString }}-->
<!--                                                                        </v-flex>-->
<!--                                                                        <v-flex shrink v-else class="caption red&#45;&#45;text font-weight-bold">-->
<!--                                                                            (No Groups Assigned)-->
<!--                                                                        </v-flex>-->

<!--                                                                    </v-layout>-->
<!--                                                                </v-flex>-->
<!--                                                                <v-flex shrink v-if="!intToBool(folder.access)" class="pr-2">-->
<!--                                                                    <v-tooltip top>-->
<!--                                                                        <v-icon class="grey&#45;&#45;text" slot="activator">security</v-icon>-->
<!--                                                                        <span>Folder Access Denied</span>-->
<!--                                                                    </v-tooltip>-->
<!--                                                                </v-flex>-->
<!--                                                            </v-layout>-->
<!--                                                        </v-flex>-->
<!--                                                    </v-layout>-->
<!--                                                    <v-divider></v-divider>-->
<!--                                                </div>-->
<!--                                            </div>-->
<!--                                        </template>-->

            </template>
            <template slot="actions">
                <v-btn
                        class="ma-0"
                        small
                        color="secondary"
                        @click="createNewFolder"
                        :disabled="disableAddFolder"
                        :loading="addingFolder"
                >
                    <v-icon left>add</v-icon>
                    Add Folder
                </v-btn>
            </template>
        </app-input-dlg>

        <v-menu
            v-if="!softBlock"
                v-model="showFolderMenu"
                :position-x="x"
                :position-y="y"
                absolute
                offset-y
        >
            <v-card @mouseleave="showFolderMenu = false">
                <div class="c-d-flex c-align-center hover-item pa-2 pr-2" @click="$createCalendarEvent({start: new Date(), fkFolder: createEventItemID})" >
                    <v-icon class="pr-2">event</v-icon>
                    Create Calendar Event
                </div>
                <div class="c-d-flex c-align-center hover-item pa-2 pr-2" @click="showFolderMenu = false">
                    <v-icon class="pr-2">close</v-icon>
                    Cancel
                </div>
            </v-card>
        </v-menu>

    </v-layout>
</template>

<script>

   import { eventBus } from "../../main";
   import { codeBaseMixin } from "../../codeBaseMixin";
   import InputDlg from '../../components/General/InputDlg';
   import {mapGetters} from 'vuex'

   export default {
       mixins: [codeBaseMixin],
       components: {
           appInputDlg: InputDlg,
       },
       props: {
           value: {
               type: Number,
               default: -1
           },
           selectedFolder: {
               type: Object,
               default: () => {
                   return {
                       id: -1
                   }
               }
           },
           // userInfo: Object,
           filterGroups: {
               type: Array,
               default: null
           },
           removeContactLists: {
             type: Boolean,
             default: false
           },
           showAllFolderChoice: {
               type: Boolean,
               default: false
           },
           localData: Object,
           showSync: Boolean,
           foldersWithSecurity: Array,
         contactListsOnly: {
           type: Boolean,
           default: false
         },
       },
       data() {
           return {
               allFoldersView: false,
               search: '',
               searchRelay: '',
               searchRelayTimeout: null,
               maxWidth: 0,
               createGroupDescription: null,
               creatingFolderGroup: false,
               showAddFolder: false,
               dragFieldSet: null,
               dropFolder: null,
               showConfirmMove: false,
               resetList: false,
               addFolderDescription: null,
               addFolderFolderGroups: null,
               addingFolder: false,
               loadingSimilar: false,
               similarRun: 0,
               selectedFolderGroups: null,
               topFolders: 0,
               localFolders: [],
               flatFolderGroups: {},
               showConfirmMoveTask: false,
               dragTask: null,
               showFolderMenu: false,
               x: 0,
               y: 0,
               createEventItemID: null,
           }
       },
       watch: {

           searchRelay(val) {
               if (this.searchRelayTimeout)
                   clearTimeout(this.searchRelayTimeout)

               this.searchRelayTimeout = setTimeout(() => {
                   this.search = val
                   this.searchRelayTimeout = null
               }, 1000)
           },


           // search() {
           //     this.selectedFolderGroups = null;
           // },
           // showAddFolder(shown) {
           //     if (shown) {
           //         this.selectedFolderGroups = null
           //         this.$nextTick(() => {
           //             setTimeout(() => {
           //                 this.$refs.addFolderInput.focus();
           //             }, 350)
           //         })
           //     }
           // },
           //
           // 'folders.folders' : {
           //     immediate: true,
           //     deep: true,
           //     handler(folders) {
           //
           //         this.flatFolderGroups = this.folders.folderGroups.reduce((obj, item) => {
           //             obj[item.id] = item;
           //             return obj
           //         }, {});
           //
           //         let tFolders = folders
           //             .filter(() => true)
           //             // Remove All Folders That Should Not Be There Based on the limit folder groups
           //             // & Add A Parsed Json Key for the folder group Lookup
           //             .reduce((list, item) => {
           //                 let parsedGroups = !this.blankString(item.folderGroups)
           //                     ? JSON.parse(item.folderGroups)
           //                     : [];
           //
           //                 let tAdd = false;
           //
           //                 if (Array.isArray(this.filterGroups) && this.filterGroups.length >= 1) {
           //                     if (parsedGroups.filter(element => this.filterGroups.includes(element)).length >= 1) {
           //                         tAdd = true;
           //                     }
           //                 } else {
           //                     tAdd = true;
           //                 }
           //
           //                 if (tAdd) {
           //                     let tItem = {};
           //                     Object.assign(tItem, item, {jFolderGroups: parsedGroups});
           //                     // Add The Group Description Here As Well
           //                     tItem.groupNames = parsedGroups.map(obj => {
           //                         return typeof this.flatFolderGroups[obj] != 'undefined' && !this.blankString(this.flatFolderGroups[obj].name) ? this.flatFolderGroups[obj].name : null
           //                     });
           //                     tItem.groupNamesString = tItem.groupNames.join(', ');
           //                     list.push(tItem);
           //                 }
           //                 return list
           //             }, [])
           //             .sort((a,b) => a['name'] < b['name'] ? -1 : 1);
           //
           //
           //         this.localFolders = tFolders;
           //     }
           // }
       },
       methods: {
           // setTopFolders(val) {
           //     this.topFolders = val
           // },

           show(e, itemID) {
               this.createEventItemID = itemID || null
               e.preventDefault()
               this.showFolderMenu = false
               this.x = e.clientX
               this.y = e.clientY
               this.$nextTick(() => {
                   this.showFolderMenu = true
               })
           },

           resetSelectList() {
               this.resetList = true;
               this.$nextTick(() => {
                   this.resetList = false;
               })
           },
           moveConfirmed() {
               this.showConfirmMove = false;
               this.fetch({method: 'GET', url:`/folders/moveDataset/${this.userInfo.entityID}/${this.dropFolder.id}/${this.dragFieldSet.fieldSet.memberID}`})
                   .then(() => {
                       // this.selectFolder(this.dropFolder);
                       this.showSnack('Info', 'Dataset Moved', 'Close', 'primary');
                       this.$emit('refresh')
                   })
                   .catch((e) => {
                       // eslint-disable-next-line
                       console.log(e)
                       this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                   })
           },
           moveConfirmedTasks() {
               this.showConfirmMove = false;
               this.fetch({method: 'GET', url:`/folders/moveDataset/${this.userInfo.entityID}/${this.dropFolder.id}/${this.dragFieldSet.fieldSet.memberID}/AndTasks`})
                   .then(() => {
                       // this.selectFolder(this.dropFolder);
                       this.showSnack('Info', 'Dataset And Tasks Moved', 'Close', 'primary');
                       this.$emit('refresh')
                   })
                   .catch((e) => {
                       // eslint-disable-next-line
                       console.log(e)
                       this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                   })
           },
           moveConfirmedTasksWhereUsed() {
               this.showConfirmMove = false;
               this.fetch({method: 'GET', url:`/folders/moveDataset/${this.userInfo.entityID}/${this.dropFolder.id}/${this.dragFieldSet.fieldSet.memberID}/AndTasksWhereUsed`})
                   .then(() => {
                       // this.selectFolder(this.dropFolder);
                       this.showSnack('Info', 'Dataset Moved', 'Close', 'primary');
                       this.$emit('refresh')
                   })
                   .catch((e) => {
                       // eslint-disable-next-line
                       console.log(e)
                       this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                   })
           },
           moveTaskConfirmed() {
               this.showConfirmMoveTask = false;
               this.fetch({method: 'GET', url:`/folders/moveTask/${this.userInfo.entityID}/${this.dropFolder.id}/${this.dragTask}`})
                   .then(() => {
                       // this.selectFolder(this.dropFolder);
                       this.showSnack('Info', 'Task Moved', 'Close', 'primary');
                       this.$emit('refresh')
                   })
                   .catch((e) => {
                       // eslint-disable-next-line
                       console.log(e)
                       this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                   })
           },
           dropped(event, folder) {
               let dragData = JSON.parse(event.dataTransfer.getData("text"));

               if (typeof dragData.isTask == "undefined") {
                   this.dragFieldSet = dragData;
                   this.dropFolder = folder;
                   this.showConfirmMove = true;
               } else {
                   this.dragTask = dragData.taskID;
                   this.dropFolder = folder;
                   this.showConfirmMoveTask = true;
               }
           },
           createNewFolder() {
               this.addingFolder = true;
               this.fetch({method: 'POST', url: `/folders/createFolder/${this.userInfo.entityID}/${this.userInfo.userID}`, body: JSON.stringify({
                       description: this.addFolderDescription,
                       folderGroups: this.addFolderFolderGroups
                   })})
                   .then((data) => {
                        // TODO Remove Filter And And use a referance array and check if the folder is populated instead
                       let awaitInterval = setInterval(() => {
                           if (this.localData.folders.filter(obj => obj.id === data.folderID).length >= 1) {
                               clearInterval(awaitInterval);
                                       this.showAddFolder = false;
                                       this.addingFolder = false;

                                       this.showSnack('Info', 'Folder Added', 'Close', 'primary');

                                       this.addFolderDescription = null;
                                       this.addFolderFolderGroups = [];

                                       this.$emit('input', data.folderID);
                           }
                       }, 100)




                       // this.waitForAddToAnArray(this.localData.folders, 'dbid', data.folderID)
                       //     .then(() => {
                       //         console.log('finally got the couch folder', new Date())
                       //         this.showAddFolder = false;
                       //         this.addingFolder = false;
                       //
                       //         this.showSnack('Info', 'Folder Added', 'Close', 'primary');
                       //
                       //         this.addFolderDescription = null;
                       //         this.addFolderFolderGroups = [];
                       //         console.log('added', data.folderID)
                       //
                       //         // this.$emit('input', data.folderID);
                       //     })
                       //     .catch(err => {
                       //         // eslint-disable-next-line
                       //         console.log(err);
                       //         this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                       //         this.creatingFolderGroup = false;
                       //         this.addingFolder = false;
                       //     })
                   })
                   .catch((e) => {
                       // eslint-disable-next-line
                       console.log(e);
                       this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                       this.creatingFolderGroup = false;
                       this.addingFolder = false;
                   })
           },
           // createFolderGroup() {
           //     if (this.createGroupDescription != null && this.createGroupDescription != '') {
           //         this.creatingFolderGroup = true;
           //
           //         this.fetch({method: 'POST', url: '/folders/createFolderGroup/' + this.userInfo.entityID, body: JSON.stringify({description: this.createGroupDescription})})
           //             .then((data) => {
           //
           //                 this.folders.folderGroups.push({
           //                     id: data.id,
           //                     name: data.description,
           //                     dropped:true,
           //                     shown:true
           //                 });
           //                 eventBus.$emit('folderGroupAdded', data)
           //
           //                 this.showSnack('Info', 'Folder Group Added', 'Close', 'primary')
           //
           //                 this.createGroupDescription = null;
           //                 this.creatingFolderGroup = false;
           //             })
           //             .catch((e) => {
           //                 // eslint-disable-next-line
           //                 console.log(e);
           //                 this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
           //                 this.creatingFolderGroup = false;
           //             })
           //     } else {
           //         this.showSnack('Error', 'A Description is Required', 'Close', 'red')
           //     }
           // },
           selectFolder(folder) {

               // folder.selected = true;
               //
               // this.folders.folders.forEach((folderLoop) => {
               //     console.log('Hello')
               //     console.log(folderLoop.id == folder.id)
               //     folderLoop.selected = folderLoop.id == folder.id;
               // });

               this.$emit('input', folder.id);

               // this.$forceUpdate();
           },
       },
       computed: {

           ...mapGetters('userInfo', ['userInfo', 'softBlock']),

           selectedFoldersAfterFolderGroups() {
               return Array.isArray(this.selectedFolderGroups) && this.selectedFolderGroups.length >= 1
                   ? this.filteredFolders.filter(obj => Array.isArray(obj.folderGroups)
                       && obj.folderGroups.filter(group => this.selectedFolderGroups.includes(group)).length > 0)
                   : this.filteredFolders
           },

           allDisplayGroups() {
               try {
                   return this.localData.folderGroups.filter(() => true).sort((a,b) => a['name'] < b['name'] ? -1 : 1);
               } catch (e) {
                   console.log(e);
                   return [];
               }
           },

           displayGroups() {
               try {
                   return this.localData.folderGroups
                        .filter((obj) => obj.access)
                        .sort((a,b) => a['description'] < b['description'] ? -1 : 1);

               } catch (e) {
                   console.log(e)
                   return [];
               }
           },
           filteredFolders() {
               try {
                   return this.foldersWithSecurity
                       .reduce((list, folder) => {
                           let bAdd = true;
                           // Check For All Folders
                           if (!this.allFoldersView) {
                               if (!folder.access && !folder.taskOnlyAccess) {
                                   bAdd = false
                               }
                           }
                           // Check The Search String
                           if (!this.blankString(this.search)) {
                               if (!`${folder.description} ${!this.blankString(folder.searchStr) ? folder.searchStr : ''}`.toLowerCase().includes(this.search.toLowerCase())) {
                                   bAdd = false
                               }
                           }
                           if (bAdd) {list.push(folder)}
                           return list
                       },[])
                       .filter(v => {
                         if (!this.contactListsOnly && !this.removeContactLists)
                           return true
                         else if (this.contactListsOnly)
                           return v.contactList
                         else
                           return !v.contactList
                       })
                       .sort((a,b) => a['description'] < b['description'] ? -1 : 1);
               } catch (e) {
                   // eslint-disable-next-line
                   console.log(e)
                   return []
               }
           },
           //
           // filteredFoldersAfterGroups() {
           //     try {
           //         if (Array.isArray(this.selectedFolderGroups) && this.selectedFolderGroups.length > 0) {
           //             return this.filteredFolders.filter(obj => obj.jFolderGroups.filter(oGroup => this.selectedFolderGroups.includes(oGroup)).length > 0);
           //         } else {
           //             return this.filteredFolders;
           //         }
           //     } catch (e) {
           //         // eslint-disable-next-line
           //         return []
           //     }
           //
           // },
           //
           // flatFilterFolders() {
           //     try {
           //         if (this.foldersLoaded) {
           //             return this.filteredFolders.reduce((list, item) => {
           //                 let tGroups = [];
           //                 if (typeof item.folderGroups != 'undefined' && !this.blankString(item.folderGroups)) {
           //                     tGroups = JSON.parse(item.folderGroups)
           //
           //                     tGroups.forEach((group) => {
           //                         if (typeof list[group] == 'undefined') {
           //                             list[group] = {
           //                                 description: this.folders.folderGroups.filter(obj => obj.id == group).map(obj => {return obj.name}).pop(),
           //                                 folders: []
           //                             }
           //                         }
           //                         list[group].folders.push(item.id)
           //                     })
           //                 }
           //                 return list
           //             }, {})
           //         } else {
           //             return {}
           //         }
           //
           //     } catch (e) {
           //         // eslint-disable-next-line
           //         console.log(e)
           //         return {}
           //     }
           // },
           // displayGroups() {
           //     return Object.keys(this.flatFilterFolders).map((key) => {
           //         return {
           //             id: key,
           //             description: this.flatFilterFolders[key].description,
           //             count: this.flatFilterFolders[key].folders.length,
           //             folders: this.flatFilterFolders[key].folders
           //         }
           //     }).sort((a,b) => a['description'] < b['description'] ? -1 : 1);
           // },
           // folderGroupsSorted() {
           //     try {
           //         return this.folders.folderGroups
           //             .filter(() => true)
           //             .sort((a,b) => a['description'] < b['description'] ? -1 : 1);
           //
           //     } catch (e) {
           //         // eslint-disable-next-line
           //         return []
           //     }
           // },
           //
           // bypassFolders() {
           //     let tFolders = this.folders.folders.filter(() => true);
           //     return tFolders
           // },
           //
           // filteredFolders() {
           //     try {
           //         if (!this.blankString(this.search) && this.search.length >= 4) {
           //             return this.bypassFolders.filter(object => (object.name.toLowerCase() + object.searchStr).includes(this.search.toLowerCase()))
           //         }  else {
           //             return this.bypassFolders
           //         }
           //     } catch (e) {
           //         return []
           //     }
           // },
           // filteredFoldersAfterGroups() {
           //     if (Array.isArray(this.selectedFolderGroups) && this.selectedFolderGroups.length >= 1) {
           //         let allShowFolders = this.selectedFolderGroups.reduce((list, item) => {
           //             list = list.concat(item.folders);
           //             return list
           //         }, []);
           //         return this.filteredFoldersWithGroups.filter(obj => allShowFolders.includes(obj.id))
           //     } else {
           //         return this.filteredFoldersWithGroups;
           //     }
           // },
           // filteredFoldersWithGroups() {
           //     return this.filteredFolders.map(obj => {
           //         if (obj.folderGroups != null && obj.folderGroups != '' && obj.folderGroups != '[]') {
           //             let objGroups = JSON.parse(obj.folderGroups);
           //             obj.folderGroupNames = this.folders.folderGroups.filter(group => objGroups.includes(group.id)).map(groupObj => {return groupObj.name}).join(', ');
           //         } else {
           //             obj.folderGroupNames = null
           //         }
           //         return obj
           //     })
           // },
           // similarFolders() {
           //     if (!this.blankString(this.addFolderDescription) && this.addFolderDescription.length >= 4) {
           //         let tFolders = this.localFolders
           //             .filter(folder => folder.name.toLowerCase().includes(this.addFolderDescription.toLowerCase()));
           //
           //         this.setTopFolders(tFolders.length);
           //
           //         return tFolders
           //
           //     } else {
           //         this.setTopFolders(0);
           //         return null;
           //     }
           // },

           disableAddFolder() {

               let tResult = this.addingFolder || this.blankString(this.addFolderDescription) || ((!Array.isArray(this.addFolderFolderGroups) || this.addFolderFolderGroups.length <= 0) && this.allDisplayGroups.length > 0)

               return tResult
           }
       }
   }
</script>

<style scoped>

    .select-item:hover {
        color: var(--v-primary-base);
    }

</style>
