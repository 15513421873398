<template>
    <div class="fill-height">
        <v-layout row class="fill-height">
            <v-flex xs3 class="fill-height scrollable">

                <multi-select-list
                        :show-action-buttons="false"
                        :show-check-boxes="true"
                        :multiple="false"
                        headline-key="description"
                        item-key="index"
                        :return-object="true"
                        v-model="selectedSection"
                        :show-filter="true"
                        :items="keyedParts"
                        :dark="true"
                        filter-label="Filter Sections"
                        :show-select-all="false"
                        :cutom-sort-fn="(a,b) => a.index - b.index"
                        @sortEvent="sortEvent($event)"
                >
                    <div slot="header" class="primary px-2 py-1">
                        <div class="subheading white--text pb-1">
                            Select Section
                        </div>
                    </div>
                    <template v-slot:action="{value, index, selected}">
                        <div v-if="selected" class="c-d-flex pr-1">
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                            icon
                                            small
                                            flat
                                            class="pa-0 ma-0"
                                            color="primary"
                                            @click="selectedSection = value;
                                                showRename = true"
                                            v-on="on"
                                    >
                                        <v-icon small>edit</v-icon>
                                    </v-btn>
                                </template>
                                Rename Section
                            </v-tooltip>
                            <v-tooltip top v-if="part.sections.length > 1">
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                            icon
                                            small
                                            flat
                                            class="pa-0 ma-0"
                                            color="primary"
                                            @click="selectedSection = value;
                                                showRemovePrompt = true"
                                            v-on="on"
                                    >
                                        <v-icon small>delete</v-icon>
                                    </v-btn>
                                </template>
                                Remove Section
                            </v-tooltip>
                            <v-tooltip top>
                                <v-btn
                                        icon
                                        small
                                        flat
                                        class="pa-0 ma-0"
                                        color="primary"
                                        @click="addSectionBelow(value.index)"
                                        slot="activator"
                                >
                                    <v-icon small>add</v-icon>
                                </v-btn>
                                Add Section Below
                            </v-tooltip>
                            <v-tooltip top>
                                <v-btn
                                        icon
                                        small
                                        flat
                                        class="pa-0 ma-0"
                                        color="primary"
                                        @click="copySectionBelow(value.index)"
                                        slot="activator"
                                >
                                    <v-icon small>file_copy</v-icon>
                                </v-btn>
                                Copy Section
                            </v-tooltip>
                        </div>
                    </template>

                    <template v-slot:headline="{value, index, selected}">
                        {{ value.description || `Section ${index + 1}` }}
                    </template>


                </multi-select-list>

            </v-flex>
            <v-flex xs9 class="fill-height scrollable c-d-flex c-flex-column pa-1" v-if="selectedSection">

                <v-card class="c-d-flex c-flex-column scrollable" :class="templateExpanded ? 'c-flex-grow-1 c-flex-equal' : null">
                    <div class="red primary c-d-flex c-justify-space-between c-align-center" @click="templateExpanded = !templateExpanded">
                        <div class="subheading white--text px-2 py-1">
                            {{selectedSection.description || `Section ${selectedSection.index + 1}`}}: Template
                        </div>
                        <div>
                            <v-tooltip left>
                                <template v-slot:activator="{on}">
                                    <v-btn
                                            color="white"
                                            icon
                                            flat
                                            small
                                            class="pa-0 ma-0 mr-2"
                                            v-on="on"
                                    >
                                        <v-icon>{{templateExpanded ? 'expand_less' : 'expand_more'}}</v-icon>
                                    </v-btn>
                                </template>
                                {{templateExpanded ? 'Collapse' : 'Expand'}}
                            </v-tooltip>
                        </div>
                    </div>
                    <div class="c-flex-grow-1 scrollable" v-if="templateExpanded && !refreshTemplate">
                        <section-editor
                                :user-info="userInfo"
                                :select-lists="selectLists"
                                :datasets="datasets"
                                :steps="steps"
                                :fields="fields"
                                :signatures="signatures"
                                :title="'Hello'"
                                :html="selectedSection.html"
                                @dismiss="showEditor = false"
                                @setHtml="setHtml($event)"
                                :attachments="attachments"
                        />
<!--                        <document-part-section-->
<!--                                :section="selectedSection"-->
<!--                                :user-info="userInfo"-->
<!--                                :select-lists="selectLists"-->
<!--                                :datasets="datasets"-->
<!--                                :steps="steps"-->
<!--                                :fields="fields"-->
<!--                                :signatures="signatures"-->
<!--                                :index="5"-->
<!--                                :allowRemove=""-->
<!--                                :drop-all="dropAll"-->
<!--                                :attachments="attachments"-->
<!--                                @removeSection="removeSection(5)"-->
<!--                                @addSectionBelow="addSectionBelow(5)"-->
<!--                                @copySectionBelow="copySectionBelow(5)"-->
<!--                        />-->
                    </div>
                </v-card>

                <v-card class="mt-1 c-d-flex c-flex-column scrollable" :class="rulesExpanded ? 'c-flex-grow-1 c-flex-equal' : null">
                    <div class="primary c-d-flex c-justify-space-between c-align-center" @click="rulesExpanded = !rulesExpanded">
                        <div class="subheading white--text px-2 py-1">
                            {{selectedSection.description || `Section ${selectedSection.index + 1}`}}: Rules
                        </div>
                        <div>
                            <v-tooltip left>
                                <template v-slot:activator="{on}">
                                    <v-btn
                                            color="white"
                                            icon
                                            flat
                                            small
                                            class="pa-0 ma-0 mr-2"
                                            v-on="on"
                                    >
                                        <v-icon>{{!rulesExpanded ? 'expand_less' : 'expand_more'}}</v-icon>
                                    </v-btn>
                                </template>
                                {{rulesExpanded ? 'Collapse' : 'Expand'}}
                            </v-tooltip>
                        </div>
                    </div>
                    <div class="c-flex-grow-1 scrollable" v-if="rulesExpanded">
                        <document-part-rules
                                :show-rules="true"
                                :user-info="userInfo"
                                :select-lists="selectLists"
                                :datasets="datasets"
                                :steps="steps"
                                :fields="fields"
                                :signatures="signatures"
                                :title="'sectionTitle'"
                                :rules="selectedSection.rules"
                                @dismiss="showRules = false"
                        />
                    </div>
                </v-card>

            </v-flex>
        </v-layout>

        <app-input-dlg
                v-if="selectedSection && showRename"
                :show-dialog="showRename"
                title="Rename Section"
                :body="`Please provide a new name for: ${selectedSection.description || `Section ${selectedSection.index + 1}`}`"
                :default-input="selectedSection.description || `Section ${selectedSection.index + 1}`"
                :cancel-button="true"
                :show-input-box="true"
                :buttons="['Rename']"
                :call-backs="[ renameSection ]"
                @dismiss="showRename = false"
        />
        <app-input-dlg
                v-if="selectedSection && showRemovePrompt"
                :show-dialog="showRemovePrompt"
                title="Remove Section"
                :body="`Please note this will remove ${selectedSection.description || `Section ${selectedSection.index + 1}`} from the document part`"
                :cancel-button="true"
                :show-input-box="false"
                :buttons="['Remove']"
                :call-backs="[ () => {removeSection(selectedSection.index)} ]"
                @dismiss="showRemovePrompt = false"
        />

    </div>
</template>

<script>
import InputDlg from "../../../../../../components/General/InputDlg";
import MultiSelectList from "@/components/Multi Select List/MultiSelectList";
import SectionEditor
    from "@/Admin/WorkflowEditor/Components/WorkflowManager/DocumentTemplate/Parts/Sections/Editor/SectionEditor";
import DocumentPartRules from "@/Admin/WorkflowEditor/Components/WorkflowManager/DocumentTemplate/Parts/Sections/Rules/DocumentPartRules.js";

export default {
    components: {
        DocumentPartRules,
        SectionEditor,
        MultiSelectList,
        appInputDlg: InputDlg
    },
    props: {
        userInfo: Object,
        part: Object,
        datasets: Array,
        fields: Array,
        steps: Array,
        selectLists: Array,
        signatures: Array,
        dropAll: Boolean,
        attachments: Array,
    },
    data() {
        return {
            selectedSection: null,
            partDescriptions: {
                "0": 'Document',
                "1": 'Header',
                "2": 'Footer',
                "3": 'First Page Header',
                "4": 'First Page Footer',
                "5": 'First Page',
                "6": 'Last Page',
            },
            dropped: true,
            showRemovalPrompt: false,
            templateExpanded: true,
            rulesExpanded: false,
            refreshTemplate: false,
            showRename: false,
            showRemovePrompt: false,
        }
    },
    watch: {
        dropAll: {
            handler(val) {
                this.dropped = val
            }
        },
        selectedSection(val) {
            if (val) {
                this.refreshTemplate = true;
                // this.$forceUpdate();
            }
        },
        "selectedSection.rules": {
            deep: true,
            handler(val) {
                this.part.sections[val.index] = val
            }
        },
        refreshTemplate(val) {
            if (val)
                this.$nextTick(() => {
                    // setTimeout(() => {
                        this.refreshTemplate = false;
                        // }, 1000)
                })
        },
        templateExpanded() {
            this.refreshTemplate = true;
        },
        rulesExpanded() {
            this.refreshTemplate = true;
        },
    },
    methods: {

        renameSection(newName) {
            this.showRename = false;
            if (newName) {
                this.selectedSection.description = newName
            } else {
                this.selectedSection.description = null
            }

            this.part.sections[this.selectedSection.index].description = this.selectedSection.description
        },

        sortEvent(val) {
            this.selectedSection = null;
            this.part.sections = val.map(obj => {
                return {
                    description: obj.description,
                    html: obj.html,
                    rules: obj.rules
                }
            })
        },

        setHtml(val) {
            this.selectedSection.html = val
            this.part.sections[this.selectedSection.index].html = val
        },
        removePart() {
            this.$emit('removePart')
        },
        removeSection(index) {
            this.showRemovePrompt = false;
            this.selectedSection = null
            this.part.sections.splice(index, 1)
        },
        addSectionBelow(index) {
            this.part.sections.splice(index + 1, 0, {html: null, rules: [], description: null})
        },
        copySectionBelow(index) {
            let tSetction = JSON.parse(JSON.stringify(this.part.sections[index]));
            this.part.sections.splice(index + 1, 0, tSetction)
        }
    },
    computed: {
        keyedParts() {
            return this.part.sections.map((obj, i) => {
                return {...obj, index: i};
            })
        }
    }
}
</script>

<style scoped>
</style>
