import { render, staticRenderFns } from "./ReportPreview.vue?vue&type=template&id=4bca2350&scoped=true"
import script from "./ReportPreview.vue?vue&type=script&lang=js"
export * from "./ReportPreview.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4bca2350",
  null
  
)

export default component.exports