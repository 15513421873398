<template>

    <v-layout column fill-height style="overflow-y: auto; overflow-x: hidden" class="pa-1">
        <v-flex shrink>
            <v-layout row align-center>
                <v-flex xs12>
                    <v-text-field
                            label="Search Store"
                            single-line
                            clearable
                            prepend-inner-icon="search"
                            color="primary"
                            flat
                            :hide-details="true"
                            :value="searchStr"
                            class="pa-0 ma-0"
                            ref="myField"
                            @input="$emit('searchStr', $event)"
                            @click:clear="$emit('searchStr', null)"
                    ></v-text-field>
                </v-flex>
                <v-flex shrink v-if="managerOptions">
                    <v-btn
                            small
                            icon
                            flat
                            color="secondary"
                            class="pa-0 ma-0"
                            @click="showMenuFn(null, $event)"
                    >
                        <v-icon>add</v-icon>
                    </v-btn>
                </v-flex>
            </v-layout>

        </v-flex>
        <v-flex xs12 style="overflow-y: auto; overflow-x: hidden">
            <div v-for="(node, i) in sortedChildren" :key="node.key + i" style="overflow-y: auto; overflow-x: hidden">
                <repo-tree-node
                        :node="node"
                        :drop-keys="searchStr ? true : dropKeys"
                        @expandFolder="expandFolder($event)"
                        @select="managerOptions ? nodeSelected($event) : $emit('select', $event)"
                        :selected-node-key="selectedNodeKey"
                        :show-menu-fn="showMenuFn"
                        :get-parents-fn="getParentsFn"
                        @updateDocument="$emit('updateDocument', $event)"
                        @updateFolder="$emit('updateFolder', $event)"
                        :selected-keys="selectedKeys"
                        :manager-options="managerOptions"
                />
            </div>
        </v-flex>
<!--Context Menu-->
        <v-menu
                v-model="showMenu"
                :position-x="x"
                :position-y="y"
                absolute
                offset-y
        >
            <v-card >
                <template v-for="(menuItem, i) in menuItems">
                    <div
                            class="hover-item centered-row pa-1"
                            :key="i"
                            @click="menuItem.callback(menuNode)"
                    >
                        <v-icon :color="menuItem.disabled ? 'grey' : menuItem.color">{{menuItem.icon}}</v-icon>
                        <div class="body-2 px-2" :class="menuItem.disabled ? 'greyType--text' : null">{{menuItem.description}}</div>
                    </div>
                </template>
            </v-card>
        </v-menu>
<!--Edit Folder-->
        <v-dialog
                v-if="showEditFolder"
                v-model="showEditFolder"
                width="500"
                persistent
        >
            <v-card width="500">
                <div class="centered-row primary px-1">
                    <v-icon color="white">add</v-icon>
                    <div class="body pa-1 px-2 white--text" style="width: 100%">{{ editFolder.id ? 'Edit Folder' : 'Create New Folder' }}</div>
                    <v-btn
                            small
                            icon
                            flat
                            color="white"
                            class="pa-0 ma-0"
                            @click="showEditFolder = false"
                            :disabled="editFolderLoading"
                    >
                        <v-icon small>close</v-icon>
                    </v-btn>
                </div>
                <div class="px-2">
                    <div class="centered-row pt-2">
                        <div class="greyType--text" style="width: 40%">
                            Folder Description:
                        </div>
                        <div style="width: 60%">
                            <v-text-field
                                    label="Folder Description"
                                    single-line
                                    clearable
                                    color="primary"
                                    flat
                                    :hide-details="true"
                                    v-model="editFolder.description"
                                    class="pa-0 ma-0"
                                    prepend-icon="edit"
                                    :disabled="editFolderLoading"
                            ></v-text-field>
                        </div>
                    </div>
                    <div class="centered-row py-2">
                        <div class="greyType--text" style="width: 40%">
                            Folder Nested Under:
                        </div>
                        <div style="width: 60%">
                            <v-autocomplete
                                    :items="[{id: 0, description: 'Root Folder'}, ...folders]"
                                    item-value="id"
                                    item-text="description"
                                    color="primary"
                                    flat
                                    v-model="editFolder.parent"
                                    hide-details
                                    class="pa-0 ma-0"
                                    single-line
                                    label="Folder Nested Under"
                                    dense
                                    prepend-icon="folder"
                                    :disabled="editFolderLoading"
                            >
                            </v-autocomplete>
                        </div>
                    </div>
                </div>
                <v-divider></v-divider>
                <div class="text-xs-right">
                    <v-btn
                            color="secondary"
                            small
                            @click="showEditFolder = false"
                            :disabled="editFolderLoading"
                    >
                        <v-icon left>close</v-icon>
                        Cancel
                    </v-btn>
                    <v-btn
                            color="primary"
                            small
                            :disabled="editFolderLoading || !editFolder.description"
                            :loading="editFolderLoading"
                            @click="modifyFolder"
                    >
                        <v-icon left>save</v-icon>
                        {{ editFolder.id ? 'Save' : 'Add Folder' }}
                    </v-btn>
                </div>
            </v-card>
        </v-dialog>
<!--Remove Node-->
        <v-dialog
                v-if="showRemoveNode"
                v-model="showRemoveNode"
                width="500"
                persistent
        >
            <v-card color="white" width="500">
                <div class="centered-row primary px-1">
                    <v-icon color="white">add</v-icon>
                    <div class="body pa-1 px-2 white--text" style="width: 100%">Remove {{ removeNode.typeDescription }}</div>
                    <v-btn
                            small
                            icon
                            flat
                            color="white"
                            class="pa-0 ma-0"
                            @click="showRemoveNode = false"
                            :disabled="removeNodeLoading"
                    >
                        <v-icon small>close</v-icon>
                    </v-btn>
                </div>
                <div class="px-2 pt-2 greyType--text">
                    Please confirm that you would like to remove the following:
                </div>
                <div class="centered-row py-2 px-2">
                    <div class="greyType--text" style="width: 40%">
                        {{removeNode.typeDescription}}:
                    </div>
                    <div style="width: 60%;" class="centered-row">
                        <v-icon :color="removeNode.color">{{removeNode.icon}}</v-icon>
                        <div class="pl-2">{{removeNode.description}}</div>
                    </div>
                </div>
                <v-divider></v-divider>
                <div class="text-xs-right">
                    <v-btn
                            color="secondary"
                            small
                            @click="showRemoveNode = false"
                            :disabled="removeNodeLoading"
                    >
                        <v-icon left>close</v-icon>
                        Cancel
                    </v-btn>
                    <v-btn
                            color="primary"
                            small
                            :disabled="removeNodeLoading"
                            :loading="removeNodeLoading"
                            @click="removeNodeRun"
                    >
                        <v-icon left>delete</v-icon>
                        Remove {{ removeNode.typeDescription }}
                    </v-btn>
                </div>
            </v-card>
        </v-dialog>
<!--Add Document-->
        <v-dialog
                v-if="showAddDoc"
                v-model="showAddDoc"
                width="500"
                persistent
        >
            <v-card width="500" style="overflow-y: auto; overflow-x: auto">
                <div class="centered-row primary px-1">
                    <v-icon color="white">add</v-icon>
                    <div class="body pa-1 px-2 white--text" style="width: 100%">Create Document</div>
                    <v-btn
                            small
                            icon
                            flat
                            color="white"
                            class="pa-0 ma-0"
                            @click="showAddDoc = false"
                            :disabled="addDocLoading"
                    >
                        <v-icon small>close</v-icon>
                    </v-btn>
                </div>

                <v-progress-linear height="5" class="py-0 my-0" :value="addDocProgress" color="primary" v-if="addDocLoading"></v-progress-linear>

                <div class="px-2" style="max-height: 600px; overflow-y: auto">
                    <div class="centered-row pt-2">
                        <div class="greyType--text" style="width: 40%">
                            Document File:
                        </div>
                        <div style="width: 60%">

                            <div class="upload-btn-wrapper" style="width: 100%" v-if="addDocFile === null">
                                <div
                                        @dragover="$event.preventDefault()"
                                        @drop="$event.preventDefault(); dropFiles($event);"
                                        class="py-1 text-xs-center greyType--text hover-item"
                                        style="border: 1px dashed lightgrey; border-radius: 3px; cursor: pointer"
                                >
                                    Click to Select or Drop File here.
                                </div>
                                <input
                                        ref="manualUpload"
                                        type="file"
                                        name="myfile"
                                        :accept="userInfo.entityInfo.mimeTypeList"
                                        @input="selectFile"
                                />
                            </div>
                            <div v-else class="centered-row hover-item" style="border: 1px solid lightgrey; border-radius: 3px;">
                                <v-icon>description</v-icon>
                                <div style="width: 100%" class="px-2 py-1">
                                    {{ addDocFile.name }}
                                </div>
                                <v-tooltip top>
                                    <v-btn
                                            slot="activator"
                                            small
                                            icon
                                            flat
                                            color="grey"
                                            class="pa-0 ma-0"
                                            @click="addDocFile = null"
                                    >
                                        <v-icon small>close</v-icon>
                                    </v-btn>
                                    Clear
                                </v-tooltip>
                            </div>

                        </div>
                    </div>
                    <div class="centered-row pt-2">
                        <div class="greyType--text" style="width: 40%">
                            Document Description:
                        </div>
                        <div style="width: 60%">
                            <v-text-field
                                    label="Document Description"
                                    single-line
                                    clearable
                                    color="primary"
                                    flat
                                    :hide-details="true"
                                    v-model="addDocMeta.description"
                                    class="pa-0 ma-0"
                                    prepend-icon="edit"
                                    :disabled="addDocLoading"
                            ></v-text-field>
                        </div>
                    </div>
                    <div class="centered-row pt-2">
                        <div class="greyType--text" style="width: 40%">
                            Document Nested Under:
                        </div>
                        <div style="width: 60%">
                            <v-autocomplete
                                    :items="[{id: 0, description: 'Root Folder'}, ...folders]"
                                    item-value="id"
                                    item-text="description"
                                    color="primary"
                                    flat
                                    v-model="addDocMeta.parent"
                                    hide-details
                                    class="pa-0 ma-0"
                                    single-line
                                    label="Folder Nested Under"
                                    dense
                                    prepend-icon="folder"
                                    :disabled="addDocLoading"
                            >
                            </v-autocomplete>
                        </div>
                    </div>
                    <div class="centered-row pt-2">
                        <div class="greyType--text" style="width: 40%">
                            Users With Access:
                        </div>
                        <div style="width: 60%">
                            <v-autocomplete
                                    :items="displayUsers"
                                    label="Users With Access"
                                    prepend-icon="person"
                                    item-value="id"
                                    item-text="description"
                                    color="primary"
                                    flat
                                    v-model="addDocMeta.users"
                                    hide-details
                                    class="pa-0 ma-0"
                                    single-line
                                    dense
                                    :disabled="addDocLoading"
                                    multiple
                            >
                            </v-autocomplete>
                        </div>
                    </div>
                    <div class="centered-row py-2">
                        <div class="greyType--text" style="width: 40%">
                            Groups With Access:
                        </div>
                        <div style="width: 60%">
                            <v-autocomplete
                                    :items="displayGroups"
                                    label="Groups With Access"
                                    prepend-icon="people"
                                    item-value="id"
                                    item-text="description"
                                    color="primary"
                                    flat
                                    v-model="addDocMeta.groups"
                                    hide-details
                                    class="pa-0 ma-0"
                                    single-line
                                    dense
                                    :disabled="addDocLoading"
                                    multiple
                            >
                            </v-autocomplete>
                        </div>
                    </div>
                </div>
                <v-divider></v-divider>
                <div class="text-xs-right">
                    <v-btn
                            color="secondary"
                            small
                            @click="showAddDoc = false"
                            :disabled="addDocLoading"
                    >
                        <v-icon left>close</v-icon>
                        Cancel
                    </v-btn>
                    <v-btn
                            color="primary"
                            small
                            :disabled="addDocLoading || !this.addDocFile || !this.addDocMeta.description"
                            :loading="addDocLoading"
                            @click="addDocRun"
                    >
                        <v-icon left>add</v-icon>
                        Add Document
                    </v-btn>
                </div>
            </v-card>
        </v-dialog>

    </v-layout>
</template>

<script>
import {mapGetters} from 'vuex'
import RepoTreeNode from "@/components/General/Repo Tree/RepoTreeNode";
import {arrSplice} from "@/codeFunctions";

export default {
    components: {RepoTreeNode},
    props: {
        tree: {
            type: Array,
            required: true
        },
        docs: {
            type: Array,
            required: true
        },
        folders: {
            type: Array,
            required: true
        },
        searchStr: {
            type: String,
            default: ''
        },
        managerOptions: {
            default: true,
            type: Boolean
        },
        getParentsFn: Function,
        value: Number,
        selectedKeys: Array,
    },
    data() {
        return {
            dropKeys: [],
            selectedNodeKey: null,

            showMenu: false,
            x: 0,
            y: 0,
            menuNode: null,

            showEditFolder: false,
            editFolder: null,
            editFolderLoading: false,

            showRemoveNode: false,
            removeNode: null,
            removeNodeLoading: false,

            addDocMeta: null,
            addDocFile: null,
            showAddDoc: false,
            addDocLoading: false,
            addDocProgress: 0
        }
    },
    watch: {
        showMenu: {
            immediate: true,
            handler(val) {
                if (!val)
                    this.menuNode = null;
            }
        },
        value: {
            immediate: true,
            handler(val) {
                try {
                    this.selectedNodeKey = !val ? null : this.docs.filter((doc) => doc.id === val).pop().key
                } catch (e) {
                    this.selectedNodeKey = null;
                }
            }
        },
        selectedNodeKey(val) {
            try {
                this.$emit('input', val ? this.docs.filter((doc) => doc.key === val).pop().id : null)
            } catch (e) {
                this.$emit('input', null)
            }
        }
    },
    methods: {

        addDocRun() {
            let vm = this;
            function updateProgress(obj) {
                vm.addDocProgress = Math.floor((obj.loaded / obj.total) * 100)
            }
            this.addDocLoading = true;
            this.$newReqUploadMultiple(
                    'post',
                    `docRepo/CreateDocument/${this.userInfo.entityID}`,
                    [ {key: 'file', val: this.addDocFile}, {key: 'reqBody', val: JSON.stringify({...this.addDocMeta, parent: this.addDocMeta.parent ? this.addDocMeta.parent : null})}],
                    true,
                    updateProgress,
                    false,
            )
                    .then(doc => {
                        this.$snack.info('Document Added')
                        this.showAddDoc = false;
                        this.addDocProgress = 0;
                        this.addDocMeta = null;
                        this.addDocFile = null;
                        this.$emit('addDocument', doc)
                        this.$nextTick(() => {
                            this.nodeSelected(doc)
                        })
                    })
                    .catch(e => {
                        console.log(e);
                        this.$snack.networkError()
                    })
                    .finally(() => {this.addDocLoading = false})

        },

        selectFile() {
            if (this.$refs.manualUpload.files.length) {
                this.addDocFile = this.$refs.manualUpload.files[0]
                if (!this.addDocMeta.description)
                    this.addDocMeta.description = this.addDocFile.name
            }
        },

        dropFiles(e) {
            this.showUpload = true;
            this.$nextTick(() => {
                if (e.dataTransfer.files.length) {
                    this.addDocFile = e.dataTransfer.files[0]
                    if (!this.addDocMeta.description)
                        this.addDocMeta.description = this.addDocFile.name
                }
            })

        },

        showMenuFn(item, e) {
            e.preventDefault()
            this.showMenu = false

            setTimeout(() => {

                this.menuNode = item

                this.x = e.clientX
                this.y = e.clientY
                this.$nextTick(() => {
                    this.showMenu = true
                })
            }, 300)
        },

        expandFolder(val) {
            function getRemoveKeys(item, removeArr) {
                if (item.type === 0) {
                    removeArr.push(item.key)
                    if (item.children && item.children.length) {
                        item.children
                                .filter(child => child.type === 0)
                                .forEach(child => {
                                    if (child.type === 0)
                                        getRemoveKeys(child, removeArr)
                                })
                    }
                }
            }

            if (val.drop) {
                this.dropKeys.push(val.node.key)
            } else {
                let removeKeys = [];
                getRemoveKeys(val.node, removeKeys)
                arrSplice(this.dropKeys, (val => removeKeys.includes(val)))
            }
        },

        nodeSelected(node) {

            switch (node.type) {
                case 0: {
                    this.selectedNodeKey = null;
                    break
                }
                case 1: {
                    this.selectedNodeKey = node.key;
                    break;
                }
                default: {
                    this.selectedNodeKey = node.key;
                }

            }


        },

        addFolder(nodeItem) {
            this.editFolder = {
                id:null,
                description: null,
                parent: nodeItem ? nodeItem.id : 0,
                type:0,
            }
            this.showEditFolder = true;
        },
        editFolderFn(nodeItem) {
            this.editFolder = JSON.parse(JSON.stringify(nodeItem))
            this.showEditFolder = true;
        },
        modifyFolder() {
            this.editFolderLoading = true;
            this.$newReq('patch', `docRepo/InsertUpdateFolder/${this.userInfo.entityID}`, {
                id: this.editFolder.id,
                description: this.editFolder.description,
                parent: this.editFolder.parent === 0 ? null : this.editFolder.parent
            })
                .then(folder => {
                    this.$snack.info('Changes Saved')
                    this.showEditFolder = false;
                    this.$emit('updateFolder', folder)
                })
                .catch(e => {
                    console.log(e);
                    this.$snack.networkError()
                })
                .finally(() => {this.editFolderLoading = false})
        },

        addDoc(nodeItem) {
            this.addDocMeta = {
                description: null,
                users: [],
                groups: [],
                parent: nodeItem ? nodeItem.id : 0,
                userID: this.userInfo.userID,
            }
            this.showAddDoc = true;
        },

        addUserDoc() {console.log('addUserDoc cLICKED');},
        removeFolderNotEmpty() {
            this.$snack.error('Folder Must Be Empty')
        },
        removeNodeRun() {
            this.loadingRemove = true
            switch (this.removeNode.type) {
                case 0: {
                    // folder
                    this.$newReq('delete', `docRepo/DeleteFolder/${this.userInfo.entityID}/${this.removeNode.id}`)
                            .then(() => {
                                this.$snack.info('Folder Removed')
                                this.showRemoveNode = false;
                                this.$emit('deleteFolder', this.removeNode.id)
                            })
                            .catch(e => {
                                console.log(e);
                                this.$snack.networkError()
                            })
                            .finally(() => {this.loadingRemove = false})
                    break;
                }
                case 1: {
                    // Document
                    let updateDoc = this.docs.filter(doc => doc.id === this.removeNode.id).pop()
                    this.$newReq('patch', `docRepo/UpdateDocument/${this.userInfo.entityID}/${this.userInfo.userID}`, {
                        id: updateDoc.id,
                        description: updateDoc.description,
                        users: updateDoc.users,
                        groups: updateDoc.groups,
                        deleteFlag: true,
                        parent: updateDoc.parent === 0 ? null : updateDoc.parent
                    })
                            .then((doc) => {
                                this.$snack.info('Document Deleted')
                                this.showRemoveNode = false;
                                this.$emit('updateDocument', doc)
                                // this.$emit('deleteDoc', this.removeNode.id)
                            })
                            .catch(e => {
                                console.log(e);
                                this.$snack.networkError()
                            })
                            .finally(() => {this.loadingRemove = false})
                    break;
                }
                default: {
                    // User Doc
                    // TODO Add Remove User Doc Splice
                    this.$newReq('delete', `docRepo/UserRemoveDoc/${this.userInfo.entityID}/${this.removeNode.id}`)
                            .then(() => {
                                this.$snack.info('Document Removed')
                                this.showRemoveNode = false;
                                this.$emit('deleteUserDoc', this.removeNode.id)
                            })
                            .catch(e => {
                                console.log(e);
                                this.$snack.networkError()
                            })
                            .finally(() => {this.loadingRemove = false})
                    break;
                }
            }
        },

        restoreItem(updateDoc) {
            this.$newReq('patch', `docRepo/UpdateDocument/${this.userInfo.entityID}/${this.userInfo.userID}`, {
                id: updateDoc.id,
                description: updateDoc.description,
                users: updateDoc.users,
                groups: updateDoc.groups,
                deleteFlag: false,
                parent: null
            })
                    .then((doc) => {
                        this.$snack.info('Document Restored')
                        this.$emit('updateDocument', doc)
                        // this.$emit('deleteDoc', this.removeNode.id)
                    })
                    .catch(e => {
                        console.log(e);
                        this.$snack.networkError()
                    })
                    .finally(() => {this.loadingRemove = false})
        },
        removeItem(nodeItem) {
            // TODO Cater For User Document Description
            this.removeNode = {
                type: nodeItem.type,
                typeDescription: nodeItem.type === 0 ? 'Folder' : (nodeItem.type === 1 ? 'Document' : 'User Document'),
                icon: nodeItem.type === 0 ? 'folder' : (nodeItem.type === 1 ? 'description' : 'contact_page'),
                color: nodeItem.type === 0 ? 'yellow darken-2' : 'primary',
                description: nodeItem.description,
                id: nodeItem.id
            }
            this.showRemoveNode = true
        },
    },
    computed: {
        ...mapGetters('userInfo', ['userInfo']),
        ...mapGetters(['localData']),

        sortedChildren() {
            return [...this.tree].sort((a,b) => a.type === b.type ? (a.description > b.description ? 1 : -1) : (a.type > b.type ? 1 : -1))
        },

        menuItems() {

            const folder = {
                icon: 'folder',
                color: 'yellow darken-2',
                description: 'Add Subfolder',
                callback: this.addFolder
            }

            const edit = {
                icon: 'edit',
                color: 'primary',
                description: 'Edit Folder',
                callback: this.editFolderFn
            }

            const doc = {
                icon: 'description',
                color: 'primary',
                description: 'Add Document',
                callback: this.addDoc
            }

            const userDoc = {
                icon: 'contact_page',
                color: 'primary',
                description: 'Add User Document',
                callback: this.addUserDoc
            }

            const del = {
                icon: 'delete',
                color: 'error',
                description: 'Remove',
            }

            if (this.showMenu && (this.menuNode ? this.menuNode.key !== 'rf--1' : true)) {
                let list = [];

                // Edit Folder
                if (this.managerOptions && this.menuNode !== null && this.menuNode.type === 0)
                    list.push(edit)

                // Folder
                if (this.managerOptions && (this.menuNode === null || this.menuNode.type === 0))
                    list.push(folder)

                // Document
                if (this.managerOptions && (this.menuNode === null || this.menuNode.type === 0))
                    list.push(doc)

                // User Document
                if (!this.managerOptions && this.menuNode === null)
                    list.push(userDoc)

                // Delete
                if (this.menuNode !== null) {
                    switch (this.menuNode.type) {
                        case 0: { // Folder

                            if (this.managerOptions)
                                list.push({
                                    ...del,
                                    disabled: !!this.menuNode.children.length,
                                    callback: this.menuNode.children.length ? this.removeFolderNotEmpty : this.removeItem
                                })
                            break;
                        }
                        case 1: { // Document

                            let deleted = this.menuNode.deleteFlag;

                            if (this.managerOptions)
                                list.push({
                                    icon: deleted ? 'restore_from_trash' : 'delete',
                                    color: deleted ? 'green' : 'error',
                                    description: deleted ? 'Restore' : 'Remove',
                                    callback: deleted ? this.restoreItem : this.removeItem
                                })
                            break;
                        }
                        default: { // User Document
                            list.push({
                                ...del,
                                callback: this.removeItem
                            })
                            break;
                        }
                    }
                }

                return list
            } else {
                return []
            }
        },

        displayUsers() {
            return this.localData.users
                    .reduce((list, user) => {
                        if (!user.disabled)
                            return list.concat({id: user.userID, description: `${user.name} ${user.surname}`.trim()})
                        return list
                    }, [])
                    .sort((a,b) => a.description > b.description ? 1 : -1)
        },

        displayGroups() {
            return [...this.localData.userGroups]
                    .sort((a,b) => a.description > b.description ? 1 : -1)
        }
    },
}
</script>

<style scoped>
.upload-btn-wrapper input[type=file] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
}
.upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
}
</style>