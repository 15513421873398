<template>
    <div>
        <v-menu offset-y full-width :close-on-content-click="false" v-model="configuringField" >
            <template v-slot:activator="{ on }">
                <div v-show="displayItemsTruncated == null" v-on="on">
                    <v-btn small flat color="secondary" class="ma-0">Configure List</v-btn>
                </div>
                <v-card ripple hover flat v-show="displayItemsTruncated != null" class="primary white--text" v-on="on">
                    <v-layout row align-center style="cursor: pointer" v-if="displayItemsTruncated != null">
                        <v-flex xs12 class="pa-1 pl-2 text-truncate" style="overflow-x: hidden;">
                            {{displayItemsTruncated.displayItems.join(', ')}}
                        </v-flex>
                        <v-flex shrink>
                            <v-tooltip top v-if="displayItemsTruncated.tooltipItems.length > 0">
                                <v-btn
                                        small
                                        flat
                                        icon
                                        color="white"
                                        class="pa-0 ma-0"
                                        slot="activator"
                                >
                                    <div class="caption">
                                        {{`+${displayItemsTruncated.tooltipItems.length}`}}
                                    </div>
                                </v-btn>
                                <span>{{displayItemsTruncated.tooltipItems.join(', ')}}</span>
                            </v-tooltip>
                        </v-flex>
                        <v-flex shrink class="pr-1">
                            <v-tooltip top>
                                <v-btn
                                        small
                                        flat
                                        icon
                                        color="white"
                                        class="pa-0 ma-0"
                                        slot="activator"
                                >
                                    <v-icon small>edit</v-icon>
                                </v-btn>
                                <span>Edit Items</span>
                            </v-tooltip>
                        </v-flex>
                    </v-layout>
                </v-card>
            </template>


            <v-card class="pa-1">
<!--List Items-->
                <div class="pa-1">
                    <div class="caption greyType--text font-weight-bold">
                        <v-layout row align-center>
                            <v-flex xs12>
                                List Items:
                            </v-flex>
                            <v-flex shrink>
                                <v-tooltip top>
                                    <v-btn
                                            icon
                                            flat
                                            class="pa-0 ma-0"
                                            color="secondary"
                                            slot="activator"
                                            small
                                            @click="showAddListItem = true"
                                    >
                                        <v-icon>add</v-icon>
                                    </v-btn>
                                    <span>Add Item to List</span>
                                </v-tooltip>
                            </v-flex>
                        </v-layout>
                    </div>
                    <div class="px-4" style="max-height: 200px; overflow-y: auto">
                        <template v-for="(item, index) in listItemsLocal">
                            <v-layout row align-center>
                                <v-flex xs12 class="caption">
                                    {{item}}
                                </v-flex>
                                <v-flex shrink>
                                    <v-tooltip top>
                                        <v-btn
                                                icon
                                                flat
                                                class="pa-0 ma-0"
                                                color="red"
                                                slot="activator"
                                                small
                                                @click="valueItems = listItemsLocal.filter((obj, i) => i != index)"
                                        >
                                            <v-icon small>delete</v-icon>
                                        </v-btn>
                                        <span>Delete Item from List</span>
                                    </v-tooltip>
                                </v-flex>
                            </v-layout>
                        </template>
                    </div>
                    <div>
                        <v-divider></v-divider>
                    </div>
                    <div class=" pt-2 text-xs-right">
                        <v-tooltip top>
                            <v-btn
                                    class="pa-0 ma-0"
                                    color="secondary"
                                    slot="activator"
                                    small
                                    @click="doneRun"
                                    :disabled="valueItems == null || valueItems.length <= 0"
                            >
                                done
                            </v-btn>
                            <span>Done</span>
                        </v-tooltip>
                    </div>
                    <app-input-dlg
                            :show-dialog="showAddListItem"
                            title="Add New Checkbox"
                            body="Checkbox Description:"
                            :cancel-button="true"
                            :show-input-box="true"
                            :buttons="['Add']"
                            :call-backs="[ addListItem ]"
                            @dismiss="showAddListItem = false"
                    />
                </div>
            </v-card>
        </v-menu>
    </div>
</template>

<script>
    import InputDlg from "../../../../../../../components/General/InputDlg";
    import {codeBaseMixin} from "../../../../../../../codeBaseMixin";

    export default {
        mixins: [codeBaseMixin],
        components: {
            appInputDlg: InputDlg
        },
        props: {
            value: String
        },
        data() {
            return {
                valueItems: null,
                showAddListItem: false,
                configuringField: false
            }
        },
        watch: {
            value: {
                immediate: true,
                handler(val) {
                    this.resetLocalValues(val);
                }
            }
        },
        methods: {
            doneRun() {
                this.$emit('input', this.valueItems != null && this.valueItems.length > 0 ? JSON.stringify(this.valueItems) : null);
                this.configuringField = false;
            },


            addListItem(item) {
                if (this.valueItems == null) {
                    this.valueItems = []
                }
                if (item == null || item == '') {
                    this.showSnack('Error', 'List Item Can Not Be Blank', 'Close', 'red');
                    return null;
                }
                if (this.valueItems.map(obj => {return obj.toLowerCase()}).includes(item.toLowerCase())) {
                    this.showSnack('Error', 'Item Already Exists', 'Close', 'red');
                    return null;
                }
                if (item.includes('"')) {
                    this.showSnack('Error', 'Item Can Not Contain A Quotation', 'Close', 'red');
                    return null;
                }

                this.valueItems.push(item);
                this.showSnack('Info', 'Item Added', 'Close', 'primary');
                this.showAddListItem = false;
            },

            resetLocalValues(val) {
                this.addingItem = false;
                this.valueItems = this.isJson(val != null ? val.replace(/\\"/g,'"') : []);
            },
        },
        computed: {

            listItemsValue() {
                return this.isJson(this.value != null ? this.value.replace(/\\"/g,'"') : null, (values) => {
                    return values != null ? values.sort((a,b) => a < b ? -1 : 1) : null;
                }, null);
            },

            displayItemsTruncated() {
                if (this.listItemsValue == null) {
                    return null;
                } else {
                    return this.listItemsValue.reduce((returnObject, item, index, valueItems) => {
                        if (index <= 2) {
                            returnObject.displayItems.push(item)
                        } else {
                            returnObject.tooltipItems.push(item)
                        }

                        if (index + 1 == valueItems.length && (returnObject.displayItems.length + returnObject.tooltipItems.length) <= 0) {
                            returnObject = null;
                        }
                        return returnObject;
                    }, {
                        displayItems: [],
                        tooltipItems: []
                    })
                }
            },

            listItemsLocal() {
                return this.isNotNull(this.valueItems, (values) => {return values.sort((a,b) => a < b ? -1 : 1);}, null);
            },
        }
    }
</script>

<style scoped>

</style>
