<template>
    <div>
        <div @click="showAdd = !showAdd">
            <slot name="activator"></slot>
        </div>

        <app-input-dlg
                :show-dialog="showAdd"
                title="Add New Folder Group"
                :cancel-button="true"
                :show-input-box="false"
                @dismiss="showAdd = false"
                :body-padding="false"
        >
            <template slot="body">
                <div class="px-1 pt-1">
                    <div :class="topicClass">
                        <div :class="headingClass">
                            Folder Group Description:
                        </div>
                        <div :class="itemClass">
                            <v-text-field
                                    label="Folder Group Description"
                                    single-line
                                    color="primary"
                                    hide-details
                                    style="width: 100%"
                                    class="pa-0 ma-0"
                                    ref="FGroupDescription"
                                    v-model="addGroupDescription"
                            />
                        </div>
                    </div>
                    <div :class="topicClass">
                        <div :class="headingClass">
                            Select User Groups:
                        </div>
                        <div :class="itemClass">
                            <v-autocomplete
                                    :items="userGroups"
                                    clearable
                                    color="primary"
                                    flat
                                    v-model="selectedUserGroups"
                                    hide-details
                                    class="pa-0 ma-0"
                                    single-line
                                    label="None Selected"
                                    item-text="description"
                                    item-value="id"
                                    dense
                                    multiple
                                    small-chips
                                    deletable-chips
                                    chips
                            >
                            </v-autocomplete>
                        </div>
                    </div>
                </div>
            </template>
            <template slot="actions">
                <div class="text-xs-right">
                    <v-btn
                            small
                            color="secondary"
                            :disabled="!Array.isArray(selectedUserGroups) || selectedUserGroups.length <= 0 || blankString(addGroupDescription) || addingFolderGroup"
                            :loading="addingFolderGroup"
                            @click="addFolderGroup"
                    >
                        Add Folder Group
                    </v-btn>
                </div>
            </template>
        </app-input-dlg>
    </div>
</template>

<script>
    import {codeBaseMixin} from "../../../codeBaseMixin";
    import InputDlg from "../../../components/General/InputDlg";

    export default {
        components: {
            appInputDlg: InputDlg
        },
        mixins: [codeBaseMixin],
        props: {
            userInfo: Object,
            selectedFolder: Object,
            userGroups: Array,
        },
        data() {
            return {
                showAdd: false,
                addGroupDescription: null,
                selectedUserGroups: [],
                addingFolderGroup: false,
            }
        },
        watch: {
            showAdd(shown) {
                if (shown) {
                    this.addGroupDescription = null;
                    this.selectedUserGroups = [];
                    this.$nextTick(() => {
                        this.$refs.FGroupDescription.focus();
                    })
                }
            },
        },
        methods: {
            addFolderGroup() {
                this.addingFolderGroup = true;
                this.fetch({method: 'POST', url:`/folderManager/security/folderGroup/${this.userInfo.entityID}/${this.selectedFolder.id}`, body: JSON.stringify({description: this.addGroupDescription, userGroups: this.selectedUserGroups})})
                    .then((data) => {
                        this.showAdd = false;
                        this.addingFolderGroup = false;
                        this.$emit('folderGroupAdded', data)
                        this.showSnack('Info', 'Folder Group Added', 'Close', 'primary')
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e);
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
                        this.addingFolderGroup = false;
                    })
            }
        }

    }
</script>

<style scoped>

</style>
