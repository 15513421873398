<template>
<!--  <div :key="typeof item.groupObject != 'undefined' ? item.title : item.taskID">-->
  <div>
    <template v-if="typeof item.groupObject != 'undefined'">
      <div style="height: 60px">
        
        <div :class="{'dropped': item.dropped}" class="c-d-flex c-align-center task-group" @click="toggleGrouping(item)" style="cursor: pointer; height: 59px">
          
          <div class="px-2 pl-3">
            <img v-if="filterSettings.groupOption == 1" src="../../../public/users.svg" alt="" width="24">
            <img v-if="filterSettings.groupOption == 6" src="../../../public/sub_users.svg" alt=""
                 width="24">
            <img v-if="filterSettings.groupOption == 2" src="../../../public/workflows.svg" alt=""
                 width="24">
            <v-icon v-if="filterSettings.groupOption == 5" class="yellow--text text--darken-2">
              folder_shared
            </v-icon>
            <v-icon v-if="filterSettings.groupOption == 0" class="yellow--text text--darken-2">folder
            </v-icon>
          </div>
          
          <div class="c-flex-grow-1 text-truncate body-2">
            <div class="pb-1">{{ !item.title || item.title === 'null' || item.title === 'null null' ? '-' : item.title }}</div>
            <!--            <v-flex xs12 style="overflow-x: hidden" class="text-truncate body-1 pl-1"-->
            <!--                    :class="`${item.dropped ? 'white&#45;&#45;text' : ''} ${!item.title || item.title === 'null' || item.title === 'null null' ? ' clear-text placeholder-content rounded-border pr-2 ml-1' : ''}`">-->
            <!--              {{ !item.title || item.title === 'null' || item.title === 'null null' ? '-' : item.title }}-->
            <!--            </v-flex>-->
            <div class="caption ">
              {{item.count}} Task{{item.count > 1 ? 's' : ''}}
            </div>
          
          </div>
          
          <div class="px-2">
            <v-icon>{{ item.dropped ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}</v-icon>
          </div>
        </div>
        <v-divider></v-divider>
      </div>
    </template>
    <template v-else>
      <task-container
          :userInfo="userInfo"
          :task="item"
          :taskBar="true"
          :showNav="true"
          @taskNav="taskNav"
          :show-escalation="true"
          :escalation-value="escalations[item.taskID] ? escalations[item.taskID].viewTTE : null"
          :cur-m-s="curMS"
          @removeEscalationRunStart="removeEscalationRunStart({item, cancelLoadFn: $event})"
      >
      </task-container>
    </template>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import TaskContainer from "@/components/TaskContainer/TaskContainer.vue";

export default {
  components: {TaskContainer},
  props: {
    source: {
      type: Object,
    },
    filterSettings: Object,
    droppedGroupings: Array,
    toggleGrouping: Function,
    taskNav: Function,
    escalations: Object,
    curMS: Number,
    removeEscalationRunStart: Function,
  },
  computed: {
    ...mapGetters('userInfo', ["userInfo"]),
    item() {
      return this.source
    }
  },
}
</script>



<style scoped>


</style>