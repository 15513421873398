<template>
    <v-menu :close-on-content-click="false" offset-y v-model="viewUserShown">
        <template #activator="{ on: menu }">
            <v-tooltip top>
                <template #activator="{ on: tooltip }">
                    <v-btn
                        small
                        icon
                        :flat="selectedDash.initials == null"
                        class="pa-0 ma-0"
                        color="primary"
                        v-on="{ ...tooltip, ...menu }"
                    >
                        <template v-if="selectedDash.initials != null">
                            <strong>{{selectedDash.initials}}</strong>
                        </template>
                        <v-icon v-else>person</v-icon>
                    </v-btn>
                </template>
                <span>{{selectedDash.description}} - Select View Dashboard </span>
            </v-tooltip>
        </template>
        <v-card width="300px">
            <v-layout column fill-height v-if="viewUserShown">
                <v-flex shrink class="pa-1">
                    <v-text-field
                        label="Filter Users"
                        single-line
                        v-model="filterStr"
                        color="primary"
                        hide-details
                        style="width: 100%"
                        class="ma-0 pa-0"
                        prepend-inner-icon="search"
                    ></v-text-field>
                </v-flex>
                <v-divider></v-divider>
                <v-flex shrink style="max-height: 400px" class="scrollable">
                    <div
                        style="cursor: pointer"
                        v-for="(dash, i) in dashboards"
                        :key="i"
                        :class="value === dash.id ? 'selected-item' : 'hover-item'"
                        @click="$emit('input', dash.id)"
                    >
                        <v-layout row align-center>
                            <v-flex shrink class="pl-2 pr-1">
                                <v-icon
                                    :class="value === dash.id ? 'primary--text' : 'greyType--text'"
                                >
                                    person
                                </v-icon>
                            </v-flex>
                            <v-flex xs12 :class="value === dash.id ? 'font-weight-bold' : ''" class="py-1">
                                {{dash.description}}
                            </v-flex>
                        </v-layout>
                        <v-divider></v-divider>
                    </div>
                </v-flex>

            </v-layout>
        </v-card>
    </v-menu>
</template>

<script>
import {mapGetters} from 'vuex';
import {blankString} from "@/codeFunctions";

export default {

    props: {
        value: Number,
        localData: Object
    },
    data() {
        return {
            filterStr: null,
            viewUserShown: false,
        }
    },
    watch: {
        viewUserShown() {
            this.filterStr = null
        },
        value() {
            this.viewUserShown = false;
        }
    },
    computed: {
        ...mapGetters('userInfo', ['userInfo']),
        dashboards() {
            return [{id: null, description: 'My Dashboard'}]
                .concat(
                    this.localData.users
                        .filter(user => this.userInfo.dashboardShares.includes(user.userID))
                        .map(user => {
                            return {
                                id: user.userID,
                                description: (user.name + ' ' + user.surname).trim()
                            }
                        })
                        .filter(dash => blankString(this.filterStr) ? true : dash.description.toLowerCase().includes(this.filterStr.toLowerCase()))
                        .sort((a,b) => a['description'] < b['description'] ? -1 : 1)
                )
        },

        selectedDash() {
            if (this.value === null) {
                return {
                    initials: null,
                    description: 'My Dashboard'
                }
            } else {
                return this.localData.users
                    .filter(user => user.userID === this.value)
                    .map(user => {

                        let firstInit = null
                        try {
                            firstInit = `${user.name}`.trim().split(' ').shift()[0];
                        } catch(e) {console.log(e)}

                        let lastInit = null
                        try {
                            lastInit = `${user.surname}`.trim().split(' ').pop()[0];
                        } catch(e) {console.log(e)}

                        return {
                            initials: (!blankString(firstInit) ? firstInit : '' + !blankString(lastInit) ? lastInit : '').toUpperCase(),
                            description: `${user.name} ${user.surname}`.trim()
                        }
                    })
                    .pop()
            }
        }
    }
}
</script>

<style scoped>

</style>