<template>
    <v-dialog
            fullscreen
            v-model="shown"
            persistent
    >
        <v-card
                flat
                class="fill-height"
                style="overflow-y: auto"
        >
            <v-layout column fill-height >
                <v-flex shrink >
<!--Card Title-->
                    <v-card-title class="py-2 pr-2 primary">
                        <span class="title font-weight-light white--text">Mail Template</span>
                        <v-spacer></v-spacer>
                        <v-btn flat icon color="white" class="pa-0 ma-0" @click="showInfo = true">
                            <v-icon>info</v-icon>
                        </v-btn>
                        <app-input-dlg
                                :show-dialog="showInfo"
                                title="Extra Variables"
                                :cancel-button="true"
                                :show-input-box="false"
                                @dismiss="showInfo = false"
                                :body-padding="false"
                        >
                            <template slot="body">
                                <div class="pa-2">
                                    <v-layout row align-center justify-space-between>
                                        <v-flex shrink style="white-space: nowrap">Task ID</v-flex>
                                        <v-flex shrink style="white-space: nowrap" v-pre>{{TaskID}}</v-flex>
                                    </v-layout>
                                    <v-layout row align-center justify-space-between>
                                        <v-flex shrink style="white-space: nowrap">Task Title</v-flex>
                                        <v-flex shrink style="white-space: nowrap" v-pre>{{TaskTitle}}</v-flex>
                                    </v-layout>
                                    <v-layout row align-center justify-space-between>
                                        <v-flex shrink style="white-space: nowrap">Current Date</v-flex>
                                        <v-flex shrink style="white-space: nowrap" v-pre>{{now}}</v-flex>
                                    </v-layout>
                                </div>
                            </template>
                        </app-input-dlg>
                        <v-btn flat icon color="white" class="pa-0 ma-0" @click="$emit('dismiss', false)">
                            <v-icon>close</v-icon>
                        </v-btn>
                    </v-card-title>
                </v-flex>

                <v-flex xs12 style="overflow-y: auto" v-show="shown">

                    <v-layout row fill-height style="overflow-y: auto">
                        <v-flex xs9 class="scroll" style="overflow-y: auto">
                            <v-layout column fill-height style="overflow-y: auto">

                                <v-flex shrink>
                                    <v-tabs
                                            v-model="modelEditor"
                                            color="primary"
                                            slider-color="secondary"
                                            grow
                                    >
                                        <v-tab
                                                key="MailTemplate"
                                                class="white--text"
                                        >
                                            Mail Template
                                        </v-tab>
                                        <v-tab
                                                key="WorkflowAttachments"
                                                class="white--text"
                                        >
                                            Attachments
                                        </v-tab>
                                    </v-tabs>
                                </v-flex>
                                <v-flex xs12 v-show="modelEditor == 0" class="scroll" style="overflow-y: hidden" id="editorWorkflowFlex" v-if="shown">

                                    <editor
                                        :extra-config="editorConfig"
                                        v-model="bodyContent"
                                    />
                                </v-flex>
                                <v-flex xs12 v-if="modelEditor == 1 && shown" style="overflow-y: auto">
                                    <v-layout row fill-height style="overflow-y: auto">
                                        <v-flex xs6 class="scroll" style="overflow-y: auto">
                                            <div class="pa-1 pt-2">
                                                <v-layout row align-center>
                                                    <v-flex xs12><span class="subheading primary--text">Workflow Attachments</span></v-flex>
                                                    <v-flex shrink>
                                                        <v-tooltip top>
                                                            <v-btn
                                                                    small
                                                                    slot="activator"
                                                                    icon
                                                                    flat
                                                                    class="pa-0 ma-0"
                                                                    @click="clearAllWorkflowAttachments"
                                                                    color="primary"
                                                            >
                                                                <v-icon>clear_all</v-icon>
                                                            </v-btn>
                                                            <span>Clear All Workflow Attachments</span>
                                                        </v-tooltip>
                                                    </v-flex>
                                                </v-layout>
                                            </div>
                                            <v-divider></v-divider>
                                            <v-list dense>
                                                <v-list-tile
                                                        v-for="attachment in workflowAttachments"
                                                        :key="JSON.stringify(attachment)"
                                                        avatar
                                                        @click="() => {}"
                                                        dense
                                                >
                                                    <v-list-tile-action>
                                                        <v-icon color="primary">attach_file</v-icon>
                                                    </v-list-tile-action>

                                                    <v-list-tile-content>
                                                        <v-list-tile-title>
                                                            <template v-if="attachment.type == 1">
                                                                <template v-for="field in fields">
                                                                    <template v-if="field.id == attachment.fieldID">
                                                                        <template v-for="step in steps">
                                                                            <template v-if="field.fk_step == step.id">
                                                                                <span class="body-2 font-weight-medium">{{step.description}}</span>
                                                                                &nbsp;-&nbsp;
                                                                                <span class="body-1 font-weight-regular">{{field.description}}</span>
                                                                            </template>
                                                                        </template>
                                                                    </template>
                                                                </template>
                                                            </template>
                                                            <template v-if="attachment.type == 2">
                                                                <template v-for="field in fields">
                                                                    <template v-if="field.id == attachment.selectFieldID">
                                                                        <template v-for="fieldset in fieldSets">
                                                                            <template v-if="fieldset.id == field.config.dataset">
                                                                                <template v-for="fsField in fieldset.fields">
                                                                                    <template v-if="fsField.id == attachment.fieldID">
                                                                                        <span class="body-2 font-weight-medium">{{field.description}}</span>
                                                                                        &nbsp;-&nbsp;
                                                                                        <span class="body-1 font-weight-regular">{{fsField.description}}</span>
                                                                                    </template>
                                                                                </template>
                                                                            </template>
                                                                        </template>
                                                                    </template>
                                                                </template>
                                                            </template>
                                                        </v-list-tile-title>
                                                    </v-list-tile-content>

                                                    <v-list-tile-action>
                                                        <v-btn
                                                                icon
                                                                flat
                                                                color="red"
                                                                class="pa-0 ma-0"
                                                                @click="removeAttachment(attachment)"
                                                        >
                                                            <v-icon>close</v-icon>
                                                        </v-btn>
                                                    </v-list-tile-action>
                                                </v-list-tile>
                                            </v-list>
                                        </v-flex>
                                        <v-flex><v-divider vertical></v-divider></v-flex>
                                        <v-flex xs6 class="scroll" style="overflow-y: auto">
                                            <div class="pa-1 pt-2">
                                                <v-layout row align-center>
                                                    <v-flex xs12><span class="subheading primary--text">Custom Attachments</span></v-flex>
                                                    <v-flex shrink>
                                                        <v-tooltip top>
                                                            <v-btn
                                                                    small
                                                                    slot="activator"
                                                                    icon
                                                                    flat
                                                                    class="pa-0 ma-0"
                                                                    @click="addAttachment"
                                                                    color="primary"
                                                            >
                                                                <v-icon>add</v-icon>
                                                            </v-btn>
                                                            <span>Add Attachment</span>
                                                        </v-tooltip>

<!--Uploader-->
                                                        <app-document-upload-dlg
                                                                :dialog="showUpload"
                                                                :user-info="userInfo"
                                                                :field-value="true"
                                                                @documentsUploaded="uploadFinished"
                                                        />
                                                    </v-flex>
                                                </v-layout>
                                            </div>
                                            <v-divider></v-divider>
                                            <div style="width: 100%" class="text-xs-center" v-if="loadingDocuments">
                                                <v-progress-circular
                                                        :size="40"
                                                        :width="5"
                                                        color="primary"
                                                        indeterminate
                                                ></v-progress-circular>
                                            </div>
                                            <template v-else>

                                                <template v-for="document in documents">
                                                    <div class="pa-2">
                                                        <app-document-container
                                                                :document="document"
                                                                :user-info="userInfo"
                                                                :mail-template="true"
                                                                @removeFromTemplate="removeDocument(document)"
                                                        />
                                                    </div>
                                                </template>

                                            </template>
                                        </v-flex>
                                    </v-layout>
                                </v-flex>
                            </v-layout>
                        </v-flex>
                        <v-flex shrink>
                            <v-divider color="primary" vertical></v-divider>
                        </v-flex>
                        <v-flex xs3 class="scroll" style="overflow-y: auto">
                            <v-layout fill-height column style="overflow-y: auto">
                                <v-flex shrink>
                                    <v-tabs
                                            v-model="model"
                                            color="primary"
                                            slider-color="secondary"
                                            grow
                                    >
                                        <v-tab
                                                key="workflow"
                                                class="white--text"
                                        >
                                            Workflow
                                        </v-tab>
                                        <v-tab
                                                key="Fieldset"
                                                class="white--text"
                                        >
                                            Datasets
                                        </v-tab>
                                    </v-tabs>
                                </v-flex>
                                <v-flex shrink class="pa-1">
                                    <div
                                            class="pa-1"
                                            style="border: 1px dashed grey; width: 100%; border-radius: 3px; cursor: pointer"
                                            @dragstart="$event.dataTransfer.setData('text', '$INPUTFIELD$')"
                                            draggable="true"
                                    >
                                        <v-layout row align-center>
                                            <v-flex shrink>
                                                <v-icon left color="primary">label</v-icon>
                                            </v-flex>
                                            <v-flex xs12>
                                                <span>Input Field</span>
                                            </v-flex>
                                        </v-layout>
                                    </div>
                                </v-flex>
<!--Workflow Fields-->
                                <v-flex style="overflow-y: auto" class="scroll" v-show="model == 0">
                                    <app-workflow-step-select-field
                                            :user-info="userInfo"
                                            :fields="fields"
                                            :steps="steps"
                                            icon="label"
                                            @input="addWorkflowField($event)"
                                            :type-filter="[1,2,3,4,5,6,7,22]"
                                            :dragFields="true"
                                    />
                                </v-flex>
<!--Fieldset Fields-->
                                <v-flex style="overflow-y: auto" class="scroll" v-show="model == 1">
                                    <app-mail-template-fieldsets-bar
                                            :user-info="userInfo"
                                            :fields="fields"
                                            :steps="steps"
                                            @input="addFieldsetField($event)"
                                            :type-filter="[1,2,3,4,5,6,7]"
                                            :field-sets="fieldSets"
                                            :dragFields="true"
                                    />
                                </v-flex>
                            </v-layout>

                        </v-flex>
                    </v-layout>

                </v-flex>
                <v-tooltip left>
                    <v-btn
                            color="secondary"
                            dark
                            fab
                            fixed
                            bottom
                            right
                            slot="activator"
                            @click="saveChanges"
                    >
                        <v-icon>save</v-icon>
                    </v-btn>
                    <span>Save Changes And Close</span>
                </v-tooltip>
            </v-layout>
        </v-card>
        <app-input-dlg
                :show-dialog="showAddInput"
                title="Add Input Field"
                body="Please provide a description for the input field that you would like to add:"
                :cancel-button="true"
                :show-input-box="true"
                :buttons="['Add']"
                :call-backs="[ addInputFieldToDoc ]"
                @dismiss="dismissAddInput(false)"
        />
    </v-dialog>
</template>

<script>

    import {eventBus} from "../../../../main";
    import {codeBaseMixin} from "../../../../codeBaseMixin";
    import WorkflowStepSelectField from './Manage/ManageDetailComponents/WorkflowStepSelectField';
    import MailTemplateFieldsetsBar from './MailTemplateFieldsetsBar';
    import DocumentUploadDlg from '../../../../Tasks/TaskView/components/Documents/DocumentUploadDlg';
    import DocumentContainer from '../../../../Tasks/TaskView/components/Documents/DocumentContainer';
    import InputDlg from "../../../../components/General/InputDlg";
    import axios from "axios";
    import Editor from "@/Editor/Editor";


    export default {
        mixins: [codeBaseMixin],
        components: {
            Editor,
            appInputDlg: InputDlg,
            // appEditor: tinyEditor,
            appWorkflowStepSelectField: WorkflowStepSelectField,
            appMailTemplateFieldsetsBar: MailTemplateFieldsetsBar,
            appDocumentUploadDlg: DocumentUploadDlg,
            appDocumentContainer: DocumentContainer
        },
        props: {
            field: Object,
            fields: Array,
            steps: Array,
            fieldSets: Array,
            userInfo: Object,
            value: Object,
            shown: Boolean
        },
        data() {
            return {
                embeddedMailAttachments: [],
                bodyContent: '',
                model: 0,
                modelEditor: 0,
                workflowAttachments: [],
                mailAttachments: [],
                documents: [],
                showUpload: {show: false},
                loadingDocuments: false,
                inputDescription: null,
                prefixKey: null,
                editor: null,
                showAddInput: false,
                addInputDescription: null,
                config: null,
                showInfo: false
            }
        },

        watch: {
            shown: {
                immediate: true,
                handler(visible) {
                    this.imageCount = 0;

                    if (visible) {
                        this.modelEditor = 0;
                        this.documents = [];
                        this.workflowAttachments = [];
                        this.mailAttachments = [];

                        this.prefixKey = new Date();
                        this.prefixKey = this.prefixKey.toString();
                        this.workflowAttachments = this.fieldLookup.workflowAttachments;
                        this.mailAttachments = this.fieldLookup.mailAttachments;

                        this.bodyContent = this.fieldLookup.body.split('#Q!').join('"');

                        if (this.mailAttachments.length > 0) {this.getDocuments()}
                    }
                }
            },

            bodyModel(val) {
                this.mailAttachments.forEach((attachment, i) => {
                    try {
                        if (attachment.type === 'embeddedUploadTemplate') {
                            if (!val.toLowerCase().includes(attachment.link.toLowerCase())) {
                                this.mailAttachments.splice(i, 1)
                            }
                        }
                    } catch(e) {
                        console.log(e)
                    }
                })

            },
        },

        methods: {
            editorDrop(dropEvent, editor, vm) {

                let dropData = dropEvent.dataTransfer.getData('Text');

                if (dropData == '$INPUTFIELD$') {
                    vm.waitForAddInput()
                        .then((fieldName) => {
                            let tag = `&nbsp;<span id="3-${fieldName}" style="background: #4CAF50; border-radius: 4px;  color: white; text-align: center; padding: 1px 4px; font-size: 11px; outline: none">${fieldName}</span>&nbsp;`
                            editor.insertContent(tag)
                        })
                        .catch((e) => {
                            console.log(e)
                        })
                } else {
                    editor.insertContent(dropData)
                }
            },

            imageRemoved($img) {
                this.$nextTick(() => {


                    // $img.forEach(img => {
                    //     console.log(img)
                        this.mailAttachments = this.mailAttachments.filter(obj => obj.type !== 'embeddedUploadTemplate' ? true : obj.id !== $img[0].dataset.id)
                    // })
                })
            },
            imageInserted($img, response) {
                $img[0].style.maxWidth = "100%";
                $img[0].style.width = "auto";
                let res = JSON.parse(response);
                this.mailAttachments.push({
                    type: 'embeddedUploadTemplate',
                    filename: res.filename,
                    id: res.id,
                    link: res.link,
                    size: res.size
                })
            },
            waitForAddInput() {
                return new Promise((resolve, reject) => {
                    this.addInputDescription = null;
                    this.showAddInput = true;

                    let addInputWaitID = setInterval(() => {
                        if (this.showAddInput == false) {
                            switch (this.addInputDescription) {
                                case null: {
                                    reject('User Canceled');
                                    break;
                                }
                                default: {
                                    resolve(this.addInputDescription)
                                }
                            }
                            clearInterval(addInputWaitID);
                        }
                    }, 200);
                })
            },
            addInputFieldToDoc(fieldName) {
                if (fieldName != null && fieldName != '') {
                    this.addInputDescription = fieldName;
                    this.dismissAddInput(true);
                } else {
                    this.showSnack('Error', 'Field description can not be blank', 'Close', 'red')
                    this.dismissAddInput(false);
                }
            },
            dismissAddInput(saveField) {
                if (saveField) {
                    this.showAddInput = false;
                } else {
                    this.addInputDescription = null;
                    this.showAddInput = false;
                }
            },
            addInput() {
                this.addInputField(this.inputDescription);
                this.inputDescription = null;
            },

            saveChanges() {

                let sTemp = this.bodyContent;

                this.fieldLookup = {
                    mailAttachments: this.mailAttachments ? this.mailAttachments.reduce((list, att) => {
                        try {
                            if (att.type !== "embeddedUploadTemplate")
                                return [...list, att]

                            if (this.bodyContent.indexOf(att.id) > -1)
                                return [...list, att]

                            return list
                        } catch (e) {
                            return [...list, att]
                        }
                    }, []) : [],
                    fixedDocuments: this.documents,
                    workflowAttachments: this.workflowAttachments,
                    body: sTemp.split('"').join('#Q!').replace(/(?:\r\n|\r|\n)/g, '')
                };

                this.$emit('dismiss', true)
            },
            removeDocument(document) {
                this.mailAttachments.forEach((attachment, i) => {
                    if (attachment == document.documentDBID) {
                        this.mailAttachments.splice(i, 1)
                    }
                });

                this.documents.forEach((loopDoc, i) => {
                    if (loopDoc.documentDBID == document.documentDBID) {
                        this.documents.splice(i, 1)
                    }
                });

                this.showSnack('Info', 'Document Removed', 'Close', 'primary')
            },
            getDocuments() {
                this.loadingDocuments = true;
                this.fetch({method: 'post', url: '/workflowEditor/getDocumentList/' + this.userInfo.entityID, body: JSON.stringify(this.mailAttachments)})
                    .then((data) => {
                        this.documents = data;
                        this.loadingDocuments = false;
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e)
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                    })

            },
            uploadFinished(data) {
                this.documents.push(data);
                this.mailAttachments.push(data.documentDBID);
            },
            clearAllWorkflowAttachments() {
                this.workflowAttachments = [];
                this.showSnack('Info', 'All Attachments Removed', 'Close', 'primary')
            },
            addAttachment() {
                this.showUpload.show = true;
            },
            removeAttachment(removeAttachment) {
                this.workflowAttachments.forEach((attachment, i) => {
                    if (removeAttachment === attachment) {
                        this.workflowAttachments.splice(i, 1);
                        this.showSnack('Info', 'Attachment Removed', 'Close', 'primary');
                    }
                })
            },
            addFieldsetField(data) {
                // type: fieldSetField.type,
                let exists = false;
                this.workflowAttachments.forEach(workflowAttachment => {
                    if (workflowAttachment.type == 2 && workflowAttachment.fieldID == data.fieldSetField && workflowAttachment.selectFieldID == data.selectField) {
                        exists = true
                    }
                });
                if (!exists) {
                    this.workflowAttachments.push({
                        type: 2,
                        fieldID: data.fieldSetField,
                        selectFieldID: data.selectField
                    });
                    this.modelEditor = 1;
                    this.showSnack('Info', 'Attachment Added', 'Close', 'primary')
                } else {
                    this.showSnack('Error', 'Attachment Already Added', 'Close', 'red')

                }
            },
            addWorkflowField(field) {
                let exists = false;
                this.workflowAttachments.forEach(workflowAttachment => {
                    if (workflowAttachment.type == 1 && workflowAttachment.fieldID == field.id) {
                        exists = true
                    }
                });
                if (!exists) {
                    this.workflowAttachments.push({
                        type: 1,
                        fieldID: field.id
                    });
                    this.modelEditor = 1;
                    this.showSnack('Info', 'Attachment Added', 'Close', 'primary')
                } else {
                    this.showSnack('Error', 'Attachment Already Added', 'Close', 'red')
                }
            },
            uploadImage(blobInfo, success) {
                ++this.imageCount
                let h = new Headers();
                let fd = new FormData();
                let myFile = blobInfo.blob();
                fd.append('attachmentFile', myFile);

                axios.request({
                    method: "post",
                    url: `${this.serverAddress}/upload/mailTemplateAttachment/${this.userInfo.entityID}`,
                    data: fd,
                })
                    .then(data => {
                        success(data.data.link);

                        this.mailAttachments.push({
                            type: 'embeddedUploadTemplate',
                            filename: data.data.filename,
                            id: data.data.id,
                            link: data.data.link,
                            size: data.data.size
                        })
                        --this.imageCount;
                    })
                    .catch(() => {
                        this.showSnack('Error', 'Image Upload Error', 'Close', 'red');
                        success('.blank');
                        --this.imageCount;
                    })
            },
        },


        computed: {

            editorConfig() {
                // testDrop(event, editor) {
                //     console.log('DROP EVENT', event)
                //
                //     editor.insertContent('<span style="background: fuchsia; display: inline-block; cursor: pointer" :draggable="true">Test123456</span>')
                // },
                // paste_data_images: true,
                // images_upload_handler: this.uploadImage,

                // },
                // uploadImage(blobInfo, success) {
                //     ++this.imageCount
                //     let h = new Headers();
                //     let fd = new FormData();
                //     let myFile = blobInfo.blob();
                //     fd.append('attachmentFile', myFile);
                //
                //     axios.request({
                //         method: "post",
                //         url: `${this.serverAddress}/upload/noteAttachment/${this.userInfo.entityID}`,
                //         data: fd,
                //         onUploadProgress: (p) => {
                //             this.uploadProgress = (p.loaded / p.total) * 100
                //         },
                //     })
                //         .then(data => {
                //             success(data.data.link);
                //             --this.imageCount;
                //         })
                //         .catch(() => {
                //             this.showSnack('Error', 'Image Upload Error', 'Close', 'red');
                //             success('.blank');
                //             --this.imageCount;
                //         })
                // },
                let vm = this
                return {
                    paste_data_images: true,
                    images_upload_handler: this.uploadImage,
                    remove_linebreaks : false,
                    init_instance_callback: function(editor) {
                        editor.focus();
                        editor.on('drop', function(e) {
                            vm.editorDrop(e, editor, vm)
                        })
                    },
                }
            },


            fieldLookup: {
                get: function(){
                    try {
                        let tTest = this.shown;
                        this.field.body = typeof this.field.body != "undefined" && this.field.body != null && this.field.body != "" ? {
                            workflowAttachments: typeof this.field.body.workflowAttachments != "undefined" && this.field.body.workflowAttachments != null ? this.field.body.workflowAttachments : [],
                            mailAttachments: typeof this.field.body.mailAttachments != "undefined" && this.field.body.mailAttachments != null ? this.field.body.mailAttachments : [],
                            body: typeof this.field.body.body != "undefined" && this.field.body.body != null ? this.field.body.body : '<p></p>',
                        } : {
                            workflowAttachments: [],
                            mailAttachments: [],
                            body: '<p></p>'
                        };
                        return this.field.body;
                    } catch (e) {
                        console.log(e)
                        return {
                            workflowAttachments: [],
                            mailAttachments: [],
                            body: '<p></p>'
                        }
                    }
                },
                set: function(newValue) {
                    this.field.body = newValue;
                }
            },
        }
    }
</script>

<style scoped>

</style>
