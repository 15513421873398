<template>
    <div>
        <div class="text-xs-center">
            <v-btn
                    small
                    class="px-2 py-0 ma-0"
                    color="secondary"
                    @click="showManageSmtp = !showManageSmtp"
            >Manage Smtp Server Settings</v-btn>
        </div>
        <app-input-dlg
                v-if="showManageSmtp"
                :show-dialog="showManageSmtp"
                title="Manage Smtp Servers"
                :cancel-button="true"
                :show-input-box="false"
                @dismiss="showManageSmtp = false"
                :body-padding="false"
        >
            <template slot="body">
                <div class="pa-1" style="overflow-x: hidden">
                    <div :class="topicClass">
                        <div :class="headingClass">
                                Add New SMTP Server
                        </div>
                        <div :class="itemClass" class="text-xs-center">
                            <v-btn
                                    small
                                    color="secondary"
                                    class="pa-0 ma-0"
                                    @click="editServ(-1)"
                            >Add Server</v-btn>
                        </div>
                    </div>
                    <div :class="topicClass" v-if="smtpServers.length > 0" style="overflow-x: hidden">
                        <div :class="headingClass">
                            Manage Existing Servers
                        </div>
                        <v-divider></v-divider>
                        <div style="max-height: 400px; overflow-x: hidden" class="scrollable" >

                            <div v-for="server in smtpServers" :key="server.id" style="overflow-x: hidden">
                                <v-layout row align-center class="hover-item hide-parent" style="cursor: pointer; overflow-x: hidden">
                                    <v-flex xs12 class="px-2 py-1" @click="editServ(server.id)" style="overflow-x: hidden">
                                        <div>
                                            {{server.server}}
                                        </div>
                                        <div class="caption greyType--text text-truncate" style="overflow-x: hidden">
                                            {{blankString(server.sendAddresses) ? '*Required Click To Edit' : server.sendAddresses.split(';').map(obj => {return obj.trim()}).join(', ')}}
                                        </div>
                                    </v-flex>
                                    <v-flex shrink class="pr-2 hide-child">
                                        <v-btn
                                                icon
                                                flat
                                                small
                                                color="red"
                                                class="pa-0 ma-0"
                                                @click="spliceServer(server.id)"
                                        >
                                            <v-icon>delete</v-icon>
                                        </v-btn>
                                    </v-flex>
                                    <v-flex shrink class="pr-2">
                                        <v-btn
                                                icon
                                                flat
                                                small
                                                color="primary"
                                                class="pa-0 ma-0"
                                                @click="editServ(server.id)"
                                        >
                                            <v-icon>edit</v-icon>
                                        </v-btn>
                                    </v-flex>
                                </v-layout>
                                <v-divider></v-divider>
                            </div>

                        </div>
                    </div>
                </div>
            </template>
            <template slot="actions">

            </template>
        </app-input-dlg>

        <app-input-dlg
                v-if="showEditAddSrv"
                :show-dialog="showEditAddSrv"
                :title="editTitle"
                :cancel-button="true"
                :show-input-box="false"
                @dismiss="showEditAddSrv = false"
                :body-padding="false"
                :width="800"
        >
            <template slot="body">
                <div class="pa-1" style="overflow-x: hidden">
<!--From Addresses-->
                    <div :class="topicClass" style="overflow-x: hidden">
                        <div class="primary--text caption font-weight-bold">
                            Send From Addresses
                        </div>
                        <div :class="itemClass" style="overflow-x: hidden" class="pt-1">
                            <div @click="showManageAddressList = true" class="hover-item body-1 px-2 py-1 text-truncate" style="border: 1px solid grey; border-radius: 3px" :class="blankString(editServerVal.sendAddresses) ? 'red--text caption text-xs-center font-weight-bold' : 'greyType--text'">
                                {{blankString(editServerVal.sendAddresses) ? '*Required Click To Edit' : editServerVal.sendAddresses.split(';').map(obj => {return obj.trim()}).join(', ')}}
                            </div>
                        </div>
                    </div>
<!--Username/Password-->
                    <v-layout row>
                        <v-flex xs6>
                            <div :class="topicClass" style="overflow-x: hidden">
                                <div class="primary--text caption font-weight-bold">
                                    Username (Blank To Disable Authentication)
                                </div>
                                <div :class="itemClass">
                                    <v-text-field
                                            label="Username"
                                            v-model="editServerVal.user"
                                            color="primary"
                                            style="width: 100%"
                                            class="ma-0 pa-0"
                                            hide-details
                                            single-line
                                    />
                                </div>
                            </div>
                        </v-flex>
                        <v-flex xs6>
                            <div :class="topicClass" style="overflow-x: hidden">
                                <div class="primary--text caption font-weight-bold">
                                    Password
                                </div>
                                <div :class="itemClass">
                                    <v-text-field
                                            label="Password"
                                            v-model="editServerVal.password"
                                            color="primary"
                                            style="width: 100%"
                                            class="ma-0 pa-0"
                                            hide-details
                                            single-line
                                            type="password"
                                    />
                                </div>
                            </div>
                        </v-flex>
                    </v-layout>
<!--Server Port TLS-->
                    <v-layout row>
                        <v-flex xs6>
                            <div :class="topicClass" style="overflow-x: hidden">
                                <div class="primary--text caption font-weight-bold">
                                    Server
                                </div>
                                <div :class="itemClass">
                                    <v-text-field
                                            label="Server"
                                            v-model="editServerVal.server"
                                            color="primary"
                                            style="width: 100%"
                                            class="ma-0 pa-0"
                                            hide-details
                                            single-line
                                    />
                                </div>
                            </div>
                        </v-flex>
                        <v-flex xs3>
                            <div :class="topicClass" style="overflow-x: hidden">
                                <div class="primary--text caption font-weight-bold">
                                    Port
                                </div>
                                <div :class="itemClass">
                                    <v-text-field
                                            label="Port"
                                            v-model="editServerVal.port"
                                            color="primary"
                                            style="width: 100%"
                                            class="ma-0 pa-0"
                                            hide-details
                                            single-line
                                    />
                                </div>
                            </div>
                        </v-flex>
                        <v-flex xs3>
                            <div :class="topicClass" style="overflow-x: hidden">
                                <div class="primary--text caption font-weight-bold">
                                    TLS
                                </div>
                                <div :class="itemClass">
                                    <v-autocomplete
                                            color="primary"
                                            label="TLS"
                                            :items="[{id:0, desc: 'Start TLS (Auto)'}, {id:1, desc: 'Enabled'}, {id:2, desc: 'Disabled'}]"
                                            item-text="desc"
                                            item-value="id"
                                            flat
                                            v-model="editServerVal.tls"
                                            hide-details
                                            dense
                                            single-line
                                            class="pa-0 ma-0"
                                    >
                                    </v-autocomplete>
                                </div>
                            </div>
                        </v-flex>
                    </v-layout>


                </div>
            </template>
            <template slot="actions">
                <v-btn
                        class="pa-0 ma-0 px-2"
                        color="secondary"
                        @click="testSmtpSettings()"
                        :loading="testingSmtp"
                        :disabled="testingSmtp || !testReady"
                        small
                        outline
                >
                    <v-icon left>check_box</v-icon>
                    Test SMTP Server Settings
                </v-btn>
                <v-btn
                        class="pa-0 ma-0 ml-2 px-2"
                        color="secondary"
                        @click="updateServerVal"
                        :loading="testingSmtp || modifyingServer"
                        :disabled="testingSmtp || !testReady || modifyingServer"
                        small
                >
                    <v-icon left>save</v-icon>
                    Done
                </v-btn>
            </template>
        </app-input-dlg>

        <app-input-dlg
                v-if="smtpTestErrorMessage != null"
                :show-dialog="smtpTestErrorMessage != null"
                title="SMTP Server Test Result"
                :body="smtpTestErrorMessage"
                :cancel-button="true"
                :show-input-box="false"
                @dismiss="smtpTestErrorMessage = null"
                :width="700"
        />


        <app-input-dlg
                :show-dialog="showManageAddressList"
                title="Manage Send From Addresses"
                :cancel-button="true"
                :show-input-box="false"
                @dismiss="showManageAddressList = false"
                :body-padding="false"
        >
            <template slot="body">

                <div class="pt-2">
                    <div class="pl-2 greyType--text font-weight-bold caption">Add Email Address:</div>
                    <v-layout row align-center class="px-2 pb-1">
                        <v-flex xs12>
                            <v-text-field
                                    single-line
                                    hide-details
                                    class="pa-0 ma-0"
                                    label="Add Email Address:"
                                    v-model="addManageAddress"
                                    color="primary"
                                    style="width: 100%"
                                    ref="addManageAddressEdit"
                            />
                        </v-flex>
                        <v-flex class="pl-2">
                            <v-btn
                                    icon
                                    flat
                                    small
                                    color="green"
                                    class="pa-0 ma-0"
                                    @click="manageAddressList.push(addManageAddress); addManageAddress = null; setFocusAddFromAddress()"
                                    :disabled="addManageAddress == null || email(addManageAddress) !== true"
                            >
                                <v-icon>add</v-icon>
                            </v-btn>
                        </v-flex>
                    </v-layout>
                    <div class="pb-1 caption font-weight-bold red--text text-xs-center" v-if="addManageAddress !== null  && email(addManageAddress) !== true">
                        Invalid Email Address
                    </div>
                    <v-divider></v-divider>
                    <div class="scrollable" style="max-height: 300px">
                        <div v-for="(addr, i) in manageAddressList" :key="i" class="hover-item" style="cursor: pointer">
                            <v-layout row align-center class="px-2">
                                <v-flex xs12 class="body-1 py-2">
                                    {{addr}}
                                </v-flex>
                                <v-flex shrink>
                                    <v-btn
                                            icon
                                            flat
                                            small
                                            color="red"
                                            class="pa-0 ma-0"
                                            @click="manageAddressList.splice(i,1)"
                                    >
                                        <v-icon>delete</v-icon>
                                    </v-btn>
                                </v-flex>
                            </v-layout>
                            <v-divider></v-divider>
                        </div>
                    </div>
                </div>

            </template>
            <template slot="actions">
                <v-btn @click="editServerVal.sendAddresses = manageAddressList.map(obj => {return obj.trim()}).join(';'); showManageAddressList = false" :disabled="manageAddressList.length === 0" small color="secondary">Done</v-btn>
            </template>
        </app-input-dlg>
    </div>
</template>

<script>
    import {codeBaseMixin} from "../../codeBaseMixin";
    import InputDlg from "../../components/General/InputDlg";

    export default {
        components: {appInputDlg: InputDlg},
        mixins: [codeBaseMixin],
        props: {
            userInfo: Object,
            smtpServerString: String,
            user: Number
        },
        data() {
            return {
                email: value => {
                    if(value.length > 0) {
                        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                        return pattern.test(value) || 'Invalid Email Address';
                    }
                },
                showManageSmtp: false,
                testingSmtp: false,
                smtpTestErrorMessage: null,
                smtpAddServer: {
                    id: -1,
                    fk_user: null,
                    user: null,
                    password: null,
                    sendAddresses: null,
                    server: null,
                    port: 587,
                    tls: 0
                },

                manageAddressList: [],
                addManageAddress: null,
                showManageAddressList: false,


                editTitle: null,
                showEditAddSrv: false,
                editServerVal: null,
                modifyingServer: false
            }
        },
        watch: {
            showManageAddressList(val) {
                if (val) {
                    try {
                        this.manageAddressList = this.editServerVal.sendAddresses.split(';').map(obj => {return obj.trim()})
                    } catch (e) {
                        // eslint-disable-next-line
                        console.log(e)
                        this.manageAddressList = [];
                    }
                }
            }
        },
        methods: {

            spliceServer(serverID) {
                this.fetch({method: 'PATCH', url: `/localUsers/spliceSmtpServers/${this.userInfo.entityID}/${serverID}/${this.user}`})
                    .then((data) => {
                        this.$emit('updateServers', JSON.stringify(data))
                        this.showSnack('Info', 'SMTP Changes Saved', 'Close', 'primary');
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e);
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                    });
            },

            updateServerVal() {
                this.modifyingServer = true;
                this.editServerVal.fk_user = this.user;

                let arrServers = this.smtpServers
                    .filter(obj => obj.id !== this.editServerVal.id);

                arrServers.push(this.editServerVal)

                this.fetch({method: 'PATCH', url: `/localUsers/updateSmtpServers/${this.userInfo.entityID}/${this.user}`, body: {servers: arrServers}})
                    .then((data) => {
                        this.$emit('updateServers', JSON.stringify(data))
                        this.modifyingServer = false;
                        this.showSnack('Info', 'SMTP Changes Saved', 'Close', 'primary');
                        this.showEditAddSrv = false;
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e);
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                        this.modifyingServer = false;
                    });
            },

            setFocusAddFromAddress() {
                this.$nextTick(() => {
                    this.$refs.addManageAddressEdit.focus();
                })
            },

            editServ(serverID) {
                if (serverID === -1) {
                    this.editTitle = 'Add New SMTP Server';
                    this.editServerVal = {
                        id: -1,
                        fk_user: null,
                        user: null,
                        password: null,
                        sendAddresses: null,
                        server: null,
                        port: 587,
                        tls: 0
                    }
                    this.showEditAddSrv = true;
                } else {
                    this.editServerVal = this.smtpServers.filter(obj => obj.id === serverID).pop();
                    this.editTitle = 'Edit SMTP Server';
                    this.showEditAddSrv = true;
                }
            },

            testSmtpSettings() {
                this.testingSmtp = true;
                this.fetch({method: 'POST', url: `/localUsers/testSmtp/${this.userInfo.entityID}/${this.userInfo.userID}`,
                    body: {
                        smtpAuthUser: this.editServerVal.user,
                        smtpAuthPassword: this.editServerVal.password,
                        smtpTLS: this.editServerVal.tls,
                        smtpServer: this.editServerVal.server,
                        smtpPort: this.editServerVal.port,
                        smtpAddress: this.editServerVal.address,
                    }
                })
                    .then((data) => {
                        this.testingSmtp = false;
                        if (data.success) {
                            this.smtpTestErrorMessage = 'SMTP Test Success'
                        } else {
                            this.smtpTestErrorMessage = data.message;
                        }
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e);
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                        this.testingSmtp = false;
                    });
            },
        },
        computed: {

            testReady() {
                return !this.blankString(this.editServerVal.server) && this.editServerVal.port != null && !this.blankString(this.editServerVal.sendAddresses)
            },

            smtpServers() {
                try {
                    let tServers = JSON.parse(this.smtpServerString);
                    return Array.isArray(tServers) ? tServers.filter(obj => obj !== null && obj.id !== null) : [];
                } catch (e) {
                    return []
                }
            }
        }
    }
</script>

<style scoped>

</style>
