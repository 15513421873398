<template>
    <div
            class="scrollable fill-height pa-1"
            style="overflow-x: hidden"
    >
        <v-card class="scrollable fill-height" style="overflow-x: hidden; background: var(--page-back)" >
            <v-layout column fill-height class="scrollable" style="overflow-x: hidden">

                <v-flex shrink>
                    <v-tabs
                            v-model="view"
                            slider-color="secondary"
                            color="primary"
                            grow
                    >
                        <v-tab class="white--text">
                            Inbox
                        </v-tab>
                        <v-tab class="white--text">
                            Bin
                        </v-tab>
                    </v-tabs>
                </v-flex>

                <v-flex class="px-2 pt-2 pb-1">
                    <v-layout row>
                        <v-flex xs12>
                                    <v-text-field
                                            color="primary"
                                            solo
                                            v-model="filterStr"
                                            hide-details
                                            class="pa-0 ma-0"
                                            single-line
                                            dense
                                            prepend-inner-icon="search"
                                            label="Search Mails"
                                            @keydown.enter="$emit('newSearch', filterStr)"
                                            clearable
                                            @click:clear="$emit('newSearch', null)"
                                    >
                                    </v-text-field>
                                    <div class="primary--text caption text-xs-center pt-1" v-if="filterStr !== searchVal">
                                        Press Enter to search
                                    </div>
                        </v-flex>
                        <v-flex shrink>
                            <v-menu open-on-hover offset-y>
                                <template slot="activator">
                                    <v-btn
                                            class="ml-2 mr-0"
                                            icon
                                            color="blue"
                                            flat
                                    >
                                        <v-icon>info</v-icon>
                                    </v-btn>
                                </template>
                                <v-card class="pa-2">
                                    <div class="subheading">
                                        Retention Policy
                                    </div>
                                    <div class="pa-2">
                                        Mails are removed after 30 days
                                        <br>
                                        Deleted mails are removed after 7 days
                                    </div>
                                </v-card>
                            </v-menu>
                        </v-flex>
                        <v-flex shrink>
                            <v-tooltip
                                    top
                                    :disabled="!(Array.isArray(selectedMailIDs) && selectedMailIDs.length)"
                            >
                                <template v-slot:activator="{on}">
                                    <v-btn
                                            class="mx-0"
                                            :disabled="!(Array.isArray(selectedMailIDs) && selectedMailIDs.length)"
                                            icon
                                            color="secondary"
                                            flat
                                            @click="flagDelete(deletedView ? 0 : 1)"
                                            v-on="on"
                                    >
                                        <v-icon>{{ deletedView ? 'inbox' : 'delete' }}</v-icon>
                                    </v-btn>
                                </template>
                                {{ deletedView ? 'Restore' : 'Archive to Bin' }}
                            </v-tooltip>
                        </v-flex>
                    </v-layout>
                </v-flex>

                <v-divider></v-divider>
                <v-flex
                        xs12
                        class="scrollable"
                        style="overflow-x: hidden"
                        ref="mailsBar"
                        @scroll="mailBarScrolled"
                >
                    <div
                            v-for="mail in mailVault"
                            :key="mail.id"
                            style="overflow-x: hidden"
                            :draggable="selectedMailIDs.includes(mail.id)"
                            @drag="$event.preventDefault()"
                            @dragstart="setLinkMails($event)"
                    >
                        <smtp-mail-bar-mail
                                :user-info="userInfo"
                                :mail-config="mailConfig"
                                :local-data="localData"
                                :mail="mail"
                                :selectedMailIDs="selectedMailIDs"
                                :loadingMailIDs="loadingMailIDs"
                                @getMail="$emit('getMail', $event)"
                        />
                    </div>
                    <div class="pa-2 text-xs-center" v-if="loadingMore">
                        <v-progress-circular
                                :size="30"
                                :width="3"
                                color="grey"
                                indeterminate
                        />
                    </div>
                </v-flex>
            </v-layout>
        </v-card>
    </div>
</template>

<script>
    import {codeBaseMixin} from "../../../codeBaseMixin";
    import SmtpMailBarMail from "./smtpMailBarMail";
    import {arrSplice, arrPushMerge} from "../../../codeFunctions";

    export default {
        components: {SmtpMailBarMail},
        mixins: [codeBaseMixin],
        props: {
            mailConfig: Object,
            localData: Object,
            userInfo: Object,
            loadingMore: Boolean,
            deletedView: Boolean,
            mailVault: Array,
            selectedMailIDs: Array,
            loadingMailIDs: Array,
            searchVal: String,
            getSelectedMails: Function
        },
        data() {
            return {
                filterStr: null
            }
        },
        methods: {

            setLinkMails(event) {
                event.dataTransfer.setData('text', JSON.stringify({
                    event: 'smtpMailLink',
                    data: null
                }));
            },

            flagDelete(value) {
                this.$emit('resetMailView')
                arrPushMerge(this.loadingMailIDs, this.selectedMailIDs);
                this.fetch({method: 'POST', url:`/smtp/flagDelete/${this.userInfo.entityID}/${value}`, body: this.selectedMailIDs})
                    .then((data) => {
                        arrSplice(this.mailVault, mail => this.loadingMailIDs.includes(mail.id));
                        arrSplice(this.selectedMailIDs, () => true);
                        arrSplice(this.loadingMailIDs, () => true);
                        this.mailBarScrolled();
                        this.$emit('clearMailView')
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e);
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
                        this.loadingMore = false;
                    })
            },


            mailBarScrolled() {
                let oScrollData = {scrollTop:  this.$refs.mailsBar.scrollTop, scrollHeight:  this.$refs.mailsBar.scrollHeight, clientHeight:  this.$refs.mailsBar.clientHeight}
                this.$emit('bumpScroll', oScrollData)
            }
        },
        computed: {
            deleteView: {
                get() {
                    return this.deletedView ? 'Deleted Mails' : 'Open Mails'
                },
                set(val) {
                    this.$emit('setDeleted', val === 'Deleted Mails');
                }
            },
            view: {
                get() {
                    return this.deletedView ? 1 : 0
                },
                set(val) {
                    this.$emit('setDeleted', val !== 0)
                }
            }
        }
    }
</script>

<style scoped>

</style>
