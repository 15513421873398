<template>
    <div style="overflow-x: auto">

        <v-flex xs12 class="text-xs-center" v-if="showLoading">
            <v-progress-circular
                    :size="28"
                    :width="3"
                    color="secondary"
                    indeterminate
            ></v-progress-circular>
        </v-flex>
        <template v-else>
            <v-layout row align-center v-if="!blockInput" style="overflow-x: auto">
                <v-flex xs12 class="pt-1">
                    <v-layout row wrap @click="runEditProtocol">
                        <!--@click="showEdit = intToBool(value.editable) && stepEditable ? true : false"-->
                        <v-flex xs12 style="overflow-y: auto; max-height: 500px; cursor: pointer; overflow-x: auto">
                            <div v-if="value.value != null && value.value != ''" v-html="value.value" class="maxWidthImages">

                            </div>
                            <div v-else style="border: 1px dashed lightgrey; border-radius: 3px" class="text-xs-center ma-1">
                                <span>{{intToBool(value.editable) ? 'Click to Edit' : 'Read Only'}}</span>
                            </div>
                        </v-flex>
                        <v-flex xs12 v-if="value.value != null && value.value != ''">
                            <v-divider class="grey lighten-1"></v-divider>
                        </v-flex>
                    </v-layout>
                </v-flex>
            </v-layout>
            <div v-else class="py-1">
                <v-layout row align-center>
                    <v-flex shrink>
                        <v-icon class="px-2 amber--text">warning</v-icon>
                    </v-flex>
                    <v-flex>
                        <span class="body-2 greyType--text">{{ fieldSetSelectField.description + ' Required' }}</span>
                    </v-flex>
                </v-layout>
            </div>
        </template>

<!--Read Only View-->
        <v-dialog v-if="viewNote" v-model="viewNote" fullscreen persistent>
            <v-card flat class="fill-height">
                <v-layout column fill-height>
                    <v-flex shrink>

                        <v-card-title class="py-2 pr-2 primary">
                            <v-icon
                                left
                                color="white"
                            >
                                note
                            </v-icon>
                            <span class="title font-weight-light white--text">{{value.description}} (Read Only)</span>
                            <v-spacer></v-spacer>

                            <v-btn
                                @click="viewNote = false"
                                small
                                icon
                                flat
                                color="white"
                                class="pa-0 ma-0"
                            >
                                <v-icon small>close</v-icon>
                            </v-btn>
                        </v-card-title>
                        <v-divider></v-divider>
                    </v-flex>
                    <v-flex xs12 class="scrollable pa-2">
                        <div v-html="value.value"></div>
                    </v-flex>
                </v-layout>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>

    import {codeBaseMixin} from "../../../../../../codeBaseMixin";
    import {DatasetBinder} from "@/Tasks/TaskView V2/components/TaskViewDetail/datasetBinder";
    // let tinyEditor = require('@tinymce/tinymce-vue').default;

    export default {
        mixins:[codeBaseMixin],
        components: {
            // appEditor: tinyEditor,
        },

        props: {
            value: Object,
            stepEditable: Boolean,
            editNoteFn: Function,
            selectFields: Array,
            fieldSets: Array,
            linkedFieldInfo: Object,
            binder: DatasetBinder
        },

        methods: {
            updateValue(newVal) {
                this.value.value = newVal
            },

            runEditProtocol() {
                if (this.value.editable && this.stepEditable) {
                    this.editNoteFn(this.value)
                        .then((newValue) => {
                            // this.value = newValue;
                            this.$emit('input', this.value)

                        })
                        .catch((e) => {
                            console.log(e)

                        })
                } else {
                    this.viewNote = true
                }
            },

            editDone() {
                this.value.value = this.modelText;
                this.showEdit = false;
            }
        },

        watch: {
            showEdit(shown) {
                if (shown) {
                    this.modelText = this.value.value;
                }
            }
        },

        data() {
            return {
                bindData: true,
                showEdit: false,
                modelText: "",
                viewNote: false,
                loading: false
            }
        },
        computed: {
            fieldSetSelectField() {
                try {
                    return this.binder.datasetSelectFieldsRef[this.lookupFieldID]
                } catch (e) {
                    console.log(e)
                    return null
                }
            },

            blockInput() {
                return !!(this.value.linkLookup
                        && this.value.linkLookup.fieldSetSelectField
                        && !this.fieldSetSelectField.value);
            },

            lookupFieldID() {
                return this.value.linkLookup && this.value.linkLookup.fieldSetSelectField ? this.value.linkLookup.fieldSetSelectField : null
            },

            lookupFieldMemberID() {
                if (this.lookupFieldID)
                    return this.fieldSetSelectField
                            ? this.fieldSetSelectField.value
                            : null
                else return null
            },


            showLoading() {
                if (this.lookupFieldID && this.lookupFieldMemberID) {
                    return this.binder.loadingDatasets.includes(Number(this.lookupFieldMemberID))
                } else {
                    return false
                }
            },
        }
    }
</script>

<style scoped>
    img {
        width: 50px
    }
</style>
