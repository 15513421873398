<template>
    <v-layout row align-center class="white--text primary" style="border-radius: 3px">
        <v-flex shrink class="pl-2 pr-1">
            <v-tooltip top v-if="!attachment.uploading && downloadProgress === 0 && !previewProgress && attachment.contentType && ['application/pdf', 'message/rfc822'].includes(attachment.contentType.toLowerCase())">
                <v-icon
                        style="cursor: pointer"
                        color="white"
                        small
                        slot="activator"
                        @click="$emit('previewAttachment', attachment)"
                >
                    visibility
                </v-icon>
                <span>Preview {{attachment.filename || 'Attachment'}}</span>
            </v-tooltip>
<!--Preview Progress-->
            <v-progress-circular
                    v-if="previewProgress"
                    :size="15"
                    :width="3"
                    color="white"
                    :indeterminate="true"
            />
<!--Upload Progress-->
            <v-progress-circular
                    v-if="attachment.uploading"
                    :size="15"
                    :width="3"
                    color="white"
                    :value="attachment.progress"
            />
<!--Download Progress-->
            <v-progress-circular
                    v-if="downloadProgress !== 0"
                    :size="15"
                    :width="3"
                    color="white"
                    :value="downloadProgress"
            />
        </v-flex>
        <v-flex shrink class="pr-1" v-if="!attachment.uploading && downloadProgress === 0 && !previewProgress">
            <v-tooltip top>
                <!--                                                            v-if="!['embeddedUpload', 'uploading', 'embeddedUploadUploading'].includes(attachment.type)"-->
                <!--                                                            @click="runPreviewAttachment(attachment, true)"-->
                <v-icon
                        style="cursor: pointer"
                        color="white"
                        small
                        slot="activator"
                        @click="$emit('downloadAttachment', attachment)"
                >
                    attach_file
                </v-icon>
                <span>Download</span>
            </v-tooltip>
        </v-flex>
        <v-flex shrink class="caption pa-1">
            {{attachment.filename || 'Attachment'}}&nbsp;<span class="caption">{{attSize}}</span>
        </v-flex>
        <v-flex
                shrink
                class="pr-1"
                style="border-bottom-right-radius: 3px; border-top-right-radius: 3px;"
                v-if="!attachment.uploading"
        >
            <v-tooltip top>
                <v-btn
                        small
                        icon
                        flat
                        class="pa-0 ma-0"
                        color="white"
                        slot="activator"
                        @click="$emit('removeAttachment', attachment)"
                >
                    <v-icon small>close</v-icon>
                </v-btn>
                <span>Remove Attachment</span>
            </v-tooltip>
        </v-flex>
    </v-layout>
</template>

<script>
export default {
    props: {
        attachment: Object,
        attSizeRef: Object
    },
    data() {
        return {
            downloadProgress: 0,
            previewProgress: false
        }
    },
    watch: {
        attSizeRef: {
            immediate: true,
            deep:true,
            handler(val) {
                if (val) {
                    this.$forceUpdate();
                }
            }
        }
    },
    computed: {
        attSize() {
            if (this.attachment.size) {
                let kb = this.attachment.size / 1024

                if (kb < 1024)
                    return `(${Math.round((kb) * 10) / 10} KB)`

                return `(${Math.round((kb / 1024) * 10) / 10} MB)`
            } else {
                try {

                    let kb = this.attSizeRef[this.attachment.taskDocumentID] / 1024

                    if (kb < 1024)
                        return `(${Math.round((kb) * 10) / 10} KB)`

                    return `(${Math.round((kb / 1024) * 10) / 10} MB)`
                } catch(e) {
                    return '(...)'
                }
            }
        }
    }
}
</script>

<style scoped>

</style>