import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import 'vuetify/src/stylus/app.styl'

Vue.use(Vuetify, {
    iconfont: 'md',
    theme: {
        primary: '#00695C',
        "title-color": '#00695C',
        appBar: '#00695C',
        secondary: '#D84315',
        "surface-accent": '#D84315',
        greyType: '#757575',
        "deep-orange": '#EF6C00',

        tRed: '#F44336',
        tOrange: '#FF9800',
        tAmber: '#FFC107',
        tGreen: '#4CAF50',
        tBlue: '#2196F3',
        tIndigo: '#3F51B5',
        tGrey: '#9E9E9E',

        tDarkRed: '#C62828',
        tDarkOrange: '#EF6C00',
        tDarkAmber: '#FF8F00',
        tDarkGreen: '#2E7D32',
        tDarkBlue: '#1565C0',
        tDarkIndigo: '#283593'
    },
    options: {
        customProperties: true
    }
});
