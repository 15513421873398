<template>
    <v-card class="fill-height" style="overflow-y: auto">
        <v-layout column fill-height>
<!--Card Title-->
            <v-flex shrink>
                <v-card-title class="py-2 pr-2 title-color">
                    <v-layout row justify-space-between align-center wrap>
                        <v-flex shrink>
                            <span class="title font-weight-light white--text">{{ selectedUser.username }}</span>
                        </v-flex>
                    </v-layout>
                </v-card-title>
            </v-flex>
<!--Tabs-->
            <v-flex shrink class="title-color">
                <v-tabs
                        v-model="model"
                        color="primary"
                        slider-color="secondary"
                        grow
                >
                    <v-tab class="white--text">User Info</v-tab>
                    <v-tab class="white--text">User Options</v-tab>
                    <v-tab class="white--text">User Security</v-tab>
                    <v-tab class="white--text">Integrations</v-tab>
                </v-tabs>
            </v-flex>
<!--User Info-->
            <v-flex v-if="model == 0" xs12 style="overflow-y: auto" class="scroll">
                <v-layout row wrap>
                    <v-flex xs12 class="pl-2 pr-1 pt-2"><span class="body-2 greyType--text">User Information Options:</span></v-flex>
                    <v-flex xs12 class="pl-2 pr-1 pt-2 text-xs-center" >
                        <v-layout row align-center justify-center class="pt-2">
                            <v-flex xs6>
                                <v-text-field
                                        label="Username"
                                        v-model="updateUsername"
                                        color="primary"
                                        style="width: 100%"
                                        class="ma-0 pa-0"
                                        :loading="updatingUsername"
                                        :disabled="updatingUsername"
                                        :rules="[email]"
                                />
                            </v-flex>
                            <v-flex shrink>
                                <v-btn
                                        :loading="updatingUsername"
                                        :disabled="updatingUsername || email(updateUsername) !== true"
                                        small
                                        color="secondary"
                                        @click="saveUsernameChanges()"
                                >Update</v-btn>
                            </v-flex>
                        </v-layout>
                    </v-flex>

                    <v-flex xs5 class="pl-2 pr-1">
                        <v-layout row align-center>
                            <v-flex xs6 class="text-xs-right pr-2">
                                <span class="body-1 greyType--text">Name:</span>
                            </v-flex>
                            <v-flex xs6>
                                <v-text-field
                                        label="Name"
                                        v-model="selectedUser.name"
                                        single-line
                                        color="primary"
                                        hide-details
                                        style="width: 100%"
                                        class="ma-0 pa-0"
                                />
                            </v-flex>
                        </v-layout>
                    </v-flex>

                    <v-flex xs5 class="pl-2 pr-1">
                        <v-layout row align-center>
                            <v-flex xs6 class="text-xs-right pr-2">
                                <span class="body-1 greyType--text">Surname:</span>
                            </v-flex>
                            <v-flex xs6>
                                <v-text-field
                                        label="Surname"
                                        v-model="selectedUser.surname"
                                        single-line
                                        color="primary"
                                        hide-details
                                        style="width: 100%"
                                        class="ma-0 pa-0"
                                />
                            </v-flex>
                        </v-layout>
                    </v-flex>

                    <v-flex xs12 class="pl-2 pr-1 pt-2"><span class="body-2 greyType--text">User Signature Options:</span></v-flex>

                    <v-flex xs5 class="pl-2 pr-1 pt-2">
                        <v-layout row align-center>
                            <v-flex xs6 class="text-xs-right pr-2">
                                <span class="body-1 greyType--text">Job Title:</span>
                            </v-flex>
                            <v-flex xs6>
                                <v-text-field
                                        label="Job Title"
                                        v-model="selectedUser.jobTitle"
                                        single-line
                                        color="primary"
                                        hide-details
                                        style="width: 100%"
                                        class="ma-0 pa-0"
                                />
                            </v-flex>
                        </v-layout>
                    </v-flex>

                    <v-flex xs5 class="pl-2 pr-1 pt-2">
                        <v-layout row align-center>
                            <v-flex xs6 class="text-xs-right pr-2">
                                <span class="body-1 greyType--text">Tel:</span>
                            </v-flex>
                            <v-flex xs6>
                                <v-text-field
                                        label="Tel"
                                        v-model="selectedUser.tel"
                                        single-line
                                        color="primary"
                                        hide-details
                                        style="width: 100%"
                                        class="ma-0 pa-0"
                                />
                            </v-flex>
                        </v-layout>
                    </v-flex>

                    <v-flex xs5 class="pl-2 pr-1 pt-2">
                        <v-layout row align-center>
                            <v-flex xs6 class="text-xs-right pr-2">
                                <span class="body-1 greyType--text">Fax:</span>
                            </v-flex>
                            <v-flex xs6>
                                <v-text-field
                                        label="Fax"
                                        v-model="selectedUser.fax"
                                        single-line
                                        color="primary"
                                        hide-details
                                        style="width: 100%"
                                        class="ma-0 pa-0"
                                />
                            </v-flex>
                        </v-layout>
                    </v-flex>

                    <v-flex xs5 class="pl-2 pr-1 pt-2">
                        <v-layout row align-center>
                            <v-flex xs6 class="text-xs-right pr-2">
                                <span class="body-1 greyType--text">Cell:</span>
                            </v-flex>
                            <v-flex xs6>
                                <v-text-field
                                        label="Cell"
                                        v-model="selectedUser.cell"
                                        single-line
                                        color="primary"
                                        hide-details
                                        style="width: 100%"
                                        class="ma-0 pa-0"
                                />
                            </v-flex>
                        </v-layout>
                    </v-flex>

                    <v-flex xs5 class="pl-2 pr-1 pt-2">
                        <v-layout row align-center>
                            <v-flex xs6 class="text-xs-right pr-2">
                                <span class="body-1 greyType--text">Email:</span>
                            </v-flex>
                            <v-flex xs6>
                                <v-text-field
                                        label="Email"
                                        v-model="selectedUser.email"
                                        single-line
                                        color="primary"
                                        hide-details
                                        style="width: 100%"
                                        class="ma-0 pa-0"
                                />
                            </v-flex>
                        </v-layout>
                    </v-flex>

                    <v-flex xs5 class="pl-2 pr-1 pt-2">
                        <v-layout row align-center>
                            <v-flex xs6 class="text-xs-right pr-2">
                                <span class="body-1 greyType--text">Department:</span>
                            </v-flex>
                            <v-flex xs6>
                                <v-text-field
                                        label="Department"
                                        v-model="selectedUser.department"
                                        single-line
                                        color="primary"
                                        hide-details
                                        style="width: 100%"
                                        class="ma-0 pa-0"
                                />
                            </v-flex>
                        </v-layout>
                    </v-flex>

                    <v-flex xs5 class="pl-2 pr-1 pt-2">
                        <v-layout row align-center>
                            <v-flex xs6 class="text-xs-right pr-2">
                                <span class="body-1 greyType--text">Website Title:</span>
                            </v-flex>
                            <v-flex xs6>
                                <v-text-field
                                        label="Website Title"
                                        v-model="selectedUser.websiteTitle"
                                        single-line
                                        color="primary"
                                        hide-details
                                        style="width: 100%"
                                        class="ma-0 pa-0"
                                />
                            </v-flex>
                        </v-layout>
                    </v-flex>

                    <v-flex xs12 class="pl-2 pr-1 pt-2"><span class="body-2 greyType--text">Signature Links:</span></v-flex>

                    <v-flex xs5 class="pl-2 pr-1 pt-2">
                        <v-layout row align-center>
                            <v-flex xs6 class="text-xs-right pr-2">
                                <span class="body-1 greyType--text">Link - 1:</span>
                            </v-flex>
                            <v-flex xs6>
                                <v-text-field
                                        label="Link - 1"
                                        v-model="selectedUser['link-1']"
                                        single-line
                                        color="primary"
                                        hide-details
                                        style="width: 100%"
                                        class="ma-0 pa-0"
                                />
                            </v-flex>
                        </v-layout>
                    </v-flex>

                    <v-flex xs5 class="pl-2 pr-1 pt-2">
                        <v-layout row align-center>
                            <v-flex xs6 class="text-xs-right pr-2">
                                <span class="body-1 greyType--text">Link - 2:</span>
                            </v-flex>
                            <v-flex xs6>
                                <v-text-field
                                        label="Link - 2"
                                        v-model="selectedUser['link-2']"
                                        single-line
                                        color="primary"
                                        hide-details
                                        style="width: 100%"
                                        class="ma-0 pa-0"
                                />
                            </v-flex>
                        </v-layout>
                    </v-flex>

                    <v-flex xs5 class="pl-2 pr-1 pt-2">
                        <v-layout row align-center>
                            <v-flex xs6 class="text-xs-right pr-2">
                                <span class="body-1 greyType--text">Link - 3:</span>
                            </v-flex>
                            <v-flex xs6>
                                <v-text-field
                                        label="Link - 3"
                                        v-model="selectedUser['link-3']"
                                        single-line
                                        color="primary"
                                        hide-details
                                        style="width: 100%"
                                        class="ma-0 pa-0"
                                />
                            </v-flex>
                        </v-layout>
                    </v-flex>

                    <v-flex xs5 class="pl-2 pr-1 pt-2">
                        <v-layout row align-center>
                            <v-flex xs6 class="text-xs-right pr-2">
                                <span class="body-1 greyType--text">Link - 4:</span>
                            </v-flex>
                            <v-flex xs6>
                                <v-text-field
                                        label="Link - 4"
                                        v-model="selectedUser['link-4']"
                                        single-line
                                        color="primary"
                                        hide-details
                                        style="width: 100%"
                                        class="ma-0 pa-0"
                                />
                            </v-flex>
                        </v-layout>
                    </v-flex>

                    <v-flex xs5 class="pl-2 pr-1 pt-2">
                        <v-layout row align-center>
                            <v-flex xs6 class="text-xs-right pr-2">
                                <span class="body-1 greyType--text">Link - 5:</span>
                            </v-flex>
                            <v-flex xs6>
                                <v-text-field
                                        label="Link - 5"
                                        v-model="selectedUser['link-5']"
                                        single-line
                                        color="primary"
                                        hide-details
                                        style="width: 100%"
                                        class="ma-0 pa-0"
                                />
                            </v-flex>
                        </v-layout>
                    </v-flex>

                    <v-flex xs5 class="pl-2 pr-1 pt-2">
                        <v-layout row align-center>
                            <v-flex xs6 class="text-xs-right pr-2">
                                <span class="body-1 greyType--text">Link - 6:</span>
                            </v-flex>
                            <v-flex xs6>
                                <v-text-field
                                        label="Link - 6"
                                        v-model="selectedUser['link-6']"
                                        single-line
                                        color="primary"
                                        hide-details
                                        style="width: 100%"
                                        class="ma-0 pa-0"
                                />
                            </v-flex>
                        </v-layout>
                    </v-flex>

                    <v-flex xs5 class="pl-2 pr-1 pt-2">
                        <v-layout row align-center>
                            <v-flex xs6 class="text-xs-right pr-2">
                                <span class="body-1 greyType--text">Link - 7:</span>
                            </v-flex>
                            <v-flex xs6>
                                <v-text-field
                                        label="Link - 7"
                                        v-model="selectedUser['link-7']"
                                        single-line
                                        color="primary"
                                        hide-details
                                        style="width: 100%"
                                        class="ma-0 pa-0"
                                />
                            </v-flex>
                        </v-layout>
                    </v-flex>

                    <v-flex xs5 class="pl-2 pr-1 pt-2">
                        <v-layout row align-center>
                            <v-flex xs6 class="text-xs-right pr-2">
                                <span class="body-1 greyType--text">Link - 8:</span>
                            </v-flex>
                            <v-flex xs6>
                                <v-text-field
                                        label="Link - 8"
                                        v-model="selectedUser['link-8']"
                                        single-line
                                        color="primary"
                                        hide-details
                                        style="width: 100%"
                                        class="ma-0 pa-0"
                                />
                            </v-flex>
                        </v-layout>
                    </v-flex>

                    <v-flex xs5 class="pl-2 pr-1 pt-2">
                        <v-layout row align-center>
                            <v-flex xs6 class="text-xs-right pr-2">
                                <span class="body-1 greyType--text">Link - 9:</span>
                            </v-flex>
                            <v-flex xs6>
                                <v-text-field
                                        label="Link - 9"
                                        v-model="selectedUser['link-9']"
                                        single-line
                                        color="primary"
                                        hide-details
                                        style="width: 100%"
                                        class="ma-0 pa-0"
                                />
                            </v-flex>
                        </v-layout>
                    </v-flex>

                    <v-flex xs5 class="pl-2 pr-1 pt-2">
                        <v-layout row align-center>
                            <v-flex xs6 class="text-xs-right pr-2">
                                <span class="body-1 greyType--text">Link - 10:</span>
                            </v-flex>
                            <v-flex xs6>
                                <v-text-field
                                        label="Link - 10"
                                        v-model="selectedUser['link-10']"
                                        single-line
                                        color="primary"
                                        hide-details
                                        style="width: 100%"
                                        class="ma-0 pa-0"
                                />
                            </v-flex>
                        </v-layout>
                    </v-flex>

                    <v-flex xs12 class="pl-2 pr-1 pt-2"><span class="body-2 greyType--text">User Signature Options:</span></v-flex>
                    <v-flex xs12 class="pl-2 pr-1 pt-2 text-xs-center" >
                       <user-signatures :selected-user-i-d="selectedUser.id"/>
                    </v-flex>

                    <v-flex xs12 class="pl-2 pr-1 pt-2"><span class="body-2 greyType--text">Dashboard Share Settings:</span></v-flex>
                    <v-flex xs12 class="pl-2 pr-1 pt-2 text-xs-center" >
                        <dashboard-share
                            :user="selectedUser.id"
                            :local-data="localData"
                        />
                    </v-flex>

                    <v-flex xs12 class="pl-2 pr-1 pt-2"><span class="body-2 greyType--text">Calendar Share Settings:</span></v-flex>
                    <v-flex xs12 class="pl-2 pr-1 pt-2 text-xs-center" >
                        <calendar-share
                            :user="selectedUser.id"
                            :local-data="localData"
                        />
                    </v-flex>

                </v-layout>
            </v-flex>
<!--User Options-->
            <v-flex v-if="model == 1" xs12 style="overflow-y: auto" class="scroll">
                <v-layout shrink>
<!--User Options-->
                    <v-flex xs3>
                        <div class="pa-2">
                            <span class="body-2 greyType--text">User Options:</span>
                        </div>
                        <v-layout column class="pl-2">
                            <v-flex shrink class="pb-2">
                                <v-switch
                                        v-model="disabled"
                                        label="Disabled"
                                        color="primary"
                                        hide-details
                                        class="pa-0 ma-0"
                                        @change="saveChangesDisabled()"
                                />
                            </v-flex>
                            <v-flex shrink class="pb-2">
                                <v-switch
                                        v-model="showAllFolders"
                                        label="Allow Show All Folders"
                                        color="primary"
                                        hide-details
                                        class="pa-0 ma-0"
                                        :disabled="disabled"
                                ></v-switch>
                            </v-flex>
                          
                            <v-flex shrink>
                                <v-btn
                                    block
                                        small
                                        color="secondary"
                                        @click="showSetPassword = true"
                                        :disabled="disabled"
                                >
                                    <v-icon left>vpn_key</v-icon>
                                    Set Password
                                </v-btn>
                            </v-flex>

                            <v-flex shrink>
                                <v-btn
                                    block
                                        small
                                        color="secondary"
                                        @click="showTwoFactor = true"
                                        :disabled="disabled"
                                >
                                    <v-icon left>verified_user</v-icon>
                                  MFA Setup
                                </v-btn>
                                <two-factor
                                        v-if="showTwoFactor"
                                        v-model="showTwoFactor"
                                        :admin="true"
                                        :userID="selectedUser.id"
                                />
                            </v-flex>
                        </v-layout>
                    </v-flex>
<!--Manager Screens-->
                    <v-flex xs3>
                        <div class="pa-2">
                            <span class="body-2 greyType--text">Manager Screens:</span>
                        </div>
                        <v-layout column class="pl-2" wrap>
                            <v-flex xs3 class="pb-2">
                                <v-switch
                                        v-model="userManager"
                                        label="User Manager"
                                        color="primary"
                                        hide-details
                                        class="pa-0 ma-0"
                                        :disabled="disabled"
                                ></v-switch>
                            </v-flex>
                            <v-flex xs3 class="pb-2">
                                <v-switch
                                        v-model="workflowManager"
                                        label="Workflow Manager"
                                        color="primary"
                                        hide-details
                                        class="pa-0 ma-0"
                                        :disabled="disabled"
                                ></v-switch>
                            </v-flex>
                            <v-flex xs3 class="pb-2">
                                <v-switch
                                        v-model="selectListManager"
                                        label="Select List Manager"
                                        color="primary"
                                        hide-details
                                        class="pa-0 ma-0"
                                        :disabled="disabled || workflowManager"
                                ></v-switch>
                            </v-flex>
                            <v-flex xs3 class="pb-2">
                                <v-switch
                                        v-model="fieldsetManager"
                                        label="Dataset Manager"
                                        color="primary"
                                        hide-details
                                        class="pa-0 ma-0"
                                        :disabled="disabled"
                                        @change="$event ? (fieldsetClone = true) : null"
                                ></v-switch>
                            </v-flex>
                            <v-flex xs3 class="pb-2">
                                <v-switch
                                        v-model="signatureManager"
                                        label="Signature Manager"
                                        color="primary"
                                        hide-details
                                        class="pa-0 ma-0"
                                        :disabled="disabled"
                                ></v-switch>
                            </v-flex>
                            <v-flex xs3 class="pb-2">
                                <v-switch
                                        v-model="folderManager"
                                        label="Folder Manager"
                                        color="primary"
                                        hide-details
                                        class="pa-0 ma-0"
                                        :disabled="disabled"
                                ></v-switch>
                            </v-flex>

                            <v-flex xs3 class="pb-2">
                                <v-switch
                                        v-model="importManager"
                                        label="Import Manager"
                                        color="primary"
                                        hide-details
                                        class="pa-0 ma-0"
                                        :disabled="disabled"
                                ></v-switch>
                            </v-flex>

                            <v-flex xs3 class="pb-2">
                                <v-switch
                                        v-model="smtpMailboxManager"
                                        label="Group Mailbox Manager"
                                        color="primary"
                                        hide-details
                                        class="pa-0 ma-0"
                                        :disabled="disabled"
                                ></v-switch>
                            </v-flex>

                            <v-flex xs3 class="pb-2">
                                <v-switch
                                        v-model="docRepoManager"
                                        label="Document Store Manager"
                                        color="primary"
                                        hide-details
                                        class="pa-0 ma-0"
                                        :disabled="disabled"
                                ></v-switch>
                            </v-flex>

                            <v-flex xs3 class="pb-2">
                                <v-switch
                                        v-model="calGroupManager"
                                        label="Calendar Groups Manager"
                                        color="primary"
                                        hide-details
                                        class="pa-0 ma-0"
                                        :disabled="disabled"
                                ></v-switch>
                            </v-flex>
                          
                          <v-flex xs3 class="pb-2">
                            <v-switch
                                v-model="contactsManager"
                                label="Contacts Manager"
                                color="primary"
                                hide-details
                                class="pa-0 ma-0"
                                :disabled="disabled"
                            ></v-switch>
                            
                          </v-flex>
                        </v-layout>
                    </v-flex>
<!--Report Options-->
                    <v-flex xs3>
                        <div class="pa-2">
                            <span class="body-2 greyType--text">Reports Options:</span>
                        </div>
                        <v-layout column class="pl-2" wrap>
                            <v-flex xs3 class="pb-2">
                                <v-switch
                                        v-model="reportsManager"
                                        label="Report Manager"
                                        color="primary"
                                        hide-details
                                        class="pa-0 ma-0"
                                        :disabled="disabled"
                                ></v-switch>
                            </v-flex>
                            <v-flex xs3 class="pb-2">
                                <v-switch
                                        v-model="reportsViewer"
                                        label="Reports Viewer"
                                        color="primary"
                                        hide-details
                                        class="pa-0 ma-0"
                                        :disabled="disabled || reportsManager"
                                ></v-switch>
                            </v-flex>
                            <v-flex xs3 class="pb-2">
                                <v-switch
                                        v-model="productivityReports"
                                        label="Productivity Reports Viewer"
                                        color="primary"
                                        hide-details
                                        class="pa-0 ma-0"
                                        :disabled="disabled"
                                ></v-switch>
                            </v-flex>
                            <v-flex xs3 class="pb-2">
                                <v-switch
                                        v-model="taskHeaderReports"
                                        label="Task Header Report Viewer"
                                        color="primary"
                                        hide-details
                                        class="pa-0 ma-0"
                                        :disabled="disabled"
                                ></v-switch>
                            </v-flex>
                        </v-layout>
                    </v-flex>
<!--User System Options-->
                    <v-flex xs3>
                        <div class="pa-2">
                            <span class="body-2 greyType--text">User System Options:</span>
                        </div>
                        <v-layout column class="pl-2" wrap>
                            <v-flex xs3 class="pb-2">
                                <v-switch
                                        v-model="createTask"
                                        label="Allow Create Task"
                                        color="primary"
                                        hide-details
                                        class="pa-0 ma-0"
                                        :disabled="disabled"
                                ></v-switch>
                            </v-flex>
                            <v-flex xs3 class="pb-2">
                                <v-switch
                                        v-model="createFolder"
                                        label="Allow Create Folder"
                                        color="primary"
                                        hide-details
                                        class="pa-0 ma-0"
                                        :disabled="disabled"
                                ></v-switch>
                            </v-flex>
                            <v-flex xs3 class="pb-2">
                                <v-switch
                                        v-model="createFieldset"
                                        label="Allow Create Dataset"
                                        color="primary"
                                        hide-details
                                        class="pa-0 ma-0"
                                        :disabled="disabled"
                                ></v-switch>
                            </v-flex>
                            <v-flex xs3 class="pb-2">
                                <v-switch
                                        v-model="fieldsetClone"
                                        label="Allow Clone Dataset"
                                        color="primary"
                                        hide-details
                                        class="pa-0 ma-0"
                                        :disabled="disabled || fieldsetManager"
                                ></v-switch>
                            </v-flex>
                            <v-flex xs3 class="pb-2">
                                <v-switch
                                        v-model="allowTransfer"
                                        label="Allow Task Transfer"
                                        color="primary"
                                        hide-details
                                        class="pa-0 ma-0"
                                        :disabled="disabled"
                                ></v-switch>
                            </v-flex>
                          
                          <v-flex xs3>
                            <v-switch
                                v-model="contactsDisabled"
                                label="Disable Chat"
                                color="primary"
                                hide-details
                                class="pa-0 ma-0"
                                :disabled="disabled"
                            ></v-switch>
                          </v-flex>
                        </v-layout>
                    </v-flex>
<!--Out Of Office-->
                    <v-flex xs3>
                        <div class="pa-2">
                            <span class="body-2 greyType--text">Out Of Office Options:</span>
                        </div>
                        <v-layout column class="pl-2" wrap>
                            <v-flex xs6>
                                <v-switch
                                        v-model="allowOutOfOfficeUpdate"
                                        label="Allow Personal Out Of Office Update"
                                        color="primary"
                                        hide-details
                                        class="pa-0 ma-0"
                                        :disabled="disabled"
                                ></v-switch>
                            </v-flex>
                            <v-flex xs6>
                                <v-switch
                                        v-model="outOfOfficeManager"
                                        label="Out Of Office Manager"
                                        color="primary"
                                        hide-details
                                        class="pa-0 ma-0"
                                        :disabled="disabled"
                                ></v-switch>
                            </v-flex>
                        </v-layout>
                        <template v-if="outOfOfficeManager">
                            <div class="pt-2 px-2 pb-0">
                                <span class="body-2 greyType--text">Out Of Office Manage Groups</span>
                                <v-divider></v-divider>
                            </div>
                            <div class="px-4 pt-1 pb-0" v-if="userGroupsDisplay.length > 0">
                                <div v-for="userGroup in userGroupsDisplay" :key="`UserGroupOutOfOfficeManage${userGroup.id}`">
                                    <v-layout row align-center v-if="outOfOfficeManager" class="hover-item" style="cursor: pointer">
                                        <v-flex xs12 class="px-2 py-2 caption">
                                            {{userGroup.description}}
                                        </v-flex>
                                        <v-flex shrink class="pr-2">
                                            <v-tooltip top>
                                                <v-btn
                                                        small
                                                        icon
                                                        flat
                                                        color="red"
                                                        style="width: 18px; height: 18px"
                                                        class="pa-0 ma-0"
                                                        slot="activator"
                                                        @click="outOfOfficeGroups = outOfOfficeGroups.filter(obj => obj != userGroup.id)"
                                                >
                                                    <v-icon small>delete</v-icon>
                                                </v-btn>
                                                <span>Remove User Group</span>
                                            </v-tooltip>
                                        </v-flex>
                                    </v-layout>
                                    <v-divider></v-divider>
                                </div>
                            </div>
                            <div v-else class="px-4 py-2 font-italic caption greyType--text text-xs-center">
                                *All Users (Please add a User Group to specify)
                            </div>
                            <div class="text-xs-center py-2">
                                <v-btn
                                        small
                                        color="secondary"
                                        outline
                                        class="ma-0"
                                        @click="showAddUserGroupInputDialog = true"
                                >
                                    <v-icon left>add</v-icon>
                                    Add User Group
                                </v-btn>
                            </div>

                            <app-input-dlg
                                    v-if="showAddUserGroupInputDialog"
                                    :show-dialog="showAddUserGroupInputDialog"
                                    title="Add Group To Out Of Office Manage"
                                    :cancel-button="true"
                                    :show-input-box="false"
                                    @dismiss="showAddUserGroupInputDialog = false"
                                    :body-padding="false"
                            >
                                <template slot="body">
                                    <div class="pa-2">
                                        <div :class="headingClass">
                                            Select User Group:
                                        </div>
                                        <div :class="itemClass">
                                            <v-autocomplete
                                                    color="primary"
                                                    label="Add User Group to User"
                                                    :items="allUserGroups.filter(obj => !outOfOfficeGroups.includes(obj.id))"
                                                    item-text="description"
                                                    return-object
                                                    flat
                                                    v-model="selectedOutOfOfficeAddUserGroup"
                                                    hide-details
                                                    dense
                                                    single-line
                                                    no-data-text="No Available User Groups To Add"
                                                    ref="selectAddUserGroupOutOfOffice"
                                            >
                                            </v-autocomplete>
                                        </div>
                                    </div>
                                </template>
                                <template slot="actions">
                                    <v-btn
                                            :disabled="selectedOutOfOfficeAddUserGroup == null"
                                            color="secondary"
                                            class="ma-0"
                                            small
                                            @click="outOfOfficeGroups = outOfOfficeGroups.concat([selectedOutOfOfficeAddUserGroup.id]); showAddUserGroupInputDialog = false"
                                    >
                                        <v-icon left>
                                            add
                                        </v-icon>
                                        Add User Group
                                    </v-btn>
                                </template>
                            </app-input-dlg>
                        </template>

                    </v-flex>
                </v-layout>
            </v-flex>
<!--Security Options-->
            <v-flex v-if="model == 2" xs12 style="overflow-y: auto" class="scroll pa-1">
                <app-security
                        :user-info="userInfo"
                        :selected-security-user="selectedUser"
                />

            </v-flex>

<!--Integrations-->
            <v-flex v-if="model == 3" xs12 style="overflow-y: auto" class="scroll">

<!--Contact Options-->
              
              
              <v-layout row align-center class="pt-2">
                <v-flex shrink class="pl-2 pr-1">
                  <span class="body-2 greyType--text">Messenger Integration</span>
                </v-flex>
              </v-layout>
              
              <v-layout row justify-space-around class="pb-2">
                <v-flex xs3 class="c-d-flex align-center justify-end">
                  <span class="body-1 greyType--text">Linked Contact:</span>
                </v-flex>
                
                <v-flex xs8 class="c-d-flex align-center">
                  <app-browse-contacts v-if="localData && userInfo.userID" v-model="selectedLinkContact" class="fill-height scrollable"  :multi-select="false">
                    <template #activator>
                      <v-card width="400" v-if="selectedUser.fkContact && contactInfo || loadingContactInfo" class="secondary white--text">
                        
                        <template v-if="contactInfo">
                          <div class="c-d-flex c-align-center px-3">
                            <v-icon color="white" left size="28">{{ contactInfo.registered ? '3p' : 'person' }}</v-icon>
                            
                            <div class="c-flex-grow-1 py-2">
                              <div class="title">{{contactInfo.fullName}}</div>
                              <div class="body-1">{{contactInfo.mobile}}</div>
                            </div>
                          </div>
                        </template>
                        <div v-else class="pa-3 c-d-flex c-align-center c-justify-center">
                          <v-progress-circular
                              :size="40"
                              :width="5"
                              color="white"
                              indeterminate
                          ></v-progress-circular>
                        </div>
                        
                        <!--                            <div class="px-2">-->
                        <!--                              <v-btn class="mx-0" color="white" flat small>-->
                        <!--                                <v-icon left>link</v-icon>-->
                        <!--                                Select Contact-->
                        <!--                              </v-btn>-->
                        <!--                            </div>-->
                      
                      
                      
                      
                      </v-card>
                      
                      <v-btn class="mx-0" color="secondary" small>
                        <v-icon left>link</v-icon>
                        Select Contact
                      </v-btn>
                      
                      <v-btn
                          v-if="!!selectedUser.fkContact"
                          class="mx-2"
                          color="error"
                          small
                          flat
                          @click.stop="selectedUser.fkContact = null"
                      >
                        <v-icon left>link_off</v-icon>
                        Unlink Contact
                      </v-btn>
                    </template>
                    
                    <template #actions="{selectedContacts, hideDialog}">
                      <v-card-actions class="c-d-flex c-justify-end">
                        <v-btn
                            @click="() => hideDialog()"
                            flat
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                            :disabled="!Array.isArray(selectedContacts) || !selectedContacts.length"
                            color="secondary"
                            @click="updateLinkedContact(selectedContacts, hideDialog)"
                        >
                          Link Selected
                        </v-btn>
                      </v-card-actions>
                    </template>
                  </app-browse-contacts>
                </v-flex>
              </v-layout>
              
<!--GMail Options-->
              <v-layout row align-center class="pt-2">
                <v-flex shrink class="pl-2">
                  <v-switch
                      v-model="gmailEnabled"
                      color="primary"
                      hide-details
                      class="pa-0 ma-0"
                      :disabled="disabled"
                  ></v-switch>
                </v-flex>
                <v-flex shrink class="pl-2 pr-1">
                  <span class="body-2 greyType--text">G Suite</span>
                </v-flex>
                
              </v-layout>
              
<!--Office 365 Options-->
              <v-layout row align-center class="pt-2">
                <v-flex shrink class="pl-2">
                  <v-switch
                      v-model="office365Enabled"
                      color="primary"
                      hide-details
                      class="pa-0 ma-0"
                      :disabled="disabled"
                  ></v-switch>
                </v-flex>
                <v-flex shrink class="pl-2 pr-1">
                  <span class="body-2 greyType--text">Office 365</span>
                </v-flex>
              </v-layout>
              
              <template v-if="office365Enabled">
                <v-layout row justify-space-around class="pb-2">
                  <v-flex xs3 class="c-d-flex align-center justify-end">
                    <span class="body-1 greyType--text">Office 365 Auto Link:</span>
                  </v-flex>
                  
                  <v-flex xs8 class="c-d-flex align-center">
                    <v-switch
                        v-model="office365Autolink"
                        :label="office365Autolink ? 'Autolink Enabled' : 'Autolink Disabled'"
                        color="primary"
                        hide-details
                        class="pa-0 ma-0"
                        :disabled="disabled"
                    ></v-switch>
                  </v-flex>
                </v-layout>
                
                <v-layout row justify-space-around class="pb-2">
                  <v-flex xs3 class="c-d-flex align-center justify-end">
                    <span class="body-1 greyType--text"></span>
                  </v-flex>
                  
                  <v-flex xs8 class="">
                    <v-btn
                        small
                        class="px-2 py-0 ma-0"
                        color="secondary"
                        @click="showManageSendAs = true"
                    >Manage send on behalf</v-btn>
                    <office-send-as
                        v-if="showManageSendAs"
                        :shown.sync="showManageSendAs"
                        :user-id="selectedUser.id"
                    ></office-send-as>
                  </v-flex>
                </v-layout>
              </template>
              
<!--SMTP Mail Options-->
              <v-layout row align-center class="pt-2">
                <v-flex shrink class="pl-2">
                  <v-switch
                      v-model="smtpAddressEnabled"
                      color="primary"
                      hide-details
                      class="pa-0 ma-0"
                      :disabled="disabled"
                  ></v-switch>
                </v-flex>
                <v-flex shrink class="pl-2 pr-1">
                  <span class="body-2 greyType--text">SMTP Mail</span>
                </v-flex>
              </v-layout>
              
              <template v-if="smtpAddressEnabled">
                <v-layout row justify-space-around class="pb-2">
                  <v-flex xs3 class="c-d-flex align-center justify-end">
                    <span class="body-1 greyType--text">SMTP Receive Address:</span>
                  </v-flex>
                  
                  <v-flex xs8 class="c-d-flex align-center">
                    <v-layout row align-center>
                      <v-flex xs9 class="hide-parent body-2 greyType--text" @click="copyMailLink()" style="cursor: pointer;">
                        <v-layout row align-center>
                          <v-flex shrink style="white-space: nowrap">
                            <span class="underlineHover">{{`${entityData.smtpKey}-${selectedUser.smtpKey}@mail.tasklogix.co.za`}}</span>
                            <input type="text" :value="`${entityData.smtpKey}-${selectedUser.smtpKey}@mail.tasklogix.co.za`" id="mailKey" class="bord" style="width: 3px; height: 3px; opacity: 0"/>
                          </v-flex>
                          <v-flex class="pl-2 hide-child">
                            <v-icon color="greyType--text" small>file_copy</v-icon>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
                
                <v-layout row justify-space-around class="pb-2">
                  <v-flex xs3 class="c-d-flex align-center justify-end">
                    <span class="body-1 greyType--text">Authorized Receive Addresses:</span>
                  </v-flex>
                  
                  <v-flex xs8 class="">
                    <div @click="showAuthAddresses = true" class="hover-item body-1 px-2 py-1" style="border: 1px solid grey; border-radius: 3px" :class="blankString(selectedUser.smtpAuthorizedAddresses) ? 'red--text caption text-xs-center font-weight-bold' : 'greyType--text'">
                      {{blankString(selectedUser.smtpAuthorizedAddresses) ? '*Required Click To Edit' : selectedUser.smtpAuthorizedAddresses.split(';').map(obj => {return obj.trim()}).join(', ')}}
                    </div>
                    
                    <app-input-dlg
                        :show-dialog="showAuthAddresses"
                        title="Authorized Receive Addresses"
                        :cancel-button="true"
                        :show-input-box="false"
                        @dismiss="showAuthAddresses = false"
                        :body-padding="false"
                    >
                      <template slot="body">
                        
                        <div class="pt-2">
                          <div class="pl-2 greyType--text font-weight-bold caption">Add Email Address:</div>
                          <v-layout row align-center class="px-2 pb-1">
                            <v-flex xs12>
                              <v-text-field
                                  single-line
                                  hide-details
                                  class="pa-0 ma-0"
                                  label="Add Email Address:"
                                  v-model="addAuthAddress"
                                  color="primary"
                                  style="width: 100%"
                              />
                            </v-flex>
                            <v-flex class="pl-2">
                              <v-btn
                                  icon
                                  flat
                                  small
                                  color="green"
                                  class="pa-0 ma-0"
                                  @click="authAddressList.push(addAuthAddress); addAuthAddress = null"
                                  :disabled="addAuthAddress == null || email(addAuthAddress) !== true"
                              >
                                <v-icon>add</v-icon>
                              </v-btn>
                            </v-flex>
                          </v-layout>
                          <div class="pb-1 caption font-weight-bold red--text text-xs-center" v-if="addAuthAddress !== null  && email(addAuthAddress) !== true">
                            Invalid Email Address
                          </div>
                          <v-divider></v-divider>
                          <div class="scrollable" style="max-height: 300px">
                            <div v-for="(addr, i) in authAddressList" :key="i" class="hover-item" style="cursor: pointer">
                              <v-layout row align-center class="px-2">
                                <v-flex xs12 class="body-1 py-2">
                                  {{addr}}
                                </v-flex>
                                <v-flex shrink>
                                  <v-btn
                                      icon
                                      flat
                                      small
                                      color="red"
                                      class="pa-0 ma-0"
                                      @click="authAddressList.splice(i,1)"
                                  >
                                    <v-icon>delete</v-icon>
                                  </v-btn>
                                </v-flex>
                              </v-layout>
                              <v-divider></v-divider>
                            </div>
                          </div>
                        </div>
                      
                      </template>
                      <template slot="actions">
                        <v-btn @click="selectedUser.smtpAuthorizedAddresses = authAddressList.map(obj => {return obj.trim()}).join(';'); showAuthAddresses = false; saveChanges(false);" :disabled="authAddressList.length === 0" small color="secondary">Done</v-btn>
                      </template>
                    </app-input-dlg>
                  </v-flex>
                </v-layout>
                
                <v-layout row justify-space-around class="pb-2">
                  <v-flex xs3 class="c-d-flex align-center justify-end">
                  
                  </v-flex>
                  
                  <v-flex xs8 class="c-d-flex align-center">
                    <user-smtp-servers-manage
                        :user-info="userInfo"
                        :user="selectedUser.id"
                        :smtp-server-string="selectedUser.smtpServers"
                        @updateServers="updateSelectedUserSMTPServers($event)"
                    />
                  </v-flex>
                </v-layout>
              </template>
              
              
<!--Sms Options-->
                <v-layout row align-center class="pt-2">
                  <v-flex shrink class="pl-2">
                    <v-switch
                        v-model="smsEnabled"
                        label=""
                        color="primary"
                        hide-details
                        class="pa-0 ma-0"
                    />
                  </v-flex>
                    <v-flex shrink class="pl-2 pr-1">
                        <span class="body-2 greyType--text">Bulk SMS</span>
                    </v-flex>
                </v-layout>
              
                <template v-if="smsEnabled">
                  <v-layout row justify-space-around class="pb-2">
                    <v-flex xs3 class="c-d-flex align-center justify-end">
                      <span class="body-1 greyType--text">Token ID:</span>
                    </v-flex>
                    
                    <v-flex xs8 class="c-d-flex align-center">
                      <v-text-field
                          label="Token ID"
                          v-model="selectedUser.bulkSmsToken"
                          single-line
                          color="primary"
                          hide-details
                          style="width: 100%"
                          class="ma-0 pa-0"
                          :disabled="!smsEnabled"
                      />
                    </v-flex>
                  </v-layout>
                  
                  <v-layout row justify-space-around class="pb-2">
                    <v-flex xs3 class="c-d-flex align-center justify-end">
                      <span class="body-1 greyType--text">Secret:</span>
                    </v-flex>
                    
                    <v-flex xs8 class="c-d-flex align-center">
                      <v-text-field
                          label="Secret"
                          v-model="selectedUser.bulkSmsSecret"
                          single-line
                          color="primary"
                          hide-details
                          style="width: 100%"
                          class="ma-0 pa-0"
                          :disabled="!smsEnabled"
                      />
                    </v-flex>
                  </v-layout>
                  
                  <v-layout row justify-space-around class="pb-2">
                    <v-flex xs3 class="c-d-flex align-center justify-end">
                    
                    </v-flex>
                    
                    <v-flex xs8 class="c-d-flex align-center">
                      <v-btn
                          small
                          color="secondary"
                          class="px-2 py-0"
                          @click="saveChanges(true)"
                          :disabled="blankString(selectedUser.bulkSmsSecret) || blankString(selectedUser.bulkSmsToken)"
                      >
                        Test Bulk Sms Settings
                      </v-btn>
                      
                      <s-m-s-profile
                          :user-info="{entityID: userInfo.entityID, userID: selectedUser.id}"
                          v-model="showSMSProfile"
                      />
                    </v-flex>
                  </v-layout>
                </template>
<!--3CX-->
              <template v-if="typeof this.userInfo.entityInfo['3CXEnabled'] !== 'undefined' && this.userInfo.entityInfo['3CXEnabled'] === '1'">
                <v-layout row align-center class="pt-2">
                  <!--                <v-flex shrink class="pl-2">-->
                  <!--                  <v-switch-->
                  <!--                      v-model="smsEnabled"-->
                  <!--                      label=""-->
                  <!--                      color="primary"-->
                  <!--                      hide-details-->
                  <!--                      class="pa-0 ma-0"-->
                  <!--                  />-->
                  <!--                </v-flex>-->
                  <v-flex shrink class="pl-2 pr-1">
                    <span class="body-2 greyType--text">3CX Integration</span>
                  </v-flex>
                </v-layout>
                
                <!--API Options-->
                <v-layout row wrap class="pt-2">
                  <!--3CX-->
                  <template
                      v-if="typeof this.userInfo.entityInfo['3CXEnabled'] !== 'undefined' && this.userInfo.entityInfo['3CXEnabled'] === '1'">
                    <v-flex xs12 class="pl-2 pr-2 pt-2">
                      <v-layout row justify-space-around class="pb-2">
                        <v-flex xs3 class="c-d-flex align-center justify-end">
                          <span class="body-1 greyType--text">Tenant:&nbsp;</span>
                        </v-flex>
                        
                        <v-flex xs8 class="c-d-flex align-center">
                          <v-autocomplete
                              label="3CX Tenant"
                              single-line
                              color="primary"
                              hide-details
                              style="width: 100%"
                              class="ma-0 pa-0"
                              item-text="description"
                              dense
                              item-value="id"
                              :items="[{id: -1, description: 'Disabled'}, ...config3CX.map((obj, i) => {return {...obj, id: i}})]"
                              v-model="selectedUser['3CXServerIndex']"
                          ></v-autocomplete>
                        </v-flex>
                      </v-layout>
                      <v-layout row justify-space-around>
                        <v-flex xs3 class="c-d-flex align-center justify-end">
                          <div class="body-1 greyType--text text-xs-right" style="white-space: nowrap;">Extensions:&nbsp;<br><span
                              class="caption">(Separated by a comma eg. "100", "201")&nbsp;</span></div>
                        </v-flex>
                        
                        <v-flex xs8 class="c-d-flex align-center">
                          <v-text-field
                              label="3CX Extensions"
                              v-model="user3CXExtensions"
                              single-line
                              color="primary"
                              hide-details
                              style="width: 100%"
                              class="ma-0 pa-0"
                          />
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </template>
                  <!--Talent Index-->
                  <!--                    <template v-if="typeof this.userInfo.entityInfo['talentIndexEnabled'] !== 'undefined' && this.userInfo.entityInfo['talentIndexEnabled'] === '1'">-->
                  <!--                        <v-flex xs12 class="pl-2 pr-1 pt-2"><span class="body-2 greyType&#45;&#45;text">XDS Integration:</span></v-flex>-->
                  <!--                        <v-flex xs12 class="pl-2 pr-2 pt-2">-->
                  <!--                            <v-layout row justify-center>-->
                  <!--                                <v-flex xs6>-->
                  <!--                                    <span class="body-1 greyType&#45;&#45;text">XDS Username:</span>-->
                  <!--                                    <v-text-field-->
                  <!--                                            label="Username (Blank is Disabled)"-->
                  <!--                                            v-model="selectedUser.talentUserName"-->
                  <!--                                            single-line-->
                  <!--                                            color="primary"-->
                  <!--                                            hide-details-->
                  <!--                                            style="width: 100%"-->
                  <!--                                            class="ma-0 pa-0"-->
                  <!--                                    />-->
                  <!--                                </v-flex>-->
                  <!--                            </v-layout>-->
                  <!--                        </v-flex>-->
                  <!--                    </template>-->
                </v-layout>
              </template>
              
              
              <template v-if="userInfo.entityInfo.yeastarSSeriesEnabled">
                <v-layout row align-center class="pt-2">
                  <v-flex shrink class="pl-2 pr-1">
                    <span class="body-2 greyType--text">Yeastar S-Series</span>
                  </v-flex>
                </v-layout>
                
                <!--API Options-->
                <v-layout row wrap class="pt-2">
                  
                  <v-flex xs12 class="pl-2 pr-2 pt-2">
                    <v-layout row justify-space-around>
                      <v-flex xs3 class="c-d-flex align-center justify-end">
                        <div class="body-1 greyType--text text-xs-right" style="white-space: nowrap;">Extensions:&nbsp;<br><span class="caption">(Separated by a comma eg. "100,201")&nbsp;</span></div>
                      </v-flex>
                      
                      <v-flex xs8 class="c-d-flex align-center">
                        <v-text-field
                            label="Yeastar S-Series Extensions"
                            v-model="userYeastarExtension"
                            single-line
                            color="primary"
                            hide-details
                            style="width: 100%"
                            class="ma-0 pa-0"
                        />
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </template>
              
              <template v-if="userInfo.entityInfo.yeastarPSeriesEnabled">
                <v-layout row align-center class="pt-2">
                  <v-flex shrink class="pl-2 pr-1">
                    <span class="body-2 greyType--text">Yeastar P-Series</span>
                  </v-flex>
                </v-layout>
                
                <!--API Options-->
                <v-layout row wrap class="pt-2">
                  
                  <v-flex xs12 class="pl-2 pr-2 pt-2">
                    <v-layout row justify-space-around>
                      <v-flex xs3 class="c-d-flex align-center justify-end">
                        <div class="body-1 greyType--text text-xs-right" style="white-space: nowrap;">Extensions:&nbsp;<br><span class="caption">(Separated by a comma eg. "100,201")&nbsp;</span></div>
                      </v-flex>
                      
                      <v-flex xs8 class="c-d-flex align-center">
                        <v-text-field
                            label="Yeastar S-Series Extensions"
                            v-model="userYeastarExtension"
                            single-line
                            color="primary"
                            hide-details
                            style="width: 100%"
                            class="ma-0 pa-0"
                        />
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </template>
              
              
            </v-flex>
<!--Save Changes-->
            <v-btn
                    color="secondary"
                    dark
                    fab
                    fixed
                    bottom
                    right
                    @click="saveChanges(false)"
                    v-if="model != 2"
            >
                <v-icon>save</v-icon>
            </v-btn>
        </v-layout>
        <app-reset-password-dlg
                :show-dialog="showSetPassword"
                title="Set Password"
                body="Password must include a minimum of 6 characters and have at least one uppercase, lowercase and special character"
                :cancel-button="true"
                :buttons="['Set Password']"
                :call-backs="[ setUserPassword ]"
                @dismiss="showSetPassword = false"
        />
        <app-input-dlg
                :show-dialog="showAddNewUserGroup"
                title="Create User Group"
                body="User Group Description:"
                :cancel-button="true"
                :show-input-box="true"
                :buttons="['Create Group']"
                :call-backs="[ addNewUserGroup ]"
                @dismiss="showAddNewUserGroup = false"
        />
    </v-card>
</template>

<script>

    import {codeBaseMixin} from "../../codeBaseMixin";
    import InputDlg from '../../components/General/InputDlg'
    import ResetPasswordDlg from "../../components/General/ResetPasswordDlg";
    import Security from "./Security/Security"
    import {blankString} from "../../codeFunctions";
    import UserSmtpServersManage from "./userSmtpServersManage";
    import SMSProfile
        from "@/Tasks/TaskView/components/TaskViewDetail/Components/FieldTypeComponents/SMS Components/SMSProfile";
    import UserSignatures from "@/Admin/UserManager/SIgnatures/UserSignatures";
    import DashboardShare from "@/Dashboard/DashboardShare/DashboardShare";
    import CalendarShare from "@/Calendar View/CalendarShare/CalendarShare";
    import TwoFactor from "@/components/Two Factor/TwoFactor";
    import OfficeSendAs from "@/components/General/OfficeSendAs/OfficeSendAs";
    import AppBrowseContacts from "@/contacts/AppBrowseContacts.vue";

    export default {
        mixins: [codeBaseMixin],
        components: {
          AppBrowseContacts,
            OfficeSendAs,
            TwoFactor,
            CalendarShare,
            DashboardShare,
            UserSignatures,
            SMSProfile,
            UserSmtpServersManage,
            appResetPasswordDlg: ResetPasswordDlg,
            appInputDlg: InputDlg,
            appSecurity: Security
        },
        props: {
            userInfo: Object,
            selectedUser: Object,
            entityData: Object,
            signatures: Array,
            localData: Object,
            config3CX: Array
            // allUserGroups: Array
        },
        data() {
            return {
                showSMSProfile: false,

                email: value => {
                    if(value.length > 0) {
                        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                        return pattern.test(value) || 'Invalid Email Address';
                    }
                },

                model: 0,
                showSetPassword: false,
                selectedUserGroup: null,

                userGroups: [],
                userGroupMembers: [],
                allUsers: [],
                allUserGroups: [],
                loadingGroups: false,

                addingUserGroup: false,
                addUserGroup: null,

                addUserGroupUser: null,
                addingUserToUserGroup: false,

                showAddNewUserGroup: false,
                showAddUserGroupInputDialog: false,
                selectedOutOfOfficeAddUserGroup: null,

                updatingUsername: false,
                updateUsername: null,
                showAuthAddresses: false,
                authAddressList: [],
                addAuthAddress: null,
                showTwoFactor: false,
                showManageSendAs: false,
                showSelectContact: false,
              selectedLinkContact: null,
              loadingContactInfo: false,
              contactInfo: null
            }
        },
        mounted() {
            this.selectedUserGroup = null;
            this.loadUserGroups()
        },
        watch: {
          
          "selectedUser.fkContact": {
            immediate: true,
            handler(val) {
              this.updateContactInfo()
            }
            
          },

            showAuthAddresses(val) {
                if (val) {
                    this.authAddressList = this.blankString(this.selectedUser.smtpAuthorizedAddresses) ? [] : this.selectedUser.smtpAuthorizedAddresses.split(';').map(obj => {return obj.trim()})
                }
            },

            selectedUser: {
                immediate: true,
                handler(newVal) {
                    if (newVal != null) {
                        this.selectedUserGroup = null;
                        this.loadUserGroups();
                        this.updatingUsername = false;
                        this.updateUsername = newVal.username;
                    }
                }
            },
            showAddUserGroupInputDialog(shown) {
                if (shown) {
                    this.selectedOutOfOfficeAddUserGroup = null;
                    this.$nextTick(() => {
                        this.$refs.selectAddUserGroupOutOfOffice.focus();
                    })
                }
            }
        },
        methods: {
          
          async updateContactInfo() {
            try {
              this.contactInfo = null;
              
              this.loadingContactInfo = true
              
              if (!this.selectedUser.fkContact)
                return
              
              const contactInfo = await this.$newReq('GET', `contacts/contactInfo/${this.selectedUser.fkContact}`)
              
              this.$set(this, "contactInfo", contactInfo)
              
            } catch (e) {
              console.log(e)
              this.$snack.networkError()
            } finally {
              this.loadingContactInfo = false
            }
          
          },
          
          updateLinkedContact(selectedContacts, hideDialog) {
            if (!selectedContacts.length)
              return hideDialog()
            const contact = selectedContacts.shift()
            this.selectedUser.fkContact = contact.id || null
            hideDialog()
          },
          
            updateSelectedUserSMTPServers(val) {
                this.selectedUser.smtpServers = val
            },

            copyMailLink() {
                /* Get the text field */
                var copyText = document.getElementById("mailKey");

                /* Select the text field */
                copyText.select();

                /* Copy the text inside the text field */
                document.execCommand("copy");

                /* Alert the copied text */
                this.showSnack('Info', 'SMTP Receive Address Copied', 'Close', 'primary')
            },

            addNewUserGroup(newGroup) {
                if (newGroup != null && newGroup != '') {
                    this.fetch({method: 'POST', url: '/localUsers/addUserGroup/' + this.userInfo.entityID, body: JSON.stringify({description: newGroup})})
                        .then((data) => {
                            this.allUserGroups.push(data);
                            this.addUserGroup = data;
                            this.showSnack('Info', 'User Group Added', 'Close', 'primary')
                            this.showAddNewUserGroup = false;
                        })
                        .catch((e) => {
                            // eslint-disable-next-line
                            console.log(e);
                            this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                        });
                } else {
                    this.showSnack('Error', 'A Group Description Is Required', 'Close', 'red')
                }
            },
            addUserToUserGroup() {
                if (this.addUserGroupUser != null && typeof this.addUserGroupUser.userID != 'undefined') {
                    this.addingUserToUserGroup = true;
                    this.fetch({method: 'POST', url: '/folders/addUserToUserGroup/' + this.userInfo.entityID + '/' + this.selectedUserGroup.userGroupID + '/' + this.addUserGroupUser.userID})
                        .then((data) => {
                            this.userGroupMembers.push(data);
                            this.addUserGroupUser = null;
                            this.addingUserToUserGroup = false;
                            this.showSnack('Info', 'User Added To Group', 'Close', 'primary')
                        })
                        .catch((e) => {
                            // eslint-disable-next-line
                            console.log(e);
                            this.addingUserToUserGroup = false;
                            this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                        });
                } else {
                    this.showSnack('Error', 'Please Select a Valid User', 'Close', 'red')
                }
            },
            removeUserFromUserGroup(user) {

                let tUser = user;

                this.userGroupMembers = this.userGroupMembers.filter(obj => obj.userGroup != user.userGroup);

                this.userGroups.forEach((userGroup, i) => {
                    if (userGroup.memberID == user.memberID) {
                        this.selectedUserGroup = null;
                        this.userGroups.splice(i, 1)
                    }
                });

                this.fetch({method: 'DELETE', url: '/localUsers/removeUserGroupFromUser/' + this.userInfo.entityID + '/' + tUser.memberID})
                    .then(() => {
                        this.showSnack('Info', 'User Removed From Group', 'Close', 'primary')
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e);
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
                        this.userGroupMembers.push(tUser)
                    });
            },
            addUserGroupToUser() {
                if (this.addUserGroup != null && typeof this.addUserGroup.id != 'undefined') {
                    this.addingUserGroup = true;
                    this.fetch({method: 'GET', url: '/localUsers/addUserGroupToUser/' + this.userInfo.entityID + '/' + this.selectedUser.id + '/' + this.addUserGroup.id})
                        .then((data) => {
                            this.userGroups.push(data.userGroup);
                            this.userGroupMembers = this.userGroupMembers.concat(data.groupMembers);

                            this.selectedUserGroup = this.userGroups[this.userGroups.length -1];
                            this.addUserGroup = null;
                            this.addingUserGroup = false;
                            this.showSnack('Info', 'User Group Added Successfully', 'Close', 'primary')
                        })
                        .catch((e) => {
                            // eslint-disable-next-line
                            console.log(e);
                            this.addingUserGroup = false;
                            this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                        })

                } else {
                    this.showSnack('Error', 'Please Select a User Group', 'Close', 'red')
                }
            },
            updateUserGroupDescription(group) {
                if (group.description != null && group.description != '') {
                    this.fetch({method: 'PATCH', url: '/localUsers/updateUserGroupName/' + this.userInfo.entityID + '/' + group.userGroupID, body: JSON.stringify({description: group.description})})
                        .then(() => {
                            this.showSnack('Info', 'User Group Updated Successfully', 'Close', 'primary')
                        })
                        .catch((e) => {
                            // eslint-disable-next-line
                            console.log(e);
                            this.addingUserGroup = false;
                            this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                        });
                    group.inEdit = 0;
                } else {
                    this.showSnack('Error', 'A Description is Required', 'Close', 'red')
                }
            },
            loadUserGroups() {
                this.loadingGroups = true;
                this.fetch({method: 'GET', url: '/localUsers/userGroupInfo/' + this.userInfo.entityID + '/' + this.selectedUser.id})
                    .then((data) => {
                        this.userGroups = data.userGroups;
                        this.userGroupMembers = data.userGroupMembers;
                        this.allUsers = data.allUsers;
                        this.allUserGroups = data.allUserGroups;
                        this.loadingGroups = false;
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e)
                    })
            },
            setUserPassword(password) {
                if (password != null && password != '') {

                    this.showSetPassword = false;

                    this.fetch({method: 'PATCH', url: '/localUsers/setPassword/' + this.userInfo.entityID + '/' + this.selectedUser.id, body: JSON.stringify({password: password, username: this.selectedUser.username})})
                        .then(() => {
                            this.showSnack('Info', 'Password Has Been Changed', 'Close', 'primary')
                        })
                        .catch((e) => {
                            // eslint-disable-next-line
                            console.log(e)
                            this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                        })

                    // showSetPassword
                } else {
                    this.showSnack('Error', 'Password Can Not Be Blank', 'Close', 'red')
                }
            },
            saveChangesDisabled() {
                if (this.selectedUser.username != null && this.selectedUser.username != '') {
                    this.fetch({method: 'PATCH', url: '/localUsers/saveChanges/disabled/' + this.userInfo.entityID, body: JSON.stringify({val: this.selectedUser.disabled, username: this.selectedUser.username})})
                        .then((data) => {
                            this.showSnack('Info', 'Changes Saved', 'Close', 'primary')
                        })
                        .catch((e) => {
                            // eslint-disable-next-line
                            console.log(e);
                            this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                        })
                } else {
                    this.showSnack('Error', 'A Username Is Required', 'Close', 'red')
                }
            },
            saveUsernameChanges() {
                if (this.updateUsername != null && this.updateUsername != '') {
                    if (this.updateUsername.toLowerCase() !== this.selectedUser.username.toLowerCase()) {
                        this.updatingUsername = true;
                        this.fetch({
                            method: 'PATCH',
                            url: `/localUsers/updateUsername/${this.userInfo.entityID}/${this.selectedUser.id}`,
                            body: JSON.stringify({
                                new: this.updateUsername,
                                old: this.selectedUser.username
                            })
                        })
                            .then((data) => {
                                if (typeof data.duplicateUser == 'undefined') {
                                    this.selectedUser.username = this.updateUsername;
                                    this.showSnack('Info', 'Username updated', 'Close', 'primary')
                                } else {
                                    this.showSnack('Error', 'Username already exists', 'Close', 'red')
                                }
                                this.updatingUsername = false;
                            })
                            .catch((e) => {
                                // eslint-disable-next-line
                                console.log(e);
                                this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')

                                this.updatingUsername = false
                            })
                    } else {
                        this.showSnack('Warning', 'Username is the same', 'Close', 'amber')
                    }

                } else {
                    this.showSnack('Error', 'A Username Is Required', 'Close', 'red')
                }
            },
            saveChanges(testSMS = false) {
                if (this.selectedUser.username != null && this.selectedUser.username != '') {
                    this.fetch({method: 'PATCH', url: '/localUsers/saveChanges/' + this.userInfo.entityID, body: JSON.stringify(this.selectedUser)})
                        .then((data) => {
                            if (data[0][0].state == 2) {
                                this.showSnack('Error', data[0][0].message, 'Close', 'red')
                            } else {

                                if (testSMS) {
                                    this.showSMSProfile = true
                                } else {
                                    this.showSnack('Info', 'Changes Saved', 'Close', 'primary')
                                }
                            }


                        })
                        .catch((e) => {
                            // eslint-disable-next-line
                            console.log(e);
                            this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')

                            // this.showAddUser
                        })
                } else {
                    this.showSnack('Error', 'A Username Is Required', 'Close', 'red')
                }
            },
        },
        computed: {


            smtpAddressEnabled: {
                get: function () {return this.intToBool(this.selectedUser.smtpAddressEnabled)},
                set: function (newValue) {this.selectedUser.smtpAddressEnabled = this.boolToInt(newValue)}
            },
            sameUsername() {
                return this.selectedUser != null && this.updateUsername != null && this.updateUsername != '' && this.updateUsername.toLowerCase() !== this.selectedUser.username.toLowerCase();
            },
            userGroupsDisplay() {
                return this.allUserGroups.filter(obj => this.outOfOfficeGroups.includes(obj.id))
                    .sort((a,b) => a['description'] < b['description'] ? -1 : 1);
            },
            availableAddUserGroupsUser() {

                let tUsers = [];

                this.allUsers.forEach((allUser) => {
                    let bAdd = true;
                    this.userGroupMembers.forEach((groupUser) => {
                        if (groupUser.userGroup == this.selectedUserGroup.userGroupID && allUser.userID == groupUser.userID) {
                            bAdd = false
                        }
                    });
                    if (bAdd) {tUsers.push(allUser)}
                });

                tUsers.sort((a,b) => a['description'] < b['description'] ? -1 : 1);

                return tUsers
            },
            availableAddUserGroups() {

                let tAvail = [];
                this.allUserGroups.forEach(addGroup => {
                    let bAdd = true;
                    this.userGroups.forEach(userGroup => {if (addGroup.id == userGroup.userGroupID) {bAdd = false}})

                    if (bAdd) {tAvail.push(addGroup)}
                });
                return tAvail.sort((a,b) => a['description'] < b['description'] ? -1 : 1);
            },
            sortedUsers() {
                let tUsers = [];

                if (this.userGroupMembers != null && this.userGroupMembers.length > 0) {
                    tUsers = this.userGroupMembers
                }

                tUsers.sort((a,b) => a['description'] < b['description'] ? -1 : 1);

                return tUsers
            },
            disabled: {
                get: function () {return this.intToBool(this.selectedUser.disabled)},
                set: function (newValue) {this.selectedUser.disabled = this.boolToInt(newValue)}
            },
            gmailAutolink: {
                get: function () {return this.intToBool(this.selectedUser.gmailAutolink)},
                set: function (newValue) {this.selectedUser.gmailAutolink = this.boolToInt(newValue)}
            },
            office365Autolink: {
                get: function () {return this.intToBool(this.selectedUser.office365Autolink)},
                set: function (newValue) {this.selectedUser.office365Autolink = this.boolToInt(newValue)}
            },
            userManager: {
                get: function () {return this.intToBool(this.selectedUser.userManager)},
                set: function (newValue) {this.selectedUser.userManager = this.boolToInt(newValue)}
            },
            showAllFolders: {
                get: function () {return this.intToBool(this.selectedUser.showAllFolders)},
                set: function (newValue) {this.selectedUser.showAllFolders = this.boolToInt(newValue)}
            },
            workflowManager: {
                get: function () {return this.intToBool(this.selectedUser.workflowManager)},
                set: function (newValue) {
                    if (newValue) {
                        this.selectListManager = true
                    }
                    this.selectedUser.workflowManager = this.boolToInt(newValue)
                }
            },
            fieldsetManager: {
                get: function () {return this.intToBool(this.selectedUser.fieldsetManager)},
                set: function (newValue) {this.selectedUser.fieldsetManager = this.boolToInt(newValue)}
            },
            fieldsetClone: {
                get: function () {return this.intToBool(this.selectedUser.fieldsetClone)},
                set: function (newValue) {this.selectedUser.fieldsetClone = this.boolToInt(newValue)}
            },
            signatureManager: {
                get: function () {return this.intToBool(this.selectedUser.signatureManager)},
                set: function (newValue) {this.selectedUser.signatureManager = this.boolToInt(newValue)}
            },
            selectListManager: {
                get: function () {
                    if (this.workflowManager) {
                        return true
                    } else {
                        return this.intToBool(this.selectedUser.selectListManager)
                    }
                },
                set: function (newValue) {this.selectedUser.selectListManager = this.boolToInt(newValue)}
            },
            groupMailManager: {
                get: function () {return this.intToBool(this.selectedUser.groupMailManager)},
                set: function (newValue) {this.selectedUser.groupMailManager = this.boolToInt(newValue)}
            },
            user3CXExtensions: {
                get() {
                    return this.selectedUser['3CXExtention']
                },
                set(val) {

                    let tTest = `[${val}]`;

                    try {
                        tTest = JSON.parse(tTest);
                        this.selectedUser['3CXExtention'] = val
                    } catch (e) {
                        // eslint-disable-next-line
                        console.log(e);
                        if (!(val.length > 0 && val.split('').pop() === ',')) {
                            this.showSnack('Error', 'Invalid Value Specified', 'Close', 'red')
                        }
                    }
                }
            },
          
          userYeastarExtension: {
            get() {
              return this.selectedUser['yeastarExtension'] || ''
            },
            set(val) {
              let tTest = `[${val}]`;
              try {
                tTest = JSON.parse(tTest);
                this.$set(this.selectedUser, "yeastarExtension", val)
              } catch (e) {
                // eslint-disable-next-line
                console.log(e);
                if (!(val.length > 0 && val.split('').pop() === ',')) {
                  this.showSnack('Error', 'Invalid Value Specified', 'Close', 'red')
                }
              }
            }
          },
            folderManager: {
                get: function () {return this.intToBool(this.selectedUser.folderManager)},
                set: function (newValue) {this.selectedUser.folderManager = this.boolToInt(newValue)}
            },
            smsEnabled: {
                get: function () {return this.intToBool(this.selectedUser.bulkSmsEnabled)},
                set: function (newValue) {this.selectedUser.bulkSmsEnabled = this.boolToInt(newValue)}
            },
            importManager: {
                get: function () {return this.intToBool(this.selectedUser.importManager)},
                set: function (newValue) {this.selectedUser.importManager = this.boolToInt(newValue)}
            },
            outOfOfficeManager: {
                get: function () {return this.intToBool(this.selectedUser.outOfOfficeManager)},
                set: function (newValue) {
                    this.selectedUser.outOfOfficeManager = this.boolToInt(newValue)
                }
            },
            outOfOfficeGroups: {
                get: function () {
                    try {
                        return JSON.parse(this.selectedUser.outOfOfficeGroups) != null ? JSON.parse(this.selectedUser.outOfOfficeGroups) : [];
                    } catch (e) {
                        // eslint-disable-next-line
                        console.log(e)
                        return []
                    }
                },
                set: function (newValue) {
                    this.selectedUser.outOfOfficeGroups = JSON.stringify(newValue);

                    this.selectedOutOfOfficeAddUserGroup = null;
                }
            },
            allowOutOfOfficeUpdate: {
                get: function () {return this.intToBool(this.selectedUser.allowOutOfOfficeUpdate)},
                set: function (newValue) {this.selectedUser.allowOutOfOfficeUpdate = this.boolToInt(newValue)}
            },
            smtpMailboxManager: {
                get: function () {return this.intToBool(this.selectedUser.smtpMailboxManager)},
                set: function (newValue) {this.selectedUser.smtpMailboxManager = this.boolToInt(newValue)}
            },
            calGroupManager: {
                get: function () {return this.intToBool(this.selectedUser.calGroupManager)},
                set: function (newValue) {this.selectedUser.calGroupManager = this.boolToInt(newValue)}
            },
          contactsManager: {
            get: function () {return this.intToBool(this.selectedUser.contactsManager)},
            set: function (newValue) {this.selectedUser.contactsManager = this.boolToInt(newValue)}
          },
          contactsDisabled: {
            get: function () {return this.intToBool(this.selectedUser.contactsDisabled)},
            set: function (newValue) {this.selectedUser.contactsDisabled = this.boolToInt(newValue)}
          },
            reportsManager: {
                get: function () {
                    return this.intToBool(this.selectedUser.reportsManager)
                },
                set: function (newValue) {
                    this.selectedUser.reportsManager = this.boolToInt(newValue)
                    this.selectedUser.reportsViewer = this.boolToInt(newValue)
                }
            },
            reportsViewer: {
                get: function () {return this.intToBool(this.selectedUser.reportsViewer)},
                set: function (newValue) {this.selectedUser.reportsViewer = this.boolToInt(newValue)}
            },
            gmailEnabled: {
                get: function () {return this.intToBool(this.selectedUser.gmailEnabled)},
                set: function (newValue) {this.selectedUser.gmailEnabled = this.boolToInt(newValue)}
            },
            office365Enabled: {
                get: function () {return this.intToBool(this.selectedUser.office365Enabled)},
                set: function (newValue) {this.selectedUser.office365Enabled = this.boolToInt(newValue)}
            },
            createTask: {
                get: function () {return this.intToBool(this.selectedUser.createTask)},
                set: function (newValue) {this.selectedUser.createTask = this.boolToInt(newValue)}
            },
            createFolder: {
                get: function () {return this.intToBool(this.selectedUser.createFolder)},
                set: function (newValue) {this.selectedUser.createFolder = this.boolToInt(newValue)}
            },
            createFieldset: {
                get: function () {return this.intToBool(this.selectedUser.createFieldset)},
                set: function (newValue) {this.selectedUser.createFieldset = this.boolToInt(newValue)}
            },
            allowTransfer: {
                get: function () {return this.intToBool(this.selectedUser.allowTransfer)},
                set: function (newValue) {this.selectedUser.allowTransfer = this.boolToInt(newValue)}
            },
            docRepoManager: {
                get: function () {return this.intToBool(this.selectedUser.docRepoManager)},
                set: function (newValue) {this.selectedUser.docRepoManager = this.boolToInt(newValue)}
            },
            productivityReports: {
                get: function () {return this.intToBool(this.selectedUser.productivityReports)},
                set: function (newValue) {this.selectedUser.productivityReports = this.boolToInt(newValue)}
            },
            taskHeaderReports: {
                get: function () {return this.intToBool(this.selectedUser.taskHeaderReports)},
                set: function (newValue) {this.selectedUser.taskHeaderReports = this.boolToInt(newValue)}
            },
        }
    }
</script>

<style scoped>

    .underlineHover:hover {
        text-decoration:underline;
    }

</style>
