<template>
    <div>
        <v-btn
                color="secondary"
                outline
                small
                block
                class="pb-0 mb-0"
                @click="showAddField = true"
                :disabled="addingField"
                :loading="addingField"
        >
            <v-icon left>add</v-icon>
            Add Field
        </v-btn>
        <app-input-dlg
                :show-dialog="showAddField"
                :title="`Add Field to: ${step.description}`"
                :cancel-button="true"
                :show-input-box="false"
                @dismiss="showAddField = false"
                :body-padding="false"
                :width="500"
        >
            <template slot="body" v-if="showAddField">
                <div class="pa-1">
<!--Field Description-->
                    <div :class="topicClass">
                        <div :class="headingClass">
                            Field Description:
                        </div>
                        <div :class="itemClass">
                            <v-text-field
                                    label="Field Description"
                                    single-line
                                    color="primary"
                                    append-outer-icon="save"
                                    hide-details
                                    style="width: 100%"
                                    class="pa-0 ma-0"
                                    ref="fieldDescription"
                                    v-model="field.description"
                            ></v-text-field>
                        </div>
                    </div>
<!--Visibility & Required-->
                    <v-layout row>
                        <v-flex xs6>
<!--Visibility-->
                            <div :class="topicClass" style="overflow-y: hidden">
                                <div :class="headingClass">
                                    Visibility:
                                </div>
                                <div :class="itemClass">
                                    <v-switch
                                            v-model="visible"
                                            :label="visible ? 'Shown' : 'Hidden'"
                                            hide-details
                                            color="secondary"
                                            class="pa-0 ma-0"
                                    ></v-switch>
                                </div>
                            </div>
                        </v-flex>
                        <v-flex xs6 v-if="![12, 20].includes(field.fieldType)">
<!--Required-->
                            <div :class="topicClass" style="overflow-y: hidden">
                                <div :class="headingClass">
                                    Required:
                                </div>
                                <div :class="itemClass">
                                    <v-switch
                                            v-model="required"
                                            :label="required ? 'Required' : 'Not Required'"
                                            hide-details
                                            color="secondary"
                                            class="pa-0 ma-0"
                                    ></v-switch>
                                </div>
                            </div>
                        </v-flex>
                    </v-layout>
<!--Field Type-->
                    <div :class="topicClass">
                        <div :class="headingClass">
                            Field Type:
                        </div>
                        <div :class="itemClass">
                            <v-autocomplete
                                    dense
                                    v-model="field.fieldType"
                                    :items="fieldTypeList"
                                    label="Select Field Type"
                                    hide-details
                                    single-line
                                    color="primary"
                                    item-text="description"
                                    item-value="id"
                                    class="pa-0 ma-0"
                                    clearable
                                    @input="resetLookups"
                            >

                            </v-autocomplete>
                        </div>
                    </div>
<!--Field Links-->
                    <div :class="topicClass" v-if="[1,2,3,4,5,6,7,13].includes(field.fieldType)">
                        <div :class="headingClass">
                            Dataset Link:
                        </div>
                        <div :class="itemClass">
                            <app-field-set-field-selector
                                    v-model="field.linkLookup"
                                    :fields="fields"
                                    :field-sets="fieldSets"
                                    :field-type-filter="[field.fieldType != 7 ? field.fieldType : 6]"
                            />
                        </div>
                    </div>
<!--Field Type 1-->
                    <div :class="topicClass" v-if="[1].includes(field.fieldType)">
                        <div :class="headingClass">
                            Update Task Title:
                        </div>
                        <div :class="itemClass">
                            <v-switch
                                    v-model="booleanLookup"
                                    label="Update Task Title"
                                    hide-details
                                    color="primary"
                                    class="pa-0 ma-0"
                            ></v-switch>
                        </div>
                    </div>
<!--Field Type 2-->
                    <div :class="topicClass" v-if="[2].includes(field.fieldType)">
                        <field-type2-configure
                                :user-info="userInfo"
                                :select-lists="selectLists"
                                v-model="fullLookup"
                        />
                    </div>
<!--Field Type 5-->
                    <div :class="topicClass" v-if="[5].includes(field.fieldType)">
                        <div :class="headingClass">
                            List Options:
                        </div>
                        <div :class="itemClass">
                            <app-field-type6-configure
                                :user-info="userInfo"
                                :select-lists="selectLists"
                                v-model="fullLookup"
                            />
                        </div>
                    </div>
<!--Field Type 7-->
                    <div :class="topicClass" v-if="[7].includes(field.fieldType)">
                        <div :class="headingClass">
                            Restricted User Groups:
                        </div>
                        <div :class="itemClass">
                            <app-workflow-manager-group-selector
                                    :user-groups="userGroups"
                                    btn-label="No Restricted User Groups"
                                    ac-label="No Restricted User Groups"
                                    v-model="field.lookup"
                            />
                        </div>
                    </div>
<!--Field Type 8-->
                    <div :class="topicClass" v-if="[8].includes(field.fieldType)">
                        <div :class="headingClass">
                            Mail Template Configuration:
                        </div>
                        <div :class="itemClass">
                            <app-field-type8-configure
                                    :user-info="userInfo"
                                    v-model="field.lookup"
                                    :fields="fields"
                                    :steps="steps"
                                    :fieldSets="fieldSets"
                                    :signatures="signatures"
                                    :show-template-editor-fn="showTemplateEditorFn"
                                    :field="field"
                            />
                        </div>
                    </div>
<!--Field Type 9-->
                    <template v-if="[9].includes(field.fieldType)">

                        <v-layout row>
                            <v-flex xs6>
                                <!--Hidden If Populated-->
                                <div :class="topicClass" style="overflow-y: hidden">
                                    <div :class="headingClass">
                                        Create Only:
                                    </div>
                                    <div :class="itemClass">
                                        <v-switch
                                                v-model="field.config.createOnly"
                                                hide-details
                                                color="secondary"
                                                class="pa-0 ma-0"
                                                @change="$emit('input'); field.config.selectOnly = !$event"
                                        ></v-switch>
                                    </div>
                                </div>
                            </v-flex>
                            <v-flex xs6>
                                <!--Select Only-->
                                <div :class="topicClass" style="overflow-y: hidden">
                                    <div :class="headingClass">
                                        Select Only:
                                    </div>

                                    <div :class="itemClass">
                                        <!--:label="required ? 'Required' : 'Not Required'"-->
                                        <v-switch
                                                v-model="field.config.selectOnly"
                                                hide-details
                                                color="secondary"
                                                class="pa-0 ma-0"
                                                @change="$emit('input'); field.config.createOnly = !$event"
                                        ></v-switch>
                                    </div>
                                </div>
                            </v-flex>
                        </v-layout>

                        <v-layout row>
                            <v-flex xs6>
                                <!--Hidden If Populated-->
                                <div :class="topicClass" style="overflow-y: hidden">
                                    <div :class="headingClass">
                                        Hidden If Populated:
                                    </div>
                                    <div :class="itemClass">
                                        <v-switch
                                                v-model="field.config.hiddenIfPopulated"
                                                hide-details
                                                color="secondary"
                                                class="pa-0 ma-0"
                                                @change="$emit('input')"
                                        ></v-switch>
                                    </div>
                                </div>
                            </v-flex>
                        </v-layout>

<!--Select Folder-->
                        <div :class="topicClass" v-if="typeof field.config.folder != 'undefined'">
                            <div :class="headingClass">
                                Dataset Folder:
                            </div>
                            <div :class="itemClass">
                                <app-workflow-manager-group-selector
                                        v-model="field.config.folder"
                                        :user-groups="fields.filter(fField => fField.fieldType === 21)"
                                        ac-label="Task Folder (Select to change)"
                                        btn-label="Task Folder (Select to change)"
                                        lbl-label="Folder Select Field"
                                        @input="$emit('input')"
                                        :multiple="false"
                                />
                            </div>
                        </div>


<!--Select Dataset-->
                        <div :class="topicClass">
                            <div :class="headingClass">
                                Dataset Field:
                            </div>
                            <div :class="itemClass">
                                <app-field-set-selector
                                        v-model="field.config.dataset"
                                        :user-info="userInfo"
                                        :field="field"
                                        :fields="fields"
                                        :field-sets="fieldSets"
                                        :field-type-filter="[field.fieldType]"
                                        :show-prompt="false"
                                />
                            </div>
                        </div>
<!--Hide Dataset-->
<!--                        <div :class="topicClass">-->
<!--                            <div :class="headingClass">-->
<!--                                Dataset Select Behaviour:-->
<!--                            </div>-->
<!--                            <div :class="itemClass">-->

<!--                                <v-autocomplete-->
<!--                                        dense-->
<!--                                        v-model="integerLookupType"-->
<!--                                        :items="[{id: 0, description: 'Regular'},{id: 1, description: 'Hidden if populated'},{id: 2, description: 'Select only (no add dataset button)'},{id: 3, description: 'Hidden if populated and select only'}]"-->
<!--                                        label="Dataset Behaviour"-->
<!--                                        hide-details-->
<!--                                        single-line-->
<!--                                        color="primary"-->
<!--                                        item-text="description"-->
<!--                                        item-value="id"-->
<!--                                        class="pa-0 ma-0"-->
<!--                                ></v-autocomplete>-->

<!--&lt;!&ndash;                                <v-switch&ndash;&gt;-->
<!--&lt;!&ndash;                                        v-model="booleanLookupType"&ndash;&gt;-->
<!--&lt;!&ndash;                                        label="Hidden if Populated"&ndash;&gt;-->
<!--&lt;!&ndash;                                        hide-details&ndash;&gt;-->
<!--&lt;!&ndash;                                        color="primary"&ndash;&gt;-->
<!--&lt;!&ndash;                                        class="pa-0 ma-0"&ndash;&gt;-->
<!--&lt;!&ndash;                                ></v-switch>&ndash;&gt;-->
<!--                            </div>-->
<!--                        </div>-->
                    </template>
<!--Field Type 10-->
                    <div :class="topicClass" v-if="[10].includes(field.fieldType)">
                        <div :class="headingClass">
                            Split Task Configuration:
                        </div>
                        <div :class="itemClass">
                            <app-field-type10-configure
                                    :user-info="userInfo"
                                    v-model="field.lookup"
                                    :fields="fields"
                                    :split-task-workflows="splitTaskWorkflows"
                                    :steps="steps"
                                    :folder-groups="folderGroups"
                                    :local-data="localData"
                                    :show-sync="showSync"
                                    :get-task-header="getTaskHeader"
                            />
                        </div>
                    </div>
<!--Field Type 11-->
                    <div :class="topicClass" v-if="[11].includes(field.fieldType)">
                        <div :class="headingClass">
                            Checkbox List:
                        </div>
                        <div :class="itemClass">
                            <app-field-type11-configure
                                    v-model="field.lookup"
                            />
                        </div>
                    </div>
<!--Field Type 12-->
                    <div :class="topicClass" v-if="[12].includes(field.fieldType)">
                        <div :class="headingClass">
                            Force Close Field:
                        </div>
                        <div :class="itemClass">
                            <v-switch
                                    v-model="forceCloseLookup"
                                    :label="forceCloseLookup ? 'Force Close Enabled' : 'Force Close'"
                                    hide-details
                                    color="secondary"
                                    class="pa-0 ma-0"
                            ></v-switch>
                        </div>
                    </div>
<!--Field Type 17-->
                    <div :class="topicClass" v-if="[17].includes(field.fieldType)">
                        <div :class="headingClass">
                            Configure SMS Field:
                        </div>
                        <div :class="itemClass">
                            <app-field-type17-configure
                                    :user-info="userInfo"
                                    v-model="field.lookup"
                                    :local-data="localData"
                                    :fields="fields"
                                    :steps="steps"
                                    :fieldSets="fieldSets"
                            />
                        </div>
                    </div>
<!--Field Type 19-->
                    <div :class="topicClass" v-if="[19].includes(field.fieldType)">
                        <div :class="headingClass">
                            Configure Document Template:
                        </div>
                        <div :class="itemClass">
                            <document-template
                                    v-model="field.lookup"
                                    :user-info="userInfo"
                                    :fields="fields"
                                    :datasets="fieldSets"
                                    :steps="steps"
                                    :select-lists="selectLists"
                            />
                        </div>
                    </div>
<!--Field Type 20-->
                    <div :class="topicClass" v-if="[20].includes(field.fieldType)">
                        <div :class="headingClass">
                            Configure Information Field:
                        </div>
                        <div :class="itemClass">
                            <information-field-editor
                                    :field="field"
                            />
                        </div>
                    </div>
<!--Field Type 21-->
                    <field-type21-configure
                            v-if="[21].includes(field.fieldType)"
                            :field="field"
                            :folder-groups="folderGroups"
                    />
<!--Field Type 22-->
<!--                    <div :class="topicClass" v-if="[22].includes(field.fieldType)">-->
<!--                        <div :class="headingClass">-->
<!--                            Configure Eval Field:-->
<!--                        </div>-->
<!--                        <div :class="itemClass">-->
<!--                            <app-field-type22-configure-->
<!--                                    :user-info="userInfo"-->
<!--                                    v-model="field.lookup"-->
<!--                                    :local-data="localData"-->
<!--                                    :fields="fields"-->
<!--                                    :steps="steps"-->
<!--                                    :fieldSets="fieldSets"-->
<!--                            />-->
<!--                        </div>-->
<!--                    </div>-->
<!--Field Shown To-->
                    <div :class="topicClass">
                        <div :class="headingClass">
                            Field Shown To:
                        </div>
                        <div :class="itemClass">
                            <app-workflow-manager-group-selector
                                    :user-groups="userGroups"
                                    btn-label="Shown to All"
                                    ac-label="Shown to All"
                                    v-model="securityShownTo"
                            />
                        </div>

                    </div>
<!--Field Editable By-->
                    <div :class="topicClass">
                        <div :class="headingClass">
                            Field Editable By:
                        </div>
                        <div :class="itemClass">
                            <app-workflow-manager-group-selector
                                    :user-groups="userGroups"
                                    btn-label="Editable by All"
                                    ac-label="Editable by All"
                                    v-model="securityEditableBy"
                            />
                        </div>
                    </div>
                    <v-divider></v-divider>
                    <div class="text-xs-right">
                        <v-btn
                                small
                                color="secondary"
                                :disabled="allowAddField == null"
                                @click="doneRun"
                        >
                            Save
                        </v-btn>
                    </div>
                </div>
            </template>
        </app-input-dlg>
    </div>


</template>

<script>
    import {codeBaseMixin} from "../../../../../../codeBaseMixin";
    import InputDlg from "../../../../../../components/General/InputDlg";
    import FieldSetFieldSelector from "./FieldSetFieldSelector";
    import FieldSetSelector from "./FieldSetSelector";
    import FieldType6Configure from "./FieldTypeConfigureFields/FieldType6Configure";
    import FieldType8Configure from "./FieldTypeConfigureFields/FieldType8Configure";
    import FieldType11Configure from "./FieldTypeConfigureFields/FieldType11Configure";
    import FieldType10Configure from "./FieldTypeConfigureFields/FieldType10Configure";
    import WorkflowManagerGroupSelector from './WorkflowManagerGroupSelector'
    import FieldType17Configure from "./FieldTypeConfigureFields/FieldType17Components/FieldType17Configure";
    import FieldType22Configure from "./FieldTypeConfigureFields/FieldType22Components/FieldType22Configure";
    import DocumentTemplate from "../../DocumentTemplate/DocumentTemplate";
    import FieldType2Configure from "./FieldTypeConfigureFields/FieldType2Configure";
    import InformationFieldEditor
        from "@/Admin/WorkflowEditor/Components/WorkflowManager/InformationFieldEditor/InformationFieldEditor";
    import FieldType21Configure
        from "@/Admin/WorkflowEditor/Components/WorkflowManager/Workflow/Components/FieldTypeConfigureFields/FieldType21Configure";


    export default {
        mixins: [codeBaseMixin],
        components: {
            FieldType21Configure,
            InformationFieldEditor,
            FieldType2Configure,
            DocumentTemplate,
            appFieldType17Configure: FieldType17Configure,
            appFieldSetFieldSelector: FieldSetFieldSelector,
            appFieldSetSelector: FieldSetSelector,
            appInputDlg: InputDlg,
            appFieldType6Configure: FieldType6Configure,
            appFieldType8Configure: FieldType8Configure,
            appFieldType10Configure: FieldType10Configure,
            appFieldType11Configure: FieldType11Configure,
            appWorkflowManagerGroupSelector: WorkflowManagerGroupSelector,
            appFieldType22Configure: FieldType22Configure
        },
        props: {
            userInfo: Object,
            step: Object,
            steps: Array,
            fields: Array,
            rules: Array,
            fieldSets: Array,
            userGroups: Array,
            selectLists: Array,
            selectedField: Object,
            splitTaskWorkflows: Array,
            folderGroups: Array,
            signatures: Array,
            showTemplateEditorFn: Function,
            localData: Object,
            showSync: Boolean,
            getTaskHeader: Function,
        },
        data() {
            return {
                showAddField: false,
                headingClass: 'caption greyType--text font-weight-bold',
                topicClass: 'pa-1',
                itemClass: 'pl-2',
                field: {
                    fk_step: null,
                    description: null,
                    fieldType: 0,
                    required: 0,
                    lookup_type: null,
                    lookup: null,
                    visible: 1,
                    securityLookup: null,
                    linkLookup: null,
                    config: null,
                },
                addingField: false
            }
        },
        watch: {

            "field.fieldType"(val) {
                if (val === 9) {
                    this.$set(this.field, "config", {folder: null, dataset: null, hiddenIfPopulated: false, selectOnly: false, createOnly: false})
                }
                if ([12,20].includes(val)) {
                    this.field.required = 0
                }
            },

            showAddField(val) {
                if (val == true) {
                    // reset Values Here
                    this.field = {
                        fk_step: this.step.id,
                        description: null,
                        fieldType: 0,
                        required: 0,
                        lookup_type: null,
                        lookup: null,
                        visible: 1,
                        securityLookup: null,
                        linkLookup: null,
                        config: null,
                    };

                    setTimeout(() => {this.$refs.fieldDescription.focus();}, 200)
                }
            }
        },
        methods: {
            resetLookups() {
                this.field.lookup = null;
                this.field.lookup_type = null;
            },

            doneRun() {
                this.addingField = true;
                this.showAddField = false;

                let newVal = null;
                if (this.securityEditableBy.length > 0) {
                    newVal = {};
                    newVal.editableBy = this.securityEditableBy;
                }
                if (this.securityShownTo.length > 0) {
                    if (newVal == null) {
                        newVal = {}
                    }
                    newVal.shownTo = this.securityShownTo;
                }


                let lookup;

                if (this.field.fieldType === 22) {
                  lookup = typeof this.field.lookup !== "string" ? JSON.stringify(this.field.lookup || {}) : this.field.lookup
                  this.field.lookup = lookup
                } else if (typeof this.field.lookup == 'object' && this.field.lookup != null) {
                  lookup = JSON.stringify(this.field.lookup)
                } else
                  lookup = this.field.lookup


                this.fetch({method: 'POST', url:`/workflowManager/field/AddLinked/${this.userInfo.entityID}`, body: JSON.stringify({
                        fk_step: this.field.fk_step,
                        fieldType: this.field.fieldType,
                        description: this.field.description,
                        visible: this.field.visible,
                        required: this.field.required,
                        securityLookup: JSON.stringify(newVal),
                        linkLookup: this.field.linkLookup,
                        lookup,
                        lookup_type: this.field.lookup_type,
                        config: this.field.config
                    })})
                    .then((data) => {
                        this.addingField = false;
                        this.fields.push(data)
                        this.showSnack('Info', 'Field Added', 'Close', 'primary');
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e);
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
                        this.addingField = false;
                    })
            }

        },
        computed: {

            booleanLookup: {
                get() {
                    try {
                        return this.intToBool(parseInt(this.field.lookup))
                    } catch (e) {
                        return false
                    }
                },
                set(val) {
                    this.field.lookup = `${this.boolToInt(val)}`
                }
            },

            integerLookupType: {
                get() {
                    try {
                        return parseInt(this.field.lookup_type)
                    } catch (e) {
                        return false
                    }
                },
                set(val) {
                    this.field.lookup_type = parseInt(val)
                }
            },

            booleanLookupType: {
                get() {
                    try {
                        return this.intToBool(parseInt(this.field.lookup_type))
                    } catch (e) {
                        return false
                    }
                },
                set(val) {
                    this.field.lookup_type = `${this.boolToInt(val)}`
                }
            },

            fieldTypeList() {
                let tResult = [
                    {id: 1, description: 'Word Field'},
                    {id: 2, description: 'Date Field'},
                    {id: 3, description: 'Number Field'},
                    {id: 4, description: 'Currency Field'},
                    {id: 5, description: 'List Field'},
                    {id: 6, description: 'Mail/Document Field'},
                    {id: 7, description: "Restricted Mail/Document"},
                    {id: 8, description: "Mail Template"},
                    {id: 9, description: "Dataset Select Field"},
                    {id: 10, description: "Split Task Field"},
                    {id: 11, description: "Checkbox List Field"},
                    {id: 12, description: "Close Task Field"},
                    {id: 13, description: "Note Field"},
                    {id: 17, description: "Sms Field"},
                    {id: 18, description: "Photo Field"},
                    {id: 19, description: "Document Template"},
                    {id: 20, description: "Information Field"},
                    {id: 21, description: "Folder Select Field"},
                    {id: 22, description: "Eval Field"},
                    {id: 23, description: "MS Word Template"},
                ];
                if (typeof this.userInfo.entityInfo.talentIndexEnabled != 'undefined' && this.userInfo.entityInfo.talentIndexEnabled == '1') {
                    tResult.push({id: 15, description: "XDS Credit Report"},)
                }
                return tResult;
            },

            allowAddField() {
                try {
                    if (this.field.description == null || this.field.description == '' || this.field.fieldType == null || this.field.fieldType == 0) {
                        return null
                    }

                    if (this.field.fieldType === 9 && !this.field.config.dataset)
                        return null

                    if ([5,7,8,10,11].includes(this.field.fieldType) && this.field.lookup == null) {
                        return null
                    } else {
                        return JSON.stringify(this.field)
                    }
                } catch (e) {
                    // eslint-disable-next-line
                    console.log(e);
                    return null
                }
            },
            visible: {
                get() {
                    return this.intToBool(this.field.visible)
                },
                set(value) {
                    this.field.visible = this.boolToInt(value)
                }
            },
            required: {
                get() {
                    return this.intToBool(this.field.required)
                },
                set(value) {
                    this.field.required = this.boolToInt(value)
                }
            },
            fullLookup: {
                get: function () {
                    return {
                        lookup_type: this.field.lookup_type,
                        lookup: this.field.lookup
                    }
                },
                set: function (newValue) {
                    this.field.lookup_type = newValue != null && typeof newValue.lookup_type != "undefined" ? newValue.lookup_type : null;
                    this.field.lookup = newValue != null && typeof newValue.lookup != "undefined" ? newValue.lookup : null;
                }
            },
            forceCloseLookup: {
                get: function () {
                    return this.intToBool(this.field.lookup == null ? 0 : this.field.lookup)
                },
                set: function (newValue) {
                    this.field.lookup = this.boolToInt(newValue)
                }
            },
            securityShownTo: {
                // getter
                get: function () {
                    return this.field.securityLookup != null && typeof this.field.securityLookup.shownTo != "undefined" ? this.field.securityLookup.shownTo : []
                },
                set: function (newValue) {
                    if (this.field.securityLookup == null) {this.field.securityLookup = {}}
                    this.field.securityLookup.shownTo = newValue;
                    this.field.securityLookup = this.field.securityLookup != null ? JSON.parse(JSON.stringify(this.field.securityLookup)) : null;
                }
            },
            securityEditableBy: {
                get: function () {
                    return this.field.securityLookup != null && typeof this.field.securityLookup.editableBy != "undefined" ? this.field.securityLookup.editableBy : []
                },
                set: function (newValue) {
                    if (this.field.securityLookup == null) {this.field.securityLookup = {}}
                    this.field.securityLookup.editableBy = newValue;
                    this.field.securityLookup = this.field.securityLookup != null ? JSON.parse(JSON.stringify(this.field.securityLookup)) : null;
                }
            },
        },
    }
</script>

<style scoped>

</style>
