var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fill-height scrollable"},[(this.tasksLoading != null)?[(_vm.tasksLoading > 0)?_c('div',{staticClass:"text-xs-center pa-2"},[_c('v-progress-circular',{attrs:{"size":40,"width":5,"color":"primary","indeterminate":""}})],1):_c('v-layout',{staticClass:"scrollable",attrs:{"row":"","fill-height":""}},[_c('v-flex',{staticClass:"fill-height scrollable",attrs:{"xs6":""}},[(_vm.datasetsLoading)?_c('div',{staticClass:"fill-height c-d-flex c-align-center c-justify-center"},[_c('v-progress-circular',{attrs:{"size":40,"width":5,"color":"primary","indeterminate":""}})],1):[_c('multi-select-list',{attrs:{"show-action-buttons":false,"show-check-boxes":true,"multiple":false,"headline-key":"description","item-key":"id","return-object":true,"show-filter":true,"items":_vm.datasets,"cutom-sort-fn":(a,b) => {
                    if (a.description !== b.description)
                        return a.description < b.description ? -1 : 1
                    else
                        return a.valueDescription < b.valueDescription ? -1 : 1
                },"filter-label":"Filter Datasets"},scopedSlots:_vm._u([{key:"headline",fn:function({value}){return [(value.valueDescription != null && value.valueDescription != '')?_c('div',{staticClass:"body-1 pb-1",staticStyle:{"white-space":"normal"}},[_vm._v(" "+_vm._s(value.valueDescription)+" ")]):_vm._e(),_c('div',{staticClass:"caption greyType--text"},[_vm._v(" "+_vm._s(value.description)+" ")])]}},{key:"action",fn:function({value}){return [_c('div',{staticClass:"px-2 subheading"},[_vm._v(" ("+_vm._s(value.tasks.length)+") ")])]}}],null,false,1405319702),model:{value:(_vm.selectedDatasetFilter),callback:function ($$v) {_vm.selectedDatasetFilter=$$v},expression:"selectedDatasetFilter"}})]],2),_c('v-divider',{attrs:{"vertical":true}}),_c('v-flex',{staticClass:"fill-height scrollable",attrs:{"xs6":""}},[(!_vm.datasetTasksLoading)?_c('v-layout',{attrs:{"column":"","fill-height":""}},[_c('v-flex',{attrs:{"shrink":""}},[_c('full-task-filter',{attrs:{"tasks":_vm.viewTasks,"filter-options":_vm.filterSettings},on:{"taskListUpdate":function($event){_vm.postFilteredTasks = $event},"settingsUpdated":function($event){_vm.filterSettings = $event}}})],1),_c('v-divider'),(_vm.filterSettings != null)?_c('v-flex',{staticClass:"scrollable",attrs:{"xs12":""}},[_c('virtual-list',{staticClass:"fill-height of-y",attrs:{"keeps":40,"estimate-size":60,"data-key":"refID","data-sources":_vm.displayTasks,"data-component":_vm.FolderTaskBandItem,"extra-props":{
                                droppedGroupings: _vm.droppedGroupings,
                                filterSettings: _vm.filterSettings,
                                toggleGrouping: _vm.toggleGrouping,
                                drag: _vm.drag,
                                taskNav: _vm.taskNav,
                                // escalations,
                                // curMS,
                                // removeEscalationRunStart,
                              }}})],1):_vm._e()],1):_c('div',{staticClass:"fill-height c-d-flex c-align-center c-justify-center"},[_c('v-progress-circular',{attrs:{"size":40,"width":5,"color":"primary","indeterminate":""}})],1)],1)],1)]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }