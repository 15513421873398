<template>
    <v-dialog v-model="shown" fullscreen persistent>
        <v-card flat class="fill-height">
            <v-layout column fill-height>
                <v-flex shrink>

                    <v-card-title class="py-2 pr-2 primary">
                        <v-icon
                            left
                            color="white"
                        >
                            note
                        </v-icon>
                        <span class="title font-weight-light white--text">{{fieldName}}</span>
                        <v-spacer></v-spacer>

                        <v-btn
                            @click="$emit('dismiss', false)"
                            small
                            icon
                            flat
                            color="white"
                            class="pa-0 ma-0"
                        >
                            <v-icon small>close</v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-divider></v-divider>
                </v-flex>

<!--                <v-flex shrink>-->
<!--                    <v-card-title class="pa-1 pl-2 primary">-->
<!--                        <span class="subheading font-weight-light white&#45;&#45;text">{{fieldName}}</span>-->
<!--                        <v-spacer></v-spacer>-->
<!--                        <v-btn-->
<!--                                @click="$emit('dismiss', false)"-->
<!--                                small-->
<!--                                icon-->
<!--                                flat-->
<!--                                color="white"-->
<!--                                class="pa-0 ma-0"-->
<!--                        >-->
<!--                            <v-icon small>close</v-icon>-->
<!--                        </v-btn>-->
<!--                    </v-card-title>-->
<!--                </v-flex>-->

                <v-flex xs12 class="scrollable" v-if="shown">
                    <editor
                        ref="editor"
                        :value="value"
                        @input="$emit('input', $event)"
                        :extra-config="editorConfig"
                    />
                </v-flex>

                <v-flex shrink class="text-xs-right">
                    <v-tooltip top style="z-index: 9999">
                        <v-btn
                                icon
                                large
                                color="secondary"
                                @click="doneEditing"
                                slot="activator"
                                :disabled="imageCount !== 0"
                        >
                            <!--@click="editDone()"-->
                            <v-icon>
                                edit
                            </v-icon>
                        </v-btn>
                        <span>Done</span>
                    </v-tooltip>
                </v-flex>
            </v-layout>

        </v-card>
    </v-dialog>
</template>

<script>
    import {codeBaseMixin} from "@/codeBaseMixin";
    import axios from 'axios'
    import Editor from "@/Editor/Editor";

    export default {
        components: {Editor},
        mixins: [codeBaseMixin],
        props: {
            shown: Boolean,
            value: String,
            userInfo: Object,
            fieldName: String
        },
        data() {
            return {
                imageCount: 0
            }
        },
        watch: {
            shown: {
                immediate: true,
                handler() {
                    this.imageCount = 0;
                }
            }
        },
        methods: {
            doneEditing() {
                this.$refs.editor.updateModel()
                setTimeout(() => {
                    this.$emit('dismiss', true);
                }, 500)
            },
            uploadImage(blobInfo, success) {
                ++this.imageCount
                let h = new Headers();
                let fd = new FormData();
                let myFile = blobInfo.blob();
                fd.append('attachmentFile', myFile);

                axios.request({
                    method: "post",
                    url: `${this.serverAddress}/upload/noteAttachment/${this.userInfo.entityID}`,
                    data: fd,
                    onUploadProgress: (p) => {
                        this.uploadProgress = (p.loaded / p.total) * 100
                    },
                })
                    .then(data => {
                        success(data.data.link);
                        --this.imageCount;
                    })
                    .catch(() => {
                        this.showSnack('Error', 'Image Upload Error', 'Close', 'red');
                        success('.blank');
                        --this.imageCount;
                    })
            },
        },
        computed: {
            editorConfig() {
                // testDrop(event, editor) {
                //     console.log('DROP EVENT', event)
                //
                //     editor.insertContent('<span style="background: fuchsia; display: inline-block; cursor: pointer" :draggable="true">Test123456</span>')
                // },
                // paste_data_images: true,
                // images_upload_handler: this.uploadImage,
                // init_instance_callback: function(editor) {
                //     editor.on('drop', function(e) {
                //         vm.testDrop(e, editor)
                // });
                // },
                // uploadImage(blobInfo, success) {
                //     ++this.imageCount
                //     let h = new Headers();
                //     let fd = new FormData();
                //     let myFile = blobInfo.blob();
                //     fd.append('attachmentFile', myFile);
                //
                //     axios.request({
                //         method: "post",
                //         url: `${this.serverAddress}/upload/noteAttachment/${this.userInfo.entityID}`,
                //         data: fd,
                //         onUploadProgress: (p) => {
                //             this.uploadProgress = (p.loaded / p.total) * 100
                //         },
                //     })
                //         .then(data => {
                //             success(data.data.link);
                //             --this.imageCount;
                //         })
                //         .catch(() => {
                //             this.showSnack('Error', 'Image Upload Error', 'Close', 'red');
                //             success('.blank');
                //             --this.imageCount;
                //         })
                // },

                return {
                    paste_data_images: true,
                    images_upload_handler: this.uploadImage,
                    init_instance_callback: function(editor) {
                        editor.focus()
                    },
                    content_style: 'img {max-width: 100%;}'
                }
            },
        }
    }
</script>

<style scoped>

</style>
