<template>
  <v-card
      class="pa-1"
      @dragstart="drag($event)"
      :draggable="!noDrag && !inEdit"
      flat
      :style="dense ? 'border: 1px solid var(--card-back-accent)' : ''"
      style="overflow-x: hidden; overflow-y: auto"
      @contextmenu="disableContext ? null : show($event)"
  >
    <v-layout row wrap v-if="document" style="overflow-x: hidden; overflow-y: auto">
      <v-flex xs12>
        <v-layout align-center justify-space-between row wrap class="pb-1">
          <!--User Circle-->
          <!--<v-flex shrink class="text-xs-center" >-->
          <!--<v-avatar color="primary" size="26">-->
          <!--<span class="caption white&#45;&#45;text">{{ getInitials(document.linkName + ' ' + document.linkSurname) }}</span>-->
          <!--</v-avatar>-->
          <!--</v-flex>-->
          <v-tooltip top>
            <v-flex slot="activator" shrink class="font-weight-medium text--small" style="cursor: pointer">
              {{
                document.linkUser === -2 ? 'Assist User' : document.linkUser === 0 ? 'System' : (document.linkContactName || (document.linkName + ' ' + document.linkSurname))
              }}
            </v-flex>
            {{
              document.linkDate ? `Linked on ${getLongDate(document.linkDate)}` : `Document Linked By ${document.linkUser === 0 ? 'System' : document.linkName + ' ' + document.linkSurname}`
            }}
          </v-tooltip>
          
          
          <v-flex shrink style="font-size: 0.8em" class="font-weight-regular">
            {{ getLongDate(document.created) }}
          </v-flex>
        </v-layout>
      </v-flex>
      <!--Document Detail-->
      <v-flex xs12 class="caption pt-1" style="overflow-x: hidden; overflow-y: auto">
        <v-layout align-center justify-start row class="pb-1">
          <v-hover>
            <v-flex shrink class="text-xs-center" slot-scope="{ hover }" v-if="progress === 0">
              <v-layout row>
                <!--                                <v-flex shrink v-show="hover && hasAccess && canView" class="pl-1 pr-1">-->
                <!--                                        <v-avatar :color="hover ? 'primary' : 'secondary'" size="26" @click="previewAttachment(document)">-->
                <!--                                            <v-icon small class="white&#45;&#45;text" style="cursor: pointer">-->
                <!--                                                visibility-->
                <!--                                            </v-icon>-->
                <!--                                        </v-avatar>-->
                <!--                                </v-flex>-->
                
                <v-flex shrink>
                  <v-tooltip top>
                    <v-avatar v-if="hasAccess" slot="activator" :color="hover ? 'primary' : 'secondary'" size="26"
                              @click="downloadFile()">
                      <v-icon small class="white--text" style="cursor: pointer">
                        {{ hover ? 'cloud_download' : 'attach_file' }}
                      </v-icon>
                    </v-avatar>
                    <v-avatar v-else slot="activator" color="secondary" size="26">
                      <v-icon small class="white--text" style="cursor: pointer">
                        attach_file
                      </v-icon>
                    </v-avatar>
                    <span>{{ hasAccess ? 'Download File' : 'This Document Has Been Restricted' }}</span>
                  
                  </v-tooltip>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex shrink class="text-xs-center" v-else>
              <v-progress-circular
                  v-if="progress !== 0"
                  :size="15"
                  :width="3"
                  color="white"
                  :value="progress"
              />
            </v-flex>
          </v-hover>
          
          <template v-if="!inEdit">
            <!--Document Name-->
            <!--                        <v-flex shrink v-if="document.extention == 'wav'">-->
            <!--                          -->
            <!--                          -->
            <!--&lt;!&ndash;                            <simple-audio :src="`${serverAddress}/upload/download/${userInfo.entityID}/${document.documentID}/${documentDownloadName}`"/>&ndash;&gt;-->
            <!--                        </v-flex>-->
            <v-flex xs12 class="pl-1 text-truncate" style="overflow-x: hidden; overflow-y: auto">
              {{ document.alias == null ? displayFilename : document.alias }}
              <div class="caption white--text px-1 green darken-2"
                   style="padding-top: 2px; padding-bottom: 1px; border-radius: 3px; display: inline-block">
                {{ `${document.extention.toUpperCase()}` }}
              </div>
              <v-tooltip top v-if="pdfDocument && isPDFSecured">
                <template v-slot:activator="{on}">
                  <v-btn
                      small
                      icon
                      flat
                      color="indigo"
                      class="pa-0 ma-0"
                      v-on="on"
                      @click="showSecurityData = true"
                  >
                    <v-icon>gpp_maybe</v-icon>
                  </v-btn>
                </template>
                PDF is secured (Click for more info)
              </v-tooltip>
            </v-flex>
            <!--No Access Icon-->
            <v-flex shrink
                    v-if="typeof document.securityLookup != 'undefined' && document.securityLookup != null && JSON.parse(document.securityLookup).length > 0">
              <v-tooltip top>
                <v-icon
                    :color="hasAccess ? 'primary' : 'red'"
                    class="pa-0 ma-0"
                    style="cursor: pointer"
                    slot="activator"
                >
                  security
                </v-icon>
                <span>{{ hasAccess ? 'Document Has Security Applied' : 'This Document Has Been Restricted' }}</span>
              </v-tooltip>
            </v-flex>
            <!--Edit Button-->
            <v-flex shrink v-if="addDocToTaskBtn">
              <v-tooltip left>
                <v-btn v-if="hasAccess" @click="$emit('addDocToTask', document)" slot="activator" flat icon
                       color="primary" class="ma-0 pa-0" small>
                  <v-icon>add</v-icon>
                </v-btn>
                <span>Add Attachment to Task</span>
              </v-tooltip>
            </v-flex>
            <v-flex shrink>
              <v-tooltip left v-if="!mailTemplate && !noteDoc">
                <v-btn v-if="hasAccess" @click="switchToEditMode" slot="activator" flat icon color="secondary"
                       class="ma-0 pa-0" small>
                  <v-icon>edit</v-icon>
                </v-btn>
                <span>Edit File Name</span>
              </v-tooltip>
            </v-flex>
            <v-flex shrink>
              <slot name="uploadSection"></slot>
            </v-flex>
            <v-flex shrink>
              <v-tooltip top v-if="fieldValue">
                <v-btn
                    @click="showUpload.show = true"
                    flat
                    icon
                    color="primary"
                    class="pa-0 ma-0"
                    slot="activator"
                >
                  <v-icon>cloud_upload</v-icon>
                </v-btn>
                
                <span>Upload New Document</span>
                <app-document-upload-dlg
                    :dialog="showUpload"
                    :user-info="userInfo"
                    :field-value="true"
                    @documentsUploaded="uploadFinished"
                />
                <!--@documentsSaved="updateBar"-->
              </v-tooltip>
              <v-tooltip v-if="mailTemplate" top>
                <v-btn
                    @click="$emit('removeFromTemplate', true)"
                    flat
                    icon
                    color="red"
                    class="pa-0 ma-0"
                    slot="activator"
                >
                  <v-icon>clear</v-icon>
                </v-btn>
                
                <span>Remove Document</span>
              </v-tooltip>
            </v-flex>
          </template>
          <template v-if="inEdit">
            <v-flex xs10 class="pl-2 c-d-flex c-align-center">
              <v-text-field
                  label="File Name"
                  single-line
                  color="primary"
                  clearable
                  v-model="newDocumentAlias"
                  @keydown.enter="newDocumentAlias && newDocumentAlias.length >= 3 ? updateAlias() : showSnack('Error', 'Name Must Be At Least 3 Characters Long', 'Close', 'red')"
                  hide-details
                  class="ma-0 pa-0"
                  ref="renameEdit"
                  @input="sanitzeText($event)"
              ></v-text-field>
              <v-tooltip left>
                <template #activator="{on}">
                  <v-btn
                      :disabled="newDocumentAlias == null || newDocumentAlias.length < 3"
                      @click="updateAlias()"
                      flat
                      icon
                      color="primary"
                      class="ma-0 pa-0"
                      v-on="on"
                  >
                    <v-icon>save</v-icon>
                  </v-btn>
                </template>
                Save Changes
              </v-tooltip>
            </v-flex>
          </template>
        </v-layout>
      </v-flex>
      <v-flex v-if="audioType">
        <div>
          <app-voice-note-player
              :url="`${sa}/download/document/${document.documentID}/${downloadFilename}/${token()}`"
              :show-delete="false"
              :show-download="true"
          />
        </div>
      </v-flex>
      <v-flex v-else-if="imageType" class="pa-1">
        <div class="c-d-flex c-align-center c-justify-center">
          <div
              class="px-2 pt-2"
              style="max-width: 100%; max-height: 100%; border-radius: 10px; position: relative; display: inline-block"
          >
            <img
                @click="eventBus.$emit('imagePreview', {
                  title: document.linkUser === -2 ? 'Assist User' : document.linkUser === 0 ? 'System' : (document.linkContactName || (document.linkName + ' ' + document.linkSurname)),
                  subtitle: getLongDate(document.created),
                  documentId: document.documentID,
                  filename: downloadFilename
                })"
                :draggable="false"
                :src="`${sa}/download/imagePreview/300/300/${document.documentID}/${downloadFilename}/${token()}`"
                :alt="downloadFilename"
                style="max-width: 100%; max-height: 300px; border-radius: 10px; cursor: pointer"
            >
            
            <a style="position: absolute; right: 0; top: 0;" :href="`${sa}/download/document/${document.documentID}/${downloadFilename}/${token()}`" target="_blank">
              <v-btn
                  icon
                  flat
                  depressed
              >
                <v-icon color="black">download</v-icon>
              </v-btn>
            </a>
          
          </div>
        </div>
      </v-flex>
      <v-flex v-else>
        <div class="px-1">
          <app-general-file-viewer :allow-download="hasAccess" :document-id="document.documentID" :filename="downloadFilename" :card-props="{flat: true}">
          
          </app-general-file-viewer>
        </div>
      </v-flex>
      
    </v-layout>
    
    <v-menu
        v-model="showMenu"
        :position-x="x"
        :position-y="y"
        absolute
        offset-y
        v-if="!softBlock && !addingToRepo"
    >
      <v-card>
        
        <div class="c-d-flex c-align-center hover-item pa-2 pr-2" @click="addToRepo()">
          <v-icon class="pr-2">contact_page</v-icon>
          Add To User Store
        </div>
        <div class="c-d-flex c-align-center hover-item pa-2 pr-2" @click="$emit('addEvent')" v-if="showAddEvent">
          <v-icon class="pr-2">event</v-icon>
          Create Calendar Event
        </div>
        <div class="c-d-flex c-align-center hover-item pa-2 pr-2" @click="showSecure = true"
             v-if="pdfDocument && !isPDFSecured">
          <v-icon class="pr-2">lock</v-icon>
          Secure PDF Document
        </div>
        <div class="c-d-flex c-align-center hover-item pa-2 pr-2" @click="showAddPassword = true"
             v-if="pdfDocument && !isPDFSecured">
          <v-icon class="pr-2">password</v-icon>
          Add Password
        </div>
        <div class="c-d-flex c-align-center hover-item pa-2 pr-2" @click="showSecurityData = true"
             v-if="pdfDocument && isPDFSecured">
          <v-icon class="pr-2">lock</v-icon>
          Security Info
        </div>
        <div class="c-d-flex c-align-center hover-item pa-2 pr-2" @click="showMenu = false">
          <v-icon class="pr-2">close</v-icon>
          Cancel
        </div>
      
      
      </v-card>
    </v-menu>
    
    <input-dlg
        :show-dialog="showSecurityData"
        v-if="showSecurityData"
        title="Security Info"
        :show-input-box="false"
        @dismiss="showSecurityData = false"
        :cancel-button="true"
        :body-padding="false"
        :width="300"
    >
      <div slot="body">
        <div v-if="loadingSecurityData || !securityInfo" class="c-d-flex pa-2 c-align-center c-justify-center">
          <v-progress-circular
              :size="40"
              :width="5"
              color="primary"
              indeterminate
          ></v-progress-circular>
        </div>
        <div v-else class="c-d-flex align-center">
          <div class="px-2 c-flex-grow-1">
            <div class="px-2 py-1 c-d-flex align-center">
              <v-icon left>person</v-icon>
              <b>{{ securityInfo.user }}</b></div>
            <div class="px-2 py-1 c-d-flex align-center">
              <v-icon left>event</v-icon>
              {{ securityInfo.date }}
            </div>
            <v-hover>
              <v-tooltip left slot-scope="{hover}">
                <template v-slot:activator="{on}">
                  <div class="hover-item px-2 py-1 c-d-flex align-center" @click="copyPassword" style="cursor: pointer"
                       v-on="on">
                    <v-icon left>password</v-icon>
                    {{ securityInfo.password }}
                    <v-icon right v-if="hover">content_copy</v-icon>
                  </div>
                </template>
                Copy Password To Clipboard
              </v-tooltip>
            </v-hover>
            <input type="text" :value="securityInfo.password" :id="'documentPassword' + securityInfo.password"
                   style="width: 3px; height: 3px; opacity: 0; position: fixed"/>
          </div>
          <div v-if="securityData.allowChange">
            <v-btn
                icon
                flat
                color="primary"
                class="pa-0 ma-0 mr-2"
                @click="showSecurityData = false; showChangePassword = true"
            >
              <v-icon>edit</v-icon>
            </v-btn>
          </div>
        </div>
      </div>
      <div slot="actions">
        <v-btn
            small
            color="secondary"
            class="pa-0 ma-0"
            @click="showSecurityData = false"
        >
          done
        </v-btn>
      </div>
    </input-dlg>
    
    <input-dlg
        :show-dialog="showAddPassword"
        v-if="showAddPassword"
        title="Add Password"
        :show-input-box="false"
        @dismiss="showAddPassword = false"
        :cancel-button="!securingPDF"
        :body-padding="false"
        :width="500"
    >
      <div slot="body">
        <div class="px-2 pt-3">
          <v-form ref="securePDFForm" :disabled="securingPDF" @submit.prevent="addPassword">
            <v-text-field
                ref="pdfSecurePasswordField"
                :readonly="securingPDF"
                color="primary"
                class="pa-0 ma-0"
                label="PDF Password"
                placeholder="eg. Password123"
                v-model="pdfSecurePassword"
                :rules="[validationRules.required, min3Characters]"
                :autofocus="true"
            >
            </v-text-field>
          </v-form>
        </div>
      </div>
      <div slot="actions">
        <v-btn
            small
            outline
            color="secondary"
            :loading="securingPDF"
            :disabled="securingPDF"
            @click="showAddPassword = false"
        >
          Cancel
        </v-btn>
        <v-btn
            small
            color="secondary"
            :loading="securingPDF"
            :disabled="securingPDF"
            @click="addPassword"
        >
          <v-icon left small>lock</v-icon>
          Add Password
        </v-btn>
      </div>
    </input-dlg>
    
    <input-dlg
        :show-dialog="showChangePassword"
        v-if="showChangePassword"
        title="Change Password"
        :show-input-box="false"
        @dismiss="showChangePassword = false"
        :cancel-button="!securingPDF"
        :body-padding="false"
        :width="500"
    >
      <div slot="body">
        <div class="px-2 pt-3">
          <v-form ref="securePDFForm" :disabled="securingPDF" @submit.prevent="changePassword">
            <v-text-field
                ref="pdfSecurePasswordField"
                :readonly="securingPDF"
                color="primary"
                class="pa-0 ma-0"
                label="PDF Password"
                placeholder="eg. Password123"
                v-model="pdfSecurePassword"
                :rules="[validationRules.required, min3Characters]"
                :autofocus="true"
            >
            </v-text-field>
          </v-form>
        </div>
      </div>
      <div slot="actions">
        <v-btn
            small
            outline
            color="secondary"
            :loading="securingPDF"
            :disabled="securingPDF"
            @click="showChangePassword = false"
        >
          Cancel
        </v-btn>
        <v-btn
            small
            color="secondary"
            :loading="securingPDF"
            :disabled="securingPDF"
            @click="changePassword"
        >
          <v-icon left small>lock</v-icon>
          Change Password
        </v-btn>
      </div>
    </input-dlg>
    
    <input-dlg
        :show-dialog="showSecure"
        v-if="showSecure"
        title="Secure PDF Document"
        :show-input-box="false"
        @dismiss="showSecure = false"
        :cancel-button="!securingPDF"
        :body-padding="false"
        :width="500"
    >
      <div slot="body">
        <div class="px-2 pt-3">
          <v-form ref="securePDFForm" :disabled="securingPDF" @submit.prevent="securePDF">
            <v-text-field
                ref="pdfSecurePasswordField"
                :readonly="securingPDF"
                color="primary"
                class="pa-0 ma-0"
                label="PDF Password"
                placeholder="eg. Password123"
                v-model="pdfSecurePassword"
                :rules="[validationRules.required, min3Characters]"
            >
            </v-text-field>
          </v-form>
        </div>
      </div>
      <div slot="actions">
        <v-btn
            small
            outline
            color="secondary"
            :loading="securingPDF"
            :disabled="securingPDF"
            @click="showSecure = false"
        >
          Cancel
        </v-btn>
        <v-btn
            small
            color="secondary"
            :loading="securingPDF"
            :disabled="securingPDF"
            @click="securePDF"
        >
          <v-icon left small>lock</v-icon>
          Secure PDF
        </v-btn>
      </div>
    
    </input-dlg>
  </v-card>

</template>

<script>

import {codeBaseMixin} from "../../../../codeBaseMixin";
import DocumentUploadDlg from './DocumentUploadDlg'
import {eventBus} from "../../../../main";
import {addDocToUserRepo} from "@/Document Repository/docRepoMan";
import InputDlg from "@/components/General/InputDlg";
import {validationRules} from "@/codeFunctions";
import {mapGetters} from "vuex";
import {lookup} from 'mime-types'
import AppVoiceNotePlayer from "@/Tasks/TaskView V2/components/Chat Message/AppVoiceNotePlayer.vue";
const tokenMan = require('@/tokenManSite')
import axiosRequest from "@/axiosRequest";
import AppGeneralFileViewer from "@/components/General/AppGeneralFileViewer.vue";

export default {
  mixins: [codeBaseMixin],
  components: {AppGeneralFileViewer, AppVoiceNotePlayer, InputDlg, appDocumentUploadDlg: DocumentUploadDlg},
  props: {
    document: Object,
    userInfo: Object,
    fieldValue: {
      value: false,
      type: Boolean
    },
    mailTemplate: {
      value: false,
      type: Boolean
    },
    noDrag: {
      type: Boolean,
      default: false
    },
    srcTaskAttachment: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: false
    },
    noteDoc: {
      default: false,
      type: Boolean
    },
    addDocToTaskBtn: {
      type: Boolean,
      default: false
    },
    showAddEvent: {
      type: Boolean,
      default: false
    },
    disableContext: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      min3Characters: value => value !== null && value.split('').length >= 3 || 'Minimum 3 characters',
      validationRules: validationRules,
      showSecure: false,
      showAddPassword: false,
      showChangePassword: false,
      pdfSecurePassword: null,
      securingPDF: false,
      inEdit: false,
      newDocumentAlias: '',
      showUpload: {show: false},
      progress: 0,
      emlView: false,
      showMenu: false,
      x: 0,
      y: 0,
      addingToRepo: false,
      
      showSecurityData: false,
      loadingSecurityData: false,
      securityData: null,
      sa: axiosRequest.serverAddress,
    }
  },
  watch: {
    showSecure: {
      immediate: true,
      handler(shown) {
        if (shown) {
          this.pdfSecurePassword = null
          setTimeout(() => {
            this.$refs.pdfSecurePasswordField.focus()
          }, 300)
        }
      }
    },
    showSecurityData: {
      immediate: true,
      handler(shown) {
        if (shown) {
          this.securityData = null;
          this.loadingSecurityData = true;
          this.$newReq('get', `upload/securePDF/${this.userInfo.entityID}/${this.document.documentDBID}`)
              .then((data) => {
                this.securityData = data;
                this.loadingSecurityData = false;
              })
              .catch(e => {
                console.log(e)
                this.$snack.networkError();
                this.showSecurityData = false;
              })
        }
      }
    }
  },
  methods: {
    token: tokenMan.token,
    copyPassword() {
      /* Get the text field */
      let copyText = document.getElementById("documentPassword" + this.securityInfo.password);
      /* Select the text field */
      copyText.select();
      /* Copy the text inside the text field */
      document.execCommand("copy");
      /* Alert the copied text */
      this.$snack.info('Password Copied')
    },
    
    securePDF() {
      if (this.$refs.securePDFForm.validate() === false) {
        this.$snack.error('Please enter a valid password.');
        return
      }
      
      this.securingPDF = true;
      this.$newReq('post', `upload/securePDF/${this.userInfo.entityID}/${this.userInfo.userID}/${this.document.documentDBID}`, {password: this.pdfSecurePassword})
          .then(securityData => {
            this.securityData = securityData;
            this.document.documentID = securityData.documentID;
            this.showSecure = false
          })
          .catch(e => {
            console.log(e);
            this.$snack.error('PDF Encryption Error');
          })
          .finally(() => {
            this.securingPDF = false;
          })
    },
    
    changePassword() {
      if (this.$refs.securePDFForm.validate() === false) {
        this.$snack.error('Please enter a valid password.');
        return
      }
      
      this.securingPDF = true;
      this.$newReq('post', `upload/changePassword/${this.userInfo.entityID}/${this.userInfo.userID}/${this.document.documentDBID}`, {password: this.pdfSecurePassword})
          .then(securityData => {
            this.securityData = securityData;
            this.showChangePassword = false
          })
          .catch(e => {
            console.log(e);
            this.$snack.error('PDF Encryption Error');
          })
          .finally(() => {
            this.securingPDF = false;
          })
    },
    
    addPassword() {
      if (this.$refs.securePDFForm.validate() === false) {
        this.$snack.error('Please enter a valid password.');
        return
      }
      
      this.securingPDF = true;
      this.$newReq('post', `upload/addPdfPassword/${this.userInfo.entityID}/${this.userInfo.userID}/${this.document.documentDBID}`, {password: this.pdfSecurePassword})
          .then(securityData => {
            this.securityData = securityData;
            this.document.documentID = securityData.documentID;
            this.showAddPassword = false
          })
          .catch(e => {
            console.log(e);
            this.$snack.error('PDF Encryption Error');
          })
          .finally(() => {
            this.securingPDF = false;
          })
    },
    
    addToRepo() {
      this.showMenu = false
      this.addingToRepo = true;
      addDocToUserRepo(this.document.documentDBID)
          .then(() => {
            this.$snack.info('Document Added')
          })
          .catch(e => {
            console.log(e)
          })
          .finally(() => {
            this.addingToRepo = false;
          })
    },
    
    show(e) {
      e.preventDefault()
      this.showMenu = false
      this.x = e.clientX
      this.y = e.clientY
      this.$nextTick(() => {
        this.showMenu = true
      })
      
    },
    
    downloadFile() {
      this.$newDownload(this.document)
    },
    
    // previewAttachment(attachment) {
    //     this.progress = 1;
    //     this.$newReqDownloadFile(
    //             'get',
    //             `upload/download/${this.userInfo.entityID}/${this.document.documentID}/${this.documentDownloadName}`,
    //             null,
    //             this.documentDownloadName || 'attachment',
    //             'url',
    //             true,
    //             false,
    //             (p) => {this.progress = Math.floor((p.loaded / p.total) * 100)}
    //
    //     )
    //             .then(async (data) => {
    //                 this.$refs.pdfViewer.localInit(data, this.documentDownloadName || 'Document');
    //             })
    //             .catch(() => {this.$snack.networkError()})
    //             .finally(() => {this.progress = 0;})
    // },
    
    sanitzeText(text) {
      if (text != null && text != '') {
        this.newDocumentAlias = this.newDocumentAlias.replace(/[/\\?%*:|"<>,#]/g, '-');
      }
    },
    
    switchToEditMode() {
      this.inEdit = true;
      this.newDocumentAlias = this.document.alias != null && this.document.alias != '' ? this.document.alias : this.document.fileName;
      
      if (this.newDocumentAlias.split('.').pop().toLowerCase() === this.document.extention.toLowerCase()) {
        const arr = this.newDocumentAlias.split('.')
        arr.pop()
        this.newDocumentAlias = arr.join('.')
      }
      
      this.$nextTick(() => {
        this.$refs.renameEdit.focus();
      })
    },
    
    drag: function (event) {
      event.dataTransfer.setData("text", JSON.stringify({dragType: 'document', data: this.document.documentDBID}));
      if (!this.srcTaskAttachment) {
        eventBus.$emit('taskDocumentDrag', this.document);
      } else {
        eventBus.$emit('SRCtaskDocumentDrag', this.document);
      }
    },
    
    uploadFinished(data) {
      this.$emit('uploadDone', data)
    },
    updateAlias() {
      this.document.alias = this.newDocumentAlias;
      this.inEdit = false;
      this.newDocumentAlias = '';
      
      this.fetch({
        url: '/tasks/updateAlias/' + this.userInfo.entityID + '/' + this.document.documentDBID,
        method: 'PATCH',
        body: JSON.stringify({alias: this.document.alias})
      })
          .catch((e) => {
            // eslint-disable-next-line
            console.log(e)
          })
      
    }
  },
  computed: {
    eventBus() {
      return eventBus
    },
    ...mapGetters(['usersRef']),
    ...mapGetters("userInfo", ["softBlock"]),
    
    downloadFilename() {
      let downloadName = this.document.alias || this.document.filename || this.document.fileName || 'file'
      const dlExt = downloadName.split('.').pop().toLowerCase()
      return dlExt === this.document.extention.toLowerCase() ? downloadName : (downloadName + '.' + this.document.extention)
    },
    
    audioType() {
      return (lookup(this.document.extention) || 'x/x').split('/').shift() === 'audio'
    },
    
    imageType() {
      return (lookup(this.document.extention) || 'x/x').split('/').shift() === 'image'
    },
    
    otherType() {
      return (lookup(this.document.extention) || 'x/x').split('/').shift() === 'image'
    },
    
    securityInfo() {
      return this.securityData ? {
        user: this.usersRef[this.securityData.userID].fullName,
        date: this.getLongDate(this.securityData.date),
        password: this.securityData.password
      } : null
    },
    
    displayFilename() {
      try {
        let filenamArr = this.document.fileName.split('.')
        filenamArr.pop()
        return filenamArr.join('.')
      } catch (e) {
        console.log(e)
        return 'Error With Filename'
      }
    },
    
    documentDownloadName() {
      if (this.document.alias == null) {
        return this.document.fileName
      } else {
        return this.document.alias.replace(/\.pdf$/g, '') + '.' + this.document.extention
      }
    },
    hasAccess() {
      if (typeof this.document.securityLookup != 'undefined' && this.document.securityLookup != null) {
        let groupsWithOutAccess = []
        try {
          groupsWithOutAccess = JSON.parse(this.document.securityLookup)
          if (typeof groupsWithOutAccess == 'number') {
            groupsWithOutAccess = [groupsWithOutAccess]
          }
          
        } catch (e) {
          return true
        }
        return this.userInfo.userGroups.filter(element => groupsWithOutAccess.includes(element)).length <= 0
      } else {
        return true
      }
    },
    canView() {
      try {
        return ['pdf', 'eml'].includes(this.document.extention.toLowerCase());
      } catch (e) {
        return false
      }
    },
    pdfDocument() {
      try {
        return this.document.extention.toLowerCase() === 'pdf';
      } catch (e) {
        return false
      }
    },
    isPDFSecured() {
      return (this.document.documentID.substring(this.document.documentID.length - 2) === '-s') || this.securityData && this.securityData.password
    }
  }
  
}
</script>

<style scoped>

</style>
