import {blankString, objectifyArr} from "../../codeFunctions";

export default {
    namespaced: true,
    state() {
        return {
            windowFocused: true,
            started: false,
            channels: [],
            chats: [],
            comboBarShowCompose: false,
            archiveView: false
        }
    },
    mutations: {
        // setMailConfig(state, payload) {
        //     state.mailConfig = payload
        // },
    },
    actions: {
        // setUserInfo(context, payload) {context.commit('setUserInfo', payload)},

    },
    getters: {
        channels(state) {
            return state.channels

        },

        sortedChats(state) {
            return [...state.chats].sort((a,b) => a.lastSent > b.lastSent ? -1 : 1)
        },

        channelChats(state, getters) {
            return getters.sortedChats.filter(chat => chat.chatType === 1)
        },

        chatsByChannelMap(state, getters) {
            const allowedChannels = new Set(state.channels.map(channel => channel.id))
            const channelMap = new Map(state.channels.map(channel => [channel.id, []]))
            for (const chat of getters.channelChats)
                if (allowedChannels.has(chat.channelId || null))
                    channelMap.get(chat.channelId).push(chat)

            return channelMap
        },

        chatsMap(state) {
            return new Map(state.chats.map(v => [v.chatKey, v]))
        },

        channelsMap(state) {
            return new Map(state.channels.map(v => [v.id, v]))
        },

        chatsMapByChatKey(state) {
            return new Map(state.chats.map(v => [v.chatKey, v]))
        },

        totalUnreadChats(state) {
            return state.chats.filter(v => !v.isArchived).reduce((accum, chat) => accum + (chat.unread || 0), 0)
        },

        totalUnreadChannelChats(state) {
            return state.chats.reduce((accum, chat) => {
                return accum + (chat.chatType === 1 ? (chat.unread || 0) : 0)
            }, 0)
        }
    }
}
