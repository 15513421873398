<template>
    <div>
        <div class="text-center pa-2" v-if="addingField" style="border-radius: 3px; border: 1px dashed grey">
            <v-progress-circular
                    :size="20"
                    :width="2"
                    color="grey"
                    indeterminate
            />
        </div>
        <div class="pa-2" v-else>
            <div
                    style="border-radius: 3px; border: 1px dashed grey"
                    class="pa-1 text-xs-center"
                    @dragover="$event.preventDefault()"
                    @drop="!droppedEvent($event)"
            >
                Drop Fields Here
            </div>
        </div>

        <v-divider></v-divider>
        <div class="scrollable" style="max-height: 300px">
            <div v-for="field in linkedFields" :key="field.id" class="hover-item">
                <v-layout row align-center>
                    <v-flex xs12 class="pa-1 caption primary--text font-weight-bold">
                        {{field.description}}
                    </v-flex>
                    <v-flex shrink>
                        <v-btn
                                small
                                color="red"
                                class="pa-0 ma-0"
                                :disabled="removingField === field.id"
                                :loading="removingField === field.id"
                                @click="removeField(field.id)"
                                flat
                                icon
                        ><v-icon small>delete</v-icon></v-btn>
                    </v-flex>
                </v-layout>
                <v-divider></v-divider>
            </div>
        </div>
    </div>
</template>

<script>
    import {codeBaseMixin} from "../../../codeBaseMixin";

    export default {
        mixins: [codeBaseMixin],
        props: {
            userInfo: Object,
            value: Object,
            userGroups: Array,
            selectLists: Array,
            saveFields: Array,
            allFields: Array,
            removingField: 0
        },
        data() {
            return {
                addingField: false
            }
        },
        methods: {
            removeField(fieldID) {
                this.removingField = fieldID;
                this.fetch({
                    method: 'PATCH', url: `/datasetmanager/datasetfieldsRemoveFieldFromGroup/${this.userInfo.entityID}/${fieldID}`})
                    .then(() => {
                        this.$emit('addedFieldRemovedFromGroup', fieldID);
                        this.showSnack('Info', 'Field Removed From Group', 'Close', 'primary');
                        this.removingField = 0;
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e);
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
                        this.removingField = 0;
                    })

            },

            addFieldToGroup(val) {
                this.addingField = true;
                this.fetch({
                    method: 'PATCH', url: `/datasetmanager/datasetfieldsAddFieldToGroup/${this.userInfo.entityID}/${this.value.id}/${val}`})
                    .then(() => {
                        this.$emit('addedFieldToGroup', {groupID: this.value.id, fieldID: val});
                        this.showSnack('Info', 'Field Added To Group', 'Close', 'primary');
                        this.addingField = false;
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e);
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
                        this.addingField = false;
                    })

            },

            droppedEvent(dropEvent) {
                try {
                    let dropData = dropEvent.dataTransfer.getData('text');

                    dropData = JSON.parse(dropData);

                    if (typeof dropData.id != 'undefined' && dropData.id != null) {
                        this.addFieldToGroup(dropData.id)
                    }
                } catch (e) {
                    console.log(e);
                }
            },
        },
        computed: {
            linkedFields() {
                try {

                    return this.allFields
                        .filter(obj => obj.fk_field_group === this.value.id)
                        .sort((a,b) => a['order'] < b['order'] ? -1 : 1);

                } catch (e) {
                    return []
                }
            }
        }
    }
</script>

<style scoped>

</style>
