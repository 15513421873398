<template>
    <div>
<!--Hidden-->
        <div>
            <div class="pa-1 pt-2 body-2 greyType--text">
                Visibility:
            </div>
            <div class="pa-1 pl-3">
                <v-layout row align-center>
                    <v-flex shrink class="pr-2">
                        <v-btn
                                icon
                                small
                                flat
                                color="primary"
                                class="pa-0 ma-0"
                                slot="activator"
                                @click="updateVisibilityFn(selectedStatus)"
                        >
                            <v-icon>{{ selectedStatus.hidden == 1 ? 'check_box_outline_blank' : 'check_box'}}</v-icon>
                        </v-btn>
                    </v-flex>
                    <v-flex class="body-1">
                        Visible
                    </v-flex>
                </v-layout>
            </div>
        </div>
<!--Escalation-->
        <div>
            <div class="pa-1 pt-2 body-2 greyType--text">
                Escalation:
            </div>
            <div class="pa-1 pl-3">
                <v-switch
                        v-model="escalation"
                        label="Enable Escalation"
                        class="pa-0 ma-0"
                        hide-details
                        color="primary"
                        @change="saveChanges()"
                ></v-switch>
            </div>
        </div>
        <div v-if="escalation">
            <div class="pa-1 pt-2 body-2 greyType--text">
                Duration Before Escalation:
            </div>
            <div class="pa-1 pl-3">
                <app-time-runner v-model="escalationTime" @input="saveChanges()"/>
            </div>
        </div>
<!--Escalation-->
        <div v-if="escalation">
            <div class="pa-1 pt-2 body-2 greyType--text">
                Extension:
            </div>
            <div class="pa-1 pl-3">
                <v-switch
                        v-model="extension"
                        label="Enable Extension"
                        class="pa-0 ma-0"
                        hide-details
                        color="primary"
                        @change="saveChanges()"
                ></v-switch>
            </div>
        </div>
        <div v-if="escalation && extension">
            <div class="pa-1 pt-2 body-2 greyType--text">
                Extension Groups:
            </div>
            <div class="pa-1 pl-3 text-xs-center" v-if="loadingExtensions">
                <v-progress-circular
                        :size="40"
                        :width="5"
                        color="primary"
                        indeterminate
                ></v-progress-circular>
            </div>
            <template v-else>
                <div class="pa-1 pl-3">
                    <v-btn
                            block
                            color="secondary"
                            outline
                            small
                            @click="addExtensionRun()"
                    >
                        <v-icon left>add</v-icon>
                        Add Extension
                    </v-btn>
                    <app-input-dlg
                            :show-dialog="showAddExtensions"
                            title="Add New Extension Group"
                            :cancel-button="true"
                            :show-input-box="false"
                            @dismiss="showAddExtensions = false"
                    >
                        <template slot="body">
                            <!--v-model="returnObject.field"-->
                            <div class="pa-1 body-2 primary--text">
                                Select A User Groups
                            </div>
                            <v-autocomplete
                                    dense
                                    :items="userGroups"
                                    v-model="selectedAddUserGroup"
                                    multiple
                                    label="Select User Groups"
                                    hide-details
                                    single-line
                                    color="primary"
                                    item-text="description"
                                    item-value="id"
                                    class="pa-0 ma-0 pl-3"
                                    chips
                                    small-chips
                            >
                                <template slot="selection" slot-scope="data">
                                    <v-chip
                                            :selected="data.selected"
                                            close
                                            class="chip--select-multi white--text"
                                            @input="selectedAddUserGroup = selectedAddUserGroup.filter(obj => obj != data.item.id)"
                                            color="primary"
                                            small
                                    >
                                        {{ data.item.description }}
                                    </v-chip>
                                </template>
                            </v-autocomplete>
                            <div class="pa-1 pt-2 body-2 primary--text">
                                Max Extension Duration (0 is unlimited)
                            </div>
                            <app-time-runner
                                    class="pl-3"
                                    v-model="selectedAddUserGroupMaxTime"
                            />
                            <div class="pa-1 pt-2 body-2 primary--text">
                                Max Extension Interval (0 is unlimited)
                            </div>
                            <app-time-runner
                                    class="pl-3"
                                    v-model="selectedAddUserGroupMaxTimeInterval"
                            />
                            <div class="pa-1 pt-2 body-2 primary--text">
                                Escalation View Start (0 is disabled)
                                <div class="caption primary--text text-xs-right">
                                    *Time From Start Of Escalation
                                </div>
                            </div>
                            <app-time-runner
                                    class="pl-3"
                                    v-model="selectedAddUserGroupViewStart"
                            />
                            <div class="pa-1 pt-2 body-2 primary--text">
                                Escalation View End (0 is infinity)
                                <div class="caption primary--text text-xs-right">
                                    *Time From Start Of Escalation
                                </div>
                            </div>
                            <app-time-runner
                                    class="pl-3"
                                    v-model="selectedAddUserGroupViewEnd"
                            />
                        </template>
                        <template slot="actions">
                            <v-btn
                                    flat
                                    color="secondary"
                                    @click="addExtensionGroup()"
                                    :disabled="(selectedAddUserGroup == null || selectedAddUserGroup.length == 0) || addingUserGroup"
                                    :loading="addingUserGroup"
                            >
                                Add User Group
                            </v-btn>
                        </template>
                    </app-input-dlg>
                </div>

                <template v-for="ext in extensions">
                    <div class="pa-2">
                        <v-card>
                            <v-layout class="pa-1 pl-2 primary" row align-center>
                                <v-flex xs12 class="body-2 white--text">
                                    <template v-for="group in userGroups.filter(obj => JSON.parse(`[${ext.fk_user_group}]`).includes(obj.id))">
                                        <v-chip
                                                close
                                                class="chip--select-multi white--text"
                                                @input="removeGroupFromGroup(ext, group)"
                                                color="primary"
                                                small
                                        >
                                            {{ group.description }}
                                        </v-chip>
                                    </template>

                                </v-flex>
                                <v-flex shrink>
                                    <v-tooltip left>
                                        <v-btn
                                                slot="activator"
                                                small
                                                flat
                                                icon
                                                class="pa-0 ma-0"
                                                color="white"
                                                @click="addUserGroupToGroupRunStart(ext)"
                                        >
                                            <v-icon>edit</v-icon>
                                        </v-btn>
                                        <span>Edit User Groups</span>
                                    </v-tooltip>
                                </v-flex>
                                <v-flex shrink>
                                    <v-tooltip left>
                                        <v-btn
                                                slot="activator"
                                                small
                                                flat
                                                icon
                                                class="pa-0 ma-0"
                                                color="white"
                                                @click="removeExt(ext)"
                                                :loading="removeExtID == ext.id"
                                                :disabled="removeExtID == ext.id"
                                        >
                                            <v-icon>delete</v-icon>
                                        </v-btn>
                                        <span>Remove Extension Group</span>
                                    </v-tooltip>
                                </v-flex>
                            </v-layout>
                            <v-divider></v-divider>
                            <v-card-text>
                                <div class="pa-1 pt-2 body-2 primary--text">
                                    Max Extension Duration (0 is unlimited)
                                </div>
                                <app-time-runner
                                        class="pl-3"
                                        :value="{
                                            months: ext.months,
                                            days: ext.days,
                                            hours: ext.hours,
                                            minutes: ext.minutes
                                        }"
                                        @input="updateExt(ext, $event, '')"
                                />
                                <div class="pa-1 pt-2 body-2 primary--text">
                                    Max Extension Interval (0 is unlimited)
                                </div>
                                <app-time-runner
                                        class="pl-3"
                                        :value="{
                                            months: ext.monthsMax,
                                            days: ext.daysMax,
                                            hours: ext.hoursMax,
                                            minutes: ext.minutesMax
                                        }"
                                        @input="updateExt(ext, $event, 'Max')"
                                />
                                <div class="pa-1 pt-2 body-2 primary--text">
                                    Escalation View Start (0 is disabled)
                                    <div class="caption primary--text text-xs-right">
                                        *Time From Start Of Escalation
                                    </div>
                                </div>
                                <app-time-runner
                                        class="pl-3"
                                        :value="{
                                            months: ext.monthsViewStart,
                                            days: ext.daysViewStart,
                                            hours: ext.hoursViewStart,
                                            minutes: ext.minutesViewStart
                                        }"
                                        @input="updateExt(ext, $event, 'ViewStart')"
                                />
                                <div class="pa-1 pt-2 body-2 primary--text">
                                    Escalation View End (0 is infinity)
                                    <div class="caption primary--text text-xs-right">
                                        *Time From Start Of Escalation
                                    </div>
                                </div>
                                <app-time-runner
                                        class="pl-3"
                                        :value="{
                                            months: ext.monthsViewEnd,
                                            days: ext.daysViewEnd,
                                            hours: ext.hoursViewEnd,
                                            minutes: ext.minutesViewEnd
                                        }"
                                        @input="updateExt(ext, $event, 'ViewEnd')"
                                />
                            </v-card-text>
                        </v-card>
                    </div>
                </template>

                <app-input-dlg
                        :show-dialog="showEditExtGroups"
                        title="Add New Extension Group"
                        :cancel-button="true"
                        :show-input-box="false"
                        @dismiss="showEditExtGroups = false"
                >
                    <template slot="body">
                        <!--v-model="returnObject.field"-->
                        <div class="pa-1 body-2 primary--text">
                            Select User Groups
                        </div>
                        <v-autocomplete
                                dense
                                :items="userGroups"
                                v-model="selectedEditExtGroups"
                                multiple
                                label="Select User Groups"
                                hide-details
                                single-line
                                color="primary"
                                item-text="description"
                                item-value="id"
                                class="pa-0 ma-0 pl-3"
                                chips
                                small-chips
                        >
                            <template slot="selection" slot-scope="data">
                                <v-chip
                                        :selected="data.selected"
                                        close
                                        class="chip--select-multi white--text"
                                        @input="selectedEditExtGroups = selectedEditExtGroups.filter(obj => obj != data.item.id)"
                                        color="primary"
                                        small
                                >
                                    {{ data.item.description }}
                                </v-chip>
                            </template>
                        </v-autocomplete>
                    </template>
                    <template slot="actions">
                        <v-btn
                                flat
                                color="secondary"
                                @click="addExtEditChanges()"
                                :disabled="selectedEditExtGroups == null || selectedEditExtGroups.length <= 0"
                        >
                            Add User Group
                        </v-btn>
                    </template>
                </app-input-dlg>

            </template>
        </div>
    </div>
</template>

<script>
    import {codeBaseMixin} from "../../../../../../codeBaseMixin";
    import TimeRunner from '../ManageDetailComponents/TimeRunner'
    import InputDlg from "../../../../../../components/General/InputDlg";

    export default {
        mixins: [codeBaseMixin],
        components: {
            appInputDlg: InputDlg,
            appTimeRunner: TimeRunner
        },
        props: {
            selectedStatus: Object,
            userInfo: Object,
            updateVisibilityFn: Function,
            userGroups: Array,
        },
        data() {
            return {
                loadingExtensions: false,
                extensions: [],
                showAddExtensions: false,
                selectedAddUserGroup: null,
                selectedAddUserGroupMaxTime: {
                    months: 0,
                    days: 0,
                    hours: 0,
                    minutes: 0,
                },
                selectedAddUserGroupMaxTimeInterval: {
                    months: 0,
                    days: 0,
                    hours: 0,
                    minutes: 0,
                },
                selectedAddUserGroupViewStart: {
                    months: 0,
                    days: 0,
                    hours: 0,
                    minutes: 0,
                },
                selectedAddUserGroupViewEnd: {
                    months: 0,
                    days: 0,
                    hours: 0,
                    minutes: 0,
                },
                addingUserGroup: false,
                removeExtID: 0,
                selectedEditExt: null,
                selectedEditExtGroups: [],
                showEditExtGroups: false,
            }
        },
        watch: {
            selectedStatus: {
                immediate: true,
                handler(status) {
                    this.loadingExtensions = true;
                    this.fetch({method: 'GET', url:`/workflowEditor/status/extensions/${this.userInfo.entityID}/${status.statusID}`})
                        .then((data) => {
                            this.extensions = data;
                            this.loadingExtensions = false;
                        })
                        .catch((e) => {
                            // eslint-disable-next-line
                            console.log(e)
                            this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
                            this.loadingExtensions = false;
                        })
                }
            }
        },
        methods: {

            addExtEditChanges() {
                this.selectedEditExt.fk_user_group = this.selectedEditExtGroups.join(',');
                this.showEditExtGroups = false;
                this.saveExtentionChanges(this.selectedEditExt);
            },

            addUserGroupToGroupRunStart(ext) {
                let tGroups = JSON.parse(`[${ext.fk_user_group}]`);
                this.selectedEditExtGroups = tGroups;
                this.selectedEditExt = ext;
                this.showEditExtGroups = true;
            },

            removeGroupFromGroup(ext, group) {
                let tGroups = JSON.parse(`[${ext.fk_user_group}]`);
                if (tGroups.length > 1) {
                    tGroups = tGroups.filter(obj => obj != group.id);
                    ext.fk_user_group = tGroups.join(',');
                    this.saveExtentionChanges(ext);
                } else {
                    this.showSnack('Info', 'At Least One User Group Is Required', 'Close', 'primary')
                }

            },

            saveExtentionChanges(ext) {
                this.fetch({method: 'PATCH', url:`/workflowEditor/status/extensions/${this.userInfo.entityID}/${ext.id}/${this.selectedStatus.workflowID}`, body: JSON.stringify(ext)})
                    .then(() => {
                        this.showSnack('Info', 'Changes Saved', 'Close', 'primary')
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e);
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
                    })
            },

            updateExt(ext, value, postFix) {
                ext['months' + postFix] = value.months;
                ext['days' + postFix] = value.days;
                ext['hours' + postFix] = value.hours;
                ext['minutes' + postFix] = value.minutes;
                this.saveExtentionChanges(ext)
            },

            removeExt(ext) {
                this.removeExtID = ext.id;
                this.fetch({method: 'DELETE', url:`/workflowEditor/status/extensions/${this.userInfo.entityID}/${ext.id}/${this.selectedStatus.workflowID}`})
                    .then(() => {
                        this.removeExtID = 0;
                        this.extensions = this.extensions.filter(obj => obj.id != ext.id);
                        this.showSnack('Info', 'Group Removed', 'Close', 'primary')
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e);
                        this.removeExtID = 0;
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
                    })

            },

            addExtensionGroup() {
                this.addingUserGroup = true;
                this.fetch({method: 'POST', url:`/workflowEditor/status/extensions/${this.userInfo.entityID}/${this.selectedStatus.workflowID}`, body: JSON.stringify({
                        fk_status_id: this.selectedStatus.statusID,
                        fk_user_group: this.selectedAddUserGroup,

                        months: this.selectedAddUserGroupMaxTime.months,
                        days: this.selectedAddUserGroupMaxTime.days,
                        hours: this.selectedAddUserGroupMaxTime.hours,
                        minutes: this.selectedAddUserGroupMaxTime.minutes,

                        monthsMax: this.selectedAddUserGroupMaxTimeInterval.months,
                        daysMax: this.selectedAddUserGroupMaxTimeInterval.days,
                        hoursMax: this.selectedAddUserGroupMaxTimeInterval.hours,
                        minutesMax: this.selectedAddUserGroupMaxTimeInterval.minutes,

                        monthsViewStart: this.selectedAddUserGroupViewStart.months,
                        daysViewStart: this.selectedAddUserGroupViewStart.days,
                        hoursViewStart: this.selectedAddUserGroupViewStart.hours,
                        minutesViewStart: this.selectedAddUserGroupViewStart.minutes,

                        monthsViewEnd: this.selectedAddUserGroupViewEnd.months,
                        daysViewEnd: this.selectedAddUserGroupViewEnd.days,
                        hoursViewEnd: this.selectedAddUserGroupViewEnd.hours,
                        minutesViewEnd: this.selectedAddUserGroupViewEnd.minutes,
                    })})
                    .then((data) => {
                        this.extensions.push(data);
                        this.addingUserGroup = false;
                        this.showAddExtensions = false;
                        this.showSnack('Info', 'Extension Group Added', 'Close', 'primary')
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e)
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
                        this.addingUserGroup = false;
                    })


            },

            addExtensionRun() {
                this.selectedAddUserGroup = null;
                this.selectedAddUserGroupMaxTime = {months: 0, days: 0, hours: 0, minutes: 0};
                this.selectedAddUserGroupMaxTimeDuration = {months: 0, days: 0, hours: 0, minutes: 0};
                this.selectedAddUserGroupViewStart = {months: 0, days: 0, hours: 0, minutes: 0};
                this.selectedAddUserGroupViewEnd = {months: 0, days: 0, hours: 0, minutes: 0};
                this.addingUserGroup = false;
                this.showAddExtensions = true;
            },

            saveChanges() {
                this.fetch({method: 'PATCH', url:`/workflowEditor/status/updateStatusMeta/${this.userInfo.entityID}/${this.selectedStatus.statusID}/${this.selectedStatus.workflowID}`, body: JSON.stringify(this.selectedStatus)})
                    .then(() => {
                        this.showSnack('Info', 'Changes Saved', 'Close', 'primary')
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e)
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                    })
            }
        },
        computed: {
            escalation: {
                // getter
                get: function () {
                    return this.intToBool(this.selectedStatus.escalationEnabled)
                },
                // setter
                set: function (newValue) {
                    this.selectedStatus.escalationEnabled = this.boolToInt(this.intToBool(newValue));
                }
            },

            extension: {
                // getter
                get: function () {
                    return this.intToBool(this.selectedStatus.enableExtension)
                },
                // setter
                set: function (newValue) {
                    this.selectedStatus.enableExtension = this.boolToInt(this.intToBool(newValue));
                }
            },

            availableUserGroups() {
                let usedGrouIDs = this.extensions.map(obj => {return obj.fk_user_group});
                return this.userGroups.reduce((list, group) => {

                    if (!usedGrouIDs.includes(group.id)) {
                        list = list.concat(group)
                    }

                    return list
                }, [])
            },

            escalationTime: {
                // getter
                get: function () {
                    return {
                        months: this.selectedStatus.escalationTTLMonths,
                        days: this.selectedStatus.escalationTTLDays,
                        hours: this.selectedStatus.escalationTTLHours,
                        minutes: this.selectedStatus.escalationTTLMinutes
                    }
                },
                // setter
                set: function (newValue) {
                    this.selectedStatus.escalationTTLMonths = newValue.months;
                    this.selectedStatus.escalationTTLDays = newValue.days;
                    this.selectedStatus.escalationTTLHours = newValue.hours;
                    this.selectedStatus.escalationTTLMinutes = newValue.minutes;
                }
            },


        }
    }
</script>

<style scoped>

</style>

