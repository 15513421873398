<template>
    <v-card
        :class="dashboard ? 'dashboardNote' : ''"
        flat
        :hover="dashboard"
        @dragstart="drag()"
        :draggable="true"
        @dragover="$event.preventDefault();"
        @drop="checkDrop($event)"
    >
        <v-layout row wrap>
            <v-flex xs12 v-if="dashboard" class="pa-1">
                <span class="body-1 font-weight-regular">{{ note.stepName }}</span>
            </v-flex>
            <v-flex xs12 class="px-1">
                <v-layout align-center justify-start row class="pb-1">
                    <v-flex shrink v-if="note.noteType == 0 && scrollContentHeight >= 300">
                        <v-tooltip top>
                            <v-icon
                                slot="activator"
                                style="cursor: pointer"
                                color="primary"
                                class="pa-0 ma-0"
                                @click="noteContentView = true"
                            >
                                fullscreen
                            </v-icon>
                            <span>Click To Expand Note Content</span>
                        </v-tooltip>
                    </v-flex>

                    <v-flex shrink class="font-weight-medium text--small pt-1" style="white-space: nowrap">
                        {{ note.authorID <= 0 ? 'System' : note.authorFullName }}
                    </v-flex>
                    <v-flex xs12 style="font-size: 0.8em" class="font-weight-regular text-xs-right">
                        {{ getLongDate(note.created) }}
                    </v-flex>
                </v-layout>
            </v-flex>
            <!--Text Note-->
            <v-flex v-if="note.noteType == 0" xs12 class="caption pt-1" @click="noteContentView = true" style="max-height: 300px; overflow-y: hidden; cursor: pointer">

                <div class="pa-1" ref="noteContent" v-html="noteLookup"></div>
                <!--</div>-->

            </v-flex>
            <!--Checklist Note-->
            <v-flex v-if="note.noteType == 1" >
                <template v-for="(check, i) in checks">

                    <v-tooltip top :key="check.description + i">
                        <v-layout slot="activator" class="pr-1" justify-start row >
                            <v-flex shrink>
                                <v-icon
                                    small
                                    color="primary"
                                    style="cursor: pointer"
                                    @click="updateCheck(check, i)"
                                >{{ check.value == false ? 'check_box_outline_blank ' : 'check_box' }}</v-icon>
                            </v-flex>
                            <v-flex shrink class="pl-1">
                                <span class="caption font-weight-light"> {{ check.description }}</span>
                            </v-flex>
                        </v-layout>

                        <!--<span>Some Words</span>-->

                        <span> {{ check.updateUserID != 0 ? check.updatedUserName + ' - ' + getLongDate(check.updateDate) : 'No Value Change'}}</span>
                    </v-tooltip>

                </template>
            </v-flex>
            <!--Voice Note-->
            <v-flex v-if="note.noteType == 2" xs12 class="text-xs-center">
                <vuetify-audio :file="serverAddress + '/voicenotestore/' + userInfo.entityID + '/' + note.lookup" ></vuetify-audio>
            </v-flex>
            <v-flex class="pt-1" xs12 v-if="viewFlags.length > 0">
            </v-flex>
        </v-layout>

        <v-layout row wrap style="">
            <v-flex xs12>

                <template v-for="(flag) in viewFlags">
                    <v-tooltip top :key="flag.viewID">
                        <template slot="activator">
                            <v-avatar
                                class="white--text ma-1" :class="flag.viewFlag == 0 ? 'tRed' : 'tGreen'"
                                size="26"
                                style="position: relative"
                            >
                                <span class="text--small">{{ getInitials(flag.userFullName) }}</span>
                            </v-avatar>
                        </template>
                        <span>{{ flag.userFullName }}</span>
                    </v-tooltip>
                </template>
            </v-flex>

            <template v-for="(flag) in viewFlags">
                <template v-if="flag.userID == userInfo.userID">
                    <v-flex :key="'markAsRead' + flag.viewID">

                        <v-layout align-center row justify-space-between>
                            <v-flex shrink>
                                <v-layout row align-center>
                                    <v-flex class="pl-1" shrink v-if="dashboard">
                                        <v-icon color="primary" style=" position: relative">
                                            note
                                        </v-icon>
                                    </v-flex>
                                    <v-flex class="pl-2" shrink>
                                        <v-icon
                                            small
                                            :color="flag.viewFlag == 0 ? 'secondary' : 'primary'"
                                            :disabled="markingAsRead"
                                            style="cursor: pointer;  position: relative"
                                            @click="updateViewFlag(flag)"
                                        >{{ flag.viewFlag == 0 ? 'check_box_outline_blank ' : 'check_box' }}
                                        </v-icon>
                                    </v-flex>
                                    <v-flex shrink class="pl-1">
                                        <span style=" position: relative" class="text--small" > {{flag.viewFlag == 0 ? ' Mark as Read' : ' Mark as Unread'}}</span>
                                    </v-flex>
                                </v-layout>
                            </v-flex>
                            <v-flex shrink class="pr-1 pb-1" v-if="viewFlags.length > 0 && allowReply && typeof note.stepID !== 'undefined' && note.stepID !== -1">
                                <v-btn
                                    flat
                                    @click="replyNoteFunction"
                                    color="secondary"
                                    small
                                    class="ma-0"
                                    style="position: relative"
                                >
                                    <!--replyToNote(viewFlags.map((obj) => {return obj.userID}).filter(obj => obj != userInfo.userID).concat([parseInt(note.authorID)]))-->
                                    <v-icon small left>reply</v-icon>
                                    <span>Reply</span>
                                </v-btn>
                            </v-flex>
                        </v-layout>
                    </v-flex>
                </template>
            </template>
        </v-layout>
        <v-layout column>
            <template v-if="note.fk_linkedDocs != null && note.fk_linkedDocs != ''">
                <template v-if="!dashboard ? task.structure.documents.length > 0 : attachedDocs.length > 0">
                    <template v-if="note.fk_linkedDocs != null">
                        <template v-for="document in !dashboard ? task.structure.documents.filter(obj => JSON.parse(note.fk_linkedDocs).includes(obj.documentDBID)) : attachedDocs.filter(obj => note.fk_linkedDocs.includes(obj.documentDBID))">
                            <v-flex shrink class="pa-1">
                                <app-document-container
                                    :document="document"
                                    :user-info="userInfo"
                                    :dense="true"
                                    :noteDoc="dashboard"
                                />
                            </v-flex>
                        </template>
                    </template>
                </template>
            </template>
        </v-layout>


        <v-dialog v-model="noteContentView" fullscreen persistent>



            <div class="white scrollable fill-height" v-if="printView">
                <v-layout row align-center>
                    <v-flex xs12 class="subheading font-weight-bold pa-2">
                        {{note.authorFullName}}
                    </v-flex>
                    <v-flex shrink style="white-space: nowrap" class="pa-2">
                        {{getLongDate(note.created)}}
                    </v-flex>
                </v-layout>
                <v-divider></v-divider>
                <template v-if="viewFlags.length > 0">
                    <div>
                        <div class="pa-2"><strong>To:</strong> {{viewFlags.map(obj => {return `${obj.userFullName}${obj.viewFlag == 0 ? `` : '(Read)'}`}).join(', ')}}</div>
                    </div>
                    <v-divider></v-divider>
                </template>
                <div v-html="noteLookup" class="pa-3"></div>
            </div>

            <v-card flat v-else class="fill-height">
                <v-layout column fill-height class="scrollable">
                    <v-flex  shrink>
                        <v-card-title class="py-2 pr-2 primary">
                            <span class="title font-weight-regular white--text">{{note.authorFullName}}</span>
                            <v-spacer></v-spacer>
                            <span class="body font-weight-light white--text pr-2">{{ getLongDate(note.created) }}</span>
                            <v-btn flat icon color="white" class="pa-0 ma-0" @click="printNote">
                                <v-icon>print</v-icon>
                            </v-btn>
                            <div v-show="false" ref="printLink"></div>

                            <v-btn flat icon color="white" class="pa-0 ma-0" @click="noteContentView = false">
                                <v-icon>close</v-icon>
                            </v-btn>
                        </v-card-title>
                        <v-divider></v-divider>
                    </v-flex>
                    <v-flex xs12 class="scrollable pa-2">
                        <div v-html="noteLookup"></div>
                    </v-flex>
                    <v-flex shrink>
                        <template v-for="(flag) in viewFlags">
                            <v-tooltip top :key="flag.viewID">
                                <template slot="activator">
                                    <v-avatar
                                        class="white--text ma-1" :class="flag.viewFlag == 0 ? 'tRed' : 'tGreen'"
                                        size="26"
                                    >
                                        <span class="text--small">{{ getInitials(flag.userFullName) }}</span>
                                    </v-avatar>
                                </template>
                                <span>{{ flag.userFullName }}</span>
                            </v-tooltip>
                        </template>

                        <template v-for="(flag) in viewFlags">
                            <template v-if="flag.userID == userInfo.userID">
                                <v-flex :key="'markAsRead' + flag.viewID">
                                    <v-layout align-center row justify-end>
                                        <v-flex shrink>
                                            <v-layout row align-center class="pr-2 pb-2">
                                                <v-flex class="" shrink>
                                                    <v-icon
                                                        small
                                                        :color="flag.viewFlag == 0 ? 'secondary' : 'primary'"
                                                        style="cursor: pointer"
                                                        @click="updateViewFlag(flag)"
                                                    >{{ flag.viewFlag == 0 ? 'check_box_outline_blank ' : 'check_box' }}
                                                    </v-icon>
                                                </v-flex>
                                                <v-flex shrink class="pl-1">
                                                    <span class="text--small"> {{flag.viewFlag == 0 ? ' Mark as Read' : ' Mark as Unread'}}</span>
                                                </v-flex>
                                            </v-layout>
                                        </v-flex>
                                    </v-layout>
                                </v-flex>
                            </template>
                        </template>
                    </v-flex>
                </v-layout>

                <!--                <v-card-title class="pa-1 pl-2 primary">-->
                <!--                    <span class="subheading font-weight-light white&#45;&#45;text">{{note.authorFullName}}</span>-->
                <!--                    <v-spacer></v-spacer>-->
                <!--                    <span class="body font-weight-light white&#45;&#45;text">{{ getLongDate(note.created) }}</span>-->
                <!--                    <v-btn-->
                <!--                            @click="noteContentView = false"-->
                <!--                            small-->
                <!--                            icon-->
                <!--                            flat-->
                <!--                            color="white"-->
                <!--                            class="pa-0 ma-0"-->
                <!--                    >-->
                <!--                        <v-icon small>close</v-icon>-->
                <!--                    </v-btn>-->
                <!--                </v-card-title>-->

                <!--                <v-card-text style="max-height: 600px; overflow-y: auto" class="scroll">-->
                <!--                    <div v-html="noteLookup"></div>-->
                <!--                </v-card-text>-->




            </v-card>
        </v-dialog>

    </v-card>
</template>

<script>

import { codeBaseMixin } from '../../../../codeBaseMixin';
import { eventBus } from '../../../../main';
import VuetifyAudio from 'vuetify-audio';
import DocumentContainer from "../Documents/DocumentContainer";

export default {
    mixins: [ codeBaseMixin ],
    props: {
        userInfo: Object,
        task: Object,
        note: Object,
        dashboard: {
            default: false,
            type: Boolean
        },
        attachedDocs: Array,
        allowReply: {
            default: true,
            type: Boolean
        }
    },
    components: {
        appDocumentContainer: DocumentContainer,
        'vuetify-audio': VuetifyAudio,
    },
    data() {
        return {
            myFlag: {},
            keyRender: true,
            showAddNote: false,
            scrollContentHeight: null,
            noteContentView: false,
            replyUsers: [],
            markingAsRead: false,
            printView: false
        }
    },
    mounted() {
        if (this.note.noteType == 0) {
            // setTimeout(() => {
            //     this.scrollContentHeight = this.$refs.noteContent.clientHeight
            // }, 2)

        }
    },
    methods: {
        printNote() {

            this.printView = true;
            this.$nextTick(() => {
                window.print();
                this.$nextTick(() => {
                    this.printView = false;
                })
            })

            // let html="<html>";
            // html+= this.$refs.printableNote.innerHTML;
            //
            // html+="</html>";
            //
            // let printWin = window.open('','','left=0,top=0,width=1,height=1,toolbar=0,scrollbars=0,status  =0');
            // printWin.document.write(html);
            // printWin.document.close();
            // printWin.focus();
            // printWin.print();
            // printWin.close();
        },

        replyNoteFunction() {
            this.$emit('reply', {
                reply: this.viewFlags.map((obj) => {return obj.userID}).filter(obj => obj != this.userInfo.userID).concat(this.note.authorID),
                step: this.note.stepID,
                noteID: this.note.noteID})
        },

        checkDrop(event) {
            let data = JSON.parse(event.dataTransfer.getData("text"));
            if (data != null && typeof data.dragType != "undefined" && data.dragType != null && data.dragType != '' && data.dragType == 'document') {
                if (this.note.fk_linkedDocs == null || this.note.fk_linkedDocs == '') {
                    this.note.fk_linkedDocs = '[]'
                }

                this.note.fk_linkedDocs = JSON.stringify(JSON.parse(this.note.fk_linkedDocs).concat(data.data));

                this.fetch({method: 'PATCH', url:`/tasks/newNoteLink/${this.userInfo.entityID}/${this.note.noteID}`, body: JSON.stringify({link: this.note.fk_linkedDocs})})
                    .then((data) => {
                        this.showSnack('Info', 'Doc Linked To Note', 'Close', 'primary')
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e)
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                    });
            }
        },

        drag: function () {
            eventBus.$emit('noteDrag', this.note);
        },

        noteReplyFinalize() {
            // this.viewFlags.forEach((flag) => {
            //     if (flag.userID == this.userInfo.userID) {
            //         this.updateViewFlag(flag);
            //     }
            // })
        },

        replyToNote(users) {
            this.replyUsers = users;
            this.showAddNote = true;
        },

        updateCheck(check, i) {

            // eventBus.$emit('ignoreNextDashboard', true);

            let updateCheck = check;

            updateCheck.value = !updateCheck.value;
            updateCheck.updateUserID = this.userInfo.userID;
            updateCheck.updatedUserName = this.userInfo.name + ' ' + this.userInfo.surname;
            let now = new Date();
            updateCheck.updateDate = now;

            let curChecks = this.checks;

            curChecks[i] = updateCheck;

            this.checks = curChecks;

            this.$forceUpdate();

            this.fetch({method: 'PATCH', url:`/tasks/updateNotelookup/${this.userInfo.entityID}/${this.userInfo.userID}/${this.note.noteID}`, body: this.note.lookup})
                .catch((e) => {
                    // eslint-disable-next-line
                    console.log(e)
                    this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                });
            // update Call
            this.task.refStructure = this.task.structure;
        },

        setMyFlag(value) {
            this.myFlag = value;
        },
        updateViewFlag(viewflag) {
            this.markingAsRead = true;
            // eventBus.$emit('ignoreNextDashboard', true);
            //Get View Flags Var
            this.myFlag = !this.myFlag;
            let flags = this.viewFlags;
            flags.forEach((flag) => {
                if (flag.viewID == viewflag.viewID) {
                    flag.viewFlag = this.boolToInt(this.myFlag)
                }
            });
            this.viewFlags = flags;
            this.$forceUpdate();

            this.fetch({method: 'PATCH', url:`/tasks/updateNoteRead/${this.userInfo.entityID}/${this.userInfo.userID}/${viewflag.viewID}/${this.boolToInt(this.myFlag)}`})
                .then(() => {
                    this.$nextTick(() => {
                        this.markingAsRead = false;
                    })

                    this.task.refStructure = this.task.structure;
                    if (this.myFlag == true) {
                        eventBus.$emit('removeNoteFromDash', {taskID: this.task.taskID, noteID: this.note.noteID})
                    }
                })
                .catch((e) => {
                    // eslint-disable-next-line
                    console.log(e)
                    this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                })
        }
    },
    computed: {

        noteLookup() {
            return this.note.lookup.replace(/data-v-.*?(="")/g, '');
        },

        viewFlags: {
            get: function () {
                if (this.note.noteViewFlags != null) {

                    try {
                        let flags = JSON.parse(this.note.noteViewFlags);
                        if (flags.length > 0) {
                            flags.forEach((flag) => {
                                if (flag.userID == this.userInfo.userID) {
                                    this.setMyFlag(this.intToBool(flag.viewFlag))
                                }
                            })
                        }
                        return flags;
                    } catch (e) {
                        return []
                    }


                } else {
                    return []
                }
            },
            // setter
            set: function (newValue) {
                this.note.noteViewFlags = JSON.stringify(newValue)
            }
        },

        checks: {
            get: function () {
                try {
                    if (this.note.lookup != null) {
                        return JSON.parse(this.note.lookup);
                    } else {
                        return []
                    }
                } catch (e) {
                    return []
                }

            },
            // setter
            set: function (newValue) {
                this.note.lookup = JSON.stringify(newValue)
            }
        },
    }
}
</script>

<style scoped>

.dashboardNote {
    border-left: 5px solid var(--v-primary-base);
}
audio:focus { outline: none; }
</style>
