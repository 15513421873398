<template >
    <div>
        <v-layout row align-center>
            <v-flex xs12>
                <v-text-field
                        label="ID Number"
                        single-line
                        v-model="idNo"
                        color="primary"
                        hide-details
                        style="width: 100%"
                        @keydown.enter="generateReport"
                        :readonly="savedIDNo != null"
                        class="ma-0 pa-0"
                ></v-text-field>
            </v-flex>
            <v-flex shrink class="pl-2 pr-1" v-if="savedIDNo == null">
                <v-tooltip left>
                    <v-btn
                            small
                            icon
                            flat
                            color="secondary"
                            class="pa-0 ma-0"
                            :disabled="!allowEdit || !allowGenerate || (idNo == null ? true : idNo.length !== 13) || savedIDNo == idNo || generatingReport"
                            :loading="generatingReport"
                            slot="activator"
                            @click="generateReport"
                    >
                        <v-icon>cloud_download</v-icon>
                    </v-btn>
                    <span>{{!allowEdit ? 'Read Only Field' : (!allowGenerate ? 'Read Only (User Not Setup)' : 'Generate Credit Report')}}</span>
                </v-tooltip>
            </v-flex>
        </v-layout>
        <div v-if="generatingReport" style="width: 100%" class="pa-2 text-xs-center">
            <v-progress-circular
                    :size="40"
                    :width="5"
                    color="primary"
                    indeterminate
            />
        </div>
        <div v-else class="pa-1">
            <v-card class="pa-1" v-if="valueDocument != null">
                <app-document-container
                        :document="valueDocument"
                        :user-info="userInfo"
                />
            </v-card>

        </div>
    </div>
</template>

<script>

    import {codeBaseMixin} from "../../../../../../codeBaseMixin";
    import DocumentContainer from "../../../Documents/DocumentContainer";

    export default {
        mixins:[codeBaseMixin],
        components: {
            appDocumentContainer: DocumentContainer
            // appEditor: tinyEditor,
        },
        props: {
            stepID: Number,
            userInfo: Object,
            value: Object,
            stepEditable: Boolean,
            task: Object,
        },

        data() {
            return {
                bindData: true,
                showEdit: false,
                idNo: null,
                generatingReport: false
            }
        },

        watch: {
            "value": {
                deep: true,
                immediate: true,
                handler(val) {
                    try {
                        let tObj = JSON.parse(val.value);
                        this.idNo = tObj.idNo
                    } catch (e) {
                        console.log(e, val)
                    }
                }
            }
        },

        methods: {
            updateValue(newVal) {
                this.value.value = newVal
            },

            generateReport() {
                if (this.allowEdit && this.allowGenerate) {

                    if (this.idNo.length === 13) {
                        this.generatingReport = true;
                        this.fetch({method: 'GET', url:`/talentIndex/generateAndSaveReport/${this.userInfo.entityID}/${this.userInfo.userID}/${this.task.taskID}/${this.stepID}/${this.value.fieldID}/${this.idNo}`})
                            .then((data) => {
                                this.task.structure.documents.push(data.doc);
                                Object.assign(this.value, data.field);
                                this.value.saveStatus = 1;
                                this.generatingReport = false;
                            })
                            .catch((e) => {
                                // eslint-disable-next-line
                                console.log(e)
                                this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                                this.generatingReport = false;
                            })
                    } else {
                        this.showSnack('Error', 'Invalid ID No', 'Close', 'red')
                    }
                } else {
                    this.showSnack('Error', 'Access Denied', 'Close', 'red')
                }
            }
        },

        computed: {

            valueDocument() {
                try {
                    return this.task.structure.documents.filter(obj => obj.documentDBID == this.documentID).pop();
                } catch (e) {
                    // eslint-disable-next-line
                    console.log(e)
                    return null;
                }
            },

            allowEdit() {
                return this.stepEditable && this.value.editable == 1;
            },

            allowGenerate() {
                return this.userInfo.localValues.talentIndexUserEnabled == 1 ? true : false;
            },

            savedIDNo() {
                try {
                    let tObj = JSON.parse(this.value.value);
                    return tObj.idNo
                } catch (e) {
                    // eslint-disable-next-line
                    console.log(e)
                    return null
                }
            },

            documentID() {
                try {
                    let tObj = JSON.parse(this.value.value)
                    return tObj.docID
                } catch (e) {
                    // eslint-disable-next-line
                    console.log(e)
                    return null;
                }
            },

            blockInput() {
                if (this.linkedFieldInfo != null) {
                    //Check If Select Has A Value
                    if (this.fieldSetSelectField.value != null && this.fieldSetSelectField.value != '' && parseInt(this.fieldSetSelectField.value) > 0) {
                        return false
                    } else {
                        this.updateValue(null)
                        return true;
                    }
                } else {
                    return false
                }
            },
        }
    }
</script>

<style scoped>

</style>
