<template>
    <v-layout row fill-height style="background: var(--page-back)">

        <div class="overlay" v-if="showLinkGmail" @click="$emit('showLinkGmailChange')" style="z-index: 1"></div>

        <!--Login To Gmail Container-->
        <v-flex
                xs12
                v-if="showLogin"
                fill-height
        >
            <v-layout row justify-space-around align-center fill-height>
                <v-flex xs6 md4 lg3>
                    <v-card>
                        <v-card-title class="pb-0">

                            <v-img
                                    src="https://ssl.gstatic.com/ui/v1/icons/mail/rfr/logo_gmail_lockup_default_1x.png"
                                    srcset="https://ssl.gstatic.com/ui/v1/icons/mail/rfr/logo_gmail_lockup_default_1x.png 1x, https://ssl.gstatic.com/ui/v1/icons/mail/rfr/logo_gmail_lockup_default_2x.png 2x "
                                    contain
                                    style="max-width: 100px;"
                                    class="ml-3 mb-3"
                            ></v-img>
                        </v-card-title>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn @click="getKey" flat color="secondary">
                                <span>Login To Gmail</span>
                                <v-icon color="secondary" right>navigate_next</v-icon>
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-flex>
            </v-layout>
        </v-flex>

        <v-flex v-if="!showLogin" xs3 v-show="!showLinkGmail">
            <v-layout column fill-height>
                <!--Compose Mail Button-->
                <v-flex shrink class="text-xs-center pt-3">
                    <v-btn
                            @click="$refs.createMail.init(0)"
                            color="red"
                            flat
                    >
                        <img src="../../../public/icons/gmail.svg" style="width: 38px" class="pr-2"/>
                        <span>Compose Message</span>
                    </v-btn>
                </v-flex>
                <v-flex xs12 class="scroll" style="overflow-y: auto">
                    <!--Gmail Labels-->
                    <app-label-bar :labels="labels" :selectedLabel="selectedLabel" @labelClicked="getMessages"/>
                </v-flex>
            </v-layout>
        </v-flex>
        <!--Gmail Message Bar-->
        <v-flex v-if="!showLogin" v-show="!mailboxView" :xs3="!showLinkGmail" :xs4="showLinkGmail">
            <v-layout column fill-height class="pl-1">
                <!--Message Search Bar-->
                <v-flex shrink>
                    <app-search-bar @simpleSearch="mailSearch('simple')" @fullSearch="mailSearch('full')"
                                    :searchOptions="searchOptions" v-show="showSearch" id="searchDiv"/>
                </v-flex>
                <!--Message Bar-->
                <v-flex xs12 class="scroll" style="overflow-y: auto">
                    <app-message-bar
                            @loadNextPage="loadNextPage"
                            :nextPage="nextPage"
                            :messages="messages"
                            @messageSelected="messageSelected"
                            :starFn="starMessage"
                            :unstarFn="unstarMessage"
                            :archiveFn="archiveMessage"
                            :selectedMessage="selectedMessage"
                    />
                </v-flex>
            </v-layout>
        </v-flex>

        <v-flex v-if="!showLogin" :xs6="!showLinkGmail" :xs8="showLinkGmail">
            <!--Select Message Gmail-->
            <v-layout row justify-space-around align-center fill-height
                      v-if="selectedMessage == null && showLogin == false && !mailboxView">
                <v-flex xs10 md8 lg4>
                    <div class="text-xs-center">
                        <v-img class="mx-auto" height="150px" width="150px" contain src="/no_mail.svg"></v-img>
                        <br>
                        <span class="title font-weight-thin">Please Select a Mail</span>
                    </div>
                </v-flex>
            </v-layout>

            <template v-if="selectedMessage != null && showLogin == false && !mailboxView">
                <v-layout column fill-height class="pl-1">

                    <v-flex xs12 style="overflow-y: auto" class="pa-1">
                        <v-card flat class="fill-height" style="overflow-y: auto; background: var(--page-back)">
                            <v-layout column fill-height style="overflow-y: auto">

                                <v-flex xs12 class="scroll pl-2 overflow-y-hidden">
                                    <!--Message Body-->

                                    <e-m-l-viewer :filename="selectedMessage.filename" :cache="true">

                                        <template slot="metaTop">
                                            <!--Create Task-->
                                            <v-tooltip top
                                                       v-if="typeof userInfo.createTask != 'undefined' && intToBool(userInfo.createTask)">
                                                <v-btn
                                                        icon
                                                        dark
                                                        small
                                                        color="secondary"
                                                        @click="createNewTask()"
                                                        slot="activator"
                                                        class="pt-0 mx-1 mt-1 elevation-5"
                                                >
                                                    <v-icon small>add</v-icon>
                                                </v-btn>
                                                <span>Create Task</span>
                                            </v-tooltip>
                                            <!--Link To Task-->
                                            <v-tooltip top>
                                                <v-btn
                                                        icon
                                                        dark
                                                        small
                                                        color="secondary"
                                                        @click="linkMail()"
                                                        slot="activator"
                                                        class="pt-0 mx-1 mt-1 elevation-5"
                                                >
                                                    <v-icon small>link</v-icon>
                                                </v-btn>
                                                <span>Link To Task</span>
                                            </v-tooltip>
                                            <!--Reply-->
                                            <v-tooltip top>
                                                <v-btn
                                                        icon
                                                        dark
                                                        small
                                                        color="primary"
                                                        @click="$refs.createMail.init(1, selectedMessage.filename, 'cache')"
                                                        slot="activator"
                                                        class="pt-0 mx-1 mt-1 elevation-5"
                                                >
                                                    <v-icon small>reply</v-icon>
                                                </v-btn>
                                                <span>Reply</span>
                                            </v-tooltip>
                                            <!--Reply To All-->
                                            <v-tooltip top>
                                                <v-btn
                                                        icon
                                                        dark
                                                        small
                                                        color="primary"
                                                        @click="$refs.createMail.init(2, selectedMessage.filename, 'cache')"
                                                        slot="activator"
                                                        class="pt-0 mx-1 mt-1 elevation-5"
                                                >
                                                    <v-icon small>reply_all</v-icon>
                                                </v-btn>
                                                <span>Reply To All</span>
                                            </v-tooltip>
                                            <!--Forward-->
                                            <v-tooltip top>
                                                <v-btn
                                                        icon
                                                        dark
                                                        small
                                                        color="primary"
                                                        @click="$refs.createMail.init(3, selectedMessage.filename, 'cache')"
                                                        slot="activator"
                                                        class="pt-0 mx-1 mt-1 elevation-5"
                                                >
                                                    <v-icon small>forward</v-icon>
                                                </v-btn>
                                                <span>Forward Mail</span>
                                            </v-tooltip>

                                            <!--Labels-->
                                            <template v-for="(labelID, i) in selectedMessage.labelIds">
                                                <template v-for="(labelObj, k) in labels">
                                                    <v-chip
                                                            v-if="labelID === labelObj.id && typeof labelObj.displayName !== 'undefined' && labelObj.id !== 'STARRED' && labelObj.id !== 'SENT'"
                                                            :key="i + '-' + k"
                                                            close
                                                            label
                                                            dark
                                                            :color="typeof labelObj.color !== 'undefined' ? labelObj.color.backgroundColor : 'grey'"
                                                            small
                                                            class="elevation-5"
                                                            @input="removeLabelFromMessage(selectedMessage.gmailID, labelObj.id)"
                                                    >
                                                        <v-icon small class="pr-2">label</v-icon>
                                                        {{ labelObj.displayName }}
                                                    </v-chip>
                                                </template>
                                            </template>
                                            <!--Tasks-->
                                            <template
                                                    v-if="typeof selectedMessage.tasks != 'undefined' && selectedMessage.tasks.length > 0 && !resetTasks">
                                                <template v-for="task in selectedMessage.tasks">
                                                    <v-menu open-on-hover offset-y nudge-left="150px" :key="task">
                                                        <v-chip
                                                                slot="activator"
                                                                small
                                                                label
                                                                color="secondary"
                                                                text-color="white"
                                                                class="elevation-5"
                                                        >
                                                            <v-icon small class="pr-2">assignment_turned_in</v-icon>
                                                            Task #{{ task }}
                                                        </v-chip>
                                                        <div style="width: 400px; background: var(--card-back); cursor: pointer">
                                                            <task-loading-container
                                                                    v-if="typeof loadedTasks[task] == 'undefined' || loadedTasks[task] == null"
                                                                    :task-id="task"
                                                            />
                                                            <task-container
                                                                    v-else
                                                                    :userInfo="userInfo"
                                                                    :task="loadedTasks[task]"
                                                                    :taskBar="false"
                                                                    :showNav="false"
                                                                    @taskSelected="taskSelected(loadedTasks[task])"
                                                            />
                                                        </div>
                                                    </v-menu>
                                                </template>
                                            </template>

                                            <!--Quick Tasks-->
                                            <template
                                                    v-if="typeof userInfo.createTask != 'undefined' && intToBool(userInfo.createTask)">
                                                <v-tooltip
                                                        top
                                                        v-for="(qt, i) in [...userInfo.quickTasks].sort((a, b) => a.config.order - b.config.order)"
                                                        :key="i"
                                                >
                                                    <v-btn
                                                            icon
                                                            dark
                                                            outline
                                                            small
                                                            color="secondary"
                                                            @click="createNewTask(qt.config)"
                                                            slot="activator"
                                                            class="pt-0 mx-1 mt-1 elevation-5"
                                                    >
                                                        <v-icon small>{{ qt.config.icon }}</v-icon>
                                                    </v-btn>
                                                    <span>Create {{ qt.config.description }} Task</span>
                                                </v-tooltip>
                                            </template>
                                        </template>
                                    </e-m-l-viewer>
                                </v-flex>
                            </v-layout>
                        </v-card>
                    </v-flex>
                </v-layout>
            </template>

        </v-flex>

        <!--Create Task Mail-->
        <app-new-task-dlg
                v-if="selectedMessage != null"
                :show-dialog="showCreateFromMail"
                :user-info="userInfo"
                @dismiss="showCreateFromMail = false"
                :mail-task="true"
                :mail-i-d="selectedMessage.gmailID"
                :mail="selectedMessage"
                :folder-groups="folderGroups"
                @pushTask="pushTask($event)"
                :local-data="localData"
                :show-sync="showSync"
                :get-task-header="getTaskHeader"
                :quick-task="quickTaskConfig"
                @clearQuick="quickTaskConfig = null"
        />

        <create-mail
                v-model="showCompose"
                ref="createMail"
        />

        <!--        <app-compose-mail-->
        <!--            v-if="showCompose"-->
        <!--                :show-dialog="showCompose"-->
        <!--                :user-info="userInfo"-->
        <!--                @dismiss="showCompose = false"-->
        <!--                :mail-config="mailConfig"-->
        <!--                :link-to-task="false"-->
        <!--                :compose-type="mailComposeType"-->
        <!--                :src-mail="mailComposeMailSrc"-->
        <!--                :src-mail-body-src="mailComposeMailSrcBody"-->
        <!--                :src-mail-path="mailComposeMailPath"-->
        <!--                :startWithDraft="false"-->
        <!--        />-->
    </v-layout>
</template>

<script>
import LabelBar from './components/LabelBar.vue';
import MessageBar from './components/MessageBar.vue';
import {eventBus} from '../../main';
import format from 'date-fns/format';
import SearchBar from './components/SearchBar.vue';
import {codeBaseMixin} from '../../codeBaseMixin';
import NewTaskDlg from "../../Tasks/NewTask/NewTaskDlg";
import EMLViewer from "@/Mail/EML Viewer/EMLViewer";
import TaskLoadingContainer from "@/components/TaskContainer/TaskLoadingContainer";
import TaskContainer from "@/components/TaskContainer/TaskContainer";
import CreateMail from "@/Mail/Create Mail/CreateMail";

export default {
    mixins: [codeBaseMixin],
    components: {
        CreateMail,
        TaskContainer,
        TaskLoadingContainer,
        EMLViewer,
        appNewTaskDlg: NewTaskDlg,
        appLabelBar: LabelBar,
        appMessageBar: MessageBar,
        appSearchBar: SearchBar,
    },
    props: {
        showLinkGmail: Boolean,
        curView: String,
        userInfo: Object,
        taskData: {
            type: Object,
            default: () => {
                return {
                    tasks: [],
                    taskLoading: true,
                    filterSettings: {
                        userOption: 0,
                        stateOption: 0,
                        orderOption: 0,
                        groupOption: 0,
                        starOption: 0
                    }
                }
            }
        },
        folderGroups: Array,
        mailConfig: Object,
        localData: Object,
        showSync: Boolean,
        getTaskHeader: Function,
    },
    data() {
        return {
            showCompose: false,
            show: true,
            selectedSignature: 0,
            checkCount: 0,
            OAuthDetails: {
                clientId: '647192441905-1lhskun1k86v9tu2bsjpabl309vu2ujs.apps.googleusercontent.com'
            },
            labelsPending: false,
            labels: [],
            messages: [],
            maxHeight: 0,
            showLogin: false,
            selectedLabel: {id: 'INBOX'},
            selectedMessage: null,
            attHeight: 0,
            messageHeaderHeight: 0,
            searchHeight: 0,
            showSearch: true,
            nextPage: null,
            request: "",
            searchOptions: {
                searchText: "",
                from: "",
                to: "",
                subject: "",
                hasWords: "",
                noWords: "",
                hasAttachment: false,
                searchIn: "All Mail",
                starred: false
            },
            messageHtml: "",
            composeDialog: false,
            selected: [],
            selectedCc: [],
            contacts: [],
            title: '',
            content: '',
            subjectContent: '',
            gmailReady: {result: false},
            myFiles: [],
            myFileCount: 0,
            contactsPending: false,
            replyDialog: false,
            mailboxView: false,
            mailboxes: [],
            mailboxesLoading: false,
            selectedMailbox: 0,
            mailboxMessages: [],
            mailboxSearchOptions: {
                searchText: "",
                from: "",
                to: "",
                subject: "",
                hasWords: "",
                hasAttachment: false,
            },
            mailboxPagination: {
                totalPages: 0,
                curPage: 0
            },
            mailboxSelectedMessage: null,
            mailboxMessageHtml: '',
            mailboxRemoveMailSnack: false,
            updateTaskLinkRunning: false,

            showCreateFromMail: false,
            showCreateFromMailBox: false,
            showEmbeddedAttachments: false,
            showEmbeddedAttachmentsMailbox: false,
            mailComposeType: 0,
            mailComposeMailSrc: null,
            mailComposeMailSrcBody: null,
            mailComposeMailPath: null,
            quickTaskConfig: null,

            resetTasks: false,
            loadedTasks: [],
        }
    },
    methods: {
        createNewTask(qtConfig = null) {
            this.quickTaskConfig = qtConfig;
            this.showCreateFromMail = true;
        },

        taskSelected(task) {
            eventBus.$emit('gotoTask', task)
        },
        pushTask(task) {
            this.$emit('pushTask', task)
        },
        iframeReset(event, frame) {
            let doc = document.getElementById(frame);

            doc.html = this.messageHtml;
            let content = (doc.contentWindow || doc.contentDocument)

            if (content.document) content = content.document;

            doc = content;

            let head = doc.head;

            //Set The Style
            let style = doc.createElement('style');
            head.appendChild(style);
            style.type = 'text/css';
            style.appendChild(document.createTextNode(`
                    body {
                        font-family: sans-serif;
                    }
                        body::-webkit-scrollbar-track {
                        background-color: #ededed;
                    }

                    body::-webkit-scrollbar {
                        width: 8px;
                        height: 8px;
                        background-color: #F5F5F5;
                    }

                    body::-webkit-scrollbar-thumb {
                        background-color: #00897B;
                    }`));

        },

        initMailbox() {
            if (typeof this.userInfo.userID != 'undefined' && this.userInfo.userID != 0) {
                this.fetch({
                    url: '/gmail/mailbox/' + this.userInfo.entityID + '/' + this.userInfo.userID,
                    method: 'GET'
                })
                        .then(mailboxResponse => {
                            this.mailboxes = mailboxResponse
                            this.mailboxesLoading = false;
                        })
                        .catch(err => {
                            console.log(err)
                        })
            } else {
                setTimeout(() => {
                    this.initMailbox();
                }, 100)
            }
        },
        getMessagesForMailbox(mailbox) {
            this.mailboxView = true;
            this.selectedMailbox = mailbox.labelID;

            eventBus.$emit('mailboxShowMessageLoader', true);


            this.fetch({
                url: '/gmail/mailbox/messagesForUserFromLabel/' + this.userInfo.entityID + '/' + this.userInfo.userID + '/' + this.selectedMailbox,
                method: 'GET'
            })
                    .then(data => {
                        this.mailboxMessages = data;
                        eventBus.$emit('mailboxShowMessageLoader', false);
                        if (!this.updateTaskLinkRunning) {
                            this.updateTaskLinkForMailbox();
                        }
                    })
                    .catch(err => {
                        console.log(err)
                    })

            this.initMailbox();
        },
        mailboxMessageSelected(message) {
            this.mailboxSelectedMessage = message;
            this.mailboxGetMessageHtml();
        },
        mailboxGetMessageHtml() {
            this.messageHtml = '';
            this.fetch({
                method: 'GET',
                url: `/gmailMailbox/${this.userInfo.entityID}/${this.mailboxSelectedMessage.fk_group_mailbox_folder + '-' + this.mailboxSelectedMessage.mailID}/${this.mailboxSelectedMessage.fk_group_mailbox_folder + '-' + this.mailboxSelectedMessage.mailID}.html`
            })
                    .then((data) => {
                        let html = data.replace(/".\//g, '"' + this.serverAddress + '/gmailMailbox/' + this.userInfo.entityID + '/' + this.mailboxSelectedMessage.fk_group_mailbox_folder + '-' + this.mailboxSelectedMessage.mailID + '/');
                        this.mailboxMessageHtml = html
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e)
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                    })
        },
        composeMailGmail(type, srcMail, srcMailBody) {
            this.mailComposeType = type;
            this.mailComposeMailSrc = srcMail;
            this.mailComposeMailSrcBody = srcMailBody;
            this.mailComposeMailPath = 'gmail';
            this.showCompose = true;
        },
        replyMail() {
            // 0 - Normal Compose, 1 - Reply, 2 - Reply to all, 3 - Forward
            this.$refs.createMail.init(1, this.selectedMessage.filename, 'cache')
        },
        replyAllMail() {
            this.composeMailGmail(2, this.selectedMessage, this.messageHtml);
            // eventBus.$emit('gmailReplyToAllMail', {mail: this.selectedMessage, mailBody: this.messageHtml})
        },
        forwardMail() {
            // eventBus.$emit('gmailForwardMail', {mail: this.selectedMessage, mailBody: this.messageHtml})
            this.composeMailGmail(3, this.selectedMessage, this.messageHtml);
        },
        openContacts() {
            window.open('https://contacts.google.com/other')
        },
        getContacts() {
            this.contactsPending = true;

            this.fetch({method: 'GET', url: `/gmail/contacts/${this.userInfo.userID}`})
                    .then((data) => {
                        this.contacts = data;
                        this.contactsPending = false
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e)
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                    });
        },
        linkMail() {
            // eventBus.$emit('linkNewMail', {mail: this.selectedMessage});

            let tMail = null;

            if (!this.mailboxView) {
                tMail = this.selectedMessage;
                tMail.isMailbox = false
            } else {
                tMail = this.mailboxSelectedMessage;
                tMail.isMailbox = true
            }

            this.$emit('setGmailMail', tMail);
            this.$emit('showLinkGmailChange');
        },
        linkMailboxMail() {
            eventBus.$emit('linkNewMailMailbox', {mail: this.mailboxSelectedMessage});
        },
        createTaskFromMailbox() {
            eventBus.$emit('mail-createTaskFromMailbox', {
                mailDBID: this.mailboxSelectedMessage.gmailID,
                userInfo: this.userInfo,
                mail: this.mailboxSelectedMessage
            })
        },
        getMessageText() {
            return this.messageHtml.substring(6, this.messageHtml.length - 6).replace(/(?:\r\n|\r|\n)/g, "<br />")
        },
        handleProcessFile(error, data) {
            this.myFiles.push(data.serverId);
        },
        removeFile(file) {

            let i = -1;
            this.myFiles.forEach((arrfile) => {
                i++;
                if (arrfile == file.serverId) this.myFiles.splice(i, 1);
            });
            this.myFileCount--;
        },
        sendCompose() {
            if (this.myFiles.length == 0) {
                this.sendSimple()
            } else {
                this.sendFull()
            }
        },
        sendSimple() {
            //Create To String

            let jBody = {};

            let toString = "";
            this.selected.forEach((obj) => {
                if (typeof obj == 'string') {
                    toString = toString + obj + ', '
                } else {
                    toString = toString + obj.email + ', '
                }
            });
            toString = toString.substring(0, toString.length - 2);
            jBody.to = toString;

            let ccString = "";
            this.selectedCc.forEach((obj) => {
                if (typeof obj == 'string') {
                    ccString = ccString + obj + ', '
                } else {
                    ccString = ccString + obj.email + ', '
                }
            });
            ccString = ccString.substring(0, ccString.length - 2);
            jBody.cc = ccString;

            jBody.from = 'user';
            jBody.subject = this.subjectContent;
            jBody.body = this.content;

            this.fetch({method: 'GET', url: `/gmail/msg/send/${this.userInfo.userID}`, body: JSON.stringify(jBody)})
                    .then((data) => {

                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e)
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                    })
            this.selected = [];
            this.selectedCc = [];
            this.subjectContent = "";
            this.content = "";
            this.composeDialog = false;
            this.userInfo.signatures.forEach((signature) => {
                if (signature.default == 1) {
                    this.selectedSignature = signature;
                    this.content = signature.signature
                }
            });

            if (this.selectedSignature == {}) {
                if (this.userInfo.signatures.length > 0) {
                    this.selectedSignature = this.userInfo.signatures[0];
                    this.content = this.userInfo.signatures[0].signature
                }
            }

        },
        resetSend() {
            this.selected = [];
            this.selectedCc = [];
            this.subjectContent = "";
            this.content = "";
            this.composeDialog = false;
            this.userInfo.signatures.forEach((signature) => {
                if (signature.default == 1) {
                    this.selectedSignature = signature;
                    this.content = signature.signature
                }
            });

            if (this.selectedSignature == {}) {
                if (this.userInfo.signatures.length > 0) {
                    this.selectedSignature = this.userInfo.signatures[0];
                    this.content = this.userInfo.signatures[0].signature
                }
            }
        },
        sendFull() {
            //Create To String

            let jBody = {};

            let toString = "";
            this.selected.forEach((obj) => {
                if (typeof obj == 'string') {
                    toString = toString + obj + ', '
                } else {
                    toString = toString + obj.email + ', '
                }
            });
            toString = toString.substring(0, toString.length - 2);
            jBody.to = toString;

            let ccString = "";
            this.selectedCc.forEach((obj) => {
                if (typeof obj == 'string') {
                    ccString = ccString + obj + ', '
                } else {
                    ccString = ccString + obj.email + ', '
                }
            });
            ccString = ccString.substring(0, ccString.length - 2);
            jBody.cc = ccString;

            jBody.from = 'user';
            jBody.subject = this.subjectContent;
            jBody.body = this.content;

            jBody = {attachments: this.myFiles, message: jBody};

            this.fetch({
                method: 'POST',
                url: `/gmail/msg/sendfull/${this.userInfo.userID}`,
                body: JSON.stringify(jBody)
            })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e)
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                    });
            this.selected = [];
            this.selectedCc = [];
            this.subjectContent = "";
            this.content = "";
            this.composeDialog = false;
            this.myFiles = [];
            this.myFileCount = 0;
            this.userInfo.signatures.forEach((signature) => {
                if (signature.default == 1) {
                    this.selectedSignature = signature;
                    this.content = signature.signature
                }
            })
            if (this.selectedSignature == {}) {
                if (this.userInfo.signatures.length > 0) {
                    this.selectedSignature = this.userInfo.signatures[0];
                    this.content = this.userInfo.signatures[0].signature
                }
            }
        },
        messageSelected(message) {
            this.selectedMessage = message;
            // this.getMessageHtml();

            if (message.labelIds.includes('UNREAD')) {
                this.removeLabelFromMessage(message.gmailID, "UNREAD")
            }
        },
        getLabels() {

            this.labelsPending = true;

            this.fetch({method: 'GET', url: `/gmail/labels/${this.userInfo.userID}`})
                    .then((data) => {
                        this.labels = data;
                        this.labelsPending = false;
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e)
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                        this.labelsPending = false;
                    });
        },
        addLabelToMessage(messageID, labelID) {

            let jBody = {"addLabelIds": [labelID]};

            this.fetch({
                method: 'POST',
                url: `/gmail/msg/modify/${this.userInfo.userID}/${messageID}`,
                body: JSON.stringify(jBody)
            })
                    .then(() => {
                        this.messages.forEach((message) => {
                            if (message.gmailID == messageID) {
                                message.labelIds.push("STARRED")
                            }
                        });
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e)
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                    });
        },
        removeLabelFromMessage(messageID, labelID) {


            let jBody = {"removeLabelIds": [labelID]};

            this.fetch({
                method: 'POST',
                url: `/gmail/msg/modify/${this.userInfo.userID}/${messageID}`,
                body: JSON.stringify(jBody)
            })
                    .then(() => {
                        this.messages.forEach((message) => {
                            if (message.gmailID == messageID) {
                                let i;
                                for (i = 0; i < message.labelIds.length; i++) {
                                    if (message.labelIds[i] == labelID) {
                                        message.labelIds.splice(i, 1);
                                    }
                                }
                            }
                        });
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e)
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                    });

        },
        removeMessageFromList(messageID) {
            let i;
            for (i = 0; i < this.messages.length; i++) {
                if (this.messages[i].gmailID == messageID) {
                    this.messages.splice(i, 1);
                }
            }
        },
        starMessage(messageID) {
            let jBody = {"addLabelIds": ["STARRED"]};
            this.fetch({
                method: 'POST',
                url: `/gmail/msg/modify/${this.userInfo.userID}/${messageID}`,
                body: JSON.stringify(jBody)
            })
                    .then((data) => {
                        this.messages.forEach((message) => {
                            if (message.gmailID == messageID) {
                                message.labelIds.push("STARRED")
                            }
                        });
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e)
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                    })
        },
        unstarMessage(message) {

            let jBody = {"removeLabelIds": ["STARRED"]};

            this.fetch({
                method: 'POST',
                url: `/gmail/msg/modify/${this.userInfo.userID}/${message.gmailID}`,
                body: JSON.stringify(jBody)
            })
                    .then(() => {
                        let i;
                        for (i = 0; i < message.labelIds.length; i++) {
                            if (message.labelIds[i] == "STARRED") {
                                message.labelIds.splice(i, 1);
                            }
                        }
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e)
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                    });
        },
        archiveMessage(message) {

            if (this.selectedMessage != null && message.gmailID == this.selectedMessage.gmailID) {
                this.selectedMessage = null
            }

            let jBody = {"removeLabelIds": ["INBOX"]};

            this.fetch({
                method: 'POST',
                url: `/gmail/msg/modify/${this.userInfo.userID}/${message.gmailID}`,
                body: JSON.stringify(jBody)
            })
                    .then(() => {
                        if (this.selectedLabel.id == "INBOX") {
                            let i;
                            for (i = 0; i < this.messages.length; i++) {
                                if (this.messages[i].gmailID == message.gmailID) {
                                    this.messages.splice(i, 1);
                                }
                            }

                            this.selectedMessage = null
                        } else {
                            let i;
                            for (i = 0; i < message.labelIds.length; i++) {
                                if (message.labelIds[i] == "INBOX") {
                                    message.labelIds.splice(i, 1);
                                }
                            }
                        }
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e)
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                    })

        },
        getMessages() {
            this.selectedMessage = null;
            this.mailboxView = false;
            this.messages = [];
            eventBus.$emit("showMessageLoader", true);

            this.labelsPending = true;

            this.fetch({
                method: 'GET',
                url: `/gmail/msg/label/${this.userInfo.userID}/${this.selectedLabel.id}/${this.userInfo.entityID}`
            })
                    .then((data) => {
                        let jData = data;
                        this.nextPage = jData.nextPage;
                        let jConvert = jData.messages;

                        jConvert.forEach((message) => {
                            message.displayDate = format(message.date, "D MMM YY");
                            message.displayDateFull = format(message.date, 'Do MMMM YYYY  h:mm A');
                        });
                        eventBus.$emit("showMessageLoader", false);
                        this.messages = jConvert;
                        this.showSearch = true;
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e)
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                    });
        },
        loadNextPage() {
            eventBus.$emit("showMoreLoader", true);

            this.labelsPending = true;

            this.$newReq('POST', `gmail/msg/nextPage/${this.userInfo.userID}/${this.userInfo.entityID}`, this.nextPage)
                    .then((data) => {
                        let jData = data;
                        this.nextPage = jData.nextPage;
                        let jConvert = jData.messages;

                        jConvert.forEach((message) => {
                            message.displayDate = format(message.date, "D MMM YY");

                            if (message.labelIds.includes('UNREAD')) {
                                message.read = 'unread';
                            } else {
                                message.read = 'read';
                            }

                            this.messages.push(message)
                        });
                        eventBus.$emit("showMoreLoader", false);
                        this.showSearch = true;
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e)
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                    })
        },
        mailSearch(type) {
            this.selectedMessage = null;
            this.messages = [];
            this.selectedLabel.id = 0;
            eventBus.$emit("showMessageLoader", true);
            this.labelsPending = true;
            let jBody = {};
            if (type == 'simple') {
                jBody.simple = this.searchOptions.searchText;
            } else {
                jBody.from = this.searchOptions.from;
                jBody.to = this.searchOptions.to;
                jBody.subject = this.searchOptions.subject;
                jBody.hasWords = this.searchOptions.hasWords;
                jBody.noWords = this.searchOptions.noWords;
                jBody.hasAttachment = this.searchOptions.hasAttachment;
                jBody.searchIn = this.searchOptions.searchIn;
                jBody.starred = this.searchOptions.starred;
            }

            this.fetch({
                method: 'POST',
                url: `/gmail/msg/search/${this.userInfo.userID}/${this.userInfo.entityID}`,
                body: JSON.stringify(jBody)
            })
                    .then((data) => {
                        let jData = data;
                        this.nextPage = jData.nextPage;

                        let jConvert = jData.messages;

                        jConvert.forEach((message) => {
                            message.displayDate = format(message.date, "D MMM YY");

                            if (message.labelIds.includes('UNREAD')) {
                                message.read = 'unread';
                            } else {
                                message.read = 'read';
                            }
                        });
                        eventBus.$emit("showMessageLoader", false);
                        this.messages = jConvert;
                        this.showSearch = true;
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e)
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                    })
        },
        checkLogin(userID) {

            this.fetch({method: 'GET', url: `/gmail/auth/checkUser/${userID}`})
                    .then((data) => {
                        this.gmailReady = data;
                        if (this.gmailReady.result == true) {
                            this.showLogin = false;
                            this.getLabels();
                            this.getMessages();
                            this.getContacts();
                        } else {
                            if (this.checkCount <= 5) {
                                this.checkCount++;
                                setTimeout(() => {

                                    this.checkLogin(this.userInfo.userID);
                                }, 1000);
                                this.showLogin = true;
                            }
                        }
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e)
                        this.showLogin = true;
                    });
        },
        getKey() {
            window.location.assign(this.gmailReady.authUrl)
        },

        gmailAuthorized() {
            this.showLogin = false;
            this.checkLogin(this.userInfo.userID)
        },
        moveGmailMessageLabel(data) {
            this.addLabelToMessage(data.messageID, data.toLabelID);
            this.removeLabelFromMessage(data.messageID, this.selectedLabel.id);
            this.removeMessageFromList(data.messageID);
        },
        userInfoSetLocal(data) {
            this.checkLogin(data.userID);
        },
        mailLinkedToTask(data) {
            this.messages.forEach((message) => {
                if (data.mail.gmailID == message.gmailID) {
                    if (typeof message.tasks == 'undefined') {
                        message.tasks = []
                    }
                    message.tasks.push(data.task.taskID);
                    eventBus.$emit('refreshMailHeader');
                }
            });
            this.mailboxMessages.forEach((message) => {
                if (data.mail.gmailID == message.gmailID) {
                    if (typeof message.tasks == 'undefined') {
                        message.tasks = []
                    }
                    message.tasks.push(data.task.taskID);
                    eventBus.$emit('refreshMailHeader');
                }
            })
        },
        updatePaginationMailbox(data) {
            this.mailboxPagination = {totalPages: data.totalPages, curPage: data.curPage}
        },
        gmailAccountRemoved() {
            this.checkLogin(this.userInfo.userID)
        },
    },
    mounted() {
        this.showSearch = false;
        this.checkLogin(this.userInfo.userID);
        this.userInfo.signatures.forEach((signature) => {
            if (signature.default == 1) {
                this.selectedSignature = signature;
                this.content = signature.signature
            }
        });

        if (this.selectedSignature == {} || this.selectedSignature == 0) {
            if (this.userInfo.signatures.length > 0) {
                this.selectedSignature = this.userInfo.signatures[0];
                this.content = this.userInfo.signatures[0].signature
            }
        }

    },
    created() {
        eventBus.$on('gmailAuthorized', this.gmailAuthorized);
        eventBus.$on('moveGmailMessageLabel', this.moveGmailMessageLabel);
        eventBus.$on('userInfoSet', this.userInfoSetLocal);
        eventBus.$on('mailLinkedToTask', this.mailLinkedToTask);
        eventBus.$on('updatePaginationMailbox', this.updatePaginationMailbox);
        eventBus.$on('gmailAccountRemoved', this.gmailAccountRemoved);
    },
    beforeDestroy() {
        eventBus.$off('gmailAuthorized', this.gmailAuthorized);
        eventBus.$off('moveGmailMessageLabel', this.moveGmailMessageLabel);
        eventBus.$off('userInfoSet', this.userInfoSetLocal);
        eventBus.$off('mailLinkedToTask', this.mailLinkedToTask);
        eventBus.$off('updatePaginationMailbox', this.updatePaginationMailbox);
        eventBus.$off('gmailAccountRemoved', this.gmailAccountRemoved);
    },
    watch: {
        selected(val) {
            let i;
            for (i = 0; i < val.length; i++) {
                if (typeof val[i].name === 'undefined') {
                    if (val[i].includes("@") === false || val[i].includes('.') === false) {
                        val.splice(i, 1);
                    }
                }
            }
        },
        selectedCc(val) {
            let i;
            for (i = 0; i < val.length; i++) {
                if (typeof val[i].name === 'undefined') {
                    if (val[i].includes("@") === false || val[i].includes('.') === false) {
                        val.splice(i, 1);
                    }
                }
            }
        },
        selectedMessage() {
            this.showEmbeddedAttachments = false;


        },

        "selectedMessage.tasks": {
            deep: true,
            immediate: false,
            handler(tasks) {
                this.loadedTasks = [];
                if (typeof tasks != 'undefined') {
                    tasks.forEach((task) => {
                        if (typeof this.loadedTasks[task] == 'undefined') {
                            this.getTaskHeader(task)
                                    .then((data) => {
                                        this.loadedTasks[data.taskID] = data
                                        this.resetTasks = true;
                                        this.$nextTick(() => {
                                            this.resetTasks = false;
                                        })
                                    })
                                    .catch(e => {
                                        console.log(e)
                                    })
                        }
                    })
                }
            }
        },

    },

}
</script>

<style scoped>

.contain >>> {
    font-size: 0.9em;
}

.overlay {
    position: fixed; /* Sit on top of the page content */
    width: 100%; /* Full width (cover the whole page) */
    height: 100%; /* Full height (cover the whole page) */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
    z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
    cursor: pointer; /* Add a pointer on hover */
    display: block;
}

</style>
