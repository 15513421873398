<template>
    <div>
        <v-menu :close-on-content-click="false" offset-y v-model="showMenu" max-width="480">
            <template v-slot:activator="{ on }">
                <div v-show="!value" v-on="on">
                    <v-btn small flat color="secondary" class="ma-0">Select Field</v-btn>
                </div>
                <v-card ripple hover flat v-show="!!value" class="primary white--text">


                    <v-layout row align-center style="cursor: pointer" v-if="!!value">

                        <v-flex xs12 class="pa-1 pl-2 text-truncate" style="overflow-x: hidden;" v-on="on">
                            {{selectedField.description}}
                        </v-flex>
                        <v-flex shrink>
                            <v-tooltip top>
                                <v-btn
                                        small
                                        flat
                                        icon
                                        color="white"
                                        class="pa-0 ma-0"
                                        slot="activator"
                                        @click="$emit('input', null)"
                                >
                                    <v-icon>close</v-icon>
                                </v-btn>
                                <span>Clear Value</span>
                            </v-tooltip>
                        </v-flex>
                        <v-flex shrink class="pr-1" v-on="on">
                            <v-tooltip top>
                                <v-btn
                                        small
                                        flat
                                        icon
                                        color="white"
                                        class="pa-0 ma-0"
                                        slot="activator"
                                >
                                    <v-icon small>edit</v-icon>
                                </v-btn>
                                <span>Select Another Field</span>
                            </v-tooltip>
                        </v-flex>
                    </v-layout>
                </v-card>
            </template>
            <v-card width="480">
                <v-layout row align-center class="pa-1" style="overflow-x: hidden; overflow-y: auto">
                    <v-flex xs12 style="overflow-x: hidden; overflow-y: auto">
                        <div class="pa-1">
                            <div class="caption greyType--text font-weight-bold">
                                Select Field
                            </div>
                            <div>
                                <v-text-field
                                        label="Search Workflow Fields"
                                        solo
                                        clearable
                                        prepend-icon="search"
                                        color="primary"
                                        flat
                                        :hide-details="true"
                                        v-model="fieldFilter"
                                        class="pa-0 ma-0"
                                ></v-text-field>
                            </div>
                            <div class="pl-2" style="overflow-x: hidden; overflow-y: auto; max-height: 400px" >
                                <div
                                        v-for="(step, i) in stepTree"
                                        :key="i"
                                >
                                    <div class="pa-1 body-2 primary--text">
                                        {{step.title}}
                                    </div>
                                    <div
                                            v-for="(field, n) in step.fields" :key="n"
                                            class="body-1 py-1 px-2 ml-2 hover-item greyType--text text--darken-1"
                                            @click="() => {
                                                showMenu = false
                                                $emit('input', field.id);
                                            }"
                                    >
                                        {{field.title}}
                                    </div>

                                </div>

                            </div>
                        </div>
                    </v-flex>
                </v-layout>
            </v-card>
        </v-menu>
    </div>
</template>

<script>
export default {
    props: {
        value: Number,
        steps: Array,
        fields: Array,
        fieldTypeFilter: Array
    },
    data() {
        return {
            showMenu: false,
            fieldFilter: null
        }
    },
    watch: {
        showMenu() {
            this.fieldFilter = null;
        }
    },
    computed: {
        selectedField() {
            return !this.value ? null : this.fields.filter(field => field.id === this.value).pop()
        },
        stepTree() {
            return [...this.steps]
                    .sort((a,b) => a.field_order - b.field_order)
                    .map((step) => {
                        return {
                            title: step.description,
                            id: step.id,
                            fields: [...this.fields]
                                    .sort((a,b) => a.field_order - b.field_order)
                                    .filter(field => {
                                        if (this.fieldTypeFilter && this.fieldTypeFilter.length && !this.fieldTypeFilter.includes(field.fieldType))
                                            return false
                                        if (this.fieldFilter && !field.description.toLowerCase().includes(this.fieldFilter.toLowerCase()))
                                            return false
                                        return field.fk_step === step.id
                                    })
                                    .map(field => {
                                        return {
                                            title: field.description,
                                            id: field.id
                                        }
                                    })
                        }
                    })
                    .filter(step => step.fields.length)
        }
    }
}
</script>

<style scoped>

</style>