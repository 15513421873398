<template>
    <v-layout column class="scrollable fill-height">

        <!--        <v-flex shrink @click="groupedNotifications.splice(1,1)"><v-btn>REOMVE No 2</v-btn></v-flex>-->
      
        <v-flex shrink class="pa-1">
            <v-layout row align-center class="pl-1">
                <template v-if="typeof userInfo.dashboardShares != 'undefined'">
                    <v-flex shrink class="pr-1" v-if="userInfo.dashboardShares.length > 0">
                        <dashboard-share-select-user
                                :local-data="localData"
                                v-model="viewUser"
                                @input="$refs.sliderScroll.scrollToTop()"
                        />
                    </v-flex>
                    <v-flex shrink class="pr-1">
                        <v-tooltip top>
                            <v-btn
                                    small
                                    icon
                                    flat
                                    class="pa-0 ma-0"
                                    color="primary"
                                    slot="activator"
                                    @click="showShareSettings = !showShareSettings"
                            >
                                <v-icon>share</v-icon>
                            </v-btn>
                            {{showShareSettings ? 'Hide Share Settings' : 'Show Share Settings'}}
                        </v-tooltip>
                    </v-flex>
                </template>
                <v-flex xs6 class="pr-2">
                    <v-card class="px-1">
                        <v-text-field
                                label="Search"
                                single-line
                                v-model="notificationSearch"
                                color="primary"
                                hide-details
                                style="width: 100%"
                                class="ma-0 pa-0"
                                prepend-inner-icon="search"
                                @input="$refs.sliderScroll.scrollToTop()"
                        ></v-text-field>
                    </v-card>
                </v-flex>
                <v-flex xs6>
                    <v-card class="px-1">
                        <v-autocomplete
                                dense
                                :value="filterNotificationTypes"
                                @input="selectItem($event); $refs.sliderScroll.scrollToTop()"
                                :items="filterTypes"
                                label="Notification Types"
                                hide-details
                                single-line
                                color="primary"
                                item-text="description"
                                item-value="val"
                                class="pa-0 ma-0"
                                multiple
                                v-if="!resetAuto"
                                clearable
                                prepend-inner-icon="filter_list"
                                hide-selected
                        >
                            <template v-slot:selection="data">
                                <dense-chip
                                        @input="remove(data.item.val); refs.sliderScroll.scrollToTop()"
                                        :color="data.item.colorName"
                                        :show-close="data.item.val != 'all'"
                                        :label="data.item.description"
                                        :icon="data.item.icon"
                                />
                            </template>

                            <template v-slot:item="data">
                                <div @click="data.item.val === 'all' ? selectAll() : ''" class="c-d-flex align-center justify-space-between" style="width: 100%;">
                                    <div>
                                        <dense-chip
                                            @input="$refs.sliderScroll.scrollToTop()"
                                            :color="data.item.colorName"
                                            :label="data.item.description"
                                            :icon="data.item.icon"
                                        />
                                    </div>
                                    <div class="flex-grow-0">
                                        <v-tooltip right>
                                            <template #activator="{on}">
                                                <v-btn
                                                    icon
                                                    flat
                                                    small
                                                    color="error"
                                                    @click.stop="showClearAllNotifications = data.item.val"
                                                    :loading="clearLoading.includes(data.item.val) || clearLoading.includes('all')"
                                                    :disabled="clearLoading.includes(data.item.val) || clearLoading.includes('all') || !!viewUser"
                                                    v-on="on"
                                                >
                                                    <v-icon>delete</v-icon>
                                                </v-btn>                            
                                            </template>
                                            Clear All {{data.item.description}}
                                        </v-tooltip>
                                    </div>

                                </div>
                            </template>

                        </v-autocomplete>
                    </v-card>
                </v-flex>
              
              <v-flex shrink class="pl-2">
                <v-tooltip top>
                  <template #activator="{on}">
                    <v-btn
                        icon
                        flat
                        small
                        :color="closedTaskFilter ? 'primary' : null"
                        class="ma-0 pa-0"
                        @click.stop="closedTaskFilter = !closedTaskFilter"
                        v-on="on"
                    >
                      <v-icon>done_all</v-icon>
                    </v-btn>
                  </template>
                  Filter by closed tasks only
                </v-tooltip>
              </v-flex>

                <v-flex shrink class="pl-1">
                    <v-tooltip right>
                        <template #activator="{on}">
                            <v-btn
                                icon
                                flat
                                small
                                color="error"
                                class="ma-0 pa-0"
                                @click.stop="showClearAllNotifications = true"
                                :loading="clearLoading.includes('all')"
                                :disabled="clearLoading.includes('all') || !!viewUser"
                                v-on="on"
                            >
                                <v-icon>delete</v-icon>
                            </v-btn>
                        </template>
                        Clear All Notifications
                    </v-tooltip>
                </v-flex>

                <v-flex shrink class="px-1">
                    <v-tooltip top>
                        <v-btn
                                small
                                icon
                                :flat="!ascSort"
                                class="pa-0 ma-0"
                                :color="!ascSort ? 'primary' : 'secondary'"
                                slot="activator"
                                @click="ascSort = !ascSort"
                        >
                            <v-icon>swap_vert</v-icon>
                        </v-btn>
                        Sort {{ascSort ? "Descending" : "Ascending"}}
                    </v-tooltip>
                </v-flex>
            </v-layout>
        </v-flex>
        <v-flex shrink v-if="showShareSettings" class="pt-1 px-1 pb-2">
            <v-card>
                <v-layout row align-center>
                    <v-flex xs12>
                        <div class="subheading font-weight-bold primary--text pa-2">Dashboard Sharing Settings</div>
                    </v-flex>
                    <v-flex shrink class="pr-1">
                        <v-btn
                                small
                                icon
                                flat
                                class="pa-0 ma-0"
                                color="primary"
                                @click="showShareSettings = !showShareSettings"
                        >
                            <v-icon>close</v-icon>
                        </v-btn>
                    </v-flex>
                </v-layout>
                <v-divider></v-divider>
                <div class="pt-2 px-2">
                    <dashboard-share
                            :user="this.userInfo.userID"
                            :local-data="localData"
                    />
                </div>
            </v-card>
        </v-flex>

        <v-flex xs12 class="scrollable notif-container" id="scrollTop" ref="parentBox">
<!--            <template v-for="(item, index) in filteredNotifications">-->
                <v-scroller
                        :items="filteredNotifications"
                        key-name="taskID"
                        :items-in-parent="20"
                        :flush-count="10"
                        ref="sliderScroll"
                >
                    <template #item="{item, index}">
                        <v-layout
                                row
                                class="pb-2"
                                :key="item.taskID"

                        >
<!--                            :style="parentScrollSize ? `max-height: ${parentScrollSize - 100}px; overflow-y: auto` : null"-->
                            <!--                        <v-flex shrink><h1>{{index}}</h1></v-flex>-->
                            <v-flex xs6 class="pa-1" v-if="item.taskID > 0">
                                <v-card hover style="background: var(--card-back)">
                                    <div class="pa-2" v-if="item.task === false" @click="taskSelected(item.task)">
                                        <app-task-loading-container
                                                :task-id="item.taskID"
                                        />
                                    </div>
                                    <template v-else>
                                        <app-task-container
                                                :userInfo="userInfo"
                                                :task="item.task"
                                                :taskBar="false"
                                                @taskSelected="taskSelected(item.task)"
                                        >
                                        </app-task-container>
                                    </template>
                                </v-card>
                            </v-flex>
                            <v-flex xs6>
                                <div
                                        v-for="(notif, index) in item.notifications"
                                        :key="`notif${item.taskID}-${index}`"
                                        class="pa-1"
                                >
                                    <template v-if="!filterNotificationTypes.includes('all') ? filterNotificationTypes.includes(notif.type) : true">
                                        <template v-if="notif.type === 'taskNotification'">
                                            <task-notification
                                                    class="pl-1"
                                                    :userInfo="userInfo"
                                                    :task="notif.task"
                                                    :notification="notif.payload"
                                                    @removed="removeNotification(notif)"
                                                    :read-only-dash="viewUser != null"
                                            />
                                        </template>
<!--Transfer Notification-->
                                        <template v-if="notif.type === 'transfer'">
                                            <transfer-notification
                                                    :userInfo="userInfo"
                                                    :task="notif.task"
                                                    :transfer="notif.payload"
                                                    @removed="removeNotification(notif)"
                                                    :read-only-dash="viewUser != null"
                                            />
                                        </template>
<!--Snoozed Notification-->
                                        <template v-if="notif.type === 'snoozed'">
                                            <task-activation
                                                    :user-info="userInfo"
                                                    :task-id="notif.taskID"
                                                    :activation="notif.created"
                                                    @removed="removeNotification(notif)"
                                                    :read-only-dash="viewUser != null"
                                            />
                                        </template>
<!--Shared Notification-->
                                        <template v-if="notif.type === 'shared'">
                                            <share-notification
                                                    :user-info="userInfo"
                                                    :task-id="notif.taskID"
                                                    :share-date="notif.created"
                                                    @removed="removeNotification(notif)"
                                                    :read-only-dash="viewUser != null"
                                            />
                                        </template>
                                        <!--AutoLink Notification-->
                                        <template v-if="notif.type === 'mail' && item.task !== false">
                                            <mail-notification
                                                    :user-info="userInfo"
                                                    :task="item.task"
                                                    :mail="notif.payload"
                                                    @removed="removeNotification(notif)"
                                                    :read-only-dash="viewUser != null"
                                                    @viewMail="mailPreview($event)"
                                            />
                                        </template>
                                        <!--Note Notification-->
                                        <template v-if="notif.type === 'note'">
                                            <note-view-container
                                                    v-if="item.task !== false"
                                                    :userInfo="userInfo"
                                                    :task="item.task"
                                                    :note="notif.payload"
                                                    :dashboard="true"
                                                    :attachedDocs="attachedDocs"
                                                    @reply="replyToNote($event, item)"
                                                    :read-only-dash="viewUser != null"
                                            />
                                            <div v-else class="text-xs-center pa-2">
                                                <v-progress-circular
                                                        :size="40"
                                                        :width="3"
                                                        color="primary"
                                                        indeterminate
                                                />
                                            </div>
                                        </template>

                                        <template v-if="notif.type === 'event'">
                                            <v-card flat>
                                                <event-header
                                                        :event-i-d="notif.payload"
                                                        :show-remove-subscription="true"
                                                        :add-left-border="true"
                                                        :cal-events2="calendarEventsRef"
                                                >

                                                </event-header>
                                            </v-card>

                                        </template>
                                    </template>
                                </div>
                            </v-flex>
                        </v-layout>
                    </template>


                    <template #label="{label}">
                        <v-tooltip left>
                            <template v-slot:activator="{on}">
                                <div v-on="on" style="border-radius: 100%; width: 10px; height: 10px; background-color: #9d9d9d;">
                                </div>
                            </template>
                            {{label}}
                        </v-tooltip>
                    </template>

                </v-scroller>

<!--                <template v-if="(index + 1) > ((curPage - 1) * pageSize) && index + 1 <= ((curPage) * pageSize)">-->
<!--                    <v-layout-->
<!--                            row-->
<!--                            class="pb-2"-->
<!--                            :key="item.taskID"-->
<!--                    >-->
<!--                        &lt;!&ndash;                        <v-flex shrink><h1>{{index}}</h1></v-flex>&ndash;&gt;-->
<!--                        <v-flex xs6 class="pa-1" v-if="item.taskID > 0">-->
<!--                            <v-card class="white" hover >-->
<!--                                <div class="pa-2" v-if="item.task === false" @click="taskSelected(item.task)">-->
<!--                                    <app-task-loading-container-->
<!--                                            :task-id="item.taskID"-->
<!--                                    />-->
<!--                                </div>-->
<!--                                <template v-else>-->
<!--                                    <app-task-container-->
<!--                                            :userInfo="userInfo"-->
<!--                                            :task="item.task"-->
<!--                                            :taskBar="false"-->
<!--                                            @taskSelected="taskSelected(item.task)"-->
<!--                                    >-->
<!--                                    </app-task-container>-->
<!--                                </template>-->
<!--                            </v-card>-->
<!--                        </v-flex>-->
<!--                        <v-flex xs6>-->
<!--                            <div-->
<!--                                    v-for="(notif, index) in item.notifications"-->
<!--                                    :key="`notif${item.taskID}-${index}`"-->
<!--                                    class="pa-1"-->
<!--                            >-->
<!--                                <template v-if="!filterNotificationTypes.includes('all') ? filterNotificationTypes.includes(notif.type) : true">-->
<!--                                    <template v-if="notif.type === 'taskNotification'">-->
<!--                                        <task-notification-->
<!--                                                class="pl-1"-->
<!--                                                :userInfo="userInfo"-->
<!--                                                :task="notif.task"-->
<!--                                                :notification="notif.payload"-->
<!--                                                @removed="removeNotification(notif)"-->
<!--                                                :read-only-dash="viewUser != null"-->
<!--                                        />-->
<!--                                    </template>-->
<!--                                    &lt;!&ndash;Transfer Notification&ndash;&gt;-->
<!--                                    <template v-if="notif.type === 'transfer'">-->
<!--                                        <transfer-notification-->
<!--                                                :userInfo="userInfo"-->
<!--                                                :task="notif.task"-->
<!--                                                :transfer="notif.payload"-->
<!--                                                @removed="removeNotification(notif)"-->
<!--                                                :read-only-dash="viewUser != null"-->
<!--                                        />-->
<!--                                    </template>-->
<!--                                    &lt;!&ndash;Snoozed Notification&ndash;&gt;-->
<!--                                    <template v-if="notif.type === 'snoozed'">-->
<!--                                        <task-activation-->
<!--                                                :user-info="userInfo"-->
<!--                                                :task-id="notif.taskID"-->
<!--                                                :activation="notif.created"-->
<!--                                                @removed="removeNotification(notif)"-->
<!--                                                :read-only-dash="viewUser != null"-->
<!--                                        />-->
<!--                                    </template>-->
<!--                                    &lt;!&ndash;Shared Notification&ndash;&gt;-->
<!--                                    <template v-if="notif.type === 'shared'">-->
<!--                                        <share-notification-->
<!--                                                :user-info="userInfo"-->
<!--                                                :task-id="notif.taskID"-->
<!--                                                :share-date="notif.created"-->
<!--                                                @removed="removeNotification(notif)"-->
<!--                                                :read-only-dash="viewUser != null"-->
<!--                                        />-->
<!--                                    </template>-->
<!--                                    &lt;!&ndash;AutoLink Notification&ndash;&gt;-->
<!--                                    <template v-if="notif.type === 'mail' && item.task !== false">-->
<!--                                        <mail-notification-->
<!--                                                :user-info="userInfo"-->
<!--                                                :task="item.task"-->
<!--                                                :mail="notif.payload"-->
<!--                                                @removed="removeNotification(notif)"-->
<!--                                                :read-only-dash="viewUser != null"-->
<!--                                                @viewMail="mailPreview($event)"-->
<!--                                        />-->
<!--                                    </template>-->
<!--                                    &lt;!&ndash;Note Notification&ndash;&gt;-->
<!--                                    <template v-if="notif.type === 'note'">-->
<!--                                        <note-view-container-->
<!--                                                v-if="item.task !== false"-->
<!--                                                :userInfo="userInfo"-->
<!--                                                :task="item.task"-->
<!--                                                :note="notif.payload"-->
<!--                                                :dashboard="true"-->
<!--                                                :attachedDocs="attachedDocs"-->
<!--                                                @reply="replyToNote($event, item)"-->
<!--                                                :read-only-dash="viewUser != null"-->
<!--                                        />-->
<!--                                        <div v-else class="text-xs-center pa-2">-->
<!--                                            <v-progress-circular-->
<!--                                                    :size="40"-->
<!--                                                    :width="3"-->
<!--                                                    color="primary"-->
<!--                                                    indeterminate-->
<!--                                            />-->
<!--                                        </div>-->
<!--                                    </template>-->

<!--                                    <template v-if="notif.type === 'event'">-->
<!--                                        <v-card flat>-->
<!--                                            <event-header :event-i-d="notif.payload" :show-remove-subscription="true" :add-left-border="true">-->

<!--                                            </event-header>-->
<!--                                        </v-card>-->

<!--                                    </template>-->
<!--                                </template>-->
<!--                            </div>-->
<!--                        </v-flex>-->
<!--                    </v-layout>-->
<!--                </template>-->

<!--            </template>-->
        </v-flex>
<!--        <v-flex shrink class="text-xs-center">-->
<!--            <v-pagination-->
<!--                    v-if="showPage && totalPages != null && totalPages > 1"-->
<!--                    v-model="curPage"-->
<!--                    :length="totalPages"-->
<!--                    color="primary"-->
<!--            ></v-pagination>-->
<!--        </v-flex>-->
        <compose-note
                v-if="typeof userInfo.userID != 'undefined'"
                :noteReplyID="noteReplyID"
                :setNoteContent="setNoteContent"
                :userInfo="userInfo"
                :replyUsers="noteReplyUsers"
                :selectedStep="{stepID: noteSelectedStep, description: 'test'}"
                :task="noteTask"
                :dialog="showAddNote"
                :dashboard="true"
                @dismiss="clearComposeNote"
        />

        <v-dialog
                :value="!!mailFile"
                fullscreen
        >
            <v-card v-if="!!mailFile" class="scrollable" style="height: 100vh">

                <v-layout column fill-height class="scrollable">
                    <v-flex shrink class="primary">
                        <v-layout row align-center>
                            <v-flex xs12>
                                <v-layout row align-center>
                                    <v-flex xs12 class="font-weight-regular white--text pa-2 title">
                                        Mail View
                                    </v-flex>
                                </v-layout>

                                <v-spacer></v-spacer>


                            </v-flex>
                            <v-flex shrink class="pa-2">
                                <v-btn
                                        icon
                                        flat
                                        color="white"
                                        class="pa-0 ma-0"
                                        @click="mailFile = null"
                                >
                                    <v-icon>close</v-icon>
                                </v-btn>
                            </v-flex>
                        </v-layout>
                    </v-flex>
                    <v-flex xs12 class="scrollable pa-2" v-if="!!mailFile">
                        <e-m-l-viewer :filename="mailFile" :load-from-filename="true" ref="emlView"/>
                    </v-flex>
                </v-layout>
            </v-card>
        </v-dialog>

        <app-input-dlg
            v-if="!!showClearAllNotifications"
            :show-dialog="!!showClearAllNotifications"
            :title="`Clear all${showClearAllNotifications !== true ? ' ' + filterTypesRef[showClearAllNotifications].description : ''} notifications`"
            :cancel-button="true"
            :show-input-box="false"
            @dismiss="showClearAllNotifications = false"
            :body-padding="true"
        >
            <template slot="body">
                <div class="pa-2">
                    The following action will clear all{{showClearAllNotifications !== true ? ' ' + filterTypesRef[showClearAllNotifications].description : ''}} notifications on your behalf. Please note that this action can't be undone.
                </div>
            </template>
            <template slot="actions">
                <v-btn
                    small
                    color="grey"
                    flat
                    @click="showClearAllNotifications = false"
                >
                    Cancel
                </v-btn>
                <v-btn
                    small
                    color="error"
                    @click="clearNotifications(showClearAllNotifications === true ? 'all' : showClearAllNotifications)"
                >
                    Continue
                </v-btn>
            </template>
        </app-input-dlg>


    </v-layout>
</template>

<script>
import {codeBaseMixin} from "../../codeBaseMixin";
import {eventBus} from "../../main";
import TaskContainer from "../../components/TaskContainer/TaskContainer";
import TaskLoadingContainer from "../../components/TaskContainer/TaskLoadingContainer";
import TransferNotification from "./Components/TransferNotification";
import TaskActivation from "./Components/TaskActivation";
import ShareNotification from "./Components/ShareNotification";
import MailNotification from "./Components/MailNotification";
import NoteViewContainer from "../../Tasks/TaskView V2/components/Notes/NoteViewContainer";
import ComposeNote from "../../Tasks/TaskView/components/Notes/ComposeNote";
import {feed} from '../../feedSocket'
import TaskNotification from "./Components/TaskNotification";
import Pagenation from "../../components/General/Pagenation";
import DashboardShareSelectUser from "@/Dashboard/DashboardShare/DashboardShareSelectUser";
import DashboardShare from "@/Dashboard/DashboardShare/DashboardShare";
import ComposeMail from "@/components/General/ComposeMail";
import {mapGetters} from "vuex";
import EventHeader from "@/Tasks/TaskView V2/components/Events/EventHeader";
import {arrSplice, colors, objectifyArr} from "@/codeFunctions";
import DenseChip from "@/components/General/DenseChip";
import EMLViewer from "@/Mail/EML Viewer/EMLViewer";
import {format} from "date-fns"
import VScroller from "@/components/Virtual Scroller/VScroller";
import appInputDlg from "@/components/General/InputDlg.vue";
import EmojiPicker from "@/components/General/EmojiPicker/EmojiPicker.vue"

export default {
    mixins: [codeBaseMixin],
    components: {
      EmojiPicker,
        appInputDlg,
        VScroller,
        EMLViewer,
        DenseChip,
        EventHeader,
        ComposeMail,
        DashboardShare,
        DashboardShareSelectUser,
        Pagenation,
        TaskNotification,
        ComposeNote,
        NoteViewContainer,
        MailNotification,
        ShareNotification,
        TaskActivation,
        TransferNotification,
        appTaskContainer: TaskContainer,
        appTaskLoadingContainer: TaskLoadingContainer
    },
    props: {
        userInfo: Object,
        liveNotification: Object,
        initTasks: Boolean,
        getTaskHeader: Function,
        noteReplyFn: Function,
        localData: Object,
    },

    data() {
        return {
            format,
            notifications: [],
            groupedNotifications: [],
            attachedDocs: [],
            lastGet: null,
            noteReplyUsers: [],
            noteSelectedStep: 0,
            noteTask: {},
            showAddNote: false,
            setNoteContent: "",
            noteReplyID: 0,
            pageSize: 10,
            curPage: 1,
            totalPages: 0,
            filterNotificationTypes: ['all'],
            filterTypes: [
                {description: 'All Notifications', val: 'all', color: "primary", icon: "done_all", colorName: "primary"},
                {description: 'Notes', val: 'note', color: "primary", icon: "note", colorName: "primary"},
                {description: 'Transfers', val: 'transfer', color: colors.red, icon: "forward", colorName: "red"},
                {description: 'Snoozed Tasks', val: 'snoozed', color: colors["light-blue"], icon: "snooze", colorName: "light-blue"},
                {description: 'Shared Tasks', val: 'shared', color: colors.green, icon: "share", colorName: "green"},
                {description: 'Auto link Mails', val: 'mail', color: colors.orange, icon: "mail", colorName: "orange"},
                {description: 'Task Notifications', val: 'taskNotification', color: colors.cyan, icon: "task", colorName: "cyan"},
                {description: 'Calendar Events', val: 'event', color: colors.blue, icon: "event", colorName: "blue"},
            ],
            resetAuto: false,
            notificationSearch: '',
            checkerInterval: null,
            showPage:true,

            viewUser: null,
            viewDashInterval: null,
            viewDashNotifications: [],
            viewDashGroupedNotifications: [],
            lastGetViewDash: null,
            showShareSettings: false,

            mailFile: null,

            parentScrollSize: null,
            ascSort: false,
            clearLoading: [],
            showClearAllNotifications: false,
          closedTaskFilter: false
        }
    },

    beforeDestroy() {
        if (this.checkerInterval != null) {
            clearInterval(this.checkerInterval);
        }

        feed.off('fetchNotifications', this.getNotifications);
        eventBus.$off('removeAutolinkNotification', this.removeAutolinkNotification);
        eventBus.$off('removeNoteFromDash', this.removeNoteNotification);
    },

    mounted() {
        this.$nextTick(() => {
            this.parentScrollSize = this.$refs.parentBox.clientHeight
        })
        this.checkerInterval = setInterval(() => {
            let tNow = new Date().getTime();
            if (this.lastGet != null && ((tNow - this.lastGet) / 1000) >= 60) {
                this.getNotifications();
            }
        }, 60000)

        this.viewDashInterval = setInterval(() => {
            if (this.viewUser != null) {
                let tNow = new Date().getTime();
                if (this.lastGetViewDash != null && ((tNow - this.lastGetViewDash) / 1000) >= 60) {
                    this.getNotificationsOtherUser();
                }
            }
        }, 60000)

    },

    watch: {

        calendarEventsWithPendingNotifications: {
            immediate: true,
            handler() {
                this.notifications = [...this.notifications]
            }
        },

        viewUser: {
            immediate: true,
            handler(val) {
                this.viewDashNotifications = [];
                if (this.viewUser != null) {
                    this.$nextTick(() => {
                        this.getNotificationsOtherUser();
                    })
                }
            }
        },

        "initTasks": {
            immediate: true,
            handler(loaded) {
                if (loaded) {
                    this.getNotifications();
                }
            }
        },

        notifications: {
            handler(val) {
                if (Array.isArray(val)) {
                    eventBus.$emit('notificationCountUpdate', val.length + this.eventNotifications.length);
                    let tNotifications = val.concat(this.eventNotifications)
                            .reduce((tasks, notif) => {
                                notif.orderVal = new Date(notif.created).getTime()
                                let taskItem = this.getOrAddElementFromArray(tasks,'taskID',notif.taskID || 0,{
                                    taskID: notif.taskID || 0,
                                    task: false,
                                    notifications: [],
                                    latest: null
                                });

                                if (notif.taskID > 0)
                                    this.getTaskHeader(notif.taskID)
                                            .then((task) => {taskItem.task = task})
                                            .catch((e) => {console.log(e)})

                                if (taskItem.latest == null || notif.orderVal > taskItem.latest) {
                                    taskItem.latest = new Date(notif.created).getTime()
                                }
                                taskItem.notifications.push(notif)
                                return tasks
                            }, [])
                            .map(task => {
                                task.notifications.sort((a,b) => a['orderVal'] > b['orderVal'] ? -1 : 1);
                                return task
                            })
                            .sort((a,b) => a['latest'] > b['latest'] ? -1 : 1);

                    this.groupedNotifications = tNotifications
                }
            }
        },

        viewDashNotifications: {
            handler(val) {
                if (Array.isArray(val)) {
                    let tNotifications = val
                            .reduce((tasks, notif) => {
                                notif.orderVal = new Date(notif.created).getTime()
                                let taskItem = this.getOrAddElementFromArray(tasks,'taskID',notif.taskID,{
                                    taskID: notif.taskID,
                                    task: false,
                                    notifications: [],
                                    latest: null
                                });
                                this.getTaskHeader(notif.taskID)
                                        .then((task) => {taskItem.task = task})
                                        .catch((e) => {console.log(e)})

                                if (taskItem.latest == null || notif.orderVal > taskItem.latest) {
                                    taskItem.latest = new Date(notif.created).getTime()
                                }
                                taskItem.notifications.push(notif)
                                return tasks
                            }, [])
                            .map(task => {
                                task.notifications.sort((a,b) => a['orderVal'] > b['orderVal'] ? -1 : 1);
                                return task
                            })
                            .sort((a,b) => a['latest'] > b['latest'] ? -1 : 1);

                    this.viewDashGroupedNotifications = tNotifications
                }
            }
        },

        curPage(val) {
            if (val > this.totalPages) {
                this.curPage = this.totalPages == 0 ? 1 : this.totalPages
            }
            this.scrollToTheTop();
        },

        filteredNotifications: {
            deep: true,
            immediate: true,
            handler(val) {
                if (Array.isArray(val) && val.length > 0) {
                    this.totalPages = Math.ceil(val.length / this.pageSize);
                } else {
                    this.totalPages = 0
                }
            }
        }

    },

    computed: {
        ...mapGetters('cal', ['calendarEventsWithPendingNotifications', "calendarEventsRef"]),

        filterTypesRef() {
            return objectifyArr(this.filterTypes, "val")
        },

        notificationLabels() {
            // latest
            const list = [];

            let lastAdded = null

            for (let k = 0; k < this.filteredNotifications.length; ++k) {

                const curDateStr = format(new Date(this.filteredNotifications[k].latest), "D MMMM YYYY")
                if (list.length === 0 || lastAdded !== curDateStr) {
                    lastAdded = curDateStr
                    list.push(curDateStr)
                }
                else {
                    list.push(null)
                }
            }
            return list
        },

        eventNotifications() {
            let template = [
                {
                    "taskID": 2,
                    "type": "transfer",
                    "payload": "{\"byID\": 1, \"toID\": 1, \"fromID\": 2, \"created\": \"2021-07-28 15:51:49.000000\", \"byFullName\": \"Stage User 1\", \"fk_task_id\": 2, \"toFullName\": \"Stage User 1\", \"transferID\": 2, \"fromFullName\": \"Stage User 2\"}",
                    "created": "2021-07-28T13:51:49.000Z"
                }
            ]


            return this.calendarEventsWithPendingNotifications.map((obj) => {
                return {
                    taskID: obj.fkTask || (obj.id * -1),
                    type: 'event',
                    payload: obj,
                    created: obj.start
                }
            })
        },


        filteredNotifications() {
            if (this.viewUser == null) {
                let tItems = !this.filterNotificationTypes.includes('all')
                        ? this.groupedNotifications
                                .reduce((list, item) => {
                                    if (this.filterNotificationTypes.includes('all')) {
                                        list.push(item)
                                    } else {
                                        if (item.notifications.map(obj => {return obj.type}).filter(obj => this.filterNotificationTypes.includes(obj)).length > 0) {
                                            list.push(item)
                                        }
                                    }
                                    return list
                                }, [])
                        : this.groupedNotifications;

                if (!this.blankString(this.notificationSearch)) {
                    tItems = tItems.reduce((list, item) => {

                        try {
                            let searchStr = `#${item.task.taskID} ${item.task.searchStr} ${item.task.title} ${item.task.ownerName} ${item.task.ownerSurname} ${item.task.folderName} ${item.task.folderGroupNames} ${item.task.workflowName} ${item.task.statusText} ${JSON.stringify(item.notifications)}`.toLowerCase();
                            if (searchStr.includes(this.notificationSearch.toLowerCase())) {
                                list.push(item)
                            }
                            return list
                        } catch (e) {
                            // eslint-disable-next-line
                            list.push(item)
                            return list
                        }
                    }, [])
                }
              
              if (this.closedTaskFilter)
                tItems = tItems.filter(v => v && v.task && v.task.taskState !== 0)
                
                return tItems.sort((a,b) => a.latest > b.latest ? (this.ascSort ? 1 : -1) : (this.ascSort ? -1 : 1))
            } else {
                let tItems = !this.filterNotificationTypes.includes('all')
                        ? this.viewDashGroupedNotifications
                                .reduce((list, item) => {
                                    if (this.filterNotificationTypes.includes('all')) {
                                        list.push(item)
                                    } else {
                                        if (item.notifications.map(obj => {return obj.type}).filter(obj => this.filterNotificationTypes.includes(obj)).length > 0) {
                                            list.push(item)
                                        }
                                    }
                                    return list
                                }, [])
                        : this.viewDashGroupedNotifications;

                if (!this.blankString(this.notificationSearch)) {
                    tItems = tItems.reduce((list, item) => {

                        try {
                            let searchStr = `#${item.task.taskID} ${item.task.searchStr} ${item.task.title} ${item.task.ownerName} ${item.task.ownerSurname} ${item.task.folderName} ${item.task.folderGroupNames} ${item.task.workflowName} ${item.task.statusText} ${JSON.stringify(item.notifications)}`.toLowerCase();
                            if (searchStr.includes(this.notificationSearch.toLowerCase())) {
                                list.push(item)
                            }
                            return list
                        } catch (e) {
                            // eslint-disable-next-line
                            list.push(item)
                            return list
                        }
                    }, [])
                }
              
              if (this.closedTaskFilter)
                tItems = tItems.filter(v => v && v.task && v.task.state !== 0)
              
              return tItems.sort((a,b) => a.latest > b.latest ? (this.ascSort ? 1 : -1) : (this.ascSort ? -1 : 1))
            }
        }
    },

    methods: {

        async clearNotifications(type) {
            try {
                this.showClearAllNotifications = false
                this.clearLoading.push(type)
                const body = {
                    vUserId: this.userInfo.userID,
                    bNotes: type === "all" || type === "note",
                    bMails: type === "all" || type === "mail",
                    bTransfers: type === "all" || type === "transfer",
                    bSnoozed: type === "all" || type === "snoozed",
                    bShared: type === "all" || type === "shared",
                    bTaskNotifications: type === "all" || type === "taskNotification",
                    bCalendar: type === "all" || type === "event",
                }
                await this.$newReq('POST', `notifications/clearNotifications/${this.userInfo.entityID}`, body)
            } catch (e) {
                console.log(e)
                this.$snack.networkError()
            } finally {
                arrSplice(this.clearLoading, (val) => {
                    console.log(val, type, val === type)
                    return val === type
                })
            }
        },

        mailPreview(data) {
            this.mailFile = data;
        },

        addNewMail(data) {

        },

        selectAll() {
            console.log('SELECT * RAN')
            setTimeout(() => {
                this.$nextTick(() => {
                    this.selectItem(['all'])
                })
            }, 500)

        },

        scrollToTheTop() {
            var myDiv = document.getElementById('scrollTop');
            myDiv.scrollTop = 0;
        },

        selectItem(arr) {

            this.curPage = 1;
            this.scrollToTheTop();

            if (arr.length > 0 && arr.includes('all')) {
                arr = arr.filter(obj => obj !== 'all')
            }

            if (arr.length === 0) {
                arr = ['all'];
                this.resetSelect();
            }

            this.filterNotificationTypes = arr
            this.resetSelect();
        },

        resetSelect() {
            this.resetAuto = true;
            this.$nextTick(() => {
                this.resetAuto = false;
            })
        },

        remove(val) {
            this.curPage = 1;
            this.scrollToTheTop();
            this.filterNotificationTypes = this.filterNotificationTypes.filter(obj => obj !== val);

            if (this.filterNotificationTypes.length <= 0) {
                this.filterNotificationTypes = ['all']
            }
            this.resetSelect();
        },

        clearComposeNote() {
            this.showAddNote = false;
            this.noteReplyUsers = [];
            this.noteSelectedStep = 0;
            this.noteTask = {};
            this.setNoteContent = "";
            this.noteReplyID = 0
        },

        replyToNoteRun(data) {
            this.noteTask = data.task.task;
            this.noteReplyUsers = data.users.map(user => {return parseInt(user)});
            this.noteSelectedStep = data.step;
            this.setNoteContent = "";
            this.noteReplyID = parseInt(data.noteID);
            this.showAddNote = true;
        },

        replyToNote(data, task) {

            this.replyToNoteRun({
                users: data.reply,
                step: parseInt(data.step),
                task: task,
                noteID: data.noteID
            })
        },

        removeNotification(notif) {


            this.notifications.forEach((headerNotification, i) => {
                if (notif === headerNotification) {
                    this.notifications.splice(i,1);
                }
            })
        },

        removeAutolinkNotification(mailDBID) {

            this.notifications.forEach((notif, i) => {
                if (notif.type === 'mail') {

                    if (notif.payload.mailDBID == mailDBID) {

                        this.notifications.splice(i,1)
                    }
                }
            })

            // this.notifications = this.notifications.filter((obj) => obj.type !== 'mail' || (obj.type === 'mail' && obj.payload.mailDBID !== mailDBID))
        },

        removeNoteNotification(data) {
            this.notifications.forEach((notif, i) => {
                if (notif.type === 'note') {

                    if (notif.payload.noteID == data.noteID) {

                        this.notifications.splice(i,1)
                    }
                }
            })
        },

        taskSelected(task) {
            eventBus.$emit('gotoTask', task)
        },

        getNotifications() {
            this.fetch({method: 'GET', url:`/notifications/${this.userInfo.entityID}/${this.userInfo.userID}`})
                    .then((data) => {
                        this.lastGet = new Date().getTime();
                        this.notifications = data.notifications.map((notification) => {
                            switch (notification.type) {
                                case 'transfer': {
                                    notification.payload = JSON.parse(notification.payload);
                                    break;
                                }
                                case 'snoozed': {
                                    notification.payload = JSON.parse(notification.payload);
                                    break;
                                }

                                case 'mail': {
                                    notification.payload = JSON.parse(notification.payload)
                                    notification.payload.type = 3;
                                    try {
                                        notification.payload.attachments = JSON.parse(notification.payload.attachments.split("~~~").join('"'));
                                    } catch (e) {
                                        // eslint-disable-next-line
                                        console.log(e)
                                        notification.payload.attachments = [];
                                    }
                                    break;
                                }
                                case 'note': {
                                    notification.payload = JSON.parse(notification.payload);
                                    notification.payload.type = 0;
                                    notification.payload.noteViewFlags = notification.payload.noteViewFlags.replace(/~/g, '"');
                                    notification.payload.lookup = notification.payload.lookup.replace(/~/g, '"');
                                    break;
                                }

                                case 'taskNotification': {
                                    notification.payload = JSON.parse(notification.payload);
                                    break;
                                }
                            }
                            return notification
                        });
                        this.attachedDocs = data.attachedDocs;
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e)
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                    })
        },

        getNotificationsOtherUser() {
            this.fetch({method: 'GET', url:`/notifications/${this.userInfo.entityID}/${this.viewUser}`})
                    .then((data) => {
                        this.lastGetViewDash = new Date().getTime();
                        this.viewDashNotifications = data.notifications.map((notification) => {
                            switch (notification.type) {
                                case 'transfer': {
                                    notification.payload = JSON.parse(notification.payload);
                                    break;
                                }
                                case 'snoozed': {
                                    notification.payload = JSON.parse(notification.payload);
                                    break;
                                }

                                case 'mail': {
                                    notification.payload = JSON.parse(notification.payload)
                                    notification.payload.type = 3;
                                    try {
                                        notification.payload.attachments = JSON.parse(notification.payload.attachments.split("~~~").join('"'));
                                    } catch (e) {
                                        // eslint-disable-next-line
                                        console.log(e)
                                        notification.payload.attachments = [];
                                    }
                                    break;
                                }
                                case 'note': {
                                    notification.payload = JSON.parse(notification.payload);
                                    notification.payload.type = 0;
                                    notification.payload.noteViewFlags = notification.payload.noteViewFlags.replace(/~/g, '"');
                                    notification.payload.lookup = notification.payload.lookup.replace(/~/g, '"');
                                    break;
                                }

                                case 'taskNotification': {
                                    notification.payload = JSON.parse(notification.payload);
                                    break;
                                }
                            }
                            return notification
                        });
                        this.attachedDocsOther = data.attachedDocsOther;
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e)
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                    })
        },
    },

    created() {
        feed.on('fetchNotifications', this.getNotifications);
        eventBus.$on('removeAutolinkNotification', this.removeAutolinkNotification);
        eventBus.$on('removeNoteFromDash', this.removeNoteNotification);
    }
}
</script>

<style scoped>
.notif-container {
    scroll-behavior: smooth;
}
</style>
