<template>
    <div class="fill-height scrollable pa-1 ">
        <v-card class="fill-height scrollable " style="overflow-x: hidden">
            <v-layout column fill-height class="scrollable">
                <v-flex shrink :style="{background: $store.state.darkTheme ? 'var(--primary-20p)' : 'var(--v-primary-base)'}">
                    <v-layout row align-center>

                        <!--                        <v-flex shrink class="pl-2">-->
                        <!--                            <v-tooltip top>-->
                        <!--                                <v-btn-->
                        <!--                                        small-->
                        <!--                                        icon-->
                        <!--                                        color="red"-->
                        <!--                                        slot="activator"-->
                        <!--                                        class="pa-0 ma-0 white&#45;&#45;text"-->
                        <!--                                >-->
                        <!--                                    {{escView.length}}-->
                        <!--                                </v-btn>-->
                        <!--                                <span>{{myEscOnly ? `${escView.length} Task/s Escalated For Me`  : `${escView.length} Task/s Escalated`}}</span>-->
                        <!--                            </v-tooltip>-->
                        <!--                        </v-flex>-->

                        <v-flex xs12 class="pa-2 body-2 white--text">
                            Escalations
                        </v-flex>
                        <!--                        <v-flex shrink class="px-2">-->
                        <!--                            <v-tooltip top>-->
                        <!--                                <v-btn-->
                        <!--                                        small-->
                        <!--                                        icon-->
                        <!--                                        flat-->
                        <!--                                        color="white"-->
                        <!--                                        slot="activator"-->
                        <!--                                        class="pa-0 ma-0"-->
                        <!--                                        @click="myEscOnly = !myEscOnly"-->
                        <!--                                >-->
                        <!--                                    <v-icon>-->
                        <!--                                        {{myEscOnly ? 'person' : 'people'}}-->
                        <!--                                    </v-icon>-->
                        <!--                                </v-btn>-->
                        <!--                                <span>{{myEscOnly ? 'Show All Escalations' : 'Show My Escalations Only'}}</span>-->
                        <!--                            </v-tooltip>-->
                        <!--                        </v-flex>-->

                    </v-layout>
                </v-flex>

                <v-flex xs12 class="scrollable " style="overflow-x: hidden">
                    <v-layout column fill-height class="">
                        <v-flex shrink>
                            <full-task-filter
                                :tasks="escalationTasks"
                                @taskListUpdate="taskListUpdate($event)"
                                @settingsUpdated="$emit('settingsUpdated', $event)"
                                :state-filter="false"
                                :filter-options="filterSettings"
                            />
                        </v-flex>
                        <v-divider></v-divider>
                        <!--                        <v-flex shrink>-->
                        <!--                            {{postFilteredTasks.length}}-->
                        <!--                        </v-flex>-->
                      
                        <v-flex xs12 class="scrollable" v-if="filterSettings != null">
                          <virtual-list
                              :keeps="40"
                              :estimate-size="60"
                              class="fill-height of-y"
                              data-key="refID"
                              :data-sources="displayTasks"
                              :data-component="EscalationItem"
                              :extra-props="{
                                droppedGroupings,
                                filterSettings,
                                toggleGrouping,
                                taskNav,
                                escalations,
                                curMS,
                                removeEscalationRunStart,
                              }"
                          >
                          </virtual-list>
                        </v-flex>
                    </v-layout>
                </v-flex>
            </v-layout>
        </v-card>

        <app-input-dlg
            v-if="showRemoveEscalation"
            :show-dialog="showRemoveEscalation"
            title="Hide Escalation"
            :body="`Are you sure that you would like to hide the escalation for task: ${removeEscalationTask.title}. Please Note this action will hide the escalation for you permanently.`"
            :cancel-button="true"
            :show-input-box="false"
            :buttons="['Remove']"
            :call-backs="[ removeEscalation ]"
            @dismiss="removeEscalationTask.cancelLoadFn(); showRemoveEscalation = false"
        />

    </div>

</template>

<script>
import TaskLoadingContainer from "../../components/TaskContainer/TaskLoadingContainer";
import TaskContainer from "../../components/TaskContainer/TaskContainer";
import {codeBaseMixin} from "../../codeBaseMixin";
import InputDlg from "../../components/General/InputDlg";
import FullTaskFilter from "@/Folders/components/FolderTasks/FullTaskFilter/FullTaskFilter";
import EscalationItem from './EscalationItem.vue'
import VirtualList from "vue-virtual-scroll-list"

export default {
    mixins: [codeBaseMixin],
    components: {FullTaskFilter, appInputDlg: InputDlg, TaskContainer, TaskLoadingContainer, VirtualList},
    props: {
        userInfo: Object,
        escalations: Object,
        getTaskHeader: Function,
        filterSettings: Object
    },
    data() {
        return {
            EscalationItem,
            curMS: 0,
            escalationsArray: [],
            removingEscalationTaskIDs: [],
            removeEscalationTask: null,
            showRemoveEscalation: false,
            filterWorkflows: [],

            postFilteredTasks: [],
            droppedGroupings: [],
            selectedTaskID: null

        }
    },
    created() {
        this.curMS = new Date().getTime();

        setInterval(() => {
            this.curMS = new Date().getTime();
        }, 60000)
    },
    methods: {
        // updateFilterSettings() {
        //     this.fetch({method: 'PATCH', url: '/localUsers/update/userSortPreference/' + this.userInfo.entityID + '/' + this.userInfo.userID, body: JSON.stringify(this.filterOptions)})
        //         .catch((e) => {
        //             // eslint-disable-next-line
        //             console.log(e)
        //             this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
        //         })
        // },
      taskNav() {
        this.$emit('hideParent')
      },
      toggleGrouping(item) {
        if (item.dropped)
          this.droppedGroupings = this.droppedGroupings.filter(obj => obj !== item.title)
        else
          this.droppedGroupings.push(item.title)
      },

        removeEscalationRunStart(task) {
            this.removeEscalationTask = task;
            this.showRemoveEscalation = true
        },

        removeEscalation() {
            this.showRemoveEscalation = false;
            this.removingEscalationTaskIDs.push(this.removeEscalationTask);

            let tUsers = JSON.parse(this.removeEscalationTask.workflowStatusHiddenUsers)
            tUsers.push(this.userInfo.userID);

            this.fetch({method: 'POST', url:`/notifications/removeEscalation/${this.userInfo.entityID}/${this.removeEscalationTask.workflowStatusValue}/${this.removeEscalationTask.taskID}`, body: JSON.stringify(tUsers)})
                .then((data) => {
                    this.removeEscalationTask = null;
                })
                .catch((e) => {
                    // eslint-disable-next-line
                    console.log(e)
                    this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                    this.removingEscalationTaskIDs = this.removingEscalationTaskIDs.filter(task.taskID);
                })
        },

        getDiffString(msOverdue) {

            // Minutes
            let tVal = (msOverdue / 1000) / 60;
            if (tVal < 60) {
                return `${Math.round(tVal)} Minute${Math.round(tVal)>1?'s':''}`;
            } else {
                // Hours
                tVal = tVal / 60;
                if (tVal < 24) {
                    return `${Math.round(tVal)} Hour${Math.round(tVal)>1?'s':''}`;
                } else {
                    // Days
                    tVal = tVal / 24;
                    if (tVal < 30.4) {
                        return `${Math.round(tVal)} Day${Math.round(tVal)>1?'s':''}`;
                    } else {
                        // Months
                        tVal = tVal / 30.4;
                        return `${Math.round(tVal)} Month${Math.round(tVal)>1?'s':''}`;
                    }
                }
            }
        },

        taskListUpdate(tasks) {
            this.postFilteredTasks = tasks
        }
    },
    watch: {

        escalations: {
            immediate: true,
            deep: true,
            handler(val) {
                if (val != 'undefined' && val != null) {
                    this.escalationsArray = Object.values(val)
                        .filter(obj => typeof obj != 'number')
                }
            }
        },

    },
    computed: {

        escalationTasks() {
            return this.escalationsArray.map(escalationObj => {return escalationObj.taskObj});
        },

        displayTasks() {
            let tItems = this.taskGroupsWithDroppedItems.map((obj,index) => {
              if (obj.groupObject) {
                obj.refID = obj.title + obj.dropped ? '1' : '0';
              } else {
                obj.refID = obj.taskID;
              }
              return obj;
            })
            return tItems
        },

        taskGroupsWithDroppedItems() {
            if (this.taskGroups != null) {
                let tItems = [];

                const taskGroupKeys = Object.keys(this.taskGroups).sort((a,b) => a < b ? -1 : 1);
                for (const groupName of taskGroupKeys) {

                    let dropped = this.droppedGroupings.includes(groupName);

                    tItems.push({
                        title: groupName,
                        dropped: dropped,
                        groupObject: true,
                        count: this.taskGroups[groupName].tasks.length
                    });

                    if (dropped) {
                        tItems = tItems.concat(this.taskGroups[groupName].tasks)
                    }
                }
                return tItems
            } else {
                return this.sortedTasks;
            }
        },

        taskGroups() {
            if (this.filterSettings.groupOption != 4) {
                let tGroups = {};
                this.postFilteredTasks.forEach(task => {
                    let group = '';
                    switch (this.filterSettings.groupOption) {
                        case 0: {group = task.folderID > 0 ? task.folderName : 'Task Folder';break;}
                        case 1: {group = `${task.ownerName} ${task.ownerSurname}`;break;}
                        case 6: {group = `${task.subOwnerName} ${task.subOwnerSurname}`;break;}
                        case 2: {group = task.workflowName;break;}
                        case 5: {group = task.folderID > 0 ? task.folderGroupNames : 'Task Folder';break;}
                    }
                    if (typeof tGroups[group] == 'undefined') {
                        tGroups[group] = {tasks: []}
                    }
                    tGroups[group].tasks.push(task);
                });
                return tGroups;
            } else {
                return null;
            }
        },
    }
}
</script>

<style scoped>

</style>
