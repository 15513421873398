<template>
    <v-card
        style="overflow-x: hidden; cursor: pointer; overflow-y: hidden"
        class="hover-item"
        :elevation="3"
    >
        <v-layout row align-center>
            <v-flex class="pl-2" v-if="loading">
                <v-btn
                    :loading="true"
                    icon
                    flat
                    small
                    color="blue"
                    class="pa-0 ma-0"
                >
                    <v-icon>edit</v-icon>
                </v-btn>
            </v-flex>
            <v-flex xs12 class="text-truncate py-2 px-2">
                <div class="text-truncate">
                    {{signature.description}}
                </div>
            </v-flex>
            <v-flex shrink class="pr-2">
                <v-tooltip top>
                    <v-btn
                        slot="activator"
                        icon
                        flat
                        small
                        color="red"
                        class="pa-0 ma-0"
                        @click="$emit('remove')"
                        :disabled="loading || signature.globalDefault === 1"
                    >
                        <v-icon>delete</v-icon>
                    </v-btn>
                    {{signature.globalDefault === 1 ? 'Cant Remove Global Default Signature' : 'Remove Signature'}}
                </v-tooltip>
            </v-flex>
<!--            @click="setDefaultSignature([user.id], user.defaultSignature === selectedSig.id ? 0 : selectedSig.id)"-->
            <v-flex shrink class="pr-2">
                <v-tooltip top>
                    <v-btn
                        slot="activator"
                        icon
                        flat
                        small
                        :color="(defaultSignature == null || defaultSignature === 0) && signature.globalDefault ? 'green' : (defaultSignature === signature.id ? 'green' : 'grey')"
                        class="pa-0 ma-0"
                        :disabled="loading"
                        @click="$emit('updateDefault')"
                    >
                        <v-icon>
                            {{ (defaultSignature == null || defaultSignature === 0) && signature.globalDefault ? 'check_circle' : (defaultSignature === signature.id ? 'check_circle' : 'check_circle_outline') }}
                        </v-icon>
                    </v-btn>
                    Set/Remove Default Signature
                </v-tooltip>
            </v-flex>
        </v-layout>
    </v-card>
</template>

<script>
export default {
    props: {
        defaultSignature: Number,
        signature: Object,
    },
    data() {
        return {
            loading: false
        }
    },
}
</script>

<style scoped>

</style>