<template>
    <v-dialog fill-height v-model="value" persistent fullscreen lazy>
        <v-card class="fill-height" flat style="overflow-y: auto">
            <v-layout column fill-height style="overflow-y: auto">
                <v-flex shrink>
                    <v-card-title class="py-2 pr-2 title-color">
                        <img src="../../../public/icons/user_folder.svg" style="width: 36px" class="pr-2"/>
                        <span class="title font-weight-light white--text">Folder Group Manager</span>
                        <v-spacer></v-spacer>
                        <v-btn flat icon color="white" class="pa-0 ma-0" @click="$emit('input', false)">
                            <v-icon>close</v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-divider></v-divider>
                </v-flex>
                <v-flex xs12 class="scrollable">
                    <div class="pa-2 text-xs-center" v-if="startingUp">
                        <loader/>
                    </div>
                    <template v-else>
                        <v-layout row fill-height class="scrollable">
<!--Select Folder Group-->
                            <v-flex xs4 class="scrollable pa-1">
                                <v-card class="fill-height">
                                    <v-layout column fill-height>
                                        <v-flex shrink class="title-color white--text px-2 py-1">
                                            Select Folder Group
                                        </v-flex>
                                        <v-flex xs12 class="scrollable">
                                            <multi-select-list
                                                    ref="folderGroupSelectList"
                                                    :items="localData.folderGroups"
                                                    item-key="id"
                                                    headline-key="description"
                                                    :return-object="false"
                                                    :multiple="false"
                                                    :show-check-boxes="true"
                                                    :show-action-buttons="true"
                                                    :action-buttons="folderGroupButtons"
                                                    :showFilter="true"
                                                    :loading="loadingFolderGroup"
                                                    v-model="selectedFolderGroup"
                                                    :dark="true"
                                                    filter-label="Filter Folder Groups"
                                            >
                                                <div slot="header" class="title-color white--text py-1 px-2">
                                                    <f-g-m-create-folder-group
                                                            :user-info="userInfo"
                                                            :user-groups="localData.userGroups"
                                                            @folderGroupAdded="addFolderGroup($event)"
                                                            :folder-groups="localData.folderGroups"
                                                    >
                                                        <v-btn
                                                                small
                                                                outline
                                                                color="white"
                                                                class="px-2 py-0 ma-0"
                                                                block
                                                                slot="activator"
                                                                :loading="folderGroupWaiting"
                                                                :disabled="folderGroupWaiting"
                                                        >
                                                            Create Folder Group
                                                        </v-btn>
                                                    </f-g-m-create-folder-group>
                                                </div>
                                            </multi-select-list>
                                        </v-flex>

                                        <app-input-dlg
                                                v-if="showRenameFolderGroup"
                                                :show-dialog="showRenameFolderGroup"
                                                title="Rename Folder Group"
                                                :body="`Rename The Folder Group '${folderGroup.description}'`"
                                                :cancel-button="true"
                                                :show-input-box="true"
                                                :default-input="folderGroup.description"
                                                :buttons="['Rename']"
                                                :call-backs="[ renameFolderGroupRun ]"
                                                :loading="loadingFolderGroup"
                                                @dismiss="showRenameFolderGroup = false"
                                        />
                                    </v-layout>
                                </v-card>
                            </v-flex>
<!--User Groups-->
                            <v-flex xs4 class="scrollable pa-1" v-if="folderGroupUserGroups != null">
                                <v-card class="fill-height">
                                    <v-layout column fill-height>
                                        <v-flex shrink class="title-color white--text px-2 py-1">
                                            User Groups
                                        </v-flex>
                                        <v-flex xs12 class="scrollable">
                                            <multi-select-list
                                                    ref="userGroupSelectList"
                                                    :items="folderGroupUserGroups"
                                                    item-key="id"
                                                    headline-key="description"
                                                    :return-object="false"
                                                    :multiple="true"
                                                    :show-check-boxes="true"
                                                    :show-action-buttons="true"
                                                    :action-buttons="userGroupButtons"
                                                    :showFilter="true"
                                                    :loading="loadingUserGroups"
                                                    v-model="selectedUserGroups"
                                                    :dark="true"
                                                    filter-label="Filter User Groups"
                                            >
                                                <div slot="header" class="title-color white--text py-1 pr-2">
                                                    <v-layout row>
                                                        <v-flex class="pl-2" v-if="userInfo.userManager === 1">
                                                            <f-g-m-create-user-group
                                                                    :userInfo="userInfo"
                                                                    :selected-folder-group="selectedFolderGroup"
                                                                    :users="localData.users"
                                                                    :userGroups="localData.userGroups"
                                                                    @userGroupAdded="addNewUserGroup($event)"
                                                            >
                                                                <v-btn
                                                                        small
                                                                        color="white"
                                                                        flat
                                                                        block
                                                                        outline
                                                                        class="px-2 py-0 ma-0"
                                                                        slot="activator"
                                                                >
                                                                    New
                                                                </v-btn>
                                                            </f-g-m-create-user-group>
                                                        </v-flex>
                                                        <v-flex class="pl-2">
                                                            <f-g-m-existing-user-group
                                                                    :userInfo="userInfo"
                                                                    :selected-folder-group="selectedFolderGroup"
                                                                    :userGroups="availableAddUserGroups"
                                                                    @userGroupAdded="addNewUserGroup($event)"
                                                            >
                                                                <v-btn
                                                                        small
                                                                        color="white"
                                                                        flat
                                                                        block
                                                                        outline
                                                                        class="px-2 py-0 ma-0"
                                                                        slot="activator"
                                                                >
                                                                    Existing
                                                                </v-btn>
                                                            </f-g-m-existing-user-group>
                                                        </v-flex>
                                                    </v-layout>
                                                </div>
                                            </multi-select-list>
                                        </v-flex>
                                    </v-layout>
                                </v-card>
                            </v-flex>
<!--Users-->
                            <v-flex xs4 class="scrollable pa-1 " v-if="folderGroupUserGroupUsers != null">
                                <v-card class="fill-height">
                                    <v-layout column fill-height>
                                        <v-flex shrink class="title-color white--text px-2 py-1">
                                            Users
                                        </v-flex>
                                        <v-flex xs12 class="scrollable">
                                            <multi-select-list
                                                    ref="userSelectList"
                                                    :items="folderGroupUserGroupUsers"
                                                    item-key="id"
                                                    headline-key="description"
                                                    :return-object="false"
                                                    :multiple="true"
                                                    :show-check-boxes="true"
                                                    :show-action-buttons="userInfo.userManager === 1"
                                                    :action-buttons="userButtons"
                                                    :showFilter="true"
                                                    :loading="loadingUsers"
                                                    v-model="selectedUsers"
                                                    :dark="true"
                                                    filter-label="Filter Users"
                                            >
                                                <div slot="header" class="title-color white--text py-1 px-2" v-if="userInfo.userManager === 1">
                                                    <f-g-m-existing-user
                                                            :userInfo="userInfo"
                                                            :selected-user-group="selectedUserGroups[0]"
                                                            :users="availableAddUsers"
                                                            @usersAdded="addNewUsers($event)"
                                                    >
                                                        <v-btn
                                                                small
                                                                color="white"
                                                                flat
                                                                block
                                                                outline
                                                                class="px-2 py-0 ma-0"
                                                                slot="activator"
                                                        >
                                                            Existing
                                                        </v-btn>
                                                    </f-g-m-existing-user>
                                                </div>
                                            </multi-select-list>
                                        </v-flex>
                                    </v-layout>
                                </v-card>
                            </v-flex>

                        </v-layout>
                    </template>
                </v-flex>
            </v-layout>
        </v-card>
    </v-dialog>
</template>

<script>
    import Loader from "../../components/General/loader";
    import MultiSelectList from "../../components/Multi Select List/MultiSelectList";
    import InputDlg from "../../components/General/InputDlg";
    import {arrPopulated, blankString, intToBool, boolToInt} from "../../codeFunctions";
    import {codeBaseMixin} from "../../codeBaseMixin";
    import FGMCreateFolderGroup from "./Components/FGMCreateFolderGroup";
    import {feed} from "../../feedSocket";
    import FGMCreateUserGroup from "./Components/FGMCreateUserGroup";
    import FGMExistingUserGroup from "./Components/FGMExistingUserGroup";
    import FGMExistingUser from "./Components/FGMExistingUser";

    export default {
        mixins: [codeBaseMixin],
        components: {
            FGMExistingUser,
            FGMExistingUserGroup,
            FGMCreateUserGroup,
            FGMCreateFolderGroup,
            appInputDlg : InputDlg,
            MultiSelectList,
            Loader
        },
        props: {
            userInfo: Object,
            localData: Object,
            value: {
                default: false,
                type: Boolean
            }
        },
        data() {
            return {
                startingUp: false,
                selectedFolderGroup: null,
                showRenameFolderGroup: false,
                loadingFolderGroup: null,
                folderGroupButtons: [{icon: 'edit', color: 'primary', cb: this.renameFolderGroupInit, hoverItem: true},],
                folderGroupWaiting: false,
                selectedUserGroups: [],
                loadingUserGroups: [],
                userGroupButtons: [
                    {icon: 'remove', color: 'red', cb: this.removeUserGroupsFromFolderGroup, hoverItem: 'selectedOnly'},
                    {icon: this.getUGIcon, color: this.getUGColor, cb: this.setUserGroupAllowDelegation, hoverItem: false},
                ],
                selectedUsers: [],
                loadingUsers: [],
                userButtons: [
                    {icon: 'remove', color: 'red', cb: this.removeUsersFromUserGroup, hoverItem: 'selectedOnly'},
                ],
            }
        },
        watch: {
            selectedFolderGroup: {
                immediate: true,
                deep: true,
                handler() {
                    try {
                        this.$refs.userGroupSelectList.clearFilter();
                    } catch (e) {
                        // eslint-disable-next-line
                    }
                    this.selectedUserGroups = [];
                    this.loadingUserGroups = [];
                }
            },
            selectedUserGroups: {
                immediate: true,
                deep: true,
                handler() {
                    try {
                        this.$refs.userSelectList.clearFilter();
                    } catch (e) {
                        // eslint-disable-next-line
                    }
                    this.selectedUsers = [];
                    this.loadingUsers = [];
                }
            }
        },
        methods: {

            setUserGroupAllowDelegation(userGroup) {
                let userGroupChanges = [];

                feed.on('userGroupChange', (data) => {
                    userGroupChanges.push(data)
                });

                let vm = this;

                function waitForAddedUserGroup(reUserGroup, waitCycle = 0) {
                    return new Promise((resolve, reject) => {
                        if (waitCycle > 20000) {
                            reject('Timeout')
                        } else {
                            if (userGroupChanges.map(obj => {
                                return obj.id
                            }).includes(reUserGroup)) {
                                vm.$nextTick(() => {
                                    resolve(reUserGroup)
                                })
                            } else {
                                setTimeout(() => {
                                    resolve(waitForAddedUserGroup(reUserGroup, waitCycle + 100))
                                }, 100)
                            }
                        }
                    })
                }

                this.loadingUserGroups = [userGroup.id];

                this.fetch({
                    method: 'PATCH',
                    url: `/folderManager/security/fgm/userGroup/allowDelegation/${this.userInfo.entityID}/${userGroup.id}/${boolToInt(!intToBool(userGroup.allowDelegation))}`,
                    body: this.selectedUsers
                })
                    .then(() => {
                        waitForAddedUserGroup(userGroup.id)
                            .then(() => {
                                this.loadingUserGroups = [];
                                this.showSnack('Info', userGroup.allowDelegation === 1 ? 'Delegation Disabled' : 'Delegation Enabled', 'Close', 'primary')
                            })
                            .catch((e) => {
                                console.log(e)
                            })
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e);
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
                        this.loadingUserGroups = [];
                    })
            },

            getUGIcon(userGroup) {
                if (userGroup.allowDelegation === 1) {
                    return 'people'
                } else {
                    return 'visibility'
                }
            },

            getUGColor(userGroup) {
                if (userGroup.allowDelegation === 1) {
                    return 'grey lighten-3'
                } else {
                    return 'blue'
                }
            },

            removeUserGroupsFromFolderGroup() {

                let folderGroupChanges = [];

                feed.on('folderGroupChange', (data) => {
                    folderGroupChanges.push(data)
                });

                let vm = this;

                function waitForAddedFolderGroup(reFolderGroup, waitCycle = 0) {
                    return new Promise((resolve, reject) => {
                        if (waitCycle > 20000) {
                            reject('Timeout')
                        } else {
                            if (folderGroupChanges.map(obj => {
                                return obj.id
                            }).includes(reFolderGroup)) {
                                vm.$nextTick(() => {
                                    resolve(reFolderGroup)
                                })
                            } else {
                                setTimeout(() => {
                                    resolve(waitForAddedFolderGroup(reFolderGroup, waitCycle + 100))
                                }, 100)
                            }
                        }
                    })
                }

                this.loadingUserGroups = this.selectedUserGroups;

                this.fetch({
                    method: 'PATCH',
                    url: `/folderManager/security/fgm/folderGroup/removeUserGroups/${this.userInfo.entityID}/${this.selectedFolderGroup}`,
                    body: this.selectedUserGroups
                })
                    .then(() => {
                        this.selectedUserGroups = [];
                        waitForAddedFolderGroup(this.selectedFolderGroup)
                            .then((folderGroup) => {
                                this.loadingUserGroups = [];
                                this.showSnack('Info', 'User Group/s Removed', 'Close', 'primary')
                            })
                            .catch((e) => {
                                console.log(e)
                            })
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e);
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
                        this.loadingUserGroups = [];
                    })
            },

            addNewUserGroup(group) {
                this.$refs.userGroupSelectList.clearFilter();
                this.$nextTick(() => {
                    this.selectedUserGroups = typeof group === 'number' ? [group] : group;
                });
            },

            addNewUsers(group) {
                this.$refs.userSelectList.clearFilter();
                this.$nextTick(() => {
                    this.selectedUsers = group;
                });
            },

            addFolderGroup(group) {
                this.$refs.folderGroupSelectList.clearFilter();
                this.$nextTick(() => {
                    this.selectedFolderGroup = group;
                });
            },

            deleteFN(item) {
                this.showSnack('Info', 'DELETE Clicked On ' + item.description, 'Close', 'primary')
            },

            renameFolderGroupInit(folderGroup) {
                this.selectedFolderGroup = folderGroup.id;
                this.showRenameFolderGroup = true;
            },
            renameFolderGroupRun(val) {
                if (blankString(val)) {
                    this.showSnack('Error', 'A description is required', 'Close', 'red')
                } else {
                    this.showRenameFolderGroup = false;
                    this.loadingFolderGroup = this.selectedFolderGroup;

                    this.fetch({method: 'PATCH', url:`/folderManager/security/folderGroup/${this.userInfo.entityID}/${this.selectedFolderGroup}`, body: JSON.stringify({description: val})})
                        .then(() => {
                            this.folderGroup.description = val;
                            this.loadingFolderGroup = null;
                            this.showSnack('Info', 'Changes Saved', 'Close', 'primary')
                        })
                        .catch((e) => {
                            // eslint-disable-next-line
                            console.log(e);
                            this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
                            this.loadingFolderGroup = null;
                        });
                }
            },
            //
            // test() {
            //     this.$refs.folderGroupSelectList.clearFilter();
            // },

            removeUsersFromUserGroup() {
                let userGroupChanges = [];

                feed.on('userGroupChange', (data) => {
                    userGroupChanges.push(data)
                });

                let vm = this;

                function waitForAddedUserGroup(reUserGroup, waitCycle = 0) {
                    return new Promise((resolve, reject) => {
                        if (waitCycle > 20000) {
                            reject('Timeout')
                        } else {
                            if (userGroupChanges.map(obj => {
                                return obj.id
                            }).includes(reUserGroup)) {
                                vm.$nextTick(() => {
                                    resolve(reUserGroup)
                                })
                            } else {
                                setTimeout(() => {
                                    resolve(waitForAddedUserGroup(reUserGroup, waitCycle + 100))
                                }, 100)
                            }
                        }
                    })
                }

                this.loadingUsers = this.selectedUsers;

                this.fetch({
                    method: 'PATCH',
                    url: `/folderManager/security/fgm/userGroup/removeUsers/${this.userInfo.entityID}/${this.selectedUserGroups[0]}`,
                    body: this.selectedUsers
                })
                    .then(() => {
                        this.selectedUsers = [];
                        waitForAddedUserGroup(this.selectedUserGroups[0])
                            .then(() => {
                                this.loadingUsers = [];
                                this.showSnack('Info', 'User/s Removed', 'Close', 'primary')
                            })
                            .catch((e) => {
                                console.log(e)
                            })
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e);
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
                        this.loadingUsers = [];
                    })
            }


        },
        computed: {
            folderGroup() {
                let oGroups = this.localData.folderGroups.filter(obj => obj.id === this.selectedFolderGroup)
                return arrPopulated(oGroups) ? oGroups.pop() : null
            },

            folderGroupUserGroups() {
                if (this.selectedFolderGroup === null) {
                    return null
                } else {
                    let fUserGroups = this.folderGroup.userGroups
                        .filter(obj => obj != null);

                    return this.localData.userGroups
                        .filter(obj => fUserGroups.includes(obj.id))
                }
            },

            availableAddUserGroups() {
                if (this.selectedFolderGroup === null) {
                    return null
                } else {
                    let fUserGroups = this.folderGroup.userGroups
                        .filter(obj => obj != null);

                    return this.localData.userGroups
                        .filter(obj => !fUserGroups.includes(obj.id))
                }
            },

            folderGroupUserGroupUsers() {
                if (this.selectedFolderGroup !== null && arrPopulated(this.selectedUserGroups) && this.selectedUserGroups.length === 1) {
                    let fUsers = this.localData.userGroups
                        .filter(obj => obj.id === this.selectedUserGroups[0])
                        .pop()['users']
                        .filter(usr => usr != null);

                    return this.localData.users
                        .filter(obj => fUsers.includes(obj.userID))
                        .map(user => {
                            return {
                                id: user.userID,
                                description: blankString(user.name) && blankString(user.surname) ? user.username : `${user.name} ${user.surname}`
                            }
                        })
                } else {
                    return null
                }
            },

            availableAddUsers() {
                if (this.selectedFolderGroup !== null && arrPopulated(this.selectedUserGroups) && this.selectedUserGroups.length === 1) {
                    let fUsers = this.localData.userGroups
                        .filter(obj => obj.id === this.selectedUserGroups[0])
                        .pop()['users']
                        .filter(usr => usr != null);

                    return this.localData.users
                        .filter(obj => !fUsers.includes(obj.userID))
                        .map(user => {
                            return {
                                id: user.userID,
                                description: blankString(user.name) && blankString(user.surname) ? user.username : `${user.name} ${user.surname}`
                            }
                        })
                } else {
                    return null
                }
            },
        }
    }
</script>

<style scoped>

</style>
