<template>
    <div
            @click="$emit('selectField', field)"
            :class="selectedField != null && selectedField.id === field.id ? 'selected-item' : 'hover-item'"
            style="overflow-x: hidden"
            ref="dragArea"
            @drag="$event.preventDefault()"
            @dragstart="setDataTransfer($event)"
            @dragend="handleDragEnd"
    >
        <v-layout row align-center class=" pl-1 pr-0" :class="borderClass" >
            <v-flex shrink v-if="savingChanges">
                <v-progress-circular
                        :size="20"
                        :width="3"
                        color="primary"
                        indeterminate
                />
            </v-flex>
            <v-flex shrink v-if="!recycleBin && !savingChanges">
                <v-icon small style="cursor: move" class="handle">drag_indicator</v-icon>
            </v-flex>

            <v-flex
                    xs12
                    @mousedown="handleDragStart"
                    @mouseup="handleDragEnd"

            >

                <v-layout row align-center>
                    <v-flex xs12>
                        <div>
                            <v-layout row align-center>
                                <v-flex
                                        xs12 class="caption pa-1"
                                        :class="fieldVisibleClass"
                                        @click="() => {}"
                                >
                                    <div
                                            :class="field.type !== 16 ? textClass : 'px-1 caption white--text primary'"
                                            :style="field.type === 16 ? 'border-radius: 3px; display: inline-block' : ''"
                                    >
                                        {{`${field.description}${!intToBool(field.visible) ? ' (Disabled)' : ''}`}}
                                    </div>
                                    <v-layout row align-center>
                                        <v-flex xs12>
                                            <div class="font-weight-light caption" :class="field.linkLookup != null ? 'secondary--text' : fieldVisibleClass != '' ? fieldVisibleClass : 'primary--text'" style="width: 100%;">
                                                <v-icon small color="primary" class="pa-0 ma-0" v-if="field.securityLookup != null && ((Array.isArray(field.securityLookup.editableBy) && field.securityLookup.editableBy.length >= 1) || (Array.isArray(field.securityLookup.shownTo) && field.securityLookup.shownTo.length >= 1))">security</v-icon>
                                                {{fieldType}}
                                            </div>
                                        </v-flex>
                                        <v-flex shrink v-if="linkedGroup != null" style="white-space: nowrap; border-radius: 3px" class="px-1 caption white--text primary">{{linkedGroup}}</v-flex>
                                    </v-layout>

                                </v-flex>
                            </v-layout>
                        </div>
                    </v-flex>
                    <v-flex shrink v-if="field.useInName == '1'" class="px-2">
                        <v-tooltip top>
                            <v-icon slot="activator" color="green" class="handle">done</v-icon>
                            <span>Use In Name</span>
                        </v-tooltip>
                    </v-flex>
                </v-layout>
            </v-flex>
        </v-layout>
        <v-divider/>
    </div>
</template>

<script>
    import {codeBaseMixin} from "../../../codeBaseMixin";

    export default {
        mixins: [codeBaseMixin],
        props: {
            userInfo: Object,
            field: Object,
            userGroups: Array,
            selectLists: Array,
            selectedField: Object,
            saveFields: Array,
            allFields: Array,
        },
        data() {
            return {
                recycleBin: false,
                showConfigure: false,
                dragTooltip: false
            }
        },



        methods: {
            setDataTransfer(event) {
                event.dataTransfer.setData('text', JSON.stringify(this.field));
            },

            handleDragStart() {
                this.dragTooltip = false;
                this.$refs.dragArea.draggable = true;
            },

            handleDragEnd() {
                this.dragTooltip = false;
                this.$refs.dragArea.draggable = false;
            },
        },

        computed: {
            linkedGroup() {
                try {
                    if (typeof this.field.fk_field_group != 'undefined' && this.field.fk_field_group !== null) {
                        let tReturn = this.allFields.filter(obj => obj.id === this.field.fk_field_group).pop()
                        return tReturn.description
                    } else {
                        return null
                    }
                } catch (e) {
                    return null
                }

            },

            savingChanges() {
                return this.saveFields.includes(this.field.id)
            },

            textClass() {
                return this.selectedField != null && this.selectedField.id === this.field.id
                    ? 'font-weight-bold caption'
                    : (
                        this.intToBool(this.field.visible)
                            ? 'font-weight-regular caption'
                            : 'greyType--text font-weight-regular caption'
                    )
            },

            fieldType() {
                switch (this.field.type) {
                    case 1: {
                        return 'Word Field';
                    }
                    case 2: {
                        return 'Date Field';
                    }
                    case 3: {
                        return 'Number Field';
                    }
                    case 4: {
                        return 'Currency Field';
                    }
                    case 5: {
                        return 'List Field';
                    }
                    case 6: {
                        return 'Mail/Document Field';
                    }
                    case 13 : {
                        return "Note Field";
                    }
                    case 16 : {
                        return "Group Field";
                    }
                  default: {
                    return ''
                  }
                }
            },

            fieldVisibleClass() {
                try {
                    return !this.field.visible ? 'greyType--text' : ''
                } catch (e) {
                    // eslint-disable-next-line
                    console.log(e)
                    return 'greyType--text'
                }
            },

            borderClass() {
                try {
                    return  this.field.visible == 0 ? 'regularFieldDisabled' : (this.field.required == 1 ? 'regularFieldRequired' : 'regularField')
                } catch (e) {
                    // eslint-disable-next-line
                    console.log(e)
                    return 'greyType--text'
                }
            },
        },
    }
</script>

<style scoped>
    .regularField{
        cursor: pointer;
        margin-left: 3px;
    }

    .regularFieldDisabled{
        /*border-radius: 3px;*/
        cursor: pointer;
        border-left: 3px solid var(--v-tGrey-base);
    }

    .regularFieldRequired{
        /*border-radius: 3px;*/
        cursor: pointer;
        border-left: 3px solid var(--v-secondary-base);
    }
</style>
