<template>
    <div class="pa-1 fill-height">
        <v-card class="c-d-flex c-flex-column fill-height">

<!--            <div class="">-->
<!--                Hello Title-->
<!--            </div>-->
            <div class="of-y c-flex-grow-1">

<!--Week View-->
                <div class="fill-height c-d-flex c-flex-column ">


<!--Month Filters And Adjusters-->
                    <slot name="header"></slot>
                    <div class="c-flex-grow-1 c-d-flex c-bord-t c-bord-b" style="overflow-x: auto" :id="scrollContainerID">

                        <slot></slot>
                    </div>
                </div>
            </div>
        </v-card>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
    props: {
        scrollContainerID: {
            type: String,
            default: 'scrollContainer'
        }
    },
    data() {
        return {

        }
    },
    watch: {},
    methods: {},
    computed: {
        ...mapGetters('userInfo', ['userInfo'])
    },
}
</script>

<style scoped>



</style>