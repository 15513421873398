<template>
    <v-layout column fill-height style="overflow-y: auto">
        <v-flex shrink>
            <v-text-field
                    label="Search For Field"
                    single-line
                    v-model="searchString"
                    color="primary"
                    style="width: 100%"
                    class="ma-0 pa-0 px-1 pt-2"
                    type="email"
                    clearable
                    prepend-icon="search"
                    @input="dropCollapse"
                    hide-details
            ></v-text-field>
        </v-flex>

        <v-flex xs12 style="overflow-y: auto" class="scroll pt-2">

<!--Select Fields-->
            <template v-for="(step, i) in orderedSteps">

                <v-layout align-center row :key="'configureField' + step.fieldID">
                    <v-flex shrink class="px-1">
                        <v-btn
                                flat
                                icon
                                small
                                color="primary"
                                class="pa-0 ma-0"
                                @click="droppedStep(i)"
                        >
                            <v-icon>{{ dropped[i] ? 'arrow_drop_up' : 'arrow_drop_down'}}</v-icon>
                        </v-btn>
                    </v-flex>
                    <v-flex xs12>
                        <span class="body-1">{{ step.description }}</span>
                    </v-flex>
                </v-layout>

                <template v-if="dropped[i]">
                    <template v-for="(field) in step.fields">
                        <div
                                @drag="$event.preventDefault()"
                                @dragstart="setDataTransfer($event, step, field)"
                                :draggable="field.type <= 5"
                                :key="'configureFieldField-' + field.id"
                                style="cursor: pointer"
                        >
                            <v-layout :key="'configureFieldField' + field.id" class="pl-3 pb-2" align-center @click="field.type >= 6 ? fieldSelected(step, field) : ''">
                                <v-flex shrink>
                                    <v-icon color="primary">{{field.type >= 6 ? 'add' : 'label'}}</v-icon>
                                </v-flex>
                                <v-flex xs12 class="pl-2">
                                    <span class="body-1">{{ field.description }}</span>
                                </v-flex>
                            </v-layout>
                        </div>

                    </template>
                </template>

            </template>

        </v-flex>
    </v-layout>
</template>

<script>
    export default {
        props: {
            userInfo: Object,
            fields: Array,
            steps: Array,
            typeFilter: Array,
            fieldSets: Array
        },
        data() {
            return {
                dropped: [],
                searchString: ''
            }
        },
        created() {
            this.orderedSteps.forEach(() => {this.dropped.push(false)});
        },
        methods: {

            setDataTransfer(event, step, field) {
                event.dataTransfer.setData('text', `&nbsp;<span id="2-${step.fieldID}-${field.id}" style="background: #1565c0; border-radius: 4px;  color: white; text-align: center; padding: 1px 4px; font-size: 11px; outline: none">${field.description}</span>&nbsp;`)
            },

            fieldSelected(selectField, fieldSetField) {
                this.$emit('input', {
                    selectField: selectField.fieldID,
                    fieldSetField: fieldSetField.id,
                    type: fieldSetField.type,
                    selectFieldDescription: selectField.description,
                    fieldsetFieldDescription: fieldSetField.description
                })
            },

            droppedStep(i) {
                this.dropped[i] = !this.dropped[i]
                this.$forceUpdate();
            },

            dropCollapse() {
                if (this.searchString != null && this.searchString != '') {
                    this.dropped.forEach((value, i) => {
                        this.dropped[i] = true
                    });
                } else {
                    this.dropped.forEach((value, i) => {
                        this.dropped[i] = false
                    });
                }
                this.$forceUpdate();
            }
        },
        computed: {
            orderedSteps() {
                let tReturn = [];
                this.fields.forEach(field => {
                    if (field.fieldType == 9) {

                        let tField = {
                            fieldID: field.id,
                            fieldSetID: field.config.dataset,
                            description: field.description,
                            fields: []
                        };

                        //Get The Fields For That Select Field AKA step
                        this.fieldSets.forEach(FS => {
                            if (FS.id == tField.fieldSetID) {
                                FS.fields.forEach(fieldsetField => {
                                    if (this.searchString == null || this.searchString == '' || fieldsetField.description.toLowerCase().includes(this.searchString.toLowerCase())) {
                                        if (this.typeFilter.length > 0) {
                                            if (this.typeFilter.includes(fieldsetField.type)) {
                                                tField.fields.push(fieldsetField)
                                            }
                                        } else {
                                            tField.fields.push(fieldsetField)
                                        }
                                    }
                                })
                            }
                        });
                        if (tField.fields.length > 0) {
                            tReturn.push(tField)
                        }
                    }
                });

                tReturn.sort((a,b) => a['description'] < b['description'] ? -1 : 1);

                return tReturn
            },
        },

    }
</script>

<style scoped>

</style>
