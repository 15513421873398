<template >
    <div>
        <v-menu offset-y full-width :close-on-content-click="false" v-model="selectingField">
            <template v-slot:activator="{ on }">
                    <div v-show="linkLookupDisplay == null" v-on="on">
                        <v-btn small flat color="secondary" class="ma-0">Select Dataset Field</v-btn>
                    </div>

                    <v-card ripple hover flat v-show="linkLookupDisplay != null" class="primary white--text" v-on="on">
                        <v-layout row align-center style="cursor: pointer" v-if="linkLookupDisplay != null">
                            <v-flex shrink class="pa-1 pl-2" v-if="includeAutoPopulate && autoPopulateDisplay != null">
                                <v-tooltip top>
                                    <v-icon
                                            slot="activator"
                                            small
                                            color="white"
                                            class="pa-0 ma-0"
                                    >
                                        {{autoPopulateDisplay ? 'sync_alt' : 'arrow_right_alt'}}
                                    </v-icon>
                                    <span>Auto Populate Enabled</span>
                                </v-tooltip>
                            </v-flex>
                            <v-flex xs12 class="pa-1 pl-2">
                                {{`${linkLookupDisplay.fieldSetSelectField} - ${linkLookupDisplay.datasetField}`}}
                            </v-flex>
                            <v-flex shrink class="pr-1">
                                <v-tooltip top>
                                    <v-btn
                                            small
                                            flat
                                            icon
                                            color="white"
                                            class="pa-0 ma-0"
                                            slot="activator"
                                            @click="removeDSLinkRun"
                                    >
                                        <v-icon small>delete</v-icon>
                                    </v-btn>
                                    <span>Remove Dataset Link</span>
                                </v-tooltip>
                            </v-flex>
                            <v-flex shrink class="pr-1">
                                <v-tooltip top>
                                    <v-btn
                                            small
                                            flat
                                            icon
                                            color="white"
                                            class="pa-0 ma-0"
                                            slot="activator"
                                    >
                                        <v-icon small>edit</v-icon>
                                    </v-btn>
                                    <span>Edit Dataset Link</span>
                                </v-tooltip>
                            </v-flex>
                        </v-layout>
                    </v-card>
            </template>

            <v-card>
                <v-layout row align-center class="pa-1">
                    <v-flex xs12>
                        <v-layout row>
                            <v-flex xs12>
                                <div class="pa-1">
                                    <div class="caption greyType--text font-weight-bold">
                                        Dataset Select Field:
                                    </div>
                                    <div class="pl-2">
                                        <v-autocomplete
                                                dense
                                                v-model="fieldSetSelectFieldModel"
                                                :items="fieldSetSelectFields"
                                                label="Select Field"
                                                hide-details
                                                single-line
                                                color="primary"
                                                item-text="description"
                                                item-value="id"
                                                class="pa-0 ma-0"
                                        ></v-autocomplete>
                                    </div>
                                </div>
                            </v-flex>
                            <v-flex xs12>
                                <div class="pa-1">
                                    <div class="caption greyType--text font-weight-bold">
                                        Dataset Field:
                                    </div>
                                    <div class="pl-2">
                                        <v-autocomplete
                                                dense
                                                v-model="field"
                                                :items="fieldSetFields"
                                                label="Select Field"
                                                hide-details
                                                single-line
                                                color="primary"
                                                item-text="description"
                                                item-value="id"
                                                class="pa-0 ma-0"
                                                :disabled="fieldSetSelectFieldModel == null"
                                        ></v-autocomplete>
                                    </div>
                                </div>
                            </v-flex>
                        </v-layout>
                        <div class="pa-1" v-if="includeAutoPopulate && autoPopulateLookupValue != null">
                            <div class="caption greyType--text font-weight-bold">
                                Auto Populate:
                            </div>
                            <div class="pl-2" style="overflow-y: hidden">
                                <v-switch
                                        v-model="autoPopulateLookupValue"
                                        :label="autoPopulateLookupValue ? 'Auto Populate' : 'Update Dataset Only'"
                                        hide-details
                                        color="secondary"
                                        class="pa-0 ma-0"
                                ></v-switch>
                            </div>
                        </div>
                    </v-flex>
                    <v-flex shrink class="pr-1">
                        <v-tooltip top>
                            <v-btn
                                    icon
                                    flat
                                    class="pa-0 ma-0"
                                    color="secondary"
                                    slot="activator"
                                    @click="cancelRun"
                            >
                                <v-icon>close</v-icon>
                            </v-btn>
                            <span>Cancel</span>
                        </v-tooltip>
                    </v-flex>
                    <v-flex shrink class="pr-1">
                        <v-tooltip top>
                            <v-btn
                                    icon
                                    flat
                                    class="pa-0 ma-0"
                                    color="primary"
                                    slot="activator"
                                    @click="doneRun"
                                    :disabled="fieldSetSelectField == null || field == null"
                            >
                                <v-icon>done</v-icon>
                            </v-btn>
                            <span>Done</span>
                        </v-tooltip>
                    </v-flex>
                </v-layout>
            </v-card>
        </v-menu>
        <app-input-dlg
                :show-dialog="showConfirmRemoveLink"
                title="Confirm Removal"
                body="Please note Dataset link will be removed."
                :cancel-button="true"
                :show-input-box="false"
                :buttons="['Cancel', 'Confirm']"
                :call-backs="[ cancelRemoveDatasetLink, confirmRemoveDatasetLink ]"
                @dismiss="showConfirmRemoveLink = false"
        />
    </div>
</template>

<script>
    import {codeBaseMixin} from "../../../../../../codeBaseMixin";

    import InputDlg from "../../../../../../components/General/InputDlg";
    export default {
        mixins: [codeBaseMixin],
        components: {
            appInputDlg: InputDlg
        },
        props: {
            fields: Array,
            fieldSets: Array,
            value: Object,
            fieldTypeFilter: Array,
            includeAutoPopulate: {
                type: Boolean,
                default: true
            }
        },
        data() {
            return {
                showConfirmRemoveLink: false,
                selectingField: false,
                field: null,
                fieldSetSelectField: null,
                autoPopulate: 1
            }
        },
        watch: {
            value: {
                immediate: true,
                handler(val) {
                    this.resetLocalValues(val)
                }
            }
        },
        methods: {
            doneRun() {

                let input = this.fieldSetSelectField != null && this.field != null ? {
                    field: this.field,
                    fieldSetSelectField: this.fieldSetSelectField,
                } : null;

                if (this.fieldSetSelectField != null && this.field != null && this.includeAutoPopulate) {
                    input.autoPopulate = this.autoPopulate
                }
                this.$emit('input', input)

                this.selectingField = false;
            },

            cancelRun() {
                this.resetLocalValues(this.value);
                this.selectingField = false
            },

            resetLocalValues(val) {
                this.field = null;
                this.fieldSetSelectField = null;
                if (
                    val != null
                    && typeof val.field != 'undefined'
                    && typeof val.fieldSetSelectField != 'undefined'
                    && val.field != null
                    && val.fieldSetSelectField != null
                ) {
                    this.field = val.field;
                    this.fieldSetSelectField = val.fieldSetSelectField;
                    if (typeof val.autoPopulate != 'undefined' && val.autoPopulate != null) {
                        this.autoPopulate = val.autoPopulate;
                    } else {
                        this.autoPopulate = 1;
                    }
                }
            },

            resetField() {
                if (!this.fieldSetFields.map(obj => {
                    return obj.id
                }).includes(this.field)) {
                    this.field = null
                }
            },
            removeDSLinkRun() {
                setTimeout(() => {
                    this.selectingField = false;
                    this.showConfirmRemoveLink = true;
                }, 1)
            },

            cancelRemoveDatasetLink() {
                this.showConfirmRemoveLink = false
            },
            confirmRemoveDatasetLink() {
                this.showConfirmRemoveLink = false;
                this.$emit('input', null)
            },
        },
        computed: {

            autoPopulateLookupValue: {
                get() {
                    if (this.field != null && this.fieldSetSelectField != null) {
                        return this.intToBool(this.autoPopulate)
                    } else {
                        return null
                    }
                },
                set(newValue) {
                    this.autoPopulate = this.boolToInt(newValue);
                }
            },

            autoPopulateDisplay() {
                if (this.linkLookupDisplay != null) {
                    if (typeof this.value.autoPopulate != 'undefined' && this.value.autoPopulate != null) {
                        return this.intToBool(this.value.autoPopulate)
                    } else {
                        return true
                    }
                } else {
                    return null
                }

            },

            fieldSetFields() {
                try {
                    if (this.fieldSetSelectField != null) {
                        let tFieldSetID = this.fields.filter(obj => obj.id == this.fieldSetSelectField).map(obj => {return parseInt(obj.config.dataset)}).pop();
                        return this.fieldSets.reduce((fields, fieldSet) => {
                            if (fieldSet.id == tFieldSetID) {
                                fields = fieldSet.fields.filter(obj => this.fieldTypeFilter.includes(obj.type) && obj.visible == 1)
                                    .map(obj => {return {description: obj.description, id: obj.id}})
                                    .sort((a,b) => a['description'] < b['description'] ? -1 : 1);
                            }
                            return fields
                        }, []);
                    } else {
                        return []
                    }
                } catch (e) {
                    // eslint-disable-next-line
                    console.log(e);
                    return [];
                }
            },

            fieldSetSelectFields() {
                return this.fields.filter(obj => obj.fieldType == 9 && obj.visible == 1)
                    .map(obj => {return {description: obj.description, id: obj.id}})
                    .sort((a,b) => a['description'] < b['description'] ? -1 : 1);
            },

            fieldSetSelectFieldModel: {
                get: function () {
                    return this.fieldSetSelectField
                },
                set: function (newValue) {
                    this.fieldSetSelectField = newValue;
                    this.resetField();
                }
            },

            linkLookupDisplay() {
                if (
                    this.value != null
                    && typeof this.value.field != 'undefined'
                    && typeof this.value.fieldSetSelectField != 'undefined'
                    && this.value.field != null
                    && this.value.fieldSetSelectField != null
                ) {
                    try {
                        // Get The value field Name
                        let selectFieldData = this.fields.reduce((value, field) => {
                            if (field.id == this.value.fieldSetSelectField) {
                                value = {
                                    description: field.description,
                                    lookup: parseInt(field.config.dataset)
                                }
                            }
                            return value
                        }, null);
                        let datasetField = this.fieldSets.filter(obj => obj.id == selectFieldData.lookup)
                            .map((fieldset) => {
                                return fieldset.fields.filter(obj => obj.id == this.value.field)
                                    .map(obj => {
                                        return obj.description
                                    })
                            })
                            .pop().pop();

                        return {
                            fieldSetSelectField: selectFieldData.description,
                            datasetField: datasetField
                        }

                    } catch (e) {
                        // eslint-disable-next-line
                        console.log(e)
                        return null
                    }
                } else {
                    return null
                }
            },
        }
    }
</script>

<style scoped>

</style>
