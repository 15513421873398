<template>
  <v-dialog
      v-model="valuePassOver"
      fullscreen
      persistent
  >
    <v-card class="scrollable"
            style="height: 100vh; overflow-y: auto; overflow-x: hidden; background: var(--page-back);">
      <v-layout column fill-height class="scrollable">
        <!--Title-->
        <v-flex shrink class="title-color">
          <v-layout row align-center>
            <v-flex xs12>
              <v-layout row align-center>
                <v-flex shrink>
                  <v-icon class="pl-2" color="white">groups</v-icon>
                </v-flex>
                <v-flex xs12 class="font-weight-regular white--text px-2 py-1 title">
                  User Groups Manager
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex shrink class="px-2">
              <v-btn
                  icon
                  flat
                  color="white"
                  class="pa-0 ma-0"
                  @click="valuePassOver = !valuePassOver"
              >
                <v-icon>close</v-icon>
              </v-btn>
            </v-flex>
          </v-layout>
          <v-divider class="title-color darken-1"></v-divider>
        </v-flex>
        <!--Loading-->
        <v-flex xs12 fill-height v-if="loadingStartup || savingChanges">
          <v-layout fill-height align-center justify-center>
            <v-flex shrink>
              <v-progress-circular
                  :size="40"
                  :width="3"
                  color="primary"
                  indeterminate
              ></v-progress-circular>
            </v-flex>
          </v-layout>
        </v-flex>
        <!--Body-->
        <v-flex xs12 fill-height class="scrollable c-d-flex" v-else>
          <!--Select User Group-->
          <div class="c-flex-equal c-flex-grow-1 c-d-flex c-flex-column">
            <multi-select-list
                :show-action-buttons="true"
                :action-buttons="[{icon: 'edit', color: 'primary', cb: renameUserGroup, hoverItem: true},]"
                :show-check-boxes="true"
                :multiple="false"
                headline-key="description"
                item-key="id"
                :return-object="false"
                v-model="selectedUG"
                :show-filter="true"
                :items="userGroupsSorted"
                :dark="true"
                filter-label="Filter User Groups"
            >
              <div slot="header" class="title-color px-2 py-1">
                <div class="subheading white--text pb-1">
                  Select User Group
                </div>
                <!--                                <v-btn-->
                <!--                                        small-->
                <!--                                        outline-->
                <!--                                        block-->
                <!--                                        color="white"-->
                <!--                                        class="pa-0 ma-0"-->
                <!--                                >-->
                <!--                                    Add User Group-->
                <!--                                </v-btn>-->
              </div>
            </multi-select-list>
          </div>
          <v-divider :vertical="true" class="title-color darken-1"></v-divider>
          
          <template v-if="!selectedUG">
            <div v-for="col in cols - 1" :key="col" class="c-flex-equal c-flex-grow-1"></div>
          </template>
          <template v-else>
            <!--Users-->
            <template v-if="userInfo.userManager">
              <div class="c-flex-equal c-flex-grow-1">
                <multi-select-list
                    :show-action-buttons="false"
                    :show-check-boxes="true"
                    :multiple="true"
                    headline-key="fullName"
                    sub-line-key="username"
                    item-key="userID"
                    :return-object="false"
                    v-model="editGroup.users"
                    :show-filter="true"
                    :items="users"
                    :dark="true"
                    filter-label="Filter Users"
                    :show-select-all="false"
                    :cutom-sort-fn="userSort"
                >
                  <div slot="header" class="title-color px-2 py-1">
                    <div class="subheading white--text pb-1">
                      <!--                                            <div class="subheading white&#45;&#45;text pb-1" style="margin-bottom: 28px">-->
                      Users
                    </div>
                  </div>
                  
                  <template v-slot:headline="{value}">
                    <div :class="value.disabled ? 'grey--text' : ''">
                      {{ value.fullName }}{{ value.disabled ? ' (Disabled)' : '' }}
                    </div>
                  </template>
                
                </multi-select-list>
              </div>
              <v-divider :vertical="true" class="title-color darken-1"></v-divider>
            </template>
            <!--Folder Groups-->
            <template v-if="this.userInfo.folderManager">
              <div class="c-flex-equal c-flex-grow-1">
                <multi-select-list
                    :show-action-buttons="true"
                    :action-buttons="[{icon: 'edit', color: 'primary', cb: renameFolderGroup, hoverItem: true},]"
                    :show-check-boxes="true"
                    :multiple="true"
                    headline-key="description"
                    item-key="id"
                    :return-object="false"
                    v-model="editGroup.folderGroups"
                    :show-filter="true"
                    :items="folderGroups"
                    :dark="true"
                    filter-label="Filter Folder Groups"
                    :show-select-all="false"
                    :cutom-sort-fn="(a,b) => {
            if (editGroup.folderGroups.includes(a.id) && editGroup.folderGroups.includes(b.id)) return a.description > b.description ? 1 : -1
            if (editGroup.folderGroups.includes(a.id)) return -1
            if (editGroup.folderGroups.includes(b.id)) return 1
            return a.description > b.description ? 1 : -1
        }"
                >
                  <div slot="header" class="title-color px-2 py-1">
                    <div class="subheading white--text pb-1">
                      Folder Groups
                    </div>
                  </div>
                </multi-select-list>
              </div>
              <v-divider :vertical="true" class="title-color darken-1"></v-divider>
            </template>
            <!--Workflow Groups-->
            <template v-if="this.userInfo.workflowManager">
              <div class="c-flex-equal c-flex-grow-1">
                <multi-select-list
                    :show-action-buttons="true"
                    :action-buttons="[{icon: 'edit', color: 'primary', cb: renameWorkflowGroup, hoverItem: true},]"
                    :show-check-boxes="true"
                    :multiple="true"
                    headline-key="description"
                    item-key="id"
                    :return-object="false"
                    v-model="editGroup.workflowGroups"
                    :show-filter="true"
                    :items="workflowGroups"
                    :dark="true"
                    filter-label="Filter Workflow Groups"
                    :show-select-all="false"
                    :cutom-sort-fn="(a,b) => {
            if (editGroup.workflowGroups.includes(a.id) && editGroup.workflowGroups.includes(b.id)) return a.description > b.description ? 1 : -1
            if (editGroup.workflowGroups.includes(a.id)) return -1
            if (editGroup.workflowGroups.includes(b.id)) return 1
            return a.description > b.description ? 1 : -1
        }"
                >
                  <div slot="header" class="title-color px-2 py-1">
                    <div class="subheading white--text pb-1">
                      Workflow Groups
                    </div>
                    <!--                                        <v-btn-->
                    <!--                                                small-->
                    <!--                                                outline-->
                    <!--                                                block-->
                    <!--                                                color="white"-->
                    <!--                                                class="pa-0 ma-0"-->
                    <!--                                        >-->
                    <!--                                            Add Workflow Group-->
                    <!--                                        </v-btn>-->
                  </div>
                </multi-select-list>
              </div>
              <v-divider :vertical="true" class="title-color darken-1"></v-divider>
            </template>
            <!--DataSets-->
            <template v-if="this.userInfo.fieldsetManager">
              <div class="c-flex-equal c-flex-grow-1">
                <multi-select-list
                    :show-action-buttons="false"
                    :show-check-boxes="true"
                    :multiple="true"
                    headline-key="description"
                    item-key="id"
                    :return-object="false"
                    :value="selectedDatasets"
                    :show-filter="true"
                    :items="dataSets"
                    :dark="true"
                    filter-label="Filter Datasets"
                    @input="modelDataSets($event)"
                    :show-select-all="false"
                    :cutom-sort-fn="(a,b) => {
            if (selectedDatasets.includes(a.id) && selectedDatasets.includes(b.id)) return a.description > b.description ? 1 : -1
            if (selectedDatasets.includes(a.id)) return -1
            if (selectedDatasets.includes(b.id)) return 1
            return a.description > b.description ? 1 : -1
        }"
                >
                  <div slot="header" class="title-color px-2 py-1">
                    <div class="subheading white--text pb-1">
                      Datasets
                    </div>
                  </div>
                  <template v-slot:action="{value, index, selected}">
                    <div v-if="selected" class="pr-2">
                      <v-tooltip left>
                        <template v-slot:activator="{on}">
                          <v-btn
                              v-on="on"
                              icon
                              flat
                              class="pa-0 ma-0"
                              small
                              @click="dataSetMembersByID[value.id].readOnly = dataSetMembersByID[value.id].readOnly ? 0 : 1"
                              color="secondary"
                          >
                            <v-icon small>{{ dataSetMembersByID[value.id].readOnly ? 'edit_off' : 'edit' }}</v-icon>
                          </v-btn>
                        </template>
                        {{ dataSetMembersByID[value.id].readOnly ? 'Read Only' : 'Editable' }}
                      </v-tooltip>
                    </div>
                  </template>
                </multi-select-list>
              </div>
              <v-divider :vertical="true" class="title-color darken-1"></v-divider>
            </template>
          
          </template>
        </v-flex>
      </v-layout>
    </v-card>
    
    <div v-if="selectedUG" style="position: absolute; right: 0; bottom: 0" class="pa-3">
      <v-tooltip left>
        <template v-slot:activator="{on}">
          <v-btn
              icon
              color="secondary"
              class="pa-0 ma-0 elevation-4"
              v-on="on"
              :large=true
              @click="saveChanges()"
              :loading="savingChanges"
              :disabled="savingChanges"
          >
            <v-icon>save</v-icon>
          </v-btn>
        </template>
        Save Changes
      </v-tooltip>
    </div>
  
  </v-dialog>
</template>


<script>

import {mapGetters} from "vuex";
import MultiSelectList from "@/components/Multi Select List/MultiSelectList";
import {objectifyArr} from "@/codeFunctions";
import {showPrompt} from "@/components/General/AppInputPrompt/AppInputPrompt";

export default {
  components: {MultiSelectList},
  props: {
    value: Boolean
  },
  data() {
    return {
      loadingStartup: false,
      userGroups: [],
      workflowGroups: [],
      folderGroups: [],
      dataSets: [],
      selectedUG: null,
      editGroup: null,
      savingChanges: false,
    }
  },
  watch: {
    selectedUG: {
      immediate: true,
      handler(val) {
        this.editGroup = val ? JSON.parse(JSON.stringify(this.userGroups.filter(group => group.id === val).pop())) : null
        this.$nextTick(() => {
          this.$forceUpdate();
        })
      }
    },
    value: {
      immediate: true,
      handler(val) {
        if (val)
          this.refreshUserGroups();
      }
    }
  },
  methods: {
    
    async renameUserGroup(group) {
      const {id, description} = group
      const newDescription = await showPrompt({
        title: 'Rename User Group',
        body: `Please provide a new name for the group "${description}"`,
        buttonLabel: 'Rename',
        showCancel: true,
        defaultValue: description,
      })
      
      if (newDescription) {
        try {
          this.savingChanges = true
          await this.$newReq('POST', `ugm/rename/userGroup`, {id, description: newDescription})
          group.description = newDescription;
          this.$snack.info('Changes saved')
        } catch (e) {
          console.log(e)
          this.$snack.networkError()
        } finally {
          this.savingChanges = false
        }
      }
    },
    
    async renameFolderGroup(group) {
      const {id, description} = group
      const newDescription = await showPrompt({
        title: 'Rename Folder Group',
        body: `Please provide a new name for the folder group "${description}"`,
        buttonLabel: 'Rename',
        showCancel: true,
        defaultValue: description,
      })
      
      if (newDescription) {
        try {
          this.savingChanges = true
          await this.$newReq('POST', `ugm/rename/folderGroup`, {id, description: newDescription})
          group.description = newDescription;
          this.$snack.info('Changes saved')
        } catch (e) {
          console.log(e)
          this.$snack.networkError()
        } finally {
          this.savingChanges = false
        }
      }
    },
    
    async renameWorkflowGroup(group) {
      const {id, description} = group
      const newDescription = await showPrompt({
        title: 'Rename Workflow Group',
        body: `Please provide a new name for the workflow group "${description}"`,
        buttonLabel: 'Rename',
        showCancel: true,
        defaultValue: description,
      })
      
      if (newDescription) {
        try {
          this.savingChanges = true
          await this.$newReq('POST', `ugm/rename/workflowGroup`, {id, description: newDescription})
          group.description = newDescription;
          this.$snack.info('Changes saved')
        } catch (e) {
          console.log(e)
          this.$snack.networkError()
        } finally {
          this.savingChanges = false
        }
      }
    },
    
    
    userSort(a, b) {
      if (this.editGroup.users.includes(a.userID) && this.editGroup.users.includes(b.userID))
        return a.disabled === b.disabled ? (a.fullName > b.fullName ? 1 : -1) : (a.disabled ? 1 : -1)
      if (this.editGroup.users.includes(a.userID))
        return -1
      if (this.editGroup.users.includes(b.userID))
        return 1
      return a.disabled === b.disabled ? (a.fullName > b.fullName ? 1 : -1) : (a.disabled ? 1 : -1)
    },
    
    sortSelectedUsers(a, b) {
      if (this.editGroup.users.includes(a.userID) && this.editGroup.users.includes(b.userID))
        return a.fullName > b.fullName ? 1 : -1
      
      if (this.editGroup.users.includes(a.userID))
        return -1
      
      if (this.editGroup.users.includes(b.userID))
        return 1
      
      return a.fullName > b.fullName ? 1 : -1
    },
    
    saveChanges() {
      this.savingChanges = true
      this.$newReq('POST', `ugm/saveChanges/${this.userInfo.entityID}`, this.editGroup)
          .then((userGroup) => {
            this.userGroups = this.userGroups.filter(ug => ug.id !== userGroup.id)
            this.userGroups.push(userGroup);
            this.$snack.info('Changes Saved');
          })
          .catch(e => {
            this.$snack.networkError();
          })
          .finally(() => {
            this.savingChanges = false
          })
    },
    
    modelDataSets(dataSetIDs) {
      // Splice
      this.editGroup.dataSets = this.editGroup.dataSets.filter(obj => dataSetIDs.includes(obj.dataSet))
      
      // Add
      let dsIDs = this.editGroup.dataSets.map(ds => {
        return ds.dataSet
      })
      dataSetIDs.forEach(newDSID => {
        if (!dsIDs.includes(newDSID)) {
          this.editGroup.dataSets.push({dataSet: newDSID, readOnly: 0})
        }
      })
    },
    
    refreshUserGroups() {
      this.selectedUG = null;
      this.loadingStartup = true;
      this.$newReq('GET', `ugm/startup/${this.userInfo.entityID}`)
          .then((data) => {
            this.userGroups = data.userGroups;
            this.workflowGroups = data.workflowGroups;
            this.folderGroups = data.folderGroups;
            this.dataSets = data.dataSets;
          })
          .catch(e => {
            this.$snack.networkError();
          })
          .finally(() => {
            this.loadingStartup = false
          })
    },
  },
  computed: {
    ...mapGetters('userInfo', ['userInfo']),
    ...mapGetters(['users']),
    valuePassOver: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    userGroupsSorted() {
      return [...this.userGroups].sort((a, b) => a.description > b.description ? 1 : -1)
    },
    cols() {
      let count = 1;
      if (this.userInfo.userManager)
        ++count;
      if (this.userInfo.workflowManager)
        ++count;
      if (this.userInfo.fieldsetManager)
        ++count
      if (this.userInfo.folderManager)
        ++count
      return count
    },
    dataSetMembersByID() {
      return objectifyArr(this.editGroup.dataSets, 'dataSet')
    },
    selectedDatasets() {
      return this.editGroup.dataSets.map(obj => {
        return obj.dataSet
      })
    }
  },
}
</script>

<style scoped>

</style>