<template>
    <v-dialog persistent v-model="show" width="700px" max-width="98%" v-if="event">
        <v-card>
            <div class="c-d-flex c-align-center py-1 darken-2" :class="(groupCalColor || event.color) || 'blue'">
                <v-icon color="white" class="ml-2">{{event.icon || 'event'}}</v-icon>
                <div class="c-flex-grow-1 pa-1 pl-2 white--text">
                    {{ event.id ? event.title : 'Create New Calender Event' }}
                </div>
                <div class="px-1">
                    <v-tooltip top v-if="event.editable && !inEdit">
                        <v-btn
                                small
                                icon
                                flat
                                class="pa-0 ma-0"
                                color="white"
                                @click="inEdit = true"
                                slot="activator"
                        >
                            <v-icon small>edit</v-icon>
                        </v-btn>
                        Edit Event
                    </v-tooltip>
                </div>
                <div class="px-1">
                    <v-btn
                            small
                            icon
                            flat
                            class="pa-0 ma-0"
                            color="white"
                            @click="show = false"
                    >
                        <v-icon small>close</v-icon>
                    </v-btn>
                </div>
            </div>

<!--Edit Event-->
            <template v-if="inEdit">
                <div class="of-y" style="max-height: 60vh">
                    <calendar-item-detail-modify ref="modifyEvent" :view-event="event"/>
                </div>
                <v-divider></v-divider>
                <div class="c-d-flex c-justify-space-between" style="background: var(--page-back)">
                    <v-btn
                            small
                            color="secondary"
                            :disabled="savingChanges"
                            @click="inEdit = !inEdit"
                            outline
                    >
                        Cancel
                    </v-btn>

                    <v-btn
                            small
                            color="secondary"
                            @click="saveChanges()"
                            :disabled="savingChanges"
                            :loading="savingChanges"
                    >
                        Save Changes
                    </v-btn>
                </div>
            </template>
<!--View Event-->
            <template v-else>
                <div class="of-y" style="max-height: 60vh">
<!--                    <calendar-item-detail-modify ref="modifyEvent" :view-event="event"/>-->

                    <calendar-event-full-detail-body :event="event" ref="eventDetail" :dense="false"/>

<!--                    <v-btn @click="inEdit = !inEdit">Edit Event</v-btn>-->

                </div>
                <v-divider></v-divider>
                <div class="c-d-flex" :class="event.editable ? 'c-justify-space-between' : 'c-justify-end'" style="background: var(--page-back)">

                    <v-btn
                            v-if="event.fkOwner === userInfo.userID"
                            small
                            color="error"
                            @click="showDeleteDlg = true"
                    >
                        Delete Event
                    </v-btn>

                    <v-btn
                            small
                            color="secondary"
                            @click="$refs.eventDetail.showAddNote = true"
                            outline
                            v-if="event.editable"
                    >
                        Add Note To Event
                    </v-btn>

                    <v-btn
                            class="white--text"
                            small
                            color="green"
                            @click="event.markAsDone()"
                            :outline="!event.done"
                            v-if="event.editable"
                    >
                        <v-icon left>{{event.done ? 'done_all' : 'check_box_outline_blank'}}</v-icon>
                        {{event.done ? 'Mark as open' : 'Mark as completed'}}
                    </v-btn>

<!--                    <v-tooltip bottom v-if="event.fkOwner === userInfo.userID">-->
<!--                        <v-btn-->
<!--                                icon-->
<!--                                color="error"-->
<!--                                class="elevation-5 ma-0 ml-1"-->
<!--                                small-->
<!--                                slot="activator"-->
<!--                                @click="showDeleteDlg = true"-->
<!--                        >-->
<!--                            <v-icon small>delete</v-icon>-->
<!--                        </v-btn>-->
<!--                        Delete event-->
<!--                    </v-tooltip>-->


                    <v-btn
                            small
                            color="secondary"
                            @click="show = false"
                    >
                        Done
                    </v-btn>
                </div>
            </template>

        </v-card>

        <v-dialog v-if="showDeleteDlg" persistent v-model="showDeleteDlg" width="500px">

            <v-card>
                <div class="c-d-flex c-align-center py-1 darken-2 error">
                    <v-icon color="white" class="ml-2">delete</v-icon>
                    <div class="c-flex-grow-1 pa-1 pl-2 white--text">
                        Confirm Delete
                    </div>
                    <div class="px-1">
                        <v-btn
                                small
                                icon
                                flat
                                class="pa-0 ma-0"
                                color="white"
                                @click="showDeleteDlg = false"
                        >
                            <v-icon small>close</v-icon>
                        </v-btn>
                    </div>
                </div>
                <div class="pa-2">
                    Confirm that you would like to delete the calendar event: {{event.title}}.
                    <br>
                    Please note that this action can't be undone.
                    <br>
                    The calendar event will be removed from all users and groups with access.
                </div>
                <v-divider></v-divider>
                <div class="c-d-flex c-justify-space-between" style="background: var(--page-back)">
                    <v-btn
                            small
                            color="secondary"
                            :disabled="removingCalendarEvent"
                            @click="showDeleteDlg = false"
                            outline
                    >
                        Cancel
                    </v-btn>

                    <v-btn
                            small
                            color="error"
                            :disabled="removingCalendarEvent"
                            :loading="removingCalendarEvent"
                            @click="deleteCalendarEvent"
                    >
                        <v-icon left>delete</v-icon>
                        Confirm Delete
                    </v-btn>
                </div>
            </v-card>
        </v-dialog>

    </v-dialog>
</template>

<script>
import {mapGetters} from 'vuex'
import CalendarItemDetailModify from "@/components/General/Calendar/CalendarItemDetailModify";
import CalendarEventFullDetailBody from "@/components/General/Calendar/CalendarEventFullDetailBody";
import {calEventOpenEvents} from "@/Calendar View/Event Manager/EventManager";

export default {
    components: {CalendarEventFullDetailBody, CalendarItemDetailModify},
    props: {
        event: Object,
        value: Boolean,
        editMode: {
            default: false,
            type: Boolean
        }
    },
    data() {
        return {
            savingChanges: false,
            inEdit: false,
            removingCalendarEvent: false,
            showDeleteDlg: false,
        }
    },
    watch: {
        show(val) {
            if (!val)
                this.$emit('dismissed')
        },
        editMode: {
            immediate: true,
            handler(val) {
                this.inEdit = val
            }
        }
    },
    methods: {

        async saveChanges() {
            this.savingChanges = true;
            this.$refs.modifyEvent.event.upsert(false, (payload, e) => {
                if (e) {
                    this.$snack.networkError();
                    console.log(e)
                } else {
                    this.$snack.info('Changes Saved')
                    this.inEdit = false
                    this.$emit('eventUpdated')
                }
                this.savingChanges = false
            })
        },

        deleteCalendarEvent() {
            this.removingCalendarEvent = true;
            this.event.removeEvent(false, (payload, e) => {
                this.showDeleteDlg = false;
                this.$snack.info('Event Deleted');
                calEventOpenEvents.emit('calEventRemoved', this.event.id)
                this.removingCalendarEvent = false;
                this.$emit('removed')
            })



        },

    },
    computed: {
        ...mapGetters('userInfo', ['userInfo']),
        show: {
            get() {
                return this.value
            },
            set(val) {
                this.$emit('input', val)
            }
        },
        groupCalColor() {
            return this.event.fkCalGroup ? this.event.groupCalendarRef.color : null
        }
    },
}
</script>

<style scoped>

</style>