<template>
    <div>
        <div
                v-if="field.fieldType !== 20"
                :align-center="field.fieldType != 11"
                class="pb-1 pt-2"
                :class="fieldClass"
                v-show="!hiddenDatasetSelectField"
        >
<!--Description-->
            <div
                class="pl-2"
                :draggable="field.fieldType <= 6"
                @dragstart="fieldDragStart"
                :style="{cursor: field.fieldType <= 6 ? 'pointer' : null}"
            >
                <v-layout row align-center class="pr-2">

                    <v-flex shrink class="px-2 lighten-4" :style="{color: readOnly ? 'var(--field-color-required)' : (required ? 'var(--field-color-warning)' : 'var(--field-color-success)')}" style="border-radius: 4px; overflow-x: hidden; overflow-y: auto">
                        <v-layout row align-center >
                            <v-flex @click.ctrl="copyFieldTitle(field.description)" shrink class="py-1" :class="required || readOnly || linkedField ? 'pr-3' : ''">
                                <div class="body nowrap text-truncate">{{field.description}}</div>
                                <div v-if="field.saveStatusText != null && field.saveStatusText != ''" class="caption greyType--text nowrap">{{field.saveStatusText}}</div>
                            </v-flex>

                            <v-flex shrink v-if="linkedField" class="pl-1">
                                <v-tooltip top>
                                    <v-icon
                                            style="cursor: pointer;"
                                            color="primary darken-1"
                                            slot="activator"
                                            class="pa-0 ma-0"
                                    >
                                        link
                                    </v-icon>
                                    <span>Linked Field</span>
                                </v-tooltip>
                            </v-flex>

                            <v-flex shrink v-if="readOnly" class="pl-1">
                                <v-tooltip top>
                                    <v-icon
                                            color="red"
                                            slot="activator"
                                            style="cursor: pointer"
                                            small
                                    >
                                        security
                                    </v-icon>
                                    <span>Read Only Field</span>
                                </v-tooltip>
                            </v-flex>

                            <v-flex shrink v-if="required" class="pl-1">
                                <v-tooltip top>
                                    <v-icon
                                            color="red"
                                            slot="activator"
                                            style="cursor: pointer"
                                            small
                                    >
                                        warning
                                    </v-icon>
                                    <span>Required Field</span>
                                </v-tooltip>
                            </v-flex>
                        </v-layout>

                    </v-flex>


                </v-layout>
            </div>
<!--Field Detail-->
            <v-layout row align-center class="pl-2">
<!--Edit Avatar-->
                <v-flex shrink class="pl-2">
                  
                  <div v-if="valueChanged" key="valueChanged" style="width: 27px; height: 27px" class="d-flex align-center justify-center">
                    <v-icon color="secondary">
                      edit
                    </v-icon>
                  </div>
                  
                  <v-tooltip v-else-if="field.fieldType != 11 && field.valueID" top :key="editedByName">
                    <template #activator="{on}">
                      <div
                          v-if="!$store.state.darkTheme"
                          v-on="on"
                          class="c-d-flex c-align-center c-justify-center fill-height"
                          style="width: 27px; height: 27px; border-radius: 50%; cursor: pointer"
                          :style="{ border: `2px solid ${editByColor}` }"
                          @click.stop="![10,11, 15].includes(field.fieldType) ? showHistory = true : ''"
                      >
                                    <span
                                        :style="{ color: editByColor }"
                                        class="caption font-weight-bold"
                                        style="line-height: 0"
                                    >
                                      {{ getInitials(editedByName) }}
                                    </span>
                      </div>
                      <div
                          v-else
                          v-on="on"
                          class="c-d-flex c-align-center c-justify-center fill-height"
                          style="width: 27px; height: 27px; border-radius: 50%; cursor: pointer"
                          :style="{ background: editByColor }"
                          @click.stop="![10,11, 15].includes(field.fieldType) ? showHistory = true : ''"
                      >
                                    <span
                                        
                                        class="caption font-weight-bold"
                                        style="line-height: 0"
                                    >
                                      {{ getInitials(editedByName) }}
                                    </span>
                      </div>
                    
                    </template>
                    <span>{{ editedByName + ' - ' + getLongDate(field.modify_date) }}</span>
                  </v-tooltip>
                  
                  <div v-else-if="field.fieldType != 11 && !field.valueID" key="nothing" style="width: 27px; height: 27px" class="d-flex align-center justify-center">
                    <v-tooltip top>
                      <v-avatar
                          style="opacity: 10%"
                          class="greyType"
                          slot="activator"
                          :size="25"
                      >
                      </v-avatar>
                      <span>Nothing Saved</span>
                    </v-tooltip>
                  </div>
                  
                </v-flex>
<!--Field-->
                <v-flex xs12 class="pl-2 pr-2"
                        :style="field.fieldType === 13 ? 'overflow: auto' : ([6,7,8].includes(field.fieldType) ? 'overflow-x: hidden; overflow-y: auto' : '')">

                    <app-field-type1
                            v-if="field.fieldType == 1"
                            v-model="field"
                            :select-fields="selectFields"
                            :field-sets="task.structure.fieldSets"
                            :step-editable="softBlock ? false : stepEditable"
                            @setMap="$emit('setMap', $event)"
                            @clearMap="$emit('clearMap', $event)"
                            :binder="binder"
                    />
                    <app-field-type2
                            v-if="field.fieldType == 2"
                            v-model="field"
                            :select-fields="selectFields"
                            :field-sets="task.structure.fieldSets"
                            :step-editable="softBlock ? false : stepEditable"
                            :binder="binder"
                    />
                    <app-field-type3
                            v-if="field.fieldType == 3"
                            v-model="field"
                            :select-fields="selectFields"
                            :field-sets="task.structure.fieldSets"
                            :step-editable="softBlock ? false : stepEditable"
                            :binder="binder"
                    />
                    <app-field-type4
                            v-if="field.fieldType == 4"
                            v-model="field"
                            :select-fields="selectFields"
                            :field-sets="task.structure.fieldSets"
                            :step-editable="softBlock ? false : stepEditable"
                            :binder="binder"
                    />
                    <app-field-type5
                            v-if="field.fieldType == 5"
                            v-model="field"
                            :select-fields="selectFields"
                            :field-sets="task.structure.fieldSets"
                            :step-editable="softBlock ? false : stepEditable"
                            :binder="binder"
                    />
                    <app-field-type6
                            v-if="field.fieldType == 6"
                            v-model="field"
                            :user-info="userInfo"
                            :mails="task.structure.mails"
                            :documents="task.structure.documents"
                            :selected-step="selectedStep"
                            :task="task"
                            :select-fields="selectFields"
                            :field-sets="task.structure.fieldSets"
                            :step-editable="softBlock ? false : stepEditable"
                            :srcTaskAttachment="srcTaskAttachment"
                            :binder="binder"
                    />
                    <app-field-type7
                            v-if="field.fieldType == 7"
                            v-model="field"
                            :user-info="userInfo"
                            :mails="task.structure.mails"
                            :documents="task.structure.documents"
                            :selected-step="selectedStep"
                            :task="task"
                            :step-editable="softBlock ? false : stepEditable"
                            :srcTaskAttachment="srcTaskAttachment"
                            :binder="binder"
                    />
                    <app-field-type8
                            v-if="field.fieldType == 8"
                            v-model="field"
                            :fields="task.structure.fields"
                            :steps="task.structure.steps"
                            :field-sets="task.structure.fieldSets"
                            :user-info="userInfo"
                            :step="selectedStep"
                            :task="task"
                            :step-editable="softBlock ? false : stepEditable"
                            :srcTaskAttachment="srcTaskAttachment"
                            :mail-config="mailConfig"
                            :binder="binder"

                    />
                    <app-field-type9
                            v-if="field.fieldType == 9"
                            v-model="field"
                            :task="task"
                            :user-info="userInfo"
                            :step-editable="softBlock ? false : stepEditable"
                            @saveFields="$emit('forceSave', true)"
                            :binder="binder"
                    />
                    <app-field-type10
                            v-if="field.fieldType == 10"
                            v-model="field"
                            :task="task"
                            :user-info="userInfo"
                            :step-editable="softBlock ? false : stepEditable"
                            :folder-groups="folderGroups"
                            :local-data="localData"
                            :show-sync="showSync"
                            :get-task-header="getTaskHeader"
                            :binder="binder"
                    />
                    <app-field-type11
                            v-if="field.fieldType == 11"
                            v-model="field"
                            :userInfo="userInfo"
                            :step-editable="softBlock ? false : stepEditable"
                    />
                    <app-field-type12
                            v-if="field.fieldType == 12"
                            v-model="field"
                            :userInfo="userInfo"
                            :fields="task.structure.fields"
                            :task-open-state="task.preSnoozedState"
                            :step-editable="softBlock ? false : stepEditable"
                            :hidden-steps="hiddenSteps"
                            :steps="task.structure.steps"
                            @selectedStep="$emit('selectedStep', $event)"
                    />
                    <app-field-type13
                            v-if="field.fieldType == 13"
                            v-model="field"
                            :step-editable="softBlock ? false : stepEditable"
                            :edit-note-fn="editNoteFn"
                            :select-fields="selectFields"
                            :field-sets="task.structure.fieldSets"
                            @input="$emit('forceSave', true)"
                            :binder="binder"
                    />
                    <app-field-type15
                            v-if="field.fieldType == 15"
                            v-model="field"
                            :step-editable="softBlock ? false : stepEditable"
                            :task="task"
                            :user-info="userInfo"
                            :step-i-d="selectedStep.stepID"
                    />
                    <app-field-type17
                            v-if="field.fieldType === 17"
                            v-model="field"
                            :user-info="userInfo"
                            :step-editable="softBlock ? false : stepEditable"
                            :fields="task.structure.fields"
                            :steps="task.structure.steps"
                            :datasets="task.structure.fieldSets"
                            :local-data="localData"
                            :binder="binder"
                            @saveFields="$emit('forceSave', true)"
                    />
                    <app-field-type18
                            v-if="field.fieldType === 18"
                            v-model="field"
                            :user-info="userInfo"
                            :step-editable="softBlock ? false : stepEditable"
                    />

                    <app-field-type19
                            v-if="field.fieldType === 19"
                            v-model="field"
                            :step-editable="softBlock ? false : stepEditable"
                            :fields="task.structure.fields"
                            :steps="task.structure.steps"
                            :field-sets="task.structure.fieldSets"
                            :user-info="userInfo"
                            :step="selectedStep"
                            :task="task"
                            @saveFields="$emit('forceSave', true)"
                            :mail-config="mailConfig"
                    />

                    <field-type21
                            v-if="field.fieldType === 21"
                            v-model="field"
                            :step-editable="softBlock ? false : stepEditable"
                            :fields="task.structure.fields"
                            :steps="task.structure.steps"
                            :field-sets="task.structure.fieldSets"
                            :user-info="userInfo"
                            :step="selectedStep"
                            :task="task"
                            :get-task-header="getTaskHeader"
                            :local-data="localData"
                            @forceSave="$emit('forceSave', true)"
                            :binder="binder"
                    />

                    <field-type22
                            v-if="field.fieldType === 22"
                            v-model="field"
                            :step-editable="softBlock ? false : stepEditable"
                            :fields="task.structure.fields"
                            :steps="task.structure.steps"
                            :field-sets="task.structure.fieldSets"
                            :user-info="userInfo"
                            :step="selectedStep"
                            :task="task"
                            @saveFields="$emit('forceSave', true)"
                            :mail-config="mailConfig"
                            :eval-binder="evalBinder"
                    />

                  <field-type23
                    v-if="field.fieldType === 23"
                    v-model="field"
                    :step-editable="softBlock ? false : stepEditable"
                    :user-info="userInfo"
                    :task="task"
                    @forceSave="$emit('forceSave', true)"
                  />
                </v-flex>

            </v-layout>
        </div>

        <div v-else>
            <field-type20
                    :lookup="field.lookup"
                    :field-title="field.description"
            />

        </div>

        <app-field-history-viewer
            :user-info="userInfo"
            :field="field"
            :task="task"
            v-model="showHistory"
            :users="users"
            :selectLists="selectLists"
            :local-data="localData"
        />
    </div>
</template>

<script>

    import FieldType1 from './FieldTypeComponents/FieldType1';
    import FieldType2 from './FieldTypeComponents/FieldType2';
    import FieldType3 from './FieldTypeComponents/FieldType3';
    import FieldType4 from './FieldTypeComponents/FieldType4';
    import FieldType5 from './FieldTypeComponents/FieldType5';
    import FieldType6 from './FieldTypeComponents/FieldType6';
    import FieldType7 from './FieldTypeComponents/FieldType7';
    import FieldType8 from './FieldTypeComponents/FieldType8';
    import FieldType9 from './FieldTypeComponents/FieldType9';
    import FieldType10 from './FieldTypeComponents/FieldType10';
    import FieldType11 from './FieldTypeComponents/FieldType11';
    import FieldType12 from './FieldTypeComponents/FieldType12';
    import FieldType13 from './FieldTypeComponents/FieldType13';
    import FieldType17 from './FieldTypeComponents/SMS Components/FieldType17';
    import {codeBaseMixin} from "../../../../../codeBaseMixin";
    import FieldHistoryViewer from './FieldHistory/FieldHistoryViewer'
    import InputDlg from "../../../../../components/General/InputDlg";
    import ValueTree from "./ValueTree/ValueTree";
    import FieldType15 from "./FieldTypeComponents/FieldType15";
    import FieldType18 from "./FieldTypeComponents/Photo Field Components/FieldType18";
    import FieldType19 from "./FieldTypeComponents/Document Template/FieldType19";
    import FieldType20
        from "@/Tasks/TaskView V2/components/TaskViewDetail/Components/FieldTypeComponents/Information Field/FieldType20";
    import FieldType21 from "@/Tasks/TaskView V2/components/TaskViewDetail/Components/FieldTypeComponents/FieldType21";
    import {DatasetBinder} from "@/Tasks/TaskView V2/components/TaskViewDetail/datasetBinder";
    import FieldType22 from "@/Tasks/TaskView V2/components/TaskViewDetail/Components/FieldTypeComponents/FieldType22";
    import {EvalBinder} from "@/Tasks/TaskView V2/components/TaskViewDetail/evalsBinder";
    import {mapGetters} from "vuex";
    import FieldType23
      from "@/Tasks/TaskView V2/components/TaskViewDetail/Components/FieldTypeComponents/FieldType23.vue";
    import {getColor} from "random-material-color";
    import {copyText} from "@/codeFunctions";


    export default {
        mixins: [codeBaseMixin],
        components: {
          FieldType23,
            FieldType22,
            FieldType21,
            FieldType20,
            appFieldType19: FieldType19,
            appFieldType18: FieldType18,
            appFieldType17: FieldType17,
            appValueTree: ValueTree,
            appInputDlg: InputDlg,
            appFieldType1: FieldType1,
            appFieldType2: FieldType2,
            appFieldType3: FieldType3,
            appFieldType4: FieldType4,
            appFieldType5: FieldType5,
            appFieldType6: FieldType6,
            appFieldType7: FieldType7,
            appFieldType8: FieldType8,
            appFieldType9: FieldType9,
            appFieldType10: FieldType10,
            appFieldType11: FieldType11,
            appFieldType12: FieldType12,
            appFieldType13: FieldType13,
            appFieldType15: FieldType15,
            appFieldHistoryViewer: FieldHistoryViewer
        },
        props: {
            evalBinder: EvalBinder,
            field: Object,
            userInfo: Object,
            task: Object,
            selectedStep: Object,
            srcTaskAttachment: {
                type: Boolean,
                default: false,
            },
            folderGroups: Array,
            users:Array,
            selectLists: {
                type: Array,
                default: () => {
                    return []
                }
            },
            editNoteFn: Function,
            mailConfig: {
                required: true,
                type: Object
            },
            hiddenSteps: Array,
            localData: Object,
            showSync: Boolean,
            getTaskHeader: Function,
            binder: DatasetBinder
        },
        data() {
            return {
                ireset: -1,
                showHistory: false,
                showAddValueTreeTitle: false
            }
        },
        methods: {
          fieldDragStart(e) {
            const fieldInformation = {
              fieldID: this.field.fieldID,
              description: this.field.description,
              fieldType: this.field.fieldType,
              lookup_type: this.field.lookup_type,
              lookup: this.field.lookup,
              config: this.field.config,
              value_type: this.field.value_type,
              value: this.field.value,
            }
            
            e.dataTransfer.setData('text', JSON.stringify({
              type: 'workflowField',
              payload: fieldInformation
            }));
          },
          
          copyFieldTitle(title) {
            copyText(document, title)
            this.$snack.info(`"${title}" Copied`)
          }
        },
      
        computed: {

            ...mapGetters("userInfo", ["softBlock"]),
          
          editedByName() {
              return (this.field.valueContactName || (this.field.valueName + ' ' + this.field.valueSurname) || '').trim()
          },
          
          editByColor() {
            return getColor({ shades: ['800'], text: this.editedByName})
          },

            linkedFieldInfo() {
                try {
                    let t = this.field.linkLookup || null
                    return t
                } catch (e) {
                    // eslint-disable-next-line
                    return null
                }
            },

            selectFields() {

                let t = this.task.structure.fields.filter(field => field.fieldType == 9)

                return t
            },
            fieldClass() {
                let tClass = '';

                if (this.field.saveStatus == 2) {tClass = 'save-fail '}
                if (this.field.saveStatus == 1 && !this.valueChanged) {tClass = 'save-success '}

                return tClass
            },
            stepEditable() {
                if (typeof this.task.structure.steps != 'undefined') {

                    let t = this.intToBool(this.task.structure.steps.filter(obj => obj.stepID == this.field.stepID)[0].editable)

                    return t
                } else {
                    return true
                }

            },
            valueChanged() {
                if (this.field.value || this.field.allowNull) {
                    if (this.field.type != 8) {
                        return this.field.value != this.field.oldValue
                    }
                }
              return false
            },

            hiddenDatasetSelectField() {
                try {
                    return this.field.fieldType === 9
                        && (this.field.config.hiddenIfPopulated)
                        && !!this.field.value
                } catch (e) {
                    return true
                }
            },
            readOnly() {
                return this.softBlock || !this.intToBool(this.field.editable) || !this.stepEditable
            },
            required() {
                return this.field.required && (this.field.value == null || this.field.value === '')
            },
            linkedField() {
                return [1,2,3,4,5,6,13].includes(this.field.fieldType) && this.linkedFieldInfo != null
            }
        }
    }
</script>

<style scoped>
    .editedField {
        background: #efefef;
    }
    
    .save-success {
      background: var(--primary-10p);
    }
    
    .save-fail {
      background: var(--red-20p);
    }


</style>
