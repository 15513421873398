<template>

    <div class="fill-height scrollable">
        <v-layout column fill-height style="overflow-y: auto; overflow-x: hidden" class="pa-1">
            <v-flex shrink>
                <v-layout row align-center>
                    <v-flex xs12>
                        <v-text-field
                                label="Search Store"
                                single-line
                                clearable
                                prepend-inner-icon="search"
                                color="primary"
                                flat
                                :hide-details="true"
                                :value="searchStr"
                                class="pa-0 ma-0"
                                ref="myField"
                                @input="$emit('searchStr', $event)"
                                @click:clear="$emit('searchStr', null)"
                        ></v-text-field>
                    </v-flex>


                    <v-flex shrink>
                        <div class="upload-btn-wrapper" >
                            <div
                                    @dragover="$event.preventDefault()"
                                    @drop="$event.preventDefault(); dropFiles($event);"
                                    class="text-xs-center"
                                    v-if="!addDocFile"
                                    style="cursor: pointer"
                            >
                                <v-btn
                                        small
                                        icon
                                        flat
                                        color="secondary"
                                        class="pa-0 ma-0"
                                >
                                    <v-icon>add</v-icon>
                                </v-btn>
                            </div>
                            <div v-else>
                                <v-progress-circular
                                        class="py-0 my-0"
                                        :value="addDocProgress"
                                        color="secondary"
                                        :size="26"
                                        :width="3"
                                        :rotate="270"
                                />
                            </div>
                            <input
                                    ref="manualUpload"
                                    type="file"
                                    name="myfile"
                                    :accept="userInfo.entityInfo.mimeTypeList"
                                    @input="selectFile"
                            />
                        </div>
                    </v-flex>
                </v-layout>
            </v-flex>

            <v-flex xs12 class="scrollable">
                <div
                        v-for="node in displayDocs"
                        :key="node.id"
                >
                    <user-repo-node
                            :node="node"
                            :selectedKeys="selectedKeys"
                            @select="$emit('select', $event)"
                            @deleteUserDoc="removeDoc($event)"
                    />
                </div>
            </v-flex>
        </v-layout>
    </div>


</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import {objectifyArr} from "@/codeFunctions";
import UserRepoNode from "@/Document Repository/UserRepoNode";

export default {
    components: {UserRepoNode},
    props: {
        selectedKeys: Array,
        searchStr: String
    },
    data() {
        return {
            addDocFile: null,
            addDocProgress: 0,
        }
    },
    watch: {},
    methods: {

        ...mapActions('repo', ['repoAddUserDoc', 'repoRemoveUserDoc']),

        removeDoc(doc) {

            if (this.selectedKeys.includes(doc.key))
                this.$emit('select', doc.key)

            this.$newReq('delete', `docRepo/UserRemoveDoc/${this.userInfo.entityID}/${doc.id}`)
                .then(() => {
                    this.$snack.info('Document Removed')
                    this.repoRemoveUserDoc(doc.id)
                })
                .catch((e) => {
                    console.log(e)
                    this.$snack.networkError()
                })

        },

        addDocRun() {
            let vm = this;
            function updateProgress(obj) {
                vm.addDocProgress = Math.floor((obj.loaded / obj.total) * 100)
            }
            this.$newReqUploadMultiple(
                    'post',
                    `docRepo/AddNewUserDoc/${this.userInfo.entityID}/${this.userInfo.userID}`,
                    [ {key: 'file', val: this.addDocFile}],
                    true,
                    updateProgress,
                    false,
            )
                    .then(doc => {
                        this.$snack.info('Document Added')
                        this.repoAddUserDoc(doc)
                    })
                    .catch(e => {
                        console.log(e);
                        this.$snack.networkError()
                    })
                    .finally(() => {
                        this.addDocProgress = 0;
                        this.addDocFile = null;
                    })

        },

        selectFile() {
            if (this.$refs.manualUpload.files.length) {
                this.addDocFile = this.$refs.manualUpload.files[0]
                this.addDocRun();
            }
        },

        dropFiles(e) {
            this.showUpload = true;
            this.$nextTick(() => {
                if (e.dataTransfer.files.length) {
                    this.addDocFile = e.dataTransfer.files[0];
                    this.addDocRun();
                }
            })

        },


    },
    computed: {
        ...mapGetters('userInfo', ['userInfo']),
        ...mapGetters('repo', ['repoUserDocs']),
        ...mapGetters(['localData']),
        refUsers() {
            return objectifyArr(this.localData.users, 'userID')
        },
        displayDocs() {
            return this.repoUserDocs
                    .filter(doc => this.searchStr ? JSON.stringify(doc).toLowerCase().includes(this.searchStr.toLowerCase()) : true)
                    .map((doc) => {
                        return {
                            ...doc,
                            description: doc.document.alias || doc.document.fileName,
                            viewDoc: {
                                ...doc.document,
                                messageID: null,
                                documentDBID: doc.document.id,
                                linkUser: doc.document.fk_import_user,
                                linkName: doc.document.fk_import_user <= 0 ? 'System' : this.refUsers[doc.document.fk_import_user].name,
                                linkSurname: doc.document.fk_import_user <= 0 ? '' : this.refUsers[doc.document.fk_import_user].surname,
                                linkDate: doc.document.created,
                                stepID: null
                            }
                        }
                    })
                    .sort((a, b) => b.id - a.id)
        },
    },
}
</script>

<style scoped>
.upload-btn-wrapper input[type=file] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    max-height: 28px;
    max-width: 28px;
}

.upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
}
</style>