<template>
        <v-card class="px-1 pt-1 pb-1 bord" style="overflow-x: hidden">
            <v-layout row align-space-around style="overflow-x: hidden" class="">
<!--Status Icon-->
                <v-flex shrink class="">
                    <v-layout column justify-space-around fill-height class="">
                        <v-flex shrink class="">
                            <v-tooltip top>
                                <v-progress-circular
                                        slot="activator"
                                        :rotate="-90"
                                        :size="60"
                                        :width="5"
                                        :value="0"
                                        :color="task.state == 0 ? '#5C6BC0' : task.state == 1 ? '#388E3C' : '#FFA000'"
                                        background-color="red"
                                >
                                    <template v-if="task.snoozed">
                                        <v-icon large color="primary">alarm</v-icon>
                                    </template>
                                    <template v-else>
                                        <template v-if="task.taskState === 0">
                                            <v-icon large color="primary">edit</v-icon>
                                        </template>
                                        <template v-if="task.taskState === 1">
                                            <v-icon large color="primary">done_all</v-icon>
                                        </template>
                                    </template>
                                </v-progress-circular>
                                <span>{{`${task.taskState == 0 ? 'Open' : 'Closed'}`}}{{task.snoozed ? ` Snoozed until ${getLongDate(task.snoozeDate)}` : ''}}</span>
                            </v-tooltip>
                        </v-flex>
                    </v-layout>
                </v-flex>
<!--Header Body-->
                <v-flex xs12 class=" px-2" style="overflow-x: hidden">
                    <v-layout column justify-space-around fill-height class="" style="overflow-x: hidden">
<!--Row #1-->
                        <v-flex shrink style="overflow-x: hidden">
                            <v-layout row align-center style="overflow-x: hidden; overflow-y: hidden">
                                <v-flex shrink class="pr-2 font-weight-medium" style="white-space: nowrap; color: var(--task-number-color)">#{{ task.taskID }}</v-flex>
                                <v-flex shrink style="height: 22px;"><img src="/users.svg" alt="User" height="22px"></v-flex>
                                <v-flex shrink class="pr-2 pl-1 body-1" style="white-space: nowrap">{{task.ownerName + ' ' + task.ownerSurname}}</v-flex>

                                <template v-if="task.subOwnerID != null && task.subOwnerID !== task.ownerID">

                                    <v-flex shrink class="pr-1">
                                        <v-tooltip top>
                                            <v-layout
                                                    row
                                                    align-center
                                                    style="overflow-x: hidden; overflow-y: hidden"
                                                    class="subUserHoverText"
                                                    slot="activator"
                                                    @click="transferTaskSubOwner(task.taskID)"
                                            >
                                                <v-flex shrink style="height: 22px;"><img src="/sub_users.svg" alt="User" height="22px"></v-flex>
                                                <v-flex shrink class="pr-1 pl-1 body-1" style="white-space: nowrap">{{task.subOwnerName + ' ' + task.subOwnerSurname}}</v-flex>
                                            </v-layout>
                                            <span>Task Sub Owner Click To Transfer</span>
                                        </v-tooltip>
                                    </v-flex>
                                </template>

                                <v-flex shrink style="height: 22px;"><img src="/folders.svg" alt="Folder" height="22px"></v-flex>
                                <v-flex shrink class="pr-2 pl-1 text-truncate body-1" style="white-space: nowrap; overflow-x: hidden">{{ task.folderName }}</v-flex>
                                <v-flex shrink style="height: 22px;"><img src="/workflows.svg" alt="Workflow" height="22px"></v-flex>
                                <v-flex shrink class="pr-2 pl-1 body-1" style="white-space: nowrap">{{task.workflowName}}</v-flex>
                            </v-layout>
                        </v-flex>
<!--Task Title-->
                        <v-flex shrink class="">
                            <v-hover>
                                <v-flex
                                        xs12
                                        slot-scope="{ hover }"
                                >
                                    <template class="">
                                        <v-layout row align-center @click="!softBlock ? showEditTitle = true : null" ripple style="cursor: pointer">
                                            <v-flex shrink>
                                                <span class="subheading font-weight-medium">{{ !blankString(task.title) ? task.title : 'No Title' }}</span>
                                            </v-flex>
                                            <v-flex shrink class="pl-1" v-if="!softBlock">
                                                <v-tooltip top v-if="hover">
                                                    <v-icon slot="activator" small color="primary">edit</v-icon>
                                                    <span>Edit Task Title</span>
                                                </v-tooltip>
                                            </v-flex>
                                        </v-layout>
                                    </template>
                                </v-flex>
                            </v-hover>

                            <app-input-dlg
                                    :show-dialog="showEditTitle"
                                    title="Rename Task"
                                    body="Please provide a New Task Title:"
                                    :cancel-button="true"
                                    :show-input-box="true"
                                    :buttons="['Rename']"
                                    :default-input="task.title"
                                    :call-backs="[ updateTaskTitle ]"
                                    @dismiss="showEditTitle = false"
                            />
                        </v-flex>
<!--Task Status Text-->
                        <v-flex shrink class="" v-if="typeof task.workflowStatusValue != 'undefined' && task.workflowStatusValue != null">
                            <v-layout row style="cursor: pointer" align-center @click="!softBlock ? showStatusView = true : false">
                                <v-flex shrink>
                                    <v-tooltip top slot="activator">
                                        <v-btn
                                                icon
                                                flat
                                                :color="escalationColor"
                                                class="pa-0 ma-0"
                                                slot="activator"
                                                small
                                                :disabled="softBlock"
                                        >
                                            <v-icon>chat_bubble</v-icon>
                                        </v-btn>
                                        <span>Edit Task Status</span>
                                    </v-tooltip>
                                </v-flex>
                                <v-flex shrink class="pl-2">
                                    <v-tooltip top>
                                        <v-btn
                                                icon
                                                class="pa-0 ma-0"
                                                :color="task.workflowStatusUser == userInfo.userID ? 'secondary' : 'primary'"
                                                small
                                                slot="activator"
                                                style="width: 25px; height: 25px"
                                        >
                                            <div class="font-weight-regular white--text caption">{{ getInitials(`${task.workflowStatusName} ${task.workflowStatusSurname}`) }}</div>
                                        </v-btn>
                                        <span>{{`${task.workflowStatusName} ${task.workflowStatusSurname} - (${getLongDate(task.workflowStatusCreated)})`}}</span>
                                    </v-tooltip>
                                </v-flex>
                                <v-flex shrink class="pl-2">
                                    <v-tooltip top>
                                        <span slot="activator" class="font-weight-regular body-1">{{task.workflowStatusType == 0 ? task.workflowStatusDescription : task.workflowStatusText}}</span>
                                        <span>{{`${task.workflowStatusName} ${task.workflowStatusSurname} - (${getLongDate(task.workflowStatusCreated)})`}}</span>
                                    </v-tooltip>
                                </v-flex>
                            </v-layout>
                        </v-flex>
                    </v-layout>
                </v-flex>
<!--Header Actions-->
                <v-flex shrink class="">
                    <v-layout column justify-space-between fill-height class="">

<!--Task Actions-->
                        <v-flex shrink class="">
                            <v-layout row align-center justify-end>
<!--Task contacts-->
                              <v-flex shrink class="" v-if="task.folderID && userInfo.entityInfo &&  userInfo.entityInfo.contactsEnabled">
                                <v-tooltip top>
                                  <template #activator="{on}">
                                      <app-browse-contacts v-if="userInfo && userInfo.userID" ref="contactView" :folder-filter="task.folderID">
                                        <template #activator>
                                          <v-btn
                                              v-on="on"
                                              icon
                                              flat
                                              color="primary"
                                              class="pa-0 ma-0 px-1"
                                              small
                                          >
                                            <img src="../../../../public/contacts.svg" class="nav-item" style="width: 22px">
                                          </v-btn>
                                        
                                        </template>
                                        
                                        <template #toolbarEnd>
                                          <div class="">
                                            <app-create-link-contact :force-folder="task.folderID" :local-data="localData" @input="contactAdded($event)">
                                              <template #activator>
                                                <div class="py-0 my-0">
                                                  <v-btn flat class="" color="white" small>
                                                    <v-icon left>add</v-icon>
                                                    Create / Link Contact
                                                  </v-btn>
                                                </div>
                                              </template>
                                            </app-create-link-contact>
                                          </div>
                                        </template>
                                      </app-browse-contacts>
                                    </template>
                                  <span>Browse Contacts</span>
                                </v-tooltip>
                                
                              </v-flex>
<!--Task Share-->
                                <v-flex shrink v-if="showShare" class="">
                                    <app-task-view-header-share
                                            :user-info="userInfo"
                                            :task="task"
                                    />
                                </v-flex>
<!--Task Status Button-->
                                <v-flex shrink class="" >



                                    <app-status-view
                                            v-model="showStatusView"
                                            :user-info="userInfo"
                                            :task="task"
                                            :cur-time="curTime"
                                            @input="inputCalled"
                                            :disabled="softBlock"
                                    >
                                        <v-tooltip top slot="activator">
                                            <v-btn
                                                :disabled="softBlock"
                                                    icon
                                                    flat
                                                    :color="escalationColor"
                                                    class="pa-0 ma-0"
                                                    slot="activator"
                                                    small
                                            >
                                                <v-icon>chat_bubble</v-icon>
                                            </v-btn>
                                            <span>Edit Task Status</span>
                                        </v-tooltip>
                                    </app-status-view>
                                </v-flex>
<!--Folder Info-->
                                <v-flex shrink class="">
                                    <v-tooltip top v-if="intToBool(task.folderID > 0)">
                                        <v-btn
                                            :disabled="softBlock"
                                                class="pa-0 ma-0"
                                                slot="activator"
                                                flat
                                                icon
                                                color="primary"
                                                @click="showFolderInfo = true"
                                                small
                                        >
                                            <v-icon>folder</v-icon>
                                        </v-btn>
                                        <span>Folder Info</span>
                                    </v-tooltip>
                                </v-flex>
<!--Star Task-->
                                <v-flex shrink class="">
                                    <v-tooltip left>
                                        <v-btn
                                                slot="activator"
                                                small
                                                :color="task.starred ? 'amber' : 'grey'"
                                                class="pa-0 ma-0 smallBtn"
                                                flat
                                                icon
                                                :loading="starLoading"
                                                :disabled="starLoading || softBlock"
                                                @click="starTask()"
                                        >
                                            <v-icon>{{ task.starred ? 'star' : 'star_border' }}</v-icon>
                                        </v-btn>
                                        <span>{{ task.starred ? 'Unstar Task' : 'Star Task' }}</span>
                                    </v-tooltip>
                                </v-flex>
<!--Transfer Task-->
                                <v-flex shrink class="">
                                    <v-tooltip left v-if="userInfo.allowTransfer == 1">
                                        <v-btn
                                                @click="transferTask(task.taskID)"
                                                slot="activator"
                                                small
                                                color="red"
                                                class="pa-0 ma-0 smallBtn"
                                                flat
                                                icon
                                                :loading="transferLoading"
                                                :disabled="transferLoading || softBlock"
                                        >
                                            <v-icon>forward</v-icon>
                                        </v-btn>
                                        <span>Transfer Task</span>
                                    </v-tooltip>
                                </v-flex>
<!--Sub Owner-->
                                <v-flex shrink class="">
                                    <v-tooltip left>
                                        <v-btn
                                                @click="transferTaskSubOwner(task.taskID)"
                                                slot="activator"
                                                small
                                                color="blue"
                                                class="pa-0 ma-0 smallBtn"
                                                flat
                                                icon
                                                :disabled="softBlock"
                                        >
                                            <img src="/sub_users.svg" alt="User" height="22px">
                                        </v-btn>
                                        <span>Set Task Sub Owner</span>
                                    </v-tooltip>
                                </v-flex>
<!--Snooze Task-->
                                <v-flex shrink class="">

                                    <v-tooltip left>
                                        <div slot="activator">
                                            <app-add-date-time
                                                    :title="`Snooze Task ${task.taskID}`"
                                                    :disabled="task.snoozed || snoozedLoading"
                                                    @inputString="snoozeTask($event)"
                                            >
                                                <v-btn
                                                        slot="activator"
                                                        small
                                                        flat
                                                        icon
                                                        color="indigo"
                                                        class="pa-0 ma-0"
                                                        @click="task.snoozed ? unSnoozeTask(task.snoozeID) : ''"
                                                        :disabled="snoozedLoading || softBlock"
                                                        :loading="snoozedLoading"
                                                >
                                                    <v-icon>{{ task.snoozed ? 'alarm_off' : 'alarm' }}</v-icon>
                                                </v-btn>
                                            </app-add-date-time>
                                        </div>
                                        <span>{{ task.snoozed ? 'Wake Task' : 'Snooze Task' }}</span>
                                    </v-tooltip>
                                </v-flex>
<!--Follow Task-->
                                <v-flex shrink class="">
                                    <v-tooltip top>
                                        <v-btn
                                            class="pa-0 ma-0"
                                            slot="activator"
                                            flat
                                            icon
                                            :color="following ? 'blue' : 'grey'"
                                            small
                                            :loading="followLoading"
                                            :disabled="followLoading || softBlock"
                                            @click="followTask"
                                        >
                                            <v-icon>{{following ? 'visibility' : 'visibility_off'}}</v-icon>
                                        </v-btn>
                                        <span>{{following ? 'Unfollow Task' : 'Follow Task'}}</span>
                                    </v-tooltip>
                                </v-flex>
<!--Refresh Task-->
                                <v-flex shrink class="">
                                    <v-tooltip top>
                                        <v-btn
                                            class="pa-0 ma-0"
                                            slot="activator"
                                            flat
                                            icon
                                            color="blue"
                                            @click="showTaskViewHistory = true"
                                            small
                                            :disabled="softBlock"
                                        >
                                            <v-icon>info</v-icon>
                                        </v-btn>
                                        <span>Open task view history</span>
                                    </v-tooltip>
                                </v-flex>
<!--Refresh Task-->
                                <v-flex shrink class="">
                                    <v-tooltip top>
                                        <v-btn
                                                class="pa-0 ma-0"
                                                slot="activator"
                                                flat
                                                icon
                                                color="primary"
                                                @click="$emit('refreshTask')"
                                                small
                                        >
                                            <v-icon>refresh</v-icon>
                                        </v-btn>
                                        <span>Refresh Task</span>
                                    </v-tooltip>
                                </v-flex>
                            </v-layout>
                        </v-flex>
<!--Folder Groups-->
                        <v-flex shrink class=" text-xs-right body-1 text-truncate" v-if="task.folderGroupNames != null && task.folderGroupNames != ''" style="max-width: 300px; white-space: nowrap">
                            {{task.folderGroupNames}}
                        </v-flex>
<!--Task Date-->
                        <v-flex shrink class=" text-xs-right greyType--text caption" style="white-space: nowrap">
                            {{ getLongDate(task.created) }} | {{getDaysOld(task.created) + ' Days'}}
                        </v-flex>
                    </v-layout>
                </v-flex>

            </v-layout>


            <app-folder-dlg
                    v-if="!destroyFolder"
                    :show-dialog="showFolderInfo"
                    @dismiss="dismissFolderDlg"
                    :user-info="userInfo"
                    dlg-type="folderView"
                    v-model="selectedFolder"
                    :local-data="localData"
                    :show-sync="showSync"
                    :get-task-header="getTaskHeader"
                    :add-doc-to-task-btn="true"
                    @addDocToTask="$emit('addDocToTask', $event)"
            />

            <app-input-dlg
                v-if="showTaskViewHistory"
                :show-dialog="showTaskViewHistory"
                title="Task view history"
                :cancel-button="true"
                :show-input-box="false"
                @dismiss="showTaskViewHistory = false"
                :body-padding="false"
                :width="500"
            >
                <template #body>
                    <div>
                        <v-text-field
                            placeholder="Search"
                            single-line
                            v-model="taskHistorySearchStr"
                            color="primary"
                            hide-details
                            prepend-inner-icon="search"
                            style="width: 100%"
                            class="ma-0 pa-1"
                        ></v-text-field>
                    </div>
                    <v-divider></v-divider>
                    <div>

                        <div v-if="loadingHistory" class="pa-5 c-d-flex c-align-center c-justify-center">
                            <v-progress-circular :size="30" :width="3" color="primary" indeterminate/>
                        </div>

                        <v-data-table
                            v-else
                            :headers="taskHistoryHeaders"
                            :items="taskViewHistory"
                            class="elevation-1"
                            :search="taskHistorySearchStr"
                        >
                            <template v-slot:items="props">
                                <td>{{ props.item.dateStr }}</td>
                                <td>{{ props.item.userStr }}</td>
                            </template>
                        </v-data-table>
                    </div>
                </template>
            </app-input-dlg>

        </v-card>
</template>

<script>

    import { codeBaseMixin } from '../../../codeBaseMixin';
    import { eventBus } from '../../../main';
    import FolderDlg from '../../../Folders/FolderDlg';
    import InputDlg from '../../../components/General/InputDlg';
    import TaskViewHeaderShare from "./TaskViewHeaderShare";
    import StatusView from '../components/TaskViewDetail/Components/StatusView/StatusView'
    import AddDateTime from "../../../components/General/AddDateTime";
    import {mapGetters} from "vuex";
    import format from "date-fns/format";
    import AppBrowseContacts from "@/contacts/AppBrowseContacts";
    import AppCreateLinkContact from "@/contacts/AppCreateLinkContact";

    export default {
        mixins: [codeBaseMixin],
        components: {
          AppCreateLinkContact,
          AppBrowseContacts,
            appAddDateTime: AddDateTime,
            appTaskViewHeaderShare: TaskViewHeaderShare,
            appFolderDlg: FolderDlg,
            appInputDlg: InputDlg,
            appStatusView: StatusView,
        },
        props: {
            task: Object,
            userInfo: Object,
            curTime: {
                type: Date,
                default: null
            },
            localData: Object,
            showSync: Boolean,
            getTaskHeader: Function,
        },
        data() {
            return {
                showFolderInfo: false,
                destroyFolder: false,
                showEditTitle: false,
                showStatusView: false,
                loadingSnooze: false,
                starLoading: false,
                snoozedLoading: false,
                transferLoading: false,
                followLoading: false,
                curTimeMinute: null,
                updateInterval: null,
                showTaskViewHistory: false,
                taskHistorySearchStr: null,
                loadingHistory: true,
                taskViewHistory: [],
                taskHistoryHeaders: [
                    { text: 'View Date', value: 'date' },
                    { text: 'User', value: 'userStr' },
                ],
            }
        },
        created() {
            eventBus.$on('ChangesSaved', this.changesSaved);

            eventBus.$on('taskTransferStart', this.listenToTransfer);

            this.curTimeMinute = new Date();

            this.updateInterval = setInterval(() => {
                this.curTimeMinute = new Date();
            }, 10000)
        },

        beforeDestroy() {
            eventBus.$off('taskTransferStart', this.listenToTransfer);
            eventBus.$off('ChangesSaved', this.changesSaved);
            try {
                clearInterval(this.updateInterval)
            } catch (e) {
                console.log(e)
            }
        },

        watch: {

            showTaskViewHistory(val) {
                if (val) {
                    this.loadTaskViewHistory()
                }
            },

            "task.snoozeID": {
                handler() {
                    this.snoozedLoading = false
                }
            },

            "task.ownerID": {
                handler() {
                    this.transferLoading = false
                }
            },

            "task": {
                handler() {
                    this.destroyFolder = true;
                    this.$nextTick(() => {
                        this.destroyFolder = false;
                    })
                }
            },
        },

        methods: {
          
          contactAdded(contact) {
            this.$refs.contactView.selectContact(contact)
          },

            async loadTaskViewHistory() {
                try {
                    this.loadingHistory = true;
                    this.taskHistorySearchStr = "";
                    this.taskViewHistory = [];
                    this.taskViewHistory = (await this.$newReq('GET', `tasks/viewHistory/${this.userInfo.entityID}/${this.task.taskID}`))
                        .map((row) => {
                            return {
                                user: this.usersRef[row.fk_userId],
                                userStr: `${this.usersRef[row.fk_userId].name} ${this.usersRef[row.fk_userId].surname}`.trim(),
                                date: new Date(row.viewDate),
                                dateStr: format(new Date(row.viewDate), 'YYYY-MM-DD HH:mm:ss')
                            }
                        });
                } catch (e) {
                    console.log(e)
                    this.$snack.networkError();
                } finally {
                    this.loadingHistory = false;
                }
            },

            changesSaved() {
                this.destroyFolder = true;
                this.$nextTick(() => {
                    this.destroyFolder = false;
                })
            },

            followTask() {

                let newFollowers = this.task.followers.includes(this.userInfo.userID)
                    ? this.task.followers.filter(user => user !== this.userInfo.userID)
                    : this.task.followers.concat(this.userInfo.userID)

                this.followLoading = true;
                this.fetch({method: 'POST', url:`/taskSync/followTask/${this.userInfo.entityID}/${this.task.taskID}`, body: newFollowers})
                    .then(() => {
                        this.task.followers = newFollowers;
                        this.followLoading = false;
                    })
                    .catch((e) => {
                        this.standardError(e);
                        this.followLoading = false;
                    })
            },

            inputCalled(val) {
                this.showStatusView = val
            },

            listenToTransfer(taskID) {
                if (taskID === this.task.taskID) {
                    this.transferLoading = true;
                }
            },

            transferTask(taskID) {
                eventBus.$emit('transferTask',{taskID: taskID, userInfo: this.userInfo})
            },

            transferTaskSubOwner(taskID) {
                eventBus.$emit('transferTaskSubOwner',{taskID: taskID, userInfo: this.userInfo, task: this.task})
            },

            starTask() {
                this.starLoading = true;
                this.fetch({method: 'GET', url:`/taskSync/starredTask/${this.userInfo.entityID}/${this.userInfo.userID}/${this.task.taskID}/${this.boolToInt(!this.task.starred)}`})
                    .then(() => {
                        this.task.starred = !this.task.starred;
                        this.starLoading = false;
                    })
                    .catch((e) => {
                        this.standardError(e);
                        this.starLoading = false;
                    })
            },

            dismissFolderDlg() {
                this.showFolderInfo = false;
                eventBus.$emit('refreshTaskFieldSetInfo');
            },

            updateTaskTitle(newTitle) {
                if (newTitle != null && newTitle != '') {
                    this.showEditTitle = false;
                    this.fetch({method: 'PATCH', url: `/tasks/renameTaskTitle/${this.userInfo.entityID}/${this.task.taskID}`, body: JSON.stringify({title: newTitle})})
                        .then((data) => {
                            this.task.title = newTitle;
                            this.showSnack('Info', 'Changes Saved', 'Close', 'primary')
                        })
                        .catch((e) => {
                            // eslint-disable-next-line
                            console.log(e)
                            this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                        })
                } else {
                    this.showSnack('Error', 'A Task Title Can Not Be Blank', 'Close', 'red')
                }
            },

            getDaysOld(date) {
                let dNow = new Date();
                return this.getDaysDifference(dNow, date);
            },

            snoozeTask(snoozeDate) {
                this.snoozedLoading = true;
                this.fetch({method: 'PATCH', url:`/tasks/sn/snoozeTask/${this.userInfo.entityID}/${this.userInfo.userID}/${this.task.taskID}`, body: JSON.stringify({date: snoozeDate})})
                    .then((data) => {
                        eventBus.$emit('newSnoozedTaskObj', data)
                    })
                    .catch((e) => {
                        console.log(e);
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
                        this.snoozedLoading = false;
                    })
            },

            unSnoozeTask(snoozeID) {
                this.snoozedLoading = true;
                this.fetch({method: 'PATCH', url:`/tasks/sn/unSnoozeTask/${this.userInfo.entityID}/${this.userInfo.userID}/${this.task.taskID}/${snoozeID}`})
                    .then(() => {
                        eventBus.$emit('removeSnoozedTaskObj', this.task.taskID)
                    })
                    .catch((e) => {
                        console.log(e);
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                        this.snoozedLoading = false;
                    })
            },
        },
        computed: {

            ...mapGetters(["usersRef"]),
            ...mapGetters("userInfo", ["softBlock"]),

            showShare() {
                if (!this.task.allowShare)
                    return false
                if (this.task.allowShare && this.task.ownerID === this.userInfo.userID)
                    return true



                try {

                    let taskShareGroups = JSON.parse(this.task.shareAdminGroups)

                    return taskShareGroups && Array.isArray(taskShareGroups) && this.userInfo.userGroups.filter(ug => taskShareGroups.includes(ug)).length
                } catch (e) {
                    return false
                }
            },

            following() {
                return this.task && this.task.followers && Array.isArray(this.task.followers)
                        ? this.task.followers.includes(this.userInfo.userID)
                        : false
            },

            selectedFolder() {
                return {name: this.task.folderName, id: this.task.folderID}
            },

            escalationColor() {
                return this.getEscalationColor(this.task, this.curTimeMinute)
            }
        }
    }
</script>

<style scoped>

    .subUserHoverText:hover {
        border: 1px solid #00897b;
        background-color: rgba(0, 137, 123, 0.18);
    }

    .subUserHoverText {
        border: 1px solid rgba(255,255,255,0);
        border-radius: 3px;
        cursor: pointer
    }

</style>
