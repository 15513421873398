<template>

    <div v-if="field.lookup">

        <div :class="topicClass">
            <div :class="headingClass">
                Limit to Folder Groups:
            </div>
            <div class="pt-1">
<!--Limit to Folder Groups-->
                <workflow-manager-group-selector
                        v-model="field.lookup.folderGroupLimit"
                        :user-groups="folderGroups"
                        ac-label="No Folder Groups Selected"
                        btn-label="Select Folder Groups (Not Limited)"
                        lbl-label="Select Folder Groups"
                        @input="$emit('input')"
                />
            </div>
        </div>

        <v-layout row wrap>
<!--Auto Select-->
            <v-flex xs6>
                <div :class="topicClass" style="overflow-y: hidden">
                    <div :class="headingClass">
                        Auto Select Current Folder:
                    </div>
                    <div :class="itemClass">
                        <v-switch
                                v-model="field.lookup.autoSelectCurrentFolder"
                                :label="field.lookup.autoSelectCurrentFolder ? 'Enabled' : 'Disabled'"
                                hide-details
                                color="secondary"
                                class="pa-0 ma-0"
                                @change="(event) => {
                                if (event) {
                                    field.lookup.createOnly = false
                                }

                                $emit('input')
                            }"
                        ></v-switch>
                    </div>
                </div>
            </v-flex>
<!--Disable After Save-->
            <v-flex xs6>
                <div :class="topicClass" style="overflow-y: hidden">
                    <div :class="headingClass">
                        Disable After Save:
                    </div>
                    <div :class="itemClass">
                        <v-switch
                                v-model="field.lookup.disableAfterSave"
                                :label="field.lookup.disableAfterSave ? 'Enabled' : 'Disabled'"
                                hide-details
                                color="secondary"
                                class="pa-0 ma-0"
                                @change="$emit('input')"
                        ></v-switch>
                    </div>
                </div>
            </v-flex>
            <v-flex xs6>
<!--Allow Create-->
                <div :class="topicClass" style="overflow-y: hidden">
                    <div :class="headingClass">
                        Allow Create:
                    </div>
                    <div :class="itemClass">
                        <v-switch
                                v-model="field.lookup.allowCreate"
                                :label="field.lookup.allowCreate ? 'Allowed' : 'Select Only'"
                                hide-details
                                color="secondary"
                                class="pa-0 ma-0"
                                @change="(event) => {
                                if (!event) {
                                    field.lookup.createOnly = false
                                }
                                $emit('input')
                            }"
                        ></v-switch>
                    </div>
                </div>
            </v-flex>
            <v-flex xs6>
            <!--Create Only-->
            <div :class="topicClass" style="overflow-y: hidden">
                <div :class="headingClass">
                    Create Only:
                </div>
                <div :class="itemClass">
                    <v-switch
                            v-model="field.lookup.createOnly"
                            :label="field.lookup.createOnly ? 'Create Only' : 'Select Enabled'"
                            hide-details
                            color="secondary"
                            class="pa-0 ma-0"
                            @change="(event) => {
                                if (event) {
                                    field.lookup.allowCreate = true
                                    field.lookup.autoSelectCurrentFolder = false
                                }

                                $emit('input')
                            }"
                    ></v-switch>
                </div>
            </div>
            </v-flex>
            <v-flex xs6>
<!--Groups With Access-->
                <div :class="topicClass" style="overflow-y: hidden">
                    <div :class="headingClass">
                        Only Show Groups With Access:
                    </div>
                    <div :class="itemClass">
                        <v-switch
                                v-model="field.lookup.onlyGroupsWithAccess"
                                :label="field.lookup.onlyGroupsWithAccess ? 'Only Groups With Access' : 'All Groups'"
                                hide-details
                                color="secondary"
                                class="pa-0 ma-0"
                                @change="$emit('input')"
                        ></v-switch>
                    </div>
                </div>
            </v-flex>
        </v-layout>
    </div>



</template>

<script>
import WorkflowManagerGroupSelector
    from "@/Admin/WorkflowEditor/Components/WorkflowManager/Workflow/Components/WorkflowManagerGroupSelector";
export default {
    components: {WorkflowManagerGroupSelector},
    props: {
        field: Object,
        folderGroups: Array
    },

    data() {
        return {
            headingClass: 'caption greyType--text font-weight-bold',
            topicClass: 'pa-1',
            itemClass: 'pl-2',
            loading: false,

            showFolderGroupLimit: false
        }
    },

    watch: {

        field: {
            immediate: true,
            handler(val) {
                switch (typeof val.lookup) {
                    case "string" : {
                        try {
                            val.lookup = JSON.parse(val.lookup);
                        } catch (e) {
                            // console.log('Made IT', val.lookup.replace(/\\"/gm, '"'))
                            val.lookup = JSON.parse(val.lookup.replace(/\\"/gm, '"'));
                        }
                        break;
                    }
                    case "object" : {
                        if (!val.lookup)
                            this.$set(val, 'lookup', {
                                folderGroupLimit: null,
                                allowCreate: true,
                                disableAfterSave: false,
                                onlyGroupsWithAccess: false,
                                autoSelectCurrentFolder: true,
                                createOnly: false,
                            })
                        break;
                    }
                    case "undefined" : {
                        this.$set(val, 'lookup', {
                            folderGroupLimit: null,
                            allowCreate: true,
                            onlyGroupsWithAccess: false,
                            autoSelectCurrentFolder: true,
                            disableAfterSave: false,
                            createOnly: false,
                        })
                        break;
                    }
                }

                if (typeof val.lookup.createOnly === 'undefined')
                    this.$set(val.lookup, 'createOnly', false)
            }
        }
    }
}
</script>

<style scoped>

</style>