<template>
    <div>
        <v-menu offset-y full-width :close-on-content-click="false" v-model="configuringField" >
            <template v-slot:activator="{ on }">
                <div v-show="splitTaskDisplay == null" v-on="on">
                    <v-btn small flat color="secondary" class="ma-0">Configure Split Task</v-btn>
                </div>
                <v-card ripple hover flat v-show="splitTaskDisplay != null" class="primary white--text" v-on="on">
                    <v-layout row align-center style="cursor: pointer" v-if="splitTaskDisplay != null">
                        <v-flex xs12 class="pa-1 pl-2 text-truncate" style="overflow-x: hidden;">
                            {{splitTaskDisplay.wfDescription}}
                        </v-flex>
                        <v-flex shrink class="pr-1">
                            <v-tooltip top>
                                <v-btn
                                        small
                                        flat
                                        icon
                                        color="white"
                                        class="pa-0 ma-0"
                                        slot="activator"
                                >
                                    <v-icon small>edit</v-icon>
                                </v-btn>
                                <span>Edit Config</span>
                            </v-tooltip>
                        </v-flex>
                    </v-layout>
                </v-card>
            </template>


            <v-card class="pa-1">
<!--Workflow-->
                <div class="pa-1">
                    <div class="caption greyType--text font-weight-bold">
                        Select Workflow:
                    </div>
                    <div class="pl-2">
                        <div v-if="localLookupDisplay == null">
                            <v-btn small flat color="secondary" class="ma-0" @click="showSelectWorkflow = true">Select
                                Workflow
                            </v-btn>
                        </div>
                        <v-card ripple hover flat v-else class="primary white--text">
                            <v-layout row align-center style="cursor: pointer" v-if="localLookupDisplay != null" @click="showSelectWorkflow = true">
                                <v-flex xs12 class="pa-1 pl-2 text-truncate" style="overflow-x: hidden;">
                                    {{localLookupDisplay.wfDescription}}
                                </v-flex>
                                <v-flex shrink class="pr-1" @click="showSelectWorkflow = true">
                                    <v-tooltip top>
                                        <v-btn
                                                small
                                                flat
                                                icon
                                                color="white"
                                                class="pa-0 ma-0"
                                                slot="activator"
                                        >
                                            <v-icon small>edit</v-icon>
                                        </v-btn>
                                        <span>Change Workflow</span>
                                    </v-tooltip>
                                </v-flex>
                            </v-layout>
                        </v-card>
                        <app-input-dlg
                                :show-dialog="showSelectWorkflow"
                                title="Select Workflow"
                                :body-padding="false"
                                :cancel-button="true"
                                :show-input-box="false"
                                :buttons="[]"
                                @dismiss="showSelectWorkflow = false"
                        >
                            <template slot="body">
                                <div class="pa-1" v-if="showSelectWorkflow">
                                    <app-workflow-select
                                            :workflows="splitTaskWorkflows"
                                            v-model="selectedWorkflow"
                                            @input="showSelectWorkflow = false"
                                            :max-height="400"
                                    ></app-workflow-select>
                                </div>
                            </template>
                        </app-input-dlg>
                    </div>
                </div>
<!--Open On Create-->
                <div class="pa-1">
                    <div class="caption greyType--text font-weight-bold">
                        Open On Create:
                    </div>
                    <div class="pl-2">
                        <v-switch
                                v-model="openOnCreate"
                                label="Open Task On Create"
                                hide-details
                                color="secondary"
                                class="pa-0 ma-0"
                        ></v-switch>
                    </div>
                </div>
<!--Split Options-->
                <div class="pa-1">
                    <div class="caption greyType--text font-weight-bold">
                        Split Options:
                    </div>
                    <div class="pl-2">
                        <v-autocomplete
                                dense
                                v-model="localLookup.splitType"
                                :items="splitTypes"
                                label="Split Options"
                                hide-details
                                single-line
                                color="primary"
                                item-text="description"
                                item-value="id"
                                class="pa-0 ma-0"
                        ></v-autocomplete>
                    </div>
                </div>
<!--Workflow Fields-->
                <div class="pa-1" v-if="localLookupDisplay != null">
                    <div class="caption greyType--text font-weight-bold">
                        Share Fields:
                    </div>
                    <div class="pl-2">
                        <div v-if="displayFieldsTruncated == null">
                            <v-btn small flat color="secondary" class="ma-0" @click="showSelectWorkflowFields = true">Select Fields</v-btn>
                        </div>
                        <v-card ripple hover flat v-else class="primary white--text">
                            <v-layout row align-center style="cursor: pointer" v-if="displayFieldsTruncated != null" @click="showSelectWorkflowFields = true">
                                <v-flex xs12 class="pa-1 pl-2 text-truncate" style="overflow-x: hidden;">
                                    {{displayFieldsTruncated.displayItems.join(', ')}}
                                </v-flex>
                                <v-flex shrink>
                                    <v-tooltip top v-if="displayFieldsTruncated.tooltipItems.length > 0">
                                        <v-btn
                                                small
                                                flat
                                                icon
                                                color="white"
                                                class="pa-0 ma-0"
                                                slot="activator"
                                        >
                                            <div class="caption">
                                                {{`+${displayFieldsTruncated.tooltipItems.length}`}}
                                            </div>
                                        </v-btn>
                                        <span>{{displayFieldsTruncated.tooltipItems.join(', ')}}</span>
                                    </v-tooltip>
                                </v-flex>
                                <v-flex shrink class="pr-1">
                                    <v-tooltip top>
                                        <v-btn
                                                small
                                                flat
                                                icon
                                                color="white"
                                                class="pa-0 ma-0"
                                                slot="activator"
                                        >
                                            <v-icon small>edit</v-icon>
                                        </v-btn>
                                        <span>Configure Fields:</span>
                                    </v-tooltip>
                                </v-flex>
                            </v-layout>
                        </v-card>
                        <app-input-dlg
                                :show-dialog="showSelectWorkflowFields"
                                title="Select Workflow Fields To Share"
                                :body-padding="false"
                                :cancel-button="true"
                                :show-input-box="false"
                                :buttons="[]"
                                @dismiss="showSelectWorkflowFields = false"
                                :width="500"
                        >
                            <template slot="body">
                                <app-fields-select
                                        :steps="steps"
                                        :fields="fields"
                                        v-model="selectedFields"
                                        :max-height="400"
                                ></app-fields-select>
                            </template>
                        </app-input-dlg>
                    </div>
                </div>
<!--Folder Options-->
                <div class="pa-1">
                    <div class="caption greyType--text font-weight-bold">
                        Folder Options:
                    </div>
                    <div class="pl-2">
                        <v-autocomplete
                                dense
                                v-model="selectedFolderOptionType"
                                :items="folderOptionTypes"
                                label="Folder Options"
                                hide-details
                                single-line
                                color="primary"
                                item-text="description"
                                item-value="id"
                                class="pa-0 ma-0"
                        ></v-autocomplete>
                    </div>
                </div>
<!--User Defined-->
                <div class="pa-1" v-if="selectedFolderOptionType == 1">
                    <div class="caption greyType--text font-weight-bold">
                        Limit Folder Selection:
                    </div>
                    <div class="pl-2">
                        <app-workflow-manager-group-selector
                                :user-groups="folderGroups"
                                btn-label="Limit Folder Selection"
                                ac-label="Limit To Folder Groups"
                                lbl-Label="Select Folder Groups:"
                                v-model="groupsWithAccess"
                        />
                    </div>
                </div>
<!--Specific Folder-->
                <div class="pa-1" v-if="selectedFolderOptionType == 2">
                    <div class="caption greyType--text font-weight-bold">
                        Select Folder:
                    </div>
                    <div class="pl-2">
                        <div v-if="selectedFolderLookupDisplay == null">
                            <v-btn small flat color="secondary" class="ma-0" @click="showSelectFolder = true">
                                Select Folder
                            </v-btn>
                        </div>
                        <v-card ripple hover flat v-else class="primary white--text">
                            <v-layout row align-center style="cursor: pointer" @click="showSelectFolder = true">
                                <v-flex xs12 v-if="selectedFolderDescription == false" class="pl-2">
                                    <v-progress-circular
                                            :size="15"
                                            :width="3"
                                            color="white"
                                            indeterminate
                                    ></v-progress-circular>
                                </v-flex>
                                <v-flex v-if="selectedFolderDescription != false" xs12 class="pa-1 pl-2 text-truncate" style="overflow-x: hidden;">
                                    {{selectedFolderDescription}}
                                </v-flex>
                                <v-flex shrink class="pr-1" @click="showSelectFolder = true">
                                    <v-tooltip top>
                                        <v-btn
                                                small
                                                flat
                                                icon
                                                color="white"
                                                class="pa-0 ma-0"
                                                slot="activator"
                                        >
                                            <v-icon small>edit</v-icon>
                                        </v-btn>
                                        <span>Change Folder</span>
                                    </v-tooltip>
                                </v-flex>
                            </v-layout>
                        </v-card>
                    </div>

                    <app-folder-dlg
                            :show-dialog="showSelectFolder"
                            @dismiss="showSelectFolder = false"
                            :select-dlg="true"
                            :user-info="userInfo"
                            :folders="foldersSelect"
                            dlg-type="allFoldersSelect"
                            @appendFolderData="foldersSelect = $event"
                            @input="selectNewSpecificFolder($event)"
                            :local-data="localData"
                            :show-sync="showSync"
                    />
                </div>

                <!--Folder Select Field-->
                <div class="pa-1" v-if="selectedFolderOptionType === 3">
                    <div class="caption greyType--text font-weight-bold">
                        Folder Select Field:
                    </div>
                    <div class="pl-2">
                        <workflow-manager-group-selector
                                v-model="localLookup.folderSelectField"
                                :user-groups="fields.filter(fField => fField.fieldType === 21)"
                                ac-label="Task Folder (Select to change)"
                                btn-label="Task Folder (Select to change)"
                                lbl-label="Folder Select Field"
                                :multiple="false"
                        />
                    </div>
                </div>

                <v-divider></v-divider>
                <div class="text-xs-right">
                    <v-btn
                            small
                            color="secondary"
                            :disabled="saveObject == null"
                            @click="doneRun"
                    >
                        Save
                    </v-btn>
                </div>
            </v-card>
        </v-menu>
    </div>
</template>

<script>
    import InputDlg from "../../../../../../../components/General/InputDlg";
    import {codeBaseMixin} from "../../../../../../../codeBaseMixin";
    import WorkflowSelect from './FieldType10Components/WorkflowSelect';
    import FieldsSelect from "./FieldType10Components/FieldsSelect";
    import FolderDlg from '../../../../../../../Folders/FolderDlg';
    import WorkflowManagerGroupSelector
        from "@/Admin/WorkflowEditor/Components/WorkflowManager/Workflow/Components/WorkflowManagerGroupSelector";

    export default {
        mixins: [codeBaseMixin],
        components: {
            WorkflowManagerGroupSelector,
            appWorkflowManagerGroupSelector: WorkflowManagerGroupSelector,
            appFolderDlg: FolderDlg,
            appFieldsSelect: FieldsSelect,
            appInputDlg: InputDlg,
            appWorkflowSelect: WorkflowSelect
        },
        props: {
            userInfo: Object,
            value: String,
            fields: Array,
            steps: Array,
            splitTaskWorkflows: Array,
            folderGroups: Array,
            localData: Object,
            showSync: Boolean,
            getTaskHeader: Function,
        },
        data() {
            return {
                configuringField: false,
                loadingWorkflows: false,
                localLookup: {
                    folderType: null,
                    workflowType: 0,
                    splitType: 0,
                    fields: [],
                    groupsWithAccess: [],
                    workflowID: null,
                    folder: null
                },
                showSelectWorkflow: false,
                showSelectWorkflowFields: false,
                folderOptionTypes: [
                    {id: 0, description: 'Same As Parent'},
                    {id: 1, description: 'User Defined'},
                    {id: 2, description: 'Specific Folder'},
                    {id: 3, description: 'Folder Select Field'},
                ],
                splitTypes: [
                    {id: 0, description: 'Single Task'},
                    {id: 1, description: 'Multiple Tasks'},
                ],
                selectedFolderDescription: null,
                showSelectFolder: false,
                foldersSelect: {
                    folderGroups: [],
                    folders: []
                },
            }
        },
        watch: {
            value: {
                immediate: true,
                handler(val) {
                    this.resetLocalValues(val);
                }
            },
            selectedFolderLookupDisplay: {
                immediate: true,
                handler(val) {
                    if (val != null) {
                        this.selectedFolderDescription = false;
                        this.getSelectedFolderDescription();
                    }
                }
            },
        },
        methods: {
            selectNewSpecificFolder(folderObj) {
                this.localLookup.folder = folderObj.id
            },

            resetLocalValues(val) {
                this.localLookup = this.parsedLookup != null
                        ? JSON.parse(JSON.stringify(this.parsedLookup))
                        : {
                            folderType: null,
                            workflowType: 0,
                            splitType: 0,
                            fields: [],
                            groupsWithAccess: [],
                            workflowID: null,
                            folder: null,
                            openOnCreate: false
                        }

                if (typeof this.localLookup.folderSelectField === 'undefined')
                    this.$set(this.localLookup, 'folderSelectField', null)

                this.$set(this, 'localLookup', this.localLookup)
            },

            getSelectedFolderDescription() {
                this.fetch({method: 'GET', url:`/workflowManager/getFolderName/${this.userInfo.entityID}/${this.selectedFolderLookupDisplay}`})
                    .then((data) => {
                        this.selectedFolderDescription = data[0].description;
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e);
                        this.selectedFolderDescription = null
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
                    })
            },

            doneRun() {
                this.$emit('input', JSON.stringify(this.saveObject));
                this.configuringField = false;
            }
        },
        computed: {

            openOnCreate: {
                get() {
                    if (typeof this.localLookup.openOnCreate == 'undefined') {
                        this.$set(this.localLookup, `openOnCreate`, false);
                    }
                    return this.localLookup.openOnCreate
                },
                set(val) {
                    this.$set(this.localLookup, `openOnCreate`, val);
                }
            },

            saveObject() {
                try {
                    if (this.localLookup.workflowID == null) {
                        return null
                    }
                    if (this.localLookup.folderType == 2 && (this.localLookup.folder == null || this.localLookup.folder == '')) {
                        return null
                    }
                    let tSaveObject = JSON.parse(JSON.stringify(this.localLookup));
                    tSaveObject.folderType = this.selectedFolderOptionType;
                    return tSaveObject
                } catch (e) {
                    return null
                }
            },

            groupsWithAccess: {
                get() {
                    try {
                        return this.localLookup.groupsWithAccess
                    } catch (e) {
                        return null
                    }
                },
                set(newVal) {
                    this.localLookup.groupsWithAccess = newVal
                }
            },
            selectedFolderLookupDisplay() {
                try {
                    return this.localLookup.folder
                } catch (e) {
                    return null
                }
            },
            selectedFolderOptionType: {
                get() {
                    try {
                        return this.localLookup.folderType
                    } catch (e) {
                        return 0
                    }
                },
                set(newVal) {
                    this.localLookup.folderType = newVal
                }
            },
            selectedFields: {
                get: function () {
                    try {
                        return this.localLookup.fields
                    } catch (e) {
                        return null
                    }
                },
                set: function (newValue) {
                    if (this.localLookup == null) {
                        this.localLookup = {}
                    }
                    this.localLookup.fields = newValue;
                }
            },
            displayFieldsTruncated() {
                if (this.localLookup == null || typeof this.localLookup.fields == "undefined" || this.localLookup.fields == null || this.localLookup.fields.length <= 0) {
                    return null;
                } else {

                    return this.localLookup.fields.reduce((returnObject, selectedField, index, allSelectedFields) => {
                        if (index <= 2) {
                            returnObject.displayItems.push(
                                this.fields.filter(obj => obj.id == selectedField.id)
                                    .pop().description
                            )
                        } else {
                            returnObject.tooltipItems.push(
                                this.fields.filter(obj => obj.id == selectedField.id)
                                .pop().description
                            )
                        }

                        if (index + 1 == allSelectedFields.length && (returnObject.displayItems.length + returnObject.tooltipItems.length) <= 0) {
                            returnObject = null;
                        }
                        return returnObject;
                    }, {
                        displayItems: [],
                        tooltipItems: []
                    })

                }
            },
            selectedWorkflow: {
                get: function () {
                    try {
                        return this.localLookup.workflowID
                    } catch (e) {
                        return null
                    }
                },
                // setter
                set: function (newValue) {
                    if (this.localLookup == null) {
                        this.localLookup = {}
                    }
                    this.localLookup.workflowID = newValue;
                }
            },
            localLookupDisplay() {
                try {
                    return this.allWorkflows.filter(obj => obj.wfID == this.localLookup.workflowID.id)
                        .pop();
                } catch (e) {
                    return null;
                }
            },
            parsedLookup() {
                if (this.value != null && this.value != '') {
                    try {
                        return this.isJson(this.value.replace(/\\"/g,'"'));
                    } catch (e) {
                        // eslint-disable-next-line
                        console.log(e)
                        return null
                    }
                } else {
                    return null
                }
            },
            allWorkflows() {
                return this.splitTaskWorkflows.reduce((wfList, workspace) => {
                    try {
                        workspace.workflowGroups.forEach(workflowGroup => {
                            try {
                                wfList = wfList.concat(workflowGroup.workflows.map(workflow => {
                                    return {
                                        wfID: workflow.id,
                                        wgID: workflowGroup.id,
                                        wsID: workspace.id,
                                        wfDescription: workflow.description,
                                        wgDescription: workflowGroup.description,
                                        wsDescription: workspace.description,
                                    }
                                }))
                            } catch (e) {
                                // eslint-disable-next-line
                                console.log(e)
                            }
                        })
                    } catch (e) {
                        // eslint-disable-next-line
                        console.log(e)
                    }
                    return wfList
                }, [])
            },
            splitTaskDisplay() {
                try {
                    return this.allWorkflows.filter(obj => obj.wfID == this.parsedLookup.workflowID.id)
                        .pop();
                } catch (e) {
                    return null;
                }
            }
        }
    }
</script>

<style scoped>

</style>
