<template>
    <app-input-dlg
            v-if="show"
            :show-dialog="show"
            title="Add New Rule"
            :cancel-button="true"
            :show-input-box="false"
            @dismiss="$emit('dismiss')"
            :body-padding="false"
            :width="600"
    >
        <template slot="body">
            <div class="pl-2 pr-0 pt-2 pb-0" style="overflow-y: hidden">
                <div :class="topicClass">
<!--Rule Description-->
                    <div :class="headingClass">
                        Rule Name:
                    </div>
                    <div :class="itemClass" class="pr-2" >
                        <v-text-field
                                label="Rule Name"
                                single-line
                                v-model="ruleName"
                                color="primary"
                                hide-details
                                style="width: 100%"
                                class="ma-0 pa-0"
                                :disabled="savingChanges"
                        ></v-text-field>
                    </div>
<!--Add Fields-->
                    <template v-if="editFields.length > 0">
                        <div :class="headingClass" class="pt-2">
                            Configure Field/s
                        </div>
                        <div :class="itemClass" style="max-height: 200px; overflow-y: auto">
                            <div class="caption" v-for="(field, n) in editFields" :key="n">
                                <app-configure-rule-field
                                        :value="field"
                                        :select-lists="selectLists"
                                        @setShow="field.showField = $event"
                                        @setOperationID="field.operationID = $event"
                                        @setValue="field.value = $event"
                                        :valid="field.showField || (!field.showField && ([2,7].includes(field.operationID) || (![2,7].includes(field.operationID) && field.value != null && field.value != '')))"
                                />
                            </div>
                        </div>
                    </template>
<!--Add Steps-->
                    <template v-if="editSteps.length > 0">
                        <div :class="headingClass" class="pt-2">
                            Add Steps
                        </div>
                        <div :class="itemClass" style="max-height: 200px; overflow-y: auto">
                            <div class="caption" v-for="(step, n) in editSteps" :key="n">
                                <div class="px-2 hover-item">
                                    <v-layout row align-centerstyle="cursor: pointer">
                                        <v-flex xs12>
                                            {{step.description}}
                                        </v-flex>
                                        <v-flex shrink>
                                            <v-tooltip top>
                                                <v-btn
                                                        small
                                                        icon
                                                        flat
                                                        class="pa-0 ma-0"
                                                        color="red"
                                                        slot="activator"
                                                        @click="editSteps.splice(n,1)"
                                                >
                                                    <v-icon small>remove</v-icon>
                                                </v-btn>
                                                <span>Don't Add Step</span>
                                            </v-tooltip>
                                        </v-flex>
                                    </v-layout>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
<!--Add Fields Save-->
            <template v-if="(editFields.length + editSteps.length) > 0">
                <v-divider></v-divider>
                <div class="pb-1">
                    <v-layout row align-center>
                        <v-flex xs12 class="caption greyType--text px-3">
                            {{saveMessage}}
                        </v-flex>
                        <v-flex shrink>
                            <v-btn
                                    small
                                    color="secondary"
                                    :disabled="saveMessage != null || savingChanges"
                                    :loading="savingChanges"
                                    @click="doneRun"
                            >
                                Add Rule
                            </v-btn>
                        </v-flex>
                    </v-layout>
                </div>
            </template>
        </template>
    </app-input-dlg>
</template>

<script>
    import {codeBaseMixin} from "../../../../../codeBaseMixin";
    import InputDlg from "../../../../../components/General/InputDlg";
    import ConfigureRuleField from "./ConfigureRuleField";

    export default {
        mixins: [codeBaseMixin],
        components: {
            appInputDlg: InputDlg,
            appConfigureRuleField: ConfigureRuleField,
        },
        props: {
            show: {
                default: false,
                type: Boolean
            },
            editRule: Object,
            addFields: Array,
            addSteps: Array,
            selectLists: Array,
            userInfo: Object,
            selectedWorkflowID: Number
        },
        data() {
            return {
                headingClass: 'caption greyType--text font-weight-bold',
                topicClass: 'pa-1',
                itemClass: 'pl-2',

                editFields: [],
                editSteps: [],
                operationDescriptions: {
                    0: "=",
                    1: "Not Equal",
                    2: "Not Blank",
                    3: "Bigger",
                    4: "Smaller",
                    5: "After",
                    6: "Before",
                },
                savingChanges: false,
                savingNameChange: false,
                ruleName: null,
            }
        },
        watch: {
            show(val) {
                if (val) {
                    this.ruleName = null;
                    this.editFields = this.addFields.map(obj => {
                        return {
                            showField: true,
                            value: null,
                            operationID: 0,
                            field: obj
                        }
                    })
                        .sort((a, b) => a['field']['description'] < b['field']['description'] ? -1 : 1);

                    if (this.editFields.length > 0) {
                        this.editFields[0].showField = false;
                    }

                    this.editSteps = this.addSteps.sort((a,b) => a['description'] < b['description'] ? -1 : 1);
                }
            }
        },
        methods: {

            doneRun() {
                this.savingChanges = true;

                let tBody = {
                    description: this.ruleName,
                    fk_workflow: this.selectedWorkflowID,
                    showFields: this.editFields.reduce((list, field) => {
                        if (field.showField == true) {
                            list.push(field.field.id)
                        }
                        return list
                    }, []),
                    showSteps: this.editSteps.map((obj) => {return obj.id}),
                    addConditions: this.editFields.reduce((list, field) => {
                        if (field.showField == false) {
                            list.push({fk_field: field.field.id, operationID: field.operationID, value: field.value})
                        }
                        return list
                    }, [])
                };

                this.fetch({method: 'POST', url:`/workflowManager/rules/add/new/${this.userInfo.entityID}`, body: JSON.stringify(tBody)})
                    .then((data) => {
                        this.savingChanges = false;
                        this.showSnack('Info', 'Rule Added', 'Close', 'primary');
                        this.$emit('ruleAdded', data);
                        this.$emit('dismiss');
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e);
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
                        this.savingChanges = false;
                    })

            },
        },
        computed: {
            saveMessage() {
                let bFine = true;

                if (this.editFields.filter(obj => !obj.showField).length <= 0) {
                    bFine = 'A minimum of one condition is required.'
                }

                this.editFields.forEach(field => {
                    if (!(field.showField || (!field.showField && ([2, 7].includes(field.operationID) || (![2, 7].includes(field.operationID) && field.value != null && field.value != ''))))) {
                        bFine = `${field.field.description} - not configured`
                    }
                });

                if (bFine === true) {
                    return null
                } else {
                    return bFine
                }
            },
        }
    }
</script>

<style scoped>

</style>
