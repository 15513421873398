<template>
  <v-card
      v-bind="cardProps"
      class="py-0 my-0 px-0"
      style="transition: all 300ms ease-in-out; width: 100%"
  >
    
    <div
        class="px-0 mx-0"
        v-if="userInfo.pdfPreview && fileExt === 'pdf'"
        style="height: 150px;
        position: relative;
        overflow-y: hidden"
    >
      <img
          :draggable="false"
          :src="allowDownload ? `${serverAddress}/download/pdfPreview/600/600/${documentId}/${filename}/${token()}` : $store.state.pdfFailoverURIString"
          :alt="filename"
          style="width: 100%;"
          @error="$event.target.src = $store.state.pdfFailoverURIString"
      />
      
      <div style="left: 0; top: 0; z-index: 1; position:absolute; width: 100%; height: 100%; background-image: linear-gradient(180deg, rgba(255,255,255,0), var(--card-back))">
      </div>
    </div>
    
    <div class="c-d-flex c-align-center px-2" style="height: 48px;">
      <div class="pr-2">
        <v-img width="32px" height="32px" style="border-radius: 5px" v-if="previewImageAddr" alt="icon" :src="previewImageAddr"/>
        <v-icon :size="32" v-else>insert_drive_file</v-icon>
      </div>
      
      <div class="text-truncate c-flex-grow-1 " >
        <slot name="default">
          <div class="text-truncate body-2">
            {{filename}}
          </div>
          <div v-if="attSize" class="text-caption" style="line-height: 1">
            {{attSize}}
          </div>
        </slot>
      </div>
      
      <!--    <a :href="`${appStore.baseUrl}/download/file/${att.documentId}/${att.filename}/${appStore.accessToken}`" target="_blank" style="text-decoration: none">-->
      <!--      <v-btn-->
      <!--          class="ml-2"-->
      <!--          density="compact"-->
      <!--          variant="text"-->
      <!--          icon="download"-->
      <!--      >-->
      <!--      </v-btn>-->
      <!--    </a>-->
      
      <div>
        <slot name="actions"></slot>
      </div>
      
      <a :href="`${serverAddress}/download/document/${documentId}/${filename}/${token()}`" target="_blank">
        <v-btn
            icon
            depressed
            class="ma-0"
            :disabled="!allowDownload"
            v-if="!hideDownload"
        >
          <v-icon>download</v-icon>
        </v-btn>
      </a>
    </div>
  </v-card>
</template>

<script>

import {mapGetters} from "vuex";

const tokenMan = require('@/tokenManSite')
import {serverAddress} from "@/axiosRequest";


export default {
  props: {
    filename: String,
    documentId: String,
    size: [Number, undefined, null],
    cardProps: {
      type: Object,
      default: () => ({})
    },
    allowDownload: {
      type: Boolean,
      default: true
    },
    hideDownload: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      serverAddress,
    }
  },
  methods: {
    token: tokenMan.token,
  },
  computed: {
    ...mapGetters('userInfo', ["userInfo"]),
    
    fileExt() {
      return (this.filename || '').split('.').pop().toLowerCase()
    },
    
    previewImageAddr() {
      if (this.$store.state.typeCatalogSet.has(this.fileExt))
        return `types/${this.fileExt}.svg`
      return null
    },
    
    attSize() {
      
      if (!this.size)
        return null;
      
      const sizeNo = typeof this.size !== "number" ? +this.size : this.size
      
      if (sizeNo < 1024)
        return `${sizeNo} bytes`;
      else if (sizeNo < (1024*1024))
        return `${+(sizeNo / 1024).toFixed(2)} KB`;
      else
        return `${+(sizeNo / (1024*1024)).toFixed(2)} MB`;
    }
  },
}
</script>



<style scoped>

</style>