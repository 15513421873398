<template>
    <div
        :class="selectedDataSet != null && selectedDataSet.id === dataSet.id ? 'selected-item' : 'hover-item'"
        style="overflow-x: hidden;"
    >
        <v-layout row align-center style="overflow-x: hidden; cursor: pointer" class="hide-parent" @click="$emit('selectDataset', dataSet)">
            <v-flex shrink class="pl-1">
                <v-tooltip top>
                    <v-icon class="pa-0 ma-0" :color="iconClass" slot="activator">
                        {{intToBool(dataSet.allowMultiple) ? 'file_copy' : 'insert_drive_file'}}
                    </v-icon>
                    <span>{{intToBool(dataSet.allowMultiple) ? 'Multiple Per Folder' : 'Only One Per Folder'}}</span>
                </v-tooltip>
            </v-flex>
            <v-flex xs12 style="overflow-x: hidden" class="pt-2 pb-1 px-2">

                <div class="text-truncate pb-1" :class="textClass">
                    {{`${dataSet.description}${!intToBool(dataSet.visible) ? ' (Disabled)' : ''}`}}
                </div>
                <div class="text-truncate caption font-weight-light" :class="blankString(groupString) ? 'red--text font-weight-bold' : 'greyType--text'">
                    {{!blankString(groupString) ? groupString : 'No Security Assigned'}}
                </div>
            </v-flex>
<!--            <v-flex shrink class="hide-child pr-1">-->
<!--                <v-tooltip top>-->
<!--                    <v-btn-->
<!--                            small-->
<!--                            icon-->
<!--                            flat-->
<!--                            color="primary"-->
<!--                            class="pa-0 ma-0"-->
<!--                            slot="activator"-->
<!--                            @click="downloadReport"-->
<!--                    >-->
<!--                        <v-icon>cloud_download</v-icon>-->
<!--                    </v-btn>-->
<!--                    <span>Download Dataset</span>-->
<!--                </v-tooltip>-->
<!--            </v-flex>-->
            <v-flex shrink class="hide-child pr-1">
                <v-tooltip top>
                    <v-btn
                            small
                            icon
                            flat
                            color="primary"
                            class="pa-0 ma-0"
                            slot="activator"
                            @click="showEdit = true"
                    >
                        <v-icon>edit</v-icon>
                    </v-btn>
                    <span>Edit Dataset</span>
                </v-tooltip>
            </v-flex>
            <app-input-dlg
                    v-if="showEdit"
                    :show-dialog="showEdit"
                    :title="`Edit ${dataSet.description}`"
                    :cancel-button="true"
                    :show-input-box="false"
                    @dismiss="showEdit = false"
                    :body-padding="false"
                    :width="500"
            >
                <template slot="body">

<!--Description-->
                    <div :class="topicClass">
                        <div :class="headingClass">
                            Dataset Description:
                        </div>
                        <div :class="itemClass">
                            <v-text-field
                                    label="Dataset Description"
                                    single-line
                                    color="primary"
                                    hide-details
                                    style="width: 100%"
                                    class="pa-0 ma-0"
                                    ref="stepDescription"
                                    v-model="tempDataSet.description"
                            />
                        </div>
                    </div>
<!--Single Multiple / Enabled-->
                    <v-layout row>
                        <v-flex xs6>
                            <div :class="topicClass" style="overflow-y: hidden">
                                <div :class="headingClass">
                                    Active Status:
                                </div>

                                <div :class="itemClass">
                                    <v-switch
                                            v-model="visible"
                                            hide-details
                                            color="secondary"
                                            :label="visible ? 'Enabled' : 'Disabled'"
                                            class="pa-0 ma-0"
                                    />
                                </div>
                            </div>
                        </v-flex>
                        <v-flex xs6>
                            <div :class="topicClass" style="overflow-y: hidden">
                                <div :class="headingClass">
                                    Dataset Type:
                                </div>

                                <div :class="itemClass">
                                    <v-switch
                                            v-model="dataSetType"
                                            hide-details
                                            color="secondary"
                                            class="pa-0 ma-0"
                                            :label="dataSetType ? 'Multiple' : 'Single'"
                                    />
                                </div>
                            </div>
                        </v-flex>
                    </v-layout>
<!--Limit To Folder Groups-->
                    <div :class="topicClass">
                        <div :class="headingClass">
                            Limit to Folder Groups:
                        </div>
                        <div :class="itemClass">
                            <app-workflow-manager-group-selector
                                    :user-groups="folderGroups"
                                    btn-label="Any Folder"
                                    ac-label="Limit to Folder Groups"
                                    lbl-label="Select Folder Groups:"
                                    v-model="selectedFolderGroups"
                            />
                        </div>
                    </div>
<!--Security-->
                    <div :class="topicClass">
                        <div :class="headingClass">
                            Dataset Security:
                        </div>
                        <div><v-divider></v-divider></div>
                        <div class="scrollable" style="max-height: 200px">
                            <div
                                    v-for="item in securityLookup"
                                    :key="item.fk_user_group"
                            >
                                <v-layout
                                        row
                                        align-center
                                        class="hide-parent hover-item"
                                        style="cursor: pointer"
                                >
                                    <v-flex shrink class="pl-2">
                                        <v-tooltip top>
                                            <v-btn
                                                    small
                                                    icon
                                                    flat
                                                    color="primary"
                                                    style="height: 20px; width: 20px"
                                                    slot="activator"
                                                    class="ma-0"
                                                    @click="updateReadOnlyValueForUserGroup(item.fk_user_group)"
                                            >
                                                <v-icon small>
                                                    {{intToBool(item.readOnly) ? 'check_box' : 'check_box_outline_blank'}}
                                                </v-icon>
                                            </v-btn>
                                            <span>Read Only</span>
                                        </v-tooltip>
                                    </v-flex>
                                    <v-flex xs12 class="px-2 py-2 caption">
                                        {{item.description}}
                                    </v-flex>
                                    <v-flex shrink class="hide-child pr-2">
                                        <v-tooltip top>
                                            <v-btn
                                                    small
                                                    icon
                                                    flat
                                                    color="red"
                                                    style="height: 20px; width: 20px"
                                                    slot="activator"
                                                    class="ma-0"
                                                    @click="removeUserGroup(item.fk_user_group)"
                                            >
                                                <v-icon small>delete</v-icon>
                                            </v-btn>
                                            <span>Remove User Group</span>
                                        </v-tooltip>
                                    </v-flex>
                                </v-layout>
                                <v-divider></v-divider>
                            </div>
                        </div>
                        <div class="text-xs-center pt-2">
                            <v-btn @click="showAddUserGroup = true" small outline flat color="secondary" class="ma-0">
                                Add User Group
                            </v-btn>
                        </div>
                    </div>
                    <div v-if="saveChangesMessage != null" :class="topicClass" class="caption red--text font-weight-bold">
                        {{saveChangesMessage}}
                    </div>
                </template>
                <template slot="actions">
                    <v-btn @click="saveChanges" small color="secondary" class="ma-0" :disabled="saveChangesMessage != null">Save Changes</v-btn>
                </template>
            </app-input-dlg>

            <app-input-dlg
                    v-if="showAddUserGroup"
                    :show-dialog="showAddUserGroup"
                    title="Add User Group"
                    :cancel-button="true"
                    :show-input-box="false"
                    @dismiss="showAddUserGroup = false"
                    :body-padding="false"
            >
                <template slot="body">
                    <div class="pa-2">
                        <div :class="headingClass">
                            Dataset Security:
                        </div>
                        <div :class="itemClass">
                            <v-autocomplete
                                    dense
                                    v-model="selectedAddUserGroup"
                                    :items="availableAddUserGroups"
                                    label="Select User Group"
                                    hide-details
                                    single-line
                                    color="primary"
                                    item-text="description"
                                    item-value="id"
                                    class="pa-0 ma-0"
                                    ref="addUserGroupAutoComplete"
                                    multiple
                                    small-chips
                                    deletable-chips
                            />
                        </div>
                    </div>
                </template>
                <template slot="actions">
                    <v-btn @click="addUserGroup" small color="secondary" class="ma-0" :disabled="selectedAddUserGroup == null">Add User Group</v-btn>
                </template>
            </app-input-dlg>
        </v-layout>
        <v-divider></v-divider>
    </div>
</template>

<script>
    import {codeBaseMixin} from "../../../codeBaseMixin";
    import InputDlg from "../../../components/General/InputDlg";
    import WorkflowManagerGroupSelector
        from "../../WorkflowEditor/Components/WorkflowManager/Workflow/Components/WorkflowManagerGroupSelector";
    import axios from 'axios';
    import tokenMan from '../../../tokenManSite'

    export default {
        components: {
            appWorkflowManagerGroupSelector: WorkflowManagerGroupSelector,
            appInputDlg: InputDlg
        },
        mixins: [codeBaseMixin],
        props: {
            userInfo: Object,
            dataSet: Object,
            selectedDataSet: Object,
            groupString: String,
            userGroups: Array,
            folderGroups: Array,
        },
        data() {
            return {
                headingClass: 'caption greyType--text font-weight-bold',
                topicClass: 'pa-1',
                itemClass: 'pl-2',
                showEdit: false,
                tempDataSet: null,
                showAddUserGroup: false,
                selectedAddUserGroup: null,
                savingChanges: false,
            }
        },
        watch: {
            showEdit: {
                immediate: true,
                handler(shown) {
                    if (shown) {
                        this.tempDataSet = JSON.parse(JSON.stringify(this.dataSet))
                    }
                }
            },
            showAddUserGroup(shown) {
                if (shown) {
                    this.selectedAddUserGroup = null;
                    this.$nextTick(() => {
                        this.$refs.addUserGroupAutoComplete.focus();
                    })
                }
            }
        },
        methods: {
            // downloadReport() {
            //     let dSet = JSON.parse(JSON.stringify(this.dataSet));
            //         var a = document.createElement("a");
            //         a.href = `${this.serverAddress}/reports/fullDatasetReport/${this.userInfo.entityID}/${dSet.id}`;
            //         a.click();
            // },

            downloadReport() {

                this.showSnack('Info', 'Your download will start shortly. Please be patient if it is a large dataset', 'Close', 'primary')
                let dSet = JSON.parse(JSON.stringify(this.dataSet));
                axios({
                    url: `${this.serverAddress}/reports/fullDatasetReport/${this.userInfo.entityID}/${dSet.id}`, //your url
                    method: 'GET',
                    headers: {
                        'tl-token': tokenMan.token(),
                    },
                    responseType: 'blob', // important
                }).then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `${dSet.description}.xlsx`); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                }).catch((e) => {
                    // eslint-disable-next-line
                    console.log(e);
                    this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                });
            },

            saveChanges() {
                this.savingChanges = true;
                this.fetch({method: 'PATCH', url:`/datasetmanager/dataset/${this.userInfo.entityID}`, body:JSON.stringify({
                        id: this.tempDataSet.id,
                        description: this.tempDataSet.description,
                        visible: this.tempDataSet.visible,
                        allowMultiple: this.tempDataSet.allowMultiple,
                        limitToFolderGroups: this.tempDataSet.limitToFolderGroups,
                        allGroups: Array.isArray(this.tempDataSet.securityLookup)
                            ? this.tempDataSet.securityLookup.map(obj => {return obj.fk_user_group})
                            : [],
                        readOnlyGroups: Array.isArray(this.tempDataSet.securityLookup)
                            ? this.tempDataSet.securityLookup.filter(obj => this.intToBool(obj.readOnly))
                                .map(obj => {return obj.fk_user_group})
                            : []
                    })})
                    .then((data) => {
                        this.$emit('patch', data);
                        this.savingChanges = false;
                        this.showEdit = false;
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e)
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                        this.savingChanges = false;
                    })
            },

            updateReadOnlyValueForUserGroup(groupID) {
                this.tempDataSet.securityLookup.forEach(item => {
                    if (item.fk_user_group === groupID) {
                        item.readOnly = this.boolToInt(!this.intToBool(item.readOnly))
                    }
                })
            },
            addUserGroup() {
                if (!Array.isArray(this.tempDataSet.securityLookup)) {
                    this.tempDataSet.securityLookup = [];
                }

                this.tempDataSet.securityLookup = this.tempDataSet.securityLookup.concat(this.selectedAddUserGroup.map(obj => {
                    return {
                        fk_user_group: obj,
                        readOnly: 0
                    }
                }));

                this.showAddUserGroup = false;
            },
            removeUserGroup(groupID) {
                this.tempDataSet.securityLookup = this.tempDataSet.securityLookup.filter(obj => obj.fk_user_group != groupID)
            }
        },
        computed: {
            selectedFolderGroups: {
                get() {
                    return Array.isArray(this.tempDataSet.limitToFolderGroups) ? this.tempDataSet.limitToFolderGroups : [];
                },
                set(newValue) {

                    this.tempDataSet.limitToFolderGroups = newValue;
                }
            },

            textClass() {
                return this.selectedDataSet != null && this.selectedDataSet.id === this.dataSet.id
                    ? 'font-weight-bold caption'
                    : (
                        this.intToBool(this.dataSet.visible)
                            ? 'font-weight-regular caption'
                            : 'greyType--text font-weight-regular caption'
                    )
            },
            iconClass() {
                return this.selectedDataSet != null && this.selectedDataSet.id === this.dataSet.id
                    ? 'primary'
                    : (
                        this.intToBool(this.dataSet.visible)
                            ? 'primary'
                            : 'grey'
                    )
            },
            visible: {
                get() {
                    return this.intToBool(this.tempDataSet.visible)
                },
                set(value) {
                    this.tempDataSet.visible = this.boolToInt(value)
                }
            },
            dataSetType: {
                get() {
                    return this.intToBool(this.tempDataSet.allowMultiple)
                },
                set(value) {
                    this.tempDataSet.allowMultiple = this.boolToInt(value)
                }
            },
            flatUserGroups() {
                try {
                    return this.userGroups.reduce((obj, group) => {
                        obj[group.id] = group.description
                        return obj;
                    }, {})
                } catch (e) {
                    // eslint-disable-next-line
                    console.log(e)
                    return null
                }
            },
            securityLookup() {
                if (!Array.isArray(this.tempDataSet.securityLookup)) {
                    return []
                } else {
                    return this.tempDataSet.securityLookup.map(securityInfo => {
                        try {
                            return {
                                fk_user_group: securityInfo.fk_user_group,
                                readOnly: securityInfo.readOnly,
                                description: this.flatUserGroups[securityInfo.fk_user_group]
                            }
                        } catch (e) {
                            console.log(e)
                            return {
                                fk_user_group: securityInfo.fk_user_group,
                                readOnly: securityInfo.readOnly,
                                description: null
                            }
                        }
                    })
                    .sort((a,b) => a['description'] < b['description'] ? -1 : 1);
                }
            },
            availableAddUserGroups() {
                let addedGroups = this.securityLookup.map(obj => {
                    return obj.fk_user_group
                });
                try {
                    return this.userGroups.filter(obj => !addedGroups.includes(obj.id))
                        .sort((a,b) => a['description'] < b['description'] ? -1 : 1);
                } catch (e) {
                    // eslint-disable-next-line
                    console.log(e)
                    return []
                }
            },
            saveChangesMessage() {
                if (this.blankString(this.tempDataSet.description)) {
                    return 'A Description is Required'
                } else {
                    return null
                }
            }
        }
    }
</script>

<style scoped>

</style>
