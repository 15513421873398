<template>
  <div>
    <input-dlg
        :show-dialog="shownRelay"
        title="Entity Options"
        :cancel-button="true"
        @dismiss="shownRelay = false"
        :full-screen="true"
        :body-padding="false"
    >
      
      <template #body>
        
        <div class="fill-height pa-5 c-d-flex c-align-center c-justify-center" v-if="startingUp">
          <v-progress-circular
              :size="40"
              :width="5"
              color="primary"
              indeterminate
          ></v-progress-circular>
        </div>
        <template v-else>
          
          <div class="of-y page-back">
            
            <div class="c-d-flex c-justify-center" >
              <v-card :max-width="600" class="c-flex-grow-1 ma-5">
                <v-card-title class="title">
                  Entity Information
                </v-card-title>
                <v-card-text>
                  <div class="">
                    <v-text-field
                        outline
                        v-model="entityOptions.description"
                        label="Entity Name"
                        hide-details
                    ></v-text-field>
                  </div>
                  
                  <div class="pt-2">
                    <v-text-field
                        outline
                        v-model.number="entityOptions.maxActiveUsers"
                        label="Max Active Users"
                        hide-details
                        type="number"
                    ></v-text-field>
                  </div>
                </v-card-text>
                <v-divider/>
                <v-card-actions class="c-d-flex c-justify-end">
                  <v-btn
                      small
                      flat
                      @click="startUp"
                  >Cancel</v-btn>
                  
                  <v-btn
                      color="secondary"
                      small
                      @click="updateEntity"
                  >Save Changes</v-btn>
                </v-card-actions>
              </v-card>
            </div>
            
            <v-card class="mb-5 mx-5">
              
              <v-card-title class="c-d-flex c-align-center">
                <div class="title text-primary primary-text pa-2 c-flex-grow-1">Site Config</div>
                <v-btn color="primary" outline small @click="config.push({id: null, description: null, val: null, backEndOnly: '1'})">Add Row</v-btn>
              </v-card-title>
              
              <v-data-table
                  :headers="configHeaders"
                  :items="config"
                  class="elevation-1"
                  :rows-per-page-items="[{'text':'All','value':-1}, 5,10,25]"
                  :disable-initial-sort="true"
              >
                <template v-slot:items="{item}">
                  
                  <td style="min-width: 200px">
                    <template v-if="item.id">
                      {{ item.description }}
                    </template>
                    <template v-else>
                      <v-text-field
                          v-model="item.description"
                          placeholder="Config Value Key"
                          hide-details
                          single-line
                      ></v-text-field>
                    </template>
                  
                  </td>
                  <td>
                    <v-btn
                        icon
                        @click="item.backEndOnly = item.backEndOnly == 1 ? '0' : '1'"
                    >
                      <v-icon :color="item.backEndOnly == 1 ? 'blue' : 'green'">
                        {{ item.backEndOnly == 1 ? 'check' : 'close' }}
                      </v-icon>
                    </v-btn>
                  </td>
                  
                  <td style="width: 100%">
                    <v-textarea
                        v-model="item.val"
                        hide-details
                        single-line
                        :auto-grow="true"
                        :rows="1"
                    ></v-textarea>
                  </td>
                  
                  
                  <td class="text-xs-right" style="min-width: 70px">
                    <v-btn
                        icon
                        color="error"
                        small
                        flat
                        @click="config = config.filter(v => v !== item)"
                    ><v-icon>delete</v-icon></v-btn>
                  </td>
                
                
                </template>
                
                <template #actions-append>
                  
                  <div class="pr-2">
                    <v-btn
                        small
                        flat
                        @click="startUp"
                    >Cancel</v-btn>
                    
                    <v-btn
                        color="secondary"
                        small
                        @click="updateConfig"
                    >Save Changes</v-btn>
                  </div>
                
                
                
                </template>
              
              
              </v-data-table>
            </v-card>
            
            <v-card class="ma-5">
              
              <v-card-title class="c-d-flex c-align-center">
                <div class="title text--primary primary--text pa-2 c-flex-grow-1">3CX Servers</div>
                <v-btn color="primary" outline small @click="editServer = {
              description: null,
              url:null,
              enabled:true,
              username: null,
              password: null,
              v20: false
            }">Add Server
                </v-btn>
              </v-card-title>
              
              
              
              <v-data-table
                  :headers="tcxHeaders"
                  :items="tcxServers"
                  class="elevation-1"
              >
                <template v-slot:items="{item}">
                  
                  <td>{{ item.description }}</td>
                  <td>{{ item.url }}</td>
                  <td><v-icon :color="!item.enabled ? 'error' : 'green'">{{ !item.enabled ? 'close' : 'check' }}</v-icon></td>
                  <td>{{ item.username }}</td>
                  <td><v-icon :color="item.v20 ? 'blue' : null">{{ !item.v20 ? 'close' : 'check' }}</v-icon></td>
                  
                  <td class="text-xs-right">
                    <v-btn
                        icon
                        color="error"
                        small
                        flat
                        @click="tcxServers = tcxServers.filter(v => v !== item)"
                    ><v-icon>delete</v-icon></v-btn>
                    <v-btn
                        icon
                        color="secondary"
                        small
                        flat
                        @click="editServer = JSON.parse(JSON.stringify(item))"
                    ><v-icon>edit</v-icon></v-btn>
                  </td>
                
                
                </template>
                
                <template #actions-append>
                  
                  <div class="pr-2">
                    <v-btn
                        small
                        flat
                        @click="setTcxServers"
                    >Cancel</v-btn>
                    
                    <v-btn
                        color="secondary"
                        small
                        @click="tcxSaveChanges"
                    >Save Changes</v-btn>
                  </div>
                
                
                
                </template>
              
              
              </v-data-table>
            
            </v-card>
            
          </div>
          
          
          
        </template>
      
      
      </template>
    
    
    
    
    </input-dlg>
    <input-dlg
        v-if="editServer"
        :show-dialog="!!editServer"
        title="Edit 3CX Server"
        :cancel-button="true"
        @dismiss="editServer = null"
        :full-screen="false"
    >
      <template #body>
        <v-text-field
            label="Description"
            v-model="editServer.description"
            hide-details
        />
        <v-text-field
            label="Url"
            v-model="editServer.url"
            hide-details
        />
        <v-switch
            label="Enabled"
            v-model="editServer.enabled"
            hide-details
        ></v-switch>
        <v-text-field
            label="username"
            v-model="editServer.username"
            hide-details
        />
        <v-text-field
            label="Password"
            v-model="editServer.password"
            type="password"
            hide-details
        />
        <v-switch
            label="V20"
            v-model="editServer.v20"
            hide-details
        ></v-switch>
        
      
      </template>
      
      
      <template #actions>
        <v-btn
            small
            flat
            @click="editServer = null"
        >Cancel</v-btn>
        
        <v-btn
            color="secondary"
            small
            @click="tcxEditDone"
        >Done</v-btn>
      </template>
    
    </input-dlg>
  </div>
  
  


</template>

<script>
import InputDlg from "@/components/General/InputDlg.vue";

export default {
  components: {InputDlg},
  props: {
    shown: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      startingUp: false,
      entityOptions: null,
      config: [],
      tcxServers: [],
      tcxHeaders: [
        {text: 'Description', value: 'description'},
        {text: 'Url', value: 'url'},
        {text: 'Enabled', value: 'enabled'},
        {text: 'Username', value: 'username'},
        {text: 'V20', value: 'v20'},
        {
          value: 'action',
          align: 'right',
          sortable: false,
        },
      ],
      configHeaders: [
        {text: 'Description', value: 'description'},
        {text: 'Back End Only', value: 'backEndOnly'},
        {text: 'Value', value: 'val'},
        {
          value: 'action',
          align: 'right',
          sortable: false,
        },
      ],
      editServer: null
    }
  },
  watch: {
    shown: {
      immediate: true,
      handler(val) {
        if (val)
          this.startUp()
      }
    }
  },
  methods: {
    async startUp() {
      try {
        this.startingUp = true
        const {config, entityOptions} = await this.$newReq('GET', 'siteSettings/startup')
        this.config = config;
        this.entityOptions = entityOptions;
        this.setTcxServers();
      } catch (e) {
        console.log(e)
      } finally {
        this.startingUp = false
      }
    },
    
    setTcxServers() {
      this.tcxServers = JSON.parse((this.config.find(v => v.description === "3CXIntegration") || {val: []}).val)
          .map((val, i) => {
            val.i = i
            val.v20 = !!val.v20
            return val
          })
    },
    
    tcxEditDone() {
      if (typeof this.editServer.i !== 'undefined') {
        
        const editObj = this.tcxServers.find(v => v.i === this.editServer.i)
        
        Object.assign(editObj, this.editServer)
      } else {
        this.editServer.i = this.tcxServers.length
        this.tcxServers.push(this.editServer)
      }
      this.editServer = null
    },
    
    tcxSaveChanges() {
        const servers = JSON.stringify(this.tcxServers.sort((a,b) => a.i > b.i ? 1 : -1))
        if (!this.config.find(v => v.description === "3CXIntegration"))
          this.config.push({id: null, description: "3CXIntegration", val: '[]'})
        const configValue = this.config.find(v => v.description === "3CXIntegration")
        configValue.val = servers
        this.updateConfig()
    },
    
    async updateConfig() {
      try {
        this.startingUp = true
        const {config} = await this.$newReq('POST', 'siteSettings/updateConfig', this.config)
        this.config = config;
        this.setTcxServers();
        this.$snack.info('Changes Saved, Please Refresh')
      } catch (e) {
        console.log(e)
        this.$snack.networkError();
      } finally {
        this.startingUp = false
      }
    },
    
    async updateEntity() {
      try {
        this.startingUp = true
        const {entityOptions} = await this.$newReq('POST', 'siteSettings/updateEntity', this.entityOptions)
        this.entityOptions = entityOptions
        this.$snack.info('Changes Saved, Please Refresh')
      } catch (e) {
        console.log(e)
        this.$snack.networkError();
      } finally {
        this.startingUp = false
      }
    }
  },
  computed: {
    shownRelay: {
      get() {
        return this.shown
      },
      set(val) {
        this.$emit("update:shown", !!val)
      }
    }
  },
}
</script>

<style scoped>

</style>