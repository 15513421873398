<template>
  <v-card class="b1 mx-auto of-y fill-height" width="500" style="position: relative">
    <div>
      <v-text-field
          prepend-inner-icon="search"
          placeholder="Search"
          hide-details
          solo
          class="ma-2 page-back"
          v-model="searchStr"
      />
    </div>
    <div v-for="cat in catList" :key="cat.title">
      <div class="py-1 px-3" style="position: sticky; top: 0; background: var(--card-back); color: var(--on-surface-grey)">
        {{cat.title}}
      </div>
      <div class="c-d-flex c-align-center c-justify-space-around c-flex-wrap px-2" style="cursor: pointer">
        <div @click="selectEmoji(emoji)" class="pa-1 emoji-div c-d-flex c-align-center c-justify-center" v-for="emoji in cat.emojis" :key="emoji.key" style="font-size: 1.5em">
          {{emoji.val}}
        </div>
      </div>
    </div>
  </v-card>
  
</template>


<script>

import emojiData from './emojiData'
import {getEmojiKey, setEmojiKey, waitForEmojiDbStarted,} from "@/components/General/EmojiPicker/frequent";

export default {
  data() {
    return {
      searchStr: null,
      frequent: []
    }
  },
  
  beforeMount() {
    // waitForEmojiDbStarted().then(() => {
    //   getEmojiKey('frequent').then(frequents => {
    //     console.log('HELOOOOOOOOO')
    //     console.log(frequents)
    //   })
    // })
    
    
    getEmojiKey('frequent')
        .then((val) => {
          if (val && val.length) {
            this.frequent = val
          }
        })
        .catch(e => {
          console.log(e)
        })
  },
  
  methods: {
    async selectEmoji(v) {
      let foundEmoji = this.frequent.find(val => val.key === v.key)
      if (!foundEmoji) {
        foundEmoji = {...v, used: 0}
        this.frequent.push(foundEmoji)
      }
      
      foundEmoji.used = foundEmoji.used + 1
      await setEmojiKey('frequent', this.frequent).catch(e => console.log(e))
      this.$emit('input', v)
    }
  },
  
  computed: {
    catList() {
      
      let list = []
      
      
      if (!this.searchStr)
        list = emojiData
      else {
        const filteredCategories = [];
        for (const cat of emojiData) {
          let filteredCat = {
            ...cat,
            emojis: cat.emojis.filter(v => v.key.includes(this.searchStr.toLowerCase()))
          }
          if (filteredCat.emojis.length)
            filteredCategories.push(filteredCat)
        }
        list = filteredCategories
      }
      
      if (this.frequent && this.frequent.length && !this.searchStr) {
        const frequentCategory = {
          title: 'Frequent',
          emojis: [...this.frequent]
              .sort((a, b) => a.used > b.used ? -1 : 1)
              .map(val => ({key: val.key, val: val.val}))
              .splice(0, 22)
        }
        list = [frequentCategory, ...list]
      }
      
      return  list
      
    }
  },
}
</script>

<style scoped>
.emoji-div {
  border: 1px solid rgba(0,0,0,0);
  width: 40px;
  height: 40px;
  line-height: 1;
  border-radius: 5px;
  transition: all 100ms ease-in-out;
}

.emoji-div:hover {
  border: 1px solid rgba(0,0,0,0.5);
  background: rgba(0,0,0,0.06);
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
</style>