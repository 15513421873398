<template>
    <v-layout row align-center style="overflow-x: hidden; overflow-y: auto">
        <v-flex xs12 class="text-xs-center" v-if="showLoading">
            <v-progress-circular
                    :size="28"
                    :width="3"
                    color="secondary"
                    indeterminate
            ></v-progress-circular>
        </v-flex>
        <template v-else>
            <v-flex
                    v-if="!blockInput"
                    style="overflow-x: hidden; overflow-y: auto"
            >
                <form
                        @dragover="allowDrop"
                        @drop="$event.preventDefault(); $event.dataTransfer.files.length == 0 ? drop($event) : dropFiles($event)"
                >
                    <v-layout row align-center style="overflow-x: hidden; overflow-y: auto">
                        <v-flex xs12 style="overflow-x: hidden; overflow-y: auto">
                            <template v-if="value && value.value != null && value.value != ''">
                                <div class="pt-1" style="overflow-x: hidden; overflow-y: auto">
                                    <template v-if="value && value.value_type == 2">
                                        <app-document-container
                                                :document="computedObj"
                                                :user-info="userInfo"
                                                :srcTaskAttachment="srcTaskAttachment"
                                                :dense="true"
                                        />
                                    </template>

                                    <template v-if="value && value.value_type == 1">

                                        <app-general-mail-container
                                                v-if="computedObj != null"
                                                :mail="computedObj"
                                                :user-info="userInfo"
                                                :dashboard="false"
                                                :task-band="false"
                                                :task-value="true"
                                                :srcTaskAttachment="srcTaskAttachment"
                                                :dense="true"
                                        />
                                    </template>
                                </div>
                            </template>
                            <template v-else>
                                <div style="border: 1px dashed lightgrey; border-radius: 3px" class="text-xs-center ma-1">
                                    <span>{{intToBool(value.editable) ? 'Drop File Here' : 'Read Only'}}</span>
                                </div>
                            </template>
                        </v-flex>
                    </v-layout>
                    <v-dialog v-model="showUploading" persistent width="200px">
                        <v-card>
                            <v-layout column>
                                <v-flex shrink class="text-xs-center pa-2 pt-3">
                                    <v-progress-circular
                                            :size="40"
                                            :width="5"
                                            color="primary"
                                            indeterminate
                                    ></v-progress-circular>
                                </v-flex>
                                <v-flex shrink class="text-xs-center pa-2">
                                    <span>Uploading File</span>
                                </v-flex>
                            </v-layout>
                        </v-card>
                    </v-dialog>
                </form>
            </v-flex>
            <v-flex xs12 v-else class="py-1">
                <v-layout row align-center>
                    <v-flex shrink>
                        <v-icon class="px-2 amber--text">warning</v-icon>
                    </v-flex>
                    <v-flex>
                        <span class="body-2 greyType--text">{{ fieldSetSelectField.description + ' Required' }}</span>
                    </v-flex>
                </v-layout>
            </v-flex>
        </template>

        <v-flex shrink v-if="!blockInput && !showLoading" style="position: relative" class="pl-2">
            <v-tooltip top v-if="!uploading">
                <template v-slot:activator="{on}">
                    <v-btn
                            class="pa-0 ma-0"
                            color="secondary"
                            flat
                            icon
                            v-on="on"
                            :disabled="!stepEditable || !value.editable"
                    >
                        <v-icon color="secondary">file_upload</v-icon>
                        <input
                            :disabled="!stepEditable || !value.editable"
                                style="width: 100%; height: 100%; left:0; top:0; position: absolute; opacity: 0"
                                ref="manualUpload"
                                type="file"
                                name="myfile"
                                :multiple="false"
                                :accept="userInfo.entityInfo.mimeTypeList"
                                @input="manualUploadRun()"
                                @drop="$event.preventDefault(); manualUploadRun($event);"
                        />
                    </v-btn>
                </template>
                Upload New Document
            </v-tooltip>
            <div v-else class="c-d-flex c-align-center c-justify-center" style="overflow-y: hidden; overflow-x: hidden">
                <v-progress-circular
                        :size="36"
                        color="secondary"
                        :value="uploadProgress"
                        :indeterminate="!uploadProgress"
                ></v-progress-circular>
            </div>

        </v-flex>
    </v-layout>
</template>

<script>

    import DocumentContainer from '../../../../../../Tasks/TaskView V2/components/Documents/DocumentContainer';
    import GeneralMailContainer from '../../../../../../components/General/generalMailContainer';
    import {eventBus} from "../../../../../../main";
    import {codeBaseMixin} from "../../../../../../codeBaseMixin";
    import {DatasetBinder} from "@/Tasks/TaskView V2/components/TaskViewDetail/datasetBinder";
    const tokenMan = require('../../../../../../tokenManSite');
    import AddDocument from "../../../Documents/AddDocument";
    import addDocument from "@/Tasks/TaskView V2/components/Documents/AddDocument";
    import axios from "axios";

    export default {
        mixins: [codeBaseMixin],
        components: {
            appDocumentContainer: DocumentContainer,
            appGeneralMailContainer: GeneralMailContainer,
            AddDocument
        },
        props: {
            value: Object,
            userInfo: Object,
            mails: Array,
            documents: Array,
            selectedStep: Object,
            task: Object,
            selectFields: Array,
            fieldSets: Array,
            stepEditable: Boolean,
            noDrag: {
                type: Boolean,
                default: false
            },
            srcTaskAttachment: {
                type: Boolean,
                default: false
            },
            linkedFieldInfo: Object,
            binder: DatasetBinder
        },
        data() {
            return {
                showUpload: {show: false},
                dragData: {
                    type:'',
                    data: {}
                },
                bindData: true,
                files: [],
                showUploading: false,
                loading: false,
                uploadProgress: null,
                uploading: false
            }
        },
        created() {
            eventBus.$on('mailDrag', this.mailDragLocalEventOne);
            eventBus.$on('attachmentDrag', this.attachmentDragLocalEvent);
            eventBus.$on('taskDocumentDrag', this.taskDocumentDragLocalEvent);
        },
        beforeDestroy() {
            eventBus.$off('mailDrag', this.mailDragLocalEventOne);
            eventBus.$off('attachmentDrag', this.attachmentDragLocalEvent);
            eventBus.$off('taskDocumentDrag', this.taskDocumentDragLocalEvent);
        },
        methods: {

            checkFileType(mimeList, file) {
                let list = mimeList
                        .split(',')
                        .map(str => {try {return str.trim().toLowerCase()} catch (e) {return ''}})
                let typeList = list
                        .filter(str => str && str.substr(0,1) !== '.')
                let extList = list
                        .filter(str => str && str.substr(0,1) === '.')
                        .map(str => {
                            try {
                                return str.substring(1)
                            } catch (e) {
                                return ''
                            }
                        })
                let ext = file.name ? file.name.split('.').pop().toLowerCase() : ''
                let type = file.type ? file.type.toLowerCase().trim() : ''
                return typeList.includes(type) || extList.includes(ext)
            },
            
            async manualUploadRun(e) {
                let file;
                if (e && e.dataTransfer.files.length)
                    file = e.dataTransfer.files[0]

                if (!file && this.$refs.manualUpload.files.length)
                    file = this.$refs.manualUpload.files[0]

                if (!file)
                    return this.$snack.error('Uploading file')

                if (((file.size / 1024) / 1024) > 50)
                    return this.$snack.error(`File upload canceled - file to large (${Math.round(((file.size / 1024) / 1024) * 10) / 10} Mb)`)
                if (!this.checkFileType(this.userInfo.entityInfo.mimeTypeList, file))
                    return this.$snack.error('File upload canceled - invalid file type')


                this.uploading = true;
                const vm = this;
                const uploadResult = await this.$newReqUpload(
                        "POST",
                        `upload/taskDocument/`,
                        file,
                        'document',
                        (p) => {
                            vm.uploadProgress = Math.floor((p.loaded / p.total) * 100)
                            console.log(vm.progress)
                        }
                )
                vm.uploadProgress = null;

                const [savedDoc] = await this.$newReq(
                        'POST',
                        `tasks/saveDocuments/${this.userInfo.entityID}/${this.userInfo.userID}/${this.task.taskID}/${this.selectedStep.stepID}`,
                        [uploadResult]
                )

                console.log({savedDoc})
                this.task.structure.documents.push(savedDoc)
                this.value.value_type = 2;
                this.value.value = savedDoc.documentDBID
                this.uploading = false;
            },

            mailDragLocalEventOne(mail) {this.dragData = {type: 'mail', data: mail}},
            attachmentDragLocalEvent(attachment) {this.dragData = {type: 'attachment', data: attachment}},
            taskDocumentDragLocalEvent(document) {this.dragData = {type: 'document', data: document}},

            updateValue(newVal) {
                this.value.value = newVal != null ? newVal.value : null;
                this.value.value_type = newVal != null ? newVal.value_type : null;
            },
            documentUploaded(document) {
                this.value.valueType = 2;
                this.value.value = JSON.stringify(document);
            },
            allowDrop: function (ev) {
                ev.preventDefault();
            },
            drop: function (ev) {
                ev.preventDefault();

                try {
                    let transferData = ev.dataTransfer.getData('text')
                    if (!transferData)
                        throw 'No Data Set'
                    let eventObj = JSON.parse(transferData)
                    if (!eventObj.type)
                        throw 'No Type Set'
                    switch (eventObj.type) {
                        case 'mailAttachment': {
                            this.linkMailAttachmentToStep(eventObj.mail, eventObj.attachment)
                            break;
                        }
                        default :{
                            throw 'No Compatible Type Found'
                        }
                    }

                    console.log(eventObj)

                } catch (e) {
                    if  (this.intToBool(this.value.editable) && this.stepEditable) {

                        switch (this.dragData.type) {

                            case '': {

                                break;
                            }
                            case 'mail': {

                                this.linkMailToStep(this.dragData.data);
                                this.value.value_type = 1;
                                this.value.value = this.dragData.data.mailDBID;
                                break;
                            }
                            case 'attachment': {

                                this.linkMailAttachmentToStep(this.dragData.data.mail, this.dragData.data.attachment)
                                break;
                            }
                            case 'document': {
                                this.value.value_type = 2;
                                this.value.value = this.dragData.data.documentDBID;
                            }
                        }
                    } else {this.showSnack('Warning', 'Field is Read Only', 'Close', 'amber')}
                }
            },


            linkMailToStep(mail) {


                mail.stepID = this.selectedStep.stepID;
                eventBus.$emit('taskMailLinked', mail);

                this.fetch({url: '/tasks/linkMailToStep/' + this.userInfo.entityID + '/' + this.userInfo.userID + '/' + mail.messageID + '/' + this.selectedStep.stepID, method: 'PATCH'})
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e)
                    })
            },
            linkMailAttachmentToStep(mail, attachment) {
                // mail.stepID = this.selectedStep.stepID;
                // eventBus.$emit('taskMailLinked', mail);


                this.$newReq('post', `eml/linkAttToStep/${this.userInfo.entityID}/${this.userInfo.userID}/${this.task.taskID}/${this.selectedStep.stepID}`,
                        {...mail, ...attachment}
                )
                        .then((data) => {
                            this.task.structure.documents.push(data);
                            eventBus.$emit('mailAttchmentLinkedToStep', true)
                            setTimeout(() => {
                                this.value.value_type = 2;
                                this.value.value = data.documentDBID;
                            }, 200)
                        })
                        .catch(e => {
                            console.log(e)
                            this.$snack.networkError()
                        })


                // let mailPath = mail.fk_gmail_group_mailbox_folder == 0 || mail.fk_gmail_group_mailbox_folder == null || mail.fk_gmail_group_mailbox_folder == '' ? mail.fk_import_user + '-' + mail.mailID : mail.fk_gmail_group_mailbox_folder + '-' + mail.mailID
                // this.fetch({url: '/tasks/linkMailAttachmentToStep/' + this.userInfo.entityID + '/' + this.userInfo.userID + '/' + mailPath + '/' + this.selectedStep.stepID + '/' + this.task.taskID, method: 'PATCH', body: JSON.stringify(attachment)})
                //     .then((data) => {
                //         this.task.structure.documents.push(data);
                //         eventBus.$emit('mailAttchmentLinkedToStep', true)
                //         setTimeout(() => {
                //             this.value.value_type = 2;
                //             this.value.value = data.documentDBID;
                //         }, 200)
                //     })
                //     .catch((e) => {
                //         // eslint-disable-next-line
                //         console.log(e)
                //     })
            },


            dropFiles(e) {

                this.showUploading = true;
                this.files = [];
                for (let i = 0; i < e.dataTransfer.files.length; i++) {
                    this.files.push(e.dataTransfer.files[i]);
                }
                if (this.files.length >= 0) {
                    this.startRun();
                } else {
                    this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
                    this.showUploading = false;
                }
            },

            startRun() {
                function checkFileType(mimeList, file) {
                    let list = mimeList
                            .split(',')
                            .map(str => {try {return str.trim().toLowerCase()} catch (e) {return ''}})
                    let typeList = list
                            .filter(str => str && str.substr(0,1) !== '.')
                    let extList = list
                            .filter(str => str && str.substr(0,1) === '.')
                            .map(str => {
                                try {
                                    return str.substring(1)
                                } catch (e) {
                                    return ''
                                }
                            })
                    let ext = file.name ? file.name.split('.').pop().toLowerCase() : ''
                    let type = file.type ? file.type.toLowerCase().trim() : ''
                    return typeList.includes(type) || extList.includes(ext)
                }

                if (((this.files[0].size / 1024) / 1024) < 50) {
                    if (checkFileType(this.userInfo.entityInfo.mimeTypeList, this.files[0])) {
                        this.startUpload()
                    } else {
                        this.showSnack('Info', 'Invalid File Type', 'Close', 'primary');
                        this.showUploading = false;
                    }
                } else {
                    this.showSnack('Info', 'File larger that 25 Mb', 'Close', 'primary');
                    this.showUploading = false;
                }
            },


            startUpload() {
                let h = new Headers();
                h.append('tl-token', tokenMan.token());
                let fd = new FormData();
                let myFile = this.files[0];
                fd.append('document', myFile);
                let req = new Request(`${this.serverAddress}/upload/taskDocument/`, {
                    method: 'POST',
                    headers: h,
                    mode: 'cors',
                    body: fd
                });

                fetch(req)
                    .then((response)=> {if (response.ok == true) {return response.text();} else {throw new Error('Bad Stuff')}})
                    .then((jsonData) => {
                        this.saveDocuments(JSON.parse(jsonData))
                        this.showUploading = false;
                    })
                    .catch((err) => {
                        //eslint-disable-next-line
                        console.log('ERROR: ' + err.message);
                        this.showSnack('Info', 'Network error. Please try again.', 'Close', 'primary')
                    });
            },

            save() {
                if (!this.fieldValue) {
                    this.saveDocuments()
                } else {
                    this.saveFieldDocuments()
                }
            },


            saveDocuments(dropDocument) {
                this.fetch(
                    {
                        url: '/tasks/saveDocuments/' + this.userInfo.entityID + '/' + this.userInfo.userID + '/' + this.task.taskID + '/' + this.selectedStep.stepID,
                        method: 'POST',
                        body: JSON.stringify([JSON.stringify(dropDocument)])
                    }
                )
                    .then(data => {
                        data.forEach(document => {
                            this.task.structure.documents.push(document)
                            this.value.value_type = 2;
                            this.value.value = document.documentDBID;
                            this.showUploading = false;
                        });
                    })
                    .catch(err => {
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
                        console.log(err);
                        this.showUploading = false;
                    })
            },
        },
        computed: {
            computedObj() {

                if (this.value.value_type == 1) {
                    if (this.value.value != null && this.value.value != '') {
                        let tMail = null;
                        try {
                            tMail = this.mails.concat(this.binder.dsMails).filter(mail => mail.mailDBID == this.value.value).pop();
                        } catch (e) {
                            console.log(e)
                        }
                        return tMail
                    } else {

                        return null
                    }
                } else {
                    if (this.value.value != null && this.value.value != '') {
                        return this.documents.concat(this.binder.dsDocuments).find(v => v.documentDBID == this.value.value)
                    } else {
                        return null
                    }
                }
            },

            fieldSetSelectField() {
                try {
                    return this.binder.datasetSelectFieldsRef[this.lookupFieldID]
                } catch (e) {
                    console.log(e)
                    return null
                }
            },

            blockInput() {
                return !!(this.value.linkLookup
                        && this.value.linkLookup.fieldSetSelectField
                        && !this.fieldSetSelectField.value);
            },

            lookupFieldID() {
                return this.value.linkLookup && this.value.linkLookup.fieldSetSelectField ? this.value.linkLookup.fieldSetSelectField : null
            },

            lookupFieldMemberID() {
                if (this.lookupFieldID)
                    return this.fieldSetSelectField
                            ? this.fieldSetSelectField.value
                            : null
                else return null
            },


            showLoading() {
                if (this.lookupFieldID && this.lookupFieldMemberID) {
                    return this.binder.loadingDatasets.includes(Number(this.lookupFieldMemberID))
                } else {
                    return false
                }
            },
        }
    }
</script>

<style scoped>

</style>
