<template>
    <v-card class="dashboardTransfer" flat hover>
        <v-layout align-center justify-space-between row wrap >
            <v-flex shrink class="px-1">
                <span class="caption font-weight-bold">Task Transfer</span>
            </v-flex>
            <v-flex shrink class="px-1">
                <div style="font-size: 0.8em; width: 100%" class="font-weight-regular text-xs-right">
                    {{ getLongDate(transfer.created) }}
                </div>
            </v-flex>
        </v-layout>

        <v-layout row align-center justify-space-between style="overflow-x: hidden; overflow-y: auto">
            <v-flex xs12 class="pl-2 text-truncate" style="overflow-x: hidden; overflow-y: auto">
                <span class="caption">From:<strong>{{' ' + transfer.fromFullName }}</strong></span>
            </v-flex>
            <v-flex xs12 class="pl-1 text-truncate" style="overflow-x: hidden; overflow-y: auto">
                <span class="caption">By:<strong>{{ ' ' + transfer.byFullName }}</strong></span>
            </v-flex>

            <v-flex shrink class="pa-1">
                <v-btn
                        flat
                        icon
                        class="pa-0 ma-0"
                        @click="removeTransferNotification"
                        color="secondary"
                        small
                        :loading="responsePending"
                        :disabled="responsePending || readOnlyDash"
                >
                    <v-icon small>close</v-icon>
                </v-btn>
            </v-flex>
        </v-layout>
    </v-card>
</template>

<script>

    import {codeBaseMixin} from "../../../codeBaseMixin";

    export default {
        mixins: [ codeBaseMixin ],
        props: {
            userInfo: Object,
            task: Object,
            transfer: Object,
            readOnlyDash: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                responsePending: false
            }
        },
        methods: {
            removeTransferNotification() {
                this.responsePending = true;
                this.fetch({method: 'PATCH', url:`/notifications/removeTransferNotification/${this.userInfo.entityID}/${this.transfer.transferID}`})
                    .then(() => {
                        this.responsePending = false;
                        this.$emit('removed')
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e)
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                        this.responsePending = false;
                    });
            }
        }
    }
</script>

<style scoped>
    .dashboardTransfer {
        border-left: 5px solid var(--v-tRed-base);
    }
</style>
