<template>
    <div>
        <div v-if="displayDoc != null">
            <v-layout row align-center class="pt-1">
                <v-flex xs12>
                    <app-document-container
                            :document="displayDoc"
                            :user-info="userInfo"
                            :srcTaskAttachment="false"
                            :dense="true"
                    />
                </v-flex>
                <v-flex shrink class="pl-2">
                    <v-tooltip top>
                        <v-btn
                                color="primary"
                                icon
                                fab
                                slot="activator"
                                @click="getConfig()"
                                :disabled="!intToBool(value.editable) || !stepEditable"
                                :loading="loadingConfig"
                        >
                            <v-icon>history_edu</v-icon>
                        </v-btn>
                        <span>Generate Document</span>
                    </v-tooltip>
                </v-flex>
            </v-layout>
        </div>
        <div v-if="signFlow">
            <div class="pa-2 text-xs-center" v-if="loadingFlowTemplate">
                <v-progress-circular
                        :size="40"
                        :width="5"
                        color="primary"
                        indeterminate
                />
            </div>
            <template v-else>
                <v-card>
                        <v-layout row align-center class="primary white--text">
                            <v-flex xs12 class="body-1 px-2 py-1">Signature Flow In Progress</v-flex>
                            <v-flex shrink class="pr-2" v-if="valueUser == userInfo.userID">
                                <app-input-dlg
                                        v-if="showCancelSignFlow"
                                        :show-dialog="showCancelSignFlow"
                                        title="Confirm"
                                        body="Are you sure that you would like to cancel the SignFlow process. This action cannot be undone."
                                        :cancel-button="true"
                                        :show-input-box="false"
                                        :buttons="['Cancel SignFlow']"
                                        :call-backs="[ cancelSignFlow ]"
                                        @dismiss="showCancelSignFlow = false"
                                />

                                <v-tooltip top>
                                    <v-btn
                                            icon
                                            flat
                                            small
                                            color="white"
                                            class="pa-0 ma-0"
                                            @click="showCancelSignFlow = true"
                                            slot="activator"
                                            :loading="removing"
                                            :disabled="removing"
                                    >
                                        <v-icon small>close</v-icon>
                                    </v-btn>
                                    <span>Cancel SignFlow</span>
                                </v-tooltip>
                            </v-flex>
                        </v-layout>
                    <div class="px-2">
                        <div v-for="(req, index) in flowTemplate.signRequests" :key="'SignatureRequest' + req.webID">
                            <v-layout row align-center class="py-1">
                                <v-flex shrink>
                                    <v-tooltip top v-if="req.signatureConfirmationStatus === 1">
                                        <v-icon
                                                color="green"
                                                style="cursor: pointer"
                                                slot="activator"
                                        >
                                            done
                                        </v-icon>
                                        <span>Signature Has Been Added</span>
                                    </v-tooltip>
                                    <template v-else>
                                        <v-tooltip top v-if="!blankString(req.OTPError) || !blankString(req.emailError)">
                                            <v-icon
                                                    color="red"
                                                    style="cursor: pointer"
                                                    slot="activator"
                                            >
                                                error
                                            </v-icon>
                                            <span>{{`${!blankString(req.OTPError) ? 'Error Sending OTP' : 'Error Sending Invitation Email'}, Please Resend Invite`}}</span>
                                        </v-tooltip>
                                        <v-tooltip top v-else>
                                            <v-icon
                                                    :color="index === flowTemplate.curSignIndex ? 'blue' : 'grey'"
                                                    style="cursor: pointer"
                                                    slot="activator"
                                            >
                                                {{index === flowTemplate.curSignIndex ? 'timer' : 'hourglass_empty'}}
                                            </v-icon>
                                            <span>
                                                <template v-if="index === flowTemplate.curSignIndex">
                                                    {{req.linkActivated === 0 ? `Invitation link sent${req.emailSendDate == null ? '' : `: ${req.emailSendDate}`}` : `Link has been opened on: ${req.linkActivatedDate}, But not signed`}}
                                                </template>
                                                <template v-else>
                                                    Signature Invite Pending
                                                </template>
                                            </span>
                                        </v-tooltip>
                                    </template>
                                </v-flex>
                                <v-flex xs12 class="pl-2 caption">
                                    <div class="font-weight-bold">
                                        {{req.emailAddress}}
                                    </div>

                                    <div class="greyType--text">
                                        {{req.OTPNumber}}
                                    </div>
                                </v-flex>
                                <v-flex shrink class="pl-2" v-if="index === flowTemplate.curSignIndex">
                                    <v-btn :disabled="sendingInvite" :loading="sendingInvite" small color="secondary" class="px-2 py-0 ma-0" @click="resendInvite(req)">
                                        Resend Invite
                                    </v-btn>
                                </v-flex>

                            </v-layout>

                        </div>
                    </div>
                </v-card>
            </template>
        </div>

        <div v-if="showGenerateBtn && displayDoc === null" class="text-xs-center">
            <v-btn
                    color="primary"
                    small
                    @click="getConfig()"
                    :disabled="!intToBool(value.editable) || !stepEditable"
                    :loading="loadingConfig"
            >
                <v-icon left>history_edu</v-icon>
                Generate Document
            </v-btn>
        </div>
        <div v-if="showGenerate">
            <document-view
                    @dismiss="showGenerate = false"
                    :user-info="userInfo"
                    :steps="steps"
                    :step="step"
                    :fields="fields"
                    v-model="value"
                    :config="docConfig"
                    :show-dialog="showGenerate"
                    :task="task"
                    :field-sets="fieldSets"
                    @docAdded="inputRun($event)"
                    @setFlowTemplate="flowTemplate = $event"
                    :mail-config="mailConfig"
            />
        </div>
<!--        {{value.value}}-->
    </div>

</template>

<script>
    import {codeBaseMixin} from "../../../../../../../codeBaseMixin";
    import DocumentView from "./DocumentView";
    import DocumentContainer from "../../../../Documents/DocumentContainer";
    import {blankString} from "../../../../../../../codeFunctions";
    import InputDlg from "../../../../../../../components/General/InputDlg";


    export default {
        components: {appInputDlg : InputDlg, appDocumentContainer: DocumentContainer, DocumentView},
        mixins: [codeBaseMixin],
        props: {
            value: Object,
            showDialog: Boolean,
            userInfo: Object,
            fields: Array,
            steps: Array,
            fieldSets: Array,
            task: Object,
            step: Object,
            field: Object,
            stepEditable: Boolean,
            mailConfig: Object,
        },
        data() {
            return {
                docConfig: null,
                loadingConfig: false,
                showGenerate: false,
                flowTemplate: null,
                loadingFlowTemplate: false,
                flowLoadCount: 0,
                sendingInvite: false,
                removing: false,
                showCancelSignFlow: false
            }
        },
        methods: {
            inputRun(val) {
                this.value.value = JSON.stringify(val);
                this.showGenerate = false;
                this.$emit('saveFields', true)
            },

            getConfig() {
                this.loadingConfig = true;
                this.fetch({method: 'GET', url:`/dtManage/getTemplate/${this.userInfo.entityID}/${this.value.lookup}`})
                    .then((data) => {
                        // console.log(JSON.parse(data))
                        this.docConfig = data;
                        this.showGenerate = true;
                        this.loadingConfig = false;
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e)
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
                        this.loadingConfig = false;
                        this.showDialog = false;
                    })
            },

            getFlow(flowID) {
                this.flowLoadCount = ++this.flowLoadCount;
                this.loadingFlowTemplate = true;
                this.fetch({method: 'GET', url:`/dtManage/template/signatureFlow/${this.userInfo.entityID}/${flowID}`})
                    .then((data) => {
                        this.flowTemplate = data;
                        this.loadingFlowTemplate = false;
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e)
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
                        this.loadingFlowTemplate = false;
                    })
            },

            cancelSignFlow() {
                this.showCancelSignFlow = false;
                this.removing = true;
                this.fetch({method: 'DELETE', url:`/dtManage/template/cancel/${this.userInfo.entityID}/${this.userInfo.userID}/${this.flowTemplate.id}`})
                    .then(() => {
                        this.removing = false;
                        this.showSnack('Info', `SignFlow Process Canceled`, 'Close', 'primary')
                        this.value.value = JSON.stringify({genComplete: false, canceled: true});
                        this.$emit('saveFields', true)
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e);
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
                        this.removing = false;
                    })
            },

            resendInvite(req) {
                this.sendingInvite = true;
                this.fetch({method: 'GET', url:`/dtManage/template/sendInvite/${this.userInfo.entityID}/${this.flowTemplate.id}`})
                    .then((data) => {
                        this.sendingInvite = false;
                        req.emailSendDate = data;
                        this.showSnack('Info', `Invite sent to: ${req.emailAddress}`, 'Close', 'primary')
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e)
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
                        this.sendingInvite = false;
                    })
            }
        },
        computed: {

            valueUser() {
                try {
                    return this.value.valueUserID
                } catch (e) {
                    return 0
                }
            },

            signFlow() {
                try {
                    let tVal = JSON.parse(this.value.value);
                    if (typeof tVal.genComplete != 'undefined' && !tVal.genComplete && typeof tVal.flowID != 'undefined' && this.flowLoadCount <= 10) {
                        if (!this.loadingFlowTemplate && this.flowTemplate == null) {
                            this.getFlow(tVal.flowID);
                        }
                        return true
                    } else {
                        return false
                    }
                } catch (e) {
                    return false
                }
            },

            showGenerateBtn() {
                if (this.value.value == null) {
                    return true
                } else {
                    try {
                        let tVal = JSON.parse(this.value.value)
                        return typeof tVal.genComplete != 'undefined' && (tVal.genComplete || (typeof tVal.canceled != 'undefined' && tVal.canceled));
                    } catch (e) {
                        // eslint-disable-next-line
                        console.log(e);
                        return null
                    }
                }
            },

            displayDoc() {
                try {
                    let tVal = JSON.parse(this.value.value);
                    return typeof tVal.genComplete != 'undefined' && tVal.genComplete ? this.task.structure.documents.filter(obj => obj.documentDBID == tVal.docID).pop() : null;
                } catch (e) {
                    return null
                }
            }
        },
    }
</script>

<style scoped>

</style>
