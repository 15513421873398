<template>
    <v-dialog fill-height v-model="value" persistent fullscreen lazy>
        <v-card class="fill-height" flat style="overflow-y: auto">
            <v-layout column fill-height style="overflow-y: auto">
                <v-flex shrink>
                    <v-card-title class="py-2 pr-2 title-color">
                        <img src="../../../public/signatures_white.svg" width="42px" class="pr-2"/>
                        <span class="title font-weight-light white--text">Signature Manager</span>
                        <v-spacer></v-spacer>
                        <v-btn flat icon color="white" class="pa-0 ma-0" @click="showInfo = true">
                            <v-icon>info</v-icon>
                        </v-btn>
                        <app-input-dlg
                                :show-dialog="showInfo"
                                title="Extra Variables"
                                :cancel-button="true"
                                :show-input-box="false"
                                @dismiss="showInfo = false"
                                :body-padding="false"
                        >
                            <template slot="body">

                                <div class="pa-2">
                                    <v-layout row align-center justify-space-between>
                                        <v-flex shrink style="white-space: nowrap">Name</v-flex>
                                        <v-flex shrink style="white-space: nowrap">$name$</v-flex>
                                    </v-layout>
                                    <v-layout row align-center justify-space-between>
                                        <v-flex shrink style="white-space: nowrap">Surname</v-flex>
                                        <v-flex shrink style="white-space: nowrap">$surname$</v-flex>
                                    </v-layout>
                                    <v-layout row align-center justify-space-between>
                                        <v-flex shrink style="white-space: nowrap">Job Title</v-flex>
                                        <v-flex shrink style="white-space: nowrap">$jobTitle$</v-flex>
                                    </v-layout>
                                    <v-layout row align-center justify-space-between>
                                        <v-flex shrink style="white-space: nowrap">Tel</v-flex>
                                        <v-flex shrink style="white-space: nowrap">$tel$</v-flex>
                                    </v-layout>
                                    <v-layout row align-center justify-space-between>
                                        <v-flex shrink style="white-space: nowrap">Fax</v-flex>
                                        <v-flex shrink style="white-space: nowrap">$fax$</v-flex>
                                    </v-layout>
                                    <v-layout row align-center justify-space-between>
                                        <v-flex shrink style="white-space: nowrap">Cell</v-flex>
                                        <v-flex shrink style="white-space: nowrap">$cell$</v-flex>
                                    </v-layout>
                                    <v-layout row align-center justify-space-between>
                                        <v-flex shrink style="white-space: nowrap">Email</v-flex>
                                        <v-flex shrink style="white-space: nowrap">$email$</v-flex>
                                    </v-layout>
                                    <v-layout row align-center justify-space-between>
                                        <v-flex shrink style="white-space: nowrap">Department</v-flex>
                                        <v-flex shrink style="white-space: nowrap">$department$</v-flex>
                                    </v-layout>
                                    <v-layout row align-center justify-space-between>
                                        <v-flex shrink style="white-space: nowrap">Website Title</v-flex>
                                        <v-flex shrink style="white-space: nowrap">$websiteTitle$</v-flex>
                                    </v-layout>
                                    <v-layout row align-center justify-space-between>
                                        <v-flex shrink style="white-space: nowrap">Link-1</v-flex>
                                        <v-flex shrink style="white-space: nowrap">$link-1$</v-flex>
                                    </v-layout>
                                    <v-layout row align-center justify-space-between>
                                        <v-flex shrink style="white-space: nowrap">Link-2</v-flex>
                                        <v-flex shrink style="white-space: nowrap">$link-2$</v-flex>
                                    </v-layout>
                                    <v-layout row align-center justify-space-between>
                                        <v-flex shrink style="white-space: nowrap">Link-3</v-flex>
                                        <v-flex shrink style="white-space: nowrap">$link-3$</v-flex>
                                    </v-layout>
                                    <v-layout row align-center justify-space-between>
                                        <v-flex shrink style="white-space: nowrap">Link-4</v-flex>
                                        <v-flex shrink style="white-space: nowrap">$link-4$</v-flex>
                                    </v-layout>
                                    <v-layout row align-center justify-space-between>
                                        <v-flex shrink style="white-space: nowrap">Link-5</v-flex>
                                        <v-flex shrink style="white-space: nowrap">$link-5$</v-flex>
                                    </v-layout>
                                    <v-layout row align-center justify-space-between>
                                        <v-flex shrink style="white-space: nowrap">Link-6</v-flex>
                                        <v-flex shrink style="white-space: nowrap">$link-6$</v-flex>
                                    </v-layout>
                                    <v-layout row align-center justify-space-between>
                                        <v-flex shrink style="white-space: nowrap">Link-7</v-flex>
                                        <v-flex shrink style="white-space: nowrap">$link-7$</v-flex>
                                    </v-layout>
                                    <v-layout row align-center justify-space-between>
                                        <v-flex shrink style="white-space: nowrap">Link-8</v-flex>
                                        <v-flex shrink style="white-space: nowrap">$link-8$</v-flex>
                                    </v-layout>
                                    <v-layout row align-center justify-space-between>
                                        <v-flex shrink style="white-space: nowrap">Link-9</v-flex>
                                        <v-flex shrink style="white-space: nowrap">$link-9$</v-flex>
                                    </v-layout>
                                    <v-layout row align-center justify-space-between>
                                        <v-flex shrink style="white-space: nowrap">Link-10</v-flex>
                                        <v-flex shrink style="white-space: nowrap">$link-10$</v-flex>
                                    </v-layout>
                                </div>
                            </template>
                        </app-input-dlg>
                        <v-btn flat icon color="white" class="pa-0 ma-0" @click="$emit('input', false)">
                            <v-icon>close</v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-divider></v-divider>
                </v-flex>
                <v-flex xs12 class="scrollable page-back">
                    <div class="pa-2 text-xs-center" v-if="startingUp">
                        <v-progress-circular
                                :size="40"
                                :width="5"
                                color="primary"
                                indeterminate
                        />
                    </div>
                    <template v-else>
                        <v-layout row fill-height class="scrollable">
                            <v-flex xs3 class="scrollable pa-1">
                                <sig-select-bar
                                        :user-info="userInfo"
                                        :signatures="signatures"
                                        :selected-sig="selectedSig"
                                        @selectSignature="selectedSig = $event"
                                />
                            </v-flex>
                            <v-flex xs9 fill-height class="scrollable" v-if="selectedSig != null">

                                <v-layout column fill-height class="scrollable">
                                    <v-flex xs12 class="pr-1 pt-1 scrollable">
                                        <sig-users
                                                :user-info="userInfo"
                                                :signatures="signatures"
                                                :selected-sig="selectedSig"
                                                :users="users"
                                        />
                                    </v-flex>
                                    <v-flex shrink class="pt-1 pr-1 pb-1">
                                        <v-card class="fill-height" style="overflow-y: auto;">
                                            <v-layout column fill-height class="scrollable" style="overflow-x: hidden">
<!--Edit Title-->
                                                <v-flex shrink @click="hideHtml = !hideHtml" style="cursor: pointer">
                                                    <v-card-title class="white--text pa-0 ma-0 title-color">
                                                        <v-layout row align-center>
                                                            <v-flex xs12 class="pa-2">Edit Signature</v-flex>
                                                            <v-flex shrink class="pr-2" style="white-space: nowrap"><span class="caption">(Click to Show/Hide)</span></v-flex>
                                                            <v-flex shrink class="pr-2">
                                                                <v-btn
                                                                        icon
                                                                        flat
                                                                        small
                                                                        color="white"
                                                                        class="pa-0 ma-0"
                                                                >
                                                                    <v-icon>{{hideHtml ? 'arrow_drop_up' : 'arrow_drop_down'}}</v-icon>
                                                                </v-btn>
                                                            </v-flex>
                                                        </v-layout>
                                                    </v-card-title>
                                                </v-flex>
<!--Edit View-->
                                                <v-flex v-if="!hideHtml" xs12 class="scrollable pa-1" style="cursor: pointer; max-height: 500px" @click="showEditHtml = true">
                                                    <div v-if="!blankString(selectedSig.html)" v-html="selectedSig.html"></div>
                                                    <div v-else>Click To Edit</div>

                                                    <sig-editor
                                                            v-if="showEditHtml"
                                                            :user-info="userInfo"
                                                            :selected-sig="selectedSig"
                                                            v-model="showEditHtml"
                                                    />
                                                </v-flex>
                                            </v-layout>
                                        </v-card>
                                    </v-flex>
                                </v-layout>
                            </v-flex>
                        </v-layout>
                    </template>
                </v-flex>
            </v-layout>
        </v-card>
    </v-dialog>
</template>

<script>
    import SigSelectBar from "./Components/SigSelectBar";
    import {codeBaseMixin} from "../../codeBaseMixin";
    import SigUsers from "./Components/sigUsers";
    import SigEditor from "./Components/SigEditor";
    import InputDlg from "../../components/General/InputDlg";

    export default {
        mixins: [codeBaseMixin],
        components: {appInputDlg: InputDlg, SigEditor, SigUsers, SigSelectBar},
        props: {
            value: {
                type: Boolean,
                default: false
            },
            userInfo: Object,
            localData: Object
        },
        data() {
            return {
                signatures: [],
                users: [],
                startingUp: false,
                selectedSig: null,
                hideHtml: false,
                showEditHtml: false,
                showInfo: false
            }
        },
        watch: {
            value: {
                immediate: true,
                handler(val) {
                    if (val) {
                        this.startup();
                    }
                }
            }
        },
        methods: {
            startup() {
                this.startingUp = true;
                this.fetch({method: 'GET', url:`/sigMan/allSignatures/${this.userInfo.entityID}`})
                    .then((data) => {
                        this.signatures = data.signatures;
                        this.users = data.users;
                        this.startingUp = false;
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e)
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                        this.startingUp = false;
                    })
            }
        },
    }
</script>

<style scoped>

</style>
