<template>
    <v-layout row>
        <v-flex class="pl-1" shrink>
            <v-hover>
                <v-layout row slot-scope="{ hover }">
                    <v-flex class="pl-1" shrink v-if="!progress && !indeterminate && hover && ['application/pdf', 'message/rfc822'].includes(attachment.attMimeType)" >
                        <v-tooltip top>
                            <v-icon
                                    style="cursor: pointer"
                                    small
                                    slot="activator"
                                    color="primary"
                                    class="ma-0"
                                    @click="$emit('previewAttachment')"
                            >
                                visibility
                            </v-icon>
                            <span>View Attachment</span>
                        </v-tooltip>
                    </v-flex>
<!--                    <v-flex class="pl-1" shrink v-show="!blankString(attachment.attName) && hover && ['PDF', 'pdf'].includes(attachment.attName.split('.').pop())">-->
<!--                        <doc-view-->
<!--                                :url="`${baseServerAddress}mailstore/${userInfo.entityID}/${mailPath}/${attachment.attName}`"-->
<!--                        >-->
<!--                            <v-tooltip top>-->
<!--                                <v-icon-->
<!--                                        style="cursor: pointer"-->
<!--                                        small-->
<!--                                        slot="activator"-->
<!--                                        color="primary"-->
<!--                                        class="ma-0"-->
<!--                                >-->
<!--                                    -->
<!--                                </v-icon>-->
<!--                                <span></span>-->
<!--                            </v-tooltip>-->
<!--                        </doc-view>-->
<!--                    </v-flex>-->
                    <v-flex class="pl-1" shrink v-if="progress || indeterminate">
                        <v-progress-circular
                                :size="12"
                                :width="2"
                                color="primary"
                                :indeterminate="indeterminate"
                                :value="progress"
                        />
                    </v-flex>
                    <v-flex class="pl-1" shrink v-if="!progress && !indeterminate">
                        <v-tooltip top>
                            <v-icon
                                    style="cursor: pointer"
                                    small
                                    slot="activator"
                                    color="primary"
                                    class="ma-0"
                                    @click="$emit('downloadAttachment')"
                            >
                                {{ hover ? 'cloud_download' : 'attachment'}}
                            </v-icon>
                            <span>Download Attachment</span>
                        </v-tooltip>
                    </v-flex>
                </v-layout>
            </v-hover>
        </v-flex>
        <v-flex class="px-2" xs12 style="overflow-x: hidden">
                                        <span
                                                class="caption text-truncate"
                                        >
                                            {{ attachment.attName || attachment.filename }}
                                        </span>
        </v-flex>
    </v-layout>
</template>

<script>
export default {
    props: {
        attachment: Object,
    },
    data() {
        return {
            progress: 0,
            indeterminate: false
        }
    },
    methods: {

    },
}
</script>

<style scoped>

</style>