<template>
    <div>
        <app-input-dlg
            :show-dialog="showDlg"
            title="Extra Variables"
            :cancel-button="true"
            :show-input-box="false"
            @dismiss="showDlg = false"
            :body-padding="false"
        >
            <template #body>
                LoadingURL: {{loadingUrl}}
                <v-text-field
                    label="URL"
                    v-model="url"
                ></v-text-field>

                <a :href="url" target="_blank">
                    <v-btn>GO</v-btn>
                </a>
            </template>


        </app-input-dlg>
        <v-btn @click="showDlg = true">OPEN</v-btn>
    </div>
</template>

<script>

import appInputDlg from "@/components/General/InputDlg.vue";

export default {
    props: {
        propName: {
            type: Number,
            default: 0
        },
    },
    components: {appInputDlg},
    data() {
        return {
            loadingUrl: false,
            showDlg: false,
            url: ""
        }
    },
    watch: {
        showDlg(val) {
            if (val)
                this.getUrl()

        },
    },
    methods: {
        async getUrl() {
            try {
                this.loadingUrl = true
                this.url = await this.$newReq('GET', 'oSmtp/url')
            } catch (e) {
                console.log(e)
                this.$snack.networkError()
            } finally {
                this.loadingUrl = false
            }
        }
    },
}

</script>

<style scoped>

</style>