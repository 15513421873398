<template>
    <v-layout row fill-height style="overflow-x: hidden; background: var(--page-back)">
        <!--Task View V2-->
        <v-flex xs12>
            <app-task-view2
                    style="overflow-y: auto; background: var(--page-back)"
                    class="scroll"
                    v-if="!loadingTemplate && selectedTask != null"
                    :task="selectedTask"
                    @starTask="starTask"
                    :userInfo="userInfo"
                    :folder-groups="folderGroups"
                    :users="taskData.users"
                    :selectLists="selectLists"
                    @showMails="showTaskMailBand = true"
                    :show-mail-band="showTaskMailBand"
                    :cur-view="curView"
                    :mail-config="mailConfig"
                    :cur-time="curTime"
                    :cur-time-minute="curTimeMinute"
                    @updateTask="$emit('updateTask', $event)"
                    @savePrompt="savePrompt = $event"
                    :save-prompt="savePrompt"
                    :local-data="localData"
                    :show-sync="showSync"
                    :get-task-header="getTaskHeader"
                    @hiddenStepsUpdated="hiddenSteps = $event"
                    @refreshTask="getTaskTemplate()"
            />
        </v-flex>
    </v-layout>
</template>

<script>
// import FilterBand from './FilterBand/FilterBand';


import { eventBus } from '../main';

import { codeBaseMixin } from '../codeBaseMixin';
import TaskView2 from './TaskView V2/TaskView';
import {mapGetters, mapActions} from "vuex";

export default {
    mixins: [codeBaseMixin],
    components: {
        appTaskView2: TaskView2,
    },

    props: {
        mailConfig: Object,
        curView: String,
        userInfo: Object,
        taskData: {
            type: Object,
            default: () => {
                return {
                    tasks: [],
                    taskLoading: true,
                    filterSettings: {
                        userOption: 0,
                        stateOption: 0,
                        orderOption: 1,
                        groupOption: 0,
                        starOption: 0
                    },
                    users: []
                }
            }
        },
        getTaskHeader: Function,
        folderGroups: Array,
        selectLists: {
            type: Array,
            default: () => {
                return []
            }
        },
        curTime: {
            type: Date,
            default: null
        },
        curTimeMinute: {
            type: Date,
            default: null
        },
        localData: Object,
        showSync: Boolean,
        taskList: Array,
    },
    data() {
        return {
            selectedTaskID: 0,
            refStructureRunning: false,
            showTaskMailBand: false,
            loadingTemplate: false,
            savePrompt: false,
            hiddenSteps: null
        }
    },
    created() {
        eventBus.$on('emitUserInfo', this.emitUserInfo);
        eventBus.$on('taskSelected', this.taskSelected);
        eventBus.$on('refreshTaskFieldSetInfo', this.refreshTaskFieldSetInfo)
        eventBus.$on('gotoTask', this.gotoTaskLocal);
        eventBus.$on('taskCreated', this.taskCreated);
    },

    beforeDestroy() {
        eventBus.$off('emitUserInfo', this.emitUserInfo);
        eventBus.$off('taskSelected', this.taskSelected);
        eventBus.$off('refreshTaskFieldSetInfo', this.refreshTaskFieldSetInfo)
        eventBus.$off('gotoTask', this.gotoTaskLocal);
        eventBus.$off('taskCreated', this.taskCreated);
    },

    methods: {
        gotoTaskLocal(task) {eventBus.$emit('navEvent', 'tasks');this.taskSelected(task.taskID);},
        taskCreated(task) {eventBus.$emit('navEvent', 'tasks');this.taskSelected(task.taskID);},
        ...mapActions('cal',['upsert']),
        emitUserInfo() {
            if (typeof this.userInfo.userID != 'undefined') {
                eventBus.$emit('userInfoEmitted', this.userInfo)
            } else {
                setTimeout(() => {
                    this.emitUserInfo();
                }, 200)
            }
        },
        taskSelected(taskID = 0) {
            this.selectedTaskID = taskID;
            this.$nextTick(() => {

                if (this.selectedTask == null) {

                    let tInterval = setInterval(() => {


                        if (this.selectedTaskID != null) {

                            clearInterval(tInterval);
                            this.nextTaskOpenSteps();
                        }
                    }, 100)
                } else {
                    this.nextTaskOpenSteps()
                }
            })
        },

        nextTaskOpenSteps() {
            if (typeof this.selectedTask.structure == 'undefined') {
                this.$set(this.selectedTask, `structure`, {});
                this.showSnack('Error', 'No Structure Warning', 'Close', 'red')
            }

            eventBus.$emit('taskLoadingStructure', true);
            this.getTaskTemplate()
        },

        getTaskTemplate() {
            console.log('Get Template')
            this.fetch({method: 'GET', url:`/workflows/getWorkflowTemplate/${this.userInfo.entityID}/${this.selectedTask.workflowID}/${this.selectedTask.taskID}/${this.userInfo.userID}`})
                    .then((data) => {

                        if (data.events && data.events.length) {
                            this.upsert({
                                events: data.events
                            })
                        }
                        let eventIDs = data.events.map(obj => {return obj.id})
                        data.events = this.calendarEvents.filter(event => eventIDs.includes(event.id))

                        this.selectedTask.structure = data;
                        this.loadingTemplate = false;

                        eventBus.$emit('taskLoadingStructure', false);
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e)
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                    });
        },
        refreshTaskFieldSetInfo() {
            this.fetch({method: 'GET', url:`/workflows/getWorkflowTemplateFieldSetRefresh/${this.userInfo.entityID}/${this.selectedTask.taskID}/${this.userInfo.userID}`})
                    .then((data) => {
                        this.selectedTask.structure.fieldSets = data.fieldSets;
                        this.selectedTask.structure.fieldSetRules = data.fieldSetRules;
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e)
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                    });
        },
        starTask(value){
            this.taskData.tasks.forEach((task) => {if (task.taskID == value.taskID) task.starredID = value.value ? 0 : null;});
        },
    },

    computed: {
        ...mapGetters('cal', ['calendarEvents']),
        selectedTask: {
            get() {
                try {
                    let tTask = this.taskList.reduce((task, item) => {
                        if (task == null) {
                            if (item.taskID == this.selectedTaskID) {
                                task = item
                            }
                        }
                        return task
                    }, null)

                    if (typeof tTask.structure === 'undefined') {
                        this.$set(tTask, `structure`, {
                            steps : [],
                            mails : [],
                            fields : [],
                            draftMails : [],
                            draftNotes : [],
                        });
                    }
                    return tTask
                } catch (e) {
                    // eslint-disable-next-line
                    return null
                }
            },
            set(value) {
                try {
                    this.selectedTaskID = value.taskID
                } catch (e) {
                    // eslint-disable-next-line
                    this.selectedTaskID = 0
                }
            }
        },
        securedSteps() {
            try {
                return this.selectedTask != null && typeof this.selectedTask.structure != 'undefined' ? this.selectedTask.structure.steps.reduce((list, item) => {
                    if (item.shown === 0) {
                        list.push(item.stepID)
                    }
                    return list
                }, []) : []
            } catch (e) {
                // eslint-disable-next-line
                console.log(e)
                return []
            }
        }
    },
}
</script>

<style scoped>

</style>
