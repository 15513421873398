<template>
    <div class="fill-height">
        <froala class="editor" ref="editorHere" tag="textarea" :config="config" :value="value" @input="$emit('input', $event)" style="width: 100%"></froala>
    </div>
</template>

<script>

    export default {
        props: {
            config: Object,
            value: String
        },
        data() {
            return {
                showDlg: false
            }
        },
    }
</script>

<style scoped>

</style>
