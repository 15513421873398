<template>
    <div class="scrollable fill-height">
        <v-layout row class="scrollable" fill-height>
            <v-flex xs5 class="scrollable" fill-height>
                <v-layout column fill-height class="scrollable">
<!--Add New Field-->
                    <v-flex class="pa-2" shrink>
                        <v-btn
                                small
                                outline
                                class="ma-0"
                                color="secondary"
                                block
                                @click="showAddField = true"
                        >
                            <v-icon left>add</v-icon>
                            Add Field
                        </v-btn>

                        <app-input-dlg
                                v-if="showAddField"
                                :show-dialog="showAddField"
                                title="Add Dataset Field"
                                :cancel-button="true"
                                :show-input-box="false"
                                @dismiss="showAddField = false"
                                :body-padding="false"
                        >
                            <template slot="body">
                                <div>
                                    <app-configure-data-set-field
                                            :value="{fk_field_set: dataSet.id}"
                                            :create-field="true"
                                            :user-info="userInfo"
                                            :user-groups="userGroups"
                                            :select-lists="selectLists"
                                            :save-fields="saveFields"
                                            @startSave="saveFields.push($event)"
                                            @patch="saveChanges($event)"
                                            @saveFailed="saveFailed($event)"
                                    />
                                </div>
                            </template>
                            <template slot="actions">

                            </template>
                        </app-input-dlg>

                    </v-flex>
                    <v-flex shrink>
                        <v-divider/>
                    </v-flex>
<!--Field Set Fields-->
                    <v-flex class="scrollable" >
                        <draggable
                                :list="dataSetFields"
                                group="dataSetFields"
                                @start="drag=true"
                                @end="drag=false"
                                @change="log"
                                ghostClass="ghost"
                                v-bind="{handle:'.handle'}"
                        >
                            <app-data-set-field
                                    v-for="field in dataSetFields"
                                    :key="field.id"
                                    :field="field"
                                    :user-info="userInfo"
                                    :select-lists="selectLists"
                                    :user-groups="userGroups"
                                    :selected-field="selectedField"
                                    @selectField="selectedField = $event"
                                    :save-fields="saveFields"
                                    :all-fields="dataSetFields"
                            />
                        </draggable>

                    </v-flex>
                </v-layout>
            </v-flex>
            <v-flex shrink>
                <v-divider :vertical="true"></v-divider>
            </v-flex>
            <v-flex xs7 v-if="selectedField != null">
                <div class="fill-height pa-2 scrollable" v-if="selectedField != null">
                    <v-card style="overflow-y: auto;">
                        <v-layout column class="scrollable">
<!--Title-->
                            <v-flex shrink>
                                <v-card-title class="white--text pa-0 ma-0 title-color">
                                    <div class="pa-2">{{selectedField.description}}</div>
                                </v-card-title>
                            </v-flex>
<!--Configure Component-->
                            <v-flex shrink>
                                <app-configure-data-set-field
                                        v-model="selectedField"
                                        :user-info="userInfo"
                                        :user-groups="userGroups"
                                        :select-lists="selectLists"
                                        :save-fields="saveFields"
                                        @startSave="saveFields.push($event)"
                                        @patch="saveChanges($event)"
                                        @saveFailed="saveFailed($event)"
                                        @addedFieldToGroup="updateFieldGroup($event)"
                                        :all-fields="dataSetFields"
                                        @addedFieldRemovedFromGroup="removeFieldGrouping($event)"
                                />
                            </v-flex>
                        </v-layout>
                    </v-card>
                </div>
            </v-flex>
        </v-layout>
    </div>
</template>

<script>
    import {codeBaseMixin} from "../../../codeBaseMixin";
    import draggable from 'vuedraggable';
    import DataSetField from "./DataSetField";
    import ConfigureDataSetField from "./ConfigureDataSetField";
    import InputDlg from "../../../components/General/InputDlg";

    export default {
        mixins: [codeBaseMixin],
        components: {
            appInputDlg: InputDlg,
            appConfigureDataSetField: ConfigureDataSetField,
            appDataSetField: DataSetField,
            draggable,
        },
        props: {
            userInfo: Object,
            dataSet: Object,
            userGroups: Array,
            selectLists: Array,
        },
        data() {
            return {
                showLoading: false,
                selectedField: null,
                saveFields: [],
                showAddField: false,
                addingField: false,
            }
        },
        watch: {
            dataSet: {
                immediate: true,
                handler(val) {
                    this.saveFields = [];
                    this.showAddField = false;
                    this.selectedField = null;
                }
            }
        },
        methods: {
            removeFieldGrouping(fieldID) {
                // {groupID: this.val.id, fieldID: val}
                this.dataSet.fields.forEach(field => {
                    if (field.id === fieldID) {
                        field.fk_field_group = null
                    }
                })
            },

            updateFieldGroup(data) {
                // {groupID: this.val.id, fieldID: val}
                this.dataSet.fields.forEach(field => {
                    if (field.id === data.fieldID) {
                        field.fk_field_group = data.groupID
                    }
                })
            },
            saveFailed(fieldID) {
                this.saveFields = this.saveFields.filter(field => field != fieldID);
            },
            saveChanges(event) {
                this.showAddField = false;
                this.saveFields = this.saveFields.filter(field => field != event.id);
                this.saveFields = this.saveFields.filter(field => field != -1);
                this.$emit('patch_field', event);
                this.$nextTick(() => {
                    this.selectedField = this.dataSetFields.filter(obj => obj.id == event.id).pop();
                })
            },
            log: function(evt) {
                if (typeof evt.moved != 'undefined') {
                    // Check Direction
                    let movedDown = true;
                    if (evt.moved.newIndex < evt.moved.oldIndex) {
                        movedDown = false
                    }
                    // Create A Temporary Array
                    let tArr = this.dataSetFields.filter(() => true);
                    if (!movedDown) {
                        tArr.splice(evt.moved.oldIndex, 1, {removeThisItem: true});
                        tArr.splice(evt.moved.newIndex, 0, evt.moved.element);
                    } else {
                        tArr.splice(evt.moved.oldIndex, 1, {removeThisItem: true});
                        tArr.splice(evt.moved.newIndex + 1, 0, evt.moved.element);
                    }
                    tArr = tArr.filter(obj => typeof obj.removeThisItem == 'undefined');
                    let refObj = tArr.reduce((obj, field, index) => {
                        obj[field.id] = index
                        return obj
                    }, {});
                    this.dataSetFields.forEach(field => {
                        field.order = refObj[field.id];
                    });
                    this.showLoading = true;
                    this.fetch({method: 'PATCH', url: `/datasetmanager/datasetfields/order/${this.userInfo.entityID}`, body: JSON.stringify(tArr.map((obj, index) => {return {id: obj.id, index: index}}))})
                        .then(() => {
                            this.showLoading = false;
                        })
                        .catch((e) => {
                            // eslint-disable-next-line
                            console.log(e)
                            this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                            this.showLoading = false;
                        })
                }
            },
        },
        computed: {
            dataSetFields() {
              // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                return Array.isArray(this.dataSet.fields) ? this.dataSet.fields.sort((a,b) => a['order'] < b['order'] ? -1 : 1) : [];
            }
        }
    }
</script>

<style scoped>

</style>
