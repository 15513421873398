<template>
    <v-dialog
            v-model="valuePassOver"
            fullscreen
            persistent
    >
        <v-card class="scrollable" style="height: 100vh; overflow-y: auto; overflow-x: hidden; background: var(--page-back)">
            <v-layout column fill-height class="scrollable">
                <!--Title-->
                <v-flex shrink class="title-color">
                    <v-layout row align-center>
                        <v-flex xs12>
                            <v-layout row align-center>
                                <v-flex shrink>
                                    <v-icon class="pl-2" color="white">edit_calendar</v-icon>
                                </v-flex>
                                <v-flex xs12 class="font-weight-regular white--text px-2 py-1 title">
                                    Calender Groups Manager
                                </v-flex>
                            </v-layout>
                        </v-flex>
                        <v-flex shrink class="px-2">
                            <v-btn
                                    icon
                                    flat
                                    color="white"
                                    class="pa-0 ma-0"
                                    @click="valuePassOver = !valuePassOver"
                            >
                                <v-icon>close</v-icon>
                            </v-btn>
                        </v-flex>
                    </v-layout>
                </v-flex>
                <!--Loading-->
                <v-flex xs12 fill-height v-if="loadingCalenderManager">
                    <v-layout fill-height align-center justify-center>
                        <v-flex shrink>
                            <v-progress-circular
                                    :size="40"
                                    :width="3"
                                    color="primary"
                                    indeterminate
                            ></v-progress-circular>
                        </v-flex>
                    </v-layout>
                </v-flex>
                <!--Body-->
                <v-flex xs12 fill-height class="scrollable" v-else>
                    <!--Select And Manage Repo Items-->
                    <v-layout row fill-height class="scrollable">
                        <v-flex xs4 fill-height class="py-2 pl-2 pr-1" style="overflow-y: auto; overflow-x: hidden">
                            <v-card class="fill-height" style="overflow-y: auto; overflow-x: hidden">
                                <multi-select-list
                                        :show-action-buttons="false"
                                        :show-check-boxes="true"
                                        :multiple="false"
                                        headline-key="description"
                                        item-key="id"
                                        :return-object="false"
                                        v-model="selectedCalendarGroupID"
                                        :show-filter="true"
                                        :items="calendarGroups"
                                        :dark="true"
                                        filter-label="Filter Calendar Group"
                                >
                                    <div slot="header" class="title-color px-2 py-1">
                                        <div class="subheading white--text pb-1">
                                            Select Calender Group
                                        </div>
                                        <v-btn
                                                small
                                                outline
                                                block
                                                color="white"
                                                class="pa-0 ma-0"
                                                @click="addCalenderGroupShow = !addCalenderGroupShow"
                                        >
                                            <v-icon left>group_add</v-icon>
                                            Add Calendar Group
                                        </v-btn>
                                    </div>
                                </multi-select-list>
                            </v-card>
                        </v-flex>

                        <v-flex xs8 fill-height class="py-2 pl-1 pr-2" style="overflow-y: auto; overflow-x: hidden" v-if="!!selectedCalendarGroup">
                            <v-card class="of-y-f">
                                <modify-calendar-group
                                        :edit-calendar-group="selectedCalendarGroup"
                                        @input="calGroupUpdated($event)"
                                />
                            </v-card>

                        </v-flex>
                    </v-layout>
                </v-flex>
            </v-layout>
        </v-card>
        <v-dialog
                v-model="addCalenderGroupShow"
                persistent
        >
            <v-card class="white" style="height: 90vh">
                <modify-calendar-group
                        v-if="addCalenderGroupShow"
                        :value="null"
                        @dismiss="addCalenderGroupShow = false"
                        @input="calGroupUpdated($event)"
                />
            </v-card>
        </v-dialog>
    </v-dialog>
</template>


<script>
import {mapActions, mapGetters} from 'vuex'
import MultiSelectList from "@/components/Multi Select List/MultiSelectList";
import ModifyCalendarGroup from "@/Admin/Calendar Groups/ModifyCalendarGroup";

export default {
    components: {ModifyCalendarGroup, MultiSelectList},
    props: {
        value: Boolean
    },
    data() {
        return {
            loadingCalenderManager: false,
            selectedCalendarGroupID: null,
            addCalenderGroupShow: false
        }
    },
    watch: {
        value: {
            immediate: true,
            handler(val) {
                if (val)
                    this.refreshGroupCalendars();
            }
        }
    },
    methods: {
        ...mapActions('cal', ['upsertToGroup']),
        refreshGroupCalendars() {
            this.loadingCalenderManager = true;
            this.$newReq('GET', `cal/groups/${this.userInfo.entityID}`)
                .then((data) => {
                    this.upsertToGroup(data)
                })
                .catch(e => {
                    this.$snack.networkError();
                })
                .finally(() => {
                    this.loadingCalenderManager = false
                })
        },
        calGroupUpdated(groupObj) {
            this.selectedCalendarGroupID = groupObj.id;
            this.addCalenderGroupShow = false;
            this.$snack.info('Changes Saved')
        }
    },
    computed: {
        ...mapGetters('userInfo', ['userInfo']),
        ...mapGetters('cal', ['calendarGroups', 'calendarGroupsRef']),
        valuePassOver: {
            get() {
                return this.value
            },
            set(val) {
                this.$emit('input', val)
            }
        },
        selectedCalendarGroup() {
            if (this.selectedCalendarGroupID)
                return this.calendarGroupsRef[this.selectedCalendarGroupID]
            return null
        }
    },
}
</script>

<style scoped>

</style>