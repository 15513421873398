<template>
    <div style="overflow-x: hidden;">
        <div v-if="typeof message.tasks != 'undefined'">
            <v-layout row wrap>
                <template v-for="task in message.tasks">
                    <v-flex shrink :key="'linkTaskHeader' + task.taskID">
                        <v-menu open-on-hover offset-y nudge-left="150px">
                                <v-chip
                                        slot="activator"
                                        small
                                        label
                                        color="secondary"
                                        text-color="white"
                                >
                                    <v-icon left small>assignment_turned_in</v-icon>
                                    Task #{{ task.taskID }}
                                </v-chip>
                            <div style="width: 400px; background: white">
                                <app-task-bar-container
                                        :user-info="userInfo"
                                        :task="task"
                                        :dashboard="true"
                                        :folder-groups="folderGroups"
                                        :calc-folder-group-names="true"
                                ></app-task-bar-container>
                            </div>
                        </v-menu>
                    </v-flex>
                </template>
            </v-layout>
        </div>

        <v-layout row align-end style="overflow-x: hidden;">
            <v-flex shrink class="pa-2">
                <v-avatar class="lighten-1" size="40" color="grey">
                    <v-icon dark>alternate_email</v-icon>
                </v-avatar>
            </v-flex>
            <v-flex xs12 class="py-2 pt-1" style="overflow-x: hidden;">
<!--Subject-->
                <div class="text-truncate title font-weight-regular;">{{ message.subject }}</div>
<!--FROM / DATE-->
                <v-layout row align-center>
                    <v-flex xs12 >
                        <template v-for="(text, i) in message.from.replace(/>/g, '').split('<')">
                            <span
                                    :class="text[text.length -1] == ' ' ? 'font-weight-bold' : 'font-weight-regular greyType--text'"
                                    class="subheading"
                                    :key="i"
                            >
                                {{ text }}
                            </span>
                        </template>
                    </v-flex>
                    <v-flex shrink class="pr-2 caption" style="white-space: nowrap">{{ mailDate }}</v-flex>
                </v-layout>
<!--To-->
                <div style="overflow-x: hidden;" class="caption text-truncate greyType--text pt-1">
                    <div v-html="'To: ' + messageTo" class="text-truncate"></div>
                </div>
<!--CC-->
                <div v-if="messageCC !== null" style="overflow-x: hidden;" class="caption text-truncate greyType--text pt-1">
                    <div v-html="'CC: ' + messageCC" class="text-truncate"></div>
                </div>
            </v-flex>
        </v-layout>
    </div>
</template>

<script>

    import { codeBaseMixin } from "../../../codeBaseMixin";
    import { eventBus } from '../../../main';
    import TaskBarContainer from '../../../components/General/TaskBarContainer';

    export default {
        components: {
            appTaskBarContainer: TaskBarContainer
        },
        props: {
            message: Object,
            userInfo: Object,
            folderGroups: Array
        },
        mixins: [codeBaseMixin],
        created() {
            eventBus.$on('refreshMailHeader', () => { this.$forceUpdate(); })
        },
        beforeDestroy() {
            eventBus.$off('refreshMailHeader', () => { this.$forceUpdate(); })
        },
        computed: {
            mailDate() {
                try {
                    return this.getLongDate(this.message.mailDate)
                } catch (e) {
                    return null
                }
            },

            messageTo() {
                try {
                    let tAddrs = this.message.to.split(',')
                    return tAddrs.map(obj => {
                        let tVals = obj.replace(/>/g,"").split('<')

                        return `<strong>${tVals[0].trim()}</strong> ${`${tVals.length <= 1 ? '' : tVals[1].trim()}`.trim()}`
                    }).join(', ')
                } catch (e) {
                    // eslint-disable-next-line
                    console.log(e);
                    return this.message.to;
                }
            },

            messageCC() {
                if (!this.blankString(this.message.cc)) {
                    try {
                        let tAddrs = this.message.cc.split(',')
                        return tAddrs.map(obj => {
                            let tVals = obj.replace(/>/g,"").split('<')

                            return `<strong>${tVals[0].trim()}</strong> ${`${tVals.length <= 1 ? '' : tVals[1].trim()}`.trim()}`
                        }).join(', ')
                    } catch (e) {
                        // eslint-disable-next-line
                        console.log(e);
                        return this.message.cc;
                    }
                } else {
                    return null
                }
            }
        }
    }
</script>

<style scoped>

</style>
