<template>
  <app-input-dlg
      :show-dialog="shown"
      :title="title"
      :body="body"
      :cancel-button="showCancel"
      :show-input-box="true"
      :buttons="showCancel ? ['Cancel', buttonLabel] : [buttonLabel]"
      :call-backs="showCancel ? [ cancel, done ] : [ done ]"
      @dismiss="() => cancel()"
      :default-input="defaultValue"
  />
</template>

<script>
import {promptEvents} from "@/components/General/AppInputPrompt/AppInputPrompt";
import appInputDlg from "@/components/General/InputDlg.vue";

export default {
  components: {appInputDlg},
  data() {
    return {
      shown: false,
      title: null,
      body: null,
      label: null,
      defaultValue: null,
      buttonLabel: null,
      showCancel: null,
      inputStr: ''
    }
  },
  created() {
    promptEvents.on('showPrompt', this.showPrompt)
  },
  beforeDestroy() {
    promptEvents.off('showPrompt', this.showPrompt)
  },
  methods: {
    showPrompt({title, body, label, defaultValue, buttonLabel, showCancel}) {
      this.shown = true;
      this.title = title || null;
      this.body = body || null;
      this.label = label || null;
      this.defaultValue = defaultValue || null;
      this.buttonLabel = buttonLabel || 'Done';
      this.showCancel = showCancel || true;
      this.inputStr = this.defaultValue || null
    },
    
    done(val) {
      promptEvents.emit('done', val)
      this.shown = false
    },
    
    cancel() {
      promptEvents.emit('done', false)
      this.shown = false
    },
  },
}
</script>



<style scoped>

</style>