<template>
    <div class="c-d-flex fill-height">
        <div class="fill-height">
            <div v-if="mini" class="c-d-flex c-flex-column c-align-center fill-height primary">
                <div class="">
                    <v-tooltip right>
                        <v-btn
                                small
                                icon
                                flat
                                color="white"
                                slot="activator"
                                @click="mini = !mini"
                        >
                            <v-icon>keyboard_arrow_right</v-icon>
                        </v-btn>
                        Show More
                    </v-tooltip>
                    <v-divider color="white"></v-divider>
                </div>

                <div class="fill-height of-y">
                    <div v-for="(option, i) in allFilterOptions" class="px-2 pt-2" :key="i">
                        <v-tooltip right>
                            <div style="position: relative" slot="activator">
                                <div
                                        :class="!filter.length || filter.includes(option.id)  ? 'amber' : 'primary darken-2'"
                                        class="caption text-xs-center primary--text font-weight-medium"
                                        v-if="option.count"
                                        style="position: absolute; top: -5px; right: -5px; width: 18px; height: 18px; border-radius: 9px"
                                >
                                    {{option.count}}
                                </div>
                                <v-btn
                                        icon
                                        flat
                                        :color="!filter.length || filter.includes(option.id)  ? 'white' : 'primary darken-2'"
                                        @click="updateFilter(option.id)"
                                        class="pa-0 ma-0"
                                >
                                    <template v-if="option.type === 'owner'">
                                        <v-icon>person</v-icon>
                                    </template>
                                    <template v-else>
                                        {{ option.initials }}
                                    </template>
                                </v-btn>
                            </div>
                            {{option.description}}
                        </v-tooltip>
                    </div>
                </div>



            </div>

            <div class="py-1 pl-1 pr-0 fill-height" style="width: 300px" v-else>
                <v-card class="fill-height of-y c-d-flex c-flex-column">

                    <div class="c-d-flex c-align-center primary">

                        <v-btn
                                flat
                                color="white"
                                class="py-0 my-0 mx-0"
                                @click="mini = !mini"
                                block
                        >
                            <div style="width: 100%" class="c-d-flex c-justify-space-between c-align-center">
                                <div class="title font-weight-regular" style="text-transform: none !important;">Calendar View</div>
                                <v-icon right>keyboard_arrow_left</v-icon>
                            </div>
                        </v-btn>
                    </div>

                    <v-date-picker
                            :value="dateRange.modelDate"
                            @input="dateRange.modelDate = $event"
                            scrollable
                            full-width
                            no-title
                            :first-day-of-week="1"
                            style="border: none; box-shadow: none"
                            color="secondary"
                    >
                    </v-date-picker>
                    <div style="width: 100%">
                        <v-divider></v-divider>
                    </div>

                    <div class="c-d-flex-grow-1 of-y">
                        <div @click="filter = []" class="c-d-flex hover-item c-align-center" v-if="allFilterOptions.length > 1">
                            <v-btn
                                    icon
                                    small
                                    flat
                                    slot="activator"
                                    :color="!filter.length ? 'primary' : 'grey'"
                                    class="pa-0 ma-0 mx-2"
                            >
                                <v-icon>{{ !filter.length ? 'check_box' : 'check_box_outline_blank' }}</v-icon>
                            </v-btn>
                            <div class="c-flex-grow-1 py-2">
                                Select All
                            </div>
                        </div>
                        <div v-for="(option, i) in allFilterOptions" class="c-d-flex hover-item c-align-center" :key="i">
                            <v-btn
                                    icon
                                    small
                                    flat
                                    slot="activator"
                                    :color="!filter.length || filter.includes(option.id) ? (option.ref.color || 'primary') : 'grey'"
                                    class="pa-0 ma-0 mx-2"
                                    @click="updateFilter(option.id)"
                            >
                                <v-icon>{{ !filter.length || filter.includes(option.id) ? 'check_box' : 'check_box_outline_blank' }}</v-icon>
                            </v-btn>
                            <div class="c-flex-grow-1 py-2" @click="updateFilter(option.id)">
                                {{option.description}}
                            </div>
                            <div v-if="option.ref">
                                <v-tooltip right v-if="!option.ref.allUsersSubscribed">
                                    <v-btn
                                            icon
                                            small
                                            flat
                                            slot="activator"
                                            color="grey lighten-1"
                                            class="pa-0 ma-0"
                                            :disabled="option.ref.loading"
                                            :loading="option.ref.loading"
                                            @click="updateSubscription(option.ref)"
                                    >
                                        <v-icon>{{ option.ref.subscribed ? 'notifications' : 'notifications_off'}}</v-icon>
                                    </v-btn>
                                    {{option.ref.subscribed ? 'Unsubscribe' : 'Subscribe'}} to Calendar Group Notifications
                                </v-tooltip>
                                <v-tooltip right v-else>
                                    <v-btn
                                            icon
                                            small
                                            flat
                                            slot="activator"
                                            color="grey lighten-1"
                                            class="pa-0 ma-0"
                                    >
                                        <v-icon>flash_auto</v-icon>
                                    </v-btn>
                                    Auto Subscribed
                                </v-tooltip>
                            </div>
                            <div class="px-1 greyType--text" @click="updateFilter(option.id)">
                                ({{option.count}})
                            </div>
                        </div>
                    </div>
                </v-card>
            </div>
        </div>
        <div class="c-flex-grow-1 fill-height" style="overflow-x: auto">
            <calendar scroll-container-i-d="userCalDaysContainer" >
                <template slot="header">
                    <div class="c-d-flex c-align-center py-0 primary">
                        <v-btn small color="white" flat icon class="py-0 my-0" @click="dateRange.previous()"><v-icon>chevron_left</v-icon></v-btn>
                        <v-btn small color="white" flat icon class="py-0 my-0" @click="dateRange.next()"><v-icon>chevron_right</v-icon></v-btn>
                        <div class="text-xs-center c-d-flex c-align-center c-flex-grow-1">
                            <v-menu
                                    ref="menu"
                                    :close-on-content-click="false"
                                    :close-on-click="true"
                                    :return-value.sync="dateRange.modelDate"
                                    lazy
                                    transition="scale-transition"
                                    offset-y
                                    full-width
                                    max-width="600px"
                                    min-width="290px"
                            >
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                            flat
                                            color="white"
                                            class="py-0 my-0 mx-0"
                                            v-on="on"
                                    >
                                        <div class="title font-weight-regular" style="text-transform: none !important;">{{dateRange.rangeDisplay}}</div>
                                    </v-btn>
                                </template>
                                <v-date-picker
                                        :value="dateRange.modelDate"
                                        scrollable
                                        @input="$refs.menu.save($event)"
                                >
                                </v-date-picker>
                            </v-menu>
                        </div>
                        <div class="pr-2">

                            <v-tooltip
                                    top
                            >
                                <v-btn
                                        small
                                        flat
                                        icon
                                        class="pa-0 ma-0"
                                        :color="filterMe ? 'amber' : 'white'"
                                        slot="activator"
                                        @click="filterMe = !filterMe"
                                >
                                    <v-icon>{{filterMe ? 'person' : 'people'}}</v-icon>
                                </v-btn>
                                {{filterMe ? 'Show All Events' : 'Show My Events Only'}}
                            </v-tooltip>

                        </div>
                        <div class="pr-2">

                            <v-tooltip
                                    top
                            >
                                <v-btn
                                        small
                                        flat
                                        icon
                                        class="pa-0 ma-0"
                                        :color="filterDone ? 'amber' : 'white'"
                                        slot="activator"
                                        @click="filterDone = !filterDone"
                                >
                                    <v-icon>{{filterDone ? 'remove_done' : 'flaky'}}</v-icon>
                                </v-btn>
                                {{filterDone ? 'Show All Events' : 'Hide Completed Events'}}
                            </v-tooltip>

                        </div>
                        <div class="pr-2">
                            <v-menu offset-y :close-on-content-click="false" v-model="selectedFilterUsersMenu">
                                <template #activator="{ on: menu }">
                                    <v-tooltip left>
                                        <template #activator="{ on: tooltip }">
                                            <div v-on="{ ...tooltip, ...menu }">
                                                <template v-for="(userID, i) in selectedFilterUsers">

                                                    <v-tooltip
                                                            top
                                                            :key="i"
                                                            v-if="i < 10"
                                                    >
                                                        <v-btn
                                                                small
                                                                flat
                                                                icon
                                                                class="pa-0 ma-0 mr-1"
                                                                color="amber"
                                                                slot="activator"
                                                        >
                                                            {{ usersRef[userID].initials }}
                                                        </v-btn>
                                                        {{usersRef[userID].fullName}}
                                                    </v-tooltip>

                                                </template>
                                                <v-btn
                                                        small
                                                        flat
                                                        icon
                                                        class="pa-0 ma-0 mr-1"
                                                        color="amber"
                                                        v-if="selectedFilterUsers.length > 10"
                                                >
                                                    +{{ selectedFilterUsers.length - 5 }}
                                                </v-btn>
                                                <v-btn
                                                        small
                                                        flat
                                                        icon
                                                        class="pa-0 ma-0"
                                                        :color="selectedFilterUsers.length ? 'amber' : 'white'"

                                                >
                                                    <v-icon>filter_alt</v-icon>
                                                </v-btn>
                                            </div>
                                        </template>
                                        <span>Filter by Event Owner</span>
                                    </v-tooltip>
                                </template>
                                <v-card style="width: 350px;">
                                    <div style="height: 350px;" class="of-y">
                                        <multi-select-list
                                                :show-action-buttons="false"
                                                :show-check-boxes="true"
                                                :multiple="true"
                                                headline-key="description"
                                                item-key="id"
                                                :return-object="false"
                                                v-model="selectedFilterUsers"
                                                :show-filter="true"
                                                :items="filterUsers"
                                                filter-label="Filter Event Owners"
                                        />
                                    </div>
                                    <v-divider></v-divider>
                                    <div class="c-d-flex c-justify-space-between" style="background: var(--page-back)">
                                        <v-btn
                                                small
                                                color="secondary"
                                                @click="selectedFilterUsers = []; selectedFilterUsersMenu = false"
                                                outline
                                                :disabled="!selectedFilterUsers.length"
                                        >
                                            Clear Filter
                                        </v-btn>

                                        <v-btn
                                                small
                                                color="secondary"
                                                @click="selectedFilterUsersMenu = false"
                                        >
                                            Done
                                        </v-btn>
                                    </div>

                                </v-card>
                            </v-menu>
                        </div>
                        <div class="px-2" v-if="showLoader">
                            <v-progress-circular
                                    :size="24"
                                    :width="3"
                                    color="white"
                                    indeterminate
                            />
                        </div>
                    </div>
                </template>
                <template>
                    <!--Day-->
                    <calendar-day
                            v-for="(day, n) in dateRange.days"
                            :key="n"
                            :id="'userCalDay' + day.dayCode"
                    >
                        <template slot="header">
                            <v-hover>
                                <div class="d-flex align-center c-flex-column c-bord-b" slot-scope="{ hover }">
                                    <div class="text-xs-center greyType--text caption ">
                                        {{day.dayLongDesc}}
                                    </div>
                                    <v-tooltip :disabled="!hover" top>
                                        <v-btn
                                                icon
                                                :flat="!hover"
                                                class=" py-0 mt-0 mb-1"
                                                :color="hover ? 'secondary' : null"
                                                slot="activator"
                                                small
                                                @click="$refs.createEvent.init({start: day.date})"
                                        >
                                            <template v-if="!hover">
                                                {{ day.day }}
                                            </template>
                                            <v-icon v-else>
                                                add
                                            </v-icon>
                                        </v-btn>
                                        Add Calendar Event
                                    </v-tooltip>
                                </div>
                            </v-hover>
                        </template>

                        <template v-if="viewEvents" >

                            <div class="c-d-flex c-flex-column of-y fill-height" style="position: relative">
                                <div v-for="event in viewEvents[day.dayCode]" :key="event.id" >
                                    <calendar-item :event="event" @input="popupEvent($event)"  @inputEdit="popupEvent($event, true)"></calendar-item>
                                </div>
                                <div class="c-flex-grow-1" @click="$refs.createEvent.init({start: day.date})" >

                                </div>
                            </div>





                        </template>
                    </calendar-day>
                </template>

                <calendar-event-full-detail
                        :event="popUpEvent"
                        v-if="showPopupEvent"
                        v-model="showPopupEvent"
                        :editMode="showPopupEventInEdit"
                />

                <calendar-item-create ref="createEvent"/>

            </calendar>
        </div>

    </div>



</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import Calendar from "@/Calendar View/Calendar";
import CalendarDay from "@/Calendar View/CalendarDay";
import CalendarItem from "@/Calendar View/CalendarItem";
import VueScrollTo from 'vue-scrollto';

import userInfo from "@/Store/userInfo/userInfo";
import CalendarItemDetailModify from "@/components/General/Calendar/CalendarItemDetailModify";
import {waitForEvent} from "@/feedSocket";
import CalendarItemCreate from "@/components/General/Calendar/CalendarItemCreate";
import CalendarEventFullDetail from "@/components/General/Calendar/CalendarEventFullDetail";
import {arrSplice} from "@/codeFunctions";
import MultiSelectList from "@/components/Multi Select List/MultiSelectList";
import {addDays, endOfDay, format, isMonday, isSameMonth, startOfDay, subDays, eachDay, getDayOfYear, getYear} from "date-fns";
// import parse from "date-fns/parse";
class DateRangeSelector {
    constructor(dateChangeCb) {
        // Set The Start Of Range
        this.setStartDate(new Date())
        // Set The End Of Range
        this.setEndDate();

        this.dateChangeCb = dateChangeCb || null
    }

    setStartDate(date) {
        this.trueDate = new Date(date);
        // Set The Start of Range
        this.startDate = startOfDay(date)
        // Move Cursor Left Until Its A Monday
        while (!isMonday(this.startDate)) {
            this.startDate = subDays(this.startDate, 1);
        }

        if (this.dateChangeCb)
            this.dateChangeCb(this.trueDate)
    }

    setEndDate() {
        this.endDate = endOfDay(addDays(this.startDate, 6));
    }

    get date() {
        return this.startDate
    }
    set date(val) {
        this.startDate = val
    }

    get rangeDisplay() {
        // 1 - 6 AUG 2021
        if (isSameMonth(this.startDate, this.endDate))
            return `${format(this.startDate, 'D')} - ${format(this.endDate, 'D MMM YYYY')}`.toUpperCase()
        // 1 AUG - 7 SEP 2021
        // if (isSameYear(this.startDate, this.endDate))
            return `${format(this.startDate, 'D MMM')} - ${format(this.endDate, 'D MMM YYYY')}`.toUpperCase()
        // // 29 DEC 2021 - 6 JAN 2022
        // return `${format(this.startDate, 'D MMM YYYY')} - ${format(this.endDate, 'D MMM YYYY')}`.toUpperCase()
    }
    next() {
        this.startDate = addDays(this.startDate, 7)
        this.trueDate = this.startDate;
        this.setEndDate();
        if (this.dateChangeCb)
            this.dateChangeCb(this.trueDate)
    }
    previous() {
        this.startDate = subDays(this.startDate, 7)
        this.trueDate = this.startDate;
        this.setEndDate();
        if (this.dateChangeCb)
            this.dateChangeCb(this.trueDate)
    }
    get modelDate() {
        return format(this.trueDate, 'YYYY-MM-DD')
    }
    set modelDate(val) {
        if (val) {
            this.setStartDate(new Date(val.split('').length <= 7 ? `${val}-01` : val))
            this.setEndDate()


        }
    }
    get days() {
        return eachDay(this.startDate, this.endDate).map((date) => {
            return {
                date: date,
                dayOfYear: getDayOfYear(date),
                dayCode: Number(`${getYear(date)}${`${getDayOfYear(date)}`.padStart(3, '0')}`),
                dayLongDesc: format(date, 'dddd'),
                dayShortDesc: format(date, 'ddd').toUpperCase(),
                day: format(date, 'MMM D').toUpperCase()
            }
        })
    }
}

function sqlDate(date) {
    if (date)
        return format(date, 'YYYY-MM-DD HH:mm:ss')
    return null
}

export default {
    components: {
        MultiSelectList,
        CalendarEventFullDetail,
        CalendarItemCreate, CalendarItem, CalendarDay, Calendar},
    props: {
        curView: String
    },
    data() {
        return {
            dateRange: new DateRangeSelector(this.selectedDateChanged),
            menu: false,
            showLoader: false,
            showPopupEvent: false,
            popUpEventID: null,
            mini: false,
            filter: [],
            fetchedRanges: [],
            selectedFilterUsers: [],
            selectedFilterUsersMenu: false,
            showPopupEventInEdit: false,
            filterDone: false,
            filterMe: false,
        }
    },
    watch: {
        async curView(val) {
            if (val && val === 'calendar') {
                await this.startUpUserCalendar();
                this.updateEvents();
            }
        }
    },
    async mounted() {
        await this.startUpUserCalendar();
        this.updateEvents();
    },
    methods: {

        updateSubscription(group) {
            if (!group.loading) {
                group.loading = true;
                group.updateSubscription()
            }
        },

        updateFilter(id) {
            if (id === null) {
                if (this.filter.length === 1 && this.filter.filter(() => true).pop() === null)
                    this.filter = []
                else
                    this.filter = [null]
            } else {
                arrSplice(this.filter, optionId => optionId === null)

                if (this.filter.includes(id))
                    arrSplice(this.filter, optionId => optionId === id)
                else
                    this.filter.push(id)
            }
        },


        ...mapActions('cal', ['upsert']),

        popupEvent(event, inEdit = false) {
            this.popUpEventID = event.id;
            this.showPopupEventInEdit = inEdit
            this.showPopupEvent = true
        },

        transTest() {
            this.showLoader = true

            setTimeout(() => {
                this.showLoader = false
            }, 2000)
        },

        selectedDateChanged(date) {
            if (date) {
                this.$nextTick(() => {
                    let dayCodeID = `#userCalDay${getYear(date)}${`${getDayOfYear(date)}`.padStart(3, '0')}`
                    VueScrollTo.scrollTo(dayCodeID, 500, {container: '#userCalDaysContainer', x: true});
                    this.updateEvents();
                })
            }
        },

        async updateEvents() {

            const rangeKey = `${sqlDate(this.dateRange.startDate)}-${sqlDate(this.dateRange.endDate)}`;

            if (!this.fetchedRanges.includes(rangeKey))
                try {
                    this.showLoader = true
                    const rangeEvents = await this.$newReq('POST', `cal/event/${this.userInfo.entityID}/${this.userInfo.userID}`, {
                        start: sqlDate(this.dateRange.startDate),
                        end: sqlDate(this.dateRange.endDate)
                    })
                    this.upsert({events: rangeEvents})
                } catch (e) {
                    console.log(e)
                } finally {
                    this.showLoader = false
                    this.fetchedRanges.push(rangeKey)
                }
        },

        startUpUserCalendar() {
            return new Promise(async (resolve) => {
                try {
                    this.showLoader = true
                    const startupData = await this.$newReq('GET', `cal/event/userStartup/${this.userInfo.entityID}/${this.userInfo.userID}`, {start: sqlDate(this.dateRange.startDate), end: sqlDate(this.dateRange.endDate)})
                    this.upsert(startupData)
                } catch (e) {
                    console.log(e)
                } finally {
                    resolve(true)
                }
            })
        }
    },
    computed: {
        ...mapGetters('userInfo', ['userInfo']),
        ...mapGetters(['usersRef', 'users']),
        ...mapGetters('cal', ['calendarGroups', 'calendarGroupsRef', 'calendarEvents', "calendarEventsWithAccess", "calendarGroupsWithAccess"]),
        popUpEvent() {
            if (this.popUpEventID) {
                try {
                    return this.calendarEventsWithAccess.filter(event => event.id === this.popUpEventID).pop()
                } catch (e) {
                    return null
                }
            } else {
                return null
            }

        },
        viewEvents() {
            let ref = this.dateRange.days.reduce((obj, day) => {
                obj[day.dayCode] = []
                return obj
            }, {});

            for (const event of this.filteredEventsAfterUsers) {
                ref[event.dateKey].push(event)
            }

            for (const dateKey of Object.keys(ref)) {
                ref[dateKey] = ref[dateKey].sort((a, b) => {return a.start > b.start ? 1 : -1})
            }

            return ref
        },

        viewEventsList() {
            return this.calendarEventsWithAccess.filter(obj => obj.start >= this.dateRange.startDate && obj.start <= this.dateRange.endDate)
        },

        filteredEvents() {
            return this.viewEventsList
                    .filter(event => this.filterMe ? event.fkOwner === this.userInfo.userID : true)
                    .filter(event => this.filter.length ? this.filter.includes(event.fkCalGroup) : true)
        },

        filteredEventsAfterDone() {
            return !this.filterDone ? this.filteredEvents : this.filteredEvents.filter(event => !event.done)
        },

        filteredEventsAfterUsers() {
            return this.selectedFilterUsers.length === 0 ? this.filteredEventsAfterDone : this.filteredEventsAfterDone.filter(event => this.selectedFilterUsers.includes(event.fkOwner))
        },

        allFilterOptions() {
            let groups = this.calendarGroupsWithAccess.map((obj) => {
                return {
                    id: obj.id,
                    initials: obj.groupInitials,
                    type: 'group',
                    description: obj.description,
                    count: this.viewEventsList.filter(event => event.fkCalGroup === obj.id).length,
                    ref: obj
                }
            }).sort((a,b) => a.description > b.description ? 1 : -1)
            return groups
        },

        filterUsers() {
            let availableUsers = this.users.filter(user => !user.disabled)

            let countRef = this.viewEventsList.reduce((obj, event) => {

                if (typeof obj[event.fkOwner] === 'undefined')
                    obj[event.fkOwner] = 0;

                obj[event.fkOwner] = ++obj[event.fkOwner];

                return obj
            }, {})

            return availableUsers
                    .map((user) => {
                        return {
                            id: user.userID,
                            description: user.fullName,
                            count: countRef[user.userID] || 0,
                            username: user.username
                        }
                    })
                    .sort((a, b) => a.description > b.description ? 1 : -1)
        }



    },
}
</script>

<style scoped>

</style>