<template>
  <div>
    <div @click="shownRelay = !shownRelay">
      <slot name="activator"></slot>
    </div>
    
    
    <v-dialog
        v-if="shownRelay"
        v-model="shownRelay"
        :width="1000"
        max-width="90%"
        persistent
        
    >
      <v-card @click="console.log(contact)" v-if="shownRelay && contact" class="scrollable c-d-flex c-flex-column" style="height: 800px; max-height: 80vh">
        
        <div class="c-flex-grow-0 px-3 primary white--text dark c-d-flex c-align-center">
          <div>
            <slot name="titleIcon">
              <v-icon color="white" left>contact_mail</v-icon>
            </slot>
          </div>
          <div class="c-flex-grow-1 py-2 subheading">
            <slot name="title">{{ contact.fullName }}</slot>
          </div>
          <div>
            <v-btn
                color="white"
                icon
                flat
                @click="shownRelay = false"
                class="mx-0"
            >
              <v-icon>close</v-icon>
            </v-btn>
          </div>
        </div>
        
        <div class="c-flex-grow-1 of-y">
          <app-contact-information v-if="shown && contact" :contact="contact"/>
        </div>
      </v-card>
      <!--            <template v-slot:activator="{ on }">-->
      <!--                -->
      
      <!--            </template>-->
      
      
    </v-dialog>
  </div>

</template>

<script>
import AppContactInformation from "@/contacts/AppContactInformation.vue";

export default {
  components: {
    AppContactInformation
  },
  props: {
    shown: {
      type: Boolean,
      default: false
    },
    contact: {
      type: [Object, null],
      default: null
    },
  },
  data() {
    return {
      selfShown: false,
      console,
    }
  },
  computed: {
    shownRelay: {
      get() {
        return this.shown || this.selfShown
      },
      set(val) {
        this.selfShown = val
        this.$emit("update:shown", val)
      }
    }
  },
}
</script>

<style scoped>

</style>