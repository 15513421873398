<template>
    <v-dialog
            v-model="showDialog"
            persistent
            width="500"
    >
        <v-card>
            <v-card-title class="pa-1 pl-2">
                <span class="subheading font-weight-light primary--text">Choose A Select List</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>

                <v-layout row wrap>
                    <v-flex xs12 class="text-xs-center" v-if="loading">
                        <v-progress-circular
                                :size="40"
                                :width="5"
                                color="primary"
                                indeterminate
                        ></v-progress-circular>
                    </v-flex>
                    <v-flex xs12 v-else>
<!--Add List-->
                        <div class="pt-2">
                            <span class="body-2 greyType--text">Add New List Item</span>
                        </div>
                        <v-text-field
                                label="Add New Select List"
                                single-line
                                v-model="addListDescription"
                                color="primary"
                                append-outer-icon="add"
                                hide-details
                                style="width: 100%"
                                @click:append-outer="addNewList"
                                class="ma-0 pa-0 pl-3"
                                :loading="addingList"
                                :disabled="addingList"
                                @keydown.enter="addNewList"
                                clearable
                        ></v-text-field>
<!--Select Select List-->
                        <div class="pt-3">
                            <span class="body-2 greyType--text">Available Select Lists</span>
                        </div>
                        <v-autocomplete
                                dense
                                v-model="selectedList"
                                :items="orderedSelectLists"
                                label="Chose A Select List"
                                hide-details
                                single-line
                                color="primary"
                                item-text="description"
                                return-object
                                class="pa-0 ma-0 pl-3"
                        ></v-autocomplete>
<!--Edit List Name-->
                        <template v-if="this.selectedList != null">
                            <div class="pt-3">
                                <span class="body-2 greyType--text">Edit Select List Name</span>
                            </div>
                            <v-text-field
                                    label="Add New Select List"
                                    single-line
                                    v-model="selectedList.description"
                                    color="primary"
                                    append-outer-icon="edit"
                                    hide-details
                                    style="width: 100%"
                                    @click:append-outer="editSelectList"
                                    class="ma-0 pa-0 pl-3"
                                    :loading="editingSelectList"
                                    :disabled="editingSelectList"
                                    @keydown.enter="editSelectList"
                                    clearable
                            ></v-text-field>
                        </template>
<!--Select List Items-->
                        <template v-if="this.selectedList != null">
                            <div class="pt-3">
                                <span class="body-2 greyType--text">List Items</span>
                            </div>
<!--Items-->
                            <div
                                    style="max-height: 250px; overflow-y: auto"
                                    class="scroll"
                            >
                                <template v-for="(item, index) in orderedSelectListMembers">
                                    <v-layout
                                            row
                                            :key="'CustomListItem' + index"
                                            align-center
                                            class="pl-3"
                                    >
                                        <v-flex xs12>
                                            <span class="body-1 font-weight-light">{{ item.value }}</span>
                                        </v-flex>
                                        <v-flex shrink>
                                            <v-btn
                                                    icon
                                                    flat
                                                    small
                                                    color="red"
                                                    @click="removeListItem(item)"
                                                    class="pa-0 ma-0"
                                            >
                                                <v-icon>
                                                    close
                                                </v-icon>
                                            </v-btn>
                                        </v-flex>
                                    </v-layout>
                                </template>
                            </div>
<!--Add List Item-->
                            <v-text-field
                                    label="Add New List Item"
                                    single-line
                                    v-model="addListItemDescription"
                                    color="primary"
                                    append-outer-icon="add"
                                    hide-details
                                    style="width: 100%"
                                    @click:append-outer="addListItem"
                                    class="ma-0 pa-0 pt-2 pl-3"
                                    @keydown.enter="addListItem"
                                    :loading="addingListItem"
                                    :disabled="addingListItem"
                                    ref="addListItemField"
                            ></v-text-field>
                        </template>
                    </v-flex>

                </v-layout>

            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                        color="secondary"
                        flat
                        @click="$emit('dismiss', true)"
                >
                    Cancel
                </v-btn>

                <v-btn
                        v-if="selectedList != null"
                        color="secondary"
                        flat
                        @click="listSelected"
                >
                    Select List
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import { codeBaseMixin} from "../../codeBaseMixin";
    export default {
        mixins: [codeBaseMixin],
        props: {
            userInfo: Object,
            showDialog: Boolean
        },
        data() {
            return {
                loading: true,
                selectLists: [],
                selectListMembers: [],
                selectedList: null,
                addListDescription: '',
                addingList: false,
                editingSelectList: false,
                addListItemDescription: '',
                addingListItem: false,
            }
        },
        mounted() {
            this.initDlg();
        },
        methods: {

            removeListItem(item) {

                this.fetch({method: 'DELETE', url: '/workflowEditor/selectListMember/' + this.userInfo.entityID + '/' + item.id})
                    .then(() => {
                        this.showSnack('Info', 'List Item Removed', 'Close', 'primary')
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e)
                    });

                this.selectListMembers.forEach((listItem, i) => {
                    if (listItem.id == item.id) {
                        this.selectListMembers.splice(i,1)
                    }
                })
            },


            addListItem() {
                if (this.addListItemDescription != null && this.addListItemDescription != '') {
                    this.addingListItem = true;
                    this.fetch({method: 'POST', url: '/workflowEditor/selectListMember/' + this.userInfo.entityID, body: JSON.stringify({description: this.addListItemDescription, selectList: this.selectedList.id})})
                        .then((data) => {
                            this.selectListMembers.push(data);
                            this.addingListItem = false;
                            this.addListItemDescription = '';
                            this.$nextTick(() => {
                                this.$refs.addListItemField.focus();
                            });

                            this.showSnack('Info', 'List Item Added', 'Close', 'primary')
                        })
                        .catch((e) => {
                            // eslint-disable-next-line
                            console.log(e)
                        })
                } else {
                    this.showSnack('Error', 'List Item Can Not Be Blank', 'Close', 'red');
                }
            },

            listSelected() {
                this.$emit('selected', {description: this.selectedList.description, id: this.selectedList.id});
                this.$emit('dismiss', true)
            },

            editSelectList() {
                if (this.selectedList.description != null && this.selectedList.description != '') {
                    this.editingSelectList = true;
                    this.fetch({method: 'PATCH', url: '/workflowEditor/selectList/' + this.userInfo.entityID, body: JSON.stringify({description: this.selectedList.description, id: this.selectedList.id})})
                        .then(() => {
                            this.showSnack('Info', 'Changes Saved', 'Close', 'primary')
                            this.editingSelectList = false
                        })
                        .catch((e) => {
                            // eslint-disable-next-line
                            console.log(e)
                        })
                } else {
                    this.showSnack('Error', 'List Name Can Not Be Blank', 'Close', 'red');
                }
            },

            addNewList() {
                if (this.addListDescription != null && this.addListDescription != '') {
                    this.addingList = true;
                    this.fetch({method: 'POST', url: '/workflowEditor/selectList/' + this.userInfo.entityID, body: JSON.stringify({description: this.addListDescription})})
                        .then((data) => {
                            this.selectLists.push(data);
                            this.addListDescription = '';
                            this.addingList = false;
                            this.selectedList = data
                        })
                        .catch((e) => {
                            // eslint-disable-next-line
                            console.log(e)
                        })
                } else {
                    this.showSnack('Error', 'List Name Can Not Be Blank', 'Close', 'red');
                }
            },

            initDlg() {
                this.loading = true;
                if (typeof this.userInfo.entityID == 'undefined') {
                    setTimeout(() => {this.initDlg()}, 500)
                } else {
                    this.fetch({method: 'GET', url: '/workflowEditor/selectlists/' + this.userInfo.entityID})
                        .then((data) => {
                            this.selectLists = data.selectLists;
                            this.selectListMembers = data.selectListMembers;
                            this.loading = false;
                        })
                        .catch((e) => {
                            // eslint-disable-next-line
                            console.log(e)
                        })
                }
            }
        },
        computed: {
            orderedSelectLists() {
                if (this.selectLists.length > 0) {
                    let tList = this.selectLists;
                    tList.forEach((list, i) => {
                       list.index = i;
                    });
                    tList.sort((a,b) => a['description'] < b['description'] ? -1 : 1);
                    return tList
                } else {
                    return [];
                }
            },

            orderedSelectListMembers() {
                if (this.selectedList != null && this.selectListMembers.length > 0) {
                    let tList = [];
                    this.selectListMembers.forEach((listItem) => {
                        if (listItem.selectList == this.selectedList.id) {
                            tList.push(listItem)
                        }
                    });
                    tList.sort((a,b) => a['value'] < b['value'] ? -1 : 1);
                    return tList
                } else {
                    return [];
                }
            }
        }
    }
</script>

<style scoped>

</style>
