// import {default
import store from "@/Store/store";
import {newReq, newReqDownloadFile, newReqUpload, newReqUploadMultipleFormValues} from '@/axiosRequest';
import {isToday, isYesterday, format, subSeconds, subMinutes, isSameYear, isThisYear} from "date-fns";
import differenceInDays from "date-fns/difference_in_days";
import {io} from "socket.io-client";
import {EventEmitter} from "events";
import differenceInHours from "date-fns/difference_in_hours";
import differenceInMinutes from "date-fns/difference_in_minutes";
import { v4 as uuidv4 } from 'uuid';

let socket;
let socketAuth;
export const rtcmRelay = new EventEmitter();

export async function connectSocket() {
  try {
    const {host, port, auth} = await newReq('POST', 'chat/getSocketServer');
    socketAuth = auth
    if (socket) {
      socket.removeAllListeners();
      socket.offAny();
      socket.disconnect();
    }
    socket = io(
      `https://${host}${port ? ":" + port : ''}/user`,
      {
        auth: { token: socketAuth },
        withCredentials: true
      }
    );
    addSocketEventHandlers()
    socket.connect();
  } catch (e) {
    console.log(e)
    throw e
  }
}

function addSocketEventHandlers() {
  if (!socket)
    return
  socket.removeAllListeners();
  socket.offAny();
  socket.on("connect", () => {
    console.log("CONNECTED chat Socket")
  });

  socket.on("upsertChat", socketChatUpsert);
  socket.on("lastSeen", sendLastSeenUpdate);
  socket.on("chatEvent", sendChatUpdate);
  socket.on("taskClosed", sendCloseTaskUpdate);
}

export async function markMessagesAsRead(chatKey) {
  try {
    const res = await newReq('POST', `chat/markUserChannelMessagesAsRead`, {chatKey})
  } catch (e) {
    console.log(e)
  }
}


export async function loadStartupData() {
  try {
    connectSocket();
    store.state.chat.started = true
    const {channels, chats} = await newReq('GET', 'chat/startup');
    store.state.chat.channels = channels;
    store.state.chat.chats = chats.map(chat => new Chat(chat));
  } catch (e) {
    console.log(e)
    store.state.chat.started = false
    throw e
  }
}

export async function loadChannelChats(channelId) {
  try {
    store.state.chat.started = true
    const {chats} = await newReq('POST', 'chat/channelChats', {channelId});
    return chats
  } catch (e) {
    console.log(e)
    store.state.chat.started = false
    throw e
  }
}

export class Chat {
  chatKey = null;
  sentByContact = null;
  fkSentContactId = null;
  fkSentUserId = null;
  message = null;
  attachments = null;
  sentByStr = null;
  fkMessageId = null;
  lastSent = null;
  fkRefTaskId = null;
  fkRefChannelId = null;
  fkRefContactId = null;
  unread = null;
  count = null;
  taskTitle = null;
  chanelInternalName = null;
  chanelExternalName = null;
  contactName = null;
  type = null;
  archived = false;
  archivedBy = null;
  archivedOn = null;

  constructor(obj) {
    Object.assign(this, obj)
    this.lastSent = new Date(this.lastSent)
  }

  get isArchived() {
    return !!this.archived
  }

  get dateTitle() {
    if (isToday(this.lastSent))
      return 'Today'
    else if (isYesterday(this.lastSent))
      return 'Yesterday'
    else if (differenceInDays(new Date(), this.lastSent) < 6)
      return format(this.lastSent, 'dddd')
    else
      return format(this.lastSent, 'YYYY/MM/DD')
  }

  getDisplayString() {

  }

  get sentAsDate() {
    if (!this.lastSent)
      return new Date(0)
    if (typeof this.lastSent === 'string')
      this.lastSent = new Date(this.lastSent)
    return this.lastSent;
  }

  get dateTitleShort() {
    if (isToday(this.lastSent))
      return format(this.lastSent, 'HH:mm')
    else if (isYesterday(this.lastSent))
      return 'Yesterday'
    else if (differenceInDays(new Date(), this.lastSent) < 6)
      return format(this.lastSent, 'ddd')
    else
      return format(this.lastSent, 'MM/DD')
  }

  get channelRef() {
    if (!this.fkRefChannelId)
      return null

    return store.getters["chat/channelsMap"].get(this.fkRefChannelId)
  }
}

function socketChatUpsert(chats) {
  for (const newChat of chats) {
    const existingChat = store.getters["chat/chatsMapByChatKey"].get(newChat.chatKey)
    if (existingChat) {
      Object.assign(existingChat, newChat)
      rtcmRelay.emit('chatUpdate', existingChat)
    } else {
      const pushChat = new Chat(newChat)
      store.state.chat.chats.push(pushChat)
      rtcmRelay.emit('chatUpdate', existingChat)
    }
  }
}

export function startLastSeenSubscription(contactId) {
  socket.emit("lastSeenSubscription", {
    action: "start",
    contactId
  })
}

export function stopLastSeenSubscription(contactId) {
  socket.emit("lastSeenSubscription", {
    action: "stop",
    contactId
  })
}

function sendLastSeenUpdate(update) {
  rtcmRelay.emit('lastSeen', {
    ...update,
    lastSeen: update.lastSeen ? new Date(update.lastSeen) : undefined
  })
}


export function startChatSubscription(chatKey) {
  socket.emit("chatSubscription", {
    action: "start",
    chatKey
  })
}

export function stopChatSubscription(chatKey) {
  socket.emit("chatSubscription", {
    action: "stop",
    chatKey
  })
}

function sendChatUpdate(update) {
  rtcmRelay.emit('chatEvent', update)
}

function sendCloseTaskUpdate(update) {
  rtcmRelay.emit('taskClosed', update)
}



export function formatLastSeen(lastSeen) {
  if (!lastSeen)
    return 'Never'

  const refDate = typeof lastSeen === 'string' ? new Date(lastSeen) : lastSeen

  if (differenceInMinutes(new Date(), refDate) <= 10) // Just Now
    return "Just now"
  else if (differenceInMinutes(new Date(), refDate) < 60) // 21 Minutes ago
    return `${differenceInMinutes(new Date(), refDate)} Minutes ago`
  else if (isToday(refDate)) // 15:55
    return format(refDate, "HH:mm")
  else if (isYesterday(refDate)) // Yesterday 15:55
    return `Yesterday ${format(refDate, "HH:mm")}`
  else if (differenceInDays(new Date(), refDate) < 6) // Friday 15:55
    return format(refDate, "dddd HH:mm")
  else if (isThisYear(refDate)) // Fri 6th May
    return format(refDate, "ddd Do MMMM")
  else // Fri 6th May 2021
    return format(refDate, "ddd Do MMMM YYYY")
}


export class OutboxMessage {
  refId;
  chatKey;
  message = null;
  attachments= null;

  constructor({refId, chatKey, message, attachments}) {
    this.chatKey = chatKey || null
    this.refId = refId || uuidv4();
    this.message = message || null;
    this.attachments = attachments || null;
  }
}


export class ChatMessage {
  id = null;//: 2,
  chatKey = null;//: "c-1-21474",
  fkRefTaskId = null;//: null,
  fkRefChannelId = null;//: 1,
  fkRefContactId = null;//: 21474,
  sentByContact = null;//: 0,
  fkContact = null;//: null,
  fkUser = null;//: 1,
  createdAt = null;//: "2024-05-16T11:04:22.000Z",
  attachments = null;//: null,
  message = null;//: "YEEEEEEAAAA BUDDY!",
  isAuthor = null;//: 0,
  authorName = null;//: "Calvin Coomer"

  constructor(obj) {
    Object.assign(this, obj)
    this.createdAt = new Date(this.createdAt)
    this.timeStr = format(this.createdAt, 'HH:mm')
    this.timeDateStr = format(this.createdAt, 'YYYY-MM-DD HH:mm')
  }


}