<template>
    <v-layout column fill-height style="overflow-y: auto">

        <v-flex shrink>
            <v-text-field
                    label="Search For Field"
                    single-line
                    v-model="searchString"
                    color="primary"
                    style="width: 100%"
                    class="ma-0 pa-0 px-1 pt-2"
                    type="email"
                    clearable
                    prepend-icon="search"
                    @input="dropCollapse"
                    hide-details
            ></v-text-field>
        </v-flex>


        <v-flex xs12 style="overflow-y: auto" class="scroll pt-2">
<!--steps-->
            <template v-for="(step, i) in orderedSteps">

                <v-layout align-center row :key="'configureField' + step.id" @click="droppedStep(i)" style="cursor: pointer">
                    <v-flex shrink class="px-1">
                        <v-btn
                                flat
                                icon
                                small
                                color="primary"
                                class="pa-0 ma-0"
                        >
                            <v-icon>{{ dropped[i] ? 'arrow_drop_up' : 'arrow_drop_down'}}</v-icon>
                        </v-btn>
                    </v-flex>
                    <v-flex xs12>
                        <span class="body-1">{{ step.description }}</span>
                    </v-flex>
                </v-layout>

                <template v-if="dropped[i]">
                    <template v-for="(field) in filteredFields">
                        <div
                                @drag="$event.preventDefault()"
                                @dragstart="setDataTransfer($event, step, field)"
                                :draggable="dragFields ? field.fieldType <= 5 || field.fieldType === 22 : false"
                                :key="'configureFieldField' + field.id"
                        >
                            <v-layout
                                    v-if="field.fk_step == step.id"
                                    class="pl-3 pb-2"
                                    align-center
                                    @click="dragFields ? (field.fieldType >= 6 && field.fieldType !== 22 ? fieldSelected(field) : '') : fieldSelected(field)"
                                    style="cursor: pointer"
                            >
                                <v-flex shrink>
                                    <v-btn
                                            icon
                                            flat
                                            color="primary"
                                            small
                                            class="pa-0 ma-0"
                                    >
                                        <v-icon>{{ icon == null ? (value != null && typeof value.id != 'undefined' && value.id == field.id ? 'check_box' : 'check_box_outline_blank') : (icon != 'label' ? icon : (field.fieldType >= 6 && field.fieldType !== 22 ? 'add' : icon))}}</v-icon>
                                    </v-btn>
                                </v-flex>
                                <v-flex xs12 class="pl-2">
                                    <span class="body-1">{{ field.description }}</span>
                                </v-flex>
                            </v-layout>
                        </div>

                    </template>
                </template>

            </template>
        </v-flex>


    </v-layout>
</template>

<script>
    export default {
        props: {
            userInfo: Object,
            fields: Array,
            steps: Array,
            value: Object,
            typeFilter: {
                type: Array,
                value: [],
            },
            icon: {
                type: String,
                value: null
            },
            dragFields: {
                type: Boolean,
                value: false
            }
        },
        data() {
            return {
                dropped: [],
                addedFields: [],
                searchString: ''
            }
        },
        created() {
            this.orderedSteps.forEach(() => {this.dropped.push(false)});
        },
        methods: {

            setDataTransfer(event, step, field) {
                event.dataTransfer.setData('text', `&nbsp;<span id="1-${field.id}" style="background: #F44336; border-radius: 4px;  color: white; text-align: center; padding: 1px 4px; font-size: 11px; outline: none">${field.description}</span>&nbsp;`)
            },

            fieldSelected(field) {
                this.$emit('input', field)
            },

            droppedStep(i) {
                this.dropped[i] = !this.dropped[i]
                this.$forceUpdate();
            },

            dropCollapse() {

                if (this.searchString != null && this.searchString != '') {
                    this.dropped.forEach((value, i) => {
                        this.dropped[i] = true
                    });
                } else {
                    this.dropped.forEach((value, i) => {
                        this.dropped[i] = false
                    });
                }
                this.$forceUpdate();
            }

        },
        computed: {
            filteredFields() {
                let tReturn = [];

                this.fields.forEach(field => {
                    if (this.searchString == null || this.searchString == '' || field.description.toLowerCase().includes(this.searchString.toLowerCase())) {

                        if (this.typeFilter.length > 0) {
                            if (this.typeFilter.includes(field.fieldType)) {
                                tReturn.push(field)
                            }
                        } else {
                            tReturn.push(field)
                        }
                    }
                });

                tReturn.sort((a,b) => a['field_order'] < b['field_order'] ? -1 : 1);

                return tReturn
            },

            orderedSteps() {
                let tReturn = [];

                this.steps.forEach(step => {

                    let fieldCount = 0;

                    this.filteredFields.forEach(field => {
                        if (field.fk_step == step.id) {++fieldCount}
                    })

                    if (fieldCount > 0) {
                        tReturn.push(step)
                    }
                });

                tReturn.sort((a,b) => a['field_order'] < b['field_order'] ? -1 : 1);

                return tReturn
            },
        }
    }
</script>

<style scoped>

</style>
