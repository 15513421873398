<template>
  <div>
    <div @click="shownRelay = !shownRelay">
      <slot name="activator"></slot>
    </div>
    
    <v-dialog
        v-model="shownRelay"
        :width="500"
        max-width="90%"
        persistent
    >
      
      <v-card v-if="shownRelay" class="scrollable c-d-flex c-flex-column" style="">
        
        <div class="c-flex-grow-0 px-3 primary white--text dark c-d-flex c-align-center">
          <div>
            <slot name="titleIcon">
              <v-icon color="white" left>contact_mail</v-icon>
            </slot>
          </div>
          <div class="c-flex-grow-1 py-2 subheading">
            <slot name="title">Link or Create New Contact</slot>
          </div>
          <div>
            <v-btn
                color="white"
                icon
                flat
                @click="shownRelay = false"
                class="mx-0"
            >
              <v-icon>close</v-icon>
            </v-btn>
          </div>
        </div>
        <v-form v-model="checkReady">
          
          <!--        <div class="c-flex-grow-1 c-d-flex of-y">-->
          <transition
              mode="out-in"
              enter-active-class="slide-in-right"
              leave-active-class="slide-out-left"
          >
            <v-card-text v-if="existingContact" key="existing">
              
              
              <div class="text-uppercase font-weight-bold text-xs-center amber--text text--darken-2 pb-2">
                Contact already Exists
<!--                <template v-if="pushEmail">-->
<!--                  <br><span class="caption">Email address will be added when linked</span>-->
<!--                </template>-->
              </div>
              
<!--              <div class="c-d-flex c-align-center">-->
<!--                <div class="pr-2">-->
<!--                  <v-icon left large>contact_mail</v-icon>-->
<!--                </div>-->
<!--                <div>-->
<!--                  <div class="title">-->
<!--                    {{ existingContact.fullName }}-->
<!--                  </div>-->
<!--                  <div class="subheading" v-if="existingContact.email && existingContact.email.length">-->
<!--                    {{ existingContact.email.join(',') }}-->
<!--                  </div>-->
<!--                  <div class="subheading">-->
<!--                    {{ existingContact.mobile }}-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
              
              <div class="c-d-flex c-align-center">
                <v-btn
                    icon
                    flat
                    color="primary"
                    class="ml-0 pl-0"
                >
                  <v-icon>contact_mail</v-icon>
                </v-btn>
                
                
                
                <v-text-field
                    class="c-flex-grow-1"
                    label="Contact"
                    :value="existingContact.fullName"
                    readonly
                    :messages="[`${existingContact.mobile ? existingContact.mobile + ' ' : ''}${existingContact.mobile && Array.isArray(existingContact.email) && existingContact.email.length ? ' | ' : ''}${Array.isArray(existingContact.email) && existingContact.email.length ? existingContact.email.join(', ') : ''}`]"
                    :rules="[validationRules.required]"
                >
                </v-text-field>
              </div>
              
              
              <div class="c-d-flex c-align-center pt-3" v-if="existingLinks && existingLinks.length">
                <v-btn
                    icon
                    flat
                    color="primary"
                    class="ml-0 pl-0"
                >
                  <v-icon>link</v-icon>
                </v-btn>
                
                <div class="c-flex-grow-1">
                  <div class="pt-1 input-box">
                    <div :class="$store.state.darkTheme ? 'theme--dark' : 'theme--light'" class="v-label v-label--active master-title mb-1" style="font-size: 10.5px">Existing links</div>
                    <div v-for="link in existingLinks" class=" body-1 c-d-flex c-align-center">
                      <v-icon small color="" class="mr-2">folder</v-icon>
                      {{link.description}}
                    </div>
                    <div class="line mt-1"></div>
                  </div>
                </div>
              </div>
              
              <div class="c-d-flex c-align-center py-3">
                <v-btn
                    class="ml-0 pl-0"
                    icon
                    flat
                    color="amber darken-2"
                    @click="showFolderSelect = true"
                    :disabled="loadingLinkFolderInfo"
                    :loading="loadingLinkFolderInfo"
                >
                  <v-icon>folder</v-icon>
                </v-btn>
                <v-text-field
                    placeholder="Click to Select"
                    persistant-placeholder
                    class="c-flex-grow-1"
                    label="Link to Folder"
                    :value="loadingLinkFolderInfo ? null : (linkFolder && linkFolderInfo ? linkFolderInfo.description : null)"
                    readonly
                    @click="!forceFolder ? showFolderSelect = true : () => {}"
                    :rules="[validationRules.required]"
                >
                </v-text-field>
              </div>
            </v-card-text>
            
            <v-card-text v-else-if="newContact" key="new">
              
              
              <div class="text-uppercase font-weight-bold text-xs-center amber--text text--darken-2 pb-2">
                Create new Contact
<!--                <template v-if="pushEmail">-->
<!--                  <br><span class="caption">Email address will be added when linked</span>-->
<!--                </template>-->
              </div>
              
              
              <!--              <div class="c-d-flex c-align-center">-->
              <!--                <div class="pr-2">-->
              <!--                  <v-icon left large>contact_mail</v-icon>-->
              <!--                </div>-->
              <!--                <div>-->
              <!--                  <div class="title">-->
              <!--                    {{ existingContact.fullName }}-->
              <!--                  </div>-->
              <!--                  <div class="subheading" v-if="existingContact.email && existingContact.email.length">-->
              <!--                    {{ existingContact.email.join(',') }}-->
              <!--                  </div>-->
              <!--                  <div class="subheading">-->
              <!--                    {{ existingContact.mobile }}-->
              <!--                  </div>-->
              <!--                </div>-->
              <!--              </div>-->
              
              <div class="">
                <div class="c-d-flex c-align-center">
                  <v-btn
                      class="ml-0 pl-0"
                      icon
                      flat
                      color="primary"
                  >
                    <v-icon>phone</v-icon>
                  </v-btn>
                  <v-text-field
                      v-if="checkObj.mobile"
                      label="Mobile"
                      :value="checkObj.mobile"
                      readonly
                  >
                  </v-text-field>
                </div>
                
                <div class="c-d-flex c-align-center">
                  <v-btn
                      class="ml-0 pl-0"
                      icon
                      flat
                      color="primary"
                  >
                    <v-icon>contact_mail</v-icon>
                  </v-btn>
                  <v-text-field
                      label="Full Name"
                      v-model="checkObj.fullName"
                      :disabled="linkingContact"
                      :rules="[validationRules.required]"
                      :autofocus="true"
                  >
                  </v-text-field>
                </div>
                
<!--                <div class="c-d-flex c-align-center">-->
<!--                  <v-btn-->
<!--                      class="ml-0 pl-0"-->
<!--                      icon-->
<!--                      flat-->
<!--                      color="primary"-->
<!--                  >-->
<!--                    <v-icon>email</v-icon>-->
<!--                  </v-btn>-->
<!--                  <v-text-field-->
<!--                      label="Email Address"-->
<!--                      v-model="checkObj.email"-->
<!--                      :disabled="linkingContact"-->
<!--                      :rules="[validationRules.emailNotRequired]"-->
<!--                  >-->
<!--                  </v-text-field>-->
<!--                </div>-->
              </div>
              
              <div class="c-d-flex c-align-center pb-3">
                <v-btn
                    class="ml-0 pl-0"
                    icon
                    flat
                    color="amber darken-2"
                    @click="showFolderSelect = true"
                    :disabled="loadingLinkFolderInfo"
                    :loading="loadingLinkFolderInfo"
                >
                  <v-icon>folder</v-icon>
                </v-btn>
                <v-text-field
                    placeholder="Click to Select"
                    persistant-placeholder
                    class="c-flex-grow-1"
                    label="Link to Folder"
                    :value="loadingLinkFolderInfo ? null : (linkFolder && linkFolderInfo ? linkFolderInfo.description : null)"
                    readonly
                    @click="!forceFolder ? showFolderSelect = true : () => {}"
                    :rules="[validationRules.required]"
                >
                </v-text-field>
              </div>
              
            </v-card-text>
            
            <v-card-text v-else key="check">
              <v-text-field
                  prepend-icon="phone"
                  :autofocus="true"
                  label="Mobile"
                  v-model="checkObj.mobile"
                  :disabled="checkingContact"
                  :rules="[validationRules.cellNo]"
              >
              </v-text-field>
            </v-card-text>
          
          </transition>
        </v-form>
        
        
        <v-card-actions class="c-d-flex c-justify-end" v-if="existingContact">
          
          <v-btn
              :disabled="linkingContact"
              :loading="linkingContact"
              @click="existingContact = null"
              flat
          >
            Back
          </v-btn>
          
          <v-btn
              :disabled="linkingContact"
              :loading="linkingContact"
              @click="shownRelay = false"
              flat
          >
            Cancel
          </v-btn>
          
          <v-btn
              :disabled="!checkReady || linkingContact"
              :loading="linkingContact"
              color="secondary"
              @click="linkContact"
          >
            Link Contact
          </v-btn>
          
        </v-card-actions>
        
        <v-card-actions class="c-d-flex c-justify-end" v-else-if="newContact">
          
          <v-btn
              :disabled="linkingContact"
              :loading="linkingContact"
              @click="newContact = false"
              flat
          >
            Back
          </v-btn>
          
          <v-btn
              :disabled="linkingContact"
              :loading="linkingContact"
              @click="newContact = false"
              flat
          >
            Cancel
          </v-btn>
          
          <v-btn
              :disabled="!checkReady || linkingContact"
              :loading="linkingContact"
              color="secondary"
              @click="createContact"
          >
            Create and Link
          </v-btn>
        
        </v-card-actions>
        
        <v-card-actions class="c-d-flex c-justify-end" v-else>
          <v-btn
              :disabled="checkingContact"
              :loading="checkingContact"
              @click="shownRelay = false"
              flat
          >
            Cancel
          </v-btn>
          <v-btn
              :disabled="!checkReady || checkingContact"
              :loading="checkingContact"
              color="secondary"
              @click="checkContact"
          >
            Next
          </v-btn>
        </v-card-actions>
        
        
        <!--        </div>-->
        <folder-dlg
            :show-add-contact="false"
            v-if="showFolderSelect && !forceFolder"
            :show-dialog="showFolderSelect"
            @dismiss="showFolderSelect = false"
            :select-dlg="true"
            :user-info="userInfo"
            :folders="foldersSelect"
            dlg-type="allFoldersSelect"
            @appendFolderData="foldersSelect = $event"
            @input="selectFolder($event)"
            :local-data="localData"
        />
        
      </v-card>
    </v-dialog>
  </div>

</template>

<script>
import draggable from "vuedraggable";
import {mapGetters} from "vuex";
import {arrSplice, validationRules} from "@/codeFunctions";
import FolderDlg from "@/Folders/FolderDlg";

export default {
  name: 'CreateLinkContact',
  components: {
    FolderDlg,
    draggable,
  },
  props: {
    shown: {
      type: Boolean,
      default: false
    },
    multiSelect: {
      type: Boolean,
      default: false
    },
    value: {
      type: [Array, Number, null],
      default: null
    },
    localData: Object,
    forceFolder: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      arrSplice,
      selfShown: false,
      loading: false,
      selectLists: [],
      customFields: new Map(),
      curPage: 0,
      droppedContacts: [],
      customFieldRef: new Map(),
      checkObj: {
        fullName: null,
        mobile: null,
        email: null
      },
      checkReady: false,
      checkingContact: false,
      newContact: false,
      existingContact: null,
      linkFolder: null,
      linkFolderInfo: null,
      loadingLinkFolderInfo: null,
      showFolderSelect: false,
      foldersSelect: {
        folderGroups: [],
        folders: []
      },
      pushEmail: null,
      existingLinks: [],
      linkingContact: false
    }
  },
  watch: {
    shownRelay: {
      immediate: true,
      handler(val) {
        this.internalSelected = this.value
        
        
        this.loading = false
        this.selectLists = []
        this.customFields = new Map()
        this.curPage = 0
        this.droppedContacts = []
        this.customFieldRef = new Map()
        this.checkObj = {
          fullName: null,
          mobile: null,
          email: null
        }
        this.checkReady = false
        this.checkingContact = false
        this.newContact = false
        this.existingContact = null
        this.linkFolder = null
        this.linkFolderInfo = null
        this.loadingLinkFolderInfo = null
        this.showFolderSelect = false
        this.foldersSelect = {
          folderGroups: [],
          folders: []
        }
        this.pushEmail = null
        this.existingLinks = []
        this.linkingContact = false
        
        
        if (val)
          this.init()
      }
    },
    linkFolder: {
      immediate: true,
      handler(val) {
        this.loadFolderInfo()
      }
    }
  },
  methods: {
    // updateContact(contact) {
    //   const existingContact = this.contacts.find(val => val.id === contact.id)
    //   if (existingContact)
    //     Object.assign(existingContact, contact)
    //   else {
    //     this.contacts.push(existingContact)
    //     if (!this.internalSelected.includes(contact.id))
    //       if (!this.multiSelect)
    //         this.internalSelected = [contact.id]
    //       else {
    //         this.internalSelected.push(contact.id)
    //         this.droppedContacts.push(contact.id)
    //       }
    //   }
    // },
    
    // listScrolled(ev) {
    //   if (!this.loading && ev.srcElement.scrollTop + ev.srcElement.clientHeight >= ev.srcElement.scrollHeight) {
    //     this.loadMore();
    //   }
    // },
    
    async createContact() {
      try {
        this.linkingContact = true
        const updatedContact = await this.$newReq('POST', `contacts/create`, {
          folderId: this.linkFolder,
          fullName: this.checkObj.fullName,
          mobile: this.checkObj.mobile,
          email: this.checkObj.email,
        })
        this.$emit('created', updatedContact)
        this.$emit('input', updatedContact)
        this.shownRelay = false
      } catch (e) {
        console.log(e)
        this.$snack.networkError()
      } finally {
        this.linkingContact = false
      }
    },
    
    async linkContact() {
      try {
        this.linkingContact = true
        const updatedContact = await this.$newReq('POST', `contacts/link`, {
          folderId: this.linkFolder,
          pushEmail: this.pushEmail,
          contactId: this.existingContact.id
        })
        this.$emit('linked', updatedContact)
        this.$emit('input', updatedContact)
        this.shownRelay = false
      } catch (e) {
        console.log(e)
        this.$snack.networkError()
      } finally {
        this.linkingContact = false
      }
    },
    
    selectFolder(val) {
      this.linkFolder = val.id
    },
    
    async loadFolderInfo() {
      
      this.linkFolderInfo = null;
      if (!this.linkFolder)
        return null
      
      try {
        this.loadingLinkFolderInfo = true
        this.linkFolderInfo = await this.$newReq('GET', `contacts/link/folderInfo/${this.linkFolder}`)
      } catch (e) {
        console.log(e)
        this.$snack.networkError()
      } finally {
        this.loadingLinkFolderInfo = false
      }
    },
    
    async init() {
      
      try {
        this.loading = true
        this.checkObj = {
          fullName: null,
          mobile: null,
          email: null
        }
        const {customFields} = await this.$newReq('GET', `contacts/browse/startup`)
        this.customFieldRef = new Map(customFields.map(val => [val.id, val]))
        if (this.forceFolder) {
          this.linkFolder = this.forceFolder
        }
        // await this.loadMore()
      } catch (e) {
        console.log(e)
        this.$snack.networkError()
      } finally {
        this.loading = false
      }
    },
    
    async checkContact() {
      try {
        this.checkingContact = true
        this.existingLinks = []
        const result = await this.$newReq('POST', `contacts/check`, this.checkObj)
        
        if (!result.exists)
          return this.newContact = true
        
        if (result.existingLinks && result.existingLinks.length)
          this.existingLinks = result.existingLinks
        
        this.existingContact = result.contact
        
        const pushMail = (this.checkObj.email || '').toLowerCase().trim()
        
        if (pushMail && (!this.existingContact.email || !this.existingContact.email.length || !this.existingContact.email.includes(pushMail)))
          this.pushEmail = pushMail
        
      } catch (e) {
        console.log(e)
        this.$snack.networkError()
      } finally {
        this.checkingContact = false
      }
    },
    
    // async loadMore(clear = false) {
    //   try {
    //     this.loading = true
    //
    //     if (clear) {
    //       this.contacts = []
    //     }
    //
    //     const receivedContacts = await this.$newReq('POST', `contacts/browse/search`, {
    //       userId: this.userInfo.userID,
    //       searchStr: this.searchStr,
    //       skip: this.contacts.length || null,
    //       limit: null
    //     })
    //     this.contacts.push(...receivedContacts)
    //   } catch (e) {
    //     console.log(e)
    //     this.$snack.networkError()
    //   } finally {
    //     this.loading = false
    //   }
    // }
  },
  computed: {
    validationRules() {
      return validationRules
    },
    ...mapGetters('userInfo', ['userInfo']),
    shownRelay: {
      get() {
        return this.shown || this.selfShown
      },
      set(val) {
        this.selfShown = val
        this.$emit("update:shown", val)
      }
    },
  },
}
</script>

<style scoped>

.input-box .line{
  height: 1px;
  width: 100%;
  background: #949494
}

.input-box:hover .master-title,
.input-box:focus-within .master-title {
//font-weight: bold;
  color: var(--v-primary-base);
}

.input-box:hover .line,
.input-box:focus-within .line {
//font-weight: bold;
  background: var(--v-primary-base);
}
</style>