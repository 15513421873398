<template>
    <v-btn
            small
            flat
            color="primary"
            class="ma-0 pa-0"
            style="height: 20px"
            :disabled="loading"
            :loading="loading"
            @click="goToTask()"
    >
        <v-icon left small v-if="checkTask">done</v-icon>
        {{'#' + taskID}}
    </v-btn>
</template>

<script>
    import {codeBaseMixin} from "../../../codeBaseMixin";
    import {eventBus} from "../../../main";

    export default {
        mixins: [codeBaseMixin],
        props: {
            taskID: Number,
            userInfo: Object,
            getTaskHeader: Function,
            lastTask: Array
        },
        mounted() {

        },
        data() {
            return {
                loading: false,
            }
        },

        methods: {
            goToTask() {
                this.loading = true;
                this.getTaskHeader(this.taskID)
                    .then(task => {
                        if (task.access) {
                            this.$emit('showReports', this.taskID);
                            eventBus.$emit('gotoTask', (task));
                        } else {
                            this.showSnack('Error', 'Access Is Denied', 'Close', 'red')
                        }

                        this.loading = false;
                    })
                    .catch(err => {
                        console.log(err);
                        this.showSnack('Error', 'Error Fetching Task', 'Close', 'red')
                        this.loading = false;
                    })
            },
        },
        computed: {
            checkTask() {
                return this.lastTask.includes(this.taskID)
            }
        }
    }
</script>

<style scoped>

</style>
