<template>
    <v-layout row>
        <v-flex>
            <v-dialog v-model="menu" style="width: 100%" class="text-xs-center" max-width="300" :disabled="readOnly">
                <v-layout row align-center slot="activator">
                    <v-flex xs12>
                        <v-text-field
                                append-outer-icon="calendar_today"
                                label="Select A Date"
                                :clearable="!readOnly"
                                color="primary"
                                flat
                                :value="field.value == null ? '' : getLongDate(field.value)"
                                hide-details
                                class="pa-0 ma-0"
                                single-line
                                readonly
                                @click:clear="field.value = null"
                        >
                        </v-text-field>
                    </v-flex>
                </v-layout>

                <v-layout column fill-height align-center justify-center>
                    <v-flex shrink>
                        <v-card class="text-xs-center">
                            <!--Date-->
                            <v-date-picker
                                    v-if="selectDate"
                                    id="calendarDiv"
                                    v-model="date"
                                    show-current
                                    color="primary"
                                    @input="dateInput"
                                    width="300"
                            ></v-date-picker>
                            <!--TIme-->
                            <v-time-picker
                                    v-if="!selectDate"
                                    v-model="time"
                                    type="month"
                                    color="primary"
                                    width="300"
                            ></v-time-picker>
                            <!--Done Button-->
                            <v-btn
                                    v-if="!selectDate"
                                    color="primary"
                                    flat
                                    @click="menu = false"
                            >
                                Done
                            </v-btn>
                        </v-card>
                    </v-flex>
                </v-layout>
            </v-dialog>
        </v-flex>
    </v-layout>
</template>

<script>
    import {codeBaseMixin} from "../../../../../../../codeBaseMixin";

    export default {
        mixins: [codeBaseMixin],
        props: {
            field: Object,
            readOnly: {
                default: false,
                type: Boolean
            }
        },
        data() {
            return {
                menu: false,
                selectDate: true,
                bindData: true
            }
        },
        methods: {
            updateValue(newVal) {
                this.field.value = newVal
            },
            dateInput() {
                this.selectDate = false;
                this.bindData = false
            }
        },
        watch: {
            menu() {
                this.selectDate = true;
            }
        },
        computed: {
            date: {
                // getter
                get: function () {

                    if (this.field.value == null) {
                        let sString = this.getSqlDate(new Date()) + ' ' + this.getFormattedTime(new Date()) + ':00';
                        return sString
                    } else {
                        return this.field.value
                    }
                },
                // setter
                set: function (newValue) {
                    this.field.value = newValue;
                    this.$emit('input');
                }
            },

            time: {
                // getter
                get: function () {

                    if (this.field.value == null) {
                        return '00:00'
                    } else {
                        return this.getFormattedTime(this.field.value);
                    }
                },
                // setter
                set: function (newValue) {
                    this.field.value = this.getSqlDate(this.date) + ' ' + newValue + ':00';
                    this.$emit('input');
                }
            },
        }
    }
</script>

<style scoped>

</style>
