<template>
    <div style="overflow-x: hidden" class="card-back">

<!--Filter Options-->
        <div class="pa-1">
            <full-task-filter-options v-model="filterOptions" :all-dropped="allDropped" :state-filter="stateFilter"/>
        </div>
<!--Filter Search Menu-->
        <v-menu
                offset-y
                v-model="searchMenuShown"
                full-width
                :close-on-content-click="false"
                style="overflow-y: hidden;"
                :lazy="true"
        >
            <div class="py-0 px-2 pb-1" slot="activator" style="width: 100%">
                <v-text-field
                        prepend-inner-icon="search"
                        label="Search"
                        class="pa-0 ma-0"
                        hide-details
                        single-line
                        @keydown.down="moveCursorDown()"
                        @keydown.up="moveCursorUp()"
                        @keydown.enter="runMenuCallbackMethod()"
                        v-model="refString"
                        ref="refArea"
                        @input="searchMenuShown = true"
                >
                    <template slot="append-outer" v-if="filters.length > 0">
                        <v-btn small flat color="red" icon class="pa-0 ma-0" @click="filters = []; $refs.refArea.focus(); searchMenuShown = false"><v-icon>close</v-icon></v-btn>
                    </template>
                </v-text-field>
            </div>


            <v-card style="overflow-y: hidden; overflow-y: auto; max-height: 450px" v-if="searchMenuShown">
                <template v-for="(item) in menuItemsWithIndex">
                    <div
                            :key="item.id"
                    >
                        <full-task-filter-item
                                :item="item"
                                :click="runMenuCallbackMethod"
                                :highlighted-index="highlightedIndex"
                                @highlight="highlightedIndex = $event"
                        />
                    </div>
                </template>

                <template v-if="displayLimit < menuItems.length - 1">

                    <v-divider></v-divider>
                    <div style="width: 100%;" class="text-xs-center pa-2">
                        <v-btn small block flat color="secondary" @click="displayLimit = displayLimit + displayLimitDefault" class="py-0 ma-0">Load More Items</v-btn>
                    </div>
                </template>
            </v-card>
        </v-menu>
<!--Filter Chips Area-->
        <v-layout row wrap style="overflow-x: hidden" class="px-2">
            <v-flex shrink v-for="(filter, i) in filters" class="pb-1 pr-2" :key="i">

                <v-layout row align-center class="white--text">
                    <v-flex shrink class="caption pl-1 primary"
                            style="border-bottom-left-radius: 3px; border-top-left-radius: 3px;">
                        <v-icon v-if="filter.type == 'f'" small style="margin-bottom: -1px" color="white">folder</v-icon>
                        <v-icon v-if="filter.type == 'fg'" small style="margin-bottom: -1px" color="white">folder_shared</v-icon>
                        <v-icon v-if="filter.type == 't'" small  color="white">edit</v-icon>
                        <img v-if="filter.type == 'w'" src="/workflows.svg" alt="" width="13" style="margin-bottom: -2px">
                        <img v-if="filter.type == 'u'" src="/users.svg" alt="" width="13" style="margin-bottom: -2px">
                        <img v-if="filter.type == 's'" src="/users.svg" alt="" width="13" style="margin-bottom: -2px">
                    </v-flex>
                    <v-flex shrink class="caption pl-1 primary wrapTextKeepLine" style="max-width: 150px; white-space: nowrap; overflow-x: hidden;">
                        {{filter.string}}&nbsp;
                    </v-flex>
                    <v-flex shrink class="px-1 close-chip-icon"
                            style="border-bottom-right-radius: 3px; border-top-right-radius: 3px;"
                            @click="removeFilter(filter)"
                    >
                        <div
                                class="caption font-weight-bold"
                        >
                            &times;
                        </div>
                    </v-flex>
                </v-layout>
            </v-flex>
        </v-layout>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex';
    import FullTaskFilterOptions from "./FullTaskFilterOptions";
    import FullTaskFilterItem from "./FullTaskFilterItem";
    import {blankString, arrSplice} from "../../../../codeFunctions";

    export default {
        components: {FullTaskFilterItem, FullTaskFilterOptions},
        props: {
            tasks: Array,
            getTaskHeader: Function,
            stateFilter: {
                type: Boolean,
                default: true
            },
            filterOptions: Object,
        },
        data() {
            return {
                allDropped: false,
                refString: null,
                filters: [],
                maxMenuOptions: 0,
                highlightedIndex: -1,
                mainMenu: [
                    {
                        title: 'Type To Search',
                        iconType: 'icon',
                        icon: 'search',
                        callback: () => {
                            this.refString = '';
                        }
                    },
                    {
                        title: 'Workflows',
                        iconType: 'svg',
                        icon: '../../../workflows.svg',
                        callback: () => {
                            this.refString = 'w:';
                        }
                    },
                    {
                        title: 'Owners',
                        iconType: 'svg',
                        icon: '../../../users.svg',
                        callback: () => {
                            this.refString = 'u:';
                        }
                    },
                    {
                        title: 'Sub Owners',
                        iconType: 'svg',
                        icon: '../../../sub_users.svg',
                        callback: () => {
                            this.refString = 's:';
                        }
                    },
                    {
                        title: 'Folder Groups',
                        iconType: 'icon',
                        icon: 'folder_shared',
                        callback: () => {
                            this.refString = 'fg:';
                        }
                    },
                    {
                        title: 'Folders',
                        iconType: 'icon',
                        icon: 'folder',
                        callback: () => {
                            this.refString = 'f:';
                        }
                    },
                ],
                resetCalc: 0,
                displayLimitDefault: 50,
                displayLimit: 0,
                searchMenuShown: false,
            }
        },

        watch: {

            "filters": {
                deep: true,
                handler(val) {
                    this.filteredTasksRecalc()
                }
            },

            filterOptions: {
                immediate: true,
                deep: true,
                handler(val) {
                    this.filteredTasksRecalc();
                    this.$emit('settingsUpdated', val)
                }
            },

            searchMenuShown() {
                this.highlightedIndex = -1;
            },

            // preTasks() {
            //     this.setPostTasks(this.filteredTasks.filterAll);
            // },

            menuItems: {
                immediate: true,
                handler() {
                    this.displayLimit = this.displayLimitDefault
                }
            },

            tasks: {
                handler() {
                    this.filteredTasksRecalc();
                }
            }
        },

        methods: {

            filteredTasksRecalc() {
                let newTasks = this.filterTasks(['t', 'w', 'u', 'fg', 'f', 's'])

                return this.filterOptions.orderOption === 0
                    ? this.$emit('taskListUpdate', newTasks.sort((a,b) => a.taskID < b.taskID ? -1 : 1))
                    : this.$emit('taskListUpdate', newTasks.sort((a,b) => a.taskID > b.taskID ? -1 : 1))
            },

            runMenuCallbackMethod(highlightedIndex) {
                if (
                    (typeof highlightedIndex == 'undefined' && this.highlightedIndex >= 0 && this.highlightedIndex <= this.maxMenuOptions && this.searchMenuShown == true)
                    ||
                    (typeof highlightedIndex != 'undefined' && highlightedIndex >= 0 && highlightedIndex <= this.maxMenuOptions && this.searchMenuShown == true)
                ) {
                    let item = this.menuItems[typeof highlightedIndex != 'undefined' ? highlightedIndex : this.highlightedIndex];

                    if (typeof item.callback != 'undefined') {
                        item.callback();
                    }
                    this.$refs.refArea.focus();
                    this.$nextTick(() => {
                        if (typeof item.count !== 'undefined' || item.title === "Enter To Search") {
                            this.searchMenuShown = false
                        }
                    })
                } else {
                    this.searchMenuShown = true;
                }
            },

            moveCursorUp() {
                this.highlightedIndex = --this.highlightedIndex;
                if (this.highlightedIndex <= 0) {this.highlightedIndex = 0;}
                this.searchMenuShown = true;
            },

            moveCursorDown() {
                this.highlightedIndex = ++this.highlightedIndex;
                if (this.highlightedIndex > this.maxMenuOptions) {this.highlightedIndex = 0;}
                this.searchMenuShown = true;
            },

            setMenuItems(max, index) {
                this.maxMenuOptions = max;
                this.highlightedIndex = index;
            },

            filterTasks(filtersToApply) {
                function lowerCaseStr(val) {
                    try {
                        return val.toLowerCase();
                    } catch (e) {
                        return null;
                    }
                }

                // ['t', 'w', 'u', 'fg', 'f']
                let tFilters = !filtersToApply.includes('t') ? null : this.filters.filter(obj => obj.type === 't').map(obj => {return obj.string.toLowerCase()});
                let wFilters = !filtersToApply.includes('w') ? null : this.filters.filter(obj => obj.type === 'w').map(obj => {return obj.string.toLowerCase()});
                let uFilters = !filtersToApply.includes('u') ? null : this.filters.filter(obj => obj.type === 'u').map(obj => {return obj.string.toLowerCase()});
                let sFilters = !filtersToApply.includes('s') ? null : this.filters.filter(obj => obj.type === 's').map(obj => {return obj.string.toLowerCase()});
                let fgFilters = !filtersToApply.includes('fg') ? null : this.filters.filter(obj => obj.type === 'fg').map(obj => {return obj.string.toLowerCase()});
                let fFilters = !filtersToApply.includes('f') ? null : this.filters.filter(obj => obj.type === 'f').map(obj => {return obj.string.toLowerCase()});

                return this.preTasks.reduce((list, task) => {
                    // Text Filter
                    if (filtersToApply.includes('t') && tFilters.length > 0) {
                        let taskStr = `#${task.taskID}${task.searchStr}${task.title}${task.ownerName}${task.ownerSurname}${task.folderName}${task.folderGroupNames}${task.workflowName}${task.statusText}`.toLowerCase();
                        try {
                            tFilters.forEach(textFilter => {
                                if (taskStr.includes(textFilter) === false) {
                                    throw new Error('No Match Found')
                                }
                            })
                        } catch (e) {
                            return list
                        }
                    }

                    // Workflow Filter
                    if (filtersToApply.includes('w') && wFilters.length > 0) {
                        if (!wFilters.includes(lowerCaseStr(task.workflowName))) {
                            return list
                        }
                    }
                    // User Filter
                    if (filtersToApply.includes('u') && uFilters.length > 0) {
                        if (!uFilters.includes(lowerCaseStr(`${task.ownerName} ${task.ownerSurname}`))) {
                            return list
                        }
                    }
                    // Sub User Filter
                    if (filtersToApply.includes('s') && sFilters.length > 0) {
                        if (!sFilters.includes(lowerCaseStr(`${task.subOwnerName} ${task.subOwnerSurname}`))) {
                            return list
                        }
                    }
                    // Folder Groups
                    if (filtersToApply.includes('fg') && fgFilters.length > 0) {
                        if (!fgFilters.includes(lowerCaseStr(task.folderGroupNames))) {
                            return list
                        }
                    }
                    // Folders
                    if (filtersToApply.includes('fg') && fFilters.length > 0) {
                        if (!fFilters.includes(lowerCaseStr(task.folderName))) {
                            return list
                        }
                    }

                    // Has Not Failed Add Task To List
                    list.push(task);
                    return list
                }, [])

            },

            removeFilter(filter) {
                this.filters = this.filters.reduce((filters, obj) => {
                    if (obj.type == filter.type && `'${obj['string']}'` == `'${filter['string']}'`){
                        return filters
                    } else {
                        filters = filters.concat([obj])
                        return filters
                    }
                }, [])
            },

            displayString(string) {
                if (string.length > 30) {
                    return string.split('').reduce((word, char, index) => {
                        if ([27,28,29].includes(index)) {
                            word.push('.')
                        } else {
                            if (index <= 26) {
                                word.push(char)
                            }
                        }
                        return word
                    }, []).join('')
                } else {
                    return string
                }
            },
        },
        computed: {
            ...mapGetters(['localData']),
            ...mapGetters('userInfo', ['userInfo']),

            preTasks() {
                let filterOptions = this.filterOptions
                return this.tasks
                    .reduce((list, task) => {
                        // User Option
                        if (filterOptions.userOption === 0 && task.ownerID !== this.userInfo.userID) {
                            return list
                        }

                        // Task State
                        switch (filterOptions.stateOption) {
                            case 0: {
                                if(task.taskState === 1 || task.snoozed) {return list}
                                break;
                            }
                            case 1: {
                                if(!task.snoozed) {return list}
                                break;
                            }
                            case 2: {
                                if(task.taskState === 1) {return list}
                                break;
                            }
                            case 3: {
                                if(task.taskState === 0) {return list}
                                break;
                            }
                        }

                        // Task Starred
                        if (filterOptions.starOption === 1 && !task.starred) {
                            return list
                        }

                        list.push(task);
                        return list;
                    }, [])
            },

            menuItemsWithIndex() {
                return this.menuItems.reduce((arr, item, index) => {
                    if (index <= this.displayLimit) {
                        item.id = index;
                        arr.push(item)
                    }
                    return arr
                }, []);
            },

            menuItems() {
                if (this.refString == null || this.refString == '') {
                    this.setMenuItems(this.mainMenu.length -1, 0);
                    return this.mainMenu;
                } else {
                    switch (this.refString.substring(0,2).toLowerCase()) {
                        case 'w:' : {
                            this.setMenuItems(this.workflowMenuItems.length, -1);
                            return this.workflowMenuItems
                        }
                        case 'u:' : {
                            this.setMenuItems(this.userMenuItems.length, -1);
                            return this.userMenuItems;
                        }
                        case 's:' : {
                            this.setMenuItems(this.subUserMenuItems.length, -1);
                            return this.subUserMenuItems;
                        }
                        case 'f:' : {
                            this.setMenuItems(this.folderMenuItems.length, -1);
                            return this.folderMenuItems;
                        }
                        default : {
                            if (this.refString.substring(0,3).toLowerCase() == 'fg:') {
                                this.setMenuItems(this.folderGroupMenuItems.length, -1);
                                return this.folderGroupMenuItems;
                            } else {
                                this.setMenuItems(1,0);
                                return [{
                                    title: 'Enter To Search',
                                    icon: 'keyboard_return',
                                    callback: () => {
                                        if (this.refString != null && this.refString != '') {
                                          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                                            this.filters.push({
                                                type: 't',
                                                string: this.refString
                                            });
                                          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                                            this.refString = '';
                                        }
                                    }
                                }]
                            }
                        }
                    }
                }
            },

            workflowMenuItems() {
                try {
                    let searchString = this.refString.substring(2, this.refString.length);
                    let filteredWorkflows = this.filters.reduce((filteredWorkflows, filter) => {
                        if (filter.type == 'w') {
                            return filteredWorkflows.concat(filter.string)
                        } else {
                            return filteredWorkflows
                        }
                    }, []);
                    let tItems = this.localData.workflows.reduce((workflows, workflow) => {
                        if (workflow.access) {
                            if (!filteredWorkflows.includes(workflow.description) && !workflows.includes(workflow.description)) {
                                if (!blankString(searchString)) {
                                    if (workflow.description.toLowerCase().includes(searchString.toLowerCase())) {
                                        workflows = workflows.concat(workflow.description)
                                    }
                                } else {
                                    workflows = workflows.concat(workflow.description)
                                }
                            }
                        }
                        return workflows
                    }, []);
                    let counts = this.filterTasks(['t', 'u', 'fg', 'f', 's'])
                        .reduce((obj, task) => {
                            if (typeof obj[task.workflowName] == 'undefined') {
                                obj[task.workflowName] = 0
                            }
                            ++obj[task.workflowName];
                            return obj
                        }, {});
                    return tItems
                        .map(item => {
                            return {
                                title: item,
                                count: counts[item],
                                iconType: 'svg',
                                icon: '../../../workflows.svg',
                                callback: () => {
                                  // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                                    this.filters.push({
                                        type: 'w',
                                        string: item
                                    });
                                  // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                                    this.refString = '';
                                }
                            }
                        })
                        .map(obj => {if (typeof obj.count == 'undefined') {obj.count = 0;} return obj})
                        .sort((a,b) => a.title < b.title ? -1 : 1)
                        .sort((a,b) => (a['count'] > 0) > (b['count'] > 0) ? -1 : 1);
                } catch (e) {
                    // eslint-disable-next-line
                    console.log(e)
                    return [];
                }
            },

            userMenuItems() {
                try {
                    let searchString = this.refString.substring(2, this.refString.length);
                    let filteredUsers = this.filters.reduce((filteredUsers, filter) => {
                        if (filter.type == 'u') {
                            return filteredUsers.concat(filter.string)
                        } else {
                            return filteredUsers
                        }
                    }, []);

                    let tItems = this.localData.users.reduce((users, user) => {
                        if (!filteredUsers.includes(`${user.name} ${user.surname}`) && !users.includes(`${user.name} ${user.surname}`)) {
                            if (!blankString(searchString)) {
                                if (`${user.name} ${user.surname}`.toLowerCase().includes(searchString.toLowerCase())) {
                                    users = users.concat(`${user.name} ${user.surname}`)
                                }
                            } else {
                                users = users.concat(`${user.name} ${user.surname}`)
                            }
                        }
                        return users
                    }, []);

                    let counts = this.filterTasks(['t', 'w', 'fg', 'f', 's'])
                        .reduce((obj, task) => {
                            if (typeof obj[`${task.ownerName} ${task.ownerSurname}`] == 'undefined') {
                                obj[`${task.ownerName} ${task.ownerSurname}`] = 0
                            }
                            ++obj[`${task.ownerName} ${task.ownerSurname}`];
                            return obj
                        }, {});

                    return tItems
                        .map(item => {
                            return {
                                title: item,
                                count: counts[item],
                                iconType: 'svg',
                                icon: '../../../users.svg',
                                callback: () => {
                                  // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                                    this.filters.push({
                                        type: 'u',
                                        string: item
                                    });
                                  // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                                    this.refString = '';
                                }
                            }
                        })
                        .map(obj => {if (typeof obj.count == 'undefined') {obj.count = 0;} return obj})
                        .sort((a,b) => a.title < b.title ? -1 : 1)
                        .sort((a,b) => (a['count'] > 0) > (b['count'] > 0) ? -1 : 1);
                } catch (e) {
                    // eslint-disable-next-line
                    console.log(e)
                    return [];
                }
            },

            subUserMenuItems() {
                try {
                    let searchString = this.refString.substring(2, this.refString.length);
                    let filteredUsers = this.filters.reduce((filteredUsers, filter) => {
                        if (filter.type == 's') {
                            return filteredUsers.concat(filter.string)
                        } else {
                            return filteredUsers
                        }
                    }, []);

                    let tItems = this.localData.users.reduce((users, user) => {
                        if (!filteredUsers.includes(`${user.name} ${user.surname}`) && !users.includes(`${user.name} ${user.surname}`)) {
                            if (!blankString(searchString)) {
                                if (`${user.name} ${user.surname}`.toLowerCase().includes(searchString.toLowerCase())) {
                                    users = users.concat(`${user.name} ${user.surname}`)
                                }
                            } else {
                                users = users.concat(`${user.name} ${user.surname}`)
                            }
                        }
                        return users
                    }, []);

                    let counts = this.filterTasks(['t', 'w', 'fg', 'f', 'u'])
                        .reduce((obj, task) => {
                            if (typeof obj[`${task.subOwnerName} ${task.subOwnerSurname}`] == 'undefined') {
                                obj[`${task.subOwnerName} ${task.subOwnerSurname}`] = 0
                            }
                            ++obj[`${task.subOwnerName} ${task.subOwnerSurname}`];
                            return obj
                        }, {});

                    return tItems
                        .map(item => {
                            return {
                                title: item,
                                count: counts[item],
                                iconType: 'svg',
                                icon: '../../../sub_users.svg',
                                callback: () => {
                                  // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                                    this.filters.push({
                                        type: 's',
                                        string: item
                                    });
                                  // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                                    this.refString = '';
                                }
                            }
                        })
                        .map(obj => {if (typeof obj.count == 'undefined') {obj.count = 0;} return obj})
                        .sort((a,b) => a.title < b.title ? -1 : 1)
                        .sort((a,b) => (a['count'] > 0) > (b['count'] > 0) ? -1 : 1);
                } catch (e) {
                    // eslint-disable-next-line
                    console.log(e)
                    return [];
                }
            },

            folderGroupMenuItems() {
                try {
                    let searchString = this.refString.substring(3, this.refString.length);

                    let filteredFolderGroups = this.filters.reduce((filteredFolderGroups, filter) => {
                        if (filter.type == 'fg') {
                            return filteredFolderGroups.concat(filter.string)
                        } else {
                            return filteredFolderGroups
                        }
                    }, []);

                    let tItems = this.localData.folderGroups.reduce((folderGroups, folderGroup) => {
                        if (folderGroup.access) {
                            if (!filteredFolderGroups.includes(folderGroup.description) && !folderGroups.includes(folderGroup.description != null ? folderGroup.description : 'Task Folder')) {
                                if (searchString != '' && searchString != '') {
                                    if ((folderGroup.description != null ? folderGroup.description : 'Task Folder').toLowerCase().includes(searchString.toLowerCase())) {
                                        folderGroups.push(folderGroup.description != null ? folderGroup.description : 'Task Folder')
                                    }
                                } else {
                                    folderGroups = folderGroups.concat(folderGroup.description != null ? folderGroup.description : 'Task Folder')
                                }
                            }
                        }
                        return folderGroups
                    }, []);
                    let counts = this.filterTasks(['t', 'w', 'u', 'f', 's'])
                        .reduce((obj, task) => {
                            if (typeof obj[blankString(task.folderGroupNames) ? 'Task Folder' : task.folderGroupNames] == 'undefined') {
                                obj[blankString(task.folderGroupNames) ? 'Task Folder' : task.folderGroupNames] = 0
                            }
                            ++obj[blankString(task.folderGroupNames) ? 'Task Folder' : task.folderGroupNames];
                            return obj
                        }, {});

                    return tItems.map(item => {
                        return {
                            title: item,
                            count: counts[item],
                            icon: 'folder_shared',
                            callback: () => {
                              // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                                this.filters.push({
                                    type: 'fg',
                                    string: item
                                });
                              // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                                this.refString = '';
                            }
                        }
                    })
                        .map(obj => {if (typeof obj.count == 'undefined') {obj.count = 0;} return obj})
                        .sort((a,b) => a.title < b.title ? -1 : 1)
                        .sort((a,b) => (a['count'] > 0) > (b['count'] > 0) ? -1 : 1);
                } catch (e) {
                    // eslint-disable-next-line
                    console.log(e)
                    return [];
                }
            },

            folderMenuItems() {
                try {
                    let searchString = this.refString.substring(2, this.refString.length);
                    let filteredFolders = this.filters.reduce((filteredFolders, filter) => {
                        if (filter.type == 'f') {
                            return filteredFolders.concat(filter.string)
                        } else {
                            return filteredFolders
                        }
                    }, []);
                    let tItems = this.localData.folders.reduce((folders, folder) => {
                        if (folder.access) {
                            if (!filteredFolders.includes(folder.description) && !folders.includes(folder.description != null ? folder.description : 'Task Folder')) {
                                if (searchString != null && searchString != '') {
                                    if (((folder.description != null ? folder.description : 'Task Folder')).toLowerCase().includes(searchString.toLowerCase())) {
                                        folders = folders.concat(folder.description != null ? folder.description : 'Task Folder')
                                    }
                                } else {
                                    folders = folders.concat(folder.description != null ? folder.description : 'Task Folder')
                                }
                            }
                        }
                        return folders
                    }, []);
                    let counts = this.filterTasks(['t', 'w', 'u', 'f', 's'])
                        .reduce((obj, task) => {
                            if (typeof obj[blankString(task.folderName) ? 'Task Folder' : task.folderName] == 'undefined') {
                                obj[blankString(task.folderName) ? 'Task Folder' : task.folderName] = 0
                            }
                            ++obj[blankString(task.folderName) ? 'Task Folder' : task.folderName];
                            return obj
                        }, {});
                    return tItems.map(item => {
                        return {
                            title: item,
                            count: counts[item],
                            icon: 'folder',
                            callback: () => {
                              // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                                this.filters.push({
                                    type: 'f',
                                    string: item
                                });
                              // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                                this.refString = '';
                            }
                        }
                    })
                        .map(obj => {if (typeof obj.count == 'undefined') {obj.count = 0;} return obj})
                        .sort((a,b) => a.title < b.title ? -1 : 1)
                        .sort((a,b) => (a['count'] > 0) > (b['count'] > 0) ? -1 : 1);

                } catch (e) {
                    // eslint-disable-next-line
                    console.log(e)
                    return [];
                }
            },
        }
    }
</script>

<style scoped>
    .close-chip-icon {
        cursor: pointer;
        background: var(--v-primary-base);
        color: white;
    }

    .close-chip-icon:hover {
        background: var(--v-primary-lighten1);
    }

    .wrapTextKeepLine {
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        line-height: 1.5 !important;
    }
</style>
