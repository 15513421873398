<template>
    <div>
        <app-general-mail-container
            :mail="mail"
            :task="task"
            :user-info="userInfo"
            @removed="$emit('removed')"
            v-if="showMailContainer"
            :read-only-dash="readOnlyDash"
            :new-mail-fn="newMailFn"
            :external-eml-view="true"
            @viewMail="$emit('viewMail', $event)"
        />
    </div>
</template>

<script>
    import generalMailContainer from '../../../components/General/generalMailContainer.vue'
    export default {
        props: {
            userInfo: Object,
            task: [Object, Boolean],
            mail: Object,
            readOnlyDash: {
                type: Boolean,
                default: false
            },
            newMailFn: Function,
        },
        components: {
            appGeneralMailContainer: generalMailContainer,
        },
        data() {
            return {
                showMailContainer: false
            }
        },
        watch: {
            task: {
                immediate: true,
                handler(val) {
                    this.showMailContainer = typeof val === 'object' && val !== null
                }
            }
        }
    }
</script>

<style scoped>

</style>
