import { render, staticRenderFns } from "./CreateUserGroup.vue?vue&type=template&id=3b24b00c&scoped=true"
import script from "./CreateUserGroup.vue?vue&type=script&lang=js"
export * from "./CreateUserGroup.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b24b00c",
  null
  
)

export default component.exports