<template>
    <div class="pt-2">
        <v-layout row align-center justify-center>
            <v-flex shrink class="body-1 greyType--text pr-2">Add Signature:</v-flex>
            <v-flex xs4>
                <v-autocomplete
                    color="primary"
                    label="Add Signature"
                    :items="availableSignatures"
                    item-text="description"
                    item-value="id"
                    flat
                    hide-details
                    dense
                    multiple
                    chips
                    small-chips
                    single-line
                    class="pa-0 ma-0"
                    v-model="addSignatures"
                    deletable-chips
                    no-data-text="No Available Signatures To Add"
                    :loading="addingSignatures"
                    :disabled="addingSignatures"
                >
                </v-autocomplete>
            </v-flex>
            <v-flex shrink class="pl-2">
                <v-btn
                    small
                    color="secondary"
                    class="py-0 px-2 ma-0"
                    :disabled="addingSignatures || addSignatures == null || addSignatures.length <= 0"
                    :lodaing="addingSignatures"
                    @click="addSignaturesToUser"
                >
                    Add Signature
                </v-btn>
            </v-flex>
        </v-layout>

        <v-layout row align-center v-if="userSignatures.length > 0" class="pt-2">
            <v-flex shrink class="px-2 body-1 greyType--text" style="white-space: nowrap">
                Added Signatures:
            </v-flex>
            <v-flex xs12>
                <v-layout row wrap style="overflow-x: hidden" class="pa-1" justify-center>
                    <v-flex
                        v-for="(sig, i) in userSignatures"
                        lg3 xs4
                        :key="sig.id"
                        class="pa-1"
                        style="overflow-x: hidden"
                    >
                        <user-signatures-sig
                            :ref="`sigContainer`"
                            :signature="sig"
                            :default-signature="defaultSig"
                            @remove="removeSignature(sig.id, i)"
                            @updateDefault="updateDefault(sig.id, i)"
                        />
                    </v-flex>
                </v-layout>
            </v-flex>
        </v-layout>
    </div>
</template>

<script>
import {mapGetters} from 'vuex';
import {codeBaseMixin} from "@/codeBaseMixin";
import UserSignaturesSig from "@/Admin/UserManager/SIgnatures/UserSignaturesSig";

export default {
    components: {UserSignaturesSig},
    mixins: [codeBaseMixin],
    props: {
        selectedUserID: Number
    },
    data() {
        return {
            loadingSignatureData: false,
            defaultSig: null,
            signatures: [],
            membership: [],
            addSignatures: [],
            addingSignatures: false,
        }
    },
    watch: {
        selectedUserID: {
            immediate: true,
            deep: true,
            handler(val) {
                if (val != null) {
                    this.resetForm();
                    this.loadSigData()
                }
            }
        }
    },
    methods: {
        updateDefault(sigID, index) {
            this.$refs.sigContainer[index].loading = true;
            this.fetch({method: 'PATCH', url: `/user/sigMan/setDefault/${this.userInfo.entityID}/${this.selectedUserID}/${sigID}`})
                .then(() => {
                    this.$refs.sigContainer[index].loading = false;
                    this.defaultSig = sigID
                })
                .catch((e) => {
                    // eslint-disable-next-line
                    console.log(e);
                    this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                    this.$refs.sigContainer[index].loading = false;
                })
        },
        removeSignature(sigID, index) {
            this.$refs.sigContainer[index].loading = true;
            this.fetch({method: 'DELETE', url: `/user/sigMan/removeSignature/${this.userInfo.entityID}/${this.selectedUserID}/${sigID}/${this.boolToInt(this.defaultSig === sigID)}`})
                .then(() => {
                    this.$refs.sigContainer[index].loading = false;
                    if (sigID === this.defaultSig) {
                        this.defaultSig = null
                    }
                    this.membership = this.membership.filter(member => member.fk_signature !== sigID)
                })
                .catch((e) => {
                    // eslint-disable-next-line
                    console.log(e);
                    this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                    this.$refs.sigContainer[index].loading = false;
                })
        },
        resetForm() {
            this.defaultSig = null;
            this.signatures = [];
            this.membership = [];
            this.addSignatures = [];
            this.addingSignatures = false;
        },
        loadSigData() {
            this.loadingSignatureData = true;
            this.fetch({method: 'GET', url: `/user/sigMan/startup/${this.userInfo.entityID}/${this.selectedUserID}`})
                .then((data) => {
                    this.defaultSig = data.defaultSignature;
                    this.signatures = data.signatures;
                    this.membership = data.membership;
                    this.loadingSignatureData = false;
                })
                .catch((e) => {
                    // eslint-disable-next-line
                    console.log(e);
                    this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                    this.loadingSignatureData = false;
                })
        },
        addSignaturesToUser() {
            this.addingSignatures = true;
            this.fetch({method: 'POST', url: `/user/sigMan/addSignatures/${this.userInfo.entityID}/${this.selectedUserID}`, body: this.addSignatures})
                .then((data) => {
                    this.addSignatures = null;
                    this.defaultSig = data.defaultSignature;
                    this.signatures = data.signatures;
                    this.membership = data.membership;
                    this.addingSignatures = false;
                })
                .catch((e) => {
                    // eslint-disable-next-line
                    console.log(e);
                    this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                    this.addingSignatures = false;
                })
        }
    },
    computed: {
        ...mapGetters('userInfo', ['userInfo']),
        availableSignatures() {
            let aAddedSigs = this.signatures
                .filter(sig => sig.globalDefault === 1)
                .map(sig => {return sig.id})
                .concat(this.membership.map(sig => {return sig.fk_signature}))
            return this.signatures.filter(sig => !aAddedSigs.includes(sig.id)).sort((a,b) => a.description > b.description ? 1 : -1)
        },
        userSignatures() {
            let aAddedSigs = this.signatures
                .filter(sig => sig.globalDefault === 1)
                .map(sig => {return sig.id})
                .concat(this.membership.map(sig => {return sig.fk_signature}))
            return this.signatures.filter(sig => aAddedSigs.includes(sig.id)).sort((a,b) => a.description > b.description ? 1 : -1)
        }

    }
}
</script>

<style scoped>

</style>