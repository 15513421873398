<template>
    <v-layout row>
        <v-flex xs12 class="py-1">
            <v-card class="pa-1">
                <v-layout row align-center>
                    <v-flex shrink>
                        <v-tooltip top>
                            <v-icon
                                    :color="field.required == 1 ? 'red' : 'white'"
                                    slot="activator"
                            >
                                arrow_right
                            </v-icon>
                            <span v-if="field.required == 1">Required Field</span>
                        </v-tooltip>
                    </v-flex>
                    <v-flex xs12>
                        <span class="subheading font-weight-light">{{ field.description }}</span>
                    </v-flex>
                    <v-flex shrink>
                        <v-tooltip top>
                            <v-icon
                                    color="secondary"
                                    slot="activator"
                            >
                                {{ field.visible == 1 ? 'check_box' : 'check_box_outline_blank'}}
                            </v-icon>
                            <span>Visibility</span>
                        </v-tooltip>
                    </v-flex>
                    <v-flex shrink>
                        <v-tooltip top>
                            <v-btn
                                    small
                                    class="pa-0 ma-0"
                                    icon
                                    flat
                                    color="primary"
                                    slot="activator"
                                    @click="dropped = ! dropped"
                            >
                                <v-icon>{{ dropped ? 'arrow_drop_up' : 'arrow_drop_down'}}</v-icon>
                            </v-btn>
                            <span>{{ dropped ? 'Collapse Detail' : 'More Detail'}}</span>
                        </v-tooltip>
                    </v-flex>
                </v-layout>

                <template v-if="dropped">
                    <v-divider></v-divider>
                    <div>
                        <div class="pt-2">
                            <span class="body-2 greyType--text">Field Type</span>
                        </div>
                        <div class="pl-3">
                            <span class="body-1">{{ fieldType }}</span>
                        </div>
<!--Edit Description-->
                        <div class="pt-2">
                            <span class="body-2 greyType--text">Edit Field Description</span>
                        </div>
                        <v-text-field
                                label="Field Description"
                                single-line
                                v-model="field.description"
                                color="primary"
                                append-outer-icon="save"
                                hide-details
                                style="width: 100%"
                                @click:append-outer="saveChanges"
                                class="ma-0 pa-0 pl-3"
                                @keydown.enter="saveChanges"
                        ></v-text-field>

<!--Field Options-->
                        <div class="pt-2">
                            <span class="body-2 greyType--text">Field Options</span>
                        </div>
                        <div class="pl-3">
                            <v-checkbox
                                    :disabled="field.type == 6"
                                    v-model="distinct"
                                    label="Unique"
                                    hide-details
                                    class="pa-0 ma-0"
                                    color="primary"
                                    @change="saveChanges"
                            ></v-checkbox>
                            <v-checkbox
                                    v-model="required"
                                    label="Required"
                                    hide-details
                                    class="pa-0 ma-0"
                                    color="primary"
                                    @change="saveChanges"
                            ></v-checkbox>
                            <v-checkbox
                                    v-model="useInName"
                                    label="Use Value in Title"
                                    hide-details
                                    class="pa-0 ma-0"
                                    color="primary"
                                    @change="saveChanges"
                            ></v-checkbox>
                            <v-checkbox
                                    v-model="visible"
                                    label="Visible"
                                    hide-details
                                    class="pa-0 ma-0"
                                    color="primary"
                                    @change="saveChanges"
                            ></v-checkbox>
                        </div>
                    </div>
                </template>
<!--Custom List-->
                <template v-if="this.field.type == 5 && this.field.lookupType == 1 && dropped">
                    <div class="pt-3">
                        <span class="body-2 greyType--text">List Items</span>
                    </div>
<!--Items-->
                    <div
                            style="max-height: 150px; overflow-y: auto"
                            class="scroll"
                    >
                        <template v-for="(item, index) in orderedSelectListMembers">
                            <v-layout
                                    row
                                    :key="'CustomListItem' + index"
                                    align-center
                                    class="pl-3"
                            >
                                <v-flex xs12>
                                    <span class="body-1 font-weight-light">{{ item }}</span>
                                </v-flex>
                                <v-flex shrink>
                                    <v-btn
                                            icon
                                            flat
                                            small
                                            color="red"
                                            @click="removeListItem(item)"
                                            class="pa-0 ma-0"
                                    >
                                        <v-icon>
                                            close
                                        </v-icon>
                                    </v-btn>
                                </v-flex>
                            </v-layout>
                        </template>
                    </div>
<!--Add List Item-->
                    <v-text-field
                            label="Add New List Item"
                            single-line
                            v-model="addListItemDescription"
                            color="primary"
                            append-outer-icon="add"
                            hide-details
                            style="width: 100%"
                            @click:append-outer="addListItem"
                            class="ma-0 pa-0 pt-2 pl-3"
                            @keydown.enter="addListItem"
                            ref="addListItemField"
                    ></v-text-field>
                </template>
<!--Select List-->
                <template v-if="field.type == 5 && field.lookupType == 2 && dropped == true">
                    <div class="pt-3">
                        <span class="body-2 greyType--text">Select List</span>
                    </div>
                    <v-layout row align-center class="pl-3">
                        <v-flex xs12>
                            <span class="body-2">{{ field.lookup != null && field.lookup != '' && typeof JSON.parse(field.lookup).description != 'undefined' ? JSON.parse(field.lookup).description : 'Please Select a List'}}</span>
                        </v-flex>
                        <v-flex shrink>
                            <v-btn
                                    small
                                    icon
                                    flat
                                    color="secondary"
                                    @click="showSelectList = true"
                                    class="pa-0 ma-0"
                            >
                                <v-icon>list</v-icon>
                            </v-btn>

                        </v-flex>

                    </v-layout>
                </template>

                <app-select-list-dlg
                        :user-info="userInfo"
                        :show-dialog="showSelectList"
                        @dismiss="showSelectList = false"
                        @selected="selectListSelected"
                />

            </v-card>
        </v-flex>
    </v-layout>
</template>

<script>
    import { codeBaseMixin } from "../../../codeBaseMixin";
    import SelectListDlg from '../../../components/General/SelectListDlg'

    export default {
        mixins: [ codeBaseMixin ],
        components: {
            appSelectListDlg: SelectListDlg
        },
        props: {
            field: Object,
            userInfo: Object,
            isTop: Boolean,
            isBottom: Boolean
        },
        data() {
            return {
                inEdit: false,
                dropped: false,
                addListItemDescription: null,
                showSelectList: false
            }
        },
        methods: {

            selectListSelected(data) {
                this.field.lookup = JSON.stringify(data);
                this.saveChanges();
            },

            addListItem() {
                if (this.addListItemDescription != null && this.addListItemDescription != '') {

                    let cycleObj = [];

                    try {
                        cycleObj = JSON.parse(this.field.lookup);
                    } catch (e) {
                        // eslint-disable-next-line
                        console.log(e)
                    }
                    cycleObj.push(this.addListItemDescription);
                    this.field.lookup = JSON.stringify(cycleObj);
                    this.addListItemDescription = '';
                    this.$refs.addListItemField.focus();
                    this.saveChanges()
                } else {
                    this.showSnack('Error', 'List Item Can Not Be Blank', 'Close', 'red')
                }
            },

            removeListItem(item) {

                let cycleObj = JSON.parse(this.field.lookup);

                cycleObj.forEach((cycleItem, i) => {
                    if (cycleItem == item) {
                        cycleObj.splice(i, 1)
                    }
                });

                this.field.lookup = JSON.stringify(cycleObj);
                this.saveChanges();
            },

            saveChanges() {

                if (this.field.description != null && this.field.description != '') {

                    let jSend = this.field;

                    if (jSend.type != 5) {
                        jSend.lookup = null;
                        jSend.lookupType = null;
                    }

                    this.fetch({method: 'PATCH', url: '/fieldsets/admin/saveFieldSetChanges/' + this.userInfo.entityID, body: JSON.stringify(jSend)})
                        .then(() => {
                            this.showSnack('Info', 'Changes Saved', 'Close', 'primary')
                        })
                        .catch((e) => {
                            // eslint-disable-next-line
                            console.log(e)
                        })
                } else {
                    this.showSnack('Error', 'Field Description Is Required', 'Close', 'red')
                }
            }
        },
        computed: {
            distinct: {
                // getter
                get: function () {
                    return this.intToBool(this.field.distinct)
                },
                // setter
                set: function (newValue) {
                    this.field.distinct = this.boolToInt(newValue)
                }
            },
            required: {
                // getter
                get: function () {
                    return this.intToBool(this.field.required)
                },
                // setter
                set: function (newValue) {
                    this.field.required = this.boolToInt(newValue)
                }
            },
            useInName: {
                // getter
                get: function () {
                    return this.intToBool(this.field.useInName)
                },
                // setter
                set: function (newValue) {
                    this.field.useInName = this.boolToInt(newValue)
                }
            },
            visible: {
                // getter
                get: function () {
                    return this.intToBool(this.field.visible)
                },
                // setter
                set: function (newValue) {
                    this.field.visible = this.boolToInt(newValue)
                }
            },
            fieldType() {
                switch (this.field.type) {
                    case 1: {
                        return 'Word Field';
                    }
                    case 2: {
                        return 'Date Field';
                    }
                    case 3: {
                        return 'Number Field';
                    }
                    case 4: {
                        return 'Currency Field';
                    }
                    case 5: {
                        return 'List Field';
                    }
                    case 6: {
                        return 'Mail/Document Field';
                    }
                }
            },

            orderedSelectListMembers() {
                if (this.field.type == 5 && this.field.lookupType == 1 && this.field.lookup != null && this.field.lookup.length > 0) {
                    let tList = [];
                    try {
                        JSON.parse(this.field.lookup).forEach((listItem) => {
                            tList.push(listItem);
                        });
                    } catch (e) {
                        // eslint-disable-next-line
                        console.log(e)
                    }
                    tList.sort((a,b) => a < b ? -1 : 1);
                    return tList
                } else {
                    return [];
                }
            }
        }

    }
</script>

<style scoped>

</style>
