<template>
    <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
        :persistent="true"
    >
        <!--<v-card flat fill-height class="bord">-->
        <v-layout column fill-height style="overflow-y: auto; background: var(--card-back)">
            <v-flex shrink>

                <v-card-title class="py-2 pr-2 primary">
                    <v-icon
                        left
                        color="white"
                    >
                        note
                    </v-icon>
                    <span class="title font-weight-light white--text">Compose Note</span>
                    <v-spacer></v-spacer>

                    <v-menu open-on-hover bottom offset-y v-if="typeof task != 'undefined' && task != null">
                        <v-btn
                            icon
                            flat
                            color="white"
                            class="pa-0 ma-0"
                            @click="() => {}"
                            slot="activator"
                        >
                            <img src="../../../../../public/tasks_lighten.svg" width="24px"/>
                        </v-btn>

                        <v-card width="400">
                            <task-container
                                :userInfo="userInfo"
                                :task="task"
                                :taskBar="false"
                            ></task-container>
                        </v-card>
                    </v-menu>

                    <v-btn v-if="!addingNoteBusy" flat icon color="white" class="pa-0 ma-0" @click="$emit('dismiss')">
                        <v-icon>close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-divider></v-divider>
            </v-flex>

            <v-flex xs12 style="overflow-y: auto">
                <v-card-text v-if="showLoading" class="pa-1 px-2 text-xs-center">
                    <v-progress-circular
                        :size="40"
                        :width="5"
                        color="primary"
                        indeterminate
                    ></v-progress-circular>
                </v-card-text>

                <v-layout v-show="!showLoading" row fill-height>
                    <v-flex xs3 class="fill-height">

                        <v-layout column fill-height>
                            <v-flex shrink>
                                <v-text-field
                                    label="Search Users"
                                    single-line
                                    v-model="userSearchString"
                                    color="primary"
                                    hide-details
                                    style="width: 100%"
                                    class="ma-0 pa-2 pt-3"
                                    clearable
                                    prepend-icon="people"
                                    append-icon="search"
                                ></v-text-field>
                            </v-flex>
                            <v-flex xs12 style="overflow-y: auto" class="scroll">

                                <v-list dense>
                                    <v-list-tile
                                        v-for="user in filteredUsers"
                                        :key="`user${user.userID}`"
                                        @click="selectDeselectUser(user)"

                                        :class="user.outOfOffice ? 'red lighten-5' : null"
                                    >
                                        <v-list-tile-action class="px-1" style="min-width: auto">
                                            <v-icon
                                                :color="typeof user.outOfOffice == 'undefined' ? 'primary' : (intToBool(user.outOfOffice) ? 'secondary' : 'primary')"
                                                class="pa-0 ma-0"
                                            >
                                                person
                                            </v-icon>
                                        </v-list-tile-action>


                                        <v-list-tile-title :class="user.userID == task.ownerID ? 'font-weight-bold blue--text' : ''">
                                            {{`${user.userFullName}${!blankString(user.jobTitle) ? ` - ${user.jobTitle}` : ''}`}} <span v-if="user.outOfOffice" class="caption red--text font-weight-bold">&nbsp;- Out of Office</span>
                                        </v-list-tile-title>

                                        <v-list-tile-action class="px-1" style="min-width: auto">
                                            <v-btn
                                                icon
                                                small
                                                flat
                                                color="primary"
                                                class="pa-0 ma-0"
                                            >
                                                <v-icon>
                                                    {{ selectedUsers.map(obj => {return obj.userID}).includes(user.userID) ? 'check_box' : 'check_box_outline_blank' }}
                                                </v-icon>
                                            </v-btn>
                                        </v-list-tile-action>

                                    </v-list-tile>

                                </v-list>
                            </v-flex>
                        </v-layout>

                    </v-flex>
                    <v-flex xs6 class="fill-height" style="overflow-y: auto">

                        <div class="fill-height">
                            <v-layout column fill-height style="overflow-y: auto">
                                <!--Tab Headings-->
                                <v-flex shrink v-if="!resetTabs">
                                    <v-tabs
                                        slot="extension"
                                        v-model="model"
                                        grow
                                    >
                                        <v-tabs-slider color="primary"></v-tabs-slider>
                                        <v-tab v-if="!draft || draft.draftID === 0 || draft.noteType === 0">Note</v-tab>
                                        <v-tab v-if="setNoteContent == null || (!draft || draft.draftID === 0 || draft.noteType === 1)">Checklist</v-tab>
                                        <v-tab v-if="draft.draftID == 0 && setNoteContent == null">Voice Note</v-tab>
                                    </v-tabs>
                                </v-flex>

                                <!--Text Note-->
                                <v-flex xs12 v-if="dialog && model === 0" style="overflow-y: auto">
                                    <editor
                                        v-model="noteBody"
                                        :extra-config="editorConfig"
                                        ref="composeNoteEditor"
                                    />
                                </v-flex>
                                <!--Checklist-->
                                <v-flex xs12 v-if="model == 1" class="pa-2" style="overflow-y: auto">
                                    <v-layout column fill-height style="overflow-y: auto">
                                        <v-flex shrink>
                                            <v-text-field
                                                label="Add Checklist Item (To add Multiple Items separate with a semicolon)"
                                                append-outer-icon="add_circle_outline"
                                                color="primary"
                                                v-model="addItem"
                                                @keydown.enter="addChecklistItem()"
                                                @click:append-outer="addChecklistItem()"
                                                hide-details
                                                class="py-2"
                                            ></v-text-field>
                                        </v-flex>
                                        <v-flex xs12 style="overflow-y: auto" class="scroll">
                                            <template v-for="(checkItem, i) in checklistItems">
                                                <v-layout justify-centre row>
                                                    <v-flex xs10>
                                                        <v-checkbox hide-details class="pa-0 ma-0 pt-1" disabled
                                                                    :label="checkItem"></v-checkbox>
                                                    </v-flex>
                                                    <v-flex class="text-xs-right">
                                                        <v-btn flat icon color="tRed" class="pa-0 ma-0"
                                                               @click="removeItem(i)">
                                                            <v-icon>remove_circle_outline</v-icon>
                                                        </v-btn>
                                                    </v-flex>
                                                </v-layout>
                                            </template>
                                        </v-flex>
                                    </v-layout>
                                </v-flex>
                                <!--Voice Note-->
                                <v-flex xs12 v-if="model == 2" class="pa-2">
                                    <v-layout row wrap justify-center transition="slide-x-transition">
                                        <v-flex xs12 class="text-xs-center">
                                            <v-btn
                                                v-if="!recording"
                                                fab
                                                dark
                                                color="blue"
                                                :loading="uploading"
                                                :disabled="uploading"
                                                @click="recordAudio"
                                            >
                                                <v-icon dark>mic</v-icon>
                                            </v-btn>

                                            <v-btn
                                                v-if="recording"
                                                fab
                                                dark
                                                color="red"
                                                @click="stopRecordingAudio"
                                            >
                                                <v-icon dark>stop</v-icon>
                                            </v-btn>
                                        </v-flex>
                                        <v-flex xs12 class="text-xs-center" v-show="dataExists">
                                            <audio
                                                controls controlsList="nodownload"
                                                ref="player"
                                                id="player"
                                                :src="recordedAudioBlob"
                                                type="audio/mpeg-3"
                                                style="margin: auto"
                                            >
                                            </audio>
                                        </v-flex>
                                    </v-layout>
                                </v-flex>

                                <v-flex shrink class="text-xs-right">

                                    <template>
                                        <v-tooltip top v-if="model != 2">
                                            <v-btn
                                                icon
                                                color="primary"
                                                :loading="addingNoteBusy"
                                                :disabled="addingNoteBusy"
                                                slot="activator"
                                                @click="saveDraft"
                                                fab
                                            >
                                                <v-icon>
                                                    save
                                                </v-icon>
                                            </v-btn>
                                            <span>Save Draft</span>
                                        </v-tooltip>
                                    </template>
                                    <v-tooltip top>
                                        <v-btn

                                            icon
                                            color="secondary"
                                            @click="selectedUsers.length > 0 ? addNoteType() : showConfirm = true"
                                            :loading="addingNoteBusy"
                                            :disabled="!showAdd ||addingNoteBusy || imageCount !== 0"
                                            slot="activator"
                                            fab
                                        >
                                            <v-icon>
                                                add
                                            </v-icon>
                                        </v-btn>
                                        <span>Add Note</span>
                                    </v-tooltip>

                                </v-flex>
                            </v-layout>

                        </div>

                    </v-flex>
                    <!--Existing Notes-->
                    <v-flex xs3 class="fill-height" >

                        <v-layout column fill-height style="background: var(--card-back)">
                            <v-flex shrink>
                                <v-layout row align-center>
                                    <v-flex xs12>
                                        <v-text-field
                                            label="Search Notes"
                                            single-line
                                            v-model.lazy="filterStr"
                                            color="primary"
                                            hide-details
                                            style="width: 100%"
                                            class="ma-0 pa-2 pt-3"
                                            clearable
                                            prepend-icon="note"
                                            append-icon="search"
                                        ></v-text-field>
                                    </v-flex>
                                    <v-flex class="px-1" v-if="drawTimeOut != null">
                                        <v-progress-circular
                                            :size="20"
                                            :width="3"
                                            color="primary"
                                            indeterminate
                                        />
                                    </v-flex>
                                </v-layout>

                            </v-flex>
                            <!--                                <v-divider></v-divider>-->
                            <v-flex xs12 style="overflow-y: auto; background: var(--page-back)" class="scroll pt-2" id="scrollTop" v-if="drawTimeOut == null">

                                <div class="text-xs-center pa-1" v-if="loadingTaskNotes">
                                    <v-progress-circular
                                        :size="40"
                                        :width="5"
                                        color="primary"
                                        indeterminate
                                    />
                                </div>
                                <template v-if="!loadingTaskNotes && taskNoteArr != null">
                                    <div
                                        v-for="(refNote, i) in taskNoteArr"
                                        class="caption greyType--text"
                                        :key="'refNote:' + i"
                                    >
                                        <div
                                            v-if="i>=((curPage - 1)*pageSize) && (i+1) <= (curPage*pageSize)"
                                            class="pb-3 px-2"
                                        >
                                            <v-card class="pt-1 noteCon" :hover="true">
                                                <note-view-container
                                                    :userInfo="userInfo"
                                                    :task="taskNotes"
                                                    :note="refNote"
                                                    :allow-reply="false"
                                                />
                                            </v-card>
                                        </div>
                                    </div>
                                </template>
                            </v-flex>
                            <v-flex shrink class="text-xs-center" v-if="drawTimeOut == null">
                                <v-pagination
                                    v-if="showPage && totalPages != null && totalPages > 1"
                                    v-model="curPage"
                                    :length="totalPages"
                                    color="primary"
                                ></v-pagination>
                            </v-flex>
                        </v-layout>

                    </v-flex>
                </v-layout>

            </v-flex>

        </v-layout>
        <input-dlg
            :show-dialog="showConfirm"
            title="No Recipients Selected"
            body="Would you like to add the note without any recipients?"
            :cancel-button="true"
            :show-input-box="false"
            :buttons="['Cancel', 'Add']"
            :call-backs="[ cancelAddNote, addNoteType ]"
            @dismiss="showConfirm = false"
        />
    </v-dialog>
</template>

<script>

import {eventBus} from '../../../../main';
import {codeBaseMixin} from '../../../../codeBaseMixin';
import VueFroala from 'vue-froala-wysiwyg';
const tokenMan = require('../../../../tokenManSite');
import ComposeNoteEditor from "./ComposeNoteEditor";
import Vue from 'vue'
import TaskContainer from "../../../../components/TaskContainer/TaskContainer";
import NoteViewContainer from "./NoteViewContainer";
import Editor from "@/Editor/Editor";
import axios from 'axios';
import InputDlg from "@/components/General/InputDlg";

export default {
    mixins: [codeBaseMixin],
    components: {
        InputDlg,
        Editor,
        NoteViewContainer,
        TaskContainer
    },
    props: {
        noteReplyID: {
            type: Number,
            default: 0
        },
        replyUsers: {
            type: Array,
            default: () => {[]}
        },
        noteReplyUser: Object,
        userInfo: Object,
        selectedStep: Object,
        task: Object,
        dialog: Boolean,
        draft: {
            type: Object,
            default: () => {
                return {
                    draftID: 0,
                    checklistItems: null,
                    noteBody: '',
                    created: null,
                    userID: null,
                    selectedUsers: null,
                    taskID: null,
                    stepID: null,
                    noteType: null,
                }
            }
        },
        setNoteContent: {
            default: null,
            type: String,
        },
        dashboard: {
            default: false,
            type: Boolean
        },
    },

    data() {
        return {
            selectedUsers: [],
            users: [],
            noteBody: '',
            model: 0,
            checklistItems: [],
            addItem: '',
            showLoading: false,
            addingNoteBusy: false,
            recording: false,
            uploading: false,
            dataExists: false,
            rec: null,
            audioChunks: [],
            recordedAudioBlob: null,
            doneRecording: false,
            filename: 'uploadVN',
            uploadVoiceNoteAudioID: null,
            userSearchString: "",
            resetTabs: false,
            initControls: null,
            loadingTaskNotes: false,
            taskNotes: null,
            showPage: true,
            curPage: 1,
            totalPages: 0,
            pageSize: 10,
            filterStr: null,
            drawTimeOut: null,
            imageCount: 0,
            showConfirm: false
        }
    },

    watch: {


        filterStr: {
            immediate: true,
            handler() {
                this.curPage = 1;
                this.scrollToTheTop();

                try {
                    clearTimeout(this.drawTimeOut);
                    this.drawTimeOut = setTimeout(() => {
                        this.$nextTick(() => {this.drawTimeOut = null})
                    }, 1000)
                } catch (e) {
                    // eslint-disable-next-line
                    console.log(e)
                }
            }
        },

        taskNoteArr(val) {

            if (val === null) {
                this.showPage = false;
                this.totalPages = 0;
                this.curPage = 1
            } else {
                this.totalPages = Math.floor(val.length / this.pageSize) + (val.length % this.pageSize > 0 ? 1 : 0)
            }
        },

        curPage(val) {
            if (val > this.totalPages) {
                this.curPage = this.totalPages == 0 ? 1 : this.totalPages
            }
            this.scrollToTheTop();
        },

        dialog(show) {

            this.resetNote();

            try {
                navigator.mediaDevices.getUserMedia({audio: true})
                    .then(stream => {
                        this.handlerFunction(stream)
                    });
            } catch (e) {
                console.log(e)
            }

            this.recordedAudioBlob = this.serverAddress;

            if (show) {
                this.getAuthorizedUsers();
                this.getTaskNotes()
            }
            if (this.noteReplyID != 0) {
                this.selectedUsers = [];
                this.selectedUsers.push(this.noteReplyUser)
            }

            if (show) {
                if (this.draft.draftID != 0){
                    if (this.draft.selectedUsers != null && this.draft.selectedUsers != '' && this.draft.selectedUsers != '[]' && JSON.parse(this.draft.selectedUsers).length > 0) {
                        this.selectedUsers = JSON.parse(this.draft.selectedUsers);
                    }

                    if (this.draft.checklistItems != null && this.draft.checklistItems != '' && this.draft.checklistItems != '[]' && JSON.parse(this.draft.checklistItems).length > 0) {
                        this.checklistItems = JSON.parse(this.draft.checklistItems);
                    }

                    if (this.draft.noteBody != null && this.draft.noteBody != '') {
                        this.noteBody = this.draft.noteBody;
                    }

                    this.model = this.draft.noteType;
                }
            }

            if (this.setNoteContent != null && this.setNoteContent != '') {
                this.noteBody = this.setNoteContent
            }

            if (this.draft && this.draft.draftID !== 0) {
                this.model = this.draft.noteType
            }


            if (this.noteBody == null || this.noteBody == ''){this.noteBody = `<br>`}

            this.resetTabs = true;
            this.$nextTick(() => {
                this.resetTabs = false;
            });

            // this.setFocus();
        }
    },

    methods: {
        cancelAddNote() {
            this.showConfirm = false;
        },
        scrollToTheTop() {
            var myDiv = document.getElementById('scrollTop');
            if (myDiv)
                myDiv.scrollTop = 0;
        },
        getTaskNotes() {
            if (this.dashboard) {
                this.loadingTaskNotes = true;
                this.fetch({method: 'GET', url:`/tasks/notes/refNotes/${this.userInfo.entityID}/${this.task.taskID}/${this.userInfo.userID}`})
                    .then((data) => {
                        this.taskNotes = data;
                        this.loadingTaskNotes = false;
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e)
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                        this.loadingTaskNotes = false;
                    });
            } else {
                this.taskNotes = this.task;
                this.loadingTaskNotes = false;
            }
        },
        imageInserted($img, response) {
            $img[0].style.maxWidth = "100%";
            $img[0].style.width = "auto";
        },
        imageRemoved($img) {
            if (this.noteBody != '' && this.noteBody != '<p>...</p>' && this.dialog) {
                this.fetch({method: 'DELETE', url:`/upload/noteAttachment/${this.userInfo.entityID}/${$img[0].dataset.id}`})
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e)
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                    })
            }
        },
        selectDeselectUser(user) {
            if (this.selectedUsers.map(obj => {
                return obj.userID
            }).includes(user.userID)) {
                this.selectedUsers = this.selectedUsers.filter(obj => obj.userID != user.userID)
            } else {
                this.selectedUsers.push(user)
            }
            // this.selectedUsers.map(obj => {return obj.userID}).includes(user.userID) ?  : this.selectedUsers.push(user)
        },
        saveDraft() {
            let tDraft = {
                draftID: this.draft.draftID,
                checklistItems: this.checklistItems,
                noteBody: this.noteBody,
                userID: this.userInfo.userID,
                selectedUsers: this.selectedUsers,
                taskID: this.task.taskID,
                stepID: this.selectedStep.stepID,
                noteType: this.model
            }

            this.fetch({
                method: 'POST',
                url:`/taskCache/note/addDraftNote/${this.userInfo.entityID}`,
                body: JSON.stringify(tDraft)
            })
                .then((data) => {


                    this.showSnack('Info', 'Draft Saved', 'Close', 'primary');

                    const exists = this.task.structure.draftNotes.map(obj => {
                        return obj.draftID
                    }).includes(data.draftID)

                    if (exists) {
                        for (const draft of this.task.structure.draftNotes) {
                            if (draft.draftID === data.draftID) {
                                Object.assign(draft, data)
                            }
                        }
                        // this.task.structure.draftNotes = this.task.structure.draftNotes.map(obj => {
                        //     if (obj.draftID != data.draftID) {
                        //         return obj
                        //     } else {
                        //         return data
                        //     }
                        // })
                    } else {
                        this.task.structure.draftNotes.push(data)
                        this.$emit('setDraft', data);
                    }
                })
                .catch((e) => {
                    // eslint-disable-next-line
                    console.log(e)
                    this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                })
        },
        resetNote() {
            this.imageCount = 0
            this.selectedUsers = [];
            this.users = [];
            this.noteBody = '';
            this.model = 0;
            this.checklistItems = [];
            this.addItem = '';
            this.showLoading = false;
            this.addingNoteBusy = false;

            this.recording = false;
            this.uploading = false;
            this.dataExists = false;
            this.rec = null;
            this.audioChunks = [];
            this.recordedAudioBlob = null;
            this.doneRecording = false;
            this.filename = 'uploadVN';
            this.uploadVoiceNoteAudioID = null;
            this.taskNotes = null;
        },
        addNoteType() {

            this.showConfirm = false;
            switch (this.model) {
                case 0: {
                    // here
                  this.addingNoteBusy = true;
                    this.$refs.composeNoteEditor.updateModel()
                    setTimeout(() => {
                        this.addNote()
                    }, 300)
                    break;
                }
                case 1: {
                    this.addChecklist()
                    break;
                }
                case 2: {
                    this.addVoiceNote()
                    break;
                }
            }
        },
        recordAudio() {
            this.audioChunks = [];
            this.rec.start();
            this.recording = true;
        },
        stopRecordingAudio() {
            this.rec.stop();
            this.doneRecording = true;
            this.recording = false;
            this.uploading = true;
            setTimeout(() => {
                this.uploadNote();
            }, 100);
        },
        handlerFunction(stream) {
            this.rec = new MediaRecorder(stream);
            this.rec.ondataavailable = e => {
                this.audioChunks = [];
                this.audioChunks.push(e.data);
                if (this.rec.state == "inactive") {
                    let blob = new Blob(this.audioChunks, {type: 'audio/mpeg-3'});
                    this.recordedAudioBlob = URL.createObjectURL(blob);
                    this.sendData(blob)
                }
            }
        },
        sendData() {
            let player = document.getElementById('player');
            player.load();
        },
        uploadNote() {
            let h = new Headers();
            h.append('tl-token', tokenMan.token());
            let blob = new Blob(this.audioChunks, {type: 'audio/mpeg-3'});
            let myFile = new File([blob], 'upload.mp3');
            let fd = new FormData();
            fd.append('audio', myFile);

            let req = new Request(this.serverAddress + '/upload/voiceNote', {
                method: 'POST',
                headers: h,
                mode: 'cors',
                body: fd
            });
            fetch(req)
                .then((response) => {
                    if (response.ok == true) {
                        return response.text();
                    } else {
                        throw new Error('Bad Stuff')
                    }
                })
                .then((jsonData) => {
                    this.uploading = false;
                    this.dataExists = true;
                    this.uploadVoiceNoteAudioID = JSON.parse(jsonData).id;
                })
                .catch((e) => {
                    // eslint-disable-next-line
                    console.log(e);
                    this.dataExists = false;
                })
        },
        addVoiceNote() {

            this.addingNoteBusy = true;

            let jSend = {
                taskID: this.task.taskID,
                stepID: this.selectedStep.stepID,
                lookup: this.uploadVoiceNoteAudioID,
                notifyUsers: [],
                type: 2
            };

            this.selectedUsers.forEach((user, i) => {
                jSend.notifyUsers.push(user.userID);
            });

            this.fetch({method: 'PATCH', url:`/tasks/newNote/${this.userInfo.entityID}/${this.userInfo.userID}`, body: JSON.stringify(jSend)})
                .then((data) => {
                    this.addingNoteBusy = false;
                    try {
                        if (this.noteReplyID != 0) {
                            this.showSnack('Info', 'Reply Sent', 'Close', 'primary');
                            this.$emit('noteReplied')
                            this.$emit('dismiss');
                        } else {
                            // Compose
                            this.task.structure.notes.splice(0, 0, data[0]);
                            this.addingNoteBusy = false;
                            this.$emit('dismiss');
                        }
                    }
                    catch (e) {
                        // eslint-disable-next-line
                        console.log(e);
                    }
                })
                .catch((e) => {
                    // eslint-disable-next-line
                    console.log(e)
                    this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                    this.addingNoteBusy = false;
                })

        },
        addNote() {
            this.addingNoteBusy = true;

            let jSend = {
                taskID: this.task.taskID,
                stepID: this.selectedStep.stepID,
                lookup: this.noteBody
                    .replace(/data-v-.*?(="")/g, '')
                    .replace(/class="((?=v-dialog__content)|(?=v-dialog__content--active)|(?=v-dialog)|(?=v-dialog--active)|(?=v-dialog--persistent)|(?=v-dialog--fullscreen)|(?=v-card)|(?=v-card--flat)|(?=v-sheet)|(?=theme--light)|(?=layout)|(?=column)|(?=fill-height)|(?=flex)|(?=scrollable)|(?=pa-2)|(?=xs12)|(?=flex)|(?=shrink))[^\n"]+(?=")/gi, 'class="')
                ,
                notifyUsers: [],
                type: 0
            };
            this.selectedUsers.forEach((user) => {
                jSend.notifyUsers.push(user.userID)
            });

            this.fetch({method: 'PATCH', url:`/tasks/newNote/${this.userInfo.entityID}/${this.userInfo.userID}`, body: JSON.stringify(jSend)})
                .then((data) => {
                    this.addingNoteBusy = false;

                    if (this.draft.draftID != 0) {
                        this.$emit('deleteDraft', this.draft.draftID)
                    }

                    try {
                        if (this.noteReplyID != 0) {
                            this.$emit('noteReplied');
                            eventBus.$emit('noteReplied', this.noteReplyID);
                            this.$emit('dismiss')
                        } else {
                            // Compose
                            this.task.structure.notes.splice(0, 0, data[0]);
                            this.addingNoteBusy = false;
                            this.$emit('dismiss')
                        }
                    }
                    catch (e) {
                        console.log(e);
                        this.addingNoteBusy = false;
                    }

                })
                .catch((e) => {
                    // eslint-disable-next-line
                    console.log(e)
                    this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                    this.addingNoteBusy = false;
                });

        },
        addChecklist() {

            this.addingNoteBusy = true;

            let jSend = {
                taskID: this.task.taskID,
                stepID: this.selectedStep.stepID,
                lookup: [],
                notifyUsers: [],
                type: 1
            };

            this.selectedUsers.forEach((user, i) => {
                jSend.notifyUsers.push(user.userID);
            });

            this.checklistItems.forEach((itemDescription) => {
                jSend.lookup.push({
                    description: itemDescription,
                    value: false,
                    updateUserID: 0,
                    updatedUserName: '',
                    updateDate: ''
                })
            });

            this.fetch({method: 'PATCH', url:`/tasks/newNote/${this.userInfo.entityID}/${this.userInfo.userID}`, body: JSON.stringify(jSend)})
                .then((data) => {
                    if (this.draft.draftID != 0) {
                        this.$emit('deleteDraft', this.draft.draftID)
                    }
                    try {
                        if (this.noteReplyID != 0) {
                            this.showSnack('Info', 'Reply Sent', 'Close', 'primary');
                            this.$emit('noteReplied')
                            this.$emit('dismiss');
                        } else {
                            // Compose
                            this.task.structure.notes.splice(0, 0, data[0]);
                            this.addingNoteBusy = false;
                            this.$emit('dismiss')
                        }

                    }
                    catch (e) {
                        console.log(e);
                        this.addingNoteBusy = false;
                    }
                })
                .catch((e) => {
                    // eslint-disable-next-line
                    console.log(e);
                    this.addingNoteBusy = false;
                    this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                })

        },
        getAuthorizedUsers() {

            this.fetch({method: 'GET', url:`/tasks/taskTransferAuthorizedUsers/${this.userInfo.entityID}/${this.task.taskID}`})
                .then((data) => {
                    try {
                        this.users = data;
                        this.showLoading = false;

                        if (this.replyUsers.length > 0) {
                            this.selectedUsers = this.users.filter(obj => this.replyUsers.includes(obj.userID))
                        }

                        if (this.replyUsers.includes(-2)) {
                            const assistUser = {
                                "userID": -2,
                                "userFullName": "NBM Assist User",
                                "userName": "NBM Assist User",
                                "userSurname": "NBM Assist User",
                                "outOfOffice": 0,
                                "jobTitle": ""
                            }
                            this.users.splice(0,0, assistUser)
                            this.selectedUsers.push(assistUser)
                        }
                    }
                    catch (e) {
                        console.log(e);
                    }
                })
                .catch((e) => {
                    // eslint-disable-next-line
                    console.log(e)
                    this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                })
        },
        weedUsers() {
            this.selectedUsers.forEach((user, i) => {
                if (typeof user.userFullName == 'undefined') {
                    this.selectedUsers.splice(i, 1);
                }
            })
        },
        addChecklistItem() {
            if (this.addItem != '') {
                this.checklistItems = this.checklistItems.concat(this.addItem.split(';').map(obj => {return obj.trim()}));
                this.addItem = '';
            }
        },
        removeItem(i) {
            this.checklistItems.splice(i, 1)
        },

        uploadImage(blobInfo, success) {
            ++this.imageCount
            let h = new Headers();
            let fd = new FormData();
            let myFile = blobInfo.blob();
            fd.append('attachmentFile', myFile);

            console.log(this.serverAddress)

            axios.request({
                method: "post",
                url: `${this.serverAddress}/upload/noteAttachment/${this.userInfo.entityID}`,
                data: fd,
                onUploadProgress: (p) => {
                    this.uploadProgress = (p.loaded / p.total) * 100
                },
            })
                .then(data => {
                    success(data.data.link);
                    --this.imageCount;
                })
                .catch(() => {
                    this.showSnack('Error', 'Image Upload Error', 'Close', 'red');
                    success('.blank');
                    --this.imageCount;
                })
        },
    },
    destroyed() {

    },

    computed: {

        showAdd() {
            switch (this.model) {
                case 0: {

                    return true;
                    // return this.noteBody != '' ;
                }
                case 1: {
                    return this.checklistItems.length > 0;
                }
                case 2: {
                    return this.uploadVoiceNoteAudioID != null;
                }
                default: {
                    return false
                }
            }
        },

        editorConfig() {
            // testDrop(event, editor) {
            //     console.log('DROP EVENT', event)
            //
            //     editor.insertContent('<span style="background: fuchsia; display: inline-block; cursor: pointer" :draggable="true">Test123456</span>')
            // },
            // paste_data_images: true,
            // images_upload_handler: this.uploadImage,
            // init_instance_callback: function(editor) {
            //     editor.on('drop', function(e) {
            //         vm.testDrop(e, editor)
            // });
            // },
            // uploadImage(blobInfo, success) {
            //     ++this.imageCount
            //     let h = new Headers();
            //     let fd = new FormData();
            //     let myFile = blobInfo.blob();
            //     fd.append('attachmentFile', myFile);
            //
            //     axios.request({
            //         method: "post",
            //         url: `${this.serverAddress}/upload/noteAttachment/${this.userInfo.entityID}`,
            //         data: fd,
            //         onUploadProgress: (p) => {
            //             this.uploadProgress = (p.loaded / p.total) * 100
            //         },
            //     })
            //         .then(data => {
            //             success(data.data.link);
            //             --this.imageCount;
            //         })
            //         .catch(() => {
            //             this.showSnack('Error', 'Image Upload Error', 'Close', 'red');
            //             success('.blank');
            //             --this.imageCount;
            //         })
            // },

            return {
                paste_data_images: true,
                images_upload_handler: this.uploadImage,
                init_instance_callback: function(editor) {
                    editor.focus()
                },
            }
        },

        taskNoteArr() {
            try {
                let showSteps = this.taskNotes.structure.steps.filter(step => step.shown).map(step => {return step.stepID})
                return this.taskNotes.structure.notes
                    .reduce((list, note) => {
                        if (note.stepID ? showSteps.includes(note.stepID) : true) {
                            if (this.blankString(this.filterStr) || JSON.stringify(note).toLowerCase().includes(this.filterStr.toLowerCase())) {
                                list.push(note)
                            }
                        }
                        return list
                    }, [])
            } catch (e) {
                return  null
            }
        },

        noteBodyCleaned: {
            get() {
                if (typeof this.noteBody == 'undefined' || this.noteBody === null) {
                    this.$set(this, `noteBody`, '');
                }
                return this.noteBody
            },
            set(val) {
                this.noteBody = val
            }
        },

        config() {
            let vm = this;
            return {
                // toolbarVisibleWithoutSelection: true,
                // toolbarInline: true,
                enter: VueFroala.ENTER_BR,
                fontSize: 	['8', '9', '10', '11', '12', '14', '16', '18', '24', '30', '36', '48', '60', '72', '96'],
                toolbarSticky: true,
                scrollableContainer: '#editorFlex',
                editorClass: 'froala-editor-class',
                tableInsertHelper: true,
                attribution: false,
                toolbarButtons: {
                    // Key represents the more button from the toolbar.
                    moreText: {
                        // List of buttons used in the  group.
                        buttons: ['bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'fontFamily', 'fontSize', 'textColor', 'backgroundColor', 'inlineClass', 'inlineStyle', 'clearFormatting'],

                        // Alignment of the group in the toolbar.
                        align: 'left',

                        // By default, 3 buttons are shown in the main toolbar. The rest of them are available when using the more button.
                        buttonsVisible: 3
                    },
                    moreParagraph: {
                        buttons: ['alignLeft', 'alignCenter', 'formatOLSimple', 'alignRight', 'alignJustify', 'formatOL', 'formatUL', 'paragraphFormat', 'paragraphStyle', 'lineHeight', 'outdent', 'indent', 'quote'],
                        align: 'left',
                        buttonsVisible: 3
                    },

                    moreRich: {
                        buttons: ['insertLink', 'insertImage', 'insertVideo', 'insertTable', 'emoticons', 'fontAwesome', 'specialCharacters', 'embedly', 'insertFile', 'insertHR'],
                        align: 'left',
                        buttonsVisible: 3
                    },

                    moreMisc: {
                        buttons: ['undo', 'redo', 'fullscreen', 'print', 'getPDF', 'spellChecker', 'selectAll', 'html', 'help'],
                        align: 'right',
                        buttonsVisible: 2
                    }
                },
                imageEditButtons: ['imageReplace', 'imageAlign', 'imageRemove', '|', 'imageLink', 'linkOpen', 'linkEdit', 'linkRemove', '-', 'imageDisplay', 'imageStyle', 'imageAlt', 'imageSize'],

                imageUploadParam: 'attachmentFile',
                imageUploadURL: `${this.baseServerAddress}upload/noteAttachment/${this.userInfo.entityID}`,
                imageUploadMethod: 'POST',

                // requestHeaders: {
                
                //     'tl-token': tokenMan.token(),
                // },

                requestHeaders: JSON.parse(`{
                        "tl-user-key": "tokenMan",
                        "tl-token": "tokenM"}`),

                imageMaxSize: 5 * 1024 * 1024,
                events: {
                    'image.inserted': this.imageInserted,
                    'initialized': function () {
                        var editor = this;
                        vm.editor = editor;
                    },

                    // 'image.removed': this.imageRemoved,



                    // 'html.get': function (html) { this.noteBody= html;}.bind(this)

                    // 'image.beforeUpload': this.beforeImageUpload
                },
                key: "ERB2zA2B2A8A1B2A1qYFd1UQRFQIVb1MSMc2IWPNe1IFg1yD4C3D2E2C4B1E1H4B2D3==",
                placeholderText: 'Edit Note Content',
                charCounterCount: false,
                tableResizerOffset: 10,
                zIndex: 99999,
                tableStyles: 	{
                    'fr-dashed-borders': 'Dashed Borders',
                    'fr-alternate-rows': 'Alternate Rows',
                    'froalatablestyle': 'No Border'
                },
                tabSpaces: 4
            }
        },
        filteredUsers() {
            return (this.userSearchString != null && this.userSearchString != ''
                    ? this.users.filter(obj =>
                            obj.userFullName
                                    .toLowerCase()
                                    .includes(this.userSearchString.toLowerCase()))

                    : [...this.users])
                    .sort((a,b) => (a['userFullName'] || '').toLowerCase() < (b['userFullName'] || '').toLowerCase() ? -1 : 1)
        }
    }
}
</script>

<style scoped>
audio:focus {
    outline: none;
}

div.v-card.noteCon {
    border-radius: 5px;
}
</style>
