<template>

    <div>

        <div @click="showUpload.show = true">
            <slot name="document">
            </slot>
        </div>

        <app-document-upload-dlg
                :dialog="showUpload"
                :user-info="userInfo"
                :task="task"
                :selected-step="selectedStep"
                @documentsSaved="updateBar"
        >
        </app-document-upload-dlg>

        <div @click="showVoip = true">
            <slot name="voip">
            </slot>
        </div>
      
      <yeastar-s-series-link-to-task
          v-if="userInfo.yeastarExtension && userInfo.entityInfo.yeastarSSeriesEnabled && showVoip"
          v-model="showVoip"
          :task-i-d="task.taskID"
          :task="task"
          :step-i-d="selectedStep.stepID"
          @addedDocument="updateBar"
      ></yeastar-s-series-link-to-task>
      
      <yeastar-p-series-link-to-task
          v-else-if="userInfo.yeastarExtension && userInfo.entityInfo.yeastarPSeriesEnabled && showVoip"
          v-model="showVoip"
          :task-i-d="task.taskID"
          :task="task"
          :step-i-d="selectedStep.stepID"
          @addedDocument="updateBar"
      ></yeastar-p-series-link-to-task>
      
        <voip3-c-x-link-to-task
                v-else-if="userInfo['3CXServerIndex'] > -1 && showVoip"
                v-model="showVoip"
                :user-info="userInfo"
                :task-i-d="task.taskID"
                :task="task"
                :step-i-d="selectedStep.stepID"
                @addedDocument="updateBar"
        />


    </div>

<!--    <v-layout row wrap v-show="selectedStep.stepID != 0">-->
<!--        <v-flex xs12>-->

<!--            <v-layout row align-center>-->
<!--                <v-flex xs12>-->
<!--                    -->
<!--                </v-flex>-->
<!--                <v-flex shrink v-if="userInfo.entityInfo['3CXEnabled'] == '1'">-->
<!--                    <v-tooltip top>-->
<!--                        <v-btn flat small color="blue" class="pa-0" @click="showVoip = true" slot="activator">-->
<!--                            -->
<!--                        </v-btn>-->
<!--                        <span>Link 3CX Recording</span>-->
<!--                    </v-tooltip>-->
<!--                    -->
<!--                </v-flex>-->
<!--            </v-layout>-->
<!--            <v-divider class="pb-2"></v-divider>-->
<!--        </v-flex>-->


<!--&lt;!&ndash;Document Containers&ndash;&gt;-->
<!--        <template v-for="(document, i) in sortedDocs">-->
<!--            <v-flex xs12 :key="i + 'document'" class="pa-2">-->
<!--                <app-document-container-->
<!--                        :document="document"-->
<!--                        :user-info="userInfo"-->
<!--                />-->
<!--                <v-divider></v-divider>-->
<!--            </v-flex>-->
<!--        </template>-->
<!--    </v-layout>-->
</template>

<script>

    import { eventBus } from '../../../../main';
    import DocumentUploadDlg from './DocumentUploadDlg'
    import DocumentContainer from './DocumentContainer'
    import Voip3CXLinkToTask from "../3CX Link To Task/voip3CXLinkToTask";
    import YeastarSSeriesLinkToTask from "@/Tasks/TaskView V2/components/YeastarSSeries/YeastarSSeriesLinkToTask.vue";
    import YeastarPSeriesLinkToTask from "@/Tasks/TaskView V2/components/YeastarPSeries/YeastarPSeriesLinkToTask.vue";
    

    export default {
        data() {
            return {
                removeCheck: false,
                showUpload: {
                    show: false
                },
                iReset: 0,
                showVoip: false
            }
        },
        components: {
          YeastarPSeriesLinkToTask,
          YeastarSSeriesLinkToTask,
            Voip3CXLinkToTask,
            appDocumentContainer: DocumentContainer,
            appDocumentUploadDlg: DocumentUploadDlg
        },
        props: {
            selectedStep: Object,
            userInfo: Object,
            task: Object,
            single: {
                type: Boolean,
                default: false
            }
        },
        methods: {
            updateBar() {
                this.showUpload = {show: false}
                this.iReset = ++this.iReset;
                this.$forceUpdate();
            },
            mailAttchmentLinkedToStep() {
                this.iReset = ++this.iReset;
                this.$forceUpdate()
            },
        },
        mounted() {
            //this.checkNoteChanges();
        },
        beforeDestroy() {
            this.removeCheck = true;
            eventBus.$off('mailAttchmentLinkedToStep', this.mailAttchmentLinkedToStep)
        },
        created() {
            eventBus.$on('mailAttchmentLinkedToStep', this.mailAttchmentLinkedToStep)
        },
        computed: {
            sortedDocs() {
                let tDocs = [];
                if (this.iReset != -1) {
                    if (this.task.structure.documents != null && this.task.structure.documents.length > 0) {
                        this.task.structure.documents.forEach(document => {
                            if (document.stepID == this.selectedStep.stepID) {
                                tDocs.push(document)
                            }
                        });
                        tDocs.sort((a,b) => new Date(a['created']) > new Date(b['created']) ? -1 : 1);
                    }
                }
                return tDocs;
            }
        }

    }
</script>

<style scoped>

</style>
