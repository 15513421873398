<template>
    <v-layout column fill-height style="overflow-y: auto">
        <v-flex xs12 style="overflow-y: auto">
            <keep-alive>
                <app-microsoft
                        v-if="curView === 'mail'"
                        :user-info="userInfo"
                        :task-data="taskData"
                        @showLinkOffice="$emit('showLinkOffice', $event)"
                        :show-link-office="showLinkOffice"
                        @setOfficeMail="$emit('setOfficeMail', $event)"
                        @createTaskOffice="$emit('createTaskOffice', $event)"
                        @setQT="$emit('setQT', $event)"
                        :office-config="mailConfig.office"
                        :new-message-fn="composeMailOffice"
                        @click="$emit('showLinkOffice', false)"
                        :get-task-header="getTaskHeader"
                        :cur-view="curView"
                />
                <smtp-mail
                        v-if="curView === 'smtp'"
                        :show-link-smtp="showLinkSmtp"
                        :mail-config="mailConfig"
                        :get-task-header="getTaskHeader"
                        :local-data="localData"
                        :user-info="userInfo"
                        @createTaskSMTP="$emit('createTaskSMTP', $event)"
                        @createTaskSMTPMailbox="$emit('createTaskSMTP', $event)"
                        @setQT="$emit('setQT', $event)"
                        @selectedSmtpMails="$emit('selectedSmtpMails', $event)"
                />

                <mailboxes
                        v-if="curView === 'mailboxes'"
                        @selectedSmtpMailboxMails="$emit('selectedSmtpMailboxMails', $event)"
                        @createTaskSMTPMailbox="$emit('createTaskSMTPMailbox', $event)"
                        :get-task-header="getTaskHeader"
                />

            </keep-alive>

            <div class="overlay" v-if="showLinkOffice" @click="$emit('showLinkOffice', false); $emit('showLinkSmtp', false)"></div>

            <app-compose-mail
                v-if="showCompose"
                    :show-dialog="showCompose"
                    :user-info="userInfo"
                    @dismiss="showCompose = false"
                    :mail-config="mailConfig"
                    :link-to-task="false"
                    :compose-type="mailComposeType"
                    :src-mail="mailComposeMailSrc"
                    :src-mail-body-src="mailComposeMailSrcBody"
                    :src-mail-path="mailComposeMailPath"
            />
        </v-flex>
    </v-layout>
</template>

<script>
    import Microsoft from './Microsoft/Microsoft';
    import ComposeMail from "../../components/General/ComposeMail";
    import SmtpMail from "../SMTP/smtpMail";
    import Mailboxes from "@/Mail/Mailboxes/Mailboxes";

    export default {
        components: {
            Mailboxes,
            SmtpMail,
            appComposeMail: ComposeMail,
            appMicrosoft: Microsoft
        },
        props: {
            taskData: Object,
            userInfo: Object,
            showLinkOffice: Boolean,
            showLinkSmtp: Boolean,
            mailConfig: Object,
            getTaskHeader: Function,
            localData: Object,
            curView: String
        },
        data() {
            return {
                showCompose: false,
                mailComposeType: 0,
                mailComposeMailSrc: null,
                mailComposeMailSrcBody: null,
                mailComposeMailPath: null
            }
        },
        methods: {
            composeMailOffice(type, srcMail, srcMailBody) {
                this.mailComposeType = type;
                this.mailComposeMailSrc = srcMail;
                this.mailComposeMailSrcBody = srcMailBody;
                this.mailComposeMailPath = 'office';
                this.showCompose = true;
            }
        }

    }
</script>

<style scoped>
    .overlay {
        position: fixed; /* Sit on top of the page content */
        width: 100%; /* Full width (cover the whole page) */
        height: 100%; /* Full height (cover the whole page) */
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0,0,0,0.5); /* Black background with opacity */
        z-index: 1; /* Specify a stack order in case you're using a different order for other elements */
        cursor: pointer; /* Add a pointer on hover */
        display: block;
    }
</style>
