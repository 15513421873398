<template>
    <div>
        <template v-if="value.length > 0">
<!--Drag Operators-->
            <div class="px-2">
                <div :class="topicClass">
                    <div :class="headingClass">
                        Operations (Drag and drop on Field Placeholders to add):
                    </div>
                    <div :class="itemClass">
                        <v-btn @dragstart="$event.dataTransfer.setData('text', JSON.stringify({operation: `+`, type: 'operator'}))" draggable="true" small flat icon class="pa-0 ma-0 py-1" color="secondary">{{`+`}}</v-btn>
                        <v-btn @dragstart="$event.dataTransfer.setData('text', JSON.stringify({operation: `-`, type: 'operator'}))" draggable="true" small flat icon class="pa-0 ma-0 py-1" color="secondary">{{`-`}}</v-btn>
                        <v-btn @dragstart="$event.dataTransfer.setData('text', JSON.stringify({operation: `*`, type: 'operator'}))" draggable="true" small flat icon class="pa-0 ma-0 py-1" color="secondary">{{`*`}}</v-btn>
                        <v-btn @dragstart="$event.dataTransfer.setData('text', JSON.stringify({operation: `/`, type: 'operator'}))" draggable="true" small flat icon class="pa-0 ma-0 py-1" color="secondary">{{`/`}}</v-btn>
                        <v-btn @dragstart="$event.dataTransfer.setData('text', JSON.stringify({operation: `(`, type: 'operator'}))" draggable="true" small flat icon class="pa-0 ma-0 py-1" color="secondary">{{`(`}}</v-btn>
                        <v-btn @dragstart="$event.dataTransfer.setData('text', JSON.stringify({operation: `)`, type: 'operator'}))" draggable="true" small flat icon class="pa-0 ma-0 py-1" color="secondary">{{`)`}}</v-btn>
                        <v-btn @dragstart="$event.dataTransfer.setData('text', JSON.stringify({operation: `equal`, type: 'equal'}))" draggable="true" small flat icon class="pa-0 ma-0 py-1" color="secondary">{{`=`}}</v-btn>
                        <v-btn @dragstart="$event.dataTransfer.setData('text', JSON.stringify({operation: `<`, type: 'operator'}))" draggable="true" small flat icon class="pa-0 ma-0 py-1" color="secondary">{{`<`}}</v-btn>
                        <v-btn @dragstart="$event.dataTransfer.setData('text', JSON.stringify({operation: `>`, type: 'operator'}))" draggable="true" small flat icon class="pa-0 ma-0 py-1" color="secondary">{{`>`}}</v-btn>
                        <v-btn @dragstart="$event.dataTransfer.setData('text', JSON.stringify({operation: `d`, type: 'd'}))" draggable="true" small flat round class="pa-0 ma-0 py-1" color="secondary">{{`Days`}}</v-btn>
                        <v-btn @dragstart="$event.dataTransfer.setData('text', JSON.stringify({operation: `w`, type: 'w'}))" draggable="true" small flat round class="pa-0 ma-0 py-1" color="secondary">{{`Weeks`}}</v-btn>
                        <v-btn @dragstart="$event.dataTransfer.setData('text', JSON.stringify({operation: `m`, type: 'm'}))" draggable="true" small flat round class="pa-0 ma-0 py-1" color="secondary">{{`Months`}}</v-btn>
                        <v-btn @dragstart="$event.dataTransfer.setData('text', JSON.stringify({operation: `y`, type: 'y'}))" draggable="true" small flat round class="pa-0 ma-0 py-1" color="secondary">{{`Years`}}</v-btn>
                        <v-btn @dragstart="$event.dataTransfer.setData('text', JSON.stringify({operation: `h`, type: 'h'}))" draggable="true" small flat round class="pa-0 ma-0 py-1" color="secondary">{{`Hours`}}</v-btn>
                        <v-btn @dragstart="$event.dataTransfer.setData('text', JSON.stringify({operation: `mi`, type: 'mi'}))" draggable="true" small flat round class="pa-0 ma-0 py-1" color="secondary">{{`Minutes`}}</v-btn>
                        <v-btn @dragstart="$event.dataTransfer.setData('text', JSON.stringify({operation: `s`, type: 's'}))" draggable="true" small flat round class="pa-0 ma-0 py-1" color="secondary">{{`Seconds`}}</v-btn>
                        <v-btn @dragstart="$event.dataTransfer.setData('text', JSON.stringify({operation: `now`, type: 'now'}))" draggable="true" small flat round class="pa-0 ma-0 py-1" color="secondary">{{`NOW`}}</v-btn>
                        <v-btn @dragstart="$event.dataTransfer.setData('text', JSON.stringify({operation: `value`, type: 'value'}))" draggable="true" small flat round class="pa-0 ma-0 py-1" color="secondary">{{`Value`}}</v-btn>
                        <v-btn @dragstart="$event.dataTransfer.setData('text', JSON.stringify({operation: `field`, type: 'field'}))" draggable="true" small flat round class="pa-0 ma-0 py-1" color="secondary">{{`Dataset Field`}}</v-btn>
                    </div>
                </div>
            </div>
            <v-divider/>
<!--Drop Fields-->
            <div>
                <div
                        v-for="field in value"
                        :key="field.id"
                >
                    <action-field
                            :user-info="userInfo"
                            :action-field="field"
                            :fields="fields"
                            @updateActions="updateAction(field, $event)"
                            @editField="editFields.push($event)"
                            @removeField="removeAction($event)"
                            :edit-fields="editFields"
                    />
                    <v-divider/>
                </div>
            </div>
        </template>



        <div class="pa-2">
            <v-btn
                    small
                    outline
                    block
                    color="secondary"
                    class="ma-0"
                    @click="showAddField = true"
                    v-if="availableAddFields.length > 0"
            >
                <v-icon>add</v-icon>
                Add New Field Action
            </v-btn>
        </div>
        <app-input-dlg
                :show-dialog="showAddField"
                title="Add New Field Action"
                :cancel-button="true"
                :show-input-box="false"
                @dismiss="showAddField = false"
                :body-padding="false"
        >
            <template slot="body">
                <div class="pa-2">
                    <div :class="topicClass">
                        <div :class="headingClass">
                            Please Select a Field to Update:
                        </div>
                        <div :class="itemClass">
                            <v-autocomplete
                                    v-if="showAddField"
                                    color="primary"
                                    label="Select Field"
                                    :items="availableAddFields"
                                    item-text="description"
                                    return-object
                                    flat
                                    v-model="selectedAddField"
                                    hide-details
                                    class="pa-0 ma-0 pt-2"
                                    dense
                                    single-line
                                    ref="addFieldAutoComplete"
                                    @keydown.enter="addNewField"
                            >
                            </v-autocomplete>
                        </div>
                    </div>
                </div>
            </template>
            <template slot="actions">
                <v-btn
                        small
                        color="secondary"
                        class="ma-0"
                        flat
                        :disabled="selectedAddField == null"
                        @click="addNewField"
                >
                    Add Field
                </v-btn>
            </template>
        </app-input-dlg>
    </div>
</template>

<script>
    import {codeBaseMixin} from "../../../codeBaseMixin";
    import InputDlg from "../../../components/General/InputDlg";
    import ActionField from "./ActionField";

    export default {
        components: {
            ActionField,
            appInputDlg: InputDlg
        },
        mixins: [codeBaseMixin],
        props: {
            value: Array,
            userInfo: Object,
            fields: Array
        },
        data() {
            return {
                showAddField: false,
                selectedAddField: null,
                editFields: [],
            }
        },
        watch: {
            showAddField(shown) {
                this.selectedAddField = null;
                if (shown) {
                    this.$nextTick(() => {
                        this.$refs.addFieldAutoComplete.focus();
                    })
                }
            },
        },
        methods: {
            removeAction(fieldID) {
                let tTemp = JSON.parse(JSON.stringify(this.value))
                tTemp.forEach((actionField, i) => {
                    if (fieldID == actionField.field) {
                        tTemp.splice(i, 1)
                    }
                });
                this.$emit('input', tTemp)
            },
            updateAction(field, actions) {
                let tTemp = JSON.parse(JSON.stringify(this.value))
                tTemp.forEach(actionField => {
                    if (field.field == actionField.field) {
                        actionField.actions = actions
                    }
                });
                this.$emit('input', tTemp)
            },

            addNewField() {
                if (this.selectedAddField == null) {
                    this.showSnack('Error', 'Please Select a Field', 'Close', 'red');
                } else {
                    this.$emit('input', this.value.concat([{
                        field: this.selectedAddField.id,
                        actions: []
                    }]));
                    this.editFields.push(this.selectedAddField.id);
                    this.selectedAddField = null;
                    this.showAddField = false;

                }
            }
        },
        computed: {
            availableAddFields() {
                let tAddedFieldIDs = this.value.map(obj => {return obj.field});

                return this.fields
                    .filter((field) =>
                        !tAddedFieldIDs.includes(field.id)
                        && field.type <= 5
                    )
                    .sort((a,b) => a['description'] < b['description'] ? -1 : 1);

            }
        }

    }
</script>

<style scoped>

</style>
