import {codeBaseMixin} from "./codeBaseMixin";
import {mapActions} from "vuex";

export const localDataMixin = {
    mixins: [codeBaseMixin],
    data() {
        return {
            userInfo: {},
            localDataBases: {
                data: {db: null, syncing: true},
                tasks: {db: null, syncing: true}
            },
            localData: {
                folders: [],
                folderGroups: [],
                users: [],
                userGroups: [],
                workflows: [],
                workspaceGroups: [],
                workspaces: [],
                workflowGroups: [],
            },
            headerVault: [],
            downloadingHeaderRequests: false,
            allVaultIDs: [],
            dbLoadDone: false,
            folderSummarySplit: [],
            flatFolderGroups: null,
            flatWorkflows: [],
            initTasks: false,
            taskDataStore: {
                tasks: [],
                taskLoading: true,
                filterSettings: {
                    userOption: 1,
                    stateOption: 2,
                    orderOption: 1,
                    groupOption: 0,
                    starOption: 0,
                    taskOption: 0,
                    escalationOption: 0
                },
            },
            closedOffset: 0,
            curClosedFilterOffset: 0,
            loadMore: false,
            lastFilterSearch: null,
            initTasksLoaded: false,
            refVault: [],
            batchTasks: [],
            batchID: 0,
            failedBatchTasks: [],
            showSync: true,
            initialSync: false,
            escalationMonitor: [],
            usersUserGroups: [],
            processedEscalations: false,

            taskWakes: []
        }
    },
    methods: {
        ...mapActions(['setLocalData']),
        startFolders() {



        },
        getSnoozedTasksForUser() {
            return new Promise ((resolve, reject) => {
                this.fetch({method: 'GET', url:`/tasks/sn/snoozedForUser/${this.userInfo.entityID}/${this.userInfo.userID}`})
                    .then((data) => {
                        data.forEach(snObj => {this.addSnoozedTask(snObj)})
                        resolve(true)
                    })
                    .catch((e) => {
                        reject(e)
                    })
            })
        },
        getSnoozed(taskID) {
            try {
                if (typeof this.taskWakes[taskID] !== 'undefined' && this.taskWakes[taskID] !== null) {
                    return {
                        snoozed: true,
                        snoozeDate: this.taskWakes[taskID].obj.activation_date,
                        snoozeID: this.taskWakes[taskID].obj.id,
                    }
                } else {
                    return {
                        snoozed: false,
                        snoozeDate: null,
                        snoozeID: null,
                    }
                }
            } catch (e) {
                // eslint-disable-next-line
                console.log(e)
                return {
                    snoozed: false,
                    snoozeDate: null,
                    snoozeID: null,
                }
            }
        },
        addSnoozedTask(snObj, curMS=null) {
            let tTemp = {
                activation_date: "2020-06-18T13:46:00.000Z",
                fk_task: 20496,
                fk_user: 1,
                id: 64879,
                notificationState: 0
            };

            let ttl = (new Date(snObj.activation_date).getTime()) - (curMS == null ? new Date().getTime() : curMS);

            let wake = null;
            if (ttl <= (((1000 * 60) * 60) * 24)) {
                wake = setTimeout(() => {
                    this.removeSnoozed(snObj.fk_task)
                }, ttl)
            }

            this.taskWakes[snObj.fk_task] = {
                obj: snObj,
                wake: wake
            }
            try {
                let task = this.refVault[Math.floor(snObj.fk_task / 100)][snObj.fk_task];
                task.snoozed = true;
                task.snoozeDate = snObj.activation_date;
                task.snoozeID = snObj.id;
            } catch (e) {
                // No Task Header To Update
            }

        },
        removeSnoozed(taskID) {
            // Update Task Header
            try {
                let task = this.refVault[Math.floor(taskID/100)][taskID]
                task.snoozed = false;
                task.snoozeDate = null;
                task.snoozeID = null;
            } catch (e) {
                // eslint-disable-next-line
                console.log(e)
            }

            if (typeof this.taskWakes[taskID] !== 'undefined' && this.taskWakes[taskID] !== null) {
                // Check If snObj Has A Wake And Disable
                if (typeof this.taskWakes[taskID].wake !== 'undefined' && this.taskWakes[taskID].wake !== null) {
                    clearTimeout(this.taskWakes[taskID].wake)
                    this.taskWakes[taskID].wake = null;
                }

                // Remove Task From Task Wakes
                this.taskWakes[taskID] = undefined;
            }
        },
        updateUsersUserGroups(clearValues = false, value) {
            if (!clearValues) {
                this.usersUserGroups.push(value)
            } else {
                this.usersUserGroups = []
            }
        },
        processEscalation(task) {
            if (this.flatWorkflows.length > 0) {

                // Check if The Task Status Has Escalation Groups Assigned To It
                try {
                    if (this.intToBool(this.flatWorkflows[task.workflowID].workflowStatuses[task.workflowStatus].escalationEnabled)) {
                        let extentions = this.flatWorkflows[task.workflowID].workflowStatuses[task.workflowStatus].extentions;

                        let view = false;
                        let viewStart = null;
                        let viewEnd = null;

                        (extentions || []).forEach(ext => {
                            let extUserGroups = JSON.parse(`[${ext.fk_user_group}]`);

                            if (extUserGroups.reduce((access, extGroup) => {
                                if (!access && this.usersUserGroups.includes(extGroup)) {
                                    access = true
                                }
                                return access
                            }, false)) {
                                view = true;

                                // User Is A Member Of The Extention

                                // Calculate The milsec Start After Status Start
                                let milStart =
                                    (ext.minutesViewStart * 60000) +
                                    (ext.hoursViewStart * (60000 * 60)) +
                                    (ext.daysViewStart * ((60000 * 60) * 24)) +
                                    (ext.monthsViewStart * (((60000 * 60) * 24) * 30));

                                if (viewStart == null || milStart < viewStart) {
                                    viewStart = milStart
                                }

                                // Calculate The milsec end after status start
                                let milEnd =
                                    (ext.minutesViewEnd * 60000) +
                                    (ext.hoursViewEnd * 3.6e+6) +
                                    (ext.daysViewEnd * 8.64e+7) +
                                    (ext.monthsViewEnd * 2.628e+9);

                                if (viewEnd !== true && ((viewEnd == null || milEnd > viewEnd) || milEnd === 0)) {
                                    if (milEnd === 0) {
                                        viewEnd = true;
                                    } else {
                                        viewEnd = milEnd
                                    }
                                }
                            }
                        })

                        if (view) {
                            this.$set(this.escalationViewTasks, task.taskID, {
                                taskID: task.taskID,
                                taskObj: task,
                                viewStart: new Date(task.workflowStatusTTE).getTime() + viewStart,
                                viewEnd: viewEnd === true ? true : new Date(task.workflowStatusTTE).getTime() + viewEnd,
                                viewTTE: new Date(task.workflowStatusTTE).getTime(),
                                userOwner: this.userInfo.userID === task.ownerID,
                                // task: task
                            })
                            this.escalationViewTasks.count = ++this.escalationViewTasks.count;
                        }
                    }
                } catch (e) {
                    // eslint-disable-next-line
                    console.log(e)
                }
            } else {
                setTimeout(() => {
                    this.processEscalation(task)
                }, 200)
            }
        },
        escalationFired(task) {
            this.processEscalation(task);
        },
        addEscalation(task, ttCheck) {
            if (task.taskState === 0 && task.workflowStatusHiddenUsers === '[]' ? true : !JSON.parse(task.workflowStatusHiddenUsers).includes(this.userInfo.userID)) {
                if (ttCheck <= 0) {
                    this.processEscalation(task);

                } else {
                    let tTimeout = setTimeout(() => {
                        this.escalationFired(task)
                    }, ttCheck);
                    this.escalationMonitor[task.taskID] = {
                        watch: tTimeout,
                        task: task
                    }
                }
            }
        },
        checkEscalationAdd(tasks) {
            let tNow = new Date().getTime();
            tasks.forEach(task => {

                if (typeof this.escalationMonitor[task.taskID] !== 'undefined') {
                    clearTimeout(this.escalationMonitor[task.taskID].watch);
                    this.escalationMonitor[task.taskID] = undefined;
                }

                if (typeof this.escalationViewTasks[task.taskID] != 'undefined') {
                    delete this.escalationViewTasks[task.taskID];
                    this.escalationViewTasks.count = --this.escalationViewTasks.count;
                }

                if (!this.blankString(task.workflowStatusTTE) && task.taskState === 0 && task.access) {
                    let escMS = new Date(task.workflowStatusTTE).getTime();
                    let escVal = ((((escMS - tNow)/1000) / 60) / 60) / 24
                    if (escVal <= 1) {
                        this.addEscalation(task, (escMS - tNow))
                    }
                }
            })
        },
        getBatchTasks() {
            let tasks = this.batchTasks;
            this.batchTasks = [];

            this.fetch({method: 'POST', url:`/taskList/batchTasks/${this.userInfo.entityID}/${this.userInfo.userID}`, body: JSON.stringify({tasks: tasks})})
                .then((data) => {
                    this._mergeTasksToVault(data);
                })
                .catch((e) => {
                    // eslint-disable-next-line
                    console.log(e);
                    this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
                    this.failedBatchTasks = this.failedBatchTasks.concat(tasks)
                })
        },
        getTaskHeader(taskID) {
            return new Promise ((resolve, reject) => {

                try {
                    if (this.taskExists(taskID)) {
                        // Return Task Header
                        resolve(this.refVault[Math.floor(taskID/100)][taskID])
                    } else {
                        //Add TaskID To Batch Request
                        this.batchTasks.push(taskID);
                        let tInterval = setInterval(() => {

                            // Check if Exists
                            if (this.taskExists(taskID)) {
                                resolve(this.refVault[Math.floor(taskID/100)][taskID])
                            } else {
                                // Task Not There Yet

                                //Check If It Failed
                                if (this.failedBatchTasks.includes(taskID)) {
                                    //Stop Checking And Remove Interval
                                    clearInterval(tInterval);
                                    reject('Error Fetching Task');
                                }
                            }

                        }, 200)
                    }
                } catch (e) {
                    // eslint-disable-next-line

                    reject(e)
                }
            })
        },
        addToRefVault(task) {
            let tBucket = Math.floor(task.taskID/100);
            if (typeof this.refVault[tBucket] === 'undefined') {this.refVault[tBucket] = []}
            this.refVault[tBucket][task.taskID] = task
        },
        taskExists(taskID) {
            try {
                return typeof this.refVault[Math.floor(taskID/100)][taskID] !== 'undefined'
            } catch (e) {
                return false
            }
        },
        closedFilterSearch(filter) {
            if (filter.length <= 0) {
                this.curClosedFilterOffset = 0;
                this.loadClosed();
            } else {
                // Check if its a new filter or the same as the old one
                if (JSON.stringify(filter) !== JSON.stringify(this.lastFilterSearch)) {
                    this.curClosedFilterOffset = 0;
                }
                this.loadClosedWithFilter(filter);
            }

            this.lastFilterSearch = filter
        },
        loadClosedWithFilter(filter) {
            // If Its An Initialization then check your last filter settings to see what kind of tasks to call
            this.downloadingHeaderRequests = ++this.downloadingHeaderRequests;
            this.loadMore = false

            let url = `/tasklist/usertasks/${this.userInfo.entityID}/${this.userInfo.userID}/1/${this.curClosedFilterOffset}`;

            this.fetch({method: 'POST', url: url, body: JSON.stringify(filter)})
                .then((data) => {
                    this._mergeTasksToVault(data, true);
                    this.downloadingHeaderRequests = --this.downloadingHeaderRequests;

                    this.curClosedFilterOffset = this.curClosedFilterOffset + data.length;

                    if (data.length >= 1000) {
                        this.loadMore = true
                    }
                })
                .catch((e) => {
                    // eslint-disable-next-line
                    console.log(e)
                    this.showSnack('Error', 'Network error. Please try again.123456789', 'Close', 'red')
                    this.downloadingHeaderRequests = --this.downloadingHeaderRequests;
                })

        },
        _mergeTasksToVault(tasks, allowAccess) {
            let addedTasks = [];

            tasks.forEach(task => {
                this.prepTaskHeader(task, allowAccess);

                if (!this.taskExists(task.taskID)) {
                    this.headerVault.push(task)
                    this.addToRefVault(task)
                    addedTasks.push(task)
                } else {

                    let tTask = this.refVault[Math.floor(task.taskID/100)][task.taskID]
                    Object.assign(tTask, task);
                    addedTasks.push(tTask)
                }
            });
            //Process Batch Here
            this.checkEscalationAdd(addedTasks)
            this.processTaskList(addedTasks)
        },
        loadClosed() {
            // If Its An Initialization then check your last filter settings to see what kind of tasks to call
            this.downloadingHeaderRequests = ++this.downloadingHeaderRequests;
            this.loadMore = false

            let url = `/tasklist/usertasks/${this.userInfo.entityID}/${this.userInfo.userID}/1/${this.closedOffset}`;

            this.fetch({method: 'GET', url: url})
                .then((data) => {
                    this._mergeTasksToVault(data, true);
                    this.downloadingHeaderRequests = --this.downloadingHeaderRequests;

                    this.closedOffset = this.closedOffset + data.length;

                    if (data.length >= 1000) {
                        this.loadMore = true
                    }
                })
                .catch((e) => {
                    // eslint-disable-next-line
                    console.log(e)
                    this.showSnack('Error', 'Network error. Please try again.123456789', 'Close', 'red')
                    this.downloadingHeaderRequests = --this.downloadingHeaderRequests;
                })

        },
        processTaskList(tasks) {
            if (this.dbLoadDone) {

                let tWorkflowsWithAccess = this._workflowsWithAccess.reduce((list, item) => {
                    if (item.access) {
                        list.push(item.id)
                    }
                    return list
                }, []);

                let tFoldersWithAccess = this._foldersFinal.reduce((list, item) => {
                    if (item.access) {
                        list.push(item.id)
                    }
                    return list
                }, []);

                let tNow = new Date().getTime();

                tasks.forEach(task => {

                    if (!Array.isArray(task.sharedUsers)) {
                        task.sharedUsers = this.parseValue(task.sharedUsers)
                    }

                    task.access =
                        (tWorkflowsWithAccess.includes(task.workflowID))
                        && (
                            (tFoldersWithAccess.includes(task.folderID))
                            || (Array.isArray(task.sharedUsers) && task.sharedUsers.includes(this.userInfo.userID))
                        );

                    try {
                        let flatUser = this._flatUsers[task.ownerID];
                        task.ownerName = flatUser.name;
                        task.ownerSurname = flatUser.surname;
                        task.ownerUsername = flatUser.username;
                        try {
                            let flatSubUser = task.subOwnerID != null ? this._flatUsers[task.subOwnerID] : flatUser;
                            task.subOwnerName = flatSubUser.name;
                            task.subOwnerSurname = flatSubUser.surname;
                            task.subOwnerUsername = flatSubUser.username;
                        } catch (e) {
                            task.subOwnerName = null;
                            task.subOwnerSurname = null;
                            task.subOwnerUsername = null;
                        }
                    } catch (e) {
                        task.ownerName = null;
                        task.ownerSurname = null;
                        task.ownerUsername = null;
                        task.subOwnerName = null;
                        task.subOwnerSurname = null;
                        task.subOwnerUsername = null;
                    }

                    try {
                        task.workflowName = this.flatWorkflows[task.workflowID].description;
                        task.allowShare = this.intToBool(this.flatWorkflows[task.workflowID].allowShare)
                        task.allowOwnerShare  = this.flatWorkflows[task.workflowID].allowOwnerShare;
                        task.shareAdminGroups  = this.flatWorkflows[task.workflowID].shareAdminGroups;
                    } catch (e) {
                        // eslint-disable-next-line
                        task.workflowName = null;
                        task.allowShare = null;
                        task.allowOwnerShare = null;
                        task.shareAdminGroups = null;
                    }

                    let snVal = this.getSnoozed(task.taskID);
                    task.snoozed = snVal.snoozed;
                    task.snoozeDate = snVal.snoozeDate;
                    task.snoozeID = snVal.snoozeID;

                    try {
                        let taskFolder = task.folderID === 0
                            ? {description: 'Task Folder', groupString: 'Task Folder'}
                            : this.folderSummarySplit[Math.floor(task.folderID / 100)]
                                .reduce((folder, item) => {
                                    if (folder == null) {
                                        if (item.id === task.folderID) {
                                            folder = item
                                        }
                                    }
                                    return folder
                                }, null);
                        task.folderName = task.folderID === 0 ? 'Task Folder' : taskFolder.description;
                        task.taskFolder = task.folderID === 0 ? null : taskFolder;
                        task.folderGroupNames = task.folderID === 0 ? 'Task Folder' : taskFolder.groupString;
                    } catch (e) {
                        // eslint-disable-next-line
                        task.folderName = 'ERROR NOT FOUND';
                        task.taskFolder = null;
                        task.folderGroupNames = 'ERROR NOT FOUND';
                    }
                });
            } else {
                setTimeout(() => {
                    this.processTaskList(tasks)
                }, 200)
            }
        },
        taskChange(doc) {
            this.prepTaskHeader(doc);
            this.checkEscalationAdd([doc])

            if (this.headerVault.reduce((replaced, task) =>  {
                if (!replaced) {
                    if (task.taskID == doc.taskID) {
                        replaced = true;

                        if (typeof task.structure != 'undefined') {
                            doc.structure = task.structure
                        }

                        Object.assign(task, doc);
                        this.populateHeader(task);
                    }
                }
                return replaced
            }, false) === false) {
                this.headerVault.push(doc)
                this.addToRefVault(doc)
                this.populateHeader(doc)
            }
        },
        populateHeader(task) {
            if (this.dbLoadDone) {
                let tWorkflowsWithAccess = this._workflowsWithAccess.reduce((list, item) => {if (item.access) {list.push(item.id)} return list }, []);
                let tFoldersWithAccess = this._foldersFinal.reduce((list, item) => {if (item.access) {list.push(item.id)} return list}, []);
                if (!Array.isArray(task.sharedUsers)) {task.sharedUsers = this.parseValue(task.sharedUsers)}
                task.access = (tWorkflowsWithAccess.includes(task.workflowID)) && ((tFoldersWithAccess.includes(task.folderID)) || (Array.isArray(task.sharedUsers) && task.sharedUsers.includes(this.userInfo.userID)));

                let flatUser = this._flatUsers[task.ownerID];
                task.ownerName = flatUser.name;
                task.ownerSurname = flatUser.surname;
                task.ownerUsername = flatUser.username;
                try {
                    let flatSubUser = task.subOwnerID != null ? this._flatUsers[task.subOwnerID] : flatUser;
                    task.subOwnerName = flatSubUser.name;
                    task.subOwnerSurname = flatSubUser.surname;
                    task.subOwnerUsername = flatSubUser.username;
                } catch (e) {
                    task.subOwnerName = null;
                    task.subOwnerSurname = null;
                    task.subOwnerUsername = null;
                }

                task.workflowName = this.flatWorkflows[task.workflowID].description;
                task.allowShare = this.intToBool(this.flatWorkflows[task.workflowID].allowShare);
                task.allowOwnerShare  = this.flatWorkflows[task.workflowID].allowOwnerShare;
                task.shareAdminGroups  = this.flatWorkflows[task.workflowID].shareAdminGroups;

                let snVal = this.getSnoozed(task.taskID);
                task.snoozed = snVal.snoozed;
                task.snoozeDate = snVal.snoozeDate;
                task.snoozeID = snVal.snoozeID;

                try {
                    let taskFolder = task.folderID === 0
                        ? {description: 'Task Folder', groupString: 'Task Folder'}
                        : this.folderSummarySplit[Math.floor(task.folderID / 100)]
                            .reduce((folder, item) => {
                                if (folder == null) {
                                    if (item.id === task.folderID) {
                                        folder = item
                                    }
                                }
                                return folder
                            }, null);
                    task.folderName = task.folderID === 0 ? 'Task Folder' : taskFolder.description;
                    task.taskFolder = task.folderID === 0 ? null : taskFolder;
                    task.folderGroupNames = task.folderID === 0 ? 'Task Folder' : taskFolder.groupString;
                } catch (e) {
                    // eslint-disable-next-line
                    task.folderName = 'ERROR NOT FOUND';
                    task.taskFolder = null;
                    task.folderGroupNames = 'ERROR NOT FOUND';
                }

                this.checkEscalationAdd([task])
            } else {
                setTimeout(() => {
                    this.populateHeader(task)
                }, 200)
            }
        },
        sharedElements(arr1, arr2) {
            try {
                return arr1.filter(x => arr2.includes(x)).length > 0
            } catch (e) {
                // eslint-disable-next-line
                console.log(e)
                return false
            }
        },
        parseValue(value) {
            try {
                return JSON.parse(value)
            } catch (e) {
                // eslint-disable-next-line
                return null
            }
        },
        prepTaskHeader(task, allowAccess) {
            if (!this.blankString(task.sharedUsers) && !Array.isArray(task.sharedUsers)) {
                task.sharedUsers = this.parseValue(task.sharedUsers)
            }


            // Set The Starred Tag First

            if (typeof task.starredUsers != 'undefined') {
                try {
                    let tUsers = JSON.parse(task.starredUsers)
                    task.starred = tUsers.includes(this.userInfo.userID);
                } catch (e) {
                    // eslint-disable-next-line
                    console.log(e)
                }
            } else {
                task.starred = this.intToBool(task.starred)
            }


            task._id = `t-${task.taskID}`;
            if (typeof task.state !== 'undefined' && task.state <= 1) {
                task.preSnoozeState = task.state
            }

            task.snoozed = false;
            task.snoozeDate = null;
            task.snoozeID = null;

            task.folderName = null;
            task.taskFolder = null;
            task.folderGroupNames = null;
            task.access = typeof allowAccess == 'boolean' && allowAccess;
            task.ownerName = null;
            task.ownerSurname = null;
            task.ownerUsername = null;
            task.subOwnerName = null;
            task.subOwnerSurname = null;
            task.subOwnerUsername = null;
            task.workflowName = null;
            task.allowShare = null;
            task.allowOwnerShare = null;
            task.shareAdminGroups = null;
            try {
                task.followers = task.followers == null ? [] : JSON.parse(task.followers)
            } catch {
                task.followers = []
            }

            task.structure = typeof task.structure == 'undefined' || task.structure == null ? {} : task.structure;
            task.structure.steps = typeof task.structure.steps == 'undefined' || task.structure.steps == null ? [] : task.structure.steps;
            task.structure.mails = typeof task.structure.mails == 'undefined' || task.structure.mails == null ? [] : task.structure.mails;
            task.structure.fields = typeof task.structure.fields == 'undefined' || task.structure.fields == null ? [] : task.structure.fields;
            task.structure.draftMails = typeof task.structure.draftMails == 'undefined' || task.structure.draftMails == null ? [] : task.structure.draftMails;
            task.structure.draftNotes = typeof task.structure.draftNotes == 'undefined' || task.structure.draftNotes == null ? [] : task.structure.draftNotes;
        },
        _addTasksToVault(tasks, allowAccess) {
            tasks.forEach(task => {
                // Check Task Formats

                this.prepTaskHeader(task, allowAccess);
                this.addToRefVault(task)

                if (this.taskExists(task.taskID)) {
                    this.headerVault.forEach((vTask, i) => {
                        if (vTask.taskID == task.taskID) {
                            this.headerVault[i] = task
                        }
                    })
                } else {
                    this.headerVault.push(task)
                }
            });
        },
        _initializeHeaders() {
            this.downloadingHeaderRequests = ++this.downloadingHeaderRequests

            this.initTasks = true;

            this.fetch({
                method: 'GET',
                url: `/tasklist/usertasks/${this.userInfo.entityID}/${this.userInfo.userID}/0/0`
            })
                .then((data) => {
                    this._mergeTasksToVault(data, true);
                    this.downloadingHeaderRequests = --this.downloadingHeaderRequests;
                    this.initTasks = false;
                    this.initTasksLoaded = true;
                })
                .catch((e) => {
                    // eslint-disable-next-line
                    console.log(e)
                    this.showSnack('Error', 'Network error. Please try again.123456', 'Close', 'red')
                })
        },
        isEmptyObject(obj) {
            for (var key in obj) {
                if (obj.hasOwnProperty(key))
                    return false;
            }
            return true;
        },
        folderNameChanged(folderDoc) {
            if (!this.showSync) {
                let folderObject = this.folderSummarySplit[Math.floor(folderDoc.id / 100)]
                    .reduce((folder, item) => {
                        if (folder == null) {
                            if (item.id === folderDoc.id) {
                                folder = item
                            }
                        }
                        return folder
                    }, null);

                if (folderObject != null) {
                    this.headerVault.forEach(task => {
                        if (task.folderID === folderObject.id) {
                            task.folderName = folderObject.description;
                            task.taskFolder = folderObject;
                            task.folderGroupNames = folderObject.groupString;
                        }
                    })
                }
            }


        },
        addToFlatWorkflows(reset, value) {
            if (reset) {
                this.flatWorkflows = []
            } else {
                this.flatWorkflows[value.id] = value
            }
        },
    },
    watch: {
        publishedLocalData: {
            immediate: true,
            handler(val) {
                this.setLocalData(val)
            }
        },

        batchTasks: {
            immediate: true,
            deep: true,
            handler(val) {
                if (val.length > 0) {
                    let curBatch = this.batchID + 1;
                    this.batchID = ++this.batchID;
                    setTimeout(() => {
                        if (curBatch === this.batchID && this.batchTasks.length > 0) {
                            this.getBatchTasks(curBatch)
                        }
                    }, 100)
                }
            }
        },

        headerVault: {
            immediate: true,
            deep: true,
            handler(newVal, oldVal) {
                try {
                    if (!Array.isArray(oldVal) || newVal.length !== oldVal.length) {
                        this.allVaultIDs = this.headerVault.map(obj => {return obj.taskID});
                    }
                } catch (e) {
                    // eslint-disable-next-line
                }

            }
        },

        "taskDataStore.filterSettings.stateOption": {
            handler(newV, oldV) {

                let oldVal = oldV <= 2;
                let newVal = newV <= 2;

                if (oldVal !== newVal && !newVal) {
                    this.loadClosed()
                }
            }
        },
    },
    computed: {

        _userGroupsWithAccess () {
            this.updateUsersUserGroups(true);
            return this.localData.userGroups.reduce((obj, group) => {
                let tGroup = {
                    access: Array.isArray(group.users) && group.users.includes(this.userInfo.userID)
                };
                Object.assign(tGroup, group);
                if (tGroup.access) {
                    this.updateUsersUserGroups(false, tGroup.id);
                }
                obj.push(tGroup)
                return obj
            }, []);
        },

        _folderGroupsWithAccess () {
            if (this._userGroupsWithAccess.length > 0) {

                this.flatFolderGroups = {};

                let tUserGroupsWithAccess = this._userGroupsWithAccess.reduce((list, item) => {if (item.access) {list.push(item.id)} return list}, []);

                return this._folderGroups.reduce((obj, group) => {
                    let tGroup = {
                        access: this.sharedElements(group.userGroups, tUserGroupsWithAccess)
                    };
                    Object.assign(tGroup, group);
                    obj.push(tGroup);

                    this.flatFolderGroups[tGroup.id] = tGroup
                    return obj
                }, []);
            } else {
                return []
            }
        },

        _workflowGroupsWithAccess () {
            if (this._userGroupsWithAccess.length > 0 && this.localData.workflowGroups.length > 0) {
                let tUserGroupsWithAccessIDs = this._userGroupsWithAccess.reduce((list, item) => {if (item.access) {list.push(item.id)} return list}, []);
                return this.localData.workflowGroups.reduce((obj, group) => {
                    let tGroup = {
                        access: this.sharedElements(group.userGroups, tUserGroupsWithAccessIDs)
                    };
                    Object.assign(tGroup, group);
                    obj.push(tGroup)
                    return obj
                }, []);
            } else {
                return[]
            }
        },

        _workflowsWithAccess () {
            if (this._workflowGroupsWithAccess.length > 0 && this.localData.workflows.length > 0) {
                this.addToFlatWorkflows(true)
                let _workflowGroupsWithAccess = this._workflowGroupsWithAccess.reduce((list, item) => {if (item.access) {list.push(item.id)} return list}, []);
                return this.localData.workflows.reduce((obj, workflow) => {
                    let tGroup = {
                        access: this.sharedElements(workflow.workflowGroups, _workflowGroupsWithAccess)
                    };
                    Object.assign(tGroup, workflow);
                    obj.push(tGroup);
                    this.addToFlatWorkflows(false, tGroup)
                    return obj
                }, []);
            } else {
                return []
            }
        },

        _foldersFinal() {
            if (this.localData.folders.length > 0 && this.flatFolderGroups != null && !this.showSync) {
                let tfolderGroupsWithAccessIDs = this._folderGroupsWithAccess
                    .reduce((list, item) => {
                        if (item.access) {
                            list.push(item.id)
                        }
                        return list
                    }, []);

                this.folderSummarySplit = {};

                let tFolders = this.localData.folders.reduce((list, folder) => {
                    let tFolder = {
                        access: this.sharedElements(folder.folderGroups, tfolderGroupsWithAccessIDs),
                        groupString: (Array.isArray(folder.folderGroups)
                            ? folder.folderGroups.reduce((list, item) => {
                                try {
                                    if (item != null) {
                                        list.push(this.flatFolderGroups[item].description)
                                    }
                                } catch (e) {
                                    // eslint-disable-next-line
                                    console.log(e)
                                }
                                return list
                            }, []) : [])
                            .sort((a,b) => a < b ? -1 : 1)
                            .join(', ')
                    };

                    Object.assign(tFolder, folder);

                    list.push(tFolder);

                    let bucket = Math.floor(tFolder.id/100);

                    if (typeof this.folderSummarySplit[bucket] === 'undefined') {
                        this.folderSummarySplit[bucket] = []
                    }

                    this.folderSummarySplit[bucket].push({id: tFolder.id,
                        description: tFolder.description,
                        groupString: tFolder.groupString})

                    return list
                }, [])
                return tFolders
            } else {
                return []
            }


        },

        publishedLocalData() {
            return {
                folders: this._foldersFinal,
                folderGroups: this._folderGroupsWithAccess,
                users: this._users,
                userGroups: this._userGroupsWithAccess,
                tasks: this.headerVault,
                workflowGroups: this._workflowGroupsWithAccess,
                workflows: this._workflowsWithAccess,
                // folderSummary: this.folderSummarySplit
            }
        },

        _folderGroups() {
            return this.localData.folderGroups;
        },

        _users() {
            return this.localData.users;
        },

        _userGroups() {
            return this.localData.userGroups;
        },

        _tasks() {
            return this.localData.tasks;
        },

        _flatUsers() {
            if (this.localData.users.length > 0) {
                return this.localData.users.reduce((list, item) => {
                    list[item.userID] = item
                    return list
                }, {})
            } else {
                return null
            }
        },

        loadingHeaders() {
            return this.initTasks || this.downloadingHeaderRequests > 0
        },
    }
};
