<template>

    <div class="fill-height scrollable">
        <template v-if="this.tasksLoading != null">
            <div class="text-xs-center pa-2" v-if="tasksLoading > 0">
                <v-progress-circular
                        :size="40"
                        :width="5"
                        color="primary"
                        indeterminate
                />
            </div>


            <v-layout row fill-height class="scrollable" v-else>
                <v-flex xs6 class="fill-height scrollable">
                    <div v-if="datasetsLoading" class="fill-height c-d-flex c-align-center c-justify-center">
                        <v-progress-circular
                                :size="40"
                                :width="5"
                                color="primary"
                                indeterminate
                        />
                    </div>
                    <template v-else>
                        <multi-select-list
                                :show-action-buttons="false"
                                :show-check-boxes="true"
                                :multiple="false"
                                headline-key="description"

                                item-key="id"
                                :return-object="true"
                                v-model="selectedDatasetFilter"
                                :show-filter="true"
                                :items="datasets"
                                :cutom-sort-fn="(a,b) => {
                    if (a.description !== b.description)
                        return a.description < b.description ? -1 : 1
                    else
                        return a.valueDescription < b.valueDescription ? -1 : 1
                }"
                                filter-label="Filter Datasets"
                        >
                            <template v-slot:headline="{value}">
                                <div class="body-1 pb-1" v-if="value.valueDescription != null && value.valueDescription != ''" style="white-space: normal">
                                    {{ value.valueDescription }}
                                </div>
                                <div class="caption greyType--text" >
                                    {{ value.description }}
                                </div>
                            </template>
                            <template v-slot:action="{value}">
                                <div class="px-2 subheading">
                                    ({{value.tasks.length}})
                                </div>
                            </template>
                        </multi-select-list>
                    </template>


                </v-flex>
                <v-divider :vertical="true"></v-divider>
                <v-flex xs6 class="fill-height scrollable">
                    <v-layout column fill-height v-if="!datasetTasksLoading">
                        <v-flex shrink>
                            <full-task-filter
                                    :tasks="viewTasks"
                                    @taskListUpdate="postFilteredTasks = $event"
                                    @settingsUpdated="filterSettings = $event"
                                    :filter-options="filterSettings"
                            />
                        </v-flex>
                        <v-divider></v-divider>
                        <v-flex xs12 class="scrollable" v-if="filterSettings != null">
                          
                          <virtual-list
                              :keeps="40"
                              :estimate-size="60"
                              class="fill-height of-y"
                              data-key="refID"
                              :data-sources="displayTasks"
                              :data-component="FolderTaskBandItem"
                              :extra-props="{
                                droppedGroupings,
                                filterSettings,
                                toggleGrouping,
                                drag,
                                taskNav,
                                // escalations,
                                // curMS,
                                // removeEscalationRunStart,
                              }"
                          >
                          </virtual-list>
                          
<!--                            <DynamicScroller-->
<!--                                    :items="displayTasks"-->
<!--                                    key-field="refID"-->
<!--                                    :min-item-size="100"-->
<!--                                    class="scroller"-->
<!--                                    style="overflow-x: hidden"-->
<!--                            >-->
<!--                                &lt;!&ndash;:size-dependencies="[&ndash;&gt;-->
<!--                                &lt;!&ndash;item.message,&ndash;&gt;-->
<!--                                &lt;!&ndash;]"&ndash;&gt;-->
<!--                                <template v-slot="{ item, index, active }" >-->
<!--                                    <DynamicScrollerItem-->
<!--                                            :item="item"-->
<!--                                            :active="active"-->
<!--                                            :data-index="index"-->
<!--                                            :size-dependencies="[-->
<!--                                item.title,-->
<!--                                selectedTaskID,-->
<!--                            ]"-->
<!--                                            style="overflow-x: hidden"-->
<!--                                    >-->
<!--                                        -->
<!--                                    </DynamicScrollerItem>-->
<!--                                </template>-->
<!--                            </DynamicScroller>-->
                        </v-flex>
                    </v-layout>
                    <div v-else class="fill-height c-d-flex c-align-center c-justify-center">
                        <v-progress-circular
                                :size="40"
                                :width="5"
                                color="primary"
                                indeterminate
                        />
                    </div>
                </v-flex>

            </v-layout>



        </template>
    </div>

</template>

<script>

    import { codeBaseMixin } from '../../../codeBaseMixin';
    import { eventBus } from '../../../main';
    // import TaskLoadingContainer from "../../../components/TaskContainer/TaskLoadingContainer";
    // import TaskContainer from "../../../components/TaskContainer/TaskContainer";
    import FullTaskFilter from "./FullTaskFilter/FullTaskFilter";
    import TaskContainer from "../../../components/TaskContainer/TaskContainer";
    import MultiSelectList from "@/components/Multi Select List/MultiSelectList";
    import VirtualList from "vue-virtual-scroll-list";
    import FolderTaskBandItem from './FolderTaskBandItem.vue'

    export default {
        mixins: [codeBaseMixin],
        props: {
            userInfo: Object,
            selectedFolder: Object,
            getTaskHeader: Function,
        },
        components: {
            MultiSelectList,
            TaskContainer,
            FullTaskFilter,
            // TaskContainer,
            // TaskLoadingContainer,
          VirtualList
        },
        data() {
            return {
                FolderTaskBandItem,
                allUsers: true,
                starredTasks: false,
                searchStr: '',
                tasksLoading: 1,
                tasks: [],
                taskIDs: [],
                loadedTasks: [],
                selectedTaskID: 0,
                snoozedTasks: false,
                closedTasks: false,
                allTaskIDs: [],
                postFilteredTasks: [],
                filterSettings: {
                    userOption: 1,  //0:Me 1:All Users
                    stateOption: 4, //0:Open Tasks, 1:Snoozed Tasks, 2:Open & Snooze, 3:Closed Tasks, 4:All Tasks
                    starOption: 0,  //0:All Tasks, 1:Starred Tasks
                    groupOption: 2, //0:Folder, 5:Folder Groups, 1:User, 2:Workflow, 4:None,
                    orderOption: 1  //0:Ascending, 1:Descending
                },

                droppedGroupings: [],

                datasets: [],
                datasetsLoading: false,
                selectedDatasetFilter: null,
                datasetTasks: [],
                datasetTasksLoading: false
            }
        },

        created() {
            eventBus.$on('taskSelectedMailLink', this.taskSelectedMailLink);
        },

        beforeDestroy() {
            eventBus.$off('taskSelectedMailLink', this.taskSelectedMailLink);
        },

        mounted() {
            this.tasks = [];
            this.searchStr = '';
            this.selectedTaskID = 0;
        },


        methods: {
          
          taskNav() {
            this.$emit('hideParent');
          },
          toggleGrouping(item) {
            if (item.dropped)
              this.droppedGroupings = this.droppedGroupings.filter(obj => obj !== item.title)
            else
              this.droppedGroupings.push(item.title)
          },

            taskSelectedMailLink(task) {this.selectedTaskID = task.taskID},

            drag(event, task) {
                event.dataTransfer.setData("text", JSON.stringify({isTask:true, taskID: task.taskID, folderName: this.selectedFolder.description}));
            },
            //
            //
            // navigateToTask(task) {
            //     eventBus.$emit('gotoTask', (task));
            //     eventBus.$emit('hideParent', false);
            //     this.$emit('gotoTask', true);
            // },

            loadTasks() {
                // console.trace("Ran Here")
                this.tasksLoading = 1;

                this.fetch({method: 'GET', url:`/tasks/taskHeadersFolder/${this.userInfo.entityID}/${this.userInfo.userID}/${this.selectedFolder.id}`})
                    .then((taskIDs) => {
                        this.tasksLoading = taskIDs.length;
                        taskIDs.forEach(taskID => {
                            this.allTaskIDs.push(taskID)
                            this.getTaskHeader(taskID)
                                .then((task) => {
                                    this.tasks.push(task);
                                    --this.tasksLoading
                                })
                                .catch(e => {console.log(e)})
                        });
                        this.taskIDs = taskIDs;
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e)
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                        this.tasksLoading = null;
                    })
            },

            loadDatasetTasks(tasks) {
                this.datasetTasksLoading = true;

                let promList = tasks
                    .map(taskID => {
                        return this.getTaskHeader(taskID)
                    })

                Promise.allSettled(promList)
                    .then(promises => {
                        for (const promResult of promises) {
                            if (promResult.status === "fulfilled") {
                                this.datasetTasks.push(promResult.value)
                            }
                        }
                    })
                    .finally(() => {
                        this.datasetTasksLoading = false;
                    })
            },


            loadDatasets() {
                // Clear Existing Filter
                // console.trace("Ran Here2")
                this.selectedDatasetFilter = null;
                this.datasets = null;
                this.datasetsLoading = false;

                if (this.selectedFolder && this.selectedFolder.id) {
                    this.datasetsLoading = true;
                    this.$newReq('GET', `fieldsets/fieldSetsForFolderWithTasks/${this.userInfo.entityID}/${this.selectedFolder.id}`)
                        .then(datasets => {
                            this.datasets = datasets
                        })
                        .catch(e => {
                            console.log(e)
                            this.$snack.networkError();
                        })
                        .finally(() => {
                            this.datasetsLoading = false
                        })
                }

            }
        },

        watch: {

            selectedDatasetFilter: {
                immediate: true,
                handler(val) {
                    this.datasetTasks = [];
                    if (val) {
                        console.log('VAL', val)
                        this.loadDatasetTasks(val.tasks)
                    }

                }
            },

            selectedFolder: {
                immediate: true,
                handler(newVal, oldVal) {


                    if (oldVal && newVal.id === oldVal.id)
                        return console.log("Escape")

                    this.datasets = [];
                    this.datasetsLoading = false;
                    this.selectedDatasetFilter = null;
                    this.datasetTasks = [];
                    this.datasetTasksLoading = false;


                    if (typeof newVal != 'undefined' && newVal != null) {
                        this.tasks = [];
                        this.searchStr = '';
                        this.selectedTaskID = 0;
                        this.taskIDs = [];
                        this.loadedTasks = [];
                        this.allTaskIDs = []
                        this.loadTasks();
                    }
                    this.loadDatasets();
                }
            },
        },

        computed: {

            viewTasks() {
                return this.selectedDatasetFilter ? this.datasetTasks : this.tasks
            },

            displayTasks() {
                return this.taskGroupsWithDroppedItems.map((obj,index) => {
                  if (obj.groupObject) {
                    obj.refID = obj.title + obj.dropped ? '1' : '0';
                  } else {
                    obj.refID = obj.taskID;
                  }
                  return obj;
                })
            },

            taskGroupsWithDroppedItems() {
                if (this.taskGroups != null) {
                    let tItems = [];

                    const taskGroupKeys = Object.keys(this.taskGroups).sort((a,b) => a < b ? -1 : 1);
                    for (const groupName of taskGroupKeys) {

                        let dropped = this.droppedGroupings.includes(groupName);

                        tItems.push({
                            title: groupName,
                            dropped: dropped,
                            groupObject: true,
                            count: this.taskGroups[groupName].tasks.length
                        });

                        if (dropped) {
                            tItems = tItems.concat(this.taskGroups[groupName].tasks)
                        }
                    }
                    return tItems
                } else {
                    return this.sortedTasks;
                }
            },

            taskGroups() {
                if (this.filterSettings.groupOption != 4) {
                    let tGroups = {};
                    this.postFilteredTasks.forEach(task => {
                        let group = '';
                        switch (this.filterSettings.groupOption) {
                            case 0: {group = task.folderID > 0 ? task.folderName : 'Task Folder';break;}
                            case 1: {group = `${task.ownerName} ${task.ownerSurname}`;break;}
                            case 6: {group = `${task.subOwnerName} ${task.subOwnerSurname}`;break;}
                            case 2: {group = task.workflowName;break;}
                            case 5: {group = task.folderID > 0 ? task.folderGroupNames : 'Task Folder';break;}
                        }
                        if (typeof tGroups[group] == 'undefined') {
                            tGroups[group] = {tasks: []}
                        }
                        tGroups[group].tasks.push(task);
                    });
                    return tGroups;
                } else {
                    return null;
                }
            },
        }

    }
</script>

<style scoped>
    .taskSelected {
        background: #ebebeb;
    }

    .taskHeader:hover {
        cursor: pointer;
        background: #f9f9f9;
    }

    .scroller {
        /*max-height: 450px;*/
        height: 100%;
    }
</style>
