<template>
    <v-dialog
            v-model="showEditor"
            persistent
            fullscreen
    >
        <v-layout column fill-height justify-center style="background: rgba(0,0,0,0.5)" class="scrollable">
            <v-flex xs11 class="scrollable">
                <v-layout row justify-center fill-height class="scrollable">
                    <v-flex xs11 fill-height class="scrollable">
                        <v-card class="fill-height scrollable">
                            <v-layout column fill-height class="scrollable">
                                <v-flex shrink>
                                    <v-layout row align-center class="primary">
                                        <v-flex xs12 class="white--text subheading pa-2 px-3">
                                            {{`Edit ${selectedSig.description}`}}
                                        </v-flex>
                                        <v-flex shrink class="px-2">
                                            <v-btn
                                                    small
                                                    icon
                                                    flat
                                                    color="white"
                                                    class="pa-0 ma-0"
                                                    @click="$emit('input', false)"
                                                    :disabled="savingChanges"
                                            >
                                                <v-icon>close</v-icon>
                                            </v-btn>
                                        </v-flex>
                                    </v-layout>
                                </v-flex>
                                <v-flex xs12 class="scrollable">
                                    <editor
                                        :extra-config="editorConfig"
                                        v-model="editHtml"
                                    />
                                </v-flex>
                                <v-divider></v-divider>
                                <v-flex shrink class="text-xs-right">
                                    <v-btn small color="secondary" outline @click="$emit('input', false)" :disabled="savingChanges">
                                        <v-icon left>close</v-icon>
                                        Cancel
                                    </v-btn>
                                    <v-btn small color="secondary" @click="saveChanges" :loading="savingChanges" :disabled="savingChanges || imageCount != 0">
                                        <v-icon left>save</v-icon>
                                        Save Changes
                                    </v-btn>
                                </v-flex>
                            </v-layout>
                        </v-card>
                    </v-flex>
                </v-layout>
            </v-flex>
        </v-layout>

    </v-dialog>
</template>

<script>
    import {codeBaseMixin} from "../../../codeBaseMixin";
    import Editor from "@/Editor/Editor";
    import axios from 'axios'

    export default {
        components: {Editor},
        mixins: [codeBaseMixin],
        props: {
            value: Boolean,
            userInfo: Object,
            selectedSig: Object,
        },
        data() {
            return {
                editHtml: null,
                savingChanges: false,
                imageCount: 0
            }
        },
        watch: {
            value: {
                immediate: true,
                handler(val) {
                    this.editHtml = this.selectedSig.html || '<br>'
                }
            }
        },
        methods: {
            uploadImage(blobInfo, success) {
                ++this.imageCount
                let h = new Headers();
                let fd = new FormData();
                let myFile = blobInfo.blob();
                fd.append('attachmentFile', myFile);

                axios.request({
                    method: "post",
                    url: `${this.serverAddress}/upload/signatureAttachment/${this.userInfo.entityID}`,
                    data: fd,
                    onUploadProgress: (p) => {
                        this.uploadProgress = (p.loaded / p.total) * 100
                    },
                })
                    .then(data => {
                        success(data.data.link);
                        --this.imageCount;
                    })
                    .catch(() => {
                        this.showSnack('Error', 'Image Upload Error', 'Close', 'red');
                        success('.blank');
                        --this.imageCount;
                    })
            },

            saveChanges() {
                this.savingChanges = true;
                this.fetch({method: 'PATCH', url:`/sigMan/updateSignature/${this.userInfo.entityID}/${this.selectedSig.id}`, body: {html: this.editHtml.replace(/\n/g, '')}})
                    .then(() => {
                        this.selectedSig.html = this.editHtml;
                        this.showEditor = false;
                        this.savingChanges = false;
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e)
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                        this.savingChanges = false;
                    })
            },
        },
        computed: {
            showEditor: {
                get() {
                    return this.value
                },
                set(val) {
                    this.$emit('input', val)
                }
            },

            editorConfig() {
                // testDrop(event, editor) {
                //     console.log('DROP EVENT', event)
                //
                //     editor.insertContent('<span style="background: fuchsia; display: inline-block; cursor: pointer" :draggable="true">Test123456</span>')
                // },
                // paste_data_images: true,
                // images_upload_handler: this.uploadImage,
                // init_instance_callback: function(editor) {
                //     editor.on('drop', function(e) {
                //         vm.testDrop(e, editor)
                // });
                // },
                // uploadImage(blobInfo, success) {
                //     ++this.imageCount
                //     let h = new Headers();
                //     let fd = new FormData();
                //     let myFile = blobInfo.blob();
                //     fd.append('attachmentFile', myFile);
                //
                //     axios.request({
                //         method: "post",
                //         url: `${this.serverAddress}/upload/noteAttachment/${this.userInfo.entityID}`,
                //         data: fd,
                //         onUploadProgress: (p) => {
                //             this.uploadProgress = (p.loaded / p.total) * 100
                //         },
                //     })
                //         .then(data => {
                //             success(data.data.link);
                //             --this.imageCount;
                //         })
                //         .catch(() => {
                //             this.showSnack('Error', 'Image Upload Error', 'Close', 'red');
                //             success('.blank');
                //             --this.imageCount;
                //         })
                // },

                return {
                    paste_data_images: true,
                    images_upload_handler: this.uploadImage,
                    remove_linebreaks : false,
                    init_instance_callback: function(editor) {
                        editor.focus()
                    },
                    content_style: 'img {max-width: 100%;}'
                }
            },
        }
    }
</script>

<style scoped>

</style>
