<template>
    <div>
        <div @click="!disabled ? showSelect = !showSelect : ''">
            <slot name="activator">

            </slot>
        </div>

        <app-input-dlg
                v-if="showSelect && !disabled"
                :show-dialog="showSelect"
                :title="title"
                :cancel-button="true"
                :show-input-box="false"
                @dismiss="showSelect = false"
                :body-padding="false"
        >
            <template slot="body">
                <div class="pa-2">
                    <div class="pb-1 body-2 greyType--text">
                        Quick Select:
                    </div>
                    <div class="pl-2">
                        <div v-for="obj in addValueObjects" :key="obj.keyValue">
                            <v-layout row align-center>
                                <v-flex xs12 class="primary--text caption font-weight-bold">
                                    {{obj.description}}
                                </v-flex>
                                <v-flex shrink class="pl-1" v-for="option in obj.options" :key="`${obj.keyValue}-${option}`">
                                    <v-btn
                                            small
                                            icon
                                            flat
                                            color="secondary"
                                            @click="selectValue(obj.keyValue, option)"
                                            class="pa-0 ma-0"
                                    >
                                        <span class="caption font-weight-bold">+{{option}}</span>
                                    </v-btn>
                                </v-flex>
                            </v-layout>
                        </div>
                    </div>
                    <div class="pb-1 pt-1 body-2 greyType--text">
                        Custom Date / Time:
                    </div>
                    <div class="text-xs-right">
                        <date-picker-with-max
                                :value="localValueString"
                                @input="postValue($event)"
                                :min="new Date()"
                        >
                            <v-btn
                                    outline
                                    flat
                                    small
                                    color="secondary"
                                    class=" ma-0"
                                    slot="activator"
                            >
                                Select Date / Time
                            </v-btn>
                        </date-picker-with-max>
                    </div>
                </div>
            </template>

        </app-input-dlg>
    </div>
</template>

<script>
    import {codeBaseMixin} from "../../codeBaseMixin";
    import InputDlg from "./InputDlg";
    import DatePickerWithMax from "./DatePickerWithMax";
    export default {
        mixins: [codeBaseMixin],
        components: {
            DatePickerWithMax,
            appInputDlg: InputDlg
        },
        methods: {
            selectValue(operator, ammount) {
                this.showSelect = false;
                this.$emit('input', this.dateAdd(new Date(), operator, ammount));
                this.$emit('inputString', this.getSqlTimestampDate(this.dateAdd(new Date(), operator, ammount)));
            },

            postValue(value) {
                this.showSelect = false;
                this.$emit('input', new Date(value));
                this.$emit('inputString', value);
            }
        },
        props: {
            value: {
                type: Date,
                default: () => {return new Date()}
            },
            title: {
                type: String,
                default: 'Select Date'
            },
            disabled: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                showSelect: false,
                localValue: null,
                addValueObjects: [
                    {description: 'Hours', keyValue: 'hour', options: [1,3,6,9,12]},
                    {description: 'Days', keyValue: 'day', options: [1,2,3,4,5]},
                    {description: 'Weeks', keyValue: 'week', options: [1,2,3,5,6]},
                    {description: 'Months', keyValue: 'month', options: [1,2,3,6,12]},
                ]
            }
        },
        watch: {
            showSelect: {
                immediate: true,
                handler(shown) {
                    if (shown) {
                        this.localValue = this.value != null ? this.value : new Date;
                    }
                }
            }
        },
        computed: {
            localValueString() {
                return this.getSqlTimestampDate(this.localValue)
            }
        }
    }
</script>

<style scoped>

</style>
