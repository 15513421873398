var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.field.lookup)?_c('div',[_c('div',{class:_vm.topicClass},[_c('div',{class:_vm.headingClass},[_vm._v(" Limit to Folder Groups: ")]),_c('div',{staticClass:"pt-1"},[_c('workflow-manager-group-selector',{attrs:{"user-groups":_vm.folderGroups,"ac-label":"No Folder Groups Selected","btn-label":"Select Folder Groups (Not Limited)","lbl-label":"Select Folder Groups"},on:{"input":function($event){return _vm.$emit('input')}},model:{value:(_vm.field.lookup.folderGroupLimit),callback:function ($$v) {_vm.$set(_vm.field.lookup, "folderGroupLimit", $$v)},expression:"field.lookup.folderGroupLimit"}})],1)]),_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs6":""}},[_c('div',{class:_vm.topicClass,staticStyle:{"overflow-y":"hidden"}},[_c('div',{class:_vm.headingClass},[_vm._v(" Auto Select Current Folder: ")]),_c('div',{class:_vm.itemClass},[_c('v-switch',{staticClass:"pa-0 ma-0",attrs:{"label":_vm.field.lookup.autoSelectCurrentFolder ? 'Enabled' : 'Disabled',"hide-details":"","color":"secondary"},on:{"change":(event) => {
                                if (event) {
                                    _vm.field.lookup.createOnly = false
                                }

                                _vm.$emit('input')
                            }},model:{value:(_vm.field.lookup.autoSelectCurrentFolder),callback:function ($$v) {_vm.$set(_vm.field.lookup, "autoSelectCurrentFolder", $$v)},expression:"field.lookup.autoSelectCurrentFolder"}})],1)])]),_c('v-flex',{attrs:{"xs6":""}},[_c('div',{class:_vm.topicClass,staticStyle:{"overflow-y":"hidden"}},[_c('div',{class:_vm.headingClass},[_vm._v(" Disable After Save: ")]),_c('div',{class:_vm.itemClass},[_c('v-switch',{staticClass:"pa-0 ma-0",attrs:{"label":_vm.field.lookup.disableAfterSave ? 'Enabled' : 'Disabled',"hide-details":"","color":"secondary"},on:{"change":function($event){return _vm.$emit('input')}},model:{value:(_vm.field.lookup.disableAfterSave),callback:function ($$v) {_vm.$set(_vm.field.lookup, "disableAfterSave", $$v)},expression:"field.lookup.disableAfterSave"}})],1)])]),_c('v-flex',{attrs:{"xs6":""}},[_c('div',{class:_vm.topicClass,staticStyle:{"overflow-y":"hidden"}},[_c('div',{class:_vm.headingClass},[_vm._v(" Allow Create: ")]),_c('div',{class:_vm.itemClass},[_c('v-switch',{staticClass:"pa-0 ma-0",attrs:{"label":_vm.field.lookup.allowCreate ? 'Allowed' : 'Select Only',"hide-details":"","color":"secondary"},on:{"change":(event) => {
                                if (!event) {
                                    _vm.field.lookup.createOnly = false
                                }
                                _vm.$emit('input')
                            }},model:{value:(_vm.field.lookup.allowCreate),callback:function ($$v) {_vm.$set(_vm.field.lookup, "allowCreate", $$v)},expression:"field.lookup.allowCreate"}})],1)])]),_c('v-flex',{attrs:{"xs6":""}},[_c('div',{class:_vm.topicClass,staticStyle:{"overflow-y":"hidden"}},[_c('div',{class:_vm.headingClass},[_vm._v(" Create Only: ")]),_c('div',{class:_vm.itemClass},[_c('v-switch',{staticClass:"pa-0 ma-0",attrs:{"label":_vm.field.lookup.createOnly ? 'Create Only' : 'Select Enabled',"hide-details":"","color":"secondary"},on:{"change":(event) => {
                                if (event) {
                                    _vm.field.lookup.allowCreate = true
                                    _vm.field.lookup.autoSelectCurrentFolder = false
                                }

                                _vm.$emit('input')
                            }},model:{value:(_vm.field.lookup.createOnly),callback:function ($$v) {_vm.$set(_vm.field.lookup, "createOnly", $$v)},expression:"field.lookup.createOnly"}})],1)])]),_c('v-flex',{attrs:{"xs6":""}},[_c('div',{class:_vm.topicClass,staticStyle:{"overflow-y":"hidden"}},[_c('div',{class:_vm.headingClass},[_vm._v(" Only Show Groups With Access: ")]),_c('div',{class:_vm.itemClass},[_c('v-switch',{staticClass:"pa-0 ma-0",attrs:{"label":_vm.field.lookup.onlyGroupsWithAccess ? 'Only Groups With Access' : 'All Groups',"hide-details":"","color":"secondary"},on:{"change":function($event){return _vm.$emit('input')}},model:{value:(_vm.field.lookup.onlyGroupsWithAccess),callback:function ($$v) {_vm.$set(_vm.field.lookup, "onlyGroupsWithAccess", $$v)},expression:"field.lookup.onlyGroupsWithAccess"}})],1)])])],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }