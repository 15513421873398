<template>
  <v-dialog :value="true" :persistent="true" :width="500">
    <v-card style="background: var(--page-back)">
      
      <div class="centered-row primary white--text px-2 py-1">
        <v-icon color="white">verified_user</v-icon>
        <div style="width: 100%" class="pl-2">
          MFA Verification
        </div>
      </div>
      
      <v-card-text style="width: 100%; overflow-x: hidden">
        <div class="c-d-flex c-justify-center pb-3 ">
          <img ref="qr" alt="QR Code"/>
        </div>
        
        <div class="">
          To set up MFA, scan the QR code with your preferred app (such as Microsoft Authenticator or Google Authenticator).
          <br/>
          <br/>
          Be sure to save your recovery key, as you’ll need it to regain access to your account if you lose access to your device.
        </div>
        <div class="caption c-d-flex c-align-center text-truncate">
          
          <div class="c-flex-grow-1 py-2 text-truncate">
            Recovery Key:
            <br>
            {{ config.recoveryKey }}
          </div>
          <v-btn
              class="mx-2"
              color="primary"
              size="x-small"
              flat
              icon
              @click="copyRecoveryKey()"
          >
            <v-icon>content_copy</v-icon>
          </v-btn>
        </div>
      
      </v-card-text>
      
      
<!--      <div class="px-2 pt-2 centered-row">-->
<!--        <div style="width: 50%">An OTP has been sent to:</div>-->
<!--        <div style="width: 50%"><strong>{{ config.sentTo }}</strong></div>-->
<!--      </div>-->
      
      <div class="px-3 pt-2">
        <v-text-field
            class="pa-0 ma-0 card-back"
            hide-details
            prepend-inner-icon="vpn_key"
            :light="!$store.state.darkTheme"
            solo
            label="Please confirm the code below"
            placeholder="Confirm Code"
            :height="30"
            mask="###-###"
            v-model="otp"
            :disabled="confirming"
            ref="otpEdit"
            @keydown.enter="!!otp && otp.length === 6 ? confirmOTP() : null"
        >
        
        </v-text-field>
      
      
      </div>
      
      <div v-if="otpError" class="pt-2 text-xs-center error--text caption">
        <strong>{{ otpError }}</strong>
      </div>
      
      <div class="pt-2 px-2 pb-2 c-d-flex c-justify-end">
        <v-btn
            class="pa-0 ma-0"
            color="secondary"
            :disabled="(!!otp ? otp.length < 6 : true) || confirming"
            :loading="confirming"
            @click="!setup ? confirmOTP() : setupOTP()"
        >
          Next
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>

import {copyText} from "@/codeFunctions";
import QRCode from "qrcode";

const EventEmitter = require('events');
const emitter = new EventEmitter();

export default {
  props: {
    config: Object,
    getOS: Function,
    setCookie: Function,
    setup: {type: Boolean, default: false},
    rememberMe: {type: Boolean, default: false}
  },
  data() {
    return {
      confirming: false,
      otp: null,
      otpError: null
    }
  },
  watch: {
  },
  mounted() {
    this.genQRRun();
  },
  methods: {
    genQRRun() {
      setTimeout(() => {
        if (this.$refs && this.$refs.qr)
          this.genQR();
        else
          this.genQRRun();
      }, 100)
    },
    
    genQR() {
      let opts = {
        errorCorrectionLevel: 'L',
        type: 'image/png',
        quality: 0.1,
        margin: 1,
        color: {
          dark: !this.$store.state.darkTheme ? "#000000" : '#FFFFFF',
          light: "#0000"
        },
        width: 220
      }
      
      const vm = this
      QRCode.toDataURL(this.config.uri, opts, function (err, url) {
        if (err) throw err
        let img = vm.$refs.qr
        img.src = url
      })
    },
    
    copyRecoveryKey() {
      copyText(document, this.config.recoveryKey)
      alert('Recovery key copied to clipboard')
    },
    
    setupOTP() {
    
    },
    
    confirmOTP() {
      this.otpError = null
      this.confirming = true;
      
      this.$newReq('POST', `login/twoFactor/confirm/${this.rememberMe ? 1 : 0}`, {token: this.config.setupToken, pin: this.otp})
          .then(data => {
            console.log({data})
            
            if (data?.status === 'failed') {
              this.otpError = data.data
            } else {
              console.log(data)
            //   {
            //     username
            //         token
            //       deviceToken
            //       rememberMe
              
              this.setCookie(data.username, data.deviceToken, 120)
              if (data.rememberMe) {
                this.$emit('rememberMe', data.rememberMe)
              }
              
              this.$emit('OTPSuccess', data.token)
            }
          })
          .catch(() => {
            this.otpError = 'Invalid OTP'
          })
          .finally(() => {
            this.confirming = false
          })
    },
    
    // sendAlt() {
    //   this.sendingAlt = true;
    //   this.$newReq('GET', `login/twoFactor/SendAlt/${this.config.entityID}/${this.config.userID}`, null, false)
    //       .then(({sentTo}) => {
    //         this.config.sentTo = sentTo
    //         this.altSent = true;
    //         this.$snack.info('Two Factor OTP Sent')
    //       })
    //       .catch(e => {
    //         console.log(e)
    //         this.$snack.networkError()
    //       })
    //       .finally(() => {
    //         this.sendingAlt = false
    //       })
    // },
    //
    //
    // cancelConfirm() {
    //   emitter.emit('result', {status: false})
    // },
  },
  computed: {},
}
</script>

<style scoped>

</style>