<template>
    <v-dialog
      v-model="showDialog"
      :width="`${width}px`"
      :persistent="true"
      :fullscreen="fullScreen"
    >
        <v-card flat :style="{background: background || 'var(--page-back)'}">
            <template v-if="!hasTitleSlot">
                <v-card-title class="pa-1 pl-2">
                    <span class="subheading font-weight-light" :class="`${headingColor}--text`">{{ title }}</span>
                    <v-spacer></v-spacer>
                    <v-btn
                            v-if="cancelButton"
                            small
                            icon
                            flat
                            color="primary"
                            class="pa-0 ma-0"
                            @click="$emit('dismiss', true)"
                    >
                        <v-icon small>close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-divider></v-divider>
            </template>

            <slot name="title"></slot>

            <v-card-text :class="!bodyPadding ? 'pa-0' : ''">
                <template v-if="body">
                    <span class="body-1 greyType--text" v-html="body"></span>
                </template>
                <div >
                    <slot name="body"></slot>
                </div>
                <template v-if="showInputBox">
                    <v-text-field
                            label=""
                            single-line
                            v-model="inputValue"
                            color="primary"
                            hide-details
                            style="width: 100%"
                            class="ma-0 pa-0"
                            clearable
                            ref="inputDlgTextField"
                            @keydown.enter="callBacks.length > 0 ? callBacks[callBacks.length - 1](inputValue) : ''"
                    ></v-text-field>
                </template>
            </v-card-text>

            <v-divider v-if="hasActionsSlot"></v-divider>

            <v-card-actions v-if="hasActionsSlot">
                    <v-spacer></v-spacer>
                    <slot name="actions"></slot>
            </v-card-actions>

            <v-divider v-if="buttons != null && buttons.length > 0"></v-divider>

            <v-card-actions v-if="buttons != null && buttons.length > 0">
                    <v-spacer></v-spacer>
                    <template v-for="(button, i) in buttons">
                        <v-btn
                                :loading="loading"
                                :key="'InputButton-' + title + '-' + i + '-' + button"
                                flat
                                color="secondary"
                                dark
                                @click="!loading ? callBacks[i](inputValue) : () => {}"
                        >
                            {{ button }}
                        </v-btn>
                    </template>
            </v-card-actions>

        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        props: {
            showDialog: {
                default: false,
                type: Boolean
            },
            title: String,
            body: String,
            cancelButton: {
                type: Boolean,
                value: true
            },
            showInputBox: {
                type: Boolean,
                value: false
            },
            buttons: Array,
            callBacks: Array,
            defaultInput: {
                type: [String, Object],
                default: null
            },
            width: {
                type: Number,
                default: 400
            },
            bodyPadding: {
                type: Boolean,
                default: true
            },
            headingColor: {
                type: String,
                default: 'primary'
            },
            background: {
                default: null,
                type: String
            },
            fullScreen: {type: Boolean, default: false},
            loading: {type: Boolean, default: false},
        },
        data() {
            return {
                inputValue: null,
                refVal: null
            }
        },
        watch: {
            showDialog: {
                immediate: true,
                handler(newVal) {
                    if (!newVal) {
                        this.inputValue = null;
                    } else {
                        this.inputValue = this.defaultInput;
                        if (this.showInputBox) {
                            setTimeout(() => {
                                this.$refs.inputDlgTextField.focus();
                            }, 50);
                        }
                    }
                }
            }

        },
        methods: {
            refVar(val) {
                if (typeof val != 'undefined') {
                    this.refVal = val
                }
                return this.refVal
            },
        },
        computed: {
            hasTitleSlot () {
                return !!this.$slots['title']
            },
            hasActionsSlot () {
                return !!this.$slots['actions']
            }
        }
    }
</script>

<style scoped>

</style>
