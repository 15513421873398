<template>
  <div>
    <task-loading-container
        v-if="loadingTask || !task"
        :task-id="taskId"
    />
    <task-container
        v-else
        :userInfo="userInfo"
        :task="task"
        :taskBar="false"
        :showNav="false"
        @taskSelected="$emit('taskSelected', $event)"
    />
  </div>
</template>

<script>
import TaskLoadingContainer from "@/components/TaskContainer/TaskLoadingContainer.vue";
import TaskContainer from "@/components/TaskContainer/TaskContainer.vue";
import {mapActions, mapGetters} from "vuex";

export default {
  components: {TaskContainer, TaskLoadingContainer},
  props: {
    taskId: Number
  },
  data() {
    return {
      loadingTask: false,
      task: null,
      console,
    }
  },
  mounted() {
    this.loadTask()
  },
  methods: {
    async loadTask() {
      this.loadingTask = true
      this.task = await this.$store.state.getTaskHeaderFn(this.taskId)
      this.loadingTask = false
    }
  },
  computed: {
    ...mapGetters("userInfo", ["userInfo"])
  },
}
</script>



<style scoped>

</style>