<template>
    <div class="pb-2 pr-2">
        <v-card class="px-2 py-1" :class="!showField ? (!valid ? 'red10' : 'green10') : ''">
            <v-layout row align-center>
                <v-flex xs12 class="body-1 primary--text">
                    {{value.field.description}}
                </v-flex>
                <v-flex shrink class="pr-2">
                    <v-switch
                            v-model="showField"
                            hide-details
                            color="secondary"
                            class="pa-0 ma-0"
                    >
                        <template slot="label">
                            <div style="white-space: pre" class="caption">{{showField ? 'Show Field' : 'Condition'}}</div>
                        </template>
                    </v-switch>
                </v-flex>
            </v-layout>
            <template v-if="!showField">
                <v-divider></v-divider>
                <v-layout row align-center>
                    <v-flex xs12 class="caption">
                        {{value.field.description}}
                    </v-flex>
                    <v-flex shrink class="pl-2" >
                        <v-select
                                :items="availableOperations"
                                item-text="description"
                                item-value="id"
                                v-model="operationID"
                                single-line
                                color="primary"
                                hide-details
                                style="width: 100%"
                                class="ma-0 pa-0"
                                dense
                        ></v-select>
                    </v-flex>
                    <v-flex xs12 class="pl-2" v-if="![2,7].includes(operationID)">
                        <template v-if="[1].includes(value.field.fieldType)">
                            <v-text-field
                                    label="Value"
                                    single-line
                                    v-model="fieldValue"
                                    color="primary"
                                    hide-details
                                    style="width: 100%"
                                    class="ma-0 pa-0"
                            ></v-text-field>
                        </template>
                        <template v-if="[2].includes(value.field.fieldType)">
                            <app-date-picker-with-max
                                    v-model="fieldValue"
                                    :date-only="true"
                            />
                        </template>
                        <template v-if="[3,4].includes(value.field.fieldType)">
                            <v-text-field
                                    label="Value"
                                    type="number"
                                    single-line
                                    v-model="fieldValue"
                                    color="primary"
                                    hide-details
                                    style="width: 100%"
                                    class="ma-0 pa-0"
                            ></v-text-field>
                        </template>
                        <template v-if="[5].includes(value.field.fieldType)">
                            <v-autocomplete
                                    :items="selectListOptions"
                                    item-text="description"
                                    item-value="id"
                                    v-model="fieldValue"
                                    single-line
                                    color="primary"
                                    hide-details
                                    style="width: 100%"
                                    class="ma-0 pa-0"
                                    dense
                            ></v-autocomplete>
                        </template>
                    </v-flex>
                </v-layout>
            </template>
        </v-card>
    </div>

</template>

<script>
    import DatePickerWithMax from "../../../../../components/General/DatePickerWithMax";
    export default {
        components: {
            appDatePickerWithMax: DatePickerWithMax
        },
        props: {
            value: Object,
            selectLists: Array,
            valid: Boolean,
            userInfo: Object
        },
        methods: {

        },
        computed: {
            selectListOptions() {
                try {
                    if (this.value.field.lookup_type == 1) {
                        return JSON.parse(this.value.field.lookup.replace(/\\"/g,'"'))
                    } else {
                        return this.selectLists.filter(obj => obj.id == parseInt(JSON.parse(this.value.field.lookup.replace(/\\"/g,'"')).id)).pop().members.map(obj => {return obj.value});
                    }
                } catch (e) {
                    // eslint-disable-next-line
                    console.log(e);
                    return [];
                }
            },
            availableOperations() {
                switch (this.value.field.fieldType) {
                    case 1: {
                        return [
                            {id: 0, description: "="},
                            {id: 1, description: "Not Equal"},
                            {id: 2, description: "Not Blank"},
                            {id: 7, description: "Is Blank"},
                        ]
                    }
                    case 2: {
                        return [
                            {id: 0, description: "="},
                            {id: 1, description: "Not Equal"},
                            {id: 2, description: "Not Blank"},
                            {id: 7, description: "Is Blank"},
                            {id: 5, description: "After"},
                            {id: 6, description: "Before"},
                        ]
                    }
                    case 3: {
                        return [
                            {id: 0, description: "="},
                            {id: 1, description: "Not Equal"},
                            {id: 2, description: "Not Blank"},
                            {id: 7, description: "Is Blank"},
                            {id: 3, description: "Bigger"},
                            {id: 4, description: "Smaller"},
                        ]
                    }
                    case 4: {
                        return [
                            {id: 0, description: "="},
                            {id: 1, description: "Not Equal"},
                            {id: 2, description: "Not Blank"},
                            {id: 7, description: "Is Blank"},
                            {id: 3, description: "Bigger"},
                            {id: 4, description: "Smaller"},
                        ]
                    }
                    case 5: {
                        return [
                            {id: 0, description: "="},
                            {id: 1, description: "Not Equal"},
                            {id: 2, description: "Not Blank"},
                            {id: 7, description: "Is Blank"},
                        ]
                    }
                    default: {
                        return [
                            {id: 2, description: "Not Blank"},
                            {id: 7, description: "Is Blank"},
                        ]
                    }
                }
            },
            fieldValue: {
                get() {
                    return this.value.value
                },
                set(newValue) {

                    this.$emit('setValue', newValue);
                }
            },
            operationID: {
                get() {
                    return this.value.operationID
                },
                set(newValue) {
                    this.$emit('setOperationID', newValue);
                }
            },
            showField: {
                get() {
                    return this.value.showField
                },
                set(newValue) {
                    this.$emit('setShow', newValue);
                }
            }
        }
    }
</script>

<style scoped>

</style>
