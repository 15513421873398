<template>
    <v-layout row fill-height class="scrollable">
<!--Editors-->
        <v-flex xs4 class="scrollable px-1 py-1">
            <v-card class="fill-height scrollable">
                <v-layout column fill-height class="scrollable">
                    <v-flex shrink class="primary white--text pa-2">Editors</v-flex>
                    <v-flex shrink class="pa-2">
                        <v-btn
                            outline
                            small
                            block
                            class="py-0 ma-0 px-2"
                            color="secondary"
                            @click="showAddEditors = true"
                        >
                            add
                        </v-btn>
                    </v-flex>
                    <v-divider></v-divider>
                    <v-flex xs12 class="scrollable">
                        <multi-select-list
                            :show-action-buttons="true"
                            :action-buttons="editorActionButtons"
                            :loading="updatingMembership ? selectedEditors : null"
                            :show-check-boxes="true"
                            :multiple="true"
                            headline-key="description"
                            sub-line-key="subDescription"
                            item-key="id"
                            :return-object="false"
                            v-model="selectedEditors"
                            :show-filter="true"
                            :items="editors"
                            filter-label="Filter Editors"
                        />
                    </v-flex>
                </v-layout>
            </v-card>
        </v-flex>
<!--Users-->
        <v-flex xs4 class="scrollable px-1 py-1">
            <v-card class="fill-height scrollable">
                <v-layout column fill-height class="scrollable">
                    <v-flex shrink class="primary white--text pa-2">Users</v-flex>
                    <v-flex shrink class="pa-2">
                        <v-btn
                            outline
                            small
                            block
                            class="py-0 ma-0 px-2"
                            color="secondary"
                            @click="showAddUsers = true"
                        >
                            add
                        </v-btn>
                    </v-flex>
                    <v-divider></v-divider>
                    <v-flex xs12 class="scrollable">
                        <multi-select-list
                            :show-action-buttons="true"
                            :action-buttons="[{icon: 'delete', color: 'red', cb: this.removeUsers, hoverItem: 'selectedOnly'}]"
                            :loading="updatingMembership ? selectedUsers : null"
                            :show-check-boxes="true"
                            :multiple="true"
                            headline-key="description"
                            sub-line-key="subDescription"
                            item-key="id"
                            :return-object="false"
                            v-model="selectedUsers"
                            :show-filter="true"
                            :items="users"
                            filter-label="Filter Viewers"
                        />
                    </v-flex>
                </v-layout>
            </v-card>
        </v-flex>
<!--Groups-->
        <v-flex xs4 class="scrollable px-1 py-1">
            <v-card class="fill-height scrollable">
                <v-layout column fill-height class="scrollable">
                    <v-flex shrink class="primary white--text pa-2">Groups</v-flex>
                    <v-flex shrink class="pa-2">
                        <v-btn
                            outline
                            small
                            block
                            class="py-0 ma-0 px-2"
                            color="secondary"
                            @click="showAddGroups = true"
                        >
                            add
                        </v-btn>
                    </v-flex>
                    <v-divider></v-divider>
                    <v-flex xs12 class="scrollable">
                        <multi-select-list
                            :show-action-buttons="true"
                            :action-buttons="[{icon: 'delete', color: 'red', cb: this.removeGroups, hoverItem: 'selectedOnly'}]"
                            :loading="updatingMembership ? selectedGroups : null"
                            :show-check-boxes="true"
                            :multiple="true"
                            headline-key="description"
                            sub-line-key="users"
                            item-key="id"
                            :return-object="false"
                            v-model="selectedGroups"
                            :show-filter="true"
                            :items="groups"
                            filter-label="Filter Groups"
                        />
                    </v-flex>
                </v-layout>
            </v-card>
        </v-flex>
<!--Add Editors-->
        <input-dlg
            :show-dialog="showAddEditors"
            title="Add Report Editors"
            :cancel-button="true"
            :show-input-box="false"
            @dismiss="showAddEditors = false"
            :body-padding="false"
        >
            <template slot="body">
                <div v-if="showAddEditors">
                    <multi-select-list
                        :show-action-buttons="false"
                        :show-check-boxes="true"
                        :multiple="true"
                        headline-key="description"
                        sub-line-key="subDescription"
                        item-key="id"
                        :return-object="false"
                        v-model="selectedAddEditors"
                        :show-filter="true"
                        :items="availableEditors"
                        filter-label="Filter Users"
                        :height="400"
                    />
                </div>
            </template>
            <template slot="actions">
                <v-btn
                    small
                    color="secondary"
                    class="py-0 px-2 ma-0"
                    :disabled="updatingMembership || !Array.isArray(selectedAddEditors) || selectedAddEditors.length <= 0"
                    :loading="updatingMembership"
                    @click="addEditors()"
                >Add Editors
                </v-btn>
            </template>
        </input-dlg>
<!--Add Users-->
        <input-dlg
            :show-dialog="showAddUsers"
            title="Add Report Viewers"
            :cancel-button="true"
            :show-input-box="false"
            @dismiss="showAddUsers = false"
            :body-padding="false"
        >
            <template slot="body">
                <div v-if="showAddUsers">
                    <multi-select-list
                        :show-action-buttons="false"
                        :show-check-boxes="true"
                        :multiple="true"
                        headline-key="description"
                        sub-line-key="subDescription"
                        item-key="id"
                        :return-object="false"
                        v-model="selectedAddUsers"
                        :show-filter="true"
                        :items="availableUsers"
                        filter-label="Filter Users"
                        :height="400"
                    />
                </div>
            </template>
            <template slot="actions">
                <v-btn
                    small
                    color="secondary"
                    class="py-0 px-2 ma-0"
                    :disabled="updatingMembership || !Array.isArray(selectedAddUsers) || selectedAddUsers.length <= 0"
                    :loading="updatingMembership"
                    @click="addUsers()"
                >Add Users
                </v-btn>
            </template>
        </input-dlg>
<!--Add Groups-->
        <input-dlg
            :show-dialog="showAddGroups"
            title="Add Report Viewer Groups"
            :cancel-button="true"
            :show-input-box="false"
            @dismiss="showAddGroups = false"
            :body-padding="false"
        >
            <template slot="body">
                <div v-if="showAddGroups">
                    <multi-select-list
                        :show-action-buttons="false"
                        :show-check-boxes="true"
                        :multiple="true"
                        headline-key="description"
                        sub-line-key="users"
                        item-key="id"
                        :return-object="false"
                        v-model="selectedAddGroups"
                        :show-filter="true"
                        :items="availableGroups"
                        filter-label="Filter Groups"
                        :height="400"
                    />
                </div>
            </template>
            <template slot="actions">
                <v-btn
                    small
                    color="secondary"
                    class="py-0 px-2 ma-0"
                    :disabled="updatingMembership || !Array.isArray(selectedAddGroups) || selectedAddGroups.length <= 0"
                    :loading="updatingMembership"
                    @click="addGroups()"
                >Add View Groups
                </v-btn>
            </template>
        </input-dlg>
<!--Transfer Ownership-->
        <input-dlg
            :show-dialog="showTransfer"
            title="Transfer Ownership"
            :cancel-button="true"
            :show-input-box="false"
            @dismiss="showTransfer = false"
            :body-padding="false"
        >
            <template slot="body">
                <div v-if="showTransfer" class="body pa-2">
                    Transfer Ownership of {{ selectedReport.description }} to
                    {{ this.editors.filter(obj => this.selectedEditors.includes(obj.id)).pop().description }}.
                    <br>
                    Please note this action can not be undone.
                </div>
            </template>
            <template slot="actions">
                <v-btn
                    small
                    color="secondary"
                    class="py-0 px-2 ma-0"
                    :disabled="updatingMembership"
                    :loading="updatingMembership"
                    @click="transferOwnership()"
                >Transfer Ownership
                </v-btn>
            </template>
        </input-dlg>
    </v-layout>
</template>

<script>
import {codeBaseMixin} from "../../../codeBaseMixin";
import InputDlg from "@/components/General/InputDlg";
import MultiSelectList from "@/components/Multi Select List/MultiSelectList";

export default {
    components: {MultiSelectList, InputDlg},
    mixins: [codeBaseMixin],
    props: {
        userInfo: Object,
        selectedReport: Object,
        localData: Object
    },
    data() {
        return {
            VAL: false,
            showAddEditors: false,
            selectedAddEditors: [],
            selectedEditors: [],

            showAddUsers: false,
            selectedAddUsers: [],
            selectedUsers: [],

            showAddGroups: false,
            selectedAddGroups: [],
            selectedGroups: [],

            updatingMembership: false,
            showTransfer: false
        }
    },
    watch: {
        selectedReport: {
            immediate: true,
            handler() {
                // this.resetShare()
            }
        },

    },
    methods: {
        transferOwnership() {
            let membership = {};
            Object.assign(membership, this.selectedReport.membership);

            membership.editors = membership.editors.concat(this.selectedReport.creator)
            membership.editors = membership.editors.filter(editor => !this.selectedEditors.includes(editor))
            membership.viewUsers = membership.viewUsers.filter(userID => !this.selectedAddEditors.includes(userID))

            this.updatingMembership = true
            this.fetch({method: 'PATCH', url:`/wfr/share/updateOwner/${this.userInfo.entityID}/${this.selectedReport.id}/${this.userInfo.userID}/${this.selectedEditors.pop()}`, body: membership})
                .then((data) => {
                    Object.assign(this.selectedReport, data)
                    this.updatingMembership = false;
                    this.resetSelections();
                    this.$emit('ownershipChanged')
                })
                .catch((e) => {
                    // eslint-disable-next-line
                    console.log(e);
                    this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
                    this.updatingMembership = false;
                })
        },
        initTransfer() {
            this.showTransfer = true;
        },
        resetSelections() {
            this.selectedAddEditors = [];
            this.selectedEditors = [];
            this.showAddEditors = false;
            this.showAddUsers = false;
            this.selectedAddUsers = [];
            this.selectedUsers = [];
            this.showAddGroups = false;
            this.selectedAddGroups = [];
            this.selectedGroups = [];
            this.showTransfer = false;
        },
        removeGroups() {
            let membership = {};
            Object.assign(membership, this.selectedReport.membership);
            membership.viewGroups = membership.viewGroups.filter(groupID => !this.selectedGroups.includes(groupID))
            this.updateMembership(membership);
        },
        addGroups() {
            let membership = {};
            Object.assign(membership, this.selectedReport.membership);
            membership.viewGroups = membership.viewGroups.concat(this.selectedAddGroups)
            this.updateMembership(membership);
        },
        removeUsers() {
            let membership = {};
            Object.assign(membership, this.selectedReport.membership);
            membership.viewUsers = membership.viewUsers.filter(user => !this.selectedUsers.includes(user))
            this.updateMembership(membership);
        },
        addUsers() {
            let membership = {};
            Object.assign(membership, this.selectedReport.membership);

            membership.viewUsers = membership.viewUsers.concat(this.selectedAddUsers)
            membership.editors = membership.editors.filter(userID => !this.selectedAddUsers.includes(userID))

            this.updateMembership(membership);
        },
        removeEditors() {
            let membership = {};
            Object.assign(membership, this.selectedReport.membership);
            membership.editors = membership.editors.filter(user => !this.selectedEditors.includes(user))
            this.updateMembership(membership);
        },
        addEditors() {
            let membership = {};
            Object.assign(membership, this.selectedReport.membership);

            membership.editors = membership.editors.concat(this.selectedAddEditors)
            membership.viewUsers = membership.viewUsers.filter(userID => !this.selectedAddEditors.includes(userID))

            this.updateMembership(membership);
        },
        updateMembership(val) {
            this.updatingMembership = true
            this.fetch({method: 'PATCH', url:`/wfr/share/updateMembers/${this.userInfo.entityID}/${this.selectedReport.id}/${this.userInfo.userID}`, body: val})
                .then((data) => {
                    Object.assign(this.selectedReport, data)
                    this.updatingMembership = false;
                    this.resetSelections();
                })
                .catch((e) => {
                    // eslint-disable-next-line
                    console.log(e);
                    this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
                    this.updatingMembership = false;
                })
        }

    },
    computed: {
        editorActionButtons() {
            if (Array.isArray(this.selectedEditors) && this.selectedEditors.length === 1) {
                return [{icon: 'forward', color: 'red', cb: this.initTransfer, hoverItem: 'selectedOnly'},{icon: 'delete', color: 'red', cb: this.removeEditors, hoverItem: 'selectedOnly'}]
            } else {
                return [{icon: 'delete', color: 'red', cb: this.removeEditors, hoverItem: 'selectedOnly'}]
            }

        },
        availableEditors() {
            return this.localData.users
                .filter(user => !this.selectedReport.membership.editors.includes(user.userID) && user.userID !== this.selectedReport.creator)
                .map(obj => {
                    return {
                        id: obj.userID,
                        description: `${obj.name} ${obj.surname}`.trim(),
                        subDescription: obj.username
                    }
                });
        },
        editors() {
            return this.localData.users
                .filter(user => this.selectedReport.membership.editors.includes(user.userID))
                .map(obj => {
                    return {
                        id: obj.userID,
                        description: `${obj.name} ${obj.surname}`.trim(),
                        subDescription: obj.username
                    }
                });
        },
        availableUsers() {
            return this.localData.users
                .filter(user => !this.selectedReport.membership.viewUsers.includes(user.userID) && user.userID !== this.selectedReport.creator)
                .map(obj => {
                    return {
                        id: obj.userID,
                        description: `${obj.name} ${obj.surname}`.trim(),
                        subDescription: obj.username
                    }
                });
        },
        users() {
            return this.localData.users
                .filter(user => this.selectedReport.membership.viewUsers.includes(user.userID))
                .map(obj => {
                    return {
                        id: obj.userID,
                        description: `${obj.name} ${obj.surname}`.trim(),
                        subDescription: obj.username
                    }
                });
        },
        availableGroups() {
            return this.localData.userGroups
                .filter(group => !this.selectedReport.membership.viewGroups.includes(group.id))
                .map(group => {
                    return {
                        id: group.id,
                        description: group.description,
                        users: this.localData.users
                            .filter(user => group.users.includes(user.userID))
                            .map(user => {return `${user.name} ${user.surname}`.trim()})
                            .join(', ')
                    }
                })
        },
        groups() {
            return this.localData.userGroups
                .filter(group => this.selectedReport.membership.viewGroups.includes(group.id))
                .map(group => {
                    return {
                        id: group.id,
                        description: group.description,
                        users: this.localData.users
                            .filter(user => group.users.includes(user.userID))
                            .map(user => {return `${user.name} ${user.surname}`.trim()})
                            .join(', ')
                    }
                })
        }
    }
}
</script>

<style scoped>

</style>
