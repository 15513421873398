import { render, staticRenderFns } from "./AddStepToWorkflow.vue?vue&type=template&id=5f5a0767&scoped=true"
import script from "./AddStepToWorkflow.vue?vue&type=script&lang=js"
export * from "./AddStepToWorkflow.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f5a0767",
  null
  
)

export default component.exports