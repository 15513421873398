<template>
    <div>

        <div class="pt-1">
            <v-btn small flat color="secondary" class="ma-0" @click="configuringField = !configuringField">Configure Eval Field</v-btn>
        </div>
        <div class="c-d-flex c-align-center c-justify-space-between pt-1">
            <div class="pl-2">
                <v-switch
                        color="primary"
                        dense
                        label="Update Task Title"
                        v-model="updateTaskTitle"
                        hide-details
                        class="py-0 my-0"
                        @change="doneRun()"
                >
                </v-switch>
            </div>
            <div class="pr-2">
                <v-switch
                        color="error"
                        dense
                        label="Force Calculation"
                        v-model="forceCalc"
                        hide-details
                        class="py-0 my-0"
                        @change="doneRun()"
                >
                </v-switch>
            </div>
        </div>



        <eval-template-editor
                v-if="configuringField"
                :shown="configuringField"
                v-model="localLookup.evalStr"
                :user-info="userInfo"
                :steps="steps"
                :field-sets="fieldSets"
                :fields="fields"
                @dismiss="configuringField = false"
                @input="doneRun()"
        />

    </div>
</template>

<script>
    import InputDlg from "../../../../../../../../components/General/InputDlg";
    import {codeBaseMixin} from "../../../../../../../../codeBaseMixin";
    import WorkflowSelect from '../FieldType10Components/WorkflowSelect';
    import FieldsSelect from "../FieldType10Components/FieldsSelect";
    import FolderDlg from '../../../../../../../../Folders/FolderDlg';
    import WorkflowManagerGroupSelector from '../../WorkflowManagerGroupSelector'
    import FieldSetFieldSelector from "../../FieldSetFieldSelector";
    import EvalTemplateEditor from "./EvalTemplateEditor";

    export default {
        mixins: [codeBaseMixin],
        components: {
            EvalTemplateEditor,
            appInputDlg: InputDlg,
            appFieldSetFieldSelector: FieldSetFieldSelector
            // appWorkflowManagerGroupSelector: WorkflowManagerGroupSelector,
            // appFolderDlg: FolderDlg,
            // appFieldsSelect: FieldsSelect,
            // appInputDlg: InputDlg,
            // appWorkflowSelect: WorkflowSelect
        },
        props: {
            userInfo: Object,
            value: [String, Object],
            localData: Object,
            steps: Array,
            fieldSets: Array,
            fields: Array,
        },
        data() {
            return {
                showConfigureTemplate: false,
                configuringField: false,
                localLookup: {
                    evalStr: "",
                    force: false
                },
            }
        },
        mounted() {
            this.fillLookup();
        },
        watch: {
            value: {
                immediate: true,
                handler(val) {
                    //this.fillLookup();
                }
            },
        },
        methods: {

            doneRun() {
                // TODO Save Yourself
                console.log("DONE RUN")
                this.$emit('input', this.localLookup)
                this.configuringField = false;
            },

            fillLookup() {
                if (!this.blankString(this.value)) {
                    try {
                        this.localLookup = typeof this.value == "string" ? JSON.parse(this.value) : JSON.parse(JSON.stringify(this.value || {}));
                    } catch (e) {
                        console.log(e, this.value)
                        this.localLookup = {
                            evalStr: "",
                            force: false
                        }
                    }
                }
            }

        },
        computed: {
            updateTaskTitle: {
                get() {
                    console.log("local Lookup", this.localLookup)
                    return this.localLookup.updateTaskTitle || false
                },
                set(val) {
                    this.$set(this.localLookup, "updateTaskTitle", val)
                }
            },

            forceCalc: {
                get() {
                    return this.localLookup.force || false
                },
                set(val) {
                    this.$set(this.localLookup, "force", val)
                }
            }
        }
    }
</script>

<style scoped>

</style>
