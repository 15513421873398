<template>
    <div>
        <v-card
                :hover="true"
                ripple
                class="secondary"
                @click="showAddStep = true"
        >
            <v-layout row align-center>
                <v-flex shrink class="pl-1">
                    <v-icon small class="pa-0 ma-0" color="white">add</v-icon>
                </v-flex>
                <v-flex shrink>
                    <div class="pr-2 pl-1">
                        <span class="caption font-weight-regular white--text" style="white-space: pre">Add Step</span>
                    </div>
                </v-flex>
            </v-layout>
        </v-card>

        <app-input-dlg
                :show-dialog="showAddStep"
                title="Add New Step"
                :cancel-button="true"
                :show-input-box="false"
                @dismiss="showAddStep = false"
                :body-padding="false"
                :width="500"
        >
            <template slot="body" v-if="showAddStep">
                <app-workflow-step-configure
                        :userInfo="userInfo"
                        :value="null"
                        :userGroups="userGroups"
                        @input="doneRun($event)"
                        :workflow-id="workflowId"
                        :add-step="true"
                        :steps="steps"
                        :fields="fields"
                >
                </app-workflow-step-configure>
            </template>
        </app-input-dlg>
    </div>

</template>

<script>
    import {codeBaseMixin} from "../../../../../../codeBaseMixin";
    import InputDlg from "../../../../../../components/General/InputDlg";
    import WorkflowStepConfigure from "./WorkflowStepConfigure";



    export default {
        mixins: [codeBaseMixin],
        components: {
            appInputDlg: InputDlg,
            appWorkflowStepConfigure: WorkflowStepConfigure
        },
        props: {
            userInfo: Object,
            userGroups: Array,
            workflowId: Number,
            steps: Array,
            fields: Array
        },
        data() {
            return {
                showAddStep: false,
            }
        },
        methods: {
            doneRun(newStep) {

                this.showAddStep = false;
                this.steps.push(newStep);
                this.$emit('newStepID', newStep.id);
            }
        },
    }
</script>

<style scoped>

</style>
