<template>
  <v-dialog
    v-if="shownRelay"
    v-model="shownRelay"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    persistent
  >
    <v-card flat height="100vh" v-if="shownRelay">

      <v-layout column fill-height class="of-y">
        <!--Card Title-->
        <v-flex shrink primary>
          <v-card-title class="py-2 pr-2 ">
            <v-icon class="white--text">description</v-icon>
            <span class="title font-weight-light white--text pl-2">Word Template Editor</span>
            <v-spacer></v-spacer>
            <v-btn
              v-if="!startingUp"
              flat
              color="white"
              @click="selectRepoDoc"
              :loading="switchingDoc"
              :disabled="switchingDoc"
            >
              Document: {{repoDoc.id ? repoDoc.description : 'Select Document'}}
            </v-btn>
            
            <v-spacer></v-spacer>
            
            <v-switch
                hide-details
                label="Generate PDF"
                v-model="generatePdf"
                dark
                color="white"
                class="py-0 my-0"
            ></v-switch>
            
            
            <v-spacer></v-spacer>


            <v-btn flat icon color="white" class="pa-0 ma-0" @click="shownRelay = false">
              <v-icon>close</v-icon>
            </v-btn>
          </v-card-title>
        </v-flex>

        <v-flex v-if="startingUp" class=" of-y">
          <div  class="c-d-flex align-center justify-center fill-height">
            <v-progress-circular
              :size="40"
              :width="5"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </div>
        </v-flex>

        <template v-else>
<!--          <v-flex shrink primary>-->
<!--            <v-btn @click="startup">Startup</v-btn>-->
<!--          </v-flex>-->

<!--          <v-flex shrink primary>-->
<!--            <div class="c-d-flex c-align-center c-justify-space-around white&#45;&#45;text">-->

<!--              <div>-->
<!--                <v-btn-->
<!--                  flat-->
<!--                  color="white"-->
<!--                  @click="selectRepoDoc"-->
<!--                  :loading="switchingDoc"-->
<!--                  :disabled="switchingDoc"-->
<!--                >-->
<!--                  Document: {{repoDoc.id ? repoDoc.description : 'Select Document'}}-->
<!--                </v-btn>-->
<!--              </div>-->
<!--            </div>-->




<!--          </v-flex>-->

          <v-flex v-if="repoDoc.id" class=" of-y">

            <v-layout fill-height class="of-y">
              <!--Card Title-->
              <v-flex xs8 class="fill-height of-y">

                <div style=" z-index: 9; position: sticky; top: 0">

                  <div class="primary px-2 pt-0 pb-2">
                    <v-text-field
                      v-model="placeholderSearch"
                      label="Search Placeholders"
                      dark
                      flat
                      solo-inverted
                      hide-details
                      clearable
                      clear-icon="mdi-close-circle-outline"
                    />
                  </div>

                  <v-progress-linear v-if="savingFieldChanges" :indeterminate="true" class="py-0 my-0" color="secondary"/>
                  <div class="c-d-flex c-align-center px-2 py-2 font-weight-bold card-back" >
                    <div style="width: 50%" class="px-1" >
                      Field
                    </div>
                    <div style="width: 50%;" class="px-1">
                      Value
                    </div>
                  </div>
                  <div style="height: 1px; background: rgb(200,200,200);">

                  </div>
                </div>



                <div
                  class="c-d-flex c-align-center px-2 hover-item"
                  v-for="line in filteredFields"
                  style="border-bottom: 1px solid rgb(200,200,200)"
                  @dragover="$event.preventDefault()"
                  @drop="droppedEvent($event, line)"
                >

                  <div class="c-d-flex align-center" style="width: 50%">
                    <div  class="c-flex-grow-1 c-d-flex c-align-center px-1 ">
                      <v-icon v-if="!line.exists" color="error" left>warning</v-icon>
                      <div class="py-2" >
                        {{line.placeholder}}<span class="caption error--text" v-if="!line.exists">&nbsp;Field not found in template</span>
                      </div>
                    </div>

                    <v-btn icon small @click="removeMap(line)" v-if="!line.exists">
                      <v-icon color="error">delete</v-icon>
                    </v-btn>
                  </div>




                  <div style="width: 50%;" class="px-1">
                    <template v-if="line.fieldId">
                      <template v-if="displayFieldMap.has(line.fieldId)">
                          <template v-for="chip in [displayFieldMap.get(line.fieldId)]">
                            <div class="c-d-flex c-justify-space-between c-align-center">
                              <v-chip
                                small
                                class=""
                                :class="chip.visible ? 'primary white--text' : undefined"
                                close
                                @input="removeMap(line)"
                              >
                                <v-icon style="z-index: 0" class="pr-2">
                                  <template v-if="chip.type === 'text'">text_fields</template>
                                  <template v-else-if="chip.type === 'date'">today</template>
                                  <template v-else-if="chip.type === 'number'">tag</template>
                                  <template v-else-if="chip.type === 'currency'">money</template>
                                  <template v-else-if="chip.type === 'image'">image</template>
                                  <template v-else-if="chip.type === 'fixed'">format_quote</template>
                                  <template v-else-if="chip.type === 'input'">person</template>
                                </v-icon>
                                {{ chip.description }}
                              </v-chip>
                              <v-btn icon small :color="line.required ? 'secondary' : undefined" @click="toggleRequired(line)">
                                <v-icon :color="line.required ? 'white' : 'grey'">done_all</v-icon>
                              </v-btn>
                            </div>
                          </template>
                      </template>
                      <template v-else>
                        FIELD NOT FOUND {{line.fieldId}}
                      </template>
                    </template>
                  </div>
                </div>

              </v-flex>

              <v-flex xs4 class="fill-height of-y">
                <div class="primary px-2 pt-0 pb-2" style="z-index: 9; position: sticky; top: 0">
                  <v-text-field
                    v-model="treeSearch"
                    label="Search Available Fields"
                    dark
                    flat
                    solo-inverted
                    hide-details
                    clearable
                    clear-icon="mdi-close-circle-outline"
                  />
                </div>

                <v-card-text>
                  <v-treeview
                    :items="fieldTree"
                    :search="treeSearch"
                    item-text="description"
                    item-children="children"
                    item-key="id"

                  >
<!--                    <template v-slot:prepend="{ item }">-->
<!--                      <v-icon-->
<!--                        v-if="item.children"-->
<!--                        v-text="`mdi-${item.id === 1 ? 'home-variant' : 'folder-network'}`"-->
<!--                      ></v-icon>-->
<!--                    </template>-->

                    <template #label="{ item }">
                      <template v-if="!item.type">
                        {{item.description}}
                      </template>
                      <template v-else>

                        <v-chip
                          small
                          :class="item.visible ? 'primary white--text' : undefined"
                          style="cursor: pointer"
                          :draggable="true"
                          @drag="$event.preventDefault()"
                          @dragstart="$event.dataTransfer.setData('text', item.id);"


                        >
                          <v-icon>
                            <template v-if="item.type === 'text'">text_fields</template>
                            <template v-else-if="item.type === 'date'">today</template>
                            <template v-else-if="item.type === 'number'">tag</template>
                            <template v-else-if="item.type === 'currency'">money</template>
                            <template v-else-if="item.type === 'image'">image</template>
                            <template v-else-if="item.type === 'fixed'">format_quote</template>
                            <template v-else-if="item.type === 'input'">person</template>
                          </v-icon>
                          {{item.description}}
                        </v-chip>
                      </template>
                    </template>

                  </v-treeview>
                </v-card-text>

              </v-flex>

            </v-layout>

          </v-flex>

        </template>

        <app-input-dlg
          v-if="userInputPrompt"
          :show-dialog="!!userInputPrompt"
          title="User Input"
          :body="`User input title`"
          :cancel-button="true"
          :show-input-box="true"
          :buttons="['Confirm']"
          :call-backs="[ addUserInputField ]"
          @dismiss="userInputPrompt = null"
        />

        <app-input-dlg
          v-if="fixedValuePrompt"
          :show-dialog="!!fixedValuePrompt"
          title="Fixed Value"
          :body="`Please supply a fixed value`"
          :cancel-button="true"
          :show-input-box="true"
          :buttons="['Confirm']"
          :call-backs="[ addFixedValue ]"
          @dismiss="fixedValuePrompt = null"
        />

      </v-layout>
    </v-card>
  </v-dialog>
</template>

<script>
import {arrSplice} from "@/codeFunctions";
import appInputDlg from "@/components/General/InputDlg.vue";

export default {
  components: {appInputDlg},
  props: {
    fieldId: {
      type: Number,
      default: 0
    },
    shown: Boolean
  },
  watch: {
    shown: {
      immediate: true,
      handler(val) {
        if (val)
          this.startup()
      }
    }
  },
  data() {
    return {
      startingUp: false,

      lookup: {},
      repoDoc: {
        id: null,
        description: null,
      },
      placeholders: [],
      fieldTree: [],

      switchingDoc: false,


      pgSync: {
        descending: false,
        page: 0,
        rowsPerPage: -1,
        sortBy: null,
        totalItems: null
      },

      treeSearch: null,
      placeholderSearch: null,

      savingFieldChanges: false,

      userInputPrompt: null,
      fixedValuePrompt: null
    }
  },
  methods: {

    async removeMap(map) {
      let preSave = JSON.stringify(this.lookup.map || [])
      try {
        if (!this.lookup.map)
          this.$set(this.lookup, "map", [])
        arrSplice(this.lookup.map, field => field.placeholder === map.placeholder)
        this.savingFieldChanges = true
        await this.saveLookup()
        preSave = null
      } catch (e) {
        console.log(e)
        this.$snack.networkError()
      } finally {
        if (preSave)
          this.$set(this.lookup, "map", JSON.parse(preSave))
        this.savingFieldChanges = false
        this.userInputPrompt = null
      }
    },

    async addUserInputField(val) {
      let preSave = JSON.stringify(this.lookup.map || [])

      try {

        if (!val)
          return this.$snack.error('Title is required')

        const fieldId = `ui-${val.trim()}`;

        if (!this.lookup.map)
          this.$set(this.lookup, "map", [])

        const mapField = this.lookup.map.find(obj => obj.placeholder === this.userInputPrompt.placeholder) || this.userInputPrompt
        arrSplice(this.lookup.map, field => field === mapField)
        mapField.fieldId = fieldId
        this.lookup.map.push(mapField)

        this.savingFieldChanges = true
        await this.saveLookup()
        preSave = null
      } catch (e) {
        console.log(e)
        this.$snack.networkError()
      } finally {
        if (preSave)
          this.$set(this.lookup, "map", JSON.parse(preSave))
        this.savingFieldChanges = false
        this.userInputPrompt = null
      }
    },

    async addFixedValue(val) {
      let preSave = JSON.stringify(this.lookup.map || [])

      try {

        if (!val)
          return this.$snack.error('Value is required')

        const fieldId = `fv-${val.trim()}`;

        if (!this.lookup.map)
          this.$set(this.lookup, "map", [])

        const mapField = this.lookup.map.find(obj => obj.placeholder === this.fixedValuePrompt.placeholder) || this.fixedValuePrompt
        arrSplice(this.lookup.map, field => field === mapField)
        mapField.fieldId = fieldId
        this.lookup.map.push(mapField)

        this.savingFieldChanges = true
        await this.saveLookup()
        preSave = null
      } catch (e) {
        console.log(e)
        this.$snack.networkError()
      } finally {
        if (preSave)
          this.$set(this.lookup, "map", JSON.parse(preSave))
        this.savingFieldChanges = false
        this.fixedValuePrompt = null
      }
    },



    async toggleRequired(line) {

      let preSave = JSON.stringify(this.lookup.map || [])

      try {
        const mapField = this.lookup.map.find(obj => obj.placeholder === line.placeholder) || line

        mapField.required = !mapField.required

        this.savingFieldChanges = true
        await this.saveLookup()
        preSave = null
      } catch (e) {
        console.log(e)
        this.$snack.networkError()
      } finally {
        if (preSave)
          this.$set(this.lookup, "map", JSON.parse(preSave))
        this.savingFieldChanges = false
      }
    },

    async droppedEvent(e, line) {

      let preSave = JSON.stringify(this.lookup.map || [])

      try {
        const fieldId = e.dataTransfer.getData('text');

        if (fieldId === 'ui-?')
          this.userInputPrompt = line

        if (fieldId === 'fv-?')
          return this.fixedValuePrompt = line


        if (!fieldId)
          return this.$snack.error('Invalid Field ID')

        if (!this.lookup.map)
          this.$set(this.lookup, "map", [])

        const mapField = this.lookup.map.find(obj => obj.placeholder === line.placeholder) || line
        arrSplice(this.lookup.map, field => field === mapField)
        mapField.fieldId = fieldId
        this.lookup.map.push(mapField)

        this.savingFieldChanges = true
        await this.saveLookup()
        preSave = null
      } catch (e) {
        console.log(e)
        this.$snack.networkError()
      } finally {
        if (preSave)
          this.$set(this.lookup, "map", JSON.parse(preSave))
        this.savingFieldChanges = false
      }
    },

    async startup() {
      try {
        this.startingUp = true
        const {
          lookup,
          repoDoc,
          placeholders,
          fieldTree,
        } = await this.$newReq('GET', 'mswt/editor/startup/' + this.fieldId)
        this.lookup = lookup;
        this.repoDoc = repoDoc;
        this.placeholders = placeholders;
        this.fieldTree = fieldTree;
      } catch (e) {
        console.log(e)
        this.$snack.networkError()
      } finally {
        this.startingUp = false
      }
    },

    async selectRepoDoc() {
      try {
        const doc = await this.$getRepoDocs({
          multiple: false,
          allowUserDocs: false
        })
        if (doc.document.extention !== 'docx')
          return this.$snack.error('.docx Required')

        try {
          this.switchingDoc = false
          if (!this.lookup)
            this.$set(this, "lookup", {})
          this.lookup.document = doc.placeHolderID
          await this.saveLookup();
          await this.startup();
        } catch (e) {
            console.log(e)
        } finally {
          this.switchingDoc = false
        }

      } catch (e) {
        console.log(e)
      }
    },

    async saveLookup() {
      await this.$newReq('POST', 'mswt/editor/lookup/' + this.fieldId, this.lookup)
    }
  },
  computed: {
    displayFieldMap() {

      const fields = new Map()

      function extractFieldAndChildren(field) {
        fields.set(field.id, field)
        if (field.children && Array.isArray(field.children))
          for (const child of field.children)
            extractFieldAndChildren(child)
      }

      for (const item of this.fieldTree)
        extractFieldAndChildren(item)

      // Add All User Input fields
      const userInputMaps = (this.lookup.map || []).filter(map => map.fieldId.match(/^ui-/))

      for (const field of userInputMaps)
        fields.set(field.fieldId, {
          id: field.fieldId,
          description: `"${field.fieldId.match(/(?<=ui-).+$/).shift() || 'Input title not found'}"`,
          visible: 1,
          fieldOrder: 0,
          type: "input"
        })

      // Add All Fixed Value Fields
      const fixedValueMaps = (this.lookup.map || []).filter(map => map.fieldId.match(/^fv-/))

      for (const field of fixedValueMaps)
        fields.set(field.fieldId, {
          id: field.fieldId,
          description: `"${field.fieldId.match(/(?<=fv-).+$/).shift() || 'Fixed value not found'}"`,
          visible: 1,
          fieldOrder: 0,
          type: "fixed"
        })

      return fields
    },
    shownRelay: {
      get() {
        return this.shown
      },
      set(val) {
        this.$emit('update:shown', val)
      }
    },
    fields() {
      const lookupFields = [...(this.lookup.map || []).map(val => ({...val}))]
      const rawPlaceholders = this.placeholders
        .filter(pc => !lookupFields.find(lf => lf.placeholder === pc))
        .map(str => ({
          placeholder: str,
          fieldId: null,
          val: null,
          required: false
        }))
      return [...rawPlaceholders, ...lookupFields]
        .map(field => ({
          ...field,
          exists: this.placeholders.includes(field.placeholder)
        }))
        .sort((a,b) => this.placeholders.indexOf(a.placeholder) - this.placeholders.indexOf(b.placeholder))
    },
    filteredFields() {
      return (this.fields || []).filter(val => this.placeholderSearch ? JSON.stringify(val).toLowerCase().includes((this.placeholderSearch || '').toLowerCase()) : true)
    },
    generatePdf: {
      get() {
        return !this.lookup ? false : this.lookup.generatePdf || false
      },
      set(val) {
        
        if (!this.lookup)
          this.$set(this, "lookup", {})
        this.$set(this.lookup, "generatePdf", val)
        this.saveLookup()
            .then(() => this.$snack.info('Changes Saved'))
            .catch(e => {
              console.log(e)
              this.$snack.networkError()
            })
      }
    }
  },
}
</script>

<style scoped>


</style>