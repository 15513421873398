<template>
    <v-dialog
            v-model="modelValue"
            persistent
            width="400"
    >
        <v-card>
            <v-card-title class="pa-1 pl-2 primary">
                <span class="subheading font-weight-light white--text">User Digest</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
                <v-form ref="digestForm">
                    <v-switch
                            class="pt-0 mt-0"
                            label="Digest Enabled"
                            v-model="digest"
                            hide-details
                            color="primary"
                    >
                    </v-switch>
                    <v-text-field
                            :rules="digest ? [validationRules.required, validationRules.email] : []"
                            label="Digest Email Address"
                            color="primary"
                            class="pb-0 pt-3 mb-0"
                            v-model="digestEmailAddress"
                    ></v-text-field>
                </v-form>


            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                        color="secondary"
                        flat
                        @click="modelValue = false"
                >
                    Cancel
                </v-btn>

                <v-btn
                        color="secondary"
                        flat
                        :disabled="savingChanges || digest ? validationRules.email(digestEmailAddress) !== true : false"
                        :loading="savingChanges"
                        @click="saveChanges()"
                >
                    Save Changes
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import {mapGetters} from 'vuex'
import {validationRules} from "@/codeFunctions";

export default {
    components: {},
    props: {
        value: Boolean,
    },
    data() {
        return {
            digest: false,
            digestEmailAddress: null,
            validationRules: validationRules,
            savingChanges: false
        }
    },
    watch: {
        value: {
            immediate: true,
            handler(val) {
                if (val) {
                    this.digest = this.userInfo.digest;
                    this.digestEmailAddress = this.userInfo.digestEmailAddress;
                }
            }
        }
    },
    methods: {
        saveChanges() {
            this.savingChanges = true;
            this.$newReq(`POST`, `users/updateDigest/${this.userInfo.entityID}/${this.userInfo.userID}`, {digest: this.digest, digestEmailAddress: this.digestEmailAddress})
                .then(() => {
                    this.userInfo.digest = this.digest;
                    this.userInfo.digestEmailAddress = this.digestEmailAddress;
                    this.$snack.info('Change Saved')
                    this.modelValue = ! this.modelValue
                })
                .catch(e => {
                    console.log(e)
                    this.$snack.networkError()
                })
                .finally(() => {
                    this.savingChanges = false
                })
        }
    },
    computed: {
        ...mapGetters('userInfo', ['userInfo']),
        modelValue: {
            get() {
                return this.value
            },
            set(val) {
                this.$emit('input', val)
            }
        }
    },
}
</script>

<style scoped>

</style>