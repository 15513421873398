<template>
    <div class="c-d-flex c-align-center">
        <div class="c-flex-equal c-flex-grow-1" v-if="showDefaultValueSwitch">
            <div :class="headingClass">
                Default Value (Current Date Time):
            </div>
            <div :class="itemClass">
                <div class="pl-2">
                    <v-switch
                            v-model="lookup"
                            hide-details
                            color="secondary"
                            class="pa-0 ma-0"
                    ></v-switch>
                </div>
            </div>
        </div>
        <div class="c-flex-equal c-flex-grow-1">
            <div :class="headingClass">
                Date Only Field:
            </div>
            <div :class="itemClass">
                <div class="pl-2">
                    <v-switch
                            v-model="dateOnly"
                            hide-details
                            color="secondary"
                            class="pa-0 ma-0"
                    ></v-switch>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import {codeBaseMixin} from "../../../../../../../codeBaseMixin";

    export default {
        mixins: [codeBaseMixin],
        props: {
            value: {
                type: Object,
                default: () => {
                    return {
                        lookup_type: null,
                        lookup: null
                    }
                }
            },
            userInfo: Object,
            showDefaultValueSwitch: {
                default: true,
                type: Boolean
            }
        },
        data() {
            return {
                someVal: null,
                headingClass: 'caption greyType--text font-weight-bold',
                topicClass: 'pa-1',
                itemClass: 'pl-2',
            }
        },
        computed: {
            dateOnly: {
                get() {
                    return !!this.value.lookup_type;
                },
                set(val) {
                    this.$emit('input', {
                        lookup_type: val ? 1 : null,
                        lookup: this.value.lookup
                    })
                }
            },
            lookup: {
                get() {
                    try {
                        return this.value != null && typeof this.value.lookup != "undefined" && this.intToBool(parseInt(this.value.lookup));
                    } catch (e) {
                        // eslint-disable-next-line
                        console.log(e);
                        return false;
                    }
                },
                set(val) {
                    this.$emit('input', {
                        lookup_type: this.value.lookup_type,
                        lookup: `${this.boolToInt(val)}`
                    })
                }
            },
        }
    }
</script>

<style scoped>

</style>
