<template>
    <v-layout row wrap v-show="selectedStep.stepID != 0">
<!--        <div @click="newCompose(0, null, null, false, null)">-->
<!--            <slot></slot>-->
<!--        </div>-->
        <div @click="newCreateMail()">
            <slot></slot>
        </div>
<!--&lt;!&ndash;Draft Containers&ndash;&gt;-->
<!--        <template v-for="(draft, i) in draftMails">-->
<!--            <v-flex-->
<!--                    xs12-->
<!--                    :key="i + 'draft'"-->
<!--                    class="pa-2"-->
<!--                    v-if="draft.stepID == selectedStep.stepID"-->
<!--            >-->
<!--                <app-task-draft-mail-->
<!--                    :user-info="userInfo"-->
<!--                    :selected-step="selectedStep"-->
<!--                    :task="task"-->
<!--                    :draft="draft"-->
<!--                    @openDraft="openDraftMailCompose(draft)"-->
<!--                />-->
<!--                <v-divider></v-divider>-->
<!--            </v-flex>-->
<!--        </template>-->

<!--Mail Containers-->
<!--        <template v-for="(mail, i) in taskMails">-->
<!--            <v-flex xs12 :key="i + 'mail'" class="pa-2">-->
<!--                <app-mail-container-->
<!--                        :mail="mail"-->
<!--                        :task="task"-->
<!--                        :user-info="userInfo"-->
<!--                        :key="mail.messageID"-->
<!--                        :dashboard="false"-->
<!--                        :task-band="false"-->
<!--                        :task-mail="true"-->
<!--                        :mail-config="mailConfig"-->
<!--                        :new-mail-fn="newCompose"-->
<!--                />-->
<!--                <v-divider></v-divider>-->
<!--            </v-flex>-->
<!--        </template>-->
<!--        <app-compose-mail-->
<!--                :show-dialog="showCompose"-->
<!--                :user-info="userInfo"-->
<!--                @dismiss="showCompose = false"-->
<!--                :mail-config="mailConfig"-->
<!--                :link-task="task"-->
<!--                :link-to-task="true"-->
<!--                :compose-type="mailComposeType"-->
<!--                :src-mail="mailComposeMailSrc"-->
<!--                :src-mail-body-src="mailComposeMailSrcBody"-->
<!--                :src-mail-path="mailComposeMailPath"-->
<!--                :start-with-draft="mailComposeStartWithDraft"-->
<!--                :draft="mailComposeDraft"-->
<!--                :task-step-i-d="mailComposeStepID"-->
<!--                @newDraft="newDraft($event)"-->
<!--                @replaceDraft="replaceDraft($event)"-->
<!--                @removeDraft="removeDraft($event)"-->
<!--                @draftError="draftError($event)"-->
<!--                @pushTaskMail="addNewMail($event)"-->

<!--        />-->

        <create-mail ref="createMail" v-model="showCompose"/>
    </v-layout>
</template>

<script>

    import { eventBus } from '../../../../main';
    import {codeBaseMixin} from "../../../../codeBaseMixin";
    import TaskDraftMail from "./TaskDraftMail";
    import ComposeMail from "../../../../components/General/ComposeMail";
    import CreateMail from "@/Mail/Create Mail/CreateMail";
    import {feed} from '../../../../feedSocket'

    export default {
        mixins: [
            codeBaseMixin
        ],
        components: {
            CreateMail,
            appComposeMail: ComposeMail,
            appTaskDraftMail: TaskDraftMail,
        },
        props: {
            selectedStep: Object,
            userInfo: Object,
            task: Object,
            mailConfig: Object,
        },
        data() {
            return {
                removeCheck: false,
                iReset: 0,
                showCompose: false,
                mailComposeType: 0,
                mailComposeMailSrc: null,
                mailComposeMailSrcBody: null,
                mailComposeMailPath: 'task',
                mailComposeStartWithDraft: false,
                mailComposeDraft: null,
                mailComposeStepID: null
            }
        },
        beforeDestroy() {
            eventBus.$off('taskMailLinked', this.resetIReset);
            eventBus.$off('taskMailSent', this.resetIReset);
            eventBus.$off('openDraftMailCompose', this.openDraftMailCompose);
            eventBus.$off('newCompose', this.newComposeRelay);
            feed.off('createMailStatus', this.updateDraftSendStatus)
            this.removeCheck = true;
        },
        created() {
            eventBus.$on('taskMailLinked', this.resetIReset);
            eventBus.$on('taskMailSent', this.resetIReset);
            eventBus.$on('openDraftMailCompose', this.openDraftMailCompose);
            eventBus.$on('newCompose', this.newComposeRelay);
            feed.on('createMailStatus', this.updateDraftSendStatus)
        },
        methods: {
            resetIReset() {
                this.iReset = ++this.iReset;
            },

            updateDraftSendStatus(data) {
                console.log('UPDATE SEND STATUS', data)

                if (this.task && this.task.structure && this.task.structure.draftMails) {
                    this.task.structure.draftMails.forEach((draft, index) => {
                        if (data.draftID === draft.id) {
                            if (data.sent) {
                                this.task.structure.draftMails.splice(index, 1)
                                if (data.mail)
                                    this.task.structure.mails.push(data.mail)
                                this.$snack.info('Mail Sent')
                            } else {
                                draft.sendStatus = 2;
                                draft.errorMessage = data.errorMessage
                                this.$snack.error('Error Sending Mail')
                            }
                        }
                    })
                }

            },

            newComposeRelay(obj) {

                this.$refs.createMail.init(obj.composeType || 0, obj.srcMailFilename || null, 'store', true, this.task, this.selectedStep.stepID)

                // this.newCompose(obj.type, obj.mailSrc, obj.mailBody, obj.draftMessage, obj.draftObject);
            },

            addNewMail(mail) {
                this.task.structure.mails.push(mail)
            },

            draftError(errorObj) {
                //Delete Draft Here
                this.task.structure.draftMails.forEach(draft => {
                    if (draft.id === errorObj.draftID) {
                        draft.sendStatus = 2;
                        this.$set(draft, 'errorMessage', errorObj.message);
                    }
                })
            },

            removeDraft(draftID) {
                //Delete Draft Here
                this.task.structure.draftMails = this.task.structure.draftMails.filter(obj => obj.id != draftID);
            },
            replaceDraft(draft) {
                this.mailComposeStartWithDraft = true;

                let draftObject = this.mapObject(JSON.parse(draft.draftObject), 'data', (data) => {
                    return this.mapObject(data, 'bodyModel', (body) => {
                        return body.replace(/(\r\n|\n|\r|\t)/gm," ").replace(/\\"/g, '"');
                    });
                });
                this.mailComposeDraft = this.mapObject(draftObject.data, 'id', () => {
                    return draft.id
                });
                this.task.structure.draftMails = this.task.structure.draftMails.map(obj => {
                    return obj.id != draft.id ? obj : draft
                });

                this.iReset = ++this.iReset
            },

            newDraft(draft) {
                this.mailComposeStartWithDraft = true;


                let draftObject = this.mapObject(JSON.parse(draft.draftObject), 'data', (data) => {
                    return this.mapObject(data, 'bodyModel', (body) => {

                        return body.replace(/(\r\n|\n|\r|\t)/gm, " ").replace(/\\"/g, '"');
                    });
                });
                this.mailComposeDraft = this.mapObject(draftObject.data, 'id', () => {
                    return draft.id
                });
                this.task.structure.draftMails.push(draft);
                this.iReset = ++this.iReset
            },

            openDraftMailCompose(draft) {
                this.$refs.createMail.init(0, draft.draftFile, 'draft', true, this.task, this.selectedStep.stepID, draft)
            },

            newCompose(type, mailSrc, mailBody, draftMessage, draftObject) {

                this.mailComposeType = type;
                this.mailComposeMailSrc = mailSrc;
                this.mailComposeMailSrcBody = mailBody;
                this.mailComposeStartWithDraft = draftMessage;
                this.mailComposeDraft = draftObject;
                this.mailComposeStepID = this.selectedStep.stepID;
                this.showCompose = true;
            },

            newCreateMail() {
                this.$refs.createMail.init(0, null, null, true, this.task, this.selectedStep.stepID)
            },

            addMail() {
                eventBus.$emit('taskComposeMail', {task: this.task, step: this.selectedStep})
            },
            openDraftMail(draft) {

                eventBus.$emit('taskComposeMailDraft', {task: this.task, step: this.selectedStep, draft: draft})
            }
        },
        computed: {

            draftMails() {
                if (this.iReset > -1) {
                    return this.task.structure.draftMails;
                } else {
                    return this.task.structure.draftMails;
                }
            },

            taskMails() {
                let tMails = [];
                if (this.iReset > -1) {
                    this.task.structure.mails.forEach(mail => {
                        if (mail.stepID == this.selectedStep.stepID) {
                            let mTemp = mail;
                            try {
                                mTemp.attachments = JSON.parse(mTemp.attachments);
                            } catch (e) {
                                // console.log(e, mTemp.attachments)
                            }

                            tMails.push(mTemp);
                        }
                    });

                    if (tMails != []) {
                        tMails.sort((a,b) => new Date(a['mailDate']) > new Date(b['mailDate']) ? -1 : 1);
                    }
                }

                return tMails;
            }
        }

    }
</script>

<style scoped>

</style>
