<template>
    <v-dialog
            v-model="valuePassOver"
            persistent
            :width="900"
    >
        <v-card class="scrollable" style="height: 500px; overflow-y: auto; overflow-x: hidden">
            <v-layout column fill-height class="scrollable">
                <!--Title-->
                <v-flex shrink class="primary">
                    <v-layout row align-center>
                        <v-flex xs12>
                            <v-layout row align-center>
                                <v-flex shrink>
                                    <v-icon class="pl-2" color="white">verified_user</v-icon>
                                </v-flex>
                                <v-flex xs12 class="font-weight-regular white--text px-2 py-1 title">
                                  MFA Security Settings
                                </v-flex>
                            </v-layout>
                        </v-flex>
                        <v-flex shrink class="pl-2 pr-1">
                            <v-btn
                                    icon
                                    flat
                                    color="white"
                                    class="pa-0 ma-0"
                                    @click="valuePassOver = !valuePassOver"
                            >
                                <v-icon>close</v-icon>
                            </v-btn>
                        </v-flex>
                    </v-layout>
                </v-flex>
                <!--Body-->
                <v-flex xs12 class="scrollable">
                    <v-layout column fill-height align-center justify-center v-if="loading">
                        <v-flex shrink class="text-xs-center">
                            <v-progress-circular
                                    :size="40"
                                    :width="3"
                                    color="primary"
                                    indeterminate
                            ></v-progress-circular>
                        </v-flex>

                    </v-layout>
                    <v-layout row class="scrollable" fill-height v-else>
                        <v-flex xs5>

                            <v-layout column fill-height>
                                <v-flex shrink class="pa-2 primary--text body-2">
                                    Options
                                </v-flex>
                                <v-divider></v-divider>
                                <v-flex xs12 class="px-2">
                                    <v-form
                                            ref="modifyTwoFactorForm"
                                            @submit.prevent=""
                                            class="fill-height"
                                    >
                                        <v-switch
                                                v-model="editConfig"
                                                label="MFA Security Enabled"
                                                color="primary"
                                                @change="changesMade = true"
                                                prepend-icon="verified_user"
                                        />
                                    </v-form>
                                </v-flex>
                                <v-flex shrink class="pa-2 text-xs-right">
                                    <v-btn
                                            color="secondary"
                                            fab
                                            class="pa-0 ma-0"
                                            :disabled="!changesMade || savingChanges"
                                            :loading="savingChanges"
                                            @click="verifyChanges"
                                    >
                                        <v-icon>save</v-icon>
                                    </v-btn>
                                </v-flex>
                            </v-layout>
                        </v-flex>
                        <v-divider :vertical="true"></v-divider>
                        <v-flex xs7>
                            <v-layout column fill-height>
                                <v-flex shrink class="pa-2 primary--text body-2">
                                    Devices
                                </v-flex>
                                <v-divider></v-divider>
                                <v-flex xs12 class="scrollable">
                                    <div class="hover-item" v-for="device in devices" :key="device.deviceID">
                                        <two-factor-device :device="device" @revoke="removeDevice($event)"/>
                                        <v-divider></v-divider>
                                    </div>
                                </v-flex>
                            </v-layout>
                        </v-flex>
                    </v-layout>
                </v-flex>
            </v-layout>
        </v-card>

    </v-dialog>
</template>

<script>
import {mapGetters} from 'vuex'
import {validationRules} from "@/codeFunctions";
import TwoFactorConfirm from '@/views/twoFactor'
import userInfo from "@/Store/userInfo/userInfo";
import TwoFactorDevice from "@/components/Two Factor/TwoFactorDevice";

export default {
    components: {
        TwoFactorDevice,
        TwoFactorConfirm
    },
    props: {
        value: Boolean,
        userID: Number,
        admin: {
            default: false,
            type: Boolean
        }
    },
    data() {
        return {
            config: false,
            editConfig: null,
            devices: [],
            loading: false,
            changesMade: false,
            validationRules: validationRules,
            showConfirmTwoFactor: false,
            twoFactorConfirmConfig: null,
            savingChanges: false
        }
    },
    watch: {
        value: {
            immediate: true,
            handler(val) {
                if (val) {
                    this.startup()
                }
            }
        },
        config() {
            this.changesMade = false
        }
    },
    methods: {

        removeDevice(device) {

            device.loading = true;
            this.$newReq('DELETE', `users/twoFactor/revokeDevice/${this.userInfo.entityID}/${device.device.deviceID}`)
                .then(() => {
                    this.devices = this.devices.filter(dev => dev.deviceID !== device.device.deviceID)
                    this.$snack.info('Device Removed');
                })
                .catch(e => {
                    console.log(e)
                    this.$snack.networkError()
                })
                .finally(() => {
                    device.loading = false;
                })

        },

        async verifyChanges() {
          try {
            this.savingChanges = true
            const res = await this.$newReq('POST', `users/twoFactor/set/enabled/toggle/${this.userID}/${this.editConfig ? '1' : '0'}`)
            
            this.userInfo.mfaEnabled = !!this.editConfig
            
            this.config = this.editConfig
          } catch (e) {
            console.log(e)
            this.$snack.networkError()
          } finally {
            this.savingChanges = false
          }
        },
      
      //TODO RESUME HERE ADD RESET BUTTON

        saveChanges() {
            console.log('Save Changes')

            this.$newReq('PATCH', `users/twoFactor/${this.userInfo.entityID}/${this.userID}/${this.userInfo.userID}`, this.editConfig)
                .then((data) => {
                    console.log('Save Result')
                    this.config = JSON.parse(JSON.stringify(this.editConfig))
                    this.$snack.info('Changes saved')
                })
                .catch(e => {
                    console.log(e);
                    this.$snack.networkError();
                })
                .finally(() => {this.savingChanges = false})

        },

        startup() {
            this.loading = true;
            this.$newReq('GET',`users/twoFactor/${this.userInfo.entityID}/${this.userID}`)
                .then(data => {
                    this.config = data.config;
                    this.editConfig = data.config;
                    this.devices = data.devices
                })
                .catch(e => {
                    console.log(e)
                    this.$snack.networkError()
                })
                .finally(() => {
                    this.loading = false
                })
        }
    },
    computed: {
        ...mapGetters('userInfo', ['userInfo']),
        valuePassOver: {
            get() {
                return this.value
            },
            set(val) {
                this.$emit('input', val)
            }
        },
    },
}
</script>

<style scoped>

</style>