<template>
    <div>
        <div class="pa-2 text-xs-center" v-if="loadingCalendarShare">
            <v-progress-circular
                :size="20"
                :width="3"
                color="primary"
                indeterminate
            />
        </div>
        <div v-else>
            <v-layout row align-center >
                <v-flex xs2 class="body-1 greyType--text pr-2 pl-2">Add Calendar Share:</v-flex>
                <v-flex xs6 class="pl-2">
                    <v-autocomplete
                        color="primary"
                        label="Add Calendar Share"
                        :items="addUsersAndUserGroups"
                        item-text="description"
                        item-value="key"
                        return-object
                        flat
                        hide-details
                        dense
                        single-line
                        multiple
                        hide-selected
                        class="pa-0 ma-0"
                        v-model="addItems"
                        no-data-text="No Available Items To Add"
                        :loading="updatingShares"
                        :disabled="updatingShares"
                    >
                        <template slot="selection" slot-scope="data">
                            <div class="pa-1">
                                <v-card hover class="px-1 teal lighten-5">
                                    <v-layout row align-center>
                                        <v-flex shrink class="pr-1">
                                            <v-icon small class="primary--text">{{data.item.type === 'user' ? 'person' : 'people'}}</v-icon>
                                        </v-flex>
                                        <v-flex shrink class="text-truncate" style="white-space: nowrap">
                                            {{data.item.description}}
                                        </v-flex>
                                        <v-flex shrink class="pl-1">
                                            <v-btn
                                                small
                                                flat
                                                icon
                                                color="red"
                                                class="pa-0 ma-0"
                                                @click="addItems = addItems.filter(item => item.type !== data.item.type ? true : item.id !== data.item.id)"
                                            >
                                                <v-icon small>close</v-icon>
                                            </v-btn>
                                        </v-flex>

                                    </v-layout>
                                </v-card>
                            </div>
                        </template>

                        <template v-slot:item="data">
                            <v-layout row align-center>
                                <v-flex shrink>
                                    <v-icon
                                        cursor="pointer"
                                        color="primary"
                                        small
                                        left
                                    >
                                        {{ data.item.type === 'user' ? 'person' : 'people' }}
                                    </v-icon>
                                </v-flex>
                                <v-flex xs12>{{ data.item.description }}</v-flex>
                            </v-layout>
                        </template>

                    </v-autocomplete>
                </v-flex>
                <v-flex shrink class="pl-2">
                    <v-btn
                        small
                        color="secondary"
                        class="py-0 px-2 ma-0"
                        :disabled="updatingShares || addItems == null || addItems.length <= 0"
                        :lodaing="updatingShares"
                        @click="addShareItems()"
                    >
                        Share
                    </v-btn>
                </v-flex>
            </v-layout>

            <v-layout row align-center v-if="addedUsersAndUserGroups.length > 0" class="pt-2">
                <v-flex xs2 class="px-2 body-1 greyType--text" style="white-space: nowrap">
                    Added Shares:
                </v-flex>
                <v-flex xs10>
                    <v-layout row wrap style="overflow-x: hidden" class="pa-1">
                        <v-flex
                            v-for="(item, i) in addedUsersAndUserGroups"
                            lg3 xs4
                            :key="item.key"
                            class="pa-1"
                            style="overflow-x: hidden"
                        >
                            <calendar-share-item
                                :share="item"
                                :ref="`shareContainer`"
                                @remove="removeShare(item, i)"
                            />
                        </v-flex>
                    </v-layout>
                </v-flex>
            </v-layout>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {codeBaseMixin} from "@/codeBaseMixin";
import {arrPopulated} from "@/codeFunctions";
import CalendarShareItem from "@/Calendar View/CalendarShare/CalendarShareItem";

export default {
    components: {CalendarShareItem},
    mixins: [codeBaseMixin],
    props: {
        user: Number,
        localData: Object
    },
    data() {
        return {
            shareUserGroups: [],
            shareUsers: [],
            loadingCalendarShare: false,
            updatingShares: false,
            addItems: []
        }
    },
    watch: {
        user: {
            immediate: true,
            handler(val) {
                if(val != null) {
                    this.getDashShareData()
                }
            }
        }
    },
    methods: {
        removeShare(share, index) {
            this.$refs.shareContainer[index].loading = true;

            let shareItems = {
                shareUsers: share.type !== 'user' ? this.shareUsers : this.shareUsers.filter(user => user !== share.id),
                shareUserGroups: share.type !== 'group' ? this.shareUserGroups : this.shareUserGroups.filter(group => group !== share.id),
            }


            this.updateShare(shareItems)
                .then(() => {
                    this.$refs.shareContainer[index].loading = false;
                })
        },

        resetCalendarShare() {
            this.shareUserGroups = [];
            this.shareUsers = [];
            this.loadingCalendarShare = false;
            this.addingShares = false;
            this.addItems = [];
        },

        getDashShareData() {
            this.resetCalendarShare();
            this.loadingCalendarShare = true
            this.fetch({method: 'GET', url:`/calendar/share/${this.userInfo.entityID}/${this.user}`})
                .then((data) => {
                    this.shareUserGroups = data.shareUserGroups;
                    this.shareUsers = data.shareUsers;
                    this.loadingCalendarShare = false;
                })
                .catch((e) => {
                    // eslint-disable-next-line
                    console.log(e)
                    this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                    this.loadingCalendarShare = false
                })
        },

        addShareItems() {
            let shareItems = {
                shareUsers: this.shareUsers
                    .concat(this.addItems.reduce((list, item) => {
                        return item.type === 'user' ? list.concat(item.id) : list
                    }, [])),
                shareUserGroups: this.shareUserGroups
                    .concat(this.addItems.reduce((list, item) => {
                        return item.type === 'group' ? list.concat(item.id) : list
                    }, [])),
            }

            this.updateShare(shareItems)
        },

        updateShare(data) {
            return new Promise((resolve, reject) => {
                this.updatingShares = true
                this.fetch({method: 'POST', url:`/calendar/share/${this.userInfo.entityID}/${this.user}`, body: data})
                    .then(() => {
                        this.addItems = [];
                        this.shareUserGroups = data.shareUserGroups;
                        this.shareUsers = data.shareUsers;
                        this.updatingShares = false;
                        resolve(true);
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e)
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                        this.updatingShares = false
                        reject(false)
                    })
            })
        }
    },
    computed: {
        ...mapGetters('userInfo', ['userInfo']),

        addedUsersAndUserGroups() {
            let tUsers = this.localData.users
                .filter(user => this.shareUsers.includes(user.userID) && user.userID !== this.user)
                .map((user) => {
                    return {
                        type: 'user',
                        description: `${user.name} ${user.surname}`,
                        id: user.userID,
                        key: 'u' + user.userID
                    }
                })
            let tUserGroups = this.localData.userGroups
                .filter(userGroup => this.shareUserGroups.includes(userGroup.id))
                .map((userGroup) => {
                    return {
                        type: 'group',
                        description: userGroup.description,
                        id: userGroup.id,
                        key: 'g' + userGroup.id
                    }
                })
            return tUsers.concat(tUserGroups).sort((a,b) => a['description'] < b['description'] ? -1 : 1);
        },

        addUsersAndUserGroups() {
            let tUsers = this.localData.users
                .filter(user => !this.shareUsers.includes(user.userID) && user.userID !== this.user && user.disabled === 0)
                .map((user) => {
                    return {
                        type: 'user',
                        description: `${user.name} ${user.surname}`,
                        id: user.userID,
                        key: 'u' + user.userID
                    }
                })
            let tUserGroups = this.localData.userGroups
                .filter(userGroup => !this.shareUserGroups.includes(userGroup.id))
                .map((userGroup) => {
                    return {
                        type: 'group',
                        description: userGroup.description,
                        id: userGroup.id,
                        key: 'g' + userGroup.id
                    }
                })
            return tUsers.concat(tUserGroups).sort((a,b) => a['description'] < b['description'] ? -1 : 1);
        }
    }
}
</script>

<style scoped>

</style>