<template>
    <div>
        <div @click="showAdd = !showAdd">
            <slot name="activator"></slot>
        </div>

        <app-input-dlg
                :show-dialog="showAdd"
                title="Add Existing Users"
                :cancel-button="true"
                :show-input-box="false"
                @dismiss="showAdd = false"
                :body-padding="false"
        >
            <template slot="body">
                <div class="pt-1">
                    <div :class="topicClass" class="px-1">
                        <div :class="headingClass">
                            Select Users:
                        </div>
                    </div>
                    <div style="overflow-y: auto">
                        <multi-select-list
                                :show-action-buttons="false"
                                :show-check-boxes="true"
                                :multiple="true"
                                headline-key="description"
                                item-key="id"
                                :return-object="false"
                                v-model="selectedUsers"
                                :show-filter="true"
                                :items="users"
                                :height="400"
                                filter-label="Filter Users"
                        />
                    </div>
                </div>
            </template>
            <template slot="actions">
                <div class="text-xs-right">
                    <v-btn
                            small
                            color="secondary"
                            :disabled="!Array.isArray(selectedUsers) || selectedUsers.length <= 0 || addingUserGroup"
                            :loading="addingUserGroup"
                            @click="addUserGroup"
                    >
                        {{`Add User${selectedUsers != null && selectedUsers.length > 1 ? 's' : ''}`}}
                    </v-btn>
                </div>
            </template>
        </app-input-dlg>
    </div>
</template>

<script>
    import {codeBaseMixin} from "../../../codeBaseMixin";
    import InputDlg from "../../../components/General/InputDlg";
    import MultiSelectList from "../../../components/Multi Select List/MultiSelectList";
    import {blankString} from "../../../codeFunctions";
    import {feed} from "../../../feedSocket";

    export default {
        components: {
            MultiSelectList,
            appInputDlg: InputDlg
        },
        mixins: [codeBaseMixin],
        props: {
            userInfo: Object,
            selectedUserGroup: Number,
            users: Array
        },
        data() {
            return {
                showAdd: false,
                addGroupDescription: null,
                selectedUsers: [],
                addingUserGroup: false,
            }
        },
        watch: {
            showAdd(shown) {
                if (shown) {
                    this.addGroupDescription = null;
                    this.selectedUsers = null;
                    this.addingUserGroup = false;
                }
            },
        },
        methods: {
            addUserGroup() {
                this.addingUserGroup = true;
                let userGroupChanges = [];

                feed.on('userGroupChange', (data) => {userGroupChanges.push(data)});

                let vm = this;

                function waitForAddedUserGroup(reUserGroup, waitCycle = 0) {
                    return new Promise ((resolve, reject) => {
                        if (waitCycle > 20000) {
                            reject('Timeout')
                        } else {
                            if (userGroupChanges.map(obj => {return obj.id}).includes(reUserGroup)) {
                                vm.$nextTick(() => {
                                    resolve(reUserGroup)
                                })
                            } else {
                                setTimeout(() => {
                                    resolve(waitForAddedUserGroup(reUserGroup, waitCycle + 100))
                                }, 100)
                            }
                        }
                    })
                }

                this.fetch({method: 'PATCH', url:`/folderManager/security/fgm/userGroup/users/${this.userInfo.entityID}/${this.selectedUserGroup}`, body: this.selectedUsers})
                    .then(async () => {
                        try {
                            this.showAdd = false;
                            this.addingUserGroup = false;
                            await waitForAddedUserGroup(this.selectedUserGroup);

                            this.$emit('usersAdded', this.selectedUsers);
                            this.showSnack('Info', 'Users Added', 'Close', 'primary');
                        } catch (e) {
                            // eslint-disable-next-line
                            this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
                            this.addingUserGroup = false;
                            console.log(e)
                        }
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e);
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
                        this.addingUserGroup = false;
                    })
            }
        },

    }
</script>

<style scoped>

</style>
