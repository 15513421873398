<template>
    <v-dialog
            v-model="showDialog"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
            style="overflow-y: auto"
    >
        <v-card flat class="fill-height" style="overflow-y: auto" v-if="showDialog">

            <v-layout column fill-height style="overflow-y: auto">
                <v-flex shrink >
<!--Card Title-->
                    <v-card-title class="py-2 pr-2 primary">
                        <!--<v-icon-->
                                <!--left-->
                                <!--color="white"-->
                        <!--&gt;-->
                            <!--list-->
                        <!--</v-icon>-->
                        <img src="../../../public/datasets.svg" width="42px" class="pr-2"/>
                        <span class="title font-weight-light white--text">Dataset Manager</span>
                        <v-spacer></v-spacer>
                        <v-btn flat icon color="white" class="pa-0 ma-0" @click="showDialog = false">
                            <v-icon>close</v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-divider></v-divider>
                </v-flex>
                <v-flex xs12 style="overflow-y: auto">
                    <v-layout row fill-height class="pt-1" >
<!--Field Sets-->
                        <v-flex xs4 class="px-1" style="overflow-y: auto">
                            <v-layout column fill-height style="overflow-y: auto">
                                <v-flex v-if="loadingFieldSets" xs12 style="overflow-y: auto" class="scroll text-xs-center pt-2">
                                    <v-progress-circular
                                            :size="40"
                                            :width="5"
                                            color="primary"
                                            indeterminate
                                    ></v-progress-circular>
                                </v-flex>
                                <v-flex v-else shrink class="pb-2" >
                                    <v-text-field
                                            label="Add New Dataset"
                                            single-line
                                            v-model="addFieldSetDescription"
                                            color="primary"
                                            append-outer-icon="add"
                                            hide-details
                                            style="width: 100%"
                                            @click:append-outer="addNewFieldSet"
                                            class="ma-0 pa-0 pt-2"
                                            :loading="addingFieldSet"
                                            :disabled="addingFieldSet"
                                            @keydown.enter="addNewFieldSet"
                                    ></v-text-field>
                                </v-flex>
                                <v-flex v-if="!loadingFieldSets" xs12 style="overflow-y: auto" class="scroll">
                                    <v-list>
                                        <v-list-tile
                                                v-for="set in fieldSets"
                                                :key="'FieldSet' + set.id"
                                                dense
                                                @click="selectFieldSet(set)"
                                                ripple
                                                :class="selectedSet.id == set.id ? 'selectedFieldSet' : ''"
                                        >
                                            <v-list-tile-action v-if="!set.inEdit">
                                                <v-tooltip top>
                                                    <v-btn @click="set.inEdit = true" slot="activator" flat icon color="secondary">
                                                        <v-icon>edit</v-icon>
                                                    </v-btn>
                                                    <span>Edit Dataset Description</span>
                                                </v-tooltip>
                                            </v-list-tile-action>
                                            <v-list-tile-content>
                                                <v-list-tile-title v-if="!set.inEdit">{{ set.description }}</v-list-tile-title>
                                                <template v-if="set.inEdit">
                                                    <v-text-field
                                                            label="Dataset Title"
                                                            single-line
                                                            v-model="set.description"
                                                            color="primary"
                                                            append-outer-icon="save"
                                                            hide-details
                                                            style="width: 100%"
                                                            @click:append-outer="editFieldSetName(set)"

                                                    ></v-text-field>
                                                </template>
                                            </v-list-tile-content>

                                            <v-list-tile-action v-if="!set.inEdit" class="ma-0 pa-0">
                                                <v-tooltip top>
                                                    <v-btn
                                                            @click="editFieldSetMultiple(set)"
                                                            slot="activator"
                                                            flat
                                                            icon
                                                            color="primary"
                                                            class="ma-0 pa-0"
                                                    >
                                                        <v-icon>{{ intToBool(set.allowMultiple) ? 'file_copy' : 'insert_drive_file' }}</v-icon>
                                                    </v-btn>
                                                    <span>{{ intToBool(set.allowMultiple) ? 'Set To Single Instance Only' : 'Set To Multiple Instances' }}</span>
                                                </v-tooltip>
                                            </v-list-tile-action>

                                            <v-list-tile-action v-if="!set.inEdit">
                                                <v-tooltip top>
                                                    <v-btn @click="editFieldSetVisibility(set)" slot="activator" flat icon color="secondary">
                                                        <v-icon>{{ intToBool(set.visible) ? 'check_box' : 'check_box_outline_blank' }}</v-icon>
                                                    </v-btn>
                                                    <span>{{ intToBool(set.visible) ? 'Disable Dataset' : 'Enable Dataset' }}</span>
                                                </v-tooltip>
                                            </v-list-tile-action>
                                        </v-list-tile>
                                    </v-list>
                                </v-flex>
                            </v-layout>
                        </v-flex>

                        <v-flex xs8 v-if="selectedSet.id != 0" style="overflow-y: auto" class="pr-1">
                            <v-card class="fill-height" style="overflow-y: auto">
                                <v-layout column fill-height style="overflow-y: auto">
                                    <!--Card Title-->
                                    <v-flex shrink>
                                        <v-card-title class="py-2 pr-2 primary">
                                            <v-layout row justify-space-between align-center wrap>
                                                <v-flex shrink>
                                                    <span class="title font-weight-light white--text">{{ selectedSet.description }}</span>
                                                </v-flex>
                                            </v-layout>
                                        </v-card-title>
                                    </v-flex>
                                    <!--Tabs-->
                                    <v-flex shrink>
                                        <v-tabs
                                                v-model="model"
                                                color="primary"
                                                slider-color="secondary"
                                                grow
                                        >
                                            <v-tab class="white--text">Fields</v-tab>
                                            <v-tab class="white--text">Security</v-tab>
                                            <v-tab class="white--text">Actions</v-tab>
                                        </v-tabs>
                                    </v-flex>
<!--Detail View-->

<!--Field Set Fields-->
                                    <v-flex xs12 v-if="model == 0" class="fill-height px-1" style="overflow-y: auto">

                                            <v-layout column fill-height style="overflow-y: auto">

<!--Add New Field Set Field-->
                                                <v-flex v-if="!loadingFieldSets && this.selectedSet.id != 0" shrink class="pb-2">
                                                    <app-field-set-field-admin-add
                                                            :user-info="userInfo"
                                                            :selected-set="selectedSet"
                                                            @fieldadded="pushNewField"
                                                    />
                                                    <v-divider class="grey"></v-divider>
                                                </v-flex>

                                                <v-flex v-if="!loadingFieldSets && this.selectedSet.id != 0" xs12 style="overflow-y: auto" class="scroll pa-1">
                                                    <template v-for="(setField, index) in orderedFields">
                                                        <v-layout row :key="'FieldSetField' + setField.id" align-center>
                                                            <v-flex xs12>
                                                                <app-field-set-field-admin
                                                                        :field="setField"
                                                                        :user-info="userInfo"
                                                                />
                                                            </v-flex>
                                                            <v-flex shrink>
                                                                <v-layout column fill-height justify-space-between>
                                                                    <v-flex shrink v-if="index != 0">
                                                                        <v-tooltip top>
                                                                            <v-btn
                                                                                    slot="activator"
                                                                                    small
                                                                                    icon
                                                                                    flat
                                                                                    color="primary"
                                                                                    class="pa-0 ma-0"
                                                                                    @click="moveUp(index)"
                                                                            >
                                                                                <v-icon>keyboard_arrow_up</v-icon>
                                                                            </v-btn>
                                                                            <span>Move Up</span>
                                                                        </v-tooltip>
                                                                    </v-flex>
                                                                    <v-flex shrink v-if="index != orderedFields.length - 1">
                                                                        <v-tooltip top>
                                                                            <v-btn
                                                                                    slot="activator"
                                                                                    small
                                                                                    icon
                                                                                    flat
                                                                                    color="primary"
                                                                                    class="pa-0 ma-0"
                                                                                    @click="moveDown(index)"
                                                                            >
                                                                                <v-icon>keyboard_arrow_down</v-icon>
                                                                            </v-btn>
                                                                            <span>Move Down</span>
                                                                        </v-tooltip>
                                                                    </v-flex>
                                                                </v-layout>
                                                            </v-flex>
                                                        </v-layout>
                                                    </template>
                                                </v-flex>
                                            </v-layout>
                                    </v-flex>
<!--User Groups-->
                                    <v-flex xs12 style="overflow-y: auto" class="fill-height px-1" v-if="model == 1">
                                        <v-layout column fill-height style="overflow-y: auto">

                                            <!--Add New User Groups-->
                                            <v-flex v-if="!loadingFieldSets && this.selectedSet.id != 0" shrink class="pb-2">
                                                <v-autocomplete
                                                        append-outer-icon="add"
                                                        color="primary"
                                                        label="Add User Group"
                                                        :items="availableGroups"
                                                        item-text="description"
                                                        item-value="id"
                                                        flat
                                                        v-model="addGroup"
                                                        hide-details
                                                        class="pa-0 ma-0 pt-2"
                                                        dense
                                                        @click:append-outer="addNewGroup"
                                                        :loading="addingGroup"
                                                        :disabled="addingGroup"
                                                        single-line
                                                >
                                                </v-autocomplete>
                                            </v-flex>

                                            <v-flex v-if="!loadingFieldSets && this.selectedSet.id != 0" xs12 style="overflow-y: auto" class="scroll pa-1">
                                                <v-list>
                                                    <v-list-tile
                                                            v-for="(userGroup) in orderedGroups"
                                                            :key="'GroupDBID' + userGroup.id"
                                                            dense
                                                            @click="() => {}"
                                                    >
                                                        <v-list-tile-content>
                                                            <v-list-tile-title>{{ userGroup.description }}</v-list-tile-title>
                                                        </v-list-tile-content>
                                                        <!--Read Only-->
                                                        <v-list-tile-action class="ma-0 pa-0">
                                                            <!--{{userGroup.readOnly}}-->
                                                            <v-tooltip top>
                                                                <v-btn
                                                                        @click="userGroup.readOnly = boolToInt(!intToBool(userGroup.readOnly)); updateReadOnlyForGroup(userGroup)"
                                                                        slot="activator"
                                                                        flat
                                                                        icon
                                                                        color="primary"
                                                                        class="ma-0 pa-0"
                                                                >
                                                                    <v-icon>{{ intToBool(userGroup.readOnly) ? 'check_box' : 'check_box_outline_blank' }}</v-icon>
                                                                </v-btn>
                                                                <span>Read Only</span>
                                                            </v-tooltip>
                                                        </v-list-tile-action>
                                                        <!--Remove User Group-->
                                                        <v-list-tile-action class="ma-0 pa-0">
                                                            <v-tooltip top>
                                                                <v-btn
                                                                        @click="removeGroup(userGroup)"
                                                                        slot="activator"
                                                                        flat
                                                                        icon
                                                                        color="red"
                                                                        class="ma-0 pa-0"
                                                                >
                                                                    <v-icon>close</v-icon>
                                                                </v-btn>
                                                                <span>Remove User Group Access</span>
                                                            </v-tooltip>
                                                        </v-list-tile-action>
                                                    </v-list-tile>
                                                </v-list>
                                            </v-flex>
                                        </v-layout>
                                    </v-flex>
<!--Dataset Actions-->
                                    <v-flex xs12 style="overflow-y: auto" class="fill-height px-1" v-if="model == 2">
                                        <app-field-set-admin-rules-detail
                                            :userInfo="userInfo"
                                            :selectedSet="selectedSet"
                                        />
                                    </v-flex>
                                </v-layout>
                            </v-card>
                        </v-flex>

                    </v-layout>
                </v-flex>
            </v-layout>

        </v-card>

    </v-dialog>
</template>

<script>
    import { eventBus} from "../../main";
    import {codeBaseMixin} from "../../codeBaseMixin";
    import FieldSetFieldAdmin from "./components/FieldSetFieldAdmin.vue";
    import FieldSetFieldAdminAdd from "./components/FieldSetFieldAdminAdd";
    import FieldSetAdminRulesDetail from "./components/FieldSetAdminRulesDetail";

    export default {
        mixins: [codeBaseMixin],
        components: {
            appFieldSetAdminRulesDetail: FieldSetAdminRulesDetail,
            appFieldSetFieldAdmin: FieldSetFieldAdmin,
            appFieldSetFieldAdminAdd: FieldSetFieldAdminAdd,
        },
        props: {
            userInfo: Object
        },
        data() {
            return {
                model: 0,
                showDialog: false,
                fieldSets: [{
                    id: 0,
                    description: '',
                    visible: 0
                }],
                loadingFieldSets: true,
                selectedSet: {
                    id: 0,
                    description: '',
                    visible: 0
                },
                addFieldSetDescription: '',
                addingFieldSet: false,
                fieldSetFields: [],
                userGroups: [],
                allGroups: [],
                addGroup: null,
                addingGroup: false,
            }
        },
        created() {
            eventBus.$on('showFieldSetEditor', this.showFieldSetEditor)
        },
        beforeDestroy() {
            eventBus.$off('showFieldSetEditor', this.showFieldSetEditor)
        },
        methods: {
            showFieldSetEditor() {
                this.showDialog = true;
                this.initFSM();
            },

            updateReadOnlyForGroup(userGroup) {
                this.fetch({method: 'PATCH', url: `/fieldsets/admin/readOnly/${this.userInfo.entityID}/${userGroup.id}/${userGroup.readOnly}`})
                    .then((data) => {
                        this.showSnack('Info', 'Changes Saved', 'Close', 'primary')
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e)
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                    })
            },

            removeGroup(userGroup) {
                this.fetch({
                    method: 'GET',
                    url: '/fieldsets/admin/removeUserGroup/' + this.userInfo.entityID + '/' + userGroup.id,
                })
                    .then(() => {
                        this.showSnack('Info', 'User Group Removed Successfully', 'Close', 'primary')
                    })
                    .catch(e => {
                        // eslint-disable-next-line
                        console.log(e)
                    });
                this.userGroups.forEach((group, i) => {
                    if (group.id == userGroup.id) {
                        this.userGroups.splice(i, 1)
                    }
                })
            },

            addNewGroup() {
                if ( this.addGroup != null && this.addGroup != 0) {
                    this.addingGroup = true;
                    this.fetch({
                        method: 'POST',
                        url: '/fieldsets/admin/addUserGroup/' + this.userInfo.entityID,
                        body: JSON.stringify({userGroup: this.addGroup, fieldSet: this.selectedSet.id})
                    })
                        .then((data) => {
                            data.readOnly = 0;
                            this.userGroups.push(data);
                            this.addGroup = null;
                            this.addingGroup = false;
                            this.showSnack('Info', 'Group Added Successfully', 'Close', 'primary')
                        })
                        .catch(e => {
                            // eslint-disable-next-line
                            console.log(e)
                        });
                } else {
                    this.showSnack('Error', 'Please Select a Group To Add', 'Close', 'red')
                }
            },

            moveUp(index) {
                let moveID = this.orderedFields[index].id;
                let moveValue = this.orderedFields[index].order;

                let swapID = this.orderedFields[index - 1].id;
                let swapValue = this.orderedFields[index - 1].order;

                let tValue = moveValue;
                moveValue = swapValue;
                swapValue = tValue;

                // Update Values
                this.fieldSetFields.forEach((field) => {
                    if (field.id == moveID) {
                        field.order = moveValue
                    }
                    if (field.id == swapID) {
                        field.order = swapValue
                    }
                });


                this.fetch({method: 'PATCH', url: '/fieldSets/admin/UpdateOrder/' + this.userInfo.entityID + '/' + moveID + '/' + moveValue + '/' + swapID + '/' + swapValue})
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e)
                    })


            },

            moveDown(index) {
                let moveID = this.orderedFields[index].id;
                let moveValue = this.orderedFields[index].order;

                let swapID = this.orderedFields[index + 1].id;
                let swapValue = this.orderedFields[index + 1].order;

                let tValue = moveValue;
                moveValue = swapValue;
                swapValue = tValue;

                // Update Values
                this.fieldSetFields.forEach((field) => {
                    if (field.id == moveID) {
                        field.order = moveValue
                    }
                    if (field.id == swapID) {
                        field.order = swapValue
                    }
                });


                this.fetch({method: 'PATCH', url: '/fieldSets/admin/UpdateOrder/' + this.userInfo.entityID + '/' + moveID + '/' + moveValue + '/' + swapID + '/' + swapValue})
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e)
                    })


            },

            pushNewField(field) {
                this.fieldSetFields.push(field);
            },

            addNewFieldSet() {
                this.addingFieldSet = true;
                this.fetch({
                    method: 'POST',
                    url: '/fieldsets/admin/addFieldSet/' + this.userInfo.entityID,
                    body: JSON.stringify({description: this.addFieldSetDescription})
                })
                    .then((data) => {
                        this.fieldSets.push({
                            id: data.fieldSetID,
                            description: this.addFieldSetDescription,
                            visible: 1,
                            inEdit: false,
                            allowMultiple: 0
                        });
                        this.selectFieldSet(this.fieldSets[this.fieldSets.length - 1]);
                        this.addFieldSetDescription = '';
                        this.addingFieldSet = false;
                    })
                    .catch(e => {
                        // eslint-disable-next-line
                        console.log(e)
                    });
            },

            selectFieldSet(set) {
                this.selectedSet = set;
            },

            editFieldSetName(set) {
                if (set.description.length > 0) {
                    this.fetch({method: 'PATCH', url:'/fieldsets/admin/editfieldset/' + this.userInfo.entityID + '/' + this.userInfo.userID, body: JSON.stringify(set)})
                        .catch(e => {
                            // eslint-disable-next-line
                            console.log(e)
                        });
                    set.inEdit = false
                } else {
                    this.showSnack('Error', 'Dataset Description Is Required', 'Close', 'red')
                }
            },

            editFieldSetVisibility(set) {
                set.visible = this.boolToInt(!this.intToBool(set.visible));
                this.fetch({
                    method: 'PATCH',
                    url: '/fieldsets/admin/editfieldsetVisibility/' + this.userInfo.entityID + '/' + this.userInfo.userID,
                    body: JSON.stringify(set)
                })
                    .catch(e => {
                        // eslint-disable-next-line
                        console.log(e)
                    });

            },

            editFieldSetMultiple(set) {
                set.allowMultiple = this.boolToInt(!this.intToBool(set.allowMultiple));
                this.fetch({
                    method: 'PATCH',
                    url: '/fieldsets/admin/editfieldsetMultiple/' + this.userInfo.entityID + '/' + this.userInfo.userID,
                    body: JSON.stringify(set)
                })
                    .catch(e => {
                        // eslint-disable-next-line
                        console.log(e)
                    });

            },

            initFSM() {
                this.loadingFieldSets = true;
                if (typeof this.userInfo.entityID != 'undefined') {
                    // Load Field Sets
                    this.fetch({method: 'GET', url: '/fieldsets/admin/' + this.userInfo.entityID})
                        .then((data) => {
                            let tFS = data.fieldSets;
                            tFS.forEach(FS => {FS.inEdit = false;});
                            this.fieldSets = tFS;
                            this.fieldSetFields = data.fieldSetFields;
                            this.userGroups = data.userGroups;
                            this.allGroups = data.allGroups;
                            this.loadingFieldSets = false;
                        })
                        .catch(e => {
                            // eslint-disable-next-line
                            console.log(e)
                        })
                } else {
                    setTimeout(() => {
                        this.initFSM()
                    },500)
                }
            }
        },

        computed: {
            orderedFields() {
                let tFields = [];
                if (this.fieldSetFields != null && this.fieldSetFields != [] && this.selectedSet.id != 0) {
                    this.fieldSetFields.forEach((field) => {
                        if (field.fk_field_set == this.selectedSet.id) {
                            tFields.push(field)
                        }
                    });
                    tFields.sort((a,b) => a['order'] < b['order'] ? -1 : 1);
                }
                return tFields;
            },

            orderedGroups() {
                let tGroups = [];
                if (this.userGroups != null && this.userGroups != [] && this.userGroups.id != 0) {
                    this.userGroups.forEach((group) => {
                        if (group.fieldSet == this.selectedSet.id) {
                            tGroups.push(group)
                        }
                    });
                    tGroups.sort((a,b) => a['description'] < b['description'] ? -1 : 1);
                }
                return tGroups;
            },


            availableGroups() {
                if (this.orderedGroups.length > 0) {
                    let arrReturn = [];
                    this.allGroups.forEach((availGroup) => {
                        let bFound = false;

                        this.userGroups.forEach((group) => {
                            if (group.fieldSet == this.selectedSet.id && availGroup.id == group.userGroupID) {
                                bFound = true;
                            }
                        });
                        if (!bFound) {
                           arrReturn.push(availGroup)
                        }
                    });
                    arrReturn.sort((a,b) => a['description'] < b['description'] ? -1 : 1);
                    return arrReturn;
                } else {
                    let arrReturn = this.allGroups;
                    arrReturn.sort((a,b) => a['description'] < b['description'] ? -1 : 1);
                    return arrReturn;
                }
            },
        }

    }
</script>

<style scoped>
    .selectedFieldSet {
        background: #efefef;
    }

</style>
