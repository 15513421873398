import { render, staticRenderFns } from "./AppContactModify.vue?vue&type=template&id=99075786&scoped=true"
import script from "./AppContactModify.vue?vue&type=script&lang=js"
export * from "./AppContactModify.vue?vue&type=script&lang=js"
import style0 from "./AppContactModify.vue?vue&type=style&index=0&id=99075786&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "99075786",
  null
  
)

export default component.exports