<template>
    <div style="overflow-y: auto; overflow-x: hidden">
        <v-layout
                row
                align-center
                style="overflow-y: auto; overflow-x: hidden; cursor: pointer"
                class="pl-2 hide-parent hover-item"
                
                :style="{background: selected ? 'var(--selected-item)' : null}"
        >
<!--Check Box-->
            <v-flex shrink class="pr-2" v-if="loading || showCheckBoxes">

                <v-progress-circular
                        v-if="loading"
                        :size="20"
                        :width="2"
                        color="grey"
                        indeterminate
                />

                <template v-else>
                    <v-icon
                            v-if="showCheckBoxes"
                            :color="selected ? 'primary' : 'grey'"
                            class="pa-0 ma-0"
                            @click="$emit('selection', !selected)"
                    >
                        {{selected ? 'check_box' : 'check_box_outline_blank'}}
                    </v-icon>
                </template>
            </v-flex>
<!--Main Text-->
            <v-flex
                    xs12
                    style="overflow-y: auto; overflow-x: hidden"
                    :class="subLine ? 'py-1' : 'py-2'"
                    class="pr-2"
                    @click="$emit(showCheckBoxes ? 'select' : 'selection', !selected)"
            >
                <div
                        v-if="headerLine || $slots.headline"
                        class="text-truncate"
                >
                    <template v-if="!$slots.headline">
                        {{headerLine || null}}
                    </template>

                    <slot name="headline"/>
                </div>
                <div v-if="subLine" class="text-truncate caption greyType--text pt-1">
                    {{Array.isArray(subLine) ? subLine.join(', ') : subLine}}
                </div>
            </v-flex>
<!--Action Buttons-->
            <template
                    v-if="actionButtons"
            >
                <template v-for="(btn, i) in actionButtons">
                    <v-flex
                            v-if="btn.hoverItem !== 'selectedOnly' ? true : selected"
                            :key="i"
                            shrink
                            class="pr-2"
                            :class="btn.hoverItem === true || btn.hoverItem === 'selectedOnly' ? 'hide-child' : null"
                    >
                        <v-btn
                                icon
                                flat
                                small
                                :color="typeof btn.color == 'string' ? btn.color : btn.color(item)"
                                class="pa-0 ma-0"
                                @click="btn.cb(item)"
                        >
                            <v-icon>{{typeof btn.icon == 'string' ? btn.icon : btn.icon(item)}}</v-icon>
                        </v-btn>
                    </v-flex>
                </template>

            </template>
            <slot name="action"></slot>

        </v-layout>


        <v-divider></v-divider>
    </div>
</template>

<script>
    import {blankString} from "../../codeFunctions";

    export default {
        props: {
            line1: String,
            line2: String,
            actionButtons: Array,
            showCB: Boolean,
            item: Object,
            loading: Boolean,
            selected: Boolean,
            showCheckBoxes: Boolean,
        },
        computed: {
            headerLine() {
                return blankString(this.item[this.line1]) ? null : this.item[this.line1];
            },
            subLine() {
                return blankString(this.item[this.line2]) ? null : this.item[this.line2];
            }
        }
    }
</script>

<style scoped>

</style>
