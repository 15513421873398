<template>
    <div>
        <div @click="showAdd = !showAdd">
            <slot name="activator"></slot>
        </div>

        <app-input-dlg
                :show-dialog="showAdd"
                title="Add New User Group"
                :cancel-button="true"
                :show-input-box="false"
                @dismiss="showAdd = false"
                :body-padding="false"
        >
            <template slot="body">
                <div class="pt-1">
                    <div :class="topicClass" class="px-1">
                        <div :class="headingClass">
                            User Group Description:
                        </div>
                        <div :class="itemClass">
                            <v-text-field
                                    label="User Group Description"
                                    single-line
                                    color="primary"
                                    hide-details
                                    style="width: 100%"
                                    class="pa-0 ma-0"
                                    ref="UGroupDescription"
                                    v-model="addGroupDescription"
                            />
                        </div>
                    </div>
                    <div :class="topicClass" class="px-1">
                        <div :class="headingClass">
                            Select Users:
                        </div>
                    </div>
                    <div style="overflow-y: auto">
                        <multi-select-list
                                :show-action-buttons="false"
                                :show-check-boxes="true"
                                :multiple="true"
                                headline-key="description"
                                item-key="id"
                                :return-object="false"
                                v-model="selectedUsers"
                                :show-filter="true"
                                :items="simpleUsers"
                                :height="400"
                                filter-label="Filter Users"
                        />
                    </div>
                </div>
            </template>
            <template slot="actions">
                <div class="text-xs-right">
                    <v-btn
                            small
                            color="secondary"
                            :disabled="!Array.isArray(selectedUsers) || selectedUsers.length <= 0 || blankString(addGroupDescription) || addingUserGroup"
                            :loading="addingUserGroup"
                            @click="addUserGroup"
                    >
                        Add User Group
                    </v-btn>
                </div>
            </template>
        </app-input-dlg>
    </div>
</template>

<script>
    import {codeBaseMixin} from "../../../codeBaseMixin";
    import InputDlg from "../../../components/General/InputDlg";
    import MultiSelectList from "../../../components/Multi Select List/MultiSelectList";
    import {blankString} from "../../../codeFunctions";
    import {feed} from "../../../feedSocket";

    export default {
        components: {
            MultiSelectList,
            appInputDlg: InputDlg
        },
        mixins: [codeBaseMixin],
        props: {
            userInfo: Object,
            selectedFolderGroup: Number,
            users: Array,
            userGroups: Array
        },
        data() {
            return {
                showAdd: false,
                addGroupDescription: null,
                selectedUsers: null,
                addingUserGroup: false,
            }
        },
        watch: {
            showAdd(shown) {
                if (shown) {
                    this.addGroupDescription = null;
                    this.selectedUsers = null;
                    this.addingUserGroup = false;
                    this.$nextTick(() => {
                        this.$refs.UGroupDescription.focus();
                    })
                }
            },
        },
        methods: {
            addUserGroup() {
                this.addingUserGroup = true;
                let folderGroupChanges = [];

                feed.on('folderGroupChange', (data) => {folderGroupChanges.push(data)});

                let vm = this;

                function waitForAddedFolderGroup(reFolderGroup, waitCycle = 0) {
                    return new Promise ((resolve, reject) => {
                        if (waitCycle > 20000) {
                            reject('Timeout')
                        } else {
                            if (folderGroupChanges.map(obj => {return obj.id}).includes(reFolderGroup)) {
                                vm.$nextTick(() => {
                                    resolve(reFolderGroup)
                                })
                            } else {
                                setTimeout(() => {
                                    resolve(waitForAddedFolderGroup(reFolderGroup, waitCycle + 100))
                                }, 100)
                            }
                        }
                    })
                }

                let userGroupChanges = [];

                feed.on('userGroupChange', (data) => {
                    userGroupChanges.push(data)
                });

                function waitForAddedUserGroup(reUserGroup, waitCycle = 0) {
                    return new Promise ((resolve, reject) => {
                        if (waitCycle > 20000) {
                            reject('Timeout')
                        } else {
                            if (userGroupChanges.map(obj => {return obj.id}).includes(reUserGroup)) {
                                vm.$nextTick(() => {
                                    resolve(reUserGroup)
                                })
                            } else {
                                setTimeout(() => {
                                    resolve(waitForAddedFolderGroup(reUserGroup, waitCycle + 100))
                                }, 100)
                            }
                        }
                    })
                }

                this.fetch({method: 'POST', url:`/folderManager/security/fgm/userGroup/${this.userInfo.entityID}/${this.selectedFolderGroup}`, body: JSON.stringify({description: this.addGroupDescription, users: this.selectedUsers})})
                    .then(async (data) => {
                        try {
                            this.showAdd = false;
                            this.addingUserGroup = false;
                            await waitForAddedFolderGroup(this.selectedFolderGroup)
                            let userGroupID = await waitForAddedUserGroup(data.reUserGroup)
                            this.$emit('userGroupAdded', userGroupID);
                            this.showSnack('Info', 'User Group Added', 'Close', 'primary');
                        } catch (e) {
                            // eslint-disable-next-line
                            this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
                            this.addingUserGroup = false;
                            console.log(e)
                        }
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e);
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
                        this.addingUserGroup = false;
                    })
            }
        },
        computed: {
            simpleUsers() {
                return this.users.map(user => {
                    return {
                        id: user.userID,
                        description: blankString(user.name) && blankString(user.surname) ? user.username : `${user.name} ${user.surname}`
                    }
                })
            }
        }

    }
</script>

<style scoped>

</style>
