<template>
    <v-card class="fill-height scrollable">
        <v-layout row fill-height class="scrollable">
<!--User Groups-->
            <v-flex xs6 class="fill-height scrollable" v-if="selectedSecurityUser != null">
                <v-layout column fill-height class="scrollable">
                    <v-flex shrink class="body-1 title-color white--text pa-2">
                        User Groups
                    </v-flex>
                    <v-flex shrink class="caption greyType--text font-weight-bold pl-2 pt-1">
                        Add User Groups:
                    </v-flex>
                    <v-flex shrink class="px-4 py-1 pb-2">
                        <v-layout row>
                            <v-flex xs12 v-if="userInfo.userManager == '1'">
                                <app-create-user-group
                                        :userInfo="userInfo"
                                        :selected-user="selectedSecurityUser"
                                        :users="users"
                                        @userGroupAdded="addNewUserGroup($event)"
                                >
                                    <v-btn
                                            small
                                            color="secondary"
                                            flat
                                            block
                                            outline
                                            class="pa-0 ma-0"
                                            slot="activator"
                                    >
                                        New
                                    </v-btn>
                                </app-create-user-group>
                            </v-flex>
                            <v-flex xs12 class="pl-2">
                                <app-add-existing-user-group
                                        :userInfo="userInfo"
                                        :selected-user="selectedSecurityUser"
                                        :userGroups="availableUserGroups"
                                        @userGroupsAdded="updateUserGroups($event)"
                                >
                                    <v-btn
                                            small
                                            color="secondary"
                                            flat
                                            block
                                            outline
                                            class="pa-0 ma-0"
                                            slot="activator"
                                    >
                                        Existing
                                    </v-btn>
                                </app-add-existing-user-group>

                            </v-flex>
                        </v-layout>
                    </v-flex>
                    <v-flex shrink>
                        <v-divider class="title-color darken-1"></v-divider>
                    </v-flex>
                    <v-flex shrink class="px-2 pb-1 pt-2" v-if="securityUserGroups.length >= 2">
                        <v-text-field
                                label="Filter User Groups"
                                flat
                                single-line
                                v-model="userGroupsFilter"
                                color="primary"
                                hide-details
                                style="width: 100%;"
                                class="ma-0 pa-0"
                                prepend-inner-icon="search"
                                lazy
                                clearable
                        />
                    </v-flex>
                    <v-flex xs12 class="scrollable fill-height fill-height" style="overflow-x: hidden">
                        <div v-for="item in filteredSecurityUserGroups" :key="`selectedUserGroup${item.id}`" >

                            <div
                                    :class="selectedUserGroup != null && selectedUserGroup.id === item.id ? 'selected-item' : 'hover-item'"
                                    :style="disableUserGroupClicks ? 'cursor: progress' : 'cursor: pointer'"
                            >
                                <v-layout row align-center style="overflow-x: hidden" class="hide-parent">
                                    <v-flex
                                            shrink
                                            v-if="selectedUserGroup != null && selectedUserGroup.id === item.id && (renamingUserGroup || removingUserGroup)"
                                            class="pl-2 py-1"
                                    >
                                        <v-progress-circular
                                                :size="20"
                                                :width="2"
                                                color="primary"
                                                indeterminate
                                        />
                                    </v-flex>
                                    <v-flex xs12 style="overflow-x: hidden" class="pa-2" @click="!disableUserGroupClicks ? selectedUserGroup = item : ''">

                                        <div class="body-1">
                                            {{item.description}}
                                        </div>
                                        <div class="caption text-truncate" :class="Array.isArray(item.members) && item.members.length > 0 ? 'greyType--text' : 'red--text'" style="overflow-x: hidden">
                                            {{Array.isArray(item.members) && item.members.length > 0 ? item.membersDescriptions.map(obj => {return typeof obj != 'undefined' ? obj : 'ERROR'}).join(', ') : 'NO USER ASSIGNED'}}
                                        </div>
                                    </v-flex>
                                    <v-flex shrink class="pr-2 hide-child">
                                        <div>
                                            <v-tooltip right>
                                                <v-btn
                                                        small
                                                        flat
                                                        icon
                                                        color="red"
                                                        style="width: 18px; height: 18px"
                                                        class="pa-0 ma-0"
                                                        slot="activator"
                                                        @click="!disableUserGroupClicks ? removeUserGroup(item, selectedSecurityUser.id) : ''"
                                                >
                                                    <v-icon small>remove</v-icon>
                                                </v-btn>
                                                <span>Remove User Group</span>
                                            </v-tooltip>
                                        </div>
                                        <div>
                                            <v-tooltip right>
                                                <v-btn
                                                        small
                                                        flat
                                                        icon
                                                        color="primary"
                                                        style="width: 18px; height: 18px"
                                                        class="pa-0 ma-0"
                                                        @click="!disableUserGroupClicks ? renameUserGroupRunStart(item) : ''"
                                                        slot="activator"
                                                >
                                                    <v-icon small>edit</v-icon>
                                                </v-btn>
                                                <span>Rename User Group</span>
                                            </v-tooltip>
                                        </div>
                                    </v-flex>
                                </v-layout>
                                <v-divider></v-divider>
                            </div>
                        </div>
                    </v-flex>
                </v-layout>
            </v-flex>

            <v-flex shrink v-if="selectedUserGroup != null"><v-divider class="title-color darken-1" vertical></v-divider></v-flex>

            <v-flex xs6 class="fill-height scrollable" v-if="selectedUserGroup != null">
                <v-layout column fill-height class="scrollable">
                    <v-flex shrink class="body-1 title-color white--text pa-2 ">
                        Users
                    </v-flex>
                    <v-flex shrink class="caption greyType--text font-weight-bold pl-2 pt-1">
                        Add User to Group:
                    </v-flex>
                    <v-flex shrink class="px-4 py-1 pb-2">
                        <app-add-user
                                :userInfo="userInfo"
                                :selected-user-group="selectedUserGroup"
                                :users="availableUsers"
                                @userAdded="updateGroup($event)"
                                :disabled="userInfo.userManager == '0'"
                        >
                            <v-btn
                                    small
                                    color="secondary"
                                    flat
                                    block
                                    outline
                                    class="pa-0 ma-0"
                                    slot="activator"
                                    :disabled="userInfo.userManager == '0'"
                            >
                                Add User
                            </v-btn>
                        </app-add-user>
                    </v-flex>
                    <v-flex shrink>
                        <v-divider></v-divider>
                    </v-flex>
                    <v-flex shrink class="px-2 pb-1 pt-2 " v-if="Array.isArray(selectedUserGroup.members) && (selectedUserGroup.members.length >= 2 || !blankString(usersFilter))">
                        <v-text-field
                                label="Filter Users"
                                flat
                                single-line
                                v-model="usersFilter"
                                color="primary"
                                hide-details
                                style="width: 100%;"
                                class="ma-0 pa-0"
                                prepend-inner-icon="search"
                                lazy
                                clearable
                        />
                    </v-flex>
                    <v-flex xs12 class="scrollable fill-height fill-height " style="overflow-x: hidden">
                      
                      <template v-if="!resetUsers">
                        <div v-for="(item, n) in filteredSelectedUsers" :key="n" >
                          <div
                              v-if="typeof item != 'undefined'"
                              class="hover-item"
                              :class="selectedUser != null && selectedUser.id === item.id ? 'selected-item' : ''"
                              :style="disableUserGroupClicks || disableUserClicks ? 'cursor: progress' : 'cursor: pointer'"
                          >
                            <v-layout row align-center style="overflow-x: hidden" class="hide-parent">
                              <v-flex
                                  shrink
                                  v-if="selectedUser != null && selectedUser.id === item.id && removingUser"
                                  class="pl-2 py-1"
                              >
                                <v-progress-circular
                                    :size="20"
                                    :width="2"
                                    color="primary"
                                    indeterminate
                                />
                              </v-flex>
                              <v-flex xs12 style="overflow-x: hidden" class="pa-2"
                                      @click="!disableUserGroupClicks && !disableUserClicks ? selectedUser = item : ''">
                                <div class="body-1"
                                     :class="selectedUser != null && selectedUser.id === item.id ? '' : (intToBool(item.disabled) ? 'greyType--text' : '')">
                                  {{ `${item.description}${intToBool(item.disabled) ? ' (Disabled)' : ''}` }}
                                </div>
                                <div class="caption text-truncate greyType--text" style="overflow-x: hidden">
                                  {{ item.username }}
                                </div>
                              </v-flex>
                              <v-flex shrink class="pr-2 hide-child" v-if="userInfo.userManager == '1'">
                                <v-tooltip left>
                                  <v-btn
                                      small
                                      flat
                                      icon
                                      color="red"
                                      style="width: 18px; height: 18px"
                                      class="pa-0 ma-0"
                                      slot="activator"
                                      @click="!disableUserGroupClicks && !disableUserClicks? removeUserGroup(selectedUserGroup, item.id) : ''"
                                  >
                                    <v-icon small>remove</v-icon>
                                  </v-btn>
                                  <span>Remove User from Group</span>
                                </v-tooltip>
                              </v-flex>
                            </v-layout>
                            <v-divider></v-divider>
                          </div>
                        </div>
                      </template>
                    </v-flex>
                </v-layout>
            </v-flex>

<!--Rename User Group-->
            <app-input-dlg
                    v-if="showRenameUserGroup"
                    :show-dialog="showRenameUserGroup"
                    title="Rename User Group"
                    body="New Description:"
                    :cancel-button="true"
                    :show-input-box="true"
                    :default-input="selectedUserGroup.description"
                    :buttons="['Rename']"
                    :call-backs="[ renameUserGroup ]"
                    @dismiss="showRenameUserGroup = false"
            />
        </v-layout>
    </v-card>
</template>

<script>
    import {codeBaseMixin} from "../../../codeBaseMixin";
    import InputDlg from "../../../components/General/InputDlg";
    import AddExistingUserGroup from "./AddExistingUserGroup";
    import AddUser from "./AddUser";
    import CreateUserGroup from "./CreateUserGroup";

    export default {
        mixins: [codeBaseMixin],
        components: {
            appInputDlg: InputDlg,
            appAddExistingUserGroup: AddExistingUserGroup,
            appAddUser: AddUser,
            appCreateUserGroup: CreateUserGroup,
        },
        props: {
            userInfo: Object,
            selectedSecurityUser: Object,
        },

        data() {
            return {
                loadingData: false,
                userGroups: [],
                users: [],
                selectedUserGroup: null,
                userGroupsFilter: null,
                renamingUserGroup: false,
                removingUserGroup: false,
                showRenameUserGroup: false,
                usersFilter: null,
                selectedUser: null,
                removingUser: false,
                resetUsers: false
            }
        },

        watch: {
            selectedSecurityUser: {
                immediate: true,
                handler(newVal, oldVal) {
                    if (typeof oldVal === 'undefined' || oldVal == null || oldVal.id != newVal.id) {
                        this.securityStartup();
                    }
                }
            },
            selectedUserGroup() {
                this.usersFilter = null;
                this.selectedUser = null;
            },
            userGroups: {
                deep: true,
                handler(value) {
                    value.forEach(val => {
                        if (this.selectedUserGroup != null && val.id === this.selectedUserGroup.id && !val.members.includes(this.selectedSecurityUser.id)) {
                            this.selectedUserGroup = null
                        }
                    })
                }
            }
        },

        methods: {
            securityStartup() {
                this.userGroupsFilter = null;
                this.usersFilter = null;
                this.userGroups = [];
                this.users = [];
                this.loading = true;
                this.selectedUser = null;
                this.fetch({method: 'GET', url:`/users/security/startup/${this.userInfo.entityID}`})
                    .then((data) => {

                        this.users = data.users;
                        this.userGroups = data.userGroups;
                        this.loading = false
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e);
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
                        this.loading = false
                    })
            },

            removeUserGroup(group, userID) {
                this.selectedUserGroup = group;
                this.removingUserGroup = true;

                this.fetch({method: 'GET', url:`/users/security/removeUserGroupFromUser/${this.userInfo.entityID}/${group.id}/${userID}`})
                    .then((data) => {
                        this.updateGroup(data);
                        this.removingUserGroup = false;
                        this.resetUsers = true;
                        this.$nextTick(() => {
                            this.resetUsers = false;
                        })
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e);
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
                        this.loading = false;
                        this.removingUserGroup = false;
                    })
            },

            renameUserGroupRunStart(group) {

                this.selectedUserGroup = group;
                this.showRenameUserGroup = true;
            },

            updateUserGroups(groups) {
                groups.forEach(group => {
                    this.updateGroup(group)
                })
            },

            updateGroup(group) {
                this.userGroups = this.userGroups.map(obj => {
                    if (obj.id === group.id) {
                        Object.assign(obj, group)
                    }
                    return obj
                });

                this.$nextTick(() => {
                    if (this.selectedUserGroup != null && this.selectedUserGroup.id === group.id) {
                        this.selectedUserGroup = this.refUserGroups[group.id]
                    }
                })
            },

            renameUserGroup(newName) {
                if (!this.blankString(newName)) {
                    this.renamingUserGroup = true;
                    this.showRenameUserGroup = false;

                    this.fetch({method: 'POST', url:`/users/security/renameUserGroup/${this.userInfo.entityID}/${this.selectedUserGroup.id}`, body: JSON.stringify({description: newName})})
                        .then((data) => {
                            this.updateGroup(data);
                            this.renamingUserGroup = false;
                        })
                        .catch((e) => {
                            // eslint-disable-next-line
                            console.log(e);
                            this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
                            this.loading = false;
                            this.renamingUserGroup = false;
                        })
                } else {
                    this.showSnack('Info', 'A Description is Required', 'Close', 'primary')
                }


            },

            addNewUserGroup(group) {
                this.userGroups.push(group)
            }
        },

        computed: {

            disableUserClicks() {
                return this.removingUser
            },

            filteredSelectedUsers() {
                if (this.selectedUserGroup != null) {
                    let tUsers = this.selectedUserGroup.members.map(obj => {
                        return this.refUsers[obj]
                    });

                    if (!this.blankString(this.usersFilter)) {
                        return tUsers.filter((obj) => `${obj.username} ${obj.description}`.toLowerCase().includes(this.usersFilter.toLowerCase())).sort((a,b) => a['description'] < b['description'] ? -1 : 1);
                    } else {
                        return tUsers.filter(() => true).sort((a,b) => a['description'] < b['description'] ? -1 : 1);
                    }
                } else {
                    return []
                }
            },

            availableUserGroups() {
                let tGroupsList = this.securityUserGroups.map(obj => {return obj.id});


                return this.userGroups.filter(obj => !tGroupsList.includes(obj.id)).map(obj => {return this.refUserGroups[obj.id]})

            },

            availableUsers() {
                if (this.selectedUserGroup != null) {
                    return this.users.filter(user => !this.selectedUserGroup.members.includes(user.id)).sort((a,b) => a['description'] < b['description'] ? -1 : 1);
                } else {
                    return []
                }
            },

            disableUserGroupClicks() {
                return this.removingUserGroup || this.renamingUserGroup
            },

            filteredSecurityUserGroups() {
                if (!this.blankString(this.userGroupsFilter)) {
                    return this.securityUserGroups.filter(obj => `${obj.description}${obj.membersDescriptions.join('')}`.toLowerCase().includes(this.userGroupsFilter.toLowerCase()))
                } else {
                    return this.securityUserGroups;
                }
            },

            securityUserGroups() {
                if (typeof this.selectedSecurityUser != 'undefined') {
                    return this.refUserGroups.reduce((list, item) => {
                        if (item.members.includes(this.selectedSecurityUser.id)) {
                            list.push(item)
                        }
                        return list
                    }, [])
                        .sort((a,b) => a['description'] < b['description'] ? -1 : 1);
                } else {
                    return null
                }
            },

            refUsers() {
                return this.users.reduce((list, item) => {
                    list[item.id] = item;
                    return list
                }, [])
            },


            refUserGroups() {
                return this.userGroups.reduce((list, item) => {
                    let tItem = {
                        membersDescriptions: item.members.map(userID => {
                            return this.refUsers[userID].description
                        })
                    };
                    Object.assign(tItem, item)
                    list[item.id] = tItem;
                    return list
                }, [])
            },
        }

    }
</script>

<style scoped>

</style>

