<template>
    <div class="fill-height">
        <div class="pa-2 text-xs-center" v-if="loading">
            <v-progress-circular
                    :size="40"
                    :width="5"
                    color="primary"
                    indeterminate
            />
        </div>
        <template v-else>
            <app-report-design
                    v-if="viewOption == 1"
                    :user-info="userInfo"
                    :selected-report="selectedReport"
                    :steps="steps"
                    :fields="fields"
                    :data-sets="dataSets"
                    :data-set-fields="dataSetFields"
                    :workflow-statuses="workflowStatuses"
                    :selected-fields="selectedFields"
                    :grouping-order="groupingOrder"
                    :previewData="previewCache"
                    :conditions="conditions"
                    :users="users"
                    :select-lists="selectLists"
                    :loading-report-preview="loadingReportPreview"
                    @add="addFieldToReport($event)"
                    @remove="removeFieldFromReport($event)"
                    @editAlias="editAlias($event)"
                    @newOrder="setNewOrder($event)"
                    @newGroupingOrder="setNewGroupingOrder($event)"
                    v-model="previewSampleSize"
                    @input="runPreviewReport()"
                    @showPreview="showPreviewMail($event)"
                    @post="saveReportChanges"
                    :get-task-header="getTaskHeader"
                    @showReports="$emit('showReports', $event)"
            />
            <report-view
                    v-if="viewOption === 0"
                    :loading-report-preview="loadingReportPreview"
                    :previewData="previewCache"
                    :selected-report="selectedReport"
                    :selectedReport="selectedReport"
                    :user-info="userInfo"
                    @showPreview="showPreviewMail($event)"
                    :get-task-header="getTaskHeader"
                    @showReports="$emit('showReports', $event)"
            />
            <report-share
                    v-if="viewOption === 2"
                    :selected-report="selectedReport"
                    :user-info="userInfo"
                    @removeUserFromReport="$emit('removeUserFromReport')"
                    :local-data="localData"
                    @ownershipChanged="$emit('ownershipChanged')"
            />
        </template>
        <app-mail-view-dlg
                v-if="showPreviewMailDlg"
                :user-info="userInfo"
                :mail="previewMail"
                :showDialog="showPreviewMailDlg"
                @dismiss="showPreviewMailDlg = false"
        />
    </div>
</template>

<script>
    import ReportDesign from './Report Design/ReportDesign'
    import {codeBaseMixin} from "../../codeBaseMixin";
    import MailViewDlg from "../../components/General/MailViewDlg";
    import ReportView from "./ReportView/ReportView";
    import ReportShare from "./Report Sharing V2/ReportShareV2";
    export default {
        mixins: [codeBaseMixin],
        components: {
            ReportShare,
            ReportView,
            appMailViewDlg: MailViewDlg,
            appReportDesign: ReportDesign
        },
        props: {
            selectedReport: Object,
            userInfo: Object,
            viewOption: Number, //0 - View, 1 - Design, 2 - Share
            getTaskHeader: Function,
            localData: Object
        },
        data() {
            return {
                loading: false,
                steps: [],
                fields: [],
                dataSets: [],
                dataSetFields: [],
                workflowStatuses: [],
                reportTemplate: null,
                previewCache: {
                    idColumns: [],
                    reportData: [],
                    userColumns: [],
                    documentColumns: [],
                    users: []
                },
                loadingReportPreview: false,
                previewSampleSize: '100',
                previewMail: null,
                showPreviewMailDlg: false,
                users: [],
                selectLists: [],
            }
        },
        watch: {
            viewOption : {
                immediate: true,
                handler(option) {
                    if (option == 0) {
                        this.runPreviewReport(0);
                    }
                    if (option == 1) {
                        this.runPreviewReport();
                    }
                }
            },
            selectedReport: {
                immediate: true,
                handler(val) {
                    this.$emit('templateLoaded', null)
                    this.getReportDetail();
                }
            },
            'reportTemplate.selectedFields': {
                deep: true,
                handler(selectedFields) {

                    if (typeof selectedFields != 'undefined' && this.reportTemplate.groupingOrder != 'undefined') {
                        // 1: Check For A Aggregate And Group If Found
                        let bAggregateFound = selectedFields.filter(obj => obj.aggregate != null).length >= 1;
                        if (bAggregateFound) {
                            //Filter Out Any Values Where There Is A Grouping For A Field That Dose Not Exist
                            let existingFieldList = selectedFields
                                .filter(obj => obj.aggregate == null)
                                .map((obj) => {
                                    return {
                                        id: obj.id,
                                        fieldType: obj.fieldType,
                                        dsSelect: typeof obj.dsSelect != 'undefined' ? obj.dsSelect : null
                                    }
                                });
                            this.reportTemplate.groupingOrder = this.reportTemplate.groupingOrder.reduce((list, field) => {
                                if (existingFieldList.filter(obj => field.id == obj.id && field.fieldType == obj.fieldType && field.dsSelect == field.dsSelect).length >= 1) {
                                    list.push(field)
                                }
                                return list;
                            }, []);
                            //Add In Any Values That Dont Exist in
                            this.reportTemplate.groupingOrder = existingFieldList.reduce((list, field) => {

                                if (list.filter(obj => field.id == obj.id && field.fieldType == obj.fieldType && field.dsSelect == field.dsSelect).length <= 0) {
                                    list.push(field)
                                }
                                return list;
                            }, this.reportTemplate.groupingOrder);
                        } else {
                            this.reportTemplate.groupingOrder = [];
                        }
                    } else {
                        this.reportTemplate.groupingOrder = [];
                    }
                }
            },
            'reportTemplate.groupingOrder': {
                deep: true,
                handler(val) {
                    this.reportTemplate.conditions = typeof this.reportTemplate.conditions == 'undefined' ? [] : JSON.parse(JSON.stringify(this.reportTemplate.conditions))
                }
            },
            'reportTemplate.conditions': {
                deep: true,
                handler(val) {


                    this.runPreviewReport()
                }
            },

        },
        methods: {
            showPreviewMail(mail) {
                this.previewMail = mail;
                this.showPreviewMailDlg = true;
            },
            setNewGroupingOrder(newOrderArray) {
                this.reportTemplate.groupingOrder = newOrderArray;
            },
            setNewOrder(newOrderArray) {
                newOrderArray.forEach(newOrder => {
                    this.reportTemplate.selectedFields.forEach(oldOrder => {

                        if (
                            (typeof newOrder.dsSelect != 'undefined' && typeof oldOrder.dsSelect != 'undefined' ? newOrder.dsSelect == oldOrder.dsSelect : true)
                            && newOrder.aggregate == oldOrder.aggregate
                            && newOrder.fieldType == oldOrder.fieldType
                            && newOrder.id == oldOrder.id
                        ) {
                            oldOrder.order = newOrder.order
                        }

                    })
                })
            },
            editAlias(aliasData) {
                this.selectedFields.forEach(field => {
                    if (
                        field.id == aliasData.field.id
                        && field.aggregate == aliasData.field.aggregate
                        && field.dsSelect == aliasData.field.dsSelect
                        && field.fieldType == aliasData.field.fieldType
                    ) {
                        field.alias = aliasData.alias;
                    }
                });
            },
            addFieldToReport(fieldData) {
                // {fieldType: "wf", id: 3491}
                if (!Array.isArray(fieldData.id) && !Array.isArray(fieldData.values)) {
                    this.selectedFields.push({
                        id: fieldData.id,
                        fieldType: fieldData.fieldType,
                        aggregate: typeof fieldData.aggregate == 'undefined' ? null : fieldData.aggregate,
                        dsSelect: fieldData.dsSelect,
                        order: this.selectedFields.length,
                        alias: null
                    });

                    if (typeof fieldData.aggregate != 'undefined' && fieldData.aggregate != null) {
                        this.selectedFields.forEach((field, i) => {
                            if (field.id == fieldData.id && fieldData.fieldType == field.fieldType && field.dsSelect == fieldData.dsSelect && (typeof field.aggregate == 'undefined' || field.aggregate == null)) {
                                this.selectedFields.splice(i, 1);
                            }
                        })
                    }

                } else {

                    if (!Array.isArray(fieldData.values)) {
                        fieldData.id.forEach(id => {
                            this.selectedFields.push({
                                id: id,
                                fieldType: fieldData.fieldType,
                                aggregate: null,
                                dsSelect: fieldData.dsSelect,
                                order: this.selectedFields.length,
                                alias: null
                            })
                        })
                    } else {
                        fieldData.values.forEach(value => {
                            this.selectedFields.push({
                                id: value.id,
                                fieldType: fieldData.fieldType,
                                aggregate: null,
                                dsSelect: value.dsSelect,
                                order: this.selectedFields.length,
                                alias: null
                            })
                        })
                    }
                }
            },
            removeFieldFromReport(fieldData) {
                if (!Array.isArray(fieldData.id) && !Array.isArray(fieldData.values)) {
                    this.selectedFields.forEach((field, i) => {
                        if (field.id == fieldData.id && fieldData.fieldType == field.fieldType && field.dsSelect == fieldData.dsSelect && (typeof fieldData.aggregate != 'undefined' ? fieldData.aggregate == field.aggregate : true)) {
                            this.selectedFields.splice(i, 1);
                        }
                    })
                } else {
                    if (!Array.isArray(fieldData.values)) {
                        this.selectedFields = this.selectedFields.filter((field) =>
                            (
                                (
                                    !fieldData.id.includes(field.id)
                                    && fieldData.fieldType == field.fieldType
                                    && field.dsSelect == fieldData.dsSelect
                                )
                                || (fieldData.fieldType != field.fieldType)
                                || (
                                    !fieldData.id.includes(field.id)
                                    && fieldData.fieldType == field.fieldType
                                    && field.dsSelect != fieldData.dsSelect
                                )
                            )
                            || (field.aggregate != null)
                        )
                    } else {
                        this.selectedFields = this.selectedFields.filter((field) =>
                            (field.fieldType == 'df' && field.aggregate != null)
                            || (field.fieldType != 'df')
                        )
                    }

                }
            },
            runPreviewReport(sampleSize) {
                this.previewCache = {
                    reportData: [],
                    userColumns: [],
                    documentColumns: [],
                    users: []
                };
                if (this.selectedFields.length > 0 && (this.viewOption == 1 || this.viewOption == 0)) {
                    this.loadingReportPreview = true;
                    this.fetch({
                        method: 'POST',
                        url:`/wfr/report/preview/${this.userInfo.entityID}/${this.userInfo.userID}/${this.selectedReport.id}/${this.selectedReport.fk_workflow}/${!this.blankString(sampleSize) ? sampleSize : this.previewSampleSize}`,
                        body: JSON.stringify(this.reportTemplate)
                    })
                        .then((data) => {

                            if (data.userColumns.length >= 1) {
                                data.reportData.forEach((row) => {
                                    data.userColumns.forEach(column => {
                                        row[column] = data.users[row[column]]
                                    })
                                })
                            }

                            this.previewCache = data;
                            this.loadingReportPreview = false;
                        })
                        .catch((e) => {
                            // eslint-disable-next-line
                            console.log(e);
                            // this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                            this.loadingReportPreview = false;
                        })
                }
            },
            saveReportChanges() {
                this.loading = true;
                this.fetch({method: 'POST', url:`/wfr/report/${this.userInfo.entityID}/${this.userInfo.userID}/${this.selectedReport.id}`, body: JSON.stringify(this.reportTemplate)})
                    .then((data) => {
                        this.loading = false;

                        this.showSnack('Info', 'Changes Saved', 'Close', 'primary')
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e);
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
                        this.loading = false;
                    })
            },
            getReportDetail() {
                this.loading = true;
                this.steps = [];
                this.fields = [];
                this.dataSets = [];
                this.dataSetFields = [];
                this.workflowStatuses = [];
                this.users = [];
                this.selectLists = [];
                this.fetch({method: 'GET', url:`/wfr/report/${this.userInfo.entityID}/${this.userInfo.userID}/${this.selectedReport.id}`})
                    .then((data) => {
                        this.steps = data.steps;
                        this.fields = data.fields;
                        this.dataSets = data.dataSets;
                        this.dataSetFields = data.dataSetFields;
                        this.workflowStatuses = data.workflowStatuses;
                        this.reportTemplate = data.reportTemplate != null ? data.reportTemplate : {selectedFields: [], groupingOrder: [], conditions: []};
                        this.$emit('templateLoaded', this.reportTemplate)
                        this.users = data.users;
                        this.selectLists = data.selectLists;
                        this.loading = false;
                        if (this.viewOption == 0) {
                            this.$nextTick(() => {

                                this.runPreviewReport(0)
                            })
                        }
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e);
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
                        this.loading = false;
                    })
            },
            setBlankReportTemplate() {
                this.reportTemplate = {
                    selectedFields: []
                }
            }
        },
        computed: {
            conditions: {
                get() {
                    if (this.reportTemplate != null && Array.isArray(this.reportTemplate.conditions)) {
                        return this.reportTemplate.conditions
                    } else {
                        this.setBlankReportTemplate();
                        return []
                    }
                },
                set(newValue) {
                    this.reportTemplate.conditions = newValue;

                }
            },
            selectedFields: {
                get() {
                    if (this.reportTemplate != null && Array.isArray(this.reportTemplate.selectedFields)) {
                        return this.reportTemplate.selectedFields
                    } else {
                        this.setBlankReportTemplate();
                        return []
                    }
                },
                set(newValue) {
                    this.reportTemplate.selectedFields = newValue;

                }
            },
            groupingOrder: {
                get() {
                    if (this.reportTemplate != null && Array.isArray(this.reportTemplate.groupingOrder)) {
                        return this.reportTemplate.groupingOrder
                    } else {
                        this.setBlankReportTemplate();
                        return []
                    }
                },
                set(newValue) {
                    this.reportTemplate.groupingOrder = newValue;
                }
            }
        }
    }
</script>

<style scoped>

</style>
