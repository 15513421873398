<template>
    <div>
        <v-autocomplete
                dense
                :items="userPrinciples"
                item-text="address"
                label="Select Mailbox"
                hide-details
                solo
                @change="principalChanged($event)"
                return-object
                ref="principleSelector"
                v-model="refValue"
                :loading="loadingPrinciples"
                :disabled="loadingPrinciples"
        >
            <template slot="selection" slot-scope="item">
                <!--<v-chip-->
                        <!--small-->
                        <!--label-->
                        <!--close-->
                        <!--dark-->
                        <!--@input="selectedWorkflows = selectedWorkflows.filter(obj => obj != item.item.id)"-->
                        <!--color="primary"-->
                <!--&gt;-->
                    <!--{{item.item.description}}-->
                <!--</v-chip>-->
                <v-layout row align-center class="hide-parent pl-2">
                    <v-flex shrink class="pr-2">
                        <img v-if="typeof item.item.default != 'undefined' && item.item.default == true" src="../../../../../public/outlook_32x1.svg" height="30px">
                        <v-icon v-if="item.item.type == 'saved'">mail</v-icon>
                        <v-icon v-if="item.item.type == 'add'" class="secondary--text">add</v-icon>
                    </v-flex>
                    <v-flex xs12 class="body-2">
                        {{item.item.address}}
                    </v-flex>
                </v-layout>
            </template>
            <template slot="item" slot-scope="address">
                <v-layout row align-center class="hide-parent">
                    <v-flex shrink class="pr-2">
                        <img v-if="typeof address.item.default != 'undefined' && address.item.default == true" src="../../../../../public/outlook_32x1.svg" height="30px">
                        <v-icon v-if="address.item.type == 'saved'">mail</v-icon>
                        <v-icon v-if="address.item.type == 'add'" class="secondary--text">add</v-icon>
                    </v-flex>
                    <v-flex xs12 class="body-1">
                        {{address.item.address}}
                    </v-flex>

                    <v-flex shrink v-if="address.item.type == 'saved'" class="hide-child">
                        <v-tooltip top>
                            <v-btn small icon flat color="red" slot="activator" class="pa-0 ma-0" @click="removePrinciple(address.item.id)">
                                <v-icon>
                                    delete
                                </v-icon>
                            </v-btn>
                            <span>Remove  Mailbox</span>
                        </v-tooltip>
                    </v-flex>

                </v-layout>
            </template>
        </v-autocomplete>
        <app-input-dlg
                :show-dialog="showAddMailBoxDialog"
                title="Add New Mailbox"
                body="Please provide the email address of the mailbox you would like to add"
                :cancel-button="true"
                :show-input-box="true"
                :buttons="['Add Mailbox']"
                :call-backs="[ selectedAddPrinciple ]"
                @dismiss="showAddMailBoxDialog = false; refValue = value;"
        />
    </div>
</template>

<script>
    import {codeBaseMixin} from "../../../../codeBaseMixin";
    import InputDlg from "../../../../components/General/InputDlg";

    export default {
        components: {appInputDlg: InputDlg},
        mixins: [codeBaseMixin],
        props: {
            userInfo: Object,
            value: Object,
            default: Object
        },
        data() {
            return {
                savedUserPrinciples: [],
                loadingPrinciples: false,
                refValue: null,
                showNewMailbox: false,
                newMailboxString: '',
                showAddMailBoxDialog: false
            }
        },
        mounted() {
            this.getAllUserPrinciples();
            this.refValue = this.default;
        },
        methods: {
            removePrinciple(principalID) {
                this.loadingPrinciples = true;
                this.fetch({
                    method: 'GET',
                    url: `/microsoft/removeUserPrinciplesForUser/${this.userInfo.entityID}/${principalID}`
                })
                    .then(() => {
                        this.loadingPrinciples = false;
                        this.$emit('input', this.default);
                        this.refValue = this.default;
                        this.showSnack('Info', 'Mailbox Removed', 'Close', 'primary')
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e);
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                        this.loadingPrinciples = false;
                    })

            },
            selectedAddPrinciple(newVal) {
                if (newVal != null && newVal != "" && this.validateEmail(newVal)) {
                    this.showAddMailBoxDialog = false;
                    this.loadingPrinciples = true;
                    this.fetch({method: 'POST', url:`/microsoft/addMailboxShareForUser/${this.userInfo.entityID}/${this.userInfo.userID}`, body: JSON.stringify({address: newVal})})
                        .then((data) => {
                            this.loadingPrinciples = false;
                            this.savedUserPrinciples.push(data);
                            this.refValue = data;
                            this.$emit('input', data)
                            this.showSnack('Info', 'Mailbox Added', 'Close', 'primary')
                        })
                        .catch((e) => {
                            // eslint-disable-next-line
                            console.log(e);
                            this.showSnack('Error', 'Please Ensure that you have Access Rights to the Mailbox', 'Close', 'red');
                            this.loadingPrinciples = false;
                            this.refValue = this.value;
                        })
                } else {
                    this.showSnack('Error', 'Invalid Email Address', 'Close', 'red');
                }
            },
            principalChanged(newVal) {

                if (newVal.id == -1) {

                    this.showAddMailBoxDialog = true;
                } else {
                    this.$emit('input', newVal)
                }
            },
            getAllUserPrinciples() {
                this.loadingPrinciples = true;
                this.fetch({method: 'GET', url:`/microsoft/getAllUserPrinciplesForUser/${this.userInfo.entityID}/${this.userInfo.userID}`})
                    .then((data) => {
                        this.loadingPrinciples = false;
                        this.savedUserPrinciples = data
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e)
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                        this.loadingPrinciples = false;
                    })
            }
        },
        computed: {
            userPrinciples() {
                try {
                    return [].concat([this.default]).concat(this.savedUserPrinciples.map(obj => {
                        obj.type = 'saved';
                        return obj;
                    }).sort((a,b) => a['address'] < b['address'] ? -1 : 1)).concat([{ id: -1, address: 'Add New Mailbox', type: 'add'}])

                } catch (e) {
                    // eslint-disable-next-line
                    console.log(e);
                    return [this.default]
                }
            }
        }
    }
</script>

<style scoped>

</style>
