<template>
    <div class="pa-2 scrollable">
<!--Conditionals-->
        <v-card style="overflow-y: auto;">
            <v-layout column fill-height class="scrollable" style="overflow-x: hidden">
<!--Title-->
                <v-flex shrink>
                    <v-card-title class="white--text pa-0 ma-0 title-color">
                        <div class="pa-2">Conditions</div>
                    </v-card-title>
                </v-flex>
<!--Drag Operators-->
                <v-flex shrink class="pa-2">
                    <v-flex shrink class="text-xs-center">
                        <v-btn @dragstart="$event.dataTransfer.setData('text', JSON.stringify({operation: `+`, type: 'operator'}))" draggable="true" small flat icon class="pa-0 ma-0 py-1" color="secondary">{{`+`}}</v-btn>
                        <v-btn @dragstart="$event.dataTransfer.setData('text', JSON.stringify({operation: `-`, type: 'operator'}))" draggable="true" small flat icon class="pa-0 ma-0 py-1" color="secondary">{{`-`}}</v-btn>
                        <v-btn @dragstart="$event.dataTransfer.setData('text', JSON.stringify({operation: `*`, type: 'operator'}))" draggable="true" small flat icon class="pa-0 ma-0 py-1" color="secondary">{{`*`}}</v-btn>
                        <v-btn @dragstart="$event.dataTransfer.setData('text', JSON.stringify({operation: `/`, type: 'operator'}))" draggable="true" small flat icon class="pa-0 ma-0 py-1" color="secondary">{{`/`}}</v-btn>
                        <v-btn @dragstart="$event.dataTransfer.setData('text', JSON.stringify({operation: `(`, type: 'operator'}))" draggable="true" small flat icon class="pa-0 ma-0 py-1" color="secondary">{{`(`}}</v-btn>
                        <v-btn @dragstart="$event.dataTransfer.setData('text', JSON.stringify({operation: `)`, type: 'operator'}))" draggable="true" small flat icon class="pa-0 ma-0 py-1" color="secondary">{{`)`}}</v-btn>
                        <v-btn @dragstart="$event.dataTransfer.setData('text', JSON.stringify({operation: `equal`, type: 'equal'}))" draggable="true" small flat icon class="pa-0 ma-0 py-1" color="secondary">{{`=`}}</v-btn>
                        <v-btn @dragstart="$event.dataTransfer.setData('text', JSON.stringify({operation: `<`, type: 'operator'}))" draggable="true" small flat icon class="pa-0 ma-0 py-1" color="secondary">{{`<`}}</v-btn>
                        <v-btn @dragstart="$event.dataTransfer.setData('text', JSON.stringify({operation: `>`, type: 'operator'}))" draggable="true" small flat icon class="pa-0 ma-0 py-1" color="secondary">{{`>`}}</v-btn>
                        <v-btn @dragstart="$event.dataTransfer.setData('text', JSON.stringify({operation: `d`, type: 'd'}))" draggable="true" small flat round class="pa-0 ma-0 py-1" color="secondary">{{`Days`}}</v-btn>
                        <v-btn @dragstart="$event.dataTransfer.setData('text', JSON.stringify({operation: `w`, type: 'w'}))" draggable="true" small flat round class="pa-0 ma-0 py-1" color="secondary">{{`Weeks`}}</v-btn>
                        <v-btn @dragstart="$event.dataTransfer.setData('text', JSON.stringify({operation: `m`, type: 'm'}))" draggable="true" small flat round class="pa-0 ma-0 py-1" color="secondary">{{`Months`}}</v-btn>
                        <v-btn @dragstart="$event.dataTransfer.setData('text', JSON.stringify({operation: `y`, type: 'y'}))" draggable="true" small flat round class="pa-0 ma-0 py-1" color="secondary">{{`Years`}}</v-btn>
                        <v-btn @dragstart="$event.dataTransfer.setData('text', JSON.stringify({operation: `h`, type: 'h'}))" draggable="true" small flat round class="pa-0 ma-0 py-1" color="secondary">{{`Hours`}}</v-btn>
                        <v-btn @dragstart="$event.dataTransfer.setData('text', JSON.stringify({operation: `mi`, type: 'mi'}))" draggable="true" small flat round class="pa-0 ma-0 py-1" color="secondary">{{`Minutes`}}</v-btn>
                        <v-btn @dragstart="$event.dataTransfer.setData('text', JSON.stringify({operation: `s`, type: 's'}))" draggable="true" small flat round class="pa-0 ma-0 py-1" color="secondary">{{`Seconds`}}</v-btn>
                        <v-btn @dragstart="$event.dataTransfer.setData('text', JSON.stringify({operation: `now`, type: 'now'}))" draggable="true" small flat round class="pa-0 ma-0 py-1" color="secondary">{{`NOW`}}</v-btn>
                        <v-btn @dragstart="$event.dataTransfer.setData('text', JSON.stringify({operation: `value`, type: 'value'}))" draggable="true" small flat round class="pa-0 ma-0 py-1" color="secondary">{{`Value`}}</v-btn>
                        <v-btn @dragstart="$event.dataTransfer.setData('text', JSON.stringify({operation: `field`, type: 'field'}))" draggable="true" small flat round class="pa-0 ma-0 py-1" color="secondary">{{`Dataset Field`}}</v-btn>
                    </v-flex>
                </v-flex>
                <v-divider/>

                <template v-if="sections.length > 0">
<!--Sections-->
                    <v-flex
                            shrink
                            v-for="(condition, ci) in sections"
                            :key="`{conditionSection:${ci}`"
                    >
                        <v-layout row align-center class="hide-parent">
                            <v-flex xs12>
                                <v-layout row wrap v-if="condition.length > 0" align-center>
                                    <v-flex
                                            shrink
                                            v-for="(section, si) in condition"
                                            class="py-1 pl-1"

                                            @dragover="$event.preventDefault()"
                                            @drop="dropOperator($event, ci, si)"
                                    >
                                        <template v-if="section.type == 'placeholder'"><v-btn icon small class="pa-0 ma-0 primary10"></v-btn></template>
                                        <template v-if="section.type == 'operator'"><v-chip @input="removeItem(ci, si)" close small class="pa-0 ma-0 primary20">{{section.operation}}</v-chip></template>
                                        <template v-if="section.type == 'd'"><v-chip @input="removeItem(ci, si)" close small class="pa-0 ma-0 primary20" >{{ section.value + ' Days' }}</v-chip></template>
                                        <template v-if="section.type == 'w'"><v-chip @input="removeItem(ci, si)" close small class="pa-0 ma-0 primary20" >{{ section.value + ' Weeks' }}</v-chip></template>
                                        <template v-if="section.type == 'm'"><v-chip @input="removeItem(ci, si)" close small class="pa-0 ma-0 primary20" >{{ section.value + ' Months' }}</v-chip></template>
                                        <template v-if="section.type == 'y'"><v-chip @input="removeItem(ci, si)" close small class="pa-0 ma-0 primary20" >{{ section.value + ' Years' }}</v-chip></template>
                                        <template v-if="section.type == 'h'"><v-chip @input="removeItem(ci, si)" close small class="pa-0 ma-0 primary20" >{{ section.value + ' Hours' }}</v-chip></template>
                                        <template v-if="section.type == 'mi'"><v-chip @input="removeItem(ci, si)" close small class="pa-0 ma-0 primary20" >{{ section.value + ' Minutes' }}</v-chip></template>
                                        <template v-if="section.type == 's'"><v-chip @input="removeItem(ci, si)" close small class="pa-0 ma-0 primary20" >{{ section.value + ' Seconds' }}</v-chip></template>
                                        <template v-if="section.type == 'now'"><v-chip @input="removeItem(ci, si)" close small class="pa-0 ma-0 primary20" >NOW</v-chip></template>
                                        <template v-if="section.type == 'value'"><v-chip @input="removeItem(ci, si)" close small class="pa-0 ma-0 primary20" >{{ `'${section.value}'` }}</v-chip></template>
                                        <template v-if="section.type == 'equal'"><v-chip @input="removeItem(ci, si)" close small class="pa-0 ma-0 primary20" >{{ `=` }}</v-chip></template>
                                        <template v-if="section.type == 'field'"><v-chip @input="removeItem(ci, si)" close small class="pa-0 ma-0 primary20" >{{ fields.filter(obj => obj.id == section.value)[0].description }}</v-chip></template>
                                        <!--@input="removeSection(i)"-->
                                    </v-flex>
                                </v-layout>
                            </v-flex>
                            <v-flex shrink class="px-1 hide-child">
                                <v-tooltip left>
                                    <v-btn
                                            small
                                            flat
                                            icon
                                            class="pa-0 ma-0"
                                            color="red"
                                            slot="activator"
                                            @click="removeCondition(ci)"
                                    >
                                        <v-icon small>delete</v-icon>
                                    </v-btn>
                                    Remove Condition
                                </v-tooltip>
                            </v-flex>

                        </v-layout>
                        <div>
                            <v-divider/>
                        </div>
                    </v-flex>
                </template>
<!--Add New Section-->
                <v-flex shrink class="pa-2">
                    <v-btn
                            color="secondary"
                            small
                            flat
                            outline
                            block
                            @click="conditions = conditions.concat([[]])"
                            class="ma-0"
                    >
                        Add Condition
                    </v-btn>
                </v-flex>

            </v-layout>
        </v-card>
<!--Action-->
        <div class="pt-2">
        <v-card style="overflow-y: auto;">
            <v-layout column fill-height class="scrollable" style="overflow-x: hidden">
<!--Title-->
                <v-flex shrink>
                    <v-card-title class="white--text pa-0 ma-0 title-color">
                        <div class="pa-2">Actions</div>
                    </v-card-title>
                </v-flex>
                <v-flex shrink class="primary--text font-weight-bold pa-2">
                    Workflow Actions
                </v-flex>
                <v-divider/>
<!--Drag Operators-->
                <v-flex shrink class="px-2">
<!--Select Workflow-->
                    <div :class="topicClass">
                        <div :class="headingClass">Select Workflow:</div>
                        <div :class="itemClass">
                            <v-autocomplete
                                    color="primary"
                                    label="Select Workflow"
                                    :items="workflows"
                                    item-text="description"
                                    return-object
                                    flat
                                    v-model="selectedActionWorkflow"
                                    hide-details
                                    class="pa-0 ma-0"
                                    dense
                                    single-line
                            >
                            </v-autocomplete>
                        </div>
                    </div>
<!--Select User Option-->
                    <template v-if="selectedActionWorkflow != null">
                        <div :class="topicClass">
                            <div :class="headingClass">Select User Option:</div>
                            <div :class="itemClass">
                                <v-autocomplete
                                        color="primary"
                                        label="Select Workflow"
                                        :items="userTypes"
                                        item-text="description"
                                        item-value="id"
                                        flat
                                        v-model="actionUserType"
                                        hide-details
                                        class="pa-0 ma-0"
                                        dense
                                        single-line
                                >
                                </v-autocomplete>
                            </div>
                        </div>
<!--Select User-->
                        <div :class="topicClass" v-if="userTypes.length <= 1"><span class="greyType--text caption">*Selected Workflow does not have Delegation set up</span></div>
                        <template v-if="actionUserType != null && actionUserType == 0">
                            <div :class="topicClass">
                                <div :class="headingClass">Select Target User:</div>
                                <div :class="itemClass">
                                    <v-autocomplete
                                            color="primary"
                                            label="Select User"
                                            :items="users"
                                            item-text="name"
                                            item-value="id"
                                            flat
                                            v-model="actionUser"
                                            hide-details
                                            class="pa-0 ma-0 pt-2"
                                            dense
                                            single-line
                                    >
                                    </v-autocomplete>
                                </div>
                            </div>
                        </template>
<!--Select Title Type-->
                        <template v-if="actionUserType != null">
                            <div :class="topicClass">
                                <div :class="headingClass">Select Title Type:</div>
                                <div :class="itemClass">
                                    <v-autocomplete
                                            color="primary"
                                            label="Select TitleType"
                                            :items="titleTypes"
                                            item-text="description"
                                            item-value="id"
                                            flat
                                            v-model="actionTitleType"
                                            hide-details
                                            class="pa-0 ma-0 pt-2"
                                            dense
                                            single-line
                                    >
                                    </v-autocomplete>
                                </div>
                            </div>
                        </template>
<!--Configure Title-->
                        <div :class="topicClass" v-if="actionTitleType == 1" class="pb-2">
                            <div :class="headingClass">Configure Title:</div>
                            <div :class="itemClass">
                                <v-btn @dragstart="$event.dataTransfer.setData('text', JSON.stringify({operation: `text`, type: 'text'}))" draggable="true" small flat round class="pa-0 ma-0 py-1" color="secondary">{{`Text`}}</v-btn>
                                <v-btn @dragstart="$event.dataTransfer.setData('text', JSON.stringify({operation: `field`, type: 'field'}))" draggable="true" small flat round class="pa-0 ma-0 py-1" color="secondary">{{`Dataset Field`}}</v-btn>
                            </div>
                        </div>
                        <div :class="topicClass" v-if="actionTitleType == 1">
                            <v-layout row align-center wrap>
                                <v-flex
                                        shrink
                                        v-for="(section, si) in titleSections"
                                        :key="`ActionSectionKey${si}`"
                                        class="py-1 pr-1"
                                        @dragover="$event.preventDefault()"
                                        @drop="dropOperatorTitle($event, si)"
                                >
                                    <template v-if="section.type == 'placeholder'"><v-btn icon small class="pa-0 ma-0 primary10"></v-btn></template>
                                    <template v-if="section.type == 'text'"><v-chip @input="removeItemTitle(si)" close small class="pa-0 ma-0 primary20" >{{ `'${section.value}'` }}</v-chip></template>
                                    <template v-if="section.type == 'field'"><v-chip @input="removeItemTitle(si)" close small class="pa-0 ma-0 primary20" >{{ fields.filter(obj => obj.id == section.value)[0].description }}</v-chip></template>
                                    <!--@input="removeSection(i)"-->
                                </v-flex>
                            </v-layout>
                        </div>
                    </template>

                </v-flex>
                <v-flex shrink class="primary--text font-weight-bold pa-2">
                    Field Actions
                </v-flex>
                <v-divider/>
                <v-flex shrink>
                    <app-field-set-admin-update-fields-action
                            :userInfo="userInfo"
                            v-model="actionFields"
                            :fields="fields"
                    />
                </v-flex>
            </v-layout>
        </v-card>
            <div style="position: absolute; bottom: 15px; right: 15px;">
                <v-btn color="secondary" class="ma-0" icon @click="$emit('post')">
                    <v-icon>save</v-icon>
                </v-btn>
            </div>
        </div>

<!--Add Value-->
        <app-input-dlg
                :show-dialog="showAddVal"
                :title="addVal.title"
                :body="addVal.body"
                :cancel-button="true"
                :show-input-box="true"
                :buttons="['Add']"
                :call-backs="[ addValue ]"
                @dismiss="showAddVal = false"
                default-input="0"
        />
<!--Add Value Title-->
        <app-input-dlg
                :show-dialog="showAddValTitle"
                :title="addValTitle.title"
                :body="addValTitle.body"
                :cancel-button="true"
                :show-input-box="true"
                :buttons="['Add']"
                :call-backs="[ addValueTitle ]"
                @dismiss="showAddValTitle = false"
                default-input="0"
        />
<!--Add Field-->
        <v-dialog v-model="showAddField" width="400px" persistent>
            <v-card flat>
                <v-card-title class="pa-1 pl-2">
                    <span class="subheading font-weight-light primary--text">Add Field</span>
                    <v-spacer></v-spacer>
                    <v-btn
                            small
                            icon
                            flat
                            color="primary"
                            class="pa-0 ma-0"
                            @click="showAddField = false"
                    >
                        <v-icon small>close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-divider></v-divider>

                <v-card-text>
                    <span class="body-1">Please Select  Field to Add</span>
                    <v-autocomplete
                            color="primary"
                            label="Select Field"
                            :items="fields"
                            item-text="description"
                            return-object
                            flat
                            v-model="selectedAddField"
                            hide-details
                            class="pa-0 ma-0 pt-2"
                            dense
                            single-line
                    >
                    </v-autocomplete>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                            flat
                            color="secondary"
                            dark
                            :disabled="selectedAddField == null"
                            @click="addField"
                    >
                        select Field
                    </v-btn>
                </v-card-actions>

            </v-card>
        </v-dialog>
<!--Add Field Title-->
        <v-dialog v-model="showAddFieldTitle" width="400px" persistent>
            <v-card flat>
                <v-card-title class="pa-1 pl-2">
                    <span class="subheading font-weight-light primary--text">Add Field</span>
                    <v-spacer></v-spacer>
                    <v-btn
                            small
                            icon
                            flat
                            color="primary"
                            class="pa-0 ma-0"
                            @click="showAddFieldTitle = false"
                    >
                        <v-icon small>close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-divider></v-divider>

                <v-card-text>
                    <span class="body-1">Please Select a Field To Add</span>
                    <v-autocomplete
                            color="primary"
                            label="Select Field"
                            :items="fields"
                            item-text="description"
                            return-object
                            flat
                            v-model="selectedAddFieldTitle"
                            hide-details
                            class="pa-0 ma-0 pt-2"
                            dense
                            single-line
                    >
                    </v-autocomplete>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                            flat
                            color="secondary"
                            dark
                            :disabled="selectedAddFieldTitle == null"
                            @click="addFieldTitle"
                    >
                        select Field
                    </v-btn>
                </v-card-actions>

            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import InputDlg from "../../../components/General/InputDlg";
    import {codeBaseMixin} from "../../../codeBaseMixin";
    import FieldSetAdminUpdateFieldsAction from "./FieldSetAdminUpdateFieldsAction";

    export default {
        mixins: [codeBaseMixin],
        components: {
            appInputDlg: InputDlg,
            appFieldSetAdminUpdateFieldsAction: FieldSetAdminUpdateFieldsAction
        },
        props: {
            userInfo: Object,
            action: Object,
            fields: Array,
            workflows: Array,
            users: Array
        },
        data() {
            return {
                expansionModel: 0,
                runReset: 0,
                showAddVal: false,
                addVal: {
                    operation: '',
                    title: '',
                    body: '',
                    position: {}
                },
                addValTitle: {
                    operation: '',
                    title: '',
                    body: '',
                    position: null
                },
                showAddField: false,
                selectedAddField: null,
                selectedAddFieldTitle: null,
                titleTypes: [{id: 0, description: 'Default Title'}, {id: 1, description: 'Custom Title'}],
                showAddFieldTitle: false,
                showAddValTitle: false,
            }
        },
        methods: {
            removeItem(conditionIndex, sectionIndex) {
                let tArr = this.sections.filter(() => true);

                tArr[conditionIndex].splice(sectionIndex, 1);

                this.conditions = tArr.map(condition => {
                    return condition.filter(obj => obj.type != 'placeholder')
                });
            },

            removeItemTitle(sectionIndex) {
                let tArr = this.titleSections.filter(() => true);
                tArr.splice(sectionIndex, 1);
                this.actionTitleSections = tArr.filter(obj => obj.type != 'placeholder')
            },

            addValue(value) {
                if (this.addVal.operator != `value`) {
                    let reg = /^-?\d+\.?\d*$/;

                    if (value != null && value != '' && reg.test(value)) {
                        //Add Date Value
                        this.showAddVal = false;

                        let tArr = this.sections.filter(() => true);

                        tArr[this.addVal.position.ci].splice(this.addVal.position.si, 1, {type: this.addVal.operator, value: value});

                        this.conditions = tArr.map(condition => {
                            return condition.filter(obj => obj.type != 'placeholder')
                        });

                    } else {
                        this.showSnack('Error', 'Invalid Value Specified', 'Close', 'red')
                    }
                } else {
                    if (value != null && value != '') {
                        //Add Value
                        this.showAddVal = false;

                        let tArr = this.sections.filter(() => true);

                        tArr[this.addVal.position.ci].splice(this.addVal.position.si, 1, {type: this.addVal.operator, value: value});

                        this.conditions = tArr.map(condition => {
                            return condition.filter(obj => obj.type != 'placeholder')
                        });

                    } else {
                        this.showSnack('Error', 'Invalid Value Specified', 'Close', 'red')
                    }
                }
            },

            addValueTitle(value) {
                if (value != null && value != '') {
                    //Add Value
                    this.showAddValTitle = false;

                    let tArr = this.titleSections.filter(() => true);

                    tArr.splice(this.addValTitle.position, 1, {
                        type: 'text',
                        value: value
                    });

                    this.actionTitleSections = tArr.filter(obj => obj.type != 'placeholder')

                } else {
                    this.showSnack('Error', 'Invalid Value Specified', 'Close', 'red')
                }
            },

            addField() {
                let tArr = this.sections.filter(() => true);

                tArr[this.addVal.position.ci].splice(this.addVal.position.si, 1, {type: `field`, value: this.selectedAddField.id, fieldType: this.selectedAddField.type});

                this.conditions = tArr.map(condition => {
                    return condition.filter(obj => obj.type != 'placeholder')
                });
                this.showAddField = false;
                this.selectedAddField = null;
            },

            addFieldTitle() {
                let tArr = this.titleSections.filter(() => true);
                tArr.splice(this.addValTitle.position, 1, {type: `field`, value: this.selectedAddFieldTitle.id, fieldType: this.selectedAddFieldTitle.type});
                this.actionTitleSections = tArr.filter(obj => obj.type != 'placeholder')
                this.showAddFieldTitle = false;
                this.selectedAddFieldTitle = null;
            },

            dropOperator(event, conditionIndex, sectionIndex) {
                let data = JSON.parse(event.dataTransfer.getData("text"));
                if ([`d`,`w`,`m`,`y`,`h`,`mi`,`s`,`value`,`field`].includes(data.operation)) {
                    switch (data.operation) {
                        case 'd': {
                            this.addVal.title = 'Add Days Value';
                            this.addVal.body = 'Please specify the Amount of Days you would like to Add';
                            break;
                        }
                        case 'w': {
                            this.addVal.title = 'Add Weeks Value';
                            this.addVal.body = 'Please specify the Amount of Weeks you would like to Add';
                            break;
                        }
                        case 'm': {
                            this.addVal.title = 'Add Months Value';
                            this.addVal.body = 'Please specify the Amount of Months you would like to Add';
                            break;
                        }
                        case 'y': {
                            this.addVal.title = 'Add Years Value';
                            this.addVal.body = 'Please specify the Amount of Years you would like to Add';
                            break;
                        }
                        case 'h': {
                            this.addVal.title = 'Add Hours Value';
                            this.addVal.body = 'Please specify the Amount of Hours you would like to Add';
                            break;
                        }
                        case 'mi': {
                            this.addVal.title = 'Add Minutes Value';
                            this.addVal.body = 'Please specify the Amount of Minutes you would like to Add';
                            break;
                        }
                        case 's': {
                            this.addVal.title = 'Add Seconds Value';
                            this.addVal.body = 'Please specify the Amount of Seconds you would like to Add';
                            break;
                        }
                        case 'value': {
                            this.addVal.title = 'Add Value';
                            this.addVal.body = 'Please specify the Value you would like to Add';
                            break;
                        }
                    }
                    this.addVal.operator = data.operation;
                    this.addVal.position = {ci: conditionIndex, si: sectionIndex};

                    if (data.operation != 'field') {
                        this.showAddVal = true;
                    } else {
                        this.showAddField = true;
                    }



                } else {
                    let tArr = this.sections.filter(() => true);

                    tArr[conditionIndex].splice(sectionIndex,1,data);

                    this.conditions = tArr.map(condition => {
                        return condition.filter(obj => obj.type != 'placeholder')
                    });
                }
            },

            dropOperatorTitle(event, sectionIndex) {
                let data = JSON.parse(event.dataTransfer.getData("text"));

                if (data.operation == 'text') {
                    this.addValTitle.title = 'Add Text';
                    this.addValTitle.body = 'Please specify the Text you would like to add:';
                    this.addValTitle.operator = data.operation;
                    this.addValTitle.position = sectionIndex;
                    this.showAddValTitle = true;
                } else {
                    this.addValTitle.operator = data.operation;
                    this.addValTitle.position = sectionIndex;
                    this.showAddFieldTitle = true;
                }
            },

            removeCondition(conditionIndex) {
                this.conditions = this.conditions.filter((obj, index) => index != conditionIndex)
            }
        },
        computed: {
            lookup() {
                if (this.action.object == null || this.action.object == '') {
                    return {
                        conditions: [],
                        action: {
                            workflowID: null,
                            userType: 0, //0 - Regular User; 1 - distribution
                            userID: null,
                            titleType: null,
                            titleObject: []
                        }
                    }
                } else {
                    return JSON.parse(this.action.object)
                }
            },
            conditions: {
                // getter
                get: function () {
                    return this.lookup.conditions
                },
                // setter
                set: function (newValue) {
                    this.action.object = JSON.stringify({
                        conditions: newValue,
                        action: this.refAction
                    })
                }
            },
            sections() {
                let tReturn = [];
                this.conditions.forEach(conditionArray => {
                    let tPush = [];
                    tPush.push({type: 'placeholder'});
                    conditionArray.forEach(item => {
                        if (item.type != 'placeholder') {
                            let tItem = item;
                            tPush.push(tItem);
                            tPush.push({type: 'placeholder'})
                        }
                    });
                    tReturn.push(tPush)
                });


                return tReturn;
            },

            actionTitleSections: {
                // getter
                get: function () {
                    return this.refAction.titleObject
                },
                // setter
                set: function (newValue) {
                    let tItem = this.refAction;
                    tItem.titleObject = newValue;
                    this.refAction = tItem;
                }
            },

            actionFields: {
                // getter
                get: function () {
                    return Array.isArray(this.refAction.updateFields) ? this.refAction.updateFields : []
                },
                // setter
                set: function (newValue) {

                    let tItem = this.refAction;
                    tItem.updateFields = newValue;
                    this.refAction = tItem;
                    // if (!Array.isArray(this.refAction.updateFields)) {
                    //     this.$set(this.refAction, `updateFields`, newValue)

                    // } else {
                    //     this.refAction.updateFields = newValue;

                    // }
                }
            },

            titleSections() {
                let tPush = [];
                tPush.push({type: 'placeholder'});
                this.actionTitleSections.forEach(item => {
                    if (item.type != 'placeholder') {
                        let tItem = item;
                        tPush.push(tItem);
                        tPush.push({type: 'placeholder'})
                    }
                });
                return tPush;
            },

            selectedActionWorkflow: {
                // getter
                get: function () {
                    return this.refAction.workflowID
                },
                // setter
                set: function (newValue) {
                    let tItem = this.refAction;
                    tItem.workflowID = newValue;
                    this.refAction = tItem;
                }
            },
            userTypes() {
                if (this.selectedActionWorkflow != null && this.intToBool(this.selectedActionWorkflow.delegationReady)) {
                    return [{id : 0, description: 'Select Target User'}, {id: 1, description: 'Use Workflow Distribution'}]
                } else {
                    return [{id : 0, description: 'Select Target User'}]
                }
            },
            actionUserType: {
                // getter
                get: function () {
                    return this.refAction.userType
                },
                // setter
                set: function (newValue) {
                    let tItem = this.refAction;
                    tItem.userType = newValue;
                    this.refAction = tItem;
                }
            },
            actionUser: {
                // getter
                get: function () {
                    return this.refAction.userID
                },
                // setter
                set: function (newValue) {
                    let tItem = this.refAction;
                    tItem.userID = newValue;
                    this.refAction = tItem;
                }
            },
            actionTitleType: {
                // getter
                get: function () {
                    return this.refAction.titleType
                },
                // setter
                set: function (newValue) {
                    let tItem = this.refAction;
                    tItem.titleType = newValue;
                    this.refAction = tItem;
                }
            },
            refAction: {
                // getter
                get: function () {
                    return this.lookup.action
                },
                // setter
                set: function (newValue) {

                    this.action.object = JSON.stringify({
                        conditions: this.conditions,
                        action: newValue
                    })
                }
            },

        },
    }
</script>

<style scoped>

</style>
