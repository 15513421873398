<template>
  <app-input-dlg
      :show-dialog="value"
      :show-input-box="false"
      :body-padding="false"
      :width="600"
  >
    <template slot="title">
      <div class="primary">
        <v-layout row align-center>
          <v-flex shrink class="pl-2 pt-1">
            <img src="3cxlogo.png" height="18px">
          </v-flex>
          <v-flex shrink class="body-1 white--text pa-2">
            Recordings
          </v-flex>
          <v-flex xs12 class="px-2 ">
            <v-text-field
                hide-details
                single-line
                dark
                prepend-icon="search"
                label="Search"
                class="pa-0 ma-0"
                color="white"
                v-model="searchStr"
            />
          </v-flex>
          <v-flex shrink v-if="extensions.length > 1">
            <v-autocomplete
                color="white"
                @input="extChanged"
                dense
                v-model="selectedExt"
                :items="extensions"
                label="Select Extension"
                hide-details
                single-line
                item-text="description"
                item-value="id"
                class="pa-0 ma-0"
                dark
                prepend-icon="contact_phone"
                hide-selected
            />
          </v-flex>
          <v-flex shrink class="px-2">
            <v-btn
                small
                icon
                flat
                class="pa-0 ma-0"
                color="white"
                @click="$emit('input', false)"
            >
              <v-icon small>close</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </div>
    </template>
    <template slot="body">
      <template v-if="extensions == null">
        <div class="body-2 red--text text-xs-center">
          3CX Is not set up please contact your system administrator.
        </div>
      </template>
      <template v-else>
        <v-divider></v-divider>
        <div v-if="loadingRecordings && recordings.length <= 0" style="width: 100%" class="text-xs-center">
          <v-progress-circular
              :size="40"
              :width="5"
              color="primary"
              indeterminate
          />
        </div>
        <div v-else-if="recordingsOutgoing.length" style="height: 400px; overflow-y: auto"
             class="c-d-flex fill-height of-y">
          <div style="width: 50%" class="fill-height c-d-flex c-flex-column of-y">
            
            <div class="primary white--text pa-2">
              Incoming Calls
            </div>
            
            <div class="c-flex-grow-1 of-y">
              <template v-for="rec in filteredCalls">
                <recording
                    :ext="selectedExt"
                    :user-info="userInfo"
                    :rec="rec"
                    :selected-rec-i-d="selectedRecID"
                    @selectRecording="selectedRecID = $event"
                    :show-to="false"
                />
              </template>
              <div class="pt-1 text-xs-center" v-if="loadMore">
                <v-btn
                    small
                    class="pa-0 mx-2"
                    outline
                    color="secondary"
                    @click="() => getCalls('incoming')"
                    :disabled="loadingRecordings"
                    :loading="loadingRecordings"
                >Load More
                </v-btn>
              </div>
            </div>
          </div>
          
          <div style="width: 50%" class="fill-height c-d-flex c-flex-column of-y">
            <div class="primary white--text pa-2">
              Outgoing Calls
            </div>
            
            <div class="c-flex-grow-1 of-y">
              <template v-for="rec in filteredCallsOutgoing">
                <recording
                    :ext="selectedExt"
                    :user-info="userInfo"
                    :rec="rec"
                    :selected-rec-i-d="selectedRecID"
                    :show-from="false"
                    @selectRecording="selectedRecID = $event"
                />
              </template>
              <div class="pt-1 text-xs-center" v-if="loadMore">
                <v-btn
                    small
                    class="pa-0 mx-2"
                    outline
                    color="secondary"
                    @click="() => getCalls('outgoing')"
                    :disabled="loadingRecordings"
                    :loading="loadingRecordings"
                >Load More
                </v-btn>
              </div>
            </div>
          
          </div>
        </div>
        <div v-else style="max-height: 400px; overflow-y: auto">
          <template v-for="rec in filteredCalls">
            <recording
                :ext="selectedExt"
                :user-info="userInfo"
                :rec="rec"
                :selected-rec-i-d="selectedRecID"
                @selectRecording="selectedRecID = $event"
            />
          </template>
          <div class="pt-1 text-xs-center" v-if="loadMore">
            <v-btn
                small
                class="pa-0 mx-2"
                outline
                color="secondary"
                @click="() => getCalls()"
                :disabled="loadingRecordings"
                :loading="loadingRecordings"
            >Load More
            </v-btn>
          </div>
        </div>
      </template>
    </template>
    <template slot="actions">
      <div style="width: 100%" class="text-xs-right">
        <v-btn
            :disabled="selectedRecID == null"
            :loading="linkingToTask"
            small
            color="secondary"
            flat
            @click="linkRecordingToTask"
        >
          <v-icon left>link</v-icon>
          Link To Task
        </v-btn>
      </div>
    </template>
  </app-input-dlg>
</template>

<script>
import InputDlg from "../../../../components/General/InputDlg";
import {codeBaseMixin} from "../../../../codeBaseMixin";
import Recording from "./Recording";
import {eventBus} from "../../../../main";

export default {
  mixins: [codeBaseMixin],
  components: {Recording, appInputDlg: InputDlg},
  props: {
    userInfo: Object,
    taskID: Number,
    task: Object,
    stepID: Number,
    value: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedExt: null,
      recordings: [],
      recordingsOutgoing: [],
      loadingRecordings: false,
      loadMore: false,
      loadMoreOutgoing: false,
      selectedRecID: null,
      linkingToTask: false,
      searchStr: null
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        if (val && this.extensions.length > 0) {
          eventBus.$emit('AudioPlay')
          this.searchStr = null;
          this.selectedRecID = null;
          this.recordings = [];
          this.recordingsOutgoing = [];
          this.selectedExt = this.extensions[0];
          this.getCalls();
        }
      }
    }
  },
  
  methods: {
    
    linkRecordingToTask() {
      this.linkingToTask = true;
      let recordingData = this.selectedRecording();
      this.fetch({
        method: 'POST', url: `/3cx/linkRecordingToTask/${this.userInfo.entityID}`, body: JSON.stringify({
          userID: this.userInfo.userID,
          taskID: this.taskID,
          stepID: this.stepID,
          newFilename: `${recordingData.from} - ${recordingData.to}.wav`,
          voice3CXid: recordingData.id,
          "3CXServerIndex": this.userInfo["3CXServerIndex"]
        })
      })
          .then((data) => {
            this.task.structure.documents.push(data)
            this.linkingToTask = false;
            this.$emit('addedDocument', false)
            this.$emit('input', false)
          })
          .catch((e) => {
            // eslint-disable-next-line
            console.log(e)
            this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
            this.linkingToTask = false;
          })
    },
    
    extChanged() {
      this.selectedRecID = null;
      this.recordings = [];
      this.recordingsOutgoing = [];
      this.getCalls()
    },
    
    getCalls(type) {
      this.$nextTick(() => {
        this.loadingRecordings = true;
        
        const skip = type === 'outgoing' ? this.recordingsOutgoing.length : this.recordings.length
        
        this.fetch({
          method: 'GET',
          url: `/3cx/recordingsForMailbox/${this.userInfo.entityID}/${this.userInfo['3CXServerIndex']}/${this.selectedExt}/${skip}${type ? '/' + type : ''}`
        })
            .then((data) => {
              
              if (data.incoming) {
                this.recordings = this.recordings.concat(data.incoming.recordings);
                this.loadMore = data.incoming.totalRows > this.recordings.length;
              }
              
              if (data.outgoing) {
                this.recordingsOutgoing = this.recordingsOutgoing.concat(data.outgoing.recordings);
                this.loadMore = data.outgoing.totalRows > this.recordingsOutgoing.length;
              }
              
              if (!data.incoming && !data.outgoing) {
                this.recordings = this.recordings.concat(data.recordings);
                this.loadMore = data.totalRows > this.recordings.length;
              }
              
              this.loadingRecordings = false;
            })
            .catch((e) => {
              // eslint-disable-next-line
              console.log(e)
              this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
              this.loadingRecordings = false;
            })
      })
    },
    
    selectedRecording() {
      try {
        let tRec = this.recordings.concat(this.recordingsOutgoing).filter(obj => obj.id === this.selectedRecID).pop();
        let tDesc = tRec.participants[tRec.participants.length - 1];
        let tSendObj = {
          id: tRec.id,
          from: tRec.fromDescription,
          to: tDesc
        }
        return tSendObj;
      } catch (e) {
        // eslint-disable-next-line
        return null
      }
    },
  },











  computed: {
    
    filteredCalls() {
      if (!this.blankString(this.searchStr)) {
        return this.recordings.reduce((list, item) => {
          let searchStr = `${item.fromDescription} ${item.fromNumber} ${item.participants.join('')}`.toLowerCase()
          
          let searchVal = this.searchStr;
          let searchVal2 = null;
          
          if (searchVal.substring(0, 1) == '0') {
            searchVal2 = `27${searchVal.substring(1, searchVal.length)}`
          }
          
          if (searchStr.includes(searchVal.toLowerCase()) || (searchVal2 != null ? searchStr.includes(searchVal2.toLowerCase()) : false)) {
            list.push(item);
          }
          return list
        }, [])
        
      } else {
        return this.recordings;
      }
    },
    
    filteredCallsOutgoing() {
      if (!this.blankString(this.searchStr)) {
        return this.recordingsOutgoing.reduce((list, item) => {
          let searchStr = `${item.fromDescription} ${item.fromNumber} ${item.participants.join('')}`
          
          let searchVal = this.searchStr;
          let searchVal2 = null;
          
          if (searchVal.substring(0, 1) == '0') {
            searchVal2 = `27${searchVal.substring(1, searchVal.length)}`
          }
          
          if (searchStr.includes(searchVal) || (searchVal2 != null ? searchStr.includes(searchVal2) : false)) {
            list.push(item);
          }
          return list
        }, [])
        
      } else {
        return this.recordingsOutgoing;
      }
    },
    
    extensions() {
      try {
        let tExts = JSON.parse(`[${this.userInfo.voice3CXExt}]`);
        
        if (tExts.length > 0) {
          return tExts;
        } else {
          return []
        }
      } catch (e) {
        // eslint-disable-next-line
        return []
      }
    }
  }
}
</script>

<style scoped>

</style>
