<template>
    <v-layout row align-center>
        <v-flex xs12>
            <template v-for="key in checkBoxes">

                <v-layout
                        row
                        align-center
                        class="py-1"
                >
                    <v-flex shrink>
                        <template v-if="key.user != null">
                            <v-tooltip top>
                                <v-avatar
                                        :color="key.user == userInfo.name + ' ' + userInfo.surname ? 'secondary' : 'primary'"
                                        slot="activator"
                                        :size="25"
                                >
                                    <span class="white--text caption">{{ getInitials(key.user) }}</span>
                                </v-avatar>
                                <span>{{ key.user + ' - ' + getLongDate(key.date) }}</span>
                            </v-tooltip>
                        </template>
                        <template v-else>
                            <v-tooltip top>
                                <v-avatar
                                        class="grey lighten-3"
                                        slot="activator"
                                        :size="25"
                                >
                                </v-avatar>
                                <span>Nothing Saved</span>
                            </v-tooltip>
                        </template>
                    </v-flex>
                    <v-flex xs12 class="pl-2">
                        <v-checkbox
                                color="primary"
                                :disabled="!intToBool(value.editable) || !stepEditable"
                                class="pa-0 ma-0"
                                hide-details
                                :indeterminate="key.user == null"
                                :label="key.key"
                                v-model="key.value"
                                @change="updateVal(key)"
                        >
                        </v-checkbox>
                    </v-flex>
                </v-layout>



                <!--<v-tooltip v-else top>-->
                    <!--<v-checkbox-->
                            <!--color="primary"-->
                            <!--slot="activator"-->
                            <!--class="pa-0 ma-0"-->
                            <!--hide-details-->
                            <!--:indeterminate="key.user == null"-->
                            <!--:label="key.key"-->
                            <!--v-model="key.value"-->
                            <!--@change="updateVal(key)"-->
                    <!--&gt;-->
                    <!--</v-checkbox>-->
                    <!--<span>{{ key.user + ' - ' + getLongDate(key.date) }}</span>-->
                <!--</v-tooltip>-->

            </template>

        </v-flex>
    </v-layout>
</template>

<script>

    import {codeBaseMixin} from "../../../../../../codeBaseMixin";

    export default {
        mixins:[codeBaseMixin],
        props: {
            value: Object,
            userInfo: Object,
            stepEditable: Boolean
        },
        data() {
            return {
                test: false,
                cbValues: []
            }
        },
        created() {
            if (this.value.value != null && this.value.value != '' && JSON.parse(this.value.value).length > 0) {
                this.cbValues = JSON.parse(this.value.value);
            }
        },
        methods: {
            updateVal(key) {
                this.cbValues.forEach(value => {
                    if (value.key == key.key) {
                        value.user = this.userInfo.name + ' ' + this.userInfo.surname;

                        let date = new Date();

                        value.date = date
                    }
                });

                this.value.value = this.saveValues;
            }
        },
        computed: {
            checkBoxes() {

                if (this.value.lookup != null && this.value.lookup != '' && JSON.parse(this.value.lookup).length > 0) {

                    JSON.parse(this.value.lookup).forEach(key => {

                        let bFound = false;

                        this.cbValues.forEach(value => {
                            if (value.key == key) {
                                if (!bFound) {
                                    bFound = true;
                                }
                            }
                        });

                        if (!bFound) {
                            this.cbValues.push({key: key, user: null, value: false, date: null})
                        }
                    });
                    return this.cbValues.sort((a,b) => a['key'] < b['key'] ? -1 : 1);
                } else {
                    return []
                }
            },
            saveValues() {
                let tValues = [];
                this.checkBoxes.forEach(value => {
                    if (value.user != null) {tValues.push(value)}
                });

                if (tValues.length > 0) {
                    return JSON.stringify(tValues)
                } else {
                   return null
                }
            }
        },

        watch: {
            value(newVal) {

            }
        }
    }
</script>

<style scoped>

</style>
