<template>
    <div class="fill-height scrollable">
        <div
                v-for="part in orderedParts"
                :key="part.type"
                class="fill-height"
        >
            <v-divider></v-divider>
            <document-part
                    :part="part"
                    :user-info="userInfo"
                    :select-lists="selectLists"
                    :datasets="datasets"
                    :steps="steps"
                    :fields="fields"
                    :signatures="signatures"
                    @removePart="removePart(part.type)"
                    :drop-all="dropAll"
                    :attachments="attachments"
            />
        </div>
<!--        <div class="text-xs-center pa-2" v-if="availableParts.length > 0">-->
<!--            <v-menu offset-y>-->
<!--                <template v-slot:activator="{ on }">-->
<!--                    <v-btn-->
<!--                            small-->
<!--                            class="ma-0"-->
<!--                            color="secondary"-->
<!--                            v-on="on"-->
<!--                    >-->
<!--                        <v-icon left>add</v-icon>-->
<!--                        Add Document Part-->
<!--                    </v-btn>-->
<!--                </template>-->
<!--                <v-list dense>-->
<!--                    <v-list-tile-->
<!--                            v-for="part in availableParts"-->
<!--                            @click="addPart(part.id)"-->
<!--                            :key="part.id"-->
<!--                    >-->
<!--                        <v-list-tile-title>{{ part.description }}</v-list-tile-title>-->
<!--                    </v-list-tile>-->
<!--                </v-list>-->
<!--            </v-menu>-->
<!--        </div>-->

    </div>
</template>

<script>
    import DocumentPart from "./DocumentPart";
    export default {
        components: {DocumentPart},
        props: {
            userInfo: Object,
            parts: Array,
            datasets: Array,
            fields: Array,
            steps: Array,
            selectLists: Array,
            signatures: Array,
            dropAll: Boolean,
            attachments: Array
        },
        data() {
            return {
                documentParts: [
                    {id: 0, description: 'Document'},
                    {id: 1, description: 'Header'},
                    {id: 2, description: 'Footer'},
                    {id: 3, description: 'First Page Header'},
                    {id: 4, description: 'First Page Footer'},
                    {id: 5, description: 'First Page'},
                    {id: 6, description: 'Last Page'},
                ]
            }
        },
        methods: {
            addPart(partID) {
                this.parts.push({type: partID, sections: [{html: null, rules: []}]});
            },
            removePart(partType) {
                this.parts.forEach((obj, i) => {
                    if (obj.type === partType) {
                        this.parts.splice(i, 1)
                    }
                })
            }
        },
        computed: {
            availableParts() {
                let arrAddedParts = this.parts.map(obj => {return obj.type});
                return this.documentParts.filter(obj => !arrAddedParts.includes(obj.id));
            },
            orderedParts() {
                let objRefTypes = {"1": 1, "3": 2, "5": 3, "4": 4, "0": 5, "6": 6, "2": 7};

                return this.parts.filter(() => true).sort((a,b) => objRefTypes[a.type] < objRefTypes[b.type] ? -1 : 1);
            }
        }

    }
</script>

<style scoped>

</style>
