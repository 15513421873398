<template>
    <v-dialog v-model="shownRelay" :width="700">
        <v-card flat>
            <v-card-title class="primary c-d-flex c-align-center pa-0 ma-0 px-2 py-1">
                <div class="subheading font-weight-light white--text pa-1 pl-2 c-flex-grow-1">Link Voip Recording</div>
                <v-text-field
                        v-model="searchStr"
                        hide-details
                        dark
                        color="white"
                        prepend-inner-icon="search"
                        placeholder="Search"
                        class="pa-0 ma-0"
                ></v-text-field>
                <v-btn
                        small
                        icon
                        flat
                        color="white"
                        class="pa-0 ma-0"
                        @click="$emit('update:shown', false)"
                >
                    <v-icon small>close</v-icon>
                </v-btn>
            </v-card-title>
            <v-divider/>

            <v-card-text class="c-d-flex c-align-center c-justify-center" v-if="loadingRecordings || linking">
                <v-progress-circular :size="40" :width="5" color="primary" indeterminate></v-progress-circular>
            </v-card-text>
            <v-card-text class="pa-0 ma-0" v-else style="max-height: 600px; overflow-y: auto; position: relative" >
                <v-data-table
                        :headers="headers"
                        :items="recordings"
                        class="elevation-1"
                        :pagination.sync="pageInfo"
                        :search="searchStr"
                >
                    <template v-slot:items="props">
                        <td>{{ props.item.date }}</td>
                        <td>{{ props.item.source }}</td>
                        <td>{{ props.item.destination }}</td>
                        <td class="text-xs-right">{{ props.item.duration }}</td>
                        <td class="text-xs-center">
                            <a target="_blank" :href="`http://life.bsitpbx.co.za:8080/recordings/${props.item.exportUrl}`" style="text-decoration: none">
                                <v-btn
                                        small
                                        icon
                                        flat
                                        color="primary"
                                        class="pa-0 ma-0"
                                >
                                    <v-icon>cloud_download</v-icon>
                                </v-btn>
                            </a>
                        </td>
                        <td class="text-xs-center">
                            <v-tooltip left>
                                <template v-slot:activator="{on}">
                                    <v-btn
                                            small
                                            icon
                                            flat
                                            color="primary"
                                            class="pa-0 ma-0"
                                            v-on="on"
                                            @click="linkToTask(props.item)"
                                    >
                                        <v-icon>link</v-icon>
                                    </v-btn>
                                </template>
                                Link To Task
                            </v-tooltip>
                        </td>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
    components: {},
    props: {
        shown: Boolean,
        selectedTask: Object
    },
    data() {
        return {
            loadingRecordings: false,
            recordings: [],
            headers: [
                { text: 'Date', value: 'date' },
                { text: 'Source', value: 'source', sortable: false},
                { text: 'Destination', value: 'destination', sortable: false },
                { text: 'Duration(s)', value: 'duration', sortable: true },
                { text: 'Download', value: null, sortable: false },
                { text: 'Link To Task', value: null, sortable: false },
            ],
            pageInfo: {
                rowsPerPage: 10,
                descending: true
            },
            searchStr: null,
            linking: false
        }
    },
    watch: {
        shown: {
            immediate: true,
            handler(val) {
                if (val)
                    this.getRecordings()
            }
        }
    },
    methods: {
        async getRecordings() {
            try {
                this.loadingRecordings = true;
                this.recordings = await this.$newReq('GET', `lifestyleVoip/recordings/${this.userInfo['link-10']}`)
                console.log({rec: this.recordings})
            } catch (e) {
                console.log(e)
                this.$snack.networkError()
            } finally {
                this.loadingRecordings = false
            }
        },
        async linkToTask(doc) {
            this.linking = true
            try {
                const linkedDoc = await this.$newReq('POST', `lifestyleVoip/linkToTask`, {
                    recording: doc,
                    taskID: this.selectedTask.taskID,
                    userID: this.userInfo.userID,
                });

                this.selectedTask.structure.documents.push(linkedDoc)
                this.$snack.info('Document Added')
                this.$emit('update:shown', false)
            } catch (e) {
                console.log(e)
                this.$snack.networkError();
            } finally {
                this.linking = false;
            }
        }
    },
    computed: {
        ...mapGetters('userInfo', ['userInfo']),
        shownRelay: {
            get() {
                return this.shown
            },
            set(val) {
                this.$emit('update:shown', val)
            }
        }
    },
}
</script>

<style scoped>

</style>