<template>
    <v-progress-circular
            :size="40"
            :width="3"
            color="primary"
            :indeterminate="value == null"
            :value="value"
    />
</template>

<script>
    export default {
        props: {
            value: {
                type: Number,
                default: null
            },
        },
    }
</script>

<style scoped>

</style>
