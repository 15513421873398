<template>
    <v-layout row align-center>

        <v-hover>
            <v-flex slot-scope="{ hover }" xs5 class="body-1">
                <v-layout row align-center>
                    <v-flex shrink>
                        {{field.description}}
                    </v-flex>
                    <v-flex v-if="parentUser == userInfo.userID && hover">
                        <v-tooltip top>
                            <v-btn
                                    small
                                    icon
                                    flat
                                    class="pa-0 ma-0"
                                    color="primary"
                                    @click="showRenameField = true"
                                    slot="activator"
                            >
                                <v-icon small>edit</v-icon>
                            </v-btn>
                            <span>Rename Field Description</span>
                        </v-tooltip>
                    </v-flex>
                </v-layout>
            </v-flex>
        </v-hover>

        <v-flex shrink class="pr-2">
            <template v-if="field.userID != null && field.userID != 0">
                <template v-for="user in users.filter(obj => obj.id == field.userID)">
                    <v-tooltip top>
                        <v-avatar
                                :color="field.userID == userInfo.userID ? 'secondary' : 'primary'"
                                slot="activator"
                                :size="25"
                                style="cursor: pointer"
                                @click="$emit('showHistory')"
                        >
                            <span class="white--text caption">{{ getInitials(`${user.name} ${user.surname}`) }}</span>
                        </v-avatar>
                        <span>{{ user.fullName + ' - ' + getLongDate(field.date) }}</span>
                    </v-tooltip>
                </template>
            </template>
            <template v-else>
                <v-tooltip top>
                    <v-avatar
                            class="grey lighten-3"
                            slot="activator"
                            :size="25"
                    >
                    </v-avatar>
                    <span>Nothing Saved</span>
                </v-tooltip>
            </template>

        </v-flex>
        <v-flex xs7>
            <value-tree-field-type1
                    v-if="field.type == 1"
                    :field="field"
                    @input="fieldChanged()"
                    :read-only="readOnly"
            />
            <value-tree-field-type2
                    v-if="field.type == 2"
                    :field="field"
                    @input="fieldChanged()"
                    :read-only="readOnly"
                    :edit-note-fn="editNoteFn"
            />
            <value-tree-field-type3
                    v-if="field.type == 3"
                    :field="field"
                    @input="fieldChanged()"
                    :read-only="readOnly"
            />
            <value-tree-field-type4
                    v-if="field.type == 4"
                    :field="field"
                    @input="fieldChanged()"
                    :read-only="readOnly"
            />
            <value-tree-field-type5
                    v-if="field.type == 5"
                    :field="field"
                    @input="fieldChanged()"
                    :read-only="readOnly"
            />
            <value-tree-field-type6
                    v-if="field.type == 6"
                    :field="field"
                    @input="fieldChanged()"
                    :read-only="readOnly"
                    :select-lists="selectLists"
            />
            <value-tree-field-type7
                    v-if="field.type == 7"
                    :field="field"
                    @input="fieldChanged()"
                    :read-only="readOnly"
            />
        </v-flex>

        <app-input-dlg
                :show-dialog="showRenameField"
                title="Rename Field"
                body="Change Field Description To?"
                :cancel-button="true"
                :show-input-box="true"
                :buttons="['Rename']"
                :call-backs="[ renameField ]"
                @dismiss="showRenameField = false"
                :default-input="field.description"
        />
    </v-layout>
</template>

<script>
    import ValueTreeFieldType1 from "./Fields/ValueTreeFieldType1";
    import {codeBaseMixin} from "../../../../../../codeBaseMixin";
    import ValueTreeFieldType2 from "./Fields/ValueTreeFieldType2";
    import ValueTreeFieldType3 from "./Fields/ValueTreeFieldType3";
    import ValueTreeFieldType4 from "./Fields/ValueTreeFieldType4";
    import ValueTreeFieldType5 from "./Fields/ValueTreeFieldType5";
    import ValueTreeFieldType7 from "./Fields/ValueTreeFieldType7";
    import ValueTreeFieldType6 from "./Fields/ValueTreeFieldType6";
    import InputDlg from "../../../../../../components/General/InputDlg";

    export default {
        mixins: [codeBaseMixin],
        components: {
            appInputDlg: InputDlg,
            ValueTreeFieldType6,
            ValueTreeFieldType7,
            ValueTreeFieldType5,
            ValueTreeFieldType4, ValueTreeFieldType3, ValueTreeFieldType2, ValueTreeFieldType1},
        props: {
            field: Object,
            users: Array,
            userInfo: Object,
            selectLists: {
                type: Array,
                default: () => {
                    return []
                }
            },
            readOnly: {
                default: false,
                type: Boolean
            },
            parentUser: Number,
            editNoteFn: Function
        },
        data() {
            return {
                showRenameField: false
            }
        },
        methods: {
            renameField(newName) {
                if (newName != null && newName != '') {
                    this.field.description = newName;
                    this.$emit('parentInput');
                    this.showRenameField = false;
                } else {
                    this.showSnack('Error', 'Field description can not be blank', 'Close', 'red')
                }
            },
            fieldChanged() {
                this.field.userID = this.userInfo.userID;
                this.field.date = new Date();
                this.$emit('input')
            }
        }
    }
</script>

<style scoped>

</style>
