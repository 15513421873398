var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fill-height scrollable pa-1"},[_c('v-card',{staticClass:"fill-height scrollable",staticStyle:{"overflow-x":"hidden"}},[_c('v-layout',{staticClass:"scrollable",attrs:{"column":"","fill-height":""}},[_c('v-flex',{style:({background: _vm.$store.state.darkTheme ? 'var(--primary-20p)' : 'var(--v-primary-base)'}),attrs:{"shrink":""}},[_c('v-layout',{attrs:{"row":"","align-center":""}},[_c('v-flex',{staticClass:"pa-2 body-2 white--text",attrs:{"xs12":""}},[_vm._v(" Escalations ")])],1)],1),_c('v-flex',{staticClass:"scrollable",staticStyle:{"overflow-x":"hidden"},attrs:{"xs12":""}},[_c('v-layout',{attrs:{"column":"","fill-height":""}},[_c('v-flex',{attrs:{"shrink":""}},[_c('full-task-filter',{attrs:{"tasks":_vm.escalationTasks,"state-filter":false,"filter-options":_vm.filterSettings},on:{"taskListUpdate":function($event){return _vm.taskListUpdate($event)},"settingsUpdated":function($event){return _vm.$emit('settingsUpdated', $event)}}})],1),_c('v-divider'),(_vm.filterSettings != null)?_c('v-flex',{staticClass:"scrollable",attrs:{"xs12":""}},[_c('virtual-list',{staticClass:"fill-height of-y",attrs:{"keeps":40,"estimate-size":60,"data-key":"refID","data-sources":_vm.displayTasks,"data-component":_vm.EscalationItem,"extra-props":{
                            droppedGroupings: _vm.droppedGroupings,
                            filterSettings: _vm.filterSettings,
                            toggleGrouping: _vm.toggleGrouping,
                            taskNav: _vm.taskNav,
                            escalations: _vm.escalations,
                            curMS: _vm.curMS,
                            removeEscalationRunStart: _vm.removeEscalationRunStart,
                          }}})],1):_vm._e()],1)],1)],1)],1),(_vm.showRemoveEscalation)?_c('app-input-dlg',{attrs:{"show-dialog":_vm.showRemoveEscalation,"title":"Hide Escalation","body":`Are you sure that you would like to hide the escalation for task: ${_vm.removeEscalationTask.title}. Please Note this action will hide the escalation for you permanently.`,"cancel-button":true,"show-input-box":false,"buttons":['Remove'],"call-backs":[ _vm.removeEscalation ]},on:{"dismiss":function($event){_vm.removeEscalationTask.cancelLoadFn(); _vm.showRemoveEscalation = false}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }