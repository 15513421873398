<template>
    <div>
        <v-btn small flat color="secondary" class="ma-0" @click="showDialog = true">Configure Document Template</v-btn>

    <v-dialog
            v-if="showDialog"
            v-model="showDialog"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
            persistent
    >
        <v-card flat height="100vh" v-if="showDialog">

            <v-layout column fill-height >
<!--Card Title-->
                <v-flex shrink primary>
                    <v-card-title class="py-2 pr-2 ">

                        <v-icon class="white--text">description</v-icon>
                        <span class="title font-weight-light white--text pl-2">Document Editor</span>
                        <v-spacer></v-spacer>
                        <v-btn flat icon color="white" class="pa-0 ma-0" @click="showInfo = true">
                            <v-icon>info</v-icon>
                        </v-btn>
                        <input-dlg
                                :show-dialog="showInfo"
                                title="Extra Variables"
                                :cancel-button="true"
                                :show-input-box="false"
                                @dismiss="showInfo = false"
                                :body-padding="false"
                        >
                            <template #body>
                                <div class="py-2">
                                    <v-layout row align-center justify-space-between class="py-2 px-4 hover-item" @click="copy('{{taskId}}')">
                                        <v-flex shrink style="white-space: nowrap">Task Task ID</v-flex>
                                        <v-flex shrink style="white-space: nowrap">
                                            <template v-pre>{{taskId}}</template>
                                            <v-btn
                                                    icon
                                                    small
                                                    color="primary"
                                                    flat
                                                    class="ma-0 ml-2"
                                            >
                                                <v-icon small>content_copy</v-icon>
                                            </v-btn>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row align-center justify-space-between class="py-2 px-4 hover-item" @click="copy('{{title}}')">
                                        <v-flex shrink style="white-space: nowrap">Task Title</v-flex>
                                        <v-flex shrink style="white-space: nowrap">
                                            <template v-pre>{{title}}</template>
                                            <v-btn
                                                    icon
                                                    small
                                                    color="primary"
                                                    flat
                                                    class="ma-0 ml-2"
                                            >
                                                <v-icon small>content_copy</v-icon>
                                            </v-btn>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row align-center justify-space-between class="py-2 px-4 hover-item" @click="copy('{{created}}')">
                                        <v-flex shrink style="white-space: nowrap">Task Created</v-flex>
                                        <v-flex shrink style="white-space: nowrap">
                                            <template v-pre>{{created}}</template>
                                            <v-btn
                                                    icon
                                                    small
                                                    color="primary"
                                                    flat
                                                    class="ma-0 ml-2"
                                            >
                                                <v-icon small>content_copy</v-icon>
                                            </v-btn>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row align-center justify-space-between class="py-2 px-4 hover-item" @click="copy('{{date}}')">
                                        <v-flex shrink style="white-space: nowrap">Current Date</v-flex>
                                        <v-flex shrink style="white-space: nowrap">
                                            <template v-pre>{{date}}</template>
                                            <v-btn
                                                    icon
                                                    small
                                                    color="primary"
                                                    flat
                                                    class="ma-0 ml-2"
                                            >
                                                <v-icon small>content_copy</v-icon>
                                            </v-btn>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row align-center justify-space-between class="py-2 px-4 hover-item" @click="copy('{{folder}}')">
                                        <v-flex shrink style="white-space: nowrap">Task Folder</v-flex>
                                        <v-flex shrink style="white-space: nowrap">
                                            <template v-pre>{{folder}}</template>
                                            <v-btn
                                                    icon
                                                    small
                                                    color="primary"
                                                    flat
                                                    class="ma-0 ml-2"
                                            >
                                                <v-icon small>content_copy</v-icon>
                                            </v-btn>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row align-center justify-space-between class="py-2 px-4 hover-item" @click="copy('{{user}}')">
                                        <v-flex shrink style="white-space: nowrap">Task Owner</v-flex>
                                        <v-flex shrink style="white-space: nowrap">
                                            <template v-pre>{{user}}</template>
                                            <v-btn
                                                    icon
                                                    small
                                                    color="primary"
                                                    flat
                                                    class="ma-0 ml-2"
                                            >
                                                <v-icon small>content_copy</v-icon>
                                            </v-btn>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row align-center justify-space-between class="py-2 px-4 hover-item" @click="copy('{{subUser}}')">
                                        <v-flex shrink style="white-space: nowrap">Task Sub Owner</v-flex>
                                        <v-flex shrink style="white-space: nowrap">
                                            <template v-pre>{{subUser}}</template>
                                            <v-btn
                                                    icon
                                                    small
                                                    color="primary"
                                                    flat
                                                    class="ma-0 ml-2"
                                            >
                                                <v-icon small>content_copy</v-icon>
                                            </v-btn>
                                        </v-flex>
                                    </v-layout>
                                </div>
                            </template>
                        </input-dlg>
                        <v-btn small icon flat color="white" class="pa-0 ma-0" @click="dropAll = !dropAll"><v-icon>{{dropAll ? 'arrow_drop_up' : 'arrow_drop_down'}}</v-icon></v-btn>
                        <v-btn flat icon color="white" class="pa-0 ma-0" @click="showDialog = false">
                            <v-icon>close</v-icon>
                        </v-btn>
                    </v-card-title>
                </v-flex>

                <template v-if="!loadingDocument">
<!--Tabs-->
                    <v-flex shrink>
                        <v-tabs
                                v-model="viewOption"
                                color="primary"
                                slider-color="secondary"
                                grow
                                show-arrows
                        >
                            <v-tab class="white--text">Document</v-tab>
                            <v-tab class="white--text">Signatures</v-tab>
                        </v-tabs>
                    </v-flex>
                    <v-flex xs12 class="scrollable">
                        <document-parts
                                v-if="viewOption === 0"
                                :parts="template.parts"
                                :user-info="userInfo"
                                :select-lists="selectLists"
                                :datasets="datasets"
                                :steps="steps"
                                :fields="fields"
                                :signatures="template.signatures"
                                :dropAll="dropAll"
                                :attachments="template.attachments"
                        />
                        <document-signatures
                                v-if="viewOption === 1"
                                :user-info="userInfo"
                                :signatures="template.signatures"
                                :datasets="datasets"
                                :steps="steps"
                                :fields="fields"
                        />
                    </v-flex>
                </template>
<!--Loading-->
                <div class="pa-4 text-xs-center" v-else>
                    <v-progress-circular
                            :size="40"
                            :width="5"
                            color="primary"
                            indeterminate
                    />
                </div>

                <div
                        style="position: absolute; bottom: 0; right: 0"
                        class="pa-2"
                >
                    <v-btn
                            icon
                            large
                            color="secondary"
                            @click="saveRun()"
                            :disabled="saving"
                            :loading="saving"
                    >
                        <v-icon>save</v-icon>
                    </v-btn>
                </div>

            </v-layout>
        </v-card>
    </v-dialog>
    </div>
</template>

<script>

    import {codeBaseMixin} from "../../../../../codeBaseMixin";
    import DocumentSignatures from './Signatures/DocumentSignatures';
    import DocumentParts from './Parts/DocumentParts';
    import {blankString, copyText} from "../../../../../codeFunctions";
    import InputDlg from "@/components/General/InputDlg";

    export default {
        components: {
            InputDlg,
            DocumentParts,
            DocumentSignatures,
        },
        mixins: [codeBaseMixin],
        props: {
            userInfo: Object,
            value: [String, Number],
            datasets: Array,
            fields: Array,
            steps: Array,
            selectLists: Array
        },
        data() {
            return {
                showDialog: false,
                template: {
                    signatures: [],
                    parts: [
                        {
                            type: 0,
                            sections: [{html: null, rules: [], description: null}]
                        }
                    ],
                    attachments: []
                },
                loadingDocument: false,
                viewOption: 0,
                dropAll: true,
                saving: false,
                showInfo: false
            }
        },

        watch: {
            showDialog(shown) {
                if (shown) {

                    if (blankString(this.value)) {
                        this.template = {
                            id: 0,
                            signatures: [],
                            parts: [
                                {
                                    type: 0,
                                    sections: [{html: null, rules: [], description: null}]
                                }
                            ],
                            attachments: []
                        }
                    } else {
                        this.loadTemplate(this.value)
                    }

                }
            }
        },
        methods: {

            copy(text) {
                copyText(document, text);
                this.$snack.info(`${text} Copied`);
            },

            loadTemplate(templateID) {
                this.loadingDocument = true;
                this.fetch({method: 'GET', url:`/dtManage/getTemplate/${this.userInfo.entityID}/${templateID}`})
                    .then((data) => {
                        // console.log(JSON.parse(data))

                        this.template = data
                        this.loadingDocument = false;
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e)
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
                        this.loadingDocument = false;
                        this.showDialog = false;
                    })
            },

            saveRun() {
                this.saving = true;
                this.fetch({method: 'PATCH', url:`/dtManage/template/${this.userInfo.entityID}/${this.template.id}`, body: JSON.stringify(this.template)})
                    .then((data) => {
                        this.template.id = data.templateID;
                        this.$emit('input', `${data.templateID}`);
                        this.saving = false;
                        // this.showDialog = false;
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e)
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
                        // this.saving = false;
                    })
            }

        },
        computed: {

        }
    }
</script>

<style scoped>

</style>
