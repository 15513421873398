<template>
    <div>

        <input-dlg
                :show-dialog="shownRelay"
                title="Manage Send As"
                :cancel-button="true"
                :show-input-box="false"
                @dismiss="shownRelay = false"
                :body-padding="false"
        >
            <template slot="body">
                <div v-if="loading" class="c-d-flex c-align-center c-justify-center" style="height: 100px">
                    <v-progress-circular
                            :size="40"
                            :width="5"
                            color="primary"
                            indeterminate
                    ></v-progress-circular>
                </div>

                <div v-else style="max-height: 400px">

                    <div v-if="!sendAsAddresses.length" class="grey--text caption font-weight-bold text-sm-center pa-3">
                        No Addresses Found
                    </div>

                    <v-list dense>
                        <v-list-tile v-for="addr in sendAsAddresses" @click="() => {}">
                            <v-list-tile-content>
                                <v-list-tile>{{addr}}</v-list-tile>
                            </v-list-tile-content>
                            <v-list-tile-action>
                                <v-btn
                                        icon
                                        color="error"
                                        flat
                                        small
                                        @click="arrSplice(sendAsAddresses, (address) => address === addr)"
                                >
                                    <v-icon>delete</v-icon>
                                </v-btn>
                            </v-list-tile-action>
                        </v-list-tile>
                    </v-list>
                </div>
            </template>
            <template #actions>
                <v-btn
                        :disabled="loading"
                        @click="showAddAddress = true"
                        small
                        color="secondary"
                        flat
                >
                    Add Address
                </v-btn>

                <v-btn
                        @click="saveChanges()"
                        small
                        color="secondary"
                        :disabled="loading"
                        :loading="loading"
                >
                    Save Changes
                </v-btn>
            </template>
        </input-dlg>

        <input-dlg
                v-if="showAddAddress"
                :show-dialog="showAddAddress"
                title="Add Send As"
                :body="`Please provide a new send as email address`"
                :cancel-button="true"
                :show-input-box="true"
                @dismiss="showAddAddress = false"
                :buttons="['Add']"
                :call-backs="[testAddress]"
        ></input-dlg>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import InputDlg from "@/components/General/InputDlg";
import {validationRules, arrSplice} from "../../../codeFunctions";

export default {
    components: {InputDlg},
    props: {
        userId: {
            type: Number,
            default: 0
        },
        shown: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            loading: false,
            sendAsAddresses: [],
            addAddressStr: null,
            showAddAddress: false,
            validationRules,
            arrSplice
        }
    },
    watch: {
        shown:{
            immediate: true,
            handler(val) {
                if (val) {
                    this.loading = false;
                    this.sendAsAddresses = [];
                    this.addAddressStr = null;
                    this.showAddAddress = false;
                    this.init();
                }
            }
        },
        showAddAddress(val) {
            if (val)
                this.addAddressStr = '';
        }
    },
    methods: {
        async testAddress(address) {

            if (this.sendAsAddresses.includes(address))
                return this.$snack.error('Address already added')
            if (!valid && validationRules.email(address) !== true)
                return this.$snack.error('Invalid email address')

            this.loading = true;
            this.showAddAddress = false
            const {valid} = await this.$newReq("POST", `users/officeSendAsTest/${this.userId}`, {address: address})

            if (!valid) {
                this.$snack.error("Access Denied")
                this.showAddAddress = false;
                this.loading = false;
            } else {
                this.sendAsAddresses.push(address);
                this.showAddAddress = false;
                this.loading = false;
                this.saveChanges(false);
            }
        },

        async init() {
            try {
                this.loading = true
                this.sendAsAddresses = await this.$newReq("GET", `users/officeSendAs/${this.userInfo.entityID}/${this.userId}`)
            } catch (e) {
                console.log(e)
                this.$snack.networkError();
            } finally {
                this.loading = false;
            }
        },

        async saveChanges(closeDlg = true) {
            try {
                this.loading = true
                await this.$newReq("POST", `users/officeSendAs/${this.userInfo.entityID}/${this.userId}`, this.sendAsAddresses)
                if (closeDlg)
                    this.shownRelay = false;
            } catch (e) {
                console.log(e)
                this.$snack.networkError();
            } finally {
                this.loading = false;
            }
        }
    },

    computed: {
        ...mapGetters("userInfo", ["userInfo"]),
        shownRelay: {
            get() {
                return this.shown
            },
            set(val) {
                this.$emit('update:shown', val)
            }
        }
    },
}
</script>

<style scoped>

</style>