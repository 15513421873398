<template>
    <v-dialog
            v-model="showDialog"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
            persistent
    >
        <v-card flat height="100vh">

            <v-layout column fill-height >
                <v-flex shrink title-color>
<!--Card Title-->
                    <v-card-title class="py-1 pr-2 ">

                        <img src="../../../public/workflows.svg" width="42px" class="pr-2"/>
                        <span class="title font-weight-light white--text">{{selectedWorkflow == null ? "Workflow Manager" : selectedWorkflow.description}}</span>
                        <v-spacer></v-spacer>
                        <v-btn flat icon color="white" class="pa-0 ma-0" @click="$emit('dismiss', false)">
                            <v-icon>close</v-icon>
                        </v-btn>
                    </v-card-title>
<!--Select Workflow And Tabs-->
                    <v-layout row align-center>
<!--Show Hide Workflow-->
                        <v-flex xs12 class="px-2">
                            <v-layout row align-center>
                                <v-flex xs4>
                                    <v-btn
                                            class="ma-0"
                                            color="white"
                                            @click="smallSelectWorkflow = !smallSelectWorkflow"
                                            flat
                                    >
                                        <v-icon left>list</v-icon>
                                        Select Workflow
                                    </v-btn>
                                </v-flex>
                                <!--Selected Workflow Tabs-->
                                <v-flex xs12 v-if="selectedWorkflow != null">
                                    <v-tabs
                                            v-model="selectedWorkflowViewOption"
                                            color="primary"
                                            slider-color="secondary"
                                            grow
                                            show-arrows
                                    >
                                        <v-tab class="white--text">Workflow</v-tab>
                                        <v-tab class="white--text">Rules</v-tab>
                                        <v-tab class="white--text">Security</v-tab>
                                        <v-tab class="white--text">Manage</v-tab>
                                    </v-tabs>
                                </v-flex>

                            </v-layout>

                        </v-flex>

                    </v-layout>
                    <v-divider></v-divider>
                </v-flex>
<!--Loading-->
                <v-flex shrink class="pt-3 text-xs-center" v-if="initialLoad">
                    <v-progress-circular
                            :size="40"
                            :width="5"
                            color="primary"
                            indeterminate
                    ></v-progress-circular>
                </v-flex>

                <template v-else>

                    <v-flex xs12 style="overflow-y: auto">
                        <v-layout row fill-height>
<!--Workflow Select-->
                            <v-flex xs4 style="overflow-y: auto" class="pl-1 py-1 pr-0" v-show="!smallSelectWorkflow">
                                <v-card class="fill-height" style="overflow-y: auto">
                                    <v-layout column fill-height>
                                        <v-flex shrink>
                                            <v-card-title class="white--text pa-0 ma-0 title-color">
                                                <div class="pa-2">Select Workflow</div>
                                            </v-card-title>
                                        </v-flex>



                                        <v-flex xs12 style="overflow-y: auto" v-if="!smallSelectWorkflow">
                                            <app-workflow-select
                                                    v-model="selectedWorkflow"
                                                    :user-info="userInfo"
                                                    :workflows-loaded="workflowsLoaded"
                                                    :workflows="workflows"
                                            />
                                        </v-flex>
                                    </v-layout>
                                </v-card>

                            </v-flex>
<!--New Workflow View-->
                            <v-flex xs12 v-if="selectedWorkflow != null" style="overflow-x: auto; overflow-y: auto">
                                <app-workflow-manager
                                        :user-info="userInfo"
                                        :selected-workflow="selectedWorkflow"
                                        :workflow-view-option="selectedWorkflowViewOption"
                                        :folder-groups="folderGroups"
                                        :show-template-editor-fn="showTemplateEditorFn"
                                        @workflowViewOption="selectedWorkflowViewOption = $event"
                                        :local-data="localData"
                                        :show-sync="showSync"
                                        :get-task-header="getTaskHeader"
                                >
                                </app-workflow-manager>
                            </v-flex>
<!--Old Workflow View-->
<!--                            <v-flex xs12 style="overflow-y: auto" v-if="selectedWorkflow != null">-->
<!--                                <app-workflow-view-->
<!--                                        :user-info="userInfo"-->
<!--                                        :workflow="selectedWorkflow"-->
<!--                                        @workflowRenamed="workflowRenamed"-->
<!--                                        @hideParent="$emit('dismiss', false)"-->
<!--                                        :show-template-editor-fn="showTemplateEditorFn"-->
<!--                                        :workflow-view-option="selectedWorkflowViewOption"-->
<!--                                />-->
<!--                            </v-flex>-->
                        </v-layout>
                    </v-flex>
                </template>
            </v-layout>
        </v-card>

        <app-configure-mail-template-dlg
                :shown="showTemplateEditorDialog"
                @dismiss="templateEditorDismissed($event)"
                :user-info="userInfo"
                :fields="MTEditorAllFields"
                :steps="MTEditorAllSteps"
                :field="MTEditorFieldLookup"
                :field-sets="MTEditorFieldSets"
        />

        <!--@input="saveTemplateChanges"-->

    </v-dialog>
</template>

<script>
    import { eventBus } from "../../main";
    import { codeBaseMixin } from "../../codeBaseMixin";
    import WorkflowSelect from './Components/WorkflowSelect/WorkflowSelect';
    import ConfigureMailTemplateDlg from './Components/WorkflowManager/ConfigureMailTemplateDlg';
    import WorkflowManager from "./Components/WorkflowManager/WorkflowManager";


    export default {
        mixins: [ codeBaseMixin ],
        components: {
            appWorkflowManager: WorkflowManager,
            appWorkflowSelect: WorkflowSelect,
            appConfigureMailTemplateDlg: ConfigureMailTemplateDlg,
        },
        props: {
            showDialog: Boolean,
            userInfo: Object,
            folderGroups: Array,
            localData: Object,
            showSync: Boolean,
            getTaskHeader: Function,
        },
        data() {
            return {
                initRan: false,
                initialLoad: true,

                workflows: null,
                workflowsLoaded: false,
                selectedWorkflow: null,

                smallSelectWorkflow: false,

                MTEditorAllFields: [],
                MTEditorAllSteps: [],
                MTEditorFieldLookup: null,
                MTEditorFieldSets: [],
                showTemplateEditorDialog: false,
                saveTemplateChanges: false,

                selectedWorkflowViewOption: 0
            }
        },
        methods: {

            // renameWorkflow(renameWorkflow) {
            //     this.workflows.forEach(workflow => {
            //         if (workflow.id == renameWorkflow.id) {
            //             workflow.description = renameWorkflow.description;
            //         }
            //     })
            // },

            showTemplateEditorFn(data) {
                return new Promise((resolve, reject) => {
                    this.MTEditorAllFields = data.allFields;
                    this.MTEditorAllSteps = data.allSteps;
                    this.MTEditorFieldLookup = data.fieldLookup;
                    this.MTEditorFieldSets = data.fieldSets;
                    this.showTemplateEditorDialog = true;

                    let checkID = setInterval(() => {
                        if (!this.showTemplateEditorDialog) {
                            clearInterval(checkID);
                            switch (this.saveTemplateChanges) {
                                case true: {
                                    resolve(this.MTEditorFieldLookup);
                                    break;
                                }
                                case false: {
                                    reject();
                                    break
                                }
                            }
                        }
                    }, 200);
                })
            },

            templateEditorDismissed(save) {
                this.saveTemplateChanges = save;
                this.showTemplateEditorDialog = false;
            },

            workflowRenamed(data) {
                this.workflows.workflows.forEach(workflow => {
                    if (workflow.id == data.id) {
                        workflow.displayName = data.description
                    }
                })
            },

            initEditor() {
                if (this.userInfo != null && typeof this.userInfo.userID != 'undefined') {
                    this.initialLoad = true;
                    this.initRan = true;
                    this.getWorkflows();
                } else {
                    setTimeout(() => {this.initEditor()}, 200)
                }
            },

            getWorkflows() {
                this.fetch({method: 'GET', url:`/workflows/workflowTreeSeperateAdmin/${this.userInfo.entityID}`})
                    .then((data) => {
                        let jData = data;
                        jData.workspaces.forEach((workspace) => {workspace.dropped = false});
                        jData.workflowGroups.forEach((workflowGroup) => {workflowGroup.dropped = false});
                        jData.workflows.forEach((workflow) => {
                            workflow.selected = this.selectedWorkflow != null && this.value != null && this.value.id == workflow.id;
                        });

                        this.workflows = jData;
                        this.workflowsLoaded = true;
                        this.initialLoad = false;
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e)
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                    })
            }
        },

        watch: {
            showDialog(value) {
                if (value == true && this.initRan == false) {
                    this.initEditor()
                }
            },
            selectedWorkflow(value) {
                if (value != null) {
                    this.smallSelectWorkflow = true
                }
            }

        }
    }
</script>

<style scoped>
    .selected {
        background: #efefef;
    }

</style>
