import {newReq} from "@/axiosRequest";

const EventEmitter      = require('events');
let emitter             = new EventEmitter();
const store = require('@/Store/store')


async function initCalendar() {
    try {
        let userInfo = store.default.getters['userInfo/userInfo']
        // Get Groups And Data
        const startupData = await newReq('GET', `cal/event/userStartup/${userInfo.entityID}/${userInfo.userID}`)
        store.default.dispatch('cal/upsert', startupData)
        // Get Dashboard Data
        const eventData = await newReq('GET', `cal/event/userStartup/dashboard/${userInfo.entityID}/${userInfo.userID}`)
        store.default.dispatch('cal/upsert', {events: eventData})
    } catch (e) {
        console.log(e)
    }
}


function openCalendarEvent(event, inEdit = false) {
    console.log("OPEN Called")
    emitter.emit(!inEdit ? 'openEvent' : 'openEventEdit', event)
}

function createCalendarEvent(eventConfig, callBack = null) {
    console.log(eventConfig)
    emitter.emit('createEvent', {eventConfig, callBack})
}

const calEventOpenEvents = emitter;

export {
    calEventOpenEvents,
    openCalendarEvent,
    createCalendarEvent,
    initCalendar
}

