<template>
  
  <div class="hover-open-parent">
    <v-layout row wrap class="hover-open" id="stepsbar" @mouseleave="scrollStep()" ref="stepsbar"
              @dragenter="addDroppedBarClass()"
              @drop="removeDroppedBarClass()"
    >
      <!--Split Task-->
      <v-flex class="pa-1" shrink v-if="resetStepView && task.fk_source_task != null && task.fk_source_task != 0">
        
        <v-menu left offset-x :nudge-left="5" v-model="splitTaskCardMenu">
          <template v-slot:activator="{ on }">
            <v-card
                :hover="true"
                ripple
                color="primary"
                style="border: 1px solid var(--v-primary-base); z-index: 99"
                v-on="on"
                @click="$emit('sourceSelected')"
                @dragover="allowDrop"
                @drop="dropSrc($event)"
            >
              <v-layout row align-center>
                <v-flex shrink class="pl-2">
                  <v-icon
                      small
                      class="white--text"
                  >
                    assignment_turned_in
                  </v-icon>
                
                </v-flex>
                <v-flex shrink>
                  <div class="px-2">
                    <span class="caption font-weight-regular white--text">{{ 'Task ' + task.fk_source_task }}</span>
                  </div>
                </v-flex>
              </v-layout>
            </v-card>
          </template>
          
          <template #default>
            <v-card width="350" style="z-index: 999; min-height: 75px; cursor: pointer"
                    class="c-d-flex c-align-center c-justify-center">
              <v-scroll-x-transition hide-on-leave>
                <v-progress-circular
                    v-if="loadingSplitTask"
                    :size="40"
                    :width="5"
                    color="primary"
                    indeterminate
                />
              </v-scroll-x-transition>
              <v-scroll-x-transition hide-on-leave>
                <div class="fill-height c-flex-grow-1" v-if="!loadingSplitTask && splitTaskHeader">
                  <task-container
                      :userInfo="userInfo"
                      :task="splitTaskHeader"
                      :taskBar="false"
                      @taskSelected="taskSelected(splitTaskHeader)"
                  >
                  </task-container>
                </div>
              </v-scroll-x-transition>
            
            </v-card>
          </template>
        </v-menu>
        
        <!--                <v-tooltip top>-->
        <!--                    <template v-slot:activator="{ on }">-->
        <!--                        <v-card-->
        <!--                            :hover="true"-->
        <!--                            ripple-->
        <!--                            color="primary"-->
        <!--                            style="border: 1px solid var(&#45;&#45;v-primary-base); z-index: 99"-->
        <!--                            v-on="on"-->
        <!--                            @click="$emit('sourceSelected')"-->
        <!--                            @contextmenu="openSrcTask($event)"-->
        <!--                            @dragover="allowDrop"-->
        <!--                            @drop="dropSrc($event)"-->
        <!--                        >-->
        <!--                            <v-layout row align-center>-->
        <!--                                <v-flex shrink class="pl-2">-->
        <!--                                    <v-icon-->
        <!--                                        small-->
        <!--                                        class="white&#45;&#45;text"-->
        <!--                                    >-->
        <!--                                        assignment_turned_in-->
        <!--                                    </v-icon>-->
        
        <!--                                </v-flex>-->
        <!--                                <v-flex shrink>-->
        <!--                                    <div class="px-2" >-->
        <!--                                        <span class="caption font-weight-regular white&#45;&#45;text" >{{'Task ' + task.fk_source_task}}</span>-->
        <!--                                    </div>-->
        <!--                                </v-flex>-->
        <!--                            </v-layout>-->
        <!--                        </v-card>-->
        <!--                    </template>-->
        <!--                    Left click to open step, Right click to open task-->
        <!--                </v-tooltip>-->
      </v-flex>
      <!--Step Headers-->
      <template v-for="step in removedEmptySteps">
        <v-flex v-if="resetStepView" :key="'Step' + step.stepID" class="pa-1" shrink :id="`stephead${step.stepID}`">
          
          <v-card
              style="border-radius: 3px"
              :hover="true"
              :elevation="step.stepID == selectedStep.stepID ? 5 : 0"
              @click="selectStep(step)"
              ripple
              :class="step.shown ? getClass(step) : getClassHidden(step)"
              @dragover="allowDrop"
              @drop="drop($event, step.stepID)"
          >
            <!--:class="getClass(step)"-->
            <v-layout row align-center>
              <v-flex shrink v-if="!step.editable || !step.shown" class="pl-2">
                <v-tooltip top>
                  <v-icon
                      small
                      :class="step.shown ? 'white--text' : getClassIcon(step)"
                      slot="activator"
                  >
                    security
                  </v-icon>
                  <span>{{ !step.shown ? 'This Step Has Been Hidden' : 'Read Only Step' }}</span>
                </v-tooltip>
              
              </v-flex>
              <v-flex shrink>
                
                <!--                                <template v-if=" (!!doneSteps && !!doneSteps[step.stepID] && doneSteps[step.stepID].required && step.stepConfig && step.stepConfig.collapseNameOnceComplete === 1)">-->
                <!--                                    done-->
                <!--                                </template>-->
                
                <!--                                <template v-if="">-->
                <!--                                    done all-->
                <!--                                </template>-->
                <div class="pl-2"
                     v-if="(!!doneSteps && !!doneSteps[step.stepID] && doneSteps[step.stepID].all && step.stepConfig && step.stepConfig.collapseNameOnceComplete === 2) || (!!doneSteps && !!doneSteps[step.stepID] && doneSteps[step.stepID].required && step.stepConfig && step.stepConfig.collapseNameOnceComplete === 1)">
                
                </div>
                <div v-else class="px-2">
                  <span class="caption font-weight-regular" style="white-space: nowrap">{{
                      step.stepConfig && step.stepConfig.alias ? refFields[step.stepConfig.alias].oldValue || step.description : step.description
                    }}</span>
                </div>
              
              </v-flex>
              <v-flex shrink v-if="step.notifications" class="pr-1">
                <v-icon
                    
                    small
                    color="yellow"
                >
                  <!--:class="step.shown ? 'white&#45;&#45;text' : getClassIcon(step)"-->
                  announcement
                </v-icon>
              </v-flex>
              <v-flex shrink
                      v-if="!!doneSteps && !!doneSteps[step.stepID] && (doneSteps[step.stepID].required || doneSteps[step.stepID].all)"
                      class="pr-2">
                <v-tooltip top>
                  <v-icon
                      small
                      :class="step.shown ? 'white--text' : getClassIcon(step)"
                      slot="activator"
                  >
                    {{ doneSteps[step.stepID].all ? 'done_all' : 'done' }}
                  </v-icon>
                  <span>{{
                      !((!!doneSteps && !!doneSteps[step.stepID] && doneSteps[step.stepID].all && step.stepConfig && step.stepConfig.collapseNameOnceComplete === 2) || (!!doneSteps && !!doneSteps[step.stepID] && doneSteps[step.stepID].required && step.stepConfig && step.stepConfig.collapseNameOnceComplete === 1)) ? (doneSteps[step.stepID].all ? 'All Fields Complete' : 'All Required Fields Complete') : (step.stepConfig && step.stepConfig.alias ? refFields[step.stepConfig.alias].oldValue || step.description : step.description)
                    }}</span>
                </v-tooltip>
              </v-flex>
            </v-layout>
          
          </v-card>
        
        </v-flex>
      
      </template>
      
      <template>
        <v-flex v-if="resetStepView" key="Step-1" class="pa-1" shrink :id="`stephead-1`">
          <v-card
              :hover="true"
              :elevation="-1 === selectedStep.stepID ? 5 : 0"
              @click="selectStep({stepID: -1})"
              ripple
              class="flat"
              style="background: rgba(0,0,0,0) !important;"
              @dragover="allowDrop"
              @drop="drop($event, -1)"
          >
            <!--:class="getClass(step)"-->
            <v-layout row align-center>
              <v-flex shrink class="px-1" style="padding-top: 1px; padding-bottom: 1px">
                <v-tooltip top>
                  <v-icon
                      small
                      class="error--text"
                      slot="activator"
                  >
                    delete
                  </v-icon>
                  <span>Recycle Bin</span>
                </v-tooltip>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
      </template>
      
      <app-task-bar-container-drop-step-select-dlg
          :user-info="userInfo"
          :source-task-i-d="task.fk_source_task"
          :use-task="true"
          :show-dialog="showCopy"
          @dismiss="showCopy = false"
          @input="copyToTaskStep($event)"
      />
    </v-layout>
  </div>


</template>

<script>

import {eventBus} from '../../../main';
import {codeBaseMixin} from "../../../codeBaseMixin";
import SplitTaskContainer
  from '../components/TaskViewDetail/Components/FieldTypeComponents/Split Task Components/SplitTaskContainer';
import TaskBarContainerDropStepSelectDlg from '../../../components/General/TaskBarContainerDropStepSelectDlg';
import VueScrollTo from 'vue-scrollto';
import {objectifyArr} from "@/codeFunctions";
import TaskContainer from "@/components/TaskContainer/TaskContainer";

export default {
  mixins: [codeBaseMixin],
  props: {
    task: Object,
    userInfo: Object,
    selectedStep: Object,
    folderGroups: Array,
    hiddenSteps: Array,
    getTaskHeader: Function,
  },
  components: {
    TaskContainer,
    appSplitTaskContainer: SplitTaskContainer,
    appTaskBarContainerDropStepSelectDlg: TaskBarContainerDropStepSelectDlg
  },
  data() {
    return {
      resetStepView: true,
      dragData: {
        type: '',
        data: {}
      },
      showCopy: false,
      stepCounts: [],
      doneSteps: null,
      loadingSplitTask: false,
      splitTaskHeader: null,
      splitTaskCardMenu: false
    }
  },
  watch: {
    selectedStep: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val != null) {
          this.scrollStep(val.stepID)
        }
      }
    },
    splitTaskCardMenu(val) {
      if (val && !this.splitTaskHeader && !this.loadingSplitTask) {
        this.loadSplitTask();
      }
    }
  },
  created() {
    eventBus.$on('stepCountReran', this.stepCountReran);
    eventBus.$on('doneStepsReran', this.doneStepsReran);
    eventBus.$on('mailDrag', this.mailDrag);
    eventBus.$on('attachmentDrag', this.attachmentDrag);
    eventBus.$on('taskDocumentDrag', this.taskDocumentDrag);
    eventBus.$on('noteDrag', this.noteDrag);
    eventBus.$on('SRCmailDrag', this.SRCmailDrag);
    eventBus.$on('SRCtaskDocumentDrag', this.SRCtaskDocumentDrag);
  },
  beforeDestroy() {
    eventBus.$off('stepCountReran', this.stepCountReran);
    eventBus.$off('doneStepsReran', this.doneStepsReran);
    eventBus.$off('mailDrag', this.mailDrag);
    eventBus.$off('attachmentDrag', this.attachmentDrag);
    eventBus.$off('taskDocumentDrag', this.taskDocumentDrag);
    eventBus.$off('noteDrag', this.noteDrag);
    eventBus.$off('SRCmailDrag', this.SRCmailDrag);
    eventBus.$off('SRCtaskDocumentDrag', this.SRCtaskDocumentDrag);
  },
  
  
  methods: {
    taskSelected(task) {
      eventBus.$emit('gotoTask', task)
    },
    
    async loadSplitTask() {
      try {
        this.loadingSplitTask = true;
        const header = await this.getTaskHeader(this.task.fk_source_task)
        this.$set(this, "splitTaskHeader", header)
        this.loadingSplitTask = false
      } catch (e) {
        console.log(e)
      }
    },
    
    doneStepsReran(val) {
      this.doneSteps = val;
    },
    stepCountReran(counts) {
      this.stepCounts = counts
    },
    mailDrag(mail) {
      this.dragData = {type: 'mail', data: mail}
    },
    attachmentDrag(attachment) {
      this.dragData = {type: 'attachment', data: attachment}
    },
    taskDocumentDrag(document) {
      this.dragData = {type: 'document', data: document}
    },
    noteDrag(note) {
      this.dragData = {type: 'note', data: note}
    },
    SRCmailDrag(mail) {
      this.dragData = {type: 'SRCmail', data: mail}
    },
    SRCtaskDocumentDrag(document) {
      this.dragData = {type: 'SRCdocument', data: document}
    },
    openSrcTask(event) {
      event.preventDefault();
      this.getTaskHeader(this.task.fk_source_task)
          .then(task => {
            eventBus.$emit('gotoTask', task)
          })
          .catch(err => {
            console.log(err)
          })
    },
    
    addDroppedBarClass() {
      try {
        this.$refs.stepsbar.classList.add('hover-drag-over');
      } catch (e) {
        // Cant Add
      }
    },
    
    removeDroppedBarClass() {
      try {
        this.$refs.stepsbar.classList.remove('hover-drag-over');
      } catch (e) {
        // Cant Remove
      }
    },
    
    isOverflown() {
      try {
        return this.$refs.stepsbar.scrollHeight > this.$refs.stepsbar.clientHeight || this.$refs.stepsbar.scrollWidth > this.$refs.stepsbar.clientWidth;
      } catch (e) {
        return 'NOTHING'
      }
    },
    
    scrollStep(stepID) {
      this.removeDroppedBarClass()
      stepID = stepID || this.selectedStep.stepID;
      try {
        VueScrollTo.scrollTo(`#stephead${stepID}`, 2, {container: `#stepsbar`, x: true, y: true});
      } catch (e) {
        console.log(e)
      }
    },
    
    copyToTaskStep(stepID) {
      // let jBody = this.drag
      //
      //
      this.fetch({
        method: 'POST',
        url: `/tasks/copyMessage/${this.userInfo.entityID}/${this.userInfo.userID}/${this.task.fk_source_task}/${stepID}/${this.dragData.type == 'mail' ? 1 : 3}/${this.dragData.type == 'mail' ? this.dragData.data.mailDBID : this.dragData.data.documentDBID}`,
      })
          .then((data) => {
            this.showSnack('Info', 'Copy Complete', 'Close', 'primary')
          })
          .catch((e) => {
            // eslint-disable-next-line
            console.log(e)
            this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
          })
    },
    
    dropSrc: function (ev) {
      if (['mail', 'document'].includes(this.dragData.type)) {
        
        this.showCopy = true;
      } else {
        this.showSnack('Error', 'Only A Mail Or A Document Can Be Copied Here', 'Close', 'red')
      }
    },
    
    allowDrop: function (ev) {
      ev.preventDefault();
    },
    
    // The Drop Event Is Here ------
    drop: function (ev, stepID) {
      ev.preventDefault();
      
      try {
        let transferData = ev.dataTransfer.getData('text')
        if (!transferData)
          throw 'No Data Set'
        let eventObj = JSON.parse(transferData)
        if (!eventObj.type)
          throw 'No Type Set'
        switch (eventObj.type) {
          case 'mailAttachment': {
            this.linkMailAttachmentToStepEml(stepID, eventObj.mail, eventObj.attachment)
            break;
          }
          case 'InfoDoc' : {
            this.addRepoDocsToTask(stepID, eventObj.doc)
            break;
          }
          case 'chatAttachmentAudio' : {
            this.addChatMessageAttToTask(stepID, eventObj.payload)
            break;
          }
          case 'chatAttachmentImage' : {
            this.addChatMessageAttToTask(stepID, eventObj.payload)
            break;
          }
          case 'chatAttachmentDocument' : {
            this.addChatMessageAttToTask(stepID, eventObj.payload)
            break;
          }
          default : {
            throw 'No Compatible Type Found'
          }
        }
        
        console.log(eventObj)
        
      } catch (e) {
        switch (this.dragData.type) {
          case '': {
            break;
          }
          case 'mail': {
            this.linkMailToStep(this.dragData.data, stepID);
            break;
          }
          case 'attachment': {
            this.linkMailAttachmentToStep(this.dragData.data.mail, this.dragData.data.attachment, stepID)
            break;
          }
          case 'document': {
            this.moveDocument(this.dragData.data, stepID);
            break;
          }
          case 'note': {
            this.moveNote(this.dragData.data, stepID);
            break;
          }
          case 'SRCdocument' : {
            this.copyToTaskStepFromSrc(stepID);
            break;
          }
          case 'SRCmail' : {
            this.copyToTaskStepFromSrc(stepID);
            this.showSnack('Info', 'SRC Mail Dropped', 'Close', 'primary')
            break;
          }
        }
      }
    },
    
    addChatMessageAttToTask(stepID, att) {
      this.$newReq('POST', `task/chat/moveAttToTask`, {
        taskId: this.task.taskID,
        stepId: stepID,
        attachment: att
      })
          .then((doc) => {
            this.task.structure.documents.push(doc)
            this.$snack.info('Document Added Successfully')
          })
          .catch(e => {
            console.log(e)
            this.$snack.networkError()
          })
    },
    
    addRepoDocsToTask(stepID, doc) {
      this.$newReq(
          'post',
          `docRepo/addDocsToTask/${this.userInfo.entityID}/${this.userInfo.userID}/${this.task.taskID}/${stepID}`,
          {docs: [doc.document.id]}
      )
          .then((addedDocs) => {
            addedDocs.forEach(document => {
              this.task.structure.documents.push(document)
            });
            this.$snack.info('Document Added Successfully')
          })
          .catch(e => {
            console.log(e)
            this.$snack.networkError()
          })
    },
    
    copyToTaskStepFromSrc(stepID) {
      
      // let jBody = this.drag
      //
      //
      this.fetch({
        method: 'POST',
        url: `/tasks/copyMessage/${this.userInfo.entityID}/${this.userInfo.userID}/${this.task.taskID}/${stepID}/${this.dragData.type == 'SRCmail' ? 1 : 3}/${this.dragData.type == 'SRCmail' ? this.dragData.data.mailDBID : this.dragData.data.documentDBID}`,
      })
          .then((data) => {
            
            switch (this.dragData.type) {
              case 'SRCmail': {
                let tDoc = this.dragData.data;
                tDoc.stepID = stepID;
                this.task.structure.mails.push(tDoc)
                break;
              }
              case 'SRCdocument': {
                let tDoc = this.dragData.data;
                tDoc.stepID = stepID;
                this.task.structure.documents.push(tDoc)
                break;
              }
              
            }
            
            this.showSnack('Info', 'Copy Complete', 'Close', 'primary')
          })
          .catch((e) => {
            // eslint-disable-next-line
            console.log(e)
            this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
          })
    },
    
    moveDocument(document, stepID) {
      document.stepID = stepID;
      this.fetch({
        url: `/tasks/moveDocument/${this.userInfo.entityID}/${document.messageID}/${stepID}`,
        method: 'PATCH'
      })
          .then((data) => {
            eventBus.$emit('mailAttchmentLinkedToStep', true);
          })
          .catch((e) => {
            // eslint-disable-next-line
            console.log(e)
          })
    },
    
    moveNote(note, stepID) {
      note.stepID = stepID;
      this.fetch({url: `/tasks/moveNote/${this.userInfo.entityID}/${note.noteID}/${stepID}`, method: 'PATCH'})
          .then((data) => {
          })
          .catch((e) => {
            // eslint-disable-next-line
            console.log(e)
          })
    },
    
    linkMailAttachmentToStep(mail, attachment, stepID) {
      
      
      let mailPath = mail.fk_gmail_group_mailbox_folder == 0 || mail.fk_gmail_group_mailbox_folder == null || mail.fk_gmail_group_mailbox_folder == '' ? mail.fk_import_user + '-' + mail.mailID : mail.fk_gmail_group_mailbox_folder + '-' + mail.mailID
      this.fetch({
        url: '/tasks/linkMailAttachmentToStep/' + this.userInfo.entityID + '/' + this.userInfo.userID + '/' + mailPath + '/' + stepID + '/' + this.task.taskID,
        method: 'PATCH',
        body: JSON.stringify(attachment)
      })
          .then((data) => {
            this.task.structure.documents.push(data);
            eventBus.$emit('mailAttchmentLinkedToStep', true);
          })
          .catch((e) => {
            // eslint-disable-next-line
            console.log(e)
          })
    },
    
    
    linkMailAttachmentToStepEml(stepID, mail, attachment) {
      this.$newReq('post', `eml/linkAttToStep/${this.userInfo.entityID}/${this.userInfo.userID}/${this.task.taskID}/${stepID}`,
          {...mail, ...attachment}
      )
          .then((data) => {
            this.task.structure.documents.push(data);
          })
          .catch(e => {
            console.log(e)
            this.$snack.networkError()
          })
      
    },
    
    linkMailToStep(mail, stepID) {
      
      
      mail.stepID = stepID;
      eventBus.$emit('taskMailLinked', mail);
      
      this.fetch({
        url: '/tasks/linkMailToStep/' + this.userInfo.entityID + '/' + this.userInfo.userID + '/' + mail.messageID + '/' + stepID,
        method: 'PATCH'
      })
          .catch((e) => {
            // eslint-disable-next-line
            console.log(e)
          })
    },
    
    selectStep(step) {
      this.selectedStep.stepID = step.stepID;
      this.selectedStep.description = step.description;
      this.$emit('stepChanged')
    },
    
    getClass(step) {
      if (step.stepID == this.selectedStep.stepID) {
        return 'selected'
      } else {
        if (step.order < this.getCurStepOrder) {
          return 'completed'
        } else {
          return 'pending'
        }
      }
    },
    
    getClassHidden(step) {
      if (step.stepID == this.selectedStep.stepID) {
        return 'selectedHidden'
      } else {
        if (step.order < this.getCurStepOrder) {
          return 'completedHidden'
        } else {
          return 'pendingHidden'
        }
      }
    },
    
    getClassIcon(step) {
      if (step.stepID == this.selectedStep.stepID) {
        return 'selectedIcon'
      } else {
        if (step.order < this.getCurStepOrder) {
          return 'completedIcon'
        } else {
          return 'pendingIcon'
        }
      }
    }
  },
  computed: {
    
    refFields() {
      return objectifyArr(this.task.structure.fields, 'fieldID')
    },
    
    getCurStepOrder() {
      let curOrder = 0;
      this.task.structure.steps.forEach((step) => {
        if (step.stepID == this.task.curStepID) {
          curOrder = step.order
        }
      });
      return curOrder
    },
    
    stepsWithNotifications() {
      
      
      this.task.structure.steps.forEach(step => {
        step.notifications = false;
        
        //First Check the Notes
        let noteViewFlags = [];
        this.task.structure.notes.filter(obj => obj.stepID == step.stepID).forEach(note => {
          try {
            noteViewFlags.push(JSON.parse(note.noteViewFlags))
          } catch (e) {
            // eslint-disable-next-line
            console.log(e);
          }
        });
        
        
        let tArr = [];
        noteViewFlags.filter(obj => obj != null).forEach(flagArr => {
          flagArr.forEach(flag => {
            tArr.push(flag)
          })
        });
        
        if (tArr.filter(obj => obj != null && obj.userID == this.userInfo.userID && obj.viewFlag == 0).length > 0) {
          step.notifications = true;
        }
        
        //Check the Autolink Mails
        if (this.task.ownerID == this.userInfo.userID) {
          this.task.structure.mails.filter(obj => obj.stepID == step.stepID).forEach(mail => {
            if (mail.autoLinkNotification == 1) {
              step.notifications = true;
            }
          });
        }
        
        
      });
      
      
      return this.task.structure.steps;
    },
    
    removedEmptySteps() {
      let tFilterSteps = this.stepCounts.filter(obj => obj.count > 0).map(obj => {
        return obj.step
      });
      return this.stepsWithNotifications.filter(step => (step.hideIfEmpty == 0) || (
          step.hideIfEmpty == 1 && tFilterSteps.includes(step.stepID)
      )).filter(obj => !this.hiddenSteps.includes(obj.stepID))
    }
  }
}
</script>

<style scoped>
.completed {
  border: 1px solid var(--v-tDarkGreen-base) !important;
  background: var(--v-tDarkGreen-base) !important;
  color: white;
}

.pending {
  border: 1px solid var(--v-tDarkOrange-base) !important;
  background: var(--v-tDarkOrange-base) !important;
  color: white;
}

.selected {
  border: 1px solid var(--v-tDarkBlue-base) !important;
  background: var(--v-tDarkBlue-base) !important;
  color: white;
}

.completedHidden {
  border: 1px solid var(--v-tDarkGreen-base);
  color: var(--v-tDarkGreen-base);
}

.pendingHidden {
  border: 1px solid var(--v-tDarkOrange-base);
  color: var(--v-tDarkOrange-base);
}

.selectedHidden {
  border: 1px solid var(--v-tDarkBlue-base);
  color: var(--v-tDarkBlue-base);
}

.completedIcon {
  color: var(--v-tDarkGreen-base);
}

.pendingIcon {
  color: var(--v-tDarkOrange-base);
}

.selectedIcon {
  color: var(--v-tDarkBlue-base);
}

.hover-open-parent {
  height: 31px;
  z-index: 9999999999;
}

.hover-open {
  transition: max-height 0s ease-out;
  /*transition: all 0.3s ease;*/
  max-height: 31px;
  overflow-y: hidden;
  /*z-index: 9999999999;*/
  position: fixed;
  animation: 1s delay-overflow;
  background: var(--page-back);
}

.hover-open:hover {
  transition: max-height 1s ease-in;
  max-height: 1024px;
  z-index: 9999999999;
  position: fixed;
  border-radius: 3px;
  background: var(--page-back);
}

.hover-drag-over {
  transition: all 0.3s ease;
  overflow-y: hidden;
  max-height: none;
  z-index: 9999999999;
  position: fixed;
  border-radius: 3px;
  background: var(--page-back);
}
</style>
