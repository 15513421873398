<template>
    <div>
        <app-input-dlg
                v-if="value"
                :show-dialog="value"
                title="Bulk SMS Profile"
                :cancel-button="true"
                :show-input-box="false"
                @dismiss="$emit('input', false)"
                :body-padding="false"
        >
            <template slot="body">
                <div class="pa-2 text-xs-center" v-if="loadingProfile">
                    <v-progress-circular
                            :size="40"
                            :width="3"
                            color="primary"
                            indeterminate
                    />
                </div>
                <div class="pa-2" v-else>
                    <v-layout row class="body-2 px-2 py-1" align-center>
                        <v-flex xs5 class="greyType--text">Name:</v-flex>
                        <v-flex xs7>{{profile.name}}</v-flex>
                    </v-layout>
                    <v-layout row class="body-2 px-2 py-1" align-center>
                        <v-flex xs5 class="greyType--text">Username:</v-flex>
                        <v-flex xs7>{{profile.bulkSmsUsername}}</v-flex>
                    </v-layout>
                    <v-layout row class="body-2 px-2 py-1" align-center>
                        <v-flex xs5 class="greyType--text">Created:</v-flex>
                        <v-flex xs7>{{getLongDate(profile.created)}}</v-flex>
                    </v-layout>
                    <v-layout row class="body-2 px-2 py-1" align-center>
                        <v-flex xs5 class="greyType--text">Credits Remaining:</v-flex>
                        <v-flex xs7>{{profile.creditsRemaining}}</v-flex>
                    </v-layout>
                </div>
            </template>
            <template slot="actions">
                <div class="text-xs-right">
                    <v-btn
                            small
                            color="secondary"
                            class="ma-0"
                            @click="$emit('input', false)"
                    >
                        <v-icon left>check</v-icon>
                        done
                    </v-btn>
                </div>
            </template>
        </app-input-dlg>
    </div>
</template>

<script>
    import InputDlg from "../../../../../../../components/General/InputDlg";
    import {codeBaseMixin} from "../../../../../../../codeBaseMixin";

    export default {
        mixins: [codeBaseMixin],
        components: {appInputDlg: InputDlg},
        props: {
            value: Boolean,
            userInfo: Object
        },
        data() {
            return {
                loadingProfile: false,
                profile: null
            }
        },
        watch: {
            value: {
                immediate: true,
                handler(shown) {
                    if (shown) {this.getSmsProfile()}
                }
            }
        },

        methods: {
            getSmsProfile() {
                this.loadingProfile = true;
                this.fetch({method: 'GET', url:`/tasks/SMS/Profile/${this.userInfo.entityID}/${this.userInfo.userID}`})
                    .then((data) => {
                        this.loadingProfile = false;
                        if (data.status) {
                            this.profile = data.message;
                        } else {
                            this.showSnack('Error', 'SMS Profile Error: ' + data.message, 'Close', 'red')
                            this.$emit('input', false)
                        }
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e)
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                        this.loadingProfile = false;
                    })
            }
        }
    }
</script>

<style scoped>

</style>
