<template>
  <div class="of-y pa-2">
    <v-card class="of-y">
      <v-card-title class="text-h6 white--text title-color" style="position: sticky; top: 0; z-index: 9">
        Links
      </v-card-title>
      
      <v-card-text>
        <div>
          <div  v-for="(app, i) in appInfo" class="pb-2">
            <div class="px-2 py-1 c-d-flex c-align-center" style="width: 100%">
              <div style="width: 50px;">
                <img :src="app.iconUrl" alt="logo" style="height: 36px"/>
              </div>
              
              <div class="c-flex-grow-1 text-truncate" >
                <div class="font-weight-bold body-2 pb-1">
                  {{app.app.name}}
                </div>
                <div class="text-truncate caption">
                  {{app.linkURL}}
                </div>
              </div>
              
              <div >
                <v-btn
                    icon
                    flat
                    color="primary"
                    @click="copyUrl(app.linkURL)"
                >
                  <v-icon>content_copy</v-icon>
                </v-btn>
              </div>
            </div>
            
            <div class="c-d-flex c-justify-center c-align-start">
              <qrcode-vue render-as="svg" ref="qrCode" :value="app.linkURL" background="rgba(0,0,0,0)"/>
              
              <v-menu>
                <template #activator="{on}">
                  <v-btn
                      large
                      icon
                      color="secondary"
                      class="pa-0 ma-0"
                      flat
                      
                      v-on="on"
                  >
                    <v-icon>download</v-icon>
                  </v-btn>
                </template>
                
                <v-card class="page-back">
                  <v-list dense>
                    <v-list-tile @click="downloadQr(i)">
                      <v-list-tile-avatar>
                        <v-icon>crop_original</v-icon>
                      </v-list-tile-avatar>
                      <v-list-tile-content>
                        <v-list-tile>Download SVG</v-list-tile>
                      </v-list-tile-content>
                    </v-list-tile>
                    
                    <template v-for="size in [96,128,256,512,1024]">
                      <v-list-tile @click="downloadQr(i, size)">
                        <v-list-tile-avatar>
                          <v-icon>photo</v-icon>
                        </v-list-tile-avatar>
                        <v-list-tile-content>
                          <v-list-tile>Download PNG {{ size }}</v-list-tile>
                        </v-list-tile-content>
                      </v-list-tile>
                    </template>
                  </v-list>
                </v-card>
              </v-menu>
            </div>
          </div>
        </div>
        
      </v-card-text>
    </v-card>
  </div>
</template>

<script>

import {mapGetters} from "vuex";
import {copyText} from "@/codeFunctions";
import QrcodeVue from 'qrcode.vue'

export default {
  components: {
    QrcodeVue,
  },
  props: {
    linkRule: {
      type: Object,
      default: () => ({})
    },
    apps: Array,
  },
  data() {
    return {
      appInfo: []
    }
  },
  watch: {
    linkRule: {
      deep: true,
      immediate: true,
      handler() {
        this.init()
      }
    }
  },
  methods: {
    downloadQr(index, type = 'svg') {
      const svgCode = this.$refs.qrCode[index].$el.firstChild
      if (type === 'svg')
        this.downloadSVGElementAsSVG(svgCode, `${this.appInfo[index].app.name.replaceAll(' ', '_')}.svg`)
      else {
        const filename = `${this.appInfo[index].app.name.replaceAll(' ', '_')}_${type}x${type}.png`
        this.downloadSVGElementAsPNG(svgCode, filename, type, type)
      }
      
    },
    
    downloadSVGElementAsPNG(svgElement, fileName, width, height) {
      const serializer = new XMLSerializer();
      const svgStr = serializer.serializeToString(svgElement);
      
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const img = new Image();
      const svgBlob = new Blob([svgStr], {type: 'image/svg+xml;charset=utf-8'});
      const url = URL.createObjectURL(svgBlob);
      
      img.onload = function() {
        // Set canvas dimensions
        canvas.width = width;
        canvas.height = height;
        
        // Optional: Calculate scaling factor to preserve aspect ratio
        const scaleX = width / img.width;
        const scaleY = height / img.height;
        const scale = Math.min(scaleX, scaleY); // To preserve aspect ratio
        
        const x = (width - img.width * scale) / 2; // Center horizontally
        const y = (height - img.height * scale) / 2; // Center vertically
        
        ctx.clearRect(0, 0, width, height); // Clear the canvas
        ctx.drawImage(img, x, y, img.width * scale, img.height * scale);
        
        canvas.toBlob(function(blob) {
          const pngUrl = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = pngUrl;
          link.download = fileName;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(pngUrl);
          URL.revokeObjectURL(url);
        }, 'image/png');
      };
      img.onerror = function() {
        console.error('Error loading SVG');
        URL.revokeObjectURL(url);
      };
      img.src = url;
    },
    
    downloadSVGElementAsSVG(svgElement, fileName) {
      const serializer = new XMLSerializer();
      const svgBlob = new Blob([serializer.serializeToString(svgElement)], {type: 'image/svg+xml'});
      const url = URL.createObjectURL(svgBlob);
      
      const link = document.createElement('a');
      link.href = url;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    },
    
    copyUrl(url) {
      copyText(document, url)
      this.$snack.info('URL copied to clipboard')
    },
    
    init() {
      const allApps = this.apps.filter(v => this.linkRule.fkApps.length ? this.linkRule.fkApps.includes(v.id) : true)
      
      this.appInfo = allApps.map((app) => {
        
        const linkURL = `https://${app.url}-app.tasklogix.co.za${process.env.NODE_ENV === "development" ? ':3000' : ''}/#/?link=${this.linkRule.linkHash}`
        const iconUrl = `https://${app.url}-app.tasklogix.co.za${process.env.NODE_ENV === "development" ? ':3000' : ''}/apps/${app.url}/${app.favIcon}`
        return {
          app,
          linkURL,
          iconUrl
        }
        
      })
      
    }
  },
  computed: {
    ...mapGetters('userInfo', ["userInfo"]),
  },
}
</script>



<style scoped>

</style>