<template>
    <div class="fill-height" style="overflow-y: auto">
        <app-manage-detail-status-options
                :user-info="userInfo"
                :workflow-data="workflowInfo"
                :local-data="localData"
        />
    </div>
</template>

<script>
    import ManageDetailStatusOptions from "./ManageDetailComponents/ManageDetailStatusOptions";
    export default {
        name: "ManageDetail",
        components: {
            appManageDetailStatusOptions: ManageDetailStatusOptions
        },
        props: {
            userInfo: Object,
            selectedWorkflow: Object,
            workflowInfo: Object,
            localData: Object
        },
        data() {
            return {
                showStatusOptions: true
            }
        },
    }
</script>

<style scoped>

</style>
