<template>
    <v-card
            class="pl-0"
            flat
    >
        <v-layout column>
            <v-flex shrink class="px-1">
                <v-layout align-center justify-start row>
                    <v-flex shrink class="text-xs-center">
                        <v-tooltip top>
                            <v-avatar
                                    slot="activator" color="secondary"
                                    size="26" style="cursor: pointer"
                                    @click="draft.sendStatus == 1 ? showSnack('Error', 'Message Sending', 'Close', 'red') : $emit('openDraft', draft)"
                            >
                                <template>
                                    <v-icon small dark>
                                        {{ draft.sendStatus == 0 ? 'drafts' : (draft.sendStatus == 1 ? 'send' : 'warning')}}
                                    </v-icon>
                                </template>
                            </v-avatar>
                            <span>{{ draft.sendStatus == 0 ? 'Open Draft' : (draft.sendStatus == 1 ? 'Draft Sending' : 'Sending Failed')}}</span>
                        </v-tooltip>
                    </v-flex>
                    <v-flex xs12 class="pl-1">
                        <v-layout class="py-0 my-0" row wrap>
                            <v-flex xs12 class="font-weight-medium text--small">
                                Draft Mail
                            </v-flex>
                            <v-flex xs12 style="font-size: 0.8em" class="font-weight-regular">
                                {{ getLongDate(draft.created) }}
                            </v-flex>
                        </v-layout>
                    </v-flex>
                    <v-flex shrink v-if="draft.sendStatus != 1">
                        <v-layout row justify-end>
                            <v-flex shrink class="text-xs-right pa-1" v-if="typeof draft.errorMessage != 'undefined'">
                                <v-tooltip top>
                                    <v-btn
                                            @click="showErrorMessage = true"
                                            slot="activator"
                                            flat
                                            icon
                                            color="blue"
                                            class="ma-0 pa-0"
                                            small
                                    >
                                        <v-icon>info</v-icon>
                                    </v-btn>
                                    <span>Error Info</span>
                                </v-tooltip>

                                <app-input-dlg
                                        v-if="showErrorMessage"
                                        :show-dialog="showErrorMessage"
                                        title="Mail Error"
                                        :width="800"
                                        :cancel-button="true"
                                        :show-input-box="false"
                                        @dismiss="showErrorMessage = false"
                                        :body-padding="false"
                                >
                                    <template slot="body">
                                        <div style="max-height: 500px; overflow-y: auto; overflow-x: auto" v-html="draft.errorMessage" class="pa-2">
                                        </div>
                                    </template>
                                </app-input-dlg>
                            </v-flex>
                            <v-flex shrink class="text-xs-right pa-1">
                                <v-tooltip top>
                                    <v-btn
                                            @click="deleteDraft(draft)"
                                            slot="activator"
                                            flat
                                            icon
                                            color="secondary"
                                            class="ma-0 pa-0"
                                            small
                                            :disabled="removingDraft"
                                            :loading="removingDraft"
                                    >
                                        <v-icon>delete</v-icon>
                                    </v-btn>
                                    <span>Delete Draft</span>
                                </v-tooltip>
                            </v-flex>
                            <v-flex shrink class="text-xs-right pa-1">
                                <v-tooltip top>
                                    <v-btn
                                            @click="$emit('openDraft', draft)"
                                            slot="activator"
                                            flat
                                            icon
                                            color="secondary"
                                            class="ma-0 pa-0"
                                            small
                                    >
                                        <v-icon>edit</v-icon>
                                    </v-btn>
                                    <span>Open Draft</span>
                                </v-tooltip>
                            </v-flex>
                        </v-layout>
                    </v-flex>
                </v-layout>
            </v-flex>
            <v-flex v-if="draft.sendStatus == 1">
                <v-progress-linear
                        indeterminate
                        height="3"
                        class="pa-0 ma-0"
                        color="secondary"
                ></v-progress-linear>
            </v-flex>
        </v-layout>
    </v-card>
</template>

<script>
    import {codeBaseMixin} from "../../../../codeBaseMixin";
    import {eventBus} from "../../../../main";
    import InputDlg from "../../../../components/General/InputDlg";

    export default {
        components: {appInputDlg: InputDlg},
        mixins: [codeBaseMixin],
        props: {
            selectedStep: Object,
            userInfo: Object,
            task: Object,
            draft: Object
        },
        data() {
            return {
                removingDraft: false,
                showErrorMessage: false
            }
        },
        methods: {
            deleteDraft(draft) {
                //Delete Draft Here
                this.removingDraft = true;
                this.fetch({method: 'DELETE', url:`/taskCache/mail/removeDraftMail/${this.userInfo.entityID}/${draft.id}`})
                    .then((data) => {
                        this.removingDraft = false;
                        this.task.structure.draftMails = this.task.structure.draftMails.filter(obj => obj.id != draft.id);
                        this.showSnack('Info', 'Draft Removed', 'Close', 'primary')
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e)
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
                        this.removingDraft = false;
                    })
            }
        },
    }
</script>

<style scoped>

</style>
