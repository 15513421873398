<template>
    <v-dialog
            v-model="showDialog"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
            persistent
    >
        <v-card flat height="100vh">

            <v-layout column fill-height >
                <v-flex shrink class="title-color">
                    <!--Card Title-->
                    <v-card-title class="py-2 pr-2">
                        <!--<v-icon-->
                                <!--left-->
                                <!--color="white"-->
                        <!--&gt;-->
                            <!--group-->
                        <!--</v-icon>-->
                        <!--<img src="../../../public/users.svg" width="42px" class="pr-2"/>-->
                        <img src="../../../public/users.svg" style="height: 24px" class="pr-2"/>
                        <span class="title font-weight-light white--text">Users Manager</span>
                        <v-spacer></v-spacer>
                        <span class="body-2 white--text">{{`${disabledUsersCount} of ${entityData != null ? entityData.maxActiveUsers : 0} Active Users Assigned`}}</span>
                        <v-spacer></v-spacer>
                        <v-btn flat icon color="white" class="pa-0 ma-0" @click="$emit('dismiss', false)">
                            <v-icon>close</v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-progress-linear class="pa-0 ma-0" height="3px" color="secondary" v-model="userPercentage"></v-progress-linear>
                    <v-divider></v-divider>
                </v-flex>
<!--Loading-->
                <v-flex shrink class="pt-3 text-xs-center" v-if="!loadingUsers">
                    <v-progress-circular
                            :size="40"
                            :width="5"
                            color="primary"
                            indeterminate
                    />
                </v-flex>

                <template v-else>
                    <v-flex xs12 style="overflow-y: auto">
                        <v-layout row fill-height class="py-1">
                            <v-layout row fill-height style="overflow-y: auto">
<!--User Select-->
                                <v-flex xs3 >
<!--Search And Add User-->
                                    <v-layout column fill-height style="overflow-y: auto">
                                        <v-flex shrink>
                                            <v-layout row align-center>
                                                <v-flex shrink>
                                                    <v-tooltip right slot="activator">
                                                        <v-btn
                                                                @click="showAddUser = true"
                                                                slot="activator"
                                                                icon
                                                                class="pa-1"
                                                                color="secondary"

                                                        >
                                                            <v-icon>add</v-icon>
                                                        </v-btn>
                                                        <span>Create a New User</span>
                                                    </v-tooltip>

                                                    <app-input-dlg
                                                            :show-dialog="showAddUser"
                                                            title="Add User"
                                                            body="Please specify a username:"
                                                            :cancel-button="true"
                                                            :show-input-box="true"
                                                            :buttons="['Create User']"
                                                            :call-backs="[ addNewUser ]"
                                                            @dismiss="showAddUser = false"
                                                    />
                                                </v-flex>
                                                <v-flex xs12>
                                                    <v-text-field
                                                            label="Search Users"
                                                            solo
                                                            clearable
                                                            prepend-icon="search"
                                                            color="primary"
                                                            flat
                                                            :hide-details="true"
                                                            v-model="userSearch"
                                                            class="pa-0 ma-0"
                                                            ref="myField"
                                                    />
                                                </v-flex>
                                            </v-layout>
                                        </v-flex>
                                        <v-flex xs12 style="overflow-y: auto" class="scroll">
                                            <div>
                                                <template v-for="(user, i) in sortedUsersDisabled">
                                                    <div
                                                            :key="'userSelect' + user.id"
                                                            @click="selectedUser = user"
                                                            class="pa-0 ma-0"
                                                            :class="selectedUser != null && selectedUser.id == user.id ? 'selected-item' : null"
                                                    >
                                                        <div v-if="i !== 0"><v-divider ></v-divider></div>
                                                        <v-layout row align-center class="hover-item"
                                                        >
                                                            <v-flex shrink>
                                                                <v-btn
                                                                        icon
                                                                        :color="intToBool(user.disabled) ? 'secondary' : 'primary'"
                                                                        flat
                                                                        class="pa-0 ma-0"
                                                                >
                                                                    <v-icon>person</v-icon>
                                                                </v-btn>
                                                            </v-flex>
                                                            <v-flex xs12 class="pl-2">
                                                                <v-layout column fill-height justify-center>
                                                                    <v-flex shrink>
                                                                        <div
                                                                                v-if="user.name != null && user.surname != null"
                                                                                :class="selectedUser != null && selectedUser.id == user.id ? 'body-2' : (intToBool(user.disabled) ? 'greyType--text body-1' : 'body-1')"
                                                                        >
                                                                            {{ `${user.name + ' ' + user.surname}${intToBool(user.disabled) ? ' ' : ''}`}}
                                                                        </div>
                                                                        <div
                                                                                :class="selectedUser != null && selectedUser.id == user.id ? 'caption font-weight-bold' : 'caption greyType--text'"
                                                                        >
                                                                            {{ user.username }}
                                                                        </div>
                                                                    </v-flex>
                                                                </v-layout>
                                                            </v-flex>
                                                        </v-layout>


                                                    </div>
                                                </template>

                                            </div>
                                        </v-flex>
                                    </v-layout>
                                </v-flex>
<!--User Detail-->
                                <v-flex
                                        xs9
                                        class="px-1 pb-1"
                                        style="overflow-y: auto"
                                        v-if="selectedUser != null"
                                >
                                    <app-user-detail-view
                                            :selected-user="selectedUser"
                                            :user-info="userInfo"
                                            :allUserGroups="allUserGroups"
                                            :entity-data="entityData"
                                            :signatures="signatures"
                                            :localData="localData"
                                            :config3-c-x="config3CX"
                                    />
                                </v-flex>
                            </v-layout>
                        </v-layout>
                    </v-flex>
                </template>
            </v-layout>
        </v-card>
    </v-dialog>
</template>

<script>

    import {codeBaseMixin} from "../../codeBaseMixin";
    import InputDlg from '../../components/General/InputDlg';
    import UserDetailView from './UserDetailView';

    export default {
        mixins: [codeBaseMixin],
        components: {
            appInputDlg: InputDlg,
            appUserDetailView: UserDetailView
        },
        props: {
            showDialog: Boolean,
            userInfo: Object,
            localData: Object,
        },
        data() {
            return {
                users: [],
                signatures: null,
                loadingUsers: false,
                showAddUser: false,
                userSearch: null,
                selectedUser: null,
                entityData: null,
                allUserGroups: null,
                config3CX: null
            }
        },
        watch: {
            showDialog(show) {
                if (show && !this.loadingUsers && this.users.length == 0) {
                    this.loadUsers();
                }
            }
        },
        methods: {

            addNewUser(username) {
                if (username != null && username != '') {
                    this.fetch({method: 'POST', url: '/localUsers/addUser/' + this.userInfo.entityID, body: JSON.stringify({description: username})})
                        .then((data) => {
                            if (typeof data.duplicateUser == 'undefined') {
                                this.users.push(data);
                                this.selectedUser = data;
                                this.showAddUser = false;
                            } else {
                                this.showSnack('Error', 'Username already exists', 'Close', 'red')
                            }
                        })
                        .catch((e) => {
                            // eslint-disable-next-line
                            console.log(e);
                            this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')

                            // this.showAddUser
                        })
                } else {
                    this.showSnack('Error', 'A Username Is Required', 'Close', 'red')
                }

            },

            loadUsers() {
                this.loadingUsers = true;
                this.fetch({method: 'GET', url: '/localUsers/usersManager/' + this.userInfo.entityID + '/' + this.userInfo.userID})
                    .then((data) => {
                        this.users = data.users;
                        this.signatures = data.signatures;
                        this.entityData = data.entityData;
                        this.allUserGroups = data.userGroups;
                        this.config3CX = data.config3CX || [];
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e);
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
                        this.loadingUsers = false;
                    })
            },


            sortUsers(a,b) {
                // First Sort On Disabled
                if (a.disabled && !b.disabled)
                    return 1
                if (!a.disabled && b.disabled)
                    return -1

                // Sort According to Display Name
                let nameA = (((a.name + a.surname) || ' ').trim() || a.username).toLowerCase();
                let nameB = (((b.name + b.surname) || ' ').trim() || b.username).toLowerCase();
                return nameA < nameB ? -1 : 1
            }

        },
        computed: {
            sortedUsersDisabled() {
                return [...this.sortedUsers].sort(this.sortUsers)
            },
            sortedUsers() {
                if (this.userSearch != null && this.userSearch != '') {
                    return this.users
                        .filter(object => (object.name + object.surname + object.username).toLowerCase().includes(this.userSearch.toLowerCase())).sort((a,b) => a['username'] < b['username'] ? -1 : 1);
                } else {
                    let tUsers = this.users;
                    return tUsers
                        .sort((a,b) => (`${typeof a['name'] != 'undefined' ? a['name'] : 'ZZZZZZZZZZ'}${typeof a['surname'] != 'undefined' ? a['surname'] : 'ZZZZZZZZZZ'}`).toLowerCase() < (`${typeof b['name'] != 'undefined' ? b['name'] : 'ZZZZZZZZZZ'}${typeof b['surname'] != 'undefined' ? b['surname'] : 'ZZZZZZZZZZ'}`).toLowerCase() ? -1 : 1);
                }
            },
            disabledUsersCount() {
                try {
                    return this.users.filter(obj => !this.intToBool(obj.disabled)).length
                } catch (e) {
                    // eslint-disable-next-line
                    console.log(e)
                    return 0
                }
            },
            userPercentage() {
                try {
                    return parseInt((this.disabledUsersCount / this.entityData.maxActiveUsers) * 100);
                } catch (e) {
                    // eslint-disable-next-line
                    return 0
                }
            }
        }
    }
</script>

<style scoped>

</style>
