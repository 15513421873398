'use strict';
const store = require('../store');
const {getYear, getDayOfYear, format} = require("date-fns");
const {newReq} = require("@/axiosRequest");
const {waitForEvent} = require("@/feedSocket");

export class CalendarEvent {
    constructor(event) {
        event = event || {};
        this.id = null;
        this.title = null;
        this.body = [];
        this.icon = null;
        this.color = null;
        this.start = new Date();
        this.duration = null;
        this.fkOwner = null;
        this.fkTask = null;
        this.fkNote = null;
        this.fkDoc = null;
        this.fkMail = null;
        this.fkFolder = null;
        this.fkDataset = null;
        this.created = null;
        this.eventHistory = null;
        this.fkCalGroup = null;
        this.subscriptions = [];
        this.sharedGroups = [];
        this.sharedUsers = [];
        this.editors = [];
        this.done = null
        this.linkPayload = null;
        Object.assign(this, JSON.parse(JSON.stringify(event)))
        this.start = new Date(this.start)
        this.created = new Date(this.created)
        this._sub = null
    }
    get groupCalendarRef() {
        // console.time("groupCalendarRef")
        const res = this.fkCalGroup ? store.default.getters["cal/calendarGroupsRef"][this.fkCalGroup] : null;
        // console.timeEnd("groupCalendarRef")

        return res
    }
    get sharedUsersRef() {
        // console.time("sharedUsersRef")
        const res = this.sharedUsers.map(userID => {
            return store.default.getters.usersRef[userID]
        });
        // console.timeEnd("sharedUsersRef")
        return res
    }
    get folderRef() {
        // console.time("folderRef")
        const res = this.fkFolder ? store.default.getters.foldersRef[this.fkFolder] : null;
        // console.timeEnd("folderRef")
        return res
    }
    get sharedGroupsRef() {

        // console.time("sharedGroupsRef")
        const res = this.sharedGroups.map(userID => {
            return store.default.getters.userGroupsRef[userID]
        });
        // console.timeEnd("sharedGroupsRef")

        return res
    }
    get editorsRef() {

        // console.time("editorsRef")
        const res = this.editors.map(userID => {
            return store.default.getters.usersRef[userID]
        });
        // console.timeEnd("editorsRef")

        return res
    }
    get dateKey() {
        // console.time("dateKey")
        const res = this.start ? Number(`${getYear(this.start)}${`${getDayOfYear(this.start)}`.padStart(3, '0')}`) : null;
        // console.timeEnd("dateKey")
        return res
    }
    get shortTime() {
        // console.time("shortTime")
        const res = format(this.start, 'hh:mm A');
        // console.timeEnd("shortTime")
        return res
    }
    get shortDate() {
        // console.time("shortDate")
        const res = format(this.start, 'DD MMM YY');
        // console.timeEnd("shortDate")
        return res
    }
    get startLongStr() {
        // console.time("startLongStr")
        const res = format(this.start, 'Do MMM YYYY hh:mm A');
        // console.timeEnd("startLongStr")
        return res
    }
    get durationStr() {
        if (this.duration) {
            // console.time("durationStr")
            let hours = Math.floor(this.duration / 60)
            let minutes = this.duration % 60
            const res = `${hours > 0 ? `${hours} Hours` : ''}${minutes > 0 ? ` ${minutes} Minutes` : ''}`.trim();;
            // console.timeEnd("durationStr")
            return res
        } else {
            return null
        }
    }
    get sharesArr() {

        // console.time("sharesArr")
        const res = this.sharedGroupsRef
            .map((obj) => {
                return {
                    type: 'group',
                    description: obj.description,
                    icon: "people",
                    color: 'light-blue',
                    id: obj.id,
                    ref: obj
                }
            })
            .concat(this.sharedUsersRef.map((obj => {
                return {
                    type: 'user',
                    description: obj.fullName,
                    icon: "person",
                    color: 'cyan',
                    id: obj.userID,
                    ref: obj
                }
            })))
            .sort((a, b) => a.description > b.description ? 1 : -1);
        // console.timeEnd("sharesArr")

        return res
    }
    get hasAccess() {
        // console.time("hasAccess")
        let userID = store.default.getters["userInfo/userInfo"].userID;
        if (this.fkOwner === userID) {
            // console.timeEnd("hasAccess")
            return true
        }
        if (this.sharedUsers.includes(userID)) { // Check Shared User
            // console.timeEnd("hasAccess")
            return true
        }
        for (const userGroup of this.sharedGroupsRef) {// Check Shared User Group
            if (userGroup.users.includes(userID)) {
                // console.timeEnd("hasAccess")
                return true
            }
        }
        if (this.editors.includes(userID)) {// Check Editor
            // console.timeEnd("hasAccess")
            return true
        }
        if (this.groupCalendarRef) {
            if (this.groupCalendarRef.users.includes(userID)) {// Check Shared User
                // console.timeEnd("hasAccess")
                return true
            }
            for (const userGroup of this.groupCalendarRef.groupsRef) { // Check Shared User Group
                if (userGroup.users.includes(userID)) {
                    // console.timeEnd("hasAccess")
                    return true
                }
            }
            if (this.groupCalendarRef.editors.includes(userID)) {// Check Editor
                // console.timeEnd("hasAccess")
                return true
            }
        }
        // console.timeEnd("hasAccess")
        return false
    }
    get bodyRef() {
        // console.time("bodyRef")
        const res = this.body.map(obj => {
            return {
                data: obj.data,
                date: format(new Date(obj.date), 'Do MMM YYYY hh:mm a'),
                refDate: obj.date,
                user: store.default.getters.usersRef[obj.fkUser],
                dateObj: new Date(obj.date)
            }
        }).sort((a,b) => a.dateObj > b.dateObj ? -1 : 1);
        // console.timeEnd("bodyRef")
        return res
    }
    get ownerRef() {
        // console.time("ownerRef")
        const res = store.default.getters.usersRef[this.fkOwner];
        // console.timeEnd("ownerRef")
        return res
    }
    get createdDateStr() {
        // console.time("createdDateStr")
        const res = format(this.created, 'Do MMM YYYY hh:mm a');
        // console.timeEnd("createdDateStr")
        return res
    }
    async upsert(subscribe, cb) {
        try {
            let event = JSON.parse(JSON.stringify(this));
            delete event.linkPayload;
            if (subscribe)
                event.subscribe = true
            let updateID = await newReq('patch', `cal/event/${store.default.getters["userInfo/userInfo"].entityID}`, event)
            let payload = (await waitForEvent(updateID)).events.pop();
            cb(payload)
        } catch (e) {
            cb(null, e)
        }
    }
    async removeEvent(subscribe, cb) {
        try {
            let updateID = await newReq('delete', `cal/event/${store.default.getters["userInfo/userInfo"].entityID}/${this.id}`)
            let payload = (await waitForEvent(updateID)).events.pop();
            console.log('DEL PAYLOAD', payload)
            cb(payload)
        } catch (e) {
            cb(null, e)
        }
    }
    async addNote(content, cb) {
        try {
            let updateID = await newReq('put', `cal/event/${store.default.getters["userInfo/userInfo"].entityID}/${store.default.getters["userInfo/userInfo"].userID}/${this.id}`, {content})
            let payload = (await waitForEvent(updateID)).events.pop();
            cb(payload)
        } catch (e) {
            cb(null, e)
        }
    }
    async updateSubscription(removed, cb) {
        try {
            let updateID = await newReq('put', `cal/event/${store.default.getters["userInfo/userInfo"].entityID}/${store.default.getters["userInfo/userInfo"].userID}/${this.id}/${removed ? 1 : 0}`)
            let payload = (await waitForEvent(updateID)).events.pop();
            Object.assign(this, payload)
            this.start = new Date(this.start)
            this.created = new Date(this.created)
            console.log({pendingNotification: this.pendingNotification})
            cb(payload)
        } catch (e) {
            cb(null, e)
        }
    }
    get editable() {

        // console.time("editable")

        if (this.hasAccess && this.groupCalendarRef && this.groupCalendarRef.allUsersEditors) {
            // console.timeEnd("editable")
            return true
        }

        let userID = store.default.getters["userInfo/userInfo"].userID;
        if (this.fkOwner === userID) {
            // console.timeEnd("editable")
            return true
        }
        if (this.editors.includes(userID)) {// Check Editor
            // console.timeEnd("editable")
            return true
        }
        if (this.groupCalendarRef) {
            if (this.groupCalendarRef.editors.includes(userID)) {// Check Editor
                // console.timeEnd("editable")
                return true
            }
        }
        // console.timeEnd("editable")
        return false
    }
    get subscribed() {

        if (!Array.isArray(this.subscriptions) || !this.subscriptions.length) {
            return false
        }

        if (this._sub) {
            this._sub = this.subscriptions.indexOf(this._sub) > -1 ? this._sub : this.subscriptions.filter(sub => sub.fkUser === store.default.getters["userInfo/userInfo"].userID).pop()
            return this._sub
        } else {
            const arr = this.subscriptions.filter(sub => sub.fkUser === store.default.getters["userInfo/userInfo"].userID)
            this._sub = arr.length ? arr.pop() : false
            return this._sub
        }


        // console.log(Array.isArray(this.subscriptions) ? this.subscriptions.indexOf(this.sub) : false)
        //
        // let subs = this.subscriptionsRefPendingNotificationFilter.filter(sub => sub.fkUser === store.default.getters["userInfo/userInfo"].userID)
        // return subs.length ? subs.shift() : false
    }
    get pendingNotification() {
        const val = this.subscriptionsRefPendingNotificationFilter
        const filter = val.filter(sub => {
            const user = sub.fkUser === store.default.getters["userInfo/userInfo"].userID
            const removed = !sub.removed

            return user && removed
        })
        return !!filter.length
    }
    get subscriptionsRef() {
        const val = this.subscriptions
        // console.time("Map")
        const map = val.map(sub => {
            return {
                ...sub,
                user: store.default.getters.usersRef[sub.fkUser],
                removed: !!sub.removed,
                removeDate: sub.removeDate ? new Date(sub.removeDate) : null
            }
        })
        const sort = map.sort((a,b) => a.user.fullName > b.user.fullName ? 1 : -1)
        return sort
    }

    get subscriptionsRefPendingNotificationFilter() {

        // // console.time("Val")
        const val = this.subscriptions
        // // console.timeEnd("Val")

        const map = val.map(sub => {
            return {
                fkUser: sub.fkUser,
                removed: !!sub.removed
            }
        })

        // // console.time("Sort")
        // const sort = map.sort((a,b) => a.user.fullName > b.user.fullName ? 1 : -1)
        // // console.timeEnd("Sort")

        return map
    }

    markAsDone() {
        let beforeSave = this.done
        this.done = this.done ? null : {userID: store.default.getters['userInfo/userInfo'].userID, date: new Date()}
        this.upsert(false, () => {})
            .catch(e => {
                console.log(e)
                this.done = beforeSave
            })
    }
    get doneRef() {
        if (this.done) {
            return {
                user: store.default.getters.usersRef[this.done.userID],
                date: this.done.date,
                dateStr: format(this.done.date, 'Do MMM YYYY hh:mm A')
            }
        } else return null
    }
}

