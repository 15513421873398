<template>
    <v-card>
        <v-layout row class="caption pa-1" align-center justify-space-between>
            <v-flex shrink class="caption font-weight-bold">{{smsBy}}</v-flex>
            <v-flex shrink class="caption font-weight-bold">To: {{sms.to}}</v-flex>
            <v-flex shrink class="caption font-weight-bold">{{smsDate}}</v-flex>
        </v-layout>
        <v-divider></v-divider>
        <div class="body-1 pa-2" style="white-space: pre-line;">{{smsBody}}<div style="display: inline; float: right" v-if="showSend">
                <v-tooltip left>
                    <v-btn
                            icon
                            small
                            flat
                            class="pa-0 ma-0 mb-2"
                            color="secondary"
                            slot="activator"
                            @click="$emit('sendAgain')"
                    >
                        <v-icon>textsms</v-icon>
                    </v-btn>
                    <span>Send Again</span>
                </v-tooltip>
            </div>
        </div>

    </v-card>
</template>

<script>
    import {codeBaseMixin} from "../../../../../../../codeBaseMixin";

    export default {
        mixins: [codeBaseMixin],
        props: {
            localData: Object,
            value: String,
            showSend: {
                default: true,
                type: Boolean
            }
        },
        computed: {
            sms() {
                try {
                    return JSON.parse(this.value.replace(/\n/g,"<br>"))
                } catch (e) {
                    // eslint-disable-next-line
                    console.log(e, this.value)
                    return null
                }
            },
            smsDate() {
                try {
                    return this.getShortDateTime(this.sms.date)
                } catch (e) {
                    // eslint-disable-next-line
                    console.log(e)
                    return 'Date Error'
                }
            },
            smsBy() {
                try {
                    return this.localData.users
                        .filter(obj => obj.userID === this.sms.sentBy)
                        .map(obj => {
                            return `${obj.name} ${obj.surname}`
                        })
                        .pop()
                } catch (e) {
                    // eslint-disable-next-line
                    console.log(e)
                    return null
                }
            },
            smsBody() {
                return this.sms.message.replace(/<br>/g,`\n`).trim()
            }
        }

    }
</script>

<style scoped>

</style>
