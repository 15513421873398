<template>
    <v-dialog
            v-model="showDialog"
            persistent
            width="400"
    >
        <v-card>
            <v-card-title class="pa-1 pl-2 py-2">
                <span class="subheading font-weight-light primary--text">Copy To Step</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="pa-0 py-0">
                <v-layout column>
                    <v-flex v-if="loading" class="pa-2 text-xs-center" shrink>
                        <v-progress-circular
                                :size="40"
                                :width="5"
                                color="primary"
                                indeterminate
                        ></v-progress-circular>
                    </v-flex>
                    <template v-else>
                        <v-flex shrink class="pl-3">

                            <v-text-field
                                    label="Search Steps"
                                    solo
                                    clearable
                                    prepend-icon="search"
                                    color="primary"
                                    flat
                                    class=""
                                    :hide-details="true"
                                    v-model="searchStr"
                            ></v-text-field>

                        </v-flex>
                        <v-flex shrink class="scroll" style="max-height: 500px; overflow-y: auto">
                            <v-list dense v-if="steps != null">
                                <template v-for="step in sortedSteps != null && searchStr != null && searchStr != '' ? sortedSteps.filter(object => object.description.toLowerCase().indexOf(searchStr.toLowerCase()) >= 0) : sortedSteps">
                                    <v-list-tile
                                            :key="'CopyStep-' + step.id"
                                            @click="() => {}"
                                    >
                                        <v-list-tile-content>
                                            <v-list-tile-title>{{ step.description }}</v-list-tile-title>
                                        </v-list-tile-content>
                                        <v-list-tile-action>
                                            <v-tooltip top>
                                                <v-btn
                                                        @click="$emit('input', step.id); $emit('dismiss')"
                                                        small
                                                        icon
                                                        flat
                                                        class="pa-0 ma-0"
                                                        color="primary"
                                                        slot="activator">
                                                    <v-icon>file_copy</v-icon>
                                                </v-btn>
                                                <span>Copy To: {{ step.description}}</span>
                                            </v-tooltip>
                                        </v-list-tile-action>
                                    </v-list-tile>
                                </template>
                            </v-list>
                        </v-flex>
                    </template>
                </v-layout>
            </v-card-text>
            <v-divider></v-divider>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                        color="secondary"
                        flat
                        @click="$emit('dismiss')"
                >
                    Cancel
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import {codeBaseMixin} from "../../codeBaseMixin";

    export default {
        mixins: [codeBaseMixin],
        props: {
            value: {
                type: Number,
                default: 0
            },
            showDialog: {
                type: Boolean,
                default: false,
            },
            workflowID: {
                type: Number,
                default: 0
            },
            userInfo: Object,
            sourceTaskID: {
                type: Number,
                default: 0
            },
            useTask: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                loading: false,
                steps: null,
                searchStr: null
            }
        },
        watch: {
            showDialog: {
                immediate: true,
                handler(shown) {
                    if (shown && this.loading == false) {
                        if (this.useTask) {
                            this.getStepsUsingTask();
                        } else {
                            this.getSteps();
                        }
                    }
                }
            }
        },
        mounted() {
            if (this.loading == false) {
                if (this.useTask) {
                    this.getStepsUsingTask();
                } else {
                    this.getSteps();
                }
            }
        },
        methods: {
            getSteps() {
                this.loading = true;
                this.fetch({method: 'GET', url: `/tasks/getWorkflowSteps/${this.userInfo.entityID}/${this.workflowID}`})
                    .then((data) => {
                        this.steps = data;
                        this.loading = false;
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e)
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
                        this.loading = false;
                    })
            },
            getStepsUsingTask() {
                this.loading = true;
                this.fetch({method: 'GET', url: `/tasks/getWorkflowStepsFromTaskID/${this.userInfo.entityID}/${this.sourceTaskID}`})
                    .then((data) => {
                        this.steps = data;
                        this.loading = false;
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e)
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
                        this.loading = false;
                    })
            }
        },
        computed: {
            sortedSteps() {

                let tSteps = this.steps;

                return tSteps.sort((a,b) => a['field_order'] < b['field_order'] ? -1 : 1);
            }
        },
    }
</script>

<style scoped>

</style>
