<template>
    <div class="caption">

        <v-treeview
                :items="totalFields"
                item-text="description"
                item-key="refKey"
                class="compact-tree"
        >
            <template v-slot:label="{ item }">

<!--Heading Field-->
                <template v-if="[0,1,3,4,6,8].includes(item.type)">
                    <v-layout row align-center>
                        <v-flex @click="headingSelect(item)" shrink class="pointer">
                            <v-icon class="ma-0 pa-0" style="font-size: 18px" :color="selectionHeadingColors[item.selections]">{{selectionHeadingIcons[item.selections]}}</v-icon>
                        </v-flex>
                        <v-flex class="pl-2 py-1" :class="item.access == 0 ? 'red--text' : ''">
                            {{item.description}}
                        </v-flex>
                    </v-layout>
                </template>

                <template v-if="[2, 5, 7].includes(item.type)">
                    <v-layout row align-center>

                        <v-flex shrink>
                            <v-tooltip top>
                                <v-icon
                                        @click="fieldSelect(item, item.type == 2 ? 'wf' : (item.type == 5 ? 'df' : 'sf'))"
                                        class="ma-0 pa-0"
                                        style="font-size: 18px"
                                        :color="selectionFieldColors[item.selections.field]"
                                        slot="activator"
                                        @dragstart="$event.dataTransfer.setData('text', JSON.stringify({item: item, type: item.type == 2 ? 'wf' : (item.type == 5 ? 'df' : 'sf')}))"
                                        draggable="true"
                                >
                                    {{item.selections.field == 0 ? 'check_box_outline_blank' : 'check_box'}}
                                </v-icon>
                                <span>{{`Field ${selectionFieldText[item.selections.field]}`}}</span>
                            </v-tooltip>
                        </v-flex>

                        <v-flex shrink class="pl-1">
                            <v-tooltip top>
                                <v-icon
                                        @click="fieldSelect(item, item.type == 2 ? 'wu' : (item.type == 5 ? 'du' : 'su'))"
                                        class="ma-0 pa-0"
                                        style="font-size: 18px"
                                        :color="selectionFieldColors[item.selections.user]"
                                        slot="activator"
                                        @dragstart="$event.dataTransfer.setData('text', JSON.stringify({item: item, type: item.type == 2 ? 'wu' : (item.type == 5 ? 'du' : 'su')}))"
                                        draggable="true"
                                >
                                    {{item.selections.user == 0 ? 'person_outline' : 'person'}}
                                </v-icon>
                                <span>{{`User, ${selectionFieldText[item.selections.user]}`}}</span>
                            </v-tooltip>
                        </v-flex>
                        <v-flex shrink class="pl-1">
                            <v-tooltip top>
                                <v-icon
                                        @click="fieldSelect(item, item.type == 2 ? 'wd' : (item.type == 5 ? 'dd' : 'sd'))"
                                        class="ma-0 pa-0"
                                        style="font-size: 18px"
                                        :color="selectionFieldColors[item.selections.date]"
                                        slot="activator"
                                        @dragstart="$event.dataTransfer.setData('text', JSON.stringify({item: item, type: item.type == 2 ? 'wd' : (item.type == 5 ? 'dd' : 'sd')}))"
                                        draggable="true"
                                >
                                    {{item.selections.date == 0 ? 'calendar_today' : 'today'}}
                                </v-icon>
                                <span>{{`Date, ${selectionFieldText[item.selections.date]}`}}</span>
                            </v-tooltip>
                        </v-flex>
                        <v-flex class="pl-2 py-1" :class="item.access == 0 ? 'red--text' : ''">
                            {{item.description}}
                        </v-flex>
                    </v-layout>
                </template>
                <template v-if="[9].includes(item.type)">
                    <v-layout row align-center>

                        <v-flex shrink>
                            <v-tooltip top>
                                <v-icon
                                        @click="fieldSelect(item, 'tf')"
                                        class="ma-0 pa-0"
                                        style="font-size: 18px"
                                        :color="selectionFieldColors[item.selections]"
                                        slot="activator"
                                        @dragstart="$event.dataTransfer.setData('text', JSON.stringify({item: item, type: 'tf'}))"
                                        draggable="true"
                                >
                                    {{item.selections == 0 ? 'check_box_outline_blank' : 'check_box'}}
                                </v-icon>
                                <span>{{`Field, ${selectionFieldText[item.selections]}`}}</span>
                            </v-tooltip>
                        </v-flex>
                        <v-flex class="pl-2 py-1" :class="item.access == 0 ? 'red--text' : ''">
                            {{item.description}}
                        </v-flex>
                    </v-layout>
                </template>
            </template>
        </v-treeview>

    </div>
</template>

<script>
    import {codeBaseMixin} from "../../../codeBaseMixin";

    export default {
        mixins: [codeBaseMixin],
        props: {
            selectedReport: Object,
            userInfo: Object,
            steps: Array,
            fields: Array,
            dataSets: Array,
            allDataSetFields: Array,
            allWorkflowStatuses: Array,
            selectedFields: Array
        },
        data() {
            return {
                headingTypes: {
                    workflowHeading: 0,
                    stepHeading: 1,
                    workflowField:2,
                    dataSetHeading: 3,
                    dataSetSelect: 4,
                    dataSetField: 5,
                    workflowStatusHeading: 6,
                    workflowStatus: 7,
                    taskFieldHeading: 8,
                    taskField: 9,
                },
                selectionHeadingIcons: {
                    0: 'check_box_outline_blank',
                    1: 'indeterminate_check_box',
                    2: 'check_box'
                },
                selectionHeadingColors: {
                    0: 'grey',
                    1: 'grey',
                    2: 'primary'
                },
                selectionFieldColors: {
                    0: 'grey',
                    1: 'primary',
                    2: 'secondary'
                },
                selectionFieldText: {
                    0: 'Click to Select',
                    1: 'Selected',
                    2: 'Selected (With Aggregate)'
                },
                allTaskFields: [
                    {id: 0, description: 'Task ID', order: 0},
                    {id: 1, description: 'Task Title', order: 1},
                    {id: 2, description: 'Task Owner', order: 2},
                    {id: 3, description: 'Folder Name', order: 4},
                    {id: 4, description: 'Folder Groups', order: 5},
                    {id: 5, description: 'Task State', order: 6},
                    // {id: 6, description: 'Task Percentage', order: 6},
                    {id: 7, description: 'Current Status', order: 7},
                    {id: 8, description: 'Task Created', order: 8},
                    {id: 9, description: 'Task Age (Days)', order: 9},
                    {id: 10, description: 'Task Duration (Days)', order: 10},
                    {id: 11, description: 'Task Escalation Duration (Days)', order: 11},
                    {id: 12, description: 'Task Sub Owner', order: 3},
                    {id: 13, description: 'Last Viewed By', order: 12},
                    {id: 14, description: 'Last Viewed Date', order: 13},
                ]
            }
        },
        methods: {
            headingSelect(heading) {
                switch (heading.type) {
                    case this.headingTypes.stepHeading: {
                        let stepFields = heading.children.reduce((list, field) => {
                            if (
                                (heading.selections < 2 && field.selections.field != 1)
                                || (heading.selections == 2 && field.selections.field > 0)
                            ) {
                                list.push(field.id)
                            }
                            return list
                        }, []);

                        this.$emit(heading.selections == 2 ? 'remove' : 'add', {fieldType: 'wf', id: stepFields, dsSelect: null, aggregate: null});
                        break;
                    }
                    case this.headingTypes.workflowHeading: {
                        let allFields = heading.children.reduce((fullList, step) => {
                            let stepFields = step.children.reduce((list, field) => {
                                if (
                                    (heading.selections < 2 && field.selections.field != 1)
                                    || (heading.selections == 2 && field.selections.field > 0)
                                ) {
                                    list.push(field.id)
                                }
                                return list
                            }, []);
                            fullList = fullList.concat(stepFields);
                            return fullList
                        }, []);

                        this.$emit(heading.selections == 2 ? 'remove' : 'add', {fieldType: 'wf', id: allFields, dsSelect: null, aggregate: null});
                        break
                    }
                    case this.headingTypes.dataSetSelect: {
                        let stepFields = heading.children.reduce((list, field) => {
                            if (
                                (heading.selections < 2 && field.selections.field != 1)
                                || (heading.selections == 2 && field.selections.field > 0)
                            ) {
                                list.push(field.id)
                            }
                            return list
                        }, []);

                        this.$emit(heading.selections == 2 ? 'remove' : 'add', {fieldType: 'df', id: stepFields, dsSelect: heading.id, aggregate: null});
                        break;
                    }
                    case this.headingTypes.dataSetHeading: {
                        let allFields = heading.children.reduce((fullList, dataSetSelect) => {
                            let dataSetSelectFields = dataSetSelect.children.reduce((list, field) => {
                                if (
                                    (heading.selections < 2 && field.selections.field != 1)
                                    || (heading.selections == 2 && field.selections.field > 0)
                                ) {
                                    list.push({id: field.id, dsSelect: dataSetSelect.id})
                                }
                                return list
                            }, []);
                            fullList = fullList.concat(dataSetSelectFields);
                            return fullList
                        }, []);
                        this.$emit(heading.selections == 2 ? 'remove' : 'add', {fieldType: 'df', values: allFields, aggregate: null});
                        break
                    }
                    case this.headingTypes.workflowStatusHeading: {
                        let statusFields = this.selectedFields.filter((obj) => obj.fieldType == 'sf' && obj.aggregate == null).map(obj => {return obj.id});

                        this.$emit(heading.selections == 2 ? 'remove' : 'add', {
                            fieldType: 'sf',
                            id: heading.selections == 2 ? statusFields : this.workflowStatuses.filter(obj => !statusFields.includes(obj.id)).map((obj) => {return obj.id}),
                            aggregate: null
                        });
                        break;
                    }
                    case this.headingTypes.taskFieldHeading: {
                        let taskFields = this.selectedFields.filter((obj) => obj.fieldType == 'tf' && obj.aggregate == null).map(obj => {return obj.id});
                        this.$emit(heading.selections == 2 ? 'remove' : 'add', {
                            fieldType: 'tf',
                            id: heading.selections == 2 ? taskFields : this.taskFields.filter(obj => !taskFields.includes(obj.id)).map((obj) => {return obj.id}),
                            aggregate: null
                        });

                        break;
                    }
                }
            },

            fieldSelect(field, selectionType) {
                let keyVar = {
                    wf: 'field',
                    wu: 'user',
                    wd: 'date',
                    df: 'field',
                    du: 'user',
                    dd: 'date',
                    sf: 'field',
                    su: 'user',
                    sd: 'date',
                };

                if (selectionType != 'tf') {
                    this.$emit(
                        field.selections[keyVar[selectionType]] >= 1 ? 'remove' : 'add',
                        {
                            fieldType: selectionType,
                            id: field.id,
                            dsSelect: ['df', 'du', 'dd'].includes(selectionType) ? field.fk_field_set : null,
                            aggregate: null
                        }
                    )
                } else {
                    this.$emit(
                        field.selections >= 1 ? 'remove' : 'add',
                        {
                            fieldType: selectionType,
                            id: field.id,
                            dsSelect: null,
                            aggregate: null
                        }
                    )
                }


            }
        },
        computed: {
            totalFields() {
                let workflowSelections = this.workflowFields.reduce((selections, step) => {
                    return selections + step.selections
                }, 0);
                let dataSetSelections = this.dataSetFields.reduce((selections, dataSet) => {
                    return selections + dataSet.selections
                }, 0);
                // let taskFieldSelections = this.taskFields.reduce((selections, field) => {
                //     return selections + field.selections
                // }, 0);
                workflowSelections = workflowSelections <= 0
                    ? 0
                    : (
                        workflowSelections == (this.workflowFields.length * 2)
                            ? 2
                            : 1
                    );
                dataSetSelections = dataSetSelections <= 0
                    ? 0
                    : (
                        dataSetSelections == (this.dataSetFields.length * 2)
                            ? 2
                            : 1
                    );
                let workflowStatusesSelections = this.selectedFields.filter(obj => obj.fieldType == 'sf' && obj.aggregate == null).length;
                workflowStatusesSelections = workflowStatusesSelections <= 0
                    ? 0
                    : (
                        workflowStatusesSelections == (this.workflowStatuses.length)
                            ? 2
                            : 1
                    );

                let taskFieldSelections = this.selectedFields.filter(obj => obj.fieldType == 'tf' && obj.aggregate == null).length;


                taskFieldSelections = taskFieldSelections <= 0
                    ? 0
                    : (
                        taskFieldSelections == (this.taskFields.length)
                            ? 2
                            : 1
                    );

                let tList = [{
                    description: 'Task Fields',
                    id: 0,
                    refKey: `taskFields`,
                    children: this.taskFields,
                    type: this.headingTypes.taskFieldHeading,
                    selections: taskFieldSelections
                }];

                if (this.workflowFields.length > 0) {tList.push(
                    {
                        description: 'Workflow Fields',
                        id: 0,
                        refKey: `workflowFields`,
                        children: this.workflowFields,
                        type: this.headingTypes.workflowHeading,
                        selections: workflowSelections
                    }
                )}

                if (this.dataSetFields.length > 0) {tList.push(
                    {
                        description: 'Dataset Fields',
                        id: 0,
                        refKey: `dataSetFields`,
                        children: this.dataSetFields,
                        type: this.headingTypes.dataSetHeading,
                        selections: dataSetSelections
                    }
                )}

                if (this.workflowStatuses.length > 0) {tList.push(
                    {
                        description: 'Workflow Statuses',
                        id: 0,
                        refKey: `workflowStatuses`,
                        children: this.workflowStatuses,
                        type: this.headingTypes.workflowStatusHeading,
                        selections: workflowStatusesSelections
                    }
                )}

                return tList;
            },

            workflowFields() {
                return this.steps
                    .filter(() => true)
                    .sort((a,b) => a['field_order'] < b['field_order'] ? -1 : 1)
                    .reduce((steps, step) => {
                        let tChildren = this.fields
                            // Filter fields And Add The Ref Key
                            .filter((field) => field.fk_step == step.id)
                            .sort((a,b) => a['field_order'] < b['field_order'] ? -1 : 1)
                            .map(field => {
                                let tField = {
                                    id: field.id,
                                    description: `${field.description}${!field.visible ? ` (Disabled)` : ''}`,
                                    refID: `workflowField:${field.id}`,
                                    type: this.headingTypes.workflowField,
                                    systemFieldType: field.fieldType,
                                    fk_step: field.fk_step,
                                    access: field.access,
                                    selections: this.selectedFields
                                        .filter(obj => ['wf','wu','wd'].includes(obj.fieldType) && obj.id == field.id)
                                        .reduce((selections, selection) => {
                                            if (selections.field != 2 && selection.fieldType == `wf`) {selections.field = selection.aggregate == null ? 1 : 2}
                                            if (selections.user != 2 && selection.fieldType == `wu`) {selections.user = selection.aggregate == null ? 1 : 2}
                                            if (selections.date != 2 && selection.fieldType == `wd`) {selections.date = selection.aggregate == null ? 1 : 2}
                                            return selections
                                        }, {field: 0, user: 0, date: 0}),
                                };
                                return tField;
                            });

                        if (tChildren.length > 0) {

                            let tStepSelection = tChildren.filter(obj => obj.selections.field >= 1).length;
                            tStepSelection = tStepSelection <= 0
                                ? 0
                                : (
                                    tStepSelection == tChildren.length
                                        ? 2
                                        : 1
                                );

                            let tStep = {
                                id: step.id,
                                description: step.description,
                                refID: `step:${step.id}`,
                                type: this.headingTypes.stepHeading,
                                access: step.access,
                                selections: tStepSelection,
                                children: tChildren
                            };

                            steps.push(tStep)
                        }

                        return steps
                    }, [])
            },

            dataSetFields() {
                return this.dataSets
                    .filter(() => true)
                    .sort((a,b) => a['field_order'] < b['field_order'] ? -1 : 1)
                    .reduce((dataSets, dataSet) => {


                        let tChildren = this.allDataSetFields
                            // Filter fields And Add The Ref Key
                            .filter((field) => field.fk_field_set == dataSet.fk_dataset)
                            .sort((a,b) => a['order'] < b['order'] ? -1 : 1)
                            .map(field => {
                                let tField = {
                                    id: field.id,
                                    description: field.description,
                                    refID: `dataSetField:${dataSet.fieldID}-${field.id}`,
                                    type: this.headingTypes.dataSetField,
                                    systemFieldType: field.type,
                                    fk_field_set: dataSet.fieldID,
                                    access: field.access,
                                    selections: this.selectedFields
                                        .filter(obj => ['df','du','dd'].includes(obj.fieldType) && obj.id == field.id && obj.dsSelect == dataSet.fieldID)
                                        .reduce((selections, selection) => {
                                            if (selections.field != 2 && selection.fieldType == `df`) {selections.field = selection.aggregate == null ? 1 : 2}
                                            if (selections.user != 2 && selection.fieldType == `du`) {selections.user = selection.aggregate == null ? 1 : 2}
                                            if (selections.date != 2 && selection.fieldType == `dd`) {selections.date = selection.aggregate == null ? 1 : 2}
                                            return selections
                                        }, {field: 0, user: 0, date: 0}),
                                };
                                return tField;
                            });

                        if (tChildren.length > 0) {

                            let tStepSelection = tChildren.filter(obj => obj.selections.field >= 1).length;
                            tStepSelection = tStepSelection <= 0
                                ? 0
                                : (
                                    tStepSelection == tChildren.length
                                        ? 2
                                        : 1
                                );

                            let tDSSelect = {
                                id: dataSet.fieldID,
                                description: `${dataSet.fieldDescription} - ${dataSet.description}`,
                                refID: `dataSet:${dataSet.fieldID}`,
                                type: this.headingTypes.dataSetSelect,
                                access: dataSet.access,
                                selections: tStepSelection,
                                children: tChildren
                            };

                            dataSets.push(tDSSelect)
                        }

                        return dataSets
                    }, [])
            },

            workflowStatuses() {
                return this.allWorkflowStatuses
                    .filter(() => true)
                    .sort((a,b) => a['order'] < b['order'] ? -1 : 1)
                    .map(status => {
                        let tStatus = {
                            id: status.id,
                            description: status.description,
                            refID: `workflowStatus:${status.id}`,
                            type: this.headingTypes.workflowStatus,
                            access: 1,
                            selections: this.selectedFields.filter(obj => ['sf', 'su', 'sd'].includes(obj.fieldType) && obj.id == status.id)
                                .reduce((selections, selection) => {
                                    if (selections.field != 2 && selection.fieldType == `sf`) {selections.field = selection.aggregate == null ? 1 : 2}
                                    if (selections.user != 2 && selection.fieldType == `su`) {selections.user = selection.aggregate == null ? 1 : 2}
                                    if (selections.date != 2 && selection.fieldType == `sd`) {selections.date = selection.aggregate == null ? 1 : 2}
                                    return selections
                                }, {field: 0, user: 0, date: 0})
                        };
                        return tStatus
                    })
            },

            taskFields() {
                return this.allTaskFields
                    .filter(() => true)
                    .sort((a,b) => a['order'] < b['order'] ? -1 : 1)
                    .map(field => {
                        let tField = {
                            id: field.id,
                            description: field.description,
                            refID: `taskField:${field.id}`,
                            type: this.headingTypes.taskField,
                            access: 1,
                            selections: this.selectedFields.filter(obj => ['tf'].includes(obj.fieldType) && obj.id == field.id)
                                .reduce((selections, selection) => {
                                    if (selections != 2) {selections = selection.aggregate == null ? 1 : 2}
                                    return selections
                                }, 0)
                        };
                        return tField
                    })
            },

        }
    }
</script>

<style scoped>

</style>
