<template>
  <v-layout column fill-height class="scrollable">
    
    <!--        <slider-scroll></slider-scroll>-->
    
    <div class="bord" v-show="false" v-if="preloadEditor">
      <editor
          :extra-config="preloadEditorConfig()"
      />
    </div>
<!--    <v-flex-->
<!--        shrink-->
<!--        class="py-1 text-xs-center red white&#45;&#45;text caption"-->
<!--    >-->
<!--      <v-btn @click="testFn">Test</v-btn>-->
<!--      -->
<!--    </v-flex>-->
    <v-flex
        shrink
        v-if="typeof this.userInfo.outOfOffice != 'undefined' && this.intToBool(this.userInfo.outOfOffice)"
        class="py-1 text-xs-center red white--text caption"
    >
      {{ `${userInfo.name} ${userInfo.surname} - Out of Office is Enabled` }}
    </v-flex>
    
    <v-flex
        shrink
        v-if="softBlock"
        class="py-1 text-xs-center red white--text body-2 c-d-flex c-align-center c-justify-center"
    >
      <v-icon class="mr-2" color="white">block</v-icon>
      {{ userInfo.entityInfo.softBlock }}
    </v-flex>
    
    <doc-repo v-show="showRepo" v-model="showRepo" ref="repo"/>
    
    <v-flex xs12 fill-height class="scrollable" style="overflow-x: hidden; overflow-y: auto">
      <v-layout row fill-height style="overflow-x: hidden; overflow-y: auto">
        <v-flex xs3 v-show="showBand" style="overflow-x: hidden; overflow-y: auto; z-index: 2" >
          <app-task-band
              :tasks="publishedLocalData.tasks"
              :userInfo="userInfo"
              :taskLoading="loadingHeaders"
              :filter-settings="taskDataStore.filterSettings"
              @getTaskHeaders="_initializeHeaders(taskDataStore.filterSettings.stateOption <= 2 ? 0 : 1)"
              :dashboard="true"
              :curView="curView"
              :selectedTaskID="selectedTaskID"
              @taskSelected="selectTask($event)"
              :local-data="publishedLocalData"
              :show-sync="localDataBases.data.syncing"
              @closedFilterUpdate="closedFilterSearch($event)"
              :load-more="loadMore"
              
              :get-task-header="getTaskHeader"
              @linkMailToTask="linkMailToTask()"
              :show-link-smtp="(showLinkSmtp && curView === 'smtp') || (showLinkSmtpMailbox && curView === 'mailboxes')"
              @refresh="_initializeHeaders"
              :refresh-loading="initTasks"
              :linking-mail="linkingMail"
              @chatMessagesLink="rtcmRelay.emit('chatMessagesLink', $event)"
          />
        </v-flex>
        <v-flex :xs9="showBand" :xs12="!showBand">
          <v-layout fill-height align-space-between justify-start column>
            <v-flex class="fill-height">
              <keep-alive>
                <template
                    v-if="curView == 'app-gmail' && typeof userInfo.gmailEnabled != 'undefined' && intToBool(userInfo.gmailEnabled)"
                    :userInfo="userInfo">
                  <!--                                    <g2 :mail-config="mailConfig" v-if="userInfo.userID === 1"/>-->
                  <app-gmail
                      :user-info="userInfo"
                      :task-data="taskData"
                      @getTaskHeaders="getTaskHeaders()"
                      :folder-groups="folderGroups"
                      :cur-view="curView"
                      :mail-config="mailConfig"
                      @pushTask="taskDataStore.tasks = taskDataStore.tasks.concat([$event])"
                      :local-data="publishedLocalData"
                      :show-sync="localDataBases.data.syncing"
                      :show-link-gmail="showLinkToGmail"
                      @showLinkGmailChange="updateShowLinkGmail"
                      @setGmailMail="gmailLinkMail = $event"
                      :get-task-header="getTaskHeader"
                  />
                
                </template>
                
                
                <app-mail
                    v-if="['mail', 'smtp', 'mailboxes'].includes(curView)"
                    :task-data="taskData"
                    :userInfo="userInfo"
                    :cur-view="curView"
                    
                    @getTaskHeaders="getTaskHeaders()"
                    
                    @showLinkOffice="showLinkOffice = $event"
                    @setOfficeMail="officeLinkMail = $event"
                    :show-link-office="showLinkOffice"
                    :show-link-smtp="showLinkSmtp"
                    
                    @createTaskOffice="initCreateTaskOffice($event)"
                    @createTaskSMTP="initCreateTaskSmtp($event)"
                    @createTaskSMTPMailbox="initCreateTaskSmtpMailbox($event)"
                    @setQT="quickTaskConfig = $event"
                    :mail-config="mailConfig"
                    @pushTask="pushTask($event)"
                    :get-task-header="getTaskHeader"
                    
                    @selectedSmtpMails="SMTPLinkMail = $event"
                    @selectedSmtpMailboxMails="SMTPLinkMailboxMail = $event"
                />
                
                <UserCalendar v-if="curView === 'calendar'" :cur-view="curView"/>
              </keep-alive>
              <app-dashboard-view
                  v-show="curView == 'app-dashboard-view'"
                  :userInfo="userInfo"
                  :task-data="taskData"
                  :folder-groups="folderGroups"
                  @getTaskHeaders="getTaskHeaders()"
                  @newNotifcations="newNotifcations($event)"
                  :cur-view="curView"
                  :user-groups="userGroups"
                  :escalation-values="taskEscalations"
                  
                  :selectedTaskID="selectedTaskID"
                  @taskSelected="selectTask($event)"
                  :cur-time-minute="curTimeMinute"
                  
                  :init-tasks="initTasksLoaded"
                  :get-task-header="getTaskHeader"
                  :escalation-view-tasks="escalationViewTasks"
                  :filter-options="taskDataStore.filterSettings"
                  :local-data="publishedLocalData"
              />
              <app-tasks
                  :cur-view="curView"
                  v-show="curView == 'app-tasks'"
                  :userInfo="userInfo"
                  :task-data="taskData"
                  :folder-groups="folderGroups"
                  :selectLists="selectLists"
                  :mail-config="mailConfig"
                  :cur-time="curTime"
                  :cur-time-minute="curTimeMinute"
                  @updateTask="updateTask($event)"
                  :local-data="publishedLocalData"
                  :show-sync="localDataBases.data.syncing"
                  :task-list="headerVault"
                  :get-task-header="getTaskHeader"
              />
              <app-chat
                  :cur-view="curView"
                  v-show="curView === 'chat'"
                  :local-data="publishedLocalData"
                  :task-list="headerVault"
                  :get-task-header="getTaskHeader"
                  :show-sync="localDataBases.data.syncing"
              />
              <!--                            <calendar-view-->
              <!--                                :cur-view="curView"-->
              <!--                                v-show="curView == 'calendar'"-->
              <!--                                :user-info="userInfo"-->
              <!--                                :local-data="localData"-->
              <!--                                :get-task-header="getTaskHeader"-->
              <!--                            />-->
            
            </v-flex>
            <!--&lt;!&ndash;Dialoges&ndash;&gt;-->
            <!--&lt;!&ndash;                        &ndash;&gt;-->
            <app-transfer-task-dialogue/>
            <app-transfer-task-dialogue-sub-owner/>
            <app-signature-manager
                v-if="typeof userInfo.signatureManager != 'undefined' && intToBool(userInfo.signatureManager) && showSigManager"
                :user-info="userInfo"
                :local-data="localData"
                v-model="showSigManager"
            />
            <!--                        <app-gmail-group-mail-box-manager-->
            <!--                            v-if="typeof userInfo.groupMailManager != 'undefined' && intToBool(userInfo.groupMailManager)"-->
            <!--                            :user-info="userInfo"-->
            <!--                        />-->
            <app-field-set-editor-dlg
                v-if="typeof userInfo.fieldsetManager != 'undefined' && intToBool(userInfo.fieldsetManager)"
                :user-info="userInfo"
            />
            <app-workflow-editor
                v-if="intToBool(userInfo.workflowManager)"
                :show-dialog="showWorkflowEditor"
                :user-info="userInfo"
                @dismiss="showWorkflowEditor = false"
                :folder-groups="folderGroups"
                :local-data="publishedLocalData"
                :show-sync="localDataBases.data.syncing"
                :get-task-header="getTaskHeader"
            />
            <app-data-set-manager
                v-if="showFieldSetManager && typeof userInfo.fieldsetManager != 'undefined' && intToBool(userInfo.fieldsetManager)"
                :show-dialog="showFieldSetManager"
                @dismiss="showFieldSetManager = false"
                :user-info="userInfo"
            />
            <app-field-note-editor-dialog
                v-if="typeof userInfo.userID != 'undefined'"
                v-model="editorHtml"
                :shown="showNoteFieldEditor"
                @dismiss="editNoteDismissed($event)"
                :user-info="userInfo"
                :field-name="noteFieldDescription"
            />
            
            <!--Snacks-->
            <v-snackbar
                v-model="mailLink"
                :timeout="snackTime"
                top
                center
            >
              Mail/s Linked to Task
              <v-btn
                  flat
                  @click="gotoTask()"
                  class="secondary--text"
              >
                Go to Task
              </v-btn>
              <v-btn
                  flat
                  @click="mailLink = false"
                  class="secondary--text"
              >
                Close
              </v-btn>
            </v-snackbar>
            <v-snackbar
                v-model="taskCreated"
                :timeout="snackTime"
                top
                center
            >
              Task #{{ task.taskID }} Created
              <v-btn
                  flat
                  @click="gotoTask()"
                  class="secondary--text"
              >
                Go to Task
              </v-btn>
              <v-btn
                  flat
                  @click="taskCreated = false"
                  class="secondary--text"
              >
                Close
              </v-btn>
            </v-snackbar>
            <v-snackbar
                top
                center
                :value="50"
                v-model="showMessageBox"
                :timeout="snackTime"
            >
              <template v-if="messageBox.title != null && messageBox.title != ''">
                        <span
                            class="subheading font-weight-bold pr-2"
                            :class="messageBox.titleColor + '--text'"
                        >
                            {{ messageBox.title }}
                        </span>
              </template>
              
              <span class="body-1">{{ messageBox.message }}</span>
              
              <v-btn
                  flat
                  @click="showMessageBox = false"
                  class="secondary--text"
              >
                {{ messageBox.buttonText }}
              </v-btn>
            </v-snackbar>
            <v-snackbar
                v-model="taskSendMail"
                :timeout="60000"
                top
                center
            >
              Sending Mail
              <v-btn
                  flat
                  @click="taskSendMail = false"
                  class="secondary--text"
              >
                Hide
              </v-btn>
            </v-snackbar>
            <!--Create Task Office Mail-->
            <template>
              <app-new-task-dlg
                  :show-dialog="showCreateTaskOffice"
                  :user-info="userInfo"
                  @dismiss="showCreateTaskOffice = false"
                  :mail-task="true"
                  :mail-i-d="officeLinkMail != null ? officeLinkMail.id : '0'"
                  :mail="officeLinkMail"
                  :folder-groups="folderGroups"
                  mailType='Office'
                  :local-data="publishedLocalData"
                  :show-sync="localDataBases.data.syncing"
                  :get-task-header="getTaskHeader"
                  @clearQuick="quickTaskConfig = null"
                  :quick-task="quickTaskConfig"
              />
            </template>
            
            <!--Create Task SMTP Mail-->
            <template>
              <app-new-task-dlg
                  :show-dialog="showCreateTaskSmtp"
                  :user-info="userInfo"
                  @dismiss="showCreateTaskSmtp = false"
                  :mail-task="true"
                  :mail-i-d="SMTPLinkMail != null ? `${SMTPLinkMail.id}` : '0'"
                  :mail="SMTPLinkMail"
                  :folder-groups="folderGroups"
                  mailType='SMTP'
                  :local-data="publishedLocalData"
                  :show-sync="localDataBases.data.syncing"
                  :get-task-header="getTaskHeader"
                  @clearQuick="quickTaskConfig = null"
                  :quick-task="quickTaskConfig"
              />
              <app-new-task-dlg
                  :show-dialog="showCreateTaskSmtpMailbox"
                  :user-info="userInfo"
                  @dismiss="showCreateTaskSmtpMailbox = false"
                  :mail-task="true"
                  :mail-i-d="SMTPLinkMailboxMail != null ? `${SMTPLinkMailboxMail.id}` : '0'"
                  :mail="SMTPLinkMailboxMail"
                  :folder-groups="folderGroups"
                  mailType='MAILBOX'
                  :local-data="publishedLocalData"
                  :show-sync="localDataBases.data.syncing"
                  :get-task-header="getTaskHeader"
                  @clearQuick="quickTaskConfig = null"
                  :quick-task="quickTaskConfig"
              />
            </template>
            
            <app-input-dlg
                v-if="showFieldSetSaveChanges"
                :show-dialog="showFieldSetSaveChanges"
                title="Save Dataset Changes"
                :cancel-button="true"
                :show-input-box="false"
                @dismiss="showFieldSetSaveChanges = false"
                :body-padding="false"
            >
              <template slot="body">
                <div class="pa-2 body-1">
                  {{
                    `Changes were made to: "${datasetChangeObject.dataSetDescription}". Would you like to save these changes or discard`
                  }}
                </div>
              
              </template>
              <template slot="actions">
                <v-btn small class="ma-0 mr-2" color="secondary" :disabled="savingFieldSetChanges"
                       @click="showFieldSetSaveChanges = false">
                  <v-icon>close</v-icon>
                  Discard
                </v-btn>
                <v-btn small class="ma-0" color="secondary" :disabled="savingFieldSetChanges"
                       @click="saveFieldSetChanges">
                  <v-icon left>save</v-icon>
                  Save
                </v-btn>
              </template>
            </app-input-dlg>
            <!--Folders-->
            <app-folder-dlg
                :allow-remove-contact="true"
                ref="folders"
                :show-dialog="showFolders"
                @dismiss="showFolders = false"
                :select-dlg="true"
                :user-info="userInfo"
                dlg-type="allFolders"
                :local-data="publishedLocalData"
                :show-sync="localDataBases.data.syncing"
                :get-task-header="getTaskHeader"
            />
            <!--Mail Size Error-->
            <app-input-dlg
                :show-dialog="showMailSizeError"
                title="Mail Size Limit"
                :cancel-button="true"
                :show-input-box="false"
                @dismiss="mailSizeErrorData = null"
                :body-padding="false"
                heading-color="red"
            >
              <template slot="body">
                <div class="pa-2">
                  <div :class="headingClass" class="pb-2">
                    Error sending Mail. Mail exceeds the maximum message size of 25Mb. Please remove
                    one or more attachments and try again.
                  </div>
                  <v-divider></v-divider>
                  <template v-for="(item, i) in mailSizeErrorData">
                    <v-layout row align-center class="hover-item" :key="i">
                      <v-flex xs10 class="px-2 py-2">
                        {{ item.fileName }}
                      </v-flex>
                      <v-flex xs2 class="pr-2 text-xs-right">
                        {{ `${Math.round(item.size * 100) / 100} Mb` }}
                      </v-flex>
                    </v-layout>
                    <v-divider :key="i + 'div'"></v-divider>
                  </template>
                
                </div>
              </template>
            </app-input-dlg>
          
          </v-layout>
        </v-flex>
      </v-layout>
    </v-flex>
    
    <v-dialog
        v-model="showSync"
        persistent
        fullscreen
    >
      <v-layout column fill-height style="background: var(--page-back)">
        <v-flex xs12></v-flex>
        <v-flex shrink class="text-xs-center">
          <div class="pa-2">
            <img :src="$store.state.darkTheme ? 'colorFullWhiteText.svg' : 'colorFull.svg'" width="300px"
                 @click="typeof userInfo.userID != 'undefined' && (userInfo.userID == 1 || userInfo.userID == 11) ? nav('syncStat') : ''">
          </div>
          <div class="pa-2">
            <v-progress-circular
                :size="40"
                :width="3"
                color="secondary"
                indeterminate
            />
          </div>
          <div class="greyType--text subheading pa-2">Loading Data</div>
        </v-flex>
        <v-flex xs12></v-flex>
      
      </v-layout>
    </v-dialog>
    
    <app-workflow-reports
        :user-info="userInfo"
        :show-report-manager="showReports"
        @dismiss="showReports = false"
        :get-task-header="getTaskHeader"
        @showReports="showHideReports()"
        :local-data="localData"
    ></app-workflow-reports>
    
    <folder-group-manager
        v-if="showFolderManager"
        :local-data="localData"
        :user-info="userInfo"
        v-model="showFolderManager"
    />
    
    <app-browse-contacts v-if="userInfo && userInfo.userID && showContacts" :shown.sync="showContacts"
                         ref="contactView">
      
      <template #toolbarEnd>
        <div class="">
          <app-create-link-contact :local-data="publishedLocalData" @input="contactAdded($event)">
            <template #activator>
              <div class="py-0 my-0">
                <v-btn flat class="" color="white" small>
                  <v-icon left>add</v-icon>
                  Create / Link Contact
                </v-btn>
              </div>
            </template>
          </app-create-link-contact>
        </div>
      </template>
    
    </app-browse-contacts>
    
    <user-manager
        v-if="intToBool(userInfo.userManager)"
        :show-dialog="showUserManger"
        :user-info="userInfo"
        :localData="publishedLocalData"
        @dismiss="showUserManger = false"
    />
    
    <document-repository-manager
        v-if="userInfo.docRepoManager && showRepoManager"
        v-model="showRepoManager"
    />
    
    <productivity-reports
        v-if="userInfo.productivityReports && showProductivityReports"
        v-model="showProductivityReports"
        @dismiss="showProductivityReports = false"
    />
    
    <task-header-report
        v-if="userInfo.taskHeaderReports && showTaskHeaderReport"
        v-model="showTaskHeaderReport"
        @dismiss="showTaskHeaderReport = false"
    />
    
    <calendar-groups
        v-if="userInfo.calGroupManager && showCalenderGroups"
        v-model="showCalenderGroups"
    />
    
    <user-groups
        v-if="(userInfo.userManager || userInfo.workflowManager || userInfo.fieldsetManager || userInfo.folderManager) && showUGM"
        v-model="showUGM"
    />
    
    <mailbox-manager v-if="userInfo.smtpMailboxManager && showSmtpMailboxManager" v-model="showSmtpMailboxManager"/>
    
    <two-factor
        v-if="showTwoFactor"
        v-model="showTwoFactor"
        :userID="userInfo.userID"
    />
    
    <app-configure-contacts
        v-if="showManageContacts"
        :shown.sync="showManageContacts"
    />
    
    <event-manager/>
  
    
    <app-image-preview/>
    
    <app-input-prompt/>
  </v-layout>
</template>

<script>
import {eventBus} from '../main';
import {codeBaseMixin} from '../codeBaseMixin';
import {localDataMixin} from "../localDataMixin";
import Gmail from '../Mail/Gmail/Gmail.vue';
import Tasks from '../Tasks/TaskScreen.vue';
import DashboardView from '../Dashboard/DashboardScreen';
import TransferTaskDialogue from '../Tasks/TranferTask/TransferTaskDialogue';
import TransferTaskDialogueSubOwner from '../Tasks/TranferTask/TransferTaskDialogueSubOwner';
import SignatureManager from '../Admin/Signatures/SigManager';
// import GmailGroupMailBoxManager from '../Admin/GmailGroupMailbox/GmailGroupMailBoxManager';
import FieldSetEditorDlg from '../Admin/FieldSetEditor/FieldSetEditorDlg.vue';
import Mail from '../Mail/Combined Mail/Mail'
import TaskBand from "../Tasks/TaskBand/TaskBand";
import NewTaskDlg from "../Tasks/NewTask/NewTaskDlg";
import WorkflowEditor from "../Admin/WorkflowEditor/WorkflowDlg";
import DataSetManager from "../Admin/Data Set Manager/DataSetManager";
import FieldNoteEditorDialog
  from "../Tasks/TaskView/components/TaskViewDetail/Components/FieldTypeComponents/FieldNoteEditor/FieldNoteEditorDialog";
import InputDlg from "../components/General/InputDlg";
import FolderDlg from '../Folders/FolderDlg'
import WorkflowReports from "../Workflow Reporting/WorkflowReports";
import {feed, initialize} from "../feedSocket";
import {dbMethods, dbEmitter} from "../localDBFunction"
import {arrPopulated} from "../codeFunctions";
import FolderGroupManager from "../Admin/Folder Group Manager/FolderGroupManager";
import {mapActions, mapGetters} from 'vuex';
import UserManager from "@/Admin/UserManager/UserManager";
import Editor from "@/Editor/Editor";
import MailboxManager from "@/Admin/MailboxManager/MailboxManager"
import DocumentRepositoryManager from "@/Admin/Document Repository Manager/DocumentRepositoryManager";
import DocRepo from "@/Document Repository/docRepo";
import {repoEvents, getRepoDocs} from '@/Document Repository/docRepoMan'
import TwoFactor from "@/components/Two Factor/TwoFactor";
import CalendarGroups from "@/Admin/Calendar Groups/CalendarGroups";
import UserCalendar from "@/Calendar View/User Calender View/UserCalendar";
import EventManager from "@/Calendar View/Event Manager/EventManager.vue";
import {initCalendar} from "@/Calendar View/Event Manager/EventManager";
import UserGroups from "@/Admin/UserGroupManager/UserGroups";
import ProductivityReports from "@/Productivity Reporting/productivityReports";
import TaskHeaderReport from "@/Task Header Report/TaskHeaderReport";
import AppConfigureContacts from "@/contacts/AppConfigureContacts.js.vue"
import AppBrowseContacts from "@/contacts/AppBrowseContacts.vue";
import AppCreateLinkContact from "@/contacts/AppCreateLinkContact.vue";
import AppImagePreview from "@/components/General/AppImagePreview.vue";
import AppChat from "@/chat/AppChat.vue";
import {rtcmRelay} from "@/chat/chatFunctions";
import AppInputPrompt from "@/components/General/AppInputPrompt/AppInputPrompt.vue";
import {showPrompt} from "@/components/General/AppInputPrompt/AppInputPrompt";


export default {
  // firebase: {
  //     notifications: db.ref('3/notifications/1'),
  // },
  mixins: [codeBaseMixin, localDataMixin],
  components: {
    AppInputPrompt,
    AppChat,
    AppCreateLinkContact,
    AppBrowseContacts,
    AppConfigureContacts,
    TaskHeaderReport,
    ProductivityReports,
    UserGroups,
    EventManager,
    UserCalendar,
    CalendarGroups,
    TwoFactor,
    DocRepo,
    DocumentRepositoryManager,
    MailboxManager,
    Editor,
    // EditorPlayground,
    UserManager,
    FolderGroupManager,
    // CreateTask,
    appWorkflowReports: WorkflowReports,
    appInputDlg: InputDlg,
    appFieldNoteEditorDialog: FieldNoteEditorDialog,
    appDataSetManager: DataSetManager,
    appWorkflowEditor: WorkflowEditor,
    appNewTaskDlg: NewTaskDlg,
    appTaskBand: TaskBand,
    appMail: Mail,
    appGmail: Gmail,
    appDashboardView: DashboardView,
    appTasks: Tasks,
    appTransferTaskDialogue: TransferTaskDialogue,
    appTransferTaskDialogueSubOwner: TransferTaskDialogueSubOwner,
    appSignatureManager: SignatureManager,
    // appGmailGroupMailBoxManager: GmailGroupMailBoxManager,
    appFieldSetEditorDlg: FieldSetEditorDlg,
    appFolderDlg: FolderDlg,
    AppImagePreview
  },
  data() {
    return {
      showManageContacts: false,
      linkingMail: false,
      showCalenderGroups: false,
      showFolderManager: false,
      quickTaskConfig: null,
      showTest: false,
      test: false,
      curView: 'app-tasks',
      task: {},
      mailLink: false,
      taskCreated: false,
      snackTime: 3500,
      contactsPending: false,
      mailConfig: {
        gmail: {
          running: false,
          contacts: [],
          addresses: [],
          loadingContacts: false,
          loadingAddress: false
        },
        office: {
          running: false,
          contacts: [],
          addresses: [],
          loadingContacts: false,
          loadingAddress: false
        },
        smtp: {
          running: false,
          contacts: [],
          addresses: [],
          loadingContacts: false,
          loadingAddress: false
        }
      },
      showCompose: false,
      taskSendMail: false,
      messageBox: {
        title: '',
        message: '',
        buttonText: '',
        titleColor: 'white'
      },
      showMessageBox: false,
      folderGroups: [],
      users: [],
      userGroups: [],
      workflows: [],
      showConnectError: false,
      lastConnect: null,
      selectLists: [],
      selectedTask: null,
      selectedTaskID: 0,
      showLinkOffice: false,
      officeLinkMail: null,
      showLinkSmtp: false,
      showLinkSmtpMailbox: false,
      gmailLinkMail: null,
      showCreateTaskOffice: false,
      curTime: null,
      curTimeMinute: null,
      showWorkflowEditor: false,
      showFieldSetManager: false,
      showNoteFieldEditor: false,
      noteFieldDescription: null,
      editorHtml: null,
      datasetChangeObject: null,
      showFieldSetSaveChanges: false,
      savingFieldSetChanges: false,
      showReports: false,
      ultraMode: false,
      showFolders: false,
      foldersSelect: {
        folderGroups: [],
        folders: []
      },
      selectedFolder: {name: "Annalize Botha", id: 219},
      mailSizeErrorData: null,
      showLinkToGmail: false,
      escalationViewTasks: {count: 0},
      // showVoip: false
      hiddenReports: false,
      showSigManager: false,
      SMTPLinkMail: null,
      SMTPLinkMailboxMail: null,
      showCreateTaskSmtp: false,
      showCreateTaskSmtpMailbox: false,
      showUserManger: false,
      preloadEditor: true,
      showSmtpMailboxManager: false,
      showRepoManager: false,
      showRepo: false,
      showTwoFactor: false,
      showUGM: false,
      showProductivityReports: false,
      showTaskHeaderReport: false,
      showContacts: false,
      rtcmRelay,
      console,
    }
  },
  watch: {
    
    "userInfo.escalationFilterPreset": {
      deep: true,
      immediate: true,
      handler(val) {
        if (val == null) {
          this.$set(this.userInfo, "escalationFilterPreset", {
            userOption: 1,  //0:Me 1:All Users
            stateOption: 4, //0:Open Tasks, 1:Snoozed Tasks, 2:Open & Snooze, 3:Closed Tasks, 4:All Tasks
            starOption: 0,  //0:All Tasks, 1:Starred Tasks
            groupOption: 2, //0:Folder, 5:Folder Groups, 1:User, 2:Workflow, 4:None,
            orderOption: 0  //0:Ascending, 1:Descending
          })
        }
        
        if (val != null && typeof this.userInfo.entityID != 'undefined' && this.userInfo.entityID != null) {
          this.fetch({
            method: 'POST',
            url: `/localUsers/preset/escalationFilterPreset/${this.userInfo.entityID}/${this.userInfo.userID}`,
            body: val
          })
              .catch((e) => {
                // eslint-disable-next-line
                console.log(e)
                this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
              })
        }
      }
    },
    
    "userInfo.splitTaskViewPreset": {
      deep: true,
      immediate: true,
      handler(val) {
        if (val == null) {
          this.$set(this.userInfo, "splitTaskViewPreset", {
            showNotes: true,
            showMails: true,
            showDocuments: true,
            showEvents: true,
            showChat: true,
            orderAscending: false,
            stepOnly: false,
            userOnly: false,
            filterStr: null,
          })
        }
        
        if (val != null && typeof this.userInfo.entityID != 'undefined' && this.userInfo.entityID != null) {
          let oVal = {};
          
          Object.assign(oVal, val);
          
          oVal.filterStr = null;
          
          this.fetch({
            method: 'POST',
            url: `/localUsers/preset/splitTaskViewPreset/${this.userInfo.entityID}/${this.userInfo.userID}`,
            body: oVal
          })
              .catch((e) => {
                // eslint-disable-next-line
                console.log(e)
                this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
              })
        }
      }
    },
    
    SMTPLinkMail: {
      immediate: true,
      deep: true,
      handler(val) {
        this.showLinkSmtp = arrPopulated(val)
      }
    },
    
    SMTPLinkMailboxMail: {
      immediate: true,
      deep: true,
      handler(val) {
        this.showLinkSmtpMailbox = arrPopulated(val)
      }
    },
    
    showReports(shown) {
      this.hiddenReports = false;
    },
    
    userInfo: {
      // call it upon creation too
      immediate: true,
      handler(userInfo) {
        // this.$rtdbBind('liveNotification', db.ref(`${userInfo.entityID}/notifications/${userInfo.userID}`))
        
        
      },
    },
    
    curView: {
      immediate: true,
      handler(val) {
        this.$store.state.curView = val
      }
    }
    
  },
  mounted() {
    this.setMailConfig(this.mailConfig)
    
    this.curView = 'app-dashboard-view';
    eventBus.$emit('showHideNav', true);
    this.showSync = true;
    
    
    this.fetch({method: 'GET', url: `/users/info`})
        .then(async (data) => {
          this.userInfo = data;
          this.setUserInfo(data);
          initialize(this.userInfo, window)
              .then(() => {
                eventBus.$emit('userInfoSet', data);
                // Load Folders And Start Local Database
                dbMethods.initialize(this.userInfo)
              })
              .catch(e => {
                console.log(e)
                this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                eventBus.$emit('showHideNav', false);
              });
          this.startRepo();
        })
        .catch((e) => {
          // eslint-disable-next-line
          console.log(e)
          this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
          eventBus.$emit('showHideNav', false);
        });
    
    repoEvents.on('getRepoDoc', (payload) => {
      
      if (this.$refs.repo) {
        if (payload && typeof payload.multiple !== 'undefined') {
          this.$refs.repo.multiple = payload.multiple
        } else {
          this.$refs.repo.multiple = true
        }
        
        if (payload && typeof payload.allowUserDocs !== 'undefined') {
          this.$refs.repo.allowUserDocs = payload.allowUserDocs
        } else {
          this.$refs.repo.allowUserDocs = true
        }
        
        this.$refs.repo.showSelect = true
        
        if (payload?.noSelect) {
          this.$refs.repo.showSelect = false
        }
      }
      
      this.showRepo = true;
    });
    
    this.oPlugInit();
  },
  
  beforeDestroy() {
    eventBus.$off('resetSyncStat', this.resetSyncStatEventBusRef);
    eventBus.$off('signatureManager', this.signatureManagerEventBusRef);
    eventBus.$off('officeAccountRemoved', this.officeAccountRemovedEventBusRef);
    eventBus.$off('OOOSet', this.OOOSetEventBusRef);
    eventBus.$off('folderField13Edit', this.folderField13EditEventBusRef);
    eventBus.$off('snackTaskMail', this.snackTaskMailEventBusRef);
    eventBus.$off('snackLinkTask', this.snackLinkTaskEventBusRef);
    eventBus.$off('snackCreateTask', this.snackCreateTaskEventBusRef);
    eventBus.$off('messageBox', this.messageBoxEventBusRef);
    eventBus.$off('navEvent', this.navEventEventBusRef);
    eventBus.$off('shareContacts', this.shareContactsEventBusRef);
    eventBus.$off('updateFilterSettings', this.updateFilterSettingsEventBusRef);
    eventBus.$off('gotoTask', this.gotoTaskEventBusRef);
    eventBus.$off('DatasetDismissedWithChanges', this.DatasetDismissedWithChangesEventBusRef);
    eventBus.$off('mailSizeError', this.mailSizeErrorEventBusRef)
    eventBus.$off('newSnoozedTaskObj', this.addSnoozedTask);
    eventBus.$off('removeSnoozedTaskObj', this.removeSnoozed);
    eventBus.$off('updateSmtpMailConfig', this.getUserDataSMTP);
    feed.off('taskChange', this.taskChange);
    feed.off('folderGroupChange', this.folderGroupChangeFeedEvent);
    feed.off('userGroupChange', this.userGroupChangeFeedEvent);
    feed.off('workspaceChange', this.workspaceChangeFeedEvent);
    feed.off('workspaceGroupChange', this.workspaceGroupChangeFeedEvent);
    feed.off('workflowChange', this.workflowChangeFeedEvent);
    feed.off('workflowGroupChange', this.workflowGroupChangeFeedEvent);
    feed.off('folderChange', this.folderChangeFeedEvent);
    dbEmitter.off('dbReady', this.dbReadyDBEmitterEvent);
    dbEmitter.off('dbError', this.dbErrorDBEmitterEvent);
    this.oPlugDestroy();
    this.$snack.emitter.off('showSnack', this.showSnackSnackEvent);
  },
  
  created() {
    
    this.setGetTaskHeaderFn(this.getTaskHeader)
    
    this.curTime = new Date();
    setInterval(() => {
      this.curTime = new Date();
    }, 60000);
    this.curTimeMinute = new Date();
    
    eventBus.$on('resetSyncStat', this.resetSyncStatEventBusRef);
    eventBus.$on('signatureManager', this.signatureManagerEventBusRef);
    eventBus.$on('officeAccountRemoved', this.officeAccountRemovedEventBusRef);
    eventBus.$on('OOOSet', this.OOOSetEventBusRef);
    eventBus.$on('folderField13Edit', this.folderField13EditEventBusRef);
    eventBus.$on('snackTaskMail', this.snackTaskMailEventBusRef);
    eventBus.$on('snackLinkTask', this.snackLinkTaskEventBusRef);
    eventBus.$on('snackCreateTask', this.snackCreateTaskEventBusRef);
    eventBus.$on('messageBox', this.messageBoxEventBusRef);
    eventBus.$on('navEvent', this.navEventEventBusRef);
    eventBus.$on('shareContacts', this.shareContactsEventBusRef);
    eventBus.$on('updateFilterSettings', this.updateFilterSettingsEventBusRef);
    eventBus.$on('gotoTask', this.gotoTaskEventBusRef);
    eventBus.$on('DatasetDismissedWithChanges', this.DatasetDismissedWithChangesEventBusRef);
    eventBus.$on('mailSizeError', this.mailSizeErrorEventBusRef)
    eventBus.$on('newSnoozedTaskObj', this.addSnoozedTask);
    eventBus.$on('removeSnoozedTaskObj', this.removeSnoozed);
    eventBus.$on('updateSmtpMailConfig', this.getUserDataSMTP);
    feed.on('taskChange', this.taskChange);
    feed.on('folderGroupChange', this.folderGroupChangeFeedEvent);
    feed.on('userGroupChange', this.userGroupChangeFeedEvent);
    feed.on('workspaceChange', this.workspaceChangeFeedEvent);
    feed.on('workspaceGroupChange', this.workspaceGroupChangeFeedEvent);
    feed.on('workflowChange', this.workflowChangeFeedEvent);
    feed.on('workflowGroupChange', this.workflowGroupChangeFeedEvent);
    feed.on('folderChange', this.folderChangeFeedEvent);
    dbEmitter.on('dbReady', this.dbReadyDBEmitterEvent);
    dbEmitter.on('dbError', this.dbErrorDBEmitterEvent);
    
    this.$snack.emitter.on('showSnack', this.showSnackSnackEvent);
  },
  methods: {
    
    async testFn() {
      const val = await showPrompt({
        title: 'Test Title',
        body: 'Some Body Here <br/> Second Line',
        buttonLabel: 'Feed Me',
        showCancel: true,
        defaultValue: 'Summer of 69',
        label: 'To Be Or not'
      })
      
      alert(val ? `The Input Was "${val}"` : 'PROMPT WAS CANCELLED')
    },
    
    contactAdded(contact) {
      this.$refs.contactView.selectContact(contact)
    },
    
    oPlugInit() {
      feed.on('oPlug:gotoTask', this.oPlugGotoTask)
      feed.on('oPlug:gotoFolder', this.oPlugGotoFolder)
    },
    
    oPlugDestroy() {
      feed.off('oPlug:gotoFolder', this.oPlugGotoFolder)
      feed.off('oPlug:gotoFolder', this.oPlugGotoFolder)
    },
    
    async oPlugGotoTask(options) {
      if (!this.$store.state.oPlugIgnore)
        try {
          const task = await this.getTaskHeader(options.taskId)
          this.showFolders = false
          eventBus.$emit('gotoTask', task)
        } catch (e) {
          console.log(e)
          this.$snack.networkError()
        }
    },
    
    async oPlugGotoFolder(options) {
      if (!this.$store.state.oPlugIgnore)
        if (this.$refs.folders) {
          this.showFolders = true
          this.$refs.folders.selectedFolderID = options.folderId
        }
    },
    
    
    showSnackSnackEvent(snack) {
      this.showSnack(snack.title, snack.message, snack.buttonText, snack.titleColor);
    },
    
    dbReadyDBEmitterEvent(ready) {
      if (ready) {
        this.foldersStartup();
      } else {
        eventBus.$emit('showHideNav', false)
      }
    },
    dbErrorDBEmitterEvent(err) {
      console.error('DATABASE ERROR FIRED:', err)
    },
    
    folderGroupChangeFeedEvent(data) {
      this.ioReplaceCheck(data, this.allFolderGroupIDs, this.localData.folderGroups)
    },
    userGroupChangeFeedEvent(data) {
      this.ioReplaceCheck(data, this.allUserGroupIDs, this.localData.userGroups)
    },
    workspaceChangeFeedEvent(data) {
      this.ioReplaceCheck(data, this.allWorkspaceIDs, this.localData.workspaces)
    },
    workspaceGroupChangeFeedEvent(data) {
      this.ioReplaceCheck(data, this.allWorkspaceGroupIDs, this.localData.workspaceGroups)
    },
    workflowChangeFeedEvent(data) {
      this.ioReplaceCheck(data, this.allWorkflowIDs, this.localData.workflows)
    },
    workflowGroupChangeFeedEvent(data) {
      this.ioReplaceCheck(data, this.allWorkflowGroupIDs, this.localData.workflowGroups)
    },
    folderChangeFeedEvent(data) {
      this.ioReplaceCheck(data, this.allFolderIDs, this.localData.folders)
      this.folderNameChanged(data);
    },
    
    OOOSetEventBusRef(value) {
      this.userInfo.outOfOffice = value
    },
    folderField13EditEventBusRef(data) {
      this.editorHtml = data.value != null && data.value != '' ? data.value : "...<br><br>";
      this.noteFieldDescription = data.fieldDescription;
      this.showNoteFieldEditor = true;
    },
    snackTaskMailEventBusRef(data) {
      this.taskSendMail = data
    },
    snackLinkTaskEventBusRef(data) {
      this.task = data;
      this.mailLink = true;
    },
    snackCreateTaskEventBusRef(data) {
      this.task = data;
      this.taskCreated = true;
    },
    messageBoxEventBusRef(data) {
      this.messageBox = data;
      this.showMessageBox = true;
    },
    navEventEventBusRef(navTo) {
      switch (navTo) {
        case 'smtp': {
          if (this.curView === 'smtp') {
            eventBus.$emit('refreshSmtp')
          }
          this.curView = 'smtp';
          break;
        }
        case 'mail': {
          this.curView = 'mail';
          break;
        }
        case 'mailboxes': {
          this.curView = 'mailboxes';
          break;
        }
        case 'chat': {
          this.curView = 'chat';
          break;
        }
        case 'gmail': {
          this.curView = 'app-gmail';
          break;
        }
        case 'office365': {
          this.curView = 'app-office';
          break;
        }
        case 'dashboard': {
          this.curView = 'app-dashboard-view';
          break;
        }
        case 'tasks': {
          this.curView = 'app-tasks';
          break;
        }
        case 'app-task-dash': {
          this.curView = 'app-task-dash';
          break;
        }
        case 'workflowEditor': {
          this.showWorkflowEditor = true
          break;
        }
        case 'datasetSetManager': {
          this.showFieldSetManager = true;
          break;
        }
        case 'folders' : {
          this.showFolders = true;
          break;
        }
        case 'reporting' : {
          this.showReports = true;
          break;
        }
        case 'calendar' : {
          this.curView = 'calendar';
          break;
        }
        case 'folderManager' : {
          this.showFolderManager = !this.showFolderManager;
          break;
        }
        case 'userManger' : {
          this.showUserManger = true;
          break;
        }
        case 'userGroupManger' : {
          this.showUGM = true;
          break;
        }
        case 'smtpMailboxManager' : {
          this.showSmtpMailboxManager = true;
          break;
        }
        case 'docRepoManager' : {
          this.showRepoManager = true;
          break;
        }
        case 'twoFactor' : {
          this.showTwoFactor = true;
          break;
        }
        case 'calGroupManager' : {
          this.showCalenderGroups = true;
          break;
        }
        case 'productivityReports' : {
          this.showProductivityReports = true;
          break;
        }
        case 'taskHeaderReport' : {
          this.showTaskHeaderReport = true;
          break;
        }
        case 'contacts' : {
          this.showContacts = true;
          break;
        }
        case 'manageContacts' : {
          this.showManageContacts = true;
          break;
        }
      }
    },
    shareContactsEventBusRef(fieldID) {
      eventBus.$emit(fieldID + 'sharedContacts', this.mailConfig.gmail.contacts)
    },
    updateFilterSettingsEventBusRef(filterSettings) {
      this.taskDataStore.filterSettings = filterSettings
    },
    gotoTaskEventBusRef(task) {
      this.selectedTask = task;
      this.selectedTaskID = task.taskID;
    },
    DatasetDismissedWithChangesEventBusRef(changeObject) {
      this.datasetChangeObject = changeObject;
      this.showFieldSetSaveChanges = true;
    },
    mailSizeErrorEventBusRef(data) {
      this.mailSizeErrorData = data;
    },
    
    resetSyncStatEventBusRef() {
      dbMethods.resetDB().then(() => {
        location.reload();
      }).catch(e => {
        console.log(e)
        this.showSnack('Error', 'Local DB Reset Failed', 'Close', 'red')
      })
    },
    signatureManagerEventBusRef() {
      this.showSigManager = true;
    },
    officeAccountRemovedEventBusRef() {
      this.mailConfig.office.running = false;
    },
    
    ...mapActions('userInfo', ['setUserInfo', 'setUserInfoProperty', 'setMailConfig']),
    ...mapActions('repo', ['repoInit']),
    ...mapActions(['setGetTaskHeaderFn']),
    
    repoTest() {
      getRepoDocs({
        multiple: true,
        allowUserDocs: true
      })
          .then((data) => {
            console.log('Sucess Docs', data)
          })
          .catch(e => {
            if (e) {
              console.log(e)
            } else {
              console.log('User Canceled')
            }
          })
    },
    
    startRepo() {
      this.$newReq('get', `docRepo/UserStartup/${this.userInfo.entityID}/${this.userInfo.userID}`)
          .then((data) => {
            this.repoInit(data)
          })
          .catch(e => {
            console.log(e)
          })
      
    },
    preloadEditorConfig() {
      let vm = this;
      return {
        init_instance_callback: function () {
          vm.preloadEditor = false;
        },
      }
    },
    async foldersStartup() {
      try {
        // Get The Last Update Value
        let lastGet = await dbMethods.getConfigValue(1);
        
        this.fetch({
          method: 'POST',
          url: `/folders/initFolders/${this.userInfo.entityID}`,
          body: JSON.stringify({lastGet: typeof lastGet != 'undefined' ? lastGet.val : null})
        })
            .then((data) => {
              dbMethods.addConfigValue(1, data.getDate)
                  .then(() => {
                    dbMethods.addFolders(data.folders)
                        .then(() => {
                          dbMethods.getFolders()
                              .then(allFolders => {
                                this.startupRun(allFolders);
                              })
                              .catch(e => {
                                // eslint-disable-next-line
                                console.log(e)
                                this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                              })
                        })
                        .catch(e => {
                          // eslint-disable-next-line
                          console.log(e)
                          this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                        })
                  })
                  .catch(e => {
                    // eslint-disable-next-line
                    console.log(e)
                    this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                  })
            })
            .catch((e) => {
              // eslint-disable-next-line
              console.log(e)
              this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
            })
      } catch (e) {
        // eslint-disable-next-line
        console.log(e)
      }
    },
    
    ioReplaceCheck(doc, idList, localVar) {
      if (idList.includes(doc.id)) {
        localVar.forEach((item, index) => {
          if (item.id == doc.id) {
            localVar.splice(index, 1, doc);
          }
        });
      } else {
        localVar.push(doc);
      }
    },
    
    startupRun(allFolders) {
      this.fetch({
        method: 'GET',
        url: '/localUsers/appStartupData/' + this.userInfo.entityID + '/' + this.userInfo.userID
      })
          .then((data) => {
            this.folderGroups = data.folderGroups;
            if (data.filterSettings.length > 0) {
              let settings = data.filterSettings[0];
              this.taskDataStore.filterSettings.userOption = settings.userOption != null ? settings.userOption : 1;
              this.taskDataStore.filterSettings.stateOption = settings.stateOption != null ? settings.stateOption : 2;
              this.taskDataStore.filterSettings.orderOption = settings.orderOption != null ? settings.orderOption : 1;
              this.taskDataStore.filterSettings.groupOption = settings.groupOption != null ? settings.groupOption : 2;
              this.taskDataStore.filterSettings.taskOption = settings.taskOption != null ? settings.taskOption : 0;
              this.taskDataStore.filterSettings.escalationOption = settings.escalationOption != null ? settings.escalationOption : 0;
            } else {
              this.taskDataStore.filterSettings.userOption = 1;
              this.taskDataStore.filterSettings.stateOption = 2;
              this.taskDataStore.filterSettings.orderOption = 1;
              this.taskDataStore.filterSettings.groupOption = 2;
              this.taskDataStore.filterSettings.taskOption = 0;
              this.taskDataStore.filterSettings.escalationOption = 0;
            }
            this.users = data.users;
            this.userGroups = data.userGroups;
            this.workflows = data.workflows;
            this.selectLists = data.selectLists;
            
            
            // Add Snoozed Tasks
            data.snoozedTasks.forEach(snObj => {
              this.addSnoozedTask(snObj)
            });
            
            
            // Set Local Data Vars
            this.localData.users = data.users;
            this.localData.userGroups = data.userGroups;
            this.localData.folderGroups = data.folderGroups;
            this.$nextTick(() => {
              this.localData.folders = allFolders;
              this.localData.workflows = data.workflows;
              this.localData.workspaceGroups = data.workspaceGroups;
              this.localData.workspaces = data.workspaces;
              this.localData.workflowGroups = data.workflowGroups;
              this.dbLoadDone = true;
              this.showSync = false;
            })
            
            // Continue With Startup
            // this.startPouch();
            
            if (this.userInfo.gmailEnabled === 1 || this.userInfo.office365Enabled || this.userInfo.smtpAddressEnabled === 1) {
              this.initMail();
            }
            
            this.showCompose = true;
            
            // Get Task Headers
            this._initializeHeaders(0, 0)
            initCalendar()
          })
          .catch((e) => {
            // eslint-disable-next-line
            console.log(e)
            this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
          })
    },
    
    showHideReports() {
      this.showReports = false
    },
    
    updateShowLinkGmail() {
      this.showLinkToGmail = !this.showLinkToGmail
    },
    
    callReload() {
      
      location.reload();
      
    },
    appendFolderData(data) {
      this.foldersSelect = data
    },
    saveFieldSetChanges() {
      this.savingFieldSetChanges = true;
      this.fetch({
        method: 'Post',
        url: '/fieldsets/saveChanges/' + this.userInfo.entityID + '/' + this.userInfo.userID + '/' + this.datasetChangeObject.dataSetMemberID + '/' + this.datasetChangeObject.folderID,
        body: JSON.stringify(this.datasetChangeObject.saveFields)
      })
          .then((data) => {
            this.savingFieldSetChanges = false;
            this.showFieldSetSaveChanges = false;
            this.datasetChangeObject = false;
            this.showSnack('Info', 'Changes Saved', 'Close', 'primary')
          })
          .catch((e) => {
            // eslint-disable-next-line
            console.log(e);
            this.savingFieldSetChanges = false;
            this.showSnack('Error', 'Network Error Please Try Again', 'Close', 'red')
          })
    },
    editNoteDismissed(data) {
      this.showNoteFieldEditor = false;
      if (data && !this.blankString(this.editorHtml) && this.editorHtml != "...<br><br>") {
        eventBus.$emit('folderField13EditDone', this.editorHtml)
      } else {
        eventBus.$emit('folderField13EditDone', null)
      }
    },
    updateTask(data) {
      
      if (typeof data.taskID != "undefined") {
        this.taskData.tasks.forEach(task => {
          if (task.taskID == data.taskID) {
            if (typeof data.preSnoozeState != 'undefined') {
              task.preSnoozeState = data.preSnoozeState
            }
            if (typeof data.state != 'undefined') {
              task.state = data.state
            }
            if (typeof data.curStepID != 'undefined') {
              task.curStepID = data.curStepID
            }
            if (typeof data.curStepName != 'undefined') {
              task.curStepName = data.curStepName
            }
          }
        })
      }
      
    },
    pushTask(task) {
      this.taskDataStore.tasks = this.taskDataStore.tasks.concat([task])
    },
    initMail() {
      if (this.intToBool(this.userInfo.gmailEnabled)) {
        this.getContactsGmail();
        this.getSendAddressesGmail();
      }
      if (this.intToBool(this.userInfo.office365Enabled)) {
        this.getContactsOffice();
        this.getSendAddressesOffice();
      }
      
      // if (this.intToBool(this.userInfo.smtpAddressEnabled)) {
      this.getUserDataSMTP();
      // }
    },
    
    
    getUserDataSMTP() {
      this.mailConfig.smtp.loadingAddress = true;
      this.fetch({method: 'GET', url: `/smtp/userData/${this.userInfo.entityID}/${this.userInfo.userID}`})
          .then((data) => {
            if (arrPopulated(data.addresses)) {
              this.mailConfig.smtp.addresses = data.addresses;
              this.mailConfig.smtp.running = true;
            } else {
              this.mailConfig.smtp.running = false;
            }
            this.mailConfig.smtp.contacts = data.contacts;
            this.mailConfig.smtp.loadingAddress = false;
            
          })
          .catch((e) => {
            // eslint-disable-next-line
            console.log(e);
            // this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
            this.mailConfig.smtp.loadingAddress = false;
          });
    },
    getSendAddressesOffice() {
      this.mailConfig.office.loadingAddress = true;
      this.fetch({method: 'GET', url: `/microsoft/emailAddresses/${this.userInfo.userID}/${this.userInfo.entityID}`})
          .then((data) => {
            this.mailConfig.office.addresses = data;
            this.mailConfig.office.loadingAddress = false;
            this.mailConfig.office.running = true
          })
          .catch((e) => {
            // eslint-disable-next-line
            // console.log(e);
            // this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
            this.mailConfig.office.loadingAddress = false;
          });
    },
    getContactsOffice() {
      this.mailConfig.office.loadingContacts = true;
      
      this.fetch({method: 'GET', url: `/microsoft/contacts/${this.userInfo.userID}`})
          .then((data) => {
            this.mailConfig.office.contacts = data;
            this.mailConfig.office.loadingContacts = false
          })
          .catch((e) => {
            // eslint-disable-next-line
            // console.log(e)
            // this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
            this.mailConfig.office.loadingAddress = false;
          });
    },
    
    initCreateTaskOffice(mail) {
      
      this.quickTaskConfig = mail.qt || null;
      
      this.officeLinkMail = mail;
      this.showCreateTaskOffice = true;
    },
    
    initCreateTaskSmtp(mail) {
      
      this.quickTaskConfig = mail.qt ? mail.qt : null;
      
      this.showCreateTaskSmtp = true;
    },
    initCreateTaskSmtpMailbox(mail) {
      this.quickTaskConfig = mail.qt ? mail.qt : null;
      
      this.showCreateTaskSmtpMailbox = true;
    },
    linkMailToTask() {
      this.linkingMail = true
      switch (this.curView) {
        case 'mailboxes': {
          this.showLinkSmtpMailbox = false;
          let jBody = {
            taskID: this.selectedTaskID,
            mail: this.SMTPLinkMailboxMail
          };
          
          this.fetch({
            method: 'POST',
            url: `/SMTP/linkMailToTaskMailbox/${this.userInfo.entityID}/${this.userInfo.userID}`,
            body: JSON.stringify(jBody)
          })
              .then((data) => {
                
                this.getTaskHeader(data.taskID)
                    .then((task) => {
                      this.showSnack('Info', 'Message/s linked To Task', 'Close', 'primary');
                      this.showLinkSmtp = false;
                    })
                    .catch(e => {
                      console.log(e)
                      this.showSnack('Error', 'Messages Linked Successfully, But could not fetch', 'Close', 'red')
                    })
              })
              .catch((e) => {
                // eslint-disable-next-line
                console.log(e)
                this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
              })
              .finally(() => {
                this.linkingMail = false
              })
          
          
          break;
        }
        case 'smtp': {
          this.showLinkSmtp = false;
          let jBody = {
            taskID: this.selectedTaskID,
            mail: this.SMTPLinkMail
          };
          
          this.fetch({
            method: 'POST',
            url: `/SMTP/linkMailToTask/${this.userInfo.entityID}/${this.userInfo.userID}`,
            body: JSON.stringify(jBody)
          })
              .then((data) => {
                
                this.getTaskHeader(data.taskID)
                    .then((task) => {
                      this.showSnack('Info', 'Message/s linked To Task', 'Close', 'primary');
                      this.showLinkSmtp = false;
                    })
                    .catch(e => {
                      console.log(e)
                      this.showSnack('Error', 'Messages Linked Successfully, But could not fetch', 'Close', 'red')
                    })
              })
              .catch((e) => {
                // eslint-disable-next-line
                console.log(e)
                this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
              })
              .finally(() => {
                this.linkingMail = false
              })
          
          
          break;
        }
        case 'mail': {
          let reqUrl = `/microsoft/linkMailToTask/${this.userInfo.entityID}/${this.userInfo.userID}`;
          this.fetch({
            method: 'POST', url: reqUrl, body: JSON.stringify({
              mail: this.officeLinkMail,
              taskID: this.selectedTaskID
            })
          })
              .then((data) => {
                
                if (this.showLinkOffice) {
                  if (typeof this.officeLinkMail.tasks == 'undefined') {
                    this.officeLinkMail.tasks = [];
                  }
                  this.officeLinkMail.tasks.push(this.selectedTaskID);
                  this.showLinkOffice = false;
                  
                  // this.task = task;
                  this.mailLink = true;
                }
              })
              .catch((e) => {
                // eslint-disable-next-line
                console.log(e)
                this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
              })
              .finally(() => {
                this.linkingMail = false
              })
          break;
        }
        default: {
          let h = new Headers();
          h.append('Content-Type', 'application/json');
          
          let url = !this.gmailLinkMail.isMailbox
              ? '/gmail/msg/linkMailToTask/' + this.userInfo.entityID + '/' + this.userInfo.userID + '/' + this.selectedTaskID
              : '/gmail/msg/linkMailboxMailToTask/' + this.userInfo.entityID + '/' + this.userInfo.userID + '/' + this.selectedTaskID;
          
          
          this.fetch({method: 'PATCH', url: url, body: JSON.stringify(this.gmailLinkMail)})
              .then((data) => {
                // eventBus.$emit('snackLinkTask', task);
                if (typeof this.gmailLinkMail.tasks == 'undefined') {
                  this.$set(this.gmailLinkMail, 'tasks', [])
                }
                this.showLinkToGmail = false;
                this.gmailLinkMail.tasks.push(this.selectedTaskID);
              })
              .catch((e) => {
                // eslint-disable-next-line
                console.log(e)
                this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
              })
              .finally(() => {
                this.linkingMail = false
              })
        }
      }
    },
    
    selectTask(task) {
      this.selectedTaskID = task.taskID;
    },
    newNotifcations(notifications) {
      
      this.taskDataStore.tasks.forEach((task, i) => {
        let tNotificationList = notifications.filter(obj => obj.taskID == task.taskID)
        if (tNotificationList.length > 0) {
          tNotificationList = tNotificationList[0];
          let tStructure = task.structure;
          tNotificationList.structure = tStructure;
          this.taskDataStore.tasks.splice(i, 1, tNotificationList)
          // task = tNotificationList;
        }
      })
    },
    gotoTask() {
      eventBus.$emit('gotoTask', (this.task));
      this.mailLink = false;
      this.taskCreated = false;
      
    },
    getContactsGmail() {
      if (this.userInfo && this.userInfo.gmailEnabled === 1) {
        this.mailConfig.gmail.loadingContacts = true;
        
        this.fetch({method: 'GET', url: `/gmail/contacts/${this.userInfo.userID}`})
            .then((data) => {
              this.mailConfig.gmail.contacts = data;
              this.mailConfig.gmail.loadingContacts = false
            })
            .catch((e) => {
              // eslint-disable-next-line
              console.log(e)
              // this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
              this.mailConfig.gmail.loadingAddress = false;
            });
      }
      
      
    },
    getSendAddressesGmail() {
      this.mailConfig.gmail.loadingAddress = true;
      this.fetch({method: 'GET', url: `/gmail/contacts/emailAddresses/${this.userInfo.userID}`})
          .then((data) => {
            this.mailConfig.gmail.addresses = data;
            this.mailConfig.gmail.loadingAddress = false;
            this.mailConfig.gmail.running = true
          })
          .catch((e) => {
            // eslint-disable-next-line
            console.log(e);
            // this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
            this.mailConfig.gmail.loadingAddress = false;
          });
    },
    getLastFilter() {
      if (typeof this.userInfo.userID != 'undefined') {
        this.fetch({
          method: 'GET',
          url: '/localUsers/appStartupData/' + this.userInfo.entityID + '/' + this.userInfo.userID
        })
            .then((data) => {
              this.folderGroups = data.folderGroups;
              if (data.filterSettings.length > 0) {
                let settings = data.filterSettings[0];
                this.taskDataStore.filterSettings.userOption = settings.userOption != null ? settings.userOption : 0;
                this.taskDataStore.filterSettings.stateOption = settings.stateOption != null ? settings.stateOption : 0;
                this.taskDataStore.filterSettings.orderOption = settings.orderOption != null ? settings.orderOption : 0;
                this.taskDataStore.filterSettings.groupOption = settings.groupOption != null ? settings.groupOption : 0;
                this.taskDataStore.filterSettings.taskOption = settings.taskOption != null ? settings.taskOption : 0;
                this.taskDataStore.filterSettings.escalationOption = settings.escalationOption != null ? settings.escalationOption : 0;
              } else {
                this.taskDataStore.filterSettings.userOption = 0;
                this.taskDataStore.filterSettings.stateOption = 0;
                this.taskDataStore.filterSettings.orderOption = 0;
                this.taskDataStore.filterSettings.groupOption = 0;
                this.taskDataStore.filterSettings.taskOption = 0;
                this.taskDataStore.filterSettings.escalationOption = 0;
              }
              this.users = data.users;
              this.userGroups = data.userGroups;
              this.workflows = data.workflows;
              this.selectLists = data.selectLists;
              // this.getTaskHeaders();
              this._initializeHeaders(0, 0)
            })
            .catch((e) => {
              // eslint-disable-next-line
              console.log(e)
              this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
            })
      } else {
        setTimeout(() => {
          this.getLastFilter();
        }, 1000)
      }
    },
    getTaskHeaders() {
      if (this.ultraMode == false) {
        this.taskDataStore.tasks = [];
        const jBody = {
          stateOption: this.taskDataStore.filterSettings.stateOption,
        };
        this.taskDataStore.taskLoading = true;
        this.fetch({
          method: 'post',
          url: '/tasks/taskHeaders/' + this.userInfo.entityID + '/' + this.userInfo.userID,
          body: JSON.stringify(jBody)
        })
            .then((data) => {
              let tTasks = data;
              tTasks.forEach(task => {
                task.structure = {};
                task.structure.steps = [];
                task.structure.mails = [];
                task.structure.fields = [];
                task.structure.draftMails = [];
                task.structure.draftNotes = [];
              });
              this.taskDataStore.tasks = tTasks;
              this.taskDataStore.taskLoading = false;
            })
            .catch((e) => {
              // eslint-disable-next-line
              console.log(e);
              this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
            })
      }
      
    },
  },
  computed: {
    ...mapGetters("userInfo", ["softBlock"]),
    showMailSizeError() {
      return this.mailSizeErrorData !== null
    },
    appFolderGroups() {
      return this.localData.folderGroups
          .map(folderGroup => {
            let tFolderGoup = {
              id: folderGroup.dbid,
              description: folderGroup.description,
              userGroups: folderGroup.userGroups,
              _id: folderGroup._id,
              _rev: folderGroup._rev,
            };
            return tFolderGoup
          })
    },
    appFolders() {
      return this.localData.folders
          .map(folder => {
            let tFolder = {
              id: folder.dbid,
              description: folder.description,
              folderGroups: this.appFolderGroups.filter(fg => folder.folderGroups.includes(fg.id)),
              _rev: folder._rev,
              _id: folder._id,
            };
            return tFolder
          })
    },
    appData() {
      return {
        userInfo: this.userInfo,
        _notifications: this.localData.notifications,
        _folders: this.localData.folders,
        _folderGroups: this.localData.folderGroups,
        _users: this.localData.users,
        // _tasks: this.localData.tasks,
        folders: this.appFolders,
        folderGroups: this.appFolderGroups
      }
    },
    allWorkspaceGroupIDs() {
      return this.localData.workspaceGroups.map(obj => {
        return obj.id
      })
    },
    allWorkflowIDs() {
      return this.localData.workflows.map(obj => {
        return obj.id
      })
    },
    allWorkflowGroupIDs() {
      return this.localData.workflowGroups.map(obj => {
        return obj.id
      })
    },
    allWorkspaceIDs() {
      return this.localData.workspaces.map(obj => {
        return obj.id
      })
    },
    allUserIDs() {
      return this.localData.users.map(obj => {
        return obj.id
      })
    },
    allUserGroupIDs() {
      return this.localData.userGroups.map(obj => {
        return obj.id
      })
    },
    allFolderIDs() {
      return this.localData.folders.map(obj => {
        return obj.id
      })
    },
    allFolderGroupIDs() {
      return this.localData.folderGroups.map(obj => {
        return obj.id
      })
    },
    taskEscalations() {
      let curTime = this.curTimeMinute;
      
      let tValues = this.taskData.tasks.reduce((arrValues, task) => {
        if (task.workflowStatusValue == null) {
          arrValues[task.taskID] = 0;
        } else {
          if (task.workflowStatusTTE == null) {
            arrValues[task.taskID] = 0;
          } else {
            let tte = new Date(task.workflowStatusTTE);
            if (tte < curTime) {
              arrValues[task.taskID] = 1;
            } else {
              arrValues[task.taskID] = task.workflowStatusExtension == 1 ? 2 : 0;
            }
          }
        }
        return arrValues
      }, {});
      
      return tValues;
    },
    showBand() {
      switch (this.curView) {
        case 'app-gmail' : {
          return this.showLinkToGmail
        }
        case 'mail' : {
          return this.showLinkOffice
        }
        case 'smtp' : {
          return true
        }
        case 'app-task-dash' : {
          return false
        }
        case 'calendar' : {
          return false
        }
        default: {
          return true
        }
      }
    },
    taskData() {
      // return this.taskDataStore;
      return {
        taskLoading: this.taskDataStore.taskLoading,
        filterSettings: {
          userOption: this.taskDataStore.filterSettings.userOption,
          stateOption: this.taskDataStore.filterSettings.stateOption,
          orderOption: this.taskDataStore.filterSettings.orderOption,
          groupOption: this.taskDataStore.filterSettings.groupOption,
          starOption: this.taskDataStore.filterSettings.starOption,
          taskOption: this.taskDataStore.filterSettings.taskOption,
        },
        tasks: this.taskDataStore.tasks.map(task => {
          task.folderGroupNames = task.folderGroups == null ? null :
              this.folderGroups.filter(obj => JSON.parse(task.folderGroups).includes(obj.id)).map(mObj => {
                return mObj.description
              }).join(', ');
          return task
        }),
        users: this.users,
        userGroups: this.userGroups,
        workflows: this.workflows,
        folderGroups: this.folderGroups
      }
    }
  }
}
</script>

<style scoped>


</style>
