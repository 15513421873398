<template>
    <v-layout fill-height id="maxHeight" justify-center row class="pl-2 pt-0 pr-0" style="background: var(--page-back)" >
<!--        <office-smtp-setup></office-smtp-setup>-->
<!--        <v-flex xs3 style="overflow-y: auto">-->
<!--          -->
<!--          <app-browse-contacts v-if="userInfo && userInfo.userID">-->
<!--            <template #activator>-->
<!--              <v-btn>-->
<!--                Browse Contacts-->
<!--              </v-btn>-->
<!--            </template>-->
<!--          </app-browse-contacts>-->
<!--&lt;!&ndash;&ndash;&gt;-->
<!--          <app-create-link-contact v-if="userInfo && userInfo.userID" :local-data="localData">-->
<!--            <template #activator>-->
<!--              <v-btn>-->
<!--                Create / Link Contact-->
<!--              </v-btn>-->
<!--            </template>-->
<!--          </app-create-link-contact>-->
<!--          -->
<!--        </v-flex>-->
        <v-flex xs8 class="scroll" style="overflow-y: auto">
            <app-notifications
                :userInfo="userInfo"
                @newNotifcations="$emit('newNotifcations', $event)"
                :cur-view="curView"
                :init-tasks="initTasks"
                :get-task-header="getTaskHeader"
                :local-data="localData"
            >
            </app-notifications>
        </v-flex>
        <v-flex xs4 fill-height style="overflow-y: auto">
            <app-escalations
                :user-info="userInfo"
                :escalations="escalationViewTasks"
                :get-task-header="getTaskHeader"
                :filter-settings="userInfo.escalationFilterPreset"
                @settingsUpdated="Object.assign(userInfo.escalationFilterPreset, $event)"
            />
        </v-flex>

    </v-layout>
</template>

<script>
import {codeBaseMixin} from "../codeBaseMixin";
import Notifications from "./Notifications/Notifications";
import Escalations from "./Escalations/Escalations";
import OfficeSmtpSetup from "@/Mail/Office Smtp/OfficeSmtpSetup.vue";
import AppBrowseContacts from "@/contacts/AppBrowseContacts.vue";
import AppCreateLinkContact from "@/contacts/AppCreateLinkContact.vue";

export default {
    mixins: [codeBaseMixin],
    components: {
      AppCreateLinkContact,
      AppBrowseContacts,
        OfficeSmtpSetup,
        appEscalations: Escalations,
        appNotifications: Notifications,
    },
    data() {
        return {
            showNewTask: false,
            showUpload: false,
            iTest: 0,
        }
    },
    props: {
        localData: Object,
        filterOptions: Object,
        curView: String,
        selectedTaskID: Number,
        userInfo: Object,
        userGroups: Array,
        taskData: {
            type: Object,
            default: () => {
                return {
                    tasks: [],
                    taskLoading: true,
                    filterSettings: {
                        userOption: 0,
                        stateOption: 0,
                        orderOption: 0,
                        groupOption: 0,
                        starOption: 0
                    }
                }
            }
        },
        folderGroups: Array,
        escalationValues: Object,
        curTimeMinute: {
            type: Date,
            default: null
        },
        initTasks: Boolean,
        getTaskHeader: Function,
        escalationViewTasks: Object,
        mailConfig: Object
    },
    methods: {
        test123() {
            // clear content
            this.$refs.mailFrame.src = "about:blank";
            // write new content
            this.$refs.mailFrame.contentWindow.document.open();
            this.$refs.mailFrame.contentWindow.document.write(`
                <html><head><style>body {font-size: 3em}</style></head><body>Hello WOrld</body></html>
                `);
            this.$refs.mailFrame.contentWindow.document.close();
        }
    }
}
</script>

<style scoped>
#frame {
    width: 125%;
    transform: scale(0.8);
    transform-origin: 0 0;
}


</style>
