<template>
    <div style="width: 350px; position: absolute; bottom: 0; right: 0" class="pr-2">
        <doc-repo-download
                ref="download"
                v-for="download in onGoingDownloads"
                :key="download.id"
                :download="download"
        />

        <p-d-f ref="pdfViewer"></p-d-f>

        <v-dialog
                v-model="emlView"
                fullscreen
        >
            <v-card v-if="emlView" class="scrollable" style="height: 100vh">

                <v-layout column fill-height class="scrollable">
                    <v-flex shrink class="primary">
                        <v-layout row align-center>
                            <v-flex xs12>
                                <v-layout row align-center>
                                    <v-flex xs12 class="font-weight-regular white--text pa-2 title">
                                        Mail View
                                    </v-flex>
                                </v-layout>

                                <v-spacer></v-spacer>


                            </v-flex>
                            <v-flex shrink class="pa-2">
                                <v-btn
                                        icon
                                        flat
                                        color="white"
                                        class="pa-0 ma-0"
                                        @click="emlView = !emlView"
                                >
                                    <v-icon>close</v-icon>
                                </v-btn>
                            </v-flex>
                        </v-layout>
                    </v-flex>
                    <v-flex xs12 class="scrollable pa-2" v-if="emlView">
                        <e-m-l-viewer
                                ref="child"
                        />
                    </v-flex>
                </v-layout>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {newDownload, repoEvents} from "@/Document Repository/docRepoMan";
import {parseMail} from "@/codeFunctions";
import axios from "axios";
import DocRepoDownload from "@/Document Repository/docRepoDownload";
import PDF from "@/components/General/PDF/PDF";
import EMLViewer from "@/Mail/EML Viewer/EMLViewer";

export default {
    components: {EMLViewer, PDF, DocRepoDownload},
    props: {
        val: Number
    },
    data() {
        return {
            onGoingDownloads: [],
            emlView: null
        }
    },
    created() {
        repoEvents.on('newDownload', (doc) => {this.newDownload(doc)});
    },
    beforeDestroy() {
        repoEvents.off('newDownload', (doc) => {this.newDownload(doc)});
    },
    watch: {},
    methods: {
        newDownload(doc, convert = true) {
            function getBlobText(blob) {
                return new Promise((resolve, reject) => {
                    let reader = new FileReader();

                    reader.readAsText(blob, 'ISO-8859-1');

                    reader.onload = function() {
                        resolve(reader.result);
                    };

                    reader.onerror = function() {
                        reject(reader.error);
                    };
                })
            }

            // Cancel Existing Download If There Is One
            this.onGoingDownloads.forEach((download, i) => {
                if (download.id === doc.documentID) {
                    try {
                        download.cancelToken.cancel()
                    } catch (e) {
                        console.log(e)
                    }
                    this.onGoingDownloads.splice(i,1)
                }
            })

            let filename = doc.alias || doc.fileName
            if (filename.split('.').pop().toLowerCase() !== doc.extention)
                filename = filename + '.' + doc.extention

            filename = (filename || '').replace(/[/\\?%*:|"<>,#]/g, '-');


            let download = {
                id: doc.documentID,
                progress: 0,
                downloaded: false,
                doc: doc,
                ref: null,
                cancelToken: axios.CancelToken.source(),
                filename: filename
            }

            if (doc.extention === 'pdf') {
              download.downloadType = 'url'
            } else if (doc.extention === 'eml') {
              download.downloadType = 'data'
            } else if (doc.extention === 'docx' && convert) {

              download.downloadType = 'url'
              const vm = this;
              download.downloadSrc = () => {
                vm.newDownload(doc, false)
              }
              filename = `docxpdfpreview/${filename.replace(/\.docx$/, '.pdf')}`
            } else {
              download.downloadType = 'auto'
            }


            this.onGoingDownloads.push(download)

            this.$nextTick(() => {
                download.ref = this.$refs.download.filter(obj => obj.download.id === download.id).pop() || null

                // Start The Download
                function updateProgress(obj) {
                    download.progress = Math.floor((obj.loaded / obj.total) * 100);
                }

                this.$newReqDownloadFile(
                        'get',
                        `upload/download/${this.userInfo.entityID}/${doc.documentID}/${filename}`,
                        null,
                        filename,
                        download.downloadType,
                        true,
                        false,
                        updateProgress,
                        download.cancelToken
                )
                        .then(async data => {

                            if (download.downloadSrc) {
                              let viewer = this.$refs.pdfViewer;
                              viewer.localInit(data, download.filename || 'attachemnt.pdf', download.downloadSrc);
                              console.log("HERE")
                            } else if (download.downloadType === 'url') {
                                let viewer = this.$refs.pdfViewer;
                                viewer.localInit(data, download.filename || 'attachemnt.pdf');
                            } else if (download.downloadType === 'data') {
                                try {
                                    let parsed = await parseMail(await getBlobText(data))
                                    this.emlView = true;
                                    this.$nextTick(() => {
                                        this.$refs.child.inception(parsed)
                                    })
                                } catch (e) {
                                    // eslint-disable-next-line
                                    console.log(e)
                                    this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                                    this.emlView = false;
                                }
                            }
                        })
                        .catch(e => {
                            if (typeof e.message !== 'undefined') {
                                this.$snack.networkError()
                            }
                        })
                        .finally(() => {
                            // Remove Download Now That It Is Done
                            this.onGoingDownloads.forEach((download, i) => {
                                if (download.id === doc.documentID) {
                                    this.onGoingDownloads.splice(i,1)
                                }
                            })
                        })
            })



            // download.cancelToken.cancel()



        }
    },
    computed: {
        ...mapGetters('userInfo', ['userInfo'])
    },
}
</script>

<style scoped>

</style>