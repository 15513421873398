<template>

    <div>
<!--Required Fields-->
        <div v-if="!canSave && !forceClose">
            <v-layout row align-center>
                <v-flex shrink>
                    <v-icon color="amber darken-2" left>warning</v-icon>
                </v-flex>
                <v-flex xs12 class="font-weight-bold amber--text text--darken-2 py-1">Please complete required fields</v-flex>
            </v-layout>

<!--Required Steps-->
            <div v-for="(field, i) in canSaveMissingFields">
                <div class="greyType--text pl-4 pb-1 hover-item" v-if="showAllRequiredFields || i<=2" style="cursor: pointer" @click="$emit('selectedStep', {stepID: field.stepID, description: field.stepDescription})">
                    {{field.stepDescription}} - {{field.fieldDescription}}
                </div>
            </div>

            <div v-if="canSaveMissingFields.length > 3 && !showAllRequiredFields">
                <div class="greyType--text pl-4 pb-1 hover-item font-weight-bold" @click="showAllRequiredFields = !showAllRequiredFields" style="cursor:pointer;">
                    + {{canSaveMissingFields.length - 3}} Others
                </div>
            </div>
        </div>
        <div>
            <v-switch
                class="pa-0 ma-0 py-1"
                hide-details
                value
                v-model="switchValue"
                label="Close Task"
                color="secondary"
                :readonly="!intToBool(value.editable)  || !stepEditable"
                :disabled="!forceClose ? !canSave  : false"
            >
            </v-switch>
        </div>
    </div>
</template>

<script>

    import {codeBaseMixin} from "../../../../../../codeBaseMixin";

    export default {
        mixins:[codeBaseMixin],
        data() {
            return {
                showAllRequiredFields: false
            }
        },
        props: {
            value: Object,
            fields: Array,
            taskOpenState: String,
            stepEditable: Boolean,
            hiddenSteps: Array,
            steps: Array
        },
        computed: {
            forceClose() {
                return this.value.lookup != null && this.value.lookup != '' && this.value.lookup == 1
            },
            switchValue: {
                // getter
                get: function () {
                    //return this.value.value
                    return this.value.value != null && this.value.value != '' ? this.intToBool(parseInt(this.value.value)) : this.intToBool(parseInt(this.taskOpenState))
                },
                // setter
                set: function (newValue) {
                    this.value.value = this.boolToInt(newValue)
                }
            },
            canSaveMissingFields() {
                return this.fields
                    .filter(field => !this.hiddenSteps.includes(field.stepID) && field.required == 1 && field.ruleShow == true && this.blankString(field.value))
                    .reduce((steps, field) => {
                        if (steps.filter(step => step.stepID === field.stepID).length <= 0) {
                            let existingStep = this.steps.filter(step => step.stepID === field.stepID).pop()
                            steps.push({
                                stepID: existingStep.stepID,
                                order: existingStep.order,
                                stepDescription: existingStep.description,
                                fields: [{
                                    fieldID: field.fieldID,
                                    order: field.field_order,
                                    fieldDescription: field.description
                                }]
                            })
                        } else {
                            steps.forEach(step => {
                                if (step.stepID === field.stepID)
                                    step.fields.push({
                                        fieldID: field.fieldID,
                                        order: field.field_order,
                                        fieldDescription: field.description
                                    })
                            })
                        }
                        return steps
                    }, [])
                    .map((step) => {
                        step.fields.sort((a,b) => a.order - b.order)
                        return step
                    })
                    .sort((a,b) => a.order - b.order)
                .reduce((fieldList, step) => {
                    return fieldList.concat(
                        step.fields.map(field => {
                            return {...step, ...field}
                        })
                )
                }, [])
            },
            canSave() {
                let bSave = this.fields.filter(field => !this.hiddenSteps.includes(field.stepID) && field.required == 1 && field.ruleShow == true && this.blankString(field.value)).length <= 0;
                return bSave
            }
        }

    }
</script>

<style scoped>

</style>
