<template>
    <div style="overflow-x: hidden; overflow-y: auto">

        <div class="text-xs-center pa-2" v-if="showTemplateEditor && sendingMail">
            <v-progress-circular
                    :size="30"
                    :width="3"
                    color="secondary"
                    indeterminate
            />

            <div class="caption greyType--text text-xs-center">Sending Mail Template</div>
        </div>

        <template v-else>
            <v-layout row align-center style="overflow-x: hidden; overflow-y: auto" v-if="computedObj !== null" class="pt-1">
                <v-flex xs12 style="overflow-x: hidden">
                    <app-general-mail-container
                            :mail="computedObj"
                            :user-info="userInfo"
                            :dashboard="false"
                            :task-band="false"
                            :task-value="true"
                            :srcTaskAttachment="srcTaskAttachment"
                            :dense="true"
                    />
                </v-flex>
                <v-flex shrink class="pl-2">
                    <v-tooltip top>
                        <v-btn
                                slot="activator"
                                color="primary"
                                icon
                                fab
                                class="pa-0 ma-0"
                                @click="getTemplateReady"
                                v-if="intToBool(userInfo.gmailEnabled) || intToBool(userInfo.office365Enabled) || intToBool(this.userInfo.smtpAddressEnabled)"
                                :disabled="!intToBool(value.editable) || !stepEditable"
                        >
                            <v-icon>
                                send
                            </v-icon>
                        </v-btn>
                        <span>Send New Mail</span>
                    </v-tooltip>
                </v-flex>
            </v-layout>

            <div class="text-xs-center" v-else>
                <v-btn
                        color="primary"
                        small
                        @click="getTemplateReady"
                        v-if="intToBool(userInfo.gmailEnabled) || intToBool(userInfo.office365Enabled) || intToBool(this.userInfo.smtpAddressEnabled)"
                        :disabled="!intToBool(value.editable) || !stepEditable"
                >
                    <v-icon left>
                        send
                    </v-icon>
                    Send Mail
                </v-btn>
            </div>
        </template>

        <app-task-mail-template-dlg
                v-if="!resetTemplate && (showTemplateEditor || sendingMail)"
                v-show="!sendingMail"
                :user-info="userInfo"
                :fields="fields"
                :steps="steps"
                :mail-template="this.value.lookup"
                :field-sets="fieldSets"
                :task="task"
                :show-dialog="showTemplateEditor"
                :field="value"
                :step="step"
                @dismiss="showTemplateEditor = false"
                :mail-config="mailConfig"
                @sending="sendingMail = $event"
                :binder="binder"
        />

    </div>
</template>

<script>

import {codeBaseMixin} from "../../../../../../codeBaseMixin";
import TaskMailTemplateDlg from './Mail Template Components/TaskMailTemplateDlg';
import GeneralMailContainer from '../../../../../../components/General/generalMailContainer';
import {DatasetBinder} from "@/Tasks/TaskView V2/components/TaskViewDetail/datasetBinder";

export default {
    mixins:[codeBaseMixin],
    components: {
        appTaskMailTemplateDlg: TaskMailTemplateDlg,
        appGeneralMailContainer: GeneralMailContainer,
    },
    props: {
        userInfo: Object,
        value: Object,
        fields: Array,
        fieldSets: Array,
        task: Object,
        steps: Array,
        step: Object,
        stepEditable: Boolean,
        srcTaskAttachment: {
            type: Boolean,
            default: false
        },

        mailConfig: {
            required: true,
            type: Object
        },
        binder: DatasetBinder
    },
    data() {
        return {
            sendingMail: false,
            showTemplateEditor: false,
            resetTemplate: false
        }
    },
    methods: {
        getTemplateReady() {
            // this.resetTemplate = true;
            // this.$nextTick(() => {
            //     this.resetTemplate = false;
            this.showTemplateEditor = true;
            // })
        },


    },
    computed: {
        computedObj() {
            if (this.value.value != null && this.value.value != '') {
                let tMail = null;
                this.task.structure.mails.forEach(mail => {
                    if (mail.mailDBID == this.value.value) {
                        tMail = mail
                    }
                });
                return tMail
            } else {
                return null
            }

        },
    },

}
</script>

<style scoped>

</style>
