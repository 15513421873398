<template>
    <v-card class="dashboardTaskActivation" flat hover>
        <v-layout align-center row>
            <v-flex shrink class="xs12 pl-1 pr-1 py-1">
                <div class="caption font-weight-bold">Task Activated</div>
                <div class="caption font-weight-light">
                    {{ getLongDate(activation) }}
                </div>
            </v-flex>
            <v-flex shrink class="pa-1">
                <v-btn
                        flat
                        icon
                        class="pa-0 ma-0"
                        @click="removeActivateNotification"
                        color="secondary"
                        small
                        :loading="responsePending"
                        :disabled="responsePending || readOnlyDash"
                >
                    <v-icon small>close</v-icon>
                </v-btn>
            </v-flex>
        </v-layout>
    </v-card>
</template>

<script>
    import { codeBaseMixin } from '../../../codeBaseMixin';

    export default {
        mixins: [ codeBaseMixin ],
        props: {
            userInfo: Object,
            activation: String,
            taskId: Number,
            readOnlyDash: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                responsePending: false
            }
        },
        methods: {
            removeActivateNotification() {
                this.responsePending = true;
                this.fetch({method: 'DELETE', url:`/tasks/snoozeTask/${this.userInfo.entityID}/${this.userInfo.userID}/${this.taskId}`})
                    .then(() => {
                        this.responsePending = false;
                        this.$emit('removed')
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e)
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                        this.responsePending = false;
                    })
            }
        }
    }
</script>

<style scoped>
    .dashboardTaskActivation {
        border-left: 5px solid var(--v-tBlue-base);
    }
</style>
