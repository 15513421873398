<template>
    <v-dialog
            v-model="value"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
            persistent
    >
        <v-card flat height="100vh" style="background: var(--page-back)">
            <v-layout column fill-height >
                <v-flex shrink title-color>
                    <!--Card Title-->
                    <v-card-title class="py-2 pr-2 ">
                        <v-icon class="pr-2 white--text">category</v-icon>
                        <span class="title font-weight-light white--text">Productivity Reports</span>
                        <v-spacer></v-spacer>
                        <v-btn flat icon color="white" class="pa-0 ma-0" @click="$emit('dismiss', false)">
                            <v-icon>close</v-icon>
                        </v-btn>
                    </v-card-title>
                </v-flex>
                <!--Loading-->
                <v-flex shrink v-if="loadingData" class="pa-2 text-xs-center">
                    <v-progress-circular
                            :size="40"
                            :width="5"
                            color="primary"
                            indeterminate
                    />
                </v-flex>
                <!--Done Loading-->
                <template v-else>
<!--Main View-->
                    <v-flex xs12 class="scrollable">
                        <v-layout row fill-height class="scrollable">
                            <v-flex xs4 fill-height class="pa-1">
                                <v-card class="fill-height c-d-flex c-flex-column">

                                    <div class="c-flex-grow-0 px-2 py-1 white--text title-color">
                                        Report Options
                                    </div>

                                    <div class="c-flex-grow-1 scrollable c-d-flex c-flex-column">
<!--Report Period-->
                                        <div class="px-2 pt-2 caption grey--text text--darken-2 font-weight-medium">
                                            Report Period
                                        </div>
                                        <div>
                                            <v-menu
                                                    ref="menu"
                                                    v-model="menu"
                                                    :close-on-content-click="false"
                                                    :nudge-right="40"
                                                    lazy
                                                    transition="scale-transition"
                                                    offset-y

                                                    full-width
                                                    min-width="290px"
                                            >
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field
                                                            label="Report Period"
                                                            dense
                                                            hide-details
                                                            class="pt-0 px-3"
                                                            prepend-inner-icon="event"
                                                            readonly
                                                            :value="reportPeriodString"
                                                            v-on="on"
                                                            single-line
                                                    >
                                                    </v-text-field>
                                                </template>
                                                <v-date-picker
                                                        v-model="selectedMonth"
                                                        landscape
                                                        type="month"
                                                        scrollable
                                                        @input="menu = false"
                                                >
                                                </v-date-picker>
                                            </v-menu>
                                        </div>
<!--Report Type-->
                                        <div class="px-2 pt-2 caption grey--text text--darken-2 font-weight-medium">
                                            Report Type
                                        </div>
                                        <v-radio-group v-model="selectedReportType" class="pl-3 my-0 pb-0 c-flex-grow-0" hide-details>
                                            <v-radio
                                                    v-for="(type, i) in reportTypes"
                                                    :key="i"
                                                    :label="type"
                                                    :value="i"
                                                    color="primary"
                                            ></v-radio>
                                        </v-radio-group>
<!--Filters-->
                                        <div class="px-2 pt-2 caption grey--text text--darken-2 font-weight-medium">
                                            Filters
                                        </div>
<!--Workflow Filter-->
                                        <v-card class="mt-1 mx-1 caption title-color white--text c-d-flex c-align-center" @click="expandFilter('workflowsExpanded')" style="cursor: pointer" hover>
                                            <div class="pa-2 c-flex-grow-1">Workflows</div>
                                            <div class="caption font-weight-medium pr-2">
                                                ({{selectedWorkflows.length || 'All'}})
                                            </div>
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on }">
                                                    <v-btn
                                                            small
                                                            icon
                                                            flat
                                                            :color="selectedWorkflows.length ? 'white' : 'primary darken-1'"
                                                            class="pa-0 ma-0"
                                                            @click="selectedWorkflows = []"
                                                            v-on="on"
                                                    >
                                                        <v-icon small>check_box</v-icon>
                                                    </v-btn>
                                                </template>
                                                Reset Selections
                                            </v-tooltip>
                                            <v-btn
                                                    small
                                                    icon
                                                    flat
                                                    color="white"
                                                    class="pa-0 ma-0 mr-2"
                                            >
                                                <v-icon>{{workflowsExpanded ? 'arrow_drop_up' : 'arrow_drop_down'}}</v-icon>
                                            </v-btn>
                                        </v-card>
                                        <div class="c-flex-grow-1 scrollable" v-if="workflowsExpanded">
                                            <div>
                                                <v-text-field
                                                        single-line
                                                        label="Search Workflows"
                                                        prepend-icon="search"
                                                        v-model="workflowFilterString"
                                                        hide-details
                                                        clearable
                                                        @click:clear="workflowFilterString = null; $refs.workflowTree.updateAll(false)"
                                                        @input="$refs.workflowTree.updateAll(!!workflowFilterString)"
                                                        class="pb-0 pt-0 px-2"
                                                >
                                                </v-text-field>
                                            </div>
                                            <v-treeview
                                                    class="pt-2 pl-2 compact-tree"
                                                    color="primary"
                                                    :items="workflowTreeFiltered"
                                                    item-children="members"
                                                    item-text="description"
                                                    item-key="id"
                                                    dense
                                                    compact
                                                    hide-details
                                                    transition
                                                    open-on-click
                                                    :open-all="!!workflowFilterString"
                                                    ref="workflowTree"
                                            >
                                                <template
                                                        slot="label"
                                                        slot-scope="prop"
                                                >
                                                    <v-layout
                                                            row
                                                            align-center
                                                            @click="prop.item.type === 'workflow' ? selectWorkflow(prop.item) : null"
                                                    >
                                                        <v-flex shrink v-if="prop.item.type === 'workflow'">
                                                            <v-btn
                                                                    style="height: 24px; width: 24px"
                                                                    :color="selectedWorkflows.includes(prop.item.id) ? 'primary' : 'grey'"
                                                                    small
                                                                    icon
                                                                    class="pa-0 ma-0"
                                                                    flat
                                                            >
                                                                <v-icon
                                                                        style="cursor: pointer; height: 18px; width: 18px"
                                                                        class="pa-0 ma-0"
                                                                        small
                                                                >
                                                                    {{selectedWorkflows.includes(prop.item.id) ? 'check_box' : 'check_box_outline_blank'}}
                                                                </v-icon>
                                                            </v-btn>

                                                        </v-flex>
                                                        <v-flex>
                                                            <span class="body-2 greyType--text">{{ prop.item.description }}</span>
                                                        </v-flex>
                                                    </v-layout>
                                                </template>
                                            </v-treeview>
                                        </div>
<!--Users Filter-->
                                        <v-card class="mt-1 mx-1 caption title-color white--text c-d-flex c-align-center" @click="expandFilter('usersExpanded')" style="cursor: pointer" hover>
                                            <div class="pa-2 c-flex-grow-1">Users</div>
                                            <div class="caption font-weight-medium pr-2">
                                                ({{selectedUsers.length || 'All'}})
                                            </div>
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on }">
                                                    <v-btn
                                                            small
                                                            icon
                                                            flat
                                                            :color="selectedUsers.length ? 'white' : 'primary darken-1'"
                                                            class="pa-0 ma-0"
                                                            @click="selectedUsers = []"
                                                            v-on="on"
                                                    >
                                                        <v-icon small>check_box</v-icon>
                                                    </v-btn>
                                                </template>
                                                Reset Selections
                                            </v-tooltip>
                                            <v-btn
                                                    small
                                                    icon
                                                    flat
                                                    color="white"
                                                    class="pa-0 ma-0 mr-2"
                                            >
                                                <v-icon>{{usersExpanded ? 'arrow_drop_up' : 'arrow_drop_down'}}</v-icon>
                                            </v-btn>
                                        </v-card>
                                        <div class="c-flex-grow-1 scrollable" v-if="usersExpanded">
                                            <multi-select-list
                                                    :show-action-buttons="false"
                                                    :show-check-boxes="true"
                                                    :multiple="true"
                                                    headline-key="name"
                                                    item-key="userID"
                                                    :return-object="false"
                                                    v-model="selectedUsers"
                                                    :show-filter="true"
                                                    :items="localData.users"
                                                    :dark="false"
                                                    filter-label="Search Users"
                                            >
                                                <template v-slot:headline="{value}">
                                                    <div :class="value.disabled ? 'grey--text' : null">
                                                        <div v-if="value.name || value.surname || value.disabled">
                                                            {{`${value.name} ${value.surname}${value.disabled ? ' (Disabled)' : ''}`.trim()}}
                                                        </div>
                                                        <div class="caption">
                                                            {{`${value.username}`.trim()}}
                                                        </div>
                                                    </div>


                                                </template>
                                            </multi-select-list>
                                        </div>
<!--User Groups Filter-->
                                        <v-card class="mt-1 mx-1 caption title-color white--text c-d-flex c-align-center" @click="expandFilter('userGroupsExpanded')" style="cursor: pointer" hover>
                                            <div class="pa-2 c-flex-grow-1">User Groups</div>
                                            <div class="caption font-weight-medium pr-2">
                                                ({{selectedUserGroups.length || 'All'}})
                                            </div>
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on }">
                                                    <v-btn
                                                            small
                                                            icon
                                                            flat
                                                            :color="selectedUserGroups.length ? 'white' : 'primary darken-1'"
                                                            class="pa-0 ma-0"
                                                            @click="selectedUserGroups = []"
                                                            v-on="on"
                                                    >
                                                        <v-icon small>check_box</v-icon>
                                                    </v-btn>
                                                </template>
                                                Reset Selections
                                            </v-tooltip>
                                            <v-btn
                                                    small
                                                    icon
                                                    flat
                                                    color="white"
                                                    class="pa-0 ma-0 mr-2"
                                            >
                                                <v-icon>{{userGroupsExpanded ? 'arrow_drop_up' : 'arrow_drop_down'}}</v-icon>
                                            </v-btn>
                                        </v-card>
                                        <div class="c-flex-grow-1 scrollable" v-if="userGroupsExpanded">
                                            <multi-select-list
                                                    :show-action-buttons="false"
                                                    :show-check-boxes="true"
                                                    :multiple="true"
                                                    headline-key="description"
                                                    item-key="id"
                                                    :return-object="false"
                                                    v-model="selectedUserGroups"
                                                    :show-filter="true"
                                                    :items="localData.userGroups"
                                                    :dark="false"
                                                    filter-label="Search User Groups"
                                            >
                                            </multi-select-list>
                                        </div>
<!--Folder Groups Filter-->
                                        <v-card class="mt-1 mx-1 caption title-color white--text c-d-flex c-align-center" @click="expandFilter('folderGroupsExpanded')" style="cursor: pointer" hover>
                                            <div class="pa-2 c-flex-grow-1">Folder Groups</div>
                                            <div class="caption font-weight-medium pr-2">
                                                ({{selectedFolderGroups.length || 'All'}})
                                            </div>
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on }">
                                                    <v-btn
                                                            small
                                                            icon
                                                            flat
                                                            :color="selectedFolderGroups.length ? 'white' : 'primary darken-1'"
                                                            class="pa-0 ma-0"
                                                            @click="selectedFolderGroups = []"
                                                            v-on="on"
                                                    >
                                                        <v-icon small>check_box</v-icon>
                                                    </v-btn>
                                                </template>
                                                Reset Selections
                                            </v-tooltip>
                                            <v-btn
                                                    small
                                                    icon
                                                    flat
                                                    color="white"
                                                    class="pa-0 ma-0 mr-2"
                                            >
                                                <v-icon>{{folderGroupsExpanded ? 'arrow_drop_up' : 'arrow_drop_down'}}</v-icon>
                                            </v-btn>
                                        </v-card>
                                        <div class="c-flex-grow-1 scrollable" v-if="folderGroupsExpanded">
                                            <multi-select-list
                                                    :show-action-buttons="false"
                                                    :show-check-boxes="true"
                                                    :multiple="true"
                                                    headline-key="description"
                                                    item-key="id"
                                                    :return-object="false"
                                                    v-model="selectedFolderGroups"
                                                    :show-filter="true"
                                                    :items="localData.folderGroups"
                                                    :dark="false"
                                                    filter-label="Search Folder Groups"
                                            >
                                            </multi-select-list>
                                        </div>

                                        <div v-if="workflowsExpanded || usersExpanded || userGroupsExpanded || folderGroupsExpanded" class="c-flex-grow-1"></div>
                                    </div>
                                </v-card>
                            </v-flex>
                            <v-flex xs12 fill-height class="scrollable pa-1">
                                <div class="fill-height c-d-flex c-align-center c-justify-center" v-if="loadingReportData">
                                    <v-progress-circular
                                            :size="40"
                                            :width="3"
                                            color="primary"
                                            indeterminate
                                    ></v-progress-circular>
                                </div>
                                <v-card v-else class="fill-height c-d-flex c-flex-column">
                                    <div class="white--text title-color c-flex-grow-0 px-2 c-d-flex">
                                        <div class="py-1 c-flex-grow-1">
                                            {{selectedReportString}}
                                        </div>
                                        <v-tooltip left>
                                            <template v-slot:activator="{ on }">
                                                <v-btn
                                                        small
                                                        color="white"
                                                        class="px-2 py-0 my-0"
                                                        @click="downloadData"
                                                        :loading="exportingData"
                                                        :disabled="exportingData"
                                                        icon
                                                        flat
                                                        v-on="on"
                                                >
                                                  <v-icon>calendar_today</v-icon>
                                                </v-btn>
                                            </template>
                                            Download Report
                                        </v-tooltip>
                                      <v-tooltip left>
                                        <template v-slot:activator="{ on }">
                                          <v-btn
                                              small
                                              color="white"
                                              class="px-2 py-0 my-0"
                                              @click="downloadDataPerDay"
                                              :loading="exportingData"
                                              :disabled="exportingData"
                                              icon
                                              flat
                                              v-on="on"
                                              style="position: relative"
                                          >
                                            <v-icon>calendar_month</v-icon>
                                          </v-btn>
                                        </template>
                                        Download Per Day Report
                                      </v-tooltip>

                                    </div>
                                    <v-grid
                                            class="c-flex-grow-1"
                                            :theme="$store.state.darkTheme ? 'darkCompact' : 'compact'"
                                            :source="reportData"
                                            :columns="viewCols"
                                            :autoSizeColumn="{
                                                mode: 'autoSizeOnTextOverlap',
                                            }"
                                            :resize="true"
                                            :export="false"
                                            @beforeCellFocus="beforeEdit"
                                            ref="dataGrid"
                                    ></v-grid>
                                </v-card>
                            </v-flex>
                        </v-layout>
                    </v-flex>
                </template>
            </v-layout>
        </v-card>
    </v-dialog>
</template>

<script>
import {mapGetters} from 'vuex'
import {format} from "date-fns";
import {arrSplice, descriptionSort} from "@/codeFunctions";
import MultiSelectList from "@/components/Multi Select List/MultiSelectList";
import VGrid from "@revolist/vue-datagrid";

export default {
    components: {MultiSelectList, VGrid},
    props: {
        value: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            con: console,
            // Config
            selectedMonth: format(new Date(), 'YYYY-MM'),
            selectedReportType: 0,
            selectedWorkflows: [],
            selectedUsers: [],
            selectedUserGroups: [],
            selectedFolderGroups: [],
            // Local
            workflowTree: null,
            workflowsExpanded: false,
            workflowFilterString: null,
            usersExpanded: false,
            userGroupsExpanded: false,
            folderGroupsExpanded: false,
            reportTypes: ['User', 'Workflow', 'Folder', 'Folder Group'],
            loadingData: false,
            loadingReportData: false,
            menu: false,

            reportData: [],
            loadIndex: 0,
            exportingData: false
        }
    },
    watch: {
        value: {
            immediate: true,
            handler(val) {
                if (val)
                    this.getMeta()
            }
        },

        selectedMonth() {
            this.getData();
        },
        selectedReportType() {
            this.getData();
        },
        selectedWorkflows() {
            this.getData();
        },
        selectedUsers() {
            this.getData();
        },
        selectedUserGroups() {
            this.getData();
        },
        selectedFolderGroups() {
            this.getData();
        },
    },
    methods: {

        downloadData() {
            this.exportingData = true;
            this.$newReqDownloadFile(
                    'POST',
                    `productivityReports/reportDataExport/${this.userInfo.entityID}`,
                    {
                        userGroups: this.selectedUserGroups,
                        users: this.selectedUsers,
                        workflows: this.selectedWorkflows,
                        folderGroups: this.selectedFolderGroups,
                        month: this.selectedMonth,
                        reportType: this.selectedReportType,
                    },
                    `${this.selectedReportString} ${this.reportPeriodString}.xlsx`,
            )
                    .catch(e => {
                        console.log(e)
                        this.$snack.networkError();
                    })
                    .finally(() => {
                        this.exportingData = false;
                    });
        },
      
      downloadDataPerDay() {
        this.exportingData = true;
        this.$newReqDownloadFile(
            'POST',
            `productivityReports/reportDataExport/${this.userInfo.entityID}/perDay`,
            {
              userGroups: this.selectedUserGroups,
              users: this.selectedUsers,
              workflows: this.selectedWorkflows,
              folderGroups: this.selectedFolderGroups,
              month: this.selectedMonth,
              reportType: this.selectedReportType,
            },
            `${this.selectedReportString} ${this.reportPeriodString} Per Day.xlsx`,
        )
            .catch(e => {
              console.log(e)
              this.$snack.networkError();
            })
            .finally(() => {
              this.exportingData = false;
            });
      },

        filter () {
            return this.caseSensitive
                    ? (item, search, textKey) => item[textKey].indexOf(this.workflowFilterString) > -1
                    : undefined
        },

        selectWorkflow(workflow) {
            if (this.selectedWorkflows.includes(workflow.id))
                arrSplice(this.selectedWorkflows, (id) => id === workflow.id)
            else
                this.selectedWorkflows.push(workflow.id)
        },

        getMeta() {
            this.loadingData = true
            this.$newReq('GET', `productivityReports/reportMeta/${this.userInfo.entityID}`)
                .then(({workflowTree}) => {

                    workflowTree
                            .sort(descriptionSort)
                            .forEach(workspace => {
                                workspace.members
                                        .sort(descriptionSort)
                                        .forEach(workflowGroup => workflowGroup.members.sort(descriptionSort))
                            })

                    this.workflowTree = workflowTree;
                    this.getData();
                })
            .catch(e => {
                console.log(e)
                this.$snack.networkError()
            })
            .finally(() => this.loadingData = false)
        },

        beforeEdit(event) {
            event.preventDefault();
        },

        getData() {
            ++this.loadIndex;
            const index = this.loadIndex;
            this.loadingReportData = true
            this.$newReq('POST', `productivityReports/reportData/${this.userInfo.entityID}`, {
                userGroups: this.selectedUserGroups,
                users: this.selectedUsers,
                workflows: this.selectedWorkflows,
                folderGroups: this.selectedFolderGroups,
                month: this.selectedMonth,
                reportType: this.selectedReportType,
            })
                    .then((data) => {
                        if (this.loadIndex === index)
                            this.reportData = data
                    })
                    .catch(e => {
                        console.log(e)
                        this.$snack.networkError()
                    })
                    .finally(() => {
                        if (this.loadIndex === index)
                            this.loadingReportData = false
                    })
        },

        expandFilter(value) {
            switch (value) {
                case 'workflowsExpanded': {
                    this.workflowsExpanded = !this.workflowsExpanded;
                    this.usersExpanded = false;
                    this.userGroupsExpanded = false;
                    this.folderGroupsExpanded = false;
                    break;
                }
                case 'usersExpanded': {
                    this.workflowsExpanded = false;
                    this.usersExpanded = !this.usersExpanded;
                    this.userGroupsExpanded = false;
                    this.folderGroupsExpanded = false;
                    break;
                }
                case 'userGroupsExpanded': {
                    this.workflowsExpanded = false;
                    this.usersExpanded = false;
                    this.userGroupsExpanded = !this.userGroupsExpanded;
                    this.folderGroupsExpanded = false;
                    break;
                }
                case 'folderGroupsExpanded': {
                    this.workflowsExpanded = false;
                    this.usersExpanded = false;
                    this.userGroupsExpanded = false;
                    this.folderGroupsExpanded = !this.folderGroupsExpanded;
                    break;
                }

            }
        }
    },
    computed: {

        viewCols() {

            let columns = [
                "Total",
                "Values Saved",
                "Mails",
                "Docs",
                "Notes",
                "Transfers",
                "Statuses",
                "Calendar Events"
            ]

            columns = columns.map((col, i) => {
                return {
                    name: col,
                    prop: col,
                    size: col === "Calendar Events" ? 160 : 120,
                    sortable: true,
                    editor: false,
                }
            })

            let mainCol = {
                autoSize: true,
                sortable: true,
                editor: false,
                // size: 200,
                pin: 'colPinStart',
                filter: true
            }
            switch (this.selectedReportType) {
                case 0: {mainCol = {...mainCol, name: "User", prop: "User"};break;}
                case 1: {mainCol = {...mainCol, name: "Workflow", prop: "Workflow"};break;}
                case 2: {mainCol = {...mainCol, name: "Folder", prop: "Folder"};break;}
                case 3: {mainCol = {...mainCol, name: "Folder Group", prop: "Folder Group"};break;}
            }

            columns.splice(0,0, mainCol)

            return columns
        },

        ...mapGetters(['localData']),
        ...mapGetters('userInfo', ['userInfo']),
        reportPeriodString() {
            return format(new Date(this.selectedMonth + '-01'), 'MMMM YYYY')
        },
        workflowTreeFiltered() {
            if (!this.workflowFilterString)
                return this.workflowTree

            const newTree = JSON.parse(JSON.stringify(this.workflowTree))
                    .reduce((wsList, ws) => {
                        ws.members = ws.members.reduce((wfgList, wfg) => {
                            wfg.members = wfg.members.filter(wf => (wf.description || '').toLowerCase().includes((this.workflowFilterString || '').toLowerCase())).sort((a,b) => a.description > b.description ? 1 : -1)
                            return wfg.members.length ? wfgList.concat(wfg) : wfgList
                        }, []).sort((a,b) => a.description > b.description ? 1 : -1)
                        return ws.members.length ? wsList.concat(ws) : wsList
                    }, []).sort((a,b) => a.description > b.description ? 1 : -1)


            return newTree
        },
        selectedReportString() {
            switch (this.selectedReportType) {
                case 0: return 'User Usage Report'
                case 1: return 'Workflow Usage Report'
                case 2: return 'Folder Usage Report'
                case 3: return 'Folder Group Usage Report'
            }
            return '';
        }
    },
}
</script>

<style scoped>

</style>