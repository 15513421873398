<template>
    <v-layout column :fill-height="height == null" style="overflow-y: auto; overflow-x: hidden" :style="height !== null ? `height:${height}px` : null">
        <v-flex shrink>
            <slot name="header"></slot>
        </v-flex>
        <v-flex shrink
                v-if="multiple || showFilter"
                :style="{background: dark ? 'var(--v-title-color-base)' : 'var(--card-back)'}"
        >

            <v-layout row align-center>
                <v-flex shrink class="pl-2" v-if="selectAll !== null && showSelectAll">
                    <v-tooltip top>
                            <v-icon
                                    :color="dark ? 'white' : (selectAll ? 'grey' : 'primary')"
                                    class="pa-0 ma-0"
                                    @click="selectAll = !selectAll"
                                    slot="activator"
                                    style="cursor: pointer"
                            >{{selectAll ? 'check_box_outline_blank' : 'check_box'}}</v-icon>

                        <span>{{selectAll ? 'Select All Items' : 'Clear Selection'}}</span>
                    </v-tooltip>
                </v-flex>
                <v-flex xs12 class="px-2 py-1" v-if="showFilter">
                    <v-text-field
                            :label="filterLabel"
                            single-line
                            clearable
                            prepend-inner-icon="search"
                            :color="dark ? 'white' : 'primary'"
                            flat
                            :dark="dark"
                            :hide-details="true"
                            v-model="searchStr"
                            class="pa-0 ma-0"
                            ref="myField"
                    ></v-text-field>
                </v-flex>
                <v-flex v-if="!showFilter && multiple" xs12 class="px-2 py-2 greyType--text">
                    {{selectAll ? 'Select All Items' : 'Clear Selection'}}
                </v-flex>
            </v-layout>
        </v-flex>
        <v-flex shrink>
            <slot name="afterFilter"></slot>
        </v-flex>
        <v-divider></v-divider>
        <v-flex xs12 style="overflow-y: auto; overflow-x: hidden">
            <draggable
                    :value="displayItems"
                    group="sections"
                    @start="drag=true"
                    @end="drag=false"
                    ghostClass="ghost"
                    @input="$emit('sortEvent', $event)"
            >
                <div v-for="(item, i) in displayItems" :key="i" class="c-d-flex c-align-center">
                    <multi-select-list-item
                            class="c-flex-grow-1"
                            :key="item[itemKey]"
                            :line1="headlineKey"
                            :line2="subLineKey"
                            :item="item"
                            :action-buttons="showActionButtons ? actionButtons : null"
                            :loading="loading ? loadingItems.includes(item) : false"
                            :showCheckBoxes="showCheckBoxes"
                            :selected="selectedItems.includes(item)"
                            @select="selectItem(item)"
                            @selection="selectionItem(item, $event)"
                    >
                        <template slot="headline">
                            <slot
                                    name="headline"
                                    :value="item"
                                    :index="i"
                                    :selected="selectedItems.includes(item)"
                            />
                        </template>
                        <template slot="action">
                            <slot
                                    name="action"
                                    :value="item"
                                    :index="i"
                                    :selected="selectedItems.includes(item)"
                            />
                        </template>
                    </multi-select-list-item>

                </div>
            </draggable>

        </v-flex>
        <v-flex shrink>
            <slot name="footer"></slot>
        </v-flex>
    </v-layout>
</template>

<script>
    import {arrPopulated, blankString} from "../../codeFunctions";
    import draggable from 'vuedraggable';
    import MultiSelectListItem from "./MultiSelectListItem";
    export default {
        components: {MultiSelectListItem , draggable,},
        props: {
            items: Array,
            itemKey: {default: null, type: String},
            returnObject: {default: false, type: Boolean},
            showCheckBoxes: {default: false, type: Boolean},
            multiple: {default: true, type: Boolean},
            loading: {default: null, type: [Array, Object, Number, String, Boolean]},

            headlineKey: String,
            subLineKey: String,

            showActionButtons: {default: false, type: Boolean},
            actionButtons: Array,

            value: {default: null, type: [Array, Object, Number, String]},
            // {icon, color, cb, hoverItem}
            showFilter: {default: true, type: Boolean},

            filterLabel: {default: 'Filter Items', type: String},
            dark: {default: false, type: Boolean},
            height: {default: null, type: Number},
            showSelectAll: {default: true, type: Boolean},
            cutomSortFn: Function
        },
        data() {
            return {
                searchStr: null
            }
        },
        methods:{
            clearFilter() {
                this.searchStr = null;



            },
            selectItem(item) {
                this.$emit('input', this.multiple ? [(this.returnObject ? item : item[this.itemKey])] : (this.returnObject ? item : item[this.itemKey]));
            },
            selectionItem(item, add) {
                let vm = this;
                function input(val) {vm.$emit('input', val);}

                if (this.multiple) {
                    if (arrPopulated(this.value)) {
                        input(add
                            ? (
                                this.returnObject
                                    ? this.value.concat([item])
                                    : this.value.concat([item[this.itemKey]])
                            )
                            : (
                                this.returnObject
                                    ? this.value.filter(obj => obj !== item)
                                    : this.value.filter(obj => obj !== item[this.itemKey])
                            )
                        )
                    } else {
                        input(add
                            ? (
                                this.returnObject
                                    ? [item]
                                    : [item[this.itemKey]]
                            )
                            : null
                        )
                    }
                } else {
                    return add
                        ? input(this.returnObject ? item : item[this.itemKey])
                        : input(null)
                }
            }
        },
        computed: {
            displayItems() {
                return (this.items || [])
                    .filter(obj => blankString(this.searchStr)
                        ? true
                        : !blankString(obj[this.headlineKey])
                            ? (obj[this.headlineKey].toLowerCase().includes(this.searchStr.toLowerCase()))
                            : false
                    )
                    .sort(this.cutomSortFn ? this.cutomSortFn : (a,b) => (!blankString(a[this.headlineKey]) ? a[this.headlineKey] : '') < (!blankString(b[this.headlineKey]) ? b[this.headlineKey] : '') ? -1 : 1);
            },

            selectAll: {
                get() {
                    if (!this.multiple) {
                        return null
                    } else {
                        return !arrPopulated(this.value)
                    }
                },
                set(val) {
                    this.$emit('input', val
                        ? []
                        : (
                            this.returnObject
                                ? this.displayItems
                                : this.displayItems.map(obj => {return obj[this.itemKey]})
                        )
                    )
                }
            },

            selectedItems() {
                if (this.multiple) {
                    if (arrPopulated(this.value)) {
                        return this.returnObject
                            ? this.value
                            : this.items.filter(obj => this.value.includes(obj[this.itemKey]))
                    } else {
                        return []
                    }
                } else {
                    try {
                        return this.returnObject
                            ? [this.value]
                            : this.items.filter(obj => obj[this.itemKey] === this.value)
                    } catch (e) {
                        return null
                    }
                }
            },
            loadingItems() {
                if (this.loading === true) {
                    return this.items
                } else {
                    if (this.multiple) {
                        if (arrPopulated(this.loading)) {
                            return this.returnObject
                                ? this.loading
                                : this.items.filter(obj => this.loading.includes(obj[this.itemKey]))
                        } else {
                            return []
                        }
                    } else {
                        try {
                            return this.returnObject
                                ? [this.loading]
                                : this.items.filter(obj => obj[this.itemKey] === this.loading)
                        } catch (e) {
                            return null
                        }
                    }
                }
            }
        }
    }
</script>

<style scoped>

</style>
