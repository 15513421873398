import { render, staticRenderFns } from "./TaskBarContainer.vue?vue&type=template&id=37f8fd85&scoped=true"
import script from "./TaskBarContainer.vue?vue&type=script&lang=js"
export * from "./TaskBarContainer.vue?vue&type=script&lang=js"
import style0 from "./TaskBarContainer.vue?vue&type=style&index=0&id=37f8fd85&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37f8fd85",
  null
  
)

export default component.exports