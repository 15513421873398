<template>
    <div class="c-d-flex c-align-center hover-item">
        <div class="body-2 c-flex-grow-1 px-2">
            {{sub.user.fullName}}
        </div>
        <div>
            <v-tooltip left>
                <v-btn
                        :disabled="sub.fkUser !== this.userInfo.userID || loading"
                        slot="activator"
                        color="secondary"
                        small
                        icon
                        flat
                        class="pa-0 ma-0"
                        :loading="loading"
                        @click="updateSubscription()"
                >
                    <v-icon>{{sub.removed ? 'check_box' : 'check_box_outline_blank'}}</v-icon>
                </v-btn>
                {{tooltip}}
            </v-tooltip>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {format} from "date-fns";

export default {
    components: {},
    props: {
        event: Object,
        sub: Object
    },
    data() {
        return {
            loading: false
        }
    },
    watch: {},
    methods: {

        updateSubscription() {
            this.loading = true;
            this.event.updateSubscription(!this.sub.removed, (payload, e) => {
                if (e) {
                    this.$snack.networkError();
                    console.log(e)
                } else {
                    this.$snack.info('Changes Saved')
                }
                this.loading = false
            })
        }

    },
    computed: {
        ...mapGetters('userInfo', ['userInfo']),
        tooltip() {
            if (this.sub.removed) {
                return `Notification Removed - ${format(this.sub.removeDate, 'Do MMM YYYY hh:mm a')}`
            } else {
                return this.sub.fkUser === this.userInfo.userID ? 'Remove Notification' : 'Notification Pending'
            }
        }
    },
}
</script>

<style scoped>

</style>