<template>
    <v-layout row align-center wrap>
        <v-flex xs12>

            <v-flex xs12 v-if="blockInput" class="py-1">
                <v-layout row align-center>
                    <v-flex shrink>
                        <v-icon class="px-2 amber--text">warning</v-icon>
                    </v-flex>
                    <v-flex>
                        <span class="body-2 greyType--text">{{ folderSelectField.description + ' Required' }}</span>
                    </v-flex>
                </v-layout>
            </v-flex>

            <v-layout column v-else>
                <v-flex shrink class="pt-1">
                    <template v-if="value.value != null && value.value != '' && JSON.parse(value.value).length > 0">
                        <app-split-task-container
                                v-for="task in JSON.parse(value.value)"
                                :taskID="task"
                                :userInfo="userInfo"
                                :folder-groups="folderGroups"
                                :get-task-header="getTaskHeader"
                                @taskBarDrop="taskDocDropped($event)"
                                :allow-drop="true"
                        />
                    </template>
                </v-flex>
                <v-flex shrink class="text-xs-center" v-if="showAddButton">
                    <v-btn
                            color="primary"
                            small
                            @click="showCreateSplitTask = true"
                            :disabled="!intToBool(value.editable) || !stepEditable"
                    >
                        <v-icon left>add</v-icon>
                        <span>Create Task</span>
                    </v-btn>
                </v-flex>
            </v-layout>

            <app-task-bar-container-drop-step-select-dlg
                    v-if="copyData.showCopy"
                    :user-info="userInfo"
                    :workflow-i-d="copyData.task.workflowID"
                    :show-dialog="copyData.showCopy"
                    @dismiss="copyData.showCopy = false"
                    @input="copyToTaskStep($event)"
            />

            <app-new-task-dlg
                    v-if="lookup != null"
                    :user-info="userInfo"
                    :show-dialog="showCreateSplitTask"

                    :split-task="true"
                    :split-data="lookup"
                    :task="task"

                    @dismiss="showCreateSplitTask = false"
                    @taskCreated="updateTaskValue($event)"

                    :folder-groups="folderGroups"
                    :local-data="localData"
                    :show-sync="showSync"
                    :get-task-header="getTaskHeader"

                    :binder="binder"
            />
        </v-flex>

    </v-layout>
</template>

<script>

    import {codeBaseMixin} from "../../../../../../codeBaseMixin";
    import {eventBus} from "../../../../../../main";
    import SplitTaskContainer from './Split Task Components/SplitTaskContainer';
    import NewTaskDlg from "../../../../../NewTask/NewTaskDlg";
    import TaskBarContainerDropStepSelectDlg
        from "../../../../../../components/General/TaskBarContainerDropStepSelectDlg";
    import {DatasetBinder} from "@/Tasks/TaskView V2/components/TaskViewDetail/datasetBinder";


    export default {
        mixins:[codeBaseMixin],
        components: {
            appTaskBarContainerDropStepSelectDlg: TaskBarContainerDropStepSelectDlg,
            appNewTaskDlg : NewTaskDlg,
            appSplitTaskContainer : SplitTaskContainer
        },
        props: {
            value: Object,
            task: Object,
            userInfo: Object,
            stepEditable: Boolean,
            folderGroups: Array,
            localData: Object,
            showSync: Boolean,
            getTaskHeader: Function,
            binder: DatasetBinder
        },

        methods: {

            copyToTaskStep(stepID) {
                this.fetch({
                    method: 'POST',
                    url: `/tasks/copyMessage/${this.userInfo.entityID}/${this.userInfo.userID}/${this.copyData.task.taskID}/${stepID}/${this.copyData.dropData.type == 'mail' ? 1 : 3}/${this.copyData.dropData.type == 'mail' ? this.copyData.dropData.data.mailDBID : this.copyData.dropData.data.documentDBID}`,
                })
                    .then((data) => {
                        this.showSnack('Info', 'Copy Complete', 'Close', 'primary')
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e)
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                    })
            },

            taskDocDropped(data) {
                this.copyData.task = data.task;
                this.copyData.dropData = data.dropData;
                this.copyData.showCopy = true;

            },

            updateTaskValue(task) {
                let tValue = [];
                if (this.value.value != null && this.value.value != '' && JSON.parse(this.value.value).length > 0) {
                    tValue = JSON.parse(this.value.value)
                }
                tValue.push(task.taskID);
                this.value.value = JSON.stringify(tValue);
                eventBus.$emit('mailTemplateSaveSent', true)
            }
        },

        data() {
            return {
                showCreateSplitTask: false,
                copyData: {
                    showCopy: false,
                    task: null,
                    dropData: null
                }
            }
        },

        computed: {

            folderSelectField() {
                try {
                    return this.binder.folderSelectFields.filter(fsf => fsf.fieldID == this.lookup.folderSelectField).pop()
                } catch (e) {
                    console.log(e)
                    return null
                }
            },

            blockInput() {
                if (this.lookup.folderType === 3) {
                    return !this.folderSelectField.value
                } else
                    return false
            },

            lookup() {
                try {
                    return JSON.parse(this.value.lookup)
                } catch (e) {
                    // eslint-disable-next-line
                    console.log(e)
                    return null
                }
            },

            showAddButton() {
                if (this.lookup != null) {

                    if (this.value.value != null && this.value.value != '' && JSON.parse(this.value.value).length > 0) {
                        return this.intToBool(this.lookup.splitType)
                    } else {
                        return true
                    }
                } else {
                    return false
                }
            }

        }

    }
</script>

<style scoped>

</style>
