import {newReq} from "@/axiosRequest";

const store = require('./Store/store');

import { useIdle } from '@vueuse/core'
const { idle, lastActive } = useIdle(60 * 1000)

setInterval(() => {
    activityPointRun()
}, 60 * 1000)

function activityPointRun() {
    if (store.default.getters['loggedIn'] && !!store.default.getters['userInfo/userInfo']) {
        if (!idle.value)
            newReq('get', `users/addActivityPoint/${store.default.getters['userInfo/userInfo'].entityID}/${store.default.getters['userInfo/userInfo'].userID}`)
                .catch(e => console.log(e))
    }
}

export {}

