<template>
  <div class="fill-height c-d-flex c-flex-column c-align-center c-justify-center">
    
    <div
        style="left: 0; position: fixed; width: 100%; height: 100%;"
        class="login-back fill-height"
    >
      
      <div style="background: linear-gradient(120deg, #0cb1c7, #00695c); position: absolute; left: 0; top: 0; width: 100%; height: 100%;">
      
      </div>
      
      <div style="position: absolute; left: 0; top: 0; width: 100%; height: 100%; opacity: 20%">
        <video autoplay muted loop id="background-video" style="width: 100%" >
          <source src="../../public/wave5.mp4" type="video/mp4">
        </video>
      </div>
      
      <div class="c-d-flex c-flex-column" style="position: absolute; top: 40%; height: 60%; left: 0; width: 100%">
        <img :src="$store.state.darkTheme ? 'waveDark.svg' : 'wave.svg'" style="width: 100%;">
        <div class="c-flex-grow-1" style="background: var(--page-back)">
        </div>
      </div>
    </div>
    
    
    <v-card
        :flat="sm"
        
        :elevation="sm ? 0 : 5"
        :width="sm ? '100%' : '90%'"
        :max-width="sm ? null : 400"
        :class="{
          'c-flex-grow-1': sm,
          'mt-auto': !sm
        }"
        :style="{'border-radius': !sm ? '15px' : undefined}"
    >
      
      
      
      <v-card-title class="c-d-flex mt-3">
        <img :src="$store.state.darkTheme ? 'colorFullWhiteText.svg' : 'colorFull.svg'" alt="logo" style="width: 100%; max-width: 300px"/>
      </v-card-title>
      <template v-if="!!resetPasswordToken">
        <v-card-text>
          <v-form ref="forgotPassword" v-model="forgotPasswordFormValid">
            
            <div>
              <p class="body-1">Enter a new password</p>
              <p class="caption">Password must include a minimum of 6 characters and have at least one uppercase, lowercase and special character</p>
            </div>
            
            <v-text-field
                color="secondary"
                single-line
                v-model="forgotPasswordResetPassword1"
                :type="showPassword ? 'text' : 'password'"
                label="New Password"
                :rules="[
                  resetPWRules.required,
                  resetPWRules.min,
                  resetPWRules.lower,
                  resetPWRules.upper,
                  resetPWRules.special,
                ]"
                placeholder="New Password"
                class="pr-3"
                ref="passwordField"
                :append-icon="!blankString(password) ? (showPassword ? 'visibility' : 'visibility_off') : null"
                @click:append="showPassword = !showPassword"
            >
              <template slot="prepend">
                <div class="pt-1">
                  <v-icon class="pa-0 ma-0" color="secondary">vpn_key</v-icon>
                </div>
              </template>
            </v-text-field>
            
            <v-text-field
                color="secondary"
                single-line
                v-model="forgotPasswordResetPassword2"
                :type="showPassword ? 'text' : 'password'"
                label="Confirm Password"
                :rules="[resetPWRules.required, samePassword]"
                placeholder="Confirm Password"
                class="pr-3 pt-0"
                :append-icon="!blankString(password) ? (showPassword ? 'visibility' : 'visibility_off') : null"
                @click:append="showPassword = !showPassword"
            >
              <template slot="prepend">
                <div class="pt-1">
                  <v-icon class="pa-0 ma-0" color="secondary">vpn_key</v-icon>
                </div>
              </template>
            </v-text-field>
            
            <!--                <div-->
            <!--                    id="captchaDiv"-->
            <!--                    class="c-d-flex c-justify-center"-->
            <!--                ></div>-->
            
            <!--          <div class="g-recaptcha d-flex justify-center pt-3"-->
            <!--               data-sitekey="6LfR0ZEpAAAAAOR2a4NHqKcQ0jpilHGoH8-RFXJd"-->
            <!--               data-size="invisible"-->
            <!--               data-callback="captchaExec"-->
            <!--               data-expired-callback="captchaExpired"-->
            <!--               data-error-callback="captchaExpired"-->
            <!--               data-badge="inline"-->
            <!--          >-->
            <!--          </div>-->
            
            <div style="width: 100%" class="text-xs-center font-weight-bold caption red--text pb-2" v-if="loginMessage != null">
              {{ loginMessage }}
            </div>
            
            
            <div class="c-d-flex c-justify-end">
              
              <v-btn :disabled="!forgotPasswordFormValid" :loading="settingNewPassword" @click="loginStart" color="secondary" class="mr-2">
                <span>Reset Password</span>
                <!--          <v-icon right>navigate_next</v-icon>-->
              </v-btn>
            </div>
          
          </v-form>
        </v-card-text>
        
        <v-card-actions class="c-d-flex c-justify-center mt-0 pt-0 mb-3">
          <div class="g-recaptcha c-d-flex c-justify-center"
               data-sitekey="6LfXXZApAAAAAC9L3mhu4PXa3kgrAPmvN_qBmFIF"
               data-size="invisible"
               data-callback="captchaExec"
               data-expired-callback="captchaExpired"
               data-error-callback="captchaExpired"
               data-badge="inline"
               :data-theme="$store.state.darkTheme ? 'dark' : 'light'"
          >
          </div>
        </v-card-actions>
      </template>
      <template v-else-if="forgotPasswordDone">
        <v-card-text>
          <div class="body-1">
            <p class="font-weight-bold">Check Your Email</p>
            
            <p>We've sent a password reset link to {{forgotPasswordDone}}. Please check your inbox (and don't forget to look in your
              spam or junk folder) to proceed. The link will expire in 10 minutes.</p>
            
            <p>If you don’t receive the email within a few minutes, you can request another one or contact our support
              team for help</p>
          </div>
        </v-card-text>
      </template>
      <template v-else-if="forgotPassword">
        <v-card-text>
          <v-form ref="forgotPassword" v-model="forgotPasswordFormValid">
            
            <div class="body-2">
              Please enter your username so we can send a password recovery link to the email address associated with your account.
            </div>
            
            <v-text-field
                color="secondary"
                single-line
                v-model="userName"
                label="Username"
                :rules="inputRules"
                placeholder="Username"
                class="py-2 pr-3"
                hide-details
                @keyup.enter="forgotPasswordFormValid ? loginStart() : () => {}"
            >
              <template slot="prepend">
                <div class="pt-1">
                  <v-icon class="pa-0 ma-0" color="secondary">account_circle</v-icon>
                </div>
              </template>
            </v-text-field>
            
            <!--                <div-->
            <!--                    id="captchaDiv"-->
            <!--                    class="c-d-flex c-justify-center"-->
            <!--                ></div>-->
            
            <!--          <div class="g-recaptcha d-flex justify-center pt-3"-->
            <!--               data-sitekey="6LfR0ZEpAAAAAOR2a4NHqKcQ0jpilHGoH8-RFXJd"-->
            <!--               data-size="invisible"-->
            <!--               data-callback="captchaExec"-->
            <!--               data-expired-callback="captchaExpired"-->
            <!--               data-error-callback="captchaExpired"-->
            <!--               data-badge="inline"-->
            <!--          >-->
            <!--          </div>-->
            
            <div style="width: 100%" class="text-xs-center font-weight-bold caption red--text pb-2" v-if="loginMessage != null">
              {{ loginMessage }}
            </div>
            
            
            <div class="c-d-flex c-justify-end">
              <v-btn :loading="sendingForgotPasswordLink" :disabled="!forgotPasswordFormValid || sendingForgotPasswordLink" @click="loginStart" color="secondary" class="mr-2">
                <span>Send Reset Password Link</span>
              </v-btn>
            </div>
          
          </v-form>
        </v-card-text>
        
        <v-card-actions class="c-d-flex c-justify-center mt-0 pt-0 mb-3">
          <div class="g-recaptcha c-d-flex c-justify-center"
               data-sitekey="6LfXXZApAAAAAC9L3mhu4PXa3kgrAPmvN_qBmFIF"
               data-size="invisible"
               data-callback="captchaExec"
               data-expired-callback="captchaExpired"
               data-error-callback="captchaExpired"
               data-badge="inline"
               :data-theme="$store.state.darkTheme ? 'dark' : 'light'"
          >
          </div>
        </v-card-actions>
      </template>
      <template v-else-if="!twoFactorLoginConfig">
        <v-card-text>
          <v-form ref="login">
            <v-text-field
                color="secondary"
                single-line
                v-model="userName"
                label="Email Address"
                :rules="inputRules"
                placeholder="Email Address"
                class="py-2 pr-3"
                hide-details
                @keyup.enter="enterUserName"
            >
              <template slot="prepend">
                <div class="pt-1">
                  <v-icon class="pa-0 ma-0" color="secondary">account_circle</v-icon>
                </div>
              </template>
            </v-text-field>
            
            <v-text-field
                @keyup.enter="loginStart"
                color="secondary"
                single-line
                v-model="password"
                :type="showPassword ? 'text' : 'password'"
                label="Password"
                :rules="inputRules"
                placeholder="Password"
                class="py-2 pr-3"
                ref="passwordField"
                :append-icon="!blankString(password) ? (showPassword ? 'visibility' : 'visibility_off') : null"
                @click:append="showPassword = !showPassword"
            >
              <template slot="prepend">
                <div class="pt-1">
                  <v-icon class="pa-0 ma-0" color="secondary">vpn_key</v-icon>
                </div>
              </template>
            </v-text-field>
            
            <!--                <div-->
            <!--                    id="captchaDiv"-->
            <!--                    class="c-d-flex c-justify-center"-->
            <!--                ></div>-->
            
            <!--          <div class="g-recaptcha d-flex justify-center pt-3"-->
            <!--               data-sitekey="6LfR0ZEpAAAAAOR2a4NHqKcQ0jpilHGoH8-RFXJd"-->
            <!--               data-size="invisible"-->
            <!--               data-callback="captchaExec"-->
            <!--               data-expired-callback="captchaExpired"-->
            <!--               data-error-callback="captchaExpired"-->
            <!--               data-badge="inline"-->
            <!--          >-->
            <!--          </div>-->
            
            <div style="width: 100%" class="text-xs-center font-weight-bold caption red--text pb-2" v-if="loginMessage != null">
              {{ loginMessage }}
            </div>
            
            
            <div class="c-d-flex c-justify-space-between">
              
              <div>
                <div>
                  <v-btn
                      class="my-0"
                      small
                      flat
                      round
                      @click="forgotPassword = true"
                  >
                    <v-icon small class="mr-2">question_mark</v-icon>
                    <span style="text-transform: none !important;">&nbsp;Forgot password</span>
                  </v-btn>
                </div>
                <div>
                  <v-btn
                      class="my-0"
                      small
                      flat
                      round
                      :color="rememberMe ? 'secondary' : 'null'"
                      @click="toggleRememberMe"
                  >
                    <transition
                        mode="out-in"
                        enter-active-class="scale-in-center"
                        leave-active-class="scale-out-center"
                    >
                      <v-icon :key="rememberMe ? '1' : '2'" small class="mr-2">
                        {{ !rememberMe ? 'check_box_outline_blank' : 'check_box' }}
                      </v-icon>
                    </transition>
                    <span style="text-transform: none !important;">&nbsp;Remember me</span>
                  </v-btn>
                </div>
              </div>
              
              <v-btn :loading="loginPending" @click="loginStart" color="secondary" class="mr-2">
                <span>Login</span>
                <!--          <v-icon right>navigate_next</v-icon>-->
              </v-btn>
            </div>
            
            <div class="c-d-flex c-align-center justify-space-around pt-2">
              <v-btn :loading="loginPending" @click="googleSSOLogin" flat small>
                
                <img src="../../public/google_g.svg" class="mr-2" style="width: 18px">
                
                <span style="text-transform: none !important;">Login with Google</span>
                <!--          <v-icon right>navigate_next</v-icon>-->
              </v-btn>
              
              <v-btn :loading="loginPending" @click="microsoftSSOLogin" flat small>
                
                <img src="../../public/microsoft_window.svg" class="mr-2" style="width: 18px">
                
                <span style="text-transform: none !important;">Login with Microsoft</span>
                <!--          <v-icon right>navigate_next</v-icon>-->
              </v-btn>
            </div>
          
          </v-form>
        </v-card-text>
        
        <v-card-actions class="c-d-flex c-justify-center mt-0 pt-0 mb-3">
          <div class="g-recaptcha c-d-flex c-justify-center"
               data-sitekey="6LfXXZApAAAAAC9L3mhu4PXa3kgrAPmvN_qBmFIF"
               data-size="invisible"
               data-callback="captchaExec"
               data-expired-callback="captchaExpired"
               data-error-callback="captchaExpired"
               data-badge="inline"
               :data-theme="$store.state.darkTheme ? 'dark' : 'light'"
          >
          </div>
        </v-card-actions>
      </template>
      <template v-else>
        <v-card-text>
          
          <div class=caption>
            Confirm MFA Pin
          </div>
          
          <v-form ref="tfaLogin">
            <v-text-field
                color="secondary"
                single-line
                v-model="mfaPin"
                label="MFA Pin"
                placeholder="MFA Pin"
                class="py-2 pr-3"
                hide-details
                @keyup.enter="enterUserName"
                ref="otpEdit"
                @keydown.enter="!!mfaPin && mfaPin.length === 6 ? confirmMFA() : null"
                mask="###-###"
                :autofocus="true"
            >
              <template slot="prepend">
                <div class="pt-1">
                  <v-icon class="pa-0 ma-0" color="secondary">vpn_key</v-icon>
                </div>
              </template>
            </v-text-field>
            
            
            <div style="width: 100%" class="text-xs-center caption red--text py-2" v-if="loginMessage != null">
              {{ loginMessage }}
            </div>
            
            
            <div class="c-d-flex c-justify-space-between">
              
              <v-btn
                  small
                  flat
                  round
                  :color="rememberMe ? 'secondary' : 'null'"
                  @click="toggleRememberMe"
              >
                <transition
                    mode="out-in"
                    enter-active-class="scale-in-center"
                    leave-active-class="scale-out-center"
                >
                  <v-icon :key="rememberMe ? '1' : '2'" small class="mr-2">{{ !rememberMe ? 'check_box_outline_blank' : 'check_box' }}</v-icon>
                </transition>
                <span style="text-transform: none !important;">&nbsp;Remember me</span>
              </v-btn>
              
              <v-btn
                  :loading="confirmingMfa"
                  @click="confirmMFA"
                  :disabled="confirmingMfa || !mfaPin || (mfaPin ? mfaPin.length < 6 : false)"
                  color="secondary"
                  class="mr-2"
              >
                <span>Confirm Pin</span>
                <!--          <v-icon right>navigate_next</v-icon>-->
              </v-btn>
            </div>
          
          </v-form>
        </v-card-text>
        
        <v-card-actions class="c-d-flex c-justify-center mt-0 pt-0 mb-3">
          <div class="g-recaptcha c-d-flex c-justify-center"
               data-sitekey="6LfXXZApAAAAAC9L3mhu4PXa3kgrAPmvN_qBmFIF"
               data-size="invisible"
               data-callback="captchaExec"
               data-expired-callback="captchaExpired"
               data-error-callback="captchaExpired"
               data-badge="inline"
               :data-theme="$store.state.darkTheme ? 'dark' : 'light'"
          >
          </div>
        </v-card-actions>
        
      </template>
    </v-card>
    
    <div
        class="c-d-flex c-align-center c-justify-end"
        :class="{'mt-auto': sm,}"
        :style="{width: !sm ? '400px' : '100%'}"
    >
      <a href="/terms_of_use.html" target="_blank">
        <v-btn
            :flat="true"
            small
            color="grey-lighten-2"
        >
          <span style="text-transform: none; font-weight: 400">Terms</span>
        </v-btn>
      </a>
      
      <a href="/privacy_policy.html" target="_blank">
        <v-btn
            :flat="true"
            small
            color="grey-lighten-2"
        >
          <span style="text-transform: none; font-weight: 400">Privacy</span>
        </v-btn>
      </a>
    </div>
    
    <div
        :class="{
                    'mt-auto': !sm,
                    'justify-end text--white': !sm,
                    'justify-center': sm
                }"
        :style="{
                width: '100%',
                background:  '#00000030'
            }"
        style="padding: 5px 15px; z-index: 1"
        class="c-d-flex c-align-center subheading "
    >
      Powered by:&nbsp;<img :src="$store.state.darkTheme ? 'whiteFull.svg' : 'blackFull.svg'" width="130" style="padding-bottom: 2px">©&nbsp;{{ format(new Date(), "YYYY") }}
    </div>
    
    
    
    <a style="position: fixed; right: 0;"
       :style="{bottom: !sm ? '34px' : '72px'}"
       href="https://tl-app.tasklogix.co.za/#/?link=n40bul" target="_blank">
    <v-btn
        class="ma-3"
        fab
        color="secondary"
    >
      <v-icon>chat</v-icon>
      
    </v-btn>
    </a>
    
    <v-snackbar v-model="loginFail" bottom>
      <span>Invalid Login</span>
      <v-btn color="secondary" flat @click="loginFail = false">
        Close
      </v-btn>
    </v-snackbar>
    
    <two-factor
        :config="twoFactorConfig"
        v-if="!!twoFactorConfig"
        :getOS="getOS"
        :setCookie="setCookie"
        :remember-me="rememberMe"
        @OTPSuccess="OTPLogin($event)"
        @rememberMe="setRememberMe"
    />
    
    
  </div>
</template>

<script>

import TwoFactor from "@/views/twoFactor";

function getOS() {
  var userAgent = window.navigator.userAgent,
      platform = window.navigator.platform,
      macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
      windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
      iosPlatforms = ['iPhone', 'iPad', 'iPod'],
      os = null;
  
  if (macosPlatforms.indexOf(platform) !== -1) {
    os = 'Mac OS';
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = 'iOS';
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = 'Windows';
  } else if (/Android/.test(userAgent)) {
    os = 'Android';
  } else if (!os && /Linux/.test(platform)) {
    os = 'Linux';
  }
  
  return os;
}

function setCookie(cname, cvalue, exdays = null) {
  const d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  let expires = "expires=" + d.toUTCString();
  document.cookie = `${cname}=${cvalue};${expires};path=/;domain=${window.location.hostname};secure`;
  // document.cookie = cname + "=" + cvalue + ";";
}

function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

import {eventBus} from '../main';
import {codeBaseMixin} from '../codeBaseMixin';
import axios from 'axios';
import {format} from "date-fns";
import {dbGlobalMethods} from "@/localDBFunction";
import {serverAddress} from "@/axiosRequest";

let tokenMan = require('../tokenManSite');

export default {
  components: {TwoFactor},
  mixins: [codeBaseMixin],
  data() {
    return {
      userName: '',
      password: '',
      gmailTokenCode: '',
      officeTokenCode: '',
      inputRules: [
        v => !!v || 'This field is required',
        v => v.length >= 6 || 'Minimum length is 6 characters'
      ],
      loginPending: false,
      loginFail: false,
      showWaitOffice: false,
      showPassword: false,
      loginMessage: null,
      isMobile: false,
      twoFactorConfig: null,
      recaptchaCode: null,
      parentWidth: 700,
      rememberMe: false,
      twoFactorLoginConfig: null,
      mfaPin: null,
      confirmingMfa: false,
      
      forgotPassword: false,
      forgotPasswordUsername: '',
      sendingForgotPasswordLink: false,
      forgotPasswordFormValid: false,
      forgotPasswordDone: null,
      
      forgotPasswordEnterCode: false,
      
      resetPasswordToken: null,
      forgotPasswordResetPassword1: '',
      forgotPasswordResetPassword2: '',
      resetPWRules: {
        required: value => !!value || 'Required.',
        min: v => v.length >= 6 || 'Min 6 characters',
        lower: v => v != null && (/[a-z]/.test(v)) || 'At Least 1 Lower Case Character Is Required',
        upper: v => v != null && (/[A-Z]/.test(v)) || 'At Least 1 Upper Case Character Is Required',
        special: v => v != null && (/[$&+,:;=?@#|'<>.^*()%!-]/.test(v)) || 'At Least 1 Special Character Is Required',
      },
      settingNewPassword: false,
      
      captchaRan: false
    }
  },
  
  mounted() {
    this.onResize()
    window.addEventListener('resize', this.onResize, {passive: true})
    window.captchaExec = this.captchaExec;
    window.captchaExpired = this.captchaExpired;
    this.loadReCaptcha()
  },
  beforeDestroy() {
    if (typeof window === 'undefined') return
    window.removeEventListener('resize', this.onResize, {passive: true})
  },
  
  methods: {
    
    testCb(v) {
      console.log(v)
      
    },
    
    googleSSOLogin() {
      this.loginPending = true
      window.location.replace(`${serverAddress}/login/googleSSO/${encodeURIComponent(serverAddress)}`)
    },
    
    microsoftSSOLogin() {
      this.loginPending = true
      window.location.replace(`${serverAddress}/login/microsoftSSO/${encodeURIComponent(serverAddress)}`)
    },
    
    async setNewPassword() {
      try {
        this.settingNewPassword = true
        
        if ('a' === 'b' && !this.recaptchaCode && process.env.NODE_ENV !== "development")
          return this.loginMessage = 'reCAPTCHA Required';
        
        this.loginMessage = null
        
        const res = await this.fetch({
          method: 'POST',
          url: `/login/resetPw`,
          body: JSON.stringify({
            token: this.resetPasswordToken,
            password: this.forgotPasswordResetPassword1,
            recaptchaCode: this.recaptchaCode
          })
        }, false)
        
        if (res.status === 'failed')
          this.loginMessage = res.message || res.error || 'Error: Something went wrong'
        else {
          tokenMan.initialize({token: res.token, exp: new Date(res.exp)});
          this.loginMessage = null;
          eventBus.$emit('showHideNav', true);
          this.$emit('succesfullLogin', {
            gmailTokenCode: this.gmailTokenCode,
            officeTokenCode: this.officeTokenCode
          });
        }
        
      } catch (e) {
        console.log(e)
        this.loginMessage = e
      } finally {
        this.settingNewPassword = false
      }
    },
    
    samePassword(val) {
      return this.forgotPasswordResetPassword1 === this.forgotPasswordResetPassword2 || 'Passwords do not match'
    },
    
    async sendForgotPassword() {
      try {
        this.sendingForgotPasswordLink = true
        
        if (!this.userName)
          return this.loginMessage = 'reCAPTCHA Required';
        
        if ('a' === 'b' && !this.recaptchaCode && process.env.NODE_ENV !== "development")
          return this.loginMessage = 'reCAPTCHA Required';
        
        this.loginMessage = null
        
        const res = await this.fetch({
          method: 'POST',
          url: `/login/sendPwResetEmail`,
          body: JSON.stringify({username: this.userName, recaptchaCode: this.recaptchaCode})
        }, false)
        
        if (res.status === 'failed')
          this.loginMessage = res.message || res.error || 'Error: Something went wrong'
        else if (res.status === 'success')
          this.forgotPasswordDone = res.sentTo
        
      } catch (e) {
        console.log(e)
        this.loginMessage = e
      } finally {
        this.sendingForgotPasswordLink = false
      }
    },
    
    async confirmMFA() {
      this.confirmingMfa = true;
      try {
        const data = await this.fetch({method: 'POST', url: `/login/mfa`, body: JSON.stringify({mfaConfig: this.twoFactorLoginConfig, pin: this.mfaPin, os: getOS(), rememberMe: this.rememberMe})}, false)
        
        if (data.status === 'failed')
          this.loginMessage = data.message || data.error
        else {
          this.setCookie(data.username, data.deviceToken, 120)
          tokenMan.initialize({token: data.token.token, exp: new Date(data.token.exp)});
          this.loginMessage = null;
          eventBus.$emit('showHideNav', true);
          this.$emit('succesfullLogin', {
            gmailTokenCode: this.gmailTokenCode,
            officeTokenCode: this.officeTokenCode
          });
        }
        this.confirmingMfa = false;
      } catch (e) {
        console.log(e)
        this.confirmingMfa = false;
        return this.loginMessage = e
      } finally {
        this.confirmingMfa = false;
      }
    },
    
    toggleRememberMe() {
      this.rememberMe = !this.rememberMe
      this.removeRememberMe()
    },
    
    async removeRememberMe() {
      return await dbGlobalMethods.addConfigValue('autoLogin', false)
    },
    
    async setRememberMe(val) {
      return await dbGlobalMethods.addConfigValue('autoLogin', val)
    },
    
    
    format,
    
    executeHere() {
      grecaptcha.execute();
    },
    
    loadReCaptcha() {
      // window.onloadCallback = this.captchaLoaded;
      const script = document.createElement('script');
      script.src = "https://www.google.com/recaptcha/api.js";
      document.head.appendChild(script);
    },
    
    loginStart() {
      this.loginPending = true
      if ('a' === 'b' && process.env.NODE_ENV !== "development")
      {
        if (this.captchaRan)
          grecaptcha.reset();
        grecaptcha.execute();
      } else {
        if (this.resetPasswordToken)
          this.setNewPassword()
        else if (this.forgotPassword)
          this.sendForgotPassword()
        else
          this.login()
      }
    },
    
    captchaExpired(data) {
      console.log(data)
      this.recaptchaCode = null
      this.loginPending = false
    },
    
    captchaExec(data) {
      
      this.captchaRan = true
      
      this.recaptchaCode = data
      

      
      if (this.resetPasswordToken) {
        console.log('TEST123')
        this.setNewPassword()
      }
      else if (this.forgotPassword)
        this.sendForgotPassword()
      else
        this.login()
    },
    
    
    getOS: getOS,
    setCookie: setCookie,
    
    onResize() {
      this.parentWidth = window.innerWidth
      this.isMobile = window.innerWidth < 900
    },
    
    enterUserName() {
      if (this.password == null || this.password === '') {
        this.$refs.passwordField.focus();
      } else {
        this.loginStart();
      }
    },
    
    OTPLogin(token) {
      this.twoFactorConfig = null;
      tokenMan.initialize({token: token.token, exp: new Date(token.exp)});
      this.loginMessage = null;
      eventBus.$emit('showHideNav', true);
      this.$emit('succesfullLogin', {gmailTokenCode: this.gmailTokenCode, officeTokenCode: this.officeTokenCode});
      this.loginPending = false;
    },
    
    login() {
      
      if (!this.userName)
        return this.loginMessage = 'Username Required';
      
      if (!this.password)
        return this.loginMessage = 'Password Required';
      
      if ('a' === 'b' && !this.recaptchaCode && process.env.NODE_ENV !== "development")
        return this.loginMessage = 'reCAPTCHA Required';
      
      // console.log(getOS())
      // console.log('Existing', getCookie(this.username))
      // setCookie(this.username, 'WARA WARA WARA')
      
      this.loginPending = true;
      if (this.$refs.login.validate()) {
        try {
          this.fetch({
            method: 'POST',
            url: `/login`,
            body: JSON.stringify({
              user: this.userName,
              password: this.password,
              deviceID: getCookie(this.userName.toLowerCase()),
              recaptchaCode: this.recaptchaCode,
              os: getOS(),
              rememberMe: this.rememberMe
            })
          }, false)
              .then(async (data) => {
                
                console.log({data})
                
                switch(data.status) {
                  case 'success': {
                    
                    console.log({data})
                    
                    if (data.rememberMe) {
                      await this.setRememberMe(data.rememberMe)
                    }

                    tokenMan.initialize({token: data.token, exp: new Date(data.exp)});
                    
                    this.loginMessage = null;
                    eventBus.$emit('showHideNav', true);
                    this.$emit('succesfullLogin', {
                      gmailTokenCode: this.gmailTokenCode,
                      officeTokenCode: this.officeTokenCode
                    });
                    break;
                  }
                  case 'failed': {
                    console.log(data)
                    this.loginMessage = data.message || data.error
                    break;
                  }
                  case 'newTwoFactor': {
                    this.twoFactorConfig = data.message
                    break;
                  }
                  case 'twoFactorLogin': {
                    this.twoFactorLoginConfig = data.token
                    break;
                  }
                }
                
                
                // switch (parseInt(data.status)) {
                //   case -1: {
                //     this.twoFactorConfig = data.message
                //     break;
                //   }
                //   case 0: {
                //
                //
                //   }
                //   case 1: {
                //     this.loginMessage = 'Malformed Request';
                //     break;
                //   }
                //   case 2: {
                //     this.loginMessage = 'User Not Found';
                //     break;
                //   }
                //   case 3: {
                //     this.loginMessage = 'Invalid Username/Password Combination';
                //     break;
                //   }
                //   case 4: {
                //     this.loginMessage = 'Token Error';
                //     break;
                //   }
                //   case 5: {
                //     this.loginMessage = data.message;
                //     break;
                //   }
                //
                // }
                
                this.loginPending = false;
              })
              .catch((e) => {
                // eslint-disable-next-line
                console.log(e)
                this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
                this.loginPending = false;
              }).finally(() => {
            this.loginPending = false;
          })
        } catch (e) {
          console.log(e)
          return this.loginMessage = e
        }
      }
    },
    
    loginRememberMe() {
      
      
      
      // this.loginPending = true;
      // if (this.$refs.login.validate()) {
      //   try {
      //     this.fetch({
      //       method: 'POST',
      //       url: `/login/auto`,
      //       body: JSON.stringify({
      //         user: this.userName,
      //         password: this.password,
      //         deviceID: getCookie(this.userName.toLowerCase()),
      //         recaptchaCode: this.recaptchaCode,
      //         os: getOS()
      //       })
      //     }, false)
      //         .then((data) => {
      //           switch (parseInt(data.status)) {
      //             case -1: {
      //               this.twoFactorConfig = data.message
      //               break;
      //             }
      //             case 0: {
      //               tokenMan.initialize(data.message);
      //               this.loginMessage = null;
      //               eventBus.$emit('showHideNav', true);
      //               this.$emit('succesfullLogin', {
      //                 gmailTokenCode: this.gmailTokenCode,
      //                 officeTokenCode: this.officeTokenCode
      //               });
      //               break;
      //             }
      //             case 1: {
      //               this.loginMessage = 'Malformed Request';
      //               break;
      //             }
      //             case 2: {
      //               this.loginMessage = 'User Not Found';
      //               break;
      //             }
      //             case 3: {
      //               this.loginMessage = 'Invalid Username/Password Combination';
      //               break;
      //             }
      //             case 4: {
      //               this.loginMessage = 'Token Error';
      //               break;
      //             }
      //             case 5: {
      //               this.loginMessage = data.message;
      //               break;
      //             }
      //
      //           }
      //
      //           this.loginPending = false;
      //         })
      //         .catch((e) => {
      //           // eslint-disable-next-line
      //           console.log(e)
      //           this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
      //           this.loginPending = false;
      //         }).finally(() => {
      //       this.loginPending = false;
      //     })
      //   } catch (e) {
      //     console.log(e)
      //     return this.loginMessage = e
      //   }
      // }
    },
    
    getOfficeToken(userKey, token, officeCode) {
      this.showWaitOffice = true;
      
      axios.request({
        method: "get",
        url: `/microsoft/getToken/${officeCode}`,
        data: null,
        headers: {
          "tl-user-key": userKey,
          "tl-token": token
        }
      }).then(data => {
        tokenMan.initialize(data.data);
        this.loginMessage = null;
        eventBus.$emit('showHideNav', true);
        this.$emit('succesfullLogin', {gmailTokenCode: this.gmailTokenCode, officeTokenCode: this.officeTokenCode});
      })
          .catch(e => {
            console.log(e)
            this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
            this.showWaitOffice = false;
          })
      
      
      // this.fetch({method: 'GET', url:``})
      //     .then(() => {
      //         window.location.href = `${this.baseServerAddress}#/dashboard/${accessCode}`;
      //     })
      //     .catch((e) => {
      //         // eslint-disable-next-line
      //         console.log(e)
      //         this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
      //         this.showWaitOffice = false;
      //     })
      
    },
    
    async getOfficeSmtpToken(config) {
      try {
        this.showWaitOffice = true;
        const data = await this.$newReq('POST', 'oSmtpLink', config, false)
        // tokenMan.initialize(data.data);
        // this.loginMessage = null;
        // eventBus.$emit('showHideNav', true);
        // this.$emit('succesfullLogin', { gmailTokenCode: this.gmailTokenCode, officeTokenCode: this.officeTokenCode});
        console.log({data})
      } catch (e) {
        console.log(e)
      } finally {
        this.showWaitOffice = false
      }
    }
  },
  
  created() {
    eventBus.$emit('showHideNav', false);
    // let url =new URL(document.location.href);
    //
    // let state = url.searchParams.get("state");
    //
    // if (state == 'office') {
    //     this.officeTokenCode = url.searchParams.get('code')
    // } else {
    //     this.gmailTokenCode = url.searchParams.get("code");
    // }
    
    var urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('error')) {
      this.loginMessage = urlParams.get('error')
    } else if (urlParams.has('resetPwToken')) {
      this.resetPasswordToken = urlParams.get('resetPwToken')
      window.history.replaceState({}, document.title, window.location.pathname);
    } else if (urlParams.has('state') && urlParams.has('code')) {
      const state = urlParams.get('state')
      const code = urlParams.get('code')
      if (state.match(/osmtplink-/gi)) {
        this.getOfficeSmtpToken({state, code})
      } else {
        switch (urlParams.get('state').substring(0, 1)) {
          case 'o' : {
            //o-u-1-stage1%40tasklogix.co.za-t-5hLWfWVPLJAwkQGf2HtUYivwdqm2iQiZhXQ2XVSdCUzJWkX9UgfoqmtsdGJPb61CCDrMgWDMbm5uZJEjx5t1yJg5
            let state = urlParams.get('state')
            let sUserKey = state.substring(state.indexOf('-u-') + 3, state.indexOf('-t-'));
            let sToken = state.substring(state.indexOf('-t-') + 3, state.length);
            
            this.getOfficeToken(
                sUserKey, sToken, urlParams.get('code')
            );
            break;
          }
          case 'g' : {
            // Gmail Code Will Come Here
            break;
          }
        }
      }
      
    } else if (urlParams.has('code') || (urlParams.has('user_key') && urlParams.has('token') && urlParams.has('exp'))) {
      if (urlParams.has('code')) {
        let url = new URL(document.location.href);
        
        this.gmailTokenCode = url.searchParams.get("code");
      }
      
      if (urlParams.has('user_key') && urlParams.has('token') && urlParams.has('exp')) {
        
        const params = {
          userKey: urlParams.get('user_key'),
          token: urlParams.get('token'),
          exp: parseInt(urlParams.get('exp')),
        }
        history.pushState({}, '', window.location.pathname);
        
        tokenMan.initialize(params);
        this.loginMessage = null;
        eventBus.$emit('showHideNav', true);
        this.$emit('succesfullLogin', {gmailTokenCode: this.gmailTokenCode, officeTokenCode: this.officeTokenCode});
      }
    } else if (urlParams.has('token') && urlParams.has('exp')) {
      
      console.log('HAS THE GOODS')
      
      const params = {
        token: urlParams.get('token'),
        exp: new Date(urlParams.get('exp')),
      }
      
      history.pushState({}, '', window.location.pathname);
      
      tokenMan.initialize(params);
      this.loginMessage = null;
      eventBus.$emit('showHideNav', true);
      this.$emit('succesfullLogin', {gmailTokenCode: this.gmailTokenCode, officeTokenCode: this.officeTokenCode});
    } else {
      dbGlobalMethods.getConfigValue('autoLogin')
          .then(async (autoLogin) => {
            
            this.rememberMe = !!autoLogin.val
            
            if (this.$store.state.autoLogin) {
              if (autoLogin.val) {
                // Attempt Remember Me
                const v = await this.fetch({method: 'GET', url: `/login/auto/${autoLogin.val}`}, false, true)
                
                console.log({v})
                if (v.token) {
                  tokenMan.initialize({token: v.token, exp: new Date(v.exp) });
                  this.loginMessage = null;
                  eventBus.$emit('showHideNav', true);
                  this.$emit('succesfullLogin', {
                    gmailTokenCode: this.gmailTokenCode,
                    officeTokenCode: this.officeTokenCode
                  });
                }
              }
            }
          })
          .catch(e => {
            console.log(e)
            this.removeRememberMe(false)
            this.$store.state.autoLogin = false
            this.rememberMe = false
          })
    }
  },
  
  computed: {
    sm() {
      return this.parentWidth <= 700
    }
  }
  
}
</script>

<style scoped>
.loginBackRight {
  background-image: url('../../public/lineBack.svg');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
}

.loginBackWhiteArrow {
  background-image: url('../../public/lineBackWhite.svg');
  background-repeat: no-repeat;
  background-size: cover;
}

.login-back {
  animation: bg-pan-left 16s infinite;
  background-size: 150% 100%;
}
@keyframes bg-pan-left {
  0% {
    background-position: 100% 50%;
  }
  50% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}

</style>
