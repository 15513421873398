<template>
    <v-layout column fill-height style="overflow-y: auto" class="px-1">
        <v-flex v-if="workflowsLoaded == false" shrink style="height: 50px">
            <v-progress-circular
                    :size="40"
                    :width="5"
                    color="primary"
                    indeterminate
            ></v-progress-circular>
        </v-flex>

        <template v-else>
            <v-flex shrink>
                <v-layout row align-center>
                    <v-flex xs12>
                        <v-text-field
                                label="Search Workflows"
                                solo
                                clearable
                                prepend-icon="search"
                                color="primary"
                                flat
                                :hide-details="true"
                                v-model="search"
                                class="pa-0 ma-0"
                                ref="myField"
                        ></v-text-field>
                    </v-flex>
                    <v-flex shrink>
                        <v-tooltip top>
                            <v-btn
                                    small
                                    icon
                                    flat
                                    color="secondary"
                                    slot="activator"
                                    class="pa-0 ma-0"
                                    @click="showAddWorkspace = true"
                            >
                                <v-icon>add</v-icon>
                            </v-btn>
                            <span>Add New Workspace</span>
                        </v-tooltip>
                        <app-input-dlg
                                :show-dialog="showAddWorkspace"
                                title="Add Workspace"
                                body="Please provide a Description for the New Workspace:"
                                :cancel-button="true"
                                :show-input-box="true"
                                :buttons="['Add Workspace']"
                                :call-backs="[ addWorkspace ]"
                                @dismiss="showAddWorkspace = false"
                        />
                    </v-flex>
                </v-layout>

            </v-flex>

            <v-flex xs12 style="overflow-y: auto;" class="scroll">
<!--Workspaces-->
                    <template v-for="workspace in filteredWorkflows.workspaces">
                        <v-layout
                                row
                                align-center
                                :key="'WS' + workspace.id"
                                wrap
                                v-if="workspace.shown"
                        >
                            <v-flex xs12
                                    @dragover="dragOver($event, 'wfg')"
                                    @drop="drop($event, 'workspaceDrop', workspace.id)"
                                    class="droparea"
                            >
                                <v-layout row align-center class="select-item">
                                    <v-flex @click="workspace.dropped = !workspace.dropped" shrink style="cursor: pointer;">
                                        <v-btn small flat icon color="primary" class="pa-0 ma-0">
                                            <v-icon>
                                                {{ workspace.dropped ? 'arrow_drop_up' : 'arrow_drop_down' }}
                                            </v-icon>
                                        </v-btn>
                                    </v-flex>
                                    <v-flex
                                            style="cursor: pointer;"
                                            @click="workspace.dropped = !workspace.dropped"
                                            xs12
                                            class="text-xs-left body-1 font-weight-light select-item"
                                    >
                                        <v-layout row align-center ripple style="cursor: pointer" class="hide-parent">
                                            <v-flex xs12>
                                                {{ workspace.displayName }}
                                            </v-flex>
                                            <v-flex shrink class="pl-1 hide-child">
                                                <v-tooltip top>
                                                    <v-icon @click="renameWorkspaceObject = workspace; workspaceRename = true"
                                                            slot="activator" color="primary">edit
                                                    </v-icon>
                                                    <span>Edit Workspace Name</span>
                                                </v-tooltip>
                                            </v-flex>
                                        </v-layout>
                                    </v-flex>
                                    <v-flex shrink>
                                        <app-add-workflow-group
                                                :work-space="workspace"
                                                @addwfgroup="addWorkflowGroupToWorkspace($event, workspace)"
                                        />
                                    </v-flex>
                                </v-layout>

                            </v-flex>

<!--WorkflowGroups-->
                            <v-flex xs11 offset-xs1>
                                <template v-if="workspace.dropped">
                                    <template v-for="workflowGroup in filteredWorkflows.workflowGroups">
                                        <template v-if="workflowGroup.workspaceID == workspace.id && workflowGroup.shown">

                                                <v-layout
                                                        row
                                                        align-center
                                                        wrap
                                                >

                                                    <v-flex xs12 class="select-item">
                                                        <div
                                                                @dragstart="setDragData($event, 'wfg', workflowGroup.id)"
                                                                :draggable="true"
                                                                :key="'WG' + workflowGroup.id"
                                                                @dragover="dragOver($event, 'wf')"
                                                                @drop="drop($event, 'workflowGroupDrop', workflowGroup.id)"
                                                        >

                                                        <v-layout row align-center>
                                                            <v-flex @click="workflowGroup.dropped = !workflowGroup.dropped" shrink style="cursor: pointer;">
                                                                <v-btn small flat icon color="primary" class="pa-0 ma-0">
                                                                    <v-icon>
                                                                        {{ workflowGroup.dropped ? 'arrow_drop_up' : 'arrow_drop_down' }}
                                                                    </v-icon>
                                                                </v-btn>
                                                            </v-flex>
                                                            <v-flex
                                                                    style="cursor: pointer;"
                                                                    @click="workflowGroup.dropped = !workflowGroup.dropped"
                                                                    xs12
                                                                    class="text-xs-left body-1 font-weight-light"
                                                            >
                                                                <v-layout row align-center ripple style="cursor: pointer" class="hide-parent">
                                                                    <v-flex xs12>
                                                                        {{ workflowGroup.displayName }}
                                                                    </v-flex>
                                                                    <v-flex shrink class="pl-1 hide-child">
                                                                        <v-tooltip top>
                                                                            <v-icon @click="renameWorkFlowGroupObject = workflowGroup; workflowGroupRename = true" slot="activator" color="primary">edit</v-icon>
                                                                            <span>Edit Workflow Group Name</span>
                                                                        </v-tooltip>
                                                                    </v-flex>
                                                                </v-layout>
                                                            </v-flex>
                                                            <v-flex shrink>
                                                                <app-add-workflow
                                                                        :workflow-group="workflowGroup"
                                                                        @addwf="addWorkflowWorkflowGroup($event, workflowGroup)"
                                                                />
                                                            </v-flex>
                                                        </v-layout>
                                                        </div>
                                                    </v-flex>
<!--Workflows-->
                                                    <v-flex xs11 offset-xs1>
                                                        <template v-if="workflowGroup.dropped">
                                                            <template v-for="workflow in filteredWorkflows.workflows">
                                                                <template v-if="workflow.workflowGroupID == workflowGroup.id && workflow.shown">
                                                                    <div
                                                                            @dragstart="setDragData($event, 'wf', workflow.id)"
                                                                            :draggable="true"
                                                                            :key="'W' + workflow.id"
                                                                    >
                                                                        <v-layout row align-center
                                                                                  class="hide-parent">

                                                                            <v-flex @click="selectWorkflow(workflow)"
                                                                                    shrink style="cursor: pointer;">
                                                                                <v-btn small flat icon color="primary"
                                                                                       class="pa-0 ma-0">
                                                                                    <v-icon small>
                                                                                        {{ workflow.selected ?
                                                                                        'check_box' :
                                                                                        'check_box_outline_blank' }}
                                                                                    </v-icon>
                                                                                </v-btn>
                                                                            </v-flex>
                                                                            <v-flex
                                                                                    style="cursor: pointer;"
                                                                                    @click="selectWorkflow(workflow)"
                                                                                    xs11
                                                                                    class="text-xs-left body-1 font-weight-light select-item"
                                                                            >
                                                                                {{ workflow.displayName }}
                                                                            </v-flex>
                                                                            <v-flex shrink class="pr-1 hide-child">
                                                                                <v-tooltip top>
                                                                                    <v-icon @click="renameWorkFlowObject = workflow; workflowRename = true"
                                                                                            slot="activator"
                                                                                            color="primary">edit
                                                                                    </v-icon>
                                                                                    <span>Edit Workflow Name</span>
                                                                                </v-tooltip>
                                                                            </v-flex>
                                                                        </v-layout>
                                                                    </div>
                                                                </template>
                                                            </template>
                                                        </template>
                                                    </v-flex>
                                                </v-layout>
                                        </template>
                                    </template>
                                </template>
                            </v-flex>
                        </v-layout>
                    </template>
            </v-flex>
        </template>

        <app-input-dlg
                :show-dialog="workspaceRename"
                title="Rename Workspace"
                body="Please provide a New Workspace Title:"
                :cancel-button="true"
                :show-input-box="true"
                :buttons="['Rename']"
                :call-backs="[ updateWorkspaceName ]"
                @dismiss="workspaceRename = false"
                :default-input="renameWorkspaceObject != null && typeof renameWorkspaceObject.displayName != 'undefined' ? renameWorkspaceObject.displayName : null"
        />

        <app-input-dlg
                :show-dialog="workflowGroupRename"
                title="Rename Workflow Group"
                body="Please provide a New Workflow Group Title:"
                :cancel-button="true"
                :show-input-box="true"
                :buttons="['Rename']"
                :call-backs="[ updateWorkflowGroup ]"
                @dismiss="workflowGroupRename = false"
                :default-input="renameWorkFlowGroupObject != null && typeof renameWorkFlowGroupObject.displayName != 'undefined' ? renameWorkFlowGroupObject.displayName : null"
        />

        <app-input-dlg
                :show-dialog="workflowRename"
                title="Rename Workflow"
                body="Please provide a New Workflow Title:"
                :cancel-button="true"
                :show-input-box="true"
                :buttons="['Rename']"
                :call-backs="[ updateWorkflow ]"
                @dismiss="workflowRename = false"
                :default-input="renameWorkFlowObject != null && typeof renameWorkFlowObject.displayName != 'undefined' ? renameWorkFlowObject.displayName : null"
        />
    </v-layout>
</template>

<script>

   import {codeBaseMixin} from "../../../../codeBaseMixin";
   import InputDlg from '../../../../components/General/InputDlg';
   import AddWorkflowGroup from './Components/AddWorkflowGroup';
   import AddWorkflow from './Components/AddWorkflow'

   export default {
       mixins: [codeBaseMixin],
       components: {
           appInputDlg: InputDlg,
           appAddWorkflowGroup: AddWorkflowGroup,
           appAddWorkflow: AddWorkflow
       },
       props: {
           value: Object,
           workflowsLoaded: Boolean,
           workflows: Object,
           userInfo: Object,
           admin: {
               type: Boolean,
               default: false
           }
       },
       data() {
           return {
               search: '',
               maxWidth: 0,
               showAddWorkspace: false,
               workspaceRename: false,
               renameWorkspaceObject: null,
               renameWorkFlowGroupObject: null,
               workflowGroupRename: false,
               renameWorkFlowObject: null,
               workflowRename: false,
               dragType: null,
               dragVal: null
           }
       },
       methods: {
           setDragData(event, type, value) {
               this.dragType = type;
               this.dragVal = value;
               event.dataTransfer.setData('text', `${value}`);
           },

           dragOver(event, type) {
               try {
                   if (this.dragType === type) {
                       event.preventDefault();
                   }
               } catch (e) {
                   // eslint-disable-next-line
                   console.log(e)
               }
           },

           drop(event, dropType, dropValue) {
               let sData = event.dataTransfer.getData("text");
               // Move Workspace Group To New Workspace
               if (dropType === 'workspaceDrop' && this.dragType === 'wfg' && this.dragVal == sData) {
                   this.fetch({
                       method: 'PATCH',
                       url: `/workflows/updateWorkflowGroupWorkspace/${this.userInfo.entityID}/${this.dragVal}/${dropValue}`,
                   })
                       .then(() => {
                           this.showSnack('Info', 'Workspace Group Moved', 'Close', 'primary');
                           this.workflows.workflowGroups.forEach(wsp => {
                               if (wsp.id === this.dragVal) {
                                   wsp.workspaceID = dropValue;
                               }
                           })
                       })
                       .catch((e) => {
                           // eslint-disable-next-line
                           console.log(e)
                           this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                       })
               }
               // Move Workflow To New Workspace Group
               if (dropType === 'workflowGroupDrop' && this.dragType === 'wf' && this.dragVal == sData) {
                   this.fetch({
                       method: 'PATCH',
                       url: `/workflows/updateWorkflowWorkflowGroup/${this.userInfo.entityID}/${this.dragVal}/${dropValue}`,
                   })
                       .then(() => {
                           this.showSnack('Info', 'Workflow Moved', 'Close', 'primary');
                           this.workflows.workflows.forEach(wf => {
                               if (wf.id === this.dragVal) {
                                   wf.workflowGroupID = dropValue;
                               }
                           })
                       })
                       .catch((e) => {
                           // eslint-disable-next-line
                           console.log(e)
                           this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                       })
               }

           },

           updateWorkflow(newName) {

               this.workflowRename = false;
               if (!this.blankString(newName)) {
                   this.fetch({
                       method: 'PATCH',
                       url: `/workflows/rename/workflow/${this.userInfo.entityID}/${this.renameWorkFlowObject.id}`,
                       body: JSON.stringify({description: newName})
                   })
                       .then((data) => {
                           this.showSnack('Info', 'Changes Saved', 'Close', 'primary');
                           this.renameWorkFlowObject.displayName = newName;
                           this.$emit('workflowRename', {id: this.renameWorkFlowObject.id, description: newName})
                           this.renameWorkFlowObject = null;
                           this.$forceUpdate();
                       })
                       .catch((e) => {
                           // eslint-disable-next-line
                           console.log(e)
                           this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                       })
               } else {
                   this.showSnack('Error', 'A Workflow Group Title Is Required', 'Close', 'red')
               }
           },

           updateWorkflowGroup(newName) {


               if (newName != null && newName != '') {
                   this.fetch({
                       method: 'PATCH',
                       url: `/workflows/rename/workflowgroup/${this.userInfo.entityID}/${this.renameWorkFlowGroupObject.id}/${this.renameWorkFlowGroupObject.workspaceID}`,
                       body: JSON.stringify({description: newName})
                   })
                       .then((data) => {
                           this.showSnack('Info', 'Changes Saved', 'Close', 'primary');
                           this.renameWorkFlowGroupObject.displayName = newName;
                           this.renameWorkFlowGroupObject = null;
                           this.$forceUpdate();
                       })
                       .catch((e) => {
                           // eslint-disable-next-line
                           console.log(e)
                           this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                       })
                   this.workflowGroupRename = false;
               } else {
                   this.showSnack('Error', 'A Workflow Group Title Is Required', 'Close', 'red')
               }
           },

           updateWorkspaceName(newName) {

               if (newName != null && newName != '') {
                   this.fetch({
                       method: 'PATCH',
                       url: `/workflows/rename/workspace/${this.userInfo.entityID}/${this.renameWorkspaceObject.id}`,
                       body: JSON.stringify({description: newName})
                   })
                       .then((data) => {
                           this.showSnack('Info', 'Changes Saved', 'Close', 'primary');
                           this.renameWorkspaceObject.displayName = newName;
                           this.renameWorkspaceObject = null;
                           this.$forceUpdate();
                       })
                       .catch((e) => {
                           // eslint-disable-next-line
                           console.log(e)
                           this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                       })
                   this.workspaceRename = false;
               } else {
                   this.showSnack('Error', 'A Workspace Title Is Required', 'Close', 'red')
               }

           },

           addWorkflowWorkflowGroup(data, workflowGroup){
               // {workspaceID: this.workSpace.id, description: groupDescription}
               this.fetch({
                   method: 'POST',
                   url: '/workflows/addWorkflow/' + this.userInfo.entityID,
                   body: JSON.stringify({description: data.description, workflowGroupID: workflowGroup.id})
               })
                   .then((data) => {
                       let tItem = data;
                       tItem.shown = true;
                       this.workflows.workflows.push(tItem);
                       this.$forceUpdate();
                       this.selectWorkflow(tItem);
                   })
                   .catch((e) => {
                       // eslint-disable-next-line
                       console.log(e)
                       this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                   })
           },

           addWorkflowGroupToWorkspace(data, workspace){
               workspace.dropped = true;
               // {workspaceID: this.workSpace.id, description: groupDescription}
                   this.fetch({
                       method: 'post',
                       url: '/workflows/addWorkflowGroup/' + this.userInfo.entityID,
                       body: JSON.stringify({description: data.description, workspaceID: workspace.id})
                   })
                       .then((data) => {
                           let tItem = data;
                           tItem.shown = true;
                           tItem.dropped = true;
                           this.workflows.workflowGroups.push(tItem);
                           this.$forceUpdate()
                       })
                       .catch((e) => {
                           // eslint-disable-next-line
                           console.log(e)
                           this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                       })
           },

           addWorkspace(workspaceName) {
               if (workspaceName != null && workspaceName != '') {
                   this.fetch({method: 'post', url: '/workflows/addWorkspace/' + this.userInfo.entityID, body: JSON.stringify({description: workspaceName})})
                       .then((data) => {
                           let tItem = data;
                           tItem.shown = true;
                           tItem.dropped = true;
                           this.workflows.workspaces.push(tItem);
                           this.showAddWorkspace = false;
                           this.$forceUpdate()
                       })
                       .catch((e) => {
                           // eslint-disable-next-line
                           console.log(e)
                           this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                       })
               } else {
                   this.showSnack('Error', 'A Workspace Description Is Required', 'Close', 'red')
               }
           },

           selectWorkflow(workflow) {
               workflow.selected = !workflow.selected;

               this.workflows.workflows.forEach((workflowLoop) => {
                   workflowLoop.selected = workflowLoop.id == workflow.id;
               });

               this.$emit('input', {
                   description: workflow.displayName,
                   id: workflow.id
               })
           },


       },
       computed: {
           filteredWorkflows() {

               let filterFlows = this.workflows;

               if (this.search != null) {
                   if (this.search != '') {
                       filterFlows.workspaces.forEach((workspace) => {workspace.shown = false});
                       filterFlows.workflowGroups.forEach((workflowGroup) => {workflowGroup.shown = false});

                       // Loop Through All Children First

                       filterFlows.workflows.forEach((workflow) => {
                           workflow.shown = workflow.displayName.toLowerCase().includes(this.search.toLowerCase());

                           if (workflow.shown) {
                               filterFlows.workflowGroups.forEach((workflowGroup) => {
                                   if (workflowGroup.id == workflow.workflowGroupID) {
                                       workflowGroup.shown = true;
                                       workflowGroup.dropped = true;
                                       filterFlows.workspaces.forEach((workspace) => {
                                           if (workspace.id == workflowGroup.workspaceID) {
                                               workspace.shown = true;
                                               workspace.dropped = true;
                                           }
                                       });
                                   }
                               });
                           }
                       });
                   } else {
                       filterFlows.workspaces.forEach((workspace) => {workspace.shown = true; workspace.dropped = false});
                       filterFlows.workflowGroups.forEach((workflowGroup) => {workflowGroup.shown = true; workflowGroup.dropped = false});
                       filterFlows.workflows.forEach((workflow) => {
                           workflow.shown = true;
                           if (workflow.selected) {
                               filterFlows.workflowGroups.forEach((workflowGroup) => {
                                   if (workflowGroup.id == workflow.workflowGroupID) {
                                       workflowGroup.dropped = true;
                                       filterFlows.workspaces.forEach((workspace) => {
                                           if (workspace.id == workflowGroup.workspaceID) {
                                               workspace.dropped = true;
                                           }
                                       });
                                   }
                               });
                           }
                       })
                   }
               }  else {
                   filterFlows.workspaces.forEach((workspace) => {workspace.shown = true; workspace.dropped = false});
                   filterFlows.workflowGroups.forEach((workflowGroup) => {workflowGroup.shown = true; workflowGroup.dropped = false});
                   filterFlows.workflows.forEach((workflow) => {
                       workflow.shown = true;
                       if (workflow.selected) {
                           filterFlows.workflowGroups.forEach((workflowGroup) => {
                               if (workflowGroup.id == workflow.workflowGroupID) {
                                   workflowGroup.dropped = true;
                                   filterFlows.workspaces.forEach((workspace) => {
                                       if (workspace.id == workflowGroup.workspaceID) {
                                           workspace.dropped = true;
                                       }
                                   });
                               }
                           });
                       }
                   })
               }

               return filterFlows;
           }
       }
   }
</script>

<style scoped>

    .select-item:hover {
        color: var(--v-primary-base);
    }

    .droparea:-moz-drag-over {
        background: rgba(255,255,255,0.5);
    }

</style>
