<template>
  <div>
    <div
        v-for="fieldRef in fields"
        :key="fieldRef.field.id"
        class="pb-2"
        style="position: relative"
    >
      <transition
          mode="out-in"
          enter-active-class="scale-in-center"
          leave-active-class="scale-out-center"
      >
        <v-icon v-if="typeof updateValues[fieldRef.field.id] != 'undefined'" style="position: absolute; right: 0; top: 0" color="orange" small >circle</v-icon>
      </transition>
      <template v-if="fieldRef.field.id === -2">
        <app-email-address-edit
            :value="fieldRef.value"
            @input="updateValue(fieldRef.field.id, $event)"
        ></app-email-address-edit>
      </template>
      <template v-else-if="fieldRef.field.type === 1">
        <div class="c-d-flex c-align-center">
          <v-text-field
              :label="fieldRef.field.description"
              :value="fieldRef.value"
              @input="updateValue(fieldRef.field.id, $event)"
              hide-details
              :readonly="fieldRef.field.editType === 2"
          ></v-text-field>
          <v-btn @click="copyStuff(fieldRef.value)" v-if="fieldRef.field.id === -1" small class="pa-0 ma-0 ml-2 mt-3" color="primary" icon flat>
            <v-icon :size="22">content_copy</v-icon>
          </v-btn>
        </div>
      </template>
      <template v-else-if="fieldRef.field.type === 2">
        <start-date-picker
            :value="fieldRef.value"
            @input="updateValue(fieldRef.field.id, $event)"
            :date-only="fieldRef.field.lookup.dateOnly"
            :model-string="true"
            :disabled="fieldRef.field.editType === 2"
        >
          <template #activator>
            <v-text-field
                :label="fieldRef.field.description"
                :value="fieldRef.value"
                @input="updateValue(fieldRef.field.id, $event)"
                hide-details
                readonly
                append-outer-icon="calendar_today"
                color="primary"
            ></v-text-field>
          </template>
        </start-date-picker>
      </template>
      <template v-else-if="fieldRef.field.type === 3">
        <v-text-field
            :label="fieldRef.field.description"
            :value="fieldRef.value"
            @input="updateValue(fieldRef.field.id, $event)"
            hide-details
            type="number"
            :prefix="fieldRef.field.lookup.currency ? 'R' : ''"
            :readonly="fieldRef.field.editType === 2"
        ></v-text-field>
      </template>
      <template v-else-if="fieldRef.field.type === 4">
        <v-autocomplete
            :label="fieldRef.field.description"
            :items="fieldRef.field.selectListValues.concat(fieldRef.value).filter((val, i, arr) => !!val && arr.indexOf(val) === i).sort()"
            :value="fieldRef.value"
            @input="updateValue(fieldRef.field.id, $event)"
            hide-details
            :prefix="fieldRef.field.lookup.currency ? 'R' : ''"
            :readonly="fieldRef.field.editType === 2"
        ></v-autocomplete>
      </template>
      <template v-else-if="fieldRef.field.type === 5">
        <app-contact-document-field
            :field="fieldRef.field"
            :value="fieldRef.value"
            @input="updateValue(fieldRef.field.id, $event)"
        ></app-contact-document-field>
      </template>
    </div>
    
    
    <div class="c-d-flex c-align-center c-justify-end">
      <v-btn
          flat
          depressed
          class="mx-1"
          @click="init"
          :disabled="savingChanges"
          small
      >
        Cancel
      </v-btn>
      
      <v-btn
          color="secondary"
          class="mr-0"
          @click="saveChanges"
          :disabled="Object.keys(updateValues).length === 0 || savingChanges"
          :loading="savingChanges"
          small
      >
        Save Changes
      </v-btn>
      
    </div>
    
  </div>

</template>

<script>
import AppContactsCustomFields from "@/contacts/AppContactsCustomFields.vue"
import draggable from "vuedraggable";
import {mapGetters} from "vuex";
import {arrSplice, copyText} from "@/codeFunctions";
import StartDatePicker from "@/components/General/Calendar/StartDatePicker/StartDatePicker.vue";
import AppContactDocumentField from "@/contacts/AppContactDocumentField.vue";
import AppEmailAddressEdit from "@/contacts/AppEmailAddressEdit.vue";

export default {
  components: {
    AppEmailAddressEdit,
    AppContactDocumentField,
    StartDatePicker
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    customFieldRef: {
      type: Map,
      required: true
    }
  },
  data() {
    return {
      editContact: null,
      savingChanges: false,
      loadingHistory: false,
      updateValues: {}
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.init()
      },
      deep: true
    },
  },
  methods: {
    
    copyStuff(str) {
      copyText(document, str);
      this.$snack.info(str + ' Copied')
    },
    
    getFileData(file) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = function () {
          let base64data = reader.result;
          try {
            resolve(base64data);
          } catch (error) {
            reject(error);
          }
        }
      })
    },
    
    
    async saveChanges() {
      try {
        this.savingChanges = true
        
        const body = {...this.updateValues, id: this.value.id}
        
        for (const key of Object.keys(body)) {
          if (body[key] instanceof File)
            body[key] = {
              fileUpload: true,
              size: body[key].size,
              filename: body[key].name,
              data: await this.getFileData(body[key])
            }
        }
        
        const updatedContact = await this.$newReq(
            'POST',
            `contacts/browse/saveChanges`,
            body
        )
        
        if (updatedContact.error)
          this.$snack.error(updatedContact.message)
        else {
          this.$emit('input', updatedContact)
          this.$snack.info("Changes Saved")
        }
        
      } catch (e) {
        console.log(e)
        this.$snack.networkError()
      } finally {
        this.savingChanges = false
      }
    },
    
    async init() {
      
      console.log("INIT CALLED")
      
      this.editContact = JSON.parse(JSON.stringify(this.value))
      this.updateValues = {}
    },
    
    updateValue(fieldId, value) {
      const existing = this.rawValues.get(fieldId)
      
      if (existing === value || value === null || value === '') {
        this.$delete(this.updateValues, fieldId.toString())
        // this.$set(this, "updateValues", this.updateValues)
        // console.log(this.updateValues)
      }
      else
        this.$set(this.updateValues, fieldId.toString(), value)
    }
    
    // async loadMore(clear = false) {
    //   try {
    //     this.loading = true
    //
    //     if (clear) {
    //       this.contacts = []
    //     }
    //
    //     const receivedContacts = await this.$newReq('POST', `contacts/browse/search`, {
    //       userId: this.userInfo.userID,
    //       searchStr: this.searchStr,
    //       skip: this.contacts.length || null,
    //       limit: null
    //     })
    //     this.contacts.push(...receivedContacts)
    //   } catch (e) {
    //     console.log(e)
    //     this.$snack.networkError()
    //   } finally {
    //     this.loading = false
    //   }
    // }
  },
  computed: {
    ...mapGetters('userInfo', ['userInfo']),
    
    rawValues() {
      return new Map([
        [-3, this.editContact.fullName],
        [-2, this.editContact.email],
        [-1,  this.editContact.mobile],
        ...[...this.customFieldRef.values()]
            .map((field) => [
              field.id,
              (this.editContact.customFieldData && this.editContact.customFieldData[field.id] ? this.editContact.customFieldData[field.id] : null)
            ])
      ])
    },
    
    fields() {
      const fieldRefs = [
        {
          field: {id: -3, description: "Full Name", visible: 1, type: 1, lookup: {}, order: -3, editType: 0, selectListValues: null},
          value: this.updateValues[-3] || this.editContact.fullName
        },
        {
          field: {id: -2, description: "Email", visible: 1, type: 1, lookup: {}, order: -2, editType: 0, selectListValues: null},
          value: this.updateValues[-2] || this.editContact.email
        },
        {
          field: {id: -1, description: "Mobile", visible: 1, type: 1, lookup: {}, order: -1, editType: 0, selectListValues: null},
          value: this.updateValues[-1] || this.editContact.mobile
        },
          ...[...this.customFieldRef.values()]
            .map((field) => (
                {
                  field,
                  value: this.updateValues[field.id] || (this.editContact.customFieldData && this.editContact.customFieldData[field.id] ? this.editContact.customFieldData[field.id] : null)
                }
            ))
      ]
          .filter(fieldRefs => fieldRefs.field.visible)
          .sort((a,b) => a.field.order > b.field.order ? 1 : -1)
      return fieldRefs
    },
    
  },
}
</script>

<style scoped>
.scale-in-center{-webkit-animation:scale-in-center .5s cubic-bezier(.25,.46,.45,.94) both;animation:scale-in-center .5s cubic-bezier(.25,.46,.45,.94) both}
@-webkit-keyframes scale-in-center{0%{-webkit-transform:scale(0);transform:scale(0);opacity:1}100%{-webkit-transform:scale(1);transform:scale(1);opacity:1}}@keyframes scale-in-center{0%{-webkit-transform:scale(0);transform:scale(0);opacity:1}100%{-webkit-transform:scale(1);transform:scale(1);opacity:1}}
.scale-out-center{-webkit-animation:scale-out-center .5s cubic-bezier(.55,.085,.68,.53) both;animation:scale-out-center .5s cubic-bezier(.55,.085,.68,.53) both}
@-webkit-keyframes scale-out-center{0%{-webkit-transform:scale(1);transform:scale(1);opacity:1}100%{-webkit-transform:scale(0);transform:scale(0);opacity:1}}@keyframes scale-out-center{0%{-webkit-transform:scale(1);transform:scale(1);opacity:1}100%{-webkit-transform:scale(0);transform:scale(0);opacity:1}}
</style>