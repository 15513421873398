const axios = require('axios');
const tokenMan = require('./tokenManSite')
const liveAPI = process.env.NODE_ENV !== "development"

const serverAddress = `${window.location.protocol}//${window.location.hostname}${getPortString()}`;
console.log("Server Address", serverAddress)

function getPortString() {
    if (+window.location.port) {
        if (liveAPI)
            return `${+window.location.port}`
        return ':4431'
    } else return '';
}

// Public Functions
function newReq(method, url, data = null, includeCredentials = true, fullResponse = false, fullError = false) {
    return new Promise((resolve, reject) => {
        let config = {method: method.toLowerCase(), url: `${serverAddress}/${url}`,}
        if (method.toLowerCase() !== 'get' && data !== null) {config.data = data}
        if (includeCredentials) {
            config.headers = {
                "tl-token": tokenMan.token()
            }
        }
        axios(config)
            .then(response => {
                if (response.status === 200) {
                    try {
                        response.data = JSON.parse(response.data)
                    } finally {
                        resolve(fullResponse ? response: response.data)
                    }
                } else {
                    try {
                        response.data = JSON.parse(response.data)
                    } finally {
                        reject(fullResponse ? response: response.data)
                    }
                }
            })
            .catch(e => {
                reject(e)
            })

    })
}

function newReqUpload(method, url, file = null, formFieldName = null, includeCredentials = true, onProgressCB = null, fullResponse = false) {
    return new Promise((resolve, reject) => {
        let formData = new FormData();
        formData.append(formFieldName, file);
        let config = {method: method.toLowerCase(), url: `${serverAddress}/${url}`, data: formData}
        if (onProgressCB != null)
            config.onUploadProgress = onProgressCB
        if (includeCredentials) {
            config.headers = {
                "tl-token": tokenMan.token(),
            }
        }
        axios(config)
            .then(response => {
                if (response.status === 200) {
                    try {
                        response.data = JSON.parse(response.data)
                    } finally {
                        resolve(fullResponse ? response: response.data)
                    }
                } else {
                    try {
                        response.data = JSON.parse(response.data)
                    } finally {
                        reject(fullResponse ? response: response.data)
                    }
                }
            })
            .catch(e => {
                reject(e)
            })
    })
}

function newReqDownloadFile(method, url, data = null, filename = null, downloadType = 'auto', includeCredentials = true, fullResponse = false, onProgressCB = null , cancelToken = null) {
    return new Promise((resolve, reject) => {
        let config = {method: method.toLowerCase(), url: `${serverAddress}/${url}`, responseType: 'blob'}
        if (method.toLowerCase() !== 'get' && data !== null) {config.data = data}
        if (includeCredentials) {
            config.headers = {
                "tl-token": tokenMan.token(),
            }
        }
        if (onProgressCB != null)
            config.onDownloadProgress = onProgressCB

        if (cancelToken) {
            config.cancelToken = cancelToken.token
        }
        axios(config)
            .then(response => {
                if (response.status === 200) {
                    if (downloadType === 'auto') {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.target = "_blank"
                        if (filename !== null)
                            link.setAttribute('download', filename); //or any other extension
                        document.body.appendChild(link);
                        link.click();


                        resolve(true)
                    } else {

                        if (downloadType === 'url') {
                            let file = new File([response.data], filename, {type: response.headers["content-type"]});
                            resolve(window.URL.createObjectURL(file))
                            // console.log(new URL(file))
                            // resolve(new URL(file))
                        }

                        if (downloadType === 'data') {
                            resolve(response.data)
                        }
                    }
                } else {
                    try {
                        response.data = JSON.parse(response.data)
                    } finally {
                        reject(fullResponse ? response: response.data)
                    }
                }
            })
            .catch(e => {
                reject(e)
            })
    })
}

function newReqUploadMultipleFormValues(method, url, values, includeCredentials = true, onProgressCB = null, fullResponse = false) {
    return new Promise((resolve, reject) => {
        let formData = new FormData();
        // formData.append(values[0].key, values[0].val);

        let i = 0;
        while (i < values.length) {
            formData.append(values[i].key, values[i].val);
            i = ++i
        }


        let config = {method: method.toLowerCase(), url: `${serverAddress}/${url}`, data: formData}
        if (onProgressCB != null)
            config.onUploadProgress = onProgressCB
        if (includeCredentials) {
            config.headers = {
                "tl-token": tokenMan.token(),
            }
        }
        axios(config)
            .then(response => {
                if (response.status === 200) {
                    try {
                        response.data = JSON.parse(response.data)
                    } finally {
                        resolve(fullResponse ? response: response.data)
                    }
                } else {
                    try {
                        response.data = JSON.parse(response.data)
                    } finally {
                        reject(fullResponse ? response: response.data)
                    }
                }
            })
            .catch(e => {
                reject(e)
            })
    })
}

module.exports = {
    newReq,
    newReqUpload,
    newReqDownloadFile,
    newReqUploadMultipleFormValues,
    liveAPI,
    serverAddress
}