<template>
    <v-dialog v-model="shown" width="700px" persistent>
        <v-card flat style="height: 70vh;" class="c-d-flex c-flex-column">
            <div class="c-d-flex c-align-center py-1 primary">
                <v-icon color="white" class="ml-2">event</v-icon>
                <div class="c-flex-grow-1 pa-1 pl-2 white--text">
                    Add Note to Event
                </div>
                <div class="px-1">
                    <v-btn
                            small
                            icon
                            flat
                            class="pa-0 ma-0"
                            color="white"
                            @click="$emit('dismiss', true)"
                    >
                        <v-icon small>close</v-icon>
                    </v-btn>
                </div>
            </div>
            <editor
                    v-if="shown"
                    v-model="value"
                    :extra-config="editorConfig"
            />

            <v-divider></v-divider>
            <div class="c-d-flex c-justify-space-between" style="background: var(--page-back)">

                <!--Event Templates-->

                <v-btn
                        small
                        color="secondary"
                        :disabled="loading"
                        @click="$emit('dismiss', true)"
                        outline
                >
                    Cancel
                </v-btn>

                <v-btn
                        small
                        color="secondary"
                        @click="addNoteCB(value)"
                        :disabled="loading"
                        :loading="loading"
                >
                    Add Note
                </v-btn>
            </div>



        </v-card>
    </v-dialog>
</template>

<script>
import Editor from "@/Editor/Editor";

export default {
    components: {Editor},
    props: {
        shown: Boolean,
        addNoteCB: Function
    },
    data() {
        return {
            value: '',
            loading: false
        }
    },
    watch: {
        show: {
            immediate: true,
            handler(val) {
                this.value = '';
            }
        }
    },
    computed: {


        editorConfig() {
            return {
                paste_data_images: true,
                remove_linebreaks : false,
                images_reuse_filename: true,
                init_instance_callback: function(editor) {
                    editor.focus()
                },
                content_style: 'img {max-width: 100%;}'
            }
        },
    }
}
</script>

<style scoped>

</style>
