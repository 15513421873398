<template>
    <v-layout column class="pt-1">

        <v-hover>
            <v-flex shrink slot-scope="{ hover }">
                <v-layout :id="asset.type == 0 ? `valueTreeHeading-${ asset.value }` : ''" row align-center style="border-radius: 3px; border: 1px solid #ebebeb;" :style="asset.type == 0 ? 'background: #ebebeb' : ''">
<!--Add Circle-->
                    <v-flex shrink class="pr-2 pl-1">
                        <template v-for="user in users.filter(obj => obj.id == asset.userID)">
                            <v-tooltip top>
                                <v-avatar
                                        :color="asset.userID == userInfo.userID ? 'secondary' : 'primary'"
                                        slot="activator"
                                        :size="25"
                                        style="cursor: pointer"
                                        @click="$emit('showHistory')"
                                >
                                    <span class="white--text caption">{{ getInitials(`${user.name} ${user.surname}`) }}</span>
                                </v-avatar>
                                <span>{{ user.fullName + ' - ' + getLongDate(asset.created) }}</span>
                            </v-tooltip>
                        </template>
                    </v-flex>
<!--Title Asset-->
                    <v-flex xs12 class="body-2" v-if="asset.type == 0">{{ asset.value }}</v-flex>
<!--values Asset-->
                    <template v-else class="white">
                        <v-flex xs12>
                            <v-layout column>
                                <v-flex shrink v-for="field in asset.fields.filter(() => true).sort((a,b) => a['order'] < b['order'] ? -1 : 1)">
                                    <app-value-tree-field
                                            :field="field"
                                            :users="users"
                                            @input="$emit('input')"
                                            :user-info="userInfo"
                                            :selectLists="selectLists"
                                            :read-only="readOnly"
                                            @showHistory="$emit('showHistory')"
                                            :parentUser="asset.userID"
                                            @parentInput="updateUserForRename"
                                            :edit-note-fn="editNoteFn"
                                    />
                                </v-flex>
                            </v-layout>
                        </v-flex>
                    </template>
<!--Add Note Button-->
                    <v-flex shrink v-if="asset.type == 0 && !readOnly">
                        <v-tooltip top>
                            <v-btn
                                    slot="activator"
                                    icon
                                    flat
                                    color="primary"
                                    class="pa-0 ma-0"
                                    small
                                    @click="print()"
                                    :disabled="!hover || addNotePrep"
                                    :loading="addNotePrep"
                            >
                                <v-icon>note_add</v-icon>
                            </v-btn>
                            <span>Add note with reference to: {{asset.value}}</span>
                        </v-tooltip>
                    </v-flex>
<!--Delete Button-->
                    <v-flex shrink v-if="asset.type == 0 && !readOnly && asset.assets.length == 0">
                        <v-btn
                                slot="activator"
                                icon
                                flat
                                color="red"
                                class="pa-0 ma-0"
                                small
                                @click="showConfirmRemove = true"
                                :disabled="!hover"
                        >
                            <v-icon>delete</v-icon>
                        </v-btn>
                    </v-flex>

                    <v-flex shrink class="pa-1" v-if="!readOnly">
                        <v-layout column align-center justify-center>
                            <v-flex shrink>
<!--Add Button-->
                                <v-menu left>
                                    <v-btn
                                            slot="activator"
                                            icon
                                            flat
                                            color="secondary"
                                            class="pa-0 ma-0"
                                            small
                                            :disabled="!hover"
                                    >
                                        <v-icon>add</v-icon>
                                    </v-btn>
                                    <v-list dense>
                                        <v-list-tile @click="showAddValueTreeTitle = true">Title</v-list-tile>
                                        <template v-for="endpoint in valueTreeNodes">
                                            <v-list-tile @click="addValueTreeNode(endpoint)">{{ endpoint.description }}</v-list-tile>
                                        </template>
                                    </v-list>
                                </v-menu>
                            </v-flex>
                            <v-flex v-if="asset.type == 1 && asset.assets.length == 0">
                                <v-btn
                                        slot="activator"
                                        icon
                                        flat
                                        color="red"
                                        class="pa-0 ma-0"
                                        small
                                        :disabled="!hover"
                                        @click="showConfirmRemove = true"
                                >
                                    <v-icon>delete</v-icon>
                                </v-btn>
                            </v-flex>
                        </v-layout>
                    </v-flex>
                </v-layout>
            </v-flex>
        </v-hover>
        <v-flex class="pl-3" v-for="(node, i) in asset.assets">
            <value-tree
                    :userInfo="userInfo"
                    :users="users"
                    :asset="node"
                    @input="$emit('input')"
                    @remove="removeNode(i)"
                    :value-tree-nodes="valueTreeNodes"
                    :selectLists="selectLists"
                    :read-only="readOnly"
                    @showHistory="$emit('showHistory')"
                    :edit-note-fn="editNoteFn"
            />
        </v-flex>

        <app-input-dlg
                :show-dialog="showAddValueTreeTitle"
                title="Add New Title"
                body="Please Specify The New Title:"
                :cancel-button="true"
                :show-input-box="true"
                :buttons="['Add Title']"
                :call-backs="[ addValueTreeTitle ]"
                @dismiss="showAddValueTreeTitle = false"
        />

        <app-input-dlg
                :show-dialog="showConfirmRemove"
                title="Confirm Delete"
                body="Are you sure you would like to delete this item, this action can not be undone"
                :cancel-button="true"
                :show-input-box="false"
                :buttons="['Remove']"
                :call-backs="[ removeNodeConfirmed ]"
                @dismiss="showConfirmRemove = false"
        />

    </v-layout>
</template>

<script>
    import {codeBaseMixin} from "../../../../../../codeBaseMixin";
    import InputDlg from "../../../../../../components/General/InputDlg";
    import ValueTreeField from "./ValueTreeField";
    import {eventBus} from "../../../../../../main";

    export default {
        components: {
            appValueTreeField: ValueTreeField,
            appInputDlg: InputDlg
        },
        mixins: [codeBaseMixin],
        name: "ValueTree",
        props: {
            userInfo: Object,
            asset: Object,
            users: Array,
            valueTreeNodes: Array,
            selectLists: {
                type: Array,
                default: () => {
                    return []
                }
            },
            readOnly: {
                default: false,
                type: Boolean
            },
            editNoteFn: Function
        },
        data() {
            return {
                showAddValueTreeTitle: false,
                showConfirmRemove: false,
                output: null,
                addNotePrep: false
            }
        },
        methods: {

            updateUserForRename() {
                this.asset.userID = this.userInfo.userID;
                this.asset.date = new Date();
                this.$emit('input');

            },

            print() {
                eventBus.$emit('CreateNoteWithHtml', `<div><a href="#valueTreeHeading-${ this.asset.value }"><div style="padding: 5px; border-radius: 3px; color: white; background: #00897B; text-align: center; cursor: pointer">${ this.asset.value }</div></a></div><div>...</div>`)
                // this.addNotePrep = true;
                // const el = this.$refs.printMe;
                // // add option type to get the image version
                // // if not provided the promise will return
                // // the canvas.
                // const options = {
                //     type: 'dataURL'
                // }
                // this.$html2canvas(el, options)
                //     .then((outputValue) => {
                //         eventBus.$emit('CreateNoteWithHtml', `<div><a href="#valueTreeHeading-${ this.asset.value }"><img src="${outputValue}" style="max-width: 100%"/></a></div><div>...</div>`)
                //         this.addNotePrep = false;
                //     })
                //     .catch((e) => {
                //         console.log(e);
                //         this.addNotePrep = false;
                //         this.showSnack('Error', 'Oops Something Went Wrong', 'Close', 'red');
                //     })
            },

            test() {
                eventBus.$emit('CreateNoteWithHtml', document.getElementById(`titleAsset${this.asset.value}`).outerHTML)
            },
            addValueTreeNode(node) {
                this.asset.assets.push({
                    type: 1,
                    assets: [],
                    userID: this.userInfo.userID,
                    created: new Date(),
                    fields: node.fields.map(object => {
                        let obj = JSON.parse(JSON.stringify(object));
                        obj.userID = null;
                        obj.date = null;
                        obj.value = null;
                        return obj;
                    })
                });

                this.$emit('input');
            },

            removeNodeConfirmed() {
                this.showConfirmRemove = false;
                this.$emit('remove');
            },

            removeNode(index) {
                this.asset.assets = this.asset.assets.filter((obj, i) => index != i);
                this.$emit('input');
            },

            addValueTreeTitle(title) {
                if (title != null && title != '') {

                    this.showAddValueTreeTitle = false;

                    this.asset.assets.push({
                        type: 0,
                        value: title,
                        assets: [],
                        userID: this.userInfo.userID,
                        created: new Date()
                    });

                    this.$emit('input')

                } else {
                    this.showSnack('Error', "Title Can't be left blank", 'Close', 'red')
                }
            }
        }
    }
</script>

<style scoped>

</style>
