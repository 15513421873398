import {token} from "@/tokenManSite";

const store             = require('./Store/store');
const EventEmitter      = require('events');
const tokenMan          = require('./tokenManSite');
const {io}                = require('socket.io-client');
let feed                = new EventEmitter();
let socket              = null;
let userInfo            = null;
let lastEventID         = null;

let calPool = {}

function spliceFromCalPool(eventID) {
    setTimeout(() => {
        delete calPool[eventID]
        console.log('Pool Event Cleared')
    }, 30000)
}

function startUpSocket(userInf, location) {
    return new Promise ((resolve, reject) => {
        try {
            userInfo = userInf;
            // socket = io(`https://service2.tasklogix.co.za:4430`, {withCredentials: true});

            // Get The Current Addr
            let socketLocation = location.location.hostname
                .split('.')
                .reduce((items, str, i) => {
                        return items.concat(!i ? `${str}-io${userInfo.feedId}` : str)
                }, []).join('.');

            socket = io(`https://${socketLocation}`,
              {
                  withCredentials: true,
                  extraHeaders: {
                      token: token()
                  }
              }
            );
            socket.on('connect', () => {
                addEventListners();
                joinRooms();
                // this.socket.emit('userConnected', userInfo.userID)
            });
            resolve(true);
        } catch (e) {
            reject(e)
        }
    })
}

function joinRooms() {
    let tData = {
        token: tokenMan.token(),
        lastEventID: lastEventID
    };
    socket.emit('joinRequest', tData)
}

function addEventListners() {

    socket.removeAllListeners();

    socket.io.on('reconnect_attempt', () => {
        socket.io.opts.extraHeaders.token = token();
    });

    socket.on('disconnect', () => {
        socket.io.opts.extraHeaders.token = token();
    });

    socket.on('connect', () => {
        addEventListners();
        joinRooms();
    });

    socket.on('catchUp', (data) => {
        console.log('Catchup Called', data)
        eventCatchupRun(data)
    });

    socket.on('newNotification', (data) => {
        feed.emit('fetchNotifications', extractEventID(data))
    });

    socket.on('error', (data) => {
        socket.disconnect();
    });

    socket.on('task', (data) => {feed.emit('taskChange', extractEventID(data))});
    socket.on('folderGroup', (data) => {feed.emit('folderGroupChange', extractEventID(data))})
    socket.on('userGroup', (data) => {feed.emit('userGroupChange', extractEventID(data))})
    socket.on('workspace', (data) => {feed.emit('workspaceChange', extractEventID(data))})
    socket.on('workspaceGroup', (data) => {feed.emit('workspaceGroupChange', extractEventID(data))})
    socket.on('workflow', (data) => {feed.emit('workflowChange', extractEventID(data))})
    socket.on('workflowGroup', (data) => {feed.emit('workflowGroupChange', extractEventID(data))})
    socket.on('folder', (data) => {feed.emit('folderChange', extractEventID(data))})
    socket.on('microsoftOfficeMessagesDownload', (data) => {feed.emit('microsoftOfficeMessagesDownload', extractEventID(data))})
    socket.on('SmtpMailLink', (data) => {feed.emit('SmtpMailLink', extractEventID(data))})
    socket.on('SmtpMailNew', (data) => {feed.emit('SmtpMailNew', extractEventID(data))})


    socket.on('SmtpMailBoxLink', (data) => {feed.emit('SmtpMailBoxLink', extractEventID(data))})
    socket.on('SmtpMailBoxNew', (data) => {feed.emit('SmtpMailBoxNew', extractEventID(data))})
    socket.on('SmtpMailBoxDeleteFlag', (data) => {feed.emit('SmtpMailBoxDeleteFlag', extractEventID(data))})

    socket.on('createMailStatus', (data) => {
        feed.emit('createMailStatus', extractEventID(data))
    })

    socket.on('calenderUpdate', payload => {
        let payloadData = extractEventID(payload)
        // Do The Upsert
        store.default.dispatch('cal/upsert', payloadData.payload)
        // Emit An Event For Success
        feed.emit(payloadData.eventID, payloadData.payload)

        calPool[payloadData.eventID] = payloadData.payload
        spliceFromCalPool(payloadData.eventID)
    })

    socket.on('oPlug:gotoTask', (data) => {feed.emit('oPlug:gotoTask', extractEventID(data))})
    socket.on('oPlug:gotoFolder', (data) => {feed.emit('oPlug:gotoFolder', extractEventID(data))})
}

function eventCatchupRun(data) {
    data.forEach(evt => {
        feed.emit(`${evt.name}Change`, evt.data);
    })
}

function extractEventID(eventData) {
    lastEventID = eventData.eventID;
    return eventData.data
}

function initialize(userInf, location) {
    return startUpSocket(userInf, location);
}

function waitForEvent(eventID, timeout = 15000) {
    return new Promise((resolve, reject) => {
        let timedOut = false;
        let failTimeOut = setTimeout(() => {
            timedOut = true
            let error = new Error('Timeout Exceeded')
            error.data = `Timeout Length:${timeout}ms`
            reject(error)
        }, timeout)

        feed.once(eventID, (data) => {
            if (!timedOut) {
                clearTimeout(failTimeOut)
                resolve(data || true)
            }
        })

        if (calPool[eventID] && !timedOut) {
            clearTimeout(failTimeOut)
            resolve(calPool[eventID] || true)
        }
    })
}

export {
    initialize,
    feed,
    waitForEvent
}

// export default {
//
//
//
//     events: feed
// };

// & "C:\Windows\System32\Microsoft.Windows.SDK.BuildTools\bin\10.0.22621.0\x64\signtool.exe" sign /v /debug /fd SHA256 /tr "http://timestamp.acs.microsoft.com" /td SHA256 /dlib "C:\Windows\System32\Microsoft.Trusted.Signing.Client\bin\x64\Azure.CodeSigning.Dlib.dll" /dmdf "C:\temp\metadata.json" trl-app.exe