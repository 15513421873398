<template>
    <div>
        <v-btn
                small
                icon
                flat
                class="pa-0 ma-0"
                @click="handleClick"
                :color="playing ? 'red' : 'primary'"
                :loading="loadingAudio"
        >
            <v-icon>{{this.playing ? 'stop' : 'play_arrow'}}</v-icon>
        </v-btn>
    </div>
</template>

<script>
    import {codeBaseMixin} from "../../codeBaseMixin";
    import {eventBus} from "../../main";

    export default {
        mixins: [codeBaseMixin],
        props: {
            src: String
        },
        data() {
            return {
                playerID: null,
                player: null,
                playing: false,
                loadingAudio: false
            }
        },
        created() {
            this.playerID = this.uuidv4();
            eventBus.$on('AudioPlay', this.stopPlayers);
        },
        beforeDestroy() {
            eventBus.$off('AudioPlay', this.stopPlayers);
            this.destroyPlayer();
        },
        methods: {
            stopPlayers(playerID)  {
                if (playerID != this.playerID) {
                    this.destroyPlayer();

                }
            },
            destroyPlayer() {
                if (this.player != null) {
                    this.player.pause();
                    this.player.src = null;
                    this.player = null
                }
                this.resetValues();
            },

            resetValues() {
                this.playing = false;
                this.loadingAudio = false;
            },

            handleClick() {
                if (this.player == null) {
                    eventBus.$emit('AudioPlay', this.playerID)
                    this.player = new Audio(this.src);

                    // this.player.addEventListener('canplay', () => {console.log('canplay Fired')});
                    // this.player.addEventListener('canplaythrough', () => {console.log('canplaythrough Fired')});
                    // this.player.addEventListener('durationchange', () => {console.log('durationchange Fired')});
                    // this.player.addEventListener('emptied', () => {console.log('emptied Fired')});

                    // this.player.addEventListener('loadeddata', () => {console.log('loadeddata Fired')});
                    // this.player.addEventListener('loadedmetadata', () => {console.log('loadedmetadata Fired')});
                    // this.player.addEventListener('loadstart', () => {console.log('loadstart Fired')});
                    // this.player.addEventListener('pause', () => {console.log('pause Fired')});
                    // this.player.addEventListener('progress', () => {console.log('progress Fired')});
                    // this.player.addEventListener('ratechange', () => {console.log('ratechange Fired')});
                    // this.player.addEventListener('stalled', () => {console.log('stalled Fired')});
                    // this.player.addEventListener('timeupdate', () => {console.log('timeupdate Fired')});
                    // this.player.addEventListener('waiting', () => {console.log('waiting Fired')});

                    this.player.addEventListener('play', () => {
                        this.resetValues();
                        this.loadingAudio = true;
                    });
                    this.player.addEventListener('playing', () => {
                        this.resetValues();
                        this.playing = true;
                    });

                    this.player.addEventListener('abort', () => {
                        this.resetValues();
                    });
                    this.player.addEventListener('ended', () => {
                        this.resetValues();
                    });
                    this.player.addEventListener('error', () => {
                        this.resetValues();
                    });

                    this.$nextTick(() => {
                        this.player.play();
                    })
                } else {
                    if (this.playing) {
                        this.player.pause();
                        this.player.currentTime = 0;
                        this.resetValues();
                    } else {
                        this.player.play();
                    }

                }
            }
        }

    }
</script>

<style scoped>

</style>
