<template>
    <v-layout row>
        <v-flex>
            <form
                    @dragover="allowDrop"
                    @drop="$event.preventDefault(); $event.dataTransfer.files.length == 0 ? drop($event) : dropFiles($event)"
            >
                <v-dialog v-model="showUploading" persistent width="200px">
                    <v-card>
                        <v-layout column>
                            <v-flex shrink class="text-xs-center pa-2 pt-3">
                                <v-progress-circular
                                        :size="40"
                                        :width="5"
                                        color="primary"
                                        indeterminate
                                ></v-progress-circular>
                            </v-flex>
                            <v-flex shrink class="text-xs-center pa-2">
                                <span>Uploading File</span>
                            </v-flex>
                        </v-layout>
                    </v-card>
                </v-dialog>

                <template v-if="value.value != null && value.value != ''">

                    <div class="pt-1">
                        <template v-if="value.value_type == 2">
                            <app-document-container
                                    :document="computedObj"
                                    :user-info="userInfo"
                                    :srcTaskAttachment="srcTaskAttachment"
                                    :dense="true"
                            />
                        </template>

                        <template v-if="value.value_type == 1">

                            <app-general-mail-container
                                    v-if="computedObj != null"
                                    :mail="computedObj"
                                    :user-info="userInfo"
                                    :dashboard="false"
                                    :task-band="false"
                                    :task-value="true"
                                    :srcTaskAttachment="srcTaskAttachment"
                                    :dense="true"
                            />
                        </template>
                    </div>
                </template>
                <template v-else>
                    <div style="border: 1px dashed lightgrey; border-radius: 3px" class="text-xs-center ma-1">
                        <span>{{intToBool(value.editable) ? 'Drop File Here' : 'Read Only'}}</span>
                    </div>
                </template>
            </form>
        </v-flex>
    </v-layout>
</template>

<script>

    import DocumentContainer from '../../../../../../Tasks/TaskView V2/components/Documents/DocumentContainer';
    import GeneralMailContainer from '../../../../../../components/General/generalMailContainer';
    import {eventBus} from "../../../../../../main";
    import {codeBaseMixin} from "../../../../../../codeBaseMixin";
    import {DatasetBinder} from "@/Tasks/TaskView V2/components/TaskViewDetail/datasetBinder";
    const tokenMan = require('../../../../../../tokenManSite')

    export default {
        mixins: [codeBaseMixin],
        components: {
            appDocumentContainer: DocumentContainer,
            appGeneralMailContainer: GeneralMailContainer,
        },
        props: {
            value: Object,
            userInfo: Object,
            mails: Array,
            documents: Array,
            selectedStep: Object,
            task: Object,
            stepEditable: Boolean,
            srcTaskAttachment: {
                type: Boolean,
                default: false
            },
        },
        data() {
            return {
                showUpload: {show: false},
                dragData: {
                    type:'',
                    data: {}
                },
                files: [],
                showUploading: false
            }
        },
        created() {
            eventBus.$on('mailDrag', this.mailDragLocalEventOne);
            eventBus.$on('attachmentDrag', this.attachmentDragLocalEvent);
            eventBus.$on('taskDocumentDrag', this.taskDocumentDragLocalEvent);
        },
        beforeDestroy() {
            eventBus.$off('mailDrag', this.mailDragLocalEventOne);
            eventBus.$off('attachmentDrag', this.attachmentDragLocalEvent);
            eventBus.$off('taskDocumentDrag', this.taskDocumentDragLocalEvent);
        },
        methods: {

            mailDragLocalEventOne(mail) {this.dragData = {type: 'mail', data: mail}},
            attachmentDragLocalEvent(attachment) {this.dragData = {type: 'attachment', data: attachment}},
            taskDocumentDragLocalEvent(document) {this.dragData = {type: 'document', data: document}},


            documentUploaded(document) {
                this.value.valueType = 2;
                this.value.value = JSON.stringify(document);
            },
            allowDrop: function (ev) {
                ev.preventDefault();
            },
            drop: function (ev) {
                ev.preventDefault();
                if (this.intToBool(this.value.editable) && this.stepEditable) {
                    switch (this.dragData.type) {
                        case '': {

                            break;
                        }
                        case 'mail': {

                            this.linkMailToStep(this.dragData.data);
                            this.value.value_type = 1;
                            this.value.value = this.dragData.data.mailDBID;
                            break;
                        }
                        case 'attachment': {

                            this.linkMailAttachmentToStep(this.dragData.data.mail, this.dragData.data.attachment)
                            //this.linkMailAttachmentToStep(this.dragData.data.mail, this.dragData.data.attachment)
                            break;
                        }
                        case 'document': {


                            this.value.value_type = 2;
                            this.value.value = this.dragData.data.documentDBID;
                        }
                    }
                } else {
                    this.showSnack('Warning', 'Field is Read Only', 'Close', 'amber')
                }
            },
            linkMailToStep(mail) {


                mail.stepID = this.selectedStep.stepID;
                eventBus.$emit('taskMailLinked', mail);

                this.fetch({url: '/tasks/linkMailToStep/' + this.userInfo.entityID + '/' + this.userInfo.userID + '/' + mail.messageID + '/' + this.selectedStep.stepID, method: 'PATCH'})
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e)
                    })
            },
            linkMailAttachmentToStep(mail, attachment) {
                // mail.stepID = this.selectedStep.stepID;
                // eventBus.$emit('taskMailLinked', mail);

                let mailPath = mail.fk_import_user != 0 ? mail.fk_import_user + '-' + mail.mailID : mail.fk_gmail_group_mailbox_folder + '-' + mail.mailID
                this.fetch({url: '/tasks/linkMailAttachmentToStepWithInheritance/' + this.userInfo.entityID + '/' + this.userInfo.userID + '/' + mailPath + '/' + this.selectedStep.stepID + '/' + this.task.taskID + '/' + mail.mailDBID, method: 'PATCH', body: JSON.stringify(attachment)})
                    .then((data) => {
                        this.task.structure.documents.push(data);
                        eventBus.$emit('mailAttchmentLinkedToStep', true)
                        setTimeout(() => {
                            this.value.value_type = 2;
                            this.value.value = data.documentDBID;
                        }, 200)
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e)
                    })
            },

            dropFiles(e) {

                this.showUploading = true;
                this.files = [];
                for (let i = 0; i < e.dataTransfer.files.length; i++) {
                    this.files.push(e.dataTransfer.files[i]);
                }
                if (this.files.length >= 0) {
                    this.startRun();
                } else {
                    this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
                    this.showUploading = false;
                }
            },

            startRun() {
                function checkFileType(mimeList, file) {
                    let list = mimeList
                            .split(',')
                            .map(str => {try {return str.trim().toLowerCase()} catch (e) {return ''}})
                    let typeList = list
                            .filter(str => str && str.substr(0,1) !== '.')
                    let extList = list
                            .filter(str => str && str.substr(0,1) === '.')
                            .map(str => {
                                try {
                                    return str.substring(1)
                                } catch (e) {
                                    return ''
                                }
                            })
                    let ext = file.name ? file.name.split('.').pop().toLowerCase() : ''
                    let type = file.type ? file.type.toLowerCase().trim() : ''
                    return typeList.includes(type) || extList.includes(ext)
                }
                if (((this.files[0].size / 1024) / 1024) < 50) {
                    if (checkFileType(this.userInfo.entityInfo.mimeTypeList, this.files[0])) {
                        this.startUpload()
                    } else {
                        this.showSnack('Info', 'Invalid File Type', 'Close', 'primary');
                        this.showUploading = false;
                    }
                } else {
                    this.showSnack('Info', 'File larger that 25 Mb', 'Close', 'primary');
                    this.showUploading = false;
                }
            },


            startUpload() {
                let h = new Headers();
              
                h.append('tl-token', tokenMan.token());

                let fd = new FormData();
                let myFile = this.files[0];
                fd.append('document', myFile);
                let req = new Request(`${this.serverAddress}/upload/taskDocument/`, {
                    method: 'POST',
                    headers: h,
                    mode: 'cors',
                    body: fd
                });

                fetch(req)
                    .then((response)=> {if (response.ok == true) {return response.text();} else {throw new Error('Bad Stuff')}})
                    .then((jsonData) => {
                        this.saveDocuments(JSON.parse(jsonData))
                    })
                    .catch((err) => {
                        //eslint-disable-next-line
                        console.log('ERROR: ' + err.message);
                        this.showSnack('Info', 'Network error. Please try again.', 'Close', 'primary')
                    });
            },

            save() {
                if (!this.fieldValue) {
                    this.saveDocuments()
                } else {
                    this.saveFieldDocuments()
                }
            },


            saveDocuments(dropDocument) {
                this.fetch(
                    {
                        url: '/tasks/saveDocuments/' + this.userInfo.entityID + '/' + this.userInfo.userID + '/' + this.task.taskID + '/' + this.selectedStep.stepID,
                        method: 'POST',
                        body: JSON.stringify([JSON.stringify(dropDocument)])
                    }
                )
                    .then(data => {
                        data.forEach(document => {
                            this.task.structure.documents.push(document)
                            this.value.value_type = 2;
                            this.value.value = document.documentDBID;
                            this.showUploading = false;
                        });
                    })
                    .catch(err => {
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
                        console.log(err);
                        this.showUploading = false;
                    })
            },
        },
        computed: {
            computedObj() {

                if (this.value.value_type == 1) {
                    if (this.value.value != null && this.value.value != '') {
                        let tMail = null;
                        this.mails.forEach(mail => {
                            if (mail.mailDBID == this.value.value) {
                                tMail = mail
                            }
                        });
                        return tMail
                    } else {

                        return null
                    }
                } else {
                    if (this.value.value != null && this.value.value != '') {
                        let tDoc = null;
                        this.documents.forEach(document => {
                            if (document.documentDBID == this.value.value) {
                                tDoc = document;
                            }
                        });

                        return tDoc
                    } else {

                        return null
                    }
                }


            }
        }
    }
</script>

<style scoped>

</style>
