<template>
    <v-dialog
            v-model="value"
            persistent
            width="700"
    >
        <v-card>
            <v-card-title class="pa-1 pl-2 py-2">
                <span class="subheading font-weight-light primary--text">{{ `${field.fieldDescription} History` }}</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text v-if="loading" class="scroll pa-0 py-0 pa-2">

                <v-progress-circular
                        :size="40"
                        :width="5"
                        color="primary"
                        indeterminate
                />

            </v-card-text>
            <v-card-text style="max-height: 600px; overflow-y: auto" class="scroll pa-0 py-0">
<!--Type 1 - 5-->
                <template v-if="field.type <= 5 && history != null && history.length > 0">
                    <v-list compact>
                        <template v-for="(value, i) in history.filter(() => true).sort((a,b) => new Date(a['modify_date']) < new Date(b['modify_date']) ? 1 : -1)">
                            <v-divider v-if="i != 0"></v-divider>
                            <v-list-tile
                                    @click="() => {}"
                            >
                                <v-layout fill-height row align-center class="py-1">
                                    <v-flex xs5>
                                        <div style="width: 100%"><span class="caption primary--text font-weight-bold">{{value.name || value.contactName || 'System'}}</span>
                                        </div>
                                        <div style="width: 100%"><span class="greyType--text caption">{{ getLongDate(value.modify_date) }}</span>
                                        </div>
                                    </v-flex>
                                    <v-flex xs7>
                                        <div style="width: 100%"><span class="body-1">{{value.value}}</span></div>
                                    </v-flex>
                                  <v-flex shrink>
                                    <v-btn
                                        icon
                                        color="primary"
                                        flat
                                        @click="copyValue(value.value)"
                                    >
                                      <v-icon>content_copy</v-icon>
                                    </v-btn>
                                  </v-flex>
                                </v-layout>
                            </v-list-tile>
                        </template>
                    </v-list>
                </template>
<!--Type 6 AND 7 AND 8-->
                <template v-if="field.type == 6 && history != null && history.length > 0">
                    <template v-for="(value, i) in history.filter(() => true).sort((a,b) => new Date(a['modify_date']) < new Date(b['modify_date']) ? 1 : -1)">
                        <v-divider v-if="i != 0"></v-divider>
                        <v-hover>
                            <v-layout
                                    fill-height
                                    row
                                    align-center
                                    class="py-1"
                                    slot-scope="{ hover }"
                                    :class="`${hover? 'grey lighten-4': ''}`"
                                    style="cursor: pointer"
                            >
                                <v-flex xs5 class="pl-2">
                                    <div style="width: 100%"><span class="caption primary--text font-weight-bold">{{value.name}}</span>
                                    </div>
                                    <div style="width: 100%"><span class="greyType--text caption">{{ getLongDate(value.modify_date) }}</span>
                                    </div>
                                </v-flex>
                                <v-flex xs7 v-if="value.value != null && value.value != ''" class="pa-2">
                                    <app-document-container
                                            v-if="value.valueType == 2 && field.fieldType != 8"
                                            :document="JSON.parse(value.value)"
                                            :user-info="userInfo"
                                    />
                                    <app-general-mail-container
                                            v-if="(value.valueType == 1 || field.fieldType == 8) && JSON.parse(value.value) != null"
                                            :mail="computedMail(value.value)"
                                            :user-info="userInfo"
                                            :dashboard="false"
                                            :task-band="false"
                                            :task-value="true"
                                    />
                                </v-flex>
                            </v-layout>
                        </v-hover>
                    </template>
                </template>

<!--Type 13 -->
                <template v-if="field.type == 13 && history != null && history.length > 0">
                    <template v-for="(value, i) in history.filter(() => true).sort((a,b) => new Date(a['modify_date']) < new Date(b['modify_date']) ? 1 : -1)">
                        <v-divider v-if="i != 0"></v-divider>
                        <div
                                class="pa-2"
                        >
                            <v-layout fill-height row align-center class="py-1">
                                <v-flex xs5>
                                    <div style="width: 100%"><span class="caption primary--text font-weight-bold">{{value.name}}</span>
                                    </div>
                                    <div style="width: 100%"><span class="greyType--text caption">{{ getLongDate(value.modify_date) }}</span>
                                    </div>
                                </v-flex>
                                <v-flex xs7>
                                    <div style="width: 100%; overflow-y: auto; max-height: 500px; cursor: pointer"
                                         v-html="value.value">
                                    </div>
                                </v-flex>
                            </v-layout>
                        </div>
                    </template>
                </template>
            </v-card-text>
            <v-divider></v-divider>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                        color="secondary"
                        flat
                        @click="$emit('input', false)"
                >
                    Done
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import {codeBaseMixin} from "../../../codeBaseMixin";
    import DocumentContainer from '../../../Tasks/TaskView/components/Documents/DocumentContainer';
    import generalMailContainer from '../../../components/General/generalMailContainer'
    import {copyText} from "@/codeFunctions";

    export default {
        mixins: [codeBaseMixin],
        components: {
            appDocumentContainer: DocumentContainer,
            appGeneralMailContainer: generalMailContainer
        },
        props: {
            userInfo: Object,
            field: Object,
            fieldSet: Object,
            value: {type: Boolean, default: false}
        },
        data() {
            return {
                history: null,
                loading: false
            }
        },

        watch: {
            value(shown) {
                if (shown) this.getHistory();
            }
        },

        methods: {
          
          copyValue(val) {
            copyText(document, val)
            this.$snack.info('Copied to clipboard')
          },
          
            getHistory() {
                this.loading = true;
                this.fetch({method: 'GET', url: `/fieldsets/FieldHistory/${this.userInfo.entityID}/${this.field.valueID}`})
                    .then((data) => {
                        this.history = data;
                        this.loading = false;
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        this.loading = false;
                        console.log(e);
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                    })
            },

            computedMail(mail) {
                if (mail != null && mail != '') {

                    let tMail = JSON.parse(mail);

                    tMail.attachments = JSON.parse(tMail.attachments);

                    return tMail
                } else {
                    return null
                }
            }
        },
    }
</script>

<style scoped>

</style>
