<template>
  <div
      class="hover-item"
      style="cursor: pointer" @click="$emit('selectRecording', rec.recording)"
      :style="{
              background: selectedRec === rec.recording ? 'var(--selected-item)' : null
            }"
  >
    <v-layout row style="overflow-x: hidden" class="px-2 py-1" align-center>
      <v-flex shrink class="pr-2">
        <simple-audio
            :src="previewAddress"
        />
      </v-flex>
      <v-flex xs5 style="overflow-x: hidden" class="py-1">
        <div class="body-1 text-truncate" style="overflow-x: hidden">
          {{ rec.callfrom }}
        </div>
      </v-flex>
      <v-flex xs5 style="overflow-x: hidden" class="py-1">
        <div class="body-1 text-truncate" style="overflow-x: hidden">
          {{ rec.callto }}
        </div>
      </v-flex>
      
      <v-flex xs2 class="caption font-weight-bold greyType--text " style="white-space: nowrap">
        {{ recordingDuration }}
      </v-flex>
      
      <v-flex xs2 class="caption font-weight-bold greyType--text mr-3" style="white-space: nowrap">
        {{ recordingDate }} {{ recordingTime }}
      </v-flex>
    </v-layout>
    <v-divider></v-divider>
  </div>
</template>

<script>
import {codeBaseMixin} from "../../../../codeBaseMixin";
import SimpleAudio from "../../../../components/General/SimpleAudio";
import {mapGetters} from "vuex";
import {serverAddress} from "@/axiosRequest";
import {token} from "@/tokenManSite";

export default {
  mixins: [codeBaseMixin],
  components: {
    SimpleAudio,
  },
  props: {
    ext: [Number, String],
    rec: Object,
    selectedRec: String,
    showFrom: {default: true, type: Boolean},
    showTo: {default: true, type: Boolean}
  },
  data() {
    return {}
  },
  methods: {},
  computed: {
    ...mapGetters("userInfo", ["userInfo"]),
    
    
    previewAddress() {
      return `${serverAddress}/yeastarSSeries/recording/preview/${this.rec.recording}/${token()}`
    },
    
    recordingDuration() {
      let callTime = +this.rec.callduraction
      
      const minutes = Math.floor(callTime / 60)
      const seconds = callTime % 60
      
      
      
      return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
    },
    
    recordingTime() {
      return this.getSqlTime(this.rec.timestart)
    },
    recordingDate() {
      return this.getShortDateLongYear(this.rec.timestart)
    },
    toDescription() {
      try {
        return this.rec.callto
      } catch (e) {
        // eslint-disable-next-line
        console.log(e)
        return '-'
      }
    },
    toNumber() {
      try {
        return this.rec.callto
      } catch (e) {
        // eslint-disable-next-line
        console.log(e)
        return '-'
      }
    }
  }
}
</script>

<style scoped>

</style>
