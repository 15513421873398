<template>
    <div>
        <v-menu offset-y full-width :close-on-content-click="false" v-model="selectingField">
            <template v-slot:activator="{ on }">
                    <div v-show="lookupDisplay == null" v-on="on">
                        <v-btn small flat color="secondary" class="ma-0">Select Dataset</v-btn>
                    </div>
                    <v-card ripple hover flat v-show="lookupDisplay != null" class="primary white--text" v-on="on">
                        <v-layout row align-center style="cursor: pointer" v-if="lookupDisplay != null">
                            <v-flex xs12 class="pa-1 pl-2">
                                {{lookupDisplay}}
                            </v-flex>
                            <v-flex shrink class="pr-1">
                                <v-tooltip top>
                                    <v-btn
                                            small
                                            flat
                                            icon
                                            color="white"
                                            class="pa-0 ma-0"
                                            slot="activator"
                                            @click="removeDSLinkRun"
                                            :disabled="value == null || value ==''"
                                    >
                                        <v-icon small>delete</v-icon>
                                    </v-btn>
                                    <span>Remove Dataset Link</span>
                                </v-tooltip>
                            </v-flex>
                            <v-flex shrink class="pr-1">
                                <v-tooltip top>
                                    <v-btn
                                            small
                                            flat
                                            icon
                                            color="white"
                                            class="pa-0 ma-0"
                                            slot="activator"
                                    >
                                        <v-icon small>edit</v-icon>
                                    </v-btn>
                                    <span>Edit Dataset Link</span>
                                </v-tooltip>
                            </v-flex>
                        </v-layout>
                    </v-card>
            </template>
            <v-card>
                <v-layout row align-center class="pa-1">
                    <v-flex xs12>
                        <div class="pa-1">
                            <div class="caption greyType--text font-weight-bold">
                                Dataset:
                            </div>
                            <div class="pl-2">
                                <v-autocomplete
                                        dense
                                        v-model="fieldSetSelectFieldModel"
                                        :items="fieldSetSelectFields"
                                        label="Select Dataset"
                                        hide-details
                                        single-line
                                        color="primary"
                                        item-text="description"
                                        item-value="id"
                                        class="pa-0 ma-0"
                                ></v-autocomplete>
                            </div>
                        </div>
                    </v-flex>
                    <v-flex shrink class="pr-1">
                        <v-tooltip top>
                            <v-btn
                                    icon
                                    flat
                                    class="pa-0 ma-0"
                                    color="secondary"
                                    slot="activator"
                                    @click="cancelRun"
                            >
                                <v-icon>close</v-icon>
                            </v-btn>
                            <span>Cancel</span>
                        </v-tooltip>
                    </v-flex>
                    <v-flex shrink class="pr-1">
                        <v-tooltip top>
                            <v-btn
                                    icon
                                    flat
                                    class="pa-0 ma-0"
                                    color="primary"
                                    slot="activator"
                                    @click="doneRun"
                                    :disabled="fieldSetSelectField == null"
                            >
                                <v-icon>done</v-icon>
                            </v-btn>
                            <span>Done</span>
                        </v-tooltip>
                    </v-flex>
                </v-layout>
            </v-card>
        </v-menu>
        <app-input-dlg
                :show-dialog="showConfirmRemoveLink"
                title="Confirm Removal"
                body="Please note changing the Dataset will disconnect all linked fields. Please note this operation can not be undone."
                :cancel-button="!clearingLinkLookup"
                :show-input-box="false"
                :buttons="!clearingLinkLookup ? ['Cancel', 'Confirm'] : []"
                :call-backs="[ cancelRemoveDatasetLink, confirmRemoveDatasetLink ]"
                @dismiss="showConfirmRemoveLink = false"
        />
    </div>
</template>

<script>
    import {codeBaseMixin} from "../../../../../../codeBaseMixin";
    import InputDlg from "../../../../../../components/General/InputDlg";

    export default {
        mixins: [codeBaseMixin],
        components: {
            appInputDlg: InputDlg
        },
        props: {
            userInfo: Object,
            field: Object,
            fields: Array,
            fieldSets: Array,
            value: [String, Number],
            fieldTypeFilter: Array,
            showPrompt: {
                type: Boolean,
                default: true
            },
            modelInt: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                showConfirmRemoveLink: false,
                selectingField: false,
                fieldSetSelectField: null,
                replacingValue: false,
                clearingLinkLookup: false
            }
        },
        watch: {
            value: {
                immediate: true,
                handler(val) {
                    this.resetLocalValues(val)
                }
            }
        },
        methods: {
            finalizeSave(clearFields) {
                this.clearingLinkLookup = false;
                this.showConfirmRemoveLink = false;

                this.fields.forEach(field => {
                    if (clearFields.includes(field.id)) {
                        field.linkLookup = null
                    }
                });

                if (!this.replacingValue) {
                    this.$emit('input', null)
                } else {
                    this.$emit('input', this.fieldSetSelectField != null ? (this.modelInt ? this.fieldSetSelectField : `${this.fieldSetSelectField}`) : null);
                }
            },

            confirmRemoveDatasetLink() {
                this.clearingLinkLookup = true;


                let clearFields = this.fields.filter(obj =>
                    obj.linkLookup != null
                    && obj.linkLookup != ''
                    && typeof obj.linkLookup.fieldSetSelectField != 'undefined'
                    && obj.linkLookup.fieldSetSelectField != null
                    && obj.linkLookup.fieldSetSelectField == this.field.id
                ).map(obj => {
                    return obj.id;
                });

                if (clearFields.length > 0) {
                    this.fetch({
                        method: 'PATCH',
                        url: `/workflowManager/field/clearlinklookup/${this.userInfo.entityID}`,
                        body: JSON.stringify(clearFields)
                    })
                        .then((data) => {
                            this.finalizeSave(clearFields)
                        })
                        .catch((e) => {
                            // eslint-disable-next-line
                            console.log(e);
                            this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
                            this.clearingLinkLookup = false;
                            this.showConfirmRemoveLink = false;
                        })
                } else {
                    this.finalizeSave([]);
                }
            },
            doneRun() {
                this.replacingValue = true;

                if (this.showPrompt) {
                    setTimeout(() => {
                        this.selectingField = false;
                        this.showConfirmRemoveLink = true;
                    }, 1)
                } else {
                    this.selectingField = false;
                    this.confirmRemoveDatasetLink();
                }
            },

            cancelRun() {
                this.resetLocalValues(this.value);
                this.selectingField = false
            },

            resetLocalValues(val) {
                this.fieldSetSelectField = null;
                this.replacingValue = false;
                if (val != null && val != '') {
                    this.fieldSetSelectField = parseInt(val);
                }
            },

            removeDSLinkRun() {
                setTimeout(() => {
                    this.selectingField = false;
                    this.showConfirmRemoveLink = true;
                }, 1)
            },

            cancelRemoveDatasetLink() {
                this.showConfirmRemoveLink = false
            },

        },
        computed: {
            fieldSetSelectFields() {
                return this.fieldSets.filter(obj => obj.visible == 1)
                    .map(obj => {return {description: obj.description, id: obj.id}})
                    .sort((a,b) => a['description'] < b['description'] ? -1 : 1);
            },

            fieldSetSelectFieldModel: {
                get: function () {
                    return this.fieldSetSelectField
                },
                set: function (newValue) {
                    this.fieldSetSelectField = newValue;
                }
            },

            lookupDisplay() {
                if (this.value != null && this.value != '') {
                    try {
                        // Get The value field Name
                        return this.fieldSets.filter(obj => obj.id == parseInt(this.value))
                            .map((fieldset) => {
                                return fieldset.description
                            })
                            .pop();

                    } catch (e) {
                        // eslint-disable-next-line
                        console.log(e)
                        return null
                    }
                } else {
                    return null
                }
            },
        }
    }
</script>

<style scoped>

</style>
