<template>
  
  <div class="fill-height c-d-flex">
    <template v-if="startupLoading">
      <div class="c-d-flex c-align-center c-justify-center c-flex-grow-1 fill-height">
        <v-progress-circular
            :size="40"
            :width="5"
            color="primary"
            indeterminate
        ></v-progress-circular>
      </div>
    </template>
    <template v-else>
      <div style="width: 50%" class="fill-height of-y">
        <div style="position: sticky; top: 0; z-index: 9" class="card-back">
          <div class="pa-2">
            <v-text-field
                class="page-back"
                solo
                hide-details
                prepend-inner-icon="search"
                placeholder="Search Channels"
                v-model="searchStr"
                :clearable="true"
            ></v-text-field>
          </div>
          
          <div class="px-2 pb-2">
            <v-btn
                block
                outline
                color="secondary"
                @click="showAddChannel = true"
            >
              <v-icon left>add</v-icon>
              Add Channel
            </v-btn>
          </div>
          
          
          <div>
            <v-divider></v-divider>
          </div>
        </div>
        <v-list>
          
          <v-list-tile
              v-for="channel in channels"
              :key="channel.id"
              color="primary"
              :value="selectedChannel === channel.id"
              @click="selectedChannel === channel.id ? selectedChannel = null : selectedChannel = channel.id"
              active-class="someActive"
          >
            <v-list-tile-avatar><v-icon>chat</v-icon></v-list-tile-avatar>
            <v-list-tile-content>
              <v-list-tile-title style="color: var(--on-surface)">{{ channel.internalName }}</v-list-tile-title>
              <v-list-tile-sub-title v-if="channel.disabled"><strong>DISABLED</strong></v-list-tile-sub-title>
            </v-list-tile-content>
          </v-list-tile>
        
        
        </v-list>
      </div>
      <div style="width: 50%" class=" fill-height of-y">
        <app-contacts-channel-config
            :edit-channel="selectedChannelObject"
            :folder-groups="folderGroups"
            :user-groups="userGroups"
            :apps="apps"
            v-if="selectedChannelObject"
            @input="updateChannel"
        />
      </div>
    </template>
    
    
    
    
    
    <app-input-dlg
        :show-dialog="showAddChannel"
        title="Create Channel"
        body="New channel internal name"
        :cancel-button="true"
        :show-input-box="true"
        :buttons="['Create']"
        :call-backs="[ addNewChannel ]"
        @dismiss="showAddChannel = false"
        default-input=""
        :loading="loadingAddChannel"
    />
    
    
  </div>

</template>

<script>

import {arrSplice} from "@/codeFunctions";
import AppContactsCustomFieldConfig from "@/contacts/AppContactsCustomFieldConfig.vue";
import draggable from 'vuedraggable';
import appInputDlg from "@/components/General/InputDlg.vue";
import AppContactsCustomFields from "@/contacts/AppContactsCustomFields.vue";
import AppContactsChannelConfig from "@/contacts/AppContactsChannelConfig.vue";

const blankField = {
  id: null,
  description: null,
  visible: 1,
  type: 0,
  lookup: null,
  order: null
}

export default {
  components: {AppContactsChannelConfig, AppContactsCustomFields, appInputDlg, AppContactsCustomFieldConfig, draggable},
  props: {
    selectLists: {
      type: Array,
      default: () => []
    },
    customFields: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      startupLoading: false,
      showAddChannel: null,
      loadingAddChannel: false,
      selectedChannel: null,
      editFields: [],
      searchStr: null,
      channels: [],
      folderGroups: [],
      userGroups: [],
      apps: []
    }
  },
  watch: {
    customFields: {
      immediate: true,
      handler(val) {
        this.editFields = JSON.parse(JSON.stringify(this.customFields))
      }
    }
    
  },
  
  mounted() {
    this.init();
  },
  
  methods: {
    
    updateChannel(updatedChannel) {
      const existingChannel = this.channels.find(c => c.id === updatedChannel.id)
      if (existingChannel)
        Object.assign(existingChannel, updatedChannel)
    },
    
    async init() {
      try {
        this.startupLoading = true
        const {channels, folderGroups, userGroups, apps} = await this.$newReq('GET', `channels/setup`)
        this.channels = channels
        this.folderGroups = folderGroups
        this.userGroups = userGroups
        this.apps = apps
      } catch (e) {
        console.log(e)
        this.$snack.networkError();
      } finally {
        this.startupLoading = false
      }
    },
    
    async addNewChannel(internalName) {
      
      if (!internalName)
        return this.$snack.error('Internal name is required');
      
      if (this.channels.find(exCha => exCha.internalName.toLowerCase() === internalName.toLowerCase()))
        return this.$snack.error('Duplicate Channel Name');
      
      try {
        this.loadingAddChannel = true
        const newChannel = await this.$newReq('POST', `channels/setup/create`, {internalName, disabled: true})
        this.$snack.info('Channel added')
        this.showAddChannel = false;
        this.channels.push(newChannel)
        this.selectedChannel = newChannel.id
      } catch (e) {
        console.log(e)
        this.$snack.networkError();
      } finally {
        this.loadingAddChannel = false
      }
    },
    
      

  },
  computed: {
    selectedChannelObject() {
      return this.channels.find(val => val.id === this.selectedChannel)
    },
  },
}
</script>


<style>

.someActive {
  //background: fuchsia !important;
  background: #00695C20;
}

.selected-tile-background {
  background-color: var(--v-primary-base);
  color: red;
}


</style>