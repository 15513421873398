<template>
    <v-layout column fill-height style="overflow-y: auto; overflow-x: hidden">
        <template v-show="!loadingHTML">
            <!--Button Bar-->
            <v-flex shrink class="pt-2 px-1">
                <v-card flat style="overflow-x: auto">
                    <!--Create Task-->
                    <v-tooltip top v-if="typeof userInfo.createTask != 'undefined' && intToBool(userInfo.createTask)">
                        <v-btn
                                fab
                                dark
                                small
                                color="secondary"
                                @click="$emit('createTaskOffice', message); $emit('showLinkOffice', false)"
                                slot="activator"
                                class="pa-0 ma-1"
                        >
                            <v-icon>add</v-icon>
                        </v-btn>
                        <span>Create Task</span>
                    </v-tooltip>
                    <!--Link To Task-->
                    <v-tooltip top>
                        <v-btn
                                fab
                                dark
                                small
                                color="secondary"
                                @click="$emit('showLinkOffice', true); $emit('setOfficeMail', message)"
                                slot="activator"
                                class="pa-0 ma-1"
                        >
                            <v-icon>link</v-icon>
                        </v-btn>
                        <span>Link To Task</span>
                    </v-tooltip>
                    <!--Reply-->
                    <v-tooltip top>
                        <v-btn
                                fab
                                dark
                                small
                                color="primary"
                                @click="officeMailReplyCompose(1)"
                                slot="activator"
                                class="pa-0 ma-1"
                                :loading="downloadingMessageHTML"
                                :disabled="downloadingMessageHTML"
                        >
                            <v-icon dark>reply</v-icon>
                        </v-btn>
                        <span>Reply</span>
                    </v-tooltip>
                    <!--Reply To All-->
                    <v-tooltip top>
                        <v-btn
                                fab
                                dark
                                small
                                color="primary"
                                @click="officeMailReplyCompose(2)"
                                slot="activator"
                                class="pa-0 ma-1"
                        >
                            <v-icon>reply_all</v-icon>
                        </v-btn>
                        <span>Reply To All</span>
                    </v-tooltip>
                    <!--Forward-->
                    <v-tooltip top>
                        <v-btn
                                fab
                                dark
                                small
                                color="primary"
                                @click="officeMailReplyCompose(3)"
                                slot="activator"
                                class="pa-0 ma-1"
                        >
                            <v-icon>forward</v-icon>
                        </v-btn>
                        <span>Forward Mail</span>
                    </v-tooltip>

                    <!--Quick Tasks-->
                    <template v-if="typeof userInfo.createTask != 'undefined' && intToBool(userInfo.createTask)">
                        <v-tooltip top v-for="qt in sortedQuickTasks" :key="`quickTaskView1:${qt.id}`">
                            <v-btn
                                    fab
                                    dark
                                    small
                                    color="secondary"
                                    @click="quickTask(qt)"
                                    slot="activator"
                                    class="pa-0 ma-1"
                            >
                                <v-icon>{{qt.config.icon}}</v-icon>
                            </v-btn>
                            <span>{{qt.config.description}}</span>
                        </v-tooltip>
                    </template>
                </v-card>
            </v-flex>
            <v-flex xs12 class="pa-1">
                <v-card flat class="fill-height">
                    <v-layout column fill-height>
                        <!--Message Header-->
                        <v-flex shrink>
                            <div v-if="typeof message.tasks != 'undefined' && message.tasks.length > 0 && !resetTasks">
                                <v-layout row wrap>
                                    <template v-for="task in message.tasks">
                                        <v-flex shrink :key="task">
                                            <v-menu open-on-hover offset-y nudge-left="150px">
                                                <v-chip
                                                        slot="activator"
                                                        small
                                                        label
                                                        color="secondary"
                                                        text-color="white"
                                                >
                                                    <v-icon left small>assignment_turned_in</v-icon>
                                                    Task #{{task}}
                                                </v-chip>
                                                <div style="width: 400px; background: var(--card-back); cursor: pointer">
                                                    <task-loading-container
                                                            v-if="typeof loadedTasks[task] == 'undefined'"
                                                            :task-id="task"
                                                    />
                                                    <task-container
                                                            v-else
                                                            :userInfo="userInfo"
                                                            :task="loadedTasks[task]"
                                                            :taskBar="false"
                                                            :showNav="false"
                                                            @taskSelected="taskSelected(loadedTasks[task])"
                                                    />
                                                </div>
                                            </v-menu>
                                        </v-flex>
                                    </template>
                                </v-layout>
                            </div>
                            <v-layout column style="overflow-x: hidden">
                                <v-flex shrink style="overflow-x: hidden">
                                    <v-layout row style="overflow-x: hidden" align-center>
                                        <v-flex shrink>
                                            <v-tooltip top>
                                                <v-icon
                                                        large
                                                        class="pa-2"
                                                        style="cursor: pointer"
                                                        slot="activator"
                                                        :class="message.starred ? 'red--text' : 'greyType--text text--lighten-2'"
                                                        @click="flagMessageFn(message)"
                                                >
                                                    flag
                                                </v-icon>
                                                <span>{{ message.starred ? 'Remove Flag' : 'Flag Message' }}</span>
                                            </v-tooltip>
                                        </v-flex>
                                        <v-flex style="overflow-x: hidden" class="">
                                            <span class="subheading font-weight-bold;">{{message.subject}}</span>
                                        </v-flex>
                                    </v-layout>
                                </v-flex>
                                <v-flex shrink style="overflow-x: hidden">
                                    <v-layout row style="overflow-x: hidden" align-center>
                                        <v-flex shrink class="pa-1">
                                            <v-avatar class="lighten-1" size="40" color="grey">
                                                <v-icon dark>alternate_email</v-icon>
                                            </v-avatar>
                                        </v-flex>

                                        <v-flex xs12 style="overflow-x: hidden" class="pl-1 pr-1">
                                            <v-layout style="overflow-x: hidden" column>
                                                <v-flex style="overflow-x: hidden" shrink>
                                                    <v-layout style="overflow-x: hidden" row align-center>
                                                        <v-flex xs12 style="overflow-x: hidden" class="text-truncate">
                                                            <template
                                                                    v-for="(text, i) in message.from.replace(/>/g, '').split('<')"
                                                            >
                                                <span
                                                        :class="text[text.length -1] == ' ' ? 'font-weight-bold' : 'font-weight-regular'"
                                                        class="subheading text-truncate" :key="i"
                                                >
                                                    {{ text }}
                                                </span>
                                                            </template>
                                                        </v-flex>
                                                        <v-flex>
                                            <span class="greyType--text caption text-truncate">
                                                {{ getLongDate(message.date) }}
                                            </span>
                                                        </v-flex>
                                                    </v-layout>
                                                </v-flex>
                                                <v-flex style="overflow-x: hidden" class="text-truncate" shrink v-if="toAddresses != null && toAddresses != ''">
                                                    <span class="caption ">{{`To: ${toAddresses}`}}</span>
                                                </v-flex>
                                                <v-flex style="overflow-x: hidden" class="text-truncate" shrink v-if="ccAddresses != null && ccAddresses != ''">
                                                    <span class="caption">{{ `CC: ${ccAddresses}` }}</span>
                                                </v-flex>
                                            </v-layout>
                                        </v-flex>

                                    </v-layout>

                                </v-flex>
                            </v-layout>
                        </v-flex>
                        <!--Message Body-->
                        <v-flex xs12 style="overflow-y: auto; overflow-x: auto">
                            <div class="fill-height">
                                <!--<iframe-->
                                <!--:src="htmlMailSrc"-->
                                <!--ref="microsoftFrame"-->
                                <!--style="width: 100%;"-->
                                <!--@load="iframeReset"-->
                                <!--frameborder="0"-->
                                <!--class="fill-height"-->
                                <!--&gt;-->
                                <!--</iframe>-->

                                <!--src="javascript:void(0);"-->
                                <iframe
                                        v-show="showFrame"
                                        src="javascript:void(0);"
                                        ref="microsoftFrame"
                                        style="width: 100%;"
                                        frameborder="0"
                                        class="fill-height"
                                        @load="iframeReset"
                                >
                                </iframe>
                            </div>
                        </v-flex>
                      
                        <!--Attachments-->
                        <v-flex shrink v-if="attachments != null">
                            <v-layout row wrap align-center>
                                <v-flex shrink v-if="!showEmbedded && embeddedAttachmentsLength > 0">
                                    <v-tooltip top>
                                        <v-btn
                                                :color="loadingAttachments ? 'grey' : 'secondary'"
                                                slot="activator"
                                                icon
                                                flat
                                                @click="showEmbedded = true"
                                                :loading="loadingAttachments"
                                        >
                                            {{`+${embeddedAttachmentsLength}`}}
                                        </v-btn>
                                        <span>Show Embedded Attachments</span>
                                    </v-tooltip>
                                </v-flex>
                                <template v-for="attachment in attachments">
                                    <v-flex shrink class="pa-1">
                                        <v-card
                                                hover
                                                :color="loadingAttachments ? 'grey' : 'primary'"
                                                class="white--text"
                                        >
                                            <v-layout row align-center>
                                                <template v-if="loadingAttachments">
                                                    <v-flex shrink class="pl-1">
                                                        <v-progress-circular
                                                                :size="16"
                                                                :width="2"
                                                                color="white"
                                                                indeterminate
                                                        />
                                                    </v-flex>
                                                    <v-flex class="pa-1 pr-2 white--text caption">
                                                        {{attachment.attName}}
                                                    </v-flex>
                                                </template>


                                                <template v-else>
                                                    <v-flex v-if="['pdf', 'PDF'].includes(attachment.attName.split('.').pop())" class="pa-1 pl-2">
                                                        <doc-view
                                                            :url="`${htmlMailBaseSrc + '/' + attachment.attName}`"
                                                        >
                                                            <v-tooltip top>
                                                                <v-icon
                                                                    style="cursor: pointer"
                                                                    small
                                                                    slot="activator"
                                                                    color="white"
                                                                    class="ma-0"
                                                                >
                                                                    visibility
                                                                </v-icon>
                                                                <span>View Attachment</span>
                                                            </v-tooltip>
                                                        </doc-view>
                                                    </v-flex>
                                                    <v-flex shrink class="pa-1">
                                                        <a
                                                                style="text-decoration: none"
                                                                :href="htmlMailBaseSrc + '/' + attachment.attName"
                                                                target="_blank">
                                                            <v-tooltip top>
                                                                <v-icon
                                                                        style="cursor: pointer"
                                                                        small
                                                                        slot="activator"
                                                                        color="white"
                                                                        class="ma-0"
                                                                >
                                                                    {{ 'attachment' }}
                                                                </v-icon>
                                                                <span>Download Attachment</span>
                                                            </v-tooltip>
                                                        </a>
                                                    </v-flex>
                                                    <a
                                                            style="text-decoration: none"
                                                            :href="htmlMailBaseSrc + '/' + attachment.attName"
                                                            target="_blank"
                                                    >
                                                        <v-flex class="pa-1 pr-2 white--text caption">
                                                            {{attachment.attName}}
                                                        </v-flex>
                                                    </a>
                                                </template>


                                            </v-layout>
                                        </v-card>
                                    </v-flex>
                                </template>

                            </v-layout>

                        </v-flex>
                    </v-layout>
                </v-card>
            </v-flex>
        </template>
    </v-layout>
</template>

<script>
    import {eventBus} from "../../../../main";
    import { codeBaseMixin } from "../../../../codeBaseMixin";
    import TaskContainer from "../../../../components/TaskContainer/TaskContainer";
    import TaskLoadingContainer from "../../../../components/TaskContainer/TaskLoadingContainer";
    import {feed} from "../../../../feedSocket";
    import DocView from "@/DocView/DocView";


    export default {
        components: {DocView, TaskLoadingContainer, TaskContainer},
        mixins: [codeBaseMixin],
        props: {
            message: Object,
            showLoading: Boolean,
            flagMessageFn: Function,
            userInfo: Object,
            showLinkOffice: Boolean,
            folderGroups: Array,
            newMessageFn: Function,
            getTaskHeader: Function,
            loadedMessages: Array,
            curView: String
        },
        watch: {
            curView: {

                immediate: true,
                handler(val) {

                }
            },

            message: {
                immediate: true,
                handler(newVal) {
                    this.showEmbedded = false;
                    if (newVal != null) {
                        this.loadMessageHtml();
                        this.showFrame = false;
                        if (Array.isArray(newVal.tasks) && newVal.tasks.length > 0) {
                            newVal.tasks.forEach((task) => {
                                this.getTaskHeader(task)
                                    .then((data) => {
                                        this.loadedTasks[data.taskID] = data
                                    })
                                    .catch(e => {console.log(e)})
                            })
                        }
                    }
                }
            },

            "message.tasks": {
                deep: true,
                immediate: false,
                handler(tasks) {
                    tasks.forEach((task) => {
                        if (typeof this.loadedTasks[task] == 'undefined') {
                            this.getTaskHeader(task)
                                .then((data) => {
                                    this.loadedTasks[data.taskID] = data
                                    this.resetTasks = true;
                                    this.$nextTick(() => {
                                        this.resetTasks = false;
                                    })
                                })
                                .catch(e => {console.log(e)})
                        }
                    })
                }
            }
        },
        data() {
            return {
                showEmbedded: false,
                downloadingMessageHTML: false,
                loadingHTML: false,
                showFrame: false,
                loadedTasks: [],
                resetTasks: false
            }
        },
        mounted() {
            this.showEmbedded = false;
            if (this.message != null) {
                this.loadMessageHtml();
                this.showFrame = false;
            }
        },
        created() {
            feed.on('microsoftOfficeMessagesDownload', (data) => {
                if (data.includes(this.message.id)) {
                    this.loadMessageHtml();
                }
            });
        },
        methods: {

            quickTask(qt) {
                this.$emit('setQT', {workflow: qt.config.workflow, folder: qt.config.folder});
                this.$emit('createTaskOffice', this.message);
                this.$emit('showLinkOffice', false)
            },

            taskSelected(task) {
                eventBus.$emit('gotoTask', task)
            },

            officeMailReplyCompose(composeType) {
                this.$emit('showLinkOffice', false);
                this.downloadingMessageHTML = true;

                this.fetch({method: 'GET', url:`/officecache/${this.userInfo.userID}-${this.message.id}/${this.userInfo.userID}-${this.message.id}.html`})
                    .then((data) => {
                        this.downloadingMessageHTML = false;

                        let srcAddress = `/officecache/${this.userInfo.userID}-${this.message.id}/${this.userInfo.userID}-${this.message.id}.html`

                        this.newMessageFn(composeType, this.message, data)
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e)
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                        this.downloadingMessageHTML = false;
                    })
            },

            iframeReset(event) {
                let doc = this.$refs.microsoftFrame;
                // doc.html = this.messageHtml;
                let content = (doc.contentWindow || doc.contentDocument)
                if (content.document)content = content.document;
                doc = content;
                let head = doc.head;
                //Set The Style
                let style = doc.createElement('style');
                head.appendChild(style);
                style.type = 'text/css';
                style.appendChild(document.createTextNode(`
                    body {
                        font-family: sans-serif;
                    }
                        body::-webkit-scrollbar-track {
                        background-color: #ededed;
                    }

                    body::-webkit-scrollbar {
                        width: 8px;
                        height: 8px;
                        background-color: #F5F5F5;
                    }

                    body::-webkit-scrollbar-thumb {
                        background-color: #00897B;
                    }`));
                var base = doc.createElement('base');
                base.href = `${this.baseServerAddress}officecache/${this.userInfo.userID}-${this.message.id}/`;
                head.appendChild(base);

                this.$refs.microsoftFrame.contentWindow.document.body.innerHTML = this.$refs.microsoftFrame.contentWindow.document.body.innerHTML;

                this.showFrame = true;
            },

            loadMessageHtml() {
                this.loadingHTML = true;
                this.fetch({method: 'GET', url: this.htmlMailSrc2})
                    .then((data) => {

                        // clear content
                        try {
                            this.$refs.microsoftFrame.src = "about:blank";
                            // write new content

                            this.$refs.microsoftFrame.contentWindow.document.open();
                            this.$refs.microsoftFrame.contentWindow.document.write(data);
                            this.$refs.microsoftFrame.contentWindow.document.close();
                        } catch (e) {
                            //Some Catch Code
                        }

                        this.loadingHTML = false;
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e)
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                        this.loadingHTML = false;
                    })
            }
        },
        computed: {
            sortedQuickTasks() {
                return this.userInfo.quickTasks.filter(() => true).sort((a,b) => a['config']['order'] < b['config']['order'] ? -1 : 1);
            },

            loadingAttachments() {
                if (typeof this.message == 'object' && this.message !== null && typeof this.message.id != 'undefined') {
                    return !this.loadedMessages.includes(this.message.id)
                } else {
                    return false
                }

            },

            attachments() {
                if (this.message.attachments.length > 0) {
                    return this.showEmbedded ? this.message.attachments : this.message.attachments.filter((obj) => obj.type != 'embedded')
                } else {
                    return null
                }
            },
            embeddedAttachmentsLength() {
                try {
                    return this.message.attachments.filter((obj) => obj.type == 'embedded').length
                } catch (e) {
                    // eslint-disable-next-line
                    console.log(e)
                  return []
                }
            },
            toAddresses() {
                try {
                    let addresses = this.message.to.split(',');
                    addresses = addresses.map((address) => {
                        let tSplit =  address.split('<')
                        return tSplit[0].trim()
                    });
                    return addresses.join(', ')
                } catch (e) {
                    return null
                }
            },

            ccAddresses() {
                try {
                    let addresses = this.message.cc.split(',');
                    addresses = addresses.map((address) => {
                        let tSplit =  address.split('<')
                        return tSplit[0].trim()
                    });
                    return addresses.join(', ')
                } catch (e) {
                    return null
                }
            },

            htmlMailBaseSrc() {
                try {
                    return this.serverAddress + '/officecache/' + this.userInfo.userID + '-' + this.message.id
                } catch(e) {
                    return null
                }
            },

            htmlMailSrc() {
                try {
                    return this.htmlMailBaseSrc + '/' + this.userInfo.userID + '-' + this.message.id + '.html'
                } catch(e) {
                    return null
                }
            },

            htmlMailSrc2() {
                try {
                    return '/officecache/' + this.userInfo.userID + '-' + this.message.id + '/' + this.userInfo.userID + '-' + this.message.id + '.html'
                } catch(e) {
                    return null
                }
            }
        }
    }
</script>

<style scoped>
</style>
