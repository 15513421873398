<template>
    <div class="doc-preview fill-height" style="position: relative">
<!--        <sign-flow-preview-pages-->
<!--                :url="src"-->
<!--                v-if="src"-->
<!--        />-->

<!--        <div class="fill-height scrollable">-->
<!--            <template v-for="(page, i) in previewParts">-->
<!--                <div class="page docView" :key="'PAGE:' + i">-->
<!--                    <v-layout column fill-height class="pa-4">-->
<!--                        <v-flex shrink v-if="page.h !== null" ref="pageHeader">-->
<!--                            <div v-html="page.h"></div>-->
<!--                        </v-flex>-->
<!--                        <v-flex class="scrollable" xs12 v-if="page.b !== null">-->
<!--                            <div v-html="page.b"></div>-->
<!--                        </v-flex>-->
<!--                        <v-flex shrink v-if="page.f !== null" ref="pageFooter">-->
<!--                            <div v-html="page.f"></div>-->
<!--                        </v-flex>-->
<!--                    </v-layout>-->
<!--                </div>-->
<!--            </template>-->
<!--        </div>-->

        <div class="fill-height" ref="pdfView" v-show="!loading"></div>

        <div class="c-d-flex c-align-center c-justify-center fill-height" v-show="loading">
            <v-progress-circular
                    :size="40"
                    :width="5"
                    color="primary"
                    indeterminate
            ></v-progress-circular>
        </div>

        <div class="pa-2 text-xs-center" style="position: absolute; width: 100%; bottom: 0">
            <v-btn
                    color="secondary"
                    class="ma-0"
                    @click="runPreview"
                    :disabled="allowGenerate || generatingDoc"
                    :loading="generatingDoc"
            >
                <v-icon left>history_edu</v-icon>
                Save & Generate Document
            </v-btn>
        </div>
    </div>

</template>

<script>
    import {blankString} from "../../../../../../../codeFunctions";
    import {codeBaseMixin} from "../../../../../../../codeBaseMixin";
    import axios from "axios";
    import tokenMan from '../../../../../../../tokenManSite'
    import SignFlowPreviewPages
        from "@/Tasks/TaskView V2/components/TaskViewDetail/Components/FieldTypeComponents/Document Template/SignFlowPreviewPages";
    import pdf from 'pdfobject'

    export default {
        components: {SignFlowPreviewPages},
        mixins: [codeBaseMixin],
        props: {
            structure: Object,
            userInfo: Object,
            flatWorkflowFields: Object,
            flatDatasetSelectFields: Object,
            flatDatasetFieldsByMemberID: Object,
            flatSignatures: Object,
            usedSignatures: Array,
            task: Object,
            step: Object,
            field: Object,
            sendSignatureMethod: Number,
            sendSignatureAddress: String,
            sendSignatureServer: Number
        },
        data() {
            return {
                generatingDoc: false,
                src: null,
                previewID: 0,
                embeddedElement: null,
                loading: false
            }
        },

        watch: {
            previewHtml: {
                immediate: true,
                handler(val) {
                    this.previewDoc()
                }
            }
        },
        methods: {
            previewDoc() {
                this.loading = true
                this.$newReqDownloadFile(
                        "post",
                        `signFlow/sign/previewPDF`,
                        {
                            bodyHTML: this.previewHtml
                        },
                        'Preview.pdf',
                        'data'
                )
                        .then((data) => {
                            try {

                                let file = new File([data], 'preview.pdf', {type: 'application/pdf'});
                                let url = window.URL.createObjectURL(file)

                                this.embeddedElement = pdf.embed(url, this.$refs.pdfView)
                                this.$nextTick(() => {
                                    this.loading = false
                                })
                            } catch(e) {
                                console.log(e)
                            }

                        })
                        .catch(e => {
                            console.log(e)
                        })
                // .finally(() => {
                //     if (previewID === this.previewID)
                //         this.loadingPreview = false;
                // })
            },
            runPreview() {
                if (this.usedSignatures.length > 0) {
                    // Generate signature request
                    let oBody = {};
                    Object.assign(oBody, this.structure);
                    oBody.usedSignatures = this.usedSignatures

                    this.generatingDoc = true;
                    this.fetch({
                        method: 'POST',
                        url:`/dtManage/template/generateSignatureFlow/${this.userInfo.entityID}/${this.userInfo.userID}/${this.task.taskID}/${this.field.fieldID}/${this.sendSignatureMethod}/${this.sendSignatureAddress}/${this.sendSignatureServer}`,
                        body: oBody
                    })
                        .then((data) => {
                            this.generatingDoc = false;
                            this.$emit('setFlowTemplate', data.signFlow)
                            this.$emit('input', {
                                genComplete: false,
                                docID: null,
                                flowID: data.flowID
                            })
                        })
                        .catch((e) => {
                            // eslint-disable-next-line
                            console.log(e);
                            this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
                            this.generatingDoc = false;
                        })

                } else {
                // Generate And Sign Doc

                    let hHeight = 0;
                    try {hHeight = this.$refs.pageHeader[0].clientHeight} catch (e) {/* eslint-disable-next-line*/console.log(e)}

                    let fHeight = 0;
                    try {fHeight = this.$refs.pageFooter[0].clientHeight} catch (e) {/* eslint-disable-next-line*/console.log(e)}

                this.generatingDoc = true;
                this.fetch({
                    method: 'POST',
                    url:`/dtManage/template/generateSimpleDocument/${this.userInfo.entityID}/${this.userInfo.userID}/${this.task.taskID}/${this.step.stepID}/${this.field.description}/${hHeight}/${fHeight}`,
                    body: this.structure
                })
                    .then((data) => {
                        this.generatingDoc = false;
                        this.task.structure.documents.push(data);
                        this.$emit('input', {
                            genComplete: true,
                            docID: data.documentDBID
                        })
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e);
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
                        this.generatingDoc = false;
                    })
                }
            },
        },
        computed: {
            allowGenerate() {

                let dissable = false

                // Workflow Fields
                this.structure.fields.workflowFields.forEach(field => {
                    if (field.field.required === 1 && blankString(field.field.value) && !dissable) {
                        dissable = true;
                    }
                })

                // Dataset Fields
                if (!dissable) {
                    this.structure.fields.datasetFields.forEach(field => {
                        if (((field.dsSelectField.value == null || field.dsSelectField.value === '') || (field.field.required === 1 && blankString(field.field.value))) && !dissable) {
                            dissable = true;
                        }
                    })
                }

                //Input Fields
                if (!dissable) {
                    this.structure.fields.inputFields.forEach(field => {
                        if (!dissable && blankString(field.field.value)) {
                            dissable = true;
                        }
                    })
                }

                //Signature Fields
                if (!dissable) {
                    this.usedSignatures.forEach(field => {
                        if (!blankString(field.OTPError) || !blankString(field.OTPError)){
                            dissable = true;
                        }
                    })
                }

                return dissable
            },

            previewParts() {

                let oFieldsByGuid = {};

                this.structure.fields.workflowFields.forEach(field => {
                    oFieldsByGuid[field.uuid] = field.field.value
                });

                this.structure.fields.datasetFields.forEach(field => {
                    try {
                        oFieldsByGuid[field.uuid] = field.field.value
                    } catch (e) {
                        oFieldsByGuid[field.uuid] = null
                    }
                });

                this.structure.fields.inputFields.forEach(field => {
                    oFieldsByGuid[field.uuid] = blankString(field.field.value) ? '' : field.field.value.replace(/\n/g, '<br>')
                });

                this.structure.fields.signatures.forEach(field => {
                    oFieldsByGuid[field.uuid] = field.fieldStr;
                });

                this.structure.fields.signatureInputFields.forEach(field => {
                    oFieldsByGuid[field.uuid] = field.fieldStr;
                });

                let arrUUIDList = Object.keys(oFieldsByGuid)

                return this.previewPartsJoined.map(obj => {
                    let oSend = {h:obj.h, b:obj.b, f:obj.f};

                    if (!blankString(obj.h)) {
                        arrUUIDList.forEach(uuid => {
                            oSend.h = oSend.h.replace(`{{${uuid}}}`, oFieldsByGuid[uuid] != null ? oFieldsByGuid[uuid] : '')
                        })
                    }
                    if (!blankString(obj.b)) {
                        arrUUIDList.forEach(uuid => {
                            oSend.b = oSend.b.replace(`{{${uuid}}}`, oFieldsByGuid[uuid] != null ? oFieldsByGuid[uuid] : '')
                        })
                    }
                    if (!blankString(obj.f)) {
                        arrUUIDList.forEach(uuid => {
                            oSend.f = oSend.f.replace(`{{${uuid}}}`, oFieldsByGuid[uuid] != null ? oFieldsByGuid[uuid] : '')
                        })
                    }

                    return oSend;
                });
            },

            previewHtml() {
                // {id: 0, description: 'Document Body'},
                // {id: 1, description: 'Header'},
                // {id: 2, description: 'Footer'},
                // {id: 3, description: 'First Page Header'},
                // {id: 4, description: 'First Page Footer'},
                // {id: 5, description: 'First Page'},
                // {id: 6, description: 'Last Page'},

                let documentBody = this.structure.parts.filter(part => part.type === 0).pop();

                documentBody = typeof documentBody == "undefined" ? null : documentBody.sections.map(section => {return section.html}).join('<br>');

                let oFieldsByGuid = {};
                this.structure.fields.workflowFields.forEach(field => {
                    if (field.field.fieldType === 4)
                        oFieldsByGuid[field.uuid] = field.field.value.replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                    else
                        oFieldsByGuid[field.uuid] = field.field.value
                });

                this.structure.fields.datasetFields.forEach(field => {
                    try {
                        oFieldsByGuid[field.uuid] = field.field.value
                    } catch (e) {
                        oFieldsByGuid[field.uuid] = null
                    }
                });

                this.structure.fields.inputFields.forEach(field => {
                    oFieldsByGuid[field.uuid] = blankString(field.field.value) ? '' : field.field.value.replace(/\n/g, '<br>')
                });

                this.structure.fields.signatures.forEach(field => {
                    oFieldsByGuid[field.uuid] = field.fieldStr;
                });

                this.structure.fields.signatureInputFields.forEach(field => {
                    oFieldsByGuid[field.uuid] = field.fieldStr;
                });

                let arrUUIDList = Object.keys(oFieldsByGuid)

                arrUUIDList.forEach(uuid => {
                    documentBody = documentBody.replace(`{{${uuid}}}`, oFieldsByGuid[uuid] != null ? oFieldsByGuid[uuid] : '')
                })

                return documentBody;
            },

            previewPartsJoined() {

                // {id: 0, description: 'Document Body'},
                // {id: 1, description: 'Header'},
                // {id: 2, description: 'Footer'},
                // {id: 3, description: 'First Page Header'},
                // {id: 4, description: 'First Page Footer'},
                // {id: 5, description: 'First Page'},
                // {id: 6, description: 'Last Page'},

                let documentBody = this.structure.parts.filter(part => part.type === 0).pop();
                let header = this.structure.parts.filter(part => part.type === 1).pop();
                let footer = this.structure.parts.filter(part => part.type === 2).pop();
                let firstPageHeader = this.structure.parts.filter(part => part.type === 3).pop();
                let firstPageFooter = this.structure.parts.filter(part => part.type === 4).pop();
                let firstPage = this.structure.parts.filter(part => part.type === 5).pop();
                let lastPage = this.structure.parts.filter(part => part.type === 6).pop();


                 documentBody = typeof documentBody == "undefined" ? null : documentBody.sections.map(section => {return section.html}).join('<br>');
                 header = typeof header == "undefined" ? null : header.sections.map(section => {return section.html}).join('<br>');
                 footer = typeof footer == "undefined" ? null : footer.sections.map(section => {return section.html}).join('<br>');
                 firstPageHeader = typeof firstPageHeader == "undefined" ? null : firstPageHeader.sections.map(section => {return section.html}).join('<br>');
                 firstPageFooter = typeof firstPageFooter == "undefined" ? null : firstPageFooter.sections.map(section => {return section.html}).join('<br>');
                 firstPage = typeof firstPage == "undefined" ? null : firstPage.sections.map(section => {return section.html}).join('<br>');
                 lastPage = typeof lastPage == "undefined" ? null : lastPage.sections.map(section => {return section.html}).join('<br>');



                let firstPageHTML = firstPage;
                let bodyHTML = documentBody;
                let lastPageHTML = lastPage;

                let firstPageView = {h: null, b: null, f: null};
                let bodyPageView = {h: null, b: null, f: null};
                let lastPageView = {h: null, b: null, f: null};

                firstPageView.b = firstPageHTML;
                bodyPageView.b = bodyHTML;
                lastPageView.b = lastPageHTML;

                if (!blankString(firstPageHeader) && !blankString(firstPageHTML)) {
                    firstPageView.h = firstPageHeader
                }
                if (!blankString(firstPageFooter) && !blankString(firstPageHTML)) {
                    firstPageView.f = firstPageFooter
                }

                if (!blankString(header) && !blankString(bodyHTML)) {
                    bodyPageView.h = header
                    if (!blankString(lastPageHTML)) {lastPageView.h = header}
                }
                if (!blankString(footer) && !blankString(bodyHTML)) {
                    bodyHTML = `${bodyHTML}${footer}`;
                    bodyPageView.f = footer
                    if (!blankString(lastPageHTML)) {lastPageView.f = footer}
                }

                let arrPages = [];

                if (!blankString(firstPageView.b)) {
                    arrPages.push(firstPageView)
                }
                if (!blankString(bodyPageView.b)) {
                    arrPages.push(bodyPageView)
                }
                if (!blankString(lastPageView.b)) {
                    arrPages.push(lastPageView)
                }

                return arrPages;
            }
        },

    }
</script>

<style scoped>
    .doc-preview {
        background: rgb(204,204,204);
    }
    .page {
        background: white;
        display: block;
        margin: 0 auto;
        margin-top: 0.5cm;
        margin-bottom: 0.5cm;
        box-shadow: 0 0 0.5cm rgba(0,0,0,0.5);
        width: 21cm;
        height: 29.7cm;
    }
</style>
