<template>
    <div>
<!--Icon-->
        <v-menu offset-y :disabled="announcements.length === 0" v-model="menu" :close-on-content-click="false">
            <template v-slot:activator="{ on }">
                <v-btn
                    icon
                    flat
                    large
                    color="white"
                    class="pa-0 ma-0"
                    slot="activator"
                    v-on="on"
                >
                    <v-badge
                        overlap
                        :color="badgeCount == 0 ? 'blue' : 'secondary'"
                        left
                        class="mt-2"
                    >
                        <template slot="badge">
                            <span class="white--text caption">{{ badgeCount }}</span>
                        </template>
                        <v-icon large color="white" class="nav-item">notifications</v-icon>
                    </v-badge>
                </v-btn>
            </template>
            <v-card width="400px">
                <template v-if="menu">
                    <template v-for="ann in announcementSorted">
                        <div :key="ann.id">
                            <announcement-item :ann="ann" @removeNotification="removeNotification(ann)"/>
                        </div>
                    </template>
                </template>
            </v-card>
        </v-menu>


    </div>
</template>

<script>
import {mapGetters} from 'vuex';
import {codeBaseMixin} from "@/codeBaseMixin";
import AnnouncementItem from "@/Announcemnts/AnnouncementItem";

export default {
    components: {AnnouncementItem},
    mixins: [codeBaseMixin],
    props: {
        val: Boolean,
    },
    data() {
        return {
            announcements: [],
            menu: false,
        }
    },
    watch: {
        userInfo: {
            immediate: true,
            handler(val) {
                if (val && typeof val.userID !== 'undefined')
                    this.getAnnouncements();
            }
        }
    },
    methods: {
        removeNotification(ann) {
            this.fetch({method: 'get', url:`/announcements/${this.userInfo.entityID}/${this.userInfo.userID}/${ann.id}`})
                .then(() => {
                    ann.notification = 0;
                })
                .catch((e) => {
                    // eslint-disable-next-line
                    console.log(e)
                })
        },
        getAnnouncements() {
            this.fetch({method: 'GET', url:`/announcements/${this.userInfo.entityID}/${this.userInfo.userID}`})
                .then((data) => {
                    this.announcements = data
                })
                .catch((e) => {
                    // eslint-disable-next-line
                    console.log(e)
                })
        }
    },
    computed: {

        announcementSorted() {
            return [...this.announcements].sort((a,b) => b.id-a.id)
        },

        ...mapGetters('userInfo', ['userInfo']),
        badgeCount() {
            return this.announcements.filter(ann => ann.notification === 1).length;
        }
    },
}
</script>

<style scoped>

</style>