import store from "@/Store/store"
import {DatasetBinder} from "@/Tasks/TaskView V2/components/TaskViewDetail/datasetBinder";
import {blankString, objectifyArr} from "@/codeFunctions";



export class EvalBinder {
    task = null;
    localData = null;
    detailController = null;
    binder = null
    binderFields = []


    get userInfo() {
        return store.getters["userInfo/userInfo"];
    }

    constructor(localData, task, detailController, binder) {
        this.task = task;
        this.localData = localData;
        this.detailController = detailController;
        this.binder = binder
        this.binderFields = this.task.structure.fields
            .filter(field => field.fieldType === 22)
            .map(field => {
                return {
                    field: field,
                    status: 'init',
                    lastValue: field.value
                }
            })
        this.binder.emitter.on('loaded', () => {
            this.runForFields()
        })
    }

    runForFields() {
        for (const binderRef of this.binderFields) {
            try {
                this.runField(binderRef)
            } catch (e) {
                // console.log(e)
            }
        }
    }

    runField(binderRef, log = false) {
        const workflowRef = objectifyArr(this.task.structure.fields, "fieldID")

        const dsRef = Object.keys(this.binder.datasets).reduce((obj, memberId) => {
            obj[memberId] = objectifyArr(this.binder.datasets[memberId].fields, "datasetFieldID")
            return obj
        }, {})


        const lookup = typeof binderRef.field.lookup === 'string' ? JSON.parse(binderRef.field.lookup) : binderRef.field.lookup


        if (log)
            console.log({lookup})

        const evalStr = lookup.evalStr
            .replace(/\\"/g,`"`)
            .replace(/&amp;/gm,`&`)
            .replace(/&lt;/gm,`<`)
            .replace(/&gt;/gm,`>`)
            .replace(/&nbsp;/gm,` `)
        const refInfo = this.htmlToText(evalStr, true)

        if (log)
            console.log({refInfo})

        binderRef.status = 'Success'
        binderRef.wf = refInfo.fields.workflow.map((id) => {
            if (!workflowRef[id]) {
                if (log) console.log("Failed:", `Couldn't Find Workflow Field - ${id}`)
                binderRef.status = 'fail'
            }
            return workflowRef[id] ? workflowRef[id] : null
        })

        binderRef.ds = refInfo.fields.dataset.map((refObj) => {
            const memberID = workflowRef[refObj.dsSelectID] && workflowRef[refObj.dsSelectID].value ? workflowRef[refObj.dsSelectID].value : null
            if (!memberID) {
                if (log) console.log("Failed:", `Couldn't Find Dataset Select Field - ${refObj.dsSelectID}`)
                binderRef.status = 'fail'
                return null
            }
            const memberField = dsRef[memberID] && dsRef[memberID][refObj.dsFieldID] ? dsRef[memberID][refObj.dsFieldID] : null
            if (memberID && lookup.force) {
                if (log) console.log("Warning:", `Couldn't Find Dataset Field - MemberID: ${memberID}, Dataset Field ID: ${refObj.dsFieldID}`)
            } else if (!memberField) {
                if (log) console.log("Failed:", `Couldn't Find Dataset Field - MemberID: ${memberID}, Dataset Field ID: ${refObj.dsFieldID}`)
                binderRef.status = 'fail'
                return null
            }
            return {field:memberField, ref: refObj}
        })


        if (binderRef.status === 'fail')
            return

        // If Not Fail Then Build
        let str = refInfo.withTags.replace(/<div>|<\/div>|<br>|<\/br>/gm, '\n')
        binderRef.wf.forEach(wf => {

            if (lookup.force) {
                const reg = new RegExp(`\{\{W-${wf.fieldID}}}`, 'gm')
                str = str.replace(reg, wf.value || 'null')
            } else {
                if (wf.value === null) {
                    if (log) console.log("Failed:", `Couldn't Find A Value For Workflow Field ${wf.description}`)
                    binderRef.status = 'fail'
                } else {
                    const reg = new RegExp(`\{\{W-${wf.fieldID}}}`, 'gm')
                    str = str.replace(reg, wf.value)
                }
            }
        })

        binderRef.ds.forEach(ds => {

            if (lookup.force) {
                const reg = new RegExp(`\{\{D-${ds.ref.dsSelectID}-${ds.ref.dsFieldID}}}`, 'gm')
                str = str.replace(reg, !ds || !ds.field || ds.field.value === null ? 'null' : ds.field.value)
            } else {
                if (!ds || ds.field.value === null) {
                    if (log) console.log("Failed:", `Couldn't Find A Value For Dataset Field - Select Field: ${ds.ref.dsSelectID}, Dataset Field: ${ds.ref.dsFieldID}`)
                    binderRef.status = 'fail'
                } else {
                    const reg = new RegExp(`\{\{D-${ds.ref.dsSelectID}-${ds.ref.dsFieldID}}}`, 'gm')
                    str = str.replace(reg, ds.field.value)
                }
            }

        })

        if (binderRef.status !== 'fail') {
            try {
                if (log)
                    console.log(str)
                const evaluation = (eval(str)).toString()
                if (binderRef.field.value !== evaluation) {
                    console.log({binderRef})
                    binderRef.field.value = evaluation
                }
            } catch (e) {
                if (log)
                    console.log(str, e)
                binderRef.status = 'fail'
            }
        }
    }

    htmlToText(html, includeReplaceValues = false) {
        let fields = {
            workflow: [],
            dataset: [],
        };

        // Step 1 Strip out Fields

        // Workflow Fields
        //`<div contenteditable="false" style="display: inline; border-radius: 3px" class="pa-1 white--text caption red" id="f-W-V-${item.id}">${item.description}</div>`
        let tWkfSplit = html.split(`<div contenteditable="false" style="display: inline; border-radius: 3px" class="pa-1 white--text caption red" id="f-W`)
        tWkfSplit = tWkfSplit.map(part => {
            // Check if Valid Tag
            if (!blankString(part) && part.length > 3 && part.substr(0,3) === '-V-') {
                let fieldName = part.substring(3, part.indexOf('"'));
                let postTag = part.substring(part.indexOf('</div>') + 6, part.length);
                fields.workflow.push(parseInt(fieldName));
                if (includeReplaceValues) {
                    postTag = `{{W-${fieldName}}}` + postTag;
                }
                return postTag
            } else {
                return part
            }
        })

        // Dataset Fields
        //`<div contenteditable="false" style="display: inline; border-radius: 3px" class="pa-1 white--text caption blue" id="f-D-V-1-5">Some Description</div>`
        let tDSSplit = tWkfSplit.join('').split(`<div contenteditable="false" style="display: inline; border-radius: 3px" class="pa-1 white--text caption blue" id="f-D`);
        tDSSplit = tDSSplit.map(part => {
            // Check if Valid Tag
            if (!blankString(part) && part.length > 3 && part.substr(0,3) === '-V-') {
                let fieldName = part.substring(3, part.indexOf('"'));

                let postTag = part.substring(part.indexOf('</div>') + 6, part.length);

                fields.dataset.push({
                    dsSelectID: parseInt(fieldName.substring(0, fieldName.indexOf('-'))),
                    dsFieldID: parseInt(fieldName.substring(fieldName.indexOf('-') + 1, fieldName.length))
                });

                if (includeReplaceValues) {
                    postTag = `{{D-${fieldName}}}` + postTag;
                }
                return postTag
            } else {
                return part
            }
        })

        let tBeforeTagReplace = tDSSplit.join('');

        //Split Out Tags
        let tAfterTags = tDSSplit.join('').split('<div>').join('\n').split('</div>').join('').split('<br>').join(`\n`).split('&nbsp;').join(' ');

        return {
            fields: fields,
            withTags: tBeforeTagReplace,
            afterTags: tAfterTags
        }
    }




}