<template>
    <v-dialog v-model="showManager" width="800px" persistent>
        <v-card height="500px">
            <div class="pa-2 text-xs-center" v-if="loadingLists">
                <v-progress-circular
                        :size="40"
                        :width="5"
                        color="primary"
                        indeterminate
                />
            </div>
            <v-layout v-else column fill-height class="scrollable">
                <v-flex shrink>
                    <v-card-title class="title-color pa-0 ma-0">
                        <v-layout row align-center>
                            <v-flex shrink class="px-2">
                                <v-icon color="white">format_list_bulleted</v-icon>
                            </v-flex>
                            <v-flex xs12 class="pa-2 subheading white--text">
                                {{title}}
                            </v-flex>
                            <v-flex shrink class="px-2">
                                <v-btn
                                        small
                                        icon
                                        flat
                                        color="white"
                                        class="pa-0 ma-0"
                                        @click="$emit('dismiss', true)"
                                >
                                    <v-icon small>close</v-icon>
                                </v-btn>
                            </v-flex>
                        </v-layout>
                    </v-card-title>
                    <v-divider style="background: var(--v-primary-darken4)"></v-divider>
                </v-flex>
                <v-flex xs12 class="scrollable">
                    <v-layout row fill-height class="scrollable">
                        <v-flex xs6 class="scrollable">
                            <v-layout column fill-height class="scrollable">
                                <v-flex shrink class="px-2">
                                    <v-layout row align-center>
                                        <v-flex xs12 class="py-2 body-2">
                                            Lists
                                        </v-flex>
                                        <v-flex shrink>
                                            <v-tooltip top>
                                                <v-btn
                                                        icon
                                                        flat
                                                        class="pa-0 ma-0"
                                                        color="secondary"
                                                        slot="activator"
                                                        small
                                                        @click="showNewList = true"
                                                >
                                                    <v-icon small>add</v-icon>
                                                </v-btn>
                                                <span>Add New Select List</span>
                                            </v-tooltip>
                                        </v-flex>
                                        <app-input-dlg
                                                :show-dialog="showNewList"
                                                title="New List"
                                                body="List Description:"
                                                :cancel-button="true"
                                                :show-input-box="true"
                                                :buttons="['Add List']"
                                                :call-backs="[ newList ]"
                                                @dismiss="showNewList = false"
                                        />
                                    </v-layout>
                                </v-flex>
                                <v-divider style="background: var(--v-primary-darken4)"></v-divider>
                                <v-flex shrink class="px-2">
                                    <v-text-field
                                            label="Search Lists"
                                            solo
                                            clearable
                                            prepend-icon="search"
                                            color="primary"
                                            flat
                                            :hide-details="true"
                                            v-model="listFilter"
                                            class="pa-0 ma-0"
                                            ref="searchListField"
                                    ></v-text-field>
                                </v-flex>

                                <v-flex xs12 class="scrollable">
                                    <template v-for="list in orderedSelectLists">
                                        <v-layout class="hide-parent" @click="selectedList = list" :key="`SelectListManager:${list.id}`" row align-center :class="selectedList != null && selectedList.id == list.id ? 'selected-item' : 'hover-item'" style="border-bottom: 1px solid #EEEEEE; cursor: pointer">
                                            <v-flex shrink class="pl-2" v-if="selectable">
                                                <v-icon
                                                        class="pa-0 ma-0"
                                                        small
                                                        :color="selectedList != null && selectedList.id == list.id ? 'primary' : 'grey'"
                                                >
                                                    {{selectedList != null && selectedList.id == list.id ? 'check_box' : 'check_box_outline_blank'}}
                                                </v-icon>
                                            </v-flex>
                                            <v-flex xs12 class="caption py-2 pl-2" :class="selectedList != null && selectedList.id == list.id ? 'body-2' : ''">
                                                {{list.description}}
                                            </v-flex>

                                            <v-flex shrink class="pl-2 hide-child">
                                                <v-tooltip top>
                                                    <v-btn
                                                            color="primary"
                                                            slot="activator"
                                                            small
                                                            class="pa-0 ma-0"
                                                            flat
                                                            icon
                                                            @click="copyItems(list)"
                                                    >
                                                        <v-icon small>file_copy</v-icon>
                                                    </v-btn>
                                                    <span>Copy List Items</span>
                                                </v-tooltip>
                                            </v-flex>

                                            <v-flex shrink class="pl-2 hide-child">
                                                <v-tooltip top>
                                                    <v-btn
                                                            color="primary"
                                                            slot="activator"
                                                            small
                                                            class="pa-0 ma-0"
                                                            flat
                                                            icon
                                                            @click="renameRunStart(list)"
                                                    >
                                                        <v-icon small>edit</v-icon>
                                                    </v-btn>
                                                    <span>Rename List</span>
                                                </v-tooltip>
                                            </v-flex>
                                        </v-layout>
                                    </template>
                                    <app-input-dlg
                                            :show-dialog="showRenameList"
                                            title="Rename List"
                                            body="New List Description:"
                                            :cancel-button="true"
                                            :show-input-box="true"
                                            :buttons="['Rename']"
                                            :call-backs="[ renameListRunEnd ]"
                                            :default-input="renameList.description"
                                            @dismiss="showRenameList = false"
                                    />
                                </v-flex>
                            </v-layout>
                        </v-flex>
                        <v-flex xs6 class="scrollable" style="border-left: 1px solid var(--v-primary-darken3)">
                            <v-layout column fill-height class="scrollable">
                                <v-flex shrink>
                                    <v-layout row align-center >
                                        <v-flex xs12 class="py-2 body-2 pl-2">
                                            List Items
                                        </v-flex>
                                        <v-flex shrink class="pr-2">
                                            <v-tooltip top>
                                                <v-btn
                                                        icon
                                                        flat
                                                        class="pa-0 ma-0"
                                                        color="secondary"
                                                        slot="activator"
                                                        small
                                                        :disabled="selectedList == null"
                                                        @click="showNewListMember = true"
                                                >
                                                    <v-icon small>add</v-icon>
                                                </v-btn>
                                                <span>Add New Item</span>
                                            </v-tooltip>

                                            <v-dialog v-model="showNewListMember" :width="400" persistent v-if="showNewListMember">
                                                <v-card flat class="">
                                                        <v-card-title class="pa-1 pl-2 primary">
                                                            <span class="subheading font-weight-light white--text">Add List Item</span>
                                                            <v-spacer></v-spacer>
                                                            <v-btn
                                                                    :disabled="loadingNewListMember"
                                                                    small
                                                                    icon
                                                                    flat
                                                                    color="white"
                                                                    class="pa-0 ma-0"
                                                                    @click="showNewListMember = false"
                                                            >
                                                                <v-icon small>close</v-icon>
                                                            </v-btn>
                                                        </v-card-title>
                                                        <v-divider style="background: var(--v-primary-darken4)"></v-divider>

                                                    <v-card-text style="max-height: 200px; overflow-y: auto">
                                                        <div class="body-1 greyType--text mb-2">{{ `Add List Item to: ${selectedList.description}` }}</div>
                                                            <v-textarea
                                                                    label="List Item"
                                                                    single-line
                                                                    v-model="importString"
                                                                    color="primary"
                                                                    hide-details
                                                                    style="width: 100%"
                                                                    class="ma-0 pa-0"
                                                                    ref="inputDlgTextField"
                                                                    rows="1"
                                                                    auto-grow
                                                                    :readonly="loadingNewListMember"
                                                                    autofocus
                                                            ></v-textarea>
                                                    </v-card-text>

                                                    <v-divider style="background: var(--v-primary-darken4)"></v-divider>

                                                    <v-card-actions>
                                                        <v-spacer></v-spacer>
                                                        <v-btn
                                                                flat
                                                                color="secondary"
                                                                dark
                                                                @click="newListMember"
                                                                :disabled="loadingNewListMember"
                                                                :loading="loadingNewListMember"
                                                        >
                                                            Add List Item
                                                        </v-btn>
                                                    </v-card-actions>

                                                </v-card>
                                            </v-dialog>

<!--                                            <app-input-dlg-->
<!--                                                    v-if="selectedList != null"-->
<!--                                                    :show-dialog="showNewListMember"-->
<!--                                                    title="New List Item"-->
<!--                                                    :body="`Add List Item to: ${selectedList.description}`"-->
<!--                                                    :cancel-button="true"-->
<!--                                                    :show-input-box="true"-->
<!--                                                    :buttons="['Add List Item']"-->
<!--                                                    :call-backs="[ newListMember ]"-->
<!--                                                    @dismiss="showNewListMember = false"-->
<!--                                            />-->
                                        </v-flex>
                                    </v-layout>
                                </v-flex>
                                <v-divider style="background: var(--v-primary-darken4)"></v-divider>
                                <v-flex shrink class="px-2">
                                    <v-text-field
                                            label="Search List Items"
                                            solo
                                            clearable
                                            prepend-icon="search"
                                            color="primary"
                                            flat
                                            :hide-details="true"
                                            v-model="itemFilter"
                                            class="pa-0 ma-0"
                                    ></v-text-field>
                                </v-flex>
                                <v-flex xs12 class="scrollable" v-if="selectedList != null">
                                    <template v-for="item in orderedSelectListMembers">
                                        <v-layout :key="`SelectListManagerItem:${item.id}`" row align-center class="hover-item hide-parent" style="border-bottom: 1px solid #EEEEEE; cursor: pointer;">
                                            <v-flex xs12 class="caption py-2 pl-2">
                                                {{item.value}}
                                            </v-flex>
                                            <v-flex shrink class="pl-2 pr-2 hide-child">
                                                <v-tooltip top>
                                                    <v-icon
                                                            class="pa-0 ma-0"
                                                            small
                                                            color="red"
                                                            style="cursor: pointer"
                                                            slot="activator"
                                                            @click="removeMember(item)"
                                                    >
                                                        delete
                                                    </v-icon>
                                                    <span>Remove Item</span>
                                                </v-tooltip>
                                            </v-flex>
                                        </v-layout>
                                    </template>
                                </v-flex>
                            </v-layout>
                        </v-flex>
                    </v-layout>
                </v-flex>
                <div style="bottom: 15px; right: 15px; position: absolute" v-if="selectable">
                    <v-btn
                            icon
                            color="secondary"
                            class="pa-0 ma-0"
                            @click="doneRun"
                            :disabled="selectedList == null"
                    >
                        <v-icon>done</v-icon>
                    </v-btn>
                </div>
            </v-layout>
        </v-card>
    </v-dialog>
</template>

<script>
    import {codeBaseMixin} from "../../codeBaseMixin";
    import InputDlg from "./InputDlg";

    export default {
        components: {
            appInputDlg: InputDlg
        },
        mixins: [codeBaseMixin],
        props: {
            userInfo: Object,
            value: [Object, Number],
            selectLists: Array,
            returnObject: {
                type: Boolean,
                default: true
            },
            showManager: Boolean,
            title: {
                type: String,
                default: 'List Manager'
            },
            selectable: {
                type: Boolean,
                default: true
            },
            selfLoad: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                selectedList: null,
                renameList: {
                    description: null,
                    id: null
                },
                showRenameList: false,
                showNewList: false,
                showNewListMember: false,
                loadingNewListMember: false,
                listFilter: null,
                itemFilter: null,
                loadingLists: false,
                importString: null
            }
        },
        watch: {
            value: {
                immediate: true,
                handler(val) {
                    this.selectedList = null;
                    if (this.returnObject) {
                        if (val != null && typeof val.id != 'undefined' && val.id != null) {
                            let tList = this.selectLists.filter(obj => obj.id == val.id)
                            this.selectedList = tList.length > 0 ? tList.pop() : null
                        }
                    } else {
                        if (val != null) {
                            let tList = this.selectLists.filter(obj => obj.id == val);
                            this.selectedList = tList.length > 0 ? tList.pop() : null
                        }
                    }
                }
            },
            showManager: {
                immediate: true,
                handler(val) {
                    if (val) {
                        if (!this.selfLoad) {
                            this.itemFilter = null;
                            this.listFilter = null;
                            setTimeout(() => {
                                this.$refs.searchListField.focus();
                            }, 200);
                        } else {
                            this.itemFilter = null;
                            this.listFilter = null;
                            this.loadSelectLists();
                        }
                    }
                }
            },
            selectedList() {
                this.itemFilter = null;
            }

        },
        methods:{

            copyItems(list) {

                console.log(
                        (list || []).members
                                .map(item => item.value).join(`\t`)
                )

                const node = document.createElement("input");
                node.setAttribute('id', 'copyInput')
                node.setAttribute('value', (list || []).members.map(item => item.value).join(`; `))
                document.body.appendChild(node);
                const inputBox = document.getElementById('copyInput');
                inputBox.select();
                document.execCommand("copy");
                document.body.removeChild(node);
                this.showSnack('Info', 'List Values Copied', 'Close', 'primary')
            },

            loadSelectLists() {
                this.loadingLists = true;
                this.fetch({method: 'get', url:`/workflowManager/selectLists/${this.userInfo.entityID}`})
                    .then((data) => {
                        this.$emit('setSelectLists', data)
                        setTimeout(() => {
                            this.$refs.searchListField.focus();
                        }, 200);
                        this.loadingLists = false;
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e);
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
                        this.loadingLists = false;
                    })
            },

            doneRun() {
                this.$emit('dismiss');
                this.$emit('input', this.returnObject ? {description: this.selectedList.description, id: this.selectedList.id} : this.selectedList.id)
            },

            renameListRunEnd(val) {
                if (val == null || val == '') {
                    this.showSnack('Error', 'List description is required', 'Close', 'red');
                    return null
                }
                if (this.selectLists.map(obj => {return obj.description.toLowerCase()}).includes(val.toLowerCase())) {
                    this.showSnack('Error', 'List description must be unique', 'Close', 'red');
                    return null
                }

                this.showRenameList = false;
                this.fetch({method: 'PATCH', url:`/workflowManager/selectList/${this.userInfo.entityID}/${this.renameList.id}`, body: JSON.stringify({description: val})})
                    .then((data) => {
                        this.selectLists.forEach((list) => {
                           if (list.id == this.renameList.id) {
                               list.description = val
                           }
                        });
                        this.showSnack('Info', 'Changes Saved', 'Close', 'primary');
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e);
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
                    })
            },

            newList(val) {
                if (val == null || val == '') {
                    this.showSnack('Error', 'List description is required', 'Close', 'red');
                    return null
                }
                if (this.selectLists.map(obj => {return obj.description.toLowerCase()}).includes(val.toLowerCase())) {
                    this.showSnack('Error', 'List description must be unique', 'Close', 'red');
                    return null
                }

                this.showNewList = false;
                this.fetch({method: 'POST', url:`/workflowManager/selectList/${this.userInfo.entityID}`, body: JSON.stringify({description: val})})
                    .then((data) => {
                        this.selectLists.push(data);
                        this.selectedList = data;
                        this.showSnack('Info', 'Changes Saved', 'Close', 'primary');
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e);
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
                    })
            },

            newListMember() {
                let val = this.importString

                if (!val)
                    return this.showSnack('Error', 'description is required', 'Close', 'red');
                if (val.includes('"'))
                    return this.showSnack('Error', 'Error Quotation Not Allowed', 'Close', 'red');

                const existingItems = (this.selectedList.members || []).map(obj => (obj.value || '').toLowerCase())

                const addItems = val
                        .split(/\n|\t|;/g)
                        .reduce((list, item) => {
                            item = item.trim()
                            if (!item || item.split('').includes('"'))
                                return list
                            if (existingItems.includes(item.toLowerCase()))
                                return list
                            if (list.includes(item.toLowerCase()))
                                return list
                            return list.concat(item)
                        }, []);

                this.loadingNewListMember = true
                this.fetch({method: 'POST', url:`/workflowManager/selectList/${this.userInfo.entityID}/${this.selectedList.id}`, body: JSON.stringify(addItems)})
                    .then((data) => {
                        this.$set(this.selectedList, `members`, data);
                        this.showSnack('Info', `${addItems.length} Items Added`, 'Close', 'primary');
                        this.showNewListMember = false;
                        this.importString = null;
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e);
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
                    })
                    .finally(() => this.loadingNewListMember = false)
            },

            renameRunStart(list) {
                this.renameList.description = list.description;
                this.renameList.id = list.id;
                this.showRenameList = true;
            },

            removeMember(listItem) {
                this.fetch({method: 'DELETE', url:`/workflowManager/selectList/member/${this.userInfo.entityID}/${listItem.id}`})
                    .then(() => {

                        this.selectedList.members = this.selectedList.members.filter(obj => obj.id != listItem.id)
                        this.showSnack('Info', 'Changes Saved', 'Close', 'primary');
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e);
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
                    })
            },
        },
        computed: {
            orderedSelectLists() {
                return (this.selectLists != null ? this.selectLists.filter(item => true).sort((a,b) => a['description'] < b['description'] ? -1 : 1) : [])
                    .filter((obj) => this.listFilter != null && this.listFilter != '' ? obj.description.toLowerCase().includes(this.listFilter.toLowerCase()) : true)
            },
            orderedSelectListMembers() {
                return (this.selectedList != null && typeof this.selectedList.members != 'undefined' && this.selectedList.members != null && this.selectedList.members.length > 0
                    ? this.selectedList.members.filter(item => true).sort((a,b) => a['value'] < b['value'] ? -1 : 1) : [])
                    .filter((obj) => this.itemFilter != null && this.itemFilter != '' ? obj.value.toLowerCase().includes(this.itemFilter.toLowerCase()) : true)
            }
        }
    }
</script>

<style scoped>
    .highlight:hover {
        border-radius: 3px;
        background-color: #EEEEEE;
    }

    .selectedListItem {
        border-radius: 3px;
        background-color: #E0E0E0;
    }

</style>
