<template>
    <v-layout row>
        <v-flex>
            <v-dialog :disabled="disabled" style="width: 100%" :max-width="!dateOnly ? '700px' : '400px'" v-model="menu">
                <div slot="activator">
                    <slot name="activator">

                    </slot>
                    <v-text-field
                            v-if="!hasActivatorSlot"
                            append-outer-icon="calendar_today"
                            label="Select A Date"
                            clearable
                            color="primary"
                            flat
                            :value="value == null ? '' : getLongDate(value)"
                            hide-details
                            class="pl-2 ma-0"
                            single-line
                    >
                    </v-text-field>
                </div>



                <v-card class="text-xs-right">
                    <div class="primary white--text pa-3 text-xs-left headline" @click="resetTimePickerRun">
                        {{displayDate}}
                    </div>

                    <v-layout row>
                        <v-flex xs12>
<!--Date-->
                            <v-date-picker
                                    no-title
                                    id="calendarDiv"
                                    v-model="date"
                                    show-current
                                    color="primary"
                                    full-width
                                    :max="maxDate"
                                    :min="minDate"
                                    flat
                                    style="height: 100%; border: none; box-shadow: none"
                                    event-color="amber"
                                    :events="[max, min].reduce((items, date) => {if (typeof date != 'undefined' && date != null) {items.push(date)} return items}, []).map(obj => {return obj.toISOString().substr(0, 10)})"
                            ></v-date-picker>
                        </v-flex>
                        <v-flex xs12 v-if="!dateOnly">
<!--Time-->
                            <v-time-picker
                                    v-if="!resetTimePicker"
                                    no-title
                                    v-model="time"
                                    type="month"
                                    color="primary"
                                    full-width
                                    flat
                                    style="border: none; box-shadow: none"
                                    :max="maxTime"
                                    :min="minTime"
                                    format="24hr"
                            ></v-time-picker>
                        </v-flex>
                    </v-layout>

                    <v-divider></v-divider>
<!--Done Button-->
                    <v-btn
                            color="secondary"
                            flat
                            @click="menu = false"
                    >
                        Cancel
                    </v-btn>
                    <v-btn
                            color="secondary"
                            flat
                            @click="updateValue"
                    >
                        Done
                    </v-btn>
                </v-card>
            </v-dialog>
        </v-flex>
    </v-layout>
</template>

<script>
    import {codeBaseMixin} from "../../codeBaseMixin";

    export default {
        mixins: [ codeBaseMixin ],
        props: {
            value: String,
            dateOnly: {
                type: Boolean,
                value: false
            },
            min: {
                type: Date,
                value: null,
            },
            max: {
                type: Date,
                value: null,
            },
            disabled: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                menu: false,
                selectedDateTime: null,
                resetTimePicker: false
            }
        },
        watch: {
            menu(shown) {
                if (shown) {
                    if (this.value == null) {
                        this.selectedDateTime = this.getSqlDate(new Date()) + ' ' + this.getFormattedTime(new Date()) + ':00' ;
                    } else {
                        this.selectedDateTime = this.value;
                    }
                }
            },
        },
        methods: {
            checkMaxTime() {
                let tSelected = new Date(this.selectedDateTime)
                if (tSelected > this.max) {
                    this.selectedDateTime = this.getSqlDate(this.max) + ' ' + this.getFormattedTime(this.max) + ':00' ;
                }
            },
            resetTimePickerRun() {
                this.resetTimePicker = true;
                this.$nextTick(() => {
                    this.resetTimePicker = false;
                })
            },

            updateValue() {
                this.menu = false;
                this.$emit('input', this.selectedDateTime);
            }
        },
        computed: {

            hasActivatorSlot () {
                return !!this.$slots['activator']
            },

            displayDate() {
                return this.getLongDate(this.selectedDateTime);
            },

            minDate() {
                if (this.min != null) {
                    return this.getSqlDate(this.min) + ' ' + this.getFormattedTime(this.min) + ':00' ;
                } else {
                    return null
                }
            },

            maxDate() {
                if (this.max != null) {
                    return this.getSqlDate(this.max) + ' ' + this.getFormattedTime(this.max) + ':00' ;
                } else {
                    return null
                }
            },

            minTime() {
                if (this.min != null) {
                    let tMinDate = new Date();
                    tMinDate.setFullYear(this.min.getFullYear(), this.min.getMonth(), this.min.getDate());
                    tMinDate.setHours(0,0,0,0);

                    let tSelectedDate = new Date(this.selectedDateTime);
                    tSelectedDate.setHours(0,0,0,0);

                    if (tMinDate.getTime() == tSelectedDate.getTime()) {
                        return `${this.min.getHours() < 10 ? '0' : ''}${this.min.getHours()}:${this.min.getMinutes() < 10 ? '0' : ''}${this.min.getMinutes()}`
                    }

                    return null
                } else {
                    return null
                }
            },

            maxTime() {
                if (this.max != null) {
                    let tMaxDate = new Date();
                    tMaxDate.setFullYear(this.max.getFullYear(), this.max.getMonth(), this.max.getDate());
                    tMaxDate.setHours(0,0,0,0);
                    let tSelectedDate = new Date(this.selectedDateTime);
                    tSelectedDate.setHours(0,0,0,0);
                    if (tMaxDate.getTime() == tSelectedDate.getTime()) {
                        this.checkMaxTime();
                        return `${this.max.getHours() < 10 ? '0' : ''}${this.max.getHours()}:${this.max.getMinutes() < 10 ? '0' : ''}${this.max.getMinutes()}`
                    }
                    return null
                } else {
                    return null
                }
            },

            date: {
                // getter
                get: function () {
                    return this.selectedDateTime
                },
                // setter
                set: function (newValue) {
                    let tDate = new Date(this.selectedDateTime);
                    let tNewDate = new Date(newValue)
                    tDate.setFullYear(tNewDate.getFullYear(), tNewDate.getMonth(), tNewDate.getDate());
                    this.selectedDateTime = this.getSqlDate(tDate) + ' ' + this.getFormattedTime(tDate) + ':00' ;
                }
            },

            time: {
                // getter
                get: function () {
                    return this.getFormattedTime(this.selectedDateTime);
                },
                // setter
                set: function (newValue) {
                    let values = newValue.split(':').map(obj => {return parseInt(obj)});

                    let tDate = new Date(this.selectedDateTime);

                    tDate.setHours(values[0], values[1]);

                    this.selectedDateTime = this.getSqlDate(tDate) + ' ' + this.getFormattedTime(tDate) + ':00' ;
                }
            },


        }
    }
</script>

<style scoped>

</style>
