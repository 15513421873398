<template>
    <v-card class="fill-height" style="overflow-y: auto;">
        <v-layout column fill-height class="scrollable" style="overflow-x: hidden">
            <v-flex shrink>
                <v-card-title class="white--text pa-0 ma-0 title-color">
                    <div class="pa-2">Select a Signature</div>
                </v-card-title>
            </v-flex>
            <v-flex shrink class="px-2 py-2">
                <v-btn
                        small
                        outline
                        class="ma-0"
                        color="secondary"
                        block
                        slot="activator"
                        @click="addSignature = true"
                        :loading="addingSignature"
                        :disabled="addingSignature"
                >
                    <v-icon left>add</v-icon>
                    Add Signature
                </v-btn>
                <app-input-dlg
                        v-if="addSignature"
                        :show-dialog="addSignature"
                        title="Create Signature"
                        body="Please provide a description for the new signature."
                        :cancel-button="true"
                        :show-input-box="true"
                        :buttons="['Add Signature']"
                        :call-backs="[ addNewSignature ]"
                        @dismiss="addSignature = false"
                />

            </v-flex>
            <v-flex shrink class="px-2">
                <v-text-field
                        label="Filter Signatures"
                        solo
                        clearable
                        prepend-icon="search"
                        color="primary"
                        flat
                        :hide-details="true"
                        v-model="filterString"
                        class="pa-0 ma-0"
                        ref="myField"
                />
            </v-flex>
            <v-divider></v-divider>
            <v-flex xs12 class="scrollable" style="overflow-x: hidden">

                <div
                        style="overflow-x: hidden; cursor: pointer"
                        v-for="sig in filteredSignatures"
                        :key="sig.id"
                        :class="selectedSig != null && selectedSig.id === sig.id ? 'selected-item' : 'hover-item'"
                >
                    <v-layout style="overflow-x: hidden" row align-center class="hide-parent py-1">
                        <v-flex shrink class="pl-2" @click="$emit('selectSignature', sig)">
                            <v-tooltip top>
                                <v-btn
                                        icon
                                        flat
                                        small
                                        :color="sig.globalDefault === 1 ? 'amber' : 'grey'"
                                        class="pa-0 ma-0"
                                        @click="sig.globalDefault === 1 ? null : setGlobalDefault(sig)"
                                        slot="activator"
                                        :loading="modifySig != null && modifySig.id === sig.id"
                                >
                                    <v-icon>{{sig.globalDefault === 1 ? 'star' : 'star_border'}}</v-icon>
                                </v-btn>
                                {{sig.globalDefault === 1 ? 'Signature is set as the global default' : 'Set as global default'}}
                            </v-tooltip>
                        </v-flex>
                        <v-flex
                                xs12
                                class="pa-2 text-truncate"
                                :class="selectedSig != null && selectedSig.id === sig.id ? 'body-2' : ''"
                                @click="$emit('selectSignature', sig)"
                        >
                            {{sig.description}}
                        </v-flex>
                        <v-flex shrink class="pr-2 hide-child">
                            <v-tooltip top>
                                <v-btn
                                        icon
                                        flat
                                        small
                                        color="primary"
                                        class="pa-0 ma-0"
                                        @click="renameSignature(sig)"
                                        slot="activator"
                                >
                                    <v-icon>edit</v-icon>
                                </v-btn>
                                Rename Signature
                            </v-tooltip>
                        </v-flex>
                    </v-layout>
                    <v-divider></v-divider>
                </div>

            </v-flex>

            <app-input-dlg
                    v-if="showRenameSig"
                    :show-dialog="showRenameSig"
                    title="Rename Signature"
                    :body="`New description for: ${modifySig.description}`"
                    :cancel-button="true"
                    :show-input-box="true"
                    :buttons="['Rename Signature']"
                    :default-input="modifySig.description"
                    :call-backs="[ renameSignatureRunEnd ]"
                    @dismiss="showRenameSig = false"
            />

        </v-layout>
    </v-card>
</template>

<script>
    import {blankString} from "../../../codeFunctions";
    import InputDlg from "../../../components/General/InputDlg";
    import {codeBaseMixin} from "../../../codeBaseMixin";

    export default {
        mixins: [codeBaseMixin],
        components: {appInputDlg: InputDlg},
        props: {
            userInfo: Object,
            selectedSig: Object,
            signatures: Array
        },
        data() {
            return {
                addSignature: false,
                addingSignature: false,
                modifySig: null,
                showRenameSig: false,
                filterString: null
            }
        },
        methods: {
            setGlobalDefault(sig) {
                this.modifySig = sig;
                this.fetch({method: 'GET', url:`/sigMan/setGlobal/${this.userInfo.entityID}/${sig.id}`})
                    .then(() => {
                        this.signatures.forEach(signature => {signature.globalDefault = 0});
                        sig.globalDefault = 1;
                        this.modifySig = null;
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e)
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                        this.modifySig = null;
                    })
            },

            renameSignature(sig) {
                this.modifySig = sig;
                this.showRenameSig = true;
            },

            renameSignatureRunEnd(sigDescription) {
                if (!blankString(sigDescription)) {
                    this.showRenameSig = false;
                    this.fetch({method: 'PATCH', url:`/sigMan/renameSignature/${this.userInfo.entityID}/${this.modifySig.id}`, body: {description: sigDescription}})
                        .then(() => {
                            this.modifySig.description = sigDescription;
                            this.modifySig = null;
                        })
                        .catch((e) => {
                            // eslint-disable-next-line
                            console.log(e)
                            this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                            this.modifySig = null;
                        })
                } else {
                    this.showSnack('Error', 'A description is required', 'Close', 'red')
                }
            },

            addNewSignature(sigDescription) {
                if (!blankString(sigDescription)) {
                    this.addSignature = false;
                    this.addingSignature = true;
                    this.fetch({method: 'POST', url:`/sigMan/addSignature/${this.userInfo.entityID}`, body: {description: sigDescription}})
                        .then((data) => {
                            this.signatures.push(data);
                            this.$emit('selectSignature', data)
                            this.addingSignature = false;
                        })
                        .catch((e) => {
                            // eslint-disable-next-line
                            console.log(e)
                            this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                            this.addingSignature = false
                        })
                } else {
                    this.showSnack('Error', 'A description is required', 'Close', 'red')
                }

            }
        },
        computed: {
            filteredSignatures() {
                return this.signatures
                    .filter((sig) => blankString(this.filterString) ?
                        true :
                        sig.description
                            .toLowerCase()
                            .includes(
                                this.filterString
                                    .toLowerCase()
                            ))
                    .sort((a,b) => a['description'] < b['description'] ? -1 : 1);
            }
        }
    }
</script>

<style scoped>

</style>
