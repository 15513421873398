<template>
    <v-layout fill-height>
        <v-flex v-if="showDialog">
            <v-dialog
                    v-model="showDialog"
                    transition="slide-x-transition"
                    persistent
                    fullscreen
            >
                <v-card class="fill-height">
                    <v-layout column fill-height>
                        <v-flex shrink>
                            <v-card-title class="py-2 pr-2 primary">
                                <span class="title font-weight-regular white--text">Mail Viewer</span>
                                <v-spacer></v-spacer>

                                <div style="display: inline"><slot></slot></div>

                                <v-btn flat icon color="white" class="pa-0 ma-0" @click="printPreview">
                                    <v-icon>print</v-icon>
                                </v-btn>
                                <div v-show="false" ref="printLink"></div>

                                <v-btn flat icon color="white" class="pa-0 ma-0" @click="$emit('dismiss', true)">
                                    <v-icon>close</v-icon>
                                </v-btn>
                            </v-card-title>
                            <v-divider></v-divider>
                        </v-flex>
                        <v-flex shrink>
                            <app-mailbox-message-header
                                    :user-info="userInfo"
                                    :message="mail"
                            />
                        </v-flex>
                        <v-flex xs12 class="text-xs-center pa-4" v-if="loadingHtml">
                            <v-progress-circular
                                    :size="40"
                                    :width="5"
                                    color="primary"
                                    indeterminate
                            ></v-progress-circular>
                        </v-flex>
                        <v-flex v-else xs12 class="scroll" style="overflow-y: hidden; overflow-x: auto">
                            <!--Message Body-->
                            <iframe
                                    :src="htmlSrc"
                                    ref="mailView"
                                    style="width: 100%;"
                                    @load="iframeReset"
                                    frameborder="0"
                                    class="fill-height"
                            >
                            </iframe>
                        </v-flex>
                        <v-flex shrink>
                            <!--Attachments-->
                            <template v-if="mail.attachments != null && mail.attachments != '' && mail.attachments != '[]'">
                                    <v-chip
                                            v-for="(attachment, i) in viewAttachemnts" :key="'att' + i"
                                            label
                                            outline
                                            color="primary"
                                            small
                                    >
                                        <v-layout row>
                                            <v-flex class="pr-1" shrink v-show="['pdf', 'PDF'].includes(attachment.attName.split('.').pop())">
                                                <doc-view
                                                    :url="`${baseServerAddress}mailstore/${userInfo.entityID}/${mailPath}/${attachment.attName}`"
                                                >
                                                    <v-tooltip top>
                                                        <v-icon
                                                            style="cursor: pointer"
                                                            small
                                                            slot="activator"
                                                            color="primary"
                                                            class="ma-0"
                                                        >
                                                            visibility
                                                        </v-icon>
                                                        <span>View Attachment</span>
                                                    </v-tooltip>
                                                </doc-view>
                                            </v-flex>
                                            <v-flex class="pr-1" shrink>
                                                <v-tooltip top>
                                                    <v-icon
                                                            style="cursor: pointer"
                                                            small
                                                            slot="activator"
                                                            color="primary"
                                                            class="ma-0"
                                                            @click="runPreviewAttachment(mailPath, attachment.attName, true)"
                                                    >
                                                        attachment
                                                    </v-icon>
                                                    <span>Download Attachment</span>
                                                </v-tooltip>
                                            </v-flex>
                                        </v-layout>

                                        <a v-if="attachment.attName.length > 20"
                                           style="text-decoration: none" class="text--primary"
                                            :href="serverAddress + '/mailstore/' + userInfo.entityID + '/' + mailPath + '/' + attachment.attName"
                                           target="_blank">
                                            <span class="primary--text">{{ attachment.attName.substring(0,20) + '...' }} </span>
                                        </a>
                                        <a v-else style="text-decoration: none"
                                            :href="serverAddress + '/mailstore/' + userInfo.entityID + '/' + mailPath + '/' + attachment.attName"
                                            target="_blank">
                                            <span class="primary--text">{{ attachment.attName }}</span>
                                        </a>
                                    </v-chip>

                                <v-chip
                                    v-if="!showEmbedded && viewAttachemnts.length < mail.attachments.length"
                                    label
                                    outline
                                    color="secondary"
                                    small
                                    @click="showEmbedded = true"
                                    style="cursor: pointer"
                                >
                                    <strong>+{{this.mail.attachments.length - viewAttachemnts.length}} More</strong>
                                </v-chip>

                            </template>
                        </v-flex>
                    </v-layout>
                </v-card>
            </v-dialog>
        </v-flex>
    </v-layout>
</template>

<script>

    import { codeBaseMixin} from "../../codeBaseMixin";
    import MailboxMessageHeader from "../../Mail/Gmail/components/MailboxMessageHeader.vue";
    import tokenMan from '../../tokenManSite'
    import DocView from "@/DocView/DocView";

    export default {
        mixins: [ codeBaseMixin ],
        components: {
            DocView,
            appMailboxMessageHeader: MailboxMessageHeader
        },
        props: {
            userInfo: Object,
            mail: Object,
            showDialog: Boolean
        },
        data() {
            return {
                messageHtml: "",
                mailPath: "",
                loadingHtml: false,
                showEmbedded: false
            }
        },
        methods: {
            printPreview() {
                window.open(`${this.baseServerAddress}mailView?entityID=${this.userInfo.entityID}&mailID=${this.mail.mailDBID}&token=${tokenMan.token()}`, '_blank')
                this.$emit('dismiss', true)
            },


            runPreviewAttachment(mailDownloadPath, attName, download) {
                let downloadAddress = '';
                downloadAddress = this.baseServerAddress + 'mailstore/' + this.userInfo.entityID + '/' + mailDownloadPath + '/' + attName;
                let win = window.open(downloadAddress, '_blank');
            },

            iframeReset(event) {
                let doc = this.$refs.mailView;

                doc.html = this.messageHtml;
                let content = (doc.contentWindow || doc.contentDocument)

                if (content.document)content = content.document;

                doc = content;

                let head = doc.head;

                //Set The Style
                let style = doc.createElement('style');
                head.appendChild(style);
                style.type = 'text/css';
                style.appendChild(document.createTextNode(`
                    body {
                        font-family: sans-serif;
                    }
                        body::-webkit-scrollbar-track {
                        background-color: #ededed;
                    }

                    body::-webkit-scrollbar {
                        width: 8px;
                        height: 8px;
                        background-color: #F5F5F5;
                    }

                    body::-webkit-scrollbar-thumb {
                        background-color: #00897B;
                    }`));

            },

        },
        computed: {
            viewAttachemnts() {
                return this.mail.attachments.filter(obj => this.showEmbedded ? true : obj.type !== 'embedded')
            },

            htmlSrc() {
              // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.mailPath = this.mail.fk_gmail_group_mailbox_folder == 0 ? (this.mail?.fk_import_user + '-' + this.mail?.mailID) : (this.mail?.fk_gmail_group_mailbox_folder + '-' + this.mail?.mailID);

                let sRequ = this.serverAddress + '/mailstore/' + this.userInfo.entityID + '/' + this.mailPath + '/' + this.mailPath + '.html';

                return sRequ
            }
        }
    }
</script>

<style scoped>

</style>
