<template>
    <div>
        <div @click="showAdd = !showAdd">
            <slot name="activator"></slot>
        </div>

        <app-input-dlg
                :show-dialog="showAdd"
                title="Add New Folder Group"
                :cancel-button="true"
                :show-input-box="false"
                @dismiss="showAdd = false"
                :body-padding="false"
        >
            <template slot="body">
                <div class="pt-1">
                    <div :class="topicClass" class="px-1">
                        <div :class="headingClass">
                            Folder Group Description:
                        </div>
                        <div :class="itemClass">
                            <v-text-field
                                    label="Folder Group Description"
                                    single-line
                                    color="primary"
                                    hide-details
                                    style="width: 100%"
                                    class="pa-0 ma-0"
                                    ref="FGroupDescription"
                                    v-model="addGroupDescription"
                            />
                        </div>
                    </div>
                    <div :class="topicClass" class="px-1">
                        <div :class="headingClass">
                            Select User Groups:
                        </div>
                    </div>
                    <div style="overflow-y: auto">
                        <multi-select-list
                                :show-action-buttons="false"
                                :show-check-boxes="true"
                                :multiple="true"
                                headline-key="description"
                                item-key="id"
                                :return-object="false"
                                v-model="selectedUserGroups"
                                :show-filter="true"
                                :items="userGroups"
                                :height="400"
                                filter-label="Filter User Groups"
                        />
                    </div>
                </div>
            </template>
            <template slot="actions">
                <div class="text-xs-right">
                    <v-btn
                            small
                            color="secondary"
                            :disabled="!Array.isArray(selectedUserGroups) || selectedUserGroups.length <= 0 || blankString(addGroupDescription) || addingFolderGroup"
                            :loading="addingFolderGroup"
                            @click="addFolderGroup"
                    >
                        Add Folder Group
                    </v-btn>
                </div>
            </template>
        </app-input-dlg>
    </div>
</template>

<script>
    import {codeBaseMixin} from "../../../codeBaseMixin";
    import InputDlg from "../../../components/General/InputDlg";
    import MultiSelectList from "../../../components/Multi Select List/MultiSelectList";
    import {feed} from "../../../feedSocket";

    export default {
        components: {
            MultiSelectList,
            appInputDlg: InputDlg
        },
        mixins: [codeBaseMixin],
        props: {
            userInfo: Object,
            userGroups: Array,
            folderGroups: Array
        },
        data() {
            return {
                showAdd: false,
                addGroupDescription: null,
                selectedUserGroups: [],
                addingFolderGroup: false,
            }
        },
        watch: {
            showAdd(shown) {
                if (shown) {
                    this.addGroupDescription = null;
                    this.selectedUserGroups = [];
                    this.$nextTick(() => {
                        this.$refs.FGroupDescription.focus();
                    })
                }
            },
        },
        methods: {
            addFolderGroup() {
                this.addingFolderGroup = true;

                let folderGroupChanges = [];

                feed.on('folderGroupChange', (data) => {
                    folderGroupChanges.push(data)
                });

                let vm = this;

                function waitForAddedFolderGroup(reFolderGroup, waitCycle = 0) {
                    return new Promise ((resolve, reject) => {

                        if (waitCycle > 20000) {
                            reject('Timeout')
                        } else {
                            if (folderGroupChanges.map(obj => {return obj.id}).includes(reFolderGroup)) {
                                vm.$nextTick(() => {
                                    resolve(reFolderGroup)
                                })
                            } else {
                                setTimeout(() => {
                                    resolve(waitForAddedFolderGroup(reFolderGroup, waitCycle + 100))
                                }, 100)
                            }
                        }
                    })
                }

                this.fetch({method: 'POST', url:`/folderManager/security/fgm/folderGroup/${this.userInfo.entityID}`, body: JSON.stringify({description: this.addGroupDescription, userGroups: this.selectedUserGroups})})
                    .then((data) => {
                        this.showAdd = false;
                        this.addingFolderGroup = false;
                        waitForAddedFolderGroup(data.reFolderGroup)
                            .then((folderGroup) => {
                                this.$emit('folderGroupAdded', folderGroup);
                                this.showSnack('Info', 'Folder Group Added', 'Close', 'primary')
                            })
                            .catch((e) => {console.log(e)})
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e);
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
                        this.addingFolderGroup = false;
                    })
            }
        }

    }
</script>

<style scoped>

</style>
