<template>
  <div class="of-y pa-2">
    <v-card class="of-y">
      <v-card-title class="text-h6 white--text" :class="rule.id ? 'title-color' : 'amber darken-2'" style="position: sticky; top: 0; z-index: 9">
        {{ rule.id ? editRule.description : 'New Rule' }}
      </v-card-title>
      
      <v-card-text>
        
        <v-form ref="editRuleForm" @submit="$event.preventDefault()" v-model="valid">
          <v-text-field
              label="Rule Description"
              color="primary"
              style="width: 100%"
              v-model="rule.description"
              :disabled="savingChanges"
              :autofocus="true"
              :rules="[validationRules.required]"
          />
          
          <v-autocomplete
              label="Apply to apps"
              placeholder="Applied to all apps"
              :persistent-placeholder="true"
              :items="apps"
              item-value="id"
              item-text="name"
              color="primary"
              style="width: 100%"
              v-model="rule.fkApps"
              multiple
              chips
              small-chips
              deletable-chips
              :disabled="savingChanges"
          />
          
          <div class="c-d-flex c-align-center grey--text  body-2">
            <div class="c-flex-grow-1">Add Contact to the following contact lists</div>
            <v-btn
                color="secondary"
                flat
                small
                @click="showFolderSelect = true"
            >
              <v-icon left>add</v-icon>
              Add Contact List
            </v-btn>
          </div>
          <v-divider></v-divider>
          <div class="text-xs-center caption error--text py-2 font-weight-bold" v-if="!rule.fkLinkFolders.length">
            AT LEAST ONE CONTACT LIST IS REQUIRED
          </div>
          <v-list v-else class="py-0 my-0" dense>
            <v-list-tile v-for="folder in selectedContactLists" @click="() => {}" :key="folder.id">
              <v-list-tile-avatar><v-icon>contacts</v-icon></v-list-tile-avatar>
              <v-list-tile-content>
                <v-list-tile-title>{{ folder.description }}</v-list-tile-title>
              </v-list-tile-content>
              <v-list-tile-action>
                <v-btn
                    small
                    icon
                    flat
                    color="error"
                    @click="rule.fkLinkFolders = rule.fkLinkFolders.filter(v => v !== folder.id)"
                ><v-icon>close</v-icon></v-btn>
              </v-list-tile-action>
            </v-list-tile>
          </v-list>
        </v-form>
      </v-card-text>
      
      <v-card-actions>
        <v-btn
            outline
            color="error"
            @click="deleteRule"
            :disabled="savingChanges"
        >Delete Rule</v-btn>
        <v-spacer/>
        <v-btn
            flat
            @click="resetField(true)"
            :disabled="savingChanges"
        >Cancel</v-btn>
        <!--        @click="saveChanges"-->
        <v-btn
            @click="saveChanges"
            color="secondary"
            :disabled="savingChanges || !valid || !rule.fkLinkFolders.length"
            :loading="savingChanges"
        >Save Changes</v-btn>
      </v-card-actions>
    </v-card>
    
    <folder-dlg
        :show-add-contact="false"
        v-if="showFolderSelect"
        :show-dialog="showFolderSelect"
        @dismiss="showFolderSelect = false"
        :select-dlg="true"
        :user-info="userInfo"
        :folders="foldersSelect"
        dlg-type="allFoldersSelect"
        @appendFolderData="foldersSelect = $event"
        @input="addContactList($event)"
        :local-data="$store.state.localData"
        :contact-lists-only="true"
    />
  </div>
</template>

<script>

import {arrSplice, validationRules} from "@/codeFunctions";
import format from "date-fns/format";
import FolderDlg from "@/Folders/FolderDlg.vue";
import userInfo from "@/Store/userInfo/userInfo";
import {mapGetters} from "vuex";

class LinkRule {
  id = null;
  description = null;
  fkApps = [];
  fkLinkFolders = [];
  linkHash = null;
  
  constructor(obj = {}) {
    Object.assign(this, JSON.parse(JSON.stringify(obj)))
  }
}


export default {
  components: {FolderDlg},
  props: {
    editRule: {
      type: Object,
      default: () => ({})
    },
    apps: Array,
  },
  data() {
    return {
      rule: new LinkRule(),
      savingChanges: false,
      valid: false,
      validationRules,
      showFolderSelect: false,
      foldersSelect: {
        folderGroups: [],
        folders: []
      },
    }
  },
  watch: {
    editRule: {
      immediate: true,
      handler(val) {
        this.resetField()
      }
    }
  },
  methods: {
    format,
    
    addContactList({id: folderId}) {
      this.rule.fkLinkFolders = this.rule.fkLinkFolders
          .concat(folderId)
          .filter((v, i, arr) => arr.indexOf(v) === i)
    },
    
    resetField(emitCancel = false) {
      this.rule = new LinkRule(this.editRule)
      if (emitCancel)
        this.$emit('cancelClicked')
    },
    
    async saveChanges() {
      if (!this.$refs.editRuleForm.validate())
        return this.$snack.error('Error Required Fields Missing')
      try {
        this.savingChanges = true
        const savedValue = await this.$newReq('POST', `appLinkRules/setup/update`, this.rule)
        this.$emit('input', savedValue)
        this.$snack.info('Changes Saved')
      } catch (e) {
        console.log(e)
        this.$snack.networkError()
      } finally {
        this.savingChanges = false
      }
    },
    
    async deleteRule() {
      try {
        this.savingChanges = true
        await this.$newReq('POST', `appLinkRules/setup/delete`, {id: this.rule.id})
        this.$emit('deleted', this.rule.id)
        this.$snack.info('Changes Saved')
      } catch (e) {
        console.log(e)
        this.$snack.networkError()
      } finally {
        this.savingChanges = false
      }
    },
  },
  computed: {
    ...mapGetters('userInfo', ["userInfo"]),
    
    selectedContactLists() {
      const selectedContactListsSet = new Set(this.rule.fkLinkFolders)
      return this.$store.state.localData.folders
          .filter(v => selectedContactListsSet.has(v.id))
          .sort((a,b) => a.description > b.description ? 1 : -1)
    }
    
    // visibleSwitch: {
    //   get() {
    //     return !!this.channel.visible
    //   },
    //   set(val) {
    //     this.channel.visible = val ? 1 : 0
    //   }
    // }
  },
}
</script>



<style scoped>
.input-box:hover .master-title,
.input-box:focus-within .master-title {
  //font-weight: bold;
  color: var(--v-primary-base);
}
</style>