<template>
  <div>
    <v-dialog
        v-model="shownRelay"
        fullscreen
        persistent
    >
      <v-card v-if="shownRelay" class="scrollable c-d-flex c-flex-column" style="height: 100vh">
        <div class="c-flex-grow-0 px-3 title-color white--text dark c-d-flex c-align-center">
            <div>
              <v-icon large color="white" left>contact_mail</v-icon>
            </div>
          <div class="c-flex-grow-1 py-2 headline">
            Contact Manager
          </div>
          <div>
            <v-btn
                color="white"
                icon
                flat
                @click="shownRelay = false"
                class="mx-0"
            >
              <v-icon>close</v-icon>
            </v-btn>
          </div>
        </div>
        
        <div class="c-flex-grow-1 of-y c-d-flex c-align-center c-justify-center" v-if="startingUp">
          <v-progress-circular
              :size="40"
              :width="5"
              color="primary"
              indeterminate
          ></v-progress-circular>
        </div>
        <template v-else>
          <div class="title-color">
            <v-tabs
                v-model="curPage"
                color="primary"
                dark
                slider-color="secondary"
            >
              <v-tab
                  v-for="page in [{title: 'Custom Fields', id: 0},{title: 'Channels', id: 1},{title: 'App Link Rules', id: 2}]"
                  :key="page.id"
                  ripple
              >
                {{page.title}}
              </v-tab>
            </v-tabs>
          </div>
          
<!--Custom Fields-->
          <div class="c-flex-grow-1 of-y" v-if="curPage === 0">
            <app-contacts-custom-fields :select-lists="selectLists" :custom-fields="customFields"/>
          </div>
<!--Manage Page-->
          <div class="c-flex-grow-1 of-y" v-else-if="curPage === 1">
            <app-contacts-channels/>
          </div>
<!--Import Page-->
          <div class="c-flex-grow-1 of-y" v-else-if="curPage === 2">
            <app-contacts-link-rules/>
          </div>
        </template>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import AppContactsCustomFields from "@/contacts/AppContactsCustomFields.vue"
import AppContactsChannels from "@/contacts/AppContactsChannels.vue";
import AppContactsLinkRules from "@/contacts/Link Rules/AppContactsLinkRules.vue";

export default {
  components: {
    AppContactsLinkRules,
    AppContactsChannels,
    AppContactsCustomFields
  },
  props: {
    shown: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      startingUp: false,
      selectLists: [],
      customFields: [],
      curPage: 0
    }
  },
  watch: {
    shown: {
      immediate: true,
      handler(val) {
        if (val)
          this.init()
      }
    }
  },
  methods: {
    async init() {
      try {
        this.startingUp = true
        const data = await this.$newReq('GET', `contacts/setup/startup`)
        this.selectLists = data.selectLists
        this.customFields = data.customFields
        console.log({data})
      } catch (e) {
        console.log(e)
        this.$snack.networkError()
      } finally {
        this.startingUp = false
      }
    }
  },
  computed: {
    shownRelay: {
      get() {
        return this.shown
      },
      set(val) {
        this.$emit("update:shown", val)
      }
    }
  },
}
</script>

<style scoped>

</style>