<template>
    <app-input-dlg
            v-if="show"
            :show-dialog="show"
            :title="`Rule: ${editRule.description != null ? editRule.description : `Rule ${editRule.id}`}`"
            :cancel-button="true"
            :show-input-box="false"
            @dismiss="$emit('dismiss')"
            :body-padding="false"
            :width="600"
    >
        <template slot="body">
            <div class="pl-2 pr-0 pt-2 pb-0" style="overflow-y: hidden">
                <div :class="topicClass">
<!--Rule Description-->
                    <div :class="headingClass">
                        Rule Name:
                    </div>
                    <div :class="itemClass" class="pr-2" >
                        <v-text-field
                                label="Rule Name"
                                single-line
                                v-model="updatedName"
                                color="primary"
                                hide-details
                                style="width: 100%"
                                class="ma-0 pa-0"
                                :append-icon="nameIcon"
                                :disabled="nameIcon != 'save'"
                                @click:append="updateName"
                        ></v-text-field>
                    </div>
<!--Rule Conditions-->
                    <template v-if="editRule.conditions.length > 0">
                        <div :class="headingClass" class="pt-2">
                            Rule Conditions:
                        </div>
                        <div :class="itemClass" style="max-height: 200px; overflow-y: auto">
                            <div class="caption" v-for="(field, n) in editRule.conditions" :key="n">
                                <app-edit-rule-field
                                        :rule="field"
                                        @input="editConditionFieldsUpdate($event, field)"
                                        @remove="removeConditionField(field)"
                                        :fields="fields"
                                        :select-lists="selectLists"
                                        :user-info="userInfo"
                                        :disableRemove="editRule.conditions.length <= 1"
                                />
                            </div>
                        </div>
                    </template>
<!--Show Fields-->
                    <template v-if="showFields.length > 0">
                        <div :class="headingClass" class="pt-2">
                            Show Fields:
                        </div>
                        <div :class="itemClass" style="max-height: 200px; overflow-y: auto">
                            <div class="caption" v-for="(field, n) in showFields" :key="n">
                                <v-layout row align-center class="hover-item pl-2" style="cursor: pointer">
                                    <v-flex xs12>
                                        {{field.description}}
                                    </v-flex>
                                    <v-flex shrink class="pl-1">
                                        <v-tooltip top>
                                            <v-btn
                                                    small
                                                    icon
                                                    flat
                                                    class="pa-0 ma-0"
                                                    color="red"
                                                    slot="activator"
                                                    @click="removeShowField(field.id)"
                                            >
                                                <v-icon small>delete</v-icon>
                                            </v-btn>
                                            <span>Remove Show Field</span>
                                        </v-tooltip>
                                    </v-flex>
                                </v-layout>
                            </div>
                        </div>
                    </template>
<!--Show Steps-->
                    <template v-if="showSteps.length > 0">
                        <div :class="headingClass" class="pt-2">
                            Show Steps:
                        </div>
                        <div :class="itemClass" style="max-height: 200px; overflow-y: auto">
                            <div class="caption" v-for="(step, n) in showSteps" :key="n">
                                <v-layout row align-center class="hover-item pl-2" style="cursor: pointer">
                                    <v-flex xs12>
                                        {{step.description}}
                                    </v-flex>
                                    <v-flex shrink class="pl-1">
                                        <v-tooltip top>
                                            <v-btn
                                                    small
                                                    icon
                                                    flat
                                                    class="pa-0 ma-0"
                                                    color="red"
                                                    slot="activator"
                                                    @click="removeShowStep(step.id)"
                                            >
                                                <v-icon small>delete</v-icon>
                                            </v-btn>
                                            <span>Remove Show Step</span>
                                        </v-tooltip>
                                    </v-flex>
                                </v-layout>
                            </div>
                        </div>
                    </template>
<!--Add Fields-->
                    <template v-if="editFields.length > 0">
                        <div :class="headingClass" class="pt-2">
                            Configure Field/s
                        </div>
                        <div :class="itemClass" style="max-height: 200px; overflow-y: auto">
                            <div class="caption" v-for="(field, n) in editFields" :key="n">
                                <app-configure-rule-field
                                        :value="field"
                                        :select-lists="selectLists"
                                        @setShow="field.showField = $event"
                                        @setOperationID="field.operationID = $event"
                                        @setValue="field.value = $event"
                                        :valid="field.showField || (!field.showField && (field.operationID == 2 || (field.operationID != 2 && field.value != null && field.value != '')))"
                                />
                            </div>
                        </div>
                    </template>
<!--Add Steps-->
                    <template v-if="editSteps.length > 0">
                        <div :class="headingClass" class="pt-2">
                            Add Steps
                        </div>
                        <div :class="itemClass" style="max-height: 200px; overflow-y: auto">
                            <div class="caption" v-for="(step, n) in editSteps" :key="n">
                                <div class="px-2 hover-item">
                                    <v-layout row align-centerstyle="cursor: pointer">
                                        <v-flex xs12>
                                            {{step.description}}
                                        </v-flex>
                                        <v-flex shrink>
                                            <v-tooltip top>
                                                <v-btn
                                                        small
                                                        icon
                                                        flat
                                                        class="pa-0 ma-0"
                                                        color="red"
                                                        slot="activator"
                                                        @click="editSteps.splice(n,1)"
                                                >
                                                    <v-icon small>remove</v-icon>
                                                </v-btn>
                                                <span>Don't Add Step</span>
                                            </v-tooltip>
                                        </v-flex>
                                    </v-layout>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
<!--Add Fields Save-->
            <template v-if="(editFields.length + editSteps.length) > 0">
                <v-divider></v-divider>
                <div class="pb-1">
                    <v-layout row align-center>
                        <v-flex xs12 class="caption greyType--text px-3">
                            {{saveMessage}}
                        </v-flex>
                        <v-flex shrink>
                            <v-btn
                                    small
                                    color="secondary"
                                    :disabled="saveMessage != null || savingChanges"
                                    :loading="savingChanges"
                                    @click="doneRun"
                            >
                                Done
                            </v-btn>
                        </v-flex>
                    </v-layout>
                </div>
            </template>
        </template>
        <!--<template slot="actions">-->

        <!--</template>-->
    </app-input-dlg>
</template>

<script>
    import {codeBaseMixin} from "../../../../../codeBaseMixin";
    import InputDlg from "../../../../../components/General/InputDlg";
    import ConfigureRuleField from "./ConfigureRuleField";
    import EditRuleField from "./EditRuleField"

    export default {
        mixins: [codeBaseMixin],
        components: {
            appInputDlg: InputDlg,
            appConfigureRuleField: ConfigureRuleField,
            appEditRuleField: EditRuleField
        },
        props: {
            show: {
                default: false,
                type: Boolean
            },
            editRule: Object,
            addFields: Array,
            addSteps: Array,
            selectLists: Array,
            fields: Array,
            steps: Array,
            userInfo: Object,
        },
        data() {
            return {
                headingClass: 'caption greyType--text font-weight-bold',
                topicClass: 'pa-1',
                itemClass: 'pl-2',

                editFields: [],
                editSteps: [],
                operationDescriptions: {
                    0: "=",
                    1: "Not Equal",
                    2: "Not Blank",
                    3: "Bigger",
                    4: "Smaller",
                    5: "After",
                    6: "Before",
                },
                savingChanges: false,
                savingNameChange: false,
                updatedName: null,
                nameIcon: 'save'
            }
        },
        watch: {
            show(val) {
                if (val) {
                    this.updatedName = this.editRule.description;

                    this.editFields = this.addFields.map(obj => {
                        return {
                            showField: true,
                            value: null,
                            operationID: 0,
                            field: obj
                        }
                    })
                        .sort((a, b) => a['field']['description'] < b['field']['description'] ? -1 : 1);

                    if (this.editFields.length > 0 && this.editRule.conditions.length <= 0) {
                        this.editFields[0].showField = false;
                    }

                    this.editSteps = this.addSteps.sort((a,b) => a['description'] < b['description'] ? -1 : 1);
                }
            }
        },
        methods: {
            updateName() {
                if (this.updatedName != null && this.updatedName) {
                    this.nameIcon = 'alarm';

                    this.fetch({method: 'PATCH', url:`/workflowManager/rules/name/${this.userInfo.entityID}/${this.editRule.id}`, body: JSON.stringify({description: this.updatedName})})
                        .then(() => {
                            this.nameIcon = 'save';
                            this.editRule.description = this.updatedName;
                            this.showSnack('Info', 'Changes Saved', 'Close', 'primary')
                        })
                        .catch((e) => {
                            // eslint-disable-next-line
                            console.log(e);
                            this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
                            this.nameIcon = 'save';
                        })
                } else {
                    this.showSnack('Error', 'Rule Name Can Not Be Blank', 'Close', 'red')
                }
            },

            removeShowField(field) {
                this.fetch({method: 'POST', url:`/workflowManager/rules/showFields/${this.userInfo.entityID}/${this.editRule.id}`, body: JSON.stringify({showFields: this.editRule.showFields.filter(obj => obj != field)})})
                    .then(() => {
                        this.editRule.showFields = this.editRule.showFields.filter(obj => obj != field);
                        this.showSnack('Info', 'Show Field Removed', 'Close', 'primary')
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e);
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
                    })
            },

            removeShowStep(step) {
                this.fetch({method: 'POST', url:`/workflowManager/rules/showSteps/${this.userInfo.entityID}/${this.editRule.id}`, body: JSON.stringify({showSteps: this.editRule.showSteps.filter(obj => obj != step)})})
                    .then(() => {
                        this.editRule.showSteps = this.editRule.showSteps.filter(obj => obj != step);
                        this.showSnack('Info', 'Show Step Removed', 'Close', 'primary')
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e);
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
                    })
            },

            editConditionFieldsUpdate(newValue, oldValue) {
                oldValue.operationID = newValue.operationID;
                oldValue.value = newValue.value;
            },

            removeConditionField(field) {
                this.editRule.conditions = this.editRule.conditions.filter(obj => obj.id != field.id);
            },

            doneRun() {
                this.savingChanges = true;

                let tBody = {
                    showFields: (this.editRule.showFields == null ? [] : this.editRule.showFields).concat(this.editFields.reduce((list, field) => {
                        if (field.showField == true) {
                            list.push(field.field.id)
                        }
                        return list
                    }, [])),
                    showSteps: (this.editRule.showSteps == null ? [] : this.editRule.showSteps).concat(this.editSteps.map((obj) => {return obj.id})),
                    addConditions: this.editFields.reduce((list, field) => {
                        if (field.showField == false) {
                            list.push({fk_field: field.field.id, operationID: field.operationID, value: field.value})
                        }
                        return list
                    }, [])
                };

                this.fetch({method: 'POST', url:`/workflowManager/rules/${this.userInfo.entityID}/${this.editRule.id}`, body: JSON.stringify(tBody)})
                    .then((data) => {
                        this.savingChanges = false;
                        this.showSnack('Info', 'Changes Saved', 'Close', 'primary');
                        this.$emit('ruleUpdated', data);
                        this.$emit('dismiss');
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e);
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
                        this.savingChanges = false;
                    })

            },
        },
        computed: {
            saveMessage() {
                let bFine = true;

                if (this.editRule.conditions.length + this.editFields.filter(obj => !obj.showField).length <= 0) {
                    bFine = 'A minimum of one condition is required.'
                }

                this.editFields.forEach(field => {
                    if (!(field.showField || (!field.showField && (field.operationID == 2 || (field.operationID != 2 && field.value != null && field.value != ''))))) {
                        bFine = `${field.field.description} - not configured`
                    }
                });

                if (bFine === true) {
                    return null
                } else {
                    return bFine
                }
            },

            showFields() {
                try {
                    return this.fields.reduce((list, field) => {
                        if (this.editRule.showFields.includes(field.id)) {
                            list.push({id: field.id, description: field.description})
                        }
                        return list
                    }, [])
                } catch (e) {
                    // eslint-disable-next-line
                    return [];
                }
            },

            showSteps() {
                try {
                    return this.steps.reduce((list, step) => {
                        if (this.editRule.showSteps.includes(step.id)) {
                            list.push({id: step.id, description: step.description})
                        }
                        return list
                    }, [])
                } catch (e) {
                    // eslint-disable-next-line
                    return [];
                }
            }
        }
    }
</script>

<style scoped>

</style>
