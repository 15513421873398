import {EventEmitter} from "events";

export const promptEvents = new EventEmitter()

export async function showPrompt({title = 'Please Provide', body = 'Body', label = 'Prompt Label', defaultValue = '', buttonLabel = 'Done', showCancel = true}){
  return new Promise((resolve, reject) => {

    console.log('Ran Here')

    promptEvents.once('done', (val) => {
      resolve(val)
    })
    promptEvents.emit('showPrompt', {
      title,
      body,
      label,
      defaultValue,
      buttonLabel,
      showCancel,
    })
  })
}