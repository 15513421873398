const EventEmitter      = require('events');
let repoEvents          = new EventEmitter();

function newDownload(document) {
    repoEvents.emit('newDownload', document)
}

function getRepoDocs(payload) {
    return new Promise((resolve, reject) => {
        repoEvents.emit('getRepoDoc', payload)
        repoEvents.on('canceled', () => {reject(null)});
        repoEvents.on('error', (data) => {reject(data)});
        repoEvents.on('done', (data) => {resolve(data)});
    })
}

function getRepoTree(folders, inputDocs, inputSpliceEmptyFolders, searchStr) {

    folders = folders || []
    inputDocs = inputDocs || []
    inputSpliceEmptyFolders = inputSpliceEmptyFolders || false
    searchStr = searchStr || null

    let inSearch = searchStr

    let recycleFolder = {
        id: -1,
        description: 'Recycle Bin',
        key: 'rf--1',
        parent: 0,
        type: 0,
        children: []
    }

    folders = [...folders, recycleFolder]

    folders.forEach(folder => {
        folder.children = []
    })

    function addParent(folderID) {
        if (!addFolders.includes(folderID)) {
            addFolders.push(folderID)
            if (folderRef[folderID].parent)
                addParent(folderRef[folderID].parent)
        }
    }

    let docs = !inSearch
        ? inputDocs
        : inputDocs.filter(doc => JSON.stringify(doc).toLowerCase().includes(searchStr.toLowerCase()))

    let spliceEmptyFolders = inSearch ? true : inputSpliceEmptyFolders


    // Create Root Folder
    let rootFolder = {
        id: 0,
        key: 'rf-0',
        parent: null,
        children: []
    }

    let folderRef = folders.reduce((obj, folder) => {
        obj[folder.id] = folder
        return obj
    }, {})

    folderRef['0'] = rootFolder

    // Add Repo Documents To folders
    docs.forEach(doc => {
        folderRef[doc.parent].children.push(doc)
    })

    // Build Add Folders Arr with Regards To Splice
    let addFolders = [];

    if (spliceEmptyFolders) {
        docs.forEach(doc => addParent(doc.parent))
    }
    // Add Folders To Folder Parent
    (spliceEmptyFolders
            ? folders.filter(obj => addFolders.includes(obj.id))
            : folders
    ).forEach(folder => {
        folderRef[folder.parent].children.push(folder)
    })

    folders.forEach(folder => {
        folder.children.sort((a,b) => a.type - b.type)
    })

    rootFolder.children.sort((a,b) => a.type - b.type)

    // Return Root Folder
    return rootFolder
}

function addDocToUserRepo(docDBID) {
    return new Promise((resolve, reject) => {
        repoEvents.emit('addDocToUserRepo', docDBID)
        repoEvents.on('addDocToUserRepoError', (data) => {reject(data)});
        repoEvents.on('addDocToUserRepoDone', (data) => {resolve(data)});
    })
}

export {
    repoEvents,
    getRepoDocs,
    getRepoTree,
    newDownload,
    addDocToUserRepo
}