<template>
  <v-dialog
    :value="sendingMail ? false : showDialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    v-if="template != null"
    :persistent="true"
  >
    <v-card flat height="100vh">

      <v-layout column fill-height>
        <v-flex shrink>
          <!--Card Title-->
          <v-card-title class="py-2 pr-2 primary">
            <v-icon
              left
              color="white"
            >
              category
            </v-icon>
            <span class="title font-weight-light white--text">Mail Template</span>
            <v-spacer></v-spacer>
            <v-btn flat icon color="white" class="pa-0 ma-0" @click="$emit('dismiss', false)">
              <v-icon>close</v-icon>
            </v-btn>
          </v-card-title>
          <v-divider></v-divider>
        </v-flex>

        <v-flex xs12 style="overflow-y: auto">
          <v-layout style="overflow-y: auto" row fill-height class="pa-1">
            <!--Fields-->
            <v-flex xs3 class="">
              <v-card class="fill-height">
                <v-layout column fill-height style="overflow-y: auto">
                  <v-flex shrink>
                    <v-card-title class="py-2 pr-2 primary">
                      <span class="title font-weight-light white--text">Fields</span>
                    </v-card-title>
                  </v-flex>

                  <v-flex xs12 style="overflow-y: auto" class="scroll pa-1">
                    <!--Input Fields-->
                    <template v-if="fieldType3s.length > 0" class="pt-2">
                      <span class="body-2 greyType--text">Input Fields</span>
                      <v-layout row wrap class="pa-1">
                        <template v-for="(input, i) in fieldType3s">
                          <v-flex :key="i + '-' + input.key" xs12 class="py-1 pl-2"
                                  style="cursor: pointer">
                            <v-layout row align-center>
                              <v-flex xs12 class="pr-1">

                                <v-textarea
                                  solo
                                  :label="input.key"
                                  v-model="input.text"

                                  class="pa-0 ma-0"
                                  hide-details
                                  rows="2"
                                ></v-textarea>

                              </v-flex>
                            </v-layout>
                          </v-flex>

                        </template>
                      </v-layout>
                    </template>
                    <!--Workflow Fields-->
                    <template v-if="fieldType1s.length > 0" class="pt-2">
                      <span class="body-2 greyType--text">Workflow Fields</span>
                      <v-layout row wrap class="pa-1">
                        <template v-for="(field, i) in distinctType1s">
                          <v-flex :key="i + '-' + field.fieldID" xs12 class="hover py-1 pl-2" style="cursor: pointer">
                            <v-layout row align-center>
                              <v-flex xs12>
                                <template
                                  v-for="step in steps.filter(filterStep => filterStep.stepID == field.stepID)">
                                  <span class="body-2">{{ step.description }}</span>
                                  &nbsp;-&nbsp;
                                  <span class="body-1 font-weight-regular">{{ field.description }}</span>
                                </template>
                              </v-flex>
                              <v-flex shrink class="pr-2">
                                <v-tooltip top v-if="field.value != null && field.value != ''">
                                  <v-icon
                                    color="green"
                                    slot="activator"
                                    style="cursor: pointer"
                                  >
                                    done
                                  </v-icon>
                                  <span>Field Ready</span>
                                </v-tooltip>
                                <template v-else>
                                  <template v-if="intToBool(field.required)">
                                    <v-tooltip top>
                                      <v-icon
                                        color="red"
                                        slot="activator"
                                        style="cursor: pointer"
                                      >
                                        error
                                      </v-icon>
                                      <span>Required Please Complete Field</span>
                                    </v-tooltip>
                                  </template>
                                  <template v-else>
                                    <v-tooltip top>
                                      <v-icon
                                        color="amber"
                                        slot="activator"
                                        style="cursor: pointer"
                                      >
                                        warning
                                      </v-icon>
                                      <span>Optional Please Complete Field</span>
                                    </v-tooltip>
                                  </template>
                                </template>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                        </template>
                      </v-layout>
                    </template>
                    <!--Fieldset Fields-->
                    <template v-if="fieldType2s.length > 0" class="pt-2">
                      <span class="body-2 greyType--text">Dataset Fields</span>
                      <v-layout row wrap class="pa-1">
                        <v-flex xs12 class="hover py-1 pl-2" style="cursor: pointer" v-for="(field, i) in fieldType2s" :key="field.fieldID">
                          <v-layout row align-center>
                            <v-flex xs12>
                              <span class="body-2">{{ field.fieldDescription }} </span>
                              &nbsp;-&nbsp;
                              <span class="body-1 font-weight-regular">{{ field.valueDescription }}</span>
                            </v-flex>
                            <v-flex shrink class="pr-2">
                              <v-tooltip top
                                         v-if="field.value != null && field.value != ''">
                                <v-icon
                                    color="green"
                                    slot="activator"
                                    style="cursor: pointer"
                                >
                                  done
                                </v-icon>
                                <span>Field Ready</span>
                              </v-tooltip>
                              
                              <template v-else>
                                <template v-if="intToBool(field.required)">
                                  <v-tooltip top>
                                    <v-icon
                                        color="red"
                                        slot="activator"
                                        style="cursor: pointer"
                                    >
                                      error
                                    </v-icon>
                                    <span>Required Please Complete Field</span>
                                  </v-tooltip>
                                </template>
                                <template v-else>
                                  <v-tooltip top>
                                    <v-icon
                                        color="amber"
                                        slot="activator"
                                        style="cursor: pointer"
                                    >
                                      warning
                                    </v-icon>
                                    <span>Optional Please Complete Field</span>
                                  </v-tooltip>
                                </template>
                              </template>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </template>
                    <!--Workflow Attachments-->
                    <template v-if="fieldType0s.length > 0" class="pt-2">
                      <span class="body-2 greyType--text">Attachments</span>
                      <v-layout row wrap class="pa-1">
                        <template v-for="(field, i) in fieldType0s">
                          <v-flex :key="i + '-' + field.fieldID" xs12 class="hover py-1 pl-2"
                                  style="cursor: pointer">

                            <v-layout row align-center>
                              <v-flex shrink v-if="field.value != null && field.value != '' && field.value_type != 1"
                                      class="pr-2">
                                <v-tooltip
                                    top
                                    v-for="document in getDoc(field.value).filter(v => !!v)"
                                    :key="document.documentID"
                                >
                                  <a
                                      v-if="hasAccess(document)"
                                      slot="activator"
                                      :href="serverAddress + '/documentstore/' + userInfo.entityID + '/' + document.documentID"
                                      :download="documentDownloadName(document)"
                                      target="_blank"
                                      style="text-decoration: none"
                                  >
                                    <v-avatar slot="activator" color="secondary"
                                              size="26">
                                      <v-icon small class="white--text"
                                              style="cursor: pointer">
                                        attach_file
                                      </v-icon>
                                    </v-avatar>
                                  </a>
                                  <v-avatar v-else slot="activator"
                                            color="secondary"
                                            size="26">
                                    <v-icon small class="white--text"
                                            style="cursor: pointer">
                                      attach_file
                                    </v-icon>
                                  </v-avatar>
                                  <span>{{ hasAccess(document) ? 'Open File' : 'This Document Has Been Restricted' }}</span>
                                </v-tooltip>
                              </v-flex>
                              <v-flex xs12>
                                <v-layout row align-center>
                                  <v-flex xs12>
                                    <span class="body-2">{{ field.mainDescr }}</span>
                                    &nbsp;&nbsp;-&nbsp;&nbsp;
                                    <span class="body-1 font-weight-regular">{{ field.subDescr }}</span>
                                  </v-flex>
                                  <v-flex shrink class="pr-2">
                                    <v-tooltip top
                                               v-if="field.value != null && field.value != '' && field.value_type != 1">
                                      <v-icon
                                        color="green"
                                        slot="activator"
                                        style="cursor: pointer"
                                      >
                                        done
                                      </v-icon>
                                      <span>Field Ready</span>
                                    </v-tooltip>
                                    <template v-else>
                                      <template v-if="intToBool(field.required)">
                                        <v-tooltip top>
                                          <v-icon
                                            color="red"
                                            slot="activator"
                                            style="cursor: pointer"
                                          >
                                            error
                                          </v-icon>
                                          <span>{{
                                              field.value_type == 1 ? 'Cannot attach a Mail to another Mail' : 'Required Please Complete Field'
                                            }}</span>
                                        </v-tooltip>
                                      </template>
                                      <template v-else>
                                        <v-tooltip top>
                                          <v-icon
                                            color="amber"
                                            slot="activator"
                                            style="cursor: pointer"
                                          >
                                            warning
                                          </v-icon>
                                          <span>{{
                                              field.value_type == 1 ? 'Cannot attach a Mail to another Mail' : 'Optional Please Complete Field'
                                            }}</span>
                                        </v-tooltip>
                                      </template>
                                    </template>
                                  </v-flex>
                                </v-layout>
                              </v-flex>
                            </v-layout>


                          </v-flex>
                        </template>
                      </v-layout>
                    </template>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
            <!--Loading-->
            <v-flex xs9 v-if="this.loadingSignature" class="text-xs-center pt-3">
              <v-progress-circular
                :size="40"
                :width="5"
                color="primary"
                indeterminate
              ></v-progress-circular>
            </v-flex>
            <!--Template-->
            <v-flex v-else xs9 class="pl-1">
              <v-card class="fill-height" style="background: var(--card-back)">
                <v-layout column fill-height style="overflow-y: auto">
                  <v-flex shrink>
                    <v-card-title class="py-2 pr-2 primary">
                      <span class="title font-weight-light white--text">Mail</span>
                    </v-card-title>
                  </v-flex>
                  <!--From And Signatures-->
                  <v-flex shrink>
                    <v-layout row align-center>
                      <!--From-->
                      <v-flex xs6>
                        <v-autocomplete
                          dense
                          :items="fromAddresses"
                          item-text="address"
                          label="From:"
                          hide-details
                          solo
                          flat
                          v-model="selectedSMTPAddress"
                          return-object
                        >
                          <template slot="prepend-inner">
                            <div class="pr-2">
                              <v-icon
                                v-if="selectedSMTPAddress == null || typeof selectedSMTPAddress.type == 'undefined'">
                                mail
                              </v-icon>
                              <template v-else>
                                <img v-if="selectedSMTPAddress.type == 'gmail'"
                                     src="../../../../../../../../public/logo_gmail_32px.svg" class="nav-item"
                                     height="22px">
                                <img v-if="selectedSMTPAddress.type == 'office'"
                                     src="../../../../../../../../public/outlook_32x1.svg" class="nav-item"
                                     height="32px">
                                <img v-if="selectedSMTPAddress.type == 'smtp'"
                                     src="../../../../../../../../public/smtp.svg" class="nav-item" height="32px">
                              </template>
                            </div>
                          </template>
                          <template slot="item" slot-scope="address">
                            <v-layout row align-center class="hide-parent">
                              <v-flex shrink class="pr-2">
                                <img v-if="address.item.type == 'gmail'"
                                     src="../../../../../../../../public/logo_gmail_32px.svg" class="nav-item"
                                     height="18px">
                                <img v-if="address.item.type == 'office'"
                                     src="../../../../../../../../public/outlook_32x1.svg" class="nav-item"
                                     height="22px">
                                <img v-if="address.item.type == 'smtp'" src="../../../../../../../../public/smtp.svg"
                                     class="nav-item" height="22px">
                              </v-flex>
                              <v-flex xs12 class="body-1">
                                {{ address.item.address }}
                              </v-flex>

                              <v-flex shrink
                                      v-if="defaultFrom.address == address.item.address && defaultFrom.type == address.item.type">
                                <v-tooltip top>
                                  <v-btn small icon flat color="amber" slot="activator" class="pa-0 ma-0">
                                    <v-icon>
                                      star
                                    </v-icon>
                                  </v-btn>
                                  <span>Default Send Address</span>
                                </v-tooltip>
                              </v-flex>

                              <v-flex shrink v-else class="hide-child">
                                <v-tooltip top>
                                  <v-btn small icon flat color="primary" slot="activator" class="pa-0 ma-0"
                                         @click="setDefaultSMTPAddress(address.item)">
                                    <v-icon>
                                      star_outline
                                    </v-icon>
                                  </v-btn>
                                  <span>Mark Address As Default</span>
                                </v-tooltip>
                              </v-flex>

                            </v-layout>
                          </template>
                        </v-autocomplete>
                      </v-flex>
                      <!--Signatures-->
                      <v-flex
                        v-if="template != null && template.forcedSignature == null && template.forcedSignature != 0"
                        xs6>
                        <v-select
                          :items="signatures"
                          item-text="description"
                          item-value="id"
                          v-model="selectedSignature"
                          label="Signature"
                          hide-details
                          dense
                          solo
                          flat
                          prepend-icon="edit"
                          :read-only="template.forcedSignature != null"
                        ></v-select>
                        <!--class="pl-2"-->

                      </v-flex>

                    </v-layout>
                  </v-flex>
                  <v-flex shrink>
                    <v-divider></v-divider>
                  </v-flex>
                  <!--To-->
                  <v-flex shrink class="c-d-flex c-align-center">
                    <div class="c-flex-grow-1">
                      <v-combobox
                        dense
                        :items="contacts"
                        item-text="display"
                        hide-details
                        prepend-inner-icon="people"
                        solo
                        flat
                        v-model="selectedTo"
                        hide-selected
                        ref="messageToInput"
                        multiple
                        :label="toPlaceHolderText"
                        @input="validateToEmailAddress($event)"
                      >
                        <template slot="item" slot-scope="address">
                          <v-icon left :color="!address.item.taskContact ? 'primary' : 'secondary'">label</v-icon>
                          <span class="body-1">{{ address.item.display }}</span>
                        </template>
                        <template
                          slot="selection"
                          slot-scope="address"
                        >
                          <div class="pr-2" style="padding-top: 2px; padding-bottom: 2px">
                            <v-layout shrink row align-center style="border-radius: 3px;"
                                      :style="typeof address.item.valid == 'undefined' || (typeof address.item.valid != 'undefined' && address.item.valid) ? 'border: 1px solid var(--v-primary-base)' : 'border: 1px solid var(--v-tRed-base)'">
                              <v-flex shrink class="caption pl-2">
                                <div
                                  class="font-weight-bold"
                                  :class="typeof address.item.valid == 'undefined' || (typeof address.item.valid != 'undefined' && address.item.valid) ? 'primary--text' : 'tRed--text'"
                                >{{
                                    address.item.name != null && address.item.name != '' ? address.item.name : address.item.display
                                  }}&nbsp;
                                </div>
                                <template
                                  v-if="typeof address.item.valid == 'undefined' || (typeof address.item.valid != 'undefined' && address.item.valid)">
                                  <div style="margin-top: -5px" class="greyType--text"
                                       v-if="address.item.name != null && address.item.name != ''">{{
                                      address.item.email
                                    }}&nbsp;
                                  </div>
                                </template>
                                <template v-else>
                                  <div style="margin-top: -5px" class="greyType--text">Invalid Email Address</div>
                                </template>
                              </v-flex>

                              <v-flex
                                shrink
                                class="pr-1"
                                style="padding-top: 2px; padding-bottom: 2px"
                              >
                                <v-btn
                                  :disabled="typeof address.item.nonRemovable != 'undefined' && address.item.nonRemovable === true"
                                  small
                                  icon
                                  flat
                                  class="pa-0 ma-0"
                                  color="primary"
                                  @click="address.parent.selectItem(address.item)"
                                >
                                  <!--@click="mailAttachments.splice(i, 1)"-->
                                  <v-icon small>close</v-icon>
                                </v-btn>
                              </v-flex>
                            </v-layout>
                          </div>
                        </template>
                      </v-combobox>
                    </div>

                    <div v-if="!showCc" class="pr-2">
                      <v-tooltip left>
                        <v-btn icon flat class="pa-0 ma-0" slot="activator" @click="showCc = true"
                               color="secondary">Cc
                        </v-btn>
                        <span>Add Cc Address</span>
                      </v-tooltip>
                    </div>
                  </v-flex>
                  <v-flex shrink>
                    <v-divider></v-divider>
                  </v-flex>
                  <!--Cc-->
                  <template v-if="showCc">
                    <v-flex shrink>
                      <v-combobox
                        dense
                        :items="contacts"
                        item-text="display"
                        hide-details
                        prepend-inner-icon="people_outline"
                        solo
                        flat
                        v-model="selectedCc"
                        hide-selected
                        ref="messageCcInput"
                        multiple
                        label="Cc:"
                        @input="validateCcEmailAddress($event)"
                      >
                        <template slot="item" slot-scope="address">
                          <v-icon left :color="!address.item.taskContact ? 'primary' : 'secondary'">label</v-icon>
                          <span class="body-1">{{ address.item.display }}</span>
                        </template>
                        <template
                          slot="selection"
                          slot-scope="address"
                        >
                          <div class="pr-2" style="padding-top: 2px; padding-bottom: 2px">
                            <v-layout shrink row align-center style="border-radius: 3px;"
                                      :style="typeof address.item.valid == 'undefined' || (typeof address.item.valid != 'undefined' && address.item.valid) ? 'border: 1px solid var(--v-primary-base)' : 'border: 1px solid var(--v-tRed-base)'">
                              <v-flex shrink class="caption pl-2">
                                <div
                                  class="font-weight-bold"
                                  :class="typeof address.item.valid == 'undefined' || (typeof address.item.valid != 'undefined' && address.item.valid) ? 'primary--text' : 'tRed--text'"
                                >{{
                                    address.item.name != null && address.item.name != '' ? address.item.name : address.item.display
                                  }}&nbsp;
                                </div>
                                <template
                                  v-if="typeof address.item.valid == 'undefined' || (typeof address.item.valid != 'undefined' && address.item.valid)">
                                  <div style="margin-top: -5px" class="greyType--text"
                                       v-if="address.item.name != null && address.item.name != ''">{{
                                      address.item.email
                                    }}&nbsp;
                                  </div>
                                </template>
                                <template v-else>
                                  <div style="margin-top: -5px" class="greyType--text">Invalid Email Address</div>
                                </template>
                              </v-flex>

                              <v-flex
                                shrink
                                class="pr-1"
                                style="padding-top: 2px; padding-bottom: 2px"
                              >
                                <v-btn
                                  :disabled="typeof address.item.nonRemovable != 'undefined' && address.item.nonRemovable === true"
                                  small
                                  icon
                                  flat
                                  class="pa-0 ma-0"
                                  color="primary"
                                  @click="address.parent.selectItem(address.item)"
                                >
                                  <!--@click="mailAttachments.splice(i, 1)"-->
                                  <v-icon small>close</v-icon>
                                </v-btn>
                              </v-flex>
                            </v-layout>
                          </div>
                        </template>
                      </v-combobox>
                    </v-flex>
                    <v-flex shrink>
                      <v-divider></v-divider>
                    </v-flex>
                  </template>
                  <!--Bcc-->
                  <template v-if="showCc">
                    <v-flex shrink>
                      <v-combobox
                        dense
                        :items="contacts"
                        item-text="display"
                        hide-details
                        prepend-inner-icon="admin_panel_settings"
                        solo
                        flat
                        v-model="selectedBcc"
                        hide-selected
                        ref="messageBccInput"
                        multiple
                        label="Bcc:"
                        @input="validateBccEmailAddress($event)"
                      >
                        <template slot="item" slot-scope="address">
                          <v-icon left :color="!address.item.taskContact ? 'primary' : 'secondary'">label</v-icon>
                          <span class="body-1">{{ address.item.display }}</span>
                        </template>
                        <template
                          slot="selection"
                          slot-scope="address"
                        >
                          <div class="pr-2" style="padding-top: 2px; padding-bottom: 2px">
                            <v-layout shrink row align-center style="border-radius: 3px;"
                                      :style="typeof address.item.valid == 'undefined' || (typeof address.item.valid != 'undefined' && address.item.valid) ? 'border: 1px solid var(--v-primary-base)' : 'border: 1px solid var(--v-tRed-base)'">
                              <v-flex shrink class="caption pl-2">
                                <div
                                  class="font-weight-bold"
                                  :class="typeof address.item.valid == 'undefined' || (typeof address.item.valid != 'undefined' && address.item.valid) ? 'primary--text' : 'tRed--text'"
                                >{{
                                    address.item.name != null && address.item.name != '' ? address.item.name : address.item.display
                                  }}&nbsp;
                                </div>
                                <template
                                  v-if="typeof address.item.valid == 'undefined' || (typeof address.item.valid != 'undefined' && address.item.valid)">
                                  <div style="margin-top: -5px" class="greyType--text"
                                       v-if="address.item.name != null && address.item.name != ''">{{
                                      address.item.email
                                    }}&nbsp;
                                  </div>
                                </template>
                                <template v-else>
                                  <div style="margin-top: -5px" class="greyType--text">Invalid Email Address</div>
                                </template>
                              </v-flex>

                              <v-flex
                                shrink
                                class="pr-1"
                                style="padding-top: 2px; padding-bottom: 2px"
                              >
                                <v-btn
                                  :disabled="typeof address.item.nonRemovable != 'undefined' && address.item.nonRemovable === true"
                                  small
                                  icon
                                  flat
                                  class="pa-0 ma-0"
                                  color="primary"
                                  @click="address.parent.selectItem(address.item)"
                                >
                                  <!--@click="mailAttachments.splice(i, 1)"-->
                                  <v-icon small>close</v-icon>
                                </v-btn>
                              </v-flex>
                            </v-layout>
                          </div>
                        </template>
                      </v-combobox>
                    </v-flex>
                    <v-flex shrink>
                      <v-divider></v-divider>
                    </v-flex>
                  </template>


                  <!--Subject-->
                  <v-flex shrink>
                    <v-text-field
                      dense
                      label="Subject:"
                      hide-details
                      prepend-inner-icon="subject"
                      solo
                      flat
                      v-model="sendMail.subject"
                      :readonly="template != null && typeof template.subject != 'undefined' && template.subject != null && template.subject != ''"
                    ></v-text-field>
                  </v-flex>
                  <v-flex shrink>
                    <v-divider></v-divider>
                  </v-flex>
                  <!--Attachments-->
                  <v-flex
                    shrink
                    class="pt-1 pb-0 px-1"
                  >
                    <!--:class="dragOverAttachments ? 'drop-zone' : ''"-->
                    <div
                      style="width: 100%"
                      @dragover="preventDefault($event)"
                      @drop="$event.preventDefault(); dropFiles($event); dragOverAttachments = false"
                      @dragenter="dragOverAttachments = true"
                      @dragleave="dragOverAttachments = false"
                    >
                      <v-layout row align-center>
                        <v-flex shrink class="pt-2 pb-2 pl-1 pr-2">
                          <v-icon class="greyType--text text--darken-2">attach_file</v-icon>
                        </v-flex>
                        <v-flex xs12>
                          <!--Place Holder Text-->
                          <template
                            v-if="mailAttachments.length <= 0 || mailAttachments.length === embeddedMailTemplateAttachments.length">
                            <span class="body-1 greyType--text">Attachments:</span>
                          </template>
                          <!--Existing Attachments-->
                          <template>
                            <v-layout row wrap align-center>
                              <p-d-f ref="pdfViewer"></p-d-f>
                              <template v-for="(attachment, i) in mailAttachments">
                                <v-flex shrink class="pr-2 pb-1" v-if="attachment.type !== 'embeddedUploadTemplate'"
                                        :key="'Attachments:' + i">
                                  <v-layout

                                    row align-center class="white--text"
                                    style="border-radius: 3px"
                                    :class="attachment.type != 'templateAttachment' ? 'primary' : attachment.color">
                                    <v-flex shrink class="pa-1 pl-2">

                                      <v-layout row align-center>
                                        <v-flex shrink>
                                          <v-tooltip
                                              top
                                              v-if="
                                            !['embeddedUpload', 'uploading', 'embeddedUploadUploading'].includes(attachment.type)
                                            && ['pdf', 'PDF'].includes(typeof attachment.filename != 'undefined' ? attachment.filename.split('.').pop() : typeof attachment.fileName != 'undefined' ? attachment.fileName.split('.').pop() : '')
                                            && attachment.documentDBID"
                                          >
                                            <template #activator="{on}">
                                              <v-icon
                                                  v-on="on"
                                                  color="white"
                                                  style="cursor: pointer"
                                                  small
                                                  class="pr-2"
                                                  @click="$newDownload(attachment)"
                                              >
                                                visibility
                                              </v-icon>
                                            </template>
                                            <span>Preview</span>
                                          </v-tooltip>
                                        </v-flex>
                                        <v-flex shrink>
                                          <v-tooltip top>
                                            <v-icon
                                              style="cursor: pointer"
                                              color="white"
                                              small
                                              v-if="!['embeddedUpload', 'uploading', 'embeddedUploadUploading'].includes(attachment.type)"
                                              @click="(attachment.type === 'templateAttachment' && attachment.value_type === 2) || attachment.type !== 'templateAttachment' ? runPreviewAttachment(attachment, true) : showSnack('Error', 'Please link a Document and not a Mail', 'Close', 'red')"
                                              slot="activator"
                                            >
                                              attach_file
                                            </v-icon>

                                            <span
                                              v-if="(attachment.type === 'templateAttachment' && attachment.value_type === 2) || attachment.type !== 'templateAttachment'">{{
                                                (attachment.type == 'templateAttachment' && blankString(attachment.value)) && (attachment.type != 'templateAttachment' ? (!(typeof attachment.filename != 'undefined' && typeof attachment.fileName == 'undefined') && !(typeof attachment.fileName != 'undefined' && typeof attachment.filename == 'undefined')) : false) ? 'No Preview Available' : 'Download'
                                              }}</span>
                                            <span v-else>Cannot download or send another mail as an attachment</span>
                                          </v-tooltip>


                                          <v-tooltip top>
                                            <v-icon
                                              slot="activator"
                                              color="white"
                                              small
                                              v-if="attachment.type == 'embeddedUpload'"
                                            >
                                              photo
                                            </v-icon>
                                            <span>Embedded Image</span>
                                          </v-tooltip>

                                          <v-progress-circular
                                            v-if="attachment.type == 'uploading'"
                                            :size="15"
                                            :width="3"
                                            color="white"
                                            :value="attachment.progress"
                                          />

                                          <v-progress-circular
                                            v-if="attachment.type == 'embeddedUploadUploading'"
                                            :size="15"
                                            :width="3"
                                            color="white"
                                            indeterminate
                                          />
                                        </v-flex>
                                      </v-layout>


                                    </v-flex>
                                    <v-flex shrink class="caption pa-1">
                                      {{
                                        !['taskDocument', 'fixedAttachment', 'templateAttachment'].includes(attachment.type) ? attachment.filename : attachment.displayName
                                      }}&nbsp;
                                    </v-flex>
                                    <v-flex
                                      shrink
                                      class="pr-1"
                                      style="border-bottom-right-radius: 3px; border-top-right-radius: 3px;"
                                      v-if="!['embeddedUpload', 'uploading', 'embeddedUploadUploading', 'fixedAttachment', 'templateAttachment'].includes(attachment.type)"
                                    >
                                      <v-tooltip top>
                                        <v-btn
                                          small
                                          icon
                                          flat
                                          class="pa-0 ma-0"
                                          color="white"
                                          slot="activator"
                                          @click="removeAttachment(attachment)"
                                        >
                                          <v-icon small>close</v-icon>
                                        </v-btn>
                                        <span>Remove Attachment</span>
                                      </v-tooltip>
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                              </template>
                            </v-layout>
                          </template>
                        </v-flex>
                        <v-flex shrink>
                          <v-menu left :close-on-content-click="false">
                            <v-btn
                              class="pa-0 ma-0"
                              flat
                              icon
                              color="secondary"
                              slot="activator"
                            >
                              <v-icon>add</v-icon>
                            </v-btn>
                            <v-card>
                              <div class="pa-2 subheading primary--text" style="white-space: pre">Add Attachments To
                                Mail
                              </div>
                              <v-divider></v-divider>
                              <v-list dense style="max-height: 300px; overflow-y: auto">
                                <v-list-tile @click="() => {}" class="text-xs-center">
                                  <div class="upload-btn-wrapper">
                                    <v-btn
                                      color="primary"
                                      flat
                                    >
                                      <v-icon left>cloud_upload</v-icon>
                                      Upload Attachments
                                    </v-btn>
                                    <input ref="manualUpload" type="file" name="myfile" multiple
                                           :accept="userInfo.entityInfo.mimeTypeList" @input="manualUploadRun()"/>
                                  </div>
                                </v-list-tile>
                                <template>
                                  <v-list-tile
                                      v-for="document in taskDocuments"
                                      :key="document.documentDBID"
                                      @click="mailAttachments = mailAttachments.concat([document])"
                                  >
                                    <v-icon color="grey" small left>assignment_turned_in</v-icon>
                                    {{ document.displayName }}
                                  </v-list-tile>
                                </template>
                              </v-list>
                            </v-card>
                          </v-menu>

                        </v-flex>
                        <v-flex shrink class="pl-2">
                          <v-tooltip left>
                            <v-btn

                              @click="addRepoDocs()"
                              class="pa-0 ma-0"
                              flat
                              icon
                              color="secondary"
                              slot="activator"

                            >
                              <v-icon>note_add</v-icon>
                            </v-btn>
                            <span>Add Document From Store</span>
                          </v-tooltip>
                        </v-flex>
                      </v-layout>
                    </div>
                  </v-flex>
                  <v-divider></v-divider>

                  <v-flex xs12 style="overflow-y: auto" class="scroll px-2">
                    <div v-html="replaceTaskVars"></div>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>

      <div style="position: fixed; bottom: 15px; right: 15px;">
        <v-tooltip left v-if="allowSend && !sendingMail">
          <div slot="activator">
            <v-btn
              fab
              color="secondary"
              @click="startSendRun"
            >
              <v-icon>send</v-icon>
            </v-btn>
          </div>
          <span>Send Mail</span>
        </v-tooltip>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>

import {codeBaseMixin} from "../../../../../../../codeBaseMixin";
import {eventBus} from "../../../../../../../main";
import {getLongDate, intToBool} from "../../../../../../../codeFunctions";
import axios from 'axios';
import Editor from '../../../../../../../Editor/Editor'
import DocView from "@/DocView/DocView";
import PDF from "@/components/General/PDF/PDF";
import {DatasetBinder} from "@/Tasks/TaskView V2/components/TaskViewDetail/datasetBinder";
import format from "date-fns/format";

export default {
  components: {PDF, DocView, Editor},
  mixins: [codeBaseMixin],
  props: {
    showDialog: Boolean,
    userInfo: Object,
    fields: Array,
    steps: Array,
    fieldSets: Array,
    mailTemplate: String,
    task: Object,
    step: Object,
    field: Object,
    mailConfig: {
      required: true,
      type: Object
    },
    binder: DatasetBinder
  },
  data() {
    return {
      console,
      fieldType0s: [],
      fieldType1s: [],
      fieldType2s: [],
      fieldType3s: [],
      ireset: 0,
      sendMail: {
        subject: null
      },
      forcedSignature: null,
      loadingSignature: false,
      selectedSignature: null,


      userContacts: [],
      toPlaceHolderText: 'To:',
      errorTo: false,
      sendingMail: false,
      selectedDocuments: [],

      selectedSMTPAddress: null,
      selectedToAddresses: [],
      selectedCcAddresses: [],
      selectedBccAddresses: [],
      addedAttachments: [],
      showCc: false
    }
  },
  created() {
    eventBus.$on(this.field.fieldID + 'sharedContacts', this.sharedContacts)
    eventBus.$on('ChangesSaved' + this.field.fieldID, this.changesSavedLocal)

  },
  beforeDestroy() {
    eventBus.$off(this.field.fieldID + 'sharedContacts', this.sharedContacts)
    eventBus.$off('ChangesSaved' + this.field.fieldID, this.changesSavedLocal)
  },
  watch: {
    sendingMail: {
      immediate: true,
      handler(val) {
        this.$emit('sending', val)
      }
    },

    selectedTo(val) {
      let i;
      for (i = 0; i < val.length; i++) {
        if (typeof val[i].name === 'undefined') {
          if (val[i].includes("@") === false || val[i].includes('.') === false) {
            val.splice(i, 1);
          }
        }
      }
    },


    showDialog: {
      immediate: true,
      handler(newVal) {
        if (newVal && this.userInfo.defaultSendAsAddress != null && this.fromAddresses.length > 0) {
          this.fromAddresses.forEach(address => {
            try {
              if (address.address == this.defaultFrom.address && address.type == this.defaultFrom.type) {
                this.selectedSMTPAddress = address
              }
            } catch (e) {
              // eslint-disable-next-line
              console.log(e)
            }
          });
        }

        if (newVal && this.userInfo.defaultSendAsAddress == null && this.fromAddresses.length > 0) {
          if (this.fromAddresses.length == 1) {
            this.selectedSMTPAddress = this.fromAddresses[0];
          }

          if (this.selectedSMTPAddress == null && this.fromAddresses.length >= 1) {
            this.selectedSMTPAddress = this.fromAddresses[0];
          }
        }


        if (newVal && this.template != null) {
          this.selectedDocuments = [];
          this.ireset = ++this.ireset;
          if (this.template.forcedSignature != null && !this.loadingSignature && this.forcedSignature == null) {
            this.getForcedSignature();
          } else {
            this.userInfo.signatures.forEach(signature => {
              if (this.intToBool(signature.default)) {
                this.selectedSignature = signature.id;
              }
            })
          }

          if (this.template != null && typeof this.template.subject != 'undefined' && this.template.subject != null && this.template.subject != '') {
            this.sendMail.subject = this.template.subject;
          }

          switch (this.template.defaultToAddressLookup) {
            case 0: {
              // To Any Address
              eventBus.$emit('shareContacts', this.field.fieldID)
            }
          }

          this.selectedTo = this.computedContacts;
        }
      }
    }
  },
  methods: {

    sharedContacts(contacts) {
      this.userContacts = contacts;
    },
    changesSavedLocal() {
      this.sendMailTemplate();
    },

    addRepoDocs() {
      function getFilename(alias, filename, ext) {
        if (alias) {
          if (alias.split('.').pop().toLowerCase() === ext.toLowerCase()) {
            return alias
          } else {
            return `${alias}.${ext}`
          }
        } else {
          return filename
        }
      }

      this.$getRepoDocs({
        multiple: true,
        allowUserDocs: true
      })
        .then((docs) => {
          this.mailAttachments = this.mailAttachments.concat(
            docs.map(doc => {
              doc.document.type = 'taskDocument';
              doc.document.displayName = doc.description;
              return doc.document
            })
          )
        })
        .catch(e => {
          if (e) {
            console.log(e)
            this.$snack.error('An Error Has Occurred')
          } else {
            console.log('User Canceled')
          }
        })
    },

    getPDFDownloadUrl(attachment) {
      let downloadAddress = '';
      switch (attachment.type) {
        case 'srcMailDoc': {
          switch (this.srcMailPath) {
            case 'task': {
              let mailPath = this.srcMail.fk_gmail_group_mailbox_folder == 0 ? this.srcMail.fk_import_user + '-' + this.srcMail.mailID : this.srcMail.fk_gmail_group_mailbox_folder + '-' + this.mail.mailID;
              return `${this.baseServerAddress}mailstore/${this.userInfo.entityID}/${mailPath}/${attachment.filename}`;
            }
            case 'gmail': {
              let mailPath = this.userInfo.userID + '-' + this.srcMail.id;
              return `${this.baseServerAddress}gmailcache/${mailPath}/${attachment.filename}`;
            }
            case 'office': {
              let mailPath = this.userInfo.userID + '-' + this.srcMail.id;
              return `${this.baseServerAddress}officecache/${mailPath}/${attachment.filename}`;
            }
          }
          break;
        }
        case 'taskDocument': {
          let downloadAddress = `${this.baseServerAddress}upload/download/${this.userInfo.entityID}/${attachment.documentID}/`;
          return downloadAddress + (attachment.alias == null ? attachment.fileName : attachment.alias.replace(/\.pdf$/g, '') + '.' + attachment.extention)
        }
        case 'fixedAttachment': {
          let downloadAddress = `${this.baseServerAddress}upload/download/${this.userInfo.entityID}/${attachment.documentID}/`;
          return downloadAddress + (attachment.alias == null ? attachment.fileName : attachment.alias.replace(/\.pdf$/g, '') + '.' + attachment.extention)
        }

        case 'templateAttachment': {
          return `${this.baseServerAddress}upload/downloadDoc/file/document/${this.userInfo.entityID}/${attachment.value}/`;
        }

        case 'uploadedAttachment': {
          let downloadAddress = `${this.baseServerAddress}upload/downloadTemp/${attachment.id}/${attachment.filename}`;
          if (typeof attachment.draftAttachment != 'undefined' && attachment.draftAttachment) {
            downloadAddress = `${this.baseServerAddress}upload/downloadCache/file/attachmentCache/${this.userInfo.entityID}/${this.draft.id}/${attachment.id}/${attachment.filename}`;
          }
          return downloadAddress;
        }

      }
    },
    runPreviewAttachment(attachment, download) {
      let downloadAddress = '';
      switch (attachment.type) {
        case 'srcMailDoc': {
          switch (this.srcMailPath) {
            case 'task': {
              let mailPath = this.srcMail.fk_gmail_group_mailbox_folder == 0 ? this.srcMail.fk_import_user + '-' + this.srcMail.mailID : this.srcMail.fk_gmail_group_mailbox_folder + '-' + this.mail.mailID;
              downloadAddress = `${this.baseServerAddress}mailstore/${this.userInfo.entityID}/${mailPath}/${attachment.filename}`;
              let win = window.open(downloadAddress, '_blank');
              break;
            }
            case 'gmail': {
              let mailPath = this.userInfo.userID + '-' + this.srcMail.id;
              downloadAddress = `${this.baseServerAddress}gmailcache/${mailPath}/${attachment.filename}`;
              let win = window.open(downloadAddress, '_blank');
              break;
            }
            case 'office': {
              let mailPath = this.userInfo.userID + '-' + this.srcMail.id;
              downloadAddress = `${this.baseServerAddress}officecache/${mailPath}/${attachment.filename}`;
              let win = window.open(downloadAddress, '_blank');
              break;
            }
          }
          break;
        }
        case 'taskDocument': {
          let downloadAddress = `${this.baseServerAddress}upload/download/${this.userInfo.entityID}/${attachment.documentID}/`;
          downloadAddress = downloadAddress + (attachment.alias == null ? attachment.fileName : attachment.alias + '.' + attachment.extention)
          let win = window.open(downloadAddress, '_blank');
          break;
        }
        case 'fixedAttachment': {
          let downloadAddress = `${this.baseServerAddress}upload/download/${this.userInfo.entityID}/${attachment.documentID}/`;
          downloadAddress = downloadAddress + (attachment.alias == null ? attachment.fileName : attachment.alias + '.' + attachment.extention)
          let win = window.open(downloadAddress, '_blank');
          break;
        }

        case 'templateAttachment': {
          let downloadAddress = `${this.baseServerAddress}upload/downloadDoc/file/document/${this.userInfo.entityID}/${attachment.value}/`;
          let win = window.open(downloadAddress, '_blank');
          break;
        }

        case 'uploadedAttachment': {
          let downloadAddress = `${this.baseServerAddress}upload/downloadTemp/${attachment.id}/${attachment.filename}`;
          if (typeof attachment.draftAttachment != 'undefined' && attachment.draftAttachment) {
            downloadAddress = `${this.baseServerAddress}upload/downloadCache/file/attachmentCache/${this.userInfo.entityID}/${this.draft.id}/${attachment.id}/${attachment.filename}`;
          }
          let win = window.open(downloadAddress, '_blank');
          break;
        }

      }
    },
    removeAttachment(attachment) {
      switch (attachment.type) {
        case 'taskDocument': {
          this.mailAttachments = this.mailAttachments.filter(obj => obj.type != 'taskDocument' || (obj.type == 'taskDocument' && obj.documentDBID != attachment.documentDBID));
          break;
        }
        case 'uploadedAttachment': {
          this.mailAttachments = this.mailAttachments.filter(obj => obj.type != 'uploadedAttachment' || (obj.type == 'uploadedAttachment' && obj.id != attachment.id));
          break;
        }
      }

    },
    manualUploadRun() {
      function checkFileType(mimeList, file) {
        let list = mimeList
          .split(',')
          .map(str => {
            try {
              return str.trim().toLowerCase()
            } catch (e) {
              return ''
            }
          })
        let typeList = list
          .filter(str => str && str.substr(0, 1) !== '.')
        let extList = list
          .filter(str => str && str.substr(0, 1) === '.')
          .map(str => {
            try {
              return str.substring(1)
            } catch (e) {
              return ''
            }
          })
        let ext = file.name ? file.name.split('.').pop().toLowerCase() : ''
        let type = file.type ? file.type.toLowerCase().trim() : ''
        return typeList.includes(type) || extList.includes(ext)
      }

      for (let i = 0; i < this.$refs.manualUpload.files.length; i++) {
        let file = this.$refs.manualUpload.files[i];
        if (((file.size / 1024) / 1024) < 50) {
          if (checkFileType(this.userInfo.entityInfo.mimeTypeList, file)) {
            this.uploadFile(file);
          } else {
            this.showSnack('Error', 'File Upload Canceled - Invalid File Type', 'Close', 'red');
          }
        } else {
          this.showSnack('Error', 'File Upload Canceled - File To Large', 'Close', 'red')
        }
      }

      this.$refs.manualUpload.value = "";
    },
    dropFiles(e) {
      function checkFileType(mimeList, file) {
        let list = mimeList
          .split(',')
          .map(str => {
            try {
              return str.trim().toLowerCase()
            } catch (e) {
              return ''
            }
          })
        let typeList = list
          .filter(str => str && str.substr(0, 1) !== '.')
        let extList = list
          .filter(str => str && str.substr(0, 1) === '.')
          .map(str => {
            try {
              return str.substring(1)
            } catch (e) {
              return ''
            }
          })
        let ext = file.name ? file.name.split('.').pop().toLowerCase() : ''
        let type = file.type ? file.type.toLowerCase().trim() : ''
        return typeList.includes(type) || extList.includes(ext)
      }

      for (let i = 0; i < e.dataTransfer.files.length; i++) {
        let file = e.dataTransfer.files[i];
        if (((file.size / 1024) / 1024) < 50) {
          if (checkFileType(this.userInfo.entityInfo.mimeTypeList, file)) {
            this.uploadFile(file);
          } else {
            this.showSnack('Error', 'File Upload Canceled - Invalid File Type', 'Close', 'red');
          }
        } else {
          this.showSnack('Error', 'File Upload Canceled - File To Large', 'Close', 'red')
        }
      }
    },
    uploadFile(file) {
      let h = new Headers();
      let fd = new FormData();
      let myFile = file;
      fd.append('attachmentFile', myFile);

      let newFile = {
        type: 'uploading',
        filename: file.name,
        id: null,
        progress: 0
      };

      this.mailAttachments = this.mailAttachments.concat(newFile);

      axios.request({
        method: "post",
        url: `${this.serverAddress}/upload/mailAttachment/`,
        data: fd,


        onUploadProgress: (p) => {
          newFile.progress = (p.loaded / p.total) * 100
        },
      }).then(data => {
        delete newFile.progress;
        newFile.link = data.data.link;
        newFile.id = data.data.id;
        newFile.type = 'uploadedAttachment';
        newFile.mime = file.type;
        newFile.size = file.size;
      })
    },
    validateToEmailAddress() {
      this.$refs.messageToInput.$data.lazySearch = '';
      this.$refs.messageToInput.$data.isMenuActive = false;
      this.selectedTo = this.selectedTo.reduce((arrTo, address) => {
        if (typeof address == 'string') {
          if (this.validateEmail(address)) {
            return arrTo.concat([{name: '', email: address.trim(), display: address.trim(), taskContact: false}]);
          } else {
            this.showSnack('Error', 'Invalid Email Address', 'Close', 'red');
            return arrTo
          }
        } else {
          return arrTo.concat([address]);
        }
      }, []);
    },
    validateCcEmailAddress() {
      this.$refs.messageCcInput.$data.lazySearch = '';
      this.$refs.messageCcInput.$data.isMenuActive = false;
      this.selectedCc = this.selectedCc.reduce((arrTo, address) => {
        if (typeof address == 'string') {
          if (this.validateEmail(address)) {
            return arrTo.concat([{name: '', email: address.trim(), display: address.trim(), taskContact: false}]);
          } else {
            this.showSnack('Error', 'Invalid Email Address', 'Close', 'red');
            return arrTo
          }
        } else {
          return arrTo.concat([address]);
        }
      }, []);
    },
    validateBccEmailAddress() {
      this.$refs.messageBccInput.$data.lazySearch = '';
      this.$refs.messageBccInput.$data.isMenuActive = false;
      this.selectedBcc = this.selectedBcc.reduce((arrTo, address) => {
        if (typeof address == 'string') {
          if (this.validateEmail(address)) {
            return arrTo.concat([{name: '', email: address.trim(), display: address.trim(), taskContact: false}]);
          } else {
            this.showSnack('Error', 'Invalid Email Address', 'Close', 'red');
            return arrTo
          }
        } else {
          return arrTo.concat([address]);
        }
      }, []);
    },
    resetTo() {
      this.$refs.TemplateCCField.$data.lazySearch = '';
      this.$refs.TemplateCCField.$data.isMenuActive = false;
    },
    documentDownloadName(document) {
      if (document.alias == null) {
        return document.fileName
      } else {
        return document.alias + '.' + document.extention
      }
    },
    hasAccess(document) {
      if (typeof document.securityLookup != 'undefined' && document.securityLookup != null) {
        let groupsWithAccess = []
        try {
          groupsWithAccess = JSON.parse(document.securityLookup)
        } catch (e) {
          return true
        }
        return this.userInfo.userGroups.filter(element => groupsWithAccess.includes(element)).length > 0
      } else {
        return true
      }
    },
    getDoc(docID) {
      try {
        return [this.task.structure.documents.filter(obj => obj.documentDBID == docID)[0]]
      } catch (e) {
        // eslint-disable-next-line
        console.log(e)
        return null
      }
    },
    clearType0s() {
      this.fieldType0s = [];
    },
    pushType0(id) {
      this.fieldType0s.push(id);
      return this.fieldType0s.length - 1
    },
    clearType1s() {
      this.fieldType1s = [];
    },
    pushType1(id) {
      this.fieldType1s.push(id);
      return this.fieldType1s.length - 1
    },
    clearType2s() {
      this.fieldType2s = [];
    },
    pushType2(id) {
      this.fieldType2s.push(id);
      return this.fieldType2s.length - 1
    },
    clearType3s() {
      this.fieldType3s = [];
    },
    pushType3(id) {
      this.fieldType3s.push({key: id, text: ''});
      return this.fieldType3s.length - 1
    },
    getForcedSignature() {
      this.loadingSignature = true;
      this.fetch({
        method: 'GET',
        url: '/tasks/GetForceSignature/' + this.userInfo.entityID + '/' + this.userInfo.userID + '/' + this.template.forcedSignature
      })
        .then((data) => {
          this.forcedSignature = data;
          this.loadingSignature = false;
          this.selectedSignature = data.id;
        })
        .catch((e) => {
          // eslint-disable-next-line
          console.log(e)
          this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
          this.loadingSignature = false;
        })
    },
    replaceInSignature(signature) {
//     <!--bodyStart-->
      // <p style=" font-size: medium;">...</p>
//     <!--bodyEnd-->
      let body = this.replacedHTML;
      return body + signature;
      // if (signature.includes('<div id="body" style="width: 96%; text-align: center; font-weight: bold; color: white; background: #1565C0; padding: 10px; border-radius: 5px;">BODY</div>')) {
      //     // Signature Has A Body Replace Area
      //     return signature.replace(`<div id="body" style="width: 96%; text-align: center; font-weight: bold; color: white; background: #1565C0; padding: 10px; border-radius: 5px;">BODY</div>`, body)
      //         .replace(`id="reply" style="`, 'id="reply" style="display: none;');
      // } else {
      //     ;
      // }

    },
    updatePlaceHolderText(text) {
      this.toPlaceHolderText = text
    },
    updateErrorTo(value) {
      this.errorTo = value
    },
    updateSelectedTo(value) {
      this.selectedTo = value
    },
    validateEmails(string) {
      // eslint-disable-next-line no-useless-escape
      var regex = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
      var result = string.replace(/\s/g, "").split(/,|;/);
      for (var i = 0; i < result.length; i++) {
        if (!regex.test(result[i])) {
          return false;
        }
      }
      return true;
    },
    setDefaultSMTPAddress(address) {
      this.userInfo.defaultSendAsAddress = JSON.stringify(address);
      this.fetch({
        method: 'POST',
        url: `/users/updateDefaultSmtp/${this.userInfo.entityID}/${this.userInfo.userID}`,
        body: JSON.stringify(address)
      })
        .then((data) => {
          this.showSnack('Info', 'Default Send Address Has Been Updated', 'Close', 'primary')
        })
        .catch((e) => {
          // eslint-disable-next-line
          console.log(e);
          this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
        })
    },
    startSendRun() {

      this.sendingMail = true;
      let tBody = JSON.stringify({
        linkTaskID: this.task.taskID,
        taskStepID: this.step.stepID,
        selectedSMTPAddress: this.selectedSMTPAddress,
        messageTo: this.selectedTo,
        messageCc: this.selectedCc,
        messageBcc: this.selectedBcc,
        mailAttachments: this.mailAttachments,
        subject: this.sendMail.subject,
        body: this.replaceSignature
          .replace(/{{taskid}}/gi, this.task.taskID)
          .replace(/{{now}}/gi, getLongDate(new Date()))
          .replace(/{{tasktitle}}/gi, this.task.title),
        displayName: `${this.userInfo.name} ${this.userInfo.surname}`
      });
      this.fetch({
        method: 'POST',
        url: `/sendMail/sendTemplate/${this.userInfo.entityID}/${this.userInfo.userID}`,
        body: tBody
      })
        .then((data) => {
          this.sendingMail = false;
          this.$emit('dismiss', false);
          let oldSendMail = JSON.parse(tBody);

          if (oldSendMail.linkTaskID == data.task.taskID) {

            // Push The New Mail
            this.$emit('pushTaskMail', data.mail);
            this.showSnack('Info', 'Message Has Been Sent', 'Close', 'primary')
            try {
              let mail = data.mail;
              this.task.structure.mails.push(mail);
              this.field.value = mail.mailDBID;
              this.mailAttachments = [];
              eventBus.$emit('mailTemplateSaveSent', true)
            } catch (e) {
              // eslint-disable-next-line
              console.log(e)
              this.showSnack('Info', 'Mail Sent But Something Went Wrong With Display', 'Close', 'primary')
            }
          }
        })
        .catch((e) => {
          this.sendingMail = false;
          // eslint-disable-next-line
          try {
            if (typeof e === 'object' && typeof e.status !== 'undefined' && e.status === 399) {
              eventBus.$emit('mailSizeError', e.data)
            } else {
              this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
            }
          } catch (e) {
            // eslint-disable-next-line
            console.log(e);
            this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
          }
        })

    },
  },
  computed: {

    editorConfig() {
      // testDrop(event, editor) {
      //     console.log('DROP EVENT', event)
      //
      //     editor.insertContent('<span style="background: fuchsia; display: inline-block; cursor: pointer" :draggable="true">Test123456</span>')
      // },
      // paste_data_images: true,
      // images_upload_handler: this.uploadImage,
      // init_instance_callback: function(editor) {
      //     editor.on('drop', function(e) {
      //         vm.testDrop(e, editor)
      // });
      // },
      // uploadImage(blobInfo, success) {
      //     ++this.imageCount
      //     let h = new Headers();
      //     let fd = new FormData();
      //     let myFile = blobInfo.blob();
      //     fd.append('attachmentFile', myFile);
      //
      //     axios.request({
      //         method: "post",
      //         url: `${this.serverAddress}/upload/noteAttachment/${this.userInfo.entityID}`,
      //         data: fd,
      //         onUploadProgress: (p) => {
      //             this.uploadProgress = (p.loaded / p.total) * 100
      //         },
      //     })
      //         .then(data => {
      //             success(data.data.link);
      //             --this.imageCount;
      //         })
      //         .catch(() => {
      //             this.showSnack('Error', 'Image Upload Error', 'Close', 'red');
      //             success('.blank');
      //             --this.imageCount;
      //         })
      // },

      return {
        paste_data_images: false,
        // images_upload_handler: this.uploadImage,
        remove_linebreaks: false,
        init_instance_callback: function (editor) {
          editor.focus()
        },
      }
    },
    distinctType1s() {
      let arrAddedFields = [];
      return this.fieldType1s.reduce((list, field) => {
        if (typeof field != 'undefined' && typeof field.value != 'undefined') {
          if (arrAddedFields.includes(field.fieldID)) {
            return list
          } else {
            arrAddedFields.push(field.fieldID)
            list.push(field);
            return list
          }
        } else {
          return list
        }

      }, []);
    },
    mailAttachments: {
      // getter
      get: function () {
        return this.fixedMailTemplateAttachments.map(att => {
          att.type = 'fixedAttachment';
          att.displayName = att.alias != null && att.alias != '' ? att.alias : att.fileName;
          return att
        }).concat(
          this.fieldType0s.map(att => {
            att.type = 'templateAttachment'
            if (att.value != null && att.value != '') {
              att.color = att.value_type == 2 ? 'primary' : 'red';
            } else {
              if (att.required == 0) {
                att.color = 'orange'
              } else {
                att.color = 'red'
              }
            }
            att.displayName = att.mainDescr + ' - ' + att.subDescr;
            return att;
          })
        ).concat(this.addedAttachments).concat(this.embeddedMailTemplateAttachments)
      },
      // setter
      set: function (newValue) {
        this.addedAttachments = newValue.filter(obj => obj.type != 'fixedAttachment' && obj.type != 'templateAttachment');
      }
    },
    taskDocuments() {
      let taskDocsOnMail = this.mailAttachments.filter(doc => doc.type == 'taskDocument').map(obj => {
        return obj.documentDBID
      });
      try {
        return this.task.structure.documents.filter(obj => !taskDocsOnMail.includes(obj.documentDBID)).map(doc => {
          doc.type = 'taskDocument';
          doc.displayName = doc.fileName;
          if (doc.alias != null && doc.alias != "") {
            doc.displayName = doc.alias
          }
          return doc
        }).sort((a, b) => a['displayName'] < b['displayName'] ? -1 : 1);
      } catch (e) {
        // eslint-disable-next-line
        console.log(e)
        return []
      }
    },
    taskContacts() {
      try {
        return this.task.structure.mails
          .map((mail) => {
            return [
              typeof mail.from != 'undefined' && mail.from != null && mail.from != '' ? `${mail.from}, ` : '',
              typeof mail.to != 'undefined' && mail.to != null && mail.to != '' ? `${mail.to}, ` : '',
              typeof mail.cc != 'undefined' && mail.cc != null && mail.cc != '' ? `${mail.cc}, ` : ''
            ].join('')
          })
          .join(', ')
          .split(',')
          .reduce((accumulator, address) => {
            if (!address || !address.trim())
              return accumulator
            if (address.match(/<[^@]+@[^@]+>/)) {
              // Name And Email
              return accumulator.concat([{
                      name: address.match(/[^<]+/).shift().trim(),
                      email: address.match(/<[^@]+@[^@]+>/).at(-1).match(/[^<]+@[^>]*/).at(-1).trim()
                    }])
            } else {
              // Just Email
              return accumulator.concat([{
                name: '',
                email: address.replace(/<undefined>/, '').trim()
              }])
            }
          }, [])
          .filter(addr => !!addr.email)
          .map(address => {
            address.taskContact = true;
            address.display = address.name != null && address.name.trim() != "" ? `${address.name} <${address.email}>` : address.email;
            return address;
          })
          .sort((a, b) => a['display'] < b['display'] ? -1 : 1);
      } catch (e) {
        // eslint-disable-next-line
        console.log(e);
        return [];
      }
    },
    contacts() {
      try {
        let arrContacts = [];
        if (this.userInfo.gmailEnabled == true && this.mailConfig.gmail.running && !this.mailConfig.gmail.loadingContacts) {
          arrContacts = arrContacts.concat(this.mailConfig.gmail.contacts);
        }
        if (this.userInfo.office365Enabled == true && this.mailConfig.office.running && !this.mailConfig.office.loadingContacts) {
          arrContacts = arrContacts.concat(this.mailConfig.office.contacts);
        }
        try {
          if (this.mailConfig.smtp && this.mailConfig.smtp.contacts) {
            arrContacts = arrContacts.concat(this.mailConfig.smtp.contacts);
          }
        } catch (e) {
          // eslint-disable-next-line
          console.log(e)
        }
        let resContacts = [];
        let tRefEmails = [];
        arrContacts.forEach(contact => {
          if (!tRefEmails.includes(contact.email)) {
            tRefEmails.push(contact.email);
            resContacts.push(contact);
          }
        });
        return this.computedContacts.concat(this.taskContacts.concat(resContacts.map(obj => {
          if (obj.name != null && obj.name.trim() != "") {
            obj.display = `${obj.name} <${obj.email}>`;
          } else {
            obj.display = obj.email;
          }
          obj.taskContact = false;
          return obj;
        }).sort((a, b) => a['display'] < b['display'] ? -1 : 1)));
      } catch (e) {
        // eslint-disable-next-line
        console.log(e)
        return [];
      }
    },
    fromAddresses() {
      let tAddresses = [];
      try {
        tAddresses = tAddresses.concat(this.mailConfig.gmail.addresses.map(address => {
          address.type = 'gmail';
          return address
        }))
      } catch (e) {
        // Do Nothing
      }
      try {
        tAddresses = tAddresses.concat(this.mailConfig.office.addresses.map(address => {
          address.type = 'office';
          return address
        }))
      } catch (e) {
        // Do Nothing
      }
      try {
        tAddresses = tAddresses.concat(this.mailConfig.smtp.addresses.map(address => {
          address.type = 'smtp';
          if (!address.name)
            address.name = address.email
          return address
        }))
      } catch (e) {
        // Do Nothing
      }

      return tAddresses;
    },
    defaultFrom() {
      try {
        if (this.userInfo.defaultSendAsAddress == null) {
          throw 'No Default Address Set'
        }
        return JSON.parse(this.userInfo.defaultSendAsAddress)
      } catch (e) {
        // eslint-disable-next-line
        console.log(e);
        return {
          address: 'null',
          type: 'null'
        }
      }
    },
    selectedTo: {
      // getter
      get: function () {
        return this.selectedToAddresses;
      },
      // setter
      set: function (newValue) {
        this.selectedToAddresses = newValue
      }
    },
    selectedCc: {
      // getter
      get: function () {
        return this.selectedCcAddresses;
      },
      // setter
      set: function (newValue) {
        this.selectedCcAddresses = newValue
      }
    },
    selectedBcc: {
      // getter
      get: function () {
        return this.selectedBccAddresses;
      },
      // setter
      set: function (newValue) {
        this.selectedBccAddresses = newValue
      }
    },
    computedContacts() {
      if (this.template != null) {
        switch (this.template.defaultToAddressLookup) {
          case 1: {
            try {
              let addresses = this.template.defaultToAddress.split(/,|;/);

              return addresses.map(addr => {
                return {
                  display: addr.trim(),
                  email: addr.trim(),
                  taskContact: false,
                  name: null,
                  nonRemovable: true,
                  valid: this.validateEmail(addr.trim())
                }
              })
            } catch (e) {
              // eslint-disable-next-line
              console.log(e)
              return []
            }
          }
          case 4: {
            try {
              let addresses = this.template.defaultToAddress.split(/,|;/);

              return addresses.map(addr => {
                return {
                  display: addr.trim(),
                  email: addr.trim(),
                  taskContact: false,
                  name: null,
                  nonRemovable: false,
                  valid: this.validateEmail(addr.trim())
                }
              })
            } catch (e) {
              // eslint-disable-next-line
              console.log(e)
              return []
            }
          }
          case 2: {
            // To Field Set Address
            try {
              let tFieldSetSelect = this.fields.filter(obj => obj.fieldID == this.template.defaultToAddress.fieldSetSelectField)[0];

              if (tFieldSetSelect.value == null || tFieldSetSelect.value == '') {
                return [{
                  display: tFieldSetSelect.description,
                  email: tFieldSetSelect.description,
                  taskContact: false,
                  name: null,
                  nonRemovable: true,
                  valid: false
                }]
              } else {

                let fieldSetMemberID = tFieldSetSelect.value;
                let fieldSetField = JSON.parse(this.fieldSets.filter(obj => obj.memberID == fieldSetMemberID)[0].fields).filter(obj => obj.fieldID == this.template.defaultToAddress.field)[0];

                if (fieldSetField.value == null || fieldSetField.value == '') {
                  return [{
                    display: fieldSetField.fieldDescription,
                    email: fieldSetField.fieldDescription,
                    taskContact: false,
                    name: null,
                    nonRemovable: true,
                    valid: false
                  }]
                } else {
                  let addresses = fieldSetField.value.split(/,|;/);

                  return addresses.map(addr => {
                    return {
                      display: addr.trim(),
                      email: addr.trim(),
                      taskContact: false,
                      name: null,
                      nonRemovable: true,
                      valid: this.validateEmail(addr.trim())
                    }
                  })
                }
              }


            } catch (e) {
              // eslint-disable-next-line
              console.log(e)
              return []
            }
          }
          case 5: {
            // To Field Set Address
            try {
              let tFieldSetSelect = this.fields.filter(obj => obj.fieldID == this.template.defaultToAddress.fieldSetSelectField)[0];

              if (tFieldSetSelect.value == null || tFieldSetSelect.value == '') {
                return [{
                  display: tFieldSetSelect.description,
                  email: tFieldSetSelect.description,
                  taskContact: false,
                  name: null,
                  nonRemovable: false,
                  valid: false
                }]
              } else {

                let fieldSetMemberID = tFieldSetSelect.value;
                let fieldSetField = JSON.parse(this.fieldSets.filter(obj => obj.memberID == fieldSetMemberID)[0].fields).filter(obj => obj.fieldID == this.template.defaultToAddress.field)[0];

                if (fieldSetField.value == null || fieldSetField.value == '') {
                  return [{
                    display: fieldSetField.fieldDescription,
                    email: fieldSetField.fieldDescription,
                    taskContact: false,
                    name: null,
                    nonRemovable: false,
                    valid: false
                  }]
                } else {
                  let addresses = fieldSetField.value.split(/,|;/);

                  return addresses.map(addr => {
                    return {
                      display: addr.trim(),
                      email: addr.trim(),
                      taskContact: false,
                      name: null,
                      nonRemovable: false,
                      valid: this.validateEmail(addr.trim())
                    }
                  })
                }
              }


            } catch (e) {
              // eslint-disable-next-line
              console.log(e)
              return []
            }
          }
          case 3: {
            // To Field Workflow Field
            try {
              let tWorkflowField = this.fields.filter(obj => obj.fieldID == this.template.defaultToAddress)[0];

              if (tWorkflowField.value == null || tWorkflowField.value == '') {
                return [{
                  display: tWorkflowField.description,
                  email: tWorkflowField.description,
                  taskContact: false,
                  name: null,
                  nonRemovable: true,
                  valid: false
                }]
              } else {
                let addresses = tWorkflowField.value.split(/,|;/);

                return addresses.map(addr => {
                  return {
                    display: addr.trim(),
                    email: addr.trim(),
                    taskContact: false,
                    name: null,
                    nonRemovable: true,
                    valid: this.validateEmail(addr.trim())
                  }
                })
              }
            } catch (e) {
              // eslint-disable-next-line
              console.log(e)
              return []
            }
          }
          case 6: {
            // To Field Workflow Field
            try {
              let tWorkflowField = this.fields.filter(obj => obj.fieldID == this.template.defaultToAddress)[0];

              if (tWorkflowField.value == null || tWorkflowField.value == '') {
                return [{
                  display: tWorkflowField.description,
                  email: tWorkflowField.description,
                  taskContact: false,
                  name: null,
                  nonRemovable: false,
                  valid: false
                }]
              } else {
                let addresses = tWorkflowField.value.split(/,|;/);

                return addresses.map(addr => {
                  return {
                    display: addr.trim(),
                    email: addr.trim(),
                    taskContact: false,
                    name: null,
                    nonRemovable: false,
                    valid: this.validateEmail(addr.trim())
                  }
                })
              }
            } catch (e) {
              // eslint-disable-next-line
              console.log(e)
              return []
            }
          }
          default : {
            return []
          }

        }
      } else {
        return []
      }
    },
    signatures() {
      if (this.template != null) {
        if (this.template.forcedSignature == null) {
          return this.userInfo.signatures
        } else {
          if (!this.loadingSignature) {
            return [this.forcedSignature];
          } else {
            return [];
          }

        }
      } else {
        return null
      }
    },
    template() {
      try {
        let tTemplate = null;

        try {
          tTemplate = JSON.parse(this.mailTemplate);
          tTemplate.body.body = tTemplate.body.body.split("#Q!").join('"');
          return tTemplate
        } catch (e) {
          // eslint-disable-next-line
          console.log(e)
          return null
        }
      } catch (e) {
        // eslint-disable-next-line
        console.log(e)
        return null
      }
    },
    fixedMailTemplateAttachments() {
      try {
        let tTemplate = null;
        try {
          tTemplate = JSON.parse(this.mailTemplate);
          tTemplate = typeof tTemplate.body.fixedDocuments != 'undefined' ? tTemplate.body.fixedDocuments : [];
          return tTemplate
        } catch (e) {
          // eslint-disable-next-line
          console.log(e);
          return []
        }
      } catch (e) {
        // eslint-disable-next-line
        console.log(e);
        return []
      }
    },
    embeddedMailTemplateAttachments() {
      try {
        let tTemplate = null;
        try {
          tTemplate = JSON.parse(this.mailTemplate);
          tTemplate = typeof tTemplate.body.mailAttachments != 'undefined' ? tTemplate.body.mailAttachments.filter(obj => obj.type === 'embeddedUploadTemplate') : [];
          return tTemplate
        } catch (e) {
          // eslint-disable-next-line
          console.log(e);
          return []
        }
      } catch (e) {
        // eslint-disable-next-line
        console.log(e);
        return []
      }
    },
    type0PassOver() {
      try {
        this.clearType0s();
        if (typeof this.template.body.workflowAttachments != "undefined" && this.template.body.workflowAttachments != null && this.template.body.workflowAttachments.length > 0) {
          //Load Up Attachments
          this.template.body.workflowAttachments.forEach(attachment => {
            // {type: 1, fieldID: 1986}
            // {type: 2, fieldID: 20, selectFieldID: 1975}
            if (attachment.type == 1) {
              let pushObj = {};
              let field = this.fields.filter(obj => obj.fieldID == attachment.fieldID && obj.ruleShow);
              if (field.length > 0) {
                field = field[0];
                pushObj.subDescr = field.description;
                pushObj.mainDescr = this.steps.filter(obj => obj.stepID == field.stepID)[0].description;
                pushObj.value = field.value;
                pushObj.value_type = field.value_type;
                pushObj.required = field.required;
                this.pushType0(pushObj)
              }

            } else {
              //Type 2

              let sendObj = {};

              let selectField = null;
              let fieldSet = null;
              let fieldSetField = null;

              try {
                selectField = this.fields.filter(object => object.fieldID == attachment.selectFieldID)[0];
                sendObj.mainDescr = selectField.description;
                sendObj.required = 1;

                if (selectField.value != null && selectField.value != '') {


                  fieldSet = this.binder.datasets[selectField.value];
                  console.log('Field SET', fieldSet, attachment)

                  fieldSetField = fieldSet.fields.filter(object => object.datasetFieldID == attachment.fieldID)[0];

                  // fieldSet = this.fieldSets.filter(object => object.memberID == selectField.value)[0];
                  // fieldSetField = JSON.parse(fieldSet.fields).filter(object => object.fieldID == attachment.fieldID)[0];

                  try {
                    sendObj.subDescr = fieldSetField.fieldDescription || 'No Document Selected';
                    sendObj.value = fieldSetField.value || null;
                    sendObj.value_type = fieldSetField.valueType || 6;
                    sendObj.required = fieldSetField.required || true;
                  } catch (e) {
                    sendObj.subDescr = 'Field Not Populated'
                    sendObj.valueDescription = 'Field Not Populated';
                    sendObj.value = null;
                    sendObj.required = false;
                  }
                } else {
                  sendObj.subDescr = 'No Dataset Selected';
                  sendObj.value = null;
                }
              } catch (e) {
                // eslint-disable-next-line
                console.log(e);
                sendObj.subDescr = 'No Dataset Selected';
                sendObj.value = null;
              }
              this.pushType0(sendObj);
            }
          })
        }
        return this.template.body.body
      } catch (e) {
        // eslint-disable-next-line
        console.log(e)
        return null
      }
    },
    type1PassOver() {
      try {
        this.clearType1s();
        let sTemp = this.type0PassOver;
        sTemp = sTemp.split('<span id="1');
        sTemp.forEach((line, i) => {
          if (line.substring(0, 1) == '-') {
            // Its A Type 1 Field
            let tField = line;
            let endOfID = tField.indexOf('"');
            let fieldID = tField.substring(1, endOfID);
            let endOfTag = tField.indexOf('</span>') + 7;
            let tFieldObj = this.fields.filter(fieldFilt => fieldFilt.fieldID == fieldID)[0];
            
            if (!tFieldObj) {
              console.log(fieldID)
            }
            
            if (tFieldObj.fieldType === 2 && tFieldObj.lookup_type === 1) {
              tFieldObj = {...tFieldObj, value: tFieldObj.value ? format(new Date(tFieldObj.value), 'YYYY-MM-DD') : tFieldObj.value}
            }

            if (tFieldObj) {
              tField = '###TASKLOGIXFIELDTYPE1-' + this.pushType1(tFieldObj) + '###' + tField.substring(endOfTag, tField.length);
              sTemp[i] = tField
            } else {
              sTemp[i] = ''
            }
          }
        });
        return sTemp.join('')
      } catch (e) {
        // eslint-disable-next-line
        console.log(e)
        return null
      }
    },
    type2PassOver() {
      try {
        this.clearType2s();
        let sTemp = this.type1PassOver;
        sTemp = sTemp.split('<span id="2');
        sTemp.forEach((line, i) => {
          if (line.substring(0, 1) == '-') {
            // Its A Type 2 Field
            let tField = line;
            let endOfID = tField.indexOf('"');
            let fieldID = tField.substring(1, endOfID);
            let endOfTag = tField.indexOf('</span>') + 7;

            let refObj = {
              selectField: fieldID.substring(0, fieldID.indexOf('-')),
              fieldID: fieldID.substring(fieldID.indexOf('-') + 1, fieldID.length)
            };

            let sendObj = {required: 0};

            let selectField = null;
            let fieldSet = null;
            let fieldSetField = null;

            try {


              selectField = this.fields.filter(object => object.fieldID == refObj.selectField)[0];

              sendObj.fieldDescription = selectField.description;

              if (selectField.value != null && selectField.value != '') {


                // fieldSet = this.fieldSets.filter(object => object.memberID == selectField.value)[0];
                fieldSet = this.binder.datasets[selectField.value];

                // fieldSetField = JSON.parse(fieldSet.fields).filter(object => object.fieldID == refObj.fieldID)[0];
                fieldSetField = fieldSet.fields.filter(object => object.datasetFieldID == refObj.fieldID)[0];

                try {
                  sendObj.valueDescription = fieldSetField.fieldDescription || `No Field Description`;
                  sendObj.value = fieldSetField.value;
                  sendObj.required = fieldSetField.required;
                } catch (e) {
                  sendObj.valueDescription = 'Field Not Populated';
                  sendObj.value = null;
                }
              } else {
                sendObj.valueDescription = 'No Dataset Selected';
                sendObj.value = null;
              }
            } catch (e) {
              // eslint-disable-next-line
              console.log(e);
              sendObj.valueDescription = 'No Dataset Selected';
              sendObj.value = null;
            }


            tField = '###TASKLOGIXFIELDTYPE2-' + this.pushType2(sendObj) + '###' + tField.substring(endOfTag, tField.length);
            sTemp[i] = tField
          }
        });
        return sTemp.join('')
      } catch (e) {
        // eslint-disable-next-line
        console.log(e)
        return null
      }
    },
    type3PassOver() {
      try {
        this.clearType3s();
        let sTemp = this.type2PassOver;
        sTemp = sTemp.split('<span id="3');
        sTemp.forEach((line, i) => {
          if (line.substring(0, 1) == '-') {
            // Its A Type 2 Field
            let tField = line;
            let endOfID = tField.indexOf('"');
            let fieldID = tField.substring(1, endOfID);
            let endOfTag = tField.indexOf('</span>') + 7;
            tField = '###TASKLOGIXFIELDTYPE3-' + this.pushType3(fieldID) + '###' + tField.substring(endOfTag, tField.length);
            sTemp[i] = tField
          }
        });
        return sTemp.join('')
      } catch (e) {
        // eslint-disable-next-line
        console.log(e)
        return null
      }
    },
    replaceTaskVars() {
      try {
        return this.replacedHTML
          .replace(/{{taskid}}/gi, this.task.taskID)
          .replace(/{{now}}/gi, getLongDate(new Date()))
          .replace(/{{tasktitle}}/gi, this.task.title)
      } catch (e) {
        // eslint-disable-next-line
        console.log(e);
        return this.replacedHTML;
      }
    },
    replacedHTML() {
      try {
        if (this.ireset > -1) {
          let sSource = this.type3PassOver;
          this.fieldType1s.forEach((field, index) => {
            if (typeof field != 'undefined' && typeof field.value != 'undefined' && field.value != null) {
              sSource = sSource.split('###TASKLOGIXFIELDTYPE1-' + index + '###').join(field.value)
            } else {
              sSource = sSource.split('###TASKLOGIXFIELDTYPE1-' + index + '###').join('')
            }
          });
          this.fieldType2s.forEach((field, index) => {
            if (typeof field != 'undefined' && typeof field.value != 'undefined' && field.value != null) {
              sSource = sSource.split('###TASKLOGIXFIELDTYPE2-' + index + '###').join(field.value)
            } else {
              sSource = sSource.split('###TASKLOGIXFIELDTYPE2-' + index + '###').join('')
            }
          });
          this.fieldType3s.forEach((field, index) => {
            if (field.text != null) {
              sSource = sSource.split('###TASKLOGIXFIELDTYPE3-' + index + '###').join(field.text.replace(/(?:\r\n|\r|\n)/g, '<br>'))
            } else {
              sSource = sSource.split('###TASKLOGIXFIELDTYPE3-' + index + '###').join('')
            }
          });
          return sSource;
        }
        
        return null
      } catch (e) {
        // eslint-disable-next-line
        console.log(e)
        return null
      }
    },
    replaceSignature() {
      try {
        if (this.selectedSignature != null && this.selectedSignature != '' && this.selectedSignature != 0) {
          if (this.template.forcedSignature == null) {
            // User Signature
            let sSignature = null
            this.userInfo.signatures.forEach(signature => {
              if (signature.id == this.selectedSignature) {
                sSignature = signature.signature;
              }
            })
            return this.replaceInSignature(sSignature);
          } else {
            // forcedSignature
            if (this.forcedSignature != null) {
              return this.replaceInSignature(this.forcedSignature.signature);
            } else {

              return this.replacedHTML;
            }
          }
        } else {
          return this.replacedHTML;
        }
      } catch (e) {
        // eslint-disable-next-line
        console.log(e)
        return null
      }
    },
    allowSend() {

      const allAddrs = [
        ...(this.selectedTo || []),
        ...(this.selectedCc || []),
        ...(this.selectedBcc || []),
      ]

      if (!allAddrs.length) {
        return false
      }

      if (this.sendMail.subject == null || this.sendMail.subject == '') {
        return false
      }

      if (this.fieldType0s.filter(obj => !this.blankString(obj.value) && obj.value_type == 1).length > 0) {

        return false
      }

      if (this.fieldType0s.filter(obj => obj.required == 1 && obj.value != null && obj.value != '' && obj.value_type == 1).length > 0) {
        return false

      } else {
        let arrTemp = (this.fieldType0s || [])
          .concat(this.fieldType1s || [])
          .concat(this.fieldType2s || [])

        return arrTemp.filter(obj => obj.required && (obj.value === null || obj.value === '')).length === 0
      }

    }
  }
}
</script>

<style scoped>
.hover:hover {
  background: #efefef;
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.btn {
  border: 2px solid gray;
  color: gray;
  background-color: white;
  padding: 8px 20px;
  border-radius: 8px;
  font-size: 20px;
  font-weight: bold;
}

.upload-btn-wrapper input[type=file] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}
</style>
