<template>

  <v-dialog
    v-model="shown"
    :width="600"
    :persistent="true"
    :fullscreen="fullscreen"
  >
    <v-card dark v-if="shown" class="c-d-flex c-flex-column" :class="fullscreen ? 'fill-height scrollable' : null">
      <div
        :style="{
                height: !fullscreen ? '500px' : ''
              }"
        class="c-flex-grow-1">

        <div
          :contenteditable="true"
          class="pa-2 fill-height scrollable eval-editor"
          @contextmenu="selectMenu"
          ref="editor"
          @input="onInput"
        ></div>


        <!--Select Type Menu-->
        <v-menu
          v-model="showSelectMenu"
          :position-x="x"
          :position-y="y"
          absolute
          offset-y
          dark
        >
          <v-list dense class="my-0 py-0">
            <v-subheader class="white--text primary">Insert:</v-subheader>
            <v-divider></v-divider>
            <v-list-tile
              @click="workflowMenu"
            >
              Workflow Field
            </v-list-tile>
            <v-list-tile
              @click="datasetMenu"
            >
              Dataset Field
            </v-list-tile>
            <v-list-tile @click="() => {}">Cancel</v-list-tile>
          </v-list>
          <!--Workflow Menu-->
          <v-menu
            v-model="showWorkflowMenu"
            :position-x="x"
            :position-y="y"
            absolute
            offset-y
          >
            <v-list dense style="max-height: 300px; overflow-y: auto" class="my-0 py-0">
              <template v-for="item in workflowFields">
                <template v-if="item.type === 'step'">
                  <v-subheader class="white--text primary" :key="`step-${item.id}`">{{item.description}}</v-subheader>
                  <v-divider :key="`divStep-${item.id}`"></v-divider>
                </template>
                <template v-else>
                  <v-list-tile
                    :key="`field-${item.fk_step}-${item.id}`"
                    @click="addWorkflowItem(item)"
                  >
                    {{item.description}}
                  </v-list-tile>
                </template>

              </template>
              <v-list-tile>Cancel</v-list-tile>
            </v-list>
          </v-menu>
        </v-menu>
        <!--Dataset Select-->
        <app-input-dlg
          :show-dialog="showDatasetMenu"
          title="Select Dataset Field"
          :cancel-button="true"
          :show-input-box="false"
          @dismiss="showDatasetMenu = false"
          :body-padding="false"
        >
          <template slot="body">
            <div class="pa-2">
              <app-field-set-field-selector
                v-model="selectedDatasetField"
                :fields="fields"
                :field-sets="fieldSets"
                :field-type-filter="[1,2,3,4,5]"
                :include-auto-populate="false"
              />
            </div>

          </template>
          <template slot="actions">
            <div class="text-sm-right pa-2">
              <v-btn
                small
                class="py-0"
                color="secondary"
                @click="selectDatasetField()"
                :disabled="selectDatasetFieldNotReady"
              >Done</v-btn>
            </div>
          </template>
        </app-input-dlg>
      </div>
      <v-divider></v-divider>
      <div class="pb-1 text-xs-right c-d-flex align-center justify-space-between">

        <div class="caption white--text pa-2">
          *Right click to add a field
        </div>
        <div>
          <v-btn
            dark
            @click="fullscreen = !fullscreen"
            small
            flat
            class="pa-0 px-2"
          >
            <v-icon left>{{!fullscreen ? 'fullscreen' : 'fullscreen_exit'}}</v-icon>
            Fullscreen
          </v-btn>
          <v-btn
            dark
            @click="$emit('dismiss')"
            small
            flat
            class="pa-0 mr-2"
          >
            Cancel
          </v-btn>
          <v-btn
            @click="done()"
            color="secondary"
            small
            class="pa-0"
          >
            Done
          </v-btn>
        </div>


      </div>
    </v-card>
  </v-dialog>



</template>

<script>




import InputDlg from "../../../../../../../../components/General/InputDlg";
import {codeBaseMixin} from "../../../../../../../../codeBaseMixin";
import FieldSetFieldSelector from "../../FieldSetFieldSelector";

export default {
  mixins: [codeBaseMixin],
  components: {
    appFieldSetFieldSelector: FieldSetFieldSelector,
    appInputDlg: InputDlg
  },
  props: {
    value: String,
    localData: Object,
    userInfo: Object,
    shown: Boolean,
    steps: Array,
    fieldSets: Array,
    fields: Array,
  },
  data() {
    return {
      showSelectMenu: false,
      showWorkflowMenu: false,
      showDatasetMenu: false,
      selectedDatasetField: null,
      showUserInput: false,
      x: 0,
      y: 0,
      range: null,
      charCount: "",
      fullscreen: false
    }
  },
  mounted() {
    this.$refs.editor.addEventListener("paste", function(e) {
      // cancel paste
      e.preventDefault();

      // get text representation of clipboard
      var text = (e.originalEvent || e).clipboardData.getData('text/plain');

      // insert text manually
      document.execCommand("insertHTML", false, text);
    });
  },
  watch: {

    showDatasetMenu(val) {
      if (val) {
        this.selectedDatasetField = null;
      }
    },

    shown: {
      immediate: true,
      handler(val) {
        if (val) {
          this.resetLocalVals()
        }
      }
    }
  },
  methods: {

    done() {
      this.$emit('input', this.$refs.editor.innerHTML)
      this.$emit('dismiss')
    },

    onInput(e) {
      this.updateCharCount(e.target.innerHTML)
    },

    updateCharCount(html = null) {

      if (this.blankString(html)) {
        html = this.$refs.editor.innerHTML
      }

      let tWork = this.htmlToText(html);

      let tDesc = `${tWork.afterTags.length} Characters, ${Math.floor(tWork.afterTags.length / 160) + 1} Message Parts.${(tWork.fields.userInput.length + tWork.fields.workflow.length + tWork.fields.dataset.length) === 0 ? '' : ` (Before Included Fields)`}`

      this.charCount = tDesc;
    },

    selectDatasetField() {
      try {
        let tFieldLookup = this.fields
          .filter(obj => obj.id === this.selectedDatasetField.fieldSetSelectField)
          .map(obj => {return parseInt(obj.config.dataset)})
          .pop();
        console.log(tFieldLookup)

        let tDescription = this.fieldSets.reduce((obj, item) => {
          if (item.id === tFieldLookup) {
            obj.dataset = item.description;
            obj.field = item.fields
              .filter(obj => obj.id === this.selectedDatasetField.field)
              .map(obj => {return obj.description})
              .pop()
          }
          return obj;
        }, {});
        this.insertAtCursor(`<div contenteditable="false" style="display: inline; border-radius: 3px" class="pa-1 white--text caption blue" id="f-D-V-${this.selectedDatasetField.fieldSetSelectField}-${this.selectedDatasetField.field}">${tDescription.dataset} - ${tDescription.field}</div>`, false)
        this.showDatasetMenu = false;
        this.$nextTick(() => {
          this.updateCharCount()
        })
      } catch (e) {
        // eslint-disable-next-line
        console.log(e)
      }
    },

    selectMenu (e) {
      e.preventDefault();
      this.showSelectMenu = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.$nextTick(() => {
        this.showSelectMenu = true
      })
    },

    workflowMenu () {
      this.showSelectMenu = false;
      this.$nextTick(() => {
        this.showWorkflowMenu = true
      })
    },

    datasetMenu () {
      this.setRange();
      this.showSelectMenu = false;
      this.$nextTick(() => {
        this.showDatasetMenu = true
      })
    },

    userInput() {
      this.setRange();
      this.showUserInput = true;
    },

    addInput(description) {
      if (!this.blankString(description)) {
        this.showUserInput = false;
        this.insertAtCursor(
          `<div contenteditable="false" style="display: inline; border-radius: 3px" class="pa-1 white--text caption green" id="f-U-V-${description.split('"').join('')}">${description.split('"').join('')}</div>`,
          false
        )
        this.$nextTick(() => {
          this.updateCharCount()
        })
      } else {
        this.showSnack('Error', 'A Description is required', 'Close', 'red')
      }
    },

    addWorkflowItem(item) {
      this.insertAtCursor(`<div contenteditable="false" style="display: inline; border-radius: 3px" class="pa-1 white--text caption red" id="f-W-V-${item.id}">${item.description}</div>`)
      this.$nextTick(() => {
        this.updateCharCount()
      })
    },

    setRange() {
      let selection = window.getSelection();
      this.range = selection.getRangeAt(0);
    },

    insertAtCursor(htmlString, setRange = true) {
      if (setRange) {this.setRange();}
      this.range.deleteContents();
      let node = this.createElementFromHTML(htmlString);
      this.range.insertNode(node);
    },

    createElementFromHTML(htmlString) {
      var div = document.createElement('div');
      div.innerHTML = htmlString.trim();
      return div.firstChild;
    },

    resetLocalVals() {
      setTimeout(() => {
        if (typeof this.value == 'string') {
          this.$refs.editor.innerHTML = this.value;
        } else {
          this.$refs.editor.innerHTML = "";
        }
      }, 300)

      // Reset Other Vals As Well
    }
  },

  computed: {
    selectDatasetFieldNotReady() {
      return this.selectedDatasetField === null || typeof this.selectedDatasetField.field == 'undefined' || typeof this.selectedDatasetField.fieldSetSelectField == 'undefined';
    },

    workflowFields() {
      let tFields = this.fields
        .filter(obj => obj.visible === 1 && [1,2,3,4,5,22].includes(obj.fieldType))
        .sort((a,b) => a['field_order'] < b['field_order'] ? -1 : 1);
      let tStepIDs = tFields.map(obj => {return obj.fk_step});
      let tSteps = this.steps
        .filter(item => tStepIDs.includes(item.id) && item.hidden === 0)
        .sort((a,b) => a['field_order'] < b['field_order'] ? -1 : 1);

      let tList = tSteps.reduce((list, item) => {
        list.push({
          id: item.id,
          type: 'step',
          description: item.description
        });
        list = list.concat(tFields
          .filter(obj => obj.fk_step === item.id)
          .map(obj => {
            return {
              id: obj.id,
              description: obj.description,
              type: 'field',
              fk_step: obj.fk_step
            }
          })
        );
        return list;
      },[]);
      return tList
    }
  }
}
</script>

<style scoped>
[contenteditable] {
  outline: 0px solid transparent;
}

.eval-editor {
  background: rgb(50,50,50);
  color: white;
  font-family: monospace;
  white-space: pre-wrap
}
</style>
