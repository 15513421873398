<template>
    <v-layout row fill-height class="scrollable">
      
      <template v-if="model === contactsPageIndexNo">
        <v-flex xs12 class="fill-height scrollable">
          <div class="pt-2 px-2" v-if="$store.getters['userInfo/folderManager'] && $store.getters['userInfo/contactsManager']">
            <template v-if="!folder.contactList">
              <v-btn
                  class="pa-0 ma-0"
                  color="blue"
                  outline
                  small
                  block
                  :loading="changingFolderType"
                  :disabled="changingFolderType"
                  @click="toggleFolderType"
              >
                <v-icon left>contacts</v-icon>
                Convert to Contact list
              </v-btn>
            </template>
            
            <template v-else>
              <v-btn
                  class="pa-0 ma-0"
                  color="deep-orange"
                  outline
                  small
                  block
                  :loading="changingFolderType"
                  :disabled="changingFolderType"
                  @click="toggleFolderType"
              >
                <v-icon left>folder</v-icon>
                Convert to Folder
              </v-btn>
            </template>
          </div>
          <app-browse-contacts :allow-remove-contact="allowRemoveContact" v-if="localData && userInfo.userID && showContacts" v-model="selectedContact" :folder-filter="folder.id" class="fill-height scrollable"  :multi-select="false" :card-only="true" ref="contactView">
            <template #header v-if="showAddContact && createLinkComponent">
              <div class="px-2 pt-2">
                
                <app-browse-contacts
                    v-if="localData && userInfo.userID && showContacts"
                    v-model="selectedLinkExistingContacts"
                    class="fill-height scrollable"
                    :multi-select="true"
                    ref="linkedContactsView"
                >
                  <template #activator>
                    <v-btn class="pa-0 ma-0" color="secondary" outline small block>
                      <v-icon left>link</v-icon>
                      Link Contact
                    </v-btn>
                  </template>
                  
                  <template #toolbarEnd>
                    <component :is="createLinkComponent" :force-folder="folder.id" :local-data="localData" @input="contactAdded($event)">
                      <template #activator>
                        <v-btn color="white" flat>
                          <v-icon left>add</v-icon>
                          Create New Contact
                        </v-btn>
                      </template>
                    </component>
                  </template>
                  
                  
                  <template #actions="{selectedContacts, hideDialog}">
                    
                    <v-card-actions class="c-d-flex c-justify-end">
                      
                      
                      
                      
                      
                      <v-btn
                          :disabled="linkingExistingContacts"
                          :loading="linkingExistingContacts"
                          @click="() => hideDialog()"
                          flat
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                          :disabled="!Array.isArray(selectedContacts) || !selectedContacts.length"
                          :loading="linkingExistingContacts"
                          color="secondary"
                          @click="linkContacts(selectedContacts, hideDialog)"
                      >
                        Link{{Array.isArray(selectedContacts) && selectedContacts.length ? ' ' + selectedContacts.length : ''}} Selected
                      </v-btn>
                    </v-card-actions>
                  
                  </template>
                
                </app-browse-contacts>
              </div>
            </template>
          </app-browse-contacts>
          
          
          <div style="position: fixed; bottom: 15px; right: 15px;">
            <slot></slot>
          </div>
        </v-flex>
      </template>
      
        <template v-else>
          <v-flex :xs5="![1,groupsPageIndexNo].includes(model)" :xs12="[1,groupsPageIndexNo].includes(model)" class="pa-1">
            <!--            <v-flex :xs5="model != 2 && model != 1" :xs12="model == 2 || model == 1" class="pa-1">-->
            <v-card class="fill-height" >
              <v-layout column fill-height style="overflow-y: auto">
                <v-flex shrink v-if="model != groupsPageIndexNo">
                  <v-card-title class="white--text pa-0 ma-0 primary">
                    <v-layout row align-center>
                      
                      <v-flex shrink v-if="typeof folder.access != 'undefined' && !folder.access" class="pl-2">
                        <v-tooltip right>
                          <v-icon
                              class="white--text"
                              slot="activator"
                              small
                              left
                          >
                            security
                          </v-icon>
                          <span>Folder Access Denied</span>
                        </v-tooltip>
                      </v-flex>
                      
                      <v-flex xs12 class="pa-2">
                        {{ folder.description }}
                      </v-flex>
                      
                      <v-flex shrink class="pr-1 text-xs-right" v-if="folder.access || typeof folder.access == 'undefined'">
                        <v-tooltip bottom>
                          <v-btn
                              small
                              class="ma-0 pa-0"
                              flat
                              icon
                              color="white"
                              slot="activator"
                              @click="inEdit = true"
                          >
                            <v-icon small>edit</v-icon>
                          </v-btn>
                          <span>Edit Folder Name</span>
                        </v-tooltip>
                      </v-flex>
                    </v-layout>
                  </v-card-title>
                </v-flex>
                
                <v-flex shrink v-if="loadingFieldSets && model != groupsPageIndexNo"  class="text-xs-center pt-3 px-1">
                  <v-progress-circular
                      :size="40"
                      :width="5"
                      color="primary"
                      indeterminate
                  
                  ></v-progress-circular>
                </v-flex>
                <template v-else>
                  <!--Field Sets-->
                  <template v-if="model == 0">
                    <!--Add Field Set-->
                    <v-flex shrink class="px-2 pt-2" v-if="!softBlock && typeof userInfo.createFieldset != 'undefined' && intToBool(userInfo.createFieldset) && (folder.access || typeof folder.access == 'undefined')">
                      <v-btn
                          small
                          outline
                          color="secondary"
                          block
                          class="ma-0"
                          @click="showAddDatasetDlg = true"
                      >
                        <v-icon left>add</v-icon>
                        Add Dataset To Folder
                      </v-btn>
                      
                      <app-input-dlg
                          :show-dialog="showAddDatasetDlg"
                          title="Add Dataset"
                          :cancel-button="true"
                          :show-input-box="false"
                          @dismiss="showAddDatasetDlg = false"
                          :body-padding="false"
                      >
                        <template slot="body">
                          <div class="pa-2">
                            <div :class="headingClass">
                              {{`Please select Dataset Type to add to ${folder.description}:`}}
                            </div>
                            <div :class="itemClass">
                              <v-autocomplete
                                  color="primary"
                                  label="Select Dataset"
                                  :items="availableAddFieldSets"
                                  item-text="description"
                                  return-object
                                  flat
                                  v-model="insertFieldSet"
                                  hide-details
                                  class="pa-0 ma-0"
                                  dense
                                  :loading="addingFieldSet"
                                  :disabled="addingFieldSet"
                                  single-line
                                  no-data-text="No Available Datasets To Add"
                              >
                              </v-autocomplete>
                            </div>
                          </div>
                        </template>
                        <template slot="actions">
                          <v-btn
                              small
                              color="secondary"
                              class="ma-0"
                              :disabled="insertFieldSet == null || addingFieldSet"
                              :loading="addingFieldSet"
                              @click="addFieldSetToFolder"
                          >
                            <v-icon left>add</v-icon>
                            Add Dataset
                          </v-btn>
                        </template>
                      </app-input-dlg>
                    </v-flex>
                    
                    
                    <!--Search Field Sets-->
                    <v-flex shrink class="px-1">
                      <v-text-field
                          label="Search Datasets"
                          single-line
                          v-model="fieldSetSearchString"
                          color="primary"
                          hide-details
                          style="width: 100%"
                          class="ma-0 pa-0 pt-2"
                          prepend-inner-icon="search"
                      ></v-text-field>
                    </v-flex>
                    <!--Field Sets-->
                    <v-flex xs12 class="scroll" style="overflow-y: auto">
                      <div v-for="(set, index) in fieldSetsComputed" :key="'FieldSet' + set.memberID" @contextmenu="show($event, set)">
                        <v-hover
                        >
                          <v-layout
                              row
                              align-center
                              slot-scope="{ hover }"
                              class="pa-2 hover-item"
                              @click="folder.access || typeof folder.access == 'undefined' ? selectFieldSet(set) : showSnack('Warning', 'Access Is Denied', 'Close', 'amber')"
                              :class="{selectedFieldSet: selectedSet.memberID === set.memberID}"
                              style="cursor: pointer"
                          
                          >
                            <v-flex xs12>
                              <div
                                  @drag="$event.preventDefault()"
                                  @dragstart="drag($event, set)"
                                  :draggable="intToBool(userInfo.folderManager)"
                              >
                                <v-layout column>
                                  <v-flex class="body-1" shrink v-if="set.valueName != null && set.valueName != ''" :class="selectedSet.memberID == set.memberID ? 'font-weight-bold' : ''">
                                    {{ set.valueName }}
                                  </v-flex>
                                  <v-flex class="caption greyType--text" shrink :class="selectedSet.memberID == set.memberID ? 'font-weight-bold' : ''">
                                    {{ set.fieldSetName }}
                                  </v-flex>
                                  <v-flex class="caption error--text font-weight-bold" v-if="set.archived">
                                    *Dataset archive has been depreciated. Please contact your dataset administrator
                                  </v-flex>
                                </v-layout>
                              </div>
                            </v-flex>
                            <v-flex shrink class="ma-0 pa-0" v-if="false">
                              <!--Archive-->
                              <template v-if="typeof userInfo.fieldsetManager != 'undefined' && intToBool(userInfo.fieldsetManager)">
                                <v-tooltip top v-if="!intToBool(set.readOnly)">
                                  <v-btn
                                      @click="$refs.confirmArchive[index].showDialog = true"
                                      slot="activator"
                                      flat
                                      icon
                                      :color="intToBool(set.archived) ? 'primary' : 'red'"
                                      class="ma-0 pa-0"
                                  >
                                    <v-icon>{{ intToBool(set.archived) ? 'unarchive' : 'archive' }}</v-icon>
                                  </v-btn>
                                  <span>{{ intToBool(set.archived) ? 'Restore Dataset' : 'Archive Dataset' }}</span>
                                </v-tooltip>
                              </template>
                              
                              <input-dlg
                                  ref="confirmArchive"
                                  title="Confirm Action"
                                  :body="'Please confirm that you would like to ' + (set.archived ? 'restore the Dataset' : 'archive the Dataset') + `${set.valueName ? ` ${set.valueName} ` : ''} - ${set.fieldSetName}`"
                                  :cancel-button="true"
                                  :show-input-box="false"
                                  :default-input="set"
                                  @dismiss="$refs.confirmArchive[index].showDialog = false"
                                  :buttons="['Confirm']"
                                  :call-backs="[archiveFieldSet]"
                              >
                              </input-dlg>
                            
                            </v-flex>
                            
                            <v-flex shrink class="ma-0 pa-0">
                              <!--Archive-->
                              <template v-if="
                                                selectedSet.memberID === set.memberID
                                                && (
                                                        (typeof userInfo.fieldsetManager != 'undefined' && intToBool(userInfo.fieldsetManager))
                                                        || (typeof userInfo.fieldsetClone != 'undefined' && intToBool(userInfo.fieldsetClone))
                                                )
                                                && allowMultipleDSRef[set.fieldSetID]">
                                <v-tooltip top v-if="!intToBool(set.readOnly)">
                                  <v-btn
                                      @click="cloneSetRef = set"
                                      slot="activator"
                                      flat
                                      icon
                                      class="ma-0 pa-0"
                                      color="primary"
                                      small
                                  >
                                    <v-icon small>content_copy</v-icon>
                                  </v-btn>
                                  <span>Clone Dataset</span>
                                </v-tooltip>
                              </template>
                            </v-flex>
                            
                            <v-flex shrink v-if="(!folder.access && typeof folder.access != 'undefined') || (intToBool(set.readOnly) && !(!folder.access && typeof folder.access != 'undefined'))">
                              <v-tooltip top v-if="intToBool(set.readOnly) && !(!folder.access && typeof folder.access != 'undefined')">
                                <v-icon class="secondary--text" slot="activator">security</v-icon>
                                <span>Read Only Access</span>
                              </v-tooltip>
                              
                              <v-tooltip top v-if="!folder.access && typeof folder.access != 'undefined'">
                                <v-icon class="greyType--text" slot="activator">security</v-icon>
                                <span>Access Is Denied</span>
                              </v-tooltip>
                            </v-flex>
                          </v-layout>
                        
                        
                        </v-hover>
                        <v-divider></v-divider>
                      </div>
                    
                    </v-flex>
                  </template>
                  <!--Folders-->
                  <template v-if="model == 1 && (folder.access || typeof folder.access == 'undefined' || folder.taskOnlyAccess)">
                    <!--Task List-->
                    <v-flex xs12 style="overflow-x: hidden">
                      <app-folder-task-band
                          :selected-folder="folder"
                          :user-info="userInfo"
                          @gotoTask="$emit('dismiss', false)"
                          @hideParent="$emit('hideParent', false)"
                          :get-task-header="getTaskHeader"
                      />
                    </v-flex>
                  
                  </template>
                  <!--Groups-->
                  <template v-if="model == groupsPageIndexNo">
                    <v-flex xs12 style="overflow-y: auto">
                      <!--                        <app-folder-groups-bar-->
                      <!--                                :selectedFolder="folder"-->
                      <!--                                :selectedGroup="selectedFolderGroup"-->
                      <!--                                :folderGroups="folderGroups"-->
                      <!--                                :userInfo="userInfo"-->
                      <!--                                :availableFolderGroups="availableFolderGroups"-->
                      <!--                                @folderGroupAdded="allFolderGroups.push($event)"-->
                      <!--                                @folderGroupMemberAdded="folderGroups.push($event)"-->
                      <!--                                @selectFolder="selectFolderGroup($event)"-->
                      <!--                        />-->
                      <app-security
                          :selected-folder="folder"
                          :user-info="userInfo"
                          :folder-view-option="model"
                          @folderGroupAdded="$emit('folderGroupAdded', $event)"
                          @securityUpdated="$emit('securityUpdated', $event)"
                      />
                    </v-flex>
                  </template>
                </template>
              
              </v-layout>
            </v-card>
          </v-flex>
          
          
          <v-flex xs7 class="pa-1" v-if="model == 0">
            
            <v-card class="fill-height" style="overflow-y: auto">
              <v-layout column fill-height v-if="typeof selectedSet.memberID != 'undefined'">
                <!--Card Title-->
                <v-flex shrink>
                  
                  <v-card-title class="white--text pa-0 ma-0 primary">
                    <v-layout row align-center justify-space-between wrap>
                      
                      <v-flex shrink class="pa-2">
                        {{ selectedSet.fieldSetName }}
                      </v-flex>
                      <!--                                <v-flex shrink class="pa-2 caption">-->
                      <!--                                    {{ selectedSet.valueName }}-->
                      <!--                                </v-flex>-->
                    </v-layout>
                  </v-card-title>
                
                </v-flex>
                
                <v-flex
                    xs12
                    class="px-0 scrollable"
                >
                  
                  <DatasetDetail
                      ref="datasetDetail"
                      :selected-dataset="selectedSet"
                      :add-doc-to-task-btn="addDocToTaskBtn"
                      @addDocToTask="$emit('addDocToTask', $event)"
                      :folder-access="!!folder.access || typeof folder.access === 'undefined'"
                      @changesMade="$emit('changesMade')"
                      :field-sets="fieldSets"
                      :folder-id="folder.id"
                  >
                    <slot v-if="selectedSet"></slot>
                  </DatasetDetail>
                  
                  <!--                        <div-->
                  <!--                                v-for="field in ungroupedFields"-->
                  <!--                                :key="'FieldSetField' + field.fieldID"-->
                  <!--                        >-->
                  <!--                            <template v-if="field.type !== 16">-->
                  <!--                                <div class="px-1">-->
                  <!--                                    <app-field-set-field-->
                  <!--                                            v-if="!hiddenFields.includes(field.fieldID)"-->
                  <!--                                            :field="field"-->
                  <!--                                            :user-info="userInfo"-->
                  <!--                                            :readOnly="intToBool(selectedSet.readOnly)"-->
                  <!--                                            :folderDocs="folderDocs"-->
                  <!--                                            :folderMails="folderMails"-->
                  <!--                                            :read-only-field="readOnlyFields.includes(field.fieldID)"-->
                  <!--                                            :secure-field="secureFields.includes(field.fieldID)"-->
                  <!--                                            :add-doc-to-task-btn="addDocToTaskBtn"-->
                  <!--                                            @forceSave="saveChanges"-->
                  <!--                                            @addDocToTask="$emit('addDocToTask', $event)"-->
                  <!--                                    />-->
                  <!--                                </div>-->
                  <!--                            </template>-->
                  <!--                            <template v-else>-->
                  <!--                                <div>-->
                  <!--                                    <v-layout row align-center-->
                  <!--                                              @click="droppedGroups.includes(field.fieldID) ? droppedGroups = droppedGroups.filter(obj => obj !== field.fieldID) : droppedGroups.push(field.fieldID)"-->
                  <!--                                              class="primary"-->
                  <!--                                    >-->
                  <!--                                        <v-flex xs12 class="py-2 pl-2 white&#45;&#45;text caption" style="border-radius: 3px">-->
                  <!--                                            {{field.fieldDescription}}-->
                  <!--                                        </v-flex>-->
                  <!--                                        <v-flex shrink class="pr-2">-->
                  <!--                                            <v-btn-->
                  <!--                                                    small-->
                  <!--                                                    icon-->
                  <!--                                                    flat-->
                  <!--                                                    class="pa-0 ma-0"-->
                  <!--                                                    color="white"-->
                  <!--                                            >-->
                  <!--                                                <v-icon>{{droppedGroups.includes(field.fieldID) ? 'arrow_drop_up' : 'arrow_drop_down'}}</v-icon>-->
                  <!--                                            </v-btn>-->
                  <!--                                        </v-flex>-->
                  <!--                                    </v-layout>-->
                  <!--                                    <v-divider color="white"></v-divider>-->
                  <!--                                    <template v-if="droppedGroups.includes(field.fieldID)">-->
                  <!--                                        <div-->
                  <!--                                                class="px-1"-->
                  <!--                                                v-for="subField in groupFields[field.fieldID]"-->
                  <!--                                                :key="'FieldSetField' + subField.fieldID"-->
                  <!--                                        >-->
                  <!--                                            <app-field-set-field-->
                  <!--                                                    v-if="!hiddenFields.includes(subField.fieldID)"-->
                  <!--                                                    :field="subField"-->
                  <!--                                                    :user-info="userInfo"-->
                  <!--                                                    :readOnly="intToBool(selectedSet.readOnly)"-->
                  <!--                                                    :folderDocs="folderDocs"-->
                  <!--                                                    @forceSave="saveChanges"-->
                  <!--                                                    :folderMails="folderMails"-->
                  <!--                                                    :read-only-field="readOnlyFields.includes(subField.fieldID)"-->
                  <!--                                                    :secure-field="secureFields.includes(subField.fieldID)"-->
                  <!--                                                    :add-doc-to-task-btn="addDocToTaskBtn"-->
                  <!--                                                    @addDocToTask="$emit('addDocToTask', $event)"-->
                  <!--                                            />-->
                  <!--                                        </div>-->
                  <!--                                        <v-divider></v-divider>-->
                  <!--                                    </template>-->
                  <!--                                </div>-->
                  
                  <!--                            </template>-->
                  
                  <!--                        </div>-->
                
                </v-flex>
              </v-layout>
              <div v-else style="position: fixed; bottom: 15px; right: 15px;">
                <slot></slot>
              </div>
            </v-card>
          
          </v-flex>
        </template>
      
      
        




        <app-input-dlg
                :show-dialog="inEdit"
                title="Rename Folder"
                body="New Folder Description:"
                :cancel-button="true"
                :default-input="folder.description"
                :show-input-box="true"
                :buttons="['Rename Folder']"
                :call-backs="[ renameFolder ]"
                @dismiss="inEdit = false"
        />

        <v-menu
            v-if="!softBlock"
                v-model="showSetMenu"
                :position-x="x"
                :position-y="y"
                absolute
                offset-y
        >
            <v-card>
                <div class="c-d-flex c-align-center hover-item pa-2 pr-2" @click="callAddEvent()" >
                    <v-icon class="pr-2">event</v-icon>
                    Create Calendar Event
                </div>
                <div class="c-d-flex c-align-center hover-item pa-2 pr-2" @click="showSetMenu = false">
                    <v-icon class="pr-2">close</v-icon>
                    Cancel
                </div>
            </v-card>
        </v-menu>

        <input-dlg
                v-if="!!cloneSetRef"
                :show-dialog="!!cloneSetRef"
                :cancel-button="true"
                :show-input-box="false"
                @dismiss="cloneSetRef = null"
                :body-padding="false"
                :width="400"
                :heading-color="'primary'"
        >
            <template slot="title">
                <div class="px-2 pt-2 primary subheading white--text">
                    Clone Dataset
                </div>
            </template>
            <template v-slot:body>
                <div style="height: 500px;" v-if="!!cloneSetRef">
                    <multi-select-list
                            :show-action-buttons="false"
                            :show-check-boxes="true"
                            :multiple="true"
                            headline-key="fieldDescription"
                            item-key="fieldID"
                            :return-object="false"
                            v-model="cloneSetFields"
                            :show-filter="true"
                            :items="cloneSetRefFields"
                            :dark="true"
                            filter-label="Filter Fields"
                    >
                        <template slot="header">
                            <div class="pa-2 primary white--text">
                                {{`Clone: ${cloneSetRef.valueName ? ` ${cloneSetRef.valueName} ` : ''} - ${cloneSetRef.fieldSetName}`}}
                                <br>
                                Please select fields to copy over to the new dataset.
                            </div>
                        </template>
                    </multi-select-list>
                </div>
                <v-divider></v-divider>
                <div class="pa-2 pt-3 c-d-flex c-justify-space-between">
                    <div class="c-d-flex c-align-center">

                        <div class="pa-2 grey--text">Copies: </div>

                        <div style="margin-top: -8px">
                            <v-text-field
                                    label="Copies"
                                    single-line
                                    v-model="cloneSetCopies"
                                    type="number"
                                    color="primary"
                                    hide-details
                                    style="width: 80px;"
                                    class="ma-0 pa-0"
                                    prepend-inner-icon="content_copy"

                            ></v-text-field>
                        </div>
                    </div>

                    <div>
                        <v-btn
                                small
                                color="secondary"
                                class="ma-0"
                                @click="cloneSetRef = null"
                                :disabled="cloningSet"
                                outline
                        >
                            Cancel
                        </v-btn>
                        <v-btn
                                small
                                color="secondary"
                                class="ma-0 ml-2"
                                @click="cloneDS"
                                :disabled="cloningSet || cloneSetFields.length <= 0"
                                :loading="cloningSet"
                        >
                            Clone Dataset
                        </v-btn>
                    </div>
                </div>
            </template>
        </input-dlg>

    </v-layout>
</template>

<script>
    import { codeBaseMixin } from "../../codeBaseMixin";
    import { eventBus } from "../../main";
    import FieldSetField from './FieldSetField';
    import FolderTaskBand from './FolderTasks/FolderTaskBand';
    // import FolderGroupsBar from './FolderGroups/FolderGroupsBar';
    // import FolderGroupDetailView from './FolderGroups/FolderGroupDetailView'
    import Security from "./Security/Security";
    import InputDlg from "@/components/General/InputDlg";
    import MultiSelectList from "@/components/Multi Select List/MultiSelectList";
    import DatasetDetail from "@/Folders/components/DatasetDetail/DatasetDetail";
    import {mapGetters} from "vuex";
    import AppBrowseContacts from "@/contacts/AppBrowseContacts";
    // import CreateLinkContact from "@/contacts/AppCreateLinkContact.vue";
    // import AppCreateLinkContact from "@/contacts/AppCreateLinkContact";

    export default {
        mixins: [codeBaseMixin],
        components: {
          // CreateLinkContact,
          // AppCreateLinkContact,
          AppBrowseContacts,
            DatasetDetail,
            MultiSelectList,
            InputDlg,
            appInputDlg: InputDlg,
            appSecurity: Security,
            appFolderTaskBand: FolderTaskBand,
            // appFolderGroupsBar: FolderGroupsBar,
            // appFolderGroupDetailView: FolderGroupDetailView
        },
        props: {
            userInfo: Object,
            folder: Object,
            showDialog: Boolean,
            model: Number,
            getTaskHeader: Function,
            addDocToTaskBtn: {
                type: Boolean,
                default: false
            },
          localData: Object,
          showAddContact: {
            type: Boolean,
            default: true
          },
          allowRemoveContact: {
            type: Boolean,
            default: false
          }
        },
        created() {
           eventBus.$on('folderGroupAdded', this.folderGroupAdded)
        },
        beforeDestroy() {
            eventBus.$off('folderGroupAdded', this.folderGroupAdded)
        },
        data() {
            return {

                showSetMenu: false,
                x: 0,
                y: 0,
                createEventItem: null,

                showAddDatasetDlg: false,
                fieldSets: [],
                allFieldSets: [],
                loadingFieldSets: false,
                selectedSet: {},
                fieldSetFilterItems: [
                    {id: 0, description: 'Active Datasets'},
                    {id: 1, description: 'Archived Datasets'},
                    {id: 2, description: 'All Datasets'},
                ],
                fieldSetFilter: 2,
                insertFieldSet: null,
                addingFieldSet: false,
                savingChanges: false,
                allFolderGroups: [],
                folderGroups: [],
                selectedFolderGroup: null,
                inEdit: false,
                fieldSetSearchString: "",
                folderMails: [],
                folderDocs: [],
                lastAskID: null,
                droppedGroups: [],

                cloningSet: false,
                cloneSetRef: null,
                cloneSetFields: [],
                cloneSetRefFields: [],
                cloneSetCopies: 1,
              selectedContact: null,
              selectedLinkExistingContacts: null,
              createLinkComponent: null,
              linkingExistingContacts: false,
              showContacts: true,
              changingFolderType: false
            }
        },
        watch: {
            folder(val, oldVal) {
                if (oldVal != null && val.id != oldVal.id) {
                    // this.checkChanges();
                    this.initFolderView();
                }
            },
            filteredFieldSetMembers() {

            },
            showDialog(shown) {
                if (!shown) {
                    // this.checkChanges();
                } else {
                    this.initFolderView();
                }

            },
            "cloneSetRef": {
                immediate: true,
                handler(val) {
                    if (val) {
                        this.cloneSetFields = [];
                        this.cloneSetCopies = 1;
                        this.cloneSetRefFields = this.$refs.datasetDetail.datasetFields
                                .filter(field => field.fieldSetMember === val.memberID && field.value && !field.distinct)
                                .sort((a,b) => a.order - b.order)
                    }
                }
            }
        },
        mounted() {
            this.initFolderView();
          
          if (this.showAddContact)
            import('@/contacts/AppCreateLinkContact.vue')
                .then((componentModule) => {
                  this.createLinkComponent = componentModule.default;
                })
                .catch((error) => {
                  console.error('Failed to load the component', error);
                });
            
        },
        methods: {
          async toggleFolderType() {
            try {
              this.changingFolderType = true
              const {status, message} = await this.$newReq('POST', `contacts/toggleFolderType`, {
                folderId: this.folder.id,
                contactList: !this.folder.contactList
              })
              if (status === 'fail')
                this.$snack.error(message)
            } catch (e) {
              this.$snack.networkError()
              console.log(e)
            } finally {
              this.changingFolderType = false
            }
            
          },
          
          async linkContacts(selectedContacts, closeDlgFn) {
            try {
              
              console.log(selectedContacts)
              
              this.linkingExistingContacts = true
              const newLinks = await this.$newReq('POST', `contacts/linkMultiple`, {
                folderId: this.folder.id,
                contactIds: (selectedContacts || []).map(contact => contact.id)
              })
              closeDlgFn()
              this.showContacts = false
              this.$snack.info(`${newLinks.length} Contact${newLinks.length === 1 ? '' : 's'} linked`)
            } catch (e) {
              console.log(e)
              this.$snack.networkError()
            } finally {
              this.linkingExistingContacts = false
              this.$nextTick(() => {
                this.showContacts = true
              })
            }
          },
          
          contactAdded(contact) {
            this.$refs.linkedContactsView.hideDialog()
            this.$refs.contactView.selectContact(contact)
          },
            refresh() {
                console.log('Hello')
            },
            folderGroupAdded(data) {
                this.allFolderGroups.push(data)
            },
            show(e, item) {
                this.createEventItem = item || null
                e.preventDefault()
                this.showSetMenu = false
                this.x = e.clientX
                this.y = e.clientY
                this.$nextTick(() => {
                    this.showSetMenu = true
                })
            },
            callAddEvent() {

                const set = this.createEventItem

                let linkPayload = {
                    memberID: set.memberID,
                    fk_folder: this.folder.id,
                    fk_fieldSet: set.fieldSetID,
                    created: set.createdDate,
                    archived: set.archived,
                    description: set.fieldSetName,
                    fieldSetDescription: set.valueName.split(' ')
                }
                let config = {
                    start: new Date(),
                    fkFolder: this.folder.id,
                    fkDataset: set.memberID,
                    linkPayload
                }
                //
                //
                // config.linkPayload = this.createEventItem.payload
                //
                this.$createCalendarEvent(config, null)
            },
            drag(event, fieldSet) {
                event.dataTransfer.setData("text", JSON.stringify({folderName: this.folder.description, fieldSetValueName: fieldSet.valueName, fieldSetName: fieldSet.fieldSetName, fieldSet: fieldSet}));
            },
            selectFolderGroup(folderGroup) {
                this.selectedFolderGroup = folderGroup
            },
            renameFolder(description) {
                if (description != null && description != '') {
                    this.inEdit = false;
                    this.fetch({method: 'PATCH', url: '/folders/renameFolder/' + this.userInfo.entityID + '/' + this.folder.id, body: JSON.stringify({description: description})})
                        .then(() => {
                            // this.$emit('folderRenamed', this.folder);
                            this.showSnack('Info', 'Changes Saved', 'Close', 'primary')
                        })
                        .catch((e) => {
                            // eslint-disable-next-line
                            console.log(e)
                            this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                        })
                } else {
                    this.showSnack('Error', 'A Folder Name is Required', 'Close', 'red')
                }
            },
            checkRemoval(source) {
                this.folderGroups.forEach((folderGroup, i) => {
                    if (folderGroup.groupID == source.id) {
                        this.folderGroups.splice(i, 1);
                    }
                })
            },
            cloneDS() {
                this.cloningSet = true
                this.$newReq('POST', `fieldsets/clone/dataset/${this.userInfo.entityID}/${this.userInfo.userID}`, {
                    memberID: this.cloneSetRef.memberID,
                    fields: this.cloneSetFields,
                    copies: this.cloneSetCopies
                })
                        .then(data => {
                            if (!data.status) {
                                this.$snack.info('Dataset Clone Success')
                            } else {
                                this.$snack.error(`Request Completed With ${data.status} Errors`)
                            }
                            this.cloneSetRef = null;
                            // this.checkChanges();
                            this.initFolderView();
                        })
                        .catch(e => {
                            console.log(e)
                            this.$snack.networkError()
                        })
                        .finally(() => {
                            this.cloningSet = false
                        })
            },
            addFieldSetToFolder() {
                if (this.insertFieldSet != null) {

                    this.addingFieldSet = true;
                    this.fetch({method: 'POST', url: '/fieldsets/addFieldSetToFolder/' + this.userInfo.entityID + '/' + this.userInfo.userID + '/' + this.folder.id + '/' + this.insertFieldSet.id})
                        .then((data) => {
                            this.insertFieldSet = null;
                            this.fieldSets.push(data.fieldSet);
                            this.selectedSet = this.fieldSets[this.fieldSets.length - 1];
                            this.addingFieldSet = false;
                            this.showAddDatasetDlg = false;
                        })
                        .catch((e) => {
                            // eslint-disable-next-line
                            console.log(e);
                            this.addingFieldSet = false;
                            this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                        })


                } else {
                    this.showSnack('Error', 'Please Select a Dataset To Add', 'Close', 'red')
                }
            },
            archiveFieldSet(set) {
                let editSet = -1;
                this.fieldSets.forEach((fieldSet, i) => {
                    if (fieldSet.memberID == set.memberID) {
                        editSet = i
                    }
                });
                this.fieldSets[editSet].archived = this.boolToInt(!this.intToBool(this.fieldSets[editSet].archived));
                this.fetch({method: 'PATCH', url: '/fieldsets/archive/' + this.userInfo.entityID + '/' + this.fieldSets[editSet].memberID + '/' + this.fieldSets[editSet].archived})
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e);
                        this.fieldSets[editSet].archived = this.boolToInt(!this.intToBool(this.fieldSets[editSet].archived));
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                    })
            },
            selectFieldSet(set) {
                this.selectedSet = set
            },
            initFolderView() {
                this.inEdit = false;
                this.fieldSets = [];
                this.allFieldSets = [];
                this.selectedSet = {};
                this.fieldSetFilter = 2;
                this.insertFieldSet = null;
                this.addingFieldSet = false;
                this.selectedFolderGroup = null;
                this.folderGroups = [];
                this.fieldSetSearchString = "";
                this.loadingFieldSets = true;
                this.fetch({method: 'GET', url: '/fieldsets/folder/' + this.userInfo.entityID + '/' + this.userInfo.userID + '/' + this.folder.id})
                    .then((data) => {
                        this.fieldSets = data.fieldSets;
                        this.allFieldSets = data.allFieldSets;
                        this.allFolderGroups = data.allFolderGroups;
                        this.folderGroups = data.folderGroups;
                        this.folderMails = data.mails;
                        this.folderDocs = data.documents;
                        this.loadingFieldSets = false;

                        if (this.folder.id !== 0) {
                            if ((Array.isArray(this.folderGroups) && this.folderGroups.length <= 0) || this.folderGroups == null) {
                                this.model = 2;
                                this.showSnack('Warning', 'No Folder Groups Assigned', 'Close', 'amber')
                            }
                        } else {
                            this.model = 1
                        }
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e);
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                    })
            }
        },
        computed: {
            ...mapGetters("userInfo", ["softBlock"]),
          
          groupsPageIndexNo() {
            if (!this.userInfo.folderManager)
              return 2
            return 2
          },
          
            contactsPageIndexNo() {
              if (!this.userInfo.folderManager)
                return 2
              return 3
            },

            allowMultipleDSRef() {
                return this.allFieldSets.reduce((obj, fs) => {
                    obj[fs.id] = fs.allowMultiple
                    return obj
                }, {})
            },
            fieldSetsComputed() {

                function fieldSetFilterFn(a,b) {
                    if (a.fieldSetName !== b.fieldSetName)
                        return a.fieldSetName < b.fieldSetName ? -1 : 1
                    else {
                      return new Date(a.createdDate) < new Date(b.createdDate) ? 1 : -1
                    }
                }

                let tSets = [...this.fieldSets].filter(set => set.access);

                // this.fieldSets.forEach(fieldSet =>{
                //     if (this.fieldSetFilter == 2) {
                //         tSets.push(fieldSet)
                //     } else {
                //         if (fieldSet.archived == this.fieldSetFilter) {
                //             tSets.push(fieldSet)
                //         }
                //     }
                // });

                tSets.sort(fieldSetFilterFn);

                return tSets.filter(obj => this.filteredFieldSetMembers.includes(obj.memberID));
            },
            availableAddFieldSets() {
                let folderGroups = Array.isArray(this.folderGroups)  ? this.folderGroups.map(obj => {return obj.groupID}): [];
                let tSets = this.allFieldSets.reduce((addSets, set) => {
                    let bAuthorised = true;
                    if (!this.blankString(set.limitToFolderGroups) && !['', '[]'].includes(set.limitToFolderGroups) && JSON.parse(set.limitToFolderGroups).length > 0) {
                        let acceptedFolderGroups = JSON.parse(set.limitToFolderGroups);
                        if (folderGroups.filter(fGroup => acceptedFolderGroups.includes(fGroup)).length <= 0) {
                            bAuthorised = false
                        }
                    }

                    if (bAuthorised) {
                        if (this.intToBool(set.allowMultiple)) {
                            addSets.push(set)
                        } else {
                            if (this.fieldSets.filter(obj => obj.fieldSetID == set.id).length <= 0) {
                                addSets.push(set)
                            }
                        }
                    }
                    return addSets
                }, []);
                return tSets
            },
            availableFolderGroups() {
                let tGroups = [];
                if (this.allFolderGroups != null && this.allFolderGroups.length > 0) {
                    this.allFolderGroups.forEach(addFolderGroup => {

                        if (this.folderGroups != null && this.folderGroups.length > 0) {
                            let bAdd = true;
                            this.folderGroups.forEach(existingFolderGroup => {
                                if (existingFolderGroup.groupID == addFolderGroup.id) {
                                    bAdd = false;
                                }
                            });
                            if (bAdd) {tGroups.push(addFolderGroup)}
                        } else {
                            tGroups.push(addFolderGroup)
                        }
                    })
                }
                return tGroups
            },
            filteredFieldSetMembers() {
                if (this.fieldSets != null
                    && this.fieldSets.length > 0
                    && !!this.fieldSetSearchString) {

                    return this.fieldSets
                            .filter(obj => (obj.searchStr || '_')
                                    .toLowerCase()
                                    .includes(this.fieldSetSearchString.toLowerCase()))
                            .map(obj => {return obj.memberID})
                } else {
                    if (this.fieldSets != null && this.fieldSets.length > 0) {
                        return this.fieldSets.map(obj => {return obj.memberID})
                    } else {
                        return []
                    }
                }
            }
        }
    }
</script>

<style scoped>
    .selectedFieldSet {
        background: var(--selected-item);
    }
</style>
