<template>
    <div class="centered-row">

        <div class="pl-2">
            <v-icon>{{device.os === 'Android' ? 'phone_android' : 'computer'}}</v-icon>
        </div>

        <div class="pl-2 py-2" style="width: 100%">
            <div class="body-2 pb-1" style="line-height: 1">{{device.os}} Device</div>
            <div class="centered-row">
                <div class="caption" style="line-height: 1; width: 50%">Last Login: {{lastAccesseDate}}</div>
                <div class="caption" style="line-height: 1; width: 50%">Created: {{ createdDate }}</div>
            </div>

        </div>
        <div class="pr-2">
            <v-tooltip left>
                <v-btn
                        small
                        icon
                        flat
                        class="pa-0 ma-0"
                        color="error"
                        slot="activator"
                        @click="revokeAccess()"
                        :disabled="loading"
                        :loading="loading"
                >
                    <v-icon>close</v-icon>
                </v-btn>
                Revoke device access
            </v-tooltip>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex';
import format from 'date-fns/format';

export default {
    props: {
        device: Object
    },
    data() {
        return {
            loading: false
        }
    },
    methods: {
        revokeAccess() {
            this.$emit('revoke', this)
        }
    },
    computed: {
        ...mapGetters('userInfo', ['userInfo']),
        createdDate() {
            return format(this.device.created, "D MMM YYYY h:mm A")
        },
        lastAccesseDate() {
            return format(this.device.lastAccessed, "D MMM YYYY h:mm A")
        }
    },
}
</script>

<style scoped>

</style>