let emojiDb = null;

async function addEmojiKey(transaction, obs, key) {
  return new Promise((resolve, reject) => {
    const request = emojiDb.transaction(obs, 'readwrite').objectStore(obs).put(key);
    request.onsuccess = () => { resolve(request) }
    request.onerror = (err) => { reject(err) }
  })
}

let dbStarted = false;

export async function waitForEmojiDb() {
  return new Promise((resolve, reject) => {
    if (!dbStarted && !emojiDb) {
      dbStarted = true;
      initEmojiDb()
        .then(() => {
          resolve(true);
        });
    } else if (!emojiDb && dbStarted) {
      setTimeout(() => {
        resolve(waitForEmojiDb());
      }, 50);
    } else if (emojiDb) {
      resolve(true);
    }
  });
}

export async function waitForEmojiDbStarted() {
  return new Promise((resolve, reject) => {
    if (dbStarted) {
      return true
    } else {
      setTimeout(() => {
        resolve(waitForEmojiDbStarted());
      }, 50);
    }
  });
}

export function initEmojiDb() {
  return new Promise((resolve, reject) => {

    if (emojiDb)
      return resolve(emojiDb)

    try {
      let resSent = false;
      const request = window.indexedDB.open('emojiConfig', 1);

      request.onupgradeneeded = (event) => {
        if (event && event.target && event.target) {
          emojiDb = event.target.result
          emojiDb.createObjectStore("frequentConfig", { keyPath: "id" });
        }
      };

      request.onerror = (event) => {
        if (!resSent) {
          console.log('onerror', event);
          reject(event);
        }
      };

      request.onsuccess = (event) => {
        emojiDb = request.result;
        emojiDb.onerror = (e) => { console.log(e); };
        // console.log("LOCAL DB READY");
        resolve(emojiDb);
      };
    } catch (e) {
      console.log(e);
    }
  });
}

export function setEmojiKey(id, val) {
  return new Promise((resolve, reject) => {
    waitForEmojiDb().then(() => {
      let transaction = emojiDb.transaction(["frequentConfig"], "readwrite");
      transaction.oncomplete = () => { resolve(true); };
      transaction.onerror = (event) => { reject(event.target.error || 'An Error Occured'); };
      let objectStore = transaction.objectStore("frequentConfig");
      objectStore.put({ id: id, val: val });
    });
  });
}

export function getEmojiKey(id) {
  return new Promise((resolve, reject) => {
    waitForEmojiDb().then(() => {
      let transaction = emojiDb.transaction(["frequentConfig"], "readonly");
      let objectStore = transaction.objectStore("frequentConfig");
      let request = objectStore.get(id);
      request.onerror = (event) => {
        reject(event.target.error || 'An Error Occured')
      };
      request.onsuccess = () => {
        resolve(request.result ? request.result.val : null)
      };
    })
  })
}
