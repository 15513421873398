<template>

    <v-layout row fill-height class="of-y" style="overflow-x: hidden; background: rgba(0,0,0,0)">
        <v-flex xs12 class="fill-height of-y item-parent scroller" ref="scroller"
                @scroll="scrollerScrollTop = $refs.scroller ? $refs.scroller.scrollTop : 0"
                style="position: relative"
        >
            <template v-for="(item, i) in drawingItems">
                <div
                        ref="item"
                        class="c-d-flex c-align-center c-justify-center item"
                        :key="item[keyName]"
                >

<!--                    <v-layout row>-->
<!--                        <v-flex xs1 class="c-d-flex c-align-center c-justify-center subheading">-->
<!--&lt;!&ndash;                            {{item.id}} - {{i}}&ndash;&gt;-->
<!--&lt;!&ndash;                            <br/>&ndash;&gt;-->
<!--&lt;!&ndash;                            &ndash;&gt;-->
<!--&lt;!&ndash;                            <br>&ndash;&gt;-->
<!--                            {{ item[keyName] }} - {{i}}-->
<!--                            <br>-->
<!--                            {{heights[i]}}-->
<!--                        </v-flex>-->
<!--                        <v-flex xs11>-->
                            <slot
                                    name="item"
                                    :item="item"
                                    :index="i"
                            />
<!--                        </v-flex>-->
<!--                    </v-layout>-->
                </div>
            </template>
        </v-flex>

<!--        <v-flex shrink class="fill-height caption pa-1" style="overflow-y: hidden; min-width: 80px">-->


<!--            Start: {{startIndex}}-->
<!--            <br>-->
<!--            End: {{endIndex}}-->
<!--            <br>-->
<!--            items: {{endIndex - startIndex}}-->
<!--            <br>-->
<!--            viewRange: {{viewRange}}-->
<!--            <br>-->
<!--            <v-btn @click="test"> Test </v-btn>-->
<!--&lt;!&ndash;            bottomItemIndex: {{bottomItemIndex}}&ndash;&gt;-->

<!--        </v-flex>-->

        <v-flex shrink class="fill-height" style="overflow-y: hidden; min-width: 20px">
            <div style="position: relative;" class="fill-height bar" ref="bar" @click.self="barClicked" @wheel="barScrolled">
                <template v-if="initDone">

<!--                    <template v-if="initDone">-->
<!--                        <div-->
<!--                                v-for="(item, i) in items"-->
<!--                                class="blue point caption c-d-flex c-align-center white&#45;&#45;text"-->
<!--                                style="position: absolute; width: 100%; line-height: 0; pointer-events: none;"-->
<!--                                :style="{-->
<!--                                    height: `${(barHeight - (barPadding * 2)) / ammOfItems}px`,-->
<!--                                    top: `${(((barHeight - (barPadding * 2)) / ammOfItems) * i) + barPadding}px`,-->
<!--                                    // display: i >= topItemIndex && i <= bottomItemIndex ? null : 'none'-->
<!--                                }"-->
<!--                        >-->
<!--                            {{i}}-->
<!--                        </div>-->
<!--                    </template>-->

                    <div class="scrollTrack">
                    </div>

                    <template v-for="(label) in cleanLabels">
                        <div
                                v-if="label"
                                class="bar-label"
                                :style="{ top: `${label.top}%` }"
                                @click="gotoIndex(label.index)"
                        >
                            <slot
                                    name="label"
                                    :label="label.item"
                                    :index="label.index"
                            />
                        </div>
                    </template>

                    <div
                            v-if="!hideBar"
                            class="pointHandle"
                            :style="{
                                top: (barPadding) + (((barHeight - (barPadding * 2)) / ammOfItems) * ((((bottomItemIndex + 1) - topItemIndex) / 2)  + topItemIndex)) + 'px',
                                height: ( ((barHeight - (barPadding * 2)) / ammOfItems) * ((bottomItemIndex + 1) - topItemIndex)) + 'px',
                                display: initDone && $refs.scroller.scrollHeight === $refs.scroller.clientHeight ? 'none' : null
                            }"
                    >
                    </div>
                </template>
            </div>
        </v-flex>
    </v-layout>
</template>

<script>

import VueScrollTo from "vue-scrollto";

function clamp(val, min, max) {
    return Math.max(min, Math.min(max, val));
}

export default {
    props: {
        items: {
            type: Array,
            required: true
        },
        itemsInParent: {
            type: Number,
            default: 30
        },
        flushCount: {
            type: Number,
            default: 10
        },

        triggerCount: {
            type: Number,
            default: 10
        },

        triggerPush: {
            type: Number,
            default: 10
        },

        keyName: {
            type: String,
            required: true
        },
        labels: {
            type: Array,
            default: () => {
                return []
            }
        }
    },
    data() {
        return {
            topIndex: 0,
            // drawingItems: [],
            initDone: false,
            scrollerScrollTop: 0,
            someTop: 0,
            barPadding: 2,
            pauseScrollingEvent: false,


            hideBar: false,

            scrollerHandleTop: null,
            scrollerHandleHeight: null,
            lastPosition: 0,


            startIndex: 0,
            endIndex: 20,

            topOffset: 0,

            scrollExc: null,


            ignoreUp: false,
            ignoreNextUp: false,
            ignoreUpTimeout: null,

            ignoreDown: false,
            ignoreNextDown: false,
            ignoreDownTimeout: null,

            downForceCheckTimeout: null,
            upForceCheckTimeout: null,
            scrollTrigger: 0,

            viewRange: 0,
        }
    },

    watch: {

        scrollTrigger() {
            this.updateViewRange()
        },

        items: {
            immediate: true,
            deep: true,
            handler(after, before) {
                this.$nextTick(() => {
                    if (this.$refs.scroller.scrollHeight === this.$refs.scroller.clientHeight && this.endIndex < this.items.length) {
                        this.endIndex = Math.min(this.items.length - 1, this.itemsInParent)
                    }
                })
            }
        },


        // topIndex: {
        //     immediate: true,
        //     handler(topIndexAfter, topIndexBofore) {
        //         console.log({topIndexBofore, topIndexAfter})
        //         // if (!this.drawingItems || !this.drawingItems.length) {
        //         //     this.drawingItems.splice(0, this.drawingItems.length)
        //         //     for (let k = this.topIndex; k < Math.min(this.items.length, this.topIndex + this.itemsInParent); ++k) {
        //         //         this.drawingItems.push(this.items[k])
        //         //     }
        //         // }
        //     }
        // },
    },
    mounted() {
        this.startIndex = 0;
        this.endIndex = Math.min(this.itemsInParent, this.items.length - 1)
        this.$nextTick(() => {
            this.$refs.scroller.addEventListener('scroll', this.scrolled)
        })
        this.$nextTick(() => {
            this.initDone = true
        })
    },
    methods: {

        gotoIndex(index) {
            this.barClicked(null, index)
        },

        test() {





            // console.log(this.$refs.scroller.scrollHeight, this.$refs.scroller.clientHeight)
        },

        updateViewRange(timeout = 300) {
            setTimeout(() => {
                if (!this.initDone)
                    return 0

                const i = this.startIndex + this.endIndex + this.scrollTrigger
                const scrollTop = this.$refs.scroller.scrollTop;
                const scrollBottom = this.$refs.scroller.clientHeight + this.$refs.scroller.scrollTop

                const parentBounds = this.$refs.scroller.getBoundingClientRect()

                let run = true;
                let topIndex = 0
                while (run && topIndex < this.$refs.item.length) {
                    const bounds = this.$refs.item[topIndex].getBoundingClientRect()
                    const bottom = (bounds.top + bounds.height) - parentBounds.top
                    if (bottom > 0)
                        run = false
                    else
                        ++topIndex
                }
                this.viewRange = topIndex
            }, timeout)
        },

        pauseUp(duration) {
            this.ignoreNextUp = true
            this.ignoreUp = true
            clearTimeout(this.ignoreUpTimeout)
            this.ignoreUpTimeout = setTimeout(this.unpauseUp, duration)
        },

        unpauseUp() {
            this.ignoreUp = false
        },

        pauseDown(duration) {
            this.ignoreNextDown = true
            this.ignoreDown = true
            clearTimeout(this.ignoreDownTimeout)
            this.ignoreDownTimeout = setTimeout(this.unpauseDown, duration)
        },

        unpauseDown() {
            this.ignoreDown = false
        },


        scrollToTop() {
            this.startIndex = 0;
            this.endIndex = Math.min(this.items.length - 1, this.startIndex + this.itemsInParent)
            this.$refs.scroller.scrollTop = 0

        },

        barScrolled(e) {
            try {
                // this.$refs.scroller.scrollTop = this.$refs.scroller.scrollTop + (e.wheelDeltaY * (-1))

                this.$refs.scroller.scrollTo(0, this.$refs.scroller.scrollTop + (e.wheelDeltaY * (-1)))
            } catch (e) {
                console.log(e)
            }
        },

        barClicked(e, overrideIndex = false) {
            // console.log(e.offsetY)

            if (this.$refs.scroller.scrollHeight === this.$refs.scroller.clientHeight) return

            this.pauseScrollingEvent = true
            this.ignoreDown = true
            this.ignoreUp = true

            this.someTop = overrideIndex === false ? e.offsetY : 0
            //Single Item Height
            const siHeight = (this.$refs.bar.clientHeight - (this.barPadding * 2)) / this.items.length
            const itemIndex = overrideIndex !== false ? overrideIndex : clamp(Math.floor(((e.offsetY - this.barPadding) / siHeight)), 0, this.items.length - 1)

            this.startIndex = Math.min(itemIndex, (this.items.length - 1 - this.itemsInParent))
            this.startIndex = Math.max(this.startIndex - 1, 0)
            this.endIndex = Math.min(this.startIndex + this.itemsInParent + 1, this.items.length - 1)

            const containerIndex = itemIndex - this.startIndex

            setTimeout(() => {
                VueScrollTo.scrollTo(this.$refs.item[containerIndex], 1, { container: this.$refs.scroller, x: false, y: true});
                setTimeout(() => {
                    this.lastPosition = this.$refs.scroller.scrollTop;
                    this.ignoreDown = false;
                    this.ignoreUp = false;
                    this.pauseScrollingEvent = false
                    this.scrolled()
                }, 500)


            }, 500)


        },

        runScroll() {

            const vm = this;

            function goDown() {
                console.log("Down Ran")

                vm.pauseUp(500)

                vm.pauseScrollingEvent = true

                const firstItemBounds = vm.$refs.item[0].clientHeight

                if (firstItemBounds < vm.$refs.scroller.scrollTop) {
                    let run = true
                    let lastElBottom = null
                    let k = 0
                    while (run) {
                        k = k + 1
                        const totalBottom = Array.from(vm.$refs.item).splice(0, k)
                                .reduce((total, item) => total + item.clientHeight, 0)
                        lastElBottom = totalBottom - vm.$refs.scroller.scrollTop
                        if (totalBottom > vm.$refs.scroller.scrollTop) {
                            run = false
                        }
                    }


                    vm.startIndex = Math.max((vm.startIndex + k - 10), 0)

                    // console.log("START INDEX", vm.startIndex)
                }

                setTimeout(() => {
                    const bottomOfScrollWindow = vm.$refs.scroller.scrollTop + vm.$refs.scroller.clientHeight
                    const lastItemTop = Array.from(vm.$refs.item)
                            .splice(0, vm.$refs.item.length - 5)
                            .reduce((total, item) => total + item.clientHeight, 0)
                    if (lastItemTop <= bottomOfScrollWindow) {
                        vm.endIndex = Math.min(vm.endIndex + 5, Math.max(vm.items.length, 0))
                    }
                    vm.pauseScrollingEvent = false
                }, 1)
                // clearTimeout(vm.downForceCheckTimeout)
                vm.downForceCheckTimeout = setTimeout(() => {
                    const scrollBottom = vm.$refs.scroller.scrollTop + vm.$refs.scroller.clientHeight
                    // console.log({
                    //     scrollHeight: vm.$refs.scroller.scrollHeight,
                    //     scrollBottom: scrollBottom,
                    //     endIndex: vm.endIndex,
                    //     Length: vm.items.length,
                    //     condition: vm.$refs.scroller.scrollHeight === scrollBottom && vm.endIndex < vm.items.length
                    // })
                    if (vm.$refs.scroller.scrollHeight === scrollBottom && vm.endIndex < vm.items.length) {
                        console.log("SCROLL DOWN FORCED")
                        goDown()
                    }
                }, 300)

                vm.scrollTrigger = Math.random()
            }

            function goUp() {
                console.log("UP RAN")
                vm.pauseDown(500)

                setTimeout(() => {

                    // setTimeout(() => {
                    // Find the first Container That Is Out Of View
                    const scrollViewPortBottom = vm.$refs.scroller.clientHeight + vm.$refs.scroller.scrollTop
                    let k = 0;
                    let totalTop = 0;
                    let run = true;
                    while (run && k < vm.$refs.item.length) {
                        totalTop = totalTop + vm.$refs.item[k].clientHeight
                        if (totalTop >= scrollViewPortBottom)
                            run = false
                        ++k
                    }
                    // Set End Index to last container + 5

                    // TODO Check Glichyness

                    vm.endIndex = Math.min(k + vm.startIndex + 5, vm.items.length - 1)
                    vm.pauseScrollingEvent = false
                    // }, 300)

                    const topFiveItemTop = Array.from(vm.$refs.item)
                            .splice(0, 5)
                            .reduce((total, item) => total + item.clientHeight, 0)

                    // console.log(topFiveItemTop, vm.$refs.scroller.scrollTop)

                    if (vm.$refs.scroller.scrollTop <= topFiveItemTop || (vm.$refs.scroller.scrollTop === 0)) {
                        const startBefore = vm.startIndex
                        vm.startIndex = Math.max(0, vm.startIndex - 10)
                        vm.lastPosition = vm.$refs.scroller.scrollTop
                        if (vm.startIndex > 0)
                            vm.$refs.scroller.scrollTop = vm.$refs.scroller.scrollTop + 1
                    }

                    vm.updateViewRange()
                }, 1)

                clearTimeout(vm.upForceCheckTimeoutForceCheckTimeout)
                vm.upForceCheckTimeoutForceCheckTimeout = setTimeout(() => {
                    if (vm.$refs.scroller.scrollTop === 0 && vm.startIndex > 0) {
                        goUp()
                    }
                    vm.updateViewRange()
                }, 300)

                vm.updateViewRange()
            }


            if (this.pauseScrollingEvent) {
                // console.log("STOPPED")
                return
            }
            const scrollTop = this.$refs.scroller.scrollTop

            const up = this.lastPosition > scrollTop
            const down = this.lastPosition < scrollTop


            if (down && !this.ignoreDown && !this.ignoreNextDown) {
                goDown();
            }

            if (up && !this.ignoreUp && !this.ignoreNextUp) {
                goUp();
            }

            // console.log( this.ignoreNextUp, this.ignoreNextDown, this.ignoreDown, this.ignoreUp)

            this.ignoreNextUp = false;
            this.ignoreNextDown = false;

            this.scrollTrigger = Math.random()

            this.lastPosition = scrollTop
        },


        scrolled() {
            clearTimeout(this.scrollExc)
            this.scrollExc = setTimeout(() => {
                this.runScroll()
            }, 10)

            this.updateViewRange()
            this.scrollerScrollTop = this.$refs.scroller.scrollTop
        }
    },
    computed: {

        cleanLabels() {
            return this.labels.reduce((list, item, index) => {
                return item ? list.concat({
                    item,
                    index,
                    top: ((index+1) / this.items.length) * 100
                }) : list
            }, [])
        },


        heights() {

            if (this.initDone) {

                const i = this.startIndex - this.endIndex

                return this.$refs.item.map(el => el.clientHeight)
            } else {
                return this.drawingItems.map((_,i) => i)
            }

        },

        tops() {

            if (this.heights) {

                let tops = []
                let lastTop = this.topOffset

                for (let k = 0; k < this.heights.length; ++k) {
                    if (k === 0) {
                        tops.push(lastTop)
                    } else {
                        lastTop = lastTop + this.heights[k-1]
                        tops.push(lastTop)
                    }
                }

                return tops


            } else {
                return this.drawingItems.map((_,i) => i)
            }

        },

        drawingItems() {
            // const items = [...this.items].splice(this.topIndex, this.itemsInParent)

            const items = [...this.items].splice(this.startIndex, (this.endIndex + 1) - this.startIndex)

            // for (let k = this.topIndex; k < Math.min(this.items.length, this.topIndex + this.itemsInParent); ++k) {
            //     items.push(this.items[k])
            // }
            return items
        },

        ammOfItems() {
            return this.items.length
        },
        barHeight() {
            return this.initDone ? this.$refs.bar.clientHeight : 0
        },

        itemsScrollHeight() {
            return this.initDone ? this.$refs.scroller.scrollHeight : 0
        },


        bottomItemIndex() {
            if (!this.initDone) return 0
            if (!this.items.length) return 0
            if (!this.$refs.item) return 0
            // Figure out the top container in scroller
            const scrollerPosition = this.scrollerScrollTop
            let index = 0
            let totalTop = 0;

            while (totalTop < scrollerPosition + this.$refs.scroller.clientHeight && index < this.$refs.item.length) {
                totalTop = totalTop + this.$refs.item[index].clientHeight
                ++index;
            }

            return index + this.startIndex - 1
        },


        topItemIndex() {
            if (!this.initDone) return 0

            // Figure out the top container in scroller
            const scrollerPosition = this.scrollerScrollTop
            let index = 0
            let totalTop = 0;
            while (totalTop < scrollerPosition && index < this.$refs.item.length) {
                totalTop = totalTop + this.$refs.item[index].clientHeight
                ++index;
            }
            return index + this.startIndex
        }
    }
}
</script>

<style scoped>

.bar-label {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    cursor: pointer;
}

.point {
    opacity: 1;
}

.point:nth-child(even) {
    opacity: 0.5;
}

.scroll-handle {
    transition: top 0.2s ease-out;
}

.bar {
    /*background: rgba(0,0,0,0.2);*/
}

.pointHandle {
    background: var(--v-primary-base);
    border-radius: 6px;
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    height: 12px;
    width: 12px;
    transition: top 0.3s ease-in-out, height 0.3s ease-in-out;
    min-height: 14px;
    pointer-events: none;
}

.scrollTrack {
    position: absolute;
    background: rgba(0, 0, 0, 0.2);
    transform: translate(-50%, 0);
    left: 50%;
    width: 3px;
    top: 5px;
    height: calc(100% - 10px);
    pointer-events: none;
    border-radius: 2px;
}

.scroller {
    /*scroll-behavior: smooth;*/
}

.scroller::-webkit-scrollbar {
    width: 0px;
    height: 8px;
    background-color: #F5F5F5;
}
</style>