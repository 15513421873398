<template>
    <div>
        <div @click="showAdd = !showAdd">
            <slot name="activator"></slot>
        </div>

        <app-input-dlg
                :show-dialog="showAdd"
                title="Add User Groups"
                :cancel-button="true"
                :show-input-box="false"
                @dismiss="showAdd = false"
                :body-padding="false"
        >
            <template slot="body">
                <div class="px-1 pt-1">
                    <div :class="topicClass">
                        <div :class="headingClass">
                            Select User Group/s to Add:
                        </div>
                    </div>
                    <multi-select-list
                        :show-action-buttons="false"
                        :show-check-boxes="true"
                        :multiple="true"
                        headline-key="description"
                        sub-line-key="membersDescriptions"
                        item-key="id"
                        :return-object="false"
                        v-model="selectedUserGroup"
                        :show-filter="true"
                        :items="filteredSelectedUserGroups"
                        :height="400"
                        filter-label="Filter Users"
                    />
<!--                        <div v-for="item in filteredSelectedUserGroups" :key="item.id" >-->
<!--                            <div-->
<!--                                    class="hover-item"-->
<!--                                    :class="selectedUserGroup != null && selectedUserGroup.id == item.id ? 'grey lighten-3' : ''"-->
<!--                            >-->
<!--                                <v-layout row align-center style="overflow-x: hidden" class="hide-parent">-->
<!--                                    <v-flex xs12 style="overflow-x: hidden" class="pa-2" @click="selectedUserGroup = item">-->
<!--                                        <div class="body-1" :class="selectedUserGroup != null && selectedUserGroup.id == item.id ? 'primary&#45;&#45;text' : ''">-->
<!--                                            {{item.description}}-->
<!--                                        </div>-->
<!--                                        <div class="caption text-truncate" :class="Array.isArray(item.members) ? 'grey&#45;&#45;text' : 'red&#45;&#45;text'" style="overflow-x: hidden">-->
<!--                                            {{Array.isArray(item.members) ? item.membersDescriptions.map(obj => {return typeof obj != 'undefined' ? obj : 'Error Retrieving User'}).join(', ') : 'NO USERS ASSIGNED'}}-->
<!--                                        </div>-->
<!--                                    </v-flex>-->
<!--                                </v-layout>-->
<!--                                <v-divider/>-->
<!--                            </div>-->
<!--                        </div>-->
                </div>
            </template>
            <template slot="actions">
                <div class="text-xs-right">
                    <v-btn
                            small
                            color="secondary"
                            :disabled="selectedUserGroup == null || addingUserGroup"
                            :loading="addingUserGroup"
                            @click="addUserGroup"
                    >
                        Add User Groups
                    </v-btn>
                </div>
            </template>
        </app-input-dlg>
    </div>
</template>

<script>
    import {codeBaseMixin} from "../../../codeBaseMixin";
    import InputDlg from "../../../components/General/InputDlg";
    import MultiSelectList from "@/components/Multi Select List/MultiSelectList";

    export default {
        components: {
            MultiSelectList,
            appInputDlg: InputDlg
        },
        mixins: [codeBaseMixin],
        props: {
            userInfo: Object,
            selectedUser: Object,
            userGroups: Array,
        },
        data() {
            return {
                showAdd: false,
                userGroupFilterString: null,
                selectedUserGroup: null,
                addingUserGroup: false,
            }
        },
        watch: {
            showAdd(shown) {
                if (shown) {
                    this.userGroupFilterString = null;
                    this.selectedUserGroup = null;
                    this.addingUserGroup = false;
                    this.$nextTick(() => {
                        this.$refs.UGroupFilter.focus();
                    })
                }
            },
        },
        methods: {
            addUserGroup() {
                this.addingUserGroup = true;
                this.fetch({method: 'POST', url:`/users/security/addUserToGroup/${this.userInfo.entityID}/${this.selectedUser.id}`, body: this.selectedUserGroup})
                    .then((data) => {
                        this.$emit('userGroupsAdded', data);
                        this.showAdd = false;
                        this.addingUserGroup = false;
                        this.showSnack('Info', 'User Group/s Added', 'Close', 'primary')
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e);
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
                        this.addingUserGroup = false;
                    })
            }
        },
        computed: {
            filteredSelectedUserGroups() {
                    return this.userGroups.filter(obj => true)//this.blankString(this.userGroupFilterString)
                        // ? this.userGroups.filter(obj => true)
                        //     .sort((a,b) => a['description'] < b['description'] ? -1 : 1)
                        //
                        // : this.userGroups
                        //     .filter(obj => `${obj.description} ${obj.membersDescriptions.join(' ')}`.toLowerCase().includes(this.userGroupFilterString.toLowerCase()))
                        //     .sort((a,b) => a['description'] < b['description'] ? -1 : 1);
            },
        }

    }
</script>

<style scoped>

</style>
