<template>
    <v-layout row fill-height>

        <v-flex xs3 class="fill-height scrollable">
            <multi-select-list
                    :show-action-buttons="false"
                    :show-check-boxes="true"
                    :multiple="false"
                    headline-key="description"
                    item-key="index"
                    :return-object="true"
                    v-model="selectedSignature"
                    :show-filter="true"
                    :items="signatures"
                    :dark="true"
                    filter-label="Filter Signatures"
                    :show-select-all="false"
                    :cutom-sort-fn="(a,b) => 1"
                    @sortEvent="sortEvent($event)"
            >
                <div slot="header" class="primary">
                    <div class="subheading white--text px-2 text-xs-center">
                        <v-btn
                                block
                                small
                                class="ma-0"
                                color="white"
                                outline
                                @click="showAddSig = true"
                        >
                            <v-icon left>add</v-icon>
                            Add Document Signature
                        </v-btn>
                    </div>
                </div>
                <template v-slot:action="{value, index, selected}">
                    <div v-if="selected" class="c-d-flex pr-1">
                        <v-tooltip top>
                            <v-btn
                                    icon
                                    small
                                    flat
                                    class="pa-0 ma-0"
                                    color="primary"
                                    @click="copySectionBelow(index, value)"
                                    slot="activator"
                            >
                                <v-icon small>file_copy</v-icon>
                            </v-btn>
                            Copy Signature
                        </v-tooltip>
                        <v-tooltip top>
                            <template v-slot:activator="{ on }">
                                <v-btn
                                        icon
                                        small
                                        flat
                                        class="pa-0 ma-0"
                                        color="primary"
                                        @click="removeID = value.id"
                                        v-on="on"
                                >
                                    <v-icon small>delete</v-icon>
                                </v-btn>
                            </template>
                            Remove Signature
                        </v-tooltip>
                    </div>
                </template>


            </multi-select-list>
        </v-flex>
        <v-flex xs9 v-if="selectedSignature" class="pa-1 grey lighten-3 fill-height">
            <document-signature
                    :value="selectedSignature"
                    :user-info="userInfo"
                    :datasets="datasets"
                    :steps="steps"
                    :fields="fields"
                    :index="signatures.indexOf(selectedSignature)"
                    :tab-index="tabIndex"
                    @tabIndexChange="tabIndex = $event"
            >
            </document-signature>
        </v-flex>


<!--Signatures-->
<!--        <draggable-->
<!--                :list="signatures"-->
<!--                group="sections"-->
<!--                @start="drag=true"-->
<!--                @end="drag=false"-->
<!--                ghostClass="ghost"-->
<!--        >-->
<!--            <v-layout-->
<!--                    v-for="(sig, i) in signatures"-->
<!--                    :key="sig.id"-->
<!--                    row-->
<!--                    align-center-->
<!--                    justify-center-->
<!--                    class="py-2"-->
<!--            >-->
<!--                <v-flex shrink class="pr-5">-->
<!--                    <v-btn-->
<!--                            fab-->
<!--                            color="primary"-->
<!--                            class="white&#45;&#45;text title"-->
<!--                    >-->
<!--                        {{i + 1}}-->
<!--                    </v-btn>-->
<!--                </v-flex>-->
<!--                <v-flex shrink>-->
<!--                    <div style="width: 600px">-->
<!--                        <document-signature-->
<!--                                :value="sig"-->
<!--                                :user-info="userInfo"-->
<!--                                :datasets="datasets"-->
<!--                                :steps="steps"-->
<!--                                :fields="fields"-->
<!--                                :index="i"-->
<!--                        >-->
<!--                            <template slot="title-bar">-->
<!--                                <v-btn-->
<!--                                        icon-->
<!--                                        flat-->
<!--                                        small-->
<!--                                        color="white"-->
<!--                                        class="pa-0 ma-0"-->
<!--                                        @click="removeSignature(sig.id)"-->
<!--                                >-->
<!--                                    <v-icon>delete</v-icon>-->
<!--                                </v-btn>-->
<!--                            </template>-->
<!--                        </document-signature>-->
<!--                    </div>-->
<!--                </v-flex>-->
<!--            </v-layout>-->
<!--        </draggable>-->

<!--<v-divider></v-divider>-->
<!--Add New Signature-->
<!--        <div class="pa-2 text-xs-center">-->
<!--            <v-btn-->
<!--                    small-->
<!--                    class="ma-0"-->
<!--                    color="secondary"-->
<!--                    @click="showAddSig = true"-->
<!--            >-->
<!--                <v-icon left>add</v-icon>-->
<!--                Add Document Signature-->
<!--            </v-btn>-->
<!--        </div>-->

        <app-input-dlg
                v-if="showAddSig"
                :show-dialog="showAddSig"
                :cancel-button="true"
                :show-input-box="false"
                @dismiss="showAddSig = false"
                :body-padding="false"
                :width="800"
        >
            <template slot="title">
                <div v-show="false">BLANK TITLE</div>
            </template>
            <template slot="body">
                <div style="width: 100%">
                    <document-signature
                            v-model="addSignature"
                            :user-info="userInfo"
                            :datasets="datasets"
                            :steps="steps"
                            :fields="fields"
                            :tab-index="tabIndex"
                            @tabIndexChange="tabIndex = $event"
                    >
                        <template slot="buttons">
                            <v-btn small color="secondary" @click="showAddSig = false" class="py-0">Cancel</v-btn>
                            <v-btn small color="secondary" class="py-0" @click="addSignatureToList"><v-icon left>add</v-icon>Add Signature</v-btn>
                        </template>
                    </document-signature>
                </div>
            </template>
        </app-input-dlg>

        <app-input-dlg
                v-if="removeID !== null"
                :show-dialog="removeID !== null"
                title="Confirm Remove"
                body="Please note if the signature was added to a section or a signature input field was linked then the template will no longer be valid. Please remove all links before applying this action"
                :cancel-button="true"
                :show-input-box="false"
                :buttons="['Confirm']"
                :call-backs="[ removeSignatureFinal ]"
                @dismiss="removeID = null"
        />


    </v-layout>
</template>

<script>
    import DocumentSignature from "./DocumentSignature";
    import InputDlg from "../../../../../../components/General/InputDlg";
    import {arrSplice, getUUID} from "../../../../../../codeFunctions";
    import draggable from 'vuedraggable';
    import MultiSelectList from "@/components/Multi Select List/MultiSelectList";

    export default {
        components: {
            MultiSelectList,
            draggable,
            appInputDlg : InputDlg,
            DocumentSignature
        },
        props: {
            userInfo: Object,
            signatures: Array,
            datasets: Array,
            fields: Array,
            steps: Array,
            selectLists: Object
        },
        data() {
            return {
                addSignature: null,
                showAddSig: false,
                removeID: null,
                selectedSignature: null,
                tabIndex: 0
            }
        },
        watch: {
            showAddSig(val) {
                if (val) {
                    this.addSignature = null
                }
            }
        },
        methods: {
            copySectionBelow(index, val) {
                let tSig = JSON.parse(JSON.stringify(val));
                this.signatures.splice(index + 1, 0, {...tSig, id: getUUID()})
            },

            sortEvent(sortedSignatures) {
                this.signatures.splice(0, this.signatures.length);
                sortedSignatures.forEach(sig => this.signatures.push(sig))
            },

            removeSignature(sigID) {
                this.removeID = sigID;
            },

            removeSignatureFinal() {
                arrSplice(this.signatures, sig => sig.id === this.removeID);
                this.removeID = null;
            },

            addSignatureToList() {
                this.signatures.push(this.addSignature);
                this.showAddSig = false;
            }
        },
    }
</script>

<style scoped>

</style>
