<template>
    <div class="fill-height pa-1">
        <v-card class="fill-height scrollable">
            <v-layout column fill-height>
                <v-flex shrink>
                    <v-card-title class="white--text pa-0 ma-0 primary">
                        <v-layout row align-center>
                            <v-flex xs6>
                                <div class="pa-2">Select Report</div>
                            </v-flex>
                            <v-flex xs6 class="text-xs-right">
                                <v-switch
                                    class="pa-0 ma-0 py-1"
                                    hide-details
                                    v-model="reportSwitch"
                                    :label="!reportSwitch ? 'My Reports' : 'All Reports'"
                                    color="white"
                                    dark
                                    style="display: inline-block;"
                                >
                                </v-switch>
<!--                                <v-autocomplete-->
<!--                                    :items="['My Reports', 'Shared With Me', 'All Reports']"-->
<!--                                    color="white"-->
<!--                                    dark-->
<!--                                    flat-->
<!--                                    v-model="selectedReportTypes"-->
<!--                                    hide-details-->
<!--                                    class="pa-0 ma-0"-->
<!--                                    single-line-->
<!--                                    label="Report Types"-->
<!--                                    dense-->
<!--                                    hide-selected-->
<!--                                >-->
<!--                                </v-autocomplete>-->
                            </v-flex>
<!--                            <v-flex xs6>-->
<!--                                <v-autocomplete-->
<!--                                        :items="['My Reports', 'Shared With Me', 'All Reports']"-->
<!--                                        color="white"-->
<!--                                        dark-->
<!--                                        flat-->
<!--                                        v-model="selectedReportTypes"-->
<!--                                        hide-details-->
<!--                                        class="pa-0 ma-0"-->
<!--                                        single-line-->
<!--                                        label="Report Types"-->
<!--                                        dense-->
<!--                                        hide-selected-->
<!--                                >-->
<!--                                </v-autocomplete>-->
<!--                            </v-flex>-->
                            <v-flex class="px-2" shrink>
                                <v-tooltip top>
                                    <v-btn
                                            class="pa-0 ma-0"
                                            small
                                            icon
                                            color="white"
                                            slot="activator"
                                            flat
                                            @click="$emit('refreshReports')"
                                    >
                                        <v-icon>refresh</v-icon>
                                    </v-btn>
                                    <span>Refresh Reports</span>
                                </v-tooltip>
                            </v-flex>
                        </v-layout>
                    </v-card-title>
                </v-flex>
                <v-flex shrink>
                    <div class="pa-2" v-if="intToBool(userInfo.reportsManager)">
                        <v-btn
                                outline
                                block
                                color="secondary"
                                class="pa-0 ma-0"
                                small
                                @click="showAddReport = true"
                        >
                            <v-icon>add</v-icon>
                            Create New Report
                        </v-btn>
                    </div>
                    <div class="px-2">
                        <v-text-field
                                label="Filter Reports"
                                solo
                                clearable
                                prepend-icon="search"
                                color="primary"
                                flat
                                :hide-details="true"
                                v-model="reportFilter"
                                class="pa-0 ma-0"
                                ref="filterField"
                        ></v-text-field>
                    </div>
                </v-flex>
                <v-divider></v-divider>
                <v-flex xs12 class="scrollable" id="reportsBar">
                    <div
                            v-for="report in filteredReports"
                            :key="`SelectReport${report.id}`"
                            :id="`report${report.id}`"
                    >
                        <v-layout
                                row
                                align-center
                                class="pointer hide-parent"
                                :class="selectedReport.id == report.id ? 'selected-item' : 'hover-item'"
                        >

                            <v-flex shrink class="pl-2" @click="$emit('input', report)">
                                <v-tooltip right>
                                    <v-btn
                                            small
                                            flat
                                            icon
                                            :color="selectedReport.id == report.id ? 'primary' : 'grey'"
                                            class="ma-0"
                                            slot="activator"
                                    >
                                        <v-icon small>{{report.role === 'owner' ? 'person' : (report.role === 'editor' ? 'edit' : 'visibility')}}</v-icon>
                                    </v-btn>
                                    <span>{{report.role === 'owner' ? 'Report Owner' : (report.role === 'editor' ? 'Report Editor' : 'Report Viewer')}}</span>
                                </v-tooltip>
                            </v-flex>
                            <v-flex xs12 class="caption pa-2" :class="selectedReport.id == report.id ? 'primary--text font-weight-bold' : ''" @click="$emit('input', report)">
                                {{report.description}}
                            </v-flex>
                            <v-flex shrink class="pl-1 hide-child" v-if="intToBool(userInfo.reportsManager)">
                                <v-tooltip top>
                                    <v-btn
                                            small
                                            flat
                                            icon
                                            class="ma-0"
                                            color="secondary"
                                            slot="activator"
                                            @click="duplicateReport = report"
                                            :loading="duplicateReport != null && duplicateReport === report.id"
                                            :disabled="duplicateReport != null"
                                    >
                                        <v-icon small>file_copy</v-icon>
                                    </v-btn>
                                    <span>Duplicate Report</span>
                                </v-tooltip>
                            </v-flex>
                            <v-flex shrink class="pl-1 hide-child" v-if="intToBool(userInfo.reportsManager) && report.creator === userInfo.userID">
                                <v-tooltip top>
                                    <v-btn
                                            small
                                            flat
                                            icon
                                            class="ma-0"
                                            color="primary"
                                            slot="activator"
                                            @click="$emit('rename', report)"
                                    >
                                        <v-icon small>edit</v-icon>
                                    </v-btn>
                                    <span>Rename Report</span>
                                </v-tooltip>
                            </v-flex>
                            <v-flex shrink class="pl-1 hide-child" v-if="intToBool(userInfo.reportsManager) && report.role === 'owner'">
                                <v-tooltip top>
                                    <v-btn
                                            small
                                            flat
                                            icon
                                            class="ma-0"
                                            color="red"
                                            slot="activator"
                                            @click="$emit('delete', report)"
                                    >
                                        <v-icon small>delete</v-icon>
                                    </v-btn>
                                    <span>Delete Report</span>
                                </v-tooltip>
                            </v-flex>
                        </v-layout>
                        <v-divider></v-divider>
                    </div>
                </v-flex>
            </v-layout>
        </v-card>

        <app-input-dlg
                :show-dialog="showAddReport"
                title="New Report"
                :cancel-button="true"
                :show-input-box="false"
                @dismiss="showAddReport = false"
                :body-padding="false"
        >
            <template slot="body">
                <div class="pa-2">
                    <div :class="headingClass">
                        Report Description:
                    </div>
                    <div :class="itemClass">
                        <v-text-field
                                label="Report Description"
                                single-line
                                v-model="addReportDescription"
                                color="primary"
                                hide-details
                                style="width: 100%"
                                class="ma-0 pa-0"
                                clearable
                                ref="addReportDescriptionField"
                        ></v-text-field>
                    </div>
                </div>
                <div class="pa-2">
                    <div :class="headingClass">
                        Select Workflow:
                    </div>
                    <div :class="itemClass">
                        <div v-if="addReportWorkflow == null">
                            <v-btn small flat color="secondary" class="ma-0" @click="showSelectWorkflow = true">
                                Select Workflow
                            </v-btn>
                        </div>
                        <v-card ripple hover flat v-else class="primary white--text">
                            <v-layout row align-center style="cursor: pointer" v-if="addReportWorkflow != null" @click="showSelectWorkflow = true">
                                <v-flex xs12 class="pa-1 pl-2 text-truncate" style="overflow-x: hidden;">
                                    {{addReportWorkflow.description}}
                                </v-flex>
                                <v-flex shrink class="pr-1" @click="showSelectWorkflow = true">
                                    <v-tooltip top>
                                        <v-btn
                                                small
                                                flat
                                                icon
                                                color="white"
                                                class="pa-0 ma-0"
                                                slot="activator"
                                        >
                                            <v-icon small>edit</v-icon>
                                        </v-btn>
                                        <span>Change Workflow</span>
                                    </v-tooltip>
                                </v-flex>
                            </v-layout>
                        </v-card>
                        <app-input-dlg
                                :show-dialog="showSelectWorkflow"
                                title="Select Workflow"
                                :body-padding="false"
                                :cancel-button="true"
                                :show-input-box="false"
                                :buttons="[]"
                                @dismiss="showSelectWorkflow = false"
                        >
                            <template slot="body">
                                <div class="pa-1" v-if="showSelectWorkflow">
                                    <app-workflow-select
                                            :workflows="workflows"
                                            v-model="addReportWorkflow"
                                            @input="showSelectWorkflow = false"
                                            :max-height="400"
                                    ></app-workflow-select>
                                </div>
                            </template>
                        </app-input-dlg>
                    </div>
                </div>

            </template>
            <template slot="actions">
                <v-btn
                        small
                        class="ma-0"
                        color="secondary"
                        @click="addNewReport"
                        :disabled="blankString(addReportDescription) || addReportWorkflow == null || addingReport"
                        :loading="addingReport"
                >
                    <v-icon left>add</v-icon>
                    Add Report
                </v-btn>
            </template>
        </app-input-dlg>

        <app-input-dlg
                v-if="duplicateReport != null"
                :show-dialog="duplicateReport != null"
                title="Duplicate Report"
                body="Duplicate Report Description:"
                :default-input="duplicateReport.description + ' (Copy)'"
                :cancel-button="true"
                :show-input-box="true"
                :buttons="['Duplicate Report']"
                :call-backs="[ duplicateReportRun ]"
                @dismiss="duplicateReport = null"
        />
    </div>
</template>

<script>
    import {codeBaseMixin} from "../../codeBaseMixin";
    import InputDlg from "../../components/General/InputDlg";
    import WorkflowSelect from "../../Admin/WorkflowEditor/Components/WorkflowManager/Workflow/Components/FieldTypeConfigureFields/FieldType10Components/WorkflowSelect";

    export default {
        components: {
            appWorkflowSelect: WorkflowSelect,
            appInputDlg: InputDlg
        },
        mixins: [codeBaseMixin],
        props: {
            value: Object,
            reportFilter: String,
            userInfo: Object,
            reports: Array,
            showSelectReport: Boolean,
            workflows: Array,
        },
        data() {
            return {
                showAddReport: false,
                addingReport: false,
                addReportDescription: null,
                addReportWorkflow: null,
                showSelectWorkflow: false,
                selectedReportTypes: 'My Reports',
                duplicateReport: null
            }
        },
        methods: {
            duplicateReportRun(description) {
                if (this.blankString(description)) {
                    this.showSnack('Error', 'A description is required.', 'Close', 'red')
                    return null
                }
                if (description === this.duplicateReport.description) {
                    this.showSnack('Error', 'Description can not be the same as selected report.', 'Close', 'red')
                    return null
                }

                this.$emit('duplicate', {reportID: this.duplicateReport.id, description: description});
                this.duplicateReport = null;
                this.$nextTick(() => {
                    this.showSnack('Info', 'Please wait while report is being duplicated', 'Close', 'primary')
                    this.selectedReportTypes = 'All Reports'
                })
            },

            addNewReport() {
                    this.showAddReport = false;
                    this.addingReport = true;
                    this.fetch({method: 'POST', url:`/wfr/report/${this.userInfo.entityID}/${this.userInfo.userID}`, body: JSON.stringify({description: this.addReportDescription, workflow: this.addReportWorkflow.id})})
                        .then((data) => {
                            this.$emit('reportAdded', {...data, role: 'owner'});
                            this.addingReport = false;
                        })
                        .catch((e) => {
                            // eslint-disable-next-line
                            console.log(e)
                            this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                            this.addingReport = false
                        })
            }
        },
        computed: {

            reportSwitch: {
                get() {
                    return this.selectedReportTypes === 'All Reports'
                },
                set(val) {
                    if (val) {
                        this.selectedReportTypes = 'All Reports'
                    } else {
                        this.selectedReportTypes = 'My Reports'
                    }
                }
            },
            searchedReports() {
                return !Array.isArray(this.reports)
                    ? []
                    : this.reports
                        .filter(report => this.blankString(this.reportFilter) || (!this.blankString(this.reportFilter) && report.description.toLowerCase().includes(this.reportFilter.toLowerCase())))
                        .sort((a,b) => a['description'] < b['description'] ? -1 : 1);
            },

            filteredReports() {
                return this.selectedReportTypes === 'All Reports'
                    ? this.searchedReports
                    : this.searchedReports.filter(obj => this.selectedReportTypes === 'Shared With Me' ? obj.creator !== this.userInfo.userID : obj.creator === this.userInfo.userID)
            },
            selectedReport() {
                return this.value != null ? this.value : {id: -1, description: null}
            },
        },
        watch: {
            showAddReport(shown) {
                if (!shown) {
                    this.addReportDescription = null;
                    this.addReportWorkflow = null;
                    this.showSelectWorkflow = false;
                } else {
                    this.$nextTick(() => {
                        this.$refs.addReportDescriptionField.focus();
                    })
                }
            },
            showSelectReport: {
                immediate: true,
                handler(shown) {
                    if (shown) {
                        this.$nextTick(() => {
                            this.$refs.filterField.focus();
                        })
                    }
                }
            },
        }
    }
</script>

<style scoped>

</style>
