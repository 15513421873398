<template>
    <v-card class="fill-height scrollable">
        <v-layout row fill-height class="scrollable">
            <v-flex xs4 class="fill-height scrollable" style="border-right: 1px solid #00897b">
                <v-layout column fill-height class="scrollable ">
                    <v-flex shrink class="body-1 primary white--text pa-2">
                        Folder Groups
                    </v-flex>
                    <v-flex shrink class="caption greyType--text font-weight-bold pl-2 pt-1">
                        Add Folder Group:
                    </v-flex>
                    <v-flex shrink class="px-4 py-1 pb-2">
                        <v-layout row>
                            <v-flex xs6 class="">
                                <app-create-folder-group
                                        :userInfo="userInfo"
                                        :selectedFolder="selectedFolder"
                                        :userGroups="userGroups"
                                        @folderGroupAdded="addFolderGroup($event)"
                                >
                                    <v-btn
                                            small
                                            color="secondary"
                                            flat
                                            block
                                            outline
                                            class="pa-0 ma-0"
                                            slot="activator"
                                    >
                                        New
                                    </v-btn>
                                </app-create-folder-group>

                            </v-flex>
                            <v-flex xs6 class="pl-2">
                                <app-add-existing-folder-group
                                        :userInfo="userInfo"
                                        :selectedFolder="selectedFolder"
                                        :folder-groups="availableFullFolderGroups"
                                        @folderGroupAdded="addExistingFolderGroup($event)"
                                >
                                    <v-btn
                                            small
                                            color="secondary"
                                            flat
                                            block
                                            outline
                                            class="pa-0 ma-0"
                                            slot="activator"
                                    >
                                        Existing
                                    </v-btn>
                                </app-add-existing-folder-group>
                            </v-flex>
                        </v-layout>

                    </v-flex>
                    <v-flex shrink>
                        <v-divider></v-divider>
                    </v-flex>
                    <v-flex shrink class="px-2 pb-1 pt-2" v-if="filteredSelectedFolderGroups.length >= 2 || !blankString(folderGroupFilter)">
                        <v-text-field
                                label="Filter Folder Groups"
                                flat
                                single-line
                                v-model="folderGroupFilter"
                                color="primary"
                                hide-details
                                style="width: 100%;"
                                class="ma-0 pa-0"
                                prepend-inner-icon="search"
                                lazy
                                clearable
                        />
                    </v-flex>
                    <v-flex xs12 class="scrollable fill-height fill-height" style="overflow-x: hidden">
                        <div v-for="item in filteredSelectedFolderGroups" :key="item.id" >
                            <div
                                    :class="selectedFolderGroup != null && selectedFolderGroup.id == item.id ? 'selected-item' : 'hover-item'"
                                    :style="disableFolderGroupClicks ? 'cursor: progress' : 'cursor: pointer'"
                            >
                                <v-layout row align-center style="overflow-x: hidden" class="hide-parent">
                                    <v-flex
                                            shrink
                                            v-if="selectedFolderGroup != null && selectedFolderGroup.id == item.id && (renamingGroup || removingGroup)"
                                            class="pl-2 py-1"
                                    >
                                        <v-progress-circular
                                                :size="20"
                                                :width="2"
                                                color="primary"
                                                indeterminate
                                        />
                                    </v-flex>
                                    <v-flex xs12 style="overflow-x: hidden" class="pa-2" @click="!disableFolderGroupClicks ? selectedFolderGroup = item : ''">
                                        <div class="body-1">
                                            {{item.description}}
                                        </div>
                                        <div class="caption text-truncate" :class="Array.isArray(item.members) && item.members.length >= 1 ? 'greyType--text' : 'red--text'" style="overflow-x: hidden">
                                            {{Array.isArray(item.members) && item.members.length >= 1 ? item.members.map(obj => {return obj.description}).join(', ') : 'NO USER GROUPS ASSIGNED'}}
                                        </div>
                                    </v-flex>
                                    <v-flex shrink class="pr-1 hide-child">
                                        <v-tooltip right>
                                            <v-btn
                                                    small
                                                    flat
                                                    icon
                                                    color="primary"
                                                    style="width: 18px; height: 18px"
                                                    class="pa-0 ma-0"
                                                    @click="!disableFolderGroupClicks ? renameFolderGroupRunStart(item) : ''"
                                                    slot="activator"
                                            >
                                                <v-icon small>edit</v-icon>
                                            </v-btn>
                                            <span>Rename Folder Group</span>
                                        </v-tooltip>
                                    </v-flex>
                                    <v-flex shrink class="pr-2 hide-child">
                                        <div>
                                            <v-tooltip right>
                                                <v-btn
                                                        small
                                                        flat
                                                        icon
                                                        color="red"
                                                        style="width: 18px; height: 18px"
                                                        class="pa-0 ma-0"
                                                        slot="activator"
                                                        @click="!disableFolderGroupClicks ? removeFolderGroup(item) : ''"
                                                >
                                                    <v-icon small>remove</v-icon>
                                                </v-btn>
                                                <span>Remove Folder Group</span>
                                            </v-tooltip>
                                        </div>
<!--                                        <div>-->
<!--                                            <v-tooltip right>-->
<!--                                                <v-btn-->
<!--                                                        small-->
<!--                                                        flat-->
<!--                                                        icon-->
<!--                                                        color="red"-->
<!--                                                        style="width: 18px; height: 18px"-->
<!--                                                        class="pa-0 ma-0"-->
<!--                                                        @click="!disableFolderGroupClicks ? deleteConfirmRunStart(item) : ''"-->
<!--                                                        slot="activator"-->
<!--                                                >-->
<!--                                                    <v-icon small>delete</v-icon>-->
<!--                                                </v-btn>-->
<!--                                                <span>Delete Folder Group</span>-->
<!--                                            </v-tooltip>-->
<!--                                        </div>-->
                                    </v-flex>
                                </v-layout>
                                <v-divider></v-divider>
                            </div>
                        </div>
                    </v-flex>
                </v-layout>
            </v-flex>
            <v-flex shrink><v-divider vertical></v-divider></v-flex>
<!--User Groups-->
            <v-flex xs4 class="fill-height scrollable" v-if="selectedFolderGroup != null" style="border-right: 1px solid #00897b">
                <v-layout column fill-height class="scrollable">
                    <v-flex shrink class="body-1 primary white--text pa-2">
                        User Groups
                    </v-flex>
                    <v-flex shrink class="caption greyType--text font-weight-bold pl-2 pt-1">
                        Add User Groups:
                    </v-flex>
                    <v-flex shrink class="px-4 py-1 pb-2">
                        <v-layout row>
                            <v-flex xs12 v-if="userInfo.userManager == '1'">
                                <app-create-user-group
                                        :userInfo="userInfo"
                                        :selected-folder-group="selectedFolderGroup"
                                        :users="users"
                                        @userGroupAdded="addNewUserGroup($event)"
                                >
                                    <v-btn
                                            small
                                            color="secondary"
                                            flat
                                            block
                                            outline
                                            class="pa-0 ma-0"
                                            slot="activator"
                                    >
                                        New
                                    </v-btn>
                                </app-create-user-group>
                            </v-flex>
                            <v-flex xs12 class="pl-2">
                                <app-add-existing-user-group
                                        :userInfo="userInfo"
                                        :selected-folder-group="selectedFolderGroup"
                                        :userGroups="availableUserGroups"
                                        @userGroupAdded="addExistingUserGroup($event)"
                                >

                                    <v-btn
                                            small
                                            color="secondary"
                                            flat
                                            block
                                            outline
                                            class="pa-0 ma-0"
                                            slot="activator"
                                    >
                                        Existing
                                    </v-btn>
                                </app-add-existing-user-group>

                            </v-flex>
                        </v-layout>
                    </v-flex>
                    <v-flex shrink>
                        <v-divider></v-divider>
                    </v-flex>
                    <v-flex shrink class="px-2 pb-1 pt-2" v-if="Array.isArray(selectedFolderGroup.members) && (selectedFolderGroup.members.length >= 2 || !blankString(userGroupsFilter))">
                        <v-text-field
                                label="Filter User Groups"
                                flat
                                single-line
                                v-model="userGroupsFilter"
                                color="primary"
                                hide-details
                                style="width: 100%;"
                                class="ma-0 pa-0"
                                prepend-inner-icon="search"
                                lazy
                                clearable
                        />
                    </v-flex>
                    <v-flex xs12 class="scrollable fill-height fill-height" style="overflow-x: hidden">
                        <div v-for="item in filteredSelectedUserGroups" :key="item.id" >
                            <div
                                    :class="selectedUserGroup != null && selectedUserGroup.id == item.id ? 'selected-item' : 'hover-item'"
                                    :style="disableFolderGroupClicks || disableUserGroupClicks ? 'cursor: progress' : 'cursor: pointer'"
                            >
                                <v-layout row align-center style="overflow-x: hidden" class="hide-parent">
                                    <v-flex
                                            shrink
                                            v-if="selectedUserGroup != null && selectedUserGroup.id == item.id && (renamingUserGroup || removingUserGroup)"
                                            class="pl-2 py-1"
                                    >
                                        <v-progress-circular
                                                :size="20"
                                                :width="2"
                                                color="primary"
                                                indeterminate
                                        />
                                    </v-flex>
                                    <v-flex xs12 style="overflow-x: hidden" class="pa-2" @click="!disableFolderGroupClicks && !disableUserGroupClicks ? selectedUserGroup = item : ''">
                                        <div class="body-1">
                                            {{item.description}}
                                        </div>
                                        <div class="caption text-truncate" :class="Array.isArray(item.members) && item.members.length > 0 ? 'greyType--text' : 'red--text'" style="overflow-x: hidden">
                                            {{Array.isArray(item.members) && item.members.length > 0 ? item.members.map(obj => {return typeof obj != 'undefined' && obj != null ? obj.description : 'ERROR'}).join(', ') : 'NO USER ASSIGNED'}}
                                        </div>
                                    </v-flex>
                                    <v-flex shrink class="pr-2 hide-child">
                                        <div>
                                            <v-tooltip right>
                                                <v-btn
                                                        small
                                                        flat
                                                        icon
                                                        color="red"
                                                        style="width: 18px; height: 18px"
                                                        class="pa-0 ma-0"
                                                        slot="activator"
                                                        @click="!disableFolderGroupClicks && !disableUserGroupClicks ? removeUserGroup(item) : ''"
                                                >
                                                    <v-icon small>remove</v-icon>
                                                </v-btn>
                                                <span>Remove User Group</span>
                                            </v-tooltip>
                                        </div>
                                        <div>
                                            <v-tooltip right>
                                                <v-btn
                                                        small
                                                        flat
                                                        icon
                                                        color="primary"
                                                        style="width: 18px; height: 18px"
                                                        class="pa-0 ma-0"
                                                        @click="!disableFolderGroupClicks && !disableUserGroupClicks ? renameUserGroupRunStart(item) : ''"
                                                        slot="activator"
                                                >
                                                    <v-icon small>edit</v-icon>
                                                </v-btn>
                                                <span>Rename User Group</span>
                                            </v-tooltip>
                                        </div>
                                    </v-flex>
                                </v-layout>
                                <v-divider></v-divider>
                            </div>
                        </div>
                    </v-flex>
                </v-layout>
            </v-flex>
                    <v-flex shrink v-if="selectedFolderGroup != null"><v-divider vertical></v-divider></v-flex>
            <v-flex xs4 class="fill-height scrollable" v-if="selectedUserGroup != null">
                <v-layout column fill-height class="scrollable">
                    <v-flex shrink class="body-1 primary white--text pa-2 ">
                        Users
                    </v-flex>
                    <v-flex shrink class="caption greyType--text font-weight-bold pl-2 pt-1">
                        Add User to Group:
                    </v-flex>
                    <v-flex shrink class="px-4 py-1 pb-2">
                        <app-add-user
                                :userInfo="userInfo"
                                :selected-user-group="selectedUserGroup"
                                :users="availableUsers"
                                @userAdded="addExistingUser($event)"
                                :disabled="userInfo.userManager == '0'"
                        >
                            <v-btn
                                    small
                                    color="secondary"
                                    flat
                                    block
                                    outline
                                    class="pa-0 ma-0"
                                    slot="activator"
                                    :disabled="userInfo.userManager == '0'"
                            >
                                Add User
                            </v-btn>
                        </app-add-user>
                    </v-flex>
                    <v-flex shrink>
                        <v-divider></v-divider>
                    </v-flex>
                    <v-flex shrink class="px-2 pb-1 pt-2" v-if="Array.isArray(selectedUserGroup.members) && (selectedUserGroup.members.length >= 2 || !blankString(usersFilter))">
                        <v-text-field
                                label="Filter Users"
                                flat
                                single-line
                                v-model="usersFilter"
                                color="primary"
                                hide-details
                                style="width: 100%;"
                                class="ma-0 pa-0"
                                prepend-inner-icon="search"
                                lazy
                                clearable
                        />
                    </v-flex>
                    <v-flex xs12 class="scrollable fill-height fill-height" style="overflow-x: hidden">
                        <div v-for="(item, n) in filteredSelectedUsers" :key="n" >
                            <div
                                    v-if="typeof item != 'undefined'"
                                    :class="selectedUser != null && selectedUser.id == item.id ? 'selected-item' : 'hover-item'"
                                    :style="disableFolderGroupClicks || disableUserGroupClicks || disableUserClicks ? 'cursor: progress' : 'cursor: pointer'"
                            >
                                <v-layout row align-center style="overflow-x: hidden" class="hide-parent">
                                    <v-flex
                                            shrink
                                            v-if="selectedUser != null && selectedUser.id == item.id && removingUser"
                                            class="pl-2 py-1"
                                    >
                                        <v-progress-circular
                                                :size="20"
                                                :width="2"
                                                color="primary"
                                                indeterminate
                                        />
                                    </v-flex>
                                    <v-flex xs12 style="overflow-x: hidden" class="pa-2" @click="!disableFolderGroupClicks && !disableUserGroupClicks && !disableUserClicks ? selectedUser = item : ''">
                                        <div class="body-1" :class="selectedUser != null && selectedUser.id == item.id ? '' : (intToBool(item.disabled) ? 'greyType--text' : '')">
                                            {{`${item.description}${intToBool(item.disabled) ? ' (Disabled)' : ''}`}}
                                        </div>
                                        <div class="caption text-truncate greyType--text"  style="overflow-x: hidden">
                                            {{item.username}}
                                        </div>
                                    </v-flex>
                                    <v-flex shrink class="pr-2 hide-child" v-if="userInfo.userManager == '1'">
                                        <v-tooltip left>
                                            <v-btn
                                                    small
                                                    flat
                                                    icon
                                                    color="red"
                                                    style="width: 18px; height: 18px"
                                                    class="pa-0 ma-0"
                                                    slot="activator"
                                                    @click="!disableFolderGroupClicks && !disableUserGroupClicks && !disableUserClicks? removeUser(item) : ''"
                                            >
                                                <v-icon small>remove</v-icon>
                                            </v-btn>
                                            <span>Remove User from Group</span>
                                        </v-tooltip>
                                    </v-flex>
                                </v-layout>
                                <v-divider></v-divider>
                            </div>
                        </div>
                    </v-flex>
                </v-layout>
            </v-flex>

<!--Rename Folder Group-->
            <app-input-dlg
                    v-if="showRenameFolderGroup"
                    :show-dialog="showRenameFolderGroup"
                    title="Rename Folder Group"
                    body="New Description:"
                    :cancel-button="true"
                    :show-input-box="true"
                    :default-input="selectedFolderGroup.description"
                    :buttons="['Rename']"
                    :call-backs="[ renameFolder ]"
                    @dismiss="showRenameFolderGroup = false"
            />
<!--Delete Folder Group-->
            <app-input-dlg
                    v-if="showConfirmDelete"
                    :show-dialog="showConfirmDelete"
                    title="Delete Folder Group"
                    :body="`Are you sure that you would like to delete '${selectedFolderGroup.description}' this action can not be undone. It will delete the Folder Group from other Folders as well.`"
                    :cancel-button="true"
                    :show-input-box="false"
                    :buttons="['Delete', 'Cancel']"
                    :call-backs="[ deleteFolderGroup, cancelDeleteFolderGroup ]"
                    @dismiss="showConfirmDelete = false"
            />
<!--Rename User Group-->
            <app-input-dlg
                    v-if="showRenameUserGroup"
                    :show-dialog="showRenameUserGroup"
                    title="Rename User Group"
                    body="New Description:"
                    :cancel-button="true"
                    :show-input-box="true"
                    :default-input="selectedUserGroup.description"
                    :buttons="['Rename']"
                    :call-backs="[ renameUserGroup ]"
                    @dismiss="showRenameUserGroup = false"
            />
        </v-layout>
    </v-card>
</template>

<script>
    import {codeBaseMixin} from "../../../codeBaseMixin";
    import CreateFolderGroup from "./CreateFolderGroup";
    import AddExistingFolderGroup from "./AddExistingFolderGroup";
    import InputDlg from "../../../components/General/InputDlg";
    import AddExistingUserGroup from "./AddExistingUserGroup";
    import AddUser from "./AddUser";
    import CreateUserGroup from "./CreateUserGroup";

    export default {
        mixins: [codeBaseMixin],
        components: {
            appInputDlg: InputDlg,
            appCreateFolderGroup: CreateFolderGroup,
            appAddExistingFolderGroup: AddExistingFolderGroup,
            appAddExistingUserGroup: AddExistingUserGroup,
            appAddUser: AddUser,
            appCreateUserGroup: CreateUserGroup,
        },
        props: {
            userInfo: Object,
            selectedFolder: Object,
            folderViewOption: Number
        },

        data() {
            return {
                loading: false,
                selectedGroups: [],
                allGroups: [],
                userGroups: [],
                users: [],
                folderGroupFilter: '',
                selectedFolderGroup: null,
                renamingGroup: false,
                showRenameFolderGroup: false,
                removingGroup: false,
                showConfirmDelete: false,
                userGroupsFilter: null,
                selectedUserGroup: null,
                renamingUserGroup: false,
                removingUserGroup: false,
                showRenameUserGroup: false,
                usersFilter: null,
                selectedUser: null,
                removingUser: false
            }
        },

        watch: {
            selectedFolder: {
                immediate: true,
                handler(newVal, oldVal) {
                    if (typeof oldVal === 'undefined' || oldVal == null || oldVal.id != newVal.id) {
                        this.securityStartup();
                    }
                }
            },
            selectedFolderGroup() {
                this.selectedUserGroup = null;
                this.userGroupsFilter = null;
            },
            selectedUserGroup() {
                this.usersFilter = null;
                this.selectedUser = null;
            }
        },

        methods: {
            addExistingUser(user) {
                this.userGroups.forEach(UGroup => {
                    if (UGroup.id == this.selectedUserGroup.id) {
                        UGroup.members.push(user.id);
                    }
                });

                let selectedUserGroupID = this.selectedUserGroup.id;
                this.selectedFolderGroup = this.filteredSelectedFolderGroups.filter(obj => obj.id == this.selectedFolderGroup.id).pop();
                this.$nextTick(() => {
                    this.selectedUserGroup = this.filteredSelectedUserGroups.filter(obj => obj.id == selectedUserGroupID).pop();
                    this.$nextTick(() => {
                        this.selectedUser = user;
                    })
                });


                // this.allGroups.forEach(WFGroup => {
                //     if (WFGroup.id == this.selectedFolderGroup.id) {
                //         WFGroup.members.push(group.id)
                //     }
                // });
                //
                // this.selectedFolderGroup = this.filteredSelectedFolderGroups.filter(obj => obj.id == this.selectedFolderGroup.id).pop();
                //
                // this.$nextTick(() => {
                //     this.selectedUserGroup = this.filteredSelectedUserGroups.filter(obj => obj.id == group.id).pop();
                // });
            },
            removeUser(user) {
                this.selectedUser = user;
                this.removingUser = true;
                this.fetch({method: 'DELETE', url:`/folderManager/security/user/${this.userInfo.entityID}/${this.selectedUserGroup.id}/${this.selectedUser.id}`})
                    .then(() => {
                        this.userGroups.forEach(UGroup => {
                            if (UGroup.id == this.selectedUserGroup.id) {
                                UGroup.members = UGroup.members.filter(obj => obj != this.selectedUser.id);
                            }
                        });
                        let selectedUserGroupID = this.selectedUserGroup.id;
                        this.selectedFolderGroup = this.filteredSelectedFolderGroups.filter(obj => obj.id == this.selectedFolderGroup.id).pop();
                        this.$nextTick(() => {
                            this.selectedUserGroup = this.filteredSelectedUserGroups.filter(obj => obj.id == selectedUserGroupID).pop();
                        });
                        this.selectedUser = null;
                        this.removingUser = false;
                        this.showSnack('Info', 'User Removed', 'Close', 'primary')
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e);
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
                        this.removingUser = false;
                    })
            },
            addExistingUserGroup(group) {

                if (!Array.isArray(this.userGroups)) {

                    this.userGroups = []
                }

                this.allGroups.forEach(FGroup => {
                    if (FGroup.id == this.selectedFolderGroup.id) {
                        if (!Array.isArray(FGroup.members)) {FGroup.members = []}
                        FGroup.members.push(group.id)
                    }
                });

                this.selectedFolderGroup = this.filteredSelectedFolderGroups.filter(obj => obj.id == this.selectedFolderGroup.id).pop();

                this.$nextTick(() => {
                    this.selectedUserGroup = this.filteredSelectedUserGroups.filter(obj => obj.id == group.id).pop();
                });
            },
            addNewUserGroup(group) {
                if (!Array.isArray(this.userGroups)) {
                    this.userGroups = []
                }

                this.userGroups.push(group);

                this.allGroups.forEach(FGroup => {
                    if (FGroup.id == this.selectedFolderGroup.id) {
                        if (!Array.isArray(FGroup.members)) {FGroup.members = []}
                        FGroup.members.push(group.id)
                    }
                });

                this.selectedFolderGroup = this.filteredSelectedFolderGroups.filter(obj => obj.id == this.selectedFolderGroup.id).pop();

                this.$nextTick(() => {
                    this.selectedUserGroup = this.filteredSelectedUserGroups.filter(obj => obj.id == group.id).pop();
                });
            },
            renameUserGroupRunStart(group) {
                this.selectedUserGroup = group;
                this.showRenameUserGroup = true
            },
            renameUserGroup(description) {
                if (this.blankString(description)) {
                    this.showSnack('Error', 'A description is required', 'Close', 'red')
                } else {
                    this.showRenameUserGroup = false;
                    this.renamingUserGroup = true;
                    this.fetch({method: 'PATCH', url:`/folderManager/security/userGroup/${this.userInfo.entityID}/${this.selectedUserGroup.id}`, body: JSON.stringify({description: description})})
                        .then(() => {
                            this.userGroups.forEach(userGroup => {
                                if (userGroup.id == this.selectedUserGroup.id) {
                                    userGroup.description = description
                                }
                            });
                            let selectedUserGroupID = this.selectedUserGroup.id;
                            this.selectedFolderGroup = this.filteredSelectedFolderGroups.filter(obj => obj.id == this.selectedFolderGroup.id).pop();
                            this.userGroups = this.userGroups.filter(() => true);
                            this.$nextTick(() => {
                                this.selectedUserGroup = this.filteredSelectedUserGroups.filter(obj => obj.id == selectedUserGroupID).pop();
                            });
                            this.renamingUserGroup = false;
                            this.showSnack('Info', 'Changes Saved', 'Close', 'primary')
                        })
                        .catch((e) => {
                            // eslint-disable-next-line
                            console.log(e);
                            this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
                            this.renamingUserGroup = false;
                        })
                }
            },
            removeUserGroup(group) {
                this.selectedUserGroup = group;
                this.removingUserGroup = true;
                this.fetch({method: 'DELETE', url:`/folderManager/security/userGroup/${this.userInfo.entityID}/${this.selectedFolderGroup.id}/${this.selectedUserGroup.id}`})
                    .then(() => {
                        this.allGroups.forEach(FGroup => {
                            if (FGroup.id == this.selectedFolderGroup.id) {
                                FGroup.members = FGroup.members.filter(obj => obj != this.selectedUserGroup.id);
                            }
                        });

                        this.selectedFolderGroup = this.filteredSelectedFolderGroups.filter(obj => obj.id == this.selectedFolderGroup.id).pop();

                        this.selectedUserGroup = null;
                        this.removingUserGroup = false;
                        this.showSnack('Info', 'Group Removed', 'Close', 'primary')
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e);
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
                        this.removingUserGroup = false;
                    })
            },
            addFolderGroup(group) {
                this.allGroups.push(group);
                if (!Array.isArray(this.selectedGroups)) {
                    this.selectedGroups = []
                }
                this.selectedGroups.push(group.id);
                this.$nextTick(() => {
                    this.selectedFolderGroup = this.filteredSelectedFolderGroups.filter(obj => obj.id == group.id).pop();
                });
                this.$emit('folderGroupAdded', group)
                this.$emit('securityUpdated', {folder: this.selectedFolder, folderGroups: this.selectedGroups.length >= 1 ? JSON.stringify(this.selectedGroups.map(obj => {return obj})) : null})
            },
            addExistingFolderGroup(group) {
                if (!Array.isArray(this.selectedGroups)) {
                    this.selectedGroups = []
                }
                this.selectedGroups.push(group.id);
                this.selectedFolderGroup = group;
                this.$emit('securityUpdated', {folder: this.selectedFolder, folderGroups: this.selectedGroups.length >= 1 ? JSON.stringify(this.selectedGroups.map(obj => {return obj})) : null})
            },
            securityStartup() {
                this.folderGroupFilter = '';
                this.selectedGroups = [];
                this.allGroups = [];
                this.userGroups = [];
                this.users = [];
                this.selectedFolderGroup = null;
                this.loading = true;
                this.fetch({method: 'GET', url:`/folderManager/security/startup/${this.userInfo.entityID}/${this.selectedFolder.id}`})
                    .then((data) => {

                        this.selectedGroups = data.selectedGroups;
                        this.allGroups = data.allGroups;
                        this.userGroups = data.userGroups;
                        this.users = data.users;
                        this.loading = false
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e);
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
                        this.loading = false
                    })
            },
            renameFolderGroupRunStart(folderGroup) {
                this.selectedFolderGroup = folderGroup;
                this.showRenameFolderGroup = true;
            },
            renameFolder(description) {
                if (this.blankString(description)) {
                    this.showSnack('Error', 'A description is required', 'Close', 'red')
                } else {
                    this.showRenameFolderGroup = false;
                    this.renamingGroup = true;
                    this.fetch({method: 'PATCH', url:`/folderManager/security/folderGroup/${this.userInfo.entityID}/${this.selectedFolderGroup.id}`, body: JSON.stringify({description: description})})
                        .then(() => {
                            this.allGroups.forEach(folderGroup => {
                                if (folderGroup.id == this.selectedFolderGroup.id) {
                                    folderGroup.description = description
                                }
                            });
                            this.renamingGroup = false;
                            this.showSnack('Info', 'Changes Saved', 'Close', 'primary')
                        })
                        .catch((e) => {
                            // eslint-disable-next-line
                            console.log(e);
                            this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
                            this.renamingGroup = false;
                        })
                }
            },
            removeFolderGroup(folderGroup) {
                this.selectedFolderGroup = folderGroup;
                this.removingGroup = true;
                this.fetch({method: 'DELETE', url:`/folderManager/security/folderGroup/${this.userInfo.entityID}/${this.selectedFolder.id}/${this.selectedFolderGroup.id}`})
                    .then(() => {
                        this.selectedGroups.forEach((folderGroupID, n) => {
                            if (folderGroupID == this.selectedFolderGroup.id) {
                                this.selectedGroups.splice(n, 1);
                            }
                        });
                        this.selectedFolderGroup = null;
                        this.removingGroup = false;
                        this.showSnack('Info', 'Group Removed', 'Close', 'primary')
                        this.$emit('securityUpdated', {folder: this.selectedFolder, folderGroups: this.selectedGroups.length >= 1 ? JSON.stringify(this.selectedGroups.map(obj => {return obj})) : null})
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e);
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
                        this.removingGroup = false;
                    })
            },
            deleteConfirmRunStart(folderGroup) {
                this.selectedFolderGroup = folderGroup;
                this.showConfirmDelete = true;
            },
            cancelDeleteFolderGroup() {
                this.showConfirmDelete = false;
            },
            deleteFolderGroup() {
                this.showConfirmDelete = false;
                this.removingGroup = true;
                this.fetch({method: 'DELETE', url:`/folderManager/security/folderGroup/remove/group/${this.userInfo.entityID}/${this.selectedFolderGroup.id}`})
                    .then(() => {
                        this.selectedGroups.forEach((folderGroupID, n) => {
                            if (folderGroupID == this.selectedFolderGroup.id) {
                                this.selectedGroups.splice(n, 1);
                            }
                        });
                        this.allGroups.forEach((folderGroup, n) => {
                            if (folderGroup.id == this.selectedFolderGroup.id) {
                                this.allGroups.splice(n, 1);
                            }
                        });
                        this.selectedFolderGroup = null;
                        this.removingGroup = false;
                        this.showSnack('Info', 'Group Deleted', 'Close', 'primary')
                        this.$emit('securityUpdated', {folder: this.selectedFolder, folderGroups: this.selectedGroups.length >= 1 ? JSON.stringify(this.selectedGroups.map(obj => {return obj})) : null})
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e);
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
                        this.removingGroup = false;
                    })
            }
        },

        computed: {
            disableUserClicks() {
                return this.renamingGroup || this.removingGroup || this.renamingUserGroup || this.removingUserGroup || this.removingUser ? true : false;
            },
            disableUserGroupClicks() {
                return this.renamingGroup || this.removingGroup || this.renamingUserGroup || this.removingUserGroup ? true : false;
            },
            disableFolderGroupClicks() {
                return this.renamingGroup || this.removingGroup ? true : false;
            },
            flatUsers() {
                try {
                    return this.users.reduce((obj, user) => {
                        obj[user.id] = user;
                        return obj
                    }, {})
                } catch (e) {
                    // eslint-disable-next-line
                    console.log(e)
                    return {}
                }
            },
            flatUserGroups() {
                try {
                    return this.userGroups.reduce((obj, group) => {
                        obj[group.id] = {
                            id: group.id,
                            description: group.description,
                            members: !Array.isArray(group.members)
                                ? null
                                : group.members.map((user) => {
                                    try {
                                        return this.flatUsers[user];
                                    } catch (e) {
                                        return null
                                    }
                                })
                        };
                        return obj
                    }, {})
                } catch (e) {
                    // eslint-disable-next-line
                    console.log(e)
                    return {}
                }
            },
            availableFullFolderGroups() {
                try {
                    return this.allGroups.filter(obj => Array.isArray(this.selectedGroups) ? !this.selectedGroups.includes(obj.id) : true).reduce((list, folderGroup) => {
                        let item = {
                            id: folderGroup.id,
                            description: folderGroup.description,
                            members: !Array.isArray(folderGroup.members)
                                ? null
                                : folderGroup.members.map((userGroup) => {
                                    try {
                                        return this.flatUserGroups[userGroup];
                                    } catch (e) {
                                        return null
                                    }
                                })
                        };
                        list.push(item);
                        return list;
                    }, [])
                } catch (e) {
                    // eslint-disable-next-line
                    console.log(e);
                    return null
                }
            },
            fullFolderGroups() {
                try {
                    return this.allGroups.reduce((list, folderGroup) => {
                        let item = {
                            id: folderGroup.id,
                            description: folderGroup.description,
                            members: !Array.isArray(folderGroup.members)
                                ? null
                                : folderGroup.members.map((userGroup) => {
                                    try {
                                        return this.flatUserGroups[userGroup];
                                    } catch (e) {
                                        return null
                                    }
                                })
                        };
                        list.push(item);
                        return list;
                    }, [])
                } catch (e) {
                    // eslint-disable-next-line
                    console.log(e);
                    return null
                }
            },
            filteredSelectedFolderGroups() {
                if (Array.isArray(this.selectedGroups)) {
                    return this.folderGroupFilter == null || this.folderGroupFilter == ''
                        ? this.fullFolderGroups.filter(obj => this.selectedGroups.includes(obj.id))
                            .sort((a,b) => a['description'] < b['description'] ? -1 : 1)
                        : this.fullFolderGroups.filter(obj => this.selectedGroups.includes(obj.id))
                            .reduce((list, folderFolderGroup) => {
                                if (
                                    [folderFolderGroup.description].concat(
                                        !Array.isArray(folderFolderGroup.members)
                                            ? null
                                            : folderFolderGroup.members.map(userGroup => {
                                                return [userGroup.description].concat(
                                                    userGroup.members.map(user => {
                                                        return typeof user != 'undefined' ? user.description : null
                                                    }).join(', ')
                                                ).join(', ')
                                            }).join(', ')
                                    ).join(', ').toLowerCase().includes(this.folderGroupFilter.toLowerCase())
                                ) {
                                    list.push(folderFolderGroup)
                                }
                                return list
                            }, [])
                            .sort((a,b) => a['description'] < b['description'] ? -1 : 1);
                } else {
                    return [];
                }

            },
            filteredSelectedUserGroups() {
                if (this.selectedFolderGroup != null && Array.isArray(this.selectedFolderGroup.members)) {
                    return this.blankString(this.userGroupsFilter)
                        ? this.selectedFolderGroup.members.filter(obj => true)
                            .sort((a,b) => a['description'] < b['description'] ? -1 : 1)

                        : this.selectedFolderGroup.members
                            .reduce((list, userGroup) => {
                                if (
                                    [userGroup.description].concat(
                                        userGroup.members.map(user => {
                                            return typeof user != 'undefined' ? user.description : null
                                        }).join(', ')
                                    ).join(', ')
                                    .toLowerCase().includes(this.userGroupsFilter.toLowerCase())
                                ) {
                                    list.push(userGroup)
                                }
                                return list
                            }, [])
                            .sort((a,b) => a['description'] < b['description'] ? -1 : 1);
                } else {
                    return [];
                }

            },
            availableUserGroups() {
                try {
                    let tExisting = Array.isArray(this.selectedFolderGroup.members) ? this.selectedFolderGroup.members.map(obj => {return obj.id}) : [];

                    return this.userGroups.filter(obj => !tExisting.includes(obj.id)).map(obj => {return this.flatUserGroups[obj.id]})
                } catch (e) {
                    // eslint-disable-next-line
                    console.log(e)
                  return []
                }
            },
            filteredSelectedUsers() {
                try {
                    if (this.selectedUserGroup != null) {
                        if (this.blankString(this.usersFilter)) {
                            return this.selectedUserGroup.members.filter(() => true)
                            .sort((a,b) => a['description'] < b['description'] ? -1 : 1);
                        } else {
                            return this.selectedUserGroup.members.filter((obj) => `${obj.username} ${obj.description}`.toLowerCase().includes(this.usersFilter.toLowerCase()))
                                .sort((a,b) => a['description'] < b['description'] ? -1 : 1);
                        }

                    } else {
                        return []
                    }

                } catch (e) {
                    // eslint-disable-next-line
                    console.log(e)
                    return []
                }
            },

            availableUsers() {
                try {
                    let tExisting = Array.isArray(this.selectedUserGroup.members) ? this.selectedUserGroup.members.map(obj => {return obj.id}) : [];

                    return this.users.filter(obj => !tExisting.includes(obj.id)).map(obj => {return this.flatUsers[obj.id]})
                } catch (e) {
                    // eslint-disable-next-line
                    console.log(e)
                  return []
                }
            },
        }

    }
</script>

<style scoped>

</style>
