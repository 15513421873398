<template>
    <v-card class="hover-item" :hover="true">
        <v-layout row align-center>
            <v-flex shrink class="pl-2 py-2">
                <v-progress-circular
                        :size="20"
                        :width="3"
                        color="primary"
                        :value="uploadStatus"
                        v-if="state === 0"
                />
                <v-icon color="green" v-if="state === 1">done</v-icon>
                <v-icon color="red" v-if="state === 2 || state === 3">close</v-icon>
            </v-flex>
            <v-flex class="px-2 py-1" xs12>
                <div class="body-2">
                    {{title}}
                </div>
                <div class="caption" :class="[2,3].includes(state) ? 'red--text' : 'greyType--text'">
                    {{subTitle}}
                </div>
            </v-flex>
            <v-flex shrink class="pr-2">
                <v-btn
                        small
                        icon
                        flat
                        color="primary"
                        class="ma-0"
                        v-if="state === 2"
                        @click="$emit('retry')"
                >
                    <v-icon>
                        refresh
                    </v-icon>
                </v-btn>
            </v-flex>
            <v-flex shrink class="pr-2">
                <v-btn
                        small
                        icon
                        flat
                        color="red"
                        class="ma-0"
                        v-if="state > 0"
                        @click="$emit('remove')"
                >
                    <v-icon>
                        close
                    </v-icon>
                </v-btn>
            </v-flex>
        </v-layout>
    </v-card>
</template>

<script>
    export default {
        props: {
            title: String,
            subTitle: String,
            state: Number, // 0 - Uploading, 1 - Success, 2 - Failed, 3 - Invalid
            uploadStatus: Number
        }
    }
</script>

<style scoped>

</style>
