<template>
    <v-layout row wrap class="pl-2">

        <v-flex xs12 style="overflow-y: auto; max-height: 500px; cursor: pointer" @click="!readOnly ? runEditProtocol() : ''">
            <div v-if="value.value != null && value.value != ''" v-html="value.value">

            </div>
            <div v-else style="border: 1px dashed lightgrey; border-radius: 3px" class="text-xs-center pa-1">
                <span>{{!readOnly ? 'Click To Edit' : 'Read Only'}}</span>
            </div>
        </v-flex>
        <v-flex xs12 v-if="value.value != null && value.value != ''">
            <v-divider class="grey lighten-1"></v-divider>
        </v-flex>

    </v-layout>
</template>

<script>
    import {codeBaseMixin} from "../../../codeBaseMixin";
    import {eventBus} from "../../../main";
    // let tinyEditor = require('@tinymce/tinymce-vue').default;

    export default {
        mixins:[codeBaseMixin],
        components: {
            // appEditor: tinyEditor,
        },
        props: {
            value: Object,
            readOnly: Boolean
        },
        created() {
            eventBus.$on('folderField13EditDone', this.eventBusEditDone);
        },
        beforeDestroy() {
            // destroy the vue listeners, etc
            eventBus.$off('folderField13EditDone', this.eventBusEditDone);
        },

        methods: {

            eventBusEditDone(event) {
                if (this.awaitingResponse) {
                    this.awaitingResponse = false;
                    if (event != null) {
                        this.value.value = event;
                        this.$emit('forceSave')
                    }
                }
            },

            runEditProtocol() {
                this.awaitingResponse = true;
                eventBus.$emit('folderField13Edit', this.value)
            },
            updateValue(newVal) {
                this.value.value = newVal
            },

            editDone() {
                this.value.value = this.modelText;
                this.showEdit = false;
            }
        },

        watch: {
            showEdit(shown) {
                if (shown) {
                    this.modelText = this.value.value;
                }
            }
        },

        data() {
            return {
                awaitingResponse: false,
                bindData: true,
                showEdit: false,
                tinyInitSettings: {
                    // plugins: 'print, autoresize, table',
                    plugins: 'print, table, imagetools, image, paste',
                    elementpath: false,

                    force_br_newlines : true,
                    force_p_newlines : false,
                    forced_root_block : '', // Needed for 3.x
                    menubar:false,
                    formats: {
                        // Changes the default format for the bold button to produce a span with style with font-width: bold
                        paragraph: { inline: 'span', styles: { 'font-weight': 'bold', 'background-color': 'orange' } }
                    },
                    toolbar: 'fontsizeselect bold italic forecolor backcolor',
                    removed_menuitems: 'newdocument',
                    visual_table_class: 'overide',
                    max_height: 500,
                    height: '100%',
                    resize: false,
                    browser_spellcheck: true,
                    // convert_urls : false,
                    relative_urls : false,
                    remove_script_host : false,
                    content_css : "/tinycontent.css",
                    paste_data_images: true,
                    paste_preprocess: function(plugin, args) {
                        args.content  = args.content.replace('<img', `<img style='max-width: 100%;' `)
                    }
                },
                modelText: ""
            }
        },
    }
</script>

<style scoped>

</style>
