<template>
    <v-chip
            style="cursor: pointer"
            small
            outline
            color="primary"
            @click="!loading ? $emit('input', true) : null"
    >
        <v-avatar class="primary">
            <template v-if="loading">
                <v-progress-circular
                        :size="18"
                        :width="2"
                        color="white"
                        indeterminate
                ></v-progress-circular>
            </template>
            <template v-else>
                <template v-if="['eml', 'pdf'].includes(ext)">
                    <v-icon v-if="ext === 'eml'" color="white" small>mail_outline</v-icon>
                    <v-icon v-else color="white" small>picture_as_pdf</v-icon>
                </template>
                <v-icon v-else color="white" small>attachment</v-icon>
            </template>
        </v-avatar>
        <span style="color: var(--on-surface)">{{ filename || 'attachment' }}</span>
    </v-chip>
</template>

<script>
export default {
    props: {
        filename: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            loading: false
        }
    },
    computed: {
        ext() {
            try {
                return this.filename.split('.').pop().toLowerCase()
            } catch(e) {
                return null
            }
        }
    }
}
</script>

<style scoped>

</style>