<template>
    <div class="" style="max-width: 100%">
<!--Event Date-->
        <div class="my-1 pr-1 c-d-flex c-align-end hover-item" v-if="!dense">
            <v-icon class="mx-2">play_arrow</v-icon>
            <div class="c-flex-grow-1">
                <div class="caption greyType--text">
                    Event Date
                </div>
                <div class="body-2">
                    {{event.startLongStr}}
                </div>
            </div>
        </div>
<!--Event Duration-->
        <div class="my-1 pr-1 c-d-flex c-align-end hover-item" v-if="event.duration && !dense">
            <v-icon class="mx-2">pause</v-icon>
            <div class="c-flex-grow-1">
                <div class="caption greyType--text">
                    Event Duration
                </div>
                <div class="body-2">
                    {{event.durationStr}}
                </div>
            </div>
        </div>
<!--Owner-->
        <div class="my-1 pr-1 c-d-flex c-align-end hover-item">
            <v-icon class="mx-2">person</v-icon>
            <div class="c-flex-grow-1">
                <div class="caption greyType--text">
                    Owner
                </div>
                <div class="c-d-flex c-align-center c-justify-space-between">
                    <div class="body-2">
                        {{event.ownerRef.fullName}}
                    </div>
                    <div class="greyType--text caption">
                        {{event.createdDateStr}}
                    </div>
                </div>
            </div>
        </div>
<!--Group Calendar-->
        <div class="my-1 pr-1 c-d-flex c-align-end hover-item" v-if="event.fkCalGroup">
            <v-icon class="mx-2">groups</v-icon>
            <div class="c-flex-grow-1">
                <div class="caption greyType--text">
                    Group Calendar
                </div>
                <div>
                    <dense-chip :label="event.groupCalendarRef.description" :color="event.groupCalendarRef.color || 'blue'" icon="groups"/>
                </div>
            </div>
        </div>
<!--Linked Items-->
        <div class="mt-1 pr-1 hover-item" v-if="event.fkFolder || event.fkTask">
            <div class="caption greyType--text" style="margin-left: 40px">
                Linked Items
            </div>
            <div class="c-d-flex c-align-center">
                <v-icon class="mx-2">link</v-icon>
                <div class="c-flex-grow-1" style="max-width: 100%; overflow-x: hidden">
<!--Main Link-->
                    <div class="pb-1 pr-1" v-if="event.fkTask ? showTask : true">
<!--Linked Task-->
                        <v-card v-if="event.fkTask" @click="taskRef !== null && taskRef.access ? gotoTask() : null">
                            <task-loading-container v-if="taskRef === null" :task-id="event.fkTask"/>
                            <task-container
                                    v-else
                                    :task="taskRef"
                                    :user-info="userInfo"
                            />
                        </v-card>
<!--Linked Folder-->
                        <v-card ripple v-if="event.fkFolder" class="c-d-flex align-center pl-2" hover>
                            <v-icon color="amber">folder</v-icon>
                            <div class="body-2 pa-2">
                                {{ event.folderRef.description }}
                            </div>
                        </v-card>
                    </div>
<!--Sub Link-->
                    <div class="py-1 pr-1" v-if="event.fkNote || event.fkDoc || event.fkMail || event.fkDataset">
<!--Task Links-->
                        <template v-if="event.fkTask && taskRef !== null">
<!--Note-->
                            <v-card v-if="event.fkNote">
                                <note-view-container
                                        :user-info="userInfo"
                                        :task="taskRef"
                                        :note="event.linkPayload"
                                />
                            </v-card>
<!--Doc-->
                            <v-card v-if="event.fkDoc">
                                <document-container :user-info="userInfo" :document="event.linkPayload"/>
                            </v-card>
<!--Mail-->
                            <v-card v-if="event.fkMail">
                                <general-mail-container
                                        :mail="event.linkPayload"
                                        :task="taskRef"
                                        :user-info="userInfo"
                                        :dashboard="false"
                                        :task-band="false"
                                />
                            </v-card>
                        </template>
<!--Folder Links-->
                        <template v-if="event.fkFolder && event.fkDataset">
<!--Dataset-->
                            <v-card class="c-d-flex align-center pl-2 " hover ripple>
                                <v-icon color="amber">content_copy</v-icon>
                                <div class="pa-1">
                                    <div class="" v-if="event.linkPayload.fieldSetDescription.length"
                                         style="line-height: 1">
                                        {{ event.linkPayload.fieldSetDescription.join(' ') }}
                                    </div>
                                    <div style="line-height: 1" class="caption">
                                        {{ event.linkPayload.description }}
                                    </div>
                                </div>
                            </v-card>
                        </template>
                    </div>
                </div>
            </div>
        </div>

<!--Event Notes-->
        <div class="mt-1 pr-1 hover-item" style="max-width: 100%; overflow-x: auto" v-if="event.body.length || event.editable">
            <div class="greyType--text caption" style="margin-left: 40px" >
                Event Notes
            </div>

            <div class="c-d-flex c-align-center" style="max-width: 100%;">
                <v-icon class="mx-2">note</v-icon>
                <div class="c-flex-grow-1" style="max-width: 100%; overflow-x: hidden;">
<!--Existing Notes-->
                    <div v-if="event.body.length" ref="notesContainer" style="overflow-x: auto">
                        <template v-for="(entry, i) in event.bodyRef">
                            <div :key="i" style="width: 100%; overflow-x: hidden" class="pa-1">
                                <v-card class="mb-1" style="width: 100%; overflow-x: hidden">

                                    <div class="caption font-weight-medium pl-1">
                                        {{entry.user.fullName}}
                                    </div>
                                    <div style="max-width: 100%;" class="shrink-images">
                                        <div class="caption pl-2 pr-1" v-html="entry.data" style="max-width: 100%"></div>
                                    </div>
                                    <div class="caption greyType--text text-xs-right pr-1">
                                        {{entry.date}}
                                    </div>

                                </v-card>
                            </div>

                        </template>
                    </div>
                    <template v-else>
                        <div class="body-2 greyType--text" style="line-height: 1" @click="showAddNote = true">
                            Add a Note to Event
                        </div>
                    </template>

                </div>
            </div>
        </div>


<!--Event Shares-->
        <div class="mt-1 pr-1 hover-item" v-if="event.sharesArr.length && !dense">
                <div class="caption greyType--text" style="margin-left: 40px">
                    Shared With
                </div>
                <div class="c-d-flex c-align-center">
                    <v-icon class="mx-2">share</v-icon>
                    <div>
                        <dense-chip
                                v-for="(share, i) in event.sharesArr"
                                :key="i"
                                :label="share.description"
                                :icon="share.icon"
                                :color="share.color"
                                class="mr-1 mb-1"
                        />
                    </div>
                </div>
        </div>
<!--Editors-->
        <div class="mt-1 pr-1 hover-item" v-if="event.editorsRef.length && !dense">
            <div class="caption greyType--text" style="margin-left: 40px">
                Editors
            </div>
            <div class="c-d-flex c-align-center">
                <v-icon class="mx-2">edit</v-icon>
                <div>
                    <dense-chip
                            v-for="(user, i) in event.editorsRef"
                            :key="i"
                            :label="user.fullName"
                            icon="person"
                            color="cyan"
                            class="mr-1 mb-1"
                    />
                </div>
            </div>
        </div>

        <calendar-event-add-note v-if="showAddNote" :shown="showAddNote" @dismiss="showAddNote = false" :add-note-c-b="addNoteToEvent" ref="addNoteEditor"/>

<!--Dashboard Notifications-->
        <div class="mt-1 pr-1 hover-item" v-if="!dense && (event.hasAccess || event.subscriptionsRef.length)">
            <div class="caption greyType--text" style="margin-left: 40px">
                Dashboard Notifications
            </div>
            <div class="c-d-flex c-align-center">
                <v-icon class="mx-2">notifications</v-icon>
                <div class="c-flex-grow-1">
                    <template v-if="!event.subscribed && event.hasAccess">
                        <div class="text-xs-center">
                            <v-btn
                                    small
                                    flat
                                    color="secondary"
                                    class="py-0 my-0 mb-1"
                                    :loading="subscribingToEvent"
                                    :disabled="subscribingToEvent"
                                    @click="subscribeToEvent()"
                            >
                                Add Dashboard Notification
                            </v-btn>
                        </div>
                    </template>

                    <calendar-event-detail-subscription
                            v-for="(sub, i) in event.subscriptionsRef"
                            :key="i"
                            :sub="sub"
                            :event="event"
                    />
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import TaskContainer from "@/components/TaskContainer/TaskContainer";
import NoteViewContainer from "@/Tasks/TaskView V2/components/Notes/NoteViewContainer";
import TaskLoadingContainer from "@/components/TaskContainer/TaskLoadingContainer";
import DenseChip from "@/components/General/DenseChip";
import DocumentContainer from "@/Tasks/TaskView V2/components/Documents/DocumentContainer";
import GeneralMailContainer from "@/components/General/generalMailContainer";
import CalendarEventAddNote from "@/components/General/Calendar/CalendarEventAddNote";
import CalendarEventDetailSubscription from "@/components/General/Calendar/CalendarEventDetailSubscription";
import {eventBus} from "@/main";

export default {
    components: {
        CalendarEventDetailSubscription,
        CalendarEventAddNote,
        GeneralMailContainer, DocumentContainer, DenseChip, TaskLoadingContainer, NoteViewContainer, TaskContainer},
    props: {
        event: Object,
        dense: {
            default: true,
            type: Boolean
        },
        showTask: {
            default: true,
            type: Boolean
        }
    },
    data() {
        return {
            taskRef: null,
            showAddNote: false,
            subscribingToEvent: false,
        }
    },
    watch: {
        event: {
            deep: true,
            immediate: true,
            handler(val) {
                if (val && this.event.fkTask) {
                    this.getTaskHeader(this.event.fkTask)
                            .then(task => {
                                this.taskRef = task
                            })
                            .catch(e => {
                                console.log(e)
                            })
                } else {
                    this.taskRef = null
                }
            }
        }
    },
    methods: {

        gotoTask() {
            console.log('GOTO Called')
            this.$emit('taskNav');
            eventBus.$emit('gotoTask', (this.taskRef));
        },

        subscribeToEvent() {
            this.subscribingToEvent = true;
            this.event.updateSubscription(false, (payload, e) => {
                if (e) {
                    this.$snack.networkError();
                    console.log(e)
                } else {
                    this.$snack.info('Changes Saved')
                }
                this.subscribingToEvent = false
            })
        },

        addNoteToEvent(data) {
                this.$refs.addNoteEditor.loading = true;
                this.event.addNote(data, (event, e) => {
                    if (e) {
                        console.log(e)
                        this.$snack.networkError();
                    } else {
                        console.log('Save Success Here', event)
                        this.showAddNote = false
                    }
                    this.$refs.addNoteEditor.loading = true;
                })


        },
    },
    computed: {
        ...mapGetters('userInfo', ['userInfo']),
        ...mapGetters(['getTaskHeader', 'foldersRef', 'usersRef']),
        hasTask() {
            return !!this.event.fkTask
        },
        hasFolder() {
            return !!this.event.fkFolder
        },
    },
}
</script>

<style scoped>

</style>