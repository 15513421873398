<template>
    <div>
        <v-btn small flat color="secondary" class="ma-0" @click="showDialog = true">Configure Information Field</v-btn>

        <v-dialog
                v-if="showDialog"
                v-model="showDialog"
                fullscreen
                hide-overlay
                transition="dialog-bottom-transition"
                persistent
        >
            <v-card flat height="100vh" v-if="showDialog">

                <v-layout column fill-height >
                    <!--Card Title-->
                    <v-flex shrink primary>
                        <v-card-title class="py-2 pr-2 ">

                            <v-icon class="white--text">info</v-icon>
                            <span class="title font-weight-light white--text pl-2">Information Field Editor</span>
                            <v-spacer></v-spacer>
                            <v-btn flat icon color="white" class="pa-0 ma-0" @click="showDialog = false">
                                <v-icon>close</v-icon>
                            </v-btn>
                        </v-card-title>
                    </v-flex>

                    <v-flex shrink class="pa-1">
                        <v-card class="c-d-flex c-flex-column fill-height">
                            <div class="primary body-2 pa-2 white--text">
                                Field Options
                            </div>

                            <div class="c-flex-grow-1 of-y-f c-d-flex">
                                <v-switch
                                        label="Display Collapsed By Default"
                                        v-model="defaultCollapsed"
                                        dense
                                        hide-details
                                        color="primary"
                                        class="pa-0 ma-2"
                                />
                                <v-switch
                                        label="Show Attached Documents"
                                        v-model="showDocs"
                                        dense
                                        hide-details
                                        color="primary"
                                        class="pa-0 ma-2"
                                />
                                <v-switch
                                        label="Show Field Body"
                                        v-model="showBody"
                                        dense
                                        hide-details
                                        color="primary"
                                        class="pa-0 ma-2"
                                />
                            </div>
                        </v-card>
                    </v-flex>

                    <v-flex xs12 class="scrollable">
                        <v-layout row fill-height>
                            <v-flex xs3 class="fill-height pa-1">
                                <v-card class="c-d-flex c-flex-column fill-height">
                                    <div class="primary body-2 pa-2 white--text">
                                        Attached Documents
                                    </div>

                                    <div class="pa-2">
                                        <v-btn
                                                color="secondary"
                                                small
                                                outline
                                                class="pa-0 ma-0"
                                                block
                                                @click="addDocument"
                                        >
                                            <v-icon left>add</v-icon>
                                            Add Document
                                        </v-btn>
                                    </div>

                                    <div class="c-flex-grow-1 of-y-f">
                                        <div v-for="(doc, i) in viewDocs" :key="i">
                                            <div class="c-d-flex align-center hover-item">
                                                <div class="c-flex-grow-1 c-d-flex align-center" @click="$newDownload(doc.document)">
                                                    <v-icon class="mx-2">file_present</v-icon>
                                                    <div class="c-flex-grow-1 pa-2">{{doc.description}}</div>
                                                </div>
                                                <v-tooltip left>
                                                    <template v-slot:activator="{on}">
                                                        <v-btn
                                                                small
                                                                color="red"
                                                                flat
                                                                class="pa-0 mx-2"
                                                                icon
                                                                @click="arrSplice(documents, docId => docId === doc.id)"
                                                                v-on="on"
                                                        >
                                                            <v-icon>delete</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    Remove Document
                                                </v-tooltip>
                                            </div>
                                            <v-divider></v-divider>
                                        </div>
                                    </div>
                                </v-card>
                            </v-flex>
                            <v-flex xs9 class="fill-height pa-1">

                                <v-card class="c-d-flex c-flex-column fill-height">
                                    <div class="primary body-2 pa-2 white--text">
                                        Field Body
                                    </div>

                                    <div class="c-flex-grow-1 of-y-f">

                                        <editor
                                                v-model="fieldBody"
                                                :extra-config="editorConfig"
                                        />


                                    </div>
                                </v-card>

                            </v-flex>
                        </v-layout>
                    </v-flex>

                    <div
                            style="position: absolute; bottom: 0; right: 0"
                            class="pa-2"
                    >
                        <v-btn
                                icon
                                large
                                color="secondary"
                                @click="() => {
                                    this.field.lookup = JSON.stringify({
                                        documents,
                                        showBody,
                                        showDocs,
                                        defaultCollapsed,
                                        fieldBody: this.stringifyHtml(fieldBody)
                                    });
                                    this.showDialog = false;
                                    this.$emit('input')
                                }"
                                :disabled="saving"
                                :loading="saving"
                        >
                            <v-icon>done</v-icon>
                        </v-btn>
                    </div>

                </v-layout>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {arrSplice, parseHtml, stringifyHtml} from "@/codeFunctions";
import Editor from "@/Editor/Editor";

export default {
    components: {Editor},
    props: {
        field: Object
    },
    data() {
        return {
            showDialog: false,
            viewOption: 0,
            saving: false,
            documents: null,
            showBody: true,
            showDocs: true,
            defaultCollapsed: false,
            fieldBody: null,
            arrSplice: arrSplice,
            parseHtml: parseHtml,
            stringifyHtml: stringifyHtml,
            editorConfig: {
                paste_data_images: true,
                remove_linebreaks : false,
                images_reuse_filename: true,
                visual: false,
                init_instance_callback: function(editor) {
                    editor.focus()
                },
            },
        }
    },
    watch: {
        showDialog: {
            immediate: true,
            handler(val) {
                function getRef(val) {
                    try {
                        return JSON.parse(val)
                    } catch (e) {
                        return JSON.parse(val.replace(/\\"/g, '"'))
                    }
                }
                if (val) {
                    let ref = getRef(this.field.lookup)
                    this.documents = (typeof ref === 'undefined' || ref === null || typeof ref.documents === 'undefined' || ref.documents === null) ? [] : ref.documents;
                    this.showBody = (typeof ref === 'undefined' || ref === null || typeof ref.showBody === 'undefined' || ref.showBody === null) ? true : ref.showBody;
                    this.showDocs = (typeof ref === 'undefined' || ref === null || typeof ref.showDocs === 'undefined' || ref.showDocs === null) ? true : ref.showDocs;
                    this.defaultCollapsed = (typeof ref === 'undefined' || ref === null || typeof ref.defaultCollapsed === 'undefined' || ref.defaultCollapsed === null) ? false : ref.defaultCollapsed;
                    this.fieldBody = (typeof ref === 'undefined' || ref === null || typeof ref.fieldBody === 'undefined' || ref.fieldBody === null) ? null : this.parseHtml(ref.fieldBody);
                }
            }
        }
    },
    methods: {
        addDocument() {
            this.$getRepoDocs({
                multiple: true,
                allowUserDocs: false
            })
                    .then((docs) => {
                        console.log(docs)
                        this.documents = this.documents.concat(docs.map(obj => obj.placeHolderID))
                    })
                    .catch(e => {
                        if (e) {
                            console.log(e)
                            this.$snack.error('An Error Has Occurred')
                        } else {
                            console.log('User Canceled')
                        }
                    })
        },

    },
    computed: {
        ...mapGetters('userInfo', ['userInfo']),
        ...mapGetters('repo', ['repoDocs']),
        ...mapGetters(['usersRef']),

        viewDocs() {
            return this.repoDocs.filter(doc => this.documents.includes(doc.id))
        },


    },
}
</script>

<style scoped>

</style>