const EventEmitter      = require('events');
let createMailEvents    = new EventEmitter();

function printMail(data) {
    createMailEvents.emit('printMail', data)
}

export {
    createMailEvents,
    printMail,
}