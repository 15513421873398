<template>
    <v-dialog
            v-model="showDialog"
            fullscreen
            persistent
    >

        <v-card class="fill-height" flat>
            <v-layout fill-height column>
                <!--Title Bar-->
                <v-flex shrink>
                    <v-card-title class="py-2 pr-2 primary">
                        <v-icon
                                left
                                color="white"
                        >
                            mail
                        </v-icon>
                        <span class="title font-weight-light white--text">Compose Mail</span>
                        <v-spacer></v-spacer>


                        <v-menu open-on-hover bottom offset-y v-if="typeof linkTask != 'undefined' && linkTask != null">
                            <v-btn
                                    icon
                                    flat
                                    color="white"
                                    class="pa-0 ma-0"
                                    @click="() => {}"
                                    slot="activator"
                            >
                                <img src="../../../public/tasks_lighten.svg" width="24px"/>
                            </v-btn>

                            <v-card width="400">
                                <task-container
                                        :userInfo="userInfo"
                                        :task="linkTask"
                                        :taskBar="false"
                                ></task-container>
                            </v-card>
                        </v-menu>

                        <v-btn flat icon color="white" class="pa-0 ma-0" @click="$emit('dismiss')">
                            <v-icon>close</v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-divider></v-divider>
                </v-flex>
                <!--From And Send Address-->
                <v-flex shrink>
                    <v-layout row align-center>
                        <v-flex xs6>
                            <v-autocomplete
                                    dense
                                    :items="fromAddresses"
                                    item-text="address"
                                    label="From:"
                                    hide-details
                                    solo
                                    flat
                                    v-model="selectedSMTPAddress"
                                    return-object
                            >
                                <template slot="prepend-inner">
                                    <div class="pr-2">
                                        <v-icon v-if="selectedSMTPAddress == null || typeof selectedSMTPAddress.type == 'undefined'">mail</v-icon>
                                        <template v-else>
                                            <img v-if="selectedSMTPAddress.type == 'gmail'" src="../../../public/logo_gmail_32px.svg" class="nav-item" height="22px">
                                            <img v-if="selectedSMTPAddress.type == 'office'" src="../../../public/outlook_32x1.svg" class="nav-item"  height="32px">
                                            <img v-if="selectedSMTPAddress.type == 'smtp'" src="../../../public/smtp.svg" class="nav-item"  height="32px">
                                        </template>
                                    </div>

                                </template>
                                <template slot="item" slot-scope="address">
                                    <v-layout row align-center class="hide-parent">
                                        <v-flex shrink class="pr-2">
                                            <img v-if="address.item.type == 'gmail'" src="../../../public/logo_gmail_32px.svg" class="nav-item" height="18px">
                                            <img v-if="address.item.type == 'office'" src="../../../public/outlook_32x1.svg" class="nav-item"  height="22px">
                                            <img v-if="address.item.type == 'smtp'" src="../../../public/smtp.svg" class="nav-item"  height="22px">
                                        </v-flex>
                                        <v-flex xs12 class="body-1">
                                            {{address.item.address}}
                                        </v-flex>

                                        <v-flex shrink v-if="defaultFrom.address == address.item.address && defaultFrom.type == address.item.type">
                                            <v-tooltip top>
                                                <v-btn small icon flat color="amber" slot="activator" class="pa-0 ma-0">
                                                    <v-icon>
                                                        star
                                                    </v-icon>
                                                </v-btn>
                                                <span>Default Send Address</span>
                                            </v-tooltip>
                                        </v-flex>

                                        <v-flex shrink v-else class="hide-child">
                                            <v-tooltip top>
                                                <v-btn small icon flat color="primary" slot="activator" class="pa-0 ma-0" @click="setDefaultSMTPAddress(address.item)">
                                                    <v-icon>
                                                        star_outline
                                                    </v-icon>
                                                </v-btn>
                                                <span>Mark Address As Default</span>
                                            </v-tooltip>
                                        </v-flex>

                                    </v-layout>
                                </template>
                            </v-autocomplete>
                        </v-flex>
                        <v-flex xs6>
                            <v-autocomplete
                                    dense
                                    :items="signatures"
                                    item-text="description"
                                    return-object
                                    label="Signature:"
                                    hide-details
                                    prepend-inner-icon="edit"
                                    solo
                                    flat
                                    v-model="selectedSignature"
                                    @change="signatureSwap($event)"
                            >
                            </v-autocomplete>
                        </v-flex>
                    </v-layout>
                </v-flex>
                <v-flex shrink><v-divider></v-divider></v-flex>
                <!--Message To-->
                <v-flex shrink>
                    <v-layout row align-center>
                        <v-flex xs12>
                            <v-combobox
                                    dense
                                    :items="contacts"
                                    item-text="display"
                                    label="To:"
                                    hide-details
                                    prepend-inner-icon="people"
                                    solo
                                    flat
                                    v-model="messageTo"
                                    hide-selected
                                    ref="messageToInput"
                                    multiple
                                    @input="validateToEmailAddress($event)"
                            >
                                <template slot="item" slot-scope="address">
                                    <v-icon left :color="!address.item.taskContact ? 'primary' : 'secondary'">label</v-icon>
                                    <span class="body-1">{{address.item.display}}</span>
                                </template>
                                <template
                                        slot="selection"
                                        slot-scope="address"
                                >
                                    <div class="pr-2" style="padding-top: 2px; padding-bottom: 2px">
                                        <v-layout shrink row align-center style="border-radius: 3px; border: 1px solid var(--v-primary-base);">
                                            <v-flex shrink class="caption pl-2">
                                                <div class="font-weight-bold primary--text">{{ address.item.name != '' ? address.item.name : address.item.display }}&nbsp;</div>
                                                <div style="margin-top: -5px" class="greyType--text" v-if="address.item.name != ''" >{{ address.item.email }}&nbsp;</div>
                                            </v-flex>
                                            <v-flex
                                                    shrink
                                                    class="pr-1"
                                                    style="padding-top: 2px; padding-bottom: 2px"
                                            >
                                                <v-btn
                                                        small
                                                        icon
                                                        flat
                                                        class="pa-0 ma-0"
                                                        color="primary"
                                                        @click="address.parent.selectItem(address.item)"
                                                >
                                                    <!--@click="mailAttachments.splice(i, 1)"-->
                                                    <v-icon small>close</v-icon>
                                                </v-btn>
                                            </v-flex>
                                        </v-layout>
                                    </div>
                                </template>
                            </v-combobox>
                        </v-flex>
                        <v-flex shrink class="pr-2">
                            <v-tooltip left>
                                <v-btn icon flat class="pa-0 ma-0" slot="activator" @click="showCC = !showCC" color="secondary">CC</v-btn>
                                <span>Add CC Address</span>
                            </v-tooltip>
                        </v-flex>
                    </v-layout>
                </v-flex>
                <v-flex shrink><v-divider></v-divider></v-flex>
                <!--Message CC-->
                <v-flex shrink v-if="showCC">
                    <v-combobox
                            dense
                            :items="contacts"
                            item-text="display"
                            label="Cc:"
                            hide-details
                            prepend-inner-icon="people_outline"
                            solo
                            flat
                            v-model="messageCC"
                            hide-selected
                            ref="messageCCInput"
                            multiple
                            @input="validateCCEmailAddress($event)"
                    >
                        <template slot="item" slot-scope="address">
                            <v-icon left :color="!address.item.taskContact ? 'primary' : 'secondary'">label</v-icon>
                            <span class="body-1">{{address.item.display}}</span>
                        </template>
                        <template
                                slot="selection"
                                slot-scope="address"
                        >
                            <div class="pr-2" style="padding-top: 2px; padding-bottom: 2px">
                                <v-layout shrink row align-center style="border-radius: 3px; border: 1px solid var(--v-primary-base);">
                                    <v-flex shrink class="caption pl-2">
                                        <div class="font-weight-bold primary--text">{{ address.item.name != '' ? address.item.name : address.item.display }}&nbsp;</div>
                                        <div style="margin-top: -5px" class="greyType--text" v-if="address.item.name != ''" >{{ address.item.email }}&nbsp;</div>
                                    </v-flex>
                                    <v-flex
                                            shrink
                                            class="pr-1"
                                            style="padding-top: 2px; padding-bottom: 2px"
                                    >
                                        <v-btn
                                                small
                                                icon
                                                flat
                                                class="pa-0 ma-0"
                                                color="primary"
                                                @click="address.parent.selectItem(address.item)"
                                        >
                                            <!--@click="mailAttachments.splice(i, 1)"-->
                                            <v-icon small>close</v-icon>
                                        </v-btn>
                                    </v-flex>
                                </v-layout>
                            </div>
                        </template>
                    </v-combobox>
                </v-flex>
                <v-flex shrink v-if="showCC"><v-divider></v-divider></v-flex>
<!--Message BCC-->
                <v-flex shrink v-if="showCC">
                    <v-combobox
                            dense
                            :items="contacts"
                            item-text="display"
                            label="Bcc:"
                            hide-details
                            prepend-inner-icon="people_outline"
                            solo
                            flat
                            v-model="messageBCC"
                            hide-selected
                            ref="messageBCCInput"
                            multiple
                            @input="validateBCCEmailAddress($event)"
                    >
                        <template slot="item" slot-scope="address">
                            <v-icon left :color="!address.item.taskContact ? 'primary' : 'secondary'">label</v-icon>
                            <span class="body-1">{{address.item.display}}</span>
                        </template>
                        <template
                                slot="selection"
                                slot-scope="address"
                        >
                            <div class="pr-2" style="padding-top: 2px; padding-bottom: 2px">
                                <v-layout shrink row align-center style="border-radius: 3px; border: 1px solid var(--v-primary-base);">
                                    <v-flex shrink class="caption pl-2">
                                        <div class="font-weight-bold primary--text">{{ address.item.name != '' ? address.item.name : address.item.display }}&nbsp;</div>
                                        <div style="margin-top: -5px" class="greyType--text" v-if="address.item.name != ''" >{{ address.item.email }}&nbsp;</div>
                                    </v-flex>
                                    <v-flex
                                            shrink
                                            class="pr-1"
                                            style="padding-top: 2px; padding-bottom: 2px"
                                    >
                                        <v-btn
                                                small
                                                icon
                                                flat
                                                class="pa-0 ma-0"
                                                color="primary"
                                                @click="address.parent.selectItem(address.item)"
                                        >
                                            <!--@click="mailAttachments.splice(i, 1)"-->
                                            <v-icon small>close</v-icon>
                                        </v-btn>
                                    </v-flex>
                                </v-layout>
                            </div>
                        </template>
                    </v-combobox>
                </v-flex>
                <v-flex shrink v-if="showCC"><v-divider></v-divider></v-flex>
                <!--Subject-->
                <v-flex shrink>
                    <v-text-field
                            dense
                            label="Subject:"
                            hide-details
                            prepend-inner-icon="subject"
                            solo
                            flat
                            v-model="subject"
                    />
                </v-flex>
                <v-flex shrink><v-divider></v-divider></v-flex>
                <!--Attachments-->
                <v-flex
                        shrink
                        class="pt-1 pb-0 px-1"
                >
                    <!--:class="dragOverAttachments ? 'drop-zone' : ''"-->
                    <div
                            style="width: 100%"
                            @dragover="preventDefault($event)"
                            @drop="$event.preventDefault(); dropFiles($event); dragOverAttachments = false"
                            @dragenter="dragOverAttachments = true"
                            @dragleave="dragOverAttachments = false"
                    >
                        <v-layout row align-center>
                            <v-flex shrink class="pt-2 pb-2 pl-1 pr-2">
                                <v-icon class="greyType--text text--darken-2">attach_file</v-icon>
                            </v-flex>
                            <v-flex xs12>
                                <!--Place Holder Text-->
                                <template v-if="showAttachments.length <= 0">
                                    <span class="body-1 greyType--text">Attachments:</span>
                                </template>
                                <!--Existing Attachments-->
                                <template>
                                    <v-layout row wrap align-center>
                                        <v-flex shrink v-for="(attachment, i) in showAttachments" class="pr-2 pb-1" :key="i">
                                            <v-layout row align-center class="white--text primary" style="border-radius: 3px">
                                                <v-flex shrink class="pl-2">
                                                    <doc-view
                                                        v-if="!['embeddedUpload', 'uploading', 'embeddedUploadUploading'].includes(attachment.type) && ['pdf', 'PDF'].includes(typeof attachment.filename != 'undefined' ? attachment.filename.split('.').pop() : attachment.fileName.split('.').pop())"
                                                        :url="getPDFSrcAttachment(attachment)"
                                                    >
                                                        <v-tooltip top>
                                                            <v-icon
                                                                color="white"
                                                                style="cursor: pointer"
                                                                small
                                                                class="pr-2"
                                                                slot="activator"
                                                            >
                                                                visibility
                                                            </v-icon>
                                                            <span>Preview</span>
                                                        </v-tooltip>
                                                    </doc-view>
                                                    <v-tooltip top>
                                                        <v-icon
                                                                slot="activator"
                                                                color="white"
                                                                small
                                                                v-if="attachment.type == 'embeddedUpload'"
                                                        >
                                                            photo
                                                        </v-icon>
                                                        <span>Embedded Image</span>
                                                    </v-tooltip>

                                                    <v-progress-circular
                                                            v-if="attachment.type == 'uploading'"
                                                            :size="15"
                                                            :width="3"
                                                            color="white"
                                                            :value="attachment.progress"
                                                    />

                                                    <v-progress-circular
                                                            v-if="attachment.type == 'embeddedUploadUploading'"
                                                            :size="15"
                                                            :width="3"
                                                            color="white"
                                                            indeterminate
                                                    />
                                                </v-flex>
                                                <v-flex shrink class="pr-1">
                                                    <v-tooltip top>
                                                        <v-icon
                                                            style="cursor: pointer"
                                                            color="white"
                                                            small
                                                            v-if="!['embeddedUpload', 'uploading', 'embeddedUploadUploading'].includes(attachment.type)"
                                                            @click="runPreviewAttachment(attachment, true)"
                                                            slot="activator"
                                                        >
                                                            attach_file
                                                        </v-icon>
                                                        <span>Download</span>
                                                    </v-tooltip>
                                                </v-flex>
                                                <v-flex shrink class="caption pa-1">
                                                    {{attachment.type != 'taskDocument' ? attachment.filename : attachment.displayName}}&nbsp;
                                                </v-flex>
                                                <v-flex
                                                        shrink
                                                        class="pr-1"
                                                        style="border-bottom-right-radius: 3px; border-top-right-radius: 3px;"
                                                        v-if="!['embeddedUpload', 'uploading', 'embeddedUploadUploading'].includes(attachment.type)"
                                                >
                                                    <v-tooltip top>
                                                        <v-btn
                                                                small
                                                                icon
                                                                flat
                                                                class="pa-0 ma-0"
                                                                color="white"
                                                                slot="activator"
                                                                @click="removeAttachment(attachment)"
                                                        >
                                                            <v-icon small>close</v-icon>
                                                        </v-btn>
                                                        <span>Remove Attachment</span>
                                                    </v-tooltip>
                                                </v-flex>
                                            </v-layout>
                                        </v-flex>

                                    </v-layout>
                                </template>
                            </v-flex>
                            <v-flex shrink v-if="linkToTask">
                                <v-tooltip left>
                                    <v-btn
                                            class="pa-0 ma-0"
                                            flat
                                            icon
                                            color="secondary"
                                            slot="activator"
                                            @click="addAttachmentMenu = true"
                                    >
                                        <v-icon>add</v-icon>
                                    </v-btn>
                                    Add an Existing Attachment
                                </v-tooltip>
                            </v-flex>
                            <v-flex shrink class="pl-2">
                                <v-tooltip left>
                                    <div class="upload-btn-wrapper" @click="addAttachmentMenu = false" slot="activator">
                                        <v-btn
                                                class="pa-0 ma-0"
                                                flat
                                                icon
                                                color="secondary"
                                        >
                                            <v-icon>cloud_upload</v-icon>
                                        </v-btn>
                                        <input ref="manualUpload" type="file" name="myfile" multiple
                                               :accept="userInfo.entityInfo.mimeTypeList" @input="manualUploadRun()"/>
                                    </div>
                                    Upload Attachments
                                </v-tooltip>

                            </v-flex>


                            <app-input-dlg
                                    :show-dialog="addAttachmentMenu"
                                    title="Add Attachments To Mail"
                                    :cancel-button="true"
                                    :show-input-box="false"
                                    @dismiss="addAttachmentMenu = false"
                                    :body-padding="false"
                            >
                                <template slot="body">
                                    <div class="">
                                        <v-list dense style="max-height: 300px; overflow-y: auto">
                                            <v-list-tile class="text-xs-center">
                                                <v-text-field
                                                        label="Filter Attachments"
                                                        single-line
                                                        v-model="filterAttachmentString"
                                                        color="primary"
                                                        style="width: 100%"
                                                        class="ma-0 pa-0"
                                                        type="email"
                                                        clearable
                                                        prepend-icon="search"
                                                        hide-details
                                                ></v-text-field>
                                            </v-list-tile>
                                            <template v-if="linkToTask && linkTask != null">
                                                <v-list-tile @click="mailAttachments.push(document);" v-for="document in filteredTaskDocuments" :key="document.documentDBID">
                                                    <v-icon color="grey" small left>assignment_turned_in</v-icon>
                                                    {{document.displayName}}
                                                </v-list-tile>
                                            </template>
                                            <template v-if="composeType > 0 && srcMail != null" >
                                                <v-list-tile @click="mailAttachments.push(document);" v-for="document in filteredMailDocuments" :key="document.documentDBID">
                                                    <v-icon small color="grey" left>mail</v-icon>
                                                    {{document.filename}}
                                                </v-list-tile>
                                            </template>
                                        </v-list>
                                    </div>
                                </template>
                            </app-input-dlg>

                        </v-layout>
                    </div>
                </v-flex>
                <v-divider></v-divider>
                <!--Mail Body-->
                <v-flex xs12  style="overflow-y: hidden;" id="editorFlex" @click="setFocus()" v-if="showDialog">
                    <editor
                        v-model="bodyModel"
                        :extra-config="editorConfig"
                    />
                </v-flex>

                <div style="position: absolute; bottom: 0; right: 0; z-index: 999999" class="pr-3 pb-3">
                    <div style="display: inline" v-if="linkToTask">
                        <v-tooltip left>
                            <v-btn
                                    icon
                                    large
                                    color="primary"
                                    slot="activator"
                                    @click="saveDraft(0, false)"
                                    :loading="savingDraft || sendingMail"
                                    :disabled="savingDraft || sendingMail"
                            >
                                <v-icon>save</v-icon>
                            </v-btn>
                            <span>Save Draft</span>
                        </v-tooltip>
                    </div>
                    <div style="display: inline" v-if="allowSend">
                        <v-tooltip left>
                            <v-btn
                                    icon
                                    large
                                    color="secondary"
                                    slot="activator"
                                    @click="linkToTask ? saveDraft(2, true) : sendMessage()"
                                    :loading="sendingMail"
                                    :disabled="sendingMail"
                                    style="z-index: 999999"
                            >
                                <v-icon>send</v-icon>
                            </v-btn>
                            <span>Send Mail</span>
                        </v-tooltip>
                    </div>
                </div>
            </v-layout>
        </v-card>
    </v-dialog>
</template>

<script>
    import {codeBaseMixin} from "../../codeBaseMixin";
    import VueFroala from 'vue-froala-wysiwyg';
    import axios from 'axios';
    import InputDlg from "./InputDlg";
    import TaskContainer from "../TaskContainer/TaskContainer";
    import {blankString, arrPopulated} from "../../codeFunctions";
    import Editor from "@/Editor/Editor";
    import DocView from "@/DocView/DocView";
    import { onClickOutside } from '@vueuse/core'

    export default {
        components: {DocView, Editor, TaskContainer, appInputDlg:InputDlg},
        mixins: [codeBaseMixin],
        props: {
            showDialog: Boolean,
            userInfo: {
                required: true,
                type: Object
            },
            mailConfig: {
                required: true,
                type: Object
            },
            composeType: {
                type: Number, //0:Compose 1:Reply 2:Reply To All 3:Forward
                default: 0
            },
            srcMail: {
                type: Object,
                default: () => {return null}
            },
            srcMailBodySrc: {
                type: String,
                default: () => {return null}
            },
            srcMailPath: {
                type: String,
                default: 'task'
            },
            linkToTask: {
                type: Boolean,
                default: false
            },
            linkTask: {
                type: Object,
                default: () => {return null}
            },
            startWithDraft: {
                type: Boolean,
                default: false
            },
            draft: {
                type: Object,
                default: () => {return null}
            },
            taskStepID: {
                type: Number,
                default: 0
            }
        },
        data() {
            return {
                srcMailBody: "",
                dragOverAttachments: false,
                subject: null,
                selectedSMTPAddress: null,
                selectedSignature: null,
                bodyModel: ``,
                mailAttachments: [],
                messageTo: null,
                showCC: false,
                messageCC: null,
                messageBCC: null,
                savingDraft: false,
                sendingMail: false,
                editor: null,
                filterAttachmentString: null,
                addAttachmentMenu: false,
                imageCount: 0
            }
        },
        computed: {

            editorConfig() {
                // testDrop(event, editor) {
                //     console.log('DROP EVENT', event)
                //
                //     editor.insertContent('<span style="background: fuchsia; display: inline-block; cursor: pointer" :draggable="true">Test123456</span>')
                // },
                // paste_data_images: true,
                // images_upload_handler: this.uploadImage,
                // init_instance_callback: function(editor) {
                //     editor.on('drop', function(e) {
                //         vm.testDrop(e, editor)
                // });
                // },
                // uploadImage(blobInfo, success) {
                //     ++this.imageCount
                //     let h = new Headers();
                //     let fd = new FormData();
                //     let myFile = blobInfo.blob();
                //     fd.append('attachmentFile', myFile);
                //
                //     axios.request({
                //         method: "post",
                //         url: `${this.serverAddress}/upload/noteAttachment/${this.userInfo.entityID}`,
                //         data: fd,
                //         onUploadProgress: (p) => {
                //             this.uploadProgress = (p.loaded / p.total) * 100
                //         },
                //     })
                //         .then(data => {
                //             success(data.data.link);
                //             --this.imageCount;
                //         })
                //         .catch(() => {
                //             this.showSnack('Error', 'Image Upload Error', 'Close', 'red');
                //             success('.blank');
                //             --this.imageCount;
                //         })
                // },

                return {
                    paste_data_images: true,
                    images_upload_handler: this.uploadImage,
                    remove_linebreaks : false,
                    init_instance_callback: function(editor) {
                        editor.focus()
                    },
                }
            },


            config() {
                let vm = this;
                return {
                    // pluginsEnabled: ["align", "code_beautifier", "code_view", "colors", "draggable", "emoticons", "entities", "file", "font_family", "font_size", "fullscreen", "image", "image_manager", "image_tui", "inline_class", "inline_style", "line_breaker", "line_height", "link", "lists", "paragraph_format", "paragraph_style", "print", "quote", "save", "special_characters", "table", "url", "word_paste"],
                    // toolbarVisibleWithoutSelection: true,
                    // toolbarInline: true,
                    enter: VueFroala.ENTER_BR,
                    fontSize: 	['8', '9', '10', '11', '12', '14', '16', '18', '24', '30', '36', '48', '60', '72', '96'],
                    key: "ERB2zA2B2A8A1B2A1qYFd1UQRFQIVb1MSMc2IWPNe1IFg1yD4C3D2E2C4B1E1H4B2D3==",
                    toolbarSticky: true,
                    scrollableContainer: '#editorFlex',
                    editorClass: 'froala-editor-class',
                    tableInsertHelper: true,
                    attribution: false,
                    toolbarButtons: {
                        // Key represents the more button from the toolbar.
                        moreText: {
                            // List of buttons used in the  group.
                            buttons: ['bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'fontFamily', 'fontSize', 'textColor', 'backgroundColor', 'inlineClass', 'inlineStyle', 'clearFormatting'],

                            // Alignment of the group in the toolbar.
                            align: 'left',

                            // By default, 3 buttons are shown in the main toolbar. The rest of them are available when using the more button.
                            buttonsVisible: 3
                        },
                        moreParagraph: {
                            buttons: ['alignLeft', 'alignCenter', 'formatOLSimple', 'alignRight', 'alignJustify', 'formatOL', 'formatUL', 'paragraphFormat', 'paragraphStyle', 'lineHeight', 'outdent', 'indent', 'quote'],
                            align: 'left',
                            buttonsVisible: 3
                        },

                        moreRich: {
                            buttons: ['insertLink', 'insertImage', 'insertVideo', 'insertTable', 'emoticons', 'fontAwesome', 'specialCharacters', 'embedly', 'insertFile', 'insertHR'],
                            align: 'left',
                            buttonsVisible: 3
                        },

                        moreMisc: {
                            buttons: ['undo', 'redo', 'fullscreen', 'print', 'getPDF', 'spellChecker', 'selectAll', 'html', 'help'],
                            align: 'right',
                            buttonsVisible: 2
                        }
                    },
                    imageEditButtons: ['imageReplace', 'imageAlign', 'imageRemove', '|', 'imageLink', 'linkOpen', 'linkEdit', 'linkRemove', '-', 'imageDisplay', 'imageStyle', 'imageAlt', 'imageSize'],
                    imageUploadParam: 'attachmentFile',
                    imageUploadURL: this.baseServerAddress + 'upload/mailAttachment',
                    imageUploadMethod: 'POST',
                    imageMaxSize: 5 * 1024 * 1024,
                    events: {
                        'image.inserted': this.imageInserted,
                        // 'initialized': this.initalizedEditor,
                        'image.removed': this.imageRemoved,
                        'initialized': function () {
                            var editor = this;
                            vm.editor = editor;
                        }
                    },
                    placeholderText: 'Edit Your Content Here!',
                    charCounterCount: false,
                    tableResizerOffset: 10,
                    zIndex: 99999,
                    tableStyles: {
                        'fr-dashed-borders': 'Dashed Borders',
                        'fr-alternate-rows': 'Alternate Rows',
                        'froalatablestyle': 'No Border'
                    },
                    tabSpaces: 4,
                    width: '100%',
                }
            },
            allowSend() {
                try {
                    return this.imageCount == 0 && this.selectedSMTPAddress != null && this.subject != null && this.subject != '' && this.messageTo != null && this.messageTo.length > 0;
                } catch (e) {
                    // eslint-disable-next-line
                    console.log(e)
                    return false
                }
            },
            taskContacts() {
                if (this.linkToTask) {
                    try {
                        return this.linkTask.structure.mails.map((mail) => {
                            return [
                                typeof mail.from != 'undefined' && mail.from != null && mail.from != '' ? `${mail.from}, ` : '',
                                typeof mail.to != 'undefined' && mail.to != null && mail.to != '' ? `${mail.to}, ` : '',
                                typeof mail.cc != 'undefined' && mail.cc != null && mail.cc != '' ? `${mail.cc}, ` : ''
                            ].join('')
                        }).join(', ').split(',').reduce((accumulator, address) => {
                            if (address.trim() != null && address.trim() != '') {
                                let tAddress = address.trim().split('<');
                                if (tAddress.length > 1) {
                                    // Name And Surname
                                    return accumulator.concat([{
                                        name: tAddress[0].trim().replace(/"/g,''),
                                        email: tAddress[1].replace(/>/g, '').trim()
                                    }])
                                } else {
                                    // Just An Address
                                    return accumulator.concat([{
                                        name: '',
                                        email: tAddress[0].trim()
                                    }])
                                }
                            } else {
                                return accumulator
                            }
                        }, []).map(address => {
                            address.taskContact = true;
                            address.display = address.name != null && address.name.trim() != "" ? `${address.name} <${address.email}>` : address.email;
                            return address;
                        }).sort((a,b) => a['display'] < b['display'] ? -1 : 1);
                    } catch (e) {
                        // eslint-disable-next-line
                        console.log(e);
                        return [];
                    }
                } else {
                    return []
                }
            },
            contacts() {
                try {
                    let arrContacts = [];
                    if (this.userInfo.gmailEnabled == true && this.mailConfig.gmail.running && !this.mailConfig.gmail.loadingContacts) {
                        arrContacts = arrContacts.concat(this.mailConfig.gmail.contacts);
                    }
                    if (this.userInfo.office365Enabled == true && this.mailConfig.office.running && !this.mailConfig.office.loadingContacts) {
                        arrContacts = arrContacts.concat(this.mailConfig.office.contacts);
                    }
                    try {
                        if (this.userInfo.smtpAddressEnabled == 1 && this.mailConfig.smtp.running && !this.mailConfig.smtp.loadingContacts) {
                            arrContacts = arrContacts.concat(this.mailConfig.smtp.contacts);
                        }
                    } catch (e) {
                        // eslint-disable-next-line
                        console.log(e)
                    }
                    let resContacts = [];
                    let tRefEmails = [];
                    arrContacts.forEach(contact => {
                        if (!tRefEmails.includes(contact.email)) {
                            tRefEmails.push(contact.email);
                            resContacts.push(contact);
                        }
                    });
                    return this.taskContacts.concat(resContacts.map(obj => {
                        if (obj.name != null && obj.name.trim() != "") {

                            obj.display = `${obj.name}`;

                        } else {
                            obj.display = obj.email;
                        }
                        obj.taskContact = false;
                        return obj;
                    }).sort((a,b) => a['display'] < b['display'] ? -1 : 1));
                } catch (e) {
                    // eslint-disable-next-line
                    console.log(e)
                    return [];
                }
            },
            showAttachments() {
                try {
                        return this.mailAttachments.filter(attachment => attachment.type != 'srcMailDoc' || (attachment.type == 'srcMailDoc' && typeof attachment.nonRemovable == 'undefined'));

                } catch (e) {
                    // eslint-disable-next-line
                    console.log(e)
                    return []
                }
            },
            srcMailDocuments() {
                let srcMailDocsOnMail = this.mailAttachments.filter(doc => doc.type == 'srcMailDoc').map(obj => {return obj.filename});

                try {
                    return this.srcMail.attachments.filter(obj => !srcMailDocsOnMail.includes(obj.attName)).map(doc => {
                        let sendDoc = {
                            type: 'srcMailDoc',
                            filename: doc.attName,
                            size: doc.attSize,
                            mime: doc.attMimeType
                        };
                        return sendDoc;
                    }).sort((a,b) => a['name'] < b['name'] ? -1 : 1);
                } catch (e) {
                    // eslint-disable-next-line
                    console.log(e)
                    return []
                }
            },
            taskDocuments() {
                let taskDocsOnMail = this.mailAttachments.filter(doc => doc.type == 'taskDocument').map(obj => {return obj.documentDBID});
                try {
                    return this.linkTask.structure.documents.filter(obj => !taskDocsOnMail.includes(obj.documentDBID)).map(doc => {
                        doc.type = 'taskDocument';
                        doc.displayName = doc.fileName;
                        if (doc.alias != null && doc.alias != "") {
                            doc.displayName = doc.alias
                        }
                        return doc
                    }).sort((a,b) => a['displayName'] < b['displayName'] ? -1 : 1);
                } catch (e) {
                    // eslint-disable-next-line
                    console.log(e)
                    return []
                }
            },
            filteredTaskDocuments() {
                let docs = this.blankString(this.filterAttachmentString) && Array.isArray(this.taskDocuments) ? this.taskDocuments
                    : this.taskDocuments.filter(obj => obj.displayName.toLowerCase().includes(this.filterAttachmentString.toLowerCase()))

                let recycleSteps = this.linkTask.structure.steps.filter(obj => obj.recycleStep === 1).map(obj => {return obj.stepID})

                let recycleExcluded = docs.filter(obj => !recycleSteps.includes(obj.stepID));

                return recycleExcluded
            },
            filteredMailDocuments() {
                return this.blankString(this.filterAttachmentString) && Array.isArray(this.srcMailDocuments) ? this.srcMailDocuments
                    : this.srcMailDocuments.filter(obj => obj.filename.toLowerCase().includes(this.filterAttachmentString.toLowerCase()))
            },
            signatures() {
                let tSignatures = [{
                    id: 0,
                    description: 'No Signature',
                    default: 0,
                    signature: `<div></div>`
                }];

                if (this.userInfo.signatures != null) {
                    try {
                        this.userInfo.signatures.forEach(signature => {
                            try {
                                tSignatures.push(signature);
                                if (this.intToBool(signature.default)) {
                                  // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                                    this.selectedSignature = signature
                                }
                            } catch (e) {
                              // Nothing
                            }
                        })
                        if (this.selectedSignature == null) {
                          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                          this.selectedSignature = tSignatures[0]
                        }
                    } catch (e) {
                        console.log(e)
                    }
                }

                if (this.selectedSignature == null) {
                  // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                    this.selectedSignature = tSignatures[0];
                }

                return tSignatures.sort((a,b) => a['description'] < b['description'] ? -1 : 1);
            },
            defaultFrom() {
                try {
                    if (this.userInfo.defaultSendAsAddress == null) {
                        throw 'No Default Address Set'
                    }
                    return JSON.parse(this.userInfo.defaultSendAsAddress)
                } catch (e) {
                    // eslint-disable-next-line
                    console.log(e);
                    return {
                        address: 'null',
                        type: 'null'
                    }
                }
            },
            fromAddresses() {
                let tAddresses = [];
                try {
                    tAddresses = tAddresses.concat(this.mailConfig.gmail.addresses.map(address => {
                        address.type = 'gmail';
                        return address
                    }))
                } catch (e) {
                  // Nothing
                }
                try {
                    tAddresses = tAddresses.concat(this.mailConfig.office.addresses.map(address => {
                        address.type = 'office';
                        return address
                    }))
                } catch (e) {
                  // Nothing
                }
                try {
                    tAddresses = tAddresses.concat(this.mailConfig.smtp.addresses.map(address => {
                        address.type = 'smtp';
                        return address
                    }))
                } catch (e) {
                  // Nothing
                }
                return tAddresses;
            }
        },
        mounted() {
            console.log("IM HERE", this.$refs.messageToInput)
        },
        watch: {

            mailAttachments: {
                immediate: true,
                deep: true,
                handler(val) {
                    console.log('MAIL ATTACHMENTS UPDATED', val)
                }
            },


            bodyModel(val) {
                this.mailAttachments.forEach((attachment, i) => {
                    try {
                        if (attachment.type === 'embeddedUpload') {
                            if (!val.toLowerCase().includes(attachment.link.toLowerCase())) {
                                this.mailAttachments.splice(i, 1)
                            }
                        }
                    } catch(e) {
                        console.log(e)
                    }
                })

            },

            addAttachmentMenu: {
                immediate: true,
                handler() {
                    this.filterAttachmentString = null
                }
            },

            showDialog: {
                immediate: true,
                handler(shown) {
                    console.log("HERE")
                    this.imageCount = 0

                    if (!this.sendingMail) {
// Reset Template
                        if (shown) {
                            this.srcMailBody = this.srcMailBodySrc;
                            this.messageTo = [];
                            this.messageCC = [];
                            this.subject = "";
                            this.mailAttachments = [];
                            this.bodyModel = "";
                        }

// Subject & From Address
                        if (shown && this.userInfo.defaultSendAsAddress != null && this.fromAddresses.length > 0) {
                            this.fromAddresses.forEach(address => {
                                try {
                                    if (address.address == this.defaultFrom.address && address.type == this.defaultFrom.type) {
                                        this.selectedSMTPAddress = address
                                    }
                                } catch (e) {
                                    // eslint-disable-next-line
                                    console.log(e)
                                }
                            });
                        }

                        if (!this.startWithDraft) {
                            // -Update The Subject
                            if (shown) {
                                // No Draft
                                switch (this.composeType) {
                                    case 0: {

                                        // Regular Compose
                                        if (this.linkToTask) {
                                            // Task Message
                                            this.subject = this.linkTask.title;
                                        } else {
                                            // Plain Compose
                                            this.subject = null;
                                        }

                                        break;
                                    }
                                    default: {
                                        // Forward Or Reply
                                        this.subject = `${this.composeType < 3 ? 'Re' : 'Fw'}: ${!['re', 'fw'].includes(this.srcMail.subject.substr(0, 2).toLowerCase()) ? this.srcMail.subject : this.srcMail.subject.substr(4, this.srcMail.subject.length)}`;
                                        break;
                                    }
                                }
                            }
                        } else {
                            // Draft Message
                            this.subject = this.draft.subject;
                            this.showCC = this.draft.showCC;
                        }

                        if (this.startWithDraft && shown) {
                            this.selectedSMTPAddress = this.draft.selectedSMTPAddress;
                        }

                        if (shown && this.userInfo.defaultSendAsAddress == null && this.fromAddresses.length > 0) {
                            // -Set THe Selected SMTP From if Draft


                            if (this.fromAddresses.length == 1) {
                                this.selectedSMTPAddress = this.fromAddresses[0];
                            }

                            if (this.selectedSMTPAddress == null && this.fromAddresses.length >= 1) {
                                this.selectedSMTPAddress = this.fromAddresses[0];
                            }
                        }
                        if (shown && this.composeType > 0 && this.srcMailBody != null && this.srcMailBody != "" && !this.startWithDraft) {
                            // Extract The Local Attachments Tat Are On The Page

                          // eslint-disable-next-line no-useless-escape
                            let tWork = this.srcMailBody.split(/(?:src\=\"\.\/)|(?:src\=\'\.\/)|(?:src\=\.\/)/);


                            let tNewDoc = [];

                            if (tWork.length > 1) {
                                tWork.forEach((line, i) => {
                                    if (i >= 1) {
                                        let endIndexQuote = line.indexOf('"');
                                        let endIndexSingleQuote = line.indexOf("'");
                                        let endIndexTag = line.indexOf('>');
                                        let endIndexSpace = line.indexOf(' ');

                                        let endType = '"';
                                        let endIndex = endIndexQuote;

                                        if (endIndexSingleQuote > 0 && ((endIndexSingleQuote < endIndex) || (endIndex == -1))) {
                                            endType = "'";
                                            endIndex = endIndexSingleQuote
                                        }

                                        if (endIndexTag > 0 && ((endIndexTag < endIndex) || (endIndex == -1))) {
                                            endType = "";
                                            endIndex = endIndexTag

                                            if (endIndexSpace > 0 && ((endIndexSpace < endIndex) || (endIndex == -1))) {
                                                endType = " ";
                                                endIndex = endIndexSpace
                                            }
                                        }

                                        let filename = line.substring(0, endIndex);

                                        let endCap = endType == '' ? '>' : endType;

                                        let tAtta = this.srcMailDocuments
                                            .filter(obj => obj.filename == filename)
                                            .map(obj => {
                                                obj.nonRemovable = true;
                                                return obj
                                            })


                                        this.mailAttachments = this.mailAttachments.concat(tAtta);

                                        switch (this.srcMailPath) {
                                            case 'office': {
                                                line = `${endType}${window.location.protocol}//${window.location.hostname}/officecache/${this.userInfo.userID}-${this.srcMail.id}/${filename}${line.substring(line.indexOf(endCap), line.length)}`;
                                                break;
                                            }
                                            case 'gmail': {
                                                console.log('LINE BEFORE',this.baseServerAddress, line)
                                                line = `${endType}${window.location.protocol}//${window.location.hostname}/gmailcache/${this.userInfo.userID}-${this.srcMail.id}/${filename}${line.substring(line.indexOf(endCap), line.length)}`;
                                                console.log('LINE AFTER', line)
                                                break;
                                            }
                                            case 'task': {
                                                let mailPath = this.srcMail.fk_gmail_group_mailbox_folder == 0 ? this.srcMail.fk_import_user + '-' + this.srcMail.mailID : this.srcMail.fk_gmail_group_mailbox_folder + '-' + this.srcMail.mailID;
                                                mailPath = `${window.location.protocol}//${window.location.hostname}/` + 'mailstore/' + this.userInfo.entityID + '/' + mailPath;
                                                line = `${endType}${mailPath}/${filename}${line.substring(line.indexOf(endCap), line.length)}`;
                                                break;
                                            }
                                        }
                                    }
                                    tNewDoc.push(line)
                                });
                                this.srcMailBody = tNewDoc.join('src=')

                            }
                        }
                        if (shown) {
                            if (this.startWithDraft) {
                                this.bodyModel = this.draft.bodyModel;
                                this.messageTo = this.draft.messageTo;
                                this.messageCC = this.draft.messageCC;
                                this.mailAttachments = this.mailAttachments.concat(this.draft.mailAttachments);
                                this.draftReplaceAttachments(this.draft.bodyModel);
                            } else {
                                // Reply Forward Email Addresses
                                switch (this.composeType) {
                                    case 1: {
                                        // Reply
                                        this.messageTo = this.extractAddressesfromString(`${this.srcMail.from}`).map(obj => {
                                            obj.taskContact = false;
                                            return obj
                                        });
                                        break;
                                    }
                                    case 2: {
                                        // Reply To All
                                        let userAddresses = this.mailConfig.gmail.addresses.map(obj => {
                                            return obj.address
                                        }).concat(this.mailConfig.office.addresses.map(obj => {
                                            return obj.address
                                        }));
                                        this.messageTo = this.extractAddressesfromString(`${this.srcMail.from}${!blankString(this.srcMail.to) ? ',' : ''}${this.srcMail.to}`).map(obj => {
                                            obj.taskContact = false;
                                            return obj
                                        }).filter(obj => !userAddresses.includes(obj.email));

                                        if (typeof this.srcMail.cc != 'undefined') {
                                            this.messageCC = this.extractAddressesfromString(`${this.srcMail.cc}`).map(obj => {
                                                obj.taskContact = false;
                                                return obj
                                            }).filter(obj => !userAddresses.includes(obj.email));
                                        }


                                        this.showCC = arrPopulated(this.messageCC);
                                        break;
                                    }
                                    case 3: {
                                        this.mailAttachments = this.mailAttachments.concat(this.srcMailDocuments);
                                    }
                                }

                                if (this.srcMail)
                                    console.log("SRC Mail", this.srcMail)

                            }

                            let tTemp = this.signatures

                            this.signatureSwap(this.selectedSignature);

                            this.bodyModel = `<br>${this.bodyModel}`
                        }
                    }
                }
            }
        },
        methods: {

            uploadImage(blobInfo, success) {
                ++this.imageCount
                let h = new Headers();
                let fd = new FormData();
                let myFile = blobInfo.blob();
                fd.append('attachmentFile', myFile);

                axios.request({
                    method: "post",
                    url: `${this.serverAddress}/upload/mailAttachment`,
                    data: fd,
                })
                    .then(data => {
                        success(data.data.link);
                        this.mailAttachments.push({
                            type: 'embeddedUpload',
                            filename: data.data.filename,
                            id: data.data.id,
                            link: data.data.link,
                            size: data.data.size
                        })
                        --this.imageCount;
                    })
                    .catch(() => {
                        this.showSnack('Error', 'Image Upload Error', 'Close', 'red');
                        success('.blank');
                        --this.imageCount;
                    })
            },

            getPDFSrcAttachment(attachment) {
                let downloadAddress = '';
                switch (attachment.type) {
                    case 'srcMailDoc': {
                        switch (this.srcMailPath) {
                            case 'task': {
                                let mailPath = this.srcMail.fk_gmail_group_mailbox_folder == 0 ? this.srcMail.fk_import_user + '-' + this.srcMail.mailID : this.srcMail.fk_gmail_group_mailbox_folder + '-' + this.mail.mailID;
                                return `${this.baseServerAddress}mailstore/${this.userInfo.entityID}/${mailPath}/${attachment.filename}`;
                            }
                            case 'gmail': {
                                let mailPath = this.userInfo.userID + '-' + this.srcMail.id;
                                return `${this.baseServerAddress}gmailcache/${mailPath}/${attachment.filename}`;
                            }
                            case 'office': {
                                let mailPath = this.userInfo.userID + '-' + this.srcMail.id;
                                return `${this.baseServerAddress}officecache/${mailPath}/${attachment.filename}`;
                            }
                        }
                        break;
                    }
                    case 'taskDocument': {
                        let downloadAddress = `${this.baseServerAddress}upload/download/${this.userInfo.entityID}/${attachment.documentID}/`;
                        return downloadAddress + (attachment.alias == null ? attachment.fileName : attachment.alias.replace(/\.pdf$/g, '') + '.' + attachment.extention)
                    }
                    case 'uploadedAttachment': {
                        return `${this.baseServerAddress}upload/downloadTemp/${attachment.id}/${attachment.filename}`;
                        // return `${this.baseServerAddress}upload/downloadCache/file/attachmentCache/${this.userInfo.entityID}/${this.draft.id}/${attachment.id}/${attachment.filename}`;
                    }

                }
            },


            runPreviewAttachment(attachment, download) {
                let downloadAddress = '';
                switch (attachment.type) {
                    case 'srcMailDoc': {
                        switch (this.srcMailPath) {
                            case 'task': {
                                let mailPath = this.srcMail.fk_gmail_group_mailbox_folder == 0 ? this.srcMail.fk_import_user + '-' + this.srcMail.mailID : this.srcMail.fk_gmail_group_mailbox_folder + '-' + this.mail.mailID;
                                downloadAddress = `${this.baseServerAddress}mailstore/${this.userInfo.entityID}/${mailPath}/${attachment.filename}`;
                                let win = window.open(downloadAddress, '_blank');
                                break;
                            }
                            case 'gmail': {
                                let mailPath = this.userInfo.userID + '-' + this.srcMail.id;
                                let win = window.open(downloadAddress, '_blank');
                                break;
                            }
                            case 'office': {
                                let mailPath = this.userInfo.userID + '-' + this.srcMail.id;
                                downloadAddress = `${this.baseServerAddress}officecache/${mailPath}/${attachment.filename}`;
                                let win = window.open(downloadAddress, '_blank');
                                break;
                            }
                        }
                        break;
                    }
                    case 'taskDocument': {
                        let downloadAddress = `${this.baseServerAddress}upload/download/${this.userInfo.entityID}/${attachment.documentID}/`;
                        downloadAddress = downloadAddress + (attachment.alias == null ? attachment.fileName : attachment.alias + '.' + attachment.extention)
                        let win = window.open(downloadAddress, '_blank');
                        break;
                    }
                    case 'uploadedAttachment': {
                        let downloadAddress = `${this.baseServerAddress}upload/downloadTemp/${attachment.id}/${attachment.filename}`;
                        if (typeof attachment.draftAttachment != 'undefined' && attachment.draftAttachment) {
                            downloadAddress = `${this.baseServerAddress}upload/downloadCache/file/attachmentCache/${this.userInfo.entityID}/${this.draft.id}/${attachment.id}/${attachment.filename}`;
                        }
                        let win = window.open(downloadAddress, '_blank');
                        break;
                    }

                }
            },
            removeAttachment(attachment) {
                switch (attachment.type) {
                    case 'taskDocument': {
                        this.mailAttachments = this.mailAttachments.filter(obj => obj.type != 'taskDocument' || (obj.type == 'taskDocument' && obj.documentDBID != attachment.documentDBID));
                        break;
                    }
                    case 'uploadedAttachment': {
                        this.mailAttachments = this.mailAttachments.filter(obj => obj.type != 'uploadedAttachment' || (obj.type == 'uploadedAttachment' && obj.id != attachment.id));
                        break;
                    }
                    case 'srcMailDoc': {
                        this.mailAttachments = this.mailAttachments.filter(obj => obj.type != 'srcMailDoc' || (obj.type == 'srcMailDoc' && obj.filename != attachment.filename));
                        break;
                    }
                }

            },
            setFocus() {
                if (!this.editor.core.hasFocus()) {
                    this.editor.events.focus(true);
                }
            },
            getSrcFolder() {
                switch (this.srcMailPath) {
                    case 'office': {
                        return `${this.userInfo.userID}-${this.srcMail.id}`;
                    }
                    case 'gmail': {
                        return `${this.userInfo.userID}-${this.srcMail.id}`;
                    }
                    case 'task': {
                        return this.srcMail.fk_gmail_group_mailbox_folder == 0 ? this.srcMail.fk_import_user + '-' + this.srcMail.mailID : this.srcMail.fk_gmail_group_mailbox_folder + '-' + this.srcMail.mailID;
                    }
                }
            },
            sendMessage() {
                this.$emit('dismiss');
                this.sendingMail = false;
                this.bodyModel = this.bodyModel.split('contenteditable="false"').join("");
                let tBody = JSON.stringify({
                    draftSaved:             this.startWithDraft,
                    draftID:                this.startWithDraft ? this.draft.id : 0,
                    linkToTask:             this.linkToTask,
                    linkTaskID:             this.linkToTask ? this.linkTask.taskID : 0,
                    taskStepID:             this.linkToTask ? this.taskStepID : 0,
                    srcMailPath:            this.srcMailPath,
                    srcMailID:              this.composeType > 0 ? this.srcMail.id : 0,
                    srcMailFolder:          this.composeType > 0 ? this.getSrcFolder() : 0,

                    selectedSMTPAddress:    this.selectedSMTPAddress,
                    messageTo:              this.messageTo,
                    messageCC:              this.messageCC,
                    mailAttachments:        this.mailAttachments,
                    subject:                this.subject,
                    body:                   this.bodyModel,
                    displayName:            `${this.userInfo.name} ${this.userInfo.surname}`
                });
                this.fetch({method: 'POST', url:`/sendMail/sendCompose/${this.userInfo.entityID}/${this.userInfo.userID}`, body: tBody})
                    .then((data) => {
                        this.sendingMail = false;
                        let oldSendMail = JSON.parse(tBody);
                        if (oldSendMail.linkToTask) {
                            if (oldSendMail.linkTaskID == data.task.taskID) {
                                // Remove Draft First
                                if (oldSendMail.draftSaved) {
                                    this.$emit('removeDraft', oldSendMail.draftID)
                                }
                                // Push The New Mail
                                this.$emit('pushTaskMail', data.mail);
                                this.showSnack('Info', 'Message Has Been Sent', 'Close', 'primary')
                            }
                        }
                    })
                    .catch((e) => {
                        let oldSendMail = JSON.parse(tBody);
                        this.$emit('draftError', {draftID: oldSendMail.draftID, message: e})
                        // eslint-disable-next-line
                        this.showSnack('Error', 'Error Sending Mail', 'Close', 'red');
                        this.sendingMail = false;
                    })
            },
            saveDraft(sendStatus, sendMessage) {

                // try {
                //
                // } catch (e) {
                //     // eslint-disable-next-line
                //     console.log(e)
                // }

                sendStatus = sendStatus || 0;
                sendMessage = sendMessage || false;
                if (sendMessage) {
                    this.sendingMail = true;
                }
                let reqBody = {
                    props: {
                        composeType: this.composeType,
                        srcMail: this.srcMail != null ? this.mapObject(this.srcMail, 'snippet', ((value) => {
                            return this.encodeHtmlForJson(value);
                        })) : null,
                        srcMailBody: this.srcMailBody != null ? this.srcMailBody : null,
                        srcMailPath: this.srcMailPath,
                    },
                    data: {
                        subject: this.subject,
                        selectedSMTPAddress: this.selectedSMTPAddress,
                        selectedSignature: this.mapObject(this.selectedSignature, 'signature', (signatureHTML) => {
                            return signatureHTML;
                        }),
                        bodyModel: this.bodyModel,
                        mailAttachments: this.mailAttachments,
                        messageTo: this.messageTo,
                        showCC: this.showCC,
                        messageCC: this.messageCC,
                    }
                };

                this.savingDraft = true;
                this.fetch({method: 'POST', url:`/tasks/saveDraftMail/${this.userInfo.entityID}/${this.linkTask.taskID}/${this.taskStepID}/${this.userInfo.userID}/${typeof this.draft != 'undefined' && this.draft != null && this.draft.id != null ? this.draft.id : 0}/${sendStatus}`, body: JSON.stringify(reqBody)})
                    .then((data) => {

                        if (typeof this.draft != 'undefined' && this.draft != null && this.draft.id != null && this.draft.id != 0) {
                            // Replace Draft
                            this.$emit('replaceDraft', this.mapObject(data, 'sendStatus', (value) => {
                                if (sendMessage) {
                                    return 1
                                } else {
                                    return value
                                }
                            }))
                        } else {
                            // New Draft
                            this.$emit('newDraft', this.mapObject(data, 'sendStatus', (value) => {
                                if (sendMessage) {
                                    return 1
                                } else {
                                    return value
                                }
                            }))
                        }

                        this.$nextTick(() => {
                            this.mailAttachments = this.draft.mailAttachments;
                            this.draftReplaceAttachments(this.draft.bodyModel);
                            if (sendMessage) {
                                this.$nextTick(() => {
                                    this.sendMessage();
                                })
                            }
                        });

                        this.savingDraft = false;
                        if (!sendMessage) {
                            this.showSnack('Info', 'Draft Saved', 'Close', 'primary');
                        }

                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e)
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                        this.savingDraft = false;
                    })


            },
            draftReplaceAttachments(bodyString) {
                let tWork = bodyString.split('src="./');
                let tNewDoc = [];
                if (tWork.length > 0) {
                    tWork.forEach((line, i) => {
                        if (i >= 1) {
                            line = `${this.serverAddress}/draftMailAttachmentCache/${this.userInfo.entityID}/${this.draft.id}/${line}`;
                        }
                        tNewDoc.push(line)
                    });
                    this.bodyModel = tNewDoc.join('src="');
                }
            },
            validateToEmailAddress() {
              this.$refs.messageToInput.$data.lazySearch = '';
              this.$refs.messageToInput.$data.isMenuActive = false;

                this.messageTo = this.messageTo.reduce((arrTo, address) => {
                    if (typeof address == 'string') {
                        address = address.trim();
                        if (this.validateEmail(address)) {
                            return arrTo.concat([{name: '', email: address.trim(), display: address.trim(), taskContact: false}]);
                        } else {
                            this.showSnack('Error', 'Invalid Email Address', 'Close', 'red');
                            return arrTo
                        }
                    } else {
                        return arrTo.concat([address]);
                    }
                }, []);
            },
            validateCCEmailAddress() {
                this.$refs.messageCCInput.$data.lazySearch = '';
                this.$refs.messageCCInput.$data.isMenuActive = false;
                this.messageCC = this.messageCC.reduce((arrTo, address) => {
                    if (typeof address == 'string') {
                        if (this.validateEmail(address)) {
                            return arrTo.concat([{name: '', email: address.trim(), display: address.trim(), taskContact: false}]);
                        } else {
                            this.showSnack('Error', 'Invalid Email Address', 'Close', 'red');
                            return arrTo
                        }
                    } else {
                        return arrTo.concat([address]);
                    }
                }, []);
            },
            validateBCCEmailAddress() {
                this.$refs.messageBCCInput.$data.lazySearch = '';
                this.$refs.messageBCCInput.$data.isMenuActive = false;
                this.messageBCC = this.messageBCC.reduce((arrTo, address) => {
                    if (typeof address == 'string') {
                        if (this.validateEmail(address)) {
                            return arrTo.concat([{name: '', email: address.trim(), display: address.trim(), taskContact: false}]);
                        } else {
                            this.showSnack('Error', 'Invalid Email Address', 'Close', 'red');
                            return arrTo
                        }
                    } else {
                        return arrTo.concat([address]);
                    }
                }, []);
            },
            manualUploadRun() {

                function checkFileType(mimeList, file) {
                    let list = mimeList
                            .split(',')
                            .map(str => {try {return str.trim().toLowerCase()} catch (e) {return ''}})
                    let typeList = list
                            .filter(str => str && str.substr(0,1) !== '.')
                    let extList = list
                            .filter(str => str && str.substr(0,1) === '.')
                            .map(str => {
                                try {
                                    return str.substring(1)
                                } catch (e) {
                                    return ''
                                }
                            })
                    let ext = file.name ? file.name.split('.').pop().toLowerCase() : ''
                    let type = file.type ? file.type.toLowerCase().trim() : ''
                    return typeList.includes(type) || extList.includes(ext)
                }

                for (let i = 0; i < this.$refs.manualUpload.files.length; i++) {
                    let file = this.$refs.manualUpload.files[i];
                    if (((file.size / 1024) / 1024) < 50) {
                        if (checkFileType(this.userInfo.entityInfo.mimeTypeList, file)) {
                            this.uploadFile(file);
                        } else {
                            this.showSnack('Error', 'File Upload Canceled - Invalid File Type', 'Close', 'red');
                        }
                    } else {
                        this.showSnack('Error', 'File Upload Canceled - File To Large', 'Close', 'red')
                    }
                }

                this.$refs.manualUpload.value = "";
            },
            imageRemoved($img) {
                this.$nextTick(() => {
                    try {
                        if (this.bodyModel.toLowerCase().indexOf($img[0].dataset.id.toLowerCase()) <= -1) {
                            this.mailAttachments = this.mailAttachments.filter(obj => obj.type != 'embeddedUpload' || (obj.type == 'embeddedUpload' && obj.id != $img[0].dataset.id))

                        }
                    } catch (e) {
                      // Nothing
                    }

                })
            },
            imageInserted($img, response) {
                $img[0].style.maxWidth = "100%";
                $img[0].style.width = "auto";
                let res = JSON.parse(response);
                this.mailAttachments.push({
                    type: 'embeddedUpload',
                    filename: res.filename,
                    id: res.id,
                    link: res.link,
                    size: res.size
                })
            },
            dropFiles(e) {
                function checkFileType(mimeList, file) {
                    let list = mimeList
                            .split(',')
                            .map(str => {try {return str.trim().toLowerCase()} catch (e) {return ''}})
                    let typeList = list
                            .filter(str => str && str.substr(0,1) !== '.')
                    let extList = list
                            .filter(str => str && str.substr(0,1) === '.')
                            .map(str => {
                                try {
                                    return str.substring(1)
                                } catch (e) {
                                    return ''
                                }
                            })
                    let ext = file.name ? file.name.split('.').pop().toLowerCase() : ''
                    let type = file.type ? file.type.toLowerCase().trim() : ''
                    return typeList.includes(type) || extList.includes(ext)
                }
                for (let i = 0; i < e.dataTransfer.files.length; i++) {
                    let file = e.dataTransfer.files[i];
                    if (((file.size / 1024) / 1024) < 50) {
                        if (checkFileType(this.userInfo.entityInfo.mimeTypeList, file)) {
                            this.uploadFile(file);
                        } else {
                            this.showSnack('Error', 'File Upload Canceled - Invalid File Type', 'Close', 'red');
                        }
                    } else {
                        this.showSnack('Error', 'File Upload Canceled - File To Large', 'Close', 'red')
                    }
                }
            },
            uploadFile(file) {
                let h = new Headers();
                let fd = new FormData();
                let myFile = file;
                fd.append('attachmentFile', myFile);

                let newFile = {
                    type: 'uploading',
                    filename: file.name,
                    id: null,
                    progress: 0
                };

                this.mailAttachments.push(newFile);

                axios.request( {
                    method: "post",
                    url: `${this.serverAddress}/upload/mailAttachment/`,
                    data: fd,

                    onUploadProgress: (p) => {
                        newFile.progress = (p.loaded / p.total) * 100
                    },
                }).then (data => {
                    delete newFile.progress;
                    newFile.link = data.data.link;
                    newFile.id = data.data.id;
                    newFile.type = 'uploadedAttachment';
                    newFile.mime = file.type;
                    newFile.size = file.size;
                })
            },
            setDefaultSMTPAddress(address) {
                this.userInfo.defaultSendAsAddress = JSON.stringify(address);
                this.fetch({method: 'POST', url:`/users/updateDefaultSmtp/${this.userInfo.entityID}/${this.userInfo.userID}`, body: JSON.stringify(address)})
                    .then((data) => {
                        this.showSnack('Info', 'Default Send Address Has Been Updated', 'Close', 'primary')
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e);
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                    })
            },
            signatureSwap(signature) {
                // Check if draft
                // Draft Mail
                if (this.bodyModel.indexOf('<!--signatureStart-->') == -1) {
                    // No Signature Added Yet Add The Signature
                    this.bodyModel = `</br><div contenteditable="false"><!--signatureStart-->${signature.signature != null ? signature.signature.replace(/\r|\n/g, ``) : `<div>ERROR WITH SIGNATURE</div>`}<!--signatureEnd--></div>`
                } else {
                    // Signature Exists Replace With New Signature
                    this.bodyModel = `${this.bodyModel.substring(0, this.bodyModel.indexOf('<!--signatureStart-->'))}<!--signatureStart-->${signature.signature.replace(/\r|\n/g, ``)}<!--signatureEnd--></div>`
                }


                if (this.composeType != 0)  {
                    // Forward Or Reply
                    if (this.srcMail != null) {
                        // Create The Forward Mail Div Here
                        this.bodyModel = `${this.bodyModel.substring(0, this.bodyModel.indexOf('<!--signatureEnd-->'))}<!--signatureEnd-->${
                            `<p></p><div contenteditable="true"><br><div style="border: 1px solid lightgray; border-radius: 4px; padding: 4px"><span style="font-weight: bold; font-size: small; color: grey">On ${this.getLongDate(typeof this.srcMail.mailDate != 'undefined' ? this.srcMail.mailDate : this.srcMail.date)}, ${this.srcMail.from} wrote ${this.srcMail.to != null && this.srcMail.to != "" ? `to ${this.srcMail.to + (typeof this.srcMail.cc != 'undefined' ? ' ' + this.srcMail.cc : '')}:` : ':'}</span></div><p></p>`}
                    ${this.srcMailBody.replace(/\n|\r/g, ``).replace(/<!--signatureEnd-->/g, '').replace(/<!--signatureStart-->/g, '')}</div>
                    </div>`
                    } else {
                        // Add An Error Message Here
                        this.bodyModel = `${this.bodyModel.substring(0, this.bodyModel.indexOf('<!--signatureEnd-->'))}<!--signatureEnd--><div>ERROR WITH REPLY MAIL</div></div>`
                    }
                }
            }
        }
    }
</script>

<style scoped>
    .upload-btn-wrapper {
        position: relative;
        overflow: hidden;
        display: inline-block;
    }

    .btn {
        border: 2px solid gray;
        color: gray;
        background-color: white;
        padding: 8px 20px;
        border-radius: 8px;
        font-size: 20px;
        font-weight: bold;
    }

    .upload-btn-wrapper input[type=file] {
        font-size: 100px;
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
    }
</style>
