<template>
    <v-card class="fill-height" style="overflow-y: auto;">
        <v-layout column fill-height class="scrollable" style="overflow-x: hidden">
            <v-flex shrink>
                <v-card-title class="white--text pa-0 ma-0 title-color">
                    <div class="pa-2">Linked Users</div>
                </v-card-title>
            </v-flex>
            <v-flex shrink class="px-2" v-if="selectedSig.globalDefault === 0">
                <v-layout row align-center>
<!--Add Users-->
                    <v-flex xs8 class="pr-2">
                        <v-btn
                                small
                                outline
                                class="ma-0"
                                color="secondary"
                                block
                                slot="activator"
                                @click="showAddUsers = true"
                                :loading="addingUsers"
                                :disabled="addingUsers"
                        >
                            <v-icon left>add</v-icon>
                            Add Users
                        </v-btn>
                        <app-input-dlg
                                v-if="showAddUsers"
                                :show-dialog="showAddUsers"
                                title="Select users to add"
                                :cancel-button="true"
                                :show-input-box="false"
                                @dismiss="showAddUsers = false"
                                :body-padding="false"
                        >
                            <template slot="body">
                                <div class="pa-2 scrollable" style="max-height: 400px">
                                    <v-autocomplete
                                            dense
                                            :items="availableUsers"
                                            item-text="fullDescription"
                                            item-value="id"
                                            label="Select Users"
                                            hide-details
                                            single-line
                                            flat
                                            v-model="addUsers"
                                            multiple
                                            check
                                            class="pa-0 ma-0"
                                            :hide-selected="true"
                                            :loading="addingUsers"
                                            chips
                                            small-chips
                                    >
                                    </v-autocomplete>
                                </div>
                            </template>
                            <template slot="actions">
                                <v-btn
                                        small
                                        color="secondary"
                                        :disabled="(Array.isArray(addUsers) ? addUsers.length <= 0 : true) || addingUsers"
                                        :loading="addingUsers"
                                        @click="addUsersToSignature()"
                                >
                                    <v-icon>add</v-icon>
                                    Add Users
                                </v-btn>
                            </template>
                        </app-input-dlg>
                    </v-flex>
<!--Filter Users                    -->
                    <v-flex xs12 class="pr-2 py-1">
                        <v-text-field
                                label="Filter Users"
                                single-line
                                clearable
                                prepend-icon="search"
                                color="primary"
                                flat
                                :hide-details="true"
                                v-model="filterString"
                                class="pa-0 ma-0"
                                ref="myField"
                        />
                    </v-flex>
<!--Multi Select Options                    -->
                    <v-flex shrink class="pl-2">
                        <v-layout row align-center justify-space-between>

                            <v-flex shrink class="pr-2">
                                <v-tooltip top>
                                    <v-btn
                                            slot="activator"
                                            small
                                            class="pa-0 ma-0 px-2"
                                            outline
                                            color="blue"
                                            @click="allMembersSelected ? selectedUsers = [] : selectedUsers = filteredUsers.map(obj => {return obj.id})"
                                    >
                                        <v-icon left small>{{allMembersSelected ? 'check_box' :
                                            'check_box_outline_blank'}}
                                        </v-icon>
                                      {{ 0 >= (selectedUsers?.length || 0) ? 'No User Selected' : `${selectedUsers.length} User${selectedUsers.length > 1 ? 's' : ''} Selected` }}
                                    </v-btn>
                                    {{allMembersSelected ? 'Clear Selection' : 'Select All Users'}}
                                </v-tooltip>
                            </v-flex>

                            <v-flex shrink class="pr-2">
                                <v-btn
                                        small
                                        class="pa-0 ma-0 px-2"
                                        outline
                                        color="green"
                                        :disabled="selectedUsers.length <= 0 || modifyUsers.length > 0"
                                        :loading="modifyUsers.length > 0"
                                        @click="setDefaultSignature(selectedUsers, selectedSig.id)"
                                >
                                    <v-icon left small>check_circle</v-icon>
                                    Set Default
                                </v-btn>
                            </v-flex>
                            <v-flex shrink>
                                <v-btn
                                        small
                                        class="pa-0 ma-0 px-2"
                                        outline
                                        color="red"
                                        :disabled="selectedUsers.length <= 0 || modifyUsers.length > 0"
                                        :loading="modifyUsers.length > 0"
                                        @click="removeUsers(selectedUsers)"
                                >
                                    <v-icon left small>delete</v-icon>
                                    Remove Users
                                </v-btn>
                            </v-flex>
                        </v-layout>

                    </v-flex>
                </v-layout>
            </v-flex>
            <v-divider></v-divider>
<!--Users-->
            <v-flex xs12 class="scrollable" style="overflow-x: hidden">
                <div class="greyType--text body-2 text-xs-center pa-2" v-if="selectedSig.globalDefault === 1">
                    *Global Default - All Users Have Access, Default Signature if users default signature is not set
                </div>
                <template v-else>
                    <v-layout row wrap style="overflow-x: hidden" class="pa-1">
                        <v-flex
                                v-for="(user, i) in filteredUsers"
                                lg4 xs6
                                :key="user.id"
                                class="pa-1"
                                style="overflow-x: hidden"
                        >
                            <v-card
                                    style="overflow-x: hidden; cursor: pointer"
                                    :class="selectedUsers.includes(user.id) ? 'selected-item' : 'page-back'"
                                    :elevation="3"
                            >
                                <v-layout row align-center class="hide-parent">
                                    <v-flex class="pl-2">
                                        <v-btn
                                                :loading="modifyUsers.includes(user.id)"
                                                icon
                                                flat
                                                small
                                                :color="selectedUsers.includes(user.id) ? 'blue' : 'grey'"
                                                class="pa-0 ma-0"
                                                @click="selectedUsers.includes(user.id) ? selectedUsers = selectedUsers.filter(usr => usr !== user.id) : selectedUsers.push(user.id)"
                                        >
                                            <v-icon>{{selectedUsers.includes(user.id) ? 'check_box' : 'check_box_outline_blank'}}</v-icon>
                                        </v-btn>
                                    </v-flex>
                                    <v-flex xs12 class="text-truncate py-1 px-2" @click="selectedUsers = [user.id]">
                                        <div class="text-truncate" :class="selectedUsers.includes(user.id) ? 'body-2' : ''">
                                            {{user.fullName}}
                                        </div>
                                        <div class="caption greyType--text">
                                            {{user.username}}
                                        </div>
                                    </v-flex>
                                    <v-flex shrink class="pr-2 hide-child" @click="selectedUsers = [user.id]">
                                        <v-tooltip top>
                                            <v-btn
                                                    slot="activator"
                                                    icon
                                                    flat
                                                    small
                                                    color="red"
                                                    class="pa-0 ma-0"
                                                    @click="removeUsers([user.id])"
                                            >
                                                <v-icon>delete</v-icon>
                                            </v-btn>
                                            Remove User
                                        </v-tooltip>
                                    </v-flex>
                                    <v-flex shrink class="pr-2" @click="selectedUsers = [user.id]">
                                        <v-tooltip top>
                                            <v-btn
                                                    slot="activator"
                                                    icon
                                                    flat
                                                    small
                                                    :color="user.defaultSignature === selectedSig.id ? 'green' : 'grey'"
                                                    class="pa-0 ma-0"
                                                    @click="setDefaultSignature([user.id], user.defaultSignature === selectedSig.id ? 0 : selectedSig.id)"
                                            >
                                                <v-icon>{{user.defaultSignature === selectedSig.id ? 'check_circle' : 'check_circle_outline'}}</v-icon>
                                            </v-btn>
                                            Set/Remove Default Signature
                                        </v-tooltip>
                                    </v-flex>
                                </v-layout>
                            </v-card>
                        </v-flex>

                    </v-layout>
                    <app-input-dlg
                            v-if="showRemoveUsersConfirm"
                            :show-dialog="showRemoveUsersConfirm"
                            title="Confirm"
                            :body="`Confirm the removal of ${modifyUsers.length > 1 ? `${modifyUsers.length} ` : ''}selected user${modifyUsers.length > 1 ? 's' : ''}`"
                            :cancel-button="true"
                            :show-input-box="false"
                            :buttons="['Remove Users']"
                            :call-backs="[ removeSignatureFromUsers ]"
                            @dismiss="showRemoveUsersConfirm = false"
                    />
                </template>
            </v-flex>
        </v-layout>
    </v-card>
</template>

<script>
    import {codeBaseMixin} from "../../../codeBaseMixin";
    import InputDlg from "../../../components/General/InputDlg";
    import {blankString, arrPopulated, arrSplice} from "../../../codeFunctions";

    export default {
        components: {appInputDlg: InputDlg},
        mixins: [codeBaseMixin],
        props: {
            selectedSig: Object,
            users: Array,
            userInfo: Object,
        },
        data() {
            return {
                selectedUsers: [],
                filterString: null,
                showAddUsers: false,
                addingUsers: false,
                addUsers: [],
                modifyUsers: [],
                showRemoveUsersConfirm: false
            }
        },
        watch: {
            selectedSig: {
                immediate: true,
                handler(val) {
                    this.selectedUsers = [];
                    this.modifyUsers = [];
                    this.filterString = null;
                }
            },
            showAddUsers(val) {
                if (val) {this.addUsers = []}
            },
            showRemoveUsersConfirm(val) {
                if (val === false) {
                    this.modifyUsers = []
                }
            }
        },
        methods: {

            removeUsers(users) {
                this.modifyUsers = users;
                this.showRemoveUsersConfirm = true
            },

            removeSignatureFromUsers() {
                let users = [];
                Object.assign(users, this.modifyUsers);
                this.showRemoveUsersConfirm = false;
                this.fetch({method: 'DELETE', url: `/sigMan/removeSignatureMembers/${this.userInfo.entityID}/${this.selectedSig.id}`, body: {users: this.modifyUsers}})
                    .then(() => {
                        this.users.forEach(user => {
                            if (users.includes(user.id) && user.defaultSignature === this.selectedSig.id) {
                                user.defaultSignature = 0
                            }
                        });
                        arrSplice(this.selectedSig.members, (member) => users.includes(member));
                        this.selectedUsers = [];
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e);
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
                        this.modifyUsers = [];
                    })
            },

            setDefaultSignature(users, val) {
                this.modifyUsers = users;
                this.fetch({method: 'PATCH', url: `/sigMan/setDefaultSignature/${this.userInfo.entityID}/${val}`, body: {users: users}})
                    .then(() => {
                        this.users.forEach(user => {
                            if (users.includes(user.id)) {
                                user.defaultSignature = val
                            }
                        });
                        this.modifyUsers = [];
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e);
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
                        this.modifyUsers = [];
                    })
            },

            addUsersToSignature() {
                this.addingUsers = true;
                this.fetch({method: 'POST', url: `/sigMan/addUsersToSignature/${this.userInfo.entityID}/${this.selectedSig.id}`, body: {users: this.addUsers}})
                    .then(() => {
                        this.showAddUsers = false;
                        this.addUsers.forEach(userID => {this.selectedSig.members.push(userID)});
                        this.addUsers = [];
                        this.addingUsers = false;
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e);
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
                        this.addingUsers = false;
                    })
            }
        },
        computed: {
            allMembersSelected() {
                return this.memberCount === this.selectedUsers.length;
            },

            memberCount() {
                return this.selectedSig.members.filter(obj => obj != null).length
            },

            filteredUsers() {
                return this.users
                    .filter(user => this.selectedSig.members.includes(user.id))
                    .map(obj => {
                        let oUser = {};
                        Object.assign(oUser, obj);
                        oUser.fullName = `${!blankString(obj.name) ? `${obj.name} ` : 'User '}${!blankString(obj.surname) ? obj.surname : ''}`;
                        oUser.filterStr = `${obj.name} ${obj.surname} ${obj.username}`;
                        return oUser
                    })
                    .filter(obj => blankString(this.filterString) ? true : obj.filterStr.toLowerCase().includes(this.filterString.toLowerCase()))
                    .sort((a,b) => (a['name']+a['surname']) < (b['name']+b['surname']) ? -1 : 1);
            },

            availableUsers() {
                return this.users
                    .filter(user => !this.selectedSig.members.includes(user.id))
                    .map(obj => {
                        let oUser = {};
                        Object.assign(oUser, obj);
                        oUser.fullName = `${!blankString(obj.name) ? `${obj.name} ` : ''}${!blankString(obj.surname) ? obj.surname : ''}`;
                        oUser.fullDescription = `${!blankString(obj.name) ? `${obj.name} ` : ''}${!blankString(obj.surname) ? `${obj.surname} ` : ''}${obj.username}`;
                        return oUser
                    })
                    .sort((a,b) => (a['name']+a['surname']) < (b['name']+b['surname']) ? -1 : 1);
            }
        }
    }
</script>

<style scoped>

</style>
