<template>
  <v-dialog
    v-model="showDialog"
    fullscreen
    hide-overlay
    persistent
    transition="dialog-bottom-transition"
    style="overflow-y: auto"
  >
    <v-card flat class="fill-height" style="overflow-y: auto; overflow-x: hidden" v-if="showDialog">
      <v-layout column fill-height style="overflow-y: auto; overflow-x: hidden">
        <!--Card Title-->
        <v-flex shrink>
          <v-card-title class="py-2 pr-2 title-color">
            <img src="../../../public/datasets.svg" width="42px" class="pr-2"/>
            <span
              class="title font-weight-light white--text">{{ selectedDataset != null ? selectedDataset.description : 'Dataset Manager' }}</span>
            <v-spacer></v-spacer>

            <v-btn
              v-if="selectedDataset != null"
              color="white"
              class="mx-2 py-0 my-0"
              @click="showApiAccess = true"
              flat
            >
              <v-icon left>api</v-icon>
              API Access
            </v-btn>

            <v-btn flat icon color="white" class="pa-0 ma-0" @click="$emit('dismiss')">
              <v-icon>close</v-icon>
            </v-btn>
          </v-card-title>
        </v-flex>
        <v-flex shrink>
          <v-divider></v-divider>
        </v-flex>
        <!--Loading-->
        <v-flex v-if="loadingDatasets" class="pa-2 text-xs-center">
          <v-progress-circular
            :size="40"
            :width="5"
            color="primary"
            indeterminate
          />
        </v-flex>
        <!--Datasets Loaded-->
        <template v-else>

          <v-flex xs12 class="scrollable">
            <!--Dataset Options-->
            <v-layout row fill-height class="scrollable">
              <!--Select Dataset-->
              <v-flex xs4 class="px-1 py-1 scrollable">
                <app-data-set-select-bar
                  :user-info="userInfo"
                  :data-sets="dataSets"
                  :filter-string="barFilterString"
                  :user-groups="userGroups"
                  :folder-groups="folderGroups"
                  v-model="selectedDataset"
                  @searchUpdated="barFilterString = $event"
                  @patch="updateDataSet($event)"
                  @post="addDataSet($event)"
                />
              </v-flex>
              <!--Dataset Detail-->
              <v-flex xs10 v-if="selectedDataset != null" class="pa-1">
                <v-card class="fill-height" style="overflow-y: auto; overflow-x: hidden">
                  <v-layout column fill-height class="scrollable" style="overflow-x: hidden">
                    <!--                                        <v-flex shrink>-->
                    <!--                                            <v-card-title class="white&#45;&#45;text pa-0 ma-0 primary">-->
                    <!--                                                <div class="pa-2">{{selectedDataset.description}}</div>-->
                    <!--                                            </v-card-title>-->
                    <!--                                        </v-flex>-->
                    <v-flex shrink class="title-color">
                      <v-tabs
                        v-model="datasetViewOption"
                        color="primary"
                        slider-color="secondary"
                        grow
                        show-arrows
                        class="smallTab pt-1"
                      >
                        <v-tab class="white--text smallTab pa-2 body-1 font-weight-regular">Fields</v-tab>
                        <v-tab class="white--text smallTab pa-2 body-1 font-weight-regular">Actions</v-tab>
                        <v-tab class="white--text smallTab pa-2 body-1 font-weight-regular">Data</v-tab>
                      </v-tabs>
                    </v-flex>
                    <!--DataSet Fields-->
                    <v-flex xs12 class="scrollable" v-show="datasetViewOption == 0">
                      <app-data-set-fields
                        :user-groups="userGroups"
                        :user-info="userInfo"
                        :data-set="selectedDataset"
                        :select-lists="selectLists"
                        @patch_field="updateField($event)"
                      />
                    </v-flex>
                    <!--Dataset Actions-->
                    <v-flex xs12 fill-height class="scrollable" v-if="datasetViewOption == 1">
                      <app-field-set-admin-rules-detail
                        :user-info="userInfo"
                        :selected-set="selectedDataset"
                      />
                    </v-flex>
                    <!--Dataset Data-->
                    <v-flex xs12 fill-height class="scrollable" v-if="datasetViewOption == 2">
                      <dataset-data
                        :selected-dataset="selectedDataset"
                      />
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout>
          </v-flex>
        </template>
      </v-layout>
    </v-card>


    <app-input-dlg
      :show-dialog="showApiAccess"
      title="API Access"
      :cancel-button="true"
      :show-input-box="false"
      @dismiss="showApiAccess = false"
      :body-padding="false"
      :width="900"
    >
      <template slot="body">
        <div v-if="loadingApiEndpoint" class="c-d-flex c-align-center c-justify-center pa-5">
          <v-progress-circular
            :size="40"
            :width="5"
            color="primary"
            indeterminate
          />
        </div>
        <template v-else>
          <v-data-table
            :headers="endpointHeaders"
            :items="apiEndpoints"
          >
            <template v-slot:items="props">
              <td>
                <v-tooltip top>
                  <template #activator="{on}">
                    <div @click="copyLink(`${serverAddress}/datasetapi/${props.item.urlKey}`)" class="primary--text caption c-d-flex align-center" v-on="on" style="cursor:pointer;">
                      <v-icon left color="primary">link</v-icon>
                      <div class="c-flex-grow-1">
                        {{serverAddress}}/datasetapi/{{props.item.urlKey}}
                      </div>
                    </div>
                  </template>
                  Copy API Link
                </v-tooltip>


              </td>
              <td>{{ (usersRef[props.item.fkUser].name + ' ' + usersRef[props.item.fkUser].surname).trim() }}</td>
              <td>{{ props.item.format }}</td>
              <td>{{ props.item.lastPull ? format(new Date(props.item.lastPull), 'YYYY-MM-DD HH:mm:ss') : '-' }}</td>
              <td>
                <div class="c-d-flex c-align-center">
                  <v-tooltip top>
                    <template #activator="{on}">
                      <a :href="`${serverAddress}/datasetApi/${props.item.urlKey}`" target="_blank">
                        <v-btn
                          small
                          icon
                          flat
                          color="secondary"
                          v-on="on"
                        >
                          <v-icon>open_in_browser</v-icon>
                        </v-btn>
                      </a>
                    </template>
                    Test Link
                  </v-tooltip>
                  <v-tooltip top>
                    <template #activator="{on}">
                      <v-btn
                        small
                        icon
                        flat
                        color="error"
                        v-on="on"
                        @click="deleteEndpointId = props.item.id"
                      >
                        <v-icon>delete</v-icon>
                      </v-btn>
                    </template>
                    Delete Endpoint
                  </v-tooltip>
                </div>

              </td>
            </template>
          </v-data-table>
        </template>
      </template>
      <template #actions>
        <div class="c-d-flex c-align-center c-justify-space-between">
          <v-btn
            small
            color="secondary"
            @click="showCreateEndpoint = true"
          >
            Create New Endpoint
          </v-btn>
          <v-btn
            small
            color="primary"
            @click="showApiAccess = false"
          >
            Done
          </v-btn>
        </div>
      </template>
    </app-input-dlg>

    <app-input-dlg
      v-if="showCreateEndpoint"
      :show-dialog="showCreateEndpoint"
      title="Create New Report API Endpopint"
      :cancel-button="!loadingCreateApiEndPoint"
      :show-input-box="false"
      @dismiss="showCreateEndpoint = false"
      :width="500"
    >
      <template slot="body">
        <div>
          <v-autocomplete
            :items="['JSON', 'CSV']"
            label="Data Format"
            hide-details
            dense
            v-model="createApiEndpointFormat"
            :disabled="loadingCreateApiEndPoint"
          ></v-autocomplete>
        </div>
      </template>
      <template #actions>
        <div class="c-d-flex c-align-center c-justify-space-between">
          <v-btn
            small
            flat
            color="grey"
            @click="showCreateEndpoint = false"
            :disabled="loadingCreateApiEndPoint"
          >
            Cancel
          </v-btn>
          <v-btn
            small
            color="primary"
            @click="createApiEndPoint()"
            :disabled="loadingCreateApiEndPoint"
            :loading="loadingCreateApiEndPoint"
          >
            Create
          </v-btn>
        </div>
      </template>
    </app-input-dlg>


    <app-input-dlg
      v-if="deleteEndpointId"
      :show-dialog="!!deleteEndpointId"
      title="Delete Endpoint"
      :cancel-button="!loadingCreateApiEndPoint"
      :show-input-box="false"
      @dismiss="deleteEndpointId = null"
      :width="500"
    >
      <template slot="body">
        <div class="error--text">
          Are you sure that you would like to delete the endpoint. Please note the following action can't be undone
        </div>
      </template>
      <template #actions>
        <div class="c-d-flex c-align-center c-justify-space-between">
          <v-btn
            small
            flat
            color="grey"
            @click="deleteEndpointId = null"
            :disabled="deletingEndpoint"
          >
            Cancel
          </v-btn>
          <v-btn
            small
            color="error"
            @click="deleteApiEndpoint()"
            :disabled="deletingEndpoint"
            :loading="deletingEndpoint"
          >
            Confirm
          </v-btn>
        </div>
      </template>
    </app-input-dlg>

  </v-dialog>
</template>

<script>
import {codeBaseMixin} from "../../codeBaseMixin";
import DataSetSelectBar from "./DataSetSelect/DataSetSelectBar";
import DataSetFields from "./DataSetFields/DataSetFields";
import FieldSetAdminRulesDetail from "../FieldSetEditor/components/FieldSetAdminRulesDetail";
import DatasetData from "@/Admin/Data Set Manager/DatasetData/DatasetData";
import appInputDlg from "@/components/General/InputDlg.vue";
import format from "date-fns/format";
import {mapGetters} from "vuex";
import {arrSplice, copyText} from "@/codeFunctions";

export default {
  mixins: [codeBaseMixin],
  components: {
    appInputDlg,
    DatasetData,
    appFieldSetAdminRulesDetail: FieldSetAdminRulesDetail,
    appDataSetFields: DataSetFields,
    appDataSetSelectBar: DataSetSelectBar
  },
  props: {
    showDialog: Boolean,
    userInfo: Object
  },
  data() {
    return {
      loadingDatasets: false,
      selectedDataset: null,
      datasetViewOption: 0,
      dataSets: [],
      userGroups: [],
      users: [],
      selectLists: [],
      folderGroups: [],
      barFilterString: null,
      showApiAccess: false,

      apiEndpoints: [],
      loadingApiEndpoint: false,
      showCreateEndpoint: false,
      createApiEndpointFormat: 'JSON',
      loadingCreateApiEndPoint: false,
      endpointHeaders: [
        { text: 'Address', value: 'urlKey' },
        { text: 'User Security', value: 'fkUser' },
        { text: 'Format', value: 'format' },
        { text: 'Last Pull', value: 'lastPull' },
        { text: '', value: 'actions', width: 50, justify: "end", sortable: false},
      ],
      format,
      deleteEndpointId: null,
      deletingEndpoint: false,
    }
  },
  watch: {
    showDialog: {
      immediate: true,
      handler(shown) {
        if (shown) {
          //Reset Local Vars
          this.loadingDatasets = true;
          this.selectedDataset = null;
          this.datasetViewOption = 0;
          //Load Datasets
          this.fetch({method: 'GET', url: `/datasetmanager/startup/${this.userInfo.entityID}`})
            .then((data) => {
              this.dataSets = data.dataSets;
              this.userGroups = data.userGroups;
              this.selectLists = data.selectLists;
              this.folderGroups = data.folderGroups;
              // this.users = data.users;
              this.loadingDatasets = false;
            })
            .catch((e) => {
              // eslint-disable-next-line
              console.log(e)
              this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
              this.loadingDatasets = false;
            })
        }
      }
    },
    showApiAccess(val) {
      if (val)
        this.refreshApiEndpoints()
    },
  },
  methods: {

    async deleteApiEndpoint() {
      try {
        this.deletingEndpoint = true;
        await this.$newReq("POST", `fieldsets/datasetAPI/deleteEndpoint/${this.userInfo.entityID}`, {
          endpointId: this.deleteEndpointId,
        })
        arrSplice(this.apiEndpoints, ep => ep.id === this.deleteEndpointId)
        this.deleteEndpointId = null;
      } catch (e) {
        console.log(e)
        this.$snack.networkError()
      } finally {
        this.deletingEndpoint = false;
      }
    },

    async createApiEndPoint() {
      try {
        this.loadingCreateApiEndPoint = true;
        const newEndpoint = await this.$newReq("POST", `fieldsets/datasetAPI/createEndpoint/${this.userInfo.entityID}`, {
          fkDataset: this.selectedDataset.id,
          userId: this.userInfo.userID,
          format: this.createApiEndpointFormat
        })
        console.log({newEndpoint})
        this.apiEndpoints.push(newEndpoint)
        this.showCreateEndpoint = false;
      } catch (e) {
        console.log(e)
        this.$snack.networkError()
      } finally {
        this.loadingCreateApiEndPoint = false;
      }
    },

    copyLink(link) {
      copyText(document, link)
      this.$snack.info('Link Copied')
    },

    async refreshApiEndpoints() {
      try {
        this.loadingApiEndpoint = true;
        this.apiEndpoints = await this.$newReq("GET", `fieldsets/datasetAPI/listEndPoints/${this.userInfo.entityID}/${this.selectedDataset.id}`)
      } catch (e) {
        console.log(e)
        this.$snack.networkError()
      } finally {
        this.loadingApiEndpoint = false;
      }
    },

    updateField(field) {
      this.dataSets.forEach((set) => {
        if (set.id === field.fk_field_set) {
          if (!Array.isArray(set.fields)) {
            set.fields = [];
          }
          set.fields = set.fields.filter(setField => setField.id != field.id);
          set.fields.push(field)
        }
      })
    },
    updateDataSet(dataSet) {
      this.dataSets.forEach((set, n) => {
        if (set.id === dataSet.id) {
          this.dataSets.splice(n, 1, dataSet);
          this.selectedDataset = dataSet;
        }
      })
    },
    addDataSet(dataSet) {
      this.dataSets.push(dataSet);
      this.selectedDataSet = dataSet;
    },
  },
  computed: {
    ...mapGetters(["usersRef", "userInfo/userInfo"])
  }
}
</script>

<style scoped>

</style>
