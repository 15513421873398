<template>

    <v-layout column fill-height class="scrollable">

<!--Title-->
        <v-flex shrink class="pa-1 px-2 title-color white--text centered-row">
            <div style="width: 100%" class="subheading">
                {{createCalendarGroup ? 'Create Calendar Group' : `Edit ${editCalendarGroup.description}`}}
            </div>
            <div v-if="createCalendarGroup">
                <v-btn
                        class="pa-0 ma-0"
                        flat
                        icon
                        small
                        color="white"
                        @click="$emit('dismiss')"
                >
                    <v-icon>close</v-icon>
                </v-btn>
            </div>
        </v-flex>
<!--Options-->
        <v-flex shrink class="centered-row">
            <div style="width: 30%" class="px-2">
                <v-text-field
                        label="Calendar Group Description"
                        color="primary"
                        v-model="calendarGroup.description"
                        :rules="[validationRules.required]"
                        @input="changesMade = true"
                        :error-messages="descriptionError"
                ></v-text-field>
            </div>
            <div style="width: 25%" class="px-2">
                    <v-autocomplete
                            label="Group Color"
                            v-model="calendarGroup.color"
                            :items="colors"
                            item-text="description"
                            item-value="value"
                            dense
                            placeholder="Default Blue"
                            @input="changesMade = true"
                    >
                        <template v-slot:selection="data">
                            <dense-chip :label="data.item.description" :color="data.item.value" icon="brush"/>
                        </template>
                        <template v-slot:item="data">
                            <dense-chip :label="data.item.description" :color="data.item.value" icon="brush"/>
                        </template>
                    </v-autocomplete>
            </div>
            <div style="width: 15%" class="px-2">
                <v-switch
                        v-model="calendarGroup.allUsersEditors"
                        label="All users are editors"
                        @change="changesMade = true"
                        color="primary"
                ></v-switch>
            </div>
            <div style="width: 15%" class="px-2">
                <v-switch
                        v-model="calendarGroup.allUsersSubscribed"
                        label="Force subscription"
                        @change="changesMade = true"
                        color="primary"
                ></v-switch>
            </div>
            <div style="width: 15%" class="px-2">
                <v-switch
                        v-model="calendarGroup.onlyEditorsCanPublish"
                        label="Only editors can publish"
                        @change="changesMade = true"
                        color="primary"
                ></v-switch>
            </div>
        </v-flex>
        <v-divider></v-divider>
        <v-flex xs12 class="of-y centered-row">
            <!--Users With Access-->
            <div style="width: 100%" class="of-y-f">
                <multi-select-list
                        :show-action-buttons="false"
                        :show-check-boxes="true"
                        :multiple="true"
                        headline-key="fullName"
                        sub-line-key="username"
                        item-key="userID"
                        :return-object="false"
                        v-model="calendarGroup.users"
                        :show-filter="true"
                        :items="users"
                        filter-label="Filter Users"
                        :dark="true"
                        @input="changesMade = true"
                        :cutom-sort-fn="userSort"
                >
                    <div class="px-2 py-1 body-2 white--text title-color" slot="header">
                        Users With Access
                    </div>

                    <template v-slot:headline="{value}">
                        <div :class="value.disabled ? 'grey--text' : ''">
                            {{value.fullName}}{{value.disabled ? ' (Disabled)' : ''}}
                        </div>
                    </template>

                </multi-select-list>
            </div>
            <v-divider :vertical="true"></v-divider>
            <!--Groups With Access-->
            <div style="width: 100%" class="of-y-f">
                <multi-select-list
                        :show-action-buttons="false"
                        :show-check-boxes="true"
                        :multiple="true"
                        headline-key="description"
                        sub-line-key="memberNames"
                        item-key="id"
                        :return-object="false"
                        v-model="calendarGroup.groups"
                        :show-filter="true"
                        :items="userGroups"
                        filter-label="Filter Groups"
                        :dark="true"
                        @input="changesMade = true"
                        :cutom-sort-fn="(a, b) => {
                            if (calendarGroup.groups.includes(a.id) && calendarGroup.groups.includes(b.id)) return a.description > b.description ? 1 : -1
                if (calendarGroup.groups.includes(a.id)) return -1
                if (calendarGroup.groups.includes(b.id)) return 1
                return a.description > b.description ? 1 : -1
                        }"
                >
                    <div class="px-2 py-1 body-2 white--text title-color" slot="header">
                        Groups With Access
                    </div>
                </multi-select-list>
            </div>
            <v-divider :vertical="true"></v-divider>

            <div style="width: 100%" class="of-y-f" v-if="!calendarGroup.allUsersEditors">
                <multi-select-list
                        :show-action-buttons="false"
                        :show-check-boxes="true"
                        :multiple="true"
                        headline-key="fullName"
                        sub-line-key="username"
                        item-key="userID"
                        :return-object="false"
                        v-model="calendarGroup.editors"
                        :show-filter="true"
                        :items="users"
                        filter-label="Filter Users"
                        :dark="true"
                        @input="changesMade = true"
                        :cutom-sort-fn="editorSort"
                >
                    <div class="px-2 py-1 body-2 white--text title-color" slot="header">
                        Group Editors
                    </div>

                    <template v-slot:headline="{value}">
                        <div :class="value.disabled ? 'grey--text' : ''">
                            {{value.fullName}}{{value.disabled ? ' (Disabled)' : ''}}
                        </div>
                    </template>
                </multi-select-list>
            </div>
            <v-divider :vertical="true"></v-divider>
        </v-flex>
        <div class="pa-2" style="position: absolute; bottom: 0; right: 0">
            <v-btn
                    fab
                    color="secondary"
                    :disabled="!changesMade || loading"
                    :loading="loading"
                    @click="validate"
            >
                <v-icon>save</v-icon>
            </v-btn>
        </div>
    </v-layout>
</template>

<script>
import {mapGetters} from 'vuex'
import {validationRules} from "@/codeFunctions";
import MultiSelectList from "@/components/Multi Select List/MultiSelectList";
import {waitForEvent} from "@/feedSocket";
import DenseChip from "@/components/General/DenseChip";

export default {
    components: {DenseChip, MultiSelectList},
    props: {
        editCalendarGroup: Object
    },
    data() {
        return {
            calendarGroup: null,
            validationRules: validationRules,
            changesMade: false,
            loading: false,
            descriptionError: null,
            colors: [
                {description: "Red", value: "red"},
                {description: "Pink", value: "pink"},
                {description: "Purple", value: "purple"},
                {description: "Deep Purple", value: "deep-purple"},
                {description: "Indigo", value: "indigo"},
                {description: "Blue", value: "blue"},
                {description: "Light Blue", value: "light-blue"},
                {description: "Cyan", value: "cyan"},
                {description: "Teal", value: "teal"},
                {description: "Green", value: "green"},
                {description: "Light Green", value: "light-green"},
                {description: "Lime", value: "lime"},
                {description: "Yellow", value: "yellow"},
                {description: "Amber", value: "amber"},
                {description: "Orange", value: "orange"},
                {description: "Deep Orange", value: "deep-orange"},
                {description: "Brown", value: "brown"},
                {description: "Grey", value: "grey"},
            ],
        }
    },
    watch: {
        editCalendarGroup: {
            immediate: true,
            handler(val) {
                this.changesMade = false;
                if (!val) {
                    this.calendarGroup = new this.CalendarGroupClass({})
                } else {
                    this.calendarGroup = new this.CalendarGroupClass(JSON.parse(JSON.stringify(val)))
                }
            }
        },
        "calendarGroup.description"() {
            this.descriptionError = null;
        }
    },
    methods: {
        userSort(a,b) {

            if (this.calendarGroup.users.includes(a.userID) && this.calendarGroup.users.includes(b.userID))
                return  a.disabled === b.disabled ? (a.fullName > b.fullName ? 1 : -1) : (a.disabled ? 1 : -1)

            if (this.calendarGroup.users.includes(a.userID))
                return -1

            if (this.calendarGroup.users.includes(b.userID))
                return 1

            return a.disabled === b.disabled ? (a.fullName > b.fullName ? 1 : -1) : (a.disabled ? 1 : -1)
        },

        editorSort(a,b) {

            if (this.calendarGroup.editors.includes(a.userID) && this.calendarGroup.editors.includes(b.userID))
                return  a.disabled === b.disabled ? (a.fullName > b.fullName ? 1 : -1) : (a.disabled ? 1 : -1)

            if (this.calendarGroup.editors.includes(a.userID))
                return -1

            if (this.calendarGroup.editors.includes(b.userID))
                return 1

            return a.disabled === b.disabled ? (a.fullName > b.fullName ? 1 : -1) : (a.disabled ? 1 : -1)
        },

        async validate() {
            if (!this.calendarGroup.description) {
                this.$snack.error('A Group description is required')
                return false
            }

            if (this.calendarGroups.filter(obj => obj.id !== this.calendarGroup.id).map(obj => {return obj.description.toLowerCase()}).includes(this.calendarGroup.description.toLowerCase())) {
                this.$snack.error('Duplicate Group Name')
                this.descriptionError = ['Duplicate Group Name']
                return false
            }

            // If Valid Then
            this.loading = true;
            try {
                let updateID = await this.calendarGroup.update();
                let updatedGroup = (await waitForEvent(updateID)).groups.pop();
                this.$emit('input', updatedGroup)
            } catch (e) {
                this.$snack.networkError();
                console.log(e)
            } finally {
                this.loading = false
            }
        }
    },
    computed: {
        ...mapGetters(['users', 'userGroups']),
        ...mapGetters('userInfo', ['userInfo']),
        ...mapGetters('cal', ['calendarGroups', 'calendarGroupsRef', 'CalendarGroupClass']),
        createCalendarGroup() {
            return !this.calendarGroup.id
        }
    },
}
</script>

<style scoped>

</style>