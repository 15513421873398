<template>
    <div @click="$emit('selectField', field)">
        <v-layout @contextmenu="show" row align-center class=" pl-1 pr-0" :class="borderClass" >
            <template v-if="!previewRules && !recycleBin">
                <v-flex shrink v-if="!showSelectFields">
                    <v-icon small style="cursor: move" class="handle">drag_indicator</v-icon>
                </v-flex>
                <v-flex shrink v-else>
                    <v-btn
                            small
                            flat
                            icon
                            :color="inSelectedFieldIDs ? 'secondary' : 'grey'"
                            style="width: 18px; height: 18px"
                            class="pa-0 ma-0"
                            @click="!inSelectedFieldIDs ? $emit('addToSelection', field) : $emit('removeFromSelection', field)"
                    >
                        <v-icon small>{{inSelectedFieldIDs ? 'check_box' : 'check_box_outline_blank'}}</v-icon>
                    </v-btn>
                </v-flex>
            </template>

            <v-flex xs12>
                <div
                        @drag="!recycleBin ? $event.preventDefault() : ''"
                        @dragstart="!recycleBin ? setDataTransfer($event) : ''"
                        @dragend="handleDragEnd"
                        @dragover="!recycleBin ? $event.preventDefault() : ''"
                        @drop="!recycleBin ? droppedEvent($event) : ''"
                        ref="dragArea"
                >
                    <v-layout row align-center>
                        <v-flex
                                xs12 class="caption pa-1"
                                :class="fieldVisibleClass"
                                @click="() => {}"
                        >
                            <div>{{field.description}}</div>
                            <div v-if="!previewRules" class="font-weight-light" :class="field.linkLookup != null ? 'secondary--text' : fieldVisibleClass != '' ? fieldVisibleClass : 'primary--text'" style="width: 100%;">
                                <v-icon small color="primary" class="pa-0 ma-0" v-if="field.securityLookup != null && ((typeof field.securityLookup.editableBy != 'undefined' && field.securityLookup.editableBy.length > 0) || (typeof field.securityLookup.shownTo != 'undefined' && field.securityLookup.shownTo.length > 0))">security</v-icon>
                                <v-icon small color="secondary" class="pa-0 ma-0" v-if="field.linkLookup != null">link</v-icon>
                                {{fieldType}}
                            </div>
                            <div v-if="previewRules && previewEditFields.includes(field.id)">
                                <template v-if="[1].includes(field.fieldType)">
                                    <v-text-field
                                            label="Value"
                                            single-line
                                            color="primary"
                                            hide-details
                                            style="width: 100%"
                                            class="pa-0 ma-0"
                                            v-model="rulePreviewValue"
                                    ></v-text-field>
                                </template>
                                <template v-if="[2].includes(field.fieldType)">
                                    <app-date-picker-with-max
                                            v-model="rulePreviewValue"
                                    />
                                </template>
                                <template v-if="[3,4].includes(field.fieldType)">
                                    <v-text-field
                                            label="Value"
                                            single-line
                                            color="primary"
                                            hide-details
                                            style="width: 100%"
                                            class="pa-0 ma-0"
                                            type="number"
                                            v-model="rulePreviewValue"
                                    ></v-text-field>
                                </template>
                                <template v-if="[5].includes(field.fieldType)">
                                    <v-autocomplete
                                            dense
                                            v-model="rulePreviewValue"
                                            :items="rulePreviewSelectListOptions"
                                            label="Value"
                                            hide-details
                                            single-line
                                            color="primary"
                                            class="pa-0 ma-0"
                                    ></v-autocomplete>
                                </template>
                                <template v-if="![1,2,3,4,5].includes(field.fieldType)">
                                    <div class="text-xs-center">
                                        <v-btn
                                                color="primary"
                                                small
                                                block
                                                flat
                                                @click="rulePreviewValue == null ? rulePreviewValue = '1' : rulePreviewValue = null"
                                        >
                                            <v-icon left>{{rulePreviewValue == null ? 'check' : 'clear'}}</v-icon>
                                            {{rulePreviewValue == null ? 'Complete' : 'Clear'}}
                                        </v-btn>
                                    </div>
                                </template>
                            </div>
                        </v-flex>

                        <v-flex shrink>
<!--Show in Conditions-->
                            <div v-if="showConditionsIn != null">
                                <v-menu offset-y left lazy>
                                    <template #activator="{ on: menu }">
                                        <v-tooltip top>
                                            <template #activator="{ on: tooltip }">

                                                <div
                                                    style="font-size: 0.8em"
                                                    class="pa-0 ma-0 blue small-icon"
                                                    v-on="{ ...tooltip, ...menu }"
                                                >
                                                    <div style="padding-top: 1px">{{showConditionsIn.length}}</div>
                                                </div>

                                            </template>
                                            <span>{{`Field Used as a Condition Field in ${showConditionsIn.length} Rule/s. (Click To View)`}}</span>
                                        </v-tooltip>
                                    </template>
                                  <v-card class="page-back">
                                    <v-list dense style="max-height: 300px; overflow-y: auto">
                                      <v-subheader class="primary--text">Condition In Rules</v-subheader>
                                      <v-divider></v-divider>
                                      <v-list-tile
                                          v-for="(item, index) in showConditionsIn"
                                          :key="index"
                                          @click="scrollToRuleFn(item.id)"
                                      >
                                        <v-list-tile-action>
                                          <v-tooltip top>
                                            <v-btn
                                                small
                                                icon
                                                flat
                                                left
                                                class="py-1 my-0"
                                                slot="activator"
                                                color="primary"
                                                @click="$emit('editRuleFromID', item.id)"
                                            >
                                              <v-icon>edit</v-icon>
                                            </v-btn>
                                            <span>Edit Rule</span>
                                          </v-tooltip>
                                        </v-list-tile-action>
                                        <v-list-tile-title>
                                          {{ item.description != null ? item.description : `Rule ${item.id}` }}
                                        </v-list-tile-title>
                                      </v-list-tile>
                                    </v-list>
                                  </v-card>
                                </v-menu>
                            </div>
<!--Show Field-->
                            <div v-if="showShownIn != null">
                                <v-menu offset-y left lazy>
                                    <template #activator="{ on: menu }">
                                        <v-tooltip top>
                                            <template #activator="{ on: tooltip }">

                                                <div
                                                    style="font-size: 0.8em"
                                                    class="pa-0 ma-0 green small-icon"
                                                    v-on="{ ...tooltip, ...menu }"
                                                >
                                                    <div style="padding-top: 1px">{{ showShownIn.length }}</div>
                                                </div>

                                            </template>
                                            <span>{{`Field Used as a Show Field in ${showShownIn.length} Rule/s. (Click To View)`}}</span>
                                        </v-tooltip>
                                    </template>
                                  <v-card class="page-back">
                                    <v-list dense style="max-height: 300px; overflow-y: auto">
                                      <v-subheader class="primary--text">Shown In Rules</v-subheader>
                                      <v-divider></v-divider>
                                      <v-list-tile
                                          v-for="(item, index) in showShownIn"
                                          :key="index"
                                          @click="scrollToRuleFn(item.id)"
                                      >
                                        <v-list-tile-action>
                                          <v-tooltip top>
                                            <v-btn
                                                small
                                                icon
                                                flat
                                                left
                                                class="py-1 my-0"
                                                slot="activator"
                                                color="primary"
                                                @click="$emit('editRuleFromID', item.id)"
                                            >
                                              <v-icon>edit</v-icon>
                                            </v-btn>
                                            <span>Edit Rule</span>
                                          </v-tooltip>
                                        </v-list-tile-action>
                                        <v-list-tile-title>
                                          {{ item.description != null ? item.description : `Rule ${item.id}` }}
                                        </v-list-tile-title>
                                      </v-list-tile>
                                    </v-list>
                                  </v-card>
                                </v-menu>
                            </div>
                        </v-flex>
                        <v-flex shrink class="pl-1">
<!--Configure Field-->
                            <div>
                                <v-tooltip top>
                                    <v-icon
                                        small
                                        style="font-size: 1.2em"
                                        color="grey"
                                        class="pa-0 ma-0"
                                        slot="activator"
                                        @click="showConfigure = true"
                                    >
                                        edit
                                    </v-icon>
                                    <span>{{ `Configure Field ${field.id}` }}</span>
                                </v-tooltip>
                            </div>
<!--Rules Icon-->
                            <div v-if="!recycleBin">
                                <v-tooltip top v-model="dragTooltip">
                                    <v-icon
                                        color="red"
                                        class="pa-0 ma-0"
                                        slot="activator"
                                        @mousedown="handleDragStart"
                                        @mouseup="handleDragEnd"
                                        small
                                        style="font-size: 1.2em"
                                    >
                                        code
                                    </v-icon>
                                    <span>Create / Update Rule</span>
                                </v-tooltip>
                            </div>
                        </v-flex>
                    </v-layout>
                </div>
            </v-flex>
        </v-layout>
        <v-divider></v-divider>
        <app-input-dlg
                v-if="showConfigure"
                :show-dialog="showConfigure"
                :title="field.description"
                :cancel-button="true"
                :show-input-box="false"
                @dismiss="showConfigure = false"
                :width="500"
                :body-padding="false"
        >
            <template slot="body" v-if="showConfigure">
                <app-workflow-field-configure
                        :shown="showConfigure"
                        :userInfo="userInfo"
                        :field="field"
                        :step="step"
                        :steps="steps"
                        :fields="fields"
                        :rules="rules"
                        :fieldSets="fieldSets"
                        :userGroups="userGroups"
                        :selectLists="selectLists"
                        :split-task-workflows="splitTaskWorkflows"
                        :folder-groups="folderGroups"
                        :signatures="signatures"
                        :show-template-editor-fn="showTemplateEditorFn"
                        :local-data="localData"
                        :show-sync="showSync"
                        :get-task-header="getTaskHeader"
                />
            </template>
            <template slot="actions" >
                <v-btn flat color="secondary" @click="showConfigure = false">Done</v-btn>
            </template>
        </app-input-dlg>
        <v-menu
            v-model="showMenu"
            :position-x="x"
            :position-y="y"
            absolute
            offset-y
            v-if="field.fieldType !== 19 && field.fieldType !== 8"
        >
            <v-list dense>
                <v-list-tile
                    @click="$emit('copyField')"
                >
                    <v-list-tile-title>Copy Field</v-list-tile-title>
                </v-list-tile>
                <v-list-tile
                    @click="() => {}"
                >
                    <v-list-tile-title>Cancel</v-list-tile-title>
                </v-list-tile>
            </v-list>
        </v-menu>

    </div>
</template>

<script>

    import {codeBaseMixin} from "../../../../../../codeBaseMixin";
    import InputDlg from "../../../../../../components/General/InputDlg";
    import WorkflowFieldConfigure from './WorkflowFieldConfigure'
    import DatePickerWithMax from "../../../../../../components/General/DatePickerWithMax";

    export default {
        components: {
            appDatePickerWithMax: DatePickerWithMax,
            appInputDlg: InputDlg,
            appWorkflowFieldConfigure: WorkflowFieldConfigure
        },
        mixins: [codeBaseMixin],
        props: {
            userInfo: Object,
            field: Object,
            step: Object,
            steps: Array,
            fields: Array,
            rules: Array,
            fieldSets: Array,
            userGroups: Array,
            selectLists: Array,
            selectedField: Object,
            splitTaskWorkflows: Array,
            folderGroups: Array,
            signatures: Array,
            showTemplateEditorFn: Function,
            ruleRef: Object,
            showSelectFields: Boolean,
            selectedFields: Array,
            scrollToRuleFn: Function,
            previewRules: Boolean,
            previewValues: Object,
            previewEditFields: Array,
            recycleBin: Boolean,
            localData: Object,
            showSync: Boolean,
            getTaskHeader: Function,
        },
        data() {
            return {
                keepHandle: false,
                showConfigure: false,
                dragTooltip: false,
                showMenu: false,
                x: 0,
                y: 0,
            }
        },
        methods: {

            show (e) {
                e.preventDefault()
                this.showMenu = false
                this.x = e.clientX
                this.y = e.clientY
                this.$nextTick(() => {
                    this.showMenu = true
                })
            },

            handleDragStart() {
                this.dragTooltip = false;
                this.$refs.dragArea.draggable = true;
            },

            handleDragEnd() {
                this.dragTooltip = false;
                this.$refs.dragArea.draggable = false;
            },

            droppedEvent(dropEvent) {
                try {
                    let dropData = dropEvent.dataTransfer.getData('text');
                    dropData = JSON.parse(dropData);

                    if (typeof dropData.type != 'undefined' && typeof dropData.data != undefined) {
                        this.$emit('ruleDrop', {type: dropData.type, dragData: dropData.data, dropField: this.field});

                    }
                } catch (e) {
                    console.log(e);
                }
            },

            setDataTransfer(event) {
                event.dataTransfer.setData('text', `${JSON.stringify({type: 'field', data: this.field})}`);
            },

        },
        computed: {

            rulePreviewSelectListOptions() {
                try {
                    if (this.field.lookup_type == 1) {
                        return JSON.parse(this.field.lookup.replace(/\\"/g,'"'))
                    } else {
                        return this.selectLists.filter(obj => obj.id == parseInt(JSON.parse(this.field.lookup.replace(/\\"/g,'"')).id)).pop().members.map(obj => {return obj.value});
                    }
                } catch (e) {
                    // eslint-disable-next-line
                    console.log(e);
                    return [];
                }
            },

            rulePreviewValue: {
                get() {
                    return typeof this.previewValues[this.field.id] != "undefined" ? this.previewValues[this.field.id] : null
                },
                set(newValue) {
                    this.$emit('setPreviewValue', {field: this.field.id, value: newValue})
                    // this.previewValues[this.field.id] = newValue
                }
            },

            inSelectedFieldIDs() {
                try {
                    return this.selectedFields.map(obj => {return obj.id}).includes(this.field.id)
                } catch (e) {
                    return [];
                }
            },

            showShownIn() {
                if (typeof this.ruleRef.fields[this.field.id] != "undefined") {
                    return this.ruleRef.fields[this.field.id].shownInRules.length == 0 ? null : this.ruleRef.fields[this.field.id].shownInRules;
                } else {
                    return null;
                }
            },

            showConditionsIn() {

                if (typeof this.ruleRef.fields[this.field.id] != "undefined") {
                    return this.ruleRef.fields[this.field.id].conditionInRules.length == 0 ? null : this.ruleRef.fields[this.field.id].conditionInRules;
                } else {
                    return null;
                }
            },

            isSelectedField() {
                return this.selectedField != null && this.selectedField.id === this.field.id;
            },

            fieldVisibleClass() {
                try {
                    return !this.field.visible ? 'greyType--text' : ''
                } catch (e) {
                    // eslint-disable-next-line
                    console.log(e)
                    return 'greyType--text'
                }
            },

            borderClass() {
                try {
                    return this.field.visible == 0 ? 'regularFieldDisabled' : (this.field.required == 1 ? 'regularFieldRequired' : 'regularField')
                } catch (e) {
                    // eslint-disable-next-line
                    console.log(e)
                    return 'greyType--text'
                }
            },

            requiredField() {
                try {
                    return this.field.required
                } catch (e) {
                    // eslint-disable-next-line
                    console.log(e)
                    return false
                }
            },

            fieldType() {
                switch (this.field.fieldType) {
                    case 1: {
                        return 'Word Field';
                    }
                    case 2: {
                        return 'Date Field';
                    }
                    case 3: {
                        return 'Number Field';
                    }
                    case 4: {
                        return 'Currency Field';
                    }
                    case 5: {
                        return 'List Field';
                    }
                    case 6: {
                        return 'Mail/Document Field';
                    }
                    case 7 : {
                        return "Restricted Mail/Document";
                    }
                    case 8 : {
                        return "Mail Template";
                    }
                    case 9 : {
                        return "Dataset Select Field";
                    }
                    case 10 : {
                        return "Split Task Field";
                    }
                    case 11 : {
                        return "Checkbox List Field";
                    }
                    case 12 : {
                        return "Close Task Field";
                    }
                    case 13 : {
                        return "Note Field";
                    }
                    case 14 : {
                        return "Value Tree";
                    }
                    case 15 : {
                        return "XDS Credit Report";
                    }
                    case 17 : {
                        return "SMS Field";
                    }
                    case 18 : {
                        return "Photo Field"
                    }
                    case 19 : {
                        return "Document Template"
                    }
                    case 20 : {
                        return "Information Field"
                    }
                    case 21 : {
                        return "Folder Select Field"
                    }
                  case 22 : {
                    return "Eval Field"
                  }
                  case 23 : {
                    return "MS Word Template"
                  }
                  default : {
                    return 'Unknown Field';
                  }
                }
            },
        }
    }
</script>

<style scoped>

    .regularField{
        border-radius: 3px;
        cursor: pointer;
        border-left: 3px solid white;
    }

    .regularFieldDisabled{
        /*border-radius: 3px;*/
        cursor: pointer;
        border-left: 3px solid var(--v-tGrey-base);
    }

    .regularFieldRequired{
        /*border-radius: 3px;*/
        cursor: pointer;
        border-left: 3px solid var(--v-secondary-base);
    }

    .small-icon {
        width: 18px;
        height: 18px;
        color: white;
        text-align: center;
        border-radius: 50%;
    }

</style>
