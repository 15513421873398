<template >
    <div>
        <div v-show="displayFieldsTruncated == null">
            <v-btn small flat color="secondary" class="ma-0" @click="showEditor = true">Add Dataset Field To Workflow</v-btn>
        </div>
        <v-card ripple hover flat v-show="displayFieldsTruncated != null" class="primary white--text" @click="showEditor = true">
            <v-layout row align-center style="cursor: pointer" v-if="displayFieldsTruncated != null">
                <v-flex xs12 class="pa-1 pl-2 text-truncate" style="overflow-x: hidden;">
                    {{displayFieldsTruncated.displayItems.join(', ')}}
                </v-flex>
                <v-flex shrink>
                    <v-tooltip top v-if="displayFieldsTruncated.tooltipItems.length > 0">
                        <v-btn
                                small
                                flat
                                icon
                                color="white"
                                class="pa-0 ma-0"
                                slot="activator"
                        >
                            <div class="caption">
                                {{`+${displayFieldsTruncated.tooltipItems.length}`}}
                            </div>
                        </v-btn>
                        <span>{{displayFieldsTruncated.tooltipItems.join(', ')}}</span>
                    </v-tooltip>
                </v-flex>
                <v-flex shrink class="pr-1">
                    <v-tooltip top>
                        <v-btn
                                small
                                flat
                                icon
                                color="white"
                                class="pa-0 ma-0"
                                slot="activator"
                        >
                            <v-icon small>edit</v-icon>
                        </v-btn>
                        <span>Configure Linked Fields</span>
                    </v-tooltip>
                </v-flex>
            </v-layout>
        </v-card>

        <app-input-dlg
                :show-dialog="showEditor"
                title="Configure Fields"
                :cancel-button="true"
                :show-input-box="false"
                @dismiss="showEditor = false"
                :width="800"
                :body-padding="false"
        >
            <template slot="body" v-if="showEditor">
                <div class="pt-2 pl-1">
                    <v-layout row align-center>
                        <v-flex xs1>
                            <v-btn
                                    small
                                    icon
                                    class="pa-0 ma-0"
                                    color="primary"
                                    flat
                                    :disabled="true"
                            >
                                <v-icon small>check_box_outline_blank</v-icon>
                            </v-btn>
                        </v-flex>

                        <v-flex xs4 class="pa-1">
                            <div class="primary--text caption font-weight-bold">
                                Dataset Field
                            </div>
                        </v-flex>
                        <v-flex xs4 class="pa-1">
                            <div class="primary--text caption font-weight-bold">
                                Step
                            </div>
                        </v-flex>
                        <v-flex xs4 class="pa-1">
                            <div class="primary--text caption font-weight-bold">
                                Workflow Field
                            </div>
                        </v-flex>
                    </v-layout>
                    <div style="width: 100%" class="pr-1">
                        <v-divider color="primary"></v-divider>
                    </div>
                    <div class="scrollable" style="max-height: 500px">
                        <template v-for="obj in joinedFields">
                            <v-layout row align-center>
                                <v-flex xs1>
                                    <v-btn
                                            small
                                            icon
                                            class="pa-0 ma-0"
                                            :color="obj.workflowField != null ? 'primary' : 'grey'"
                                            flat
                                            @click="obj.workflowField == null ? addNewField(obj.fieldSetField): unlinkFieldRunStart(obj.workflowField)"
                                            :disabled="addingFields.includes(obj.fieldSetField.id) || (obj.workflowField != null && removeFields.includes(obj.workflowField.id))"
                                            :loading="addingFields.includes(obj.fieldSetField.id) || (obj.workflowField != null && removeFields.includes(obj.workflowField.id))"
                                    >
                                        <v-icon small>{{obj.workflowField != null ? 'check_box' : 'check_box_outline_blank'}}</v-icon>
                                    </v-btn>
                                </v-flex>

                                <v-flex xs4 class="pa-1 greyType--text caption">
                                    {{obj.fieldSetField.description}}
                                </v-flex>
                                <v-flex xs4 class="pa-1 greyType--text caption">
                                    {{obj.workflowStep != null ? obj.workflowStep.description : '-'}}
                                </v-flex>
                                <v-flex xs4 class="greyType--text caption">
                                    <v-layout row align-center class="hide-parent">
                                        <v-flex xs12 class="pa-1">
                                            {{obj.workflowField != null ? obj.workflowField.description : '-'}}
                                        </v-flex>
                                        <v-flex shrink v-if="obj.workflowField != null" class="hide-child">
                                            <v-tooltip top>
                                                <v-btn
                                                        slot="activator"
                                                        small
                                                        icon
                                                        class="pa-0 ma-0"
                                                        flat
                                                        color="primary"
                                                        @click="renameFieldRunStart(obj.workflowField)"
                                                        :disabled="(obj.workflowField != null && removeFields.includes(obj.workflowField.id))"
                                                        :loading="(obj.workflowField != null && removeFields.includes(obj.workflowField.id))"
                                                >
                                                    <v-icon small>edit</v-icon>
                                                </v-btn>
                                                <span>Rename Workflow Field</span>
                                            </v-tooltip>
                                        </v-flex>
                                    </v-layout>
                                </v-flex>
                            </v-layout>
                        </template>
                    </div>
                </div>
            </template>
        </app-input-dlg>

        <app-input-dlg
                :show-dialog="showRenameField"
                title="Rename Field"
                body="Field Description:"
                :cancel-button="true"
                :show-input-box="true"
                :default-input="fieldHoldingVar != null ? fieldHoldingVar.description : ''"
                :buttons="['Confirm']"
                :call-backs="[ renameFieldRunEnd ]"
                @dismiss="showRenameField = false"
        />

        <app-input-dlg
                :show-dialog="showConfirmRemoveLink"
                title="Remove Dataset Link"
                body="Note: Dataset link will be removed but not the field itself"
                :cancel-button="true"
                :show-input-box="false"
                :buttons="['Cancel','Remove']"
                :call-backs="[ unlinkFieldRunCancel, unlinkField ]"
                @dismiss="showConfirmRemoveLink = false"
        />
    </div>
</template>

<script>
    import {codeBaseMixin} from "../../../../../../../codeBaseMixin";
    import InputDlg from "../../../../../../../components/General/InputDlg";

    export default {
        mixins: [codeBaseMixin],
        components: {
            appInputDlg: InputDlg
        },
        props: {
            userInfo: Object,
            field: Object,
            fields: Array,
            fieldSets: Array,
            steps: Array,
        },
        data() {
            return {
                showEditor: false,

                addingFields: [],

                removeFields: [],
                showConfirmRemoveLink: false,

                fieldHoldingVar: null,
                showRenameField: false,
            }
        },
        watch: {
            showEditor: {
                immediate: true,
                handler(val) {
                    this.addingFields = [];
                    this.removeFields = []
                }
            }
        },
        methods: {
            renameFieldRunEnd(newDescription) {
                if (newDescription != null && newDescription != '') {
                    this.fieldHoldingVar.description = newDescription;
                    this.showRenameField = false;
                    this.removeFields.push(this.fieldHoldingVar.id);

                    this.fetch({method: 'PATCH', url:`/workflowManager/field/meta/${this.userInfo.entityID}/${this.fieldHoldingVar.id}`, body: JSON.stringify({
                            description: newDescription,
                            visible: this.fieldHoldingVar.visible,
                            required: this.fieldHoldingVar.required,
                            securityLookup: this.fieldHoldingVar.securityLookup,
                            linkLookup: this.fieldHoldingVar.linkLookup
                        })})
                        .then((data) => {
                            this.removeFields = this.removeFields.filter(obj => obj != this.fieldHoldingVar.id);
                            this.showSnack('Info', 'Changes Saved', 'Close', 'primary');
                        })
                        .catch((e) => {
                            // eslint-disable-next-line
                            this.removeFields = this.removeFields.filter(obj => obj != this.fieldHoldingVar.id);
                            console.log(e);
                            this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
                        })

                } else {
                    this.showSnack('Error', 'Field description required', 'Close', 'red')
                }
            },

            renameFieldRunStart(workflowField) {
                this.fieldHoldingVar = workflowField;
                this.showRenameField = true;
            },

            unlinkFieldRunStart(workflowField) {
                this.fieldHoldingVar = workflowField;
                this.showConfirmRemoveLink = true;
            },

            unlinkFieldRunCancel() {
                this.showConfirmRemoveLink = false;
                this.fieldHoldingVar = null;
            },

            unlinkField() {
                this.fieldHoldingVar.linkLookup = null;
                this.showConfirmRemoveLink = false;
                this.removeFields.push(this.fieldHoldingVar.id);

                this.fetch({
                    method: 'PATCH',
                    url: `/workflowManager/field/meta/${this.userInfo.entityID}/${this.fieldHoldingVar.id}`,
                    body: JSON.stringify({
                        description: this.fieldHoldingVar.description,
                        visible: this.fieldHoldingVar.visible,
                        required: this.fieldHoldingVar.required,
                        securityLookup: this.fieldHoldingVar.securityLookup,
                        linkLookup: this.fieldHoldingVar.linkLookup
                    })
                })
                    .then((data) => {
                        this.removeFields = this.removeFields.filter(obj => obj != this.fieldHoldingVar.id);
                        this.showSnack('Info', 'Changes Saved', 'Close', 'primary');
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        this.removeFields = this.removeFields.filter(obj => obj != this.fieldHoldingVar.id);
                        console.log(e);
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
                    })
            },

            addNewField(field) {
                this.addingFields.push(field.id);

                let newField= {
                    fk_step: this.field.fk_step,
                    description: field.description,
                    fieldType: field.type,
                    required: 0,
                    lookup_type: JSON.stringify(field.lookupType),
                    lookup: JSON.stringify(field.lookup),
                    linkLookup: JSON.stringify({
                        field: field.id,
                        fieldSetSelectField: this.field.id,
                        autoPopulate: 1
                    })
                };

                this.fetch({method: 'POST', url:`/workflowManager/field/AddLinked/${this.userInfo.entityID}`, body: JSON.stringify(newField)})
                    .then((data) => {
                        this.addingFields = this.addingFields.filter(obj => obj != field.id)
                        this.fields.push(data);
                        this.showSnack('Info', 'Field Added', 'Close', 'primary')
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e)
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                        this.addingFields = this.addingFields.filter(obj => obj != field.id)
                    })
            }
        },
        computed: {
            joinedFields() {
                try {
                    // Get the Field Set Fields
                    let tFieldSetFields = (this.fieldSets.filter(obj => obj.id == this.field.config.dataset) || [{fields: []}])
                            .pop()
                            .fields
                            .filter(field => field.visible == 1)
                    // Get The Workflow Fields
                    let tWorkflowFields = this.fields.reduce((linkedFields, wField) => {
                        if (
                            wField.linkLookup != null
                            && typeof wField.linkLookup.fieldSetSelectField != 'undefined'
                            && wField.linkLookup.fieldSetSelectField != null
                            && wField.linkLookup.fieldSetSelectField == this.field.id
                            && typeof wField.linkLookup.field != 'undefined'
                            && wField.linkLookup.field != null
                        ) {
                            linkedFields.push(wField);
                        }
                        return linkedFields
                    }, []);
                    // Merge the 2 Together And Add The Step
                    let tWorkflowFieldFieldLinks = tWorkflowFields.map(obj => {return obj.linkLookup.field});

                    let tReturnValue = tFieldSetFields.reduce((returnFields, fsField) => {
                        let wField = null;
                        let wStep = null;

                        if (tWorkflowFieldFieldLinks.includes(fsField.id)) {

                            wField = tWorkflowFields.filter(obj => obj.linkLookup.field == fsField.id)
                                .pop();
                            wStep = this.steps.filter(obj => obj.id == wField.fk_step)
                                .pop()
                        }

                        returnFields.push({
                            fieldSetField: fsField,
                            workflowStep: wStep,
                            workflowField: wField
                        });

                        return returnFields;
                    }, []);

                    return tReturnValue
                        .sort((a,b) => a.fieldSetField.description < b.fieldSetField.description ? -1 : 1)
                        .filter(obj => obj.fieldSetField.type !== 16)
                } catch (e) {
                    // eslint-disable-next-line
                    console.log(e)
                    return null
                }
            },

            displayFieldsTruncated() {

                let tLinkedFields = this.joinedFields
                    .reduce((returnList, obj) => {
                        if (obj.workflowField != null) {
                            returnList.push(obj.fieldSetField.description)
                        }
                        return returnList
                    }, []);

                if (tLinkedFields == null || tLinkedFields <= 0) {
                    return null;
                } else {
                    return tLinkedFields.reduce((returnObject, field, index, valueFields) => {
                        if (index <= 2) {
                            returnObject.displayItems.push(field)
                        } else {
                            returnObject.tooltipItems.push(field)
                        }

                        if (index + 1 == valueFields.length && (returnObject.displayItems.length + returnObject.tooltipItems.length) <= 0) {
                            returnObject = null;
                        }
                        return returnObject;
                    }, {
                        displayItems: [],
                        tooltipItems: []
                    })
                }
            },

            // fieldSetFields() {
            //     try {
            //         if (this.fieldSetSelectField != null) {
            //             let tFieldSetID = this.fields.filter(obj => obj.id == this.fieldSetSelectField).map(obj => {return parseInt(obj.lookup)}).pop();
            //             return this.fieldSets.reduce((fields, fieldSet) => {
            //                 if (fieldSet.id == tFieldSetID) {
            //                     fields = fieldSet.fields.filter(obj => this.fieldTypeFilter.includes(obj.type) && obj.visible == 1)
            //                         .map(obj => {return {description: obj.description, id: obj.id}})
            //                         .sort((a,b) => a['description'] < b['description'] ? -1 : 1);
            //                 }
            //                 return fields
            //             }, []);
            //         } else {
            //             return []
            //         }
            //     } catch (e) {
            //         // eslint-disable-next-line
            //         console.log(e);
            //         return [];
            //     }
            // },

            // fieldSetSelectFields() {
            //     return this.fields.filter(obj => obj.fieldType == 9 && obj.visible == 1)
            //         .map(obj => {return {description: obj.description, id: obj.id}})
            //         .sort((a,b) => a['description'] < b['description'] ? -1 : 1);
            // },

            // fieldSetSelectFieldModel: {
            //     get: function () {
            //         return this.fieldSetSelectField
            //     },
            //     set: function (newValue) {
            //         this.fieldSetSelectField = newValue;
            //         this.resetField();
            //     }
            // },

            // linkLookupDisplay() {
            //     if (
            //         this.value != null
            //         && typeof this.value.field != 'undefined'
            //         && typeof this.value.fieldSetSelectField != 'undefined'
            //         && this.value.field != null
            //         && this.value.fieldSetSelectField != null
            //     ) {
            //         try {
            //             // Get The value field Name
            //             let selectFieldData = this.fields.reduce((value, field) => {
            //                 if (field.id == this.value.fieldSetSelectField) {
            //                     value = {
            //                         description: field.description,
            //                         lookup: parseInt(field.lookup)
            //                     }
            //                 }
            //                 return value
            //             }, null);
            //             let datasetField = this.fieldSets.filter(obj => obj.id == selectFieldData.lookup)
            //                 .map((fieldset) => {
            //                     return fieldset.fields.filter(obj => obj.id == this.value.field)
            //                         .map(obj => {
            //                             return obj.description
            //                         })
            //                 })
            //                 .pop().pop();
            //
            //             return {
            //                 fieldSetSelectField: selectFieldData.description,
            //                 datasetField: datasetField
            //             }
            //
            //         } catch (e) {
            //             // eslint-disable-next-line
            //             console.log(e)
            //             return null
            //         }
            //     } else {
            //         return null
            //     }
            // },
        }
    }
</script>

<style scoped>

</style>
