<template>
    <v-layout class="scrollable" column fill-height>
        <v-flex shrink class="px-2">
            <v-layout row align-center>
                <v-flex xs12>
                    <v-text-field
                            label="Filter Templates"
                            solo
                            clearable
                            prepend-icon="search"
                            color="primary"
                            flat
                            :hide-details="true"
                            v-model="search"
                            class="pa-0 ma-0"
                            ref="myField"
                    ></v-text-field>
                </v-flex>
                <v-flex shrink>
                    <v-tooltip top>
                        <v-btn
                                small
                                icon
                                flat
                                color="secondary"
                                slot="activator"
                                class="pa-0 ma-0"
                                @click="$emit('addTemplate')"
                        >
                            <v-icon>add</v-icon>
                        </v-btn>
                        <span>Add New Template</span>
                    </v-tooltip>
                </v-flex>
            </v-layout>
        </v-flex>
        <v-divider></v-divider>
        <v-flex xs12 class="scroll" v-if="items.length > 0">
            <div
                    v-for="template in items"
                    class="hover-item hide-parent"
            >
                <v-layout row align-center>
                    <v-flex shrink class="pl-2" @click="$emit('input', template)">
                        <v-icon
                                small
                                :color="value != null && value.id == template.id ? 'primary' : 'grey'"
                        >
                            {{value != null && value.id == template.id ? 'check_box' : 'check_box_outline_blank'}}
                        </v-icon>
                    </v-flex>
                    <v-flex class="pa-2" :class="value != null && value.id == template.id ? 'primary--text font-weight-bold' : ''" @click="$emit('input', template)">
                        {{template.description}}
                    </v-flex>
                    <v-flex shrink class="pr-2 hide-child" >
                        <v-tooltip top>
                            <v-btn
                                    small
                                    icon
                                    flat
                                    class="pa-0 ma-0"
                                    color="red"
                                    slot="activator"
                                    @click="$emit('removeImportTemplate', template)"
                            >
                                <v-icon small>delete</v-icon>
                            </v-btn>
                            Delete Template
                        </v-tooltip>
                    </v-flex>
                    <v-flex shrink class="pr-2 hide-child" @click="$emit('input', template)">
                        <v-tooltip top>
                            <v-btn
                                    small
                                    icon
                                    flat
                                    class="pa-0 ma-0"
                                    color="primary"
                                    slot="activator"
                                    @click="$emit('rename')"
                            >
                                <v-icon small>edit</v-icon>
                            </v-btn>
                            Rename Template
                        </v-tooltip>
                    </v-flex>
                </v-layout>
                <v-divider></v-divider>

            </div>
        </v-flex>
    </v-layout>
</template>

<script>
    export default {
        props: {
            value: Object,
            items: Array
        },
        data() {
            return {
                search: null
            }
        },
    }
</script>

<style scoped>

</style>
