<template>
    <v-autocomplete
            v-bind="$attrs"
            :items="icons"
            item-text="name"
            item-value="name"
            dense
            hide-details
            label="Select Icon"
            :filter="(item, queryText, itemText) => {return `${item.tagStr} ${itemText}`.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 }"
            @input="v => $emit('input', v)"
    >
        <template v-slot:selection="data">
            <dense-chip :label="data.item.description" :color="$attrs.color || 'primary'" :icon="data.item.name"/>
        </template>
        <template v-slot:item="data">
            <div class="c-d-flex c-align-center">
                <v-icon left>
                    {{ data.item.name }}
                </v-icon>
                {{ data.item.description }}
            </div>
        </template>
    </v-autocomplete>
</template>

<script>

import iconsSrc from '@/components/General/IconInput/icons'
import DenseChip from "@/components/General/DenseChip";

export default {
    components: {DenseChip},
    inheritAttrs: false,
    data() {
        return {
            icons: iconsSrc,
            console: console
        }
    },
}
</script>

<style scoped>

</style>