<template>
    <v-layout column fill-height class="" style="overflow-x: hidden; overflow-y: auto; background: var(--page-back)">
<!--        {{curView}}-->
        <v-flex shrink v-if="showFilters">
            <!--Toolbar-->
<!--            {{curView}}-->
            <app-task-filter-band-toolbar
                    :filter-settings="filterSettings"
                    :userInfo="userInfo"
                    :filter="filter"
                    @filter="filterUpdated($event)"
                    :droppedItems="droppedGroupings"
                    @drop="droppedGroupings.length > 0 ? (clearGroupings()) : dropAllGroupings()"
                    @dropAll="clearGroupings()"
                    @scrollToTop="$refs.scrollBox.scrollTop = 0"
                    :folder-groups="folderGroups"
                    :local-data="localData"
                    :show-sync="showSync"
                    :get-task-header="getTaskHeader"
                    @refresh="$emit('refresh')"
                    :refresh-loading="refreshLoading"
            />
        </v-flex>

        <v-flex shrink>
            <task-filter
                    :pre-tasks="preferenceTasks"
                    :folder-groups="folderGroups"
                    :set-post-tasks="setPostTasks"
                    @filtersUpdated="filtersUpdated($event)"
                    :local-data="localData"
            />
        </v-flex>
        <v-flex shrink class="white">
            <v-progress-linear
                    :indeterminate="taskLoading"
                    height="3"
                    class="pa-0 ma-0"
                    color="grey"
                    style="background-color: var(--page-back) !important;"
            ></v-progress-linear>
        </v-flex>
        <v-flex shrink class="px-2" v-if="loadMore && filterSettings.stateOption >= 3">
            <v-btn
                    @click="filtersUpdated(filters)"
                    color="secondary"
                    block
                    flat
                    small
            >
                Load More Results
            </v-btn>
<!--            {{curView}}-->
        </v-flex>
        <v-flex xs12 style="overflow-y: auto; overflow-x: hidden" id="taskScrollBar" ref="scrollBox"  class="white" >
<!--            {{tasks.length + '-' + displayTasks.length}}-->
            <!--@scroll="finiteScroll"-->

            <!--<template v-for="item in taskGroupsWithDroppedItems">-->


            <!--</template>-->
          
<!--                      :extra-props="{-->
<!--                      selectedMessageIds,-->
<!--                      handleContextMenu,-->
<!--                      selectedMessageIdsSize,-->
<!--                      handleMessageSelect,-->
<!--                      handleDragStart,-->
<!--                      handleViewTasks,-->
<!--                      allowAttachmentDrag: false,-->
<!--                      showLinkedTasks: true-->
<!--                      }"-->
<!--          -->
<!--          -->
<!--                      @resized="onItemRendered"-->
<!--                      @totop="onTotop"-->
<!--                      @scroll="e => vslScrolled(e)"-->
<!--          <v-btn @click="console.log($refs.vsl)"></v-btn>-->
          <virtual-list
              :keeps="40"
              :estimate-size="60"
              class="fill-height of-y"
              data-key="refID"
              :data-sources="displayTasks"
              :data-component="TaskBandItem"
              :extra-props="{
                droppedGroupings,
                filterSettings,
                toggleGrouping,
                selectedTaskID,
                curView,
                taskSelected,
                taskBarDrop,
                smtpMailLink,
                smtpMailboxMailLink,
                chatMessagesLink,
                linkingMail,
                showLinkSmtp,
                linkMailToTask
              }"
              style="background: var(--card-back)"
          >
          </virtual-list>
          
          

<!--            <DynamicScroller-->
<!--                    :items="displayTasks"-->
<!--                    key-field="refID"-->
<!--                    :min-item-size="100"-->
<!--                    class="scroller"-->
<!--                    style="overflow-x: hidden"-->
<!--            >-->
<!--                &lt;!&ndash;:size-dependencies="[&ndash;&gt;-->
<!--                &lt;!&ndash;item.message,&ndash;&gt;-->
<!--                &lt;!&ndash;]"&ndash;&gt;-->
<!--                <template v-slot="{ item, index, active }" >-->
<!--                    <DynamicScrollerItem-->
<!--                            :item="item"-->
<!--                            :active="active"-->
<!--                            :data-index="index"-->
<!--                            :size-dependencies="[-->
<!--                                item.title,-->
<!--                                selectedTaskID-->
<!--                            ]"-->
<!--                            style="overflow-x: hidden"-->
<!--                    >-->
<!--                        <div :key="typeof item.groupObject != 'undefined' ? item.title : item.taskID">-->
<!--                            <template v-if="typeof item.groupObject != 'undefined'">-->
<!--                                <div>-->
<!--                                    <v-layout @click="item.dropped ? droppedGroupings = droppedGroupings.filter(obj => obj != item.title) : droppedGroupings.push(item.title)" row align-center class="groupHover py-1" :class="item.dropped ? 'primary' : ''">-->
<!--                                        <v-flex class="pl-1" shrink>-->
<!--                                            <img v-if="filterSettings.groupOption == 1" src="../../../public/users.svg" alt="" width="24">-->
<!--                                            <img v-if="filterSettings.groupOption == 6" src="../../../public/sub_users.svg" alt="" width="24">-->
<!--                                            <img v-if="filterSettings.groupOption == 2" src="../../../public/workflows.svg" alt="" width="24">-->
<!--                                            <v-icon v-if="filterSettings.groupOption == 5" class="yellow&#45;&#45;text text&#45;&#45;darken-2">folder_shared</v-icon>-->
<!--                                            <v-icon v-if="filterSettings.groupOption == 0" class="yellow&#45;&#45;text text&#45;&#45;darken-2">folder</v-icon>-->
<!--                                        </v-flex>-->
<!--                                        <v-flex xs12 style="overflow-x: hidden" class="text-truncate body-1 pl-1" :class="`${item.dropped ? 'white&#45;&#45;text' : ''} ${blankString(item.title) || item.title === 'null' || item.title === 'null null' ? ' clear-text placeholder-content rounded-border pr-2 ml-1' : ''}`">-->
<!--                                            {{ blankString(item.title) || item.title === 'null' || item.title === 'null null' ? '-' : item.title }}-->
<!--                                        </v-flex>-->
<!--                                        <v-flex shrink class="body-1 pl-1" :class="item.dropped ? 'white&#45;&#45;text' : ''">-->
<!--                                            {{ `(${item.count})` }}-->
<!--                                        </v-flex>-->
<!--                                        <v-flex shrink class="px-1">-->
<!--                                            <v-btn class="pa-0 ma-0" small flat icon :color="item.dropped ? 'white' : 'primary'">-->
<!--                                                <v-icon>{{ item.dropped ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}}</v-icon>-->
<!--                                            </v-btn>-->
<!--                                        </v-flex>-->
<!--                                    </v-layout>-->
<!--                                    <v-divider></v-divider>-->
<!--                                </div>-->
<!--                            </template>-->
<!--                            <template v-else>-->
<!--                                <app-task-container-->
<!--                                        :userInfo="userInfo"-->
<!--                                        :task="item"-->
<!--                                        :taskBar="true"-->
<!--                                        :showNav="['mail', 'app-gmail', 'smtp', 'mailboxes', 'chat'].includes(curView)"-->
<!--                                        @taskSelected="taskSelectedTaskBand(item)"-->
<!--                                        :selected="selectedTaskID === item.taskID"-->
<!--                                        :class="selectedTaskID === item.taskID ? 'grey lighten-3' : 'white'"-->
<!--                                        @taskBarDrop="taskDocDropped($event, item)"-->
<!--                                        :allow-drop="true"-->
<!--                                        @smtpMailLink="taskSelectedTaskBand(item); linkMailToTask()"-->
<!--                                        @smtpMailboxMailLink="taskSelectedTaskBand(item); linkMailToTask()"-->
<!--                                        @chatMessagesLink="$emit('chatMessagesLink', $event)"-->
<!--                                >-->
<!--                                    <div-->
<!--                                            slot="right"-->
<!--                                            v-if="((['mailboxes','smtp'].includes(curView) && showLinkSmtp) || ['mail', 'app-gmail'].includes(curView)) && selectedTaskID === item.taskID"-->
<!--                                            @click="linkMailToTask()"-->
<!--                                            class="pr-1"-->
<!--                                    >-->
<!--                                        <v-tooltip top>-->
<!--                                            <v-btn-->
<!--                                                    small-->
<!--                                                    color="primary"-->
<!--                                                    icon-->
<!--                                                    class="pa-0 ma-0"-->
<!--                                                    slot="activator"-->
<!--                                                    :disabled="linkingMail"-->
<!--                                                    :loading="linkingMail"-->
<!--                                            >-->
<!--                                                <v-icon small>link</v-icon>-->
<!--                                            </v-btn>-->
<!--                                            Link to Task-->
<!--                                        </v-tooltip>-->
<!--                                    </div>-->
<!--&lt;!&ndash;                                    <div&ndash;&gt;-->
<!--&lt;!&ndash;                                            slot="lower"&ndash;&gt;-->
<!--&lt;!&ndash;                                            v-if="['mail', 'app-gmail'].includes(curView) && selectedTaskID === item.taskID"&ndash;&gt;-->
<!--&lt;!&ndash;                                            style="width: 100%;"&ndash;&gt;-->
<!--&lt;!&ndash;                                            class="text-xs-right pr-2 grey lighten-3"&ndash;&gt;-->
<!--&lt;!&ndash;                                            @click="linkMailToTask()"&ndash;&gt;-->
<!--&lt;!&ndash;                                    >&ndash;&gt;-->

<!--&lt;!&ndash;                                        <v-btn&ndash;&gt;-->
<!--&lt;!&ndash;                                                small&ndash;&gt;-->
<!--&lt;!&ndash;                                                color="secondary"&ndash;&gt;-->
<!--&lt;!&ndash;                                                flat&ndash;&gt;-->

<!--&lt;!&ndash;                                        >&ndash;&gt;-->
<!--&lt;!&ndash;                                            <v-icon left>link</v-icon>&ndash;&gt;-->
<!--&lt;!&ndash;                                            <span>Link To Task</span>&ndash;&gt;-->
<!--&lt;!&ndash;                                        </v-btn>&ndash;&gt;-->
<!--&lt;!&ndash;                                    </div>&ndash;&gt;-->
<!--                                </app-task-container>-->
<!--                            </template>-->
<!--                        </div>-->
<!--                    </DynamicScrollerItem>-->
<!--                </template>-->
<!--            </DynamicScroller>-->
        </v-flex>
        <app-task-bar-container-drop-step-select-dlg
                v-if="copyData.showCopy"
                :user-info="userInfo"
                :workflow-i-d="copyData.task.workflowID"
                :show-dialog="copyData.showCopy"
                @dismiss="copyData.showCopy = false"
                @input="copyToTaskStep($event)"
        />
    </v-layout>
</template>

<script>
    import {eventBus} from '../../main';
    import {codeBaseMixin} from '../../codeBaseMixin';
    import TaskFilterBandToolbar from './components/TaskFilterBandToolbar';
    import VueScrollTo from 'vue-scrollto';
    import TaskFilter from "./components/TaskFilter";
    import TaskContainer from "../../components/TaskContainer/TaskContainer";
    import TaskBarContainerDropStepSelectDlg from "../../components/General/TaskBarContainerDropStepSelectDlg";
    import TaskBandItem from "@/Tasks/TaskBand/components/TaskBandItem.vue";
    import VirtualList from "vue-virtual-scroll-list"

    export default {
        components: {
            appTaskBarContainerDropStepSelectDlg: TaskBarContainerDropStepSelectDlg,
            appTaskContainer: TaskContainer,
            TaskFilter,
            appTaskFilterBandToolbar: TaskFilterBandToolbar,
          TaskBandItem,
          VirtualList,
        },
        mixins: [codeBaseMixin],
        props: {
            tasks: Array,
            userInfo: Object,
            selectedTaskID: {
                type: Number,
                default: 0
            },
            taskLoading: Boolean,
            filterSettings: Object,
            dashboard: {
                type: Boolean,
                default: false
            },
            folderGroups: Array,
            curView: String,
            mailLink: {
                type: Boolean,
                default: false
            },
            linkMailFn: Function,
            showFilters: {
                default: true,
                type: Boolean
            },
            localData: Object,
            showSync: Boolean,
            loadMore: Boolean,
            getTaskHeader: Function,
            showLinkSmtp: Boolean,
            refreshLoading: Boolean,
            linkingMail: Boolean
        },

        data() {
            return {
              console,
              TaskBandItem,
                toolbarHeight: 0,
                sortingOptions: {
                    orderBy: 0,
                    groupBy: 0
                },
                pagerHeight: 0,
                filter: '',
                droppedGroupings: [],
                drawRate: 25,
                postFilteredTasks: [],
                bResetFilter: 0,
                filters: [],
                copyData: {
                    showCopy: false,
                    task: null,
                    dropData: null
                }
            }
        },

        created() {
            eventBus.$on('taskBandToTop', this.taskBandToTop);
        },
        beforeDestroy() {
            eventBus.$off('taskBandToTop', this.taskBandToTop);
        },

        methods: {
          
            taskSelected(item) {
              this.taskSelectedTaskBand(item)
            },
          
            taskBarDrop(event, item) {
              console.log({event, item})
              
              this.taskDocDropped(event, item)
            },
            smtpMailLink(item) {
              this.taskSelectedTaskBand(item);
              this.linkMailToTask()
            },
            smtpMailboxMailLink(item) {
              this.taskSelectedTaskBand(item);
              this.linkMailToTask()
            },
            chatMessagesLink(event) {
              this.$emit('chatMessagesLink', event)
            },
          
          
          
            toggleGrouping(item) {
              if (item.dropped)
                this.droppedGroupings = this.droppedGroupings.filter(obj => obj !== item.title)
              else
                this.droppedGroupings.push(item.title)
            },

            taskBandToTop() {this.$refs.taskBar.scrollTop = 0},

            copyToTaskStep(stepID) {
                this.fetch({
                    method: 'POST',
                    url: `/tasks/copyMessage/${this.userInfo.entityID}/${this.userInfo.userID}/${this.copyData.task.taskID}/${stepID}/${this.copyData.dropData.type == 'mail' ? 1 : 3}/${this.copyData.dropData.type == 'mail' ? this.copyData.dropData.data.mailDBID : this.copyData.dropData.data.documentDBID}`,
                })
                    .then((data) => {
                        this.showSnack('Info', 'Copy Complete', 'Close', 'primary')
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e)
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                    })
            },

            taskDocDropped(dragData, task) {
              
              console.log({dragData})
              
              console.log(dragData)
              
              
                this.copyData.task = task;
                this.copyData.dropData = dragData;
                this.copyData.showCopy = true;

            },

            linkMailToTask() {
                // this.linkMailFn(item)
                this.$emit('linkMailToTask')
            },

            taskSelectedTaskBand(task) {
                if (['app-tasks', 'app-dashboard-view', 'calendar'].includes(this.curView)) {
                    eventBus.$emit('gotoTask', task)
                }
                if (['chat', 'mail', 'app-gmail', 'smtp', 'mailboxes'].includes(this.curView)) {
                    this.$emit('taskSelected', task)
                }
            },

            filtersUpdated(filters) {
                this.filters = filters;
                if (this.filterSettings.stateOption >= 3){
                    this.$emit('closedFilterUpdate', filters)
                }
            },

            setPostTasks(tasks) {
                this.postFilteredTasks = tasks
            },

            refreshRequested() {
                this.$emit('refreshRequested');
                this.clearGroupings();
            },

            navToTask(task) {
                eventBus.$emit('gotoTask', (task));
            },

            filterUpdated(value) {
                setTimeout(() => {
                    this.$refs.scrollBox.scrollTop = 0;
                }, 200);
                this.drawRate = 25;
                //this.dropAll();
                this.filter = value;
            },

            clearGroupings() {
                this.droppedGroupings = [];
            },

            dropAllGroupings() {
                if (this.taskGroups != null) {
                    this.droppedGroupings = Object.keys(this.taskGroups);
                } else {
                    this.droppedGroupings = [];
                }
            },

            getClass(ownerID, userID, taskID) {
                if (taskID != this.selectedTaskID) {
                    return ownerID == userID ? 'myTask' : 'otherTask'
                } else {
                    return ownerID == userID ? 'myTask taskSelected' : 'otherTask taskSelected'
                }
            },

            scrollToTop() {
                setTimeout(() => {
                    VueScrollTo.scrollTo('#taskScrollBarTop', 500, {container: '#taskScrollBar'});
                }, 500);
            },

        },

        computed: {
            preferenceTasks() {
                let tList = this.tasks.reduce((taskList, task) => {


                    let bAdd = true;

                    if (!task.access) {
                        bAdd = false;
                    }

                    if (this.filterSettings.starOption == 1) {
                        if (!task.starred) {
                            bAdd = false;
                        }
                    }

                    switch (this.filterSettings.userOption) {
                        case 0: {
                            if (!(task.ownerID == this.userInfo.userID || task.subOwnerID == this.userInfo.userID)) {
                                bAdd = false;
                            }
                            break;
                        }
                        case 2: {
                            if (task.ownerID != this.userInfo.userID) {
                                bAdd = false;
                            }
                            break;
                        }
                        case 3: {
                            if (!(task.subOwnerID == this.userInfo.userID && task.ownerID != task.subOwnerID)) {
                                bAdd = false;
                            }
                            break;
                        }
                        // default: {
                        //     bAdd = true;
                        // }
                    }

                    switch (this.filterSettings.stateOption) {
                        case 0: {
                            if (task.taskState != 0 || task.snoozed) {
                                bAdd = false;
                            }
                            break;
                        }
                        case 1: {
                            if (!task.snoozed) {
                                bAdd = false;
                            }
                            break;
                        }
                        case 2: {
                            if (task.taskState == 1) {
                                bAdd = false;
                            }
                            break;
                        }
                        case 3: {
                            if (task.taskState != 1) {
                                bAdd = false;
                            }
                            break;
                        }
                        default: {
                            bAdd = true;
                        }
                    }

                    if (!task.access)
                        bAdd = false

                    if (bAdd) {
                        taskList = taskList.concat([task])
                    }

                    return taskList;
                }, []);
                return tList;
            },

            displayTasks() {
                return this.taskGroupsWithDroppedItems.map((obj,index) => {
                  if (obj.groupObject) {
                    obj.refID = obj.title + obj.dropped ? '1' : '0';
                  } else {
                    obj.refID = obj.taskID;
                  }
                  return obj;
                })
            },

            taskGroupsWithDroppedItems() {
                if (this.taskGroups != null) {
                    let tItems = [];

                    const taskGroupKeys = Object.keys(this.taskGroups).sort((a,b) => a < b ? -1 : 1);
                    for (const groupName of taskGroupKeys) {

                        let dropped = this.droppedGroupings.includes(groupName);

                        tItems.push({
                            title: groupName,
                            dropped: dropped,
                            groupObject: true,
                            count: this.taskGroups[groupName].tasks.length
                        });

                        if (dropped) {
                            tItems = tItems.concat(this.taskGroups[groupName].tasks)
                        }
                    }
                    return tItems
                } else {
                    return this.sortedTasks;
                }
            },

            taskGroups() {
                if (this.filterSettings.groupOption != 4) {
                    let tGroups = {};
                    this.sortedTasks.forEach(task => {
                        let group = '';
                        switch (this.filterSettings.groupOption) {
                            case 0: {group = task.folderID > 0 ? task.folderName : 'Task Folder';break;}
                            case 1: {group = `${task.ownerName} ${task.ownerSurname}`;break;}
                            case 2: {group = task.workflowName;break;}
                            case 5: {group = task.folderID > 0 ? task.folderGroupNames : 'Task Folder';break;}
                            case 6: {group = `${task.subOwnerName} ${task.subOwnerSurname}`;break;}
                        }
                        if (typeof tGroups[group] == 'undefined') {
                            tGroups[group] = {tasks: []}
                        }
                        tGroups[group].tasks.push(task);
                    });
                    return tGroups;
                } else {
                    return null;
                }
            },

            sortedTasks() {
                if (this.filterSettings.orderOption == 1) {
                    return [...this.postFilteredTasks].sort((a,b) => a['taskID'] > b['taskID'] ? -1 : 1);
                } else {
                    return [...this.postFilteredTasks].sort((a,b) => a['taskID'] < b['taskID'] ? -1 : 1);
                }
            },

            someDataTranslated() {
                return this.someData
            },
        },
    }
</script>

<style scoped>

    .taskSelected {
        background: #ebebeb;
    }

    .taskHeader:hover {
        cursor: pointer;
        background: #f9f9f9;
    }

    .groupByGroup {
        background: #f9f9f9;
    }

    .otherTask {
        border-left: 5px solid var(--v-primary-base);
    }

    .myTask {
        border-left: 5px solid var(--v-secondary-base);
    }

    .scroller {
        /*max-height: 450px;*/
        height: 100%;
    }

    .groupHover:hover {
        background: #eeeeee;
        cursor: pointer;
    }

</style>
