<template>
    <v-layout row fill-height style="overflow-y: auto; background: var(--page-back)">
<!--Folder Bar-->
        <v-flex v-if="officeConfig.running == true" v-show="!showLinkOffice" xs3 class="fill-height" style="overflow-y: auto; background: var(--page-back)">
            <v-layout column fill-height style="overflow-y: auto">
<!--Mail Switcher-->
                <v-flex class="pa-2" shrink v-if="userPrincipal != null" v-show="!loadingFolders && userPrincipalDefault != null">
                    <app-mailbox-switch
                            :user-info="userInfo"
                            :office-config="officeConfig"
                            :default="userPrincipalDefault"
                            v-model="userPrincipal"
                            @input="initOffice"
                    />
                </v-flex>
<!--Compose New Message-->
                <v-flex shrink class="px-2 text-xs-center" v-if="userPrincipal != null" v-show="!loadingFolders && userPrincipalDefault != null">
                    <v-btn large flat color="blue" @click="newMail(0)">
                        <img src="../../../../public/outlook_32x1.svg" class="nav-item">
                        Compose Message
                    </v-btn>
                </v-flex>
<!--Mailbox Folders-->
                <v-flex xs12 style="overflow-y: auto">
                    <v-layout column class="pt-2" fill-height v-if="loadingFolders">
                        <v-flex shrink class="text-xs-center">
                            <v-progress-circular
                                    :size="40"
                                    :width="5"
                                    color="primary"
                                    indeterminate
                            ></v-progress-circular>
                        </v-flex>
                    </v-layout>
                    <app-folders
                            v-else
                            :user-info="userInfo"
                            :folders="folders"
                            :inbox-id="officeInboxId"
                            :fn-load-sub-folders="getChildFolders"
                            :selected-label="selectedLabel"
                            @labelSelected="selectedLabel = $event; getMessagesForFolder($event.id); searchOptions = {
                                searchText: '',
                                from: '',
                                to: '',
                                subject: '',
                                hasWords: '',
                                noWords: '',
                                hasAttachment: false,
                                starred: false
                            }; selectedMessage = null"
                            @droppedLabel="moveMessageToFolder($event)"
                    />
                </v-flex>
            </v-layout>
        </v-flex>
<!--Loading Office-->
        <v-flex xs12 v-if="officeConfig.loadingAddress && !showLogin" class="fill-height">
            <v-layout column align-center justify-space-around fill-height>
                <v-flex shrink>
                    <v-progress-circular
                            :size="40"
                            :width="5"
                            color="primary"
                            indeterminate
                    ></v-progress-circular>
                </v-flex>
            </v-layout>
        </v-flex>
<!--Login To Office-->
        <v-flex xs12 v-if="officeConfig.loadingAddress == false && officeConfig.running == false" class="fill-height">
            <v-layout column align-center justify-space-around fill-height>
                <v-flex shrink>
                    <v-card width="400">
                        <v-card-title class="pa-1 pl-2">
                            <span class="subheading font-weight-light primary--text">Login</span>
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-card-text>
                            <div class="pa-2">Login To Microsoft Office</div>
                            <div class="text-xs-right pa-2">
                                <v-btn large flat color="blue" @click="authorize">
                                    <img src="../../../../public/outlook_32x1.svg" class="nav-item">
                                    Login
                                </v-btn>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-flex>
            </v-layout>
        </v-flex>
<!--MailBar-->
        <v-flex :xs3="!showLinkOffice" :xs4="showLinkOffice"  style="overflow-y: auto; background: var(--page-back)" class="fill-height px-1" v-if="officeConfig.running && !showLogin && userPrincipal != null">
            <v-layout fill-height column>
                <v-flex shrink>
                    <app-search-menu
                            :searchOptions="searchOptions"
                            @runSearch="getSearchMessages(); selectedMessage = null"
                            v-if="!loadingMessages"
                    />
                </v-flex>
                <v-flex style="overflow-y: auto" xs12>
                    <app-mail-bar
                            :show-loading="loadingMessages"
                            :userInfo="userInfo"
                            :messages="messageList"
                            :nextLink="nextLink"
                            @selectMessage="selectMessage($event)"
                            :selected-message="selectedMessage"
                            :load-more-fn="loadMoreMessages"
                            :loading-next="loadingMoreMessages"
                            @dragMessage="dragMessage = $event"
                            :movingMessage="movingMessage"
                            :dragMessage="dragMessage"
                            :archive-message-fn="archiveMessage"
                            :flag-message-fn="flagMessage"
                            @showLinkOffice="$emit('showLinkOffice', $event)"
                            :user-principal="userPrincipal"
                    />
                </v-flex>
            </v-layout>
        </v-flex>
<!--Message Pane-->
        <v-flex :xs6="!showLinkOffice" :xs8="showLinkOffice" class="fill-height  pa-2" style="overflow-y: auto" v-if="officeConfig.running && !showLogin && selectedMessage != null">
            <template v-if="!loadedMessages.includes(selectedMessage.id)">

                <v-layout column justify-center align-center fill-height>
                    <v-flex shrink class="text-xs-center">
                        <v-progress-circular
                                :size="40"
                                :width="5"
                                color="primary"
                                indeterminate
                        ></v-progress-circular>
                    </v-flex>
                </v-layout>
            </template>
            <e-m-l-viewer v-else :filename="selectedMessage.filename" :cache="true" ref="emlView">
                <template slot="metaTop">
<!--Create Task-->
                    <v-tooltip top v-if="typeof userInfo.createTask != 'undefined' && intToBool(userInfo.createTask)">
                        <v-btn
                                icon
                                dark
                                small
                                color="secondary"
                                @click="$emit('createTaskOffice', {...selectedMessage, attachments: $refs.emlView.messageData.attachments}); $emit('showLinkOffice', false)"
                                slot="activator"
                                class="pt-0 mx-1 mt-1 elevation-5"
                        >
                            <v-icon small>add</v-icon>
                        </v-btn>
                        <span>Create Task</span>
                    </v-tooltip>
<!--Link To Task-->
                    <v-tooltip top>
                        <v-btn
                                icon
                                dark
                                small
                                color="secondary"
                                @click="$emit('showLinkOffice', true); $emit('setOfficeMail', {...selectedMessage, attachments: $refs.emlView.messageData.attachments})"
                                slot="activator"
                                class="pt-0 mx-1 mt-1 elevation-5"
                        >
                            <v-icon small>link</v-icon>
                        </v-btn>
                        <span>Link To Task</span>
                    </v-tooltip>
<!--Reply-->
                    <v-tooltip top>
                        <v-btn
                                icon
                                dark
                                small
                                color="secondary"
                                @click="newMail(1)"
                                slot="activator"
                                class="pt-0 mx-1 mt-1 elevation-5"
                        >
                            <v-icon small>reply</v-icon>
                        </v-btn>
                        <span>Reply</span>
                    </v-tooltip>
<!--Reply To All-->
                    <v-tooltip top>
                        <v-btn
                                icon
                                dark
                                small
                                color="secondary"
                                @click="newMail(2)"
                                slot="activator"
                                class="pt-0 mx-1 mt-1 elevation-5"
                        >
                            <v-icon small>reply_all</v-icon>
                        </v-btn>
                        <span>Reply To All</span>
                    </v-tooltip>
<!--Forward-->
                    <v-tooltip top>
                        <v-btn
                                icon
                                dark
                                small
                                color="secondary"
                                @click="newMail(3)"
                                slot="activator"
                                class="pt-0 mx-1 mt-1 elevation-5"
                        >
                            <v-icon small>forward</v-icon>
                        </v-btn>
                        <span>Forward Mail</span>
                    </v-tooltip>

                    <!--Tasks-->
                    <template v-if="typeof selectedMessage.tasks != 'undefined' && selectedMessage.tasks.length > 0 && !resetTasks">
                        <template v-for="task in selectedMessage.tasks">
                            <v-menu open-on-hover offset-y nudge-left="150px" :key="task">
                                <v-chip
                                        slot="activator"
                                        small
                                        label
                                        color="secondary"
                                        text-color="white"
                                        class="elevation-5"
                                >
                                    <v-icon small class="pr-2">assignment_turned_in</v-icon>
                                    Task #{{task}}
                                </v-chip>
                                <div style="width: 400px; background: var(--card-back); cursor: pointer">
                                    <task-loading-container
                                            v-if="typeof loadedTasks[task] == 'undefined' || loadedTasks[task] == null"
                                            :task-id="task"
                                    />
                                    <task-container
                                            v-else
                                            :userInfo="userInfo"
                                            :task="loadedTasks[task]"
                                            :taskBar="false"
                                            :showNav="false"
                                            @taskSelected="taskSelected(loadedTasks[task])"
                                    />
                                </div>
                            </v-menu>
                        </template>
                    </template>

                    <template v-if="typeof userInfo.createTask != 'undefined' && intToBool(userInfo.createTask)">
                        <v-tooltip
                                top
                                v-for="(qt, i) in [...userInfo.quickTasks].sort((a, b) => a.config.order - b.config.order)"
                                :key="i"
                        >
                            <v-btn
                                    icon
                                    dark
                                    outline
                                    small
                                    color="secondary"
                                    @click="$emit('createTaskOffice', {...selectedMessage, attachments: $refs.emlView.messageData.attachments, qt: qt.config}); $emit('showLinkOffice', false)"
                                    slot="activator"
                                    class="pt-0 mx-1 mt-1 elevation-5"
                            >
                                <v-icon small>{{ qt.config.icon }}</v-icon>
                            </v-btn>
                            <span>Create {{qt.config.description}} Task</span>
                        </v-tooltip>
                    </template>
                </template>
            </e-m-l-viewer>



<!--            <app-message-view-->
<!--                    :message="selectedMessage"-->
<!--                    :showLoading="false"-->
<!--                    :flag-message-fn="flagMessage"-->
<!--                    :user-info="userInfo"-->
<!--                    @showLinkOffice="$emit('showLinkOffice', $event)"-->
<!--                    :show-link-office="showLinkOffice"-->
<!--                    @setOfficeMail="$emit('setOfficeMail', $event)"-->
<!--                    :folder-groups="taskData.folderGroups"-->
<!--                    @createTaskOffice="$emit('createTaskOffice', $event)"-->
<!--                    @setQT="$emit('setQT', $event)"-->
<!--                    :new-message-fn="newMessageFn"-->
<!--                    :get-task-header="getTaskHeader"-->
<!--                    :loaded-messages="loadedMessages"-->
<!--                    :cur-view="curView"-->
<!--            />-->
        </v-flex>

        <create-mail v-model="showCompose" ref="createMail"/>

    </v-layout>
</template>

<script>
    import {codeBaseMixin} from "@/codeBaseMixin";
    import {eventBus} from "@/main";
    import Folders from "./Components/Folders";
    import SearchMenu from "./Components/SearchMenu";
    import MailBar from "./Components/MailBar";
    import MessageView from "./Components/MessageView";
    import MailboxSwitch from "./Components/mailboxSwitch";
    import tokenMan from '../../../tokenManSite';
    import {feed} from "@/feedSocket";
    import EMLViewer from "../../EML Viewer/EMLViewer";
    import TaskContainer from "../../../components/TaskContainer/TaskContainer";
    import TaskLoadingContainer from "../../../components/TaskContainer/TaskLoadingContainer";
    import CreateMail from "@/Mail/Create Mail/CreateMail";

    export default {
        components: {
            appMailboxSwitch: MailboxSwitch,
            appMessageView: MessageView,
            appMailBar: MailBar,
            appSearchMenu: SearchMenu,
            appFolders: Folders,
            EMLViewer: EMLViewer,
            TaskLoadingContainer,
            TaskContainer,
            CreateMail
        },
        mixins: [codeBaseMixin],
        props: {
            userInfo: Object,
            taskData: Object,
            showLinkOffice: Boolean,
            officeConfig: Object,
            newMessageFn: Function,
            getTaskHeader: Function,
            curView: String,
        },
        data() {
            return {
                showLogin: false,
                checkingUser: true,
                officeInboxId: null,
                folders: [],
                loadingFolders: false,
                selectedLabel: null,
                messageList: [],
                nextLink: "",
                searchOptions: {
                    searchText: "",
                    from: "",
                    to: "",
                    subject: "",
                    hasWords: "",
                    noWords: "",
                    hasAttachment: false,
                    starred: false
                },
                showSearch: false,
                loadingMessages: false,
                selectedMessage: null,
                loadingMoreMessages: false,
                dragMessage: null,
                movingMessage: false,
                userPrincipalDefault: null,
                userPrincipal: null,
                loadedMessages: [],
                loadedTasks: [],
                resetTasks: false,
                showCompose: false
            }
        },
        watch: {

            "selectedMessage.tasks": {
                deep: true,
                immediate: false,
                handler(tasks) {
                    tasks.forEach((task) => {
                        if (typeof this.loadedTasks[task] == 'undefined') {
                            this.getTaskHeader(task)
                                    .then((data) => {
                                        this.loadedTasks[data.taskID] = data
                                        this.resetTasks = true;
                                        this.$nextTick(() => {
                                            this.resetTasks = false;
                                        })
                                    })
                                    .catch(e => {console.log(e)})
                        }
                    })
                }
            }

        },
        created() {
            eventBus.$on('mailLinkedToTaskOffice', this.mailLinkedToTaskOffice);
            feed.on('microsoftOfficeMessagesDownload', this.microsoftOfficeMessagesDownload);

            this.getAndSetDefaultuserPrincipal();
        },
        beforeDestroy() {
            eventBus.$off('mailLinkedToTaskOffice', this.mailLinkedToTaskOffice);
            feed.off('microsoftOfficeMessagesDownload', this.microsoftOfficeMessagesDownload);
        },
        mounted() {
            this.initOffice();
        },
        methods: {
            mailLinkedToTaskOffice(data) {
                this.messageList.forEach((message) => {
                    if (data.mail.id == message.id) {
                        if (typeof message.tasks == 'undefined') {
                            message.tasks = []
                        }
                        message.tasks.push(data.task.taskID);
                    }
                })
            },
            microsoftOfficeMessagesDownload(data) {
                this.loadedMessages = this.loadedMessages.concat(data)
            },

            newMail(composeType) {
                // 0 - Normal Compose, 1 - Reply, 2 - Reply to all, 3 - Forward
                this.$refs.createMail.init(composeType, composeType > 0 ? this.selectedMessage.filename : null, 'cache')
            },

            taskSelected(task) {
                eventBus.$emit('gotoTask', task)
            },

            getAndSetDefaultuserPrincipal() {
                if (this.officeConfig.loadingAddress) {
                    setTimeout(() => {
                        this.getAndSetDefaultuserPrincipal()
                    }, 1000)
                } else {
                    if (this.officeConfig.addresses.length >= 1) {
                        this.userPrincipal = {
                            id: 0,
                            default: true,
                            address: this.officeConfig.addresses[0].address
                        };
                        this.userPrincipalDefault = {
                            id: 0,
                            default: true,
                            address: this.officeConfig.addresses[0].address
                        }
                    }
                }
            },
            flagMessage(message) {
                message.starred = !message.starred;
                this.fetch({
                    method: 'POST',
                    url: `/microsoft/updateFlag/${this.userInfo.userID}`,
                    body: JSON.stringify({message: message.id, value: message.starred})
                })
                    .then((data) => {
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e);
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
                        message.starred = !message.starred;
                    })
            },
            archiveMessage(message) {
                this.$emit('showLinkOffice', false)
                this.dragMessage = message;
                this.moveMessageToFolder({id: 'archive'});
            },
            selectMessage(message) {
                this.selectedMessage = message;
                if (typeof this.userPrincipal.default != 'undefined' && this.userPrincipal.default) {
                    this.markAsRead(message)
                }
            },
            markAsRead(message) {
                message.isRead = true;
                this.fetch({
                    method: 'POST',
                    url: `/microsoft/readMessage/${this.userInfo.userID}`,
                    body: JSON.stringify({message: message.id})
                })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e);
                    })
            },
            moveMessageToFolder(folderObj) {
                if (this.dragMessage != null && folderObj != null) {
                    this.movingMessage = true;
                    this.fetch({
                        method: 'POST',
                        url: `/microsoft/moveMessageToFolder/${this.userInfo.userID}`,
                        body: JSON.stringify({
                            folder: folderObj.id,
                            message: this.dragMessage.officeID
                        })
                    })
                        .then(() => {
                            this.messageList = this.messageList.filter((message) => message.id != this.dragMessage.id);
                            this.dragMessage = null;
                            this.movingMessage = false;
                            this.selectedMessage = null;
                        })
                        .catch((e) => {
                            // eslint-disable-next-line
                            console.log(e);
                            this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                            this.loadingMoreMessages = false;
                            this.movingMessage = false;
                        })
                }
            },
            initOffice() {
                if (this.officeConfig.loadingAddress || this.userPrincipal == null) {
                    setTimeout(() => {
                        this.initOffice()
                    }, 100)
                } else {
                    if (this.officeConfig.running) {
                        this.getFoldersForUser();
                        this.getMessagesForFolder('inbox');
                    }
                }
            },
            authorize() {
                this.fetch({method: 'GET', url:`/microsoft/getAuthUrl/${tokenMan.token()}`})
                    .then((data) => {
                        window.location.href = `${data.authUrl}`;
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e)
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                    })
            },
            getFoldersForUser() {
                this.loadingFolders = true;
                this.fetch({method: 'GET', url:`/microsoft/GetFolders/${this.userInfo.entityID}/${this.userInfo.userID}/${this.officeInboxId == null ? '-' : this.officeInboxId}/${this.userPrincipal.address}`})
                    .then((data) => {
                        this.selectedLabel = {
                            id: data.inboxId,
                            displayName: 'Inbox'
                        },
                            this.loadingFolders = false;
                        this.folders = data.folders.map((obj) => {
                            switch (obj.displayName) {
                                case 'Inbox': {
                                    obj.icon = 'inbox'; break
                                }
                                case 'Deleted Items': {
                                    obj.icon = 'delete'; break
                                }
                                case 'Drafts': {
                                    obj.icon = 'drafts'; break
                                }
                                case 'Junk Email': {
                                    obj.icon = 'delete'; break
                                }
                                case 'Spambox': {
                                    obj.icon = 'not_interested'; break
                                }
                                case 'Sent Items': {
                                    obj.icon = 'send'; break
                                }
                                case 'Archive': {
                                    obj.icon = 'archive'; break
                                }
                                default: {
                                    obj.icon = 'folder'
                                }
                            }
                            return obj
                        });
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e)
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                    })
            },
            getChildFolders(item) {
                return new Promise((resolve, reject) => {
                    this.fetch({method: 'GET', url:`/microsoft/getFolderChildrenCache/${this.userInfo.entityID}/${this.userInfo.userID}/${item.id}`})
                        .then((data) => {

                            if (data.length == 0) {
                                // eslint-disable-next-line


                                this.getChildFoldersFull(item.id)
                                    .then((data) => {
                                        item.children = data;
                                        resolve(true)
                                    })
                                    .catch((e) => {
                                        console.log(e);
                                        reject(e)
                                    })
                            } else {

                                item.children = data;
                                this.getChildFoldersFull(item.id)
                                    .then((data) => {

                                        let refObj = {}
                                        data.forEach((folder) => {
                                            refObj[folder.id] = folder
                                        })





                                        // Check Removals First
                                        item.children.forEach((folder, i) => {
                                            if (typeof refObj[folder.id] == 'undefined') {
                                                item.children.splice(i,1);

                                            } else {
                                                //Compare The 2 Objects
                                                folder.displayName = refObj[folder.id].displayName;

                                                //Check For Removal of The CHild Property
                                                if (typeof refObj[folder.id].children == "undefined" && typeof folder.children != 'undefined') {
                                                    // delete folder.children
                                                    item.children.splice(i,refObj[folder.id]);
                                                    item.children = item.children.filter(() => true);
                                                }

                                                //Check For Adding Of Children Property
                                                if (typeof refObj[folder.id].children != "undefined" && typeof folder.children == 'undefined') {
                                                    folder.children = [];
                                                }
                                            }
                                        });

                                        let tPushCheckArr = item.children.map((obj) => {
                                            return obj.id
                                        });

                                        data.forEach((folder) => {
                                            if (!tPushCheckArr.includes(folder.id)) {
                                                item.children.push(folder)
                                            }
                                        })

                                    })
                                    .catch((e) => {
                                        console.log(e);
                                        reject(e)
                                    })
                                resolve(true)
                            }
                        })
                        .catch((e) => {
                            // eslint-disable-next-line
                            console.log(e)
                            this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                            reject(e)
                        })
                })
            },
            getChildFoldersFull(itemID) {
                return new Promise((resolve, reject) => {
                    this.fetch({
                        method: 'GET',
                        url: `/microsoft/getFolderChildren/${this.userInfo.entityID}/${this.userInfo.userID}/${itemID}/${this.userPrincipal.address}`
                    })
                        .then((data) => {
                            // this.showSnack('Info', 'full Get Done', 'Close', 'primary')
                            resolve(data);
                        })
                        .catch((e) => {
                            // eslint-disable-next-line
                            console.log(e)
                            this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                            reject(e)
                        })
                })
            },
            getMessagesForFolder(folderID) {
                this.loadingMessages = true;
                this.fetch({
                    method: 'GET',
                    url: `/microsoft/getMessagesForFolder/${this.userInfo.entityID}/${this.userInfo.userID}/${folderID}/${this.userPrincipal.address}`
                })
                    .then((data) => {
                        this.messageList = data.messages;
                        this.nextLink = data.nextlink;

                        this.loadingMessages = false;
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e);
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                        this.loadingMessages = false;
                    })
            },
            getSearchMessages() {
                this.nextLink = null;
                this.loadingMessages = true;
                this.fetch({
                    method: 'POST',
                    url: `/microsoft/getMessagesSearch/${this.userInfo.entityID}/${this.userInfo.userID}/${this.userPrincipal.address}`,
                    body: JSON.stringify(this.searchOptions)
                })
                    .then((data) => {
                        this.messageList = data.messages;
                        this.nextLink = data.nextlink;
                        this.loadingMessages = false;
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e);
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                        this.loadingMessages = false;
                    })
            },
            loadMoreMessages() {
                this.loadingMoreMessages = true;
                this.fetch({
                    method: 'POST',
                    url: `/microsoft/getMoreMessages/${this.userInfo.entityID}/${this.userInfo.userID}`,
                    body: JSON.stringify({nextLink: this.nextLink})
                })
                    .then((data) => {
                        this.messageList = this.messageList.concat(data.messages);
                        this.nextLink = data.nextlink;
                        this.loadingMoreMessages = false;
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e);
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                        this.loadingMoreMessages = false;
                    })
            },
        },
    }
</script>

<style scoped>

</style>
