<template>
    <div v-if="ready" class="fill-height">
        <v-card hover class="fill-height c-d-flex c-flex-column">
            <v-card-title
                    class="primary pa-0 white--text"
            >
                <v-layout row align-center>
                    <v-flex shrink style="white-space: nowrap" class="pa-2">
                        {{displayTitle}}
                    </v-flex>
                    <v-flex xs12 class="px-2">
                        <div class="text-xs-right"><slot name="title-bar"></slot></div>
                    </v-flex>
                </v-layout>

            </v-card-title>

            <v-tabs
                    :value="tabIndex"
                    @change="$emit('tabIndexChange', $event)"
                    color="primary"
                    slider-color="secondary"
                    grow
                    show-arrows
            >
                <v-tab class="white--text">Signature Options</v-tab>
                <v-tab class="white--text">Mail Body</v-tab>
            </v-tabs>

            <div class="px-2 pb-2" v-if="tabIndex === 0">
<!--Description-->
                <v-layout row align-center class=" pt-2">
                    <v-flex xs4 class="greyType--text">
                        Description:
                    </v-flex>
                    <v-flex xs8 class="px-1">
                        <v-text-field
                                color="primary"
                                label="Description"
                                class="my-0 py-0"
                                hide-details
                                single-line
                                v-model="value.description"
                        ></v-text-field>
                    </v-flex>
                </v-layout>
<!--Email Address-->
                <v-layout row align-center class="  pt-2">
                    <v-flex xs4 class="greyType--text">
                        Email Address:
                    </v-flex>
                    <v-flex xs8 class="px-1">
                        <v-menu offset-y>
                            <template v-slot:activator="{ on }">
                                <v-card
                                        class="secondary white--text body-1 px-2"
                                        hover
                                        v-on="on"
                                >
                                    <v-layout row align-center>
                                        <v-flex xs12>
                                            {{displayEmail}}
                                        </v-flex>
                                        <v-flex shrink>
                                            <v-btn small icon flat class="pa-0 ma-0" color="white">
                                                <v-icon small>edit</v-icon>
                                            </v-btn>
                                        </v-flex>
                                    </v-layout>
                                </v-card>
                            </template>
                            <v-list dense>
                                <v-list-tile
                                        v-for="srcType in authSources"
                                        @click="addSrcRun(srcType.id)"
                                        :key="srcType.id"
                                >
                                    <v-list-tile-title>{{ srcType.description }}</v-list-tile-title>
                                </v-list-tile>
                            </v-list>
                        </v-menu>
                    </v-flex>
                </v-layout>

<!--OTP Number-->
                <v-layout row align-center class=" pt-2">
                    <v-flex xs4 class="greyType--text">
                        OTP Number:
                    </v-flex>
                    <v-flex xs8 class="px-1">
                        <v-menu offset-y>
                            <template v-slot:activator="{ on }">
                                <v-card
                                        class="secondary white--text body-1 px-2"
                                        hover
                                        v-on="on"
                                >
                                    <v-layout row align-center>
                                        <v-flex xs12>
                                            {{displayOTP}}
                                        </v-flex>
                                        <v-flex shrink>
                                            <v-btn small icon flat class="pa-0 ma-0" color="white">
                                                <v-icon small>edit</v-icon>
                                            </v-btn>
                                        </v-flex>
                                    </v-layout>
                                </v-card>
                            </template>
                            <v-list dense>
                                <v-list-tile
                                        v-for="srcType in authSources"
                                        @click="addSrcRun(srcType.id, false)"
                                        :key="srcType.id"
                                >
                                    <v-list-tile-title>{{ srcType.description }}</v-list-tile-title>
                                </v-list-tile>
                            </v-list>
                        </v-menu>
                    </v-flex>
                </v-layout>

<!--Confirm Message-->
                <v-layout row class=" pt-1 scrollable" style="max-height: 300px">
                    <v-flex xs4 class="greyType--text py-1">
                        Signature Heading:
                    </v-flex>
                    <v-flex xs8 class="px-1 py-1">
                        <v-textarea
                                color="primary"
                                label="Signature Heading"
                                class="my-0 py-0"
                                hide-details
                                single-line
                                v-model="value.message"
                                :rows="2"
                                auto-grow
                        ></v-textarea>
                    </v-flex>
                </v-layout>

<!--Signature Disclaimer-->
                <v-layout row class=" pt-1 scrollable" style="max-height: 300px">
                    <v-flex xs4 class="greyType--text py-1">
                        Signature Disclaimer:
                    </v-flex>
                    <v-flex xs8 class="px-1 py-1">
                        <v-textarea
                                color="primary"
                                label="Signature Disclaimer"
                                class="my-0 py-0"
                                hide-details
                                single-line
                                v-model="value.disclaimer"
                                :rows="2"
                                auto-grow
                        ></v-textarea>
                    </v-flex>
                </v-layout>

<!--Subject-->
                <v-layout row class=" pt-1 scrollable" style="max-height: 300px">
                    <v-flex xs4 class="greyType--text py-1">
                        Signature Email Subject:
                    </v-flex>
                    <v-flex xs8 class="px-1 py-1">
                        <v-text-field
                                color="primary"
                                label="Default"
                                class="my-0 py-0"
                                hide-details
                                single-line
                                v-model="value.mailSubject"
                        ></v-text-field>
                    </v-flex>
                </v-layout>
<!--Body-->


            </div>
<!--            <v-layout row class="c-flex-grow-0 pt-1 scrollable" style="max-height: 300px">-->
<!--                <v-flex xs4 class="greyType&#45;&#45;text pb-1 px-2">-->
<!--                    Signature Email Body:-->
<!--                </v-flex>-->
<!--            </v-layout>-->
            <div v-if="ready && tabIndex === 1" class="c-flex-grow-1" :style="typeof index == 'undefined' ? 'height: 500px' : ''">
                <editor
                        v-model="bodyRelay"
                        :extra-config="editorConfig"
                />
            </div>

<!-- Src Workflow Field-->
            <app-input-dlg
                    v-if="addSrcType === 1"
                    :show-dialog="addSrcType === 1"
                    title="Select Workflow Field"
                    :cancel-button="true"
                    :show-input-box="false"
                    @dismiss="addSrcType = null"
                    :body-padding="false"
            >
                <template slot="body">
                    <div class="scrollable" style="max-height: 500px">
                        <v-list dense style="max-height: 300px; overflow-y: auto" class="pt-0">
                            <template v-for="item in srcFields">
                                <template v-if="item.type === 'step'">
                                    <v-subheader class="white--text primary" :key="`step-${item.id}`">{{item.description}}</v-subheader>
                                    <v-divider :key="`divStep-${item.id}`"></v-divider>
                                </template>
                                <template v-else>
                                    <v-list-tile
                                            :key="`field-${item.fk_step}-${item.id}`"
                                            @click="setSrcVal(item.id)"
                                    >
                                        {{item.description}}
                                    </v-list-tile>
                                </template>

                            </template>
                        </v-list>
                    </div>

                </template>
            </app-input-dlg>
<!--Src Dataset Field-->
            <app-input-dlg
                    v-if="addSrcType === 2"
                    :show-dialog="addSrcType === 2"
                    title="Select Dataset Field"
                    :cancel-button="true"
                    :show-input-box="false"
                    @dismiss="addSrcType = null"
                    :body-padding="false"
            >
                <template slot="body">
                    <div class="pa-2">
                        <field-set-field-selector
                                :fields="fields"
                                :field-sets="datasets"
                                :field-type-filter="[1]"
                                :include-auto-populate="false"
                                @input="setSrcVal($event)"
                        />
                    </div>
                </template>
            </app-input-dlg>

            <div class="text-xs-right"><slot name="buttons"></slot></div>
        </v-card>
    </div>
</template>

<script>
    import {blankString, getUUID} from "../../../../../../codeFunctions";
    import InputDlg from "../../../../../../components/General/InputDlg";
    import FieldSetFieldSelector from "../../Workflow/Components/FieldSetFieldSelector";
    import Editor from "@/Editor/Editor";

    export default {
        components: {Editor, FieldSetFieldSelector, appInputDlg: InputDlg},
        props: {
            userInfo: Object,
            value: Object,
            datasets: Array,
            fields: Array,
            steps: Array,
            selectLists: Object,
            index: Number,
            tabIndex: {
                type: Number,
                default: 0
            }
        },
        created() {
            if (typeof this.value === 'undefined' || this.value === null) {
                this.$emit('input', {
                    id: getUUID(),
                    description: null,
                    message: null,
                    disclaimer: null,
                    emailType: 0, // 0-User Input 1-Workflow Field 2-Dataset Field
                    emailValue: null,
                    OTPType: 0, // 0-User Input 1-Workflow Field 2-Dataset Field
                    OTPValue: null,
                    mailSubject: null,
                    mailBody: null
                });
                setTimeout(() => {this.ready = true}, 500);
            } else {
                if (typeof this.value.mailSubject === "undefined")
                    this.value.mailSubject = null

                if (typeof this.value.mailBody === "undefined")
                    this.value.mailBody = null

                this.ready = true
            }

        },
        data() {
            return {
                ready: false,
                authSources: [
                    {id: 0, description: 'User Input'},
                    {id: 1, description: 'Workflow Field'},
                    {id: 2, description: 'Dataset Field'},
                ],
                addSrcType: null,
                addSrcVal: null,
                addSrcToEmail: true,
                editorConfig: {
                    paste_data_images: true,
                    remove_linebreaks : false,
                    images_reuse_filename: true,
                    init_instance_callback: function(editor) {
                        editor.focus()
                    },
                },

            }
        },
        methods: {
            addSrcRun(srcType, emailField = true) {
                if (srcType === 0) {
                    if (emailField) {
                        this.value.emailType = 0;
                        this.value.emailValue = null;
                    } else {
                        this.value.OTPType = 0;
                        this.value.OTPValue = null;
                    }
                } else {
                    this.addSrcVal = null;
                    this.addSrcToEmail = emailField;
                    this.addSrcType = srcType;
                }
            },

            setSrcVal(val) {
                this.addSrcVal = val;
                if (this.addSrcToEmail) {
                    this.value.emailType = this.addSrcType;
                    this.value.emailValue = val;
                } else {
                    this.value.OTPType = this.addSrcType;
                    this.value.OTPValue = val;
                }
                this.addSrcType = null;
                this.addSrcVal = null;
            },
        },
        computed: {
            bodyRelay: {
                get() {
                    return this.value.mailBody || '<br/>'
                },
                set(val) {
                    this.value.mailBody = val
                }
            },
            displayTitle() {
                return this.value == null || blankString(this.value.description) ? (typeof this.index == 'undefined' ? 'Add Signature' :  `Signature ${this.index + 1}`) : this.value.description;
            },
            displayEmail() {
                switch (this.value.emailType) {
                    case 0: {
                        return 'User Input';
                    }
                    case 1: {
                        // Workflow Field
                        return this.fields.filter(obj => obj.id === this.value.emailValue).map(obj => {return obj.description}).pop();
                    }
                    case 2: {
                        // Dataset Field
                        let oSelectField = this.fields.filter(obj => obj.id === this.value.emailValue.fieldSetSelectField).pop();

                        let oField = this.datasets
                            .filter(obj => obj.id === parseInt(oSelectField.config.dataset))
                            .map(obj => {return obj.fields.filter(field => field.id === this.value.emailValue.field).pop()})
                            .pop();
                        return `${oSelectField.description} - ${oField.description}`
                    }
                    default : {return null}
                }
            },
            displayOTP() {
                switch (this.value.OTPType) {
                    case 0: {
                        return 'User Input';
                    }
                    case 1: {
                        // Workflow Field
                        return this.fields.filter(obj => obj.id === this.value.OTPValue).map(obj => {return obj.description}).pop();
                    }
                    case 2: {
                        // Dataset Field
                        let oSelectField = this.fields.filter(obj => obj.id === this.value.OTPValue.fieldSetSelectField).pop();

                        let oField = this.datasets
                            .filter(obj => obj.id === parseInt(oSelectField.config.dataset))
                            .map(obj => {return obj.fields.filter(field => field.id === this.value.OTPValue.field).pop()})
                            .pop();
                        return `${oSelectField.description} - ${oField.description}`
                    }
                    default : {return null}
                }
            },
            srcFields() {
                let tFields = this.fields
                    .filter(obj => obj.visible === 1 && obj.fieldType === 1)
                    .sort((a,b) => a['field_order'] < b['field_order'] ? -1 : 1);
                let tStepIDs = tFields.map(obj => {return obj.fk_step});
                let tSteps = this.steps
                    .filter(item => tStepIDs.includes(item.id) && item.hidden === 0)
                    .sort((a,b) => a['field_order'] < b['field_order'] ? -1 : 1);

                let tList = tSteps.reduce((list, item) => {
                    list.push({
                        id: item.id,
                        type: 'step',
                        description: item.description
                    });
                    list = list.concat(tFields
                        .filter(obj => obj.fk_step === item.id)
                        .map(obj => {
                            return {
                                id: obj.id,
                                description: obj.description,
                                type: 'field',
                                fk_step: obj.fk_step
                            }
                        })
                    );
                    return list;
                },[]);
                return tList
            },
        }
    }
</script>

<style scoped>

</style>
