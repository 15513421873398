<template>
    <v-dialog
            v-model="valuePassOver"
            fullscreen
    >
        <v-card v-if="valuePassOver" class="scrollable" style="height: 100vh">
            <v-layout column fill-height class="scrollable">
<!--Compose Title-->
                <v-flex shrink class="primary">
                    <v-layout row align-center>
                        <v-flex xs12>
                            <v-layout row align-center>
                                <v-flex shrink>
                                    <v-icon class="pl-2" color="white">mail_outline</v-icon>
                                </v-flex>
                                <v-flex xs12 class="font-weight-regular white--text px-2 py-1 title">
                                    Compose Email
                                </v-flex>
                            </v-layout>
                        </v-flex>
                        <v-flex shrink class="px-2">
                            <v-btn
                                    icon
                                    flat
                                    color="white"
                                    class="pa-0 ma-0"
                                    @click="valuePassOver = !valuePassOver"
                            >
                                <v-icon>close</v-icon>
                            </v-btn>
                        </v-flex>
                    </v-layout>
                </v-flex>

<!--Loading-->
                <template v-if="loading">
                    <v-flex xs12 class="scrollable">
                        <v-layout column fill-height align-center justify-center>
                            <v-flex shrink>
                                <v-progress-circular
                                        :size="40"
                                        :width="3"
                                        color="primary"
                                        indeterminate
                                ></v-progress-circular>
                            </v-flex>
                        </v-layout>

                    </v-flex>
                </template>
<!--Content-->
                <template v-else>
<!--From Address / Signature-->
                    <v-flex shrink>
                        <v-layout row align-center>
<!--From Address-->
                            <v-flex xs6>
                                <v-autocomplete
                                        dense
                                        :items="fromAddresses"
                                        item-text="address"
                                        item-value="refKey"
                                        label="From:"
                                        hide-details
                                        solo
                                        flat
                                        v-model="sendAddress"
                                        return-object
                                        ref="fromInput"
                                >
                                    <template slot="prepend-inner">
                                        <div class="pr-2">
                                            <v-icon v-if="sendAddress == null || typeof sendAddress.type == 'undefined'">mail</v-icon>
                                            <template v-else>
                                                <img v-if="sendAddress.type == 'gmail'" src="../../../public/logo_gmail_32px.svg" class="nav-item" height="22px">
                                                <img v-if="sendAddress.type == 'office'" src="../../../public/outlook_32x1.svg" class="nav-item"  height="32px">
                                                <img v-if="sendAddress.type == 'smtp'" src="../../../public/smtp.svg" class="nav-item"  height="32px">
                                            </template>
                                        </div>

                                    </template>
                                    <template slot="item" slot-scope="address">
                                        <v-layout row align-center class="hide-parent">
                                            <v-flex shrink class="pr-2">
                                                <img v-if="address.item.type == 'gmail'" src="../../../public/logo_gmail_32px.svg" class="nav-item" height="18px">
                                                <img v-if="address.item.type == 'office'" src="../../../public/outlook_32x1.svg" class="nav-item"  height="22px">
                                                <img v-if="address.item.type == 'smtp'" src="../../../public/smtp.svg" class="nav-item"  height="22px">
                                            </v-flex>
                                            <v-flex xs12 class="body-1">
                                                {{address.item.address}}
                                            </v-flex>

                                            <v-flex shrink v-if="defaultFrom.address == address.item.address && defaultFrom.type == address.item.type">
                                                <v-tooltip top>
                                                    <v-btn small icon flat color="amber" slot="activator" class="pa-0 ma-0">
                                                        <v-icon>
                                                            star
                                                        </v-icon>
                                                    </v-btn>
                                                    <span>Default Send Address</span>
                                                </v-tooltip>
                                            </v-flex>

                                            <v-flex shrink v-else class="hide-child">
                                                <v-tooltip top>
                                                    <v-btn small icon flat color="primary" slot="activator" class="pa-0 ma-0" @click="setDefaultSendAddress(address.item)">
                                                        <v-icon>
                                                            star_outline
                                                        </v-icon>
                                                    </v-btn>
                                                    <span>Mark Address As Default</span>
                                                </v-tooltip>
                                            </v-flex>

                                        </v-layout>
                                    </template>
                                </v-autocomplete>
                            </v-flex>
<!--Signature-->
                            <v-flex xs6>
                                <v-autocomplete
                                        dense
                                        :items="signatures"
                                        item-text="description"
                                        return-object
                                        label="Signature:"
                                        hide-details
                                        prepend-inner-icon="edit"
                                        solo
                                        flat
                                        v-model="signature"
                                        @change="signatureChange($event)"
                                        ref="signatureInput"
                                >
                                </v-autocomplete>
                            </v-flex>

                            <v-flex shrink class="px-2" v-if="totalAttSize.val">
                                <v-tooltip left>
                                    <template v-slot:activator="{ on }">
<!--                                        <v-avatar  :size="35" >-->
                                            <div
                                                    v-on="on"
                                                    :class="totalAttSize.unit === 'MB' && totalAttSize.val > 10 ? 'red--text' :'primary--text'"
                                                    class="caption text-xs-center font-weight-bold"
                                                    style="white-space: nowrap; cursor: pointer"
                                            >
                                                {{totalAttSize.val}} {{totalAttSize.unit}}
                                            </div>
<!--                                        </v-avatar>-->
                                    </template>
                                    {{totalAttSize.val}} {{totalAttSize.unit}} Estimated Mail Size
                                </v-tooltip>
                            </v-flex>
                        </v-layout>
                    </v-flex>
                    <v-flex shrink><v-divider></v-divider></v-flex>
<!--To Address-->
                    <v-flex shrink>
                        <v-layout row align-center>
                            <v-flex xs12>
                                <v-combobox
                                        dense
                                        :items="contacts"
                                        item-text="display"
                                        label="To:"
                                        hide-details
                                        prepend-inner-icon="people"
                                        solo
                                        flat
                                        v-model="to"
                                        hide-selected
                                        ref="toInput"
                                        multiple
                                        @input="validateToEmailAddress($event)"
                                >
                                    <template slot="item" slot-scope="address">


                                        <div class="c-d-flex c-align-center c-flex-grow-1">

                                            <div style="width: 50px; max-height: 30px" class="c-d-flex c-justify-center text-xs-center">
                                                <v-icon v-if="address.item.taskContact" color="primary">label</v-icon>
                                                <template v-else>
                                                    <img v-if="address.item.type === 'gmail'" src="../../../public/logo_gmail_32px.svg" class="nav-item" height="22px">
                                                    <img v-if="address.item.type === 'office'" src="../../../public/outlook_32x1.svg" class="nav-item"  height="32px">
                                                    <img v-if="address.item.type === 'smtp'" src="../../../public/smtp.svg" class="nav-item"  height="22px">
                                                </template>
                                            </div>

                                            <div class="body-1 c-flex-grow-1">{{ address.item.display }}</div>

                                            <div v-if="address.item.type === 'smtp'">
                                                <v-tooltip top>
                                                    <template v-slot:activator="{on}">
                                                        <v-btn
                                                                small
                                                                icon
                                                                flat
                                                                class="pa-0 ma-0 mx-2"
                                                                @click="removeSmtpContact(address.item)"
                                                                color="red"
                                                                v-on="on"
                                                        >
                                                            <v-icon>delete</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    Remove Contact
                                                </v-tooltip>
                                            </div>
                                        </div>
                                    </template>
                                    <template
                                            slot="selection"
                                            slot-scope="address"
                                    >
                                        <div class="pr-2" style="padding-top: 2px; padding-bottom: 2px">
                                            <v-layout shrink row align-center
                                                      style="border-radius: 3px; border: 1px solid var(--v-primary-base);">
                                                <v-flex shrink class="caption pl-2">
                                                    <div class="font-weight-bold primary--text">{{ address.item.name || address.item.display }}&nbsp;
                                                    </div>
                                                    <div style="margin-top: -5px" class="greyType--text"
                                                         v-if="address.item.name != ''">{{ address.item.email }}&nbsp;
                                                    </div>
                                                </v-flex>
                                                <v-flex
                                                        shrink
                                                        class="pr-1"
                                                        style="padding-top: 2px; padding-bottom: 2px"
                                                >
                                                    <v-btn
                                                            small
                                                            icon
                                                            flat
                                                            class="pa-0 ma-0"
                                                            color="primary"
                                                            @click="address.parent.selectItem(address.item)"
                                                    >
                                                        <!--@click="mailAttachments.splice(i, 1)"-->
                                                        <v-icon small>close</v-icon>
                                                    </v-btn>
                                                </v-flex>
                                            </v-layout>
                                        </div>
                                    </template>
                                </v-combobox>
                            </v-flex>
                            <v-flex shrink class="pr-2" v-if="!showCc">
                                <v-tooltip left>
                                    <v-btn icon flat class="pa-0 ma-0" slot="activator" @click="showCc = true"
                                           color="secondary">Cc
                                    </v-btn>
                                    <span>Add Cc Address</span>
                                </v-tooltip>
                            </v-flex>
<!--                            <v-flex shrink class="pr-2" v-if="!showBcc && !!sendAddress && !!sendAddress.type && sendAddress.type !== 'gmail'">-->
<!--                                <v-tooltip left>-->
<!--                                    <v-btn icon flat class="pa-0 ma-0" slot="activator" @click="showBcc = true"-->
<!--                                           color="secondary">Bcc-->
<!--                                    </v-btn>-->
<!--                                    <span>Add Bcc Address</span>-->
<!--                                </v-tooltip>-->
<!--                            </v-flex>-->
                        </v-layout>
                    </v-flex>
                    <v-flex shrink><v-divider></v-divider></v-flex>
<!--CC-->
                    <template v-if="showCc">
                        <v-flex shrink>
                            <v-combobox
                                    dense
                                    :items="contacts"
                                    item-text="display"
                                    label="Cc:"
                                    hide-details
                                    prepend-inner-icon="people_outline"
                                    solo
                                    flat
                                    v-model="cc"
                                    hide-selected
                                    ref="ccInput"
                                    multiple
                                    @input="validateCcEmailAddress($event)"
                            >
                                <template slot="item" slot-scope="address">
                                    <v-icon left :color="!address.item.taskContact ? 'primary' : 'secondary'">label
                                    </v-icon>
                                    <span class="body-1">{{ address.item.display }}</span>
                                </template>
                                <template
                                        slot="selection"
                                        slot-scope="address"
                                >
                                    <div class="pr-2" style="padding-top: 2px; padding-bottom: 2px">
                                        <v-layout shrink row align-center
                                                  style="border-radius: 3px; border: 1px solid var(--v-primary-base);">
                                            <v-flex shrink class="caption pl-2">
                                                <div class="font-weight-bold primary--text">{{
                                                        address.item.name != '' ? address.item.name : address.item.display
                                                    }}&nbsp;
                                                </div>
                                                <div style="margin-top: -5px" class="greyType--text"
                                                     v-if="address.item.name != ''">{{ address.item.email }}&nbsp;
                                                </div>
                                            </v-flex>
                                            <v-flex
                                                    shrink
                                                    class="pr-1"
                                                    style="padding-top: 2px; padding-bottom: 2px"
                                            >
                                                <v-btn
                                                        small
                                                        icon
                                                        flat
                                                        class="pa-0 ma-0"
                                                        color="primary"
                                                        @click="address.parent.selectItem(address.item)"
                                                >
                                                    <!--@click="mailAttachments.splice(i, 1)"-->
                                                    <v-icon small>close</v-icon>
                                                </v-btn>
                                            </v-flex>
                                        </v-layout>
                                    </div>
                                </template>
                            </v-combobox>
                        </v-flex>
                        <v-flex shrink><v-divider></v-divider></v-flex>
                    </template>
<!--BCC-->
                    <template v-if="showCc">
                        <v-flex shrink>
                            <v-combobox
                                    dense
                                    :items="contacts"
                                    item-text="display"
                                    label="Bcc:"
                                    hide-details
                                    prepend-inner-icon="admin_panel_settings"
                                    solo
                                    flat
                                    v-model="bcc"
                                    hide-selected
                                    ref="bccInput"
                                    multiple
                                    @input="validateBccEmailAddress($event)"
                            >
                                <template slot="item" slot-scope="address">
                                    <v-icon left :color="!address.item.taskContact ? 'primary' : 'secondary'">label</v-icon>
                                    <span class="body-1">{{address.item.display}}</span>
                                </template>
                                <template
                                        slot="selection"
                                        slot-scope="address"
                                >
                                    <div class="pr-2" style="padding-top: 2px; padding-bottom: 2px">
                                        <v-layout shrink row align-center style="border-radius: 3px; border: 1px solid var(--v-primary-base);">
                                            <v-flex shrink class="caption pl-2">
                                                <div class="font-weight-bold primary--text">{{ address.item.name != '' ? address.item.name : address.item.display }}&nbsp;</div>
                                                <div style="margin-top: -5px" class="greyType--text" v-if="address.item.name != ''" >{{ address.item.email }}&nbsp;</div>
                                            </v-flex>
                                            <v-flex
                                                    shrink
                                                    class="pr-1"
                                                    style="padding-top: 2px; padding-bottom: 2px"
                                            >
                                                <v-btn
                                                        small
                                                        icon
                                                        flat
                                                        class="pa-0 ma-0"
                                                        color="primary"
                                                        @click="address.parent.selectItem(address.item)"
                                                >
                                                    <!--@click="mailAttachments.splice(i, 1)"-->
                                                    <v-icon small>close</v-icon>
                                                </v-btn>
                                            </v-flex>
                                        </v-layout>
                                    </div>
                                </template>
                            </v-combobox>
                        </v-flex>
                        <v-flex shrink><v-divider></v-divider></v-flex>
                    </template>
<!--Subject-->
                    <v-flex shrink>
                        <v-text-field
                                dense
                                label="Subject:"
                                hide-details
                                prepend-inner-icon="subject"
                                solo
                                flat
                                v-model="subject"
                        />
                    </v-flex>
                    <v-flex shrink><v-divider></v-divider></v-flex>
<!--Attachments-->
                    <v-flex
                            shrink
                            class="pt-1 pb-0 px-1"
                    >
                        <div
                                style="width: 100%"
                                @dragover="$event.preventDefault()"
                                @drop="$event.preventDefault(); dropFiles($event);"
                        >
                            <v-layout row align-center>
                                <v-flex shrink class="pt-2 pb-2 pl-1 pr-2">
                                    <v-icon class="greyType--text text--darken-2">attach_file</v-icon>
                                </v-flex>
                                <v-flex xs12>
<!--Place Holder Text-->
                                    <template v-if="attachments.length <= 0">
                                        <span class="body-1 greyType--text">Attachments:</span>
                                    </template>
<!--Existing Attachments-->
                                    <template>
                                        <v-layout row wrap align-center>
                                            <v-flex shrink v-for="(attachment, i) in attachments" class="pr-2 pb-1" :key="i">
                                                <create-mail-attachment
                                                        ref="attachment"
                                                        :attachment="attachment"
                                                        :att-size-ref="taskAttRef"
                                                        @downloadAttachment="downloadAttachment(attachment, i)"
                                                        @previewAttachment="previewAttachment(attachment, i)"
                                                        @removeAttachment="removeAttachment(attachment)"
                                                />
                                            </v-flex>
                                        </v-layout>
                                    </template>
                                </v-flex>

                                <v-flex shrink v-if="linkToTask && task && Array.isArray(taskDocuments) && taskDocuments.length > 0">
                                    <v-tooltip left>
                                        <v-btn
                                                class="pa-0 ma-0"
                                                flat
                                                icon
                                                color="secondary"
                                                slot="activator"
                                                @click="addTaskAttMenu = true"
                                        >
                                            <v-icon>add</v-icon>
                                        </v-btn>
                                        Add an Existing Attachment
                                    </v-tooltip>
                                </v-flex>

                                <v-flex shrink class="pl-2">
                                    <v-tooltip left>
                                        <div class="upload-btn-wrapper" @click="addAttachmentMenu = false" slot="activator">
                                            <v-btn
                                                    class="pa-0 ma-0"
                                                    flat
                                                    icon
                                                    color="secondary"
                                            >
                                                <v-icon>cloud_upload</v-icon>
                                            </v-btn>
                                            <input ref="manualUpload" type="file" name="myfile" multiple
                                                   :accept="userInfo.entityInfo.mimeTypeList" @input="manualUploadRun()"/>
                                        </div>
                                        Upload Attachments
                                    </v-tooltip>

                                </v-flex>


                                <input-dlg
                                        :show-dialog="addTaskAttMenu"
                                        title="Add Attachments To Mail"
                                        :cancel-button="true"
                                        :show-input-box="false"
                                        @dismiss="addTaskAttMenu = false"
                                        :body-padding="false"
                                        :width="500"
                                >
                                    <template slot="body">
                                        <div style="overflow-x: hidden; overflow-y: auto">
                                            <v-list dense style="max-height: 300px; overflow-y: auto; overflow-x: hidden" >
                                                <v-list-tile class="text-xs-center">
                                                    <v-text-field
                                                            label="Filter Attachments"
                                                            single-line
                                                            v-model="filterTaskAttString"
                                                            color="primary"
                                                            style="width: 100%"
                                                            class="ma-0 pa-0"
                                                            type="email"
                                                            clearable
                                                            prepend-icon="search"
                                                            hide-details
                                                    ></v-text-field>
                                                </v-list-tile>
                                                <v-divider></v-divider>
                                                <template v-for="(doc, i) in taskDocuments">
                                                    <v-list-tile :key="i" @click="addTaskAttachment(doc)">
                                                        <v-icon color="grey" small left>assignment_turned_in</v-icon>
                                                        <v-list-tile-content>
                                                            <v-list-tile-sub-title  class="body-2 mt-0 text-truncate" style="width: 100%; overflow-x: hidden;">
                                                                {{doc.filename}}
                                                            </v-list-tile-sub-title>
                                                            <v-list-tile-sub-title class="text-truncate caption mt-0 text-xs-right" style="width: 100%; overflow-x: hidden">
                                                                {{doc.dateStr}}
                                                            </v-list-tile-sub-title>
                                                        </v-list-tile-content>
                                                    </v-list-tile>
                                                    <v-divider :key="i + 'div'"></v-divider>
                                                </template>
                                            </v-list>
                                        </div>
                                    </template>
                                </input-dlg>

                                <v-flex shrink class="pl-2">
                                    <v-tooltip left>
                                        <v-btn

                                                @click="addRepoDocs()"
                                                class="pa-0 ma-0"
                                                flat
                                                icon
                                                color="secondary"
                                                slot="activator"

                                        >
                                            <v-icon>note_add</v-icon>
                                        </v-btn>
                                        <span>Add Document From Store</span>
                                    </v-tooltip>
                                </v-flex>
                            </v-layout>
                        </div>
                    </v-flex>
                    <v-flex shrink><v-divider></v-divider></v-flex>

<!--Compose Body-->
<!--                    @click="setFocus()"-->
                    <v-flex xs12  style="overflow-y: hidden;" id="editorFlex">
                        <editor
                                @focus="closeInputs"
                                ref="mailEditor"
                                v-model="body"
                                :extra-config="editorConfig"
                        />
                    </v-flex>
                </template>
            </v-layout>

<!--Save Draft / Send Mail-->
            <div class="mr-4 mb-2" style="position: fixed; right: 0; bottom: 0">
                <div style="display: inline" v-if="linkToTask">
                    <v-tooltip left>
                        <v-btn
                                icon
                                large
                                color="primary"
                                slot="activator"
                                @click="saveDraft"
                                :disabled="sending"
                                :loading="sending"
                        >
                            <v-icon>save</v-icon>
                        </v-btn>
                        <span>Save Draft</span>
                    </v-tooltip>
<!--                    @click="saveDraft(0, false)"-->
<!--                    :loading="savingDraft || sendingMail"-->
<!--                    :disabled="savingDraft || sendingMail"-->
                </div>
                <div style="display: inline">
                    <v-tooltip left>
                        <v-btn
                                icon
                                large
                                :color="sendReady === true ? 'secondary' : null"
                                :disabled="sending"
                                :loading="sending"
                                slot="activator"
                                style="z-index: 999999"
                                @click="sendReady === true ? sendMail() : null"
                        >
                            <v-icon>send</v-icon>
                        </v-btn>
                        <span>{{sendReady === true ? 'Send Mail' : sendReady}}</span>
                    </v-tooltip>
<!--                    @click="linkToTask ? saveDraft(2, true) : sendMessage()"-->
<!--                    :loading="sendingMail"-->
<!--                    :disabled="sendingMail"-->
                </div>
            </div>

        </v-card>

<!--EML Preview-->
        <v-dialog
                v-model="emlView"
                fullscreen
        >
            <v-card v-if="emlView" class="scrollable" style="height: 100vh">

                <v-layout column fill-height class="scrollable">
                    <v-flex shrink class="primary">
                        <v-layout row align-center>
                            <v-flex xs12>
                                <v-layout row align-center>
                                    <v-flex xs12 class="font-weight-regular white--text pa-2 title">
                                        Mail View
                                    </v-flex>
                                </v-layout>

                                <v-spacer></v-spacer>


                            </v-flex>
                            <v-flex shrink class="pa-2">
                                <v-btn
                                        icon
                                        flat
                                        color="white"
                                        class="pa-0 ma-0"
                                        @click="emlView = !emlView"
                                >
                                    <v-icon>close</v-icon>
                                </v-btn>
                            </v-flex>
                        </v-layout>
                    </v-flex>
                    <v-flex xs12 class="scrollable pa-2" v-if="emlView">
                        <e-m-l-viewer
                            
                                ref="emlView"
                        />
                    </v-flex>
                </v-layout>
            </v-card>
        </v-dialog>

<!--PDF Preview-->
        <p-d-f ref="pdfViewer"></p-d-f>


    </v-dialog>
</template>

<script>

import {mapGetters} from 'vuex';
import Editor from "@/Editor/Editor";
import CreateMailAttachment from "@/Mail/Create Mail/CreateMailAttachment";
import InputDlg from "@/components/General/InputDlg";
import mime from 'mime-types';
import {format} from 'date-fns'
import EMLViewer from "@/Mail/EML Viewer/EMLViewer";
import PDF from "@/components/General/PDF/PDF";
import {arrSplice, parseMail, validateEmail} from '@/codeFunctions'
import {onClickOutside, until} from '@vueuse/core'

export default {
  components: {PDF, EMLViewer, InputDlg, CreateMailAttachment, Editor},
  props: {
    value: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      console,
      composeType: 0, // 0 - Normal Compose, 1 - Reply, 2 - Reply to all, 3 - Forward
      srcMailFilename: null, // xxx-xx-xxxx-xxxxx.eml
      srcMailLocation: 'store', // store, cache, draft
      editorConfig: {
        paste_data_images: true,
        remove_linebreaks: false,
        images_reuse_filename: true,
        visual: false,
        init_instance_callback: function (editor) {
          editor.focus()
        },
      },
      srcMailData: null,
      loading: false,
      sendAddress: null,
      signature: null,
      to: [],
      cc: [],
      bcc: [],
      showCc: false,
      showBcc: false,
      subject: null,
      body: '',
      attachments: [],
      linkToTask: false,
      task: null,
      stepID: null,
      addTaskAttMenu: false,
      filterTaskAttString: null,
      taskAttRef: {total: 0},
      sending: false,
      draft: null,
      emlView: false,
    }
  },
  watch: {
    taskDocuments: {
      deep: true,
      handler(val) {
        if (val && Array.isArray(val) && val.length === 0)
          this.addTaskAttMenu = false
      }
    },
  },
  mounted() {
  },
  computed: {
    ...mapGetters('userInfo', ['userInfo', 'mailConfig']),
    valuePassOver: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    fromAddresses() {
      let tAddresses = [];
      try {
        if (this.mailConfig.gmail.running)
          tAddresses = tAddresses.concat(this.mailConfig.gmail.addresses.map(address => {
            address.type = 'gmail';
            return address
          }))
      } catch (e) {
        // some error
      }
      try {
        if (this.mailConfig.office.running)
          tAddresses = tAddresses.concat(this.mailConfig.office.addresses.map(address => {
            address.type = 'office';
            return address
          }))
      } catch (e) {
        // some error
      }
      try {
        if (this.mailConfig.smtp.running)
          tAddresses = tAddresses.concat(this.mailConfig.smtp.addresses.map(address => {
            address.type = 'smtp';
            return address
          }))
      } catch (e) {
        // some error
      }
      return tAddresses.map((obj, i) => {
        return {...obj, refKey: i}
      });
    },
    defaultFrom() {
      try {
        if (this.userInfo.defaultSendAsAddress == null) {
          throw 'No Default Address Set'
        }
        return JSON.parse(this.userInfo.defaultSendAsAddress)
      } catch (e) {
        return {
          address: 'null',
          type: 'null'
        }
      }
    },
    signatures() {
      if (Array.isArray(this.userInfo.signatures)) {
        return [{
          id: 0,
          description: 'No Signature',
          default: 0,
          signature: `<div></div>`
        }, ...this.userInfo.signatures].sort((a, b) => a.description < b.description ? -1 : 1);
      } else {
        return [{id: 0, description: 'No Signature', default: 0, signature: `<div></div>`}]
      }
    },
    contacts() {
      try {
        let arrContacts = [];
        if (this.userInfo.gmailEnabled == true && this.mailConfig.gmail.running && !this.mailConfig.gmail.loadingContacts) {
          arrContacts = arrContacts.concat(
              this.mailConfig.gmail.contacts.map((obj) => {
                return {
                  ...obj,
                  type: 'gmail'
                }
              })
          );
        }
        if (this.userInfo.office365Enabled == true && this.mailConfig.office.running && !this.mailConfig.office.loadingContacts) {
          arrContacts = arrContacts.concat(
              this.mailConfig.office.contacts.map((obj) => {
                return {
                  ...obj,
                  type: 'office'
                }
              })
          );
        }
        try {
          if (
              // this.userInfo.smtpAddressEnabled == 1 && this.mailConfig.smtp.running &&
              !this.mailConfig.smtp.loadingContacts) {
            
            
            arrContacts = arrContacts.concat(
                this.mailConfig.smtp.contacts.map((obj) => {
                  return {
                    ...obj,
                    type: 'smtp'
                  }
                })
            );
          }
        } catch (e) {
          // eslint-disable-next-line
          console.log(e)
        }
        
        let resContacts = [];
        let tRefEmails = [];
        
        arrContacts.forEach(contact => {
          if (!tRefEmails.includes(contact.email)) {
            tRefEmails.push(contact.email);
            resContacts.push(contact);
          }
        });
        
        return this.taskContacts.map(obj => {
          return {...obj, type: 'task'}
        })
            .concat(resContacts.map(obj => {
              if (obj.name != null && obj.name.trim() != "") {
                obj.display = `${obj.name}`;
              } else {
                obj.display = obj.email;
              }
              obj.taskContact = false;
              return obj;
            }).sort((a, b) => a['display'] < b['display'] ? -1 : 1));
      } catch (e) {
        // eslint-disable-next-line
        console.log(e)
        return [];
      }
    },
    taskDocuments() {
      const vm = this
      
      function hasAccess(document) {
        if (typeof document.securityLookup != 'undefined' && document.securityLookup != null) {
          let groupsWithOutAccess = []
          try {
            groupsWithOutAccess = JSON.parse(document.securityLookup)
            if (typeof groupsWithOutAccess == 'number') {
              groupsWithOutAccess = [groupsWithOutAccess]
            }
            
          } catch (e) {
            return true
          }
          return vm.userInfo.userGroups.filter(element => groupsWithOutAccess.includes(element)).length <= 0
        } else {
          return true
        }
      }
      
      function getFilename(alias, filename, ext) {
        if (alias) {
          if (alias.split('.').pop().toLowerCase() === ext.toLowerCase()) {
            return alias
          } else {
            return `${alias}.${ext}`
          }
        } else {
          return filename
        }
      }
      
      if (this.task && this.task.structure && this.task.structure.documents) {
        let addedTaskDocIDs = this.attachments.reduce((list, att) => {
          if (att.source === 'task')
            list.push(att.taskDocumentID)
          return list
        }, [])
        
        return this.task.structure.documents
            .filter(doc => doc.stepID > -1 && hasAccess(doc) && !this.hiddenSteps.includes(doc.stepID))
            .sort((a, b) => a.linkDate > b.linkDate ? -1 : 1)
            .map((att) => {
              return {
                type: "attachment",
                contentType: mime.lookup(att.extention),
                contentDisposition: "attachment",
                filename: getFilename(att.alias, att.fileName, att.extention),
                size: null,
                source: 'task',
                taskDocumentID: att.documentID,
                dateStr: format(att.linkDate, 'Do MMM YYYY HH:mm A')
              }
            })
            .filter(att => !addedTaskDocIDs.includes(att.taskDocumentID))
            .filter(att => !this.filterTaskAttString || att.filename.toLowerCase().includes(this.filterTaskAttString.toLowerCase()))
      } else {
        return []
      }
    },
    hiddenSteps() {
      try {
        return this.task.structure.steps
            .filter(step => step.shown === 0)
            .map(step => {
              return step.stepID
            })
      } catch (e) {
        return [];
      }
    },
    taskContacts() {
      if (this.linkToTask && this.task && this.task.structure && this.task.structure.mails) {
        try {
          return this.task.structure.mails.map((mail) => {
            return [
              typeof mail.from != 'undefined' && mail.from != null && mail.from != '' ? `${mail.from}, ` : '',
              typeof mail.to != 'undefined' && mail.to != null && mail.to != '' ? `${mail.to}, ` : '',
              typeof mail.cc != 'undefined' && mail.cc != null && mail.cc != '' ? `${mail.cc}, ` : ''
            ].join('')
          }).join(', ').split(',').reduce((accumulator, address) => {
            if (address.trim() != null && address.trim() != '') {
              
              
              let tAddress = address
                  .replace(/<undefined>/g, '')
                  .trim()
                  .split('<');
              
              if (tAddress.length > 1) {
                // Name And Surname
                return accumulator.concat([{
                  name: tAddress[0].trim().replace(/"/g, ''),
                  email: tAddress[1].replace(/>/g, '').trim()
                }])
              } else {
                // Just An Address
                return accumulator.concat([{
                  name: '',
                  email: tAddress[0].trim()
                }])
              }
              
              
            } else {
              return accumulator
            }
          }, []).map(address => {
            address.taskContact = true;
            address.display = address.name != null && address.name.trim() != "" ? `${address.name} <${address.email}>` : address.email;
            return address;
          }).sort((a, b) => a['display'] < b['display'] ? -1 : 1);
        } catch (e) {
          // eslint-disable-next-line
          console.log(e);
          return [];
        }
      } else {
        return []
      }
    },
    totalAttSize() {
      let kb = this.attachments.reduce((size, item) => {
        if (item.size) {
          return item.size + size
        } else {
          try {
            return this.taskAttRef[item.taskDocumentID] + size
          } catch (e) {
            return size
          }
        }
      }, 0) / 1024;
      
      if (kb < 1024)
        return {val: Math.round((kb) * 10) / 10, unit: 'KB'};
      
      return {val: Math.round((kb / 1024) * 10) / 10, unit: 'MB'};
    },
    sendReady() {
      if (!(this.to && Array.isArray(this.to) && this.to.length) && !(this.bcc && Array.isArray(this.bcc) && this.bcc.length))
        return 'No To or Bcc address specified'
      if (!this.subject)
        return 'No Subject specified'
      if (this.attachments.filter(obj => obj.uploading).length > 0)
        return 'Attachments are uploading'
      
      return true
    }
  },
  methods: {
    
    closeInputs() {
      if (this.$refs.toInput)
        this.$refs.toInput.blur()
      if (this.$refs.ccInput)
        this.$refs.ccInput.blur()
      if (this.$refs.bccInput)
        this.$refs.bccInput.blur()
      if (this.$refs.fromInput)
        this.$refs.fromInput.blur()
      if (this.$refs.signatureInput)
        this.$refs.signatureInput.blur()
    },
    removeSmtpContact(contact) {
      setTimeout(() => {
        arrSplice(this.to, (obj) => obj.email === contact.email)
      }, 10);
      this.$newReq('delete', `eml/createMail/smtp/removeContact/${this.userInfo.entityID}/${this.userInfo.userID}`, contact)
          .then(data => {
            arrSplice(this.mailConfig.smtp.contacts, (obj) => obj.email === contact.email)
            this.$snack.info('Contact Removed')
          })
          .catch(e => {
            console.log(e)
          })
    },
    addRepoDocs() {
      function getFilename(alias, filename, ext) {
        if (alias) {
          if (alias.split('.').pop().toLowerCase() === ext.toLowerCase()) {
            return alias
          } else {
            return `${alias}.${ext}`
          }
        } else {
          return filename
        }
      }
      
      this.$getRepoDocs({
        multiple: true,
        allowUserDocs: true
      })
          .then((docs) => {
            docs.map((att) => {
              return {
                type: "attachment",
                contentType: mime.lookup(att.extention),
                contentDisposition: "attachment",
                filename: getFilename(att.document.alias, att.document.fileName, att.document.extention),
                size: null,
                source: 'task',
                taskDocumentID: att.documentID
              }
            }).forEach(att => {
              this.attachments.push(att)
            })
            
            this.checkTaskAttachmentSize();
          })
          .catch(e => {
            if (e) {
              console.log(e)
              this.$snack.error('An Error Has Occurred')
            } else {
              console.log('User Canceled')
            }
          })
    },
    reset() {
      this.composeType = 0;
      this.srcMailFilename = null;
      this.srcMailLocation = 'store';
      this.srcMailData = null;
      this.loading = false;
      this.sendAddress = null;
      this.signature = null;
      this.to = [];
      this.cc = [];
      this.bcc = [];
      this.showCc = false;
      this.showBcc = false;
      this.subject = null;
      this.body = '';
      this.attachments = [];
      this.linkToTask = false;
      this.task = null;
      this.stepID = null;
      this.addTaskAttMenu = false;
      this.filterTaskAttString = null;
      this.taskAttRef = {total: 0};
      this.draft = null;
      this.emlView = false;
    },
    sendMail() {
      this.$refs.mailEditor.updateModel();
      this.sending = true
      this.$newReq(
          'post',
          `eml/createMail/send/${this.userInfo.entityID}/${this.userInfo.userID}`,
          {
            srcMailFilename: this.srcMailFilename,
            srcMailLocation: this.srcMailLocation,
            sendAddress: this.sendAddress,
            to: this.to,
            cc: this.cc,
            bcc: this.bcc,
            subject: this.subject,
            body: this.body,
            attachments: this.attachments,
            linkToTask: this.linkToTask,
            taskID: this.task ? this.task.taskID : null,
            stepID: this.stepID,
            sendName: `${this.userInfo.name} ${this.userInfo.surname}`.trim(),
            draft: this.draft
          })
          .then((data) => {
            
            if (this.task && data.draft) {
              if (!this.task.structure.draftMails.filter(draft => draft.id === data.draft.id).length) {
                this.task.structure.draftMails.push(data.draft)
              }
              this.task.structure.draftMails.forEach(draft => {
                if (draft.id === data.draft.id) {
                  draft.errorMessage = null
                  draft.sendStatus = 1
                }
              })
            }
            
            this.$snack.info('Sending Mail');
            this.$emit('input', false)
          })
          .catch((e) => {
            this.$snack.networkError();
          })
          .finally(() => {
            this.sending = false
          })
    },
    saveDraft() {
      this.$refs.mailEditor.updateModel();
      this.sending = true
      this.$newReq(
          'post',
          `eml/createMail/draft/${this.userInfo.entityID}/${this.userInfo.userID}`,
          {
            srcMailFilename: this.srcMailFilename,
            srcMailLocation: this.srcMailLocation,
            sendAddress: this.sendAddress,
            to: this.to,
            cc: this.cc,
            bcc: this.bcc,
            subject: this.subject,
            body: this.body,
            attachments: this.attachments,
            linkToTask: this.linkToTask,
            taskID: this.task.taskID,
            stepID: this.stepID,
            sendName: `${this.userInfo.name} ${this.userInfo.surname}`.trim(),
            draft: this.draft
          })
          .then((data) => {
            if (data.success && data.draft) {
              this.draft = data.draft;
              this.task.structure.draftMails.push(data.draft)
            }
          })
          .catch((e) => {
            this.$snack.networkError();
          })
          .finally(() => {
            this.sending = false
          })
    },
    addTaskAttachment(doc) {
      this.attachments.push(doc)
      
      this.checkTaskAttachmentSize();
    },
    downloadAttachment(att, index) {
      let attComponent = this.$refs.attachment[index];
      attComponent.downloadProgress = 1;
      this.$newReqDownloadFile(
          'post',
          `eml/createMail/download/${this.userInfo.entityID}`,
          {
            ...att,
            srcMailPath: this.srcMailFilename,
            srcMailLocation: this.srcMailLocation
          },
          att.filename || 'attachment',
          'auto',
          true,
          false,
          (p) => {
            attComponent.downloadProgress = Math.floor((p.loaded / p.total) * 100)
          }
      )
          .catch(() => {
            this.$snack.networkError()
          })
          .finally(() => {
            attComponent.downloadProgress = 0;
          })
    },
    previewAttachment(att, index) {
      function getBlobText(blob) {
        return new Promise((resolve, reject) => {
          let reader = new FileReader();
          
          reader.readAsText(blob, 'ISO-8859-1');
          
          reader.onload = function () {
            resolve(reader.result);
          };
          
          reader.onerror = function () {
            reject(reader.error);
          };
        })
      }
      
      let attComponent = this.$refs.attachment[index];
      attComponent.downloadProgress = 1;
      let downloadType = att.contentType === "application/pdf" ? 'url' : (att.contentType === 'message/rfc822' ? 'data' : 'auto');
      this.$newReqDownloadFile(
          'post',
          `eml/createMail/download/${this.userInfo.entityID}`,
          {
            ...att,
            srcMailPath: this.srcMailFilename,
            srcMailLocation: this.srcMailLocation
          },
          att.filename || 'attachemnt',
          downloadType,
          true,
          false,
          (p) => {
            attComponent.downloadProgress = Math.floor((p.loaded / p.total) * 100)
          },
      )
          .then(async data => {
            if (downloadType === 'url') {
              let viewer = this.$refs.pdfViewer;
              viewer.localInit(data, att.filename || 'attachemnt.pdf');
            }
            if (downloadType === 'data') {
              attComponent.previewProgress = true;
              parseMail(await getBlobText(data))
                  .then(parsed => {
                    this.emlView = true;
                    this.$nextTick(() => {
                      this.$refs.emlView.inception(parsed)
                    })
                  })
                  .catch(err => {
                    console.log(err)
                  })
                  .finally(() => {
                    attComponent.downloadProgress = 0;
                    attComponent.previewProgress = false;
                  })
            }
          })
          .catch(e => {
            // eslint-disable-next-line
            console.log(e)
            this.$snack.networkError()
          })
          .finally(() => {
            attComponent.downloadProgress = 0;
          })
    },
    removeAttachment(att) {
      this.attachments.forEach((obj, i) => {
        if (obj === att)
          this.attachments.splice(i, 1)
      })
    },
    manualUploadRun() {
      function checkFileType(mimeList, file) {
        let list = mimeList
            .split(',')
            .map(str => {
              try {
                return str.trim().toLowerCase()
              } catch (e) {
                return ''
              }
            })
        let typeList = list
            .filter(str => str && str.substr(0, 1) !== '.')
        let extList = list
            .filter(str => str && str.substr(0, 1) === '.')
            .map(str => {
              try {
                return str.substring(1)
              } catch (e) {
                return ''
              }
            })
        let ext = file.name ? file.name.split('.').pop().toLowerCase() : ''
        let type = file.type ? file.type.toLowerCase().trim() : ''
        return typeList.includes(type) || extList.includes(ext)
      }
      
      for (let i = 0; i < this.$refs.manualUpload.files.length; i++) {
        let file = this.$refs.manualUpload.files[i];
        if (((file.size / 1024) / 1024) < 50) {
          if (
              checkFileType(this.userInfo.entityInfo.mimeTypeList, file)
          ) {
            this.uploadFile(file);
          } else {
            console.log('Invalid File Type', file)
            this.$snack.error('File Upload Canceled - Invalid File Type');
          }
        } else {
          this.$snack.error('File Upload Canceled - File To Large')
        }
      }
      this.$refs.manualUpload.value = "";
    },
    dropFiles(e) {
      function checkFileType(mimeList, file) {
        let list = mimeList
            .split(',')
            .map(str => {
              try {
                return str.trim().toLowerCase()
              } catch (e) {
                return ''
              }
            })
        let typeList = list
            .filter(str => str && str.substr(0, 1) !== '.')
        let extList = list
            .filter(str => str && str.substr(0, 1) === '.')
            .map(str => {
              try {
                return str.substring(1)
              } catch (e) {
                return ''
              }
            })
        let ext = file.name ? file.name.split('.').pop().toLowerCase() : ''
        let type = file.type ? file.type.toLowerCase().trim() : ''
        return typeList.includes(type) || extList.includes(ext)
      }
      
      for (let i = 0; i < e.dataTransfer.files.length; i++) {
        let file = e.dataTransfer.files[i];
        if (((file.size / 1024) / 1024) < 50) {
          if (checkFileType(this.userInfo.entityInfo.mimeTypeList, file)) {
            this.uploadFile(file);
          } else {
            this.$snack.error('File Upload Canceled - Invalid File Type');
          }
        } else {
          this.$snack.error('File Upload Canceled - File To Large');
        }
      }
    },
    uploadFile(file) {
      let newFile = {
        type: "attachment",
        contentType: file.type,
        contentDisposition: "attachment",
        filename: file.name,
        size: file.size,
        progress: 1,
        source: 'cache',
        uploading: true
      };
      
      this.attachments.push(newFile)
      
      this.$newReqUpload(
          'post',
          `eml/createMail/upload/${this.userInfo.entityID}`,
          file,
          'attachment',
          true,
          (p) => {
            newFile.progress = Math.floor((p.loaded / p.total) * 100)
          })
          .then((data) => {
            delete newFile.progress;
            newFile.uploading = false
            newFile.cacheFilename = data.cacheFilename
          })
          .catch(e => {
            console.log(e)
            this.$snack.networkError();
            this.attachments.forEach((att, i) => {
              if (att === newFile)
                this.attachments.splice(i, 1)
            })
          })
    },
    getSrcMailData() {
      return new Promise((resolve) => {
        this.loading = true;
        this.$newReq('post', `eml/createMail/${this.userInfo.entityID}`, {
          route: this.srcMailLocation,
          filename: this.srcMailFilename
        })
            .then((data) => {
              this.srcMailData = data
            })
            .catch((e) => {
              // eslint-disable-next-line
              console.log(e);
              this.$snack.networkError();
              this.$emit('input', false)
            })
            .finally(() => {
              this.loading = false
              resolve(true)
            })
      })
    },
    checkTaskAttachmentSize() {
      this.taskAttRef = null;
      let taskAttachments = this.attachments.filter(att => att.source === 'task');
      if (taskAttachments.length > 0) {
        this.$newReq('post', `eml/createMail/checkSize/${this.userInfo.entityID}`, taskAttachments)
            .then((data) => {
              this.taskAttRef = data
            })
            .catch((e) => {
              this.$snack.networkError();
              this.taskAttRef = {total: 0};
            })
      } else {
        this.taskAttRef = {total: 0};
      }
    },
    setDefaultSendAddress(address) {
      this.$newReq('post', `users/updateDefaultSmtp/${this.userInfo.entityID}/${this.userInfo.userID}`, address)
          .then(() => {
            this.userInfo.defaultSendAsAddress = JSON.stringify(address);
            this.$snack.info('Default Send Address Has Been Updated')
          })
          .catch((e) => {
            // eslint-disable-next-line
            console.log(e);
            this.$snack.networkError();
          })
    },
    signatureChange2() {
      console.log("fired")
      this.body = "<p>hi</p>"
    },
    signatureChange(signature) {
      
      if (signature.signature) {
        this.body = this.body.replace(/<!--signature_start-->.*?<!--signature_end-->/s, `<!--signature_start-->${signature.signature}<!--signature_end-->`)
      } else {
        this.body = this.body.replace(/<!--signature_start-->.*?<!--signature_end-->/s, '<!--signature_start--><div></div><!--signature_end-->')
      }
    },
    validateToEmailAddress() {
      this.$refs.toInput.$data.lazySearch = '';
      this.$refs.toInput.$data.isMenuActive = false;
      this.to = this.to.reduce((arrTo, address) => {
        if (typeof address == 'string') {
          address = address.trim();
          if (validateEmail(address)) {
            return arrTo.concat([{name: '', email: address.trim(), display: address.trim(), taskContact: false}]);
          } else {
            this.$snack.error('Invalid Email Address');
            return arrTo
          }
        } else {
          return arrTo.concat([address]);
        }
      }, []);
    },
    validateCcEmailAddress() {
      this.$refs.ccInput.$data.lazySearch = '';
      this.$refs.ccInput.$data.isMenuActive = false;
      this.cc = this.cc.reduce((arrTo, address) => {
        if (typeof address == 'string') {
          address = address.trim();
          if (validateEmail(address)) {
            return arrTo.concat([{name: '', email: address.trim(), display: address.trim(), taskContact: false}]);
          } else {
            this.$snack.error('Invalid Email Address');
            return arrTo
          }
        } else {
          return arrTo.concat([address]);
        }
      }, []);
    },
    validateBccEmailAddress() {
      this.$refs.bccInput.$data.lazySearch = '';
      this.$refs.bccInput.$data.isMenuActive = false;
      this.bcc = this.bcc.reduce((arrTo, address) => {
        if (typeof address == 'string') {
          address = address.trim();
          if (validateEmail(address)) {
            return arrTo.concat([{name: '', email: address.trim(), display: address.trim(), taskContact: false}]);
          } else {
            this.$snack.error('Invalid Email Address');
            return arrTo
          }
        } else {
          return arrTo.concat([address]);
        }
      }, []);
    },
    async init(composeType = 0, srcMailFilename = null, srcMailLocation = 'store', linkToTask = false, task = null, stepID = null, draft = null) {
      let vm = this;
      
      function setDefaultSendAddress() {
        if (Array.isArray(vm.fromAddresses) && vm.fromAddresses.length > 0) {
          vm.sendAddress = vm.fromAddresses.reduce((selectedAddr, addr) => {
            return addr.default ? addr : selectedAddr
          }, null) || vm.fromAddresses[0]
        }
        
        const allToAddr = []
        
        if (vm.srcMailData && vm.srcMailData.to && vm.srcMailData.to.length)
          vm.srcMailData.to.forEach(addr => allToAddr.push((addr.address || '').toLowerCase()))
        
        if (vm.srcMailData && vm.srcMailData.cc && vm.srcMailData.cc.length)
          vm.srcMailData.cc.forEach(addr => allToAddr.push((addr.address || '').toLowerCase()))
        
        if (vm.srcMailData && vm.srcMailData.bcc && vm.srcMailData.bcc.length)
          vm.srcMailData.bcc.forEach(addr => allToAddr.push((addr.address || '').toLowerCase()))
        
        if (allToAddr.length) {
          const usedFromAddresses = vm.fromAddresses.filter(addr => allToAddr.includes((addr.address || '').toLowerCase()))
          if (usedFromAddresses.length)
            vm.sendAddress = usedFromAddresses.shift()
        }
      }
      
      function setDefaultSignature() {
        if (Array.isArray(vm.signatures) && vm.signatures.length > 0) {
          vm.signature = vm.signatures.reduce((selectedSig, sig) => {
            return sig.default === 1 ? sig : selectedSig
          }, null) || vm.signatures[0]
        }
      }
      
      this.reset();
      
      // Set Local Vars
      this.composeType = composeType;
      this.srcMailFilename = srcMailFilename;
      this.srcMailLocation = srcMailLocation;
      this.linkToTask = linkToTask;
      this.task = task;
      this.stepID = stepID;
      this.draft = draft;
      
      // Show Create Mail
      this.$emit('input', true)
      
      // Fetch Src Mail if needed
      if (this.srcMailFilename && this.srcMailLocation)
        await this.getSrcMailData()
      
      
      // Set Initial Body For Regular Compose
      if (this.srcMailLocation !== 'draft' && this.composeType === 0) {
        this.body = "<br><!--signature_start--><div></div><!--signature_end-->";
      }
      
      // Set Initial Mail Body For Reply, Reply To All, Forward
      if (
          this.srcMailLocation
          && this.srcMailLocation !== 'draft'
          && this.composeType > 0
          && this.srcMailData
          && this.srcMailFilename
      ) {
        this.body = this.generateBody();
      }
      
      // Set Initial Mail Body For Draft
      if (
          this.srcMailLocation
          && this.srcMailLocation === 'draft'
          && this.srcMailData
          && this.srcMailFilename
      ) {
        this.body = this.srcMailData.html || this.srcMailData.textAsHtml;
      }
      
      // Set Mail Defaults
      if (this.srcMailLocation !== 'draft') {
        setDefaultSignature();
        
        if (this.signature)
          this.signatureChange(this.signature)
        setDefaultSendAddress();
      }
      
      // Set Mail Meta Data
      switch (this.composeType) {
          // Reply
        case 1: {
          let fromAddressList = this.fromAddresses.map(addr => {
            return addr.address.toLowerCase()
          })
          // Set The To Address
          try {
            this.to = this.srcMailData.from
                .map((obj) => {
                  return {
                    email: obj.address,
                    name: obj.name || null,
                    display: obj.name ? `${obj.name} <${obj.address}>` : obj.address,
                    taskContact: false
                  }
                })
                .filter(obj => !fromAddressList.includes(obj.email.toLowerCase()))
          } catch (e) {
            this.to = []
          }
          
          // Set The Subject
          let srcSubject = this.srcMailData.subject || 'Subject';
          srcSubject = srcSubject.toLowerCase().trim().substr(0, 2)
          if (!['re'].includes(srcSubject)) {
            this.subject = `Re: ${this.srcMailData.subject || ''}`;
          } else {
            this.subject = this.srcMailData.subject
          }
          break;
        }
          // Reply to All
        case 2: {
          let fromAddressList = this.fromAddresses.map(addr => {
            return addr.address.toLowerCase()
          })
          // Set The To Address
          try {
            this.to = this.srcMailData.from
                .map((obj) => {
                  return {
                    email: obj.address,
                    name: obj.name || '',
                    display: obj.name ? `${obj.name} <${obj.address}>` : obj.address,
                    taskContact: false
                  }
                })
                .concat(this.srcMailData.to
                    .map((obj) => {
                      return {
                        email: obj.address,
                        name: obj.name || '',
                        display: obj.name ? `${obj.name} <${obj.address}>` : obj.address,
                        taskContact: false
                      }
                    }))
                .filter(obj => !fromAddressList.includes(obj.email.toLowerCase()))
          } catch (e) {
            this.to = []
          }
          
          // Set The CC Address
          try {
            this.cc = this.srcMailData.cc
                .map((obj) => {
                  return {
                    email: obj.address,
                    name: obj.name || '',
                    display: obj.name ? `${obj.name} <${obj.address}>` : obj.address,
                    taskContact: false
                  }
                })
                .filter(obj => !fromAddressList.includes(obj.email.toLowerCase()))
          } catch (e) {
            this.cc = []
          } finally {
            if (this.cc.length > 0)
              this.showCc = true
          }
          
          // Set The Bcc Address
          try {
            this.bcc = this.srcMailData.bcc
                .map((obj) => {
                  return {
                    email: obj.address,
                    name: obj.name || '',
                    display: obj.name ? `${obj.name} <${obj.address}>` : obj.address,
                    taskContact: false
                  }
                })
                .filter(obj => !fromAddressList.includes(obj.email.toLowerCase()))
          } catch (e) {
            this.bcc = []
          } finally {
            if (this.bcc.length > 0)
              this.showBcc = true
          }
          
          // Set The Subject
          let srcSubject = this.srcMailData.subject || 'Subject';
          srcSubject = srcSubject.toLowerCase().trim().substr(0, 2)
          if (!['re'].includes(srcSubject)) {
            this.subject = `Re: ${this.srcMailData.subject || ''}`;
          } else {
            this.subject = this.srcMailData.subject
          }
          
          break;
        }
          // Forward
        case 3: {
          
          console.log('Hello Friend', this.srcMailLocation)
          
          
          
          // Set Mail Attachements
          this.attachments = this.attachments.concat(this.srcMailData.attachments
              .map((att) => {
                att.size = att.length;
                att.filename = att.fileName
                att.source = 'mail';
                return att
              })
              .filter(att => att.contentDisposition !== 'inline')
          )
          
          // Set The Subject
          let srcSubject = this.srcMailData.subject || 'Subject';
          srcSubject = srcSubject.toLowerCase().trim().substr(0, 2)
          if (!['fw'].includes(srcSubject)) {
            this.subject = `Fw: ${this.srcMailData.subject || ''}`
          } else {
            this.subject = this.srcMailData.subject
          }
          break;
        }
          // Compose
        default: {
          if (this.linkToTask && this.task)
            this.subject = this.task.title
          
          if (this.draft && this.srcMailLocation === 'draft') {
            
            try {
              
              this.to = this.srcMailData.to
                  .map((obj) => {
                    return {
                      email: obj.address,
                      name: obj.name || obj.address,
                      display: obj.name ? `${obj.name} <${obj.address}>` : obj.address,
                      taskContact: false
                    }
                  })
            } catch (e) {
              console.log(e)
              this.to = []
            }
            
            // Set The CC Address
            try {
              this.cc = this.srcMailData.cc
                  .map((obj) => {
                    return {
                      email: obj.address,
                      name: obj.name || null,
                      display: obj.name ? `${obj.name} <${obj.address}>` : obj.address,
                      taskContact: false
                    }
                  })
            } catch (e) {
              this.cc = []
            } finally {
              if (this.cc.length > 0)
                this.showCc = true
            }
            
            // Set The Bcc Address
            try {
              this.bcc = this.srcMailData.bcc
                  .map((obj) => {
                    return {
                      email: obj.address,
                      name: obj.name || null,
                      display: obj.name ? `${obj.name} <${obj.address}>` : obj.address,
                      taskContact: false
                    }
                  })
            } catch (e) {
              this.bcc = []
            } finally {
              if (this.bcc.length > 0)
                this.showBcc = true
            }
            
            // Set The Subject
            this.subject = this.srcMailData.subject || null;
            
            setDefaultSignature();
            this.body = this.srcMailData.html
            
            // Set The From Address
            try {
              this.sendAddress = JSON.parse(this.srcMailData.headers['tl-send-address'])
            } catch (e) {
              this.setDefaultSendAddress()
            }
            
            
            // Set The Attachments
            this.attachments = this.attachments.concat(this.srcMailData.attachments
                .map((att) => {
                  att.size = att.length;
                  att.filename = att.fileName
                  att.source = 'mail';
                  return att
                })
                .filter(att => att.contentDisposition !== 'inline')
            )
            
          }
        }
      }
    },
    
    removeRedundantDivs(html) {
      const regex = /^(<div>\s*)+(.+?)(<\/div>\s*)+$/gsm;
      return html.replace(regex, (match, openingDiv, content, closingDiv) => {
        return content.trim();
      });
    },
    
    generateBody() {
      let vm = this;
      
      function getToStr() {
        let arrAddress = [];
        try {
          if (vm.srcMailData.to)
            arrAddress = arrAddress.concat(vm.srcMailData.to)
        } catch (e) {
          // console.log(e)
        }
        try {
          if (vm.srcMailData.cc)
            arrAddress = arrAddress.concat(vm.srcMailData.cc)
        } catch (e) {
          // console.log(e)
        }
        
        
        return arrAddress.length === 0 ? 'Someone' : arrAddress.map((addr) => {
          return addr.name ? `${addr.name} <${addr.address}>` : `${addr.address}`
        }).join(', ')
      }
      
      const replybox = '<div style="border: 1px solid lightgray; border-radius: 4px; padding: 4px;"> <span style="font-weight: bold; font-size: small; color: grey"> On ' +
          format(this.srcMailData.date, 'Do MMMM YYYY  h:mm A') + ', ' +
          (this.srcMailData.from ? this.srcMailData.from.map((addr) => {
            return addr.name ? `${addr.name} <${addr.address}>` : `${addr.address}`
          }).join(', ') : 'Someone') +
          ' wrote to ' + getToStr() + ' </span> </div>';
      
      let tTEst = null
      
      if ((this.srcMailData.html || this.srcMailData.textAsHtml).match(/<head>/im)) {
        // Extract All to head
        tTEst = (this.srcMailData.html || this.srcMailData.textAsHtml).replace(/<body[^>]*>/gmi, (match, p1, p2) => {
          if (p1) {
            return `${match}\n<br><!--signature_start--><div></div><!--signature_end--><br>\n ${replybox}`
          }
          if (p2) {
            return `<br><div style="padding-left: 60px">${match}</div>`
          }
          return match
        })
      } else {
        tTEst = '<br><!--signature_start--><div></div><!--signature_end--><br>' +
            replybox +
            '<br><div style="padding-left: 30px">' +
            (this.srcMailData.html || this.srcMailData.textAsHtml)
            + '</div>';
      }
      
      return this.removeRedundantDivs(tTEst)
    },
    
  }
}

</script>

<style scoped>
.upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
}

.btn {
    border: 2px solid gray;
    color: gray;
    background-color: white;
    padding: 8px 20px;
    border-radius: 8px;
    font-size: 20px;
    font-weight: bold;
}

.upload-btn-wrapper input[type=file] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
}
</style>