<template>
    <v-dialog
            v-model="valuePassOver"
            persistent
            :width="800"
    >
        <v-card class="scrollable" style="height: 500px; overflow-y: auto; overflow-x: hidden; background: var(--page-back)" v-if="value" >


          <div class="c-d-flex align-center justify-center pa-5" v-if="loading">
            <v-progress-circular
              :size="40"
              :width="5"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </div>

            <v-layout column fill-height class="scrollable" v-else>
<!--Title-->
                <v-flex shrink class="primary">
                    <v-layout row align-center>
                        <v-flex xs12>
                            <v-layout row align-center>
                                <v-flex shrink>
                                    <v-icon class="pl-2" color="white">note_add</v-icon>
                                </v-flex>
                                <v-flex xs12 class="font-weight-regular white--text px-2 py-1 title">
                                    Document Store
                                </v-flex>
                            </v-layout>
                        </v-flex>
                        <v-flex shrink class="pl-2 pr-1">
                            <v-btn
                                    icon
                                    flat
                                    color="white"
                                    class="pa-0 ma-0"
                                    @click="valuePassOver = !valuePassOver"
                            >
                                <v-icon>close</v-icon>
                            </v-btn>
                        </v-flex>
                    </v-layout>
                </v-flex>
<!--Body-->
                <v-flex xs12 class="scrollable">

                    <v-layout row fill-height class="scrollable">
                        <v-flex xs6 fill-height style="overflow-y: auto; overflow-x: hidden" class="pl-1 pr-0 py-1">
                            <v-card class="fill-height no-x">

                                <v-layout column fill-height class="no-x">
                                    <v-flex shrink>
                                        <div class="c-tabs">
                                            <div class="c-tab" :class="repoView ? 'c-tab-active' : null" @click="repoView = true">
                                                <v-icon small :style="repoView ? 'color: white' : 'color: rgba(255,255,255,0.8)'">description</v-icon>
                                                <span class="py-1 px-1">Organisation Store</span>
                                            </div>
                                            <div class="c-tab" :class="!repoView ? 'c-tab-active' : null"  @click="repoView = false" v-if="allowUserDocs">
                                                <v-icon small :style="!repoView ? 'color: white' : 'color: rgba(255,255,255,0.8)'">contact_page</v-icon>
                                                <span class="py-1 px-1">User Store</span>
                                            </div>
                                        </div>
                                    </v-flex>
                                    <v-flex xs12 class="no-x">
                                        <repo-tree
                                                v-if="repoView"

                                                v-model="selectedDocID"
                                                ref="repoTree"

                                                :tree="tree"
                                                :docs="repoDocs"
                                                :folders="repoFolders"
                                                :manager-options="false"


                                                :search-str="searchStr"
                                                @searchStr="searchStr = $event"

                                                @select="multiple ? (selectedKeys.includes($event) ? selectedKeys = selectedKeys.filter(obj => obj !== $event) : selectedKeys.push($event)) : selectedKeys = [$event]"
                                                :selected-keys="selectedKeys"
                                        />
                                        <user-repo
                                                v-else
                                                :search-str="searchStr"
                                                @searchStr="searchStr = $event"

                                                :selected-keys="selectedKeys"
                                                @select="multiple ? (selectedKeys.includes($event) ? selectedKeys = selectedKeys.filter(obj => obj !== $event) : selectedKeys.push($event)) : selectedKeys = [$event]"

                                        />
<!--                                        @select="selectedKeys.includes($event) ? selectedKeys = selectedKeys.filter(obj => obj !== $event) : selectedKeys.push($event)"-->
                                    </v-flex>
                                </v-layout>
                            </v-card>
                        </v-flex>

                        <v-flex xs6 fill-height class="pa-1" style="overflow-y: auto; overflow-x: hidden">
                            <v-card class="fill-height no-x">

                                <v-layout column fill-height class="no-x">
                                    <v-flex shrink class="primary pa-1 pl-2 white--text" style="height: 34px">
                                        Selected Documents
                                    </v-flex>
                                    <v-flex xs12 class="no-x">

                                        <div v-for="doc in selectedViewDocs" :key="doc.key" class="no-x">

                                            <div class="centered-row no-x pl-1">

                                                <div class="no-x" style="width: 100%">
                                                    <document-container
                                                            :document="doc.fullDoc"
                                                            :user-info="userInfo"
                                                            :note-doc="doc.type === 1"
                                                    />
                                                </div>

                                                <div class="pr-1">
                                                    <v-tooltip left>
                                                        <v-btn
                                                                small
                                                                icon
                                                                class="pa-0 ma-0"
                                                                slot="activator"
                                                                color="error"
                                                                flat
                                                                @click="selectedKeys = selectedKeys.filter(obj => obj !== doc.key)"
                                                        >
                                                            <v-icon>close</v-icon>
                                                        </v-btn>
                                                        Remove Document
                                                    </v-tooltip>
                                                </div>
                                            </div>
                                            <v-divider></v-divider>
                                        </div>
                                    </v-flex>
                                </v-layout>

                                <div v-if="showSelect" class="pb-2 pr-2" style="position: absolute; bottom: 0; right: 0">

                                    <v-tooltip top :disabled="!selectedKeys.length">
                                        <v-btn
                                                color="secondary"
                                                class="pa-0 ma-0"
                                                :disabled="!selectedKeys.length"
                                                fab
                                                small
                                                slot="activator"
                                                @click="done()"
                                        >
                                            <v-icon medium>check</v-icon>
                                        </v-btn>
                                        Select Documents
                                    </v-tooltip>
                                </div>

                            </v-card>
                        </v-flex>
                    </v-layout>
                </v-flex>
            </v-layout>
        </v-card>
    </v-dialog>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import {repoEvents, getRepoTree} from "@/Document Repository/docRepoMan";
import RepoTree from "@/components/General/Repo Tree/RepoTree";
import UserRepo from "@/Document Repository/UserRepo";
import {objectifyArr} from "@/codeFunctions";
import DocumentContainer from "@/Tasks/TaskView V2/components/Documents/DocumentContainer";

export default {
    components: {DocumentContainer, UserRepo, RepoTree},
    props: {
        value: Boolean
    },
    data() {
        return {
            searchStr: null,
            tree: null,
            selectedDocID: null,
            repoView: true,
            selectedKeys: [],
            multiple: true,
            allowUserDocs: true,
            loading: false,
            showSelect: true
        }
    },
    watch: {
        value: {
            // immediate: true,
            handler(val) {
                if (val) {
                    this.searchStr = null
                    this.selectedKeys = []
                    this.refreshRepo()
                }

                if (!this.allowUserDocs)
                    this.repoView = true

            }
        },
        searchStr() {
            if (this.repoView)
                this.refreshTree();
        },
        repoView(val) {
            if (val && this.value) {
                this.refreshTree();
            }
        }
    },
    created() {
        repoEvents.on('addDocToUserRepo', (docID) => this.addDocToUserRepo(docID))
    },
    beforeDestroy() {
        repoEvents.off('addDocToUserRepo', (docID) => this.addDocToUserRepo(docID))
    },
    methods: {
        ...mapActions('repo', ['repoAddUserDoc', 'repoInit']),

        async refreshRepo() {
          try {
              this.loading = true
              const data = await this.$newReq('get', `docRepo/UserStartup/${this.userInfo.entityID}/${this.userInfo.userID}`)
              this.repoInit(data)
          } catch (e) {
              console.log(e)
          } finally {
              this.refreshTree();
            this.loading = false
          }
        },

        refreshTree() {
            this.tree = getRepoTree(
                    this.repoFolders,
                    this.repoDocs,
                    true,
                    this.searchStr
            ).children;
        },

        done() {
            repoEvents.emit('done', this.returnResult);
            this.$emit('input', false)
        },

        addDocToUserRepo(docID) {
            if (!this.repoUserDocs.filter(doc => doc.fk_doc === docID).length) {
                this.$newReq('post', `docRepo/AddExistingUserDoc/${this.userInfo.entityID}/${this.userInfo.userID}/${docID}`)
                        .then((doc) => {
                            // addDocToUserRepoDone
                            this.repoAddUserDoc(doc)
                            repoEvents.emit('addDocToUserRepoDone', doc)
                        })
                        .catch(e => {
                            this.$snack.networkError()
                            repoEvents.emit('addDocToUserRepoError', e)
                        })
            } else {
                this.$snack.info('Document Already Exists')
            }
        }
    },
    computed: {
        ...mapGetters('userInfo', ['userInfo']),
        ...mapGetters('repo', ['repoDocs', 'repoFolders', 'repoUserDocs']),
        ...mapGetters(['localData']),
        refUsers() {
            return objectifyArr(this.localData.users, 'userID')
        },

        valuePassOver: {
            get() {
                return this.value
            },
            set(val) {
                if (!val)
                    repoEvents.emit('canceled')
                this.$emit('input', val)
            }
        },

        selectedViewDocs() {
            return this.repoDocs
                .concat(this.repoUserDocs)
                .filter(node => this.selectedKeys.includes(node.key))
                .map((doc) => {
                    return {
                        ...doc,
                        description: doc.description || (doc.document.alias || doc.document.fileName),
                        fullDoc: {
                            ...doc.document,
                            messageID: null,
                            documentDBID: doc.document.id,
                            linkUser: doc.document.fk_import_user,
                            linkName: doc.document.fk_import_user === 0 ? 'System' : this.refUsers[doc.document.fk_import_user].name,
                            linkSurname: doc.document.fk_import_user === 0 ? '' : this.refUsers[doc.document.fk_import_user].surname,
                            linkDate: doc.document.created,
                            stepID: null
                        }
                    }
                })
        },

        returnResult() {
            if (this.multiple) {
                return this.selectedViewDocs
                        .map((doc) => {
                            return {
                                placeHolderID: doc.type === 1 ? doc.id : null,
                                description: doc.description,
                                documentDBID: doc.fullDoc.documentDBID,
                                documentID: doc.fullDoc.documentID,
                                document: doc.fullDoc
                            }
                        })
            } else {
                return this.selectedViewDocs
                        .map((doc) => {
                            return {
                                placeHolderID: doc.type === 1 ? doc.id : null,
                                description: doc.description,
                                documentDBID: doc.fullDoc.documentDBID,
                                documentID: doc.fullDoc.documentID,
                                document: doc.fullDoc
                            }
                        })
                        .pop()
            }
        }

    },
}


</script>

<style scoped>

    .c-tabs {
        display: flex;
        align-items: center;
        justify-content: left;
    }

    .c-tab {
        display: flex;
        align-items: center;
        justify-content: center;

        background: var(--v-primary-base);
        width: 100%;
        text-align: center;
        border-bottom: 3px solid var(--v-primary-base);
        color: rgba(255,255,255,0.8);
        cursor: pointer;
    }

    .c-tab:hover {
        border-bottom: 3px solid var(--v-primary-lighten2);
    }

    .c-tab.c-tab-active {
        color: white;
        border-bottom: 3px solid var(--v-secondary-base);
    }

</style>