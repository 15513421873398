<template>
    <v-layout row align-center justify-center>
        <v-flex shrink>
            <v-btn
                    icon
                    flat
                    small
                    color="secondary"
                    class="pa-0 ma-0"
                    @click="() => {}"
            >
                <v-icon>arrow_left</v-icon>
            </v-btn>
        </v-flex>
        <v-flex shrink>
            Hello2
        </v-flex>
    </v-layout>
</template>

<script>
    export default {
        props: {
            value: Number,
            length: Number
        }
    }
</script>

<style scoped>

</style>
