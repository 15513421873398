<template>
  <!-- eslint-disable -->
    <v-dialog
            v-model="showDialog"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
            persistent
    >
        <v-card flat height="100vh">

            <v-layout column fill-height>
                <v-flex shrink class="primary">
                    <!--Card Title-->
                    <v-card-title class="py-2 pr-2">
                        <!--<v-icon-->
                                <!--left-->
                                <!--color="white"-->
                        <!--&gt;-->
                            <!--publish-->
                        <!--</v-icon>-->
                        <img src="../../../public/import.svg" width="42px" class="pr-2"/>
                        <span class="title font-weight-light white--text">Import Manager</span>
                        <v-spacer></v-spacer>
                        <v-btn flat icon color="white" class="pa-0 ma-0" @click="$emit('dismiss', false)">
                            <v-icon>close</v-icon>
                        </v-btn>
                    </v-card-title>
                </v-flex>
                <v-flex shrink class="primary">
                    <v-layout row align-center>
                        <v-flex xs4 class="pa-1">
                            <v-btn
                                    class="ma-0"
                                    color="white"
                                    @click="showSelectImport = !showSelectImport"
                                    flat
                            >
                                <v-icon left>list</v-icon>
                                Select Import Template
                            </v-btn>
                        </v-flex>
                        <v-flex xs12 v-if="selectedTemplate != null">
                            <v-tabs
                                    v-model="importModel"
                                    color="primary"
                                    slider-color="secondary"
                                    grow
                                    show-arrows
                            >
                                <v-tab class="white--text">Data</v-tab>
                                <v-tab
                                        class="white--text"
                                        :disabled="
                                            selectedTemplate == null
                                            || typeof selectedTemplate == 'undefined'
                                            || selectedTemplate.data == null
                                            || selectedTemplate.data == ''"
                                >
                                    Field Map
                                </v-tab>

                                <v-tab
                                        class="white--text"
                                        :disabled="
                                            selectedTemplate == null
                                            || typeof selectedTemplate == 'undefined'
                                            || selectedTemplate.data == null
                                            || selectedTemplate.data == ''"
                                >
                                    Security
                                </v-tab>

                                <v-tab
                                        class="white--text"
                                        :disabled="
                                            selectedTemplate == null
                                            || typeof selectedTemplate.data == 'undefined'
                                            || selectedTemplate.data == null
                                            || selectedTemplate.data == ''
                                            || selectedTemplate.folderNameIndex == null
                                            || selectedTemplate.fieldMap == null
                                            || selectedTemplate.folderGroup == null
                                            || selectedTemplate.folderGroup.length <= 0
                                            "
                                >
                                    Import Utility
                                </v-tab>
                            </v-tabs>
                        </v-flex>
                    </v-layout>
                    <v-divider></v-divider>
                </v-flex>

                <v-flex v-if="loadingTemplates" class="text-xs-center pa-2">
                    <v-progress-circular
                            :size="40"
                            :width="5"
                            color="primary"
                            indeterminate
                    ></v-progress-circular>
                </v-flex>

                <v-flex v-else xs12 style="overflow-y: auto" class="scroll pa-0">
                    <v-layout row fill-height>
                        <v-flex xs3 style="overflow-y: auto" class="pa-1" v-show="showSelectImport">
                            <v-card class="fill-height" style="overflow-y: auto">
                                <v-layout column fill-height>
                                    <v-flex shrink>
                                        <v-card-title class="white--text pa-0 ma-0 primary">
                                            <div class="pa-2">Select Import Template</div>
                                        </v-card-title>
                                    </v-flex>



                                    <v-flex xs12 style="overflow-y: auto" v-if="showSelectImport">
                                        <select-import-template
                                            v-model="selectedTemplate"
                                            :items="templates == null ? [] : templates"
                                            @input="showSelectImport = false"
                                            @addTemplate="showAddTemplateDlg = true"
                                            @rename="showRename = true"
                                            @removeImportTemplate="removeImportTemplate($event)"
                                        />
                                        <app-input-dlg
                                                v-if="showRename"
                                                :show-dialog="showRename"
                                                title="Rename Template"
                                                body="Please provide a New Template Name"
                                                :cancel-button="true"
                                                :show-input-box="true"
                                                :buttons="['Rename']"
                                                :call-backs="[ renameTemplate ]"
                                                @dismiss="showRename = false"
                                                :default-input="selectedTemplate.description"
                                        />
                                        <app-input-dlg
                                                v-if="showAddTemplateDlg"
                                                :show-dialog="showAddTemplateDlg"
                                                title="Add Template"
                                                body="Template Description"
                                                :cancel-button="true"
                                                :show-input-box="true"
                                                :buttons="['Add Template']"
                                                :call-backs="[ addImportTemplate ]"
                                                @dismiss="showAddTemplateDlg = false"
                                        />
                                    </v-flex>
                                </v-layout>
                            </v-card>
                        </v-flex>
<!--Data-->
                        <v-flex :xs9="showSelectImport" :xs12="!showSelectImport" class="pa-1"  fill-height v-if="selectedTemplate != null && importModel == 0" >
                            <v-card class="fill-height" style="overflow-y: auto">
                                <v-layout column fill-height class="scrollable">
                                    <v-flex shrink>
                                        <v-card-title class="white--text pa-0 ma-0 primary">
                                            <div class="pa-2">Data</div>
                                        </v-card-title>
                                    </v-flex>

                                    <v-flex xs12 class="pa-2 text-xs-center" v-if="loadingData">
                                        <v-progress-circular
                                                :size="40"
                                                :width="5"
                                                color="primary"
                                                indeterminate
                                        ></v-progress-circular>
                                    </v-flex>

                                    <v-flex xs12 v-else class="px-2 scrollable">
                                        <v-layout column fill-height class="scrollable">
                                            <v-flex shrink>
                                                <!--Add Data-->
                                                <div class="pt-2">
                                                    <span class="body-2 greyType--text">Add Raw CSV Data</span>
                                                </div>
                                                <v-textarea
                                                        hide-details
                                                        class="pa-0 ma-0 pl-3 pt-2"
                                                        solo
                                                        label="Raw CSV Data"
                                                        v-model="rawAddData"
                                                        lines="4"
                                                ></v-textarea>
                                                <v-layout row align-center class="pl-3 pt-2">
                                                    <v-flex xs6>
                                                        <span class="caption greyType--text">*Please Include Field Names In Raw Data</span>
                                                    </v-flex>
                                                    <v-flex xs6 class="text-xs-right" v-if="rawAddData != null">
                                                        <v-btn
                                                                small
                                                                flat
                                                                color="secondary"
                                                                @click="dataConversion"
                                                        >
                                                            <v-icon left>add</v-icon>
                                                            Add Raw Data
                                                        </v-btn>
                                                    </v-flex>
                                                </v-layout>
                                                <div class="pt-3">
                                                    <span class="body-2 greyType--text">Data Pool</span>
                                                </div>
                                            </v-flex>
                                            <v-flex xs12 class="scrollable">
                                                <v-data-table
                                                        :headers="selectedTemplateData != null ? selectedTemplateData.headers : []"
                                                        :items="selectedTemplateData != null ? selectedTemplateData.values : []"
                                                        class="elevation-1 fill-height"
                                                        v-if="selectedTemplate.data != null"
                                                >
                                                    <template
                                                            slot="items"
                                                            slot-scope="props"
                                                    >
                                                        <td style="display: none">{{ props.index }}</td>
                                                        <template v-for="(key, i) in Object.keys(props.item)">
                                                            <td v-if="key != 'importID'" :key="props.index + '-' + i">
                                                                <div style="white-space: pre">{{ props.item[key] }}</div>
                                                            </td>
                                                        </template>
                                                    </template>
                                                </v-data-table>
                                            </v-flex>
                                        </v-layout>
                                    </v-flex>


                                </v-layout>
                            </v-card>
                        </v-flex>
<!--Field Map-->
                        <v-flex :xs9="showSelectImport" :xs12="!showSelectImport" class="pa-1"  fill-height v-if="selectedTemplate != null && importModel == 1 && typeof selectedTemplate.data != 'undefined' && selectedTemplate.data != null && selectedTemplate.data != ''" >
                            <v-card class="fill-height" style="overflow-y: auto">
                                <v-layout column fill-height class="scrollable">
                                    <v-flex shrink>
                                        <v-card-title class="white--text pa-0 ma-0 primary">
                                            <div class="pa-2">Field Map</div>
                                        </v-card-title>
                                    </v-flex>

                                    <v-flex xs12 class="pa-2 text-xs-center" v-if="loadingData">
                                        <v-progress-circular
                                                :size="40"
                                                :width="5"
                                                color="primary"
                                                indeterminate
                                        ></v-progress-circular>
                                    </v-flex>

                                    <v-flex xs12 v-else class="px-2 scrollable">
                                        <v-layout row justify-space-between fill-height>
                                            <v-flex fill-height xs6 style="overflow-y: auto">

                                                <v-layout column fill-height>
                                                    <v-flex shrink class="pa-2">
                                                        <span class="body-2 primary--text pl-1">Data Fields</span>
                                                    </v-flex>
                                                    <v-divider></v-divider>
                                                    <v-flex class="scroll" style="overflow-y: auto">
                                                        <v-list class="pl-3">
                                                            <template
                                                                    v-for="(heading, i) in (selectedTemplateData != null ? selectedTemplateData.headers : [])">
                                                                <v-list-tile
                                                                        compact
                                                                        :key="'FieldMapHeading' + i"
                                                                        @click="() => {}"
                                                                        @dragover="$event.preventDefault();"
                                                                        @drop="updateFieldMap(i)"
                                                                >

                                                                    <v-layout row align-center>
                                                                        <v-flex xs1 class="pr-2">
                                                                            <v-tooltip right>
                                                                                <v-icon
                                                                                        color="primary"
                                                                                        style="cursor: pointer"
                                                                                        slot="activator"
                                                                                        @click="selectedTemplate.folderNameIndex = i"
                                                                                >
                                                                                    {{selectedTemplate.folderNameIndex == i ? 'check_box' : 'check_box_outline_blank' }}
                                                                                </v-icon>
                                                                                <span>Use Field As Default Folder Name</span>
                                                                            </v-tooltip>
                                                                        </v-flex>
                                                                        <v-flex xs5>
                                                                            <span class="body-1">{{ heading.text }}</span>
                                                                        </v-flex>
                                                                        <v-flex shrink>
                                                                            <span class="body-2 primary--text">=</span>
                                                                        </v-flex>
                                                                        <template
                                                                                v-if="selectedTemplate.fieldMap != null && selectedTemplate.fieldMap != '' && JSON.parse(selectedTemplate.fieldMap).length > 0"
                                                                        >
                                                                            <template
                                                                                    v-for="(map, i) in JSON.parse(selectedTemplate.fieldMap).filter(object => object.index == i)"
                                                                            >
                                                                                <v-flex class="pl-2" xs5 :key="i + 'a'">
                                                                                    <template
                                                                                            v-for="fieldSet in fieldSets.filter(fsObject => fsObject.id == map.fieldSet)"
                                                                                    >
                                                                                        <template
                                                                                                v-for="fieldSetField in JSON.parse(fieldSet.fields).filter(fsfObject => fsfObject.id == map.field)">
                                                                                            <span class="body-1">{{fieldSet.description + ' - ' + fieldSetField.description}}</span>
                                                                                        </template>
                                                                                    </template>
                                                                                </v-flex>
                                                                                <v-flex xs1 class="text-xs-right" :key="i + 'b'">
                                                                                    <v-btn
                                                                                            icon
                                                                                            small
                                                                                            flat
                                                                                            color="red"
                                                                                            class="pa-0 ma-0 px-1"
                                                                                            @click="removeMapLink(i)"
                                                                                    >
                                                                                        <v-icon>close</v-icon>
                                                                                    </v-btn>
                                                                                </v-flex>
                                                                            </template>
                                                                        </template>
                                                                    </v-layout>
                                                                </v-list-tile>
                                                            </template>
                                                        </v-list>
                                                    </v-flex>
                                                </v-layout>
                                            </v-flex>
                                            <v-flex fill-height xs6 style="overflow-y: auto">
                                                <v-layout column fill-height>
                                                    <v-flex shrink class="pa-2">
                                                        <span class="body-2 primary--text pl-1">Dataset Fields</span>
                                                    </v-flex>
                                                    <v-divider></v-divider>
                                                    <v-flex shrink>
                                                        <v-autocomplete
                                                                dense
                                                                v-model="curFieldset"
                                                                :items="fieldSets"
                                                                label="Select A Dataset"
                                                                item-text="description"
                                                                return-object
                                                                hide-details
                                                                single-line
                                                                color="primary"
                                                                class="ma-0 pa-0 pl-1 pt-2"
                                                                clearable
                                                        ></v-autocomplete>
                                                    </v-flex>
                                                    <v-flex class="scroll" style="overflow-y: auto"
                                                            v-if="curFieldset != null">
                                                        <v-list class="pl-3">
                                                            <template
                                                                    v-for="(field, i) in JSON.parse(curFieldset.fields)">
                                                                <v-list-tile
                                                                        compact
                                                                        :key="'fsField' + i"
                                                                        @click="() => {}"

                                                                        @drag="dragField = {field: field.id, fieldSet: curFieldset.id}"
                                                                        draggable="true"
                                                                >
                                                                    <span class="body-1">{{ field.description }}</span>
                                                                </v-list-tile>
                                                            </template>
                                                        </v-list>
                                                    </v-flex>
                                                </v-layout>
                                            </v-flex>

                                        </v-layout>
                                    </v-flex>
                                </v-layout>
                            </v-card>
                        </v-flex>
<!--Security-->
                        <v-flex :xs9="showSelectImport" :xs12="!showSelectImport" class="pa-1"  fill-height v-if="selectedTemplate != null && importModel == 2 && typeof selectedTemplate.data != 'undefined' && selectedTemplate.data != null && selectedTemplate.data != ''" >
                            <v-card class="fill-height" style="overflow-y: auto">
                                <v-layout column fill-height class="scrollable">
                                    <v-flex shrink>
                                        <v-card-title class="white--text pa-0 ma-0 primary">
                                            <div class="pa-2">Security</div>
                                        </v-card-title>
                                    </v-flex>

                                    <v-flex xs12 class="pa-2 text-xs-center" v-if="loadingData">
                                        <v-progress-circular
                                                :size="40"
                                                :width="5"
                                                color="primary"
                                                indeterminate
                                        ></v-progress-circular>
                                    </v-flex>

                                    <v-flex xs12 v-else class="px-2 scrollable">
                                        <v-flex shrink class="pa-2">
                                            <span class="body-2 primary--text pl-1">Select Folder Groups</span>
                                        </v-flex>

                                        <v-divider></v-divider>

                                        <v-layout row align-center justify-center class="pt-2">
                                            <v-flex xs6>
                                                <v-autocomplete
                                                        dense
                                                        v-model="templateFolderGroups"
                                                        :items="folderGroups == null ? [] : folderGroups"
                                                        label="Select Folder Groups"
                                                        item-text="description"
                                                        item-value="id"
                                                        multiple
                                                        hide-details
                                                        single-line
                                                        color="primary"
                                                        class="ma-0 pa-0 pl-3"
                                                        clearable
                                                        chips
                                                        small-chips
                                                        deletable-chips
                                                ></v-autocomplete>
                                            </v-flex>
                                        </v-layout>
                                    </v-flex>
                                </v-layout>
                            </v-card>
                        </v-flex>

                        <v-flex :xs9="showSelectImport" :xs12="!showSelectImport" class="pa-1"  fill-height v-if="selectedTemplate != null && importModel == 3 && typeof selectedTemplate.data != 'undefined' && selectedTemplate.data != null && selectedTemplate.data != '' && selectedTemplate.folderNameIndex != null && selectedTemplate.fieldMap != null && selectedTemplate.folderGroup != null && selectedTemplate.folderGroup.length > 0" >
                            <v-card class="fill-height" style="overflow-y: auto">
                                <v-layout column fill-height class="scrollable">
                                    <v-flex shrink>
                                        <v-card-title class="white--text pa-0 ma-0 primary">
                                            <div class="pa-2">Import Utility</div>
                                        </v-card-title>
                                    </v-flex>

                                    <v-flex xs12 class="pa-2 text-xs-center" v-if="loadingData">
                                        <v-progress-circular
                                                :size="40"
                                                :width="5"
                                                color="primary"
                                                indeterminate
                                        ></v-progress-circular>
                                    </v-flex>

                                    <v-flex xs12 v-else class="px-2 scrollable">
                                        <v-layout row fill-height justify-center style="overflow-y: auto">
                                            <v-flex xs8 class="fill-height" style="overflow-y: auto">
                                                <v-layout column fill-height>
                                                    <v-flex shrink>
                                                        <v-layout row align-center>
                                                            <v-flex xs12>
                                                                <span class="greyType--text body-2">Select Data</span>
                                                            </v-flex>
                                                            <v-flex shrink>
                                                                <v-menu offset-y :close-on-content-click="false"
                                                                        v-model="showSelectFields">
                                                                    <v-tooltip top slot="activator">
                                                                        <v-btn
                                                                                icon
                                                                                flat
                                                                                color="secondary"
                                                                                slot="activator"
                                                                                class="ma-0 pa-0"
                                                                        >
                                                                            <v-icon>list</v-icon>
                                                                        </v-btn>
                                                                        <span>Select Display Fields</span>
                                                                    </v-tooltip>
                                                                    <v-card class="text-xs-center">
                                                                        <v-list style="max-height: 400px; overflow-y: auto"
                                                                                class="scroll">
                                                                            <v-list-tile
                                                                                    v-for="(heading, i) in selectedTemplateData != null ? selectedTemplateData.headers : []"
                                                                                    @click="displayFields.includes(i) ? displayFields = displayFields.filter(object => object != i) : displayFields.push(i)"
                                                                            >
                                                                                <v-list-tile-actions>
                                                                                    <v-icon
                                                                                            color="primary"
                                                                                            style="cursor: pointer"
                                                                                            left
                                                                                    >
                                                                                        {{displayFields.includes(i) ?
                                                                                        'check_box' :
                                                                                        'check_box_outline_blank'}}
                                                                                    </v-icon>
                                                                                </v-list-tile-actions>
                                                                                <v-list-tile-title>
                                                                                    {{ heading.text }}
                                                                                </v-list-tile-title>
                                                                            </v-list-tile>
                                                                        </v-list>
                                                                        <v-btn
                                                                                flat
                                                                                small
                                                                                color="secondary"
                                                                                @click="showSelectFields = false"
                                                                        >Done
                                                                        </v-btn>
                                                                    </v-card>
                                                                </v-menu>
                                                            </v-flex>
                                                        </v-layout>
                                                        <v-divider></v-divider>
                                                    </v-flex>
                                                    <v-flex xs12 style="overflow-y: auto">
                                                        <!--Select Data Component-->
                                                        <v-data-table
                                                                :headers="displayFields.length > 0 ? [{text: '', sortable: false, value: ''}].concat((selectedTemplateData != null ? selectedTemplateData.headers : []).filter(object => displayFields.includes(object.index))) : [{text: '', sortable: false, value: ''}].concat((selectedTemplateData != null ? selectedTemplateData.headers : []))"
                                                                :items="selectedTemplateData != null ? selectedTemplateData.values : []"
                                                                class="elevation-1"
                                                                v-if="selectedTemplate.data != null"
                                                                ref="datagridImport"
                                                                :hide-actions="quickImport"
                                                        >
                                                            <template
                                                                    slot="items"
                                                                    slot-scope="props"
                                                            >
                                                                <td style="display: none">{{ props.index }}</td>

                                                                <td>
                                                                    <v-icon color="primary"
                                                                            @click="importIDs.includes(props.item.importID) ? importIDs = importIDs.filter(obj => obj != props.item.importID) : importIDs.push(props.item.importID)">
                                                                        {{ importIDs.includes(props.item.importID) ?
                                                                        'check_box' : 'check_box_outline_blank' }}
                                                                    </v-icon>
                                                                </td>

                                                                <template v-if="displayFields.length > 0">
                                                                    <template v-for="(key, i) in Object.keys(props.item)">
                                                                        <td v-if="key != 'importID' && displayFields.includes(i)"
                                                                            :key="props.index + '-' + i">
                                                                            <div style="white-space: pre">{{ props.item[key]
                                                                                }}
                                                                            </div>
                                                                        </td>
                                                                    </template>
                                                                </template>

                                                                <template v-else>
                                                                    <template v-for="(key, i) in Object.keys(props.item)">
                                                                        <td v-if="key != 'importID'"
                                                                            :key="props.index + '-' + i">
                                                                            <div style="white-space: pre">{{ props.item[key]
                                                                                }}
                                                                            </div>
                                                                        </td>
                                                                    </template>
                                                                </template>

                                                            </template>
                                                        </v-data-table>
                                                    </v-flex>
                                                </v-layout>
                                            </v-flex>
                                            <v-flex xs4 class="pl-2">

                                                <v-layout row align-center>
                                                    <v-flex xs12>
                                                        <span class="greyType--text body-2">Import</span>
                                                    </v-flex>
                                                    <v-flex shrink>

                                                        <v-tooltip top>
                                                            <v-btn
                                                                    icon
                                                                    flat
                                                                    color="primary"
                                                                    @click="quickImport = !quickImport"
                                                                    slot="activator"
                                                                    :loading="uploading"
                                                                    :disabled="uploading"
                                                            >
                                                                <v-icon>{{ quickImport ? 'play_arrow' : 'fast_forward'}}</v-icon>
                                                            </v-btn>
                                                            <span>{{ quickImport ? 'Switch To Slow Import' : 'Switch To Quick Import'}}</span>
                                                        </v-tooltip>


                                                    </v-flex>
                                                    <v-flex shrink>
                                                        <v-tooltip left slot="activator">
                                                            <v-btn
                                                                    icon
                                                                    flat
                                                                    color="secondary"
                                                                    slot="activator"
                                                                    class="ma-0 pa-0"
                                                                    @click="addToExisting = !addToExisting"
                                                            >
                                                                <v-icon>
                                                                    {{ !addToExisting ? 'call_merge' : 'add'}}
                                                                </v-icon>
                                                            </v-btn>
                                                            <span>{{ addToExisting ? 'Create New Folder' : 'Link To Existing Folder'}}</span>
                                                        </v-tooltip>
                                                    </v-flex>
                                                </v-layout>
                                                <v-divider></v-divider>

                                                <v-layout column class="pl-3">
                                                    <v-flex shrink class="pt-2">

                                                        <!--Select Existing-->
                                                        <v-autocomplete
                                                                v-if="addToExisting"
                                                                dense
                                                                v-model="selectedMergeFolder"
                                                                :items="folders"
                                                                label="Select A Folder To Merge Into"
                                                                item-text="description"
                                                                item-value="id"
                                                                hide-details
                                                                single-line
                                                                color="primary"
                                                                class="ma-0 pa-0 pl-3"
                                                                clearable
                                                        ></v-autocomplete>

                                                        <!--Create New Folder-->
                                                        <template v-else>
                                                            <template
                                                                    v-if="addFolderTitle != null && showMerge()">

                                                                <v-layout row align-center>
                                                                    <v-flex xs12>
                                                                        <div class="text-xs-center">
                                                                            <span class="red--text caption">*Duplicate Folder found with an overlapping Folder Group. Please consider merging</span>
                                                                        </div>

                                                                        <v-text-field
                                                                                label="Folder Name"
                                                                                single-line
                                                                                v-model="addFolderTitle"
                                                                                color="primary"
                                                                                hide-details
                                                                                style="width: 100%"
                                                                                class="ma-0 pa-0 pl-2"
                                                                                background-color="red"
                                                                                prepend-inner-icon="folder"
                                                                                dark
                                                                        ></v-text-field>
                                                                    </v-flex>
                                                                    <v-flex shrink>
                                                                        <v-btn
                                                                                icon
                                                                                flat
                                                                                color="red"
                                                                                class="pa-0 ma-0 px-1"
                                                                                @click="switchImportType"
                                                                        >
                                                                            <v-icon>link</v-icon>
                                                                        </v-btn>

                                                                    </v-flex>

                                                                </v-layout>

                                                            </template>
                                                            <v-text-field
                                                                    v-else
                                                                    label="Folder Name"
                                                                    single-line
                                                                    v-model="addFolderTitle"
                                                                    color="primary"
                                                                    hide-details
                                                                    style="width: 100%"
                                                                    class="ma-0 pa-0 pl-2"
                                                            ></v-text-field>
                                                        </template>


                                                    </v-flex>
                                                    <v-flex shrink class="text-xs-center">
                                                        <!--{{importIDs}}-->
                                                        <v-btn
                                                                @click="importIDs.length == 0 ? quickImportRun() : (addToExisting ? uploadUpdateFolder() : uploadNewFolder())"
                                                                v-if="quickImport" color="secondary"
                                                        >
                                                            {{ importIDs.length > 0 ? 'Import' : 'Select Top Folder'}}
                                                        </v-btn>
                                                    </v-flex>
                                                    <v-flex shrink class="text-xs-center">
                                                        <v-btn
                                                                @click="uploadNewFolder(true)"
                                                                v-if="quickImport && !(addFolderTitle != null && folders.filter(obj => obj.description.toLowerCase() == addFolderTitle.toLowerCase()).length > 0) && importIDs.length >= 1" color="secondary"
                                                        >
                                                            <v-icon left>directions_run</v-icon>
                                                            Import Run
                                                        </v-btn>
                                                    </v-flex>
                                                    <v-flex shrink class="scroll"
                                                            style="overflow-y: auto; max-height: 300px">
                                                        <v-treeview
                                                                :items="postTemplates"
                                                                item-children="postFields"
                                                                item-key="index"
                                                                color="secondary"
                                                        >
                                                            <template
                                                                    slot="label"
                                                                    slot-scope="prop"
                                                            >
                                                                <v-layout row align-center>
                                                                    <v-flex :xs6="typeof prop.item.value != 'undefined'"
                                                                            :xs12="typeof prop.item.value == 'undefined'">
                                                                        <span class="body-1 greyType--text">{{ prop.item.description }}</span>
                                                                    </v-flex>
                                                                    <template v-if="typeof prop.item.value != 'undefined'">
                                                                        <v-flex shrink>
                                                                            <span class="body-2 primary--text">=</span>
                                                                        </v-flex>
                                                                        <v-flex xs6 class="text-xs-right pr-2">
                                                                            <span class="body-1 greyType--text">{{ prop.item.value }}</span>
                                                                        </v-flex>
                                                                    </template>
                                                                    <template v-else>
                                                                        <v-flex shrink>
                                                                            <v-tooltip left>
                                                                                <v-icon
                                                                                        color="red"
                                                                                        slot="activator"
                                                                                        style="cursor: pointer"
                                                                                        ripple
                                                                                        @click="postTemplates = postTemplates.filter(obj => obj.index != prop.item.index); showSnack('Info', 'Dataset Removed', 'Close', 'primary')"
                                                                                >
                                                                                    remove
                                                                                </v-icon>
                                                                                <span>Remove Dataset</span>
                                                                            </v-tooltip>
                                                                        </v-flex>
                                                                    </template>
                                                                </v-layout>

                                                            </template>

                                                        </v-treeview>
                                                    </v-flex>
                                                    <v-flex shrink class="text-xs-center">
                                                        <v-btn
                                                                color="secondary"
                                                                v-if="(!quickImport && ((!addToExisting && addFolderTitle != null && addFolderTitle != '') || (addToExisting && selectedMergeFolder != null && selectedMergeFolder != '')) && postTemplates.length > 0)"
                                                                @click="addToExisting ? uploadUpdateFolder() : uploadNewFolder()"
                                                                :disabled="uploading"
                                                                :loading="uploading"
                                                        >
                                                            Import
                                                        </v-btn>
                                                    </v-flex>


                                                </v-layout>


                                            </v-flex>
                                        </v-layout>
                                    </v-flex>
                                </v-layout>
                            </v-card>
                        </v-flex>
                    </v-layout>










                    <v-btn
                            @click="saveChanges"
                            v-if="!savingChanges && selectedTemplate != null"
                            dark
                            fab
                            fixed
                            bottom
                            right
                            color="primary"
                    >
                        <v-icon>save</v-icon>
                    </v-btn>

                </v-flex>
            </v-layout>
        </v-card>
    </v-dialog>
</template>

<script>

    import {codeBaseMixin} from "../../codeBaseMixin";
    import InputDlg from "../../components/General/InputDlg";
    import SelectImportTemplate from "./SelectImportTemplate"


    export default {
        mixins: [codeBaseMixin],
        components: {
            SelectImportTemplate,
            appSelectImportTemplate: SelectImportTemplate,
            appInputDlg: InputDlg,
        },
        props: {
            showDialog: {
                type: Boolean,
                default: false
            },
            userInfo: Object
        },
        data() {
            return {
                importModel: 0,
                showSelectImport: true,
                expansionModel: 0,
                loadingTemplates: false,
                templates: null,
                folderGroups: null,
                fieldSets: null,
                folders: null,
                selectedTemplate: null,
                showAddTemplateDlg: false,
                loadingData: false,
                rawAddData: null,
                dataConverted: null,
                savingChanges: false,
                curFieldset: null,

                dragField: null,
                displayFields: [],
                showSelectFields: false,

                importIDs: [],

                addToExisting: false,

                addFolderTitle: '',

                postTemplates: [],

                selectedMergeFolder: null,
                uploading: false,
                showRename: false,
                refreshTitle: false,
                quickImport: false
            }
        },
        watch: {
          
            
          
            showDialog: {
              handler(shown) {
                if (shown && !this.loadingTemplate && this.templates == null) {
                  this.getTemplates();
                }
              },
              immediate: true
            },
            selectedTemplate(selectedTemplate) {
                this.rawAddData = null;
                this.curFieldset = null;
                this.displayFields = [];
                this.importModel = 0;
                this.loadData();
            },
            importIDs(newValue) {

                if (newValue != null && newValue.length > 0) {



                    let importLines = JSON.parse(this.selectedTemplate.data).values.filter(obj => this.importIDs.includes(obj.importID));


                    //Recalculate the Suggested Folder Title
                    try {
                        this.addFolderTitle = importLines[0][JSON.parse(this.selectedTemplate.data).headers[this.selectedTemplate.folderNameIndex].value];
                    } catch (e) {
                        // eslint-disable-next-line
                        console.log(e)
                        this.addFolderTitle = null;
                    }


                    let fieldSets = [];

                    JSON.parse(this.selectedTemplate.fieldMap).map(item => {
                        return item.fieldSet;
                    }).forEach(fieldSetID => {
                        if (!fieldSets.includes(fieldSetID)) {
                            fieldSets.push(fieldSetID)
                        }
                    });

                    fieldSets = this.fieldSets.filter(obj => fieldSets.includes(obj.id));

                    let tItems = [];

                    fieldSets.forEach((fieldSet, i) => {
                        let tItem = {};
                        tItem.id = fieldSet.id;
                        tItem.description = fieldSet.description;
                        tItem.fields = JSON.parse(fieldSet.fields);
                        tItems.push(tItem)
                    });

                    fieldSets = tItems;

                    let fieldSetTemplates = [];

                    let templateIndex = 0;


                    importLines.forEach(line => {
                        fieldSets.forEach(fieldSet => {
                            let fieldSetTemplate = {
                                index: templateIndex,
                                id: fieldSet.id,
                                description: fieldSet.description,
                                postFields: []
                            };
                            templateIndex = ++templateIndex;

                            JSON.parse(this.selectedTemplate.fieldMap).filter(obj => obj.fieldSet == fieldSetTemplate.id).forEach(mapField => {

                                let postField = {
                                    id: mapField.field,
                                    description: fieldSet.fields.filter(obj => obj.id == mapField.field)[0].description,
                                    value: line[JSON.parse(this.selectedTemplate.data).headers.filter(obj => obj.index == mapField.index)[0].value]
                                };
                                if (postField.value != null && postField.value != '') {
                                    fieldSetTemplate.postFields.push(postField)
                                }
                            });
                            fieldSetTemplates.push(fieldSetTemplate)
                        })

                    });


                    // Step 1 Remove All Empty Field Sets
                    fieldSetTemplates = fieldSetTemplates.filter(obj => obj.postFields.length > 0);

                    //Step 2 Remove All Multiple Field Sets that shouldn't be there

                    let filteredTemplates = [];

                    fieldSetTemplates.forEach(template => {

                        // Check Multiple or Not
                        if (!this.intToBool(this.fieldSets.filter(obj => obj.id == template.id)[0].allowMultiple)) {
                            // If Only 1 Allowed Then Check if there is already 1
                            if (filteredTemplates.filter(obj => obj.id == template.id).length == 0) {
                                filteredTemplates.push(template);
                            }
                        } else {
                            // Multiple are allowed
                            filteredTemplates.push(template);
                        }
                    });


                    this.postTemplates = filteredTemplates
                }
            }
        },
        methods: {
            showMerge() {
                try {
                    let arrGroups = this.selectedTemplate.folderGroup

                    return this.folders
                        .filter(obj => obj.description.toLowerCase() == this.addFolderTitle.toLowerCase())
                        .map(obj => {
                            try {
                                return JSON.parse(obj.folderGroups)
                            } catch (e) {
                                console.log(e)
                                return []
                            }
                        })
                        .reduce((list, item) => {
                            return list.concat(item)
                        }, [])
                        .reduce((value, item) => {
                            if (arrGroups.includes(item)) {
                                value = true
                            }
                            return value
                        }, false)
                } catch (e) {
                    // eslint-disable-next-line
                    console.log(e)
                }

            },

            quickImportRun(importRun = false) {

                let tItems = this.$refs.datagridImport.filteredItems;

                let headers = JSON.parse(this.selectedTemplate.data).headers;

                let headerTitle = headers.filter(obj => obj.index == this.selectedTemplate.folderNameIndex)[0].value;

                this.importIDs = tItems.filter(obj => obj[headerTitle].trim().toLowerCase().split(String.fromCharCode(160)).join(' ') == tItems[0][headerTitle].trim().toLowerCase().split(String.fromCharCode(160)).join(' ')).map(obj => {return obj.importID})

                if (importRun) {
                    this.$nextTick(() => {

                        if (
                            this.quickImport
                            && !(this.addFolderTitle != null && this.folders.filter(obj => obj.description.toLowerCase() == this.addFolderTitle.toLowerCase()).length > 0)
                            && this.importIDs.length >= 1
                        ) {
                            this.uploadNewFolder(importRun)
                        } else {
                            this.saveChanges();
                        }
                    })
                }
            },

            uploadNewFolder(importRun = false) {
                this.uploading = true;
                let jBody = {
                    description: this.addFolderTitle,
                    folderGroups: this.templateFolderGroups,
                    fieldSets: this.postTemplates,
                    userID: this.userInfo.userID
                };
                this.fetch({
                    method: 'POST',
                    url: '/import/folder/' + this.userInfo.entityID,
                    body: JSON.stringify(jBody)
                })
                    .then((data) => {
                        this.folders = this.folders.filter(obj => obj.id != data.id);
                        this.folders.push(data);
                        this.uploading = false;
                        let importLines = JSON.parse(this.selectedTemplate.data);
                        importLines.values = importLines.values.filter(obj => !this.importIDs.includes(obj.importID));
                        this.selectedTemplate.data = JSON.stringify(importLines);
                        this.addFolderTitle = '';
                        this.importIDs = [];
                        this.postTemplates = [];
                        if (importRun) {
                            this.$nextTick(() => {
                                this.quickImportRun(importRun)
                            })
                        } else {
                            this.saveChanges();
                        }
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e);
                        this.uploading = false;
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
                        this.saveChanges();
                    })
            },
            uploadUpdateFolder() {
                this.uploading = true;
                let jBody = {
                    folderGroups: this.templateFolderGroups,
                    fieldSets: this.postTemplates,
                    userID: this.userInfo.userID
                };
                this.fetch({
                    method: 'PATCH',
                    url: '/import/folder/' + this.userInfo.entityID + '/' + this.selectedMergeFolder,
                    body: JSON.stringify(jBody)
                })
                    .then((data) => {
                        this.folders = this.folders.filter(obj => obj.id != data.id);
                        this.folders.push(data);
                        this.uploading = false;

                        let importLines = JSON.parse(this.selectedTemplate.data);
                        importLines.values = importLines.values.filter(obj => !this.importIDs.includes(obj.importID))

                        this.selectedTemplate.data = JSON.stringify(importLines);


                        this.selectedMergeFolder = null;
                        this.importIDs = [];
                        this.postTemplates = [];

                        this.saveChanges();
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e);
                        this.uploading = false;
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
                    })
            },
            switchImportType() {
                this.addToExisting = true;
                this.selectedMergeFolder = this.folders.filter(obj => obj.description.toLowerCase() == this.addFolderTitle.toLowerCase())[0].id
            },
            removeMapLink(index) {
                let tArr = [];

                if (this.selectedTemplate.fieldMap != null && this.selectedTemplate.fieldMap != '') {
                    tArr = JSON.parse(this.selectedTemplate.fieldMap);
                }

                if (tArr.length > 0) {
                    tArr.forEach((line, i) => {
                        if (line.index == index) {
                            tArr.splice(i, 1);
                        }
                    })
                }

                this.selectedTemplate.fieldMap = (tArr.length > 0 ? JSON.stringify(tArr) : null);
            },
            updateFieldMap(index) {

                let tArr = [];

                if (this.selectedTemplate.fieldMap != null && this.selectedTemplate.fieldMap != '') {
                    tArr = JSON.parse(this.selectedTemplate.fieldMap);
                }


                let bFound = false;

                if (tArr.length > 0) {
                    tArr.forEach((line, i) => {
                        if (line.index == index) {
                            tArr[i].field = this.dragField.field;
                            tArr[i].fieldSet = this.dragField.fieldSet;
                            bFound = true
                        }
                    })
                }

                if (!bFound) {
                    tArr.push({
                        index: index,
                        field: this.dragField.field,
                        fieldSet: this.dragField.fieldSet,
                    })
                }

                this.selectedTemplate.fieldMap = (tArr.length > 0 ? JSON.stringify(tArr) : null);

            },
            saveChanges() {
                this.savingChanges = true;
                this.fetch({
                    method: 'PATCH',
                    url: '/import/' + this.userInfo.entityID,
                    body: JSON.stringify(this.selectedTemplate)
                })
                    .then(() => {
                        this.showSnack('Info', 'Changes Have Been Saved', 'Close', 'primary');
                        this.savingChanges = false;
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e);
                        this.savingChanges = false;
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
                    })
            },

            renameTemplate(newName) {
                if (newName != null && newName != '') {
                    this.showRename = false;
                    this.selectedTemplate.description = newName;

                    this.fetch({method: 'PATCH', url: `/import/rename/template/${this.userInfo.entityID}/${this.selectedTemplate.id}`, body: `{"description": "${newName}"}`})
                        .then((data) => {
                            this.showSnack('Info', 'Changes Saved', 'Close', 'primary')
                            this.refreshTitle = true;
                            this.$nextTick(() => {
                                this.refreshTitle = false
                            })
                        })
                        .catch((e) => {
                            // eslint-disable-next-line
                            console.log(e);
                            this.loadingTemplates = false;
                            this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
                        });
                } else {
                    this.showSnack('Error', 'A Template Name Is Required', 'Close', 'red')
                }
            },


            getTemplates() {
                this.loadingTemplates = true;
                this.fetch({method: 'GET', url: '/import/' + this.userInfo.entityID})
                    .then((data) => {

                        this.templates = data.templates;
                        this.folderGroups = data.folderGroups;
                        this.fieldSets = data.fieldSets;
                        this.folders = data.folders;

                        // if (this.templates.length > 0) {
                        //     this.selectedTemplate = this.templates[0]
                        // }

                        this.loadingTemplates = false;
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e);
                        this.loadingTemplates = false;
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
                    })
            },
            addImportTemplate(description) {
                if (description != null && description != '') {
                    this.fetch({
                        method: 'POST',
                        url: '/import/' + this.userInfo.entityID,
                        body: JSON.stringify({description: description})
                    })
                        .then((data) => {

                            if (this.templates == null) {
                                this.templates = [];
                            }

                            this.templates.push(data);
                            this.selectedTemplate = this.templates[this.templates.length - 1];

                            this.showAddTemplateDlg = false;
                        })
                        .catch((e) => {
                            // eslint-disable-next-line
                            console.log(e);
                            this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
                            this.showAddTemplateDlg = false;
                        })
                } else {
                    this.showSnack('Error', 'A Description is Required', 'Close', 'red')
                }
            },

            removeImportTemplate(template) {
                this.selectedTemplate = null;

                this.fetch({
                    method: 'DELETE',
                    url: '/import/' + this.userInfo.entityID + '/' + template.id
                })
                    .then((data) => {
                        this.templates = this.templates.filter(obj => obj.id != template.id)
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e);
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
                        this.showAddTemplateDlg = false;
                    })
            },

            loadData() {
                this.loadingData = true;
                this.fetch({
                    method: 'GET',
                    url: '/import/data/' + this.userInfo.entityID + '/' + this.selectedTemplate.id
                })
                    .then((data) => {
                        this.selectedTemplate.data = data.data;
                        this.loadingData = false;
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e);
                        this.loadingData = false;
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
                    })
            },
            dataConversion() {
                if (this.rawAddData != null && this.rawAddData != '') {
                    try {
                        let rows = this.rawAddData.split('\n');
                        rows.forEach((row, i) => {
                            if (row == '') {
                                rows.splice(i, 1)
                            } else {
                                rows[i] = row.split('\t')
                            }
                        });

// Create Headers
                        let headers = [];
                        rows[0].forEach((heading, i) => {
                            headers.push({text: heading, value: heading, index: i})
                        });
                        //headers = headers.push({ text: 'importID', value: 'importID' })
// Create Values
                        let values = [];
                        rows.forEach((row, i) => {
                            if (i != 0) {
                                try {
                                    let tValueRow = {};
                                    row.forEach((val, i) => {
                                        tValueRow[headers[i].value] = val
                                    });
                                    tValueRow.importID = i;
                                    values.push(tValueRow)
                                } catch (e) {
                                    // eslint-disable-next-line
                                    console.log(e)
                                }

                            }
                        });

                        this.selectedTemplate.data = JSON.stringify({headers: headers, values: values});
                    } catch (e) {
                        // eslint-disable-next-line
                        this.showSnack('Error', 'Something Went Wrong Please Contact Support', 'Close', 'red');
                        console.log(e)
                    }
                }

            },
        },
        computed: {

            selectedTemplateData() {

                if (this.selectedTemplate != null && this.selectedTemplate.data != null) {
                    return JSON.parse(this.selectedTemplate.data)
                } else {
                    return null
                }
            },


            templateFolderGroups: {
                get: function () {
                    if (this.selectedTemplate != null && this.selectedTemplate.folderGroup != null && this.selectedTemplate.folderGroup != '' && JSON.parse(this.selectedTemplate.folderGroup).length > 0) {
                        return JSON.parse(this.selectedTemplate.folderGroup)
                    } else {
                        return null
                    }
                },
                set: function (newValue) {
                    if (newValue != null && newValue.length > 0) {
                        this.selectedTemplate.folderGroup = JSON.stringify(newValue)
                    } else {
                        this.selectedTemplate.folderGroup = null
                    }
                }
            },
        },
    }
</script>

<style scoped>

</style>
