<template>
  
  <v-card style="border-radius: 10px !important;">
    
    <div class="py-2 px-2">
      <div class="body-2 font-weight-bold">
        Information Request by {{request.createdBy.fullName}}
      </div>
      <div class="caption" v-if="request.prompt" style="white-space: normal !important;">
        {{request.prompt}}
      </div>
    </div>
    
    <v-divider></v-divider>
    
    <v-card-text class="py-2 px-2">
      
      <div v-for="field in request.fields" class="pb-1">
        
        <div class="caption text--grey text--lighten-1">
          {{field.description}}
        </div>
        
        <div class="pl-2">
          <template v-if="!field.value && !field.defaultValue">
            <div class="body-2 deep-orange--text">
              Awaiting completion
            </div>
          </template>
          <template v-else-if="field.fieldType === 1">
            <div class="body-2">
              {{ field.value || field.defaultValue }}
            </div>
          </template>
          <template v-else-if="field.fieldType === 2">
            <div class="body-2">
              {{ format(new Date(field.value || field.defaultValue), `YYYY-MM-DD${field.fieldOptions?.dateOnly ? '' : ' HH:mm'}`) }}
            </div>
          </template>
          <template v-else-if="field.fieldType === 3">
            <div class="body-2">
              {{ +(field.value || field.defaultValue) }}
            </div>
          </template>
          <template v-else-if="field.fieldType === 4">
            <div class="body-2">
              {{ (+(field.value || field.defaultValue)).toFixed(2) }}
            </div>
          </template>
          <template v-else-if="field.fieldType === 5">
            <div class="body-2">
              {{ field.value || field.defaultValue }}
            </div>
          </template>
          <template v-else-if="field.fieldType === 6">
            <div class="body-2">
              <app-general-file-viewer
                  :card-props="{flat: true}"
                  :document-id="(field.value || field.defaultValue).documentId"
                  :filename="(field.value || field.defaultValue).filename"
                  :size="(field.value || field.defaultValue).size"
              />
            </div>
          </template>
        
        </div>
      
      
      
      
      
      </div>
    </v-card-text>
    
    <v-divider></v-divider>
    
    <v-card-actions v-if="request.targetId && !request.submitted" class="text-center d-flex justify-center pt-2 mt-0">
<!--      <v-btn @click="console.log(request)"></v-btn>-->
      <div v-if="loadingContactInfo" class="c-d-flex c-align-center c-justify-center">
        fullName
        mobile
      </div>
      <div v-else-if="contactInfo">
        <div class="caption text--lighten-1">Targeted To:</div>
        
        <div class="c-d-flex c-align-center pt-1 pl-2">
          <div>
            
            <div
                class="avatar-circle c-d-flex c-align-center c-justify-center white--text"
                style="width: 40px; height: 40px; border-radius: 50%;"
                :style="{
                        background: getColor()({ shades: ['800'], text: contactInfo.fullName}),
                      }"
            >
              <div class="body-1 font-weight-bold white--text">{{ getInitials(contactInfo.fullName) }}</div>
            </div>
          </div>
          
          <div class="pl-2">
            <div class="body-2 font-weight-bold">
              {{ contactInfo.fullName }}
            </div>
            <div class="caption">
              {{ contactInfo.mobile }}
            </div>
          </div>
        </div>
      
      </div>
      
<!--      {{contactInfo}}-->
      
      
      
<!--      <v-btn-->
<!--          :block="true"-->
<!--          color="primary"-->
<!--          variant="elevated"-->
<!--          text="Open Request"-->
<!--          prepend-icon="edit"-->
<!--          @click="showInformationRequest = true"-->
<!--      >-->
<!--      </v-btn>-->
<!--    -->
    </v-card-actions>
<!--    -->
    <v-card-actions class="pt-0 mt-0" v-else-if="request.submitted">
      <div>
        <div class="caption text--lighten-1">
          Submitted By:
        </div>
        
        <div class="c-d-flex c-align-center pt-1 pl-2">
          <div>
            
            <div
                class="avatar-circle c-d-flex c-align-center c-justify-center white--text"
                style="width: 40px; height: 40px; border-radius: 50%;"
                :style="{
              background: getColor()({ shades: ['800'], text: request.submittedName}),
            }"
            >
              <div class="body-1 font-weight-bold white--text">{{ getInitials(request.submittedName) }}</div>
            </div>
          </div>
          
          <div class="pl-2">
            <div class="body-2 font-weight-bold">
              {{ request.submittedName }}
            </div>
            <div class="caption">
              {{ format(new Date(request.submittedDate), 'YYYY-MM-DD HH:mm') }}
            </div>
          </div>
        </div>
      </div>
    </v-card-actions>
  </v-card>

</template>


<script>
import {format} from 'date-fns'
import AppGeneralFileViewer from "@/components/General/AppGeneralFileViewer.vue";
import {getInitials} from "../codeFunctions";
import {getColor} from "random-material-color/dist/randomColor";

export default {
  components: {AppGeneralFileViewer},
  props: {
    request: {
      type: Object,
      required: true
    },
    messageId: Number,
    
  },
  data() {
    return {
      loadingContactInfo: false,
      contactInfo: null,
    }
  },
  
  watch: {
    "request.targetId": {
      immediate: true,
      handler(val) {
        if (val)
          this.fetchContactInfo()
      }
    }
  },
  methods: {
    getColor() {
      return getColor
    },
    getInitials,
    format,
    async fetchContactInfo() {
      try {
        this.loadingContactInfo = true
        this.contactInfo = await this.$newReq('GET', `contacts/contactInfo/${this.request.targetId}`)
      } catch (e) {
        console.log(e)
        
      } finally {
        this.loadingContactInfo = false
      }
    }
  },
}
</script>


<style scoped>

</style>