import { render, staticRenderFns } from "./TaskLoadingContainer.vue?vue&type=template&id=162b9d9a&scoped=true"
import script from "./TaskLoadingContainer.vue?vue&type=script&lang=js"
export * from "./TaskLoadingContainer.vue?vue&type=script&lang=js"
import style0 from "./TaskLoadingContainer.vue?vue&type=style&index=0&id=162b9d9a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "162b9d9a",
  null
  
)

export default component.exports