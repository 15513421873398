<template>
    <v-layout row align-center class="hover-item pa-1" :class="ann.notification === 1 ? 'secondary lighten-5' : null" @click="$emit('removeNotification')">
        <v-flex shrink v-if="ann.icon !== null" class="pl-2">
            <v-icon :color="ann.iconColor || 'primary'">{{ann.icon}}</v-icon>
        </v-flex>
        <v-flex xs12 class="px-2">
            <div class="font-weight-bold" v-if="ann.title !== null" :class="`${ann.titleColor || 'black'}--text`">{{ann.title}}</div>
            <div class="caption" v-if="ann.subText !== null">{{ ann.subText }}</div>
            <v-layout row align-end justify-space-between>
                <v-flex shrink style="white-space: nowrap" class="caption font-weight-bold secondary--text pr-2" >
                    <a
                        v-if="ann.link !== null"
                        :href="ann.linkURL"
                        class="secondary--text"
                        style="text-decoration: none"
                        target="_blank"
                    >
                        {{ann.linkText}}
                    </a>
                </v-flex>
                <v-flex shrink style="white-space: nowrap;" class="caption greyType--text">
                    {{format(ann.date, 'Do MMMM YYYY  h:mm A')}}
                </v-flex>
            </v-layout>
        </v-flex>
    </v-layout>
</template>

<script>

import {format} from 'date-fns';

export default {
    props: {
        ann: Object,
    },
    methods: {
        format:format
    },

}
</script>

<style scoped>

</style>