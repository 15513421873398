<template>
    <div>
        <div class="pa-2">
            <report-condition-add-btn
                    @input="addWrapper($event)"
                    :add-only="conditions.length <= 0"
                    :show-condition="false"
            />
        </div>

        <div v-for="(wrapper, index) in conditions" class="pl-2">
            <report-condition-wrapper
                    :wrapper="wrapper"
                    :flat-fields="flatFields"
                    :users="users"
                    :select-lists="selectLists"
                    :first-item="index == 0"
                    @spliceMe="removeConditionChild(index)"
            />
        </div>


    </div>
</template>

<script>
    import {codeBaseMixin} from "../../../codeBaseMixin";
    import ReportConditionAddBtn from "./Report Conditions/ReportConditionAddBtn";
    import ReportConditionWrapper from "./Report Conditions/ReportConditionWrapper";

    export default {
        components: {ReportConditionWrapper, ReportConditionAddBtn},
        mixins: [codeBaseMixin],
        props: {
            selectedReport: Object,
            userInfo: Object,
            steps: Array,
            fields: Array,
            flatFields: Object,
            manageFields: Array,
            dataSets: Array,
            allDataSetFields: Array,
            allWorkflowStatuses: Array,
            selectedFields: Array,
            groupingOrder: Array,
            conditions: Array,
            users: Array,
            selectLists: Array,
        },
        data() {
            return {
                key: 'value'
            }
        },
        methods: {
            addWrapper(type) {
                this.conditions.push({
                    type: 'W',
                    function: type,
                    children: []
                })
            },

            removeConditionChild(index) {
                this.conditions.splice(index, 1);
            },

        },
        computed: {

        }

    }
</script>

<style scoped>

</style>
