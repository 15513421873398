<template>
    <div>
        <div v-if="showLoading" class="pa-2 text-xs-center" style="width: 100%">
            <v-progress-circular
                    :size="40"
                    :width="5"
                    color="primary"
                    indeterminate
            ></v-progress-circular>
        </div>
        <div
                v-for="message in messagesAdditional"
                v-else
                :key="message.id"
        >
            <v-card
                    flat
                    @dragstart="$emit('dragMessage', message); $emit('showLinkOffice', false)"
                    :draggable="typeof userPrincipal.default != 'undefined' && userPrincipal.default == true"
            >
                <v-layout
                        row
                        align-center
                        style="overflow-x: hidden; cursor: pointer"
                        :class="typeof selectedMessage != 'undefined' && selectedMessage != null && selectedMessage.id == message.id ? 'selectedMessage' : (!message.isRead ? 'unread' : 'read')"
                        @click="$emit('selectMessage', message); $emit('showLinkOffice', false)"
                        class="py-1"
                >
                    <v-flex shrink class="px-1">
                        <!--Mail Flag-->
                        <div v-if="typeof userPrincipal.default != 'undefined' && userPrincipal.default == true">
                            <v-tooltip top>
                                <v-icon @click="flagMessageFn(message)" style="cursor: pointer" slot="activator" :class="message.starred ? 'red--text' : (typeof selectedMessage != 'undefined' && selectedMessage != null && selectedMessage.id == message.id ? 'white--text' : 'greyType--text text--lighten-2')">flag</v-icon>
                                <span>{{ message.starred ? 'Remove Flag' : 'Flag Message' }}</span>
                            </v-tooltip>
                        </div>
                        <!--Linked To Task-->
                        <div v-if="typeof message.tasks != 'undefined' && message.tasks.length > 0">
                            <v-icon class="px-1" color="primary">assignment_turned_in</v-icon>
                        </div>
                    </v-flex>
                    <v-flex xs12 style="overflow-x: hidden">
                        <v-layout column>
                            <v-flex shrink style="overflow-x: hidden">
                                <v-layout row align-center>
                                    <v-flex
                                        xs12
                                        class="subheading px-1 text-truncate"
                                        style="white-space: pre; overflow-x: hidden"
                                        v-if="message.from != null && message.from != ''"
                                        :style="!message.isRead ? 'font-weight: 500' : null"
                                    >
                                        {{ message.from.split('<')[0] }}
                                    </v-flex>
                                    <v-flex shrink class="pr-1">
                                    <span style="white-space: nowrap" class="greyType--text caption">
                                        {{ message.displayDate }}
                                    </span>
                                    </v-flex>
                                </v-layout>
                            </v-flex>
                            <v-flex shrink style="overflow-x: hidden">
                                <v-layout row style="overflow-x: hidden" align-center>
                                    <v-flex xs12 style="overflow-x: hidden">
                                        <div style="overflow-x: hidden" class="text-truncate" v-if="message.subject != null && message.subject != ''">
                                            <span class="body-1" style="white-space: pre;">{{ message.subject }}</span>
                                        </div>
                                        <div style="overflow-x: hidden" class="text-truncate">
                                            <span class="caption greyType--text" style="white-space: pre;">{{ message.snippetDisplay.replace(/(\r\n|\n|\r)/gm," ") }}</span>
                                        </div>
                                    </v-flex>
                                    <v-flex shrink>
                                        <v-tooltip top>
                                            <v-icon
                                                    color="red"
                                                    slot="activator"
                                                    style="cursor: pointer"
                                                    class="pa-1"
                                                    @click="archiveMessageFn(message)"
                                                    :disabled="typeof userPrincipal.default == 'undefined'"
                                            >
                                                archive
                                            </v-icon>
                                            <span>Archive Message</span>
                                        </v-tooltip>
                                    </v-flex>
                                </v-layout>
                            </v-flex>
                        </v-layout>
                    </v-flex>
                </v-layout>
                <v-progress-linear
                        v-if="message.showLoading"
                        indeterminate
                        height="3"
                        class="pa-0 ma-0"
                ></v-progress-linear>
                <v-divider></v-divider>
            </v-card>

        </div>

        <div
                v-if="!showLoading && typeof nextLink != 'undefined' && nextLink != null && nextLink != ''"
                class="text-xs-center"
        >
            <v-btn
                    flat
                    color="secondary"
                    :loading="loadingNext"
                    :disabled="loadingNext"
                    @click="loadMoreFn"
            >
                Load More
            </v-btn>
        </div>
    </div>
</template>

<script>
    import {codeBaseMixin} from "../../../../codeBaseMixin";

    export default {
        mixins: [codeBaseMixin],
        props: {
            userInfo: Object,
            messages: Array,
            nextLink: String,
            showLoading: {
                type: Boolean,
                default: false
            },
            selectedMessage: Object,
            loadingNext: Boolean,
            loadMoreFn: Function,
            dragMessage: Object,
            movingMessage: Boolean,
            archiveMessageFn: Function,
            flagMessageFn: Function,
            showLinkOffice: Boolean,
            userPrincipal: Object
        },
        computed: {
            messagesAdditional() {
                return this.messages.map((message) => {
                    message.displayDate = this.getShortDate(message.date);
                    message.snippetDisplay = message.snippet != null ? message.snippet.replace(/\n/g," ") : '';
                    if (this.movingMessage && this.dragMessage != null && this.dragMessage.id == message.id) {
                        message.showLoading = true;
                    } else {
                        message.showLoading = false;
                    }
                    return message
                })
            }
        }
    }
</script>

<style scoped>
    .selectedMessage {
        border-left: 4px solid var(--v-secondary-base);
        background-color: var(--selected-item);
    }

    .read {
      border-left: 4px solid rgba(0,0,0,0);
      background: var(--hover-item-background);
    }

    .unread {
        border-left: 4px solid var(--v-primary-base);
    }

    .read:hover {
        border-left: 4px solid rgba(0,0,0,0);
        background-color: var(--hover-item-background);
    }

    .unread:hover {
        border-left: 4px solid rgba(0,0,0,0);
        background-color: var(--hover-item-background);
    }
</style>
