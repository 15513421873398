<template>
    <div>
        <div class="pa-2 text-xs-center" v-if="loading">
            <v-progress-circular
                    :size="40"
                    :width="5"
                    color="primary"
                    indeterminate
            ></v-progress-circular>
        </div>
        <template v-else>
            <div :class="topicClass">
                <div :class="headingClass">
                    Create Task Menu Options:
                </div>
                <div :class="itemClass" class="pr-2">
                    <v-switch
                            class="pa-0 ma-0 pl-4"
                            hide-details
                            color="primary"
                            v-model="createVisibility"
                            label="Show in Create Task Menu"
                            @change="updateCreateTaskVisibility()"
                    />
                </div>
            </div>
            <div :class="topicClass">
                <div :class="headingClass">
                    Workflow Assign Groups:
                </div>
                <div :class="itemClass" class="pr-2">
                    <v-autocomplete
                            dense
                            v-model="assignGroups"
                            :items="userGroupsSorted"
                            label="No Assign Groups"
                            hide-details
                            single-line
                            color="primary"
                            item-text="description"
                            item-value="id"
                            multiple
                            small-chips
                            deletable-chips
                            class="pa-0 ma-0 pl-3 pr-3"
                            @input="updateWorkflowDelegation"
                    />
                </div>
            </div>
            <div :class="topicClass">
                <div :class="headingClass">

                    Workflow Delegation Groups:
                </div>
                <div :class="itemClass" class="pr-2">
                    <v-autocomplete
                            dense
                            v-model="delegationGroups"
                            :items="userGroupsSorted"
                            label="No Delegation Groups"
                            hide-details
                            single-line
                            color="primary"
                            item-text="description"
                            item-value="id"
                            multiple
                            small-chips
                            deletable-chips
                            class="pa-0 ma-0 pl-3 pr-3"
                            @input="updateWorkflowDelegation"
                    />
                </div>
            </div>
            <div :class="topicClass">
                <div :class="headingClass">
                    Sharing Options:
                </div>
                <div :class="itemClass" class="pr-2">
                    <v-switch
                            class="pa-0 ma-0 pl-4"
                            hide-details
                            color="primary"
                            v-model="allowShare"
                            label="Allow Sharing of Tasks"
                            @change="updateWorkflowShare()"
                            :disabled="allowNoFolder"
                    />
                    <v-switch
                            v-show="allowShare"
                            class="pa-0 ma-0 pl-4 pt-1"
                            hide-details
                            color="primary"
                            v-model="allowOwnerShare"
                            label="Allow Owner Share"
                            @change="updateAllowOwnerShare()"
                    />
                </div>
                <div :class="topicClass" v-show="allowShare">
                    <div :class="headingClass">
                        Share Admin Groups:
                    </div>
                    <div :class="itemClass" class="pr-2">
                        <v-autocomplete
                                dense
                                v-model="shareAdminGroups"
                                :items="userGroupsSorted"
                                label="Share Admin Groups"
                                hide-details
                                single-line
                                color="primary"
                                item-text="description"
                                item-value="id"
                                multiple
                                small-chips
                                deletable-chips
                                class="pa-0 ma-0 pl-3 pr-3"
                                @input="updateWorkflowShareAdminGroups"
                        />
                    </div>
                </div>
                <div :class="topicClass">
                    <div :class="headingClass">
                        Folder Options:
                    </div>
                    <div :class="itemClass" class="pr-2">
                        <v-switch
                                class="pa-0 ma-0 pl-4"
                                hide-details
                                color="primary"
                                v-model="allowNoFolder"
                                label="Allow No Folder"
                                @change="updateWorkflowNoFolder()"
                        />
                    </div>
                </div>
                <div :class="topicClass">
                    <div :class="headingClass">
                        Limit Folder Selection to:
                    </div>
                    <div :class="itemClass" class="pr-2">
                        <v-autocomplete
                                dense
                                v-model="limitFolderGroups"
                                :items="folderGroupsSorted"
                                label="All Folders"
                                hide-details
                                single-line
                                color="primary"
                                item-text="description"
                                item-value="id"
                                multiple
                                small-chips
                                deletable-chips
                                class="pa-0 ma-0 pl-3 pr-3"
                                @input="updateWorkflowlimitFolders"
                        />
                    </div>
                </div>
                <div :class="topicClass">
                    <div :class="headingClass">
                        Status Options:
                    </div>

                    <div class="c-d-flex align-center">
                        <div :class="itemClass" class="c-flex-equal c-flex-grow-1">
                            <v-switch
                                    class="pa-0 ma-0 pl-4"
                                    hide-details
                                    color="primary"
                                    v-model="allowTextStatus"
                                    label="Allow Text Status"
                                    @change="updateWorkflowTextStatus()"
                            />
                        </div>
                        <div :class="itemClass" class="c-flex-equal c-flex-grow-1">
                            <v-switch
                                    class="pa-0 ma-0 pl-4"
                                    hide-details
                                    color="primary"
                                    v-model="ownerOnlyStatus"
                                    label="Owner Only Update"
                                    @change="updateWorkflowOwnerOnlyStatus()"
                            />
                        </div>
                    </div>
                    <div :class="itemClass" class="c-flex-equal c-flex-grow-1">
                        <v-switch
                                class="pa-0 ma-0 pl-4"
                                hide-details
                                color="primary"
                                v-model="statusUpdateNotifications"
                                label="Enable Status Update Notifications"
                                @change="updateWorkflowStatusNotificationOptions()"
                        />
                    </div>
                    <div :class="itemClass" class="pr-2">
                        <v-autocomplete
                                dense
                                v-model="workflowInfo.statusUpdateNotificationGroups"
                                :items="userGroupsSorted"
                                label="Status Update Notification Groups"
                                hide-details
                                single-line
                                color="primary"
                                item-text="description"
                                item-value="id"
                                multiple
                                small-chips
                                deletable-chips
                                class="pa-0 ma-0 pl-3 pr-3"
                                @input="updateWorkflowStatusNotificationOptions"
                        />
                    </div>
                </div>
                <div :class="topicClass">
                    <div :class="headingClass">
                        Default Title:
                    </div>
                    <div :class="itemClass" class="pr-2">
                        <v-text-field
                                v-model="defaultTitle"
                                label="Edit To Set A Fixed Title"
                                hide-details
                                single-line
                                color="primary"
                                class="pa-0 ma-0 pl-3 pr-3"
                                append-outer-icon="save"
                                @click:append-outer="updateDefaultTitle()"
                        />
                    </div>
                </div>
            </div>
            <div :class="topicClass" v-if="workflowInfo !== null">
                <div :class="headingClass">
                    Last Workflow Step:
                </div>
                <div :class="itemClass" class="pr-2">
                    <v-autocomplete
                            dense
                            v-model="workflowInfo.lastStep"
                            :items="steps"
                            label="Select Last Step"
                            hide-details
                            single-line
                            color="primary"
                            item-text="description"
                            item-value="id"
                            small-chips
                            deletable-chips
                            class="pa-0 ma-0 pl-3 pr-3"
                            @input="updateWorkflowLastStep"
                    />
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import {codeBaseMixin} from "../../../../../codeBaseMixin";
import {descriptionSort, sorter} from "@/codeFunctions";

export default {
    mixins: [codeBaseMixin],
    props: {
        userInfo: Object,
        selectedWorkflow: Object,
    },
    data() {
        return {
            loading: false,
            workflowInfo: null,
            userGroups: null,
            folderGroups: null,
            steps: [],
            headingClass: 'caption greyType--text font-weight-bold',
            topicClass: 'pa-1 pb-2',
            itemClass: 'pl-2',
        }
    },
    mounted() {
        this.getWorkflowInfo();
    },
    watch: {
        selectedWorkflow() {
            this.getWorkflowInfo();
        }
    },
    methods: {
        updateDefaultTitle() {
            this.fetch({
                method: 'POST',
                url: `/workflowEditor/workflowManageSetDefaultTitle/${this.userInfo.entityID}/${this.selectedWorkflow.id}`,
                body: {defaultTitle: this.workflowInfo.defaultTitle}
            })
                    .then(() => {
                        this.showSnack('Info', 'Changes Saved', 'Close', 'primary')
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e)
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
                    })
        },

        getWorkflowInfo() {
            this.loading = true;
            this.fetch({
                method: 'GET',
                url: `/workflowEditor/workflowManageInfo/${this.userInfo.entityID}/${this.selectedWorkflow.id}`
            })
                    .then((data) => {
                        this.workflowInfo = data.info;
                        this.userGroups = data.groups;
                        this.folderGroups = data.folderGroups;
                        this.steps = data.steps;
                        this.loading = false;
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e)
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
                        this.loading = false;
                    })
        },

        updateCreateTaskVisibility() {
            this.fetch({
                method: 'GET',
                url: `/workflowEditor/workflowManageSetCreateVisibility/${this.userInfo.entityID}/${this.selectedWorkflow.id}/${this.workflowInfo.showInCreateTaskMenu}`
            })
                    .then(() => {
                        this.showSnack('Info', 'Changes Saved', 'Close', 'primary')
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e)
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
                    })
        },

        updateWorkflowDelegation() {
            this.fetch({
                method: 'POST',
                url: `/workflowEditor/workflowManageSetDelegation/${this.userInfo.entityID}/${this.selectedWorkflow.id}`,
                body: JSON.stringify({
                    delegationGroups: this.workflowInfo.delegationGroups,
                    assignGroups: this.workflowInfo.assignGroups
                })
            })
                    .then(() => {
                        this.showSnack('Info', 'Changes Saved', 'Close', 'primary')
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e)
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
                    })
        },

        updateWorkflowShare() {
            this.fetch({
                method: 'GET',
                url: `/workflowEditor/workflowManageSetWorkflowShare/${this.userInfo.entityID}/${this.selectedWorkflow.id}/${this.workflowInfo.allowShare}`
            })
                    .then(() => {
                        this.showSnack('Info', 'Changes Saved', 'Close', 'primary')
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e)
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
                    })
        },

        updateWorkflowlimitFolders() {
            this.fetch({
                method: 'POST',
                url: `/workflowEditor/workflowManageSetlimitFolders/${this.userInfo.entityID}/${this.selectedWorkflow.id}`,
                body: JSON.stringify({limitTo: this.limitFolderGroups})
            })
                    .then(() => {
                        this.showSnack('Info', 'Changes Saved', 'Close', 'primary')
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e)
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
                    })
        },

        updateWorkflowLastStep() {
            this.fetch({
                method: 'POST',
                url: `/workflowEditor/workflowManageSetLastStep/${this.userInfo.entityID}/${this.selectedWorkflow.id}/${this.workflowInfo.lastStep}`,
                body: JSON.stringify({limitTo: this.limitFolderGroups})
            })
                    .then(() => {
                        this.showSnack('Info', 'Changes Saved', 'Close', 'primary')
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e)
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
                    })
        },

        updateWorkflowNoFolder() {
            this.fetch({
                method: 'GET',
                url: `/workflowEditor/workflowManageSetNoFolder/${this.userInfo.entityID}/${this.selectedWorkflow.id}/${this.allowNoFolder}`
            })
                    .then(() => {
                        this.updateWorkflowShare();
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e)
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
                    })
        },

        updateWorkflowTextStatus() {
            this.fetch({
                method: 'GET',
                url: `/workflowEditor/status/enableTextStatus/${this.userInfo.entityID}/${this.selectedWorkflow.id}/${this.workflowInfo.allowTextStatus}`
            })
                    .then(() => {
                        this.showSnack('Info', 'Changes Saved', 'Close', 'primary')
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e)
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
                    })
        },

        updateWorkflowStatusNotificationOptions() {
            this.$newReq(
                'POST',
                `workflowEditor/status/updateWorkflowStatusNotificationOptions/${this.userInfo.entityID}/${this.selectedWorkflow.id}`,
                    {
                        statusUpdateNotifications: this.workflowInfo.statusUpdateNotifications,
                        statusUpdateNotificationGroups: this.workflowInfo.statusUpdateNotificationGroups,
                    }
            )
                    .then(() => {
                        this.showSnack('Info', 'Changes Saved', 'Close', 'primary')
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e)
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
                    })
        },

        updateWorkflowOwnerOnlyStatus() {
            this.fetch({
                method: 'GET',
                url: `/workflowEditor/status/ownerOnlyStatus/${this.userInfo.entityID}/${this.selectedWorkflow.id}/${this.workflowInfo.ownerOnlyStatus}`
            })
                    .then(() => {
                        this.showSnack('Info', 'Changes Saved', 'Close', 'primary')
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e)
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
                    })
        },

        updateAllowOwnerShare() {
            this.fetch({
                method: 'GET',
                url: `/workflowEditor/workflowManageSetAllowOwnerShare/${this.userInfo.entityID}/${this.selectedWorkflow.id}/${this.allowOwnerShare}`
            })
                    .then(() => {
                        this.showSnack('Info', 'Changes Saved', 'Close', 'primary')
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e)
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
                    })
        },

        updateWorkflowShareAdminGroups() {
            this.fetch({
                method: 'POST',
                url: `/workflowEditor/workflowManageSetShareAdminGroups/${this.userInfo.entityID}/${this.selectedWorkflow.id}`,
                body: JSON.stringify({shareAdminGroups: this.shareAdminGroups})
            })
                    .then(() => {
                        this.showSnack('Info', 'Changes Saved', 'Close', 'primary')
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e)
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
                    })
        },
    },
    computed: {
        folderGroupsSorted() {
            return sorter(this.folderGroups, descriptionSort)
        },

        userGroupsSorted() {
            return [...this.userGroups].sort((a, b) => a.description > b.description ? 1 : -1)
        },

        assignGroups: {
            // getter
            get: function () {
                if (this.workflowInfo != null && typeof this.workflowInfo.assignGroups != 'undefined') {
                    return JSON.parse(this.workflowInfo.assignGroups)
                } else {
                    return []
                }
            },
            // setter
            set: function (newValue) {

                this.workflowInfo.assignGroups = JSON.stringify(newValue)
            }
        },

        delegationGroups: {
            // getter
            get: function () {
                if (this.workflowInfo != null && typeof this.workflowInfo.delegationGroups != 'undefined') {
                    return JSON.parse(this.workflowInfo.delegationGroups)
                } else {
                    return []
                }
            },
            // setter
            set: function (newValue) {

                this.workflowInfo.delegationGroups = JSON.stringify(newValue)
            }
        },

        createVisibility: {
            // getter
            get: function () {
                if (this.workflowInfo != null) {
                    return this.intToBool(this.workflowInfo.showInCreateTaskMenu)
                } else {
                    return false
                }
            },
            // setter
            set: function (newValue) {
                this.workflowInfo.showInCreateTaskMenu = this.boolToInt(newValue)
            }
        },

        allowShare: {
            // getter
            get: function () {
                if (this.workflowInfo != null) {
                    return this.intToBool(this.workflowInfo.allowShare)
                } else {
                    return false
                }
            },
            // setter
            set: function (newValue) {
                this.workflowInfo.allowShare = this.boolToInt(newValue)
            }
        },

        limitFolderGroups: {
            // getter
            get: function () {
                if (this.workflowInfo != null && typeof this.workflowInfo.limitFolderGroups != 'undefined') {
                    return JSON.parse(this.workflowInfo.limitFolderGroups)
                } else {
                    return []
                }
            },
            // setter
            set: function (newValue) {

                this.workflowInfo.limitFolderGroups = JSON.stringify(newValue)
            }
        },

        allowNoFolder: {
            // getter
            get: function () {
                if (this.workflowInfo != null) {
                    return this.intToBool(this.workflowInfo.blankFolder)
                } else {
                    return false
                }
            },
            // setter
            set: function (newValue) {
                this.workflowInfo.blankFolder = this.boolToInt(newValue);
                this.workflowInfo.allowShare = 1;
            }
        },

        allowTextStatus: {
            // getter
            get: function () {
                if (this.workflowInfo != null) {
                    return this.intToBool(this.workflowInfo.allowTextStatus)
                } else {
                    return false
                }
            },
            // setter
            set: function (newValue) {
                this.workflowInfo.allowTextStatus = this.boolToInt(newValue)
            }
        },

        statusUpdateNotifications: {
            // getter
            get: function () {
                if (this.workflowInfo != null) {
                    return this.intToBool(this.workflowInfo.statusUpdateNotifications)
                } else {
                    return false
                }
            },
            // setter
            set: function (newValue) {
                this.workflowInfo.statusUpdateNotifications = this.boolToInt(newValue)
            }
        },

        ownerOnlyStatus: {
            // getter
            get: function () {
                if (this.workflowInfo != null) {
                    return !!this.workflowInfo.ownerOnlyStatus
                } else {
                    return false
                }
            },
            // setter
            set: function (newValue) {
                this.$set(this.workflowInfo, "ownerOnlyStatus", !!newValue)
            }
        },

        defaultTitle: {
            // getter
            get: function () {
                if (this.workflowInfo != null) {
                    return this.workflowInfo.defaultTitle
                } else {
                    return false
                }
            },
            // setter
            set: function (newValue) {
                this.workflowInfo.defaultTitle = newValue
            }
        },

        allowOwnerShare: {
            // getter
            get: function () {
                if (this.workflowInfo != null) {
                    return this.intToBool(this.workflowInfo.allowOwnerShare)
                } else {
                    return false
                }
            },
            // setter
            set: function (newValue) {
                this.workflowInfo.allowOwnerShare = this.boolToInt(newValue)
            }
        },

        shareAdminGroups: {
            // getter
            get: function () {
                if (this.workflowInfo != null && typeof this.workflowInfo.shareAdminGroups != 'undefined') {
                    return JSON.parse(this.workflowInfo.shareAdminGroups)
                } else {
                    return []
                }
            },
            // setter
            set: function (newValue) {
                this.workflowInfo.shareAdminGroups = JSON.stringify(newValue)
            }
        },
    },
}
</script>

<style scoped>

</style>
