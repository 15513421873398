<template>
    <v-dialog
        v-model="showModel"
        fullscreen
        hide-overlay
        persistent
        transition="dialog-bottom-transition"
        style="overflow-y: auto"
    >
        <v-card flat class="fill-height of-y" style="overflow-y: auto; overflow-x: hidden">
            <v-layout column fill-height style="overflow-y: auto; overflow-x: hidden" >
                <!--Card Title-->
                <v-flex shrink >
                    <v-card-title class="py-2 pr-2 title-color">
                        <v-icon left color="white">shopping_cart</v-icon>
                        <span class="title font-weight-light white--text">Billing Manager</span>
                        <v-spacer></v-spacer>
                        <v-autocomplete
                            class="mx-1"
                            label="Entity"
                            :items="allEntities"
                            v-model="entityFilter"
                            dense
                            hide-details
                            dark
                            solo-inverted
                            flat
                            clearable
                        ></v-autocomplete>
                        <v-autocomplete
                            class="mx-1"
                            label="State"
                            :items="states"
                            v-model="stateFilter"
                            dense
                            hide-details
                            dark
                            solo-inverted
                            flat
                            clearable
                        ></v-autocomplete>
                        <v-autocomplete
                            class="mx-1"
                            label="Status"
                            :items="statuses"
                            v-model="statusFilter"
                            dense
                            hide-details
                            dark
                            solo-inverted
                            flat
                            clearable
                        ></v-autocomplete>
                        <v-autocomplete
                            class="mx-1"
                            label="Campaign"
                            :items="campaigns"
                            v-model="campaignFilter"
                            dense
                            hide-details
                            dark
                            solo-inverted
                            flat
                            clearable
                        ></v-autocomplete>
                        <v-text-field
                            class="mx-1"
                            label="Search"
                            v-model="textFilter"
                            dense
                            hide-details
                            flat
                            clearable
                            dark
                            solo-inverted
                        ></v-text-field>
                        <v-btn flat icon color="white" class="pa-0 ma-0" @click="exportData()">
                            <div style="line-height: 1;" class="text-xs-center">
                                {{filteredUsers.length}}
                            </div>
                        </v-btn>
                        <v-btn flat icon color="white" class="pa-0 ma-0" @click="exportData()">
                            <v-icon>file_download</v-icon>
                        </v-btn>
                        <v-btn flat icon color="white" class="pa-0 ma-0" @click="showModel = false">
                            <v-icon>close</v-icon>
                        </v-btn>
                    </v-card-title>
                </v-flex>
                <!--Loading-->
                <v-flex v-if="loadingUsers" class="pa-2 text-xs-center">
                    <v-progress-circular
                        :size="40"
                        :width="5"
                        color="primary"
                        indeterminate
                    />
                </v-flex>
                <!--Datasets Loaded-->
                <template v-else>
                    <v-flex xs12 class="scrollable">
                        <v-data-table
                            :headers="userHeaders"
                            :items="filteredUsers"
                            item-key="userId"
                            :pagination.sync="pageData"
                            class="fill-height scroll-table"
                        >
                            <template v-slot:items="props">
                                <td>
                                    <span @click="copyStuff(props.item.entityId)" class="underline-hover">{{ props.item.entityId }}</span>
                                </td>
                                <td>
                                    <span @click="copyStuff(props.item.userId)" class="underline-hover">{{ props.item.userId }}</span>
                                </td>
                                <td>
                                    <span @click="copyStuff(props.item.entityName)" class="underline-hover">{{ props.item.entityName }}</span>
                                </td>
                                <td>
                                    <span @click="copyStuff(props.item.username)" class="underline-hover">{{ props.item.username }}</span>
                                </td>
                                <td>
                                    <span @click="copyStuff(`${props.item.name} ${props.item.surname}`.trim())" class="underline-hover">{{ `${props.item.name} ${props.item.surname}`.trim() }}</span>
                                </td>
                                <td>
                                    <v-tooltip right>
                                        <template #activator="{on}">
                                            <v-btn
                                                outline
                                                :color="props.item.disabled ? 'grey' : 'green'"
                                                small
                                                style="z-index: 0"
                                                v-on="on"
                                                :loading="showLoadingUsers.includes(props.item.userId)"
                                                :disabled="showLoadingUsers.includes(props.item.userId)"
                                                @click="toggleDisabled(props.item)"
                                            >
                                                {{props.item.disabled ? 'Disabled' : 'Enabled'}}
                                            </v-btn>
                                        </template>
                                        {{props.item.disabled ? 'Enable User' : 'Disable User'}}
                                    </v-tooltip>
                                </td>
                                <td>{{ props.item.disabledChanged ? format(new Date(props.item.disabledChanged), 'YYYY-MM-DD HH:mm:ss') : '-' }}</td>
                                <td><v-tooltip left>
                                    <template #activator="{on}">
                                        <v-btn

                                            :color="props.item.status === 'BILLABLE' ? 'green white--text' : 'error white--text'"
                                            small
                                            style="z-index: 0"
                                            v-on="on"
                                            :loading="showLoadingUsers.includes(props.item.userId)"
                                            :disabled="showLoadingUsers.includes(props.item.userId)"
                                            @click="toggleBillable(props.item)"
                                        >
                                            {{props.item.status}}
                                        </v-btn>
                                    </template>
                                    {{props.item.status === 'BILLABLE' ? 'Set as NON BILLABLE' : 'Set as BILLABLE'}}
                                </v-tooltip></td>
                                <td>
                                    <v-btn
                                        :color="!props.item.campaign ? 'grey' : getColor({text: props.item.campaign})"
                                        :flat="!props.item.campaign"
                                        small
                                        style="z-index: 0"
                                        :loading="showLoadingUsers.includes(props.item.userId)"
                                        :disabled="showLoadingUsers.includes(props.item.userId)"
                                        @click="campaignUser = props.item"
                                        class="white--text"
                                    >
                                        {{props.item.campaign ? props.item.campaign : 'None'}}
                                    </v-btn>
                                </td>
                            </template>
                        </v-data-table>
                    </v-flex>
                </template>
            </v-layout>
        </v-card>


        <input-dlg
            v-if="campaignUser"
            :show-dialog="!!campaignUser"
            title="Set Campaign"
            :cancel-button="true"
            :show-input-box="false"
            @dismiss="campaignUser = null"
            :body-padding="true"
            :width="500"
        >
            <template slot="body">

                <v-combobox
                    ref="campaignCombo"
                    :items="campaigns"
                    hide-details
                    :label="'New campaign for: ' + (campaignUser.name || '') + ' ' + (campaignUser.surname  || '')"
                    v-model="editCampaignText"
                ></v-combobox>

            </template>
            <template #actions>
                <v-btn
                    small
                    flat
                    color="grey"
                    @click="campaignUser = null"
                >
                    Cancel
                </v-btn>
                <v-btn
                    small
                    color="primary"
                    @click="updateCampaignUser()"
                >
                    Update
                </v-btn>
            </template>
        </input-dlg>


    </v-dialog>
</template>

<script>
import {codeBaseMixin} from "../../codeBaseMixin";
import format from "date-fns/format";
import {arrSplice, copyText, exportStats} from "@/codeFunctions";
import {getColor} from "random-material-color";
import InputDlg from "@/components/General/InputDlg.vue";

export default {
    mixins: [codeBaseMixin],
    components: {
        InputDlg

    },
    props: {
        show: Boolean,
        userInfo: Object
    },
    data() {
        return {
            loadingUsers: false,
            users: [],
            userHeaders: [
                { text: 'Entity ID', value: 'entityId' },
                { text: 'User ID', value: 'userId' },
                { text: 'Entity', value: 'entityName' },
                { text: 'Username', value: 'username' },
                { text: 'Name', value: 'name' },
                { text: 'Disabled', value: 'disabled' },
                { text: 'Enbabled/Disabled Date', value: 'disabledChanged' },
                { text: 'Status', value: 'status' },
                { text: 'Campaign', value: 'campaign' },
            ],
            format,
            pageData: {
                descending: false,
                page: 1,
                rowsPerPage: -1,
                sortBy: null,
                totalItems: null
            },
            entityFilter: null,
            states: [
                "Enabled",
                "Disabled"
            ],
            statuses: [
                "BILLABLE",
                "NON BILLABLE"
            ],
            stateFilter: null,
            statusFilter: null,
            campaignFilter: null,
            textFilter: null,
            showLoadingUsers: [],
            campaignUser: null,
            editCampaignText: null,
            getColor,
        }
    },
    watch: {
        show: {
            immediate: true,
            handler(shown) {
                this.loadUsers();
            }
        },
        campaignUser(val) {
            if (val) {
                this.editCampaignText = val.campaign || ''
                setTimeout(() => {
                    this.$refs.campaignCombo.focus();
                }, 500)
            }

        }
    },
    methods: {

        copyStuff(stuff) {
            copyText(document,stuff);
            this.$snack.info('Copied to clipboard')
        },

        async updateCampaignUser() {
            const user = this.campaignUser;
            this.campaignUser = null;

            try {
                const {userId, entityId} = user
                this.showLoadingUsers.push(user.userId)
                const updatedUser = await this.$newReq('POST', 'billing/setCampaign', {userId, entityId, campaign: this.editCampaignText})
                Object.assign(user, updatedUser);
            } catch (e) {
                console.log(e)
                this.$snack.networkError();
            } finally {
                arrSplice(this.showLoadingUsers, uId => uId === user.userId)
            }

        },

        exportData() {
            exportStats(
                `${this.entityFilter ? this.entityFilter + ' ' : '' }${this.stateFilter ? this.stateFilter + ' ' : '' }${this.statusFilter ? this.statusFilter + ' ' : '' }Users ${format(new Date(), 'YYYY-MM-DD')}`,
                'Users',
                this.userHeaders,
                this.filteredUsers.map(user => {
                    return {
                        ...user,
                        disabledChanged: user.disabledChanged ? format(new Date(user.disabledChanged), 'YYYY-MM-DD HH:mm:ss') : null,
                        disabled: user.disabled ? 'Disabled' : 'Enabled'
                    }
                })
            )
        },

        toggleStatus(user) {
            console.log('Toggle User Status', user)
        },
        async toggleDisabled(user) {
            try {
                const {userId, entityId, disabled} = user
                this.showLoadingUsers.push(user.userId)
                const updatedUser = await this.$newReq('POST', 'billing/toggleDisabled', {userId, entityId, disabled: !disabled})
                Object.assign(user, updatedUser);
            } catch (e) {
                console.log(e)
                this.$snack.networkError();
            } finally {
                arrSplice(this.showLoadingUsers, uId => uId === user.userId)
            }
        },
        async toggleBillable(user) {
            try {
                const {userId, entityId, status} = user
                this.showLoadingUsers.push(user.userId)
                const updatedUser = await this.$newReq('POST', 'billing/toggleBillable', {userId, entityId, status: status === 'BILLABLE' ? 'NON BILLABLE' : 'BILLABLE'})
                Object.assign(user, updatedUser);
            } catch (e) {
                console.log(e)
                this.$snack.networkError();
            } finally {
                arrSplice(this.showLoadingUsers, uId => uId === user.userId)
            }
        },
        async loadUsers() {
            try {
                this.loadingUsers = true;
                this.users = await this.$newReq('get', 'billing/allUsers')
            } catch (e) {
                console.log(e)
                this.$snack.networkError();
            } finally {
                this.loadingUsers = false
            }
        },
    },
    computed: {
        campaigns() {
            return this.users.reduce((list, user) => {
                return !!user.campaign && list.includes(user.campaign) ? list : [...list, user.campaign]
            }, []).sort().filter(val => !!val)
        },
        filteredUsers() {
            const startUsers = [...this.users]
            if (this.textFilter)
                arrSplice(startUsers, userObj => !(JSON.stringify(userObj) || '').toLowerCase().includes((this.textFilter || '').toLowerCase()))
            if (this.statusFilter)
                arrSplice(startUsers, obj => obj.status !== this.statusFilter )
            if (this.stateFilter)
                arrSplice(startUsers, obj => !!obj.disabled === (this.stateFilter === 'Enabled' ))
            if (this.entityFilter)
                arrSplice(startUsers, obj => obj.entityName !== this.entityFilter)
            if (this.campaignFilter)
                arrSplice(startUsers, obj => obj.campaign !== this.campaignFilter)
            return startUsers
        },
        allEntities() {
            return this.users.reduce((list, user) => {
                return list.includes(user.entityName) ? list : [...list, user.entityName]
            }, []).sort()
        },

        showModel: {
            get() {
                return this.show;
            },
            set(val) {
                this.$emit('update:show', val)
            }
        }

    }
}
</script>

<style scoped>

    .underline-hover:hover {
        cursor: pointer;
        text-decoration: underline;
        font-weight: bold;
        color: #000;
    }

</style>
