<template>
    <div class="fill-height">

<!--Loading-->
        <v-layout v-if="loadingViewMail" column fill-height justify-center align-center>
            <v-flex shrink>
                <v-progress-circular :size="30" :width="3" color="primary" indeterminate/>
            </v-flex>
        </v-layout>

<!--MailView-->
        <template v-if="viewReady && selectedMailIDs.length > 0">
            <div class="fill-height scrollable pr-1 py-1 pl-0">
                    <v-layout column fill-height class="scrollable">

<!--Button Bar-->
                        <v-flex shrink class="pt-1 pb-1">
                            <v-card flat style="overflow-x: auto">
                                <!--Create Task-->
                                <v-tooltip top v-if="typeof userInfo.createTask != 'undefined' && intToBool(userInfo.createTask)">
                                    <v-btn
                                            fab
                                            dark
                                            small
                                            color="secondary"
                                            @click="$emit('createTaskSMTP', mail)"
                                            slot="activator"
                                            class="pa-0 ma-1"
                                    >
                                        <v-icon>add</v-icon>
                                    </v-btn>
                                    <span>Create Task</span>
                                </v-tooltip>
<!--Link To Task-->
<!--                                <v-tooltip top>-->
<!--                                    <v-btn-->
<!--                                            fab-->
<!--                                            dark-->
<!--                                            small-->
<!--                                            color="secondary"-->
<!--                                            @click="$emit('showLinkOffice', true); $emit('setOfficeMail', message)"-->
<!--                                            slot="activator"-->
<!--                                            class="pa-0 ma-1"-->
<!--                                    >-->
<!--                                        <v-icon>link</v-icon>-->
<!--                                    </v-btn>-->
<!--                                    <span>Link To Task</span>-->
<!--                                </v-tooltip>-->

                                <!--Quick Tasks-->
                                <template v-if="typeof userInfo.createTask != 'undefined' && intToBool(userInfo.createTask)">
                                    <v-tooltip top v-for="qt in sortedQuickTasks" :key="`quickTaskView1:${qt.id}`">
                                        <v-btn
                                                fab
                                                dark
                                                small
                                                color="secondary"
                                                @click="quickTask(qt)"
                                                slot="activator"
                                                class="pa-0 ma-1"
                                        >
                                            <v-icon>{{qt.config.icon}}</v-icon>
                                        </v-btn>
                                        <span>{{qt.config.description}}</span>
                                    </v-tooltip>
                                </template>
                            </v-card>
                        </v-flex>

                        <v-flex xs12 fill-height class="scrollable">
                            <v-card class="fill-height scrollable">
                                <v-layout column fill-height class="scrollable">
<!--Mail Header-->
                                    <v-flex shrink class="">
                                        <v-layout column style="overflow-x: hidden">
                                            <v-flex shrink style="overflow-x: hidden">
                                                <v-layout row style="overflow-x: hidden" align-center>
                                                    <v-flex shrink class="pa-1 pl-3">
                                                        <v-avatar class="lighten-1" size="40" color="grey">
                                                            <v-icon dark>alternate_email</v-icon>
                                                        </v-avatar>
                                                    </v-flex>

                                                    <v-flex xs12 style="overflow-x: hidden" class="px-3 pt-3 pb-1">
                                                        <div style="overflow-x: hidden" class="pa-0">
                                                <span class="subheading font-weight-regular">
                                                    {{mail.subject}}
                                                </span>
                                                        </div>
                                                        <v-layout style="overflow-x: hidden" column>
                                                            <v-flex style="overflow-x: hidden" shrink>
                                                                <v-layout style="overflow-x: hidden" row align-center>
                                                                    <v-flex xs12 style="overflow-x: hidden" class="text-truncate">
                                                                        <template
                                                                                v-for="(text, i) in mail.from.replace(/>/g, '').split('<')"
                                                                        >
                                                                            <span :class="text[text.length -1] == ' ' ? 'font-weight-bold' : 'font-weight-regular'" class="subheading text-truncate" :key="i">{{ text }}</span>
                                                                        </template>
                                                                    </v-flex>
                                                                    <v-flex>
                                                                        <span class="greyType--text caption text-truncate">{{ getLongDate(mail.mailDate) }}</span>
                                                                    </v-flex>
                                                                </v-layout>
                                                            </v-flex>
                                                            <v-flex style="overflow-x: hidden" class="text-truncate" shrink
                                                                    v-if="toAddresses != null && toAddresses != ''">
                                                                <span class="caption ">{{`To: ${toAddresses}`}}</span>
                                                            </v-flex>
                                                            <v-flex style="overflow-x: hidden" class="text-truncate" shrink
                                                                    v-if="ccAddresses != null && ccAddresses != ''">
                                                                <span class="caption">{{ `CC: ${ccAddresses}` }}</span>
                                                            </v-flex>
                                                        </v-layout>
                                                    </v-flex>

                                                </v-layout>

                                            </v-flex>
                                        </v-layout>
                                    </v-flex>
<!--Tasks-->
                                    <v-flex shrink v-if="typeof mail.tasks != 'undefined' && mail.tasks.length > 0" class="pb-2">
                                        <v-layout row wrap>
                                            <template v-for="task in mail.tasks">
                                                <v-flex shrink :key="task">
                                                    <v-menu open-on-hover lazy offset-y nudge-left="150px">
                                                        <v-chip
                                                                slot="activator"
                                                                small
                                                                label
                                                                color="secondary"
                                                                text-color="white"
                                                        >
                                                            <v-icon left small>assignment_turned_in</v-icon>
                                                            Task #{{task}}
                                                        </v-chip>
                                                        <div style="width: 400px; background: var(--card-back); cursor: pointer">
                                                            <task-loading-container
                                                                    v-if="loadedTasks[task].loadingTask"
                                                                    :task-id="task"
                                                            />
                                                            <task-container
                                                                    v-else
                                                                    :userInfo="userInfo"
                                                                    :task="loadedTasks[task].task"
                                                                    :taskBar="false"
                                                                    :showNav="false"
                                                                    @taskSelected="taskSelected(loadedTasks[task].task)"
                                                            />
                                                        </div>
                                                    </v-menu>
                                                </v-flex>
                                            </template>
                                        </v-layout>
                                    </v-flex>

                                    <!--Mail Body-->
                                    <v-flex xs12 class="scrollable">
                                        <iframe
                                                src="javascript:void(0);"
                                                ref="smtpFrame"
                                                class="fill-height"
                                                frameborder="0"
                                                style="width: 100%; padding: 0; margin: 0; display:block"
                                                @load="iframeReset"
                                        >
                                        </iframe>
                                    </v-flex>
<!--Attachments-->
                                    <v-flex shrink v-if="attachments.length >= 1">
                                        <v-layout row wrap align-center>
                                            <template v-for="attachment in attachments" >
                                                <v-flex shrink class="pa-1" :key="attachment.id">
                                                    <v-card
                                                            hover
                                                            color="primary"
                                                            class="white--text"
                                                    >
                                                        <v-layout row align-center>
                                                            <v-flex v-if="['pdf', 'PDF'].includes(attachment.filename.split('.').pop())" class="pa-1 pl-2">
                                                                <doc-view
                                                                    :url="`${htmlMailBaseSrc + '/' + attachment.id}`"
                                                                >
                                                                    <v-tooltip top>
                                                                        <v-icon
                                                                            style="cursor: pointer"
                                                                            small
                                                                            slot="activator"
                                                                            color="white"
                                                                            class="ma-0"
                                                                        >
                                                                            visibility
                                                                        </v-icon>
                                                                        <span>View Attachment</span>
                                                                    </v-tooltip>
                                                                </doc-view>
                                                            </v-flex>
                                                            <v-flex shrink class="pa-1">
                                                                <a
                                                                        :download="attachment.filename"
                                                                        style="text-decoration: none"
                                                                        :href="`${attachmentDownloadBase}/${attachment.id}/${attachment.filename}`"
                                                                        target="_blank">
                                                                    <v-tooltip top>
                                                                        <v-icon
                                                                                style="cursor: pointer"
                                                                                small
                                                                                slot="activator"
                                                                                color="white"
                                                                                class="ma-0"
                                                                        >
                                                                            {{ 'attachment' }}
                                                                        </v-icon>
                                                                        <span>Download Attachment</span>
                                                                    </v-tooltip>
                                                                </a>
                                                            </v-flex>
                                                            <a
                                                                    style="text-decoration: none"
                                                                    :href="htmlMailBaseSrc + '/' + attachment.filename"
                                                                    target="_blank"
                                                            >
                                                                <v-flex class="pa-1 pr-2 white--text caption">
                                                                    {{attachment.filename}}
                                                                </v-flex>
                                                            </a>
                                                        </v-layout>
                                                    </v-card>
                                                </v-flex>
                                            </template>
                                        </v-layout>
                                    </v-flex>

                                </v-layout>
                            </v-card>
                        </v-flex>



                    </v-layout>

            </div>
        </template>

<!--No Mail SVG-->
        <v-layout v-if="(!viewReady && !loadingViewMail) || (viewReady && !loadingViewMail && selectedMailIDs.length === 0)" column fill-height justify-center align-center>
            <v-flex shrink style="width: 200px">
                <img src="../../../../public/no_mail.svg" alt="No Mail">
            </v-flex>
        </v-layout>

<!--        <div >-->
<!--            {{mail}}-->
<!--            <v-divider></v-divider>-->
<!--            {{html}}-->
<!--        </div>-->
    </div>
</template>

<script>
    import {codeBaseMixin} from "../../../codeBaseMixin";
    import {blankString, arrPopulated} from "../../../codeFunctions";
    import TaskLoadingContainer from "../../../components/TaskContainer/TaskLoadingContainer";
    import TaskContainer from "../../../components/TaskContainer/TaskContainer";
    import {eventBus} from "../../../main";
    import DocView from "@/DocView/DocView";

    export default {
        components: {DocView, TaskContainer, TaskLoadingContainer},
        mixins: [codeBaseMixin],
        props: {
            userInfo: Object,
            html: String,
            mail: Object,
            loadingViewMail: Boolean,
            getTaskHeader: Function,
            selectedMailIDs: Array,
        },
        data() {
            return {
                showFrame: false,
                htmlMailBaseSrc: null,
                attachmentDownloadBase: null,
                loadedTasks: {},
            }
        },
        watch: {

            "mail.tasks" : {
                immediate: true,
                deep: true,
                handler(val) {
                    if (arrPopulated(val)) {
                        val.forEach(taskID => {
                            if (typeof this.loadedTasks[taskID] == 'undefined') {
                                this.$set(this.loadedTasks, taskID, {loadingTask: true, task: null});
                                this.getTaskHeader(taskID)
                                    .then((taskHeader) => {
                                        this.$set(this.loadedTasks, taskID, {loadingTask: false, task: taskHeader});
                                    })
                                    .catch((e) => {
                                        console.log(e)
                                    })
                            }
                        })
                    }
                }
            },

            html: {
                immediate: true,
                handler(val) {
                    if (!blankString(val)) {
                        this.$nextTick(() => {
                            try {
                                this.htmlMailBaseSrc = `${this.serverAddress}/smtpStore/${this.userInfo.entityID}/${this.mail.mailID}/`
                                this.attachmentDownloadBase = `${this.serverAddress}/upload/smtpAttachmentDownload/${this.userInfo.entityID}/${this.mail.mailID}`
                                let sNewHtml = val;
                                this.mail.attachments.forEach(attachment => {
                                    if (typeof attachment.cid != 'undefined' && !blankString(attachment.cid)) {
                                        var cidRe = new RegExp(`src="cid:${attachment.cid}`, 'g');
                                        sNewHtml = sNewHtml.replace(cidRe,`src="${this.htmlMailBaseSrc}${attachment.id}`)
                                    }
                                })
                                // clear content
                                this.$refs.smtpFrame.src = "about:blank";
                                // write new content
                                this.$refs.smtpFrame.contentWindow.document.open();
                                this.$refs.smtpFrame.contentWindow.document.write(sNewHtml);
                                this.$refs.smtpFrame.contentWindow.document.close();
                            } catch (e) {
                                //Some Catch Code
                                console.log(e)
                            }
                        })
                    }
                }
            }
        },

        methods: {
            quickTask(qt) {
                this.$emit('setQT', {workflow: qt.config.workflow, folder: qt.config.folder});
                this.$emit('createTaskSMTP', this.mail);
            },

            taskSelected(task) {
                eventBus.$emit('gotoTask', task)
            },

            iframeReset(event) {
                let doc = this.$refs.smtpFrame;
                // doc.html = this.messageHtml;
                let content = (doc.contentWindow || doc.contentDocument)
                if (content.document)content = content.document;
                doc = content;
                let head = doc.head;
                //Set The Style
                let style = doc.createElement('style');
                head.appendChild(style);
                style.type = 'text/css';
                style.appendChild(document.createTextNode(`
                    body {
                        font-family: sans-serif;
                    }
                        body::-webkit-scrollbar-track {
                        background-color: #ededed;
                    }

                    body::-webkit-scrollbar {
                        width: 8px;
                        height: 8px;
                        background-color: #F5F5F5;
                    }

                    body::-webkit-scrollbar-thumb {
                        background-color: #00897B;
                    }`));
                // var base = doc.createElement('base');
                // base.href = `${this.baseServerAddress}officecache/${this.userInfo.userID}-${this.message.id}/`;
                // head.appendChild(base);

                this.$refs.smtpFrame.contentWindow.document.body.innerHTML = this.$refs.smtpFrame.contentWindow.document.body.innerHTML;


                this.showFrame = true;
            },
        },
        computed: {
            sortedQuickTasks() {
                return this.userInfo.quickTasks.filter(() => true).sort((a,b) => a['config']['order'] < b['config']['order'] ? -1 : 1);
            },

            viewReady() {
                return !blankString(this.html) && !this.loadingViewMail && typeof this.mail != 'undefined' && this.mail !== null && typeof this.mail.id == 'number';
            },

            attachments() {
                return typeof this.mail.attachments != 'undefined' && arrPopulated(this.mail.attachments)
                    ? this.mail.attachments
                        .filter(att => !att.inline)
                        .sort((a,b) => a['filename'] < b['filename'] ? -1 : 1)
                    : []
            },

            toAddresses() {
                try {
                    let addresses = this.mail.to.split(',');
                    addresses = addresses.map((address) => {
                        let tSplit =  address.split('<')
                        return tSplit[0].trim()
                    });
                    return addresses.join(', ')
                } catch (e) {
                    return null
                }
            },

            ccAddresses() {
                try {
                    let addresses = this.mail.cc.split(',');
                    addresses = addresses.map((address) => {
                        let tSplit =  address.split('<')
                        return tSplit[0].trim()
                    });
                    return addresses.join(', ')
                } catch (e) {
                    return null
                }
            },
        }
    }
</script>

<style scoped>

</style>
