<template>
    <v-autocomplete
            :items="selectItems"
            :clearable="!readOnly"
            color="primary"
            flat
            v-model="field.value"
            hide-details
            class="pa-0 ma-0"
            single-line
            :label="field.description"
            dense
            @input="$emit('input')"
            :readonly="readOnly"
    >
    </v-autocomplete>
</template>

<script>
    export default {
        props: {
            field: Object,
            selectLists: {
                type: Array,
                default: () => {
                    return []
                }
            },
            readOnly: {
                default: false,
                type: Boolean
            }
        },
        computed: {
            selectItems() {
                try {
                    if (this.field.listType == 0) {
                        return this.field.items
                    } else {
                        return JSON.parse(this.selectLists.filter(obj => obj.id == this.field.selectList.id)[0].values)
                    }
                } catch (e) {
                    // eslint-disable-next-line
                    console.log(e)

                    return []
                }
            }
        }
    }
</script>

<style scoped>

</style>
