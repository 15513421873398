import { render, staticRenderFns } from "./generalMailContainerAttachment.vue?vue&type=template&id=77c4b732&scoped=true"
import script from "./generalMailContainerAttachment.vue?vue&type=script&lang=js"
export * from "./generalMailContainerAttachment.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77c4b732",
  null
  
)

export default component.exports