<template>
    <div>
        <div class="text-xs-center" v-if="value.value === null && !uploading">
            <v-btn
                    color="primary"
                    small
                    @click="showSnap = true"
                    :disabled="!intToBool(value.editable) || !stepEditable"
            >
                <v-icon left>photo_camera</v-icon>
                <span>Capture Photo</span>
            </v-btn>
        </div>

        <div v-if="uploading">
            <v-layout row justify-center>
                <v-flex shrink>
                        <v-layout row align-center>
                            <v-flex shrink class="pr-3">
                                <v-progress-circular
                                        :size="20"
                                        :width="2"
                                        color="secondary"
                                        :value="uploadPercentage"
                                />
                            </v-flex>
                            <v-flex shrink class="body-2 secondary--text">Uploading</v-flex>
                        </v-layout>
                </v-flex>
            </v-layout>
        </div>

        <div v-if="value.value != null && !uploading">
            <v-layout row align-center>
                <v-spacer></v-spacer>
                <v-flex shrink>
                    <div
                            class="pa-0 ma-0"
                            style="border-radius: 3px; max-width: 300px; cursor: pointer"
                    >
                        <img @click="showLarge = true" style="border-radius: 3px;" class="photoimg" :src="useTempForSrc ? `${serverAddress}\\documentcache\\${value.value}` : `${serverAddress}\\documentstore\\${userInfo.entityID}\\${value.value}`" alt="Photo">
                    </div>
                </v-flex>
                <v-spacer></v-spacer>
                <v-flex shrink>
                    <v-tooltip top>
                        <v-btn
                                color="primary"
                                icon
                                fab
                                @click="showSnap = true"
                                :disabled="!intToBool(value.editable) || !stepEditable"
                                slot="activator"
                        >
                            <v-icon>photo_camera</v-icon>
                        </v-btn>
                        <span>Capture Photo</span>
                    </v-tooltip>
                </v-flex>

                <app-input-dlg
                        v-if="showLarge"
                        :show-dialog="showLarge"
                        :cancel-button="true"
                        :show-input-box="false"
                        @dismiss="showLarge = false"
                        :body-padding="false"
                        :width="800"
                        background="black"
                >
                    <template slot="title">
                        <div v-show="false">TITLE HERE</div>
                    </template>
                    <template slot="body">
                        <v-layout row justify-center>
                            <v-flex shrink>
                                <div style="max-width: 800px" class="black pt-2 pb-0">
                                    <img style="border-radius: 3px;" class="photoimgLarge" :src="useTempForSrc ? `${serverAddress}\\documentcache\\${value.value}` : `${serverAddress}\\documentstore\\${userInfo.entityID}\\${value.value}`" alt="Photo">
                                    <div class="pa-2" style="position: absolute; right: 0; top: 0">
                                        <v-btn
                                                small
                                                color="primary"
                                                flat
                                                class="pa-0 ma-0"
                                                @click="showLarge = false"
                                                icon
                                        >
                                            <v-icon>close</v-icon>
                                        </v-btn>
                                    </div>
                                </div>
                            </v-flex>
                        </v-layout>
                    </template>
                </app-input-dlg>
            </v-layout>
        </div>

        <app-snap-photo
                title="Take Photo"
                :show-snap="showSnap"
                @dismiss="showSnap = false"
                @input="uploadRun"
        />
    </div>
</template>

<script>
    import SnapPhoto from './SnapPhoto'
    import {codeBaseMixin} from "../../../../../../../codeBaseMixin";
    import axios from "axios";
    import InputDlg from "../../../../../../../components/General/InputDlg";
    export default {
        mixins: [codeBaseMixin],
        components: {
            appInputDlg: InputDlg,
            appSnapPhoto: SnapPhoto
        },
        props: {
            userInfo: Object,
            value: Object,
            stepEditable: Boolean
        },
        data() {
            return {
                showSnap: false,
                uploading: false,
                uploadPercentage: 0,
                useTempForSrc: false,
                showLarge: false
            }
        },
        methods: {

            dataURItoBlob(dataURI) {
                // convert base64/URLEncoded data component to raw binary data held in a string
                var byteString;
                if (dataURI.split(',')[0].indexOf('base64') >= 0)
                    byteString = atob(dataURI.split(',')[1]);
                else
                    byteString = unescape(dataURI.split(',')[1]);

                // separate out the mime component
                var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

                // write the bytes of the string to a typed array
                var ia = new Uint8Array(byteString.length);
                for (var i = 0; i < byteString.length; i++) {
                    ia[i] = byteString.charCodeAt(i);
                }

                return new Blob([ia], {type: mimeString});
            },

            uploadRun(picture) {
                this.uploading = true;

                let fd = new FormData();

                let myFile = this.dataURItoBlob(picture);

                fd.append('document', myFile);

                axios.request( {
                    method: "post",
                    url: `${this.serverAddress}/upload/taskDocument/`,
                    data: fd,
                    onUploadProgress: (p) => {
                        this.uploadPercentage = (p.loaded / p.total) * 100
                    },
                }).then (data => {
                    this.uploading = false;
                    this.useTempForSrc = true;
                    this.value.value = data.data.id
                })
                    .catch(err => {
                        console.log(err);
                        this.uploading = false;
                        this.showSnack('Error', 'File Upload Error', 'Close', 'red')
                    })
            }
        }
    }
</script>

<style scoped>
    .photoimg {
        max-width:200px;
        max-height:150px;
        width: auto;
        height: auto;
    }
    .photoimgLarge {
        max-width:800px;
        max-height:600px;
        width: auto;
        height: auto;
    }
</style>
