<template>
    <v-card class="pa-0 ma-0">
        <div
                :draggable="!recycleBin"
                @drag="$event.preventDefault()"
                @dragstart="setDataTransfer($event)"
        >
            <v-card-title
                    class="white--text pa-0 ma-0"
                    :class="!recycleBin ? (stepHidden ? (stepHiddenWhenEmpty ? 'blue darken-3' : 'title-color')  : 'grey') : 'secondary'"
            >
                <v-layout row align-center class="hide-parent" style="overflow-x: hidden" >
                    <v-flex xs12 class="pa-2 text-truncate handle" style="overflow-x: hidden; cursor: pointer" @click="!recycleBin ? showEditStep = true : ''">
                        {{step.description}}
                    </v-flex>
                    <template v-if="!recycleBin">
                    <v-flex shrink class="pr-1 hide-child">
                        <v-tooltip top>
                            <v-btn

                                @click="showEditStep = true"
                                small
                                flat
                                icon
                                class="pa-0 ma-0"
                                color="white"
                                slot="activator"
                            >
                                <v-icon small>edit</v-icon>
                            </v-btn>
                            <span>Configure Step</span>
                        </v-tooltip>
                    </v-flex>
                    <v-flex shrink class="pr-1" v-if="showShownIn != null">
                        <v-menu offset-y left>
                            <template #activator="{ on: menu }">
                                <v-tooltip top>
                                    <template #activator="{ on: tooltip }">
                                        <v-btn
                                                small
                                                icon
                                                color="white"
                                                style="width: 18px; height: 18px"
                                                class="pa-0 ma-0"
                                                v-on="{ ...tooltip, ...menu }"
                                        >
                                            <span :class="stepHidden ? (stepHiddenWhenEmpty ? 'blue--text text--darken-3' : 'primary--text')  : 'greyType--text'"
                                                  style="font-size: 0.8em">{{showShownIn.length}}</span>
                                        </v-btn>
                                    </template>
                                    <span>{{`Field Used as a Show Field in ${showShownIn.length} Rule/s. (Click To View)`}}</span>
                                </v-tooltip>
                            </template>
                          <v-card class="page-back">
                            <v-list dense style="max-height: 300px; overflow-y: auto">
                              <v-subheader class="primary--text">Shown In Rules</v-subheader>
                              <v-divider></v-divider>
                              <v-list-tile
                                  v-for="(item, index) in showShownIn"
                                  :key="index"
                                  @click="scrollToRuleFn(item.id)"
                              >
                                <v-list-tile-action>
                                  <v-tooltip top>
                                    <v-btn
                                        small
                                        icon
                                        flat
                                        left
                                        class="py-1 my-0"
                                        slot="activator"
                                        color="primary"
                                        @click="$emit('editRuleFromID', item.id)"
                                    >
                                      <v-icon>edit</v-icon>
                                    </v-btn>
                                    <span>Edit Rule</span>
                                  </v-tooltip>
                                </v-list-tile-action>
                                <v-list-tile-title>{{ item.description != null ? item.description : `Rule ${item.id}` }}
                                </v-list-tile-title>
                              </v-list-tile>
                            </v-list>
                          </v-card>
                        </v-menu>
                    </v-flex>


                    <v-flex shrink class="pr-1" v-if="showSecureIcon">
                        <v-tooltip top>
                            <v-btn
                                    small flat icon class="pa-0 ma-0" color="white" slot="activator"
                            >
                                <v-icon small>security</v-icon>
                            </v-btn>
                            <span>Step Security</span>
                        </v-tooltip>
                    </v-flex>


                    <v-flex shrink class="pr-1 hide-child">
                        <v-tooltip top>
                            <v-btn
                                    small flat icon class="pa-0 ma-0" color="white" slot="activator"
                                    @click="$emit('copyStep')"
                            >
                                <v-icon small>file_copy</v-icon>
                            </v-btn>
                            <span>Copy Step</span>
                        </v-tooltip>
                    </v-flex>
                    </template>
                </v-layout>
                <v-progress-linear :indeterminate="true" color="white" class="pa-0 ma-0" height="2" v-if="showLoading"></v-progress-linear>
            </v-card-title>
        </div>


        <v-card-text class="pa-1">
            <draggable
                    :list="stepFields"
                    :group="recycleBin ? 'recycleBin' : 'fields'"
                    @start="drag=true"
                    @end="drag=false"
                    @change="log"
                    ghostClass="ghost"
                    v-bind="{handle:'.handle'}"
            >
<!--                <div v-for="field in stepFields">-->
<!--                    {{field.description}}-->
<!--                </div>-->

                <app-workflow-field
                        v-for="field in stepFields"
                        :key="`WorkflowField:${field.id}`"
                        v-show="(!recycleBin ? field.visible == 1 : true) && (!previewRules || (previewRules && !hiddenFields.includes(field.id)))"
                        :userInfo="userInfo"
                        :field="field"
                        :step="step"
                        :steps="steps"
                        :fields="fields"
                        :rules="rules"
                        :fieldSets="fieldSets"
                        :userGroups="userGroups"
                        :selectLists="selectLists"
                        @selectField="$emit('selectField', $event)"
                        @ruleDrop="$emit('ruleDrop', $event)"
                        :selected-field="selectedField"
                        :split-task-workflows="splitTaskWorkflows"
                        :folder-groups="folderGroups"
                        :signatures="signatures"
                        :show-template-editor-fn="showTemplateEditorFn"
                        :rule-ref="ruleRef"
                        :show-select-fields="showSelectFields"
                        :selected-fields="selectedFields"
                        @addToSelection="$emit('addToSelection', $event)"
                        @removeFromSelection="$emit('removeFromSelection', $event)"
                        :scroll-to-rule-fn="scrollToRuleFn"
                        @editRuleFromID="$emit('editRuleFromID', $event)"
                        :preview-rules="previewRules"
                        :preview-values="previewValues"
                        :preview-edit-fields="previewEditFields"
                        @setPreviewValue="$emit('setPreviewValue', $event)"
                        :recycle-bin="recycleBin"
                        :local-data="localData"
                        :show-sync="showSync"
                        :get-task-header="getTaskHeader"
                        @copyField="copyField(field)"
                />
            </draggable>
            <app-add-field-to-step
                    v-if="!recycleBin"
                    :user-info="userInfo"
                    :step="step"
                    :steps="steps"
                    :fields="fields"
                    :rules="rules"
                    :field-sets="fieldSets"
                    :user-groups="userGroups"
                    :select-lists="selectLists"
                    :selected-field="selectedField"
                    :split-task-workflows="splitTaskWorkflows"
                    :folder-groups="folderGroups"
                    :signatures="signatures"
                    :show-template-editor-fn="showTemplateEditorFn"
                    :local-data="localData"
                    :show-sync="showSync"
                    :get-task-header="getTaskHeader"
            >
            </app-add-field-to-step>
        </v-card-text>

        <app-input-dlg
                v-if="!recycleBin"
                :show-dialog="showEditStep"
                title="Configure Step"
                :cancel-button="true"
                :show-input-box="false"
                @dismiss="showEditStep = false"
                :body-padding="false"
                :width="500"
        >
            <template slot="body" v-if="showEditStep">
                <app-workflow-step-configure
                        :userInfo="userInfo"
                        v-model="stepMeta"
                        :userGroups="userGroups"
                        @input="showEditStep = false"
                        :steps="steps"
                        :fields="fields"
                >
                </app-workflow-step-configure>
            </template>
        </app-input-dlg>
    </v-card>
</template>

<script>
    import {codeBaseMixin} from "../../../../../../codeBaseMixin";
    import InputDlg from "../../../../../../components/General/InputDlg";
    import WorkflowField from "./WorkflowField";
    import draggable from 'vuedraggable';
    import AddFieldToStep from './AddFieldToStep'
    import WorkflowStepConfigure from './WorkflowStepConfigure'


    export default {
        components: {
            appAddFieldToStep: AddFieldToStep,
            appWorkflowField: WorkflowField,
            appInputDlg: InputDlg,
            draggable,
            appWorkflowStepConfigure: WorkflowStepConfigure
        },
        mixins: [codeBaseMixin],
        props: {
            userInfo: Object,
            step: Object,
            steps: Array,
            fields: Array,
            rules: Array,
            fieldSets: Array,
            userGroups: Array,
            selectLists: Array,
            selectedField: Object,
            splitTaskWorkflows: Array,
            folderGroups: Array,
            signatures: Array,
            showTemplateEditorFn: Function,
            ruleRef: Object,
            showSelectFields: Boolean,
            selectedFields: Array,
            scrollToRuleFn: Function,
            previewRules: Boolean,
            previewValues: Object,
            previewEditFields: Array,
            hiddenFields: Array,
            recycleFields: Array,
            localData: Object,
            showSync: Boolean,
            getTaskHeader: Function,
        },
        data() {
            return {
                showRename: false,
                showLoading: false,
                showSecurity: false,
                preSecurityValues: null,
                showVisableMenu: false,
                drag: false,
                showEditStep: false
            }
        },
        watch: {
            showSecurity: {
                immediate: true,
                handler(val) {
                    if (val == true) {
                        this.preSecurityValues = JSON.parse(JSON.stringify(this.step.securityLookup));
                    }
                }
            }
        },
        methods: {

            copyField(field) {
                let newVal = null;
                if (this.securityEditableBy.length > 0) {
                    newVal = {};
                    newVal.editableBy = this.securityEditableBy;
                }
                if (this.securityShownTo.length > 0) {
                    if (newVal == null) {
                        newVal = {}
                    }
                    newVal.shownTo = this.securityShownTo;
                }

                this.fetch({
                    method: 'POST',
                    url: `/workflowManager/field/AddLinked/${this.userInfo.entityID}`,
                    body: JSON.stringify({
                        fk_step: field.fk_step,
                        fieldType: field.fieldType,
                        description: `${field.description} Copy`,
                        visible: field.visible,
                        required: field.required,
                        securityLookup: JSON.stringify(newVal),
                        linkLookup: field.linkLookup,
                        lookup: field.lookup,
                        lookup_type: field.lookup_type
                    })
                })
                    .then((data) => {
                        this.fields.push(data)
                        this.showSnack('Info', 'Field added to bottom of step', 'Close', 'primary');
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e);
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
                    })

            },

            setDataTransfer(event) {
                event.dataTransfer.setData('text', `${JSON.stringify({type: 'step', data: this.step})}`);
            },

            log: function(evt) {
                if (typeof evt.added != 'undefined') {
                    // Get The Old Step
                    let oldStepID = evt.added.element.fk_step;
                    // Move The Field Over to a holding step
                    evt.added.element.fk_step = 0;
                    // Fix The Order Of The Old Step First
                    let tSrcList = this.fields.filter(field => field.fk_step == oldStepID)
                        .sort((a,b) => a['field_order'] < b['field_order'] ? -1 : 1)
                        .map((field, index) => {
                            field.field_order = index
                            return field
                        });
                    // Fix The Order Of The New Step
                    let tList = this.fields.filter(field => field.fk_step == this.step.id)
                        .sort((a,b) => a['field_order'] < b['field_order'] ? -1 : 1);
                    evt.added.element.fk_step = this.step.id;
                    tList.splice(evt.added.newIndex, 0, evt.added.element);
                    tList.forEach((field, index) => {
                        field.field_order = index
                    });
                    let tSend = tSrcList.concat(tList).map(field => {
                        return {
                            id:             field.id,
                            fk_step:        field.fk_step,
                            field_order:    field.field_order
                        }
                    });
                    this.showLoading = true;
                    this.fetch({method: 'PATCH', url:`/workflowManager/workflow/step/fieldOrder/${this.userInfo.entityID}`, body: JSON.stringify(tSend)})
                        .then(() => {
                            this.showLoading = false;
                            this.showSnack('Info', 'Changes Saved', 'Close', 'primary')
                        })
                        .catch((e) => {
                            // eslint-disable-next-line
                            console.log(e);
                            this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
                            this.showLoading = false;
                        })
                }

                if (typeof evt.moved != 'undefined') {
                    // Move The Field Over to a holding step

                    this.stepFields.forEach((field, index) => {
                        field.field_order = index
                    });

                    evt.moved.element.field_order = evt.moved.newIndex - 0.5;

                    this.stepFields.forEach((field, index) => {
                        field.field_order = index
                    });

                    let tSend = this.stepFields.map(field => {
                        return {
                            id:             field.id,
                            fk_step:        field.fk_step,
                            field_order:    field.field_order
                        }
                    });
                    this.showLoading = true;
                    this.fetch({method: 'PATCH', url:`/workflowManager/workflow/step/fieldOrder/${this.userInfo.entityID}`, body: JSON.stringify(tSend)})
                        .then(() => {
                            this.showLoading = false;
                            this.showSnack('Info', 'Changes Saved', 'Close', 'primary')
                        })
                        .catch((e) => {
                            // eslint-disable-next-line
                            console.log(e);
                            this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
                            this.showLoading = false;
                        })
                }
            },
        },
        computed: {
            recycleBin() {
                return this.step.id == -1;
            },

            showShownIn() {
                if (typeof this.ruleRef.steps[this.step.id] != "undefined") {
                    return this.ruleRef.steps[this.step.id].length == 0 ? null : this.ruleRef.steps[this.step.id];
                } else {
                    return null;
                }
            },

            stepMeta: {
                get() {
                    return {
                        id: this.step.id,
                        description: this.step.description,
                        hidden: this.step.hidden,
                        securityLookup: this.step.securityLookup,
                        hideIfEmpty: this.step.hideIfEmpty,
                        recycleStep: this.step.recycleStep,
                        stepConfig: this.step.stepConfig
                    }
                },
                set(newVal) {
                    this.step.description = newVal.description;
                    this.step.hidden = newVal.hidden;
                    this.step.securityLookup = newVal.securityLookup;
                    this.step.recycleStep = newVal.recycleStep;
                    this.step.hideIfEmpty = newVal.hideIfEmpty;
                    this.step.stepConfig = newVal.stepConfig;
                }
            },

            securityEditableBy: {
                // getter
                get: function () {
                    return this.step.securityLookup != null && typeof this.step.securityLookup.editableBy != "undefined" ? this.step.securityLookup.editableBy : []
                },
                // setter
                set: function (newValue) {
                    if (this.step.securityLookup == null) {this.step.securityLookup = {}}
                    this.step.securityLookup.editableBy = newValue;
                }
            },

            securityShownTo: {
                // getter
                get: function () {
                    return this.step.securityLookup != null && typeof this.step.securityLookup.shownTo != "undefined" ? this.step.securityLookup.shownTo : []
                },
                // setter
                set: function (newValue) {
                    if (this.step.securityLookup == null) {this.step.securityLookup = {}}
                    this.step.securityLookup.shownTo = newValue;
                }
            },

            showSecureIcon() {
                return ((Array.isArray(this.securityShownTo) ? this.securityShownTo.length : 0) + (Array.isArray(this.securityEditableBy) ? this.securityEditableBy.length : 0)) > 0;
            },

            stepHidden: {
                get: function () {
                    return !this.intToBool(this.step.hidden)
                },
                set: function (newValue) {
                    this.step.hidden = this.boolToInt(!newValue)
                    this.saveVisibility();
                }
            },

            stepHiddenWhenEmpty: {
                get: function () {
                    return this.intToBool(this.step.hideIfEmpty)
                },
                set: function (newValue) {
                    this.step.hideIfEmpty = this.boolToInt(newValue)
                    this.saveVisibility();
                }
            },

            stepFields() {
                if (!this.recycleBin) {
                    return this.fields.filter(field => field.fk_step == this.step.id).sort((a,b) => a['field_order'] < b['field_order'] ? -1 : 1);
                } else {
                    return this.recycleFields.filter(obj => true).sort((a,b) => a['description'] < b['description'] ? -1 : 1);
                }
            },
        }

    }
</script>

<style scoped>

</style>
