<template>
    <v-layout column fill-height class="scrollable">
        <v-flex :xs7="!compactPreview" :xs12="compactPreview" v-show="!fullScreenPreview" class="scrollable">
            <v-layout row fill-height class="scrollable">
                <!--Fields-->
                <v-flex xs3 fill-height class="scrollable pa-1">
                    <v-card class="fill-height">
                        <v-layout column fill-height>
                            <v-flex shrink>
                                <v-card-title class="white--text pa-2 ma-0 primary">
                                    Select Fields
                                </v-card-title>
                            </v-flex>
                            <v-flex xs12 fill-height class="scrollable">
                                <rule-design-select-fields
                                        :selectedReport="selectedReport"
                                        :userInfo="userInfo"
                                        :steps="steps"
                                        :fields="fields"
                                        :dataSets="dataSets"
                                        :all-data-set-fields="dataSetFields"
                                        :all-workflow-statuses="workflowStatuses"
                                        :selected-fields="selectedFields"
                                        @remove="$emit('remove', $event)"
                                        @add="$emit('add', $event)"
                                />
                            </v-flex>
                        </v-layout>
                    </v-card>
                </v-flex>
                <v-flex xs3 fill-height class="scrollable pa-1">
                    <v-card class="fill-height">
                        <v-layout column fill-height>
                            <v-flex shrink>
                                <v-card-title class="white--text pa-2 ma-0 primary">
                                    Report Fields
                                </v-card-title>
                            </v-flex>
                            <v-flex xs12 fill-height class="scrollable">
                                <rule-design-manage-fields
                                        :selectedReport="selectedReport"
                                        :userInfo="userInfo"
                                        :steps="steps"
                                        :fields="fields"
                                        :flat-fields="flatFields"
                                        :manage-fields="manageFields"
                                        :dataSets="dataSets"
                                        :all-data-set-fields="dataSetFields"
                                        :all-workflow-statuses="workflowStatuses"
                                        :selected-fields="selectedFields"
                                        @remove="$emit('remove', $event)"
                                        @add="$emit('add', $event)"
                                        @editAlias="$emit('editAlias', $event)"
                                        @newOrder="$emit('newOrder', $event)"
                                />
                            </v-flex>
                        </v-layout>
                    </v-card>
                </v-flex>
                <!--Conditions And Groups-->
                <v-flex xs6 fill-height class="scrollable">
                    <v-layout column fill-height class="scrollable">
                        <v-flex :xs6="showGroupings" :xs12="!showGroupings" class="pa-1 scrollable">
                            <v-card class="scrollable fill-height">
                                <v-layout column fill-height>
                                    <v-flex shrink>
                                        <v-card-title class="white--text pa-2 ma-0 primary">Conditions</v-card-title>
                                    </v-flex>
                                    <v-flex xs12 fill-height class="scrollable">
                                        <report-conditions
                                                :selectedReport="selectedReport"
                                                :userInfo="userInfo"
                                                :steps="steps"
                                                :fields="fields"
                                                :flat-fields="flatFields"
                                                :manage-fields="manageFields"
                                                :dataSets="dataSets"
                                                :all-data-set-fields="dataSetFields"
                                                :all-workflow-statuses="workflowStatuses"
                                                :selected-fields="selectedFields"
                                                :conditions="conditions"
                                                :users="users"
                                                :select-lists="selectLists"
                                        />
                                    </v-flex>
                                </v-layout>
                            </v-card>
                        </v-flex>
                        <v-flex :xs6="showGroupings" :shrink="!showGroupings" class="pa-1 scrollable">
                            <v-card class="fill-height">
                                <v-layout column fill-height>
                                    <v-flex shrink>
                                        <v-card-title class="white--text pa-0 ma-0 primary">
                                            <v-layout row align-center>
                                                <v-flex xs12 class="pa-2">
                                                    Groupings
                                                </v-flex>
                                                <v-flex shrink class="px-2">
                                                    <v-tooltip left>
                                                        <v-btn
                                                                small icon flat class="ma-0" color="white"
                                                                slot="activator"
                                                                @click="showGroupings = !showGroupings"
                                                        >
                                                            <v-icon>{{!showGroupings ? 'arrow_drop_up' : 'arrow_drop_down'}}</v-icon>
                                                        </v-btn>
                                                        <span>Expand/Hide Groupings</span>
                                                    </v-tooltip>
                                                </v-flex>
                                            </v-layout>
                                        </v-card-title>
                                    </v-flex>
                                    <v-flex xs12 fill-height class="scrollable" v-show="showGroupings">
                                        <rule-groupings
                                                :selectedReport="selectedReport"
                                                :userInfo="userInfo"
                                                :steps="steps"
                                                :fields="fields"
                                                :flat-fields="flatFields"
                                                :manage-fields="manageFields"
                                                :dataSets="dataSets"
                                                :all-data-set-fields="dataSetFields"
                                                :all-workflow-statuses="workflowStatuses"
                                                :selected-fields="selectedFields"
                                                @remove="$emit('remove', $event)"
                                                @add="$emit('add', $event)"
                                                @editAlias="$emit('editAlias', $event)"
                                                @newGroupingOrder="$emit('newGroupingOrder', $event)"
                                                :grouping-order="groupingOrder"
                                        >
                                        </rule-groupings>
                                    </v-flex>
                                </v-layout>
                            </v-card>
                        </v-flex>
                    </v-layout>
                </v-flex>
            </v-layout>
        </v-flex>
        <v-flex :shrink="compactPreview" :xs5="!compactPreview" :xs12="fullScreenPreview" class="scrollable pa-1">
                    <v-card :class="!compactPreview ? 'fill-height' : 'fill-height'">
                        <v-layout column fill-height>
                            <v-flex shrink>
                                <v-card-title class="white--text pa-0 ma-0 primary">
                                    <v-layout row align-center>
                                        <v-flex xs12 class="pa-2">
                                            Preview Report
                                        </v-flex>
                                        <v-flex shrink class="px-2">
                                            <v-tooltip left>
                                                <v-btn
                                                        small icon flat class="ma-0" color="white"
                                                        slot="activator"
                                                        @click="updateSampleSize"
                                                >
                                                    {{value === '0' ? 'All' : value}}
                                                </v-btn>
                                                <span>Preview Sample Size</span>
                                            </v-tooltip>
                                        </v-flex>
                                        <v-flex shrink class="px-2">
                                            <v-tooltip left>
                                                <v-btn
                                                        small icon flat class="ma-0" color="white"
                                                        slot="activator"
                                                        @click="fullScreenPreview = !fullScreenPreview"
                                                >
                                                    <v-icon>{{fullScreenPreview ? 'fullscreen_exit' : 'fullscreen'}}</v-icon>
                                                </v-btn>
                                                <span>Enter/Exit Full Screen</span>
                                            </v-tooltip>
                                        </v-flex>
                                        <v-flex shrink class="pr-2 pl-0" v-show="!fullScreenPreview">
                                            <v-tooltip left>
                                                <v-btn
                                                        small icon flat class="ma-0" color="white"
                                                        slot="activator"
                                                        @click="compactPreview = !compactPreview"
                                                >
                                                    <v-icon>{{compactPreview ? 'arrow_drop_up' : 'arrow_drop_down'}}</v-icon>
                                                </v-btn>
                                                <span>Expand/Hide Preview</span>
                                            </v-tooltip>
                                        </v-flex>

                                        <v-flex shrink class="pr-2 pl-0" v-show="!fullScreenPreview">
                                            <v-tooltip left>
                                                <v-btn
                                                        color="secondary"
                                                        class="ma-0"
                                                        icon
                                                        small
                                                        @click="$emit('post')"
                                                        slot="activator"
                                                >
                                                    <v-icon small>save</v-icon>
                                                </v-btn>
                                                <span>Save Changes</span>
                                            </v-tooltip>
                                        </v-flex>
                                    </v-layout>
                                </v-card-title>
                            </v-flex>
                            <v-flex xs12 fill-height class="scrollable" style="overflow-x: auto">
                                <div class="text-xs-center" v-if="loadingReportPreview">
                                    <v-progress-circular
                                            :size="40"
                                            :width="5"
                                            color="primary"
                                            indeterminate
                                    />
                                </div>
                                <report-preview
                                        v-if="!loadingReportPreview && !compactPreview && !refreshView && previewData != null && typeof previewData.reportData != 'undefined' && previewData.reportData.length > 0"
                                        :preview-data="previewData"
                                        :user-info="userInfo"
                                        @showPreview="$emit('showPreview', $event)"
                                        :get-task-header="getTaskHeader"
                                        @showReports="$emit('showReports', $event)"
                                ></report-preview>
                            </v-flex>
                        </v-layout>
                    </v-card>

        </v-flex>


    </v-layout>
</template>

<script>
    import RuleDesignSelectFields from "./ReportDesignSelectFields";
    import RuleDesignManageFields from "./ReportDesignManageFields";
    import {codeBaseMixin} from "../../../codeBaseMixin";
    import RuleGroupings from "./ReportGroupings";
    import ReportPreview from "./ReportPreview";
    import ReportConditions from "./ReportConditions";
    export default {
        mixins: [codeBaseMixin],
        components: {
            ReportConditions,
            ReportPreview,
            RuleGroupings,
            RuleDesignManageFields,
            RuleDesignSelectFields
        },
        props: {
            selectedReport: Object,
            userInfo: Object,
            steps: Array,
            fields: Array,
            dataSets: Array,
            dataSetFields: Array,
            workflowStatuses: Array,
            selectedFields: Array,
            previewData: Object,
            groupingOrder: Array,
            loadingReportPreview: Boolean,
            value: String,
            conditions: Array,
            users: Array,
            selectLists: Array,
            getTaskHeader: Function,
        },
        data() {
            return {
                fullScreenPreview: false,
                compactPreview: false,
                showGroupings: true,
                showSelectedFields: false,
                refreshView: false,
            }
        },
        methods: {
            updateSampleSize() {
                switch (this.value) {
                    case '100' : {
                        this.$emit('input', '1000');
                        break;
                    }
                    case '1000' : {
                        this.$emit('input', '0');
                        break;
                    }
                    case '0' : {
                        this.$emit('input', '100');
                        break;
                    }
                }
            },

            queryTypeFromFieldType(fieldType) {
                switch (fieldType) {
                        case 1 : {return 2} // - Word Field
                        case 2 : {return 3} // - Date Field
                        case 3 : {return 1} // - Number Field
                        case 4 : {return 1} // - Currency Field
                        case 5 : {return 6} // - List Field
                        case 6 : {return 4} // - Mail/Document Field
                        case 7 : {return 4} // - Restricted Mail/Document
                        case 8 : {return 4} // - MailTemplate
                        case 9 : {return 4} // - Field Set Select Field
                        case 10 : {return 4} // - Split Task Field
                        case 11 : {return 4} // - Check box list Field
                        case 12 : {return 4} // - Close Task Field
                        case 13 : {return 4} // - Information Field
                        default : {return 4}
                }
            },

        },
        watch: {
            fullScreenPreview() {
                this.compactPreview = false;
                this.refreshView = true;
                setTimeout(() => {this.refreshView = false;}, 500)
            }
        },
        computed: {
            computedHeadings() {
                try {
                    return Object.keys(this.previewData[0]);
                } catch (e) {
                    // eslint-disable-next-line
                    return []
                }
            },
            manageFields() {
                return this.selectedFields.filter(() => true)
                    .sort((a,b) => a['order'] < b['order'] ? -1 : 1)
                    .map((obj, i) => {
                        let tField = {
                            id: obj.id,
                            fieldType: obj.fieldType,
                            aggregate: obj.aggregate,
                            dsSelect: obj.dsSelect,
                            order: i,
                            alias: obj.alias,
                        };
                        tField.mainDescription = '-';
                        tField.subDescription = '-';
// Workflow Fields
                        if (['wf','wu','wd'].includes(tField.fieldType)) {
                            tField.allowAggregate = this.flatFields.workflowFields[tField.id].allowAggregate;
                            if (tField.alias == null) {
                                tField.mainDescription = this.flatFields.workflowFields[tField.id].description;
                                if (tField.aggregate != null) {tField.mainDescription = `${tField.aggregate}(${tField.mainDescription})`}
                                tField.subDescription = this.flatFields.steps[this.flatFields.workflowFields[tField.id].fk_step].description;
                            } else {
                                tField.mainDescription = tField.alias;
                                tField.subDescription = this.flatFields.workflowFields[tField.id].description + ' - ' + this.flatFields.steps[this.flatFields.workflowFields[tField.id].fk_step].description;
                            }
                        }
// Dataset Fields
                        if (['df','du','dd'].includes(tField.fieldType)) {
                            tField.allowAggregate = this.flatFields.dataSetFields[tField.id].allowAggregate;
                            if (tField.alias == null) {
                                tField.mainDescription = this.flatFields.dataSetFields[tField.id].description;
                                if (tField.aggregate != null) {tField.mainDescription = `${tField.aggregate}(${tField.mainDescription})`}
                                tField.subDescription = this.flatFields.dataSets[tField.dsSelect].fieldDescription + ' - ' + this.flatFields.dataSets[tField.dsSelect].dataSetDescription;
                            } else {
                                tField.mainDescription = tField.alias;
                                tField.subDescription = this.flatFields.dataSetFields[tField.id].description + ' - ' + this.flatFields.dataSets[tField.dsSelect].fieldDescription + ' - ' + this.flatFields.dataSets[tField.dsSelect].dataSetDescription;
                            }
                        }
// Task Fields
                        if (['tf'].includes(tField.fieldType)) {
                            tField.allowAggregate = this.flatFields.taskFields[tField.id].allowAggregate;
                            if (tField.alias == null) {
                                tField.mainDescription = this.flatFields.taskFields[tField.id].description;
                                if (tField.aggregate != null) {tField.mainDescription = `${tField.aggregate}(${tField.mainDescription})`}
                                tField.subDescription = 'Task Field';
                            } else {
                                tField.mainDescription = tField.alias;
                                tField.subDescription = this.flatFields.taskFields[tField.id].description;
                            }
                        }
// Status Fields
                        if (['sf','su','sd'].includes(tField.fieldType)) {
                            tField.allowAggregate = this.flatFields.workflowStatuses[tField.id].allowAggregate;
                            if (tField.alias == null) {
                                tField.mainDescription = this.flatFields.workflowStatuses[tField.id].description;
                                if (tField.aggregate != null) {tField.mainDescription = `${tField.aggregate}(${tField.mainDescription})`}
                                tField.subDescription = 'Workflow Status';
                            } else {
                                tField.mainDescription = tField.alias;
                                tField.subDescription = this.flatFields.workflowStatuses[tField.id].description;
                            }
                        }
                        return tField
                    });
            },
            flatFields() {
                return {
                    taskFields: {
                        0: {id: 0, description: 'Task ID', access: true, allowAggregate: true, queryFieldType: 1},
                        1: {id: 1, description: 'Task Title', access: true, allowAggregate: true, queryFieldType: 2},
                        2: {id: 2, description: 'Task Owner', access: true, allowAggregate: true, queryFieldType: 5},
                        3: {id: 3, description: 'Folder Name', access: true, allowAggregate: true, queryFieldType: 2},
                        4: {id: 4, description: 'Folder Groups', access: true, allowAggregate: true, queryFieldType: 2},
                        5: {id: 5, description: 'Task State', access: true, allowAggregate: true, queryFieldType: 2},
                        6: {id: 6, description: 'Task Percentage', access: true, allowAggregate: true, queryFieldType: 1},
                        7: {id: 7, description: 'Current Status', access: true, allowAggregate: true, queryFieldType: 2},
                        8: {id: 8, description: 'Task Created', access: true, allowAggregate: true, queryFieldType: 3},
                        9: {id: 9, description: 'Task Age (Days)', access: true, allowAggregate: true, queryFieldType: 1},
                        10: {id: 10, description: 'Task Duration (Days)', access: true, allowAggregate: true, queryFieldType: 1},
                        11: {id: 11, description: 'Task Escalation Duration (Days)', access: true, allowAggregate: true, queryFieldType: 1},
                        12: {id: 12, description: 'Task Sub Owner', access: true, allowAggregate: true, queryFieldType: 5},
                        13: {id: 13, description: 'Last Viewed By', access: true, allowAggregate: true, queryFieldType: 5},
                        14: {id: 14, description: 'Last Viewed Date', access: true, allowAggregate: true, queryFieldType: 3},
                    },
                    workflowFields: this.fields.reduce((list, item) => {
                        list[item.id] = {
                            id: item.id,
                            fk_step: item.fk_step,
                            description: item.description,
                            lookup: item.lookup,
                            type: item.fieldType,
                            access: this.intToBool(item.access),
                            allowAggregate: [1, 2, 3, 4, 5, 12].includes(item.fieldType),
                            systemFieldType: item.fieldType,
                            queryFieldType: this.queryTypeFromFieldType(item.fieldType)
                        };
                        return list
                    }, {}),
                    dataSetFields: this.dataSetFields.reduce((list, item) => {
                        list[item.id] = {
                            id: item.id,
                            fk_field_set: item.fk_field_set,
                            description: item.description,
                            type: item.type,
                            lookup: item.lookup,
                            access: this.intToBool(item.access),
                            allowAggregate: [1, 2, 3, 4, 5, 12].includes(item.type),
                            systemFieldType: item.fieldType,
                            queryFieldType: this.queryTypeFromFieldType(item.type)
                        };
                        return list
                    }, {}),
                    workflowStatuses: this.workflowStatuses.reduce((list, item) => {
                        list[item.id] = {
                            id: item.id,
                            description: item.description,
                            type: null,
                            access: this.intToBool(item.access),
                            allowAggregate: true,
                            queryFieldType: 2,
                            systemFieldType: 1,
                        };
                        return list
                    }, {}),
                    dataSets: this.dataSets.reduce((list, item) => {
                        list[item.fieldID] = {
                            fieldID: item.fieldID,
                            fieldDescription: item.fieldDescription,
                            fk_step: item.fk_step,
                            fk_dataset: item.fk_dataset,
                            dataSetDescription: item.description,
                        };
                        return list
                    }, {}),
                    steps: this.steps.reduce((list, item) => {
                        list[item.id] = {
                            id: item.id,
                            description: item.description,
                        };
                        return list
                    }, {})
                }
            },
        }
    }
</script>

<style scoped>
    .report-table table {
        border-collapse: collapse;
        width: 100%;
    }

    .report-table th, td {
        text-align: left;
        padding: 8px;
    }

    .report-table tr:nth-child(even){background-color: #f2f2f2}

    .report-table th {
        background-color: #4CAF50;
        color: white;
    }
</style>
<!--                        <table class="report-table fill-height scrollable">-->
<!--                            <tr class="bord" style="height: 30px">-->
<!--                                <th v-for="key in computedHeadings">{{key}}</th>-->
<!--                            </tr>-->
<!--                            <tbody class="bord fill-height scrollable">-->
<!--                            <tr v-for="line in previewData">-->
<!--                                <td v-for="val in computedHeadings">{{line[val]}}</td>-->
<!--                            </tr>-->
<!--                            </tbody>-->

<!--                            <v-layout column fill-height style="overflow-x: auto">-->
<!--                                <v-flex shrink >-->
<!--                                    <v-layout row >-->
<!--                                        <v-flex style="min-width: 100px; max-width: 100px; overflow-x: hidden" class="text-truncate primary&#45;&#45;text body-2 pa-2" v-for="key in computedHeadings">-->
<!--                                            {{key}}-->
<!--                                        </v-flex>-->
<!--                                    </v-layout>-->
<!--                                </v-flex>-->
<!--&lt;!&ndash;                                <v-flex shrink>&ndash;&gt;-->
<!--&lt;!&ndash;                                    <div style="border-radius: 2px; height: 2px; width: 100%" class="secondary"></div>&ndash;&gt;-->
<!--&lt;!&ndash;                                </v-flex>&ndash;&gt;-->

<!--                                <v-flex fill-height id="test123" style="overflow-x: hidden; overflow-y: scroll">-->
<!--                                    <v-layout row v-for="line in previewData">-->
<!--                                        <v-flex v-for="val in computedHeadings" style="min-width: 100px; max-width: 100px; overflow-x: hidden" class="text-truncate caption pa-2">-->
<!--                                            {{line[val]}}-->
<!--                                        </v-flex>-->
<!--                                    </v-layout>-->
<!--                                </v-flex>-->



<!--                            </v-layout>-->


<!--                        </table>-->
