<template>
    <v-layout row>
        <v-flex class="pl-2">
            <v-autocomplete
                    :items="JSON.parse(value.lookup)"
                    clearable
                    color="primary"
                    flat
                    v-model="value.value"
                    hide-details
                    class="pa-0 ma-0"
                    single-line
                    :label="value.fieldDescription"
                    dense
                    :readonly="readOnly"
                    @input="$emit('forceSave')"
            >
            </v-autocomplete>
        </v-flex>
    </v-layout>
</template>

<script>
    export default {
        props: {
            value: Object,
            readOnly: Boolean
        }
    }
</script>

<style scoped>

</style>
