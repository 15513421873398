<template>
    <v-card :color="color" class="c-d-flex c-align-center" style="border-radius: 16px; cursor: pointer; display: inline-flex;" hover ripple>
        <v-icon v-if="!textIcon" small :class="color" class="pa-1 darken-2" :color="iconColor" style="border-radius: 16px">{{ icon }}</v-icon>
        <div v-else :class="color" class="darken-2 white--text px-1" style="border-radius: 16px; ">{{icon}}</div>
        <div class="white--text caption pl-1" :class="showClose ? 'pr-0' : 'pr-2'" style="line-height: 0; padding-top: 12px; padding-bottom: 12px; max-width: 100%; overflow-x: hidden; white-space: nowrap">
            {{maxChars ? `${label.substr(0,maxChars)}${label.length > maxChars ? '...' : ''}` : label}}
        </div>
        <div v-if="showClose">
            <v-btn
                    small
                    icon
                    flat
                    class="pa-0 ma-0"
                    color="white"
                    style="height: 24px; width: 24px"
                    @click="$emit('input')"
            >
                <v-icon small>close</v-icon>
            </v-btn>
        </div>
    </v-card>
</template>

<script>

export default {
    props: {
        color: {
            default: 'primary',
            type: String
        },
        iconColor: {
            default: 'white',
            type: String
        },
        icon: String,
        textIcon: {
            default: false,
            type: Boolean
        },
        label: String,
        showClose: {
            default: false,
            type: Boolean
        },
        maxChars: {
            default: null,
            type: Number
        }
    },
}
</script>