
<template>
    <v-dialog
            v-model="showReportManager"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
            persistent
    >
        <v-card flat height="100vh">

            <v-layout column fill-height >
                <v-flex shrink primary>
<!--Card Title-->
                    <v-card-title class="py-2 pr-2 ">
                        <img src="icons/reports.svg" width="42px" class="pr-2"/>
                        <span class="title font-weight-light white--text">{{selectedReport == null ? "Reports" : selectedReport.description}}</span>
                        <v-spacer></v-spacer>
                        <v-btn flat icon color="white" class="pa-0 ma-0" @click="$emit('dismiss', false)">
                            <v-icon>close</v-icon>
                        </v-btn>
                    </v-card-title>
                </v-flex>
<!--Loading-->
                <v-flex shrink v-if="loadingReports" class="pa-2 text-xs-center">
                    <v-progress-circular
                            :size="40"
                            :width="5"
                            color="primary"
                            indeterminate
                    />
                </v-flex>
<!--Done Loading-->
                <template v-else>
<!--Select Report And Tabs Bar-->
                    <v-flex shrink class="primary">
                        <v-layout row align-center>
<!--Show Hide Report-->
                            <v-flex xs12 class="px-2">
                                <v-layout row align-center justify-space-between>
                                    <v-flex xs4>
                                        <v-btn
                                                class="ma-0"
                                                color="white"
                                                @click="showSelectReport = !showSelectReport"
                                                :disabled="selectedReport == null"
                                                flat
                                        >
                                            <v-icon left>list</v-icon>
                                            Select Report
                                        </v-btn>
                                    </v-flex>
                                    <v-flex xs12 v-if="selectedReport != null && intToBool(userInfo.reportsManager)" >
                                        <v-tabs
                                                v-model="reportView"
                                                color="primary"
                                                slider-color="secondary"
                                                grow
                                                show-arrows
                                                v-if="!resetTabs"
                                        >
                                            <v-tab class="white--text">View</v-tab>
                                            <v-tab class="white--text" v-show="['owner', 'editor'].includes(selectedReport.role)">Design</v-tab>
                                            <v-tab class="white--text" v-show="selectedReport.role === 'owner'">Share</v-tab>
                                        </v-tabs>
                                    </v-flex>
                                    <v-flex shrink v-if="selectedReport != null" class="pl-2">
                                        <v-btn
                                            color="white"
                                            class="ma-0"
                                            @click="downloadReport"
                                            flat
                                            :loading="downloadingReport || selectedReportTemplate == null"
                                            :disabled="downloadingReport || selectedReportTemplate == null"
                                        >
                                            <v-icon left>cloud_download</v-icon>
                                            Download Report
                                        </v-btn>
                                    </v-flex>
                                    <v-flex shrink v-if="selectedReport != null && (selectedReport.role === 'owner' || selectedReport.role === 'editor')" class="pl-2">
                                        <v-btn
                                            color="white"
                                            class="ma-0"
                                            @click="showApiAccess = true"
                                            flat
                                        >
                                            <v-icon left>api</v-icon>
                                            API Access
                                        </v-btn>
                                    </v-flex>

                                </v-layout>

                            </v-flex>
<!--Selected Report Tabs-->

                        </v-layout>
                        <v-divider></v-divider>
                    </v-flex>
<!--Main View-->
                    <v-flex xs12 class="scrollable">
                        <v-layout row fill-height class="scrollable">
                            <v-flex xs4 v-show="showSelectReport" fill-height>
                                <app-select-report
                                        v-model="selectedReport"
                                        :reportFilter="selectReportFilter"
                                        :userInfo="userInfo"
                                        :reports="reportList"
                                        :showSelectReport="showSelectReport"
                                        @reportAdded="newReport($event)"
                                        @input="selectedReportRun"
                                        :workflows="workflows"
                                        @delete="deleteReport($event)"
                                        @remove="removeUserFromReport($event)"
                                        @rename="renameReportRunStart($event)"
                                        @refreshReports="refreshReports"
                                        @duplicate="duplicateReport($event)"
                                />
                            </v-flex>
                            <v-flex xs12 fill-height class="scrollable" v-if="selectedReport != null">
                                <app-report-detail
                                        :user-info="userInfo"
                                        :selected-report="selectedReport"
                                        :view-option="reportView"
                                        @removeUserFromReport="removeReportForUser()"
                                        :get-task-header="getTaskHeader"
                                        @showReports="$emit('showReports', $event)"
                                        @templateLoaded="selectedReportTemplate = $event"
                                        :local-data="localData"
                                        @ownershipChanged="reportView = 0;"
                                />
                            </v-flex>
                        </v-layout>
                    </v-flex>
                </template>
            </v-layout>
        </v-card>
        <app-input-dlg
                v-if="showRenameReport"
                :show-dialog="showRenameReport"
                title="Rename Report"
                body="Please Provide a New Report Name:"
                :cancel-button="true"
                :show-input-box="true"
                :buttons="['Rename Report']"
                :call-backs="[ renameReport ]"
                @dismiss="showRenameReport = false"
                :default-input="selectedReport.description"
        />
        <app-input-dlg
                v-if="showRemoveReport"
                :show-dialog="showRemoveReport"
                title="Confirm Delete Report"
                :body="`Please Note this will permanently delete the report '${removalReport.description}' for all users that the report is shared with. If you donnot want to permanently delete the report, transfer the report ownership in the sharing tab and remove your share.`"
                :cancel-button="true"
                :show-input-box="false"
                :buttons="['Remove Report']"
                :call-backs="[ removeReport ]"
                @dismiss="showRemoveReport = false"
        />

        <app-input-dlg
            :show-dialog="showApiAccess"
            title="API Access"
            :cancel-button="true"
            :show-input-box="false"
            @dismiss="showApiAccess = false"
            :body-padding="false"
            :width="900"
        >
            <template slot="body">
                <div v-if="loadingApiEndpoint" class="c-d-flex c-align-center c-justify-center pa-5">
                    <v-progress-circular
                        :size="40"
                        :width="5"
                        color="primary"
                        indeterminate
                    />
                </div>
                <template v-else>
                    <v-data-table
                        :headers="endpointHeaders"
                        :items="apiEndpoints"
                    >
                        <template v-slot:items="props">
                            <td>
                                <v-tooltip top>
                                    <template #activator="{on}">
                                        <div @click="copyLink(`${serverAddress}/reportapi/${props.item.urlKey}`)" class="primary--text caption c-d-flex align-center" v-on="on" style="cursor:pointer;">
                                            <v-icon left color="primary">link</v-icon>
                                            <div class="c-flex-grow-1">
                                                {{serverAddress}}/reportapi/{{props.item.urlKey}}
                                            </div>
                                        </div>
                                    </template>
                                    Copy API Link
                                </v-tooltip>


                            </td>
                            <td>{{ (usersRef[props.item.fkReportUser].name + ' ' + usersRef[props.item.fkReportUser].surname).trim() }}</td>
                            <td>{{ props.item.format }}</td>
                            <td>{{ props.item.lastPull ? format(new Date(props.item.lastPull), 'YYYY-MM-DD HH:mm:ss') : '-' }}</td>
                            <td>
                                <div class="c-d-flex c-align-center">
                                    <v-tooltip top>
                                        <template #activator="{on}">
                                            <a :href="`${serverAddress}/reportapi/${props.item.urlKey}`" target="_blank">
                                                <v-btn
                                                    small
                                                    icon
                                                    flat
                                                    color="secondary"
                                                    v-on="on"
                                                >
                                                    <v-icon>open_in_browser</v-icon>
                                                </v-btn>
                                            </a>
                                        </template>
                                        Test Link
                                    </v-tooltip>
                                    <v-tooltip top>
                                        <template #activator="{on}">
                                            <v-btn
                                                small
                                                icon
                                                flat
                                                color="error"
                                                v-on="on"
                                                @click="deleteEndpointId = props.item.id"
                                            >
                                                <v-icon>delete</v-icon>
                                            </v-btn>
                                        </template>
                                        Delete Endpoint
                                    </v-tooltip>
                                </div>

                            </td>
                        </template>
                    </v-data-table>
                </template>
            </template>
            <template #actions>
                <div class="c-d-flex c-align-center c-justify-space-between">
                    <v-btn
                        small
                        color="secondary"
                        @click="showCreateEndpoint = true"
                    >
                        Create New Endpoint
                    </v-btn>
                    <v-btn
                        small
                        color="primary"
                        @click="showApiAccess = false"
                    >
                        Done
                    </v-btn>
                </div>
            </template>
        </app-input-dlg>

        <app-input-dlg
            v-if="showCreateEndpoint"
            :show-dialog="showCreateEndpoint"
            title="Create New Report API Endpopint"
            :cancel-button="!loadingCreateApiEndPoint"
            :show-input-box="false"
            @dismiss="showCreateEndpoint = false"
            :width="500"
        >
            <template slot="body">
                <div>
                    <v-autocomplete
                        :items="['JSON', 'CSV']"
                        label="Data Format"
                        hide-details
                        dense
                        v-model="createApiEndpointFormat"
                        :disabled="loadingCreateApiEndPoint"
                    ></v-autocomplete>
                </div>
            </template>
            <template #actions>
                <div class="c-d-flex c-align-center c-justify-space-between">
                    <v-btn
                        small
                        flat
                        color="grey"
                        @click="showCreateEndpoint = false"
                        :disabled="loadingCreateApiEndPoint"
                    >
                        Cancel
                    </v-btn>
                    <v-btn
                        small
                        color="primary"
                        @click="createApiEndPoint()"
                        :disabled="loadingCreateApiEndPoint"
                        :loading="loadingCreateApiEndPoint"
                    >
                        Create
                    </v-btn>
                </div>
            </template>
        </app-input-dlg>


        <app-input-dlg
            v-if="deleteEndpointId"
            :show-dialog="!!deleteEndpointId"
            title="Delete Endpoint"
            :cancel-button="!loadingCreateApiEndPoint"
            :show-input-box="false"
            @dismiss="deleteEndpointId = null"
            :width="500"
        >
            <template slot="body">
                <div class="error--text">
                    Are you sure that you would like to delete the endpoint. Please note the following action can't be undone
                </div>
            </template>
            <template #actions>
                <div class="c-d-flex c-align-center c-justify-space-between">
                    <v-btn
                        small
                        flat
                        color="grey"
                        @click="deleteEndpointId = null"
                        :disabled="deletingEndpoint"
                    >
                        Cancel
                    </v-btn>
                    <v-btn
                        small
                        color="error"
                        @click="deleteApiEndpoint()"
                        :disabled="deletingEndpoint"
                        :loading="deletingEndpoint"
                    >
                        Confirm
                    </v-btn>
                </div>
            </template>
        </app-input-dlg>
    </v-dialog>
</template>

<script>

    // TODO Document Preview Not Working

    import {codeBaseMixin} from "../codeBaseMixin";
    import SelectReport from "./Select Report/SelectReport";
    import ReportDetail from "./Report Detail/ReportDetail";
    import InputDlg from "../components/General/InputDlg";
    import axios from 'axios';
    import tokenMan from "../tokenManSite";
    import {mapGetters} from "vuex";
    import format from "date-fns/format";
    import {arrSplice, copyText} from "@/codeFunctions";

    export default {
        components: {
            appInputDlg: InputDlg,
            appReportDetail: ReportDetail,
            appSelectReport: SelectReport
        },
        mixins: [codeBaseMixin],
        props: {
            showReportManager: Boolean,
            userInfo: Object,
            getTaskHeader: Function,
            localData: Object
        },
        data() {
            return {
                loadingReports: false,
                selectedReport: null,
                reportList: null,
                showSelectReport: true,
                reportView: 0,
                selectReportFilter: null,
                workflows: [],
                showRenameReport: false,
                resetTabs: false,
                showRemoveReport: false,
                removalReport: null,
                firstTimeLoaded: true,
                selectedReportTemplate: null,
                downloadingReport: false,
                showApiAccess: false,
                apiEndpoints: [],
                loadingApiEndpoint: false,
                showCreateEndpoint: false,
                createApiEndpointFormat: 'JSON',
                loadingCreateApiEndPoint: false,
                endpointHeaders: [
                    { text: 'Address', value: 'urlKey' },
                    { text: 'User Security', value: 'fkReportUser' },
                    { text: 'Format', value: 'format' },
                    { text: 'Last Pull', value: 'lastPull' },
                    { text: '', value: 'actions', width: 50, justify: "end", sortable: false},
                ],
                format,
                deleteEndpointId: null,
                deletingEndpoint: false,

            }
        },
        watch: {
            showApiAccess(val) {
                if (val)
                    this.refreshApiEndpoints()
            },
            showReportManager: {
                immediate: true,
                handler(shown) {
                    if (shown && this.firstTimeLoaded) {
                        this.showSelectReport = true;
                        this.reportView = 0;
                        this.selectedReport = null;
                        this.reportList = null;
                        this.firstTimeLoaded = false;
                        this.startup();
                    }
                }
            }
        },
        methods: {

            copyLink(link) {
                copyText(document, link)
                this.$snack.info('Link Copied')
            },

            async deleteApiEndpoint() {
                try {
                    this.deletingEndpoint = true;
                    await this.$newReq("POST", `wfr/reportAPI/deleteEndpoint/${this.userInfo.entityID}`, {
                        endpointId: this.deleteEndpointId,
                    })
                    arrSplice(this.apiEndpoints, ep => ep.id === this.deleteEndpointId)
                    this.deleteEndpointId = null;
                } catch (e) {
                    console.log(e)
                    this.$snack.networkError()
                } finally {
                    this.deletingEndpoint = false;
                }
            },

            async createApiEndPoint() {
                try {
                    this.loadingCreateApiEndPoint = true;
                    const newEndpoint = await this.$newReq("POST", `wfr/reportAPI/createEndpoint/${this.userInfo.entityID}`, {
                        reportId: this.selectedReport.id,
                        userId: this.userInfo.userID,
                        format: this.createApiEndpointFormat
                    })
                    console.log({newEndpoint})
                    this.apiEndpoints.push(newEndpoint)
                    this.showCreateEndpoint = false;
                } catch (e) {
                    console.log(e)
                    this.$snack.networkError()
                } finally {
                    this.loadingCreateApiEndPoint = false;
                }
            },

            async refreshApiEndpoints() {
                try {
                    this.loadingApiEndpoint = true;
                    this.apiEndpoints = await this.$newReq("GET", `wfr/reportAPI/listEndPoints/${this.userInfo.entityID}/${this.selectedReport.id}`)
                } catch (e) {
                    console.log(e)
                    this.$snack.networkError()
                } finally {
                    this.loadingApiEndpoint = false;
                }
            },

            downloadReport() {
                this.downloadingReport = true;
                axios({
                    url: `${this.serverAddress}/wfr/report/previewDownload/${this.userInfo.entityID}/${this.userInfo.userID}/${this.selectedReport.id}/${this.selectedReport.fk_workflow}/0`, //your url
                    method: 'POST',
                    data: this.selectedReportTemplate,
                    headers: {
                        
                        'tl-token': tokenMan.token(),
                    },
                    responseType: 'blob', // important
                }).then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `${this.selectedReport.description}.xlsx`); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                    this.downloadingReport = false;
                }).catch((e) => {
                    // eslint-disable-next-line
                    console.log(e);
                    this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                    this.downloadingReport = false;
                });

                    // this.fetch({
                    //     method: 'POST',
                    //     url:,
                    //     body: JSON.stringify(this.selectedReportTemplate)
                    // })
                    //
                    //     })
                    //     .catch((e) => {
                    //         // eslint-disable-next-line
                    //         console.log(e);
                    //         // this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                    //     })
            },


            duplicateReport(reportData) {
                this.selectedReport = null;
                this.loadingReports = true;
                this.fetch({method: 'POST', url:`/wfr/duplicate/${this.userInfo.entityID}/${reportData.reportID}/${this.userInfo.userID}`, body: JSON.stringify({description: reportData.description})})
                    .then((data) => {
                        this.reportList.push(data);
                        this.showSnack('Info', 'Report Duplicated', 'Close', 'primary');
                        this.selectedReport = data;
                        this.loadingReports = false;
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e);
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                        this.loadingReports = false;
                    })
            },

            refreshReports() {
                this.showSelectReport = true;
                this.reportView = 0;
                this.selectedReport = null;
                this.reportList = null;
                this.startup();
            },

            removeReportForUser() {
                this.reportList = this.reportList.filter(obj => obj.id !== this.selectedReport.id);
                this.selectedReport = null;
                this.showSelectReport = true;
            },

            deleteReport(report) {
                this.removalReport = report;
                this.showRemoveReport = true;
            },

            removeUserFromReport(repID) {
                this.selectedReport = null;
                this.loadingReports = true;
                this.showRemoveReport = false;
                this.fetch({method: 'DELETE', url:`/wfr/share/${this.userInfo.entityID}/${repID}/${this.userInfo.userID}`})
                    .then(() => {
                        this.selectedReport = null;
                        this.reportList = this.reportList.filter(obj => obj.id != repID);
                        this.showSnack('Info', 'Report Removed', 'Close', 'primary')
                        this.loadingReports = false;
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e);
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
                        this.loadingReports = false;
                    })
            },

            removeReport() {
                let report = this.removalReport;
                this.selectedReport = null;
                this.loadingReports = true;
                this.showRemoveReport = false;
                this.fetch({method: 'DELETE', url:`/wfr/userReports/${this.userInfo.entityID}/${report.id}`})
                    .then(() => {
                        this.selectedReport = null;
                        this.reportList = this.reportList.filter(obj => obj.id != report.id);
                        this.showSnack('Info', 'Report Removed', 'Close', 'primary')
                        this.loadingReports = false;
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e);
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                        this.loadingReports = false;
                    })
            },

            selectedReportRun() {
                this.showSelectReport = false;
                this.resetTabs = true;
                this.reportView = 0;
                this.$nextTick(() => {
                    this.resetTabs = false;
                })
            },

            newReport(report) {
                this.reportList.push(report);
                this.selectedReport = report;
                this.showSelectReport = false;
                this.reportView = 1;
            },
            startup() {
                this.loadingReports = true;
                this.fetch({method: 'GET', url:`/wfr/userReports/${this.userInfo.entityID}/${this.userInfo.userID}`})
                    .then((data) => {
                        this.reportList = data.reports;
                        this.workflows = data.workflows;
                        this.loadingReports = false;
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e);
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red')
                        this.loadingReports = false;
                    })
            },
            renameReportRunStart(report) {
                this.selectedReport = report;
                this.showRenameReport = true;
            },
            renameReport(description) {
                if (!this.blankString(description)) {
                    this.showRenameReport = false;
                    this.fetch({method: 'PATCH', url:`/wfr/userReports/${this.userInfo.entityID}/${this.selectedReport.id}`, body: JSON.stringify({description: description})})
                        .then(() => {
                            this.selectedReport.description = description;
                            this.showSnack('Info', 'Report Renamed', 'Close', 'primary')
                        })
                        .catch((e) => {
                            // eslint-disable-next-line
                            console.log(e);
                            this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
                        })
                } else {
                    this.showSnack('Error', 'A description is required', 'Close', 'red')
                }
            }
        },
        computed: {
            ...mapGetters(["usersRef"])
        }
    }
</script>

<style scoped>

</style>
