export default {
    namespaced: true,
    state() {
        return {
            docs: [],
            folders: [],
            userDocs: [],
        }
    },
    mutations: {
        setDocs(state, payload) {state.docs = payload},
        setFolders(state, payload) {state.folders = payload},
        setUserDocs(state, payload) {state.userDocs = payload},
        addUserDoc(state, payload) {state.userDocs.push(payload)},
        removeUserDoc(state, payload) {state.userDocs = state.userDocs.filter(obj => obj.id !== payload)},
    },
    actions: {
        repoInit(context, payload) {
            context.commit('setDocs', payload.docs)
            context.commit('setFolders', payload.folders)
            context.commit('setUserDocs', payload.userDocs)
        },
        repoAddUserDoc(context, payload) {
            context.commit('addUserDoc', payload)
        },
        repoRemoveUserDoc(context, payload) {
            context.commit('removeUserDoc', payload)
        }
    },
    getters: {
        repoDocs(state) {return state.docs},
        repoFolders(state) {return state.folders},
        repoUserDocs(state) {return state.userDocs},
    }
}
